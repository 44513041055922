<!--
 * @Author: Zeng
 * @Date: 2023-07-11 15:47:36
 * @LastEditTime: 2023-07-20 13:37:04
-->
<template>
  <div>
    <summary-data :deptList="deptList"></summary-data>
    <projection-data :deptList="deptList"></projection-data>
    <material-data :deptList="deptList"></material-data>
  </div>
</template>

<script>
import SummaryData from './components/SummaryData'
import ProjectionData from './components/ProjectionData'
import MaterialData from './components/MaterialData'
import { getDeptList } from '@/network/api/toolManagement/api-product-test'

export default {
  components: {
    'summary-data': SummaryData,
    'projection-data': ProjectionData,
    'material-data': MaterialData
  },
  data() {
    return {
      deptList: []
    }
  },

  mounted() {
    this.loadDepartment()
  },
  methods: {
    // 加载部门下拉
    loadDepartment() {
      getDeptList({ departmentName: '' }).then(res => {
        let list = res.data.map(v => {
          return {
            departmentId: v.departmentId,
            departmentName: v.departmentName,
            children: v.children ? JSON.parse(v.children) : []
          }
        })
        this.deptList = list || []
      })
    }
  }
}
</script>

<style lang="scss">
.projection-container {
  margin-bottom: 30px;

  .projection-header {
    font-size: 20px;
    font-weight: 500;
    color: #1a1a1a;
    position: relative;
    margin: 10px 0;

    &::before {
      content: '';
      display: inline-block;
      width: 3px;
      height: 20px;
      background-color: $themeColor;
      position: absolute;
      top: 5px;
    }

    span {
      margin-left: 12px;
    }
  }

  .projection-content {
    padding: 10px 24px;
    box-sizing: border-box;
    border: solid 1px #EBEEF5;
    border-radius: 4px;
  }

  .projection-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    .title {
      font-size: 16px;
      font-weight: 500;
      color: #1a1a1a;
    }
  }
}
</style>
