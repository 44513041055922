<template>
  <el-dialog :title="isEdit ? '分享基木鱼' : '分享基木鱼'" width="576px" :visible.sync="visible" :before-close="close" label-position="right" :modal-append-to-body="false" :close-on-press-escape="false" :close-on-click-modal="false">
    <!-- <span style="color: darkgray;">{{ synch }} </span> -->

    <el-timeline style="margin-left: 168px;" class="mt-20">
      <el-timeline-item v-for="(activity, index) in activities" :key="index" :icon="activity.icon" :type="activity.type" :color="activity.color" :size="activity.size" :timestamp="activity.timestamp">
        {{ activity.content }}
      </el-timeline-item>
    </el-timeline>
    <span slot="footer" class="dialog-footer">
      <!-- <el-button round @click="close">取消</el-button>
      <el-button type="primary" round @click="submit">确定</el-button> -->
    </span>
  </el-dialog>
</template>
<script>
import { synchAccount } from '@/network/api/account/accountBaiduHousekeeper'
import moment from 'moment'

import { AssetManagementBaiduWoodenFishEnt, formRules } from './object'
export default {
  inject: ['loadData'],
  data() {
    return {
      ent: new AssetManagementBaiduWoodenFishEnt(),
      rules: formRules,
      isEdit: false,
      visible: false,
      synch: '正在分享',
      unique: false,
      cityList: [],
      reverse: true,
      activities: [
        {
          content: '分享中',
          timestamp: '',
          size: 'large',
          type: 'primary',
          icon: 'el-icon-loading'
        },
        {
          content: '分享结束(完成n个)',
          timestamp: '',
          size: 'large'
        }
      ]
    }
  },
  methods: {
    UniqueChange(val) {
      this.activities[1].timestamp = moment().format('YYYY-MM-DD hh:mm:ss')
      if (undefined === val) {
        this.$message({
          message: '网络异常',
          type: 'error'
        })
      }
      synchAccount(val)
        .then((res) => {
          if (res.code === 200) {
            if (res.data != null) {
              this.activities[0].icon = 'el-icon-circle-check'
              this.activities[1].icon = 'el-icon-circle-check'
              this.activities[1].type = 'primary'
              this.activities[1].content = '分享结束(完成' + res.data + '个)'
              this.activities[1].timestamp = moment().format('YYYY-MM-DD hh:mm:ss')
              this.synch = '完成分享'
              this.loadData()
              this.visible = false
            } else {
              this.unique = false
            }
          } else {
            this.infoOpt()
          }
        })
        .catch(() => {
          this.infoOpt()
        })
    },
    infoOpt() {
      this.activities[0].icon = 'el-icon-circle-close'
      this.activities[1].icon = 'el-icon-circle-close'
      this.activities[0].type = 'danger  '
      this.activities[1].type = 'danger  '
      this.activities[1].content = '分享失败'
      this.activities[1].timestamp = moment().format('YYYY-MM-DD hh:mm:ss')
      this.synch = '分享失败'
    },
    submit() {
      this.loadData()
      this.close()
    },

    close() {
      this.visible = false
    },
    show(isEdit = false, ent) {
      this.activities[0].timestamp = moment().format('YYYY-MM-DD hh:mm:ss')
      this.unique = false
      this.ent = ent
      this.isEdit = isEdit
      this.visible = true
      this.$nextTick(() => {
        if (!this.isEdit) {
          this.synch = '正在分享'
          this.activities[0].type = 'primary'
          this.activities[0].icon = 'el-icon-loading'
          this.activities[1].icon = ''
          this.activities[1].type = ''
          this.activities[1].content = '分享结束(完成n个)'
          this.activities[1].timestamp = ''

          this.UniqueChange(ent.housekeeperId)
        }
      })
    }
  }
}
</script>
<style scoped></style>
