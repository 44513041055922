/*
 * @Description: Do not edit
 * @Date: 2021-10-05 13:41:50
 * @LastEditTime: 2023-06-16 13:52:36
 */
import service from '@/network/service/service'

export function Page(data) {
  return service.post('/api/tencent/adMarketingApiClients/page', data)
}

// 绑定前调用
export function Save(data) {
  return service.post('/api/tencent/adMarketingApiClients/save', data)
}
export function Save1(data) {
  return service.post('/api/tencent/adMarketingApiClients/save1', data)
}
export function getAuthorizeUrl(id) {
  return service.get(`/api/tencent/adMarketingApiClients/gdt/authorize`, {
    params: { id }
  })
}
export function getAuthorizeUrl1(id) {
  return service.get(`/api/tencent/adMarketingApiClients/gdt/authorize1`, {
    params: { id }
  })
}
export function adMarketingApiClientsSelect(data) {
  return service.post('/api/tencent/adMarketingApiClients/select', data)
}

export function batch(data) {
  return service.post('/api/tencent/adMarketingApiClients/batch', data)
}

/** 推广计划下拉 */
export function getCampaignsSelect(data) {
  return service.get(`/api/tencent/campaignsAdq/getCampaigns/${data.page}/${data.pageSize}/${data.localAccountId}/${data.campaignId}`)
}

/** 广告下拉 */
export function getAdgroupsSelect(data) {
  return service.get(`/api/tencent/campaignsAdq/getAdgroups/${data.page}/${data.pageSize}/${data.localAccountId}/${data.campaignId}/${data.adgroupId}`)
}

/** 广告复制 */
export function replicate(data) {
  return service.post('/api/tencent/campaignsAdq/replicateNow', data)
}

/** 广告复制列表页 */
export function getCopyPage(data) {
  return service.get(`/api/tencent/campaignsAdq/getPage/${data.pageNum}/${data.pageSize}`)
}

/** 广告复制任务上传列表 */
export function getGenerateList(data) {
  return service.get(`/api/tencent/campaignsAdq/getGenerate/${data.pageNum}/${data.pageSize}/${data.taskId}`)
}

// -------------- 服务商账户 ---------------------------
/** 腾讯账户服务商列表 */
export function getTencentHousekeeperList(params) {
  return service.get('/api/tencent/adMarketingApiClients/getTabulationData', { params })
}

/** 刷新绑定子客账户 */
export function refreshChildAccount(id) {
  return service.get(`/api/tencent/adMarketingApiClients/refreshChildrenAccount/${id}`)
}

/** 添加腾讯账户服务商 */
export function addTencentHousekeeper(params) {
  return service.post('/api/tencent/adMarketingApiClients/addItem', params)
}

/** 编辑腾讯账户服务商 */
export function updateTencentHousekeeper(params) {
  return service.post('/api/tencent/adMarketingApiClients/updateItem48', params)
}

/** 腾讯账户服务商重新授权 */
export function authTencentHousekeeper(id) {
  return service.get(`/api/tencent/adMarketingApiClients/redelegation/${id}`)
}

/** 腾讯账户服务商下拉 */
export function getHousekeeperSelect(params) {
  return service.get(`/api/tencent/adMarketingApiClients/slt1`, { params })
}

/** 腾讯账户服务商未选择账户列表 */
export function getChildAccountList(data) {
  return service.get(`/api/tencent/adMarketingApiClients/importChildrenAccount/${data.adMarketingApiServiceId}/${data.accountId}/${data.pageNum}/${data.pageSize}`)
}

/** 腾讯账户服务商-导入子客账户 */
export function syncChildAccount(params) {
  return service.post(`/api/tencent/adMarketingApiClients/saveImportChildrenAccount`, params)
}

// -------------- 投放账户 ---------------------------
/** 投放账户列表 */
export function getTencentAdvList(params) {
  return service.post('/api/tencent/adMarketingApiClients/pageList', params)
}

/** 保存投放账户 */
export function saveTencentAdv(data) {
  return service.post('/api/tencent/adMarketingApiClients/saveItem', data)
}

/** 编辑投放账户 */
export function updateTencentAdv(data) {
  return service.post('/api/tencent/adMarketingApiClients/updateItem', data)
}
