export const searchForm = {

  projectName: null,
  leaderUserId: null,
  projectVisibleType: null,
  visibleUserIdList: null,
  status: null,
  deleted: null,
  createBy: null,
  createTime: null,
  updateBy: null,
  updateTime: null,
  remark: null
}

export class BasicSettingProjectEnt {
  bsProjectId
  projectName
  leaderUserIdWeb
  leaderUserId
  leaderUserName
  projectVisibleType
  visibleUserIdListWeb
  visibleUserIdList
  visibleUserIdListName
  status
  deleted
  createBy
  createTime
  updateBy
  updateTime
  remark
}

export const formRules = {
  // bsProjectId: [{ required: true, message: '请填写', trigger: 'change' }],
  projectName: [{ required: true, message: '请填写项目名称', trigger: 'change' }]
  // leaderUserId: [{ required: true, message: '请填写项目负责人', trigger: 'change' }],
  // projectVisibleType: [{ required: true, message: '请填写可见状态/ALL:全员/PART:部分', trigger: 'change' }],
  // visibleUserIdList: [{ required: true, message: '请填写可见人员结合', trigger: 'change' }],
  // status: [{ required: true, message: '请填写状态/ISUSEING、INNOTUSEING', trigger: 'change' }],
  // deleted: [{ required: true, message: '请填写逻辑删除', trigger: 'change' }],
  // createBy: [{ required: true, message: '请填写创建者', trigger: 'change' }],
  // createTime: [{ required: true, message: '请填写创建时间', trigger: 'change' }],
  // updateBy: [{ required: true, message: '请填写更新者', trigger: 'change' }],
  // updateTime: [{ required: true, message: '请填写更新时间', trigger: 'change' }],
  // remark: [{ required: true, message: '请填写备注', trigger: 'change' }]
}
