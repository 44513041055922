<!--
 * @Description: 工作区详情
 * @Date: 2021-10-18 16:12:24
 * @LastEditTime: 2022-05-24 15:47:47
-->

<template>
  <section>
    <draggable v-model="finalWidgets" :animation="500" @end="end">
      <transition-group>
        <div v-for="(ele, index) in finalWidgets" :key="ele.widgetTypeV2" :class="{ active: seletedWidgetsIndex === index, widget: true }" @click="setSelectedIndex(index)">
          <el-tooltip effect="light" placement="right-start">
            <template slot="content">
              <i class="el-icon-delete-solid" @click="deleteItem(index)" />
            </template>
            <div>
              <component :is="ele.component" :detail="ele" />
            </div>
          </el-tooltip>
        </div>
      </transition-group>
    </draggable>
  </section>
</template>

<script>
import draggable from 'vuedraggable'
import Img from './workbench_component/img.vue'
import Slider from './workbench_component/slider.vue'
import Text from './workbench_component/text.vue'
import copyGh from './workbench_component/copyGh.vue'
import Applet from './workbench_component/applet.vue'
export default {
  components: {
    draggable,
    'm-img': Img,
    'm-slider': Slider,
    'm-text': Text,
    'm-copyGh': copyGh,
    'm-applet': Applet
  },
  props: {
    // 选中的索引
    finalWidgets: {
      type: Array,
      default: () => []
    },
    // 选中的索引
    seletedWidgetsIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      component: 'm-image'
    }
  },
  methods: {
    /**
     * @description: 设置当前选中的组件
     * @param {number} index 当前选中的索引
     */
    setSelectedIndex(index) {
      this.$emit('setSelectedIndex', index)
    },

    /**
     * @description: 删除一个组件
     * @param {number} index 当前选中的索引
     */
    deleteItem(index) {
      this.finalWidgets.splice(index, 1)
      // 让最后一个组件选中
      let i = this.finalWidgets.length === 0 ? -1 : this.finalWidgets.length - 1
      this.$emit('setSelectedIndex', i)
      this.$emit('setFinalWidgetsCount', this.finalWidgets.length)
    },

    end() {
      this.$emit('updateFinalWidgets', this.finalWidgets)
    }
  }
}
</script>

<style lang="scss" scoped>
.widget {
  border: 1px solid transparent;
}
.active {
  border: 1px solid #409eff;
}
</style>
