var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            width: "548px",
            title: "上传文件",
            visible: _vm.show,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
            close: _vm.handleCancel,
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "fileUploader",
              staticClass: "upload-demo",
              attrs: {
                limit: 1,
                accept: "xlsx",
                drag: "",
                "before-upload": _vm.beforeUpload,
                action: "fakeaction",
                "http-request": _vm.uploadFile,
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("将文件拖到此处，或"),
                _c("em", [_vm._v("点击上传")]),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "import-dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleDownloadTemplate } }, [
                _vm._v("下载模板"),
              ]),
              _c("el-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("取消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }