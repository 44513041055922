<!--
 * @Description: 图片编辑区
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2022-03-25 14:46:30
-->

<template>
  <section>
    <base-box name="topImg">
      <p slot="desc">
        投放朋友圈信息流，广告外层将自动拉取顶部图片。 投放公众号及其他广告位，将不会拉取。
      </p>
      <template slot="body">
        <div>
          <p class="font-13">广告位：</p>
          <div class="ml-20">
            <el-radio v-model="detail.adLocation" label="sns">朋友圈信息流</el-radio>
            <el-radio v-model="detail.adLocation" label="gh">公众号及其他广告位</el-radio>
          </div>
        </div>

        <div v-if="detail.adLocation === 'sns'">
          <p class="font-13">外层样式：</p>
          <div class="ml-20">
            <el-radio v-model="detail.viewType" label="0">常规广告</el-radio>
            <el-radio v-model="detail.viewType" label="1">卡片广告</el-radio>
          </div>
        </div>

        <div class="image-updata">
          <p class="font-13">选择图片：</p>
          <div>
            <div class="update" @click="setMaterialBox">
              <img v-if="detail.pureImageUrl" :src="detail.pureImageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon" />
            </div>
            <div class="tip mt-10">
              <p>暂不支持含有二维码的图片</p>
              <p>图片格式：大小不超过300KB，</p>
              <p v-if="detail.adLocation === 'sns' && detail.viewType === '0'" class="tip">图片尺寸：800 * 800像素 / 640 * 800像素 / 800 * 640像素</p>
              <p v-if="detail.adLocation === 'sns' && detail.viewType === '1'" class="tip">图片尺寸： 800 * 450像素</p>
              <p v-if="detail.adLocation === 'gh'" class="tip">图片尺寸：800 * 800像素 / 640 * 800像素 / 800 * 640像素 / 800 * 450像素</p>
            </div>
          </div>
        </div>
      </template>
    </base-box>
    <material-box
      :visible="materialBox_visible"
      :img-count="1"
      :image-list="imageList"
      name="ldy"
      select-type="multiple"
      :attribute="{
        key: 'topImg',
        value: '顶部图片',
        count: 1,
        size: 300 * 1024,
        listType: 'image/jpg,image/jpeg,image/png',
        width: width,
        height: height
      }"
      @setMaterialBox="setMaterialBox"
      @submit="selectImg"
    />
  </section>
</template>

<script>
import BaseBox from './base.vue'
import MaterialBox from '@/components/materialBox'

export default {
  components: {
    'base-box': BaseBox,
    MaterialBox
  },
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      materialBox_visible: false,
      width: [800, 640, 800],
      height: [800, 800, 640]
    }
  },

  watch: {
    detail: {
      handler(value) {
        if (value.adLocation === 'sns' && value.viewType === '0') {
          this.width = [800, 640, 800]
          this.height = [800, 800, 640]
        }

        if (value.adLocation === 'sns' && value.viewType === '1') {
          this.width = [800]
          this.height = [450]
        }

        if (value.adLocation === 'gh') {
          this.width = [800, 640, 800, 800]
          this.height = [800, 800, 640, 450]
        }

        // 切换状态 置空图片
        if (!this.width.includes(value.initWidth)) {
          value.pureImageUrl = ''
        }
        if (!this.height.includes(value.initHeight)) {
          value.pureImageUrl = ''
        }
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    /**
     * @description: 打开/关闭资源库
     */
    setMaterialBox() {
      this.materialBox_visible = !this.materialBox_visible
    },

    /**
     * @description: 选择图片后调用
     * @param {Array} img 图片信息
     */
    selectImg(img) {
      this.imageList = img
      let { detail } = this
      const { height, width, image } = img[0]
      detail.initHeight = height
      detail.initWidth = width
      detail.pureImageUrl = image
      if (+width === 800 && +height === 800) {
        detail.imageWidth = 750
        detail.imageHeight = 750
      } else if (+width === 800 && +height === 450) {
        detail.imageWidth = 750
        detail.imageHeight = 421
      } else if (+width === 800 && +height === 640) {
        detail.imageWidth = 750
        detail.imageHeight = 600
      } else if (+width === 640 && +height === 800) {
        detail.imageWidth = 750
        detail.imageHeight = 937
      } else {
        detail.imageWidth = 750
        detail.imageHeight = 750
      }
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.update {
  width: 130px !important;
  height: 130px;
  line-height: 130px;
  text-align: center;
  border: 1px solid #edecf2;
  background-color: #fbfafd;
  border-radius: 3px;
  cursor: pointer;
  font-size: 30px;
  color: #7d7d7d;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .avatar {
    width: 130px;
  }
}

.image-updata {
  display: flex !important;
  align-items: flex-start !important;
}

// .setting {
//   display: flex;
//   justify-content: flex-start;
//   margin: 10px 0;
//   align-items: center;
//   & > p {
//     font-size: 14px;
//     color: rgb(78, 78, 78);
//   }
//   & > div {
//     width: 80%;
//     margin-left: 30px;
//   }
// }
</style>
