<!--
 * @Description: 工作区 -> 文本组件
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2022-03-31 10:01:18
-->

<template>
  <section>
    <div v-if="detail.content === ''" class="text">
      <div style="text-align: center">
        <i class="el-icon-tickets"></i>
        <p>{{ detail.name }}组件</p>
      </div>
    </div>
    <div
      v-else
      :style="{
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingTop: `${detail.paddingTop}px`,
        paddingBottom: `${detail.paddingBottom}px`,
        fontSize: `${detail.fontSize}px`,
        textAlign: detail.textAlignment,
        color: detail.fontColor,
        fontWeight: detail.showType
      }"
    >
      <pre style="margin:0;    white-space: pre-line;">
        {{ detail.content }}
      </pre>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 1.5;
}
.text {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: #fff;
  display: flex;
  flex-direction: column;
  i {
    font-size: 60px;
    color: #ccc;
  }
  p {
    margin-top: 10px;
    font-size: 24px;
    color: #ccc;
  }
}
</style>
