<template>
  <div>
    <!-- 列表查询 -->
    <el-form ref="search" :inline="true" :model="search" class="search-form search-form-4" @submit.native.prevent>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="任务名称" prop="taskName">
            <el-input v-model="search.taskName" placeholder="请输入任务名称" style="width: 100%"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="账户ID" prop="accountId">
            <el-input v-model="search.accountId" placeholder="请输入账户ID" style="width: 100%"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="创建人" prop="createBy">
            <el-input v-model="search.createBy" placeholder="请输入创建人" style="width: 100%"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="任务状态" prop="status">
            <el-select v-model="search.status" style="width: 100%">
              <el-option v-for="(item, index) in statusList" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" round @click="handleSearch">查询</el-button>
            <el-button round @click="resetFormQuery">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div style="text-align:left " class="mb-15">
      <router-link :to="{ name: 'createTencentCrowdPackingTask' }" tag="span">
        <el-button type="primary" icon="el-icon-plus" round> 新建任务 </el-button>
      </router-link>
    </div>
    <div>
      <el-table v-loading="list.loading" :data="list.data" stripe class="list-table" style="width: 100%;">
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="taskName" label="任务名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="accountId" label="账户ID"></el-table-column>
        <el-table-column prop="executionCycle" label="执行周期">
          <template slot-scope="{row}">
            <span>{{ row.executionCycle | filterExecutionCycle }}</span>
            <span v-if="row.executionCycle == 2">{{ row.loopCycle | filterLoopCycle }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="dataType" label="文件类型">
          <template slot-scope="{row}">
            <span>{{ row.dataType | filterDataType }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="dataScope" label="数据范围">
          <template slot-scope="{ row }">
            <el-button type="text" @click="handleShowTaskData(row)">共 {{ row.dataScope || 0 }} 个</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="dataConditionStr" label="数据条件"></el-table-column>
        <el-table-column prop="createBy" label="创建人"></el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="{row}">
            <span>{{ row.status | filterStatus }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="currentExecuteTime" label="最近执行时间"></el-table-column>
        <el-table-column prop="nextExecuteTime" label="下次执行时间"></el-table-column>
        <el-table-column prop="" label="操作">
          <template slot-scope="{row}">
            <el-button type="text" @click="handleGoEdit(row.adAdqAudienceTaskId)">
              <el-tooltip content="编辑" placement="top">
                <i class="el-icon-edit" style="font-size: 16px"></i>
              </el-tooltip>
            </el-button>
            <el-button type="text" @click="handleGoDetail(row.adAdqAudienceTaskId)">
              <el-tooltip content="详情" placement="top">
                <i class="el-icon-document" style="font-size: 16px"></i>
              </el-tooltip>
            </el-button>
            <el-button type="text" @click="handleDelete(row.adAdqAudienceTaskId)">
              <el-tooltip content="删除" placement="top">
                <i class="el-icon-delete" style="font-size: 16px;color: red;"></i>
              </el-tooltip>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page">
        <paginations :total="list.total" :page.sync="list.pageNum" :page-sizes="[5, 10, 15, 20]"
          :limit.sync="list.pageSize" @pagination="getPage" />
      </div>
    </div>

    <el-dialog title="任务数据范围" :visible.sync="taskData.show" width="800px" :modal-append-to-body="false" destroy-on-close @close="handleCloseDialog">
      <el-form ref="form" :inline="true" label-position="right">
        <el-form-item label="APPID" prop="appid">
          <el-input v-model="taskData.appid" placeholder="请输入APPID"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearchTask">检索</el-button>
        </el-form-item>
      </el-form>
      <div class="task-total">共 <span>{{ taskData.total }}</span> 个</div>
      <el-table v-loading="taskData.loading" :data="taskData.data" stripe class="list-table" style="width: 100%;">
        <el-empty slot="empty" description="暂无数据"></el-empty>
        <el-table-column prop="name" label="名称"> </el-table-column>
        <el-table-column prop="openId" label="APPID" width="180px"></el-table-column>
        <el-table-column prop="unionId" label="企微主体">
          <template slot-scope="{row}">{{ +taskData.dataType === 2 ? row.unionId : '-' }}</template>
        </el-table-column>
        <el-table-column prop="mdmId" label="主体ID">
          <template slot-scope="{row}">{{ +taskData.dataType === 2 ? row.mdmId : '-' }}</template>
        </el-table-column>
      </el-table>
      <div class="page">
        <paginations :total="taskData.total" :page.sync="taskData.pageNum" :page-sizes="[5, 10, 15, 20]"
          :limit.sync="taskData.pageSize" @pagination="getTaskData" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { dataConditions } from './util'
import { getCrowdPackList, deleteCrowdPackTask, getDataRangeList } from '@/network/api/assetManagement/assetManageTencent'
// import { getDateStartEnd } from '@/utils/ruoyi'
export default {
  name: 'CrowdManagement',
  filters: {
    filterExecutionCycle(value) {
      return +value === 1 ? '单次' : '循环'
    },
    filterLoopCycle(value) {
      const obj = { 1: '每日', 2: '每周', 3: '每月' }
      return '-' + obj[+value] || ''
    },
    filterDataType(value) {
      const obj = { 1: '微信openid', 2: '企微unionid' }
      return obj[+value] || '-'
    },
    filterStatus(value) {
      const status = {
        2: '创建中',
        3: '待执行',
        4: '待下次执行',
        5: '执行中',
        6: '已完成'
      }
      return status[+value] || '-'
    }
  },
  data() {
    return {
      dataConditions,
      statusList: [
        { id: 1, name: '全部' },
        { id: 2, name: '创建中' },
        { id: 3, name: '待执行' },
        { id: 4, name: '待下次执行' },
        { id: 5, name: '执行中' },
        { id: 6, name: '已完成' }
      ],
      search: {
        taskName: '',
        accountId: '',
        createBy: '',
        status: 1
      },
      list: {
        loading: false,
        data: [],
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      taskData: {
        show: false,
        loading: false,
        adAdqAudienceTaskId: '',
        appid: '',
        dataType: '',
        data: [],
        pageSize: 10,
        pageNum: 1,
        total: 0
      }
    }
  },
  mounted() {
    this.getPage()
  },
  methods: {
    /**
     * @description: 重置数据
     * @param {*} formName ref
    */

    resetFormQuery() {
      this.$refs.search.resetFields()
      this.getPage()
    },

    // 点击搜索
    handleSearch() {
      this.getPage()
    },

    // 获取列表
    getPage() {
      let search = {
        ...this.search,
        pageSize: this.list.pageSize,
        pageNum: this.list.pageNum
      }
      delete search.createTime
      this.list.loading = true
      getCrowdPackList(search).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          records.map(item => {
            let obj = this.dataConditions[+item.dataType].find(v => v.id === +item.dataCondition)
            item.dataConditionStr = obj.value || '-'
            return item
          })
          this.list.data = records
          this.list.total = total
          this.list.loading = false
        }
      }).catch(e => {
        this.list.loading = false
      })
    },

    // 获取任务范围数据
    getTaskData() {
      let search = {
        adAdqAudienceTaskId: this.taskData.adAdqAudienceTaskId,
        openId: this.taskData.appid,
        pageSize: this.taskData.pageSize,
        pageNum: this.taskData.pageNum
      }
      this.taskData.loading = true
      getDataRangeList(search).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          this.taskData.data = records
          this.taskData.total = total
          this.taskData.loading = false
        }
      }).catch(e => {
        this.taskData.loading = false
      })
    },

    // 点击删除
    handleDelete(id) {
      this.$confirm('删除后任务将不在执行！', '确定要删除该任务吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { code } = await deleteCrowdPackTask(id)
        if (code === 200) {
          this.getPage()
          this.$message.success('删除成功!')
        }
      }).catch(() => {
      })
    },

    // 点击编辑
    handleGoEdit(id) {
      this.$router.push({
        name: 'editTencentCrowdPackingTask',
        params: { id: id }
      })
    },

    // 点击详情
    handleGoDetail(id) {
      this.$router.push({
        name: 'tencentCrowdPackingTaskDetail',
        params: { id: id }
      })
    },

    // ------------------- 任务数据范围  ----------------------------
    // 展示数据范围
    handleShowTaskData(row) {
      // if (!row.dataScope) {
      //   return
      // }
      this.taskData.adAdqAudienceTaskId = row.adAdqAudienceTaskId
      this.taskData.dataType = row.dataType
      this.getTaskData()
      this.taskData.show = true
    },

    // 点击搜索数据范围
    handleSearchTask() {
      this.getTaskData()
    },

    handleCloseDialog() {
      this.taskData = {
        show: false,
        loading: false,
        adAdqAudienceTaskId: '',
        appid: '',
        dataType: '',
        data: [],
        pageSize: 10,
        pageNum: 1,
        total: 0
      }
    }
  }
}
</script>

<style scoped lang="scss">
.task-total {
  font-size: 14px;
  text-align: right;
  color: #999;

  span {
    color: #409EFF;
  }
}
</style>
