<template>
  <el-dialog title="导入已有计划" width="60%" top="100px" :visible.sync="visible" :before-close="close" label-position="right" :modal="false" :modal-append-to-body="false" :close-on-press-escape="false" :close-on-click-modal="false">
    <!-- <div class="table-container">
      <el-table v-loading="loading" stripe class="list-table" :data="data">
        <c-data-empty slot="empty" />
        <el-table-column type="index" label="序号" align="center" :show-overflow-tooltip="true" />
        <el-table-column prop="baiduAdvPlanId" label="计划ID" align="center" :show-overflow-tooltip="true" />
        <el-table-column prop="campaignFeedName" label="计划名称" align="center" :show-overflow-tooltip="true" />
      </el-table>
    </div>
    <c-pagination
      :page-size.sync="pageInfo.pageSize"
      :page-no.sync="pageInfo.pageNum"
      :total="pageInfo.total"
      :get-data-fun="loadData"
    /> -->
    <!-- 列表查询 -->
    <el-form ref="search" style="width:100%" :inline="true" :model="search">
      <el-form-item label="账户管家" prop="type">
        <el-select v-model="search.housekeeperId" filterable placeholder="账户管家" @change="selectChange">
          <el-option v-for="item in houseKeeper" :key="item.housekeeperId" :label="item.launchAccountName" :value="item.housekeeperId" />
        </el-select>
      </el-form-item>

      <el-form-item label="投放账户" prop="type">
        <el-select v-model="search.baiduId" filterable placeholder="投放账户">
          <el-option v-for="item in baiduAdv" :key="item.baiduId" :label="item.accountName" :value="item.baiduId" />
        </el-select>
      </el-form-item>

      <el-form-item label="计划名称" prop="nickname">
        <el-input v-model="search.nickname" placeholder="请输入计划名称"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" round @click="getPlan()">查询</el-button>
        <el-button type="primary" round @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <TablePane :data-source="dataSource" @getPage="getPlan"> </TablePane>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="importPlan">导入</el-button>
      <el-button @click="visible = false">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { Page } from '@/network/api/advManagement/advManagementBaiduPlan'
import { getHouseKeeper } from '@/network/api/account/accountBaiduHousekeeper'
import { getAccountByHouseId } from '@/network/api/account/accountBaiduAdv'
import { PageCommonComponent } from '@/common/mixin'
import TablePane from '@/components/tablePane.vue'
export default {
  components: {
    TablePane
  },
  mixins: [PageCommonComponent],
  data() {
    return {
      visible: false,
      loading: false,
      data: [],
      bgtctltypeList: [],
      planPauseList: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      search: {
        housekeeperId: null,
        baiduId: '',
        rule_name: ''
      },
      dataSource: {
        data: [], // 表格数据
        cols: [
          {
            label: '计划ID',
            render: function(record) {
              return (
                // eslint-disable-next-line
                <p class="text-ellipsis" style="width:130px">
                  {record.rule_name}
                </p>
              )
            }
          },
          {
            label: '计划名称',
            prop: 'rule_name'
          },
          {
            label: '账户管家',
            render: function(record) {
              return record.type === 'FIXED' ? '固定' : '自定义'
            }
          },
          {
            label: '投放账户',
            render: function(record) {
              return record.type === 'FIXED' ? '固定' : '自定义'
            }
          }
        ], // 表格的列数据
        isIndex: true, // 列表序号
        loading: false, // loading
        isOperation: false,
        isSelection: true,
        handleSelectionChange: (val) => {}, // 点击行选中多选返回选中数组
        pageData: {
          total: 0 // 总条数
        }
      },
      houseKeeper: [], // 账户管家
      baiduAdv: [] // 投放账户
    }
  },
  mounted() {
    this.loadData()
    // this.getDicts('baidu_plan_bgtctltype').then(response => {
    //   this.bgtctltypeList = response.data
    // })
    // this.getDicts('baidu_plan_pause').then(response => {
    //   this.planPauseList = response.data
    // })
  },
  methods: {
    /**
     * @description: 获取计划列表
     * @param {*} params
     */
    getPlan(params = { pageNum: 1, pageSize: 10 }) {
      this.dataSource.loading = true
      // ...this.search,
      Page({ ...params }).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          this.dataSource.data = records
          this.dataSource.pageData.total = total
          this.dataSource.loading = false
        }
      })
    },
    /**
     * @description: 重置
     */
    resetForm() {
      this.$refs['search'].resetFields()
      this.getPlan()
    },

    /**
     * @description: 获取账户管家
     */
    getHk() {
      getHouseKeeper().then((res) => {
        if (res.code === 200) {
          this.houseKeeper = res.data
        }
      })
    },

    /**
     * @description: 选择账户管家获取投放账户
     * @param {*} val 账户管家Id
     */
    selectChange(val) {
      this.baiduAdv = []
      getAccountByHouseId(val).then((res) => {
        if (res.code === 200) {
          this.baiduAdv = res.data
        }
      })
    },

    /**
     * @description: 导入计划
     */
    importPlan() {
      //  handleSelectionChange 里可以获取每条数据信息
    },
    // formatterBudget(row, column, cellValue, index) {
    //   if (cellValue === null || cellValue === undefined) {
    //     return '无限'
    //   } else {
    //     return cellValue
    //   }
    // },
    // formatterbgtctltype(row, column, cellValue, index) {
    //   var rtstr = '-'
    //   this.bgtctltypeList.forEach(item => {
    //     if (item.dictValue === cellValue + '') {
    //       rtstr = item.dictLabel
    //     }
    //   })
    //   return rtstr
    // },
    close() {
      this.visible = false
      // this.$nextTick(() => {
      //   this.$refs.ent.resetFields()
      // })
    },
    loadData(isFirst = false) {
      const searchForm = {}
      this.doLoadData(isFirst, searchForm)
    },
    doLoadData(firstPage = false, searchForm) {
      firstPage && (this.pageInfo.pageNum = 1)
      const req = { ...searchForm, ...this.pageInfo }
      this.loading = true
      Page(req).then((res) => {
        this.data = res.data.records
        this.pageInfo.total = res.data.total
        this.loading = false
      })
    },
    show() {
      this.visible = true
      this.getHk()
      this.getPlan()
      this.loadData(false)
    }
  }
}
</script>

<style></style>
