<template>
  <div>
    <el-form ref="search" :inline="true" :model="search" class="search-form search-form-4" @submit.native.prevent>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="账号主体" prop="subjectName">
            <el-input v-model="search.subjectName" placeholder="请输入账号名称" style="width: 100%"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" round @click="loadData">查询</el-button>
            <el-button round @click="resetFormQuery('search')">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div class="justify-sb mb-15">
      <!-- <el-dropdown @command="handleCommand">
        <el-button type="primary"> 批量操作<i class="el-icon-arrow-down el-icon--right"></i> </el-button>
        <el-dropdown-menu slot="dropdown">

          <el-dropdown-item command="0" :disabled="selectRow.length < 2 || !selectRow.every((item) => item.relationProjectId === selectRow[0].relationProjectId)">修改项目</el-dropdown-item>
          <el-dropdown-item command="1" :disabled="selectRow.length < 2 || !selectRow.every((item) => item.relationProjectId === selectRow[0].relationProjectId)">修改产品</el-dropdown-item>
          <el-dropdown-item command="2" :disabled="selectRow.length < 2 || !selectRow.every((item) => item.relationProjectId === selectRow[0].relationProjectId)">修改人员</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
      <div>
        <el-button class="ml-20" type="primary" round @click="clickE('add')">新增账号</el-button>
        <!-- <el-button class="ml-20" type="primary" round @click="visible = true">同步投放账号</el-button> -->
      </div>
    </div>
    <!-- <el-dialog title="选择账户主体" width="460px" :visible.sync="showAddVisible" :modal-append-to-body="false">
      <span>
        <label class="ml-20">账户主体: </label>
        <el-select v-model="appId" style="width:300px" placeholder="选择账户主体">
          <el-option v-for="item in subject" :key="item.appId" :label="item.subjectName" :value="item.appId" />
        </el-select>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showAddSubmit">确定</el-button>
      </span>
    </el-dialog> -->
    <div class="table-container">
      <TablePane :data-source="dataSource" @getPage="doLoadData"> </TablePane>
    </div>
    <!-- 编辑账户 -->
    <el-dialog :title="titleDialog" :visible.sync="edit_visible" width="620px" :modal-append-to-body="false" destroy-on-close @open="showModal" @close="resetForm('ruleForm')">
      <el-form ref="ruleForm" :model="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="登录账号" prop="devAccount" :rules="{ required: true, message: '请输入管理账号', trigger: 'change' }">
          <el-input v-model="ruleForm.devAccount" placeholder="请输入管理账号" maxlength="100" show-word-limit style="width: 300px;"></el-input>
        </el-form-item>
        <!-- <el-form-item label="登录密码" prop="password" :rules="{ required: true, message: '请输入登录密码', trigger: 'change' }">
          <el-input v-model="ruleForm.password" placeholder="请输入登录密码" maxlength="100" show-word-limit style="width: 300px;"></el-input>
        </el-form-item> -->
        <el-form-item label="账号主体" prop="subjectName" :rules="{ required: true, message: '请输入账号主体', trigger: 'change' }">
          <el-input v-model="ruleForm.subjectName" placeholder="请输入账号主体" maxlength="100" show-word-limit style="width: 300px;"></el-input>
        </el-form-item>
        <!-- <el-form-item label="AppId" prop="appId" :rules="{ required: true, message: '请输入AppId', trigger: 'change' }">
          <el-input v-model="ruleForm.appId" placeholder="请输入AppId" maxlength="100" show-word-limit style="width: 300px;"></el-input>
        </el-form-item>
        <el-form-item label="secret" prop="secret" :rules="{ required: true, message: '请输入secret', trigger: 'change' }">
          <el-input v-model="ruleForm.secret" placeholder="请输入secret" maxlength="100" show-word-limit style="width: 300px;"></el-input>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('ruleForm')">确定 </el-button>
        <el-button @click="resetForm('ruleForm')">取消</el-button>
      </span>
    </el-dialog>

    <OptionDialog :visible="visible" :row-account-id="rowAccountId" :subject-id="subjectId" @setVisible="(value) => (visible = value)" />
  </div>
</template>

<script>
import { PageCommonComponent } from '@/common/mixin'
import TablePane from '@/components/tablePane.vue' // authorize adMarketingApiClientsSelect
import AccountBaidu from '@/mixin/account_baidu'
import { Page, update, save } from '@/network/api/account/kuaishouHouseKeeper'

import OptionDialog from './OptionDialog'
import { getUser } from '@/network/api/user'

export default {
  name: 'AccountHugeHousekeeper',
  components: { OptionDialog, TablePane },
  mixins: [PageCommonComponent, AccountBaidu],
  provide: function() {
    return {
      loadData: this.loadData
    }
  },

  data() {
    return {
      subjectId: null,
      selectRow: [], // 选中的列
      editInfo_visible: false,
      commandType: '0',
      // 编辑账户start
      edit_visible: false,
      userList: [], // 用户list
      ruleForm_id: 0,
      search: {
        onlinkUserName: null,
        relationProjectId: null,
        relationProductId: null,
        launchUserName: null
      },
      titleDialog: '新增',
      ruleForm: {
        subjectId: null,
        devAccount: null,
        subjectName: null,

        relationProjectId: 0,
        relationProductId: null,
        putInStatus: null,
        launch: null
      },
      ruleForm2: {
        relationProjectId: null,
        relationProductId: 0,
        launch: null
      },
      // 编辑账户end
      plan_visible: false,
      ruleForm3: {
        planId: '',
        accountType: 1
      },
      appId: null,
      showAddVisible: false,
      loading: false,
      visible: false,
      rowAccountId: null,
      data: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      dataSource: {
        data: [],
        cols: [
          {
            label: '管理账号',
            prop: 'devAccount'
          },
          {
            label: '账号主体',
            prop: 'subjectName'
          }
          // {
          //   label: 'AppId',
          //   prop: 'appId'
          // },
          // {
          //   label: 'secret',
          //   prop: 'secret'
          // },
          // {
          //   label: '密码',
          //   render: (recode) => {
          //     var ar = recode.password.split('')
          //     var returnData = ''
          //     for (let i = 0; i < ar.length; i++) {
          //       if (i === 0) {
          //         returnData = ar[i]
          //       } else if (i === ar.length - 1) {
          //         returnData = returnData + ar[i]
          //       } else {
          //         returnData = returnData + '*'
          //       }
          //     }
          //     return returnData
          //   }
          // }
        ],
        isIndex: true, // 列表序号
        loading: false, // loading
        isOperation: true,
        isSelection: true,
        pageData: {
          total: 0 // 总条数
        },
        handleSelectionChange: (selection) => {
          this.selectRow = selection
        },
        operation: {
          // 表格有操作列时设置
          // 表格有操作列时设置
          label: '操作', // 列名
          width: 100, // 根据实际情况给宽度
          fixed: 'right',
          data: [
            {
              icon: 'el-icon-edit',
              tooltip: '编辑',
              handleRow: (index, row) => {
                this.ruleForm_id = row.accountLocalId
                this.selectProjectId = +row.relationProjectId
                // this.selectProductId = +row.relationProductId
                this.ruleForm = {

                  devAccount: row.devAccount,
                  subjectId: row.subjectId,
                  subjectName: row.subjectName
                }
                this.titleDialog = '编辑'
                this.edit_visible = true
              }
            },
            {
              icon: 'el-icon-download',
              tooltip: '同步投放账户',
              handleRow: (index, row) => {
                this.visible = true
                this.subjectId = row.subjectId
              }
            }
          ]
        }
      }
    }
  },
  mounted() {
    this.loadData()

    getUser().then((res) => {
      if (res.code === 200) {
        this.titleDialog = '新增'
        this.userList = res.data
      }
    })
  },

  methods: {
    clickE(val) {
      if (val === 'add') {
        this.ruleForm.subjectId = null
        this.edit_visible = true
      }
    },

    loadData() {
      const searchForm = this.search
      this.doLoadData(searchForm)
    },
    doLoadData(searchForm) {
      const req = { pageNum: 1, pageSize: 10, ...searchForm }
      this.dataSource.loading = true
      Page(req).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          this.dataSource.data = records
          this.dataSource_data = records
          this.dataSource.pageData.total = total
          this.dataSource.pageData.pageNum = req.pageNum
          this.dataSource.loading = false
        }
      })
    },

    handleCommand(value) {
      this.selectProjectId = this.selectRow[0].relationProjectId
      this.ruleForm2.relationProductId = this.selectRow[0].relationProductId
      this.ruleForm2.launch = `${this.selectRow[0].launchUserId}+${this.selectRow[0].launchUserName}`
      this.commandType = value
      this.editInfo_visible = true
    },

    /**
     * @description: 提交数据
     * @param {*} formName ref
     */
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (formName === 'ruleForm') {
            let { subjectId, devAccount, subjectName } = this.ruleForm
            let params = {
              subjectId,
              devAccount,
              subjectName
            }
            if (this.edit_visible) {
              if (subjectId === null) {
                save(params).then((res) => {
                  if (res && res.code === 200) {
                    this.editInfo_visible = false
                    this.selectRow = []
                    this.$message.success('新增成功!')
                    this.resetFormQuery(formName)
                    this.edit_visible = false
                    this.id = 0
                    this.loadData()
                  }
                })
              } else {
                update(params).then((res) => {
                  if (res && res.code === 200) {
                    this.editInfo_visible = false
                    this.selectRow = []
                    this.$message.success('修改成功!')
                    this.resetFormQuery(formName)
                    this.edit_visible = false
                    this.id = 0
                    this.loadData()
                  }
                })
              }
            }
          }
        }
      })
    },

    /**
     * @description: 重置数据
     * @param {*} formName ref
     */
    resetForm(formName) {
      this.edit_visible = false
      this.editInfo_visible = false
      this.plan_visible = false
      this.$nextTick(() => {
        this.$refs[formName].resetFields()
      })
    },

    resetFormQuery(formName) {
      this.productListed = []
      this.edit_visible = false
      this.editInfo_visible = false
      this.plan_visible = false
      this.selectProjectId = ''
      this.$nextTick(() => {
        this.$refs[formName].resetFields()
        this.loadData()
      })
    },

    // 同步账号
    getOauthFun() {
      this.visible = true
    }
  }
}
</script>

<style scoped></style>
