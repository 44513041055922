<!--
 * @Description: 推广计划模板选择
 * @Date: 2021-11-09 09:32:28
 * @LastEditTime: 2022-02-21 09:45:20
-->
<template>
  <div>
    <Base title="基础信息">
    <el-form :inline="true" :model="ruleData" class="demo-form" label-width="100px">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="任务名称" style="width: 100%">
            <el-input v-model="ruleData.taskName" placeholder="请输入任务名称" style="width: 100%"
              @change="changeTaskName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="项目" style="width: 100%">
            <el-select v-model="ruleData.projectId" style="width: 100%" @change="changeProject">
              <el-option v-for="(item, index) in projectList" :key="index" :label="item.projectName"
                :value="item.bsProjectId"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="产品" style="width: 100%">
            <el-select v-model="ruleData.productId" style="width: 100%" @change="changeProduct">
              <el-option label="不限" :value="-1"></el-option>
              <el-option v-for="(item, index) in productListed" :key="index" :label="item.productName"
                :value="item.bsProductId"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item prop="promotedObjectType" label="推广目标" style="width: 100%">
            <el-select v-model="ruleData.promotedObjectType" placeholder="请输入推广目标" style="width: 100%"
              @change="changeTaskName">
              <el-option label="收集销售线索" value="PROMOTED_OBJECT_TYPE_LEAD_AD"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="投放账户" style="width: 100%">
            <el-select v-model="ruleData.accountId" multiple collapse-tags style="width: 100%" filterable remote
              placeholder="请输入名称/ID检索" :remote-method="remoteMethod" :loading="loading" @change="changeAccount">
              <el-option v-for="(item, index) in AccountUin_Options" :key="index"
                :label="`${item.corporationName}-${item.accountId}`" :value="item.accountId"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="广告版位" style="width: 100%">
            <!-- <el-select v-model="ruleData.taskSiteSet" style="width: 100%" placeholder="请选择广告版位" @change="changeTaskName">
                    <el-option v-for="item in adSiteSetOption" :key="item.value" :value="item.value" :label="item.label"></el-option>
                  </el-select> -->
            <el-input v-model="showSiteSet" placeholder="请选择广告版位" :disabled="true" style="width: 100%"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item style="width: 100%">
            <el-button type="primary" @click="taskSiteSetShow">选择广告版位</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    </Base>
    <!-- 配置区 -->
    <Deploy ref="deployRef" :account-baidu-adv="accountBaiduAdv" :task-id="taskId" :account-id="ruleData.accountId"
      :project-id="ruleData.projectId" :is-update-task="isUpdateTask" @setAccount="setAccount"
      @getShowPreviewFun="getShowPreviewFun" @saveTaskFun="saveTaskFun" />

    <!-- 预览区 -->
    <Base title="预览区" :desc="taskId ? '配置信息变更后，预览区清空重置' : ''">
    <div v-if="previews.length" class="preview-scroll-content">
      <div v-for="(item, index) in previews" :key="index" class="preview-account-item">
        <div class="table-head">
          <div class="account">{{ item.accountLists.taaccountId }}</div>
          <div>
            广告数量：<el-button type="text">{{ item.adNum }}</el-button>
          </div>
        </div>

        <el-table v-loading="item.loading" :data="item.groupList" v-el-table-infinite-scroll="item.loadMore"
          :infinite-scroll-disabled="item.disabled" :infinite-scroll-immediate="false" infinite-scroll-distance="50px"
          style="width: 100%" height="400" border class="table-weChat">
          <el-table-column label="推广计划" width="300" align="center">
            <el-table-column label="推广计划信息" width="250" align="center">
              <template slot-scope="{ row }">
                <p>{{ row.campaignName }}</p>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="广告" align="center">
            <el-table-column label="广告名称" width="250" align="center">
              <template slot-scope="{ row, $index }">
                <span style="color: #409eff">{{ row.stAdName }}</span>
                <i class="el-icon-edit icon-edit"
                  @click="onClickEditInfo(4, row.taskGenerateId, row.stAdName, index, $index)"></i>
              </template>
            </el-table-column>
            <el-table-column label="出价" width="150" align="center">
              <template slot-scope="{ row, $index }">
                <span style="color: #409eff">{{ row.stAdBid ? row.stAdBid / 100 : 0 }}元</span>
                <i class="el-icon-edit icon-edit"
                  @click="onClickEditInfo(5, row.taskGenerateId, row.stAdBid ? row.stAdBid / 100 : 0, index, $index)"></i>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="广告创意" align="center">
            <el-table-column label="创意素材" align="center">
              <template slot-scope="{row}">
                <div :style="{ 'display': row.videoUrls ? 'flex' : 'block', 'justify-content': 'center' }">
                  <div v-if="(row.videoUrls && row.videoUrls.length)" class="preview-material-video">
                    <el-popover v-for="(video, vi) in row.videoUrls" :key="vi" placement="right" trigger="hover">
                      <div class="element-pop-img">
                        <video :src="video" controls autoplay="autoplay" loop="-1" muted="false"></video>
                      </div>
                      <div slot="reference" class="element-img">
                        <video :src="video"></video>
                      </div>
                    </el-popover>
                  </div>
                  <div v-if="ruleData.taskSiteSet !== 'a' && row.videoUrls && !row.imgUrls" class="preview-material-video">
                    <el-popover placement="right" trigger="hover">
                      <div class="element-pop-img">
                        <video :src="row.videoUrls[0]"></video>
                      </div>
                      <div slot="reference" class="element-img">
                        <video :src="row.videoUrls[0]"></video>
                      </div>
                    </el-popover>
                  </div>
                  <div v-else-if="(row.imgUrls && row.imgUrls.length)" class="preview-material-image">
                    <el-popover v-for="(image, mi) in row.imgUrls" :key="mi" placement="right" trigger="hover">
                      <div class="element-pop-img">
                        <el-image :src="image" fit="contain"></el-image>
                      </div>
                      <div slot="reference" class="element-img" :class="'element-img-group-' + row.imgUrls.length">
                        <el-image :src="image" fit="contain" lazy></el-image>
                      </div>
                    </el-popover>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="创意文案" width="250" align="center">
              <template slot-scope="{ row }">
                <span>{{ row.text }}</span>
              </template>
            </el-table-column>
            <el-table-column label="落地页" width="300" align="center">
              <template slot-scope="{ row }">
                <div v-if="row.pageType === 'ONLINE'">
                  <p>{{ row.pageName }}</p>
                  <el-popover placement="top" trigger="click" @show="handlePreviewPage(row)" @hide="hidePopover(row)">
                    <div class="qrcode-box" :id="('set_page_qrcode' + row.taskGenerateId)">
                      <span class="qrcode-loading">加载中...</span>
                    </div>
                    <div class="qrcode-tips" style="text-align:center">请使用微信扫码</div>
                    <div slot="reference">
                      <el-button type="text">预览</el-button>
                    </div>
                  </el-popover>
                </div>
                <div v-else>
                  <p>{{ row.pageName }}</p>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>

      </div>
    </div>
    <template slot="footer">
      <div class="justify-sb" style="justify-content: center;margin-bottom: 100px">
        <el-button v-if="previews.length" type="primary" @click="startTask">开始新任务</el-button>
      </div>
    </template>
    </Base>

    <!-- 编辑信息弹框 -->
    <c-edit-info-dialog ref="editInfo" @confirm="handleEditInfo" />
    <task-site-set-dialog ref="siteSet" @confirm="setSiteSet" />
  </div>
</template>

<script>
import Base from '../../../advManagement/baidu/baiduBatch/component/base.vue'
import accountUin_tencent from '@/mixin/accountUin_tencent.js'
import { saveTask, publishTask, getAllSiteSet, showPreviewAccountList, showLandingPage, setAdgroupName, setBidAmount } from '@/network/api/advManagement/advManagementTencent'
import Deploy from './deploy.vue'
import EditInfoDialog from './component/editInfoDialog.vue'
import { adSiteSetOption, wechatOptions, tencentOptions } from './util'
import moment from 'moment'
import TaskSiteSetDialog from './component/taskSiteSetDialog.vue'
import elTableInfiniteScroll from 'el-table-infinite-scroll'
import QRCode from 'qrcodejs2'

export default {
  components: {
    Base,
    Deploy,
    'c-edit-info-dialog': EditInfoDialog,
    TaskSiteSetDialog
  },
  directives: {
    'el-table-infinite-scroll': elTableInfiniteScroll
  },
  mixins: [accountUin_tencent],
  data() {
    return {
      adSiteSetOption,
      taskId: 0, // 任务Id
      taskStatus: null, // 任务状态
      show_ad_button_flag: false,
      isUpdateTask: false,
      ruleData: {
        // 后期修改
        taskId: this.$route.params.taskId || null,
        taskName: `${this.$route.params.username || '任务'}-${moment().format('YYYYMMDDHHmmss')}`,
        projectId: '',
        productId: -1,
        accountId: [], // 投放账户
        promotedObjectType: 'PROMOTED_OBJECT_TYPE_LEAD_AD', // [] // 推广目标
        taskSiteSet: '',
        accountSaveTaskRequestList: [],
        taskRule: '',
        taskCombine: 1,
        configuredStatus: 'AD_STATUS_SUSPEND'
      },
      accountBaiduAdv: [], // 选中的账号
      selectOption: [], // 存储选中账号
      pushLoading: false,
      // tableLoading: false,
      // 表格数据
      // dataSource: {
      //   data: [],
      //   // selectedData: [],
      //   isIndex: true, // 列表序号
      //   loading: false, // loading
      //   isOperation: false,
      //   isBorder: true,
      //   handleSelectionChange() { },
      //   select() { },
      //   selectable() { },
      //   selectAll() { },
      //   pageData: {
      //     total: 0, // 总条数
      //     pageSize: 10,
      //     layout: 'total, prev, pager, next, jumper'
      //   }
      // },
      previews: [],
      pageSize: 5,
      groupIndex: 0,
      rowIndex: 0,

      wechatSiteOptions: [],
      tencentSiteOptions: [],
      showSiteSet: ''
    }
  },

  mounted() {
    this.publishAll()
    this.getAllSiteSetFun()
  },

  methods: {
    /**
     * @description 编辑获取数据
     */
    publishAll() {
      let { taskId } = this.$route.params
      if (taskId) {
        this.taskId = +taskId
        this.$refs['deployRef'].isRuleDeploy = true
        this.$refs['deployRef'].byTaskIdGetAllFun(taskId)
      }
    },

    async getAllSiteSetFun() {
      const { code, data } = await getAllSiteSet()
      if (code === 200) {
        this.wechatSiteOptions = data['微信公众号与小程序']
        this.tencentSiteOptions = data['腾讯平台与内容媒体']
      }
    },

    /**
     * @description 批量提交
     */
    async startTask() {
      const { code } = await publishTask(this.taskId)
      if (code === 200) {
        this.$message.success('已提交!')
        this.startNewTask()
        this.$router.push('/tencentAmountAdBatch')
      }
    },

    setAccount({ adAdqTask, accountLists }) {
      const { taskName, projectId, productId, promotedObjectType, taskSiteSet, taskCombine, configuredStatus } = adAdqTask
      this.showSiteSetFun(taskSiteSet)
      this.accountBaiduAdv = []
      this.AccountUin_Options = []
      this.selectOption = []
      this.ruleData = {
        // 后期修改
        taskId: this.taskId,
        taskName,
        projectId,
        productId,
        accountId: accountLists.map((item) => item.taaccountId.toString()), // 投放账户
        promotedObjectType, // [] // 推广目标
        taskSiteSet: taskSiteSet,
        taskCombine: taskCombine,
        configuredStatus: configuredStatus,
        accountSaveTaskRequestList: []
      }
      this.selectProjectId = projectId.toString()
      this.selectProductId = productId
      accountLists.map((item) => {
        this.accountBaiduAdv.push({
          _id: item.taskAccountId,
          localAccountId: item.talocalAccountId,
          _name: item.tacorporationName,
          taaccountId: item.taaccountId
        })
        this.AccountUin_Options.push({
          corporationName: item.tacorporationName,
          accountId: item.taaccountId.toString(),
          id: item.talocalAccountId
        })
        this.selectOption.push({
          corporationName: item.tacorporationName,
          accountId: item.taaccountId.toString(),
          id: item.talocalAccountId
        })
        this.ruleData.accountSaveTaskRequestList.push({
          tALocalAccountId: item.talocalAccountId,
          tAAccountId: item.taaccountId
          // taaAccCorporationName: account.corporationName
        })
      })
    },

    showAdButton(flag) {
      this.show_ad_button_flag = flag
    },

    async getPage({ pageNum, pageSize }) {
      this.$refs['tableRef'].showPreviewFun({ pageNum, pageSize })
    },

    /**
     * @description 生成taskId
     */
    saveTaskFun(value) {
      const { projectId, accountId, promotedObjectType, taskSiteSet, taskName } = this.ruleData
      if (projectId === '' || accountId.length === 0 || promotedObjectType === '' || !taskSiteSet || taskSiteSet === '' || taskName === '') {
        this.$refs['deployRef'].isRuleDeploy = false
        return this.$message.info('请先选择基础信息')
      }

      if (value !== 'ruleDeployVisible' && !this.$refs['deployRef'].isRuleDeploy) {
        return this.$message.info('请先配置规则')
      }

      if (value === 'ruleDeployVisible') {
        if (!this.$refs['deployRef'].isRuleDeploy) {
          this.$refs['deployRef'][value] = true
          return
        } else {
          this.ruleData.taskRule = this.$refs['deployRef'].taskRule
          this.ruleData.taskCombine = this.$refs['deployRef'].taskCombine
          this.ruleData.configuredStatus = this.$refs['deployRef'].configuredStatus
        }
      }

      if (this.taskId && !this.isUpdateTask) {
        this.$refs['deployRef'][value] = true
      } else {
        saveTask(this.ruleData).then((res) => {
          if (res.code === 200) {
            this.taskId = +res.data.taskId
            this.isUpdateTask = false
            this.$refs['deployRef'][value] = false
            this.$refs['deployRef'].byTaskIdGetAllFun(this.taskId)
          }
        })
      }
    },

    // 更新任务基础信息
    updateTaskFun() {
      if (!this.isUpdateTask) {
        return
      }
      saveTask(this.ruleData).then((res) => {
        if (res.code === 200) {
          this.taskId = +res.data.taskId
          this.isUpdateTask = false
          this.$refs['deployRef'].byTaskIdGetAllFun(this.taskId)
        }
      })
    },

    /**
     * @description 设置TaskId
     */
    setTaskId(value) {
      this.taskId = value
    },

    getShowPreviewFun(data) {
      this.groupIndex = 0
      this.rowIndex = 0
      this.previews = []
      if (!data) {
        return
      }
      this.taskStatus = data.adAdqTask.taskStatus
      this.$refs['deployRef'].taskStatus = this.taskStatus
      let arr = data.previewAreaDataItemLists || []
      let _this = this
      arr.map((v, i) => {
        v.groupList = []
        v.total = 0
        v.pageNum = 0
        v.disabled = false
        v.loading = false
        v.loadMore = function () {
          _this.loadMoreList(v, i)
        }
        return v
      })
      this.$nextTick(() => {
        this.previews = arr
      })

      // if (!data) {
      //   this.dataSource.data = []
      //   return
      // }
      // this.dataSource.data = []
      // let adAdqTaskAssets = []
      // if (data.adAdqTaskAssets) {
      //   adAdqTaskAssets = Object.keys(data.adAdqTaskAssets).map((key) => {
      //     return data.adAdqTaskAssets[key][0]
      //   })
      // }
      // const adAdqTaskDescriptions = Object.keys(data.adAdqTaskDescriptions)
      //   .sort()
      //   .map((key) => {
      //     return data.adAdqTaskDescriptions[key][0]
      //   })
      // this.taskStatus = data.adAdqTask.taskStatus
      // this.$refs['deployRef'].taskStatus = this.taskStatus
      // data.previewAreaDataItemLists.forEach((item) => {
      //   item.adAdqTask = data.adAdqTask
      //   item.adAdqTaskAdcreatives = data.adAdqTaskAdcreatives
      //   item.adAdqTaskAssets = adAdqTaskAssets || null
      //   item.adAdqTaskDescriptions = adAdqTaskDescriptions || null
      //   if (data.adAdqTaskPages && data.adAdqTaskPages.length) {
      //     item.adAdqTaskPages.push(...data.adAdqTaskPages)
      //   }
      // })
      // this.dataSource.data = data.previewAreaDataItemLists
    },
    // 加载更多
    loadMoreList(v, i) {
      console.log('more', v, v.pageNum + 1, v.disabled, i)
      v.loading = true
      this.getPreviewAccount(v.accountLists.taskAccountId, v.pageNum + 1, i).then(obj => {
        if (obj.pageNum === 1) {
          v.groupList = obj.records
        } else {
          v.groupList = v.groupList.concat(obj.records)
        }

        if (obj.total <= v.groupList.length) {
          v.disabled = true
        }

        v.loading = false
        v.total = obj.total
        v.pageNum = obj.pageNum
      })
    },
    // 加载账号列表
    getPreviewAccount(taskAccountId, pageNum = 1, index) {
      return new Promise((resolve) => {
        let params = {
          taskAccountId: taskAccountId,
          taskId: this.taskId,
          pageNum: pageNum,
          pageSize: this.pageSize
        }

        showPreviewAccountList(params).then(({ code, data }) => {
          if (code === 200) {
            resolve({
              total: data.total,
              pageNum: data.current,
              records: data.records
            })
          } else {
            resolve({
              total: 0,
              pageNum: 1,
              records: []
            })
          }
        }).catch(e => {
          resolve({
            total: 0,
            pageNum: 1,
            records: []
          })
        })
      })
    },
    /**
     * @description 开始新任务
     */
    startNewTask() {
      this.taskId = 0
      this.ruleData = {
        // 后期修改
        taskId: 0,
        taskName: '',
        projectId: '',
        productId: -1,
        accountId: [], // 投放账户
        promotedObjectType: 'PROMOTED_OBJECT_TYPE_LEAD_AD', // [] // 推广目标
        taskSiteSet: '',
        taskCombine: 1,
        configuredStatus: 'AD_STATUS_SUSPEND'
      }
      this.accountBaiduAdv = []
      // this.dataSource.data = []
      this.previews = []
      this.groupIndex = 0
      this.rowIndex = 0
      this.$refs['deployRef'].startNewTask()
    },

    /**
     * @description 取消
     */
    async cancel() { },

    // 更新重置任务基础信息
    changeTaskName() {
      if (this.taskId) {
        this.isUpdateTask = true
        this.$refs['deployRef'].startNewTask()
        this.$refs['deployRef'].clearTaskGenerate()
      }
    },

    changeProject(value) {
      this.selectProjectId = value
      this.ruleData.productId = -1
      this.changeTaskName()
    },

    changeProduct(value) {
      this.selectProductId = value
      this.changeTaskName()
    },

    /**
     * @description 获取选中的投放账户
     */
    changeAccount(values) {
      // this.accountBaiduAdv = []
      // this.selectOption = []
      this.ruleData.accountSaveTaskRequestList = []
      let select = JSON.parse(JSON.stringify(this.selectOption))
      // if (!select || !select.length) {
      //   select = []
      // }
      // this.ruleData.accountSaveTaskRequestList = []
      // console.log(this.selectOption, this.AccountUin_Options, '11111')
      values.map((item) => {
        const account = this.AccountUin_Options.filter((f) => f.accountId === item)[0]
        if (account) {
          select.push(account)
          this.selectOption.push(account)
        }
      })
      values.forEach((item) => {
        const account = select.filter((f) => f.accountId === item)[0]
        this.ruleData.accountSaveTaskRequestList.push({
          tALocalAccountId: account?.id,
          tAAccountId: account?.accountId
          // taaAccCorporationName: account.corporationName
        })
      })
      // console.log(select, this.ruleData.accountSaveTaskRequestList, '222222')
      if (this.taskId) {
        this.isUpdateTask = true
        this.$refs['deployRef'].startNewTask()
        this.$refs['deployRef'].clearTaskGenerate()
      }
    },

    // 点击预览落地页
    handlePreviewPage(row) {
      showLandingPage(row.taskGenerateId).then(({ code, data }) => {
        if (code === 200) {
          let tempId = `set_page_qrcode${row.taskGenerateId}`
          document.getElementById(tempId).innerHTML = ''

          new QRCode(document.getElementById(tempId), {
            text: data,
            width: 100,
            height: 100
          })
        }
      })
    },

    hidePopover({ taskGenerateId }) {
      let tempId = `set_page_qrcode${taskGenerateId}`
      document.getElementById(tempId).innerHTML = '<span class="qrcode-loading" style="color: #dcdfe6;font-size: 12px;">加载中...</span>'
    },

    // 编辑信息
    onClickEditInfo(type, taskAccountId, val, index, rowIndex) {
      this.groupIndex = index
      this.rowIndex = rowIndex
      this.$refs.editInfo.showModal(type, taskAccountId, val)
    },

    // 编辑信息回调
    handleEditInfo(type, value, taskAccountId) {
      if (type === 4) {
        this.updateAdgroupNameFun(value, taskAccountId)
      } else if (type === 5) {
        value = value ? value * 100 : 0
        this.updateBidAmountFun(value, taskAccountId)
      }
    },

    // 预览区 更新广告名称
    updateAdgroupNameFun(value, taskAccountId) {
      const params = {
        adName: value,
        taskGenerateId: taskAccountId
      }
      setAdgroupName(params).then(({ code }) => {
        if (code === 200) {
          this.$message.success('修改广告名称成功!')
          this.previews[this.groupIndex].groupList[this.rowIndex].stAdName = value
          // this.$emit('refresh')
        }
      })
    },

    // 预览区 更新出价金额
    updateBidAmountFun(value, taskAccountId) {
      const params = {
        bid: value,
        taskGenerateId: taskAccountId
      }
      setBidAmount(params).then(({ code }) => {
        if (code === 200) {
          this.$message.success('修改广告出价成功!')
          this.previews[this.groupIndex].groupList[this.rowIndex].stAdBid = value
          // this.$emit('refresh')
        }
      })
    },

    taskSiteSetShow() {
      this.$refs.siteSet.showModal(this.ruleData.taskSiteSet, this.wechatSiteOptions, this.tencentSiteOptions)
    },

    // 选择广告版位
    setSiteSet(taskSiteSet, isUpdate) {
      this.showSiteSetFun(taskSiteSet)
      this.ruleData.taskSiteSet = taskSiteSet
      if (!isUpdate) {
        this.changeTaskName()
      }
    },

    // 显示广告版位
    showSiteSetFun(taskSiteSet) {
      this.showSiteSet = ''
      if (!taskSiteSet) {
        return
      }
      let siteSets = taskSiteSet.split('')
      if (siteSets.includes('a')) {
        this.showSiteSet += ' 朋友圈 +'
      }
      if (siteSets.includes('i')) {
        this.showSiteSet += ' 视频号 +'
      }
      let ischeckedWchats = false
      let ischeckedTencents = false
      siteSets.forEach((item) => {
        if (wechatOptions.includes(item)) {
          ischeckedWchats = true
        }
        if (tencentOptions.includes(item)) {
          ischeckedTencents = true
        }
      })
      if (ischeckedWchats) {
        this.showSiteSet += ' 微信公众号与小程序 +'
      }
      if (ischeckedTencents) {
        this.showSiteSet += ' 腾讯平台与内容媒体 +'
      }
      if (siteSets.includes('h')) {
        this.showSiteSet += ' 优量汇 +'
      }
      this.showSiteSet = this.showSiteSet.substr(0, this.showSiteSet.length - 1)
    },

    // 删除广告
    onClickDelete(id) {
      this.$confirm('此操作将删除广告, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        // 掉接口
      })
    },

    // table 选择 cell 时调用
    onTableSelectionChange(selection) {
      this.selectedData = selection
    },

    // 选择状态修改
    checkboxSelect(row, rowIndex) {
      if (row.status === 2) {
        return false // 禁用
      } else {
        return true // 不禁用
      }
    }
  }
}
</script>

<style lang="scss">
.demo-form {
  .el-form-item {
    display: flex;
  }

  .el-form-item__content {
    width: 100% !important;
  }
}
</style>

<style scoped lang="scss">
.element-img {
  // width: 80px;
  // height: 80px;
  // max-width: 100px;
  // max-height: 100px;
  text-align: center;
  margin: auto;
  // padding-top: 12px;

  video {
    width: 100%;
    height: 100%
  }
}

.element-pop-img {
  max-width: 260px;

  video {
    width: 100%;
    height: 100%
  }
}

.qrcode-box {
  width: 100px;
  height: 100px;
  margin: auto;
  text-align: center;

  .qrcode-loading {
    color: #dcdfe6;
    font-size: 12px;
    margin-top: 40px;
  }
}

.qrcode-tips {
  text-align: center;
  margin-top: 10px;
}

.preview-scroll-content {
  width: 100%;
  max-height: 900px;
  overflow-y: auto;

  .preview-account-item {
    margin-bottom: 30px;

    .table-head {
      display: flex;
      justify-content: space-between;
      border: solid 1px #dcdfe6;
      border-bottom: 0;
      box-sizing: border-box;
      background-color: #F5F7FA;
      padding: 5px 20px;

      .account {
        font-size: 20px;
        margin-top: 5px;
      }
    }

    .icon-edit {
      color: #409eff;
      font-size: 14px;
      cursor: pointer;
    }

    .preview-material-video {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .element-img {
        margin: 0 10px;
        max-width: 110px;
        max-height: 110px;
      }
    }

    .preview-material-image {
      display: flex;
      justify-content: center;

      .element-img {
        margin: 0 10px;

        &.element-img-group-1 {
          max-width: 110px;
          max-height: 110px;
        }

        &.element-img-group-3 {
          max-width: 90px;
          max-height: 90px;
        }

        &.element-img-group-4 {
          max-width: 80px;
          max-height: 80px;
        }

        &.element-img-group-6 {
          max-width: 60px;
          max-height: 60px;
        }
      }
    }
  }
}

.table-head {
  display: flex;
  justify-content: space-between;

  .account {
    font-size: 20px;
    margin-right: 10px;
  }
}

.edit-btn {
  cursor: pointer;
  color: #409eff;
}

.demo-form-inline {
  display: flex;
  justify-content: space-between;
}

.table-pane {
  padding: 10px 40px 0 40px !important;
  text-align: right;
}

.bottom-btn {
  display: flex;
  justify-content: center;
}

.divider {
  border-right: 1px solid #dcdfe6;
  padding-right: 5px;
}

.title-count {
  border-bottom: 1px #ccc solid;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 0 3px 8px 1px;

  p {
    padding: 0;
    margin: 0;
  }
}

.footer-add {
  border-top: 1px #ccc solid;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding-top: 10px;
  cursor: pointer;
  color: #409eff;
}

.demo-form {
  .el-form-item__content {
    width: 100% !important;
  }
}

.table-column-text {
  height: 140px;
  overflow-y: auto;
}
</style>
