var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "err-404" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("h1", { staticClass: "title" }, [_vm._v("404")]),
        _c("p", { staticClass: "text" }, [_vm._v("抱歉，你访问的页面不存在")]),
        _c(
          "el-button",
          {
            staticStyle: { width: "100px" },
            attrs: { type: "primary" },
            on: {
              click: function ($event) {
                return _vm.$router.push("/")
              },
            },
          },
          [_vm._v(" 返回首页")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }