var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "table-template",
      attrs: {
        title: "回传类型选择",
        visible: _vm.visible,
        "append-to-body": true,
        width: "900px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        close: function ($event) {
          _vm.visible = false
        },
      },
    },
    [
      _c(
        "el-row",
        [
          _vm._v(" 回传类型： "),
          _c(
            "el-radio",
            {
              attrs: { label: "1" },
              model: {
                value: _vm.payModel,
                callback: function ($$v) {
                  _vm.payModel = $$v
                },
                expression: "payModel",
              },
            },
            [_vm._v("下单")]
          ),
          _c(
            "el-radio",
            {
              attrs: { label: "2" },
              model: {
                value: _vm.payModel,
                callback: function ($$v) {
                  _vm.payModel = $$v
                },
                expression: "payModel",
              },
            },
            [_vm._v("付费")]
          ),
          _c(
            "el-radio",
            {
              attrs: { label: "3" },
              model: {
                value: _vm.payModel,
                callback: function ($$v) {
                  _vm.payModel = $$v
                },
                expression: "payModel",
              },
            },
            [_vm._v("添加购物车")]
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { "margin-top": "10px" } },
        [
          _c("el-col", [
            _c("span", { staticStyle: { color: "red" } }, [_vm._v("注：")]),
            _c("span", { staticStyle: { color: "red" } }, [
              _vm._v("1、下单回传默认不回传付费，需自行手动回传"),
            ]),
            _c("p", { staticStyle: { color: "red", "margin-left": "27px" } }, [
              _vm._v("2、相同目标重复回传，广告平台会去重"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              staticClass: "btn",
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { type: "primary" },
              on: { click: _vm.submit },
            },
            [_vm._v("回 传")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }