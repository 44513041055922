<!--
 * @Description: 投放账号
 * @Date: 2021-12-01 09:15:29
 * @LastEditTime: 2022-04-18 17:10:32
-->

<template>
  <div>
    <!-- 列表查询 -->
    <el-form ref="search" :inline="true" :model="search" class="search-form search-form-4" @submit.native.prevent>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="账号名称" prop="accountName">
            <el-input v-model="search.accountName" placeholder="请输入账号名称" style="width: 100%"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="关联项目" prop="relationProjectId">
            <el-select v-model="search.relationProjectId" style="width: 100%" placeholder="请选择项目" @change="(value) => (selectProjectId = value)">
              <el-option v-for="item in projectList" :key="item.bsProjectId" :label="item.projectName" :value="item.bsProjectId" />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="关联产品" prop="relationProductId">
            <el-select v-model="search.relationProductId" style="width: 100%" placeholder="请选择产品" @change="(value) => (selectProductId = value)">
              <el-option label="不限" :value="0"></el-option>
              <el-option v-for="(itm, idx) in productListed" :key="idx" :label="itm.productName" :value="itm.bsProductId"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="投放人员" prop="launchUserName">
            <el-input v-model="search.launchUserName" placeholder="请输入投放人员" style="width: 100%;"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="投放状态" prop="putInStatus">
            <el-select v-model="search.putInStatus" style="width: 300px">
              <el-option label="全部" :value="null"></el-option>
              <el-option label="投放" :value="1"></el-option>
              <el-option label="停投" :value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" round @click="getPage">查询</el-button>
            <el-button round @click="resetFormQuery('search')">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="justify-sb mb-15">
      <el-dropdown @command="handleCommand">
        <el-button type="primary"> 批量操作<i class="el-icon-arrow-down el-icon--right"></i> </el-button>
        <el-dropdown-menu slot="dropdown">
          <!-- :disabled=""  -->
          <el-dropdown-item command="0" :disabled="selectRow.length < 2 || !selectRow.every((item) => item.relationProjectId === selectRow[0].relationProjectId)">修改项目</el-dropdown-item>
          <el-dropdown-item command="1" :disabled="selectRow.length < 2 || !selectRow.every((item) => item.relationProjectId === selectRow[0].relationProjectId)">修改产品</el-dropdown-item>
          <el-dropdown-item command="2" :disabled="selectRow.length < 2 || !selectRow.every((item) => item.relationProjectId === selectRow[0].relationProjectId)">修改人员</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-button type="primary" icon="el-icon-plus" round @click="add"> 新增账户 </el-button>
    </div>
    <!-- 表格 -->
    <TablePane :data-source="dataSource" @getPage="getPage"> </TablePane>

    <!-- 投放账户信息 -->
    <el-dialog title="投放账户信息" :visible.sync="advInfo_visible" width="620px" :modal-append-to-body="false" destroy-on-close @open="showModal" @close="resetForm('ruleForm')">
      <el-form ref="ruleForm" :model="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="登录账号" prop="accountName" :rules="{ required: true, message: '请输入登录账号', trigger: 'change' }">
          <el-input v-model="ruleForm.accountName" style="width:400px" placeholder="请输入登录账号" />
        </el-form-item>

        <el-form-item label="登录密码" prop="accountPassword" :rules="{ required: true, message: '请输入登录密码', trigger: 'change' }">
          <el-input v-model="ruleForm.accountPassword" show-password style="width:400px" placeholder="请输入登录密码" />
        </el-form-item>

        <el-form-item label="APIkey" prop="apiKey" :rules="{ required: true, message: '请输入APIkey', trigger: 'change' }">
          <el-input v-model="ruleForm.apiKey" show-password style="width:400px" placeholder="请输入APIkey" />
        </el-form-item>

        <el-form-item label="项目" prop="relationProjectId" :rules="{ required: true, message: '请选择项目', trigger: 'change' }">
          <el-select
            v-model="ruleForm.relationProjectId"
            style="width:400px"
            placeholder="请选择项目"
            @change="
              (value) => {
                selectProjectId = value
              }
            "
          >
            <el-option v-for="item in projectList" :key="item.bsProjectId" :label="item.projectName" :value="item.bsProjectId" />
          </el-select>
        </el-form-item>
        <el-form-item label="产品" prop="relationProductId">
          <el-select v-model="ruleForm.relationProductId" style="width:400px" placeholder="请选择产品" @change="(value) => (selectProductId = value)">
            <el-option label="不限" :value="0"></el-option>
            <el-option v-for="(itm, idx) in productListed" :key="idx" :label="itm.productName" :value="itm.bsProductId"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="投放人员" prop="launch" :rules="{ required: true, message: '请选择投放人员', trigger: 'change' }">
          <el-select v-model="ruleForm.launch" value-key="key" filterable clearable placeholder="请选择投放人员" style="width: 400px;">
            <el-option v-for="(itm, idx) in userList" :key="idx" :label="itm.label" :value="`${itm.key}+${itm.label}`"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="投放状态" prop="putInStatus" :rules="{ required: true, message: '请选择投放状态', trigger: 'change' }">
          <el-select v-model="ruleForm.putInStatus" style="width:400px" placeholder="请选择投放状态">
            <el-option label="投放" :value="1"></el-option>
            <el-option label="停投" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="ruleForm.remark" type="textarea" maxlength="30" placeholder="请输入内容,内容最多30个字符" style="width:400px"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('ruleForm')">{{ editItem_visible ? '修改' : '添加' }}</el-button>
        <el-button @click="resetForm('ruleForm')">取消</el-button>
      </span>
    </el-dialog>

    <!-- 批量修改 -->
    <el-dialog :title="commandType === '0' ? '关联项目批量修改' : commandType === '1' ? '关联产品批量修改' : '投放人员批量修改'" :visible.sync="editInfo_visible" width="620px" :modal-append-to-body="false" destroy-on-close @open="showModal">
      <el-form ref="ruleForm2" :model="ruleForm2" label-width="100px" class="demo-ruleForm">
        <div v-if="commandType === '0'">
          <el-form-item label="关联项目:" prop="relationProjectId" :rules="{ required: true, message: '请选择关联项目', trigger: 'change' }">
            <el-select v-model="ruleForm2.relationProjectId" style="width:400px" placeholder="请选择项目" @change="(value) => (selectProjectId = value)">
              <el-option v-for="item in projectList" :key="item.bsProjectId" :label="item.projectName" :value="item.bsProjectId" />
            </el-select>
          </el-form-item>
          <el-form-item label="产品:" prop="relationProductId">
            <el-select v-model="ruleForm2.relationProductId" style="width:400px" placeholder="请选择产品">
              <el-option label="不限" :value="0"></el-option>
              <el-option v-for="(itm, idx) in productListed" :key="idx" :label="itm.productName" :value="itm.bsProductId"></el-option>
            </el-select>
          </el-form-item>
        </div>

        <div v-else>
          <div v-if="commandType === '1'">
            <el-form-item label="项目:" prop="project_name">
              <span>{{ selectRow.length ? selectRow[0].relationProjectName : '' }}</span>
            </el-form-item>
            <el-form-item label="关联产品:">
              <el-select v-model="ruleForm2.relationProductId" style="width:400px" placeholder="请选择产品">
                <el-option label="不限" :value="0"></el-option>
                <el-option v-for="(itm, idx) in productListed" :key="idx" :label="itm.productName" :value="itm.bsProductId"></el-option>
              </el-select>
            </el-form-item>
          </div>

          <div v-if="commandType === '2'">
            <el-form-item label="投放人员:" prop="project_name">
              <el-select v-model="ruleForm2.launch" value-key="key" filterable clearable placeholder="请选择投放人员" style="width: 100%;">
                <el-option v-for="(itm, idx) in userList" :key="idx" :label="itm.label" :value="`${itm.key}+${itm.label}`"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('ruleForm2')">修改</el-button>
        <el-button @click="resetForm('ruleForm2')">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TablePane from '@/components/tablePane.vue' // authorize adMarketingApiClientsSelect
import { ucAccountsBatch, ucAccountsUpdate, ucAccountsSave, ucAccountsPage } from '@/network/api/account/ucAdvAccount.js'
import AccountBaidu from '@/mixin/account_baidu'
import { getUser } from '@/network/api/user'

export default {
  components: {
    TablePane
  },
  mixins: [AccountBaidu],
  data() {
    return {
      userList: [], // 用户list
      id: '',
      search: {
        accountName: null,
        relationProjectId: null,
        relationProductId: null,
        launchUserName: null
      },
      ruleForm: {
        accountName: null,
        accountPassword: null,
        apiKey: null,
        relationProjectId: null,
        relationProductId: 0,
        launch: null,
        putInStatus: null,
        remark: null
      },
      ruleForm2: {
        relationProjectId: null,
        relationProductId: 0,
        launch: null
      },
      editInfo: false,
      advInfo_visible: false,
      editItem_visible: false,
      editInfo_visible: false,
      commandType: '0',
      selectRow: [], // 选中的列
      dataSource: {
        data: [],
        cols: [
          {
            label: '账号名称',
            prop: 'accountName'
          },
          {
            label: '账户主体',
            prop: 'accountSubject'
          },
          {
            label: '登录密码',
            prop: 'accountPassword2'
          },
          {
            label: '项目',
            prop: 'relationProjectName'
          },
          {
            label: '产品',
            prop: 'relationProductName'
          },
          {
            label: '投放人员',
            prop: 'launchUserName'
          },
          {
            label: '投放状态',
            width: 100,
            render: (recode) => {
              return recode.putInStatus === 1 ? <el-tag type='success'>投放</el-tag> : <el-tag type='danger'>停投</el-tag>
            }
          },
          {
            label: 'APIkey',
            prop: 'apiKey2'
          }
        ],
        isIndex: true, // 列表序号
        loading: false, // loading
        isOperation: true,
        isSelection: true,
        pageData: {
          total: 0 // 总条数
        },
        handleSelectionChange: (selection) => {
          this.selectRow = selection
        },
        operation: {
          // 表格有操作列时设置
          label: '操作', // 列名
          width: 100, // 根据实际情况给宽度
          fixed: 'right',
          data: [
            {
              icon: 'el-icon-edit',
              tooltip: '编辑',
              handleRow: (index, re) => {
                this.id = re.accountLocalId
                this.editInfo = re
                this.advInfo_visible = true
                this.editItem_visible = true
                this.selectProjectId = +re.relationProjectId
                this.selectProductId = +re.relationProductId

                this.ruleForm = {
                  accountName: re.accountName,
                  accountPassword: re.accountPassword,
                  apiKey: re.apiKey,
                  relationProjectId: +re.relationProjectId,
                  relationProductId: +re.relationProductId,
                  launch: `${re.launchUserId}+${re.launchUserName}`,
                  putInStatus: re.putInStatus,
                  remark: re.remark
                }
              }
            }
          ]
        }
      }
    }
  },
  // mounted() {
  //   this.getPage()
  // },
  watch: {
    projectList(value) {
      if (value.length !== 0) {
        this.getPage()
      }
    },
    selectProjectId() {
      this.ruleForm2.relationProductId = 0
    }
  },

  mounted() {
    this._getUserList()
  },
  methods: {

    // 显示弹框
    // showModal() {
    //   this._getUserList()
    // },
    // 获取成员列表
    _getUserList() {
      getUser().then((res) => {
        if (res.code === 200) {
          this.userList = res.data
        }
      })
    },

    add() {
      this.advInfo_visible = true
      this.editItem_visible = false
      this.$nextTick(() => {
        this.$refs['ruleForm'].resetFields()
      })
    },
    // 50ddc322-2b9e-492c-becc-3a9bc532fcbf
    // Yd123456@
    // 苏州灵爽
    /**
     * @description: 提交数据
     * @param {*} formName ref
     */
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (formName === 'ruleForm') {
            let { relationProjectId, relationProductId, remark, accountName, accountPassword, apiKey, launch, putInStatus } = this.ruleForm
            let params = {
              accountName,
              accountPassword,
              apiKey,
              relationProjectId,
              relationProjectName: this.projectList.filter((item) => item.bsProjectId === relationProjectId)[0].projectName,
              relationProductId: relationProductId || 0,
              relationProductName: relationProductId !== 0 ? this.productListed.filter((item) => item.bsProductId === relationProductId)[0].productName : '不限',
              launchUserId: launch.split('+')[0],
              launchUserName: launch.split('+')[1],
              putInStatus,
              remark
            }
            if (this.editItem_visible) {
              ucAccountsUpdate({ accountLocalId: this.id, ...params }).then((res) => {
                if (res && res.code === 200) {
                  this.selectRow = []
                  this.$message.success('修改成功!')
                  this.productListed = []
                  this.id = 0
                  this.resetFormQuery(formName)
                }
              })
            } else {
              ucAccountsSave(params).then((res) => {
                if (res && res.code === 200) {
                  this.$message.success('创建成功!')
                  this.resetFormQuery(formName)
                }
              })
            }
          }
        }
        if (formName === 'ruleForm2') {
          // 关联项目批量修改
          console.log(this.ruleForm2)
          let { relationProjectId, relationProductId, launch } = this.ruleForm2
          if (this.commandType === '0') {
            let params = {
              accountLocalIds: this.selectRow.map((item) => item.accountLocalId),
              relationProjectId,
              relationProjectName: this.projectList.filter((item) => item.bsProjectId === relationProjectId)[0].projectName,
              relationProductId: relationProductId || 0,
              relationProductName: relationProductId !== 0 ? this.productListed.filter((item) => item.bsProductId === relationProductId)[0].productName : '不限'
            }
            ucAccountsBatch(params).then((res) => {
              if (res && res.code === 200) {
                this.editInfo_visible = false
                this.selectRow = []
                this.$message.success('项目修改成功!')
              }
            })
          } else if (this.commandType === '1') {
            // 关联产品批量修改
            let params = {
              accountLocalIds: this.selectRow.map((item) => item.accountLocalId),
              relationProductId: relationProductId || 0,
              relationProductName: relationProductId !== 0 ? this.productListed.filter((item) => item.bsProductId === relationProductId)[0].productName : '不限'
            }
            ucAccountsBatch(params).then((res) => {
              if (res && res.code === 200) {
                this.editInfo_visible = false
                this.$message.success('产品修改成功!')
              }
            })
          } else {
            //  投放人员批量修改
            let params = {
              accountLocalIds: this.selectRow.map((item) => item.accountLocalId),
              launchUserId: launch.split('+')[0],
              launchUserName: launch.split('+')[1]
            }
            ucAccountsBatch(params).then((res) => {
              if (res && res.code === 200) {
                this.editInfo_visible = false
                this.$message.success('投放人员修改成功!')
                this.selectRow = []
              }
            })
          }
          this.resetFormQuery(formName)
        }
      })
    },

    /**
     * @description: 重置数据
     * @param {*} formName ref
     */
    resetForm(formName) {
      this.advInfo_visible = false
      this.editInfo_visible = false

      this.selectProjectId = ''
      this.$nextTick(() => {
        this.$refs[formName].resetFields()
        this.ruleForm.relationProjectId = null
      })
    },

    resetFormQuery(formName) {
      this.advInfo_visible = false
      this.editInfo_visible = false
      this.productListed = []
      this.selectProjectId = ''
      setTimeout(() => {
        this.$refs[formName].resetFields()
        this.getPage()
        this.ruleForm = {
          ...this.ruleForm,
          relationProjectId: null
        }
      }, 400)
    },

    handleCommand(value) {
      if (value !== 2) {
        this.selectProjectId = this.selectRow[0].relationProjectId
        this.ruleForm2.relationProjectId = this.selectRow[0].relationProjectId
        // this.ruleForm2.relationProductId = this.selectRow[0].relationProductId
      } else {
        this.ruleForm2.launch = `${this.selectRow[0].launchUserId}+${this.selectRow[0].launchUserName}`
      }
      this.commandType = value
      this.editInfo_visible = true
    },

    // async gdtClick(id) {
    //   const { code, data } = await getAuthorizeUrl(id)
    //   if (code === 200) {
    //     window.open(data, 'authorize', 'width:700,height=500,left=0,top=0,menubar=no,toolbar=no')
    //   }
    // },

    /**
     * @description: 获取列表
     * @param {*} params
     */
    getPage(params = { pageNum: 1, pageSize: 10 }) {
      this.dataSource.loading = true
      let search = {
        ...this.search,
        projectIds: this.projectList.map((item) => item.bsProjectId)
      }
      ucAccountsPage({ ...search, ...params }).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          // records
          records.forEach((item) => {
            item.apiKey2 = `${item.apiKey.substring(0, 5)}***${item.apiKey.substr(-5)}`
            item.accountPassword2 = `${item.accountPassword.substring(0, 2)}***${item.accountPassword.substr(-2)}`
          })
          this.dataSource.data = records
          this.dataSource.pageData.total = total
          this.dataSource.loading = false
        }
      })
    }
  }
}
</script>
