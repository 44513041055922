var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "creativity-text" },
      [
        _vm.creativityTextList.flag
          ? _c(
              "div",
              [
                ["展示Banner图片", "小程序banner广告"].includes(
                  _vm.crtSizeLabel
                )
                  ? _c("div", { staticClass: "kong" }, [
                      _vm._v(
                        " " + _vm._s(`${_vm.crtSizeLabel}无需选择文案`) + " "
                      ),
                    ])
                  : _vm._e(),
                _vm._l(_vm.creativityTextList.list, function (item, index) {
                  return _c(
                    "div",
                    { key: item.groupName, staticClass: "list-box" },
                    [
                      _c("div", { staticClass: "justify-sb align-center" }, [
                        _c("p", [_vm._v(_vm._s(item.groupName))]),
                        _c("i", {
                          staticClass: "el-icon-circle-close close-icon",
                          on: {
                            click: function ($event) {
                              return _vm.deleteCreativeGroup(index)
                            },
                          },
                        }),
                      ]),
                      _c(
                        "ul",
                        _vm._l(
                          item.adMpManagementTaskImgVideoENTList[0],
                          function (value, key) {
                            return _c("li", { key: key }, [
                              !["taskTextId", "assetGroupId"].includes(key)
                                ? _c("p", { staticClass: "key" }, [
                                    _vm._v(
                                      _vm._s(_vm.creativityTextType[key]) + "："
                                    ),
                                  ])
                                : _vm._e(),
                              !["taskTextId", "assetGroupId"].includes(key)
                                ? _c("p", { staticClass: "value" }, [
                                    _vm._v(_vm._s(value)),
                                  ])
                                : _vm._e(),
                            ])
                          }
                        ),
                        0
                      ),
                    ]
                  )
                }),
              ],
              2
            )
          : _c("div", { staticClass: "kong" }, [_vm._v(" 请选择创意文案 ")]),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "创意文案",
              visible: _vm.visible,
              width: "800px",
              "modal-append-to-body": false,
              "destroy-on-close": "",
              "before-close": _vm.handleClose,
            },
          },
          [
            _c(
              "span",
              [
                _c(
                  "div",
                  { staticClass: "justify-sb align-center pt-10 pb-10" },
                  [
                    _c("div", [
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v("创意内容"),
                      ]),
                      _c("span", { staticClass: "ml-20" }, [
                        _vm._v(
                          "创意组数量：" +
                            _vm._s(_vm.creativityTextList.list.length) +
                            "组"
                        ),
                      ]),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.isBatchFun },
                      },
                      [_vm._v("批量添加")]
                    ),
                  ],
                  1
                ),
                _vm.creativityTextList.list.length
                  ? _c(
                      "el-collapse",
                      {
                        model: {
                          value: _vm.collapse,
                          callback: function ($$v) {
                            _vm.collapse = $$v
                          },
                          expression: "collapse",
                        },
                      },
                      _vm._l(
                        _vm.creativityTextList.list,
                        function (item, index) {
                          return _c(
                            "el-collapse-item",
                            {
                              key: item.groupName,
                              attrs: { name: item.groupName },
                            },
                            [
                              _c("template", { slot: "title" }, [
                                _c(
                                  "div",
                                  { staticClass: "justify-sb group-title" },
                                  [
                                    _c("p", [_vm._v(_vm._s(item.groupName))]),
                                    _c("div", { staticClass: "icon" }, [
                                      _c("i", {
                                        staticClass: "el-icon-document-copy",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.copyCreativeGroup(index)
                                          },
                                        },
                                      }),
                                      _vm.creativityTextList.list.length > 1
                                        ? _c("i", {
                                            staticClass: "el-icon-close",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.deleteCreativeGroup(
                                                  index
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ]),
                                  ]
                                ),
                              ]),
                              _c(
                                "div",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "justify-end" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              _vm.textVisible = true
                                            },
                                          },
                                        },
                                        [_vm._v("新增文案")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form",
                                    {
                                      ref: `text-${index}`,
                                      refInFor: true,
                                      attrs: {
                                        model:
                                          item
                                            .adMpManagementTaskImgVideoENTList[0],
                                        "label-width": "110px",
                                      },
                                    },
                                    [
                                      _c("CreativityTextContent", {
                                        attrs: {
                                          "crt-size-label": _vm.crtSizeLabel,
                                          "creativity-data": _vm.creativityData,
                                          item: item
                                            .adMpManagementTaskImgVideoENTList[0],
                                          "project-id": _vm.projectId,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          )
                        }
                      ),
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "text-align": "center",
                      "margin-top": "10px",
                    },
                  },
                  [
                    _c("el-button", { on: { click: _vm.addCreativeGroup } }, [
                      _vm._v("添加创意组"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c("el-button", { on: { click: _vm.handleClose } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", disabled: !_vm.isSearchLock },
                    on: { click: _vm.onSubmit },
                  },
                  [_vm._v("确 定")]
                ),
              ],
              1
            ),
          ]
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "新增文案",
              visible: _vm.textVisible,
              width: "600px",
              "modal-append-to-body": false,
              "destroy-on-close": "",
            },
            on: {
              "update:visible": function ($event) {
                _vm.textVisible = $event
              },
            },
          },
          [
            _c(
              "span",
              [
                _c(
                  "el-form",
                  {
                    ref: "ruleData",
                    staticClass: "demo-form-inline mt-20",
                    attrs: { inline: true, model: _vm.ruleData },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "项目名称:",
                          prop: "projectId",
                          rules: {
                            required: true,
                            message: "请选择项目",
                            trigger: "change",
                          },
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "400px" },
                            on: {
                              change: (value) => (
                                (_vm.selectProjectId = value),
                                (_vm.ruleData.productIds = -1)
                              ),
                            },
                            model: {
                              value: _vm.ruleData.projectId,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleData, "projectId", $$v)
                              },
                              expression: "ruleData.projectId",
                            },
                          },
                          _vm._l(_vm.projectList, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: {
                                label: item.projectName,
                                value: item.bsProjectId + "",
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "产品名称:",
                          rules: {
                            required: true,
                            message: "请选择产品",
                            trigger: "change",
                          },
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "400px" },
                            on: {
                              change: (value) => (_vm.selectProductId = value),
                            },
                            model: {
                              value: _vm.ruleData.productIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleData, "productIds", $$v)
                              },
                              expression: "ruleData.productIds",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "不限", value: -1 },
                            }),
                            _vm._l(_vm.productListed, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.productName,
                                  value: item.bsProductId,
                                },
                              })
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c("br"),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "文案内容",
                          prop: "textarea",
                          rules: {
                            required: true,
                            message: "请选择产品",
                            trigger: "change",
                          },
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "400px" },
                          attrs: {
                            type: "textarea",
                            rows: 6,
                            placeholder: "请输入文案，多个文案请换行！",
                          },
                          model: {
                            value: _vm.ruleData.textarea,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleData, "textarea", $$v)
                            },
                            expression: "ruleData.textarea",
                          },
                        }),
                        _c("p", { staticClass: "tip" }, [
                          _c(
                            "span",
                            {
                              style: {
                                color:
                                  (_vm.ruleData.textarea
                                    ? _vm.ruleData.textarea.split("\n").length
                                    : 0) > 20
                                    ? "red"
                                    : "#7d7d7d",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.ruleData.textarea
                                    ? _vm.ruleData.textarea.split("\n").length
                                    : 1
                                )
                              ),
                            ]
                          ),
                          _vm._v(" /20. 手动换行可添加多条文案 "),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        _vm.textVisible = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", disabled: !_vm.isSearchLock },
                    on: { click: _vm.addTextSubmit },
                  },
                  [_vm._v("确 定")]
                ),
              ],
              1
            ),
          ]
        ),
        _c("CreativityBatchAddTextContent", {
          attrs: {
            "is-batch": _vm.isBatch,
            "crt-size-label": _vm.crtSizeLabel,
            "creativity-data": _vm.creativityData,
            "project-id": _vm.projectId,
          },
          on: { setIsBatch: _vm.setIsBatch, onOk: _vm.batchSubmit },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }