<!--
 * @Author: Zeng
 * @Date: 2024-03-19 10:32:37
 * @LastEditTime: 2024-03-19 11:20:20
-->
<template>
  <el-dialog class="table-template" title="落地页名称修改" :visible.sync="show" :append-to-body="true" width="600px" top="20vh"
    @close="onCloseDialog">
    <div class="page-container">
      <el-form ref="form" :inline="false" :model="form" :rules="rules">
        <el-form-item label="" prop="name">
          <el-input v-model="form.name" placeholder="请输入落地页名称名称" maxlength="30" show-word-limit style="width: 100%">
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">取消</el-button>
      <el-button class="btn" type="primary" :disabled="loading" @click="handleConfirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { updateCustomLandingPageName } from '@/network/api/toolManagement/api-landing-page'
export default {
  name: 'EditPageNameDialog',
  data() {
    return {
      show: false,
      loading: false,
      form: {
        id: '',
        name: ''
      },
      rules: {
        name: [{ required: true, message: '请输入落地页名称', trigger: 'blur' }]
      }
    }
  },

  created() { },

  mounted() { },
  methods: {
    /* --------------------------- Actions --------------------------- */
    showModal(data) {
      if (Object.keys(data).length) {
        this.form = {
          ...data
        }
      }
      this.show = true
    },
    // 点击确定按钮
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return
        }

        let { id, name } = this.form
        this.loading = true
        updateCustomLandingPageName(id, name).then(({ code }) => {
          if (code === 200) {
            this.$SuccessMessage('编辑成功')
            this.$emit('confirm')
            this.closeModal()
          }
        }).finally(() => {
          this.loading = false
        })
      })
    },
    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      // 重置表单
      this.form = {
        id: '',
        name: ''
      }
      setTimeout(() => {
        this.$refs.form.clearValidate()
      }, 100)
    },
    // 关闭弹框
    closeModal() {
      this.show = false
    }
  }
}
</script>

<style scoped lang="scss"></style>
