var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "append-to-body": true,
        title: _vm.dialogTitle,
        width: "500px",
        top: "20vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.onCloseDialog,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "100px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "专辑", prop: "albumId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "请选择专辑",
                  },
                  on: { change: _vm.handleAlbum },
                  model: {
                    value: _vm.form.albumId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "albumId", $$v)
                    },
                    expression: "form.albumId",
                  },
                },
                _vm._l(_vm.albumList, function (itm, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { label: itm.albumName, value: itm.albumId },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "素材组", prop: "materialGroupId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    disabled: !_vm.form.albumId,
                    filterable: "",
                    clearable: "",
                    placeholder: "请选择素材组",
                  },
                  model: {
                    value: _vm.form.materialGroupId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "materialGroupId", $$v)
                    },
                    expression: "form.materialGroupId",
                  },
                },
                _vm._l(_vm.materialGroupList, function (itm, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: {
                      label: itm.materialGroupName,
                      value: itm.materialGroupId,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "btn", on: { click: _vm.closeModal } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: {
                type: "primary",
                loading: _vm.isLoading,
                disabled: _vm.isLoading,
              },
              on: {
                click: function ($event) {
                  return _vm.clickConfirm("form")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }