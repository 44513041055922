<template>
  <el-dialog
    :title="isEdit ? '编 辑': '新 增'"
    width="45%"
    :visible.sync="visible"
    :before-close="close"
    label-position="right"
    :modal-append-to-body="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <el-form ref="ent" :model="ent" :rules="rules" label-width="110px">

      <el-form-item label="产品名称" prop="productName">
        <el-input v-model="ent.productName" maxlength="30" placeholder="最大输入长度30 超出截断" />
      </el-form-item>
      <el-form-item label="产品品类" prop="productTypeId">
        <el-select
          v-model="ent.productTypeId"
          filterable
          default-first-option
          placeholder="请选择"
          @change="productType"
        >
          <el-option
            v-for="item in typeList"
            :key="item.bsProductTypeId"
            :label="item.typeName"
            :value="item.bsProductTypeId"
          />
        </el-select>

        <el-button class="ml-10" type="text" @click="typeAdd"><u>新增品类</u></el-button>
        <el-tooltip class="item" effect="dark" content="刷新产品品类选择内容" placement="top-start">
          <el-button class="ml-10" type="text" icon="el-icon-refresh" @click="refreshRest" />
        </el-tooltip>
        <!-- <el-input v-model="ent.productTypeId" /> -->
      </el-form-item>
      <el-form-item label="关联项目" prop="relationProjectIdNew">
        <el-select
          v-model="ent.relationProjectIdNew"
          multiple
          collapse-tags
          filterable
          default-first-option
          placeholder="请选择"
          @change="projectChange"
        >
          <el-option
            v-for="item in projectList"
            :key="item.bsProjectId"
            :label="item.projectName"
            :value="item.bsProjectId"
          />
        </el-select>
        <el-button class="ml-10" type="text" @click="projectAdd"><u>新增项目</u></el-button>
        <el-tooltip class="item" effect="dark" content="刷新关联项目选择内容" placement="top-start">
          <el-button class="ml-10" type="text" icon="el-icon-refresh" @click="refreshRest" />
        </el-tooltip>
      </el-form-item>
      <el-form-item label="产品来源" prop="productSourceId">
        <el-select
          v-model="ent.productSourceId"
          filterable
          default-first-option
          placeholder="请选择"
          @change="sourceChange"
        >
          <el-option
            v-for="item in sourceList"
            :key="item.bsProductSourceId"
            :label="item.source"
            :value="item.bsProductSourceId"
          />
        </el-select>
        <el-button class="ml-10" type="text" @click="sourceAdd"><u>新增来源</u></el-button>
        <el-tooltip class="item" effect="dark" content="刷新产品来源选择内容" placement="top-start">
          <el-button class="ml-10" type="text" icon="el-icon-refresh" @click="refreshRest" />
        </el-tooltip>
      </el-form-item>
      <el-form-item label="是否启用" prop="isUseing">
        <el-select
          v-model="ent.isUseing"

          filterable
          default-first-option
          placeholder="请选择"
        >
          <el-option
            v-for="item in isUseingList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>

      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="ent.remark" type="textarea" maxlength="100" min="0" placeholder="最大输入长度100 超出截断" />
      </el-form-item>
      <!-- <el-form-item label="关联项目名称" prop="relationProjectName">
        <el-input v-model="ent.relationProjectName" />
      </el-form-item> -->
      <!-- <el-form-item label="产品类型名称" prop="productTypeName">
        <el-input v-model="ent.productTypeName" />
      </el-form-item> -->

      <!-- <el-form-item label="产品来源名称" prop="productSourceName">
        <el-input v-model="ent.productSourceName" />
      </el-form-item> -->
      <!-- <el-form-item label="产品组id" prop="productGroupId">
        <el-input v-model="ent.productGroupId" />
      </el-form-item>
      <el-form-item label="产品组名称" prop="productGroupName">
        <el-input v-model="ent.productGroupName" />
      </el-form-item> -->

      <!-- <el-form-item label="逻辑删除" prop="deleted">
        <el-input v-model="ent.deleted" />
      </el-form-item>
      <el-form-item label="创建者" prop="createBy">
        <el-input v-model="ent.createBy" />
      </el-form-item>
      <el-form-item label="创建时间" prop="createTime">
        <el-input v-model="ent.createTime" />
      </el-form-item>
      <el-form-item label="更新者" prop="updateBy">
        <el-input v-model="ent.updateBy" />
      </el-form-item>
      <el-form-item label="更新时间" prop="updateTime">
        <el-input v-model="ent.updateTime" />
      </el-form-item> -->

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button round @click="close">取消</el-button>
      <el-popconfirm class="ml-10" confirm-button-text="提交" cancel-button-text="取消" icon="el-icon-info" icon-color="red" title="确定提交？" @confirm="submit">
        <el-button slot="reference" type="primary" round>确定</el-button>
      </el-popconfirm>
    </span>
  </el-dialog>
</template>
<script>
import {
  Add,
  Update
} from '@/network/api/basicSetting/product/basicSettingProduct'
import { BasicSettingProductEnt, formRules, isUseingList } from './object'
import { getProjectForProduct } from '@/network/api/basicSetting/project/basicSettingProject'
import { getAllIsUseing } from '@/network/api/basicSetting/product/basicSettingProductType'

export default {
  inject: ['loadData', 'showAddProject', 'showAddType', 'showAddSource'],
  data() {
    return {
      ent: new BasicSettingProductEnt(),
      rules: formRules,
      isEdit: false,
      visible: false,
      isUseingList: isUseingList,
      cityList: [],
      typeList: [],
      projectList: [],
      sourceList: [],
      objSoucer: {}
    }
  },
  mounted() {
    this.getType()
    this.getProject()
  },

  methods: {
    refreshRest() {
      this.getType()
      this.getProject()
    },
    activateds() {
      this.$message({
        message: '激活',
        type: 'success'
      })
    },
    projectAdd() {
      // this.close()
      this.showAddProject()
    },
    typeAdd() {
      // this.close()
      this.showAddType()
    },
    sourceAdd() {
      // this.close()
      this.showAddSource()
    },
    productType(val) {
      this.typeList.forEach(item => {
        if (item.bsProductTypeId === val) {
          this.ent.productTypeName = item.typeName
        }
      })
    },
    projectChange(val) {
      // this.sourceList = []
      // debugger
      // this.ent.productSourceId = undefined
      // this.ent.productSourceName = undefined
      // if (this.objSoucer[val] !== undefined) {
      //   this.sourceList = this.objSoucer[val]
      // }
      // this.projectList.forEach(item => {
      //   if (item.bsProjectId === val) {
      //     this.ent.relationProjectName = item.projectName
      //   }
      // })
    },
    sourceChange(val) {
      this.sourceList.forEach(item => {
        if (item.bsProductSourceId === val) {
          this.ent.productSourceName = item.source
        }
      })
    },

    getType() {
      getAllIsUseing().then(res => {
        if (res.code === 200) {
          this.typeList = res.data
        }
      })
    },
    getProject() {
      getProjectForProduct().then(res => {
        if (res.code === 200) {
          const data = res.data
          if (undefined !== data.PROJECT) {
            this.projectList = data.PROJECT
          }
          if (undefined !== data.SOURCE) {
            this.sourceList = data.SOURCE

            // this.objSoucer = data.SOURCE
            // if (this.ent.relationProjectId !== undefined) {
            //   if (this.objSoucer[this.ent.relationProjectId] !== undefined) {
            //     this.sourceList = this.objSoucer[this.ent.relationProjectId]
            //   }
            // }
          }
        }
      })
    },
    submit() {
      this.$refs.ent.validate((validate) => {
        if (validate) {
          this.isEdit ? this.updateRow() : this.addRow()
        }
      })
    },
    addRow() {
      const req = { ...this.ent }
      req.relationProjectIdNew = JSON.stringify(req.relationProjectIdNew)
      Add(req).then(res => {
        if (res.code === 200) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.loadData()
          this.close()
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    updateRow() {
      const req = { ...this.ent }
      req.relationProjectIdNew = JSON.stringify(req.relationProjectIdNew)
      Update(req).then(res => {
        if (res.code === 200) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.loadData()
          this.close()
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    close() {
      this.visible = false
      this.$nextTick(() => {
        this.$refs.ent.resetFields()
      })
    },
    show(isEdit = false, ent) {
      this.getType()
      this.getProject()
      if (!isEdit) {
        ent.isUseing = 'ISUSEING'
      }
      this.ent = ent
      if (isEdit) {
        this.ent.relationProjectIdNew = JSON.parse(ent.relationProjectIdNew)
      }
      this.isEdit = isEdit
      this.visible = true
      this.$nextTick(() => {
        if (this.isEdit) {
          if (this.objSoucer[this.ent.relationProjectId] !== undefined) {
            this.sourceList = this.objSoucer[this.ent.relationProjectId]
          }
        }
      })
    }
  }
}
</script>
