var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { attrs: { gutter: 20 } },
    [
      _c(
        "el-col",
        { attrs: { span: 12 } },
        [
          _c(
            "ModuleBox",
            { staticClass: "mb-20", attrs: { title: "定向人群" } },
            [
              _c("section", { staticClass: "orientation-box" }, [
                _vm.orientation_len === 0
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "justify-center align-center add-orientation",
                        on: {
                          click: function ($event) {
                            return _vm.addSelectedData("orientation")
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-plus" }),
                        _c("span", [_vm._v("点击添加人群")]),
                      ]
                    )
                  : _c(
                      "div",
                      { staticClass: "orientation-list" },
                      _vm._l(
                        _vm.selectedData_orientation,
                        function (item, index) {
                          return _c(
                            "ul",
                            {
                              key: index,
                              class: {
                                "selected-ul":
                                  index === _vm.selectedIndex_orientation,
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.getSelectedIndex(
                                    index,
                                    "orientation"
                                  )
                                },
                              },
                            },
                            [
                              item.length
                                ? [
                                    _c(
                                      "div",
                                      _vm._l(item, function (label, idx) {
                                        return _c("li", { key: idx }, [
                                          _c("div", [
                                            _c("span", [
                                              _vm._v(_vm._s(label.name)),
                                            ]),
                                            _c("span", [
                                              _vm._v(_vm._s(label.value)),
                                            ]),
                                          ]),
                                          _c("i", {
                                            staticClass: "el-icon-circle-close",
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteLi(index, idx)
                                              },
                                            },
                                          }),
                                        ])
                                      }),
                                      0
                                    ),
                                    _c("i", {
                                      staticClass: "el-icon-circle-close",
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteGroup(index)
                                        },
                                      },
                                    }),
                                  ]
                                : [
                                    _c(
                                      "div",
                                      { staticClass: "justify-center" },
                                      [
                                        _c("p", { staticClass: "tip" }, [
                                          _vm._v("请选择标签"),
                                        ]),
                                      ]
                                    ),
                                    _c("i", {
                                      staticClass: "el-icon-circle-close",
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteGroup(index)
                                        },
                                      },
                                    }),
                                  ],
                            ],
                            2
                          )
                        }
                      ),
                      0
                    ),
                _vm.orientation_len !== 0
                  ? _c(
                      "div",
                      { staticClass: "justify-center align-center" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "mt-20",
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.addSelectedData("orientation")
                              },
                            },
                          },
                          [_vm._v("添加交集")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]
          ),
          _c(
            "ModuleBox",
            { staticClass: "mb-20", attrs: { title: "排除人群" } },
            [
              _c("section", { staticClass: "orientation-box" }, [
                _vm.exclude_len === 0
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "justify-center align-center add-orientation",
                        on: {
                          click: function ($event) {
                            return _vm.addSelectedData("exclude")
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-plus" }),
                        _c("span", [_vm._v("点击添加人群")]),
                      ]
                    )
                  : _c(
                      "div",
                      { staticClass: "orientation-list" },
                      _vm._l(_vm.selectedData_exclude, function (item, index) {
                        return _c(
                          "ul",
                          {
                            key: index,
                            class: {
                              "selected-ul":
                                index === _vm.selectedIndex_exclude,
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.getSelectedIndex(index, "exclude")
                              },
                            },
                          },
                          [
                            item.length
                              ? [
                                  _c(
                                    "div",
                                    _vm._l(item, function (label, idx) {
                                      return _c("li", { key: idx }, [
                                        _c("div", [
                                          _c("span", [
                                            _vm._v(_vm._s(label.name)),
                                          ]),
                                          _c("span", [
                                            _vm._v(_vm._s(label.value)),
                                          ]),
                                        ]),
                                        _c("i", {
                                          staticClass: "el-icon-circle-close",
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteLi(index, idx)
                                            },
                                          },
                                        }),
                                      ])
                                    }),
                                    0
                                  ),
                                  _c("i", {
                                    staticClass: "el-icon-circle-close",
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteGroup(index)
                                      },
                                    },
                                  }),
                                ]
                              : [
                                  _c("div", { staticClass: "justify-center" }, [
                                    _c("p", { staticClass: "tip" }, [
                                      _vm._v("请选择标签"),
                                    ]),
                                  ]),
                                  _c("i", {
                                    staticClass: "el-icon-circle-close",
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteGroup(index)
                                      },
                                    },
                                  }),
                                ],
                          ],
                          2
                        )
                      }),
                      0
                    ),
                _vm.exclude_len !== 0
                  ? _c(
                      "div",
                      { staticClass: "justify-center align-center" },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "mt-20",
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.addSelectedData("exclude")
                              },
                            },
                          },
                          [_vm._v("添加交集")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]
          ),
          _c(
            "ModuleBox",
            { attrs: { title: "基础信息" } },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: { model: _vm.ruleForm, "label-width": "100px" },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "人群名称:",
                        prop: "name",
                        rules: {
                          required: true,
                          message: "请输入人群名称",
                          trigger: "change",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "30",
                          placeholder: "请输入人群名称",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.ruleForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "name", $$v)
                          },
                          expression: "ruleForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "项目：", prop: "projectId" } },
                    [
                      _c("SearchSelect", {
                        staticStyle: { width: "450px" },
                        attrs: {
                          options: _vm.projectList,
                          props: {
                            label: "projectName",
                            value: "bsProjectId",
                          },
                          placeholder: "请选择项目",
                        },
                        on: {
                          change: (value) => (_vm.selectProjectId = value),
                        },
                        model: {
                          value: _vm.ruleForm.projectId,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "projectId", $$v)
                          },
                          expression: "ruleForm.projectId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品：", prop: "productId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "450px" },
                          attrs: { placeholder: "请选择产品" },
                          on: {
                            change: (value) => (_vm.selectProductId = value),
                          },
                          model: {
                            value: _vm.ruleForm.productId,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "productId", $$v)
                            },
                            expression: "ruleForm.productId",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "不限", value: 0 },
                          }),
                          _vm._l(_vm.productListed, function (itm, idx) {
                            return _c("el-option", {
                              key: idx,
                              attrs: {
                                label: itm.productName,
                                value: itm.bsProductId,
                              },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "人群描述:", prop: "description" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: "4",
                          maxlength: "100",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.ruleForm.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "description", $$v)
                          },
                          expression: "ruleForm.description",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "mt-20",
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("ruleForm")
                            },
                          },
                        },
                        [_vm._v("生成人群")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-col", { attrs: { span: 12 } }, [
        _c(
          "div",
          { staticClass: "label" },
          [
            _c(
              "el-radio-group",
              {
                on: { change: _vm.radioChange },
                model: {
                  value: _vm.selectOneLevel,
                  callback: function ($$v) {
                    _vm.selectOneLevel = $$v
                  },
                  expression: "selectOneLevel",
                },
              },
              _vm._l(_vm.editableTabs_oneLevel, function (item) {
                return _c(
                  "el-radio-button",
                  { key: item.label, attrs: { label: item.label } },
                  [_vm._v(_vm._s(item.value))]
                )
              }),
              1
            ),
            _c(
              "el-tabs",
              {
                staticClass: "mt-20",
                attrs: { type: "card" },
                on: { "tab-click": (value) => _vm.getLeafTagsFun(value.name) },
                model: {
                  value: _vm.editableTabsValue,
                  callback: function ($$v) {
                    _vm.editableTabsValue = $$v
                  },
                  expression: "editableTabsValue",
                },
              },
              _vm._l(_vm.editableTabs, function (item) {
                return _c(
                  "el-tab-pane",
                  { key: item.threeId, attrs: { name: item.threeId } },
                  [
                    _c("template", { slot: "label" }, [
                      _c("div", { staticClass: "tab-item" }, [
                        _c("span", [_vm._v(_vm._s(item.twoLevel))]),
                        _c("br"),
                        _c("span", { staticClass: "two" }, [
                          _vm._v(_vm._s(item.threeLevel)),
                        ]),
                      ]),
                    ]),
                  ],
                  2
                )
              }),
              1
            ),
            _c("LabelTree", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tree_loading,
                  expression: "tree_loading",
                },
              ],
              ref: "labelTree",
              attrs: {
                data: _vm.tree_Data,
                "set-checked-keys": _vm.treeCheckedKeys,
              },
              on: { getSelected: _vm.getSelected },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }