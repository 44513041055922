<!--
 * @Description: 文本编辑区
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2024-03-21 16:25:13
-->

<template>
  <section class="section">
    <base-box name="text">
      <template slot="body">
        <el-form ref="textForm" class="img-form-box" :model="detail" size="mini" :inline="true" :rules="rules"
          label-width="80px">
          <el-form-item label="文本内容" prop="content">
            <el-input v-model="detail.content" type="textarea" :rows="15" resize="none" placeholder="请输入内容"
              maxlength="500" show-word-limit> </el-input>
          </el-form-item>

          <el-form-item label="字符样式" prop="fontSize">
            <div style="display:flex">
              <div class="mr-10">
                <el-input-number v-model="detail.fontSize" size="small" :min="1" :max="100"></el-input-number>
              </div>
              <div class="mr-10">
                <el-dropdown>
                  <div class="ldy-bg-color" :style="`background:${detail.fontColor}`"></div>
                  <el-dropdown-menu slot="dropdown">
                    <twitter-color :colors="detail.fontColor" style="margin-top: 10px"
                      @change="(value) => (detail.fontColor = value)" />
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div class="mr-10">
                <el-radio-group v-model="detail.showType" size="small">
                  <el-radio-button label="normal">常规</el-radio-button>
                  <el-radio-button label="bold">加粗</el-radio-button>
                </el-radio-group>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="对齐方式" prop="textAlignment">
            <el-radio-group v-model="detail.textAlignment" size="small">
              <el-radio-button label="left">左对齐</el-radio-button>
              <el-radio-button label="center">居中对齐</el-radio-button>
              <el-radio-button label="right">右对齐</el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="上边距" prop="paddingTop">
            <el-slider v-model="detail.paddingTop" show-input :max="100"></el-slider>
          </el-form-item>

          <el-form-item label="下边距" prop="paddingBottom">
            <el-slider v-model="detail.paddingBottom" show-input :max="100"></el-slider>
          </el-form-item>
        </el-form>
      </template>
    </base-box>
  </section>
</template>

<script>
import BaseBox from './box.vue'
import Twitter from '@/components/twitterColor.vue'
export default {
  components: {
    'base-box': BaseBox,
    'twitter-color': Twitter
  },
  props: {
    detail: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      rules: {
        content: [{ required: true, message: '请输入文本内容', trigger: 'blur' }]
      }
    }
  },
  methods: {
    validateForm() {
      let flag = true
      this.$refs.textForm.validate(valid => {
        flag = valid
      })

      return flag
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}

.update {
  width: 130px !important;
  height: 130px;
  line-height: 130px;
  text-align: center;
  border: 1px solid #edecf2;
  background-color: #fbfafd;
  border-radius: 3px;
  cursor: pointer;
  font-size: 30px;
  color: #7d7d7d;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .avatar {
    width: 130px;
  }
}

.image-updata {
  display: flex !important;
  align-items: flex-start !important;
}

.ldy-bg-color {
  width: 28px;
  height: 28px;
  border: 5px solid #bfc2c7;
  border-radius: 4px;
}

.setting {
  display: flex;
  align-items: flex-start !important;
}

.section {
  .w-100 {
    font-size: 12px;
    margin-right: 10px;
  }
}
</style>
