<template>
  <el-drawer title="广告基础信息" size="900px" :append-to-body="true" custom-class="dialog" :visible.sync="show"
    label-position="right" @close="onCloseDialog">
    <div class="drawer-content">
      <div class="title">营销场景</div>
      <el-form ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="营销链路" prop="marketingPurpose">
          <el-select v-model="form.marketingPurpose" style="width: 300px;" :disabled="true">
            <el-option label="行动转化" value="CONVERSION"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="计划名称" prop="planNameTemplate">
          <el-input v-model="form.planNameTemplate" placeholder="请输入计划名称" maxlength="100" show-word-limit
            style="width: 300px;"></el-input>
        </el-form-item>
        <div class="tip">通配符
          <span @click="onClickSpecialChar('日期')">+日期</span>
          <span @click="onClickSpecialChar('产品名')">+产品名</span>
          <span @click="onClickSpecialChar('投手名称')">+投手名称</span>
          <span @click="onClickSpecialChar('标号')">+标号</span>
          <span @click="onClickSpecialChar('素材名')">+素材名</span>
        </div>

        <div class="title">投放内容</div>
        <el-form-item label="数据对接方式" prop="trackType">
          <el-select v-model="form.trackType" style="width: 300px;" prop="trackType">
            <el-option v-for="item in trackTypes" :label="item.text" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>

        <!-- 当【数据对接方式】为转化追踪 -->
        <el-form-item v-if="form.trackType == 1" label="落地页类型" prop="pageType">
          <el-select v-model="form.pageType" style="width: 300px;">
            <el-option v-for="item in pageTypes" :label="item.text" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>

        <!-- 当【数据对接方式】为事件管理 -->
        <el-form-item v-if="form.trackType == 2" label="推广内容" prop="contentType">
          <el-select v-model="form.contentType" style="width: 300px;">
            <el-option v-for="item in landTypes" :label="item.text" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>

        <!-- 当【数据对接方式】为“事件管理”且【推广内容】为“小程序”时 -->
        <template v-if="form.trackType == 2 && form.contentType == 'MINI_PROGRAME'">
          <el-form-item label="小程序类型" prop="appType">
            <el-select v-model="form.appType" style="width: 300px;">
              <el-option label="字节小程序" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="小程序APPID" prop="appId">
            <el-input v-model="form.appId" placeholder="请输入小程序APPID" style="width: 300px;"></el-input>
          </el-form-item>
          <el-form-item label="小程序链接" prop="appUrl">
            <el-input v-model="form.appUrl" placeholder="请输入小程序链接" style="width: 300px;"></el-input>
          </el-form-item>
          <el-form-item label="启动页面" prop="startPage">
            <el-input v-model="form.startPage" placeholder="请输入启动页面" style="width: 300px;"></el-input>
            <span v-if="showErrorTip.page" class="error-tip">{{ showErrorTip.page }}</span>
          </el-form-item>
          <el-form-item label="启动参数" prop="startParams">
            <el-input v-model="form.startParams" placeholder="请输入启动参数" style="width: 300px;"></el-input>
            <span v-if="showErrorTip.params" class="error-tip">{{ showErrorTip.params }}</span>
          </el-form-item>
        </template>

        <el-form-item label="优化目标">
          <div class="target">
            <!-- 当【数据对接方式】为转化追踪 -->
            <el-form-item v-if="form.trackType == 1" label="转化追踪方式" style="margin-top: 40px;" prop="convertSourceType">
              <el-select v-model="form.convertSourceType" style="width: 300px;">
                <el-option v-for="(itm, idx) in convertSourceTypes" :key="idx" :label="itm.text" :value="itm.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="转化目标选择" style="margin-top: 40px;" prop="convertType">
              <el-select v-model="form.convertType" style="width: 300px;">
                <el-option v-for="(itm, idx) in convertTypes" :key="idx" :label="itm.text" :value="itm.id"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form-item>

        <div class="title">投放范围</div>
        <el-form-item label="广告位置" prop="inventoryCatalog">
          <el-select v-model="form.inventoryCatalog" style="width: 300px;">
            <el-option v-for="(itm, idx) in inventoryCatalogs" :key="idx" :label="itm.text" :value="itm.id"></el-option>
          </el-select>
          <!-- 当【广告位置】为首选媒体 -->
          <div v-if="form.inventoryCatalog === 'MANUAL'" class="media">
            <div class="media-title">首选媒体</div>
            <div class="media-content">
              <div class="media-content-item">
                <el-checkbox :indeterminate="isIndeterminate" v-model="isSelectAll" @change="handleCheckAllChange">全选
                </el-checkbox>
              </div>
              <el-checkbox-group v-model="checkedMediaes" @change="handleCheckedChange">
                <div v-for="(item, index) in mediaTypes" :key="index" class="media-content-item">
                  <el-checkbox :label="item.key" :key="item.key">{{ item.value }}</el-checkbox>
                </div>
              </el-checkbox-group>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="搜索快投" prop="feedDeliverySearch">
          <el-select v-model="form.feedDeliverySearch" style="width: 300px;">
            <el-option label="不启用" :value="'DISABLED'"></el-option>
            <el-option label="启用" :value="'HAS_OPEN'"></el-option>
          </el-select>
          <!-- 当【搜索快投】为启用 -->
          <span v-if="form.feedDeliverySearch === 'HAS_OPEN'" style="margin-left: 25px;"><span>已添加{{
              form.searchKeyWord.length || 0
          }}个关键字</span><span style="margin-left: 10px;font-size: 14px; color: #409EFF; cursor: pointer;"
              @click="handleEditKeyword">编辑</span></span>
        </el-form-item>
        <!-- 当【搜索快投】为启用 -->
        <template v-if="form.feedDeliverySearch === 'HAS_OPEN'">
          <el-form-item label="出价系数" prop="searchBidRatio">
            <el-input-number v-model="form.searchBidRatio" label="请输入出价系数" :min="1" :max="2" :step="0.1" :precision="2">
            </el-input-number>
            <!-- <el-select v-model="form.bidCoefficient" style="width: 300px;">
              <el-option label="不启用" :value="'DISABLED'"></el-option>
              <el-option label="启用" :value="'HAS_OPEN'"></el-option>
            </el-select> -->
            <!-- 当【出价系数】为启用 -->
            <!-- <div style="margin-top: 20px" v-if="form.bidCoefficient == 'HAS_OPEN'">
              <el-input-number v-model="form.searchBidRatio" label="请输入出价系数" :min="1" :max="2" :step="0.1"
                :precision="2"></el-input-number>
            </div> -->
          </el-form-item>
          <el-form-item label="定向拓展" prop="audienceExtend">
            <el-select v-model="form.audienceExtend" style="width: 300px;">
              <el-option label="不启用" :value="'OFF'"></el-option>
              <el-option label="启用" :value="'ON'"></el-option>
            </el-select>
          </el-form-item>
        </template>

        <div class="title">预算与出价</div>
        <el-form-item label="投放场景" prop="smartBidType">
          <el-select v-model="form.smartBidType" style="width: 300px;">
            <el-option v-for="(itm, idx) in smartBidTypes" :key="idx" :label="itm.text" :value="itm.id"></el-option>
          </el-select>
        </el-form-item>

        <!-- 当【投放场景】为常规投放 -->
        <template v-if="form.smartBidType == 1">
          <el-form-item label="竞价策略" prop="flowControlMode">
            <el-select v-model="form.flowControlMode" style="width: 300px;">
              <el-option v-for="(itm, idx) in flowControlTypes" :key="idx" :label="itm.text" :value="itm.id">
              </el-option>
            </el-select>
          </el-form-item>

          <!-- 当【竞价策略】为稳定成本 -->
          <el-form-item v-if="form.flowControlMode == 1" label="投放速度" prop="flowSpeedMode">
            <el-select v-model="form.flowSpeedMode" style="width: 300px;">
              <el-option v-for="(itm, idx) in flowSpeedModeTypes" :key="idx" :label="itm.text" :value="itm.id">
              </el-option>
            </el-select>
          </el-form-item>
        </template>

        <el-form-item label="预算" prop="budgetMode">
          <el-select v-model="form.budgetMode" style="width: 180px;margin-right: 20px;">
            <el-option label="日预算" :value="'BUDGET_MODE_DAY'"></el-option>
            <el-option label="总预算" :value="'BUDGET_MODE_TOTAL'"></el-option>
          </el-select>
          <el-input-number v-model="form.budget" :precision="2" :min="300" :step="100" placeholder="请填写预算金额">
          </el-input-number>
        </el-form-item>

        <el-form-item label="投放时间" prop="scheduleType">
          <el-select v-model="form.scheduleType" style="width: 180px;margin-right: 20px;">
            <el-option label="从今天到长期投放" :value="'SCHEDULE_FROM_NOW'"></el-option>
            <el-option label="设置开始和结束日期" :value="'SCHEDULE_START_END'"></el-option>
          </el-select>
          <!-- 当【投放时间】为设置开始和结束日期 -->
          <template v-if="form.scheduleType === 'SCHEDULE_START_END'">
            <el-date-picker v-model="date" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="-"
              start-placeholder="开始日期" end-placeholder="结束日期" style="width: 400px;" @change="handleChangeDate">
            </el-date-picker>
          </template>
        </el-form-item>
        <el-form-item label="投放时段" prop="scheduleRangeType">
          <el-select v-model="form.scheduleRangeType" style="width: 180px;">
            <el-option label="不限" :value="1"></el-option>
            <el-option label="指定时段" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <!-- 当【投放时段】为指定时段 -->
        <div v-if="form.scheduleRangeType === 2" class="time-range-box">
          <!-- 巨量组件 -->
          <schedule v-model="form.scheduleRange" @change="getDate" />
        </div>
        <el-form-item label="付费方式" prop="pricing">
          <el-select v-model="form.pricing" style="width: 180px;">
            <el-option label="按展示付费(oCPM)" :value="'PRICING_OCPM'"></el-option>
          </el-select>
        </el-form-item>

        <!-- 当【投放场景】为最大转化数投放 不显示转化出价 -->
        <el-form-item v-if="form.smartBidType !== 3" label="转化出价" prop="cpaBid">
          <el-input-number v-model.number="form.cpaBid" :min="0.1" :max="10000" style="width: 180px;">
          </el-input-number> 元
        </el-form-item>

        <div class="title">优化设置</div>
        <el-form-item label="继承优质计划" prop="autoInheritSwitch">
          <el-select v-model="form.autoInheritSwitch" style="width: 180px;">
            <el-option label="不启用" :value="'OFF'"></el-option>
            <el-option label="启用" :value="'ON'"></el-option>
          </el-select>
        </el-form-item>
        <!-- 当【继承优质计划】为启用 -->
        <template v-if="form.autoInheritSwitch === 'ON'">
          <el-form-item label="继承账户类型" prop="inheritType">
            <el-select v-model="form.inheritType" style="width: 180px;">
              <el-option label="账户内计划" value="INHERIT_FROM_ACCOUNT"></el-option>
              <el-option label="同组织下其他广告主" value="INHERIT_FROM_CUSTOMER"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="form.inheritType === 'INHERIT_FROM_CUSTOMER'" label="账户ID" prop="inheritedAdvertiserId">
            <el-input v-model="form.inheritedAdvertiserId" :rows="4" type="textarea" resize="none" placeholder="请输入账户ID，回车换行"
              style="width: 300px;" />
          </el-form-item>
        </template>
      </el-form>
    </div>
    <!--底部按钮-->
    <div class="dialog-footer">
      <div>
        <el-checkbox v-model="form.saveAsPreset">存为预设</el-checkbox>
      </div>
      <div>
        <el-button class="btn" @click="closeModal">取 消</el-button>
        <el-button class="btn" type="primary" :loading="isLoading" :disabled="isLoading"
          @click="handleConfirm('form')">确定
        </el-button>
      </div>
    </div>
    <!-- 关键字弹窗 -->
    <edit-keyword-dialog ref="editKeywordDialog" @confirm="handleKeywordData"></edit-keyword-dialog>
  </el-drawer>
</template>
<script>
import { saveConfigPlan, getPlanDraft } from '@/network/api/api-huge-batch'
import EditKeywordDialog from './EditKeywordDialog'
export default {
  name: 'AdInfoDialog',
  components: {
    'edit-keyword-dialog': EditKeywordDialog
  },
  props: {
    baseId: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    var validaCheckAppId = (rule, value, callback) => {
      if (value && !/^[a-zA-Z0-9]{1,100}$/.test(value)) {
        return callback(new Error('小程序AppId格式不正确'))
      } else {
        return callback()
      }
    }
    return {
      isLoading: false,
      show: false, // 是否显示弹框
      showErrorTip: {
        page: '',
        params: ''
      },
      date: '', // 投放时间
      scheduleRange: '', // 投放时段
      mediaTypes: [
        { key: 'INVENTORY_FEED', value: '头条信息流' },
        { key: 'INVENTORY_VIDEO_FEED', value: '西瓜信息流' },
        { key: 'INVENTORY_HOTSOON_FEED', value: '火山信息流' },
        { key: 'INVENTORY_AWEME_FEED', value: '抖音信息流' },
        { key: 'INVENTORY_TOMATO_NOVEL', value: '番茄小说' },
        { key: 'INVENTORY_UNION_SLOT', value: '穿山甲' },
        { key: 'UNION_BOUTIQUE_GAME', value: 'ohayoo精品游戏' }
      ], // 首选媒体列表
      isIndeterminate: true, // 首选媒体全选半角状态
      isSelectAll: false, // 首选媒体是否全选
      trackTypes: [{ id: 1, text: '转化追踪' }, { id: 2, text: '事件管理' }], // 数据对接方式
      convertSourceTypes: [{ id: 1, text: '线索API' }], // 转化追踪方式
      convertTypes: [{ id: 'AD_CONVERT_TYPE_PAY', text: '付费' }, { id: 'AD_CONVERT_TYPE_CUSTOMER_EFFECTIVE', text: '有效获客' }], // 转化目标选择
      pageTypes: [{ id: 'TETRIS_EXTERNAL', text: '橙子建站' }, { id: 'THIRD_EXTERNAL', text: '第三方落地页' }], // 落地页类型
      landTypes: [{ id: 'TETRIS_EXTERNAL', text: '橙子建站' }, { id: 'THIRD_EXTERNAL', text: '第三方落地页' }, { id: 'MINI_PROGRAME', text: '小程序' }], // 推广内容
      inventoryCatalogs: [{ id: 'UNIVERSAL', text: '通投智选' }, { id: 'MANUAL', text: '首选媒体' }], // 广告位置
      smartBidTypes: [{ id: 1, text: '常规投放' }, { id: 2, text: '放量投放' }, { id: 3, text: '最大转化数投放' }], // 投放场景
      flowControlTypes: [
        { id: 1, text: '稳定成本' }, { id: 2, text: '控制成本上限' }
      ], // 竞价策略
      flowSpeedModeTypes: [{ id: 1, text: '匀速(均衡投放)' }, { id: 2, text: '加速(优选跑量)' }], // 投放速度
      form: {
        startPage: '',
        appUrl: '',
        startParams: ''
      },
      rules: {
        marketingPurpose: [{ required: true, message: '请选择营销链路', trigger: 'change' }],
        planNameTemplate: [{ required: true, message: '请输入计划名称', trigger: 'blur' }],
        trackType: [{ required: true, message: '请选择数据对接方式', trigger: 'change' }],
        pageType: [{ required: true, message: '请选择落地页类型', trigger: 'change' }],
        contentType: [{ required: true, message: '请选择推广内容', trigger: 'change' }],
        convertSourceType: [{ required: true, message: '请选择转化追踪方式', trigger: 'change' }],
        convertType: [{ required: true, message: '请选择转化目标', trigger: 'change' }],
        appType: [{ required: true, message: '请选择小程序类型', trigger: 'change' }],
        appId: [{ required: true, message: '请输入小程序APPID', trigger: 'blur' }, {
          validator: validaCheckAppId, trigger: 'blur'
        }],
        appUrl: [{ required: true, message: '请输入小程序链接', trigger: 'blur' }],
        startPage: [{ required: true, message: '请输入小程序启动页面', trigger: 'blur' }],
        startParams: [{ required: true, message: '请输入小程序启动参数', trigger: 'blur' }],
        inventoryCatalog: [{ required: true, message: '请选择广告位置', trigger: 'change' }],
        searchBidRatio: [{ required: true, message: '请输入出价系数', trigger: 'blur' }],
        smartBidType: [{ required: true, message: '请选择投放场景', trigger: 'change' }],
        flowControlMode: [{ required: true, message: '请选择竞价策略', trigger: 'change' }],
        flowSpeedMode: [{ required: true, message: '请选择投放速度', trigger: 'change' }],
        budgetMode: [{ required: true, message: '请选择预算类型', trigger: 'change' }],
        scheduleType: [{ required: true, message: '请选择投放时间类型', trigger: 'change' }],
        scheduleRangeType: [{ required: true, message: '请选择投放时段类型', trigger: 'change' }],
        cpaBid: [{ required: true, message: '请输入转化出价', trigger: 'blur' }],
        inheritType: [{ required: true, message: '请选择继承账户类型', trigger: 'change' }],
        inheritedAdvertiserId: [{ required: true, message: '请输入账户ID', trigger: 'blur' }]
      },
      // searchKeyWordLength: 0, // 已选择关键字个数
      checkedMediaes: [] // 首选媒体选中项
    }
  },
  watch: {
    'form.appUrl': {
      handler(_value) {
        if (_value) {
          let query = this.validateUrl(_value)
          this.form.startPage = query.startPage
          this.form.startParams = query.startParams
          let appId = this.getAppletParams(_value, 'app_id')
          this.form.appId = appId || ''
        }
      },
      deep: true
    },
    'form.startPage'(value) {
      let query = this.validateUrl(this.form.appUrl)
      if (value && query.startPage && value !== query.startPage) {
        this.showErrorTip.page = '请确实数据正确性！'
      } else {
        this.showErrorTip.page = ''
      }
    },
    'form.startParams'(value) {
      let query = this.validateUrl(this.form.appUrl)
      if (value && query.startParams && value !== query.startParams) {
        this.showErrorTip.params = '请确实数据正确性！'
      } else {
        this.showErrorTip.params = ''
      }
    },
    'form.inheritedAdvertiserId'(newValue, oldValue) {
      if (newValue && this.getAddRow() > 3) {
        this.form.inheritedAdvertiserId = oldValue
        this.$message.warning('最多输入3行')
      }
    }
  },
  methods: {
    // 显示弹框
    showModal(base) {
      this.show = true
      if (base && base.id) {
        this.initData(base)
      } else {
        this.getPresetInfo(base)
      }
    },
    // 关闭弹框
    closeModal() {
      this.show = false
    },
    initData(base) {
      let inventoryTypes = ''
      // let searchKeyWord = ''
      if (base.inventoryTypes !== '') {
        inventoryTypes = base.inventoryTypes || ''
        this.checkedMediaes = base.inventoryTypes ? base.inventoryTypes.split(',') : []
      }
      // if (base.searchKeyWord) {
      //   this.searchKeyWordLength = base.searchKeyWord.split(',').length
      //   searchKeyWord = base.searchKeyWord
      // }
      this.date = []
      this.date.push(base.startTime || '')
      this.date.push(base.endTime || '')
      this.form = {
        id: base.id,
        marketingPurpose: base.marketingPurpose, // 营销链路
        planNameTemplate: base.planNameTemplate, // 计划名称
        trackType: base.trackType, // 数据对接方式
        pageType: base.pageType, // 落地页类型
        contentType: base.contentType, // 推广内容
        convertSourceType: base.convertSourceType, // 转化追踪方式
        convertType: base.convertType, // 转化目标
        inventoryCatalog: base.inventoryCatalog, // 广告位置
        inventoryTypes: inventoryTypes, // 首选媒体
        feedDeliverySearch: base.feedDeliverySearch, // 搜索快投
        searchKeyWord: base.searchKeyWord || [], // 搜索快投关键字
        // bidCoefficient: 'HAS_OPEN', // 出价系数
        searchBidRatio: base.searchBidRatio, // 出价系数值
        audienceExtend: base.audienceExtend, // 定向拓展
        smartBidType: base.smartBidType, // 投放场景
        flowControlMode: base.flowControlMode, // 竞价策略
        flowSpeedMode: base.flowSpeedMode, // 投放速度
        budgetMode: base.budgetMode, // 预算类型
        budget: base.budget, // 预算金额
        scheduleType: base.scheduleType, // 投放时间
        scheduleRangeType: base.scheduleRangeType, // 投放时段
        scheduleRange: base.scheduleRange, // 自定义投放时段
        pricing: base.pricing, // 付费方式
        cpaBid: base.cpaBid, // 转化出价
        autoInheritSwitch: base.autoInheritSwitch, // 继承优质计划
        inheritType: base.inheritType, // 继承账户类型
        inheritedAdvertiserId: base.inheritedAdvertiserId, // 继承账户ID
        appType: base.appType, // 类型
        appId: base.appId, // appid
        appUrl: base.appUrl, // 链接
        startPage: base.startPage, // 启动页面
        startParams: base.startParams, // 启动参数
        startTime: base.startTime || '', // 开始时间
        endTime: base.endTime || '', // 结束时间
        saveAsPreset: base.saveAsPreset // 存为预设
      }
    },

    // 获取预存信息
    getPresetInfo(base) {
      getPlanDraft().then(({ code, data }) => {
        if (code === 200 && Object.keys(data).length) {
          this.initData(data)
        } else {
          this.form = { ...base }
        }
      }).catch(() => {
        this.form = { ...base }
      })
    },

    /* --------------------------- 按钮点击 -------------------------- */

    getAddRow() {
      let str = this.form.inheritedAdvertiserId.split('\n')
      str = str.filter(v => v !== '')
      return str.length || 0
    },
    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      // 重置表单
      // this.searchKeyWordLength = 0
      this.checkedMediaes = []
      this.$refs['form'].clearValidate()
    },

    // 点击确定按钮
    handleConfirm(formName) {
      this.$refs[formName].validate(valid => {
        if (!valid) {
          return
        }
        if (this._validate()) {
          let arr = []
          // 投放时段为指定时段并且投放时段当前是空字符串
          if (this.form.scheduleRangeType === 1 && this.form.scheduleRange === '') {
            for (let i = 0; i < 336; i++) {
              arr.push(0)
            }
            this.form.scheduleRange = arr.join('')
          }

          let params = {
            id: this.form.id || '',
            baseId: this.baseId,
            ...this.form
          }
          this.isLoading = true
          saveConfigPlan(params).then(res => {
            if (res.code === 200) {
              this.$emit('confirm', res.data)
              this.isLoading = false
              this.show = false
            } else {
              this.isLoading = false
            }
          }).catch(() => {
            this.isLoading = false
          })
        }
      })
    },
    // 编辑关键字弹窗
    handleEditKeyword() {
      this.$refs.editKeywordDialog.showModal(this.form.searchKeyWord)
    },
    // 关键字弹窗回调
    handleKeywordData(row) {
      this.form.searchKeyWord = [...row]
      // this.searchKeyWordLength = row.length || 0
    },
    // 时间段弹窗回调
    getDate(val) {
      this.form.scheduleRange = val
    },
    // 首选媒体弹窗选择
    handleCheckedChange(value) {
      let checkedCount = value.length
      this.isSelectAll = checkedCount === this.mediaTypes.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.mediaTypes.length
      this.form.inventoryTypes = this.checkedMediaes.join(',')
    },
    // 媒体弹窗全选
    handleCheckAllChange(val) {
      let ids = this.mediaTypes.map(v => v.key)
      this.checkedMediaes = val ? ids : []
      this.form.inventoryTypes = this.checkedMediaes.join(',')
      this.isIndeterminate = false
    },
    // 增加广告组名通配符
    onClickSpecialChar(str) {
      this.form.planNameTemplate += '<' + str + '>'
    },
    // 修改时间
    handleChangeDate(e) {
      this.form.startTime = e[0]
      this.form.endTime = e[1]
    },
    /* --------------------------- 按钮点击 -------------------------- */
    // 校验数据
    _validate() {
      if (this.form.inventoryCatalog === 'MANUAL' && !this.form.inventoryTypes) {
        this.$WarningMessage('请选择媒体')
        return false
      }
      if (!this.form.budget) {
        this.$WarningMessage('请填写预算金额')
        return false
      }
      if (!this.form.cpaBid) {
        this.$WarningMessage('请填写转化出价')
        return false
      }
      if (this.form.scheduleRangeType === 2 && !this.form.scheduleRange) {
        this.$WarningMessage('请选择时间段')
        return false
      }
      // if (this.form.feedDeliverySearch === 'HAS_OPEN' && !this.form.searchKeyWord.length) {
      //   this.$WarningMessage('请选择关键字')
      //   return false
      // }
      return true
    },

    // 校验小程序链接
    validateUrl(_value) {
      let url = decodeURIComponent(_value)
      let str = url.match(/start_page=(\S*)/)
      str = str && str.length ? str[1] : ''
      const query = str ? str.split('?') : []
      let startPage = query.length ? query[0] : ''
      let startParams = query.length ? query[1] : ''
      return { startPage, startParams }
    },
    getAppletParams(_value, name) {
      let url = decodeURIComponent(_value)
      let reg = new RegExp(name + '=(.*?)(&|$)')
      let res = url.match(reg)
      return res ? res[1] : null
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  position: fixed;
  top: 64px;
  right: 0;
  bottom: 0;
  z-index: 2033;
  display: flex;
  flex-direction: column;
  background-color: #f0f2f5;
  box-shadow: -8px 0 8px rgb(0 0 0 / 20%);

  .tip {
    margin-left: 120px;
    font-size: 12px;

    span {
      margin-left: 10px;
      color: #409eff;
      cursor: pointer;
    }
  }

  .drawer-content {
    height: calc(100vh - 212px);
    padding: 0 16px;
    margin: 16px;
    overflow: auto;
    background: #fff;
    border: 1px solid #e8eaec;
    border-radius: 3px;

    .error-tip {
      color: red;
      font-size: 12px;
    }

    .title {
      margin-top: 41px;
      margin-bottom: 24px;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #333;
      font-weight: 700;
    }

    .douyin-tip {
      font-size: 12px;
      margin-left: 130px;
    }

    .target {
      width: 500px;
      min-height: 120px;
      padding-bottom: 30px;
      box-sizing: border-box;
      border: 1px solid #dcdfe6;
    }

    .time {
      // margin-left: 100px;
      margin-bottom: 10px;
      margin-top: 20px;
    }

    .time-range-box {
      margin-left: 120px;
      margin-bottom: 10px;
    }

    .media {
      width: 300px;
      border: 1px solid #dee4f5;
      margin-top: 20px;

      .media-title {
        height: 40px;
        padding: 0 12px;
        font-size: 14px;
        line-height: 40px;
        color: #333;
        background-color: #fafbfe;
        border-bottom: 1px solid #dee4f5;
      }

      .media-content {
        width: 300px;
        height: 250px;
        display: flex;
        flex-direction: column;
        overflow: auto;
        margin-left: 5px;

        .el-checkbox {
          width: 100%;
          box-sizing: border-box;
        }
      }
    }
  }

  .dialog-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
  }
}
</style>
