<!--
 * @Description: 推广计划模板选择
 * @Date: 2021-11-09 09:32:28
 * @LastEditTime: 2022-05-24 13:31:46
-->
<template>
  <div>
    <Base title="配置区" :desc="taskId ? '基础信息变更后，配置区清空重置' : ''">
      <template slot="topRight"> </template>
      <div>
        <el-descriptions direction="vertical" border>
          <el-descriptions-item label="计划" content-style="width: 14.28%">
            <el-row>
              <el-col :span="24" class=" deploy-item">
                <div class="title-count">
                  <p>计划基础信息</p>
                </div>
                <Plan :visible="planModalVisible" :promoted-object-type="ruleData.promotedObjectType" :form-data="planData" :task-id="taskId" @byTaskIdGetAllFun="byTaskIdGetAllFun" />
                <el-button type="text" class="footer-add" @click="addPlan">{{ planData.flag ? '编辑' : '添加' }}</el-button>
              </el-col>
            </el-row>
          </el-descriptions-item>

          <!-- ------------广告创意--------------- -->

          <el-descriptions-item label="广告" content-style="width: 28.56%">
            <el-row>
              <el-col :span="12" class="divider deploy-item">
                <div class="title-count">
                  <p>广告基础信息</p>
                </div>
                <Advertisement :visible="adModalVisible" :crt-size-label="planData.crtSizeLabel" :promoted-object-type="promotedObjectType" :form-data="adData" :task-id="taskId" @byTaskIdGetAllFun="byTaskIdGetAllFun" />
                <el-button :disabled="!planData.flag" type="text" class="footer-add" @click="addAd">{{ adData.flag ? '编辑' : '添加' }}</el-button>
              </el-col>

              <el-col :span="12" style="padding-left: 8px" class="deploy-item">
                <div class="title-count">
                  <p>定向包</p>
                  <p>已选 {{ orientationData.flag ? orientationData.list.length : 0 }} 个</p>
                </div>
                <OrientationPackage
                  :visible="orientationVisible"
                  :form-data="orientationData"
                  :task-id="taskId"
                  @setFormData="
                    (data) => {
                      orientationData = data
                    }
                  "
                  @byTaskIdGetAllFun="byTaskIdGetAllFun"
                />
                <el-button type="text" :disabled="!planData.flag" class="footer-add" @click="addOrientation">添加</el-button>
              </el-col>
            </el-row>
          </el-descriptions-item>

          <!-- ------------广告创意--------------- -->
          <el-descriptions-item label="广告创意" content-style="width: 57.12%">
            <el-row>
              <el-col :span="6" class="divider deploy-item">
                <div class="title-count">
                  <p>创意基础信息</p>
                </div>
                <CreativityBaseInfo :visible="creativityVisible" :promoted-object-type="ruleData.promotedObjectType" :project-id="projectId" :crt-size-label="planData.crtSizeLabel" :form-data="creativityData" :task-id="taskId" @byTaskIdGetAllFun="byTaskIdGetAllFun" />
                <el-button type="text" :disabled="!planData.flag" class="footer-add" @click="addCreativity">{{ creativityData.flag ? '编辑' : '添加' }}</el-button>
              </el-col>
              <el-col :span="6" style="padding-left: 8px" class="divider deploy-item">
                <div class="title-count">
                  <p>创意素材</p>
                  <p>已选 {{ creativityMaterialData.flag ? creativityMaterialData.list.length : 0 }} 组</p>
                </div>
                <CreativityMaterial
                  :visible="materialVisible"
                  :account-id="accountId"
                  :crt-size-label="planData.crtSizeLabel"
                  :promoted-object-type="ruleData.promotedObjectType"
                  :creativity-material-data-template="creativityMaterialDataTemplate"
                  :form-data="creativityMaterialData"
                  :project-id="projectId"
                  :creativity-data="creativityData"
                  :task-id="taskId"
                  @byTaskIdGetAllFun="byTaskIdGetAllFun"
                />
                <el-button type="text" :disabled="!planData.flag || !creativityData.flag || planData.crtSizeLabel === '朋友圈'" class="footer-add" @click="addMaterial">{{ creativityMaterialData.flag ? '编辑' : '添加' }}</el-button>
              </el-col>

              <el-col :span="6" style="padding-left: 8px" class="divider deploy-item">
                <div class="title-count">
                  <p>创意文案</p>
                  <p>已选 {{ creativityTextData.flag ? creativityTextData.list.length : 0 }} 组</p>
                </div>
                <CreativityText
                  :visible="textVisible"
                  :creativity-data="creativityData"
                  :form-data="creativityTextData"
                  :crt-size-label="planData.crtSizeLabel"
                  :creativity-text-data-template="creativityTextDataTemplate"
                  :project-id="projectId"
                  :task-id="taskId"
                  @byTaskIdGetAllFun="byTaskIdGetAllFun"
                />
                <el-button type="text" :disabled="!planData.flag || !creativityData.flag || ['展示Banner图片', '小程序banner广告'].includes(planData.crtSizeLabel)" class="footer-add" @click="addText">{{ creativityTextDataTemplate.flag ? '编辑' : '添加' }}</el-button>
              </el-col>

              <el-col :span="6" style="padding-left: 8px" class="deploy-item">
                <div class="title-count">
                  <p>落地页</p>
                  <p>已选 {{ ldyData.flag ? ldyData.list.length : 0 }} 条</p>
                </div>
                <Ldy
                  :visible="ldyModalVisible"
                  :form-data="ldyData"
                  :task-id="taskId"
                  :creativity-data="creativityData"
                  :crt-size-label="planData.crtSizeLabel"
                  @setFormData="
                    (data) => {
                      ldyData = data
                    }
                  "
                  @byTaskIdGetAllFun="byTaskIdGetAllFun"
                />
                <el-button type="text" class="footer-add" :disabled="!planData.flag || !creativityData.flag || !(creativityData.pageType && creativityData.pageType === 4)" @click="addLDY">添加</el-button>
              </el-col>
            </el-row>
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <template slot="footer">
        <div class="justify-sb">
          <!-- //:disabled="!planData.flag" -->
          <div>
            <el-button v-if="isCustomer" :disabled="!planData.flag" @click="addCustomer">企微客服组</el-button>
            <el-button v-if="adData.trackingSourceid === '0'" class="ml-10" @click="addSource">数据源</el-button>
            <!--  -->
            <el-button v-if="orientationData.list.some((s) => s.isOnlineCrowd)" class="ml-10" @click="addCrowd">人群包</el-button>
            <el-button v-if="planData.flag" class="ml-10" @click="addGoods">商品选择</el-button>
          </div>
          <el-button
            type="primary"
            :loading="showPreviewFunLoading"
            :disabled="showPreviewFunLoading || !planData.flag || !adData.flag || !orientationData.flag || !creativityData.flag || !creativityMaterialData.flag || !creativityTextData.flag || !ldyData.flag || !customerData.flag"
            @click="showPreviewFun({ pageNum: 1, pageSize: 10 })"
          >
            预览广告
          </el-button>
        </div>
      </template>
    </Base>

    <Customer v-if="isCustomer" :visible="customerVisible" :project-id="projectId" :account-adv="accountAdv" :form-data="customerData" :task-id="taskId" @byTaskIdGetAllFun="byTaskIdGetAllFun" />

    <Source v-if="adData.trackingSourceid === '0'" :visible="sourceVisible" :project-id="projectId" :account-adv="accountAdv" :form-data="sourceData" :task-id="taskId" @byTaskIdGetAllFun="byTaskIdGetAllFun" />

    <Crowd v-if="orientationData.list.some((s) => s.isOnlineCrowd)" :visible="crowdVisible" :project-id="projectId" :account-adv="accountAdv" :form-data="crowdData" :task-id="taskId" @byTaskIdGetAllFun="byTaskIdGetAllFun" />

    <Goods v-if="planData.flag" :visible="goodsVisible" :project-id="projectId" :account-adv="accountAdv" :form-data="goodsData" :task-id="taskId" @byTaskIdGetAllFun="byTaskIdGetAllFun" />
  </div>
</template>

<script>
import Base from '../../../advManagement/baidu/baiduBatch/component/base.vue'
// 配置区
import Plan from './component/plan.vue'
import Advertisement from './component/advertisement.vue'
import Crowd from './component/crowd.vue'
import Customer from './component/customer.vue'
import Goods from './component/goods.vue'

import Source from './component/source.vue'
import CreativityBaseInfo from './component/creativityBaseInfo.vue'
import CreativityMaterial from './component/creativityMaterial.vue'
import CreativityText from './component/creativityText.vue'
import OrientationPackage from './component/orientationPackage.vue'
import Ldy from './component/ldy.vue'

import { byTaskIdGetAll, generateCode, getGenerateList, updateBaseInfo } from '@/network/api/advManagement/advManagementWatch'
import { creativityDataList, creativityTextApiParams, creativityDataListByCreateGroup } from './util'
import { jsonFormat } from '@/utils/ruoyi.js'
import { creativityBaseData } from './component/creativityBaseData.js'
import moment from 'moment'

export default {
  provide: function() {
    return {
      getDataByLeftQuery: this.getPlanFun,
      close: () => {
        this.planModalVisible = false
        this.adModalVisible = false
        this.ldyModalVisible = false
        this.orientationVisible = false
      },
      closeCreativity: () => {
        this.creativityVisible = false
      },
      closeRuleDeploy: () => {
        this.ruleDeployVisible = false
      },
      closeMaterial: () => {
        this.materialVisible = false
      },
      closeText: () => {
        this.textVisible = false
      },
      customerClose: () => {
        this.customerVisible = false
      },
      sourceClose: () => {
        this.sourceVisible = false
      },
      crowdClose: () => {
        this.crowdVisible = false
      },

      goodsClose: () => {
        this.goodsVisible = false
      }
    }
  },
  components: {
    Base,
    Plan, // 计划页面
    Advertisement, // 广告页面
    OrientationPackage, // 定向包页面
    CreativityBaseInfo, // 创意基础信息页面
    CreativityMaterial, // 创意素材页面
    CreativityText, // 创意文案页面
    Ldy, // 落地页页面
    Customer, // 企微客服组 页面
    Source, // 精准归因
    Crowd, // 人群包
    Goods // 商品选择
  },

  props: {
    productListed: {
      type: Array,
      default: () => []
    },
    accountAdv: {
      type: Array,
      default: () => []
    },
    taskId: {
      type: Number,
      default: 0
    },
    projectId: {
      type: String,
      default: ''
    },
    promotedObjectType: {
      type: String,
      default: ''
    },
    accountId: {
      type: Array,
      default: () => []
    },
    generate: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    ruleData: {
      type: Object,
      default: () => {}
    },
    // 服务商信息
    spid: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showPreviewFunLoading: false, // 预览广告loading
      ruleDeployVisible: false, // 规则配置
      isRuleDeploy: false,
      paramsTaskId: this.$route.params.taskId || 0,

      // 计划 start
      planModalVisible: false,
      planData: {
        cname: `计划名称-${moment().format('YYYYMMDDHHmmss')}`,
        isFriendCircle: false,
        adsAddress: null,
        crtSizeId: this.promotedObjectType === 'PRODUCTTYPE_WECHAT' ? 111 : 22,
        otherId: this.promotedObjectType === 'PRODUCTTYPE_WECHAT' ? 1 : 2,
        crtSize: '666',
        crtSizeLabel: this.promotedObjectType === 'PRODUCTTYPE_WECHAT' ? '朋友圈' : '公众号文章中部',
        ctype: 'CAMPAIGNTYPE_AUCTION',
        dayBudget: 50,
        flag: false
      },
      // 计划 end

      // 广告 start
      adModalVisible: false,
      adData: {
        aname: `广告名称-${moment().format('YYYYMMDDHHmmss')}`,
        launchData: '1',
        trackingSourceid: '1', // 归因方式
        date: [
          moment().format('YYYY-MM-DD'),
          moment()
            .subtract('days', -30)
            .format('YYYY-MM-DD')
        ],
        date2: moment().format('YYYY-MM-DD'),
        bidStrategy: 1,
        bidObjective: null,
        bidObjectiveMode: '1',
        dayBudget: 50,
        secondBid: 1,
        bid: 0.82
      },
      // 广告 end

      // 定向包 start
      orientationVisible: false,
      orientationData: {
        list: [],
        flag: false
      },
      // 定向包 end

      // 创意基础信息 start
      creativityVisible: false,
      creativityData: {
        ...creativityBaseData[creativityDataList[this.promotedObjectType === 'PRODUCTTYPE_WECHAT' ? '朋友圈' : '公众号文章中部']],
        flag: false
      },
      // 创意基础信息 end

      // 创意素材 start
      materialVisible: false,
      creativityMaterialDataTemplate: [
        {
          taskId: this.taskId,
          localAssetId: null,
          localAssetUrl: null,
          localAssetMd: null,
          type: 'img'
        }
      ],
      creativityMaterialData: {
        list: [
          {
            groupName: '创意组01',
            num: 1,
            taskId: this.taskId,
            type: 'img',
            adMpManagementTaskImgVideoENTList: [
              {
                creativeGroup: '创意组01',
                taskId: this.taskId,
                localAssetId: null,
                localAssetUrl: null,
                localAssetMd: null,
                type: 'img'
              }
            ]
          }
        ],
        flag: false
      },
      // 创意素材 end

      // 创意文案 start
      textVisible: false,
      creativityTextDataTemplate: [JSON.parse(JSON.stringify(creativityTextApiParams[this.promotedObjectType === 'PRODUCTTYPE_WECHAT' ? '朋友圈' : '公众号文章中部']))],
      creativityTextData: {
        list: [
          {
            groupName: '创意组01',
            num: 1,
            taskId: this.taskId,
            type: 'text',
            textType: creativityDataListByCreateGroup[this.promotedObjectType === 'PRODUCTTYPE_WECHAT' ? '朋友圈' : '公众号文章中部'],
            adMpManagementTaskImgVideoENTList: [JSON.parse(JSON.stringify(creativityTextApiParams[this.promotedObjectType === 'PRODUCTTYPE_WECHAT' ? '朋友圈' : '公众号文章中部']))]
          }
        ],
        flag: false
      },
      creativityTextTotal: 0,
      // 创意文案 end

      // 落地页 start
      ldyModalVisible: false,
      ldyData: {
        list: [],
        flag: false
      },
      ldyTotal: 0,
      // 落地页 end

      // 企微客服组 start
      customerVisible: false,
      customerData: {
        list: [],
        flag: false
      },
      // 是否纯在企微客服组
      isCustomer: false,
      // 企微客服组 end

      // 数据源 start
      sourceVisible: false,
      sourceData: {
        list: [],
        flag: false
      },
      // 数据源 end

      // 人群包 start
      crowdVisible: false,
      crowdData: {
        list: [],
        flag: false
      },
      // 人群包 end

      // 商品库 start
      goodsVisible: false,
      goodsData: {
        list: [],
        flag: false
      },
      // 商品库 end

      baiduMePlan: [], // 百度计划模板list
      adMpManagementTaskENT: [], // 主任务
      adMpManagementTaskAccountENT: []
    }
  },

  watch: {
    promotedObjectType: {
      handler() {
        this.adForm.bidObjective = null
        // if (!this.taskId) return
        // this.planData.isFriendCircle = value === 'PRODUCTTYPE_WECHAT'
        // this.planData.crtSizeId = value === 'PRODUCTTYPE_WECHAT' ? 111 : 22
        // this.planData.otherId = value === 'PRODUCTTYPE_WECHAT' ? 1 : 2
        // this.planData.crtSizeLabel = value === 'PRODUCTTYPE_WECHAT' ? '朋友圈' : '公众号文章中部'
      }
    },

    accountAdv: {
      handler(value) {
        let list = []
        value.map((item) => {
          list.push({
            taskServiceId: null,
            name: item.name,
            ghid: item.ghid,
            appids: [],
            groupid: null,
            corpid: null
          })
        })

        this.customerData = {
          ...this.customerData,
          list
        }
      }
    }
  },

  methods: {
    /**
     * @description 每次更新后获取最新数据
     * @param taskId 当前任务Id
     */
    byTaskIdGetAllFun(taskId = this.taskId) {
      byTaskIdGetAll(taskId).then((res) => {
        if (res.code === 200) {
          const {
            adMpManagementTaskPlanCampaignENT,
            adMpManagementTaskPlanAdGroupENT,
            adMpManagementTaskMaterialENT,
            adMpManagementTaskPlanAdTargetENTList,
            adMpManagementTaskPageENT,
            adMpManagementTaskAssetGroupVOS,
            adMpManagementTaskPlanCustomerServiceENTS,
            adMpManagementTaskAssetGroupTextVOS,
            adMpManagementTaskENT,
            adMpManagementTaskLocalProductENT,
            imgs,
            adMpManagementTaskAccountENT
          } = res.data
          this.$emit('getBaseInfo', { account: adMpManagementTaskAccountENT, management: adMpManagementTaskENT, localProductENT: adMpManagementTaskLocalProductENT })
          this.adMpManagementTaskENT = adMpManagementTaskENT
          this.adMpManagementTaskAccountENT = adMpManagementTaskAccountENT
          // 计划
          if (adMpManagementTaskPlanCampaignENT) {
            const { otherId, crtSizeId, crtSize, crtSizeLabel } = adMpManagementTaskPlanCampaignENT
            this.ruleData.promotedObjectType = adMpManagementTaskENT.promotedObjectType
            this.planData = {
              ...adMpManagementTaskPlanCampaignENT,
              adsAddress: `${otherId}+${crtSizeId}+${crtSize}+${crtSizeLabel}`,
              flag: true
            }
          } else {
            this.planData = {
              cname: `计划名称-${moment().format('YYYYMMDDHHmmss')}`,
              isFriendCircle: false,
              adsAddress: null,
              crtSizeId: this.promotedObjectType === 'PRODUCTTYPE_WECHAT' ? 111 : 22,
              otherId: this.promotedObjectType === 'PRODUCTTYPE_WECHAT' ? 1 : 2,
              crtSize: '666',
              crtSizeLabel: this.promotedObjectType === 'PRODUCTTYPE_WECHAT' ? '朋友圈' : '公众号文章中部',
              ctype: 'CAMPAIGNTYPE_AUCTION',
              dayBudget: 50,
              flag: false
            }
          }

          // 广告
          if (adMpManagementTaskPlanAdGroupENT) {
            let strategyOpt = jsonFormat(adMpManagementTaskPlanAdGroupENT.strategyOpt, 'object')

            this.adData = {
              ...this.adData,
              isFriendCircle: adMpManagementTaskPlanCampaignENT.crtSizeLabel === '朋友圈',
              ...adMpManagementTaskPlanAdGroupENT,
              secondBid: strategyOpt.second_bid / 100,
              bid: adMpManagementTaskPlanAdGroupENT.bid / 100,
              launchData: adMpManagementTaskPlanAdGroupENT.endTime === 0 ? '2' : '1',
              secondBidObjective: strategyOpt.second_bid_objective,
              // beginTime: adMpManagementTaskPlanAdGroupENT.beginTime,
              date: adMpManagementTaskPlanAdGroupENT.endTime === 0 ? [] : [moment(moment.unix(adMpManagementTaskPlanAdGroupENT.beginTime)).format('YYYY-MM-DD'), moment(moment.unix(adMpManagementTaskPlanAdGroupENT.endTime)).format('YYYY-MM-DD')],
              date2: adMpManagementTaskPlanAdGroupENT.endTime === 0 ? moment(moment.unix(adMpManagementTaskPlanAdGroupENT.beginTime)).format('YYYY-MM-DD') : [],
              flag: true
            }
          } else {
            this.adData = {
              aname: `广告名称-${moment().format('YYYYMMDDHHmmss')}`,
              launchData: '1',
              isFriendCircle: this.planData.crtSizeLabel === '朋友圈',
              date: [
                moment().format('YYYY-MM-DD'),
                moment()
                  .subtract('days', -30)
                  .format('YYYY-MM-DD')
              ],
              date2: [],
              trackingSourceid: '1', // 归因方式
              bidStrategy: 1,
              bidObjectiveMode: '1',
              bidObjective: null,
              dayBudget: 50,
              secondBid: 1,
              secondBidObjective: null,
              bid: 0.82
            }
          }

          // 定向包
          if (adMpManagementTaskPlanAdTargetENTList && adMpManagementTaskPlanAdTargetENTList.length) {
            this.orientationData = {
              list: adMpManagementTaskPlanAdTargetENTList,
              flag: true
            }
          } else {
            this.orientationData = {
              list: [],
              flag: false
            }
          }

          // 创意基础信息

          if (adMpManagementTaskMaterialENT) {
            this.creativityData = {
              ...this.creativityData,
              ...adMpManagementTaskMaterialENT,
              pageTopImgFlag: !!imgs,
              pageTopImgs: imgs,
              flag: true
            }
          } else {
            this.creativityData = {
              ...creativityBaseData[creativityDataList[this.planData.crtSizeLabel]],
              flag: false
            }
          }

          // 创意素材
          if (adMpManagementTaskAssetGroupVOS && adMpManagementTaskAssetGroupVOS.length) {
            this.creativityMaterialData = {
              list: adMpManagementTaskAssetGroupVOS,
              flag: true
            }
          } else {
            let list = [
              {
                creativeGroup: '创意组01',
                taskId: this.taskId,
                localAssetId: null,
                localAssetUrl: null,
                localAssetMd: null,
                type: ['展示Banner图片', '优雅横版大图', '公众号文章底部图片', '公众号文章中部', '小程序banner广告'].includes(this.planData.crtSizeLabel) ? 'img' : 'video'
              }
            ]

            if (['展示Banner图片', '优雅横版大图', '公众号文章底部图片', '公众号文章中部', '小程序banner广告'].includes(this.planData.crtSizeLabel)) {
              this.creativityMaterialData = {
                list: [
                  {
                    groupName: '创意组01',
                    taskId: this.taskId,
                    type: ['展示Banner图片', '优雅横版大图', '公众号文章底部图片', '公众号文章中部', '小程序banner广告'].includes(this.planData.crtSizeLabel) ? 'img' : 'video',
                    adMpManagementTaskImgVideoENTList: [list[0]]
                  }
                ],
                flag: adMpManagementTaskPlanCampaignENT ? adMpManagementTaskPlanCampaignENT.crtSizeLabel === '朋友圈' : false
              }
            } else {
              list[0].type = 'video'
              list[1] = {
                imgVideoId: null,
                localAssetId: null,
                localAssetUrl: null,
                localAssetMd: null,
                type: 'imgs',
                taskId: this.taskId
              }
              this.creativityMaterialData = {
                list: [
                  {
                    groupName: `创意组01`,
                    taskId: this.taskId,
                    type: 'video',
                    adMpManagementTaskImgVideoENTList: list
                  }
                ],
                flag: adMpManagementTaskPlanCampaignENT ? adMpManagementTaskPlanCampaignENT.crtSizeLabel === '朋友圈' : false
              }
            }
          }

          // 创意文案
          if (adMpManagementTaskAssetGroupTextVOS && adMpManagementTaskAssetGroupTextVOS.length) {
            this.creativityTextData = {
              list: adMpManagementTaskAssetGroupTextVOS,
              flag: true
            }
          } else {
            if (['朋友圈', '优雅横版大图', '公众号文章中部', '沉浸式横版视频', '公众号文章底部图片', '公众号文章底部视频'].includes(this.planData.crtSizeLabel)) {
              this.creativityTextData = {
                list: [
                  {
                    groupName: '创意组01',
                    taskId: this.taskId,
                    type: 'text',
                    textType: creativityDataListByCreateGroup[this.planData.crtSizeLabel],
                    adMpManagementTaskImgVideoENTList: [JSON.parse(JSON.stringify(creativityTextApiParams[this.planData.crtSizeLabel] || ''))]
                  }
                ],
                flag: false
              }
            } else {
              this.creativityTextData = {
                list: [],
                flag: true
              }
            }
          }

          // 落地页
          if (adMpManagementTaskPageENT && adMpManagementTaskPageENT.length) {
            this.ldyData = {
              list: adMpManagementTaskPageENT,
              flag: true
            }
            // 落地页存在加企业客服组
            if (adMpManagementTaskPageENT.some((s) => s.isEnterpriseWx === 1)) {
              this.isCustomer = true
              this.customerData.flag = false
            } else {
              this.isCustomer = false
              this.customerData.flag = true
            }
          } else {
            this.ldyData = {
              list: [],
              flag: this.creativityData.pageType === 6
            }
            this.isCustomer = false
            this.customerData.flag = true
          }

          // 企微客服组
          if (adMpManagementTaskPlanCustomerServiceENTS && adMpManagementTaskPlanCustomerServiceENTS.length) {
            this.customerData.list = adMpManagementTaskPlanCustomerServiceENTS
            this.customerData.flag = true
          }

          // 数据源
          if (adMpManagementTaskAccountENT && adMpManagementTaskAccountENT.length) {
            let data = []
            adMpManagementTaskAccountENT.map((item) => {
              if (item.trackingSourceid) {
                data.push({
                  id: `${+JSON.parse(item.trackingSourceid)[0]}+${item.taskAccountId}`,
                  user_action_set_id: +JSON.parse(item.trackingSourceid)[0],
                  name: item.trackingSourceidName,
                  taskAccountId: item.taskAccountId,
                  accountName: item.accountName,
                  show_name: `${item.trackingSourceidName}_(${item.accountName})`
                })
              }
            })
            this.sourceData.list = data
            this.sourceData.flag = true
          }

          // 商品选择
          if (adMpManagementTaskAccountENT && adMpManagementTaskAccountENT.length) {
            let data = []
            adMpManagementTaskAccountENT.map((item) => {
              if (item.productJson) {
                let info = jsonFormat(item.productJson, 'object')
                let { name } = jsonFormat(info.productJsonName, 'object')
                data.push({
                  id: `${info.dynamic_ad_product_id}+${item.taskAccountId}`,
                  name: name,
                  taskAccountId: info.taskAccountId,
                  accountName: item.accountName,
                  show_name: `${name}_(${item.accountName})`
                })
              }
            })
            this.goodsData.list = data
            this.goodsData.flag = true
          }

          // 人群包
          if (adMpManagementTaskAccountENT && adMpManagementTaskAccountENT.length) {
            let data = []
            let data2 = []

            adMpManagementTaskAccountENT.map((item) => {
              let inDmpAudience = jsonFormat(item.inDmpAudienceInfo)
              let notInDmpAudience = jsonFormat(item.notInDmpAudienceInfo)
              if (inDmpAudience || notInDmpAudience) {
                data.push(...inDmpAudience)
                data2.push(...notInDmpAudience)
              }
            })
            this.crowdData.list = [data, data2]
            this.crowdData.flag = true
          }

          // 编辑时, 配置区填写完整,自动预览广告
          if (this.planData.flag && this.adData.flag && this.orientationData.flag && this.creativityData.flag && this.creativityMaterialData.flag && this.creativityTextData.flag && this.ldyData.flag && this.customerData.flag && this.$route.params.taskId) {
            this.showPreviewFun()
          }
          this.$emit('setLoading', false)
        }
      })
    },

    /**
     * @desc 获取预览广告信息
     */
    showPreviewFun() {
      if (this.adData.trackingSourceid === '0' && this.adMpManagementTaskAccountENT.some((s) => !s.trackingSourceid)) {
        return this.$message.error(`有账户未选择数据源!`)
      }

      if (this.orientationData.list.some((s) => s.isOnlineCrowd) && this.adMpManagementTaskAccountENT.some((s) => !(s.notInDmpAudienceInfo || s.inDmpAudienceInfo))) {
        return this.$message.error(`有账户未选择人群包!`)
      }

      this.showPreviewFunLoading = true
      if (!this.adMpManagementTaskENT.generate) {
        generateCode(this.taskId).then(async (res) => {
          if (res.code === 200) {
            this.getGenerateListFun()
          }
        })
      } else {
        this.getGenerateListFun()
      }
    },

    async getGenerateListFun() {
      const { code, data } = await getGenerateList(this.taskId) // { taskId: this.taskId, pageNum, pageSize }
      if (code === 200) {
        let dataList = []
        let adDataBase = data.adMpManagementGenerateRelationENTS
        data.adMpManagementTaskAccountENTS.map((item) => {
          item.adMpManagementTaskGenerateENT &&
            item.adMpManagementTaskGenerateENT.map((item_task) => {
              let adData = JSON.parse(JSON.stringify(data.showDataitems.adMpManagementTaskPlanAdGroupENT))
              let filterData = []
              if (adDataBase.length) {
                filterData = adDataBase.filter((f) => f.generateId === item_task.id)
                if (filterData.length) {
                  adData.bid = filterData[0].bid || adData.bid
                  adData.beginTime = filterData[0].beginTime || adData.beginTime
                  adData.endTime = filterData[0].launchData === 2 ? null : filterData[0].endTime
                }
              }
              dataList.push({
                generate: item_task,
                account: item,
                count: item.adMpManagementTaskGenerateENT.length,
                baseData: data.showDataitems.adMpManagementTaskPlanCampaignENT,
                plan: data.showDataitems.adMpManagementTaskPlanCampaignENT,
                creativityData: data.showDataitems.adMpManagementTaskENT,
                adData: filterData.length ? JSON.parse(JSON.stringify(adData)) : data.showDataitems.adMpManagementTaskPlanAdGroupENT
              })
            })
        })
        if (!this.$route.params.taskId) {
          this.byTaskIdGetAllFun(this.taskId)
        }
        this.showPreviewFunLoading = false
        this.$emit('getShowPreviewFun', dataList)
      }
    },

    /**
     * @description 添加计划
     */
    addPlan() {
      this.$emit('saveTaskFun', 'planModalVisible')
    },
    /**
     * @description 添加广告
     */
    addAd() {
      this.$emit('saveTaskFun', 'adModalVisible')
    },

    /**
     * @description 添加定向包
     */
    addOrientation() {
      this.$emit('saveTaskFun', 'orientationVisible')
    },

    /**
     * @description 创意基础信息
     */
    addCreativity() {
      this.$emit('saveTaskFun', 'creativityVisible')
    },
    /**
     * @description 创意素材
     */
    addMaterial() {
      this.$emit('saveTaskFun', 'materialVisible')
    },

    /**
     * @description 创意文案
     */
    addText() {
      this.$emit('saveTaskFun', 'textVisible')
    },
    /**
     * @description 落地页
     */
    addLDY() {
      this.$emit('saveTaskFun', 'ldyModalVisible')
    },

    /**
     * @description 企微客服
     */
    addCustomer() {
      this.$emit('saveTaskFun', 'customerVisible')
    },

    /**
     * @description 数据源
     */
    addSource() {
      this.$emit('saveTaskFun', 'sourceVisible')
    },

    /**
     * @description 人群包
     */
    addCrowd() {
      this.$emit('saveTaskFun', 'crowdVisible')
    },

    /**
     * @description 商品库
     */
    addGoods() {
      this.$emit('saveTaskFun', 'goodsVisible')
    },

    /**
     * @description 重新配置
     */
    async updateBaseInfoFun() {
      let accountENTList = []

      if (this.ruleData.ghid) {
        accountENTList = JSON.parse(JSON.stringify(this.ruleData.ghid)).map((item) => {
          let [localAccountId, accountId, accountName] = item.split('+')
          let account = this.adMpManagementTaskAccountENT.filter((f) => f.accountId === accountId)
          return {
            taskId: this.taskId,
            localAccountId,
            accountId,
            accountName,
            taskAccountId: account.length ? account[0].taskAccountId : null
          }
        })
      }

      let adMpManagementTaskENT = {
        taskId: this.taskId,
        promotedObjectType: this.ruleData.promotedObjectType,
        taskName: this.ruleData.taskName,
        projectId: this.ruleData.projectId,
        agencyName: this.ruleData.spid.split('+')[0],
        localAmsId: +this.ruleData.spid.split('+')[1],
        isAddPlan: true
      }
      const { code } = await updateBaseInfo({ adMpManagementTaskENT, adMpManagementTaskLocalProductENT:
      /* eslint-disable */
           this.ruleData.productIds !== '-1' 
           ? [{ "productId": this.ruleData.productIds, "productName": this.productListed.filter((item) => item.bsProductId === +this.ruleData.productIds)[0].productName}] 
           : null
        /* eslint-disable */, adMpManagementTaskAccountENT: accountENTList })

      if (code === 200) {
        this.byTaskIdGetAllFun()
      }
    },

    /**
     * @description 开始新任务----------------------后期修改
     */
    startNewTask() {
      this.isRuleDeploy = false

      // 计划
      this.planData = []
      // 广告
      this.adData = []
      // 创意基础信息
      this.creativityData = {
        adcId: null,
        adcreativeTemplateId: 711,
        buttonText: null,
        bottomText: null,
        adName: `创意名称:${new Date().getHours()}:${new Date().getMinutes()}`,
        userId: ''
      }
      this.adcreativesTemplate = null
      // 创意素材
      this.creativityMaterialData = {
        list: [
          {
            groupName: '创意组01',
            taskId: this.taskId,
            type: ['展示Banner图片', '优雅横版大图', '公众号文章底部图片', '公众号文章中部', '小程序banner广告'].includes(this.planData.crtSizeLabel) ? 'img' : 'video',
            adMpManagementTaskImgVideoENTList: [
              {
                creativeGroup: '创意组01',
                taskId: this.taskId,
                localAssetId: null,
                localAssetUrl: null,
                localAssetMd: null,
                type: ['展示Banner图片', '优雅横版大图', '公众号文章底部图片', '公众号文章中部', '小程序banner广告'].includes(this.planData.crtSizeLabel) ? 'img' : 'video'
              }
            ]
          }
        ],
        flag: false
      }
      // 创意文案
      this.creativityTextData = {
        flag: false
      }
      // 落地页
      this.ldyData = {}
      // 品牌
      this.customerData = []
    }
  }
}
</script>

<style lang="scss">
.deploy-item {
  .is-disabled {
    color: #c0c4cc !important;
  }
}
</style>

<style scoped lang="scss">
.divider {
  border-right: 1px solid #dcdfe6;
  padding-right: 5px;
}
.deploy-item {
  display: flex;
  flex-direction: column;
}
.title-count {
  border-bottom: 1px #ccc solid;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 0 3px 8px 1px;
  p {
    padding: 0;
    margin: 0;
  }
}
.footer-add {
  border-top: 1px #ccc solid !important;
  display: flex;
  justify-content: center;
  padding: 10px 0 0 0;
  cursor: pointer;
  color: #409eff;
}
</style>
