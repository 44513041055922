<!--
 * @Description: 商品现在
 * @Date: 2021-12-06 15:57:16
 * @LastEditTime: 2022-04-20 16:54:45
-->
<template>
  <div class="goods">
    <!-- 添加计划 -->
    <SelectModal
      ref="modal"
      type="table"
      dialog-title="商品"
      left-title="投放账户"
      :title-info="{
        title: '选择推广计划',
        leftLevel: '1'
      }"
      :visible="visible"
      :left-query-list="accountAdv.map((item) => ({ _name: item.name, _id: item.taskAccountId }))"
      :table-data-source="dataSource"
      :select-page-list="selectPageList"
      @onOk="getSelectSourceData"
    />
  </div>
</template>

<script>
import { searchGoods, saveProductList, getDynamicCategoryId } from '@/network/api/advManagement/advManagementWatch'
import SelectModal from '@/components/selectModal/selectModal.vue'
import _ from 'lodash'

export default {
  inject: ['goodsClose'],
  provide: function() {
    return {
      getDataByLeftQuery: this.searchGoodsFun,
      queryFun1: this.sourceSync,
      queryText1: '同步',
      close: this.goodsClose,
      setSelectPageList: this.setSelectPageList,
      deleteSelectData: this.deleteSelectSourceData
    }
  },

  components: {
    SelectModal
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    accountAdv: {
      type: Array,
      default: () => []
    },
    taskId: {
      type: Number,
      default: 0
    },
    formData: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      selectPageList: [],
      // 当前选中的id
      taskAccountId: 0,
      // 表格数据
      dataSource: {
        data: [],
        cols: [
          {
            label: '商品名称',
            prop: 'name'
          },
          {
            label: '商品ID',
            prop: 'product_outer_id'
          }
        ], // 表格的列数据
        isIndex: false, // 列表序号
        loading: true, // loading
        isOperation: false,
        isSelection: true,
        isBorder: true,
        selectAll: async (selection) => {
          if (selection.length > 0) {
            let selectionList = []
            // 添加 dynamic_category_id字段
            selection.forEach(async (element) => {
              if (!element.dynamic_category_id) {
                const res = await getDynamicCategoryId({ catalogId: element.catalog_id, taskAccountId: element.taskAccountId, productOuterIds: element.product_outer_id })
                element.dynamic_category_id = res.data.dynamic_category_id
              }
            })

            selectionList.push(...selection)
            let list = []
            selectionList.map((item) => {
              if (list.length === 0 || !list.some((s) => s.id === item.id)) {
                list.push(item)
              }
            })

            let arr = [...this.selectPageList, ...list]

            let newArr = []
            arr.forEach((item) => {
              if (newArr.length === 0 || !newArr.some((s) => s.id === item.id)) {
                newArr.push(item)
              }
            })
            let groupByAccountId = _.groupBy(newArr, 'taskAccountId')
            let pageList = []
            for (const key in groupByAccountId) {
              if (Object.hasOwnProperty.call(groupByAccountId, key)) {
                const element = groupByAccountId[key]
                pageList.push(element[element.length - 1])
              }
            }
            this.selectPageList = pageList
          } else {
            // 当点击全部取消
            this.selectPageList = this.selectPageList.filter((f) => f.taskAccountId !== this.taskAccountId)
          }
        },
        select: async (selection, row) => {
          let selectionList = []
          // 添加 dynamic_category_id字段
          if (!row.dynamic_category_id) {
            const res = await getDynamicCategoryId({ catalogId: row.catalog_id, taskAccountId: row.taskAccountId, productOuterIds: row.product_outer_id })
            row.dynamic_category_id = res.data.dynamic_category_id
          }
          selectionList.push(...selection)
          let list = []
          selectionList.map((item) => {
            if (list.length === 0 || !list.some((s) => s.id === item.id)) {
              list.push(item)
            }
          })

          let arr = [...this.selectPageList, ...list]

          let newArr = []
          arr.forEach((item) => {
            if (newArr.length === 0 || !newArr.some((s) => s.id === item.id)) {
              newArr.push(item)
            }
          })

          if (!selection.some((s) => s.id === row.id)) {
            newArr = newArr.filter((f) => f.id !== row.id)
          }

          let groupByAccountId = _.groupBy(newArr, 'taskAccountId')
          let pageList = []
          for (const key in groupByAccountId) {
            if (Object.hasOwnProperty.call(groupByAccountId, key)) {
              const element = groupByAccountId[key]
              pageList.push(element[element.length - 1])
            }
          }
          this.selectPageList = pageList
        },
        pageData: {
          total: 0, // 总条数
          pageSize: 10,
          layout: 'total, prev, pager, next'
        }
      }
    }
  },

  watch: {
    formData: {
      handler(value) {
        this.selectPageList = value.list
      },
      deep: true,
      immediate: true
    },
    visible: {
      handler(value) {
        if (value) {
          this.selectPageList = this.formData.list
        }
      }
    }
  },

  methods: {
    /**
     * @description: 同步商品
     */
    async sourceSync(_id) {
      if (!_id) return this.$message.error('请先选择账户')
      this.dataSource.loading = true
      setTimeout(() => {
        this.dataSource.loading = false
      }, 200)
      this.$message.success('刷新成功!')
    },

    /**
     * @desc 获取当前选中账户下的商品
     */
    async searchGoodsFun({ item, searchName, catalog, catalog_id }) {
      this.taskAccountId = item._id
      this.dataSource.loading = true
      if (!catalog_id) {
        this.dataSource.loading = false
        this.dataSource.data = []
        return this.$message.error('该账户下没有商品')
      }

      const { code, data } = await searchGoods({ taskAccountId: item._id, name: searchName, catalog_id })

      if (code === 200) {
        data.list.forEach((ele) => {
          ele.key = `${ele.product_outer_id}+${item._id}`
          ele.id = `${ele.product_outer_id}+${item._id}`
          ele.name = ele.product_name
          ele.catalog_id = catalog_id
          ele.productFullInfo = JSON.stringify(ele)
          ele.commodityCategoryInfo = JSON.stringify(catalog)
          ele.taskAccountId = item._id
          ele.accountName = item._name
          ele.show_name = `${ele.product_name}_(${item._name})`
        })
        this.dataSource.loading = false

        this.dataSource.pageData.total = data.total
        this.dataSource.data = data.list
      }
    },

    /**
     * @desc 删除数据后提交
     */
    deleteSelectSourceData(id) {
      let params = {
        saveProducts: []
      }
      this.selectPageList.map((item) => {
        params.saveProducts.push({
          isUseProduct: false,
          taskAccountId: item.taskAccountId,
          material_source_id: 0,
          dynamic_product_ad_category: 0,
          dynamic_ad_product_id: 0,
          dynamic_category_id: 0,
          productJsonName: 0,
          productFullInfo: '{}',
          commodityCategoryInfo: '{}'
        })
      })
      if (params.saveProducts.length) {
        saveProductList(params).then((res) => {
          if (res.code === 200) {
            this.$message.success('删除成功')
            this.$emit('byTaskIdGetAllFun')
          }
        })
      }
    },

    /**
     * @desc 获取选中的数据后提交
     */
    getSelectSourceData() {
      if (!this.selectPageList.length) {
        return this.$message.error('请选择商品')
      }
      let params = {
        saveProducts: []
      }
      this.selectPageList.map((item) => {
        params.saveProducts.push({
          isUseProduct: true,
          taskAccountId: item.taskAccountId,
          material_source_id: item.catalog_id,
          dynamic_product_ad_category: item.catalog_id,
          dynamic_ad_product_id: item.product_outer_id,
          dynamic_category_id: item.dynamic_category_id,
          productFullInfo: item.productFullInfo,
          commodityCategoryInfo: item.commodityCategoryInfo,
          productJsonName: JSON.stringify({ name: item.name, id: item.product_hash_id })
        })
      })
      if (params.saveProducts.length) {
        saveProductList(params).then((res) => {
          if (res.code === 200) {
            this.$emit('byTaskIdGetAllFun')
            this.$message.success('商品添加成功')
            this.goodsClose()
          }
        })
      }
      this.selectPageList = []
    },

    setSelectPageList(data) {
      this.selectPageList = data
    }
  }
}
</script>

<style lang="scss">
.handleTree-icon :hover {
  color: #409eff;
}

.goods {
  .custom-tree-node {
    // flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    width: 100px !important;
    .label {
      width: 300px !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
</style>
