var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "slider" }, [
    _vm.detail.type === "101"
      ? _c("div", { staticStyle: { width: "100%" } }, [
          _vm.sliderList.length
            ? _c(
                "div",
                { staticStyle: { width: "100%" } },
                [
                  _c(
                    "el-carousel",
                    { attrs: { interval: 2000 } },
                    _vm._l(_vm.sliderList, function (item) {
                      return _c(
                        "el-carousel-item",
                        { key: item.id, staticClass: "carousel-item" },
                        [
                          _c("img", {
                            attrs: {
                              src:
                                item.componentItem.pureImageUrl ||
                                _vm.defaultImg,
                              alt: "",
                            },
                          }),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _c(
                "div",
                { staticStyle: { "text-align": "center" } },
                [
                  _vm._l(
                    _vm.detail.componentGroupList.componentGroup.length
                      ? _vm.detail.componentGroupList.componentGroup
                      : 3,
                    function (item, index) {
                      return _c("i", {
                        key: index,
                        staticClass: "el-icon-picture-outline",
                      })
                    }
                  ),
                  _c("p", [_vm._v("轮播图组件")]),
                ],
                2
              ),
        ])
      : _vm._e(),
    _vm.detail.type === "102"
      ? _c("div", { staticStyle: { width: "100%" } }, [
          _vm.sliderList2.length
            ? _c("div", { staticStyle: { width: "100%" } }, [
                _c(
                  "div",
                  {
                    class: { "active-carousel": _vm.detail.type === "102" },
                    on: {
                      click: function ($event) {
                        return _vm.settype("102")
                      },
                    },
                  },
                  [
                    _c(
                      "el-carousel",
                      { attrs: { interval: 2000 } },
                      _vm._l(_vm.sliderList2, function (item) {
                        return _c(
                          "el-carousel-item",
                          { key: item.id, staticClass: "carousel-item" },
                          [
                            _c("img", {
                              attrs: {
                                src: item.pureImageUrl || _vm.defaultImg,
                                alt: "",
                              },
                            }),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _c(
                "div",
                { staticStyle: { "text-align": "center" } },
                [
                  _vm._l(
                    _vm.detail.length ? _vm.sliderList2 : 3,
                    function (item, index) {
                      return _c("i", {
                        key: index,
                        staticClass: "el-icon-picture-outline",
                      })
                    }
                  ),
                  _c("p", [_vm._v("轮播图组件")]),
                ],
                2
              ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }