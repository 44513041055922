var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return ["蒲栋良", "陈飞", "刘威", "靳双动", "王程"].includes(
    _vm.userInfo.username
  )
    ? _c(
        "div",
        [
          _c(
            "el-row",
            [
              _c("el-button", { on: { click: _vm.handleEnd } }, [
                _vm._v("关闭服务"),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c("el-button", { on: { click: _vm.handleStart } }, [
                _vm._v("开启服务"),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }