var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form-item",
        { attrs: { label: "信息栏类型：", prop: "headDescType" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.ruleFormData.headDescType,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleFormData, "headDescType", $$v)
                },
                expression: "ruleFormData.headDescType",
              },
            },
            [_c("el-radio", { attrs: { label: 2 } }, [_vm._v("文案型")])],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: {
            label:
              _vm.promotedObjectType === "PRODUCTTYPE_WECHAT"
                ? "点击跳转"
                : "外层素材跳转",
            prop: "pageType",
          },
        },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.ruleFormData.pageType,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleFormData, "pageType", $$v)
                },
                expression: "ruleFormData.pageType",
              },
            },
            [_c("el-radio", { attrs: { label: 4 } }, [_vm._v("原生推广页")])],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "按钮文案：", prop: "buttonParam" } },
        [
          _vm.promotedObjectType === "PRODUCTTYPE_WECHAT"
            ? _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleFormData.buttonParam,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleFormData, "buttonParam", $$v)
                    },
                    expression: "ruleFormData.buttonParam",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "关注公众号" } }, [
                    _vm._v("关注公众号"),
                  ]),
                  _c("el-radio", { attrs: { label: "查看详情" } }, [
                    _vm._v("查看详情"),
                  ]),
                  _c("el-radio", { attrs: { label: "了解更多" } }, [
                    _vm._v("了解更多"),
                  ]),
                ],
                1
              )
            : _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleFormData.buttonParam,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleFormData, "buttonParam", $$v)
                    },
                    expression: "ruleFormData.buttonParam",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "查看详情" } }, [
                    _vm._v("查看详情"),
                  ]),
                  _c("el-radio", { attrs: { label: "立即咨询" } }, [
                    _vm._v("立即咨询"),
                  ]),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "标签展示：", prop: "mpTagType" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.ruleFormData.mpTagType,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleFormData, "mpTagType", $$v)
                },
                expression: "ruleFormData.mpTagType",
              },
            },
            [_c("el-radio", { attrs: { label: 0 } }, [_vm._v("不启用")])],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "结束页：", prop: "isEndPage" } },
        [
          _c("el-switch", {
            on: { change: _vm.endPageFlagChange },
            model: {
              value: _vm.ruleFormData.isEndPage,
              callback: function ($$v) {
                _vm.$set(_vm.ruleFormData, "isEndPage", $$v)
              },
              expression: "ruleFormData.isEndPage",
            },
          }),
        ],
        1
      ),
      _vm.ruleFormData.isEndPage
        ? _c(
            "el-form-item",
            { attrs: { label: "结束页类型：", prop: "endPageType" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleFormData.endPageType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleFormData, "endPageType", $$v)
                    },
                    expression: "ruleFormData.endPageType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [
                    _vm._v("突出头像昵称"),
                  ]),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("突出文案")]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        { attrs: { label: "", prop: "pageTopImgFlag" } },
        [
          _c(
            "el-checkbox",
            {
              on: { change: _vm.pageTopImgFlagChange },
              model: {
                value: _vm.ruleFormData.pageTopImgFlag,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleFormData, "pageTopImgFlag", $$v)
                },
                expression: "ruleFormData.pageTopImgFlag",
              },
            },
            [_vm._v("支持在播放结束后展现广告结束页")]
          ),
        ],
        1
      ),
      _vm.ruleFormData.pageTopImgFlag
        ? _c(
            "el-form-item",
            {
              attrs: {
                label: "页面顶部图：",
                prop: "pageTopImgs",
                rules: _vm.rules.pageTopImgs,
              },
            },
            [
              _c(
                "div",
                { staticClass: "justify-start" },
                _vm._l(_vm.ruleFormData.pageTopImgs, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "avatar-uploader mr-10",
                      on: {
                        click: function ($event) {
                          _vm.materialBoxVisible = true
                        },
                      },
                    },
                    [
                      item.image || item.localAssetUrl
                        ? _c(
                            "div",
                            {
                              staticClass: "avatar-uploader",
                              on: {
                                click: function ($event) {
                                  _vm.materialBoxVisible = true
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticStyle: { width: "98px" },
                                attrs: {
                                  src: item.image || item.localAssetUrl,
                                },
                              }),
                            ]
                          )
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                    ]
                  )
                }),
                0
              ),
            ]
          )
        : _vm._e(),
      _c(
        "el-form-item",
        {
          attrs: { label: "创意名称：", prop: "tname", rules: _vm.rules.tname },
        },
        [
          _c("el-input", {
            staticStyle: { width: "400px" },
            attrs: {
              rows: "4",
              type: "textarea",
              placeholder: "请输入创意名称",
              maxlength: "60",
              "show-word-limit": "",
            },
            model: {
              value: _vm.ruleFormData.tname,
              callback: function ($$v) {
                _vm.$set(_vm.ruleFormData, "tname", $$v)
              },
              expression: "ruleFormData.tname",
            },
          }),
        ],
        1
      ),
      _vm.materialBoxVisible
        ? _c(
            "div",
            [
              _c("material-box", {
                attrs: {
                  visible: _vm.materialBoxVisible,
                  "img-count": 3,
                  name: "creativeGroup",
                  "image-list": _vm.imageList,
                  attribute: {
                    key: "creativeGroup",
                    value: "创意素材",
                    count: 3,
                    size: 300 * 1024,
                    listType: "image/jpg,image/jpeg,image/png",
                    width: 750,
                    height: 750,
                  },
                  "project-id": [+_vm.projectId],
                },
                on: {
                  setMaterialBox: function ($event) {
                    _vm.materialBoxVisible = false
                  },
                  submit: _vm.selectImg,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }