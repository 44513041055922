import { render, staticRenderFns } from "./RelationPageDialog.vue?vue&type=template&id=a3f2fff2&scoped=true&"
import script from "./RelationPageDialog.vue?vue&type=script&lang=js&"
export * from "./RelationPageDialog.vue?vue&type=script&lang=js&"
import style0 from "./RelationPageDialog.vue?vue&type=style&index=0&id=a3f2fff2&lang=scss&"
import style1 from "./RelationPageDialog.vue?vue&type=style&index=1&id=a3f2fff2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3f2fff2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/Data/ad_frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a3f2fff2')) {
      api.createRecord('a3f2fff2', component.options)
    } else {
      api.reload('a3f2fff2', component.options)
    }
    module.hot.accept("./RelationPageDialog.vue?vue&type=template&id=a3f2fff2&scoped=true&", function () {
      api.rerender('a3f2fff2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/toolManagement/landingPageTool/customLandingPage/components/RelationPageDialog.vue"
export default component.exports