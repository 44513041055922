<!--
 * @Description: Do not edit
 * @Date: 2021-10-05 13:41:50
 * @LastEditTime: 2022-02-16 15:49:49
-->
<template>
  <el-form ref="searchForm" class="search-form search-form-4" :model="searchForm">
    <el-row :gutter="20">
      <el-col :span="6">
        <el-form-item prop="pageName" label="落地页名称">
          <el-input v-model="searchForm.pageName" placeholder="请输入目标落地页名称" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="shareStatus" label="分享状态">
          <el-select v-model="searchForm.shareStatus" filterable placeholder="请选择分享状态">
            <el-option v-for="item in shareStatus()" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue" />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-form-item prop="updateBy" label="分享人">
          <el-input v-model="searchForm.updateBy" placeholder="请输入分享人" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="updateTime " label="分享时间">
          <el-date-picker v-model="searchForm.updateTime" type="date" placeholder="请输入分享时间" />
          <!-- <el-input v-model="searchForm.updateTime" placeholder="分享时间" /> -->
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-button class="btn" type="primary" @click="search">
          查询
        </el-button>
        <el-button class="btn" @click="resetSearch">
          重置
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { searchForm } from './object'
export default {
  inject: ['loadData', 'shareStatus'],
  data() {
    return {
      searchForm: { ...searchForm }
    }
  },
  methods: {
    search() {
      this.loadData(true)
    },
    // 折中方案
    getSearchForm() {
      return { ...this.searchForm }
    },
    resetSearch() {
      this.$refs.searchForm.resetFields()
    }
  }
}
</script>
