<template>
  <div class="direction-template">
    <el-form ref="directionTempForm" label-position="right" label-width="120px" :rules="rules" :model="forms">
      <div class="direction-setting-box">
        <div class="direction-setting-title">基本信息</div>
        <div class="direction-setting basic">
          <el-form-item label="模板名称" prop="name">
            <el-input v-model="forms.name" placeholder="请输入模板名称" show-word-limit maxlength="30"></el-input>
          </el-form-item>
          <el-form-item label="定向描述" prop="description">
            <el-input v-model="forms.description" type="textarea" resize="none" rows="2" placeholder="请输入定向描述"
              show-word-limit maxlength="30"></el-input>
          </el-form-item>
          <el-form-item label="关联项目" prop="project">
            <el-select v-model="forms.project" style="width: 100%" placeholder="请选择关联项目" @change="projectChange">
              <el-option v-for="item in projectOptions" :key="item.bsProjectId" :label="item.projectName"
                :value="item.bsProjectId" />
            </el-select>
          </el-form-item>
          <el-form-item label="关联产品" prop="product">
            <el-select v-model="forms.product" style="width: 100%" placeholder="请选择关联产品" @change="productChange">
              <el-option label="不限" :value="-1"></el-option>
              <el-option v-for="item in productOptions" :key="item.bsProductId" :label="item.productName"
                :value="item.bsProductId" />
            </el-select>
          </el-form-item>
        </div>
      </div>
      <div class="direction-setting-box">
        <div class="direction-setting-title">定向设置</div>
        <div class="direction-setting">
          <el-form-item label="定向包类型" prop="landing_type">
            <el-radio-group v-model="forms.landing_type">
              <el-radio-button label="EXTERNAL">落地页</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="投放范围" prop="delivery_range">
            <el-radio-group v-model="forms.delivery_range" @change="rangeChange">
              <el-radio-button v-for="item in deliverRangData" :key="item.key" :label="item.key">{{ item.value }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <!--地域-->
          <div class="region-list-box">
            <div class="region-box">
              <district-select :class="{ 'is-business-district': forms.district == 'CUSTOM_BUSINESS_DISTRICT' }"
                :value="districtValue" @change="districtChange" />
              <div :class="['shop-circle', { selected: forms.district == 'CUSTOM_BUSINESS_DISTRICT' }]"
                @click.stop="handleSelectCircle">按商圈</div>
            </div>
            <!--商圈-->
            <template v-if="forms.district == 'CUSTOM_BUSINESS_DISTRICT'">
              <business-district-comp ref="businessDistrict">
                <div class="check-group-mix">
                  <el-radio-group v-model="forms.location_type">
                    <el-radio v-for="item in locationTypeData" :key="item.key" :label="item.key">{{ item.value }}
                    </el-radio>
                  </el-radio-group>
                </div>
              </business-district-comp>
            </template>
          </div>
          <el-form-item label="性别" prop="gender">
            <el-radio-group v-model="forms.gender">
              <el-radio-button v-for="item in genderData" :key="item.key" :label="item.key">{{ item.value }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="年龄" prop="age">
            <div class="check-group-mix">
              <el-radio-group v-model="forms.age" class="unlimit-radio">
                <el-radio-button :label="['NONE']">不限</el-radio-button>
              </el-radio-group>
              <el-checkbox-group v-model="forms.age" class="unlimit-check">
                <el-checkbox-button v-for="item in ageData" :key="item.key" :label="item.key">{{ item.value }}
                </el-checkbox-button>
              </el-checkbox-group>
            </div>
          </el-form-item>
          <el-form-item label="自定义人群" prop="retargeting_tags_include">
            <el-radio-group v-model="forms.retargeting_tags_include">
              <el-radio-button :label="[]">不限</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="行为兴趣" prop="interest_action_mode">
            <el-radio-group v-model="forms.interest_action_mode">
              <el-radio-button v-for="item in interestActionData" :key="item.key" :label="item.key">{{ item.value }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="forms.delivery_range == 'DEFAULT'" label="抖音达人" prop="aweme_fan_behaviors">
            <el-radio-group v-model="forms.aweme_fan_behaviors">
              <el-radio-button :label="[]">不限</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="媒体定向" prop="superior_popularity_type">
            <el-radio-group v-model="forms.superior_popularity_type">
              <el-radio-button label="NONE">不限</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="平台" prop="platform">
            <div class="check-group-mix">
              <el-radio-group v-model="forms.platform" class="unlimit-radio">
                <el-radio-button :label="['NONE']">不限</el-radio-button>
              </el-radio-group>
              <el-checkbox-group v-model="forms.platform" class="unlimit-check">
                <el-checkbox-button label="IOS">IOS</el-checkbox-button>
                <el-checkbox-button label="ANDROID">Android</el-checkbox-button>
              </el-checkbox-group>
<!--              <el-radio-group v-model="forms.platform" class="unlimit-radio">-->
<!--                <el-radio-button :label="['PC']">PC</el-radio-button>-->
<!--              </el-radio-group>-->
            </div>
          </el-form-item>
          <el-form-item label="设备类型" prop="device_type">
            <div class="check-group-mix">
              <el-radio-group v-model="forms.device_type" class="unlimit-radio">
                <el-radio-button :label="['NONE']">不限</el-radio-button>
              </el-radio-group>
              <el-checkbox-group v-model="forms.device_type" class="unlimit-check">
                <el-checkbox-button v-for="item in deviceTypeData" :key="item.key" :label="item.key">{{ item.value }}
                </el-checkbox-button>
              </el-checkbox-group>
            </div>
          </el-form-item>
          <el-form-item label="网络" prop="ac">
            <div class="check-group-mix">
              <el-radio-group v-model="forms.ac" class="unlimit-radio">
                <el-radio-button :label="['NONE']">不限</el-radio-button>
              </el-radio-group>
              <el-checkbox-group v-model="forms.ac" class="unlimit-check">
                <el-checkbox-button v-for="item in networkData" :key="item.key" :label="item.key">{{ item.value }}
                </el-checkbox-button>
              </el-checkbox-group>
            </div>
          </el-form-item>
          <el-form-item v-if="forms.delivery_range == 'DEFAULT'" label="文章分类" prop="article_category">
            <el-radio-group v-model="forms.article_category">
              <el-radio-button :label="[]">不限</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="运营商" prop="carrier">
            <div class="check-group-mix">
              <el-radio-group v-model="forms.carrier" class="unlimit-radio">
                <el-radio-button :label="['NONE']">不限</el-radio-button>
              </el-radio-group>
              <el-checkbox-group v-model="forms.carrier" class="unlimit-check">
                <el-checkbox-button v-for="item in operateData" :key="item.key" :label="item.key">{{ item.value }}
                </el-checkbox-button>
              </el-checkbox-group>
            </div>
          </el-form-item>
          <el-form-item v-if="forms.delivery_range == 'DEFAULT'" label="新用户" prop="activate_type">
            <div class="check-group-mix">
              <el-radio-group v-model="forms.activate_type" class="unlimit-radio">
                <el-radio-button :label="['NONE']">不限</el-radio-button>
              </el-radio-group>
              <el-checkbox-group v-model="forms.activate_type" class="unlimit-check">
                <el-checkbox-button v-for="item in newUserData" :key="item.key" :label="item.key">{{ item.value }}
                </el-checkbox-button>
              </el-checkbox-group>
            </div>
          </el-form-item>
          <el-form-item label="手机价格" prop="launch_price">
            <el-radio-group v-model="phoneCustom" @change="handleSelectPhone">
              <el-radio-button label="unlimit">不限</el-radio-button>
              <el-radio-button label="custom">自定义</el-radio-button>
            </el-radio-group>
            <div v-if="phoneCustom == 'custom'" class="phone-price-box">
              <div class="price-value-box">
                <span>价格区间：{{ forms.launch_price | formatPriceLimit }}</span>
                <span>单位：元</span>
              </div>
              <div class="price-slider">
                <el-slider v-model="forms.launch_price" range show-stops :show-tooltip="false"
                  :step="forms.launch_price[1] >= 10000 ? 1000 : 500" :min="0" :max="11000" :marks="phoneSlierMark">
                </el-slider>
              </div>
              <div class="short-setting">
                <span class="label">快捷设置：</span>
                <span v-for="item in shortSetData" :key="item.key" class="short-item"
                  @click="handleShortSet(item.key)">{{ item.value }}</span>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="智能放量" prop="auto_extend_enabled">
            <el-radio-group v-model="forms.auto_extend_enabled">
              <el-radio-button :label="0">不启用</el-radio-button>
              <!-- <el-radio-button :label="1">启用</el-radio-button> -->
            </el-radio-group>
          </el-form-item>
          <el-form-item label="已安装用户" prop="hide_if_exists">
            <el-radio-group v-model="forms.hide_if_exists">
              <el-radio-button :label="0">不限</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="过滤已转化用户" prop="hide_if_converted">
            <el-radio-group v-model="forms.hide_if_converted">
              <el-radio-button v-for="(item, index) in transferData" :key="index" :label="item.id">{{ item.name }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
        </div>
      </div>

      <div class="direction-setting-footer">
        <el-button v-if="tempPage" type="primary" :loading="isLoading" @click="handleSubmit">{{ tempPage | filterBtnText
        }}</el-button>
        <!--        <el-button v-else type="primary" :loading="isLoading" @click="handleSubmit">创建</el-button>-->
        <el-button :loading="isLoading" @click="handleBack">取消</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { getMeRelationProject } from '@/network/api/basicSetting/project/basicSettingProject'
import { getProductByProject } from '@/network/api/basicSetting/product/basicSettingProduct'
import { addTemplate, viewTemplate, updateTemplate } from '@/network/api/massiveAd/massiveAd'
import businessDistrictComp from './businessDistrictComp'
import moment from 'moment'

const MAX_LIMIT = 11000
export default {
  name: 'AddMassiveAd',
  components: {
    'business-district-comp': businessDistrictComp
  },
  filters: {
    formatPriceLimit(value) {
      if (value && value.length) {
        let min = value[0]
        let max = value[1]
        if (min === 0 && max === MAX_LIMIT) {
          return '不限'
        } else if (min >= 0 && max === MAX_LIMIT) {
          return min + '元以上'
        } else if (min === 0 && max <= MAX_LIMIT) {
          return max + '元以内'
        } else {
          return min + '元-' + max + '元'
        }
      }
    },
    filterBtnText(value) {
      let obj = { create: '创建', update: '编辑', copy: '复制' }
      return obj[value] || ''
    }
  },
  props: {},
  data() {
    return {
      tempPage: 'create', // 页面类型 create 新建 update 编辑 copy 复制
      isLoading: false,
      forms: {
        name: '', // 模板名称
        description: '', // 描述
        project: '', // 项目
        product: '', // 产品
        projectName: '', // 项目名称
        productName: '', // 产品名称

        landing_type: 'EXTERNAL', // 定向类型(推广目的)： EXTERNAL落地页
        delivery_range: 'DEFAULT', // 投放范围
        union_video_type: 'ORIGINAL_VIDEO', // 投放范围为穿山甲填写
        district: 'NONE', // 地域类型
        city: [], // 地域类型为省市或区县时传值id
        business_ids: [], // 地域类型为商圈时传值id
        location_type: 'ALL', // 位置类型
        gender: 'NONE', // 性别
        age: ['NONE'], // 年龄
        retargeting_tags_include: [], // 自定义人群
        retargeting_tags_exclude: [],
        interest_action_mode: 'UNLIMITED', // 行为兴趣
        aweme_fan_behaviors: [], // 抖音达人
        superior_popularity_type: 'NONE', // 媒体定向,
        platform: ['NONE'], // 平台
        device_type: ['NONE'], // 设备类型
        ac: ['NONE'], // 网络类型
        carrier: ['NONE'], // 运营商
        hide_if_exists: 0, // 过滤已安装
        hide_if_converted: 'NO_EXCLUDE', // 过滤已转化用户
        article_category: [], // 文章分类
        activate_type: ['NONE'], // 新用户
        auto_extend_enabled: 0, // 智能放量
        launch_price: [0, MAX_LIMIT] // 手机价格
      },
      phoneCustom: 'unlimit', // 手机价格类型
      districtValue: {
        district: 'NONE'
      },
      rules: {
        name: { required: true, trigger: 'blur', message: '请输入模板名称' },
        description: { required: true, trigger: 'blur', message: '请输入定向描述' },
        landing_type: { required: true, trigger: 'change', message: '请选择定向包类型' },
        delivery_range: { required: true, trigger: 'change', message: '请选择投放范围' },
        platform: { required: true, trigger: 'change', message: '请选择平台' },
        project: { required: true, trigger: 'change', message: '请选择关联项目' },
        product: { required: true, trigger: 'change', message: '请选择关联产品' }
      },
      projectOptions: [], // 关联项目
      productOptions: [], // 关联产品
      // 过滤已转化用户
      transferData: [
        { id: 'NO_EXCLUDE', name: '不过滤' },
        { id: 'AD', name: '广告计划' },
        { id: 'CAMPAIGN', name: '广告组' },
        { id: 'ADVERTISER', name: '广告账户' }
      ],
      // 投放范围
      deliverRangData: [
        { key: 'DEFAULT', value: '默认' },
        { key: 'UNIVERSAL', value: '通投智选' },
        { key: 'UNION', value: '穿山甲' }
      ],
      // 地域类型
      districtTypeData: [
        { key: 'NONE', value: '不限' },
        { key: 'CITY', value: '按省市' },
        { key: 'COUNTY', value: '按区县' },
        { key: 'BUSINESS_DISTRICT', value: '按商圈' }
      ],
      // 位置类型
      locationTypeData: [
        { key: 'CURRENT', value: '正在该地区的用户' },
        { key: 'HOME', value: '居住在该地区的用户' },
        { key: 'TRAVEL', value: '到该地区旅行的用户' },
        { key: 'ALL', value: '该地区内的所有用户' }
      ],
      // 性别
      genderData: [
        { key: 'NONE', value: '不限' },
        { key: 'GENDER_MALE', value: '男' },
        { key: 'GENDER_FEMALE', value: '女' }
      ],
      // 年龄
      ageData: [
        { key: 'AGE_BETWEEN_18_23', value: '18-23' },
        { key: 'AGE_BETWEEN_24_30', value: '24-30' },
        { key: 'AGE_BETWEEN_31_40', value: '31-40' },
        { key: 'AGE_BETWEEN_41_49', value: '41-49' },
        { key: 'AGE_ABOVE_50', value: '50+' }
      ],
      // 平台
      platformData: [
        { key: 'IOS', value: 'IOS' },
        { key: 'ANDROID', value: 'Android' }
        // { key: 'PC', value: 'PC' }
      ],
      // 行为兴趣
      interestActionData: [
        { key: 'UNLIMITED', value: '不限' },
        { key: 'RECOMMEND', value: '系统推荐' }
      ],
      // 设备类型
      deviceTypeData: [
        { key: 'MOBILE', value: '智能手机' },
        { key: 'PAD', value: '平板' }
      ],
      // 网络类型
      networkData: [
        { key: 'WIFI', value: 'WIFI' },
        { key: '2G', value: '2G' },
        { key: '3G', value: '3G' },
        { key: '4G', value: '4G' },
        { key: '5G', value: '5G' }
      ],
      // 运营商
      operateData: [
        { key: 'MOBILE', value: '移动' },
        { key: 'UNICOM', value: '联通' },
        { key: 'TELCOM', value: '电信' }
      ],
      // 新用户
      newUserData: [
        { key: 'WITH_IN_A_MONTH', value: '一个月内' },
        { key: 'ONE_MONTH_2_THREE_MONTH', value: '一个月到三个月' },
        { key: 'THREE_MONTH_EAILIER', value: '三个月以上' }
      ],
      // 快捷设置手机价格
      shortSetData: [
        {
          key: '2000-4000',
          value: '2000元-4000元'
        },
        {
          key: '4000-6000',
          value: '4000元-6000元'
        },
        {
          key: '6000-8000',
          value: '6000元-8000元'
        },
        {
          key: '8000-10000',
          value: '8000元-10000元'
        }
      ],
      // 手机价格slider分段
      phoneSlierMark: {
        0: '0',
        2000: '2000',
        4000: '4000',
        6000: '6000',
        8000: '8000',
        10000: '10000'
      },
      checkField: {
        age: 'ageData',
        platform: 'platformData',
        ac: 'networkData',
        carrier: 'operateData',
        activate_type: 'newUserData',
        device_type: 'deviceTypeData'
      }
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getProject()
    if (this.$route.params.id || this.$route.params.copyId) {
      if (this.$route.path.includes('copyDirectionTemp')) {
        this.tempPage = 'copy'
      } else if (this.$route.path.includes('editDirectionTemp')) {
        this.tempPage = 'update'
      }
      this._loadTemplateDetail()
    }
  },
  mounted() { },
  methods: {
    // 模板详情
    _loadTemplateDetail() {
      let id = this.$route.params.id || this.$route.params.copyId
      this.isLoading = true
      viewTemplate(id)
        .then((res) => {
          if (res.code === 200) {
            let data = res.data || {}
            let content = data.content ? JSON.parse(data.content) : {}
            this.getProduct(data.projectId)
            if (content.district === 'BUSINESS_DISTRICT') {
              // 商圈
              content.district = 'CUSTOM_BUSINESS_DISTRICT'
              setTimeout(() => {
                this.$refs.businessDistrict._setBusinessList(content.business)
              }, 500)
            } else {
              this.districtValue = {
                district: content.district,
                city: content.city || [],
                location_type: content.location_type
              }
            }
            if (content.launch_price[0] === 0 && content.launch_price[1] === MAX_LIMIT) {
              this.phoneCustom = 'unlimit'
            } else {
              this.phoneCustom = 'custom'
            }
            this.forms = {
              id: data.id,
              name: this.$route.params.copyId ? `${data.name}-${moment().format('MMDDHHmmss')}` : data.name, // 模板名称
              description: data.description, // 描述
              project: data.projectId, // 项目
              product: data.productId, // 产品
              projectName: data.projectName, // 项目名称
              productName: data.productName, // 产品名称

              landing_type: data.type, // 定向类型(推广目的)： EXTERNAL落地页
              delivery_range: content.delivery_range, // 投放范围
              union_video_type: content.union_video_type, // 投放范围为穿山甲填写
              district: content.district, // 地域类型
              city: content.city, // 地域类型为省市或区县时传值id
              business_ids: content.business_ids, // 地域类型为商圈时传值id
              location_type: content.location_type, // 位置类型
              gender: content.gender, // 性别
              age: this.checkFieldAll('age', content.age), // 年龄
              retargeting_tags_include: content.retargeting_tags_exclude, // 自定义人群
              retargeting_tags_exclude: content.retargeting_tags_exclude,
              interest_action_mode: content.interest_action_mode, // 行为兴趣
              aweme_fan_behaviors: content.aweme_fan_behaviors, // 抖音达人
              superior_popularity_type: content.superior_popularity_type, // 媒体定向,
              platform: this.checkFieldAll('platform', content.platform), // 平台
              device_type: this.checkFieldAll('device_type', content.device_type), // 设备类型
              ac: this.checkFieldAll('ac', content.ac), // 网络类型
              carrier: this.checkFieldAll('carrier', content.carrier), // 运营商
              hide_if_exists: content.hide_if_exists, // 过滤已安装
              hide_if_converted: content.hide_if_converted, // 过滤已转化用户
              article_category: content.article_category, // 文章分类
              activate_type: this.checkFieldAll('activate_type', content.activate_type), // 新用户
              auto_extend_enabled: content.auto_extend_enabled, // 智能放量
              launch_price: content.launch_price // 手机价格
            }
          }
          this.isLoading = false
        })
        .catch((e) => {
          this.isLoading = false
        })
    },
    // 详情回显是否为多选不限字段
    checkFieldAll(key, value) {
      let fieldKeys = Object.keys(this.checkField)
      if (fieldKeys.includes(key) && value.length === 0) {
        return ['NONE']
      } else {
        return value
      }
    },

    getProduct(val) {
      getProductByProject(val).then((res) => {
        if (res.code === 200) {
          this.productOptions = res.data
        }
      })
    },
    getProject() {
      getMeRelationProject().then((res) => {
        if (res.code === 200) {
          this.projectOptions = res.data
        }
      })
    },

    // 选择关联项目
    projectChange(val) {
      this.forms.product = ''
      this.forms.productName = ''
      let item = this.projectOptions.find((v) => v.bsProjectId === val)
      this.forms.projectName = item ? item.projectName : ''
      this.getProduct(val)
    },

    // 选择关联产品
    productChange(val) {
      let item = this.productOptions.find((v) => v.bsProductId === val)
      this.forms.productName = item ? item.productName : ''
    },

    // 地域选择
    districtChange(data) {
      this.forms.district = data.district
      this.forms.city = data.city || []
      this.forms.location_type = data.location_type || 'ALL'
    },

    // 地域选择-按商圈
    handleSelectCircle() {
      this.districtValue = {
        district: 'CUSTOM_BUSINESS_DISTRICT',
        city: [],
        location_type: this.forms.location_type
      }
      this.forms.district = 'CUSTOM_BUSINESS_DISTRICT'
    },

    // 投放范围选择
    rangeChange(value) {
      if (value !== 'DEFAULT') {
        this.forms.aweme_fan_behaviors = []
        this.forms.article_category = ['NONE'] // 文章分类
        this.forms.activate_type = ['NONE']
      }
    },
    // 手机价格快捷设置
    handleShortSet(value) {
      let str = value.split('-')
      this.forms.launch_price = str
    },

    // 选择手机价格类型
    handleSelectPhone(value) {
      if (value === 'unlimit') {
        this.forms.launch_price = [0, MAX_LIMIT]
      }
    },

    // 点击创建
    handleSubmit() {
      this.$refs.directionTempForm.validate((valid) => {
        if (!valid) {
          return
        }

        let forms = this.forms

        let field = this.checkField

        let params = {
          description: forms.description,
          name: forms.name,
          projectId: forms.project,
          projectName: forms.projectName,
          productId: forms.product,
          productName: forms.productName,
          type: forms.landing_type
        }

        let excludeField = ['description', 'project', 'projectName', 'product', 'productName']
        let content = {}
        for (let key in forms) {
          let obj = forms[key]
          let fieldKeys = Object.keys(field)
          if (Array.isArray(obj)) {
            let list = forms[key].filter((v) => v !== 'NONE')
            if (fieldKeys.includes(key) && list.length === 0) {
              // let arr = this[field[key]].map((v) => v.key)
              content[key] = []
            } else {
              content[key] = list
            }
          } else if (!excludeField.includes(key)) {
            content[key] = obj
          }
        }

        // businessDistrict
        if (content.district === 'CUSTOM_BUSINESS_DISTRICT') {
          // 商圈
          let business = this.$refs.businessDistrict._getBusinessList()
          let ids = business.map((v) => v.id)
          if (!ids.length) {
            return this.$message.error('请选择商圈')
          }
          content.business_ids = ids
          content.business = business
          content.district = 'BUSINESS_DISTRICT'
        }

        if ((content.district === 'CITY' || content.district === 'COUNTY') && !content.city.length) {
          return this.$message.error('请选择地域')
        }

        params.content = JSON.stringify(content)

        switch (this.tempPage) {
          case 'update':
            params.id = this.forms.id
            this.saveEditSubmit(params)
            break
          case 'copy':
            this.saveCopySubmit(params)
            break
          case 'create':
            this.saveSubmit(params)
            break
        }
      })
    },

    saveSubmit(data) {
      this.isLoading = true
      addTemplate(data)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success('添加成功')
            if (this.$route.query.page === 'juliang') {
              window.opener.postMessage('refresh', window.location)
              window.opener = null
              window.open('', '_self').close()
            }
            this.handleBack()
          } else {
            this.$message.error('添加失败')
          }
          this.isLoading = false
        })
        .catch((e) => {
          this.isLoading = false
        })
    },

    saveEditSubmit(data) {
      this.isLoading = true
      updateTemplate(data)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success('编辑成功')
            if (this.$route.query.page === 'juliang') {
              window.opener.postMessage('refresh', window.location)
              window.opener = null
              window.open('', '_self').close()
            }
            this.handleBack()
          } else {
            this.$message.error('编辑失败')
          }
          this.isLoading = false
        })
        .catch((e) => {
          this.isLoading = false
        })
    },

    saveCopySubmit(data) {
      this.isLoading = true
      addTemplate(data)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success('复制成功')
            this.handleBack()
          } else {
            this.$message.error('复制失败')
          }
          this.isLoading = false
        })
        .catch((e) => {
          this.isLoading = false
        })
    },

    // 点击取消
    handleBack() {
      this.$router.replace({
        name: 'hugeAmountAd'
      })
    }
  }
}
</script>

<style lang="scss">
.input-item .byted-radio-group .bui-btn:last-child {
  display: none !important;
}

.is-business-district.market-ui-audience-district .market-ui-b-row:nth-child(2) {
  display: none;
}

.direction-setting-box {

  .el-radio-button__inner,
  .el-checkbox-button__inner {
    font-weight: 400;
  }

  .el-radio-button__orig-radio:checked+.el-radio-button__inner {
    color: $themeColor;
    background-color: #ebf3ff;
    border-color: $themeColor;
    font-weight: 400;
  }

  .el-checkbox-button .el-checkbox-button__inner:after {
    content: '';
    position: absolute;
    right: 1px;
    top: -2px;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 6px solid;
    border-left-color: #e0e0e0;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    border-radius: 2px;
  }

  .el-checkbox-button.is-checked .el-checkbox-button__inner {
    color: $themeColor;
    background-color: #ebf3ff;
    border-color: $themeColor;
    font-weight: 400;
    position: relative;

    &:after {
      border-left: 6px solid #338aff;
    }
  }

  .check-group-mix {
    display: flex;
    align-items: center;
    /*.unlimit-radio .el-radio-button:first-child:last-child .el-radio-button__inner{*/
    /*  border-top-right-radius: 0px;*/
    /*  border-bottom-right-radius: 0px;*/
    /*}*/
    /*.unlimit-check .el-checkbox-button:first-child .el-checkbox-button__inner{*/
    /*  border-top-left-radius: 0px;*/
    /*  border-bottom-left-radius: 0px;*/
    /*}*/
  }
}
</style>

<style scoped lang="scss">
.direction-template {
  .direction-setting-box {
    margin: 20px 0;
  }

  .direction-setting.basic {
    max-width: 600px;
  }

  .direction-setting-footer {
    text-align: center;
    margin: 50px 0;
  }

  .direction-setting-title {
    font-size: 22px;
    position: relative;
    padding-left: 10px;
    margin-bottom: 20px;

    &:before {
      content: '';
      width: 3px;
      height: 18px;
      position: absolute;
      left: 0;
      top: 6px;
      background-color: $themeColor;
    }
  }

  .phone-price-box {
    max-width: 600px;
    margin: 20px 0;

    .price-value-box {
      display: flex;
      justify-content: space-between;
    }

    .short-setting {
      margin-top: 30px;

      .short-item {
        color: $themeColor;
        margin-right: 15px;
        cursor: pointer;
      }
    }
  }

  .region-list-box {
    margin-left: 30px;

    .region-box {
      position: relative;

      .shop-circle {
        width: 98px;
        position: absolute;
        top: 1px;
        left: 388px;
        color: #333;
        font-size: 14px;
        background-color: #fff;
        text-align: center;
        border: solid 1px #e0e0e0;
        border-radius: 0 4px 4px 0;
        border-left: none;
        box-sizing: border-box;
        padding: 5px 16px;
        line-height: 22px;
        cursor: pointer;
        z-index: 99999;
        user-select: none;

        &:hover,
        &.selected {
          color: #338aff;
          background-color: #ebf3ff;
          border: solid 1px #338aff;
          border-radius: 0 4px 4px 0;
          box-sizing: border-box;
          margin-left: -1px;
        }
      }
    }

    .region-content {
      margin-left: 90px;
      margin-bottom: 20px;

      .region-panel-box {
        display: flex;
      }

      .region-panel {
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        width: 400px;
        margin-top: 10px;

        &.selected {
          width: 200px;
          margin-left: 16px;
        }

        .region-panel-content {
          height: 192px;
          overflow-y: auto;
        }

        .header {
          border-bottom: 1px solid #e0e0e0;
          box-sizing: border-box;
          background-color: #fafafa;
          color: #333;
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          padding: 8px 12px;
        }

        .panel-item {
          padding: 6px 12px;
          position: relative;
          cursor: pointer;
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          line-height: 22px;
          font-size: 14px;
          color: #333;
          font-weight: 400;

          &:hover {
            background-color: #f0f0f0;
          }
        }
      }
    }
  }
}
</style>
