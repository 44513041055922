<template>
  <div>
    <!-- 列表查询 -->
    <el-form
      ref="search"
      :inline="true"
      :model="search"
      class="search-form search-form-4"
      @submit.native.prevent
    >
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item
            label="任务名称"
            prop="taskName"
          >
            <el-input
              v-model="search.taskName"
              placeholder="请输入任务名称"
              style="width: 100%"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="执行周期"
            prop="taskExecuteCycle"
          >
            <el-select
              v-model="search.taskExecuteCycle"
              style="width: 100%"
            >
              <el-option
                v-for="i in execCycles"
                :key="i.value"
                v-bind="i"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="执行状态"
            prop="status"
          >
            <el-select
              v-model="search.status"
              style="width: 100%"
            >
              <el-option
                v-for="i in execStatus"
                :key="i.value"
                v-bind="i"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="数据条件"
            prop="dataCondition"
          >
            <el-select
              v-model="search.dataCondition"
              style="width: 100%"
            >
              <el-option
                v-for="i in dataConditions"
                :key="i.value"
                v-bind="i"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="创建人"
            prop="createBy"
          >
            <el-input
              v-model="search.createBy"
              placeholder="请输入创建人"
              style="width: 100%"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item>
            <el-button
              type="primary"
              round
              @click="handleSearch"
            >查询</el-button>
            <el-button
              round
              @click="resetFormQuery"
            >重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div
      style="text-align:left "
      class="mb-15"
    >
      <router-link
        :to="{ name: 'createHugeCrowdPackingTask' }"
        tag="span"
      >
        <el-button
          type="primary"
          icon="el-icon-plus"
          round
        > 新建任务 </el-button>
      </router-link>
    </div>
    <div>
      <el-table
        v-loading="list.loading"
        :data="list.data"
        stripe
        class="list-table"
        style="width: 100%;"
      >
        <el-table-column
          type="index"
          label="序号"
          width="50"
        ></el-table-column>
        <el-table-column
          prop="tiktokTaskId"
          label="任务ID"
        ></el-table-column>
        <el-table-column
          prop="taskName"
          label="任务名称"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="taskExecuteCycle"
          label="执行周期"
        >
          <template slot-scope="{row}">
            {{ row.taskExecuteCycle | filterExecutionCycle }}
          </template>
        </el-table-column>
        <el-table-column
          prop="taskDataWhere"
          label="数据条件"
        >
          <template slot-scope="{row}">
            {{ row.taskDataWhere | filterDataCondition }}
          </template>
        </el-table-column>
        <el-table-column
          prop="taskExecuteStatus"
          label="执行状态"
        >
          <template slot-scope="{row}">
            <span>{{ row.taskExecuteStatus | filterStatus }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="executeTime"
          label="最近执行时间"
        ></el-table-column>
        <el-table-column
          prop="nextExecuteTime"
          label="下次执行时间"
        ></el-table-column>
        <el-table-column
          prop="createBy"
          label="创建人"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
        ></el-table-column>
        <el-table-column
          prop=""
          label="操作"
        >
          <template slot-scope="{row}">
            <el-button
              v-if="row.status === 1"
              type="text"
              @click="handleExecute(row.tiktokTaskId)"
            >
              <el-tooltip
                content="执行"
                placement="top"
              >
                <i
                  class="el-icon-play"
                  style="font-size: 16px; color: green;"
                ></i>
              </el-tooltip>
            </el-button>
            <el-button
              type="text"
              @click="handleGoEdit(row.tiktokTaskId)"
            >
              <el-tooltip
                content="编辑"
                placement="top"
              >
                <i
                  class="el-icon-edit"
                  style="font-size: 16px"
                ></i>
              </el-tooltip>
            </el-button>
            <el-button
              type="text"
              @click="handleGoDetail(row.tiktokTaskId)"
            >
              <el-tooltip
                content="详情"
                placement="top"
              >
                <i
                  class="el-icon-document"
                  style="font-size: 16px"
                ></i>
              </el-tooltip>
            </el-button>
            <el-button
              type="text"
              @click="handleDelete(row.tiktokTaskId)"
            >
              <el-tooltip
                content="删除"
                placement="top"
              >
                <i
                  class="el-icon-delete"
                  style="font-size: 16px;color: red;"
                ></i>
              </el-tooltip>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page">
        <paginations
          :total="list.total"
          :page.sync="list.pageNum"
          :page-sizes="[5, 10, 15, 20]"
          :limit.sync="list.pageSize"
          @pagination="getPage"
        />
      </div>
    </div>

  </div>
</template>

<script>
import { execCycles, execStatus, execCycleMap, dataConditions, dataConditionMap, execStatusMap } from './util'
import { getCrowdPackList, runCrowdPackTask, deleteCrowdPackTask } from '@/network/api/huge/assetCrowdPack'
export default {
  name: 'HugeCrowdManagement',
  filters: {
    filterExecutionCycle(value) {
      return execCycleMap[value]
    },
    filterDataCondition(value) {
      return dataConditionMap[value]
    },
    filterStatus(value) {
      return execStatusMap[value] || '-'
    }
  },
  data() {
    return {
      execCycles, execStatus, dataConditions,
      search: {
        taskName: '',
        createBy: '',
        taskExecuteCycle: 0,
        dataCondition: 0,
        status: 0
      },
      list: {
        loading: false,
        data: [],
        pageSize: 10,
        pageNum: 1,
        total: 0
      }
    }
  },
  mounted() {
    this.getPage()
  },
  methods: {
    /**
     * @description: 重置数据
     * @param {*} formName ref
    */

    resetFormQuery() {
      this.$refs.search.resetFields()
      this.getPage()
    },

    // 点击搜索
    handleSearch() {
      this.getPage()
    },

    // 获取列表
    getPage() {
      const { taskName, createBy, status: taskExecuteStatus, dataCondition: taskDataWhere, taskExecuteCycle } = this.search
      const { pageSize, pageNum } = this.list
      const search = {
        taskName,
        createBy,
        taskExecuteStatus,
        taskExecuteCycle,
        taskDataWhere,
        pageSize,
        pageNum
      }
      this.list.loading = true
      getCrowdPackList(search).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          this.list.data = records
          this.list.total = total
        }
      }).finally(() => {
        this.list.loading = false
      })
    },

    // 执行
    handleExecute(id) {
      this.$confirm('是否要立即运行任务？', '确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { code } = await runCrowdPackTask(id)
        if (code === 200) {
          this.getPage()
          this.$message.success('操作成功!')
        }
      }).catch(() => {
        this.$message.error('操作失败!')
      })
    },

    // 点击删除
    handleDelete(id) {
      this.$confirm('删除后任务将不再执行！', '确定要删除该任务吗？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { code } = await deleteCrowdPackTask(id)
        if (code === 200) {
          this.getPage()
          this.$message.success('删除成功!')
        }
      }).catch(() => {
      })
    },

    // 点击编辑
    handleGoEdit(id) {
      this.$router.push({
        name: 'editHugeCrowdPackingTask',
        params: { id }
      })
    },

    // 点击详情
    handleGoDetail(id) {
      this.$router.push({
        name: 'hugeCrowdPackingTaskDetail',
        params: { id }
      })
    }

  }
}
</script>

<style scoped lang="scss">
.task-total {
  font-size: 14px;
  text-align: right;
  color: #999;

  span {
    color: #409EFF;
  }
}
</style>
