/*
 * @Description: Do not edit
 * @Date: 2021-11-10 19:20:55
 * @LastEditTime: 2022-03-28 17:38:28
 */
export let searchForm = {
  advId: null,
  accountId: null,
  accountName: null,
  loginAccount: null,
  subjectName: null,
  accountBalance: null,
  projectId: null,
  projectName: null,
  productId: null,
  productName: null,
  deleted: null,
  createBy: null,
  createTime: null,
  updateBy: null,
  updateTime: null,
  putInStatus: null,
  remark: null
}

export class AccountHugeAdvEnt {
  advId
  accountId
  accountName
  smartBidNoBid = 0
  loginAccount
  subjectName
  accountBalance
  projectId
  projectName
  productId
  productName
  putBy
  putByName
  putInStatus
}

export let formRules = {
  advId: [{ required: true, message: '请填写', trigger: 'change' }],
  accountId: [{ required: true, message: '请填写账号ID', trigger: 'change' }],
  accountName: [{ required: true, message: '请填写账户名称', trigger: 'change' }],
  loginAccount: [{ required: true, message: '请填写登录账号', trigger: 'change' }],
  subjectName: [{ required: true, message: '请填写主体名称', trigger: 'change' }],
  accountBalance: [{ required: true, message: '请填写账号余额', trigger: 'change' }],
  projectId: [{ required: true, message: '请填写项目id', trigger: 'change' }],
  projectName: [{ required: true, message: '请填写项目名称', trigger: 'change' }],
  productId: [{ required: true, message: '请填写产品id', trigger: 'change' }],
  productName: [{ required: true, message: '请填写产品', trigger: 'change' }],
  deleted: [{ required: true, message: '请填写逻辑删除', trigger: 'change' }],
  createBy: [{ required: true, message: '请填写创建者', trigger: 'change' }],
  createTime: [{ required: true, message: '请填写创建时间', trigger: 'change' }],
  updateBy: [{ required: true, message: '请填写更新者', trigger: 'change' }],
  updateTime: [{ required: true, message: '请填写更新时间', trigger: 'change' }],
  remark: [{ required: true, message: '请填写备注', trigger: 'change' }]
}
