const makeMap = (list) => list.reduce((m, { value, label }) => {
  m[value] = label
  return m
}, {})
// 执行周期
export const execCycles = [
  { value: 0, label: '全部' },
  { value: -1, label: '单次' },
  { value: 1, label: '循环-每日' },
  { value: 7, label: '循环-7日' },
  { value: 30, label: '循环-30日' }
]

export const execCycleMap = makeMap(execCycles)

export const loopCycles = execCycles.slice(2).map(({ value, label }) => ({ value, label: label.slice(3) }))

// 执行状态
export const execStatus = [
  { value: 0, label: '全部' },
  { value: 1, label: '待执行' },
  { value: 2, label: '执行中' },
  { value: 3, label: '待下次执行' },
  { value: 4, label: '已完成' }
]

export const execStatusMap = makeMap(execStatus)
// 数据条件
export const dataConditions = [
  { value: 0, label: '全部' },
  { value: 1, label: '企微留存' },
  { value: 2, label: '企微流失' }
]

export const isRetained = dataConditions.slice(1).map(({ value, label }) => ({ value: value, label }))

export const dataConditionMap = makeMap(isRetained)

// 文件类型
export const fileTypes = [
  { value: 's_idfa', label: 'IDFA' },
  { value: 's_imei', label: 'IMEI_MD5' },
  { value: 's_oaid', label: 'OAID_MD5' }
]

export const fileTypeMap = makeMap(fileTypes)
// 性别
export const sexSelects = [
  { value: 0, label: '未知' },
  { value: 1, label: '男' },
  { value: 2, label: '女' }
]
export const sexTypeMap = makeMap(sexSelects)
