var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "navbar-box" }, [
    _c("div", { staticClass: "navbar-left" }, [_c("Dropdown")], 1),
    _c(
      "div",
      { staticClass: "navbar-right" },
      [
        _c("div", { staticClass: "avatar-wrapper" }, [
          _c("div", { staticClass: "image-box" }, [
            _vm.userInfo.avatar
              ? _c("img", { attrs: { src: _vm.userInfo.avatar, alt: "" } })
              : _c("img", {
                  attrs: {
                    src: require("@/assets/image/icon/icon-default-avatar.png"),
                    alt: "",
                  },
                }),
          ]),
        ]),
        _c(
          "el-dropdown",
          [
            _c("div", { staticClass: "user-info" }, [
              _c("span", { staticClass: "name" }, [
                _vm._v(_vm._s(_vm.userInfo.username)),
              ]),
              _c("i", { staticClass: "el-icon-arrow-down icon-right" }),
            ]),
            _c(
              "el-dropdown-menu",
              [
                _c(
                  "el-dropdown-item",
                  { attrs: { command: 0 } },
                  [
                    _c(
                      "s-confirm-box",
                      {
                        attrs: {
                          type: "warning",
                          text: "确定要退出登录吗？",
                          content: "",
                        },
                        on: { confirm: _vm.handleLogout },
                      },
                      [
                        _c("span", { staticClass: "logout" }, [
                          _vm._v("退出登录"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }