<!--
 * @Description: Do not edit
 * @Date: 2022-02-15 09:22:45
 * @LastEditTime: 2022-02-24 09:10:55
-->

<template>
  <div>
    <el-tabs v-model="editableTabsValue" type="card" closable @tab-remove="removeTab">
      <el-tab-pane v-for="item in breadcrumbMenu" :key="item.url" :name="item.url">
        <template slot="label">
          <a-dropdown>
            <router-link :to="item.url" class="el-dropdown-link" tag="span" style="display: inline-block;">
              {{ item.name }}
            </router-link>
            <a-menu slot="overlay" @click="(e) => select(e, item.url)">
              <!-- <a-menu-item :key="1">
                <a href="javascript:;">刷新当前</a>
              </a-menu-item> -->
              <a-menu-item :key="2">
                <a href="javascript:;">关闭当前</a>
              </a-menu-item>
              <a-menu-item :key="3">
                <a href="javascript:;">关闭其他</a>
              </a-menu-item>
              <a-menu-item :key="4">
                <a href="javascript:;">关闭所有</a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { Dropdown, Menu } from 'ant-design-vue'

export default {
  name: 'Breadcrumb',

  components: {
    'a-dropdown': Dropdown,
    'a-menu': Menu,
    'a-menu-item': Menu.Item
  },
  data() {
    return {
      editableTabsValue: '/home'
    }
  },

  computed: {
    ...mapGetters(['sideMenu', 'menu', 'breadcrumbMenu'])
  },

  watch: {
    $route: {
      handler: function(val) {
        this.editableTabsValue = val.path
      }
    }
  },

  methods: {
    ...mapMutations('user', ['SET_SIDE_MENU', 'SET_BREADCRUMB_MENU']),

    select({ key }, url) {
      if (key === 2) {
        // 关闭当前
        let breadcrumbMenu = this.breadcrumbMenu.filter((f) => f.url !== url)
        this.SET_BREADCRUMB_MENU(breadcrumbMenu)
      } else if (key === 3) {
        // 关闭其他
        let breadcrumbMenu = this.breadcrumbMenu.filter((f) => f.url === url)
        this.SET_BREADCRUMB_MENU(breadcrumbMenu)
      } else if (key === 4) {
        // 关闭所有
        this.SET_BREADCRUMB_MENU([])
      }
    },

    removeTab(url) {
      let breadcrumbMenu = this.breadcrumbMenu.filter((f) => f.url !== url)
      this.SET_BREADCRUMB_MENU(breadcrumbMenu)
    }
  }
}
</script>

<style></style>
