<!--
 * @Description: 创意文案
 * @Date: 2021-12-06 15:57:16
 * @LastEditTime: 2022-05-20 10:06:57
-->
<template>
  <div>
    <el-dialog title="批量添加" :visible="isBatch" width="800px" :modal-append-to-body="false" destroy-on-close @close="close">
      <el-form ref="text" :inline="true" :model="text" class="demo-form-inline mt-20">
        <div class="justify-sb mt-10 mb-20">
          <p>项目名称: {{ baseInfo().management.projectName }}</p>
          <p>产品名称: {{ baseInfo().management.productName || '不限' }}</p>
        </div>
        <!-- 朋友圈 -->
        <el-form-item v-if="crtSizeLabel === '朋友圈'" prop="circleOfFriendsText" label="文案:" :rules="rules.circleOfFriendsText">
          <el-input v-model="text.circleOfFriendsText" type="textarea" style="width: 600px" :rows="4" placeholder="请输入文案，多个文案请换行！" />
        </el-form-item>

        <!-- 优雅横版大图 -->
        <el-form-item v-if="crtSizeLabel === '优雅横版大图'" prop="infoBarText" label="信息栏文案:" :rules="rules.infoBarText">
          <el-input v-model="text.infoBarText" :trigger-on-focus="false" type="textarea" style="width: 600px" :rows="4" placeholder="请输入文案，多个文案请换行！" />
        </el-form-item>
        <el-form-item v-if="crtSizeLabel === '优雅横版大图'" prop="graphicTextTitle" label="图文标题:" :rules="rules.graphicTextTitle">
          <el-input v-model="text.graphicTextTitle" type="textarea" placeholder="请输入图文标题，，多个标题请换行！" :rows="4" style="width: 600px" />
        </el-form-item>
        <el-form-item v-if="crtSizeLabel === '优雅横版大图'" prop="advTextOne" label="广告文案:" :rules="rules.advTextOne">
          <el-input v-model="text.advTextOne" type="textarea" placeholder="请输入首行文案，，多个文案请换行！" :rows="4" style="width: 600px" />
        </el-form-item>
        <el-form-item v-if="crtSizeLabel === '优雅横版大图'" prop="advTextTwo" label="" :rules="rules.advTextTwo">
          <el-input v-model="text.advTextTwo" type="textarea" placeholder="请输入次行文案，，多个文案请换行！" :rows="4" style="width: 600px" />
        </el-form-item>
        <el-form-item v-if="crtSizeLabel === '优雅横版大图'" prop="advTextThree" label="" :rules="rules.advTextThree">
          <el-input v-model="text.advTextThree" type="textarea" placeholder="请输入第三行文案(选填)，多个请换行！" :rows="4" style="width: 600px" />
        </el-form-item>

        <!-- 沉浸式横版视频 -->
        <el-form-item v-if="crtSizeLabel === '沉浸式横版视频'" label="广告卡片文案:" prop="adCardCopyText" :rules="rules.adCardCopyText">
          <el-input v-model="text.adCardCopyText" type="textarea" :rows="4" style="width: 600px" placeholder="请输入广告卡片文案, ，多个文案请换行！" />
        </el-form-item>
        <el-form-item v-if="crtSizeLabel === '沉浸式横版视频'" label="信息栏文案:" prop="infoBarText" :rules="rules.infoBarText">
          <el-input v-model="text.infoBarText" type="textarea" :rows="4" placeholder="请输入信息栏文案，多个文案请换行！" style="width: 600px" />
        </el-form-item>
        <el-form-item v-if="crtSizeLabel === '沉浸式横版视频'" label="广告文案:" prop="advTextOne" :rules="rules.advTextOne">
          <el-input v-model="text.advTextOne" type="textarea" :rows="4" placeholder="请输入首行文案，多个文案请换行！" style="width: 600px" :max-length="16" />
        </el-form-item>
        <el-form-item v-if="crtSizeLabel === '沉浸式横版视频'" label="" prop="advTextTwo" :rules="rules.advTextTwo">
          <el-input v-model="text.advTextTwo" type="textarea" :rows="4" placeholder="请输入次行文案，多个文案请换行！" style="width: 600px" :max-length="16" />
        </el-form-item>
        <el-form-item v-if="crtSizeLabel === '沉浸式横版视频'" label="" prop="advTextThree" :rules="rules.advTextThree">
          <el-input v-model="text.advTextThree" type="textarea" :rows="4" placeholder="请输入第三行文案(选填)，多个文案请换行！" style="width: 600px" :max-length="16" />
        </el-form-item>
        <el-form-item v-if="crtSizeLabel === '沉浸式横版视频' && creativityData.isEndPage" prop="tailTextTitle" label="结束页文案:" :rules="rules.tailTextTitle">
          <el-input v-model="text.tailTextTitle" type="textarea" :rows="4" placeholder="请输入结束页文案，多个文案请换行！" style="width: 600px" />
        </el-form-item>

        <!-- 公众号文章底部图片 -->
        <el-form-item v-if="crtSizeLabel === '公众号文章底部图片'" label="信息栏文案:" prop="infoBarText" :rules="rules.infoBarText">
          <el-input v-model="text.infoBarText" type="textarea" :rows="4" placeholder="请输入信息栏文案，多个文案请换行！" style="width: 600px" />
        </el-form-item>

        <!-- 公众号文章底部视频 -->
        <el-form-item v-if="crtSizeLabel === '公众号文章底部视频'" label="信息栏文案:" prop="infoBarText" :rules="rules.infoBarText">
          <el-input v-model="text.infoBarText" type="textarea" :rows="4" placeholder="请输入信息栏文案，多个文案请换行！" style="width: 600px" />
        </el-form-item>
        <el-form-item v-if="crtSizeLabel === '公众号文章底部视频' && creativityData.isEndPage" label="结束页文案:" prop="tailTextTitle" :rules="rules.tailTextTitle">
          <el-input v-model="text.tailTextTitle" type="textarea" :rows="4" placeholder="请输入结束页文案，多个文案请换行！" style="width: 600px" />
        </el-form-item>

        <!-- 公众号文章中部 -->
        <el-form-item v-if="crtSizeLabel === '公众号文章中部'" label="信息栏文案:" prop="text" :rules="rules.text">
          <el-input v-model="text.text" type="textarea" :rows="4" placeholder="请输入信息栏文案，多个文案请换行！" style="width: 600px" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" :disabled="submitFormLoading" @click="addTextSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import SearchSelectAsync from './searchSelectAsync.vue'
import { mapGetters } from 'vuex'
import { addBatchTxt } from '@/network/api/api-material'
export default {
  // components: {
  //   SearchSelectAsync
  // },
  inject: ['baseInfo', 'ruleData'],
  props: {
    isBatch: {
      type: Boolean,
      default: false
    },
    /** 广告位置 */
    crtSizeLabel: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: () => {}
    },
    projectId: {
      type: String,
      default: ''
    },
    creativityData: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    let validateTextLength = (rule, value, callback, len) => {
      let lenArr = value ? value.split('\n') : []
      if (lenArr.some((s) => s.length > len)) {
        callback(new Error(`文案长度不能超过${len}个字符`))
      } else {
        callback()
      }
    }
    return {
      submitFormLoading: false,
      rules: {
        text: [
          { required: true, message: '请输入信息栏文案', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              validateTextLength(rule, value, callback, 12)
            },
            trigger: 'change'
          }
        ],
        tailTextTitle: [
          { required: true, message: '请输入结束页文案', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              validateTextLength(rule, value, callback, 12)
            },
            trigger: 'change'
          }
        ],
        infoBarText: [
          { required: true, message: '请输入信息栏文案', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              validateTextLength(rule, value, callback, 12)
            },
            trigger: 'change'
          }
        ],
        advTextOne: [
          { required: true, message: '请输入首行文案', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              validateTextLength(rule, value, callback, 16)
            },
            trigger: 'change'
          }
        ],
        advTextTwo: [
          { required: true, message: '请输入次行文案', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              validateTextLength(rule, value, callback, 16)
            },
            trigger: 'change'
          }
        ],

        graphicTextTitle: [
          { required: true, message: '请输入图文文案', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              validateTextLength(rule, value, callback, 12)
            },
            trigger: 'change'
          }
        ],
        circleOfFriendsText: [
          { required: true, message: '请输入朋友圈文案', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              validateTextLength(rule, value, callback, 40)
            },
            trigger: 'change'
          }
        ],
        adCardCopyText: [
          { required: true, message: '请输入广告卡片文案', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              validateTextLength(rule, value, callback, 12)
            },
            trigger: 'change'
          }
        ],
        advTextThree: [
          { required: false },
          {
            validator: (rule, value, callback) => {
              validateTextLength(rule, value, callback, 16)
            },
            trigger: 'change'
          }
        ]
      },
      text: {}
    }
  },

  computed: {
    ...mapGetters(['userInfo'])
  },

  watch: {
    isBatch: {
      handler(value) {
        if (value) {
          if (this.crtSizeLabel === '朋友圈') {
            this.text = {
              circleOfFriendsText: null
            }
          } else if (this.crtSizeLabel === '优雅横版大图') {
            this.text = {
              infoBarText: null,
              graphicTextTitle: null,
              advTextOne: null,
              advTextTwo: null,
              advTextThree: null
            }
          } else if (this.crtSizeLabel === '沉浸式横版视频') {
            this.text = {
              adCardCopyText: null,
              infoBarText: null,
              advTextOne: null,
              advTextTwo: null,
              advTextThree: null,
              tailTextTitle: null
            }
          } else if (this.crtSizeLabel === '公众号文章底部图片') {
            this.text = {
              infoBarText: null
            }
          } else if (this.crtSizeLabel === '公众号文章底部视频') {
            this.text = {
              infoBarText: null,
              tailTextTitle: null
            }
          } else if (this.crtSizeLabel === '公众号文章中部') {
            this.text = {
              text: null
            }
          }
        }
      },
      immediate: true
    }
  },

  methods: {
    close() {
      this.$emit('setIsBatch', false)
    },

    setSplit(value) {
      let list = []
      if (value) {
        list = value.split('\n')
      }
      return list
      // if (value) {
      //   return list.filter((f) => f !== '')
      // } else {
      //   return []
      // }
    },

    addTextSubmit() {
      this.$refs['text'].validate((valid) => {
        if (valid) {
          if (!this.submitFormLoading) {
            this.submitFormLoading = true
            setTimeout(() => {
              this.submit()
            }, 100)
          }
        }
      })
    },

    submit() {
      let textList = []
      let ruleData = this.ruleData()
      let params = []

      if (this.crtSizeLabel === '朋友圈') {
        let circleOfFriendsTextArr = this.setSplit(this.text.circleOfFriendsText)
        params = circleOfFriendsTextArr
        circleOfFriendsTextArr.forEach((text) => {
          textList.push({
            circleOfFriendsText: text
          })
        })
      } else if (this.crtSizeLabel === '优雅横版大图') {
        let infoBarTextArr = this.setSplit(this.text.infoBarText)
        let graphicTextTitleArr = this.setSplit(this.text.graphicTextTitle)
        let advTextOneArr = this.setSplit(this.text.advTextOne)
        let advTextTwoArr = this.setSplit(this.text.advTextTwo)
        let advTextThreeArr = this.setSplit(this.text.advTextThree)
        if (infoBarTextArr.length > 3 || graphicTextTitleArr.length > 3 || advTextOneArr.length > 3 || advTextTwoArr.length > 3 || advTextThreeArr.length > 3) {
          return this.$message.error('每项最多填3个文案!')
        }
        // 把文本添加到素材组中
        params = [...infoBarTextArr, ...graphicTextTitleArr, ...advTextOneArr, ...advTextTwoArr, ...advTextThreeArr]
        infoBarTextArr.forEach((infoBarText, index) => {
          graphicTextTitleArr.forEach((graphicTextTitle, index) => {
            advTextOneArr.forEach((advTextOne, index) => {
              advTextTwoArr.forEach((advTextTwo, index) => {
                if (advTextThreeArr.length) {
                  advTextThreeArr.forEach((advTextThree, index) => {
                    textList.push({
                      infoBarText: infoBarText,
                      graphicTextTitle: graphicTextTitle,
                      advTextOne: advTextOne,
                      advTextTwo: advTextTwo,
                      advTextThree: advTextThree || ''
                    })
                  })
                } else {
                  textList.push({
                    infoBarText: infoBarText,
                    graphicTextTitle: graphicTextTitle,
                    advTextOne: advTextOne,
                    advTextTwo: advTextTwo,
                    advTextThree: ''
                  })
                }
              })
            })
          })
        })
      } else if (this.crtSizeLabel === '沉浸式横版视频') {
        let adCardCopyTextArr_2 = this.setSplit(this.text.adCardCopyText)
        let infoBarTextArr_2 = this.setSplit(this.text.infoBarText)
        let advTextOneArr_2 = this.setSplit(this.text.advTextOne)
        let advTextTwoArr_2 = this.setSplit(this.text.advTextTwo)
        let advTextThreeArr_2 = this.setSplit(this.text.advTextThree)
        let tailTextTitleArr_2 = this.setSplit(this.text.tailTextTitle)
        if (infoBarTextArr_2.length > 3 || adCardCopyTextArr_2.length > 3 || advTextOneArr_2.length > 3 || advTextTwoArr_2.length > 3 || advTextThreeArr_2.length > 3 || tailTextTitleArr_2.length > 3) {
          return this.$message.error('每项最多填3个文案!')
        }
        // 把文本添加到素材组中
        params = [...adCardCopyTextArr_2, ...infoBarTextArr_2, ...advTextOneArr_2, ...advTextTwoArr_2, ...advTextThreeArr_2, ...tailTextTitleArr_2]
        adCardCopyTextArr_2.forEach((adCardCopyText, index) => {
          infoBarTextArr_2.forEach((infoBarText, index) => {
            advTextOneArr_2.forEach((advTextOne, index) => {
              advTextTwoArr_2.forEach((advTextTwo, index) => {
                if (advTextThreeArr_2.length && tailTextTitleArr_2.length) {
                  advTextThreeArr_2.forEach((advTextThree, index) => {
                    tailTextTitleArr_2.forEach((tailTextTitle, index) => {
                      textList.push({
                        adCardCopyText: adCardCopyText,
                        infoBarText: infoBarText,
                        advTextOne: advTextOne,
                        advTextTwo: advTextTwo,
                        advTextThree: advTextThree,
                        tailTextTitle: tailTextTitle
                      })
                    })
                  })
                } else if (advTextThreeArr_2.length) {
                  advTextThreeArr_2.forEach((advTextThree, index) => {
                    textList.push({
                      adCardCopyText: adCardCopyText,
                      infoBarText: infoBarText,
                      advTextOne: advTextOne,
                      advTextTwo: advTextTwo,
                      advTextThree: advTextThree,
                      tailTextTitle: ''
                    })
                  })
                } else if (tailTextTitleArr_2.length) {
                  tailTextTitleArr_2.forEach((tailTextTitle, index) => {
                    textList.push({
                      adCardCopyText: adCardCopyText,
                      infoBarText: infoBarText,
                      advTextOne: advTextOne,
                      advTextTwo: advTextTwo,
                      advTextThree: tailTextTitle,
                      tailTextTitle: ''
                    })
                  })
                } else {
                  textList.push({
                    adCardCopyText: adCardCopyText,
                    infoBarText: infoBarText,
                    advTextOne: advTextOne,
                    advTextTwo: advTextTwo,
                    advTextThree: '',
                    tailTextTitle: ''
                  })
                }
              })
            })
          })
        })
      } else if (this.crtSizeLabel === '公众号文章底部图片') {
        let infoBarTextArr = this.setSplit(this.text.infoBarText)
        // 把文本添加到素材组中
        params = [...infoBarTextArr]
        infoBarTextArr.forEach((text) => {
          textList.push({
            infoBarText: text
          })
        })
      } else if (this.crtSizeLabel === '公众号文章底部视频') {
        let infoBarTextArr = this.setSplit(this.text.infoBarText)
        let tailTextTitleArr = this.setSplit(this.text.tailTextTitle)
        // 把文本添加到素材组中
        params = [...infoBarTextArr, ...tailTextTitleArr]
        if (this.verification([infoBarTextArr, tailTextTitleArr])) {
          infoBarTextArr.forEach((infoBarText, index) => {
            tailTextTitleArr.forEach((tailTextTitle, index) => {
              textList.push({
                infoBarText: infoBarText,
                tailTextTitle: tailTextTitle
              })
            })
          })
        }
      } else if (this.crtSizeLabel === '公众号文章中部') {
        let textArr = this.setSplit(this.text.text)
        // 把文本添加到素材组中
        params = [...textArr]
        textArr.forEach((text) => {
          textList.push({
            text: text
          })
        })
      }
      let params2 = params.map((text) => {
        return {
          text,
          productId: ruleData.productIds,
          projectId: ruleData.projectId,
          adminId: this.userInfo.userid,
          nickname: this.userInfo.username
        }
      })
      addBatchTxt(params2).then((res) => {
        if (res.code === 200) {
          this.submitFormLoading = false
          this.$message.success('添加成功')
          this.$emit('onOk', textList)
          this.close()
        }
      })
    },

    /**
     * @desc 验证每个文案长度是否相同
     * @params advTextThreeArr 第三行文案
     */
    verification(textList, advTextThreeArr) {
      if (advTextThreeArr[0].length) {
        textList = [...textList, ...advTextThreeArr]
      }
      if (textList) {
        if (textList.every((e) => e.length !== 0)) {
          return true
        } else {
          this.$message.error('文案个数不统一')
          return false
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>
