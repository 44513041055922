import service from '@/network/service/service'

export function Page(data) {
  return service.post('/api/system/basicSettingProductType/page', data)
}

export function Add(data) {
  return service.post('/api/system/basicSettingProductType/save', data)
}

export function Update(data) {
  return service.post('/api/system/basicSettingProductType/update', data)
}

export function Delete(id) {
  return service.delete('/api/system/basicSettingProductType/' + id)
}

export function getAllIsUseing() {
  return service.get('/api/system/basicSettingProductType/getAllIsUseing')
}

