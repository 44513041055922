var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "图片裁剪",
            width: "900px",
            visible: _vm.visible,
            "modal-append-to-body": false,
            "before-close": _vm.cancel,
          },
        },
        [
          _c("div", { staticClass: "v-crop" }, [
            _c(
              "div",
              { staticClass: "v-crop-top" },
              [
                _c("CropCanvas", {
                  ref: "cropRef",
                  attrs: {
                    image: _vm.image,
                    crop: _vm.crop,
                    "max-size": _vm.maxSize,
                    natural: _vm.natural,
                    target: _vm.target,
                    src: _vm.src,
                  },
                  on: {
                    onImageLoaded: _vm.onImageLoaded,
                    onMouseWheel: _vm.onMouseWheel,
                  },
                }),
                _c(
                  "div",
                  { staticClass: "v-crop-list" },
                  [
                    _c(
                      "ul",
                      _vm._l(_vm.urls, function (url, index) {
                        return _c(
                          "li",
                          {
                            key: index,
                            class: {
                              "v-crop-item": true,
                              "v-crop-item-croped": url === _vm.src,
                            },
                          },
                          [
                            _c("img", { attrs: { src: url, alt: "img" } }),
                            _c(
                              "div",
                              {
                                class: {
                                  "v-crop-item-croped": true,
                                  "v-crop-item-croped-active":
                                    !!_vm.fileList[index],
                                },
                              },
                              [_vm._v(" 完成 ")]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "v-crop-done",
                        attrs: { type: "primary" },
                        on: { click: _vm.cropFun },
                      },
                      [_vm._v(" 裁剪 ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("section", { staticClass: "v-crop-info" }, [
              _c("div", [
                _c("p", { staticClass: "v-crop-info-title mb-10" }, [
                  _vm._v("图片信息"),
                ]),
              ]),
              _c(
                "p",
                { staticClass: "tip", staticStyle: { "font-size": "14px" } },
                [
                  _vm._v(
                    "图片尺寸: " +
                      _vm._s(`${_vm.natural.width}px * ${_vm.natural.height}px`)
                  ),
                ]
              ),
              _c("div", { staticClass: "v-crop-info-size" }, [
                _c("p", { staticClass: "v-crop-info-title mb-10" }, [
                  _vm._v("可选裁切尺寸"),
                ]),
                _c("div", { staticClass: "v-crop-controller" }, [
                  _c("span", { staticClass: "v-crop-example" }),
                  _c(
                    "div",
                    [
                      !_vm.targetWidth
                        ? _c("el-input-number", {
                            staticStyle: { width: "100px" },
                            attrs: {
                              "controls-position": "right",
                              value: _vm.target.width,
                              min: _vm.rangeWidth
                                ? _vm.rangeWidth[0]
                                : undefined,
                              max: _vm.rangeWidth
                                ? _vm.rangeWidth[1]
                                : undefined,
                              size: "small",
                            },
                            on: {
                              change: (v) => _vm.onTargetChange(v, "width"),
                            },
                          })
                        : _c("p", [_vm._v(_vm._s(_vm.targetWidth))]),
                    ],
                    1
                  ),
                  _vm._v(" * "),
                  _c(
                    "div",
                    [
                      _vm.targetHeight === null
                        ? _c("el-input-number", {
                            staticStyle: { width: "100px" },
                            attrs: {
                              value: _vm.target.height,
                              min: _vm.rangeHeight
                                ? _vm.rangeHeight[0]
                                : undefined,
                              max: _vm.rangeHeight
                                ? _vm.rangeHeight[1]
                                : undefined,
                              size: "small",
                              "controls-position": "right",
                            },
                            on: {
                              change: (v) => {
                                _vm.onTargetChange(v, "height")
                              },
                            },
                          })
                        : _c("p", [_vm._v(_vm._s(_vm.targetHeight))]),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onOk } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }