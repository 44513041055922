<!--
 * @Description: 推广创意模板选择
 * @Date: 2021-11-09 09:32:28
 * @LastEditTime: 2022-05-19 16:44:09
-->
<template>
  <div>
    <Base title="推广创意模板">
      <div class="justify-sb">
        <el-button type="primary" @click="addTemplate">添加创意</el-button>
        <div class="justify-start">
          <span class="mr-10">创建</span>
          <el-input-number v-model="creativeNum" class="mr-10" :min="1" :max="creativeNumMax" controls-position="right" style="width:140px" />
          <span class="mr-20">个创意组</span>
          <span class="tip mr-20">
            理论组合数
            <span style="font-size: 16px">{{ combinationNum }}</span> 个
          </span>
          <el-tooltip class="item" effect="dark" content="批量上传较慢,请耐心等待" placement="top">
            <div>
              <el-button :loading="pushLoading" type="primary" :disabled="dataSource.data.every((item) => item.releaseStatus === '已发布')" @click="publishAll(dataSource.data)">素材全部上传</el-button>
            </div>
          </el-tooltip>
        </div>
      </div>
      <div class="table-pane mt-20">
        <TablePane v-loading="tableLoading" :element-loading-text="`${creativeFeedName}, 正在素材上传中`" :data-source="dataSource" class="table" border @getPage="getPage"> </TablePane>
      </div>
      <div class="bottom-btn">
        <el-tooltip class="item ml-20" effect="dark" content="执行上一步操作,列表数据将会被清除" placement="top">
          <div>
            <el-button :disabled="prevStepDisabled || pushLoading || tableLoading || dataSource.data.some((item) => item.pushStatus !== '等待发布')" @click="prevStep">上一步</el-button>
          </div>
        </el-tooltip>
        <el-tooltip v-if="dataSource.length === 0 || dataSource.data.some((item) => item.releaseStatus === '未发布')" class="item" effect="dark" content="请先上传所有素材！" placement="top">
          <div>
            <el-button class="ml-20" type="primary" disabled>发 &nbsp; 布</el-button>
          </div>
        </el-tooltip>
        <el-button v-else type="primary" :disabled="dataSource.data.every((item) => item.pushStatus === '发布成功')" :loading="submitLoading" class="ml-20" @click="submit"> 发 &nbsp; 布 </el-button>
        <el-button class="ml-20" :disabled="pushLoading || tableLoading || dataSource.data.some((item) => item.pushStatus !== '等待发布')" @click="cancel">取 &nbsp; 消</el-button>
      </div>
    </Base>
    <SeleteModal
      dialog-title="选择推广创意"
      left-title="单元"
      :tab-pane="[
        {
          title: '创意模板',
          name: 'xsdy',
          subTitle: [{ key: 'xsmb', value: '关联创意模板' }],
          sort: 1,
          level: '2'
        },
        {
          title: '线上创意',
          name: 'dymb',
          subTitle: [{ key: 'xsdy', value: '关联创意模板' }],
          sort: 2,
          level: '2'
        }
      ]"
      :visible="seleteModalVisible"
      :local-query="dataAll.unit.filter((item) => item.type === '模板')"
      :online-query="dataAll.unit.filter((item) => item.type === '线上')"
      :local-data="meCreative"
      :online-data="baiduMeCreative"
      :data-source="dataSource.data"
      @onOk="getTemplateData"
    />
  </div>
</template>

<script>
import Base from './component/base.vue'
import TablePane from '@/components/tablePane.vue'
import SeleteModal from './component/seleteModal.vue'
import { GetCreativeByUnit, bySubjectGetCreative, byIdUploadBaiduImgOrVideo, deleteBaiduCreativeFend, batchUpCreative, getUploadList } from '@/network/api/advManagement/advManagementBaiduCreativeFend' // 获取创意模板
import _ from 'lodash'
import { jsonFormat } from '../../../../utils/ruoyi'

const PUBLISH = '已发布'
const UN_PUBLISH = '未发布'

export default {
  provide: function() {
    return {
      getBaiduOnLocal: this.getCreative,
      getBaiduOnline: this.getCreativeByOnline,
      getBaiduOnlineTwo: () => {},
      close: () => {
        this.seleteModalVisible = false
        this.baiduMeCreative = []
      }
    }
  },
  inject: ['setStep', 'dataAll'],
  components: {
    Base,
    TablePane,
    SeleteModal
  },
  props: {
    baiduIdList: {
      type: Array,
      default: () => []
    },
    housekeeperInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      seleteModalVisible: false,
      ldyVisible: false, // 选择落地页Visible
      meCreative: [], // 创意模板list
      baiduMeCreative: [], // 百度创意模板list
      templateData: [], // 选中的模板数据
      pushLoading: false,
      submitLoading: false,
      tableLoading: false,
      creativeFeedName: '',
      creativeNum: 1, // 创意数量
      creativeNumMax: 99, // 创意数量最大值
      combinationNum: 0, //  理论生成组数
      uploadList: [], // 获取上传后数组
      prevStepDisabled: false,
      pushData: [],
      // 表格数据
      dataSource: {
        data: [],
        cols: [
          {
            label: '创意名称',
            prop: 'lable'
          },
          {
            label: '线上创意Id',
            render: (record) => (record.creativeFeedId ? record.creativeFeedId : '-')
          },
          {
            label: '线上创意名称',
            render: (record) => (record.creativeFeedName2 ? record.creativeFeedName2 : '-')
          },
          {
            label: '创建人',
            width: 130,
            prop: 'createBy'
          },
          {
            label: '创建时间',
            // width: 130,
            prop: 'createTime'
          },
          {
            label: '投放账户',
            prop: 'accountName'
          },
          {
            label: '关联计划模板',
            // width: 130,
            prop: 'campaignFeedName'
          },
          {
            label: '关联单元模板',
            // width: 130,
            prop: 'baiduUnitName'
          },
          {
            label: '类型',
            width: 80,
            prop: 'type'
          },

          {
            label: '上传状态',
            width: 100,
            render: (record) => {
              if (record.releaseStatus === PUBLISH) {
                // eslint-disable-next-line
                return <el-tag type="success">已上传</el-tag>
              } else {
                // eslint-disable-next-line
                return <el-tag type="danger">未上传</el-tag>
              }
            }
          },
          {
            label: '发布状态',
            width: 100,
            render: (record) => {
              /* eslint-disable */
              if (record.pushStatus === '发布成功') {
                return <el-tag type="success">发布成功</el-tag>
              } else if (record.pushStatus === '发布失败') {
                return (
                  <el-tooltip class="item" effect="dark" placement="top">
                    <template slot="content"> {record.error_msg} </template>
                    <el-tag type="danger">发布失败</el-tag>
                  </el-tooltip>
                )
              } else {
                return <el-tag type="info">等待发布</el-tag>
              }
              /* eslint-disable */
            }
          }
        ], // 表格的列数据
        isIndex: true, // 列表序号
        loading: false, // loading
        isOperation: true,
        isBorder: true,
        handleSelectionChange() {},
        pageData: {
          total: 0, // 总条数
          pageSize: 10,
          layout: 'total, prev, pager, next, jumper'
        },
        operation: {
          // 表格有操作列时设置
          label: '操作', // 列名
          width: '120', // 根据实际情况给宽度
          loading: this.tableLoading,
          data: [
            {
              label: '素材上传', // 操作名称
              type: 'text', // 按钮类型
              showLabel: 'releaseStatus',
              showValue: ['未发布'],
              handleRow: async (index, record) => {
                this.tableLoading = true
                this.creativeFeedName = record.creativeFeedName
                this.publishAll([record])
              } // 自定义事件
            },
            {
              label: '删除', // 操作名称
              type: 'text', // 按钮类型
              color: '#ff0000',
              showLabel: 'pushStatus',
              showValue: ['等待发布', '发布失败'],
              handleRow: async (index, record) => {
                if (record.releaseStatus === PUBLISH && record.type === '线上') {
                  const { code } = await deleteBaiduCreativeFend(record.housekeeperId, record.baiduId, record.creativeFeedId)
                  if (code === 200) {
                    this.dataSource.data.splice(index, 1)
                    this.$message.success('删除成功!')
                  }
                } else {
                  this.dataSource.data.splice(index, 1)
                  this.$message.success('删除成功!')
                }
                this.templateData = this.templateData.filter((f) => f.creativeFeedId !== record.creativeFeedId)
                // debugger
                let combinationNumList = this.dataSource.data.map((item) => item.groupNum)
                this.combinationNum = combinationNumList.length ? combinationNumList.reduce((n, o) => Number(n || 0) + Number(o || 0)) : 0
              }
            }
          ]
        }
      }
    }
  },

  methods: {
    /**
     * @description 获取单元模板 (单元模板/_id)
     */
    async getCreative(Item) {
      const { code, data } = await GetCreativeByUnit(Item.baiduUnitId)
      if (code === 200) {
        data.forEach((item) => {
          item.id = `${Item.adgroupFeedId}+${item.baiduCreativeFendId}`
          item.lable = item.creativeFeedName
        })
        // 判线上创意板是否被选中, 选中置灰
        if (this.dataSource.data.length) {
          data.forEach((item) => {
            this.templateData.forEach((el) => {
              if (item.id === el.id) {
                item.disabled = true
              }
            })
          })
        }
        this.meCreative = data
      }
    },

    /**
     * 添加模板
     */
    addTemplate() {
      this.seleteModalVisible = true
    },

    /**
     * @description 获取线上创意
     * @param {item} 已选中的线上创意
     */
    async getCreativeByOnline(Item) {
      const { code, data } = await bySubjectGetCreative(Item.subject)
      if (code === 200) {
        data.forEach((item) => {
          item.id = `${Item.adgroupFeedId}+${item.baiduCreativeFendId}`
          item.lable = item.creativeFeedName
        })
        // 判线上创意板是否被选中, 选中置灰
        if (this.dataSource.data.length) {
          data.forEach((item) => {
            this.templateData.forEach((el) => {
              if (item.id === el.id) {
                item.disabled = true
              }
            })
          })
        }
        this.baiduMeCreative = data
      }
    },

    /**
     * @description 获取创意模板数据
     * @param {data}
     */
    getTemplateData(data) {
      let qc_data = [...this.templateData, ...data.online, ...data.local]
      const groupByData = _.groupBy(qc_data, 'adgroupFeedId')
      let flag = false
      for (const key in groupByData) {
        if (Object.hasOwnProperty.call(groupByData, key)) {
          const element = groupByData[key]
          if (element.length !== 1) {
            flag = true
          }
        }
      }
      if (flag) {
        return this.$message.error('一个单元下只能选择一个创意!')
      }
      let combinationNumList = qc_data.map((item) => item.groupNum)
      this.combinationNum = combinationNumList.reduce((n, o) => Number(n || 0) + Number(o || 0)) || 0
      qc_data.forEach((element) => {
        element.pushStatus = '等待发布'
      })
      this.dataSource.data = [...qc_data].splice(0, 10)
      this.templateData = qc_data
      this.dataSource.pageData.total = qc_data.length
    },

    /**
     * @description 设置分页
     */
    getPage({ pageSize, pageNum }) {
      let data = [...this.templateData]
      this.dataSource.data = data.splice((pageNum - 1) * pageSize, pageSize * pageNum)
    },

    /**
     * @description 素材上传前准备
     */
    async getUploadListFun(list) {
      const { housekeeperId } = this.housekeeperInfo
      let params = {
        housekeeperId,
        creativeSize: this.creativeNum,
        lists: []
      }
      list.forEach((item) => {
        if (item.releaseStatus === UN_PUBLISH) {
          const { baiduCreativeFendId, adgroupFeedId, campaignFeedId, baiduId } = item
          params.lists.push({
            baiduCreativeFendId,
            campaignFeedId,
            adgroupFeedId,
            baiduId
          })
        }
      })
      return await getUploadList(params)
    },

    /**
     * @description 全部上传
     */
    async publishAll(dataList = this.dataSource.data) {
      const { code, data } = await this.getUploadListFun(dataList)
      if (code !== 200) return
      this.uploadList = data
      this.pushLoading = true
      let items = []
      data.map((uploadItem) => {
        let dataSourceItem = this.dataSource.data.find((f) => f.baiduCreativeFendId === uploadItem.baiduCreativeFendId && +f.campaignFeedId === +uploadItem.campaignFeedId && +f.adgroupFeedId === +uploadItem.adgroupFeedId && f.baiduId === uploadItem.baiduId)

        items.push({
          ...dataSourceItem,
          ...uploadItem,
          advAccountName: dataSourceItem.accountName, // 百度投放账户
          // baiduId: item.baiduId, // 百度投放账户Id
          // baiduCreativeFendId: item.baiduCreativeFendId, // 创意模板id
          imageIds: uploadItem.imgIds // 创意图片id容
          // videoIds: item.videoIds // 创意视频id容
        })
      })
      this.dataSource.data.forEach((f) => {
        items.map((itemsItem) => {
          if (+f.campaignFeedId === +itemsItem.campaignFeedId && +f.adgroupFeedId === +itemsItem.adgroupFeedId && f.baiduCreativeFendId === itemsItem.baiduCreativeFendId && f.baiduId === itemsItem.baiduId) {
            f.adgroupFeedId = itemsItem.adgroupFeedId
            f.baiduCreativeFendCacheId = itemsItem.baiduCreativeFendCacheId
            f.baiduCreativeFendId = itemsItem.baiduCreativeFendId
            f.baiduId = itemsItem.baiduId
            f.creativeSize = itemsItem.creativeSize
            // f.campaignFeedId = itemsItem.campaignFeedId
            // f.housekeeperId = itemsItem.housekeeperId
            f.imgIds = itemsItem.imgIds
            f.materialCache = itemsItem.materialCache
            f.materialEachSize = itemsItem.materialEachSize
            f.videoId = itemsItem.videoIds
            f.pushStatus = '等待发布'
          }
        })
      })
      console.log(this.dataSource.data)
      const { housekeeperId, launchAccountName, launchAccountPassword, token } = this.housekeeperInfo
      let params = {
        housekeeperId,
        launchAccountName, // 管家投放名称
        launchAccountPassword, // 管家投放密码
        token, // 管家投放密码
        items
      }
      byIdUploadBaiduImgOrVideo(params).then((res) => {
        if (res) {
          let success = []
          let error = []
          if (res.code === 200) {
            res.data.items.map((item) => {
              if (item.code === 200) {
                this.dataSource.data.forEach((element) => {
                  if (+element.baiduCreativeFendId === +item.baiduCreativeFendId && +element.baiduId === +item.baiduId) {
                    element.releaseStatus = PUBLISH
                    element.imgUploadInfo = item.imgUploadInfo
                    element.videoUploadInfo = item.videoUploadInfo
                    element.housekeeperId
                  }
                })
                success.push(item.baiduCreativeFendId)
              } else {
                error.push(item.baiduCreativeFendId)
              }
            })
          }

          if (success.length === this.dataSource.data.length) {
            this.$message.success('全部上传成功')
          } else if (error.length === this.dataSource.data.length) {
            this.$message.error('全部上传失败')
          } else {
            if (error.length === 0) {
              this.$message.success('上传成功')
            } else if (success.length === 0) {
              this.$message.success('上传失败')
            } else {
              this.$message.info(`上传成功:${success.length}个, 上传失败:${error.length}个`)
            }
          }
        }
        this.pushLoading = false
        this.tableLoading = false
      })
    },

    /**
     * @description 上一步
     */
    prevStep() {
      this.templateData = []
      this.dataSource.data = []
      this.setStep('prev')
    },

    /**
     * @description 取消
     */
    async cancel() {
      // this.setStep('cancel')
      // let items = []
      // this.dataSource.data.map((item) => {
      //   if (item.releaseStatus === PUBLISH && item.type !== '模板') {
      //     if (items.length === 0 || !items.some((s) => s.baiduId === item.baiduId)) {
      //       items.push({
      //         baiduId: item.baiduId,
      //         feedId: []
      //       })
      //     }
      //   }
      // })
      // this.dataSource.data.map((item) => {
      //   if (item.releaseStatus === PUBLISH && item.type !== '模板') {
      //     items.forEach((element) => {
      //       if (element.baiduId === item.baiduId) {
      //         element.feedId.push(item.creativeFeedId)
      //       }
      //     })
      //   }
      // })

      // if (items.length === 0) {
      //   this.dataSource.data = []
      //   this.templateData = []
      //   this.submitLoading = false
      // }

      // let params = {
      //   housekeeperId: this.dataSource.data[0].housekeeperId,
      //   items
      // }
      // const { code } = await deleteBaiduApiCreative(params)
      // if (code === 200) {
      //   this.dataSource.data = []
      //   this.templateData = []
      //   this.submitLoading = false
      //   this.$message.success('操作成功!')
      // }
      this.templateData = []
      this.dataSource.data = []
    },

    /**
     * @description 发布
     */
    async submit() {
      this.submitLoading = true
      const { housekeeperId } = this.housekeeperInfo
      let params = {
        housekeeperId,
        creativeSize: this.creativeNum,
        items: []
      }
      let new_data = []
      this.dataSource.data.map((item) => {
        if (new_data.length === 0 || !new_data.some((s) => s.campaignFeedId === item.campaignFeedId && s.adgroupFeedId === item.adgroupFeedId)) {
          new_data.push({
            ...item,
            baiduCreativeFendId: [item.baiduCreativeFendId]
          })
        } else {
          new_data.forEach((element) => {
            if (element.campaignFeedId === item.campaignFeedId && element.adgroupFeedId === item.adgroupFeedId) {
              element.baiduCreativeFendId.push(item.baiduCreativeFendId)
              element.imgUploadInfo = [...element.imgUploadInfo, ...item.imgUploadInfo]
              element.videoUploadInfo = [...element.videoUploadInfo, ...item.videoUploadInfo]
            }
          })
        }
      })

      new_data.map((item) => {
        const { baiduId, campaignFeedId, campaignFeedName, adgroupFeedId, adgroupFeedName, baiduCreativeFendId, imgUploadInfo, videoUploadInfo, material } = item
        const { elements } = jsonFormat(material, 'object')
        let itemsItem = {
          campaignFeedName, // 计划名称
          baiduCreativeFendId, // 创意本地id
          adgroupFeedId, // 单元线上id
          baiduId, // 百度账户
          campaignFeedId, // 计划id
          adgroupFeedName, // 单元线上名称
          imgUploadInfo, // 返回图片上传信
          videoUploadInfo, // 返回视频返回信息"
          creativeNum: this.creativeNum,
          titles: elements.titles,
          pictureSingle: elements.pictureSingle.length ? elements.pictureSingle : null,
          pictureLarge: elements.pictureLarge.length ? elements.pictureLarge : null,
          picture3Fixed: elements.picture3Fixed.length ? elements.picture3Fixed : null,
          videoHorizontal: elements.videoHorizontal.length ? elements.videoHorizontal : null,
          videoVertical: elements.videoVertical.length ? elements.videoVertical : null,

          creativeSize: this.creativeNum,
          advManagementBaiduCreativeFendCache: {
            adgroupFeedId,
            baiduCreativeFendCacheId: item.baiduCreativeFendCacheId,
            baiduCreativeFendId: +item.baiduCreativeFendId[0],
            baiduId,
            campaignFeedId: item.campaignFeedId,
            creativeSize: item.creativeSize,
            housekeeperId: item.housekeeperId,
            imgIds: item.imgIds,
            materialCache: item.materialCache,
            materialEachSize: item.materialEachSize,
            videoIds: item.videoIds
          }
        }

        params.items.push(itemsItem)
      })

      const res = await batchUpCreative(params)
      this.submitLoading = false
      this.prevStepDisabled = true
      if (res) {
        const { code, data } = res
        if (code === 200) {
          let resData = []
          data.map((Item) => {
            Item.itemVos.map((item) => {
              resData.push({
                adgroupFeedId: +Item.adgroupFeedId,
                baiduId: +Item.baiduId,
                baiduCreativeFendId: item.baiduCreativeFendId,
                code: item.code,
                msg: item.msg,
                creativeFeedId: item.creativeFeedId,
                creativeFeedName: item.creativeFeedName
              })
            })
          })
          let success = []
          let error = []
          resData.map((item) => {
            this.dataSource.data.forEach((element) => {
              if (+element.adgroupFeedId === +item.adgroupFeedId && +element.baiduId === +item.baiduId && +element.baiduCreativeFendId === +item.baiduCreativeFendId) {
                if (item.code === 200) {
                  element.pushStatus = '发布成功'
                  // element.creativeFeedId = item.itemVos[0].creativeFeedId
                  element.creativeFeedName2 = item.itemVos[0].creativeFeedName
                  // element.housekeeperId = +item.housekeeperId
                  success.push(item.creativeFeedId)
                } else {
                  element.pushStatus = '发布失败'
                  error.push(item.creativeFeedId)
                  element.error_msg = item.msg
                }
                this.submitLoading = false
              }
            })
          })
          if (success.length === this.dataSource.data.length) {
            this.$message.success('全部发布成功')
            this.$router.push('/publishLogTable')
          } else if (error.length === this.dataSource.data.length) {
            this.$message.error('全部发布失败')
          } else {
            if (error.length === 0) {
              this.$message.success('发布布成功')
            } else if (success.length === 0) {
              this.$message.success('发布失败')
            } else {
              this.$message.info(`发布成功:${success.length}个, 发布失败:${error.length}个`)
            }
          }
          this.submitLoading = false
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.demo-form-inline {
  display: flex;
  justify-content: space-between;
}

.table-pane {
  padding: 10px 40px 0 40px;
  text-align: right;
}

.bottom-btn {
  display: flex;
  justify-content: center;
}
</style>
