/*
 * @Description: Do not edit
 * @Date: 2021-12-27 14:40:58
 * @LastEditTime: 2022-03-18 13:47:14
 */

export const topSlider_img_item = {
  type: '41',
  pureImageUrl: '',
  imageHeight: 750,
  imageWidth: 750,
  initWidth: '',
  initHeight: '',
  fileSize: 0,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,
  materialId: 0,
  imageMd5: ''
}

// 复合组件一行一个基本效果
export const shelfnew_one = [
  {
    jumpMode: 'btn_jump',
    layoutHeight: '228',
    layoutWidth: '670',
    subType: '1',
    type: '103',
    layoutItems: {
      componentItem: [
        {
          name: '图片',
          layoutHeight: '180',
          layoutWidth: '180',
          paddingLeft: '24',
          paddingTop: '24',
          cornerRadius: '4',
          pureImageUrl: '',
          type: '41',
          imageMd5: '',
          materialId: '',
          wxad_IsSubNode: '1'
        },
        {
          descType: 'text',
          layoutHeight: '228',
          layoutWidth: '470',
          paddingLeft: '24',
          paddingRight: '40',
          paddingTop: '24',
          subType: '0',
          type: '103',
          wxad_IsSubNode: '1',
          layoutItems: {
            componentItem: [
              {
                content: '标题',
                fontColor: '#353535',
                fontSize: '32',
                layoutHeight: '44',
                layoutWidth: '410',
                maxLines: '1',
                name: '标题',
                showType: '1',
                type: '1',
                wxad_IsSubNode: '1'
              },
              {
                content: '描述',
                fontColor: '#b2b2b2',
                fontSize: '24',
                layoutHeight: '40',
                layoutWidth: '410',
                name: '描述',
                paddingTop: '8',
                type: '1',
                wxad_IsSubNode: '1'
              },
              {
                id: `widget_${new Date().getTime()}_0`,
                name: '添加商家微信',
                type: '21',
                qrUrl: '',
                corpid: '',
                btnType: '0',
                groupid: 0,
                subType: '15',
                useIcon: '0',
                btnStyle: '1',
                btnTitle: '联系商家',
                fontSize: '26',
                btnHeight: '60',
                fontColor: '#FFFFFF',
                qrExtInfo: '',
                borderSize: '0',
                btnJumpUrl: '',
                mpJumpType: '1',
                paddingTop: '28',
                widgetType: 'button',
                btnFontType: '0',
                layoutWidth: '152',
                paddingLeft: '0',
                serviceType: '1',
                cornerRadius: 4,
                h5_config_id: '',
                layoutHeight: '60',
                paddingRight: '0',
                widgetTypeV2: 'enterprise_wx_auto',
                chatGroupName: '',
                paddingBottom: '0',
                origBtnJumpUrl: '',
                btnBgColorTheme: '#07C160',
                needUpdateQrUrl: 0,
                btnBorderColorTheme: '#FFFFFF',
                wxapp_config_id: ''
              }
            ]
          }
        }
      ]
    }
  }
]

// 复合组件一行两个基本效果
export const shelfnew_two = [
  {
    layoutHeight: '542',
    layoutWidth: '324',
    type: '104',
    borderColor: '#e5e5e5',
    bgColor: '#ffffff',
    borderSize: '1',
    cornerRadius: '8',
    layoutItems: {
      componentItem: [
        {
          jumpMode: 'btn_jump',
          layoutHeight: '542',
          layoutWidth: '324',
          type: '103',
          subType: '0',
          descType: 'text', // 描述/价格price
          layoutItems: {
            componentItem: [
              {
                name: '图片',
                layoutHeight: '300',
                pureImageUrl: '',
                type: '41',
                imageMd5: '73d6c223f384f1a5a581d8e24e6cd887',
                layoutWidth: '300',
                paddingLeft: '12',
                paddingTop: '12',
                cornerRadius: '4',
                materialId: 2767582888,
                wxad_IsSubNode: '1'
              },
              {
                name: '标题',
                content: '',
                fontColor: '#353535',
                fontSize: '32',
                layoutHeight: '44',
                layoutWidth: '272',
                maxLines: '1',
                paddingLeft: '24',
                paddingTop: '16',
                showType: '1',
                type: '1',
                wxad_IsSubNode: '1'
              },
              {
                name: '描述',
                content: '',
                fontColor: '#b2b2b2',
                fontSize: '24',
                paddingLeft: '24',
                layoutHeight: '40',
                layoutWidth: '272',
                paddingTop: '8',
                type: '1',
                wxad_IsSubNode: '1'
              }
            ]
          }
        }
      ]
    },
    wxad_IsSubNode: '1'
  },
  {
    layoutHeight: '542',
    layoutWidth: '324',
    type: '104',
    borderColor: '#e5e5e5',
    bgColor: '#ffffff',
    borderSize: '1',
    cornerRadius: '8',
    paddingLeft: '22',
    layoutItems: {
      componentItem: [
        {
          jumpMode: 'btn_jump',
          layoutHeight: '542',
          layoutWidth: '324',
          type: '103',
          subType: '0',
          descType: 'text',
          layoutItems: {
            componentItem: [
              {
                name: '图片',
                layoutHeight: '300',
                pureImageUrl: '',
                type: '41',
                imageMd5: '2a8fc2703cfed528a1417dd82e3b4256',
                layoutWidth: '300',
                paddingLeft: '12',
                paddingRight: '12',
                paddingTop: '12',
                cornerRadius: '4',
                materialId: 2767775781,
                wxad_IsSubNode: '1'
              },
              {
                name: '标题',
                content: '',
                fontColor: '#353535',
                fontSize: '32',
                layoutHeight: '44',
                layoutWidth: '272',
                paddingLeft: '24',
                maxLines: '1',
                paddingTop: '16',
                showType: '1',
                type: '1',
                wxad_IsSubNode: '1'
              },
              {
                name: '描述',
                content: '',
                fontColor: '#b2b2b2',
                fontSize: '24',
                layoutHeight: '40',
                layoutWidth: '272',
                paddingLeft: '24',
                paddingTop: '8',
                type: '1',
                wxad_IsSubNode: '1'
              }
            ]
          }
        }
      ]
    },
    wxad_IsSubNode: '1'
  }
]

// 复合组件 - 关注公众号
export const shelfnew_gh = {
  widgetTypeV2: 'gh',
  widgetType: 'button',
  type: '21',
  name: '商品关注公众号',
  btnTitle: '关注公众号',
  subType: '17',
  fontSize: '26',
  fontColor: '#FFFFFF',
  btnType: '0',
  borderSize: '0',
  btnHeight: '60',
  btnBgColorTheme: '#07C160',
  btnBorderColorTheme: '#FFFFFF',
  btnFontType: '0',
  btnStyle: '1',
  paddingTop: '28',
  paddingBottom: '0',
  paddingRight: '0',
  paddingLeft: '0',
  cornerRadius: 4,
  useIcon: '0',
  field21_1: {
    origBtnJumpUrl: '',
    wxad_guide_group_id: ''
  },
  id: 'widget_1640673513357_3',
  layoutWidth: '152',
  layoutHeight: '60'
}
// 复合组件 - 添加商家微信
export const shelfnew_enterprise_wx_auto = {
  widgetTypeV2: 'enterprise_wx_auto',
  widgetType: 'button',
  type: '21',
  name: '商品添加商家微信',
  btnTitle: '',
  subType: '15',
  fontSize: '26',
  fontColor: 'clear',
  btnType: '0',
  borderSize: '0',
  btnHeight: '228',
  btnBgColorTheme: 'clear',
  btnBorderColorTheme: 'clear',
  btnFontType: '0',
  btnStyle: '1',
  paddingTop: '0',
  paddingBottom: '0',
  paddingRight: '0',
  paddingLeft: '0',
  cornerRadius: 4,
  useIcon: '0',
  mpJumpType: '1',
  origBtnJumpUrl: '',
  btnJumpUrl: '',
  corpid: '',
  groupid: 0,
  h5_config_id: '',
  wxapp_config_id: '',
  qrUrl: '',
  needUpdateQrUrl: 0,
  qrExtInfo: '',
  serviceType: '1',
  chatGroupName: '1028测试3',
  id: 'widget_1636623372008_10',
  layoutWidth: '750',
  layoutHeight: '228'
}
