import store from '@/store'

/**
 * @description 全局函数，table列权限无法使用v-has指令显示隐藏，只能通过v-if条件判断显示隐藏
 * @param value
 * @returns {boolean}
 */
export function hasPerm(value) {
  const permission = store.state.user.permission
  if (value) {
    if (value instanceof Array) {
      const check = value.every(v => !permission.includes(v))
      return !check
    } else {
      return permission.includes(value)
    }
  } else {
    return true
  }
}
