var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "section" },
    [
      _c(
        "el-row",
        {
          staticStyle: { height: "100%" },
          attrs: { type: "flex", justify: "space-between", gutter: 10 },
        },
        [
          _c("el-col", { staticClass: "tree", attrs: { span: 18 } }, [
            _c("div", { staticClass: "left-ul" }, [
              _c("p", [_vm._v("已选" + _vm._s(`${_vm.leftTitle}模板`))]),
              _c("ul", [
                _vm._leftQueryList.length
                  ? _c("div", [
                      _vm.titleInfo.leftLevel === "2"
                        ? _c(
                            "div",
                            [
                              _c("el-tree", {
                                attrs: {
                                  "render-content": _vm.renderContent,
                                  data: _vm.leftQueryList,
                                  "default-expand-all": "",
                                  props: _vm.defaultProps,
                                },
                                on: { "node-click": _vm.setLeftQuerySelect },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            _vm._l(_vm._leftQueryList, function (item) {
                              return _c(
                                "li",
                                {
                                  key: item._id,
                                  class: {
                                    active:
                                      _vm.leftQuerySelect._id === item._id,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setLeftQuerySelect(item)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(item._name))]
                              )
                            }),
                            0
                          ),
                    ])
                  : _c(
                      "div",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "margin-top": "40px",
                        },
                      },
                      [_c("span", [_vm._v("未选择")])]
                    ),
              ]),
            ]),
            _c("div", { staticClass: "tree-select" }, [
              _c(
                "div",
                { staticClass: "tree-top" },
                [
                  _c("p", [_vm._v(_vm._s(`已有${_vm.dialogTitle}`))]),
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: { placeholder: "输入关键字进行过滤", size: "small" },
                    model: {
                      value: _vm.filterText,
                      callback: function ($$v) {
                        _vm.filterText = $$v
                      },
                      expression: "filterText",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.treeLoading,
                      expression: "treeLoading",
                    },
                  ],
                  staticClass: "filter-tree-box",
                },
                [
                  _c("el-tree", {
                    ref: "tree",
                    staticClass: "filter-tree",
                    attrs: {
                      "current-node-key": 7,
                      "node-key": "id",
                      "show-checkbox": "",
                      data: _vm.data,
                      props: { label: "label" },
                      "default-expand-all": "",
                      "filter-node-method": _vm.filterNode,
                    },
                    on: { "check-change": _vm.checkChange },
                  }),
                  _c("el-pagination", {
                    staticStyle: { "text-align": "right" },
                    attrs: { layout: "prev, pager, next", total: _vm.total },
                    on: { "current-change": _vm.currentChange },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("el-col", { staticClass: "selected-tree", attrs: { span: 6 } }, [
            _c("p", [_vm._v("已选" + _vm._s(_vm.dialogTitle))]),
            _c(
              "ul",
              _vm._l(_vm._selectedData, function (item) {
                return _c(
                  "li",
                  { key: item.id, staticClass: "selected-tree-li" },
                  [
                    _c("p", [_vm._v(_vm._s(item.label))]),
                    _c("i", {
                      staticClass: "el-icon-circle-close",
                      on: {
                        click: function ($event) {
                          return _vm.deleteItem(item.id)
                        },
                      },
                    }),
                  ]
                )
              }),
              0
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }