var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "box",
    [
      _c("template", { slot: "title" }, [_vm._v("品牌信息")]),
      _c(
        "el-form-item",
        { attrs: { label: "品牌名称", prop: "brand" } },
        [
          _c("el-input", {
            staticStyle: { width: "600px" },
            attrs: { maxlength: "11", "show-word-limit": "" },
            model: {
              value: _vm.sizeForm.brand,
              callback: function ($$v) {
                _vm.$set(_vm.sizeForm, "brand", $$v)
              },
              expression: "sizeForm.brand",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "品牌头像", prop: "userPortrait" } },
        [
          _c(
            "div",
            {
              staticClass: "avatar-uploader",
              on: { click: _vm.setMaterialBox },
            },
            [
              _vm.sizeForm.userPortrait
                ? _c("img", {
                    staticClass: "avatar",
                    attrs: { src: _vm.sizeForm.userPortrait },
                  })
                : _c("i", { staticClass: "el-icon-plus avatar-uploader-icon" }),
            ]
          ),
          _c(
            "p",
            { staticClass: "tip", staticStyle: { "margin-top": "10px" } },
            [
              _vm._v(
                " 尺寸：120px * 120px, 格式：JPG、JPEG、PNG格式, 大小：1024kb以内 "
              ),
            ]
          ),
        ]
      ),
      _c("material-box", {
        attrs: {
          visible: _vm.materialBox,
          "img-count": 1,
          name: "userPortrait",
          "image-list": [
            {
              image: _vm.sizeForm.userPortrait,
              imageId: _vm.sizeForm.userPortraitId,
            },
          ],
          attribute: {
            key: "userPortrait",
            value: "头像",
            count: 1,
            size: 1204 * 1204,
            listType: "image/jpg,image/jpeg,image/png",
            width: 120,
            height: 120,
          },
          "project-id": _vm.projectId,
        },
        on: { setMaterialBox: _vm.setMaterialBox, submit: _vm.selectImg },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }