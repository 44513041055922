<!--
 * @Description: 选择推广单元等框
 * @Date: 2021-11-09 09:19:23
 * @LastEditTime: 2022-03-17 14:17:30
-->

<template>
  <div class="dialog">
    <el-dialog :title="`选择${dialogTitle}`" :visible="visible" center width="70%" :modal-append-to-body="false" destroy-on-close @close="onClose">
      <span>
        <SelectModalTable v-if="type === 'table'" :visible="visible" :data-source="tableDataSource" :select-page-list="selectPageList" :title-info="titleInfo" :left-title="leftTitle" :dialog-title="dialogTitle" :data="data" :left-query-list="leftQueryList" @getSelectedData="getSelectedData" />
        <SelectModalTree v-if="type === 'tree'" ref="modalTree" :visible="visible" :query-fun="queryFun" :title-info="titleInfo" :left-title="leftTitle" :dialog-title="dialogTitle" :data="data" :left-query-list="leftQueryList" @getSelectedData="getSelectedData" />
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onClose">取 消</el-button>
        <el-button type="primary" @click="onOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import SelectModalTree from './selectModalTree.vue'
import SelectModalTable from './selectModalTable.vue'
export default {
  inject: ['close', 'setSelectPageList'],
  components: {
    SelectModalTree,
    SelectModalTable
  },
  props: {
    type: {
      type: String,
      default: 'tree'
    },
    dialogTitle: {
      type: String,
      default: ''
    },
    leftTitle: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    },
    titleInfo: {
      type: Object,
      default: () => {}
    },
    // 筛选数据
    data: {
      type: Array,
      default: () => []
    },
    // 左侧用来筛选数据
    leftQueryList: {
      type: Array,
      default: () => []
    },
    tableDataSource: {
      type: Object,
      default: () => {}
    },
    // 选中的项
    /* eslint-disable */
    selectPageList: {
      type: Array,
      require: false
    },
    /* eslint-disable */
    queryFun: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selected: this.titleInfo,
      templateData: [] // 选中的数据
    }
  },
  watch: {
    visible(value) {
      if (value) {
        this.templateData = []
      }
    }
  },
  methods: {
    /**
     * @description 获取最后选择的计划数据
     * @param {data} 模板数据
     */
    getSelectedData(data) {
      this.templateData = data
    },

    /**
     * @description 关闭弹框
     */
    onClose() {
      this.setSelectPageList([])
      this.close()
      // this.$refs.modalTree.resetData()
    },

    /**
     * @description 弹框 => 点击确定
     */
    onOk() {
      this.$emit('onOk', this.templateData)
      // this.onClose()
    }
  }
}
</script>

<style lang="scss">
.dialog {
  .el-tabs .el-tabs__item.is-active {
    border-top: solid 0px #409eff;
  }
}
</style>

<style lang="scss" scoped>
p {
  margin: 0;
  padding: 0;
}
</style>
