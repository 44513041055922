var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c("el-card", { attrs: { shadow: "hover" } }, [
        _c(
          "div",
          { staticClass: "user" },
          [
            _c("el-avatar", {
              attrs: { icon: "el-icon-user-solid", src: _vm.userInfo.avatar },
            }),
            _c("span", [_vm._v("欢迎," + _vm._s(_vm.userInfo.username) + ".")]),
            _c("router-link", { attrs: { to: "/hugeAmountBatch" } }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }