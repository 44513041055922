var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "table-template",
      attrs: {
        title: "短剧回传",
        visible: _vm.visible,
        "append-to-body": true,
        width: "900px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        close: function ($event) {
          _vm.visible = false
        },
      },
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-radio",
            {
              attrs: { label: "1" },
              model: {
                value: _vm.payModel,
                callback: function ($$v) {
                  _vm.payModel = $$v
                },
                expression: "payModel",
              },
            },
            [_vm._v("有效获客")]
          ),
          _c(
            "el-radio",
            {
              attrs: { label: "2" },
              model: {
                value: _vm.payModel,
                callback: function ($$v) {
                  _vm.payModel = $$v
                },
                expression: "payModel",
              },
            },
            [_vm._v("付费1")]
          ),
          _c(
            "el-radio",
            {
              attrs: { label: "3" },
              model: {
                value: _vm.payModel,
                callback: function ($$v) {
                  _vm.payModel = $$v
                },
                expression: "payModel",
              },
            },
            [_vm._v("付费2")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              staticClass: "btn",
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { type: "primary" },
              on: { click: _vm.submit },
            },
            [_vm._v("回 传")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }