var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "base" }, [
    _c(
      "header",
      [
        _c("p", [
          _vm._v(" " + _vm._s(_vm.title)),
          _c(
            "span",
            { staticClass: "tip ml-20", staticStyle: { color: "#ee4343" } },
            [_vm._v(_vm._s(_vm.desc))]
          ),
        ]),
        _vm._t("topRight"),
      ],
      2
    ),
    _c("section", [_vm._t("default")], 2),
    _c("div", { staticClass: "footer" }, [_vm._t("footer")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }