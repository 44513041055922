var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("el-tree", {
    ref: "tree",
    attrs: {
      data: _vm.data,
      "show-checkbox": "",
      "node-key": "value",
      "default-expand-all": "",
      props: _vm.defaultProps,
    },
    on: { check: _vm.checkChange },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }