var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "list-box" },
    _vm._l(_vm.dataProps, function (item) {
      return _c("li", { key: item.key }, [
        item.value
          ? _c("p", { staticClass: "key" }, [_vm._v(_vm._s(item.key) + "：")])
          : _vm._e(),
        item.value
          ? _c("p", { staticClass: "value" }, [_vm._v(_vm._s(item.value))])
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }