var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "custom-landingpage-form",
          attrs: {
            inline: true,
            model: _vm.form,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称", prop: "toolPageName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: "请输入落地页名称",
                          maxlength: "30",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.form.toolPageName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "toolPageName", $$v)
                          },
                          expression: "form.toolPageName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型", prop: "toolPageType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择类型" },
                          on: { change: _vm.handleChangePageType },
                          model: {
                            value: _vm.form.toolPageType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "toolPageType", $$v)
                            },
                            expression: "form.toolPageType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "跳转页", value: 1 },
                          }),
                          _c("el-option", {
                            attrs: { label: "召回页", value: 2 },
                          }),
                          _c("el-option", {
                            attrs: { label: "循环页", value: 3 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.isRecallOrLoop
                ? [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "顶部名称",
                              prop: "toolPageTopName",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "请输入落地页顶部名称",
                                maxlength: "10",
                                "show-word-limit": "",
                              },
                              model: {
                                value: _vm.form.toolPageTopName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "toolPageTopName", $$v)
                                },
                                expression: "form.toolPageTopName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "背景色", prop: "colors" } },
                          [
                            _c(
                              "el-dropdown",
                              { staticStyle: { width: "28px" } },
                              [
                                _c("div", {
                                  staticClass: "ldy-bg-color",
                                  style: `background:${_vm.form.colors}`,
                                }),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  [
                                    _c("twitter-color", {
                                      staticStyle: { "margin-top": "10px" },
                                      attrs: { colors: _vm.form.colors },
                                      on: {
                                        change: (value) =>
                                          (_vm.form.colors = value),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _vm.isRecallOrLoop
            ? _c(
                "el-row",
                { staticClass: "comp-box", attrs: { gutter: 60 } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "min-width": "450px" },
                      attrs: { span: 7 },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "grid-content bg-purple" },
                        [
                          _c("WidgetGallery", {
                            attrs: {
                              "seleted-widgets-index": _vm.selectedWidgetsIndex,
                            },
                            on: { setFinalWidgets: _vm.setFinalWidgets },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { width: "415px", "margin-right": "60px" } },
                    [
                      _c(
                        "div",
                        {
                          ref: "benchOptContainer",
                          staticClass: "grid-content bg-purple workbench",
                          style: `background:${_vm.form.colors}`,
                        },
                        [
                          _c("Workbench", {
                            attrs: {
                              "final-widgets": _vm.finalWidgets,
                              "seleted-widgets-index": _vm.selectedWidgetsIndex,
                            },
                            on: {
                              updateFinalWidgets: _vm.updateFinalWidgets,
                              setFinalWidgetsCount: _vm.setFinalWidgetsCount,
                              setSelectedIndex: _vm.setSelectedIndex,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "min-width": "450px" },
                      attrs: { xl: 10, lg: 8 },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "grid-content bg-purple" },
                        [
                          _c("AdEditors", {
                            ref: "adEditors",
                            attrs: {
                              "final-widgets": _vm.finalWidgets,
                              "seleted-widgets-index": _vm.selectedWidgetsIndex,
                              platforms: _vm.platforms,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _vm.isRecallOrLoop
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "默认跳转", prop: "isSkip" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "请选择默认跳转",
                                disabled: true,
                              },
                              model: {
                                value: _vm.form.isSkip,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "isSkip", $$v)
                                },
                                expression: "form.isSkip",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "开启", value: true },
                              }),
                              _c("el-option", {
                                attrs: { label: "不开启", value: false },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "平台",
                        prop: "platform",
                        rules: {
                          required: _vm.isRecallOrLoop ? false : true,
                          message: "请选择平台",
                          trigger: "change",
                        },
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择平台", filterable: "" },
                          model: {
                            value: _vm.form.platform,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "platform", $$v)
                            },
                            expression: "form.platform",
                          },
                        },
                        _vm._l(_vm.platforms, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: item.dictLabel,
                              value: item.dictValue,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "关联内容",
                        prop: "relatedContent",
                        rules: {
                          required: _vm.isRecallOrLoop ? false : true,
                          message: "请输入关联内容",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          placeholder: "请输入关联内容",
                          maxlength: "30",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.form.relatedContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "relatedContent", $$v)
                          },
                          expression: "form.relatedContent",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.isRecallOrLoop || (_vm.isRecallOrLoop && !!_vm.form.isSkip)
            ? [
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "链接类型", prop: "linkType" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { placeholder: "请选择链接类型" },
                                model: {
                                  value: _vm.form.linkType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "linkType", $$v)
                                  },
                                  expression: "form.linkType",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "普通链接", value: 1 },
                                }),
                                _c("el-option", {
                                  attrs: { label: "获客助手链接", value: 2 },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 10 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "跳转链接", prop: "skipLink" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                type: "textarea",
                                rows: 3,
                                resize: "none",
                                placeholder: "请输入跳转链接",
                                maxlength: "500",
                                "show-word-limit": "",
                              },
                              model: {
                                value: _vm.form.skipLink,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "skipLink", $$v)
                                },
                                expression: "form.skipLink",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "div",
            { staticClass: "form-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.loading },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v("保存")]
              ),
              _c("el-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }