
<template>
  <div>
    <Search ref="search" />
    <div class="justify-sb mb-15">
      <el-button type="primary" icon="el-icon-plus" round @click="upAsset()"> 立即上传 </el-button>
    </div>
    <Add ref="add" :img-list="imgList" :video-list="videoList" @seleAsset="seleAsset0" @delImage="delImage" @delVideo="delVideo" />
    <selectImg ref="siv" @fixCheck="fixCheck" />
    <SelectVideo ref="sivideo" @fixCheck="fixCheckVideo" />
    <!-- 表格 -->
    <TablePane :data-source="dataSource" @getPage="getPage">
      <template slot="operation" slot-scope="{ row }">
        <!--  -->
        <!--  -->
        <el-button v-if="row.status === 'submitted' && row.failNum>0" type="text" @click="refresData(row)">
          <el-tooltip content="重新提交" placement="top">
            <i class="el-icon-refresh-left" style="font-size: 16px"></i>
          </el-tooltip>
        </el-button>
      </template>
    </TablePane>
  </div>
</template>

<script>
import accountUinTencent from '@/mixin/accountUin_tencent'
import TablePane from '@/components/tablePane.vue' // authorize adMarketingApiClientsSelect
import { Page, refresData } from '@/network/api/assetUpload/asetUp.js'
const statusList = {
  'all': '全部',
  'submitting': '提交中',
  'submitted': '提交完成'
}
import selectImg from './selectImg.vue'
import SelectVideo from './selectVideo.vue'
import Search from './search'
import Add from './add'
export default {
  components: {
    Search, TablePane, Add, selectImg, SelectVideo
  },
  provide: function() {
    return {
      loadData: this.loadData
    }
  },
  mixins: [accountUinTencent],
  data() {
    return {
      imgList: [],
      videoList: [],
      selectRow: null,
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      dataSource: {
        data: [],
        cols: [
          {
            label: '上传人',
            prop: 'createBy'
          },
          {
            label: '上传时间',
            prop: 'createTime'
          },
          {
            label: '账户明细',
            noTip: true,
            render: (record) => (
              /* eslint-disable */
              <el-tooltip class="item" effect="dark" placement="top">
                <template slot="content">
                  <p style="max-width: 200px; padding: 0; margin: 0">{record.accountList}</p>
                </template>
                <p class="text-ellipsis">{record.accountInfo}</p>
              </el-tooltip>
              /* eslint-disable */
            )
          },
          {
            label: '素材数',
            prop: 'assetInfo'
          },
          {
            label: '状态',
            width: 160,
            render: (record) => {
              return (
                /*eslint-disable*/
                <div class="status">
                  <p style="font-size: 11px">{statusList[record.status]}</p>
                  <div class="status-num">
                    <p style="font-size: 11px">
                      成功：<span style="color:green">{record.successNum}</span>
                    </p>
                    <p style="font-size: 11px">
                      失败：<span style="color:red">{record.failNum}</span>
                    </p>
                  </div>
                </div>
                /*eslint-disable*/
              )
            }
          }
        ], // 表格的列数据
        isIndex: true, // 列表序号
        loading: false, // loading
        isOperation: true,
        isSelection: true,
        pageData: {
          total: 0 // 总条数
        },
        handleSelectionChange: (selection) => {
          this.selectRow = selection
        },
        operation: {
          // 表格有操作列时设置
          label: '操作', // 列名
          width: 100, // 根据实际情况给宽度
          fixed: 'right',
          data: [
            // {
            //   icon: 'el-icon-refresh-left',
            //   tooltip: '重新提交',
            //   handleRow: (index, re) => {

            //   }
            // }
            // {
            //   icon: 'el-icon-date',
            //   tooltip: '详情',
            //   handleRow: (index, re) => {

            //   }
            // }
          ]
        }
      }
    }
  },

  mounted() {
    this.getPage()
  },
  methods: {
    refresData(row){
      refresData(row.assetBatchUpId).then((res)=>{
        if(res.code===200){
          this.$message.success("操作成功")
           this.getPage()
        }
      })
    },
    //删除视频
    delVideo(video){
      var index0 =this.videoList.indexOf(video)
    if(index0!==-1){
      this.videoList.splice(index0,1);
    }
    },
    //删除图片
    delImage(image){
    var index0 =this.imgList.indexOf(image)
    if(index0!==-1){
      this.imgList.splice(index0,1);
    }
    },
    fixCheckVideo(video){
  //遍历选中图片
      video.forEach(element => {
        //已有中是否存在
      var seleList=  this.videoList.filter((f)=>{
          return f.videoId === element.videoId
        })
        if(seleList.length===0){
          this.videoList.push(element)
        }
      });
    },
    //确定选中
    fixCheck(image){
      //遍历选中图片
      image.forEach(element => {
        //已有中是否存在
      var seleList=  this.imgList.filter((f)=>{
          return f.imageId === element.imageId
        })
        if(seleList.length===0){
          this.imgList.push(element)
        }
      });
     
    },
    //选择图片
    seleAsset0(val){
      if(val=="img"){
      this.$refs.siv.selectAsset(val)
      }
      if(val=="video"){
      this.$refs.sivideo.selectAsset(val)
      }
    },

    //立即上传
    upAsset(){
      this.imgList=[]
      this.videoList=[]
      this.$refs.add.eventShow(true)
    },
    //查询
     loadData(isFirst = false) {
      const searchForm = this.$refs.search.getSearchForm()
      this.doLoadData(isFirst, searchForm)
    },
    doLoadData(firstPage = false, searchForm) {
      firstPage && (this.pageInfo.pageNum = 1)
      const req = { ...searchForm,  ...this.pageInfo }
      this.dataSource.loading = true
      Page(req).then(({ code, data })  => {
          if (code === 200) {
          const { records, total } = data
          // records
          this.dataSource.data = records
          this.dataSource.pageData.total = total
          this.dataSource.loading = false
        }
      })
    },
    /**
     * @description: 获取列表
     * @param {*} params
     */
    getPage(params = { pageNum: 1, pageSize: 10 }) {
      this.dataSource.loading = true
      Page({ ...params }).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          // records
          this.dataSource.data = records
          this.dataSource.pageData.total = total
          this.dataSource.loading = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.status {
  padding-right: 20px;
  p {
    padding: 0;
    margin: 0;
  }
  .status-num {
    display: flex;
    justify-content: space-between;
    p {
      font-size: 13px;
      color: rgb(153, 153, 153);
    }
  }
}
.red {
  color: #ff0000;
}
</style>
