var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "element-library-box",
      attrs: {
        title: "新增元素",
        visible: _vm.show,
        "append-to-body": true,
        width: "600px",
        top: "20vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.onCloseDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                inline: false,
                "label-width": "100px",
                model: _vm.form,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "元素名称", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      placeholder: "请输入元素名称",
                      maxlength: "15",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "元素类型", prop: "typeId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择元素类型" },
                      on: { change: _vm.handleSelectType },
                      model: {
                        value: _vm.form.typeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "typeId", $$v)
                        },
                        expression: "form.typeId",
                      },
                    },
                    _vm._l(_vm.elementTypes, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              !_vm.form.id
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "基础类型", prop: "baseType" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请选择基础类型" },
                            model: {
                              value: _vm.form.baseType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "baseType", $$v)
                              },
                              expression: "form.baseType",
                            },
                          },
                          _vm._l(_vm.baseTypes, function (item) {
                            return _c("el-option", {
                              key: item.key,
                              attrs: { label: item.value, value: item.key },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.form.baseType === "IMG"
                      ? _c(
                          "el-form-item",
                          [
                            _c("template", { slot: "label" }, [
                              _c("div", [
                                _c(
                                  "span",
                                  { staticStyle: { color: "#F56C6C" } },
                                  [_vm._v("*")]
                                ),
                                _vm._v(
                                  " 图片(" +
                                    _vm._s(_vm.images.length) +
                                    "/" +
                                    _vm._s(_vm.FILE_NUM) +
                                    ")"
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "form-image-list" },
                              [
                                _vm._l(_vm.images, function (item, index) {
                                  return _c("div", { key: index }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-image-list-cell" },
                                      [
                                        _c("img", {
                                          attrs: { src: item.url, alt: "" },
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-image-list-cell-del",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleDeleteImage(
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("删除")]
                                        ),
                                      ]
                                    ),
                                  ])
                                }),
                                _c(
                                  "el-upload",
                                  {
                                    staticClass: "form-image-upload",
                                    attrs: {
                                      accept: ".png,.jpg,.jpeg,.gif",
                                      action: "fakeaction",
                                      "list-type": "picture-card",
                                      multiple: true,
                                      "show-file-list": false,
                                      "http-request": _vm.uploadImage,
                                    },
                                  },
                                  [_c("i", { staticClass: "el-icon-plus" })]
                                ),
                              ],
                              2
                            ),
                            _c("div", { staticClass: "tips" }, [
                              _vm._v("格式：png、jpg、jpeg、gif"),
                            ]),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm.form.baseType === "VIDEO"
                      ? _c(
                          "el-form-item",
                          [
                            _c("template", { slot: "label" }, [
                              _c("div", [
                                _c(
                                  "span",
                                  { staticStyle: { color: "#F56C6C" } },
                                  [_vm._v("*")]
                                ),
                                _vm._v(
                                  " 视频(" +
                                    _vm._s(_vm.videos.length) +
                                    "/" +
                                    _vm._s(_vm.FILE_NUM) +
                                    ")"
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "form-image-list" },
                              [
                                _vm._l(_vm.videos, function (item, index) {
                                  return _c("div", { key: index }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-image-list-cell" },
                                      [
                                        _c("video", {
                                          attrs: { src: item.url },
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-image-list-cell-del",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleDeleteVideo(
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("删除")]
                                        ),
                                      ]
                                    ),
                                  ])
                                }),
                                _c("el-progress", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.getIsShow(),
                                      expression: "getIsShow()",
                                    },
                                  ],
                                  staticClass: "justify-center1",
                                  staticStyle: { width: "150px" },
                                  attrs: {
                                    percentage: _vm.schedule,
                                    status:
                                      _vm.schedule == 100
                                        ? "success"
                                        : "warning",
                                  },
                                }),
                                _vm.videos.length < _vm.FILE_NUM
                                  ? _c(
                                      "el-upload",
                                      {
                                        staticClass: "form-image-upload",
                                        attrs: {
                                          id: "uploa-video",
                                          disabled: _vm.getIsShow(),
                                          action: "fakeaction",
                                          accept: ".mp4,.avi,.mov",
                                          "list-type": "picture-card",
                                          "before-upload":
                                            _vm.beforeUploadVideo,
                                          multiple: false,
                                          "show-file-list": false,
                                          "http-request": _vm.uploadVideo,
                                        },
                                      },
                                      [_c("i", { staticClass: "el-icon-plus" })]
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _c("div", { staticClass: "tips" }, [
                              _vm._v("格式：mp4、avi、mov"),
                            ]),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "btn", on: { click: _vm.closeModal } },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { type: "primary", disabled: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }