<template>
  <div>
    <el-dialog title="企微客服组" :visible="wecomModalVisible" width="900px" :modal-append-to-body="false" destroy-on-close @close="resetForm()">
      <el-form :model="dataFrom">
        <el-row :gutter="10">
          <el-col :span="8">账户ID</el-col>
          <el-col :span="8">绑定企微ID</el-col>
          <el-col :span="8">客服组ID</el-col>
        </el-row>
        <el-row v-for="item in dataFrom.wecomData" :key="item.localAccountId" :gutter="10" style="margin-top: 10px">
          <el-col :span="8">
            <el-form-item>
              {{ item.name }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :rules="{ required: true, message: '请选择企微ID', trigger: 'change' }">
              <el-select v-model="item.tACorpId" clearable filterable remote :remote-method="(query) => remoteMethod(query, item.localAccountId)" :loading="loading">
                <el-option v-for="it in corpIdOption" :key="it.wxCorpid" :label="it.name + '(' + it.wxCorpid + ')'" :value="it.wxCorpid + ',' + it.name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :rules="{ required: true, message: '请选择客服组ID', trigger: 'change' }">
              <el-select v-model="item.tAGroupId" clearable>
                <el-option v-for="it in wxGroupIOptionMap[item.localAccountId]" :key="it.id" :value="it.groupId" :label="it.groupName + '(' + it.groupId + ')'"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="saveWecomData">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getCorpId, getWxGroupId, taskAddQW } from '@/network/api/advManagement/advManagementTencent'
// import { debounce } from '@/utils/ruoyi'

export default {
  inject: ['close'],
  // mixins: [accountUinTencent],
  props: {
    wecomModalVisible: {
      type: Boolean,
      default: false
    },
    wecomData: {
      type: Array,
      default: () => []
    },
    taskId: {
      type: Number,
      default: 0
    },
    accountData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dataFrom: {
        wecomData: []
      },
      corpIdOption: [],
      wxGroupIOption: [],
      loading: false,
      corpIdOptionMap: {},
      wxGroupIOptionMap: {}
    }
  },

  // computed: {
  //   corpIdOptionMap() {
  //     return this.corpIdOption
  //   }
  // },
  watch: {
    accountData(val) {
      this.dataFrom.wecomData = []
      if (val && val.length) {
        val.forEach((item) => {
          this.changeCorpId(item.localAccountId)
          const wecom = this.wecomData.filter((f) => f.talocalAccountId === item.localAccountId)[0]
          if (wecom && wecom.tacorpName) {
            // setTimeout(() => {
            //   // this.getCorpIdList(wecom.tacorpName)
            //   // this.changeCorpId(wecom.talocalAccountId)
            // }, 500)
            this.getCorpIdList(wecom?.tacorpName)
          }
          // this.getCorpIdList(wecom?.tacorpName)
          // this.changeCorpId(item.localAccountId)
          // console.log(this.corpIdOption, this.wxGroupIOption, 'cccccc')
          setTimeout(() => {
            const corpIdItem = this.corpIdOption[0]
            this.dataFrom.wecomData.push({
              tAGroupId: wecom?.tagroupId || null,
              tACorpId: corpIdItem ? corpIdItem?.wxCorpid + ',' + corpIdItem?.name : null,
              tAIsUseWxSpec: true,
              taskAccountId: item._id,
              name: item._name,
              localAccountId: item.localAccountId
            })
          }, 500)
        })
      }
    }
  },

  methods: {
    resetForm() {
      this.close()
    },

    remoteMethod(query, localAccountId) {
      if (query !== '' && query !== null) {
        this.loading = true
        this.getCorpIdList(query, localAccountId)
      } else {
        this.corpIdOption = []
      }
      this.loading = false
    },

    getCorpIdList(query, localAccountId) {
      this.loading = true
      getCorpId(query).then(({ code, data }) => {
        if (code === 200) {
          this.corpIdOption = data
          this.corpIdOptionMap[localAccountId] = data
        }
      })
      this.loading = false
      this.$forceUpdate()
      // console.log(this.corpIdOption, this.corpIdOptionMap, 'oooooo')
    },

    // getCorpIdList: debounce(function (query) {
    //   // this.corpIdOption[localAccountId] = []
    //   // if (!this.corpIdOptionMap.value) {
    //   //   this.corpIdOptionMap.value = {}
    //   // }
    //   this.loading = true
    //   getCorpId(query).then(({ code, data }) => {
    //     if (code === 200) {
    //       this.corpIdOption = data
    //       // this.corpIdOptionMap.value[localAccountId] = data
    //     }
    //   })
    //   this.loading = false
    //   this.$forceUpdate()
    //   console.log(this.corpIdOption, 'oooooo')
    // }),

    changeCorpId(localAccountId) {
      this.wxGroupIOption = []
      // console.log(val, localAccountId, 'cccc')
      getWxGroupId({ localAccountId, wxCorpid: null, pageNum: 1, pageSize: 200 }).then(({ code, data }) => {
        if (code === 200) {
          this.wxGroupIOption = data.list
          this.wxGroupIOptionMap[localAccountId] = data.list
        }
      })
    },

    saveWecomData() {
      // console.log(this.dataFrom, 'ssss')
      let isCorp = true
      let isGroup = true
      this.dataFrom.wecomData.forEach((item) => {
        if (!item.tACorpId) {
          isCorp = false
          return
        }
        if (!item.tAGroupId) {
          isGroup = false
          return
        }
      })
      if (!isCorp) {
        return this.$message.error('请选择企微ID!')
      }
      if (!isGroup) {
        return this.$message.error('请选择客服组ID!')
      }
      this.$emit('clearTaskGenerate')
      const lists = this.dataFrom.wecomData.map((item) => {
        const corp = item.tACorpId.split(',')
        item.tACorpId = corp[0]
        item.tACorpName = corp[1]
        return item
      })
      taskAddQW({ lists }).then(({ code }) => {
        if (code === 200) {
          this.$emit('byTaskIdGetAllFun')
          this.$message.success('企微保存成功!')
          this.close()
        }
      })
    }
  }
}
</script>
