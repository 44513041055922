<template>
  <div class="clearfix">
    <el-pagination
      class="pagination flr"
      :layout="tools"
      :current-page.sync="no"
      :hide-on-single-page="hideOnSinglePage"
      :page-size.sync="size"
      :page-sizes="sizes"
      :total="total"
      :pager-count="pagerCount"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    // 当前页码
    pageNo: {
      type: Number,
      required: true
    },
    // 每页显示多少条
    pageSize: {
      type: Number,
      required: true
    },
    // 总数
    total: {
      type: Number,
      required: true
    },
    getDataFun: {
      type: Function,
      required: true
    },
    sizes: {
      type: Array,
      default: () => [10, 20, 30, 50, 100]
    },
    tools: {
      type: String,
      default: 'total, sizes, prev, pager, next'
    },
    // 只有一页数据时隐藏分页
    hideOnSinglePage: {
      type: Boolean,
      default: false
    },
    pagerCount: {
      type: Number,
      required: false,
      default: 7
    }
  },
  data () {
    return {
      size: this.pageSize,
      no: this.pageNo
    }
  },
  watch: {
    no (val) {
      this.$emit('update:pageNo', val)
      this.getDataFun()
    },

    size (val) {
      this.$emit('update:pageSize', val)
      this.pageNo === 1 ? this.getDataFun() : this.$emit('update:pageNo', 1)
    },

    pageNo (val) {
      this.no = val
    },

    pageSize (val) {
      this.size = val
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/style/variables.scss";

$pageHeight: 24px;
.pagination {
  margin: 30px 0px 30px 100px;

  &.el-pagination .btn-prev,
  &.el-pagination .btn-next {
    background-color: transparent;
    height: $pageHeight;
    line-height: $pageHeight;
  }

  &.el-pagination span:not([class*="suffix"]),
  &.el-pagination button {
    height: $pageHeight;
    line-height: $pageHeight;
  }

  .el-button.jump-btn {
    width: $pageHeight;
    height: $pageHeight;
    background-color: $themeColor;
    border-radius: 50%;
    text-align: center;
    line-height: $pageHeight;
    padding: 0;
    color: #fff;
    margin-left: 15px;

    &:hover {
      background-color: $themeColor;
    }
  }

  &.el-pagination__editor.el-input {
    width: 56px;
  }

  .el-pagination__editor.el-input .el-input__inner {
    height: 32px;
    border-radius: 16px;
    border: solid 1px #dcdfe6;
    background-color: #f8f9fb;
  }

  .el-pager li {
    min-width: $pageHeight;
    height: $pageHeight;
    margin-right: 13px;
    line-height: $pageHeight;
    font-size: 14px;
    color: #474747;
    font-weight: 500;
    transition: all 0.3s;
    border: solid 1px transparent;
    box-sizing: border-box;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      border: solid 1px $themeColor;
      color: $themeColor;
      box-sizing: border-box;
      background-color: #fbfafd;
    }

    &.active {
      border: solid 1px $themeColor;
      color: $themeColor;
      box-sizing: border-box;
      border-radius: 2px;
      background-color: #fbfafd;

      &:hover {
        border: solid 1px $themeColor;
        color: $themeColor;
      }
    }
  }

  .el-pagination__jump {
    margin-left: 10px;
  }

  &.el-pagination .el-pagination__sizes {
    margin: 0 5px;

    .el-select .el-input {
      width: 89px;

      .el-input__inner {
        height: 24px;
        border-radius: 2px;
        border: solid 1px #edecf2;
      }
    }
  }
}
</style>
