export const searchForm = {
  bsLabelTypeId: null,
  labelTypeName: null,
  relationProjectId: null,
  relationProjectName: null,
  deleted: null,
  createBy: null,
  createTime: null,
  updateBy: null,
  updateTime: null,
  remark: null
}

export class BasicSettingLabelTypeEnt {
  bsLabelTypeId
  labelTypeName
  relationProjectId
  relationProjectName
  deleted
  createBy
  createTime
  updateBy
  updateTime
  remark
}

export const formRules = {

  labelTypeName: [{ required: true, message: '请填写标签分类名称', trigger: 'change' }],
  relationProjectId: [{ required: true, message: '请填写关联项目id', trigger: 'change' }]

}
