var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item.children
    ? _c(
        "el-submenu",
        { attrs: { index: _vm.item.campaignFeedName } },
        [
          _c(
            "template",
            { slot: "title" },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: _vm.item.campaignFeedName,
                    placement: "top",
                  },
                },
                [
                  _c("div", { staticClass: "text-ellipsis" }, [
                    _vm._v(_vm._s(_vm.item.campaignFeedName)),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._l(_vm.item.children, function (child, i) {
            return _c("el-menu-item", { key: i, attrs: { index: child.id } }, [
              _c(
                "div",
                { staticClass: "menu-item-text" },
                [
                  _c("p", { staticClass: "text-ellipsis" }, [
                    _vm._v(_vm._s(child.lable)),
                  ]),
                  _vm._t("default", null, {
                    id: child.id,
                    campaignFeedName: _vm.item.campaignFeedName,
                  }),
                ],
                2
              ),
            ])
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }