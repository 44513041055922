/*
 * @Description: Do not edit
 * @Date: 2021-09-15 09:48:06
 * @LastEditTime: 2022-02-16 10:11:33
 */
import { getUserInfo, getMenuList } from '@/network/api/api-base'

export default {
  namespaced: true,
  state: {
    userInfo: {},
    permission: [],
    isCollapse: false,
    sideMenu: [],
    breadcrumbMenu: [],
    menu: []
  },
  mutations: {
    SET_USER_INFO: (state, info) => {
      state.userInfo = info
    },
    SET_USER_PERMISSION: (state, permission) => {
      const _permission = []
      permission.map((v) => {
        if (v.path) {
          _permission.push(v.path)
        }
      })
      state.permission = _permission
    },
    CLEAR(state) {
      state.userInfo = {}
      state.permission = []
      state.isCollapse = false
      state.menu = []
    },
    SET_MENU(state, menu) {
      state.menu = menu
    },
    SET_SIDE_MENU(state, sideMenu) {
      state.sideMenu = sideMenu
    },

    SET_BREADCRUMB_MENU(state, breadcrumbMenu) {
      state.breadcrumbMenu = breadcrumbMenu
    },

    TOGGLE_MENU(state, isCollapse) {
      state.isCollapse = isCollapse
    }
  },

  actions: {
    // 获取用户信息
    async GetUserInfo({ commit, rootMutations }) {
      try {
        const res = await getUserInfo()
        if (res.code === 200) {
          commit('SET_USER_INFO', {
            avatar: res.data.plugininfo['pic'] || '',
            username: res.data.username,
            userid: res.data.userid,
            roles: res.data.plugininfo['roles']
          })
          // resolve()
        }
      } catch (error) {
        // reject(error)
      }
    },
    // 获取用户菜单
    async GetUserMenu({ commit, rootMutations }) {
      try {
        const res = await getMenuList()
        if (res.code === 200) {
          commit('SET_MENU', res.data.menu || [])
          commit('SET_USER_PERMISSION', res.data.permission || [])
          // resolve()
        } else {
          commit('SET_MENU', [])
        }
      } catch (error) {
        // reject(error)
        console.log(error)
        // commit('SET_MENU', menus)
      }
    }
  }
}
