import service from '@/network/service/service'

export function Page(data) {
  return service.post('/api/system/basicSettingProduct/page', data)
}
export function updateBatch(data) {
  return service.post('/api/system/basicSettingProduct/updateBatch', data)
}

export function Add(data) {
  return service.post('/api/system/basicSettingProduct/save', data)
}

export function Update(data) {
  return service.post('/api/system/basicSettingProduct/update', data)
}

export function Delete(id) {
  return service.delete('/api/system/basicSettingProduct/' + id)
}

export function getProductByProject(id) {
  return service.get('/api/system/basicSettingProduct/projectId/' + id)
}
