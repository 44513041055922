<template>
  <el-dialog class="table-template" title="同步人群包" :visible.sync="show" :append-to-body="true" :show-close="false"
    :close-on-click-modal="false" width="620px" top="20vh" destroy-on-close @close="onCloseDialog">
    <div class="page-container">
      <template v-if="step === 1">
        <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="80px">
          <el-form-item label="投放账户" prop="accountId">
            <el-select v-model="form.accountId" style="width: 300px">
              <el-option v-for="item in accountList" :label="item.value" :value="item.key" :key="item.key"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template v-else>
        <el-steps direction="vertical" :active="isLoading ? 1 : 2">
          <el-step title="同步中">
            <div slot="title">同步中 <i v-if="isLoading" class="el-icon-loading"></i></div>
          </el-step>
          <el-step title="同步结束">
            <span slot="title">同步结束（共 {{ syncTotal }} 个）</span>
          </el-step>
        </el-steps>
      </template>
    </div>
    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button v-if="step === 1" class="btn" @click="closeModal">取 消</el-button>
      <el-button class="btn" type="primary" @click="clickConfirm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      form: {
        accountId: ''
      },
      rules: {
        accountId: [{ required: true, message: '请选择投放账户', trigger: 'change' }]
      },
      accountList: [],
      syncTotal: 0, // 同步总数
      isLoading: false,
      step: 1 // 1 选择账户 2同步
    }
  },
  computed: {},
  methods: {
    /* --------------------------- Actions --------------------------- */
    showModal(accountId) {
      this.show = true
      this.form.accountId = accountId || ''
      // this.tableData = [...keywords]
      // this.getSortTable()
    },
    // 点击确定按钮
    clickConfirm() {
      if (this.step === 1) {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.step++
          }
        })
        return
      }
      this.$emit('confirm')
      this.closeModal()
    },
    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      // 重置表单
      this.syncTotal = 0
      this.isLoading = false
      this.step = 1
      this.form.accountId = ''
      // this.$refs['form'].clearValidate()
    },
    // 关闭弹框
    closeModal() {
      this.show = false
    }
  }
}
</script>

<style scoped>
.page-container {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
