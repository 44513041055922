var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "search",
          staticClass: "search-form search-form-4",
          attrs: { inline: true, model: _vm.search },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品名称", prop: "toolProductName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请输入产品名称" },
                        model: {
                          value: _vm.search.toolProductName,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "toolProductName", $$v)
                          },
                          expression: "search.toolProductName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "平台", prop: "toolProductPlatform" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.search.toolProductPlatform,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "toolProductPlatform", $$v)
                            },
                            expression: "search.toolProductPlatform",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: 1 },
                          }),
                          _vm._l(_vm.platformList, function (item) {
                            return _c("el-option", {
                              key: item.dictValue,
                              attrs: {
                                label: item.dictLabel,
                                value: item.dictValue,
                              },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型", prop: "type" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.search.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "type", $$v)
                            },
                            expression: "search.type",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: 1 },
                          }),
                          _vm._l(_vm.typeList, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "收录时间", prop: "createTime" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "datetimerange",
                          "prange-separator": "至",
                          "start-placeholder": "开始时间",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "end-placeholder": "结束时间",
                        },
                        model: {
                          value: _vm.search.createTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "createTime", $$v)
                          },
                          expression: "search.createTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "测试状态",
                        prop: "toolProductTestStatus",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.search.toolProductTestStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "toolProductTestStatus", $$v)
                            },
                            expression: "search.toolProductTestStatus",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: 1 },
                          }),
                          _vm._l(_vm.testStatusList, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "测试结果", prop: "toolProductResult" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.search.toolProductResult,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "toolProductResult", $$v)
                            },
                            expression: "search.toolProductResult",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: 1 },
                          }),
                          _vm._l(_vm.toolProductResultList, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", round: "" },
                          on: { click: _vm.onSubmit },
                        },
                        [_vm._v("查 询")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", round: "" },
                          on: {
                            click: function ($event) {
                              return _vm.resetForm("ruleForm")
                            },
                          },
                        },
                        [_vm._v("重 置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.list.loading,
                  expression: "list.loading",
                },
              ],
              staticClass: "list-table",
              class: { "no-data": !_vm.list.data || !_vm.list.data.length },
              staticStyle: { width: "100%" },
              attrs: { data: _vm.list.data, stripe: "" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  width: "50",
                  align: "center",
                  fixed: "left",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "toolProductName",
                  label: "名称",
                  align: "center",
                  fixed: "left",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "toolProductSource",
                  label: "来源",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "平台", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.platformList.find(
                                (item) =>
                                  item.dictValue === row.toolProductPlatform
                              ).dictLabel
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "类型", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm._f("filterToolType")(row.type)) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "连载状态", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              row.serializeStatus === "1" ? "连载" : "完结"
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "num", label: "数量", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "收录时间",
                  width: "180",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "评价", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleProductEvaluation(
                                  row.toolProductId
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(row.toolProductCommentLists?.length || 0) +
                                "条"
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "收费卡点", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handlePayCardPoint(row.toolProductId)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.payCardPointListShow || "添加"))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "投放配置", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleLaunchConfiguration(
                                  row.toolProductId,
                                  row.toolProductFilterItemId
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.channelName || "添加"))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "指定广告位", align: "left", width: "200px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.toolProductLaunchPersonsTest.length
                          ? _c(
                              "el-tooltip",
                              { attrs: { placement: "top-start" } },
                              [
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  _vm._l(
                                    row.toolProductLaunchPersonsTest,
                                    function (asset, index) {
                                      return _c("div", { key: index }, [
                                        _vm._v(
                                          _vm._s(asset.userName || "-") +
                                            "：" +
                                            _vm._s(asset.adSpace || "-")
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                                _c(
                                  "div",
                                  _vm._l(
                                    row.toolProductLaunchPersonsTest.slice(
                                      0,
                                      3
                                    ),
                                    function (asset, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "tool-user-label",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(asset.userName || "-") +
                                              "：" +
                                              _vm._s(asset.adSpace || "-")
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            )
                          : _c("span", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "toolProductAdPlatform",
                  label: "广告平台",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "toolProductAdSpace",
                  label: "广告位",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "素材详情", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleMaterialDetails(
                                  row.toolProductId,
                                  row.toolProductFilterItemId
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.toolPeoductAssetInfos))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "指定完成时间", align: "left", width: "200px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.toolProductLaunchPersonsAsset.length
                          ? _c(
                              "el-tooltip",
                              { attrs: { placement: "top-start" } },
                              [
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  _vm._l(
                                    row.toolProductLaunchPersonsAsset,
                                    function (asset, index) {
                                      return _c("div", { key: index }, [
                                        _vm._v(
                                          _vm._s(asset.userName || "-") +
                                            "：" +
                                            _vm._s(asset.appointTime || "-")
                                        ),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                                _c(
                                  "div",
                                  _vm._l(
                                    row.toolProductLaunchPersonsAsset.slice(
                                      0,
                                      3
                                    ),
                                    function (asset, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "tool-user-label",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(asset.userName || "-") +
                                              "：" +
                                              _vm._s(asset.appointTime || "-")
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            )
                          : _c("span", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "测试时段", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.toolProductTestStatus === "2" ||
                        row.toolProductTestStatus === "3"
                          ? _c("span", [_vm._v("-")])
                          : _vm._e(),
                        row.toolProductTestStatus === "4"
                          ? _c("span", [
                              _vm._v(_vm._s(row.createTimeLaunch) + " ~ 暂无"),
                            ])
                          : _vm._e(),
                        row.toolProductTestStatus === "5"
                          ? _c("span", [
                              _vm._v(
                                _vm._s(row.createTimeLaunch) +
                                  " ~ " +
                                  _vm._s(row.endTime)
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "测试状态", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("filterTestStatus")(
                                row.toolProductTestStatus
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "结果", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.toolProductFilterStatus)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "",
                  label: "操作",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.toolProductTestStatus === "4" &&
                        row.toolProductFilterStatus !== "失败" &&
                        row.toolProductFilterStatus !== "成功"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEndTest(
                                      row.toolProductFilterItemId
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "结束测试",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-finished",
                                      staticStyle: { "font-size": "16px" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c("span", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "page" },
            [
              _c("paginations", {
                attrs: {
                  total: _vm.list.total,
                  page: _vm.list.pageNum,
                  "page-sizes": [5, 10, 15, 20],
                  limit: _vm.list.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.list, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.list, "pageSize", $event)
                  },
                  pagination: _vm.getPage,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("product-evaluation-dialog", {
        ref: "productEvaluation",
        on: { confirm: _vm.getPage },
      }),
      _c("pay-card-point-dialog", {
        ref: "payCardPoint",
        on: { confirm: _vm.getPage },
      }),
      _c("end-test-dialog", { ref: "endTest", on: { confirm: _vm.getPage } }),
      _c("launch-configuration-dialog", {
        ref: "launchConfiguration",
        on: { confirm: _vm.getPage },
      }),
      _c("material-details-dialog", {
        ref: "materialDetails",
        on: { confirm: _vm.getPage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }