<!--
 * @Description: Do not edit
 * @Date: 2021-09-20 12:51:36
 * @LastEditTime: 2022-03-02 09:50:14
-->
<template>
  <box>
    <template slot="title">品牌信息</template>
    <el-form-item label="品牌名称" prop="brand">
      <el-input v-model="sizeForm.brand" maxlength="11" show-word-limit style="width: 600px" />
    </el-form-item>

    <el-form-item label="品牌头像" prop="userPortrait">
      <!-- <el-upload class="avatar-uploader" :action="UPLOAD_URL" :show-file-list="false" :before-upload="beforeAvatarUpload" :on-success="handleAvatarSuccess">
        <img v-if="sizeForm.userPortrait" :src="sizeForm.userPortrait" class="avatar" />
        <i v-else class="el-icon-plus avatar-uploader-icon" />
      </el-upload> -->
      <div class="avatar-uploader" @click="setMaterialBox">
        <img v-if="sizeForm.userPortrait" :src="sizeForm.userPortrait" class="avatar" />
        <i v-else class="el-icon-plus avatar-uploader-icon" />
      </div>
      <p class="tip" style=" margin-top: 10px">
        尺寸：120px * 120px, 格式：JPG、JPEG、PNG格式, 大小：1024kb以内
      </p>
    </el-form-item>
    <material-box
      :visible="materialBox"
      :img-count="1"
      name="userPortrait"
      :image-list="[{ image: sizeForm.userPortrait, imageId: sizeForm.userPortraitId }]"
      :attribute="{
        key: 'userPortrait',
        value: '头像',
        count: 1,
        size: 1204 * 1204,
        listType: 'image/jpg,image/jpeg,image/png',
        width: 120,
        height: 120
      }"
      :project-id="projectId"
      @setMaterialBox="setMaterialBox"
      @submit="selectImg"
    />
  </box>
</template>

<script>
import Box from './box.vue'
import MaterialBox from '@/components/materialBox'
export default {
  components: {
    'material-box': MaterialBox,
    Box
  },
  props: {
    sizeForm: {
      type: Object,
      default: () => {
        return {
          brand: ''
        }
      }
    },
    projectId: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      UPLOAD_URL: process.env.VUE_APP_UPLOAD_URL,
      imageUrl: '',
      materialBox: false
    }
  },
  methods: {
    setMaterialBox() {
      if (!(this.projectId.length && this.projectId[0])) return this.$message.error('请先选择关联单元!')
      this.materialBox = !this.materialBox
    },

    // 选择图片成功
    selectImg(imgInfo) {
      this.sizeForm.userPortrait = imgInfo[0].image
      this.sizeForm.userPortraitId = imgInfo[0].imageId
    },
    handleAvatarSuccess(res, file) {
      const { data, code } = res
      const { url } = data.data[0]
      if (code === 200) {
        this.imageUrl = url
      }
      this.$emit('changeData', { userPortrait: url })
    },
    async beforeAvatarUpload(file) {
      const isType = ['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)
      const isLt1M = file.size / 1024 / 1024 < 1
      if (!isType) {
        this.$message.error('上传头像图片只能是 JPG、JPEG、PNG 格式!')
      }
      if (!isLt1M) {
        this.$message.error('上传头像图片大小不能超过 1024kb!')
      }
      const reader = new FileReader()
      reader.readAsDataURL(file)
      // 让页面中的img标签的src指向读取的路径
      await new Promise((resolve, reject) => {
        reader.onload = () => {
          const img = new Image()
          img.src = reader.result
          img.onload = () => {
            if (img.width !== 120 || img.height !== 120) {
              this.$message.error('上传头像图片尺寸应为120px * 120px!')
              reject(false)
              return false
            } else {
              resolve(true)
            }
          }
        }
      })
      return isType && isLt1M
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  /* border: 1px dashed #838383; */
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 128px;
  height: 128px;
  line-height: 128px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
