<!--
 * @Description: 创意基础信息
 * @Date: 2021-12-06 15:57:16
 * @LastEditTime: 2022-01-21 09:51:30
-->
<template>
  <div style="height:300px">
    <div class="creativityBaseInfo">
      <p>
        <span>创意展现: </span>
        <el-tag>{{ creativityData.creativeDisplayType === 'CREATIVE_DISPLAY_TYPE_INTELLIGENCE' ? '优选模式' : '轮播模式'
        }}</el-tag>
      </p>
      <p>
        <span>自动衍生视频创意: </span>
        <el-tag>{{ creativityData.autoDerivedCreativeEnabled ? '开启' : '关闭' }}</el-tag>
      </p>
      <p>
        <span>创意形式: </span>
        <span>
          <el-tag>{{ creativeType[creativityData.adcreativeTemplateId] }} </el-tag>
        </span>
      </p>
      <p>
        <span>按钮文案: </span>
        <span>
          <el-tag>{{ linkNameTypeMap[creativityData.linkNameType] || ' 无 ' }}</el-tag>
        </span>
      </p>
      <p>
        <span>落地页类型: </span>
        <el-tag>{{ creativityData.pageType === 'PAGE_TYPE_CANVAS_WECHAT' ? '原生落地页' : '阅宝落地页' }}</el-tag>
      </p>
      <p>
        <span>创意名称: </span>
        <el-tag>{{ creativityData.adcreativeName }}</el-tag>
      </p>
    </div>

    <el-dialog title="创意基础信息" :visible="creativityVisible" width="800px" :modal-append-to-body="false"
      @close="handleClose">
      <span>
        <el-form ref="ruleForm" :model="ruleForm" label-width="140px">
          <h3>创意形式</h3>
          <el-form-item label="创意展现：">
            <el-radio-group v-model="ruleForm.creativeDisplayType">
              <el-radio label="CREATIVE_DISPLAY_TYPE_INTELLIGENCE">优选模式</el-radio>
              <el-radio label="CREATIVE_DISPLAY_TYPE_AVERAGE">轮播模式</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="自动衍生视频创意：">
            <el-radio-group v-model="ruleForm.autoDerivedCreativeEnabled">
              <el-radio :label="false">关闭</el-radio>
              <el-radio :label="true">开启</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="创意形式：">
            <el-radio-group v-model="ruleForm.adcreativeTemplateId" @change="adcreativeTemplateIdChange">
              <el-radio v-for="item in adcreativeTemplateTypImg[taskSiteSet]" :key="item.label" :class="item.class"
                :label="item.label">{{ item.text }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="落地页：">
            <el-radio-group v-model="ruleForm.pageType" @change="changePageType">
              <el-radio label="PAGE_TYPE_CANVAS_WECHAT">原生落地页</el-radio>
              <!-- <el-radio v-if="[3,4,5].includes(taskSiteSet)" label="PAGE_TYPE_YUEBAO_OFFICIAL_ACCOUNT_ARTICLE">阅宝落地页</el-radio> -->
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="ruleForm.pageType === 'PAGE_TYPE_CANVAS_WECHAT' && taskSiteSetStr.includes('a')"
            label="使用顶部素材：">
            <el-radio-group v-model="ruleForm.overrideCanvasHeadOption">
              <el-radio label="OPTION_CANVAS_OVERRIDE_CREATIVE" :disabled="taskCombine === 2">使用</el-radio>
              <el-radio label="OPTION_CREATIVE_OVERRIDE_CANVAS">不使用</el-radio>
            </el-radio-group>
          </el-form-item>

          <h3>创意内容</h3>
          <template v-if="taskSiteSet === 6">
            <el-form-item label="浮层卡片">
              <el-select v-model="floatingCard.type" @change="handleChangeCardType">
                <el-option label="立即创建" :value="0"></el-option>
                <el-option label="选择已有" :value="1"></el-option>
              </el-select>
              <el-button v-if="floatingCard.type === 1" type="primary" plain style="margin-left: 10px"
                @click="handleFloatingCard">立即选择</el-button>
            </el-form-item>
            <div class="tuiguang"
              v-if="floatingCard.type === 0 || (floatingCard.type === 1 && floatingCard.floatingZoneImageId)">
              <el-form-item label="创意图片" prop="imageUrl">
                <el-upload class="avatar-uploader" :action="UPLOAD_URL" :show-file-list="false"
                  :disabled="floatingCard.type === 1" accept=".JPG,.JPEG,.PNG,.BMP"
                  :before-upload="(file) => beforeAvatarUpload(file)" :on-success="(res) => handleAvatarSuccess(res)">
                  <img v-if="floatingCard.floatingZoneImageId" :src="floatingCard.floatingZoneImageId" class="avatar"
                    style="border:1px solid #ccc; border-radius: 4px " />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <div style="color: #999;font-size:12px">尺寸：800px*800px, JPG、JPEG、PNG、BMP, 大小：400KB以内</div>
              </el-form-item>
              <el-form-item label="文案（1-10字）" prop="floatingZoneName">
                <el-input v-model="floatingCard.floatingZoneName" placeholder="请输入文案（1-10字）"
                  :disabled="floatingCard.type === 1" style="width: 300px;" maxlength="10" show-word-limit />
              </el-form-item>
              <el-form-item label="文案（1-14字）" prop="floatingZoneDesc">
                <el-input v-model="floatingCard.floatingZoneDesc" placeholder="请输入文案（1-14字）"
                  :disabled="floatingCard.type === 1" style="width: 300px;" maxlength="14" show-word-limit />
              </el-form-item>
              <el-form-item label="按钮文案">
                <el-select v-model="floatingCard.floatingZoneButtonText" placeholder="请选择"
                  :disabled="floatingCard.type === 1" style="width: 300px;">
                  <el-option value="MORE_INFO" label="了解更多">了解更多</el-option>
                  <el-option value="BUY_NOW" label="立即购买">立即购买</el-option>
                  <el-option value="RESERVE_NOW" label="立即预约">立即预约</el-option>
                  <el-option value="CONSULT_NOW" label="立即咨询">立即咨询</el-option>
                  <el-option value="APPLY_NOW" label="立即申请">立即申请</el-option>
                  <el-option value="BOOK_NOW" label="立即预定">立即预定</el-option>
                  <el-option value="GET_IT_NOW" label="立即领取">立即领取</el-option>
                  <el-option value="TRY_NOW" label="立即体验">立即体验</el-option>
                </el-select>
              </el-form-item>
            </div>
            <el-form-item label="保存至视频号">
              <el-select v-model="floatingCard.isSaveVideoAccount">
                <el-option :value="true" label="是"></el-option>
                <el-option :value="false" label="否"></el-option>
              </el-select>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item label="品牌形象：">
              <el-select :value="'品牌简介页'">
                <el-option value="品牌简介页" label="品牌简介页">品牌简介页</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="行动按钮：">
              <el-select v-model="activeButton" :disabled="taskSiteSet !== 1" @change="changeActive">
                <el-option :value="true" label="开启">开启</el-option>
                <el-option :value="false" label="关闭">关闭</el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="activeButton" label="按钮文案：" prop="linkNameType"
              :rules="{ required: true, message: '请选择按钮文案', trigger: 'change' }">
              <el-select v-model="ruleForm.linkNameType" placeholder="请选择">
                <el-option value="VIEW_DETAILS" label="查看详情">查看详情</el-option>
                <el-option value="MORE_INFO" label="了解更多">了解更多</el-option>
                <el-option value="BUY_NOW" label="立即购买">立即购买</el-option>
                <el-option value="RESERVE_NOW" label="立即预约">立即预约</el-option>
                <el-option value="CONSULT_NOW" label="立即咨询">立即咨询</el-option>
                <el-option value="APPLY_NOW" label="立即申请">立即申请</el-option>
                <el-option value="BOOK_NOW" label="立即预定">立即预定</el-option>
                <el-option value="GET_COUPONS" label="领取优惠">领取优惠</el-option>
                <el-option value="GET_IT_NOW" label="立即领取">立即领取</el-option>
                <el-option value="GO_SHOPPING" label="去逛逛">去逛逛</el-option>
                <el-option value="TRY_NOW" label="立即体验">立即体验</el-option>
                <el-option value="BUY_ASAP" label="立即抢购">立即抢购</el-option>
                <el-option value="SETUP_NOW" label="立即开通">立即开通</el-option>
                <el-option value="SECKILL_NOW" label="立即秒杀">立即秒杀</el-option>
                <el-option value="WATCH_LIVE" label="观看直播">观看直播</el-option>
                <el-option value="ENTER_MINI_PROGRAM" label="进入小程序">进入小程序</el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="activeButton && [1, 12, 13, 14, 123, 124, 134, 1234].includes(taskSiteSet)"
              label="跳转落地页：">
              <el-select v-model="ruleForm.linkPageType">
                <el-option value="LINK_PAGE_TYPE_CANVAS_WECHAT" label="原生落地页"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="[1, 3, 34].includes(taskSiteSet)" label="数据展示：">
              <el-select v-model="isConversion" @change="changeConversion">
                <el-option :value="true" label="开启">开启</el-option>
                <el-option :value="false" label="关闭">关闭</el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="isConversion" label="数据类型："
              :rules="{ required: true, message: '请选择数据类型', trigger: 'change' }">
              <el-select v-model="ruleForm.conversionDataType">
                <el-option value="CONVERSION_DATA_ADMETRIC" label="转化数据量">转化数据量</el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="isConversion" label="转化行为："
              :rules="{ required: true, message: '请选择转化行为', trigger: 'change' }">
              <el-select v-model="ruleForm.conversionTargetType">
                <el-option value="CONVERSION_TARGET_GET" label="领取">领取</el-option>
                <el-option value="CONVERSION_TARGET_RESERVE" label="预约">预约</el-option>
                <el-option value="CONVERSION_TARGET_BUY" label="购买">购买</el-option>
                <el-option value="CONVERSION_TARGET_APPLY" label="申请">申请</el-option>
                <el-option value="CONVERSION_TARGET_CONSULT" label="咨询">咨询</el-option>
                <el-option v-if="taskSiteSet !== 1" value="CONVERSION_TARGET_DOWNLOAD" label="下载">下载</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="创意名称：" prop="adcreativeName" :rules="{ required: true, message: '请输入创意名称' }">
              <el-input v-model="ruleForm.adcreativeName" rows="4" type="textarea" style="width: 400px"
                placeholder="请输入创意名称" maxlength="60" show-word-limit />
            </el-form-item>
          </template>
          <el-form-item label="">
            <el-checkbox v-model="ruleForm.saveAsPreset">存为预设</el-checkbox>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeCreativity">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <floating-card-dialog ref="floatingCard" @select="confirmFloatingCard"></floating-card-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex' //
import { creativeType, adcreativeTemplateTypImg, linkNameTypeMap } from '../util'
import { saveSelectCreative, deletePageByTaskId } from '@/network/api/advManagement/advManagementTencent' // 获取计划模板
import FloatingCardDialog from './floatingCardDialog'
export default {
  components: {
    FloatingCardDialog
  },
  inject: ['closeCreativity'],
  props: {
    creativityVisible: {
      type: Boolean,
      default: false
    },
    creativityData: {
      type: Object,
      default: () => { }
    },
    taskId: {
      type: Number,
      default: 0
    },
    taskSiteSet: {
      type: Number,
      default: 0
    },
    taskSiteSetStr: {
      type: String,
      default: ''
    },
    taskCombine: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      UPLOAD_URL: '/api/file/upload',
      creativeType,
      adcreativeTemplateTypImg,
      linkNameTypeMap,
      ruleForm: this.creativityData,
      activeButton: true, // 行动按钮
      isConversion: false, // 数据展示
      floatingCard: {
        type: 0, // 浮层卡片选择类型 0 立即创建 1选择已有
        isSaveVideoAccount: false, // 保存至视频号
        floatingZoneImageId: '', // 创意图片
        floatingZoneSwitch: '', // 浮层卡片开关
        floatingZoneName: '', // 文案1-10
        floatingZoneDesc: '', // 文案1-14
        floatingZoneButtonText: '' // 按钮文案
      }
    }
  },

  computed: {
    ...mapGetters(['userInfo', 'isCollapse'])
  },

  watch: {
    creativityVisible(value) {
      if (!value) {
        return
      }
      if (this.taskSiteSet === 6) {
        if (this.creativityData.adcreativeElements && Object.keys(this.creativityData.adcreativeElements).length > 0) {
          let elements = this.creativityData.adcreativeElements
          this.floatingCard = {
            type: 1, // 浮层卡片选择类型 0 立即创建 1选择已有
            isSaveVideoAccount: elements.finder_object_visibility, // 保存至视频号
            floatingZoneImageId: elements.floatingZoneStruct.floatingZoneImageId, // 创意图片
            floatingZoneSwitch: true, // 浮层卡片开关
            floatingZoneName: elements.floatingZoneStruct.floatingZoneName, // 文案1-10
            floatingZoneDesc: elements.floatingZoneStruct.floatingZoneDesc, // 文案1-14
            floatingZoneButtonText: this.handleFilterButtonText(elements.floatingZoneStruct.floatingZoneButtonText) // 按钮文案
          }
        } else {
          this.floatingCard.type = 0
        }
      }
    },
    creativityData(value) {
      this.ruleForm = value
      if (value.conversionDataType && value.conversionTargetType) {
        this.isConversion = true
      }
      if (this.taskCombine === 2) {
        this.ruleForm.overrideCanvasHeadOption = 'OPTION_CREATIVE_OVERRIDE_CANVAS'
      }
    }
  },

  methods: {
    changePageType(val) {
      // 使用原生落地页，并且顶部素材值为空，默认为不使用
      if (val === 'PAGE_TYPE_CANVAS_WECHAT' && !this.ruleForm.overrideCanvasHeadOption) {
        this.ruleForm.overrideCanvasHeadOption = 'OPTION_CREATIVE_OVERRIDE_CANVAS'
      }
    },

    // 浮层卡片创建类型修改
    handleChangeCardType(value) {
      this.floatingCard = {
        ...this.floatingCard,
        floatingZoneImageId: '', // 创意图片
        floatingZoneSwitch: '', // 浮层卡片开关
        floatingZoneName: '', // 文案1-10
        floatingZoneDesc: '', // 文案1-14
        floatingZoneButtonText: '' // 按钮文案
      }
    },

    // 转换按钮文案
    handleFilterButtonText(text = '') {
      let str = ''
      for (let key in this.linkNameTypeMap) {
        if (this.linkNameTypeMap[key] === text) {
          str = key
          return str
        }
      }
      return str || text
    },

    changeConversion(val) {
      if (!val) {
        this.ruleForm.conversionDataType = null
        this.ruleForm.conversionTargetType = null
      }
    },

    changeActive(val) {
      if (!val) {
        this.ruleForm.linkNameType = null
        this.ruleForm.linkPageType = null
      }
    },

    adcreativeTemplateIdChange() {
      this.clearPage()
    },

    //  清空落地页
    async clearPage() {
      await deletePageByTaskId(this.taskId)
    },

    setUserId(value) {
      if (value) {
        this.ruleForm.userId = this.userInfo.userid
      } else {
        this.ruleForm.userId = ''
      }
    },

    // 点击选择已有的悬浮卡片
    handleFloatingCard() {
      this.$refs.floatingCard.showModal()
    },

    // 选择已有卡片回调
    confirmFloatingCard(data) {
      this.floatingCard = {
        ...this.floatingCard,
        floatingZoneImageId: data.floatingZoneImageId, // 创意图片
        floatingZoneName: data.floatingZoneName, // 文案1-10
        floatingZoneDesc: data.floatingZoneDesc, // 文案1-14
        floatingZoneButtonText: this.handleFilterButtonText(data.floatingZoneButtonText) // 按钮文案
      }
    },

    // 卡片主图-判断图片大小
    beforeAvatarUpload: async function (file, type) {
      const isType = ['image/jpg', 'image/jpeg', 'image/png', 'image/bmp'].includes(file.type)
      const isSize = file.size / 1024 < 400
      if (!isType) {
        this.$message.error(`上传图片只能是'image/jpg', 'image/jpeg', 'image/png', 'image/bmp'格式!`)
      }
      if (!isSize) {
        this.$message.error(`上传图片大小不能超过400KB!`)
      }
      const reader = new FileReader()
      reader.readAsDataURL(file)
      // 让页面中的img标签的src指向读取的路径
      await new Promise((resolve, reject) => {
        reader.onload = () => {
          const img = new Image()
          img.src = reader.result
          img.onload = () => {
            if (img.width !== 800 || img.height !== 800) {
              this.$message.error(`上传图片尺寸仅支持 800px * 800px`)
              reject(false)
              return false
            } else {
              resolve(true)
            }
          }
        }
      })
      return isType && isSize
    },

    // 卡片主图上传回调
    handleAvatarSuccess(res) {
      const { data, code } = res
      if (code === 200) {
        this.floatingCard.floatingZoneImageId = data.url
      }
    },

    onSubmit() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.$emit('clearTaskGenerate')
          const { linkNameType, conversionDataType, conversionTargetType } = this.ruleForm
          // console.log(this.ruleForm, 'form')
          if (this.taskSiteSet === 6) {
            const { floatingZoneImageId, floatingZoneName, floatingZoneDesc, floatingZoneButtonText } = this.floatingCard
            if (!floatingZoneImageId) {
              return this.$message.error('请上传创意图片！')
            }
            if (!floatingZoneName) {
              return this.$message.error('请输入文案（1-10字）！')
            }
            if (!floatingZoneDesc) {
              return this.$message.error('请输入文案（1-14字）！')
            }
            if (!floatingZoneButtonText) {
              return this.$message.error('请选择按钮文案！')
            }
          } else {
            if (this.activeButton) {
              if (!linkNameType) {
                return this.$message.error('请选择按钮文案！')
              }
            }
            if (this.isConversion) {
              if (!conversionDataType) {
                return this.$message.error('请选择数据类型！')
              }
              if (!conversionTargetType) {
                return this.$message.error('请选择转化行为！')
              }
            }
          }

          let params = this.formatParams()
          saveSelectCreative({
            ...this.ruleForm,
            taskId: this.taskId,
            taskAdcId: this.creativityData.taskAdcId || null,
            // adcreativeName,
            // adcreativeTemplateId,
            // pageType,
            // siteSet: this.creativityData.siteSet,
            adcreativeElements: JSON.stringify(params)
          }).then((res) => {
            if (res.code === 200) {
              this.$message.success('创意添加成功')
              this.$emit('setCreativityBaseInfo')
              this.closeCreativity()
            }
          })
        }
      })
    },

    formatParams() {
      const { adcreativeTemplateId, linkNameType } = this.ruleForm
      if (this.taskSiteSet === 6) {
        const { isSaveVideoAccount, floatingZoneImageId, floatingZoneName, floatingZoneDesc, floatingZoneButtonText } = this.floatingCard
        let floatingZoneStruct = {
          floatingZoneImageId: floatingZoneImageId, // 创意图片
          floatingZoneSwitch: true, // 浮层卡片开关
          floatingZoneName: floatingZoneName, // 文案1-10
          floatingZoneDesc: floatingZoneDesc, // 文案1-14
          floatingZoneButtonText: this.linkNameTypeMap[floatingZoneButtonText], // 按钮文案
          floatingZoneType: 'FLOATING_ZONE_TYPE_IMAGE_TEXT' // 卡片类型
        }
        let elements = {}
        switch (adcreativeTemplateId) {
          case 599: {
            // 横版视频4:3
            elements = {
              description: '',
              image: '',
              floatingZoneStruct,
              finder_object_visibility: isSaveVideoAccount // 保存至视频号
            }
            break
          }
          case 720: {
            // 横版视频
            elements = {
              description: '',
              image: '',
              video: '',
              floatingZoneStruct,
              finder_object_visibility: isSaveVideoAccount // 保存至视频号
            }
            break
          }
          case 721: {
            // 竖版视频
            elements = {
              description: '',
              image: '',
              video: '',
              floatingZoneStruct,
              finder_object_visibility: isSaveVideoAccount // 保存至视频号
            }
            break
          }
        }
        return elements
      } else {
        if ([311, 641, 642, 643].includes(adcreativeTemplateId)) {
          return {
            brand: {
              brandImg: '',
              brandName: ''
            },
            imageList: [],
            description: '',
            buttonText: this.linkNameTypeMap[linkNameType],
            linkNameType
          }
        } else if ([711, 712].includes(adcreativeTemplateId)) {
          // 竖版大图
          return {
            brand: {
              brandImg: '',
              brandName: ''
            },
            description: '',
            buttonText: this.linkNameTypeMap[linkNameType],
            linkNameType
          }
        } else if (adcreativeTemplateId === 721) {
          // 竖版视频
          return {
            brand: {
              brandImg: '',
              brandName: ''
            },
            label: [
              {
                content: '',
                type: ''
              }
            ],
            buttonText: this.linkNameTypeMap[linkNameType],
            description: '',
            image: '',
            video: '',
            linkNameType
          }
        } else if (adcreativeTemplateId === 720) {
          // 竖版视频
          return {
            brand: {
              brandImg: '',
              brandName: ''
            },
            buttonText: this.linkNameTypeMap[linkNameType],
            description: '',
            image: '',
            video: '',
            linkNameType
          }
        }
      }
    },
    //  <el-radio :label="718">横版三小图 3:2</el-radio>
    //               <el-radio :label="721" class="mt-10">竖版视频 9:16</el-radio>
    //               <el-radio :label="710" class="mt-10">横版视频 16:9</el-radio>
    handleClose() {
      this.floatingCard = {
        type: 0, // 浮层卡片选择类型 0 立即创建 1选择已有
        isSaveVideoAccount: false, // 保存至视频号
        floatingZoneImageId: '', // 创意图片
        floatingZoneSwitch: '', // 浮层卡片开关
        floatingZoneName: '', // 文案1-10
        floatingZoneDesc: '', // 文案1-14
        floatingZoneButtonText: '' // 按钮文案
      }
      this.closeCreativity()
      this.$refs['ruleForm'].resetFields()
    }
  }
}
</script>

<style lang="scss">
.handleTree-icon :hover {
  color: #409eff;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>

<style lang="scss" scoped>
.creativityBaseInfo {
  &>p {
    font-size: 13px;
    margin-bottom: 5px;
  }
}

.tuiguang {
  border: 1px solid #dcdfe6;
  margin-left: 140px;
  box-sizing: border-box;
  padding: 10px 0;
  margin-bottom: 20px;
}
</style>
