/*
 * @Description: 账户管家 投放账户
 * @Date: 2021-11-01 09:56:28
 * @LastEditTime: 2021-11-01 14:13:12
 */
import { getProjectAndProduct } from '@/network/api/assetManagement/assetManagementHugeThirdLandingPage'

export default {
  data() {
    return {
      accountHugeHousekeeperENTs: [],
      accountHugeHousekeeperENTsId: null,
      accountHugeAdvENTLists: [],
      rules: {
        accountId: [{ required: true, message: '请选择管家账号', trigger: 'blur' }],
        GGZaccountId: [{ required: true, message: '请选择广告主账号', trigger: 'blur' }]
      } // 表单校验规则
    }
  },
  created() {
    this.getProjectAndProductFun()
  },
  mounted() {},
  methods: {
    getProjectAndProductFun() {
      getProjectAndProduct().then(({ code, data }) => {
        if (code === 200) {
          this.accountHugeHousekeeperENTs = data.map((item) => item.accountHugeHousekeeperENT)
          let accountHugeAdvENTLists = {}
          data.map((item, index) => {
            let key = item.accountHugeHousekeeperENT.accountId
            if (index === 0) {
              this.accountHugeHousekeeperENTsId = key
            }
            accountHugeAdvENTLists[key] = item.accountHugeAdvENTList
          })
          this.accountHugeAdvENTLists = accountHugeAdvENTLists
        }
      })
    }
  }
}
