<template>
  <div>
    <!-- 列表查询 -->
    <el-form ref="search" :inline="true" :model="search" class="search-form search-form-4" @submit.native.prevent>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="投放账户" prop="uploadType">
            <el-select v-model="search.accountId" placeholder="请选择投放账户">
              <el-option v-for="item in accountList" :key="item.dictLabel" :value="item.dictValue"
                :label="item.dictLabel">{{ item.dictLabel }}</el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" round @click="handleSearch">查询</el-button>
            <el-button round @click="resetFormQuery('search')">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div class="mb-15">
      <el-button type="primary" round>人群关联导入</el-button>
      <el-button type="primary" round @click="handleSyncCrowdPack">同步人群包</el-button>
    </div>

    <div>
      <el-table v-loading="list.loading" :data="list.data" stripe class="list-table"
        :class="{ 'no-data': !list.data || !list.data.length }" style="width: 100%;">
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="" label="人群名称"></el-table-column>
        <el-table-column prop="" label="人群类型"></el-table-column>
        <el-table-column prop="" label="处理状态"></el-table-column>
        <el-table-column prop="" label="用户覆盖数"></el-table-column>
        <el-table-column prop="" label="投放账户"></el-table-column>
        <el-table-column prop="" label="账户ID"></el-table-column>
        <el-table-column prop="" label="关联APPID"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column prop="createTime" label="更新时间"></el-table-column>
        <el-table-column prop="" label="操作">
          <template slot-scope="{row}">
            <el-button type="text" @click="handleDelete(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page">
        <paginations :total="list.total" :page.sync="list.pageNum" :page-sizes="[5, 10, 15, 20]"
          :limit.sync="list.pageSize" @pagination="getPage" />
      </div>
    </div>

    <sync-crowd-pack ref="syncCrowdPack"></sync-crowd-pack>
  </div>
</template>

<script>
import { getDateStartEnd } from '@/utils/ruoyi'
import syncCrowdPack from './syncCrowdPack.vue'
export default {
  name: 'CrowdManagement',
  components: {
    'sync-crowd-pack': syncCrowdPack
  },
  data() {
    return {
      search: {
        accountId: ''
      },
      accountList: [],
      list: {
        loading: true,
        data: [],
        pageSize: 10,
        pageNum: 1,
        total: 0
      }
    }
  },
  mounted() {
    this.getPage()
  },
  methods: {
    /**
     * @description: 重置数据
     * @param {*} formName ref
    */

    resetFormQuery(formName) {
      this.$refs[formName].resetFields()
      this.getPage()
    },

    // 点击搜索
    handleSearch() {
      this.getPage()
    },

    // 获取列表
    getPage() {
      this.list.loading = true
      const [createTimeStart, createTimeEnd] = getDateStartEnd(this.search.createTime)
      let search = {
        ...this.search,
        createTimeEnd,
        createTimeStart,
        pageSize: this.list.pageSize,
        pageNum: this.list.pageNum
      }
      console.log(search)
      this.list.loading = false
      this.list.data = [{}]
      // delete search.createTime
      // Page(search).then(({ code, data }) => {
      //   if (code === 200) {
      //     const { records, total } = data
      //     records.forEach(v => {
      //       v.createBy = v.createBy || '-'
      //       v.projectName = v.projectName || '-'
      //       v.createBy = v.createBy || '-'
      //     })
      //     this.list.data = records
      //     this.list.total = total
      //     this.list.loading = false
      //   }
      // }).catch(e => {
      //   this.list.loading = false
      // })
    },

    // 点击删除
    handleDelete() {
    },

    // 点击同步人群包
    handleSyncCrowdPack() {
      this.$refs.syncCrowdPack.showModal(this.search.accountId)
    }
  }
}
</script>

<style scoped lang="scss"></style>
