<template>
  <div>
    <Search ref="search" />
    <el-row type="flex" justify="space-between" class="operate-box">
      <el-button type="primary" icon="el-icon-plus" round @click="showAdd">新建计划模板</el-button>
    </el-row>
    <div class="table-container">
      <el-table v-loading="loading" stripe class="list-table" :data="data">
        <c-data-empty slot="empty" />
        <el-table-column type="index" label="序号" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="campaignFeedName" label="计划名称" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="subject" label="营销目标" align="left">
          <template>
            网站链接
          </template>
        </el-table-column>
        <el-table-column prop="bgtctltype" label="预算消耗方式" align="left" :formatter="formatterbgtctltype" />
        <el-table-column prop="budget" label="预算(元/天)" align="left" :formatter="formatterBudget" />
        <el-table-column prop="projectName" label="关联项目" align="left" />
        <el-table-column prop="productName" label="关联产品" align="left" />
        <el-table-column prop="createBy" label="创建者" align="left" />
        <el-table-column prop="createTime" label="创建时间" align="left" show-overflow-tooltip />
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button type="text" size="small" @click="showEdit(row)">
              <el-tooltip content="编辑" placement="top">
                <i class="el-icon-edit" style="font-size: 16px"></i>
              </el-tooltip>
            </el-button>
            <el-button type="text" size="small" @click="deleteRow(row)">
              <el-tooltip content="删除" placement="top">
                <i class="el-icon-delete" style="font-size: 16px; color: red"></i>
              </el-tooltip>
            </el-button>
            <el-button type="text" size="small" @click="copy(row)">
              <el-tooltip content="复制" placement="top">
                <i class="el-icon-copy-document" style="font-size: 16px"></i>
              </el-tooltip>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <c-pagination :page-size.sync="pageInfo.pageSize" :page-no.sync="pageInfo.pageNum" :total="pageInfo.total" :get-data-fun="loadData" />
    <OptionDialog ref="dialog" />
  </div>
</template>

<script>
import { PageCommonComponent } from '@/common/mixin'
import { Page, Delete, copyPlan } from '@/network/api/advManagement/advManagementBaiduPlan'
import Search from './Search'
import moment from 'moment'
import OptionDialog from './OptionDialog'
import { AdvManagementBaiduPlanEnt } from './object'
export default {
  name: 'AdvManagementBaiduPlan',
  components: { Search, OptionDialog },
  mixins: [PageCommonComponent],
  provide: function() {
    return {
      loadData: this.loadData,
      showEdit: this.showEdit
    }
  },
  data() {
    return {
      loading: false,
      data: [],
      bgtctltypeList: [],
      planPauseList: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  mounted() {
    this.loadData()
    this.getDicts('baidu_plan_bgtctltype').then((response) => {
      this.bgtctltypeList = response.data
    })
    this.getDicts('baidu_plan_pause').then((response) => {
      this.planPauseList = response.data
    })
  },
  methods: {
    copy(row) {
      this.$confirm('确定复制, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          copyPlan('id=' + row.baiduAdvPlanId).then((res) => {
            if (res.code === 200) {
              this.$message({
                message: '复制成功',
                type: 'success'
              })
              this.loadData()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消复制'
          })
        })
    },
    statuButton() {
      this.$message({ message: '开发中', type: 'success' })
    },
    formatterPauseType(row, cellValue) {
      if (row.starttime !== undefined && row.starttime !== null && row.endtime !== undefined && row.endtime !== null) {
        var tf = moment().isBetween(row.starttime, row.endtime)
        var tf1 = moment().isBefore(row.starttime)
        if (tf1 && cellValue + '' === '0') {
          // 未到开始时间/有效状态
          // cellValue = 2
          return true
        }
        if (tf && cellValue + '' === '0') {
          // 到开始时间/有效状态
          // cellValue = 0
          return true
        }
        var After = moment().isAfter(row.starttime)
        if (After) {
          // 过结束时间
          // cellValue = 1
          return false
        }
      }
      if (cellValue === 1 || cellValue === '1') {
        return true
      }

      return false
    },
    formatterPauseTF(row, cellValue) {
      if (row.starttime !== undefined && row.starttime !== null && row.endtime !== undefined && row.endtime !== null) {
        var tf = moment().isBetween(row.starttime, row.endtime)
        var tf1 = moment().isBefore(row.starttime)
        if (tf1 && cellValue + '' === '0') {
          // 未到开始时间/有效状态
          cellValue = 2
        }
        if (tf && cellValue + '' === '0') {
          // 到开始时间/有效状态
          cellValue = 0
        }
        var After = moment().isAfter(row.starttime)
        if (After) {
          // 过结束时间
          cellValue = 1
        }
      }
      return cellValue
    },
    formatterPause(row, cellValue) {
      var rtstr = '-'
      if (row.starttime !== undefined && row.starttime !== null && row.endtime !== undefined && row.endtime !== null) {
        var tf = moment().isBetween(row.starttime, row.endtime)
        var tf1 = moment().isBefore(row.starttime)
        if (tf1 && cellValue + '' === '0') {
          // 未到开始时间/有效状态
          cellValue = 2
        }
        if (tf && cellValue + '' === '0') {
          // 到开始时间/有效状态
          cellValue = 0
        }
        var After = moment().isAfter(row.starttime)
        if (After) {
          // 过结束时间
          cellValue = 1
        }
      }

      this.planPauseList.forEach((item) => {
        if (item.dictValue === cellValue + '') {
          rtstr = item.dictLabel
        }
      })
      return rtstr
    },
    formatterBudget(row, column, cellValue, index) {
      if (cellValue === null || cellValue === undefined) {
        return '无限'
      } else {
        return cellValue
      }
    },
    formatterbgtctltype(row, column, cellValue, index) {
      var rtstr = '-'
      this.bgtctltypeList.forEach((item) => {
        if (item.dictValue === cellValue + '') {
          rtstr = item.dictLabel
        }
      })
      return rtstr
    },
    loadData(isFirst = false) {
      const searchForm = this.$refs.search.getSearchForm()
      var time = searchForm.selectDate
      if (time !== null && time !== undefined) {
        searchForm.selectDate = time.toString()
      }
      this.doLoadData(isFirst, searchForm)
    },
    doLoadData(firstPage = false, searchForm) {
      firstPage && (this.pageInfo.pageNum = 1)
      const req = { ...searchForm, ...this.pageInfo }
      this.loading = true
      Page(req).then((res) => {
        this.data = res.data.records
        this.pageInfo.total = res.data.total
        this.loading = false
      })
    },
    deleteRow(row) {
      this.$confirm('确定删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          Delete(row.baiduAdvPlanId).then((res) => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.loadData()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showEdit(row) {
      this.$refs.dialog.show(true, { ...row })
    },
    showAdd() {
      this.$refs.dialog.show(false, new AdvManagementBaiduPlanEnt())
    }
  }
}
</script>

<style scoped></style>
