<!--
 * @Description: 工作区 -> 跳转小程序组件
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2021-12-30 15:43:52
-->

<template>
  <section>
    <div
      :style="{
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingTop: `${detail.paddingTop}px`,
        paddingBottom: `${detail.paddingBottom}px`
      }"
    >
      <div
        class="button"
        :style="{
          background: detail.btnBgColorTheme,
          color: detail.fontColor,
          border: `1px solid ${detail.btnBorderColorTheme}`,
          fontWeight: detail.btnFontType === '0' ? 'normal' : 'bold'
        }"
      >
        {{ detail.btnTitle }}
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.button {
  border-radius: 20px;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
}
</style>
