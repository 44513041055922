<template>
  <!--筛选设置弹框-->
  <el-dialog
    class="filter-setting-dialog"
    title="筛选设置"
    :visible.sync="show"
    :append-to-body="true"
    width="600px"
    top="20vh"
    @close="onCloseDialog"
  >
    <div class="page-container">
      <el-form
        ref="form"
        :inline="false"
        label-width="100px"
        :model="form"
        :rules="rules"
      >
        <el-form-item
          label="是否筛选"
          prop="isFilter"
        >
          <el-select
            v-model="form.isFilter"
            style="width: 100%"
            placeholder="请选择是否筛选"
          >
            <el-option
              :value="true"
              label="是"
            ></el-option>
            <el-option
              :value="false"
              label="否"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          v-if="form.isFilter"
          label="规则筛选"
        >
          <div style="margin-bottom: 10px">
            需要投手
            <el-input
              v-model="form.launchPersonnel"
              placeholder="请输入数量"
              style="width: 50%; margin: 0 10px"
              @input="(e) => (form.num= _integerZeroFn(e))"
            >
            </el-input>
            人投票通过
          </div>

          <div style="margin-bottom: 10px">
            需要素材
            <el-input
              v-model="form.assetPersonnel"
              placeholder="请输入数量"
              style="width: 50%; margin: 0 10px"
              @input="(e) => (form.num= _integerZeroFn(e))"
            >
            </el-input>
            人投票通过
          </div>

          <div style="margin-bottom: 10px">
            超过
            <el-input
              v-model="form.overstepDay"
              placeholder="请输入数量"
              style="width: 50%; margin: 0 10px 0 37px"
              @input="(e) => (form.num= _integerZeroFn(e))"
            >
            </el-input>
            天自动结束
          </div>

          <div style="margin-bottom: 10px">
            发起人
            <el-select
              v-model="form.isComputeInitiatePersonnel"
              style="width: 50%; margin: 0 10px 0 23px"
              placeholder="请选择是否筛选"
            >
              <el-option
                :value="true"
                label="计入"
              ></el-option>
              <el-option
                :value="false"
                label="不计入"
              ></el-option>
            </el-select>
            投票人数
          </div>
        </el-form-item>
      </el-form>
    </div>
    <!--底部按钮-->
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        class="btn"
        @click="closeModal"
      >取消</el-button>
      <el-button
        class="btn"
        type="primary"
        :disabled="loading"
        @click="handleConfirm"
      >确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { filterConfigurationDetail, saveFilterConfiguration } from '@/network/api/toolManagement/api-product-test'

export default {
  name: 'FilterSettingDialog',
  data () {
    return {
      show: false,
      loading: false,
      form: {
        isFilter: true,
        launchPersonnel: 2,
        assetPersonnel: 0,
        overstepDay: 7,
        isComputeInitiatePersonnel: true
      },

      rules: {
        isFilter: [{ required: true, message: '请选择是否筛选', trigger: 'change' }]
      }
    }
  },
  methods: {
    /* --------------------------- Private --------------------------- */
    // 获取筛选设置详情
    _getFilterConfigurationDetail () {
      filterConfigurationDetail().then(res => {
        const { isFilter, launchPersonnel, assetPersonnel, overstepDay, isComputeInitiatePersonnel } = res.data
        this.form.isFilter = isFilter
        this.form.launchPersonnel = launchPersonnel || 2
        this.form.assetPersonnel = assetPersonnel || 0
        this.form.overstepDay = overstepDay || 7
        this.form.isComputeInitiatePersonnel = isComputeInitiatePersonnel
      })
    },

    // 只能输入0和正整数
    _integerZeroFn (value) {
      let reg = /^(0|\+?[1-9][0-9]*)$/
      let strArray = value.split('')
      let newStrs = ''
      for (let i = 0; i < strArray.length; i++) {
        if (reg.test(strArray[i])) {
          console.log(strArray[i])
          newStrs += strArray[i]
        } else if (i > 0 && strArray[i] === '0') {
          newStrs += strArray[i]
        }
      }
      if (!(newStrs - 0 < 0)) {
        return newStrs - 0
      } else {
        return ''
      }
    },

    /* --------------------------- Actions --------------------------- */
    showModal () {
      this._getFilterConfigurationDetail()
      this.show = true
    },

    // 点击确定按钮
    handleConfirm () {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return
        }

        if (this.form.launchPersonnel === '' || this.form.assetPersonnel === '' || !this.form.overstepDay === '') {
          this.$message.error('请输入投票人数或筛选天数')
          return
        }

        if (this.form.launchPersonnel === 0 && this.form.assetPersonnel === 0) {
          this.$message.error('投票总人数不可为0')
          return
        }

        if (this.form.overstepDay === 0) {
          this.$message.error('筛选天数不可为0')
          return
        }

        let params = Object.assign({}, this.form)
        this.loading = true
        saveFilterConfiguration(params).then(({ code }) => {
          if (code === 200) {
            this.$message.success('设置成功')
            this.$emit('confirm')
            this.closeModal()
          }
        }).finally(() => {
          this.loading = false
        })
      })
    },

    // 监听 Dialog 弹框关闭
    onCloseDialog () {
      // 重置表单
      this.form = {
        isFilter: true,
        launchPersonnel: 2,
        assetPersonnel: 0,
        overstepDay: 7,
        isComputeInitiatePersonnel: true
      }
    },

    // 关闭弹框
    closeModal () {
      this.show = false
    }
  }
}
</script>

<style scoped lang="scss">
</style>
