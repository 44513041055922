<!--  -->
<template>
  <el-dialog :title="'立即上传'" width="1000px" :visible.sync="visible" :before-close="close" label-position="right" :modal-append-to-body="false" :close-on-press-escape="false" :close-on-click-modal="false">
    <div style="width:100%;height:500px;overflow-x:auto;overflow-y:auto">
      <el-row>
        <el-col :span="3" class="justify-center ">
          <div class="wh160"> <span>图片：</span></div>
        </el-col>
        <el-col v-for="img in imgList" :key="img" :span="3">
          <div class="imgs">
            <el-card :body-style="{ padding: '0px' }">
              <el-image
                class="wh100"
                :src="img.url"
              ></el-image>
              <div>
                <i class="el-icon-error" style="margin-right: 5px;margin-left: 5px;" @click="$emit('delImage',img)"></i>
                <el-tooltip class="item" effect="dark" :content="img.materialName" placement="top-start">
                  <span style="font-size: 6px;">{{ img.materialName.length>5? img.materialName.substring(0,5)+"...":img.materialName }}</span>
                </el-tooltip>
              </div>
            </el-card>

          </div>
        </el-col>
        <el-col :span="3" class="justify-center wh100 ">
          <el-button class="wh100" icon="el-icon-plus" @click="$emit('seleAsset','img')"></el-button>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="3" class="justify-center ">
          <div class="wh160"> <span>视频：</span></div>
        </el-col>
        <el-col v-for="video in videoList" :key="video" :span="3">
          <div class="imgs">
            <el-card :body-style="{ padding: '0px' }">
              <video class="wh100" :src="video.url" />
              <div>
                <i class="el-icon-error" style="margin-right: 5px;margin-left: 5px;" @click="$emit('delVideo',video)"></i>
                <el-tooltip class="item" effect="dark" :content="video.materialName" placement="top-start">
                  <span style="font-size: 6px;">{{ video.materialName.length>5? video.materialName.substring(0,5)+"...":video.materialName }}</span>
                </el-tooltip>
              </div>
            </el-card>

          </div>
        </el-col>
        <el-col :span="3" class="justify-center wh100 ">
          <el-button class="wh100" icon="el-icon-plus" @click="$emit('seleAsset','video')"></el-button>
        </el-col>
      </el-row>
      <!-- 账户-->
      <el-divider content-position="left"> 投放账户选择</el-divider>
      <el-row>
        <el-col :span="24">
          <div style="text-align: center">
            <!-- 列表查询 -->
            <el-form ref="searchAccount" :inline="true" :model="searchAccount" class="search-form search-form-4" @submit.native.prevent>
              <el-row :gutter="24">

                <el-col :span="6">
                  <el-form-item label="服务商" prop="id">
                    <el-select v-model="searchAccount.id" style="width: 100%">
                      <el-option v-for="(itm, idx) in adMpAmList" :key="idx" :label="itm.agencyName+'('+itm.mobileId+')'" :value="itm.id"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="6">
                  <el-form-item prop="name" label="名称/ghid">
                    <el-input v-model="searchAccount.name" placeholder="请输入公众号名称/ghid" />
                  </el-form-item>
                </el-col>

                <el-col :span="3">
                  <el-form-item>
                    <el-button type="primary" round @click="selectGetPage">查询</el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <div style="text-align: center">
            <!-- 表格 -->
            <TablePane :data-source="dataSource" @getPage="getPage"> </TablePane>
          </div>
        </el-col>

        <el-col :span="12">
          <div style="text-align: center">
            <!-- 表格 -->
            <TablePane :data-source="dataSourceSed"> </TablePane>
          </div>
        </el-col>

      </el-row>
    </div>
    <!-- 按钮-->
    <el-divider></el-divider>
    <el-row>
      <el-col :span="24"><div class="justify-center">
        <el-button type="primary" @click="uploadNow">立即上传</el-button>
        <el-button @click=";(visible=false)">取消</el-button>
      </div></el-col>
    </el-row>
  </el-dialog>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import TablePane from '@/components/tablePane.vue' // authorize adMarketingApiClientsSelect
import { adMpAmsAccountsPage, getAdMpAm } from '@/network/api/account/weChatAdvAccount.js'
import { uploadNew } from '@/network/api/assetUpload/asetUp.js'
export default {
  name: '',
  // import引入的组件需要注入到对象中才能使用
  components: { TablePane },
  inject: ['loadData'],
  props: {
    imgList: {
      type: Array,
      default: () => []
    },
    videoList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      adMpAmList: [], // 服务商list
      searchAccount: {
        id: null
      },

      leftDefaultChecked: [],
      rightDefaultChecked: [],
      SelectedVal: [1, 2], // 选中
      dataSource: {
        data: [],
        cols: [
          {
            label: '账号名称',
            width: 100,
            prop: 'name'
          },
          {
            label: 'appid',
            width: 100,
            prop: 'appid'
          }
        ], // 表格的列数据
        isIndex: true, // 列表序号
        loading: false, // loading
        isOperation: true,
        isSelection: true,
        pageData: {
          total: 0 // 总条数
          // layout: 'total, prev, pager, next'
        },
        handleSelectionChange: (selection) => {
          selection.forEach(element => {
            var sed0 = this.dataSource.data.indexOf(element)
            if (sed0 !== -1) {
              this.dataSourceSed.data.push(element)
              this.dataSource.data.splice(sed0, 1)
            }
          })
          this.dataSourceSed.pageData.total = this.dataSourceSed.data.length
          this.dataSourceSed.pageData.pageSize = this.dataSourceSed.data.length
        },
        operation: {
          // 表格有操作列时设置
          label: '', // 列名
          width: 80, // 根据实际情况给宽度
          data: []
        }
      },
      dataSourceSed: {
        data: [],
        cols: [
          {
            label: '账号名称',
            width: 100,
            prop: 'name'
          },
          {
            label: 'appid',
            width: 100,
            prop: 'appid'
          }
        ], // 表格的列数据
        isIndex: true, // 列表序号
        loading: false, // loading
        isOperation: true,
        isSelection: true,
        pageData: {
          total: 0 // 总条数
          // layout: 'total, prev, pager, next'
        },
        handleSelectionChange: (selection) => {
          selection.forEach(element => {
            var sed0 = this.dataSourceSed.data.indexOf(element)
            if (sed0 !== -1) {
              this.dataSourceSed.data.splice(sed0, 1)
            }
          })
          this.getPage()
          this.dataSourceSed.pageData.total = this.dataSourceSed.data.length
          this.dataSourceSed.pageData.pageSize = this.dataSourceSed.data.length
        },
        operation: {
          label: '', // 列名
          width: 80, // 根据实际情况给宽度
          data: []

        }
      },
      visible: false

    }
  },

  // 方法集合
  methods: {
    // 立即上传
    uploadNow() {
      if (this.imgList.length === 0 && this.videoList.length === 0) {
        this.$message.error('请选择图片或视频')
        return
      }
      if (this.dataSourceSed.data.length === 0) {
        this.$message.error('请选择账户')
        return
      }
      uploadNew({ 'listImgs': this.imgList, 'listVideos': this.videoList, 'listAccounts': this.dataSourceSed.data }).then((res) => {
        if (res.code === 200) {
          this.$message.success('操作成功')
          this.loadData()
          this.visible = false
        }
      })
    },
    // 获取服务商
    _getAdMpAm() {
      getAdMpAm().then((res) => {
        if (res.code === 200) {
          this.adMpAmList = res.data
        }
      })
    },
    selectGetPage() {
      // this.dataSourceSed.data = []
      this.getPage()
    },
    /**
     * @description: 获取列表
     * @param {*} params
     */
    getPage(params = { pageNum: 1, pageSize: 10 }) {
      if (this.searchAccount.id === null) {
        this.$message('请选择服务商')
        return
      }
      var list = this.adMpAmList.filter((f) => {
        return this.searchAccount.id === f.id
      })
      var val = list[0]
      this.dataSource.loading = true
      let search = {
        'mobileId': val.mobileId,
        'search': this.searchAccount.name,
        'spid': val.spid
      }
      adMpAmsAccountsPage({ ...search, ...params }).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data

          this.dataSource.data = records.filter((element) => {
            var nlist = this.dataSourceSed.data.filter((f) => {
              return element.id === f.id
            })
            return nlist.length < 1
          })
          // records
          // this.dataSource.data = records
          this.dataSource.pageData.total = total
          this.dataSource.pageData.pageNum = params.pageNum
          this.dataSource.loading = false
        }
      })
    },
    handleChange(value, direction, movedKeys) {
      console.log(value, direction, movedKeys)
    },
    eventShow(isEdit) {
      this.dataSourceSed.data = []
      this.dataSource.data = []
      this.searchAccount.id = null
      this.dataSource.pageData.total = 0
      this.dataSourceSed.pageData.total = 0
      this._getAdMpAm()
      this.visible = isEdit
    },
    close() {
      this.visible = false
      // this.$nextTick(() => {
      //   this.$refs.ent.resetFields()
      // })
    }
  } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
/* @import url(); 引入公共css类 */
  .imgs{
     padding-right: 20px;
  }
  .wh100{
      width: 100px;
       height: 100px
  }
  .wh160{
      width: 60px;
       height: 60px;
       margin-top: 40px;
       margin-left: 20px;
  }
   .transfer-footer {
    margin-left: 20px;
    padding: 6px 5px;
  }

</style>
