var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Base",
        {
          attrs: {
            title: "配置区",
            desc: _vm.taskId ? "基础信息变更后，配置区清空重置" : "",
          },
        },
        [
          _c("template", { slot: "topRight" }),
          _c(
            "div",
            [
              _c(
                "el-descriptions",
                { attrs: { direction: "vertical", border: "" } },
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: "计划",
                        "content-style": "width: 14.28%",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { staticClass: "deploy-item", attrs: { span: 24 } },
                            [
                              _c("div", { staticClass: "title-count" }, [
                                _c("p", [_vm._v("计划基础信息")]),
                              ]),
                              _c("Plan", {
                                attrs: {
                                  visible: _vm.planModalVisible,
                                  "promoted-object-type":
                                    _vm.ruleData.promotedObjectType,
                                  "form-data": _vm.planData,
                                  "task-id": _vm.taskId,
                                },
                                on: {
                                  byTaskIdGetAllFun: _vm.byTaskIdGetAllFun,
                                },
                              }),
                              _c(
                                "el-button",
                                {
                                  staticClass: "footer-add",
                                  attrs: { type: "text" },
                                  on: { click: _vm.addPlan },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.planData.flag ? "编辑" : "添加")
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: "广告",
                        "content-style": "width: 28.56%",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "divider deploy-item",
                              attrs: { span: 12 },
                            },
                            [
                              _c("div", { staticClass: "title-count" }, [
                                _c("p", [_vm._v("广告基础信息")]),
                              ]),
                              _c("Advertisement", {
                                attrs: {
                                  visible: _vm.adModalVisible,
                                  "crt-size-label": _vm.planData.crtSizeLabel,
                                  "promoted-object-type":
                                    _vm.promotedObjectType,
                                  "form-data": _vm.adData,
                                  "task-id": _vm.taskId,
                                },
                                on: {
                                  byTaskIdGetAllFun: _vm.byTaskIdGetAllFun,
                                },
                              }),
                              _c(
                                "el-button",
                                {
                                  staticClass: "footer-add",
                                  attrs: {
                                    disabled: !_vm.planData.flag,
                                    type: "text",
                                  },
                                  on: { click: _vm.addAd },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.adData.flag ? "编辑" : "添加")
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "deploy-item",
                              staticStyle: { "padding-left": "8px" },
                              attrs: { span: 12 },
                            },
                            [
                              _c("div", { staticClass: "title-count" }, [
                                _c("p", [_vm._v("定向包")]),
                                _c("p", [
                                  _vm._v(
                                    "已选 " +
                                      _vm._s(
                                        _vm.orientationData.flag
                                          ? _vm.orientationData.list.length
                                          : 0
                                      ) +
                                      " 个"
                                  ),
                                ]),
                              ]),
                              _c("OrientationPackage", {
                                attrs: {
                                  visible: _vm.orientationVisible,
                                  "form-data": _vm.orientationData,
                                  "task-id": _vm.taskId,
                                },
                                on: {
                                  setFormData: (data) => {
                                    _vm.orientationData = data
                                  },
                                  byTaskIdGetAllFun: _vm.byTaskIdGetAllFun,
                                },
                              }),
                              _c(
                                "el-button",
                                {
                                  staticClass: "footer-add",
                                  attrs: {
                                    type: "text",
                                    disabled: !_vm.planData.flag,
                                  },
                                  on: { click: _vm.addOrientation },
                                },
                                [_vm._v("添加")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: "广告创意",
                        "content-style": "width: 57.12%",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "divider deploy-item",
                              attrs: { span: 6 },
                            },
                            [
                              _c("div", { staticClass: "title-count" }, [
                                _c("p", [_vm._v("创意基础信息")]),
                              ]),
                              _c("CreativityBaseInfo", {
                                attrs: {
                                  visible: _vm.creativityVisible,
                                  "promoted-object-type":
                                    _vm.ruleData.promotedObjectType,
                                  "project-id": _vm.projectId,
                                  "crt-size-label": _vm.planData.crtSizeLabel,
                                  "form-data": _vm.creativityData,
                                  "task-id": _vm.taskId,
                                },
                                on: {
                                  byTaskIdGetAllFun: _vm.byTaskIdGetAllFun,
                                },
                              }),
                              _c(
                                "el-button",
                                {
                                  staticClass: "footer-add",
                                  attrs: {
                                    type: "text",
                                    disabled: !_vm.planData.flag,
                                  },
                                  on: { click: _vm.addCreativity },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.creativityData.flag ? "编辑" : "添加"
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "divider deploy-item",
                              staticStyle: { "padding-left": "8px" },
                              attrs: { span: 6 },
                            },
                            [
                              _c("div", { staticClass: "title-count" }, [
                                _c("p", [_vm._v("创意素材")]),
                                _c("p", [
                                  _vm._v(
                                    "已选 " +
                                      _vm._s(
                                        _vm.creativityMaterialData.flag
                                          ? _vm.creativityMaterialData.list
                                              .length
                                          : 0
                                      ) +
                                      " 组"
                                  ),
                                ]),
                              ]),
                              _c("CreativityMaterial", {
                                attrs: {
                                  visible: _vm.materialVisible,
                                  "account-id": _vm.accountId,
                                  "crt-size-label": _vm.planData.crtSizeLabel,
                                  "promoted-object-type":
                                    _vm.ruleData.promotedObjectType,
                                  "creativity-material-data-template":
                                    _vm.creativityMaterialDataTemplate,
                                  "form-data": _vm.creativityMaterialData,
                                  "project-id": _vm.projectId,
                                  "creativity-data": _vm.creativityData,
                                  "task-id": _vm.taskId,
                                },
                                on: {
                                  byTaskIdGetAllFun: _vm.byTaskIdGetAllFun,
                                },
                              }),
                              _c(
                                "el-button",
                                {
                                  staticClass: "footer-add",
                                  attrs: {
                                    type: "text",
                                    disabled:
                                      !_vm.planData.flag ||
                                      !_vm.creativityData.flag ||
                                      _vm.planData.crtSizeLabel === "朋友圈",
                                  },
                                  on: { click: _vm.addMaterial },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.creativityMaterialData.flag
                                        ? "编辑"
                                        : "添加"
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "divider deploy-item",
                              staticStyle: { "padding-left": "8px" },
                              attrs: { span: 6 },
                            },
                            [
                              _c("div", { staticClass: "title-count" }, [
                                _c("p", [_vm._v("创意文案")]),
                                _c("p", [
                                  _vm._v(
                                    "已选 " +
                                      _vm._s(
                                        _vm.creativityTextData.flag
                                          ? _vm.creativityTextData.list.length
                                          : 0
                                      ) +
                                      " 组"
                                  ),
                                ]),
                              ]),
                              _c("CreativityText", {
                                attrs: {
                                  visible: _vm.textVisible,
                                  "creativity-data": _vm.creativityData,
                                  "form-data": _vm.creativityTextData,
                                  "crt-size-label": _vm.planData.crtSizeLabel,
                                  "creativity-text-data-template":
                                    _vm.creativityTextDataTemplate,
                                  "project-id": _vm.projectId,
                                  "task-id": _vm.taskId,
                                },
                                on: {
                                  byTaskIdGetAllFun: _vm.byTaskIdGetAllFun,
                                },
                              }),
                              _c(
                                "el-button",
                                {
                                  staticClass: "footer-add",
                                  attrs: {
                                    type: "text",
                                    disabled:
                                      !_vm.planData.flag ||
                                      !_vm.creativityData.flag ||
                                      [
                                        "展示Banner图片",
                                        "小程序banner广告",
                                      ].includes(_vm.planData.crtSizeLabel),
                                  },
                                  on: { click: _vm.addText },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.creativityTextDataTemplate.flag
                                        ? "编辑"
                                        : "添加"
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "deploy-item",
                              staticStyle: { "padding-left": "8px" },
                              attrs: { span: 6 },
                            },
                            [
                              _c("div", { staticClass: "title-count" }, [
                                _c("p", [_vm._v("落地页")]),
                                _c("p", [
                                  _vm._v(
                                    "已选 " +
                                      _vm._s(
                                        _vm.ldyData.flag
                                          ? _vm.ldyData.list.length
                                          : 0
                                      ) +
                                      " 条"
                                  ),
                                ]),
                              ]),
                              _c("Ldy", {
                                attrs: {
                                  visible: _vm.ldyModalVisible,
                                  "form-data": _vm.ldyData,
                                  "task-id": _vm.taskId,
                                  "creativity-data": _vm.creativityData,
                                  "crt-size-label": _vm.planData.crtSizeLabel,
                                },
                                on: {
                                  setFormData: (data) => {
                                    _vm.ldyData = data
                                  },
                                  byTaskIdGetAllFun: _vm.byTaskIdGetAllFun,
                                },
                              }),
                              _c(
                                "el-button",
                                {
                                  staticClass: "footer-add",
                                  attrs: {
                                    type: "text",
                                    disabled:
                                      !_vm.planData.flag ||
                                      !_vm.creativityData.flag ||
                                      !(
                                        _vm.creativityData.pageType &&
                                        _vm.creativityData.pageType === 4
                                      ),
                                  },
                                  on: { click: _vm.addLDY },
                                },
                                [_vm._v("添加")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              { staticClass: "justify-sb" },
              [
                _c(
                  "div",
                  [
                    _vm.isCustomer
                      ? _c(
                          "el-button",
                          {
                            attrs: { disabled: !_vm.planData.flag },
                            on: { click: _vm.addCustomer },
                          },
                          [_vm._v("企微客服组")]
                        )
                      : _vm._e(),
                    _vm.adData.trackingSourceid === "0"
                      ? _c(
                          "el-button",
                          {
                            staticClass: "ml-10",
                            on: { click: _vm.addSource },
                          },
                          [_vm._v("数据源")]
                        )
                      : _vm._e(),
                    _vm.orientationData.list.some((s) => s.isOnlineCrowd)
                      ? _c(
                          "el-button",
                          { staticClass: "ml-10", on: { click: _vm.addCrowd } },
                          [_vm._v("人群包")]
                        )
                      : _vm._e(),
                    _vm.planData.flag
                      ? _c(
                          "el-button",
                          { staticClass: "ml-10", on: { click: _vm.addGoods } },
                          [_vm._v("商品选择")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      loading: _vm.showPreviewFunLoading,
                      disabled:
                        _vm.showPreviewFunLoading ||
                        !_vm.planData.flag ||
                        !_vm.adData.flag ||
                        !_vm.orientationData.flag ||
                        !_vm.creativityData.flag ||
                        !_vm.creativityMaterialData.flag ||
                        !_vm.creativityTextData.flag ||
                        !_vm.ldyData.flag ||
                        !_vm.customerData.flag,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.showPreviewFun({ pageNum: 1, pageSize: 10 })
                      },
                    },
                  },
                  [_vm._v(" 预览广告 ")]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _vm.isCustomer
        ? _c("Customer", {
            attrs: {
              visible: _vm.customerVisible,
              "project-id": _vm.projectId,
              "account-adv": _vm.accountAdv,
              "form-data": _vm.customerData,
              "task-id": _vm.taskId,
            },
            on: { byTaskIdGetAllFun: _vm.byTaskIdGetAllFun },
          })
        : _vm._e(),
      _vm.adData.trackingSourceid === "0"
        ? _c("Source", {
            attrs: {
              visible: _vm.sourceVisible,
              "project-id": _vm.projectId,
              "account-adv": _vm.accountAdv,
              "form-data": _vm.sourceData,
              "task-id": _vm.taskId,
            },
            on: { byTaskIdGetAllFun: _vm.byTaskIdGetAllFun },
          })
        : _vm._e(),
      _vm.orientationData.list.some((s) => s.isOnlineCrowd)
        ? _c("Crowd", {
            attrs: {
              visible: _vm.crowdVisible,
              "project-id": _vm.projectId,
              "account-adv": _vm.accountAdv,
              "form-data": _vm.crowdData,
              "task-id": _vm.taskId,
            },
            on: { byTaskIdGetAllFun: _vm.byTaskIdGetAllFun },
          })
        : _vm._e(),
      _vm.planData.flag
        ? _c("Goods", {
            attrs: {
              visible: _vm.goodsVisible,
              "project-id": _vm.projectId,
              "account-adv": _vm.accountAdv,
              "form-data": _vm.goodsData,
              "task-id": _vm.taskId,
            },
            on: { byTaskIdGetAllFun: _vm.byTaskIdGetAllFun },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }