var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        title: "监测链接",
        "append-to-body": true,
        width: "800px",
        top: "20vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "tools-box" },
        [
          _c(
            "el-button",
            { attrs: { type: "text" }, on: { click: _vm.handleClickUrl } },
            [_vm._v("获取链接")]
          ),
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            rules: _vm.rules,
            "label-position": "left",
            model: _vm.form,
            "label-width": "130px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "链接分配", prop: "type" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("全部一致")]),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("单户配置")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.form.type == 1
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "有效触点链接",
                        prop: "allTrack[0].trackUrl",
                        rules: [
                          {
                            required: true,
                            trigger: "blur",
                            message: "请输入触点链接",
                          },
                          { validator: _vm.validateCheckFn, trigger: "blur" },
                        ],
                      },
                    },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "bottom",
                            title: "",
                            width: "400",
                            trigger: "click",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: {
                              width: "280px",
                              "margin-right": "10px",
                            },
                            attrs: {
                              slot: "reference",
                              type: "text",
                              placeholder: "请输入有效触点链接",
                            },
                            slot: "reference",
                            model: {
                              value: _vm.form.allTrack[0].trackUrl,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.allTrack[0], "trackUrl", $$v)
                              },
                              expression: "form.allTrack[0].trackUrl",
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "track-options-box" },
                            [
                              _c(
                                "div",
                                { staticClass: "track-option-header" },
                                [
                                  _c("span", [_vm._v("监测链接")]),
                                  _c("span", [_vm._v("备注")]),
                                ]
                              ),
                              !_vm.adviseData.length
                                ? _c(
                                    "div",
                                    { staticClass: "track-option-empty" },
                                    [_vm._v("暂无数据")]
                                  )
                                : _vm._l(
                                    _vm.adviseData,
                                    function (item, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "track-option-item",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleSelectRemark(
                                                item,
                                                -1
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content: item.trackUrl,
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "name" },
                                                [_vm._v(_vm._s(item.trackUrl))]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content: item.remarks,
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "addr" },
                                                [_vm._v(_vm._s(item.remarks))]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c("el-input", {
                        staticStyle: { width: "180px" },
                        attrs: {
                          type: "text",
                          placeholder: "备注，非必填",
                          maxlength: 15,
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.form.allTrack[0].remarks,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.allTrack[0], "remarks", $$v)
                          },
                          expression: "form.allTrack[0].remarks",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.type == 2
            ? _c(
                "div",
                _vm._l(_vm.form.tracks, function (item, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      _c("div", { staticClass: "track-title" }, [
                        _vm._v(_vm._s(item.name || "-")),
                      ]),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "有效触点链接",
                            prop: "tracks." + index + ".trackUrl",
                            rules: [
                              {
                                required: true,
                                trigger: "change",
                                message: "请输入触点链接",
                              },
                              {
                                validator: _vm.validateCheckFn,
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                placement: "bottom",
                                title: "",
                                width: "400",
                                trigger: "hover",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: {
                                  width: "280px",
                                  "margin-right": "10px",
                                },
                                attrs: {
                                  slot: "reference",
                                  type: "text",
                                  placeholder: "请输入有效触点链接",
                                },
                                slot: "reference",
                                model: {
                                  value: _vm.form.tracks[index].trackUrl,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.tracks[index],
                                      "trackUrl",
                                      $$v
                                    )
                                  },
                                  expression: "form.tracks[index].trackUrl",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "track-options-box" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "track-option-header" },
                                    [
                                      _c("span", [_vm._v("监测链接")]),
                                      _c("span", [_vm._v("备注")]),
                                    ]
                                  ),
                                  !_vm.adviseData.length
                                    ? _c(
                                        "div",
                                        { staticClass: "track-option-empty" },
                                        [_vm._v("暂无数据")]
                                      )
                                    : _vm._l(_vm.adviseData, function (v, i) {
                                        return _c(
                                          "div",
                                          {
                                            key: i,
                                            staticClass: "track-option-item",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleSelectRemark(
                                                  v,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  content: v.trackUrl,
                                                  placement: "top",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "name" },
                                                  [_vm._v(_vm._s(v.trackUrl))]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "item",
                                                attrs: {
                                                  effect: "dark",
                                                  content: v.remarks,
                                                  placement: "top",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "addr" },
                                                  [_vm._v(_vm._s(v.remarks))]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c("el-input", {
                            staticStyle: { width: "180px" },
                            attrs: {
                              type: "text",
                              placeholder: "备注，非必填",
                              maxlength: 15,
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.form.tracks[index].remarks,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.tracks[index], "remarks", $$v)
                              },
                              expression: "form.tracks[index].remarks",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "btn", on: { click: _vm.closeModal } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: {
                type: "primary",
                loading: _vm.isLoading,
                disabled: _vm.isLoading,
              },
              on: {
                click: function ($event) {
                  return _vm.clickConfirm("form")
                },
              },
            },
            [_vm._v("确 定 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }