<template>
  <div>
    <!-- 列表查询 -->
    <el-form ref="search" :inline="true" :model="search" class="search-form search-form-4">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="产品名称" prop="toolProductName">
            <el-input v-model="search.toolProductName" placeholder="请输入产品名称" style="width: 100%"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="平台" prop="toolProductPlatform">
            <el-select v-model="search.toolProductPlatform" style="width: 100%">
              <el-option label="全部" :value="1"></el-option>

              <el-option v-for="item in platformList" :key="item.dictValue" :label="item.dictLabel"
                :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="类型" prop="type">
            <el-select v-model="search.type" style="width: 100%">
              <el-option label="全部" :value="1"></el-option>

              <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="投测时间" prop="launchTime">
            <el-date-picker v-model="search.launchTime" style="width: 100%" type="datetimerange" prange-separator="至"
              start-placeholder="开始时间" value-format="yyyy-MM-dd HH:mm:ss" end-placeholder="结束时间">
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="素材时间" prop="assetTime">
            <el-date-picker v-model="search.assetTime" style="width: 100%" type="datetimerange" prange-separator="至"
              start-placeholder="开始时间" value-format="yyyy-MM-dd HH:mm:ss" end-placeholder="结束时间">
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="测试状态" prop="toolProductTestStatus">
            <el-select v-model="search.toolProductTestStatus" style="width: 100%">
              <el-option label="全部" :value="1"></el-option>

              <el-option v-for="item in testStatusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="测试结果" prop="toolProductResult">
            <el-select v-model="search.toolProductResult" style="width: 100%">
              <el-option label="全部" :value="1"></el-option>

              <el-option v-for="item in toolProductResultList" :key="item.id" :label="item.name"
                :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="是否起量" prop="isInception">
            <el-select v-model="search.isInception" style="width: 100%">
              <el-option label="全部" :value="1"></el-option>
              <el-option label="已起量" :value="2"></el-option>
              <el-option label="未起量" :value="3"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="投放部门" prop="launchDeptId">
            <el-cascader v-model="search.launchDeptId" :options="deptList" :props="cascaderProp" filterable
              placeholder="请选择投放部门" style="width: 100%"></el-cascader>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="素材部门" prop="assetDeptId">
            <el-cascader v-model="search.assetDeptId" :options="deptList" :props="cascaderProp" filterable
              placeholder="请选择素材部门" style="width: 100%"></el-cascader>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" round @click="onSubmit">查 询</el-button>
            <el-button type="primary" round @click="resetForm('ruleForm')">重 置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div>
      <el-table v-loading="list.loading" :data="list.data" stripe class="list-table"
        :class="{ 'no-data': !list.data || !list.data.length }" style="width: 100%">
        <el-table-column type="index" label="序号" width="50" align="center" fixed="left"></el-table-column>

        <el-table-column prop="toolProductName" label="名称" align="center" fixed="left"></el-table-column>

        <el-table-column label="平台" align="center">
          <template slot-scope="{ row }">
            {{ platformList.find(item => item.dictValue === row.toolProductPlatform).dictLabel }}
          </template>
        </el-table-column>

        <el-table-column label="类型" align="center">
          <template slot-scope="{ row }">
            {{ row.type | filterToolType }}
          </template>
        </el-table-column>

        <el-table-column label="连载状态" align="center">
          <template slot-scope="{ row }">
            {{ row.serializeStatus === '1' ? '连载' : '完结' }}
          </template>
        </el-table-column>

        <el-table-column prop="num" label="数量" align="center"></el-table-column>

        <el-table-column label="投放人员" align="left" width="200px">
          <template slot-scope="{ row }">
            <el-tooltip v-if="row.testToolProductLaunchPerson.length" placement="top-start">
              <div slot="content">
                <div v-for="(asset, index) in row.testToolProductLaunchPerson" :key="index">{{ asset }}</div>
              </div>
              <div>
                <div v-for="(asset, index) in row.testToolProductLaunchPerson.slice(0, 3)" :key="index" class="tool-user-label">{{ asset }}</div>
              </div>
            </el-tooltip>
            <span v-else>-</span>
          </template>
        </el-table-column>

        <el-table-column label="素材人员" align="left" width="200px">
          <template slot-scope="{ row }">
            <el-tooltip v-if="row.assetToolProductLaunchPerson.length" placement="top-start">
              <div slot="content">
                <div v-for="(asset, index) in row.assetToolProductLaunchPerson" :key="index">{{ asset }}</div>
              </div>
              <div>
                <div v-for="(asset, index) in row.assetToolProductLaunchPerson.slice(0, 3)" :key="index" class="tool-user-label">{{ asset }}</div>
              </div>
            </el-tooltip>
            <span v-else>-</span>
          </template>
        </el-table-column>

        <el-table-column prop="testStartAndEndTime" label="测试时段" align="center" width="200"></el-table-column>

        <el-table-column label="测试状态" align="center">
          <template slot-scope="{ row }">
            {{ row.testStatus | filterTestStatus }}
          </template>
        </el-table-column>

        <el-table-column prop="isInception" label="是否起量" align="center">
          <template slot-scope="{ row }">
            <div @click="handleAmountDetail(row.toolProductId)" style="cursor: pointer;">
              <el-tag v-if="row.isInception" type="success">已起量</el-tag>
              <el-tag v-else type="danger">未起量</el-tag>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="consume" label="消耗" align="center"></el-table-column>

        <el-table-column prop="payPowderPrice" label="支付粉价" align="center"></el-table-column>

        <el-table-column prop="d1" label="D1" align="center"></el-table-column>

        <el-table-column prop="d2" label="D2" align="center"></el-table-column>

        <el-table-column prop="d3" label="D3" align="center"></el-table-column>

        <el-table-column label="详情" fixed="right">
          <template slot-scope="{ row }">
            <el-button type="text" @click="handleGoDetail(row.toolProductId, row)">
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="page">
        <paginations :total="list.total" :page.sync="list.pageNum" :page-sizes="[5, 10, 15, 20]"
          :limit.sync="list.pageSize" @pagination="getPage" />
      </div>
    </div>

    <!-- 测试详情 -->
    <test-details-dialog ref="testDetails" @confirm="getPage" />

    <!-- 产品起量详情 -->
    <amount-details-dialog ref="amountDetails" />
  </div>
</template>

<script>
import { projectionDataList, getDeptList } from '@/network/api/toolManagement/api-product-test'
import TestDetailsDialog from './components/TestDetailsDialog'
import AmountDetailsDialog from './components/AmountDetailsDialog'

export default {
  components: {
    'test-details-dialog': TestDetailsDialog,
    'amount-details-dialog': AmountDetailsDialog
  },
  filters: {
    filterToolType(value) {
      const types = { '1': '全部', '2': '男频', '3': '女频', '4': '男女通用' }
      return types[value] || ''
    },
    filterTestStatus(value) {
      const types = { '2': '未测试', '3': '待测试', '4': '测试中', '5': '已测试', '6': '自动结束' }
      return types[value] || ''
    }
  },
  data() {
    return {
      search: {
        toolProductName: '',
        toolProductPlatform: 1,
        type: 1,
        isInception: 1,
        launchTime: null,
        assetTime: null,
        toolProductTestStatus: 1,
        toolProductResult: 1,
        launchDeptId: '',
        assetDeptId: ''
      },
      cascaderProp: {
        emitPath: false,
        checkStrictly: true,
        value: 'departmentId',
        label: 'departmentName',
        children: 'children'
      },

      list: {
        loading: true,
        data: [],
        pageSize: 10,
        pageNum: 1,
        total: 0
      },

      deptList: [],
      platformList: [],
      departmentList: [],
      typeList: [{ id: 2, name: '男频' }, { id: 3, name: '女频' }, { id: 4, name: '男女通用' }],
      toolProductResultList: [{ id: 2, name: '失败' }, { id: 3, name: '成功' }],
      testStatusList: [{ id: 2, name: '未测试' }, { id: 4, name: '测试中' }, { id: 5, name: '已测试' }, { id: 6, name: '自动结束' }]
    }
  },

  mounted() {
    this.getDicts('return_platform_type').then((response) => {
      this.platformList = response.data
      this.getPage()
      this._getDeptList()
    })
  },

  methods: {
    // 列表
    getPage() {
      this.list.loading = true
      let params = {
        ...this.search,
        pageSize: this.list.pageSize,
        pageNum: this.list.pageNum
      }
      projectionDataList(params).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          if (records.length) {
            this.list.data = records.map(item => {
              return {
                // 产品数据
                toolProductId: item.toolProductLibray.toolProductId,
                toolProductName: item.toolProductLibray.toolProductName,
                toolProductPlatform: item.toolProductLibray.toolProductPlatform,
                type: item.toolProductLibray.type,
                serializeStatus: item.toolProductLibray.serializeStatus,
                num: item.toolProductLibray.num,
                testStatus: item.toolProductLibray.toolProductTestStatus,
                isInception: item.toolProductLibray.isInception,

                assetToolProductLaunchPerson: item.assetToolProductLaunchPersonNew,
                testToolProductLaunchPerson: item.testToolProductLaunchPersonNew,
                testStartAndEndTime: item.testStartAndEndTime,

                consume: item.toolProductLibray.consume,
                payPowderPrice: item.toolProductLibray.payPowderPrice,
                d1: item.toolProductLibray.d1,
                d2: item.toolProductLibray.d2,
                d3: item.toolProductLibray.d3
              }
            })
          } else {
            this.list.data = []
          }

          this.list.total = total
        }
      }).finally(e => {
        this.list.loading = false
      })
    },

    _getDeptList() {
      getDeptList({ departmentName: '' }).then(res => {
        let list = res.data.map(v => {
          return {
            departmentId: v.departmentId,
            departmentName: v.departmentName,
            children: v.children ? JSON.parse(v.children) : []
          }
        })
        this.deptList = list || []
      })
    },

    // 搜索
    onSubmit() {
      if (this.list.pageNum === 1) {
        this.getPage()
      } else {
        this.list.pageNum = 1
      }
    },

    // 重置
    resetForm() {
      this.$refs['search'].resetFields()
      this.search.launchTime = null
      this.search.assetTime = null
      this.getPage()
    },

    // 点击跳转
    handleGoDetail(id, val) {
      this.$refs.testDetails.showModal(id, val)
    },

    // 点击起量
    handleAmountDetail(id) {
      this.$refs.amountDetails.showModal(id)
    }
  }
}
</script>

<style lang="scss" scoped>
.element-img {
  width: 60px;
  height: 60px;
  text-align: center;
  margin: auto;
  padding-top: 12px;

  video {
    width: 100%;
    height: 100%;
  }
}

.element-pop-img {
  max-width: 260px;

  video {
    width: 100%;
    height: 100%;
  }
}

.tool-user-label{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
}

.red {
  color: #ff0000;
}
</style>
