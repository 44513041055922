var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "批量添加",
            visible: _vm.isBatch,
            width: "800px",
            "modal-append-to-body": false,
            "destroy-on-close": "",
          },
          on: { close: _vm.close },
        },
        [
          _c(
            "el-form",
            {
              ref: "text",
              staticClass: "demo-form-inline mt-20",
              attrs: { inline: true, model: _vm.text },
            },
            [
              _c("div", { staticClass: "justify-sb mt-10 mb-20" }, [
                _c("p", [
                  _vm._v(
                    "项目名称: " + _vm._s(_vm.baseInfo().management.projectName)
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "产品名称: " +
                      _vm._s(_vm.baseInfo().management.productName || "不限")
                  ),
                ]),
              ]),
              _vm.crtSizeLabel === "朋友圈"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "circleOfFriendsText",
                        label: "文案:",
                        rules: _vm.rules.circleOfFriendsText,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "600px" },
                        attrs: {
                          type: "textarea",
                          rows: 4,
                          placeholder: "请输入文案，多个文案请换行！",
                        },
                        model: {
                          value: _vm.text.circleOfFriendsText,
                          callback: function ($$v) {
                            _vm.$set(_vm.text, "circleOfFriendsText", $$v)
                          },
                          expression: "text.circleOfFriendsText",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.crtSizeLabel === "优雅横版大图"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "infoBarText",
                        label: "信息栏文案:",
                        rules: _vm.rules.infoBarText,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "600px" },
                        attrs: {
                          "trigger-on-focus": false,
                          type: "textarea",
                          rows: 4,
                          placeholder: "请输入文案，多个文案请换行！",
                        },
                        model: {
                          value: _vm.text.infoBarText,
                          callback: function ($$v) {
                            _vm.$set(_vm.text, "infoBarText", $$v)
                          },
                          expression: "text.infoBarText",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.crtSizeLabel === "优雅横版大图"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "graphicTextTitle",
                        label: "图文标题:",
                        rules: _vm.rules.graphicTextTitle,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "600px" },
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入图文标题，，多个标题请换行！",
                          rows: 4,
                        },
                        model: {
                          value: _vm.text.graphicTextTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.text, "graphicTextTitle", $$v)
                          },
                          expression: "text.graphicTextTitle",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.crtSizeLabel === "优雅横版大图"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "advTextOne",
                        label: "广告文案:",
                        rules: _vm.rules.advTextOne,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "600px" },
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入首行文案，，多个文案请换行！",
                          rows: 4,
                        },
                        model: {
                          value: _vm.text.advTextOne,
                          callback: function ($$v) {
                            _vm.$set(_vm.text, "advTextOne", $$v)
                          },
                          expression: "text.advTextOne",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.crtSizeLabel === "优雅横版大图"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "advTextTwo",
                        label: "",
                        rules: _vm.rules.advTextTwo,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "600px" },
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入次行文案，，多个文案请换行！",
                          rows: 4,
                        },
                        model: {
                          value: _vm.text.advTextTwo,
                          callback: function ($$v) {
                            _vm.$set(_vm.text, "advTextTwo", $$v)
                          },
                          expression: "text.advTextTwo",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.crtSizeLabel === "优雅横版大图"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "advTextThree",
                        label: "",
                        rules: _vm.rules.advTextThree,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "600px" },
                        attrs: {
                          type: "textarea",
                          placeholder: "请输入第三行文案(选填)，多个请换行！",
                          rows: 4,
                        },
                        model: {
                          value: _vm.text.advTextThree,
                          callback: function ($$v) {
                            _vm.$set(_vm.text, "advTextThree", $$v)
                          },
                          expression: "text.advTextThree",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.crtSizeLabel === "沉浸式横版视频"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "广告卡片文案:",
                        prop: "adCardCopyText",
                        rules: _vm.rules.adCardCopyText,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "600px" },
                        attrs: {
                          type: "textarea",
                          rows: 4,
                          placeholder: "请输入广告卡片文案, ，多个文案请换行！",
                        },
                        model: {
                          value: _vm.text.adCardCopyText,
                          callback: function ($$v) {
                            _vm.$set(_vm.text, "adCardCopyText", $$v)
                          },
                          expression: "text.adCardCopyText",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.crtSizeLabel === "沉浸式横版视频"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "信息栏文案:",
                        prop: "infoBarText",
                        rules: _vm.rules.infoBarText,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "600px" },
                        attrs: {
                          type: "textarea",
                          rows: 4,
                          placeholder: "请输入信息栏文案，多个文案请换行！",
                        },
                        model: {
                          value: _vm.text.infoBarText,
                          callback: function ($$v) {
                            _vm.$set(_vm.text, "infoBarText", $$v)
                          },
                          expression: "text.infoBarText",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.crtSizeLabel === "沉浸式横版视频"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "广告文案:",
                        prop: "advTextOne",
                        rules: _vm.rules.advTextOne,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "600px" },
                        attrs: {
                          type: "textarea",
                          rows: 4,
                          placeholder: "请输入首行文案，多个文案请换行！",
                          "max-length": 16,
                        },
                        model: {
                          value: _vm.text.advTextOne,
                          callback: function ($$v) {
                            _vm.$set(_vm.text, "advTextOne", $$v)
                          },
                          expression: "text.advTextOne",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.crtSizeLabel === "沉浸式横版视频"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "",
                        prop: "advTextTwo",
                        rules: _vm.rules.advTextTwo,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "600px" },
                        attrs: {
                          type: "textarea",
                          rows: 4,
                          placeholder: "请输入次行文案，多个文案请换行！",
                          "max-length": 16,
                        },
                        model: {
                          value: _vm.text.advTextTwo,
                          callback: function ($$v) {
                            _vm.$set(_vm.text, "advTextTwo", $$v)
                          },
                          expression: "text.advTextTwo",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.crtSizeLabel === "沉浸式横版视频"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "",
                        prop: "advTextThree",
                        rules: _vm.rules.advTextThree,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "600px" },
                        attrs: {
                          type: "textarea",
                          rows: 4,
                          placeholder:
                            "请输入第三行文案(选填)，多个文案请换行！",
                          "max-length": 16,
                        },
                        model: {
                          value: _vm.text.advTextThree,
                          callback: function ($$v) {
                            _vm.$set(_vm.text, "advTextThree", $$v)
                          },
                          expression: "text.advTextThree",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.crtSizeLabel === "沉浸式横版视频" &&
              _vm.creativityData.isEndPage
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "tailTextTitle",
                        label: "结束页文案:",
                        rules: _vm.rules.tailTextTitle,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "600px" },
                        attrs: {
                          type: "textarea",
                          rows: 4,
                          placeholder: "请输入结束页文案，多个文案请换行！",
                        },
                        model: {
                          value: _vm.text.tailTextTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.text, "tailTextTitle", $$v)
                          },
                          expression: "text.tailTextTitle",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.crtSizeLabel === "公众号文章底部图片"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "信息栏文案:",
                        prop: "infoBarText",
                        rules: _vm.rules.infoBarText,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "600px" },
                        attrs: {
                          type: "textarea",
                          rows: 4,
                          placeholder: "请输入信息栏文案，多个文案请换行！",
                        },
                        model: {
                          value: _vm.text.infoBarText,
                          callback: function ($$v) {
                            _vm.$set(_vm.text, "infoBarText", $$v)
                          },
                          expression: "text.infoBarText",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.crtSizeLabel === "公众号文章底部视频"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "信息栏文案:",
                        prop: "infoBarText",
                        rules: _vm.rules.infoBarText,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "600px" },
                        attrs: {
                          type: "textarea",
                          rows: 4,
                          placeholder: "请输入信息栏文案，多个文案请换行！",
                        },
                        model: {
                          value: _vm.text.infoBarText,
                          callback: function ($$v) {
                            _vm.$set(_vm.text, "infoBarText", $$v)
                          },
                          expression: "text.infoBarText",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.crtSizeLabel === "公众号文章底部视频" &&
              _vm.creativityData.isEndPage
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "结束页文案:",
                        prop: "tailTextTitle",
                        rules: _vm.rules.tailTextTitle,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "600px" },
                        attrs: {
                          type: "textarea",
                          rows: 4,
                          placeholder: "请输入结束页文案，多个文案请换行！",
                        },
                        model: {
                          value: _vm.text.tailTextTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.text, "tailTextTitle", $$v)
                          },
                          expression: "text.tailTextTitle",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.crtSizeLabel === "公众号文章中部"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "信息栏文案:",
                        prop: "text",
                        rules: _vm.rules.text,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "600px" },
                        attrs: {
                          type: "textarea",
                          rows: 4,
                          placeholder: "请输入信息栏文案，多个文案请换行！",
                        },
                        model: {
                          value: _vm.text.text,
                          callback: function ($$v) {
                            _vm.$set(_vm.text, "text", $$v)
                          },
                          expression: "text.text",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.submitFormLoading },
                  on: { click: _vm.addTextSubmit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }