/*
 * @Description: Do not edit
 * @Date: 2021-11-10 19:20:56
 * @LastEditTime: 2022-04-01 09:24:06
 */
export let searchForm = {
  pageName: null,
  siteId: null,
  campaignAccount: null,
  displayId: null
}

export class AssetManagementHugeThirdLandingPageEnt {
  thirdLandingPageId
  auditStatus
  createTimeApi
  name
  siteId
  thumbnail
  url
  relationHousekeeperName
  relationHousekeeperId
  relationAdvAccountName
  relationAdvId
  deleted
  createBy
  createTime
  updateBy
  updateTime
  remark
  requestId
}

export let formRules = {
  thirdLandingPageId: [{ required: true, message: '请填写', trigger: 'change' }],
  auditStatus: [{ required: true, message: '请填写站点审核状态', trigger: 'change' }],
  createTimeApi: [{ required: true, message: '请填写站点创建时间', trigger: 'change' }],
  name: [{ required: true, message: '请填写站点名称', trigger: 'change' }],
  siteId: [{ required: true, message: '请填写站点id', trigger: 'change' }],
  thumbnail: [{ required: true, message: '请填写缩略图地址', trigger: 'change' }],
  url: [{ required: true, message: '请填写站点预览地址', trigger: 'change' }],
  relationHousekeeperName: [{ required: true, message: '请填写关联管家账号名称', trigger: 'change' }],
  relationHousekeeperId: [{ required: true, message: '请填写关联管家账号id', trigger: 'change' }],
  relationAdvAccountName: [{ required: true, message: '请填写关联投放账户名称', trigger: 'change' }],
  relationAdvId: [{ required: true, message: '请填写关联投放账户id', trigger: 'change' }],
  deleted: [{ required: true, message: '请填写逻辑删除', trigger: 'change' }],
  createBy: [{ required: true, message: '请填写创建者', trigger: 'change' }],
  createTime: [{ required: true, message: '请填写创建时间', trigger: 'change' }],
  updateBy: [{ required: true, message: '请填写更新者', trigger: 'change' }],
  updateTime: [{ required: true, message: '请填写更新时间', trigger: 'change' }],
  remark: [{ required: true, message: '请填写备注', trigger: 'change' }],
  requestId: [{ required: true, message: '请填写请求的日志id', trigger: 'change' }]
}
