var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "search",
          staticClass: "search-form search-form-4",
          attrs: { inline: true, model: _vm.search },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "任务名称", prop: "name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请输入任务名称" },
                        model: {
                          value: _vm.search.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "name", $$v)
                          },
                          expression: "search.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "创建人", prop: "createBy" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请输入创建人" },
                        model: {
                          value: _vm.search.createBy,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "createBy", $$v)
                          },
                          expression: "search.createBy",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "创建时间", prop: "createTime" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "daterange",
                          "prange-separator": "至",
                          "start-placeholder": "开始时间",
                          "value-format": "yyyy-MM-dd",
                          "end-placeholder": "结束时间",
                        },
                        model: {
                          value: _vm.search.createTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "createTime", $$v)
                          },
                          expression: "search.createTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "任务状态", prop: "status" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.search.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "status", $$v)
                            },
                            expression: "search.status",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: "" },
                          }),
                          _c("el-option", {
                            attrs: { label: "配置中", value: 2 },
                          }),
                          _c("el-option", {
                            attrs: { label: "待提交", value: 3 },
                          }),
                          _c("el-option", {
                            attrs: { label: "提交中", value: 4 },
                          }),
                          _c("el-option", {
                            attrs: { label: "提交完成", value: 5 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", round: "" },
                          on: { click: _vm.onSubmit },
                        },
                        [_vm._v("查 询")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", round: "" },
                          on: {
                            click: function ($event) {
                              return _vm.resetForm("ruleForm")
                            },
                          },
                        },
                        [_vm._v("重 置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mb-15", staticStyle: { "text-align": "left" } },
        [
          _c(
            "router-link",
            { attrs: { to: { name: "creatVideoTask" }, tag: "span" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary", icon: "el-icon-plus", round: "" } },
                [_vm._v(" 新建任务 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.list.loading,
                  expression: "list.loading",
                },
              ],
              staticClass: "list-table",
              class: { "no-data": !_vm.list.data || !_vm.list.data.length },
              staticStyle: { width: "100%" },
              attrs: { data: _vm.list.data, stripe: "" },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "序号", width: "50" },
              }),
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "任务名称" },
              }),
              _c("el-table-column", {
                attrs: { prop: "createBy", label: "创建人" },
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "创建时间" },
              }),
              _c("el-table-column", {
                attrs: { prop: "", label: "数量" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [_c("span", [_vm._v(_vm._s(row.planCount))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "", width: "180", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("div", { staticClass: "status" }, [
                          _c(
                            "p",
                            {
                              staticStyle: {
                                "font-size": "11px",
                                "font-weight": "400",
                              },
                              style: {
                                color: [0, 5].includes(row.status)
                                  ? row.planFailCount == 0
                                    ? "green"
                                    : "red"
                                  : "",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.statusList[row.status]))]
                          ),
                          [0, 5].includes(row.status)
                            ? _c(
                                "div",
                                { staticClass: "status-num" },
                                [
                                  _c(
                                    "p",
                                    { staticStyle: { "font-size": "11px" } },
                                    [
                                      _vm._v(" 成功："),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "green" } },
                                        [_vm._v(_vm._s(row.planSuccessCount))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: row.failMsg,
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticStyle: { "font-size": "11px" },
                                        },
                                        [
                                          _vm._v(" 失败："),
                                          _c(
                                            "span",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v(_vm._s(row.planFailCount))]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.status == 2 || row.status == 3
                          ? [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleGoEdit(row)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: "继续",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-edit",
                                        staticStyle: { "font-size": "16px" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "red",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteTask(row.id)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: "删除",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-delete",
                                        staticStyle: { "font-size": "16px" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        row.status == 4 || row.status == 5
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.detailTask(row)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "详情",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-document",
                                      staticStyle: { "font-size": "16px" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "page" },
            [
              _c("paginations", {
                attrs: {
                  total: _vm.list.total,
                  page: _vm.list.pageNum,
                  "page-sizes": [5, 10, 15, 20],
                  limit: _vm.list.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.list, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.list, "pageSize", $event)
                  },
                  pagination: _vm.getPage,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "视频任务详情",
            visible: _vm.descVisible,
            width: "800px",
            "modal-append-to-body": false,
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.descVisible = $event
            },
            close: _vm.handleCloseDetail,
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-descriptions",
                { attrs: { column: 2 } },
                [
                  _c("el-descriptions-item", { attrs: { label: "任务名称" } }, [
                    _vm._v(_vm._s(_vm.videoDetails.name)),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "创建人" } }, [
                    _vm._v(_vm._s(_vm.videoDetails.createBy)),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "创建时间" } }, [
                    _vm._v(_vm._s(_vm.videoDetails.createTime)),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "存储路径" } }, [
                    _vm._v(
                      _vm._s(_vm.videoDetails.albumName) +
                        " - " +
                        _vm._s(_vm.videoDetails.materialGroupName) +
                        " "
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "margin-top": "20px", "text-align": "right" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.downloading,
                        size: "mini",
                      },
                      on: { click: _vm.handleDownloadAll },
                    },
                    [_vm._v("全部下载")]
                  ),
                  _vm.videoDetails.status < 5
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.syncLoading,
                            size: "mini",
                          },
                          on: { click: _vm.handleSyncConfigTask },
                        },
                        [_vm._v("同步状态")]
                      )
                    : _vm._e(),
                  _c("el-button", {
                    attrs: {
                      icon: "el-icon-refresh",
                      size: "mini",
                      circle: "",
                    },
                    on: { click: _vm.getDetailPage },
                  }),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.details.loading,
                      expression: "details.loading",
                    },
                  ],
                  staticClass: "list-table",
                  class: {
                    "no-data": !_vm.details.data || !_vm.details.data.length,
                  },
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.details.data, stripe: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", label: "序号", width: "50" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "", label: "状态", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content: row.failMsg,
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { class: ["status", "status-" + row.status] },
                                  [_vm._v(_vm._s(_vm.detailStatus[row.status]))]
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "url", label: "预览", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.videoResultUrl
                              ? _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "right",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "element-pop-img" },
                                      [
                                        _c("video", {
                                          attrs: {
                                            src: row.videoResultUrl,
                                            controls: "",
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "element-img",
                                        attrs: { slot: "reference" },
                                        slot: "reference",
                                      },
                                      [
                                        _c("video", {
                                          attrs: { src: row.videoResultUrl },
                                        }),
                                      ]
                                    ),
                                  ]
                                )
                              : _c("div", [_vm._v("-")]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "", label: "操作", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.status != 3
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "red",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleSyncTaskStatus(row)
                                      },
                                    },
                                  },
                                  [_vm._v("同步状态")]
                                )
                              : _vm._e(),
                            row.status == 4
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "red",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleReSubmit(row)
                                      },
                                    },
                                  },
                                  [_vm._v("重新执行")]
                                )
                              : _vm._e(),
                            row.status == 3
                              ? _c(
                                  "el-button",
                                  {
                                    staticClass: "red",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDownloadVideo(row)
                                      },
                                    },
                                  },
                                  [_vm._v("下载至本地 ")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "page" },
                [
                  _c("paginations", {
                    attrs: {
                      total: _vm.details.total,
                      page: _vm.details.pageNum,
                      "page-sizes": [5, 10, 15, 20],
                      limit: _vm.details.pageSize,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.details, "pageNum", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.details, "pageSize", $event)
                      },
                      pagination: _vm.getDetailPage,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }