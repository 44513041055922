export const searchForm = {
  publisBaiduId: null,
  templatePlanName: null,
  launchPlatform: null,
  housekeeperId: null,
  launchAccountName: null,
  baiduId: null,
  accountName: null,
  publishStatus: null,
  launcheStatus: null,
  planId: null,
  unitId: null,
  creativeId: null,
  deleted: null,
  createBy: null,
  createTime: null,
  updateBy: null,
  updateTime: null,
  remark: null
}

export class AdvManagementBaiduPublishEnt {
  publisBaiduId
  templatePlanName
  launchPlatform
  housekeeperId
  launchAccountName
  baiduId
  accountName
  publishStatus
  launcheStatus
  planId
  unitId
  creativeId
  deleted
  createBy
  createTime
  updateBy
  updateTime
  remark
}

export const formRules = {
  publisBaiduId: [{ required: true, message: '请填写', trigger: 'change' }],
  templatePlanName: [{ required: true, message: '请填写模板计划名称', trigger: 'change' }],
  launchPlatform: [{ required: true, message: '请填写投放平台', trigger: 'change' }],
  housekeeperId: [{ required: true, message: '请填写', trigger: 'change' }],
  launchAccountName: [{ required: true, message: '请填写账户名称', trigger: 'change' }],
  baiduId: [{ required: true, message: '请填写', trigger: 'change' }],
  accountName: [{ required: true, message: '请填写账号名称', trigger: 'change' }],
  publishStatus: [{ required: true, message: '请填写发布状态', trigger: 'change' }],
  launcheStatus: [{ required: true, message: '请填写投放状态', trigger: 'change' }],
  planId: [{ required: true, message: '请填写计划id', trigger: 'change' }],
  unitId: [{ required: true, message: '请填写单元id', trigger: 'change' }],
  creativeId: [{ required: true, message: '请填写创意id', trigger: 'change' }],
  deleted: [{ required: true, message: '请填写逻辑删除', trigger: 'change' }],
  createBy: [{ required: true, message: '请填写创建者', trigger: 'change' }],
  createTime: [{ required: true, message: '请填写创建时间', trigger: 'change' }],
  updateBy: [{ required: true, message: '请填写更新者', trigger: 'change' }],
  updateTime: [{ required: true, message: '请填写更新时间', trigger: 'change' }],
  remark: [{ required: true, message: '请填写备注', trigger: 'change' }]
}
