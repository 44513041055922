<template>
  <!--元素类型弹框-->
  <el-dialog class="element-library-box" title="新增元素" :visible.sync="show" :append-to-body="true" width="600px"
    top="20vh" @close="onCloseDialog">
    <div class="page-container">
      <el-form ref="form" :inline="false" label-width="100px" :model="form" :rules="rules">
        <el-form-item label="元素名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入元素名称" maxlength="15" show-word-limit style="width: 100%">
          </el-input>
        </el-form-item>
        <el-form-item label="元素类型" prop="typeId">
          <el-select v-model="form.typeId" style="width: 100%" placeholder="请选择元素类型" @change="handleSelectType">
            <el-option v-for="item in elementTypes" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <!-- 编辑不展示 -->
        <template v-if="!form.id">
          <el-form-item label="基础类型" prop="baseType">
            <el-select v-model="form.baseType" style="width: 100%" placeholder="请选择基础类型">
              <el-option v-for="item in baseTypes" :key="item.key" :label="item.value" :value="item.key"></el-option>
            </el-select>
          </el-form-item>

          <!-- 图片 -->
          <el-form-item v-if="form.baseType === 'IMG'">
            <template slot="label">
              <div><span style="color: #F56C6C">*</span> 图片({{ images.length }}/{{ FILE_NUM }})</div>
            </template>
            <div class="form-image-list">
              <div v-for="(item, index) in images" :key="index">
                <div class="form-image-list-cell">
                  <img :src="item.url" alt="" />
                  <div class="form-image-list-cell-del" @click="handleDeleteImage(index)">删除</div>
                </div>
              </div>
              <el-upload class="form-image-upload" accept=".png,.jpg,.jpeg,.gif" action="fakeaction"
                list-type="picture-card" :multiple="true" :show-file-list="false" :http-request="uploadImage">
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
            <div class="tips">格式：png、jpg、jpeg、gif</div>
          </el-form-item>

          <!-- 视频 -->
          <el-form-item v-if="form.baseType === 'VIDEO'">
            <template slot="label">
              <div><span style="color: #F56C6C">*</span> 视频({{ videos.length }}/{{ FILE_NUM }})</div>
            </template>
            <div class="form-image-list">
              <div v-for="(item, index) in videos" :key="index">
                <div class="form-image-list-cell">
                  <video :src="item.url"></video>
                  <div class="form-image-list-cell-del" @click="handleDeleteVideo(index)">删除</div>
                </div>
              </div>
              <el-progress v-show="getIsShow()" :percentage="schedule" class="justify-center1" style="width: 150px"
                :status="schedule == 100 ? 'success' : 'warning'"></el-progress>
              <el-upload v-if="videos.length < FILE_NUM" id="uploa-video" :disabled="getIsShow()"
                class="form-image-upload" action="fakeaction" accept=".mp4,.avi,.mov" list-type="picture-card"
                :before-upload="beforeUploadVideo" :multiple="false" :show-file-list="false"
                :http-request="uploadVideo">
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
            <div class="tips">格式：mp4、avi、mov</div>
          </el-form-item>
        </template>
      </el-form>
    </div>
    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">取消</el-button>
      <el-button class="btn" type="primary" :disabled="loading" @click="handleConfirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getElementType, addElement, updateElement } from '@/network/api/toolManagement/api-tool'
import SparkMD5 from 'spark-md5'
import oss from '@/mixin/oss'
import { uploadMaterial } from '@/network/api/api-material'

export default {
  name: 'ElementLibraryDialog',
  mixins: [oss],
  props: {},
  data() {
    return {
      FILE_NUM: 5,
      show: false,
      loading: false,
      form: {
        name: '',
        typeId: '',
        typeName: '',
        baseType: 'VIDEO'
      },
      videos: [],
      images: [],
      rules: {
        name: [{ required: true, message: '请输入元素名称', trigger: 'blur' }],
        typeId: [{ required: true, message: '请选择元素类型', trigger: 'change' }],
        baseType: [{ required: true, message: '请选择基础类型', trigger: 'change' }]
      },
      baseTypes: [{ key: 'IMG', value: '图片' }, { key: 'VIDEO', value: '视频' }],
      elementTypes: []
    }
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {
    /* --------------------------- Actions --------------------------- */
    showModal(data) {
      if (Object.keys(data).length) {
        this.form = {
          ...data
        }
      }
      this.show = true
      this.getElementType()
    },
    // 点击确定按钮
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return
        }
        let { id, name, typeId, typeName, baseType, url } = this.form
        let params = {
          name,
          typeId,
          typeName,
          baseType
        }
        this.loading = true
        if (id) {
          params.id = id
          params.url = url || ''
          updateElement(params).then(({ code }) => {
            if (code === 200) {
              this.$SuccessMessage('编辑成功')
              this.$emit('confirm')
              this.closeModal()
            }
          }).finally(() => {
            this.loading = false
          })
        } else {
          let field = baseType === 'IMG' ? 'images' : 'videos'
          let arr = this[field].map(v => v.url)
          if (!arr.length) {
            this.$message.warning('请上传文件')
            return
          }
          params.urls = arr
          addElement(params).then(({ code }) => {
            if (code === 200) {
              this.$message.success('添加成功')
              this.$emit('confirm')
              this.closeModal()
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      // 重置表单
      this.form = {
        name: '',
        typeId: '',
        typeName: '',
        baseType: 'IMG'
      }
      this.images = []
      this.videos = []
      setTimeout(() => {
        this.$refs.form.clearValidate()
      }, 100)
    },
    // 关闭弹框
    closeModal() {
      this.show = false
    },

    // 获取元素类型
    getElementType() {
      getElementType({ name: '' }).then(({ code, data }) => {
        if (code === 200) {
          this.elementTypes = data
        }
      })
    },

    // 上传前回调
    beforeUploadVideo(file) {
      if (['video/mp4', 'video/avi', 'video/mov'].indexOf(file.type) === -1) {
        this.$message.warning('请上传正确的文件格式')
        return false
      }
    },

    // 图片上传
    uploadImage(params) {
      if (this.images.length > this.FILE_NUM) {
        this.$message.error(`上传图片不能超过${this.FILE_NUM}张!`)
        return
      }
      const file = params.file
      const fileType = file.type
      const isImage = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'].includes(fileType)
      // const isSize = file.size / 1024 > 1000
      if (!isImage) {
        this.$message.warning('只能上传图片格式png、jpg、gif, jpeg!')
        return
      }
      // if (!isSize) {
      //   this.$message.error(`上传图片大小不能超过 1000M!`)
      //   return
      // }
      // 根据后台需求数据格式
      const form = new FormData()
      // 文件对象
      form.append('file', file)
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append('name', file.name)
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      // form.append('name', file.name)
      uploadMaterial(form)
        .then((res) => {
          this.images.push({
            originName: res.data.originName,
            width: res.data.width,
            height: res.data.height,
            size: res.data.size,
            url: res.data.url,
            hash: res.data.hashCode
          })
        })
        .catch(() => {
          this.$message.error('图片上传失败')
        })
        .finally(() => { })
    },
    // 视频上传
    uploadVideo(params) {
      if (this.videos.length > this.FILE_NUM) {
        this.$message.error(`上传视频不能超过${this.FILE_NUM}个!`)
        return
      }
      const file = params.file

      const form = new FormData()
      // 文件对象
      form.append('file', file)
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      // form.append('name', file.name)
      // 获取上传的视频的宽高
      let videoWidth = 0
      let videoHeight = 0
      const url = URL.createObjectURL(file)
      const video = document.createElement('video')

      video.onloadedmetadata = (evt) => {
        URL.revokeObjectURL(url)
        videoWidth = video.videoWidth
        videoHeight = video.videoHeight
      }
      video.onloadeddata = (evt) => {
        this.getFileHash(file).then((val) => {
          this.loading = true
          this.ossUpload(file, {
            width: videoWidth,
            size: file.size,
            hash: val.HASH,
            height: videoHeight
          }, (val) => {
            if (val.width !== videoWidth) {
              val.width = videoWidth
            }
            if (val.height !== videoHeight) {
              val.height = videoHeight
            }
            this.videos.push(val)
            this.loading = false
          }, (er) => {
            this.loading = false
            this.$message.error(er)
          })
        })
      }
      video.src = url
      video.load()
      // 获取文件hash值以及后缀名
    },
    getFileHash(file) {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)
        reader.onload = ev => {
          let buffer = ev.target.result
          let spark = new SparkMD5.ArrayBuffer()
          let HASH
          let suffix
          spark.append(buffer)
          HASH = spark.end()
          suffix = file.name.substring(file.name.lastIndexOf('.') + 1)
          resolve({
            buffer,
            HASH,
            suffix,
            filename: `${HASH}.${suffix}`
          })
        }
      })
    },

    // 选择元素类型
    handleSelectType(value) {
      if (value) {
        let item = this.elementTypes.find(v => v.id === value)
        if (item) {
          this.form.typeName = item.name
        }
        if ([1, 2].includes(value)) {
          this.baseTypes = [{ key: 'VIDEO', value: '视频' }]
          this.form.baseType = 'VIDEO'
        } else {
          this.baseTypes = [{ key: 'IMG', value: '图片' }, { key: 'VIDEO', value: '视频' }]
          this.form.baseType = 'IMG'
        }
      }
    },

    // 删除图片
    handleDeleteImage(index) {
      this.images.splice(index, 1)
    },

    // 删除视频
    handleDeleteVideo(index) {
      this.videos.splice(index, 1)
    }

    /* --------------------------- Private --------------------------- */
  }
}
</script>

<style lang="scss" scoped>
.tips {
  font-size: 12px;
  color: #999999;
}

.justify-center1 {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

::v-deep .form-image-upload .el-upload--picture-card {
  position: relative;
  padding: 0 !important;
  width: 100px !important;
  height: 100px !important;
  margin-right: 3px !important;

  .el-icon-plus {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>

<style scoped lang="scss">
.form-image-list {
  display: flex;
  flex-flow: row wrap;

  .form-image-list-cell {
    position: relative;
    border: 1px solid #ccc;
    width: 100px;
    height: 100px;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 10px;
    overflow: hidden;

    // margin-left: 3px;
    // margin-top: 3px;
    img {
      width: 100px;
      height: 100px;
    }

    video {
      width: 100px;
      height: 100px;
    }

    .form-image-list-cell-del {
      color: #000;
      position: absolute;
      left: 40%;
      bottom: 0;
      width: 40px;
      height: 25px;
      line-height: 25px;
      border-radius: 4px;
      box-sizing: border-box;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      margin-right: 14px;
      background: #eeecf2;
      cursor: pointer;
      transition: 0.2s;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        background: $themeColor;
        color: white;
      }
    }
  }
}
</style>
