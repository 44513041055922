<template>
  <!--显示列弹框-->
  <el-dialog :visible.sync="show" title="监测链接" :append-to-body="true" width="800px" top="20vh">
    <div class="tools-box">
      <el-button type="text" @click="handleClickUrl">获取链接</el-button>
    </div>
    <el-form ref="form" :rules="rules" label-position="left" :model="form" label-width="130px">
      <el-form-item label="链接分配" prop="type">
        <el-radio-group v-model="form.type">
          <el-radio :label="1">全部一致</el-radio>
          <el-radio :label="2">单户配置</el-radio>
        </el-radio-group>
      </el-form-item>
      <div v-if="form.type == 1">
        <el-form-item label="有效触点链接" prop="allTrack[0].trackUrl" :rules="[
          { required: true, trigger: 'blur', message: '请输入触点链接' },
          { validator: validateCheckFn, trigger: 'blur' }
        ]">
          <el-popover placement="bottom" title="" width="400" trigger="click">
            <el-input slot="reference" v-model="form.allTrack[0].trackUrl" type="text" placeholder="请输入有效触点链接"
              style="width: 280px;margin-right: 10px" />
            <div class="track-options-box">
              <div class="track-option-header">
                <span>监测链接</span>
                <span>备注</span>
              </div>
              <div v-if="!adviseData.length" class="track-option-empty">暂无数据</div>
              <div v-else class="track-option-item" v-for="(item, index) in adviseData" :key="index"
                @click="handleSelectRemark(item, -1)">
                <el-tooltip class="item" effect="dark" :content="item.trackUrl" placement="top">
                  <div class="name">{{ item.trackUrl }}</div>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" :content="item.remarks" placement="top">
                  <div class="addr">{{ item.remarks }}</div>
                </el-tooltip>
              </div>
            </div>
          </el-popover>
          <el-input v-model="form.allTrack[0].remarks" type="text" placeholder="备注，非必填" :maxlength="15" show-word-limit
            style="width: 180px" />
        </el-form-item>
      </div>
      <div v-if="form.type == 2">
        <div v-for="(item, index) in form.tracks" :key="index">
          <div class="track-title">{{ item.name || '-' }}</div>
          <el-form-item label="有效触点链接" :prop="'tracks.' + index + '.trackUrl'" :rules="[
            { required: true, trigger: 'change', message: '请输入触点链接' },
            { validator: validateCheckFn, trigger: 'blur' }
          ]">
            <el-popover placement="bottom" title="" width="400" trigger="hover">
              <el-input slot="reference" v-model="form.tracks[index].trackUrl" type="text" placeholder="请输入有效触点链接"
                style="width: 280px;margin-right: 10px" />
              <div class="track-options-box">
                <div class="track-option-header">
                  <span>监测链接</span>
                  <span>备注</span>
                </div>
                <div v-if="!adviseData.length" class="track-option-empty">暂无数据</div>
                <div v-else class="track-option-item" v-for="(v, i) in adviseData" :key="i"
                  @click="handleSelectRemark(v, index)">
                  <el-tooltip class="item" effect="dark" :content="v.trackUrl" placement="top">
                    <div class="name">{{ v.trackUrl }}</div>
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" :content="v.remarks" placement="top">
                    <div class="addr">{{ v.remarks }}</div>
                  </el-tooltip>
                </div>
              </div>
            </el-popover>
            <el-input v-model="form.tracks[index].remarks" type="text" placeholder="备注，非必填" :maxlength="15"
              show-word-limit style="width: 180px" />
          </el-form-item>
        </div>
      </div>
    </el-form>

    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">取 消</el-button>
      <el-button class="btn" type="primary" :loading="isLoading" :disabled="isLoading" @click="clickConfirm('form')">确 定
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { saveConfigTraceUrl, getTraceUrlHistory } from '@/network/api/api-huge-batch'
import { pathRegValidator } from '@/common/utils/validate'
export default {
  name: 'TraceUrlDialog',
  props: {
    baseId: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    let validateCheckPath = (rule, value, callback) => {
      if (!pathRegValidator(value)) {
        return callback(new Error('链接格式不正确'))
      } else {
        return callback()
      }
    }
    return {
      show: false, // 是否显示弹框
      isLoading: false,
      advertisers: [], // 投放账户
      adviseData: [],
      form: {},
      rules: {
        type: [{ required: true, message: '请选择链接分配', trigger: 'change' }],
        trackUrl: [{ required: true, message: '请输入有效触点链接', trigger: 'blur' }, {
          validator: validateCheckPath, trigger: 'blur'
        }]
      }
    }
  },
  watch: {},

  methods: {
    validateCheckFn(rule, value, callback) {
      if (value && !pathRegValidator(value)) {
        callback(new Error('链接格式不正确'))
      } else {
        callback()
      }
    },
    /* --------------------------- Actions --------------------------- */
    // 显示弹框
    showModal(advertisers, rule) {
      this.advertisers = advertisers
      const objItem = {
        trackUrl: '',
        name: '',
        advertiserId: '',
        remarks: ''
      }
      let tracks = advertisers.map(v => {
        return {
          trackUrl: '',
          name: v.name,
          advertiserId: v.advertiserId,
          remarks: ''
        }
      })
      if (rule && rule.length) {
        let type = +rule[0].type
        const allTrack = type === 1 ? [{ ...rule[0] }] : [objItem]
        let singleTrack = rule.map(v => {
          let item = advertisers.find(t => +t.advertiserId === +v.advertiserId)
          return {
            ...v,
            name: item ? item.name : ''
          }
        })
        const track = type === 2 ? [...singleTrack] : tracks
        this.form = {
          type: type,
          tracks: track,
          allTrack: allTrack
        }
      } else {
        this.form = {
          type: 1,
          allTrack: [objItem],
          tracks: [...tracks]
        }
      }

      this._getUrlHistory()

      this.show = true
    },
    // 关闭弹框
    closeModal() {
      this.$refs.form.clearValidate()
      this.show = false
    },

    // 点击确定按钮
    clickConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const { type, tracks, allTrack } = this.form
          let arr = type === 1 ? allTrack : tracks
          let data = arr.map(v => {
            return {
              advertiserId: v.advertiserId || '',
              baseId: this.baseId,
              id: v.id || '',
              trackUrl: v.trackUrl,
              remarks: v.remarks || '',
              type: type
            }
          })
          this.isLoading = true
          saveConfigTraceUrl(data).then(res => {
            if (res.code === 200) {
              this.$emit('confirm', res.data)
              this.closeModal()
            }
          }).finally(() => {
            this.isLoading = false
          })
        }
      })
    },

    // 点击获取链接
    handleClickUrl() {
      window.open('https://dkrsvba2zy.feishu.cn/docs/doccnkOZXUL3TBqzQObvrIeJI2E')
    },

    // 获取监测链接历史记录
    _getUrlHistory() {
      getTraceUrlHistory().then(({ code, data }) => {
        if (code === 200) {
          this.adviseData = data || []
        }
      })
    },

    // 选中建议
    handleSelectRemark(value, index) {
      if (index >= 0) {
        this.form.tracks[index].trackUrl = value.trackUrl
        this.$refs.form.clearValidate('tracks.' + index + '.trackUrl')
        this.form.tracks[index].remarks = value.remarks
      } else {
        this.form.allTrack[0].trackUrl = value.trackUrl
        this.$refs.form.clearValidate('allTrack[0].trackUrl')
        this.form.allTrack[0].remarks = value.remarks
      }
    }
    /* --------------------------- Private --------------------------- */
  }
}
</script>

<style scoped lang="scss">
.tools-box {
  position: absolute;
  text-align: right;
  right: 100px;
  z-index: 1;
}

.track-title {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  color: #333;
  font-weight: 500;
  margin-bottom: 5px;
}

.track-options-box {
  .track-option-header{
    display: flex;
    margin-bottom: 10px;
    span{
      text-align: center;
      font-size: 16px;
      color: #333;
      font-weight: 500;
      &:first-child{
        width: 270px;
      }
      &:last-child{
        flex: 1;
      }
    }
  }

  .track-option-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    padding: 0 5px;
    box-sizing: border-box;
    min-height: 60px;

    .name {
      width: 270px;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      padding-right: 20px;
    }

    .addr {
      flex: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &:hover {
      background-color: #f5f7fa;
    }
  }

  .track-option-empty{
      color: #999;
      text-align: center;
      width: 100%;
      padding: 10px 0;
    }
}
</style>
