<!-- search -->
<template>

  <el-form ref="searchForm" class="search-form search-form-4" :model="searchForm" :inline="true">
    <el-row :gutter="20">
      <el-col :span="6">
        <el-form-item prop="createBy" label="上传人">
          <el-input v-model="searchForm.createBy" placeholder="请输入上传人" />
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-form-item prop="createTime" label="上传时间">
          <el-date-picker v-model="searchForm.createTime" type="date" placeholder="请选择"></el-date-picker>
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-form-item prop="status" label="上传状态">
          <el-select v-model="searchForm.status" placeholder="请输入关联项目">
            <el-option label="全部" value="all" />
            <el-option label="提交中" value="submitting" />
            <el-option label="提交完成" value="submitted" />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-button class="btn" type="primary" @click="search">
          查询
        </el-button>
        <el-button class="btn" @click="resetSearch">
          重置
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import { searchForm } from './object'
export default {
  inject: ['loadData'],
  name: '',
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    return {
      searchForm: { ...searchForm }

    }
  },

  // 方法集合
  methods: {
    // 折中方案
    getSearchForm() {
      return { ...this.searchForm }
    },
    search() {
      this.loadData(true)
    },
    resetSearch() {
      this.$refs.searchForm.resetFields()
      this.loadData(true)
    }
  } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
/* @import url(); 引入公共css类 */

</style>
