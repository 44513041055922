<!--
 * @Description: 创意基础信息
 * @Date: 2021-12-06 15:57:16
 * @LastEditTime: 2022-04-09 15:20:25
-->
<template>
  <div style="height:300px">
    <ContentBox v-if="formData.flag" :data-props="creativityDiv" />
    <div v-else class="kong">请选择计划</div>
    <el-dialog title="创意基础信息" :visible="visible" width="650px" :modal-append-to-body="false" destroy-on-close @close="beforeClose">
      <span>
        <el-form ref="creativityForm" :model="creativityForm" label-width="120px">
          <component :is="creativityComponentsList[crtSizeLabel]" :rule-form-data="creativityForm" :project-id="projectId" :promoted-object-type="promotedObjectType" @pageTypeChange="pageTypeChange"></component>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeCreativity">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { creativeType, creativityComponentsList, materialTypeList } from '../util'
import { saveCreative, updateCreative, saveImgByVideo } from '../../../../../network/api/advManagement/advManagementWatch'
import ContentBox from './contentBox.vue'
// 创意类型
import MomentsInfo from './creativity/moments-info.vue'
import centerInfo from './creativity/center-info.vue'
import formWeappBanner from './creativity/form-weapp-banner.vue'
import horizonTalImageInfo from './creativity/horizonTal-image-info.vue'
import quietVideoInfo from './creativity/quiet-video-info.vue'
import weappBanner from './creativity/weapp-banner.vue'
import formWeappBannerImage from './creativity/form-weapp-banner-image.vue'
import formWeappBannerVideo from './creativity/form-weapp-banner-video.vue'

export default {
  components: {
    ContentBox,
    'moments-info': MomentsInfo,
    'center-info': centerInfo,
    'form-weapp-banner': formWeappBanner,
    'horizonTal-image-info': horizonTalImageInfo,
    'quiet-video-info': quietVideoInfo,
    'weapp-banner': weappBanner,
    'form-weapp-banner-image': formWeappBannerImage,
    'form-weapp-banner-video': formWeappBannerVideo
  },
  inject: ['closeCreativity'],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    /** 广告位置 */
    crtSizeLabel: {
      type: String,
      default: ''
    },
    formData: {
      type: Object,
      default: () => {}
    },
    /** 推广目标 */
    promotedObjectType: {
      type: String,
      default: ''
    },
    /** 项目Id */
    projectId: {
      type: String,
      default: ''
    },
    /** 任务Id */
    taskId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      creativityComponentsList,
      creativeType,
      creativityForm: {}
    }
  },

  watch: {
    formData: {
      handler(value) {
        if (value.buttonParam === null) {
          if (this.promotedObjectType === 'PRODUCTTYPE_WECHAT') {
            value.buttonParam = '关注公众号'
          } else {
            value.buttonParam = '查看详情'
          }
        }
        this.creativityForm = value
        var assetTypes = '常规单图'
        switch (value.assetType) {
          case 3:
            assetTypes = '常规三图'
            break
          case 4:
            assetTypes = '常规四图'
            break
          case 6:
            assetTypes = '常规六图'
            break
        }
        this.creativityDiv = [
          { key: '广告样式', value: this.crtSizeLabel === '朋友圈' ? '常规广告' : null },
          { key: '素材类型', value: this.crtSizeLabel === '朋友圈' ? assetTypes : null },
          { key: '创意样式', value: materialTypeList[value.crtSize] || null },
          { key: '信息栏类型', value: this.crtSizeLabel === '朋友圈' ? null : '文案型' },
          { key: this.crtSizeLabel === '朋友圈' ? '外层素材跳转：' : '点击跳转', value: value.pageType ? (value.pageType === 4 ? '原生推广页' : '公众号详情页') : null },
          { key: '点击按钮跳转', value: value.buttonPageType ? (value.buttonPageType === 4 ? '原生推广页' : '一键关注') : null },
          { key: '文字链跳转：', value: this.crtSizeLabel === '朋友圈' ? (value.linkPageType === 4 ? '原生推广页' : '公众号详情页') : null },
          { key: '按钮文案', value: this.crtSizeLabel === '朋友圈' ? null : value.buttonParam || null },
          { key: '标签展示', value: value.snsDynamicShowSwitch ? '启用' : '不启用' }
        ]
      },
      immediate: true
    }
  },

  methods: {
    pageTypeChange(e) {
      if (e === 4) {
        this.creativityForm.buttonPageType = 4
      }
    },

    onSubmit() {
      this.$refs['creativityForm'].validate(async (valid) => {
        if (valid) {
          const creativityForm = this.creativityForm
          if (this.crtSizeLabel === '沉浸式横版视频' && creativityForm.pageTopImgs && creativityForm.pageTopImgs.length) {
            let adMpManagementTaskImgVideoENTList = []
            creativityForm.pageTopImgs.map((item) => {
              if (item.imgVideoId) return
              adMpManagementTaskImgVideoENTList.push({
                localAssetId: item.imageId || item.localAssetId,
                localAssetUrl: item.image || item.localAssetUrl,
                localAssetMd: item.hash || item.localAssetMd,
                type: 'img',
                taskId: this.taskId
              })
            })
            if (adMpManagementTaskImgVideoENTList.length) {
              await saveImgByVideo({ adMpManagementTaskImgVideoENTList })
            }
          }
          let params = { ...creativityForm, taskMaterialId: this.formData.taskMaterialId || null, taskId: this.taskId }
          // 点击跳转为公众号详情页时, 点击按钮跳转不展示(buttonPageType = 0)
          if (params.pageType === 6) {
            params.buttonPageType = 0
          }
          // 沉浸式视频 结束页关闭时, 结束页类型为null
          // if (!params.endPageFlag) {
          //   params.endPageType = null
          // }
          if (this.formData.taskMaterialId) {
            const { code } = await updateCreative(params)
            if (code === 200) {
              this.$message.success('创意编辑成功')
            }
          } else {
            const { code } = await saveCreative(params)
            if (code === 200) {
              this.$message.success('创意添加成功')
            }
          }
          this.$emit('setCreativityBaseInfo')
          this.closeCreativity()
        }
      })
    },

    beforeClose() {
      this.$emit('byTaskIdGetAllFun')
      this.closeCreativity()
    }
  }
}
</script>

<style lang="scss"></style>
