<template>
  <div>
    <Search ref="search" />

    <el-row type="flex" justify="space-between" class="operate-box">
      <el-col :span="10">
        <el-dropdown @command="showOpts">
          <el-button type="primary"> 批量操作<i class="el-icon-arrow-down el-icon--right"></i> </el-button>
          <el-dropdown-menu slot="dropdown">
            <!-- :disabled=""  -->
            <el-dropdown-item command="project" :disabled="displayUpdateProject">修改项目</el-dropdown-item>
            <el-dropdown-item command="product" :disabled="displayUpdateProject">修改产品</el-dropdown-item>
            <el-dropdown-item command="people" :disabled="displayUpdateProject">修改人员</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
      <el-button type="primary" round @click="addAd">新增广告主 </el-button>
    </el-row>

    <div class="table-container">
      <el-table v-loading="loading" stripe class="list-table" :data="data" @selection-change="handleSelectionChange">
        <c-data-empty slot="empty" />
        <el-table-column type="selection" />
        <el-table-column type="index" label="序号" width="80" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="masterUid" label="账户ID" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="masterName" label="账户名称" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="oauthMasterName" label="关联账户管家" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="projectName" label="关联项目" align="left" :show-overflow-tooltip="true" :formatter="formatterUnderf" />
        <el-table-column prop="launchProductsNames" label="关联产品" align="left" :show-overflow-tooltip="true" :formatter="formatterUnderf" />

        <el-table-column prop="userName" label="投放人员" align="left" :show-overflow-tooltip="true" :formatter="formatterUnderf" />
        <el-table-column prop="putInStatus" label="投放状态" align="left" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.putInStatus === 1? 'success' : 'danger'"
              disable-transitions
            >{{ scope.row.putInStatus === 0 ? '停投' : '投放' }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="100">
          <template slot-scope="{ row }">
            <el-button type="text" size="small" @click="showEdit(row)">
              <el-tooltip content="编辑" placement="top">
                <i class="el-icon-edit" style="font-size: 16px"></i>
              </el-tooltip>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <c-pagination :page-size.sync="pageInfo.pageSize" :page-no.sync="pageInfo.pageNum" :total="pageInfo.total" :get-data-fun="loadData" />
    <OptionDialog ref="dialog" />
    <OptionDialogSync :visible.sync="visible" />
  </div>
</template>

<script>
import { PageCommonComponent } from '@/common/mixin'
import { Page, Delete, Add, getHouseKeeper } from '@/network/api/account/accountBaiduAdv'

// import { getHouseKeeper } from '@/network/api/account/accountBaiduHousekeeper'
import { getBatchMeRelationProject } from '@/network/api/basicSetting/project/basicSettingProject'
import Search from './Search'
import OptionDialog from './OptionDialog'
import { AccountBaiduAdvEnt } from './object'
import OptionDialogSync from '../accountBaiduHousekeeper/OptionDialogSync'
var windowObjectReference = {
  closed: null
}
export default {
  name: 'AccountBaiduAdv',
  components: { Search, OptionDialog, OptionDialogSync },
  mixins: [PageCommonComponent],
  provide: function() {
    return {
      projectList: this.getProjectList,
      productList: this.getProductList,
      houseKeeper: () => this.houseKeeper,
      loadData: this.loadData,
      showEdit: this.showEdit
    }
  },
  data() {
    return {
      disabledTF: true,
      multipleSelection: [],
      visibelOpt: true,
      loading: false,
      visible: false,
      data: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      projectList: [],
      houseKeeper: [],
      productList: {},
      projectId: '',
      housekeeperId: undefined,
      displayUpdateProject: true
    }
  },

  mounted() {
    const housekeeperId = this.$route.params && this.$route.params.housekeeperId
    this.housekeeperId = undefined
    if (housekeeperId !== undefined && housekeeperId !== null) {
      this.housekeeperId = housekeeperId
      const searchForm = this.$refs.search.getSearchForm()
      searchForm.hkId = housekeeperId
      this.doLoadData(false, searchForm)
    } else {
      this.$refs.search.updateHousekeeperId(undefined)
      this.loadData()
    }

    let _this = this
    var loop = setInterval(function() {
      if (windowObjectReference.closed) {
        clearInterval(loop)
        _this.loadData()
      }
    }, 1000)

    this.getProject()
    this.getHk()
  },
  methods: {
    addAd() {
      // this.visible = true
      Add().then(({ code, data }) => {
        if (code === 200) {
          windowObjectReference = window.open(data, 'authorize', 'width=900,height=700,left=100,top=0,menubar=no,toolbar=no')
        }
      })
    },

    formatterUnderf(row, column, cellValue, index) {
      if (cellValue === undefined || cellValue === null) {
        return '_'
      } else {
        return cellValue
      }
    },
    getProjectList() {
      return this.projectList
    },
    getProductList() {
      return this.productList
    },
    getHk() {
      getHouseKeeper({ masterName: null }).then((res) => {
        if (res.code === 200) {
          this.houseKeeper = res.data
          if (undefined !== this.housekeeperId) {
            this.$refs.search.updateHousekeeperId(this.housekeeperId)
          } else {
            this.$refs.search.updateHousekeeperId(undefined)
          }
        }
      })
    },
    getProject() {
      getBatchMeRelationProject().then((res) => {
        if (res.code === 200) {
          const data = res.data
          if (undefined !== data.projects) {
            this.projectList = data.projects
          }
          if (undefined !== data.products) {
            this.productList = data.products
          }
        }
      })
    },
    handleSelectionChange(val) {
      this.projectId = ''
      this.displayUpdateProject = false
      this.multipleSelection = val
      if (this.multipleSelection.length > 1) {
        this.multipleSelection.forEach((fe) => {
          if (undefined !== fe.projectId && fe.projectId !== null) {
            if (this.projectId === '') {
              this.projectId = fe.projectId
            } else {
              if (this.projectId !== fe.projectId) {
                this.displayUpdateProject = true
              }
            }
          }
        })
      } else if (this.multipleSelection.length === 1) {
        this.projectId = this.multipleSelection[0] !== undefined ? this.multipleSelection[0].projectId : ''
      } else {
        this.displayUpdateProject = true
      }
    },
    showOpt() {
      this.visibelOpt = !this.visibelOpt
    },
    loadData(isFirst = false) {
      const searchForm = this.$refs.search.getSearchForm()
      this.doLoadData(isFirst, searchForm)
    },
    doLoadData(firstPage = false, searchForm) {
      firstPage && (this.pageInfo.pageNum = 1)
      const req = { ...searchForm, search: searchForm.accountName, ...this.pageInfo }
      delete req.accountName
      delete req.accountId
      this.loading = true
      Page(req).then((res) => {
        this.data = res.data.records
        this.pageInfo.total = res.data.total
        this.loading = false
      })
    },
    deleteRow(row) {
      this.$confirm('确定删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          Delete(row.id).then((res) => {
            if (res.code === 200) {
              this.$message({
                message: '删除成功',
                type: 'success'
              })
              this.loadData()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showEdit(row) {
      this.$refs.dialog.show(true, { ...row })
    },
    showAdd() {
      this.$refs.dialog.show(false, new AccountBaiduAdvEnt())
    },
    showOpts(type) {
      var ids = []
      this.multipleSelection.forEach((fe) => {
        ids.push(fe.oauthAdId)
      })

      this.$refs.dialog.showType(true, ids, type, this.projectId)
    }
  }
}
</script>

<style scoped></style>
