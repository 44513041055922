<template>
  <el-dialog
    :title="isEdit ? '编 辑': '新 增'"
    width="45%"
    :visible.sync="visible"
    :before-close="close"
    label-position="right"
    :modal-append-to-body="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <el-form ref="ent" :model="ent" :rules="rules" label-width="110px">
      <el-form-item label="" prop="convertId">
        <el-input v-model="ent.convertId"></el-input>
      </el-form-item>
      <el-form-item label="广告主id" prop="advertiserId">
        <el-input v-model="ent.advertiserId"></el-input>
      </el-form-item>
      <el-form-item label="转化id" prop="id">
        <el-input v-model="ent.id"></el-input>
      </el-form-item>
      <el-form-item
        label="转化名称
"
        prop="name"
      >
        <el-input v-model="ent.name"></el-input>
      </el-form-item>
      <el-form-item label="应用类型" prop="appType">
        <el-input v-model="ent.appType"></el-input>
      </el-form-item>
      <el-form-item label="包名" prop="packageName">
        <el-input v-model="ent.packageName"></el-input>
      </el-form-item>
      <el-form-item label="下载地址" prop="downloadUrl">
        <el-input v-model="ent.downloadUrl"></el-input>
      </el-form-item>
      <el-form-item label="转化工具操作状态" prop="optStatus">
        <el-input v-model="ent.optStatus"></el-input>
      </el-form-item>
      <el-form-item label="转化来源" prop="convertSourceType">
        <el-input v-model="ent.convertSourceType"></el-input>
      </el-form-item>
      <el-form-item label="转化状态" prop="status">
        <el-input v-model="ent.status"></el-input>
      </el-form-item>
      <el-form-item label="转化类型" prop="convertType">
        <el-input v-model="ent.convertType"></el-input>
      </el-form-item>
      <el-form-item label="点击监测链接" prop="actionTrackUrl">
        <el-input v-model="ent.actionTrackUrl"></el-input>
      </el-form-item>
      <el-form-item label="展示监测链接" prop="displayTrackUrl">
        <el-input v-model="ent.displayTrackUrl"></el-input>
      </el-form-item>
      <el-form-item
        label="视频有效播放监测链接"
        prop="videoPlayEffectiveTrackUrl"
      >
        <el-input v-model="ent.videoPlayEffectiveTrackUrl"></el-input>
      </el-form-item>
      <el-form-item label="视频播放完毕监测链接" prop="videoPlayDoneTrackUrl">
        <el-input v-model="ent.videoPlayDoneTrackUrl"></el-input>
      </el-form-item>
      <el-form-item label="视频播放监测链接" prop="videoPlayTrackUrl">
        <el-input v-model="ent.videoPlayTrackUrl"></el-input>
      </el-form-item>
      <el-form-item
        label="激活回传地址"
        prop="convertActivateCallbackUrl"
      >
        <el-input v-model="ent.convertActivateCallbackUrl"></el-input>
      </el-form-item>
      <el-form-item label="APP ID" prop="appId">
        <el-input v-model="ent.appId"></el-input>
      </el-form-item>
      <el-form-item
        label="落地页链接"
        prop="externalUrl"
      >
        <el-input v-model="ent.externalUrl"></el-input>
      </el-form-item>
      <el-form-item
        label="监测参数"
        prop="convertTrackParams"
      >
        <el-input v-model="ent.convertTrackParams"></el-input>
      </el-form-item>
      <el-form-item label="转化基础代码" prop="convertBaseCode">
        <el-input v-model="ent.convertBaseCode"></el-input>
      </el-form-item>
      <el-form-item label="转化代码（JS方式）" prop="convertJsCode">
        <el-input v-model="ent.convertJsCode"></el-input>
      </el-form-item>
      <el-form-item label="转化代码（HTML方式）" prop="convertHtmlCode">
        <el-input v-model="ent.convertHtmlCode"></el-input>
      </el-form-item>
      <el-form-item label="转化页面" prop="convertXpathUrl">
        <el-input v-model="ent.convertXpathUrl"></el-input>
      </el-form-item>
      <el-form-item label="转化路径" prop="convertXpathValue">
        <el-input v-model="ent.convertXpathValue"></el-input>
      </el-form-item>
      <el-form-item label="直达链接" prop="openUrl">
        <el-input v-model="ent.openUrl"></el-input>
      </el-form-item>
      <el-form-item label="创建时间" prop="createTimeApi">
        <el-input v-model="ent.createTimeApi"></el-input>
      </el-form-item>
      <el-form-item label="更新时间" prop="modifyTimeApi">
        <el-input v-model="ent.modifyTimeApi"></el-input>
      </el-form-item>
      <el-form-item label="转化类型下匹配规则字段" prop="ignoreParams">
        <el-input v-model="ent.ignoreParams"></el-input>
      </el-form-item>
      <el-form-item label="转化统计方式" prop="convertDataType">
        <el-input v-model="ent.convertDataType"></el-input>
      </el-form-item>
      <el-form-item label="逻辑删除" prop="deleted">
        <el-input v-model="ent.deleted"></el-input>
      </el-form-item>
      <el-form-item label="创建者" prop="createBy">
        <el-input v-model="ent.createBy"></el-input>
      </el-form-item>
      <el-form-item label="创建时间" prop="createTime">
        <el-input v-model="ent.createTime"></el-input>
      </el-form-item>
      <el-form-item label="更新者" prop="updateBy">
        <el-input v-model="ent.updateBy"></el-input>
      </el-form-item>
      <el-form-item label="更新时间" prop="updateTime">
        <el-input v-model="ent.updateTime"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="ent.remark"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button round @click="close">取消</el-button>
      <el-button type="primary" round @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {
  Add,
  Update
} from '@/network/api/assetManagement/assetManagementHugeConvert'
import { AssetManagementHugeConvertEnt, formRules } from './object'

export default {
  inject: ['loadData'],
  data() {
    return {
      ent: new AssetManagementHugeConvertEnt(),
      rules: formRules,
      isEdit: false,
      visible: false,
      cityList: []
    }
  },
  methods: {
    submit() {
      this.$refs.ent.validate((validate) => {
        if (validate) {
          this.isEdit ? this.updateRow() : this.addRow()
        }
      })
    },
    addRow() {
      let req = { ...this.ent }
      Add(req).then(res => {
        this.$message({
          message: '保存成功',
          type: 'success'
        })
        this.loadData()
        this.close()
      })
    },
    updateRow() {
      let req = { ...this.ent }
      Update(req).then(res => {
        this.$message({
          message: '修改成功',
          type: 'success'
        })
        this.loadData()
        this.close()
      })
    },
    close() {
      this.visible = false
      this.$nextTick(() => {
        this.$refs.ent.resetFields()
      })
    },
    show(isEdit = false, ent) {
      this.ent = ent
      this.isEdit = isEdit
      this.visible = true
      this.$nextTick(() => {
        if (this.isEdit) {
          console.log('不能为空')
        }
      })
    }
  }
}
</script>
