// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/image/icon/icon-nav-search-normal.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/image/icon/icon-nav-search-hover.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".navbar-box[data-v-3f1ae435] {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 30px;\n  background-color: #fff;\n  border-bottom: 1px solid #e6e6e6;\n  flex: 1;\n}\n.navbar-box .navbar-search[data-v-3f1ae435] {\n  height: 100%;\n  padding: 0 15px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n.navbar-box .navbar-search .icon[data-v-3f1ae435] {\n  width: 20px;\n  height: 20px;\n  background-size: 20px !important;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 0 0 no-repeat;\n}\n.navbar-box .navbar-search .icon[data-v-3f1ae435]:hover {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") 0 0 no-repeat;\n}\n.navbar-box .navbar-right[data-v-3f1ae435] {\n  display: flex;\n  align-items: center;\n}\n.navbar-box .navbar-right .avatar-wrapper[data-v-3f1ae435] {\n  display: inline-block;\n  margin-left: 50px;\n  margin-right: 10px;\n}\n.navbar-box .navbar-right .avatar-wrapper .image-box[data-v-3f1ae435] {\n  width: 30px;\n  height: 30px;\n  border-radius: 50%;\n  overflow: hidden;\n}\n.navbar-box .navbar-right .avatar-wrapper .image-box img[data-v-3f1ae435] {\n  display: block;\n  width: 100%;\n  height: 100%;\n}\n.navbar-box .icon-notification[data-v-3f1ae435] {\n  color: #b7b9c5;\n  font-size: 24px;\n  position: relative;\n  top: -10px;\n}\n.navbar-box .user-info[data-v-3f1ae435] {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\n.navbar-box .user-info .name[data-v-3f1ae435] {\n  font-size: 16px;\n  color: #212937;\n  margin-right: 5px;\n}", ""]);
// Exports
module.exports = exports;
