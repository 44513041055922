<template>
  <el-row>
    <el-col :span="12">
      <el-form ref="redisForm" label-width="100" :model="redisCommand">
        <el-form-item label="Command" prop="command">
          <el-select v-model="redisCommand.command" style="width: 100%;">
            <el-option v-for="item in commandList" :key="item" :value="item">{{ item }}</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Key" prop="key">
          <el-input v-model="redisCommand.key" placeholder="Key" />
        </el-form-item>
        <el-form-item label="HKey" prop="value">
          <el-input v-model="redisCommand.hkey" placeholder="HKey" />
        </el-form-item>
        <el-form-item label="Value" prop="value">
          <el-input v-model="redisCommand.value" placeholder="Value" type="textarea" :rows="10" />
        </el-form-item>
        <el-form-item style="text-align: right">
          <el-button class="btn" type="primary" :loading="isBtnLoading" round @click="exec">执行</el-button>
          <el-button class="btn" round @click="reset">重置</el-button>
        </el-form-item>
        <el-form-item label="Result" prop="result">
          <el-input v-model="result" placeholder="Result" type="textarea" :rows="10" />
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
import { exec } from '@/network/api/system'

export default {
  name: 'Redis',
  data() {
    this.commandList = ['get', 'set', 'del', 'hget', 'hset', 'hdel']
    return {
      isBtnLoading: false,
      result: '',
      redisCommand: {
        'command': '',
        'key': '',
        'hkey': '',
        'value': ''
      }
    }
  },
  methods: {
    exec() {
      this.isBtnLoading = true
      exec(this.redisCommand).then(res => {
        this.isBtnLoading = false
        this.result = JSON.stringify(res.data, null, '  ')
      })
    },
    reset() {
      this.$refs.redisForm.resetFields()
      this.result = ''
    }
  }
}
</script>

<style scoped>

</style>
