/*
 * @Description: Do not edit
 * @Date: 2021-12-08 10:39:24
 * @LastEditTime: 2022-05-19 09:22:16
 */
// <!-- <el-radio :label="711">横版大图 16:9</el-radio>
//               <el-radio :label="712">竖版大图 9:16</el-radio>
//               <el-radio :label="718">横版三小图 3:2</el-radio>
//               <el-radio :label="721" class="mt-10">竖版视频 9:16</el-radio>
//               <el-radio :label="720" class="mt-10">横版视频 16:9</el-radio> -->
export function getMaterialList(type, taskId) {
  let data = []
  if (type === 711 || type === 712) {
    data.push({
      creativeGroup: '创意组01',
      autoGenerateCover: null,
      taskId,
      dataType: 'image',
      localId: null,
      localUrl: null,
      type: null,
      assetId: null,
      maxSize: 140,
      width: type === 711 ? 1280 : 1080,
      height: type === 711 ? 720 : 1920
    })
  }
  if (type === 718) {
    data.push(
      {
        creativeGroup: '创意组01',
        autoGenerateCover: null,
        taskId,
        dataType: 'image',
        localId: null,
        localUrl: null,
        type: null,
        assetId: null,
        width: 480,
        height: 320,
        maxSize: 100
      },
      {
        creativeGroup: '创意组01',
        autoGenerateCover: null,
        taskId,
        dataType: 'image',
        localId: null,
        localUrl: null,
        assetId: null,
        type: null,
        width: 320,
        height: 320,
        maxSize: 100
      },
      {
        creativeGroup: '创意组01',
        autoGenerateCover: null,
        taskId,
        dataType: 'image',
        localId: null,
        localUrl: null,
        assetId: null,
        type: null,
        width: 320,
        height: 320,
        maxSize: 100
      }
    )
  }
  if (type === 721 || type === 720) {
    data.push(
      {
        creativeGroup: '创意组01',
        autoGenerateCover: false,
        taskId,
        dataType: 'video',
        localId: null,
        assetId: null,
        localUrl: null,
        type: null,
        maxSize: 100 * 1024,
        width: type === 720 ? 1280 : 720,
        height: type === 720 ? 720 : 1280
      },
      {
        creativeGroup: '创意组01',
        autoGenerateCover: false,
        taskId,
        dataType: 'image',
        localId: null,
        localUrl: null,
        assetId: null,
        type: null,
        maxSize: 100 * 1024,
        width: type === 720 ? 1280 : 720,
        height: type === 720 ? 720 : 1280
      }
    )
  }
  return data
}

export const creativeType = {
  711: '横版大图',
  712: '竖版大图',
  718: '横版三小图',
  721: '竖版视频',
  720: '横版视频'
}

export const creativityComponentsList = {
  朋友圈: 'moments-info',
  公众号文章中部: 'center-info',
  展示Banner图片: 'form-weapp-banner',
  优雅横版大图: 'horizonTal-image-info',
  沉浸式横版视频: 'quiet-video-info',
  小程序banner广告: 'weapp-banner',
  公众号文章底部图片: 'form-weapp-banner-image',
  公众号文章底部视频: 'form-weapp-banner-video'
}

export const creativityDataList = {
  朋友圈: 'moments_info',
  公众号文章中部: 'center_info',
  展示Banner图片: 'form_weapp_banner',
  优雅横版大图: 'horizonTal_image_info',
  沉浸式横版视频: 'quiet_video_info',
  小程序banner广告: 'weapp_banner',
  公众号文章底部图片: 'form_weapp_banner_image',
  公众号文章底部视频: 'form_weapp_banner_video'
}

export const creativityDataListByCreateGroup = {
  朋友圈: 'CircleOfFriends',
  优雅横版大图: 'HBigPicture',
  公众号文章中部: 'Middle',
  沉浸式横版视频: 'HVideo',
  公众号文章底部图片: 'TailPicture',
  公众号文章底部视频: 'TailVideo'
}

export const creativityTextApi = {
  朋友圈: 'adMpManagementTaskTextCircleOfFriends',
  优雅横版大图: 'adMpManagementTaskTextHBigPicture',
  公众号文章中部: 'adMpManagementTaskTextMiddle',
  沉浸式横版视频: 'adMpManagementTaskTextHVideo',
  公众号文章底部图片: 'adMpManagementTaskTextTailPicture',
  公众号文章底部视频: 'adMpManagementTaskTextTailVideo'
}

export const creativityTextApiParams = {
  朋友圈: {
    // taskTextId: null,
    circleOfFriendsText: '' // 选择朋友圈（文案）
  },
  优雅横版大图: {
    // taskTextId: null,
    infoBarText: '', // (优雅横板大图)信息栏文案)
    graphicTextTitle: '', // (优雅横板大图)图文标题
    advTextOne: '', // (优雅横板大图)广告文案
    advTextTwo: '', // (优雅横板大图)广告文案 次
    advTextThree: '' // (优雅横板大图)广告文案 三
  },
  公众号文章中部: {
    // taskTextId: null,
    text: '' // (公众号文章中部)信息栏文案)
  },
  沉浸式横版视频: {
    // taskTextId: null,
    adCardCopyText: '', // 广告卡片文案
    advTextOne: '', // 广告文案
    advTextTwo: '', // 广告文案 次
    advTextThree: '', // 广告文案 三
    infoBarText: '', // 信息栏文案
    tailTextTitle: '' // :结束页文案
  },
  公众号文章底部图片: {
    // taskTextId: null,
    infoBarText: '' // 信息栏文案
  },
  公众号文章底部视频: {
    // taskTextId: null,
    infoBarText: '', // 信息栏文案
    tailTextTitle: '' // 结束页文案
  }
}

export const creativityTextType = {
  circleOfFriendsText: '文案', // (优雅横板大图)信息栏文案)
  graphicTextTitle: '图文标题', // (优雅横板大图)图文标题
  text: '信息栏文案', // (公众号文章中部)信息栏文案)
  adCardCopyText: '卡片文案', // 广告卡片文案
  advTextOne: '首行文案', // 广告文案
  advTextTwo: '次行文案', // 广告文案 次
  advTextThree: '第三行文案', // 广告文案 三
  infoBarText: '信息栏文案', // 信息栏文案
  tailTextTitle: '结束页文案' // 结束页文案
}

export const materialTypeList = {
  484: '图片式卡片',
  998: '大图式卡片',
  538: '视频式卡片',
  925: '图片（新）',
  927: '大图',
  928: '大图',
  556: '图片名片式banner'
}

export const creativityMaterialInfo = {
  朋友圈: null,
  公众号文章中部: {
    width: 960,
    height: 540,
    size: 80 * 1024,
    listType: 'image/jpg,image/jpeg,image/png'
  },
  展示Banner图片: {
    width: 465,
    height: 230,
    size: 30 * 1024,
    listType: 'image/jpg,image/jpeg,image/png'
  },
  优雅横版大图: {
    width: 960,
    height: 540,
    size: 80 * 1024,
    listType: 'image/jpg,image/jpeg,image/png'
  },
  沉浸式横版视频: {
    width: 960,
    height: 540,
    size: 100 * 1024 * 1024,
    listType: 'video/mp4'
  },
  小程序banner广告: {
    width: 960,
    height: 334,
    size: 100 * 1024,
    listType: 'image/jpg,image/jpeg,image/png'
  },
  公众号文章底部图片: {
    width: 960,
    height: 540,
    size: 80 * 1024,
    listType: 'image/jpg,image/jpeg,image/png'
  },
  公众号文章底部视频: {
    width: 960,
    height: 540,
    size: 100 * 1024 * 1024,
    listType: 'video/mp4',
    duration: '6-30秒'
  }
}
