<!--
 * @Author: Zeng
 * @Date: 2023-07-11 17:04:24
 * @LastEditTime: 2023-07-28 15:52:17
-->
<template>
  <div class="projection-container">
    <div class="projection-header"><span>素材数据</span></div>
    <div class="projection-content">
      <el-row :gutter="24">
        <el-col :span="24">
          <div class="projection-item-header">
            <span class="title">人员剪辑明细</span>
            <div class="item-search">
              <el-input v-model="user.userName" type="text" placeholder="人员名称" size="mini"
                style="width: 120px;margin-right: 10px;"></el-input>
              <el-cascader ref="cascader" v-model="user.deptId" :options="deptList" :props="cascaderProp"
                :show-all-levels="false" filterable clearable placeholder="请选择部门" size="mini"
                style="width: 120px;margin-right: 10px;" @change="handleChangeDept"></el-cascader>
              <SearchDate v-model="user.dates" />
            </div>
          </div>

          <el-table v-loading="user.loading" class="list-table" :data="user.list" stripe size="small">
            <el-empty slot="empty" description="暂无数据"></el-empty>
            <el-table-column label="日期" prop="showDate" align="center"></el-table-column>
            <el-table-column label="部门" prop="deptName" align="center"></el-table-column>
            <el-table-column label="姓名" prop="userName" align="center"></el-table-column>
            <el-table-column label="当日剪辑产品" prop="todayClipNum" align="center"></el-table-column>
            <el-table-column label="累计剪辑产品" prop="sumClipNum" align="center"></el-table-column>
            <el-table-column label="当日剪辑素材" prop="todayClipAsset" align="center"></el-table-column>
            <el-table-column label="累计剪辑素材" prop="sumClipAssetNum" align="center"></el-table-column>
            <el-table-column label="参与起量" prop="participateIn" align="center"></el-table-column>
            <el-table-column label="近似累计起量率" prop="sumAmountRate" align="center">
              <template slot-scope="{row}">{{ row.sumAmountRate }}%</template>
            </el-table-column>
          </el-table>
          <div class="page">
            <paginations :total="user.total" :page.sync="user.pageNum" :limit.sync="user.pageSize"
              layout="prev, pager, next" @pagination="getUserPage" small />
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import SearchDate from './SearchDate'
import { staticsUserAsset } from '@/network/api/toolManagement/api-product-test'
export default {
  components: {
    SearchDate
  },
  props: {
    deptList: Array
  },
  data() {
    return {
      cascaderProp: {
        emitPath: false,
        checkStrictly: true,
        value: 'departmentId',
        label: 'departmentName',
        children: 'children'
      },
      user: {
        list: [],
        loading: false,
        total: 0,
        pageSize: 10,
        pageNum: 1,
        userName: '',
        deptId: '',
        dates: []
      }
    }
  },

  watch: {
    'user.userName'() {
      this.resetUserPage()
    },
    'user.dates': {
      handler() {
        this.resetUserPage()
      },
      deep: true
    }
  },

  mounted() { },
  methods: {
    // 选中部门
    handleChangeDept() {
      this.$refs.cascader.dropDownVisible = false
      this.resetUserPage()
    },

    // 重置人员数据
    resetUserPage() {
      this.user.pageNum = 1
      this.getUserPage()
    },

    getUserPage() {
      const { pageNum, pageSize, dates, deptId, userName } = this.user
      let params = {
        pageNum,
        pageSize,
        deptId,
        userName,
        dates
      }
      this.user.loading = true
      staticsUserAsset(params).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          this.user.list = records || []
          this.user.total = total
        }
      }).finally(() => {
        this.user.loading = false
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
