var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: { inline: true, model: _vm.ruleForm, "label-width": "100px" },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "名称：",
                        prop: "name",
                        rules: {
                          required: true,
                          message: "请输入定向名称",
                          trigger: "change",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "450px" },
                        attrs: {
                          placeholder: "请输入定向名称",
                          maxlength: "30",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.ruleForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "name", $$v)
                          },
                          expression: "ruleForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "数据类型：",
                        prop: "uploadType",
                        rules: {
                          required: true,
                          message: "请选择数据类型",
                          trigger: "change",
                        },
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "450px" },
                          attrs: { placeholder: "请选择数据类型" },
                          model: {
                            value: _vm.ruleForm.uploadType,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "uploadType", $$v)
                            },
                            expression: "ruleForm.uploadType",
                          },
                        },
                        _vm._l(_vm.crowdPack_type_list, function (item) {
                          return _c(
                            "el-option",
                            {
                              key: item.dictLabel,
                              attrs: {
                                value: item.dictValue,
                                label: item.dictLabel,
                              },
                            },
                            [_vm._v(_vm._s(item.dictLabel))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "文件上传：",
                        prop: "uploadFileMd5",
                        rules: {
                          required: true,
                          message: "请选择文件上传",
                          trigger: "change",
                        },
                      },
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "my-upload",
                          staticClass: "upload-demo",
                          attrs: {
                            name: "file",
                            limit: 1,
                            action: "/api/file/upload",
                            "before-upload": _vm.beforeAvatarUpload,
                            "on-preview": _vm.handlePreview,
                            "on-success": _vm.uploadSuccess,
                            accept: ".txt",
                            "on-remove": _vm.handleRemove,
                            "before-remove": _vm.beforeRemove,
                            "on-exceed": _vm.handleExceed,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("点击上传")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [
                              _c("div", { staticClass: "tip" }, [
                                _c("p", { staticClass: "reset-p" }, [
                                  _vm._v(
                                    "文件内容规范：每行只能有一个数据，不能有空格或其他字符"
                                  ),
                                ]),
                                _c("p", { staticClass: "reset-p" }, [
                                  _vm._v(
                                    "文件内容规范：仅英文与数字及横线，不能含中文与特殊字符"
                                  ),
                                ]),
                                _c("p", { staticClass: "reset-p" }, [
                                  _vm._v("文件格式规范：只支持txt"),
                                ]),
                                _c("p", { staticClass: "reset-p" }, [
                                  _vm._v("文件大小规范：最大支持1G上传"),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "项目：",
                        prop: "projectId",
                        rules: {
                          required: true,
                          message: "请选择项目",
                          trigger: "change",
                        },
                      },
                    },
                    [
                      _c("SearchSelect", {
                        staticStyle: { width: "450px" },
                        attrs: {
                          options: _vm.projectList,
                          props: {
                            label: "projectName",
                            value: "bsProjectId",
                          },
                          placeholder: "请选择项目",
                        },
                        on: {
                          change: (value) => (_vm.selectProjectId = value),
                        },
                        model: {
                          value: _vm.ruleForm.projectId,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "projectId", $$v)
                          },
                          expression: "ruleForm.projectId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品：", prop: "productId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "450px" },
                          attrs: { placeholder: "请选择产品" },
                          on: {
                            change: (value) => (_vm.selectProductId = value),
                          },
                          model: {
                            value: _vm.ruleForm.productId,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "productId", $$v)
                            },
                            expression: "ruleForm.productId",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "不限", value: 0 },
                          }),
                          _vm._l(_vm.productListed, function (itm, idx) {
                            return _c("el-option", {
                              key: idx,
                              attrs: {
                                label: itm.productName,
                                value: itm.bsProductId,
                              },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "描述：", prop: "description" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "450px" },
                        attrs: {
                          type: "textarea",
                          rows: "7",
                          maxlength: "100",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.ruleForm.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "description", $$v)
                          },
                          expression: "ruleForm.description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "mt-20" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: " " } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("ruleForm")
                            },
                          },
                        },
                        [_vm._v("确 定")]
                      ),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.resetForm("ruleForm")
                            },
                          },
                        },
                        [_vm._v("重 置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }