<!--
 * @Description: 推广单元模板选择
 * @Date: 2021-11-09 09:32:28
 * @LastEditTime: 2022-05-11 13:33:30
-->
<template>
  <div>
    <Base title="推广单元模板">
      <el-button type="primary" @click="addTemplate">添加单元</el-button>
      <div class="table-pane">
        <el-button type="primary" :disabled="!dataSource.data.length" @click="ldyVisible = true">选择落地页</el-button>
        <el-button type="primary" :loading="pushLoading" :disabled="(!ldyData.length && dataSource.data.some((item) => item.releaseStatus === '未发布')) || dataSource.data.every((item) => item.releaseStatus === '已发布')" @click="publishAll">全部发布</el-button>
        <TablePane v-loading="tableLoading" :element-loading-text="`${adgroupFeedName}, 正在发布中`" :data-source="dataSource" class="table" border @getPage="getPage"> </TablePane>
      </div>
      <div class="bottom-btn">
        <el-tooltip class="item" effect="dark" content="执行上一步操作,列表数据将会被清除" placement="top">
          <div>
            <el-button :disabled="pushLoading || tableLoading" @click="prevStep"> 上一步</el-button>
          </div>
        </el-tooltip>
        <el-button class="ml-20" @click="cancel">取 &nbsp; 消 </el-button>
        <el-tooltip v-if="dataSource.data.length === 0 || dataSource.data.some((item) => item.releaseStatus === '未发布')" class="item ml-20" effect="dark" content="请发布全部单元模板！" placement="top">
          <div>
            <el-button type="primary" disabled>下一步</el-button>
          </div>
        </el-tooltip>
        <el-button v-else class="ml-20" type="primary" :disabled="pushLoading || tableLoading" @click="nextStep">下一步</el-button>
      </div>
      <!-- dataAll:{{ dataAll.plan.filter((item) => item.type === '模板') }} -->
    </Base>
    <SeleteModal
      dialog-title="选择推广单元"
      left-title="计划"
      :tab-pane="[
        {
          title: '单元模板',
          name: 'xsdy',
          subTitle: [{ key: 'xsmb', value: '单元模板' }],
          sort: 1,
          level: '2'
        },
        {
          title: '线上单元',
          name: 'dymb',
          subTitle: [
            { key: 'xsdy', value: '线上单元' },
            { key: 'dymb', value: '单元模板' }
          ],
          sort: 2,
          level: '2'
        }
      ]"
      :visible="seleteModalVisible"
      :local-query="dataAll.plan.filter((item) => item.type === '模板')"
      :online-query="dataAll.plan.filter((item) => item.type === '线上')"
      :local-data="meUnit"
      :online-data="baiduMeUnit"
      :data-source="dataSource.data"
      @onOk="getTemplateData"
    />

    <SelectLdy :ldy-visible="ldyVisible" :house-account-data="baiduIdList" @close="ldyVisible = false" @getLdyData="getLdyData" />
  </div>
</template>

<script>
import Base from './component/base.vue'
import SelectLdy from './component/selectLdy.vue'
import TablePane from '@/components/tablePane.vue'
import SeleteModal from './component/seleteModal.vue'
import { getUnitBuPlan, byApiPlanIdGetApiUnit, GetUnitSubjectBuPlan, batchUpUnit, deleteBaiduUnit, deleteBaiduApiUnit } from '@/network/api/advManagement/advManagementBaiduUnit'
import { omit } from '@/utils/ruoyi'
const PUBLISH = '已发布'
const UN_PUBLISH = '未发布'

export default {
  provide: function() {
    return {
      getBaiduOnLocal: this.getUnit,
      getBaiduOnline: this.getUnitByOnline,
      getBaiduOnlineTwo: this.getBaiduOnlineTwo,
      close: () => {
        this.seleteModalVisible = false
        this.baiduMeUnit = []
      }
    }
  },
  inject: ['setStep', 'dataAll'],
  components: {
    Base,
    TablePane,
    SeleteModal,
    SelectLdy
  },
  props: {
    baiduIdList: {
      type: Array,
      default: () => []
    },
    housekeeperInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      seleteModalVisible: false,
      ldyVisible: false, // 选择落地页Visible
      ldyData: [], // 选择落地页数据
      meUnit: [], // 计划模板list
      baiduMeUnit: [], // 百度计划模板list
      templateData: [], // 选中的模板数据
      pushLoading: false,
      tableLoading: false,
      adgroupFeedName: '',
      // 表格数据
      dataSource: {
        data: [],
        cols: [
          {
            label: '单元名称',
            render: (record) => {
              return (
                /* eslint-disable */
                <el-tooltip placement="top">
                  <div slot="content">
                    <p>{record.type === '线上' ? '-' : record.lable}</p>
                  </div>
                  <p class="text-ellipsis">{record.type === '线上' ? '-' : record.lable}</p>
                </el-tooltip>
                /* eslint-disable */
              )
            }
          },
          {
            label: '线上单元Id',
            render: (record) => (record.adgroupFeedId ? record.adgroupFeedId : '-')
          },
          {
            label: '线上单元名称',
            render: (record) => {
              return (
                /* eslint-disable */
                <el-tooltip placement="top">
                  <div slot="content">
                    <p>{record.adgroupFeedName2 ? record.adgroupFeedName2 : record.lable}</p>
                  </div>
                  <p class="text-ellipsis">{record.adgroupFeedName2 ? record.adgroupFeedName2 : record.lable}</p>
                </el-tooltip>
                /* eslint-disable */
              )
            }
          },
          {
            label: '创建人',
            width: '100',
            prop: 'createBy'
          },
          {
            label: '创建时间',
            prop: 'createTime'
          },
          {
            label: '投放账户',
            prop: 'accountName'
          },
          {
            label: '关联计划',
            // width: 130,
            prop: 'campaignFeedName'
          },
          {
            label: '类型',
            width: 130,
            prop: 'type'
          },
          {
            label: '发布状态',
            width: 100,
            render: (record) => {
              if (record.releaseStatus === PUBLISH) {
                // eslint-disable-next-line
                return record.type === '模板' ? <el-tag type="success">已发布</el-tag> : '-'
              } else {
                // eslint-disable-next-line
                return <el-tag type="danger">未发布</el-tag>
              }
            }
          }
        ], // 表格的列数据
        isIndex: true, // 列表序号
        loading: false, // loading
        isOperation: true,
        isBorder: true,
        handleSelectionChange() {},
        pageData: {
          total: 0, // 总条数
          pageSize: 10,
          layout: 'total, prev, pager, next, jumper'
        },
        operation: {
          // 表格有操作列时设置
          label: '操作', // 列名
          width: '150', // 根据实际情况给宽度
          data: [
            {
              label: '发布', // 操作名称
              type: 'text', // 按钮类型
              showLabel: 'releaseStatus',
              showValue: ['未发布'],
              handleRow: async (index, record) => {
                this.tableLoading = true
                this.adgroupFeedName = record.adgroupFeedName
                if (record.releaseStatus === UN_PUBLISH) {
                  this.setLdyData()
                  if (this.ldyData.length) {
                    let params = {
                      housekeeperId: this.housekeeperInfo.housekeeperId,
                      items: [
                        {
                          baiduId: record.baiduId,
                          baiduUnitId: record.baiduUnitId,
                          campaignFeedId: record.campaignFeedId
                        }
                      ],
                      batchUpUnitLandPage: [omit(record.batchUpUnitLandPage, ['accountName', 'housekeeperId'])]
                    }
                    const res = await batchUpUnit(params)
                    if (res) {
                      const { code, data } = res
                      if (code === 200) {
                        if (data.items[0].code === 200) {
                          this.$message.success('发布成功!')
                          this.dataSource.data[index].releaseStatus = PUBLISH
                          this.dataSource.data[index].adgroupFeedId = data.items[0].adgroupFeedId
                          this.dataSource.data[index].adgroupFeedName2 = data.items[0].adgroupFeedName
                        } else {
                          this.$message.error(data.items[0].msg)
                          // this.$message.error('发布失败!')
                        }
                      }
                    }
                  } else {
                    this.$message.error('请先选择落地页!')
                  }
                  this.tableLoading = false
                }
              } // 自定义事件
            },
            {
              label: '删除', // 操作名称
              type: 'text', // 按钮类型
              color: '#ff0000',
              handleRow: async (index, record) => {
                if (record.releaseStatus === PUBLISH && record.type === '模板') {
                  const { code } = await deleteBaiduUnit(record.housekeeperId, record.baiduId, record.adgroupFeedId)
                  if (code === 200) {
                    this.dataSource.data.splice(index, 1)
                    this.$message.success('删除成功!')
                  }
                } else {
                  this.dataSource.data.splice(index, 1)
                  this.$message.success('删除成功!')
                }
                // 线上单元下模板数据id和模板数据id不一样, 但是baiduUnitId相同
                this.templateData = this.templateData.filter((f) => {
                  return f.id !== record.id
                })
              }
            }
          ]
        }
      }
    }
  },

  methods: {
    /**
     * @description 获取单元模板 (单元模板/_id)
     */
    async getUnit(item) {
      const { code, data } = await getUnitBuPlan(item._id)
      this.meUnit = this.format_GetUnit(code, data, item.baiduId)
    },

    /**
     * @description 获取单元模板 (线上单元-单元模板/subject)
     */
    async getBaiduOnlineTwo(item) {
      const { code, data } = await GetUnitSubjectBuPlan(item.subject)
      this.baiduMeUnit = this.format_GetUnit(code, data, item.campaignFeedId)
    },

    format_GetUnit(code, data, key) {
      if (code === 200) {
        data.forEach((item) => {
          item.id = item.subject ? `${item.baiduUnitId}+${key}` : `${item.baiduUnitId}+${key}`
          item.lable = item.adgroupFeedName
          item.accountName = item.adgroupFeedName
          item.accountId = item.baiduUnitId
        })
        // 判线上计划板是否被选中, 选中置灰
        if (this.dataSource.data.length) {
          data.forEach((item) => {
            this.templateData.forEach((el) => {
              if (item.id === el.id) {
                item.disabled = true
              }
            })
          })
        }
        return data
      }
    },

    /**
     * 添加模板
     */
    addTemplate() {
      this.seleteModalVisible = true
    },

    /**
     * @description 获取线上单元
     * @param {item} 已选中的线上计划
     */
    async getUnitByOnline(item) {
      let params = {
        housekeeperId: item.housekeeperId,
        baiduId: item.baiduId,
        campaignFeedId: item._id,
        campaignFeedName: item._ladel
      }
      const { code, data } = await byApiPlanIdGetApiUnit(params)
      if (code === 200) {
        data.forEach((item) => {
          item.id = item.adgroupFeedId
          item.lable = item.adgroupFeedName
          item.accountName = item.adgroupFeedName
          item.accountId = item.adgroupFeedId
        })
        // 判线上计划板是否被选中, 选中置灰
        if (this.dataSource.data.length) {
          data.forEach((item) => {
            this.templateData.forEach((el) => {
              if (item.id === el.id) {
                item.disabled = true
              }
            })
          })
        }
        this.baiduMeUnit = data
      }
    },

    /**
     * @description 获取计划模板数据
     * @param {data}
     */
    getTemplateData(data) {
      let _templateData = [...this.templateData]
      let dataLocal = data.local || []
      let dataOnline = data.online || []
      // 线上单元选择本地模板发布后,再次选择相同线上模板时去重
      if (_templateData.length) {
        let _dataOnline = []
        dataOnline.map((item) => {
          if (!_templateData.some((s) => s.adgroupFeedId === item.adgroupFeedId)) {
            _dataOnline.push(item)
          }
        })
        dataOnline = _dataOnline
      }
      let dataAll = [..._templateData, ...dataLocal, ...dataOnline]
      let qc_local_one = []
      let qc_local_two = []

      // 线上单元模板和单元模板Id相同, 要根据type2分别去重
      dataAll
        .filter((f) => f.type2 === '线上单元模板')
        .map((item) => {
          if (qc_local_one.length === 0 || !qc_local_one.some((s) => s.id === item.id)) {
            qc_local_one.push(item)
          }
        })
      dataAll
        .filter((f) => f.type2 === undefined)
        .map((item) => {
          if (qc_local_two.length === 0 || !qc_local_two.some((s) => s.id === item.id)) {
            qc_local_two.push(item)
          }
        })

      let qc_data = [...qc_local_one, ...qc_local_two]
      this.templateData = qc_data
      this.dataSource.data = [...qc_data].splice(0, 10)
      this.dataSource.pageData.total = qc_data.length
    },

    /**
     * @description 设置分页
     */
    getPage({ pageSize, pageNum }) {
      let data = [...this.templateData]
      this.dataSource.data = data.splice((pageNum - 1) * pageSize, pageSize * pageNum)
    },

    /**
     * @description 获取落地页信息
     */
    getLdyData(list) {
      this.dataSource.data.forEach((element) => {
        if (list.length) {
          list.map((item) => {
            if (element.accountName === item.accountName) {
              element.batchUpUnitLandPage = item
            }
          })
        } else {
          delete element.batchUpUnitLandPage
        }
      })
      this.ldyVisible = false
      this.ldyData = list
    },

    /**
     * @description 设置落地页信息到单元中
     */
    setLdyData() {
      this.dataSource.data.forEach((element) => {
        if (this.ldyData.length) {
          this.ldyData.map((item) => {
            if (element.accountName === item.accountName) {
              element.batchUpUnitLandPage = item
            }
          })
        } else {
          delete element.batchUpUnitLandPage
        }
      })
    },

    /**
     * @description 全部发布
     */
    publishAll() {
      this.setLdyData()
      this.pushLoading = true
      let items = []
      let batchUpUnitLandPage = []
      this.dataSource.data.map((item) => {
        if (item.releaseStatus === UN_PUBLISH) {
          items.push({
            baiduId: item.baiduId,
            baiduUnitId: item.baiduUnitId,
            campaignFeedId: item.campaignFeedId
          })
          batchUpUnitLandPage.push(omit(item.batchUpUnitLandPage, ['accountName', 'housekeeperId']))
        }
      })
      let params = {
        housekeeperId: this.housekeeperInfo.housekeeperId,
        items,
        batchUpUnitLandPage
      }
      batchUpUnit(params).then(({ code, data }) => {
        if (code === 200) {
          data.items.map((item) => {
            if (item.code === 200) {
              let index = this.dataSource.data.findIndex((f) => f.baiduUnitId === +item.baiduUnitId && f.baiduId === +item.baiduId && +f.campaignFeedId === +item.campaignFeedId)
              this.dataSource.data[index].releaseStatus = PUBLISH
              this.dataSource.data[index].adgroupFeedId = item.adgroupFeedId
              this.dataSource.data[index].adgroupFeedName2 = item.adgroupFeedName
            } else {
              this.$message.error(item.msg)
            }
          })
        }
        this.pushLoading = false
      })
    },

    /**
     * @description 上一步
     */
    prevStep() {
      this.cancel()
      this.setStep('prev')
    },

    /**
     * @description 下一步
     */
    nextStep() {
      let qc_dataSource = []
      this.dataSource.data.map((item) => {
        if (qc_dataSource.length === 0 || !qc_dataSource.some((s) => s.id === item.id)) {
          qc_dataSource.push(item)
        }
      })
      if (this.dataAll.plan.length > qc_dataSource.length) {
        this.$msgbox({
          title: '提示',
          message: '部分计划未选择单元,是否执行下一步!',
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(() => {
          // 1.将选中数据保持
          this.$emit('getUnitData', this.dataSource.data)
          // 2.跳到创意选择
          this.setStep('next')
        })
      } else {
        // 1.将选中数据保持
        this.$emit('getUnitData', this.dataSource.data)
        // 2.跳到创意选择
        this.setStep('next')
      }
    },

    /**
     * @description 取消
     */
    async cancel() {
      let items = []
      this.dataSource.data.map((item) => {
        if (item.releaseStatus === PUBLISH && item.type === '模板') {
          if (items.length === 0 || !items.some((s) => s.baiduId === item.baiduId)) {
            items.push({
              baiduId: item.baiduId,
              feedId: []
            })
          }
        }
      })
      this.dataSource.data.map((item) => {
        if (item.releaseStatus === PUBLISH && item.type === '模板') {
          items.forEach((element) => {
            if (element.baiduId === item.baiduId) {
              element.feedId.push(item.adgroupFeedId)
            }
          })
        }
      })

      if (items.length === 0) {
        this.dataSource.data = []
        this.templateData = []
      }

      let perams = {
        housekeeperId: this.dataSource.data[0].housekeeperId,
        items
      }
      const { code } = await deleteBaiduApiUnit(perams)
      if (code === 200) {
        this.dataSource.data = []
        this.templateData = []
        this.$message.success('操作成功!')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.demo-form-inline {
  display: flex;
  justify-content: space-between;
}

.table-pane {
  padding: 10px 40px 0 40px;
  text-align: right;
}

.bottom-btn {
  display: flex;
  justify-content: center;
}
</style>
