<template>
  <!--显示列弹框-->
  <el-dialog :visible.sync="show" :append-to-body="true" width="600px" top="20vh" @close="onCloseDialog">
    <!--自定义 title-->
    <span slot="title" class="dialog-title" style="font-size:18px">{{ dialogTitle }}</span>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="关联项目" prop="projectId">
        <el-select v-model="form.projectId" filterable clearable placeholder="请选择关联项目" style="width: 100%;" @change="handleSelect">
          <el-option v-for="(itm, idx) in projectList" :key="idx" :label="itm.projectName" :value="itm.bsProjectId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关联产品" prop="productId">
        <el-select v-model="form.productId" filterable :disabled="!form.projectId" clearable placeholder="请选择关联产品" style="width: 100%;">
          <el-option label="不限" :value="-1"></el-option>
          <el-option v-for="(itm, idx) in productList" :key="idx" :label="itm.productName" :value="itm.bsProductId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="文案" prop="text">
        <el-input v-model="form.text" type="textarea" placeholder="请输入文案" rows="8"></el-input>
        <p class="tip">
          <span :style="{ color: (form.text ? form.text.split('\n').length : 0) > 20 ? 'red' : '#7d7d7d' }">{{ form.text ? form.text.split('\n').length : 1 }}</span>
          /20. 手动换行可添加多条文案
        </p>
      </el-form-item>
    </el-form>

    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">取 消</el-button>
      <el-button class="btn" type="primary" :loading="isLoading" :disabled="isLoading" @click="clickConfirm('form')">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { getTxtDetail, addTxt, editTxt } from '@/network/api/api-material'
import { getMeRelationProject } from '@/network/api/basicSetting/project/basicSettingProject'
import { getProductByProject } from '@/network/api/basicSetting/product/basicSettingProduct'
export default {
  name: 'EditCopyDialog',
  components: {},
  filters: {},
  props: {},
  data() {
    return {
      show: false, // 是否显示弹框
      dialogTitle: '',
      projectList: [], // 项目列表
      productList: [], // 产品列表
      isLoading: false, // 是否正在加载
      id: '',
      form: {
        productId: -1,
        projectId: '',
        text: ''
      }, // 表单数据
      rules: {
        text: [{ required: true, message: '请输入文案', trigger: 'blur' }],
        productId: [{ required: true, message: '请选择关联产品', trigger: 'blur' }],
        projectId: [{ required: true, message: '请选择关联项目', trigger: 'blur' }]
      } // 表单校验规则
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    /* --------------------------- Actions --------------------------- */
    // 显示弹框
    showModal(id, params) {
      this.show = true
      this.dialogTitle = id ? '编辑文案' : '新增文案'
      this._getProject()
      id && this._getTxtDetail(id)
      this.id = id
      if (params) {
        const { projectId, productId } = params
        this.form.projectId = +projectId || ''
        let _productId = +productId || ''
        this._getProduct(this.form.projectId, _productId)
      }
    },

    // 关闭弹框
    closeModal() {
      this.show = false
    },
    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      // 重置表单
      this.$refs['form'].resetFields()

      // 清除数据
      this.form = {
        productId: '',
        projectId: '',
        text: ''
      }
      this.id = ''
    },

    // 点击确定按钮
    clickConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.id ? this._editTxt() : this._addTxt()
        } else {
          this.$WarningMessage('请将表单填写完整')
        }
      })
    },
    // 选择关联项目之后
    handleSelect(e) {
      if (e !== '') {
        this.form.productId = ''
        this._getProduct(e)
      }
    },
    /* --------------------------- Private --------------------------- */
    // 获取项目列表(个人可见)
    _getProject() {
      getMeRelationProject().then((res) => {
        if (res.code === 200) {
          this.projectList = res.data
        }
      })
    },
    // 获取产品列表(个人可见)
    _getProduct(val, productId = -1) {
      getProductByProject(val).then((res) => {
        if (res.code === 200) {
          this.productList = res.data
          this.form.productId = productId
        }
      })
    },
    // 新增素材
    _addTxt() {
      this.isLoading = true
      let params = {}
      params.text = this.form.text
      params.productId = this.form.productId
      params.projectId = this.form.projectId
      params.adminId = this.userInfo.userid
      params.nickname = this.userInfo.username
      let textareaArr = params.text.split('\n')
      textareaArr.map((item) => {
        addTxt({ ...params, text: item }).then((res) => {
          this.closeModal()
          this.$emit('edit')
        })
      })
      this.$refs['form'].resetFields()
      this.$message.success('新增素材成功')
    },

    // 获取素材详情
    _getTxtDetail(id) {
      this.isLoading = true
      getTxtDetail({ id: id })
        .then((res) => {
          this._getProduct(res.data.projectId)
          this.form.text = res.data.text
          this.form.productId = res.data.productId
          this.form.projectId = res.data.projectId
        })
        .catch((err) => {
          this.$ErrorMessage(err.data || '获取素材详情失败')
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    // 编辑素材
    _editTxt() {
      this.isLoading = true
      let params = {}
      params.text = this.form.text
      params.productId = this.form.productId
      params.projectId = this.form.projectId
      params.adminId = this.userInfo.userid
      params.nickname = this.userInfo.username
      editTxt(this.id, params)
        .then((res) => {
          // this.$SuccessMessage('编辑素材成功')
          this.$emit('edit')
          this.closeModal()
        })
        .catch((err) => {
          this.$ErrorMessage(err.data || '编辑素材失败')
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
.dialog-footer {
  margin-right: 30px;
}
</style>
