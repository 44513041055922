<!--
 * @Description: Do not edit
 * @Date: 2021-09-20 12:51:36
 * @LastEditTime: 2022-05-12 15:59:00
-->
<template>
  <box>
    <template slot="title">程序化设置</template>
    <el-form-item label="创意名称" prop="creativeFeedName">
      <el-input v-model="sizeForm.creativeFeedName" maxlength="1024" style="width: 600px" />
    </el-form-item>
    <!-- 创意标题 start-->
    <el-form-item label="创意标题" prop="titles">
      <div class="label">
        <ul>
          <li v-for="(item, index) in titles" :key="item">{{ index + 1 }}</li>
        </ul>
        <div>
          <el-form-item>
            <el-input v-model="sizeForm.elements.titles" type="textarea" :autosize="{ minRows: 6 }" style="width: 900px" @change="changeTitles" />
          </el-form-item>
        </div>
      </div>
      <p class="tip">
        <span :style="{ color: titles.length > 50 ? 'red' : '##7d7d7d' }"> {{ titles.length }} / 50 </span>
        点击回车填写下一条，每条标题最多不超过60字符
      </p>
      <ul class="custom-title ml-20 mt-10">
        <li v-for="item in custom_title" :key="item.title" @click="setSizeForm('titles', item.title)">+ {{ item.title }}</li>
      </ul>
    </el-form-item>
    <!-- 创意标题 end -->

    <!-- 创意物料 start-->
    <el-form-item label="创意物料" prop="pic">
      <div>
        <el-radio v-for="(item, index) in pic_label_type" :key="index" v-model="pic_label" :label="item.key" border>
          {{ item.value }}
          {{ item.value === '固定三图' ? Math.floor(sizeForm.elements[item.key] && sizeForm.elements[item.key].length / 3) : sizeForm.elements[item.key] && sizeForm.elements[item.key].length }} / 50
        </el-radio>
        <!-- 创意物料 图片区域 -->
        <div v-for="item in pic_label_type" :key="item.key" class="pic-box">
          <!-- 视频上传 -->
          <div v-if="item.key === 'video' && pic_label === 'video'" class="avatar-uploader mt-20 mb-20">
            <div class="justify-start">
              <div class="upload-box-2 ">
                <i class="upload-icon  el-icon-plus el-upload--picture-card" @click="setMaterialVideoBox" />
                <div class="tip">视 频</div>
              </div>
              <ul v-if="sizeForm.elements.videoUrl && sizeForm.elements.videoUrl.length" class="material-showcase">
                <li v-for="Item in sizeForm.elements.videoUrl" :key="Item">
                  <video :src="Item.videoUrl" />
                  <div class="delete-img ">
                    <i class="el-icon-delete" style="font-size: 28px" @click="(e) => deleteVideo(e, Item.videoUrl)" />
                  </div>
                </li>
              </ul>
            </div>
            <div class="pic-tip">
              <!-- 视频提示 -->
              <p class="tip">大小: {{ flag ? '≤' : 'd' }} 500MB</p>
              <p class="tip">横版尺寸: 横版16:9，分辨率≥1280*720</p>
              <p class="tip">竖版尺寸: 竖版9:16，分辨率≥720*1280</p>
            </div>
          </div>

          <!-- 固定三图 -->
          <div v-if="pic_label === 'picture3Fixed'">
            <div v-if="pic_label === item.key" class="avatar-uploader" style="display: flex">
              <div class="upload-box-2">
                <i class="upload-icon el-icon-plus el-upload--picture-card" @click="setMaterialBox" />
              </div>
              <ul v-if="sizeForm.elements[item.key] && sizeForm.elements[item.key].length" class="material-showcase ">
                <li v-for="(Item, index) in sizeForm.elements[item.key]" :key="Item" :class="{ wite: (index + 1) % 3 === 0 && index + 1 !== sizeForm.elements[item.key].length }">
                  <img :key="Item.imageid" :src="Item.image" class="avatar" />
                  <div class="delete-img">
                    <i class="el-icon-delete" @click="(e) => deletePicImg(e, index)" />
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <!-- 小图, 大图, 视频 上传, 展示区 -->
          <div v-else>
            <div v-if="pic_label === item.key" class="img-upload-box">
              <div class="upload-box-2">
                <i class="upload-icon el-icon-plus el-upload--picture-card" @click="setMaterialBox" />
                <div v-if="item.key === 'video' && pic_label === 'video'" class="tip">
                  封面图
                </div>
              </div>
              <!-- 视频封面图 -->
              <template v-if="item.key === 'video' && pic_label === 'video'">
                <ul v-if="sizeForm.elements.videoUrl && sizeForm.elements.videoUrl.length" class="material-showcase">
                  <!-- <li v-for="(Item, index) in sizeForm.elements.videoUrl" :key="Item">
                    <div v-if="Item.poster && Item.poster.length">
                      <img :src="Item.poster[0].image || defaultImg" class="avatar" />
                      <div class="imgCount-tip">
                        {{ Item.poster.length === 1 ? '单图' : `多图 ${Item.poster.length}` }}
                      </div>
                    </div>
                    <div class="delete-img">
                      <i class="el-icon-delete" style="font-size: 28px" @click="(e) => deletePosterImg(e, index)" />
                    </div>
                  </li> -->
                  <!-- {{
                    sizeForm.elements.videoUrl
                  }} -->
                  <li v-for="(Item, index) in sizeForm.elements.videoUrl" :key="Item">
                    <div v-if="Item.poster && Item.poster.length">
                      <img :src="Item.poster[0].image || defaultImg" class="avatar" />
                    </div>
                    <div class="delete-img">
                      <i class="el-icon-delete" style="font-size: 28px" @click="(e) => deletePosterImg(e, index)" />
                    </div>
                  </li>
                </ul>
              </template>
              <!-- 小图, 大图, 固定三图-->
              <template v-else>
                <ul v-if="sizeForm.elements[item.key] && sizeForm.elements[item.key].length" class="material-showcase">
                  <li v-for="(Item, index) in sizeForm.elements[item.key]" :key="index">
                    <img :src="Item.image" class="avatar" />
                    <div class="delete-img">
                      <i class="el-icon-delete" style="font-size: 28px" @click="(e) => deletePicImg(e, index)" />
                    </div>
                  </li>
                </ul>
              </template>
            </div>
            <div v-if="item.key === 'video' && pic_label === 'video'" class="pic-tip">
              <p class="tip">尺寸: 1140 * 640</p>
            </div>
          </div>

          <!-- 尺寸信息, 提示区 -->
          <div v-if="pic_label === item.key" class="pic-tip">
            <!-- 小图, 大图,固定三图 提示-->
            <div v-if="item.key !== 'video'">
              <p v-if="item.size && item.size < 10000" class="tip">
                大小:
                {{ item.key === 'video' ? `≥${item.size / 1024}MB` : `≤ ${item.size}KB` }}
              </p>
              <p v-if="item.width && item.width < 10000" class="tip">尺寸:{{ item.width }} * {{ item.height }}</p>
              <p v-if="item.listType" class="tip">格式: {{ item.listType }}</p>
            </div>
          </div>
        </div>
      </div>
    </el-form-item>
    <!-- 创意物料 end-->

    <el-form-item label="创意工具" prop="progFlag">
      <template slot="label">
        创意工具
        <el-tooltip class="item" effect="dark" placement="top">
          <template slot="content">
            创意加工工具可以将您本单元内提交的所有图片和标题作为元素，
            <br />
            由系统优选匹配模版进行二次加工，为您扩展创意候选集。
            <br />
            扩展创意的投放数据将在组合叉乘结果中体现，您可以随时评估效果。
          </template>
          <i class="el-icon-question" />
        </el-tooltip>
      </template>
      <el-checkbox :value="sizeForm.progFlag === 32 ? true : false" border @change="(e) => setSizeForm('progFlag', e)">
        图片生成视频
      </el-checkbox>
    </el-form-item>

    <el-form-item prop="playnum">
      <template slot="label">
        附加信息
        <el-tooltip class="item" effect="dark" placement="top">
          <template slot="content">
            选择附加信息后，在广告展示时系统会根据广告主选择的附加信息选出
            <br />
            最优样式展示。其中评论数为下载类应用评论数； 阅读量、播放量分别
            <br />
            针对图文和视频物料，根据广告实际点击数据等指标进行脱敏计算得出。
            <br />
            附加信息暂时只针对手百流量生效）
          </template>
          <i class="el-icon-question" />
        </el-tooltip>
      </template>
      <div>
        <el-checkbox label="阅读量" :value="sizeForm.readnum === 1" border @change="(e) => setSizeForm('readnum', e)" />
        <el-checkbox label="播放量" :value="sizeForm.playnum === 1" border @change="(e) => setSizeForm('playnum', e)" />
      </div>
    </el-form-item>

    <material-box
      :visible="materialBox"
      select-all
      :img-count="pic_label_type.filter((item) => item.key === pic_label)[0].count"
      :name="pic_label"
      :image-list="JSON.parse(JSON.stringify(sizeForm.elements[pic_label]))"
      :attribute="attributeImg"
      :pic_label_type="pic_label_type"
      :project-id="projectId"
      @setMaterialBox="setMaterialBox"
      @submit="selectImg"
    />

    <MaterialVideoBox
      :visible="materialVideo"
      :img-count="pic_label_type.filter((item) => item.key === pic_label)[0].count"
      name="videoUrl"
      :video-listed="sizeForm.elements['videoUrl']"
      :attribute="attribute"
      :project-id="projectId"
      @setMaterialBox="setMaterialVideoBox"
      @clearvideo="clearvideo"
      @handleVideoSuccess="handleVideoSuccess"
    />
  </box>
</template>

<script>
import Box from '../box.vue'
import MaterialBox from '@/components/materialBox'
import MaterialVideoBox from '@/components/materialBox/video.vue'

export default {
  components: {
    Box,
    MaterialBox,
    MaterialVideoBox
  },
  props: {
    sizeForm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    projectId: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      titles: [''],
      flag: true,
      nowTitle: '',
      pic_label: 'pictureSingle', // 当前选中的创意
      materialBox: false, // 素材选择框visible
      materialVideo: false, // 素材选择框visible
      pic_label_type: [
        {
          key: 'pictureSingle',
          value: '小图',
          count: 50,
          size: 100 * 1024,
          listType: 'image/jpg,image/jpeg,image/png',
          width: 370,
          height: 245
        },
        {
          key: 'pictureLarge',
          value: '大图',
          count: 50,
          size: 500 * 1024,
          listType: 'image/jpg,image/jpeg,image/png',
          width: 1140,
          height: 640
        },
        {
          key: 'picture3Fixed',
          value: '固定三图',
          count: 50 * 3,
          size: 100 * 1024,
          listType: 'image/jpg,image/jpeg,image/png',
          width: 370,
          height: 245
        },
        {
          key: 'video',
          value: '视频',
          count: 50,
          size: 500 * 1024,
          listType: 'image/jpg,image/jpeg,image/png',
          width: null,
          height: null
        }
        // {
        //   key: 'colorRenderImages',
        //   value: '互动图',
        //   count: 6,
        //   size: 10000,
        //   listType: 'image/jpg,image/jpeg,image/png',
        //   width: 10000,
        //   height: 10000
        // }
      ],
      // pics: {
      //   pictureSingle: [], // 小图[]
      //   pictureLarge: [], // 大图[]
      //   picture3Fixed: [], // 固定三图[[]]
      //   video: [], // 视频封面三图[[]]
      //   colorRenderImages: [], // 互动图[]
      //   videoHorizontal: [], // 横版视频16:9[]
      //   videoVertical: [] // 竖版视频9:16[]
      // },
      custom_title: [
        {
          title: '日期'
        },
        {
          title: '地域'
        },
        {
          title: '年龄'
        }
      ],
      dialogImageUrl: '',
      dialogVisible: false
    }
  },
  computed: {
    attributeImg() {
      /*eslint-disable*/
      return this.pic_label === 'video'
        ? {
            key: 'video',
            value: '视频',
            count: 50,
            size: 500 * 1024,
            listType: 'image/jpg,image/jpeg,image/png',
            width: 1140,
            height: 640
          }
        : this.pic_label_type.filter((item) => item.key === this.pic_label)[0]
      /*eslint-disable*/
    },
    attribute() {
      return this.pic_label_type.filter((item) => item.key === this.pic_label)[0]
    }
  },
  watch: {
    sizeForm: {
      handler(newData) {
        this.changeTitles(newData.elements.titles)
      },
      deep: true
    }
  },
  methods: {
    // 改变创意物料的选项
    changePicLabel(label) {
      this.pic_label = label
    },
    // 创意工具和附加信息
    setSizeForm(type, value) {
      switch (type) {
        case 'readnum':
          this.$emit('changeData', { readnum: value ? 1 : 0 })
          break
        case 'titles':
          this.changeTitles(`${this.sizeForm.elements.titles}#{${value}}`)
          // this.$emit('changeData', { elements: { ...this.sizeForm.elements, titles: `${this.sizeForm.elements.titles}#{${value}}` }})
          break
        case 'playnum':
          this.$emit('changeData', { playnum: value ? 1 : 0 })
          break
        case 'progFlag':
          this.$emit('changeData', { progFlag: value ? 32 : 0 })
          break
        default:
          break
      }
    },
    // 创意标题
    changeTitles(value) {
      if (value) {
        const title = value && value.split('\n')

        if (!title.every((s) => s.length <= 60)) return this.$message.error('每条创意不能超过60个字符!')
        this.titles = title
        this.sizeForm.elements.titles = value

        this.nowTitle = value
      } else {
        this.titles = ['']
      }
    },

    // 上传视频成功后,归类
    handleVideoSuccess(videos) {
      this.sizeForm.elements.videoUrl = videos
      this.sizeForm.elements['videoHorizontal'] = []
      this.sizeForm.elements['videoVertical'] = []
      videos.forEach((item) => {
        if (item.width / item.height === 16 / 9) {
          this.sizeForm.elements['videoHorizontal'].push(item)
        } else if (item.width / item.height === 9 / 16) {
          this.sizeForm.elements['videoVertical'].push(item)
        }
      })
    },

    // 选择图片成功
    selectImg(imgInfo) {
      this.sizeForm.elements[this.pic_label] = imgInfo
    },

    // 移除图片封面
    deletePosterImg(e, index) {
      e.stopPropagation()
      e.preventDefault()
      this.sizeForm.elements.videoUrl.map((item, i) => {
        if (index === i) {
          item.poster = []
        }
        return item
      })
      this.handleVideoSuccess(this.sizeForm.elements.videoUrl)
      // let h_len = 0
      // let v_len = 0
      // this.sizeForm.elements['videoHorizontal'].map((item, index) => {
      //   if (item.videoId === videoId) {
      //     h_len = index
      //   }
      //   return item
      // })
      // const videoHorizontal = { ...this.sizeForm.elements['videoHorizontal'][h_len], poster: [] }
      // this.sizeForm.elements['videoHorizontal'].splice(h_len, 1, videoHorizontal)

      // this.sizeForm.elements['videoVertical'].map((item, index) => {
      //   if (item.videoId === videoId) {
      //     v_len = index
      //   }
      //   return item
      // })
      // const videoVertical = { ...this.sizeForm.elements['videoVertical'][v_len], poster: [] }
      // this.sizeForm.elements['videoVertical'].splice(h_len, 1, videoVertical)
    },

    // 移除图片
    deletePicImg(e, index) {
      e.stopPropagation()
      e.preventDefault()
      this.sizeForm.elements[this.pic_label].splice(index, 1)
    },

    // 移除视频
    deleteVideo(e, url) {
      e.stopPropagation()
      e.preventDefault()
      let videos = []
      this.sizeForm.elements.videoUrl.map((item) => {
        if (item.videoUrl !== url) {
          videos.push(item)
        }
        return item
      })
      this.handleVideoSuccess(videos)
      //     const videoHorizontal = []
      // this.sizeForm.elements['videoHorizontal'] = videoHorizontal
      // const videoVertical = []
      // this.sizeForm.elements['videoVertical'].forEach((item) => {
      //   if (item.url !== url) {
      //     videoVertical.push(item)
      //   }
      // })
      // this.sizeForm.elements['videoVertical'] = videoVertical
    },

    setMaterialBox() {
      if (!(this.projectId.length && this.projectId[0])) return this.$message.error('请先选择关联单元!')

      if (this.pic_label === 'video' && [...this.sizeForm.elements.videoHorizontal, ...this.sizeForm.elements.videoVertical].length === 0) {
        return this.$message.error('请先选择视频!')
      }
      this.materialBox = !this.materialBox

      // debugger
      if (!this.materialBox && this.pic_label === 'video') {
        this.sizeForm.elements.videoUrl[this.sizeForm.elements.videoUrl.length - 1].poster = this.sizeForm.elements.video
        this.sizeForm.elements.videoUrl.forEach((item, index) => {
          item.poster = [this.sizeForm.elements.video[index]]
        })
      }
    },

    clearvideo() {
      this.sizeForm.elements.video = []
    },

    setMaterialVideoBox() {
      if (!(this.projectId.length && this.projectId[0])) return this.$message.error('请先选择关联单元!')
      this.materialVideo = !this.materialVideo
    }
  }
}
</script>

<style scoped lang="scss">
.label {
  display: flex;
  ul {
    margin-right: 2px;
    padding-top: 6px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 3px;
    background-color: rgb(223, 223, 223);
    li {
      list-style: none;
      line-height: 1.5;
    }
  }
}
.pic-box {
  margin-top: 20px;
}
.pic-tip {
  margin-top: 10px;
  p {
    margin: 0;
    padding: 0;
    line-height: 20px;
    color: #a8a4a4;
  }
}

.custom-title {
  display: flex;
  li {
    padding: 4px 7px;
    line-height: 20px;
    background: #ccc;
    border-radius: 2px;
    margin-right: 8px;
    cursor: pointer;
  }
}

.upload-box {
  display: flex;
  width: 600px;
  flex-wrap: wrap;
  padding: 0;
  & > li {
    display: flex;
    img {
      margin: 6px;
      border: 1px solid #ccc;
      width: 148px;
      height: 148px;
      border-radius: 6px;
    }
    & > div {
      width: 600px;
      display: flex;
      align-items: center;
    }
    i {
      font-size: 40px;
    }
  }
}

.upload-box-2 {
  display: flex;
  width: 125px;
  height: 125px;
  .upload-icon {
    font-size: 24px;
    color: #cccaca;
    text-align: center;
    height: 125px;
    line-height: 125px;
  }
  .tip {
    width: 20px;
    text-align: center;
    background: #ccc;
    display: flex;
    align-items: center;
  }
  li {
    position: relative;
    img {
      width: 148px;
      height: 148px;
      margin: 0 5px;
      border: 1px solid rgb(216, 216, 216);
      border-radius: 6px;
    }
    &:hover .delete-img {
      display: flex;
    }
    .imgCount-tip {
      position: absolute;
      background: #000;
      color: #fff;
      bottom: 6px;
      right: 10px;
      border-radius: 3px;
      font-size: 14px;
      padding: 0 4px;
      line-height: 28px;
    }
    .delete-img {
      display: none;
      position: absolute;
      top: 0;
      background: rgba(0, 0, 0, 0.44);
      margin: 0 5px;
      width: 148px;
      height: 148px;
      border-radius: 6px;
      align-items: center;
      justify-content: center;
      transition: all 1s ease-out;
      .el-icon-delete {
        color: rgba(255, 255, 255, 0.815);
      }
    }
    .delete-video {
      width: 178px;
      margin: 0;
    }
  }
}

.img-upload-box {
  display: flex;
}
.material-showcase {
  display: flex;
  max-width: 1342px;
  flex-wrap: wrap;
  justify-content: flex-end;
  border: 1px #ccc solid;
  padding: 10px;
  border-radius: 10px;
  margin-left: 20px;
  .wite {
    border-right: 4px solid #919191;
  }
  li {
    position: relative;
    img {
      width: 100px;
      height: 100px;
      margin: 0 5px;
      border: 1px solid rgb(216, 216, 216);
      border-radius: 6px;
    }
    video {
      width: 100px;
      height: 100px;
      margin: 0 5px;
      border: 1px solid rgb(216, 216, 216);
      border-radius: 6px;
    }
    &:hover .delete-img {
      display: flex;
    }
    .imgCount-tip {
      position: absolute;
      background: #000;
      color: #fff;
      bottom: 6px;
      right: 10px;
      border-radius: 3px;
      font-size: 14px;
      padding: 0 4px;
      line-height: 28px;
    }
    .delete-img {
      display: none;
      position: absolute;
      top: 0;
      background: rgba(0, 0, 0, 0.44);
      margin: 0 5px;
      width: 100px;
      height: 100px;
      border-radius: 6px;
      align-items: center;
      justify-content: center;
      transition: all 1s ease-out;
      .el-icon-delete {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.815);
      }
    }
    .delete-video {
      width: 178px;
      margin: 0;
    }
  }
}

.upload-box-video {
  li {
    height: 148px;
    width: 148px;
    display: flex;
    align-items: center;
    margin: 0 5px;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 6px;
    video {
      width: 148px;
    }
  }
}
</style>
