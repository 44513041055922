var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "append-to-body": true,
        width: "500px",
        top: "10vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.onCloseDialog,
      },
    },
    [
      _c(
        "span",
        {
          staticClass: "dialog-title",
          attrs: { slot: "title" },
          slot: "title",
        },
        [_vm._v("素材信息")]
      ),
      _c("div", { staticClass: "info" }, [
        _c(
          "div",
          { staticClass: "name" },
          [
            _c("span", [_vm._v("素材名称")]),
            _c("el-input", {
              staticClass: "material-name",
              attrs: {
                disabled: true,
                "show-word-limit": "",
                maxlength: "30",
                placeholder: "请输入素材名称",
              },
              model: {
                value: _vm.material.materialName,
                callback: function ($$v) {
                  _vm.$set(_vm.material, "materialName", $$v)
                },
                expression: "material.materialName",
              },
            }),
            _c("i", {
              staticClass: "el-icon-edit el-input__icon",
              attrs: { slot: "suffix" },
              slot: "suffix",
            }),
          ],
          1
        ),
        _c("div", { staticClass: "info-image" }, [
          _vm.type === "image"
            ? _c("img", { attrs: { src: _vm.material.url, alt: "" } })
            : _vm._e(),
          _vm.type === "video"
            ? _c("video", { attrs: { src: _vm.material.url, controls: "" } })
            : _vm._e(),
        ]),
        _c("div", { staticClass: "info-detail" }, [
          _c("div", { staticClass: "title" }, [
            _c("div", { staticClass: "long" }),
            _c("div", { staticClass: "text" }, [_vm._v("基础信息")]),
          ]),
          _c("div", { staticClass: "detail" }, [
            _c("div", { staticClass: "item" }, [
              _c("div", [
                _vm._v(" 素材ID"),
                _c("span", [
                  _vm._v(_vm._s(_vm.material.imageId || _vm.material.videoId)),
                ]),
              ]),
              _c("div", [
                _vm._v(" 创建时间"),
                _c("span", [_vm._v(_vm._s(_vm.material.createdAt))]),
              ]),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("div", [
                _vm._v(" 素材尺寸"),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.material.width) +
                      "*" +
                      _vm._s(_vm.material.height)
                  ),
                ]),
              ]),
              _vm.type === "image"
                ? _c("div", [
                    _vm._v(" 素材大小"),
                    _c("span", [
                      _vm._v(
                        _vm._s(Math.floor(_vm.material.size / 1024)) + " KB"
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.type === "video"
                ? _c("div", [
                    _vm._v(" 素材大小"),
                    _c("span", [
                      _vm._v(
                        _vm._s(Math.floor(_vm.material.size / 1024 / 1024)) +
                          " MB"
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("div", [
                _vm._v(" 专辑"),
                _c("span", [_vm._v(_vm._s(_vm.material.albumName || "无"))]),
              ]),
              _c("div", [
                _vm._v(" 素材组"),
                _c("span", [
                  _vm._v(_vm._s(_vm.material.materialGroupName || "无")),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "item" }, [
              _c("div", [
                _vm._v(" 来源"),
                _c("span", [_vm._v(_vm._s(_vm.material.nickname))]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "input" },
              [
                _c("span", [_vm._v("关键字")]),
                _c("el-input", {
                  staticClass: "textarea",
                  attrs: {
                    "show-word-limit": "",
                    maxlength: "30",
                    placeholder: "请输入关键字",
                  },
                  model: {
                    value: _vm.material.keyword,
                    callback: function ($$v) {
                      _vm.$set(_vm.material, "keyword", $$v)
                    },
                    expression: "material.keyword",
                  },
                }),
                _c("i", {
                  staticClass: "el-icon-edit el-input__icon",
                  attrs: { slot: "suffix" },
                  slot: "suffix",
                }),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "user" }, [
          _c("div", { staticClass: "title" }, [
            _c("div", { staticClass: "long" }),
            _c("div", { staticClass: "text" }, [_vm._v("人员信息")]),
          ]),
          _c(
            "div",
            { staticClass: "item" },
            [
              _c("span", [_vm._v("创意人")]),
              _c(
                "el-select",
                {
                  attrs: {
                    "value-key": "key",
                    filterable: "",
                    clearable: "",
                    placeholder: "请选投放人员",
                  },
                  model: {
                    value: _vm.creator,
                    callback: function ($$v) {
                      _vm.creator = $$v
                    },
                    expression: "creator",
                  },
                },
                _vm._l(_vm.userList, function (itm, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { label: itm.label, value: itm },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "btn", on: { click: _vm.closeModal } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { staticClass: "btn", on: { click: _vm.onClickMove } },
            [_vm._v("移 动")]
          ),
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: {
                type: "primary",
                loading: _vm.isLoading,
                disabled: _vm.isLoading,
              },
              on: {
                click: function ($event) {
                  return _vm.clickConfirm("form")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
      _c("batch-move-material-dialog", {
        ref: "batchMoveMaterialDialog",
        attrs: { type: _vm.type },
        on: { edit: _vm.handleDatail },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }