var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "300px" } },
    [
      _vm.formData.flag
        ? _c("ContentBox", { attrs: { "data-props": _vm.creativityDiv } })
        : _c("div", { staticClass: "kong" }, [_vm._v("请选择计划")]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "创意基础信息",
            visible: _vm.visible,
            width: "650px",
            "modal-append-to-body": false,
            "destroy-on-close": "",
          },
          on: { close: _vm.beforeClose },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-form",
                {
                  ref: "creativityForm",
                  attrs: { model: _vm.creativityForm, "label-width": "120px" },
                },
                [
                  _c(_vm.creativityComponentsList[_vm.crtSizeLabel], {
                    tag: "component",
                    attrs: {
                      "rule-form-data": _vm.creativityForm,
                      "project-id": _vm.projectId,
                      "promoted-object-type": _vm.promotedObjectType,
                    },
                    on: { pageTypeChange: _vm.pageTypeChange },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeCreativity } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }