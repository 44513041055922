import service from '@/network/service/service'

// --------- 产品库 ------------
/**
 * 产品库列表
 */
export const productLibraryList = (params) => service.post(`/api/system/toolProduct/pageSt`, params)

/**
 * 评论列表
 */
export const commentList = (id, num, size) => service.get(`/api/system/toolProduct/commentProductLibray/${id}/${num}/${size}`)

/**
 * 新增评价
 */
export const addCommentList = (params) => service.post(`/api/system/toolProduct/commentProductLibray`, params)

/**
 * 编辑产品详情
 */
export const productDetail = (id) => service.get(`/api/system/toolProduct/getProductLibray/${id}`)

/**
 * 新增或者编辑产品
 */
export const addOrEditProduct = (params) => service.post(`/api/system/toolProduct/saveOrUpdateProductLibray`, params)

/**
 * 删除产品
 */
export const deleteProduct = (id) => service.delete(`/api/system/toolProduct/deleteProductLibray/${id}`)

/**
 * 获取筛选配置
 */
export const filterConfigurationDetail = () => service.get(`/api/system/toolProduct/getFilterSetting`)

/**
 * 筛选配置
 */
export const saveFilterConfiguration = (params) => service.post(`/api/system/toolProduct/saveFilterSetting`, params)

/**
 * 发起筛选
 */
export function initiateScreening(query) {
  return service({
    url: '/api/system/toolProduct/filterPL',
    method: 'post',
    params: query
  })
}

/**
 * 产品评价
 */
export const poductEvaluation = (params) => service.post(`/api/system/toolProduct/commentProductLibray`, params)

/**
 * 付费卡点
 */
export const paidCardPointsList = (id, num, size) => service.get(`/api/system/toolProduct/getPayCardPoint/${id}/${num}/${size}`)

/**
 * 保存付费卡点
 */
export const savePaidCardPoints = (params) => service.post(`/api/system/toolProduct/payCardPoint`, params)

/**
 * 删除付费卡点
 */
export const deletePaidCardPoints = (id) => service.delete(`/api/system/toolProduct/deletePayCardPoint/${id}`)

/**
 * 获取组别
 */
export const getDeptList = (params) => service.get(`/api/system/toolProduct/getDept`, { params })

/**
 * 获取组别-人员
 */
export const getUserList = (params) => service.get(`/api/system/toolProduct/byDeptgetUsers`, { params })

/**
 * 获取测试或者投手
 */
export const getPitcherOrMaterial = (id, type) => service.get(`/api/system/toolProduct/getToolTestOrAssetPerson/${id}/${type}`)

/**
 * 删除测试投手或素材
 */
export const deletePitcherOrMaterial = (id) => service.get(`/api/system/toolProduct/deleteToolTestOrAssetPerson/${id}`)

/**
 * 保存测试投手或素材
 */
export const savePitcherOrMaterial = (params) => service.post(`/api/system/toolProduct/saveToolTestOrAssetPerson`, params)

// --------- 产品筛选 ------------
/**
 * 产品筛选列表
 */
export const productScreeningList = (params) => service.post(`/api/system/toolProduct/pageFilterSel`, params)

/**
 * 取消筛选
 */
export function unfilteringProductScreen(query) {
  return service({
    url: '/api/system/toolProduct/filterPLRemover',
    method: 'post',
    params: query
  })
}

/**
 * 撤销筛选
 */
export function revokeProductScreen(query) {
  return service({
    url: '/api/system/toolProduct/filterPLrevoke',
    method: 'post',
    params: query
  })
}

/**
 * 部门下拉
 */
export function getDept(query) {
  return service({
    url: '/api/system/toolProduct/getDept',
    method: 'get',
    params: query
  })
}

/**
 * 发起投票
 */
export function initiateVoting(query) {
  return service({
    url: '/api/system/toolProduct/filterPLVote',
    method: 'post',
    params: query
  })
}

// --------- 我的投测 ------------
/**
 * 我的投测列表
 */
export const MyProjectionList = (params) => service.post(`/api/system/toolProduct/myLaunchProductFilter`, params)

/**
 * 测试结束
 */
export function testEnd(query) {
  return service({
    url: '/api/system/toolProduct/endTest',
    method: 'post',
    params: query
  })
}

/**
 * 宙斯下拉
 */
export function zeusPulldown(query) {
  return service({
    url: '/api/system/toolProduct/getDramaAccount',
    method: 'get',
    params: query
  })
}

/**
 * 获取投放配置列表
 */
export const launchConfigurationList = (id, num, size) => service.get(`/api/system/toolProduct/getPlatformSetting/${id}/${num}/${size}`)

/**
 * 保存投放配置
 */
export const saveLaunchConfiguration = (params) => service.post(`/api/system/toolProduct/platformSetting`, params)

/**
 * 删除投放配置
 */
export const deleteLaunchConfiguration = (id) => service.delete(`/api/system/toolProduct/deletePlatformSetting/${id}`)

/**
 * 素材详情
 */
export const materialDetailList = (id, num, size) => service.get(`/api/system/toolProduct/getAssetInfo/${id}/${num}/${size}`)

/**
 * 保存素材详情
 */
export const saveMaterialDetail = (params) => service.post(`/api/system/toolProduct/saveAssetInfo`, params)

/**
 * 删除素材详情
 */
export const deleteMaterialDetail = (id) => service.delete(`/api/system/toolProduct/deleteAssetInfo/${id}`)

// --------- 投测数据 ------------
/**
 * 投测数据列表
 */
export const projectionDataList = (params) => service.post(`/api/system/toolProduct/launchData`, params)

/**
 * 获取投测数据
 */
export const getProjectionData = (id, num, size) => service.get(`/api/system/toolProduct/selectItem/${id}/${num}/${size}`)

/**
 * 获取投测数据跳转
 */
export const getProjectionDataJump = (id) => service.get(`/api/system/toolProduct/selectRequestData/${id}`)

/**
 * 测试结果
 */
export const testResult = (toolProductFilterItemId, relust) => service.get(`/api/system/toolProduct/updateFilterStatus/${toolProductFilterItemId}/${relust}`)

// --------- 投测统计 ------------
/**
 * 产品新增
 */
export const staticsProductAdd = (dates, num, size) => service.get(`/api/system/toolProductForms/getProductAdd/${num}/${size}/${dates}`)

/**
 * 部门投测
 */
export const staticsDeptLaunch = (params) => service.post(`/api/system/toolProductForms/getDeptLaunch`, params)

/**
 * 人员投测
 */
export const staticsUserLaunch = (params) => service.post(`/api/system/toolProductForms/getUserLaunch`, params)

/**
 * 平台起量
 */
export const staticsPlatLaunch = (dates, num, size) => service.get(`/api/system/toolProductForms/getPlatforms/${num}/${size}/${dates}`)

/**
 * 人员剪辑明细
 */
export const staticsUserAsset = (params) => service.post(`/api/system/toolProductForms/getAsset`, params)
