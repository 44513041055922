var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "base-box",
        { attrs: { name: "topImg" } },
        [
          _c("p", { attrs: { slot: "desc" }, slot: "desc" }, [
            _vm._v(
              " 投放朋友圈信息流，广告外层将自动拉取顶部图片。 投放公众号及其他广告位，将不会拉取。 "
            ),
          ]),
          _c("template", { slot: "body" }, [
            _c("div", [
              _c("p", { staticClass: "font-13" }, [_vm._v("广告位：")]),
              _c(
                "div",
                { staticClass: "ml-20" },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "sns" },
                      model: {
                        value: _vm.detail.adLocation,
                        callback: function ($$v) {
                          _vm.$set(_vm.detail, "adLocation", $$v)
                        },
                        expression: "detail.adLocation",
                      },
                    },
                    [_vm._v("朋友圈信息流")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "gh" },
                      model: {
                        value: _vm.detail.adLocation,
                        callback: function ($$v) {
                          _vm.$set(_vm.detail, "adLocation", $$v)
                        },
                        expression: "detail.adLocation",
                      },
                    },
                    [_vm._v("公众号及其他广告位")]
                  ),
                ],
                1
              ),
            ]),
            _vm.detail.adLocation === "sns"
              ? _c("div", [
                  _c("p", { staticClass: "font-13" }, [_vm._v("外层样式：")]),
                  _c(
                    "div",
                    { staticClass: "ml-20" },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "0" },
                          model: {
                            value: _vm.detail.viewType,
                            callback: function ($$v) {
                              _vm.$set(_vm.detail, "viewType", $$v)
                            },
                            expression: "detail.viewType",
                          },
                        },
                        [_vm._v("常规广告")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.detail.viewType,
                            callback: function ($$v) {
                              _vm.$set(_vm.detail, "viewType", $$v)
                            },
                            expression: "detail.viewType",
                          },
                        },
                        [_vm._v("卡片广告")]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "image-updata" }, [
              _c("p", { staticClass: "font-13" }, [_vm._v("选择图片：")]),
              _c("div", [
                _c(
                  "div",
                  { staticClass: "update", on: { click: _vm.setMaterialBox } },
                  [
                    _vm.detail.pureImageUrl
                      ? _c("img", {
                          staticClass: "avatar",
                          attrs: { src: _vm.detail.pureImageUrl },
                        })
                      : _c("i", {
                          staticClass: "el-icon-plus avatar-uploader-icon",
                        }),
                  ]
                ),
                _c("div", { staticClass: "tip mt-10" }, [
                  _c("p", [_vm._v("暂不支持含有二维码的图片")]),
                  _c("p", [_vm._v("图片格式：大小不超过300KB，")]),
                  _vm.detail.adLocation === "sns" && _vm.detail.viewType === "0"
                    ? _c("p", { staticClass: "tip" }, [
                        _vm._v(
                          "图片尺寸：800 * 800像素 / 640 * 800像素 / 800 * 640像素"
                        ),
                      ])
                    : _vm._e(),
                  _vm.detail.adLocation === "sns" && _vm.detail.viewType === "1"
                    ? _c("p", { staticClass: "tip" }, [
                        _vm._v("图片尺寸： 800 * 450像素"),
                      ])
                    : _vm._e(),
                  _vm.detail.adLocation === "gh"
                    ? _c("p", { staticClass: "tip" }, [
                        _vm._v(
                          "图片尺寸：800 * 800像素 / 640 * 800像素 / 800 * 640像素 / 800 * 450像素"
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
        ],
        2
      ),
      _c("material-box", {
        attrs: {
          visible: _vm.materialBox_visible,
          "img-count": 1,
          "image-list": _vm.imageList,
          name: "ldy",
          "select-type": "multiple",
          attribute: {
            key: "topImg",
            value: "顶部图片",
            count: 1,
            size: 300 * 1024,
            listType: "image/jpg,image/jpeg,image/png",
            width: _vm.width,
            height: _vm.height,
          },
        },
        on: { setMaterialBox: _vm.setMaterialBox, submit: _vm.selectImg },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }