var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "product-evaluation-dialog",
      attrs: {
        title: "产品评价",
        visible: _vm.show,
        "append-to-body": true,
        width: "550px",
        top: "20vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.onCloseDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _vm.commentList.length
            ? _c(
                "div",
                { staticClass: "comment-list" },
                _vm._l(_vm.commentList, function (item) {
                  return _c("div", { key: item.id, staticClass: "card" }, [
                    _c("div", { staticClass: "title" }, [
                      _c("span", { staticClass: "left" }, [
                        _vm._v(_vm._s(item.toolProductLaunchPerson.userName)),
                      ]),
                      _c("span", { staticClass: "right" }, [
                        _vm._v(_vm._s(item.toolProductComment.createTime)),
                      ]),
                    ]),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(
                        " " +
                          _vm._s(item.toolProductComment.commentContent) +
                          " "
                      ),
                    ]),
                  ])
                }),
                0
              )
            : _c(
                "div",
                [_c("el-empty", { attrs: { description: "暂无评价" } })],
                1
              ),
          _c("el-input", {
            attrs: {
              type: "textarea",
              placeholder: "请输入评论",
              maxlength: "100",
              "show-word-limit": "",
              rows: 4,
            },
            model: {
              value: _vm.textarea,
              callback: function ($$v) {
                _vm.textarea = $$v
              },
              expression: "textarea",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "btn", on: { click: _vm.closeModal } },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { type: "primary", disabled: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("发布")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }