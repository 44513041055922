export const searchForm = {
  masterName: null
  // housekeeperId: null,
  // launchAccountName: null,
  // launchAccountPassword: null,
  // launchAccountNumber: null,
  // projectName: null,
  // token: null,
  // ocpcToken: null,
  // deleted: null,
  // createBy: null,
  // createTime: null,
  // updateBy: null,
  // updateTime: null,
  // remark: null
}

// export class AccountBaiduHousekeeperEnt {
//   housekeeperId
//   launchAccountName
//   launchAccountPassword
//   launchAccountNumber
//   projectName
//   ocpcToken
//   token
//   deleted
//   createBy
//   createTime
//   updateBy
//   updateTime
//   remark
// }

export const formRules = {

  launchAccountName: [{ required: true, message: '请填写账户名称', trigger: 'change' }],
  launchAccountPassword: [{ required: true, message: '请填写密码', trigger: 'change' }],
  projectName: [{ required: true, message: '请填写归属主体', trigger: 'change' }],
  token: [{ required: true, message: '请填写token', trigger: 'change' }],
  ocpcToken: [{ required: true, message: '请填写ocpcToken', trigger: 'change' }]

}
