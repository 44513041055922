import service from '@/network/service/service'

export function startServer(data) {
  return service.get(`/api/Maintain/start?password=${data}`)
}
export function endServer(data) {
  return service.get(`/api/Maintain/end?password=${data}`)
}
export function getServer(data) {
  return service.get(`/api/tencent/monitor/server`)
}
export function exec(data) {
  return service.post('/admin/redis/exec', data)
}

export function uploadFile(params) {
  return service({
    method: 'post',
    url: '/admin/file/upload',
    data: params,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

