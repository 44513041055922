import service from '@/network/service/service'

export function Page(data) {
  return service.post('/api/system/basicSettingLabelType/page', data)
}

export function Add(data) {
  return service.post('/api/system/basicSettingLabelType/save', data)
}

export function Update(data) {
  return service.post('/api/system/basicSettingLabelType/update', data)
}

export function Delete(id) {
  return service.delete('/api/system/basicSettingLabelType/' + id)
}

