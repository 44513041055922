
<template>
  <!--显示列弹框-->
  <el-dialog
    :visible.sync="show"
    :append-to-body="true"
    :title="dialogTitle"
    width="500px"
    top="20vh"
    @close="onCloseDialog"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="专辑" prop="albumId">
        <el-select v-model="form.albumId" filterable clearable placeholder="请选择专辑" style="width: 100%;" @change="handleAlbum">
          <el-option v-for="(itm, idx) in albumList" :key="idx" :label="itm.albumName" :value="itm.albumId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="素材组" prop="materialGroupId">
        <el-select v-model="form.materialGroupId" :disabled="!form.albumId" filterable clearable placeholder="请选择素材组" style="width: 100%;">
          <el-option v-for="(itm, idx) in materialGroupList" :key="idx" :label="itm.materialGroupName" :value="itm.materialGroupId"></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">取 消</el-button>
      <el-button class="btn" type="primary" :loading="isLoading" :disabled="isLoading" @click="clickConfirm('form')">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  batchCopyImageMarterial,
  batchCopyVideoMarterial,
  getAlbumAllList,
  getMarterialGroupAllList
} from '@/network/api/api-material'
import { getMeRelationProject } from '@/network/api/basicSetting/project/basicSettingProject'
import { mapGetters } from 'vuex'
export default {
  name: 'BatchCopyMaterialDialog',
  components: {
  },
  filters: {},
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      show: false, // 是否显示弹框
      dialogTitle: '',
      materialGroupList: [],
      albumList: [],
      isLoading: false, // 是否正在加载
      form: {
        albumId: '',
        materialGroupId: ''
      }, // 表单数据
      rules: {
        materialGroupId: [{ required: true, message: '请选择素材组', trigger: 'blur' }],
        albumId: [{ required: true, message: '请选择专辑', trigger: 'blur' }]
      } // 表单校验规则
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  watch: {},
  created () {
  },
  mounted () {
  },
  methods: {
    /* --------------------------- Actions --------------------------- */
    // 显示弹框
    showModal (ids) {
      this.show = true
      this.ids = ids
      this.dialogTitle = '复制素材'
      this._getProject()
    },
    // 专辑有改动的回调
    handleAlbum (e) {
      if (e) {
        this._getMarterialGroupAllList(e)
      } else {
        this.form.materialGroupId = ''
      }
    },

    // 关闭弹框
    closeModal () {
      this.show = false
    },
    // 监听 Dialog 弹框关闭
    onCloseDialog () {
      // 重置表单
      this.$refs['form'].resetFields()

      // 清除数据
      this.form = {
        albumId: '',
        materialName: ''
      }
    },

    // 点击确定按钮
    clickConfirm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.type === 'image' ? this._batchCopyImageMarterial() : this._batchCopyVideoMarterial()
        } else {
          this.$WarningMessage('请将表单填写完整')
        }
      })
    },
    /* --------------------------- Private --------------------------- */
    // 获取项目列表(个人可见)
    _getProject () {
      getMeRelationProject().then(res => {
        if (res.code === 200) {
          this.projectList = res.data
          let ids = res.data.map(item => {
            return item.bsProjectId
          })
          this._getAlbumAllList(ids)
        }
      })
    },
    // 获取专辑列表
    _getAlbumAllList (ids) {
      let params = {
        type: this.type,
        projectIds: ids
      }
      getAlbumAllList(params).then(res => {
        this.albumList = res.data
      })
    },
    // 获取素材组列表
    _getMarterialGroupAllList (albumId) {
      let params = {
        type: this.type,
        albumId: albumId
      }
      getMarterialGroupAllList(params).then(res => {
        this.materialGroupList = res.data
      })
    },
    // 批量复制图片素材
    _batchCopyImageMarterial () {
      this.isLoading = true
      let params = {}
      params.imageIds = this.ids
      params.albumId = this.form.albumId
      params.materialGroupId = this.form.materialGroupId
      params.adminId = this.userInfo.userid
      params.nickname = this.userInfo.username
      batchCopyImageMarterial(params).then(res => {
        // this.$SuccessMessage('复制视频素材成功')
        this.$emit('edit')
        this.closeModal()
      }).catch(err => {
        this.$ErrorMessage(err.data || '复制视频素材失败')
      }).finally(() => {
        this.isLoading = false
      })
    },
    // 批量复制视频素材
    _batchCopyVideoMarterial () {
      this.isLoading = true
      let params = {}
      params.videoIds = this.ids
      params.albumId = this.form.albumId
      params.materialGroupId = this.form.materialGroupId
      params.adminId = this.userInfo.userid
      params.nickname = this.userInfo.username
      batchCopyVideoMarterial(params).then(res => {
        // this.$SuccessMessage('复制图片素材成功')
        this.$emit('edit')
        this.closeModal()
      }).catch(err => {
        this.$ErrorMessage(err.data || '复制图片素材失败')
      }).finally(() => {
        this.isLoading = false
      })
    }
  }
}
</script>
<style>
</style>
