var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      attrs: {
        title: "创意基本信息",
        size: "900px",
        "append-to-body": true,
        "custom-class": "dialog",
        visible: _vm.show,
        "label-position": "right",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.onCloseDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "drawer-content" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("创意信息")]),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-width": "130px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "创意方式", prop: "creativeType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      model: {
                        value: _vm.form.creativeType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "creativeType", $$v)
                        },
                        expression: "form.creativeType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "程序化创意", value: 1 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "基础创意组件", prop: "sourceType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      model: {
                        value: _vm.form.sourceType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "sourceType", $$v)
                        },
                        expression: "form.sourceType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "自主创建", value: 1 },
                      }),
                      _c("el-option", {
                        attrs: { label: "选择已有", value: 2 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.form.sourceType === 1
                ? _c("el-form-item", { attrs: { label: "推广卡片" } }, [
                    _c(
                      "div",
                      { staticClass: "tuiguang" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "tuiguang-item",
                            attrs: { label: "卡片主图", prop: "imageUrl" },
                          },
                          [
                            _c(
                              "el-upload",
                              {
                                staticClass: "avatar-uploader",
                                attrs: {
                                  action: _vm.UPLOAD_URL,
                                  "show-file-list": false,
                                  "before-upload": (file) =>
                                    _vm.beforeAvatarUpload(file),
                                  "on-success": (res) =>
                                    _vm.handleAvatarSuccess(res),
                                },
                              },
                              [
                                _vm.form.imageUrl
                                  ? _c("img", {
                                      staticClass: "avatar",
                                      staticStyle: {
                                        border: "1px solid #ccc",
                                        "border-radius": "4px",
                                      },
                                      attrs: { src: _vm.form.imageUrl },
                                    })
                                  : _c("i", {
                                      staticClass:
                                        "el-icon-plus avatar-uploader-icon",
                                    }),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#999",
                                  "font-size": "12px",
                                },
                              },
                              [
                                _vm._v(
                                  "尺寸：108px*108px, 格式：JPG、JPEG、PNG格式, 大小：1M以内"
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "tuiguang-item",
                            attrs: { label: "卡片标题", prop: "title" },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "300px" },
                              attrs: { maxlength: "20", "show-word-limit": "" },
                              model: {
                                value: _vm.form.title,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "title", $$v)
                                },
                                expression: "form.title",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "tuiguang-item",
                            attrs: { label: "推广卖点" },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  size: "mini",
                                  plain: "",
                                  disabled: _vm.products.length >= 10,
                                },
                                on: { click: _vm.handleAddPoint },
                              },
                              [_vm._v("添加 ")]
                            ),
                            _vm._v(" " + _vm._s(_vm.products.length) + "/10 "),
                            _vm._l(_vm.products, function (t, i) {
                              return _c(
                                "div",
                                { key: i, staticClass: "tag-input" },
                                [
                                  _c("el-input", {
                                    key: i,
                                    staticStyle: { width: "300px" },
                                    attrs: {
                                      minlength: "6",
                                      maxlength: "9",
                                      "show-word-limit": "",
                                      placeholder: "请输入推广卖点",
                                    },
                                    model: {
                                      value: _vm.products[i],
                                      callback: function ($$v) {
                                        _vm.$set(_vm.products, i, $$v)
                                      },
                                      expression: "products[i]",
                                    },
                                  }),
                                  _vm.products.length > 1
                                    ? _c("i", {
                                        staticClass: "el-icon-delete opt-icon",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDeletePoint(i)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            }),
                          ],
                          2
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "tuiguang-item",
                            attrs: { label: "行动号召", prop: "buttonText" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "300px" },
                                attrs: { placeholder: "请选择行动号召" },
                                model: {
                                  value: _vm.form.buttonText,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "buttonText", $$v)
                                  },
                                  expression: "form.buttonText",
                                },
                              },
                              _vm._l(
                                _vm.activeTextList,
                                function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: { label: item, value: item },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "tuiguang-item",
                            attrs: {
                              label: "智能优选",
                              prop: "enablePersonalAction",
                            },
                          },
                          [
                            _c("el-switch", {
                              attrs: { "active-value": 1, "inactive-value": 0 },
                              model: {
                                value: _vm.form.enablePersonalAction,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "enablePersonalAction",
                                    $$v
                                  )
                                },
                                expression: "form.enablePersonalAction",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticClass: "tuiguang-item",
                            attrs: { label: "组件名称", prop: "componentName" },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "300px" },
                              attrs: { maxlength: "20", "show-word-limit": "" },
                              model: {
                                value: _vm.form.componentName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "componentName", $$v)
                                },
                                expression: "form.componentName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.form.sourceType === 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "配置组件" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: { click: _vm.onClickCreativeComponent },
                        },
                        [_vm._v("配置")]
                      ),
                      _c("span", { staticStyle: { "margin-left": "12px" } }, [
                        _vm._v(
                          "已选择" + _vm._s(_vm.componentLength) + "个创意组件"
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "来源", prop: "source" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      placeholder: "请输入来源",
                      maxlength: "20",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.form.source,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "source", $$v)
                      },
                      expression: "form.source",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "title" }, [_vm._v("原生广告设置")]),
              _c(
                "el-form-item",
                { attrs: { label: "原生广告" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px", "margin-right": "15px" },
                      model: {
                        value: _vm.form.douyin,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "douyin", $$v)
                        },
                        expression: "form.douyin",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "不使用", value: 1 } }),
                      _c("el-option", { attrs: { label: "使用", value: 2 } }),
                    ],
                    1
                  ),
                  _vm.form.douyin == 2
                    ? _c(
                        "el-checkbox",
                        {
                          staticStyle: { "margin-bottom": "5px" },
                          attrs: { "true-label": 1, "false-label": 0 },
                          model: {
                            value: _vm.form.isFeedAndFavSee,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "isFeedAndFavSee", $$v)
                            },
                            expression: "form.isFeedAndFavSee",
                          },
                        },
                        [_vm._v(" 主页作品列表隐藏广告视频")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "广告评论" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      model: {
                        value: _vm.form.isCommentDisable,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "isCommentDisable", $$v)
                        },
                        expression: "form.isCommentDisable",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "关闭", value: 1 } }),
                      _c("el-option", { attrs: { label: "启用", value: 0 } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "客户端视频下载" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      model: {
                        value: _vm.form.adDownloadStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "adDownloadStatus", $$v)
                        },
                        expression: "form.adDownloadStatus",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "关闭", value: 1 } }),
                      _c("el-option", { attrs: { label: "启用", value: 0 } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "title" }, [_vm._v("创意分类和标签")]),
              _c(
                "el-form-item",
                { attrs: { label: "创意分类" } },
                [
                  _c("el-cascader", {
                    ref: "cascader",
                    staticStyle: { width: "300px" },
                    attrs: {
                      "show-all-levels": false,
                      props: _vm.props,
                      clearable: "",
                      options: _vm.classify,
                      placeholder: "请选择分类",
                    },
                    on: { change: _vm.handleChangeValue },
                    model: {
                      value: _vm.form.thirdIndustryIds,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "thirdIndustryIds", $$v)
                      },
                      expression: "form.thirdIndustryIds",
                    },
                  }),
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "创意标签" } }, [
                _c(
                  "div",
                  { staticClass: "tag" },
                  [
                    _c("el-input", {
                      staticStyle: { width: "300px", "margin-right": "15px" },
                      attrs: {
                        placeholder:
                          "空格分隔,最多二十个,每个标签不超过10个字符",
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handleAddTag.apply(null, arguments)
                        },
                      },
                      model: {
                        value: _vm.tagText,
                        callback: function ($$v) {
                          _vm.tagText = $$v
                        },
                        expression: "tagText",
                      },
                    }),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          plain: "",
                          disabled: _vm.tagText === "",
                        },
                        on: { click: _vm.handleAddTag },
                      },
                      [_vm._v("添加")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: { click: _vm.handleSelectHaveTag },
                      },
                      [_vm._v("已有标签")]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "mg-card-box" }, [
                  _c("div", { staticClass: "mg-card-header" }, [
                    _c("div", { staticClass: "mg-card-title" }, [
                      _vm._v("已添加标签"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "tag-clear" },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "font-size": "12px" },
                            attrs: { type: "text" },
                            on: { click: _vm.handleClearTag },
                          },
                          [_vm._v("清空")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "mg-card-body" },
                    _vm._l(_vm.tagList, function (item, index) {
                      return _c("div", { key: index, staticClass: "mg-tag" }, [
                        _vm._v(" " + _vm._s(item) + " "),
                        _c("span", {
                          staticClass: "del el-icon-close",
                          on: {
                            click: function ($event) {
                              return _vm.handleDeleteTag(index)
                            },
                          },
                        }),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "dialog-footer" }, [
        _c(
          "div",
          [
            _c(
              "el-checkbox",
              {
                model: {
                  value: _vm.form.saveAsPreset,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "saveAsPreset", $$v)
                  },
                  expression: "form.saveAsPreset",
                },
              },
              [_vm._v("存为预设")]
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-button",
              { staticClass: "btn", on: { click: _vm.closeModal } },
              [_vm._v("取 消")]
            ),
            _c(
              "el-button",
              {
                staticClass: "btn",
                attrs: {
                  type: "primary",
                  loading: _vm.isLoading,
                  disabled: _vm.isLoading,
                },
                on: { click: _vm.handleConfirm },
              },
              [_vm._v("确 定")]
            ),
          ],
          1
        ),
      ]),
      _c("tag-list-dialog", {
        ref: "tagListDialog",
        on: { confirm: _vm.handleTag },
      }),
      _c("creative-component-dialog", {
        ref: "creativeComponentDialog",
        on: { confirm: _vm.handleComponent },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }