var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Search", { ref: "search" }),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { staticClass: "elStyle", attrs: { span: 6 } },
            [
              _c(
                "el-row",
                {
                  staticClass: "operate-box",
                  staticStyle: { display: "flex", "justify-content": "center" },
                  attrs: { type: "flex", justify: "space-between" },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { type: "primary", icon: "el-icon-plus" },
                      on: { click: _vm.showAdd },
                    },
                    [_vm._v("新增标签分类")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "table-container" },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "list-table",
                      attrs: { stripe: "", data: _vm.data },
                    },
                    [
                      _c("c-data-empty", {
                        attrs: { slot: "empty" },
                        slot: "empty",
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "labelTypeName",
                          label: "分类名称",
                          align: "center",
                          "show-overflow-tooltip": true,
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "relationProjectName",
                          label: "所属项目",
                          align: "center",
                          "show-overflow-tooltip": true,
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "num",
                          label: "标签数量",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    style:
                                      scope.row.color === "NO"
                                        ? _vm.tableNo
                                        : _vm.tableNum,
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.buttonNum(
                                          scope.row.bsLabelTypeId
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "u",
                                      {
                                        style:
                                          scope.row.color !== "NO"
                                            ? "color:#fff"
                                            : "",
                                      },
                                      [_vm._v(_vm._s(scope.row.num))]
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          fixed: "right",
                          "show-overflow-tooltip": true,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showEdit(row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteRow(row)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("c-pagination", {
                attrs: {
                  "page-size": _vm.pageInfo.pageSize,
                  "page-no": _vm.pageInfo.pageNum,
                  total: _vm.pageInfo.total,
                  "get-data-fun": _vm.loadData,
                },
                on: {
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.pageInfo, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.pageInfo, "pageSize", $event)
                  },
                  "update:pageNo": function ($event) {
                    return _vm.$set(_vm.pageInfo, "pageNum", $event)
                  },
                  "update:page-no": function ($event) {
                    return _vm.$set(_vm.pageInfo, "pageNum", $event)
                  },
                },
              }),
              _c("OptionDialog", { ref: "dialog" }),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "elStyle ml-10", attrs: { span: 17 } },
            [
              _c(
                "el-row",
                {
                  staticClass: "operate-box",
                  attrs: { type: "flex", justify: "space-between" },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    [
                      _c("el-alert", {
                        attrs: {
                          title: "分类名称：" + _vm.labelTypeObj.labelTypeName,
                          type: "info",
                          center: "",
                          closable: false,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "ml-5",
                          attrs: {
                            type: "primary",
                            icon: "el-icon-plus",
                            round: "",
                          },
                          on: { click: _vm.showAddLabel },
                        },
                        [_vm._v("新 增")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "table-container" },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "list-table",
                      attrs: { stripe: "", data: _vm.dataLabel },
                    },
                    [
                      _c("c-data-empty", {
                        attrs: { slot: "empty" },
                        slot: "empty",
                      }),
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "序号",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "labelName",
                          label: "标签名称",
                          align: "center",
                          "show-overflow-tooltip": true,
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showEd(row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteRowLabel(row)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("c-pagination", {
                attrs: {
                  "page-size": _vm.pageInfoLable.pageSize,
                  "page-no": _vm.pageInfoLable.pageNum,
                  total: _vm.pageInfoLable.total,
                  "get-data-fun": _vm.labelPage,
                },
                on: {
                  "update:pageSize": function ($event) {
                    return _vm.$set(_vm.pageInfoLable, "pageSize", $event)
                  },
                  "update:page-size": function ($event) {
                    return _vm.$set(_vm.pageInfoLable, "pageSize", $event)
                  },
                  "update:pageNo": function ($event) {
                    return _vm.$set(_vm.pageInfoLable, "pageNum", $event)
                  },
                  "update:page-no": function ($event) {
                    return _vm.$set(_vm.pageInfoLable, "pageNum", $event)
                  },
                },
              }),
              _c("OptionDialogLabel", { ref: "dialogLabel" }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }