
<template>
  <!--显示列弹框-->
  <el-dialog
    :visible.sync="show"
    :append-to-body="true"
    width="600px"
    top="20vh"
    @close="onCloseDialog"
  >
    <!--自定义 title-->
    <span slot="title" class="dialog-title">{{ dialogTitle }}</span>
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="专辑名称" prop="albumName">
        <el-input v-model.trim="form.albumName" clearable show-word-limit maxlength="30" placeholder="请输入专辑名称" @blur="blurInput"></el-input>
      </el-form-item>
      <el-form-item label="关联项目" prop="projectId">
        <el-select v-model="form.projectId" filterable clearable placeholder="请选择关联项目" style="width: 100%;" @change="handleSelect">
          <el-option v-for="(itm, idx) in projectList" :key="idx" :label="itm.projectName" :value="itm.bsProjectId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关联产品" prop="productId">
        <el-select v-model="form.productId" filterable :disabled="!form.projectId" clearable placeholder="请选择关联产品" style="width: 100%;">
          <el-option v-for="(itm, idx) in productList" :key="idx" :label="itm.productName" :value="itm.bsProductId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="标签" prop="tag">
        <el-select v-model="form.tag" multiple :disabled="!form.projectId" filterable clearable placeholder="请选择标签" style="width: 100%;">
          <el-option v-for="(itm, idx) in tagList" :key="idx" :label="itm.labelName" :value="itm.bsLabelId"></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">取 消</el-button>
      <el-button class="btn" type="primary" :loading="isLoading" :disabled="isLoading" @click="clickConfirm('form')">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  getTagList,
  getAlbumDetail,
  addAlbum,
  editAlbum,
  findAlbumList
} from '@/network/api/api-material'
import { getMeRelationProject } from '@/network/api/basicSetting/project/basicSettingProject'
import { getProductByProject } from '@/network/api/basicSetting/product/basicSettingProduct'
export default {
  name: 'EditAlbumDialog',
  components: {
  },
  filters: {},
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      show: false, // 是否显示弹框
      dialogTitle: '',
      projectList: [], // 项目列表
      productList: [], // 产品列表
      tagList: [], // 标签列表
      list: [],
      isLoading: false, // 是否正在加载
      id: '',
      form: {
        albumName: '',
        productId: '',
        projectId: '',
        tag: []
      }, // 表单数据
      lastAlbumName: '', // 编辑时刚进入详情页的专辑名称
      rules: {
        albumName: [{ required: true, message: '请输入专辑名称', trigger: 'blur' }],
        productId: [{ required: true, message: '请选择关联产品', trigger: 'blur' }],
        projectId: [{ required: true, message: '请选择关联项目', trigger: 'blur' }],
        tag: [{ required: true, message: '请选择标签', trigger: 'blur' }]
      } // 表单校验规则
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  watch: {},
  created () {
  },
  mounted () {
  },
  methods: {
    /* --------------------------- Actions --------------------------- */
    // 显示弹框
    showModal (id, projectId) {
      this.show = true
      this.dialogTitle = id ? '编辑专辑' : '新增专辑'
      this._getProject()
      id && this._getAlbumDetail(id, projectId)
      this.id = id
    },

    // 关闭弹框
    closeModal () {
      this.show = false
    },
    // 监听 Dialog 弹框关闭
    onCloseDialog () {
      // 重置表单
      this.$refs['form'].resetFields()

      // 清除数据
      this.form = {
        albumName: '',
        productId: '',
        projectId: '',
        tag: []
      }
      this.projectList = [] // 项目列表
      this.productList = [] // 产品列表
      this.tagList = []
      this.list = []
      this.id = ''
      this.lastAlbumName = ''
    },

    // 点击确定按钮
    clickConfirm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this._validate()) {
            this.id ? this._editAlbum() : this._addAlbum()
          }
        } else {
          this.$WarningMessage('请将表单填写完整')
        }
      })
    },
    // 校验数据
    _validate () {
      if (this.form.albumName === this.lastAlbumName) {
        return true
      } else {
        if (this.list.length === 0) {
          return true
        } else {
          this.$ErrorMessage('专辑名称已存在，请重新输入')
          return false
        }
      }
    },
    // 输入框失去焦点事件
    blurInput () {
      let params = {
        albumName: this.form.albumName
      }
      findAlbumList(params).then(res => {
        if (res.code === 200) {
          this.list = res.data
        }
      })
    },
    // 选择关联项目之后
    handleSelect (e) {
      if (e !== '') {
        this.form.productId = ''
        this.form.tag = []
        this._getProduct(e)
        this._getTagList(e)
      }
    },
    /* --------------------------- Private --------------------------- */
    // 获取项目列表(个人可见)
    _getProject() {
      getMeRelationProject().then(res => {
        if (res.code === 200) {
          this.projectList = res.data
        }
      })
    },
    // 获取产品列表(个人可见)
    _getProduct(val) {
      getProductByProject(val).then(res => {
        if (res.code === 200) {
          this.productList = res.data
        }
      })
    },
    // 获取标签列表(个人可见)
    _getTagList(val) {
      getTagList(val).then(res => {
        if (res.code === 200) {
          this.tagList = res.data
        }
      })
    },
    // 新增专辑
    _addAlbum () {
      this.isLoading = true
      let params = {}
      let tag = this.form.tag.join(',')
      params.tag = tag
      params.albumName = this.form.albumName
      params.productId = this.form.productId
      params.projectId = this.form.projectId
      params.type = this.type
      params.adminId = this.userInfo.userid
      params.nickname = this.userInfo.username
      addAlbum(params).then(res => {
        this.$emit('edit')
        this.closeModal()
      }).catch(err => {
        this.$ErrorMessage(err.data || '新增专辑失败')
      }).finally(() => {
        this.isLoading = false
      })
    },

    // 获取专辑详情
    async _getAlbumDetail (id, projectId) {
      this.isLoading = true
      await this._getProduct(projectId)
      await this._getTagList(projectId)
      getAlbumDetail({ id: id }).then(res => {
        this.form.albumName = res.data.albumName
        this.lastAlbumName = res.data.albumName
        this.form.productId = res.data.productId
        this.form.projectId = res.data.projectId
        let tag = []
        let tags = res.data.tag.split(',')
        for (let i in tags) {
          tag.push(Number(tags[i]))
        }
        this.form.tag = tag
      }).catch(err => {
        this.$ErrorMessage(err.data || '获取专辑详情失败')
      }).finally(() => {
        this.isLoading = false
      })
    },

    // 编辑专辑
    _editAlbum () {
      this.isLoading = true
      let params = {}
      let tag = this.form.tag.join(',')
      params.tag = tag
      params.albumName = this.form.albumName
      params.productId = this.form.productId
      params.projectId = this.form.projectId
      params.type = this.type
      params.adminId = this.userInfo.userid
      params.nickname = this.userInfo.username
      editAlbum(this.id, params).then(res => {
        // this.$SuccessMessage('编辑专辑成功')
        this.$emit('edit')
        this.closeModal()
      }).catch(err => {
        this.$ErrorMessage(err.data || '编辑专辑失败')
      }).finally(() => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
