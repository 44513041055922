<!--
 * @Description: 公众号文章底部视频
 * @Date: 2022-01-06 10:42:13
 * @LastEditTime: 2022-03-04 15:18:10
-->
<template>
  <div>
    <el-form-item label="创意样式：" prop="crtSize">
      <el-radio-group v-model="ruleFormData.crtSize">
        <el-radio :label="538">视频式卡片</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="信息栏类型：" prop="headDescType">
      <el-radio-group v-model="ruleFormData.headDescType">
        <el-radio :label="2">文案型</el-radio>
      </el-radio-group>
    </el-form-item>
    <!-- -------_ -->
    <el-form-item label="结束页：" prop="isEndPage">
      <el-switch v-model="ruleFormData.isEndPage" @change="endPageFlagChange" />
    </el-form-item>
    <!--  -->
    <el-form-item v-if="ruleFormData.isEndPage" label="结束页类型：" prop="endPageType">
      <el-radio-group v-model="ruleFormData.endPageType">
        <el-radio :label="1">突出头像昵称</el-radio>
        <el-radio :label="2">突出文案</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item v-if="ruleFormData.endPage === 1" label="结束页文案：" prop="endPageDesc" :rules="{ required: true, message: '请输入结束页文案' }">
      <el-input v-model="ruleFormData.endPageDesc" style="width: 400px" placeholder="请输入结束页文案" maxlength="12" show-word-limit />
    </el-form-item>

    <!-- ------ -->

    <el-form-item label="点击跳转：" prop="pageType">
      <el-radio-group
        v-model="ruleFormData.pageType"
        @change="
          (e) => {
            this.$emit('pageTypeChange', e)
          }
        "
      >
        <el-radio :label="4">原生推广页</el-radio>
        <el-radio :label="6">公众号详情页</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item v-if="ruleFormData.pageType === 4" label="点击按钮跳转：" prop="buttonPageType">
      <el-radio-group v-model="ruleFormData.buttonPageType">
        <el-radio :label="4">原生推广页</el-radio>
        <el-radio :label="40024">一键关注</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="按钮文案：" prop="buttonParam">
      <!-- /推广公众号 -->
      <el-radio-group v-if="promotedObjectType === 'PRODUCTTYPE_WECHAT'" v-model="ruleFormData.buttonParam">
        <el-radio label="关注公众号">关注公众号</el-radio>
        <el-radio label="阅读小说">阅读小说</el-radio>
      </el-radio-group>
      <el-radio-group v-else v-model="ruleFormData.buttonParam">
        <el-radio label="查看详情">查看详情</el-radio>
        <el-radio label="立即咨询">立即咨询</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="标签展示：" prop="mpTagType">
      <el-radio-group v-model="ruleFormData.mpTagType">
        <el-radio :label="0">不启用</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="创意名称：" prop="tname" :rules="{ required: true, maxlength: 60, message: '请输入创意名称' }">
      <el-input v-model="ruleFormData.tname" rows="4" type="textarea" style="width: 400px" placeholder="请输入创意名称" maxlength="60" show-word-limit />
    </el-form-item>
  </div>
</template>

<script>
export default {
  props: {
    ruleFormData: {
      type: Object,
      default: () => {}
    },
    taskId: {
      type: Number,
      default: 0
    },
    promotedObjectType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      ruleForm: { ...this.ruleFormData, endPageTyp: 1 }
    }
  },

  methods: {
    endPageFlagChange(e) {
      this.ruleFormData.endPageType = e ? 1 : null
    }
  }
}
</script>

<style lang="scss"></style>
