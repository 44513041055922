/*
 * @Description: 巨量回传Api
 * @Date: 2021-11-24 15:47:31
 * @LastEditTime: 2022-05-18 17:00:38
 */
import service from '@/network/service/service'

export function Page(data) {
  return service.post('/api/system/comRule/page', data)
}

export function manualCallback(data) {
  return service.post('/api/system/returnData/user/v2/manualCallback', data)
}

export function byPkUniqueGet(pkUnique) {
  return service.get(`/api/system/comRule/byPkUniqueGet/${pkUnique}`)
}

/*
 * @description: 新建规则
 */
export function Save(data) {
  return service.post('/api/system/comRule/save', data)
}

/*
 * @description: 新建规则
 */
export function Update(data) {
  return service.post('/api/system/comRule/update', data)
}

/*
 * @description: 获取关联账号
 */
export function ruleRelation({ ruleId, pageSize, pageNum, name }) {
  return service.get(`/api/system/comRule/getRelativeAccount/${ruleId}/${pageSize}/${pageNum}/${name}`)
}

/*
 * @description: 获取关联计划
 */
export function rulePlan(data) {
  return service.post('/api/system/comRule/ruleRelation', data)
}

/*
 * @description: 获取回传日志
 */
export function ruleReturnLog(data) {
  return service.post('/api/system/comRule/getReturnLog', data)
}

// export function ruleReturnLog(pkUnique) {
//   return service.get(`/api/system/comRule/getReturnLog/${pkUnique}`)
// }

/*
 * @description: 获取回传修改日志
 */
export function ruleOperateLog(data) {
  return service.post('/api/system/comRuleOptLog/page', data)
}

/*
 * @description: 巨量规则关系解除
 */
export const dismissRelation = (ruleId, ghid) => service.get(`/api/system/comRule/relieve/${ruleId}/${ghid}`)

/*
 * @description: 激活回传
 */
export function activationPage(data) {
  return service.post('/api/system/returnData/user/page ', data)
}

/*
 * @description: 转化回传
 */
export function transformPage(data) {
  return service.post('/api/system/returnData/order/page ', data)
}

/*
 * @description: 转化回传 -> 获取假回传列表
 */
export function virtualCallbackPage(data) {
  return service.post('/api/system/returnData/virtualCallback/page ', data)
}

/*
 * @description: 删除计划
 */
export function deleteHandHugeReturn(pk_unique) {
  return service.delete(`/api/system/comRule/${pk_unique}`)
}

/*
 * @description: 手动回传
 */
// export function handHugeReturn(recordId) {
//   return service.get(`/api/system/returnData/order/callback/${recordId}`)
// }
export function handHugeReturn(data) {
  return service.post('/api/system/returnData/order/v2/callback', data)
}

/** 回传日志 */
export function callbackLog(data) {
  return service.post('/api/system/returnData/order/v2/callback/log', data)
}

/*
 * @description: 假回传
 */
// export function virtualCallback({ recordId, adRecordId }) {
//   return service.get(`/api/system/returnData/order/virtualCallback/${recordId}/${adRecordId}`)
// }
export function virtualCallback(data) {
  return service.post(`/api/system/returnData/order/v2/virtualCallback`, data)
}

/*
 * @description: 用户匹配率
 */
export function matchRatePage() {
  return service.get(`/api/system/order/virtualCallback`)
}
