<!--
 * @Description: 工作区详情
 * @Date: 2021-10-18 16:12:24
 * @LastEditTime: 2024-03-29 09:29:41
-->
<template>
  <section>
    <draggable v-model="finalWidgets" :animation="500" filter=".not-drag" @end="end">
      <transition-group tag="div" style="min-height: 720px">
        <!-- style="height: 750px;overflow: hidden auto;" -->
        <div v-for="(ele, index) in finalWidgets" :key="index" :class="{
          active: seletedWidgetsIndex === index, widget: true,
          'is-button-top not-drag': ele.type === 'button' && ele.btnPosition === 2,
          'is-button-bottom not-drag': ele.type === 'button' && ele.btnPosition === 3,
          'is-button-define not-drag': ele.type === 'button' && ele.btnPosition === 1
        }" :style="{ top: `${ele.type === 'button' && ele.btnPosition === 1 ? ele.marginTop + 'px' : 'auto'}` }" @click="setSelectedIndex(index)">
          <el-tooltip effect="light" placement="right-start">
            <template slot="content">
              <i class="el-icon-delete-solid" @click="deleteItem(index)"></i>
            </template>
            <div>
              <component :is="ele.component" :detail="ele" />
            </div>
          </el-tooltip>
        </div>
      </transition-group>
    </draggable>
  </section>
</template>

<script>
import draggable from 'vuedraggable'
import Img from '../workbench_component/img.vue'
import Text from '../workbench_component/text.vue'
import Base from '../workbench_component/base.vue'
export default {
  components: {
    draggable,
    'm-img': Img,
    'm-text': Text,
    'm-base': Base
  },
  props: {
    // 选中的索引
    finalWidgets: {
      type: Array,
      default: () => []
    },
    // 选中的索引
    seletedWidgetsIndex: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      component: 'm-image'
    }
  },

  methods: {
    /**
     * @description: 设置当前选中的组件
     * @param {number} index 当前选中的索引
     */
    setSelectedIndex(index) {
      this.$emit('setSelectedIndex', index)
    },

    /**
     * @description: 删除一个组件
     * @param {number} index 当前选中的索引
     */
    deleteItem(index) {
      this.finalWidgets.splice(index, 1)
      // 让最后一个组件选中
      let i = this.finalWidgets.length === 0 ? -1 : this.finalWidgets.length - 1
      this.$emit('setSelectedIndex', i)
      this.$emit('setFinalWidgetsCount', this.finalWidgets.length)
    },

    end() {
      this.$emit('updateFinalWidgets', this.finalWidgets)
    }
  }
}
</script>

<style lang="scss" scoped>
.widget {
  border: 1px solid transparent;
}

.active {
  border: 1px solid #409eff;
}

.is-button-top {
  position: absolute;
  top: 0 !important;
  left: 0;
  right: 0;
  z-index: 1;
}

.is-button-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.is-button-define {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
}

.button-widgets-box {
  width: 100%;
}
</style>
