<!--
 * @Description: 定向包
 * @Date: 2022-01-07 14:52:55
 * @LastEditTime: 2022-05-19 13:48:01
-->

<template>
  <div style="height: 300px;">
    <div v-if="orientationData.flag">
      <ul class="crowd-pack-selected2">
        <li v-for="item in orientationData.list" :key="item.targetingsId">
          <span>{{ item.targetingsName }}</span>
          <i class="el-icon-circle-close" @click="deleteSelectInterest(item)"></i>
        </li>
      </ul>
    </div>
    <div v-else class="kong">请选定向包</div>

    <el-dialog title="定向包" :visible="visible" width="920px" :modal-append-to-body="false" destroy-on-close @open="open" @close="resetForm()">
      <el-row :gutter="10">
        <el-col :span="16">
          <el-input v-model="name" placeholder="请输入定向名称查询" class="input-with-select mb-10 w-300">
            <el-button slot="append" icon="el-icon-search" @click="getPage"></el-button>
          </el-input>
          <TablePane ref="TablePane" :data-source="dataSource" @getPage="getPage" />
        </el-col>
        <el-col :span="8">
          <p class="crowd-pack-selected-title">已选定向包</p>
          <p class="tip">已发布</p>
          <ul class="crowd-pack-selected">
            <li v-for="item in orientationData.list.filter((f) => f.adTargetId)" :key="item.id">
              <span>{{ item.targetingsName }}</span>
              <!-- <i class="el-icon-circle-close" @click="deleteSelectInterest(item)"></i> -->
            </li>
          </ul>
          <ul class="crowd-pack-selected">
            <p class="tip">未发布</p>
            <li v-for="item in orientationData.list.filter((f) => f.adTargetId === null)" :key="item.id">
              <span>{{ item.targetingsName }}</span>
              <i class="el-icon-circle-close" @click="deleteSelectInterest(item)"></i>
            </li>
          </ul>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" :disabled="!orientationData.list.filter((f) => f.adTargetId === null).length" @click="onSubmit">确定</el-button>
        <el-button @click="visible = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TablePane from '@/components/tablePane.vue'
import accountUinTencent from '@/mixin/accountUin_tencent'
import { targetingsPage } from '@/network/api/assetManagement/assetManageWeChatAdv.js'
import { saveOrientation, deleteOrientation } from '@/network/api/advManagement/advManagementWatch'
// deleteOrientation

export default {
  components: {
    TablePane
  },
  inject: ['close'],
  mixins: [accountUinTencent],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    formData: {
      type: Object,
      default: () => {}
    },
    taskId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      name: null,
      orientationData: {},
      dataSource: {
        data: [],
        cols: [
          {
            label: '定向名称',
            prop: 'name'
          },
          {
            label: '上传时间',
            prop: 'createTime'
          }
        ], // 表格的列数据
        isIndex: false, // 列表序号
        loading: false, // loading
        isOperation: false,
        isSelection: true,
        pageData: {
          total: 0, // 总条数
          layout: 'total, prev, pager, next'
        },
        selectAll: (selection) => {
          if (selection.length > 0) {
            let selectionList = []
            selectionList.push(...selection)
            let list = []
            selectionList.map((item) => {
              if (list.length === 0 || !list.some((s) => s.targetingsId === item.targetingsId)) {
                list.push(item)
              }
            })

            let arr = [...this.orientationData.list, ...list]

            let newArr = []
            arr.forEach((item) => {
              if (newArr.length === 0 || !newArr.some((s) => s.id === item.id)) {
                newArr.push(item)
              }
            })

            this.orientationData = {
              ...this.orientationData,
              list: newArr
            }
          } else {
            this.orientationData = {
              ...this.orientationData,
              list: this.orientationData.list.filter((f) => f.adTargetId !== null)
            }
          }
        },
        select: (selection, row) => {
          if (row.adTargetId) {
            this.setSelectData()
            return this.$message.error('该定向包已发布')
          }
          if (selection.length > 0) {
            let selectionList = []
            selectionList.push(...selection)
            let list = []
            selectionList.map((item) => {
              if (list.length === 0 || !list.some((s) => s.targetingsId === item.targetingsId)) {
                list.push(item)
              }
            })

            let arr = [...this.orientationData.list, ...list]

            let newArr = []
            arr.forEach((item) => {
              if (newArr.length === 0 || !newArr.some((s) => s.id === item.id)) {
                newArr.push(item)
              }
            })

            if (!selection.some((s) => s.id === row.id)) {
              newArr = newArr.filter((f) => f.id !== row.id)
            }

            this.orientationData = {
              ...this.orientationData,
              list: newArr
            }
          }
        }
      }
    }
  },

  watch: {
    formData: {
      handler(value) {
        if (value.list) {
          this.orientationData = {
            ...value,
            list: value.list.map((item) => {
              return {
                ...item,
                id: item.targetingsId
              }
            })
          }
        } else {
          this.orientationData = value
        }
      },
      immediate: true
    }
  },

  methods: {
    /**
     * @description: 获取人群
     * @param {*} params
     */

    open() {
      this.getPage()
    },

    setFormData() {
      this.$emit('setFormData', this.orientationData)
    },

    getPage(params = { pageNum: 1, pageSize: 10 }) {
      this.dataSource.loading = true
      targetingsPage({ ...params, name: this.name }).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          records.forEach((element) => {
            console.log(this.orientationData.list)
            let idList = this.orientationData.list.filter((f) => f.targetingsId === element.id)
            element.adTargetId = idList.length ? idList[0].adTargetId || null : null
            element.targetingsId = element.id
            element.targetingsName = element.name
          })
          this.dataSource.data = records
          this.dataSource.pageData.total = total
          this.dataSource.pageData.pageNum = params.pageNum
          this.dataSource.loading = false
          this.setSelectData()
        }
      })
    },

    setSelectData() {
      this.$refs['TablePane'].getRowData([])
      if (this.orientationData.list.length) {
        let rows = []
        this.dataSource.data.map((item) => {
          if (this.orientationData.list.some((s) => s.targetingsId === item.id)) {
            rows.push(item)
          }
        })
        this.$refs['TablePane'].getRowData(rows)
      }
    },

    /**
     * @description 删除选中的人群包
     */
    async deleteSelectInterest(item) {
      if (item.adTargetId) {
        const { code } = await deleteOrientation(item.adTargetId)
        if (code === 200) {
          this.orientationData.list = this.orientationData.list.filter((f) => f.id !== item.id)
          this.$emit('byTaskIdGetAllFun')
          this.setSelectData()
          this.setFormData()
          return this.$message.success('删除成功!')
        }
      } else {
        this.orientationData.list = this.orientationData.list.filter((f) => f.id !== item.id)
        this.setFormData()
        this.setSelectData()
      }
    },

    async onSubmit() {
      let adMpManagementTaskPlanAdTargetENTList = []
      if (this.orientationData.list.length === 0) {
        return this.$message.info('请选择定向包!')
      }
      this.orientationData.list.map((item) => {
        if (!item.adTargetId) {
          adMpManagementTaskPlanAdTargetENTList.push({
            targetingsId: item.targetingsId,
            taskId: this.taskId,
            targetingsName: item.targetingsName,
            isOnlineCrowd: item.isOnlineCrowd || false
          })
        }
      })
      if (adMpManagementTaskPlanAdTargetENTList.length) {
        const { code } = await saveOrientation({ adMpManagementTaskPlanAdTargetENTList })
        if (code === 200) {
          this.$emit('byTaskIdGetAllFun')
          this.resetForm()
          this.$message.success('定向包保存成功!')
        }
      } else {
        this.close()
      }
    },

    resetForm() {
      this.name = null
      this.close()
    }
  }
}
</script>

<style lang="scss">
.city-box {
  .el-cascader__tags {
    max-height: 240px;
    overflow-y: auto;
  }
}
.el-cascader-panel {
  .el-cascader-menu__wrap {
    height: 320px;
  }
}
</style>

<style lang="scss" scoped>
p {
  margin: 0;
  padding: 0;
}

.background {
  background: #f7f8fa;
  padding: 10px;
  border-radius: 10px;
}

.crowd-pack-selected-title {
  border-left: 3px solid #409eff;
  padding-left: 5px;
  margin: 0 0 5px 0;
  font-weight: 600;
}
.crowd-pack-selected {
  display: flex;
  flex-direction: column;
  max-height: 486px;
  overflow-y: auto;
  li {
    width: 100%;
    display: flex;
    padding: 4px 0;
    align-items: center;
    font-size: 14px;
    span {
      width: 80%;
      text-align: right;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      flex: 1;
      margin-right: 10px;
    }
    i {
      width: 20px;
      cursor: pointer;
      font-weight: 600;
    }
  }
}

.crowd-pack-selected2 {
  display: flex;
  flex-direction: column;
  height: 296px;
  padding: 0 10px 0 0;
  overflow-y: auto;
  li {
    width: 100%;
    display: flex;
    padding: 4px 0;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    span {
      text-align: right;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-right: 10px;
      font-size: 13px;
    }
    i {
      cursor: pointer;
      color: #888888;
      font-size: 13px;
    }
  }
}
</style>
