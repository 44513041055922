var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "top-slider" }, [
    _vm.sliderList.length !== 0 &&
    !_vm.sliderList.every((item) => item.pureImageUrl === "")
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-carousel",
              { attrs: { height: "260px" } },
              _vm._l(_vm.sliderList, function (item) {
                return _c("el-carousel-item", { key: item.id }, [
                  _c("img", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      src: item.pureImageUrl || _vm.defaultImg,
                      alt: "",
                    },
                  }),
                ])
              }),
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          { staticStyle: { "text-align": "center" } },
          [
            _vm._l(
              _vm.sliderList.length ? _vm.sliderList : 3,
              function (item, index) {
                return _c("i", {
                  key: index,
                  staticClass: "el-icon-picture-outline main-color",
                })
              }
            ),
            _c("p", { staticClass: "main-color" }, [_vm._v("顶部轮播图组件")]),
          ],
          2
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }