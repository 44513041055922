<!--
 * @Description: 巨量回传  回传设置
 * @Date: 2021-10-19 19:05:18
 * @LastEditTime: 2022-05-13 14:35:08
-->

<template>
  <div>
    <!-- 列表查询 -->
    <el-form ref="search" :inline="true" :model="search" class="search-form search-form-4">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="规则名称" prop="rule_name">
            <el-input v-model="search.rule_name" placeholder="请输入规则名称"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="规则类型" prop="type">
            <el-select v-model="search.type">
              <el-option label="全部" value=""></el-option>
              <el-option label="固定模式" value="FIXED"></el-option>
              <el-option label="自定义模式" value="FREE"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="适用范围" prop="rule_range">
            <el-select v-model="search.rule_range">
              <el-option label="全部" value=""></el-option>
              <el-option label="公众号" value="PUBLIC"></el-option>
              <el-option label="投放广告" value="AD"></el-option>
              <el-option label="快应用" value="QUICKAPP" />
              <el-option label="短剧" value="VNOVEL" />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="创建人" prop="nickname">
            <el-input v-model="search.created_by" placeholder="请输入创建人"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="投放平台" prop="platform">
            <el-select v-model="search.platform">
              <el-option v-for="item in platform_list" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="创建时间" prop="date">
            <el-date-picker v-model="search.date" type="daterange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" round @click="onSubmit">查询</el-button>
            <el-button type="primary" round @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-button type="primary" icon="el-icon-plus" round class="mb-20" @click="platformSelectVisible = true">
      新建规则
    </el-button>
    <!-- 表格 -->
    <TablePane :data-source="dataSource" @getPage="getPage"> </TablePane>

    <!-- 关联的账号 -->
    <el-dialog
      title="关联账号"
      :visible.sync="associate_public"
      :modal-append-to-body="false"
      destroy-on-close
      @close="
        () => {
          publicId = null
        }
      "
    >
      <p><span style="color: red">* </span>需保留一个关联关系</p>
      <el-input v-model="publicId" style="width: 400px; margin-bottom: 10px" placeholder="请输入公众号名称/ghid">
        <template slot="append"> <el-button slot="append" icon="el-icon-search" @click="getRuleRelation(1)"></el-button></template>
      </el-input>
      <el-table :data="associate_public_List" class="list-table">
        <el-table-column property="name" label="名称" align="center"></el-table-column>
        <el-table-column property="id" label="ID" align="center"></el-table-column>
        <el-table-column property="created_at" label="关联时间" align="center"></el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-button type="text" :disabled="associate_public_List.length === 1" @click="dismissRelationFun(scope.row.id, scope.$index)">解除关联</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination style="text-align:right; margin-top:10px" background layout="prev, pager, next" :total="associate_public_List.length" @current-change="getRuleRelation"> </el-pagination>
    </el-dialog>

    <!-- 选择平台 -->
    <el-dialog title="新增回传规则" width="500px" :visible.sync="platformSelectVisible" :modal-append-to-body="false" destroy-on-close>
      <span class="align-center justify-center">
        <label class="mr-10">投放平台: </label>

        <el-select v-model="platformSelect">
          <el-option v-for="item in platform_list" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue"></el-option>
        </el-select>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button class="mr-20" @click="platformSelectVisible = false">取 消</el-button>
        <router-link :to="{ name: 'createReturn', params: { type: platformSelect } }" tag="span">
          <el-button type="primary">
            确 定
          </el-button>
        </router-link>
      </span>
    </el-dialog>
    <!-- 关联的计划 -->
    <el-dialog
      title="关联计划"
      :visible.sync="associate_plan"
      :modal-append-to-body="false"
      destroy-on-close
      @close="
        () => {
          planId = null
        }
      "
    >
      <el-input v-model="planId" style="width: 400px; margin-bottom: 10px" placeholder="请输入计划ID">
        <template slot="append"> <el-button slot="append" icon="el-icon-search" @click="getRulePlan(1)"></el-button></template>
      </el-input>
      <el-table :data="associate_plan_List" class="list-table">
        <el-table-column property="id" label="ID" align="center"></el-table-column>
        <el-table-column property="created_at" label="关联时间" align="center"></el-table-column>
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="dismissRelationFun(scope.row.id, scope.$index)">解除关联</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background style="text-align:right; margin-top:10px" layout="prev, pager, next" :total="associate_public_List.length" @current-change="getRulePlan"> </el-pagination>
    </el-dialog>

    <!-- 回传日志 -->
    <el-dialog
      title="回传日志"
      width="70%"
      :visible.sync="returnLog_visible"
      :modal-append-to-body="false"
      destroy-on-close
      @close="
        () => {
          returnDate = []
        }
      "
    >
      <div class="block mb-10">
        <span class="mr-10">回传时间</span>
        <el-date-picker v-model="returnDate" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
        <el-button class="ml-10" type="primary" icon="el-icon-search" @click="getRuleReturnLog"></el-button>
      </div>
      <el-table :data="returnLog_List" class="list-table">
        <el-table-column property="platform" label="书城" align="center">
          <template slot-scope="scope">
            <span>{{ scope.platform ? platformList.filter(item.key === scope.row.platform)[0].value : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column property="ghid" label="ghid" align="center" />
        <el-table-column property="merchant_id" label="订单Id" align="center" />
        <el-table-column label="注册时间" align="center">
          <template slot-scope="scope">
            <p>{{ moment(moment.unix(scope.user_createtime)).format('YYYY-MM-DD') }}</p>
          </template>
        </el-table-column>
        <el-table-column label="下单时间" align="center">
          <template slot-scope="scope">
            <p>{{ moment(moment.unix(scope.finish_time)).format('YYYY-MM-DD') }}</p>
          </template>
        </el-table-column>
        <el-table-column property="true_money" label="充值金额" align="center" />
        <el-table-column label="回传时间" align="center">
          <template slot-scope="scope">
            <p>{{ moment(moment.unix(scope.return_time)).format('YYYY-MM-DD') }}</p>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background style="text-align:right; margin-top:10px" layout="prev, pager, next" :total="associate_public_List.length" @current-change="getRuleReturnLog"> </el-pagination>
    </el-dialog>

    <!-- 修改日志 -->
    <el-dialog title="修改日志" width="60%" :visible.sync="editLog_visible" :modal-append-to-body="false" destroy-on-close>
      <div class="block mb-10">
        <span class="mr-10">修改日期： </span>
        <el-date-picker v-model="createdDate" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
        <el-button class="ml-10" type="primary" icon="el-icon-search" @click="getRuleOperateLog(1)"></el-button>
      </div>
      <el-table :data="editLog_List" class="list-table">
        <el-table-column property="updatedAt" label="日期" align="center" width="150"></el-table-column>
        <el-table-column property="nickname" label="修改人" align="center" width="150"></el-table-column>
        <el-table-column label="修改前" align="center">
          <template slot-scope="scope">
            <ul className="column-start">
              <li v-for="(item, index) in editData(scope.row.nowData, scope.row.originData, 'origin')" :key="index" class="justify-left" style="width:300px; text-align: left">
                <div>{{ item.key }} :</div>
                <el-tooltip class="item" effect="dark" :content="item.value" placement="top">
                  <span class="text-ellipsis w-250" style="text-align: left;">{{ item.value }} </span>
                </el-tooltip>
              </li>
            </ul>
          </template>
        </el-table-column>
        <el-table-column label="修改后" align="center">
          <template slot-scope="scope">
            <ul className="column-start">
              <li v-for="(item, index) in editData(scope.row.nowData, scope.row.originData, 'now')" :key="index" class="justify-left" style="width:300px; text-align: left">
                <div>{{ item.key }} :</div>
                <el-tooltip class="item" effect="dark" :content="item.value" placement="top">
                  <span class="text-ellipsis  w-250" style="text-align: left;">{{ item.value }} </span>
                </el-tooltip>
              </li>
            </ul>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background style="text-align:right; margin-top:10px" layout="prev, pager, next" :total="associate_public_List.length" @current-change="getRuleOperateLog"> </el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import TablePane from '@/components/tablePane.vue' //
import { Page, ruleRelation, ruleReturnLog, ruleOperateLog, dismissRelation, deleteHandHugeReturn } from '@/network/api/passBack/index.js'
import { jsonFormat, getDateStartEnd } from '@/utils/ruoyi'
import { orderOperatorOption, platformList, platform_4_py_text } from '../data.js'
import _ from 'lodash'
import moment from 'moment'

const returnConditionOption = {
  1: '上传所有充值行为',
  2: '上传用户在关注当天自然日的所有充值行为',
  3: '上传用户在关注当天自然日的首次充值行为',
  4: '上传用户在关注24小时内的所有充值行为',
  5: '上传用户在关注24小时内的首次充值行为'
}

const ruleRangeMapping = {
  '公众号': 'PUBLIC',
  '投放广告': 'AD',
  '快应用': 'QUICKAPP',
  '短剧': 'VNOVEL'
}

export default {
  components: {
    TablePane
  },
  data() {
    return {
      platform_list: [],
      search: {
        rule_name: null,
        type: '',
        rule_range: '',
        created_by: null,
        date: null,
        platform: null
      },
      ruleId: '', // 选择的规则Id
      // 选择平台
      platformSelectVisible: false,
      platformSelect: 'baidu', // 选择平台,百度,快手,巨量,UC
      platformList,
      orderOperatorOption,
      // 关联账户
      associate_public: false,
      associate_public_List: [],
      publicId: null,
      // 关联计划
      associate_plan: false,
      associate_plan_List: [],
      planId: null,
      // 回传日志
      returnLog_visible: false,
      returnLog_List: [],
      returnDate: [],
      // 修改日志
      editLog_visible: false,
      editLog_List: [],
      createdDate: [],
      // 表格数据
      dataSource: {
        data: [],
        cols: [
          {
            label: '规则名称',
            prop: 'rule_name'
            // render: function(record) {
            //   return (
            //     // eslint-disable-next-line
            //     <span class="text-ellipsis" style="width:130px">
            //       {record.rule_name}
            //     </span>
            //   )
            // }
          },
          {
            label: '回传类型',
            render: function(record) {
              return record.type === 'FIXED' ? '固定' : '自定义'
            }
          },
          {
            label: '适用范围',
            render: function(record) {
              return ruleRangeMapping[record.rule_range]
            }
          },
          {
            label: '投放平台',
            render: function(record) {
              return platform_4_py_text[record.platform]
            }
          },
          {
            label: '关联账户',
            prop: 'public_num',
            handleRow: (index, record) => {
              this.ruleId = record.pk_unique
              this.associate_public = true
              this.getRuleRelation()
            }
          },
          {
            label: '关联计划',
            prop: 'plan_num',
            handleRow: (index, record) => {
              this.ruleId = record.pk_unique
              this.associate_plan = true
              this.getRulePlan()
            }
          },
          {
            label: '回传日志',
            prop: 'logNum',
            handleRow: (index, record) => {
              this.ruleId = record.pk_unique
              this.returnLog_visible = true
              this.getRuleReturnLog()
            }
          },
          {
            label: '创建人',
            prop: 'created_by'
          },
          {
            label: '创建时间',
            width: 200,
            render: function(record) {
              return moment(record.created_at).format('YYYY-MM-DD HH:mm:ss')
            }
          },
          {
            label: '启用时间',
            width: 210,
            render: function(record) {
              return (
                <p>
                  {record.start_time} - {record.end_time}
                </p>
              )
            }
          }
        ], // 表格的列数据
        isIndex: true, // 列表序号
        loading: false, // loading
        isOperation: true,
        pageData: {
          total: 0 // 总条数
        },
        handleSelectionChange() {},
        operation: {
          // 表格有操作列时设置
          label: '操作', // 列名
          data: [
            {
              tooltip: '编辑',
              icon: 'el-icon-edit',
              handleRow: (index, record) => {
                this.$router.push({
                  name: 'editReturn',
                  params: { pk_unique: record.pk_unique, type: record.platform }
                })
              }
            },
            {
              tooltip: '删除',
              icon: 'el-icon-delete',
              color: '#ff0000',
              handleRow: (index, record) => {
                this.$confirm('确定删除, 是否继续?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(res => {
                  deleteHandHugeReturn(record.pk_unique).then((res) => {
                    if (res.code === 200) {
                      this.$message.success('删除成功！')
                      this.dataSource.data.splice(index, 1)
                    }
                  })
                })
              }
            },
            {
              tooltip: '修改日志',
              icon: 'el-icon-edit-outline',
              handleRow: (index, record) => {
                this.ruleId = record.pk_unique
                this.editLog_visible = true
                this.getRuleOperateLog()
              }
            }
          ]
        }
      }
    }
  },

  mounted() {
    this.getPage()
    this.getDicts('return_compaign_platform_setting').then((response) => {
      this.platform_list = response.data
    })
  },
  methods: {
    /**
     * @description 获取关联账号列表
     */
    getRuleRelation(pageNum = 1) {
      const { publicId, ruleId } = this
      let params = {
        name: publicId || -1,
        ruleId,
        pageSize: 10,
        pageNum
      }
      ruleRelation(params).then(({ code, data }) => {
        if (code === 200) {
          this.associate_public_List = data.records
        }
      })
    },

    /**
     * @description 获取关联计划列表
     */
    getRulePlan(pageNum = 1) {
      const { planId, ruleId } = this
      // planId,
      let params = {
        name: planId || -1,
        ruleId,
        pageSize: 10,
        pageNum
      }
      ruleRelation(params).then(({ code, data }) => {
        if (code === 200) {
          this.associate_plan_List = data.records
        }
      })
    },

    /**
     * @description 获取回传日志
     */
    getRuleReturnLog(pageNum = 1) {
      const { returnDate, ruleId } = this
      const [startDate, endDate] = getDateStartEnd(returnDate)
      let params = {
        ruleId,
        date_start: startDate,
        date_end: endDate,
        pageSize: 10,
        pageNum
      }
      ruleReturnLog(params).then(({ code, data }) => {
        if (code === 200) {
          this.returnLog_List = data.records
        }
      })
    },

    /**
     * @description 获取回传修改日志
     */
    getRuleOperateLog(pageNum = 1) {
      const { createdDate } = this
      const [startDate, endDate] = getDateStartEnd(createdDate)
      let params = {
        start_created_at: startDate,
        end_created_at: endDate,
        ruleId: this.ruleId,
        pageSize: 10,
        pageNum
      }
      ruleOperateLog(params).then(({ code, data }) => {
        if (code === 200) {
          this.editLog_List = data.records
        }
      })
    },

    /**
     * @description: 解除关联
     * @param {number} id
     * @return {*}
     */
    async dismissRelationFun(id, index) {
      const { code } = await dismissRelation(this.ruleId, id)
      if (code === 200) {
        this.$message.success('解除成功!')
      }
      this.associate_public_List.splice(index, 1)
      this.dataSource.data.forEach((item) => {
        if (item.pk_unique === this.ruleId) {
          item.public_num--
        }
      })
    },

    /**
     * @description: 获取列表
     * @param {*} params
     */
    getPage(params = { pageNum: 1, pageSize: 10 }) {
      this.dataSource.loading = true
      const [start_created_at, end_created_at] = getDateStartEnd(this.search.date)
      let search = {
        ...this.search,
        start_created_at,
        end_created_at
      }
      Page({ ...search, ...params }).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          let newRecords = records.map((item) => {
            return {
              ...item,
              public_num: item.rule_range === 'PUBLIC' ? item.accounts.length || 0 : '无',
              plan_num: item.rule_range === 'AD' ? item.accounts.length || 0 : '无',
              logNum: item.logNum === 0 ? '无' : item.logNum
            }
          })
          this.dataSource.data = newRecords
          this.dataSource.pageData.total = total
          this.dataSource.loading = false
        }
      })
    },

    editData(now_data, origin_data, type) {
      // now_data = jsonFormat(now_data, 'object')
      // origin_data = jsonFormat(origin_data, 'object')
      let now = []
      let origin = []
      const now_rule_json = jsonFormat(now_data, 'object')
      const origin_rule_json = jsonFormat(origin_data, 'object')
      const origin_accounts = origin_rule_json.accounts
      const now_accounts = now_rule_json.accounts
      if (!now_rule_json) {
        return []
      }
      if (!_.isEqual(origin_accounts, now_accounts)) {
        origin.push({ key: '关联账号', value: origin_accounts.map((item) => item.name).toString() })
        now.push({ key: '关联账号', value: now_accounts.map((item) => item.name).toString() })
      }
      if (now_rule_json.rule_name !== origin_rule_json.rule_name) {
        origin.push({ key: '规则名称', value: origin_rule_json.rule_name })
        now.push({ key: '规则名称', value: now_rule_json.rule_name })
      }
      if (now_rule_json.type !== origin_rule_json.type) {
        origin.push({ key: '回传类型', value: origin_rule_json.type === 'FIXED' ? '固定类型' : '自定义模式' })
        now.push({ key: '回传类型', value: now_rule_json.type === 'FIXED' ? '固定类型' : '自定义模式' })
      }

      // 回传类型没有更改的情况下执行
      if (now_rule_json.type === origin_rule_json.type) {
        if (now_rule_json.type === 'FIXED') {
          if (now_rule_json.return_condition !== origin_rule_json.return_condition) {
            origin.push({ key: '回传方式', value: returnConditionOption[origin_rule_json.return_condition] })
            now.push({ key: '回传方式', value: returnConditionOption[now_rule_json.return_condition] })
          }

          if (now_rule_json.return_type !== origin_rule_json.return_type) {
            origin.push({ key: '回传方式', value: origin_rule_json.return_type === 1 ? '百分比回传' : '循环比例回传' })
            now.push({ key: '回传方式', value: now_rule_json.return_type === 1 ? '百分比回传' : '循环比例回传' })
          }

          if (!_.isEqual(now_rule_json.cycle_proportion, origin_rule_json.cycle_proportion)) {
            origin.push({ key: '循环比例', value: origin_rule_json.cycle_proportion })
            now.push({ key: '循环比例', value: now_rule_json.cycle_proportion })
          }

          if (now_rule_json.cycle_rule !== origin_rule_json.cycle_rule) {
            origin.push({ key: '循环规则', value: origin_rule_json.cycle_rule === 1 ? '按订单循环' : '每日重置' })
            now.push({ key: '循环规则', value: now_rule_json.cycle_rule === 1 ? '按订单循环' : '每日重置' })
          }
          if (now_rule_json.is_few_return !== origin_rule_json.is_few_return) {
            origin.push({ key: '部分订单回传', value: origin_rule_json.is_few_return === 1 ? '开启' : '关闭' })
            now.push({ key: '部分订单回传', value: now_rule_json.is_few_return === 1 ? '开启' : '关闭' })
          }
          if (now_rule_json.return_rate !== origin_rule_json.return_rate) {
            origin.push({ key: '百分比回传', value: `${origin_rule_json.return_rate * 100}%` })
            now.push({ key: '百分比回传', value: `${now_rule_json.return_rate * 100}%` })
          }
        } else {
          if (now_rule_json.is_first_recharge !== origin_rule_json.is_first_recharge) {
            origin.push({ key: '是否首充', value: origin_rule_json.is_first_recharge === 1 ? '是' : '否' })
            now.push({ key: '是否首充', value: now_rule_json.is_first_recharge === 1 ? '是' : '否' })
          }

          if (now_rule_json.cycle_proportion !== origin_rule_json.cycle_proportion) {
            origin.push({ key: '循环比例', value: origin_rule_json.cycle_proportion })
            now.push({ key: '循环比例', value: now_rule_json.cycle_proportion })
          }

          let origin_condition = []
          let now_condition = []
          origin_rule_json.order_amount_rule.map((item) => {
            const { attention_type, attention_time } = origin_rule_json
            origin_condition.push(
              `回传用户在关注${attention_type === 1 ? '当天' : '后'} ${attention_time}小时内，金额${this.orderOperatorOption.filter((f) => f.value === item.amount_off_condition)[0].title} ${item.amount_off}元至${orderOperatorOption.filter((f) => f.value === item.amount_on_condition)[0].title} ${
                item.amount_on
              }元，回传比例为${item.order_return_rate}%的订单`
            )
          })
          now_rule_json.order_amount_rule.map((item) => {
            const { attention_type, attention_time } = now_rule_json
            now_condition.push(
              `回传用户在关注${attention_type === 1 ? '当天' : '后'} ${attention_time}小时内，金额${this.orderOperatorOption.filter((f) => f.value === item.amount_off_condition)[0].title} ${item.amount_off}元至${orderOperatorOption.filter((f) => f.value === item.amount_on_condition)[0].title} ${
                item.amount_on
              }元，回传比例为${item.order_return_rate}%的订单`
            )
          })
          if (!_.isEqual(now_condition, origin_condition)) {
            origin.push({ key: '回传条件', value: origin_condition + '' })
            now.push({ key: '回传条件', value: now_condition + '' })
          }
        }
      }

      if (now_rule_json.start_time !== origin_rule_json.start_time || now_rule_json.end_time !== origin_rule_json.end_time) {
        origin.push({ key: '启用时间', value: `${origin_rule_json.start_time}-${origin_rule_json.end_time}` })
        now.push({ key: '启用时间', value: `${now_rule_json.start_time}-${now_rule_json.end_time}` })
      }

      if (type === 'origin') {
        return origin
      } else {
        return now
      }
    },

    /**
     * @description: 重置
     */
    resetForm() {
      this.$refs['search'].resetFields()
      this.getPage()
    },

    /**
     * @description: 查询
     */
    onSubmit() {
      this.getPage()
    }
  }
}
</script>
