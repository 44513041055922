var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "section" },
    [
      _c(
        "base-box",
        { attrs: { name: "applet" } },
        [
          _c(
            "template",
            { slot: "body" },
            [
              _c("div", { staticClass: "setting" }, [
                _c("p", { staticClass: "w-100" }, [_vm._v("小程序链接：")]),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            _vm.dialogVisible = true
                          },
                        },
                      },
                      [_vm._v("添加跳转链接")]
                    ),
                    _c(
                      "div",
                      { staticClass: "url-list tip" },
                      _vm._l(_vm.detail.urlList, function (item, index) {
                        return _c("li", { key: index }, [
                          _c("div", [
                            _vm._v(" 跳转链接： "),
                            _c(
                              "p",
                              [
                                _c(
                                  "el-tooltip",
                                  { attrs: { content: item.url } },
                                  [
                                    _c("p", { staticClass: "url" }, [
                                      _vm._v(_vm._s(item.url)),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" , 访问上限：" + _vm._s(item.uv) + " "),
                          ]),
                          _c(
                            "span",
                            {
                              staticClass: "ml-10",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteUrl(index)
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-error" })]
                          ),
                        ])
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "提示",
                    visible: _vm.dialogVisible,
                    width: "500px",
                    "before-close": _vm.handleClose,
                    "modal-append-to-body": false,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogVisible = $event
                    },
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        model: _vm.formData,
                        rules: _vm.rules,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "跳转链接", prop: "url" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "300px" },
                            attrs: { placeholder: "请输入跳转链接" },
                            model: {
                              value: _vm.formData.url,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "url", $$v)
                              },
                              expression: "formData.url",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "访问上限(UV)", prop: "uv" } },
                        [
                          _c("el-input-number", {
                            model: {
                              value: _vm.formData.uv,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "uv", $$v)
                              },
                              expression: "formData.uv",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.dialogVisible = false
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.submit },
                        },
                        [_vm._v("确 定")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", [
                _c("p", { staticClass: "w-100" }, [_vm._v("按钮文案：")]),
                _c(
                  "div",
                  { staticStyle: { display: "flex" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "200px" },
                      attrs: {
                        value: _vm.detail.btnTitle,
                        maxlength: "10",
                        "show-word-limit": "",
                        type: "text",
                        size: "small",
                      },
                      on: { input: _vm.btnTitle },
                    }),
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "ml-20",
                        attrs: { size: "small" },
                        model: {
                          value: _vm.detail.btnFontType,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "btnFontType", $$v)
                          },
                          expression: "detail.btnFontType",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: "normal" } }, [
                          _vm._v("常规"),
                        ]),
                        _c("el-radio-button", { attrs: { label: "bold" } }, [
                          _vm._v("加粗"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c("p", { staticClass: "w-100" }, [_vm._v("按钮字体色：")]),
                _c(
                  "div",
                  [
                    _c(
                      "el-dropdown",
                      [
                        _c("div", {
                          staticClass: "ldy-bg-color",
                          style: `background:${_vm.detail.btnFontColor}`,
                        }),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c("twitter-color", {
                              staticStyle: { "margin-top": "10px" },
                              attrs: { colors: _vm.detail.btnFontColor },
                              on: {
                                change: (value) =>
                                  (_vm.detail.btnFontColor = value),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c("p", { staticClass: "w-100" }, [_vm._v("按钮边框色：")]),
                _c(
                  "div",
                  [
                    _c(
                      "el-dropdown",
                      [
                        _c("div", {
                          staticClass: "ldy-bg-color",
                          style: `background:${_vm.detail.btnBorderColorTheme}`,
                        }),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c("twitter-color", {
                              staticStyle: { "margin-top": "10px" },
                              attrs: { colors: _vm.detail.btnBorderColorTheme },
                              on: {
                                change: (value) =>
                                  (_vm.detail.btnBorderColorTheme = value),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c("p", { staticClass: "w-100" }, [_vm._v("按钮填充色：")]),
                _c(
                  "div",
                  [
                    _c(
                      "el-dropdown",
                      [
                        _c("div", {
                          staticClass: "ldy-bg-color",
                          style: `background:${_vm.detail.btnBgColorTheme}`,
                        }),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c("twitter-color", {
                              staticStyle: { "margin-top": "10px" },
                              attrs: { colors: _vm.detail.btnBgColorTheme },
                              on: {
                                change: (value) =>
                                  (_vm.detail.btnBgColorTheme = value),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c("p", { staticClass: "w-100" }, [_vm._v("上边距：")]),
                _c(
                  "div",
                  [
                    _c("el-slider", {
                      attrs: { "show-input": "" },
                      model: {
                        value: _vm.detail.paddingTop,
                        callback: function ($$v) {
                          _vm.$set(_vm.detail, "paddingTop", $$v)
                        },
                        expression: "detail.paddingTop",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c("p", { staticClass: "w-100" }, [_vm._v("下边距：")]),
                _c(
                  "div",
                  [
                    _c("el-slider", {
                      attrs: { "show-input": "" },
                      model: {
                        value: _vm.detail.paddingBottom,
                        callback: function ($$v) {
                          _vm.$set(_vm.detail, "paddingBottom", $$v)
                        },
                        expression: "detail.paddingBottom",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }