<!--
 * @Description: Do not edit
 * @Date: 2021-12-24 14:49:52
 * @LastEditTime: 2022-02-10 16:36:48
-->
<template>
  <el-form ref="searchForm" inline class="search-form search-form-4" :model="searchForm">
    <el-row :gutter="20">
      <el-col :span="6">
        <el-form-item prop="projectName" label="项目名称">
          <el-input v-model="searchForm.projectName" placeholder="项目名称" @keyup.enter.native="search" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="leaderUserName" label="项目负责人">
          <el-input v-model="searchForm.leaderUserName" placeholder="项目负责人" @keyup.enter.native="search" />
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-form-item prop="visibleUserIdListName" label="项目可见人员">
          <el-input v-model="searchForm.visibleUserIdListName" placeholder="项目可见人员" @keyup.enter.native="search" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="projectVisibleType" label="可见状态">
          <el-select v-model="searchForm.projectVisibleType" placeholder="可见状态">
            <el-option label="全员可见" value="ALL" />
            <el-option label="指定人员" value="PART" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="status" label="可用状态">
          <el-select v-model="searchForm.status" placeholder="可用状态">
            <el-option label="可用" value="1" />
            <el-option label="不可用" value="0" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="createBy" label="创建者">
          <el-input v-model="searchForm.createBy" placeholder="创建者" @keyup.enter.native="search" />
        </el-form-item>
      </el-col>
      <!-- <el-col :span="6">
        <el-form-item prop="createTime">
          <el-input v-model="searchForm.createTime" placeholder="创建时间" />
        </el-form-item>
      </el-col> -->
      <el-col :span="6">
        <el-form-item prop="updateBy" label="更新者">
          <el-input v-model="searchForm.updateBy" placeholder="更新者" @keyup.enter.native="search" />
        </el-form-item>
      </el-col>

      <!-- <el-col :span="6">
        <el-form-item prop="updateTime">
          <el-input v-model="searchForm.updateTime" placeholder="更新时间" />
        </el-form-item>
      </el-col> -->
      <!-- <el-col :span="6">
        <el-form-item prop="remark">
          <el-input v-model="searchForm.remark" placeholder="备注" />
        </el-form-item>
      </el-col> -->
      <el-col :span="6">
        <el-button class="btn" type="primary" @click="search">
          查询
        </el-button>
        <el-button class="btn" @click="resetSearch">
          重置
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { searchForm } from './object'
export default {
  inject: ['loadData'],
  data() {
    return {
      searchForm: { ...searchForm }
    }
  },
  methods: {
    search() {
      this.loadData(true)
    },
    // 折中方案
    getSearchForm() {
      return { ...this.searchForm }
    },
    resetSearch() {
      this.$refs.searchForm.resetFields()
    }
  }
}
</script>
