<!--
 * @Description: Do not edit
 * @Date: 2021-11-10 19:20:55
 * @LastEditTime: 2022-02-17 10:38:34
-->
<template>
  <el-form ref="searchForm" class="search-form search-form-4" :model="searchForm">
    <el-row :gutter="20">
      <el-col :span="6">
        <el-form-item prop="operator" label="操作人姓名">
          <el-input v-model="searchForm.operator" placeholder="请输入操作人姓名" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="operatorId" label="操作人ID">
          <el-input v-model="searchForm.operatorId" placeholder="请输入操作人ID" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="uri" label="uri">
          <el-input v-model="searchForm.uri" placeholder="请输入uri" />
        </el-form-item>
      </el-col>
      <el-col :span="6" label="创建时间">
        <el-form-item prop="createTime">
          <el-date-picker v-model="searchForm.createTime" type="datetimerange" range-separator="至" start-placeholder="开始时间" value-format="yyyy-MM-dd HH:mm:ss" end-placeholder="结束时间" @change="handleTimeChange" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row style="padding-bottom: 10px">
      <el-col :span="6">
        <el-button class="btn" type="primary" @click="search">
          查询
        </el-button>
        <el-button class="btn" @click="resetSearch">
          重置
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { searchForm } from './object'

export default {
  inject: ['loadData'],
  data() {
    return {
      searchForm: { ...searchForm }
    }
  },
  methods: {
    search() {
      this.loadData(true)
    },
    // 折中方案
    getSearchForm() {
      return { ...this.searchForm }
    },
    handleTimeChange(timeRange) {
      if (timeRange.length === 2) {
        this.searchForm.startTime = timeRange[0]
        this.searchForm.endTime = timeRange[1]
      } else {
        this.searchForm.startTime = null
        this.searchForm.endTime = null
      }
    },
    resetSearch() {
      this.$refs.searchForm.resetFields()
    }
  }
}
</script>
