var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.isEdit ? "编 辑" : "新 增",
        width: "45%",
        visible: _vm.visible,
        "before-close": _vm.close,
        "label-position": "right",
        "modal-append-to-body": false,
        "close-on-press-escape": false,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ent",
          attrs: { model: _vm.ent, rules: _vm.rules, "label-width": "110px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "orangeCreateId" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.orangeCreateId,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "orangeCreateId", $$v)
                  },
                  expression: "ent.orangeCreateId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "具体见返回示例业务数据", prop: "bricks" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.bricks,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "bricks", $$v)
                  },
                  expression: "ent.bricks",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "站点ID", prop: "id" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.siteId,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "siteId", $$v)
                  },
                  expression: "ent.siteId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "站点名称", prop: "name" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "name", $$v)
                  },
                  expression: "ent.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "站点状态", prop: "status" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "status", $$v)
                  },
                  expression: "ent.status",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "建站类型", prop: "siteType" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.siteType,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "siteType", $$v)
                  },
                  expression: "ent.siteType",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "缩略图", prop: "thumbnail" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.thumbnail,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "thumbnail", $$v)
                  },
                  expression: "ent.thumbnail",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "请求日志id", prop: "requestId" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.requestId,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "requestId", $$v)
                  },
                  expression: "ent.requestId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "关联管家账号名称",
                prop: "relationHousekeeperName",
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.relationHousekeeperName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "relationHousekeeperName", $$v)
                  },
                  expression: "ent.relationHousekeeperName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: "关联管家账号id", prop: "relationHousekeeperId" },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.relationHousekeeperId,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "relationHousekeeperId", $$v)
                  },
                  expression: "ent.relationHousekeeperId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "关联投放账户名称",
                prop: "relationAdvAccountName",
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.relationAdvAccountName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "relationAdvAccountName", $$v)
                  },
                  expression: "ent.relationAdvAccountName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "关联投放账户id", prop: "relationAdvId" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.relationAdvId,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "relationAdvId", $$v)
                  },
                  expression: "ent.relationAdvId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "逻辑删除", prop: "deleted" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.deleted,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "deleted", $$v)
                  },
                  expression: "ent.deleted",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "创建者", prop: "createBy" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.createBy,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "createBy", $$v)
                  },
                  expression: "ent.createBy",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "创建时间", prop: "createTime" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.createTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "createTime", $$v)
                  },
                  expression: "ent.createTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "更新者", prop: "updateBy" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.updateBy,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "updateBy", $$v)
                  },
                  expression: "ent.updateBy",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "更新时间", prop: "updateTime" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.updateTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "updateTime", $$v)
                  },
                  expression: "ent.updateTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "remark", $$v)
                  },
                  expression: "ent.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { attrs: { round: "" }, on: { click: _vm.close } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", round: "" },
              on: { click: _vm.submit },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }