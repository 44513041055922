<!--
 * @Description: 投放账号
 * @Date: 2021-12-01 09:15:29
 * @LastEditTime: 2023-06-19 16:34:47
-->

<template>
  <div>
    <!-- 列表查询 -->
    <el-form ref="search" :inline="true" :model="search" class="search-form" @submit.native.prevent>
      <el-form-item label="账户ID" prop="accountId">
        <el-input v-model="search.accountId" placeholder="请输入账户ID" style="width: 300px"></el-input>
      </el-form-item>

      <el-form-item label="关联服务商" prop="adMarketingApiServiceId">
        <el-select v-model="search.adMarketingApiServiceId" style="width: 300px">
          <el-option label="全部" :value="null"></el-option>
          <el-option v-for="(item, index) in adMpAmList" :key="index" :label="item.corporationName"
            :value="item.adMarketingApiServiceId"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="账户类型" prop="type">
        <el-select v-model="search.type" style="width: 300px">
          <el-option label="全部" :value="null"></el-option>
          <el-option label="子客账户" value="子客账户"></el-option>
          <el-option label="直客账户" value="直客账户"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="投放状态" prop="putInStatus">
        <el-select v-model="search.putInStatus" style="width: 300px">
          <el-option label="全部" :value="null"></el-option>
          <el-option label="投放" :value="1"></el-option>
          <el-option label="停投" :value="0"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" round @click="getPage">查询</el-button>
        <el-button type="primary" round @click="resetFormQuery('search')">重置</el-button>
      </el-form-item>
    </el-form>

    <div class="justify-sb mb-15">
      <div>
        <el-button type="primary" icon="el-icon-plus" round @click="handleAddAccount('')">新增直客账户</el-button>
        <el-button type="primary" round @click="handleImportAccount">导入子客账户</el-button>
      </div>
    </div>

    <!-- 表格 -->
    <TablePane :data-source="dataSource" @getPage="getPage"> </TablePane>

    <!-- 新增直客账户 -->
    <add-adv-account ref="addAdvAccount" @confirm="handleAuth"></add-adv-account>

    <!-- 导入子客账户 -->
    <import-housekeeper ref="importHousekeeper" @confirm="getPage"></import-housekeeper>
  </div>
</template>

<script>
import TablePane from '@/components/tablePane.vue' // authorize adMarketingApiClientsSelect
import ImportTencentHousekeeper from './components/ImportTencentHousekeeper.vue'
import AddTencentAdvAccount from './components/AddTencentAdvAccount.vue'
import { getTencentAdvList, getHousekeeperSelect } from '@/network/api/account/tencentAdvAccount.js'
// import AccountBaidu from '@/mixin/account_baidu'

export default {
  components: {
    TablePane,
    'import-housekeeper': ImportTencentHousekeeper,
    'add-adv-account': AddTencentAdvAccount
  },
  // mixins: [AccountBaidu],
  data() {
    return {
      id: '',
      // 搜索字段
      search: {
        putInStatus: null, // 状态
        accountId: null, // 账户id
        type: null, // 类型
        adMarketingApiServiceId: null // 服务商
      },
      adMpAmList: [], // 服务商下拉
      editInfo: false,
      advInfo_visible: false, // 投放账户弹窗
      editItem_visible: false, // 编辑账户
      dataSource: {
        data: [],
        cols: [
          {
            label: '账户ID',
            prop: 'accountId'
          },
          {
            label: '账号名称',
            prop: 'corporationName'
          },
          {
            label: '账户类型',
            prop: 'type',
            render: function (row) {
              return row.type || '-'
            }
          },
          {
            label: '关联服务商',
            prop: 'serviceName',
            render: function (row) {
              return row.serviceName || '-'
            }
          },
          {
            label: '项目',
            prop: 'projectName'
          },
          {
            label: '产品',
            render: function (recode) {
              return !recode.productName ? '不限' : recode.productName
            }
          },
          {
            label: '投放人员',
            prop: 'launchName'
          },
          {
            label: '备注',
            prop: 'remark'
          },
          {
            label: '投放状态',
            width: 100,
            render: (recode) => {
              return recode.putInStatus === 1 ? <el-tag type='success'>投放</el-tag> : <el-tag type='danger'>停投</el-tag>
            }
          }
        ], // 表格的列数据
        isIndex: true, // 列表序号
        loading: false, // loading
        isOperation: true,
        isSelection: false,
        pageData: {
          total: 0 // 总条数
        },
        handleSelectionChange: (selection) => { },
        operation: {
          // 表格有操作列时设置
          label: '操作', // 列名
          width: 100, // 根据实际情况给宽度
          fixed: 'right',
          data: [
            {
              icon: 'el-icon-edit',
              tooltip: '编辑',
              handleRow: (index, row) => {
                this.handleAddAccount(row)
              }
            }
          ]
        }
      }
    }
  },
  // mounted() {
  //   this.getPage()
  // },
  // watch: {
  //   projectList(value) {
  //     if (value.length !== 0) {
  //       this.getPage()
  //     }
  //   }
  // },
  mounted() {
    this._getAdMpAm()
    this.getPage()
  },
  methods: {
    resetFormQuery(formName) {
      this.$refs[formName].resetFields()
      setTimeout(() => {
        this.getPage()
      }, 400)
    },

    // 新增直客账户
    handleAddAccount(row) {
      this.$refs.addAdvAccount.showModal(row)
    },

    // 导入子客账户
    handleImportAccount() {
      this.$refs.importHousekeeper.showModal()
    },

    // 授权账户
    handleAuth(url) {
      this.getPage()
      this.gdtClick(url)
    },

    // 跳转授权链接
    async gdtClick(url) {
      if (!url) return
      window.open(url, '_blank')
    },

    /**
     * @description: 获取列表
     * @param {*} params
     */
    getPage(params = { pageNum: 1, pageSize: 10 }) {
      this.dataSource.loading = true
      let search = {
        ...this.search,
        pageNum: params.pageNum || 1,
        pageSize: params.pageSize || 10
      }
      getTencentAdvList(search).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          // records
          this.dataSource.data = records
          this.dataSource.pageData.total = total
          this.dataSource.loading = false
        }
      })
    },

    // 获取服务商
    _getAdMpAm(serviceAccountId = 0) {
      getHousekeeperSelect({ serviceAccountId }).then((res) => {
        if (res.code === 200) {
          this.adMpAmList = res.data
        }
      })
    }
  }
}
</script>
