<template>
  <el-dialog
    :title="isEdit ? '编 辑': '新 增'"
    width="1040px"
    :visible.sync="visible"
    :before-close="close"
    label-position="right"
    :modal-append-to-body="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <el-form ref="ent" :model="ent" :rules="rules" label-width="110px">
      <!-- <el-form-item label="" prop="bsProjectId">
        <el-input v-model="ent.bsProjectId" />
      </el-form-item> -->
      <el-form-item label="项目名称" prop="projectName">
        <el-input v-model="ent.projectName" :disabled="isEdit" maxlength="30" placeholder="最大输入长度30 超出截断" />
      </el-form-item>
      <el-form-item label="可用状态" prop="status">
        <el-radio-group v-model="ent.status">
          <el-radio-button label="可用" />
          <el-radio-button label="不可用" />
        </el-radio-group>
      </el-form-item>
      <el-form-item label="项目负责人" prop="leaderUserId">
        <el-select
          v-model="ent.leaderUserIdWeb"

          filterable
          default-first-option
          placeholder="请选择"
        >
          <el-option
            v-for="item in options"
            :key="item.key"
            :label="item.label"
            :value="item.key"
          />
        </el-select>

        <!-- <el-input v-model="ent.leaderUserId" /> -->
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="ent.remark" maxlength="100" type="textarea" placeholder="最大输入长度100 超出截断" />
      </el-form-item>

      <el-form-item label="可见状态" prop="projectVisibleType">
        <el-radio-group v-model="ent.projectVisibleType" @change="radioChange">
          <el-radio-button label="全员可见" />
          <el-radio-button label="指定人员" />
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="visibleTransfer" prop="remark">
        <div class="edit_dev">
          <el-transfer
            v-model="ent.visibleUserIdListWeb"
            :titles="['待选择', '已选择']"
            filterable
            filter-placeholder="请输入"
            :data="options"
          />
        </div>
      </el-form-item>

      <!-- <el-form-item label="可见状态/ALL:全员/PART:部分" prop="projectVisibleType">
        <el-input v-model="ent.projectVisibleType" />
      </el-form-item> -->
      <!-- <el-form-item label="可见人员结合" prop="visibleUserIdList">
        <el-input v-model="ent.visibleUserIdList" />
      </el-form-item> -->
      <!-- <el-form-item label="状态/ISUSEING、INNOTUSEING" prop="status">
        <el-input v-model="ent.status" />
      </el-form-item>
      <el-form-item label="逻辑删除" prop="deleted">
        <el-input v-model="ent.deleted" />
      </el-form-item>
      <el-form-item label="创建者" prop="createBy">
        <el-input v-model="ent.createBy" />
      </el-form-item>
      <el-form-item label="创建时间" prop="createTime">
        <el-input v-model="ent.createTime" />
      </el-form-item>
      <el-form-item label="更新者" prop="updateBy">
        <el-input v-model="ent.updateBy" />
      </el-form-item>
      <el-form-item label="更新时间" prop="updateTime">
        <el-input v-model="ent.updateTime" />
      </el-form-item> -->

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button round @click="close">取消</el-button>
      <!-- <el-popconfirm class="ml-10" confirm-button-text="提交" cancel-button-text="取消" icon="el-icon-info" icon-color="red" title="确定提交？" @confirm="submit">  </el-popconfirm> -->
      <el-button slot="reference" type="primary" round @click="submit">确定</el-button>

    </span>
  </el-dialog>
</template>
<script>
import {
  Add,
  Update
} from '@/network/api/basicSetting/project/basicSettingProject'

import { getUser } from '@/network/api/user'
import { BasicSettingProjectEnt, formRules } from './object'

export default {
  inject: ['loadData'],
  data() {
    return {
      ent: new BasicSettingProjectEnt(),
      rules: formRules,
      isEdit: false,
      visible: false,
      cityList: [],
      options: [],
      visibleTransfer: false
    }
  },
  mounted() {
    this.getUserList()
  },
  methods: {

    radioChange() {
      const VisibleType = this.ent.projectVisibleType
      if (VisibleType === '全员可见') {
        this.visibleTransfer = false
        if (!this.isEdit) {
          this.ent.visibleUserIdListWeb = []
        }
      } else {
        this.visibleTransfer = true
      }
    },
    getUserList() {
      getUser().then(res => {
        if (res.code === 200) {
          this.options = res.data
        }
      })
    },
    submit() {
      this.$refs.ent.validate((validate) => {
        if (validate) {
          if (this.ent.projectVisibleType === '指定人员') {
            if (undefined === this.ent.visibleUserIdListWeb || this.ent.visibleUserIdListWeb.length === 0) {
              this.$message({
                message: '请选择指定人员',
                type: 'error'
              })
              return
            }
          }
          if ((undefined !== this.ent.leaderUserIdWeb || undefined !== this.ent.visibleUserIdListWeb) && undefined !== this.options) {
            if (undefined !== this.ent.leaderUserIdWeb) {
              // this.ent.leaderUserIdWeb.forEach(x => {
              //   this.options.forEach(w => {
              //     if (w.key === x) {
              //       this.ent.leaderUserName = this.ent.leaderUserName === undefined ? w.label : this.ent.leaderUserName + ',' + w.label
              //     }
              //   })
              // })
              this.ent.leaderUserId = this.ent.leaderUserIdWeb
              this.options.forEach(item => {
                if (item.key === this.ent.leaderUserId) {
                  this.ent.leaderUserName = item.label
                }
              })
            }
            if (undefined !== this.ent.visibleUserIdListWeb) {
              this.ent.visibleUserIdListWeb.forEach(x => {
                this.options.forEach(w => {
                  if (w.key === x) {
                    this.ent.visibleUserIdListName = this.ent.visibleUserIdListName === undefined || this.ent.visibleUserIdListName === null ? w.label : this.ent.visibleUserIdListName + ',' + w.label
                  }
                })
              })
              this.ent.visibleUserIdList = this.ent.visibleUserIdListWeb.toString()
            }
          }
          this.isEdit ? this.updateRow() : this.addRow()
        }
      })
    },
    addRow() {
      const req = { ...this.ent }
      Add(req).then(res => {
        if (res.code === 200) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.loadData()
          this.close()
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    updateRow() {
      const req = { ...this.ent }
      Update(req).then(res => {
        if (res.code === 200) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.loadData()
          this.close()
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    close() {
      this.visible = false
      this.$nextTick(() => {
        this.$refs.ent.resetFields()
      })
    },
    show(isEdit = false, ent) {
      this.ent = ent
      if (undefined === ent.projectVisibleType) {
        this.ent.projectVisibleType = '全员可见'
        this.visibleTransfer = false
      }
      if (undefined === ent.status) {
        this.ent.status = '可用'
      }

      this.isEdit = isEdit
      this.visible = true
      this.$nextTick(() => {
        if (this.isEdit) {
          console.log('不能为空！')
        }
      })
    },

    showE(isEdit = false, ent) {
      this.ent = new BasicSettingProjectEnt()
      this.visibleTransfer = false
      this.ent.bsProjectId = ent.bsProjectId
      this.ent.projectName = ent.projectName
      this.ent.projectVisibleType = ent.projectVisibleType
      this.ent.status = ent.status
      this.ent.remark = ent.remark

      if (undefined === ent.projectVisibleType) {
        this.ent.projectVisibleType = '全员可见'
        this.visibleTransfer = false
      }
      if (undefined === ent.status) {
        this.ent.status = '可用'
      }

      if (ent.projectVisibleType === '指定人员') {
        this.visibleTransfer = true
        if (undefined !== ent.visibleUserIdList) {
          this.ent.visibleUserIdListWeb = ent.visibleUserIdList.split(',')
        }
      }
      if (undefined !== ent.leaderUserId) {
        this.ent.leaderUserIdWeb = ent.leaderUserId
      }

      this.isEdit = isEdit
      this.visible = true
      this.$nextTick(() => {
        if (this.isEdit) {
          this.ent.visibleUserIdListName = undefined
          this.ent.leaderUserName = undefined
        }
      })
    }
  }
}
</script>
<style scoped>
   .edit_dev >>> .el-transfer-panel {
     width:300px;
   }
</style>
