var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c("p", { staticClass: "base-title" }, [
      _vm._v(_vm._s(_vm.nowWidget.type) + ": " + _vm._s(_vm.nowWidget.title)),
    ]),
    _c(
      "div",
      { staticClass: "tip mt-10" },
      [
        _vm.desc !== "" ? _c("p", [_vm._v(_vm._s(_vm.desc))]) : _vm._e(),
        _vm._t("desc"),
      ],
      2
    ),
    _c("div", { staticClass: "dotted" }),
    _c("p", { staticStyle: { margin: "20px 0", "text-align": "center" } }, [
      _vm._v(_vm._s(_vm.title !== "" ? _vm.title : "基础设置")),
    ]),
    _c("div", { staticClass: "slot-body" }, [_vm._t("body")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }