import { render, staticRenderFns } from "./copyGh.vue?vue&type=template&id=6ab3a204&scoped=true&"
import script from "./copyGh.vue?vue&type=script&lang=js&"
export * from "./copyGh.vue?vue&type=script&lang=js&"
import style0 from "./copyGh.vue?vue&type=style&index=0&id=6ab3a204&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ab3a204",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/Data/ad_frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6ab3a204')) {
      api.createRecord('6ab3a204', component.options)
    } else {
      api.reload('6ab3a204', component.options)
    }
    module.hot.accept("./copyGh.vue?vue&type=template&id=6ab3a204&scoped=true&", function () {
      api.rerender('6ab3a204', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/assetManagement/huge_ldy/workbench_component/copyGh.vue"
export default component.exports