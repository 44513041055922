var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c(
        "el-col",
        { attrs: { span: 12 } },
        [
          _c(
            "el-form",
            {
              ref: "redisForm",
              attrs: { "label-width": "100", model: _vm.redisCommand },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Command", prop: "command" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      model: {
                        value: _vm.redisCommand.command,
                        callback: function ($$v) {
                          _vm.$set(_vm.redisCommand, "command", $$v)
                        },
                        expression: "redisCommand.command",
                      },
                    },
                    _vm._l(_vm.commandList, function (item) {
                      return _c(
                        "el-option",
                        { key: item, attrs: { value: item } },
                        [_vm._v(_vm._s(item))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Key", prop: "key" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Key" },
                    model: {
                      value: _vm.redisCommand.key,
                      callback: function ($$v) {
                        _vm.$set(_vm.redisCommand, "key", $$v)
                      },
                      expression: "redisCommand.key",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "HKey", prop: "value" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "HKey" },
                    model: {
                      value: _vm.redisCommand.hkey,
                      callback: function ($$v) {
                        _vm.$set(_vm.redisCommand, "hkey", $$v)
                      },
                      expression: "redisCommand.hkey",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Value", prop: "value" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Value", type: "textarea", rows: 10 },
                    model: {
                      value: _vm.redisCommand.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.redisCommand, "value", $$v)
                      },
                      expression: "redisCommand.value",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticStyle: { "text-align": "right" } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      attrs: {
                        type: "primary",
                        loading: _vm.isBtnLoading,
                        round: "",
                      },
                      on: { click: _vm.exec },
                    },
                    [_vm._v("执行")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      attrs: { round: "" },
                      on: { click: _vm.reset },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Result", prop: "result" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "Result",
                      type: "textarea",
                      rows: 10,
                    },
                    model: {
                      value: _vm.result,
                      callback: function ($$v) {
                        _vm.result = $$v
                      },
                      expression: "result",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }