<!--
 * @Description: 计划/ 广告 展示 tree
 * @Date: 2021-12-06 15:57:16
 * @LastEditTime: 2022-03-08 17:28:39
-->
<template>
  <div class="plan-modal" style="height: 300px;overflow-y: auto;">
    <el-tree :data="treeData" node-key="id" default-expand-all :expand-on-click-node="false">
      <span slot-scope="row" class="custom-tree-node">
        <el-tooltip :content="row.node.label" placement="top">
          <span class="label">{{ row.node.label }}</span>
        </el-tooltip>
        <span v-if="row.node.level !== 1" class="handleTree-icon">
          <i class="el-icon-circle-close" @click="() => remove(row.node, row.data)"></i>
        </span>
      </span>
    </el-tree>
    <!-- 添加计划 -->
    <SelectModal
      ref="modal"
      dialog-title="推广计划"
      left-title="投放账户"
      :title-info="{
        title: '选择推广计划',
        leftLevel: '1',
        rightLevel: '1'
      }"
      :visible="planModalVisible"
      :left-query-list="accountBaiduAdv"
      :data="baiduMePlan"
      :total="total"
      @onOk="getSelectPlanData"
    />
  </div>
</template>

<script>
import { getPlan, savePlan, deleteRelation } from '@/network/api/advManagement/advManagementTencent' // 获取计划模板
import SelectModal from '../component/selectModal/selectModal.vue'

export default {
  provide: function() {
    return {
      getDataByLeftQuery: this.getPlanFun,
      delete: this.delete
    }
  },
  inject: ['close'],

  components: {
    SelectModal
  },

  props: {
    planModalVisible: {
      type: Boolean,
      default: false
    },
    accountBaiduAdv: {
      type: Array,
      default: () => []
    },
    planData: {
      type: Array,
      default: () => []
    },
    taskId: {
      type: Number,
      default: 0
    },
    taskStatus: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      data: this.planData,
      accountData: this.accountBaiduAdv,
      total: 0,
      baiduMePlan: []
    }
  },

  computed: {
    treeData() {
      const data = []
      this.accountData.forEach(item => {
        let children = []
        const plan = this.data.filter(f => f.taskAccountId === item._id)[0]
        if (plan && plan.campaignId) {
          children = [{
            id: plan?.taskCampaignAdInfoId,
            label: plan?.campaignName
          }]
        }
        data.push({
          id: item._id,
          label: item._name,
          children
        })
      })
      return data
    }
  },

  watch: {
    planData(value) {
      this.data = value
    },
    accountBaiduAdv: {
      handler(value) {
        this.accountData = value
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    remove(node, data) {
      const parent = node.parent
      const children = parent.data.children || parent.data
      const index = children.findIndex((d) => d.id === data.id)
      this.delete(data.id)
      children.splice(index, 1)
    },

    delete(id) {
      this.$emit('clearTaskGenerate')
      deleteRelation(id).then(({ code }) => {
        if (code === 200) {
          this.$emit('byTaskIdGetAllFun')
          this.$message.success('删除计划成功!')
          this.close()
        }
      })
    },

    /**
     * 获取选中账户下的计划
     */
    async getPlanFun(item, pageNum = 1, text = null) {
      const params = {
        localAccountId: item.localAccountId,
        campaignName: text || null,
        pageNum,
        pageSize: 10
      }
      const { code, data } = await getPlan(params)
      if (code === 200) {
        this.total = data.total
        data.list.forEach((ele) => {
          ele.id = `${ele.campaignId}+${item._id}`
          ele.label = ele.campaignName
          ele.key = item._id
          ele.accountId = item._id
          ele.parentName = item._name
          ele.localAccountId = item.localAccountId
        })
        // 判线上计划板是否被选中, 选中置灰
        if (this.data.length) {
          data.list.forEach((it) => {
            this.data.map((el) => {
              if (it.id === `${el.campaignId}+${el.taskAccountId}`) {
                it.taskCampaignAdInfoId = el.taskCampaignAdInfoId
                it.disabled = true
              }
            })
          })
        }
        this.baiduMePlan = data.list
      }
    },

    /**
     * @desc 获取选中的计划后提交
     */
    getSelectPlanData(value) {
      let params = []
      if (!value || !value.length) {
        this.$message.error('请选择计划!')
        return
      }
      value.map((item) => {
        params.push({
          // taskId: this.taskId,
          taskAccountId: item._id,
          campaignId: item.id.split('+')[0],
          campaignName: item.label
        })
      })
      this.$emit('clearTaskGenerate')
      savePlan(params).then((res) => {
        if (res.code === 200) {
          this.$emit('byTaskIdGetAllFun')
          this.$message.success('计划保存成功!')
          this.close()
        }
      })
    }
  }
}
</script>

<style lang="scss">
.handleTree-icon :hover {
  color: #409eff;
}

.plan-modal {
  .custom-tree-node {
    // flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    width: 100px !important;
    .label {
      width: 300px !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
</style>
