/*
 * @Description: Do not edit
 * @Date: 2022-02-26 13:38:29
 * @LastEditTime: 2024-05-29 18:35:21
 */
export const searchForm = {
  // baiduId: null,
  masterName: null,
  oauthId: null,

  housekeeperName: null,
  housekeeperId: null,
  accountName: null,
  // oauthId: '',
  userId: null,
  userName: null,
  projectId: null,
  projectName: null,
  launchProductsNames: null,
  launchProductsIds: null,
  token: null,
  deleted: null,
  createBy: null,
  createTime: null,
  updateBy: null,
  updateTime: null,
  hkId: null,
  putInStatus: null,
  remark: null
}

export class AccountBaiduAdvEnt {
  oauthAdId
  userId
  userName
  projectId
  projectName
  launchProductsNames
  launchProductsIds
  putInStatus
  // ids
  // baiduId
  // housekeeperName
  // housekeeperId
  // hkId
  // accountName
  // userId
  // userName
  // projectId
  // projectName
  // launchProductsNames
  // launchProductsIds
  // token
  // deleted
  // createBy
  // createTime
  // updateBy
  // updateTime
  // remark
  // putInStatus
}

export const formRules = {
  baiduId: [{ required: true, message: '请填写', trigger: 'change' }],
  housekeeperName: [{ required: true, message: '请填写关联账户管家', trigger: 'change' }],
  housekeeperId: [{ required: true, message: '请填写管家', trigger: 'change' }],
  accountName: [{ required: true, message: '请填写账号名称', trigger: 'change' }],
  userId: [{ required: true, message: '请填写投放人员', trigger: 'change' }],
  userName: [{ required: true, message: '请填写投放人员', trigger: 'change' }],
  projectId: [{ required: true, message: '请填写关联项目', trigger: 'change' }],
  projectName: [{ required: true, message: '请填写关联项目', trigger: 'change' }],
  launchProductsNames: [{ required: true, message: '请填写投放产品', trigger: 'change' }],
  launchProductsIds: [{ required: true, message: '请填写投放产品id', trigger: 'change' }],
  token: [{ required: true, message: '请填写回传token', trigger: 'change' }],
  deleted: [{ required: true, message: '请填写逻辑删除', trigger: 'change' }],
  createBy: [{ required: true, message: '请填写创建者', trigger: 'change' }],
  createTime: [{ required: true, message: '请填写创建时间', trigger: 'change' }],
  updateBy: [{ required: true, message: '请填写更新者', trigger: 'change' }],
  updateTime: [{ required: true, message: '请填写更新时间', trigger: 'change' }],
  remark: [{ required: true, message: '请填写备注', trigger: 'change' }]
}
