<!--
 * @Description: 选择推广单元等框
 * @Date: 2022-10-25 09:19:23
-->

<template>
  <div class="dialog">
    <el-dialog :title="`选择${dialogTitle}`" :visible="visible" center width="90%" :modal-append-to-body="false"
      destroy-on-close @close="onClose">
      <el-form v-if="adv" ref="form" :inline="false" label-width="100px" :model="form" :rules="formRule">
        <el-form-item label="广告名称" prop="adcreativeName">
          <el-input v-model="form.adcreativeName" placeholder="请输入广告名称" maxlength="60" show-word-limit
            style="width: 400px"></el-input>
          <div class="display:start" style="width: 500px">
            通配符：
            <el-button type="text" @click="addAName('{投手名称}')">+投手名称</el-button>
            <el-button type="text" @click="addAName('{产品名}')">+产品名</el-button>
            <el-button type="text" @click="addAName('{日期}')">+日期</el-button>
            <el-button type="text" @click="addAName('{标号}')">+标号</el-button>
            <el-button type="text" @click="addAName('{账户ID}')">+账户ID</el-button>
            <el-button type="text" @click="addAName('{素材名}')">+素材名</el-button>
          </div>
        </el-form-item>
        <el-form-item label="日期配置" prop="dateConfiguration">
          <el-select style="width: 400px" v-model="form.dateConfiguration" @change="handleChangeDateType">
            <el-option label="沿用线上" :value="1"></el-option>
            <el-option label="自定义" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <!-- 当【日期配置】为自定义 -->
        <template v-if="form.dateConfiguration === 2">
          <el-form-item label="投放日期" prop="date">
            <el-select v-model="form.scheduleType" style="width: 180px;margin-right: 20px;"
              @change="handleChangeScheduleType">
              <el-option label="长期投放" :value="1"></el-option>
              <el-option label="指定开始与结束日期" :value="2"></el-option>
            </el-select>
            <!-- 当【投放日期】为指定开始与结束日期 -->
            <template v-if="form.scheduleType === 2">
              <el-date-picker v-model="form.date" value-format="yyyy-MM-dd" key="schedule-range" type="daterange"
                range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 400px;"
                :picker-options="pickerOptions">
              </el-date-picker>
            </template>
            <template v-else>
              <el-date-picker v-model="form.date" value-format="yyyy-MM-dd" key="schedule" type="date"
                placeholder="开始日期" style="width: 180px;" :picker-options="pickerOptions">
              </el-date-picker>
            </template>
          </el-form-item>
          <el-form-item label="投放时间" prop="scheduleRangeType">
            <el-select v-model="form.scheduleRangeType" style="width: 180px;margin-right: 20px;"
              @change="handleChangeScheduleRange">
              <el-option label="全天" :value="1"></el-option>
              <el-option label="指定开始与结束时间" :value="2"></el-option>
            </el-select>
            <!-- 当【投放时间】为指定开始与结束时间 -->
            <template v-if="form.scheduleRangeType === 2">
              <!-- <el-time-picker is-range v-model="form.time" range-separator="-" start-placeholder="开始时间"
                value-format="HH:mm" format="HH:mm" end-placeholder="结束时间" placeholder="选择时间范围" style="width: 400px;">
              </el-time-picker> -->
              <el-time-select placeholder="起始时间" v-model="form.startTime" :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '24:00'
              }" @change="handleSelectTime">
              </el-time-select>
              -
              <el-time-select placeholder="结束时间" v-model="form.endTime" :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '24:00',
                minTime: form.startTime
              }">
              </el-time-select>
            </template>
          </el-form-item>
        </template>
      </el-form>
      <span>
        <SelectModalTable v-if="type === 'table'" :visible="visible" :data-source="tableDataSource"
          :select-page-list="selectPageList" :title-info="titleInfo" :left-title="leftTitle" :dialog-title="dialogTitle"
          :data="data" :left-query-list="leftQueryList" @getSelectedData="getSelectedData" />
        <SelectModalTree v-if="type === 'tree'" ref="modalTree" :visible="visible" :total="total" :query-fun="queryFun"
          :title-info="titleInfo" :left-title="leftTitle" :dialog-title="dialogTitle" :data="data"
          :left-query-list="leftQueryList" :adv="adv" :select-page-list="selectPageList"
          @getSelectedData="getSelectedData" />
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onClose">取 消</el-button>
        <el-button type="primary" @click="onOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import SelectModalTree from './selectModalTree.vue'
import SelectModalTable from './selectModalTable.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  inject: ['close', 'setSelectPageList'],
  components: {
    SelectModalTree,
    SelectModalTable
  },
  props: {
    type: {
      type: String,
      default: 'tree'
    },
    dialogTitle: {
      type: String,
      default: ''
    },
    leftTitle: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    },
    titleInfo: {
      type: Object,
      default: () => { }
    },
    // 筛选数据
    data: {
      type: Array,
      default: () => []
    },
    total: {
      type: Number,
      default: 0
    },
    // 左侧用来筛选数据
    leftQueryList: {
      type: Array,
      default: () => []
    },
    tableDataSource: {
      type: Object,
      default: () => { }
    },
    // 选中的项
    /* eslint-disable */
    selectPageList: {
      type: Array,
      require: false
    },
    /* eslint-disable */
    queryFun: {
      type: Array,
      default: () => []
    },
    // 是否显示广告筛选
    adv: {
      type: Boolean,
      default: false
    },
    // 产品名称
    productName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // adSiteSetOption,
      selected: this.titleInfo,
      templateData: [], // 选中的数据
      nameIndex: 1,
      // adcreativeName: `广告名称`, // 广告创意名称
      localAdgroupName: ``,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      form: {
        adcreativeName: `广告名称`,
        dateConfiguration: 1, // 日期配置 1沿用线上 2自定义
        scheduleType: 1, // 投放日期
        scheduleRangeType: 1, // 投放时间
        date: '', // 投放日期
        startTime: '', // 投放时间
        endTime: ''
      },

      formRule: {
        adcreativeName: [{ required: true, message: '请输入广告名称', trigger: 'blur' }],
        dateConfiguration: [{ required: true, message: '请选择日期配置', trigger: 'change' }],
        date: [{ required: true, message: '请选择投放日期', trigger: 'change' }],
        scheduleRangeType: [{ required: true, message: '请选择投放时间', trigger: 'change' }]
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  watch: {
    visible(value) {
      if (value) {
        this.templateData = []
        if (this.selectPageList && this.selectPageList.length) {
        let item = this.selectPageList[0]
        let launchDate = item.launchDate ? JSON.parse(item.launchDate) : {type: 1, startTime: '', endTime: ''}
        let launchTime = item.launchTime ? JSON.parse(item.launchTime) : {type: 1, startTime: '', endTime: ''}
        this.form = {
          adcreativeName: item.localAdgroupName,
          dateConfiguration: item.dateConfiguration || 1, // 日期配置 1沿用线上 2自定义
          scheduleType: launchDate.type, // 投放日期
          scheduleRangeType: launchTime.type, // 投放时间
          date: launchDate.type === 1 ? launchDate.startTime : [launchDate.startTime, launchDate.endTime], // 投放日期
          startTime: launchTime.type === 2 ? launchTime.startTime : '', // 投放时间
          endTime: launchTime.type === 2 ? launchTime.endTime : ''
        }
      }
      }
    }
  },
  methods: {
    addAName(text) {
      this.form.adcreativeName = this.form.adcreativeName + text
      if (this.form.adcreativeName.length > 60) {
        this.form.adcreativeName = this.form.adcreativeName.substring(0, 60)
      }
      // this.nameIndex++
    },

    /**
     * @description 获取最后选择的计划数据
     * @param {data} 模板数据
     */
    getSelectedData(data) {
      this.templateData = data
    },

    replaceName() {
      var reg = /\{(.*?)\}/gi
      var tmp = this.form.adcreativeName.match(reg)
      this.localAdgroupName = this.form.adcreativeName
      if (tmp) {
        tmp.forEach(name => {
          if (name === '{投手名称}') {
            this.localAdgroupName = this.localAdgroupName.replace(name, this.userInfo.username)
          }
          if (name === '{产品名}') {
            this.localAdgroupName = this.localAdgroupName.replace(name, this.productName || '')
          }
          if (name === '{日期}') {
            this.localAdgroupName = this.localAdgroupName.replace(name, moment().format('YYYYMMDDHHmmss'))
          }
        })
      }
    },


    // 切换日期配置
    handleChangeDateType(value) {
      if (value === 1) {
        this.form = {
          ...this.form,
          scheduleType: 1, // 投放日期
          scheduleRangeType: 1, // 投放时间
          date: '', // 投放日期
          startTime: '', // 投放时间
          endTime: ''
        }
      }
    },

    // 切换投放日期类型
    handleChangeScheduleType(value) {
      this.form.date = ''
    },

    // 切换投放时间
    handleChangeScheduleRange(value) {
      this.form.startTime = ''
      this.form.endTime = ''
    },

    // 选择投放时间
    handleSelectTime() {
      this.form.endTime = ''
    },

    /**
     * @description 关闭弹框
     */
    onClose() {
      this.setSelectPageList([])
      this.close()
      // this.$refs.modalTree.resetData()
    },

    /**
     * @description 弹框 => 点击确定
     */
    onOk() {
      if (this.adv) {
        this.$refs.form.validate(valid => {
          if (!valid) {
            return
          }
          this.replaceName()
          let { dateConfiguration, scheduleType, scheduleRangeType, date, startTime, endTime } = this.form
          if (dateConfiguration === 2 && scheduleRangeType === 2 && (!startTime || !endTime)) {
            this.$message.error('请选择投放时间')
            return
          }
          this.templateData.forEach(item => {
            item.localAdgroupName = this.localAdgroupName
            item.dateConfiguration = dateConfiguration
            if (dateConfiguration === 2) {
              let _date = typeof date === 'string' ? [date, ''] : date
              item.launchDate = {
                type: scheduleType,
                startTime: _date[0],
                endTime: _date[1]
              }
              item.launchTime = {
                type: scheduleRangeType,
                startTime: startTime,
                endTime: endTime
              }
            } else {
              item.launchDate = {
                type: '',
                startTime: '',
                endTime: ''
              }
              item.launchTime = {
                type: '',
                startTime: '',
                endTime: ''
              }
            }
          })
          this.$emit('onOk', this.templateData)
        })
        
      } else {
        this.$emit('onOk', this.templateData)
      }
    }
  }
}
</script>

<style lang="scss">
.dialog {
  .el-tabs .el-tabs__item.is-active {
    border-top: solid 0px #409eff;
  }
}
</style>

<style lang="scss" scoped>
p {
  margin: 0;
  padding: 0;
}
</style>
