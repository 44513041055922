var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rule" },
    [
      _c(
        "el-form",
        {
          ref: "submitParams",
          attrs: { model: _vm.ruleData, "label-width": "100px" },
        },
        [
          _c(
            "box",
            { attrs: { name: "规则名称" } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "规则名称",
                    prop: "rule_name",
                    rules: [{ required: true, message: "请输入规则名称!" }],
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: { placeholder: "请输入规则名称" },
                    model: {
                      value: _vm.ruleData.rule_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleData, "rule_name", $$v)
                      },
                      expression: "ruleData.rule_name",
                    },
                  }),
                ],
                1
              ),
              _vm.ruleData.platform === "gdt"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "规则类型",
                        prop: "rule_type",
                        rules: [{ required: true, message: "请选择规则类型" }],
                      },
                    },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          on: { change: _vm.changeRuleType },
                          model: {
                            value: _vm.ruleData.rule_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleData, "rule_type", $$v)
                            },
                            expression: "ruleData.rule_type",
                          },
                        },
                        [
                          _c(
                            "el-checkbox",
                            { key: "订单回传", attrs: { label: 1 } },
                            [_vm._v("订单回传")]
                          ),
                          _c(
                            "el-checkbox",
                            { key: "企微加粉回传", attrs: { label: 2 } },
                            [_vm._v("企微加粉回传")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-collapse",
            {
              model: {
                value: _vm.collapseVal,
                callback: function ($$v) {
                  _vm.collapseVal = $$v
                },
                expression: "collapseVal",
              },
            },
            [
              _vm.ruleData.rule_type.includes(1)
                ? _c(
                    "el-collapse-item",
                    { attrs: { title: "订单回传配置", name: 1 } },
                    [
                      _c(
                        "box",
                        { attrs: { name: "模式选择" } },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 10 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "回传类型",
                                        prop: "type",
                                        rules: [{ required: true }],
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "400px" },
                                          model: {
                                            value: _vm.ruleData.type,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleData,
                                                "type",
                                                $$v
                                              )
                                            },
                                            expression: "ruleData.type",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "固定模式",
                                              value: "FIXED",
                                            },
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "自定义模式",
                                              value: "FREE",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "适用范围",
                                        prop: "rule_range",
                                        rules: [{ required: true }],
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "400px" },
                                          on: { change: _vm.ruleRangeChange },
                                          model: {
                                            value: _vm.ruleData.rule_range,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleData,
                                                "rule_range",
                                                $$v
                                              )
                                            },
                                            expression: "ruleData.rule_range",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "公众号",
                                              value: "PUBLIC",
                                            },
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "投放广告",
                                              value: "AD",
                                            },
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "快应用",
                                              value: "QUICKAPP",
                                            },
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "短剧",
                                              value: "VNOVEL",
                                            },
                                          }),
                                          _vm.ruleData.platform === "gdt"
                                            ? _c("el-option", {
                                                attrs: {
                                                  label: "小游戏",
                                                  value: "GAME",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "box",
                        { attrs: { name: "回传条件设置" } },
                        [
                          _vm.ruleData.type === "FIXED"
                            ? _c("Fixed", {
                                attrs: { "rule-data": _vm.ruleData },
                              })
                            : _c("Customize", {
                                attrs: { "rule-data": _vm.ruleData },
                              }),
                        ],
                        1
                      ),
                      _c("box", { attrs: { name: "关联账号" } }, [
                        _vm.ruleData.rule_range === "PUBLIC"
                          ? _c("div", [
                              _vm.isShowAccounts
                                ? _c(
                                    "div",
                                    [
                                      _vm.ruleData.platform === "gdt"
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "回传类型",
                                                    prop: "return_action_type",
                                                    rules: [{ required: true }],
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "400px",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.changeActionType,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.ruleData
                                                            .return_action_type,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.ruleData,
                                                            "return_action_type",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "ruleData.return_action_type",
                                                      },
                                                    },
                                                    [
                                                      _c("el-option", {
                                                        attrs: {
                                                          label: "数据源",
                                                          value: 1,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "flex-direction": "row",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticStyle: {
                                                        width: "300px",
                                                      },
                                                      attrs: {
                                                        prop: "wx_appid",
                                                        label: "小说",
                                                        rules: {
                                                          required: true,
                                                          message: "请选择小说",
                                                          trigger: "change",
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-select",
                                                        {
                                                          model: {
                                                            value:
                                                              _vm.ruleData
                                                                .wx_appid,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.ruleData,
                                                                "wx_appid",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "ruleData.wx_appid",
                                                          },
                                                        },
                                                        [
                                                          _c("el-option", {
                                                            attrs: {
                                                              label: "阳光小说",
                                                              value: "P1001",
                                                            },
                                                          }),
                                                          _c("el-option", {
                                                            attrs: {
                                                              label: "番茄小说",
                                                              value: "P1016",
                                                            },
                                                          }),
                                                          _c("el-option", {
                                                            attrs: {
                                                              label: "阅文小说",
                                                              value: "P1003",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticStyle: {
                                                        width: "300px",
                                                      },
                                                      attrs: {
                                                        rules: {
                                                          required:
                                                            _vm.ruleData
                                                              .platform ===
                                                              "gdt" &&
                                                            _vm.ruleData
                                                              .rule_range ===
                                                              "PUBLIC",
                                                          message:
                                                            "请输入数据源ID",
                                                          trigger: "blur",
                                                        },
                                                        label: "数据源ID",
                                                        prop: "data_id",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: {
                                                          placeholder:
                                                            "输入数据源ID",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleData
                                                              .data_id,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleData,
                                                              "data_id",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleData.data_id",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-form-item",
                                                    {
                                                      staticStyle: {
                                                        width: "300px",
                                                      },
                                                      attrs: {
                                                        rules: {
                                                          required: true,
                                                          message:
                                                            "请输入APPID",
                                                          trigger: "blur",
                                                        },
                                                        label: "APPID",
                                                        prop: "data_appid",
                                                      },
                                                    },
                                                    [
                                                      _c("el-input", {
                                                        attrs: {
                                                          placeholder:
                                                            "创建数据源公众号/小程序APPID",
                                                        },
                                                        on: {
                                                          input:
                                                            _vm.inputChangeAppId,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.ruleData
                                                              .data_appid,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.ruleData,
                                                              "data_appid",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "ruleData.data_appid",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "justify-content":
                                                      "flex-start",
                                                    "margin-left": "90px",
                                                  },
                                                },
                                                [
                                                  _c("AssociatedAccountV2", {
                                                    ref: "account",
                                                    attrs: {
                                                      accounts:
                                                        _vm.default_accounts,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _c("AssociatedAccount", {
                                            ref: "account",
                                            attrs: {
                                              accounts: _vm.default_accounts,
                                            },
                                          }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm.ruleData.rule_range === "AD"
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  padding: "20px",
                                  "text-align": "center",
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "plans",
                                      rules: {
                                        required: true,
                                        message: "请输入放广告计划Id",
                                        trigger: "change",
                                      },
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "600px" },
                                      attrs: {
                                        value: _vm.ruleData.plans,
                                        type: "textarea",
                                        autosize: { minRows: 8, maxRows: 8 },
                                        placeholder:
                                          "输入投放广告计划，若多个计划请换行",
                                      },
                                      on: { input: _vm.plansInput },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.ruleData.rule_range === "VNOVEL"
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  padding: "20px",
                                  "text-align": "left",
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "回传类型",
                                      prop: "return_action_type",
                                      rules: [{ required: true }],
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "400px" },
                                        on: { change: _vm.changeActionType },
                                        model: {
                                          value:
                                            _vm.ruleData.return_action_type,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleData,
                                              "return_action_type",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "ruleData.return_action_type",
                                        },
                                      },
                                      [
                                        _vm.ruleData.platform === "oceanengine"
                                          ? [
                                              _c("el-option", {
                                                attrs: {
                                                  label: "资产化回传",
                                                  value: 3,
                                                },
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "获客助手回传",
                                                  value: 4,
                                                },
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "IP+UA",
                                                  value: 5,
                                                },
                                              }),
                                            ]
                                          : [
                                              _c("el-option", {
                                                attrs: {
                                                  label: "数据源",
                                                  value: 1,
                                                },
                                              }),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "转化归因",
                                                  value: 2,
                                                },
                                              }),
                                            ],
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "flex-direction": "row",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { width: "300px" },
                                        attrs: {
                                          prop: "vnovel_type",
                                          label: "短剧",
                                          rules: {
                                            required: true,
                                            message: "请选择短剧类型",
                                            trigger: "change",
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            on: {
                                              change:
                                                _vm.handleSelectQuickAppType,
                                            },
                                            model: {
                                              value: _vm.ruleData.vnovel_type,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.ruleData,
                                                  "vnovel_type",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "ruleData.vnovel_type",
                                            },
                                          },
                                          _vm._l(
                                            _vm.vnovelTypeList,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.value,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.value,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    (_vm.ruleData.platform === "gdt" ||
                                      (_vm.ruleData.platform ===
                                        "oceanengine" &&
                                        _vm.ruleData.return_action_type ==
                                          3)) &&
                                    _vm.vnovelApplet.includes(
                                      _vm.ruleData.vnovel_type
                                    )
                                      ? _c(
                                          "el-form-item",
                                          {
                                            staticStyle: { width: "300px" },
                                            attrs: {
                                              prop: "wx_appid",
                                              label: "小程序",
                                              rules: {
                                                required: true,
                                                message: "请选择小程序",
                                                trigger: "change",
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                on: {
                                                  change:
                                                    _vm.handleSelectApplet,
                                                },
                                                model: {
                                                  value: _vm.ruleData.wx_appid,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.ruleData,
                                                      "wx_appid",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "ruleData.wx_appid",
                                                },
                                              },
                                              _vm._l(
                                                _vm.appletOptions,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.value,
                                                    attrs: {
                                                      label: item.label,
                                                      value: item.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.ruleData.platform === "gdt" &&
                                    _vm.ruleData.return_action_type === 1
                                      ? _c(
                                          "el-form-item",
                                          {
                                            staticStyle: { width: "300px" },
                                            attrs: {
                                              rules: {
                                                required:
                                                  _vm.ruleData.platform ===
                                                    "gdt" &&
                                                  _vm.ruleData.rule_range ===
                                                    "VNOVEL",
                                                message: "请输入数据源ID",
                                                trigger: "blur",
                                              },
                                              label: "数据源ID",
                                              prop: "data_id",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "输入数据源ID",
                                              },
                                              on: { input: _vm.inputChangeId },
                                              model: {
                                                value: _vm.ruleData.data_id,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.ruleData,
                                                    "data_id",
                                                    $$v
                                                  )
                                                },
                                                expression: "ruleData.data_id",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.ruleData.platform === "gdt" &&
                                    _vm.ruleData.return_action_type === 1
                                      ? _c(
                                          "el-form-item",
                                          {
                                            staticStyle: { width: "300px" },
                                            attrs: {
                                              rules: {
                                                required:
                                                  _vm.ruleData.platform ===
                                                    "gdt" &&
                                                  _vm.ruleData.rule_range ===
                                                    "VNOVEL",
                                                message:
                                                  "请请输入输入推广渠道ID",
                                                trigger: "blur",
                                              },
                                              label: "APPID",
                                              prop: "data_appid",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder:
                                                  "创建数据源公众号/小程序APPID",
                                              },
                                              on: {
                                                input: _vm.inputChangeAppId,
                                              },
                                              model: {
                                                value: _vm.ruleData.data_appid,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.ruleData,
                                                    "data_appid",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "ruleData.data_appid",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "plans",
                                      rules: {
                                        required: true,
                                        message: "请输入输入推广渠道ID",
                                        trigger: "change",
                                      },
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "600px" },
                                      attrs: {
                                        value: _vm.ruleData.plans,
                                        type: "textarea",
                                        autosize: { minRows: 8, maxRows: 8 },
                                        placeholder:
                                          "输入推广渠道ID，若有多个请换行",
                                      },
                                      on: { input: _vm.plansInput },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.ruleData.rule_range === "QUICKAPP"
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  padding: "20px",
                                  "text-align": "center",
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "300px" },
                                    attrs: {
                                      prop: "quick_app_type",
                                      label: "快应用",
                                      rules: {
                                        required: true,
                                        message: "请选择快应用类型",
                                        trigger: "change",
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        on: {
                                          change: _vm.handleSelectQuickAppType,
                                        },
                                        model: {
                                          value: _vm.ruleData.quick_app_type,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleData,
                                              "quick_app_type",
                                              $$v
                                            )
                                          },
                                          expression: "ruleData.quick_app_type",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: { label: "常看", value: 1 },
                                        }),
                                        _c("el-option", {
                                          attrs: { label: "自有", value: 2 },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: "阅文陌香",
                                            value: 3,
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: "点众-松鼠阅读",
                                            value: 4,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      prop: "plansArr",
                                      label: "推广ID",
                                      rules: {
                                        required: true,
                                        message: "请输入推广渠道Id",
                                        trigger: "change",
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          filterable: "",
                                          multiple: "",
                                          remote: "",
                                          clearable: "",
                                          "reserve-keyword": "",
                                          placeholder:
                                            "选择快应用，输入推广链接名称",
                                          "remote-method": _vm.remoteMethod,
                                        },
                                        model: {
                                          value: _vm.ruleData.plansArr,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleData,
                                              "plansArr",
                                              $$v
                                            )
                                          },
                                          expression: "ruleData.plansArr",
                                        },
                                      },
                                      _vm._l(
                                        _vm.promotionIdArr,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.urlId,
                                            attrs: {
                                              label: item.urlName,
                                              value: item.urlId,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.ruleData.rule_range === "GAME"
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  padding: "20px",
                                  "text-align": "left",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "flex-direction": "row",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { width: "300px" },
                                        attrs: {
                                          label: "游戏平台",
                                          rules: {
                                            required: true,
                                            message: "请选择游戏平台",
                                            trigger: "change",
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            model: {
                                              value: _vm.ruleData.vnovel_type,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.ruleData,
                                                  "vnovel_type",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "ruleData.vnovel_type",
                                            },
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: {
                                                label: "默认平台",
                                                value: "G1001",
                                              },
                                            }),
                                            _c("el-option", {
                                              attrs: {
                                                label: "一包多发",
                                                value: "G1002",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { width: "300px" },
                                        attrs: {
                                          label: "小游戏",
                                          rules: {
                                            required: true,
                                            message: "请选择小游戏",
                                            trigger: "change",
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: { filterable: "" },
                                            model: {
                                              value: _vm.ruleData.wx_appid,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.ruleData,
                                                  "wx_appid",
                                                  $$v
                                                )
                                              },
                                              expression: "ruleData.wx_appid",
                                            },
                                          },
                                          _vm._l(
                                            _vm.gameList,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.dictLabel,
                                                  value: item.dictValue,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { width: "300px" },
                                        attrs: {
                                          rules: {
                                            required: true,
                                            message: "请输入数据源ID",
                                            trigger: "blur",
                                          },
                                          label: "数据源ID",
                                          prop: "data_id",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入数据源ID",
                                          },
                                          on: { input: _vm.inputChangeId },
                                          model: {
                                            value: _vm.ruleData.data_id,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleData,
                                                "data_id",
                                                $$v
                                              )
                                            },
                                            expression: "ruleData.data_id",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { width: "300px" },
                                        attrs: {
                                          rules: {
                                            required: true,
                                            message: "请输入APPID",
                                            trigger: "blur",
                                          },
                                          label: "APPID",
                                          prop: "data_appid",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder:
                                              "创建数据源公众号/小程序APPID",
                                          },
                                          on: { input: _vm.inputChangeAppId },
                                          model: {
                                            value: _vm.ruleData.data_appid,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.ruleData,
                                                "data_appid",
                                                $$v
                                              )
                                            },
                                            expression: "ruleData.data_appid",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.ruleData.vnovel_type == "G1002"
                                  ? _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop: "plans",
                                          rules: {
                                            required: true,
                                            message: "请输入小游戏APPID",
                                            trigger: "change",
                                          },
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "600px" },
                                          attrs: {
                                            value: _vm.ruleData.plans,
                                            type: "textarea",
                                            autosize: {
                                              minRows: 8,
                                              maxRows: 8,
                                            },
                                            placeholder:
                                              "请输入小游戏APPID，若有多个请换行",
                                          },
                                          on: { input: _vm.plansInput },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop: "plans",
                                          rules: {
                                            required: true,
                                            message: "请输入推广渠道ID",
                                            trigger: "change",
                                          },
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "600px" },
                                          attrs: {
                                            value: _vm.ruleData.plans,
                                            type: "textarea",
                                            autosize: {
                                              minRows: 8,
                                              maxRows: 8,
                                            },
                                            placeholder:
                                              "请输入推广渠道ID，若有多个请换行",
                                          },
                                          on: { input: _vm.plansInput },
                                        }),
                                      ],
                                      1
                                    ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.ruleData.rule_type.includes(2)
                ? _c(
                    "el-collapse-item",
                    { attrs: { title: "企微加粉回传配置", name: 2 } },
                    [
                      _c(
                        "box",
                        { attrs: { name: "回传条件设置" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "box-qw",
                              staticStyle: { display: "flex" },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "回传方式",
                                    prop: "return_type_qw",
                                    rules: [{ required: true }],
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "300px" },
                                      on: { change: _vm.changeReturnTypeQW },
                                      model: {
                                        value: _vm.ruleData.return_type_qw,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleData,
                                            "return_type_qw",
                                            $$v
                                          )
                                        },
                                        expression: "ruleData.return_type_qw",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "全部回传", value: 0 },
                                      }),
                                      _c("el-option", {
                                        attrs: {
                                          label: "百分比回传",
                                          value: 1,
                                        },
                                      }),
                                      _c("el-option", {
                                        attrs: {
                                          label: "循环比例回传",
                                          value: 2,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.ruleData.return_type_qw === 1
                                ? _c("el-input-number", {
                                    staticStyle: {
                                      width: "150px",
                                      "margin-left": "10px",
                                    },
                                    attrs: {
                                      min: 0,
                                      max: 100,
                                      precision: 0,
                                      placeholder: "0-100",
                                    },
                                    model: {
                                      value: _vm.ruleData.return_rate_qw,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleData,
                                          "return_rate_qw",
                                          $$v
                                        )
                                      },
                                      expression: "ruleData.return_rate_qw",
                                    },
                                  })
                                : _vm._e(),
                              _vm.ruleData.return_type_qw === 1
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-top": "6px",
                                        padding: "0 5px",
                                      },
                                    },
                                    [_vm._v("%")]
                                  )
                                : _vm._e(),
                              _vm.ruleData.return_type_qw === 2
                                ? _c(
                                    "el-form-item",
                                    {
                                      staticClass: "customize_cycle_proportion",
                                      staticStyle: {
                                        "margin-left": "10px",
                                        width: "100px",
                                      },
                                      attrs: {
                                        prop: "customize_cycle_proportion_start_qw",
                                        rules: [
                                          {
                                            required:
                                              _vm.ruleData.return_type_qw === 2,
                                            message: "填入传几个用户!",
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _vm.ruleData.return_type_qw === 2
                                        ? _c("el-input", {
                                            attrs: {
                                              placeholder: "填入传几个用户",
                                            },
                                            model: {
                                              value:
                                                _vm.ruleData
                                                  .customize_cycle_proportion_start_qw,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.ruleData,
                                                  "customize_cycle_proportion_start_qw",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "ruleData.customize_cycle_proportion_start_qw",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.ruleData.return_type_qw === 2
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-top": "6px",
                                        padding: "0 10px",
                                      },
                                    },
                                    [_vm._v(":")]
                                  )
                                : _vm._e(),
                              _vm.ruleData.return_type_qw === 2
                                ? _c(
                                    "el-form-item",
                                    {
                                      staticClass: "customize_cycle_proportion",
                                      staticStyle: { width: "100px" },
                                      attrs: {
                                        prop: "customize_cycle_proportion_end_qw",
                                        rules: [
                                          {
                                            required:
                                              _vm.ruleData.return_type_qw === 2,
                                            message: "填入卡几个用户!",
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _vm.ruleData.return_type_qw === 2
                                        ? _c("el-input", {
                                            attrs: {
                                              placeholder: "填入卡几个用户",
                                            },
                                            model: {
                                              value:
                                                _vm.ruleData
                                                  .customize_cycle_proportion_end_qw,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.ruleData,
                                                  "customize_cycle_proportion_end_qw",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "ruleData.customize_cycle_proportion_end_qw",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.ruleData.return_type_qw === 2
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "循环规则",
                                    prop: "cycle_proportion",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.ruleData.cycle_rule_qw,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleData,
                                            "cycle_rule_qw",
                                            $$v
                                          )
                                        },
                                        expression: "ruleData.cycle_rule_qw",
                                      },
                                    },
                                    [
                                      _c("el-radio", { attrs: { label: 1 } }, [
                                        _vm._v("按用户循环"),
                                      ]),
                                      _c("el-radio", { attrs: { label: 2 } }, [
                                        _vm._v("每日重置"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "box",
                        { attrs: { name: "关联内容设置" } },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "row",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { width: "400px" },
                                  attrs: {
                                    rules: {
                                      required: true,
                                      message: "请输入数据源ID",
                                      trigger: "blur",
                                    },
                                    label: "数据源ID",
                                    prop: "data_id_qw",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "输入数据源ID" },
                                    on: { input: _vm.inputChangeIdQw },
                                    model: {
                                      value: _vm.ruleData.data_id_qw,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleData,
                                          "data_id_qw",
                                          $$v
                                        )
                                      },
                                      expression: "ruleData.data_id_qw",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { width: "400px" },
                                  attrs: {
                                    rules: {
                                      required: true,
                                      message: "创建数据源公众号/小程序APPID",
                                      trigger: "blur",
                                    },
                                    label: "APPID",
                                    prop: "data_appid_qw",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      placeholder:
                                        "创建数据源公众号/小程序APPID",
                                    },
                                    on: { input: _vm.inputChangeAppIdQw },
                                    model: {
                                      value: _vm.ruleData.data_appid_qw,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleData,
                                          "data_appid_qw",
                                          $$v
                                        )
                                      },
                                      expression: "ruleData.data_appid_qw",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "customize_cycle_proportion",
                              staticStyle: { "margin-left": "20px" },
                              attrs: { prop: "wx_appid_qw" },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "600px" },
                                attrs: {
                                  type: "textarea",
                                  autosize: { minRows: 8, maxRows: 8 },
                                  placeholder:
                                    "企微绑定公众号/小程序APPID、或广告ID，支持多选输入，回车换行",
                                },
                                model: {
                                  value: _vm.ruleData.wx_appid_qw,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.ruleData, "wx_appid_qw", $$v)
                                  },
                                  expression: "ruleData.wx_appid_qw",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { "label-width": "0px" } },
            [
              _c(
                "el-button",
                {
                  staticClass: "mt-20 mb-20",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm()
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      this.$route.params.pk_unique ? "确认修改" : "立即创建"
                    )
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }