var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        title: "广告组编辑",
        "append-to-body": true,
        width: "800px",
        top: "20vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            rules: _vm.rules,
            "label-position": "right",
            model: _vm.form,
            "label-width": "150px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "广告组内计划数上限", prop: "groupPlanMax" } },
            [
              _c("el-input-number", {
                attrs: {
                  label: "广告组内计划数上限",
                  min: 1,
                  max: 500,
                  controls: false,
                },
                model: {
                  value: _vm.form.groupPlanMax,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "groupPlanMax", $$v)
                  },
                  expression: "form.groupPlanMax",
                },
              }),
              _c("span", { staticClass: "input-tips" }, [
                _vm._v("可填写1～500"),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "广告组预算", prop: "budgetMode" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.budgetMode,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "budgetMode", $$v)
                    },
                    expression: "form.budgetMode",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "BUDGET_MODE_INFINITE" } }, [
                    _vm._v("不限"),
                  ]),
                  _c("el-radio", { attrs: { label: "BUDGET_MODE_DAY" } }, [
                    _vm._v("指定预算"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.form.budgetMode === "BUDGET_MODE_DAY"
            ? _c(
                "el-form-item",
                { attrs: { label: "日预算", prop: "budget" } },
                [
                  _c("el-input-number", {
                    attrs: {
                      label: "日预算",
                      min: 300,
                      precision: 2,
                      controls: false,
                    },
                    model: {
                      value: _vm.form.budget,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "budget", $$v)
                      },
                      expression: "form.budget",
                    },
                  }),
                  _c("span", { staticClass: "input-tips" }, [
                    _vm._v("日预算不少于300.00元"),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "广告组默认状态", prop: "groupOperation" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.groupOperation,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "groupOperation", $$v)
                    },
                    expression: "form.groupOperation",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "enable" } }, [
                    _vm._v("开启"),
                  ]),
                  _c("el-radio", { attrs: { label: "disable" } }, [
                    _vm._v("暂停"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "广告计划默认状态", prop: "planOperation" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.planOperation,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "planOperation", $$v)
                    },
                    expression: "form.planOperation",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "enable" } }, [
                    _vm._v("开启"),
                  ]),
                  _c("el-radio", { attrs: { label: "disable" } }, [
                    _vm._v("暂停"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "广告组名称", prop: "groupNameTemplate" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: {
                  type: "text",
                  placeholder: "请输入广告组名称",
                  maxlength: "100",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.form.groupNameTemplate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "groupNameTemplate", $$v)
                  },
                  expression: "form.groupNameTemplate",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "tip" }, [
            _vm._v("通配符： "),
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.onClickSpecialChar("日期")
                  },
                },
              },
              [_vm._v("+日期")]
            ),
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.onClickSpecialChar("产品名")
                  },
                },
              },
              [_vm._v("+产品名")]
            ),
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.onClickSpecialChar("投手名称")
                  },
                },
              },
              [_vm._v("+投手名称")]
            ),
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.onClickSpecialChar("标号")
                  },
                },
              },
              [_vm._v("+标号")]
            ),
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.onClickSpecialChar("素材名")
                  },
                },
              },
              [_vm._v("+素材名")]
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "btn", on: { click: _vm.closeModal } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: {
                type: "primary",
                loading: _vm.isLoading,
                disabled: _vm.isLoading,
              },
              on: {
                click: function ($event) {
                  return _vm.clickConfirm("form")
                },
              },
            },
            [_vm._v("确 定 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }