var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.isEdit ? "编 辑" : "新 增",
        width: "45%",
        visible: _vm.visible,
        "before-close": _vm.close,
        "label-position": "right",
        "modal-append-to-body": false,
        "close-on-press-escape": false,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ent",
          attrs: { model: _vm.ent, rules: _vm.rules, "label-width": "110px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "名称", prop: "landingPageName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入名称" },
                model: {
                  value: _vm.ent.landingPageName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "landingPageName", $$v)
                  },
                  expression: "ent.landingPageName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "类型", prop: "landingPageType" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择类型" },
                  model: {
                    value: _vm.ent.landingPageType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ent, "landingPageType", $$v)
                    },
                    expression: "ent.landingPageType",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: "橙子建站", value: "橙子建站" },
                  }),
                  _c("el-option", {
                    attrs: { label: "第三方落地页", value: "第三方落地页" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "url", prop: "landingPageUrl" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.landingPageUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "landingPageUrl", $$v)
                  },
                  expression: "ent.landingPageUrl",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "逻辑删除", prop: "deleted" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.deleted,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "deleted", $$v)
                  },
                  expression: "ent.deleted",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "创建者", prop: "createBy" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.createBy,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "createBy", $$v)
                  },
                  expression: "ent.createBy",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "创建时间", prop: "createTime" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.createTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "createTime", $$v)
                  },
                  expression: "ent.createTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "更新者", prop: "updateBy" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.updateBy,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "updateBy", $$v)
                  },
                  expression: "ent.updateBy",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "更新时间", prop: "updateTime" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.updateTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "updateTime", $$v)
                  },
                  expression: "ent.updateTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "remark", $$v)
                  },
                  expression: "ent.remark",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "url_id", prop: "landingPageUrlId" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.landingPageUrlId,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "landingPageUrlId", $$v)
                  },
                  expression: "ent.landingPageUrlId",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { attrs: { round: "" }, on: { click: _vm.close } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", round: "" },
              on: { click: _vm.submit },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }