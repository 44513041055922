<script type="text/jsx">
export default {
  name: 'ConfirmBox',
  props: {
    text: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'confirm'
    },
    beforeConfirm: {
      type: Function,
      default () {
        return Promise.resolve(true)
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      show: false,
      icons: {
        'confirm': {
          icon: 'el-icon-warning',
          color: '#2842C8'
        },
        'danger': {
          icon: 'el-icon-warning',
          color: '#FD5655'
        },
        'warning': {
          icon: 'el-icon-warning',
          color: '#FF9000'
        }
      }
    }
  },
  methods: {
    async showConfirmBox () {
      if (this.disabled) {
        return
      }
      const checkOperate = await this.beforeConfirm()
      if (checkOperate) {
        const icon = this.icons[this.type].icon
        const color = this.icons[this.type].color
        const kEle = `<div class="confirm-box">
        <div>
          <i class="${icon}" style="color: ${color}"></i>
          <span>${this.text}</span>
        </div>
      <p>${this.content}</p>
      </div>`
        this.$confirm(`${kEle}`, {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          showClose: false,
          roundButton: true,
          closeOnClickModal: false,
          confirmButtonClass: 'button-confirm',
          cancelButtonClass: 'button-confirm',
          center: true
        }).then(() => {
          this.$emit('confirm')
        }).catch(() => {
        })
      }
    }
  },
  render () {
    const { showConfirmBox } = this
    return (
      <div onClick={showConfirmBox}>{this.$slots.default}</div>)
  }
}
</script>

<style lang="scss">
.confirm-box {
  text-align: center;
  margin-bottom: 20px;

  p {
    font-size: 14px;
    color: #a8acb4;
    margin: 15px 0 15px !important;

    span {
      font-size: 16px;
      font-weight: bold;
      margin-right: 10px;
      color: #31c37b;
    }
  }

  div {
    span {
      font-size: 18px;
      color: #1e1e1e;
    }

    i {
      font-size: 18px;
      font-weight: bold;
    }
  }
}

.el-message-box__btns .button-confirm {
  width: 60px;
}
</style>
