<!--
 * @Description: 微信服务商账户
 * @Date: 2021-12-01 09:15:29
 * @LastEditTime: 2022-04-01 11:31:21
-->

<template>
  <div>
    <!-- 列表查询 -->
    <el-form ref="search" :inline="true" :model="search" class="search-form" @submit.native.prevent>
      <el-form-item label="服务商" prop="searchName">
        <el-select v-model="search.searchName" style="width: 300px;">
          <el-option label="全部" :value="null"></el-option>
          <el-option v-for="(itm, idx) in adMpAmList" :key="idx" :label="`${itm.agencyName} ( ${itm.mobileId} )`" :value="`${itm.agencyName}+${itm.mobileId}`"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" round @click="getPage">查询</el-button>
        <el-button round @click="resetFormQuery('search')">重置</el-button>
      </el-form-item>
    </el-form>

    <!-- 表格 -->
    <TablePane :data-source="dataSource" @getPage="getPage"> </TablePane>

    <!-- 同步账号 -->
    <el-dialog title="同步投放账号" :visible.sync="sync_account_visible" width="1120px" :modal-append-to-body="false" destroy-on-close @open="open" @close="resetForm('ruleForm')">
      <el-form ref="ruleForm" :model="ruleForm" label-width="100px" class="demo-ruleForm justify-sb" :inline="true">
        <el-form-item label="服务商: " prop="spid" :rules="{ required: true, message: '请选择服务商', trigger: 'change' }">
          <el-select v-model="ruleForm.spid" style="width: 500px;">
            <el-option v-for="(itm, idx) in adMpAmList" :key="idx" :label="`${itm.agencyName} ( ${itm.mobileId} )`" :value="`${itm.spid}+${itm.id}`"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" round :disabled="ruleForm.spid === null" @click="getAdMpAmsAccountsPage">获取未同步账户</el-button>
        </el-form-item>
      </el-form>
      <el-divider> 账号选择</el-divider>
      <el-row :gutter="10">
        <el-col>
          <el-input v-model="ruleForm.ghid" placeholder="请输入账号名称/gh_id" style="width: 300px" size="small"><el-button slot="append" icon="el-icon-search" @click="getAdMpAmsAccountsPage"></el-button></el-input>
        </el-col>
        <el-col :span="12">
          <TablePane ref="multipleTable" class="mt-10" :data-source="dataSource_sync" @getPage="getAdMpAmsAccountsPage" />
        </el-col>
        <el-col :span="12">
          <el-table v-loading="table_right_loading" :data="dataSource_sync_right" style="width: 100%" class="list-table mt-10">
            <el-table-column label="账号名称" align="center" show-overflow-tooltip>
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column label="appid" align="center" show-overflow-tooltip>
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.appid }}</span>
              </template>
            </el-table-column>
            <el-table-column label="ghid" align="center" show-overflow-tooltip>
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.ghid }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination style="text-align:right" :current-page.sync="currentPage1" :page-size="10" layout="total, prev, pager, next" :total="dataSource_sync_right_all.length" @current-change="handleCurrentChange"> </el-pagination>
        </el-col>

        <!-- 账户配置 -->
        <el-col>
          <el-divider>账户配置</el-divider>
          <el-form ref="accountConfig" :inline="true" :model="accountConfig" class="demo-ruleForm justify-sb" @submit.native.prevent>
            <el-form-item label="项目" prop="projectId" :rules="{ required: true, message: '请选择项目', trigger: 'change' }">
              <el-select v-model="accountConfig.projectId" placeholder="请选择项目" @change="(value) => (selectProjectId = value)">
                <el-option v-for="item in projectList" :key="item.bsProjectId" :label="item.projectName" :value="item.bsProjectId" />
              </el-select>
            </el-form-item>

            <el-form-item label="产品" prop="productId" :rules="{ required: true, message: '请选择产品', trigger: 'change' }">
              <el-select v-model="accountConfig.productId" placeholder="请选择产品" @change="(value) => (selectProductId = value)">
                <el-option label="不限" :value="0"></el-option>
                <el-option v-for="(itm, idx) in productListed" :key="idx" :label="itm.productName" :value="itm.bsProductId"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="人员" prop="launch" :rules="{ required: true, message: '请选择人员', trigger: 'blue' }">
              <el-select v-model="accountConfig.launch" value-key="key" filterable clearable placeholder="请选择投放人员">
                <el-option v-for="(itm, idx) in userList" :key="idx" :label="itm.label" :value="itm.key"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('accountConfig')">同步</el-button>
        <el-button @click="resetForm('accountConfig')">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TablePane from '@/components/tablePane.vue' // adMpAmsSelect
import { adMpAmsPage, getAdMpAm, adMpAmsAccountsPage, adMpAmsAccountsAmsAsync } from '@/network/api/account/weChatAdvAccount.js'
import AccountBaidu from '@/mixin/account_baidu'
import { getUser } from '@/network/api/user'

export default {
  components: {
    TablePane
  },
  mixins: [AccountBaidu],
  data() {
    return {
      adMpAmList: [], // 服务商list
      userList: [], // 用户list
      adMpAmsOption: [], // 服务商list
      search: {
        searchName: null
      },
      sync_account_visible: false, // 同步账号
      editInfo_visible: false, // 编辑
      editInfo: false,
      batchInfo_visible: false, // 批量修改
      commandType: '0',
      syncSelectRow: [], // 选中的列
      table_right_loading: false,
      dataSource_sync_right: [],
      dataSource_sync_right_all: [],
      currentPage1: 1,
      accountConfig: {
        projectId: null,
        productId: 0,
        launch: null
      },
      //
      id: '',
      ruleForm: {
        spid: null,
        ghid: null,
        dataStatus: 0
      },
      dataSource: {
        data: [],
        cols: [
          {
            label: '服务商名称',
            prop: 'agencyName'
          },
          {
            label: '绑定微信',
            prop: 'wechatNickname'
          },
          {
            label: '手机编号',
            prop: 'mobileId'
          },
          {
            label: '扫码登录状态',
            // eslint-disable-next-line
            render: (recode) => (recode.active === 1 ? <el-tag>正常</el-tag> : <el-tag type="danger">错误</el-tag>)
          },
          {
            label: '绑定投放账户',
            prop: 'accountNum',
            handleRow: (index, record) => {
              this.$router.push({
                name: 'weChatLaunchAccountType',
                params: {
                  id: record.spid
                }
              })
            }
            // eslint-disable-next-line
          },
          {
            label: '登录有效截止时间',
            prop: 'expires'
          }
        ], // 表格的列数据
        isIndex: true, // 列表序号
        loading: false, // loading
        isOperation: true,
        isSelection: false,
        pageData: {
          total: 0 // 总条数
        },
        handleSelectionChange: () => {},
        operation: {
          // 表格有操作列时设置
          label: '操作', // 列名
          width: 80, // 根据实际情况给宽度
          data: [
            {
              tooltip: '同步投放账号',
              icon: 'el-icon-upload2',
              handleRow: (index, record) => {
                this.ruleForm.spid = `${record.spid}+${record.id}`
                this.sync_account_visible = true
              }
            }
          ]
        }
      },
      dataSource_sync: {
        data: [],
        cols: [
          {
            label: '账号名称',
            with: 100,
            prop: 'name'
          },
          {
            label: 'appid',
            prop: 'appid'
          },
          {
            label: 'ghid',
            prop: 'ghid'
          }
        ], // 表格的列数据
        isIndex: false, // 列表序号
        loading: false, // loading
        isOperation: false,
        isSelection: true,
        pageData: {
          total: 0, // 总条数
          layout: 'total, prev, pager, next'
        },
        selectAll: (selection) => {
          if (selection.length) {
            this.table_right_loading = true
            this.dataSource_sync_right_all.push(...selection)
            let list = []
            this.dataSource_sync_right_all.map((item) => {
              if (list.length === 0 || !list.some((s) => s.id === item.id)) {
                list.push(item)
              }
            })
            this.dataSource_sync_right_all = list
            this.dataSource_sync_right = [...list].splice(0, 10)
            this.table_right_loading = false
          } else {
            this.dataSource_sync_right_all = []
            this.dataSource_sync_right = []
          }
        },
        select: (selection, row) => {
          if (selection.length > 0) {
            this.table_right_loading = true
            this.dataSource_sync_right_all.push(...selection)
            let list = []
            this.dataSource_sync_right_all.map((item) => {
              if (list.length === 0 || !list.some((s) => s.id === item.id)) {
                list.push(item)
              }
            })
            this.dataSource_sync_right_all = list
            this.dataSource_sync_right = [...list].splice(0, 10)
            this.table_right_loading = false
          }

          if (!selection.some((s) => s.id === row.id)) {
            this.dataSource_sync_right_all = this.dataSource_sync_right_all.filter((f) => f.id !== row.id)
            this.dataSource_sync_right = [...this.dataSource_sync_right_all].splice(0, 10)
            this.table_right_loading = false
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  mounted() {
    this.getPage()
    this._getUserList()
    this._getAdMpAm()
  },
  methods: {
    open() {
      this.accountConfig = {
        ...this.accountConfig,
        launch: this.userInfo.userid
      }
    },

    handleCurrentChange(current) {
      let list = [...this.dataSource_sync_right_all]
      this.dataSource_sync_right = list.splice((current - 1) * 10, current * 10)
    },

    handleDelete(index, row) {
      this.$refs['multipleTable'].getRowData([])
      this.dataSource_sync_right = this.dataSource_sync_right.filter((f) => f.id !== row.id)
      this.dataSource_sync_right_all = this.dataSource_sync_right_all.filter((f) => f.id !== row.id)
      this.$refs['multipleTable'].getRowData(this.dataSource_sync_right_all)
    },

    // 获取成员列表
    _getUserList() {
      getUser().then((res) => {
        if (res.code === 200) {
          this.userList = res.data
        }
      })
    },
    // 获取服务商
    _getAdMpAm() {
      getAdMpAm().then((res) => {
        if (res.code === 200) {
          this.adMpAmList = res.data
        }
      })
    },

    submitForm(formName) {
      if (this.dataSource_sync_right.length === 0) {
        return this.$message.error('请选择同步账户!')
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let { projectId, productId, launch } = this.accountConfig
          let params = {
            ids: this.dataSource_sync_right.map((item) => item.id),
            projectId,
            projectName: this.projectList.filter((item) => item.bsProjectId === projectId)[0].projectName,
            productId: productId !== 0 ? productId : 0,
            productName: productId !== 0 ? this.productListed.filter((item) => item.bsProductId === productId)[0].productName : '不限',
            launchId: launch,
            launchName: this.userList.length ? this.userList.filter((item) => item.key === launch)[0].label : null
          }
          adMpAmsAccountsAmsAsync(params).then((res) => {
            if (res.code === 200) {
              this.sync_account_visible = false
              this.dataSource_sync_right = []
              this.dataSource_sync.data = []
              this.$message.success('同步完成!')
            }
          })
        }
      })
    },

    /**
     * @description: 获取同步数据
     *      */

    getAdMpAmsAccountsPage(params = { pageNum: 1, pageSize: 10 }) {
      this.dataSource_sync.loading = true
      this.$refs['multipleTable'].getRowData([])
      let search = { ...this.ruleForm, spid: this.ruleForm.spid.split('+')[0] }
      if (search.ghid && search.ghid.indexOf('gh_') === -1) {
        search.name = search.ghid
        search.ghid = null
      } else {
        search.name = null
      }
      adMpAmsAccountsPage({ ...search, pageNum: params.pageNum || 1, pageSize: params.pageSize || 10 }).then((res) => {
        const { records, total } = res.data
        this.dataSource_sync.data = records
        this.dataSource_sync.pageData.total = total
        this.dataSource_sync.loading = false

        let rowData = []
        records.map((item) => {
          this.dataSource_sync_right_all.map((item2) => {
            if (item.id === item2.id) {
              rowData.push(item)
            }
          })
        })
        this.$refs['multipleTable'].getRowData(rowData)
      })
    },

    /**
     * @description: 重置数据
     * @param {*} formName ref
     */
    resetForm(formName) {
      this.batchInfo_visible = false
      this.editInfo_visible = false
      this.sync_account_visible = false
      this.dataSource_sync_right = []
      this.dataSource_sync_right_all = []
      this.dataSource_sync.data = []
      this.dataSource_sync.pageData.total = 0
      this.ruleForm.spid = null
      this.ruleForm.ghid = null
      this.$refs[formName].resetFields()
    },

    resetFormQuery(formName) {
      this.batchInfo_visible = false
      this.editInfo_visible = false
      this.$refs[formName].resetFields()
      this.getPage()
    },

    /**
     * @description: 获取列表
     * @param {*} params
     */
    getPage(params = { pageNum: 1, pageSize: 10 }) {
      this.dataSource.loading = true
      let search = {
        searchName: this.search.searchName ? this.search.searchName.split('+')[0] : null,
        mobileId: this.search.searchName ? this.search.searchName.split('+')[1] : null
      }
      adMpAmsPage({ ...search, ...params }).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          this.dataSource.data = records
          this.dataSource.pageData.total = total
          this.dataSource.loading = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.account-info {
  display: flex;
}
</style>
