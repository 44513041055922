export const searchForm = {
  baiduCreativeFendId: null,
  creativeFeedId: null,
  adgroupFeedId: null,
  materialstyle: null,
  creativeFeedName: null,
  pause: null,
  status: null,
  material: null,
  refusereason: null,
  playnum: null,
  ideaType: null,
  showMt: null,
  addtime: null,
  approvemsgnew: null,
  audittimemodel: null,
  deleted: null,
  createBy: null,
  createTime: null,
  updateBy: null,
  updateTime: null,
  remark: null,
  selectDate: null,
  selectDateValue: null
}

export class AdvManagementBaiduCreativeFendEnt {
  baiduCreativeFendId
  creativeFeedId
  adgroupFeedId
  materialstyle
  creativeFeedName
  pause
  status
  material
  refusereason
  playnum
  ideaType
  showMt
  addtime
  approvemsgnew
  audittimemodel
  deleted
  createBy
  createTime
  updateBy
  updateTime
  remark
}

export const formRules = {
  baiduCreativeFendId: [{ required: true, message: '请填写', trigger: 'change' }],
  creativeFeedId: [{ required: true, message: '请填写创意ID	', trigger: 'change' }],
  adgroupFeedId: [{ required: true, message: '请填写所属推广单元ID', trigger: 'change' }],
  materialstyle: [{ required: true, message: '请填写创意样式ID', trigger: 'change' }],
  creativeFeedName: [{ required: true, message: '请填写创意名称	', trigger: 'change' }],
  pause: [{ required: true, message: '请填写是否暂停推广	', trigger: 'change' }],
  status: [{ required: true, message: '请填写创意状态 ', trigger: 'change' }],
  material: [{ required: true, message: '请填写物料内容 ', trigger: 'change' }],
  refusereason: [{ required: true, message: '请填写审核未通过的原因（审核拒绝理由） ', trigger: 'change' }],
  playnum: [{ required: true, message: '请填写视频创意的视频播放量 ', trigger: 'change' }],
  ideaType: [{ required: true, message: '请填写创意类型 ', trigger: 'change' }],
  showMt: [{ required: true, message: '请填写程序化创意展示样式', trigger: 'change' }],
  addtime: [{ required: true, message: '请填写创意的创建时间', trigger: 'change' }],
  approvemsgnew: [{ required: true, message: '请填写JSON格式化后的审核未通过的原因', trigger: 'change' }],
  audittimemodel: [{ required: true, message: '请填写预估审核时间	', trigger: 'change' }],
  deleted: [{ required: true, message: '请填写逻辑删除', trigger: 'change' }],
  createBy: [{ required: true, message: '请填写创建者', trigger: 'change' }],
  createTime: [{ required: true, message: '请填写创建时间', trigger: 'change' }],
  updateBy: [{ required: true, message: '请填写更新者', trigger: 'change' }],
  updateTime: [{ required: true, message: '请填写更新时间', trigger: 'change' }],
  remark: [{ required: true, message: '请填写备注', trigger: 'change' }]
}
