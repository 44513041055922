var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "search",
          staticClass: "search-form search-form-4",
          attrs: { inline: true, model: _vm.search },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品名称", prop: "toolProductName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请输入产品名称" },
                        model: {
                          value: _vm.search.toolProductName,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "toolProductName", $$v)
                          },
                          expression: "search.toolProductName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "平台", prop: "toolProductPlatform" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.search.toolProductPlatform,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "toolProductPlatform", $$v)
                            },
                            expression: "search.toolProductPlatform",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: 1 },
                          }),
                          _vm._l(_vm.platformList, function (item) {
                            return _c("el-option", {
                              key: item.dictValue,
                              attrs: {
                                label: item.dictLabel,
                                value: item.dictValue,
                              },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型", prop: "type" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.search.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "type", $$v)
                            },
                            expression: "search.type",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: 1 },
                          }),
                          _vm._l(_vm.typeList, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "投测时间", prop: "launchTime" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "datetimerange",
                          "prange-separator": "至",
                          "start-placeholder": "开始时间",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "end-placeholder": "结束时间",
                        },
                        model: {
                          value: _vm.search.launchTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "launchTime", $$v)
                          },
                          expression: "search.launchTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "素材时间", prop: "assetTime" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "datetimerange",
                          "prange-separator": "至",
                          "start-placeholder": "开始时间",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "end-placeholder": "结束时间",
                        },
                        model: {
                          value: _vm.search.assetTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "assetTime", $$v)
                          },
                          expression: "search.assetTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "测试状态",
                        prop: "toolProductTestStatus",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.search.toolProductTestStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "toolProductTestStatus", $$v)
                            },
                            expression: "search.toolProductTestStatus",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: 1 },
                          }),
                          _vm._l(_vm.testStatusList, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "测试结果", prop: "toolProductResult" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.search.toolProductResult,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "toolProductResult", $$v)
                            },
                            expression: "search.toolProductResult",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: 1 },
                          }),
                          _vm._l(_vm.toolProductResultList, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否起量", prop: "isInception" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.search.isInception,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "isInception", $$v)
                            },
                            expression: "search.isInception",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: 1 },
                          }),
                          _c("el-option", {
                            attrs: { label: "已起量", value: 2 },
                          }),
                          _c("el-option", {
                            attrs: { label: "未起量", value: 3 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "投放部门", prop: "launchDeptId" } },
                    [
                      _c("el-cascader", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          options: _vm.deptList,
                          props: _vm.cascaderProp,
                          filterable: "",
                          placeholder: "请选择投放部门",
                        },
                        model: {
                          value: _vm.search.launchDeptId,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "launchDeptId", $$v)
                          },
                          expression: "search.launchDeptId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "素材部门", prop: "assetDeptId" } },
                    [
                      _c("el-cascader", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          options: _vm.deptList,
                          props: _vm.cascaderProp,
                          filterable: "",
                          placeholder: "请选择素材部门",
                        },
                        model: {
                          value: _vm.search.assetDeptId,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "assetDeptId", $$v)
                          },
                          expression: "search.assetDeptId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", round: "" },
                          on: { click: _vm.onSubmit },
                        },
                        [_vm._v("查 询")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", round: "" },
                          on: {
                            click: function ($event) {
                              return _vm.resetForm("ruleForm")
                            },
                          },
                        },
                        [_vm._v("重 置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.list.loading,
                  expression: "list.loading",
                },
              ],
              staticClass: "list-table",
              class: { "no-data": !_vm.list.data || !_vm.list.data.length },
              staticStyle: { width: "100%" },
              attrs: { data: _vm.list.data, stripe: "" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  width: "50",
                  align: "center",
                  fixed: "left",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "toolProductName",
                  label: "名称",
                  align: "center",
                  fixed: "left",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "平台", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.platformList.find(
                                (item) =>
                                  item.dictValue === row.toolProductPlatform
                              ).dictLabel
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "类型", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm._f("filterToolType")(row.type)) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "连载状态", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              row.serializeStatus === "1" ? "连载" : "完结"
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "num", label: "数量", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { label: "投放人员", align: "left", width: "200px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.testToolProductLaunchPerson.length
                          ? _c(
                              "el-tooltip",
                              { attrs: { placement: "top-start" } },
                              [
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  _vm._l(
                                    row.testToolProductLaunchPerson,
                                    function (asset, index) {
                                      return _c("div", { key: index }, [
                                        _vm._v(_vm._s(asset)),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                                _c(
                                  "div",
                                  _vm._l(
                                    row.testToolProductLaunchPerson.slice(0, 3),
                                    function (asset, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "tool-user-label",
                                        },
                                        [_vm._v(_vm._s(asset))]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            )
                          : _c("span", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "素材人员", align: "left", width: "200px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.assetToolProductLaunchPerson.length
                          ? _c(
                              "el-tooltip",
                              { attrs: { placement: "top-start" } },
                              [
                                _c(
                                  "div",
                                  {
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  _vm._l(
                                    row.assetToolProductLaunchPerson,
                                    function (asset, index) {
                                      return _c("div", { key: index }, [
                                        _vm._v(_vm._s(asset)),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                                _c(
                                  "div",
                                  _vm._l(
                                    row.assetToolProductLaunchPerson.slice(
                                      0,
                                      3
                                    ),
                                    function (asset, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index,
                                          staticClass: "tool-user-label",
                                        },
                                        [_vm._v(_vm._s(asset))]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            )
                          : _c("span", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "testStartAndEndTime",
                  label: "测试时段",
                  align: "center",
                  width: "200",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "测试状态", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("filterTestStatus")(row.testStatus)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "isInception",
                  label: "是否起量",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                return _vm.handleAmountDetail(row.toolProductId)
                              },
                            },
                          },
                          [
                            row.isInception
                              ? _c("el-tag", { attrs: { type: "success" } }, [
                                  _vm._v("已起量"),
                                ])
                              : _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v("未起量"),
                                ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "consume", label: "消耗", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "payPowderPrice",
                  label: "支付粉价",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "d1", label: "D1", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { prop: "d2", label: "D2", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { prop: "d3", label: "D3", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { label: "详情", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleGoDetail(
                                  row.toolProductId,
                                  row
                                )
                              },
                            },
                          },
                          [_vm._v(" 查看 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "page" },
            [
              _c("paginations", {
                attrs: {
                  total: _vm.list.total,
                  page: _vm.list.pageNum,
                  "page-sizes": [5, 10, 15, 20],
                  limit: _vm.list.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.list, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.list, "pageSize", $event)
                  },
                  pagination: _vm.getPage,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("test-details-dialog", {
        ref: "testDetails",
        on: { confirm: _vm.getPage },
      }),
      _c("amount-details-dialog", { ref: "amountDetails" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }