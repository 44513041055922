<!--
  落地页模版
-->
<template>
  <div>
    <Search ref="search" />
    <el-row type="flex" justify="space-between" class="operate-box">
      <el-button type="primary" icon="el-icon-plus" round @click="showAdd">新增落地页模板</el-button>
    </el-row>
    <div class="table-container">
      <el-table v-loading="loading" stripe class="list-table" :data="data">
        <c-data-empty slot="empty"></c-data-empty>
        <el-table-column type="index" label="序号" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="pageName" label="落地页名称" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="thumbnail" label="缩略图" align="left">
          <template slot-scope="{ row }">
            <i v-if="!row.thumbnail">-</i>
            <el-image
              v-else :src="row.thumbnail"
              style="height: 100px"
              :preview-src-list="[row.thumbnail]"
            />
          </template>
        </el-table-column>
        <el-table-column prop="createBy" label="创建者" align="left" />
        <el-table-column label="创建时间" align="left" prop="createdAt">
          <template slot-scope="{ row }">
            <span>{{ moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="修改时间" align="left" prop="updatedAt">
          <template slot-scope="{ row }">
            <span v-if="row.updatedAt">{{ moment(row.updatedAt).format('YYYY-MM-DD HH:mm:ss') }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="left" width="220">
          <template slot-scope="{ row }">
            <el-button type="text" size="small" @click="showCopyCreate(row)">
              <el-tooltip content="复制新建" placement="top">
                <i class="el-icon-copy-document" style="font-size: 16px"></i>
              </el-tooltip>
            </el-button>
            <el-button type="text" size="small" @click="showEdit(row)">
              <el-tooltip content="编辑" placement="top">
                <i class="el-icon-edit" style="font-size: 16px"></i>
              </el-tooltip>
            </el-button>
            <el-button type="text" size="small" @click="push(row)">
              <el-tooltip content="发布" placement="top">
                <i class="el-icon-upload2" style="font-size: 16px"></i>
              </el-tooltip>
            </el-button>
            <el-button type="text" size="small" @click="showPreview(row.id)">
              <el-tooltip content="预览" placement="top">
                <i class="el-icon-view" style="font-size: 16px "></i>
              </el-tooltip>
            </el-button>
            <el-button :id="'btnLink' + row.id" type="text" size="small" :data-clipboard-text="url" @click="copyLink(row.id)">
              <el-tooltip content="复制链接" placement="top">
                <i class="el-icon-link" style="font-size: 16px"></i>
              </el-tooltip>
            </el-button>
            <el-button type="text" size="small" @click="deleteRow(row)">
              <el-tooltip content="删除" placement="top">
                <i class="el-icon-delete" style="font-size: 16px;color:red"></i>
              </el-tooltip>
            </el-button>
            <el-button v-if="row.isRedirect" type="text" size="small" @click="tamperRow(row)">
              <el-tooltip content="篡改配置" placement="top">
                <i class="el-icon-edit-outline" style="font-size: 16px;color:red"></i>
              </el-tooltip>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog title="落地页发布" :visible.sync="publishVisible" width="600px" :modal-append-to-body="false" :before-close="close" :show-close="false">
      <span>
        <el-form ref="submitParams" :model="submitParams" :rules="rules" label-width="100px">
          <el-form-item label="管理账号" prop="housekeeperId">
            <el-select v-model="submitParams.housekeeperId" style="width:400px" @change="AdvENTListchange">
              <el-option v-for="(item, index) in accountHugeHousekeeperENTs" :key="index" :label="item.accountName" :value="item.housekeeperId"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="投放账户" prop="advId">
            <el-select v-model="submitParams.advId" multiple filterable style="width:400px">
              <el-option v-for="(item, index) in accountHugeHousekeeperENTsId" :key="index" :label="item.accountName" :value="item.advId"></el-option>
            </el-select>
          </el-form-item>
          <!-- v-if="!isApplet" -->
          <el-form-item label="公 众 号" prop="name">
            <el-select v-model="submitParams.name" filterable remote reserve-keyword placeholder="请输入公众号全称" :remote-method="remoteMethod" :loading="publicListLoading" style="width:400px" @change="getGhid">
              <el-option v-for="(item, index) in publicList" :key="index" :label="item.name" :value="`${item.ghid}+${item.name}+${item.wechat}+${item.headImg}`"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="主体域名" prop="domain">
            <el-select v-model="submitParams.domain" filterable remote reserve-keyword placeholder="请选择域名" :remote-method="remoteMethod" :loading="publicListLoading" style="width:400px" @change="getGhid">
              <el-option v-for="(item, index) in domainPageList" :key="index" :label="item.subject" :value="`${item.scheme}://${item.domain}`"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item>
            <el-button type="primary" @click="submitForm">确定</el-button>
            <el-button @click="resetForm">重置</el-button>
          </el-form-item> -->
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 认</el-button>
      </span>
    </el-dialog>
    <c-pagination :page-size.sync="pageInfo.pageSize" :page-no.sync="pageInfo.pageNum" :total="pageInfo.total" :get-data-fun="loadData"></c-pagination>
    <OptionDialog ref="dialog" />
  </div>
</template>

<script>
import { PageCommonComponent } from '@/common/mixin'
import {
  Page,
  Delete,
  getWechatAccount,
  buildThirdLandingPage,
  getPageDomain,
  getUrl
} from '@/network/api/assetManagement/assetManagementHugeLandingPageTemp'
import { debounce } from '@/utils/ruoyi'
import Search from './Search'
import OptionDialog from './OptionDialog'
import { getProjectAndProduct } from '@/network/api/assetManagement/assetManagementHugeThirdLandingPage'
// import { jsonFormat } from '@/utils/ruoyi'
import Clipboard from 'clipboard'

export default {
  name: 'AssetManagementHugeLandingPageTemp',
  components: { Search, OptionDialog },
  mixins: [PageCommonComponent],
  provide: function () {
    return {
      loadData: this.loadData,
      showEdit: this.showEdit
    }
  },
  data() {
    return {
      rules: {
        housekeeperId: [{ required: true, message: '请选择管理账号', trigger: 'change' }],
        advId: [{ required: true, message: '请选投放账户', trigger: 'change' }],
        name: [{ required: true, message: '请选择公众号', trigger: 'change' }],
        domain: [{ required: true, message: '请选择公众号', trigger: 'change' }]
      },
      option: {
        img: '../../../../assets/image/empty-idea.png'
      },
      submitFormLoading: false,
      loading: false,
      data: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      publishVisible: false,
      publicListLoading: false,
      publicList: [], // 公众号
      promoteList: [], // 投放账户
      accountList: [], // 管理账号
      accountHugeHousekeeperENTs: [],
      accountHugeHousekeeperENTsId: [],
      accountHugeAdvENTLists: [],
      isApplet: false, // 判断是跳转小程序还是关注公众号
      submitParams: {
        id: [], // 模板Id
        housekeeperId: [], // 管理账号名称
        advId: '', // 投放账户
        name: '', // 公众号名称 / 小程序
        ghid: '', // 公众号名称 / 小程序
        headImg: '', // 公众号名称 / 小程序
        wechat: '' // 公众号名称 / 小程序
      },
      domainPageList: [],
      url: ''
    }
  },
  mounted() {
    this.loadData()
    this.getPageDomainFun()
  },
  methods: {
    async showPreview(id) {
      const { code, data } = await getUrl(id)
      if (code === 200) {
        let url = data.pageUrl.substr(0, 1) === '/' ? `${data.domain}${data.pageUrl}` : `${data.domain}/${data.pageUrl}`
        window.open(url, 'authorize', 'width:700,height=500,left=0,top=0,menubar=no,toolbar=no')
      }
    },
    async copyLink(id) {
      const { code, data } = await getUrl(id)
      if (code === 200) {
        this.url = data.pageUrl.substr(0, 1) === '/' ? `${data.domain}${data.pageUrl}` : `${data.domain}/${data.pageUrl}`
        this.$nextTick(() => {
          let clipboard = new Clipboard('#btnLink' + id)
          clipboard.on('success', (e) => {
            this.$message.success('复制成功')
            // 释放内存
            clipboard.destroy()
          })
          clipboard.on('error', (e) => {
            console.log(e)
            // 不支持复制
            this.$message.error('不支持复制')
            // 释放内存
            clipboard.destroy()
          })
        })
      }
    },
    /**
     * @description:获取域名
     */
    async getPageDomainFun() {
      const { code, data } = await getPageDomain({ platform: 'oceanengine' })
      if (code === 200) {
        this.domainPageList = data.records
        let item = data.records[0]
        this.submitParams.domain = `${item.scheme}://${item.domain}`
      }
    },

    AdvENTListchange(value) {
      this.submitParams = {
        ...this.submitParams,
        advId: null
      }
      this.accountHugeHousekeeperENTsId = this.accountHugeAdvENTLists[value]
    },

    /**
     * @description: 点击发布
     */
    push(row) {
      const { id } = row
      this.publishVisible = true
      this.submitParams.id = id
      this.getProjectAndProductFun()
    },

    loadData(isFirst = false) {
      const searchForm = this.$refs.search.getSearchForm()
      this.doLoadData(isFirst, searchForm)
    },
    doLoadData(firstPage = false, searchForm) {
      firstPage && (this.pageInfo.pageNum = 1)
      let req = { ...searchForm, ...this.pageInfo, pageType: 'oceanengine' }
      this.loading = true
      Page(req).then((res) => {
        this.data = res.data.records
        this.pageInfo.total = res.data.total
        this.loading = false
      })
    },
    deleteRow(row) {
      this.$confirm('确定删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        Delete(row.id).then((res) => {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.loadData()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },

    /**
     * @description: 获取管理账号/投放账户
     */
    getProjectAndProductFun() {
      this.isSync = true
      getProjectAndProduct().then(({ code, data }) => {
        if (code === 200) {
          this.projectAndProduct = data
          this.accountHugeHousekeeperENTs = data.map((item) => item.accountHugeHousekeeperENT)
          let accountHugeAdvENTLists = {}
          data.map((item, index) => {
            let key = item.accountHugeHousekeeperENT.housekeeperId
            if (index === 0) {
              this.accountHugeHousekeeperENTsId = item.accountHugeAdvENTList
            }
            accountHugeAdvENTLists[key] = item.accountHugeAdvENTList
          })
          this.accountHugeAdvENTLists = accountHugeAdvENTLists
        }
      })
    },

    /**
     * @description: 获取所有公众号
     */
    remoteMethod(query) {
      if (!query) return
      this.publicListLoading = true
      debounce(
        getWechatAccount(query).then(({ code, data }) => {
          if (code === 200) {
            this.publicListLoading = false
            this.publicList = data
          } else {
            this.publicList = []
          }
        })
      )
    },

    /**
     * @description:获取选中的公众号信息
     */
    getGhid(value) {
      let [ghid, name, wechat, headImg] = value.split('+')
      this.submitParams.ghid = ghid
      this.submitParams.name = name
      this.submitParams.wechat = wechat
      this.submitParams.headImg = headImg
    },

    showEdit(row) {
      this.$refs.dialog.show(true, { ...row })
      this.$router.push({
        name: 'editLdy',
        params: { id: row.id }
      })
    },

    showCopyCreate(row) {
      this.$router.push({
        name: 'copyCreateLdy',
        params: { copyId: row.id }
      })
    },

    tamperRow(row) {
      this.$router.push({
        name: 'editTamperLdy',
        params: { type: 'tamper', id: row.id }
      })
    },
    /**
     * @description: 发布
     */
    submitForm() {
      this.$refs['submitParams'].validate((valid) => {
        if (valid) {
          if (!this.submitFormLoading) {
            this.submitFormLoading = true
            setTimeout(() => {
              this.submit()
              this.publishVisible = false
            }, 100)
          }
        }
      })
    },
    async submit() {
      let data = { ...this.submitParams }
      data.advId = this.submitParams.advId + ''
      const { code } = await buildThirdLandingPage({ ...data, platform: 'oceanengine' })
      if (code === 200) {
        this.submitFormLoading = false
        this.$message.success('发布成功!')
        this.resetForm()
      }
    },

    close() {
      this.publishVisible = false
      this.resetForm()
    },

    resetForm() {
      this.$refs['submitParams'].resetFields()
    },

    showAdd() {
      this.$router.push({ path: '/create-ldy' })
    }
  }
}
</script>

<style scoped></style>
