<!--
 * @Description: 工作区详情
 * @Date: 2021-10-18 16:12:24
 * @LastEditTime: 2023-03-10 17:38:27
-->

<template>
  <section class="workbench">
    <div @click="setSelectedIndex(-1)">
      <el-tooltip v-if="finalTopWidgets.component" placement="right-start">
        <template slot="content">
          <i class="el-icon-delete-solid" @click="deleteItem(-1)" />
        </template>
        <div :class="{ active: selectWidgetsIndex === -1, widget: true }">
          <component :is="finalTopWidgets.component" :detail="finalTopWidgets" />
        </div>
      </el-tooltip>
      <div v-else class="top-component">
        <i class="el-icon-s-help"></i>
        <h3>顶部组件</h3>
        <p>选择一个顶部组件到此处</p>
      </div>
    </div>
    <draggable v-model="finalWidgets" :animation="500" @end="end">
      <transition-group>
        <div v-for="(ele, index) in finalWidgets" :key="ele.widgetTypeV2" :class="{ widget: true }" @click="setSelectedIndex(index)">
          <div v-if="ele.widgetTypeV2 === 'floatbutton'">
            <el-tooltip placement="right-start">
              <template slot="content">
                <i class="el-icon-delete-solid" @click="deleteItem(index)" />
              </template>
              <m-floatButton :detail="ele" :index="index" :select-widgets-index="selectWidgetsIndex" />
            </el-tooltip>
          </div>
          <div v-else :class="{ active: selectWidgetsIndex === index, widget: true }">
            <el-tooltip placement="right-start">
              <template slot="content">
                <i class="el-icon-delete-solid" @click="deleteItem(index)" />
              </template>
              <div>
                <component :is="ele.component" :detail="ele" />
              </div>
            </el-tooltip>
          </div>
        </div>
      </transition-group>
    </draggable>
    <div v-if="finalWidgets.some((s) => s.widgetTypeV2 === 'floatbutton')" style="height: 62px"></div>
  </section>
</template>

<script>
import draggable from 'vuedraggable'
import topImg from './workbench_component/topImg.vue'
import topSlider from './workbench_component/topSlider.vue'
import topVideo from './workbench_component/topVideo.vue'
import video from './workbench_component/video.vue'
import Img from './workbench_component/img.vue'
import Slider from './workbench_component/slider.vue'
import Text from './workbench_component/text.vue'
import copyGh from './workbench_component/copyGh.vue'
import EnterpriseWxAuto from './workbench_component/enterpriseWxAuto.vue'
import FloatButton from './workbench_component/floatButton.vue'
import ShelfNew from './workbench_component/shelfnew/index.vue'

export default {
  components: {
    draggable,
    'm-topImg': topImg,
    'm-topSlider': topSlider,
    'm-topVideo': topVideo,
    'm-img': Img,
    'm-video': video,
    'm-slider': Slider,
    'm-text': Text,
    'm-copyGh': copyGh,
    'm-enterpriseWxAuto': EnterpriseWxAuto,
    'm-floatButton': FloatButton,
    'm-shelfNew': ShelfNew
  },
  props: {
    // 选中顶部的索引
    finalTopWidgets: {
      type: Object,
      default: () => {}
    },
    // 选中的索引
    finalWidgets: {
      type: Array,
      default: () => []
    },
    // 选中的索引
    selectWidgetsIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      component: 'm-image'
    }
  },

  methods: {
    /**
     * @description: 设置当前选中的组件
     * @param {number} index 当前选中的索引
     */
    setSelectedIndex(index) {
      this.$emit('setSelectedIndex', +index)
    },

    /**
     * @description: 删除一个组件
     * @param {number} index 当前选中的索引
     */
    deleteItem(index) {
      if (index === -1) {
        this.$emit('setFinalTopWidgets')
        this.$emit('cancelTopWidgetsDisable')
      } else {
        this.finalWidgets.splice(index, 1)
        // 让最后一个组件选中
        let i = this.finalWidgets.length === 0 ? -1 : this.finalWidgets.length - 1
        this.$emit('setSelectedIndex', i)
        this.$emit('setFinalWidgetsCount', this.finalWidgets.length)
      }
    },

    end() {
      console.log(this.finalWidgets)
      this.$emit('updateFinalWidgets', this.finalWidgets)
    }
  }
}
</script>

<style lang="scss" scoped>
.widget {
  border: 1px solid transparent;
}
.active {
  border: 1px solid #409eff;
}
.top-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 250px;
  background: #75b9fc;
  color: #fff;
  h3 {
    margin: 5px;
    padding: 0;
  }
  i {
    font-size: 70px;
  }
  p {
    margin: 0;
    padding: 0;
  }
}
</style>
