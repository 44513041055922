var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form-item",
        { attrs: { label: "广告样式：", rules: { required: true } } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.ruleFormData.adStyle,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleFormData, "adStyle", $$v)
                },
                expression: "ruleFormData.adStyle",
              },
            },
            [
              _c("el-radio", { attrs: { label: "routine" } }, [
                _vm._v("常规广告"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: {
            label: "素材类型：",
            prop: "linkName",
            rules: { required: true },
          },
        },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.ruleFormData.assetType,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleFormData, "assetType", $$v)
                },
                expression: "ruleFormData.assetType",
              },
            },
            [
              _c("el-radio", { attrs: { label: 1 } }, [_vm._v("常规单图")]),
              _c("el-radio", { attrs: { label: 3 } }, [_vm._v("常规三图")]),
              _c("el-radio", { attrs: { label: 4 } }, [_vm._v("常规四图")]),
              _c("el-radio", { attrs: { label: 6 } }, [_vm._v("常规六图")]),
              _c("el-radio", { attrs: { label: 71 } }, [
                _vm._v("竖版视频 720像素x1280像素"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: {
            label: "外层素材跳转：",
            "label-width": "120px",
            prop: "pageType",
            rules: { required: true },
          },
        },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.ruleFormData.pageType,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleFormData, "pageType", $$v)
                },
                expression: "ruleFormData.pageType",
              },
            },
            [_c("el-radio", { attrs: { label: 4 } }, [_vm._v("原生推广页")])],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: {
            label: "文字链文案：",
            prop: "linkName",
            rules: { required: true },
          },
        },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.ruleFormData.linkName,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleFormData, "linkName", $$v)
                },
                expression: "ruleFormData.linkName",
              },
            },
            [
              _c("el-radio", { attrs: { label: "了解更多" } }, [
                _vm._v("了解更多"),
              ]),
              _c("el-radio", { attrs: { label: "查看详情" } }, [
                _vm._v("查看详情"),
              ]),
              _c("el-radio", { attrs: { label: "关注公众号" } }, [
                _vm._v("关注公众号"),
              ]),
              _c("el-radio", { attrs: { label: "阅读小说" } }, [
                _vm._v("阅读小说"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: {
            label: "文字链跳转：",
            prop: "linkPageType",
            rules: { required: true },
          },
        },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.ruleFormData.linkPageType,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleFormData, "linkPageType", $$v)
                },
                expression: "ruleFormData.linkPageType",
              },
            },
            [
              _c("el-radio", { attrs: { label: 4 } }, [_vm._v("原生推广页")]),
              _c("el-radio", { attrs: { label: 6 } }, [_vm._v("公众号详情页")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.ruleFormData.assetType == 1
        ? _c(
            "el-form-item",
            { attrs: { label: "创意复用：" } },
            [
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.ruleForm.creativeReuse,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "creativeReuse", $$v)
                    },
                    expression: "ruleForm.creativeReuse",
                  },
                },
                [_vm._v("订阅号")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        { attrs: { label: "数据展示：", prop: "snsDynamicShowSwitch" } },
        [
          _c("el-switch", {
            on: { change: _vm.handelSnsDynamicShowSwitch },
            model: {
              value: _vm.ruleFormData.snsDynamicShowSwitch,
              callback: function ($$v) {
                _vm.$set(_vm.ruleFormData, "snsDynamicShowSwitch", $$v)
              },
              expression: "ruleFormData.snsDynamicShowSwitch",
            },
          }),
        ],
        1
      ),
      _vm.ruleFormData.snsDynamicShowSwitch
        ? _c(
            "el-form-item",
            { attrs: { label: "数据类型：", prop: "snsDynamicShowType" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.snsDynamicShowType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "snsDynamicShowType", $$v)
                    },
                    expression: "ruleForm.snsDynamicShowType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 6 } }, [
                    _vm._v("转化目标量"),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        {
          attrs: {
            label: "创意名称：",
            prop: "tname",
            rules: { required: true, maxlength: 60, message: "请输入创意名称" },
          },
        },
        [
          _c("el-input", {
            staticStyle: { width: "400px" },
            attrs: {
              rows: "4",
              type: "textarea",
              placeholder: "请输入创意名称",
              maxlength: "60",
              "show-word-limit": "",
            },
            model: {
              value: _vm.ruleFormData.tname,
              callback: function ($$v) {
                _vm.$set(_vm.ruleFormData, "tname", $$v)
              },
              expression: "ruleFormData.tname",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }