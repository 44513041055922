<!--
 * @Description: 巨量广告批量程序化
-->
<template>
  <div>
    <!-- 列表查询 -->
    <el-form ref="search" :inline="true" :model="search" class="search-form search-form-4">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="任务名称" prop="taskName">
            <el-input v-model="search.taskName" placeholder="请输入任务名称" style="width: 100%"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="创建人" prop="createBy">
            <el-input v-model="search.createBy" placeholder="请输入创建人" style="width: 100%"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="提交状态" prop="status">
            <el-select v-model="search.status" style="width: 100%">
              <el-option label="不限" value=""></el-option>
              <el-option label="配置中" value="2"></el-option>
              <el-option label="待提交" value="3"></el-option>
              <el-option label="提交中" value="4"></el-option>
              <el-option label="提交完成" value="5"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="创建时间" prop="createTime">
            <el-date-picker v-model="search.createTime" style="width: 100%" type="daterange" prange-separator="至"
              start-placeholder="开始时间" value-format="yyyy-MM-dd" end-placeholder="结束时间"> </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item>
            <el-button type="primary" round @click="onSubmit">查 询</el-button>
            <el-button type="primary" round @click="resetForm('ruleForm')">重 置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- 表格 -->

    <div style="text-align:left " class="mb-15">
      <router-link :to="{ name: 'createHugeAmountAdBatch' }" tag="span">
        <el-button type="primary" icon="el-icon-plus" round> 新建任务 </el-button>
      </router-link>
    </div>

    <div>
      <el-table v-loading="list.loading" :data="list.data" stripe class="list-table"
        :class="{ 'no-data': !list.data || !list.data.length }" style="width: 100%;">
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="id" label="ID" width="50"></el-table-column>
        <el-table-column prop="taskName" label="任务名称">
          <template slot-scope="{row}">
            <el-tooltip class="item" effect="dark" placement="top">
              <template slot="content">
                <p class="task-name-Flow">{{ row.taskName }}</p>
              </template>
              <p class="task-name-Flow">{{ row.taskName }}</p>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column prop="createBy" label="创建人"></el-table-column>
        <el-table-column prop="projectName" label="项目"></el-table-column>
        <el-table-column prop="" label="产品">
          <template slot-scope="{row}">
            <p>{{ row.productName || '不限' }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="" label="投放账户" width="320">
          <template slot-scope="{row}">
            <el-tooltip class="item" effect="dark" placement="top">
              <template slot="content">
                <p class="task-name-Flow">{{ row.advertisers.length && row.advertisers.join(',') || '-' }}</p>
              </template>
              <div class="task-account-box">
                <p class="task-name-Flow">{{ row.advertisers.length && row.advertisers.join(',') || '-' }}</p>
                <span class="task-account-total" v-if="row.advertisers && row.advertisers.length > 1">共{{
                    row.advertisers &&
                    row.advertisers.length
                }}个账户</span>
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="" label="推广目标">
          <template slot-scope="{row}">
            <p>{{ promotedObjectTypeList[row.landingType] }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="" label="提交广告数">
          <template slot-scope="{row}">
            <p>{{ row.planCount }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="" label="状态">
          <template slot-scope="{row}">
            <div class="status">
              <p style="font-size: 11px; font-weight: 400"
                :style="{ 'color': row.status == 5 ? (row.planFailCount == 0 ? 'green' : 'red') : '' }">
                {{ statusList[row.status] }}</p>
              <div v-if="row.status == 5" class="status-num">
                <p style="font-size: 11px">
                  成功：<span style="color:green">{{ row.planSuccessCount }}</span>
                </p>
                <el-tooltip :content="row.failMsg" placement="top">
                <p style="font-size: 11px">
                    失败：<span style="color:red">{{ row.planFailCount }}</span>
                </p>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作">
          <template slot-scope="{row}">
            <template v-if="row.status == 2 || row.status == 3">
              <el-button type="text" @click="handleGoEdit(row)">
                <el-tooltip content="继续" placement="top">
                  <i class="el-icon-edit" style="font-size: 16px"></i>
                </el-tooltip>
              </el-button>
              <el-button type="text" class="red" @click="deleteTask(row.id)">
                <el-tooltip content="删除" placement="top">
                  <i class="el-icon-delete" style="font-size: 16px"></i>
                </el-tooltip>
              </el-button>
            </template>
            <!-- v-else-if="row.status == 5" -->
            <el-button v-else-if="row.status == 5" type="text" @click="detailTask(row)">
              <el-tooltip content="详情" placement="top">
                <i class="el-icon-document" style="font-size: 16px"></i>
              </el-tooltip>
            </el-button>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>

      <div class="page">
        <paginations :total="list.total" :page.sync="list.pageNum" :page-sizes="[5, 10, 15, 20]"
          :limit.sync="list.pageSize" @pagination="getPage" />
      </div>
    </div>

    <el-dialog title="任务详情" :visible.sync="descVisible" width="1200px" :modal-append-to-body="false" destroy-on-close
      @close="() => { showErrorFlag = false }">
      <span>
        <el-descriptions :column="2">
          <el-descriptions-item :span="24" label="任务名称">{{ dataSource_data_info.taskName }}</el-descriptions-item>
          <el-descriptions-item label="项目">{{ dataSource_data_info.projectName }}</el-descriptions-item>
          <el-descriptions-item label="产品">{{ dataSource_data_info.productName || '不限' }}</el-descriptions-item>
          <el-descriptions-item label="提交广告数">{{ dataSource_data_info.planCount }}</el-descriptions-item>
          <el-descriptions-item label="创建人">{{ dataSource_data_info.createBy }}</el-descriptions-item>
          <el-descriptions-item label="创建时间">{{ dataSource_data_info.createTime }}</el-descriptions-item>
        </el-descriptions>
        <el-row style="margin-top:20px">
          <el-col :span="12">
            <el-checkbox v-model="showErrorFlag" @change="showError"> 仅查看失败</el-checkbox>
          </el-col>
          <el-col :span="12" style="text-align:right">
            <el-button type="primary" size="mini" :loading="republishLoading" @click="handleRePublish">重新提交</el-button>
          </el-col>
        </el-row>
        <TablePane :data-source="dataSource_desc" @getPage="getDescPage"> </TablePane>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  // getById
  rePublishTask,
  getListDetail,
  deletePlans,
  Page
} from '@/network/api/api-huge-batch'

import { getDateStartEnd } from '@/utils/ruoyi'
import { mapGetters } from 'vuex'

import TablePane from '@/components/tablePane'

export default {
  components: {
    TablePane
  },
  data() {
    return {
      search: {
        taskName: null,
        createTime: '',
        createBy: null,
        status: ''
      },
      list: {
        loading: true,
        data: [],
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      promotedObjectTypeList: {
        PRODUCTTYPE_WECHAT_SHOP: '推广商品',
        LINK: '收集销售线索',
        PRODUCTTYPE_WECHAT: '推广公众号'
      },
      statusList: {
        '1': '不限',
        '2': '配置中',
        '3': '待提交',
        '4': '提交中',
        '5': '提交完成'
      },
      pageSize: 10,
      descVisible: false,
      showErrorFlag: false,
      republishLoading: false,
      descData: [],
      dataSource_data: [],
      dataSource_data_info: {},
      dataSource_desc: {
        data: [], // 表格数据
        cols: [
          {
            label: '账户ID',
            prop: 'advertiserId'
          },
          {
            label: '广告组名称',
            prop: 'adGroupName'
          },
          {
            label: '广告组ID',
            prop: 'campaignId'
          },
          {
            label: '计划名称',
            prop: 'planName'
          },
          {
            label: '计划ID',
            prop: 'adId'
          },
          // {
          //   label: '创意ID',
          //   prop: 'creativeId'
          // },
          {
            label: '状态',
            // eslint-disable-next-line
            render: (record) => {
              return (
                <div>
                  {record.publishStatus === 'SUCCESS' ? (<el-tag type='success'>成功</el-tag>
                  ) : record.publishStatus === 'FAIL' ? (
                    <el-tooltip class='item' effect='dark' placement='top'>
                      <template slot='content'>
                        <span style='max-width: 200px;'>{record.publishMessage || '无'}</span>
                      </template>
                      <el-tag type='danger'>失败</el-tag>
                    </el-tooltip>
                  ) : ''}
                </div>
              )
            }
          }
        ], // 表格的列数据
        isIndex: false, // 列表序号
        loading: true, // loading
        isOperation: false,
        pageData: {
          pageNum: 1,
          total: 0, // 总条数
          layout: 'total, prev, pager, next'
        },
        handleSelectionChange() { },
        operation: {}
      }
    }
  },

  computed: {
    ...mapGetters(['userInfo'])
  },

  mounted() {
    this.getPage()
  },

  methods: {
    /**
       * @description: 获取列表
       * @param {*} params
       */
    getPage() {
      this.list.loading = true
      const [createTimeStart, createTimeEnd] = getDateStartEnd(this.search.createTime)
      let search = {
        ...this.search,
        createTimeEnd,
        createTimeStart,
        pageSize: this.list.pageSize,
        pageNum: this.list.pageNum
      }
      delete search.createTime
      Page(search).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          records.forEach(v => {
            v.createBy = v.createBy || '-'
            v.projectName = v.projectName || '-'
            v.createBy = v.createBy || '-'
          })
          this.list.data = records
          this.list.total = total
          this.list.loading = false
        }
      }).catch(e => {
        this.list.loading = false
      })
    },

    /**
   * @description: 重置
   */
    resetForm() {
      this.$refs['search'].resetFields()
      this.getPage()
    },

    // 点击继续
    handleGoEdit(row) {
      this.$router.push({
        name: 'editHugeAmountAdBatch',
        params: { id: row.id }
      })
    },

    /**
       * @description: 删除任务
       */
    deleteTask(taskId) {
      this.$confirm('确定要取消该任务吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { code } = await deletePlans({ baseId: taskId })
        if (code === 200) {
          this.getPage()
          this.$message.success('删除成功!')
        }
      }).catch(() => {
      })
    },

    /**
       * @description: 查询
       */
    onSubmit() {
      this.getPage()
    },

    // -------- 任务详情 ------------
    async getDescPage({ taskId = this.dataSource_data_info.id, pageNum = 1, pageSize = 10, isSuccess = !this.showErrorFlag }) {
      let params = {
        baseId: taskId,
        justFail: isSuccess ? 0 : 1,
        pageNum: 1,
        pageSize: pageSize
      }
      this.dataSource_desc.loading = true
      const { code, data } = await getListDetail(params)
      if (code === 200) {
        this.dataSource_desc.data = data.records
        this.dataSource_desc.pageData.total = data.total
        this.dataSource_desc.pageData.pageNum = pageNum
        this.dataSource_desc.loading = false
      } else {
        this.dataSource_desc.loading = false
      }
    },
    /**
   * @description:查看失败数据
   */
    showError(flag) {
      this.getDescPage({ pageNum: 1, pageSize: 10, isSuccess: !flag })
    },

    // 重新提交
    handleRePublish() {
      this.dataSource_desc.loading = true
      this.republishLoading = true
      let id = this.dataSource_data_info.id
      rePublishTask({ id: id }).then(res => {
        if (res.code === 200) {
          this.$message.success('提交中,请稍后查看!')
          this.dataSource_desc.loading = false
          this.descVisible = false
          this.republishLoading = false
          this.getPage()
        }
      }).finally(() => {
        this.dataSource_desc.loading = false
        this.republishLoading = false
      })
    },

    async detailTask(record) {
      this.descVisible = true
      this.dataSource_data_info = record
      this.getDescPage({ taskId: record.taskId })
    }
    // -------- 任务详情 ------------
  }
}
</script>

<style>
.is-disabled {
  color: #c0c4cc !important;
}
</style>

<style lang="scss" scoped>
.task-name-Flow {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.task-account-box {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  .task-account-total {
    white-space: nowrap;
    position: relative;
    top: 24px;
  }
}

p {
  padding: 0;
  margin: 0;
}

.status {
  padding-right: 20px;

  p {
    padding: 0;
    margin: 0;
  }

  .status-num {
    display: flex;
    justify-content: space-between;

    p {
      font-size: 13px;
      color: rgb(153, 153, 153);
    }
  }
}

.red {
  color: #ff0000;
}
</style>
