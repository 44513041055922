<!--
 * @Description: 工作区 -> 复制公众号组件
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2022-05-11 11:17:13
-->

<template>
  <section>
    <div
      :style="{
        paddingTop: `${detail.paddingTop}px`,
        paddingBottom: `${detail.paddingBottom}px`
      }"
    >
      <!-- <div v-if="detail.type === 'label'" class="label">
        <div class="avatar">
          <el-avatar :size="54" src="https://empty" @error="errorHandler">
            <img src="https://cw-oss.mokamrp.com/copywriting/outerside/images/IGmnH7n2zi28fDAHqDFFCo2stlGMe9pJIuEhFmIt.jpeg" />
          </el-avatar>
          <p>公众号名称</p>
        </div>
        <div
          class="button"
          :style="{
            background: detail.btnBgColorTheme,
            color: detail.btnFontColor,
            border: `1px solid ${detail.btnBorderColorTheme}`,
            fontWeight: detail.btnFontType === '0' ? 'normal' : 'bold'
          }"
        >
          {{ detail.btnTitle }}
        </div>
      </div> -->
      <div class="button">
        <div
          class="button-btn"
          :style="{
            background: detail.btnBgColorTheme,
            color: detail.fontColor,
            border: `1px solid ${detail.btnBorderColorTheme}`,
            fontWeight: detail.btnFontType === '0' ? 'normal' : 'bold'
          }"
        >
          <p v-html="detail.btnTitle"></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  .avatar {
    display: flex;
    align-items: center;
  }
  p {
    margin-left: 10px;
    font-size: 18px;
  }
  .button {
    padding: 5px 14px;
    border-radius: 5px;
    font-size: 20px;
  }
}
.button {
  padding-left: 10px;
  padding-right: 10px;
  .button-btn {
    border-radius: 20px;
    line-height: 40px;
    text-align: center;
    font-size: 20px;
  }
}
</style>
