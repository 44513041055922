
<template>
  <!--显示列弹框-->
  <el-dialog
    :visible.sync="show"
    :append-to-body="true"
    width="600px"
    top="20vh"
    @close="onCloseDialog"
  >
    <!--自定义 title-->
    <span slot="title" class="dialog-title">{{ dialogTitle }}</span>
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="素材组名称" prop="materialGroupName">
        <el-input v-model.trim="form.materialGroupName" clearable show-word-limit maxlength="30" placeholder="请输入素材组名称" @blur="blurInput"></el-input>
      </el-form-item>
      <el-form-item label="专辑" prop="albumId">
        <el-select v-model="form.albumId" filterable clearable placeholder="请选择专辑" style="width: 100%;">
          <el-option v-for="(itm, idx) in albumList" :key="idx" :label="itm.albumName" :value="itm.albumId"></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">取 消</el-button>
      <el-button class="btn" type="primary" :loading="isLoading" :disabled="isLoading" @click="clickConfirm('form')">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  getAlbumAllList,
  getMarterialGroupDetail,
  addMarterialGroup,
  editMarterialGroup,
  findMarterialGroupList
} from '@/network/api/api-material'
import { getMeRelationProject } from '@/network/api/basicSetting/project/basicSettingProject'
export default {
  name: 'EditMaterialGroupDialog',
  components: {
  },
  filters: {},
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      show: false, // 是否显示弹框
      dialogTitle: '',
      albumList: [],
      isLoading: false, // 是否正在加载
      lastmaterialGroupName: '',
      list: [],
      id: '',
      form: {
        materialGroupName: '',
        albumId: ''
      }, // 表单数据
      rules: {
        materialGroupName: [{ required: true, message: '请输入素材组名称', trigger: 'blur' }],
        albumId: [{ required: true, message: '请选择专辑', trigger: 'blur' }]
      } // 表单校验规则
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  watch: {},
  created () {
  },
  mounted () {
  },
  methods: {
    /* --------------------------- Actions --------------------------- */
    // 显示弹框
    showModalalbum (id, albumId) {
      this.show = true
      this.dialogTitle = id ? '编辑素材组' : '新增素材组'
      this._getProjectTree(albumId)
      id && this._getMarterialGroupDetail(id)
      this.id = id
    },
    // 显示弹框
    showModal (id) {
      this.show = true
      this.dialogTitle = id ? '编辑素材组' : '新增素材组'
      this._getProject()
      id && this._getMarterialGroupDetail(id)
      this.id = id
    },

    // 关闭弹框
    closeModal () {
      this.show = false
    },
    // 监听 Dialog 弹框关闭
    onCloseDialog () {
      // 重置表单
      this.$refs['form'].resetFields()

      // 清除数据
      this.form = {
        materialGroupName: '',
        albumId: ''
      }
      this.lastmaterialGroupName = ''
      this.albumList = []
      this.list = []
      this.id = ''
    },

    // 点击确定按钮
    clickConfirm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this._validate()) {
            this.id ? this._editMarterialGroup() : this._addMarterialGroup()
          }
        } else {
          this.$WarningMessage('请将表单填写完整')
        }
      })
    },

    // 校验数据
    _validate () {
      if (this.form.materialGroupName === this.lastmaterialGroupName) {
        return true
      } else {
        if (this.list.length === 0) {
          return true
        } else {
          this.$ErrorMessage('素材组名称已存在，请重新输入')
          return false
        }
      }
    },
    // 输入框失去焦点事件
    blurInput () {
      let params = {
        materialGroupName: this.form.materialGroupName
      }
      findMarterialGroupList(params).then(res => {
        if (res.code === 200) {
          this.list = res.data || []
        }
      })
    },
    /* --------------------------- Private --------------------------- */
    // 获取项目列表(个人可见)
    _getProject () {
      getMeRelationProject().then(res => {
        if (res.code === 200) {
          this.projectList = res.data
          let ids = res.data.map(item => {
            return item.bsProjectId
          })
          this._getAlbumAllList(ids)
        }
      })
    },
    _getProjectTree (albumId) {
      getMeRelationProject().then(res => {
        if (res.code === 200) {
          this.projectList = res.data
          let ids = res.data.map(item => {
            return item.bsProjectId
          })
          this._getAlbumAllListTree(ids, albumId)
        }
      })
    },
    // 获取专辑列表
    _getAlbumAllListTree (ids, albumId) {
      let params = {
        type: this.type,
        projectIds: ids
      }
      getAlbumAllList(params).then(res => {
        this.albumList = res.data
        this.form.albumId = albumId
      })
    },
    // 获取专辑列表
    _getAlbumAllList (ids) {
      let params = {
        type: this.type,
        projectIds: ids
      }
      getAlbumAllList(params).then(res => {
        this.albumList = res.data
      })
    },
    // 新增素材组
    _addMarterialGroup () {
      this.isLoading = true
      let params = {}
      params.albumId = this.form.albumId
      params.materialGroupName = this.form.materialGroupName
      params.type = this.type
      params.adminId = this.userInfo.userid
      params.nickname = this.userInfo.username
      addMarterialGroup(params).then(res => {
        this.$emit('edit')
        this.closeModal()
      }).catch(err => {
        this.$ErrorMessage(err.data || '新增素材组失败')
      }).finally(() => {
        this.isLoading = false
      })
    },

    // 获取素材组详情
    _getMarterialGroupDetail (id) {
      this.isLoading = true
      getMarterialGroupDetail({ id: id }).then(res => {
        this.form.materialGroupName = res.data.materialGroupName
        this.lastmaterialGroupName = res.data.materialGroupName
        this.form.albumId = Number(res.data.albumId)
      }).catch(err => {
        this.$ErrorMessage(err.data || '获取素材组详情失败')
      }).finally(() => {
        this.isLoading = false
      })
    },

    // 编辑素材组
    _editMarterialGroup () {
      this.isLoading = true
      let params = {}
      params.albumId = this.form.albumId
      params.materialGroupName = this.form.materialGroupName
      params.type = this.type
      params.adminId = this.userInfo.userid
      params.nickname = this.userInfo.username
      editMarterialGroup(this.id, params).then(res => {
        // this.$SuccessMessage('编辑素材组成功')
        this.$emit('edit')
        this.closeModal()
      }).catch(err => {
        this.$ErrorMessage(err.data || '编辑素材组失败')
      }).finally(() => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
