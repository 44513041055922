<!--
 * @Description: Do not edit
 * @Date: 2021-10-11 13:56:59
 * @LastEditTime: 2022-05-20 17:03:16
 -->
<template>
  <div class="material-box">
    <!-- :visible="visible" -->
    <el-dialog
      custom-class="material-dialog"
      title="图片选择"
      :visible="visible"
      :width="`1400px`"
      top="100px"
      :append-to-body="true"
      :before-close="close"
      destroy-on-close
    >
      <!-- 内容开始 -->
      <el-row
        class="selected-img"
        :gutter="20"
      >
        <!-- 左边树形筛选框 -->
        <el-col :span="4">
          <el-col
            :span="3"
            class="tree-box"
          >
            <el-input
              v-model="filterText"
              placeholder="输入关键字进行过滤"
              size="small"
              style="width: 200px"
            > </el-input>
            <div class="tree">
              <el-tree
                ref="tree"
                v-loading="treeLoading"
                element-loading-spinner="el-icon-loading"
                :data="adAlbum"
                lazy
                :load="loadNode"
                :props="defaultProps"
                :filter-node-method="filterNode"
                @node-click="nodeClick"
              ></el-tree>
            </div>
          </el-col>
        </el-col>

        <!-- 右边内容去 -->
        <el-col :span="20">
          <!-- 右上搜索框 -->
          <el-row>
            <el-col>
              <el-form
                ref="imageQuery"
                :inline="true"
                :model="imageQuery"
                style="marginleft: 10px"
              >
                <el-form-item label="上传人: ">
                  <el-input
                    v-model="imageQuery.nickname"
                    placeholder="请输入上传人"
                    style="width: 150px"
                  ></el-input>
                </el-form-item>
                <el-form-item label="宽: ">
                  <el-input
                    v-model="imageQuery.width"
                    placeholder="请输入宽"
                    style="width: 120px"
                  ></el-input>
                </el-form-item>
                <el-form-item label="高: ">
                  <el-input
                    v-model="imageQuery.height"
                    placeholder="请输入高"
                    style="width: 120px"
                  ></el-input>
                </el-form-item>
                <el-form-item class="ml-20">
                  <el-button
                    type="primary"
                    @click="onSubmit"
                  >查询</el-button>
                  <el-button
                    type="primary"
                    @click="resetForm"
                  >重置</el-button>
                </el-form-item>
                <el-form-item
                  v-if="selectAll"
                  class="ml-20"
                >
                  <el-checkbox
                    v-model="selectedImgCheckbox"
                    :disabled="selectAllDisabled"
                    @change="selectAllFun"
                  >全选当前素材组下图片</el-checkbox>
                </el-form-item>
                <el-button @click="onClickAddMaterial(0)">新增素材</el-button>
              </el-form>
            </el-col>
          </el-row>

          <!-- 右下内容选择区 -->
          <el-row :gutter="20">
            <el-col
              v-loading="imgLoading"
              :span="18"
              element-loading-spinner="el-icon-loading"
              element-loading-text="图片获取中..."
            >
              <ul
                v-if="imgList.length"
                style="margin: 0"
                class="select-img"
              >
                <el-row>
                  <el-col
                    v-for="item in imgList"
                    :key="item.imageId"
                    :span="6"
                  >
                    <li
                      :class="{ active: selectedImgList.some((s) => s.imageId === item.imageId) }"
                      @click="selectImg(item)"
                    >
                      <div class="avatar-box">
                        <img
                          :src="item.url"
                          class="avatar"
                        />
                      </div>
                      <div
                        class="attachments"
                        :class="{ 'success-color': successColor(item) }"
                      >
                        <p
                          class="text-ellipsis"
                          :title="item.materialName"
                        >{{ item.materialName }}</p>
                        <div>
                          <p>
                            <span>{{ item.width }}px</span> * <span>{{ item.height }}px</span>
                          </p>
                          <p>{{ parseInt(item.size / 1024) }}kb</p>
                        </div>
                      </div>
                      <i class="el-icon-success"></i>
                    </li>
                  </el-col>
                </el-row>
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="imagListTotal"
                  style="text-align: right"
                  :current-page.sync="imageQuery.pageNum"
                  :page-size="8"
                  @current-change="currentChange"
                  @prev-click="currentChange"
                  @next-click="currentChange"
                >
                </el-pagination>
              </ul>
              <ul
                v-else
                style="margin: 0"
                class="select-img"
              >
                <el-empty description="该素材组暂无图片,请选择其他素材组"></el-empty>
              </ul>
            </el-col>
            <el-col :span="6">
              <el-row class="selected-box">
                <template v-if="isBatch">
                  <el-col
                    v-for="(item, index) in new Array(imgCountData)"
                    :key="index"
                    :span="name.indexOf('picture3Fixed') >= 0 ? 8 : 12"
                    class="mb-10"
                  >
                    <section :class="{ 'selected-img-item2': name.indexOf('picture3Fixed') >= 0, 'selected-img-item': name.indexOf('picture3Fixed') == -1 }">
                      <div>
                        <el-avatar
                          shape="square"
                          :size="name.indexOf('picture3Fixed') >= 0 ? 70 : 100"
                          class="avatar"
                          icon="el-icon-picture"
                          :src="selectedImgList[index] && selectedImgList[index].image"
                        ></el-avatar>
                        <div class="avatar-mask">
                          <p @click="handleImg(index, 'updata')">替换</p>
                          <p @click="handleImg(index, 'delete')">删除</p>
                        </div>
                      </div>
                    </section>
                  </el-col>
                </template>

                <template v-else>
                  <el-col
                    v-for="(item, index) in selectedImgList"
                    :key="index"
                    :span="name.indexOf('picture3Fixed') >= 0 ? 8 : 12"
                    class="mb-10"
                  >
                    <section :class="{ 'selected-img-item2': name.indexOf('picture3Fixed') >= 0, 'selected-img-item': name.indexOf('picture3Fixed') == -1 }">
                      <div>
                        <el-avatar
                          shape="square"
                          :size="name.indexOf('picture3Fixed') >= 0 ? 70 : 100"
                          class="avatar"
                          icon="el-icon-picture"
                          :src="item.image"
                        ></el-avatar>
                        <div class="avatar-mask">
                          <p @click="handleImg(index, 'updata')">替换</p>
                          <p @click="handleImg(index, 'delete')">删除</p>
                        </div>
                      </div>
                    </section>
                  </el-col>
                </template>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
      </el-row>

      <!-- 内容结束 -->
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="close">取 消</el-button>
        <el-button
          type="primary"
          @click="select"
        >确 定</el-button>
      </div>
    </el-dialog>
    <div v-if="cropperVisible">
      <Cropper
        :target-height="targetHeight"
        :target-width="targetWidth"
        :target-props="{
          width: selectType === 'multiple' ? attribute.width[0] : attribute.width,
          height: selectType === 'multiple' ? attribute.height[0] : attribute.height
        }"
        :range-width="rangeWidth"
        :range-height="rangeHeight"
        :max-size="300"
        :visible="cropperVisible"
        :urls="cropImgList.map((item) => item.url)"
        :image-info="cropImgList"
        @cancel="cancelFun"
      />
    </div>

    <AddMaterialDialog
      ref="addMaterialDialog"
      add-position="1"
      :type="'image'"
      @edit="onSubmit"
    ></AddMaterialDialog>
  </div>
</template>

<script>
import { getMaterialList } from '@/network/api/advManagement/advManagementBaiduCreativeFend'
import { getAdAlbum, getAdMaterialGroup } from '@/network/api/account/materents'
import Cropper from '@/components/cropper/index.vue'
import AddMaterialDialog from '@/components/materialManagement/material/AddMaterialDialog'
export default {
  components: { Cropper, AddMaterialDialog },
  props: {
    name: {
      type: String,
      default: 'pictureSingle'
    },
    visible: {
      type: Boolean,
      default: false
    },
    // 最多上传数量
    imgCount: {
      type: Number,
      default: 0
    },
    // 已上传图片
    imageList: {
      type: Array,
      default: () => []
    },
    // 图片属性用作验证图片是否符合
    attribute: {
      type: Object,
      default: () => {}
    },
    // 项目id
    projectId: {
      type: Array,
      default: () => []
    },
    selectAll: {
      type: Boolean,
      default: false
    },
    // 图片尺寸有多种选择 selectType = "multiple"
    selectType: {
      type: String,
      default: ''
    },
    // 是否批量添加
    isBatch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      UPLOAD_URL: process.env.VUE_APP_UPLOAD_URL,
      treeLoading: false,
      imgLoading: false,
      selectedImgCheckbox: false,
      selectedImgList: [], // 选中的图片
      selectedImgDetailList: [], // 选中的图片详情
      updateImgIndex: -1, // 替换图片index
      innerVisible: false,
      imgList: [], // 图片列表
      imagListTotal: 0, // 图片数量
      adAlbum: [], // 专辑名称
      materialGroup: [], // 专辑名称
      filterText: '', // 筛选专辑
      imgCountData: 0,
      selectAllDisabled: true, // 是否可以全选
      defaultProps: {
        children: 'children',
        label: 'label',
        isLeaf: 'leaf'
      },

      imageQuery: {
        // 图片搜索条件
        nickname: null,
        albumId: null,
        materialGroupId: null,
        width: typeof this.attribute.width === 'number' ? this.attribute.width : null,
        height: typeof this.attribute.height === 'number' ? this.attribute.height : null,
        pageNum: 1,
        type: 'image'
      },
      cropperVisible: false, // 裁剪框Visible
      targetWidth: 0, // 裁剪高
      targetHeight: 0, // 裁剪宽
      cropImgList: [], // 选中的图片
      rangeWidth: [], // 可裁剪的范围
      rangeHeight: [] //  可裁剪的范围
    }
  },

  watch: {
    visible: {
      handler(val) {
        if (val) {
          this.imgList = []
          this.imagListTotal = 0
        }
        if (!this.isBatch) {
          this.imgCountData = this.imgCount
        } else {
          this.imgCountData = 0
        }
        this.selectedImgCheckbox = false
        this.selectAllDisabled = true
        this.selectedImgList = JSON.parse(JSON.stringify(this.imageList))
      },
      immediate: true
    },

    attribute: {
      handler(val) {
        this.imageQuery.width = typeof val.width === 'number' ? val.width : ''
        this.imageQuery.height = typeof val.height === 'number' ? val.height : ''
      }
    },
    // 筛选专辑
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },

  methods: {
    // 点击编辑/新增素材
    onClickAddMaterial() {
      this.$refs.addMaterialDialog.showModal()
    },

    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    /**
     * @description: 点击树形选择调用
     * @param {*} value
     * @return {*}
     */
    nodeClick(value) {
      const { albumId, materialGroupId, typeName } = value

      this.imageQuery.albumId = albumId
      this.imageQuery.materialGroupId = typeName === 'materialGroup' ? materialGroupId : null
      this.imageQuery.pageNum = 1
      this.getMaterialListFun()
    },
    /**
     * @description: 获取tree组件数据
     * @param {*} node 上下文
     * @param {*} resolve 设置数据
     */
    loadNode(node, resolve) {
      this.treeLoading = true
      // 获取专辑
      if (node.level === 0) {
        getAdAlbum({ type: 'image', projectIds: this.projectId }).then(({ code, data }) => {
          this.treeLoading = false
          if (code === 200) {
            data.forEach((f) => {
              f.label = f.albumName
              f.typeName = 'album'
            })
            return resolve(data)
          }
        })
      }
      // 获取素材组
      const { albumId } = node.data
      if (node.level === 1) {
        getAdMaterialGroup({ type: 'image', albumId }).then(({ code, data }) => {
          this.treeLoading = false
          if (code === 200 && data && data.length > 0) {
            data.forEach((f) => {
              f.label = f.materialGroupName
              f.typeName = 'materialGroup'
              f.leaf = true
            })
            return resolve(data)
          } else {
            return resolve([])
          }
        })
      }
    },
    /**
     * @description: 获取图片列表
     * @param {*} current 当前页
     */
    getMaterialListFun() {
      this.imgLoading = true
      getMaterialList(this.imageQuery).then((res) => {
        if (res.code === 200) {
          this.imgList = res.data.list
          this.imagListTotal = res.data.total
          this.imgLoading = false
          this.selectedImgCheckbox = false

          if (this.imageQuery.materialGroupId && this.imgList.length) {
            this.selectAllDisabled = false
          } else {
            this.selectAllDisabled = true
          }
        }
      })
    },

    /**
     * @description: 切换分页
     * @param {*} current 当前页
     */
    currentChange(current) {
      this.imageQuery.pageNum = current
      this.getMaterialListFun()
    },

    /**
     * @description: 查询图片
     */
    onSubmit() {
      this.getMaterialListFun()
    },

    /**
     * @description: 重置
     */
    resetForm() {
      this.$refs['imageQuery'].resetFields()
      this.imageQuery.nickname = ''
      this.imageQuery.width = null
      this.imageQuery.height = null
      this.imageQuery.pageNum = 1
      this.getMaterialListFun()
    },

    successColor(item) {
      const { size, width, height, value } = this.attribute
      if (size < item.size) {
        return false
      } else if (value === '横版视频4:3') {
        if (!(item.width / item.height === 4 / 3) || item.width < 1280 || item.height < 960) {
          return false
        } else {
          return true
        }
      } else if (value === '横版视频') {
        if (!(item.width / item.height === 16 / 9) || item.width < 1280 || item.height < 720) {
          return false
        } else {
          return true
        }
      } else if (value === '竖版视频') {
        if (!(item.width / item.height === 9 / 16) || item.width < 720 || item.height < 1280) {
          return false
        } else {
          return true
        }
      } else if (width !== item.width || height !== item.height) {
        return false
      } else {
        return true
      }
    },

    /**
     * @description: 验证图片
     * @param {*} selectImg 选择框里的图片信息
     * @return {*}
     */
    verification(selectImg) {
      const { size, width, height, value } = this.attribute
      // 大于10000表示没有限制
      if (width === 10000 || height === 10000) {
        return true
      }
      // 验证尺寸
      if (size < selectImg.size) {
        return `图片不能大于${size / 1024}KB`
      }
      if (value === '横版视频4:3') {
        if (!(selectImg.width / selectImg.height === 4 / 3)) {
          return `横版视频封面尺寸比应该为4 : 3`
        } else if (selectImg.width < 1280 || selectImg.height < 960) {
          return `横版视频封面最小尺寸应该为 1280px * 960px`
        } else {
          return true
        }
      }
      if (value === '横版视频') {
        if (!(selectImg.width / selectImg.height === 16 / 9)) {
          return `横版视频封面尺寸比应该为16 : 9`
        } else if (selectImg.width < 1280 || selectImg.height < 720) {
          return `横版视频封面最小尺寸应该为 1280px * 720px`
        } else {
          return true
        }
      }
      if (value === '竖版视频') {
        if (!(selectImg.width / selectImg.height === 9 / 16)) {
          return `竖版视频封面尺寸比应该为9 : 16`
        } else if (selectImg.width < 720 || selectImg.height < 1280) {
          return `竖版视频封面最小尺寸应该为 720px * 1280px`
        } else {
          return true
        }
      }
      // 固定宽高
      if (typeof width === 'number' && typeof height === 'number') {
        if (width < selectImg.width && height < selectImg.height) {
          this.targetWidth = width
          this.targetHeight = height
          return '准备裁剪'
        } else if (width === selectImg.width && height === selectImg.height) {
          return true
        } else {
          return `选择的图片宽度应该为${width}px * ${height}px `
        }
      } else if (typeof width === 'number' && Array.isArray(height)) {
        // 固定宽度, 高度可选
        if (width === selectImg.width && height[0] <= selectImg.height && height[1] >= selectImg.height) {
          return true
        } else if (width < selectImg.width && height[0] <= selectImg.height && height[1] >= selectImg.height) {
          this.targetWidth = width
          this.targetHeight = null
          // this.rangeHeight = height
          return '准备裁剪'
        } else {
          return `选择的图片宽度应该为${width}px * ${height[0]}-${height[1]}px `
        }
      }
    },

    /*
     * @description: 验证多种选择图片 (推广页顶部图片为例图片尺寸：800 * 800像素 / 640 * 800像素 / 800 * 640像素)
     * @param {*} selectImg 选择框里的图片信息
     * @return {*}
     */
    verificationMultiple(selectImg) {
      const { size, width, height, value } = this.attribute
      // 大于10000表示没有限制
      if (width === 10000 || height === 10000) {
        return true
      }

      // 验证尺寸
      if (size < selectImg.size) {
        return `图片不能大于${size / 1024}KB`
      }

      if (value === '横版视频4:3') {
        if (!(selectImg.width / selectImg.height === 4 / 3)) {
          return `横版视频封面尺寸比应该为4 : 3`
        } else if (selectImg.width < 1280 || selectImg.height < 960) {
          return `横版视频封面最小尺寸应该为 1280px * 960px`
        } else {
          return true
        }
      }
      if (value === '横版视频') {
        if (!(selectImg.width / selectImg.height === 16 / 9)) {
          return `横版视频封面尺寸比应该为16 : 9`
        } else if (selectImg.width < 1280 || selectImg.height < 720) {
          return `横版视频封面最小尺寸应该为 1280px * 720px`
        } else {
          return true
        }
      }
      if (value === '竖版视频') {
        if (!(selectImg.width / selectImg.height === 9 / 16)) {
          return `竖版视频封面尺寸比应该为9 : 16`
        } else if (selectImg.width < 720 || selectImg.height < 1280) {
          return `竖版视频封面最小尺寸应该为 720px * 1280px`
        } else {
          return true
        }
      }
      // 固定宽高
      let width_height = []
      for (let index = 0; index < width.length; index++) {
        const widthItem = width[index]
        const heightItem = height[index]
        width_height.push(`${widthItem}-${heightItem}`)
      }

      if (width_height.includes(`${selectImg.width}-${selectImg.height}`)) {
        return true
      } else if (Math.max(...width) < selectImg.width && Math.max(...height) < selectImg.height) {
        this.targetWidth = null
        this.targetHeight = null
        // this.rangeWidth = [Math.max(...width), ]
        // this.rangeHeight = [Math.max(...height), Math.min(...height)]
        return '准备裁剪'
      } else {
        return `选择的图片宽度应该为${width_height} `
      }
    },
    /**
     * @description: 选择图片
     * @param {object} item 每张图片信息
     */
    selectImg(item) {
      const { selectedImgList, imgCount, updateImgIndex } = this
      const message = Array.isArray(this.attribute.width) ? this.verificationMultiple(item) : this.verification(item)
      // debugger
      if (message === '准备裁剪') {
        this.cropperVisible = true
        this.cropImgList.push(item)
      } else if (message === true) {
        // 替换图片
        if (updateImgIndex !== -1) {
          selectedImgList.splice(updateImgIndex, 1, { image: item.url, imageId: +item.imageId, ...item })
        } else {
          // 添加图片
          // 1. 判断是否已经选中.如果选中就取消选中
          if (!selectedImgList.some((s) => s.imageId === item.imageId)) {
            // 批量添加 - 不限图片数量
            if (this.isBatch) {
              this.selectedImgList.push({ image: item.url, imageId: +item.imageId, ...item })
            } else if (this.imgCount === 1) {
              // 单图
              this.selectedImgList = [{ image: item.url, imageId: +item.imageId, ...item }]
            } else if (selectedImgList.length < imgCount) {
              // 固定图片数量
              selectedImgList.push({ image: item.url, imageId: +item.imageId, ...item })
            } else {
              this.$message.warning(`图片最多选择${imgCount}张`)
            }
          } else {
            selectedImgList.splice(
              selectedImgList.findIndex((s) => s.imageId === item.imageId),
              1
            )
          }
        }
      } else {
        return this.$message.error(message)
      }

      this.imgCountData = this.selectedImgList.length

      this.updateImgIndex = -1
    },

    /**
     * @description: 删除/替换图片
     * @param {number} index 当前图片索引
     * @param {delete | updata} type
     */
    handleImg(index, type) {
      if (!this.selectedImgList.length) return this.$message.error('请先选择图片!')
      if (type === 'delete') {
        this.selectedImgList.splice(index, 1)
        this.imgCountData = this.selectedImgList.length
      } else {
        this.updateImgIndex = index
        this.$message.success('请选择要替换的图片!')
      }
    },

    /**
     * @description: 点击关闭弹框调用
     */
    close() {
      this.$emit('submit', this.imageList)
      this.$nextTick(() => {
        this.$emit('setMaterialBox')
      })
    },

    /**
     * @description: 点击关闭裁剪框调用
     */
    cancelFun() {
      this.cropperVisible = false
      this.cropImgList = []
      this.getMaterialListFun()
    },

    /**
     * @description: 点击确定调用
     */
    select() {
      if (this.name === 'picture3Fixed' && this.selectedImgList.length % 3 !== 0) {
        return this.$message.error('请正确选择固定三图!')
      }
      // 自定义模式下
      this.$emit('submit', this.selectedImgList)
      this.$emit('setMaterialBox')
    },

    /**
     * @description: 全选当前页面下图片
     */
    async selectAllFun(flag) {
      this.selectedImgCheckbox = true
      if (flag) {
        const { attribute, selectedImgList } = this
        let imgList = []
        const { code, data } = await getMaterialList({ ...this.imageQuery, pageSize: 50 })
        if (code === 200) {
          data.list.forEach((item) => {
            // 判断尺寸
            if (attribute.width === item.width && attribute.height === item.height && attribute.size >= parseInt(item.size)) {
              // 判断是否已存在
              if (!selectedImgList.some((s) => s.imageId === item.imageId)) {
                imgList.push({ image: item.url, imageId: +item.imageId, ...item })
              }
            }
          })
          this.selectedImgList = [...selectedImgList, ...imgList]
          this.imgCountData = this.selectedImgList.length
        }
      } else {
        this.selectedImgCheckbox = false
        // this.selectedImgList = []
        let list = this.imgList.map((item) => item.imageId)
        this.selectedImgList = this.selectedImgList.filter((f) => !list.includes(f.imageId))
        this.imgCountData = this.selectedImgList.length
      }
    }
  }
}
</script>

<style scoped lang="scss">
.material-dialog {
  .success-color {
    color: #409eff !important;
  }
  .selected-box {
    height: 439px;
    overflow-y: auto;
  }
  .selected-img {
    .selected-img-item {
      width: 100px;
      height: 100px;
      & > div {
        position: relative;
        .avatar-mask {
          width: 100px;
          height: 100px;
          background: rgba(0, 0, 0, 0.507);
          position: absolute;
          top: 0px;
          left: 10px;
          border-radius: 4px;
          display: none;
          p {
            margin-top: 20px;
            color: #fff;
            text-align: center;
            cursor: pointer;
          }
        }
        &:hover .avatar-mask {
          display: block;
        }
      }
    }
    .selected-img-item2 {
      width: 70px;
      height: 70px;
      & > div {
        position: relative;
        .avatar-mask {
          width: 70px;
          height: 70px;
          background: rgba(0, 0, 0, 0.507);
          position: absolute;
          top: 0px;
          left: 10px;
          border-radius: 4px;
          display: none;
          p {
            margin-top: 10px;
            color: #fff;
            text-align: center;
            cursor: pointer;
          }
        }
        &:hover .avatar-mask {
          display: block;
        }
      }
    }
    .avatar {
      // margin-top: 10px;
      margin-left: 10px;
    }
  }
  .tree-box {
    width: 100%;
    height: 500px;
    .tree {
      width: 100%;
      height: 460px;
      margin-top: 10px;
      overflow-y: auto;
    }
  }
  .select-img {
    height: 450px;
    padding: 0;
    li {
      border: 1px solid #ddd;
      margin: 10px;
      padding: 7px 3px 6px 3px;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      & > .avatar-box {
        height: 140px;
        overflow: hidden;
        display: flex;
        align-items: center;
      }
      img {
        width: 100%;
        margin: 0;
        background: none;
        margin: 0 !important;
      }
      .attachments {
        padding: 5px;
        > div {
          display: flex;
          justify-content: space-between;
          margin-top: 5px;
        }
        p {
          padding: 0;
          margin: 0;
        }
      }
      .el-icon-success {
        position: absolute;
        top: -10px;
        left: -10px;
        font-size: 20px;
        color: #409eff;
        display: none;
        background: #fff;
      }
    }
    .active {
      border-color: #409eff;
      .el-icon-success {
        display: block;
      }
    }
  }
}
</style>
