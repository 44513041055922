<!--
 * @Description: 组件选择区
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2023-03-10 15:28:30
-->

<template>
  <div>
    <section v-if="editors === ''" class="ad-editors">
      未选中组件
    </section>
    <section v-else class="ad-editors">
      <component :is="editors" :detail="detail" />
    </section>
  </div>
</template>

<script>
import topSlider from './adEditors_component/topSlider.vue'
import topImg from './adEditors_component/topImg.vue'
import topVideo from './adEditors_component/topVideo.vue'
import video from './adEditors_component/video.vue'
import Img from './adEditors_component/img.vue'
import Slider from './adEditors_component/slider.vue'
import Text from './adEditors_component/text.vue'
import CopyGh from './adEditors_component/copyGh.vue'
import EnterpriseWxAuto from './adEditors_component/enterpriseWxAuto.vue'
import FloatButton from './adEditors_component/floatButton.vue'
import ShelfNew from './adEditors_component/shelfnew/index.vue'

export default {
  components: {
    'm-img': Img,
    'm-slider': Slider,
    'm-topSlider': topSlider,
    'm-topImg': topImg,
    'm-topVideo': topVideo,
    'm-video': video,
    'm-text': Text,
    'm-copyGh': CopyGh,
    'm-enterpriseWxAuto': EnterpriseWxAuto,
    'm-floatButton': FloatButton,
    'm-shelfNew': ShelfNew
  },
  props: {
    finalWidgets: {
      type: Array,
      default: () => []
    },
    finalTopWidgets: {
      type: Object,
      default: () => {}
    },
    selectWidgetsIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      editors: '',
      detail: {}
    }
  },

  computed: {
    /**
     * @description: 当前需要选择几张图片
     * @return {number}
     */
    imgCount() {
      if (this.selectWidgetsIndex > -1) {
        if (this.finalWidgets[this.selectWidgetsIndex].name === '图片') {
          return 1
        } else {
          return +this.finalWidgets[this.selectWidgetsIndex].componentCount
        }
      } else {
        return 0
      }
    },
    /**
     * @description: 可以上传的图片的格式
     * @return {object}
     */
    attribute() {
      if (this.selectWidgetsIndex > -1) {
        if (this.finalWidgets[this.selectWidgetsIndex].name === '图片') {
          return {
            count: 1,
            size: 300,
            listType: 'image/jpg,image/jpeg,image/png',
            width: 750,
            height: [1, 1536]
          }
        } else {
          return {
            count: +this.finalWidgets[this.selectWidgetsIndex].componentCount,
            size: 300,
            listType: 'image/jpg,image/jpeg,image/png',
            width: 750,
            height: [300, 1125]
          }
        }
      } else {
        return {}
      }
    },
    imageList() {
      if (this.selectWidgetsIndex > -1) {
        if (this.finalWidgets[this.selectWidgetsIndex].name === '图片') {
          return [
            {
              image: this.finalWidgets[this.selectWidgetsIndex].image,
              iamgeId: this.finalWidgets[this.selectWidgetsIndex].iamgeId
            }
          ]
        } else {
          return this.finalWidgets[this.selectWidgetsIndex].componentGroup
        }
      } else {
        return []
      }
    }
  },
  watch: {
    selectWidgetsIndex: {
      handler(value) {
        if (value === -1) {
          this.editors = this.finalTopWidgets.component
          this.detail = this.finalTopWidgets
        } else if (value > -1) {
          this.detail = {}
          this.$nextTick(() => {
            this.editors = this.finalWidgets[value].component
            this.detail = this.finalWidgets[value]
          })
        } else {
          this.editors = ''
        }
      }
    },
    // 增删时, 显示对应编辑区
    finalTopWidgets: {
      handler(value) {
        if (Object.keys(value).length === 0) {
          if (this.finalWidgets.length) {
            this.$emit('setSelectedIndex', 0)
            this.editors = this.finalWidgets[0].component
            this.detail = this.finalWidgets[0]
          } else {
            this.editors = ''
            this.detail = {}
          }
        } else {
          this.editors = value.component
          this.detail = value
        }
      }
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.ad-editors {
  padding: 10px;
}
</style>
