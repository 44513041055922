<!--
 * @Description: 落地页
 * @Date: 2022-01-07 14:52:55
 * @LastEditTime: 2022-05-19 13:47:58
-->

<template>
  <div style="margin-top: 10px">
    <!-- <el-row>
      <el-col> -->
    <el-input v-model="name" placeholder="请输入落地页名称" class="input-with-select mb-10 w-300">
      <el-button slot="append" icon="el-icon-search" @click="getPage"></el-button>
    </el-input>
    <TablePane ref="TablePane" :data-source="dataSource" @getPage="getPage" />
    <!-- </el-col> -->
    <!-- <el-col :span="6">
        <p class="crowd-pack-selected-title">已选落地页</p>
        <ul class="crowd-pack-selected">
          <p class="tip">已发布</p>
          <li v-for="item in ldyData.list.filter((f) => f.taskPageId)" :key="item.id">
            <span>{{ item.pageName }}</span>
          </li>
        </ul>
        <ul class="crowd-pack-selected">
          <p class="tip">未发布</p>
          <li v-for="item in ldyData.list.filter((f) => f.taskPageId === null)" :key="item.id">
            <span>{{ item.pageName }}</span>
          </li>
        </ul>
      </el-col> -->
    <!-- </el-row> -->
  </div>
</template>

<script>
import TablePane from '@/components/tablePane.vue'
import { ldyPage } from '@/network/api/assetManagement/assetManageWeChatAdv.js'

export default {
  components: {
    TablePane
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    formData: {
      type: Object,
      default: () => {}
    },
    taskId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      name: null,
      ldyData: {},
      dataSource: {
        data: [],
        cols: [
          {
            label: '落地页名称',
            prop: 'promoteTitle'
          },
          {
            label: '上传时间',
            prop: 'createTime'
          }
        ], // 表格的列数据
        isIndex: false, // 列表序号
        loading: false, // loading
        isOperation: false,
        isSelection: true,
        pageData: {
          total: 0, // 总条数
          layout: 'total, prev, pager, next'
        },
        handleSelectionChange() {},
        selectable: (row) => {
          return true
        },
        selectAll: (selection) => {
          if (selection.length > 0) {
            let selectionList = []
            selectionList.push(...selection)
            let list = []
            selectionList.map((item) => {
              if (list.length === 0 || !list.some((s) => s.id === item.id)) {
                list.push({
                  taskPageId: item.taskPageId || null,
                  taskId: item.taskId || null,
                  id: item.id,
                  pageName: item.promoteTitle
                })
              }
            })

            let arr = [...this.ldyData.list, ...list]

            let newArr = []
            arr.forEach((item) => {
              if (newArr.length === 0 || !newArr.some((s) => s.id === item.id)) {
                newArr.push(item)
              }
            })

            this.ldyData = {
              ...this.ldyData,
              list: newArr
            }
          } else {
            this.ldyData = {
              ...this.ldyData,
              list: this.ldyData.list.filter((f) => f.taskPageId !== null)
            }
          }
          this.$emit('setLdyData', this.ldyData)
        },
        select: (selection, row) => {
          if (row.taskPageId) {
            this.setSelectData()
            return this.$message.error('该条落地页已发布')
          }
          if (selection.length > 0) {
            let selectionList = []
            selectionList.push(...selection)
            let list = []
            selectionList.map((item) => {
              if (list.length === 0 || !list.some((s) => s.id === item.id)) {
                list.push({
                  taskPageId: item.taskPageId || null,
                  taskId: item.taskId || null,
                  id: item.id,
                  pageName: item.promoteTitle
                })
              }
            })

            let arr = [...this.ldyData.list, ...list]

            let newArr = []
            arr.forEach((item) => {
              if (newArr.length === 0 || !newArr.some((s) => s.id === item.id)) {
                newArr.push(item)
              }
            })

            if (!selection.some((s) => s.id === row.id)) {
              newArr = newArr.filter((f) => f.id !== row.id)
            }

            this.ldyData = {
              ...this.ldyData,
              list: newArr
            }
          } else {
            this.ldyData = {
              ...this.ldyData,
              list: this.ldyData.list.filter((f) => f.taskPageId !== null)
            }
          }
          this.$emit('setLdyData', this.ldyData)
        }
      }
    }
  },

  watch: {
    formData: {
      handler(value) {
        if (value.list) {
          this.ldyData = {
            ...value,
            list: value.list.map((item) => {
              return {
                ...item,
                id: item.localPageId,
                pageName: item.localPageName
              }
            })
          }
        } else {
          this.ldyData = value
        }
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    getPage(params = { pageNum: 1, pageSize: 10 }) {
      this.dataSource.loading = true
      ldyPage({ ...params, promoteTitle: this.name, taskId: this.taskId }).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          records.forEach((element) => {
            element.pageName = element.promoteTitle
            element.taskPageId = (this.ldyData.list.filter((f) => f.localPageId === element.id).length && this.ldyData.list.filter((f) => f.localPageId === element.id)[0].taskPageId) || null
            element.taskId = this.taskId
          })
          this.dataSource.data = records
          this.dataSource.pageData.total = total
          this.dataSource.pageData.pageNum = params.pageNum
          this.dataSource.loading = false
          this.setSelectData()
        }
      })
    },

    setSelectData() {
      this.$refs['TablePane'].getRowData([])
      if (this.ldyData.list.length) {
        let rows = []
        this.dataSource.data.map((item) => {
          if (this.ldyData.list.some((s) => s.id === item.id)) {
            rows.push(item)
          }
        })

        this.$refs['TablePane'].getRowData(rows)
      }
    }
  }
}
</script>

<style lang="scss">
.city-box {
  .el-cascader__tags {
    max-height: 240px;
    overflow-y: auto;
  }
}
.el-cascader-panel {
  .el-cascader-menu__wrap {
    height: 320px;
  }
}
</style>

<style lang="scss" scoped>
p {
  margin: 0;
  padding: 0;
}

.background {
  background: #f7f8fa;
  padding: 10px;
  border-radius: 10px;
}

.crowd-pack-selected-title {
  border-left: 3px solid #409eff;
  padding-left: 5px;
  margin: 0 0 5px 0;
  font-weight: 600;
}
.crowd-pack-selected {
  display: flex;
  flex-direction: column;
  max-height: 486px;
  overflow-y: auto;
  li {
    width: 100%;
    display: flex;
    padding: 4px 0;
    align-items: center;
    font-size: 14px;
    span {
      width: 80%;
      text-align: right;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      flex: 1;
      margin-right: 10px;
    }
    i {
      width: 20px;
      cursor: pointer;
      font-weight: 600;
    }
  }
}

.crowd-pack-selected2 {
  display: flex;
  flex-direction: column;
  height: 296px;
  padding: 0 10px 0 0;
  overflow-y: auto;
  li {
    width: 100%;
    display: flex;
    padding: 4px 0;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    span {
      text-align: right;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-right: 10px;
      font-size: 13px;
    }
    i {
      cursor: pointer;
      color: #888888;
      font-size: 13px;
    }
  }
}
</style>
