var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "base-box",
        { attrs: { name: "img" } },
        [
          _c("template", { slot: "body" }, [
            _c("div", { staticClass: "image-updata" }, [
              _c("p", { staticClass: "font-13" }, [_vm._v("选择图片：")]),
              _c("div", [
                _c(
                  "div",
                  { staticClass: "update", on: { click: _vm.setMaterialBox } },
                  [
                    _vm.detail.pureImageUrl
                      ? _c("img", {
                          staticClass: "avatar",
                          attrs: { src: _vm.detail.pureImageUrl },
                        })
                      : _c("i", {
                          staticClass: "el-icon-plus avatar-uploader-icon",
                        }),
                  ]
                ),
                _c("p", { staticClass: "tip mt-10" }, [
                  _vm._v(" 暂不支持含有二维码的图片 "),
                  _c("br"),
                  _vm._v(" 图片格式：大小不超过300KB， "),
                  _c("br"),
                  _vm._v(" 图片尺寸：宽度750像素，高度不超过1536像素 "),
                ]),
              ]),
            ]),
            _c("div", [
              _c("p", { staticClass: "font-13" }, [_vm._v("上边距：")]),
              _c(
                "div",
                { staticClass: "ml-20" },
                [
                  _c("el-slider", {
                    attrs: { "show-input": "" },
                    model: {
                      value: _vm.detail.paddingTop,
                      callback: function ($$v) {
                        _vm.$set(_vm.detail, "paddingTop", $$v)
                      },
                      expression: "detail.paddingTop",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "setting" }, [
              _c("p", { staticClass: "font-13" }, [_vm._v("下边距：")]),
              _c(
                "div",
                { staticClass: "ml-20" },
                [
                  _c("el-slider", {
                    attrs: { "show-input": "" },
                    model: {
                      value: _vm.detail.paddingBottom,
                      callback: function ($$v) {
                        _vm.$set(_vm.detail, "paddingBottom", $$v)
                      },
                      expression: "detail.paddingBottom",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
      _c("material-box", {
        attrs: {
          visible: _vm.materialBox_visible,
          "img-count": 1,
          "image-list": _vm.imageList,
          name: "ldy",
          attribute: {
            key: "img",
            value: "图片",
            count: 1,
            size: 300 * 1024,
            listType: "image/jpg,image/jpeg,image/png",
            width: 750,
            height: [0, 1536],
          },
        },
        on: { setMaterialBox: _vm.setMaterialBox, submit: _vm.selectImg },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }