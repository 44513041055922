<!--
 * @Description: 微信人群包列表
 * @Date: 2021-12-01 09:15:29
 * @LastEditTime: 2022-04-01 11:31:17
 :options="projectList"
  :props="{
    label: 'projectName',
    value: 'bsProjectId'
  }"
-->

<template>
  <div>
    <!-- 列表查询 -->
    <el-form ref="search" :inline="true" :model="search" class="search-form search-form-4" @submit.native.prevent>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="人群包名称" prop="name">
            <el-input v-model="search.name" placeholder="请输入定向名称"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="上传人" prop="createBy">
            <el-input v-model="search.createBy" placeholder="请输入上传人"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="数据类型" prop="uploadType">
            <el-select v-model="search.uploadType" placeholder="请选择数据类型">
              <el-option v-for="item in crowdPack_type_list" :key="item.dictLabel" :value="item.dictValue" :label="item.dictLabel">{{ item.dictLabel }}</el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="项目" prop="projectId">
            <SearchSelect
              v-model="search.projectId"
              :options="projectList"
              :props="{
                label: 'projectName',
                value: 'bsProjectId'
              }"
              placeholder="请选择项目"
              @change="(value) => (selectProjectId = value)"
            />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="产品" prop="productId">
            <el-select v-model="search.productId" placeholder="请选择产品" @change="(value) => (selectProductId = value)">
              <el-option label="不限" :value="0"></el-option>
              <el-option v-for="(itm, idx) in productListed" :key="idx" :label="itm.productName" :value="itm.bsProductId"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="上传时间" prop="createTime">
            <el-date-picker v-model="search.createTime" type="daterange" value-format="yyyy-MM-dd" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" round @click="getPage">查询</el-button>
            <el-button round @click="resetFormQuery('search')">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div class="justify-sb">
      <el-dropdown @command="handleCommand">
        <el-button type="primary"> 批量操作 <i class="el-icon-arrow-down el-icon--right"></i> </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :disabled="selectRow.length < 1" command="sync">批量同步</el-dropdown-item>
          <el-dropdown-item :disabled="selectRow.length < 1" command="delete">批量删除</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <el-dropdown>
        <el-button type="primary"> 新增人群包 <i class="el-icon-arrow-down el-icon--right"></i> </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item> <router-link to="/wechatLocalUpload" tag="span">本地上传人群包</router-link></el-dropdown-item>
          <el-dropdown-item><router-link to="/wechatLabelUpload" tag="span">标签生成人群包</router-link></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <!-- 表格 -->
    <TablePane class="mt-20" :data-source="dataSource" @getPage="getPage"> </TablePane>

    <!-- 人群包同步 -->
    <el-dialog title="历史同步账户" :visible.sync="history_sync_visible" width="820px" :modal-append-to-body="false" destroy-on-close>
      <TablePane :data-source="dataSource_sync"> </TablePane>
    </el-dialog>

    <!-- 人群包同步 -->
    <el-dialog title="人群包同步" :visible.sync="sync_visible" width="620px" :modal-append-to-body="false" destroy-on-close>
      <el-form ref="ruleForm" :model="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="服务商:" prop="spid" :rules="{ required: true, message: '请选择服务商', trigger: 'change' }">
          <el-select
            v-model="ruleForm.spid"
            style="width:400px"
            placeholder="请选择服务商"
            @change="
              () => {
                ruleForm.ghids = null
              }
            "
          >
            <el-option v-for="(itm, idx) in adMpAmList" :key="idx" :label="`${itm.agencyName} (${itm.mobileId})`" :value="`${itm.spid}+${itm.mobileId}`"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="投放账户:" prop="ghids" :rules="{ required: true, message: '请选择投放账户', trigger: 'blue' }">
          <SearchSelectAsyncAccount
            v-model="ruleForm.ghids"
            :disabled="!ruleForm.spid"
            :spid="ruleForm.spid && ruleForm.spid.split('+')[0]"
            multiple
            :props="{
              label: 'name',
              value: 'ghid'
            }"
            style="width:400px"
            placeholder="请选择投放账户"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
        <el-button @click="resetForm('ruleForm')">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { audiencePage, audienceManagementPage, audienceDelete, adMpAudienceAsync } from '@/network/api/assetManagement/assetManageWeChatAdv.js'
import { getAdMpAm } from '@/network/api/account/weChatAdvAccount.js'
import TablePane from '@/components/tablePane.vue'
import SearchSelectAsyncAccount from '@/components/searchSelectAsyncAccount.vue'
import SearchSelect from '@/components/searchSelect.vue'
import { getDateStartEnd } from '@/utils/ruoyi'
import accountUinTencent from '@/mixin/accountUin_tencent'
import { crowdPack_type_obj } from '../data'

export default {
  components: {
    TablePane,
    SearchSelectAsyncAccount,
    SearchSelect
  },
  mixins: [accountUinTencent],
  data() {
    return {
      crowdPack_type_list: [],
      adMpAmList: [],
      search: {
        name: null,
        uploadType: null,
        createTime: null,
        projectId: null,
        productId: null,
        createBy: null
      },
      sync_visible: false, // 历史同步账户
      history_sync_visible: false, // 历史同步账户
      commandType: '0',
      selectRow: [], // 选中的列
      selectRow_one: {},
      ruleForm: {
        spid: null,
        ghids: null
      },
      dataSource: {
        data: [],
        cols: [
          {
            label: '人群包名称',
            prop: 'name'
          },
          {
            label: '上传人',
            prop: 'createBy'
          },
          {
            label: '上传时间',
            prop: 'createTime'
          },
          {
            label: '数据类型',
            render: function(record) {
              // eslint-disable-next-line
              return record.uploadType ? <el-tag>{crowdPack_type_obj[record.uploadType]}</el-tag> : '-'
            }
          },
          {
            label: '关联项目',
            // eslint-disable-next-line
            render: (record) => <p class="text-ellipsis">{record.projectName || '-'}</p>
          },
          {
            label: '关联产品',
            render: (record) => record.productName || '-'
          },
          {
            label: '历史同步账户数',
            width: 130,
            prop: 'asyncNum',
            handleRow: (index, record) => {
              this.history_sync_visible = true
              this.getManagementPage({ templateId: record.id })
            }
          },
          {
            label: '描述',
            /* eslint-disable*/
            render: (recode) => (
              <el-tooltip placement="top">
                <div slot="content" style="max-width: 200px">
                  {recode.description || '无描述'}
                </div>
                <p class="text-ellipsis">{recode.description || '-'}</p>
              </el-tooltip>
              /* eslint-disable*/
            )
          }
        ], // 表格的列数据
        isIndex: true, // 列表序号
        loading: true, // loading
        isOperation: true,
        isSelection: true,
        pageData: {
          total: 0 // 总条数
        },
        handleSelectionChange: (selection) => {
          this.selectRow = selection
        },
        operation: {
          // 表格有操作列时设置
          label: '操作', // 列名
          width: 120, // 根据实际情况给宽度
          data: [
            {
              tooltip: '同步',
              icon: 'el-icon-upload2',
              handleRow: (index, recode) => {
                this.sync_visible = true
                this.selectRow = []
                this.selectRow_one = recode
              }
            },
            {
              tooltip: '删除',
              icon: 'el-icon-delete',
              color: '#ff0000',
              handleRow: (index, recode) => {
                this.deleteList([recode.id])
                this.getPage()
              }
            }
          ]
        }
      },
      dataSource_sync: {
        data: [],
        cols: [
          {
            label: '账户名称',
            prop: 'ghname'
          },
          {
            label: '账户ID',
            prop: 'ghid'
          },
          {
            label: '同步人',
            prop: 'createBy'
          },
          {
            label: '上传时间',
            prop: 'createTime'
          }
        ], // 表格的列数据
        isIndex: true, // 列表序号
        loading: false, // loading
        isOperation: false,
        isSelection: false,
        pageData: {
          total: 0 // 总条数
        },
        handleSelectionChange: () => {}
      }
    }
  },

  mounted() {
    this.getPage()
    this._getAdMpAm()
    this.getDicts('crowdPack_type_list').then((response) => {
      this.crowdPack_type_list = response.data
    })
  },

  methods: {
    // 获取服务商
    _getAdMpAm() {
      getAdMpAm().then((res) => {
        if (res.code === 200) {
          this.adMpAmList = res.data
        }
      })
    },
    /**
     * @description: 人群包同步
     */
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        const { selectRow_one, selectRow, ruleForm } = this
        if (valid) {
          let params = {
            ids: selectRow.length ? selectRow.map((item) => ({ id: item.id })) : [{ id: selectRow_one.id }],
            ghids: ruleForm.ghids.map((item) => {
              return {
                ghid: item.split('+')[0],
                name: item.split('+')[1]
              }
            }),
            spid: ruleForm.spid.split('+')[0],
            mobileId: ruleForm.spid.split('+')[1]
          }
          const { code } = await adMpAudienceAsync(params)
          if (code === 200) {
            this.$message.success('同步成功!')
          }
          this.resetFormQuery(formName)
        }
      })
    },

    /**
     * @description: 重置数据
     * @param {*} formName ref
     */
    resetForm(formName) {
      this.sync_visible = false
      this.$refs[formName].resetFields()
    },

    resetFormQuery(formName) {
      this.$refs[formName].resetFields()
      this.productListed = []
      this.getPage()
    },

    /**
     * @description: 获取列表
     * @param {*} params
     */
    getPage(params = { pageNum: 1, pageSize: 10 }) {
      this.dataSource.loading = true
      const { createTime, productId, projectId } = this.search
      const [startCreateTime, endCreateTime] = getDateStartEnd(createTime)
      let search = {
        ...this.search,
        startCreateTime,
        endCreateTime,
        projectIds: projectId ? [projectId] : null,
        productIds: productId ? [productId] : null
      }
      delete search.createTime
      audiencePage({ ...search, ...params }).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          this.dataSource.data = records
          this.dataSource.pageData.total = total
          this.dataSource.pageData.pageNum = params.pageNum
          this.dataSource.loading = false
        }
      })
    },

    /**
     * @description: 获取同步列表
     * @param {*} params
     */
    getManagementPage(params = { pageNum: 1, pageSize: 10, templateId: null }) {
      this.dataSource_sync.loading = true
      audienceManagementPage(params).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          this.dataSource_sync.data = records
          this.dataSource_sync.pageData.total = total
          this.dataSource_sync.pageData.pageNum = params.pageNum
          this.dataSource_sync.loading = false
        }
      })
    },

    handleCommand(value) {
      if (value === 'delete') {
        this.deleteList(this.selectRow.map((item) => item.id))
      } else {
        this.sync_visible = true
      }
    },

    deleteList(ids) {
      this.$confirm('确认删除该条数据?', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(async () => {
        const { code } = await audienceDelete({ ids })
        if (code === 200) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getPage()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 5px 0;
  margin: 0;
}
.account-info {
  display: flex;
}
</style>
