var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "video-template-box" },
    [
      _c("div", { staticClass: "template-left" }, [
        _c(
          "div",
          { staticClass: "template-search-form" },
          [
            _c("el-input", {
              staticStyle: { width: "200px", "margin-right": "10px" },
              attrs: { placeholder: "请输入模板名称", clearable: "" },
              model: {
                value: _vm.templateName,
                callback: function ($$v) {
                  _vm.templateName = $$v
                },
                expression: "templateName",
              },
            }),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
              [_vm._v("查询")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "video-template-list" },
          _vm._l(_vm.templateList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "video-template-item",
                class: { active: _vm.activeTemplate === item.id },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleChangeTemplate(item)
                  },
                },
              },
              [
                _c("div", { staticClass: "name" }, [_vm._v(_vm._s(item.name))]),
                _c("div", { staticClass: "num" }, [
                  _vm._v("元素：" + _vm._s(item.elementCount)),
                ]),
                _c("div", { staticClass: "template-id" }, [
                  _vm._v("模板ID：" + _vm._s(item.videoTemplateId)),
                ]),
                _c(
                  "div",
                  { staticClass: "template-opt" },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "template-status",
                        class: { offline: item.state === 0 },
                      },
                      [_vm._v(_vm._s(_vm._f("filterState")(item.state)))]
                    ),
                    _c("el-button", {
                      staticClass: "template-edit-btn",
                      attrs: { type: "text", icon: "el-icon-edit" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleAddTemplate(item)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          }),
          0
        ),
        _c(
          "div",
          {
            staticClass: "video-template-add",
            on: {
              click: function ($event) {
                return _vm.handleAddTemplate("")
              },
            },
          },
          [_vm._v("新增")]
        ),
      ]),
      _c(
        "div",
        { staticClass: "template-right" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-plus", round: "" },
              on: { click: _vm.handleAddRelation },
            },
            [_vm._v("添加")]
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "10px" } },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "list-table",
                  class: { "no-data": !_vm.list || !_vm.list.length },
                  attrs: { data: _vm.list, stripe: "", height: "700px" },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "index", label: "序号", width: "50" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "元素名称", align: "center" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "baseType",
                      label: "基础类型",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(_vm._f("filterBaseType")(row.baseType)) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "typeName",
                      label: "元素类型",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "replaceName",
                      label: "替换字段",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return _c(
                            "div",
                            {},
                            [
                              !row.isEdit
                                ? _c(
                                    "span",
                                    { staticClass: "edit-input-box" },
                                    [
                                      _vm._v(_vm._s(row.replaceName) + " "),
                                      _c("el-button", {
                                        staticClass: "el-icon-edit",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleEdit(row)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c("el-input", {
                                    staticStyle: { width: "100px" },
                                    attrs: { type: "text", autofocus: true },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.handleEditSave(row)
                                      },
                                    },
                                    model: {
                                      value: row.replaceName,
                                      callback: function ($$v) {
                                        _vm.$set(row, "replaceName", $$v)
                                      },
                                      expression: "row.replaceName",
                                    },
                                  }),
                            ],
                            1
                          )
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "url", label: "预览", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.baseType === "IMG"
                              ? _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "right",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "element-pop-img" },
                                      [
                                        _c("el-image", {
                                          attrs: {
                                            src: row.url,
                                            fit: "contain",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "element-img",
                                        attrs: { slot: "reference" },
                                        slot: "reference",
                                      },
                                      [
                                        _c("el-image", {
                                          attrs: {
                                            src: row.url,
                                            fit: "contain",
                                            lazy: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            row.baseType === "VIDEO"
                              ? _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "right",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "element-pop-img" },
                                      [
                                        _c("video", {
                                          attrs: { src: row.url, controls: "" },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "element-img",
                                        attrs: { slot: "reference" },
                                        slot: "reference",
                                      },
                                      [_c("video", { attrs: { src: row.url } })]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "", label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "el-button",
                              {
                                staticClass: "red",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDeleteElement(row.id)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "删除",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-delete",
                                      staticStyle: { "font-size": "16px" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("video-template", {
        ref: "videoTemplateDialog",
        on: { confirm: _vm.getTemplateList },
      }),
      _c("template-element-relation", {
        ref: "templateRelationDialog",
        on: { refresh: _vm.refreshData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }