/*
 * @Description: Do not edit
 * @Date: 2021-10-21 10:23:18
 * @LastEditTime: 2022-03-30 15:36:49
 */
/** 百分比行为 Option*/
export const returnConditionOption = [
  { title: '上传所有充值行为', value: 1 },
  { title: '上传用户在关注当天自然日的所有充值行为', value: 2 },
  { title: '上传用户在关注当天自然日的首次充值行为', value: 3 },
  { title: '上传用户在关注24小时内的所有充值行为', value: 4 },
  { title: '上传用户在关注24小时内的首次充值行为', value: 5 }
]

/** 百分比回传 Option*/
export const returnRateOption = [
  { title: '10%', value: 0.1 },
  { title: '20%', value: 0.2 },
  { title: '30%', value: 0.3 },
  { title: '40%', value: 0.4 },
  { title: '50%', value: 0.5 },
  { title: '60%', value: 0.6 },
  { title: '70%', value: 0.7 },
  { title: '80%', value: 0.8 },
  { title: '90%', value: 0.9 }
]

/** 循环比例 Option*/
export const cycleProportionOption = [
  { title: '1:1', value: '1:1' },
  { title: '2:1', value: '2:1' },
  { title: '3:1', value: '3:1' },
  { title: '4:1', value: '4:1' },
  { title: '5:1', value: '5:1' },
  { title: '自定义', value: '0:0' }
]
/** 循环比例 Option*/
export const cycleProportionOption_baidu = [
  { title: '1', value: 1 },
  { title: '2', value: 2 },
  { title: '3', value: 3 },
  { title: '4', value: 4 },
  { title: '5', value: 5 },
  { title: '6', value: 6 },
  { title: '7', value: 7 },
  { title: '8', value: 8 },
  { title: '9', value: 9 },
  { title: '10', value: 10 }
]

export const orderOperatorOption = [
  { title: '等于', value: 'EQ' },
  { title: '大于', value: 'GT' },
  { title: '大于等于', value: 'EGT' },
  { title: '小于', value: 'LT' },
  { title: '小于等于', value: 'ELT' }
]
export const column = [
  {
    label: '书城',
    render: (record) => <p>{this.platform_obj[record.platform]}</p>
  },
  {
    label: '公众号',
    prop: 'public_name'
  },
  {
    label: '广告ID',
    prop: 'adid'
  },
  {
    label: '订单ID',
    render: function(record) {
      return (
        /*eslint-disable*/
        <p class="text-ellipsis" style={{ width: 80, textAlign: 'center' }}>
          {record.merchantId}
        </p>
        /*eslint-disable*/
      )
    }
  },
  {
    label: '用户名称',
    render: (record) => <p>{record.nickname === '' ? '无' : record.nickname}</p>
  },
  {
    label: '用户ID',
    prop: 'uid'
  },
  {
    label: '注册时间',
    width: 80,
    render: (record) => <p>{moment(moment.unix(record.createdTime)).format('YYYY-MM-DD HH:MM:ss')}</p>
  },
  {
    label: '下单时间',
    render: (record) => <p>{moment(moment.unix(record.orderCreatetime)).format('YYYY-MM-DD')}</p>
  },
  {
    label: '充值金额',
    prop: 'money'
  },
  {
    label: '订单类型',
    render: (record) => (record.order_type === 0 ? '未知' : record.order_type === 1 ? '首充' : '复充')
  },
  {
    label: '回传状态',
    render: function(record) {
      return (
        <div>
          {record.status === 0 ? (
            <el-tag type="info">未推送</el-tag>
          ) : record.status === 1 ? (
            <el-tag type="success">推送完成</el-tag>
          ) : record.status === 2 ? (
            <el-tag type="danger">推送失败</el-tag>
          ) : record.status === 3 ? (
            <el-tag type="success">手动推送</el-tag>
          ) : (
            <el-tag type="info">需手动推送</el-tag>
          )}
        </div>
      )
    }
  },
  {
    label: '回传地址',
    render: function(record) {
      return (
        <p class="text-ellipsis" style={{ width: 100, textAlign: 'center' }}>
          {record.logidurl}
        </p>
      )
    }
  }
]

export const platformList = [
  {
    key: 'P1001',
    value: '阳光平台'
  },
  {
    key: 'P1002',
    value: '掌中云平台'
  },

  {
    key: 'P1003',
    value: '阅文平台'
  },

  {
    key: 'P1004',
    value: '花生书城平台'
  },

  {
    key: 'P1005',
    value: '追书云平台'
  },
  {
    key: 'P1006',
    value: '悠书阁平台'
  },
  {
    key: 'P1007',
    value: '掌读平台'
  },
  // {
  //   key: 'P1008',
  //   value: '网易平台'
  // },
  {
    key: 'P1009',
    value: '迈步平台'
  },
  {
    key: 'P1010',
    value: '千帆阅文'
  },
  {
    key: 'P1011',
    value: '咪咕'
  },
  {
    key: 'P1012',
    value: '海读'
  },
  {
    key: 'P1013',
    value: '七悦'
  }
]

export const platform_obj = {
  P1001: '阳光平台',
  P1002: '掌中云平台',
  P1003: '阅文平台',
  P1004: '花生书城平台',
  P1005: '追书云平台',
  P1006: '悠书阁平台',
  P1007: '掌读平台',
  // P1008: '网易平台',
  P1009: '迈步平台',
  P1010: '千帆阅文',
  P1011: '咪咕',
  P1012: '海读',
  P1013: '七悦'
}

export const platform_4 = {
  1: '百度',
  2: '巨量',
  3: 'UC',
  4: '快手'
}

export const platform_4_py = {
  1: 'baidu',
  2: 'oceanengine',
  3: 'uc',
  4: 'kuaishou'
}

export const platform_4_py_text = {
  baidu: '百度',
  oceanengine: '巨量',
  uc: 'UC',
  kuaishou: '快手',
  gdt: '腾讯'
}
