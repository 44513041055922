var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.isEdit ? "编 辑" : "新 增",
        width: "45%",
        visible: _vm.visible,
        "before-close": _vm.close,
        "label-position": "right",
        "modal-append-to-body": false,
        "close-on-press-escape": false,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ent",
          attrs: { model: _vm.ent, rules: _vm.rules, "label-width": "110px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "baiduCreativeFendId" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.baiduCreativeFendId,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "baiduCreativeFendId", $$v)
                  },
                  expression: "ent.baiduCreativeFendId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "创意ID\t", prop: "creativeFeedId" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.creativeFeedId,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "creativeFeedId", $$v)
                  },
                  expression: "ent.creativeFeedId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "所属推广单元ID", prop: "adgroupFeedId" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.adgroupFeedId,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "adgroupFeedId", $$v)
                  },
                  expression: "ent.adgroupFeedId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "创意样式ID", prop: "materialstyle" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.materialstyle,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "materialstyle", $$v)
                  },
                  expression: "ent.materialstyle",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "创意名称\t", prop: "creativeFeedName" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.creativeFeedName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "creativeFeedName", $$v)
                  },
                  expression: "ent.creativeFeedName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否暂停推广\t", prop: "pause" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.pause,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "pause", $$v)
                  },
                  expression: "ent.pause",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "创意状态 ", prop: "status" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "status", $$v)
                  },
                  expression: "ent.status",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "物料内容 ", prop: "material" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.material,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "material", $$v)
                  },
                  expression: "ent.material",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "审核未通过的原因（审核拒绝理由）\n",
                prop: "refusereason",
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.refusereason,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "refusereason", $$v)
                  },
                  expression: "ent.refusereason",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "视频创意的视频播放量", prop: "playnum" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.playnum,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "playnum", $$v)
                  },
                  expression: "ent.playnum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label:
                  "创意类型  \t取值范围：枚举值，列表如下\n0 - 自定义创意\n1 - 程序化创意",
                prop: "ideaType",
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.ideaType,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "ideaType", $$v)
                  },
                  expression: "ent.ideaType",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "程序化创意展示样式\t ", prop: "showMt" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.showMt,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "showMt", $$v)
                  },
                  expression: "ent.showMt",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "创意的创建时", prop: "addtime" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.addtime,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "addtime", $$v)
                  },
                  expression: "ent.addtime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "JSON格式化后的审核未通过的原因",
                prop: "approvemsgnew",
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.approvemsgnew,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "approvemsgnew", $$v)
                  },
                  expression: "ent.approvemsgnew",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "预估审核时间\t", prop: "audittimemodel" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.audittimemodel,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "audittimemodel", $$v)
                  },
                  expression: "ent.audittimemodel",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "逻辑删除", prop: "deleted" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.deleted,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "deleted", $$v)
                  },
                  expression: "ent.deleted",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "创建者", prop: "createBy" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.createBy,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "createBy", $$v)
                  },
                  expression: "ent.createBy",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "创建时间", prop: "createTime" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.createTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "createTime", $$v)
                  },
                  expression: "ent.createTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "更新者", prop: "updateBy" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.updateBy,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "updateBy", $$v)
                  },
                  expression: "ent.updateBy",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "更新时间", prop: "updateTime" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.updateTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "updateTime", $$v)
                  },
                  expression: "ent.updateTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "remark", $$v)
                  },
                  expression: "ent.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { attrs: { round: "" }, on: { click: _vm.close } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", round: "" },
              on: { click: _vm.submit },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }