<template>
  <!--显示列弹框-->
  <el-drawer title="创意素材" size="900px" :append-to-body="true" custom-class="dialog" :visible.sync="show"
    label-position="right" @close="onCloseDialog">
    <div class="drawer-content">
      <el-form label-width="120px">
        <el-form-item label="每个创意组配置">
          <el-input-number v-model="videoSize" :max="10" :controls="false" style="width: 100px"
            @change="handleSizeChange('videoSize')"></el-input-number> 个视频
          <el-input-number v-model="imageSize" :max="12" :controls="false" style="width: 100px"
            @change="handleSizeChange('imageSize')"></el-input-number> 个图片
          <div>
            <span class="tip">提示：每组素材视频数量不得超过10，图片数量不得超过12</span>
          </div>
        </el-form-item>
        <el-form-item label="自动生成封面">
          <el-select v-model="generateCover" style="width: 300px;">
            <el-option label="关闭" :value="0"></el-option>
            <el-option label="开启" :value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="group-info">
        <div>
          <span class="group-label">创意制作</span>
          <span class="group-count">创意组数量：{{ creativeMedias.length }} 组</span>
          <el-button :disabled="creativeMedias.length >= maxSelect" type="primary" size="mini" plain
            style="margin-left:10px" @click="handleAddGroup">添加创意组
          </el-button>
        </div>
        <div>
          <el-button type="danger" :disabled="!creativeMedias.length" plain icon="el-icon-delete" size="mini"
            @click="handleEmpty">清空</el-button>
        </div>
      </div>
      <!-- 素材组 -->
      <div class="creative">
        <div v-for="(item, index) in creativeMedias" :key="index" class="creative-content">
          <div class="creative-group">
            <!-- 素材组头部 -->
            <div class="creative-group-header">
              <span class="creative-group-name">创意组{{ index + 1 }}</span>
              <span class="el-icon-delete" style="color: red;cursor: pointer;" @click="onClickDelGroup(index)"></span>
            </div>
            <!-- 素材组内容 -->
            <div class="creative-group-body">
              <div class="creative-tabs">
                <div :class="['tag', item.activeMenu === 2 ? 'active' : '']" @click="onClickTag(index, 2)">视频({{
                    item.videos.length
                }}/{{ videoSize }})</div>
                <div :class="['tag', item.activeMenu === 1 ? 'active' : '']" @click="onClickTag(index, 1)">图片({{
                    item.images.length
                }}/{{ imageSize }})</div>
              </div>
              <!-- 图片素材 -->
              <div v-show="item.activeMenu === 1" class="creative-image-material">
                <div v-for="(itm, idx) in item.images" :key="idx" class="creative-image-material-item">
                  <i class="el-icon-circle-close" @click.stop="onClickDelImage(index, idx)"></i>
                  <img v-if="itm.imageUrl !== ''" :src="itm.localImageUrl" alt="" />
                  <div v-if="itm.imageUrl === ''" class="avatar">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                  <div class="avatar-mask">
                    <p @click="onClickUploadImage(index, idx, 'image')">上传图片</p>
                  </div>
                </div>
                <div v-if="item.images.length < imageSize" class="creative-image-material-item-add"
                  @click="onClickAddImage(index)">+</div>
              </div>
              <!-- 视频素材 -->
              <div v-show="item.activeMenu === 2" class="creative-video-material">
                <div v-for="(itm, idx) in item.videos" :key="idx" class="creative-video-material-item">
                  <i class="el-icon-circle-close" @click.stop="onClickDelVideo(index, idx)"></i>
                  <!-- 视频 -->
                  <div class="creative-video-material-item-left">
                    <video v-if="itm.videoUrl !== ''" :src="itm.localVideoUrl" controls></video>
                    <div v-if="itm.videoUrl === ''" class="avatar">
                      <i class="el-icon-video-camera"></i>
                    </div>
                    <div class="avatar-mask">
                      <p @click="onClickUploadVideo(index, idx)">上传视频</p>
                    </div>
                  </div>
                  <!-- 视频封面 -->
                  <div v-if="generateCover == 0 || itm.videoImageUrl" class="creative-video-material-item-right">
                    <el-image v-if="itm.videoImageUrl" :src="itm.localImageUrl" fit="contain"></el-image>
                    <template v-if="generateCover == 0">
                      <div v-if="!itm.videoImageUrl" class="avatar">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                      <div class="avatar-mask">
                        <p @click="onClickUploadImage(index, idx, 'video')">上传封面</p>
                      </div>
                    </template>
                  </div>
                </div>
                <div v-if="item.videos.length < videoSize" class="creative-video-material-item-add"
                  @click="onClickAddVideo(index)">+</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--底部按钮-->
    <div class="dialog-footer">
      <el-button class="btn" @click="closeModal">取 消</el-button>
      <el-button class="btn" type="primary" :loading="isLoading" :disabled="!creativeMedias.length"
        @click="clickConfirm">确 定</el-button>
    </div>

    <image-select-dialog ref="imageSelectDialog" :project-list="projectList" :advertisers="advertisers"
      @confirm="handleSelectImage">
    </image-select-dialog>
    <video-select-dialog ref="videoSelectDialog" :project-list="projectList" :advertisers="advertisers"
      @confirm="handleSelectVideo">
    </video-select-dialog>
  </el-drawer>
</template>

<script>
import ImageSelectDialog from './ImageSelectDialog'
import VideoSelectDialog from './VideoSelectDialog'

import { saveCreativeMaterial } from '@/network/api/api-huge-batch'
export default {
  name: 'MaterialSelectDialog',
  components: {
    'image-select-dialog': ImageSelectDialog,
    'video-select-dialog': VideoSelectDialog
  },
  filters: {},
  props: {
    baseId: {
      type: [Number, String],
      default: 0
    },
    projectList: {
      type: Array,
      default: () => []
    },
    advertisers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      show: false, // 是否显示弹框
      isLoading: false,
      id: '',
      maxSelect: 0,
      imageSize: 0, // 配置图片数量
      videoSize: 1, // 配置视频数量
      copy_imageSize: 0,
      copy_videoSize: 1,
      generateCover: 1, // 是否开启自动生成封面 1开启 0关闭
      creativeMedias: []
    }
  },
  computed: {},
  watch: {},
  created() { },
  mounted() { },
  methods: {
    /* --------------------------- Actions --------------------------- */
    // 显示弹框
    showModal(creativeMedias, maxSelect) {
      this.maxSelect = maxSelect
      this.copy_imageSize = this.imageSize = creativeMedias.imageSize
      this.copy_videoSize = this.videoSize = creativeMedias.videoSize
      this.generateCover = creativeMedias.generateCover
      this.id = creativeMedias.id || ''
      let groups = creativeMedias.groups || []
      let arr = groups.map(v => {
        return {
          activeMenu: 2,
          images: [...v.images],
          videos: [...v.videos]
        }
      })
      this.creativeMedias = arr
      this.show = true
    },
    // 关闭弹框
    closeModal() {
      this.show = false
    },
    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      // 重置表单
      this.isLoading = false
      this.imageSize = 0
      this.videoSize = 1
      this.generateCover = 1
      this.creativeMedias = []
    },

    /* --------------------------- 按钮点击 -------------------------- */
    handleSizeChange(tag) {
      if (this.creativeMedias.length) {
        this.$confirm('创意配置信息变更，创意组信息将清空<p>确定要变更吗？</p>', '创意配置信息变更提示', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          // 点击确定
          this.creativeMedias = []
          this['copy_' + tag] = this[tag]
        }).catch(() => {
          this[tag] = this['copy_' + tag]
        })
      }
    },
    // 点击添加素材组
    handleAddGroup() {
      this.creativeMedias.push({
        activeMenu: 2, // 当前激活的菜单
        images: [],
        videos: []
      })
    },

    // 点击清空素材组
    handleEmpty() {
      this.creativeMedias = []
    },
    /* --------------------------- 按钮点击 -------------------------- */

    // 点击确定按钮
    clickConfirm() {
      if (this._validate()) {
        let medias = this.creativeMedias.map((v, i) => {
          return {
            groupName: `创意组${i + 1}`,
            groupNo: i + 1,
            images: v.images,
            videos: v.videos
          }
        })
        let data = {
          id: this.id,
          baseId: this.baseId,
          imageSize: this.imageSize,
          videoSize: this.videoSize,
          generateCover: this.generateCover,
          groups: medias
        }
        this.isLoading = true
        saveCreativeMaterial(data).then(res => {
          if (res.code === 200) {
            this.$emit('confirm', res.data)
            this.closeModal()
          }
        }).finally(() => {
          this.isLoading = false
        })
      }
    },

    // 点击添加图片
    onClickAddImage(index) {
      this.creativeMedias[index].images.push({
        imageUrl: '',
        imageId: '',
        imageMode: '',
        localImageUrl: '',
        imageName: '',
        type: 'image'
      })
    },
    // 点击添加视频
    onClickAddVideo(index) {
      this.creativeMedias[index].videos.push({
        videoImageUrl: '',
        videoImageId: '',
        videoUrl: '',
        videoId: '',
        videoImageMode: '',
        localVideoUrl: '',
        localImageUrl: '',
        videoName: '',
        type: 'video'
      })
    },
    // 选择tags
    onClickTag(index, i) {
      this.creativeMedias[index].activeMenu = i
    },

    // 删除某个素材组
    onClickDelGroup(index) {
      this.creativeMedias.splice(index, 1)
    },
    // 删除图片
    onClickDelImage(index, i) {
      this.creativeMedias[index].images.splice(i, 1)
    },
    // 删除视频
    onClickDelVideo(index, i) {
      this.creativeMedias[index].videos.splice(i, 1)
    },
    /* --------------------------- 图片弹窗 --------------------------- */
    // 点击上传图片
    onClickUploadImage(groupIndex, imageIndex, type) {
      let limitNum = 1
      if (type === 'image') {
        let arr = this.creativeMedias[groupIndex].images.filter(v => v.imageId)
        let len = arr.length || 0
        limitNum = this.imageSize - len
      }
      this.$refs.imageSelectDialog.showModal(groupIndex, imageIndex, type, limitNum)
    },
    // 上传图片弹窗的回调
    handleSelectImage(row, _data) {
      if (row.type === 'image') {
        // 整合数据字段
        let data = _data.map(v => {
          return {
            imageUrl: v.hugeImageUrl,
            imageId: v.hugeImageId,
            imageMode: v.imageMode,
            localImageUrl: v.localImageUrl,
            imageName: v.materialName,
            type: 'image'
          }
        })

        this.creativeMedias[row.groupIndex].images.splice(row.imageIndex, 1)

        let coverNum = 0 // 总填充数据数量
        let source = [...data] // 填充数据源
        for (let i = row.groupIndex; i < this.creativeMedias.length; i++) {
          let arr = this.creativeMedias[i].images.filter(v => v.imageId)
          let cover = this.imageSize - arr.length
          coverNum += cover
          let res = source.slice(0, cover)
          source = source.slice(cover)
          let video = this.creativeMedias[i].images.concat(res)
          video = video.filter(v => v.imageId)
          this.creativeMedias[i].images = video
        }

        if (data.length > coverNum) {
          let arr = data.slice(coverNum)
          let res = this.splitArray(arr, this.imageSize)
          for (let i = 0; i < res.length; i++) {
            this.creativeMedias.push({
              activeMenu: 1, // 当前激活的菜单
              images: res[i],
              videos: []
            })
          }
        }
      } else {
        let item = _data[0]
        this.$set(this.creativeMedias[row.groupIndex].videos[row.imageIndex], 'videoImageUrl', item.hugeImageUrl)
        this.$set(this.creativeMedias[row.groupIndex].videos[row.imageIndex], 'videoImageId', item.hugeImageId)
        this.$set(this.creativeMedias[row.groupIndex].videos[row.imageIndex], 'videoImageMode', item.imageMode)
        this.$set(this.creativeMedias[row.groupIndex].videos[row.imageIndex], 'localImageUrl', item.localImageUrl)
      }
      // if (row.type === 'image') {
      //   data.map((v, imageIndex) => {
      //     if (imageIndex > this.creativeMedias[row.groupIndex].images.length - 1) {
      //       this.onClickAddImage(row.groupIndex)
      //     }
      //     let startIndex = imageIndex + row.imageIndex
      //     setTimeout(() => {
      //       this.$set(this.creativeMedias[row.groupIndex].images[startIndex], 'imageUrl', v.hugeImageUrl)
      //       this.$set(this.creativeMedias[row.groupIndex].images[startIndex], 'imageId', v.hugeImageId)
      //       this.$set(this.creativeMedias[row.groupIndex].images[startIndex], 'imageMode', v.imageMode)
      //       this.$set(this.creativeMedias[row.groupIndex].images[startIndex], 'localImageUrl', v.localImageUrl)
      //     }, 100)
      //   })
      // } else {
      //   let item = data[0]
      //   this.$set(this.creativeMedias[row.groupIndex].videos[row.imageIndex], 'videoImageUrl', item.hugeImageUrl)
      //   this.$set(this.creativeMedias[row.groupIndex].videos[row.imageIndex], 'videoImageId', item.hugeImageId)
      //   this.$set(this.creativeMedias[row.groupIndex].videos[row.imageIndex], 'videoImageMode', item.imageMode)
      //   this.$set(this.creativeMedias[row.groupIndex].videos[row.imageIndex], 'localImageUrl', item.localImageUrl)
      // }
    },
    // 校验是否全部有图片或者视频
    _validate() {
      if (this.creativeMedias.length > this.maxSelect) {
        this.$WarningMessage(`最多添加${this.maxSelect}个创意组`)
        return false
      }
      for (let i = 0; i < this.creativeMedias.length; i++) {
        if (this.imageSize > 0 && this.creativeMedias[i].images.length === 0) {
          this.$WarningMessage(`请选择第${i + 1}个素材组中的图片素材`)
          return false
        } else {
          for (let j = 0; j < this.creativeMedias[i].images.length; j++) {
            if (!this.creativeMedias[i].images[j].imageUrl) {
              this.$WarningMessage(`请选择第${i + 1}个素材组中第${j + 1}图片素材`)
              return false
            }
          }
        }
        if (this.videoSize > 0 && this.creativeMedias[i].videos.length === 0) {
          this.$WarningMessage(`请选择第${i + 1}个素材组中的视频素材`)
          return false
        } else {
          for (let j = 0; j < this.creativeMedias[i].videos.length; j++) {
            if (!this.creativeMedias[i].videos[j].videoImageUrl) {
              this.$WarningMessage(`请选择第${i + 1}个素材组中第${j + 1}封面图片素材`)
              return false
            }
            if (!this.creativeMedias[i].videos[j].videoUrl) {
              this.$WarningMessage(`请选择第${i + 1}个素材组中第${j + 1}视频素材`)
              return false
            }
          }
        }
      }
      return true
    },
    /* --------------------------- 视频弹窗 --------------------------- */
    // 点击上传视频
    onClickUploadVideo(groupIndex, videoIndex) {
      this.$refs.videoSelectDialog.showModal(groupIndex, videoIndex)
    },
    // 上传视频弹窗的回调
    handleSelectVideo(row, _data) {
      // 整合数据字段
      let data = _data.map(v => {
        return {
          videoImageUrl: v.hugeImageUrl,
          videoImageId: v.hugeImageId,
          videoUrl: v.hugeVideoUrl,
          videoId: v.hugeVideoId,
          videoImageMode: v.imageMode,
          localVideoUrl: v.localVideoUrl,
          localImageUrl: v.localImageUrl,
          videoName: v.materialName,
          type: 'video'
        }
      })

      this.creativeMedias[row.groupIndex].videos.splice(row.videoIndex, 1)
      let coverNum = 0 // 总填充数据数量
      let source = [...data] // 填充数据源
      for (let i = row.groupIndex; i < this.creativeMedias.length; i++) {
        let arr = this.creativeMedias[i].videos.filter(v => v.videoId)
        let cover = this.videoSize - arr.length
        coverNum += cover
        let res = source.slice(0, cover)
        source = source.slice(cover)
        let video = this.creativeMedias[i].videos.concat(res)
        video = video.filter(v => v.videoId)
        this.creativeMedias[i].videos = video
      }

      if (data.length > coverNum) {
        let arr = data.slice(coverNum)
        let res = this.splitArray(arr, this.videoSize)
        for (let i = 0; i < res.length; i++) {
          this.creativeMedias.push({
            activeMenu: 2, // 当前激活的菜单
            images: [],
            videos: res[i]
          })
        }
      }
      // currentGroup.map((v, videoIndex) => {
      //   if (videoIndex > this.creativeMedias[row.groupIndex].videos.length - 1) {
      //     this.onClickAddVideo(row.groupIndex)
      //   }
      //   // 填充添加的创意组视频
      //   let startIndex = videoIndex + row.videoIndex
      //   this.$set(this.creativeMedias[row.groupIndex].videos[startIndex], 'videoImageUrl', v.hugeImageUrl)
      //   this.$set(this.creativeMedias[row.groupIndex].videos[startIndex], 'videoId', v.hugeVideoId)
      //   this.$set(this.creativeMedias[row.groupIndex].videos[startIndex], 'videoImageMode', v.imageMode)
      //   this.$set(this.creativeMedias[row.groupIndex].videos[startIndex], 'videoUrl', v.hugeVideoUrl)
      //   this.$set(this.creativeMedias[row.groupIndex].videos[startIndex], 'videoImageId', v.hugeImageId)
      //   this.$set(this.creativeMedias[row.groupIndex].videos[startIndex], 'localVideoUrl', v.localVideoUrl)
      //   this.$set(this.creativeMedias[row.groupIndex].videos[startIndex], 'localImageUrl', v.localImageUrl)
      // })
    },

    splitArray(arr, n) {
      if (!arr.length || !n || n < 1) {
        return []
      }
      let [start, end, result] = [null, null, []]
      for (let i = 0; i < Math.ceil(arr.length / n); i++) {
        start = i * n
        end = start + n
        result.push(arr.slice(start, end))
      }
      return result
    }
    /* --------------------------- Private --------------------------- */
  }
}
</script>

<style scoped lang="scss">
.dialog {
  position: fixed;
  top: 64px;
  right: 0;
  bottom: 0;
  z-index: 2033;
  display: flex;
  flex-direction: column;
  background-color: #f0f2f5;
  box-shadow: -8px 0 8px rgb(0 0 0 / 20%);

  .drawer-content {
    height: calc(100vh - 212px);
    padding: 16px;
    margin: 16px;
    overflow: auto;
    background: #fff;
    border: 1px solid #e8eaec;
    border-radius: 3px;
  }

  .dialog-footer {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    text-align: right;
    padding: 15px 20px;
  }

  .v-modal {
    z-index: 0;
  }

  .tip {
    font-size: 10px;
  }

  .group-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    padding-right: 16px;
    padding-left: 16px;
    margin-bottom: 16px;
    box-sizing: border-box;
    border: 1px solid #dcdfe6;
    border-radius: 2px;

    .group-label {
      font-size: 14px;
      font-weight: 700;
      color: #333;
    }

    .group-count {
      margin-left: 16px;
      font-size: 12px;
      color: #666;
    }
  }

  .creative {
    // height: 500px;
    // overflow-y: auto;

    .creative-content {
      margin-bottom: 10px;

      .creative-group {
        position: relative;
        line-height: 32px;
        border: 1px solid #dcdfe6;
        border-radius: 2px;
        box-sizing: border-box;

        .creative-group-header {
          background-color: #fafafa;
          padding: 8px 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .creative-group-name {
            color: #333;
            font-size: 12px;
            font-weight: 500;
          }

          img {
            width: 30px;
            height: 30px;
          }
        }

        .creative-group-body {
          position: relative;
          border-top: 1px solid #dcdfe6;
          padding: 0 10px 10px;
        }

        .creative-tabs {
          display: flex;
          font-size: 12px;
          height: 40px;
          line-height: 40px;
          margin-bottom: 15px;

          .tag {
            margin: 0 15px;
            cursor: pointer;
          }

          .active {
            color: #409eff;
            border-bottom: solid 2px #409eff;
          }
        }

        .creative-image-material {
          width: 100%;
          display: flex;
          flex-flow: wrap row;

          .creative-image-material-item {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 150px;
            height: 150px;
            font-size: 24px;
            border: 1px dashed #dcdfe6;
            margin: 5px;

            img {
              width: 100%;
              height: 100%;
            }

            .avatar {
              width: 100%;
              height: 100%;
              font-size: 40px;
              color: #8c939d;
              background-color: #c0c4cc;
              text-align: center;
              padding-top: 45px;
            }

            .avatar-mask {
              width: 100px;
              height: 100px;
              position: absolute;
              display: none;

              p {
                color: #fff;
                padding: 5px;
                margin-top: 30px;
                background-color: #409eff;
                font-size: 14px;
                border-radius: 4px;
                text-align: center;
                cursor: pointer;
              }
            }

            &:hover .avatar-mask {
              display: block;
            }

            .el-icon-circle-close {
              position: absolute;
              top: 5px;
              right: 5px;
              z-index: 1000;
              color: #8c939d;
              font-size: 20px;
              cursor: pointer;
            }
          }

          .creative-image-material-item-add {
            margin: 5px;
            display: flex;
            width: 150px;
            height: 150px;
            font-size: 40px;
            font-weight: 300;
            color: #8c939d;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border: 1px dashed #dcdfe6;
            box-sizing: border-box;
          }
        }

        .creative-video-material {
          width: 100%;
          display: flex;
          flex-flow: wrap row;

          .creative-video-material-item {
            position: relative;
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 460px;
            height: 150px;
            font-size: 24px;
            border: 1px dashed #dcdfe6;
            margin: 5px;
            padding-right: 10px;

            .el-icon-circle-close {
              position: absolute;
              top: 5px;
              right: 5px;
              z-index: 1000;
              color: #8c939d;
              font-size: 20px;
              cursor: pointer;
            }

            .creative-video-material-item-left {
              position: relative;
              width: 180px;
              height: 140px;
              margin-right: 10px;
              border: 1px solid #ccc;

              video {
                width: 100%;
                height: 100%;
              }

              .avatar {
                width: 100%;
                height: 100%;
                font-size: 40px;
                color: #8c939d;
                background-color: #c0c4cc;
                text-align: center;
                padding-top: 45px;
              }

              .avatar-mask {
                width: 100px;
                height: 100px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: none;

                p {
                  margin-top: 30px;
                  color: #fff;
                  padding: 5px;
                  background-color: #409eff;
                  border-radius: 4px;
                  font-size: 14px;
                  text-align: center;
                  cursor: pointer;
                }
              }

              &:hover .avatar-mask {
                display: block;
              }
            }

            .creative-video-material-item-right {
              position: relative;
              width: 180px;
              height: 140px;
              border: 1px solid #dcdfe6;
              display: flex;
              justify-content: center;

              img {
                width: 100%;
                height: 100%;
              }

              .avatar {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #8c939d;
              }

              .avatar-mask {
                width: 100px;
                height: 100px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: none;

                p {
                  color: #fff;
                  padding: 5px;
                  margin-top: 30px;
                  background-color: #409eff;
                  font-size: 14px;
                  border-radius: 4px;
                  text-align: center;
                  cursor: pointer;
                }
              }

              &:hover .avatar-mask {
                display: block;
              }
            }
          }

          .creative-video-material-item-add {
            margin: 5px;
            display: flex;
            width: 150px;
            height: 150px;
            font-size: 40px;
            font-weight: 300;
            color: #8c939d;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border: 1px dashed #dcdfe6;
            box-sizing: border-box;
          }
        }
      }
    }
  }
}
</style>
