<template>
  <el-dialog :title="isEdit ? '编 辑' : '新 增'" width="45%" :visible.sync="visible" :before-close="close" label-position="right" :modal-append-to-body="false" :close-on-press-escape="false" :close-on-click-modal="false">
    <el-form ref="ent" :model="ent" :rules="rules" label-width="110px">
      <el-form-item label="名称" prop="landingPageName">
        <el-input v-model="ent.landingPageName" placeholder="请输入名称"></el-input>
      </el-form-item>
      <el-form-item label="类型" prop="landingPageType">
        <el-select v-model="ent.landingPageType" placeholder="请选择类型" style="width: 100%">
          <el-option label="橙子建站" value="橙子建站" />
          <el-option label="第三方落地页" value="第三方落地页" />
        </el-select>
      </el-form-item>
      <el-form-item label="url" prop="landingPageUrl">
        <el-input v-model="ent.landingPageUrl"></el-input>
      </el-form-item>
      <el-form-item label="逻辑删除" prop="deleted">
        <el-input v-model="ent.deleted"></el-input>
      </el-form-item>
      <el-form-item label="创建者" prop="createBy">
        <el-input v-model="ent.createBy"></el-input>
      </el-form-item>
      <el-form-item label="创建时间" prop="createTime">
        <el-input v-model="ent.createTime"></el-input>
      </el-form-item>
      <el-form-item label="更新者" prop="updateBy">
        <el-input v-model="ent.updateBy"></el-input>
      </el-form-item>
      <el-form-item label="更新时间" prop="updateTime">
        <el-input v-model="ent.updateTime"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="ent.remark"></el-input>
      </el-form-item>
      <el-form-item label="url_id" prop="landingPageUrlId">
        <el-input v-model="ent.landingPageUrlId"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button round @click="close">取消</el-button>
      <el-button type="primary" round @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { Add, Update } from '@/network/api/assetManagement/assetManagementHugeLandingPageTemp'
import { AssetManagementHugeLandingPageTempEnt, formRules } from './object'

export default {
  inject: ['loadData'],
  data() {
    return {
      ent: new AssetManagementHugeLandingPageTempEnt(),
      rules: formRules,
      isEdit: false,
      visible: false,
      cityList: []
    }
  },
  methods: {
    submit() {
      this.$refs.ent.validate((validate) => {
        if (validate) {
          this.isEdit ? this.updateRow() : this.addRow()
        }
      })
    },
    addRow() {
      let req = { ...this.ent }
      Add(req).then((res) => {
        this.$message({
          message: '保存成功',
          type: 'success'
        })
        this.loadData()
        this.close()
      })
    },
    updateRow() {
      let req = { ...this.ent }
      Update(req).then((res) => {
        this.$message({
          message: '修改成功',
          type: 'success'
        })
        this.loadData()
        this.close()
      })
    },
    close() {
      this.visible = false
      this.$nextTick(() => {
        this.$refs.ent.resetFields()
      })
    },
    show(isEdit = false, ent) {
      this.ent = ent
      this.isEdit = isEdit
      this.visible = true
      this.$nextTick(() => {
        if (this.isEdit) {
          console.log('不能为空')
        }
      })
    }
  }
}
</script>
