<template>
  <!--产品评价弹框-->
  <el-dialog
    class="product-evaluation-dialog"
    title="产品评价"
    :visible.sync="show"
    :append-to-body="true"
    width="550px"
    top="20vh"
    @close="onCloseDialog"
  >
    <div class="page-container">
      <div
        v-if="commentList.length"
        class="comment-list"
      >
        <div
          v-for="item in commentList"
          :key="item.id"
          class="card"
        >
          <div class="title">
            <span class="left">{{ item.toolProductLaunchPerson.userName }}</span>
            <span class="right">{{ item.toolProductComment.createTime }}</span>
          </div>
          <div class="content">
            {{ item.toolProductComment.commentContent }}
          </div>
        </div>
      </div>
      <div v-else>
        <el-empty description="暂无评价"></el-empty>
      </div>
      <el-input
        v-model="textarea"
        type="textarea"
        placeholder="请输入评论"
        maxlength="100"
        show-word-limit
        :rows="4"
      >
      </el-input>
    </div>

    <!--底部按钮-->
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        class="btn"
        @click="closeModal"
      >取消</el-button>
      <el-button
        class="btn"
        type="primary"
        :disabled="loading"
        @click="handleConfirm"
      >发布</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { commentList, addCommentList } from '@/network/api/toolManagement/api-product-test'

export default {
  name: 'ProductEvaluationDialog',
  data() {
    return {
      show: false,
      loading: false,
      id: '',
      textarea: '',
      commentList: []
    }
  },
  methods: {
    /* --------------------------- Private --------------------------- */
    // 获取评论列表
    _getCommentList () {
      commentList(this.id, 1, 1000).then(res => {
        if (res.code === 200) {
          this.commentList = res.data.records || []
        }
      })
    },

    // 获取当前时间
    _getCurrentTime() {
      var date = new Date()// 当前时间
      var year = date.getFullYear() // 返回指定日期的年份
      var month = this.repair(date.getMonth() + 1)// 月
      var day = this.repair(date.getDate())// 日
      var hour = this.repair(date.getHours())// 时
      var minute = this.repair(date.getMinutes())// 分
      var second = this.repair(date.getSeconds())// 秒

      // 当前时间
      var curTime = year + '-' + month + '-' + day +
            ' ' + hour + ':' + minute + ':' + second
      return curTime
    },

    repair(i) {
      if (i >= 0 && i <= 9) {
        return '0' + i
      } else {
        return i
      }
    },

    /* --------------------------- Actions --------------------------- */
    showModal(id) {
      this.id = id
      this._getCommentList()
      this.show = true
    },

    // 点击发布
    handleConfirm() {
      if (!this.textarea) {
        this.$message.error('请输入评价')
        return
      }

      let params = {
        lists: [
          {
            commentContent: this.textarea,
            createTime: this._getCurrentTime()
          }
        ],
        toolProductId: this.id
      }

      this.loading = true
      addCommentList(params).then(({ code }) => {
        if (code === 200) {
          this.$message.success('添加评论成功')
          this.textarea = ''
          this.$emit('confirm')
          this._getCommentList()
        }
      }).finally(() => {
        this.loading = false
      })
    },

    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      this.id = ''
      this.textarea = ''
      this.commentList = []
    },

    // 关闭弹框
    closeModal() {
      this.show = false
    }
  }
}
</script>

<style scoped lang="scss">
.comment-list {
  width: 100%;
  height: 300px;
  margin-bottom: 10px;
  overflow-y: auto;
  border-bottom: 1px dashed #ccc;

  .card {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;

    .title {
      margin-bottom: 10px;

      .left {
        font-size: 16px;
        color: #000000;
        margin-right: 20px;
      }

      .right {
        color: #989898;
      }
    }

    .content {
      //超出两行省略号
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      line-clamp: 2;
      -webkit-line-clamp: 2; //显示几行
    }
  }
}
</style>
