var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("img", {
    staticClass: "icon-img",
    style: { width: _vm.width },
    attrs: { src: require(`@/assets/image/icon/${_vm.icon}.png`) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }