
<template>
  <!--关键字弹框-->
  <el-dialog class="table-template" :title="'关键词'" :visible.sync="show" :append-to-body="true" width="800px" top="20vh"
    @close="onCloseDialog">
    <div class="page-container">
      <el-form ref="keywordForm" :inline="false" :model="keywordForm" :rules="rules" label-width="80px">
        <el-form-item label="关键字" prop="word">
          <el-input v-model="keywordForm.word" placeholder="请输入关键字" style="width: 300px;"></el-input>
        </el-form-item>

        <el-form-item label="匹配方式" prop="matchType">
          <el-select v-model="keywordForm.matchType" style="width: 300px;">
            <el-option v-for="item in matchTypes" :label="item.value" :value="item.key" :key="item.key"></el-option>
          </el-select>
          <el-button type="primary" style="position: absolute; right: 0" :loading="saveLoading"
            @click="handleAddKeyword">添加</el-button>
        </el-form-item>
      </el-form>
      <el-divider></el-divider>
      <div style="text-align:right">
        <el-button type="primary" plain size="mini" @click="onClickUpload">批量上传</el-button>
      </div>
      <el-table :data="sortTableData" v-loading="loading">
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column label="关键词" align="center">
          <span slot-scope="{row}">{{ row.word }}</span>
        </el-table-column>
        <el-table-column label="匹配方式" align="center">
          <template slot-scope="{row}">
            <span>{{ row.matchType | filterMatchType }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="{row, $index}">
            <el-button type="text" size="mini" @click="handleDelete(row, $index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page">
        <paginations :total="tableData.length" :page.sync="pageNum" :page-sizes="[5, 10, 15, 20]" :limit.sync="pageSize"
          @pagination="getSortTable" />
      </div>
    </div>
    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">取 消</el-button>
      <el-button class="btn" type="primary" :disabled="isLoading" @click="clickConfirm">确 定</el-button>
    </div>
    <!-- 上传文件弹框 -->
    <upload-file-dialog ref="uploadDialog" @upload="handleUpload"></upload-file-dialog>
  </el-dialog>
</template>

<script>
import { uploadKeywords } from '@/network/api/api-huge-batch'
import UploadFileDialog from './UploadFileDialog'
export default {
  name: 'EditKeywordDialog',
  components: {
    UploadFileDialog
  },
  filters: {
    filterMatchType(value) {
      let types = { 'PRECISION': '精准', 'EXTENSIVE': '广泛', 'PHRASE': '短语' }
      return types[value] || ''
    }
  },
  props: {},
  data() {
    return {
      show: false,
      loading: false,
      isLoading: false,
      saveLoading: false,
      keywordForm: {
        word: '', // 关键字
        matchType: 'PRECISION' // 匹配方式
      },
      rules: {
        word: [{ required: true, message: '请输入关键字', trigger: 'blur' }],
        matchType: [{ required: true, message: '请选择匹配方式', trigger: 'change' }]
      },
      tableData: [],
      sortTableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 5,
      matchTypes: [
        { key: 'PRECISION', value: '精准' },
        { key: 'EXTENSIVE', value: '广泛' },
        { key: 'PHRASE', value: '短语' }
      ]
    }
  },
  computed: {
  },
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {
    /* --------------------------- Actions --------------------------- */
    showModal(keywords) {
      this.show = true
      this.tableData = [...keywords]
      this.getSortTable()
    },
    // 点击确定按钮
    clickConfirm() {
      this.$emit('confirm', this.tableData)
      this.closeModal()
    },
    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      // 重置表单
      this.tableData = []
      this.keywordForm = {
        word: '',
        matchType: 'PRECISION'
      }
      this.$refs['keywordForm'].clearValidate()
    },
    // 关闭弹框
    closeModal() {
      this.show = false
    },

    getSortTable() {
      this.sortTableData = this.tableData.slice((this.pageNum - 1) * this.pageSize, this.pageNum * this.pageSize)
    },

    // 点击添加关键字
    handleAddKeyword() {
      this.$refs.keywordForm.validate(valid => {
        if (valid) {
          let data = this.keywordForm
          let item = this.tableData.find(item => item.word === data.word)
          if (item) {
            this.$message.error('关键字名称重复')
            return
          }
          this.tableData.unshift({ word: data.word, matchType: data.matchType })
          this.getSortTable()
          this.keywordForm.word = ''
        }
      })
    },

    // 点击删除
    handleDelete(row, index) {
      this.tableData.splice(index, 1)
      this.getSortTable()
    },

    // 点击上传
    onClickUpload() {
      this.$refs.uploadDialog.showModal()
    },

    // 上传文件回调
    handleUpload(e) {
      uploadKeywords(e).then(res => {
        if (res.code === 200) {
          this.tableData = res.data
          this.getSortTable()
        }
      })
    }
    /* --------------------------- Private --------------------------- */
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/style/variables";
@import "~@/assets/style/mixin";

.table-template {
  .copy-type {
    margin-bottom: 10px;
    width: 150px;
    box-sizing: border-box;
    display: flex;
    color: #909399;
    font-size: 16px;
    font-weight: 700;
    border-bottom: 1px solid #909399;

    .copy-type-item {
      margin-right: 15px;
      padding: 5px;
      cursor: pointer;
    }

    .isSelect {
      color: rgb(52, 92, 224);
      border-bottom: 2px solid rgb(52, 92, 224);
    }
  }

  .search-form-container {
    .el-form {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .flex-btn {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .left {
      margin-right: 10px;
    }
  }

  .page-container {
    margin-bottom: 50px;

    .btn-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;

      .operation-btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .table-info {
          padding: 10px 0;
          color: #333A3F;
          font-size: 16px;
          margin-left: 12px;

          .number {
            color: #2842C8;
            font-size: 24px;
            margin: 0 5px;
          }
        }

        .el-button--small {
          padding: 6px 13px;
        }

        .btn-content {
          @include flex-center;
          height: 18px;

          .icon-container {
            @include flex-center;
            width: 18px;
            height: 18px;
            border-radius: 9px;
            // background: #1667FF;
            margin-right: 6px;

            img {
              width: 12px;
              height: 12px;
            }
          }
        }
      }

      .function-btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .function-btn {
          @include flex-center;
          width: 26px;
          height: 20px;
          background: white;
          transition: 0.2s;
          margin-left: 10px;
          position: relative;
          cursor: pointer;

          .table-columns-container {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 10;
            width: 150px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 2px 5px 0px rgba(229, 228, 234, 1);
            border-radius: 4px;
            padding-left: 10px;
            overflow: hidden;
            display: none;

            .table-columns-item {
              height: 30px;
              line-height: 30px;
            }
          }

          &:hover {
            background: #F2F2F2;

            .table-columns-container {
              display: block;
            }
          }

          img {
            width: 18px;
            height: 18px;
          }
        }
      }
    }

    .table-select-tip {
      height: 38px;
      padding-left: 17px;
      display: flex;
      align-items: center;
      color: #909399;
      font-size: 14px;
      background: #DBE9FF;
      border: 1px solid #4788FF;
      border-radius: 4px;

      img {
        width: 16px;
        height: 16px;
      }
    }

    .table-link {
      color: #606266;
      transition: 0.2s;
      text-decoration: none;

      &:hover {
        color: $themeColor;
        text-decoration: underline;
      }
    }

    .poster-container {
      @include flex-center;
      width: 36px;
      height: 36px;
      overflow: hidden;

      .poster {
        width: 36px;
        min-height: 36px;
      }
    }

    .opt-container {
      display: flex;
      justify-content: space-around;

      .opt-text {
        font-size: 14px;
      }
    }

    .cell-btn {
      font-size: 0;

      img {
        width: 18px;
        height: 18px;
      }

      .cell-btn-hover {
        display: none;
      }

      &:hover {
        .cell-btn-icon {
          display: none;
        }

        .cell-btn-hover {
          display: block;
        }
      }
    }

    .pagination-container {
      height: 64px;
      padding-right: 50px;
      box-sizing: border-box;
    }
  }
}

::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}
</style>

