<template>
  <!--显示列弹框-->
  <el-dialog :visible.sync="show" :append-to-body="true" :title="dialogTitle" destroy-on-close width="680px" top="20vh"
    @close="onCloseDialog">
    <!--自定义 materialName-->
    <span slot="materialName" class="dialog-materialName">{{ dialogTitle }}</span>
    <el-form ref="form" :model="form" :rules="rules" label-width="110px" class="create-video-box">
      <!-- <el-form-item v-if="['2'].includes(addPosition)" label="素材名称" prop="materialName">
        <el-input v-model="form.materialName" placeholder="请输入素材名称"></el-input>
      </el-form-item> -->
      <el-form-item label="专辑" prop="albumId">
        <el-select v-model="form.albumId" filterable clearable placeholder="请选择专辑" style="width: 100%;"
          @change="handleAlbum">
          <el-option v-for="(itm, idx) in albumList" :key="idx" :label="itm.albumName" :value="itm.albumId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="素材组" prop="materialGroupId">
        <el-select v-model="form.materialGroupId" :disabled="!form.albumId" filterable clearable placeholder="请选择素材组"
          style="width: 100%;">
          <el-option v-for="(itm, idx) in materialGroupList" :key="idx" :label="itm.materialGroupName"
            :value="itm.materialGroupId"></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item v-if="['2'].includes(addPosition)" label="创意人" prop="creater">
        <el-select v-model="form.creater" value-key="key" filterable clearable placeholder="请选择投放人员" style="width: 100%;">
          <el-option v-for="(itm, idx) in userList" :key="idx" :label="itm.label" :value="itm"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="关键词" prop="keyword">
        <el-input v-model="form.keyword" placeholder="请输入图文标题"></el-input>
      </el-form-item>
      <el-form-item v-if="type === 'image'">
        <template slot="label">
          <div><span style="color: #F56C6C">*</span> 图片({{ images.length }}/25)</div>
        </template>
        <div class="form-image-list">
          <div v-for="(item, index) in images" :key="index">
            <div class="form-image-list-cell">
              <img :src="item.url" alt="" />
              <div class="form-image-list-cell-del" @click="onClickdelImage(index)">删除</div>
            </div>
          </div>
          <el-upload class="form-image-upload" action="fakeaction" list-type="picture-card"
            accept=".png,.jpg,.jpeg,.gif" :multiple="true" :show-file-list="false" :http-request="uploadImage">
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
        <span style="font-size: 12px;">支持格式：png、jpg、jpeg、gif</span>
      </el-form-item>
      <el-form-item v-if="type === 'video'">
        <template slot="label">
          <div><span style="color: #F56C6C">*</span> 视频({{ videos.length }}/20)</div>
        </template>
        <div class="form-image-list">
          <div v-for="(item, index) in videos" :key="index">
            <div class="form-image-list-cell">
              <el-progress v-show="item.status === 'uploading'" :text-inside="true" :stroke-width="14"
                style="margin:45px 10px 0 10px;" :percentage="item.progress"
                :status="item.progress ? 'success' : 'warning'"></el-progress>
              <video :src="item.url"></video>
              <div v-show="item.status !== 'uploading'" class="form-image-list-cell-del">
                <el-button type="primary" circle icon="el-icon-delete" @click="onClickdelVideo(index)"></el-button>
              </div>
            </div>
          </div>
          <el-upload v-if="videos.length < 20" id="uploa-video" class="form-image-upload" action="fakeaction"
            accept=".mp4,.ogg,.flv,.avi,.wmv,.rmvb,.mov" list-type="picture-card" :multiple="true" :limit="videoLimit"
            :show-file-list="false" :before-upload="beforeUploadVideo" :on-exceed="handleExceedVideo">
            <i class="el-icon-plus"></i>
          </el-upload>

          <!-- <div v-for="(item, index) in videos" :key="index">
            <div class="form-image-list-cell">
              <video :src="item.url"></video>
              <div v-show="item.status !== 'uploading'" class="form-image-list-cell-del">
                <el-button type="primary" circle icon="el-icon-delete" @click="onClickdelVideo(index)"></el-button>
              </div>
            </div>
          </div>
          <el-progress v-show="getIsShow()" :percentage="schedule" class="justify-center1" style="width: 150px"
            :status="schedule == 100 ? 'success' : 'warning'"></el-progress>
          <el-upload id="uploa-video" :disabled="getIsShow()" class="form-image-upload" action="fakeaction"
            list-type="picture-card" :before-upload="beforeUploadVideo" :multiple="false" :show-file-list="false"
            :http-request="uploadVideo">
            <i class="el-icon-plus"></i>
          </el-upload> -->
        </div>
        <!-- <div class="tips">格式：mp4、avi、mov</div> -->
        <!-- <span style="font-size: 12px;">支持格式：png、jpg、jpeg、gif、bmp，单张不超过10MB</span> -->
      </el-form-item>
    </el-form>

    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">取 消</el-button>
      <el-button class="btn" type="primary" :loading="isLoading || fileUploading" :disabled="isLoading" @click="clickConfirm('form')">确
        定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { uploadMaterial, getAlbumAllList, getMarterialGroupAllList, addImageMarterial, addVideoMarterial } from '@/network/api/api-material'
import { getMeRelationProject } from '@/network/api/basicSetting/project/basicSettingProject'
import SparkMD5 from 'spark-md5'
import { MessageBox } from 'element-ui'
import { mapGetters } from 'vuex'
import oss from '@/views/toolManagement/materialTool/videoTask/create/oss'
import { getUser } from '@/network/api/user'
export default {
  name: 'AddMaterialDialog',
  components: {},
  filters: {},
  mixins: [oss],

  props: {
    type: {
      type: String,
      default: ''
    },
    // 添加位置, 1代表在素材选择框里添加, 2代表在图片库里添加
    addPosition: {
      type: String,
      default: '2'
    }
  },
  data() {
    return {
      show: false, // 是否显示弹框
      dialogTitle: '',
      userList: [],
      materialGroupList: [],
      albumList: [],
      isLoading: false, // 是否正在加载
      images: [], // 上传的图片
      videos: [], // 上传的视频
      form: {
        keyword: '',
        albumId: '',
        materialName: '',
        materialGroupId: '',
        creater: {}
      }, // 表单数据
      rules: {
        materialGroupId: [{ required: true, message: '请选择素材组', trigger: 'blur' }],
        keyword: [{ required: true, message: '请输入关键字', trigger: 'blur' }],
        materialName: [{ required: true, message: '请输入素材名称', trigger: 'blur' }],
        albumId: [{ required: true, message: '请选择专辑', trigger: 'blur' }],
        creater: [{ required: true, message: '请输选择创意人', trigger: 'blur' }]
      } // 表单校验规则
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    videoLimit() {
      let num = 20 - this.videos.length
      return num >= 0 ? num : 0
    },
    fileUploading() {
      let item = this.videos.filter(v => v.status === 'uploading')
      return item.length > 0
    }
  },
  watch: {

  },
  created() { },
  mounted() { },
  methods: {
    /* --------------------------- Actions --------------------------- */
    // 显示弹框
    showModalTree(albumId, groupId) {
      this.show = true
      this.dialogTitle = '新增素材'
      this._getProjectTree(albumId)
      this._getMarterialGroupAllListTree(albumId, groupId)
      // this._getUserList()
    },
    showModal() {
      this.show = true
      this.dialogTitle = '新增素材'
      this._getProject()
      // this._getUserList()
    },
    // 专辑有改动的回调
    handleAlbum(e) {
      if (e !== '') {
        this.form.materialGroupId = ''
        this._getMarterialGroupAllList(e)
      }
    },

    // 关闭弹框
    closeModal() {
      this.show = false
    },
    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      // 重置表单
      this.$refs['form'].resetFields()

      // 清除数据
      this.form = {
        keyword: '',
        albumId: '',
        materialName: '',
        materialGroupId: '',
        creater: {}
      }
      this.userList = []
      this.materialGroupList = []
      this.images = []
      this.videos = []
      this.show = false
    },

    // 删除图片
    onClickdelImage(index) {
      this.images.splice(index, 1)
    },

    // 删除视频
    onClickdelVideo(index) {
      this.videos.splice(index, 1)
    },

    // 点击确定按钮
    clickConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.type === 'image' ? this._addImageMarterial() : this._addVideoMarterial()
        } else {
          this.$WarningMessage('请将表单填写完整')
        }
      })
    },

    // 图片上传
    uploadImage(params) {
      if (this.images.length > 25) {
        this.$message.error(`上传图片不能超过 25张!`)
        return
      }
      const file = params.file
      const fileType = file.type
      const isImage = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'].includes(fileType)
      // const isSize = file.size / 1024 > 1000
      if (!isImage) {
        MessageBox.alert('只能上传图片格式png、jpg、gif, jpeg!', '', {
          confirmButtonText: '确定',
          type: 'warning',
          showClose: false,
          center: true
        })
        return
      }
      // if (!isSize) {
      //   this.$message.error(`上传图片大小不能超过 1000M!`)
      //   return
      // }
      // 根据后台需求数据格式
      const form = new FormData()
      // 文件对象
      form.append('file', file)
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append('name', file.name)
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      // form.append('name', file.name)
      uploadMaterial(form)
        .then((res) => {
          this.images.push({
            originName: res.data.originName,
            width: res.data.width,
            height: res.data.height,
            size: res.data.size,
            url: res.data.url,
            hash: res.data.hashCode
          })
        })
        .catch(() => {
          MessageBox.alert('图片上传失败', '', {
            confirmButtonText: '确定',
            type: 'warning',
            showClose: false,
            center: true
          })
        })
        .finally(() => { })
    },

    // 上传前回调
    beforeUploadVideo(file) {
      if (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb', 'video/mov'].indexOf(file.type) === -1) {
        this.$WarningMessage('请上传正确的视频格式')
        return false
      }
      const fileList = {}
      for (const key in file) {
        fileList[key] = file[key]
      }

      this.videos.push({ ...fileList, progress: 0, status: 'uploading' })
      this.uploadVideo(file)
      return false
    },

    showProgress(file, params) {
      const arr = [...this.videos].map(items => {
        if (items.uid === file.uid) {
          items = { ...items, ...params }
        }
        return items
      })

      this.videos = [...arr]
    },

    // 文件超出限制
    handleExceedVideo() {
      this.$message.error(`上传视频不能超过${this.VIDEO_NUM}个!`)
    },
    // 视频上传(原始备份)
    uploadVideoBF(params) {
      if (this.videos.length > 20) {
        this.$message.error(`上传视频不能超过 20个!`)
        return
      }
      const file = params.file
      const form = new FormData()
      // 文件对象
      form.append('file', file)
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      // form.append('name', file.name)
      // 获取上传的视频的宽高
      let videoWidth = 0
      let videoHeight = 0
      const url = URL.createObjectURL(file)
      const video = document.createElement('video')
      video.onloadedmetadata = (evt) => {
        URL.revokeObjectURL(url)
        videoWidth = video.videoWidth
        videoHeight = video.videoHeight
        console.log(video.videoWidth, video.videoHeight)
      }
      video.src = url
      video.load()
      uploadMaterial(form)
        .then((res) => {
          this.videos.push({
            originName: res.data.originName,
            width: videoWidth,
            height: videoHeight,
            size: res.data.size,
            url: res.data.url,
            hash: res.data.hashCode
          })
        })
        .catch(() => {
          MessageBox.alert('视频上传失败', '', {
            confirmButtonText: '确定',
            type: 'warning',
            showClose: false,
            center: true
          })
        })
        .finally(() => { })
    },
    getFileHash(file) {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)
        reader.onload = ev => {
          let buffer = ev.target.result
          let spark = new SparkMD5.ArrayBuffer()
          let HASH
          let suffix
          spark.append(buffer)
          HASH = spark.end()
          suffix = file.name.substring(file.name.lastIndexOf('.') + 1)
          resolve({
            buffer,
            HASH,
            suffix,
            filename: `${HASH}.${suffix}`
          })
        }
      })
    },
    // 视频上传
    uploadVideo(file) {
      const form = new FormData()
      // 文件对象
      form.append('file', file)
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      // form.append('name', file.name)
      // 获取上传的视频的宽高
      let videoWidth = 0
      let videoHeight = 0
      const url = URL.createObjectURL(file)
      const video = document.createElement('video')

      video.onloadedmetadata = (evt) => {
        URL.revokeObjectURL(url)
        videoWidth = video.videoWidth
        videoHeight = video.videoHeight
        console.log('W-H-0')
        console.log(video.videoWidth, video.videoHeight)
      }
      video.onloadeddata = (evt) => {
        this.getFileHash(file).then((val) => {
          console.log(val)
          console.log('W-H-1')
          console.log(videoWidth)
          console.log(videoHeight)
          this.ossUpload(file, {
            width: videoWidth,
            size: file.size,
            hash: val.HASH,
            height: videoHeight,
            duration: video.duration
          }, (val) => {
            // if (val.width !== videoWidth) {
            //   val.width = videoWidth
            // }
            // if (val.height !== videoHeight) {
            //   val.height = videoHeight
            // }
            // this.videos.push(val)
          }, (er) => {
            this.$message.error(er)
          }, (p) => {
            this.showProgress(file, p)
          })
        })
      }
      video.src = url
      video.load()
      // 获取文件hash值以及后缀名
    },
    /* --------------------------- Private --------------------------- */
    // 获取成员列表
    _getUserList() {
      getUser().then((res) => {
        if (res.code === 200) {
          this.userList = res.data
        }
      })
    },
    // 获取项目列表(个人可见)
    _getProjectTree(albumId) {
      getMeRelationProject().then((res) => {
        if (res.code === 200) {
          this.projectList = res.data
          let ids = res.data.map((item) => {
            return item.bsProjectId
          })
          this._getAlbumAllListTree(ids, albumId)
        }
      })
    },
    _getProject() {
      getMeRelationProject().then((res) => {
        if (res.code === 200) {
          this.projectList = res.data
          let ids = res.data.map((item) => {
            return item.bsProjectId
          })
          this._getAlbumAllList(ids)
        }
      })
    },
    // 获取专辑列表
    _getAlbumAllListTree(ids, albumId) {
      let params = {
        type: this.type,
        projectIds: ids
      }
      getAlbumAllList(params).then((res) => {
        this.albumList = res.data
        this.form.albumId = albumId
      })
    },
    // 获取素材组列表
    _getMarterialGroupAllListTree(albumId, groupId) {
      let params = {
        type: this.type,
        albumId: albumId
      }
      getMarterialGroupAllList(params).then((res) => {
        this.materialGroupList = res.data
        this.form.materialGroupId = groupId
      })
    },
    // 获取专辑列表
    _getAlbumAllList(ids) {
      let params = {
        type: this.type,
        projectIds: ids
      }
      getAlbumAllList(params).then((res) => {
        this.albumList = res.data
      })
    },
    // 获取素材组列表
    _getMarterialGroupAllList(albumId) {
      let params = {
        type: this.type,
        albumId: albumId
      }
      getMarterialGroupAllList(params).then((res) => {
        this.materialGroupList = res.data
      })
    },
    // 新增图片素材
    _addImageMarterial() {
      this.isLoading = true
      if (this.images.length === 0) {
        this.$ErrorMessage('请选择图片')
        return
      }
      this.images.forEach((item) => {
        item.keyword = this.form.keyword
        item.albumId = this.form.albumId
        item.materialName = item.originName
        // item.materialName = this.addPosition === '1' ? item.originName : this.form.materialName
        item.materialGroupId = this.form.materialGroupId
        item.creatorId = this.addPosition === '1' ? this.userInfo.userid : this.form.creater.key
        item.creatorName = this.addPosition === '1' ? this.userInfo.username : this.form.creater.label
        item.adminId = this.userInfo.userid
        item.nickname = this.userInfo.username
      })
      let promiseList = []
      for (let i = 0; i < this.images.length; i++) {
        if (!this.images[i].url) {
          continue
        }
        promiseList.push(this.addImageMarterial(this.images[i]))
      }
      Promise.all(promiseList)
        .then((results) => {
          this.$emit('edit')
        })
        .catch(() => {
          this.$ErrorMessage('新增图片素材失败')
        })
        .finally(() => {
          this.isLoading = false
          this.closeModal()
        })
    },
    // 新增视频素材
    _addVideoMarterial() {
      this.isLoading = true
      if (this.videos.length === 0) {
        this.$ErrorMessage('请选择视频')
        return
      }
      this.videos.forEach((item) => {
        item.keyword = this.form.keyword
        item.albumId = this.form.albumId
        item.materialName = item.originName
        // item.materialName = this.addPosition === '1' ? item.originName : this.form.materialName
        item.materialGroupId = this.form.materialGroupId
        item.creatorId = this.addPosition === '1' ? this.userInfo.userid : this.form.creater.key
        item.creatorName = this.addPosition === '1' ? this.userInfo.username : this.form.creater.label
        item.adminId = this.userInfo.userid
        item.nickname = this.userInfo.username
        item.adminId = this.userInfo.userid
        item.nickname = this.userInfo.username
      })
      let promiseList = []
      for (let i = 0; i < this.videos.length; i++) {
        if (!this.videos[i].url) {
          continue
        }
        promiseList.push(this.addVideoMarterial(this.videos[i]))
      }
      Promise.all(promiseList)
        .then((results) => {
          this.$emit('edit')
          this.closeModal()
        })
        .catch(() => {
          this.$ErrorMessage('新增视频素材失败')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    // 新增单个图片
    addImageMarterial(item) {
      addImageMarterial(item).then((res) => {
        // console.log(res)
      })
    },
    // 新增单个视频
    addVideoMarterial(item) {
      addVideoMarterial(item).then((res) => {
        // console.log(res)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.justify-center1 {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

::v-deep .form-image-upload .el-upload--picture-card {
  position: relative;
  padding: 0 !important;
  width: 100px !important;
  height: 100px !important;
  margin-right: 3px !important;

  .el-icon-plus {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>

<style scoped lang="scss">
.form-image-list {
  display: flex;
  flex-flow: row wrap;

  .form-image-list-cell {
    position: relative;
    border: 1px solid #ccc;
    width: 100px;
    height: 100px;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 10px;
    overflow: hidden;

    // margin-left: 3px;
    // margin-top: 3px;
    img {
      width: 100px;
      height: 100px;
    }

    video {
      width: 100px;
      height: 100px;
    }

    .form-image-list-cell-del {
      background-color: rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: none;
      justify-content: center;
      align-items: center;

      // &:last-child {
      //   margin-right: 0;
      // }

      // &:hover {
      //   background: $themeColor;
      //   color: white;
      // }
    }

    &:hover {
      .form-image-list-cell-del {
        display: flex;
      }
    }
  }
}
</style>
