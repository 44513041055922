<template>
  <!--显示列弹框-->
  <el-dialog
    class="edit-info-dialog"
    :visible.sync="show"
    :modal-append-to-body="false"
    width="650px"
    @close="onCloseDialog"
  >
    <!--自定义 title-->
    <span slot="title" class="dialog-title">{{ title }}</span>

    <el-form
      ref="ruleForm"
      :model="formData"
      label-width="150px"
      :rules="rules"
      @submit.native.prevent
    >
      <!-- 计划名称 -->
      <el-form-item v-if="type === 1" label="计划名称" prop="name">
        <el-input
          v-model="formData.name"
          placeholder="请输入计划名称"
          clearable
        >
        </el-input>
      </el-form-item>

      <!-- 日预算 -->
      <el-form-item
        v-if="type === 2"
        class="title-label"
        label="日预算"
        prop="dailyBudget"
      >
        <el-radio v-model="formData.isDailyBudget" :label="0">不限</el-radio>
        <el-radio v-model="formData.isDailyBudget" :label="1">指定日预算</el-radio>
        <el-input
          v-if="formData.isDailyBudget"
          v-model="formData.dailyBudget"
          placeholder="请输入日预算"
          clearable
        >
        </el-input>
      </el-form-item>

      <!-- 总预算 -->
      <el-form-item
        v-if="type === 3"
        class="title-label"
        label="总预算"
        prop="generalBudget"
      >
        <el-radio v-model="formData.isGeneralBudget" :label="0">不限</el-radio>
        <el-radio v-model="formData.isGeneralBudget" :label="1">指定总预算</el-radio>
        <el-input
          v-if="formData.isGeneralBudget"
          v-model="formData.generalBudget"
          placeholder="请输入总预算"
          clearable
        >
        </el-input>
      </el-form-item>

      <!-- 广告名称 -->
      <el-form-item
        v-if="type === 4"
        class="title-label"
        label="广告名称"
        prop="advertisingName"
      >
        <el-input
          v-model="formData.advertisingName"
          placeholder="请输入广告名称"
          clearable
        >
        </el-input>
        <div class="form-tips">为保证唯一，系统会在上述名称后自动拼接随机编码</div>
      </el-form-item>

      <!-- 出价 -->
      <el-form-item
        v-if="type === 5"
        class="title-label"
        label="出价"
        prop="offer"
      >
        <el-input-number v-model="formData.offer" placeholder="请输入出价" :min="0.01" :max="5000" :precision="2" clearable>
        </el-input-number>
      </el-form-item>
    </el-form>

    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">取 消</el-button>
      <el-button class="btn" type="primary" @click="clickConfirm">确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>

export default {
  name: 'EditInfoDialog',
  data () {
    return {
      show: false, // 是否显示弹框
      loading: false,
      type: '', // 1 名称 2 日预算 3 总预算 4 广告名称 5 出价
      taskAccountId: 0,
      formData: {
        name: '',
        dailyBudget: '',
        isDailyBudget: 1,
        generalBudget: '',
        isGeneralBudget: 1,
        advertisingName: '',
        offer: ''
      },

      title: '',
      key: '',
      value: '',
      titleList: [
        {
          type: 1,
          name: '推广计划名称编辑'
        },
        {
          type: 2,
          name: '日预算编辑'
        },
        {
          type: 3,
          name: '总预算编辑'
        },
        {
          type: 4,
          name: '广告名称编辑',
          key: 'advertisingName'
        },
        {
          type: 5,
          name: '广告出价编辑',
          key: 'offer'
        }
      ],

      rules: {
        name: [{ required: true, message: '请输入计划名称', trigger: 'blur' }],
        dailyBudget: [{ required: true, message: '请输入日预算', trigger: 'blur' }],
        generalBudget: [{ required: true, message: '请输入总预算', trigger: 'blur' }],
        advertisingName: [{ required: true, message: '请输入广告名称', trigger: 'blur' }],
        offer: [{ required: true, message: '请输入出价', trigger: 'blur' }]
      }
    }
  },
  methods: {
    /* --------------------------- Actions --------------------------- */
    // 显示弹框
    showModal (type, taskAccountId, val) {
      this.type = type
      this.taskAccountId = taskAccountId
      this.value = val
      const titleInfo = this.titleList.find(item => item.type === this.type)
      this.title = titleInfo.name
      this.key = titleInfo?.key
      this.formData[titleInfo?.key] = val
      this.show = true
    },

    // 关闭弹框
    closeModal () {
      this.show = false
    },

    // 监听 Dialog 弹框关闭
    onCloseDialog () {
      this.formData = {
        name: '',
        dailyBudget: '',
        isDailyBudget: 1,
        generalBudget: '',
        isGeneralBudget: 1,
        advertisingName: '',
        offer: ''
      }
    },

    // 点击确定按钮
    clickConfirm () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let data = Object.assign({}, this.formData)
          if (data[this.key] !== this.value) {
            this.$emit('confirm', this.type, data[this.key], this.taskAccountId)
          }
          this.closeModal()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
// .edit-info-dialog {
// }
.form-tips{
  font-size: 12px;
  color: #b4b6b0;
}
</style>
