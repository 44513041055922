var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c(
      "div",
      {
        style: {
          paddingTop: `${_vm.detail.paddingTop}px`,
          paddingBottom: `${_vm.detail.paddingBottom}px`,
        },
      },
      [
        _c("div", { staticClass: "button" }, [
          _c(
            "div",
            {
              staticClass: "button-btn",
              style: {
                background: _vm.detail.btnBgColorTheme,
                color: _vm.detail.fontColor,
                border: `1px solid ${_vm.detail.btnBorderColorTheme}`,
                fontWeight: _vm.detail.btnFontType === "0" ? "normal" : "bold",
              },
            },
            [_c("p", { domProps: { innerHTML: _vm._s(_vm.detail.btnTitle) } })]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }