var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "material-box",
      attrs: {
        title: "图片选择",
        visible: _vm.show,
        "destroy-on-close": true,
        width: `1500px`,
        top: "100px",
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.onCloseDialog,
      },
    },
    [
      _c(
        "el-row",
        { staticClass: "selected-img", attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4 } },
            [
              _c(
                "el-col",
                { staticClass: "tree-box", attrs: { span: 3 } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "输入关键字进行过滤", size: "small" },
                    model: {
                      value: _vm.filterText,
                      callback: function ($$v) {
                        _vm.filterText = $$v
                      },
                      expression: "filterText",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "tree" },
                    [
                      _c("el-tree", {
                        ref: "tree",
                        attrs: {
                          data: _vm.adAlbum,
                          lazy: "",
                          load: _vm.loadNode,
                          props: _vm.defaultProps,
                          "filter-node-method": _vm.filterNode,
                        },
                        on: { "node-click": _vm.nodeClick },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 15 } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "imageQuery",
                          staticStyle: { marginLeft: "10px" },
                          attrs: { inline: true, model: _vm.imageQuery },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "上传人: " } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入上传人" },
                                model: {
                                  value: _vm.imageQuery.nickname,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.imageQuery, "nickname", $$v)
                                  },
                                  expression: "imageQuery.nickname",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "宽: " } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入宽" },
                                model: {
                                  value: _vm.imageQuery.width,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.imageQuery, "width", $$v)
                                  },
                                  expression: "imageQuery.width",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "高: " } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入高" },
                                model: {
                                  value: _vm.imageQuery.height,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.imageQuery, "height", $$v)
                                  },
                                  expression: "imageQuery.height",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.onSubmit },
                                },
                                [_vm._v("查询")]
                              ),
                              _c(
                                "el-button",
                                { on: { click: _vm.resetForm } },
                                [_vm._v("重置")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: { click: _vm.handleUploadMaterial },
                        },
                        [_vm._v("上传")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-row", { attrs: { gutter: 20 } }, [
                _vm.imgList.length
                  ? _c(
                      "ul",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.imgLoading,
                            expression: "imgLoading",
                          },
                        ],
                        staticClass: "select-img",
                        staticStyle: { margin: "0" },
                        attrs: { "element-loading-text": "图片获取中" },
                      },
                      [
                        _c(
                          "el-row",
                          _vm._l(_vm.imgList, function (item) {
                            return _c(
                              "el-col",
                              { key: item.imageId, attrs: { span: 6 } },
                              [
                                _c(
                                  "li",
                                  {
                                    class: {
                                      active: _vm.selectedIds.includes(
                                        item.imageId
                                      ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleSelectImg(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "avatar-box" }, [
                                      _c("img", {
                                        staticClass: "avatar",
                                        attrs: { src: item.url },
                                      }),
                                    ]),
                                    _c("div", { staticClass: "attachments" }, [
                                      _c("p", [
                                        _vm._v(_vm._s(item.materialName)),
                                      ]),
                                      _c("div", [
                                        _c("p", [
                                          _c("span", [
                                            _vm._v(_vm._s(item.width) + "px"),
                                          ]),
                                          _vm._v(" * "),
                                          _c("span", [
                                            _vm._v(_vm._s(item.height) + "px"),
                                          ]),
                                        ]),
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(parseInt(item.size / 1024)) +
                                              "kb"
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                    _c("i", { staticClass: "el-icon-success" }),
                                  ]
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                        _c("el-pagination", {
                          staticStyle: { "text-align": "right" },
                          attrs: {
                            background: "",
                            layout: "prev, pager, next",
                            total: _vm.imagListTotal,
                          },
                          on: {
                            "current-change": _vm.currentChange,
                            "prev-click": _vm.currentChange,
                            "next-click": _vm.currentChange,
                          },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "ul",
                      {
                        staticClass: "select-img",
                        staticStyle: { margin: "0" },
                      },
                      [_c("el-empty", { attrs: { description: "暂无数据" } })],
                      1
                    ),
              ]),
            ],
            1
          ),
          _c("el-col", { attrs: { span: 5 } }, [
            _c("div", { staticClass: "comp-selected-box page-name" }, [
              _c("div", { staticClass: "comp-title" }, [_vm._v("已选素材")]),
              _c(
                "div",
                { staticClass: "comp-selected-content" },
                _vm._l(_vm.selectedData, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "comp-selected-item" },
                    [
                      _c("img", {
                        staticClass: "comp-video",
                        attrs: { src: item.url },
                      }),
                      _c("span", {
                        staticClass: "el-icon-error comp-del-icon",
                        on: {
                          click: function ($event) {
                            return _vm.handleDeleteSelected(index)
                          },
                        },
                      }),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.closeModal } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                loading: _vm.isLoading,
                disabled: _vm.selectedData.length === 0,
              },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确 定 ")]
          ),
        ],
        1
      ),
      _c("add-material-dialog", {
        ref: "addMaterialDialog",
        attrs: { type: "image" },
        on: { edit: _vm._getUpdateMaterial },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }