var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Search", { ref: "search" }),
      _c(
        "el-row",
        {
          staticClass: "operate-box",
          attrs: { type: "flex", justify: "space-between" },
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-orange", round: "" },
              on: { click: _vm.showSync },
            },
            [_vm._v("同步账户")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.isPublish ? "推送" : "同步账户",
            visible: _vm.isSync || _vm.isPublish,
            width: "500px",
            "show-close": false,
            "modal-append-to-body": false,
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-form",
                {
                  ref: "accountRef",
                  attrs: { model: _vm.account, "label-width": "100px" },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "管家账号",
                        prop: "accountId",
                        rules: [{ required: true, message: "管家账号必选" }],
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "300px" },
                          on: { change: _vm.AdvENTListchange },
                          model: {
                            value: _vm.account.accountId,
                            callback: function ($$v) {
                              _vm.$set(_vm.account, "accountId", $$v)
                            },
                            expression: "account.accountId",
                          },
                        },
                        _vm._l(
                          _vm.accountHugeHousekeeperENTs,
                          function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: {
                                label: item.accountName,
                                value: item.accountId,
                              },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "广告主账号",
                        prop: "GGZaccountId",
                        rules: [{ required: true, message: "广告主账号必选" }],
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "300px" },
                          attrs: { filterable: "", multiple: "" },
                          model: {
                            value: _vm.account.GGZaccountId,
                            callback: function ($$v) {
                              _vm.$set(_vm.account, "GGZaccountId", $$v)
                            },
                            expression: "account.GGZaccountId",
                          },
                        },
                        _vm._l(
                          _vm.accountHugeAdvENTLists[
                            _vm.accountHugeHousekeeperENTsId
                          ],
                          function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: {
                                label: item.accountName,
                                value: item.accountId,
                              },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      ;(_vm.isSync = false), (_vm.isPublish = false)
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.dialogVisible },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "table-container" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "list-table",
              attrs: { stripe: "", data: _vm.data },
            },
            [
              _c("c-data-empty", { attrs: { slot: "empty" }, slot: "empty" }),
              _c("el-table-column", {
                attrs: {
                  prop: "id",
                  label: "ID",
                  align: "left",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: "转化名称",
                  align: "left",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "convertSourceType",
                  label: "接入方式",
                  align: "left",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm.csStatus.filter(
                                (item) =>
                                  item.dictValue === row.convertSourceType
                              )[0].dictLabel
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "convertType",
                  label: "转化目标",
                  align: "left",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("p", [
                          _vm._v(_vm._s(_vm.convertType[row.convertType])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "deepExternalAction",
                  label: "深度转化目标",
                  align: "left",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              row.deepExternalAction
                                ? _vm.convertTypeList.filter(
                                    (item) =>
                                      item.dictValue === row.deepExternalAction
                                  )[0].dictLabel
                                : "-"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "status", label: "转化状态", align: "left" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              row.status === "AD_CONVERT_STATUS_ACTIVE"
                                ? "活跃（激活）"
                                : "不活跃（未激活）"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "relationHousekeeperName",
                  label: "管家账号",
                  "show-overflow-tooltip": "",
                  align: "left",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "relationAdvAccountName",
                  label: "投放账户",
                  "show-overflow-tooltip": "",
                  align: "left",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "创建时间",
                  align: "left",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("p", [
                          _vm._v(
                            _vm._s(
                              _vm
                                .moment(row.createTime)
                                .format("YYYY-MM-DD HH:mm:ss")
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.status === "AD_CONVERT_STATUS_ACTIVE"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.publishFun(row)
                                  },
                                },
                              },
                              [_vm._v(" 推送 ")]
                            )
                          : _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.activationFun(row)
                                  },
                                },
                              },
                              [_vm._v("激活")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "转化追踪激活",
            visible: _vm.isActivation,
            width: "500px",
            "modal-append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isActivation = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-form",
                {
                  ref: "activationRef",
                  attrs: { model: _vm.activation, "label-width": "100px" },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "测试链接",
                        prop: "url",
                        rules: [{ required: true, message: "测试链接必填" }],
                      },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.activation.url,
                          callback: function ($$v) {
                            _vm.$set(_vm.activation, "url", $$v)
                          },
                          expression: "activation.url",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      ;(_vm.isActivation = false), (_vm.activation.url = "")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.activationSubmit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("c-pagination", {
        attrs: {
          "page-size": _vm.pageInfo.pageSize,
          "page-no": _vm.pageInfo.pageNum,
          total: _vm.pageInfo.total,
          "get-data-fun": _vm.loadData,
        },
        on: {
          "update:pageSize": function ($event) {
            return _vm.$set(_vm.pageInfo, "pageSize", $event)
          },
          "update:page-size": function ($event) {
            return _vm.$set(_vm.pageInfo, "pageSize", $event)
          },
          "update:pageNo": function ($event) {
            return _vm.$set(_vm.pageInfo, "pageNum", $event)
          },
          "update:page-no": function ($event) {
            return _vm.$set(_vm.pageInfo, "pageNum", $event)
          },
        },
      }),
      _c("OptionDialog", { ref: "dialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }