<!--
 * @Author: Zeng
 * @Date: 2023-06-08 14:47:01
 * @LastEditTime: 2023-06-16 13:41:54
-->
<template>
  <el-dialog :title="ruleForm.adMarketingApiServiceId ? '编辑服务商账户' : '新增服务商账户'" :visible.sync="show" width="620px"
    :modal-append-to-body="false" destroy-on-close @close="closeModal">
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px">
      <el-form-item v-if="ruleForm.adMarketingApiServiceId" label="账户ID" prop="serviceAccountId">
        <span>{{ ruleForm.serviceAccountId }}</span>
      </el-form-item>
      <el-form-item label="运营者微信" prop="relationWx">
        <el-input v-model="ruleForm.relationWx" type="text" style="width:400px" placeholder="请输入运营者微信"></el-input>
      </el-form-item>
      <el-form-item label="账户名称" prop="corporationName">
        <el-input v-model="ruleForm.corporationName" type="text" style="width:400px" placeholder="请输入账户名称"></el-input>
      </el-form-item>
      <el-form-item label="账户主体" prop="mdmName">
        <el-input v-model="ruleForm.mdmName" type="text" style="width:400px" placeholder="请输入账户主体"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="ruleForm.remark" type="textarea" maxlength="30" placeholder="请输入备注"
          style="width:400px"></el-input>
      </el-form-item>

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleSubmit">确定</el-button>
      <el-button @click="closeModal">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { updateTencentHousekeeper, addTencentHousekeeper } from '@/network/api/account/tencentAdvAccount.js'
export default {
  data() {
    return {
      show: false,
      ruleForm: {
        relationWx: '',
        corporationName: '',
        mdmName: '',
        remark: ''
      },
      rules: {
        relationWx: [{ required: true, message: '请输入运营者微信', trigger: 'blur' }]
      },
      loading: false
    }
  },

  created() { },

  mounted() { },
  methods: {
    showModal(row) {
      if (row) {
        this.ruleForm = {
          adMarketingApiServiceId: row.adMarketingApiServiceId,
          serviceAccountId: row.serviceAccountId,
          relationWx: row.relationWx,
          corporationName: row.corporationName,
          mdmName: row.mdmName,
          remark: row.remark
        }
      }
      this.show = true
    },
    closeModal() {
      this.$refs.ruleForm.resetForm()
      this.ruleForm = {
        relationWx: '',
        corporationName: '',
        mdmName: '',
        remark: ''
      }
      this.show = false
    },

    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (!valid) return
        let params = {
          ...this.ruleForm
        }
        this.loading = true
        if (params.adMarketingApiServiceId) {
          updateTencentHousekeeper(params).then(res => {
            this.loading = false
            this.$emit('confirm', res.data)
            this.closeModal()
          })
        } else {
          addTencentHousekeeper(params).then(res => {
            this.loading = false
            this.$emit('confirm', res.data)
            this.closeModal()
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
