<template>
  <div>
    <Search ref="search" />

    <el-row>

      <el-col :span="6" class="elStyle">
        <el-row
          type="flex"
          justify="space-between"
          class="operate-box"
          style="display: flex;justify-content: center;"
        >
          <el-button type="primary" icon="el-icon-plus" style="    width: 100%;" @click="showAdd">新增标签分类</el-button>
        </el-row>
        <div class="table-container">
          <el-table v-loading="loading" stripe class="list-table" :data="data">
            <c-data-empty slot="empty" />
            <el-table-column prop="labelTypeName" label="分类名称" align="center" :show-overflow-tooltip="true" />
            <el-table-column prop="relationProjectName" label="所属项目" align="center" :show-overflow-tooltip="true" />
            <el-table-column prop="num" label="标签数量" align="center">
              <template slot-scope="scope">
                <el-button :style="scope.row.color==='NO'? tableNo:tableNum" type="text" @click="buttonNum(scope.row.bsLabelTypeId)"><u :style="scope.row.color!=='NO'? 'color:#fff':''">{{ scope.row.num }}</u></el-button>
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" :show-overflow-tooltip="true">
              <template slot-scope="{row}">
                <el-button type="text" size="small" @click="showEdit(row)">编辑</el-button>
                <el-button type="text" size="small" @click="deleteRow(row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <c-pagination
          :page-size.sync="pageInfo.pageSize"
          :page-no.sync="pageInfo.pageNum"
          :total="pageInfo.total"
          :get-data-fun="loadData"
        />
        <OptionDialog ref="dialog" />

      </el-col>

      <el-col :span="17" class="elStyle ml-10">
        <el-row type="flex" justify="space-between" class="operate-box">
          <el-col :span="22" style="">
            <el-alert :title="'分类名称：'+labelTypeObj.labelTypeName" type="info" center :closable="false" />
          </el-col>
          <el-col :span="2">
            <el-button type="primary" class="ml-5" icon="el-icon-plus" round @click="showAddLabel">新 增</el-button>
          </el-col>
        </el-row>
        <div class="table-container">
          <el-table v-loading="loading" stripe class="list-table" :data="dataLabel">
            <c-data-empty slot="empty" />
            <el-table-column type="index" label="序号" align="center" />
            <el-table-column prop="labelName" label="标签名称" align="center" :show-overflow-tooltip="true" />
            <!-- <el-table-column prop="num" label="已关联专辑" align="center" :show-overflow-tooltip="true" /> -->
            <!-- <el-table-column prop="createBy" label="创建者" align="center" />
            <el-table-column prop="createTime" label="创建时间" align="center" />
            <el-table-column prop="updateBy" label="更新者" align="center" />
            <el-table-column prop="updateTime" label="更新时间" align="center" /> -->

            <el-table-column label="操作">
              <template slot-scope="{row}">
                <el-button type="text" size="small" @click="showEd(row)">编辑</el-button>
                <el-button type="text" size="small" @click="deleteRowLabel(row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <c-pagination
          :page-size.sync="pageInfoLable.pageSize"
          :page-no.sync="pageInfoLable.pageNum"
          :total="pageInfoLable.total"
          :get-data-fun="labelPage"
        />
        <OptionDialogLabel ref="dialogLabel" />

      </el-col>
    </el-row>

  </div>
</template>

<script>
import { PageCommonComponent } from '@/common/mixin'
import { DeleteLabel } from '@/network/api/basicSetting/label/basicSettingLabel'
import {
  Page,
  Delete
} from '@/network/api/basicSetting/label/basicSettingLabelType'
import Search from './Search'
import OptionDialog from './OptionDialog'
import OptionDialogLabel from '../basicSettingLabel/OptionDialog'
import { BasicSettingLabelTypeEnt } from './object'

export default {
  name: 'BasicSettingLabelType',
  components: { Search, OptionDialog, OptionDialogLabel },
  mixins: [PageCommonComponent],
  provide: function () {
    return {
      loadData: this.loadData,
      showEdit: this.showEdit
    }
  },
  data() {
    return {
      cuntId: '',

      tableNo: {
        width: '100%',
        height: '100%'
      },
      tableNum: {
        width: '100%',
        height: '100%',
        backgroundColor: '#409EFF'
      },
      labelTypeObj: {},
      labelObj: {},
      dataLabel: [],
      allDataLabel: [],
      pageInfoLable: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },

      radioVal: undefined,
      loading: false,
      data: [],
      height: '500px',
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    labelPage() {
      var pageNum = this.pageInfoLable.pageNum
      var pageSize = this.pageInfoLable.pageSize
      if (undefined === this.allDataLabel) {
        this.dataLabel = []
        this.pageInfoLable.total = 0
      } else {
        this.dataLabel = this.allDataLabel.slice((pageNum - 1) * pageSize, this.allDataLabel.length > pageNum * pageSize ? pageNum * pageSize : this.allDataLabel.length)
        this.pageInfoLable.total = this.allDataLabel.length
      }
    },
    buttonNum(bsLabelTypeId) {
      this.cuntId = bsLabelTypeId
      this.data.forEach(item => {
        if (item.bsLabelTypeId === bsLabelTypeId) {
          item.color = 'YES'
          this.labelTypeObj = item
        } else {
          item.color = 'NO'
        }
      })
      if (undefined !== this.labelObj) {
        this.allDataLabel = this.labelObj[bsLabelTypeId]
        if (undefined === this.allDataLabel) {
          this.dataLabel = []
          this.pageInfoLable.total = 0
        } else {
          this.dataLabel = this.allDataLabel.slice(0, this.allDataLabel.length > 10 ? 10 : this.allDataLabel.length)
          this.pageInfoLable.total = this.allDataLabel.length
        }
      }
    },

    loadData(isFirst = false) {
      const searchForm = this.$refs.search.getSearchForm()
      this.doLoadData(isFirst, searchForm)
    },
    doLoadData(firstPage = false, searchForm) {
      firstPage && (this.pageInfo.pageNum = 1)
      const req = { ...searchForm, ...this.pageInfo }
      this.loading = true
      Page(req).then(res => {
        this.data = res.data.LabelType
        if (this.cuntId === '' && undefined !== this.data && this.data.length > 0) {
          this.data[0].color = 'red'
          this.labelTypeObj = this.data[0]
        } else {
          if (undefined !== this.data && this.data.length > 0) {
            this.data.forEach(item => {
              if (item.bsLabelTypeId === this.cuntId) {
                this.labelTypeObj = item
                this.labelTypeObj.color = 'red'
              }
            })
          }
        }

        this.labelObj = res.data.Label
        if (undefined !== this.labelObj) {
          this.allDataLabel = this.labelObj[this.labelTypeObj.bsLabelTypeId]
          if (undefined !== this.allDataLabel) {
            this.dataLabel = this.allDataLabel.slice(0, this.allDataLabel.length > 10 ? 10 : this.allDataLabel.length)
            this.pageInfoLable.total = this.allDataLabel.length
          }
        }

        this.pageInfo.total = res.data.total
        this.loading = false
      })
    },
    deleteRowLabel(row) {
      this.$confirm('确定删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        DeleteLabel(row.bsLabelId).then(res => {
          if (res.code === 200) {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.loadData()
          } else {
            this.$message({
              message: res.msg,
              type: 'error'
            })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    deleteRow(row) {
      this.$confirm('确定删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        Delete(row.bsLabelTypeId).then(res => {
          if (res.code === 200) {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.loadData()
          } else {
            this.$message({
              message: res.msg,
              type: 'error'
            })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    showEdit(row) {
      this.$refs.dialog.show(true, { ...row })
    },
    showAdd() {
      this.$refs.dialog.show(false, new BasicSettingLabelTypeEnt())
    },
    showAddLabel() {
      this.$refs.dialogLabel.show(false, this.labelTypeObj)
    },

    showEd(row) {
      this.$refs.dialogLabel.showEd(true, this.labelTypeObj, row)
    }
  }
}
</script>

<style scoped>

.elStyle{
   border: 1px solid #EDECF2;height:740px
}

</style>
