<template>
  <div>
    <Search ref="search" />
    <el-row type="flex" justify="space-between" class="operate-box">
      <el-button type="primary" icon="el-icon-plus" round @click="showAdd">新增广告主</el-button>
    </el-row>
    <div class="table-container">
      <el-table v-loading="loading" stripe class="list-table" :data="data">
        <c-data-empty slot="empty"></c-data-empty>
        <el-table-column prop="accountId" label="账号ID" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="accountName" label="账户名称" align="left" :show-overflow-tooltip="true" />
        <!-- <el-table-column prop="loginAccount" label="登录账号" align="left" :show-overflow-tooltip="true"> -->
        <!-- <template slot-scope="{ row }">
            <span>{{ row.loginAccount || '-' }}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="subjectName" label="主体名称" align="left" :show-overflow-tooltip="true" />

        <el-table-column prop="accountBalance" label="账号余额" align="left">
          <template slot-scope="{ row }">
            <span>{{ row.accountBalance || 0 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="projectName" label="项目名称" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="productName" label="产品" align="left" />
        <el-table-column prop="putByName" label="投放人员" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="smartBidNoBid" label="最大转化投放" align="left" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.smartBidNoBid === 1 ? '开启' : '-' }}
          </template>
        </el-table-column>
        <el-table-column prop="putInStatus" label="投放状态" align="left" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.putInStatus === 1? 'success' : 'danger'"
              disable-transitions
            >{{ scope.row.putInStatus === 0 ? '停投' : '投放' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="{ row }">
            <el-button type="text" size="small" @click="showEdit(row)">
              <el-tooltip content="编辑" placement="top">
                <i class="el-icon-edit" style="font-size: 16px"></i>
              </el-tooltip>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <c-pagination :page-size.sync="pageInfo.pageSize" :page-no.sync="pageInfo.pageNum" :total="pageInfo.total" :get-data-fun="loadData"> </c-pagination>
    <OptionDialog :visible="visible" @setVisible="(value) => (visible = value)" @getPage="loadData" />
    <OptionDialogEdit ref="dialog" />
  </div>
</template>

<script>
import { PageCommonComponent } from '@/common/mixin'
import { Page, Delete } from '@/network/api/huge/accountHugeAdv'
import Search from './Search'
import OptionDialog from '../accountHugeHousekeeper/OptionDialog'
import OptionDialogEdit from './OptionDialog'
export default {
  name: 'AccountHugeAdv',
  components: { Search, OptionDialog, OptionDialogEdit },
  mixins: [PageCommonComponent],
  provide: function() {
    return {
      loadData: this.loadData,
      showEdit: this.showEdit
    }
  },
  data() {
    return {
      loading: false,
      data: [],
      visible: false,
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData(isFirst = false) {
      const searchForm = this.$refs.search.getSearchForm()
      this.doLoadData(isFirst, searchForm)
    },
    doLoadData(firstPage = false, searchForm) {
      firstPage && (this.pageInfo.pageNum = 1)
      const req = { ...searchForm, search: searchForm.accountName, ...this.pageInfo }
      delete req.accountName
      delete req.accountId
      this.loading = true
      Page(req).then((res) => {
        this.data = res.data.records
        this.pageInfo.total = res.data.total
        this.loading = false
      })
    },
    deleteRow(row) {
      this.$confirm('确定删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        Delete(row.id).then((res) => {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.loadData()
        })
      })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showEdit(row) {
      this.$refs.dialog.show(true, { ...row })
    },
    showAdd() {
      this.visible = true
    }
  }
}
</script>

<style scoped></style>
