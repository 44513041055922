var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "300px" } },
    [
      _vm.planForm.flag
        ? _c("ContentBox", { attrs: { "data-props": _vm.planDiv } })
        : _c("div", { staticClass: "kong" }, [_vm._v("请选择计划")]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "计划基础信息",
            visible: _vm.visible,
            width: "600px",
            "modal-append-to-body": false,
          },
          on: { close: _vm.beforeClose },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.planForm, "label-width": "100px" },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "投放位置",
                        rules: {
                          required: true,
                          message: "请选择投放位置",
                          trigger: "blue",
                        },
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            prop: "isFriendCircle",
                            placeholder: "请选择投放位置",
                            rules: {
                              required: true,
                              message: "请选择投放账户",
                              trigger: "blue",
                            },
                          },
                          on: { change: _vm.launchPlanAddressChange },
                          model: {
                            value: _vm.planForm.isFriendCircle,
                            callback: function ($$v) {
                              _vm.$set(_vm.planForm, "isFriendCircle", $$v)
                            },
                            expression: "planForm.isFriendCircle",
                          },
                        },
                        [
                          _c("el-option", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.promotedObjectType ===
                                  "PRODUCTTYPE_WECHAT",
                                expression:
                                  "promotedObjectType==='PRODUCTTYPE_WECHAT'",
                              },
                            ],
                            attrs: { label: "朋友圈信息流", value: true },
                          }),
                          _c("el-option", {
                            attrs: { label: "公众号平台流量", value: false },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "广告位置",
                        prop: "adsAddress",
                        rules: {
                          required: true,
                          message: "请选择广告位置",
                          trigger: "blue",
                        },
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择广告位置" },
                          on: { change: _vm.adsAddressChange },
                          model: {
                            value: _vm.planForm.adsAddress,
                            callback: function ($$v) {
                              _vm.$set(_vm.planForm, "adsAddress", $$v)
                            },
                            expression: "planForm.adsAddress",
                          },
                        },
                        _vm._l(_vm.crtSizeList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: `${item.label}`,
                              value: `${item.other_id}+${item.id}+${item.value}+${item.label}`,
                            },
                          })
                        }),
                        1
                      ),
                      _vm.planForm.flag
                        ? _c("p", { staticClass: "tip" }, [
                            _vm._v("修改广告位置后, 广告创意数据会被清空"),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "购买方式",
                        prop: "ctype",
                        rules: {
                          required: true,
                          message: "请选购买方式",
                          trigger: "blue",
                        },
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.planForm.ctype,
                            callback: function ($$v) {
                              _vm.$set(_vm.planForm, "ctype", $$v)
                            },
                            expression: "planForm.ctype",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: "竞价购买",
                              value: "CAMPAIGNTYPE_AUCTION",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "计划日预算",
                        prop: "dayBudget",
                        rules: {
                          required: true,
                          message: "请选择计划日预算",
                          trigger: "blue",
                        },
                      },
                    },
                    [
                      _c("el-input-number", {
                        attrs: {
                          min: 50,
                          max: 40000000,
                          "controls-position": "right",
                        },
                        model: {
                          value: _vm.planForm.dayBudget,
                          callback: function ($$v) {
                            _vm.$set(_vm.planForm, "dayBudget", $$v)
                          },
                          expression: "planForm.dayBudget",
                        },
                      }),
                      _c("span", { staticClass: "ml-10" }, [_vm._v("元/天")]),
                      _c("br"),
                      _c("p", { staticClass: "tip" }, [
                        _vm._v("每日预算有效范围为 50 ~ 40,000,000 元/天。"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "计划名称",
                        prop: "cname",
                        rules: {
                          required: true,
                          message: "请输入计划名称",
                          maxlength: 60,
                          trigger: "blue",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "400px" },
                        attrs: {
                          rows: "4",
                          type: "textarea",
                          placeholder: "请输入计划名称",
                          maxlength: "60",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.planForm.cname,
                          callback: function ($$v) {
                            _vm.$set(_vm.planForm, "cname", $$v)
                          },
                          expression: "planForm.cname",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                {
                  attrs: { disabled: !_vm.isSearchLock, type: "primary" },
                  on: { click: _vm.getSelectPlanData },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }