<!--
 * @Description: 腾讯广告-> 新建定向模板
 * @Date: 2021-12-01 09:15:29
 * @LastEditTime: 2022-02-21 09:39:25
-->

<template>
  <div>
    <!-- 列表查询 -->
    <el-form ref="form" :model="form" label-width="110px">
      <el-form-item label="模板名称：" prop="name">
        <el-input v-model="form.name" placeholder="请输入模板名称" style="width: 400px"></el-input>
      </el-form-item>
      <el-form-item label="定向描述：" prop="description">
        <el-input v-model="form.description" placeholder="请输入定向描述" style="width: 400px"></el-input>
      </el-form-item>
      <el-form-item label="关联项目：" prop="projectId">
        <el-input v-model="form.projectId" placeholder="请选择关联项目" style="width: 400px"></el-input>
      </el-form-item>
      <el-form-item label="关联产品：" prop="productId">
        <el-input v-model="form.productId" placeholder="请选择关联关联产品" style="width: 400px"></el-input>
      </el-form-item>

      <el-divider>人口学属性</el-divider>

      <!-- 地理位置 -->
      <el-form-item label="地理位置：" prop="location_types">
        <el-radio v-model="form.location_types" label="" border>不限</el-radio>
        <el-radio v-model="form.location_types" label="customize" border>自定义</el-radio>
        <el-collapse v-if="form.location_types === 'customize'" accordion class="mt-20" @change="(name) => getTargetingTagsFun(name)">
          <el-collapse-item name="REGION">
            <template slot="title">地域</template>
            <el-cascader placeholder="搜索国家、省、市、区" :options="REGION_list" collapse-tags :props="{ label: 'name', value: 'id', multiple: true }" clearable>></el-cascader>
          </el-collapse-item>
          <el-collapse-item name="BUSINESS_DISTRICT">
            <template slot="title">商圈 </template>
            <el-cascader placeholder="搜索商圈" :options="BUSINESS_DISTRICT_list" collapse-tags :props="{ label: 'name', value: 'id', multiple: true }" clearable>></el-cascader>
          </el-collapse-item>
        </el-collapse>
      </el-form-item>

      <!-- 年龄 -->
      <el-form-item label="年龄：" prop="age_label">
        <el-row>
          <el-col :xl="4" :lg="6">
            <el-radio :value="form.age_label" label="" border @change=";(form.age = ''), (form.age_label = '')">不限</el-radio>
            <el-radio :value="form.age_label" label="customize" border @change=";(form.age = [14, 18]), (form.age_label = 'customize')">自定义</el-radio>
          </el-col>
          <el-col v-if="form.age_label === 'customize'" prop="age" :xl="20" :lg="18">
            <el-select v-model="form.age[0]" style="width:160px" clearable placeholder="请选择" @change="(value) => (value > form.age[0] ? (form.age[0] = value) : null)">
              <el-option v-for="item in age_min_list" :key="item.value" :label="item.value" :value="item.key"> </el-option>
            </el-select>
            <span> - </span>
            <el-select v-model="form.age[1]" style="width:160px" clearable placeholder="请选择">
              <el-option v-for="item in age_max_list" :key="item.key" :label="item.value" :value="item.key" :disabled="item.key < form.age[0]"> </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>

      <!-- 性别 -->
      <el-form-item label="性别：" prop="gender">
        <el-radio-group v-model="form.gender">
          <el-radio label="" border>不限</el-radio>
          <el-radio label="MALE" border>男</el-radio>
          <el-radio label="FEMALE" border>女</el-radio>
        </el-radio-group>
      </el-form-item>

      <!-- 学历： -->
      <el-form-item label="学历：" prop="education">
        <el-row>
          <el-col :xl="4" :lg="6">
            <el-radio v-model="form.education_label" label="" border @change="form.marital_status = []">不限</el-radio>
            <el-radio v-model="form.education_label" label="customize" border>其他</el-radio>
          </el-col>
          <el-col v-if="form.education_label === 'customize'" :xl="20" :lg="18">
            <el-checkbox-group v-model="form.education" :min="0">
              <el-checkbox v-for="item in education_list" :key="item.dictLabel" :label="item.dictValue">{{ item.dictLabel }}</el-checkbox>
            </el-checkbox-group>
          </el-col>
        </el-row>
      </el-form-item>

      <!-- 婚恋育儿： -->
      <el-form-item label="婚恋育儿：" prop="marital_status_label">
        <el-row>
          <el-col>
            <el-radio v-model="form.marital_status_label" label="" border @change="form.marital_status = []">不限</el-radio>
            <el-radio v-model="form.marital_status_label" label="customize" border>自定义</el-radio>
          </el-col>
          <el-col v-if="form.marital_status_label === 'customize'">
            <ListBox>
              <el-checkbox-group v-model="form.marital_status" :min="0">
                <el-checkbox v-for="item in marital_status_list" :key="item.dictLabel" :label="item.dictValue">{{ item.dictLabel }}</el-checkbox>
              </el-checkbox-group>
            </ListBox>
          </el-col>
        </el-row>
      </el-form-item>

      <!-- 工作状态： -->
      <el-form-item label="工作状态：" prop="working_status_label">
        <el-row>
          <el-col>
            <el-radio v-model="form.working_status_label" label="" border @change="form.working_status = []">不限</el-radio>
            <el-radio v-model="form.working_status_label" label="customize" border>自定义</el-radio>
          </el-col>
          <el-col v-if="form.working_status_label === 'customize'">
            <ListBox>
              <el-checkbox-group v-model="form.marital_status" :min="0">
                <el-checkbox v-for="item in working_status_list" :key="item.dictLabel" :label="item.dictValue">{{ item.dictLabel }}</el-checkbox>
              </el-checkbox-group>
            </ListBox>
          </el-col>
        </el-row>
      </el-form-item>

      <!-- 财产状态： -->
      <el-form-item label="财产状态" prop="financial_situation_label">
        <el-row>
          <el-col :xl="4" :lg="6">
            <el-radio v-model="form.financial_situation_label" label="" border @change="form.financial_situation = []">不限</el-radio>
            <el-radio v-model="form.financial_situation_label" label="customize" border>其他</el-radio>
          </el-col>
          <el-col v-if="form.financial_situation_label === 'customize'" :xl="20" :lg="18">
            <el-checkbox-group v-model="form.financial_situation" :min="0">
              <el-checkbox label="HOME_OWNERS ">有房人士</el-checkbox>
              <el-checkbox label="CAR_OWNERS">有车人士</el-checkbox>
            </el-checkbox-group>
          </el-col>
        </el-row>
      </el-form-item>

      <!-- 消费类型 -->
      <el-form-item label="消费类型：" prop="consumption_type_label">
        <el-row>
          <el-col :xl="4" :lg="6">
            <el-radio v-model="form.consumption_type_label" label="" border @change="form.consumption_type = []">不限</el-radio>
            <el-radio v-model="form.consumption_type_label" label="customize" border>其他</el-radio>
          </el-col>
          <el-col v-if="form.consumption_type_label === 'customize'" :xl="20" :lg="18">
            <el-checkbox-group v-model="form.consumption_type" :min="0">
              <el-checkbox label="PAID_GOODS_VIRTUAL">虚拟商品</el-checkbox>
              <el-checkbox label="PAID_GOODS_REAL">实物电商</el-checkbox>
            </el-checkbox-group>
          </el-col>
        </el-row>
      </el-form-item>

      <!-- 游戏消费 -->
      <el-form-item label="游戏消费：" prop="game_consumption_level_label">
        <el-row>
          <el-col :xl="4" :lg="6">
            <el-radio v-model="form.game_consumption_level_label" label="" border @change="form.game_consumption_level = []">不限</el-radio>
            <el-radio v-model="form.game_consumption_level_label" label="customize" border>其他</el-radio>
          </el-col>
          <el-col v-if="form.game_consumption_level_label === 'customize'" :xl="20" :lg="18">
            <el-checkbox-group v-model="form.game_consumption_level" :min="0">
              <el-checkbox label="HIGH">高</el-checkbox>
              <el-checkbox label="NORMAL">普通</el-checkbox>
            </el-checkbox-group>
          </el-col>
        </el-row>
      </el-form-item>

      <!-- 消费水平 -->
      <el-form-item label="消费水平：" prop="consumption_status_label">
        <el-row>
          <el-col :xl="4" :lg="6">
            <el-radio v-model="form.consumption_status_label" label="" border @change="form.consumption_status = []">不限</el-radio>
            <el-radio v-model="form.consumption_status_label" label="customize" border>其他</el-radio>
          </el-col>
          <el-col v-if="form.consumption_status_label === 'customize'" :xl="20" :lg="18">
            <el-checkbox-group v-model="form.consumption_status" :min="0">
              <el-checkbox label="HIGH">高消费</el-checkbox>
              <el-checkbox label="LOW">低消费</el-checkbox>
            </el-checkbox-group>
          </el-col>
        </el-row>
      </el-form-item>

      <!-- 社区价格 -->
      <el-form-item label="社区价格：" prop="residential_community_price_label">
        <el-row>
          <el-col :xl="4" :lg="6">
            <el-radio :value="form.residential_community_price_label" label="" border @change=";(form.residential_community_price = ''), (form.residential_community_price_label = '')">不限</el-radio>
            <el-radio :value="form.residential_community_price_label" label="customize" border @change=";(form.residential_community_price = [1, 100000]), (form.residential_community_price_label = 'customize')">自定义</el-radio>
          </el-col>
          <el-col v-if="form.residential_community_price_label === 'customize'" prop="residential_community_price" :xl="20" :lg="18">
            <el-select v-model="form.residential_community_price[0]" style="width:160px" filterable clearable placeholder="请选择" @change="(value) => (value > form.residential_community_price[0] ? (form.residential_community_price[0] = value) : null)">
              <el-option v-for="item in residential_community_price_min" :key="item.value" :label="item.value" :value="item.key"> </el-option>
            </el-select>

            <span> - </span>

            <el-select v-model="form.residential_community_price[1]" style="width:160px" filterable clearable placeholder="请选择" @change="(value) => (value > form.residential_community_price[1] ? (form.residential_community_price[1] = value) : null)">
              <el-option v-for="item in residential_community_price_max" :key="item.value" :label="item.value" :value="item.key"> </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>
      <el-divider>用户行为</el-divider>

      <!-- 行为兴趣意向 -->
      <el-form-item label="行为兴趣意向：" prop="interest_label">
        <el-radio v-model="form.interest_label" label="" border @change="form.marital_status = []">不限</el-radio>
        <el-radio v-model="form.interest_label" label="customize" border>自定义</el-radio>
        <el-collapse v-if="form.interest_label === 'customize'" accordion class="mt-20" @change="(name) => getTargetingTagsFun(name)">
          <el-collapse-item name="BEHAVIOR">
            <template slot="title">行为</template>
            <el-cascader placeholder="搜索行为名称" :options="BEHAVIOR_loading" collapse-tags :props="{ label: 'name', value: 'id', multiple: true }" clearable>></el-cascader>
          </el-collapse-item>
          <el-collapse-item name="INTEREST">
            <template slot="title">兴趣 </template>
            <el-cascader placeholder="搜索兴趣名称" :options="[]" :props="props" collapse-tags clearable></el-cascader>
          </el-collapse-item>
          <el-collapse-item name="INTENTION">
            <template slot="title">意向 </template>
            <el-cascader placeholder="搜索意向名称" :options="[]" :props="props" collapse-tags clearable></el-cascader>
          </el-collapse-item>
        </el-collapse>
      </el-form-item>

      <!-- 排除已转化 -->
      <el-form-item label="排除已转化：" prop="excluded_dimension_label">
        <el-row>
          <el-col>
            <el-radio v-model="form.excluded_dimension" label="" border @change=";(form.conversion_behavior_list_flag = false), (form.conversion_behavior_list = [])">不限</el-radio>
            <el-radio v-for="item in excluded_dimension_list" :key="item.dictLabel" v-model="form.excluded_dimension" :label="item.dictValue" border @change=";(form.conversion_behavior_list_flag = false), (form.conversion_behavior_list = [])">{{ item.dictLabel }}</el-radio>
          </el-col>
          <el-col v-if="form.excluded_dimension !== ''">
            <div class="background mt-10">
              <p style="padding: 0; margin-bottom: -8px">系统自动依照当前广告选择的优化目标作为此定向的转化行为</p>
              <el-checkbox v-model="form.conversion_behavior_list_flag" :disabled="form.excluded_dimension === 'EXCLUDED_DIMENSION_PRODUCT'">自定义转化行为</el-checkbox>
              <br />
              <el-select
                v-if="form.conversion_behavior_list_flag"
                v-model="form.conversion_behavior_list"
                :multiple-limit="1"
                multiple
                class="mt-10 w-300"
                filterable
                clearable
                placeholder="请选择"
                @change="(value) => (value > form.residential_community_price[1] ? (form.residential_community_price[1] = value) : null)"
              >
                <el-option v-for="item in excluded_converted_audience_list" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue"> </el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
      </el-form-item>

      <!-- 自定义人群 -->
      <el-form-item label="自定义人群：" prop="interest_label">
        <el-row>
          <el-col :xl="4" :lg="6">
            <el-radio v-model="form.education_label" label="" border @change="form.marital_status = []">不限</el-radio>
            <el-radio v-model="form.education_label" label="customize" border>其他</el-radio>
          </el-col>
          <el-col v-if="form.education_label === 'customize'" :xl="20" :lg="18">
            <el-checkbox-group v-model="form.education" :min="0">
              <el-checkbox label="item.dictValue">定向人群</el-checkbox>
              <el-checkbox label="item.dictValue">排除人群</el-checkbox>
            </el-checkbox-group>
          </el-col>
        </el-row>
      </el-form-item>
      <el-divider><span>设备定向</span></el-divider>

      <!-- 设备品牌型号 -->
      <el-form-item label="设备品牌型号：" prop="included_list_label">
        <el-row>
          <el-col>
            <el-radio v-model="form.included_list_label" label="" border @change="form.included_list = []">不限</el-radio>
            <el-radio v-model="form.included_list_label" label="customize" border @change="getTargetingTagsFun('DEVICE_BRAND_MODEL')">自定义</el-radio>
          </el-col>
          <el-col v-if="form.included_list_label === 'customize'" class="mt-20">
            <div v-loading="included_type_loading">
              <el-cascader style="width:400px" :options="included_type_list" collapse-tags :props="{ label: 'name', value: 'id', multiple: true }" clearable></el-cascader>
            </div>
          </el-col>
        </el-row>
      </el-form-item>

      <!-- 上网场景 -->
      <el-form-item label="上网场景：" prop="network_scene_label">
        <el-row>
          <el-col :xl="4" :lg="6">
            <el-radio v-model="form.network_scene_label" label="" border @change="form.network_scene = []">不限</el-radio>
            <el-radio v-model="form.network_scene_label" label="customize" border>其他</el-radio>
          </el-col>
          <el-col v-if="form.network_scene_label === 'customize'" :xl="20" :lg="18">
            <el-checkbox-group v-model="form.network_scene" :min="0">
              <el-checkbox v-for="item in network_scene_list" :key="item.dictLabel" :label="item.dictValue">{{ item.dictLabel }}</el-checkbox>
            </el-checkbox-group>
          </el-col>
        </el-row>
      </el-form-item>

      <!-- 操作系统版本 -->
      <el-form-item label="操作系统版本：" prop="user_os_label">
        <el-row>
          <el-col>
            <el-radio v-model="form.user_os_label" label="" border @change="form.user_os_type = []">不限</el-radio>
            <el-radio v-model="form.user_os_label" label="customize" border>其他</el-radio>
          </el-col>
          <el-col v-if="form.user_os_label === 'customize'" class="mt-20">
            <el-cascader style="width:400px" collapse-tags :options="user_os_list" :props="{ label: 'dictLabel', value: 'dictValue', multiple: true }" clearable></el-cascader>
          </el-col>
        </el-row>
      </el-form-item>

      <!-- 联网方式 -->
      <el-form-item label="联网方式：" prop="network_type_label">
        <el-row>
          <el-col :xl="4" :lg="6">
            <el-radio v-model="form.network_type_label" label="" border @change="form.network_type = []">不限</el-radio>
            <el-radio v-model="form.network_type_label" label="customize" border>其他</el-radio>
          </el-col>
          <el-col v-if="form.network_type_label === 'customize'" :xl="20" :lg="18">
            <el-checkbox-group v-model="form.network_type" :min="0">
              <el-checkbox v-for="item in network_type_list" :key="item.dictLabel" :label="item.dictValue">{{ item.dictLabel }}</el-checkbox>
            </el-checkbox-group>
          </el-col>
        </el-row>
      </el-form-item>

      <!-- 移动运营商 -->
      <el-form-item label="移动运营商：" prop="network_operator_label">
        <el-row>
          <el-col :xl="4" :lg="6">
            <el-radio v-model="form.network_operator_label" label="" border @change="form.network_operator = []">不限</el-radio>
            <el-radio v-model="form.network_operator_label" label="customize" border>其他</el-radio>
          </el-col>
          <el-col v-if="form.network_operator_label === 'customize'" :xl="20" :lg="18">
            <el-checkbox-group v-model="form.network_operator" :min="0">
              <el-checkbox v-for="item in network_operator_list" :key="item.dictLabel" :label="item.dictValue">{{ item.dictLabel }}</el-checkbox>
            </el-checkbox-group>
          </el-col>
        </el-row>
      </el-form-item>

      <!-- 设备价格 -->
      <el-form-item label="设备价格：" prop="device_price_label">
        <el-row>
          <el-col :xl="4" :lg="6">
            <el-radio v-model="form.device_price_label" label="" border @change="form.device_price = []">不限</el-radio>
            <el-radio v-model="form.device_price_label" label="customize" border>其他</el-radio>
          </el-col>
          <el-col v-if="form.device_price_label === 'customize'" :xl="20" :lg="18">
            <el-checkbox-group v-model="form.device_price" :min="0">
              <el-checkbox v-for="item in device_price_list" :key="item.dictLabel" :label="item.dictValue">{{ item.dictLabel }}</el-checkbox>
            </el-checkbox-group>
          </el-col>
        </el-row>
      </el-form-item>

      <el-divider><span>天气定向</span></el-divider>

      <!-- 温度 -->
      <el-form-item label="温度：" prop="temperature_label">
        <el-row>
          <el-col :xl="4" :lg="6">
            <el-radio :value="form.temperature_label" label="" border @change=";(form.temperature = ''), (form.temperature_label = '')">不限</el-radio>
            <el-radio :value="form.temperature_label" label="customize" border @change=";(form.temperature = [252, 323]), (form.temperature_label = 'customize')">自定义</el-radio>
          </el-col>
          <el-col v-if="form.temperature_label === 'customize'" prop="temperature" :xl="20" :lg="18">
            <el-select v-model="form.temperature[0]" style="width:160px" filterable clearable placeholder="请选择" @change="(value) => (value > form.temperature[0] ? (form.temperature[0] = value) : null)">
              <el-option v-for="item in temperature_min" :key="item.value" :label="item.value" :value="item.key"> </el-option>
            </el-select>

            <span> - </span>

            <el-select v-model="form.temperature[1]" style="width:160px" filterable clearable placeholder="请选择" @change="(value) => (value > form.temperature[1] ? (form.temperature[1] = value) : null)">
              <el-option v-for="item in temperature_max" :key="item.value" :label="item.value" :value="item.key"> </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-form-item>

      <!-- 紫外线指数 -->
      <el-form-item label="紫外线指数：" prop="uv_index_label">
        <el-row>
          <el-col :xl="4" :lg="6">
            <el-radio v-model="form.uv_index_label" label="" border @change="form.uv_index = []">不限</el-radio>
            <el-radio v-model="form.uv_index_label" label="customize" border>其他</el-radio>
          </el-col>
          <el-col v-if="form.uv_index_label === 'customize'" :xl="20" :lg="18">
            <el-checkbox-group v-model="form.uv_index" :min="0">
              <el-checkbox v-for="item in uv_index_list" :key="item.dictLabel" :label="item.dictValue">{{ item.dictLabel }}</el-checkbox>
            </el-checkbox-group>
          </el-col>
        </el-row>
      </el-form-item>

      <!-- 穿衣指数 -->
      <el-form-item label="穿衣指数：" prop="dressing_index_label">
        <el-row>
          <el-col :xl="4" :lg="6">
            <el-radio v-model="form.dressing_index_label" label="" border @change="form.dressing_index = []">不限</el-radio>
            <el-radio v-model="form.dressing_index_label" label="customize" border>其他</el-radio>
          </el-col>
          <el-col v-if="form.dressing_index_label === 'customize'" :xl="20" :lg="18">
            <el-checkbox-group v-model="form.dressing_index" :min="0">
              <el-checkbox v-for="item in dressing_index_list" :key="item.dictLabel" :label="item.dictValue">{{ item.dictLabel }}</el-checkbox>
            </el-checkbox-group>
          </el-col>
        </el-row>
      </el-form-item>

      <!-- 气象 -->
      <el-form-item label="气象：" prop="climate_label">
        <el-row>
          <el-col :xl="4" :lg="6">
            <el-radio v-model="form.climate_label" label="" border @change="form.climate = []">不限</el-radio>
            <el-radio v-model="form.climate_label" label="customize" border>其他</el-radio>
          </el-col>
          <el-col v-if="form.climate_label === 'customize'" :xl="20" :lg="18">
            <el-checkbox-group v-model="form.climate" :min="0">
              <el-checkbox v-for="item in climate_list" :key="item.dictLabel" :label="item.dictValue">{{ item.dictLabel }}</el-checkbox>
            </el-checkbox-group>
          </el-col>
        </el-row>
      </el-form-item>

      <!-- 化妆指数指数 -->
      <el-form-item label="化妆指数：" prop="makeup_index_label">
        <el-row>
          <el-col :xl="4" :lg="6">
            <el-radio v-model="form.makeup_index_label" label="" border @change="form.makeup_index = []">不限</el-radio>
            <el-radio v-model="form.makeup_index_label" label="customize" border>其他</el-radio>
          </el-col>
          <el-col v-if="form.makeup_index_label === 'customize'" :xl="20" :lg="18">
            <el-checkbox-group v-model="form.makeup_index" :min="0">
              <el-checkbox v-for="item in makeup_index_list" :key="item.dictLabel" :label="item.dictValue">{{ item.dictLabel }}</el-checkbox>
            </el-checkbox-group>
          </el-col>
        </el-row>
      </el-form-item>

      <!-- 自动扩量 -->
      <el-form-item label="自动扩量：" prop="uv_index">
        <el-switch />
      </el-form-item>

      <el-form-item label="不可突破定向：" prop="uv_index">
        <el-checkbox-group v-model="form.climate" :min="0">
          <el-checkbox label="地域">地域</el-checkbox>
          <el-checkbox label="年龄">年龄</el-checkbox>
          <el-checkbox label="性别">性别</el-checkbox>
        </el-checkbox-group>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onSubmit">确定</el-button>
        <el-button @click="resetForm('ruleForm')">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getTargetingTags, selectOne } from '@/network/api/assetManagement/assetManageTencent'
import { age_min_list, age_max_list, residential_community_price_min, residential_community_price_max, temperature_min, temperature_max } from '../data'
import ListBox from './listBox.vue'
export default {
  components: {
    ListBox
  },
  data() {
    return {
      accountId: 0,
      age_min_list,
      age_max_list,
      residential_community_price_min,
      residential_community_price_max,
      temperature_min,
      temperature_max,
      form: {
        name: null,
        description: null,
        projectId: null,
        productId: null,
        location_types: '',
        gender: '', // 性别
        // 年龄
        age_label: '',
        age: '',
        // 婚恋育儿
        marital_status_label: '',
        marital_status: [],
        // 学历
        education_label: '',
        education: [],
        // 工作状态
        working_status_label: '',
        working_status: [],
        // 财产状态
        financial_situation_label: '',
        financial_situation: [],
        // 消费类型
        consumption_type_label: '',
        consumption_type: [],
        // 游戏消费
        game_consumption_level_label: '',
        game_consumption_level: [],
        // 消费水平
        consumption_status_label: '',
        consumption_status: [],
        // 居住社区价格
        residential_community_price_label: '',
        residential_community_price: [],

        // 设备名称
        included_list_label: '',

        // 排除已转化用户定向范围
        excluded_dimension: '',
        // 自定义转化行为
        conversion_behavior_list_flag: false,
        conversion_behavior_list: [],
        // 上网场景
        network_scene_label: '',
        conversion_behavior: [],
        // 联网方式
        network_type_label: '',
        network_type: [],
        // 移动运营商
        network_operator_label: '',
        network_operator: [],
        // 设备价格
        device_price_label: '',
        device_price: [],
        // 温度
        temperature_label: '',
        temperature: [],
        // 紫外线指数
        uv_index_label: '',
        uv_index: [],
        // 	穿衣指数
        dressing_index_label: '',
        dressing_index: [],
        // 化妆指数
        makeup_index_label: '',
        makeup_index: [],
        // 气象
        climate_label: '',
        climate: [],
        // 兴趣
        interest_label: '',
        interest: {
          category_id_list: [],
          keyword_list: []
        },
        excluded_custom_audience: [],
        user_os_label: '', // 操作系统
        user_os_type: [],
        user_os_ios: [],
        user_os_android: [],
        ios_label: '',
        android_label: ''
      },
      education_list: [], // 学历 list
      marital_status_list: [], // 婚恋育儿 list
      working_status_list: [], // 工作状态 list
      user_os_list: [], // 操作系统list
      excluded_dimension_list: [], // 排除已转化用户定向范围 list
      excluded_converted_audience_list: [], // 广告优化目标类型
      network_scene_list: [], // 上网场景
      network_type_list: [], // 联网方式
      network_operator_list: [], // 移动运营商
      device_price_list: [], // 设备价格
      uv_index_list: [], // 紫外线指数
      dressing_index_list: [], // 	穿衣指数
      makeup_index_list: [], // 化妆指数
      climate_list: [], // 气象
      included_type_list: [], // 设备名称List
      included_type_loading: false,
      BEHAVIOR_list: [], // 行为list
      BEHAVIOR_loading: false,
      BUSINESS_DISTRICT_list: [],
      REGION_list: []
    }
  },

  mounted() {
    // 操作系统list
    this.getDicts('gdt_api_os').then((response) => {
      let user_os_ios_list = response.data.filter((f) => f.dictValue.indexOf('IOS') !== -1)
      let user_os_android_list = response.data.filter((f) => f.dictValue.indexOf('ANDROID') !== -1)
      let user_os_list = [
        {
          dictValue: 'IOS 系统',
          dictLabel: 'IOS',
          children: user_os_ios_list
        },
        {
          dictValue: 'Android 系统',
          dictLabel: 'Android',
          children: user_os_android_list
        }
      ]
      this.user_os_list = user_os_list
    })
    this.getDicts('gdt_api_marriage_status').then((response) => {
      this.marital_status_list = response.data
    })
    this.getDicts('gdt_api_education').then((response) => {
      this.education_list = response.data
    })
    this.getDicts('gdt_api_living_status').then((response) => {
      this.working_status_list = response.data
    })
    //	排除已转化人群定向（gdt）
    this.getDicts('gdt_api_excluded_converted_audience').then((response) => {
      this.excluded_dimension_list = response.data
    })
    //	广告优化目标类型
    this.getDicts('gdt_int_optimization_goal').then((response) => {
      this.excluded_converted_audience_list = response.data
    })
    // 上网场景
    this.getDicts('gdt_api_online_scenario').then((response) => {
      this.network_scene_list = response.data
    })
    // 联网方式
    this.getDicts('gdt_api_network_type').then((response) => {
      this.network_type_list = response.data
    })

    // 联网方式
    this.getDicts('gdt_api_network_operator').then((response) => {
      this.network_operator_list = response.data
    })
    // 设备价格
    this.getDicts('gdt_api_device_price').then((response) => {
      this.device_price_list = response.data
    })
    // 化妆指数，
    this.getDicts('gdt_api_makeup_index').then((response) => {
      this.makeup_index_list = response.data
    })
    // 紫外线指数
    this.getDicts('gdt_api_u_v_index').then((response) => {
      this.uv_index_list = response.data
    })
    // 穿衣指数
    this.getDicts('gdt_api_dress_index').then((response) => {
      this.dressing_index_list = response.data
    })
    // 气象
    this.getDicts('gdt_api_climate').then((response) => {
      this.climate_list = response.data
    })

    // 设备品牌型号

    selectOne().then((res) => {
      if (res.code === 200) {
        this.accountId = res.data.accountId
      }
    })
  },

  methods: {
    /**
     * @description 获取定向标签
     */
    getTargetingTagsFun(type, tagSpec = null) {
      if (type === 'DEVICE_BRAND_MODEL') {
        if (this.included_type_list.length) return
        this.included_type_loading = true
        getTargetingTags({ accountId: this.accountId, type, tagSpec }).then((res) => {
          this.included_type_loading = false
          this.included_type_list = this.getCascaderData(res.data)
        })
      }

      if (type === 'REGION') {
        getTargetingTags({ accountId: this.accountId, type, tagSpec }).then((res) => {
          this.REGION_list = this.getCascaderData(res.data)
        })
      }

      // if (type === 'BUSINESS_DISTRICT_list') {
      // }

      if (type === 'BEHAVIOR') {
        if (this.BEHAVIOR_list.length) return
        this.BEHAVIOR_loading = true
        getTargetingTags({
          accountId: this.accountId,
          type,
          tagSpec: {
            behavior_spec: {
              query_mode: 'TARGETING_TAG_QUERY_MODE_COMMON'
              // query_spec: {
              //   query: '',
              //    query_list: [],
              //     max_result_number:50,
              // }
            }
          }
        }).then((res) => {
          this.BEHAVIOR_loading = false
          let level_one = res.data.filter((f) => f.parent_id === '0')
          res.data.map((item) => {
            level_one.map((level) => {
              if (level.id === item.parent_id) {
                level.children = level.children || []
                level.children.push(item)
              }
            })
          })
          this.BEHAVIOR_list = level_one
        })
      }
    },

    getCascaderData(data) {
      let newData = data.filter((f) => f.parent_id === '0')
      data.map((item) => {
        newData.map((newItem) => {
          if (newItem.id === item.parent_id) {
            newItem.children = newItem.children || []
            newItem.children.push(item)
          }
        })
      })
      return newData
    },

    onSubmit() {
      let params = { ...this.form }
      // 格式化年龄
      if (typeof params.age === 'string' && params.age !== '') {
        let [min, max] = params.age.split(',')
        params.age = [+min, +max]
      }
      // 格式化人生阶段
      if (params.marital_status.length !== 0) {
        let marital_status = []
        this.form.marital_status.map((item) => {
          marital_status = [...marital_status, ...item.split(',')]
        })
        params.marital_status = marital_status
      } else {
        params.marital_status = ''
      }

      // 格式化学历
      if (params.education.length !== 0) {
        let education = []
        this.form.education.map((item) => {
          education = [...education, ...item.split(',')]
        })
        params.education = education
      } else {
        params.education = ''
      }

      // 格式化操作系统
      if (params.user_os_label !== '') {
        params.user_os = [...params.user_os_ios, ...params.user_os_android]
      }

      // 格式化网络
      if (params.network_type !== '') {
        params.network_type = params.network_type.split(',')
      }

      // 删除辅助字段
      delete params.age_label
      delete params.marital_status_label
      delete params.education_label
      console.log(params)
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  margin: 0;
  padding: 0;
}

.background {
  background: #f7f8fa;
  padding: 10px;
  border-radius: 10px;
}
</style>
