<!--
 * @Description: 微信广告定向
 * @Date: 2021-12-01 09:15:29
 * @LastEditTime: 2022-03-18 15:55:26
-->

<template>
  <div>
    <!-- 列表查询 -->
    <el-form ref="search" :inline="true" :model="search" class="search-form search-form-4" @submit.native.prevent>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="定向名称" prop="name">
            <el-input v-model="search.name" placeholder="请输入定向名称"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="创建人" prop="createBy">
            <el-input v-model="search.createBy" placeholder="请输入创建人"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="创建时间" prop="createTime">
            <el-date-picker v-model="search.createTime" type="daterange" value-format="yyyy-MM-dd" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" round @click="getPage">查询</el-button>
            <el-button round @click="resetFormQuery('search')">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div class="justify-sb">
      <el-dropdown @command="handleCommand">
        <el-button type="primary"> 批量操作<i class="el-icon-arrow-down el-icon--right"></i> </el-button>
        <el-dropdown-menu slot="dropdown">
          <!-- :disabled=""  -->
          <el-dropdown-item command="0" :disabled="selectRow.length < 2 || !selectRow.every((item) => item.projectId === selectRow[0].projectId)">修改项目</el-dropdown-item>
          <el-dropdown-item command="1" :disabled="selectRow.length < 2 || !selectRow.every((item) => item.projectId === selectRow[0].projectId)">修改产品</el-dropdown-item>
          <el-dropdown-item command="2" :disabled="selectRow.length < 2">批量删除</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <router-link :to="{ name: 'addWachatAudienceTemp' }">
        <el-button type="primary" round @click="sync_account_visible = true"><i class="el-icon-plus"> 新建定向 </i></el-button>
      </router-link>
    </div>

    <!-- 表格 -->
    <TablePane class="mt-20" :data-source="dataSource" @getPage="getPage"> </TablePane>

    <!-- 批量修改 -->
    <el-dialog :title="commandType === '0' ? '批量修改关联项目' : commandType === '1' ? '批量修改关联产品' : '批量删除'" :visible.sync="batchInfo_visible" width="620px" :modal-append-to-body="false" destroy-on-close>
      <div v-if="commandType === '2'" class="justify-center">
        <h3>确定要删除定向吗？</h3>
      </div>

      <el-form v-else ref="ruleForm2" :model="ruleForm2" label-width="100px" class="demo-ruleForm">
        <div v-if="commandType === '0'">
          <el-form-item label="项目:" prop="projectId" :rules="{ required: true, message: '请选择关联项目', trigger: 'change' }">
            <SearchSelect
              v-model="ruleForm2.projectId"
              :options="projectList"
              :props="{
                label: 'projectName',
                value: 'bsProjectId'
              }"
              style="width:400px"
              placeholder="请选择项目"
              @change="(value) => (selectProjectId = value)"
            />
          </el-form-item>
        </div>

        <div v-else>
          <div v-if="commandType === '1'">
            <el-form-item label="关联产品:">
              <el-select v-model="ruleForm2.productId" style="width:400px" placeholder="请选择产品">
                <el-option label="不限" :value="0"></el-option>
                <el-option v-for="(itm, idx) in productListed" :key="idx" :label="itm.productName" :value="itm.bsProductId"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('ruleForm2')">确定</el-button>
        <el-button @click="resetForm('ruleForm2')">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { targetingsDelete, targetingsUpdate } from '@/network/api/assetManagement/assetManageWeChatAdv.js'
import { targetingsPage } from '@/network/api/assetManagement/assetManageWeChatAdv.js'
import TablePane from '@/components/tablePane.vue'
import { getDateStartEnd } from '@/utils/ruoyi'
import accountUinTencent from '@/mixin/accountUin_tencent'

export default {
  components: {
    TablePane
  },
  mixins: [accountUinTencent],
  data() {
    return {
      search: {
        name: null,
        createTime: null,
        createBy: null
      },
      batchInfo_visible: false, // 批量修改
      commandType: '0',
      selectRow: [], // 选中的列
      ruleForm2: {
        projectId: null,
        productId: 0
      },
      dataSource: {
        data: [],
        cols: [
          {
            label: '定向名称',
            prop: 'name'
          },
          {
            label: '关联项目',
            prop: 'projectName'
          },
          {
            label: '关联产品',
            prop: 'productName'
          },
          {
            label: '地域',
            width: 150,
            render: function(record) {
              let areaContent = JSON.parse(record.areaContent)
              return (
                /* eslint-disable */
                <div class="justify-start">
                  <p style="width: 100%" class="text-ellipsis">
                    {areaContent + ''}
                  </p>
                  <p style="width: 86px">等{areaContent.length}个</p>
                </div>
                /* eslint-disable */
              )
            }
          },
          {
            label: '年龄',
            render: function(record) {
              let age = JSON.parse(record.age)
              return <p>{age[0]}</p>
            }
          },
          {
            label: '性别',
            render: function(record) {
              let gender = JSON.parse(record.gender)
              // eslint-disable-next-line
              return gender.length ? gender[0] === 'MALE' ? <el-tag>男</el-tag> : <el-tag type="danger">女</el-tag> : <el-tag type="info">全部</el-tag>
            }
          },
          {
            label: '定向人群',
            render: function(record) {
              let inDmpAudienceLabel = JSON.parse(record.inDmpAudienceName)
              return (
                <el-tooltip placement="top">
                  <div slot="content" style="max-width: 200px">
                    {inDmpAudienceLabel.length ? inDmpAudienceLabel.map((item) => item.name) : '无描述'}
                  </div>
                  <p class="text-ellipsis">{inDmpAudienceLabel.length ? inDmpAudienceLabel.map((item) => item.name) : '-'}</p>
                </el-tooltip>
              )
            }
          },
          {
            label: '排除人群',
            render: function(record) {
              let notDmpAudienceLabel = JSON.parse(record.notInDmpAudienceName)
              return (
                <el-tooltip placement="top">
                  <div slot="content" style="max-width: 200px">
                    {notDmpAudienceLabel.length ? notDmpAudienceLabel.map((item) => item.name) : '无描述'}
                  </div>
                  <p class="text-ellipsis">{notDmpAudienceLabel.length ? notDmpAudienceLabel.map((item) => item.name) : '-'}</p>
                </el-tooltip>
              )
            }
          },
          {
            label: '创建人',
            prop: 'createBy'
          },
          {
            label: '创建时间',
            prop: 'createTime'
          }
        ], // 表格的列数据
        isIndex: true, // 列表序号
        loading: true, // loading
        isOperation: true,
        isSelection: true,
        pageData: {
          total: 0 // 总条数
        },
        handleSelectionChange: (selection) => {
          this.selectRow = selection
        },
        operation: {
          // 表格有操作列时设置
          label: '操作', // 列名
          width: 80, // 根据实际情况给宽度
          data: [
            {
              tooltip: '编辑',
              icon: 'el-icon-edit',
              handleRow: (index, recode) => {
                this.$router.push({
                  name: 'editWachatAudienceTemp',
                  params: {
                    id: recode.id
                  }
                })
              }
            },
            {
              tooltip: '删除', // 操作名称
              icon: 'el-icon-delete', // 按钮类型
              color: '#ff0000',
              handleRow: async (index, record) => {
                this.$confirm('确定要删除该定向吗?', '删除提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'error'
                }).then(async () => {
                  let params = {
                    ids: [record.id]
                  }
                  const { code } = await targetingsDelete(params)
                  if (code === 200) {
                    this.getPage()
                    this.$message.success('删除成功!')
                  }
                })
              }
            }
          ]
        }
      }
    }
  },

  mounted() {
    this.getPage()
  },
  methods: {
    /**
     * @description: 提交数据
     * @param {*} formName ref
     */
    submitForm(formName) {
      if (this.commandType === '2') {
        // 批量删除
        let params = {
          ids: this.selectRow.map((item) => item.id)
        }
        targetingsDelete(params).then((res) => {
          if (res && res.code === 200) {
            this.$message.success('批量删除成功!')
            this.batchInfo_visible = false
            this.selectRow = []
            this.getPage()
          }
        })
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // 关联项目批量修改
            let { projectId } = this.ruleForm2
            if (this.commandType === '0') {
              let params = {
                ids: this.selectRow.map((item) => item.id),
                projectId,
                projectName: this.projectList.filter((item) => item.bsProjectId === projectId)[0].projectName,
                productId: 0,
                productName: '不限'
                // launchId: null,
                // launchName: null
              }
              targetingsUpdate(params).then((res) => {
                if (res && res.code === 200) {
                  this.selectRow = []
                  this.$message.success('项目修改成功!')
                }
              })
            } else if (this.commandType === '1') {
              // 关联产品批量修改
              let params = {
                ids: this.selectRow.map((item) => item.id),
                // projectId: null,
                // projectName: null,
                productId: this.ruleForm2.productId,
                productName: this.ruleForm2.productId !== 0 ? this.productListed.filter((item) => item.bsProductId === this.ruleForm2.productId)[0].productName : '不限'
                // launchId: null,
                // launchName: null
              }
              targetingsUpdate(params).then((res) => {
                if (res && res.code === 200) {
                  this.$message.success('产品修改成功!')
                }
              })
            }
            this.resetFormQuery(formName)
          }
        })
      }
    },

    /**
     * @description: 重置数据
     * @param {*} formName ref
     */
    resetForm(formName) {
      this.batchInfo_visible = false
      this.$refs[formName].resetFields()
    },

    resetFormQuery(formName) {
      this.batchInfo_visible = false

      this.$refs[formName].resetFields()
      setTimeout(() => {
        this.getPage()
      }, 300)
    },

    /**
     * @description: 批量操作
     * @param {*} value 修改的项
     */
    handleCommand(value) {
      this.selectProjectId = this.selectRow[0].projectId
      if (value === '0') {
        this.ruleForm2 = {
          projectId: +this.selectRow[0].projectId,
          productId: null,
          launch: null
        }
      } else if (value === '1') {
        this.ruleForm2 = {
          projectId: null,
          productId: +this.selectRow[0].productId,
          launch: null
        }
      }
      this.commandType = value
      this.batchInfo_visible = true
    },

    /**
     * @description: 获取列表
     * @param {*} params
     */
    getPage(params = { pageNum: 1, pageSize: 10 }) {
      this.dataSource.loading = true
      const [startCreateTime, endCreateTime] = getDateStartEnd(this.search.createTime)
      let search = {
        ...this.search,
        startCreateTime,
        endCreateTime
      }
      delete search.createTime
      targetingsPage({ ...search, ...params }).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          // records
          this.dataSource.data = records
          this.dataSource.pageData.total = total
          this.dataSource.loading = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  margin: 0;
  padding: 5px 0;
}
.account-info {
  display: flex;
}
</style>
