<template>
  <div>
    <a-menu v-model="current" mode="horizontal" class="m-a-menu">
      <!--  第一级 -->
      <a-menu-item key="/home">
        <router-link to="/home" tag="span" class="submenu-title">
          <i :class="`iconfont icon-home`" style="color: #000000a6; " />
          <span class="title" @click="titleClick('home')">首页</span>
        </router-link>
      </a-menu-item>
      <a-sub-menu v-for="(itemOne, index) in menu" :key="index">
        <span slot="title" class="submenu-title-wrapper submenu-title">
          <i :class="`iconfont ${itemOne.remarks}`" style="color: #000000a6" />
          <span class="title">{{ itemOne.menuName }}</span>
        </span>
        <!--  第二级 -->
        <a-menu-item-group v-for="( itemTwo, tindex ) in itemOne.children" :key="tindex" :title="itemTwo.menuName">
          <!--  第三级 -->
          <a-menu-item v-for="itemThree in itemTwo.children" :key="itemThree.url">
            <router-link :to="itemThree.url" tag="div">
              <span @click="titleClick(itemThree)">{{ itemThree.menuName }}</span>
            </router-link>
          </a-menu-item>
        </a-menu-item-group>
      </a-sub-menu>
    </a-menu>
  </div>
</template>
<script>
import { Menu } from 'ant-design-vue'

import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    'a-menu': Menu,
    'a-sub-menu': Menu.SubMenu,
    'a-menu-item': Menu.Item,
    'a-menu-item-group': Menu.ItemGroup
  },

  data() {
    return {
      current: ['/home']
    }
  },

  computed: {
    ...mapGetters(['isCollapse', 'menu', 'breadcrumbMenu'])
  },

  watch: {
    $route: {
      handler: function (val) {
        this.current = [val.path]
      }
    }
  },

  mounted() {
    this.SET_BREADCRUMB_MENU([{ url: '/home', name: '首页' }])
  },

  methods: {
    ...mapMutations('user', ['SET_SIDE_MENU', 'SET_BREADCRUMB_MENU']),

    titleClick(menuItem) {
      if (!menuItem.url || menuItem === 'home' || menuItem.remarks === '') {
        return this.SET_SIDE_MENU([])
      }

      let breadcrumbMenu = this.breadcrumbMenu
      let item = {
        url: menuItem.url,
        name: menuItem.menuName
      }
      if (this.breadcrumbMenu.length === 0 || !breadcrumbMenu.some((s) => s.url === menuItem.url)) {
        breadcrumbMenu.push(item)
        this.SET_BREADCRUMB_MENU(breadcrumbMenu)
      }

      this.SET_SIDE_MENU(this.menu.filter((f) => f.url.indexOf(menuItem.remarks) > -1))
    }
  }
}
</script>

<style lang="scss">
.ant-menu-submenu-popup {
  .ant-menu-sub {
    display: flex !important;
  }

  .ant-menu-item-group {
    padding-top: 5px;
    padding-bottom: 5px;

    .ant-menu-item-group-title {
      border-left: 3px solid #409eff;
      padding: 0px 8px;
      margin-left: 10px;
      margin-top: 5px;
      margin-bottom: 10px;
      font-weight: 600;
      font-size: 15px;
      color: rgb(119, 119, 119);
    }

    .ant-menu-item-group-list {
      .ant-menu-item {
        padding: 0 20px 0 21px;
        line-height: 28px;
        height: 28px;
        margin: 5px 0;
      }

      .ant-menu-item-active {
        color: #72b6f5 !important;
      }
    }
  }

  .ant-menu-item-selected {
    background-color: transparent !important;
  }
}

.submenu-title {
  display: flex;
  align-items: center;

  .title {
    font-size: 15px;
  }
}

.ant-menu-item-selected,
.ant-menu-item-active {
  .iconfont {
    color: #1890ff !important;
  }
}

.ant-menu-submenu-selected,
.ant-menu-submenu-active {
  .ant-menu-submenu-title {
    .iconfont {
      color: #1890ff !important;
    }
  }
}
</style>
