var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "转化追踪激活(应用API)",
        width: "45%",
        visible: _vm.visible,
        "before-close": _vm.close,
        "label-position": "right",
        "modal-append-to-body": false,
        "close-on-press-escape": false,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticStyle: { "text-align": "left" } },
        [
          _c(
            "div",
            {
              staticClass: "justify-sb",
              staticStyle: { padding: "0 40px 20px 40px" },
            },
            [
              _c("p", { staticClass: "tip" }, [
                _vm._v("账户管家: " + _vm._s(_vm.ent.housekeeperName)),
              ]),
              _c("p", { staticClass: "tip" }, [
                _vm._v("投放账户: " + _vm._s(_vm.ent.accountName)),
              ]),
            ]
          ),
          _c(
            "el-form",
            { attrs: { "label-width": "110px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "监测链接URL：", rule: { required: true } } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入推广URL" },
                    model: {
                      value: _vm.url,
                      callback: function ($$v) {
                        _vm.url = $$v
                      },
                      expression: "url",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticStyle: { "margin-left": "28px" } }, [
                _c("p", { staticClass: "tip" }, [_vm._v("推广URL获取")]),
                _c("p", { staticClass: "tip" }, [
                  _vm._v(
                    "1、登录投放账户，于【工具中心】-【转化追踪】中找到对应转化追踪;"
                  ),
                ]),
                _c("p", { staticClass: "tip" }, [
                  _vm._v("2、点击【联调】，并复制“第二步”中的推广URL;"),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { attrs: { round: "" }, on: { click: _vm.close } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", round: "" },
              on: { click: _vm.submit },
            },
            [_vm._v("激活")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }