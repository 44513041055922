import service from '@/network/service/service'

export function Page(data) {
  return service.post('/api/system/assetManagementBaiduAudience/page', data)
}

export function Add(data) {
  return service.post('/api/system/assetManagementBaiduAudience/save', data)
}

export function Update(data) {
  return service.post('/api/system/assetManagementBaiduAudience/update', data)
}

export function Delete(id) {
  return service.delete('/api/system/assetManagementBaiduAudience/' + id)
}

