<!--
 * @Description: 展示Banner图片
 * @Date: 2022-01-06 10:42:13
 * @LastEditTime: 2022-02-24 14:13:03
-->
<template>
  <div>
    <el-form-item label="创意样式：" prop="crtSize">
      <el-radio-group v-model="ruleFormData.crtSize">
        <el-radio :label="925">图片（新）</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="点击跳转：" prop="pageType">
      <el-radio-group
        v-model="ruleFormData.pageType"
        @change="
          (e) => {
            this.$emit('pageTypeChange', e)
          }
        "
      >
        <el-radio :label="4">原生推广页</el-radio>
        <el-radio :label="6">公众号详情页</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item v-if="ruleFormData.pageType === 4" label="点击按钮跳转：" prop="buttonPageType">
      <el-radio-group v-model="ruleFormData.buttonPageType">
        <el-radio :label="4">原生推广页</el-radio>
        <el-radio :label="40024">一键关注</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="创意名称：" prop="tname" :rules="{ required: true, maxlength: 60, message: '请输入创意名称' }">
      <el-input v-model="ruleFormData.tname" rows="4" type="textarea" style="width: 400px" placeholder="请输入创意名称" maxlength="60" show-word-limit />
    </el-form-item>
  </div>
</template>

<script>
export default {
  props: {
    ruleFormData: {
      type: Object,
      default: () => {}
    },
    taskId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      ruleForm: this.ruleFormData
    }
  },
  methods: {}
}
</script>

<style lang="scss"></style>
