<template>
  <div class="err-404">
    <div class="content">
      <h1 class="title">404</h1>
      <p class="text">抱歉，你访问的页面不存在</p>
      <el-button type="primary" style="width: 100px" @click="$router.push('/')">
        返回首页</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Err404',
  data () {
    return {}
  }
}
</script>

<style scoped lang="scss">
.err-404 {
  margin-top: 200px;

  .content {
    padding-left: 40px;
    text-align: center;
  }

  .title {
    margin-top: 80px;
    font-size: 72px;
    color: #434e59;
    font-weight: 600;
    line-height: 72px;
    margin-bottom: 24px;
  }

  .text {
    color: rgba(0, 0, 0, 0.45);
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 16px;
  }
}
</style>
