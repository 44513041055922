<!--
 * @Description: 固定回传
 * @Date: 2021-10-21 09:12:51
 * @LastEditTime: 2022-03-25 17:13:01
-->
<template>
  <section>
    <el-form-item prop="return_condition" label-width="30px">
      <el-radio-group v-model="ruleData.return_condition">
        <el-row>
          <el-col v-for="item in returnConditionOption" :key="item.value" class="mt-10">
            <el-radio :label="item.value">{{ item.title }}</el-radio>
          </el-col>
        </el-row>
      </el-radio-group>
    </el-form-item>
    <el-form-item prop="is_few_return" label="部分订单回传" label-width="130px">
      <el-switch v-model="ruleData.is_few_return"></el-switch>
    </el-form-item>

    <!-- 部分订单回传开启 -->
    <template v-if="ruleData.is_few_return">
      <el-form-item label="回传方式" prop="return_type" :rules="[{ required: true }]">
        <el-select v-model="ruleData.return_type" style="width:400px" @change="changeReturnType">
          <el-option label="百分比回传" :value="1" />
          <el-option label="循环比例回传" :value="2" />
        </el-select>
      </el-form-item>

      <!-- 百度循环比例1:1 - 5:1 -->
      <div style="display: flex;">
        <el-form-item v-if="ruleData.return_type === 2 && type !== 'baidu'" label="循环比例" prop="cycle_proportion" :rules="[{ required: true }]">
          <el-select v-model="ruleData.cycle_proportion" style="width:400px" @change="changeCycleProportion">
            <el-option v-for="item in cycleProportionOption" :key="item.value" :label="item.title" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item style="margin-left: 5px" prop="customize_cycle_proportion_start" :rules="[{ required: ruleData.cycle_proportion === '0:0',message: '填入传几单!' }]" class="customize_cycle_proportion">
          <el-input v-if="ruleData.cycle_proportion === '0:0'" v-model="ruleData.customize_cycle_proportion_start" style="width:100px;" placeholder="填入传几单"></el-input>
        </el-form-item>
        <div v-if="ruleData.cycle_proportion === '0:0'" style="margin-top: 6px; padding:0 5px">:</div>
        <el-form-item prop="customize_cycle_proportion_end" :rules="[{ required: ruleData.cycle_proportion === '0:0',message: '填入卡几单!' }]" class="customize_cycle_proportion">
          <el-input v-if="ruleData.cycle_proportion === '0:0'" v-model="ruleData.customize_cycle_proportion_end" style="width:100px" placeholder="填入卡几单"></el-input>
        </el-form-item>
        <el-form-item v-if="ruleData.return_type === 2 && type !== 'baidu'" class="customize_cycle_proportion" style="margin-left: 5px" prop="is_first_return" :rules="[{ required: true }]">
          <el-select v-model="ruleData.is_first_return" style="width:150px">
            <el-option label="优先回传" :value="1" />
            <el-option label="优先卡单" :value="2" />
          </el-select>
        </el-form-item>
      </div>
      <!-- 百度循环比例1-10 -->
      <el-form-item v-if="ruleData.return_type === 2 && type === 'baidu'" label="循环比例" prop="cycle_proportion" :rules="[{ required: true }]">
        <el-select v-model="ruleData.cycle_proportion" multiple style="width:400px">
          <el-option v-for="item in cycleProportionOption_baidu" :key="item.value" :label="item.title" :value="item.value" />
        </el-select>
        <p class="tip" style="padding-bottom: 0px; margin-bottom: 0px">勾选不回传订单序号</p>
      </el-form-item>

      <el-form-item v-if="ruleData.return_type === 2" label="循环规则" prop="cycle_proportion">
        <el-radio-group v-model="ruleData.cycle_rule">
          <el-radio :label="1">按订单循环</el-radio>
          <el-radio :label="2">每日重置</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item v-if="ruleData.return_type === 1" label="百分比回传" prop="return_rate" :rules="[{ required: true }]">
        <el-radio-group v-model="ruleData.return_rate">
          <el-radio v-for="item in returnRateOption" :key="item.value" :label="item.value">{{ item.title }}</el-radio>
        </el-radio-group>
      </el-form-item>
    </template>

    <el-form-item prop="date" label="启用时间" :rules="[{ required: true }]">
      <el-time-picker v-model="ruleData.date" is-range range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间"> </el-time-picker>
    </el-form-item>

    <el-form-item v-if="ruleData.platform === 'gdt'" prop="is_on_sale" label="回传金额调整">
      <el-switch v-model="ruleData.is_on_sale" @change="changeSale"></el-switch>
    </el-form-item>
    <div style="display: flex;">
      <el-form-item v-if="ruleData.is_on_sale" style="margin-left: 30px" prop="discount" :rules="[{ required: ruleData.discount === 0 || ruleData.discount === null, message: '请输入缩减比例!' }]" class="customize_cycle_proportion">
        <el-input-number v-if="ruleData.is_on_sale" v-model="ruleData.discount" :min="0" :precision="2" style="width:150px;" placeholder="输入缩减比例"></el-input-number>
      </el-form-item>
      <div v-if="ruleData.is_on_sale" style="margin-top: 6px; padding: 0 10px">%</div>
    </div>
  </section>
</template>

<script>
import { returnConditionOption, returnRateOption, cycleProportionOption, cycleProportionOption_baidu } from '../../data'
export default {
  props: {
    ruleData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      returnConditionOption,
      returnRateOption,
      cycleProportionOption,
      cycleProportionOption_baidu,
      type: 'huge'
    }
  },
  mounted() {
    this.type = this.$route.params.type
  },
  methods: {
    changeReturnType(val) {
      if (val === 1) {
        this.ruleData.cycle_proportion = this.ruleData.platform === 'baidu' ? [] : '1:1'
        this.ruleData.customize_cycle_proportion_start = null
        this.ruleData.customize_cycle_proportion_end = null
        this.ruleData.is_first_return = null
      } else {
        this.ruleData.is_first_return = 1
      }
    },
    changeCycleProportion(val) {
      if (val !== '0:0') {
        this.ruleData.customize_cycle_proportion_start = null
        this.ruleData.customize_cycle_proportion_end = null
      }
    },
    changeSale(val) {
      if (!val) {
        this.ruleData.discount = 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .customize_cycle_proportion {
      ::v-deep .el-form-item__content {
      margin:0 !important
    }
  }
</style>
