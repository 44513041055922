var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "table-template",
      attrs: {
        title: "元素类型",
        visible: _vm.show,
        "append-to-body": true,
        width: "700px",
        top: "20vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.onCloseDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { inline: false, model: _vm.form, rules: _vm.rules },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "类型名称", prop: "typeName" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请输入类型名称" },
                    model: {
                      value: _vm.form.typeName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "typeName", $$v)
                      },
                      expression: "form.typeName",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { type: "primary", loading: _vm.saveLoading },
                      on: { click: _vm.handleAddType },
                    },
                    [_vm._v("添加 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { data: _vm.tableData, height: "300px" },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "序号" },
              }),
              _c("el-table-column", {
                attrs: { label: "名称", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return _c(
                        "div",
                        {},
                        [
                          !row.isEdit
                            ? _c(
                                "span",
                                { staticClass: "edit-input-box" },
                                [
                                  _vm._v(_vm._s(row.name) + " "),
                                  row.canDel
                                    ? _c("el-button", {
                                        staticClass: "el-icon-edit",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleEdit(row)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _c("el-input", {
                                staticStyle: { width: "100px" },
                                attrs: { type: "text", autofocus: true },
                                on: {
                                  blur: function ($event) {
                                    return _vm.handleEditSave(row)
                                  },
                                },
                                model: {
                                  value: row.name,
                                  callback: function ($$v) {
                                    _vm.$set(row, "name", $$v)
                                  },
                                  expression: "row.name",
                                },
                              }),
                        ],
                        1
                      )
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.canDel
                          ? _c("el-button", {
                              attrs: { type: "text", icon: "el-icon-delete" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(row.id)
                                },
                              },
                            })
                          : _c("span", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "btn", on: { click: _vm.closeModal } },
            [_vm._v("关 闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }