/*
 * @Description: Do not edit
 * @Date: 2021-12-01 14:18:39
 * @LastEditTime: 2022-03-22 09:48:41
 */
import service from '@/network/service/service'

// 微信广告程序化任务列表
export function Page(data) {
  return service.post('/api/tencent/adMpManagementTask/page', data)
}

export function Delete(taskId) {
  return service.delete(`/api/tencent/adMpManagementTask/${taskId}`, {
    data: { taskId: [taskId] }
  })
}

export function deleteTaskId(taskId) {
  return service.delete(`/api/tencent/adMpManagementTask/${taskId}`)
}

// 获取推广计划
export function getPromotionTarget() {
  return service.get('/api/tencent/adMpManagementTask/getPromotionTarget')
}

// 程序化批量-新建（项目、产品、服务商、投放账户）和userId 预设
export function saveTask(data) {
  return service.post('/api/tencent/adMpManagementTask/saveTask', data)
}

export function updateBaseInfo(data) {
  return service.post('/api/tencent/adMpManagementTask/update', data)
}

// 通过TaskId获取程序化整体数据-程序化
export function byTaskIdGetAll(taskId) {
  return service.get(`/api/tencent/adMpManagementTask/getBasicAndConfigurationArea/${taskId}`)
}

// 投放位置 Crt_size字段
export function getCrtSize(type, isFriendCircle = true) {
  return service.get('/api/tencent/adMpManagementTask/getCrtSize', {
    params: {
      type,
      isFriendCircle
    }
  })
}

// 创建/修改配置区计划
export function savePlan(data) {
  return service.post('/api/tencent/adMpManagementTaskPlanCampaign/saveOrUpdate', data)
}

// 修改出价
export function updatePlan(data) {
  return service.post('/api/tencent/adMpManagementGenerateRelation/update', data)
}

// 优化目标枚举+二级
export function getOptimizeTheTarget(type, isFriendCircle = true) {
  return service.get('/api/tencent/adMpManagementTask/getOptimizeTheTarget', {
    params: {
      type,
      isFriendCircle
    }
  })
}

// 程序化批量-新建（项目、产品、服务商、投放账户）和userId 预设
export function saveAd(data) {
  return service.post('/api/tencent/adMpManagementTaskPlanAdGroup/save', data)
}

// 修改配置区广告
export function updateAd(data) {
  return service.post('/api/tencent/adMpManagementTaskPlanAdGroup/update', data)
}

// 保存广告创意
export function saveCreative(data) {
  return service.post('/api/tencent/adMpManagementTaskMaterial/save', data)
}

// 修改选广告创意
export function updateCreative(data) {
  return service.post('/api/tencent/adMpManagementTaskMaterial/update', data)
}

// 创建文案
export function saveText(data, type) {
  return service.post(`/api/tencent/${type}/save`, data)
}

// 修改文案
export function updateText(data, type) {
  return service.post(`/api/tencent/${type}/update`, data)
}

// 删除文案
export function deleteText(data, type) {
  return service.delete(`/api/tencent/${type}/${data[0]}`, data)
}

// 创建定向包
export function saveOrientation(data) {
  return service.post('/api/tencent/adMpManagementTaskPlanAdTarget/save', data)
}

// 删除定向包
export function deleteOrientation(adTargetId) {
  return service.delete(`/api/tencent/adMpManagementTaskPlanAdTarget/${adTargetId}`)
}

// 创建落地页
export function saveLdy(data) {
  return service.post('/api/tencent/adMpManagementTaskPage/save', data)
}

// 修改落地页
export function updateLdy(data) {
  return service.post('/api/tencent/adMpManagementTaskPage/update', data)
}

// 删除落地页
export function deleteLdy(taskAssetId) {
  return service.delete(`/api/tencent/adMpManagementTaskPage/${taskAssetId}`)
}

// 保存企微客服组
export function saveService(data) {
  return service.post('/api/tencent/adMpManagementTaskPlanCustomerService/save', data)
}

// 修改企微客服组
export function updateService(data) {
  return service.post('/api/tencent/adMpManagementTaskPlanCustomerService/update', data)
}

// 修改企微客服组
export function getAccountInterface(params) {
  return service.get('/api/tencent/adMpManagementTask/getAccountInterface', {
    params
  })
}

// 查询账户对应人群包
export function getAudienceList(params) {
  return service.get('/api/tencent/adMpTrack/getAudienceList', {
    params
  })
}

// 添加/修改/删除 关联人群包
export function updateSaveAudience(data) {
  return service.post('/api/tencent/adMpManagementTaskAccount/updateSaveAudience', data)
}

// 删除企微客服组
export function deleteService(taskServiceId) {
  return service.delete(`/api/tencent/adMpManagementTaskPlanCustomerService/${taskServiceId}`)
}

// 获取数据源
export function getTrack({ taskAccountId, name }) {
  return service.get(`/api/tencent/adMpTrack/getTrack/${taskAccountId}/${name || '-1'}`)
}

// 添加/修改/删除 关联数据源  数据源
export function updateSource(data) {
  return service.post(`/api/tencent/adMpManagementTaskAccount/update`, data)
}

// 获取商品库
export function getGoodsList(params) {
  return service.get(`/api/tencent/adMpTrack/getGoodsList`, {
    params
  })
}

// 获取商品
export function searchGoods(params) {
  return service.get(`/api/tencent/adMpTrack/searchGoods`, {
    params
  })
}

// 获取保存 dynamic_category_id
export function getDynamicCategoryId(params) {
  return service.get(`/api/tencent/adMpTrack/getDynamicCategoryId`, {
    params
  })
}

// 添加/修改/删除 关联数据源 商品
export function saveProductList(data) {
  return service.post(`/api/tencent/adMpManagementTaskAccount/SaveProductList`, data)
}

// 保存沉浸式横版视频中的页面顶部图片
export function saveImgByVideo(data) {
  return service.post('/api/tencent/adMpManagementTaskImgVideo/saveImg', data)
}

// 创建组名称（素材/文案）
export function saveGroup(data) {
  return service.post('/api/tencent/adMpManagementTaskAssetGroup/save', data)
}

// 删除组名称（素材/文案）
export function deleteGroup(assetGroupId) {
  return service.DELETE(`/api/tencent/adMpManagementTaskAssetGroup/${assetGroupId}`)
}

// 保存创意素材图片或视频
export function saveImgVideo(data) {
  return service.post('/api/tencent/adMpManagementTaskImgVideo/save', data)
}

// 修改创意素材图片或视频
export function updateImgVideo(data) {
  return service.post('/api/tencent/adMpManagementTaskImgVideo/update', data)
}

// 保存沉浸式横版视频中的页面顶部图片
export function saveImg(data) {
  return service.post('/api/tencent/adMpManagementTaskImgVideo/saveImg', data)
}

// 删除创意素材图片或视频
export function deleteImgVideo(data) {
  return service.delete(`/api/tencent/adMpManagementTaskImgVideo/${data}`, {
    imgVideoId: [data]
  })
}

// 生成广告
export function generateCode(taskId) {
  return service.get(`/api/tencent/adMpManagementTask/generateCode/${taskId}`)
}

// 生成广告
export function getGenerateStatus(taskId) {
  return service.get(`/api/tencent/adMpManagementTask/getGenerateStatus/${taskId}`)
}
// （预览广告）
export function getGenerateList(taskId) {
  return service.get(`/api/tencent/adMpManagementTask/getGenerateList/${taskId}`)
}

// 批量发布task

export function publishTask(taskId) {
  return service.get(`/api/tencent/adMpManagementTask/pushBatchPlan/${taskId}`)
}

// 查看详情
export function showDetails(data) {
  return service.post(`/api/tencent/adMpManagementTask/details`, data)
}

// --------------------------------- --------------------------------- ---------------------------------

// 获取推广计划
export function getPlan(data) {
  return service.post('/api/tencent/adManagementGdtCampaigns/page', data)
}

// 获取推广计划
export function getLdy(data) {
  return service.post(`/api/tencent/adManagementGdtPages/page`, data)
}

// 通过TaskId获取程序化整体数据-程序化

// 修改
export function updateTask(data) {
  return service.post('/api/tencent/adManagementGdtPublishTash/update', data)
}

// 上传素材图片视-程序化
export function imgOrVideoUploadGdt(data) {
  return service.post('/api/tencent/adManagementGdtPublishTash/imgOrVideoUploadGdt', data)
}

// 腾讯批量_删除素材
export function deleteAssetImgOrVideo({ taskId, creativeGroup, localId }) {
  return service.delete(`/api/tencent/adManagementGdtPublishTash/deleteAssetImgOrVideo/${taskId}/${creativeGroup}/${localId}`)
}

// 获取添加广告组账户和计划信息-
export function getAccountAndPlan(taskId) {
  return service.get(`/api/tencent/adManagementGdtPublishTash/getAccountAndPlan/${taskId}`)
}

// 创建广告主-----关联表(复用)-程序化保存计划-广告组-落地页-商品标识
export function saveAdv(lists) {
  return service.post('/api/tencent/adManagementGdtPublishTash/saveAdv', { lists })
}

// 创建落地页------关联表(复用)-程序化保存计划-广告组-落地页-商品标识
export function savePage(list) {
  return service.post('/api/tencent/adManagementGdtPublishTash/savePage', { list })
}

// /广告主list
export function getAdGroups(data) {
  return service.post('/api/tencent/adManagementGdtAdgroups/page', data)
}

// 品牌列表
export function getBrand(data) {
  return service.post('/api/tencent/adManagementGdtBrand/page', data)
}

// 预览广告
export function showPreview({ taskId, pageNum, pageSize }) {
  return service.get(`/api/tencent/adManagementGdtPublishTash/showPreview/${taskId}/${pageNum}/${pageSize}`)
}

// 创建品牌
export function saveBrand(list) {
  return service.post(`/api/tencent/adManagementGdtPublishTash/saveBrand`, { list })
}

// 批量发布task
export function deleteTask(id) {
  return service.delete(`/api/tencent/adManagementGdtPublishTash/${id}`)
}

// 重新提交
export function againPublishTask(taskId, accountCampaingsId) {
  return service.get(`/api/tencent/adManagementGdtPublishTash/publishTask/${taskId}/${accountCampaingsId}`)
}

// 删除 计划/广告组/落地页/品牌
export function deleteRelation(data) {
  return service.post(`/api/tencent/adManagementGdtPublishTash/deleteRelation`, data)
}
