export const searchForm = {
  logId: null,
  woodenFishId: null,
  shareBaiduId: null,
  shareAccountName: null,
  shareStatus: null,
  pageId: null,
  pageName: null,
  sharePageId: null,
  sharePageName: null,
  housekeeperId: null,
  housekeeperName: null,
  baiduId: null,
  accountName: null,
  deleted: null,
  createBy: null,
  createTime: null,
  updateBy: null,
  updateTime: null,
  remark: null
}

export class AssetManagementBaiduWoodenFishLogEnt {
  logId
  woodenFishId
  shareBaiduId
  shareAccountName
  shareStatus
  sharePageId
  sharePageName
  housekeeperId
  housekeeperName
  baiduId
  accountName
  pageId
  pageName
  deleted
  createBy
  createTime
  updateBy
  updateTime
  remark
}

export const formRules = {
  logId: [{ required: true, message: '请填写', trigger: 'change' }],
  woodenFishId: [{ required: true, message: '请填写基木鱼id', trigger: 'change' }],
  shareBaiduId: [{ required: true, message: '请填写分享投放账户id', trigger: 'change' }],
  shareAccountName: [{ required: true, message: '请填写分享投放账户', trigger: 'change' }],
  shareStatus: [{ required: true, message: '请填写分享状态', trigger: 'change' }],
  pageId: [{ required: true, message: '请填写落地页ID', trigger: 'change' }],
  pageName: [{ required: true, message: '请填写落地页名称', trigger: 'change' }],
  deleted: [{ required: true, message: '请填写逻辑删除', trigger: 'change' }],
  createBy: [{ required: true, message: '请填写创建者', trigger: 'change' }],
  createTime: [{ required: true, message: '请填写创建时间', trigger: 'change' }],
  updateBy: [{ required: true, message: '请填写更新者', trigger: 'change' }],
  updateTime: [{ required: true, message: '请填写更新时间', trigger: 'change' }],
  remark: [{ required: true, message: '请填写备注', trigger: 'change' }]
}
