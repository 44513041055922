<!--
 * @Description: Do not edit
 * @Date: 2021-09-20 12:51:36
 * @LastEditTime: 2021-09-28 15:54:32
-->
<template>
  <box>
    <template slot="title">落地页信息</template>
    <el-form-item>
      <template slot="label">
        点击监测URL
        <el-tooltip class="item" effect="dark" content="用于检测广告的点击效果。" placement="top">
          <i class="el-icon-question" />
        </el-tooltip>
      </template>
      <el-input v-model="sizeForm.monitorUrl" maxlength="1024" style="width: 600px" />
    </el-form-item>
  </box>
</template>

<script>
import Box from './box.vue'
export default {
  components: {
    Box
  },
  props: {
    sizeForm: {
      type: Object,
      default: () => {
        return {
          monitorUrl: ''
        }
      }
    }
  },
  data() {
    return {
      UPLOAD_URL: process.env.VUE_APP_UPLOAD_URL,
      imageUrl: ''
    }
  }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #838383;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
