<template>
  <el-dialog
    :title="isEdit ? '编 辑': '新 增'"
    width="45%"
    :visible.sync="visible"
    :before-close="close"
    label-position="right"
    :modal-append-to-body="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <el-form ref="ent" :model="ent" :rules="rules" label-width="110px">
      <el-form-item label="ID" prop="id">
        <el-input v-model="ent.id" />
      </el-form-item>
      <el-form-item label="操作人" prop="operator">
        <el-input v-model="ent.operator" />
      </el-form-item>
      <el-form-item label="操作人ID" prop="operatorId">
        <el-input v-model="ent.operatorId" />
      </el-form-item>
      <el-form-item label="IP" prop="ip">
        <el-input v-model="ent.ip" />
      </el-form-item>
      <el-form-item label="uri" prop="uri">
        <el-input v-model="ent.uri" />
      </el-form-item>
      <el-form-item label="请求参数" prop="parameter">
        <el-input v-model="ent.parameter" />
      </el-form-item>
      <el-form-item label="请求参数" prop="payload">
        <el-input v-model="ent.payload" />
      </el-form-item>
      <el-form-item label="日期分片字段" prop="partitionField">
        <el-input v-model="ent.partitionField" />
      </el-form-item>
      <el-form-item label="创建时间" prop="createTime">
        <el-input v-model="ent.createTime" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {
  Add,
  Update
} from '@/network/api/adminLog'
import { AdminLogEnt, formRules } from './object'

export default {
  inject: ['loadData'],
  data() {
    return {
      ent: new AdminLogEnt(),
      rules: formRules,
      isEdit: false,
      visible: false,
      cityList: []
    }
  },
  methods: {
    somethingFunction() {
      console.log('xxxx')
    },
    submit() {
      this.$refs.ent.validate((validate) => {
        if (validate) {
          this.isEdit ? this.updateRow() : this.addRow()
        }
      })
    },
    addRow() {
      const req = { ...this.ent }
      Add(req).then(res => {
        this.$message({
          message: '保存成功',
          type: 'success'
        })
        this.loadData()
        this.close()
      })
    },
    updateRow() {
      const req = { ...this.ent }
      Update(req).then(res => {
        this.$message({
          message: '修改成功',
          type: 'success'
        })
        this.loadData()
        this.close()
      })
    },
    close() {
      this.visible = false
      this.$nextTick(() => {
        this.$refs.ent.resetFields()
      })
    },
    show(isEdit = false, ent) {
      this.ent = ent
      this.isEdit = isEdit
      this.visible = true
      this.$nextTick(() => {
        if (this.isEdit) {
          console.log()
        }
      })
    }
  }
}
</script>
