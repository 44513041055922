var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "el-select",
        _vm._g(
          _vm._b(
            {
              staticStyle: { width: "460px" },
              attrs: {
                value: _vm.value,
                loading: _vm.loading,
                "allow-create": "",
                "remote-method": _vm.remoteMethod,
                filterable: "",
                clearable: "",
                remote: "",
                "reserve-keyword": "",
              },
              on: { change: _vm.changeOptions },
            },
            "el-select",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        _vm._l(_vm.text_options, function (item) {
          return _c("el-option", {
            key: item.id,
            attrs: { label: item.text, value: item.text },
          })
        }),
        1
      ),
      _c(
        "span",
        {
          staticClass: "tip ml-10",
          style: {
            color: _vm.value.length > _vm.maxLength ? "#f80000" : "#7d7d7d",
          },
        },
        [_vm._v(" " + _vm._s(_vm.value.length) + " / " + _vm._s(_vm.maxLength))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }