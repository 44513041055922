var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Search", { ref: "search" }),
      _c(
        "el-row",
        {
          staticClass: "operate-box",
          attrs: { type: "flex", justify: "space-between" },
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-plus", round: "" },
              on: { click: _vm.showAdd },
            },
            [_vm._v("新 增")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-container" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "list-table",
              attrs: { stripe: "", data: _vm.data },
            },
            [
              _c("c-data-empty", { attrs: { slot: "empty" }, slot: "empty" }),
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  width: "80",
                  align: "left",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "masterUid", label: "账户ID", align: "left" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "masterName",
                  label: "账户名称",
                  align: "left",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.showTbAdd(row)
                              },
                            },
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content: "同步投放账号",
                                  placement: "top",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-upload2",
                                  staticStyle: { "font-size": "16px" },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("c-pagination", {
        attrs: {
          "page-size": _vm.pageInfo.pageSize,
          "page-no": _vm.pageInfo.pageNum,
          total: _vm.pageInfo.total,
          "get-data-fun": _vm.loadData,
        },
        on: {
          "update:pageSize": function ($event) {
            return _vm.$set(_vm.pageInfo, "pageSize", $event)
          },
          "update:page-size": function ($event) {
            return _vm.$set(_vm.pageInfo, "pageSize", $event)
          },
          "update:pageNo": function ($event) {
            return _vm.$set(_vm.pageInfo, "pageNum", $event)
          },
          "update:page-no": function ($event) {
            return _vm.$set(_vm.pageInfo, "pageNum", $event)
          },
        },
      }),
      _c("OptionDialogSync", {
        attrs: { visible: _vm.visible, "row-account-id": _vm.rowAccountId },
        on: {
          "update:visible": function ($event) {
            _vm.visible = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }