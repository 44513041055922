var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "top-img",
      style: {
        paddingTop: `${_vm.detail.paddingTop}px`,
        paddingBottom: `${_vm.detail.paddingBottom}px`,
      },
    },
    [
      _vm.detail.sightVideoUrl
        ? _c("div", { staticStyle: { width: "auto", height: "260px" } }, [
            _c("video", {
              staticStyle: { height: "100%" },
              attrs: { src: _vm.detail.sightVideoUrl },
            }),
          ])
        : _c("div", { staticStyle: { "text-align": "center" } }, [
            _c("i", { staticClass: "el-icon-video-camera" }),
            _c("p", [_vm._v("视频组件")]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }