<template>
  <div>
    <Search
      ref="search"
      :account-huge-housekeeper-e-n-ts="accountHugeHousekeeperENTs"
      :account-huge-adv-e-n-t-lists="accountHugeAdvENTLists"
      :account-huge-housekeeper-e-n-ts-id="accountHugeHousekeeperENTsId"
    />
    <el-row
      type="flex"
      justify="space-between"
      class="operate-box"
    >
      <el-button
        type="primary"
        icon="el-icon-orange"
        round
        @click="showSync"
      >同步账户</el-button>
    </el-row>
    <el-dialog
      :title="isPublish ? '推送' : '同步账户'"
      :visible="isSync || isPublish"
      width="500px"
      :show-close="false"
      :modal-append-to-body="false"
    >
      <span>
        <el-form
          ref="accountRef"
          :model="account"
          label-width="100px"
        >
          <el-form-item
            label="管家账号"
            prop="accountId"
            :rules="[{ required: true, message: '管家账号必选' }]"
          >
            <el-select
              v-model="account.accountId"
              style="width:300px"
              @change="AdvENTListchange"
            >
              <el-option
                v-for="(item, index) in accountHugeHousekeeperENTs"
                :key="index"
                :label="item.accountName"
                :value="item.accountId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label="广告主账号"
            prop="GGZaccountId"
            :rules="[{ required: true, message: '广告主账号必选' }]"
          >
            <el-select
              v-model="account.GGZaccountId"
              filterable
              multiple
              style="width:300px"
            >
              <el-option
                v-for="(item, index) in accountHugeAdvENTLists[accountHugeHousekeeperENTsId]"
                :key="index"
                :label="item.accountName"
                :value="item.accountId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </span>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          @click="
            ;(isSync = false),
              (isPublish = false),
              (account = {
                accountId: null,
                GGZaccountId: []
              })
          "
        >
          取 消
        </el-button>
        <el-button
          type="primary"
          @click="dialogVisible"
        >确 定</el-button>
      </span>
    </el-dialog>
    <div class="table-container">
      <el-table
        v-loading="loading"
        stripe
        class="list-table"
        :data="data"
      >
        <c-data-empty slot="empty"></c-data-empty>
        <el-table-column
          prop="id"
          label="ID"
          align="left"
          show-overflow-tooltip
        />
        <el-table-column
          prop="name"
          label="转化名称"
          align="left"
          show-overflow-tooltip
        />
        <el-table-column
          prop="convertSourceType"
          label="接入方式"
          align="left"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            <p>{{ csStatus.filter((item) => item.dictValue === row.convertSourceType)[0].dictLabel }}</p>
          </template>
        </el-table-column>

        <el-table-column
          prop="convertType"
          label="转化目标"
          align="left"
        >
          <template slot-scope="{ row }">
            <p>{{ convertType[row.convertType] }}</p>
          </template>
        </el-table-column>

        <el-table-column
          prop="deepExternalAction"
          label="深度转化目标"
          align="left"
        >
          <template slot-scope="{ row }">
            <p>{{ row.deepExternalAction ? convertTypeList.filter((item) => item.dictValue === row.deepExternalAction)[0].dictLabel : '-' }}</p>
          </template>
        </el-table-column>

        <el-table-column
          prop="status"
          label="转化状态"
          align="left"
        >
          <template slot-scope="{ row }">
            <p>{{ row.status === 'AD_CONVERT_STATUS_ACTIVE' ? '活跃（激活）' : '不活跃（未激活）' }}</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="relationHousekeeperName"
          label="管家账号"
          show-overflow-tooltip
          align="left"
        />
        <el-table-column
          prop="relationAdvAccountName"
          label="投放账户"
          show-overflow-tooltip
          align="left"
        />
        <el-table-column
          prop="createTime"
          label="创建时间"
          align="left"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            <p>{{ moment(row.createTime).format('YYYY-MM-DD HH:mm:ss') }}</p>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="80"
        >
          <template slot-scope="{ row }">
            <el-button
              v-if="row.status === 'AD_CONVERT_STATUS_ACTIVE'"
              type="text"
              size="small"
              @click="publishFun(row)"
            >推送</el-button>
            <el-button
              v-else
              type="text"
              size="small"
              @click="activationFun(row)"
            >激活</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 转化追踪激活 -->
    <el-dialog
      title="转化追踪激活"
      :visible.sync="isActivation"
      width="500px"
      :modal-append-to-body="false"
    >
      <span>
        <el-form
          ref="activationRef"
          :model="activation"
          label-width="100px"
        >
          <el-form-item
            label="测试链接"
            prop="url"
            :rules="[{ required: true, message: '测试链接必填' }]"
          >
            <el-input v-model="activation.url" />
          </el-form-item>
        </el-form>
      </span>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click=";(isActivation = false), (activation.url = '')">取 消</el-button>
        <el-button
          type="primary"
          @click="activationSubmit"
        >确 定</el-button>
      </span>
    </el-dialog>
    <c-pagination
      :page-size.sync="pageInfo.pageSize"
      :page-no.sync="pageInfo.pageNum"
      :total="pageInfo.total"
      :get-data-fun="loadData"
    > </c-pagination>
    <OptionDialog ref="dialog" />
  </div>
</template>

<script>
import { PageCommonComponent } from '@/common/mixin'
import { getProjectAndProduct } from '@/network/api/assetManagement/assetManagementHugeThirdLandingPage'
import { Page, Delete, Synchronization, ActivationApi, publish } from '@/network/api/assetManagement/assetManagementHugeConvert'
import Search from './Search'
import OptionDialog from './OptionDialog'
import { convertType } from './object'
export default {
  name: 'AssetManagementHugeConvert',
  components: { Search, OptionDialog },
  mixins: [PageCommonComponent],
  provide: function() {
    return {
      loadData: this.loadData,
      showEdit: this.showEdit
    }
  },
  data() {
    return {
      loading: false,
      data: [],
      csStatus: [],
      convertType,
      convertTypeList: [],
      isSync: false, // 同步
      isPublish: false, // 推送
      account: {
        accountId: '',
        GGZaccountId: [],
        convertId: ''
      },
      accountHugeHousekeeperENTs: [],
      accountHugeHousekeeperENTsId: [],
      accountHugeAdvENTLists: {},
      isActivation: false, // 激活
      activation: {
        url: '',
        convertId: ''
      },
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  mounted() {
    this.loadData()
    this.getProjectAndProductFun()
  },
  methods: {
    loadData(isFirst = false) {
      const searchForm = this.$refs.search.getSearchForm()
      this.doLoadData(isFirst, searchForm)
      this.getDicts('sys_convert_source_status').then((response) => {
        this.csStatus = response.data
      })
      this.getDicts('sys_convert_type').then((response) => {
        this.convertTypeList = response.data
      })
    },
    doLoadData(firstPage = false, searchForm) {
      firstPage && (this.pageInfo.pageNum = 1)
      let req = { ...searchForm, ...this.pageInfo }
      this.loading = true
      Page(req).then((res) => {
        this.data = res.data.records
        this.pageInfo.total = res.data.total
        this.loading = false
      })
    },
    deleteRow(row) {
      this.$confirm('确定删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          Delete(row.id).then((res) => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.loadData()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showEdit(row) {
      this.$refs.dialog.show(true, { ...row })
    },
    getProjectAndProductFun() {
      getProjectAndProduct().then(({ code, data }) => {
        if (code === 200) {
          this.projectAndProduct = data
          this.accountHugeHousekeeperENTs = data.map((item) => item.accountHugeHousekeeperENT)
          let accountHugeAdvENTLists = {}
          data.map((item, index) => {
            let key = item.accountHugeHousekeeperENT.accountId
            if (index === 0) {
              this.accountHugeHousekeeperENTsId = key
            }
            accountHugeAdvENTLists[key] = item.accountHugeAdvENTList
          })
          this.accountHugeAdvENTLists = accountHugeAdvENTLists
        }
      })
    },

    dialogVisible() {
      this.$refs['accountRef'].validate((valid) => {
        if (valid) {
          if (this.isPublish) {
            publish(this.account).then(({ code }) => {
              if (code === 200) {
                this.account = {
                  accountId: '',
                  GGZaccountId: [],
                  convertId: ''
                }
                this.$message.success('推送成功!')
                this.isSync = false
                this.loadData()
              }
            })
          } else {
            Synchronization(this.account).then(({ code }) => {
              if (code === 200) {
                this.account = {
                  accountId: '',
                  GGZaccountId: []
                }
                this.$message.success('同步成功!')
                this.isSync = false
                this.loadData()
              }
            })
          }
        }
      })
    },

    // 激活
    activationFun(row) {
      this.isActivation = true
      this.activation.convertId = row.convertId
    },

    activationSubmit() {
      this.$refs['activationRef'].validate((valid) => {
        if (valid) {
          // this.activation.url = encodeURIComponent(this.activation.url)
          ActivationApi({ ...this.activation, url: encodeURIComponent(this.activation.url) }).then(({ code }) => {
            if (code === 200) {
              this.$message.success('激活成功')
              this.isActivation = false
              this.activation.url = ''
              this.loadData()
            }
          })
        }
      })
    },

    // 推送
    publishFun(row) {
      this.isPublish = true
      this.account.convertId = row.convertId
    },

    showSync() {
      this.isSync = true
    },
    AdvENTListchange(value) {
      this.accountHugeHousekeeperENTsId = value
      this.account.GGZaccountId = null
    }
  }
}
</script>

<style scoped lang="scss">
.table-container {
  p {
    padding: 0;
    margin: 0;
  }
}
</style>
