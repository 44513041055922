var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "section" },
    [
      _c(
        "base-box",
        { attrs: { name: "text" } },
        [
          _c(
            "template",
            { slot: "body" },
            [
              _c(
                "el-form",
                {
                  ref: "textForm",
                  staticClass: "img-form-box",
                  attrs: {
                    model: _vm.detail,
                    size: "mini",
                    inline: true,
                    rules: _vm.rules,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "文本内容", prop: "content" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 15,
                          resize: "none",
                          placeholder: "请输入内容",
                          maxlength: "500",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.detail.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "content", $$v)
                          },
                          expression: "detail.content",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "字符样式", prop: "fontSize" } },
                    [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _c(
                          "div",
                          { staticClass: "mr-10" },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small", min: 1, max: 100 },
                              model: {
                                value: _vm.detail.fontSize,
                                callback: function ($$v) {
                                  _vm.$set(_vm.detail, "fontSize", $$v)
                                },
                                expression: "detail.fontSize",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mr-10" },
                          [
                            _c(
                              "el-dropdown",
                              [
                                _c("div", {
                                  staticClass: "ldy-bg-color",
                                  style: `background:${_vm.detail.fontColor}`,
                                }),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  [
                                    _c("twitter-color", {
                                      staticStyle: { "margin-top": "10px" },
                                      attrs: { colors: _vm.detail.fontColor },
                                      on: {
                                        change: (value) =>
                                          (_vm.detail.fontColor = value),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mr-10" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.detail.showType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.detail, "showType", $$v)
                                  },
                                  expression: "detail.showType",
                                },
                              },
                              [
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "normal" } },
                                  [_vm._v("常规")]
                                ),
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "bold" } },
                                  [_vm._v("加粗")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "对齐方式", prop: "textAlignment" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "small" },
                          model: {
                            value: _vm.detail.textAlignment,
                            callback: function ($$v) {
                              _vm.$set(_vm.detail, "textAlignment", $$v)
                            },
                            expression: "detail.textAlignment",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "left" } }, [
                            _vm._v("左对齐"),
                          ]),
                          _c(
                            "el-radio-button",
                            { attrs: { label: "center" } },
                            [_vm._v("居中对齐")]
                          ),
                          _c("el-radio-button", { attrs: { label: "right" } }, [
                            _vm._v("右对齐"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "上边距", prop: "paddingTop" } },
                    [
                      _c("el-slider", {
                        attrs: { "show-input": "", max: 100 },
                        model: {
                          value: _vm.detail.paddingTop,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "paddingTop", $$v)
                          },
                          expression: "detail.paddingTop",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "下边距", prop: "paddingBottom" } },
                    [
                      _c("el-slider", {
                        attrs: { "show-input": "", max: 100 },
                        model: {
                          value: _vm.detail.paddingBottom,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "paddingBottom", $$v)
                          },
                          expression: "detail.paddingBottom",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }