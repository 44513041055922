export const searchForm = {
  typeName: null,
  isUseing: null,
  remark: null
}

export class BasicSettingProductTypeEnt {
  typeName
  isUseing
  remark
}

export const formRules = {
  typeName: [{ required: true, message: '请填写品类名称', trigger: 'change' }],
  isUseing: [{ required: true, message: '请填写是否启用', trigger: 'change' }]

}

export const isUseingList = [{
  value: 'ISUSEING',
  label: '启用'
}, {
  value: 'ISNOTUSEING',
  label: '不启用'
}]
