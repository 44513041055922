var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, "label-width": "110px" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "ModuleBox",
                    { attrs: { title: "基础信息" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "定向名称：",
                            prop: "name",
                            rules: {
                              required: true,
                              message: "请输入定向名称：",
                              trigger: "change",
                            },
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "400px" },
                            attrs: { placeholder: "请输入定向名称" },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "ModuleBox",
                    { attrs: { title: "基础信息" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "项目：",
                            prop: "projectId",
                            rules: {
                              required: true,
                              message: "请选择项目",
                              trigger: "change",
                            },
                          },
                        },
                        [
                          _c("SearchSelect", {
                            staticStyle: { width: "400px" },
                            attrs: {
                              options: _vm.projectList,
                              props: {
                                label: "projectName",
                                value: "bsProjectId",
                              },
                              placeholder: "请选择项目",
                            },
                            on: {
                              change: (value) => (_vm.selectProjectId = value),
                            },
                            model: {
                              value: _vm.form.projectId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "projectId", $$v)
                              },
                              expression: "form.projectId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "关联产品：", prop: "productId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "400px" },
                              attrs: { placeholder: "请选择产品" },
                              model: {
                                value: _vm.form.productId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "productId", $$v)
                                },
                                expression: "form.productId",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "不限", value: 0 },
                              }),
                              _vm._l(_vm.productListed, function (itm, idx) {
                                return _c("el-option", {
                                  key: idx,
                                  attrs: {
                                    label: itm.productName,
                                    value: itm.bsProductId,
                                  },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c(
                    "ModuleBox",
                    { attrs: { title: "地域信息" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "地域：",
                            prop: "area",
                            rules: {
                              required: true,
                              message: "请选择地域",
                              trigger: "change",
                            },
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "area" },
                              model: {
                                value: _vm.form.areaType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "areaType", $$v)
                                },
                                expression: "form.areaType",
                              },
                            },
                            [_vm._v("常驻地")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "travelArea" },
                              model: {
                                value: _vm.form.areaType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "areaType", $$v)
                                },
                                expression: "form.areaType",
                              },
                            },
                            [_vm._v("差旅出行")]
                          ),
                          _c(
                            "div",
                            { staticClass: "city-box" },
                            [
                              _c("Area", {
                                attrs: { "select-data": _vm.form.area },
                                on: { changeArea: _vm.changeArea },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c(
                    "ModuleBox",
                    { attrs: { title: "人群基础信息" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "年龄：",
                            prop: "age",
                            rules: {
                              required: true,
                              message: "请选择年龄",
                              trigger: "change",
                            },
                          },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { prop: "age", xl: 20, lg: 18 } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "160px" },
                                      attrs: { placeholder: "请选择" },
                                      on: {
                                        change: (value) =>
                                          value > _vm.form.age[0]
                                            ? (_vm.form.age[0] = value)
                                            : null,
                                      },
                                      model: {
                                        value: _vm.form.age[0],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form.age, 0, $$v)
                                        },
                                        expression: "form.age[0]",
                                      },
                                    },
                                    _vm._l(_vm.age_min_list, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.value,
                                          value: item.key,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                  _c("span", [_vm._v(" - ")]),
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "160px" },
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.form.age[1],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form.age, 1, $$v)
                                        },
                                        expression: "form.age[1]",
                                      },
                                    },
                                    _vm._l(_vm.age_max_list, function (item) {
                                      return _c("el-option", {
                                        key: item.key,
                                        attrs: {
                                          label: item.value,
                                          value: item.key,
                                          disabled: item.key < _vm.form.age[0],
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "性别：",
                            prop: "gender",
                            rules: {
                              required: true,
                              message: "请选择性别",
                              trigger: "change",
                            },
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.form.gender,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "gender", $$v)
                                },
                                expression: "form.gender",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "all" } }, [
                                _vm._v("全部"),
                              ]),
                              _c("el-radio", { attrs: { label: "MALE" } }, [
                                _vm._v("男"),
                              ]),
                              _c("el-radio", { attrs: { label: "FEMALE" } }, [
                                _vm._v("女"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "行为兴趣：",
                            prop: "behaviorAndInterest",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "behavior" },
                              model: {
                                value: _vm.form.behaviorAndInterest,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "behaviorAndInterest", $$v)
                                },
                                expression: "form.behaviorAndInterest",
                              },
                            },
                            [
                              _vm._v(" 行为"),
                              _vm.form.behavior.category.length
                                ? _c("span", [
                                    _vm._v(
                                      "( " +
                                        _vm._s(
                                          _vm.form.behavior.category.length
                                        ) +
                                        " )"
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "interest" },
                              model: {
                                value: _vm.form.behaviorAndInterest,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "behaviorAndInterest", $$v)
                                },
                                expression: "form.behaviorAndInterest",
                              },
                            },
                            [
                              _vm._v(" 兴趣"),
                              _vm.form.interest.category.length
                                ? _c("span", [
                                    _vm._v(
                                      "( " +
                                        _vm._s(
                                          _vm.form.interest.category.length
                                        ) +
                                        " )"
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          _vm.form.behaviorAndInterest === "behavior"
                            ? _c(
                                "div",
                                [
                                  _c("Behavior", {
                                    attrs: { "select-data": _vm.form.behavior },
                                    on: { changeBehavior: _vm.changeBehavior },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                { staticClass: "city-box" },
                                [
                                  _c("Interest", {
                                    attrs: { "select-data": _vm.form.interest },
                                    on: { changeInterest: _vm.changeInterest },
                                  }),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c(
                    "ModuleBox",
                    {
                      attrs: { title: "更多人群信息", desc: "(以下均为选填)" },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "学历：", prop: "education" } },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { xl: 20, lg: 18 } },
                                [
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      on: {
                                        change: (value) =>
                                          _vm.changeAllFormate(
                                            value,
                                            "education"
                                          ),
                                      },
                                      model: {
                                        value: _vm.form.education,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "education", $$v)
                                        },
                                        expression: "form.education",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-checkbox",
                                        { attrs: { label: "" } },
                                        [_vm._v("全部")]
                                      ),
                                      _vm._l(
                                        _vm.education_list,
                                        function (item) {
                                          return _c(
                                            "el-checkbox",
                                            {
                                              key: item.dictLabel,
                                              attrs: { label: item.dictValue },
                                            },
                                            [_vm._v(_vm._s(item.dictLabel))]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "操作系统：", prop: "os" } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.form.os,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "os", $$v)
                                },
                                expression: "form.os",
                              },
                            },
                            [
                              _c(
                                "el-checkbox",
                                { attrs: { label: "ANDROID" } },
                                [_vm._v("Android")]
                              ),
                              _c("el-checkbox", { attrs: { label: "IOS" } }, [
                                _vm._v("IOS"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "手机价格：", prop: "devicePrice" } },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { xl: 20, lg: 18 } },
                                [
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      on: {
                                        change: (value) =>
                                          _vm.changeAllFormate(
                                            value,
                                            "devicePrice"
                                          ),
                                      },
                                      model: {
                                        value: _vm.form.devicePrice,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "devicePrice", $$v)
                                        },
                                        expression: "form.devicePrice",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-checkbox",
                                        { attrs: { label: "" } },
                                        [_vm._v("全部")]
                                      ),
                                      _vm._l(
                                        _vm.device_price_list,
                                        function (item) {
                                          return _c(
                                            "el-checkbox",
                                            {
                                              key: item.dictLabel,
                                              attrs: { label: item.dictValue },
                                            },
                                            [_vm._v(_vm._s(item.dictLabel))]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "手机品牌：",
                            prop: "deviceBrandModel",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: 0 },
                                      model: {
                                        value: _vm.form.deviceBrandModel_label,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "deviceBrandModel_label",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.deviceBrandModel_label",
                                      },
                                    },
                                    [_vm._v("全部")]
                                  ),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: 1 },
                                      model: {
                                        value: _vm.form.deviceBrandModel_label,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "deviceBrandModel_label",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.deviceBrandModel_label",
                                      },
                                    },
                                    [
                                      _vm._v(" 定向 "),
                                      _vm.form.deviceBrandModel_label === 1
                                        ? _c("span", [
                                            _vm._v(
                                              "( " +
                                                _vm._s(
                                                  _vm.form.deviceBrandModel
                                                    .length
                                                ) +
                                                " )"
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: 2 },
                                      model: {
                                        value: _vm.form.deviceBrandModel_label,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "deviceBrandModel_label",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.deviceBrandModel_label",
                                      },
                                    },
                                    [
                                      _vm._v(" 排除 "),
                                      _vm.form.deviceBrandModel_label === 2
                                        ? _c("span", [
                                            _vm._v(
                                              " ( " +
                                                _vm._s(
                                                  _vm.form.deviceBrandModel
                                                    .length
                                                ) +
                                                " ) "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("el-col", [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.form.deviceBrandModel_label !== 0,
                                        expression:
                                          "form.deviceBrandModel_label !== 0",
                                      },
                                    ],
                                  },
                                  [
                                    _c("SearchSelect", {
                                      staticStyle: { width: "700px" },
                                      attrs: {
                                        options: _vm.included_type_list,
                                        multiple: "",
                                        props: { label: "name", value: "id" },
                                        placeholder: `请选择${
                                          _vm.form.deviceBrandModel_label === 1
                                            ? "定向"
                                            : "排除"
                                        }手机品牌`,
                                      },
                                      model: {
                                        value: _vm.form.deviceBrandModel,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "deviceBrandModel",
                                            $$v
                                          )
                                        },
                                        expression: "form.deviceBrandModel",
                                      },
                                    }),
                                    _c(
                                      "el-checkbox",
                                      {
                                        staticClass: "ml-20",
                                        on: {
                                          change: (e) =>
                                            _vm.selectAll(
                                              e,
                                              "deviceBrandModel"
                                            ),
                                        },
                                      },
                                      [_vm._v("全选")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "运营商：", prop: "telcom" } },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { xl: 20, lg: 18 } },
                                [
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      on: {
                                        change: (value) =>
                                          _vm.changeAllFormate(value, "telcom"),
                                      },
                                      model: {
                                        value: _vm.form.telcom,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "telcom", $$v)
                                        },
                                        expression: "form.telcom",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-checkbox",
                                        { attrs: { label: "" } },
                                        [_vm._v("全部")]
                                      ),
                                      _vm._l(
                                        _vm.network_operator_list,
                                        function (item) {
                                          return _c(
                                            "el-checkbox",
                                            {
                                              key: item.dictLabel,
                                              attrs: { label: item.dictValue },
                                            },
                                            [_vm._v(_vm._s(item.dictLabel))]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "联网方式：", prop: "connection" } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              on: {
                                change: (value) =>
                                  _vm.changeAllFormate(value, "connection"),
                              },
                              model: {
                                value: _vm.form.connection,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "connection", $$v)
                                },
                                expression: "form.connection",
                              },
                            },
                            [
                              _c("el-checkbox", { attrs: { label: "" } }, [
                                _vm._v("全部"),
                              ]),
                              _vm._l(_vm.network_type_list, function (item) {
                                return _c(
                                  "el-checkbox",
                                  {
                                    key: item.dictLabel,
                                    attrs: { label: item.dictValue },
                                  },
                                  [_vm._v(_vm._s(item.dictLabel))]
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "再营销：", prop: "in_action_list" },
                        },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              on: {
                                change: (value) =>
                                  _vm.changeAllFormate(value, "in_action_list"),
                              },
                              model: {
                                value: _vm.form.in_action_list,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "in_action_list", $$v)
                                },
                                expression: "form.in_action_list",
                              },
                            },
                            [
                              _c("el-checkbox", { attrs: { label: "" } }, [
                                _vm._v("不启用"),
                              ]),
                              _vm._l(_vm.marketing_list, function (item) {
                                return _c(
                                  "el-checkbox",
                                  {
                                    key: item.key,
                                    attrs: {
                                      disabled:
                                        _vm.form.not_in_action_list.includes(
                                          item.key
                                        ),
                                      label: item.key,
                                    },
                                  },
                                  [_vm._v(_vm._s(item.value))]
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "排除营销：",
                            prop: "not_in_action_list",
                          },
                        },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              on: {
                                change: (value) =>
                                  _vm.changeAllFormate(
                                    value,
                                    "not_in_action_list"
                                  ),
                              },
                              model: {
                                value: _vm.form.not_in_action_list,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "not_in_action_list", $$v)
                                },
                                expression: "form.not_in_action_list",
                              },
                            },
                            [
                              _c("el-checkbox", { attrs: { label: "" } }, [
                                _vm._v("不启用"),
                              ]),
                              _vm._l(_vm.not_marketing_list, function (item) {
                                return _c(
                                  "el-checkbox",
                                  {
                                    key: item.key,
                                    attrs: {
                                      disabled:
                                        _vm.form.in_action_list.includes(
                                          item.key
                                        ),
                                      label: item.key,
                                    },
                                  },
                                  [_vm._v(_vm._s(item.value))]
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "自定义人群：" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.form.isCrowds,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "isCrowds", $$v)
                              },
                              expression: "form.isCrowds",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.form.isCrowds
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "人群类型：",
                                prop: "isOnlineCrowd",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: false },
                                  model: {
                                    value: _vm.form.isOnlineCrowd,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "isOnlineCrowd", $$v)
                                    },
                                    expression: "form.isOnlineCrowd",
                                  },
                                },
                                [_vm._v("人群模板")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: { label: true },
                                  model: {
                                    value: _vm.form.isOnlineCrowd,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "isOnlineCrowd", $$v)
                                    },
                                    expression: "form.isOnlineCrowd",
                                  },
                                },
                                [_vm._v("线上人群包")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.isCrowds && !_vm.form.isOnlineCrowd
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "定向人群：",
                                prop: "in_dmp_audience",
                              },
                            },
                            [
                              _vm.form.in_dmp_audience.length === 0
                                ? _c(
                                    "el-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectCrowdPack(
                                            "in_dmp_audience"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("选择定向人群")]
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectCrowdPack(
                                            "in_dmp_audience"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          `已选 ( ${_vm.form.in_dmp_audience[0].name.substring(
                                            0,
                                            5
                                          )}... 等 ${
                                            _vm.form.in_dmp_audience.length
                                          } 条数据 )`
                                        )
                                      ),
                                    ]
                                  ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.isCrowds && !_vm.form.isOnlineCrowd
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "排除人群：",
                                prop: "not_in_dmp_audience",
                              },
                            },
                            [
                              _vm.form.not_in_dmp_audience.length === 0
                                ? _c(
                                    "el-button",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectCrowdPack(
                                            "not_in_dmp_audience"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("选择排除人群")]
                                  )
                                : _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.selectCrowdPack(
                                            "not_in_dmp_audience"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          `已选 ( ${_vm.form.not_in_dmp_audience[0].name.substring(
                                            0,
                                            5
                                          )}... 等 ${
                                            _vm.form.not_in_dmp_audience.length
                                          } 条数据 )`
                                        )
                                      ),
                                    ]
                                  ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("el-divider"),
                      _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.onSubmit },
                            },
                            [_vm._v(_vm._s(_vm.paramsId ? "修改" : "确定"))]
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "ml-20",
                              attrs: { to: "/wachatAudience" },
                            },
                            [_c("el-button", [_vm._v("取消")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title:
              _vm.interest_type === "in_dmp_audience" ? "定向人群" : "排除人群",
            visible: _vm.interest_visible,
            width: "920px",
            "modal-append-to-body": false,
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.interest_visible = $event
            },
            open: _vm.open,
            close: function ($event) {
              return _vm.resetForm("interest")
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [
                  _c("TablePane", {
                    ref: "TablePane",
                    attrs: { "data-source": _vm.dataSource },
                    on: { getPage: _vm.getPage },
                  }),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("p", { staticClass: "crowd-pack-selected-title" }, [
                  _vm._v(
                    _vm._s(
                      _vm.interest_type === "in_dmp_audience"
                        ? "已选定向人群"
                        : "已选排除人群"
                    )
                  ),
                ]),
                _c(
                  "ul",
                  { staticClass: "crowd-pack-selected" },
                  _vm._l(_vm.select_interest_list, function (item) {
                    return _c("li", { key: item.id }, [
                      _c("span", [_vm._v(_vm._s(item.name))]),
                      _c("i", {
                        staticClass: "el-icon-circle-close",
                        on: {
                          click: function ($event) {
                            return _vm.deleteSelectInterest(item.id)
                          },
                        },
                      }),
                    ])
                  }),
                  0
                ),
              ]),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.interestOnOk } },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.interest_visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }