<template>
  <div>
    <Search ref="search" />

    <el-row type="flex" justify="start" class="operate-box mt-20">
      <!-- <el-button type="primary" :disabled="shareBatchDisplay" round @click="dialogVisibleButton(undefined)">批量分享</el-button> -->
      <el-button type="primary" icon="el-icon-plus" round @click="showTbAdd">同步落地页</el-button>
    </el-row>
    <div class="table-container">
      <el-table v-loading="loading" stripe class="list-table" :data="data" @selection-change="handleSelectionChange">
        <c-data-empty slot="empty" />
        <el-table-column type="selection" />
        <el-table-column type="index" label="序号" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="pageId" label="落地页ID" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="pageName" label="落地页名称" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="siteId" label="站点ID" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="siteName" label="站点名称" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="housekeeperName" label="账号管家" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="onlineUrl" label="站点URL" align="left" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span :id="'id' + scope.row.woodenFishId"> {{ scope.row.onlineUrl }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="transType" label="目标转化" align="left" :show-overflow-tooltip="true" :formatter="formatterTransType" />
        <el-table-column prop="createTime" label="同步时间" align="left" :show-overflow-tooltip="true" />
        <el-table-column label="操作" width="150">
          <template slot-scope="{ row }">
            <!-- <el-button type="text" size="small" @click="dialogVisibleButton(row)">分享</el-button> -->
            <el-button :id="'btnLink' + row.woodenFishId" type="text" size="small" :data-clipboard-target="'#id' + row.woodenFishId" @click="link(row)">链接</el-button>
            <el-button type="text" size="small" @click="onClickPreview(row)">预览</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <c-pagination :page-size.sync="pageInfo.pageSize" :page-no.sync="pageInfo.pageNum" :total="pageInfo.total" :get-data-fun="loadData" />
    <OptionDialog ref="dialog" />
    <tbOptionDialog ref="tbdialog" />

    <el-dialog :title="titleContent" width="576px" :visible.sync="dialogVisible" label-position="right" :modal-append-to-body="false" :close-on-press-escape="false" :close-on-click-modal="false">
      <el-form class="mt-20">
        <el-form-item label="账户管家">
          <el-select v-model="housekeeperId" filterable placeholder="账户管家" style="width: 400px" @change="selectChange">
            <el-option v-for="item in houseKeeper" :key="item.housekeeperId" :label="item.launchAccountName" :value="item.housekeeperId" />
          </el-select>
        </el-form-item>
        <el-form-item label="投放账户">
          <el-select v-model="baiduId" filterable placeholder="投放账户" style="width: 400px">
            <el-option v-for="item in baiduAdv" :key="item.baiduId" :label="item.accountName" :value="item.baiduId" />
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="showAdd()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import { PageCommonComponent } from '@/common/mixin'
import { Page, Delete } from '@/network/api/assetManagement/assetManagementBaiduWoodenFish'
import Search from './Search'
import { getHouseKeeper } from '@/network/api/account/accountBaiduHousekeeper'
import { getAccountByHouseId } from '@/network/api/account/accountBaiduAdv'
import OptionDialog from './OptionDialog'
import tbOptionDialog from './tbOptionDialog'

import { transType } from './object'
export default {
  name: 'AssetManagementBaiduWoodenFish',
  components: { Search, OptionDialog, tbOptionDialog },
  mixins: [PageCommonComponent],
  provide: function() {
    return {
      houseKeeper: () => this.houseKeeper,
      loadData: this.loadData,
      showEdit: this.showEdit
    }
  },
  data() {
    return {
      isWoodenFish: true,
      housekeeperId: undefined,
      baiduId: undefined,
      titleContent: '',
      dialogVisible: false,
      houseKeeper: [],
      transStatusList: [],
      transFromList: [],
      multipleSelection: [],
      shareHouseKeeper: [],
      baiduAdv: [],
      shareBatchDisplay: true,
      subjectName: '',
      loading: false,
      data: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  mounted() {
    // location.reload()
    this.loadData()
    this.getHk()
  },
  methods: {
    formatterTransType(row, column, cellValue, index) {
      if (cellValue === undefined || cellValue === null || cellValue === '') {
        return '-'
      }
      var split = cellValue.split(',')
      var retData = []
      split.forEach((item) => {
        transType.forEach((da) => {
          if (item === da.id) {
            retData.push(da.label)
          }
        })
      })
      return retData.toString()
    },
    link(row) {
      var clipboard = new Clipboard('#btnLink' + row.woodenFishId)
      clipboard.on('success', (e) => {
        this.$message.success('复制成功')
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message.error('不支持复制')
        // 释放内存
        clipboard.destroy()
      })
    },
    selectChange(val) {
      this.baiduAdv = []
      getAccountByHouseId(val).then((res) => {
        if (res.code === 200) {
          this.baiduAdv = res.data
        }
      })
    },
    handleSelectionChange(val) {
      this.shareHouseKeeper = []
      this.housekeeperId = undefined
      this.baiduId = undefined
      this.subjectName = ''
      this.shareBatchDisplay = false
      this.multipleSelection = val
      this.multipleSelection.forEach((item) => {
        var projectName = item.projectName
        if (this.subjectName === '') {
          this.subjectName = projectName
        } else {
          if (this.subjectName !== projectName) {
            this.shareBatchDisplay = true
          }
        }
      })
      if (!this.shareBatchDisplay) {
        this.shareHouseKeeper = this.houseKeeper
        // this.houseKeeper.forEach(item => {
        //   if (item.projectName === this.subjectName) {
        //     this.shareHouseKeeper.push(item)
        //   }
        // })
      }
    },
    dialogVisibleButton(row) {
      this.isWoodenFish = false
      if (row === undefined) {
        this.titleContent = '批量分享'
        this.dialogVisible = true
      } else {
        this.titleContent = '分享'
        this.handleSelectionChange([row])
        this.shareBatchDisplay = true
        this.dialogVisible = true
      }
    },
    getHk() {
      getHouseKeeper().then((res) => {
        if (res.code === 200) {
          this.houseKeeper = res.data
        }
      })
    },
    loadData(isFirst = false) {
      const searchForm = this.$refs.search.getSearchForm()
      this.doLoadData(isFirst, searchForm)
    },
    doLoadData(firstPage = false, searchForm) {
      firstPage && (this.pageInfo.pageNum = 1)
      const req = { ...searchForm, ...this.pageInfo }
      this.loading = true
      Page(req).then((res) => {
        this.data = res.data.records
        this.pageInfo.total = res.data.total
        this.loading = false
      })
    },
    deleteRow(row) {
      this.$confirm('确定删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          Delete(row.id).then((res) => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.loadData()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showEdit(row) {
      this.$refs.dialog.show(true, { ...row })
    },

    showAdd() {
      if (this.isWoodenFish) {
        if (this.housekeeperId === undefined || this.baiduId === undefined) {
          return this.$message('请选择账户管家, 投放账户')
        }
        this.dialogVisible = false
        this.$refs.tbdialog.show(false, { baiduId: this.baiduId, housekeeperId: this.housekeeperId })
      } else {
        if (this.housekeeperId === undefined || this.baiduId === undefined) {
          return this.$message.error('请选择账户管家, 投放账户')
        }
        this.dialogVisible = false
        this.$refs.dialog.show(false, { housekeeperId: this.housekeeperId, baiduId: this.baiduId })
      }
    },

    showTbAdd(row) {
      this.housekeeperId = undefined
      this.baiduId = undefined
      this.isWoodenFish = true
      this.titleContent = '同步落地页'
      this.dialogVisible = true
      // this.$refs.tbdialog.show(false, { ...row })
    },
    // 预览
    onClickPreview(row) {
      window.open(row.onlineUrl)
    }
  }
}
</script>

<style scoped></style>
