var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "projection-container" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "projection-content" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 50 } },
          [
            _c(
              "el-col",
              { attrs: { span: 6 } },
              [
                _c(
                  "div",
                  { staticClass: "projection-item-header" },
                  [
                    _c("span", { staticClass: "title" }, [_vm._v("产品新增")]),
                    _c("SearchDate", {
                      model: {
                        value: _vm.product.dates,
                        callback: function ($$v) {
                          _vm.$set(_vm.product, "dates", $$v)
                        },
                        expression: "product.dates",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.product.loading,
                        expression: "product.loading",
                      },
                    ],
                    staticClass: "list-table",
                    attrs: {
                      data: _vm.product.list,
                      stripe: "",
                      size: "small",
                      height: "300",
                    },
                  },
                  [
                    _c("el-empty", {
                      attrs: { slot: "empty", description: "暂无数据" },
                      slot: "empty",
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "日期",
                        prop: "showDate",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "新增数",
                        prop: "addNum",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "总数", prop: "sumNum", align: "center" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-col",
              { attrs: { span: 18 } },
              [
                _c("div", { staticClass: "projection-item-header" }, [
                  _c("span", { staticClass: "title" }, [_vm._v("部门投测")]),
                  _c(
                    "div",
                    { staticClass: "item-search" },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: {
                            width: "100px",
                            "margin-right": "10px",
                          },
                          attrs: { size: "mini" },
                          on: { change: _vm.handleChangeType },
                          model: {
                            value: _vm.dept.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.dept, "type", $$v)
                            },
                            expression: "dept.type",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "汇总", value: 1 },
                          }),
                          _c("el-option", {
                            attrs: { label: "部门细分", value: 2 },
                          }),
                        ],
                        1
                      ),
                      _vm.dept.type === 2
                        ? _c("el-cascader", {
                            ref: "cascader",
                            staticStyle: {
                              width: "120px",
                              "margin-right": "10px",
                            },
                            attrs: {
                              options: _vm.deptList,
                              props: _vm.cascaderProp,
                              "show-all-levels": false,
                              filterable: "",
                              placeholder: "请选择部门",
                              size: "mini",
                            },
                            on: { change: _vm.handleChangeDept },
                            model: {
                              value: _vm.dept.deptId,
                              callback: function ($$v) {
                                _vm.$set(_vm.dept, "deptId", $$v)
                              },
                              expression: "dept.deptId",
                            },
                          })
                        : _vm._e(),
                      _c("SearchDate", {
                        model: {
                          value: _vm.dept.dates,
                          callback: function ($$v) {
                            _vm.$set(_vm.dept, "dates", $$v)
                          },
                          expression: "dept.dates",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.dept.loading,
                        expression: "dept.loading",
                      },
                    ],
                    staticClass: "list-table",
                    attrs: { data: _vm.dept.list, stripe: "", size: "small" },
                  },
                  [
                    _c("el-empty", {
                      attrs: { slot: "empty", description: "暂无数据" },
                      slot: "empty",
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "日期",
                        prop: "showDate",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "部门",
                        prop: "deptName",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "新增投测",
                        prop: "newAddLaunchTestNum",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "待测试",
                        prop: "waitTestNum",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "测试中",
                        prop: "progressTest",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "已完成",
                        prop: "completeNum",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "新增起量",
                        prop: "newAddAmount",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "累计起量",
                        prop: "sumAmount",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "累计起量率",
                        prop: "sumAmountRate",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [_vm._v(_vm._s(row.sumAmountRate) + "%")]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "page" },
                  [
                    _c("paginations", {
                      attrs: {
                        total: _vm.dept.total,
                        page: _vm.dept.pageNum,
                        limit: _vm.dept.pageSize,
                        layout: "prev, pager, next",
                        small: "",
                      },
                      on: {
                        "update:page": function ($event) {
                          return _vm.$set(_vm.dept, "pageNum", $event)
                        },
                        "update:limit": function ($event) {
                          return _vm.$set(_vm.dept, "pageSize", $event)
                        },
                        pagination: _vm.getDeptPage,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "projection-header" }, [
      _c("span", [_vm._v("汇总数据")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }