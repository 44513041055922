<template>
  <!--元素类型弹框-->
  <el-dialog class="table-template" title="元素类型" :visible.sync="show" :append-to-body="true" width="700px" top="20vh"
    @close="onCloseDialog">
    <div class="page-container">
      <el-form ref="form" :inline="false" :model="form" :rules="rules">
        <el-form-item label="类型名称" prop="typeName">
          <el-input v-model="form.typeName" placeholder="请输入类型名称" style="width: 200px;"></el-input>
          <el-button type="primary" style="margin-left: 10px" :loading="saveLoading" @click="handleAddType">添加
          </el-button>
        </el-form-item>
      </el-form>
      <el-table :data="tableData" v-loading="loading" height="300px">
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column label="名称" align="center">
          <div slot-scope="{row}">
            <span v-if="!row.isEdit" class="edit-input-box">{{ row.name }} <el-button v-if="row.canDel" type="text" class="el-icon-edit"
                @click="handleEdit(row)"></el-button></span>
            <el-input v-else style="width: 100px" type="text" :autofocus="true" v-model="row.name"
              @blur="handleEditSave(row)"></el-input>
          </div>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="{row}">
            <el-button v-if="row.canDel" type="text" icon="el-icon-delete" @click="handleDelete(row.id)"></el-button>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getElementType, addElementType, deleteElementType, updateElementType } from '@/network/api/toolManagement/api-tool'
export default {
  name: 'ElementTypeDialog',
  props: {},
  data() {
    return {
      show: false,
      loading: false,
      saveLoading: false,
      form: {
        typeName: '' // 类型名称
      },
      rules: {
        typeName: [{ required: true, message: '请输入类型名称', trigger: 'blur' }]
      },
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 5
    }
  },
  computed: {
  },
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {
    /* --------------------------- Actions --------------------------- */
    showModal() {
      this.show = true
      this.getTableData()
    },
    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      // 重置表单
      this.tableData = []
      this.form = {
        typeName: ''
      }
      this.$refs['form'].clearValidate()
      this.$emit('refresh')
    },
    // 关闭弹框
    closeModal() {
      this.show = false
    },

    getTableData() {
      this.loading = true
      getElementType({ name: '' }).then(({ code, data }) => {
        if (code === 200) {
          let arr = data.map(v => {
            return {
              ...v,
              copyName: v.name
            }
          })
          this.tableData = arr
        }
      }).finally(() => {
        this.loading = false
      })
    },

    // 点击编辑
    handleEdit(row) {
      if (row.isEdit) {
        this.$delete(row, 'isEdit')
      } else {
        this.$set(row, 'isEdit', true)
      }
    },

    // 编辑保存
    handleEditSave(row) {
      if (!row.name) {
        this.$message.error('请输入元素名')
        return
      }
      if (row.name === row.copyName) {
        this.$delete(row, 'isEdit')
        return
      }
      updateElementType({ ...row }).then(({ code }) => {
        if (code === 200) {
          this.$delete(row, 'isEdit')
        }
      })
    },

    // 点击添加
    handleAddType() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.saveLoading = true
          addElementType({ name: this.form.typeName }).then(({ code }) => {
            if (code === 200) {
              this.getTableData()
              this.form.typeName = ''
            }
          }).finally(() => {
            this.saveLoading = false
          })
        }
      })
    },

    // 点击删除
    handleDelete(id) {
      deleteElementType({ id: id }).then(({ code }) => {
        if (code === 200) {
          this.getTableData()
        }
      })
    }

    /* --------------------------- Private --------------------------- */
  }
}
</script>

<style lang="scss" scoped>
</style>
