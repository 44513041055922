var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "Base",
        { attrs: { title: "基础信息" } },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.ruleData },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "任务名称:",
                    prop: "taskName",
                    rules: {
                      required: true,
                      message: "请输入任务名称",
                      trigger: "change",
                    },
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    on: { change: _vm.changeBaseInfo },
                    model: {
                      value: _vm.ruleData.taskName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleData, "taskName", $$v)
                      },
                      expression: "ruleData.taskName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "项目名称:",
                    prop: "projectId",
                    rules: {
                      required: true,
                      message: "请选择项目",
                      trigger: "change",
                    },
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      on: { change: _vm.changeProjectId },
                      model: {
                        value: _vm.ruleData.projectId,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleData, "projectId", $$v)
                        },
                        expression: "ruleData.projectId",
                      },
                    },
                    _vm._l(_vm.projectList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          label: item.projectName,
                          value: item.bsProjectId + "",
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "产品名称:",
                    rules: {
                      required: true,
                      message: "请选择产品",
                      trigger: "change",
                    },
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      on: { change: _vm.changeProductId },
                      model: {
                        value: _vm.ruleData.productIds,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleData, "productIds", $$v)
                        },
                        expression: "ruleData.productIds",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "不限", value: "-1" },
                      }),
                      _vm._l(_vm.productListed, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: {
                            label: item.productName,
                            value: item.bsProductId,
                          },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: " 服 务 商: ",
                    prop: "spid",
                    rules: {
                      required: true,
                      message: "请选择服务商",
                      trigger: "change",
                    },
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { placeholder: "请选择服务商" },
                      on: { change: _vm.changeBaseSpid },
                      model: {
                        value: _vm.ruleData.spid,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleData, "spid", $$v)
                        },
                        expression: "ruleData.spid",
                      },
                    },
                    _vm._l(_vm.adMpAmList, function (itm, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: {
                          label: `${itm.agencyName} (${itm.mobileId})`,
                          value: `${itm.spid}+${itm.id}`,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "投放账户:",
                    prop: "ghid",
                    rules: {
                      required: true,
                      message: "请选择投放账户",
                      trigger: "blue",
                    },
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c("SearchSelectAsync", {
                        ref: "selectAsync",
                        staticStyle: { width: "300px" },
                        attrs: {
                          name: "ghids",
                          disabled: !_vm.ruleData.spid,
                          multiple: "",
                          api: "/api/tencent/adMpAmsAccounts/page",
                          params: ["name"],
                          query: {
                            spid: _vm.ruleData.spid
                              ? _vm.ruleData.spid.split("+")[0]
                              : "",
                            putInStatus: 1,
                          },
                          props: { label: "name", value: "ghid" },
                          "collapse-tags": "",
                          "options-data": _vm.optionsData,
                          placeholder: "请选择投放账户",
                        },
                        on: { change: _vm.changeAccount },
                        model: {
                          value: _vm.ruleData.ghid,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleData, "ghid", $$v)
                          },
                          expression: "ruleData.ghid",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "promotedObjectType",
                    label: "推广目标",
                    rules: {
                      required: true,
                      message: "请选择推广目标",
                      trigger: "change",
                    },
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { placeholder: "请选择推广目标" },
                      on: { change: _vm.changeBaseInfo },
                      model: {
                        value: _vm.ruleData.promotedObjectType,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleData, "promotedObjectType", $$v)
                        },
                        expression: "ruleData.promotedObjectType",
                      },
                    },
                    _vm._l(_vm.promotionTargetList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("Deploy", {
        ref: "deployRef",
        attrs: {
          "account-adv": _vm.accountAdv,
          loading: _vm.loading,
          "task-id": _vm.taskId,
          "promoted-object-type": _vm.ruleData.promotedObjectType,
          "account-id": _vm.ruleData.accountId,
          "project-id": _vm.ruleData.projectId,
          "rule-data": _vm.ruleData,
          spid: _vm.ruleData.spid,
          "product-listed": _vm.productListed,
        },
        on: {
          setLoading: (value) => {
            _vm.loading = value
          },
          getBaseInfo: _vm.getBaseInfo,
          getShowPreviewFun: _vm.getShowPreviewFun,
          saveTaskFun: _vm.saveTaskFun,
        },
      }),
      _c(
        "Base",
        { attrs: { title: "预览区" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              staticClass: "table-weChat",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.dataSource_select, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: { label: "投放账户", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("p", [_vm._v(_vm._s(row.account.accountName))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "推广计划数量", width: "110" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [_c("p", [_vm._v(_vm._s(row.count))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "广告数量", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [_c("p", [_vm._v(_vm._s(row.count))])]
                    },
                  },
                ]),
              }),
              _c(
                "el-table-column",
                { attrs: { label: "计划" } },
                [
                  _c("el-table-column", {
                    attrs: { label: "投放位置", "show-overflow-tooltip": "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  row.plan.isFriendCircle
                                    ? "朋友圈信息流"
                                    : "公众号平台流量"
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "广告位置", "show-overflow-tooltip": "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _vm._v(" " + _vm._s(row.plan.crtSizeLabel) + " "),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "计划日预算" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("p", [_vm._v(_vm._s(row.plan.dayBudget / 100))]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "el-table-column",
                { attrs: { label: "广告" } },
                [
                  _c("el-table-column", {
                    attrs: { label: "投放时间", width: "200px" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editLaunchData(row)
                                  },
                                },
                              },
                              [
                                row.adData.endTime
                                  ? _c("p", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            `${_vm
                                              .moment(
                                                _vm.moment.unix(
                                                  row.adData.beginTime
                                                )
                                              )
                                              .format("YYYY-MM-DD")} ~ ${_vm
                                              .moment(
                                                _vm.moment.unix(
                                                  row.adData.endTime
                                                )
                                              )
                                              .format("YYYY-MM-DD")}`
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _c("p", [_vm._v(" 长期投放 ")]),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "el-table-column",
                    { attrs: { label: "投放时段" } },
                    [[_c("p", [_vm._v("全天投放")])]],
                    2
                  ),
                  _c("el-table-column", {
                    attrs: { label: "优化目标" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.objectiveList[+row.adData.bidObjective] ||
                                    ""
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "广告日预算 (元)" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c("p", [
                              _vm._v(_vm._s(row.adData.dayBudget / 100)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "出价 (元)" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editBid(row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(row.adData.bid / 100))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-pagination", {
            staticClass: "mt-10",
            staticStyle: { "text-align": "right" },
            attrs: {
              "current-page": _vm.currentPage,
              "page-size": 10,
              layout: "total, prev, pager, next",
              total: _vm.dataSource.length,
            },
            on: {
              "update:currentPage": function ($event) {
                _vm.currentPage = $event
              },
              "update:current-page": function ($event) {
                _vm.currentPage = $event
              },
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "justify-center mb-20" },
        [
          _c(
            "el-button",
            {
              staticClass: "mb-20",
              attrs: { type: "primary", disabled: _vm.dataSource.length === 0 },
              on: { click: _vm.bulkSubmit },
            },
            [_vm._v("批量提交")]
          ),
          _c(
            "router-link",
            {
              staticClass: "ml-20 mb-20",
              attrs: { to: "/weChatAmountAdBatch" },
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    "~click": function ($event) {
                      return _vm.cancelTask.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("取消任务")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改投放日期",
            visible: _vm.editLaunchDataVisible,
            width: "600px",
            "modal-append-to-body": false,
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.editLaunchDataVisible = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-form",
                {
                  ref: "editForm",
                  attrs: { model: _vm.editForm, "label-width": "120px" },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "投放时间:",
                        rules: {
                          required: true,
                          message: "请选择投放时间",
                          trigger: "blue",
                        },
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.editForm.launchData,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "launchData", $$v)
                            },
                            expression: "editForm.launchData",
                          },
                        },
                        [_vm._v("选择开始和结束日期")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "2" },
                          model: {
                            value: _vm.editForm.launchData,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "launchData", $$v)
                            },
                            expression: "editForm.launchData",
                          },
                        },
                        [_vm._v("长期投放")]
                      ),
                    ],
                    1
                  ),
                  _vm.editForm.launchData === "1"
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "开始/结束日期:",
                            rules: {
                              required: true,
                              message: "请选择开始/结束日期",
                              trigger: "blue",
                            },
                          },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "330px" },
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              type: "daterange",
                              "range-separator": "~",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                            },
                            model: {
                              value: _vm.editForm.date,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "date", $$v)
                              },
                              expression: "editForm.date",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editForm.launchData === "2"
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "开始日期:",
                            rules: {
                              required: true,
                              message: "请选择开始日期",
                              trigger: "blue",
                            },
                          },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "280px" },
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              type: "date",
                              placeholder: "请选择开始日期",
                            },
                            model: {
                              value: _vm.editForm.date2,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "date2", $$v)
                              },
                              expression: "editForm.date2",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.editLaunchDataVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.getSelectPlanData },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改出价",
            visible: _vm.editBidVisible,
            width: "370px",
            "modal-append-to-body": false,
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.editBidVisible = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-form",
                { ref: "editForm2", attrs: { model: _vm.editForm2 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "出 价:" } },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "210px" },
                        attrs: { "controls-position": "right", min: 0 },
                        model: {
                          value: _vm.editForm2.bid,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm2, "bid", $$v)
                          },
                          expression: "editForm2.bid",
                        },
                      }),
                      _c("span", { staticClass: "ml-10" }, [_vm._v("元")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.editBidVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.getSelectPlanData },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }