<!--
 * @Description: Do not edit
 * @Date: 2021-12-03 09:41:49
 * @LastEditTime: 2022-01-21 13:39:07
-->
<template>
  <ul class="list-box">
    <li v-for="item in dataProps" :key="item.key">
      <p v-if="item.value" class="key">{{ item.key }}：</p>
      <p v-if="item.value" class="value">{{ item.value }}</p>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    dataProps: {
      type: Array,
      default: () => {}
    }
  }
}
</script>

<style scoped lang="scss">
p {
  margin: 0;
  padding: 5px 0;
  font-size: 13px;
}
ul {
  li {
    display: flex;
    .value {
      color: rgb(136, 136, 136);
    }
  }
}
</style>
