<!--
 * @Description: 选择推广单元等框 Table 数据要有(_id,_name)
 * @Date: 2021-11-09 11:49:47
 * @LastEditTime: 2022-04-20 16:54:38
-->
<template>
  <section class="section">
    <el-row type="flex" justify="space-between" style="height: 100%">
      <el-col class="tree" :span="6">
        <div class="left-ul">
          <p>已选{{ `${leftTitle}` }}</p>
          <!-- 模板数据 -->
          <ul>
            <div v-if="_leftQueryList.length">
              <!-- 左侧选择框是  tree 还是  <li></li> -->
              <div v-if="titleInfo.leftLevel === '2'">
                <el-tree :render-content="renderContent" :data="leftQueryList" default-expand-all :props="defaultProps" @node-click="setLeftQuerySelect"></el-tree>
              </div>
              <div v-else v-loading="treeLoading">
                <li v-for="item in _leftQueryList" :key="item._id" :class="{ active: leftQuerySelect._id === item._id }" @click="setLeftQuerySelect(item)">{{ item._name }}</li>
              </div>
            </div>
            <div v-else style="text-align:center; margin-top: 40px">
              <span>未选择</span>
            </div>
          </ul>
        </div>
      </el-col>

      <!-- 已选择部分 -->
      <el-col class="selected-tree" :span="selectPageList ? 12 : 18">
        <div class="justify-sb">
          <!-- 查询 -->
          <div class="justify-start" style="width: 400px">
            <!-- 商品  -->
            <el-select v-if="['商品'].includes(dialogTitle)" v-model="catalogId" style="width: 150px; margin-right: 10px" @change="selectChange">
              <el-option v-for="item in goodsList" :key="item.catalog_id" :label="item.name" :value="item.catalog_id"> </el-option>
            </el-select>
            <!-- 人群包 -->
            <el-radio-group v-if="['人群包'].includes(dialogTitle)" v-model="crowdType" @change="crowdSearchPage">
              <el-radio-button :label="0">定向人群</el-radio-button>
              <el-radio-button :label="1">排除人群</el-radio-button>
            </el-radio-group>
            <!-- 默认展示 -->
            <el-input v-if="!['人群包'].includes(dialogTitle)" v-model="selectTable" placeholder="请输入关键词查询" style="flex: 1">
              <el-button slot="append" icon="el-icon-search" @click="searchPage"></el-button>
            </el-input>
          </div>
          <!-- 同步 -->
          <el-button v-if="queryText1" type="text" class="ml-20" @click="queryFun1(leftQuerySelect._id)">{{ queryText1 }}</el-button>
        </div>

        <div v-if="['人群包'].includes(dialogTitle)">
          <TablePane v-if="crowdType === 0" ref="TablePane" :data-source="dataSource.dataSource" class="table mt-20" @getPage="getPage"></TablePane>
          <TablePane2 v-else ref="TablePane" :data-source="dataSource.dataSource2" class="table mt-20" @getPage="getPage"></TablePane2>
        </div>
        <div v-else>
          <TablePane ref="TablePane" :data-source="dataSource" class="table mt-20" @getPage="getPage"></TablePane>
        </div>
      </el-col>

      <!-- 已选择部分 -->
      <el-col v-if="selectPageList" class="selected-tree" :span="6">
        <p>已选{{ dialogTitle }}</p>
        <ul v-if="['人群包'].includes(dialogTitle)">
          <p class="mt-10 tip">定向人群:</p>
          <li v-for="item in selectPageList[0]" :key="item.id" class="selected-tree-li">
            <p>{{ item.show_name || item.name }}</p>
            <i class="el-icon-circle-close" @click="deleteCrowdItem(item.id, 0)"></i>
          </li>
          <p class="mt-10  tip">排除人群:</p>
          <li v-for="item in selectPageList[1]" :key="item.id" class="selected-tree-li">
            <p>{{ item.show_name || item.name }}</p>
            <i class="el-icon-circle-close" @click="deleteCrowdItem(item.id, 1)"></i>
          </li>
        </ul>

        <ul v-else>
          <li v-for="item in selectPageList" :key="item.id" class="selected-tree-li">
            <p>{{ item.show_name || item.name }}</p>
            <i class="el-icon-circle-close" @click="deleteItem(item.id)"></i>
          </li>
        </ul>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import TablePane from '@/components/tablePane.vue'
import { getGoodsList } from '@/network/api/advManagement/advManagementWatch'

export default {
  inject: ['getDataByLeftQuery', 'queryFun1', 'queryFun2', 'queryText1', 'queryText2', 'setSelectPageList', 'deleteSelectData'],
  components: {
    TablePane,
    TablePane2: TablePane
  },
  props: {
    dialogTitle: {
      type: String,
      default: ''
    },
    leftTitle: {
      type: String,
      default: ''
    },
    titleInfo: {
      type: Object,
      default: () => {}
    },
    visible: {
      type: Boolean,
      default: false
    },
    // 模板数据
    data: {
      type: Array,
      default: () => []
    },
    // 模板左侧数据
    leftQueryList: {
      type: Array,
      default: () => []
    },
    dataSource: {
      type: Object,
      default: () => {}
    },
    // 选中的项
    /* eslint-disable */
    selectPageList: {
      type: Array,
      require: false
    },
    /* eslint-disable */
    queryFun: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      crowdType: 0, //定向人群 '0', 排除人群 "1'
      selectTable: '',
      catalogId: null,
      catalog: {},
      goodsList: [],
      filterText: '', // 过滤主体类容
      subTitleInedx: 0,
      // 选中的数据
      selectedData: this.selectPageList || [], // 选中的数据
      // 本地左侧选中的数据
      leftQuerySelect: {},
      selectedDataOnline_copy: {},
      selectedDataOnlineList: [],
      copyList: [],
      treeLoading: false,
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },

  computed: {
    // 去除重复数据
    _selectedData() {
      let data = []
      this.selectedData.map((item) => {
        if (!data.some((s) => s.id === item.id) && !item.type2) {
          data.push(item)
        }
      })
      return data
    },

    // 去重localQuery
    _leftQueryList() {
      let data = []
      this.leftQueryList.map((item) => {
        if (!data.some((s) => s._id === item._id)) {
          data.push(item)
        }
      })
      return data
    },
    // 左侧树形选择框
    _localQueryTree() {
      let newArr = []
      this.leftQueryList.map((item) => {
        if (!newArr.some((s) => s && s.label === item._name)) {
          newArr.push({
            ...item,
            label: item.accountName
            // children: []
          })
        }
      })

      if (this.titleInfo.leftLevel === '2') {
        this.leftQueryList.forEach((element) => {
          newArr.forEach((newElement) => {
            if (element.accountName === newElement.label) {
              newElement.children = newElement.children ? newElement.children : []
              newElement.children.push({
                ...element,
                label: element.label
              })
            }
          })
        })
      }
      if (this.dialogTitle === '3') {
        this.leftQueryList.forEach((element) => {
          newArr.forEach((newElement) => {
            if (element.accountName === newElement.label) {
              newElement.children = newElement.children ? newElement.children : []
              if (newElement.children.length === 0 || !newElement.children.some((s) => s.label === element.baiduUnitName)) {
                newElement.children.push({
                  ...element,
                  label: element.campaignFeedName
                })
              }
            }
          })
        })

        newArr.forEach((newElement) => {
          newElement.children.forEach((item) => {
            item.children = this.leftQueryList.filter((f) => f.baiduUnitName === item.baiduUnitName && f.campaignFeedId === item.campaignFeedId)
          })
        })
      }
      return newArr
    },
    // 选中 线上数据中 的 模板数据
    _selectedDataOnlineList() {
      let newArr = []
      this.selectedDataOnlineList.map((item) => {
        if (!newArr.some((s) => s && s.campaignFeedName === item.campaignFeedName)) {
          newArr.push({
            ...item
          })
        }
      })

      this.selectedDataOnlineList.forEach((element) => {
        newArr.forEach((newElement) => {
          if (element.campaignFeedName === newElement.campaignFeedName) {
            newElement.children = newElement.children ? newElement.children : []
            newElement.children.push({
              ...element
            })
          }
        })
      })
      return newArr
    }
  },

  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },

    selectPageList: {
      handler: function(value) {
        this.setSelectData(value)
      }
    },

    visible: {
      handler: function(value) {
        if (value === true) {
          if (this.leftQueryList.length) {
            this.setLeftQuerySelect(this.leftQueryList[0])
          }
        }
      },
      immediate: true
    }
  },

  methods: {
    /**
     * @description 获取商品库(后期修改)
     */
    async getGoodsListFun() {
      if (this.dialogTitle !== '商品') return
      const { code, data } = await getGoodsList({ taskAccountId: this.leftQuerySelect._id })
      if (code === 200) {
        if (data.length === 0) {
          this.goodsList = []
          this.catalogId = null
          this.catalog = {}
          this.searchPage()
        } else {
          this.goodsList = data
          this.catalogId = data[0].catalog_id
          this.catalog = data[0]
          this.searchPage()
          this.setSelectData()
        }
        this.treeLoading = false
      }
    },
    /**
     * @description 切换商品库(后期修改)
     */
    selectChange(value) {
      this.catalog = this.goodsList.filter((f) => f.catalog_id === value)[0]
      this.catalogId = value
      this.selectTable = ''
      this.searchPage()
    },

    /**
     * @description 筛选tree
     */
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },

    renderContent(h, { node }) {
      return (
        // eslint-disable-next-line
        <span class="custom-tree-node text-ellipsis">
          <a>{node.label}</a>
        </span>
      )
    },

    /**
     * @description 获取本地数据
     */
    async setLeftQuerySelect(item) {
      this.treeLoading = true
      this.leftQuerySelect = item
      this.selectTable = ''
      if (this.dialogTitle !== '商品') {
        await this.getDataByLeftQuery({ item, catalog: null, crowdType: this.crowdType }) // 获取模板数据, 该方法是调用父组件方法
        this.treeLoading = false
        this.setSelectData()
      } else {
        this.getGoodsListFun()
      }
    },

    /**
     * @description 获取本地数据
     */
    async searchPage() {
      await this.getDataByLeftQuery({ item: this.leftQuerySelect, pageNum: 1, pageSize: 10, searchName: this.selectTable, catalog: this.catalog, catalog_id: this.catalogId, crowdType: this.crowdType })
      this.setSelectData()
    },

    /**
     * @description 人群包获取本地数据
     */
    async crowdSearchPage(type) {
      await this.getDataByLeftQuery({ item: this.leftQuerySelect, pageNum: 1, pageSize: 10, searchName: this.selectTable, catalog: this.catalog, catalog_id: this.catalogId, crowdType: type })
      this.setSelectData()
    },

    /**
     * @description 获取本地数据
     */
    async getPage(params) {
      this.getDataByLeftQuery({ item: this.leftQuerySelect, ...params, crowdType: this.crowdType })
    },

    /**
     * @description 删除选中的数据
     */
    deleteItem(id) {
      let data = JSON.parse(JSON.stringify(this.selectPageList)).filter((f) => f.id !== id)
      if (this.deleteSelectData) {
        this.deleteSelectData(id)
      }

      this.setSelectPageList(data)
      this.setSelectData(data)
    },

    /**
     * @description 删除选中的数据
     */
    deleteCrowdItem(id, index) {
      let data = JSON.parse(JSON.stringify(this.selectPageList[index])).filter((f) => f.id !== id)
      this.selectPageList[index] = data
      if (this.deleteSelectData) {
        this.deleteSelectData(id)
      }
      this.setSelectPageList(this.selectPageList)
      this.setSelectData(this.selectPageList, index)
    },

    setSelectData(data = this.selectPageList, index = this.crowdType) {
      this.$refs['TablePane'].getRowData([])
      if (this.dialogTitle === '人群包') {
        data = this.selectPageList[index]
        if (data.length) {
          let rows = []
          this.dataSource[index === 0 ? 'dataSource' : 'dataSource2'].data.map((item) => {
            if (data.some((s) => s.id === item.id)) {
              rows.push(item)
            }
          })
          this.$refs['TablePane'].getRowData(rows)
        }
      } else {
        if (data.length) {
          let rows = []
          this.dataSource.data.map((item) => {
            if (data.some((s) => s.id === item.id)) {
              rows.push(item)
            }
          })
          this.$refs['TablePane'].getRowData(rows)
        }
      }
    },

    /**
     * @description 重置数据
     */
    resetData() {
      this.filterText = ''
    }
  }
}
</script>

<style lang="scss">
.left-tree {
  .el-tree-node > .el-tree-node__content {
    .el-tree-node__label {
      font-weight: 600;
    }
  }
  .el-tree-node__children {
    .el-tree-node__label {
      font-weight: 500 !important;
      margin-left: 10px;
    }
  }
}
.tree {
  // width: 23% !important;
  .is-leaf {
    display: none;
  }
}
.selected-tree {
  .el-menu-vertical-demo {
    border-right: none !important;
    .el-submenu__title {
      height: 24px;
      line-height: 24px;
      font-size: 14px;
      background: antiquewhite;
    }
    .el-menu {
      li {
        height: 26px;
        line-height: 26px;
      }
    }
    .el-menu-item.is-active {
      color: #333;
    }
  }
}
</style>

<style lang="scss" scoped>
p {
  margin: 0;
  padding: 0;
}

.section {
  height: 500px;
}

.filter-tree-box {
  padding-left: 10px;
  background: #ccc;
  height: 450px;
  overflow-x: auto;
}
.tree {
  height: 100%;
  padding-left: 10px !important;
  border: 1px solid #ccc;
  border-radius: 8px;
  // padding: 20px;
  display: flex;

  .left-ul {
    // padding-top: 10px;
    width: 100%;
    // border-right: 1px solid #ccc;
    p {
      font-size: 16px;
      width: 100%;
      padding: 10px;
      border-bottom: 1px solid #ccc;
    }
    ul {
      overflow-y: auto;
      height: 452px;
      li {
        padding: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        &.active {
          background: #409eff;
          color: #fff;
        }
      }
    }
  }
  &.el-col {
    padding: 0 !important;
  }
}

.tree-select {
  flex: 1;
  .tree-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px;
    border-bottom: 1px solid #ccc;
    p {
      font-size: 16px;
    }
    ul {
      display: flex;
      li {
        margin: 0 10px 0 5px;
        cursor: pointer;
        &.active {
          color: #409eff;
        }
        &.disable {
          color: #ccc;
        }
      }
    }
  }

  // .filter-tree {
  //   padding-left: 10px;
  // }
}
.selected-tree {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-left: 15px;
  height: 500px;
  overflow-y: auto;
  .selected-tree-li {
    display: flex;
    align-items: center;
    padding: 4px;
    &:hover {
      background: #f2f2f2;
      // color: #f2f2f2;
    }
    p {
      // width: 80%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex: 1;
    }
    .count {
      margin: 0 4px;
      padding: 0 3px;
      border: 1px solid #ccc;
      font-size: 10px;
      border-radius: 4px;
    }
    i {
      cursor: pointer;
    }
  }
}
</style>
