var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "300px" } },
    [
      _vm.adForm.flag
        ? _c("ContentBox", { attrs: { "data-props": _vm.adDiv } })
        : _c("div", { staticClass: "kong" }, [_vm._v("请选择广告")]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "广告基础信息",
            visible: _vm.visible,
            width: "800px",
            "modal-append-to-body": false,
          },
          on: { close: _vm.clickClose },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.adForm, "label-width": "100px" },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "投放时间:",
                        rules: {
                          required: true,
                          message: "请选择投放时间",
                          trigger: "blue",
                        },
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.adForm.launchData,
                            callback: function ($$v) {
                              _vm.$set(_vm.adForm, "launchData", $$v)
                            },
                            expression: "adForm.launchData",
                          },
                        },
                        [_vm._v("选择开始和结束日期")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "2" },
                          model: {
                            value: _vm.adForm.launchData,
                            callback: function ($$v) {
                              _vm.$set(_vm.adForm, "launchData", $$v)
                            },
                            expression: "adForm.launchData",
                          },
                        },
                        [_vm._v("长期投放")]
                      ),
                    ],
                    1
                  ),
                  _vm.adForm.launchData === "1"
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "",
                            rules: {
                              required: true,
                              message: "请选择投放时间",
                              trigger: "blue",
                            },
                          },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "400px" },
                            attrs: {
                              "picker-options": _vm.pickerOptions,
                              "value-format": "yyyy-MM-dd",
                              type: "daterange",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                            },
                            model: {
                              value: _vm.adForm.date,
                              callback: function ($$v) {
                                _vm.$set(_vm.adForm, "date", $$v)
                              },
                              expression: "adForm.date",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.adForm.launchData === "2"
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "开始日期:",
                            rules: {
                              required: true,
                              message: "请选择开始日期",
                              trigger: "blue",
                            },
                          },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "400px" },
                            attrs: {
                              "picker-options": _vm.pickerOptions,
                              "value-format": "yyyy-MM-dd",
                              type: "date",
                              placeholder: "请选择开始日期",
                            },
                            model: {
                              value: _vm.adForm.date2,
                              callback: function ($$v) {
                                _vm.$set(_vm.adForm, "date2", $$v)
                              },
                              expression: "adForm.date2",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "投放时断:" } },
                    [
                      _c("el-radio", { attrs: { value: "1", label: "1" } }, [
                        _vm._v("全天投放"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "归因方式:", prop: "trackingSourceid" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.adForm.trackingSourceid,
                            callback: function ($$v) {
                              _vm.$set(_vm.adForm, "trackingSourceid", $$v)
                            },
                            expression: "adForm.trackingSourceid",
                          },
                        },
                        [_vm._v("全网归因 ")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "0" },
                          model: {
                            value: _vm.adForm.trackingSourceid,
                            callback: function ($$v) {
                              _vm.$set(_vm.adForm, "trackingSourceid", $$v)
                            },
                            expression: "adForm.trackingSourceid",
                          },
                        },
                        [_vm._v("精准匹配归因")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "出价方式:" } },
                    [
                      [
                        "展示Banner图片",
                        "优雅横版大图",
                        "公众号文章底部视频",
                        "公众号文章底部图片",
                        "公众号文章中部",
                        "小程序banner广告",
                      ].includes(_vm.crtSizeLabel)
                        ? _c(
                            "el-radio",
                            { attrs: { label: "1", value: "1" } },
                            [_vm._v("oCPC")]
                          )
                        : _vm._e(),
                      ["朋友圈", "沉浸式横版视频"].includes(_vm.crtSizeLabel)
                        ? _c(
                            "el-radio",
                            { attrs: { value: "1", label: "1" } },
                            [_vm._v("oCPM")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "投放模式:" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.adForm.bidStrategy,
                            callback: function ($$v) {
                              _vm.$set(_vm.adForm, "bidStrategy", $$v)
                            },
                            expression: "adForm.bidStrategy",
                          },
                        },
                        [_vm._v("均衡投放")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 2 },
                          model: {
                            value: _vm.adForm.bidStrategy,
                            callback: function ($$v) {
                              _vm.$set(_vm.adForm, "bidStrategy", $$v)
                            },
                            expression: "adForm.bidStrategy",
                          },
                        },
                        [_vm._v("优先跑量")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 3 },
                          model: {
                            value: _vm.adForm.bidStrategy,
                            callback: function ($$v) {
                              _vm.$set(_vm.adForm, "bidStrategy", $$v)
                            },
                            expression: "adForm.bidStrategy",
                          },
                        },
                        [_vm._v("优先成本")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "优化目标:" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "mt-10",
                          on: { change: _vm.setBidObjective },
                          model: {
                            value: _vm.adForm.bidObjective,
                            callback: function ($$v) {
                              _vm.$set(_vm.adForm, "bidObjective", $$v)
                            },
                            expression: "adForm.bidObjective",
                          },
                        },
                        _vm._l(_vm.objectiveList, function (item) {
                          return _c(
                            "el-radio",
                            {
                              key: item.value,
                              staticClass: "mb-10",
                              attrs: { label: item.value },
                            },
                            [_vm._v(_vm._s(item.label))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  [6, 10, 25].includes(+_vm.adForm.bidObjective)
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "深度优化:" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "mt-10",
                              on: { change: _vm.setBidObjectiveMode },
                              model: {
                                value: _vm.adForm.bidObjectiveMode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.adForm, "bidObjectiveMode", $$v)
                                },
                                expression: "adForm.bidObjectiveMode",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("不启用"),
                              ]),
                              _vm.bidObjectiveList &&
                              _vm.bidObjectiveList.length > 0
                                ? _c("el-radio", { attrs: { label: "2" } }, [
                                    _vm._v("优化转化行为"),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.adForm.bidObjectiveMode === "2" &&
                  _vm.bidObjectiveList &&
                  _vm.bidObjectiveList.length > 0
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "深度优化目标:" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              staticClass: "mt-10",
                              model: {
                                value: _vm.adForm.secondBidObjective,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.adForm,
                                    "secondBidObjective",
                                    $$v
                                  )
                                },
                                expression: "adForm.secondBidObjective",
                              },
                            },
                            _vm._l(_vm.bidObjectiveList, function (item) {
                              return _c(
                                "el-radio",
                                {
                                  key: item.value,
                                  staticClass: "mb-10",
                                  attrs: { label: item.value },
                                },
                                [_vm._v(_vm._s(item.label))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.adForm.bidObjectiveMode === "2"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "深度出价:" } },
                        [
                          _c("el-input-number", {
                            attrs: { min: 1, "controls-position": "right" },
                            model: {
                              value: _vm.adForm.secondBid,
                              callback: function ($$v) {
                                _vm.$set(_vm.adForm, "secondBid", $$v)
                              },
                              expression: "adForm.secondBid",
                            },
                          }),
                          _c("span", { staticClass: "ml-10" }, [_vm._v("元")]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "每日预算:" } },
                            [
                              _c("el-input-number", {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  min: 50,
                                  max: 40000000,
                                  "controls-position": "right",
                                },
                                model: {
                                  value: _vm.adForm.dayBudget,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.adForm, "dayBudget", $$v)
                                  },
                                  expression: "adForm.dayBudget",
                                },
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "ml-10",
                                  staticStyle: { "white-space": "nowrap" },
                                },
                                [_vm._v("元/天")]
                              ),
                              _c("p", { staticClass: "tip" }, [
                                _vm._v(
                                  "每日预算有效范围为 50 ~ 40,000,000 元/天"
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "出价:" } },
                            [
                              _c("el-input-number", {
                                staticStyle: { width: "180px" },
                                attrs: { min: 0, "controls-position": "right" },
                                model: {
                                  value: _vm.adForm.bid,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.adForm, "bid", $$v)
                                  },
                                  expression: "adForm.bid",
                                },
                              }),
                              _c("span", { staticClass: "ml-10" }, [
                                _vm._v("元"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-divider"),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "mt-20",
                      attrs: {
                        label: "广告名称:",
                        prop: "aname",
                        rules: {
                          required: true,
                          message: "请输入广告名称",
                          trigger: "blue",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        staticClass: "mt-10",
                        staticStyle: { width: "400px" },
                        attrs: {
                          rows: "4",
                          type: "textarea",
                          placeholder: "请输入广告名称",
                          maxlength: 60,
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.adForm.aname,
                          callback: function ($$v) {
                            _vm.$set(_vm.adForm, "aname", $$v)
                          },
                          expression: "adForm.aname",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "display:start" },
                        [
                          _vm._v(" 通配符： "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  _vm.addAName(
                                    _vm.moment().format("YYYYMMDDHHmmss")
                                  )
                                },
                              },
                            },
                            [_vm._v("+日期")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.addAName(`0${_vm.nameIndex}`)
                                },
                              },
                            },
                            [_vm._v("+标号")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.addAName(_vm.userInfo.username)
                                },
                              },
                            },
                            [_vm._v("+投手名称")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: !_vm.isSearchLock },
                  on: { click: _vm.getSelectPlanData },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }