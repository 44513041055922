<!--
 * @Description: 轮播图编辑区
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2022-03-02 16:48:21
-->

<template>
  <section>
    <base-box name="slider">
      <template slot="desc">
        适用广告位：朋友圈信息流<br />
        适用外层样式：常规广告<br />
        创建广告时，仅选择了以上广告位与外层样式的广告可使用此推广页。广告外层将自动拉取顶部轮播图。
      </template>
      <template slot="body">
        <div>
          <p class="font-13">图片数量：</p>
          <div>
            <el-radio-group v-model="detail.componentCount" size="medium" @change="setImgCount">
              <el-radio-button label="2"></el-radio-button>
              <el-radio-button label="3"></el-radio-button>
              <el-radio-button label="4"></el-radio-button>
              <el-radio-button label="5"></el-radio-button>
            </el-radio-group>
          </div>
        </div>

        <div>
          <p class="font-13">轮播样式：</p>
          <el-row :gutter="50">
            <el-col :span="12" class="carousel">
              <div :class="{ 'active-carousel': detail.type === '101' }" @click="settype('101')">
                <el-carousel :interval="2000" arrow="never">
                  <el-carousel-item v-for="item in 3" :key="item">
                    <div class="carousel-item">
                      <div class="small" style="width: 100%"></div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </el-col>
            <el-col :span="12" class="carousel">
              <div :class="{ 'active-carousel': detail.type === '102' }" @click="settype('102')">
                <el-carousel :interval="2000" arrow="never">
                  <el-carousel-item>
                    <div class="carousel-item">
                      <div class="small" style="width: 15%"></div>
                      <div class="big" style="width: 82%"></div>
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="carousel-item">
                      <div class="small" style="width: 12%"></div>
                      <div class="big" style="width: 69%"></div>
                      <div class="small" style="width: 12%"></div>
                    </div>
                  </el-carousel-item>
                  <el-carousel-item>
                    <div class="carousel-item">
                      <div class="small" style="width: 82%"></div>
                      <div class="big" style="width: 15%"></div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </el-col>
          </el-row>
        </div>

        <div class="image-updata">
          <p class="font-13">选择图片：</p>
          <div v-if="detail.type === '101'">
            <div v-for="item in detail.componentGroupList.componentGroup" :key="item.id" class="update" @click="setMaterialBox">
              <div v-if="item.componentItem.pureImageUrl !== ''" class="image">
                <img :src="item.componentItem.pureImageUrl" />
              </div>
              <i v-else class="el-icon-plus avatar-uploader-icon" />
            </div>
          </div>
          <div v-if="detail.type === '102'">
            <div v-for="item in detail.componentGroupList.componentItem" :key="item.id" class="update" @click="setMaterialBox">
              <div v-if="item.pureImageUrl !== ''" class="image">
                <img :src="item.pureImageUrl" />
              </div>
              <i v-else class="el-icon-plus avatar-uploader-icon" />
            </div>
          </div>
        </div>
        <p class="tip mt-10" style="margin-left:70px">
          暂不支持含有二维码的图片 <br />
          图片格式：大小不超过300KB，不支持Gif <br />
          图片尺寸：宽度750像素，高度不超过300-1125像素
        </p>
      </template>
    </base-box>

    <material-box
      :visible="materialBox_visible"
      :img-count="+detail.componentCount"
      :image-list="detail.type === '101' ? imageList_101 : imageList_102"
      name="ldy"
      :attribute="{
        key: 'slider',
        value: '轮播图',
        count: +detail.componentCount,
        size: 300 * 1024,
        listType: 'image/jpg,image/jpeg,image/png',
        width: 750,
        height: [300, 1125]
      }"
      @setMaterialBox="setMaterialBox"
      @submit="selectImg"
    />
  </section>
</template>

<script>
import BaseBox from './base.vue'
import MaterialBox from '@/components/materialBox'
import { topSlider_img_item } from '../defaultWidgetData'
export default {
  components: {
    'base-box': BaseBox,
    MaterialBox
  },
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      materialBox_visible: false,
      imageList_101: [],
      imageList_102: []
    }
  },
  methods: {
    settype(type) {
      this.detail.type = type
      if (type === '101') {
        this.detail.componentCount = this.detail.componentGroupList.componentGroup.length + ''
        // 置空 102
        const { componentItem } = { ...this.detail.componentGroupList }
        componentItem.forEach((element) => {
          element.pureImageUrl = ''
          element.imageHeight = ''
          element.imageWidth = ''
        })
        this.detail.componentGroupList.componentItem = componentItem
      } else {
        this.detail.componentCount = this.detail.componentGroupList.componentItem.length + ''
        // 置空 101
        const { componentGroup } = { ...this.detail.componentGroupList }
        componentGroup.forEach((element) => {
          element.componentItem.pureImageUrl = ''
          element.componentItem.imageHeight = ''
          element.componentItem.imageWidth = ''
        })
        this.detail.componentGroupList.componentGroup = componentGroup
      }
    },
    /**
     * @description: 设置轮播图数量
     */
    setImgCount(value) {
      const { detail } = this
      let componentGroupList = { ...this.detail.componentGroupList }
      if (detail.type === '102') {
        let componentCount_102 = componentGroupList.componentItem.length
        // 数量变多添加图结构
        if (+value > +componentCount_102) {
          for (let index = 0; index < +value - componentCount_102; index++) {
            componentGroupList.componentItem.push({
              id: `slider_${new Date().getTime()}_${componentCount_102 + index}`,
              ...topSlider_img_item
            })
          }
          this.detail.componentGroupList.componentItem = componentGroupList.componentItem
        } else {
          // 数量变少减少图结构
          let { componentItem } = componentGroupList
          this.detail.componentGroupList.componentItem = componentItem.slice(0, +value)
        }
      }
      if (detail.type === '101') {
        let componentCount = componentGroupList.componentGroup.length
        // 数量变多添加图结构
        if (+value > +componentCount) {
          for (let index = 0; index < +value - componentCount; index++) {
            componentGroupList.componentGroup.push({
              id: `slider_${new Date().getTime()}_${componentCount + index}`,
              componentItem: { ...topSlider_img_item }
            })
          }
          this.detail.componentGroupList.componentGroup = componentGroupList.componentGroup
        } else {
          // 数量变少减少图结构
          let { componentGroup } = componentGroupList
          this.detail.componentGroupList.componentGroup = componentGroup.slice(0, +value)
        }
      }
    },

    /**
     * @description: 打开/关闭资源库
     */
    setMaterialBox() {
      this.materialBox_visible = !this.materialBox_visible
    },

    /**
     * @description: 选择图片后调用
     * @param {Array} img 图片信息
     */
    selectImg(imgs) {
      const { detail } = this
      if (detail.type === '101') {
        this.imageList_101 = imgs
        detail.id = 1
        const { componentGroup } = { ...this.detail.componentGroupList }
        imgs.map((item, index) => {
          const { image, width, height } = item
          let componentItem = componentGroup[index].componentItem
          componentGroup[index] = {
            ...componentGroup[index],
            componentItem: {
              ...componentItem,
              pureImageUrl: image,
              imageHeight: width + '',
              imageWidth: height + ''
            }
          }
        })
        detail.componentGroupList.componentGroup = componentGroup
      }

      if (detail.type === '102') {
        this.imageList_102 = imgs
        const { componentItem } = { ...detail.componentGroupList }
        imgs.map((item, index) => {
          const { image, width, height } = item
          componentItem[index] = {
            ...componentItem[index],
            pureImageUrl: image,
            imageHeight: width + '',
            imageWidth: height + ''
          }
        })
        detail.componentGroupList.componentItem = componentItem
      }
      detail.id = `widget_${new Date().getTime()}_0`
    }
  }
}
</script>
<style lang="scss">
.carousel {
  & > div {
    border: 2px transparent solid;
  }
  .active-carousel {
    border: 2px #449eff solid;
    border-radius: 6px;
  }
  .el-carousel {
    height: 100px;
    border-radius: 6px;
  }
  .el-carousel__container {
    height: 100px !important;
  }

  .carousel-item {
    height: 100%;
    display: flex;
    justify-content: space-between;
    background-color: rgb(241, 241, 241);
    padding: 10px;
    .small {
      background-color: #d3dce6;
      height: 100%;
    }
    .big {
      background-color: #1f2f3d;
    }
  }
  .el-carousel__indicator {
    .el-carousel__button {
      width: 4px;
      height: 4px;
      border-radius: 50%;
    }
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
}
</style>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.update {
  width: 130px !important;
  height: 130px;
  line-height: 100px;
  text-align: center;
  border: 1px solid #edecf2;
  background-color: #fbfafd;
  border-radius: 3px;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }
  .avatar {
    width: 130px;
  }
}
.image-updata {
  display: flex !important;
  align-items: flex-start !important;
  & > div {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
