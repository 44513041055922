var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "end-test-dialog",
      attrs: {
        title: "结束测试",
        visible: _vm.show,
        "append-to-body": true,
        width: "500px",
        top: "20vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.onCloseDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                inline: false,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "结束时间", prop: "endTime" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      type: "date",
                      placeholder: "选择日期时间",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      "picker-options": _vm.endPickerOptions,
                    },
                    model: {
                      value: _vm.form.endTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "endTime", $$v)
                      },
                      expression: "form.endTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "测试结果", prop: "testResult" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { placeholder: "请选择测试结果" },
                      model: {
                        value: _vm.form.testResult,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "testResult", $$v)
                        },
                        expression: "form.testResult",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "成功", value: "3" } }),
                      _c("el-option", { attrs: { label: "失败", value: "2" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "btn", on: { click: _vm.closeModal } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { type: "primary", disabled: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }