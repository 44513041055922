<!--
 * @Author: Zeng
 * @Date: 2023-06-08 14:47:01
 * @LastEditTime: 2024-03-12 11:07:08
-->
<template>
  <el-dialog title="投放账户信息" :visible.sync="show" width="620px" :modal-append-to-body="false" destroy-on-close
    @close="closeModal">
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm">
      <el-form-item label="账户名称" prop="corporationName">
        <el-input v-model="ruleForm.corporationName" placeholder="请输入账户名称" style="width: 100%;" maxlength="15" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="项目" prop="projectId">
        <el-select v-model="ruleForm.projectId" style="width: 100%;" placeholder="请选择项目"
          @change="(value) => (selectProjectId = value)">
          <el-option v-for="item in projectList" :key="item.bsProjectId" :label="item.projectName"
            :value="item.bsProjectId" />
        </el-select>
      </el-form-item>

      <el-form-item label="产品" prop="productId">
        <el-select v-model="ruleForm.productId" style="width: 100%;" placeholder="请选择产品"
          @change="(value) => (selectProductId = value)">
          <el-option label="不限" :value="0"></el-option>
          <el-option v-for="(itm, idx) in productListed" :key="idx" :label="itm.productName"
            :value="itm.bsProductId"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="组别" prop="departmentId">
        <el-cascader ref="deptRef" v-model="ruleForm.departmentId" :options="deptList" :props="cascaderProp" filterable
          placeholder="请选择组别" @change="handleSelectDept" style="width: 100%;"></el-cascader>
      </el-form-item>

      <el-form-item label="投放人员" prop="launchId">
        <el-select v-model="ruleForm.launchId" value-key="key" filterable clearable placeholder="请选择投放人员"
          style="width: 100%;">
          <el-option v-for="(itm, idx) in userList" :key="idx" :label="itm.userName" :value="itm.userId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="投放状态" prop="putInStatus">
        <el-select v-model="ruleForm.putInStatus" placeholder="请选择投放状态" style="width: 100%;">
          <el-option label="投放" :value="1"></el-option>
          <el-option label="停投" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="ruleForm.remark" type="textarea" :rows="4" maxlength="30" show-word-limit
          placeholder="请输入内容,内容最多数日30个字符" style="width: 100%;"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleSubmit">{{ ruleForm.id ? '确定' : '立即绑定' }}</el-button>
      <el-button @click="closeModal">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  getDeptList,
  getUserList
} from '@/network/api/toolManagement/api-product-test'
import { saveTencentAdv, updateTencentAdv } from '@/network/api/account/tencentAdvAccount.js'
import AccountBaidu from '@/mixin/account_baidu'

export default {
  mixins: [AccountBaidu],
  data() {
    return {
      show: false,
      cascaderProp: {
        emitPath: false,
        checkStrictly: true,
        value: 'departmentId',
        label: 'departmentName',
        children: 'children'
      },
      deptList: [],
      userList: [], // 用户list
      ruleForm: {
        projectId: null,
        productId: 0,
        putInStatus: null,
        departmentId: null,
        launchId: null,
        remark: null
      },
      rules: {
        corporationName: [{ required: true, message: '请输入账户名称', trigger: 'blur' }],
        projectId: [{ required: true, message: '请选择项目', trigger: 'change' }],
        departmentId: [{ required: true, message: '请选择组别', trigger: 'change' }],
        launchId: [{ required: true, message: '请选择投放人员', trigger: 'change' }],
        putInStatus: [{ required: true, message: '请选择投放状态', trigger: 'change' }]
      }
    }
  },
  watch: {
    selectProjectId() {
      this.ruleForm.productId = 0
    }
  },

  created() { },

  mounted() { },
  methods: {
    showModal(row) {
      this.selectProjectId = +row.projectId
      this.selectProductId = +row.productId
      if (row) {
        this.ruleForm = {
          ...this.ruleForm,
          id: row.id,
          corporationName: row.corporationName,
          projectId: +row.projectId,
          productId: +row.productId,
          putInStatus: row.putInStatus,
          departmentId: row.departmentId || '',
          launchId: row.launchId,
          remark: row.remark
        }
        this._getUserList()
      }
      this._getDeptList()
      this.show = true
    },
    closeModal() {
      this.$refs.ruleForm.resetForm()
      this.ruleForm = {
        projectId: null,
        productId: 0,
        putInStatus: null,
        departmentId: null,
        launchId: null,
        remark: null
      }
      this.show = false
    },

    handleSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (!valid) return
        let { id, projectId, productId, remark, launchId, departmentId, putInStatus } = this.ruleForm
        let node = this.$refs.deptRef.getCheckedNodes()
        let params = {
          projectId,
          projectName: this.projectList.filter((item) => item.bsProjectId === projectId)[0].projectName,
          productId: productId !== 0 ? productId : 0,
          productName: productId !== 0 ? this.productListed.filter((item) => item.bsProductId === productId)[0].productName : null,
          launchId,
          launchName: this.userList.length ? this.userList.filter((item) => item.userId === launchId)[0].userName : null,
          departmentId,
          departmentName: node.length ? node[0].label : '',
          remark
        }
        if (id) { // 编辑
          params = {
            ...params,
            id,
            putInStatus
          }
          updateTencentAdv(params).then((res) => {
            if (res && res.code === 200) {
              this.$message.success('编辑成功!')
              this.$emit('confirm')
              this.closeModal()
            }
          })
        } else {
          // 添加绑定
          saveTencentAdv(params).then((res) => {
            if (res && res.code === 200) {
              this.$emit('confirm', res.data)
              this.closeModal()
            }
          })
        }
      })
    },

    // 选择部门
    handleSelectDept() {
      this.ruleForm.launchId = null
      this._getUserList()
    },

    // 获取组别
    _getDeptList() {
      getDeptList({ departmentName: '' }).then(res => {
        let list = res.data.map(v => {
          return {
            departmentId: v.departmentId,
            departmentName: v.departmentName,
            children: v.children ? JSON.parse(v.children) : []
          }
        })
        this.deptList = list || []
      })
    },

    // 获取成员列表
    _getUserList() {
      getUserList({ departmentId: this.ruleForm.departmentId, userName: '' }).then((res) => {
        if (res.code === 200) {
          this.userList = res.data
        }
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
