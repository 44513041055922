<!--
 * @Description: Do not edit
 * @Date: 2021-10-25 10:21:35
 * @LastEditTime: 2022-05-19 16:44:57
-->
<template>
  <div>
    <el-dialog title="图片裁剪" width="900px" :visible="visible" :modal-append-to-body="false" :before-close="cancel">
      <!-- 裁切画布 start  -->
      <div class="v-crop">
        <div class="v-crop-top">
          <CropCanvas ref="cropRef" :image="image" :crop="crop" :max-size="maxSize" :natural="natural" :target="target" :src="src" @onImageLoaded="onImageLoaded" @onMouseWheel="onMouseWheel" />
          <!-- 裁切画布 end  裁切列表 start  -->
          <div class="v-crop-list">
            <ul>
              <li v-for="(url, index) in urls" :key="index" :class="{ 'v-crop-item': true, 'v-crop-item-croped': url === src }">
                <img :src="url" alt="img" />
                <div :class="{ 'v-crop-item-croped': true, 'v-crop-item-croped-active': !!fileList[index] }">
                  完成
                </div>
              </li>
            </ul>
            <el-button class="v-crop-done" type="primary" @click="cropFun">
              裁剪
            </el-button>
          </div>
        </div>
        <!-- /* 裁切列表 end */ -->

        <section class="v-crop-info">
          <div>
            <p class="v-crop-info-title mb-10">图片信息</p>
          </div>
          <p style="font-size: 14px" class="tip">图片尺寸: {{ `${natural.width}px * ${natural.height}px` }}</p>

          <div class="v-crop-info-size">
            <p class="v-crop-info-title mb-10">可选裁切尺寸</p>
            <div class="v-crop-controller">
              <span class="v-crop-example"></span>
              <div>
                <el-input-number v-if="!targetWidth" style="width: 100px" controls-position="right" :value="target.width" :min="rangeWidth ? rangeWidth[0] : undefined" :max="rangeWidth ? rangeWidth[1] : undefined" size="small" @change="(v) => onTargetChange(v, 'width')"> </el-input-number>
                <p v-else>{{ targetWidth }}</p>
              </div>
              *
              <div>
                <el-input-number
                  v-if="targetHeight === null"
                  :value="target.height"
                  style="width: 100px"
                  :min="rangeHeight ? rangeHeight[0] : undefined"
                  :max="rangeHeight ? rangeHeight[1] : undefined"
                  size="small"
                  controls-position="right"
                  @change="
                    (v) => {
                      onTargetChange(v, 'height')
                    }
                  "
                >
                </el-input-number>
                <p v-else>{{ targetHeight }}</p>
              </div>
            </div>
          </div>
        </section>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="onOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import CropCanvas from './canvas'
import { uploadMaterial, addImageMarterial } from '@/network/api/api-material'
import { omit } from '../../utils/ruoyi'
export default {
  name: 'CropperImage',
  components: {
    CropCanvas
  },
  props: {
    targetProps: {
      type: Object,
      default: () => {
        return {
          /** 裁切宽度 */
          width: 0,
          /** 裁切高度 */
          height: 300
        }
      }
    },
    maxSize: {
      type: Number,
      default: 300
    },
    urls: {
      type: Array,
      default: () => []
    },
    /** 裁切目标宽度, 如传入该值则宽度不再可更改 */
    // eslint-disable-next-line vue/require-default-prop
    targetWidth: {
      type: Number
    },
    /** 宽度范围取值 */
    rangeWidth: {
      type: Array,
      default: () => []
    },
    /** 裁切目标高度, 如传入该值则高度不再可更改 */
    // eslint-disable-next-line vue/require-default-prop
    targetHeight: {
      type: Number
    },
    visible: {
      type: Boolean,
      default: false
    },
    /** 高度范围, 元组表示范围 */
    rangeHeight: {
      type: Array,
      default: () => []
    },
    /** 图片信息 */
    imageInfo: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      /** 裁切区域状态 */
      crop: {
        /** 裁切区域宽 */
        width: this.maxSize,
        /** 裁切区域高 */
        height: this.maxSize
      },
      /** 裁切区域宽高比 */
      cropAspectRatio: 1,
      /** 图片地址 */
      src: this.urls.length ? this.urls[0] : '',
      target: this.targetProps,
      /** 图片状态 */
      image: {
        /** 图片宽 */
        width: 300,
        /** 图片高 */
        height: 300
      },
      /** 图片宽高比 */
      imageAspectRatio: 1,
      /** 图片原始属性 */
      natural: {
        /** 图片原始宽 */
        width: 0,
        /** 图片原始高 */
        height: 0
      },

      /** 裁切的图片文件 */
      fileList: [],

      /** 选择编辑的图片, 默认为0 */
      activeIndex: 0
    }
  },
  watch: {
    urls: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.src = this.urls[0] || ''
          this.activeIndex = 0
        }
      }
    },
    targetWidth: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.target = {
            width: this.targetWidth || 0,
            height: this.targetHeight || this.maxSize
          }
        }
      }
    },
    targetHeight: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.target = {
            width: this.targetWidth || 0,
            height: this.targetHeight || this.maxSize
          }
        }
      }
    }
  },
  methods: {
    /**
     * 生成矩形
     * @param width 矩形宽
     * @param height 矩形高
     * @param maxSize 矩形最大尺寸
     */
    generateRect(width, height, maxSize = 300) {
      let resultWidth = 0
      let resultHeight = 0

      // 宽高比
      const aspectRatio = width / (height || this.natural.height / 2)

      if (aspectRatio > 1) {
        resultWidth = maxSize
        resultHeight = resultWidth / aspectRatio
      } else if (aspectRatio === 1) {
        resultWidth = maxSize
        resultHeight = maxSize
      } else {
        resultHeight = maxSize
        resultWidth = resultHeight * aspectRatio
      }

      return [resultWidth, resultHeight, aspectRatio]
    },

    /**
     * 图片加载完成
     * 初始化裁切宽高, 图片在画布中的宽高, 图片宽高比, 图片最大偏移量
     */
    onImageLoaded(e) {
      const { naturalWidth, naturalHeight } = e.target
      const { targetWidth, targetHeight, target } = this

      // 获取裁切框默认宽高
      const natural = {
        width: naturalWidth,
        height: naturalHeight
      }

      let imageAspectRatio
      let cropAspectRatio
      let cropWidth
      let cropHeight
      let imageWidth
      let imageHeight

      if (targetWidth !== null && targetHeight !== null) {
        // 宽高都已经指定无法更改
        const generateRectValue = this.generateRect(targetWidth, targetHeight)
        cropWidth = generateRectValue[0]
        cropHeight = generateRectValue[1]
        cropAspectRatio = generateRectValue[2]
        imageAspectRatio = naturalWidth / naturalHeight

        if (imageAspectRatio > cropAspectRatio) {
          imageHeight = cropHeight
          imageWidth = imageAspectRatio * imageHeight
        } else {
          imageWidth = cropWidth
          imageHeight = imageWidth / imageAspectRatio
        }
      } else {
        // 宽高至少有一项可以更改
        const generateRectValue = this.generateRect(naturalWidth, naturalHeight)
        cropWidth = generateRectValue[0]
        cropHeight = generateRectValue[1]
        cropAspectRatio = generateRectValue[2]
        imageAspectRatio = cropAspectRatio

        imageWidth = cropWidth
        imageHeight = cropHeight
        if (target.width) {
          this.target.height = Math.floor(target.width / cropAspectRatio)
        } else if (target.height) {
          this.target.width = Math.floor(target.height * cropAspectRatio)
        } else {
          this.target.width = naturalWidth
          this.target.height = naturalHeight
        }
      }

      // 图片加载时 图片宽高比和裁切宽高比一致
      this.cropAspectRatio = cropAspectRatio

      this.imageAspectRatio = imageAspectRatio

      this.imageAspectRatio = imageAspectRatio
      this.natural = natural
      this.crop = {
        width: cropWidth,
        height: cropHeight === 0 ? 200 : cropHeight
      }

      this.image = {
        width: imageWidth,
        height: imageHeight
      }
    },

    /**
     * 鼠标滚动事件用来做图片的缩放, 以及图片的最大偏移量
     * 如果
     */
    onMouseWheel(e) {
      const { imageAspectRatio, image, crop, cropAspectRatio } = this
      const disX = e.altKey ? -e.deltaY : e.deltaY > 0 ? -10 : 10

      const disY = disX / imageAspectRatio
      let { width, height } = image

      width += disX
      height += disY

      // 最小缩放
      if (width < crop.width || height < crop.height) {
        if (imageAspectRatio > cropAspectRatio) {
          height = crop.height
          width = height * imageAspectRatio
        } else if (imageAspectRatio < cropAspectRatio) {
          width = crop.width
          height = width / imageAspectRatio
        } else {
          width = crop.width
          height = crop.height
        }
      }

      this.image = {
        width,
        height
      }
    },

    /**
     * 裁切目标值发生改变
     * 裁切区域的宽高会随之改变
     *
     */
    onTargetChange(value, type) {
      const { rangeHeight, rangeWidth } = this
      const { imageAspectRatio } = this

      // 裁切范围
      const range = type === 'width' ? rangeWidth : rangeHeight
      if (range) {
        if (value < range[0]) {
          value = range[0]
        }
        if (value > range[1]) {
          value = range[1]
        }
      }
      this.target.height = value

      // 获取裁切区域宽高以及宽高比
      const [cropWidth, cropHeight, aspectRatio] = this.generateRect(this.target.width, this.target.height)

      // 1: 原始图片宽高比 < 裁切宽高比 图片宽 = 裁切宽, 图片高 = 图片宽 / naturalAspectRatio
      // 2: 原始图片宽高比 > 裁切宽高比 图片高 = 裁切宽, 图片宽 = 图片高 * naturalAspectRatio
      let image = {
        width: 0,
        height: 0
      }
      if (imageAspectRatio < aspectRatio) {
        image.width = cropWidth
        image.height = image.width / imageAspectRatio
      } else {
        image.height = cropHeight
        image.width = image.height * imageAspectRatio
      }
      this.crop = {
        width: cropWidth,
        height: cropHeight === 0 ? 200 : cropHeight
      }
      this.image = image
      this.cropAspectRatio = aspectRatio
    },

    async cropFun() {
      const { fileList, activeIndex, target } = this
      this.onTargetChange(target.height === 0 ? 750 : target.height, 'height')
      const { urls } = this
      const file = await this.$refs.cropRef.cropImage()
      fileList[activeIndex] = file
      this.src = urls[activeIndex + 1 >= urls.length ? urls.length - 1 : activeIndex + 1]
      this.fileList = [...fileList]
      this.activeIndex = activeIndex + 1 < urls.length ? activeIndex + 1 : activeIndex
    },
    /**
     * 上传裁切的图片
     */
    async onOk() {
      const { fileList, natural, urls, rangeHeight, targetWidth, imageType } = this

      if (fileList.length === 0 && imageType === 'img') {
        let rangeHeight1 = rangeHeight ? rangeHeight[0] : 0
        let rangeHeight2 = rangeHeight ? rangeHeight[1] : 0
        if (natural.width === targetWidth && rangeHeight1 < natural.width && rangeHeight2 > natural.width) {
          return this.props.hideVisible()
        }
      }
      if (fileList.length !== urls.length) {
        return this.$message.error('请完成所有的裁切')
      }
      const form = new FormData()
      // 文件对象
      form.append('file', fileList[0])
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      // form.append('name', '1')
      const { code, data } = await uploadMaterial(form)
      if (code !== 200) {
        this.$message.success('裁剪失败，请重新裁剪！')
      } else {
        const { adminId, albumId, creatorId, creatorName, keyword, nickname, materialName, materialGroupId } = this.imageInfo[0]
        let dataInfo = {
          albumId,
          adminId,
          materialGroupId,
          creatorId,
          creatorName,
          keyword,
          nickname,
          materialName,
          hash: data.hashCode,
          ...omit(data, ['originName', 'hashCode'])
        }
        const res = await addImageMarterial(dataInfo)
        if (res.code === 200) {
          this.$emit('onOk', data)
          this.$message.success(`裁剪完成, 素材上传至资源库`)
          this.cancel()
        }
      }
      this.fileList = []
      this.activeIndex = 0
    },

    /**
     * @description: 关闭弹窗
     */
    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped lang="scss">
p {
  margin: 0;
  padding: 0;
}
// 画布宽高
$s: 400px;
// list和info 的左边距
$ml: 12px;
// list 宽
$lw: 100px;

.v-crop {
  display: flex;
  justify-content: space-between;
  .v-crop-top {
    display: flex;
  }

  .v-crop-canvas {
    margin-top: 50%;
    transform: translateY(-50%);
    max-height: 100%;
  }

  .v-crop-list {
    display: flex;
    width: 140px;
    flex-direction: column;
    align-items: center;
    ul {
      margin: 0;
      padding: 0;
    }
  }

  .v-crop-item {
    width: 100px;
    height: 100px;
    padding: 4px;
    line-height: 92px;
    border: 1px solid #ddd;
    cursor: pointer;
    border-radius: 4px;
    font-size: 0;
    text-align: center;
    position: relative;
    margin-left: 8px;

    .v-crop-item-selected {
      border-color: #4c88f6;
    }

    .v-crop-item-croped {
      display: none;
    }
    .v-crop-item-croped-active {
      display: block;
      color: #fff;
      text-align: center;
      line-height: 92px;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      font-size: 20px;
      background-color: rgba($color: #000000, $alpha: 0.6);
    }

    img {
      width: 260px;
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle;
    }
  }

  .v-crop-info {
    font-size: 16px;
    & > div {
      display: flex;
      align-items: center;
      padding-right: 20px;
    }
    .v-crop-info-size {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 30px;
    }
    .v-crop-info-title {
      margin-right: 20px;
    }
  }

  .v-crop-controller {
    display: flex;
    width: 250px;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: pointer;
    border: 1px solid #ebedf0;
    line-height: 50px;
    height: 50px;
    padding: 0 16px;
    border-radius: 2px;
    text-align: right;
    position: relative;
    background-color: #f4f5f7;
    user-select: none;
    color: #333 !important;
  }

  .v-crop-info-example {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 28px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 2px;

    &::before,
    &::after {
      box-sizing: border-box;
      content: '';
      position: absolute;
    }

    &::before {
      height: 36px;
      width: 100%;
      left: 0;
      top: -8px;
      background-color: #fafbfc;
      border: 2px solid #d3d4d6;
    }

    &::after {
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #eef0f4;
      border: 2px solid #9a9da2;
    }
  }

  .v-crop-done {
    margin-top: 20px;
    width: 110px;
  }
}
</style>
