<template>
  <div>
    <Search ref="search" />
    <el-row :gutter="24" class="operate-box mt-20">
      <el-col :span="2"> <el-button type="primary" icon="el-icon-share" round @click="showAdd">批量分享</el-button></el-col>
      <el-col :span="2"> <el-button type="primary" icon="el-icon-remove" round @click="showAdd">批量取消</el-button></el-col>
    </el-row>
    <div class="table-container">
      <el-table v-loading="loading" stripe class="list-table" :data="data">
        <c-data-empty slot="empty" />

        <el-table-column type="index" label="序号" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="pageName" label="落地页名称" align="left" />
        <el-table-column prop="housekeeperName" label="账号管家" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="accountName" label="投放管家" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="accountName" label="投放管家" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="shareAccountName" label="分享账户" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="shareStatus" label="分享状态" align="left" :show-overflow-tooltip="true" />

        <el-table-column prop="sharePageId" label="落地页ID" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="sharePageName" label="落地页名称" align="left" :show-overflow-tooltip="true" />

        <el-table-column prop="createBy" label="分享人" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="createTime" label="分享时间" align="left" :show-overflow-tooltip="true" />
        <el-table-column label="操作" width="100">
          <template slot-scope="{ row }">
            <el-button type="text" size="small" @click="showEdit(row)">分享</el-button>
            <el-button type="text" size="small" @click="deleteRow(row)">取消</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <c-pagination :page-size.sync="pageInfo.pageSize" :page-no.sync="pageInfo.pageNum" :total="pageInfo.total" :get-data-fun="loadData" />
    <OptionDialog ref="dialog" />
  </div>
</template>

<script>
import { PageCommonComponent } from '@/common/mixin'
import { Page, Delete } from '@/network/api/assetManagement/assetManagementBaiduWoodenFishLog'
import Search from './Search'
import OptionDialog from './OptionDialog'
import { AssetManagementBaiduWoodenFishLogEnt } from './object'
export default {
  name: 'AssetManagementBaiduWoodenFishLog',
  components: { Search, OptionDialog },
  mixins: [PageCommonComponent],
  provide: function() {
    return {
      shareStatus: () => this.shareStatus,
      loadData: this.loadData,
      showEdit: this.showEdit
    }
  },
  data() {
    return {
      loading: false,
      data: [],
      shareStatus: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  mounted() {
    this.loadData()
    this.getDicts('baidu_wooden_fish_log_share_status').then((response) => {
      this.shareStatus = response.data
    })
  },
  methods: {
    loadData(isFirst = false) {
      const searchForm = this.$refs.search.getSearchForm()
      this.doLoadData(isFirst, searchForm)
    },
    doLoadData(firstPage = false, searchForm) {
      firstPage && (this.pageInfo.pageNum = 1)
      const req = { ...searchForm, ...this.pageInfo }
      this.loading = true
      Page(req).then((res) => {
        this.data = res.data.records
        this.pageInfo.total = res.data.total
        this.loading = false
      })
    },
    deleteRow(row) {
      this.$confirm('确定删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          Delete(row.id).then((res) => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.loadData()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showEdit(row) {
      this.$refs.dialog.show(true, { ...row })
    },
    showAdd() {
      this.$refs.dialog.show(false, new AssetManagementBaiduWoodenFishLogEnt())
    }
  }
}
</script>

<style scoped></style>
