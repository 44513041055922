<!--
 * @Description: 工作区 -> 顶部图片组件
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2021-12-28 17:25:41
-->

<template>
  <section class="top-video" :style="{ paddingTop: `${detail.paddingTop}px`, paddingBottom: `${detail.paddingBottom}px` }">
    <div v-if="detail.sightVideoUrl" style="width: auto; height: 260px">
      <video :src="detail.sightVideoUrl" alt="" style="height:100%" />
    </div>
    <div v-else style="text-align: center">
      <i class="el-icon-video-camera main-color"></i>
      <p class="main-color">顶部视频组件</p>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.top-video {
  width: 100%;
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(222, 237, 247);
  display: flex;
  flex-direction: column;
  i {
    font-size: 40px;
    color: #ccc;
  }
  p {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 600;
    color: #ccc;
  }
}
</style>
