import service from '@/network/service/service'

export function Page(data) {
  return service.post('/api/system/basicSettingProject/page', data)
}

export function getRelationProject() {
  return service.get('/api/system/basicSettingProject/getRelationProject')
}

export function getBatchMeRelationProject() {
  return service.get('/api/system/basicSettingProject/getBatchMeRelationProject')
}

export function getMeRelationProject() {
  return service.get('/api/system/basicSettingProject/getMeRelationProject')
}
export function getProjectForSource() {
  return service.get('/api/system/basicSettingProject/getProjectForSource')
}
export function getProjectForProduct() {
  return service.get('/api/system/basicSettingProject/getProjectForProduct')
}

export function Add(data) {
  return service.post('/api/system/basicSettingProject/save', data)
}

export function Update(data) {
  return service.post('/api/system/basicSettingProject/update', data)
}

export function Delete(id) {
  return service.delete('/api/system/basicSettingProject/' + id)
}
