<template>
  <!--新增产品弹框-->
  <el-dialog
    class="add-product-dialog"
    title="新增产品"
    :visible.sync="show"
    :append-to-body="true"
    width="600px"
    top="20vh"
    @close="onCloseDialog"
  >
    <div class="page-container">
      <el-form
        ref="form"
        :inline="false"
        label-width="100px"
        :model="form"
        :rules="rules"
      >
        <el-form-item
          label="产品名称"
          prop="toolProductName"
        >
          <el-input
            v-model="form.toolProductName"
            placeholder="请输入产品名称"
            maxlength="30"
            show-word-limit
            style="width: 100%"
          >
          </el-input>
        </el-form-item>

        <el-form-item
          label="来源"
          prop="toolProductSource"
        >
          <el-input
            v-model="form.toolProductSource"
            placeholder="请输入来源"
            maxlength="15"
            show-word-limit
            style="width: 100%"
          >
          </el-input>
        </el-form-item>

        <el-form-item
          label="平台"
          prop="toolProductPlatform"
        >
          <el-select
            v-model="form.toolProductPlatform"
            style="width: 100%"
            placeholder="请选择平台"
          >
            <el-option
              label="全部"
              value=""
            ></el-option>
            <el-option
              v-for="item in platformList"
              :key="item.dictValue"
              :label="item.dictLabel"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="类型"
          prop="type"
        >
          <el-select
            v-model="form.type"
            style="width: 100%"
            placeholder="请选择类型"
          >
            <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="连载状态"
          prop="serializeStatus"
        >
          <el-select
            v-model="form.serializeStatus"
            style="width: 100%"
            placeholder="请选择连载状态"
          >
            <el-option
              v-for="item in statusList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="数量"
          prop="num"
        >
          <el-input
            v-model="form.num"
            placeholder="请输入数量"
            style="width: 100%"
            @input="(e) => (form.num= _integerZeroFn(e))"
          >
          </el-input>
        </el-form-item>

        <el-form-item
          label="主角名"
          prop="lead"
        >
          <el-input
            v-model="form.lead"
            placeholder="请输入主角名"
            maxlength="20"
            show-word-limit
            :step="1"
            step-strictly
            style="width: 100%"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <!--底部按钮-->
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        class="btn"
        @click="closeModal"
      >取消</el-button>
      <el-button
        class="btn"
        type="primary"
        :disabled="loading"
        @click="handleConfirm"
      >确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addOrEditProduct, productDetail } from '@/network/api/toolManagement/api-product-test'

export default {
  name: 'AddProductDialog',
  data() {
    return {
      show: false,
      loading: false,
      form: {
        toolProductName: '',
        toolProductSource: '',
        toolProductPlatform: '',
        type: 2,
        serializeStatus: 2,
        num: 0,
        lead: ''
      },

      id: '',

      platformList: [],
      typeList: [{ id: 2, name: '男频' }, { id: 3, name: '女频' }, { id: 4, name: '男女通用' }],
      statusList: [{ id: 1, name: '连载' }, { id: 2, name: '完结' }],

      rules: {
        toolProductName: [{ required: true, message: '请输入产品名称', trigger: 'blur' }],
        toolProductSource: [{ required: true, message: '请输入来源', trigger: 'blur' }],
        toolProductPlatform: [{ required: true, message: '请选择平台', trigger: 'change' }],
        type: [{ required: true, message: '请选择类型', trigger: 'change' }],
        serializeStatus: [{ required: true, message: '请选择连载状态', trigger: 'change' }],
        num: [{ required: true, message: '请输入数量', trigger: 'blur' }]
      }
    }
  },
  methods: {
    /* --------------------------- Private --------------------------- */
    // 获取产品详情
    _getProductDetail () {
      productDetail(this.id).then(res => {
        this.form = { ...res.data }
        this.form.type = +this.form.type
        this.form.serializeStatus = +this.form.serializeStatus
      })
    },

    // 只能输入0和正整数
    _integerZeroFn (value) {
      let reg = /^(0|\+?[1-9][0-9]*)$/
      let strArray = value.split('')
      let newStrs = ''
      for (let i = 0; i < strArray.length; i++) {
        if (reg.test(strArray[i])) {
          console.log(strArray[i])
          newStrs += strArray[i]
        } else if (i > 0 && strArray[i] === '0') {
          newStrs += strArray[i]
        }
      }
      if (!(newStrs - 0 < 0)) {
        return newStrs - 0
      } else {
        return ''
      }
    },

    /* --------------------------- Actions --------------------------- */
    showModal(id) {
      this.getDicts('return_platform_type').then((response) => {
        this.platformList = response.data
        if (id) {
          this.id = id
          this._getProductDetail()
        }
        this.show = true
      })
    },

    // 点击确定按钮
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return
        }

        let { toolProductName, toolProductSource, toolProductPlatform, type, serializeStatus, num, lead } = this.form
        let params = {
          toolProductName,
          toolProductSource,
          toolProductPlatform,
          type,
          serializeStatus,
          num,
          lead
        }

        if (this.id) params.toolProductId = this.id
        this.loading = true
        addOrEditProduct(params).then(({ code }) => {
          if (code === 200) {
            this.$SuccessMessage(`${this.id ? '编辑成功' : '新增成功'}`)
            this.$emit('confirm')
            this.closeModal()
          }
        }).finally(() => {
          this.loading = false
        })
      })
    },

    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      // 重置表单
      this.form = {
        toolProductName: '',
        toolProductSource: '',
        toolProductPlatform: '',
        type: 2,
        serializeStatus: 2,
        num: 0,
        lead: ''
      }

      this.id = ''
      setTimeout(() => {
        this.$refs.form.clearValidate()
      }, 100)
    },

    // 关闭弹框
    closeModal() {
      this.show = false
    }
  }
}
</script>

<style scoped lang="scss">
</style>
