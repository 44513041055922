/*
 * @Description: Do not edit
 * @Date: 2021-10-21 13:48:24
 * @LastEditTime: 2021-10-21 13:56:53
 */
import service from '@/network/service/service'

export function getAdAlbum(data) {
  return service.post('/api/file/adAlbum/select', data)
}

export function getAdMaterialGroup(data) {
  return service.post('/api/file/adMaterialGroup/select', data)
}
