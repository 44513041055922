<!--
 * @Description: 计划/ 广告 展示 tree
 * @Date: 2021-12-06 15:57:16
 * @LastEditTime: 2022-03-08 17:28:52
-->
<template>
  <div style="height: 300px">
    <div v-if="ldyData.flag">
      <div class="crowd-pack-selected2">
        <p>落地页模版</p>
        <ul>
          <li v-for="item in showLdyDataMap['mp']" :key="item.taskPageId">
            <el-tooltip :content="item.pageName" placement="top">
              <span>{{ item.pageName }}</span>
            </el-tooltip>
            <span class="handleTree-icon">
              <i class="el-icon-circle-close" @click="deleteSelectInterest(item)"></i>
            </span>
          </li>
        </ul>
        <p>线上落地页</p>
        <el-tree :data="showLdyDataMap['adq']" node-key="id" default-expand-all :expand-on-click-node="false">
          <span slot-scope="row" class="custom-tree-node">
            <el-tooltip :content="row.node.label + '(' + row.node.key + ')'" placement="top">
              <span class="label">{{ row.node.label }}</span>
            </el-tooltip>
            <span v-if="row.node.level !== 1" class="handleTree-icon">
              <i class="el-icon-circle-close" @click="() => remove(row.node, row.data)"></i>
            </span>
          </span>
        </el-tree>
        <!-- <ul>
          <li v-for="item in showLdyDataMap['adq']" :key="item.taskPageId">
            <el-tooltip :content="item.pageName" placement="top">
              <span>{{ item.pageName }}</span>
            </el-tooltip>
            <i class="el-icon-circle-close" @click="deleteSelectInterest(item)"></i>
          </li>
        </ul> -->
      </div>
    </div>
    <div v-else class="kong">
      {{ '请选落地页' }}
    </div>
    <div>
      <el-dialog title="落地页" :visible="visible" width="80%" :modal-append-to-body="false" @open="open" @close="close">
        <el-row :gutter="10">
          <el-col :span="18">
            <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
              <el-tab-pane label="落地页模版" name="mp">
                <ldy-mp ref="mp" :form-data="formData" :task-id="taskId" @setLdyData="setLdyData" @deleteSelectInterest="deleteSelectInterest" />
              </el-tab-pane>
              <el-tab-pane label="线上落地页" name="adq">
                <ldy-adq ref="adq" :form-data="formData" :task-id="taskId" :account-data="accountData" :task-site-set="taskSiteSet" :creativity-data="creativityData" @setLdyData="setLdyData" @deleteSelectInterest="deleteSelectInterest" />
              </el-tab-pane>
            </el-tabs>
          </el-col>
          <el-col :span="6">
            <ul class="crowd-pack-selected">
              <p class="tip">落地页模版</p>
              <li v-for="item in ldyDataMap['mp']" :key="item.id">
                <span>{{ item.pageName }}</span>
              </li>
            </ul>
            <ul class="crowd-pack-selected">
              <p class="tip">线上落地页</p>
              <li v-for="item in ldyDataMap['adq']" :key="item.id">
                <span>{{ item.pageName }}</span>
              </li>
            </ul>
          </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
          <el-button @click="close">取消</el-button>
          <el-button type="primary" :disabled="!ldyData || !ldyData.list.filter((f) => f.taskPageId === null).length" @click="onSubmit">确定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { savePage, deletePage } from '@/network/api/advManagement/advManagementTencent'
import LdyMp from './ldyMp.vue'
import LdyAdq from './ldyAdq.vue'

export default {
  components: {
    LdyMp,
    LdyAdq
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    formData: {
      type: Object,
      default: () => {}
    },
    taskId: {
      type: Number,
      default: 0
    },
    accountData: {
      type: Array,
      default: () => []
    },
    taskSiteSet: {
      type: Number,
      default: 0
    },
    creativityData: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      activeName: 'mp',
      ldyData: {},
      ldyDataMap: {},
      timeout: null,
      showLdyDataMap: {}
    }
  },

  watch: {
    formData: {
      handler(value) {
        this.showLdyDataMap['mp'] = []
        this.showLdyDataMap['adq'] = []
        if (value && value.list) {
          this.ldyData = {
            ...value,
            list: value.list.map((item) => {
              return {
                ...item,
                id: item.onlinePageId || item.localPageId,
                pageName: item.onlinePageName || item.localPageName,
                taskAccountId: item.taskAccountId
              }
            })
          }
          const ldyMps = value.list.filter((f) => f.localPageId && f.localPageName)
          if (ldyMps) {
            this.showLdyDataMap['mp'] = ldyMps.map((item) => {
              return {
                ...item,
                id: item.localPageId,
                pageName: item.localPageName,
                taskAccountId: item.taskAccountId
              }
            })
            this.ldyDataMap['mp'] = this.showLdyDataMap['mp']
          }
          const ldyAdqs = value.list.filter((f) => f.onlinePageId && f.onlinePageName)
          if (ldyAdqs) {
            this.ldyDataMap['adq'] = ldyAdqs.map((item) => {
              return {
                ...item,
                id: item.onlinePageId,
                pageName: item.onlinePageName,
                taskAccountId: item.taskAccountId
              }
            })
          }
          this.accountData.forEach((account) => {
            let children = []
            const adqs = ldyAdqs.filter((f) => f.taskAccountId === account._id)
            adqs.forEach((adq) => {
              if (adq && adq.onlinePageName) {
                children.push({
                  id: adq?.onlinePageId,
                  label: adq?.onlinePageName,
                  taskPageId: adq?.taskPageId
                })
              }
            })
            this.showLdyDataMap['adq'].push({ id: account.taaccountId, label: account._name, children })
          })
        } else {
          this.ldyData = value
        }
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    open() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$refs[this.activeName].getPage()
      }, 200)
    },

    handleClick() {
      this.$refs[this.activeName].getPage()
    },

    remove(node, data) {
      const parent = node.parent
      const children = parent.data.children || parent.data
      const index = children.findIndex((d) => d.id === data.id)
      this.deleteSelectInterest(data)
      children.splice(index, 1)
    },

    /**
     * @description 删除选中的人群包
     */
    async deleteSelectInterest(item) {
      clearTimeout(this.timeout)
      if (item.taskPageId) {
        this.$emit('clearTaskGenerate')
        const { code } = await deletePage(item.taskPageId)
        if (code === 200) {
          this.ldyData.list = this.ldyData.list.filter((f) => f.id !== item.id)
          this.$emit('byTaskIdGetAllFun')
          // this.$refs[this.activeName].setSelectData()
          return this.$message.success('删除成功!')
        }
      } else {
        let list = this.ldyData.list.filter((f) => f.id !== item.id)
        this.ldyData.list = list
        // this.$refs[this.activeName].setSelectData()
      }
    },

    async onSubmit() {
      let createParams = []
      Object.keys(this.ldyDataMap).forEach((key) => {
        this.ldyDataMap[key].forEach((item) => {
          if (!item.taskPageId) {
            if (key === 'adq') {
              createParams.push({
                onlinePageId: item.id,
                onlinePageName: item.pageName,
                taskAccountId: item.taskAccountId,
                onlineVideoId: item?.onlineVideoId,
                onlineImageIdList: item?.onlineImageIdList,
                pageType: 'ONLINE'
              })
            } else {
              createParams.push({
                localPageId: item.id,
                localPageName: item.pageName,
                pageType: 'TEMP'
              })
            }
          }
        })
      })

      if (createParams.length) {
        this.$emit('clearTaskGenerate')
        const { code } = await savePage({ lists: createParams, taskId: this.taskId })
        if (code === 200) {
          this.$emit('byTaskIdGetAllFun')
          this.$emit('closeLdy')
        }
      }
      this.$message.success('落地页保存成功!')
    },

    setLdyData(data) {
      if (!this.ldyDataMap[this.activeName]) {
        this.ldyDataMap[this.activeName] = []
      }
      this.ldyDataMap[this.activeName] = data.list
      const list = []
      Object.keys(this.ldyDataMap).forEach((key) => {
        list.push(...this.ldyDataMap[key])
      })
      this.ldyData.list = list
    },

    close() {
      this.$emit('byTaskIdGetAllFun')
      this.$emit('closeLdy')
    }
  }
}
</script>

<style lang="scss">
.handleTree-icon :hover {
  color: #409eff;
}

.ldy {
  .custom-tree-node {
    // flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    width: 100px !important;
    .label {
      width: 300px !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.crowd-pack-selected2 {
  display: flex;
  flex-direction: column;
  height: 296px;
  padding: 0 10px 0 0;
  overflow-y: auto;
  p {
    font-size: 14px;
    font-weight: 500;
    margin: 10px 0px 4px 0px;
  }
  li {
    width: 100%;
    display: flex;
    padding: 4px 4px;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    span {
      text-align: right;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-right: 10px;
      font-size: 13px;
    }
    i {
      cursor: pointer;
      color: #888888;
      font-size: 13px;
    }
  }
}

.crowd-pack-selected {
  display: flex;
  flex-direction: column;
  max-height: 486px;
  overflow-y: auto;
  li {
    width: 100%;
    display: flex;
    padding: 4px 0;
    align-items: center;
    font-size: 14px;
    span {
      width: 80%;
      text-align: right;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      flex: 1;
      margin-right: 10px;
    }
    i {
      width: 20px;
      cursor: pointer;
      font-weight: 600;
    }
  }
  .tip {
    font-size: 13px;
  }
}
</style>
