<template>
  <div>
    <Search ref="search" />
    <el-row type="flex" class="operate-box">
      <el-button type="primary" icon="el-icon-plus" round @click="showAdd">新增账户</el-button>
      <!-- <el-button type="primary" icon="el-icon-refresh" round @click="getOauthFun">同步管家账户</el-button> -->
    </el-row>
    <div class="table-container">
      <el-table v-loading="loading" stripe class="list-table" :data="data">
        <c-data-empty slot="empty"></c-data-empty>
        <el-table-column type="index" label="序号" width="80" align="left" />
        <el-table-column prop="accountId" label="账户ID" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="accountName" label="账户名称" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="accountNumber" label="登录账号" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="userInfoId" label="用户Id" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="userInfoEmail" label="邮箱" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="userInfoDisplayName" label="用户名" align="left" :show-overflow-tooltip="true" />
        <el-table-column label="操作" width="100">
          <template slot-scope="{ row }">
            <el-button type="text" size="small" @click="showLaunchAdd(row)">
              <el-tooltip content="同步投放账户" placement="top">
                <i class="el-icon-upload2" style="font-size: 16px"></i>
              </el-tooltip>
            </el-button>
            <!-- <el-button type="text" size="small" @click="showEdit(row)">
              <el-tooltip content="编辑" placement="top">
                <i class="el-icon-edit" style="font-size: 16px"></i>
              </el-tooltip>
            </el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <c-pagination :page-size.sync="pageInfo.pageSize" :page-no.sync="pageInfo.pageNum" :total="pageInfo.total"
      :get-data-fun="loadData"> </c-pagination>
    <OptionDialog :visible="visible" :row-account-id="rowAccountId" @setVisible="(value) => (visible = value)" />
  </div>
</template>

<script>
import { PageCommonComponent } from '@/common/mixin'
import { Page, Add } from '@/network/api/huge/accountHugeHousekeeper'
import Search from './Search'
import OptionDialog from './OptionDialog'
var windowObjectReference = {
  closed: null
}
export default {
  name: 'AccountHugeHousekeeper',
  components: { Search, OptionDialog },
  mixins: [PageCommonComponent],
  provide: function () {
    return {
      loadData: this.loadData,
      showEdit: this.showEdit
    }
  },
  data() {
    return {
      loading: false,
      visible: false,
      rowAccountId: null,
      data: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  mounted() {
    this.loadData()
    let _this = this
    var loop = setInterval(function () {
      if (windowObjectReference.closed) {
        clearInterval(loop)
        _this.loadData()
      }
    }, 1000)
  },

  methods: {
    loadData(isFirst = false) {
      const searchForm = this.$refs.search.getSearchForm()
      this.doLoadData(isFirst, searchForm)
    },
    doLoadData(firstPage = false, searchForm) {
      firstPage && (this.pageInfo.pageNum = 1)
      const req = { ...searchForm, search: searchForm.accountName, ...this.pageInfo }
      delete req.accountName
      delete req.accountId
      this.loading = true
      Page(req).then((res) => {
        this.data = res.data.records
        this.pageInfo.total = res.data.total
        this.loading = false
      })
    },

    showAdd() {
      Add().then(({ code, msg }) => {
        if (code === 200) {
          windowObjectReference = window.open(msg, 'authorize', 'width=900,height=700,left=100,top=0,menubar=no,toolbar=no')
        }
      })
    },

    // 同步账号
    getOauthFun() {
      this.visible = true
    },

    showLaunchAdd(row) {
      this.rowAccountId = row.housekeeperId
      this.visible = true
    }
  }
}
</script>

<style scoped></style>
