<template>
  <!--显示列弹框-->
  <el-drawer title="创意基本信息" size="900px" :append-to-body="true" custom-class="dialog" :visible.sync="show"
    label-position="right" @close="onCloseDialog">
    <div class="drawer-content">
      <div class="title">创意信息</div>
      <el-form ref="form" :rules="rules" :model="form" label-width="130px">
        <el-form-item label="创意方式" prop="creativeType">
          <el-select v-model="form.creativeType" style="width: 300px;">
            <el-option label="程序化创意" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="基础创意组件" prop="sourceType">
          <el-select v-model="form.sourceType" style="width: 300px;">
            <el-option label="自主创建" :value="1"></el-option>
            <el-option label="选择已有" :value="2"></el-option>
          </el-select>
        </el-form-item>

        <!-- 自动创建 -->
        <el-form-item v-if="form.sourceType === 1" label="推广卡片">
          <div class="tuiguang">
            <el-form-item class="tuiguang-item" label="卡片主图" prop="imageUrl">
              <el-upload class="avatar-uploader" :action="UPLOAD_URL" :show-file-list="false"
                :before-upload="(file) => beforeAvatarUpload(file)" :on-success="(res) => handleAvatarSuccess(res)">
                <img v-if="form.imageUrl" :src="form.imageUrl" class="avatar"
                  style="border:1px solid #ccc; border-radius: 4px " />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div style="color: #999;font-size:12px">尺寸：108px*108px, 格式：JPG、JPEG、PNG格式, 大小：1M以内</div>
            </el-form-item>
            <el-form-item class="tuiguang-item" label="卡片标题" prop="title">
              <el-input v-model="form.title" style="width: 300px;" maxlength="20" show-word-limit />
            </el-form-item>
            <el-form-item class="tuiguang-item" label="推广卖点">
              <el-button type="primary" size="mini" plain :disabled="products.length >= 10" @click="handleAddPoint">添加
              </el-button> {{ products.length }}/10
              <div v-for="(t, i) in products" :key="i" class="tag-input">
                <el-input :key="i" v-model="products[i]" minlength="6" maxlength="9" style="width: 300px;"
                  show-word-limit placeholder="请输入推广卖点"></el-input>
                <i v-if="products.length > 1" class="el-icon-delete opt-icon" @click="handleDeletePoint(i)"></i>
              </div>
            </el-form-item>
            <el-form-item class="tuiguang-item" label="行动号召" prop="buttonText">
              <el-select v-model="form.buttonText" placeholder="请选择行动号召" style="width: 300px;">
                <el-option v-for="(item, index) in activeTextList" :key="index" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="tuiguang-item" label="智能优选" prop="enablePersonalAction">
              <el-switch v-model="form.enablePersonalAction" :active-value="1" :inactive-value="0"></el-switch>
            </el-form-item>
            <el-form-item class="tuiguang-item" label="组件名称" prop="componentName">
              <el-input v-model="form.componentName" style="width: 300px;" maxlength="20" show-word-limit />
            </el-form-item>
          </div>
        </el-form-item>

        <!-- 选择已有 -->
        <el-form-item v-if="form.sourceType === 2" label="配置组件">
          <el-button type="primary" plain @click="onClickCreativeComponent">配置</el-button>
          <span style="margin-left: 12px;">已选择{{ componentLength }}个创意组件</span>
        </el-form-item>
        <el-form-item label="来源" prop="source">
          <el-input v-model="form.source" style="width: 300px;" placeholder="请输入来源" maxlength="20" show-word-limit />
        </el-form-item>

        <div class="title">原生广告设置</div>
        <el-form-item label="原生广告">
          <el-select v-model="form.douyin" style="width: 300px;margin-right: 15px">
            <el-option label="不使用" :value="1"></el-option>
            <el-option label="使用" :value="2"></el-option>
          </el-select>
          <el-checkbox v-if="form.douyin == 2" v-model="form.isFeedAndFavSee" :true-label="1" :false-label="0"
            style="margin-bottom: 5px;">
            主页作品列表隐藏广告视频</el-checkbox>
        </el-form-item>
        <el-form-item label="广告评论">
          <el-select v-model="form.isCommentDisable" style="width: 300px;">
            <el-option label="关闭" :value="1"></el-option>
            <el-option label="启用" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="客户端视频下载">
          <el-select v-model="form.adDownloadStatus" style="width: 300px;">
            <el-option label="关闭" :value="1"></el-option>
            <el-option label="启用" :value="0"></el-option>
          </el-select>
        </el-form-item>

        <div class="title">创意分类和标签</div>
        <el-form-item label="创意分类">
          <el-cascader ref="cascader" v-model="form.thirdIndustryIds" :show-all-levels="false" :props="props" clearable
            :options="classify" placeholder="请选择分类" @change="handleChangeValue" style="width: 300px;"></el-cascader>
        </el-form-item>
        <el-form-item label="创意标签">
          <div class="tag">
            <el-input v-model="tagText" style="width: 300px;margin-right:15px" placeholder="空格分隔,最多二十个,每个标签不超过10个字符"
              @keyup.enter.native="handleAddTag">
            </el-input>
            <el-button type="primary" plain :disabled="tagText === ''" @click="handleAddTag">添加</el-button>
            <el-button type="text" @click="handleSelectHaveTag">已有标签</el-button>
          </div>
          <!-- 标签 -->
          <div class="mg-card-box">
            <div class="mg-card-header">
              <div class="mg-card-title">已添加标签</div>
              <div class="tag-clear">
                <el-button type="text" style="font-size:12px" @click="handleClearTag">清空</el-button>
              </div>
            </div>
            <div class="mg-card-body">
              <div v-for="(item, index) in tagList" :key="index" class="mg-tag">
                {{ item }}

                <span class="del el-icon-close" @click="handleDeleteTag(index)"></span>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <!--底部按钮-->
    <div class="dialog-footer">
      <div>
        <el-checkbox v-model="form.saveAsPreset">存为预设</el-checkbox>
      </div>
      <div>
        <el-button class="btn" @click="closeModal">取 消</el-button>
        <el-button class="btn" type="primary" :loading="isLoading" :disabled="isLoading" @click="handleConfirm">确
          定</el-button>
      </div>
    </div>

    <!-- 选择标签 -->
    <tag-list-dialog ref="tagListDialog" @confirm="handleTag"></tag-list-dialog>

    <!-- 创意组件弹窗 -->
    <creative-component-dialog ref="creativeComponentDialog" @confirm="handleComponent">
    </creative-component-dialog>
  </el-drawer>
</template>

<script>
import { getActionTextList, getIndustryList, saveConfigCreative, getCreativeDraft } from '@/network/api/api-huge-batch'
import CreativeComponentDialog from './CreativeComponentDialog'
import TagListDialog from './TagListDialog'

export default {
  name: 'CreativeInfoConfigDialog',
  components: {
    'tag-list-dialog': TagListDialog,
    'creative-component-dialog': CreativeComponentDialog
  },
  props: {
    baseId: {
      type: [Number, String],
      default: 0
    },
    housekeeperId: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      UPLOAD_URL: '/api/file/upload',
      props: {
        value: 'value',
        label: 'label',
        children: 'children'
      },
      show: false, // 是否显示弹框
      isLoading: false,
      products: [''], // 推广卖点
      tagList: [], // 标签列表
      classify: [], // 创意分类列表
      tagText: '', // 用户输入的标签
      activeTextList: [], // 行动号召列表
      componentLength: 0, // 创意组件个数
      form: {},
      rules: {
        creativeType: [{ required: true, message: '请选择创意方式', trigger: 'change' }],
        sourceType: [{ required: true, message: '请选择创意组件', trigger: 'change' }],
        imageUrl: [{ required: true, message: '请上传卡片主图', trigger: 'change' }],
        title: [{ required: true, message: '请输入卡片标题', trigger: 'blur' }],
        productSellingPoints: [{ required: true, message: '请输入推广卖点', trigger: 'blur' }],
        buttonText: [{ required: true, message: '请选择行动号召', trigger: 'change' }],
        enablePersonalAction: [{ required: true, message: '请选择智能优选', trigger: 'change' }],
        componentName: [{ required: true, message: '请输入组件名称', trigger: 'blur' }],
        source: [{ required: true, message: '请输入来源', trigger: 'blur' }]
      },
      advertisers: [] // 投放账户
    }
  },
  methods: {
    /* --------------------------- Actions --------------------------- */
    // 显示弹框
    showModal(data, advertisers) {
      this.advertisers = advertisers
      this.show = true
      this._getActionTextList()
      this._getIndustryList()
      if (data && data.id) {
        this.initData(data)
      } else {
        this.getPresetInfo(data)
        // this.form = { ...data }
      }
    },
    initData(data) {
      this.tagList = data.adKeywords ? data.adKeywords.split(',') : []
      this.products = data.productSellingPoints ? data.productSellingPoints.split(',') : ['']
      let thirdIndustryIds = []
      if (data.thirdIndustryIds) {
        let ids = []
        let array = data.thirdIndustryIds.split(',')
        for (let i = 0; i < array.length; i++) {
          ids.push(Number(array[i]))
        }
        thirdIndustryIds = ids
      }
      let componentIds = data.componentIds ? JSON.parse(data.componentIds) : []
      this.form = {
        id: data.id,
        creativeType: data.creativeType, // 创意方式
        sourceType: data.sourceType, // 创意组件
        imageId: data.imageId, // 卡片主图id
        imageUrl: data.imageUrl, // 卡片主图url
        title: data.title, // 卡片标题
        productSellingPoints: data.productSellingPoints, // 推广卖点
        buttonText: data.buttonText, // 行动号召
        enablePersonalAction: data.enablePersonalAction, // 智能优选
        componentName: data.componentName, // 组件名称
        componentIds: componentIds, // 已有组件
        source: data.source, // 来源
        douyin: data.douyin, // 原生广告
        isFeedAndFavSee: data.isFeedAndFavSee, // 主页作品列表隐藏广告视频
        isCommentDisable: data.isCommentDisable, // 广告评论
        adDownloadStatus: data.adDownloadStatus, // 客户端视频下载
        thirdIndustryIds: thirdIndustryIds, // 创意分类id
        thirdIndustryNames: data.thirdIndustryNames, // 创意分类名称
        tagId: data.tagId, // 选择已有标签的id
        adKeywords: data.adKeywords, // 标签
        saveAsPreset: data.saveAsPreset // 存为预设
      }
      this.componentLength = componentIds.length
    },
    // 关闭弹框
    closeModal() {
      this.show = false
    },
    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      // 重置表单
      this.products = [''] // 推广卖点
      this.tagList = [] // 标签列表
      this.classify = [] // 创意分类列表
      this.tagText = '' // 用户输入的标签
      this.componentLength = 0
      this.form = {}
      this.$refs['form'].clearValidate()
    },

    /* --------------------------- 数据接口加载 -------------------------- */
    // 获取行动号召数据
    _getActionTextList() {
      let id = this.advertisers[0].advertiserId
      getActionTextList(id).then((res) => {
        if (res.code === 200) {
          this.activeTextList = res.data
        }
      })
    },

    // 获取预存信息
    getPresetInfo(base) {
      getCreativeDraft().then(({ code, data }) => {
        if (code === 200 && Object.keys(data).length) {
          this.initData(data)
        } else {
          this.form = { ...base }
        }
      }).catch(() => {
        this.form = { ...base }
      })
    },

    // 获取创意分类数据  整合成级联需要的数据结构
    _getIndustryList() {
      getIndustryList(1).then((res) => {
        this.classify = res.data.map((item) => {
          item.children = []
          item.label = item.first_industry_name
          item.value = item.first_industry_id
          return item
        })
        getIndustryList(2).then((res) => {
          let twoOptions = res.data.map((item) => {
            item.children = []
            item.label = item.second_industry_name
            item.value = item.second_industry_id
            return item
          })
          this.classify.forEach((item) => {
            for (let i = 0; i < twoOptions.length; i++) {
              if (twoOptions[i].first_industry_id === item.first_industry_id) {
                item.children.push(twoOptions[i])
              }
            }
          })
          getIndustryList(3).then((res) => {
            let threeOptions = res.data.map((item) => {
              item.label = item.third_industry_name
              item.value = item.third_industry_id
              return item
            })
            this.classify.forEach((item) => {
              item.children.forEach((itm) => {
                for (let i = 0; i < threeOptions.length; i++) {
                  if (itm.second_industry_id === threeOptions[i].second_industry_id) {
                    itm.children.push(threeOptions[i])
                  }
                }
              })
            })
          })
        })
      })
    },
    /* --------------------------- 数据接口加载 -------------------------- */

    /* --------------------------- 按钮点击 -------------------------- */
    // 点击添加推广卖点
    handleAddPoint() {
      this.products.push('')
    },

    // 点击删除推广卖点
    handleDeletePoint(i) {
      this.products.splice(i, 1)
    },

    // 点击添加标签
    handleAddTag() {
      let tagArr = this.tagText.split(' ')
      if (tagArr.length > 20) {
        this.$message.warning('单次添加标签不可超过20个！')
        return
      }
      let flag = true
      for (let i = 0; i < tagArr.length; i++) {
        if (tagArr[i].length > 10) {
          flag = false
          break
        }
      }
      if (!flag) {
        this.$message.warning('每个标签不可超过10个字符！')
        return
      }

      tagArr.map(v => {
        if (v.length) {
          this.tagList.push(v)
        }
      })

      this.form.tagId = ''
      this.tagText = ''
    },

    // 点击清空标签
    handleClearTag() {
      this.tagList = []
    },

    // 点击删除标签
    handleDeleteTag(i) {
      this.tagList.splice(i, 1)
    },

    // 选择已有标签
    handleSelectHaveTag() {
      this.$refs.tagListDialog.showModal()
    },

    // 创意分类选择
    handleChangeValue(e) {
      if (e && e.length !== 0) {
        this.form.thirdIndustryNames = this.$refs.cascader.getCheckedNodes()[0].label
      } else {
        this.form.thirdIndustryNames = ''
      }
    },

    // 创意组件弹窗
    onClickCreativeComponent() {
      let data = {
        advertisers: this.advertisers,
        housekeeperId: this.housekeeperId,
        components: this.form.componentIds || ''
      }
      this.$refs.creativeComponentDialog.showModal(data)
    },

    // 点击确定按钮
    handleConfirm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this._validate()) {
            let tagList = this.tagList.length > 0 ? this.tagList.join(',') : ''
            let products = this.products.length > 0 ? this.products.join(',') : ''
            let params = {
              ...this.form,
              id: this.form.id || '',
              baseId: this.baseId,
              componentIds: JSON.stringify(this.form.componentIds),
              thirdIndustryIds: this.form.thirdIndustryIds.length > 2 ? this.form.thirdIndustryIds.join(',') : '',
              adKeywords: tagList,
              productSellingPoints: products
            }
            this.isLoading = true
            saveConfigCreative(params).then(res => {
              if (res.code === 200) {
                this.$emit('confirm', res.data)
                this.closeModal()
              }
              this.isLoading = false
            }).catch(e => {
              this.isLoading = false
            })
          }
        }
      })
    },
    /* --------------------------- 按钮点击 -------------------------- */

    /* --------------------------- 组件回调 -------------------------- */
    // 卡片主图-判断图片大小
    beforeAvatarUpload: async function (file, type) {
      const isType = ['image/jpg', 'image/jpeg', 'image/png'].includes(file.type)
      const isSize = file.size / 1024 / 1024 < 1024
      if (!isType) {
        this.$message.error(`上传图片只能是'image/jpg', 'image/jpeg', 'image/png'格式!`)
      }
      if (!isSize) {
        this.$message.error(`上传图片大小不能超过1M!`)
      }
      const reader = new FileReader()
      reader.readAsDataURL(file)
      // 让页面中的img标签的src指向读取的路径
      await new Promise((resolve, reject) => {
        reader.onload = () => {
          const img = new Image()
          img.src = reader.result
          img.onload = () => {
            if (img.width !== 108 || img.height !== 108) {
              this.$message.error(`上传图片尺寸仅支持 108px * 108px`)
              reject(false)
              return false
            } else {
              resolve(true)
            }
          }
        }
      })
      return isType && isSize
    },

    // 卡片主图上传回调
    handleAvatarSuccess(res) {
      const { data, code } = res
      if (code === 200) {
        this.form.imageUrl = data.url
      }
    },

    // 标签弹窗回调
    handleTag(row) {
      this.form.tagId = row[0].id
      let tags = row[0].tags.split(',')
      this.tagList = tags
    },

    // 创意组件回调
    handleComponent(row, len) {
      this.form.componentIds = row
      this.componentLength = len
    },
    /* --------------------------- 组件回调 -------------------------- */
    // 校验数据
    _validate() {
      if (this.form.sourceType === 1) {
        if (!this.form.imageUrl) {
          this.$WarningMessage('请选择卡片主图')
          return false
        }
        if (this.products.length > 0) {
          for (let i = 0; i < this.products.length; i++) {
            if (!this.products[i]) {
              this.$WarningMessage(`请填写第${i + 1}条推广卖点`)
              return false
            }
          }
        } else {
          this.$WarningMessage(`请填写推广卖点`)
          return false
        }
      }
      return true
    }
  }
}
</script>

<style scoped lang="scss">
.dialog {
  position: fixed;
  top: 64px;
  right: 0;
  bottom: 0;
  z-index: 2033;
  display: flex;
  flex-direction: column;
  background-color: #f0f2f5;
  box-shadow: -8px 0 8px rgb(0 0 0 / 20%);

  .drawer-content {
    height: calc(100vh - 212px);
    padding: 0 16px;
    margin: 16px;
    overflow: auto;
    background: #fff;
    border: 1px solid #e8eaec;
    border-radius: 3px;

    .title {
      margin-top: 41px;
      margin-bottom: 24px;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #333;
      font-weight: 700;
    }

    .douyin-tip {
      font-size: 12px;
      margin-left: 130px;
    }

    .tag {
      display: flex;
    }

    .tuiguang {
      width: 600px;
      border: 1px solid #ccc;

      .tuiguang-image {
        width: 140px;
        height: 140px;
        font-size: 25px;
        border: 1px dashed #ccc;
        display: flex;
        cursor: pointer;
        justify-content: center;
        align-items: center;
      }

      .tuiguang-item {
        padding: 20px 0;

        .tag-input {
          margin-top: 10px;
          display: flex;
          align-items: center;
          margin-bottom: 8px;

          .opt-icon {
            font-size: 14px;
            cursor: pointer;
            margin-left: 8px;
            color: red;
          }
        }
      }
    }

    .mg-card-box {
      border: solid 1px #dcdee2;
      box-sizing: border-box;
      width: 400px;
      margin-top: 20px;

      .mg-card-header {
        display: flex;
        justify-content: space-between;
        height: 32px;
        background-color: #f8f9fd;
        font-size: 12px;
        padding: 0 8px;
        box-sizing: border-box;
      }

      .mg-card-body {
        width: 400px;
        overflow: auto;
        min-height: 160px;
        padding: 10px 7px;
        border-top: 1px solid #dcdee2;
        box-sizing: border-box;
        // display: flex;
        // flex-flow: row wrap;

        .mg-tag {
          display: flex;
          justify-content: space-between;
          box-sizing: border-box;
          float: left;
          height: 24px;
          line-height: 24px;
          margin-right: 5px;
          padding-right: 8px;
          padding-left: 9px;
          margin-bottom: 8px;
          color: #515a6e;
          background: #f4f5fc;
          border-radius: 4px;

          .del {
            margin-left: 50px;
            font-size: 14px;
            cursor: pointer;
            position: relative;
            top: 5px;
          }
        }
      }
    }
  }

  .dialog-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
  }

  .avatar-uploader {
    width: 108px;
    height: 108px;
    text-align: center;
    border: solid 1px #dcdee2;
    line-height: 108px;

    .avatar-uploader-icon {
      font-size: 30px;
      font-weight: 400;
      color: #dcdee2;
    }
  }
}
</style>
