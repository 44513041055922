var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.seletedWidgetsIndex !== -1
    ? _c(
        "section",
        { staticClass: "ad-editors" },
        [
          _c(_vm.editors, {
            tag: "component",
            attrs: {
              detail: _vm.finalWidgets[_vm.seletedWidgetsIndex],
              platforms: _vm.platforms,
            },
          }),
        ],
        1
      )
    : _c(
        "section",
        { staticClass: "ad-editors" },
        [
          _c("el-empty", {
            attrs: { "image-size": 100, description: "未选中组件" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }