<template>
  <div>
    <el-dialog
      width="548px"
      title="上传文件"
      :visible.sync="show"
      append-to-body
      @close="handleCancel"
    >
      <el-upload
        ref="fileUploader"
        class="upload-demo"
        :limit="1"
        accept="xlsx"
        drag
        :before-upload="beforeUpload"
        action="fakeaction"
        :http-request="uploadFile"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
      <div slot="footer" class="import-dialog-footer">
        <el-button @click="handleDownloadTemplate">下载模板</el-button>
        <el-button @click="handleCancel">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import download from "downloadjs";
export default {
  name: 'UploadFileDialog',
  props: {
  },
  data () {
    return {
      show: false,
      isLoading: false
    }
  },
  watch: {},
  methods: {
    showModal () {
      this.show = true
    },
    /**
     * 上传校验
     */
    beforeUpload (file) {
      const isXls = file.name.indexOf('xlsx') !== -1
      // 这里常规检验，看项目需求而定
      if (!isXls) {
        this.$ErrorMessage('只能上传xlsx!')
        return false
      }
      return true
    },
    /**
     * 上传文件
     */
    uploadFile (params) {
      this.isLoading = true
      const file = params.file
      this.fileNme = file.name
      // 根据后台需求数据格式
      const form = new FormData()
      // 文件对象
      form.append('file', file)
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append('name', file.name)
      this.$emit('upload', form)
      this.handleCancel()
    },

    /**
     * 下载模版
     */
    handleDownloadTemplate () {
      // 进行txt 文件URL下载需要拼接'?response-content-type=application/octet-stream'
      window.open('https://cw-oss.mokamrp.com/ad/2022/11/10/3e31cd93-93bd-44db-9be6-50b148a6bba3.xlsx' + '?response-content-type=application/octet-stream')
    },
    /**
     * 取消
     */
    handleCancel () {
      this.$refs.fileUploader.clearFiles()
      this.show = false
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/style/mixin";
@import "~@/assets/style/variables";

.upload-demo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
