var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.ruleForm.adMarketingApiServiceId
          ? "编辑服务商账户"
          : "新增服务商账户",
        visible: _vm.show,
        width: "620px",
        "modal-append-to-body": false,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.closeModal,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _vm.ruleForm.adMarketingApiServiceId
            ? _c(
                "el-form-item",
                { attrs: { label: "账户ID", prop: "serviceAccountId" } },
                [_c("span", [_vm._v(_vm._s(_vm.ruleForm.serviceAccountId))])]
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "运营者微信", prop: "relationWx" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: { type: "text", placeholder: "请输入运营者微信" },
                model: {
                  value: _vm.ruleForm.relationWx,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "relationWx", $$v)
                  },
                  expression: "ruleForm.relationWx",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "账户名称", prop: "corporationName" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: { type: "text", placeholder: "请输入账户名称" },
                model: {
                  value: _vm.ruleForm.corporationName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "corporationName", $$v)
                  },
                  expression: "ruleForm.corporationName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "账户主体", prop: "mdmName" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: { type: "text", placeholder: "请输入账户主体" },
                model: {
                  value: _vm.ruleForm.mdmName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "mdmName", $$v)
                  },
                  expression: "ruleForm.mdmName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: {
                  type: "textarea",
                  maxlength: "30",
                  placeholder: "请输入备注",
                },
                model: {
                  value: _vm.ruleForm.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "remark", $$v)
                  },
                  expression: "ruleForm.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v("确定")]
          ),
          _c("el-button", { on: { click: _vm.closeModal } }, [_vm._v("取消")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }