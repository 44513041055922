var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "tempalte",
    },
    [
      _vm._l(_vm.previews, function (item, index) {
        return _c("div", { key: index, staticClass: "config-body" }, [
          _c("div", { staticClass: "config-header" }, [
            _c("span", [
              _vm._v(
                _vm._s(item.advertiserName) +
                  "（" +
                  _vm._s(item.advertiserId) +
                  "）"
              ),
            ]),
            _c("div", [
              _c("span", [_vm._v("广告组数量：" + _vm._s(item.groupSize))]),
              _c("span", { staticStyle: { "margin-left": "50px" } }, [
                _vm._v("广告计划数量：" + _vm._s(item.planSize)),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "config-body-content" },
            [
              _c(
                "div",
                { staticClass: "config-body-content-title-box" },
                [
                  _c(
                    "el-row",
                    {
                      staticClass: "config-body-content-title",
                      attrs: { gutter: 28 },
                    },
                    [
                      _c("el-col", { attrs: { span: 3 } }, [
                        _c("div", { staticClass: "group content-title" }, [
                          _vm._v("广告组"),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 12 } }, [
                        _c("div", { staticClass: "plan content-title" }, [
                          _vm._v("广告计划"),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 9 } }, [
                        _c("div", { staticClass: "creative content-title" }, [
                          _vm._v("广告创意"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._l(item.groupList, function (group, gindex) {
                return _c(
                  "el-row",
                  {
                    key: gindex,
                    staticClass: "config-body-content-middle",
                    attrs: { gutter: 28 },
                  },
                  [
                    _c("el-col", { attrs: { span: 3 } }, [
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "item-header" }, [
                          _c("div", { staticClass: "left" }, [
                            _c("span", [_vm._v("广告组信息")]),
                          ]),
                        ]),
                        _c("div", { staticClass: "item-body" }, [
                          _c("div", [
                            _vm._v("名称: "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.handleClickEdit(
                                      "groupName",
                                      index,
                                      gindex,
                                      0,
                                      group
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(_vm._s(group.groupName) + " "),
                                _c("i", {
                                  staticClass: "icon-edit el-icon-edit",
                                }),
                              ]
                            ),
                          ]),
                          _c("div", [
                            _vm._v("推广目的: "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  group.landingType === "LINK"
                                    ? "销售线索收集"
                                    : "无"
                                )
                              ),
                            ]),
                          ]),
                          _c("div", [
                            _vm._v("预算: "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  group.budgetMode == "BUDGET_MODE_INFINITE"
                                    ? "不限"
                                    : group.budget
                                )
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _c(
                      "el-col",
                      { attrs: { span: 21 } },
                      [
                        _c(
                          "el-table",
                          {
                            staticClass: "preview-plan-table",
                            staticStyle: { width: "100%" },
                            attrs: {
                              data: group.planList,
                              border: "",
                              size: "small",
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                prop: "planName",
                                label: "广告计划名称",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ row, $index }) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleClickEdit(
                                                  "planName",
                                                  index,
                                                  gindex,
                                                  $index,
                                                  row
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(_vm._s(row.planName) + " "),
                                            _c("i", {
                                              staticClass:
                                                "icon-edit el-icon-edit",
                                            }),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "date",
                                label: "转化目标",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "table-item" },
                                          [
                                            _vm._v("营销链路："),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  row.marketingPurpose ===
                                                    "CONVERSION"
                                                    ? "行动转化"
                                                    : "无"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "table-item" },
                                          [
                                            _vm._v("转化追踪方式："),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  row.convertSourceType === 1
                                                    ? "线索API"
                                                    : "无"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "table-item" },
                                          [
                                            _vm._v("转化目标："),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("filterConvertType")(
                                                    row.convertType
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "table-item" },
                                          [
                                            _vm._v("转化追踪："),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  row.convertName ||
                                                    row.assetName ||
                                                    ""
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "date",
                                label: "预算与出价",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "table-item" },
                                          [
                                            _vm._v("目标转化出价："),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(row.cpaBid || 0) + "元"
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "table-item" },
                                          [
                                            _vm._v("预算："),
                                            _c("span", [
                                              _vm._v(_vm._s(row.budget) + "元"),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "audiencePackageName",
                                label: "用户定向包",
                                align: "center",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "inventoryCatalog",
                                label: "投放位置",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("filterInventoryCatalog")(
                                                row.inventoryCatalog
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "date",
                                label: "创意分类标签",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        _c(
                                          "div",
                                          { staticClass: "table-item" },
                                          [
                                            _vm._v("创意分类："),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  row.thirdIndustryNames || "无"
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "table-item" },
                                          [
                                            _vm._v("创意标签："),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(row.adKeywords || "无")
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "",
                                label: "创意素材",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return _vm._l(
                                        JSON.parse(row.mediaUrls),
                                        function (m, mi) {
                                          return _c(
                                            "div",
                                            { key: mi },
                                            [
                                              _c("el-image", {
                                                staticStyle: {
                                                  width: "88px",
                                                  height: "50px",
                                                },
                                                attrs: { src: m, fit: "cover" },
                                              }),
                                            ],
                                            1
                                          )
                                        }
                                      )
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "titlePackageName",
                                label: "标题包",
                                align: "center",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "pageName",
                                label: "落地页",
                                align: "center",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ])
      }),
      _c(
        "div",
        { staticClass: "config-footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: !_vm.previews.length },
              on: { click: _vm.handleSubmitPlan },
            },
            [_vm._v("立即提交")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                disabled: !_vm.previews.length,
                plain: "",
              },
              on: { click: _vm.handleSavePlan },
            },
            [_vm._v("任务暂存")]
          ),
          _c(
            "el-button",
            {
              attrs: { disabled: !_vm.previews.length },
              on: { click: _vm.handleCancelPlan },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "table-template",
          attrs: {
            title: _vm.forms.title,
            visible: _vm.showEditDialog,
            "append-to-body": true,
            width: "500px",
            top: "20vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showEditDialog = $event
            },
            close: _vm.onCloseDialog,
          },
        },
        [
          _c(
            "div",
            { staticClass: "page-container" },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: { placeholder: "请输入名称" },
                model: {
                  value: _vm.forms.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.forms, "name", $$v)
                  },
                  expression: "forms.name",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                { staticClass: "btn", on: { click: _vm.closeModal } },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "btn",
                  attrs: { type: "primary", disabled: _vm.isLoading },
                  on: { click: _vm.handleConfirmEdit },
                },
                [_vm._v("确 定 ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }