<!--
 * @Description: 标签上传
 * @Date: 2021-12-20 15:03:27
 * @LastEditTime: 2022-01-20 17:15:32
-->
<template>
  <el-row :gutter="20">
    <el-col :span="12">
      <ModuleBox title="定向人群" class="mb-20">
        <section class="orientation-box">
          <div v-if="orientation_len === 0" class="justify-center align-center add-orientation" @click="addSelectedData('orientation')">
            <i class="el-icon-plus"></i>
            <span>点击添加人群</span>
          </div>
          <div v-else class="orientation-list">
            <ul v-for="(item, index) in selectedData_orientation" :key="index" :class="{ 'selected-ul': index === selectedIndex_orientation }" @click.stop="getSelectedIndex(index, 'orientation')">
              <template v-if="item.length">
                <div>
                  <li v-for="(label, idx) in item" :key="idx">
                    <div>
                      <span>{{ label.name }}</span>
                      <span>{{ label.value }}</span>
                    </div>
                    <i class="el-icon-circle-close" @click="deleteLi(index, idx)"></i>
                  </li>
                </div>
                <i class="el-icon-circle-close" @click="deleteGroup(index)"></i>
              </template>
              <template v-else>
                <div class="justify-center">
                  <p class="tip">请选择标签</p>
                </div>
                <i class="el-icon-circle-close" @click="deleteGroup(index)"></i>
              </template>
            </ul>
          </div>

          <div v-if="orientation_len !== 0" class="justify-center align-center">
            <el-button type="primary" class="mt-20" @click="addSelectedData('orientation')">添加交集</el-button>
          </div>
        </section>
      </ModuleBox>
      <ModuleBox title="排除人群" class="mb-20">
        <section class="orientation-box">
          <div v-if="exclude_len === 0" class="justify-center align-center add-orientation" @click="addSelectedData('exclude')">
            <i class="el-icon-plus"></i>
            <span>点击添加人群</span>
          </div>
          <div v-else class="orientation-list">
            <ul v-for="(item, index) in selectedData_exclude" :key="index" :class="{ 'selected-ul': index === selectedIndex_exclude }" @click.stop="getSelectedIndex(index, 'exclude')">
              <template v-if="item.length">
                <div>
                  <li v-for="(label, idx) in item" :key="idx">
                    <div>
                      <span>{{ label.name }}</span>
                      <span>{{ label.value }}</span>
                    </div>
                    <i class="el-icon-circle-close" @click="deleteLi(index, idx)"></i>
                  </li>
                </div>
                <i class="el-icon-circle-close" @click="deleteGroup(index)"></i>
              </template>
              <template v-else>
                <div class="justify-center">
                  <p class="tip">请选择标签</p>
                </div>
                <i class="el-icon-circle-close" @click="deleteGroup(index)"></i>
              </template>
            </ul>
          </div>

          <div v-if="exclude_len !== 0" class="justify-center align-center">
            <el-button type="primary" class="mt-20" @click="addSelectedData('exclude')">添加交集</el-button>
          </div>
        </section>
      </ModuleBox>

      <ModuleBox title="基础信息">
        <el-form ref="ruleForm" :model="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="人群名称:" prop="name" :rules="{ required: true, message: '请输入人群名称', trigger: 'change' }">
            <el-input v-model="ruleForm.name" maxlength="30" placeholder="请输入人群名称" show-word-limit />
          </el-form-item>

          <el-form-item label="项目：" prop="projectId">
            <SearchSelect
              v-model="ruleForm.projectId"
              :options="projectList"
              :props="{
                label: 'projectName',
                value: 'bsProjectId'
              }"
              style="width:450px"
              placeholder="请选择项目"
              @change="(value) => (selectProjectId = value)"
            />
          </el-form-item>
          <el-form-item label="产品：" prop="productId">
            <el-select v-model="ruleForm.productId" placeholder="请选择产品" style="width: 450px" @change="(value) => (selectProductId = value)">
              <el-option label="不限" :value="0"></el-option>
              <el-option v-for="(itm, idx) in productListed" :key="idx" :label="itm.productName" :value="itm.bsProductId"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="人群描述:" prop="description">
            <el-input v-model="ruleForm.description" type="textarea" rows="4" maxlength="100" show-word-limit />
          </el-form-item>

          <el-form-item>
            <el-button type="primary" class="mt-20" @click="submitForm('ruleForm')">生成人群</el-button>
          </el-form-item>
        </el-form>
      </ModuleBox>
    </el-col>
    <el-col :span="12">
      <div class="label">
        <el-radio-group v-model="selectOneLevel" @change="radioChange">
          <el-radio-button v-for="item in editableTabs_oneLevel" :key="item.label" :label="item.label">{{ item.value }}</el-radio-button>
        </el-radio-group>
        <el-tabs v-model="editableTabsValue" type="card" class="mt-20" @tab-click="(value) => getLeafTagsFun(value.name)">
          <el-tab-pane v-for="item in editableTabs" :key="item.threeId" :name="item.threeId">
            <template slot="label">
              <div class="tab-item">
                <span>{{ item.twoLevel }}</span>
                <br />
                <span class="two">{{ item.threeLevel }}</span>
              </div>
            </template>
          </el-tab-pane>
        </el-tabs>
        <LabelTree ref="labelTree" v-loading="tree_loading" :data="tree_Data" :set-checked-keys="treeCheckedKeys" @getSelected="getSelected" />
      </div>
    </el-col>
  </el-row>
</template>

<script>
// getTags
import accountUinTencent from '@/mixin/accountUin_tencent'
import { audienceSave, getTags, getLeafTags } from '@/network/api/assetManagement/assetManageWeChatAdv.js'
import ModuleBox from '../orientation/create/moduleBox.vue'
import LabelTree from './labelTree.vue'
export default {
  components: {
    ModuleBox,
    LabelTree
  },
  mixins: [accountUinTencent],
  data() {
    return {
      nowSelect: 'orientation', // orientation/exclude
      // 定向人群 start
      selectedData_orientation: [], // 人群二维数组
      orientation_len: 0, // 人群二维数组长度
      selectedIndex_orientation: 0, // 当前选中的数组索引
      // 定向人群 end

      // 排除人群 start
      selectedData_exclude: [], // 人群二维数组
      exclude_len: 0, // 人群二维数组长度
      selectedIndex_exclude: 0, // 当前选中的数组索引
      // 排除人群 end

      ruleForm: {
        name: null,
        description: null,
        projectId: null,
        productId: 0
      },

      editableTabsValue: '',
      editableTabs: [],
      editableTabs_all: [],
      editableTabs_oneLevel: [],
      selectOneLevel: [],
      tree_loading: false,
      tree_Data: []
    }
  },

  mounted() {
    getTags().then(({ code, data }) => {
      if (code === 200) {
        let formate = []
        let index = 0
        data.map((item) => {
          this.editableTabs_oneLevel.push({
            label: item.value,
            value: item.name
          })
          item.options.map((twoItem) => {
            twoItem.options.map((threeItem) => {
              formate[index] = {
                oneLevel: item.name,
                oneId: item.value,
                twoLevel: twoItem.name,
                twoId: twoItem.value,
                threeLevel: threeItem.name,
                threeId: threeItem.value
              }
              index++
            })
          })
        })
        this.editableTabs_all = formate
        this.selectOneLevel = this.editableTabs_oneLevel[0].label
        console.log(formate)
        this.editableTabs = formate.filter((f) => f.oneId === this.selectOneLevel)
        this.editableTabsValue = this.editableTabs[0].threeId
        this.getLeafTagsFun(this.editableTabs[0].threeId)
      }
    })
  },
  methods: {
    /**
     * @description 一级标签选中时调用
     * @param {string} value 人口学/行业标签/用户行为/热点标签(key)
     */
    radioChange(value) {
      this.editableTabs = this.editableTabs_all.filter((f) => f.oneId === value)
      this.editableTabsValue = this.editableTabs[0].threeId
      this.getLeafTagsFun(this.editableTabs[0].threeId)
    },

    /**
     * @description 获取子标签
     * @param {string} parentId
     */
    async getLeafTagsFun(parentId) {
      this.tree_loading = true
      const { code, data } = await getLeafTags({ parentId })
      if (code === 200) {
        this.tree_Data = data.tags
        this.$nextTick(() => {
          this.setCheckedKeysFun(this.nowSelect)
          this.tree_loading = false
        })
      }
    },

    /**
     * @description 开始添加人群 向selectedData里添加一个新的数组
     * @param {} type exclude/orientation
     */
    addSelectedData(type) {
      if (type === 'orientation') {
        this.selectedData_orientation.splice(this.orientation_len, 0, [])
        this.selectedIndex_orientation = this.orientation_len
        this.orientation_len++
        this.selectedIndex_exclude = -1
      } else {
        this.selectedData_exclude.splice(this.exclude_len, 0, [])
        this.selectedIndex_exclude = this.exclude_len
        this.exclude_len++
        this.selectedIndex_orientation = -1
      }
      this.$refs.labelTree.setCheckedKeys([])
      this.nowSelect = type
    },

    /**
     * @description 选中tree数据后调用
     */
    getSelected(value) {
      if (value.children) {
        value.children.map((item) => {
          if (this.nowSelect === 'orientation') {
            this.pushSelectedData_orientation(item)
          } else {
            this.pushSelectedData_exclude(item)
          }
        })
      } else {
        if (this.nowSelect === 'orientation') {
          this.pushSelectedData_orientation(value)
        } else {
          this.pushSelectedData_exclude(value)
        }
      }
    },

    /**
     * @description 向SelectedData里添加数据,数据已存在时,将数据删除
     * @param {id: number, label: string} value 当前选中的项的值
     */
    pushSelectedData_orientation(value) {
      const _selectedData = [...this.selectedData_orientation[this.selectedIndex_orientation]]
      if (value.children) {
        value.map((valueItem) => {
          _selectedData.map((selected, selectedIndex) => {
            if (selected.value === valueItem.value) {
              this.selectedData_orientation[selected].splice(selectedIndex, 1)
            } else {
              this.selectedData_orientation[this.selectedIndex_orientation].push(selected)
            }
          })
        })
      } else {
        // 选择最后一级标签
        if (_selectedData.some((s) => s.value === value.value)) {
          _selectedData.map((selected, selectedIndex) => {
            if (selected.value === value.value) {
              this.selectedData_orientation[this.selectedIndex_orientation].splice(selectedIndex, 1)
            }
          })
        } else {
          this.selectedData_orientation[this.selectedIndex_orientation].push(value)
        }
      }
    },

    /**
     * @description 向SelectedData里添加数据,数据已存在时,将数据删除
     * @param {id: number, label: string} value 当前选中的项的值
     */
    pushSelectedData_exclude(value) {
      const _selectedData = [...this.selectedData_exclude[this.selectedIndex_exclude]]
      if (value.children) {
        value.map((valueItem) => {
          _selectedData.map((selected, selectedIndex) => {
            if (selected.value === valueItem.value) {
              this.selectedData_exclude[selected].splice(selectedIndex, 1)
            } else {
              this.selectedData_exclude[this.selectedIndex_exclude].push(selected)
            }
          })
        })
      } else {
        // 选择最后一级标签
        if (_selectedData.some((s) => s.value === value.value)) {
          _selectedData.map((selected, selectedIndex) => {
            if (selected.value === value.value) {
              this.selectedData_exclude[this.selectedIndex_exclude].splice(selectedIndex, 1)
            }
          })
        } else {
          this.selectedData_exclude[this.selectedIndex_exclude].push(value)
        }
      }
    },

    /**
     * @description 获取选中的标签组,并让选中的项高亮显示
     * @param {number} index 当前选中的项的索引
     */
    getSelectedIndex(index, type) {
      this.nowSelect = type
      if (type === 'orientation') {
        // 选中定向人群标签组, 设置当前高亮的索引,在选中右侧tree
        this.selectedIndex_exclude = -1
        if (this.selectedData_orientation.length === index) {
          this.selectedIndex_orientation = index - 1
        } else {
          this.selectedIndex_orientation = index
        }
        this.setCheckedKeysFun(type)
      } else {
        this.selectedIndex_orientation = -1
        if (this.selectedData_exclude.length === index) {
          this.selectedIndex_exclude = index - 1
        } else {
          this.selectedIndex_exclude = index
        }
        this.setCheckedKeysFun(type)
      }
    },

    /**
     * @description 设置tree选中
     * @param {string} type  exclude/orientation
     */
    setCheckedKeysFun(type) {
      if (type === 'orientation') {
        let list = this.selectedData_orientation[this.selectedIndex_orientation]
        if (list) {
          this.treeCheckedKeys_orientation = list.map((item) => item.value)
          this.$refs.labelTree.setCheckedKeys(list.map((item) => item.value))
        } else {
          this.$refs.labelTree.setCheckedKeys([])
        }
      } else {
        let list = this.selectedData_exclude[this.selectedIndex_exclude]
        if (list) {
          this.treeCheckedKeys_exclude = list.map((item) => item.value)
          this.$refs.labelTree.setCheckedKeys(list.map((item) => item.value))
        } else {
          this.$refs.labelTree.setCheckedKeys([])
        }
      }
    },

    /**
     * @description 删除标签组
     * @param {number} groupIndex 当前选中的项的索引
     */
    deleteGroup(groupIndex) {
      if (this.nowSelect === 'orientation') {
        let selectedIndex = this.selectedIndex_orientation
        this.selectedData_orientation.splice(groupIndex, 1)
        // 如果删除选中的项时
        if (groupIndex === selectedIndex) {
          this.selectedIndex_orientation = groupIndex - 1
        } else if (groupIndex < selectedIndex) {
          // 如果删除项小于length时
          this.selectedIndex_orientation = this.selectedData_orientation.length - 1
        }
        this.orientation_len = this.selectedData_orientation.length
      } else {
        let selectedIndex = this.selectedIndex_exclude
        this.selectedData_exclude.splice(groupIndex, 1)
        // 如果删除选中的项时
        if (groupIndex === selectedIndex) {
          this.selectedIndex_exclude = groupIndex - 1
        } else if (groupIndex < selectedIndex) {
          // 如果删除项小于length时
          this.selectedIndex_exclude = this.selectedData_exclude.length - 1
        }
        this.exclude_len = this.selectedData_exclude.length
      }
    },

    /**
     * @description 删除标签
     * @param {number} groupIndex 当前选中的标签组的索引
     * @param {number} idx 当前选中的项的索引
     */
    deleteLi(groupIndex, idx) {
      if (this.nowSelect === 'orientation') {
        this.selectedData_orientation[groupIndex].splice(idx, 1)
      } else {
        this.selectedData_exclude[groupIndex].splice(idx, 1)
      }
    },

    /**
     * @description: 提交数据
     * @param {*} formName ref
     */
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let dmpInclude = []
          let dmpInclude1 = []
          this.selectedData_orientation.map((item) => {
            item.map((item) => {
              dmpInclude1.push({
                audienceId: item.audienceId,
                audienceType: null
              })
            })
            dmpInclude.push(dmpInclude1)
            dmpInclude1 = []
          })

          let dmpExclude = []
          let dmpExclude1 = []
          this.selectedData_exclude.map((item) => {
            item.map((item) => {
              dmpExclude1.push({
                audienceId: item.audienceId,
                audienceType: null
              })
            })
            dmpExclude.push(dmpExclude1)
            dmpExclude1 = []
          })

          const { projectId, productId } = this.ruleForm
          let params = {
            ...this.ruleForm,
            projectId,
            projectName: this.projectList.filter((item) => item.bsProjectId === projectId)[0].projectName,
            productId,
            productName: productId !== 0 ? this.productListed.filter((item) => item.bsProductId === productId)[0].productName : '不限',
            type: 1,
            dmpInclude: JSON.stringify(dmpInclude),
            dmpExclude: JSON.stringify(dmpExclude)
          }
          audienceSave(params).then(({ code }) => {
            if (code === 200) {
              this.$message.success('定向创建成功')
              this.$router.push('/wechatCrowdPack')
              this.resetForm(formName)
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="scss">
.label {
  .el-tabs__item {
    line-height: 20px !important;
  }
}
</style>

<style lang="scss" scoped>
.label {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
}

.orientation-box {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  min-height: 200px;
  cursor: pointer;
  margin-left: 20px;
  .add-orientation {
    font-weight: 700;
    margin-top: 80px;
    i {
      font-weight: bold;
      font-size: 20px;
      margin-right: 8px;
    }
  }

  .orientation-list {
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 6px;
      padding: 10px;
      & > div {
        border: 1px solid #ccc;
        border-radius: 5px;
        flex: 1;
        margin-right: 20px;
        li {
          display: flex;
          padding: 10px;
          align-items: center;
          & > div {
            display: flex;
            justify-content: space-between;
            flex: 1;
            margin-right: 20px;
          }
        }
      }
      & > i {
        font-size: 26px;
      }
    }
    .selected-ul {
      & > div {
        border: 1px solid rgb(141, 181, 228);
      }
    }
  }
}

.tab-item {
  text-align: center;
  span {
    padding: 0;
    margin: 0;
    line-height: 0;
  }

  span {
    font-size: 10px;
  }
  .two {
    font-size: 13px;
    font-weight: 600;
    // color: rgb(24, 24, 24);
  }
}
</style>
