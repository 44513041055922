<!--
 * @Description: 推广创意
 * @Date: 2021-09-20 12:46:48
 * @LastEditTime: 2022-05-19 16:44:22
-->
<template>
  <div class="creativity">
    <el-form ref="sizeForm" label-position="right" label-width="120px" :rules="rules" :model="sizeForm">
      <box>
        <template slot="title">关联单元</template>
        <el-form-item label="关联单元" label-width="100px" prop="baiduUnitId">
          <el-select v-model="sizeForm.baiduUnitId" placeholder="请选择关联单元" filterable style="width: 400px" @change="baiduUnitChange">
            <el-option v-for="item in baiduUnitIds" :key="item.baiduUnitId" :label="item.adgroupFeedName" :value="item.baiduUnitId"></el-option>
          </el-select>
        </el-form-item>
      </box>
      <box>
        <template slot="title">生成方式</template>
        <el-form-item label="创意方式">
          <el-radio-group v-model="sizeForm.ideaType">
            <el-radio-button :label="1">
              程序化
              <el-tooltip class="item" effect="dark" content="支持批量上传标题和图片，系统将根据标题*图片*样式的最优组合，对不同流量进行不同展现。" placement="top">
                <i class="el-icon-question" />
              </el-tooltip>
            </el-radio-button>
            <el-radio-button :label="0" disabled>
              自定义
              <el-tooltip class="item" effect="dark" content="系统将根据您组合的创意进行优选投放。" placement="top">
                <i class="el-icon-question" />
              </el-tooltip>
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
      </box>
      <!-- 品牌信息 -->
      <BrandInfo :size-form="sizeForm" :project-id="projectId" @changeData="changeData" />
      <!-- 落地页信息 -->
      <LdyInfo :size-form="sizeForm" />
      <!-- 程序化设置 -->
      <template v-if="sizeForm.ideaType === 1">
        <Programmatic ref="programmaticRef" :size-form="sizeForm" :project-id="projectId" @changeData="changeData" />
      </template>
      <template v-else>
        <!-- <Customize ref="customize2" :size-form="sizeForm" @changeData="changeData" /> -->
      </template>

      <el-form-item label-width="0px">
        <el-button type="primary" class="mb-20" @click="submitForm('sizeForm')">
          {{ paramsId ? '确认创意' : '立即创建' }}
        </el-button>

        <router-link to="/baiduCreative" class="ml-20">
          <el-button> 取消 </el-button>
        </router-link>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Box from './components/box.vue'
import BrandInfo from './components/brandInfo.vue'
import LdyInfo from './components/ldyInfo.vue'
import Programmatic from './components/programmatic/index.vue'
// import Customize from './components/customize/index.vue'
import { Page, Add, getBaiduUnitId, Update } from '@/network/api/advManagement/advManagementBaiduCreativeFend'
import moment from 'moment'
import _ from 'lodash'
export default {
  components: {
    Box,
    BrandInfo,
    LdyInfo,
    Programmatic
    // Customize
  },
  data() {
    let checkPic = (rule, value, callback) => {
      callback()
    }
    let checkTitles = (rule, value, callback) => {
      callback()
    }
    return {
      paramsId: this.$route.params.id,
      projectId: [], // 项目id
      sizeForm: {
        baiduUnitId: null, // 关联单元
        ideaType: 1, // 创意方式 0 - 自定义创意 1 - 程序化创意
        brand: '品牌名称', // null 品牌名称
        userPortrait: null, // 品牌头像
        userPortraitId: 0,
        monitorUrl: null, // 点击监测URL, 统一要求url长度不超过1024个字符。
        creativeFeedName: `创意名称-${moment().format('YYYYMMDDHHmmss')}`, // 创意名称=${new Date().getHours()}:${new Date().getMinutes()} null
        elements: {
          // 创意标题
          titles: '', // titles: '',
          pictureSingle: [], // 小图[]
          pictureLarge: [], // 大图[]
          picture3Fixed: [], // 固定三图[[]]
          video: [], // 视频[[]]
          videoUrl: [], // 视频封面[[]]
          colorRenderImages: [], // 互动图[]
          videoHorizontal: [], // 横版视频16:9[]
          videoVertical: [] // 竖版视频9:16[]
        },
        progFlag: 32, // 图片图片生成视频, []未选中, [32]☑
        playnum: 0, // 播放量 0未选中, 1☑
        readnum: 0, // 阅读量 0未选中, 1☑
        material: {}, // 程序化设置内容
        modideas: {
          pictureSingle: [],
          pictureLarge: [],
          picture3Fixed: [],
          oriSinglePic: [],
          videoHorizontal: [],
          videoVertical: [],
          openScreen: [],
          banner: []
        } // 创意样式内容
      },
      baiduUnitIds: [],
      baiduId: 0,

      rules: {
        baiduUnitId: [{ required: true, message: '请输入关联单元', trigger: 'blur' }],
        brand: [{ required: true, message: '请输入品牌名称', trigger: 'blur' }],
        userPortrait: [{ required: true, message: '请上传品牌头像', trigger: 'blur' }],
        creativeFeedName: [{ required: true, message: '请输入创意名称', trigger: 'blur' }],
        titles: [{ required: true, validator: checkTitles, trigger: 'blur' }],
        pic: [{ validator: checkPic, required: true, trigger: 'blur' }]
      }
    }
  },

  watch: {
    baiduUnitIds: {
      handler(value) {
        if (!value.length) return
        this.projectId = [value.filter((f) => f.baiduUnitId === this.baiduId)[0].projectId]
      },
      immediate: true
    },
    baiduId: {
      handler(value) {
        if (!value) return
        this.projectId = [this.baiduUnitIds.filter((f) => f.baiduUnitId === value[0])[0].projectId]
      },
      immediate: true
    }
  },
  mounted() {
    this.getBaiduUnitIdFun()
    this.getPageItem()
  },
  methods: {
    getPageItem() {
      if (!this.$route.params.id) return
      Page({ baiduCreativeFendId: this.$route.params.id }).then(({ code, data }) => {
        if (code === 200) {
          const { brand, baiduUnitId, ideaType, material, creativeFeedName, progFlag, playnum, readnum } = data.records[0]

          this.projectId = [baiduUnitId]
          this.sizeForm.baiduUnitId = baiduUnitId
          this.sizeForm.ideaType = ideaType
          this.sizeForm.brand = brand
          this.sizeForm.creativeFeedName = this.$route.params.type === 'copy' ? creativeFeedName + '复制' : creativeFeedName
          this.sizeForm.progFlag = +progFlag
          this.sizeForm.playnum = +playnum
          this.sizeForm.readnum = +readnum
          const { userPortrait, elements, userPortraitId } = JSON.parse(material)
          this.sizeForm.userPortrait = userPortrait
          this.sizeForm.userPortraitId = userPortraitId
          this.baiduId = baiduUnitId
          // 固定3图改变格式
          let elements2 = {
            ...elements,
            titles: elements.titles.join('\n'),
            picture3Fixed: elements.picture3Fixed.flat(),
            videoUrl: [...elements.videoHorizontal, ...elements.videoVertical],
            video: [...elements.videoHorizontal, ...elements.videoVertical].map((item) => item.poster[0])
          }
          this.sizeForm.elements = elements2

          let flag = true
          for (const key in elements2) {
            if (Object.hasOwnProperty.call(elements2, key)) {
              const element = elements2[key]
              if (flag && Array.isArray(element) && element.length > 0) {
                this.$refs['programmaticRef'].pic_label = key
                flag = false
              }
            }
          }
        }
      })
    },
    // 获取关联单元
    getBaiduUnitIdFun() {
      getBaiduUnitId().then((res) => {
        const { code, data } = res
        if (code === 200) {
          this.baiduUnitIds = data
        }
      })
    },

    // 选择关联单元获取项目Id
    baiduUnitChange(value) {
      this.projectId = [this.baiduUnitIds.filter((f) => f.baiduUnitId === value)[0].projectId]
    },

    // 改变sizeForm数据
    changeData(newSizeForm) {
      this.sizeForm = {
        ...this.sizeForm,
        ...newSizeForm
      }
    },

    // 立即创建
    submitForm(formName) {
      const pics = this.sizeForm.elements
      let picslen = false
      if (!this.sizeForm.userPortrait) {
        return this.$message.error(`请先上传品牌头像!`)
      }
      if (this.sizeForm.elements.videoUrl && this.sizeForm.elements.videoUrl.some((item) => item.poster.length === 0)) {
        return this.$message.error(`请上传视频封面图!`)
      }

      for (const key in pics) {
        if (key !== 'titles') {
          if (Object.hasOwnProperty.call(pics, key)) {
            const element = pics[key]
            if (element.length > 0) {
              delete element[0].status
              delete element[0].uid
              picslen = true
            }
          }
        }
      }
      if (!picslen) {
        return this.$message.error('请选择创意物料')
      }

      const titles = JSON.parse(JSON.stringify(this.sizeForm.elements.titles))
      if (!titles.length) {
        return this.$message.error('请选择创意标题')
      }
      const title = titles && titles.split('\n')
      let titleUniq = _.uniq(title)
      if (titleUniq.length !== title.length) {
        return this.$message.error('存在两条相同标题, 请修改!')
      }
      if (!title.every((s) => s.length <= 60)) {
        return this.$message.error('每条标题最多不超过60字符!')
      }

      let params = {
        ...this.sizeForm,
        materialstyle: this.sizeForm.ideaType === 1 ? 114 : 114,
        material: {
          title: this.sizeForm.elements.titles.replace(/[\r\n]/g, ''),
          brand: this.sizeForm.brand,
          userPortrait: this.sizeForm.userPortrait,
          userPortraitId: this.sizeForm.userPortraitId,
          monitorUrl: this.sizeForm.monitorUrl
        }
      }
      delete this.sizeForm.elements.video
      delete this.sizeForm.elements.videoUrl
      if (this.sizeForm.ideaType === 1) {
        delete params.modideas
        delete params.elements
        // 固定3图改变格式
        let picture3Fixed = []
        let index = 0
        while (index < this.sizeForm.elements.picture3Fixed.length) {
          picture3Fixed.push(this.sizeForm.elements.picture3Fixed.slice(index, (index += 3)))
        }

        params.material.elements = {
          ...this.sizeForm.elements,
          titles: titles.split('\n'),
          picture3Fixed
        }

        if (params.material.elements.titles.length > 50) {
          return this.$message.error('创意标题不能大于50条!')
        }

        if (params.material.elements.titles.some((s) => s === null || s === '')) {
          return this.$message.error(`第${params.material.elements.titles.findIndex((s) => s === null || s === '') + 1}行创意标题不能为空!`)
        }

        // 删除视频
        // delete params.material.elements.video
        params.material = JSON.stringify(params.material)
      } else {
        const customizeData = this.$refs['customize2'] && this.$refs['customize2'].getCustomizeData()
        if (!customizeData) return
        console.log(customizeData)
        params.modideas = customizeData
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.$route.params.id) {
            if (this.$route.params.type === 'copy') {
              // 复制新建
              params.baiduCreativeFendId = null
              const res = await Add({ datas: [params] })
              if (res) {
                if (res.code === 200) {
                  this.$router.push({ path: '/baiduCreative' })
                  this.$message.success('复制成功!')
                }
              } else {
                return false
              }
            } else {
              // 编辑
              params.baiduCreativeFendId = this.$route.params.id
              const res = await Update({ datas: [params] })
              if (res) {
                if (res.code === 200) {
                  this.$router.push({ path: '/baiduCreative' })
                  this.$message.success('编辑成功!')
                }
              } else {
                return false
              }
            }
          } else {
            // 创建
            const { code } = await Add({ datas: [params] })
            if (code === 200) {
              this.$router.push({ path: '/baiduCreative' })
              this.$message.success('创建成功!')
            }
          }
        } else {
          this.$message.success('字段未填写完全!')
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
