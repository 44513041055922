var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "add-product-dialog",
      attrs: {
        title: "新增产品",
        visible: _vm.show,
        "append-to-body": true,
        width: "600px",
        top: "20vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.onCloseDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                inline: false,
                "label-width": "100px",
                model: _vm.form,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "产品名称", prop: "toolProductName" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      placeholder: "请输入产品名称",
                      maxlength: "30",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.form.toolProductName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "toolProductName", $$v)
                      },
                      expression: "form.toolProductName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "来源", prop: "toolProductSource" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      placeholder: "请输入来源",
                      maxlength: "15",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.form.toolProductSource,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "toolProductSource", $$v)
                      },
                      expression: "form.toolProductSource",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "平台", prop: "toolProductPlatform" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择平台" },
                      model: {
                        value: _vm.form.toolProductPlatform,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "toolProductPlatform", $$v)
                        },
                        expression: "form.toolProductPlatform",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "全部", value: "" } }),
                      _vm._l(_vm.platformList, function (item) {
                        return _c("el-option", {
                          key: item.dictValue,
                          attrs: {
                            label: item.dictLabel,
                            value: item.dictValue,
                          },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "类型", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择类型" },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    _vm._l(_vm.typeList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "连载状态", prop: "serializeStatus" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择连载状态" },
                      model: {
                        value: _vm.form.serializeStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "serializeStatus", $$v)
                        },
                        expression: "form.serializeStatus",
                      },
                    },
                    _vm._l(_vm.statusList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "数量", prop: "num" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100%" },
                    attrs: { placeholder: "请输入数量" },
                    on: {
                      input: (e) => (_vm.form.num = _vm._integerZeroFn(e)),
                    },
                    model: {
                      value: _vm.form.num,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "num", $$v)
                      },
                      expression: "form.num",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "主角名", prop: "lead" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      placeholder: "请输入主角名",
                      maxlength: "20",
                      "show-word-limit": "",
                      step: 1,
                      "step-strictly": "",
                    },
                    model: {
                      value: _vm.form.lead,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "lead", $$v)
                      },
                      expression: "form.lead",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "btn", on: { click: _vm.closeModal } },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { type: "primary", disabled: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }