/*
 * @Description: 百度广告api
 * @Date: 2021-09-15 09:48:06
 * @LastEditTime: 2021-11-23 09:01:43
 */
import service from '@/network/service/service'

export function Page(data) {
  return service.post('/api/system/advManagementBaiduPlan/page', data)
}

export function Add(data) {
  return service.post('/api/system/advManagementBaiduPlan/save', data)
}

export function Update(data) {
  return service.post('/api/system/advManagementBaiduPlan/update', data)
}

export function Delete(id) {
  return service.delete('/api/system/advManagementBaiduPlan/' + id)
}
export function copyPlan(id) {
  return service.get('/api/system/advManagementBaiduPlan/copyPlan?' + id)
}
export function getMePlan() {
  return service.get('/api/system/advManagementBaiduPlan/getMePlan')
}

export function byProjectIdGetPlan(projectId, productId) {
  return service.get(`/api/system/advManagementBaiduPlan/byProjectidGetPlan/${projectId}/${productId}`)
}

/**
 * @description 百度程序化:获取线上百度计划
 *
 */
export function getBaiduApiPlan(housekeeperId, baiduId) {
  return service.get(`/api/system/advManagementBaiduPublish/getBaiduApiPlan/${housekeeperId}/${baiduId}/1`)
}

/**
 * @description 发布线上百度计划
 *
 */
export function batchUpPlan(data) {
  return service.post(`/api/system/advManagementBaiduPublish/batchUpPlan`, data)
}

/**
 * @description  删除线上百度计划
 *
 */
export function deleteBaiduApiPlan(data) {
  return service.post(`/api/system/advManagementBaiduPublish/deleteBaiduApiPlan`, data)
}
