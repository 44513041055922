/*
 * @Description: 创意数据
 * @Date: 2022-01-06 13:34:45
 * @LastEditTime: 2022-04-09 14:53:44
 */

import moment from 'moment'

export const creativityBaseData = {
  // 朋友圈创意-基本信息
  moments_info: {
    creativeReuse: true, // 创意复用
    assetType: 1, // 创意素材类型
    adStyle: 'routine', //  广告样式： 常规广告(routine)
    pageType: 4, // 外层素材跳转：原生推广页(4)
    linkName: '了解更多', // 文字链文案：了解公众号(了解公众号), 了解更多(了解更多)
    linkPageType: 4, // 文字链跳转：原生推广页(4), 公众号详情页(6)
    snsDynamicShowSwitch: false, // 数据展示：
    snsDynamicShowType: null, // 数据类型：选中(6)
    snsDynamicShowText: null,
    tname: `朋友圈-${moment().format('YYYYMMDDhhmmss')}` // 创意名称：
  },
  // 公众号文章中标-基本信息
  center_info: {
    crtSize: 484, // 创意样式: 图片式卡片(484)
    headDescType: 2, // 信息栏类型： 文案型(2)
    mpTagType: 0, // 标签展示： 不启用(0)
    pageType: 4, // 外层素材跳转： 原生推广页(4), 公众号详情页(6)
    buttonPageType: 4, // 点击按钮跳转: 原生推广页(4)  一键关注(40024)
    buttonParam: null, // 按钮文案：: 阅读小说(阅读小说)
    tname: ` 公众号文章中部-${moment().format('YYYYMMDDhhmmss')}` // 创意名称：
  },
  // 展示Banner图片-基本信息
  form_weapp_banner: {
    crtSize: 925, // 创意样式: 图片（新(925)
    pageType: 4, // 外层素材跳转： 原生推广页(4), 公众号详情页(6)
    buttonPageType: 4, // 点击按钮跳转: 原生推广页(4) 一键关注(40024),
    tname: `展示Banner图片-${moment().format('YYYYMMDDhhmmss')}` // 创意名称：
  },

  // 优雅横版大图
  horizonTal_image_info: {
    crtSize: 928, // 创意样式: 大图(推广公众号是928, 其他是927)
    headDescType: 2, // 信息栏类型： 文案型(2)
    mpTagType: 0, // 标签展示： 不启用(0)
    pageType: 4, // 外层素材跳转： 原生推广页(4), 公众号详情页(6)
    buttonPageType: 4, // 点击按钮跳转: 原生推广页(4) 一键关注(40024),
    buttonParam: null, // 按钮文案：: 阅读小说(推广公众号是阅读小说, 其他是立即咨询)
    tname: `优雅横版大图-${moment().format('YYYYMMDDhhmmss')}` // 创意名称：
  },
  // 沉浸式视频
  quiet_video_info: {
    headDescType: 2, // 信息栏类型： 文案型(2)
    pageType: 4, // 外层素材跳转： 原生推广页(4), 公众号详情页(6)
    buttonParam: null, // 按钮文案：: 关注公众号(推广公众号是关注公众号, 其他是立即咨询)
    mpTagType: 0, // 标签展示： 不启用(0)
    endPage: 0, // 结束页：开启(1) 关闭(0)
    endPageType: 1, // 结束页类型： 突出头像昵称(1) 突出文案(2),
    endPageDesc: '', // 结束页文案
    pageTopImgFlag: false, // 支持在播放结束后展现广告结束页
    pageTopImgs: [], // 页面顶部图片：['','','']
    tname: `沉浸式视频-${moment().format('YYYYMMDDhhmmss')}` // 创意名称：
  },
  // 小程序banner广告
  weapp_banner: {
    crtSize: 556, // 创意样式: 图片名片式banner(556)
    pageType: 4, // 外层素材跳转： 原生推广页(4), 公众号详情页(6)
    tname: `小程序banner广告-${moment().format('YYYYMMDDhhmmss')}` // 创意名称：
  },
  // 公众号文章底部视频
  form_weapp_banner_video: {
    crtSize: 538, // 创意样式: 视频式卡片(538)
    headDescType: 2, // 信息栏类型： 文案型(2)
    endPage: 0, // 结束页：开启(1) 关闭(0)
    endPageType: 1, // 结束页类型： 突出头像昵称(1) 突出文案(2),
    endPageDesc: '', // 结束页文案
    pageType: 4, // 点击跳转： 原生推广页(4), 公众号详情页(6)
    buttonPageType: 4, // 点击按钮跳转: 原生推广页(4)  一键关注(40024)
    buttonParam: null, // 按钮文案：: 阅读小说(阅读小说)
    mpTagType: 0, // 标签展示： 不启用(0)
    tname: `公众号文章底部视频-${moment().format('YYYYMMDDhhmmss')}` // 创意名称：
  },
  // 公众号文章底部图片
  form_weapp_banner_image: {
    crtSize: 998, // 创意样式: 大图式卡片(998)
    headDescType: 2, // 信息栏类型： 文案型(2)
    pageType: 4, // 点击跳转： 原生推广页(4), 公众号详情页(6)
    buttonPageType: 4, // 点击按钮跳转: 原生推广页(4)  一键关注(40024)
    buttonParam: null, // 按钮文案：: 阅读小说(阅读小说)
    mpTagType: 0, // 标签展示： 不启用(0)
    tname: `公众号文章底部图片-${moment().format('YYYYMMDDhhmmss')}` // 创意名称：
  }
}
