<!--
 * @Description: 复制公众号编辑区
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2022-04-01 16:06:35
-->

<template>
  <section class="section">
    <base-box name="copyGh">
      <template slot="body">
        <div>
          <p class="w-100">组件样式：</p>
          <div style="display:flex; align-items: center">
            <div>
              <el-radio-group v-model="detail.type" size="medium">
                <el-radio label="label">标签</el-radio>
                <el-radio label="button">按钮</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
        <div>
          <p class="w-100">组件背景色：</p>
          <el-dropdown style="width:200px" placement="bottom-start">
            <div class="ldy-bg-color" :style="`background:${detail.backgroundColor}`"></div>
            <el-dropdown-menu slot="dropdown">
              <twitter-color :colors="detail.backgroundColor" style="margin-top: 10px" @change="(value) => (detail.backgroundColor = value)" />
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div>
          <p class="w-100">公众号字体色：</p>
          <div>
            <el-dropdown style="width:200px" placement="bottom-start">
              <div class="ldy-bg-color" :style="`background:${detail.ghFontColor}`"></div>
              <el-dropdown-menu slot="dropdown">
                <twitter-color :colors="detail.ghFontColor" style="margin-top: 10px" @change="(value) => (detail.ghFontColor = value)" />
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <div>
          <p class="w-100">按钮文案：</p>
          <div style="display: flex">
            <el-input :value="detail.btnTitle" style="width: 200px" maxlength="10" show-word-limit type="text" size="small" @input="btnTitle" />
            <el-radio-group v-model="detail.btnFontType" size="small" class="ml-20">
              <el-radio-button label="normal">常规</el-radio-button>
              <el-radio-button label="bold">加粗</el-radio-button>
            </el-radio-group>
          </div>
        </div>

        <div>
          <p class="w-100">按钮字体色：</p>
          <div>
            <el-dropdown>
              <div class="ldy-bg-color" :style="`background:${detail.btnFontColor}`"></div>
              <el-dropdown-menu slot="dropdown">
                <twitter-color :colors="detail.btnFontColor" style="margin-top: 10px" @change="(value) => (detail.btnFontColor = value)" />
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <div>
          <p class="w-100">按钮边框色：</p>
          <div>
            <el-dropdown>
              <div class="ldy-bg-color" :style="`background:${detail.btnBorderColorTheme}`"></div>
              <el-dropdown-menu slot="dropdown">
                <twitter-color :colors="detail.btnBorderColorTheme" style="margin-top: 10px" @change="(value) => (detail.btnBorderColorTheme = value)" />
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <div>
          <p class="w-100">按钮填充色：</p>
          <div>
            <el-dropdown>
              <div class="ldy-bg-color" :style="`background:${detail.btnBgColorTheme}`"></div>
              <el-dropdown-menu slot="dropdown">
                <twitter-color :colors="detail.btnBgColorTheme" style="margin-top: 10px" @change="(value) => (detail.btnBgColorTheme = value)" />
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <div>
          <p class="w-100">上边距：</p>
          <div>
            <el-slider v-model="detail.paddingTop" show-input> </el-slider>
          </div>
        </div>

        <div>
          <p class="w-100">下边距：</p>
          <div>
            <el-slider v-model="detail.paddingBottom" show-input> </el-slider>
          </div>
        </div>
      </template>
    </base-box>
  </section>
</template>

<script>
import BaseBox from './base.vue'
import Twitter from '@/components/twitterColor.vue'
export default {
  components: {
    'base-box': BaseBox,
    'twitter-color': Twitter
  },
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    btnTitle(value) {
      this.detail.btnTitle = this.nbsp2Space(value)
    },

    nbsp2Space(str) {
      var arrEntities = { lt: '<', gt: '>', nbsp: ' ', amp: '&', quot: '"' }
      return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, function(all, t) {
        return arrEntities[t]
      })
    },
    /**
     * @description: 打开资源库
     */
    openMaterialBox() {
      this.$emit('openMaterialBox')
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.update {
  width: 130px !important;
  height: 130px;
  line-height: 130px;
  text-align: center;
  border: 1px solid #edecf2;
  background-color: #fbfafd;
  border-radius: 3px;
  cursor: pointer;
  font-size: 30px;
  color: #7d7d7d;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .avatar {
    width: 130px;
  }
}

.image-updata {
  display: flex !important;
  align-items: flex-start !important;
}

.ldy-bg-color {
  width: 28px;
  height: 28px;
  border: 5px solid #bfc2c7;
  border-radius: 4px;
}

.setting {
  display: flex;
  align-items: flex-start !important;
}

.section {
  .w-100 {
    font-size: 12px;
    margin-right: 10px;
  }
}
</style>
