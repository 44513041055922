<!--
 * @Description: 复制公众号编辑区
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2023-03-09 17:40:34
-->

<template>
  <section>
    <base-box name="floatbutton" desc="页面中出现全景图，将暂时消失" :title="!setting ? '基础设置' : '客服/按钮设置'">
      <template v-if="!setting" slot="body">
        <div>
          <p class="w-100 font-13">转化按钮：</p>
          <div class="ml-20" style="display:flex; align-items: center">
            <p style="font-size: 14px; color:#a7a5a5">添加商家微信</p>
            <el-button type="text" class="ml-20" @click="setting = true">设置</el-button>
          </div>
        </div>

        <div>
          <p class="w-100 font-13">卡片样式：</p>
          <div class="ml-20" style="display:flex; align-items: center">
            <el-select v-model="detail.cardType" placeholder="请选择卡片样式" style="width: 250px">
              <el-option label="图片+标题+描述+按钮" :value="0"></el-option>
              <el-option label="标题+按钮" :value="1"></el-option>
            </el-select>
          </div>
        </div>

        <div v-if="detail.cardType === 0">
          <p class="w-100 font-13">图片：</p>
          <div class="ml-20 image-updata">
            <el-upload class="avatar-uploader update" :action="UPLOAD_URL" accept=".png,.jpg,.jpeg" :show-file-list="false"
              :before-upload="beforeAvatarUpload" :on-success="(res) => handleAvatarSuccess(res)">
              <img v-if="detail.pureImageUrl" :src="detail.pureImageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <p class="tip mt-20 ml-20">
              图片大小：96像素*96像素 <br />
              图片格式：大小不超过300KB，不支持Gif
            </p>
          </div>
        </div>

        <div>
          <p class="w-100 font-13">标题：</p>
          <div class="ml-20" style="display: flex">
            <el-input v-model="detail.title" placeholder="请输入悬浮组件标题" style="width: 250px" maxlength="10"
              show-word-limit />
          </div>
        </div>

        <div v-if="detail.cardType === 0">
          <p class="w-100 font-13">描述：</p>
          <div class="ml-20" style="display: flex">
            <el-input v-model="detail.desc" placeholder="请输入悬浮组件描述" style="width: 250px" maxlength="14" show-word-limit />
          </div>
        </div>

        <div>
          <p class="w-100 font-13">标题字色：</p>
          <div class="ml-20">
            <el-dropdown>
              <div class="ldy-bg-color" :style="`background:${detail.titleColor}`"></div>
              <el-dropdown-menu slot="dropdown">
                <twitter-color :colors="detail.titleColor" style="margin-top: 10px"
                  @change="(value) => (detail.titleColor = value)" />
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <div v-if="detail.cardType === 0">
          <p class="w-100 font-13">描述字色：</p>
          <div class="ml-20">
            <el-dropdown>
              <div class="ldy-bg-color" :style="`background:${detail.descColor}`"></div>
              <el-dropdown-menu slot="dropdown">
                <twitter-color :colors="detail.descColor" style="margin-top: 10px"
                  @change="(value) => (detail.descColor = value)" />
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <div>
          <p class="w-100 font-13">出现方式：</p>
          <div class="ml-20">
            <el-radio value="1" label="1">进入页面时出现 </el-radio>
          </div>
        </div>

        <div>
          <p class="w-100 font-13">消失方式：</p>
          <div class="ml-20">
            <el-radio value="1" label="1">不消失 </el-radio>
          </div>
        </div>
      </template>

      <template v-else slot="body">
        <div>
          <p class="w-100 font-13 font-13">客服类型：</p>
          <div class="ml-20">
            <el-radio value="1" label="1">企业微信客服 </el-radio>
          </div>
        </div>

        <div>
          <p class="w-100 font-13">添加类型：</p>
          <div class="ml-20">
            <el-radio value="1" label="1">客服 </el-radio>
          </div>
        </div>

        <div>
          <p class="w-100 font-13">客服分配：</p>
          <div class="ml-20">
            <p class="tip">广告模板发布时添加</p>
          </div>
        </div>
        <el-divider></el-divider>
        <div>
          <p class="w-100 font-13">按钮文案：</p>
          <div class="ml-20" style="display: flex">
            <el-input v-model="detail.componentItem.btnTitle" style="width: 160px" :maxlength="5" show-word-limit />
            <el-radio-group v-model="detail.componentItem.btnFontType" size="small" class="ml-20">
              <el-radio-button label="0">常规</el-radio-button>
              <el-radio-button label="1">加粗</el-radio-button>
            </el-radio-group>
          </div>
        </div>

        <div>
          <p class="w-100 font-13">按钮字体色：</p>
          <div class="ml-20">
            <el-dropdown>
              <div class="ldy-bg-color" :style="`background:${detail.componentItem.fontColor}`"></div>
              <el-dropdown-menu slot="dropdown">
                <twitter-color :colors="detail.btnFontColor" style="margin-top: 10px"
                  @change="(value) => (detail.componentItem.fontColor = value)" />
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <div>
          <p class="w-100 font-13">按钮填充色：</p>
          <div class="ml-20">
            <el-dropdown>
              <div class="ldy-bg-color" :style="`background:${detail.componentItem.btnBgColorTheme}`"></div>
              <el-dropdown-menu slot="dropdown">
                <twitter-color :colors="detail.componentItem.btnBgColorTheme" style="margin-top: 10px"
                  @change="(value) => (detail.componentItem.btnBgColorTheme = value)" />
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div style="width: 100%" @click="setting = false">
          <i class="el-icon-back"></i>
        </div>
      </template>
    </base-box>
  </section>
</template>

<script>
import BaseBox from './base.vue'
import Twitter from '@/components/twitterColor.vue'
export default {
  components: {
    'base-box': BaseBox,
    'twitter-color': Twitter
  },
  props: {
    detail: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      setting: false,
      UPLOAD_URL: '/api/file/upload'
    }
  },
  methods: {
    /**
     * @description: 打开资源库
     */
    openMaterialBox() {
      this.$emit('openMaterialBox')
    },

    // 卡片主图-判断图片大小
    async beforeAvatarUpload (file, type) {
      const isType = ['image/jpg', 'image/jpeg', 'image/png'].includes(file.type)

      const isSize = file.size / 1024 < 300
      if (!isType) {
        this.$message.error(`上传图片只能是'image/jpg', 'image/jpeg', 'image/png'格式!`)
      }
      if (!isSize) {
        this.$message.error(`上传图片大小不能超过300KB!`)
      }
      const reader = new FileReader()
      reader.readAsDataURL(file)
      // 让页面中的img标签的src指向读取的路径
      await new Promise((resolve, reject) => {
        reader.onload = () => {
          const img = new Image()
          img.src = reader.result
          img.onload = () => {
            if (img.width !== 96 || img.height !== 96) {
              this.$message.error(`上传图片尺寸仅支持 96px * 96px`)
              reject(false)
              return false
            } else {
              resolve(true)
            }
          }
        }
      })
      return isType && isSize
    },

    // 卡片主图上传回调
    handleAvatarSuccess(res) {
      const { data, code } = res
      if (code === 200) {
        this.detail.pureImageUrl = data.url
      }
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}

.update {
  width: 130px !important;
  height: 130px;
  line-height: 130px;
  text-align: center;
  border: 1px solid #edecf2;
  background-color: #fbfafd;
  border-radius: 3px;
  cursor: pointer;
  font-size: 30px;
  color: #7d7d7d;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .avatar {
    width: 130px;
  }
}

.image-updata {
  display: flex !important;
  align-items: flex-start !important;
}

.ldy-bg-color {
  width: 28px;
  height: 28px;
  border: 5px solid #bfc2c7;
  border-radius: 4px;
}

.setting {
  display: flex;
  align-items: flex-start !important;
}
</style>
