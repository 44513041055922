export const searchForm = {
  bsProductId: null,
  productName: null,
  productTypeId: null,
  productTypeName: null,
  productSourceId: null,
  productSourceName: null,
  productGroupId: null,
  productGroupName: null,
  relationProjectId: null,
  relationProjectName: null,
  relationProjectIdNew: null,
  relationProjectNameNew: null,
  isUseing: null,
  deleted: null,
  createBy: null,
  createTime: null,
  updateBy: null,
  updateTime: null,
  remark: null
}

export class BasicSettingProductEnt {
  bsProductId
  productName
  productTypeId
  productTypeName
  productSourceId
  productSourceName
  productGroupId
  productGroupName
  relationProjectId
  relationProjectName
  relationProjectIdNew
  relationProjectNameNew
  isUseing
  deleted
  createBy
  createTime
  updateBy
  updateTime
  remark
}

export const formRules = {
  // bsProductId: [{ required: true, message: '请填写', trigger: 'change' }],
  productName: [{ required: true, message: '请填写产品名称', trigger: 'blur' }],
  productTypeId: [{ required: true, message: '请填写产品类型id', trigger: 'blur' }],
  // productTypeName: [{ required: true, message: '请填写产品类型名称', trigger: 'change' }],
  productSourceId: [{ required: true, message: '请填写产品来源id', trigger: 'blur' }],
  relationProjectIdNew: [{ required: true, message: '请填写关联项目', trigger: 'blur' }],
  // productSourceName: [{ required: true, message: '请填写产品来源名称', trigger: 'change' }],
  // productGroupId: [{ required: true, message: '请填写产品组id', trigger: 'change' }],
  // productGroupName: [{ required: true, message: '请填写产品组名称', trigger: 'change' }],
  // relationProjectId: [{ required: true, message: '请填写关联项目id', trigger: 'change' }],
  // relationProjectName: [{ required: true, message: '请填写关联项目名称', trigger: 'change' }],
  isUseing: [{ required: true, message: '请填写是否启用', trigger: 'blur' }]
  // deleted: [{ required: true, message: '请填写逻辑删除', trigger: 'change' }],
  // createBy: [{ required: true, message: '请填写创建者', trigger: 'change' }],
  // createTime: [{ required: true, message: '请填写创建时间', trigger: 'change' }],
  // updateBy: [{ required: true, message: '请填写更新者', trigger: 'change' }],
  // updateTime: [{ required: true, message: '请填写更新时间', trigger: 'change' }],
  // remark: [{ required: true, message: '请填写备注', trigger: 'change' }]
}
export const isUseingList = [{
  value: 'ISUSEING',
  label: '启用'
}, {
  value: 'ISNOTUSEING',
  label: '不启用'
}]
