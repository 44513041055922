var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("summary-data", { attrs: { deptList: _vm.deptList } }),
      _c("projection-data", { attrs: { deptList: _vm.deptList } }),
      _c("material-data", { attrs: { deptList: _vm.deptList } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }