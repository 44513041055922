/*
 * @Description: 华为落地页
 * @Date: 2022-04-20 17:56:48
 * @LastEditTime: 2022-04-25 15:14:45
 */
import service from '@/network/service/service'

export function Page(data) {
  return service.post('/api/system/cwOceanenginePage/page', data)
}

export function Add(data) {
  return service.post('/api/system/cwOceanenginePage/save', data)
}

export function Update(data) {
  return service.post('/api/system/cwOceanenginePage/update', data)
}

export function buildCommonThirdLandingPage(data) {
  return service.post('/api/system/cwOceanenginePage/buildCommonThirdLandingPage', data)
}

export function getFormal(id) {
  return service.get(`/api/system/cwOceanenginePage/getFormal/${id}`)
}

export function Delete(id) {
  return service.delete('/api/system/cwOceanenginePage/' + id)
}

export function getWechatAccount(params) {
  return service.get(`/api/system/dcWechatAccount/getAll?ghid=${params}&name=${params}`)
}

export function getWechatAccountLike(params) {
  return service.get(`/api/system/dcWechatAccount/getAllLike?ghid=${params}&name=${params}`)
}

export function buildThirdLandingPage(data) {
  return service.post(`/api/system/cwOceanenginePage/buildThirdLandingPage`, data)
}

export function getPageDomain(data) {
  return service.post(`/api/system/cwOceanenginePageDomain/page`, data)
}
