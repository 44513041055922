<template>
  <el-dialog
    :title="isEdit ? '编 辑': '新 增'"
    width="45%"
    :visible.sync="visible"
    :before-close="close"
    label-position="right"
    :modal-append-to-body="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <el-form ref="ent" :model="ent" :rules="rules" label-width="110px">

      <el-form-item label="标签分类名称" prop="labelTypeName">
        <el-input v-model="ent.labelTypeName" maxlength="15" placeholder="最大输入长度15 超出截断" />
      </el-form-item>
      <el-form-item label="关联项目" prop="relationProjectId">
        <el-select v-model="ent.relationProjectId" multiple filterable placeholder="请选择" @change="selectChange">
          <el-option
            v-for="item in options"
            :key="item.bsProjectId"
            :label="item.projectName"
            :value="item.bsProjectId"
          />
        </el-select>
        <!-- <el-input v-model="ent.relationProjectId" /> -->
      </el-form-item>

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button round @click="close">取消</el-button>

      <el-popconfirm class="ml-10" confirm-button-text="提交" cancel-button-text="取消" icon="el-icon-info" icon-color="red" title="确定提交？" @confirm="submit">
        <el-button slot="reference" type="primary" round>确定</el-button>
      </el-popconfirm>

    </span>
  </el-dialog>
</template>
<script>
import {
  Add,
  Update
} from '@/network/api/basicSetting/label/basicSettingLabelType'
import { BasicSettingLabelTypeEnt, formRules } from './object'
import {
  getProjectForSource
} from '@/network/api/basicSetting/project/basicSettingProject'
export default {
  inject: ['loadData'],
  data() {
    return {
      ent: new BasicSettingLabelTypeEnt(),
      rules: formRules,
      isEdit: false,
      visible: false,
      options: [],
      cityList: []
    }
  },
  mounted() {
    this.getProject()
  },
  methods: {
    selectChange(val) {
      if (val !== undefined && val.length > 0) {
        var content = ''
        val.forEach(item => {
          var arr2 = this.options.filter(w => w.bsProjectId === item)
          if (arr2.length > 0) {
            if (content === '') {
              content = arr2[0].projectName
            } else {
              content = content + ',' + arr2[0].projectName
            }
          }
        })
        this.ent.relationProjectName = content
      }
    },
    getProject() {
      getProjectForSource().then(x => {
        if (x.code === 200) {
          this.options = x.data
        }
      })
    },
    submit() {
      this.$refs.ent.validate((validate) => {
        if (validate) {
          this.isEdit ? this.updateRow() : this.addRow()
        }
      })
    },
    addRow() {
      const req = { ...this.ent }
      req.relationProjectId = req.relationProjectId.toString()
      Add(req).then(res => {
        if (res.code === 200) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.loadData()
          this.close()
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    updateRow() {
      const req = { ...this.ent }
      req.relationProjectId = req.relationProjectId.toString()

      Update(req).then(res => {
        if (res.code === 200) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.loadData()
          this.close()
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    close() {
      this.visible = false
      this.$nextTick(() => {
        this.$refs.ent.resetFields()
      })
    },
    show(isEdit = false, ent) {
      if (isEdit) {
        this.ent = new BasicSettingLabelTypeEnt()
        this.ent.bsLabelTypeId = ent.bsLabelTypeId
        this.ent.labelTypeName = ent.labelTypeName
        this.ent.relationProjectName = ent.relationProjectName
        if (ent.relationProjectId !== undefined && ent.relationProjectId !== null) {
          const arrays = ent.relationProjectId.split(',')
          if (arrays !== undefined) {
            this.ent.relationProjectId = []
            arrays.forEach(item => {
              this.ent.relationProjectId.push(Number(item))
            })
          }
        }
      } else {
        this.ent = ent
        this.ent.relationProjectId = []
      }

      this.isEdit = isEdit
      this.visible = true
      this.$nextTick(() => {
        if (this.isEdit) {
          console.log('不能为空')
        }
      })
    }
  }
}
</script>
