<template>
  <el-dialog class="table-template" title="短剧回传" :visible.sync="visible" :append-to-body="true"
             width="900px" @close="visible = false"
  >
    <el-row>
      <el-radio v-model="payModel" label="1">有效获客</el-radio>
      <el-radio v-model="payModel" label="2">付费1</el-radio>
      <el-radio v-model="payModel" label="3">付费2</el-radio>
    </el-row>
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="visible = false">取 消</el-button>
      <el-button class="btn" type="primary" @click="submit">回 传</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { handHugeReturn } from '@/network/api/passBack'

export default {
  name: 'VnovelCallbackDialog',
  data() {
    return {
      record: null,
      payModel: '1',
      visible: false
    }
  },
  methods: {
    submit() {
      let params = {
        recordId: this.record.id,
        platform: this.record.platform,
        deliveryPlatform: this.record.type + '',
        payModel: this.payModel
      }
      handHugeReturn(params).then((res) => {
        if (res.code === 200) {
          this.$message.success('回传成功!')
        }
      }).finally(() => {
        this.visible = false
        this.$emit('submit')
      })
    },
    showDialog(row) {
      this.record = row
      this.visible = true
    }
  }
}
</script>

<style scoped>

</style>
