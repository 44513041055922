<!--
 * @Description: 工作区 -> 图文复合组件 一行一个
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2022-03-03 11:45:07
-->

<template>
  <section :style="{ border: `1px solid ${detail.borderColor}`, background: detail.bgColor }">
    <img :src="imgInfo.pureImageUrl || defaultImg" alt="" />
    <div>
      <div class="title">
        <h3 :style="{ color: titleInfo.fontColor }">{{ titleInfo.content }}</h3>
        <p :style="{ color: descInfo.fontColor }">{{ descInfo.descType === 'text' ? descInfo.content : descInfo.content }}</p>
      </div>
      <div class="button">
        <el-button
          :style="{
            background: buttonInfo.btnBgColorTheme,
            color: buttonInfo.fontColor,
            border: `1px solid ${buttonInfo.btnBorderColorTheme}`,
            fontWeight: buttonInfo.btnFontType === '0' ? 'normal' : 'bold'
          }"
        >
          {{ buttonInfo.btnTitle }}
        </el-button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dataInfo: this.detail.layoutItems.componentItem[0],
      imgInfo: this.detail.layoutItems.componentItem[0].layoutItems.componentItem[0],
      titleInfo: this.detail.layoutItems.componentItem[0].layoutItems.componentItem[1].layoutItems.componentItem[0],
      descInfo: this.detail.layoutItems.componentItem[0].layoutItems.componentItem[1].layoutItems.componentItem[1],
      buttonInfo: this.detail.layoutItems.componentItem[0].layoutItems.componentItem[1].layoutItems.componentItem[2]
    }
  },

  watch: {
    detail: {
      handler(value) {
        this.descInfo = this.detail.layoutItems.componentItem[0].layoutItems.componentItem[1].layoutItems.componentItem[1]
        this.buttonInfo = this.detail.layoutItems.componentItem[0].layoutItems.componentItem[1].layoutItems.componentItem[2]
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
p,
h3 {
  margin: 0;
  padding: 0;
}
section {
  padding: 10px;
  display: flex;
  height: 140px;
  img {
    width: 120px;
    height: 120px;
  }
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
    .title {
      p {
        color: rgb(177, 174, 174);
        font-size: 14px;
      }
    }
  }
}
</style>
