var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "search",
          staticClass: "search-form search-form-4",
          attrs: { inline: true, model: _vm.search },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "元素名称", prop: "name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请输入元素名称" },
                        model: {
                          value: _vm.search.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "name", $$v)
                          },
                          expression: "search.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "基础类型", prop: "baseType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.search.baseType,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "baseType", $$v)
                            },
                            expression: "search.baseType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: "" },
                          }),
                          _vm._l(_vm.baseTypes, function (item) {
                            return _c("el-option", {
                              key: item.key,
                              attrs: { label: item.value, value: item.key },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "元素类型", prop: "typeId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.search.typeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "typeId", $$v)
                            },
                            expression: "search.typeId",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: "" },
                          }),
                          _vm._l(_vm.elementTypes, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", round: "" },
                          on: { click: _vm.onSubmit },
                        },
                        [_vm._v("查 询")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", round: "" },
                          on: {
                            click: function ($event) {
                              return _vm.resetForm("ruleForm")
                            },
                          },
                        },
                        [_vm._v("重 置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mb-15", staticStyle: { "text-align": "left" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-plus", round: "" },
              on: {
                click: function ($event) {
                  return _vm.handleAddElement("")
                },
              },
            },
            [_vm._v(" 新建元素 ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", round: "" },
              on: { click: _vm.handleElementType },
            },
            [_vm._v(" 元素类型 ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.list.loading,
                  expression: "list.loading",
                },
              ],
              staticClass: "list-table",
              class: { "no-data": !_vm.list.data || !_vm.list.data.length },
              staticStyle: { width: "100%" },
              attrs: { data: _vm.list.data, stripe: "" },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "序号", width: "50" },
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "元素名称", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { prop: "baseType", label: "基础类型", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("filterBaseType")(row.baseType)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "typeName", label: "元素类型", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { prop: "url", label: "预览", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.baseType === "IMG"
                          ? _c(
                              "el-popover",
                              {
                                attrs: { placement: "right", trigger: "hover" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "element-pop-img" },
                                  [
                                    _c("el-image", {
                                      attrs: { src: row.url, fit: "contain" },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "element-img",
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [
                                    _c("el-image", {
                                      attrs: {
                                        src: row.url,
                                        fit: "contain",
                                        lazy: "",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        row.baseType === "VIDEO"
                          ? _c(
                              "el-popover",
                              {
                                attrs: { placement: "right", trigger: "hover" },
                              },
                              [
                                _c("div", { staticClass: "element-pop-img" }, [
                                  _c("video", {
                                    attrs: { src: row.url, controls: "" },
                                  }),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "element-img",
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [_c("video", { attrs: { src: row.url } })]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "createBy", label: "创建人", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "创建时间",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleAddElement(row)
                              },
                            },
                          },
                          [
                            _c(
                              "el-tooltip",
                              { attrs: { content: "编辑", placement: "top" } },
                              [
                                _c("i", {
                                  staticClass: "el-icon-edit",
                                  staticStyle: { "font-size": "16px" },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "red",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDeleteElement(row.id)
                              },
                            },
                          },
                          [
                            _c(
                              "el-tooltip",
                              { attrs: { content: "删除", placement: "top" } },
                              [
                                _c("i", {
                                  staticClass: "el-icon-delete",
                                  staticStyle: { "font-size": "16px" },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "page" },
            [
              _c("paginations", {
                attrs: {
                  total: _vm.list.total,
                  page: _vm.list.pageNum,
                  "page-sizes": [5, 10, 15, 20],
                  limit: _vm.list.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.list, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.list, "pageSize", $event)
                  },
                  pagination: _vm.getPage,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("element-type", {
        ref: "elementTypeDialog",
        on: { refresh: _vm.handleRefresh },
      }),
      _c("element-library", {
        ref: "elementLibraryDialog",
        on: { confirm: _vm.getPage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }