var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "300px" } },
    [
      _c("div", { staticClass: "creativityBaseInfo" }, [
        _c(
          "p",
          [
            _c("span", [_vm._v("创意展现: ")]),
            _c("el-tag", [
              _vm._v(
                _vm._s(
                  _vm.creativityData.creativeDisplayType ===
                    "CREATIVE_DISPLAY_TYPE_INTELLIGENCE"
                    ? "优选模式"
                    : "轮播模式"
                )
              ),
            ]),
          ],
          1
        ),
        _c(
          "p",
          [
            _c("span", [_vm._v("自动衍生视频创意: ")]),
            _c("el-tag", [
              _vm._v(
                _vm._s(
                  _vm.creativityData.autoDerivedCreativeEnabled
                    ? "开启"
                    : "关闭"
                )
              ),
            ]),
          ],
          1
        ),
        _c("p", [
          _c("span", [_vm._v("创意形式: ")]),
          _c(
            "span",
            [
              _c("el-tag", [
                _vm._v(
                  _vm._s(
                    _vm.creativeType[_vm.creativityData.adcreativeTemplateId]
                  ) + " "
                ),
              ]),
            ],
            1
          ),
        ]),
        _c("p", [
          _c("span", [_vm._v("按钮文案: ")]),
          _c(
            "span",
            [
              _c("el-tag", [
                _vm._v(
                  _vm._s(
                    _vm.linkNameTypeMap[_vm.creativityData.linkNameType] ||
                      " 无 "
                  )
                ),
              ]),
            ],
            1
          ),
        ]),
        _c(
          "p",
          [
            _c("span", [_vm._v("落地页类型: ")]),
            _c("el-tag", [
              _vm._v(
                _vm._s(
                  _vm.creativityData.pageType === "PAGE_TYPE_CANVAS_WECHAT"
                    ? "原生落地页"
                    : "阅宝落地页"
                )
              ),
            ]),
          ],
          1
        ),
        _c(
          "p",
          [
            _c("span", [_vm._v("创意名称: ")]),
            _c("el-tag", [_vm._v(_vm._s(_vm.creativityData.adcreativeName))]),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "创意基础信息",
            visible: _vm.creativityVisible,
            width: "800px",
            "modal-append-to-body": false,
          },
          on: { close: _vm.handleClose },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: { model: _vm.ruleForm, "label-width": "140px" },
                },
                [
                  _c("h3", [_vm._v("创意形式")]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "创意展现：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.ruleForm.creativeDisplayType,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "creativeDisplayType", $$v)
                            },
                            expression: "ruleForm.creativeDisplayType",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: {
                                label: "CREATIVE_DISPLAY_TYPE_INTELLIGENCE",
                              },
                            },
                            [_vm._v("优选模式")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "CREATIVE_DISPLAY_TYPE_AVERAGE" },
                            },
                            [_vm._v("轮播模式")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "自动衍生视频创意：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.ruleForm.autoDerivedCreativeEnabled,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "autoDerivedCreativeEnabled",
                                $$v
                              )
                            },
                            expression: "ruleForm.autoDerivedCreativeEnabled",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("关闭"),
                          ]),
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("开启"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "创意形式：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.adcreativeTemplateIdChange },
                          model: {
                            value: _vm.ruleForm.adcreativeTemplateId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleForm,
                                "adcreativeTemplateId",
                                $$v
                              )
                            },
                            expression: "ruleForm.adcreativeTemplateId",
                          },
                        },
                        _vm._l(
                          _vm.adcreativeTemplateTypImg[_vm.taskSiteSet],
                          function (item) {
                            return _c(
                              "el-radio",
                              {
                                key: item.label,
                                class: item.class,
                                attrs: { label: item.label },
                              },
                              [_vm._v(_vm._s(item.text))]
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "落地页：" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.changePageType },
                          model: {
                            value: _vm.ruleForm.pageType,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "pageType", $$v)
                            },
                            expression: "ruleForm.pageType",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            { attrs: { label: "PAGE_TYPE_CANVAS_WECHAT" } },
                            [_vm._v("原生落地页")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.ruleForm.pageType === "PAGE_TYPE_CANVAS_WECHAT" &&
                  _vm.taskSiteSetStr.includes("a")
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "使用顶部素材：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.overrideCanvasHeadOption,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleForm,
                                    "overrideCanvasHeadOption",
                                    $$v
                                  )
                                },
                                expression: "ruleForm.overrideCanvasHeadOption",
                              },
                            },
                            [
                              _c(
                                "el-radio",
                                {
                                  attrs: {
                                    label: "OPTION_CANVAS_OVERRIDE_CREATIVE",
                                    disabled: _vm.taskCombine === 2,
                                  },
                                },
                                [_vm._v("使用")]
                              ),
                              _c(
                                "el-radio",
                                {
                                  attrs: {
                                    label: "OPTION_CREATIVE_OVERRIDE_CANVAS",
                                  },
                                },
                                [_vm._v("不使用")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("h3", [_vm._v("创意内容")]),
                  _vm.taskSiteSet === 6
                    ? [
                        _c(
                          "el-form-item",
                          { attrs: { label: "浮层卡片" } },
                          [
                            _c(
                              "el-select",
                              {
                                on: { change: _vm.handleChangeCardType },
                                model: {
                                  value: _vm.floatingCard.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.floatingCard, "type", $$v)
                                  },
                                  expression: "floatingCard.type",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "立即创建", value: 0 },
                                }),
                                _c("el-option", {
                                  attrs: { label: "选择已有", value: 1 },
                                }),
                              ],
                              1
                            ),
                            _vm.floatingCard.type === 1
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: { type: "primary", plain: "" },
                                    on: { click: _vm.handleFloatingCard },
                                  },
                                  [_vm._v("立即选择")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm.floatingCard.type === 0 ||
                        (_vm.floatingCard.type === 1 &&
                          _vm.floatingCard.floatingZoneImageId)
                          ? _c(
                              "div",
                              { staticClass: "tuiguang" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "创意图片",
                                      prop: "imageUrl",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-upload",
                                      {
                                        staticClass: "avatar-uploader",
                                        attrs: {
                                          action: _vm.UPLOAD_URL,
                                          "show-file-list": false,
                                          disabled: _vm.floatingCard.type === 1,
                                          accept: ".JPG,.JPEG,.PNG,.BMP",
                                          "before-upload": (file) =>
                                            _vm.beforeAvatarUpload(file),
                                          "on-success": (res) =>
                                            _vm.handleAvatarSuccess(res),
                                        },
                                      },
                                      [
                                        _vm.floatingCard.floatingZoneImageId
                                          ? _c("img", {
                                              staticClass: "avatar",
                                              staticStyle: {
                                                border: "1px solid #ccc",
                                                "border-radius": "4px",
                                              },
                                              attrs: {
                                                src: _vm.floatingCard
                                                  .floatingZoneImageId,
                                              },
                                            })
                                          : _c("i", {
                                              staticClass:
                                                "el-icon-plus avatar-uploader-icon",
                                            }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          color: "#999",
                                          "font-size": "12px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "尺寸：800px*800px, JPG、JPEG、PNG、BMP, 大小：400KB以内"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "文案（1-10字）",
                                      prop: "floatingZoneName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "300px" },
                                      attrs: {
                                        placeholder: "请输入文案（1-10字）",
                                        disabled: _vm.floatingCard.type === 1,
                                        maxlength: "10",
                                        "show-word-limit": "",
                                      },
                                      model: {
                                        value:
                                          _vm.floatingCard.floatingZoneName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.floatingCard,
                                            "floatingZoneName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "floatingCard.floatingZoneName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "文案（1-14字）",
                                      prop: "floatingZoneDesc",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "300px" },
                                      attrs: {
                                        placeholder: "请输入文案（1-14字）",
                                        disabled: _vm.floatingCard.type === 1,
                                        maxlength: "14",
                                        "show-word-limit": "",
                                      },
                                      model: {
                                        value:
                                          _vm.floatingCard.floatingZoneDesc,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.floatingCard,
                                            "floatingZoneDesc",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "floatingCard.floatingZoneDesc",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "按钮文案" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "300px" },
                                        attrs: {
                                          placeholder: "请选择",
                                          disabled: _vm.floatingCard.type === 1,
                                        },
                                        model: {
                                          value:
                                            _vm.floatingCard
                                              .floatingZoneButtonText,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.floatingCard,
                                              "floatingZoneButtonText",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "floatingCard.floatingZoneButtonText",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-option",
                                          {
                                            attrs: {
                                              value: "MORE_INFO",
                                              label: "了解更多",
                                            },
                                          },
                                          [_vm._v("了解更多")]
                                        ),
                                        _c(
                                          "el-option",
                                          {
                                            attrs: {
                                              value: "BUY_NOW",
                                              label: "立即购买",
                                            },
                                          },
                                          [_vm._v("立即购买")]
                                        ),
                                        _c(
                                          "el-option",
                                          {
                                            attrs: {
                                              value: "RESERVE_NOW",
                                              label: "立即预约",
                                            },
                                          },
                                          [_vm._v("立即预约")]
                                        ),
                                        _c(
                                          "el-option",
                                          {
                                            attrs: {
                                              value: "CONSULT_NOW",
                                              label: "立即咨询",
                                            },
                                          },
                                          [_vm._v("立即咨询")]
                                        ),
                                        _c(
                                          "el-option",
                                          {
                                            attrs: {
                                              value: "APPLY_NOW",
                                              label: "立即申请",
                                            },
                                          },
                                          [_vm._v("立即申请")]
                                        ),
                                        _c(
                                          "el-option",
                                          {
                                            attrs: {
                                              value: "BOOK_NOW",
                                              label: "立即预定",
                                            },
                                          },
                                          [_vm._v("立即预定")]
                                        ),
                                        _c(
                                          "el-option",
                                          {
                                            attrs: {
                                              value: "GET_IT_NOW",
                                              label: "立即领取",
                                            },
                                          },
                                          [_vm._v("立即领取")]
                                        ),
                                        _c(
                                          "el-option",
                                          {
                                            attrs: {
                                              value: "TRY_NOW",
                                              label: "立即体验",
                                            },
                                          },
                                          [_vm._v("立即体验")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          { attrs: { label: "保存至视频号" } },
                          [
                            _c(
                              "el-select",
                              {
                                model: {
                                  value: _vm.floatingCard.isSaveVideoAccount,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.floatingCard,
                                      "isSaveVideoAccount",
                                      $$v
                                    )
                                  },
                                  expression: "floatingCard.isSaveVideoAccount",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { value: true, label: "是" },
                                }),
                                _c("el-option", {
                                  attrs: { value: false, label: "否" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : [
                        _c(
                          "el-form-item",
                          { attrs: { label: "品牌形象：" } },
                          [
                            _c(
                              "el-select",
                              { attrs: { value: "品牌简介页" } },
                              [
                                _c(
                                  "el-option",
                                  {
                                    attrs: {
                                      value: "品牌简介页",
                                      label: "品牌简介页",
                                    },
                                  },
                                  [_vm._v("品牌简介页")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "行动按钮：" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { disabled: _vm.taskSiteSet !== 1 },
                                on: { change: _vm.changeActive },
                                model: {
                                  value: _vm.activeButton,
                                  callback: function ($$v) {
                                    _vm.activeButton = $$v
                                  },
                                  expression: "activeButton",
                                },
                              },
                              [
                                _c(
                                  "el-option",
                                  { attrs: { value: true, label: "开启" } },
                                  [_vm._v("开启")]
                                ),
                                _c(
                                  "el-option",
                                  { attrs: { value: false, label: "关闭" } },
                                  [_vm._v("关闭")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.activeButton
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "按钮文案：",
                                  prop: "linkNameType",
                                  rules: {
                                    required: true,
                                    message: "请选择按钮文案",
                                    trigger: "change",
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { placeholder: "请选择" },
                                    model: {
                                      value: _vm.ruleForm.linkNameType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "linkNameType",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.linkNameType",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-option",
                                      {
                                        attrs: {
                                          value: "VIEW_DETAILS",
                                          label: "查看详情",
                                        },
                                      },
                                      [_vm._v("查看详情")]
                                    ),
                                    _c(
                                      "el-option",
                                      {
                                        attrs: {
                                          value: "MORE_INFO",
                                          label: "了解更多",
                                        },
                                      },
                                      [_vm._v("了解更多")]
                                    ),
                                    _c(
                                      "el-option",
                                      {
                                        attrs: {
                                          value: "BUY_NOW",
                                          label: "立即购买",
                                        },
                                      },
                                      [_vm._v("立即购买")]
                                    ),
                                    _c(
                                      "el-option",
                                      {
                                        attrs: {
                                          value: "RESERVE_NOW",
                                          label: "立即预约",
                                        },
                                      },
                                      [_vm._v("立即预约")]
                                    ),
                                    _c(
                                      "el-option",
                                      {
                                        attrs: {
                                          value: "CONSULT_NOW",
                                          label: "立即咨询",
                                        },
                                      },
                                      [_vm._v("立即咨询")]
                                    ),
                                    _c(
                                      "el-option",
                                      {
                                        attrs: {
                                          value: "APPLY_NOW",
                                          label: "立即申请",
                                        },
                                      },
                                      [_vm._v("立即申请")]
                                    ),
                                    _c(
                                      "el-option",
                                      {
                                        attrs: {
                                          value: "BOOK_NOW",
                                          label: "立即预定",
                                        },
                                      },
                                      [_vm._v("立即预定")]
                                    ),
                                    _c(
                                      "el-option",
                                      {
                                        attrs: {
                                          value: "GET_COUPONS",
                                          label: "领取优惠",
                                        },
                                      },
                                      [_vm._v("领取优惠")]
                                    ),
                                    _c(
                                      "el-option",
                                      {
                                        attrs: {
                                          value: "GET_IT_NOW",
                                          label: "立即领取",
                                        },
                                      },
                                      [_vm._v("立即领取")]
                                    ),
                                    _c(
                                      "el-option",
                                      {
                                        attrs: {
                                          value: "GO_SHOPPING",
                                          label: "去逛逛",
                                        },
                                      },
                                      [_vm._v("去逛逛")]
                                    ),
                                    _c(
                                      "el-option",
                                      {
                                        attrs: {
                                          value: "TRY_NOW",
                                          label: "立即体验",
                                        },
                                      },
                                      [_vm._v("立即体验")]
                                    ),
                                    _c(
                                      "el-option",
                                      {
                                        attrs: {
                                          value: "BUY_ASAP",
                                          label: "立即抢购",
                                        },
                                      },
                                      [_vm._v("立即抢购")]
                                    ),
                                    _c(
                                      "el-option",
                                      {
                                        attrs: {
                                          value: "SETUP_NOW",
                                          label: "立即开通",
                                        },
                                      },
                                      [_vm._v("立即开通")]
                                    ),
                                    _c(
                                      "el-option",
                                      {
                                        attrs: {
                                          value: "SECKILL_NOW",
                                          label: "立即秒杀",
                                        },
                                      },
                                      [_vm._v("立即秒杀")]
                                    ),
                                    _c(
                                      "el-option",
                                      {
                                        attrs: {
                                          value: "WATCH_LIVE",
                                          label: "观看直播",
                                        },
                                      },
                                      [_vm._v("观看直播")]
                                    ),
                                    _c(
                                      "el-option",
                                      {
                                        attrs: {
                                          value: "ENTER_MINI_PROGRAM",
                                          label: "进入小程序",
                                        },
                                      },
                                      [_vm._v("进入小程序")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.activeButton &&
                        [1, 12, 13, 14, 123, 124, 134, 1234].includes(
                          _vm.taskSiteSet
                        )
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "跳转落地页：" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    model: {
                                      value: _vm.ruleForm.linkPageType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "linkPageType",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.linkPageType",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: {
                                        value: "LINK_PAGE_TYPE_CANVAS_WECHAT",
                                        label: "原生落地页",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        [1, 3, 34].includes(_vm.taskSiteSet)
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "数据展示：" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    on: { change: _vm.changeConversion },
                                    model: {
                                      value: _vm.isConversion,
                                      callback: function ($$v) {
                                        _vm.isConversion = $$v
                                      },
                                      expression: "isConversion",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-option",
                                      { attrs: { value: true, label: "开启" } },
                                      [_vm._v("开启")]
                                    ),
                                    _c(
                                      "el-option",
                                      {
                                        attrs: { value: false, label: "关闭" },
                                      },
                                      [_vm._v("关闭")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isConversion
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "数据类型：",
                                  rules: {
                                    required: true,
                                    message: "请选择数据类型",
                                    trigger: "change",
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    model: {
                                      value: _vm.ruleForm.conversionDataType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "conversionDataType",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.conversionDataType",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-option",
                                      {
                                        attrs: {
                                          value: "CONVERSION_DATA_ADMETRIC",
                                          label: "转化数据量",
                                        },
                                      },
                                      [_vm._v("转化数据量")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isConversion
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "转化行为：",
                                  rules: {
                                    required: true,
                                    message: "请选择转化行为",
                                    trigger: "change",
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    model: {
                                      value: _vm.ruleForm.conversionTargetType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "conversionTargetType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "ruleForm.conversionTargetType",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-option",
                                      {
                                        attrs: {
                                          value: "CONVERSION_TARGET_GET",
                                          label: "领取",
                                        },
                                      },
                                      [_vm._v("领取")]
                                    ),
                                    _c(
                                      "el-option",
                                      {
                                        attrs: {
                                          value: "CONVERSION_TARGET_RESERVE",
                                          label: "预约",
                                        },
                                      },
                                      [_vm._v("预约")]
                                    ),
                                    _c(
                                      "el-option",
                                      {
                                        attrs: {
                                          value: "CONVERSION_TARGET_BUY",
                                          label: "购买",
                                        },
                                      },
                                      [_vm._v("购买")]
                                    ),
                                    _c(
                                      "el-option",
                                      {
                                        attrs: {
                                          value: "CONVERSION_TARGET_APPLY",
                                          label: "申请",
                                        },
                                      },
                                      [_vm._v("申请")]
                                    ),
                                    _c(
                                      "el-option",
                                      {
                                        attrs: {
                                          value: "CONVERSION_TARGET_CONSULT",
                                          label: "咨询",
                                        },
                                      },
                                      [_vm._v("咨询")]
                                    ),
                                    _vm.taskSiteSet !== 1
                                      ? _c(
                                          "el-option",
                                          {
                                            attrs: {
                                              value:
                                                "CONVERSION_TARGET_DOWNLOAD",
                                              label: "下载",
                                            },
                                          },
                                          [_vm._v("下载")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "创意名称：",
                              prop: "adcreativeName",
                              rules: {
                                required: true,
                                message: "请输入创意名称",
                              },
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "400px" },
                              attrs: {
                                rows: "4",
                                type: "textarea",
                                placeholder: "请输入创意名称",
                                maxlength: "60",
                                "show-word-limit": "",
                              },
                              model: {
                                value: _vm.ruleForm.adcreativeName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "adcreativeName", $$v)
                                },
                                expression: "ruleForm.adcreativeName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          model: {
                            value: _vm.ruleForm.saveAsPreset,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "saveAsPreset", $$v)
                            },
                            expression: "ruleForm.saveAsPreset",
                          },
                        },
                        [_vm._v("存为预设")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeCreativity } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("floating-card-dialog", {
        ref: "floatingCard",
        on: { select: _vm.confirmFloatingCard },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }