<!--
 * @Description: 腾讯广告程序化
 * @Date: 2021-12-06 09:32:07
 * @LastEditTime: 2022-03-09 15:07:19
-->
<template>
  <div>
    <!-- 列表查询 -->
    <el-form ref="search" :inline="true" :model="search" class="search-form search-form-4">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="任务名称" prop="taskName">
            <el-input v-model="search.taskName" placeholder="请输入任务名称"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="创建人" prop="createBy">
            <el-input v-model="search.createBy" placeholder="请输入创建人"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="提交状态" prop="taskStatus">
            <el-select v-model="search.taskStatus">
              <el-option label="全部" value=""></el-option>
              <el-option label="已匹配" value="2"></el-option>
              <el-option label="未匹配" value="3"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="创建时间" prop="createTime">
            <el-date-picker v-model="search.createTime" type="daterange" value-format="yyyy-MM-dd" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" round @click="onSubmit">查询</el-button>
            <el-button type="primary" round @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- 表格 -->

    <div style="text-align:left" class="mb-15">
      <router-link :to="{ name: 'createTencentAmountAd', params: { username: userInfo.username } }" tag="span">
        <el-button type="primary" icon="el-icon-plus" round>
          新建任务
        </el-button>
      </router-link>
    </div>
    <TablePane :data-source="dataSource" @getPage="getPage"> </TablePane>

    <el-dialog title="任务详情" :visible.sync="descVisible" width="1200px" :modal-append-to-body="false" destroy-on-close @close="closDesc">
      <span>
        <el-descriptions>
          <el-descriptions-item label="任务名称">{{ dataSource_data_info.taskName }}</el-descriptions-item>
          <el-descriptions-item label="项目">{{ dataSource_data_info.projectName }}</el-descriptions-item>
          <el-descriptions-item label="产品">{{ dataSource_data_info.productName }}</el-descriptions-item>
          <el-descriptions-item label="提交广告数">{{ dataSource_data_info.adSum }}</el-descriptions-item>
          <el-descriptions-item label="创建人">{{ dataSource_data_info.createBy }}</el-descriptions-item>
          <el-descriptions-item label="创建时间">{{ dataSource_data_info.createTime }}</el-descriptions-item>
        </el-descriptions>
        <el-button type="primary" style="margin-top: 30px; margin-right: 20px" size="mini" round @click="retryTask">重新提交</el-button>
        <el-checkbox v-model="showErrorFlag" @change="showError"> 仅查看失败</el-checkbox>
        <TablePane :data-source="dataSource_desc" @getPage="getDescPage"> </TablePane>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Page, getGenerate, deleteTask, publishTask } from '@/network/api/advManagement/advManagementTencent'
import { getDateStartEnd } from '@/utils/ruoyi'
// import moment from 'moment'
import TablePane from '@/components/tablePane.vue'
import { mapGetters } from 'vuex'
import store from '@/store/index'

export default {
  components: {
    TablePane
  },
  data() {
    return {
      search: {
        taskName: null,
        createTime: null,
        createBy: null,
        taskStatus: null
      },
      descVisible: false,
      showErrorFlag: false,
      descData: [],
      dataSource_data: [],
      dataSource_data_info: {},
      dataSource: {
        data: [], // 表格数据
        cols: [
          {
            label: '任务名称',
            prop: 'taskName'
          },
          {
            label: '创建时间',
            prop: 'createTime'
          },
          {
            label: '创建人',
            prop: 'createBy'
          },
          {
            label: '项目',
            prop: 'projectName'
          },
          {
            label: '产品',
            prop: 'productName',
            render: (record) => <p>{record.productName || '不限'}</p>
          },
          {
            label: '投放账户',
            prop: 'accountNames'
          },
          {
            label: '推广目标',
            render: (record) => <p>收集销售线索</p>
          },
          {
            label: '提交广告数',
            render: (record) => <p>{record.adSum || '-'}</p>
          },
          {
            label: '状态',
            width: 130,
            render: (record) => {
              return (
                /*eslint-disable*/
                <div class="status">
                  <p style="font-size: 11px">{record.taskStatusCn}</p>
                  <div class="status-num">
                    <p style="font-size: 11px">
                      成功：<span style="color:green">{record.successSum}</span>
                    </p>
                    <p style="font-size: 11px">
                      失败：<span style="color:red">{record.failSum}</span>
                    </p>
                  </div>
                </div>
                /*eslint-disable*/
              )
            }
          }
        ], // 表格的列数据
        isIndex: true, // 列表序号
        loading: false, // loading
        isOperation: true,
        pageData: {
          total: 0 // 总条数
        },
        handleSelectionChange() {},
        select() {},
        selectable() {},
        selectAll() {},
        operation: {
          // 表格有操作列时设置
          label: '操作', // 列名
          data: [
            {
              tooltip: '继续 ', // 操作名称
              icon: 'el-icon-right', // 按钮类型
              showLabel: 'taskStatusCn',
              showValue: this.isManager() ? ['待提交', '配置中', '提交成功'] : ['待提交', '配置中'],
              handleRow: async (index, record) => {
                this.$router.push({ name: 'editTencentAmountAd', params: { taskId: record.taskId } })
              } // 自定义事件
            },
            {
              tooltip: '详情 ', // 操作名称
              icon: 'el-icon-document', // 按钮类型
              showLabel: 'taskStatusCn',
              showValue: ['提交中', '提交成功'],
              handleRow: async (index, record) => {
                this.descVisible = true
                this.dataSource_data_info = record
                this.getDescPage({ taskId: record.taskId, status: true })
              } // 自定义事件
            },
            // {
            //   label: '复制', // 操作名称
            //   showLabel: 'status',
            //   type: 'text', // 按钮类型
            //   showValue: ['提交完成'],
            //   handleRow: async (index, record) => {}
            // },
            {
              tooltip: '删除', // 操作名称
              icon: 'el-icon-delete', // 按钮类型
              color: '#ff0000',
              handleRow: async (index, record) => {
                const { code } = await deleteTask(record.taskId)
                if (code === 200) {
                  this.dataSource.data.splice(index, 1)
                  this.$message.success('删除成功!')
                  this.getPage()
                }
              }
            }
          ]
        },
      },
      dataSource_desc: {
        data: [], // 表格数据
        cols: [
          {
            label: '任务Id',
            prop: 'taskId',
            width: 70
          },
          {
            label: '账户Id',
            prop: 'taskAccountId',
            width: 70
          },
          {
            label: '计划名称',
            prop: 'campaignName'
          },
          {
            label: '计划ID',
            prop: 'campaignId'
          },
          {
            label: '广告名称',
            prop: 'adgroupName'
          },
          {
            label: '广告ID',
            prop: 'adgroupId'
          },
          {
            label: '创意名称',
            prop: 'adcreativeName'
          },
          {
            label: '创意ID',
            prop: 'adcreativeId'
          },
          {
            label: '状态',
            // eslint-disable-next-line
            render: (record) => {
              return (
                <div>
                  {record.code === 200 ? (
                    <el-tag type="success">成功</el-tag>
                  ) : (
                    <el-tooltip class="item" effect="dark" placement="top">
                      <template slot="content">{record.message}</template>
                      <el-tag type="danger">失败</el-tag>
                    </el-tooltip>
                  )}
                </div>
              )
            }
          }
        ], // 表格的列数据
        isIndex: false, // 列表序号
        loading: false, // loading
        isOperation: false,
        pageData: {
          total: 0 // 总条数
        },
        handleSelectionChange() {},
        select() {},
        selectable() {},
        selectAll() {},
        // operation: {
        //   // 表格有操作列时设置
        //   label: '操作', // 列名
        //   width: '60', // 根据实际情况给宽度
        //   data: [
        //     {
        //       label: '重新提交', // 操作名称
        //       type: 'text', // 按钮类型
        //       showLabel: 'code',
        //       showNotValue: [200, 500],
        //       handleRow: async (index, record) => {
        //         const { code } = await againPublishTask(record.taskId, record.accountCampaingsId)
        //         if (code === 200) {
        //           this.$message.success('正在重新上传！')
        //         }
        //       } // 自定义事件
        //     },
        //     {
        //       label: '-', // 操作名称
        //       type: 'text', // 按钮类型
        //       showLabel: 'code',
        //       showValue: [200, 500]
        //     }
        //   ]
        // }
      }
    }
  },

  computed: {
    ...mapGetters(['userInfo'])
  },

  mounted() {
    this.getPage()
  },
  methods: {
    /**
     * @description: 获取列表
     * @param {*} params
     */
    getPage(params = { pageNum: 1, pageSize: 10 }) {
      this.dataSource.loading = true
      const [start_createTime, end_createTime] = getDateStartEnd(this.search.createTime)
      let search = {
        ...this.search,
        selectStartTime: start_createTime,
        selectEndTime: end_createTime
      }
      delete search.createTime
      Page({ ...search, ...params }).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          this.dataSource.data = records
          this.dataSource_data = records
          this.dataSource.pageData.total = total
          this.dataSource.pageData.pageNum = params.pageNum
          this.dataSource.loading = false
        }
      })
    },

    async getDescPage({ taskId = this.dataSource_data_info?.taskId || 0, status = this.showErrorFlag ? false : true, pageNum = 1, pageSize = 10 }) {
      const { code, data } = await getGenerate({ taskId, status, pageNum, pageSize })
      if (code === 200) {
        const { records, total } = data
        this.dataSource_desc.data = records
        this.dataSource_data = records
        this.dataSource_desc.pageData.total = total
        this.dataSource_desc.pageData.pageNum = pageNum
      }
    },

    /**
     * @description:查看失败数据
     */
    showError(flag) {
      let data = [...this.dataSource_data]
      if (flag) {
        this.getDescPage({ taskId: this.dataSource_data_info.taskId, status: false })
        // this.dataSource_desc.data = data.filter((item) => ![200, 500].includes(item.code))
      } else {
        this.dataSource_desc.data = data
      }
    },

    // 重新提交任务
    async retryTask() {
      const { code } = await publishTask(this.dataSource_data_info.taskId)
      if (code === 200) {
        this.$message.success('已提交!')
        this.descVisible = false
        this.showErrorFlag = false
        this.dataSource_data_info = {}
        this.getPage()
      }
    },

    closDesc() {
      this.descVisible = false
      this.showErrorFlag = false
      this.dataSource_data_info = {}
    },

    // 超级管理员
    isManager() {
      const userInfos = store.getters.userInfo
      if (userInfos) {
        const roles = userInfos?.roles.split(',')
        return roles.includes("10001000")
      }
      return false
    },

    /**
     * @description: 重置
     */
    resetForm() {
      this.$refs['search'].resetFields()
      this.getPage()
    },

    /**
     * @description: 查询
     */
    onSubmit() {
      this.getPage()
    }
  }
}
</script>

<style lang="scss" scoped>
.status {
  p {
    padding: 0;
    margin: 0;
  }
  .status-num {
    display: flex;
    justify-content: space-between;
    p {
      font-size: 13px;
      color: rgb(153, 153, 153);
    }
  }
}
</style>
