var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.isEdit ? "编 辑" : "新 增",
        width: "580px",
        visible: _vm.visible,
        "before-close": _vm.close,
        "label-position": "right",
        "modal-append-to-body": false,
        "close-on-press-escape": false,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ent",
          attrs: { model: _vm.ent, rules: _vm.rules, "label-width": "110px" },
        },
        [
          _vm.type === "type"
            ? _c(
                "el-form-item",
                { attrs: { label: "产品品类", prop: "productTypeId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        "default-first-option": "",
                        placeholder: "请选择",
                      },
                      on: { change: _vm.productType },
                      model: {
                        value: _vm.ent.productTypeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.ent, "productTypeId", $$v)
                        },
                        expression: "ent.productTypeId",
                      },
                    },
                    _vm._l(_vm.typeList, function (item) {
                      return _c("el-option", {
                        key: item.bsProductTypeId,
                        attrs: {
                          label: item.typeName,
                          value: item.bsProductTypeId,
                        },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml-10",
                      attrs: { type: "text" },
                      on: { click: _vm.typeAdd },
                    },
                    [_c("u", [_vm._v("新增品类")])]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.type === "project"
            ? _c(
                "el-form-item",
                { attrs: { label: "关联项目", prop: "relationProjectIdNew" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        multiple: "",
                        "collapse-tags": "",
                        "default-first-option": "",
                        placeholder: "请选择",
                      },
                      on: { change: _vm.projectChange },
                      model: {
                        value: _vm.ent.relationProjectIdNew,
                        callback: function ($$v) {
                          _vm.$set(_vm.ent, "relationProjectIdNew", $$v)
                        },
                        expression: "ent.relationProjectIdNew",
                      },
                    },
                    _vm._l(_vm.projectList, function (item) {
                      return _c("el-option", {
                        key: item.bsProjectId,
                        attrs: {
                          label: item.projectName,
                          value: item.bsProjectId,
                        },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml-10",
                      attrs: { type: "text" },
                      on: { click: _vm.projectAdd },
                    },
                    [_c("u", [_vm._v("新增项目")])]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.type === "source" || _vm.type === "project"
            ? _c(
                "el-form-item",
                { attrs: { label: "产品来源", prop: "productSourceId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        "default-first-option": "",
                        placeholder: "请选择",
                      },
                      on: { change: _vm.sourceChange },
                      model: {
                        value: _vm.ent.productSourceId,
                        callback: function ($$v) {
                          _vm.$set(_vm.ent, "productSourceId", $$v)
                        },
                        expression: "ent.productSourceId",
                      },
                    },
                    _vm._l(_vm.sourceList, function (item) {
                      return _c("el-option", {
                        key: item.bsProductSourceId,
                        attrs: {
                          label: item.source,
                          value: item.bsProductSourceId,
                        },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml-10",
                      attrs: { type: "text" },
                      on: { click: _vm.sourceAdd },
                    },
                    [_c("u", [_vm._v("新增来源")])]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { attrs: { round: "" }, on: { click: _vm.close } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-popconfirm",
            {
              staticClass: "ml-10",
              attrs: {
                "confirm-button-text": "提交",
                "cancel-button-text": "取消",
                icon: "el-icon-info",
                "icon-color": "red",
                title: "确定提交？",
              },
              on: { confirm: _vm.submit },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { slot: "reference", type: "primary", round: "" },
                  slot: "reference",
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }