<!--
 * @Description: 组件选择区
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2022-04-21 13:35:38
-->

<template>
  <section>
    <div v-for="item in group" :key="item.title" class="widget-box">
      <p class="widget-title">{{ item.title }}</p>
      <ul class="widget-ul">
        <li v-for="widget in item.widgets" :key="widget.type" :class="{ disable: widget.type.indexOf('top') !== -1 && topWidgetsDisable, selected: widget.type === finalTopWidgets.widgetTypeV2 }" @click="setFinalWidgets(widget)">
          <i :class="widget.icon"></i>
          <p>{{ widget.name }}</p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { widgetGalleryGroup } from './widgetGalleryData'
import { widgetMapData } from './indexData'
export default {
  props: {
    topWidgetsDisable: {
      type: Boolean,
      default: false
    },
    finalTopWidgets: {
      type: Object,
      default: () => {}
    },
    finalWidgets: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      group: widgetGalleryGroup,
      topDisable: true
    }
  },

  methods: {
    /**
     * @description: 添加组件到操作区
     * @param {*} item 其中一个组件的数据
     */
    setFinalWidgets(item) {
      const { type } = item
      if (type === 'floatbutton' && this.finalWidgets.some((item) => item.widgetTypeV2 === 'floatbutton')) {
        return this.$message.error('一个落地页只能添加一个悬浮组件')
      }
      const widget = JSON.parse(JSON.stringify(widgetMapData[type]))
      if (type.indexOf('top') !== -1) {
        this.$emit('setFinalTopWidgets', widget)
      } else {
        this.$emit('setFinalWidgets', widget)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.disable {
  pointer-events: none;
  i {
    color: #ccc !important;
  }
  p {
    color: #ccc !important;
  }
}

.selected {
  border: 1px solid #409eff !important;
  i {
    color: #409eff !important;
  }
  p {
    color: #409eff !important;
  }
}
section {
  padding: 20px 10px 30px 10px;
}

.widget-box {
  padding: 10px;
  .widget-title {
    font-size: 18px;
    border-left: 3px solid #409eff;
    padding-left: 10px;
  }
  .widget-ul {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0;
    padding-top: 20px;
    li {
      width: 100px;
      padding: 15px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #f2f2f2;
      margin: 0px 10px;
      border-radius: 6px;
      border: 1px solid #f2f2f2;
      cursor: pointer;
      margin-bottom: 10px;
      i {
        font-size: 22px;
        color: #636161;
      }
      p {
        margin-top: 10px;
        color: #444444;
        font-size: 12px;
      }
      &:hover {
        background: #c9c9c9;
        color: #fff;
      }
    }
  }
}
</style>
