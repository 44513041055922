export var map = new Map()
export class AdvManagementBaiduUnitEnt {
  baiduUnitId
  adgroupFeedId
  campaignFeedId
  baiduAdvPlanId
  adgroupFeedName
  pause
  status
  audience
  bid
  producttypes
  ftypes
  bidtype
  ocpc
  atpfeedid
  deleted
  createBy
  createTime
  updateBy
  updateTime
  remark
}

export var launchType = [
  { name: '百度信息流', value: '1' },
  { name: '好看视频', value: '8' },
  { name: '贴吧', value: '2' },
  { name: '百度小说', value: '64' }

]
// 单元定向
export class CreateUnitAudience {
  search = ['bizArea', 'place']
  searchSetVal = ['bizArea', 'place', 'region']
  mapMutex = new Map()// key:attr val:互斥参数
  mapAttr = new Map()// key:attr val:{'name':'字段名称',display:'是否禁用',show:'是否展示',value:'值'}
  constructor() {
    this.initData()
  }
  // 地域互斥
  regionMutex(attrName) {
    var vl = attrName
    if (attrName === '0') {
      vl = 'region'
    }
    this.searchSetVal.forEach(w => {
      if (w !== vl) {
        this.mapAttr.get(w).value = undefined
      }
    })

    if (attrName === '0' || attrName === 'region') {
      this.search.forEach(x => {
        this.changeMutexDisNoplay(x)
      })
    } else {
      this.search.forEach(x => {
        if (x === attrName) {
          this.changeMutexDisplay(x)
        }
      })
    }
  }
  changeMutexDisNoplay(attrName) {
    // 获取互斥集合

    if (this.mapMutex.has(attrName)) {
      var listMutex = this.mapMutex.get(attrName)
      if (listMutex !== undefined && listMutex !== null && listMutex.length > 0 && listMutex instanceof Array) {
        // 遍历互斥 进行display=true 和 value设置underfunded
        listMutex.forEach(item => {
          var end = false
          if (this.mapMutex.has(item)) {
            var mutex = this.mapMutex.get(item)// 获取互斥对象的互斥集合
            // 验证是否存在其他互斥
            mutex.forEach(x => {
              if (this.mapAttr.has(x) && x !== attrName) {
                var objData = this.mapAttr.get(x)
                if (objData !== undefined) {
                  if (objData.value !== undefined) {
                    end = true
                  }
                }
              }
            })
          }
          if (this.mapAttr.has(item) && !end) {
            var objData = this.mapAttr.get(item)
            if (objData !== undefined) {
              objData.display = false
              objData.value = undefined
            }
          }
        })
      }
    }
  }
  changeMutexDisplay(attrName) {
    // 获取互斥集合
    if (this.mapMutex.has(attrName)) {
      var listMutex = this.mapMutex.get(attrName)
      if (listMutex !== undefined && listMutex !== null && listMutex.length > 0 && listMutex instanceof Array) {
        // 遍历互斥 进行display=true 和 value设置underfunded
        listMutex.forEach(item => {
          if (this.mapAttr.has(item)) {
            var objData = this.mapAttr.get(item)
            if (objData !== undefined) {
              objData.display = true
              objData.value = undefined
            }
          }
        })
      }
    }
  }

  // 根据参数名称互斥禁用
  changeDisplay(attrName, dispaly) {
    // 获取互斥集合
    if (this.mapMutex.has(attrName)) {
      var listMutex = this.mapMutex.get(attrName)
      if (listMutex !== undefined && listMutex !== null && listMutex.length > 0 && listMutex instanceof Array) {
        // 遍历互斥 进行display=true 和 value设置underfunded
        listMutex.forEach(item => {
          if (this.mapAttr.has(item)) {
            var objData = this.mapAttr.get(item)
            objData.display = dispaly
            objData.value = undefined
          }
        })
      }
    }
  }

  // 返回参数对象用来赋值和获取值
  getObjectData(attrName) {
    return this.mapAttr.get(attrName)
  }
  getObjectDataDisplay(attrName) {
    return this.mapAttr.get(attrName).display
  }
  getAgeInitDisplay() {
    var obj = this.mapAttr.get('age').value
    if (obj !== undefined) {
      if (obj === '0' || obj === 0) {
        return 'age0'
      } else {
        return 'ageOther'
      }
    } else {
      var customAge = this.mapAttr.get('customAge').value
      if (customAge !== undefined) {
        return 'customAge'
      }
    }
    return 'age0'
  }
  // 返回当前数据
  getCurrentDataObjdect() {
    var data = {}
    this.mapAttr.forEach((x, y) => {
      if (y.name !== undefined) {
        data[x] = y.name
      }
    })
    return data
  }
  getRegionShow() {
    var retrunData = '0'
    var map = new Map()
    this.mapAttr.forEach((x, y) => {
      if (this.search.indexOf(x) !== -1) {
        map.set(x.y)
      }
    })
    if (map.size === 4) {
      map.forEach((x, y) => {
        if (y.value !== undefined) {
          retrunData = x
        }
      })
    }
    return retrunData
  }

  // 初始化 原生定向设置列表
  initData() {
    this.mapMutex.set('region', [])
    this.mapAttr.set('region', { name: '地域（省市区县）不限', display: false, show: true, value: undefined })

    this.mapMutex.set('bizArea', ['crowd'])
    this.mapAttr.set('bizArea', { name: '预置商圈', display: false, show: true, value: undefined })

    this.mapMutex.set('customArea', ['crowd'])
    this.mapAttr.set('customArea', { name: '自定义商圈', display: false, show: true, value: undefined })

    this.mapMutex.set('place', ['crowd'])
    this.mapAttr.set('place', { name: '场所', display: false, show: true, value: undefined })

    this.mapMutex.set('useractiontype', [])
    this.mapAttr.set('useractiontype', { name: '用户到访类型', display: false, show: true, value: undefined })

    this.mapMutex.set('age', ['customAge'])
    this.mapAttr.set('age', { name: '年龄', display: false, show: true, value: undefined })

    this.mapMutex.set('customAge', [])
    this.mapAttr.set('customAge', { name: '自定义年龄', display: false, show: true, value: undefined })

    this.mapMutex.set('sex', [])
    this.mapAttr.set('sex', { name: '性别', display: false, show: true, value: undefined })

    this.mapMutex.set('lifeStage', [])
    this.mapAttr.set('lifeStage', { name: '人生阶段', display: false, show: true, value: ['0'] })

    this.mapMutex.set('education', [])
    this.mapAttr.set('education', { name: '学历', display: false, show: true, value: undefined })

    this.mapMutex.set('newInterests', ['crowd'])
    this.mapAttr.set('newInterests', { name: '新兴趣（兴趣2.0）', display: false, show: true, value: undefined })

    this.mapMutex.set('keywords', [])
    this.mapAttr.set('keywords', { name: '意图词', display: false, show: true, value: undefined })

    this.mapMutex.set('keywordsExtend', [])
    this.mapAttr.set('keywordsExtend', { name: '意图词用户行为', display: false, show: true, value: undefined })

    this.mapMutex.set('crowd', ['bizArea', 'customArea', 'place', 'newInterests'])
    this.mapAttr.set('crowd', { name: '人群包（定向人群）', display: false, show: true, value: undefined })

    this.mapMutex.set('excludeCrowd', [])
    this.mapAttr.set('excludeCrowd', { name: '人群包（排除人群）', display: false, show: true, value: undefined })

    this.mapMutex.set('excludeTrans', [])
    this.mapAttr.set('excludeTrans', { name: '排除已转化人群', display: false, show: true, value: undefined })

    this.mapMutex.set('device', [])
    this.mapAttr.set('device', { name: '操作系统', display: false, show: true, value: undefined })

    this.mapMutex.set('iosVersion', [])
    this.mapAttr.set('iosVersion', { name: 'iOS系统版本', display: false, show: true, value: undefined })

    this.mapMutex.set('androidVersion', [])
    this.mapAttr.set('androidVersion', { name: 'Android系统版本', display: false, show: true, value: undefined })

    this.mapMutex.set('androidBrands', [])
    this.mapAttr.set('androidBrands', { name: '手机品牌', display: false, show: true, value: undefined })

    this.mapMutex.set('mobilePhonePrice', [])
    this.mapAttr.set('mobilePhonePrice', { name: '手机价格', display: false, show: true, value: undefined })

    this.mapMutex.set('app', [])
    this.mapAttr.set('app', { name: 'APP行为', display: false, show: true, value: undefined })

    this.mapMutex.set('net', [])
    this.mapAttr.set('net', { name: '网络', display: false, show: true, value: undefined })

    this.mapMutex.set('autoExpansion', [])
    this.mapAttr.set('autoExpansion', { name: '自动扩量', display: false, show: true, value: undefined })

    this.mapMutex.set('articleType', [])
    this.mapAttr.set('articleType', { name: '文章分类', display: false, show: true, value: undefined })
  }
}

