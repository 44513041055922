import { render, staticRenderFns } from "./applet.vue?vue&type=template&id=37344be8&scoped=true&"
import script from "./applet.vue?vue&type=script&lang=js&"
export * from "./applet.vue?vue&type=script&lang=js&"
import style0 from "./applet.vue?vue&type=style&index=0&id=37344be8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37344be8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/Data/ad_frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('37344be8')) {
      api.createRecord('37344be8', component.options)
    } else {
      api.reload('37344be8', component.options)
    }
    module.hot.accept("./applet.vue?vue&type=template&id=37344be8&scoped=true&", function () {
      api.rerender('37344be8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/assetManagement/huawei_kuaishou_360/ldy/workbench_component/applet.vue"
export default component.exports