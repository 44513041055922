<template>
  <el-scrollbar class="scroll">
    <a-menu v-model="openKeys" mode="inline" :inline-collapsed="isCollapse" theme="light">
      <template v-for="item in sideMenu[0].children">
        <a-menu-item v-if="item.children.length === 0" :key="item.url">
          <i :class="`iconfont icon-${item.remarks || 'el-dian'} mr-12`" />
          {{ item.menuName }}
        </a-menu-item>
        <a-sub-menu :key="item.url">
          <span slot="title">
            <i :class="`iconfont icon-${item.remarks || 'el-dian'} mr-12`" />
            <span>{{ item.menuName }} </span>
          </span>
          <a-menu-item v-for="itemTwo in item.children" :key="itemTwo.url" @click="titleClick(itemTwo)">
            <router-link tag="div" :to="itemTwo.url" class="align-center">
              <i :class="`iconfont icon-dian`" style="font-size: 22px" />
              {{ itemTwo.menuName }}
            </router-link>
          </a-menu-item>
        </a-sub-menu>
      </template>
    </a-menu>
  </el-scrollbar>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { Menu } from 'ant-design-vue'

export default {
  name: 'SideBar',
  components: {
    'a-menu': Menu,
    'a-sub-menu': Menu.SubMenu,
    'a-menu-item': Menu.Item
  },

  data() {
    return {
      openKeys: ['/home']
    }
  },

  computed: {
    ...mapGetters(['sideMenu', 'breadcrumbMenu', 'isCollapse'])
  },

  watch: {
    $route: {
      handler: function (val) {
        if (val.path.substring(1).indexOf('/') > -1) {
          let path = val.path.substring(1).split('/')[0]
          this.openKeys = [`/${path}`]
        } else {
          this.openKeys = [val.path]
        }
      }
    }
  },

  methods: {
    ...mapMutations('user', ['SET_SIDE_MENU', 'SET_BREADCRUMB_MENU']),

    titleClick(menuItem) {
      let breadcrumbMenu = this.breadcrumbMenu
      let item = {
        url: menuItem.url,
        name: menuItem.menuName
      }
      if (this.breadcrumbMenu.length === 0 || !breadcrumbMenu.some((s) => s.url === menuItem.url)) {
        breadcrumbMenu.push(item)
        this.SET_BREADCRUMB_MENU(breadcrumbMenu)
      }
    }
  }
}
</script>

<style lang="scss">
.scroll {
  background: #ccc;
  border-right: 1px solid #e8e8e8;

  // .ant-menu-inline {
  //   width: 199px;
  // }
  .el-scrollbar__wrap {
    background: #fff;
  }

  .el-scrollbar__view {
    height: 100%;
  }

  .ant-menu {
    height: 100%;
  }

  .ant-menu-inline-collapsed {
    width: 72px;

    .iconfont {
      margin-right: 40px !important;
    }
  }

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: 1px solid transparent !important;
  }
}
</style>

<style scoped lang="scss">
@import '~@/assets/style/mixin.scss';
</style>
