import service from '@/network/service/service'

export function Page(data) {
  return service.post('/api/system/basicSettingProductSource/page', data)
}

export function Add(data) {
  return service.post('/api/system/basicSettingProductSource/save', data)
}
export function getIsUseingData() {
  return service.get('/api/system/basicSettingProductSource/getIsUseingData')
}

export function Update(data) {
  return service.post('/api/system/basicSettingProductSource/update', data)
}

export function Delete(id) {
  return service.delete('/api/system/basicSettingProductSource/' + id)
}

