import service from '@/network/service/service'
// --------- 视频元素库 ------------
/**
 * 获取元素库列表
 */
export const getElementList = (params) => service.post(`/api/system/tool/video/element/page`, params)

/**
 * 编辑元素库
 */
export const updateElement = (params) => service.post(`/api/system/tool/video/element/update`, params)

/**
 * 添加元素库
 */
export const addElement = (params) => service.post(`/api/system/tool/video/element/save`, params)

/**
 * 删除元素库
 */
export const deleteElement = (params) => service.get(`/api/system/tool/video/element/delete`, { params })

// --------- 视频模板库 ------------
/**
 * 获取元素模板列表
 */
export const getElementTemplateList = (params) => service.get(`/api/system/tool/video/template/list`, { params })

/**
 * 获取可用模板下拉列表
 */
export const getTemplateSelect = (params) => service.get(`/api/system/tool/video/template/onlineList`, { params })

/**
 * 获取可用模板下元素列表
 */
export const getTemplateElementSelect = (params) => service.get(`/api/system/tool/video/template/getElementConfig`, { params })

/**
 * 保存元素模板
 */
export const saveElementTemplate = (params) => service.post(`/api/system/tool/video/template/save`, params)

/**
 * 编辑元素模板
 */
export const updateElementTemplate = (params) => service.post(`/api/system/tool/video/template/update`, params)

/**
 * 获取模板关联元素列表
 */
export const getTemplateElementList = (params) => service.get(`/api/system/tool/video/template/getElements`, { params })

/**
 * 保存模板关联元素
 */
export const relationTemplateElement = (params) => service.get(`/api/system/tool/video/template/saveElement`, { params })

/**
 * 删除模板关联元素
 */
export const deleteTemplateElement = (params) => service.get(`/api/system/tool/video/template/deleteElement`, { params })

/**
 * 编辑替换元素
 */
export const updateTemplateReplace = (params) => service.post(`/api/system/tool/video/template/updateElement`, params)

// --------- 元素类型 ------------
/**
 * 获取元素类型列表
 */
export const getElementType = (params) => service.get(`/api/system/tool/video/template/type/list`, { params })

/**
 * 编辑元素类型
 */
export const updateElementType = (params) => service.post(`/api/system/tool/video/template/type/update`, params)

/**
 * 添加元素类型
 */
export const addElementType = (params) => service.post(`/api/system/tool/video/template/type/save`, params)

/**
 * 删除元素类型
 */
export const deleteElementType = (params) => service.get(`/api/system/tool/video/template/type/delete`, { params })

// --------- 视频任务 ------------
/**
 * 获取任务列表
 */
export const getVideoTaskList = (params) => service.post(`/api/system/tool/video/task/page`, params)

/**
 * 添加任务
 */
export const saveVideoTask = (params) => service.post(`/api/system/tool/video/task/save`, params)

/**
 * 提交任务
 */
export const submitVideoTask = (params) => service.post(`/api/system/tool/video/task/submit`, params)

/**
 * 重新提交任务
 */
export const reSubmitVideoTask = (params) => service.get(`/api/system/tool/video/task/reSubmitTask`, params)

/**
 * 编辑任务
 */
export const updateVideoTask = (params) => service.post(`/api/system/tool/video/task/update`, params)

/**
 * 删除任务
 */
export const deleteVideoTask = (params) => service.post(`/api/system/tool/video/task/delete`, params)

/**
 * 任务详情列表
 */
export const getVideoTaskDetail = (params) => service.post(`/api/system/tool/video/task/taskDetail`, params)

/**
 * 任务详情
 */
export const getVideoTaskById = (params) => service.get(`/api/system/tool/video/task/getById`, { params })

/**
 * 下载路径
 */
export const getDownloadUrls = (params) => service.get(`/api/system/tool/video/task/getUrls`, { params })
export const syncConfigStatus = (params) => service.get(`/api/system/tool/video/task/syncConfigStatus`, { params })
export const syncTaskStatus = (params) => service.get(`/api/system/tool/video/task/syncTaskStatus`, { params })
