<!--
 * @Description: 本地上传
 * @Date: 2021-12-20 15:03:27
 * @LastEditTime: 2022-02-08 16:03:44
-->

<template>
  <div>
    <!-- 列表查询 -->
    <el-form ref="ruleForm" :inline="true" :model="ruleForm" label-width="100px" class="demo-ruleForm" @submit.native.prevent>
      <el-row>
        <el-col>
          <el-form-item label="名称：" prop="name" :rules="{ required: true, message: '请输入定向名称', trigger: 'change' }">
            <el-input v-model="ruleForm.name" placeholder="请输入定向名称" style="width: 450px" maxlength="30" show-word-limit></el-input>
          </el-form-item>
        </el-col>

        <el-col>
          <el-form-item label="数据类型：" prop="uploadType" :rules="{ required: true, message: '请选择数据类型', trigger: 'change' }">
            <el-select v-model="ruleForm.uploadType" style="width: 450px" placeholder="请选择数据类型">
              <el-option v-for="item in crowdPack_type_list" :key="item.dictLabel" :value="item.dictValue" :label="item.dictLabel">{{ item.dictLabel }}</el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col>
          <!-- //multiple :limit="3" -->
          <el-form-item label="文件上传：" prop="uploadFileMd5" :rules="{ required: true, message: '请选择文件上传', trigger: 'change' }">
            <el-upload ref="my-upload" name="file" :limit="1" class="upload-demo" action="/api/file/upload" :before-upload="beforeAvatarUpload" :on-preview="handlePreview" :on-success="uploadSuccess" accept=".txt" :on-remove="handleRemove" :before-remove="beforeRemove" :on-exceed="handleExceed">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                <div class="tip">
                  <p class="reset-p">文件内容规范：每行只能有一个数据，不能有空格或其他字符</p>
                  <p class="reset-p">文件内容规范：仅英文与数字及横线，不能含中文与特殊字符</p>
                  <p class="reset-p">文件格式规范：只支持txt</p>
                  <p class="reset-p">文件大小规范：最大支持1G上传</p>
                </div>
              </div>
            </el-upload>
          </el-form-item>
        </el-col>

        <el-col>
          <el-form-item label="项目：" prop="projectId" :rules="{ required: true, message: '请选择项目', trigger: 'change' }">
            <SearchSelect
              v-model="ruleForm.projectId"
              :options="projectList"
              :props="{
                label: 'projectName',
                value: 'bsProjectId'
              }"
              style="width:450px"
              placeholder="请选择项目"
              @change="(value) => (selectProjectId = value)"
            />
          </el-form-item>
        </el-col>

        <el-col>
          <el-form-item label="产品：" prop="productId">
            <el-select v-model="ruleForm.productId" placeholder="请选择产品" style="width: 450px" @change="(value) => (selectProductId = value)">
              <el-option label="不限" :value="0"></el-option>
              <el-option v-for="(itm, idx) in productListed" :key="idx" :label="itm.productName" :value="itm.bsProductId"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col>
          <el-form-item label="描述：" prop="description">
            <el-input v-model="ruleForm.description" type="textarea" rows="7" maxlength="100" show-word-limit style="width: 450px" />
          </el-form-item>
        </el-col>
        <el-col class="mt-20">
          <el-form-item label=" ">
            <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
            <el-button @click="resetForm('ruleForm')">重 置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import accountUinTencent from '@/mixin/accountUin_tencent'
import { audienceSave } from '@/network/api/assetManagement/assetManageWeChatAdv.js'

export default {
  mixins: [accountUinTencent],
  data() {
    return {
      crowdPack_type_list: [],
      ruleForm: {
        name: null,
        uploadType: '',
        uploadFileMd5: '',
        projectId: null,
        productId: 0,
        description: null,
        type: 2,
        uploadUrl: null,
        uploadFileSize: null,
        uploadFileName: null
      }
    }
  },

  mounted() {
    this.getDicts('crowdPack_type_list').then((response) => {
      this.crowdPack_type_list = response.data
    })
  },

  methods: {
    /**
     * @description: 提交数据
     * @param {*} formName ref
     */
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const { projectId, productId } = this.ruleForm
          let params = {
            ...this.ruleForm,
            projectId,
            projectName: this.projectList.filter((item) => item.bsProjectId === projectId)[0].projectName,
            productId,
            productName: productId !== 0 ? this.productListed.filter((item) => item.bsProductId === productId)[0].productName : '不限'
          }
          audienceSave(params).then(({ code }) => {
            if (code === 200) {
              this.$message.success('定向创建成功')
              this.$router.push('/wechatCrowdPack')
              this.resetForm(formName)
            }
          })
        }
      })
    },

    beforeAvatarUpload(file) {
      const isLt1GB = file.size / 1024 / 1024 < 1024
      if (!isLt1GB) {
        this.$message.error('上传文件大小不能超过 2GB!')
      }
      return isLt1GB
    },

    uploadSuccess(args) {
      if (args.code === 200) {
        this.ruleForm.uploadFileMd5 = args.data.hashCode
        this.ruleForm.uploadUrl = args.data.url
        this.ruleForm.uploadFileSize = args.data.size
        this.ruleForm.uploadFileName = args.data.originName
      }
    },

    /**
     * @description: 重置数据
     * @param {*} formName ref
     */
    resetForm(formName) {
      this.$refs['my-upload'].clearFiles()
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
.account-info {
  display: flex;
}
</style>
