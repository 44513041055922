var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Search", { ref: "search" }),
      _c(
        "el-row",
        {
          staticClass: "operate-box mt-20",
          attrs: { type: "flex", justify: "start" },
        },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                disabled: _vm.shareBatchDisplay,
                round: "",
              },
              on: {
                click: function ($event) {
                  return _vm.dialogVisibleButton(undefined)
                },
              },
            },
            [_vm._v("批量分享")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-plus", round: "" },
              on: { click: _vm.showTbAdd },
            },
            [_vm._v("同步转化追踪")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-container" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "list-table",
              attrs: { stripe: "", data: _vm.data },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("c-data-empty", { attrs: { slot: "empty" }, slot: "empty" }),
              _c("el-table-column", { attrs: { type: "selection" } }),
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  align: "left",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "appTransId",
                  label: "ID",
                  align: "left",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "transName",
                  label: "转化名称",
                  align: "left",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "transFrom",
                  label: "接入方式",
                  align: "left",
                  formatter: _vm.formatterTransFrom,
                  "show-overflow-tooltip": true,
                },
              }),
              _vm._v(" x "),
              _c("el-table-column", {
                attrs: {
                  prop: "transStatus",
                  label: "转化配置状态",
                  align: "left",
                  formatter: _vm.formatterTransStatus,
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "housekeeperName",
                  label: "账号管家",
                  align: "left",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "accountName",
                  label: "账户名称",
                  align: "left",
                  "show-overflow-tooltip": true,
                },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.dialogVisibleButton(row)
                              },
                            },
                          },
                          [_vm._v("分享")]
                        ),
                        row.transStatus === 2 || row.transStatus === "2"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteRow(row)
                                  },
                                },
                              },
                              [_vm._v("激活")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("c-pagination", {
        attrs: {
          "page-size": _vm.pageInfo.pageSize,
          "page-no": _vm.pageInfo.pageNum,
          total: _vm.pageInfo.total,
          "get-data-fun": _vm.loadData,
        },
        on: {
          "update:pageSize": function ($event) {
            return _vm.$set(_vm.pageInfo, "pageSize", $event)
          },
          "update:page-size": function ($event) {
            return _vm.$set(_vm.pageInfo, "pageSize", $event)
          },
          "update:pageNo": function ($event) {
            return _vm.$set(_vm.pageInfo, "pageNum", $event)
          },
          "update:page-no": function ($event) {
            return _vm.$set(_vm.pageInfo, "pageNum", $event)
          },
        },
      }),
      _c("OptionDialog", { ref: "dialog" }),
      _c("tbOptionDialog", { ref: "tbdialog" }),
      _c("activetion", { ref: "activeDialog" }),
      _c("activetion1", { ref: "activeDialog1" }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.titleContent,
            width: "576px",
            visible: _vm.dialogVisible,
            "label-position": "right",
            "modal-append-to-body": false,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          !_vm.synchRangeShow
            ? _c(
                "span",
                { staticStyle: { color: "darkgray" } },
                [
                  _vm._v(" 仅支持分享至主体 "),
                  _c(
                    "font",
                    { staticStyle: { color: "#409EFF", size: "14px" } },
                    [_vm._v(_vm._s(_vm.subjectName))]
                  ),
                  _vm._v(" 下的投放账户 "),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form",
            { staticClass: "mt-20" },
            [
              _vm.synchRangeShow
                ? _c(
                    "el-form-item",
                    { attrs: { label: "同步范围" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "400px" },
                          attrs: { filterable: "", placeholder: "同步范围" },
                          on: { change: _vm.accountApponit },
                          model: {
                            value: _vm.synchRange,
                            callback: function ($$v) {
                              _vm.synchRange = $$v
                            },
                            expression: "synchRange",
                          },
                        },
                        _vm._l(_vm.trackRange, function (item) {
                          return _c("el-option", {
                            key: item.dictValue,
                            attrs: {
                              "trans-from-list": "",
                              label: item.dictLabel,
                              value: item.dictValue,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "账户管家" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "400px" },
                      attrs: {
                        disabled: _vm.allStatus,
                        filterable: "",
                        placeholder: "请选择账户管家",
                      },
                      on: { change: _vm.selectChange },
                      model: {
                        value: _vm.housekeeperId,
                        callback: function ($$v) {
                          _vm.housekeeperId = $$v
                        },
                        expression: "housekeeperId",
                      },
                    },
                    _vm._l(_vm.shareHouseKeeper, function (item) {
                      return _c("el-option", {
                        key: item.housekeeperId,
                        attrs: {
                          label: item.launchAccountName,
                          value: item.housekeeperId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "投放账户" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "400px" },
                      attrs: {
                        value: _vm.baiduId,
                        disabled: _vm.allStatus,
                        filterable: "",
                        multiple: "",
                        placeholder: "请选择投放账户",
                      },
                      on: { change: _vm.setBaiduId },
                    },
                    [
                      _vm.synchRangeShow
                        ? _c("el-option", {
                            key: "0",
                            attrs: { label: "全部", value: "0" },
                          })
                        : _vm._e(),
                      _vm._l(_vm.baiduAdv, function (item) {
                        return _c("el-option", {
                          key: item.baiduId,
                          attrs: {
                            label: item.accountName,
                            value: item.baiduId,
                          },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.showAdd()
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }