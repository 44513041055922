var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c("p", { staticClass: "base-title" }, [
      _vm._v(_vm._s(_vm.nowWidget.type) + ": " + _vm._s(_vm.nowWidget.title)),
    ]),
    _c("div", { staticClass: "dotted" }),
    _c("div", { staticClass: "slot-body" }, [_vm._t("body")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }