<!--
 * @Description: Do not edit
 * @Date: 2021-11-10 19:20:56
 * @LastEditTime: 2022-05-06 11:29:38
-->
<template>
  <el-form ref="searchForm" class="search-form search-form-4" :model="searchForm">
    <el-row :gutter="20">
      <el-col :span="6">
        <el-form-item prop="housekeeperId" label="账户管家">
          <el-select v-model="searchForm.housekeeperId" filterable placeholder="请输入账户管家" @change="selectChange">
            <el-option v-for="item in houseKeeper()" :key="item.housekeeperId" :label="item.launchAccountName" :value="item.housekeeperId" />
          </el-select>
          <!-- <el-input v-model="searchForm.housekeeperId" placeholder="账号管家" /> -->
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-form-item prop="baiduId" label="投放账户">
          <el-select v-model="searchForm.baiduId" filterable placeholder="请输入投放账户">
            <el-option v-for="item in baiduAdv" :key="item.baiduId" :label="item.accountName" :value="item.baiduId" />
          </el-select>
          <!-- <el-input v-model="searchForm.baiduId" placeholder="投放账户" /> -->
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="pageName" label="落地页名称">
          <el-input v-model="searchForm.pageName" placeholder="请输入落地页名称" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-button class="btn" type="primary" @click="search">
          查询
        </el-button>
        <el-button class="btn" @click="resetSearch">
          重置
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { searchForm } from './object'
import { getAccountByHouseId } from '@/network/api/account/accountBaiduAdv'
export default {
  inject: ['loadData', 'houseKeeper'],
  data() {
    return {
      searchForm: { ...searchForm },
      baiduAdv: []
    }
  },

  methods: {
    selectChange(val) {
      this.baiduAdv = []
      this.searchForm.baiduId = ''
      getAccountByHouseId(val).then((res) => {
        if (res.code === 200) {
          this.baiduAdv = res.data
        }
      })
    },
    search() {
      this.loadData(true)
    },
    // 折中方案
    getSearchForm() {
      return { ...this.searchForm }
    },
    resetSearch() {
      this.$refs.searchForm.resetFields()
      this.baiduAdv = []
      this.loadData(true)
    }
  }
}
</script>
