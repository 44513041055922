<template>
  <div class="create-video-box">
    <el-form ref="form" :model="form" :rules="rule" label-width="160px">
      <el-form-item label="任务名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入任务名称" style="width: 300px;" maxlength="30" show-word-limit>
        </el-input>
      </el-form-item>
      <el-form-item label="视频模板" prop="templateId">
        <el-select v-model="form.templateId" filterable placeholder="请选择视频模板" style="width: 300px"
          @change="handleChangeTemplate">
          <el-option v-for="(itm, idx) in templateList" :key="idx" :label="itm.name" :value="itm.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="form.templateId" label="视频元素" prop="elementConfig">
        <div class="video-elements-box" v-if="form.elementConfig.length">
          <el-tabs v-model="activeElement" type="card">
            <el-tab-pane v-for="(item, index) in form.elementConfig" :key="index" :label="item.typeName"
              :name="item.typeId + ''" class="element-list">
              <div class="element-item" v-for="(child, i) in item.items" :key="i" @click="handleSelectElement(child)">
                <div class="thumb-box">
                  <el-image v-if="child.type === 'IMG'" :src="child.imgUrl" fit="scale-down"></el-image>
                  <video v-if="child.type === 'VIDEO'" :src="child.url"></video>
                </div>
                <span class="check-circle-box" :class="{ 'el-icon-check': child.checked }"></span>
                <div class="element-item-title">{{ child.name }}</div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-form-item>
      <el-form-item label="视频时长" prop="duration">
        <el-radio-group v-model="form.duration">
          <el-radio-button v-for="(item, index) in durationTypes" :key="index" :label="item.key">{{ item.text }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="form.duration === 5" label="自定义视频时长" prop="times">
        <el-input-number v-model="form.times" placeholder="可输入5-120S之间" :controls="false" :min="5" :max="120"
          style="width: 100px"></el-input-number>
        <span class="tips">可输入5~120S之间</span>
      </el-form-item>
      <el-form-item label="存储位置" prop="albumId">
        <el-select v-model="form.albumId" filterable placeholder="请选择专辑" style="width: 180px;margin-right: 10px;"
          @change="handleAlbum">
          <el-option v-for="(itm, idx) in albumList" :key="idx" :label="itm.albumName" :value="itm.albumId"></el-option>
        </el-select>
        <el-select v-model="form.materialGroupId" :disabled="!form.albumId" filterable clearable placeholder="请选择素材组"
          style="width: 180px;margin-right: 10px;" @change="handleMaterialGroup">
          <el-option v-for="(itm, idx) in materialGroupList" :key="idx" :label="itm.materialGroupName"
            :value="itm.materialGroupId"></el-option>
        </el-select>
        <el-input v-model="form.keyword" :disabled="!form.albumId" placeholder="请输入素材关键字，空格分隔" style="width: 220px;">
        </el-input>
      </el-form-item>
      <el-form-item>
        <template slot="label">
          <div><span style="color: #F56C6C">*</span> 源视频({{ form.videos.length }}/{{ VIDEO_NUM }})</div>
        </template>
        <div class="form-image-list">
          <!-- <div v-for="(item, index) in form.videos" :key="index">
            <div class="form-image-list-cell">
              <video :src="item.url"></video>
              <div class="form-image-list-cell-del" @click="handleDeleteVideo(index)">删除</div>
            </div>
          </div> -->
          <div v-for="(item, index) in form.videos" :key="index">
            <div class="form-image-list-cell">
              <el-progress v-show="item.status === 'uploading'" :text-inside="true" :stroke-width="14"
                style="margin:45px 10px 0 10px;" :percentage="item.progress"
                :status="item.progress ? 'success' : 'warning'"></el-progress>
              <video :src="item.url"></video>
              <div v-show="item.status !== 'uploading'" class="form-image-list-cell-del">
                <el-button type="primary" circle icon="el-icon-delete" @click="handleDeleteVideo(index)"></el-button>
              </div>
            </div>
          </div>
          <el-upload v-if="form.videos.length < VIDEO_NUM" id="uploa-video" class="form-image-upload"
            action="fakeaction" accept=".mp4,.avi,.mov" list-type="picture-card" :multiple="true" :limit="videoLimit"
            :show-file-list="false" :before-upload="beforeUploadVideo" :on-exceed="handleExceedVideo">
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
        <div class="tips">格式：mp4、avi、mov</div>
      </el-form-item>

      <div class="form-footer">
        <el-button type="primary" :disabled="loading || fileUploading" @click="handleConfirm('submit')">立即提交</el-button>
        <el-button type="primary" :disabled="loading || fileUploading" @click="handleConfirm('save')"
          plain>保存</el-button>
        <el-button @click="handleCancel">取 消</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { getAlbumAllList, getMarterialGroupAllList } from '@/network/api/api-material'
import { getTemplateSelect, getTemplateElementSelect, saveVideoTask, submitVideoTask, getVideoTaskById, updateVideoTask } from '@/network/api/toolManagement/api-tool'
import SparkMD5 from 'spark-md5'
import oss from './oss'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  mixins: [oss],
  data() {
    return {
      loading: false,
      taskId: null,
      VIDEO_NUM: 20, // 可上传视频最大数
      activeElement: '', // 视频元素默认选中tab
      form: {
        name: '', // 任务名
        templateId: '', // 视频模板
        elementConfig: [], // 模板元素
        duration: 6, // 视频时长，默认原视频时长
        times: 0, // 自定义视频时长
        albumId: '',
        albumName: '',
        materialGroupId: '',
        materialGroupName: '',
        keyword: '',
        videos: []
      },
      templateList: [], // 视频模板数据
      albumList: [], // 专辑数据
      materialGroupList: [], // 素材组数据
      durationTypes: [
        { key: 1, value: 5, text: '5S' },
        { key: 2, value: 29, text: '29S' },
        { key: 3, value: 59, text: '59S' },
        { key: 4, value: 89, text: '89S' },
        { key: 5, value: '', text: '自定义' },
        { key: 6, value: '', text: '原视频时长' }
      ],
      rule: {
        name: [{ required: true, message: '请输入任务名称', trigger: 'blur' }],
        templateId: [{ required: true, message: '请选择视频模板', trigger: 'change' }],
        elementConfig: [{ required: true, message: '请选择视频元素', trigger: 'change' }],
        duration: [{ required: true, message: '请选择视频时长', trigger: 'change' }],
        times: [{ required: true, message: '请输入视频时长', trigger: 'blur' }],
        albumId: [{ required: true, message: '请选择专辑', trigger: 'change' }],
        videos: [{ required: true, message: '请上传源视频', trigger: 'change' }]
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    videoLimit() {
      let num = this.VIDEO_NUM - this.form.videos.length
      return num >= 0 ? num : 0
    },
    fileUploading() {
      let item = this.form.videos.filter(v => v.status === 'uploading')
      return item.length > 0
    }
  },
  created() {
    this.getByIdFun()
  },
  mounted() { },
  methods: {

    async getByIdFun() {
      this.getTemplateList()
      this._getAlbumAllList()

      let { id } = this.$route.params
      if (id) {
        const { code, data } = await getVideoTaskById({ id: id })
        if (code === 200) {
          this.activeElement = data.elementConfig.length ? data.elementConfig[0].typeId + '' : ''
          let duration = 0
          let times = 0
          let urls = data.videoUrls.map(v => {
            return {
              url: v.videoUrl,
              duration: v.videoDuration,
              originName: v.videoName
            }
          })
          if (data.videoDurationOriginal === 1) {
            // 原视频时长
            duration = 6
          } else if (data.videoDurationCustomize === 1) {
            // 自定义视频时长
            times = data.videoDuration
            duration = 5
          } else {
            let item = this.durationTypes.find(v => v.value === data.videoDuration)
            duration = item.key
          }
          this._getMarterialGroupAllList(data.albumId)
          this.form = {
            id: data.id,
            name: data.name, // 任务名
            templateId: data.templateId, // 视频模板
            elementConfig: data.elementConfig, // 模板元素
            duration: duration, // 视频时长，默认原视频时长
            times: times, // 自定义视频时长
            albumId: data.albumId,
            albumName: data.albumName,
            materialGroupId: data.materialGroupId,
            materialGroupName: data.materialGroupName,
            keyword: data.keyword,
            videos: urls
          }
        }
      } else {
        this.getTaskName()
      }
    },

    // 点击确定
    handleConfirm(tag) {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return
        }

        let { id, name, templateId, elementConfig, duration, times, albumId, albumName, materialGroupId, materialGroupName, keyword, videos } = this.form

        if (!materialGroupId) {
          this.$message.error('请选择素材组')
          return
        }
        if (!keyword) {
          this.$message.error('请输入关键字')
          return
        }

        if (!videos.length) {
          this.$message.error('请上传源视频')
          return
        }

        let urls = videos.map(v => {
          return {
            videoDuration: v.duration,
            videoUrl: v.url,
            videoName: v.originName
          }
        })
        let videoDuration = 0
        let videoDurationOriginal = 0
        let videoDurationCustomize = 0
        switch (duration) {
          case 6: // 原视频时长
            videoDurationOriginal = 1
            break
          case 5: // 自定义时长
            videoDurationCustomize = 1
            videoDuration = times
            break
          default: {
            let item = this.durationTypes.find(v => v.key === duration)
            videoDuration = item.value
          }
        }

        let params = {
          name,
          templateId,
          elementConfig,
          videoDuration,
          videoDurationOriginal,
          videoDurationCustomize,
          materialGroupId,
          materialGroupName,
          albumId,
          albumName,
          keyword,
          videoUrls: urls
        }

        if (tag === 'submit') {
          params.id = id || ''
          this.submitVideoTemplate(params)
        } else {
          if (id) {
            params.id = id
            this.editVideoTemplate(params)
          } else {
            this.saveVideoTemplate(params)
          }
        }
      })
    },

    // 保存
    saveVideoTemplate(params) {
      this.loading = true
      saveVideoTask(params).then(({ code }) => {
        if (code === 200) {
          this.$message.success('任务保存成功')
          this.$router.replace({
            name: 'toolVideoTask'
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },

    // 编辑
    editVideoTemplate(params) {
      this.loading = true
      updateVideoTask(params).then(({ code }) => {
        if (code === 200) {
          this.$message.success('任务编辑成功')
          this.$router.replace({
            name: 'toolVideoTask'
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },

    // 提交
    submitVideoTemplate(params) {
      this.loading = true
      submitVideoTask(params).then(({ code }) => {
        if (code === 200) {
          this.$message.success('任务提交成功')
          this.$router.replace({
            name: 'toolVideoTask'
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },

    // 填充任务名称
    getTaskName() {
      let str = this.userInfo.username + '-' + moment().format('YYYYMMDDHHmmss')
      this.form.name = str
    },

    // 获取专辑列表
    _getAlbumAllList(ids) {
      let params = {
        type: 'video',
        projectIds: ids
      }
      getAlbumAllList(params).then((res) => {
        this.albumList = res.data
      })
    },

    // 获取素材组列表
    _getMarterialGroupAllList(albumId) {
      let params = {
        type: 'video',
        albumId: albumId
      }
      getMarterialGroupAllList(params).then((res) => {
        this.materialGroupList = res.data
      })
    },

    // 获取视频模板
    getTemplateList() {
      getTemplateSelect().then(({ code, data }) => {
        if (code === 200) {
          this.templateList = data
        }
      })
    },

    // 获取模板下元素配置
    getElementConfigList(templateId) {
      getTemplateElementSelect({ templateId: templateId }).then(({ code, data }) => {
        if (code === 200) {
          this.form.elementConfig = data
          this.activeElement = data.length ? data[0].typeId + '' : ''
        }
      })
    },

    // 选择视频模板
    handleChangeTemplate(value) {
      if (value) {
        this.form.elementConfig = []
        this.activeElement = ''
        this.getElementConfigList(value)
      }
    },

    // 选择视频元素
    handleSelectElement(row) {
      row.checked = !row.checked
    },

    // 专辑有改动的回调
    handleAlbum(e) {
      if (e !== '') {
        let item = this.albumList.find(v => v.albumId === e)
        if (item) {
          this.form.albumName = item.albumName
        }
        this.form.materialGroupId = ''
        this._getMarterialGroupAllList(e)
      }
    },

    // 素材组
    handleMaterialGroup(value) {
      if (value) {
        let item = this.materialGroupList.find(v => v.materialGroupId === value)
        if (item) {
          this.form.materialGroupName = item.materialGroupName
        }
      }
    },
    // 上传前回调
    beforeUploadVideo(file) {
      if (['video/mp4', 'video/ogg', 'video/avi', 'video/mov'].indexOf(file.type) === -1) {
        this.$WarningMessage('请上传正确的视频格式')
        return false
      }
      const fileList = {}
      for (const key in file) {
        fileList[key] = file[key]
      }

      // if (this.form.videos.length > this.VIDEO_NUM) {
      //   this.$message.error(`上传视频不能超过${this.VIDEO_NUM}个!`)
      //   return false
      // }
      this.form.videos.push({ ...fileList, progress: 0, status: 'uploading' })
      this.uploadVideo(file)
      return false
    },

    showProgress(file, params) {
      const arr = [...this.form.videos].map(items => {
        if (items.uid === file.uid) {
          items = { ...items, ...params }
        }
        return items
      })

      this.form.videos = [...arr]
    },

    // 文件超出限制
    handleExceedVideo() {
      this.$message.error(`上传视频不能超过${this.VIDEO_NUM}个!`)
    },
    // 视频上传
    uploadVideo(file) {
      // const file = params.file

      const form = new FormData()
      // 文件对象
      form.append('file', file)
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      // form.append('name', file.name)
      // 获取上传的视频的宽高
      let videoWidth = 0
      let videoHeight = 0
      // let duration = 0
      const url = URL.createObjectURL(file)
      const video = document.createElement('video')

      video.onloadedmetadata = (evt) => {
        URL.revokeObjectURL(url)
        videoWidth = video.videoWidth
        videoHeight = video.videoHeight
      }
      video.onloadeddata = (evt) => {
        // duration = video.duration
        this.getFileHash(file).then((val) => {
          // this.loading = true
          this.ossUpload(file, {
            width: videoWidth,
            size: file.size,
            hash: val.HASH,
            height: videoHeight,
            duration: video.duration
          }, (val) => {
            // if (val.width !== videoWidth) {
            //   val.width = videoWidth
            // }
            // if (val.height !== videoHeight) {
            //   val.height = videoHeight
            // }
            // val.duration = duration
            // this.form.videos.push(val)
            // this.loading = false
          }, (er) => {
            // this.loading = false
            this.$message.error(er)
          }, (p) => {
            this.showProgress(file, p)
          })
        })
      }
      video.src = url
      video.load()
      // 获取文件hash值以及后缀名
    },
    getFileHash(file) {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)
        reader.onload = ev => {
          let buffer = ev.target.result
          let spark = new SparkMD5.ArrayBuffer()
          let HASH
          let suffix
          spark.append(buffer)
          HASH = spark.end()
          suffix = file.name.substring(file.name.lastIndexOf('.') + 1)
          resolve({
            buffer,
            HASH,
            suffix,
            filename: `${HASH}.${suffix}`
          })
        }
      })
    },
    // 删除视频
    handleDeleteVideo(index) {
      this.form.videos.splice(index, 1)
    },

    // 点击取消
    handleCancel() {
      this.$router.replace({
        name: 'toolVideoTask'
      })
    }
  }
}
</script>

<style lang="scss">
.video-elements-box {
  .el-tabs {
    &.el-tabs--card>.el-tabs__header .el-tabs__nav {
      border-left-color: #e4e7ed;
      border-right-color: #e4e7ed;
      border-top-color: #e4e7ed;
      border-radius: 0;
    }

    &.el-tabs--card>.el-tabs__header .el-tabs__item {
      border-left-color: #e4e7ed;
    }

    &.el-tabs--card>.el-tabs__header {
      border-bottom: solid 1px #e4e7ed;
      margin-bottom: 0;
    }

    .el-tabs__item {
      font-size: 14px;
      font-weight: 300;
      color: #B0B0B0;
      transition: all 0.3s;
      user-select: none;
      border-right: solid 1px transparent;

      &.is-active {
        box-sizing: content-box;
        color: #409EFF;
        font-weight: 400;

        &:not(:first-child) {
          border-left-color: #e4e7ed;
        }
      }
    }

    .el-tabs__content {
      padding: 10px;
      border: solid 1px #e4e7ed;
      border-top: 0;
      box-sizing: border-box;
    }
  }
}

.create-video-box .form-image-upload {
  .el-upload--picture-card {
    position: relative;
    padding: 0 !important;
    width: 100px !important;
    height: 100px !important;
    margin-right: 3px !important;

    .el-icon-plus {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>

<style lang="scss" scoped>
.create-video-box {
  width: 800px;

  .tips {
    font-size: 12px;
    color: #999999;
    margin-left: 10px;
  }

  .video-elements-box {
    width: 660px;

    .element-list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }

    .element-item {
      padding: 0 5px;
      position: relative;
      margin: 10px 0;

      .thumb-box {
        width: 150px;
        height: 150px;
        border: solid 1px #dcdfe6;
        box-sizing: border-box;
        cursor: pointer;
      }

      .el-image {
        width: 100%;
        height: 100%;
      }

      video {
        width: 100%;
        height: 100%;
      }

      .check-circle-box {
        width: 16px;
        height: 16px;
        background-color: #fff;
        border: solid 1px #dcdfe6;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        right: 5px;

        &.el-icon-check {
          color: #fff;
          background-color: $themeColor;
        }
      }
    }

    .element-item-title {
      text-align: center;
      font-size: 12px;
      color: #333;
    }
  }

  .justify-center1 {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
  }

  .form-image-list {
    display: flex;
    flex-flow: row wrap;
    border: solid 1px #dcdfe6;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 4px;

    .form-image-list-cell {
      position: relative;
      border: 1px solid #ccc;
      width: 100px;
      height: 100px;
      border-radius: 5px;
      margin-right: 10px;
      margin-bottom: 10px;
      overflow: hidden;

      // margin-left: 3px;
      // margin-top: 3px;
      img {
        width: 100px;
        height: 100px;
      }

      video {
        width: 100px;
        height: 100px;
      }

      .form-image-list-cell-del {
        background-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: none;
        justify-content: center;
        align-items: center;
        // color: #000;
        // position: absolute;
        // left: 50%;
        // transform: translateX(-50%);
        // bottom: 0;
        // width: 40px;
        // height: 25px;
        // line-height: 25px;
        // border-radius: 4px;
        // box-sizing: border-box;
        // font-size: 14px;
        // font-weight: 400;
        // text-align: center;
        // margin-right: 14px;
        // background: #eeecf2;
        // cursor: pointer;
        // transition: 0.2s;

        // &:last-child {
        //   margin-right: 0;
        // }

        // &:hover {
        //   background: $themeColor;
        //   color: white;
        // }
      }

      &:hover {
        .form-image-list-cell-del {
          display: flex;
        }
      }
    }
  }

  .form-footer {
    text-align: center;
    margin-top: 50px;
  }
}
</style>
