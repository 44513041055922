
<template>
  <!--显示列弹框-->
  <el-dialog
    :visible.sync="show"
    :append-to-body="true"
    width="600px"
    top="20vh"
    @close="onCloseDialog"
  >
    <!--自定义 title-->
    <span slot="title" class="dialog-title">{{ dialogTitle }}</span>
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="项目" prop="projectId">
        <el-select v-model="form.projectId" filterable clearable placeholder="请选择关联项目" style="width: 100%;" @change="handleSelect">
          <el-option v-for="(itm, idx) in projectList" :key="idx" :label="itm.projectName" :value="itm.bsProjectId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="产品" prop="productId">
        <el-select v-model="form.productId" filterable :disabled="!form.projectId" clearable placeholder="请选择关联产品" style="width: 100%;">
          <el-option v-for="(itm, idx) in productList" :key="idx" :label="itm.productName" :value="itm.bsProductId"></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">取 消</el-button>
      <el-button class="btn" type="primary" :loading="isLoading" :disabled="isLoading" @click="clickConfirm('form')">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  batchMoveTxt
} from '@/network/api/api-material'
import { getMeRelationProject } from '@/network/api/basicSetting/project/basicSettingProject'
import { getProductByProject } from '@/network/api/basicSetting/product/basicSettingProduct'
export default {
  name: 'EditCopyDialog',
  components: {
  },
  filters: {},
  props: {
  },
  data () {
    return {
      show: false, // 是否显示弹框
      dialogTitle: '',
      projectList: [], // 项目列表
      productList: [], // 产品列表
      isLoading: false, // 是否正在加载
      ids: [],
      form: {
        productId: '',
        projectId: ''
      }, // 表单数据
      rules: {
        productId: [{ required: true, message: '请选择关联产品', trigger: 'blur' }],
        projectId: [{ required: true, message: '请选择关联项目', trigger: 'blur' }]
      } // 表单校验规则
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  watch: {},
  created () {
  },
  mounted () {
  },
  methods: {
    /* --------------------------- Actions --------------------------- */
    // 显示弹框
    showModal (ids) {
      this.show = true
      this.dialogTitle = '批量关联产品'
      this._getProject()
      this.ids = ids
    },

    // 关闭弹框
    closeModal () {
      this.show = false
    },
    // 监听 Dialog 弹框关闭
    onCloseDialog () {
      // 重置表单
      this.$refs['form'].resetFields()

      // 清除数据
      this.form = {
        productId: '',
        projectId: ''
      }
      this.ids = []
    },

    // 点击确定按钮
    clickConfirm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this._batchMoveTxt()
        } else {
          this.$WarningMessage('请将表单填写完整')
        }
      })
    },
    // 选择关联项目之后
    handleSelect (e) {
      if (e !== '') {
        this.form.productId = ''
        this._getProduct(e)
      }
    },
    /* --------------------------- Private --------------------------- */
    // 获取项目列表(个人可见)
    _getProject() {
      getMeRelationProject().then(res => {
        if (res.code === 200) {
          this.projectList = res.data
        }
      })
    },
    // 获取产品列表(个人可见)
    _getProduct(val) {
      getProductByProject(val).then(res => {
        if (res.code === 200) {
          this.productList = res.data
        }
      })
    },
    // 移动文本素材
    _batchMoveTxt  () {
      this.isLoading = true
      let params = {
        adminId: this.userInfo.userid,
        nickname: this.userInfo.username,
        productId: this.form.productId,
        projectId: this.form.projectId,
        textIds: this.ids
      }
      batchMoveTxt(params).then(res => {
        // this.$SuccessMessage('关联文本成功')
        this.$emit('edit')
        this.closeModal()
      }).catch(err => {
        this.$ErrorMessage(err.data || '关联文本失败')
      }).finally(() => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
