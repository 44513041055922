<!--
 * @Description: Do not edit
 * @Date: 2021-11-10 19:20:56
 * @LastEditTime: 2022-05-13 15:27:43
-->
<template>
  <el-dialog :title="'转化追踪激活'" width="45%" :visible.sync="visible" :before-close="close" label-position="right" :modal-append-to-body="false" :close-on-press-escape="false" :close-on-click-modal="false">
    <div style="text-align: left">
      <div class="justify-sb" style="padding: 0 40px 20px 40px">
        <p class="tip">账户管家: {{ ent.housekeeperName }}</p>
        <p class="tip">投放账户: {{ ent.accountName }}</p>
      </div>
      <el-form label-width="110px">
        <el-form-item label="推广URL：" :rule="{ required: true }">
          <el-input v-model="url" placeholder="请输入推广URL" />
        </el-form-item>
        <el-form-item label="ocpc_token" :rule="{ required: true }">
          <el-input v-model="token" placeholder="请输入ocpc_token" />
        </el-form-item>
        <div style="margin-left: 28px">
          <p class="tip">推广URL获取</p>
          <p class="tip">1、登录投放账户，于【工具中心】-【转化追踪】中找到对应转化追踪;</p>
          <p class="tip">2、点击【联调】，并复制“第二步”中的推广URL;</p>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button round @click="close">取消</el-button>
      <el-button type="primary" round @click="submit">激活</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { activationTrack } from '@/network/api/assetManagement/assetManagementBaiduTrack'
import { AssetManagementBaiduTrackEnt, formRules } from './object'

export default {
  inject: ['loadData'],
  data() {
    return {
      url: undefined,
      token: null,
      ent: new AssetManagementBaiduTrackEnt(),
      rules: formRules,
      isEdit: false,
      visible: false,
      cityList: []
    }
  },
  methods: {
    submit() {
      if (!this.url && !this.token) {
        return this.$message.error('请输入必填信息')
      }
      var data = `url=${this.url}&trackId=${this.ent.trackId}&token=${this.token}`
      console.log(data)
      activationTrack(data).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: '激活成功',
            type: 'success'
          })
        }
        this.loadData()
        this.close()
      })
    },
    close() {
      this.visible = false
    },
    show(isEdit = false, ent) {
      this.ent = ent
      this.isEdit = isEdit
      this.visible = true
      this.$nextTick(() => {
        if (this.isEdit) {
          console.log('不能为空')
        }
      })
    }
  }
}
</script>
