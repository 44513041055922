<!--
 * @Description: 腾讯广告-> 新建定向模板
 * @Date: 2021-12-01 09:15:29
 * @LastEditTime: 2022-04-21 17:04:41
-->

<template>
  <div>
    <!-- 列表查询 -->
    <el-form ref="form" :model="form" label-width="110px">
      <el-row :gutter="20">
        <el-col :span="12">
          <ModuleBox title="基础信息">
            <el-form-item label="定向名称：" prop="name" :rules="{ required: true, message: '请输入定向名称：', trigger: 'change' }">
              <el-input v-model="form.name" placeholder="请输入定向名称" style="width: 400px"></el-input>
            </el-form-item>
          </ModuleBox>
        </el-col>
        <el-col :span="12">
          <ModuleBox title="基础信息">
            <el-form-item label="项目：" prop="projectId" :rules="{ required: true, message: '请选择项目', trigger: 'change' }">
              <SearchSelect
                v-model="form.projectId"
                :options="projectList"
                :props="{
                  label: 'projectName',
                  value: 'bsProjectId'
                }"
                style="width:400px"
                placeholder="请选择项目"
                @change="(value) => (selectProjectId = value)"
              />
            </el-form-item>
            <el-form-item label="关联产品：" prop="productId">
              <el-select v-model="form.productId" style="width:400px" placeholder="请选择产品">
                <el-option label="不限" :value="0"></el-option>
                <el-option v-for="(itm, idx) in productListed" :key="idx" :label="itm.productName" :value="itm.bsProductId"></el-option>
              </el-select>
            </el-form-item>
          </ModuleBox>
        </el-col>

        <el-col>
          <ModuleBox title="地域信息">
            <el-form-item label="地域：" prop="area" :rules="{ required: true, message: '请选择地域', trigger: 'change' }">
              <el-radio v-model="form.areaType" label="area">常驻地</el-radio>
              <el-radio v-model="form.areaType" label="travelArea">差旅出行</el-radio>
              <div class="city-box">
                <Area :select-data="form.area" @changeArea="changeArea" />
              </div>
            </el-form-item>
          </ModuleBox>
        </el-col>

        <el-col>
          <ModuleBox title="人群基础信息">
            <el-form-item label="年龄：" prop="age" :rules="{ required: true, message: '请选择年龄', trigger: 'change' }">
              <el-row>
                <el-col prop="age" :xl="20" :lg="18">
                  <el-select v-model="form.age[0]" style="width:160px" placeholder="请选择" @change="(value) => (value > form.age[0] ? (form.age[0] = value) : null)">
                    <el-option v-for="item in age_min_list" :key="item.value" :label="item.value" :value="item.key"> </el-option>
                  </el-select>
                  <span> - </span>
                  <el-select v-model="form.age[1]" style="width:160px" placeholder="请选择">
                    <el-option v-for="item in age_max_list" :key="item.key" :label="item.value" :value="item.key" :disabled="item.key < form.age[0]"> </el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-form-item>

            <!-- 性别 -->
            <el-form-item label="性别：" prop="gender" :rules="{ required: true, message: '请选择性别', trigger: 'change' }">
              <el-radio-group v-model="form.gender">
                <el-radio label="all">全部</el-radio>
                <el-radio label="MALE">男</el-radio>
                <el-radio label="FEMALE">女</el-radio>
              </el-radio-group>
            </el-form-item>

            <!-- 行为兴趣意向 -->
            <el-form-item label="行为兴趣：" prop="behaviorAndInterest">
              <el-radio v-model="form.behaviorAndInterest" label="behavior">
                行为<span v-if="form.behavior.category.length">( {{ form.behavior.category.length }} )</span>
              </el-radio>
              <el-radio v-model="form.behaviorAndInterest" label="interest">
                兴趣<span v-if="form.interest.category.length">( {{ form.interest.category.length }} )</span>
              </el-radio>
              <div v-if="form.behaviorAndInterest === 'behavior'">
                <Behavior :select-data="form.behavior" @changeBehavior="changeBehavior" />
              </div>
              <div v-else class="city-box">
                <Interest :select-data="form.interest" @changeInterest="changeInterest" />
              </div>
            </el-form-item>
          </ModuleBox>
        </el-col>

        <!-- 更多人群信息 -->
        <el-col>
          <ModuleBox title="更多人群信息" desc="(以下均为选填)">
            <!-- 学历： -->
            <el-form-item label="学历：" prop="education">
              <el-row>
                <el-col :xl="20" :lg="18">
                  <el-checkbox-group v-model="form.education" @change="(value) => changeAllFormate(value, 'education')">
                    <el-checkbox label="">全部</el-checkbox>
                    <el-checkbox v-for="item in education_list" :key="item.dictLabel" :label="item.dictValue">{{ item.dictLabel }}</el-checkbox>
                  </el-checkbox-group>
                </el-col>
              </el-row>
            </el-form-item>
            <!-- 操作系统版本 -->
            <el-form-item label="操作系统：" prop="os">
              <el-checkbox-group v-model="form.os">
                <el-checkbox label="ANDROID">Android</el-checkbox>
                <el-checkbox label="IOS">IOS</el-checkbox>
              </el-checkbox-group>
            </el-form-item>

            <!-- 手机价格： -->
            <el-form-item label="手机价格：" prop="devicePrice">
              <el-row>
                <el-col :xl="20" :lg="18">
                  <el-checkbox-group v-model="form.devicePrice" @change="(value) => changeAllFormate(value, 'devicePrice')">
                    <el-checkbox label="">全部</el-checkbox>
                    <el-checkbox v-for="item in device_price_list" :key="item.dictLabel" :label="item.dictValue">{{ item.dictLabel }}</el-checkbox>
                  </el-checkbox-group>
                </el-col>
              </el-row>
            </el-form-item>

            <!-- 设备品牌型号 -->
            <el-form-item label="手机品牌：" prop="deviceBrandModel">
              <el-row>
                <el-col>
                  <el-radio v-model="form.deviceBrandModel_label" :label="0">全部</el-radio>
                  <el-radio v-model="form.deviceBrandModel_label" :label="1">
                    定向 <span v-if="form.deviceBrandModel_label === 1">( {{ form.deviceBrandModel.length }} )</span>
                  </el-radio>
                  <el-radio v-model="form.deviceBrandModel_label" :label="2">
                    排除
                    <span v-if="form.deviceBrandModel_label === 2"> ( {{ form.deviceBrandModel.length }} ) </span>
                  </el-radio>
                </el-col>
                <el-col>
                  <div v-show="form.deviceBrandModel_label !== 0">
                    <SearchSelect v-model="form.deviceBrandModel" :options="included_type_list" multiple :props="{ label: 'name', value: 'id' }" style="width:700px" :placeholder="`请选择${form.deviceBrandModel_label === 1 ? '定向' : '排除'}手机品牌`" />
                    <el-checkbox class="ml-20" @change="(e) => selectAll(e, 'deviceBrandModel')">全选</el-checkbox>
                  </div>
                </el-col>
              </el-row>
            </el-form-item>

            <!-- 运营商 -->
            <el-form-item label="运营商：" prop="telcom">
              <el-row>
                <el-col :xl="20" :lg="18">
                  <el-checkbox-group v-model="form.telcom" @change="(value) => changeAllFormate(value, 'telcom')">
                    <el-checkbox label="">全部</el-checkbox>
                    <el-checkbox v-for="item in network_operator_list" :key="item.dictLabel" :label="item.dictValue">{{ item.dictLabel }}</el-checkbox>
                  </el-checkbox-group>
                </el-col>
              </el-row>
            </el-form-item>

            <!-- 联网方式 -->
            <el-form-item label="联网方式：" prop="connection">
              <el-checkbox-group v-model="form.connection" @change="(value) => changeAllFormate(value, 'connection')">
                <el-checkbox label="">全部</el-checkbox>
                <el-checkbox v-for="item in network_type_list" :key="item.dictLabel" :label="item.dictValue">{{ item.dictLabel }}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>

            <!-- 再营销： -->
            <el-form-item label="再营销：" prop="in_action_list">
              <el-checkbox-group v-model="form.in_action_list" @change="(value) => changeAllFormate(value, 'in_action_list')">
                <el-checkbox label="">不启用</el-checkbox>
                <el-checkbox v-for="item in marketing_list" :key="item.key" :disabled="form.not_in_action_list.includes(item.key)" :label="item.key">{{ item.value }}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>

            <!-- 排除营销： -->
            <el-form-item label="排除营销：" prop="not_in_action_list">
              <el-checkbox-group v-model="form.not_in_action_list" @change="(value) => changeAllFormate(value, 'not_in_action_list')">
                <el-checkbox label="">不启用</el-checkbox>
                <el-checkbox v-for="item in not_marketing_list" :key="item.key" :disabled="form.in_action_list.includes(item.key)" :label="item.key">{{ item.value }}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>

            <!-- 自定义人群 -->
            <el-form-item label="自定义人群：">
              <el-switch v-model="form.isCrowds" />
            </el-form-item>

            <el-form-item v-if="form.isCrowds" label="人群类型：" prop="isOnlineCrowd">
              <el-radio v-model="form.isOnlineCrowd" :label="false">人群模板</el-radio>
              <el-radio v-model="form.isOnlineCrowd" :label="true">线上人群包</el-radio>
            </el-form-item>

            <el-form-item v-if="form.isCrowds && !form.isOnlineCrowd" label="定向人群：" prop="in_dmp_audience">
              <el-button v-if="form.in_dmp_audience.length === 0" @click="selectCrowdPack('in_dmp_audience')">选择定向人群</el-button>
              <el-button v-else type="primary" @click="selectCrowdPack('in_dmp_audience')">{{ `已选 ( ${form.in_dmp_audience[0].name.substring(0, 5)}... 等 ${form.in_dmp_audience.length} 条数据 )` }}</el-button>
            </el-form-item>
            <el-form-item v-if="form.isCrowds && !form.isOnlineCrowd" label="排除人群：" prop="not_in_dmp_audience">
              <el-button v-if="form.not_in_dmp_audience.length === 0" @click="selectCrowdPack('not_in_dmp_audience')">选择排除人群</el-button>
              <el-button v-else type="primary" @click="selectCrowdPack('not_in_dmp_audience')">{{ `已选 ( ${form.not_in_dmp_audience[0].name.substring(0, 5)}... 等 ${form.not_in_dmp_audience.length} 条数据 )` }}</el-button>
            </el-form-item>
            <el-divider></el-divider>

            <el-form-item label="">
              <el-button type="primary" @click="onSubmit">{{ paramsId ? '修改' : '确定' }}</el-button>
              <router-link to="/wachatAudience" class="ml-20">
                <el-button>取消</el-button>
              </router-link>
            </el-form-item>
          </ModuleBox>
        </el-col>
      </el-row>
    </el-form>

    <el-dialog :title="interest_type === 'in_dmp_audience' ? '定向人群' : '排除人群'" :visible.sync="interest_visible" width="920px" :modal-append-to-body="false" destroy-on-close @open="open" @close="resetForm('interest')">
      <el-row :gutter="10">
        <el-col :span="16">
          <TablePane ref="TablePane" :data-source="dataSource" @getPage="getPage" />
        </el-col>
        <el-col :span="8">
          <p class="crowd-pack-selected-title">{{ interest_type === 'in_dmp_audience' ? '已选定向人群' : '已选排除人群' }}</p>
          <ul class="crowd-pack-selected">
            <li v-for="item in select_interest_list" :key="item.id">
              <span>{{ item.name }}</span>
              <i class="el-icon-circle-close" @click="deleteSelectInterest(item.id)"></i>
            </li>
          </ul>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="interestOnOk">确定</el-button>
        <el-button @click="interest_visible = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { audiencePage, getAdMpTargetingsOnly, targetingsSave, targetingsTargetUpdate } from '@/network/api/assetManagement/assetManageWeChatAdv.js'
import { getTargetingTags, getNewOne, selectOne } from '@/network/api/assetManagement/assetManageTencent'
import { age_min_list, age_max_list, not_marketing_list, marketing_list, residential_community_price_min, residential_community_price_max, temperature_min, temperature_max } from '../../data'
import TablePane from '@/components/tablePane.vue'
import accountUinTencent from '@/mixin/accountUin_tencent'
import ModuleBox from './moduleBox.vue'
import { jsonFormat } from '@/utils/ruoyi'
import Area from './area.vue'
import Interest from './interest'
import Behavior from './behavior'

export default {
  components: {
    TablePane,
    ModuleBox,
    Area,
    Interest,
    Behavior
  },
  mixins: [accountUinTencent],
  data() {
    return {
      accountId: 0,
      age_min_list,
      age_max_list,
      residential_community_price_min,
      residential_community_price_max,
      temperature_min,
      temperature_max,
      marketing_list,
      not_marketing_list,
      interest_visible: false,
      interest_type: 'in_dmp_audience', // not_in_dmp_audience
      select_interest_list: [],
      form: {
        name: null,
        projectId: null,
        productId: 0,
        areaType: 'area',
        // 地域信息
        area: [],
        areaContent: [],
        // 性别
        gender: 'all',
        // 年龄
        age: [14, 66],
        // 兴趣行为
        behaviorAndInterest: 'behavior',
        // 行为
        interest_label: '',
        interest: {
          category: [],
          keyword_detail: [],
          keyword: []
        },
        // 兴趣
        behavior: {
          category: [],
          keyword_detail: [],
          scene: [],
          keyword: []
        },
        // 学历
        education: [''],
        // 操作系统
        os: ['ANDROID', 'IOS'],
        // 设备价格
        devicePrice: [''],
        // 设备品牌
        deviceBrandModel_label: 0,
        deviceBrandModel: [],
        // 联网方式
        connection: [''],
        // 移动运营商
        telcom: [''],
        // 在营销
        in_action_list: [''],
        // 排除营销
        not_in_action_list: [''],

        // 自定义人群
        isCrowds: false,
        // 人群类型
        isOnlineCrowd: false,
        // 定向人群
        in_dmp_audience: [],
        // 排除人群
        not_in_dmp_audience: []
      },
      education_list: [], // 学历 list
      // network_scene_list: [], // 上网场景
      network_type_list: [], // 联网方式
      network_operator_list: [], // 移动运营商
      device_price_list: [], // 设备价格
      included_type_list: [], // 设备名称List
      paramsId: this.$route.params.id,
      nowPage_interest_list: [],
      dataSource: {
        data: [],
        cols: [
          {
            label: '人群包名称',
            prop: 'name'
          },
          {
            label: '上传人',
            prop: 'createBy'
          },
          {
            label: '上传时间',
            prop: 'createTime'
          }
        ], // 表格的列数据
        isIndex: true, // 列表序号
        loading: false, // loading
        isOperation: false,
        isSelection: true,
        pageData: {
          total: 0, // 总条数
          layout: 'total, prev, pager, next'
        },
        selectable: (row) => {
          if (this.interest_type === 'in_dmp_audience') {
            if (this.form.not_in_dmp_audience && this.form.not_in_dmp_audience.length) {
              return !this.form.not_in_dmp_audience.some((s) => s.id === row.id)
            } else {
              return true
            }
          } else {
            if (this.form.in_dmp_audience && this.form.in_dmp_audience.length) {
              return !this.form.in_dmp_audience.some((s) => s.id === row.id)
            } else {
              return true
            }
          }
        },

        selectAll: (selection) => {
          if (selection.length > 0) {
            let selectionList = []
            selectionList.push(...selection)
            let list = []
            selectionList.map((item) => {
              if (list.length === 0 || !list.some((s) => s.id === item.id)) {
                list.push(item)
              }
            })

            let arr = [...this.select_interest_list, ...list]

            let newArr = []
            arr.forEach((item) => {
              if (newArr.length === 0 || !newArr.some((s) => s.id === item.id)) {
                newArr.push(item)
              }
            })

            this.select_interest_list = newArr
          } else {
            let ids = this.nowPage_interest_list.map((item) => item.id)
            this.select_interest_list = this.select_interest_list.filter((f) => !ids.includes(f.id))
          }
        },
        select: (selection, row) => {
          if (this.select_interest_list.some((s) => s.id === row.id)) {
            this.select_interest_list = this.select_interest_list.filter((f) => f.id !== row.id)
          } else {
            this.select_interest_list.push(...selection)
            let list = []
            this.select_interest_list.map((item) => {
              if (list.length === 0 || !list.some((s) => s.id === item.id)) {
                list.push(item)
              }
            })
            this.select_interest_list = list
          }
        }
      }
    }
  },

  mounted() {
    // 操作系统list
    // this.getDicts('gdt_api_marriage_status').then((response) => {
    //   this.marital_status_list = response.data
    // })
    this.getDicts('gdt_api_education').then((response) => {
      this.education_list = response.data
    })
    // this.getDicts('gdt_api_living_status').then((response) => {
    //   this.working_status_list = response.data
    // })
    // 上网场景
    // this.getDicts('gdt_api_online_scenario').then((response) => {
    //   this.network_scene_list = response.data
    // })
    // 联网方式
    this.getDicts('gdt_api_network_type').then((response) => {
      this.network_type_list = response.data
    })
    // 联网方式
    this.getDicts('gdt_api_network_operator').then((response) => {
      this.network_operator_list = response.data
    })
    // 设备价格
    this.getDicts('gdt_api_device_price').then((response) => {
      this.device_price_list = response.data
    })
    // 设备价格名称
    getNewOne().then(({ code, data }) => {
      if (code === 200 && Object.hasOwnProperty.call(data, 'accountId')) {
        getTargetingTags({ accountId: data.accountId, type: 'DEVICE_BRAND_MODEL', tagSpec: null }).then((res) => {
          this.included_type_list = res.data
        })
      }
    })

    // 设备品牌型号
    selectOne().then((res) => {
      if (res.code === 200) {
        this.accountId = res.data.accountId
      }
    })
    this.editOrientation()
  },

  methods: {
    async editOrientation() {
      if (this.$route.params.id) {
        const { data, code } = await getAdMpTargetingsOnly(this.$route.params.id)
        if (code) {
          let { name, projectId, productId, area, areaType, isOnlineCrowd, areaContent, education, os, gender, age, devicePrice, connection, inDmpAudienceName, notInDmpAudienceName, telcom, behaviorInterest, deviceBrandModel, adBehavior } = data

          console.log(jsonFormat(deviceBrandModel).length === 0 ? 0 : jsonFormat(deviceBrandModel).map((element) => Math.abs(element)))

          let ageNum = jsonFormat(age)[0].split('~')
          let interest = jsonFormat(behaviorInterest, 'Object').interest ? jsonFormat(behaviorInterest, 'Object').interest : { category: [] }
          let behavior = jsonFormat(behaviorInterest, 'Object').behavior ? jsonFormat(behaviorInterest, 'Object').behavior[0] : { category: [] }
          let interestCategory = interest.category.map((item) => item + '')
          let behaviorCategory = behavior.category.map((item) => +item + '')

          this.form = {
            name,
            projectId: +projectId,
            productId: +productId,
            areaType: areaType,
            area: jsonFormat(area),
            areaContent: jsonFormat(areaContent),
            gender: jsonFormat(gender).length === 0 ? 'all' : jsonFormat(gender)[0],
            age: [+ageNum[0], +ageNum[1]],
            education: this.formateJson(education), // 学历
            os: jsonFormat(os), // 操作系统
            devicePrice: this.formateJson(devicePrice), // 设备价格
            connection: this.formateJson(connection), // 联网方式
            deviceBrandModel: jsonFormat(deviceBrandModel).length === 0 ? [] : jsonFormat(deviceBrandModel).map((element) => Math.abs(element) + ''), // 手机品牌
            deviceBrandModel_label: jsonFormat(deviceBrandModel).length === 0 ? 0 : jsonFormat(deviceBrandModel)[0] > 0 ? 1 : 2,
            telcom: this.formateJson(telcom), // 移动运营商
            in_action_list: jsonFormat(adBehavior)[0].in_action_list.length ? jsonFormat(adBehavior)[0].in_action_list : [''], // 在营销
            not_in_action_list: jsonFormat(adBehavior)[0].not_in_action_list.length ? jsonFormat(adBehavior)[0].not_in_action_list : [''], // 排除营销
            // 行为
            interest: { ...interest, category: interestCategory },
            // 兴趣
            behavior: { ...behavior, category: behaviorCategory },
            behaviorAndInterest: interest.category.length ? 'interest' : 'behavior',
            isOnlineCrowd,
            in_dmp_audience: jsonFormat(inDmpAudienceName), // 定向人群
            not_in_dmp_audience: jsonFormat(notInDmpAudienceName), // 排除人群
            isCrowds: !!(jsonFormat(inDmpAudienceName).length || jsonFormat(notInDmpAudienceName).length) || isOnlineCrowd
          }
        }
      }
    },

    // 格式化数组JSON
    formateJson(val) {
      let data = jsonFormat(val)
      return data.length === 0 ? [''] : data
    },
    /**
     * @description 全选
     */
    selectAll(flag, type) {
      if (type === 'deviceBrandModel') {
        if (flag) {
          const list = this.included_type_list.map((item) => item.id)
          this.form.deviceBrandModel = list
        } else {
          this.form.deviceBrandModel = []
        }
      }
    },

    getCascaderData(data) {
      let newData = data.filter((f) => f.parent_id === '0')
      data.map((item) => {
        newData.map((newItem) => {
          if (newItem.id === item.parent_id) {
            newItem.children = newItem.children || []
            newItem.children.push(item)
          }
        })
      })
      return newData
    },

    /**
     * @description: 获取人群
     * @param {*} params
     */

    open() {
      this.getPage()
    },
    getPage(params = { pageNum: 1, pageSize: 10 }) {
      this.dataSource.loading = true
      audiencePage({ ...params }).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          this.dataSource.data = records
          this.nowPage_interest_list = records
          this.dataSource.pageData.total = total
          this.dataSource.pageData.pageNum = params.pageNum
          this.dataSource.loading = false
          this.setSelectData()
        }
      })
    },

    setSelectData() {
      this.$refs['TablePane'].getRowData([])
      if (this.select_interest_list.length) {
        let rows = []
        this.dataSource.data.map((item) => {
          if (this.select_interest_list.some((s) => s.id === item.id)) {
            rows.push(item)
          }
        })

        this.$refs['TablePane'].getRowData(rows)
      }
    },

    /**
     * @description 打开人群包弹框
     */
    selectCrowdPack(type) {
      this.interest_type = type
      this.interest_visible = true
      if (this.interest_type === 'in_dmp_audience') {
        this.select_interest_list = this.form.in_dmp_audience
      } else {
        this.select_interest_list = this.form.not_in_dmp_audience
      }
    },

    /**
     * @description 删除选中的人群包
     */
    deleteSelectInterest(id) {
      this.select_interest_list = this.select_interest_list.filter((f) => f.id !== id)
      this.setSelectData()
    },

    /**
     * @description 确认选中
     */
    interestOnOk() {
      if (this.interest_type === 'in_dmp_audience') {
        this.form.in_dmp_audience = this.select_interest_list
      } else {
        this.form.not_in_dmp_audience = this.select_interest_list
      }
      this.interest_visible = false
      this.resetForm('interest')
    },

    /**
     * @desc 选择全部时取消其他选择
     */
    changeAllFormate(value, type) {
      if (value[value.length - 1] === '') {
        this.form[type] = ['']
      } else {
        this.form[type] = value.filter((f) => f !== '')
      }
    },

    onSubmit() {
      // if (this.form.interest.category.length === 0 && this.form.behavior.category.length === 0) {
      //   return this.$message.error('请选择行为兴趣')
      // }
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          let params = { ...this.form }
          // 将['']设置为[]
          for (const key in params) {
            const element = params[key]
            if (Array.isArray(element) && element.length === 1 && element[0] === '') {
              params[key] = []
            }
          }
          // 在营销, 排除营销格式化
          params.adBehavior = [
            {
              in_action_list: params.in_action_list,
              not_in_action_list: params.not_in_action_list
            }
          ]

          params.projectName = this.projectList.filter((item) => +item.bsProjectId === +params.projectId)[0].projectName
          console.log(this.productListed)
          let product = params.productId !== 0 ? this.productListed.filter((item) => +item.bsProductId === +params.productId) : []
          if (product.length === 0 && params.productId !== 0) return this.$message.error('请重新选择产品信息!')
          params.productName = params.productId !== 0 ? product[0].productName : '不限'
          // 兴趣行为格式化
          let interestCategory = params.interest.category.map((item) => +item)
          let behaviorCategory = params.behavior.category.map((item) => +item)

          params.behaviorInterest = {}

          if (params.interest.category.length) {
            params.behaviorInterest = {
              ...params.behaviorInterest,
              interest: { ...params.interest, category: interestCategory, keyword_detail: [], keyword: [] }
            }
          } else {
            delete params.behaviorInterest.interest
          }

          if (params.behavior.category.length) {
            params.behaviorInterest = {
              ...params.behaviorInterest,
              behavior: [{ ...params.behavior, category: behaviorCategory, keyword_detail: [], keyword: [], scene: [1], time_window: [6], strength: [1] }]
            }
          } else {
            delete params.behaviorInterest.behavior
          }

          // params.behaviorInterest = {
          //   interest: { ...params.interest, category: interestCategory, keyword_detail: [], keyword: [] },
          //   behavior: [{ ...params.behavior, category: behaviorCategory, keyword_detail: [], keyword: [], scene: [1], time_window: [6], strength: [1] }]
          // }
          params.gender = params.gender === 'all' ? [] : [params.gender]
          // 手机品牌格式化
          let brandModel = Array.isArray(params.deviceBrandModel) ? params.deviceBrandModel.map((el) => ~(+el - 1)) : []
          params.deviceBrandModel = params.deviceBrandModel_label === 2 ? brandModel : params.deviceBrandModel.map((item) => +item)
          // 人群格式化
          params.inDmpAudienceName = params.isCrowds ? [...params.in_dmp_audience] : []
          params.notInDmpAudienceName = params.isCrowds ? [...params.not_in_dmp_audience] : []
          params.inDmpAudience = params.isCrowds ? params.in_dmp_audience.map((item) => item.id) : [] // 定向人群
          params.notInDmpAudience = params.isCrowds ? params.not_in_dmp_audience.map((item) => item.id) : [] // 排除人群
          // 删除辅助字段
          delete params.behavior
          delete params.interest
          delete params.in_action_list
          delete params.not_in_action_list
          delete params.interest_label
          delete params.deviceBrandModel_label
          delete params.behaviorAndInterest
          delete params.isCrowds
          console.log(params)
          // 将字段JSON

          Object.keys(params).forEach((key) => {
            const element = params[key]
            if (key === 'age') {
              let age = [`${element[0]}~${element[1]}`]
              params[key] = JSON.stringify(age)
            } else if (!['name', 'projectName', 'productName', 'areaType'].includes(key)) {
              params[key] = JSON.stringify(element)
            }
          })

          if (this.$route.params.id) {
            const { code } = await targetingsTargetUpdate({ ...params, id: this.$route.params.id })
            if (code === 200) {
              this.$message.success('修改成功!')
            }
          } else {
            const { code } = await targetingsSave(params)
            if (code === 200) {
              this.$message.success('创建成功!')
            }
          }
          this.$router.push('/wachatAudience')
        }
      })
    },

    resetForm(type) {
      if (type === 'interest') {
        this.select_interest_list = []
      }
    },

    // 获取选中的兴趣
    changeBehavior(value) {
      value.category.map((item) => +item)

      this.form.behavior = value
    },

    // 获取选中的兴趣
    changeInterest(value) {
      value.category.map((item) => +item)
      this.form.interest = value
    },

    // 获取选中的地区
    changeArea(value) {
      this.form.area = value.area
      this.form.areaContent = value.areaContent
    }
  }
}
</script>

<style lang="scss">
.city-box {
  .el-cascader__tags {
    max-height: 240px;
    overflow-y: auto;
  }
}
.el-cascader-panel {
  .el-cascader-menu__wrap {
    height: 320px;
  }
}
</style>

<style lang="scss" scoped>
p {
  margin: 0;
  padding: 0;
}

.background {
  background: #f7f8fa;
  padding: 10px;
  border-radius: 10px;
}

.crowd-pack-selected-title {
  border-left: 3px solid #409eff;
  padding-left: 5px;
  margin: 0 0 5px 0;
  font-weight: 600;
}
.crowd-pack-selected {
  display: flex;
  flex-direction: column;
  max-height: 486px;
  overflow-y: auto;
  li {
    width: 100%;
    display: flex;
    padding: 4px 0;
    align-items: center;
    font-size: 14px;
    span {
      width: 80%;
      text-align: right;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      flex: 1;
      margin-right: 10px;
    }
    i {
      width: 20px;
      cursor: pointer;
      font-weight: 600;
    }
  }
}
</style>
