<!--
 * @Author: Zeng
 * @Date: 2023-07-11 16:48:47
 * @LastEditTime: 2023-08-07 18:12:07
-->
<template>
  <div class="projection-container">
    <div class="projection-header"><span>投测数据</span></div>
    <div class="projection-content">
      <el-row :gutter="24">
        <el-col :span="24">
          <div class="projection-item-header">
            <span class="title">人员投测</span>
            <div class="item-search">
              <el-input v-model="user.userName" type="text" placeholder="人员名称" size="mini" clearable
                style="width: 120px;margin-right: 10px;"></el-input>
              <el-cascader ref="cascader" v-model="user.deptId" :options="deptList" :props="cascaderProp"
                :show-all-levels="false" filterable clearable placeholder="请选择部门" size="mini"
                style="width: 120px;margin-right: 10px;" @change="handleChangeDept"></el-cascader>
              <SearchDate v-model="user.dates" />
            </div>
          </div>

          <el-table v-loading="user.loading" class="list-table" :data="user.list" stripe size="small">
            <el-empty slot="empty" description="暂无数据"></el-empty>
            <el-table-column label="日期" prop="showDate" align="center"></el-table-column>
            <el-table-column label="部门" prop="deptName" align="center"></el-table-column>
            <el-table-column label="姓名" prop="userName" align="center"></el-table-column>
            <el-table-column label="新增投测" prop="newAddLaunchTestNum" align="center"></el-table-column>
            <el-table-column label="待测试" prop="waitTestNum" align="center"></el-table-column>
            <el-table-column label="测试中" prop="progressTest" align="center"></el-table-column>
            <el-table-column label="已完成" prop="completeNum" align="center"></el-table-column>
            <el-table-column label="新增起量" prop="newAddAmount" align="center"></el-table-column>
            <el-table-column label="累计起量" prop="sumAmount" align="center"></el-table-column>
            <el-table-column label="累计起量率" prop="" align="center">
              <template slot-scope="{row}">{{ row.sumAmountRate }}%</template>
            </el-table-column>
          </el-table>
          <div class="page">
            <paginations :total="user.total" :page.sync="user.pageNum" :limit.sync="user.pageSize"
              layout="prev, pager, next" @pagination="getUserPage" small />
          </div>
        </el-col>

        <el-col :span="24">
          <el-divider></el-divider>
          <div class="projection-item-header">
            <span class="title">平台起量</span>
            <SearchDate v-model="platform.dates" />
          </div>
          <el-table v-loading="platform.loading" class="list-table" :data="platform.list" stripe size="small">
            <el-empty slot="empty" description="暂无数据"></el-empty>
            <el-table-column label="平台" prop="platform" align="center">
              <template slot-scope="{row}">
                {{ platformList.find(item => item.dictValue === row.platform)?.dictLabel }}
              </template>
            </el-table-column>
            <el-table-column label="投测数" prop="launchTestNum" align="center"></el-table-column>
            <el-table-column label="起量数" prop="startAmountNum" align="center"></el-table-column>
            <el-table-column label="累计起量率" prop="sumAmountRate" align="center">
              <template slot-scope="{row}">{{ row.sumAmountRate }}%</template>
            </el-table-column>
            <el-table-column label="视频号" prop="video" align="center">
              <template slot-scope="{row}">
                <div class="list-item" v-for="(item, index) in row.videoStatic" :key="index">
                  <span class="label">{{ item.label }}：</span>
                  <span class="value">{{ item.value }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="XS" prop="xs" align="center">
              <template slot-scope="{row}">
                <div class="list-item" v-for="(item, index) in row.xsStatic" :key="index">
                  <span class="label">{{ item.label }}：</span>
                  <span class="value">{{ item.value }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="XQ" prop="xq" align="center">
              <template slot-scope="{row}">
                <div class="list-item" v-for="(item, index) in row.xqStatic" :key="index">
                  <span class="label">{{ item.label }}：</span>
                  <span class="value">{{ item.value }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="朋友圈" prop="friendCircle" align="center">
              <template slot-scope="{row}">
                <div class="list-item" v-for="(item, index) in row.friendCircleStatic" :key="index">
                  <span class="label">{{ item.label }}：</span>
                  <span class="value">{{ item.value }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="公众号" prop="officialAccount" align="center">
              <template slot-scope="{row}">
                <div class="list-item" v-for="(item, index) in row.officialAccountStatic" :key="index">
                  <span class="label">{{ item.label }}：</span>
                  <span class="value">{{ item.value }}</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="page">
            <paginations :total="platform.total" :page.sync="platform.pageNum" :limit.sync="platform.pageSize"
              layout="prev, pager, next" @pagination="getPlatformPage" small />
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import SearchDate from './SearchDate'
import { staticsUserLaunch, staticsPlatLaunch } from '@/network/api/toolManagement/api-product-test'
export default {
  components: {
    SearchDate
  },
  props: {
    deptList: Array
  },
  data() {
    return {
      cascaderProp: {
        emitPath: false,
        checkStrictly: true,
        value: 'departmentId',
        label: 'departmentName',
        children: 'children'
      },
      platformList: [],
      platform: {
        list: [],
        loading: false,
        total: 0,
        pageSize: 10,
        pageNum: 1,
        dates: []
      },
      user: {
        list: [],
        loading: false,
        total: 0,
        pageSize: 10,
        pageNum: 1,
        userName: '',
        deptId: '',
        dates: []
      }
    }
  },

  watch: {
    'user.userName'() {
      this.resetUserPage()
    },
    'user.dates': {
      handler() {
        this.resetUserPage()
      },
      deep: true
    },
    'platform.dates': {
      handler() {
        this.resetPlatformPage()
      },
      deep: true
    }
  },

  mounted() {
    this.getDicts('return_platform_type').then((response) => {
      this.platformList = response.data
    })
  },
  methods: {

    // 选中部门
    handleChangeDept() {
      this.$refs.cascader.dropDownVisible = false
      this.resetUserPage()
    },

    // 重置人员数据
    resetUserPage() {
      this.user.pageNum = 1
      this.getUserPage()
    },

    // 重置平台数据
    resetPlatformPage() {
      this.platform.pageNum = 1
      this.getPlatformPage()
    },

    getUserPage() {
      const { pageNum, pageSize, dates, deptId, userName } = this.user
      let params = {
        pageNum,
        pageSize,
        deptId,
        userName,
        dates
      }
      this.user.loading = true
      staticsUserLaunch(params).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          this.user.list = records || []
          this.user.total = total
        }
      }).finally(() => {
        this.user.loading = false
      })
    },

    getPlatformPage() {
      const { pageNum, pageSize, dates } = this.platform
      this.platform.loading = true
      staticsPlatLaunch(dates, pageNum, pageSize).then(({ code, data }) => {
        if (data.code === 200) {
          let { records, total } = data.data
          let list = records.map(v => {
            v.videoStatic = this.computedSiteArr('video', v)
            v.xsStatic = this.computedSiteArr('xs', v)
            v.xqStatic = this.computedSiteArr('xq', v)
            v.friendCircleStatic = this.computedSiteArr('friendCircle', v)
            v.officialAccountStatic = this.computedSiteArr('officialAccount', v)
            return v
          })
          this.platform.list = list || []
          this.platform.total = total
        }
      }).finally(() => {
        this.platform.loading = false
      })
    },

    // 计算平台起量数据
    computedSiteArr(type, data) {
      let arr = []
      let list = data ? JSON.parse(data[type]) : {}
      for (let key in list) {
        arr.push({
          label: key,
          value: list[key]
        })
      }
      return arr || []
    }
  }
}
</script>

<style scoped lang="scss">
.list-item {
  text-align: left;
  .label {
    display: inline-block;
    width: 50px;
    text-align: right;
    margin-right: 5px;
  }
}
</style>
