/*
 * @Description: Do not edit
 * @Date: 2021-10-13 16:03:30
 * @LastEditTime: 2022-05-23 11:14:04
 */
// import service from '@/network/service/service'

// export function Page(data) {
//   return service.post('/api/system/assetManagementHugeLandingPageTemp/page', data)
// }

// export function Add(data) {
//   return service.post('/api/system/assetManagementHugeLandingPageTemp/save', data)
// }

// export function Update(data) {
//   return service.post('/api/system/assetManagementHugeLandingPageTemp/update', data)
// }

// export function Delete(id) {
//   return service.delete('/api/system/assetManagementHugeLandingPageTemp/' + id)
// }
import service from '@/network/service/service'

export function Page(data) {
  return service.post('/api/system/cwOceanenginePage/page', data)
}

export function Add(data) {
  return service.post('/api/system/cwOceanenginePage/save', data)
}

export function Update(data) {
  return service.post('/api/system/cwOceanenginePage/update', data)
}

// /擦边落地页保
export function formalSave(data) {
  return service.post('/api/system/cwOceanenginePage/formalSave', data)
}

export function formalUpdate(data) {
  return service.post('/api/system/cwOceanenginePage/formalUpdate', data)
}

export function getFormal(id) {
  return service.get(`/api/system/cwOceanenginePage/getFormal/${id}`)
}

export function Delete(id) {
  return service.delete('/api/system/cwOceanenginePage/' + id)
}

export function getWechatAccount(params) {
  return service.get(`/api/system/dcWechatAccount/getAll?ghid=${params}&name=${params}`)
}

export function getWechatAccountLike(params) {
  return service.get(`/api/system/dcWechatAccount/getAllLike?ghid=${params}&name=${params}`)
}

export function buildThirdLandingPage(data) {
  return service.post(`/api/system/cwOceanenginePage/buildThirdLandingPage`, data)
}

export function getPageDomain(data) {
  return service.post(`/api/system/cwOceanenginePageDomain/page`, data)
}

export function getUrl(id) {
  return service.get(`/api/system/cwOceanenginePage/getCommonPageInfo/${id}`)
}

export function getQuickAppPromotionId(data) {
  return service.post(`/api/system/dcWechatAccount/getPromotionIdLike`, data)
}
