import service from '@/network/service/service'

/**
 * 获取用户信息
 */
export function getUserInfo() {
  return service.get('/api/system/admin/getAdminInfo')
}

/**
 * 获取菜单
 */
export function getMenuList(params) {
  return service.get('/api/system/admin/getMenuAndRole', { params })
}

/**
 * 退出登录
 */
export function logout(params) {
  return service.get('/api/system/admin/logout', { params })
}

/**
 * @description 文件上传
 * @param params 文件流
 */
export function uploadFile(params) {
  return service.post(process.env.VUE_APP_UPLOAD_URL, params)
}

/**
 * @description 上传模板（超时设置为 30秒）
 * @param path 上传接口路径
 * @param params 接口参数
 */
export function uploadTemp(path, params) {
  return service.post(`/api${path}`, params, { timeout: 30000 })
}
