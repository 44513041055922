var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "projection-container" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "projection-content" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 24 } },
          [
            _c(
              "el-col",
              { attrs: { span: 24 } },
              [
                _c("div", { staticClass: "projection-item-header" }, [
                  _c("span", { staticClass: "title" }, [_vm._v("人员投测")]),
                  _c(
                    "div",
                    { staticClass: "item-search" },
                    [
                      _c("el-input", {
                        staticStyle: { width: "120px", "margin-right": "10px" },
                        attrs: {
                          type: "text",
                          placeholder: "人员名称",
                          size: "mini",
                          clearable: "",
                        },
                        model: {
                          value: _vm.user.userName,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "userName", $$v)
                          },
                          expression: "user.userName",
                        },
                      }),
                      _c("el-cascader", {
                        ref: "cascader",
                        staticStyle: { width: "120px", "margin-right": "10px" },
                        attrs: {
                          options: _vm.deptList,
                          props: _vm.cascaderProp,
                          "show-all-levels": false,
                          filterable: "",
                          clearable: "",
                          placeholder: "请选择部门",
                          size: "mini",
                        },
                        on: { change: _vm.handleChangeDept },
                        model: {
                          value: _vm.user.deptId,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "deptId", $$v)
                          },
                          expression: "user.deptId",
                        },
                      }),
                      _c("SearchDate", {
                        model: {
                          value: _vm.user.dates,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "dates", $$v)
                          },
                          expression: "user.dates",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.user.loading,
                        expression: "user.loading",
                      },
                    ],
                    staticClass: "list-table",
                    attrs: { data: _vm.user.list, stripe: "", size: "small" },
                  },
                  [
                    _c("el-empty", {
                      attrs: { slot: "empty", description: "暂无数据" },
                      slot: "empty",
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "日期",
                        prop: "showDate",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "部门",
                        prop: "deptName",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "姓名",
                        prop: "userName",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "新增投测",
                        prop: "newAddLaunchTestNum",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "待测试",
                        prop: "waitTestNum",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "测试中",
                        prop: "progressTest",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "已完成",
                        prop: "completeNum",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "新增起量",
                        prop: "newAddAmount",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "累计起量",
                        prop: "sumAmount",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { label: "累计起量率", prop: "", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [_vm._v(_vm._s(row.sumAmountRate) + "%")]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "page" },
                  [
                    _c("paginations", {
                      attrs: {
                        total: _vm.user.total,
                        page: _vm.user.pageNum,
                        limit: _vm.user.pageSize,
                        layout: "prev, pager, next",
                        small: "",
                      },
                      on: {
                        "update:page": function ($event) {
                          return _vm.$set(_vm.user, "pageNum", $event)
                        },
                        "update:limit": function ($event) {
                          return _vm.$set(_vm.user, "pageSize", $event)
                        },
                        pagination: _vm.getUserPage,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-col",
              { attrs: { span: 24 } },
              [
                _c("el-divider"),
                _c(
                  "div",
                  { staticClass: "projection-item-header" },
                  [
                    _c("span", { staticClass: "title" }, [_vm._v("平台起量")]),
                    _c("SearchDate", {
                      model: {
                        value: _vm.platform.dates,
                        callback: function ($$v) {
                          _vm.$set(_vm.platform, "dates", $$v)
                        },
                        expression: "platform.dates",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.platform.loading,
                        expression: "platform.loading",
                      },
                    ],
                    staticClass: "list-table",
                    attrs: {
                      data: _vm.platform.list,
                      stripe: "",
                      size: "small",
                    },
                  },
                  [
                    _c("el-empty", {
                      attrs: { slot: "empty", description: "暂无数据" },
                      slot: "empty",
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "平台",
                        prop: "platform",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.platformList.find(
                                      (item) => item.dictValue === row.platform
                                    )?.dictLabel
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "投测数",
                        prop: "launchTestNum",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "起量数",
                        prop: "startAmountNum",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "累计起量率",
                        prop: "sumAmountRate",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [_vm._v(_vm._s(row.sumAmountRate) + "%")]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "视频号",
                        prop: "video",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return _vm._l(
                              row.videoStatic,
                              function (item, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "list-item" },
                                  [
                                    _c("span", { staticClass: "label" }, [
                                      _vm._v(_vm._s(item.label) + "："),
                                    ]),
                                    _c("span", { staticClass: "value" }, [
                                      _vm._v(_vm._s(item.value)),
                                    ]),
                                  ]
                                )
                              }
                            )
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "XS", prop: "xs", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return _vm._l(row.xsStatic, function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "list-item" },
                                [
                                  _c("span", { staticClass: "label" }, [
                                    _vm._v(_vm._s(item.label) + "："),
                                  ]),
                                  _c("span", { staticClass: "value" }, [
                                    _vm._v(_vm._s(item.value)),
                                  ]),
                                ]
                              )
                            })
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: { label: "XQ", prop: "xq", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return _vm._l(row.xqStatic, function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "list-item" },
                                [
                                  _c("span", { staticClass: "label" }, [
                                    _vm._v(_vm._s(item.label) + "："),
                                  ]),
                                  _c("span", { staticClass: "value" }, [
                                    _vm._v(_vm._s(item.value)),
                                  ]),
                                ]
                              )
                            })
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "朋友圈",
                        prop: "friendCircle",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return _vm._l(
                              row.friendCircleStatic,
                              function (item, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "list-item" },
                                  [
                                    _c("span", { staticClass: "label" }, [
                                      _vm._v(_vm._s(item.label) + "："),
                                    ]),
                                    _c("span", { staticClass: "value" }, [
                                      _vm._v(_vm._s(item.value)),
                                    ]),
                                  ]
                                )
                              }
                            )
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "公众号",
                        prop: "officialAccount",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return _vm._l(
                              row.officialAccountStatic,
                              function (item, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "list-item" },
                                  [
                                    _c("span", { staticClass: "label" }, [
                                      _vm._v(_vm._s(item.label) + "："),
                                    ]),
                                    _c("span", { staticClass: "value" }, [
                                      _vm._v(_vm._s(item.value)),
                                    ]),
                                  ]
                                )
                              }
                            )
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "page" },
                  [
                    _c("paginations", {
                      attrs: {
                        total: _vm.platform.total,
                        page: _vm.platform.pageNum,
                        limit: _vm.platform.pageSize,
                        layout: "prev, pager, next",
                        small: "",
                      },
                      on: {
                        "update:page": function ($event) {
                          return _vm.$set(_vm.platform, "pageNum", $event)
                        },
                        "update:limit": function ($event) {
                          return _vm.$set(_vm.platform, "pageSize", $event)
                        },
                        pagination: _vm.getPlatformPage,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "projection-header" }, [
      _c("span", [_vm._v("投测数据")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }