<!--
 * @Description: 自定义回传
 * @Date: 2021-10-21 09:12:51
 * @LastEditTime: 2024-02-26 11:04:35
-->
<template>
  <div>
    <div class="return-condition-custom">
      <el-form-item label="是否首充 " :rules="[{ required: true }]" prop="is_first_recharge">
        <el-radio-group v-model="ruleData.is_first_recharge">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="2">否</el-radio>
        </el-radio-group>
      </el-form-item>

      <div class="attention">
        <el-form-item label="时间条件 " :rules="[{ required: true }]" prop="attention_type">
          <el-radio-group v-model="ruleData.attention_type">
            <el-radio :label="1">关注当天</el-radio>
            <el-radio :label="2">关注后</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item>
          <div class="align-center">
            <span> {{ ruleData.attention_type === 1 ? '关注当天 ' : '关注后 ' }} </span>
            <el-form-item style="width: 100px; margin: 0 47px 0 10px" prop="attention_time">
              <el-inputNumber v-model="ruleData.attention_time" :min="0" :max="ruleData.attention_type === 1 ? 23 : 999"
                size="small" />
            </el-form-item>
            <span>小时内的</span>
            <!-- {{ ruleData.attention_type === 1 ? '(不填则为当天自然日内都符合条件)' : '' }} -->
          </div>
        </el-form-item>
      </div>

      <div class="attention">
        <el-form-item label="回传方式 " :rules="[{ required: true }]" prop="attention_type">
          <el-select v-model="ruleData.return_type" placeholder="请选择回传方式">
            <el-option v-for="item in returnTypes0" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>

          <el-select v-if="ruleData.return_type === 2" v-model="ruleData.cycle_rule" class="pl-10" placeholder="请选择循环方式">
            <el-option v-for="item in returnTypes1" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-select v-if="ruleData.return_type === 2" v-model="ruleData.is_first_return" class="pl-10"
            placeholder="请选择循环卡控条件">
            <el-option v-for="item in returnTypes2" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </div>

      <el-form-item label="订单金额" :rules="[{ required: true }]">
        <el-button icon="el-icon-plus" type="primary" class="mb-10" @click="addOrderAmountRule"></el-button>
        <!-- <> -->
        <!-- <PlusCircleOutlined style="{{fontSize:" 20}} on-click="{addOrderAmountRule}/"> -->

        <div v-if="ruleData.order_amount_rule.length">
          <el-table :data="ruleData.order_amount_rule" style="width: 100%" border>
            <el-table-column label="是否符合" type="key" align="center" width="100">
              <template slot-scope="{ row }">
                <p v-if="row.verify" style="color:#409EFF">验证通过</p>
                <p v-else style="color:#ff0000">条件有误</p>
              </template>
            </el-table-column>

            <el-table-column label="下限条件" type="amount_off_condition" align="center" width="120">
              <template slot-scope="{ row }">
                <el-select :value="row.amount_off_condition" size="small" @change="(value) => {
                  setOrderAmountRule(row.key, 'amount_off_condition', value)
                }
                  ">
                  <el-option v-for="item in orderOperatorOption" :key="item.value"
                    :disabled="conditionDisabled2(row).includes(item.value) ? true : false" :label="item.title"
                    :value="item.value"> </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="下限金额" type="amount_off" align="center">
              <template slot-scope="{ row }">
                <el-input-number :value="row.amount_off" :min="0" size="small" style="width: 100%;"
                  controls-position="right" @change="(value) => {
                    setOrderAmountRule(row.key, 'amount_off', value)
                  }
                    " />
                <!-- {{ row.amount_off }} -->
                <!-- <el-input v-model="row.amount_off"></el-input> -->
              </template>
            </el-table-column>
            <el-table-column label="上限条件" type="amount_on_condition" align="center" width="120">
              <template slot-scope="{ row }">
                <el-select :value="row.amount_on_condition" size="small" style="width: 100%;" @change="(value) => {
                  setOrderAmountRule(row.key, 'amount_on_condition', value)
                }
                  ">
                  <el-option v-for="item in orderOperatorOption" :key="item.value"
                    :disabled="conditionDisabled(row).includes(item.value) ? true : false" :label="item.title"
                    :value="item.value"> </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="上限金额" type="amount_on" align="center">
              <template slot-scope="{ row }">
                <el-input-number :value="row.amount_on" :min="0" style="width: 100%;" controls-position="right"
                  size="small" @change="(value) => {
                    setOrderAmountRule(row.key, 'amount_on', value)
                  }
                    " />
                <!-- <el-input v-model="row.amount_on"></el-input> -->
              </template>
            </el-table-column>

            <el-table-column v-if="ruleData.return_type === 1" label="回传比例(%)" type="order_return_rate" align="center">
              <template slot-scope="{ row }">
                <!-- <el-input-number v-model="row.order_return_rate" :min="1" size="small" /> -->
                <el-input-number :value="row.order_return_rate" style="width: 100%;" controls-position="right" :min="0"
                  :max="100" @input="(value) => {
                    setOrderAmountRule(row.key, 'order_return_rate', value)
                  }
                    "></el-input-number>
              </template>
            </el-table-column>

            <el-table-column v-if="ruleData.return_type === 2" label="循环比例(%)" type="cycle_proportion01" align="center">
              <template slot-scope="{ row }">
                <el-input-number :value="row.order_return_pick" controls-position="right" style="  width: 90px;" :min="0"
                  :max="100" @input="(value) => {
                    setOrderAmountRule(row.key, 'order_return_pick', value)
                  }
                    ">
                </el-input-number>
                :
                <el-input-number :value="row.order_return_ban" controls-position="right" style="  width: 90px;" :min="0"
                  :max="100" @input="(value) => {
                    setOrderAmountRule(row.key, 'order_return_ban', value)
                  }
                    ">
                </el-input-number>
              </template>
            </el-table-column>

            <el-table-column label="生效时间" type="order_effect_time" align="center">
              <template slot-scope="{ row }">
                <el-time-picker is-range v-model="row.order_effect_time" style="width: 100%;" size="small"
                  value-format="HH:mm:ss" range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间" @change="(value) => {
                    setOrderAmountRule(row.key, 'order_effect_time', value)
                  }
                    ">
                </el-time-picker>
              </template>
            </el-table-column>

            <el-table-column label="操作" type="order_return_rate" align="center">
              <template slot-scope="{ row }">
                <!-- <el-input-number v-model="row.order_return_rate" :min="1" size="small" /> -->
                <el-button type="text" style="color: red" @click="deleteOrder(row.key)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form-item>

      <el-form-item label="回传条件">
        <div v-if="ruleData.order_amount_rule.length">
          <p v-for="item in ruleData.order_amount_rule" :key="item.key"
            :style="{ marginTop: '-2px', color: ruleData.order_amount_rule_flag ? 'red' : '' }">
            <span>回传用户在</span>
            <span style="color:#409EFF"> 关注{{ ruleData.attention_type === 1 ? '当天 ' : ' 后 ' }}{{ ruleData.attention_time
            }} 小时 </span>
            <span>内,金额</span>
            <span style="color:#409EFF"> {{ orderOperatorOption.filter((f) => f.value ===
              item.amount_off_condition)[0].title }} {{ item.amount_off }}元 </span>
            <span>至</span>
            <span style="color:#409EFF"> {{ orderOperatorOption.filter((f) => f.value ===
              item.amount_on_condition)[0].title }} {{ item.amount_on }}元 </span>
            <span>回传比例为</span>
            <span style="color:#409EFF"> {{ item.order_return_rate }}% </span>
            <span>循环比例为</span>
            <span style="color:#409EFF"> {{ item.cycle_proportion }}% </span>
            <span>的订单</span>
          </p>
        </div>
      </el-form-item>
      <el-form-item prop="date" label="启用时间">
        <el-time-picker v-model="ruleData.date" is-range range-separator="-" start-placeholder="开始时间"
          end-placeholder="结束时间"> </el-time-picker>
      </el-form-item>
      <!-- /** v-if="ruleData.platform === 'gdt'" */ -->
      <el-form-item prop="is_on_sale" label="回传金额调整">
        <el-switch v-model="ruleData.is_on_sale" @change="changeSale"></el-switch>
      </el-form-item>

      <div style="display: flex;">
        <el-form-item v-if="ruleData.is_on_sale" style="margin-left: 30px" prop="discount"
          :rules="[{ required: ruleData.discount === '0' || ruleData.discount === null, message: '请输入缩减比例!' }]"
          class="customize_cycle_proportion">
          <el-input-number v-if="ruleData.is_on_sale" v-model="ruleData.discount" :min="0" :precision="2"
            style="width:150px;" placeholder="输入缩减比例"></el-input-number>
        </el-form-item>
        <div v-if="ruleData.is_on_sale" style="margin-top: 6px; padding: 0 10px">%</div>
      </div>
    </div>
  </div>
</template>

<script>
import { orderOperatorOption } from '../../data'
import _ from 'lodash'
// import moment from 'moment'
export default {
  props: {
    ruleData: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      returnTypes0: [{
        value: 1,
        label: '百分比回传'
      }, {
        value: 2,
        label: '循环比回传'
      }],
      returnTypes1: [{
        value: 1,
        label: '按订单循环'
      }, {
        value: 2,
        label: '每日重置'
      }],
      returnTypes2: [{
        value: 1,
        label: '优先回传'
      }, {
        value: 2,
        label: '优先卡控'
      }],
      orderOperatorOption,
      orderOperatorNumbers: [] /** 每行符合的金额 */,
      orderOperatorArr: [], /** 符合的金额集合 */
      orderEffectArr: [] /** 符合的生效时间集合 */
    }
  },
  computed: {
    conditionDisabled2() {
      return function (record) {
        if (record.amount_on_condition === 'GT' || record.amount_on_condition === 'EGT') {
          return ['GT', 'EGT']
        }
        if (record.amount_on_condition === 'LT' || record.amount_on_condition === 'ELT') {
          return ['LT', 'ELT']
        }
        return []
      }
    },
    conditionDisabled() {
      return function (record) {
        if (record.amount_off_condition === 'GT' || record.amount_off_condition === 'EGT') {
          return ['GT', 'EGT']
        }
        if (record.amount_off_condition === 'LT' || record.amount_off_condition === 'ELT') {
          return ['LT', 'ELT']
        }
        return []
      }
    }
  },
  methods: {
    addOrderAmountRule() {
      let item = {
        key: this.ruleData.order_amount_rule.length + 1,
        operator: 'AND',
        amount_off: 0,
        amount_off_condition: 'EQ',
        amount_on: 0,
        amount_on_condition: 'EQ',
        order_return_rate: 1,
        order_effect_time: ['00:00:00', '23:59:59'],
        effective_begin_time: '00:00:00',
        effective_end_time: '23:59:59',
        order_return_pick: 1,
        order_return_ban: 1,
        verify: false
      }
      this.ruleData.order_amount_rule.push(item)
    },

    changeSale(val) {
      if (!val) {
        this.ruleData.discount = 0
      }
    },

    handleChangeEffectTime(value) {
      console.log(value)
    },

    /**
     * @description: 订单金额表格类容切换时调用
     * @param {*} key 当前d
     * @param {*} type
     * @param {*} value
     * @return {*}
     */
    setOrderAmountRule(key, type, value) {
      let _order_amount_rule = [...this.ruleData.order_amount_rule]
      _order_amount_rule.forEach((item) => {
        if (item.key === key) {
          if (type === 'order_effect_time') {
            item.effective_begin_time = value[0]
            item.effective_end_time = value[1]
          } else {
            item[type] = value
          }
        }
      })
      this.ruleData.order_amount_rule = _order_amount_rule
      _order_amount_rule.forEach((item, index) => {
        this.verificationOrder(item, index)
      })
    },

    /** 验证订单金额是否有效 */
    verificationOrder(data, index) {
      // 获取最大金额用来创建数组
      const { order_amount_rule } = this.ruleData
      // 最大,最小金额
      let operatorArr = []
      // 最大金额
      let operatorMax = 0
      order_amount_rule.forEach((item) => {
        operatorArr.push(item.amount_off)
        operatorArr.push(item.amount_on)
      })
      operatorMax = operatorArr.sort((a, b) => b - a)[0] || 0

      // 满足条件的金额数组
      let operatorList = new Array(operatorMax + 1)

      for (let i = 0; i < operatorList.length; i++) {
        // 当下限条件为大于
        this.GreaterToEqual(data, i)

        // 当下限条件为大于
        this.Greater(data, i)

        // 当下限条件为等于
        this.Equal(data, i)

        // 当下限条件为小于等于
        this.LessThanToEqual(data, i)

        // 当下限条件为小于
        this.lessThan(data, i)
      }
      this.orderOperatorArr[index] = [...this.formateNumber([...this.orderOperatorNumbers])]
      this.orderOperatorNumbers = []

      console.log('🚀 每行符合的金额', this.orderOperatorArr)
      console.log('🚀重叠的金额的金额', this.orderOperatorArr.length > 1 ? _.intersection(...this.orderOperatorArr) : [])

      this.orderEffectArr[index] = [...this.getEffectiveTimeArr(data)]
      console.log('🚀 每行符合的生效时间', this.orderEffectArr)
      console.log('🚀 重叠的生效时间', this.orderEffectArr.length > 1 ? _.intersection(...this.orderEffectArr) : [])

      let _order_amount_rule = [...this.ruleData.order_amount_rule]
      let _order_amount_rule_flag = this.ruleData.order_amount_rule_flag
      /** 重叠的金额集合, 前提有两条数据 */
      let finalNum = this.orderOperatorArr.length > 1 ? _.intersection(...this.orderOperatorArr) : []

      /** 重叠的金额集合, 前提有两条数据 */
      let finalEffect = this.orderEffectArr.length > 1 ? _.intersection(...this.orderEffectArr) : []

      /** 判断是否验证通过 */
      if (_order_amount_rule.length > 1) {
        if (finalNum.length && finalEffect.length) {
          // 如果有重叠的金额, 全部设置verify为false
          // this.$message.error('当条金额条件与其他金额条存在重叠，请重新编辑!')
          this.$message.error('已存在相同金额即生效时间规则，请修改！')
          _order_amount_rule_flag = true
          this.orderOperatorArr.forEach((item, index) => {
            _order_amount_rule[index].verify = false
          })
        } else {
          // 每条如果条件有误, 设置verify为true,
          this.orderOperatorArr.forEach((item, index) => {
            if (item.length === 0) {
              _order_amount_rule[index].verify = false
              _order_amount_rule_flag = true
            } else {
              _order_amount_rule[index].verify = true
              _order_amount_rule_flag = false
            }
          })
        }
      } else {
        // 每条如果条件有误, 设置verify为true,
        this.orderOperatorArr.forEach((item, index) => {
          if (item.length === 0) {
            _order_amount_rule[index].verify = false
            _order_amount_rule_flag = true
          } else {
            _order_amount_rule[index].verify = true
            _order_amount_rule_flag = false
          }
        })
      }
      this.ruleData.order_amount_rule = _order_amount_rule
      this.ruleData.order_amount_rule_flag = _order_amount_rule_flag
    },

    // 生成 生效时间段 数组
    getEffectiveTimeArr(data) {
      const effective_begin_time = data.effective_begin_time || ''
      const effective_end_time = data.effective_end_time || ''
      const beginTime = effective_begin_time.split(':').map(v => +v)
      const endTime = effective_end_time.split(':').map(v => +v)
      let arr = []
      for (let i = beginTime[0]; i <= endTime[0]; i++) {
        if (i === beginTime[0]) {
          arr.push(effective_begin_time)
        } else if (i === endTime[0]) {
          if (endTime[1] > 0) {
            arr.push(i)
          }
          arr.push(effective_end_time)
        } else {
          arr.push(i)
        }
      }
      return arr
    },

    formateNumber(numbers) {
      return numbers.filter((f) => f != null)
    },

    // 当下限条件为大于等于
    GreaterToEqual(data, i) {
      // 下限金额条件
      let down_symbol = data.amount_off_condition
      // 上限金额条件
      let up_symbol = data.amount_on_condition
      if (down_symbol === 'EGT') {
        switch (up_symbol) {
          case 'LT':
            if (i >= data.amount_off && i < data.amount_on) {
              this.orderOperatorNumbers[i] = i
            }
            break
          case 'ELT':
            if (i >= data.amount_off && i <= data.amount_on) {
              this.orderOperatorNumbers[i] = i
            }
            break
          case 'EQ':
            if (i >= data.amount_off && i === data.amount_on) {
              this.orderOperatorNumbers[i] = i
            }
            break
          case 'GT':
            if (i >= data.amount_off && i > data.amount_on) {
              this.orderOperatorNumbers[i] = i
            }
            break
          case 'EGT':
            if (i >= data.amount_off && i >= data.amount_on) {
              this.orderOperatorNumbers[i] = i
            }
            break
          default:
            break
        }
      }
    },

    // 当下限条件为大于
    Greater(data, i) {
      // 下限金额条件
      let down_symbol = data.amount_off_condition
      // 上限金额条件
      let up_symbol = data.amount_on_condition
      if (down_symbol === 'GT') {
        switch (up_symbol) {
          case 'LT':
            if (i > data.amount_off && i < data.amount_on) {
              this.orderOperatorNumbers[i] = i
            }
            break
          case 'ELT':
            if (i > data.amount_off && i <= data.amount_on) {
              this.orderOperatorNumbers[i] = i
            }
            break
          case 'EQ':
            if (i > data.amount_off && i === data.amount_on) {
              this.orderOperatorNumbers[i] = i
            }
            break
          case 'GT':
            if (i > data.amount_off && i > data.amount_on) {
              this.orderOperatorNumbers[i] = i
            }
            break
          case 'EGT':
            if (i > data.amount_off && i >= data.amount_on) {
              this.orderOperatorNumbers[i] = i
            }
            break
          default:
            break
        }
      }
    },

    // 当下限条件小于等于
    Equal(data, i) {
      // 下限金额条件
      let down_symbol = data.amount_off_condition
      // 上限金额条件
      let up_symbol = data.amount_on_condition
      if (down_symbol === 'EQ') {
        switch (up_symbol) {
          case 'LT':
            if (i === data.amount_off && i < data.amount_on) {
              this.orderOperatorNumbers[i] = i
            }
            break
          case 'ELT':
            if (i === data.amount_off && i <= data.amount_on) {
              this.orderOperatorNumbers[i] = i
            }
            break
          case 'EQ':
            if (i === data.amount_off && i === data.amount_on) {
              this.orderOperatorNumbers[i] = i
            }
            break
          case 'GT':
            if (i === data.amount_off && i > data.amount_on) {
              this.orderOperatorNumbers[i] = i
            }
            break
          case 'EGT':
            if (i === data.amount_off && i >= data.amount_on) {
              this.orderOperatorNumbers[i] = i
            }
            break
          default:
            break
        }
      }
    },

    // 当下限条件小于等于
    LessThanToEqual(data, i) {
      // 下限金额条件
      let down_symbol = data.amount_off_condition
      // 上限金额条件
      let up_symbol = data.amount_on_condition
      if (down_symbol === 'ELT') {
        switch (up_symbol) {
          case 'LT':
            if (i <= data.amount_off && i < data.amount_on) {
              this.orderOperatorNumbers[i] = i
            }
            break
          case 'ELT':
            if (i <= data.amount_off && i <= data.amount_on) {
              this.orderOperatorNumbers[i] = i
            }
            break
          case 'EQ':
            if (i <= data.amount_off && i === data.amount_on) {
              this.orderOperatorNumbers[i] = i
            }
            break
          case 'GT':
            if (i <= data.amount_off && i > data.amount_on) {
              this.orderOperatorNumbers[i] = i
            }
            break
          case 'EGT':
            if (i <= data.amount_off && i >= data.amount_on) {
              this.orderOperatorNumbers[i] = i
            }
            break
          default:
            break
        }
      }
    },

    // 当下限条件小于
    lessThan(data, i) {
      // 下限金额条件
      let down_symbol = data.amount_off_condition
      // 上限金额条件
      let up_symbol = data.amount_on_condition
      if (down_symbol === 'LT') {
        switch (up_symbol) {
          case 'LT':
            if (i < data.amount_off && i < data.amount_on) {
              this.orderOperatorNumbers[i] = i
            }
            break
          case 'ELT':
            if (i < data.amount_off && i <= data.amount_on) {
              this.orderOperatorNumbers[i] = i
            }
            break
          case 'EQ':
            if (i < data.amount_off && i === data.amount_on) {
              this.orderOperatorNumbers[i] = i
            }
            break
          case 'GT':
            if (i < data.amount_off && i > data.amount_on) {
              this.orderOperatorNumbers[i] = i
            }
            break
          case 'EGT':
            if (i < data.amount_off && i >= data.amount_on) {
              this.orderOperatorNumbers[i] = i
            }
            break
          default:
            break
        }
      }
    },
    deleteOrder(key) {
      let _order_amount_rule = []
      this.ruleData.order_amount_rule.forEach((item) => {
        if (item.key !== key) {
          _order_amount_rule.push(item)
        }
      })
      this.ruleData.order_amount_rule = _order_amount_rule
    }
  }
}
</script>

<style lang="scss" scoped>
.attention {
  display: flex;
  align-items: center;
}

.customize_cycle_proportion {
  ::v-deep .el-form-item__content {
    margin: 0 !important
  }
}
</style>
