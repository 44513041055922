/**
 * 全局插件，包括一些方法、组件等
 */

import DataEmpty from '@/components/DataEmpty'
import Icon from '@/components/Icon'

const GlobalPlugin = {
  install(Vue, options) {
    /**
     * message 成功提示
     * @param message 信息内容
     * @param duration 显示时间，默认 2000 毫秒，0 为一直显示
     * @param showClose 是否显示关闭按钮，默认不显示
     * @param offset 距离窗口顶部的距离
     */
    Vue.prototype.$SuccessMessage = function (message = '', duration = 2000, showClose = false, offset = 200, dangerouslyUseHTMLString = true) {
      const finalOpt = {
        dangerouslyUseHTMLString: dangerouslyUseHTMLString,
        message: message,
        duration: duration,
        type: 'success',
        showClose: showClose,
        offset: offset
      }
      Vue.prototype.$message(finalOpt)
    }

    /**
     * message 错误提示
     * @param message 信息内容
     * @param duration 显示时间，默认 3000 毫秒，0 为一直显示
     * @param showClose 是否显示关闭按钮，默认不显示
     * @param offset 距离窗口顶部的距离
     */
    Vue.prototype.$ErrorMessage = function (message = '', duration = 3000, showClose = false, offset = 200, dangerouslyUseHTMLString = true) {
      const finalOpt = {
        dangerouslyUseHTMLString: dangerouslyUseHTMLString,
        message: message,
        duration: duration,
        type: 'error',
        showClose: showClose,
        offset: offset
      }
      Vue.prototype.$message(finalOpt)
    }

    /**
     * message 警告提示
     * @param message 信息内容
     * @param duration 显示时间，默认 3000 毫秒，0 为一直显示
     * @param showClose 是否显示关闭按钮，默认不显示
     * @param offset 距离窗口顶部的距离
     */
    Vue.prototype.$WarningMessage = function (message = '', duration = 3000, showClose = false, offset = 200, dangerouslyUseHTMLString = true) {
      const finalOpt = {
        dangerouslyUseHTMLString: dangerouslyUseHTMLString,
        message: message,
        duration: duration,
        type: 'warning',
        showClose: showClose,
        offset: offset
      }
      Vue.prototype.$message(finalOpt)
    }

    /**
     * message 信息提示
     * @param message 信息内容
     * @param duration 显示时间，默认 2000 毫秒，0 为一直显示
     * @param showClose 是否显示关闭按钮，默认不显示
     * @param offset 距离窗口顶部的距离
     */
    Vue.prototype.$InfoMessage = function (message = '', duration = 2000, showClose = false, offset = 200, dangerouslyUseHTMLString = true) {
      const finalOpt = {
        dangerouslyUseHTMLString: dangerouslyUseHTMLString,
        message: message,
        duration: duration,
        type: 'info',
        showClose: showClose,
        offset: offset
      }
      Vue.prototype.$message(finalOpt)
    }

    /* --------------------------- 全局组件 --------------------------- */
    Vue.component('c-data-empty', DataEmpty)
    Vue.component('c-icon', Icon)
  }
}

export default GlobalPlugin
