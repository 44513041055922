<template>
  <el-dialog
    :title="isEdit ? '编 辑': '新 增'"
    width="45%"
    :visible.sync="visible"
    :before-close="close"
    label-position="right"
    :modal-append-to-body="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <el-form ref="ent" :model="ent" :rules="rules" label-width="110px">

      <el-form-item label="分类名称" prop="lableTypeName">
        <el-input v-model="ent.lableTypeName" disabled />
      </el-form-item>
      <el-form-item v-if="!isEdit" label="" prop="labelName">
        <div>
          <el-row>
            <el-col :span="19">
              <p>输入标签名称多个标签请换行；不统计重复签名；失去光标自动去重</p>
            </el-col>
            <el-col :span="5">
              <p style="color:#67C23A;font-weight: 600;">标签数：{{ addNum }}</p>
            </el-col>
          </el-row>
        </div>
        <el-input
          v-model="ent.labelName"
          type="textarea"
          :autosize="{ minRows: 10, maxRows: 24}"
          @input="textChange"
          @blur="blurInput"
        />
      </el-form-item>
      <el-form-item v-if="isEdit" label="标签名称" prop="labelName">
        <el-input v-model="ent.labelName" />
      </el-form-item>

    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button round @click="close">取消</el-button>
      <el-popconfirm class="ml-10" confirm-button-text="提交" cancel-button-text="取消" icon="el-icon-info" icon-color="red" title="确定提交？" @confirm="submit">
        <el-button slot="reference" type="primary" round>确定</el-button>
      </el-popconfirm>
    </span>
  </el-dialog>
</template>
<script>
import {
  Add,
  Update
} from '@/network/api/basicSetting/label/basicSettingLabel'
import { BasicSettingLabelEnt, formRules } from './object'

export default {
  inject: ['loadData'],
  data() {
    return {
      ent: new BasicSettingLabelEnt(),
      addNum: 0,
      rules: formRules,
      isEdit: false,
      visible: false,
      cityList: []
    }
  },
  methods: {
    unique(arr) {
      return Array.from(new Set(arr))
    },
    blurInput() {
      var ct = this.ent.labelName.split('\n')
      if (undefined !== ct) {
        var res = this.unique(ct)
        var cont = ''
        res.forEach(item => {
          if (cont !== '' && undefined !== cont) {
            cont = cont + '\n' + item
          } else {
            cont = item
          }
        })
        this.ent.labelName = cont
      }
    },

    textChange() {
      var ct = this.ent.labelName.split('\n')
      var ind = 0
      if (undefined !== ct) {
        var res = this.unique(ct)
        res.forEach(item => {
          if (item !== '' && undefined !== item) {
            ind = ind + 1
          }
        })
        this.addNum = ind
      }
    },
    submit() {
      this.$refs.ent.validate((validate) => {
        if (validate) {
          this.isEdit ? this.updateRow() : this.addRow()
        }
      })
    },
    addRow() {
      const req = { ...this.ent }
      req.labelName = this.ent.labelName.split('\n').toString()
      Add(req).then(res => {
        if (res.code === 200) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.loadData()
          this.close()
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    updateRow() {
      const req = { ...this.ent }
      Update(req).then(res => {
        if (res.code === 200) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.loadData()
          this.close()
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    close() {
      this.visible = false
      this.$nextTick(() => {
        this.$refs.ent.resetFields()
      })
    },
    showEd(isEdit = false, ent, row) {
      if (isEdit) {
        this.addNum = 0
        this.ent = new BasicSettingLabelEnt()
        this.ent.lableTypeId = ent.bsLabelTypeId
        this.ent.lableTypeName = ent.labelTypeName
        if (row.labelName !== undefined) {
          this.ent.labelName = row.labelName
        }
      } else {
        this.ent = ent
      }
      if (row.bsLabelId !== undefined) {
        this.ent.bsLabelId = row.bsLabelId
      }
      this.isEdit = isEdit
      this.visible = true
      this.$nextTick(() => {
        if (this.isEdit) {
          console.log('不能为空')
        }
      })
    },

    show(isEdit = false, ent) {
      if (!isEdit) {
        this.addNum = 0
        this.ent = new BasicSettingLabelEnt()
        this.ent.lableTypeId = ent.bsLabelTypeId
        this.ent.lableTypeName = ent.labelTypeName
      } else {
        this.ent = ent
      }

      this.isEdit = isEdit
      this.visible = true
      this.$nextTick(() => {
        if (this.isEdit) {
          console.log('不能为空')
        }
      })
    }
  }
}
</script>
