var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      attrs: {
        id: "drawerid",
        size: "70%",
        visible: _vm.visible,
        "show-close": false,
        "before-close": _vm.close,
        "label-position": "right",
        "destroy-on-close": true,
        "modal-append-to-body": false,
        "close-on-press-escape": false,
        "wrapper-closable": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          ref: "orderBox",
          staticStyle: {
            overflow: "auto",
            "margin-top": "0px",
            "overflow-x": "hidden",
          },
          attrs: { id: "div" },
        },
        [
          _c(
            "div",
            {
              staticClass: "titleunitC",
              staticStyle: { "margin-bottom": "10px" },
              attrs: { id: "titleUnit1" },
            },
            [_c("span", [_vm._v("新建定向模板")])]
          ),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { staticClass: "left-purple-dark", attrs: { id: "cotUnit" } },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-row",
                        {
                          staticClass: "bg-purple-dark",
                          staticStyle: { "margin-left": "30px" },
                        },
                        [
                          _c("el-col", { attrs: { span: 23 } }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-row",
                                  {
                                    staticClass:
                                      "bg-purple-dark medisplay leftAndTop",
                                  },
                                  [
                                    _c("el-col", { attrs: { span: 4 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "meContent" },
                                        [
                                          _c(
                                            "font",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v("关联项目"),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("el-col", { attrs: { span: 6 } }, [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              attrs: {
                                                filterable: "",
                                                "allow-create": "",
                                                "default-first-option": "",
                                                placeholder: "请选择",
                                              },
                                              on: {
                                                change: _vm.bsProjectChange,
                                              },
                                              model: {
                                                value: _vm.ent.projectId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.ent,
                                                    "projectId",
                                                    $$v
                                                  )
                                                },
                                                expression: "ent.projectId",
                                              },
                                            },
                                            _vm._l(
                                              _vm.rProjectList,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.bsProjectId,
                                                  attrs: {
                                                    label: item.projectName,
                                                    value: item.bsProjectId,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("el-col", { attrs: { span: 4 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "meContent" },
                                        [
                                          _c(
                                            "font",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v("关联产品"),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("el-col", { attrs: { span: 6 } }, [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticClass: "w-300",
                                              attrs: {
                                                filterable: "",
                                                "allow-create": "",
                                                "default-first-option": "",
                                                placeholder: "请选择",
                                              },
                                              model: {
                                                value: _vm.ent.productId,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.ent,
                                                    "productId",
                                                    $$v
                                                  )
                                                },
                                                expression: "ent.productId",
                                              },
                                            },
                                            _vm._l(
                                              _vm.productList,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.bsProductId,
                                                  attrs: {
                                                    label: item.productName,
                                                    value: item.bsProductId,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        {
                          staticClass: "bg-purple-dark",
                          staticStyle: { "margin-left": "30px" },
                        },
                        [
                          _c("div", { staticClass: "SettitleunitC" }, [
                            _c("span", { staticClass: "showFontSize20" }, [
                              _vm._v("定向设置"),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 23 } }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-row",
                                  { staticClass: "medisplay leftAndTop" },
                                  [
                                    _c("el-col", { attrs: { span: 4 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "meContent" },
                                        [
                                          _c(
                                            "font",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v("地域"),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("el-col", { attrs: { span: 20 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              on: { change: _vm.regionChange },
                                              model: {
                                                value: _vm.region,
                                                callback: function ($$v) {
                                                  _vm.region = $$v
                                                },
                                                expression: "region",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio-button",
                                                { attrs: { label: "0" } },
                                                [_vm._v("不限")]
                                              ),
                                              _c(
                                                "el-radio-button",
                                                { attrs: { label: "region" } },
                                                [_vm._v("地域（省市区县）")]
                                              ),
                                              _c(
                                                "el-radio-button",
                                                {
                                                  attrs: {
                                                    label: "bizArea",
                                                    disabled:
                                                      _vm.bizAreaDisplay,
                                                  },
                                                },
                                                [_vm._v("预置商圈")]
                                              ),
                                              _c(
                                                "el-radio-button",
                                                {
                                                  attrs: {
                                                    label: "place",
                                                    disabled: _vm.placeDisplay,
                                                  },
                                                },
                                                [_vm._v("场所")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.region === "region"
                                  ? _c(
                                      "el-row",
                                      { staticClass: "medisplay leftAndTop" },
                                      [
                                        _c("el-col", { attrs: { span: 4 } }, [
                                          _c("div", {
                                            staticClass: "meContent",
                                          }),
                                        ]),
                                        _c("el-col", { attrs: { span: 20 } }, [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                              },
                                            },
                                            [
                                              _c("treeTransfers", {
                                                attrs: {
                                                  "basic-date": _vm.basicDate,
                                                  "one-comp": "中国",
                                                  "show-data":
                                                    _vm.regionEchoShowData,
                                                  "check-data":
                                                    _vm.regionEchoCheckData,
                                                  "select-name":
                                                    "地域（省市区县）",
                                                },
                                                on: {
                                                  getData:
                                                    _vm.getRegionSelectData,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.region === "bizArea"
                                  ? _c(
                                      "el-row",
                                      { staticClass: "medisplay leftAndTop" },
                                      [
                                        _c("el-col", { attrs: { span: 4 } }, [
                                          _c("div", {
                                            staticClass: "meContent",
                                          }),
                                        ]),
                                        _c("el-col", { attrs: { span: 20 } }, [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                              },
                                            },
                                            [
                                              _c("bizArea", {
                                                attrs: {
                                                  "basic-date": _vm.bizAreaData,
                                                  "select-name": "预置商圈",
                                                  "echo-show-data":
                                                    _vm.echoBizAreaShowData,
                                                  "echo-check-data":
                                                    _vm.echoBizAreaCheckData,
                                                },
                                                on: {
                                                  getData: _vm.getBizAreaData,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.region === "place"
                                  ? _c(
                                      "el-row",
                                      { staticClass: "medisplay leftAndTop" },
                                      [
                                        _c("el-col", { attrs: { span: 4 } }, [
                                          _c("div", {
                                            staticClass: "meContent",
                                          }),
                                        ]),
                                        _c("el-col", { attrs: { span: 20 } }, [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                              },
                                            },
                                            [
                                              _c("treeTransfers", {
                                                attrs: {
                                                  "basic-date":
                                                    _vm.basicPlaceDate,
                                                  "show-data":
                                                    _vm.placeEchoShowData,
                                                  "check-data":
                                                    _vm.placeEchoCheckData,
                                                  "select-name": "场所",
                                                },
                                                on: {
                                                  getData:
                                                    _vm.getPlaceSelectData,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-row",
                                  { staticClass: "medisplay leftAndTop" },
                                  [
                                    _c("el-col", { attrs: { span: 4 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "meContent" },
                                        [
                                          _c(
                                            "font",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v("年龄"),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("el-col", { attrs: { span: 20 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              on: { change: _vm.ageChange },
                                              model: {
                                                value: _vm.age,
                                                callback: function ($$v) {
                                                  _vm.age = $$v
                                                },
                                                expression: "age",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio-button",
                                                { attrs: { label: "age0" } },
                                                [_vm._v("不限")]
                                              ),
                                              _c(
                                                "el-radio-button",
                                                {
                                                  attrs: { label: "ageOther" },
                                                },
                                                [_vm._v("指定年龄段")]
                                              ),
                                              _c(
                                                "el-radio-button",
                                                {
                                                  attrs: { label: "customAge" },
                                                },
                                                [_vm._v("自定义年龄")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.age === "ageOther"
                                  ? _c(
                                      "el-row",
                                      { staticClass: "medisplay leftAndTop" },
                                      [
                                        _c("el-col", { attrs: { span: 4 } }, [
                                          _c("div", {
                                            staticClass: "meContent",
                                          }),
                                        ]),
                                        _c("el-col", { attrs: { span: 20 } }, [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-checkbox-group",
                                                {
                                                  on: {
                                                    change:
                                                      _vm.ageOtherGroupChange,
                                                  },
                                                  model: {
                                                    value: _vm.ageOtherGroup,
                                                    callback: function ($$v) {
                                                      _vm.ageOtherGroup = $$v
                                                    },
                                                    expression: "ageOtherGroup",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.ages,
                                                  function (age1) {
                                                    return _c(
                                                      "el-checkbox",
                                                      {
                                                        key: age1,
                                                        attrs: { label: age1 },
                                                      },
                                                      [_vm._v(_vm._s(age1))]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.age === "customAge"
                                  ? _c(
                                      "el-row",
                                      { staticClass: "medisplay leftAndTop" },
                                      [
                                        _c("el-col", { attrs: { span: 4 } }, [
                                          _c("div", {
                                            staticClass: "meContent",
                                          }),
                                        ]),
                                        _c("el-col", { attrs: { span: 20 } }, [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticClass: "w-120 mr-10",
                                                  attrs: {
                                                    placeholder: "请选择",
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.customAgeStartChange,
                                                  },
                                                  model: {
                                                    value: _vm.customAgeStart,
                                                    callback: function ($$v) {
                                                      _vm.customAgeStart = $$v
                                                    },
                                                    expression:
                                                      "customAgeStart",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.customAgeStartList,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.value,
                                                      attrs: {
                                                        label: item.id,
                                                        value: item.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                              _vm._v("- "),
                                              _c(
                                                "el-select",
                                                {
                                                  staticClass: "w-120 ml-10",
                                                  attrs: {
                                                    placeholder: "请选择",
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.customAgeStartChanges,
                                                  },
                                                  model: {
                                                    value: _vm.customAgeEnd,
                                                    callback: function ($$v) {
                                                      _vm.customAgeEnd = $$v
                                                    },
                                                    expression: "customAgeEnd",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.customAgeEndList,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.value,
                                                      attrs: {
                                                        disabled:
                                                          item.value === 56
                                                            ? false
                                                            : _vm.customAgeStart +
                                                                9 >
                                                              item.value,
                                                        label: item.id,
                                                        value: item.value,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-row",
                                  { staticClass: "medisplay leftAndTop" },
                                  [
                                    _c("el-col", { attrs: { span: 4 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "meContent" },
                                        [
                                          _c(
                                            "font",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v("性别"),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("el-col", { attrs: { span: 20 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              on: { change: _vm.sexChange },
                                              model: {
                                                value: _vm.sex,
                                                callback: function ($$v) {
                                                  _vm.sex = $$v
                                                },
                                                expression: "sex",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio-button",
                                                { attrs: { label: "0" } },
                                                [_vm._v("不限")]
                                              ),
                                              _c(
                                                "el-radio-button",
                                                { attrs: { label: "2" } },
                                                [_vm._v("男")]
                                              ),
                                              _c(
                                                "el-radio-button",
                                                { attrs: { label: "1" } },
                                                [_vm._v("女")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  { staticClass: "medisplay leftAndTop" },
                                  [
                                    _c("el-col", { attrs: { span: 4 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "meContent" },
                                        [
                                          _c(
                                            "font",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v("人生阶段"),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("el-col", { attrs: { span: 20 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-checkbox-group",
                                            {
                                              on: {
                                                change: _vm.lifeStageChange,
                                              },
                                              model: {
                                                value: _vm.lifeStage,
                                                callback: function ($$v) {
                                                  _vm.lifeStage = $$v
                                                },
                                                expression: "lifeStage",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-checkbox-button",
                                                {
                                                  key: "0",
                                                  attrs: { label: "0" },
                                                },
                                                [_vm._v("不限")]
                                              ),
                                              _c(
                                                "el-checkbox-button",
                                                {
                                                  key: "1",
                                                  attrs: { label: "1" },
                                                },
                                                [_vm._v("未婚")]
                                              ),
                                              _c(
                                                "el-checkbox-button",
                                                {
                                                  key: "2",
                                                  attrs: { label: "2" },
                                                },
                                                [_vm._v("已婚")]
                                              ),
                                              _c(
                                                "el-checkbox-button",
                                                {
                                                  key: "3",
                                                  attrs: { label: "3" },
                                                },
                                                [_vm._v("孕期")]
                                              ),
                                              _c(
                                                "el-checkbox-button",
                                                {
                                                  key: "4",
                                                  attrs: { label: "4" },
                                                },
                                                [_vm._v("家有儿女")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.lifeStage.indexOf("4") !== -1
                                  ? _c(
                                      "el-row",
                                      { staticClass: "medisplay leftAndTop" },
                                      [
                                        _c("el-col", { attrs: { span: 4 } }, [
                                          _c("div", {
                                            staticClass: "meContent",
                                          }),
                                        ]),
                                        _c("el-col", { attrs: { span: 20 } }, [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-checkbox-group",
                                                {
                                                  on: {
                                                    change:
                                                      _vm.lifeStage4Change,
                                                  },
                                                  model: {
                                                    value: _vm.lifeStage4,
                                                    callback: function ($$v) {
                                                      _vm.lifeStage4 = $$v
                                                    },
                                                    expression: "lifeStage4",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.lifeStageList,
                                                  function (item) {
                                                    return _c(
                                                      "el-checkbox",
                                                      {
                                                        key: item,
                                                        attrs: { label: item },
                                                      },
                                                      [_vm._v(_vm._s(item))]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-row",
                                  { staticClass: "medisplay leftAndTop" },
                                  [
                                    _c("el-col", { attrs: { span: 4 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "meContent" },
                                        [
                                          _c(
                                            "font",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v("学历 "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                effect: "dark",
                                                placement: "top-start",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    width: "200px",
                                                  },
                                                  attrs: { slot: "content" },
                                                  slot: "content",
                                                },
                                                [
                                                  _vm._v(
                                                    " 学历以取得毕业证为准，不包含在读情况 "
                                                  ),
                                                ]
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-question ml-5 mr-5",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("el-col", { attrs: { span: 20 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-checkbox-group",
                                            {
                                              on: {
                                                change: _vm.educationChange,
                                              },
                                              model: {
                                                value: _vm.education,
                                                callback: function ($$v) {
                                                  _vm.education = $$v
                                                },
                                                expression: "education",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-checkbox-button",
                                                { attrs: { label: "0" } },
                                                [_vm._v("不限")]
                                              ),
                                              _c(
                                                "el-checkbox-button",
                                                { attrs: { label: "1" } },
                                                [_vm._v("大学及以上")]
                                              ),
                                              _c(
                                                "el-checkbox-button",
                                                { attrs: { label: "3" } },
                                                [_vm._v("大专")]
                                              ),
                                              _c(
                                                "el-checkbox-button",
                                                { attrs: { label: "2" } },
                                                [_vm._v("高中及以下")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  { staticClass: "medisplay leftAndTop" },
                                  [
                                    _c("el-col", { attrs: { span: 4 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "meContent" },
                                        [
                                          _c(
                                            "font",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v("新兴趣（兴趣2.0） "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                effect: "dark",
                                                placement: "top-start",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    width: "200px",
                                                  },
                                                  attrs: { slot: "content" },
                                                  slot: "content",
                                                },
                                                [
                                                  _vm._v(
                                                    " 综合网民的浏览、搜索及应用内行为的表现综合判断人群兴趣类别，您可选择和自己业务相关的兴趣标签，定向目标人群 2020年升级了兴趣数据源及策略，为您找到更匹配人群，一定成本约束下，帮助您获取更多的转化量 "
                                                  ),
                                                ]
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-question ml-5 mr-5",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("el-col", { attrs: { span: 20 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              on: {
                                                change: _vm.newInterestsChange,
                                              },
                                              model: {
                                                value: _vm.newInterests,
                                                callback: function ($$v) {
                                                  _vm.newInterests = $$v
                                                },
                                                expression: "newInterests",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio-button",
                                                { attrs: { label: "0" } },
                                                [_vm._v("不限")]
                                              ),
                                              _c(
                                                "el-radio-button",
                                                {
                                                  attrs: {
                                                    label: "1",
                                                    disabled:
                                                      _vm.newInterestsDisplay,
                                                  },
                                                },
                                                [_vm._v("自定义")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.newInterests === "1"
                                  ? _c(
                                      "el-row",
                                      { staticClass: "medisplay leftAndTop" },
                                      [
                                        _c("el-col", { attrs: { span: 4 } }, [
                                          _c("div", {
                                            staticClass: "meContent",
                                          }),
                                        ]),
                                        _c("el-col", { attrs: { span: 20 } }, [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                              },
                                            },
                                            [
                                              _c("treeTransfers", {
                                                attrs: {
                                                  "basic-date":
                                                    _vm.basicnewInterestsDate,
                                                  "show-data":
                                                    _vm.newInterestsEchoShowData,
                                                  "check-data":
                                                    _vm.newInterestsEchoCheckData,
                                                  "select-name": "新兴趣",
                                                },
                                                on: {
                                                  getData:
                                                    _vm.getnewInterestsSelectData,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-row",
                                  { staticClass: "medisplay leftAndTop" },
                                  [
                                    _c("el-col", { attrs: { span: 4 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "meContent" },
                                        [
                                          _c(
                                            "font",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v("意图词"),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("el-col", { attrs: { span: 20 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              model: {
                                                value: _vm.keywords,
                                                callback: function ($$v) {
                                                  _vm.keywords = $$v
                                                },
                                                expression: "keywords",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio-button",
                                                { attrs: { label: "0" } },
                                                [_vm._v("不限")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  { staticClass: "medisplay leftAndTop" },
                                  [
                                    _c("el-col", { attrs: { span: 4 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "meContent" },
                                        [
                                          _c(
                                            "font",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v("人群包 "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                effect: "dark",
                                                placement: "top-start",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    width: "200px",
                                                  },
                                                  attrs: { slot: "content" },
                                                  slot: "content",
                                                },
                                                [
                                                  _vm._v(
                                                    " 定向投放或者排除特定人群包，您可点击下方的管理自定义人群包进入观星盘创建或者管理自定义人群包。 为了您的投放效果，人群包暂不支持与以下定向同时选择：兴趣、意图词、商圈、场所 "
                                                  ),
                                                ]
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-question ml-5 mr-5",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("el-col", { attrs: { span: 20 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              on: { change: _vm.crowdChange },
                                              model: {
                                                value: _vm.crowd,
                                                callback: function ($$v) {
                                                  _vm.crowd = $$v
                                                },
                                                expression: "crowd",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio-button",
                                                { attrs: { label: "0" } },
                                                [_vm._v("不限")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  { staticClass: "medisplay leftAndTop" },
                                  [
                                    _c("el-col", { attrs: { span: 4 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "meContent" },
                                        [
                                          _c(
                                            "font",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v("排除已转化人群 "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                effect: "dark",
                                                placement: "top-start",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    width: "200px",
                                                  },
                                                  attrs: { slot: "content" },
                                                  slot: "content",
                                                },
                                                [
                                                  _vm._v(
                                                    " 设置排除已转化定向，该定向仅支持oCPC、oCPM出价方式。默认排除近7天的已转化用户，若选择排除更多的时间窗，可前往观星盘设置后在平台的''人群包-排除人群''中排除。 "
                                                  ),
                                                ]
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-question ml-5 mr-5",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("el-col", { attrs: { span: 20 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              on: {
                                                change: _vm.excludeTransChange,
                                              },
                                              model: {
                                                value: _vm.excludeTrans,
                                                callback: function ($$v) {
                                                  _vm.excludeTrans = $$v
                                                },
                                                expression: "excludeTrans",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio-button",
                                                { attrs: { label: "0" } },
                                                [_vm._v("不限")]
                                              ),
                                              _c(
                                                "el-radio-button",
                                                { attrs: { label: "1" } },
                                                [_vm._v("同账户下")]
                                              ),
                                              _c(
                                                "el-radio-button",
                                                { attrs: { label: "2" } },
                                                [_vm._v("同计划下")]
                                              ),
                                              _c(
                                                "el-radio-button",
                                                { attrs: { label: "3" } },
                                                [_vm._v("同单元下")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  { staticClass: "medisplay leftAndTop" },
                                  [
                                    _c("el-col", { attrs: { span: 4 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "meContent" },
                                        [
                                          _c(
                                            "font",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v("操作系统"),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("el-col", { attrs: { span: 20 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-checkbox-group",
                                            {
                                              on: { change: _vm.deviceChange },
                                              model: {
                                                value: _vm.device,
                                                callback: function ($$v) {
                                                  _vm.device = $$v
                                                },
                                                expression: "device",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-checkbox-button",
                                                { attrs: { label: "0" } },
                                                [_vm._v("不限")]
                                              ),
                                              _c(
                                                "el-checkbox-button",
                                                { attrs: { label: "1" } },
                                                [_vm._v("IOS")]
                                              ),
                                              _c(
                                                "el-checkbox-button",
                                                { attrs: { label: "2" } },
                                                [_vm._v("Android")]
                                              ),
                                              _c(
                                                "el-checkbox-button",
                                                { attrs: { label: "4" } },
                                                [_vm._v("计算机")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-row",
                                  { staticClass: "medisplay leftAndTop" },
                                  [
                                    _c("el-col", { attrs: { span: 4 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "meContent" },
                                        [
                                          _c(
                                            "font",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v("APP行为 "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                effect: "dark",
                                                placement: "top-start",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    width: "200px",
                                                  },
                                                  attrs: { slot: "content" },
                                                  slot: "content",
                                                },
                                                [
                                                  _vm._v(
                                                    " 根据手机APP使用行为寻找目标人群，注意：该功能仅支持Android操作系统。 "
                                                  ),
                                                ]
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-question ml-5 mr-5",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("el-col", { attrs: { span: 20 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              on: { change: _vm.appChange },
                                              model: {
                                                value: _vm.app,
                                                callback: function ($$v) {
                                                  _vm.app = $$v
                                                },
                                                expression: "app",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio-button",
                                                {
                                                  attrs: {
                                                    label: "all",
                                                    disabled: _vm.deviceApp,
                                                  },
                                                },
                                                [_vm._v("不限")]
                                              ),
                                              _c(
                                                "el-radio-button",
                                                {
                                                  attrs: {
                                                    label: "category",
                                                    disabled: _vm.deviceApp,
                                                  },
                                                },
                                                [_vm._v("APP分类")]
                                              ),
                                              _c(
                                                "el-radio-button",
                                                {
                                                  attrs: {
                                                    label: "custom",
                                                    disabled: _vm.deviceApp,
                                                  },
                                                },
                                                [_vm._v("自定义APP")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                (!_vm.deviceApp && _vm.app === "category") ||
                                (!_vm.deviceApp && _vm.app === "custom")
                                  ? _c(
                                      "el-row",
                                      { staticClass: "medisplay leftAndTop" },
                                      [
                                        _c("el-col", { attrs: { span: 4 } }, [
                                          _c(
                                            "div",
                                            { staticClass: "meContent" },
                                            [
                                              _c(
                                                "font",
                                                {
                                                  staticStyle: { color: "red" },
                                                },
                                                [_vm._v("*")]
                                              ),
                                              _vm._v("定向逻辑"),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _c("el-col", { attrs: { span: 20 } }, [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio-group",
                                                {
                                                  on: {
                                                    change: _vm.behaviorChange,
                                                  },
                                                  model: {
                                                    value: _vm.behavior,
                                                    callback: function ($$v) {
                                                      _vm.behavior = $$v
                                                    },
                                                    expression: "behavior",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio-button",
                                                    { attrs: { label: "2" } },
                                                    [_vm._v("定向已安装")]
                                                  ),
                                                  _c(
                                                    "el-radio-button",
                                                    { attrs: { label: "4" } },
                                                    [_vm._v("排除已安装")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                !_vm.deviceApp && _vm.app === "category"
                                  ? _c(
                                      "el-row",
                                      { staticClass: "medisplay leftAndTop" },
                                      [
                                        _c("el-col", { attrs: { span: 4 } }, [
                                          _c("div", {
                                            staticClass: "meContent",
                                          }),
                                        ]),
                                        _c("el-col", { attrs: { span: 20 } }, [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                              },
                                            },
                                            [
                                              _c("treeTransfers", {
                                                attrs: {
                                                  "basic-date": _vm.appTypeList,
                                                  "select-name": "可选APP分类",
                                                  "show-data":
                                                    _vm.categoryEchoShowData,
                                                  "check-data":
                                                    _vm.categoryEchoCheckData,
                                                },
                                                on: {
                                                  getData:
                                                    _vm.getappTypeSelectData,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                !_vm.deviceApp && _vm.app === "custom"
                                  ? _c(
                                      "el-row",
                                      { staticClass: "medisplay leftAndTop" },
                                      [
                                        _c("el-col", { attrs: { span: 4 } }, [
                                          _c("div", {
                                            staticClass: "meContent",
                                          }),
                                        ]),
                                        _c("el-col", { attrs: { span: 20 } }, [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                              },
                                            },
                                            [
                                              _c("treeTransferCustom", {
                                                attrs: {
                                                  "basic-date": [],
                                                  "select-name":
                                                    "可选APP自定义",
                                                  "show-datas":
                                                    _vm.customEchoShowData,
                                                  "check-data":
                                                    _vm.customEchoCheckData,
                                                },
                                                on: {
                                                  getData:
                                                    _vm.getcustomSelectData,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-row",
                                  { staticClass: "medisplay leftAndTop" },
                                  [
                                    _c("el-col", { attrs: { span: 4 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "meContent" },
                                        [
                                          _c(
                                            "font",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v("网络 "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                effect: "dark",
                                                placement: "top-start",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    width: "200px",
                                                  },
                                                  attrs: { slot: "content" },
                                                  slot: "content",
                                                },
                                                [
                                                  _vm._v(
                                                    " 网络定向仅对移动端流量生效，计算机端流量无效 "
                                                  ),
                                                ]
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-question ml-5 mr-5",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("el-col", { attrs: { span: 20 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              on: { change: _vm.netChange },
                                              model: {
                                                value: _vm.net,
                                                callback: function ($$v) {
                                                  _vm.net = $$v
                                                },
                                                expression: "net",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio-button",
                                                { attrs: { label: "0" } },
                                                [_vm._v("不限")]
                                              ),
                                              _c(
                                                "el-radio-button",
                                                { attrs: { label: "1" } },
                                                [_vm._v("wifi")]
                                              ),
                                              _c(
                                                "el-radio-button",
                                                { attrs: { label: "2" } },
                                                [_vm._v("移动网络")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm.device.indexOf("1") !== -1
                                  ? _c(
                                      "el-row",
                                      { staticClass: "medisplay leftAndTop" },
                                      [
                                        _c("el-col", { attrs: { span: 4 } }, [
                                          _c(
                                            "div",
                                            { staticClass: "meContent" },
                                            [
                                              _c(
                                                "font",
                                                {
                                                  staticStyle: { color: "red" },
                                                },
                                                [_vm._v("*")]
                                              ),
                                              _vm._v("iOS版本"),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _c("el-col", { attrs: { span: 20 } }, [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio-group",
                                                {
                                                  on: {
                                                    change:
                                                      _vm.iosVersionChange,
                                                  },
                                                  model: {
                                                    value: _vm.iosVersion,
                                                    callback: function ($$v) {
                                                      _vm.iosVersion = $$v
                                                    },
                                                    expression: "iosVersion",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio-button",
                                                    { attrs: { label: "0" } },
                                                    [_vm._v("不限")]
                                                  ),
                                                  _c(
                                                    "el-radio-button",
                                                    { attrs: { label: "1" } },
                                                    [_vm._v("自定义")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.iosVersion === "1" &&
                                _vm.device.indexOf("1") !== -1
                                  ? _c(
                                      "el-row",
                                      { staticClass: "medisplay leftAndTop" },
                                      [
                                        _c("el-col", { attrs: { span: 4 } }, [
                                          _c("div", {
                                            staticClass: "meContent",
                                          }),
                                        ]),
                                        _c("el-col", { attrs: { span: 20 } }, [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-checkbox-group",
                                                {
                                                  on: {
                                                    change:
                                                      _vm.iosVersionChangeGroup,
                                                  },
                                                  model: {
                                                    value: _vm.iosVersionList,
                                                    callback: function ($$v) {
                                                      _vm.iosVersionList = $$v
                                                    },
                                                    expression:
                                                      "iosVersionList",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.iosList,
                                                  function (item) {
                                                    return _c(
                                                      "el-checkbox",
                                                      {
                                                        key: item,
                                                        attrs: { label: item },
                                                      },
                                                      [_vm._v(_vm._s(item))]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.device.indexOf("2") !== -1
                                  ? _c(
                                      "el-row",
                                      { staticClass: "medisplay leftAndTop" },
                                      [
                                        _c("el-col", { attrs: { span: 4 } }, [
                                          _c(
                                            "div",
                                            { staticClass: "meContent" },
                                            [
                                              _c(
                                                "font",
                                                {
                                                  staticStyle: { color: "red" },
                                                },
                                                [_vm._v("*")]
                                              ),
                                              _vm._v("Android版本"),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _c("el-col", { attrs: { span: 20 } }, [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio-group",
                                                {
                                                  on: {
                                                    change:
                                                      _vm.androidVersionChange,
                                                  },
                                                  model: {
                                                    value: _vm.androidVersion,
                                                    callback: function ($$v) {
                                                      _vm.androidVersion = $$v
                                                    },
                                                    expression:
                                                      "androidVersion",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio-button",
                                                    { attrs: { label: "0" } },
                                                    [_vm._v("不限")]
                                                  ),
                                                  _c(
                                                    "el-radio-button",
                                                    { attrs: { label: "1" } },
                                                    [_vm._v("自定义")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.androidVersion === "1" &&
                                _vm.device.indexOf("2") !== -1
                                  ? _c(
                                      "el-row",
                                      { staticClass: "medisplay leftAndTop" },
                                      [
                                        _c("el-col", { attrs: { span: 4 } }, [
                                          _c("div", {
                                            staticClass: "meContent",
                                          }),
                                        ]),
                                        _c("el-col", { attrs: { span: 20 } }, [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-checkbox-group",
                                                {
                                                  on: {
                                                    change:
                                                      _vm.androidVersionChangeGroup,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.androidVersionList,
                                                    callback: function ($$v) {
                                                      _vm.androidVersionList =
                                                        $$v
                                                    },
                                                    expression:
                                                      "androidVersionList",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.androidList,
                                                  function (item) {
                                                    return _c(
                                                      "el-checkbox",
                                                      {
                                                        key: item,
                                                        attrs: { label: item },
                                                      },
                                                      [_vm._v(_vm._s(item))]
                                                    )
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-row",
                                  { staticClass: "medisplay leftAndTop mb-20" },
                                  [
                                    _c("el-col", { attrs: { span: 4 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "meContent" },
                                        [
                                          _c(
                                            "font",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v("自动扩量 "),
                                          _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                effect: "dark",
                                                placement: "top-start",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    width: "200px",
                                                  },
                                                  attrs: { slot: "content" },
                                                  slot: "content",
                                                },
                                                [
                                                  _vm._v(
                                                    " 启用自动扩量后，可在单元积累了一定的转化数据后，在当前已选定向之外探索更多的适配人群，达到更好投放效果。"
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    " 自动扩量的规模和效果，会根据您已选的流量和定向特征有所不同， 自动扩量不会突破年龄、性别和投放省市这些基础定向设定。 "
                                                  ),
                                                ]
                                              ),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-question ml-5 mr-5",
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("el-col", { attrs: { span: 20 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _c("el-switch", {
                                            staticClass: "mr-10",
                                            on: {
                                              change: _vm.autoExpansionChange,
                                            },
                                            model: {
                                              value: _vm.autoExpansion,
                                              callback: function ($$v) {
                                                _vm.autoExpansion = $$v
                                              },
                                              expression: "autoExpansion",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "font",
                                            { staticClass: "descClass" },
                                            [
                                              _vm._v(
                                                "启用自动扩量后，系统会在已选定向之外，自动将广告投放给更多合适的人群"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        {
                          staticClass: "bg-purple-dark",
                          staticStyle: { "margin-left": "30px" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "SettitleunitC",
                              staticStyle: { height: "40px" },
                            },
                            [
                              _c("span", { staticClass: "showFontSize20" }, [
                                _vm._v("定向名称"),
                              ]),
                            ]
                          ),
                          _c("el-col", { attrs: { span: 23 } }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "el-row",
                                  { staticClass: "medisplay leftAndTop mb-20" },
                                  [
                                    _c("el-col", { attrs: { span: 4 } }, [
                                      _c(
                                        "div",
                                        { staticClass: "meContent" },
                                        [
                                          _c(
                                            "font",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v("定向名称"),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c("el-col", { attrs: { span: 20 } }, [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              maxlength: "100",
                                              placeholder: "请输入单元名称",
                                            },
                                            model: {
                                              value: _vm.adgroupFeedName,
                                              callback: function ($$v) {
                                                _vm.adgroupFeedName = $$v
                                              },
                                              expression: "adgroupFeedName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("el-col", { attrs: { span: 23 } }, [
                            _c(
                              "div",
                              {
                                staticClass: "height-70 ml-20 mb-20",
                                staticStyle: { "padding-top": "30px" },
                              },
                              [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: { title: "确定提交？" },
                                    on: { confirm: _vm.submit },
                                  },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          slot: "reference",
                                          type: "primary",
                                          round: "",
                                        },
                                        slot: "reference",
                                      },
                                      [_vm._v("确定")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "ml-10",
                                    attrs: { round: "" },
                                    on: { click: _vm.close },
                                  },
                                  [_vm._v("取消")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c("el-col", { attrs: { span: 1 } }, [
                _c("div", { staticStyle: { height: "10px" } }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }