/*
 * @Author: Zeng
 * @Date: 2023-09-05 09:12:25
 * @LastEditTime: 2024-03-21 14:13:53
 */
import service from '@/network/service/service'
// --------- 中转落地页 ------------
/**
 * 获取中转落地页列表
 */
export const getLandingPage = (params) => service.post(`/api/kuaishou/ToolPageRedirectController/getLists`, params)

/**
 * 添加/修改中转落地页
 */
export const updateLandingPage = (params) => service.post(`/api/kuaishou/ToolPageRedirectController/add`, params)

/**
 * 删除中转落地页
 */
export const deleteLandingPage = (id) => service.put(`/api/kuaishou/ToolPageRedirectController/del/${id}`)

// --------- 自定义落地页 ------------
/**
 * 获取落地页列表
 */
export const getCustomLandPageList = (params) => service.post(`/api/system/toolPageController/page`, params)

/**
 * 添加/修改落地页
 */
export const updateCustomLandingPage = (params) => service.post(`/api/system/toolPageController/saveOrUpdate`, params)

/**
 * 删除落地页
 */
export const deleteCustomLandingPage = (id) => service.delete(`/api/system/toolPageController/delete/${id}`)

/**
 * 获取落地页
 */
export const getCustomLandingPage = (id) => service.get(`/api/system/toolPageController/getOne/${id}`)

/**
 * 修改落地页名称
 */
export const updateCustomLandingPageName = (id, name) => service.get(`/api/system/toolPageController/setName/${id}/${name}`)

/**
 * 复制落地页
 */
export const copyCustomLandingPage = (id) => service.get(`/api/system/toolPageController/copy/${id}`)

/**
 * 关联循环页
 */
export const relatedLandingPage = (params) => service.post(`/api/system/toolPageController/relatedPage`, params)
