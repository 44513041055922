/*
 * @Description: 巨量广告api
 * @Date: 2021-10-13 16:03:30
 * @LastEditTime: 2024-01-23 17:05:10
 */
import service from '@/network/service/service'

export function Page(data) {
  return service.post('/api/system/accountHugeHousekeeper/page', data)
}

export function Add(data) {
  return service.get('/api/system/hugeAppApi/getAuthorizeUrl')
}

export function Update(data) {
  return service.post('/api/system/accountHugeHousekeeper/update', data)
}

export function Delete(id) {
  return service.delete('/api/system/accountHugeHousekeeper/' + id)
}

export function getOauth2() {
  return service.get('/api/system/hugeAppApi/getOauth2')
}

export function advertiserIds(id) {
  return service.get(`/api/system/hugeAppApi/getAdvertiser/${id}`)
}

export function getAdvertiserNew(id) {
  return service.get(`/api/system/hugeAppApi/getAdvertiserNew`, {
    params: {
      housekeeperId: id
    }
  })
}

export function Sync(data) {
  return service.post('/api/system/accountHugeAdv/save12', data)
}
