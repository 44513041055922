var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Search", { ref: "search" }),
      _c(
        "div",
        { staticClass: "justify-sb mb-15" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-plus", round: "" },
              on: {
                click: function ($event) {
                  return _vm.upAsset()
                },
              },
            },
            [_vm._v(" 立即上传 ")]
          ),
        ],
        1
      ),
      _c("Add", {
        ref: "add",
        attrs: { "img-list": _vm.imgList, "video-list": _vm.videoList },
        on: {
          seleAsset: _vm.seleAsset0,
          delImage: _vm.delImage,
          delVideo: _vm.delVideo,
        },
      }),
      _c("selectImg", { ref: "siv", on: { fixCheck: _vm.fixCheck } }),
      _c("SelectVideo", {
        ref: "sivideo",
        on: { fixCheck: _vm.fixCheckVideo },
      }),
      _c("TablePane", {
        attrs: { "data-source": _vm.dataSource },
        on: { getPage: _vm.getPage },
        scopedSlots: _vm._u([
          {
            key: "operation",
            fn: function ({ row }) {
              return [
                row.status === "submitted" && row.failNum > 0
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.refresData(row)
                          },
                        },
                      },
                      [
                        _c(
                          "el-tooltip",
                          { attrs: { content: "重新提交", placement: "top" } },
                          [
                            _c("i", {
                              staticClass: "el-icon-refresh-left",
                              staticStyle: { "font-size": "16px" },
                            }),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }