<!--
 * @Description: 创意基础信息
 * @Date: 2021-12-06 15:57:16
 * @LastEditTime: 2021-12-16 14:55:34
-->
<template>
  <div>
    <div class="creativity-text">
      <div v-if="creativityTextData[0].descriptionContent !== null">
        <div v-for="item in creativityTextData" :key="item.desGroupName" class="text-box">
          <p class="text-name">{{ item.desGroupName }}</p>
          <div class="text-item">
            <el-tooltip :content="item.descriptionContent" placement="top">
              <p>{{ item.descriptionContent }}</p>
            </el-tooltip>
            <!-- <el-tooltip :content="item.text2" placement="top">
              <p>{{ item.text2 }}</p>
            </el-tooltip> -->
          </div>
        </div>
      </div>
      <div v-else style="text-align: center; padding-top: 20px;color: #909399;">
        暂无数据
      </div>

      <el-dialog title="创意文案" :visible="textVisible" width="800px" :modal-append-to-body="false" destroy-on-close :before-close="handleClose">
        <span>
          <div class="justify-start">
            <h3>文案内容</h3>
            <p class="ml-20">文案组数量：{{ creativityTextList ? creativityTextList.length : 1 }}组</p>
          </div>
          <el-collapse v-model="collapse">
            <el-collapse-item v-for="(item, index) in creativityTextList" :key="item.desGroupName" :name="item.desGroupName">
              <template slot="title">
                <div class="justify-sb group-title">
                  <p>{{ item.desGroupName }}</p>
                  <div class="icon">
                    <i class="el-icon-document-copy" @click="copyCreativeGroup(index)"></i>
                    <i v-if="(creativityTextList ? creativityTextList.length : 0) > 1" class="el-icon-close" @click="deleteCreativeGroup(index)"></i>
                  </div>
                </div>
              </template>
              <div style="text-align:center">
                <div class="ml-20">
                  <el-form :model="item" label-width="110px">
                    <el-row>
                      <el-col>
                        <el-form-item label="广告文案:" prop="descriptionContent" :rules="rules.text" style="width: 100%;">
                          <!-- <span style="width: 60px; text-align: right">文案1： </span> -->
                          <el-input v-if="(taskSiteSet === 1 || taskSiteSet === 6)" v-model="item.descriptionContent" type="textarea" style="width: 400px" :rows="4" resize="none" placeholder="请输入文案, 支持换行输入, 最多4行"></el-input>
                          <el-select v-else v-model="item.descriptionContent" allow-create default-first-option filterable remote reserve-keyword style="width:80%" placeholder="请输入文案" :remote-method="remoteMethod" :loading="loading" @change="(value) => textChange(value, index)">
                            <el-option v-for="option in text_options" :key="option.textId" :label="option.text" :value="option.text"></el-option>
                          </el-select>
                          <span class="tip ml-10" :style="{ color: (item.descriptionContent ? item.descriptionContent.length : 0) > 30 ? '#f80000' : '#7d7d7d' }">{{ `${item.descriptionContent ? item.descriptionContent.length : 0} / 30` }}</span>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
          <div style="text-align:center; margin-top: 10px">
            <el-button type="primary" @click="addCreativeGroup">添加文案组</el-button>
          </div>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="onSubmit">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { saveText, deleteText } from '@/network/api/advManagement/advManagementTencent' // 获取计划模板
import { getTxtList } from '@/network/api/api-material'
import { debounce } from '@/utils/ruoyi'

export default {
  inject: ['closeText'],
  props: {
    textVisible: {
      type: Boolean,
      default: false
    },
    creativityTextData: {
      type: Array,
      default: () => []
    },
    projectId: {
      type: [String, Number],
      default: ''
    },
    taskId: {
      type: Number,
      default: 0
    },
    taskSiteSet: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      text_options: [],
      loading: false,
      collapse: ['文案组01'],
      handleText1: false,
      handleText2: false,
      creativityTextList: this.creativityTextData,
      rules: {
        text: [
          { required: true, message: '请输入文案', trigger: 'change' },
          { max: 30, message: '长度最多为30个字符', trigger: 'change' }
        ]
      }
    }
  },

  watch: {
    creativityTextData(value) {
      this.creativityTextList = value
      // console.log(value, this.creativityTextList, 'tttt')
    }
  },

  methods: {
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        this.getSelectAccountUinFun(query, this.projectId)
      } else {
        this.AccountUin_Options = []
      }
    },

    getSelectAccountUinFun: debounce(function(query) {
      getTxtList(1, 100, { text: query, projectId: +this.projectId }).then((res) => {
        if (res.code === 200) {
          this.loading = false
          this.text_options = res.data.list
        }
      })
    }),

    textChange(value, index) {
      if (value && value.length > 30) {
        // this.creativityTextList[index].descriptionContent = this.creativityTextData[index].descriptionContent
        return this.$message.error('文案长度不能超过30！')
      }
      const textId = this.text_options.filter(f => f.text === value)[0]?.textId
      this.creativityTextList[index].textId = textId
    },

    /**
     * @description 添加创素材组
     */
    addCreativeGroup() {
      if (this.creativityTextList[this.creativityTextList?.length - 1].descriptionContent === null) {
        return this.$message.error('请先输入文案!')
      }
      this.creativityTextList.push({
        taskId: this.taskId,
        taskDescriptionId: null,
        descriptionContent: null,
        desGroupOrderBy: this.creativityTextList?.length + 1,
        desGroupName: `文案组0${this.creativityTextList?.length + 1}`
      })
      this.collapse.push(`文案组0${this.creativityTextList?.length + 1}`)
    },

    /**
     * @description 删除素材组
     */
    async deleteCreativeGroup(index) {
      if (this.creativityTextList[index].taskDescriptionId) {
        this.$emit('clearTaskGenerate')
        await deleteText(this.taskId, this.creativityTextList[index].desGroupName)
      }
      this.creativityTextList.splice(index, 1)
      this.collapse.splice(index, 1)
      this.creativityTextList.forEach((item, index) => {
        item.desGroupName = `文案组0${index + 1}`
        item.desGroupOrderBy = index + 1
        // item.taskDescriptionId = item.taskDescriptionId
      })
    },

    /**
     * @description 复制创素材组
     */
    copyCreativeGroup(index) {
      if (this.creativityTextList[this.creativityTextList?.length - 1].descriptionContent === null) {
        return this.$message.error('请先输入文案!')
      }
      this.creativityTextList.push({
        ...this.creativityTextList[index],
        desGroupOrderBy: this.creativityTextList?.length + 1,
        taskDescriptionId: null,
        desGroupName: `文案组0${this.creativityTextList?.length + 1}`
      })
      this.collapse.push(`文案组0${this.creativityTextList?.length + 1}`)
    },

    /**
     * @description 创意素材添加
     */
    async onSubmit() {
      if (this.creativityTextList[this.creativityTextList?.length - 1].descriptionContent === null) {
        return this.$message.error('请先输入文案后再提交!')
      }
      let lists = []
      let save = true
      this.creativityTextList.forEach((item) => {
        if (item?.descriptionContent.length > 30) {
          save = false
          this.$message.error('文案长度不能超过30！')
          return
        } else if ((this.taskSiteSet === 1 || this.taskSiteSet === 6) && item?.descriptionContent.split('\n').length > 4) {
          save = false
          this.$message.error('文案换行最多4行！')
          return
        } else {
          lists.push(
            {
              ...item,
              textId: item.textId || null
            })
        }
      })
      if (!save) {
        return
      }
      this.$emit('clearTaskGenerate')
      saveText({ lists, taskId: this.taskId }).then((res) => {
        if (res.code === 200) {
          this.$message.success('文案保存成功!')
          this.$emit('byTaskIdGetAllFun')
          this.closeText()
        }
      })
    },

    handleClose() {
      this.$emit('byTaskIdGetAllFun')
      this.closeText()
    }
  }
}
</script>

<style lang="scss">
.creativity-text {
  overflow-y: auto;
  .text-box {
    padding-left: 10px;
    .text-name {
      font-weight: bold;
      margin-bottom: 6px;
      margin-top: 10px;
    }
    .text-item {
      margin-left: 10px;
      p {
        line-height: 26px;
        width: 230px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
  p {
    margin: 0;
    padding: 0;
  }
  height: 300px;
  .el-dialog__body {
    padding-top: 0px !important;
  }
  .group-title {
    width: 100%;
    .icon {
      i {
        margin-right: 10px;
      }
    }
  }
  .img-box {
    display: flex;
    & > p {
      width: 50px;
      font-size: 16px;
      text-align: right;

      margin-right: 10px;
    }
    .info {
      margin-top: 8px;
      margin-left: 10px;
      p {
        color: #a8a4a4;
        font-size: 14px;
        margin: 0;
        padding: 0;
      }
    }
  }

  .avatar-uploader {
    .el-upload {
      border: 1px solid #edecf2;
      background-color: #fbfafd;
      border-radius: 3px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .el-upload:hover {
      border-color: $themeColor;
      .avatar-uploader-icon {
        color: $themeColor;
      }
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #eae9f0;
      width: 100px;
      height: 100px;
      line-height: 100px;
      text-align: center;
      border: 1px solid #f2f2f2;
      border-radius: 4px;
    }
    .avatar {
      width: 100px;
      height: 100px;
      display: block;
    }
    .el-upload__tip {
      color: #a8acb4;
      font-size: 12px;
      margin-top: -17px;
    }
  }
  .el-collapse-item__header {
    font-size: 16px;
    font-weight: bold;
  }
}
.el-tabs .el-tabs__item.is-active {
  border-top: solid 0px #409eff;
}
</style>
