import service from '@/network/service/service'

export function generateData(data) {
  return service.post('/api/tencent/tiktok/generate', data)
}

export function regenerationData(tiktokExportZipId) {
  return service.post(`/api/tencent/tiktok/generateAgainOne/${tiktokExportZipId}`)
}

export function Page(data) {
  return service.post('/api/tencent/tiktok/page', data)
}
