var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "direction-template" },
    [
      _c(
        "el-form",
        {
          ref: "directionTempForm",
          attrs: {
            "label-position": "right",
            "label-width": "120px",
            rules: _vm.rules,
            model: _vm.forms,
          },
        },
        [
          _c("div", { staticClass: "direction-setting-box" }, [
            _c("div", { staticClass: "direction-setting-title" }, [
              _vm._v("基本信息"),
            ]),
            _c(
              "div",
              { staticClass: "direction-setting basic" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "模板名称", prop: "name" } },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "请输入模板名称",
                        "show-word-limit": "",
                        maxlength: "30",
                      },
                      model: {
                        value: _vm.forms.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.forms, "name", $$v)
                        },
                        expression: "forms.name",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "定向描述", prop: "description" } },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        resize: "none",
                        rows: "2",
                        placeholder: "请输入定向描述",
                        "show-word-limit": "",
                        maxlength: "30",
                      },
                      model: {
                        value: _vm.forms.description,
                        callback: function ($$v) {
                          _vm.$set(_vm.forms, "description", $$v)
                        },
                        expression: "forms.description",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "关联项目", prop: "project" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请选择关联项目" },
                        on: { change: _vm.projectChange },
                        model: {
                          value: _vm.forms.project,
                          callback: function ($$v) {
                            _vm.$set(_vm.forms, "project", $$v)
                          },
                          expression: "forms.project",
                        },
                      },
                      _vm._l(_vm.projectOptions, function (item) {
                        return _c("el-option", {
                          key: item.bsProjectId,
                          attrs: {
                            label: item.projectName,
                            value: item.bsProjectId,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "关联产品", prop: "product" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请选择关联产品" },
                        on: { change: _vm.productChange },
                        model: {
                          value: _vm.forms.product,
                          callback: function ($$v) {
                            _vm.$set(_vm.forms, "product", $$v)
                          },
                          expression: "forms.product",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "不限", value: -1 },
                        }),
                        _vm._l(_vm.productOptions, function (item) {
                          return _c("el-option", {
                            key: item.bsProductId,
                            attrs: {
                              label: item.productName,
                              value: item.bsProductId,
                            },
                          })
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "direction-setting-box" }, [
            _c("div", { staticClass: "direction-setting-title" }, [
              _vm._v("定向设置"),
            ]),
            _c(
              "div",
              { staticClass: "direction-setting" },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "定向包类型", prop: "landing_type" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.forms.landing_type,
                          callback: function ($$v) {
                            _vm.$set(_vm.forms, "landing_type", $$v)
                          },
                          expression: "forms.landing_type",
                        },
                      },
                      [
                        _c(
                          "el-radio-button",
                          { attrs: { label: "EXTERNAL" } },
                          [_vm._v("落地页")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "投放范围", prop: "delivery_range" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        on: { change: _vm.rangeChange },
                        model: {
                          value: _vm.forms.delivery_range,
                          callback: function ($$v) {
                            _vm.$set(_vm.forms, "delivery_range", $$v)
                          },
                          expression: "forms.delivery_range",
                        },
                      },
                      _vm._l(_vm.deliverRangData, function (item) {
                        return _c(
                          "el-radio-button",
                          { key: item.key, attrs: { label: item.key } },
                          [_vm._v(_vm._s(item.value) + " ")]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "region-list-box" },
                  [
                    _c(
                      "div",
                      { staticClass: "region-box" },
                      [
                        _c("district-select", {
                          class: {
                            "is-business-district":
                              _vm.forms.district == "CUSTOM_BUSINESS_DISTRICT",
                          },
                          attrs: { value: _vm.districtValue },
                          on: { change: _vm.districtChange },
                        }),
                        _c(
                          "div",
                          {
                            class: [
                              "shop-circle",
                              {
                                selected:
                                  _vm.forms.district ==
                                  "CUSTOM_BUSINESS_DISTRICT",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleSelectCircle.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [_vm._v("按商圈")]
                        ),
                      ],
                      1
                    ),
                    _vm.forms.district == "CUSTOM_BUSINESS_DISTRICT"
                      ? [
                          _c(
                            "business-district-comp",
                            { ref: "businessDistrict" },
                            [
                              _c(
                                "div",
                                { staticClass: "check-group-mix" },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.forms.location_type,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.forms,
                                            "location_type",
                                            $$v
                                          )
                                        },
                                        expression: "forms.location_type",
                                      },
                                    },
                                    _vm._l(
                                      _vm.locationTypeData,
                                      function (item) {
                                        return _c(
                                          "el-radio",
                                          {
                                            key: item.key,
                                            attrs: { label: item.key },
                                          },
                                          [_vm._v(_vm._s(item.value) + " ")]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "性别", prop: "gender" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.forms.gender,
                          callback: function ($$v) {
                            _vm.$set(_vm.forms, "gender", $$v)
                          },
                          expression: "forms.gender",
                        },
                      },
                      _vm._l(_vm.genderData, function (item) {
                        return _c(
                          "el-radio-button",
                          { key: item.key, attrs: { label: item.key } },
                          [_vm._v(_vm._s(item.value) + " ")]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c("el-form-item", { attrs: { label: "年龄", prop: "age" } }, [
                  _c(
                    "div",
                    { staticClass: "check-group-mix" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "unlimit-radio",
                          model: {
                            value: _vm.forms.age,
                            callback: function ($$v) {
                              _vm.$set(_vm.forms, "age", $$v)
                            },
                            expression: "forms.age",
                          },
                        },
                        [
                          _c(
                            "el-radio-button",
                            { attrs: { label: ["NONE"] } },
                            [_vm._v("不限")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-checkbox-group",
                        {
                          staticClass: "unlimit-check",
                          model: {
                            value: _vm.forms.age,
                            callback: function ($$v) {
                              _vm.$set(_vm.forms, "age", $$v)
                            },
                            expression: "forms.age",
                          },
                        },
                        _vm._l(_vm.ageData, function (item) {
                          return _c(
                            "el-checkbox-button",
                            { key: item.key, attrs: { label: item.key } },
                            [_vm._v(_vm._s(item.value) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "自定义人群",
                      prop: "retargeting_tags_include",
                    },
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.forms.retargeting_tags_include,
                          callback: function ($$v) {
                            _vm.$set(_vm.forms, "retargeting_tags_include", $$v)
                          },
                          expression: "forms.retargeting_tags_include",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: [] } }, [
                          _vm._v("不限"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: { label: "行为兴趣", prop: "interest_action_mode" },
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.forms.interest_action_mode,
                          callback: function ($$v) {
                            _vm.$set(_vm.forms, "interest_action_mode", $$v)
                          },
                          expression: "forms.interest_action_mode",
                        },
                      },
                      _vm._l(_vm.interestActionData, function (item) {
                        return _c(
                          "el-radio-button",
                          { key: item.key, attrs: { label: item.key } },
                          [_vm._v(_vm._s(item.value) + " ")]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm.forms.delivery_range == "DEFAULT"
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "抖音达人",
                          prop: "aweme_fan_behaviors",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.forms.aweme_fan_behaviors,
                              callback: function ($$v) {
                                _vm.$set(_vm.forms, "aweme_fan_behaviors", $$v)
                              },
                              expression: "forms.aweme_fan_behaviors",
                            },
                          },
                          [
                            _c("el-radio-button", { attrs: { label: [] } }, [
                              _vm._v("不限"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "媒体定向",
                      prop: "superior_popularity_type",
                    },
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.forms.superior_popularity_type,
                          callback: function ($$v) {
                            _vm.$set(_vm.forms, "superior_popularity_type", $$v)
                          },
                          expression: "forms.superior_popularity_type",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: "NONE" } }, [
                          _vm._v("不限"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "平台", prop: "platform" } },
                  [
                    _c(
                      "div",
                      { staticClass: "check-group-mix" },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "unlimit-radio",
                            model: {
                              value: _vm.forms.platform,
                              callback: function ($$v) {
                                _vm.$set(_vm.forms, "platform", $$v)
                              },
                              expression: "forms.platform",
                            },
                          },
                          [
                            _c(
                              "el-radio-button",
                              { attrs: { label: ["NONE"] } },
                              [_vm._v("不限")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-checkbox-group",
                          {
                            staticClass: "unlimit-check",
                            model: {
                              value: _vm.forms.platform,
                              callback: function ($$v) {
                                _vm.$set(_vm.forms, "platform", $$v)
                              },
                              expression: "forms.platform",
                            },
                          },
                          [
                            _c(
                              "el-checkbox-button",
                              { attrs: { label: "IOS" } },
                              [_vm._v("IOS")]
                            ),
                            _c(
                              "el-checkbox-button",
                              { attrs: { label: "ANDROID" } },
                              [_vm._v("Android")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "设备类型", prop: "device_type" } },
                  [
                    _c(
                      "div",
                      { staticClass: "check-group-mix" },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "unlimit-radio",
                            model: {
                              value: _vm.forms.device_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.forms, "device_type", $$v)
                              },
                              expression: "forms.device_type",
                            },
                          },
                          [
                            _c(
                              "el-radio-button",
                              { attrs: { label: ["NONE"] } },
                              [_vm._v("不限")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-checkbox-group",
                          {
                            staticClass: "unlimit-check",
                            model: {
                              value: _vm.forms.device_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.forms, "device_type", $$v)
                              },
                              expression: "forms.device_type",
                            },
                          },
                          _vm._l(_vm.deviceTypeData, function (item) {
                            return _c(
                              "el-checkbox-button",
                              { key: item.key, attrs: { label: item.key } },
                              [_vm._v(_vm._s(item.value) + " ")]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c("el-form-item", { attrs: { label: "网络", prop: "ac" } }, [
                  _c(
                    "div",
                    { staticClass: "check-group-mix" },
                    [
                      _c(
                        "el-radio-group",
                        {
                          staticClass: "unlimit-radio",
                          model: {
                            value: _vm.forms.ac,
                            callback: function ($$v) {
                              _vm.$set(_vm.forms, "ac", $$v)
                            },
                            expression: "forms.ac",
                          },
                        },
                        [
                          _c(
                            "el-radio-button",
                            { attrs: { label: ["NONE"] } },
                            [_vm._v("不限")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-checkbox-group",
                        {
                          staticClass: "unlimit-check",
                          model: {
                            value: _vm.forms.ac,
                            callback: function ($$v) {
                              _vm.$set(_vm.forms, "ac", $$v)
                            },
                            expression: "forms.ac",
                          },
                        },
                        _vm._l(_vm.networkData, function (item) {
                          return _c(
                            "el-checkbox-button",
                            { key: item.key, attrs: { label: item.key } },
                            [_vm._v(_vm._s(item.value) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.forms.delivery_range == "DEFAULT"
                  ? _c(
                      "el-form-item",
                      {
                        attrs: { label: "文章分类", prop: "article_category" },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.forms.article_category,
                              callback: function ($$v) {
                                _vm.$set(_vm.forms, "article_category", $$v)
                              },
                              expression: "forms.article_category",
                            },
                          },
                          [
                            _c("el-radio-button", { attrs: { label: [] } }, [
                              _vm._v("不限"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  { attrs: { label: "运营商", prop: "carrier" } },
                  [
                    _c(
                      "div",
                      { staticClass: "check-group-mix" },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "unlimit-radio",
                            model: {
                              value: _vm.forms.carrier,
                              callback: function ($$v) {
                                _vm.$set(_vm.forms, "carrier", $$v)
                              },
                              expression: "forms.carrier",
                            },
                          },
                          [
                            _c(
                              "el-radio-button",
                              { attrs: { label: ["NONE"] } },
                              [_vm._v("不限")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-checkbox-group",
                          {
                            staticClass: "unlimit-check",
                            model: {
                              value: _vm.forms.carrier,
                              callback: function ($$v) {
                                _vm.$set(_vm.forms, "carrier", $$v)
                              },
                              expression: "forms.carrier",
                            },
                          },
                          _vm._l(_vm.operateData, function (item) {
                            return _c(
                              "el-checkbox-button",
                              { key: item.key, attrs: { label: item.key } },
                              [_vm._v(_vm._s(item.value) + " ")]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm.forms.delivery_range == "DEFAULT"
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "新用户", prop: "activate_type" } },
                      [
                        _c(
                          "div",
                          { staticClass: "check-group-mix" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                staticClass: "unlimit-radio",
                                model: {
                                  value: _vm.forms.activate_type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.forms, "activate_type", $$v)
                                  },
                                  expression: "forms.activate_type",
                                },
                              },
                              [
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: ["NONE"] } },
                                  [_vm._v("不限")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-checkbox-group",
                              {
                                staticClass: "unlimit-check",
                                model: {
                                  value: _vm.forms.activate_type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.forms, "activate_type", $$v)
                                  },
                                  expression: "forms.activate_type",
                                },
                              },
                              _vm._l(_vm.newUserData, function (item) {
                                return _c(
                                  "el-checkbox-button",
                                  { key: item.key, attrs: { label: item.key } },
                                  [_vm._v(_vm._s(item.value) + " ")]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  { attrs: { label: "手机价格", prop: "launch_price" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        on: { change: _vm.handleSelectPhone },
                        model: {
                          value: _vm.phoneCustom,
                          callback: function ($$v) {
                            _vm.phoneCustom = $$v
                          },
                          expression: "phoneCustom",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: "unlimit" } }, [
                          _vm._v("不限"),
                        ]),
                        _c("el-radio-button", { attrs: { label: "custom" } }, [
                          _vm._v("自定义"),
                        ]),
                      ],
                      1
                    ),
                    _vm.phoneCustom == "custom"
                      ? _c("div", { staticClass: "phone-price-box" }, [
                          _c("div", { staticClass: "price-value-box" }, [
                            _c("span", [
                              _vm._v(
                                "价格区间：" +
                                  _vm._s(
                                    _vm._f("formatPriceLimit")(
                                      _vm.forms.launch_price
                                    )
                                  )
                              ),
                            ]),
                            _c("span", [_vm._v("单位：元")]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "price-slider" },
                            [
                              _c("el-slider", {
                                attrs: {
                                  range: "",
                                  "show-stops": "",
                                  "show-tooltip": false,
                                  step:
                                    _vm.forms.launch_price[1] >= 10000
                                      ? 1000
                                      : 500,
                                  min: 0,
                                  max: 11000,
                                  marks: _vm.phoneSlierMark,
                                },
                                model: {
                                  value: _vm.forms.launch_price,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.forms, "launch_price", $$v)
                                  },
                                  expression: "forms.launch_price",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "short-setting" },
                            [
                              _c("span", { staticClass: "label" }, [
                                _vm._v("快捷设置："),
                              ]),
                              _vm._l(_vm.shortSetData, function (item) {
                                return _c(
                                  "span",
                                  {
                                    key: item.key,
                                    staticClass: "short-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleShortSet(item.key)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(item.value))]
                                )
                              }),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "智能放量", prop: "auto_extend_enabled" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.forms.auto_extend_enabled,
                          callback: function ($$v) {
                            _vm.$set(_vm.forms, "auto_extend_enabled", $$v)
                          },
                          expression: "forms.auto_extend_enabled",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: 0 } }, [
                          _vm._v("不启用"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "已安装用户", prop: "hide_if_exists" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.forms.hide_if_exists,
                          callback: function ($$v) {
                            _vm.$set(_vm.forms, "hide_if_exists", $$v)
                          },
                          expression: "forms.hide_if_exists",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: 0 } }, [
                          _vm._v("不限"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "过滤已转化用户",
                      prop: "hide_if_converted",
                    },
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.forms.hide_if_converted,
                          callback: function ($$v) {
                            _vm.$set(_vm.forms, "hide_if_converted", $$v)
                          },
                          expression: "forms.hide_if_converted",
                        },
                      },
                      _vm._l(_vm.transferData, function (item, index) {
                        return _c(
                          "el-radio-button",
                          { key: index, attrs: { label: item.id } },
                          [_vm._v(_vm._s(item.name) + " ")]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "direction-setting-footer" },
            [
              _vm.tempPage
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.isLoading },
                      on: { click: _vm.handleSubmit },
                    },
                    [_vm._v(_vm._s(_vm._f("filterBtnText")(_vm.tempPage)))]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.isLoading },
                  on: { click: _vm.handleBack },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }