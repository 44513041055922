/*
 * @Description: Do not edit
 * @Date: 2021-12-01 14:18:39
 * @LastEditTime: 2022-01-21 14:40:14
 */
import service from '@/network/service/service'

// 获取推广计划
export function getPlan(data) {
  return service.post('/api/tencent/AdAdqTaskController/getCampaigns', data)
}

// 获取落地页
export function getLdy(data) {
  return service.post(`/api/tencent/AdAdqTaskController/getWechatPages`, data)
}

// 保存选中的创意-程序化-程序化注意taskId 和userId 预设
export function saveSelectCreative(data) {
  return service.post('/api/tencent/AdAdqTaskController/saveOrUpdateAdcreatives', data)
}

// 获取广告位下的预存创意
export function getSaveAsPreset(taskSiteSet) {
  return service({
    method: 'get',
    params: { taskSiteSet },
    url: '/api/tencent/AdAdqTaskController/getSaveAsPreset'
  })
}

// 通过TaskId获取程序化整体数据-程序化
export function byTaskIdGetAll(taskId) {
  return service.get(`/api/tencent/AdAdqTaskController/taskEchoData/${taskId}`)
}

// 保存修改任务基础信息
export function saveTask(data) {
  return service.post('/api/tencent/AdAdqTaskController/saveOrUpdateTask', data)
}

// 上传素材图片视-程序化
export function imgOrVideoUploadGdt(data) {
  return service.post('/api/tencent/AdAdqTaskController/saveAsset', data)
}

// 上传素材图片视-程序化
export function saveAssetDesc(data) {
  return service.post('/api/tencent/AdAdqTaskController/saveOrUpdateAssetDesc', data)
}

// 腾讯批量_删除素材
export function deleteAssetImgOrVideo({ taskId, assetGroupName }) {
  return service.delete(`/api/tencent/AdAdqTaskController/deleteAsset/${taskId}/${assetGroupName}`)
}

// 腾讯批量_删除素材组合整组
export function deleteAssetGroup({ taskId, assetDescriptionGroupName }) {
  return service.delete(`/api/tencent/AdAdqTaskController/deleteAssetAndDesc/${taskId}/${assetDescriptionGroupName}`)
}

// 腾讯批量_删除素材组合文案
export function deleteAssetDesc({ taskId, desGroupName }) {
  return service.delete(`/api/tencent/AdAdqTaskController/deleteDescription/${taskId}/${desGroupName}`)
}

// 保存文案
export function saveText(data) {
  return service.post('/api/tencent/AdAdqTaskController/saveDescription', data)
}

// 删除文案
export function deleteText(taskId, desGroupName) {
  return service.delete(`/api/tencent/AdAdqTaskController/deleteDescription/${taskId}/${desGroupName}`)
}

// 创建计划-----关联表(复用)-程序化保存计划-广告组-落地页-商品标识
export function savePlan(lists) {
  return service.post('/api/tencent/AdAdqTaskController/saveCampaign', { lists })
}

// 获取添加广告组账户和计划信息-
export function getAccountAndPlan(taskId) {
  return service.get(`/api/tencent/adManagementGdtPublishTash/getAccountAndPlan/${taskId}`)
}

// 创建广告主-----关联表(复用)-程序化保存计划-广告组-落地页-商品标识
export function saveAdv(lists) {
  return service.post('/api/tencent/AdAdqTaskController/saveAdv', { lists })
}

// 创建落地页------关联表(复用)-程序化保存计划-广告组-落地页-商品标识
export function savePage(data) {
  return service.post('/api/tencent/AdAdqTaskController/savePage', data)
}

// /广告主list
export function getAdGroups(data) {
  return service.post('/api/tencent/AdAdqTaskController/getAdgroups', data)
}

// 品牌列表
export function getBrand(data) {
  return service.post('/api/tencent/AdAdqTaskController/getBrand', data)
}

// 已创建的浮层卡片列表
export function getFloadingCardList(params) {
  return service({
    method: 'post',
    params,
    url: '/api/tencent/AdAdqTaskController/getAdcreativesVideoNumContent'
  })
}

// 视频号列表
export function getVideoAccount(params) {
  return service({
    method: 'post',
    params,
    url: '/api/tencent/AdAdqTaskController/getVideoNum'
  })
}

// 视频号保存
export function saveVideoAccount(data) {
  return service.post('/api/tencent/AdAdqTaskController/batchSavaOrupdateUsername', data)
}

// 预览广告
export function generate(taskId) {
  return service.get(`/api/tencent/AdAdqTaskController/generate/${taskId}`)
}

// 删除预览
export function clearGenerate(taskId) {
  return service.delete(`/api/tencent/AdAdqTaskController/clearGenerate/${taskId}`)
}

// 预览区
export function showPreview({ taskId, pageNum, pageSize }) {
  return service.get(`/api/tencent/AdAdqTaskController/previewArea/${taskId}`)
}

// 预览区-账户
export function showPreviewAccount({ taskId }) {
  return service.get(`/api/tencent/AdAdqTaskController/previewArea38/${taskId}`)
}

// 预览区-账户列表
export function showPreviewAccountList({ taskId, pageNum, pageSize, taskAccountId }) {
  return service.get(`/api/tencent/AdAdqTaskController/previewArea38Get/${pageNum}/${pageSize}/${taskId}/${taskAccountId}`)
}

// 预览区-落地页
export function showLandingPage(taskGenerateId) {
  return service.get(`/api/tencent/AdAdqTaskController/previewPage/${taskGenerateId}`)
}

// 查看详情
export function getGenerate(params) {
  return service({
    method: 'get',
    url: '/api/tencent/AdAdqTaskController/getGenerate',
    params
  })
}

// 保存品牌
export function saveBrand(data) {
  return service.post(`/api/tencent/AdAdqTaskController/taskAddBrand`, data)
}

// 上传品牌形象
export function brandAdd(params) {
  return service({
    method: 'post',
    params,
    url: '/api/tencent/AdAdqTaskController/brandAdd'
  })
}

// adManagementGdtPublishTash/page
export function Page(data) {
  return service.post('/api/tencent/AdAdqTaskController/page', data)
}

export function getAllSiteSet() {
  return service.get('/api/tencent/AdAdqTaskController/getAllSiteSet')
}

// 批量发布task
export function publishTask(taskId) {
  return service.get(`/api/tencent/AdAdqTaskController/submit/${taskId}`)
}

// 删除task
export function deleteTask(id) {
  return service.delete(`/api/tencent/AdAdqTaskController/deleteTask/${id}`)
}

// 重新提交
export function againPublishTask(taskId, accountCampaingsId) {
  return service.get(`/api/tencent/adManagementGdtPublishTash/publishTask/${taskId}/${accountCampaingsId}`)
}

// 删除 计划
export function deleteRelation(taskCampaignAdInfoId) {
  return service.delete(`/api/tencent/AdAdqTaskController/deleteCampaign/${taskCampaignAdInfoId}`)
}
// 删除广告
export function deleteAdv(taskCampaignAdInfoId) {
  return service.delete(`/api/tencent/AdAdqTaskController/deleteAdv/${taskCampaignAdInfoId}`)
}
// 删除落地页
export function deletePage(taskPageIds) {
  return service.delete(`/api/tencent/AdAdqTaskController/deletePage/${taskPageIds}`)
}
// 清空落地页
export function deletePageByTaskId(taskId) {
  return service.delete(`/api/tencent/AdAdqTaskController/deletePageByTaskId/${taskId}`)
}

// 获取企微id
export function getCorpId(name) {
  return service({
    method: 'get',
    url: '/api/tencent/AdAdqTaskController/getCorpId',
    params: { name }
  })
  // return service.get(`/api/tencent/AdAdqTaskController/getCorpId`)
}

// 获取企微客服组id
export function getWxGroupId(data) {
  return service.post('/api/tencent/AdAdqTaskController/getWxGroupId', data)
}

// 保存企微配置
export function taskAddQW(data) {
  return service.post('/api/tencent/AdAdqTaskController/taskAddQW', data)
}

// 预览区 更新出价金额
export function updateBidAmount(data) {
  return service({
    method: 'post',
    url: '/api/tencent/AdAdqTaskController/updateBidAmount',
    params: data
  })
}

// 预览区 修改广告名称
export function updateAdgroupName(data) {
  return service({
    method: 'post',
    url: '/api/tencent/AdAdqTaskController/updateAdgroupName',
    params: data
  })
}

// 新 预览区 更新出价金额
export function setBidAmount(data) {
  return service({
    method: 'post',
    url: '/api/tencent/AdAdqTaskController/setAdBid',
    params: data
  })
}

// 新 预览区 修改广告名称
export function setAdgroupName(data) {
  return service({
    method: 'post',
    url: '/api/tencent/AdAdqTaskController/setAdName',
    params: data
  })
}
