var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, "label-width": "110px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "模板名称：", prop: "name" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: { placeholder: "请输入模板名称" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "定向描述：", prop: "description" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: { placeholder: "请输入定向描述" },
                model: {
                  value: _vm.form.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "description", $$v)
                  },
                  expression: "form.description",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "关联项目：", prop: "projectId" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: { placeholder: "请选择关联项目" },
                model: {
                  value: _vm.form.projectId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "projectId", $$v)
                  },
                  expression: "form.projectId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "关联产品：", prop: "productId" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: { placeholder: "请选择关联关联产品" },
                model: {
                  value: _vm.form.productId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "productId", $$v)
                  },
                  expression: "form.productId",
                },
              }),
            ],
            1
          ),
          _c("el-divider", [_vm._v("人口学属性")]),
          _c(
            "el-form-item",
            { attrs: { label: "地理位置：", prop: "location_types" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "", border: "" },
                  model: {
                    value: _vm.form.location_types,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "location_types", $$v)
                    },
                    expression: "form.location_types",
                  },
                },
                [_vm._v("不限")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "customize", border: "" },
                  model: {
                    value: _vm.form.location_types,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "location_types", $$v)
                    },
                    expression: "form.location_types",
                  },
                },
                [_vm._v("自定义")]
              ),
              _vm.form.location_types === "customize"
                ? _c(
                    "el-collapse",
                    {
                      staticClass: "mt-20",
                      attrs: { accordion: "" },
                      on: { change: (name) => _vm.getTargetingTagsFun(name) },
                    },
                    [
                      _c(
                        "el-collapse-item",
                        { attrs: { name: "REGION" } },
                        [
                          _c("template", { slot: "title" }, [_vm._v("地域")]),
                          _c(
                            "el-cascader",
                            {
                              attrs: {
                                placeholder: "搜索国家、省、市、区",
                                options: _vm.REGION_list,
                                "collapse-tags": "",
                                props: {
                                  label: "name",
                                  value: "id",
                                  multiple: true,
                                },
                                clearable: "",
                              },
                            },
                            [_vm._v(">")]
                          ),
                        ],
                        2
                      ),
                      _c(
                        "el-collapse-item",
                        { attrs: { name: "BUSINESS_DISTRICT" } },
                        [
                          _c("template", { slot: "title" }, [_vm._v("商圈 ")]),
                          _c(
                            "el-cascader",
                            {
                              attrs: {
                                placeholder: "搜索商圈",
                                options: _vm.BUSINESS_DISTRICT_list,
                                "collapse-tags": "",
                                props: {
                                  label: "name",
                                  value: "id",
                                  multiple: true,
                                },
                                clearable: "",
                              },
                            },
                            [_vm._v(">")]
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "年龄：", prop: "age_label" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { xl: 4, lg: 6 } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: {
                            value: _vm.form.age_label,
                            label: "",
                            border: "",
                          },
                          on: {
                            change: function ($event) {
                              ;(_vm.form.age = ""), (_vm.form.age_label = "")
                            },
                          },
                        },
                        [_vm._v("不限")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: {
                            value: _vm.form.age_label,
                            label: "customize",
                            border: "",
                          },
                          on: {
                            change: function ($event) {
                              ;(_vm.form.age = [14, 18]),
                                (_vm.form.age_label = "customize")
                            },
                          },
                        },
                        [_vm._v("自定义")]
                      ),
                    ],
                    1
                  ),
                  _vm.form.age_label === "customize"
                    ? _c(
                        "el-col",
                        { attrs: { prop: "age", xl: 20, lg: 18 } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "160px" },
                              attrs: { clearable: "", placeholder: "请选择" },
                              on: {
                                change: (value) =>
                                  value > _vm.form.age[0]
                                    ? (_vm.form.age[0] = value)
                                    : null,
                              },
                              model: {
                                value: _vm.form.age[0],
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.age, 0, $$v)
                                },
                                expression: "form.age[0]",
                              },
                            },
                            _vm._l(_vm.age_min_list, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.value, value: item.key },
                              })
                            }),
                            1
                          ),
                          _c("span", [_vm._v(" - ")]),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "160px" },
                              attrs: { clearable: "", placeholder: "请选择" },
                              model: {
                                value: _vm.form.age[1],
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.age, 1, $$v)
                                },
                                expression: "form.age[1]",
                              },
                            },
                            _vm._l(_vm.age_max_list, function (item) {
                              return _c("el-option", {
                                key: item.key,
                                attrs: {
                                  label: item.value,
                                  value: item.key,
                                  disabled: item.key < _vm.form.age[0],
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "性别：", prop: "gender" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.gender,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "gender", $$v)
                    },
                    expression: "form.gender",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "", border: "" } }, [
                    _vm._v("不限"),
                  ]),
                  _c("el-radio", { attrs: { label: "MALE", border: "" } }, [
                    _vm._v("男"),
                  ]),
                  _c("el-radio", { attrs: { label: "FEMALE", border: "" } }, [
                    _vm._v("女"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "学历：", prop: "education" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { xl: 4, lg: 6 } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "", border: "" },
                          on: {
                            change: function ($event) {
                              _vm.form.marital_status = []
                            },
                          },
                          model: {
                            value: _vm.form.education_label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "education_label", $$v)
                            },
                            expression: "form.education_label",
                          },
                        },
                        [_vm._v("不限")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "customize", border: "" },
                          model: {
                            value: _vm.form.education_label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "education_label", $$v)
                            },
                            expression: "form.education_label",
                          },
                        },
                        [_vm._v("其他")]
                      ),
                    ],
                    1
                  ),
                  _vm.form.education_label === "customize"
                    ? _c(
                        "el-col",
                        { attrs: { xl: 20, lg: 18 } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              attrs: { min: 0 },
                              model: {
                                value: _vm.form.education,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "education", $$v)
                                },
                                expression: "form.education",
                              },
                            },
                            _vm._l(_vm.education_list, function (item) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: item.dictLabel,
                                  attrs: { label: item.dictValue },
                                },
                                [_vm._v(_vm._s(item.dictLabel))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "婚恋育儿：", prop: "marital_status_label" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "", border: "" },
                          on: {
                            change: function ($event) {
                              _vm.form.marital_status = []
                            },
                          },
                          model: {
                            value: _vm.form.marital_status_label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "marital_status_label", $$v)
                            },
                            expression: "form.marital_status_label",
                          },
                        },
                        [_vm._v("不限")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "customize", border: "" },
                          model: {
                            value: _vm.form.marital_status_label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "marital_status_label", $$v)
                            },
                            expression: "form.marital_status_label",
                          },
                        },
                        [_vm._v("自定义")]
                      ),
                    ],
                    1
                  ),
                  _vm.form.marital_status_label === "customize"
                    ? _c(
                        "el-col",
                        [
                          _c(
                            "ListBox",
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  attrs: { min: 0 },
                                  model: {
                                    value: _vm.form.marital_status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "marital_status", $$v)
                                    },
                                    expression: "form.marital_status",
                                  },
                                },
                                _vm._l(
                                  _vm.marital_status_list,
                                  function (item) {
                                    return _c(
                                      "el-checkbox",
                                      {
                                        key: item.dictLabel,
                                        attrs: { label: item.dictValue },
                                      },
                                      [_vm._v(_vm._s(item.dictLabel))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "工作状态：", prop: "working_status_label" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "", border: "" },
                          on: {
                            change: function ($event) {
                              _vm.form.working_status = []
                            },
                          },
                          model: {
                            value: _vm.form.working_status_label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "working_status_label", $$v)
                            },
                            expression: "form.working_status_label",
                          },
                        },
                        [_vm._v("不限")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "customize", border: "" },
                          model: {
                            value: _vm.form.working_status_label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "working_status_label", $$v)
                            },
                            expression: "form.working_status_label",
                          },
                        },
                        [_vm._v("自定义")]
                      ),
                    ],
                    1
                  ),
                  _vm.form.working_status_label === "customize"
                    ? _c(
                        "el-col",
                        [
                          _c(
                            "ListBox",
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  attrs: { min: 0 },
                                  model: {
                                    value: _vm.form.marital_status,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "marital_status", $$v)
                                    },
                                    expression: "form.marital_status",
                                  },
                                },
                                _vm._l(
                                  _vm.working_status_list,
                                  function (item) {
                                    return _c(
                                      "el-checkbox",
                                      {
                                        key: item.dictLabel,
                                        attrs: { label: item.dictValue },
                                      },
                                      [_vm._v(_vm._s(item.dictLabel))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "财产状态", prop: "financial_situation_label" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { xl: 4, lg: 6 } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "", border: "" },
                          on: {
                            change: function ($event) {
                              _vm.form.financial_situation = []
                            },
                          },
                          model: {
                            value: _vm.form.financial_situation_label,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "financial_situation_label",
                                $$v
                              )
                            },
                            expression: "form.financial_situation_label",
                          },
                        },
                        [_vm._v("不限")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "customize", border: "" },
                          model: {
                            value: _vm.form.financial_situation_label,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "financial_situation_label",
                                $$v
                              )
                            },
                            expression: "form.financial_situation_label",
                          },
                        },
                        [_vm._v("其他")]
                      ),
                    ],
                    1
                  ),
                  _vm.form.financial_situation_label === "customize"
                    ? _c(
                        "el-col",
                        { attrs: { xl: 20, lg: 18 } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              attrs: { min: 0 },
                              model: {
                                value: _vm.form.financial_situation,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "financial_situation", $$v)
                                },
                                expression: "form.financial_situation",
                              },
                            },
                            [
                              _c(
                                "el-checkbox",
                                { attrs: { label: "HOME_OWNERS " } },
                                [_vm._v("有房人士")]
                              ),
                              _c(
                                "el-checkbox",
                                { attrs: { label: "CAR_OWNERS" } },
                                [_vm._v("有车人士")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "消费类型：", prop: "consumption_type_label" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { xl: 4, lg: 6 } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "", border: "" },
                          on: {
                            change: function ($event) {
                              _vm.form.consumption_type = []
                            },
                          },
                          model: {
                            value: _vm.form.consumption_type_label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "consumption_type_label", $$v)
                            },
                            expression: "form.consumption_type_label",
                          },
                        },
                        [_vm._v("不限")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "customize", border: "" },
                          model: {
                            value: _vm.form.consumption_type_label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "consumption_type_label", $$v)
                            },
                            expression: "form.consumption_type_label",
                          },
                        },
                        [_vm._v("其他")]
                      ),
                    ],
                    1
                  ),
                  _vm.form.consumption_type_label === "customize"
                    ? _c(
                        "el-col",
                        { attrs: { xl: 20, lg: 18 } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              attrs: { min: 0 },
                              model: {
                                value: _vm.form.consumption_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "consumption_type", $$v)
                                },
                                expression: "form.consumption_type",
                              },
                            },
                            [
                              _c(
                                "el-checkbox",
                                { attrs: { label: "PAID_GOODS_VIRTUAL" } },
                                [_vm._v("虚拟商品")]
                              ),
                              _c(
                                "el-checkbox",
                                { attrs: { label: "PAID_GOODS_REAL" } },
                                [_vm._v("实物电商")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "游戏消费：",
                prop: "game_consumption_level_label",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { xl: 4, lg: 6 } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "", border: "" },
                          on: {
                            change: function ($event) {
                              _vm.form.game_consumption_level = []
                            },
                          },
                          model: {
                            value: _vm.form.game_consumption_level_label,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "game_consumption_level_label",
                                $$v
                              )
                            },
                            expression: "form.game_consumption_level_label",
                          },
                        },
                        [_vm._v("不限")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "customize", border: "" },
                          model: {
                            value: _vm.form.game_consumption_level_label,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "game_consumption_level_label",
                                $$v
                              )
                            },
                            expression: "form.game_consumption_level_label",
                          },
                        },
                        [_vm._v("其他")]
                      ),
                    ],
                    1
                  ),
                  _vm.form.game_consumption_level_label === "customize"
                    ? _c(
                        "el-col",
                        { attrs: { xl: 20, lg: 18 } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              attrs: { min: 0 },
                              model: {
                                value: _vm.form.game_consumption_level,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "game_consumption_level",
                                    $$v
                                  )
                                },
                                expression: "form.game_consumption_level",
                              },
                            },
                            [
                              _c("el-checkbox", { attrs: { label: "HIGH" } }, [
                                _vm._v("高"),
                              ]),
                              _c(
                                "el-checkbox",
                                { attrs: { label: "NORMAL" } },
                                [_vm._v("普通")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: "消费水平：", prop: "consumption_status_label" },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { xl: 4, lg: 6 } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "", border: "" },
                          on: {
                            change: function ($event) {
                              _vm.form.consumption_status = []
                            },
                          },
                          model: {
                            value: _vm.form.consumption_status_label,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "consumption_status_label",
                                $$v
                              )
                            },
                            expression: "form.consumption_status_label",
                          },
                        },
                        [_vm._v("不限")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "customize", border: "" },
                          model: {
                            value: _vm.form.consumption_status_label,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "consumption_status_label",
                                $$v
                              )
                            },
                            expression: "form.consumption_status_label",
                          },
                        },
                        [_vm._v("其他")]
                      ),
                    ],
                    1
                  ),
                  _vm.form.consumption_status_label === "customize"
                    ? _c(
                        "el-col",
                        { attrs: { xl: 20, lg: 18 } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              attrs: { min: 0 },
                              model: {
                                value: _vm.form.consumption_status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "consumption_status", $$v)
                                },
                                expression: "form.consumption_status",
                              },
                            },
                            [
                              _c("el-checkbox", { attrs: { label: "HIGH" } }, [
                                _vm._v("高消费"),
                              ]),
                              _c("el-checkbox", { attrs: { label: "LOW" } }, [
                                _vm._v("低消费"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "社区价格：",
                prop: "residential_community_price_label",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { xl: 4, lg: 6 } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: {
                            value: _vm.form.residential_community_price_label,
                            label: "",
                            border: "",
                          },
                          on: {
                            change: function ($event) {
                              ;(_vm.form.residential_community_price = ""),
                                (_vm.form.residential_community_price_label =
                                  "")
                            },
                          },
                        },
                        [_vm._v("不限")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: {
                            value: _vm.form.residential_community_price_label,
                            label: "customize",
                            border: "",
                          },
                          on: {
                            change: function ($event) {
                              ;(_vm.form.residential_community_price = [
                                1, 100000,
                              ]),
                                (_vm.form.residential_community_price_label =
                                  "customize")
                            },
                          },
                        },
                        [_vm._v("自定义")]
                      ),
                    ],
                    1
                  ),
                  _vm.form.residential_community_price_label === "customize"
                    ? _c(
                        "el-col",
                        {
                          attrs: {
                            prop: "residential_community_price",
                            xl: 20,
                            lg: 18,
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "160px" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: "请选择",
                              },
                              on: {
                                change: (value) =>
                                  value >
                                  _vm.form.residential_community_price[0]
                                    ? (_vm.form.residential_community_price[0] =
                                        value)
                                    : null,
                              },
                              model: {
                                value: _vm.form.residential_community_price[0],
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.residential_community_price,
                                    0,
                                    $$v
                                  )
                                },
                                expression:
                                  "form.residential_community_price[0]",
                              },
                            },
                            _vm._l(
                              _vm.residential_community_price_min,
                              function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: { label: item.value, value: item.key },
                                })
                              }
                            ),
                            1
                          ),
                          _c("span", [_vm._v(" - ")]),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "160px" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: "请选择",
                              },
                              on: {
                                change: (value) =>
                                  value >
                                  _vm.form.residential_community_price[1]
                                    ? (_vm.form.residential_community_price[1] =
                                        value)
                                    : null,
                              },
                              model: {
                                value: _vm.form.residential_community_price[1],
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form.residential_community_price,
                                    1,
                                    $$v
                                  )
                                },
                                expression:
                                  "form.residential_community_price[1]",
                              },
                            },
                            _vm._l(
                              _vm.residential_community_price_max,
                              function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: { label: item.value, value: item.key },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider", [_vm._v("用户行为")]),
          _c(
            "el-form-item",
            { attrs: { label: "行为兴趣意向：", prop: "interest_label" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "", border: "" },
                  on: {
                    change: function ($event) {
                      _vm.form.marital_status = []
                    },
                  },
                  model: {
                    value: _vm.form.interest_label,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "interest_label", $$v)
                    },
                    expression: "form.interest_label",
                  },
                },
                [_vm._v("不限")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "customize", border: "" },
                  model: {
                    value: _vm.form.interest_label,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "interest_label", $$v)
                    },
                    expression: "form.interest_label",
                  },
                },
                [_vm._v("自定义")]
              ),
              _vm.form.interest_label === "customize"
                ? _c(
                    "el-collapse",
                    {
                      staticClass: "mt-20",
                      attrs: { accordion: "" },
                      on: { change: (name) => _vm.getTargetingTagsFun(name) },
                    },
                    [
                      _c(
                        "el-collapse-item",
                        { attrs: { name: "BEHAVIOR" } },
                        [
                          _c("template", { slot: "title" }, [_vm._v("行为")]),
                          _c(
                            "el-cascader",
                            {
                              attrs: {
                                placeholder: "搜索行为名称",
                                options: _vm.BEHAVIOR_loading,
                                "collapse-tags": "",
                                props: {
                                  label: "name",
                                  value: "id",
                                  multiple: true,
                                },
                                clearable: "",
                              },
                            },
                            [_vm._v(">")]
                          ),
                        ],
                        2
                      ),
                      _c(
                        "el-collapse-item",
                        { attrs: { name: "INTEREST" } },
                        [
                          _c("template", { slot: "title" }, [_vm._v("兴趣 ")]),
                          _c("el-cascader", {
                            attrs: {
                              placeholder: "搜索兴趣名称",
                              options: [],
                              props: _vm.props,
                              "collapse-tags": "",
                              clearable: "",
                            },
                          }),
                        ],
                        2
                      ),
                      _c(
                        "el-collapse-item",
                        { attrs: { name: "INTENTION" } },
                        [
                          _c("template", { slot: "title" }, [_vm._v("意向 ")]),
                          _c("el-cascader", {
                            attrs: {
                              placeholder: "搜索意向名称",
                              options: [],
                              props: _vm.props,
                              "collapse-tags": "",
                              clearable: "",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "排除已转化：",
                prop: "excluded_dimension_label",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "", border: "" },
                          on: {
                            change: function ($event) {
                              ;(_vm.form.conversion_behavior_list_flag = false),
                                (_vm.form.conversion_behavior_list = [])
                            },
                          },
                          model: {
                            value: _vm.form.excluded_dimension,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "excluded_dimension", $$v)
                            },
                            expression: "form.excluded_dimension",
                          },
                        },
                        [_vm._v("不限")]
                      ),
                      _vm._l(_vm.excluded_dimension_list, function (item) {
                        return _c(
                          "el-radio",
                          {
                            key: item.dictLabel,
                            attrs: { label: item.dictValue, border: "" },
                            on: {
                              change: function ($event) {
                                ;(_vm.form.conversion_behavior_list_flag = false),
                                  (_vm.form.conversion_behavior_list = [])
                              },
                            },
                            model: {
                              value: _vm.form.excluded_dimension,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "excluded_dimension", $$v)
                              },
                              expression: "form.excluded_dimension",
                            },
                          },
                          [_vm._v(_vm._s(item.dictLabel))]
                        )
                      }),
                    ],
                    2
                  ),
                  _vm.form.excluded_dimension !== ""
                    ? _c("el-col", [
                        _c(
                          "div",
                          { staticClass: "background mt-10" },
                          [
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  padding: "0",
                                  "margin-bottom": "-8px",
                                },
                              },
                              [
                                _vm._v(
                                  "系统自动依照当前广告选择的优化目标作为此定向的转化行为"
                                ),
                              ]
                            ),
                            _c(
                              "el-checkbox",
                              {
                                attrs: {
                                  disabled:
                                    _vm.form.excluded_dimension ===
                                    "EXCLUDED_DIMENSION_PRODUCT",
                                },
                                model: {
                                  value: _vm.form.conversion_behavior_list_flag,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "conversion_behavior_list_flag",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.conversion_behavior_list_flag",
                                },
                              },
                              [_vm._v("自定义转化行为")]
                            ),
                            _c("br"),
                            _vm.form.conversion_behavior_list_flag
                              ? _c(
                                  "el-select",
                                  {
                                    staticClass: "mt-10 w-300",
                                    attrs: {
                                      "multiple-limit": 1,
                                      multiple: "",
                                      filterable: "",
                                      clearable: "",
                                      placeholder: "请选择",
                                    },
                                    on: {
                                      change: (value) =>
                                        value >
                                        _vm.form.residential_community_price[1]
                                          ? (_vm.form.residential_community_price[1] =
                                              value)
                                          : null,
                                    },
                                    model: {
                                      value: _vm.form.conversion_behavior_list,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "conversion_behavior_list",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.conversion_behavior_list",
                                    },
                                  },
                                  _vm._l(
                                    _vm.excluded_converted_audience_list,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.dictValue,
                                        attrs: {
                                          label: item.dictLabel,
                                          value: item.dictValue,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "自定义人群：", prop: "interest_label" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { xl: 4, lg: 6 } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "", border: "" },
                          on: {
                            change: function ($event) {
                              _vm.form.marital_status = []
                            },
                          },
                          model: {
                            value: _vm.form.education_label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "education_label", $$v)
                            },
                            expression: "form.education_label",
                          },
                        },
                        [_vm._v("不限")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "customize", border: "" },
                          model: {
                            value: _vm.form.education_label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "education_label", $$v)
                            },
                            expression: "form.education_label",
                          },
                        },
                        [_vm._v("其他")]
                      ),
                    ],
                    1
                  ),
                  _vm.form.education_label === "customize"
                    ? _c(
                        "el-col",
                        { attrs: { xl: 20, lg: 18 } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              attrs: { min: 0 },
                              model: {
                                value: _vm.form.education,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "education", $$v)
                                },
                                expression: "form.education",
                              },
                            },
                            [
                              _c(
                                "el-checkbox",
                                { attrs: { label: "item.dictValue" } },
                                [_vm._v("定向人群")]
                              ),
                              _c(
                                "el-checkbox",
                                { attrs: { label: "item.dictValue" } },
                                [_vm._v("排除人群")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider", [_c("span", [_vm._v("设备定向")])]),
          _c(
            "el-form-item",
            { attrs: { label: "设备品牌型号：", prop: "included_list_label" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "", border: "" },
                          on: {
                            change: function ($event) {
                              _vm.form.included_list = []
                            },
                          },
                          model: {
                            value: _vm.form.included_list_label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "included_list_label", $$v)
                            },
                            expression: "form.included_list_label",
                          },
                        },
                        [_vm._v("不限")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "customize", border: "" },
                          on: {
                            change: function ($event) {
                              return _vm.getTargetingTagsFun(
                                "DEVICE_BRAND_MODEL"
                              )
                            },
                          },
                          model: {
                            value: _vm.form.included_list_label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "included_list_label", $$v)
                            },
                            expression: "form.included_list_label",
                          },
                        },
                        [_vm._v("自定义")]
                      ),
                    ],
                    1
                  ),
                  _vm.form.included_list_label === "customize"
                    ? _c("el-col", { staticClass: "mt-20" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.included_type_loading,
                                expression: "included_type_loading",
                              },
                            ],
                          },
                          [
                            _c("el-cascader", {
                              staticStyle: { width: "400px" },
                              attrs: {
                                options: _vm.included_type_list,
                                "collapse-tags": "",
                                props: {
                                  label: "name",
                                  value: "id",
                                  multiple: true,
                                },
                                clearable: "",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "上网场景：", prop: "network_scene_label" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { xl: 4, lg: 6 } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "", border: "" },
                          on: {
                            change: function ($event) {
                              _vm.form.network_scene = []
                            },
                          },
                          model: {
                            value: _vm.form.network_scene_label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "network_scene_label", $$v)
                            },
                            expression: "form.network_scene_label",
                          },
                        },
                        [_vm._v("不限")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "customize", border: "" },
                          model: {
                            value: _vm.form.network_scene_label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "network_scene_label", $$v)
                            },
                            expression: "form.network_scene_label",
                          },
                        },
                        [_vm._v("其他")]
                      ),
                    ],
                    1
                  ),
                  _vm.form.network_scene_label === "customize"
                    ? _c(
                        "el-col",
                        { attrs: { xl: 20, lg: 18 } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              attrs: { min: 0 },
                              model: {
                                value: _vm.form.network_scene,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "network_scene", $$v)
                                },
                                expression: "form.network_scene",
                              },
                            },
                            _vm._l(_vm.network_scene_list, function (item) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: item.dictLabel,
                                  attrs: { label: item.dictValue },
                                },
                                [_vm._v(_vm._s(item.dictLabel))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "操作系统版本：", prop: "user_os_label" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "", border: "" },
                          on: {
                            change: function ($event) {
                              _vm.form.user_os_type = []
                            },
                          },
                          model: {
                            value: _vm.form.user_os_label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "user_os_label", $$v)
                            },
                            expression: "form.user_os_label",
                          },
                        },
                        [_vm._v("不限")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "customize", border: "" },
                          model: {
                            value: _vm.form.user_os_label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "user_os_label", $$v)
                            },
                            expression: "form.user_os_label",
                          },
                        },
                        [_vm._v("其他")]
                      ),
                    ],
                    1
                  ),
                  _vm.form.user_os_label === "customize"
                    ? _c(
                        "el-col",
                        { staticClass: "mt-20" },
                        [
                          _c("el-cascader", {
                            staticStyle: { width: "400px" },
                            attrs: {
                              "collapse-tags": "",
                              options: _vm.user_os_list,
                              props: {
                                label: "dictLabel",
                                value: "dictValue",
                                multiple: true,
                              },
                              clearable: "",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "联网方式：", prop: "network_type_label" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { xl: 4, lg: 6 } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "", border: "" },
                          on: {
                            change: function ($event) {
                              _vm.form.network_type = []
                            },
                          },
                          model: {
                            value: _vm.form.network_type_label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "network_type_label", $$v)
                            },
                            expression: "form.network_type_label",
                          },
                        },
                        [_vm._v("不限")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "customize", border: "" },
                          model: {
                            value: _vm.form.network_type_label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "network_type_label", $$v)
                            },
                            expression: "form.network_type_label",
                          },
                        },
                        [_vm._v("其他")]
                      ),
                    ],
                    1
                  ),
                  _vm.form.network_type_label === "customize"
                    ? _c(
                        "el-col",
                        { attrs: { xl: 20, lg: 18 } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              attrs: { min: 0 },
                              model: {
                                value: _vm.form.network_type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "network_type", $$v)
                                },
                                expression: "form.network_type",
                              },
                            },
                            _vm._l(_vm.network_type_list, function (item) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: item.dictLabel,
                                  attrs: { label: item.dictValue },
                                },
                                [_vm._v(_vm._s(item.dictLabel))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: "移动运营商：", prop: "network_operator_label" },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { xl: 4, lg: 6 } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "", border: "" },
                          on: {
                            change: function ($event) {
                              _vm.form.network_operator = []
                            },
                          },
                          model: {
                            value: _vm.form.network_operator_label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "network_operator_label", $$v)
                            },
                            expression: "form.network_operator_label",
                          },
                        },
                        [_vm._v("不限")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "customize", border: "" },
                          model: {
                            value: _vm.form.network_operator_label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "network_operator_label", $$v)
                            },
                            expression: "form.network_operator_label",
                          },
                        },
                        [_vm._v("其他")]
                      ),
                    ],
                    1
                  ),
                  _vm.form.network_operator_label === "customize"
                    ? _c(
                        "el-col",
                        { attrs: { xl: 20, lg: 18 } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              attrs: { min: 0 },
                              model: {
                                value: _vm.form.network_operator,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "network_operator", $$v)
                                },
                                expression: "form.network_operator",
                              },
                            },
                            _vm._l(_vm.network_operator_list, function (item) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: item.dictLabel,
                                  attrs: { label: item.dictValue },
                                },
                                [_vm._v(_vm._s(item.dictLabel))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "设备价格：", prop: "device_price_label" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { xl: 4, lg: 6 } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "", border: "" },
                          on: {
                            change: function ($event) {
                              _vm.form.device_price = []
                            },
                          },
                          model: {
                            value: _vm.form.device_price_label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "device_price_label", $$v)
                            },
                            expression: "form.device_price_label",
                          },
                        },
                        [_vm._v("不限")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "customize", border: "" },
                          model: {
                            value: _vm.form.device_price_label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "device_price_label", $$v)
                            },
                            expression: "form.device_price_label",
                          },
                        },
                        [_vm._v("其他")]
                      ),
                    ],
                    1
                  ),
                  _vm.form.device_price_label === "customize"
                    ? _c(
                        "el-col",
                        { attrs: { xl: 20, lg: 18 } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              attrs: { min: 0 },
                              model: {
                                value: _vm.form.device_price,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "device_price", $$v)
                                },
                                expression: "form.device_price",
                              },
                            },
                            _vm._l(_vm.device_price_list, function (item) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: item.dictLabel,
                                  attrs: { label: item.dictValue },
                                },
                                [_vm._v(_vm._s(item.dictLabel))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider", [_c("span", [_vm._v("天气定向")])]),
          _c(
            "el-form-item",
            { attrs: { label: "温度：", prop: "temperature_label" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { xl: 4, lg: 6 } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: {
                            value: _vm.form.temperature_label,
                            label: "",
                            border: "",
                          },
                          on: {
                            change: function ($event) {
                              ;(_vm.form.temperature = ""),
                                (_vm.form.temperature_label = "")
                            },
                          },
                        },
                        [_vm._v("不限")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: {
                            value: _vm.form.temperature_label,
                            label: "customize",
                            border: "",
                          },
                          on: {
                            change: function ($event) {
                              ;(_vm.form.temperature = [252, 323]),
                                (_vm.form.temperature_label = "customize")
                            },
                          },
                        },
                        [_vm._v("自定义")]
                      ),
                    ],
                    1
                  ),
                  _vm.form.temperature_label === "customize"
                    ? _c(
                        "el-col",
                        { attrs: { prop: "temperature", xl: 20, lg: 18 } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "160px" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: "请选择",
                              },
                              on: {
                                change: (value) =>
                                  value > _vm.form.temperature[0]
                                    ? (_vm.form.temperature[0] = value)
                                    : null,
                              },
                              model: {
                                value: _vm.form.temperature[0],
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.temperature, 0, $$v)
                                },
                                expression: "form.temperature[0]",
                              },
                            },
                            _vm._l(_vm.temperature_min, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.value, value: item.key },
                              })
                            }),
                            1
                          ),
                          _c("span", [_vm._v(" - ")]),
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "160px" },
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: "请选择",
                              },
                              on: {
                                change: (value) =>
                                  value > _vm.form.temperature[1]
                                    ? (_vm.form.temperature[1] = value)
                                    : null,
                              },
                              model: {
                                value: _vm.form.temperature[1],
                                callback: function ($$v) {
                                  _vm.$set(_vm.form.temperature, 1, $$v)
                                },
                                expression: "form.temperature[1]",
                              },
                            },
                            _vm._l(_vm.temperature_max, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.value, value: item.key },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "紫外线指数：", prop: "uv_index_label" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { xl: 4, lg: 6 } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "", border: "" },
                          on: {
                            change: function ($event) {
                              _vm.form.uv_index = []
                            },
                          },
                          model: {
                            value: _vm.form.uv_index_label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "uv_index_label", $$v)
                            },
                            expression: "form.uv_index_label",
                          },
                        },
                        [_vm._v("不限")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "customize", border: "" },
                          model: {
                            value: _vm.form.uv_index_label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "uv_index_label", $$v)
                            },
                            expression: "form.uv_index_label",
                          },
                        },
                        [_vm._v("其他")]
                      ),
                    ],
                    1
                  ),
                  _vm.form.uv_index_label === "customize"
                    ? _c(
                        "el-col",
                        { attrs: { xl: 20, lg: 18 } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              attrs: { min: 0 },
                              model: {
                                value: _vm.form.uv_index,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "uv_index", $$v)
                                },
                                expression: "form.uv_index",
                              },
                            },
                            _vm._l(_vm.uv_index_list, function (item) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: item.dictLabel,
                                  attrs: { label: item.dictValue },
                                },
                                [_vm._v(_vm._s(item.dictLabel))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "穿衣指数：", prop: "dressing_index_label" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { xl: 4, lg: 6 } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "", border: "" },
                          on: {
                            change: function ($event) {
                              _vm.form.dressing_index = []
                            },
                          },
                          model: {
                            value: _vm.form.dressing_index_label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "dressing_index_label", $$v)
                            },
                            expression: "form.dressing_index_label",
                          },
                        },
                        [_vm._v("不限")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "customize", border: "" },
                          model: {
                            value: _vm.form.dressing_index_label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "dressing_index_label", $$v)
                            },
                            expression: "form.dressing_index_label",
                          },
                        },
                        [_vm._v("其他")]
                      ),
                    ],
                    1
                  ),
                  _vm.form.dressing_index_label === "customize"
                    ? _c(
                        "el-col",
                        { attrs: { xl: 20, lg: 18 } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              attrs: { min: 0 },
                              model: {
                                value: _vm.form.dressing_index,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "dressing_index", $$v)
                                },
                                expression: "form.dressing_index",
                              },
                            },
                            _vm._l(_vm.dressing_index_list, function (item) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: item.dictLabel,
                                  attrs: { label: item.dictValue },
                                },
                                [_vm._v(_vm._s(item.dictLabel))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "气象：", prop: "climate_label" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { xl: 4, lg: 6 } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "", border: "" },
                          on: {
                            change: function ($event) {
                              _vm.form.climate = []
                            },
                          },
                          model: {
                            value: _vm.form.climate_label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "climate_label", $$v)
                            },
                            expression: "form.climate_label",
                          },
                        },
                        [_vm._v("不限")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "customize", border: "" },
                          model: {
                            value: _vm.form.climate_label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "climate_label", $$v)
                            },
                            expression: "form.climate_label",
                          },
                        },
                        [_vm._v("其他")]
                      ),
                    ],
                    1
                  ),
                  _vm.form.climate_label === "customize"
                    ? _c(
                        "el-col",
                        { attrs: { xl: 20, lg: 18 } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              attrs: { min: 0 },
                              model: {
                                value: _vm.form.climate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "climate", $$v)
                                },
                                expression: "form.climate",
                              },
                            },
                            _vm._l(_vm.climate_list, function (item) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: item.dictLabel,
                                  attrs: { label: item.dictValue },
                                },
                                [_vm._v(_vm._s(item.dictLabel))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "化妆指数：", prop: "makeup_index_label" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { xl: 4, lg: 6 } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "", border: "" },
                          on: {
                            change: function ($event) {
                              _vm.form.makeup_index = []
                            },
                          },
                          model: {
                            value: _vm.form.makeup_index_label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "makeup_index_label", $$v)
                            },
                            expression: "form.makeup_index_label",
                          },
                        },
                        [_vm._v("不限")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "customize", border: "" },
                          model: {
                            value: _vm.form.makeup_index_label,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "makeup_index_label", $$v)
                            },
                            expression: "form.makeup_index_label",
                          },
                        },
                        [_vm._v("其他")]
                      ),
                    ],
                    1
                  ),
                  _vm.form.makeup_index_label === "customize"
                    ? _c(
                        "el-col",
                        { attrs: { xl: 20, lg: 18 } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              attrs: { min: 0 },
                              model: {
                                value: _vm.form.makeup_index,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "makeup_index", $$v)
                                },
                                expression: "form.makeup_index",
                              },
                            },
                            _vm._l(_vm.makeup_index_list, function (item) {
                              return _c(
                                "el-checkbox",
                                {
                                  key: item.dictLabel,
                                  attrs: { label: item.dictValue },
                                },
                                [_vm._v(_vm._s(item.dictLabel))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "自动扩量：", prop: "uv_index" } },
            [_c("el-switch")],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "不可突破定向：", prop: "uv_index" } },
            [
              _c(
                "el-checkbox-group",
                {
                  attrs: { min: 0 },
                  model: {
                    value: _vm.form.climate,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "climate", $$v)
                    },
                    expression: "form.climate",
                  },
                },
                [
                  _c("el-checkbox", { attrs: { label: "地域" } }, [
                    _vm._v("地域"),
                  ]),
                  _c("el-checkbox", { attrs: { label: "年龄" } }, [
                    _vm._v("年龄"),
                  ]),
                  _c("el-checkbox", { attrs: { label: "性别" } }, [
                    _vm._v("性别"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.resetForm("ruleForm")
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }