<template>
  <div>
    <Search ref="search" />
    <el-row type="flex" justify="space-between" class="operate-box">
      <el-button type="primary" icon="el-icon-plus" round @click="showAdd">新 增</el-button>
    </el-row>
    <div class="table-container">
      <el-table v-loading="loading" stripe class="list-table" :data="data">
        <c-data-empty slot="empty" />
        <el-table-column type="index" width="50" label="序号" align="left" />
        <el-table-column prop="typeName" label="品类名称" align="left" />
        <el-table-column prop="isUseing" label="是否启用" align="left">
          <template slot-scope="scope">
            <div v-if="scope.row.isUseing === 'ISUSEING'" slot="reference" class="name-wrapper">
              <el-alert title="启用" type="success" center show-icon :closable="false" />
            </div>
            <div v-if="scope.row.isUseing === 'ISNOTUSEING'" slot="reference" class="name-wrapper">
              <el-alert title="不启用" type="error" center show-icon :closable="false" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="createBy" label="创建者" align="left" />
        <el-table-column prop="createTime" label="创建时间" align="left" />
        <el-table-column prop="updateBy" label="更新者" align="left" />
        <el-table-column prop="updateTime" label="更新时间" align="left" />

        <el-table-column label="操作" width="100">
          <template slot-scope="{ row }">
            <el-button type="text" size="small" @click="showEdit(row)">
              <el-tooltip content="编辑" placement="top">
                <i class="el-icon-edit" style="font-size: 16px"></i>
              </el-tooltip>
            </el-button>
            <el-button type="text" size="small" @click="deleteRow(row)">
              <el-tooltip content="删除" placement="top">
                <i class="el-icon-delete" style="font-size: 16px; color: red"></i>
              </el-tooltip>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <c-pagination :page-size.sync="pageInfo.pageSize" :page-no.sync="pageInfo.pageNum" :total="pageInfo.total" :get-data-fun="loadData" />
    <OptionDialog ref="dialog" />
  </div>
</template>

<script>
import { PageCommonComponent } from '@/common/mixin'
import { Page, Delete } from '@/network/api/basicSetting/product/basicSettingProductType'
import Search from './Search'
import OptionDialog from './OptionDialog'
import { BasicSettingProductTypeEnt } from './object'
export default {
  name: 'BasicSettingProductType',
  components: { Search, OptionDialog },
  mixins: [PageCommonComponent],
  provide: function() {
    return {
      loadData: this.loadData,
      showEdit: this.showEdit
    }
  },
  data() {
    return {
      loading: false,
      data: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData(isFirst = false) {
      const searchForm = this.$refs.search.getSearchForm()

      this.doLoadData(isFirst, searchForm)
    },
    doLoadData(firstPage = false, searchForm) {
      firstPage && (this.pageInfo.pageNum = 1)
      const req = { ...searchForm, ...this.pageInfo }
      this.loading = true
      Page(req).then((res) => {
        this.loading = false
        if (res.code === 200) {
          this.data = res.data.records
          this.pageInfo.total = res.data.total
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
          this.data = []
          this.pageInfo.total = 0
        }
      })
    },
    deleteRow(row) {
      this.$confirm('确定删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          Delete(row.bsProductTypeId).then((res) => {
            if (res.code === 200) {
              this.$message({
                message: '删除成功',
                type: 'success'
              })
              this.loadData()
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showEdit(row) {
      this.$refs.dialog.show(true, { ...row })
    },
    showAdd() {
      this.$refs.dialog.show(false, new BasicSettingProductTypeEnt())
    }
  }
}
</script>

<style scoped>
.el-alert--success.is-light {
  background-color: #f0f9eb;
  color: #67c23a;
  padding: 0;
}
.el-alert--error.is-light {
  background-color: #fef0f0;
  color: #f56c6c;
  padding: 0;
}
</style>
