<template>
  <!--显示列弹框-->
  <el-dialog class="edit-info-dialog" :visible.sync="show" title="浮层卡片选择" :modal-append-to-body="false" width="850px"
    @close="onCloseDialog">
    <el-table ref="multipleTable" :data="tableData" v-loading="loading" @row-click="singleSelection">
      <el-table-column align="center" width="55" label="选择">
        <template slot-scope="scope">
          <el-radio class="radio" v-model="templateSelection" :label="scope.row.taskAdcId">&nbsp;</el-radio>
        </template>
      </el-table-column>
      <el-table-column label="图片" align="left">
        <template slot-scope="{row}">
          <div style="width:60px;height: 60px;">
            <el-image :src="row.floatingZoneImageId"></el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="文案（1-10字）" align="center">
        <span slot-scope="{ row }">{{ row.floatingZoneName }}</span>
      </el-table-column>
      <el-table-column label="文案（1-14字）" align="center">
        <span slot-scope="{ row }">{{ row.floatingZoneDesc }}</span>
      </el-table-column>
      <el-table-column label="按钮文案" align="center">
        <span slot-scope="{ row }">{{ row.floatingZoneButtonText }}</span>
      </el-table-column>
      <el-table-column label="创建时间" align="center">
        <span slot-scope="{ row }">{{ row.createTime || '-' }}</span>
      </el-table-column>
    </el-table>
    <div class="pagination-container">
      <c-pagination :page-size.sync="pageSize" :page-no.sync="pageNum" :pager-count="5" :total="total"
        tools="total, prev, pager, next" :get-data-fun="getPageData" />
    </div>
    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">取 消</el-button>
      <el-button class="btn" type="primary" @click="clickConfirm">确 定 </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { PageCommonComponent } from '@/common/mixin'
import { getFloadingCardList } from '@/network/api/advManagement/advManagementTencent'

export default {
  name: 'FloatingCardDialog',
  mixins: [PageCommonComponent],
  data() {
    return {
      show: false, // 是否显示弹框
      loading: false,
      tableData: [], // 当前账户所选列表
      templateSelection: '',
      selectedData: [], // 当前投放账户选中的数据
      pageNum: 1,
      pageSize: 10,
      total: 0
    }
  },
  methods: {
    /* --------------------------- Actions --------------------------- */
    // 显示弹框
    showModal() {
      this.show = true
      this.getPageData()
    },

    // 关闭弹框
    closeModal() {
      this.show = false
    },

    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      this.templateSelection = ''
      this.selectedData = []
    },

    singleSelection(value) {
      if (this.templateSelection === value.taskAdcId) {
        this.templateSelection = ''
        this.selectedData = []
        return
      }
      this.templateSelection = value.taskAdcId
      this.selectedData = [value]
    },

    handleRadioSelect(label) {
      console.log(label)
    },

    getPageData() {
      let params = {
        page: this.pageNum,
        pageSize: this.pageSize
      }
      this.loading = true
      getFloadingCardList(params).then((res) => {
        if (res.code === 200) {
          let list = res.data.records.map(v => {
            let item = v.adcreativeElements ? JSON.parse(v.adcreativeElements).floatingZoneStruct : {}
            item.createTime = v.createTime
            item.taskAdcId = v.taskAdcId
            return item
          })
          this.tableData = list
          this.total = res.data.total
        }
      }).finally(() => {
        this.loading = false
      })
    },

    // 点击确定按钮
    clickConfirm() {
      if (!this.selectedData.length) {
        return this.$message.error('请选择浮层卡片')
      }

      this.$emit('select', this.selectedData[0])
      this.templateSelection = ''
      this.selectedData = []
      this.closeModal()
    }
  }
}
</script>
