<template>
  <div class="table-template">
    <el-container>
      <el-aside width="20%">
        <!-- 顶部标签 -->
        <!-- <el-tabs v-model="imageType" type="card" @tab-click="onClickSelectType()">
          <el-tab-pane label="专辑" :name="'1'"></el-tab-pane>
          <el-tab-pane label="素材组" :name="'2'"></el-tab-pane>
          <el-tab-pane label="素材" :name="'3'"></el-tab-pane>
        </el-tabs> -->

        <div>
          <el-input v-model.trim="input3" placeholder="请输入内容" class="input-with-select" clearable>
            <el-select slot="prepend" v-model="imageTypeTree" style="width:90px " placeholder="请选择"
              @change="onClickSearchInput">
              <el-option label="专辑" value="1"></el-option>
              <el-option label="素材组" value="2"></el-option>
              <!-- <el-option label="素材" value="3"></el-option> -->
            </el-select>
            <el-button slot="append" icon="el-icon-search" @click="onClickSearchInput"></el-button>
          </el-input>
        </div>
        <div style="margin-top: 10px;" @click="onClickSearchAll">
          <el-row>
            <el-col :span="21">
              <div class="grid-content bg-purple">
                <span style="font-weight: 700;margin-top: 10px;">全部专辑</span>
              </div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content bg-purple-light">
                <el-popover popper-class="popper" style="padding-right:20px" placement="top-start" trigger="hover">
                  <div class="popover-content">
                    <div class="popover-btn" @click="onClickAddAlbum(0, 0)">新增专辑</div>
                  </div>
                  <el-button slot="reference" style="color:#000000" type="text" icon="el-icon-more"
                    @click.stop=""></el-button>
                </el-popover>
              </div>
            </el-col>
          </el-row>
        </div>
        <div style="height: 700px;">

          <el-tree :data="imgStructure" :props="defaultProps" style="width:95% height:600px" :expand-on-click-node="false"
            @node-click="albumCheckTree">
            <span slot-scope="{ data }" class="custom-tree-node">
              <template>
                <span v-if="data.label.length <= 8">{{ data.label }}</span>
                <el-tooltip v-else class="item" effect="dark" :content="data.label" placement="top">
                  <span>{{ data.label.slice(0, 8) }}...</span>
                </el-tooltip>
              </template>
              <el-popover v-if="data.level === 1" popper-class="popper" style="padding-right:20px" placement="top-start"
                trigger="hover">
                <div class="popover-content">
                  <div class="popover-btn" @click="onClickAddAlbum(data.albumId, data.projectId)">编辑专辑</div>
                  <div class="popover-btn" @click="deleteAlbum(data.albumId)">删除专辑</div>
                  <div class="popover-btn" @click="iconMore(data)">新增素材组</div>
                </div>
                <el-button slot="reference" type="text" icon="el-icon-more" @click.stop=""></el-button>
              </el-popover>
              <el-popover v-else popper-class="popper" style="padding-right:5px" placement="top-start" trigger="hover">
                <div class="popover-content">
                  <div class="popover-btn" @click="onClickAddMaterialGroup(data.materialGroupId)">编辑素材组</div>
                  <div class="popover-btn" @click="onClickDelTree(data.materialGroupId)">删除素材组</div>
                  <div class="popover-btn" @click="iconMore(data)">新增素材</div>

                </div>
                <el-button slot="reference" type="text" icon="el-icon-more" @click.stop=""></el-button>

              </el-popover>

              <!-- <span v-if="node.level===1" style="padding-right:20px" @click.stop="iconMore(node)">
                <i class="el-icon-more"></i>
              </span>

              <span v-else style="padding-right:5px" @click.stop="iconMore(node)">
                <i class="el-icon-more"></i>
              </span> -->
            </span>

          </el-tree>
        </div>

      </el-aside>
      <el-main style="padding-top: 0px;">
        <div class="search-form-container">
          <span style="font-size: 20px;font-weight: 600; ">{{ imageType === '1' ? '专辑列表' : imageType === '2' ? '组列表' :
            '素材列表'
          }}</span>

          <!--查询条件-->
          <!-- <el-form v-show="false" class="search-form search-form-4">

            <template v-if="imageType === '1'">
              <el-row :gutter="15">
                <el-col :span="4">
                  <el-form-item prop="albumName" label="专辑名称">
                    <el-input v-model="albumSearchForm.albumName" placeholder="请输入专辑名称查询" style="width: 100%;"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="9">
                  <el-form-item prop="date" label="创建时间">
                    <el-date-picker v-model="albumSearchForm.date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="4">

                  <el-button type="primary" style="width: 70px;" @click="onClickSearch">查询</el-button>

                  <el-button type="plain" style="width: 70px;" @click="onClickReset">重置</el-button>
                </el-col>
              </el-row>
            </template>

            <template v-if="imageType === '2'">
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item prop="materialGroupName" label="素材组名称">
                    <el-input v-model="materialGroupSearchForm.materialGroupName" placeholder="请输入素材组名称" style="width: 100%;"></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="6">
                  <el-form-item prop="albumId" label="专辑">
                    <el-select v-model="materialGroupSearchForm.albumId" filterable clearable placeholder="请选择专辑" style="width: 100%;">
                      <el-option v-for="(item, index) in allAlbumList" :key="index" :label="item.albumName" :value="item.albumId"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="date" label="创建时间">
                    <el-date-picker v-model="materialGroupSearchForm.date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="6">

                  <el-button type="primary" style="width: 70px;" @click="onClickSearch">查询</el-button>

                  <el-button type="plain" style="width: 70px;" @click="onClickReset">重置</el-button>
                </el-col>
              </el-row>
            </template>

            <template v-if="imageType === '3'">
              <el-row :gutter="20">
                <el-col :span="6">
                  <el-form-item prop="materialName" label="素材名称">
                    <el-input v-model="materialSearchForm.materialName" placeholder="请输入素材名称"></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="6">
                  <el-form-item prop="albumId" label="专辑名称">
                    <el-select v-model="materialSearchForm.albumId" filterable clearable placeholder="请选择专辑" style="width: 100%;" @change="handlegroup">
                      <el-option v-for="(item, index) in allAlbumList" :key="index" :label="item.albumName" :value="item.albumId"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="6">
                  <el-form-item prop="materialGroupId" label="素材组">
                    <el-select v-model="materialSearchForm.materialGroupId" filterable clearable :disabled="!materialSearchForm.albumId" placeholder="请选择素材组" style="width: 100%;">
                      <el-option v-for="(item, index) in allMaterialGroupList" :key="index" :label="item.materialGroupName" :value="item.materialGroupId"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="adminId" label="上传人">
                    <el-select v-model="materialSearchForm.adminId" filterable clearable placeholder="请选择上传人" style="width: 100%;">
                      <el-option v-for="(item, index) in userList" :key="index" :label="item.label" :value="item.key"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="width" label="素材宽度">
                    <el-input v-model="materialSearchForm.width" placeholder="请输入素材宽度"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="height" label="素材高度">
                    <el-input v-model="materialSearchForm.height" placeholder="请输入素材高度"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="keyword" label="关键字">
                    <el-input v-model="materialSearchForm.keyword" placeholder="请输入素材关键字"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item prop="date" label="创建时间">
                    <el-date-picker v-model="materialSearchForm.date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-button type="primary" style="width: 70px;" @click="onClickSearch">查询</el-button>
                  <el-button type="plain" style="width: 70px;" @click="onClickReset">重置</el-button>
                </el-col>
              </el-row>
            </template>
          </el-form> -->
        </div>

        <!-- 点击按钮 -->
        <div class="flex-btn">
          <div v-show="imageType === '3'" class="left">
            <el-dropdown :disabled="!selectedData.length" split-button type="primary">
              批量操作
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="onClickBatchMove()">批量移动</el-dropdown-item>
                <el-dropdown-item @click.native="onClickBatchCopy()">批量复制</el-dropdown-item>
                <el-dropdown-item @click.native="onClickBatchDel()">批量删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="right">
            <el-button type="primary" style="margin-right: 10px;" @click="onClickAddMaterial(0)">新增素材</el-button>
            <el-button v-show="imageType === '1'" type="primary" @click="onClickAddAlbum(0, 0)">新增专辑</el-button>
            <el-button v-show="imageType === '2'" type="primary"
              @click="onClickAddMaterialGroupAlbumR()">新增素材组</el-button>
          </div>
        </div>
        <!-- 数据列表 -->
        <!-- 专辑 -->
        <div v-show="imageType === '1'">
          <div class="album">
            <el-row :gutter="20">
              <el-col v-for="(item, index) in albumList" :key="index" :xl="4" :sm="6" :md="6" :xs="7">
                <div class="album-item" @click="onCickAlbum(item.albumId)">
                  <div class="album-item-image">
                    <img
                      :src="item.urls[0] || 'https://cw-oss.mokamrp.com/ad/2021/10/22/f73d496e-5744-4ea6-b7fa-ab78b8b6b126.jpeg'"
                      alt="" />
                  </div>
                  <div class="album-item-bottom-box">
                    <div class="album-item-middle">
                      <div class="album-item-middle-left">
                        <span>{{ item.materialGroupNum }}</span>
                        <span>素材组</span>
                      </div>
                      <div class="album-item-middle-center"></div>
                      <div class="album-item-middle-right">
                        <span>{{ item.imageMaterialNum }}</span>
                        <span>素材</span>
                      </div>
                    </div>
                    <div class="album-item-bottom">
                      <div class="album-item-bottom-text">{{ item.albumName }}</div>
                      <div class="album-item-bottom-btn">
                        <el-popover popper-class="popper" placement="top" width="80" trigger="hover">
                          <div class="popover-content">
                            <div class="popover-btn" @click="onClickAddAlbum(item.albumId, item.projectId)">编辑</div>
                            <div class="popover-btn" @click="onClickDel(item.albumId)">删除</div>
                          </div>
                          <div slot="reference" class="opt-icon-container">
                            <div class="dian"></div>
                            <div class="dian"></div>
                            <div class="dian"></div>
                          </div>
                        </el-popover>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <!-- 素材组 -->
        <div v-show="imageType === '2'">
          <div class="material-group">
            <el-row :gutter="20">
              <el-col v-for="(item, index) in materialGroupList" :key="index" :xl="4" :sm="6" :md="6" :xs="7">
                <div class="material-group-item" @click="onClickMaterialGroup(item.albumId, item.materialGroupId)">
                  <!-- 一张图情况 -->
                  <div v-if="item.urls.length === 1" class="material-group-item-image image-1">
                    <img
                      :src="item.urls[0] || 'https://cw-oss.mokamrp.com/ad/2021/10/22/f73d496e-5744-4ea6-b7fa-ab78b8b6b126.jpeg'"
                      alt="" />
                  </div>
                  <!-- 两张图情况 -->
                  <!-- <div v-else-if="item.urls.length === 2" class="material-group-item-image image-2">
                    <div class="col mr-10">
                      <img :src="item.urls[0] || 'https://cw-oss.mokamrp.com/ad/2021/10/22/f73d496e-5744-4ea6-b7fa-ab78b8b6b126.jpeg'" alt="" />
                    </div>
                    <div class="col">
                      <img :src="item.urls[1] || 'https://cw-oss.mokamrp.com/ad/2021/10/22/f73d496e-5744-4ea6-b7fa-ab78b8b6b126.jpeg'" alt="" />
                    </div>
                  </div> -->
                  <!-- 三张图情况 -->
                  <!-- <div v-else-if="item.urls.length === 3" class="material-group-item-image image-3">
                    <div class="col mr-10">
                      <img :src="item.urls[0] || 'https://cw-oss.mokamrp.com/ad/2021/10/22/f73d496e-5744-4ea6-b7fa-ab78b8b6b126.jpeg'" alt="" />
                    </div>
                    <div class="col mr-10">
                      <img :src="item.urls[1] || 'https://cw-oss.mokamrp.com/ad/2021/10/22/f73d496e-5744-4ea6-b7fa-ab78b8b6b126.jpeg'" alt="" />
                    </div>
                    <div class="col">
                      <img :src="item.urls[2] || 'https://cw-oss.mokamrp.com/ad/2021/10/22/f73d496e-5744-4ea6-b7fa-ab78b8b6b126.jpeg'" alt="" />
                    </div>
                  </div> -->

                  <!-- 四张图情况 -->
                  <el-row v-else-if="item.urls.length > 1" class="material-group-item-image image-4">
                    <el-col :span="12" class="col " style=" margin-bottom: 5px">
                      <img
                        :src="item.urls[0] || 'https://cw-oss.mokamrp.com/ad/2021/10/22/f73d496e-5744-4ea6-b7fa-ab78b8b6b126.jpeg'"
                        alt="" />
                    </el-col>
                    <el-col :span="12" class="col" style="padding-left: 5px; margin-bottom: 5px">
                      <img
                        :src="item.urls[1] || 'https://cw-oss.mokamrp.com/ad/2021/10/22/f73d496e-5744-4ea6-b7fa-ab78b8b6b126.jpeg'"
                        alt="" />
                    </el-col>
                    <el-col :span="12" class="col ">
                      <img
                        :src="item.urls[2] || 'https://cw-oss.mokamrp.com/ad/2021/10/22/f73d496e-5744-4ea6-b7fa-ab78b8b6b126.jpeg'"
                        alt="" />
                    </el-col>
                    <el-col :span="12" class="col" style="padding-left: 5px">
                      <img
                        :src="item.urls[3] || 'https://cw-oss.mokamrp.com/ad/2021/10/22/f73d496e-5744-4ea6-b7fa-ab78b8b6b126.jpeg'"
                        alt="" />
                    </el-col>
                  </el-row>
                  <!-- 无图情况 -->
                  <div v-else class="material-group-item-image image-1">
                    <img src="https://cw-oss.mokamrp.com/ad/2021/10/22/f73d496e-5744-4ea6-b7fa-ab78b8b6b126.jpeg"
                      alt="" />
                  </div>
                  <div class="material-group-item-bottom">
                    <div class="material-group-item-bottom-text">{{ item.materialGroupName }}</div>
                    <div class="material-group-item-bottom-btn">
                      <el-popover popper-class="popper" placement="top" width="80" trigger="hover">
                        <div class="popover-content">
                          <div class="popover-btn" @click="onClickAddMaterialGroup(item.materialGroupId)">编辑</div>
                          <div class="popover-btn" @click="onClickDel(item.materialGroupId)">删除</div>
                        </div>
                        <div slot="reference" class="opt-icon-container">
                          <div class="dian"></div>
                          <div class="dian"></div>
                          <div class="dian"></div>
                        </div>
                      </el-popover>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
        <!-- 素材 -->
        <div v-show="imageType === '3'">
          <el-row class="material" :gutter="20">
            <el-col v-for="(item, index) in materialList" :key="index" :xl="4" :sm="6" :md="6" :xs="7">
              <div :class="['material-item', item.isSelected ? 'is-active' : '']" @click="onClickSelectCell(index)">
                <div class="material-item-image">
                  <img :src="item.url" alt="" />
                  <div class="size">{{ item.width }}*{{ item.height }}</div>
                </div>
                <div class="material-item-bottom">
                  <div class="material-item-bottom-text">{{ item.materialName }}</div>
                  <div class="material-item-bottom-btn">
                    <el-popover popper-class="popper" placement="top" width="80" trigger="hover">
                      <div class="popover-content">
                        <div class="popover-btn" @click="onClickMaterialInfo(item.imageId)">详情</div>
                        <div class="popover-btn" @click="onClickCopy(item.imageId)">复制</div>
                        <div class="popover-btn" @click="onClickMove(item.imageId)">移动</div>
                        <div class="popover-btn" @click="onClickDel(item.imageId)">删除</div>
                      </div>
                      <div slot="reference" class="opt-icon-container">
                        <div class="dian"></div>
                        <div class="dian"></div>
                        <div class="dian"></div>
                      </div>
                    </el-popover>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="pagination-container">
          <c-pagination :page-size.sync="pageSize" :page-no.sync="pageNum" :total="total" :get-data-fun="getPageData" />
        </div>
        <!-- 编辑/新增 专辑弹窗 -->
        <edit-album-dialog ref="editAlbumDialog" :type="'image'" @edit="_refreshListAndTree"></edit-album-dialog>
        <!-- 编辑/新增 专辑素材组弹窗 -->
        <edit-material-group-dialog ref="editMaterialGroupDialog" :type="'image'"
          @edit="refreshMarterialGroupList"></edit-material-group-dialog>
        <!-- 新增素材弹窗 -->
        <add-material-dialog ref="addMaterialDialog" :type="'image'" @edit="_getImageMarterialList"></add-material-dialog>
        <!-- 素材弹窗详情 -->
        <material-detail-dialog ref="materialDetailDialog" :type="'image'"
          @edit="_getImageMarterialList"></material-detail-dialog>
        <!-- 批量移动弹窗 -->
        <batch-move-material-dialog ref="batchMoveMaterialDialog" :type="'image'"
          @edit="_getImageMarterialList"></batch-move-material-dialog>
        <!-- 批量复制弹窗 -->
        <batch-copy-material-dialog ref="batchCopyMaterialDialog" :type="'image'"
          @edit="_getImageMarterialList"></batch-copy-material-dialog>
      </el-main>
    </el-container>

  </div>
</template>

<script>
import { getImgStructure, getAlbumList, getMarterialGroupList, getImageMarterialList, delMarterialGroup, delImageMarterial, delAlbum, batchDelImageMarterial, getAlbumAllList, getMarterialGroupAllList, getUserAlbum } from '@/network/api/api-material'
import { getUser } from '@/network/api/user'
import { getMeRelationProject } from '@/network/api/basicSetting/project/basicSettingProject'
import { getProductByProject } from '@/network/api/basicSetting/product/basicSettingProduct'
import EditAlbumDialog from '@/components/materialManagement/album/EditAlbumDialog'
import EditMaterialGroupDialog from '@/components/materialManagement/materilaGroup/EditMaterialGroupDialog'
import AddMaterialDialog from '@/components/materialManagement/material/AddMaterialDialog'
import MaterialDetailDialog from '@/components/materialManagement/material/MaterialDetailDialog'
import BatchMoveMaterialDialog from '@/components/materialManagement/material/BatchMoveMaterialDialog'
import BatchCopyMaterialDialog from '@/components/materialManagement/material/BatchCopyMaterialDialog'
import { PageCommonComponent } from '@/common/mixin'
export default {
  name: 'ImageMaterialManagement',
  components: {
    'add-material-dialog': AddMaterialDialog,
    'edit-album-dialog': EditAlbumDialog,
    'edit-material-group-dialog': EditMaterialGroupDialog,
    'material-detail-dialog': MaterialDetailDialog,
    'batch-move-material-dialog': BatchMoveMaterialDialog,
    'batch-copy-material-dialog': BatchCopyMaterialDialog
  },
  mixins: [PageCommonComponent],
  data() {
    return {

      imageTypeTree: '1', // 专辑树
      currentAlbumId: null, // tree当前选择的专辑id
      currentMaterialGroupId: null, // tree当前选择的组id
      loading: false,
      imageType: '1', // 图片素材分类  1-专辑 2-素材组 3-素材
      projectList: [], // 项目列表
      productList: [], // 产品列表
      tableData: [],
      searchDate: '',
      pageNum: 1,
      pageSize: 10,
      total: 0,
      userList: [], // 成员列表
      albumList: [], // 专辑列表
      imgStructure: [], // 左侧tree数据
      allAlbumList: [], // 全部专辑列表
      materialGroupList: [], // 素材组列表
      allMaterialGroupList: [], // 全部素材组列表
      selectedData: [], // 选中的素材
      materialList: [], // 素材列表
      userAllAlbumList: [], // 用户关联项目下的所有专辑
      albumSearchForm: {
        albumName: '',
        date: ''
      }, // 专辑查询条件
      materialSearchForm: {
        date: '',
        materialName: '',
        albumId: '',
        materialGroupId: '',
        keyword: '',
        height: null,
        width: null,
        adminId: ''
      }, // 素材查询条件
      input3: null, // tree 搜索
      selectedTree: '1',
      defaultProps: {
        children: 'adMaterialGroup',
        label: 'label'
      },
      materialGroupSearchForm: {
        materialGroupName: '',
        date: '',
        albumId: ''
      } // 素材组查询条件
    }
  },
  computed: {},
  watch: {},
  created() { },
  mounted() {
    this._getProject()
    this._getUserList()
    this._getAlbumAllList()
    this._getAlbumList()
    this.getImgStructureData()
  },
  activated() { },
  methods: {
    /* -----修改-----------*/
    albumCheckTree(data) {
      this.currentAlbumId = data.albumId
      if (data.level === 1) {
        this.currentMaterialGroupId = null
        this.onCickAlbumTreeClick(data.albumId)
      }
      if (data.level === 2) {
        this.currentMaterialGroupId = data.materialGroupId
        this.onClickMaterialGroupTree(data.albumId, data.materialGroupId)
      }
    },
    iconMore(data) {
      this.currentAlbumId = data.albumId
      if (data.level === 1) {
        this.onClickAddMaterialGroupAlbum(0, data.albumId)
      } else if (data.level === 2) {
        this.onClickAddMaterialTree(data.albumId, data.materialGroupId)
      }
    },
    /* --------------------------- Actions --------------------------- */
    // 点击选择类型
    onClickSelectType() {
      this.pageNum = 1
      this.onClickReset()
    },
    // 删除专辑
    deleteAlbum(id) {
      this._delAlbum(id)
    },
    //
    onClickDelTree(id) {
      this._delMarterialGroup(id)
    },
    // 删除
    onClickDel(id) {
      switch (this.imageType) {
        case '1':
          this._delAlbum(id)
          break
        case '2':
          this._delMarterialGroup(id)
          break
        case '3':
          this._delImageMarterial(id)
          break
        default:
          break
      }
    },
    // 重置
    onClickReset() {
      this.pageNum = 1
      switch (this.imageType) {
        case '1':
          this.albumSearchForm = {
            albumName: '',
            date: ''
          }
          this._getAlbumList()
          break
        case '2':
          this.materialGroupSearchForm = {
            materialGroupName: '',
            date: '',
            albumId: ''
          }
          this._getMarterialGroupList()
          break
        case '3':
          this.materialSearchForm = {
            height: null,
            width: null,
            keyword: '',
            date: '',
            materialName: '',
            albumId: '',
            materialGroupId: '',
            adminId: ''
          }
          this._getImageMarterialList()
          break
        default:
          break
      }
    },

    // 查询
    onClickSearchTreeClick() {
      switch (this.imageType) {
        case '1':
          // this.albumSearchForm.albumName = this.input3
          this._getAlbumList()
          break
        case '2':
          this.materialSearchForm.albumId = this.currentAlbumId
          this.materialSearchForm.materialGroupId = this.currentcurrentMaterialGroupIdAlbumId
          // this.materialGroupSearchForm.materialGroupName = this.input3
          this._getMarterialGroupList()
          break
        case '3':
          //  this.materialSearchForm.materialName = this.input3
          this._getImageMarterialList()
          break
        default:
          break
      }
    },
    // 全部专辑事件
    onClickSearchAll() {
      this.input3 = null
      this.imageType = '1'
      this.onClickSearchInput()
    },
    onClickSearchInput() {
      this.currentAlbumId = null
      this.imageType = this.imageTypeTree
      switch (this.imageType) {
        case '1':
          this.albumSearchForm.albumName = this.input3
          this._getAlbumList()
          this.getImgStructureData()
          break
        case '2':
          this.materialSearchForm.albumId = null
          this.materialGroupSearchForm.materialGroupName = this.input3
          this._getMarterialGroupList()
          this.getImgStructureData()
          break
        case '3':
          this.materialSearchForm.albumId = null
          this.materialSearchForm.materialGroupId = null
          this.materialSearchForm.materialName = this.input3
          this._getImageMarterialList()
          this.getImgStructureData()
          break
        default:
          break
      }
    },
    // 查询
    onClickSearch() {
      switch (this.imageType) {
        case '1':
          this._getAlbumList()
          break
        case '2':
          this._getMarterialGroupList()
          break
        case '3':
          this._getImageMarterialList()
          break
        default:
          break
      }
    },
    // 批量移动
    onClickBatchMove() {
      let ids = this.selectedData.map((item) => {
        return item.imageId
      })
      this.$refs.batchMoveMaterialDialog.showModal(ids)
    },
    // 批量复制
    onClickBatchCopy() {
      let ids = this.selectedData.map((item) => {
        return item.imageId
      })
      this.$refs.batchCopyMaterialDialog.showModal(ids)
    },
    // 批量删除
    onClickBatchDel() {
      let ids = this.selectedData.map((item) => {
        return item.imageId
      })
      this._batchDelImageMarterial(ids)
    },
    // 点击选择或取消选择一条图片素材
    onClickSelectCell(index) {
      let item = this.materialList[index]
      item.isSelected = !item.isSelected
      if (item.isSelected) {
        // 添加到已选择的素材列表中
        this.selectedData.push(item)
      } else {
        // 找到对应索引，从已选择的素材列表中删除
        let index = this.selectedData.indexOf(item)
        this.selectedData.splice(index, 1)
      }
    },
    // 修改页数
    getPageData() {
      switch (this.imageType) {
        case '1':
          this._getAlbumList()
          break
        case '2':
          this._getMarterialGroupList()
          break
        case '3':
          this._getImageMarterialList()
          break
        default:
          break
      }
    },
    // 选择专辑之后
    handlegroup(e) {
      if (e !== '') {
        this.materialSearchForm.materialGroupId = ''
        this._getMarterialGroupAllList(e)
      }
    },
    /* --------------------------- 专辑 ----------------------------- */
    // 点击新增/编辑专辑
    onClickAddAlbum(id, projectId) {
      this.$refs.editAlbumDialog.showModal(id, projectId)
    },
    // 点击专辑跳转到素材组
    onCickAlbum(albumId) {
      this.imageType = '2'
      this.materialGroupSearchForm.albumId = albumId
      this.onClickSearch()
    },
    // tree型点击事件
    onCickAlbumTreeClick(albumId) {
      this.imageType = '2'
      this.materialGroupSearchForm.albumId = albumId
      this.onClickSearchTreeClick()
    },
    /* --------------------------- 素材组 --------------------------- */
    // 点击编辑/新增素材组
    onClickAddMaterialGroup(id) {
      this.$refs.editMaterialGroupDialog.showModal(id)
    },
    // Tree新增素材组
    onClickAddMaterialGroupAlbum(id, albumId) {
      this.$refs.editMaterialGroupDialog.showModalalbum(id, albumId)
    },
    // Tree新增素材组
    onClickAddMaterialGroupAlbumR() {
      this.$refs.editMaterialGroupDialog.showModalalbum(0, this.currentAlbumId)
    },
    // 点击素材组跳转素材
    onClickMaterialGroup(albumId, groupId) {
      this._getMarterialGroupAllList(albumId)
      this.imageType = '3'
      this.materialSearchForm.albumId = albumId
      this.materialSearchForm.materialGroupId = groupId
      this.onClickSearch()
    },
    onClickMaterialGroupTree(albumId, groupId) {
      this._getMarterialGroupAllList(albumId)
      this.imageType = '3'
      this.materialSearchForm.albumId = albumId
      this.materialSearchForm.materialGroupId = groupId
      this.onClickSearchTreeClick()
    },
    /* --------------------------- 素材 ----------------------------- */
    // 点击编辑/新增素材
    onClickAddMaterial() {
      if (this.currentAlbumId) {
        this.$refs.addMaterialDialog.showModalTree(this.currentAlbumId, this.currentMaterialGroupId)
      } else {
        this.$refs.addMaterialDialog.showModal()
      }
    },
    onClickAddMaterialTree(albumId, groupId) {
      this.$refs.addMaterialDialog.showModalTree(albumId, groupId)
    },
    // 素材的详细信息
    onClickMaterialInfo(id) {
      this.$refs.materialDetailDialog.showModal('image', id)
    },
    // 素材移动
    onClickMove(id) {
      let ids = []
      ids.push(id)
      this.$refs.batchMoveMaterialDialog.showModal(ids)
    },
    // 素材复制
    onClickCopy(id) {
      let ids = []
      ids.push(id)
      this.$refs.batchCopyMaterialDialog.showModal(ids)
    },
    /* --------------------------- Private -------------------------- */
    // 获取成员列表
    _getUserList() {
      getUser().then((res) => {
        if (res.code === 200) {
          this.userList = res.data
        }
      })
    },
    // 获取专辑列表
    _getAlbumAllList() {
      let params = {
        type: 'image'
      }
      getAlbumAllList(params).then((res) => {
        this.allAlbumList = res.data
      })
    },
    // 获取素材组列表
    _getMarterialGroupAllList(albumId) {
      let params = {
        type: 'image',
        albumId: albumId
      }
      getMarterialGroupAllList(params).then((res) => {
        this.allMaterialGroupList = res.data
      })
    },
    // // 点击素材组列表
    // _getMarterialGroupAllListClick(albumId, materialGroupId) {
    //   let params = {
    //     type: 'image',
    //     materialGroupId: materialGroupId,
    //     albumId: albumId
    //   }
    //   getMarterialGroupAllList(params).then((res) => {
    //     this.allMaterialGroupList = res.data
    //   })
    // },
    // 获取项目列表(个人可见)
    async _getProject() {
      await getMeRelationProject().then((res) => {
        if (res.code === 200) {
          this.projectList = res.data
          let ids = res.data.map((item) => {
            return item.bsProjectId
          })
          this._getUserAlbum(ids)
        }
      })
    },
    // 获取产品列表(个人可见)
    _getProduct(val) {
      getProductByProject(val).then((res) => {
        if (res.code === 200) {
          this.productList = res.data
        }
      })
    },
    // 获取当前用户所有的专辑列表(个人可见)
    _getUserAlbum(ids) {
      getUserAlbum({ ids: ids }).then((res) => {
        if (res.code === 200) {
          this.userAllAlbumList = res.data
        }
      })
    },
    async _refreshListAndTree() {
      this.getImgStructureData()
      this._getAlbumList()
    },
    // 获取专辑列表
    async _getAlbumList() {
      let params = {
        type: 'image'
      }
      if (this.projectList.length === 0) {
        await this._getProject()
      }
      let projectIds = this.projectList.map((item) => {
        return item.bsProjectId
      })
      params.projectIds = projectIds
      if (this.albumSearchForm.albumName) {
        params.albumName = this.albumSearchForm.albumName
      }
      if (this.albumSearchForm.date) {
        params.startTime = `${this.albumSearchForm.date[0]} 00:00:00`
        params.endTime = `${this.albumSearchForm.date[1]} 23:59:59`
      }
      getAlbumList(this.pageNum, this.pageSize, params)
        .then((res) => {
          this.albumList = res.data.list
          this.total = res.data.total
          // this.getImgStructure()
        })
        .catch((err) => {
          this.$ErrorMessage(err.data || '获取专辑列表失败')
        })
    },

    async getImgStructureData() {
      let params = {
        type: 'image'
      }
      params.level = this.imageType
      if (this.projectList.length === 0) {
        await this._getProject()
      }
      let projectIds = this.projectList.map((item) => {
        return item.bsProjectId
      })
      params.projectIds = projectIds
      if (this.input3) {
        params.albumName = this.input3
      }
      if (this.albumSearchForm.date) {
        params.startTime = `${this.albumSearchForm.date[0]} 00:00:00`
        params.endTime = `${this.albumSearchForm.date[1]} 23:59:59`
      }
      getImgStructure(this.pageNum, this.pageSize, params)
        .then((res) => {
          this.imgStructure = res.data
        })
        .catch((err) => {
          this.$ErrorMessage(err.data || '获取专辑列表失败')
        })
    },

    // 刷新素材组
    refreshMarterialGroupList() {
      this._getMarterialGroupList()
      this.getImgStructureData()
    },
    // 获取素材组列表
    _getMarterialGroupList() {
      let params = {
        type: 'image'
      }
      if (this.materialGroupSearchForm.materialGroupName) {
        params.materialGroupName = this.materialGroupSearchForm.materialGroupName
      }
      if (this.materialGroupSearchForm.date) {
        params.startTime = `${this.materialGroupSearchForm.date[0]} 00:00:00`
        params.endTime = `${this.materialGroupSearchForm.date[1]} 23:59:59`
      }
      if (this.materialGroupSearchForm.albumId) {
        params.albumId = this.materialGroupSearchForm.albumId
      } else {
        let albumIds = this.userAllAlbumList.map((item) => {
          return item.albumId
        })
        params.albumIds = albumIds
      }
      getMarterialGroupList(this.pageNum, this.pageSize, params)
        .then((res) => {
          this.materialGroupList = res.data.list
          this.total = res.data.total
        })
        .catch((err) => {
          this.$ErrorMessage(err.data || '获取素材组列表失败')
        })
    },
    // 获取素材列表
    _getImageMarterialList() {
      let params = {
        height: this.materialSearchForm.height,
        width: this.materialSearchForm.width
      }
      if (this.materialSearchForm.materialName) {
        params.materialName = this.materialSearchForm.materialName
      }
      if (this.materialSearchForm.date) {
        params.startTime = `${this.materialSearchForm.date[0]} 00:00:00`
        params.endTime = `${this.materialSearchForm.date[1]} 23:59:59`
      }
      if (this.materialSearchForm.adminId) {
        params.adminId = this.materialSearchForm.adminId
      }
      if (this.materialSearchForm.albumId) {
        params.albumId = this.materialSearchForm.albumId
      } else {
        let albumIds = this.userAllAlbumList.map((item) => {
          return item.albumId
        })
        params.albumIds = albumIds
      }
      if (this.materialSearchForm.materialGroupId) {
        params.materialGroupId = this.materialSearchForm.materialGroupId
      }
      if (this.materialSearchForm.keyword) {
        params.keyword = this.materialSearchForm.keyword
      }
      getImageMarterialList(this.pageNum, this.pageSize, params)
        .then((res) => {
          this.materialList = res.data.list.map((item) => {
            item.isSelected = false
            return item
          })
          this.total = res.data.total
          this.selectedData = []
        })
        .catch((err) => {
          this.$ErrorMessage(err.data || '获取素材列表失败')
        })
    },
    // 删除专辑
    _delAlbum(id) {
      this.$confirm('此操作将永久删除该专辑，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delAlbum(id)
          .then((res) => {
            // this.$SuccessMessage('删除专辑成功')
            this._getAlbumList()
            this.getImgStructureData()
          })
          .catch((err) => {
            this.$ErrorMessage(err.data || '删除专辑失败')
          })
      })
    },
    // 删除素材组
    _delMarterialGroup(id) {
      this.$confirm('此操作将永久删除该素材组，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delMarterialGroup(id)
          .then((res) => {
            // this.$SuccessMessage('删除素材组成功')
            this._getMarterialGroupList()
            this.getImgStructureData()
          })
          .catch((err) => {
            this.$ErrorMessage(err.data || '删除素材组失败')
          })
      })
    },
    // 删除素材
    _delImageMarterial(id) {
      this.$confirm('此操作将永久删除该素材，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delImageMarterial(id)
          .then((res) => {
            // this.$SuccessMessage('删除素材成功')
            this._getImageMarterialList()
          })
          .catch((err) => {
            this.$ErrorMessage(err.data || '删除素材失败')
          })
      })
    },
    // 批量删除素材
    _batchDelImageMarterial(ids) {
      this.$confirm('此操作将批量删除素材，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        batchDelImageMarterial({ ids: ids })
          .then((res) => {
            // this.$SuccessMessage('批量删除素材成功')
            this._getImageMarterialList()
          })
          .catch((err) => {
            this.$ErrorMessage(err.data || '删除素材失败')
          })
      })
    }
  }
}
</script>
<style lang="scss">
.popper {
  width: 100px !important;
  padding: 0;
  min-width: 100px !important;

  .popover-content {
    .popover-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 36px;
      cursor: pointer;

      &:hover {
        background: #f7f5ff;
        color: #409eff;
      }
    }
  }
}
</style>
<style scoped lang="scss">
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.table-template {
  .search-form-container {
    margin-top: 0px;
  }

  .flex-btn {
    margin-top: 10px;
    display: flex;
    flex-direction: row-reverse;
    // justify-content: flex-start;
    // align-items: center;
  }

  .album {
    color: #595959;
    margin-top: 20px;

    // display: flex;
    // flex-wrap: wrap;
    .album-item {
      // background-color: #f8f5f5;
      box-shadow: 0 1px 5px 0 rgb(0 0 0 / 10%);
      margin-bottom: 20px;
      height: 290px;
      border: 3px solid transparent;
      border-radius: 6px;

      &:hover {
        box-shadow: 2px 2px 5px 2px rgb(228, 227, 227);
        // transform: scale(1.05);
      }

      .album-item-image {
        width: 100%;
        height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          width: 95%;
          height: 90%;
          border-radius: 6px;
        }
      }

      .album-item-middle {
        font-size: 13px;
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: -8px;
        border-bottom: 1px solid #ccc;

        .album-item-middle-left {
          width: 50%;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }

        .album-item-middle-center {
          width: 1px;
          height: 30px;
          background: rgb(221, 221, 221);
        }

        .album-item-middle-right {
          width: 50%;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }
      }

      .album-item-bottom-box {
        background-color: #fff;
        font-size: 14px;
        padding: 0 10px;

        .album-item-bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 11px;

          .album-item-bottom-text {
            font-size: 13px;
            width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: rgb(59, 59, 59);
          }

          .album-item-bottom-btn {
            .opt-icon-container {
              display: flex;

              .dian {
                width: 4px;
                height: 4px;
                background: rgb(59, 59, 59);
                border-radius: 50%;
                margin-right: 3px;
              }
            }
          }
        }
      }
    }
  }

  .material-group {
    color: #555a6f;
    font-size: 16px;
    margin-top: 20px;

    .material-group-item {
      background-color: #fff;
      border: 3px solid transparent;
      box-shadow: 0 1px 5px 0 rgb(0 0 0 / 10%);
      margin-bottom: 20px;
      height: 280px;
      border-radius: 6px;

      &:hover {
        box-shadow: 2px 2px 5px 2px #ccc;
        // transform: scale(1.05);
      }

      .material-group-item-image {
        box-sizing: border-box;
        height: 230px;
        padding: 6px 6px 0px 6px;
      }

      .image-1 {
        height: 230px;
        width: 100%;

        img {
          width: 100%;
          height: 223px;
          border-radius: 6px;
        }
      }

      .image-2 {
        display: flex;
        height: 230px;
        width: 100%;

        img {
          width: 100%;
          height: 223px;
          border-radius: 6px;
        }
      }

      .image-3 {
        display: flex;
        height: 230px;
        width: 100%;

        .col {
          overflow: hidden;
          border-radius: 6px;

          img {
            width: auto;
            height: 100%;
          }
        }
      }

      .image-4 {
        width: 100%;
        height: 230px;
        display: flex;
        flex-wrap: wrap;

        .col {
          overflow: hidden;
          height: 108px;
          border-radius: 6px;

          img {
            border-radius: 6px;
            width: auto;
            height: 100%;
          }
        }
      }
    }

    .material-group-item-bottom {
      display: flex;
      height: 50px;
      font-size: 14px;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;

      .material-group-item-bottom-text {
        width: 150px;
        color: rgb(59, 59, 59);
        font-size: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .material-group-item-bottom-btn {
        .opt-icon-container {
          display: flex;

          .dian {
            width: 4px;
            height: 4px;
            background: rgb(59, 59, 59);
            border-radius: 50%;
            margin-right: 3px;
          }
        }
      }
    }
  }

  .material {
    color: #555a6f;
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .is-active {
      border: 3px solid #409eff !important;
    }

    .material-item {
      background-color: #fff;
      border: 3px solid transparent;
      box-shadow: 0 1px 5px 0 rgb(0 0 0 / 10%);
      margin-bottom: 20px;
      padding: 0px 6px 0px 6px;
      border-radius: 6px;

      &:hover {
        box-shadow: 2px 2px 5px 2px #ccc;
      }

      .material-item-image {
        position: relative;
        height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 95%;
          border-radius: 6px;
        }

        .size {
          font-size: 13px;
          background-color: #000;
          opacity: 0.5;
          color: #fff;
          padding: 2px 4px;
          position: absolute;
          bottom: 5px;
          right: 0;
        }
      }

      .material-item-bottom {
        display: flex;
        height: 40px;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;

        .material-item-bottom-text {
          width: 150px;
          font-size: 13px;
          color: rgb(59, 59, 59);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .material-item-bottom-btn {
          .opt-icon-container {
            display: flex;

            .dian {
              width: 4px;
              height: 4px;
              background: rgb(59, 59, 59);
              border-radius: 50%;
              margin-right: 3px;
            }
          }
        }
      }
    }
  }
}
</style>
