var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "section" },
    [
      _c(
        "base-box",
        { attrs: { name: "img" } },
        [
          _c(
            "template",
            { slot: "body" },
            [
              _c(
                "el-form",
                {
                  ref: "imgForm",
                  staticClass: "img-form-box",
                  attrs: {
                    model: _vm.detail,
                    size: "mini",
                    inline: true,
                    rules: _vm.rules,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "上传图片", prop: "pureImageUrl" } },
                    [
                      _c("div", { staticClass: "image-updata" }, [
                        _c(
                          "div",
                          [
                            _c(
                              "el-upload",
                              {
                                staticClass: "avatar-uploader",
                                attrs: {
                                  action: "fakeaction",
                                  "show-file-list": false,
                                  accept: ".png,.jpg,.jpeg,.gif",
                                  "before-upload": _vm.beforeAvatarUpload,
                                  "http-request": _vm.handleUpload,
                                },
                              },
                              [
                                _vm.detail.pureImageUrl
                                  ? [
                                      _c("img", {
                                        staticClass: "avatar",
                                        staticStyle: {
                                          border: "1px solid #ccc",
                                          "border-radius": "4px",
                                        },
                                        attrs: { src: _vm.detail.pureImageUrl },
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "uploader-actions" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "upload_action_delete",
                                              on: {
                                                click: _vm.handleRemoveImage,
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-delete",
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "upload_action_delete",
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-edit",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _c("i", {
                                      staticClass:
                                        "el-icon-plus avatar-uploader-icon",
                                    }),
                              ],
                              2
                            ),
                            _c("p", { staticClass: "tip mt-10" }, [
                              _vm._v(" 图片大小：不超过500KB "),
                              _c("br"),
                              _vm._v(
                                " 图片尺寸：宽度750像素，高度不超过1536像素 "
                              ),
                              _c("br"),
                              _vm._v(" 图片格式：png、jpg、jpeg、gif "),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "上边距", prop: "paddingTop" } },
                    [
                      _c("el-slider", {
                        attrs: { "show-input": "", max: 100 },
                        model: {
                          value: _vm.detail.paddingTop,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "paddingTop", $$v)
                          },
                          expression: "detail.paddingTop",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "下边距", prop: "paddingBottom" } },
                    [
                      _c("el-slider", {
                        attrs: { "show-input": "", max: 100 },
                        model: {
                          value: _vm.detail.paddingBottom,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "paddingBottom", $$v)
                          },
                          expression: "detail.paddingBottom",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "点击跳转", prop: "clickJump" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { size: "mini" },
                          model: {
                            value: _vm.detail.clickJump,
                            callback: function ($$v) {
                              _vm.$set(_vm.detail, "clickJump", $$v)
                            },
                            expression: "detail.clickJump",
                          },
                        },
                        [
                          _c("el-option", { attrs: { label: "是", value: 1 } }),
                          _c("el-option", { attrs: { label: "否", value: 2 } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.detail.clickJump == 1
                    ? [
                        _c(
                          "el-form-item",
                          { attrs: { label: "链接类型", prop: "linkType" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  size: "mini",
                                  placeholder: "请选择链接类型",
                                },
                                model: {
                                  value: _vm.detail.linkType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.detail, "linkType", $$v)
                                  },
                                  expression: "detail.linkType",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "普通链接", value: 1 },
                                }),
                                _c("el-option", {
                                  attrs: { label: "获客助手链接", value: 2 },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "跳转链接", prop: "link" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                size: "mini",
                                type: "textarea",
                                rows: 3,
                                resize: "none",
                                placeholder: "请输入跳转链接",
                                maxlength: "500",
                                "show-word-limit": "",
                              },
                              model: {
                                value: _vm.detail.link,
                                callback: function ($$v) {
                                  _vm.$set(_vm.detail, "link", $$v)
                                },
                                expression: "detail.link",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.detail.linkType == 1
                          ? [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "平台", prop: "platformId" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: "请选择平台",
                                        size: "mini",
                                        filterable: "",
                                      },
                                      model: {
                                        value: _vm.detail.platformId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.detail,
                                            "platformId",
                                            $$v
                                          )
                                        },
                                        expression: "detail.platformId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.platforms,
                                      function (item, index) {
                                        return _c("el-option", {
                                          key: index,
                                          attrs: {
                                            label: item.dictLabel,
                                            value: item.dictValue,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "关联内容",
                                    prop: "relationContent",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      size: "mini",
                                      placeholder: "请输入投放平台相关ID",
                                      maxlength: "30",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.detail.relationContent,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.detail,
                                          "relationContent",
                                          $$v
                                        )
                                      },
                                      expression: "detail.relationContent",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }