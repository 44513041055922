/*
 * @Description: Do not edit
 * @Date: 2021-11-01 15:13:00
 * @LastEditTime: 2021-11-01 15:49:23
 */
import service from '@/network/service/service'

export function EventPage(data) {
  return service.post('/api/system/assetManagementHugeCreatedEvent/page', data)
}

export function Add(data) {
  return service.post('/api/system/assetManagementHugeCreatedEvent/save', data)
}

export function Update(data) {
  return service.post('/api/system/assetManagementHugeCreatedEvent/update', data)
}

export function Delete(id) {
  return service.delete('/api/system/assetManagementHugeCreatedEvent/' + id)
}
