<!--
 * @Description: 工作区 -> 跳转小程序组件
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2024-03-25 09:08:29
-->

<template>
  <section>
    <div
      :style="{
        paddingLeft: '0px',
        paddingRight: '0px',
        paddingTop: `${detail.paddingTop}px`,
        paddingBottom: `${detail.paddingBottom}px`,
        boxSizing: 'border-box'
      }"
    >
      <div
        class="button"
        :style="{
          paddingLeft: '10px',
          paddingRight: '10px',
          width: detail.btnSize == 'large' ? '100%' : '50%',
          height: detail.btnHeight + 'px',
          background: detail.btnBgColorTheme,
          color: detail.btnFontColor,
          fontSize: detail.btnFontSize + 'px',
          border: `1px solid ${detail.btnBorderColorTheme}`,
          fontWeight: detail.btnFontType,
          textAlign: detail.textAlignment
        }"
      >
        <p v-html="detail.btnTitle"></p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.button {
  border-radius: 23px;
  line-height: 42px;
  text-align: center;
  font-size: 20px;
  height: 46px;
  margin: auto;
  box-sizing: border-box;
}
.copyGh {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: #fff;
  display: flex;
  flex-direction: column;
  i {
    font-size: 60px;
    color: #ccc;
  }
  p {
    margin-top: 10px;
    font-size: 24px;
    color: #ccc;
  }
}
</style>
