var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "a-menu",
        {
          staticClass: "m-a-menu",
          attrs: { mode: "horizontal" },
          model: {
            value: _vm.current,
            callback: function ($$v) {
              _vm.current = $$v
            },
            expression: "current",
          },
        },
        [
          _c(
            "a-menu-item",
            { key: "/home" },
            [
              _c(
                "router-link",
                {
                  staticClass: "submenu-title",
                  attrs: { to: "/home", tag: "span" },
                },
                [
                  _c("i", {
                    class: `iconfont icon-home`,
                    staticStyle: { color: "#000000a6" },
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "title",
                      on: {
                        click: function ($event) {
                          return _vm.titleClick("home")
                        },
                      },
                    },
                    [_vm._v("首页")]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._l(_vm.menu, function (itemOne, index) {
            return _c(
              "a-sub-menu",
              { key: index },
              [
                _c(
                  "span",
                  {
                    staticClass: "submenu-title-wrapper submenu-title",
                    attrs: { slot: "title" },
                    slot: "title",
                  },
                  [
                    _c("i", {
                      class: `iconfont ${itemOne.remarks}`,
                      staticStyle: { color: "#000000a6" },
                    }),
                    _c("span", { staticClass: "title" }, [
                      _vm._v(_vm._s(itemOne.menuName)),
                    ]),
                  ]
                ),
                _vm._l(itemOne.children, function (itemTwo, tindex) {
                  return _c(
                    "a-menu-item-group",
                    { key: tindex, attrs: { title: itemTwo.menuName } },
                    _vm._l(itemTwo.children, function (itemThree) {
                      return _c(
                        "a-menu-item",
                        { key: itemThree.url },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: itemThree.url, tag: "div" } },
                            [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.titleClick(itemThree)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(itemThree.menuName))]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  )
                }),
              ],
              2
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }