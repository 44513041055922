<template>
  <div>
    <Search ref="search" />
    <el-row type="flex" justify="space-between" class="operate-box">
      <el-col v-if="visibelOpt" :span="10">
        <el-button type="primary" round @click="showOpt">批量操作 <i class="el-icon-right" /></el-button>
      </el-col>
      <el-col v-if="!visibelOpt" :span="10">
        <el-button type="primary" icon="el-icon-edit" :disabled="disabledTF" round @click="showOpts('type')">修改品类</el-button>
        <el-button type="primary" icon="el-icon-edit" :disabled="sourceDisabled" round @click="showOpts('source')">修改来源</el-button>
        <el-button type="primary" icon="el-icon-edit" :disabled="disabledTF" round @click="showOpts('project')">修改项目</el-button>
        <el-button type="success" icon="el-icon-check" :disabled="disabledTF" round @click="showOpts('start')">批量启用</el-button>
        <el-button type="danger" icon="el-icon-close" :disabled="disabledTF" round @click="showOpts('end')">批量不启用</el-button>
        <el-button type="info" icon="el-icon-back" round @click="showOpt">隐藏</el-button>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" icon="el-icon-plus" round @click="showAdd">新 增</el-button>
      </el-col>
    </el-row>
    <div class="table-container">
      <el-table v-loading="loading" stripe class="list-table" :data="data" @selection-change="handleSelectionChange">
        >
        <c-data-empty slot="empty" />
        <el-table-column type="selection" />
        <el-table-column type="index" width="50" label="序号" align="left" />
        <el-table-column prop="productName" label="产品名称" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="productTypeName" label="产品品类名称" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="productSourceName" label="产品来源名称" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="relationProjectName" label="关联项目名称" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="isUseing" label="是否启用" align="left">
          <template slot-scope="scope">
            <div v-if="scope.row.isUseing === 'ISUSEING'" slot="reference" class="name-wrapper">
              <el-alert title="启用" type="success" center show-icon :closable="false" />
            </div>
            <div v-if="scope.row.isUseing === 'ISNOTUSEING'" slot="reference" class="name-wrapper">
              <el-alert title="不启用" type="error" center show-icon :closable="false" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="createBy" label="创建者" align="left" />
        <el-table-column prop="createTime" label="创建时间" width="150" align="left" />
        <el-table-column prop="updateBy" label="更新者" align="left" />
        <el-table-column prop="updateTime" label="更新时间" width="150" align="left" show-overflow-tooltip />
        <el-table-column label="操作" width="100">
          <template slot-scope="{ row }">
            <el-button type="text" size="small" @click="showEdit(row)">
              <el-tooltip content="编辑" placement="top">
                <i class="el-icon-edit" style="font-size: 16px"></i>
              </el-tooltip>
            </el-button>
            <el-button type="text" size="small" @click="deleteRow(row)">
              <el-tooltip content="删除" placement="top">
                <i class="el-icon-delete" style="font-size: 16px; color: red"></i>
              </el-tooltip>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <c-pagination :page-size.sync="pageInfo.pageSize" :page-no.sync="pageInfo.pageNum" :total="pageInfo.total" :get-data-fun="loadData" />
    <OptionDialog ref="dialog" />
    <ProjectOptionDialog ref="proj" />
    <ProductTypeDialog ref="typelog" />
    <ProductSourceDialog ref="sourcelog" />
    <OptOptionDialog ref="optlog" />
  </div>
</template>
<script>
import { PageCommonComponent } from '@/common/mixin'
import { Page, Delete } from '@/network/api/basicSetting/product/basicSettingProduct'
import Search from './Search'
import OptionDialog from './OptionDialog'
import OptOptionDialog from './OptOptionDialog'
import ProjectOptionDialog from '../../project/OptionDialog'
import { BasicSettingProjectEnt } from '../../project/object'
import ProductTypeDialog from '../type/OptionDialog'
import { BasicSettingProductTypeEnt } from '../type/object'
import ProductSourceDialog from '../source/OptionDialog'
import { BasicSettingProductSourceEnt } from '../source/object'
import { BasicSettingProductEnt } from './object'
import { updateBatch } from '@/network/api/basicSetting/product/basicSettingProduct'
export default {
  name: 'BasicSettingProduct',
  components: { Search, OptionDialog, ProjectOptionDialog, ProductTypeDialog, ProductSourceDialog, OptOptionDialog },
  mixins: [PageCommonComponent],
  provide: function() {
    return {
      loadData: this.loadData,
      showEdit: this.showEdit,
      showAddProject: this.showAddProject,
      showAddType: this.showAddType,
      showAddSource: this.showAddSource
    }
  },
  data() {
    return {
      disabledTF: true,
      sourceDisabled: true,
      visibelOpt: true,
      loading: false,
      data: [],
      multipleSelection: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val
      if (this.multipleSelection === undefined || this.multipleSelection === null || this.multipleSelection.length === 0) {
        this.disabledTF = true
      } else {
        this.disabledTF = false
      }
      let status = '0'
      let projectId = ''
      this.multipleSelection.forEach((w) => {
        if (projectId === '') {
          projectId = w.relationProjectId
        } else {
          if (projectId !== w.relationProjectId && status === '0') {
            status = '1'
          }
        }
      })
      if (!this.disabledTF) {
        this.sourceDisabled = false
      } else {
        this.sourceDisabled = true
      }
    },
    showOpt() {
      this.visibelOpt = !this.visibelOpt
    },
    loadData(isFirst = false) {
      const searchForm = this.$refs.search.getSearchForm()
      this.doLoadData(isFirst, searchForm)
    },
    doLoadData(firstPage = false, searchForm) {
      firstPage && (this.pageInfo.pageNum = 1)
      const req = { ...searchForm, ...this.pageInfo }
      this.loading = true
      Page(req).then((res) => {
        this.data = res.data.records
        this.pageInfo.total = res.data.total
        this.loading = false
      })
    },
    deleteRow(row) {
      this.$confirm('确定删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          Delete(row.bsProductId).then((res) => {
            if (res.code === 200) {
              this.$message({
                message: '删除成功',
                type: 'success'
              })
              this.loadData()
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showEdit(row) {
      this.$refs.dialog.show(true, { ...row })
    },
    showAdd() {
      this.$refs.dialog.show(false, new BasicSettingProductEnt())
    },
    showAddProject() {
      this.$refs.proj.show(false, new BasicSettingProjectEnt())
    },
    showAddType() {
      this.$refs.typelog.show(false, new BasicSettingProductTypeEnt())
    },
    showAddSource() {
      this.$refs.sourcelog.show(false, new BasicSettingProductSourceEnt())
    },
    showOpts(type) {
      let status = '0'

      let projectId = ''
      const array = []
      this.multipleSelection.forEach((w) => {
        array.push(w.bsProductId)
        if (projectId === '') {
          projectId = w.relationProjectId
        } else {
          if (projectId !== w.relationProjectId && status === '0') {
            status = '1'
          }
        }
      })
      if (array.length === 0) {
        this.$message({
          type: 'error',
          message: '请选择'
        })
        return
      }
      if (type === 'source') {
        if (array.length === 1 || (array.length > 1 && status === '0')) {
          this.$refs.optlog.show(type, array, projectId)
        } else {
          this.$message({
            type: 'error',
            message: '请选择关联项目相同的数据'
          })
        }
      }
      if (type === 'type' || type === 'project') {
        this.$refs.optlog.show(type, array, undefined)
      }

      if (type === 'start' || type === 'end') {
        updateBatch({ ids: array.toString(), type: type }).then((res) => {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          this.loadData()
        })
      }
    }
  }
}
</script>

<style scoped>
.el-alert--success.is-light {
  background-color: #f0f9eb;
  color: #67c23a;
  padding: 0;
}
.el-alert--error.is-light {
  background-color: #fef0f0;
  color: #f56c6c;
  padding: 0;
}
</style>
