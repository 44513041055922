<!--
 * @Description: 创意基础信息
 * @Date: 2021-12-06 15:57:16
 * @LastEditTime: 2022-05-19 16:43:05
-->
<template>
  <div>
    <div class="creativity-material">
      <div v-if="creativityMaterialList.flag">
        <div v-if="crtSizeLabel === '朋友圈'" class="kong">
          {{ '原生落地页顶部组件获取' }}
        </div>
        <div v-else>
          <div v-for="item in creativityMaterialList.list" :key="item.groupName" class="justify-sb align-center">
            <div class="img-list">
              <p style="font-size: 12px;">{{ item.groupName }}:</p>
              <div class="img-box">
                <div v-for="image in item.adMpManagementTaskImgVideoENTList" :key="image.creativeGroup">
                  <video v-if="image.localAssetUrl && image.localAssetUrl.indexOf('.mp4') > -1 && image.localAssetUrl" :src="image.localAssetUrl"></video>
                  <img v-else :src="image.localAssetUrl" class="avatar" />
                </div>
              </div>
            </div>
            <i class="el-icon-circle-close close-icon" @click="deleteMaterialList(item.adMpManagementTaskImgVideoENTList[0].imgVideoId)"></i>
          </div>
        </div>
      </div>
      <div v-else class="kong">
        {{ '请选择创意素材' }}
      </div>

      <el-dialog title="创意素材" :visible="visible" width="800px" :modal-append-to-body="false" destroy-on-close :before-close="handleClose">
        <span>
          <div class="justify-sb align-center pt-10 pb-10">
            <div>
              <span style="font-weight: bold">创意内容</span>
              <span class="ml-20">创意组数量：{{ creativityMaterialList.list.length }}组</span>
            </div>
            <el-button type="primary" size="small" @click="isBatchFun">批量添加</el-button>
          </div>
          <el-collapse v-if="creativityMaterialList.list.length" v-model="collapse">
            <el-collapse-item v-for="(item, index) in creativityMaterialList.list" :key="item.groupName" :name="item.groupName">
              <template slot="title">
                <div class="justify-sb group-title">
                  <p>{{ item.groupName }}</p>
                  <div class="icon">
                    <i class="el-icon-document-copy" @click.stop="copyCreativeGroup(index)"></i>
                    <i v-if="creativityMaterialList.list.length > 1" class="el-icon-close" @click.stop="deleteCreativeGroup(index)"></i>
                  </div>
                </div>
              </template>

              <div v-if="['展示Banner图片', '优雅横版大图', '公众号文章底部图片', '公众号文章中部', '小程序banner广告'].includes(crtSizeLabel)" class="img-box">
                <p>图片:</p>
                <div class="mr-10">
                  <div class="avatar-uploader" @click="setMaterialBox(index)">
                    <img v-if="item.adMpManagementTaskImgVideoENTList[0].localAssetUrl" :src="item.adMpManagementTaskImgVideoENTList[0].localAssetUrl" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon" />
                  </div>
                </div>
                <div class="info">
                  <p>尺寸：{{ creativityMaterialInfoData.width }}px * {{ creativityMaterialInfoData.height }}px</p>
                  <p>格式：JPG、JPEG、PNG格式</p>
                  <p>大小：{{ creativityMaterialInfoData.size / 1024 }} kb 以内</p>
                </div>
              </div>
              <!-- 视频 -->
              <div v-else class="justify-sb">
                <div class="img-box">
                  <p>视频:</p>
                  <div class="mr-10">
                    <div class="avatar-uploader" @click="setMaterialVideoBox(index)">
                      <video v-if="item.adMpManagementTaskImgVideoENTList[0].localAssetUrl" :src="item.adMpManagementTaskImgVideoENTList[0].localAssetUrl" class="avatar" />
                      <i v-else class="el-icon-plus avatar-uploader-icon" />
                    </div>
                  </div>
                  <div class="info">
                    <p>尺寸：{{ creativityMaterialInfoData.width }}px * {{ creativityMaterialInfoData.height }}px</p>
                    <p>格式：mp4格式</p>
                    <p>大小：100MB 以内</p>
                    <p>播放时长: 6s - 30s</p>
                  </div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
          <div style="text-align:center; margin-top: 10px">
            <el-button
              @click="
                () => {
                  addCreativeGroup()
                }
              "
            >添加创意组</el-button>
          </div>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" :disabled="!isSearchLock" @click="onSubmit">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <div v-if="visible">
      <material-box
        :visible="materialBoxVisible"
        :img-count="1"
        name="creativeGroup"
        select-all
        :image-list="[]"
        :is-batch="isBatch"
        select-type="multiple"
        :attribute="{
          key: 'creativeGroup',
          value: '创意素材',
          count: 1,
          size: creativityMaterialInfoData.size,
          listType: 'image/jpg,image/jpeg,image/png',
          width: creativityMaterialInfoData.width,
          height: creativityMaterialInfoData.height
        }"
        :project-id="[+projectId]"
        @setMaterialBox="materialBoxVisible = false"
        @submit="selectImg"
      />

      <MaterialVideoBox
        :visible="materialVideoVisible"
        :img-count="1"
        name="videoUrl"
        select-all
        :is-batch="isBatch"
        select-type="multiple"
        :video-listed="[]"
        :attribute="{
          key: 'videoHorizontal',
          value: '横版视频',
          count: 1,
          size: creativityMaterialInfoData.size,
          listType: 'video/mp4',
          width: creativityMaterialInfoData.width,
          height: creativityMaterialInfoData.height
        }"
        :project-id="[+projectId]"
        @setMaterialBox="materialVideoVisible = false"
        @handleVideoSuccess="selectVideo"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { saveImgVideo, updateImgVideo, deleteImgVideo } from '@/network/api/advManagement/advManagementWatch'
import MaterialBox from '@/components/materialBox'
import MaterialVideoBox from '@/components/materialBox/videoV2.vue'
import { creativityMaterialInfo } from '../util'
// import _ from 'lodash'
export default {
  components: {
    MaterialBox,
    MaterialVideoBox
  },

  inject: ['closeMaterial'],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    /** 广告位置 */
    crtSizeLabel: {
      type: String,
      default: ''
    },
    creativityData: {
      type: Object,
      default: () => {}
    },
    formData: {
      type: Object,
      default: () => {}
    },
    creativityMaterialDataTemplate: {
      type: Array,
      default: () => []
    },
    promotedObjectType: {
      type: String,
      default: ''
    },
    taskId: {
      type: Number,
      default: 0
    },
    projectId: {
      type: String,
      default: ''
    },
    accountId: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isBatch: false, // 是否点击批量添加
      materialBoxVisible: false, // 图片选择框Visible
      materialVideoVisible: false, // 视频选择框Visible
      creativityMaterialInfo,
      isSearchLock: true,
      creativityMaterialInfoData: {
        width: 960,
        height: 540,
        size: 80 * 1024,
        listType: 'image/jpg,image/jpeg,image/png'
      },
      setIndex: 0,
      creativityMaterialList: {},
      collapse: ['创意组01', '创意组02', '创意组03']
    }
  },

  computed: {
    ...mapGetters(['userInfo'])
  },

  watch: {
    formData: {
      handler(value) {
        console.log(this.formData)
        this.creativityMaterialList = value
      },
      immediate: true
    },

    crtSizeLabel: {
      handler(value) {
        if (value !== '朋友圈') {
          //          PRODUCTTYPE_WECHAT_SHOP: '推广商品',
          // PRODUCTTYPE_LEAD_AD: '收集销售线索',
          // PRODUCTTYPE_WECHAT: '推广公众号'
          // 图片尺寸 (推广公众号下的小程序banner广告 与 推广商品下的小程序banner广告)
          if (this.promotedObjectType === 'PRODUCTTYPE_WECHAT' && value === '小程序banner广告') {
            this.creativityMaterialInfoData = {
              width: 640,
              height: 316,
              size: 100 * 1024,
              listType: 'image/jpg,image/jpeg,image/png'
            }
          } else if (this.promotedObjectType === 'PRODUCTTYPE_LEAD_AD' && value === '展示Banner图片') {
            this.creativityMaterialInfoData = {
              width: 960,
              height: 334,
              size: 100 * 1024,
              listType: 'image/jpg,image/jpeg,image/png'
            }
          } else {
            this.creativityMaterialInfoData = creativityMaterialInfo[value]
          }
        }
        let creativityMaterialList = this.creativityMaterialList
        if (!['展示Banner图片', '优雅横版大图', '公众号文章底部图片', '公众号文章中部', '小程序banner广告'].includes(value)) {
          if (creativityMaterialList.list[0].adMpManagementTaskImgVideoENTList.length === 1) {
            // 视频添加封面
            creativityMaterialList.list[0].adMpManagementTaskImgVideoENTList[1] = {
              imgVideoId: null,
              localAssetId: null,
              localAssetUrl: null,
              localAssetMd: null,
              type: 'img',
              taskId: this.taskId
            }
          }
          this.creativityMaterialList = creativityMaterialList
        } else {
          if (creativityMaterialList.list[0].adMpManagementTaskImgVideoENTList.length === 2) {
            creativityMaterialList.list[0].adMpManagementTaskImgVideoENTList.splice(1, 1)
            this.creativityMaterialList = creativityMaterialList
          }
        }
      },
      immediate: true
    }
  },

  methods: {
    isBatchFun() {
      this.isBatch = true
      if (['展示Banner图片', '优雅横版大图', '公众号文章底部图片', '公众号文章中部', '小程序banner广告'].includes(this.crtSizeLabel)) {
        this.materialBoxVisible = true
      } else {
        this.materialVideoVisible = true
      }
    },

    /**
     * @description 打开图片素材选择框
     */
    setMaterialBox(index = 0) {
      this.setIndex = index
      this.materialBoxVisible = true
    },

    /**
     * @description 打开视频素材选择框
     */
    setMaterialVideoBox(index = 0) {
      this.setIndex = index
      this.materialVideoVisible = true
    },

    /**
     * @description 添加创素材组
     */
    addCreativeGroup(info, num) {
      // 验证上一个是否添加图片
      if (this.validateImage()) return
      let len = 0
      let index = 0
      if (info) {
        this.creativityMaterialList.list = this.creativityMaterialList.list.filter((f) => f.adMpManagementTaskImgVideoENTList[0].localAssetUrl)
        if (this.creativityMaterialList.list.length > 0) {
          len = this.creativityMaterialList.list.length
          index = +this.creativityMaterialList.list[len - 1].groupName.substring(3)
        } else {
          index = 0
        }
      } else {
        len = this.creativityMaterialList.list.length
        index = +this.creativityMaterialList.list[len - 1].groupName.substring(3)
      }
      let list = JSON.parse(JSON.stringify(this.creativityMaterialDataTemplate))

      list.forEach((ele) => {
        ele.creativeGroup = `创意组0${index + 1}`
        if (info) {
          ele.localAssetId = info.imageId || info.videoId || null
          ele.localAssetUrl = info.image || info.url || null
          ele.localAssetMd = info.hash || null
        }
      })

      if (['展示Banner图片', '优雅横版大图', '公众号文章底部图片', '公众号文章中部', '小程序banner广告'].includes(this.crtSizeLabel)) {
        this.creativityMaterialList.list.push({
          groupName: `创意组0${index + 1}`,
          taskId: this.taskId,
          type: 'img',
          adMpManagementTaskImgVideoENTList: list
        })
      } else {
        // 视频添加封面
        list[0].type = 'video'
        list[1] = {
          imgVideoId: null,
          localAssetId: null,
          localAssetUrl: null,
          localAssetMd: null,
          type: 'imgs',
          taskId: this.taskId
        }
        this.creativityMaterialList.list.push({
          groupName: `创意组0${index + 1}`,
          taskId: this.taskId,
          type: 'video',
          adMpManagementTaskImgVideoENTList: list
        })
      }

      if (info) {
        this.creativityMaterialList.list = this.creativityMaterialList.list.filter((f) => f.adMpManagementTaskImgVideoENTList[0].localAssetUrl)
      }
    },

    /**
     * @description 删除创素材组
     */
    async deleteCreativeGroup(index) {
      let item = this.creativityMaterialList.list[index]
      this.creativityMaterialList.list.splice(index, 1)
      if (item.adMpManagementTaskImgVideoENTList[0].imgVideoId) {
        const { code } = await deleteImgVideo(item.adMpManagementTaskImgVideoENTList[0].imgVideoId)
        if (code === 200) {
          this.$message.success('删除成功!')
        }
      }
    },

    /**
     * @description 复制创素材组
     */
    copyCreativeGroup(index) {
      if (this.validateImage()) return
      let len = this.creativityMaterialList.list.length
      let num = +this.creativityMaterialList.list[len - 1].groupName.substring(3)
      this.creativityMaterialList.list.push({
        groupName: `创意组0${num + 1}`,
        taskId: this.taskId,
        type: ['展示Banner图片', '优雅横版大图', '公众号文章底部图片', '公众号文章中部', '小程序banner广告'].includes(this.crtSizeLabel) ? 'img' : 'video',
        adMpManagementTaskImgVideoENTList: JSON.parse(JSON.stringify(this.creativityMaterialList.list[index].adMpManagementTaskImgVideoENTList)).map((item) => {
          item.creativeGroup = `创意组0${num + 1}`
          item.imgVideoId = null
          return item
        })
      })
    },

    /**
     * @description 选择图片后赋值图片信息
     */
    selectImg(value) {
      if (this.isBatch) {
        value.map((item, index) => {
          this.addCreativeGroup(item, index)
        })
      } else {
        if (['展示Banner图片', '优雅横版大图', '公众号文章底部图片', '公众号文章中部', '小程序banner广告'].includes(this.crtSizeLabel)) {
          this.creativityMaterialList.list[this.setIndex].adMpManagementTaskImgVideoENTList[0].localAssetId = value[0].imageId
          this.creativityMaterialList.list[this.setIndex].adMpManagementTaskImgVideoENTList[0].localAssetUrl = value[0].image
          this.creativityMaterialList.list[this.setIndex].adMpManagementTaskImgVideoENTList[0].localAssetMd = value[0].hash
        } else {
          this.creativityMaterialList.list[this.setIndex].adMpManagementTaskImgVideoENTList[1].localAssetId = value[0].imageId
          this.creativityMaterialList.list[this.setIndex].adMpManagementTaskImgVideoENTList[1].localAssetUrl = value[0].image
          this.creativityMaterialList.list[this.setIndex].adMpManagementTaskImgVideoENTList[1].localAssetMd = value[0].hash
        }
      }
      this.isBatch = false
    },

    // 上传视频成功后,归类
    selectVideo(videos) {
      if (this.isBatch) {
        videos.map((item, index) => {
          this.addCreativeGroup(item, index)
        })
      } else {
        this.creativityMaterialList.list[this.setIndex].adMpManagementTaskImgVideoENTList[0].localAssetId = videos[0].videoId
        this.creativityMaterialList.list[this.setIndex].adMpManagementTaskImgVideoENTList[0].localAssetUrl = videos[0].videoUrl
      }

      this.isBatch = false
    },

    /**
     * @description 创意素材保存
     */
    async onSubmit() {
      if (this.validateImage()) return
      this.isSearchLock = false

      let createParams = []
      let updateParams = []
      this.creativityMaterialList.list.map((item) => {
        if (item.adMpManagementTaskImgVideoENTList[0].imgVideoId) {
          let obj = Object.assign({}, item.adMpManagementTaskImgVideoENTList[0], { taskId: this.taskId })
          updateParams.push(obj)
        } else {
          createParams.push({
            taskId: this.taskId,
            ...item
          })
        }
      })

      if (createParams.length) {
        const { code } = await saveImgVideo({ groupAndAssets: createParams })
        if (code === 200) {
          this.isSearchLock = true
          this.$message.success('素材保存成功!')
          this.closeMaterial()
        }
      }

      if (updateParams.length) {
        const { code } = await updateImgVideo({ adMpManagementTaskImgVideoENT: updateParams })
        if (code === 200) {
          this.isSearchLock = true
          this.$message.success('素材修改成功!')
          this.closeMaterial()
        }
      }
      this.$emit('byTaskIdGetAllFun')
    },

    validateImage() {
      if (this.isBatch) return false
      if (['展示Banner图片', '优雅横版大图', '公众号文章底部图片', '公众号文章中部', '小程序banner广告'].includes(this.crtSizeLabel)) {
        if (this.creativityMaterialList.list[this.creativityMaterialList.list.length - 1].adMpManagementTaskImgVideoENTList[0].localAssetId === null) {
          this.$message.error('请先选择图片!')
          return true
        }
      } else {
        if (this.creativityMaterialList.list[this.creativityMaterialList.list.length - 1].adMpManagementTaskImgVideoENTList[0].localAssetId === null) {
          this.$message.error('请先选择视频!')
          return true
        }
        // if (this.creativityMaterialList.list[this.creativityMaterialList.list.length - 1].adMpManagementTaskImgVideoENTList[1].localAssetId === null) {
        //   this.$message.error('请先选择封面!')
        //   return true
        // }
      }
      return false
    },

    handleClose() {
      this.closeMaterial()
      this.$emit('byTaskIdGetAllFun')
    },

    async deleteMaterialList(imgVideoId) {
      const { code } = await deleteImgVideo([imgVideoId])
      if (code === 200) {
        this.$emit('byTaskIdGetAllFun')
        this.$message.success('删除成功!')
      }
    }
  }
}
</script>

<style lang="scss">
.creativity-material {
  overflow-y: auto;
  .img-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
    p {
      max-width: 100px;
      max-height: 60px;
      overflow: hidden;
    }
    .img-box {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      img {
        width: 80px;
        margin-left: 5px;
      }
      video {
        width: 80px;
        margin-left: 5px;
      }
    }
  }
  .close-icon {
    margin-bottom: 10px;
    cursor: pointer;
    &:hover {
      font-weight: 800;
      font-size: 15px;
    }
  }
  p {
    margin: 0;
    padding: 0;
  }
  height: 300px;
  .el-dialog__body {
    padding-top: 0px !important;
  }
  .group-title {
    width: 100%;
    .icon {
      i {
        margin-right: 10px;
      }
    }
  }
  .img-box {
    display: flex;
    & > p {
      width: 50px;
      font-size: 14px;
      text-align: right;

      margin-right: 10px;
    }
    .info {
      margin-top: 8px;
      margin-left: 10px;
      p {
        color: #a8a4a4;
        font-size: 12px;
        margin: 0;
        padding: 0;
      }
    }
  }

  .avatar-uploader {
    .el-upload {
      border: 1px solid #edecf2;
      background-color: #fbfafd;
      border-radius: 3px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .el-upload:hover {
      border-color: $themeColor;
      .avatar-uploader-icon {
        color: $themeColor;
      }
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #eae9f0;
      width: 100px;
      height: 100px;
      line-height: 100px;
      text-align: center;
      border: 1px solid #f2f2f2;
      border-radius: 4px;
    }
    .avatar {
      width: 100px;
      height: 100px;
      display: block;
    }
    .el-upload__tip {
      color: #a8acb4;
      font-size: 12px;
      margin-top: -17px;
    }
  }
  .el-collapse-item__header {
    font-size: 16px;
    font-weight: bold;
  }
}
.el-tabs .el-tabs__item.is-active {
  border-top: solid 0px #409eff;
}
</style>
