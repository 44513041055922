<!--
 * @Descripttion: 二次封装支持模糊音搜索el-select--全局组件
 * @Date: 2021-04-21 17:09:08
-->
<template>
  <el-select :value="value" v-bind="$attrs" :loading="loading" :remote-method="remoteMethod" filterable clearable remote reserve-keyword v-on="$listeners" @clear="clearSelect">
    <el-option v-for="item in optionData" :key="item[props.value]" v-bind="$attrs" :label="item[props.label]" :value="item[props.value]"> </el-option>
  </el-select>
</template>

<script>
import { debounce } from '@/utils/ruoyi'
import service from '@/network/service/service'
import _ from 'lodash'
export default {
  name: 'SearchSelectAsync',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    // 需要绑定的值 等于 v-model
    value: {
      type: [String, Number],
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    // api必传其一
    api: {
      type: String,
      default: '',
      required: true
    },
    // api存在 params必传
    params: {
      type: Array,
      default() {
        return []
      },
      required: true
    },
    query: {
      type: Object,
      default() {
        return {}
      }
    },
    // 投放账户 默认选项
    optionsData: {
      type: Array,
      default() {
        return []
      },
      required: true
    },
    // el-option参数 必传
    props: {
      type: Object,
      default() {
        return {
          value: 'value',
          label: 'label'
        }
      },
      required: true
    }
  },
  data() {
    return {
      optionsList: [],
      copyOptionsList: [],
      loading: false
    }
  },

  computed: {
    optionData() {
      return _.uniqBy([...this.optionsData, ...this.optionsList], 'ghid')
    }
  },
  methods: {
    remoteMethod: debounce(function(e) {
      this.handleFilter(e)
    }),
    /**
     * @Description: 下拉框支持模糊搜索
     * @Author: JayShen
     * @param {*} val
     */
    handleFilter(val) {
      this.loading = true
      this.getOptionsList(val)
    },

    async getOptionsList(value) {
      if (!value) return
      let params = {}
      if (this.name === 'ghids') {
        let data = { name: value, ...this.query }
        const res = await service.post(this.api, data)
        if (res.code === 200) {
          res.data.records.forEach((item) => (item.ghid = `${item.id}+${item.ghid}+${item.name}`))
          this.optionsList = res.data.records
          this.loading = false
        }
      } else {
        this.params.map((item) => (params[item] = value))
        const { code, data } = await service.get(this.api, { params, ...this.query })
        if (code === 200) {
          this.optionsList = data
          this.loading = false
        }
      }
    },
    /**
     * @Description: clear、focus事件还原数组
     * @Author: JayShen
     * @param {*}
     */
    clearSelect() {
      this.optionsList = this.copyOptionsList
    }
  }
}
</script>
