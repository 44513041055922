<template>
  <el-row>

    <el-col :span="6">
      <div style="border: 1px solid #d3d9e6">
        <el-alert
          style="height:40px"
          :title="'省市区(县)('+initNum+')'"
          :closable="false"
          type="info"
        />
        <el-input v-model="input5s" style="height:35px" placeholder="请输入内容" class="input-with-select" @focus="focusClicks" @blur="blurClicks">
          <el-button slot="append" icon="el-icon-search" style="height:35px" />
        </el-input>
        <el-tree
          v-if="!tfs"
          class="elTreeL"
          style=" margin-right: 2px;"
          :data="data1s"
          default-expand-all
          @node-click="nodeClicks"
        />
        <el-tree
          v-if="tfs"

          class="elTreeL"
          style=" margin-right: 2px;"
          :data="data3"

          node-key="id"
          :default-expanded-keys="defShows"
          :default-checked-keys="selectedDatas"
          @node-click="checkTrees"
        />
      </div>
    </el-col>

    <el-col :span="12">
      <div style="border: 1px solid #d3d9e6">
        <el-alert
          style="height:40px"
          :title="selectName+ address+'('+data2.length+')'"
          :closable="false"
          type="info"
        />
        <el-input v-model="input5" style="height:35px" placeholder="请输入内容" class="input-with-select" @focus="focusClick" @blur="blurClick">
          <el-button slot="append" icon="el-icon-search" style="height:35px" />
        </el-input>
        <el-tree
          v-if="!tf"
          class="elTreeLefts"
          style=" margin-right: 2px;"
          :data="data1"
          default-expand-all
          @node-click="nodeClick"
        />
        <el-tree
          v-if="tf"
          ref="tree"
          class="elTreeLefts"
          style=" margin-right: 2px;"
          :data="data2"
          show-checkbox
          node-key="id"
          :default-expanded-keys="defShow"
          :default-checked-keys="endCheckRight"
          @check="checkTree"
        />
      </div>
    </el-col>
    <el-col :span="6">
      <div style="border: 1px solid #d3d9e6">
        <el-alert
          style="height:40px"
          :title="'【取消栏】'+'已选中('+endCheckRight.length+')'"
          :closable="false"
          type="info"
        />
        <el-tree
          class="elTreeRights"
          :data="endDataRight"
          show-checkbox
          default-expand-all
          :default-checked-keys="endCheckRight"
          node-key="id"
          :expand-on-click-node="false"
          @check="endCheckTree"
        />
      </div>
    </el-col>
  </el-row>
</template>
<script>
// import { regionArray } from './regionArrays'
import { getBizArea } from '@/network/api/advManagement/advManagementBaiduUnit'
export default {
  components: { },
  props: {
    basicDate: {
      type: Array, // 类型
      default: null // 默认值
    },
    oneComp: {
      type: String, // 类型
      default: undefined // 默认值
    },
    selectName: {
      type: String, // 类型
      default: undefined // 默认值
    },
    echoShowData: {
      type: Array,
      default: () => []
    },
    echoCheckData: {
      type: Array,
      default: () => []
    }
  },
  inject: [],
  data() {
    return {
      address: '',
      value9: undefined,
      initNum: 0,
      selectNum: 0,
      selectedData: [],
      selectedDataRight: [],
      selectedDatas: [],
      allTf: true,
      defShow: ['9999999'],
      defShows: ['9999999'],
      tf: true,
      tfs: true,
      data1: [],
      data1s: [],
      data3: this.basicDate,
      data2: [],
      input5: undefined,
      input5s: undefined,
      halfSelected: [],
      loading: undefined,
      endDataRight: this.echoShowData !== undefined && this.echoShowData instanceof Array && this.echoShowData.length > 0 ? this.echoShowData : [],
      endCheckRight: this.echoCheckData !== undefined && this.echoCheckData instanceof Array && this.echoCheckData.length > 0 ? this.echoCheckData : [],
      collectionData: [],
      collectionCheck: [],
      compara: undefined,
      selectData: []
    }
  },
  computed: {

  },
  watch: {
    // { 'data': this.endDataRight, 'check': this.endCheckRight }
    echoShowData: function(val) {
      if (val instanceof Array && val.length > 0) {
        this.endDataRight = val
      }
    },
    echoCheckData: function(val) {
      if (val instanceof Array && val.length > 0) {
        this.endCheckRight = val
      }
    },
    input5s: function(val) {
      this.data1s = []
      if (val !== undefined && val !== null && val.trim() !== '') {
        this.data1s = this.forData2Bynames(this.data3, val)
      }
    },
    input5: function(val) {
      this.data1 = []
      if (val !== undefined && val !== null && val.trim() !== '') {
        this.data1 = this.forData2Byname(this.data2, val)
      }
    },
    basicDate: function(val) {
      Object.assign(this.$data, this.$options.data())
      this.data3 = val
      this.initNum = this.forData3(this.data3)
    }
  },
  mounted() {
    this.initNum = this.forData3(this.data3)
  },
  methods: {
    initData3(val) {
      val.forEach(x => {
        if (x.label === '中国') {
          var children = x.children
          if (children !== undefined && children instanceof Array && children.length > 0) {
            children.forEach(p => {
              var schildren = p.children
              if (schildren !== undefined && schildren instanceof Array && schildren.length > 0) {
                schildren.forEach(p => {
                  var shchildren = p.children
                  if (shchildren !== undefined && shchildren instanceof Array && shchildren.length > 0) {
                    p.children = []
                  }
                })
              }
            })
          }
          this.data3 = children
        }
      })
    },
    // getdispt() {
    //   this.endCheckRight = new Set(this.endCheckRight)
    //   return this.endCheckRight.length
    // },
    endCheckTree(data1, data2, data3) {
      // var check =data2.checkedKeys
      var checkedKeys = data2.checkedKeys
      var halfCheckedKeys = data2.halfCheckedKeys
      if (halfCheckedKeys !== undefined && halfCheckedKeys.length > 0) {
        halfCheckedKeys.forEach(p => {
          checkedKeys.push(p)
        })
      }
      var checkData = []
      var newData = []
      this.endDataRight.forEach(w => {
        if (checkedKeys.indexOf(w.id) > -1 || checkedKeys.indexOf(w.id + '') > -1) {
          var children = w.children
          if (children !== undefined && children instanceof Array && children.length > 0) {
            var newchildrenData = []
            children.forEach(o => {
              if (checkedKeys.indexOf(o.id) > -1 || checkedKeys.indexOf(o.id + '') > -1) {
                newchildrenData.push({ 'id': o.id, 'label': o.label, 'children': o.children })
                checkData.push(o.id)
              }
            })
            newData.push({ 'id': w.id, 'label': w.label, 'children': newchildrenData })
          }
        }
      })
      this.endDataRight = newData
      this.collectionData = newData
      this.$refs.tree.setCheckedKeys(checkData)
      this.selectData = []
      this.endCheckRight = checkData
      this.collectionCheck = checkData
      var returnData = { 'data': this.endDataRight, 'check': this.endCheckRight }
      this.$emit('getData', returnData)
    },
    nodeClicks(data1, data2, data3) {
      if (!this.tfs) {
        this.input5s = undefined
      }
      this.tfs = !this.tfs
      this.checkTrees(data1, data2)
    },
    nodeClick(data1, data2, data3) {
      console.log(data1, data2, data3)
      if (!this.tf) {
        this.input5 = undefined
      }
      this.tf = !this.tf
      if (data1.id !== undefined) {
        var array = data1.id.split(',')
        if (array.length > 0) {
          var index = array.length - 1
          var halfCheckedKeys = []
          for (var i = 0; i < array.length; i++) {
            if (i !== array.length - 1) {
              halfCheckedKeys.push(array[i])
            }
          }
          this.selectedDataRight.forEach(t => {
            halfCheckedKeys.push(t)
          })
          var checkedKeys = []
          checkedKeys.push(array[index])
          this.selectedData.forEach(e => {
            checkedKeys.push(e)
          })

          this.checkTree({}, { 'halfCheckedKeys': halfCheckedKeys, 'checkedKeys': checkedKeys })
        }
      }
    },
    forData2Bynames(dataArray, all) {
      var returnData = []
      // 省
      dataArray.forEach(item => {
        if (item.label === this.oneComp || this.oneComp === null || this.oneComp === undefined) {
          var children = item.children
          var items = item.label.indexOf(all) !== -1
          if (items) {
            returnData.push({ 'label': item.label, 'id': item.id, 'children': [] })
          }
          // 有子集合
          if (children !== undefined && children instanceof Array && children.length > 0) {
            // 市
            children.forEach(s => {
              // var schildren = s.children
              // 省包含
              var stf = s.label.indexOf(all) !== -1
              if (items) {
                returnData.push({ 'label': item.label + '>' + s.label, 'id': s.id, 'children': [] })
              } else {
                if (stf) {
                  returnData.push({ 'label': item.label + '>' + s.label, 'id': s.id, 'children': [] })
                }
              }

              // 有子集合
              // if (schildren !== undefined && schildren instanceof Array && schildren.length > 0) {
              //   // 遍历市
              //   schildren.forEach(sh => {
              //     var qchildren = sh.children
              //     // 市包含
              //     var shtf = sh.label.indexOf(all) !== -1
              //     if (shtf) {
              //       returnData.push({ 'label': s.label + '>' + sh.label, 'id': startid + ',' + s.id + ',' + sh.id, 'children': [] })
              //     }
              //     if (qchildren !== undefined && qchildren instanceof Array && qchildren.length > 0) {
              //       // 遍历区tt
              //       qchildren.forEach(t => {
              //         if (stf) {
              //           returnData.push({ 'label': s.label + '>' + sh.label + '>' + t.label, 'id': startid + ',' + s.id + ',' + sh.id + ',' + t.id, 'children': [] })
              //         } else {
              //           if (shtf) {
              //             returnData.push({ 'label': s.label + '>' + sh.label + '>' + t.label, 'id': startid + ',' + s.id + ',' + sh.id + ',' + t.id, 'children': [] })
              //           } else {
              //             if (t.label.indexOf(all) !== -1) {
              //               returnData.push({ 'label': s.label + '>' + sh.label + '>' + t.label, 'id': startid + ',' + s.id + ',' + sh.id + ',' + t.id, 'children': [] })
              //             }
              //           }
              //         }
              //       })
              //     }
              //   })
              // }
            })
          }
        }
      })
      return returnData
    },
    forData2Byname(dataArray, all) {
      var returnData = []
      dataArray.forEach(item => {
        if (item.label === this.oneComp || this.oneComp === null || this.oneComp === undefined) {
          var children = item.children
          var startid = item.id
          // 有子集合
          if (children !== undefined && children instanceof Array && children.length > 0) {
            // 遍历省
            children.forEach(s => {
              var schildren = s.children
              // 省包含
              var stf = s.label.indexOf(all) !== -1
              if (stf) {
                returnData.push({ 'label': s.label, 'id': startid + ',' + s.id, 'children': [] })
              }
              // 有子集合
              if (schildren !== undefined && schildren instanceof Array && schildren.length > 0) {
                // 遍历市
                schildren.forEach(sh => {
                  var qchildren = sh.children
                  // 市包含
                  var shtf = sh.label.indexOf(all) !== -1
                  if (shtf) {
                    returnData.push({ 'label': s.label + '>' + sh.label, 'id': startid + ',' + s.id + ',' + sh.id, 'children': [] })
                  }
                  if (qchildren !== undefined && qchildren instanceof Array && qchildren.length > 0) {
                    // 遍历区tt
                    qchildren.forEach(t => {
                      if (stf) {
                        returnData.push({ 'label': s.label + '>' + sh.label + '>' + t.label, 'id': startid + ',' + s.id + ',' + sh.id + ',' + t.id, 'children': [] })
                      } else {
                        if (shtf) {
                          returnData.push({ 'label': s.label + '>' + sh.label + '>' + t.label, 'id': startid + ',' + s.id + ',' + sh.id + ',' + t.id, 'children': [] })
                        } else {
                          if (t.label.indexOf(all) !== -1) {
                            returnData.push({ 'label': s.label + '>' + sh.label + '>' + t.label, 'id': startid + ',' + s.id + ',' + sh.id + ',' + t.id, 'children': [] })
                          }
                        }
                      }
                    })
                  }
                })
              }
            })
          }
        }
      })
      return returnData
    },
    forData2Byname1(dataArray, all) {
      var returnData = []
      dataArray.forEach(item => {
        var children = item.children
        // 有子集合
        if (children !== undefined && children instanceof Array && children.length > 0) {
          var obje1 = { 'label': item.label, 'id': item.id, 'children': [] }
          returnData.push(obje1)
        } else {
          if (item.label.indexOf(all) !== -1) {
            var obje = { 'label': item.label, 'id': item.id, 'children': [] }
            returnData.push(obje)
          }
        }
      })
      return returnData
    },
    blurClick() {
      var val = this.input5
      if (val === undefined || val === null || val.trim() === '') {
        this.input5 = undefined
        this.tf = true
      }
    },
    focusClick() {
      this.tf = false
    },
    focusClicks() {
      this.tfs = false
    },
    blurClicks() {
      var val = this.input5s
      if (val === undefined || val === null || val.trim() === '') {
        this.input5s = undefined
        this.tfs = true
      }
    },
    forData3(dataArray) {
      var returnData = 0
      dataArray.forEach(item => {
        var children = item.children
        // eslint-disable-next-line no-empty
        if (children instanceof Array && children.length > 0) {
          var tr = this.forData3(children)
          returnData = returnData + tr
        } else {
          returnData++
        }
      })
      return returnData
    },
    checkTrees(Currentdata, data) {
      this.address = '-' + Currentdata.label
      if (this.compara === undefined) {
        this.compara = this.address
      }
      this.data2 = []
      getBizArea(Currentdata.id).then(res => {
        if (res.code === 200) {
          this.data2 = res.data
        }
      })
    },
    checkTree(Currentdata, data) {
      //  看看能把自己绕进去吗
      if (this.compara !== this.address) {
        this.selectData.forEach(w => {
          this.collectionData.push(w)
        })
        this.selectData = []
        this.selectedDataRight.forEach(e => {
          this.collectionCheck.push(e)
        })
        this.selectedDataRight = []

        this.compara = this.address
      }
      this.selectNum = 0
      var halfs = data.halfCheckedKeys
      var check = data.checkedKeys
      var all = []
      if (halfs !== undefined && halfs instanceof Array) {
        this.halfSelected = halfs
        halfs.forEach(y => {
          all.push(y)
        })
      }
      if (check !== undefined && check instanceof Array) {
        this.selectedData = check
        this.selectedDataRight = check
        // this.selectedData.forEach(e => {
        //   var tf = this.selectedDataRight.filter(r => {
        //     return e === r
        //   })
        //   if (tf.length === 0) { this.selectedDataRight.push(e) }
        // })
        check.forEach(u => {
          all.push(u)
        })
      }
      if (check.length === 0) {
        this.selectData = []
        this.selectedDataRight = []
      }
      this.endCheckRight = []
      this.selectedDataRight.forEach(w => {
        var tf = this.endCheckRight.filter(r => {
          return w === r
        })
        if (tf.length === 0) {
          this.endCheckRight.push(w)
        }
      })
      this.collectionCheck.forEach(r => {
        var tf = this.endCheckRight.filter(w => {
          return w === r
        })
        if (tf.length === 0) {
          this.endCheckRight.push(r)
        }
      })
      if (all.length > 0) {
        if (this.data2 instanceof Array) {
          this.selectData = this.forData2(this.data2, all)
          if (this.selectNum > 20) {
            this.allTf = false
          }
        }
      }
      this.endDataRight = []
      this.selectData.forEach(w => {
        this.endDataRight.push(w)
      })
      this.collectionData.forEach(r => {
        var tf = this.endDataRight.filter(w => {
          return w.id === r.id
        })
        if (tf.length === 0) {
          this.endDataRight.push(r)
        }
      })

      var returnData = { 'data': this.endDataRight, 'check': this.endCheckRight }
      this.$emit('getData', returnData)
    },
    forData2(dataArray, all) {
      var returnData = []
      dataArray.forEach(item => {
        var id = item.id
        // 选中的包含
        if (all.indexOf(id) > -1 || all.indexOf(id + '') > -1) {
          var children = item.children
          // eslint-disable-next-line no-empty
          var obj = { 'label': item.label, 'id': item.id, 'children': [] }
          if (children instanceof Array && children.length > 0) {
            obj.children = this.forData2(children, all)
          } else {
            this.selectNum++
          }
          returnData.push(obj)
        }
      })
      return returnData
    },

    setCheckedKeys(array) {
      this.$refs.tree.setCheckedKeys(array)
    },
    resetChecked() {
      this.$refs.tree.setCheckedKeys([])
    }
  }
}
</script>
<style>
  .elTreeLefts {
        overflow: auto;
        height: calc( 200px);

    }
  .elTreeRights {
      overflow: auto;
      height: calc( 236px);

  }
  .elTreeL {
      overflow: auto;
      height: calc( 200px);

  }
  .el-alert {
    border-radius: 0px;
  }
  .el-input{
    border-radius: 0px;
  }
</style>
