/*
 * @Description: 基础数据
 * @Date: 2021-10-18 11:53:23
 * @LastEditTime: 2024-03-29 09:33:44
 */
export let widgets = [
  {
    type: '基础组件',
    title: '图片',
    name: 'img'
  },
  {
    type: '基础组件',
    title: '文本',
    name: 'text'
  },
  {
    type: '转化按钮',
    title: '基础按钮',
    name: 'base'
  }
]

export let widgetMapData = {
  img: {
    widgetTypeV2: 'img',
    component: 'm-img',
    name: '图片',
    imageId: '',
    paddingTop: 0,
    paddingBottom: 0,
    pureImageUrl: '',
    height: '',
    width: '',
    clickJump: 2,
    linkType: 2,
    link: '',
    platformId: '',
    relationContent: ''
  },

  text: {
    widgetTypeV2: 'text',
    component: 'm-text',
    name: '文本',
    expand: false,
    fontSize: '14',
    fontColor: '#595959',
    textAlignment: 'left', // 对齐方式
    content: '', // 类容
    showType: 'normal', // '0'常规, '1'加粗
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 24,
    paddingLeft: 24,
    id: ''
  },

  base: {
    widgetTypeV2: 'base',
    component: 'm-base',
    type: 'button',
    name: '基础按钮',
    btnTitle: '基础按钮', // 按钮文案
    btnSize: 'large', // 按钮大小
    btnWidth: 750, // 按钮宽
    btnHeight: 46, // 按钮高
    marginTop: 0,
    btnFontType: 'normal', // 按钮外观 -> 按钮文案 '0'常规, '1'加粗
    btnFontSize: '14',
    textAlignment: 'center', // 对齐方式
    backgroundColor: '#409EFF', // 组件底色
    btnFontColor: '#595959', // 按钮外观 -> 字体色
    btnBorderColorTheme: '#409EFF', // 按钮外观 -> 边框色
    btnBgColorTheme: '#409EFF', // 按钮外观 -> 填充色
    paddingBottom: 0,
    paddingTop: 0,
    btnPosition: 1, // 按钮位置
    linkType: 2,
    link: '',
    platformId: '',
    relationContent: '',
    urlList: []
  }
}
