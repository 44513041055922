var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "search",
          staticClass: "search-form search-form-4",
          attrs: { inline: true, model: _vm.search },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "账号名称", prop: "name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请输入账号名称" },
                        model: {
                          value: _vm.search.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "name", $$v)
                          },
                          expression: "search.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "账号类型",
                        prop: "wechatAccountTypeName",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.search.wechatAccountTypeName,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "wechatAccountTypeName", $$v)
                            },
                            expression: "search.wechatAccountTypeName",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: "" },
                          }),
                          _c("el-option", {
                            attrs: { label: "公众号", value: "公众号" },
                          }),
                          _c("el-option", {
                            attrs: { label: "小程序", value: "小程序" },
                          }),
                          _c("el-option", {
                            attrs: { label: "小游戏", value: "小游戏" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "服务商", prop: "spid" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.search.spid,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "spid", $$v)
                            },
                            expression: "search.spid",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: "null" },
                          }),
                          _vm._l(_vm.adMpAmList, function (itm, idx) {
                            return _c("el-option", {
                              key: idx,
                              attrs: { label: itm.agencyName, value: itm.spid },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "项目", prop: "projectId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择项目" },
                          on: {
                            change: (value) => (_vm.selectProjectId = value),
                          },
                          model: {
                            value: _vm.search.projectId,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "projectId", $$v)
                            },
                            expression: "search.projectId",
                          },
                        },
                        _vm._l(_vm.projectList, function (item) {
                          return _c("el-option", {
                            key: item.bsProjectId,
                            attrs: {
                              label: item.projectName,
                              value: item.bsProjectId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品", prop: "productId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择产品" },
                          on: {
                            change: (value) => (_vm.selectProductId = value),
                          },
                          model: {
                            value: _vm.search.productId,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "productId", $$v)
                            },
                            expression: "search.productId",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "不限", value: 0 },
                          }),
                          _vm._l(_vm.productListed, function (itm, idx) {
                            return _c("el-option", {
                              key: idx,
                              attrs: {
                                label: itm.productName,
                                value: itm.bsProductId,
                              },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "人员", prop: "launch" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            multiple: "",
                            "collapse-tags": "",
                            "value-key": "key",
                            filterable: "",
                            clearable: "",
                            placeholder: "请选择投放人员",
                          },
                          model: {
                            value: _vm.search.launch,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "launch", $$v)
                            },
                            expression: "search.launch",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: -1 },
                          }),
                          _vm._l(_vm.userList, function (itm, idx) {
                            return _c("el-option", {
                              key: idx,
                              attrs: { label: itm.label, value: itm.key },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "投放状态", prop: "putInStatus" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.search.putInStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "putInStatus", $$v)
                            },
                            expression: "search.putInStatus",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: null },
                          }),
                          _c("el-option", {
                            attrs: { label: "投放", value: 1 },
                          }),
                          _c("el-option", {
                            attrs: { label: "停投", value: 0 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", round: "" },
                          on: { click: _vm.getPage },
                        },
                        [_vm._v("查询")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { round: "" },
                          on: {
                            click: function ($event) {
                              return _vm.resetFormQuery("search")
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "justify-sb" },
        [
          _c(
            "el-dropdown",
            { on: { command: _vm.handleCommand } },
            [
              _c("el-button", { attrs: { type: "primary" } }, [
                _vm._v(" 批量操作"),
                _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: {
                        command: "0",
                        disabled:
                          _vm.selectRow.length < 2 ||
                          !_vm.selectRow.every(
                            (item) =>
                              item.projectId === _vm.selectRow[0].projectId
                          ),
                      },
                    },
                    [_vm._v("修改项目")]
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: {
                        command: "1",
                        disabled:
                          _vm.selectRow.length < 2 ||
                          !_vm.selectRow.every(
                            (item) =>
                              item.projectId === _vm.selectRow[0].projectId
                          ),
                      },
                    },
                    [_vm._v("修改产品")]
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: {
                        command: "2",
                        disabled:
                          _vm.selectRow.length < 2 ||
                          !_vm.selectRow.every(
                            (item) =>
                              item.projectId === _vm.selectRow[0].projectId
                          ),
                      },
                    },
                    [_vm._v("修改人员")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", round: "" },
              on: {
                click: function ($event) {
                  _vm.sync_account_visible = true
                },
              },
            },
            [_vm._v(" 同步账号 ")]
          ),
        ],
        1
      ),
      _c("TablePane", {
        staticClass: "mt-20",
        attrs: { "data-source": _vm.dataSource },
        on: { getPage: _vm.getPage },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "同步投放账号",
            visible: _vm.sync_account_visible,
            width: "1120px",
            "modal-append-to-body": false,
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.sync_account_visible = $event
            },
            open: _vm.open,
            close: function ($event) {
              return _vm.resetForm("accountForm")
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "accountForm",
              staticClass: "demo-ruleForm justify-sb",
              attrs: {
                model: _vm.accountForm,
                "label-width": "100px",
                inline: true,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "服务商: ",
                    prop: "spid",
                    rules: {
                      required: true,
                      message: "请选择服务商",
                      trigger: "change",
                    },
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "500px" },
                      model: {
                        value: _vm.accountForm.spid,
                        callback: function ($$v) {
                          _vm.$set(_vm.accountForm, "spid", $$v)
                        },
                        expression: "accountForm.spid",
                      },
                    },
                    _vm._l(_vm.adMpAmList, function (itm, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: {
                          label: `${itm.agencyName} ( ${itm.mobileId} )`,
                          value: `${itm.spid}+${itm.mobileId}`,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        round: "",
                        disabled: _vm.accountForm.spid === null,
                      },
                      on: { click: _vm.getAdMpAmsAccountsPage },
                    },
                    [_vm._v("获取未同步账号")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider", [_vm._v(" 账号选择")]),
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        placeholder: "请输入账号名称/gh_id查询",
                        size: "small",
                      },
                      model: {
                        value: _vm.accountForm.ghid,
                        callback: function ($$v) {
                          _vm.$set(_vm.accountForm, "ghid", $$v)
                        },
                        expression: "accountForm.ghid",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: { click: _vm.getAdMpAmsAccountsPage },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("TablePane", {
                    ref: "multipleTable",
                    staticClass: "mt-10",
                    attrs: { "data-source": _vm.dataSource_sync },
                    on: { getPage: _vm.getAdMpAmsAccountsPage },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.table_right_loading,
                          expression: "table_right_loading",
                        },
                      ],
                      staticClass: "list-table mt-10",
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.dataSource_sync_right },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "账号名称",
                          align: "center",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "10px" } },
                                  [_vm._v(_vm._s(scope.row.name))]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "appid",
                          align: "center",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "10px" } },
                                  [_vm._v(_vm._s(scope.row.appid))]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "ghid",
                          align: "center",
                          "show-overflow-tooltip": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "10px" } },
                                  [_vm._v(_vm._s(scope.row.ghid))]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "danger" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDelete(
                                          scope.$index,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c("el-pagination", {
                    staticStyle: { "text-align": "right" },
                    attrs: {
                      "current-page": _vm.currentPage1,
                      "page-size": 10,
                      layout: "total, prev, pager, next",
                      total: _vm.dataSource_sync_right_all.length,
                    },
                    on: {
                      "update:currentPage": function ($event) {
                        _vm.currentPage1 = $event
                      },
                      "update:current-page": function ($event) {
                        _vm.currentPage1 = $event
                      },
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                [
                  _c("el-divider", [_vm._v("账户配置")]),
                  _c(
                    "el-form",
                    {
                      ref: "accountConfig",
                      staticClass: "demo-ruleForm justify-sb",
                      attrs: { inline: true, model: _vm.accountConfig },
                      nativeOn: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "项目",
                            prop: "projectId",
                            rules: {
                              required: true,
                              message: "请选择项目",
                              trigger: "change",
                            },
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择项目" },
                              on: {
                                change: (value) =>
                                  (_vm.selectProjectId = value),
                              },
                              model: {
                                value: _vm.accountConfig.projectId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.accountConfig, "projectId", $$v)
                                },
                                expression: "accountConfig.projectId",
                              },
                            },
                            _vm._l(_vm.projectList, function (item) {
                              return _c("el-option", {
                                key: item.bsProjectId,
                                attrs: {
                                  label: item.projectName,
                                  value: item.bsProjectId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "产品",
                            prop: "productId",
                            rules: {
                              required: true,
                              message: "请选择产品",
                              trigger: "change",
                            },
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择产品" },
                              on: {
                                change: (value) =>
                                  (_vm.selectProductId = value),
                              },
                              model: {
                                value: _vm.accountConfig.productId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.accountConfig, "productId", $$v)
                                },
                                expression: "accountConfig.productId",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "不限", value: 0 },
                              }),
                              _vm._l(_vm.productListed, function (itm, idx) {
                                return _c("el-option", {
                                  key: idx,
                                  attrs: {
                                    label: itm.productName,
                                    value: itm.bsProductId,
                                  },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "人员",
                            prop: "launch",
                            rules: {
                              required: true,
                              message: "请选择人员",
                              trigger: "blue",
                            },
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                "value-key": "key",
                                filterable: "",
                                clearable: "",
                                placeholder: "请选择投放人员",
                              },
                              model: {
                                value: _vm.accountConfig.launch,
                                callback: function ($$v) {
                                  _vm.$set(_vm.accountConfig, "launch", $$v)
                                },
                                expression: "accountConfig.launch",
                              },
                            },
                            _vm._l(_vm.userList, function (itm, idx) {
                              return _c("el-option", {
                                key: idx,
                                attrs: { label: itm.label, value: itm.key },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("accountConfig")
                    },
                  },
                },
                [_vm._v("同步")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.resetForm("accountConfig")
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "投放账号编辑",
            visible: _vm.editInfo_visible,
            width: "720px",
            "modal-append-to-body": false,
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.editInfo_visible = $event
            },
            close: function ($event) {
              return _vm.resetForm("ruleForm")
            },
          },
        },
        [
          _c("div", { staticClass: "justify-sb pl-20 pr-20 mb-20" }, [
            _c("p", [_vm._v("账号名称：" + _vm._s(_vm.editInfo.name))]),
            _c("p", [_vm._v("appid：" + _vm._s(_vm.editInfo.appid))]),
            _c("p", [_vm._v("ghid：" + _vm._s(_vm.editInfo.ghid))]),
          ]),
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: { model: _vm.ruleForm, "label-width": "100px" },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "项目",
                    prop: "projectId",
                    rules: {
                      required: true,
                      message: "请选择项目",
                      trigger: "change",
                    },
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "400px" },
                      attrs: { placeholder: "请选择项目" },
                      on: {
                        change: (value) => (
                          (_vm.selectProjectId = value),
                          (_vm.ruleForm.productId = 0)
                        ),
                      },
                      model: {
                        value: _vm.ruleForm.projectId,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "projectId", $$v)
                        },
                        expression: "ruleForm.projectId",
                      },
                    },
                    _vm._l(_vm.projectList, function (item) {
                      return _c("el-option", {
                        key: item.bsProjectId,
                        attrs: {
                          label: item.projectName,
                          value: item.bsProjectId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "产品",
                    prop: "productId",
                    rules: {
                      required: true,
                      message: "请选择投放人员",
                      trigger: "change",
                    },
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "400px" },
                      attrs: { placeholder: "请选择产品" },
                      on: { change: (value) => (_vm.selectProductId = value) },
                      model: {
                        value: _vm.ruleForm.productId,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "productId", $$v)
                        },
                        expression: "ruleForm.productId",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "不限", value: 0 } }),
                      _vm._l(_vm.productListed, function (itm, idx) {
                        return _c("el-option", {
                          key: idx,
                          attrs: {
                            label: itm.productName,
                            value: itm.bsProductId,
                          },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "投放人员",
                    prop: "launch",
                    rules: {
                      required: true,
                      message: "请选择投放人员",
                      trigger: "change",
                    },
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "400px" },
                      attrs: {
                        "value-key": "key",
                        filterable: "",
                        clearable: "",
                        placeholder: "请选择投放人员",
                      },
                      model: {
                        value: _vm.ruleForm.launch,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "launch", $$v)
                        },
                        expression: "ruleForm.launch",
                      },
                    },
                    _vm._l(_vm.userList, function (itm, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: {
                          label: itm.label,
                          value: `${itm.key}+${itm.label}`,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "投放状态",
                    prop: "putInStatus",
                    rules: {
                      required: true,
                      message: "请选择投放状态",
                      trigger: "change",
                    },
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "400px" },
                      attrs: { placeholder: "请选择投放状态" },
                      model: {
                        value: _vm.ruleForm.putInStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "putInStatus", $$v)
                        },
                        expression: "ruleForm.putInStatus",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "投放", value: 1 } }),
                      _c("el-option", { attrs: { label: "停投", value: 0 } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      type: "textarea",
                      maxlength: "30",
                      placeholder: "请输入内容,内容最多数日30个字符",
                    },
                    model: {
                      value: _vm.ruleForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "remark", $$v)
                      },
                      expression: "ruleForm.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v(" 修改")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.resetForm("ruleForm")
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title:
              _vm.commandType === "0"
                ? "批量修改关联项目"
                : _vm.commandType === "1"
                ? "批量修改关联产品"
                : "批量修改投放人员",
            visible: _vm.batchInfo_visible,
            width: "620px",
            "modal-append-to-body": false,
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.batchInfo_visible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm2",
              staticClass: "demo-ruleForm",
              attrs: { model: _vm.ruleForm2, "label-width": "100px" },
            },
            [
              _vm.commandType === "0"
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "项目:",
                            prop: "projectId",
                            rules: {
                              required: true,
                              message: "请选择关联项目",
                              trigger: "change",
                            },
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "400px" },
                              attrs: { placeholder: "请选择项目" },
                              on: {
                                change: (value) =>
                                  (_vm.selectProjectId = value),
                              },
                              model: {
                                value: _vm.ruleForm2.projectId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm2, "projectId", $$v)
                                },
                                expression: "ruleForm2.projectId",
                              },
                            },
                            _vm._l(_vm.projectList, function (item) {
                              return _c("el-option", {
                                key: item.bsProjectId,
                                attrs: {
                                  label: item.projectName,
                                  value: item.bsProjectId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c("div", [
                    _vm.commandType === "1"
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "关联产品:" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "400px" },
                                    attrs: { placeholder: "请选择产品" },
                                    model: {
                                      value: _vm.ruleForm2.productId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ruleForm2,
                                          "productId",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm2.productId",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: { label: "不限", value: 0 },
                                    }),
                                    _vm._l(
                                      _vm.productListed,
                                      function (itm, idx) {
                                        return _c("el-option", {
                                          key: idx,
                                          attrs: {
                                            label: itm.productName,
                                            value: itm.bsProductId,
                                          },
                                        })
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.commandType === "2"
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "投放人员:",
                                  prop: "project_name",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      "value-key": "key",
                                      filterable: "",
                                      clearable: "",
                                      placeholder: "请选择投放人员",
                                    },
                                    model: {
                                      value: _vm.ruleForm2.launch,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.ruleForm2, "launch", $$v)
                                      },
                                      expression: "ruleForm2.launch",
                                    },
                                  },
                                  _vm._l(_vm.userList, function (itm, idx) {
                                    return _c("el-option", {
                                      key: idx,
                                      attrs: {
                                        label: itm.label,
                                        value: `${itm.key}+${itm.label}`,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
            ]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm2")
                    },
                  },
                },
                [_vm._v("修改")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.resetForm("ruleForm2")
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }