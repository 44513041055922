var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "el-form-item",
        { attrs: { prop: "return_condition", "label-width": "30px" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.ruleData.return_condition,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleData, "return_condition", $$v)
                },
                expression: "ruleData.return_condition",
              },
            },
            [
              _c(
                "el-row",
                _vm._l(_vm.returnConditionOption, function (item) {
                  return _c(
                    "el-col",
                    { key: item.value, staticClass: "mt-10" },
                    [
                      _c("el-radio", { attrs: { label: item.value } }, [
                        _vm._v(_vm._s(item.title)),
                      ]),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: {
            prop: "is_few_return",
            label: "部分订单回传",
            "label-width": "130px",
          },
        },
        [
          _c("el-switch", {
            model: {
              value: _vm.ruleData.is_few_return,
              callback: function ($$v) {
                _vm.$set(_vm.ruleData, "is_few_return", $$v)
              },
              expression: "ruleData.is_few_return",
            },
          }),
        ],
        1
      ),
      _vm.ruleData.is_few_return
        ? [
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "回传方式",
                  prop: "return_type",
                  rules: [{ required: true }],
                },
              },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "400px" },
                    on: { change: _vm.changeReturnType },
                    model: {
                      value: _vm.ruleData.return_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleData, "return_type", $$v)
                      },
                      expression: "ruleData.return_type",
                    },
                  },
                  [
                    _c("el-option", {
                      attrs: { label: "百分比回传", value: 1 },
                    }),
                    _c("el-option", {
                      attrs: { label: "循环比例回传", value: 2 },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { display: "flex" } },
              [
                _vm.ruleData.return_type === 2 && _vm.type !== "baidu"
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "循环比例",
                          prop: "cycle_proportion",
                          rules: [{ required: true }],
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "400px" },
                            on: { change: _vm.changeCycleProportion },
                            model: {
                              value: _vm.ruleData.cycle_proportion,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleData, "cycle_proportion", $$v)
                              },
                              expression: "ruleData.cycle_proportion",
                            },
                          },
                          _vm._l(_vm.cycleProportionOption, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.title, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  {
                    staticClass: "customize_cycle_proportion",
                    staticStyle: { "margin-left": "5px" },
                    attrs: {
                      prop: "customize_cycle_proportion_start",
                      rules: [
                        {
                          required: _vm.ruleData.cycle_proportion === "0:0",
                          message: "填入传几单!",
                        },
                      ],
                    },
                  },
                  [
                    _vm.ruleData.cycle_proportion === "0:0"
                      ? _c("el-input", {
                          staticStyle: { width: "100px" },
                          attrs: { placeholder: "填入传几单" },
                          model: {
                            value:
                              _vm.ruleData.customize_cycle_proportion_start,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleData,
                                "customize_cycle_proportion_start",
                                $$v
                              )
                            },
                            expression:
                              "ruleData.customize_cycle_proportion_start",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.ruleData.cycle_proportion === "0:0"
                  ? _c(
                      "div",
                      {
                        staticStyle: { "margin-top": "6px", padding: "0 5px" },
                      },
                      [_vm._v(":")]
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  {
                    staticClass: "customize_cycle_proportion",
                    attrs: {
                      prop: "customize_cycle_proportion_end",
                      rules: [
                        {
                          required: _vm.ruleData.cycle_proportion === "0:0",
                          message: "填入卡几单!",
                        },
                      ],
                    },
                  },
                  [
                    _vm.ruleData.cycle_proportion === "0:0"
                      ? _c("el-input", {
                          staticStyle: { width: "100px" },
                          attrs: { placeholder: "填入卡几单" },
                          model: {
                            value: _vm.ruleData.customize_cycle_proportion_end,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.ruleData,
                                "customize_cycle_proportion_end",
                                $$v
                              )
                            },
                            expression:
                              "ruleData.customize_cycle_proportion_end",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.ruleData.return_type === 2 && _vm.type !== "baidu"
                  ? _c(
                      "el-form-item",
                      {
                        staticClass: "customize_cycle_proportion",
                        staticStyle: { "margin-left": "5px" },
                        attrs: {
                          prop: "is_first_return",
                          rules: [{ required: true }],
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "150px" },
                            model: {
                              value: _vm.ruleData.is_first_return,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleData, "is_first_return", $$v)
                              },
                              expression: "ruleData.is_first_return",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "优先回传", value: 1 },
                            }),
                            _c("el-option", {
                              attrs: { label: "优先卡单", value: 2 },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm.ruleData.return_type === 2 && _vm.type === "baidu"
              ? _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "循环比例",
                      prop: "cycle_proportion",
                      rules: [{ required: true }],
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "400px" },
                        attrs: { multiple: "" },
                        model: {
                          value: _vm.ruleData.cycle_proportion,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleData, "cycle_proportion", $$v)
                          },
                          expression: "ruleData.cycle_proportion",
                        },
                      },
                      _vm._l(_vm.cycleProportionOption_baidu, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.title, value: item.value },
                        })
                      }),
                      1
                    ),
                    _c(
                      "p",
                      {
                        staticClass: "tip",
                        staticStyle: {
                          "padding-bottom": "0px",
                          "margin-bottom": "0px",
                        },
                      },
                      [_vm._v("勾选不回传订单序号")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.ruleData.return_type === 2
              ? _c(
                  "el-form-item",
                  { attrs: { label: "循环规则", prop: "cycle_proportion" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.ruleData.cycle_rule,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleData, "cycle_rule", $$v)
                          },
                          expression: "ruleData.cycle_rule",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("按订单循环"),
                        ]),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("每日重置"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.ruleData.return_type === 1
              ? _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "百分比回传",
                      prop: "return_rate",
                      rules: [{ required: true }],
                    },
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.ruleData.return_rate,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleData, "return_rate", $$v)
                          },
                          expression: "ruleData.return_rate",
                        },
                      },
                      _vm._l(_vm.returnRateOption, function (item) {
                        return _c(
                          "el-radio",
                          { key: item.value, attrs: { label: item.value } },
                          [_vm._v(_vm._s(item.title))]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _vm._e(),
      _c(
        "el-form-item",
        {
          attrs: {
            prop: "date",
            label: "启用时间",
            rules: [{ required: true }],
          },
        },
        [
          _c("el-time-picker", {
            attrs: {
              "is-range": "",
              "range-separator": "-",
              "start-placeholder": "开始时间",
              "end-placeholder": "结束时间",
            },
            model: {
              value: _vm.ruleData.date,
              callback: function ($$v) {
                _vm.$set(_vm.ruleData, "date", $$v)
              },
              expression: "ruleData.date",
            },
          }),
        ],
        1
      ),
      _vm.ruleData.platform === "gdt"
        ? _c(
            "el-form-item",
            { attrs: { prop: "is_on_sale", label: "回传金额调整" } },
            [
              _c("el-switch", {
                on: { change: _vm.changeSale },
                model: {
                  value: _vm.ruleData.is_on_sale,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleData, "is_on_sale", $$v)
                  },
                  expression: "ruleData.is_on_sale",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticStyle: { display: "flex" } },
        [
          _vm.ruleData.is_on_sale
            ? _c(
                "el-form-item",
                {
                  staticClass: "customize_cycle_proportion",
                  staticStyle: { "margin-left": "30px" },
                  attrs: {
                    prop: "discount",
                    rules: [
                      {
                        required:
                          _vm.ruleData.discount === 0 ||
                          _vm.ruleData.discount === null,
                        message: "请输入缩减比例!",
                      },
                    ],
                  },
                },
                [
                  _vm.ruleData.is_on_sale
                    ? _c("el-input-number", {
                        staticStyle: { width: "150px" },
                        attrs: {
                          min: 0,
                          precision: 2,
                          placeholder: "输入缩减比例",
                        },
                        model: {
                          value: _vm.ruleData.discount,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleData, "discount", $$v)
                          },
                          expression: "ruleData.discount",
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.ruleData.is_on_sale
            ? _c(
                "div",
                { staticStyle: { "margin-top": "6px", padding: "0 10px" } },
                [_vm._v("%")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }