<!--
 * @Description: 计划
 * @Date: 2021-12-06 15:57:16
 * @LastEditTime: 2022-05-23 17:30:28
-->
<template>
  <div style="height: 300px;">
    <ContentBox v-if="adForm.flag" :data-props="adDiv" />
    <div v-else class="kong">请选择广告</div>

    <!-- 配置计划 -->
    <el-dialog title="广告基础信息" :visible="visible" width="800px" :modal-append-to-body="false" @close="clickClose">
      <span>
        <el-form ref="form" :model="adForm" label-width="100px">
          <el-form-item label="投放时间:" :rules="{ required: true, message: '请选择投放时间', trigger: 'blue' }">
            <el-radio v-model="adForm.launchData" label="1">选择开始和结束日期</el-radio>
            <el-radio v-model="adForm.launchData" label="2">长期投放</el-radio>
          </el-form-item>

          <el-form-item v-if="adForm.launchData === '1'" label="" :rules="{ required: true, message: '请选择投放时间', trigger: 'blue' }">
            <el-date-picker v-model="adForm.date" :picker-options="pickerOptions" value-format="yyyy-MM-dd" style="width:400px" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
          </el-form-item>

          <el-form-item v-if="adForm.launchData === '2'" label="开始日期:" :rules="{ required: true, message: '请选择开始日期', trigger: 'blue' }">
            <el-date-picker v-model="adForm.date2" :picker-options="pickerOptions" value-format="yyyy-MM-dd" style="width:400px" type="date" placeholder="请选择开始日期"> </el-date-picker>
          </el-form-item>
          <el-form-item label="投放时断:">
            <el-radio value="1" label="1">全天投放</el-radio>
          </el-form-item>

          <el-form-item label="归因方式:" prop="trackingSourceid">
            <el-radio v-model="adForm.trackingSourceid" label="1">全网归因 </el-radio>
            <el-radio v-model="adForm.trackingSourceid" label="0">精准匹配归因</el-radio>
          </el-form-item>

          <el-form-item label="出价方式:">
            <el-radio v-if="['展示Banner图片', '优雅横版大图', '公众号文章底部视频', '公众号文章底部图片', '公众号文章中部', '小程序banner广告'].includes(crtSizeLabel)" label="1" value="1">oCPC</el-radio>
            <el-radio v-if="['朋友圈', '沉浸式横版视频'].includes(crtSizeLabel)" value="1" label="1">oCPM</el-radio>
          </el-form-item>

          <el-form-item label="投放模式:">
            <el-radio v-model="adForm.bidStrategy" :label="1">均衡投放</el-radio>
            <el-radio v-model="adForm.bidStrategy" :label="2">优先跑量</el-radio>
            <el-radio v-model="adForm.bidStrategy" :label="3">优先成本</el-radio>
          </el-form-item>

          <el-form-item label="优化目标:">
            <el-radio-group v-model="adForm.bidObjective" class="mt-10" @change="setBidObjective">
              <el-radio v-for="item in objectiveList" :key="item.value" class="mb-10" :label="item.value">{{ item.label }}</el-radio>
            </el-radio-group>
            <!-- <el-select :value="adForm.bidObjective" style="width:400px" placeholder="请选择投放位置" :rules="{ required: true, message: '请选择投放账户', trigger: 'blue' }" @change="launchPlanAddressChange">
              <el-option v-for="item in objectiveList" :key="item.id" :label="item.value" :value="item.value"></el-option>
            </el-select> -->
          </el-form-item>

          <el-form-item v-if="[6, 10, 25].includes(+adForm.bidObjective)" label="深度优化:">
            <el-radio-group v-model="adForm.bidObjectiveMode" class="mt-10" @change="setBidObjectiveMode">
              <el-radio label="1">不启用</el-radio>
              <el-radio v-if="bidObjectiveList && bidObjectiveList.length > 0" label="2">优化转化行为</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item v-if="adForm.bidObjectiveMode === '2' && bidObjectiveList && bidObjectiveList.length > 0" label="深度优化目标:">
            <el-radio-group v-model="adForm.secondBidObjective" class="mt-10">
              <el-radio v-for="item in bidObjectiveList" :key="item.value" class="mb-10" :label="item.value">{{ item.label }}</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item v-if="adForm.bidObjectiveMode === '2'" label="深度出价:">
            <el-input-number v-model="adForm.secondBid" :min="1" controls-position="right"></el-input-number>
            <span class="ml-10">元</span>
          </el-form-item>

          <el-row>
            <el-col :span="12">
              <el-form-item label="每日预算:">
                <el-input-number v-model="adForm.dayBudget" :min="50" :max="40000000" style="width: 180px" controls-position="right"></el-input-number>
                <span class="ml-10" style="white-space: nowrap;">元/天</span>
                <p class="tip">每日预算有效范围为 50 ~ 40,000,000 元/天</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="出价:">
                <el-input-number v-model="adForm.bid" :min="0" style="width: 180px" controls-position="right"></el-input-number>
                <span class="ml-10">元</span>
                <!-- <p class="tip">广告出价有效范围为 0.1 ~ 20元</p> -->
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider></el-divider>

          <el-form-item label="广告名称:" prop="aname" class="mt-20" :rules="{ required: true, message: '请输入广告名称', trigger: 'blue' }">
            <!-- <el-input v-model="adForm.aname" maxlength="60" show-word-limit></el-input> -->
            <el-input v-model="adForm.aname" class="mt-10" rows="4" type="textarea" style="width: 400px" placeholder="请输入广告名称" :maxlength="60" show-word-limit />
            <div class="display:start">
              通配符：
              <el-button type="text" @click="addAName(moment().format('YYYYMMDDHHmmss'))">+日期</el-button>
              <el-button type="text" @click="addAName(`0${nameIndex}`)">+标号</el-button>
              <el-button type="text" @click="addAName(userInfo.username)">+投手名称</el-button>
            </div>
          </el-form-item>

          <!--
          <el-form-item label="一键起量:">
            <el-switch v-model="value"> </el-switch>
          </el-form-item> -->
        </el-form>
        <!-- <div style="text-align:right">
          <el-checkbox v-model="adForm.userId">存为预设</el-checkbox>
        </div> -->
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" :disabled="!isSearchLock" @click="getSelectPlanData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getOptimizeTheTarget, saveAd, updateAd } from '@/network/api/advManagement/advManagementWatch' // 获取计划模板
import ContentBox from './contentBox.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  components: {
    ContentBox
  },

  inject: ['close'],

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    accountBaiduAdv: {
      type: Array,
      default: () => []
    },
    promotedObjectType: {
      type: String,
      default: ''
    },
    crtSizeLabel: {
      type: String,
      default: ''
    },
    formData: {
      type: Object,
      default: () => {}
    },
    taskId: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      objectiveList: [], // 优化目标
      bidObjectiveList: [], // 深度优化目标
      adForm: {},
      adDiv: [],
      nameIndex: 1,
      isSearchLock: true,
      pickerOptions: {
        disabledDate(time) {
          // 此条为设置禁止用户选择今天之前的日期，包含今天。
          // return time.getTime() <= (Date.now());
          // 此条为设置禁止用户选择今天之前的日期，不包含今天。
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
        }
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },

  watch: {
    formData: {
      handler(value) {
        let data = [
          moment().format('YYYY-MM-DD'),
          moment()
            .subtract('days', -30)
            .format('YYYY-MM-DD')
        ]
        this.adForm = {
          ...value,

          date: value.date.length === 0 ? data : value.date,
          date2: value.date2.length === 0 ? moment().format('YYYY-MM-DD') : value.date2
          // secondBid: 1
        }
        this.adDiv = [
          { key: '投放时间', value: value.endTime === 0 ? '长期投放' : `${moment(moment.unix(value.beginTime)).format('YYYY-MM-DD')} ~ ${moment(moment.unix(value.endTime)).format('YYYY-MM-DD')}` },
          { key: '投放时段', value: '全天投放' },
          { key: '归因方式', value: value.trackingSourceid === '0' ? '精准匹配归因' : '全网归因' },
          { key: '出价方式', value: value.bidAmountMode },
          { key: '投放模式', value: value.bidStrategy === 1 ? '均衡投放' : value.bidStrategy === 2 ? '优先跑量' : '优先成本' },
          { key: '优化目标', value: this.objectiveList.length && this.objectiveList.filter((item) => +item.value === +value.bidObjective)[0].label },
          { key: '每日预算', value: `${value.dayBudget} 元/天` },
          { key: '出价', value: `${value.bid} 元/天` }
        ]
      },
      immediate: true
    },
    // 打开弹框调用
    visible(value) {
      if (value) {
        if (value && this.objectiveList.length === 0) {
          this.getOptimizeTheTargetFun(this.promotedObjectType)
        }
      } else {
        this.objectiveList = []
      }
    }
  },

  methods: {
    addAName(text) {
      this.adForm.aname = this.adForm.aname + text
      if (this.adForm.aname.length > 60) {
        this.adForm.aname = this.adForm.aname.substring(0, 60)
      }
      this.nameIndex++
    },
    /**
     * @desc 根据投放位置获取广告位置
     */
    getOptimizeTheTargetFun(type) {
      getOptimizeTheTarget(type, this.adForm.isFriendCircle).then(({ code, data }) => {
        if (code === 200) {
          this.objectiveList = data
          console.log(this.adForm.bidObjective)
          if (this.adForm.bidObjective == null) {
            this.adForm.bidObjective = data[0].value
            this.bidObjectiveList = data[0].child
          } else {
            this.bidObjectiveList = data.find((f) => +f.value === +this.adForm.bidObjective).child
          }
        }
      })
    },

    setUserId(value) {
      if (value) {
        this.ruleForm.userId = this.userInfo.userid
      } else {
        this.ruleForm.userId = ''
      }
    },

    clickClose() {
      this.$emit('byTaskIdGetAllFun')
      this.close()
    },

    setBidObjective(e) {
      let obj = this.objectiveList.find((f) => f.value === e)
      this.bidObjectiveList = obj.child
      this.adForm.bidObjectiveMode = '1'
    },

    setBidObjectiveMode(e) {
      if (e === '2') {
        this.adForm.secondBidObjective = this.bidObjectiveList && this.bidObjectiveList[0].value
      } else {
        this.adForm.secondBidObjective = null
      }
    },

    /**
     * @desc 获取选中的计划后提交
     */
    getSelectPlanData() {
      if (this.isSearchLock) {
        this.isSearchLock = false
        this.$refs['form'].validate((valid) => {
          if (valid) {
            let params = {
              taskId: this.taskId,
              aname: this.adForm.aname,
              beginTime: this.adForm.launchData === '2' ? moment(`${this.adForm.date2} 00:00:00`).unix() : moment(`${this.adForm.date[0]} 00:00:00`).unix(),
              endTime: this.adForm.launchData === '2' ? 0 : moment(`${this.adForm.date[1]} 23:59:59`).unix(),
              dayBudget: this.adForm.dayBudget,
              bid: +this.adForm.bid * 100,
              bidStrategy: this.adForm.bidStrategy,
              trackingSourceid: this.adForm.trackingSourceid,
              bidAmountMode: ['展示Banner图片', '优雅横版大图', '公众号文章底部', '公众号文章中部', '小程序banner广告'].includes(this.crtSizeLabel) ? 'oCPC' : 'oCPM',
              strategyOpt: JSON.stringify({
                bid_objective: this.adForm.bidObjective,
                bid_action_type: this.adForm.bidObjective,
                bid_objective_mode: this.adForm.bidObjectiveMode
              })
            }

            if (this.adForm.bidObjectiveMode === '2') {
              params.strategyOpt = JSON.stringify({
                second_bid_objective: this.adForm.secondBidObjective,
                second_bid: +this.adForm.secondBid * 100,
                bid_objective: this.adForm.bidObjective,
                bid_action_type: this.adForm.bidObjective,
                bid_objective_mode: this.adForm.bidObjectiveMode
              })
            }

            if (this.adForm.adGroupId) {
              updateAd({ ...params, adGroupId: this.adForm.adGroupId }).then((res) => {
                if (res.code === 200) {
                  this.$emit('byTaskIdGetAllFun')
                  this.$message.success('广告修改成功!')
                  this.isSearchLock = true
                  this.close()
                }
              })
            } else {
              saveAd(params).then((res) => {
                if (res.code === 200) {
                  this.$emit('byTaskIdGetAllFun')
                  this.$message.success('广告保存成功!')
                  this.isSearchLock = true
                  this.close()
                }
              })
            }
          }
        })
      }
    }
  }
}
</script>
