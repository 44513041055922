var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { ref: "mySection", staticClass: "section" },
    [
      _c(
        "base-box",
        { attrs: { name: "base" } },
        [
          _c(
            "template",
            { slot: "body" },
            [
              _c(
                "el-form",
                {
                  ref: "baseForm",
                  staticClass: "img-form-box",
                  attrs: {
                    model: _vm.detail,
                    size: "mini",
                    inline: true,
                    rules: _vm.rules,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "按钮文案", prop: "btnTitle" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          maxlength: "10",
                          "show-word-limit": "",
                          type: "text",
                          size: "small",
                        },
                        model: {
                          value: _vm.detail.btnTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "btnTitle", $$v)
                          },
                          expression: "detail.btnTitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "按钮大小", prop: "btnSize" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "small" },
                          model: {
                            value: _vm.detail.btnSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.detail, "btnSize", $$v)
                            },
                            expression: "detail.btnSize",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "large" } }, [
                            _vm._v("大"),
                          ]),
                          _c("el-radio-button", { attrs: { label: "small" } }, [
                            _vm._v("小"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "字符样式", prop: "btnFontSize" } },
                    [
                      _c("div", { staticStyle: { display: "flex" } }, [
                        _c(
                          "div",
                          { staticClass: "mr-10" },
                          [
                            _c("el-input-number", {
                              attrs: { size: "small", min: 1, max: 100 },
                              model: {
                                value: _vm.detail.btnFontSize,
                                callback: function ($$v) {
                                  _vm.$set(_vm.detail, "btnFontSize", $$v)
                                },
                                expression: "detail.btnFontSize",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mr-10" },
                          [
                            _c(
                              "el-dropdown",
                              [
                                _c("div", {
                                  staticClass: "ldy-bg-color",
                                  style: `background:${_vm.detail.btnFontColor}`,
                                }),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  [
                                    _c("twitter-color", {
                                      staticStyle: { "margin-top": "10px" },
                                      attrs: {
                                        colors: _vm.detail.btnFontColor,
                                      },
                                      on: {
                                        change: (value) =>
                                          (_vm.detail.btnFontColor = value),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {},
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: { size: "small" },
                                model: {
                                  value: _vm.detail.btnFontType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.detail, "btnFontType", $$v)
                                  },
                                  expression: "detail.btnFontType",
                                },
                              },
                              [
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "normal" } },
                                  [_vm._v("常规")]
                                ),
                                _c(
                                  "el-radio-button",
                                  { attrs: { label: "bold" } },
                                  [_vm._v("加粗")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "对齐方式", prop: "textAlignment" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "small" },
                          model: {
                            value: _vm.detail.textAlignment,
                            callback: function ($$v) {
                              _vm.$set(_vm.detail, "textAlignment", $$v)
                            },
                            expression: "detail.textAlignment",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "left" } }, [
                            _vm._v("左对齐"),
                          ]),
                          _c(
                            "el-radio-button",
                            { attrs: { label: "center" } },
                            [_vm._v("居中对齐")]
                          ),
                          _c("el-radio-button", { attrs: { label: "right" } }, [
                            _vm._v("右对齐"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "边框颜色", prop: "btnBorderColorTheme" },
                    },
                    [
                      _c(
                        "el-dropdown",
                        [
                          _c("div", {
                            staticClass: "ldy-bg-color",
                            style: `background:${_vm.detail.btnBorderColorTheme}`,
                          }),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              _c("twitter-color", {
                                staticStyle: { "margin-top": "10px" },
                                attrs: {
                                  colors: _vm.detail.btnBorderColorTheme,
                                },
                                on: {
                                  change: (value) =>
                                    (_vm.detail.btnBorderColorTheme = value),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "填充颜色", prop: "btnBgColorTheme" } },
                    [
                      _c(
                        "el-dropdown",
                        [
                          _c("div", {
                            staticClass: "ldy-bg-color",
                            style: `background:${_vm.detail.btnBgColorTheme}`,
                          }),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              _c("twitter-color", {
                                staticStyle: { "margin-top": "10px" },
                                attrs: { colors: _vm.detail.btnBgColorTheme },
                                on: {
                                  change: (value) =>
                                    (_vm.detail.btnBgColorTheme = value),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "对齐方式", prop: "textAlignment" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "small" },
                          model: {
                            value: _vm.detail.textAlignment,
                            callback: function ($$v) {
                              _vm.$set(_vm.detail, "textAlignment", $$v)
                            },
                            expression: "detail.textAlignment",
                          },
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "left" } }, [
                            _vm._v("左对齐"),
                          ]),
                          _c(
                            "el-radio-button",
                            { attrs: { label: "center" } },
                            [_vm._v("居中对齐")]
                          ),
                          _c("el-radio-button", { attrs: { label: "right" } }, [
                            _vm._v("右对齐"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "顶部距离", prop: "marginTop" } },
                    [
                      _c("el-slider", {
                        attrs: { "show-input": "", max: _vm.maxMarginTop },
                        model: {
                          value: _vm.detail.marginTop,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "marginTop", $$v)
                          },
                          expression: "detail.marginTop",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "上边距", prop: "paddingTop" } },
                    [
                      _c("el-slider", {
                        attrs: { "show-input": "", max: 100 },
                        model: {
                          value: _vm.detail.paddingTop,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "paddingTop", $$v)
                          },
                          expression: "detail.paddingTop",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "下边距", prop: "paddingBottom" } },
                    [
                      _c("el-slider", {
                        attrs: { "show-input": "", max: 100 },
                        model: {
                          value: _vm.detail.paddingBottom,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "paddingBottom", $$v)
                          },
                          expression: "detail.paddingBottom",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "按钮位置", prop: "btnPosition" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { size: "mini" },
                          model: {
                            value: _vm.detail.btnPosition,
                            callback: function ($$v) {
                              _vm.$set(_vm.detail, "btnPosition", $$v)
                            },
                            expression: "detail.btnPosition",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "自定义", value: 1 },
                          }),
                          _c("el-option", {
                            attrs: { label: "始终置顶", value: 2 },
                          }),
                          _c("el-option", {
                            attrs: { label: "始终底部", value: 3 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "链接类型", prop: "linkType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            size: "mini",
                            placeholder: "请选择链接类型",
                          },
                          model: {
                            value: _vm.detail.linkType,
                            callback: function ($$v) {
                              _vm.$set(_vm.detail, "linkType", $$v)
                            },
                            expression: "detail.linkType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "普通链接", value: 1 },
                          }),
                          _c("el-option", {
                            attrs: { label: "获客助手链接", value: 2 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "跳转链接", prop: "link" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          size: "mini",
                          type: "textarea",
                          rows: 3,
                          resize: "none",
                          placeholder: "请输入跳转链接",
                          maxlength: "500",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.detail.link,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "link", $$v)
                          },
                          expression: "detail.link",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.detail.linkType == 1
                    ? [
                        _c(
                          "el-form-item",
                          { attrs: { label: "平台", prop: "platformId" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  placeholder: "请选择平台",
                                  size: "mini",
                                  filterable: "",
                                },
                                model: {
                                  value: _vm.detail.platformId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.detail, "platformId", $$v)
                                  },
                                  expression: "detail.platformId",
                                },
                              },
                              _vm._l(_vm.platforms, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.dictLabel,
                                    value: item.dictValue,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "关联内容",
                              prop: "relationContent",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                size: "mini",
                                placeholder: "请输入投放平台相关ID",
                                maxlength: "30",
                                "show-word-limit": "",
                              },
                              model: {
                                value: _vm.detail.relationContent,
                                callback: function ($$v) {
                                  _vm.$set(_vm.detail, "relationContent", $$v)
                                },
                                expression: "detail.relationContent",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }