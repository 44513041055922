var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "material-box" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "图片选择",
            visible: _vm.visible,
            width: `1400px`,
            top: "100px",
            "before-close": _vm.close,
            "destroy-on-close": "",
            "modal-append-to-body": false,
          },
        },
        [
          _c(
            "el-row",
            { staticClass: "selected-img", attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-col",
                    { staticClass: "tree-box", attrs: { span: 3 } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          placeholder: "输入关键字进行过滤",
                          size: "small",
                        },
                        model: {
                          value: _vm.filterText,
                          callback: function ($$v) {
                            _vm.filterText = $$v
                          },
                          expression: "filterText",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "tree" },
                        [
                          _c("el-tree", {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.treeLoading,
                                expression: "treeLoading",
                              },
                            ],
                            ref: "tree",
                            attrs: {
                              "element-loading-spinner": "el-icon-loading",
                              data: _vm.adAlbum,
                              lazy: "",
                              load: _vm.loadNode,
                              props: _vm.defaultProps,
                              "filter-node-method": _vm.filterNode,
                            },
                            on: { "node-click": _vm.nodeClick },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form",
                            {
                              ref: "imageQuery",
                              staticStyle: { marginLeft: "10px" },
                              attrs: { inline: true, model: _vm.imageQuery },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "上传人: " } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "150px" },
                                    attrs: { placeholder: "请输入上传人" },
                                    model: {
                                      value: _vm.imageQuery.nickname,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.imageQuery,
                                          "nickname",
                                          $$v
                                        )
                                      },
                                      expression: "imageQuery.nickname",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "宽: " } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "120px" },
                                    attrs: { placeholder: "请输入宽" },
                                    model: {
                                      value: _vm.imageQuery.width,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.imageQuery, "width", $$v)
                                      },
                                      expression: "imageQuery.width",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "高: " } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "120px" },
                                    attrs: { placeholder: "请输入高" },
                                    model: {
                                      value: _vm.imageQuery.height,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.imageQuery, "height", $$v)
                                      },
                                      expression: "imageQuery.height",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { staticClass: "ml-20" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: { click: _vm.onSubmit },
                                    },
                                    [_vm._v("查询")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: { click: _vm.resetForm },
                                    },
                                    [_vm._v("重置")]
                                  ),
                                ],
                                1
                              ),
                              _vm.selectAll
                                ? _c(
                                    "el-form-item",
                                    { staticClass: "ml-20" },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: {
                                            disabled: _vm.selectAllDisabled,
                                          },
                                          on: { change: _vm.selectAllFun },
                                          model: {
                                            value: _vm.selectedImgCheckbox,
                                            callback: function ($$v) {
                                              _vm.selectedImgCheckbox = $$v
                                            },
                                            expression: "selectedImgCheckbox",
                                          },
                                        },
                                        [_vm._v("全选当前素材组下图片")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.onClickAddMaterial(0)
                                    },
                                  },
                                },
                                [_vm._v("新增素材")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.imgLoading,
                              expression: "imgLoading",
                            },
                          ],
                          attrs: {
                            span: 18,
                            "element-loading-spinner": "el-icon-loading",
                            "element-loading-text": "图片获取中...",
                          },
                        },
                        [
                          _vm.imgList.length
                            ? _c(
                                "ul",
                                {
                                  staticClass: "select-img",
                                  staticStyle: { margin: "0" },
                                },
                                [
                                  _c(
                                    "el-row",
                                    _vm._l(_vm.imgList, function (item) {
                                      return _c(
                                        "el-col",
                                        {
                                          key: item.imageId,
                                          attrs: { span: 6 },
                                        },
                                        [
                                          _c(
                                            "li",
                                            {
                                              class: {
                                                active:
                                                  _vm.selectedImgList.some(
                                                    (s) =>
                                                      s.imageId === item.imageId
                                                  ),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.selectImg(item)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "avatar-box" },
                                                [
                                                  _c("img", {
                                                    staticClass: "avatar",
                                                    attrs: { src: item.url },
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "attachments",
                                                  class: {
                                                    "success-color":
                                                      _vm.attribute.width ===
                                                        item.width &&
                                                      _vm.attribute.height ===
                                                        item.height &&
                                                      _vm.attribute.size >=
                                                        parseInt(item.size),
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-ellipsis",
                                                      attrs: {
                                                        title:
                                                          item.materialName,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.materialName
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c("div", [
                                                    _c("p", [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(item.width) +
                                                            "px"
                                                        ),
                                                      ]),
                                                      _vm._v(" * "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(item.height) +
                                                            "px"
                                                        ),
                                                      ]),
                                                    ]),
                                                    _c("p", [
                                                      _vm._v(
                                                        _vm._s(
                                                          parseInt(
                                                            item.size / 1024
                                                          )
                                                        ) + "kb"
                                                      ),
                                                    ]),
                                                  ]),
                                                ]
                                              ),
                                              _c("i", {
                                                staticClass: "el-icon-success",
                                              }),
                                            ]
                                          ),
                                        ]
                                      )
                                    }),
                                    1
                                  ),
                                  _c("el-pagination", {
                                    staticStyle: { "text-align": "right" },
                                    attrs: {
                                      background: "",
                                      layout: "prev, pager, next",
                                      total: _vm.imagListTotal,
                                      "current-page": _vm.imageQuery.pageNum,
                                    },
                                    on: {
                                      "update:currentPage": function ($event) {
                                        return _vm.$set(
                                          _vm.imageQuery,
                                          "pageNum",
                                          $event
                                        )
                                      },
                                      "update:current-page": function ($event) {
                                        return _vm.$set(
                                          _vm.imageQuery,
                                          "pageNum",
                                          $event
                                        )
                                      },
                                      "current-change": _vm.currentChange,
                                      "prev-click": _vm.currentChange,
                                      "next-click": _vm.currentChange,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "ul",
                                {
                                  staticClass: "select-img",
                                  staticStyle: { margin: "0" },
                                },
                                [
                                  _c("el-empty", {
                                    attrs: {
                                      description:
                                        "该素材组暂无图片,请选择其他素材组",
                                    },
                                  }),
                                ],
                                1
                              ),
                        ]
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-row",
                            { staticClass: "selected-box" },
                            [
                              _vm.isBatch
                                ? _vm._l(
                                    new Array(_vm.imgCountData),
                                    function (item, index) {
                                      return _c(
                                        "el-col",
                                        {
                                          key: index,
                                          staticClass: "mb-10",
                                          attrs: {
                                            span:
                                              _vm.name.indexOf(
                                                "picture3Fixed"
                                              ) >= 0
                                                ? 8
                                                : 12,
                                          },
                                        },
                                        [
                                          _c(
                                            "section",
                                            {
                                              class: {
                                                "selected-img-item2":
                                                  _vm.name.indexOf(
                                                    "picture3Fixed"
                                                  ) >= 0,
                                                "selected-img-item":
                                                  _vm.name.indexOf(
                                                    "picture3Fixed"
                                                  ) == -1,
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c("el-avatar", {
                                                    staticClass: "avatar",
                                                    attrs: {
                                                      shape: "square",
                                                      size:
                                                        _vm.name.indexOf(
                                                          "picture3Fixed"
                                                        ) >= 0
                                                          ? 70
                                                          : 100,
                                                      icon: "el-icon-picture",
                                                      src:
                                                        _vm.selectedImgList[
                                                          index
                                                        ] &&
                                                        _vm.selectedImgList[
                                                          index
                                                        ].image,
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "avatar-mask",
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleImg(
                                                                index,
                                                                "updata"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("替换")]
                                                      ),
                                                      _c(
                                                        "p",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleImg(
                                                                index,
                                                                "delete"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("删除")]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  )
                                : _vm._l(
                                    _vm.selectedImgList,
                                    function (item, index) {
                                      return _c(
                                        "el-col",
                                        {
                                          key: index,
                                          staticClass: "mb-10",
                                          attrs: {
                                            span:
                                              _vm.name.indexOf(
                                                "picture3Fixed"
                                              ) >= 0
                                                ? 8
                                                : 12,
                                          },
                                        },
                                        [
                                          _c(
                                            "section",
                                            {
                                              class: {
                                                "selected-img-item2":
                                                  _vm.name.indexOf(
                                                    "picture3Fixed"
                                                  ) >= 0,
                                                "selected-img-item":
                                                  _vm.name.indexOf(
                                                    "picture3Fixed"
                                                  ) == -1,
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c("el-avatar", {
                                                    staticClass: "avatar",
                                                    attrs: {
                                                      shape: "square",
                                                      size:
                                                        _vm.name.indexOf(
                                                          "picture3Fixed"
                                                        ) >= 0
                                                          ? 70
                                                          : 100,
                                                      icon: "el-icon-picture",
                                                      src: item.image,
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "avatar-mask",
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleImg(
                                                                index,
                                                                "updata"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("替换")]
                                                      ),
                                                      _c(
                                                        "p",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleImg(
                                                                index,
                                                                "delete"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("删除")]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.select } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.cropperVisible
        ? _c(
            "div",
            [
              _c("Cropper", {
                attrs: {
                  "target-height": _vm.targetHeight,
                  "target-width": _vm.targetWidth,
                  "target-props": {
                    width:
                      _vm.selectType === "multiple"
                        ? _vm.attribute.width[0]
                        : _vm.attribute.width,
                    height:
                      _vm.selectType === "multiple"
                        ? _vm.attribute.height[0]
                        : _vm.attribute.height,
                  },
                  "range-width": _vm.rangeWidth,
                  "range-height": _vm.rangeHeight,
                  "max-size": 300,
                  visible: _vm.cropperVisible,
                  urls: _vm.cropImgList.map((item) => item.url),
                  "image-info": _vm.cropImgList,
                },
                on: { cancel: _vm.cancelFun },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("AddMaterialDialog", {
        ref: "addMaterialDialog",
        attrs: { "add-position": "1", type: "image" },
        on: { edit: _vm.onSubmit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }