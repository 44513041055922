<template>
  <!--结束测试弹框-->
  <el-dialog
    class="end-test-dialog"
    title="结束测试"
    :visible.sync="show"
    :append-to-body="true"
    width="500px"
    top="20vh"
    @close="onCloseDialog"
  >
    <div class="page-container">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        :inline="false"
        label-width="80px"
      >
        <el-form-item
          label="结束时间"
          prop="endTime"
        >
          <el-date-picker
            v-model="form.endTime"
            type="date"
            placeholder="选择日期时间"
            style="width: 300px;"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            :picker-options="endPickerOptions"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item
          label="测试结果"
          prop="testResult"
        >
          <el-select
            v-model="form.testResult"
            placeholder="请选择测试结果"
            style="width: 300px;"
          >
            <el-option
              label="成功"
              value="3"
            >
            </el-option>

            <el-option
              label="失败"
              value="2"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <!--底部按钮-->
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        class="btn"
        @click="closeModal"
      >取 消</el-button>
      <el-button
        class="btn"
        type="primary"
        :disabled="loading"
        @click="handleConfirm"
      >确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  testEnd
} from '@/network/api/toolManagement/api-product-test'
export default {
  name: 'EndTestDialog',
  props: {},
  data() {
    return {
      show: false,
      loading: false,
      saveLoading: false,

      form: {
        toolProductFilterItemId: '',
        endTime: this.getNowTime(),
        testResult: ''
      },

      endPickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
        selectableRange: '00:00:00 - 23:59:59'
      },

      rules: {
        endTime: [
          { required: true, message: '请选择结束时间', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    /* --------------------------- Private --------------------------- */
    // 获取当前时间
    getNowTime() {
      var now = new Date()
      var year = now.getFullYear() // 得到年份
      var month = now.getMonth() // 得到月份
      var date = now.getDate() // 得到日期
      month = month + 1
      month = month.toString().padStart(2, '0')
      date = date.toString().padStart(2, '0')
      var defaultDate = `${year}-${month}-${date}`
      return defaultDate
    },

    /* --------------------------- Actions --------------------------- */
    showModal(id) {
      this.form.toolProductFilterItemId = id
      this.show = true
    },

    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      // 重置表单
      this.tableData = []
      this.form = {
        toolProductFilterItemId: '',
        endTime: '',
        testResult: ''
      }
      this.$refs['form'].clearValidate()
    },

    // 关闭弹框
    closeModal() {
      this.show = false
    },

    // 确定
    handleConfirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let parmas = Object.assign({}, this.form)
          this.saveLoading = true
          testEnd(parmas)
            .then(({ code }) => {
              if (code === 200) {
                this.$message.success('测试结束成功')
                this.$emit('confirm')
                this.closeModal()
              }
            })
            .finally(() => {
              this.saveLoading = false
            })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
