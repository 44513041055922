<!--
 * @Descripttion: 二次封装支持模糊音搜索el-select--全局组件
 * @Date: 2021-04-21 17:09:08
-->
<template>
  <div class="box">
    <el-select :value="value" v-bind="$attrs" style="width: 460px" :loading="loading" allow-create :remote-method="remoteMethod" filterable clearable remote reserve-keyword @change="changeOptions" v-on="$listeners">
      <el-option v-for="item in text_options" :key="item.id" :label="item.text" :value="item.text"> </el-option>
    </el-select>
    <span class="tip ml-10" :style="{ color: value.length > maxLength ? '#f80000' : '#7d7d7d' }"> {{ value.length }} / {{ maxLength }}</span>
  </div>
</template>

<script>
import { debounce } from '@/utils/ruoyi'
import _ from 'lodash'
import { getTxtList, addTxt } from '@/network/api/api-material'
import { mapGetters } from 'vuex'
export default {
  name: 'SearchSelectAsyncText',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    // 需要绑定的值 等于 v-model
    value: {
      type: [String, Number],
      default: ''
    },
    projectId: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      default: 12
    }
  },
  inject: ['ruleData'],
  data() {
    return {
      text_options: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  methods: {
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        this.getSelectAccountUinFun(query, this.projectId)
      } else {
        this.AccountUin_Options = []
      }
    },

    getSelectAccountUinFun: debounce(function(query) {
      getTxtList(1, 100, { text: query, projectId: +this.projectId }).then((res) => {
        if (res.code === 200) {
          this.loading = false
          this.text_options = _.uniqBy(res.data.list, 'text')
        }
      })
    }),

    changeOptions(value) {
      if (!this.text_options.some((s) => s.text === value)) {
        this.addTextSubmit(value)
      }
    },

    // 保存文案到文案库
    addTextSubmit(value) {
      let ruleData = this.ruleData()
      let params = {
        productId: ruleData.productIds,
        projectId: ruleData.projectId,
        adminId: this.userInfo.userid,
        nickname: this.userInfo.username
      }
      addTxt({ ...params, text: value }).then((res) => {
        this.$message.success('新增素材成功')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  justify-items: start;
  align-items: center;
}
</style>
