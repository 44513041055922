var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "el-select",
        _vm._g(
          _vm._b(
            {
              staticStyle: { width: "460px" },
              attrs: {
                value: _vm.value,
                loading: _vm.loading,
                "remote-method": _vm.remoteMethod,
                filterable: "",
                clearable: "",
                remote: "",
                "reserve-keyword": "",
              },
            },
            "el-select",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        _vm._l(_vm.adgroupId_options, function (item) {
          return _c("el-option", {
            key: item[_vm.props.label],
            attrs: { label: item[_vm.props.label], value: item },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }