<template>
  <el-dialog
    :title="isEdit ? '编 辑': '新 增'"
    width="45%"
    :visible.sync="visible"
    :before-close="close"
    label-position="right"
    :modal-append-to-body="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <el-form ref="ent" :model="ent" :rules="rules" label-width="110px">
      <el-form-item label="" prop="baiduCreativeFendId">
        <el-input v-model="ent.baiduCreativeFendId" />
      </el-form-item>
      <el-form-item label="创意ID	" prop="creativeFeedId">
        <el-input v-model="ent.creativeFeedId" />
      </el-form-item>
      <el-form-item label="所属推广单元ID" prop="adgroupFeedId">
        <el-input v-model="ent.adgroupFeedId" />
      </el-form-item>
      <el-form-item label="创意样式ID" prop="materialstyle">
        <el-input v-model="ent.materialstyle" />
      </el-form-item>
      <el-form-item label="创意名称	" prop="creativeFeedName">
        <el-input v-model="ent.creativeFeedName" />
      </el-form-item>
      <el-form-item
        label="是否暂停推广	"
        prop="pause"
      >
        <el-input v-model="ent.pause" />
      </el-form-item>
      <el-form-item
        label="创意状态 "
        prop="status"
      >
        <el-input v-model="ent.status" />
      </el-form-item>
      <el-form-item label="物料内容 " prop="material">
        <el-input v-model="ent.material" />
      </el-form-item>
      <el-form-item
        label="审核未通过的原因（审核拒绝理由）
"
        prop="refusereason"
      >
        <el-input v-model="ent.refusereason" />
      </el-form-item>
      <el-form-item
        label="视频创意的视频播放量"
        prop="playnum"
      >
        <el-input v-model="ent.playnum" />
      </el-form-item>
      <el-form-item
        label="创意类型  	取值范围：枚举值，列表如下
0 - 自定义创意
1 - 程序化创意"
        prop="ideaType"
      >
        <el-input v-model="ent.ideaType" />
      </el-form-item>
      <el-form-item
        label="程序化创意展示样式	 "
        prop="showMt"
      >
        <el-input v-model="ent.showMt" />
      </el-form-item>
      <el-form-item
        label="创意的创建时"
        prop="addtime"
      >
        <el-input v-model="ent.addtime" />
      </el-form-item>
      <el-form-item
        label="JSON格式化后的审核未通过的原因"
        prop="approvemsgnew"
      >
        <el-input v-model="ent.approvemsgnew" />
      </el-form-item>
      <el-form-item
        label="预估审核时间	"
        prop="audittimemodel"
      >
        <el-input v-model="ent.audittimemodel" />
      </el-form-item>
      <el-form-item label="逻辑删除" prop="deleted">
        <el-input v-model="ent.deleted" />
      </el-form-item>
      <el-form-item label="创建者" prop="createBy">
        <el-input v-model="ent.createBy" />
      </el-form-item>
      <el-form-item label="创建时间" prop="createTime">
        <el-input v-model="ent.createTime" />
      </el-form-item>
      <el-form-item label="更新者" prop="updateBy">
        <el-input v-model="ent.updateBy" />
      </el-form-item>
      <el-form-item label="更新时间" prop="updateTime">
        <el-input v-model="ent.updateTime" />
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="ent.remark" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button round @click="close">取消</el-button>
      <el-button type="primary" round @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {
  Add,
  Update
} from '@/network/api/advManagement/advManagementBaiduCreativeFend'
import { AdvManagementBaiduCreativeFendEnt, formRules } from './object'

export default {
  inject: ['loadData'],
  data() {
    return {
      ent: new AdvManagementBaiduCreativeFendEnt(),
      rules: formRules,
      isEdit: false,
      visible: false,
      cityList: []
    }
  },
  methods: {
    submit() {
      this.$refs.ent.validate((validate) => {
        if (validate) {
          this.isEdit ? this.updateRow() : this.addRow()
        }
      })
    },
    addRow() {
      const req = { ...this.ent }
      Add(req).then(res => {
        this.$message({
          message: '保存成功',
          type: 'success'
        })
        this.loadData()
        this.close()
      })
    },
    updateRow() {
      const req = { ...this.ent }
      Update(req).then(res => {
        this.$message({
          message: '修改成功',
          type: 'success'
        })
        this.loadData()
        this.close()
      })
    },
    close() {
      this.visible = false
      this.$nextTick(() => {
        this.$refs.ent.resetFields()
      })
    },
    show(isEdit = false, ent) {
      this.ent = ent
      this.isEdit = isEdit
      this.visible = true
      this.$nextTick(() => {
        if (this.isEdit) {
          console.log('不能为空')
        }
      })
    }
  }
}
</script>
