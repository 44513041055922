var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "product-details-dialog",
      attrs: {
        title: "产品起量详情",
        visible: _vm.show,
        "append-to-body": true,
        width: "800px",
        top: "20vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.onCloseDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                stripe: true,
                border: true,
                data: _vm.tableData,
                height: "300px",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "channelName",
                  label: "配置名称",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "userName", label: "投手", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "toolProductAdSpace",
                  label: "广告位",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "首次起量日", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [_vm._v(_vm._s(row.inceptionTime || "-"))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "首次起量消耗", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " + _vm._s(row.inceptionConsumer || "-") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "btn", on: { click: _vm.closeModal } },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }