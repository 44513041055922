<!--
 * @Description: 巨量广告主账户
 * @Date: 2021-11-10 19:20:55
 * @LastEditTime: 2022-02-17 10:37:05
-->
<template>
  <el-form ref="searchForm" class="search-form search-form-4" :model="searchForm">
    <el-row :gutter="20">
      <el-col :span="6">
        <el-form-item prop="accountName" label="账户名称/ID">
          <el-input v-model="searchForm.accountName" placeholder="请输入账户名称"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-button class="btn" type="primary" @click="search">
          查询
        </el-button>
        <el-button class="btn" @click="resetSearch">
          重置
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { searchForm } from './object'
export default {
  inject: ['loadData'],
  data() {
    return {
      searchForm: searchForm
    }
  },
  methods: {
    search() {
      this.loadData(true)
    },
    // 折中方案
    getSearchForm() {
      return { ...this.searchForm }
    },
    resetSearch() {
      this.searchForm = searchForm
      this.$refs.searchForm.resetFields()
      this.search()
    }
  }
}
</script>
