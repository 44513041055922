<template>
  <!--模视频板弹框-->
  <el-dialog class="element-library-box" title="新增模板" :visible.sync="show" :append-to-body="true" width="600px"
    top="20vh" @close="onCloseDialog">
    <div class="page-container">
      <el-form ref="form" :inline="false" label-width="100px" :model="form" :rules="rules">
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入名称" maxlength="20" show-word-limit style="width: 100%">
          </el-input>
        </el-form-item>
        <el-form-item label="状态" prop="state">
          <el-radio-group v-model="form.state">
            <el-radio :label="1">在线</el-radio>
            <el-radio :label="0">下线</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="模板ID" prop="videoTemplateId">
          <el-input v-model="form.videoTemplateId" placeholder="请输入模板ID" maxlength="50" show-word-limit style="width: 100%">
          </el-input>
        </el-form-item>
        <el-form-item label="源视频字段" prop="replaceName">
          <el-input v-model="form.replaceName" placeholder="请输入源视频字段" style="width: 100%">
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">取消</el-button>
      <el-button class="btn" type="primary" :disabled="loading" @click="handleConfirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { saveElementTemplate, updateElementTemplate } from '@/network/api/toolManagement/api-tool'
export default {
  name: 'VideoTemplateDialog',
  props: {},
  data() {
    return {
      show: false,
      loading: false,
      form: {
        name: '',
        state: 1,
        videoTemplateId: '',
        replaceName: ''
      },
      rules: {
        name: [{ required: true, message: '请输入模板名称', trigger: 'blur' }],
        state: [{ required: true, message: '请选择状态', trigger: 'change' }],
        videoTemplateId: [{ required: true, message: '请输入阿里云模板ID', trigger: 'blur' }],
        replaceName: [{ required: true, message: '请输入源视频字段', trigger: 'blur' }]
      }
    }
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {
    /* --------------------------- Actions --------------------------- */
    showModal(data) {
      if (Object.keys(data).length) {
        this.form = {
          ...data
        }
      }
      this.show = true
    },
    // 点击确定按钮
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return
        }
        let { id, name, state, videoTemplateId, replaceName } = this.form
        let params = {
          name,
          state,
          videoTemplateId,
          replaceName
        }
        this.loading = true
        if (id) {
          params.id = id
          updateElementTemplate(params).then(({ code }) => {
            if (code === 200) {
              this.$SuccessMessage('编辑成功')
              this.$emit('confirm')
              this.closeModal()
            }
          }).finally(() => {
            this.loading = false
          })
        } else {
          saveElementTemplate(params).then(({ code }) => {
            if (code === 200) {
              this.$message.success('添加成功')
              this.$emit('confirm')
              this.closeModal()
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      // 重置表单
      this.form = {
        name: '',
        state: 1,
        videoTemplateId: '',
        replaceName: ''
      }
      this.$refs['form'].clearValidate()
    },
    // 关闭弹框
    closeModal() {
      this.show = false
    }

    /* --------------------------- Private --------------------------- */
  }
}
</script>

<style scoped lang="scss">
</style>
