var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "create-video-box" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rule, "label-width": "160px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "任务名称", prop: "name" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: {
                  placeholder: "请输入任务名称",
                  maxlength: "30",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "视频模板", prop: "templateId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "300px" },
                  attrs: { filterable: "", placeholder: "请选择视频模板" },
                  on: { change: _vm.handleChangeTemplate },
                  model: {
                    value: _vm.form.templateId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "templateId", $$v)
                    },
                    expression: "form.templateId",
                  },
                },
                _vm._l(_vm.templateList, function (itm, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { label: itm.name, value: itm.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.form.templateId
            ? _c(
                "el-form-item",
                { attrs: { label: "视频元素", prop: "elementConfig" } },
                [
                  _vm.form.elementConfig.length
                    ? _c(
                        "div",
                        { staticClass: "video-elements-box" },
                        [
                          _c(
                            "el-tabs",
                            {
                              attrs: { type: "card" },
                              model: {
                                value: _vm.activeElement,
                                callback: function ($$v) {
                                  _vm.activeElement = $$v
                                },
                                expression: "activeElement",
                              },
                            },
                            _vm._l(
                              _vm.form.elementConfig,
                              function (item, index) {
                                return _c(
                                  "el-tab-pane",
                                  {
                                    key: index,
                                    staticClass: "element-list",
                                    attrs: {
                                      label: item.typeName,
                                      name: item.typeId + "",
                                    },
                                  },
                                  _vm._l(item.items, function (child, i) {
                                    return _c(
                                      "div",
                                      {
                                        key: i,
                                        staticClass: "element-item",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleSelectElement(
                                              child
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "thumb-box" },
                                          [
                                            child.type === "IMG"
                                              ? _c("el-image", {
                                                  attrs: {
                                                    src: child.imgUrl,
                                                    fit: "scale-down",
                                                  },
                                                })
                                              : _vm._e(),
                                            child.type === "VIDEO"
                                              ? _c("video", {
                                                  attrs: { src: child.url },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c("span", {
                                          staticClass: "check-circle-box",
                                          class: {
                                            "el-icon-check": child.checked,
                                          },
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "element-item-title" },
                                          [_vm._v(_vm._s(child.name))]
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "视频时长", prop: "duration" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.duration,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "duration", $$v)
                    },
                    expression: "form.duration",
                  },
                },
                _vm._l(_vm.durationTypes, function (item, index) {
                  return _c(
                    "el-radio-button",
                    { key: index, attrs: { label: item.key } },
                    [_vm._v(_vm._s(item.text) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm.form.duration === 5
            ? _c(
                "el-form-item",
                { attrs: { label: "自定义视频时长", prop: "times" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "100px" },
                    attrs: {
                      placeholder: "可输入5-120S之间",
                      controls: false,
                      min: 5,
                      max: 120,
                    },
                    model: {
                      value: _vm.form.times,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "times", $$v)
                      },
                      expression: "form.times",
                    },
                  }),
                  _c("span", { staticClass: "tips" }, [
                    _vm._v("可输入5~120S之间"),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "存储位置", prop: "albumId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "180px", "margin-right": "10px" },
                  attrs: { filterable: "", placeholder: "请选择专辑" },
                  on: { change: _vm.handleAlbum },
                  model: {
                    value: _vm.form.albumId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "albumId", $$v)
                    },
                    expression: "form.albumId",
                  },
                },
                _vm._l(_vm.albumList, function (itm, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { label: itm.albumName, value: itm.albumId },
                  })
                }),
                1
              ),
              _c(
                "el-select",
                {
                  staticStyle: { width: "180px", "margin-right": "10px" },
                  attrs: {
                    disabled: !_vm.form.albumId,
                    filterable: "",
                    clearable: "",
                    placeholder: "请选择素材组",
                  },
                  on: { change: _vm.handleMaterialGroup },
                  model: {
                    value: _vm.form.materialGroupId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "materialGroupId", $$v)
                    },
                    expression: "form.materialGroupId",
                  },
                },
                _vm._l(_vm.materialGroupList, function (itm, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: {
                      label: itm.materialGroupName,
                      value: itm.materialGroupId,
                    },
                  })
                }),
                1
              ),
              _c("el-input", {
                staticStyle: { width: "220px" },
                attrs: {
                  disabled: !_vm.form.albumId,
                  placeholder: "请输入素材关键字，空格分隔",
                },
                model: {
                  value: _vm.form.keyword,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "keyword", $$v)
                  },
                  expression: "form.keyword",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c("template", { slot: "label" }, [
                _c("div", [
                  _c("span", { staticStyle: { color: "#F56C6C" } }, [
                    _vm._v("*"),
                  ]),
                  _vm._v(
                    " 源视频(" +
                      _vm._s(_vm.form.videos.length) +
                      "/" +
                      _vm._s(_vm.VIDEO_NUM) +
                      ")"
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "form-image-list" },
                [
                  _vm._l(_vm.form.videos, function (item, index) {
                    return _c("div", { key: index }, [
                      _c(
                        "div",
                        { staticClass: "form-image-list-cell" },
                        [
                          _c("el-progress", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.status === "uploading",
                                expression: "item.status === 'uploading'",
                              },
                            ],
                            staticStyle: { margin: "45px 10px 0 10px" },
                            attrs: {
                              "text-inside": true,
                              "stroke-width": 14,
                              percentage: item.progress,
                              status: item.progress ? "success" : "warning",
                            },
                          }),
                          _c("video", { attrs: { src: item.url } }),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: item.status !== "uploading",
                                  expression: "item.status !== 'uploading'",
                                },
                              ],
                              staticClass: "form-image-list-cell-del",
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  type: "primary",
                                  circle: "",
                                  icon: "el-icon-delete",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDeleteVideo(index)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  }),
                  _vm.form.videos.length < _vm.VIDEO_NUM
                    ? _c(
                        "el-upload",
                        {
                          staticClass: "form-image-upload",
                          attrs: {
                            id: "uploa-video",
                            action: "fakeaction",
                            accept: ".mp4,.avi,.mov",
                            "list-type": "picture-card",
                            multiple: true,
                            limit: _vm.videoLimit,
                            "show-file-list": false,
                            "before-upload": _vm.beforeUploadVideo,
                            "on-exceed": _vm.handleExceedVideo,
                          },
                        },
                        [_c("i", { staticClass: "el-icon-plus" })]
                      )
                    : _vm._e(),
                ],
                2
              ),
              _c("div", { staticClass: "tips" }, [
                _vm._v("格式：mp4、avi、mov"),
              ]),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "form-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.loading || _vm.fileUploading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleConfirm("submit")
                    },
                  },
                },
                [_vm._v("立即提交")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.loading || _vm.fileUploading,
                    plain: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleConfirm("save")
                    },
                  },
                },
                [_vm._v("保存")]
              ),
              _c("el-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }