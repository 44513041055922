<!--
 * @Author: Zeng
 * @Date: 2023-06-08 15:38:48
 * @LastEditTime: 2023-06-16 14:07:18
-->
<template>
  <el-dialog title="导入子客账户" :visible.sync="show" width="800px" :modal-append-to-body="false" destroy-on-close
    @close="closeModal">
    <el-form ref="ruleForm" :model="ruleForm" :inline="true">
      <el-form-item label="服务商" prop="adMarketingApiServiceId">
        <el-select v-model="ruleForm.adMarketingApiServiceId" filterable style="width: 300px;">
          <el-option v-for="(itm, idx) in adMpAmList" :key="idx"
            :label="`${itm.corporationName} ( ${itm.serviceAccountId || '-'} )`"
            :value="`${itm.corporationName}+${itm.adMarketingApiServiceId}+${itm.serviceAccountId}`"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label-width="0">
        <el-button type="primary" :disabled="!ruleForm.adMarketingApiServiceId"
          @click="getAdMpAmsAccountsPage">获取未同步账户</el-button>
      </el-form-item>
    </el-form>

    <el-divider content-position="left">选择账户</el-divider>
    <el-row :gutter="10">
      <el-col :span="12" class="col-border">
        <el-input v-model="ruleForm.accountId" placeholder="请输入账户ID" style="width: 300px" size="small"><el-button
            slot="append" icon="el-icon-search" @click="getAdMpAmsAccountsPage"></el-button></el-input>
        <TablePane ref="multipleTable" class="mt-10" :data-source="dataSource_sync" @getPage="getAdMpAmsAccountsPage" />
      </el-col>
      <el-col :span="12" class="col-border">
        <div class="justify-sb account-select-header">
          <span>已选择投放账户</span>
          <span>已选{{ dataSource_sync_right.length }}个</span>
        </div>
        <el-table v-loading="table_right_loading" :data="dataSource_sync_right" style="width: 100%" class="list-table">
          <el-table-column label="账户名称" align="center" prop="corporationName" show-overflow-tooltip></el-table-column>
          <el-table-column label="账户ID" align="center" prop="accountId" show-overflow-tooltip></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <span class="el-icon-delete icon-delete" @click="handleDelete(scope.$index, scope.row)"></span>
            </template>
          </el-table-column>
        </el-table>
        <div class="page mt-20">
          <el-pagination style="text-align:right" :current-page.sync="currentPage1" :page-size="10"
            layout="prev, pager, next" :total="dataSource_sync_right_all.length" @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </el-col>

      <!-- 账户配置 -->
      <el-col>
        <el-divider content-position="left">账户配置</el-divider>
        <el-form ref="accountConfig" :inline="true" :model="accountConfig" :rules="accountRules"
          class="demo-ruleForm justify-sb" @submit.native.prevent>
          <el-row>
            <el-col :span="12">
              <el-form-item label="项目" prop="projectId">
                <el-select v-model="accountConfig.projectId" placeholder="请选择项目"
                  @change="(value) => (selectProjectId = value)">
                  <el-option v-for="item in projectList" :key="item.bsProjectId" :label="item.projectName"
                    :value="item.bsProjectId" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="产品" prop="productId">
                <el-select v-model="accountConfig.productId" placeholder="请选择产品"
                  @change="(value) => (selectProductId = value)">
                  <el-option label="不限" :value="0"></el-option>
                  <el-option v-for="(itm, idx) in productListed" :key="idx" :label="itm.productName"
                    :value="itm.bsProductId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="组别" prop="departmentId">
                <el-cascader ref="deptRef" v-model="accountConfig.departmentId" :options="deptList" :props="cascaderProp"
                  filterable placeholder="请选择组别" @change="handleSelectDept"></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="投手" prop="userId">
                <el-select v-model="accountConfig.userId" filterable placeholder="请选择投手">
                  <el-option v-for="(itm, idx) in userList" :key="idx" :label="itm.userName"
                    :value="itm.userId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleSubmit">同步</el-button>
      <el-button @click="closeModal">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getHousekeeperSelect, getChildAccountList, syncChildAccount } from '@/network/api/account/tencentAdvAccount.js'
import {
  getDeptList,
  getUserList
} from '@/network/api/toolManagement/api-product-test'
import AccountBaidu from '@/mixin/account_baidu'
import TablePane from '@/components/tablePane.vue' // adMpAmsSelect

export default {
  components: {
    TablePane
  },
  mixins: [AccountBaidu],
  data() {
    return {
      show: false,
      // 服务商
      ruleForm: {
        adMarketingApiServiceId: '',
        // spid: null,
        accountId: null
      },
      // 账号配置
      accountConfig: {
        projectId: null,
        productId: 0,
        departmentId: '',
        userId: ''
      },
      accountRules: {
        // projectId: [
        //   { required: true, message: '请选择项目', trigger: 'change' }
        // ],
        // productId: [
        //   { required: true, message: '请选择产品', trigger: 'change' }
        // ]
      },
      cascaderProp: {
        emitPath: false,
        checkStrictly: true,
        value: 'departmentId',
        label: 'departmentName',
        children: 'children'
      },
      adMpAmList: [], // 服务商list
      deptList: [], // 组别列表
      userList: [], // 用户list
      table_right_loading: false,
      dataSource_sync_right: [],
      dataSource_sync_right_all: [],
      currentPage1: 1,
      dataSource_sync: {
        data: [],
        cols: [
          {
            label: '账户名称',
            with: 100,
            prop: 'corporationName'
          },
          {
            label: '账户ID',
            prop: 'accountId'
          }
        ], // 表格的列数据
        isIndex: false, // 列表序号
        loading: false, // loading
        isOperation: false,
        isSelection: true,
        pageData: {
          total: 0, // 总条数
          paperCount: 5,
          layout: 'prev, pager, next'
        },
        selectAll: (selection) => {
          if (selection.length) {
            this.table_right_loading = true
            this.dataSource_sync_right_all.push(...selection)
            let list = []
            this.dataSource_sync_right_all.map((item) => {
              if (list.length === 0 || !list.some((s) => s.id === item.id)) {
                list.push(item)
              }
            })
            this.dataSource_sync_right_all = list
            this.dataSource_sync_right = [...list].splice(0, 10)
            this.table_right_loading = false
          } else {
            this.dataSource_sync_right_all = []
            this.dataSource_sync_right = []
          }
        },
        select: (selection, row) => {
          if (selection.length > 0) {
            this.table_right_loading = true
            this.dataSource_sync_right_all.push(...selection)
            let list = []
            this.dataSource_sync_right_all.map((item) => {
              if (list.length === 0 || !list.some((s) => s.id === item.id)) {
                list.push(item)
              }
            })
            this.dataSource_sync_right_all = list
            this.dataSource_sync_right = [...list].splice(0, 10)
            this.table_right_loading = false
          }

          if (!selection.some((s) => s.id === row.id)) {
            this.dataSource_sync_right_all = this.dataSource_sync_right_all.filter((f) => f.id !== row.id)
            this.dataSource_sync_right = [...this.dataSource_sync_right_all].splice(0, 10)
            this.table_right_loading = false
          }
        }
      },
      allDataSource: []
    }
  },

  created() { },

  mounted() { },
  methods: {
    showModal(row) {
      if (row) {
        this.ruleForm.adMarketingApiServiceId = `${row.corporationName}+${row.adMarketingApiServiceId}+${row.serviceAccountId}`
      }
      this._getAdMpAm()
      this._getDeptList()
      this.show = true
    },
    closeModal() {
      this.dataSource_sync_right = []
      this.dataSource_sync_right_all = []
      this.dataSource_sync.data = []
      this.dataSource_sync.pageData.total = 0
      this.ruleForm.adMarketingApiServiceId = ''
      this.ruleForm.accountId = null
      this.$refs.ruleForm.resetFields()
      this.show = false
    },

    // 同步
    handleSubmit() {
      if (this.dataSource_sync_right.length === 0) {
        return this.$message.error('请选择同步账户!')
      }
      this.$refs.accountConfig.validate((valid) => {
        if (valid) {
          let node = this.$refs.deptRef.getCheckedNodes()
          let { projectId, productId, departmentId, userId } = this.accountConfig
          let params = {
            adMarketingApiServiceId: +this.ruleForm.adMarketingApiServiceId.split('+')[1],
            ids: this.dataSource_sync_right.map((item) => item.id),
            projectId,
            projectName: this.projectList.filter((item) => item.bsProjectId === projectId)[0].projectName,
            productId: productId !== 0 ? productId : 0,
            productName: productId !== 0 ? this.productListed.filter((item) => item.bsProductId === productId)[0].productName : '不限',
            departmentId,
            departmentName: node.length ? node[0].label : '',
            userId,
            userName: this.userList.length ? this.userList.filter((item) => item.userId === userId)[0].userName : null
          }
          syncChildAccount(params).then((res) => {
            if (res.code === 200) {
              this.dataSource_sync_right = []
              this.dataSource_sync.data = []
              this.closeModal()
              this.$message.success('导入完成!')
            }
          })
        }
      })
    },

    // 获取服务商
    _getAdMpAm(serviceAccountId = 0) {
      getHousekeeperSelect({ serviceAccountId }).then((res) => {
        if (res.code === 200) {
          this.adMpAmList = res.data
        }
      })
    },

    // 获取组别
    _getDeptList() {
      getDeptList({ departmentName: '' }).then(res => {
        let list = res.data.map(v => {
          return {
            departmentId: v.departmentId,
            departmentName: v.departmentName,
            children: v.children ? JSON.parse(v.children) : []
          }
        })
        this.deptList = list || []
      })
    },

    // 获取成员列表
    _getUserList() {
      getUserList({ departmentId: this.accountConfig.departmentId, userName: '' }).then((res) => {
        if (res.code === 200) {
          this.userList = res.data
        }
      })
    },

    // 获取同步数据
    getAdMpAmsAccountsPage(params = { pageNum: 1, pageSize: 10 }) {
      this.dataSource_sync.loading = true
      this.$refs['multipleTable'].getRowData([])
      let search = {
        adMarketingApiServiceId: this.ruleForm.adMarketingApiServiceId.split('+')[1],
        accountId: this.ruleForm.accountId || 0,
        pageNum: params.pageNum || 1,
        pageSize: params.pageSize || 10
      }
      getChildAccountList(search).then((res) => {
        const { records, total } = res.data
        this.dataSource_sync.data = records
        this.dataSource_sync.pageData.total = total
        this.dataSource_sync.loading = false

        let rowData = []
        res.data.map((item) => {
          this.dataSource_sync_right_all.map((item2) => {
            if (item.id === item2.id) {
              rowData.push(item)
            }
          })
        })
        this.$refs['multipleTable'].getRowData(rowData)
      }).catch(e => {
        this.dataSource_sync.loading = false
      })
    },

    // 切换分页
    handleCurrentChange(current) {
      let list = [...this.dataSource_sync_right_all]
      this.dataSource_sync_right = list.splice((current - 1) * 10, current * 10)
    },

    // 选择部门
    handleSelectDept() {
      this.accountConfig.userId = null
      this._getUserList()
    },

    // 删除选中账户
    handleDelete(index, row) {
      this.$refs['multipleTable'].getRowData([])
      this.dataSource_sync_right = this.dataSource_sync_right.filter((f) => f.id !== row.id)
      this.dataSource_sync_right_all = this.dataSource_sync_right_all.filter((f) => f.id !== row.id)
      this.$refs['multipleTable'].getRowData(this.dataSource_sync_right_all)
    }
  }
}
</script>

<style scoped lang="scss">
.el-dialog__wrapper .el-dialog .el-dialog__body .el-form {
  width: 100%;
}

.col-border {
  // border: solid 1px #E4E7ED;
  // padding: 10px 0;
  // box-sizing: border-box;
}

.account-select-header {
  padding: 10px 0;

  span:last-child {
    color: #878787;
    font-size: 12px;
    position: relative;
    top: 2px;
  }
}

.icon-delete {
  color: #f00;
}
</style>
