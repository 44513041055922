<template>
  <!-- @wheel="onMouseWheel" -->
  <section class="v-crop-box" @wheel="onMouseWheel">
    <img ref="imageRef" crossOrigin="anonymous" :class="{ 'v-crop-img': true, 'v-crop-img--moving': didMouseDown }" :src="src" :style="style" alt="img" draggable="false" @load="(e) => onLoad(e)" />
    <div :class="{ 'v-crop-selection-active': didMouseDown, 'v-crop-selection': true }" :style="cropStyle"></div>
  </section>
</template>

<script>
import { debounce } from '../../utils/ruoyi'
export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    image: {
      type: Object,
      default: () => {
        return {
          width: 300,
          height: 300
        }
      }
    },
    target: {
      type: Object,
      default: () => {}
    },
    maxSize: {
      type: Number,
      default: 300
    },
    crop: {
      type: Object,
      default: () => {}
    },
    natural: {
      type: Object,
      default: () => {}
    },
    /** 宽度范围取值 */
    rangeWidth: {
      type: Array,
      default: () => []
    },
    /** 高度范围, 元组表示范围 */
    rangeHeight: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      transformX: 0,
      transformY: 0,
      didMouseDown: false
    }
  },
  computed: {
    style() {
      return {
        width: `${this.image.width}px`,
        height: `${this.image.height}px`,
        transform: `translate(${this.transformX}px, ${this.transformY}px)`
      }
    },
    cropStyle() {
      return {
        width: `${this.crop.width}px`,
        height: `${this.crop.height}px`
      }
    },
    largestOffset() {
      const { image, crop } = this
      let x = (image.width - crop.width) / 2
      let y = (image.height - crop.height) / 2
      return { x, y }
    }
  },
  watch: {
    image: {
      handler() {
        this.restore()
      }
    },
    src: {
      handler() {
        this.transformY = 0
        this.transformX = 0
      }
    }
  },
  mounted() {
    this.bindEvent()
  },
  beforeDestroy() {
    this.offEvent()
  },
  methods: {
    onLoad(e) {
      this.$emit('onImageLoaded', e)
    },
    /** 绑定事件 */
    bindEvent() {
      this.$refs.imageRef.addEventListener('mousedown', this.onMouseDown)
    },

    /** 解绑事件 */
    offEvent() {
      this.$refs.imageRef.removeEventListener('mousedown', this.onMouseDown)
    },

    /**
     * 鼠标滚动事件用来做图片的缩放, 以及图片的最大偏移量
     * 如果
     */
    onMouseWheel(e) {
      this.$emit('onMouseWheel', e)
    },
    /** 按下移动图片 */
    onMouseDown(e) {
      // 初始变换值
      let { transformX, transformY } = this

      const { x, y } = this.largestOffset

      let originX = e.pageX
      let originY = e.pageY

      this.didMouseDown = true

      // 慢速的倍率
      let slowSpeedRatio = 10

      // 移动
      const onMouseMove = (e) => {
        // 鼠标移动的距离
        let disX = e.pageX - originX
        let disY = e.pageY - originY

        // 偏移量 = 按下之前的偏移量 + 鼠标移动的距离
        let targetX = transformX + disX
        let targetY = transformY + disY

        // 偏移量绝对值, 如果超过限制的最大偏移量则减速
        let absTargetX = Math.abs(targetX)
        let absTargetY = Math.abs(targetY)

        // 超出最大水平偏移量
        if (absTargetX > x) {
          let changed = x + (absTargetX - x) / slowSpeedRatio
          targetX = targetX < 0 ? -changed : changed
        }
        // 超出最大垂直偏移量
        if (absTargetY > y) {
          let changed = y + (absTargetY - y) / slowSpeedRatio
          targetY = targetY < 0 ? -changed : changed
        }

        this.transformX = targetX
        this.transformY = targetY
      }

      // 松开
      const onMouseUp = () => {
        let { transformX, transformY } = this
        // 超出最大水平偏移量
        if (Math.abs(transformX) > x) {
          this.transformX = transformX < 0 ? -x : x
        }
        // 超出最大垂直偏移量
        if (Math.abs(transformY) > y) {
          this.transformY = transformY < 0 ? -y : y
        }

        this.didMouseDown = false

        document.removeEventListener('mousemove', onMouseMove)
        document.removeEventListener('mouseup', onMouseUp)
      }

      document.addEventListener('mousemove', onMouseMove)
      document.addEventListener('mouseup', onMouseUp)
    },
    /** 修正图片偏移量, 使其一直填满裁切区域 */
    restore() {
      debounce(() => {
        let { transformX, transformY } = this.state
        const { x, y } = this.largestOffset
        Math.abs(transformX) > x &&
          this.setState({
            transformX: transformX < 0 ? -x : x
          })
        Math.abs(transformY) > y &&
          this.setState({
            transformY: transformY < 0 ? -y : y
          })
      }, 200)
    },

    /**
     * 图片裁切
     * @param element 裁切的元素
     */
    async cropImage() {
      const element = this.$refs.imageRef
      const { crop, target } = this
      const { x, y } = this.largestOffset
      const { transformX, transformY } = this
      const scaleRatio = element.naturalWidth / element.width

      const canvas = document.createElement('canvas')
      canvas.width = target.width
      canvas.height = target.height
      const ctx = canvas.getContext('2d')

      const sx = (transformX > 0 ? x - transformX : 2 * x) * scaleRatio
      const sy = (transformY > 0 ? y - transformY : 2 * y) * scaleRatio

      const sw = (crop.width / element.width) * element.naturalWidth
      const sh = (crop.height / element.height) * element.naturalHeight

      ctx.drawImage(
        element,

        sx,
        sy,

        sw,
        sh,

        0,
        0,

        target.width,
        target.height
      )
      return this.toBlob(canvas)
    },
    toBlob(canvas, quality = 0.92, name) {
      const { maxSize } = this
      return new Promise((resolve, reject) => {
        canvas.toBlob(
          (blob) => {
            if (!blob) {
              return reject(blob)
            }
            const file = new File([blob], name || 'img', {
              type: 'image/jpeg'
            })
            if (maxSize > 0 && file.size > maxSize * 1024) {
              quality *= +((maxSize * 1024) / file.size).toFixed(2)
              return this.toBlob(canvas, quality, name).then((res) => resolve(res))
            }

            resolve(file)
          },
          'image/jpeg',
          quality
        )
      })
    }
  }
}
</script>

<style lang="scss" scoped>
// 画布宽高
$s: 400px;
// list和info 的左边距
$ml: 12px;
// list 宽
$lw: 100px;

.v-crop-box {
  background-color: #ccc;
  width: 400px;
  height: 400px;
  overflow: hidden;
  position: relative;
}

.v-crop-img,
.v-crop-selection {
  position: absolute;
  left: -10000px;
  right: -10000px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.v-crop-img {
  cursor: move;
  transition: transform 0.3s cubic-bezier(0.68, 0, 0.27, 1.25);

  .v-crop-moving {
    transition: none;
  }
}

.v-crop-selection {
  box-shadow: 0 0 0 1000px rgba($color: #000000, $alpha: 0.7);
  border: 1px dashed #fff;
  border-radius: 4px;
  // pointer-events: none;
  transition: box-shadow 0.3s;
  .v-crop-selection-active {
    box-shadow: 0 0 0 1000px rgba($color: #000000, $alpha: 0.5);
  }
}
</style>
