<!--
 * @Description: (大多数地方用这个Video选择框)
 * @Date: 2021-10-11 13:56:59
 * @LastEditTime: 2023-03-09 17:12:21
-->
<template>
  <div class="material-video-box">
    <el-dialog title="视频选择" :visible="visible" :width="`1400px`" top="100px" :before-close="close"
      :destroy-on-close="true" :modal-append-to-body="false">
      <!-- 内容开始 -->
      <el-row class="selected-img" :gutter="20">
        <!-- 左边树形筛选框 -->
        <el-col :span="4">
          <el-col :span="3" class="tree-box">
            <el-input v-model="filterText" placeholder="输入关键字进行过滤" size="small" style="width: 200px"> </el-input>
            <div class="tree">
              <el-tree ref="tree" :data="adAlbum" lazy :load="loadNode" :props="defaultProps"
                :filter-node-method="filterNode" @node-click="nodeClick"></el-tree>
            </div>
          </el-col>
        </el-col>

        <!-- 右边内容去 -->
        <el-col :span="20">
          <!-- 右上搜索框 -->
          <el-row>
            <el-col :span="22">
              <el-form ref="videoQuery" :inline="true" :model="videoQuery" style="marginLeft: 10px">
                <el-form-item label="上传人: ">
                  <el-input v-model="videoQuery.nickname" placeholder="请输入上传人" style="width: 150px"></el-input>
                </el-form-item>
                <el-form-item label="宽: ">
                  <el-input v-model="videoQuery.width" placeholder="请输入宽" style="width: 120px"></el-input>
                </el-form-item>
                <el-form-item label="高: ">
                  <el-input v-model="videoQuery.height" placeholder="请输入高" style="width: 120px"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="onSubmit">查询</el-button>
                  <el-button @click="resetForm">重置</el-button>
                </el-form-item>
                <el-form-item v-if="selectAll" class="ml-20">
                  <el-checkbox v-model="selectedImgCheckbox" :disabled="selectAllDisabled"
                    @change="selectAllFun">全选当前素材组下视频</el-checkbox>
                </el-form-item>
              </el-form>
            </el-col>
            <el-col :span="2">
              <el-button @click="onClickAddMaterial(0)">新增素材</el-button>
            </el-col>
          </el-row>

          <!-- 右下内容选择区 -->
          <el-row :gutter="20">
            <el-col :span="18">
              <ul v-if="videoList.length > 0" v-loading="videoLoading" element-loading-text="视频获取中" style="margin: 0"
                class="select-video">
                <el-row>
                  <el-col v-for="item in videoList" :key="item.videoId" :span="6">
                    <li :class="{ active: selectedVideoList.some((s) => s.videoId === item.videoId) }"
                      @click="selectVideo(item)">
                      <div class="video-div">
                        <video :src="item.url"></video>
                      </div>
                      <div class="attachments" :class="{ 'success-color': successColor(item) }">
                        <p>{{ item.materialName }}</p>
                        <div>
                          <p>{{ item.width }}px * {{ item.height }}px</p>
                          <p>{{ (item.size / 1024 / 1024).toFixed(2) }}MB</p>
                        </div>
                      </div>
                      <i class="el-icon-success"></i>
                    </li>
                  </el-col>
                </el-row>
                <el-pagination background layout="prev, pager, next" :total="videoListTotal" :page-size="8"
                  style="text-align: right;" :current-page.sync="videoQuery.pageNum" @current-change="currentChange"
                  @prev-click="currentChange" @next-click="currentChange"> </el-pagination>
              </ul>
              <ul v-else style="margin: 0" class="select-img">
                <el-empty description="暂无数据"></el-empty>
              </ul>
            </el-col>
            <el-col :span="6">
              <el-row class="selected-video">
                <template v-if="!isBatch && !isPage">
                  <el-col v-for="(item, index) in new Array(imgCount)" :key="index" :span="12">
                    <section class="selected-video-item">
                      <div>
                        <template>
                          <el-avatar v-if="!(selectedVideoList[index] && selectedVideoList[index].videoUrl)"
                            shape="square" :size="100" class="avatar" icon="el-icon-video-camera-solid"></el-avatar>
                          <div v-else class="avatar-video">
                            <video style="width:100%; height:100%"
                              :src="selectedVideoList[index] && selectedVideoList[index].videoUrl"></video>
                          </div>
                        </template>
                        <div class="avatar-mask">
                          <p @click="handleImg(index, 'updata')">替换</p>
                          <p @click="handleImg(index, 'delete')">删除</p>
                        </div>
                      </div>
                    </section>
                  </el-col>
                </template>
                <template v-else>
                  <el-col v-for="(item, index) in selectedVideoList" :key="index" :span="12">
                    <section class="selected-video-item">
                      <div>
                        <template>
                          <el-avatar v-if="!item.url" shape="square" :size="100" class="avatar"
                            icon="el-icon-video-camera-solid"></el-avatar>
                          <div v-else class="avatar-video">
                            <video style="width:100%; height:100%" :src="item.url"></video>
                          </div>
                        </template>
                        <div class="avatar-mask">
                          <p @click="handleImg(index, 'updata')">替换</p>
                          <p @click="handleImg(index, 'delete')">删除</p>
                        </div>
                      </div>
                    </section>
                  </el-col>
                </template>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <!-- 内容结束 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="select">确 定</el-button>
      </div>
    </el-dialog>
    <AddMaterialDialog ref="addMaterialDialog" :type="'video'" add-position="1" @edit="onSubmit"></AddMaterialDialog>
  </div>
</template>

<script>
import { getMaterialList_video } from '@/network/api/advManagement/advManagementBaiduCreativeFend'
import AddMaterialDialog from '@/components/materialManagement/material/AddMaterialDialog'
import { getAdAlbum, getAdMaterialGroup } from '@/network/api/account/materents'
export default {
  components: { AddMaterialDialog },
  props: {
    name: {
      type: String,
      default: 'video'
    },
    visible: {
      type: Boolean,
      default: false
    },
    // 最多上传数量
    imgCount: {
      type: Number,
      default: 6
    },
    // 已上传视频
    videoListed: {
      type: Array,
      default: () => []
    },
    // 视频属性用作验证视频是否符合

    // key: 'topImg', // 唯一key
    // value: '顶部视频',  //唯一name
    // count: 1, // 本次最多上传几个
    // size: 100 * 1024 * 1024,
    // listType: 'image/jpg,image/jpeg,image/png',
    // width: 640,
    // height: 480,
    // fixedHeight: [480, 360]  // 固定高度,当有超过一个固定高度时调用

    attribute: {
      type: Object,
      default: () => { }
    },
    // 项目id
    projectId: {
      type: Array,
      default: () => []
    },
    selectAll: {
      type: Boolean,
      default: false
    },
    // 尺寸有多种选择 "multiple"
    selectType: {
      type: String,
      default: ''
    },
    // 是否批量添加
    isBatch: {
      type: Boolean,
      default: false
    },
    // 是否为落地页模板素材
    isPage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedVideoList: [], // 选中的视频
      updateVideoIndex: -1, // 替换视频index
      UPLOAD_VIDEO_URL: process.env.VUE_APP_UPLOAD_VIDEO_URL,
      innerVisible: false,
      selectedImgCheckbox: false,
      selectAllDisabled: true, // 是否可以全选
      videoList: [], // 视频列表
      videoListTotal: 0, // 视频数量
      adAlbum: [], // 专辑名称
      filterText: '', // 筛选专辑
      defaultProps: {
        children: 'children',
        label: 'label',
        isLeaf: 'leaf'
      },
      videoLoading: false, // 视频加载
      videoQuery: {
        // 视频搜索条件
        nickname: '',
        albumId: 0,
        width: null,
        height: null,
        pageNum: 1,
        type: 'video'
      }
    }
  },
  watch: {
    visible: {
      handler() {
        this.selectedVideoList = this.videoListed.map(v => {
          return {
            ...v,
            videoId: v.videoId || v.materialId,
            url: v.url || v.sightVideoUrl
          }
        })
        this.selectedImgCheckbox = false
        this.selectAllDisabled = true
      },
      immediate: true
    },
    // 筛选专辑
    filterText(val) {
      this.$refs.tree.filter(val)
    },

    attribute: {
      handler(val) {
        this.videoQuery.width = typeof val.width === 'number' ? val.width : ''
        this.videoQuery.height = typeof val.height === 'number' ? val.height : ''
      }
    }
  },
  methods: {
    // 点击编辑/新增素材
    onClickAddMaterial() {
      this.$refs.addMaterialDialog.showModal()
    },

    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    /**
     * @description: 点击树形选择调用
     * @param {*} value
     * @return {*}
     */
    nodeClick(value) {
      const { albumId, materialGroupId, typeName } = value
      this.videoQuery.albumId = albumId
      // this.imageQuery.materialGroupId = materialGroupId
      this.videoQuery.materialGroupId = typeName === 'materialGroup' ? materialGroupId : null
      this.videoQuery.pageNum = 1
      this.getMaterialListFun()
    },
    /**
     * @description: 获取tree组件数据
     * @param {*} node 上下文
     * @param {*} resolve 设置数据
     */
    loadNode(node, resolve) {
      // 获取专辑
      if (node.level === 0) {
        getAdAlbum({ type: 'video', projectIds: this.projectId }).then(({ code, data }) => {
          if (code === 200) {
            data.forEach((f) => {
              f.label = f.albumName
              f.typeName = 'album'
            })
            return resolve(data)
          }
        })
      }
      // 获取素材组
      const { albumId } = node.data
      if (node.level === 1) {
        getAdMaterialGroup({ type: 'video', albumId }).then(({ code, data }) => {
          if (code === 200) {
            data &&
              data.forEach((f) => {
                f.label = f.materialGroupName
                f.typeName = 'materialGroup'
                f.leaf = true
              })
            return resolve(data)
          } else {
            return resolve([])
          }
        })
      }
    },
    /**
     * @description: 获取视频列表
     * @param {*} current 当前页
     */
    getMaterialListFun() {
      this.videoLoading = true
      getMaterialList_video(this.videoQuery).then((res) => {
        if (res.code === 200) {
          this.videoList = res.data.list
          this.videoListTotal = res.data.total
          this.videoLoading = false
          this.selectedImgCheckbox = false

          if (this.videoQuery.materialGroupId && this.videoList.length) {
            this.selectAllDisabled = false
          } else {
            this.selectAllDisabled = true
          }
        }
      })
    },

    /**
     * @description: 查询视频
     */
    onSubmit() {
      this.getMaterialListFun()
    },

    /**
     * @description: 重置
     */
    resetForm() {
      this.$refs['videoQuery'].resetFields()
      this.videoQuery.nickname = ''
      this.videoQuery.width = null
      this.videoQuery.height = null
      this.videoQuery.pageNum = 1
      this.getMaterialListFun()
    },

    /**
     * @description: 切换分页
     * @param {*} current 当前页
     */
    currentChange(current) {
      this.getMaterialListFun(current)
    },

    successColor(item) {
      // <!-- attribute.width === item.width && attribute.height === item.height && attribute.size >= parseInt(item.size) -->
      const { size, width, height } = this.attribute
      if (size < item.size / 1024) {
        return false
      } else {
        if (typeof width === 'number') {
          if (width !== item.width || height !== item.height) {
            return false
          } else {
            return true
          }
        } else {
          if (!width.includes(item.width) || !height.includes(item.height)) {
            return false
          } else {
            return true
          }
        }
      }
    },

    // 验证视频是否合规
    verification(info) {
      const { size, width, height } = this.attribute
      if (size < info.size / 1024) {
        return `视频不能大于${size}KB`
      }
      if (width === null && height === null) {
        return ''
      }
      if (typeof width === 'number' && width !== info.width) {
        return `选择的视频宽度应为${width}px!`
      }
      if (typeof height === 'number' && height !== info.height) {
        return `选择的视频高度应为${height}px!`
      }

      // 区间形式
      if (Array.isArray(width) && width.length === 2 && !(width[0] < info.width < width[1])) {
        return `选择的视频宽度应为${width[0]}-${width[1]} `
      }
      // 区间形式
      if (Array.isArray(height) && height.length === 2 && !(height[0] < info.width < height[1])) {
        return `选择的视频高度应为${height[0]}- ${height[1]}`
      }
      return ''
    },

    /*
     * @description: 验证多种选择视频
     * @param {*} selectImg 选择框里的视频信息
     * @return {*}
     */
    verificationMultiple(selectImg) {
      const { size, width, height } = this.attribute
      // 大于10000表示没有限制
      if (width === 10000 || height === 10000) {
        return true
      }

      // 验证尺寸
      if (size < selectImg.size) {
        return `视频不能大于${size / 1024}KB`
      }
      // 固定宽高 widths 数组的情况[1028]
      if (Array.isArray(width)) {
        let width_height = []
        for (let index = 0; index < width.length; index++) {
          const widthItem = width[index]
          const heightItem = height[index]
          width_height.push(`${widthItem}-${heightItem}`)
        }

        if (!width_height.includes(`${selectImg.width}-${selectImg.height}`)) {
          return `选择的视频宽度应该为${width_height} `
        }
      } else {
        if (width !== selectImg.width || height !== selectImg.height) {
          return `选择的视频宽度应该为${width} * ${height} `
        }
      }
    },

    // 选择视频
    selectVideo(item) {
      const { selectedVideoList, updateVideoIndex, selectType, attribute } = this
      const message = selectType === 'multiple' ? this.verificationMultiple(item) : this.verification(item)
      // debugger
      if (message) return this.$message.error(message)
      // 替换视频
      if (updateVideoIndex !== -1) {
        selectedVideoList.splice(updateVideoIndex, 1, { url: item.url, videoId: +item.videoId, ...item })
      } else {
        // 添加视频
        const video = {
          videoUrl: item.url,
          videoId: +item.videoId,
          width: item.width,
          height: item.height,
          poster: []
        }

        // 1. 判断是否已经选中.如果选中就取消选中
        if (!selectedVideoList.some((s) => s.videoId === item.videoId)) {
          if (this.isBatch) {
            // 批量添加
            selectedVideoList.push({ url: item.url, videoId: +item.videoId, ...item })
          } else {
            if (this.imgCount === 1) {
              this.selectedVideoList = [{ url: video.videoUrl, ...video }]
              return
            }
            if (this.isPage) {
              if (selectedVideoList.length >= attribute.count) {
                this.$message.warning(`视频最多选择${attribute.count}个`)
              } else {
                selectedVideoList.push({ url: video.videoUrl, ...video })
              }
            } else {
              if (selectedVideoList.length === 0) {
                // 第一个视频随意添加
                selectedVideoList.push(video)
              } else if (selectedVideoList.length > 0 && selectedVideoList.length < 7) {
                selectedVideoList.push(video)
                this.$emit('clearvideo')
              } else {
                this.$message.warning(`视频最多选择6个`)
              }
            }
          }
        } else {
          selectedVideoList.splice(
            selectedVideoList.findIndex((s) => s.videoId === item.videoId),
            1
          )
        }
      }
      this.updateVideoIndex = -1
    },

    // 删除/替换视频
    handleImg(index, type) {
      if (!this.selectedVideoList.length) return this.$message.error('请先选择视频!')
      if (type === 'delete') {
        this.selectedVideoList.splice(index, 1)
      } else {
        this.updateVideoIndex = index
        this.$message.success('请选择要替换的视频!')
      }
    },

    /**
     * @description: 全选当前页面下视频
     */
    async selectAllFun(flag) {
      this.selectedImgCheckbox = true
      if (flag) {
        const { attribute, selectedVideoList } = this
        let videoList = []
        const { code, data } = await getMaterialList_video({ ...this.videoQuery, pageSize: 50 })
        if (code === 200) {
          data.list.forEach((item) => {
            // 判断尺寸
            if (attribute.width === item.width && attribute.height === item.height && attribute.size >= parseInt(item.size)) {
              // 判断是否已存在
              if (!selectedVideoList.some((s) => s.videoId === item.videoId)) {
                videoList.push({ image: item.url, videoId: +item.videoId, ...item })
              }
            }
          })
          this.selectedVideoList = [...selectedVideoList, ...videoList]
          this.imgCountData = this.selectedVideoList.length
        }
      } else {
        this.selectedImgCheckbox = false
        // this.selectedVideoList = []
        let list = this.videoList.map((item) => item.videoId)
        this.selectedVideoList = this.selectedVideoList.filter((f) => !list.includes(f.videoId))
        this.imgCountData = this.selectedVideoList.length
      }
    },

    // 关闭弹框
    close() {
      this.$emit('setMaterialBox')
    },

    select() {
      let videos = [...this.selectedVideoList]
      this.$emit('handleVideoSuccess', videos)
      this.close()
    }
  }
}
</script>

<style scoped lang="scss">
.material-video-box {
  .success-color {
    color: #409eff !important;
  }

  .selected-video {
    height: 439px;
    overflow-y: auto;

    .selected-video-item {
      width: 100px;
      height: 100px;

      .avatar-video {
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        margin-top: 10px;
        background: #c0c4cc;
        border-radius: 4px;

        video {
          width: 100%;
        }
      }

      &>div {
        position: relative;

        .avatar-mask {
          width: 100px;
          height: 100px;
          background: rgba(0, 0, 0, 0.507);
          position: absolute;
          top: 0px;
          left: 10px;
          border-radius: 4px;
          display: none;

          p {
            margin-top: 20px;
            color: #fff;
            text-align: center;
            cursor: pointer;
          }
        }

        &:hover .avatar-mask {
          display: block;
        }
      }
    }

    .avatar {
      margin: 10px;
    }
  }

  .tree-box {
    width: 100%;
    height: 500px;

    .tree {
      width: 100%;
      height: 460px;
      margin-top: 10px;
      overflow-y: auto;
    }
  }

  .select-video {
    li {
      border: 1px solid #ddd;
      margin: 10px;
      padding: 7px 3px 6px 3px;
      border-radius: 6px;
      cursor: pointer;
      position: relative;

      .video-div {
        background: #00000014;
      }

      video {
        width: 100%;
        height: 160px;
        margin: 0;
        display: flex;
        justify-content: center;
        background: none;
      }

      .attachments {
        padding: 5px;
        margin-top: px;

        >div {
          display: flex;
          justify-content: space-between;
          margin-top: 8px;
        }

        p {
          padding: 0;
          margin: 0;
        }
      }

      .el-icon-success {
        position: absolute;
        top: -10px;
        left: -10px;
        font-size: 20px;
        color: #409eff;
        display: none;
        background: #fff;
      }
    }

    .active {
      border-color: #409eff;

      .el-icon-success {
        display: block;
      }
    }
  }
}
</style>
