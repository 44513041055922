<!--
 * @Description: 选择推广单元等框
 * @Date: 2021-11-09 09:19:23
 * @LastEditTime: 2021-12-27 09:48:08
-->

<template>
  <div class="dialog">
    <el-dialog :title="dialogTitle" :visible="visible" center width="70%" :modal-append-to-body="false" destroy-on-close @close="onClose">
      <span>
        <!-- onlineQuery: {{ onlineQuery }} -->
        <el-tabs v-model="active" @tab-click="handleClick">
          <el-tab-pane v-for="(item, index) in tabPane" :key="index" :label="item.title" :name="item.name" :disabled="item.disabled"> </el-tab-pane>
        </el-tabs>
        <!-- eslint-disable-line -->
        <SeleteModalTree ref="modalTree" :visible="visible" :data-source="dataSource" :selected="selected" :left-title="leftTitle" :dialog-title="dialogTitle" :local-data="localData" :online-data="onlineData" :online-query="onlineQuery" :local-query="localQuery" @getSelectedData="getSelectedData" />
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onClose">取 消</el-button>
        <el-button type="primary" @click="onOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import SeleteModalTree from './seleteModalTree.vue'
export default {
  inject: ['close'],
  components: {
    SeleteModalTree
  },
  props: {
    dialogTitle: {
      type: String,
      default: ''
    },
    leftTitle: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    },
    tabPane: {
      type: Array,
      default: () => []
    },
    // 模板数据
    localData: {
      type: Array,
      default: () => []
    },
    // 线上数据
    onlineData: {
      type: Array,
      default: () => []
    },
    // 线上左侧数据
    onlineQuery: {
      type: Array,
      default: () => []
    },
    // 模板左侧数据
    localQuery: {
      type: Array,
      default: () => []
    },
    dataSource: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selected: this.tabPane.filter((item) => item.disabled === undefined || item.disabled === false)[0],
      active: this.tabPane.filter((item) => item.disabled === undefined || item.disabled === false)[0].name,
      templateData: [] // 选中的数据
    }
  },
  watch: {
    visible(value) {
      if (value) {
        this.templateData = []
      }
    }
  },
  methods: {
    handleClick(tab) {
      const { name } = tab
      let a = this.tabPane.filter((f) => f.name === name)[0]
      this.selected = a
      this.active = a.name
    },

    /**
     * @description 获取最后选择的计划数据
     * @param {data} 模板数据
     */
    getSelectedData(data) {
      this.templateData = data
    },

    /**
     * @description 关闭弹框
     */
    onClose() {
      this.selected = this.tabPane[0]
      this.active = this.tabPane[0].name
      this.close()
      this.$refs.modalTree.resetData()
    },

    /**
     * @description 弹框 => 点击确定
     */
    onOk() {
      this.onClose()
      this.$emit('onOk', this.templateData)
    }
  }
}
</script>

<style lang="scss">
.dialog {
  .el-tabs .el-tabs__item.is-active {
    border-top: solid 0px #409eff;
  }
}
</style>

<style lang="scss" scoped>
p {
  margin: 0;
  padding: 0;
}
</style>
