var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "base-box",
        { attrs: { name: "enterprise_wx_auto" } },
        [
          _c(
            "template",
            { slot: "body" },
            [
              _c("el-divider"),
              _c("div", [
                _c("p", { staticClass: "w-100 font-13" }, [
                  _vm._v("客服类型："),
                ]),
                _c(
                  "div",
                  { staticClass: "ml-20" },
                  [
                    _c("el-radio", { attrs: { value: "1", label: "1" } }, [
                      _vm._v("企业微信客服"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c("p", { staticClass: "w-100 font-13" }, [
                  _vm._v("添加类型："),
                ]),
                _c(
                  "div",
                  { staticClass: "ml-20", staticStyle: { display: "flex" } },
                  [
                    _c("el-radio", { attrs: { value: "1", label: "1" } }, [
                      _vm._v("客服"),
                    ]),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c("p", { staticClass: "w-100 font-13" }, [
                  _vm._v("客服分配："),
                ]),
                _c("div", { staticClass: "ml-20" }, [
                  _c("p", { staticClass: "tip" }, [
                    _vm._v("广告模板发布时添加"),
                  ]),
                ]),
              ]),
              _c("el-divider"),
              _c("div", [
                _c("p", { staticClass: "w-100 font-13" }, [
                  _vm._v("按钮文案："),
                ]),
                _c(
                  "div",
                  { staticClass: "ml-20", staticStyle: { display: "flex" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "210px" },
                      attrs: { maxlength: "10", "show-word-limit": "" },
                      model: {
                        value: _vm.detail.btnTitle,
                        callback: function ($$v) {
                          _vm.$set(_vm.detail, "btnTitle", $$v)
                        },
                        expression: "detail.btnTitle",
                      },
                    }),
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "ml-20",
                        attrs: { size: "small" },
                        model: {
                          value: _vm.detail.btnFontType,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "btnFontType", $$v)
                          },
                          expression: "detail.btnFontType",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: "0" } }, [
                          _vm._v("常规"),
                        ]),
                        _c("el-radio-button", { attrs: { label: "1" } }, [
                          _vm._v("加粗"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c("p", { staticClass: "w-100 font-13" }, [
                  _vm._v("按钮字体色："),
                ]),
                _c(
                  "div",
                  { staticClass: "ml-20" },
                  [
                    _c(
                      "el-dropdown",
                      [
                        _c("div", {
                          staticClass: "ldy-bg-color",
                          style: `background:${_vm.detail.fontColor}`,
                        }),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c("twitter-color", {
                              staticStyle: { "margin-top": "10px" },
                              attrs: { colors: _vm.detail.fontColor },
                              on: {
                                change: (value) =>
                                  (_vm.detail.fontColor = value),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c("p", { staticClass: "w-100 font-13" }, [
                  _vm._v("按钮边框色："),
                ]),
                _c(
                  "div",
                  { staticClass: "ml-20" },
                  [
                    _c(
                      "el-dropdown",
                      [
                        _c("div", {
                          staticClass: "ldy-bg-color",
                          style: `background:${_vm.detail.btnBorderColorTheme}`,
                        }),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c("twitter-color", {
                              staticStyle: { "margin-top": "10px" },
                              attrs: { colors: _vm.detail.btnBorderColorTheme },
                              on: {
                                change: (value) =>
                                  (_vm.detail.btnBorderColorTheme = value),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c("p", { staticClass: "w-100 font-13" }, [
                  _vm._v("按钮填充色："),
                ]),
                _c(
                  "div",
                  { staticClass: "ml-20" },
                  [
                    _c(
                      "el-dropdown",
                      [
                        _c("div", {
                          staticClass: "ldy-bg-color",
                          style: `background:${_vm.detail.btnBgColorTheme}`,
                        }),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c("twitter-color", {
                              staticStyle: { "margin-top": "10px" },
                              attrs: { colors: _vm.detail.btnBgColorTheme },
                              on: {
                                change: (value) =>
                                  (_vm.detail.btnBgColorTheme = value),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c("p", { staticClass: "w-100 font-13" }, [_vm._v("上边距：")]),
                _c(
                  "div",
                  { staticClass: "ml-20" },
                  [
                    _c("el-slider", {
                      attrs: { "show-input": "" },
                      model: {
                        value: _vm.detail.paddingTop,
                        callback: function ($$v) {
                          _vm.$set(_vm.detail, "paddingTop", $$v)
                        },
                        expression: "detail.paddingTop",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c("p", { staticClass: "w-100 font-13" }, [_vm._v("下边距：")]),
                _c(
                  "div",
                  { staticClass: "ml-20" },
                  [
                    _c("el-slider", {
                      attrs: { "show-input": "" },
                      model: {
                        value: _vm.detail.paddingBottom,
                        callback: function ($$v) {
                          _vm.$set(_vm.detail, "paddingBottom", $$v)
                        },
                        expression: "detail.paddingBottom",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }