var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _vm.detail.content === ""
      ? _c("div", { staticClass: "copyGh" }, [
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c("i", { staticClass: "el-icon-document-copy" }),
            _c("p", [_vm._v(_vm._s(_vm.detail.name) + "组件")]),
          ]),
        ])
      : _c(
          "div",
          {
            style: {
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: `${_vm.detail.paddingTop}px`,
              paddingBottom: `${_vm.detail.paddingBottom}px`,
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "button",
                style: {
                  background: _vm.detail.btnBgColorTheme,
                  color: _vm.detail.btnFontColor,
                  border: `1px solid ${_vm.detail.btnBorderColorTheme}`,
                  fontWeight: _vm.detail.btnFontType,
                },
              },
              [
                _c("p", {
                  domProps: { innerHTML: _vm._s(_vm.detail.btnTitle) },
                }),
              ]
            ),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }