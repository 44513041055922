/*
 * @Description: 基础数据
 * @Date: 2021-10-18 11:53:23
 * @LastEditTime: 2022-03-16 09:21:55
 */
export let widgets = [
  {
    type: '基础组件',
    title: '图片',
    name: 'img'
  },
  {
    type: '基础组件',
    title: '轮播图',
    name: 'slider'
  },
  {
    type: '基础组件',
    title: '文本',
    name: 'text'
  },
  {
    type: '转化按钮',
    title: '复制公众号',
    name: 'copyGh'
  },
  {
    type: '转化按钮',
    title: '跳转小程序',
    name: 'applet'
  }
]

export let widgetMapData = {
  img: {
    widgetTypeV2: 'img',
    component: 'm-img',
    name: '图片',
    imageId: '',
    paddingTop: 0,
    paddingBottom: 0,
    pureImageUrl: '',
    height: '',
    width: ''
  },

  slider: {
    widgetTypeV2: 'slider',
    component: 'm-slider',
    name: '轮播图',
    componentCount: 2,
    slider: ['', '']
  },

  text: {
    widgetTypeV2: 'text',
    component: 'm-text',
    name: '文本',
    expand: false,
    fontSize: '14',
    fontColor: '#595959',
    textAlignment: 'left', // 对齐方式
    content: '', // 类容
    showType: 'normal', // '0'常规, '1'加粗
    paddingTop: 22,
    paddingBottom: 22,
    paddingRight: 24,
    paddingLeft: 24,
    id: ''
  },
  copyGh: {
    widgetTypeV2: 'copyGh',
    component: 'm-copyGh',
    type: 'label',
    name: '复制公众号',
    btnTitle: '关注', // 按钮文案
    btnFontType: 'normal', // 按钮外观 -> 按钮文案 '0'常规, '1'加粗
    ghFontColor: '#000000', // 公众号名称字体色
    backgroundColor: '#FFFFFF', // 组件底色
    btnFontColor: '#FFFFFF', // 按钮外观 -> 字体色
    btnBorderColorTheme: '#ffffff', // 按钮外观 -> 边框色
    btnBgColorTheme: '#07C160', // 按钮外观 -> 填充色
    paddingBottom: 0,
    paddingTop: 0
  },

  hideCopyGh: {
    widgetTypeV2: 'hideCopyGh',
    component: 'm-copyGh',
    type: 'label',
    name: '复制公众号',
    btnTitle: '关注', // 按钮文案
    btnFontType: 'normal', // 按钮外观 -> 按钮文案 '0'常规, '1'加粗
    ghFontColor: '#000000', // 公众号名称字体色
    backgroundColor: '#FFFFFF', // 组件底色
    btnFontColor: '#FFFFFF', // 按钮外观 -> 字体色
    btnBorderColorTheme: '#ffffff', // 按钮外观 -> 边框色
    btnBgColorTheme: '#07C160', // 按钮外观 -> 填充色
    paddingBottom: 0,
    paddingTop: 0
  },

  platform: {
    widgetTypeV2: 'platform'
  },

  applet: {
    widgetTypeV2: 'applet',
    component: 'm-applet',
    type: 'button',
    name: '跳转小程序',
    btnTitle: '关注', // 按钮文案
    btnFontType: 'normal', // 按钮外观 -> 按钮文案 '0'常规, '1'加粗
    backgroundColor: '#FFFFFF', // 组件底色
    btnFontColor: '#FFFFFF', // 按钮外观 -> 字体色
    btnBorderColorTheme: '#ffffff', // 按钮外观 -> 边框色
    btnBgColorTheme: '#07C160', // 按钮外观 -> 填充色
    paddingBottom: 0,
    paddingTop: 0,
    urlList: []
  }
}
