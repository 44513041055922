<!--
 * @Description: 组件选择区
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2024-03-28 18:22:49
-->

<template>
  <section v-if="seletedWidgetsIndex !== -1" class="ad-editors">
    <component :is="editors" :detail="finalWidgets[seletedWidgetsIndex]" :platforms="platforms" />
  </section>
  <section v-else class="ad-editors">
    <el-empty :image-size="100" description="未选中组件"></el-empty>
  </section>
</template>

<script>
import Img from '../adEditors_component/img.vue'
import Text from '../adEditors_component/text.vue'
import Base from '../adEditors_component/base.vue'

export default {
  components: {
    'm-img': Img,
    'm-text': Text,
    'm-base': Base
  },
  props: {
    finalWidgets: {
      type: Array,
      default: () => []
    },
    seletedWidgetsIndex: {
      type: Number,
      default: 0
    },
    platforms: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      editors: ''
    }
  },

  computed: {},
  watch: {
    seletedWidgetsIndex: {
      handler(val) {
        if (val > -1) {
          this.editors = this.finalWidgets[this.seletedWidgetsIndex].component
        }
      },
      immediate: true
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}

.ad-editors {
  padding: 10px;
  height: 100%;
  box-sizing: border-box;
}
</style>
