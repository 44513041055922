<!--
 * @Description: 工作区 -> 图文复合组件 一行两个
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2021-12-29 09:14:03
-->

<template>
  <section>
    <div></div>
  </section>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped></style>
