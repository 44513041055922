var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("twitter-picker", {
    staticStyle: { "margin-top": "10px" },
    attrs: { "default-colors": _vm.colorArr },
    on: { input: _vm.change },
    model: {
      value: _vm.colors,
      callback: function ($$v) {
        _vm.colors = $$v
      },
      expression: "colors",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }