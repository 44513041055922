var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { class: { active: _vm.selectWidgetsIndex === _vm.index } },
    [
      _vm.detail.cardType === 0
        ? [
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "center" } },
              [
                _c("img", {
                  attrs: {
                    src: _vm.detail.pureImageUrl || _vm.defaultImg,
                    alt: "",
                  },
                }),
                _c("div", { staticClass: "title ml-10" }, [
                  _c("h3", { style: { color: _vm.detail.titleColor } }, [
                    _vm._v(_vm._s(_vm.detail.title)),
                  ]),
                  _c("p", { style: { color: _vm.detail.descColor } }, [
                    _vm._v(_vm._s(_vm.detail.desc)),
                  ]),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "button",
                style: {
                  background: _vm.detail.componentItem.btnBgColorTheme,
                  color: _vm.detail.componentItem.fontColor,
                  border: `1px solid ${_vm.detail.componentItem.btnBorderColorTheme}`,
                  fontWeight:
                    _vm.detail.componentItem.btnFontType === "0"
                      ? "normal"
                      : "bold",
                },
              },
              [_vm._v(" " + _vm._s(_vm.detail.componentItem.btnTitle) + " ")]
            ),
          ]
        : [
            _c(
              "div",
              {
                staticClass: "text",
                style: {
                  color: _vm.detail.titleColor,
                },
              },
              [_vm._v(" " + _vm._s(_vm.detail.title) + " ")]
            ),
            _c(
              "div",
              {
                staticClass: "button",
                style: {
                  background: _vm.detail.componentItem.btnBgColorTheme,
                  color: _vm.detail.componentItem.fontColor,
                  border: `1px solid ${_vm.detail.componentItem.btnBorderColorTheme}`,
                  fontWeight:
                    _vm.detail.componentItem.btnFontType === "0"
                      ? "normal"
                      : "bold",
                },
              },
              [_vm._v(" " + _vm._s(_vm.detail.componentItem.btnTitle) + " ")]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }