import service from '@/network/service/service'

// 人群自动打包任务列表
export function getCrowdPackList(params) {
  return service.post('/api/tencent/tiktokTask/page', params)
}

// 保存/更新人群打包任务
export function saveCrowdPackTask(data) {
  return service.post(`/api/tencent/tiktokTask/saveOrUpdate`, data)
}

export function runCrowdPackTask(id) {
  return service.get(`/api/tencent/tiktokTask/executeOne/${id}`)
}

// 删除人群打包任务
export function deleteCrowdPackTask(id) {
  return service.delete(`/api/tencent/tiktokTask/delete/${id}`)
}

// 人群打包详情
export function getCrowdPackDetail(id, times = ['yyyy-MM-dd']) {
  return service.get(`/api/tencent/tiktokTask/getDetails/${id}/${times?.join('_')}`)
}

// 人群自动打包-任务数据范围列表
export function getDataRangeList(params) {
  return service({
    method: 'get',
    url: '/api/tencent/tiktokTask/getTaskInfo',
    params
  })
}

// 人群包 end
