var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Base",
        {
          attrs: {
            title: "配置区",
            desc: _vm.taskId ? "基础信息变更后，配置区清空重置" : "",
          },
        },
        [
          _c(
            "template",
            { slot: "topRight" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.taskId !== "" && _vm.isRuleDeploy,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.addRule("add")
                    },
                  },
                },
                [_vm._v("规则配置")]
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-descriptions",
                { attrs: { direction: "vertical", column: 2, border: "" } },
                [
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: "广告",
                        "content-style": "width: 33.33%",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "divider deploy-item",
                              attrs: { span: 12 },
                            },
                            [
                              _c("div", { staticClass: "title-count" }, [
                                _c("p", [_vm._v("已有计划")]),
                                _c("p", [
                                  _vm._v(
                                    "已选 " + _vm._s(_vm.planTotal) + " 条"
                                  ),
                                ]),
                              ]),
                              _c("Plan", {
                                attrs: {
                                  "plan-modal-visible": _vm.planModalVisible,
                                  "account-baidu-adv": _vm.accountBaiduAdv,
                                  "plan-data": _vm.planData,
                                  "task-id": _vm.taskId,
                                },
                                on: {
                                  byTaskIdGetAllFun: _vm.byTaskIdGetAllFun,
                                  clearTaskGenerate: _vm.clearTaskGenerate,
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "footer-add" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { padding: "0 0" },
                                      attrs: {
                                        type: "text",
                                        disabled: _vm.isUpdateTask,
                                      },
                                      on: { click: _vm.addPlan },
                                    },
                                    [_vm._v("添加")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "deploy-item",
                              staticStyle: { "padding-left": "8px" },
                              attrs: { span: 12 },
                            },
                            [
                              _c("div", { staticClass: "title-count" }, [
                                _c("p", [_vm._v("复用广告")]),
                                _c("p", [
                                  _vm._v("已选 " + _vm._s(_vm.adTotal) + " 条"),
                                ]),
                              ]),
                              _c("Advertisement", {
                                attrs: {
                                  "ad-modal-visible": _vm.adModalVisible,
                                  "ad-data": _vm.adData,
                                  "account-adv": _vm.accountBaiduAdv,
                                  "task-id": _vm.taskId,
                                  "product-name": _vm.productName,
                                  "task-site-set": _vm.taskSiteSet,
                                  "task-site-set-str": _vm.taskSiteSetStr,
                                },
                                on: {
                                  byTaskIdGetAllFun: _vm.byTaskIdGetAllFun,
                                  clearTaskGenerate: _vm.clearTaskGenerate,
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "footer-add" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { padding: "0 0" },
                                      attrs: {
                                        type: "text",
                                        disabled:
                                          _vm.isUpdateTask ||
                                          _vm.planTotal === 0,
                                      },
                                      on: { click: _vm.addAd },
                                    },
                                    [_vm._v("添加")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-descriptions-item",
                    {
                      attrs: {
                        label: "广告创意",
                        span: 16,
                        "content-style": "width: 66.66%",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "divider deploy-item",
                              attrs: { span: 6 },
                            },
                            [
                              _c("div", { staticClass: "title-count" }, [
                                _c("p", [_vm._v("创意基础信息")]),
                              ]),
                              _c("CreativityBaseInfo", {
                                attrs: {
                                  "creativity-visible": _vm.creativityVisible,
                                  "creativity-data": _vm.creativityData,
                                  "task-id": _vm.taskId,
                                  "task-site-set": _vm.taskSiteSet,
                                  "task-site-set-str": _vm.taskSiteSetStr,
                                  "task-combine": _vm.taskCombine,
                                },
                                on: {
                                  setCreativityBaseInfo:
                                    _vm.setCreativityBaseInfo,
                                  byTaskIdGetAllFun: _vm.byTaskIdGetAllFun,
                                  clearTaskGenerate: _vm.clearTaskGenerate,
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "footer-add" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { padding: "0 0" },
                                      attrs: {
                                        type: "text",
                                        disabled: _vm.isUpdateTask,
                                      },
                                      on: { click: _vm.addCreativity },
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.taskCombine === 2
                            ? [
                                _c(
                                  "el-col",
                                  {
                                    staticClass: "divider deploy-item",
                                    staticStyle: { "padding-left": "8px" },
                                    attrs: { span: 12 },
                                  },
                                  [
                                    _c("div", { staticClass: "title-count" }, [
                                      _c("p", [_vm._v("素材组合")]),
                                      _c("p", [
                                        _vm._v(
                                          "已选 " +
                                            _vm._s(
                                              _vm.materialCombinaData.length
                                            ) +
                                            " 个"
                                        ),
                                      ]),
                                    ]),
                                    _c("CreativityMaterialCombination", {
                                      ref: "materialCombination",
                                      attrs: {
                                        "material-visible":
                                          _vm.materialCombinaVisible,
                                        "account-id": _vm.accountId,
                                        "project-id": _vm.projectId,
                                        "creativity-data": _vm.creativityData,
                                        "material-combina-data":
                                          _vm.materialCombinaData,
                                        "task-id": _vm.taskId,
                                        "task-site-set": _vm.taskSiteSet,
                                      },
                                      on: {
                                        byTaskIdGetAllFun:
                                          _vm.byTaskIdGetAllFun,
                                        clearTaskGenerate:
                                          _vm.clearTaskGenerate,
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "footer-add" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { padding: "0 0" },
                                            attrs: {
                                              type: "text",
                                              disabled:
                                                _vm.isUpdateTask ||
                                                _vm.creativityData === null ||
                                                _vm.creativityData
                                                  .overrideCanvasHeadOption ===
                                                  "OPTION_CANVAS_OVERRIDE_CREATIVE",
                                            },
                                            on: {
                                              click: _vm.addMaterialCombination,
                                            },
                                          },
                                          [_vm._v("添加")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : [
                                _c(
                                  "el-col",
                                  {
                                    staticClass: "divider deploy-item",
                                    staticStyle: { "padding-left": "8px" },
                                    attrs: { span: 6 },
                                  },
                                  [
                                    _c("div", { staticClass: "title-count" }, [
                                      _c("p", [_vm._v("创意素材")]),
                                      _c("p", [
                                        _vm._v(
                                          "已选 " +
                                            _vm._s(_vm.creativityTotal) +
                                            " 组"
                                        ),
                                      ]),
                                    ]),
                                    _c("CreativityMaterial", {
                                      ref: "material",
                                      attrs: {
                                        "material-visible": _vm.materialVisible,
                                        "account-id": _vm.accountId,
                                        "creativity-material-data-template":
                                          _vm.creativityMaterialDataTemplate,
                                        "creativity-material-data":
                                          _vm.creativityMaterialData,
                                        "project-id": _vm.projectId,
                                        "creativity-data": _vm.creativityData,
                                        "task-id": _vm.taskId,
                                        "task-site-set": _vm.taskSiteSet,
                                      },
                                      on: {
                                        byTaskIdGetAllFun:
                                          _vm.byTaskIdGetAllFun,
                                        clearTaskGenerate:
                                          _vm.clearTaskGenerate,
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "footer-add" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { padding: "0 0" },
                                            attrs: {
                                              type: "text",
                                              disabled:
                                                _vm.isUpdateTask ||
                                                _vm.creativityData === null ||
                                                _vm.creativityData
                                                  .overrideCanvasHeadOption ===
                                                  "OPTION_CANVAS_OVERRIDE_CREATIVE",
                                            },
                                            on: { click: _vm.addMaterial },
                                          },
                                          [_vm._v("添加")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  {
                                    staticClass: "divider deploy-item",
                                    staticStyle: { "padding-left": "8px" },
                                    attrs: { span: 6 },
                                  },
                                  [
                                    _c("div", { staticClass: "title-count" }, [
                                      _c("p", [_vm._v("创意文案")]),
                                      _c("p", [
                                        _vm._v(
                                          "已选 " +
                                            _vm._s(_vm.creativityTextTotal) +
                                            " 组"
                                        ),
                                      ]),
                                    ]),
                                    _c("CreativityText", {
                                      attrs: {
                                        "text-visible": _vm.textVisible,
                                        "creativity-text-data":
                                          _vm.creativityTextData,
                                        "project-id": _vm.projectId,
                                        "task-id": _vm.taskId,
                                        "task-site-set": _vm.taskSiteSet,
                                      },
                                      on: {
                                        byTaskIdGetAllFun:
                                          _vm.byTaskIdGetAllFun,
                                        clearTaskGenerate:
                                          _vm.clearTaskGenerate,
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticClass: "footer-add" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { padding: "0 0" },
                                            attrs: {
                                              type: "text",
                                              disabled: _vm.isUpdateTask,
                                            },
                                            on: { click: _vm.addText },
                                          },
                                          [_vm._v("添加")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                          _c(
                            "el-col",
                            {
                              staticClass: "deploy-item",
                              staticStyle: { "padding-left": "8px" },
                              attrs: { span: 6 },
                            },
                            [
                              _c("div", { staticClass: "title-count" }, [
                                _c("p", [_vm._v("落地页")]),
                                _c("p", [
                                  _vm._v(
                                    "已选 " + _vm._s(_vm.ldyTotal) + " 条"
                                  ),
                                ]),
                              ]),
                              _c("Ldy", {
                                attrs: {
                                  visible: _vm.ldyModalVisible,
                                  "account-data": _vm.accountBaiduAdv,
                                  "form-data": _vm.ldyData,
                                  "task-id": _vm.taskId,
                                  "task-site-set": _vm.taskSiteSet,
                                  "creativity-data": _vm.creativityData,
                                },
                                on: {
                                  byTaskIdGetAllFun: _vm.byTaskIdGetAllFun,
                                  clearTaskGenerate: _vm.clearTaskGenerate,
                                  closeLdy: _vm.closeLdy,
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "footer-add" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { padding: "0 0" },
                                      attrs: {
                                        type: "text",
                                        disabled: _vm.isUpdateTask,
                                      },
                                      on: { click: _vm.addLDY },
                                    },
                                    [_vm._v("添加")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              { staticClass: "justify-sb" },
              [
                _c(
                  "div",
                  [
                    _c("el-button", { on: { click: _vm.addBrand } }, [
                      _vm._v("品牌标识"),
                    ]),
                    _vm.taskSiteSetStr.includes("i")
                      ? _c(
                          "el-button",
                          { on: { click: _vm.addVideoAccount } },
                          [_vm._v("视频号")]
                        )
                      : _vm._e(),
                    _vm.creativityData.pageType === "PAGE_TYPE_CANVAS_WECHAT"
                      ? _c("el-button", { on: { click: _vm.addWecom } }, [
                          _vm._v("企微配置"),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      disabled: !_vm.show_ad_button_flag || _vm.isUpdateTask,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.showPreviewFun()
                      },
                    },
                  },
                  [_vm._v("预览广告")]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("Brand", {
        attrs: {
          "brand-modal-visible": _vm.brandModalVisible,
          "project-id": _vm.projectId,
          "account-baidu-adv": _vm.accountBaiduAdv,
          "brand-data": _vm.brandData,
          "task-id": _vm.taskId,
        },
        on: {
          byTaskIdGetAllFun: _vm.byTaskIdGetAllFun,
          clearTaskGenerate: _vm.clearTaskGenerate,
        },
      }),
      _c("WeCom", {
        attrs: {
          "wecom-modal-visible": _vm.wecomModalVisible,
          "account-data": _vm.accountBaiduAdv,
          "task-id": _vm.taskId,
          "wecom-data": _vm.wecomData,
        },
        on: {
          byTaskIdGetAllFun: _vm.byTaskIdGetAllFun,
          clearTaskGenerate: _vm.clearTaskGenerate,
        },
      }),
      _c("RuleDeploy", {
        attrs: {
          "rule-deploy-visible": _vm.ruleDeployVisible,
          "is-rule-deploy": _vm.isRuleDeploy,
          "task-id": _vm.taskId,
          "task-combine": _vm.taskCombine,
          "configured-status": _vm.configuredStatus,
        },
        on: { setRuleDeploy: _vm.setRuleDeploy },
      }),
      _c("VideoAccountDialog", {
        attrs: {
          "video-modal-visible": _vm.videoModalVisible,
          "project-id": _vm.projectId,
          "account-data": _vm.accountBaiduAdv,
          "video-account-data": _vm.videoAccountData,
          "task-id": _vm.taskId,
        },
        on: {
          byTaskIdGetAllFun: _vm.byTaskIdGetAllFun,
          clearTaskGenerate: _vm.clearTaskGenerate,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }