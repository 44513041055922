var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "projection-container" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "projection-content" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 24 } },
          [
            _c(
              "el-col",
              { attrs: { span: 24 } },
              [
                _c("div", { staticClass: "projection-item-header" }, [
                  _c("span", { staticClass: "title" }, [
                    _vm._v("人员剪辑明细"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "item-search" },
                    [
                      _c("el-input", {
                        staticStyle: { width: "120px", "margin-right": "10px" },
                        attrs: {
                          type: "text",
                          placeholder: "人员名称",
                          size: "mini",
                        },
                        model: {
                          value: _vm.user.userName,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "userName", $$v)
                          },
                          expression: "user.userName",
                        },
                      }),
                      _c("el-cascader", {
                        ref: "cascader",
                        staticStyle: { width: "120px", "margin-right": "10px" },
                        attrs: {
                          options: _vm.deptList,
                          props: _vm.cascaderProp,
                          "show-all-levels": false,
                          filterable: "",
                          clearable: "",
                          placeholder: "请选择部门",
                          size: "mini",
                        },
                        on: { change: _vm.handleChangeDept },
                        model: {
                          value: _vm.user.deptId,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "deptId", $$v)
                          },
                          expression: "user.deptId",
                        },
                      }),
                      _c("SearchDate", {
                        model: {
                          value: _vm.user.dates,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "dates", $$v)
                          },
                          expression: "user.dates",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.user.loading,
                        expression: "user.loading",
                      },
                    ],
                    staticClass: "list-table",
                    attrs: { data: _vm.user.list, stripe: "", size: "small" },
                  },
                  [
                    _c("el-empty", {
                      attrs: { slot: "empty", description: "暂无数据" },
                      slot: "empty",
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "日期",
                        prop: "showDate",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "部门",
                        prop: "deptName",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "姓名",
                        prop: "userName",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "当日剪辑产品",
                        prop: "todayClipNum",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "累计剪辑产品",
                        prop: "sumClipNum",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "当日剪辑素材",
                        prop: "todayClipAsset",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "累计剪辑素材",
                        prop: "sumClipAssetNum",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "参与起量",
                        prop: "participateIn",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "近似累计起量率",
                        prop: "sumAmountRate",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [_vm._v(_vm._s(row.sumAmountRate) + "%")]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "page" },
                  [
                    _c("paginations", {
                      attrs: {
                        total: _vm.user.total,
                        page: _vm.user.pageNum,
                        limit: _vm.user.pageSize,
                        layout: "prev, pager, next",
                        small: "",
                      },
                      on: {
                        "update:page": function ($event) {
                          return _vm.$set(_vm.user, "pageNum", $event)
                        },
                        "update:limit": function ($event) {
                          return _vm.$set(_vm.user, "pageSize", $event)
                        },
                        pagination: _vm.getUserPage,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "projection-header" }, [
      _c("span", [_vm._v("素材数据")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }