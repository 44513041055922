<!--  -->
<template>
  <div class="content">

    <el-table
      :header-cell-style="{ 'text-align': 'center', background: '#FAFAFA', 'font-weight': '400', }"
      :cell-style="{ 'text-align': 'center', 'margin-bottom': '0', }"
      :data="tableDatas"
      border
      style="margin-top: 10px"
    >

      <el-table-column
        prop="name"
        label="投放账户"
        width="180"
      >
      </el-table-column>

      <el-table-column label="企微ID" width="180">
        <template slot-scope="scope">
          <el-form :model="scope.row">
            <el-form-item size="mini">
              <el-input
                v-if="scope.row.isOK"
                v-model="scope.row.corpid"
                placeholder="请输入"
                style="width: 100%; margin: auto"
              ></el-input>
              <span v-else>{{ scope.row.corpid }}</span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>

      <el-table-column label="客服组ID" width="180">
        <template slot-scope="scope">
          <el-form :model="scope.row">
            <el-form-item size="mini">
              <el-input
                v-if="scope.row.isOK"
                v-model="scope.row.groupid"
                placeholder="请输入"
                style="width: 100%; margin: auto"
              ></el-input>
              <span v-else>{{ scope.row.groupid }}</span>
            </el-form-item>
          </el-form>
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" :icon=" scope.row.isOK? 'el-icon-check':'el-icon-edit' " @click="handleEdit(scope.$index, scope.row)"></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  name: '',
  // import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    editTableData: {
      type: Array,
      default: () => []
    }

  },
  data() {
    return {
      tableDatas: []
    }
  },
  // 监听属性 类似于data概念
  computed: {

  },
  // 监控data中的数据变化
  watch: {
    editTableData() {
      this.tableDatas.forEach((element, index) => {
        if (this.editTableData.filter(xf => element.ghid === xf.split('+')[0]).length === 0) {
          this.tableDatas.splice(index, 1)
        }
      })
      this.editTableData.forEach(element => {
        if (this.tableDatas.filter(xf => xf.ghid === element.split('+')[0]).length === 0) {
          this.tableDatas.push({
            isOK: true,
            ghid: element.split('+')[0],
            name: element.split('+')[1]
          })
        }
      })
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {},
  // 方法集合
  methods: {
    handleEdit(index, row) {
      row.isOK = !row.isOK
      // 在这里进行根据需求逻辑修改
      this.$emit('retdata', this.tableDatas)
      console.log(index, row)
    },
    handleDelete(index, row) {
      console.log(index, row)
    }

  } // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
/* @import url(); 引入公共css类 */

</style>
