<!--
 * @Description: Do not edit
 * @Date: 2021-12-24 14:49:52
 * @LastEditTime: 2022-02-16 11:55:13
-->
<template>
  <el-form ref="searchForm" class="search-form" :model="searchForm">
    <el-row :gutter="20">
      <el-col :span="6">
        <el-form-item prop="source" label="来源名称">
          <el-input v-model="searchForm.source" placeholder="请输入来源名称" @keyup.enter.native="search" />
        </el-form-item>
      </el-col>
      <!-- <el-col :span="6">
        <el-form-item prop="relationProjectName" label="关联项目名称">
          <el-input v-model="searchForm.relationProjectName" placeholder="请输入关联项目名称" @keyup.enter.native="search" />
        </el-form-item>
      </el-col> -->
      <el-col :span="6">
        <el-form-item prop="isUseing" label="是否启用">
          <el-select v-model="searchForm.isUseing" placeholder="请选择">
            <el-option v-for="item in isUseingList" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-button class="btn" type="primary" @click="search">
          查询
        </el-button>
        <el-button class="btn" @click="resetSearch">
          重置
        </el-button>
      </el-col>
    </el-row>
    <el-row />
  </el-form>
</template>
<script>
import { searchForm, isUseingList } from './object'
export default {
  inject: ['loadData'],
  data() {
    return {
      searchForm: { ...searchForm },
      isUseingList: isUseingList
    }
  },
  methods: {
    search() {
      this.loadData(true)
    },
    // 折中方案
    getSearchForm() {
      return { ...this.searchForm }
    },
    resetSearch() {
      this.$refs.searchForm.resetFields()
    }
  }
}
</script>
