<template>
  <el-dialog title="导入定向模板" width="60%" top="100px" :visible.sync="visible" :before-close="close" label-position="right" :modal="false" :modal-append-to-body="false" :close-on-press-escape="false" :close-on-click-modal="false">
    <!-- 列表查询 -->
    <el-form ref="search" style="width:100%" :inline="true" :model="search">
      <el-form-item label="单元名称" prop="audienceName">
        <el-input v-model="search.audienceName" placeholder="请输入名称"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" round @click="getPlan()">查询</el-button>
        <el-button type="primary" round @click="resetForm()">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <TablePane :data-source="dataSource" @getPage="getPlan"> </TablePane>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="importPlan">导入</el-button>
      <el-button @click="visible = false">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { Page } from '@/network/api/assetManagement/assetManagementBaiduAudience'
import { getHouseKeeper } from '@/network/api/account/accountBaiduHousekeeper'
import { getAccountByHouseId } from '@/network/api/account/accountBaiduAdv'
import { PageCommonComponent } from '@/common/mixin'
import TablePane from '@/components/tablePaneUp.vue'
export default {
  components: {
    TablePane
  },
  mixins: [PageCommonComponent],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    bsProjectId: {
      type: Number
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      bgtctltypeList: [],
      planPauseList: [],
      importData: {},
      search: {
        audienceName: ''
      },
      dataSource: {
        data: [], // 表格数据
        cols: [
          {
            label: '定向包名称',
            prop: 'audienceName'
          },
          {
            label: '创建人',
            prop: 'createBy'
          },
          {
            label: '创建时间',
            prop: 'createTime'
          }
        ], // 表格的列数据
        isIndex: true, // 列表序号
        loading: false, // loading
        isOperation: false,
        isSelection: false,
        highlightCurrentRow: true,
        handleCurrentChange: (val) => {
          this.importData = val
        },
        handleSelectionChange: (val) => {}, // 点击行选中多选返回选中数组
        pageData: {
          total: 0 // 总条数
        }
      },
      houseKeeper: [], // 账户管家
      baiduAdv: [] // 投放账户
    }
  },
  mounted() {
    // this.loadData()
  },
  methods: {
    /**
     * @description: 获取计划列表
     * @param {*} params
     */
    getPlan(params = { pageNum: 1, pageSize: 10 }) {
      this.dataSource.loading = true
      let req = { ...params, ...this.search, projectId: this.bsProjectId }
      Page(req).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          this.dataSource.data = records
          this.dataSource.pageData.total = total
          this.dataSource.loading = false
        }
      })
    },
    /**
     * @description: 重置
     */
    resetForm() {
      this.$refs['search'].resetFields()
      this.getPlan()
    },

    /**
     * @description: 获取账户管家
     */
    getHk() {
      getHouseKeeper().then((res) => {
        if (res.code === 200) {
          this.houseKeeper = res.data
        }
      })
    },

    /**
     * @description: 选择账户管家获取投放账户
     * @param {*} val 账户管家Id
     */
    selectChange(val) {
      this.baiduAdv = []
      getAccountByHouseId(val).then((res) => {
        if (res.code === 200) {
          this.baiduAdv = res.data
        }
      })
    },

    /**
     * @description: 导入计划
     */
    importPlan() {
      if (this.importData.audience) {
        this.$emit('audience', this.importData.audience)
        this.close()
      } else {
        this.$message({
          message: '请选择定向模板',
          type: 'info'
        })
      }
      //  handleSelectionChange 里可以获取每条数据信息
    },
    close() {
      this.visible = false
    },
    show() {
      this.visible = true
      this.getHk()
      this.getPlan()
      this.loadData(false)
    }
  }
}
</script>

<style></style>
