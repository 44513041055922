<!--
 * @Description: 计划
 * @Date: 2021-12-06 15:57:16
 * @LastEditTime: 2022-04-09 16:49:53
-->
<template>
  <div style="height: 300px;">
    <ContentBox v-if="planForm.flag" :data-props="planDiv" />
    <div v-else class="kong">请选择计划</div>
    <!-- 配置计划 -->
    <el-dialog title="计划基础信息" :visible="visible" width="600px" :modal-append-to-body="false" @close="beforeClose">
      <div>
        <el-form ref="form" :model="planForm" label-width="100px">
          <el-form-item label="投放位置" :rules="{ required: true, message: '请选择投放位置', trigger: 'blue' }">
            <el-select v-model="planForm.isFriendCircle" prop="isFriendCircle" style="width:100%" placeholder="请选择投放位置" :rules="{ required: true, message: '请选择投放账户', trigger: 'blue' }" @change="launchPlanAddressChange">
              <!-- v-if="promotedObjectType === 'PRODUCTTYPE_WECHAT'" -->
              <el-option v-show="promotedObjectType==='PRODUCTTYPE_WECHAT'" label="朋友圈信息流" :value="true"></el-option>
              <el-option label="公众号平台流量" :value="false"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="广告位置" prop="adsAddress" :rules="{ required: true, message: '请选择广告位置', trigger: 'blue' }">
            <el-select v-model="planForm.adsAddress" placeholder="请选择广告位置" style="width:100%" @change="adsAddressChange">
              <el-option v-for="item in crtSizeList" :key="item.id" :label="`${item.label}`" :value="`${item.other_id}+${item.id}+${item.value}+${item.label}`"></el-option>
            </el-select>

            <p v-if="planForm.flag" class="tip">修改广告位置后, 广告创意数据会被清空</p>
          </el-form-item>

          <el-form-item label="购买方式" prop="ctype" :rules="{ required: true, message: '请选购买方式', trigger: 'blue' }">
            <el-select v-model="planForm.ctype" style="width:100%">
              <el-option label="竞价购买" value="CAMPAIGNTYPE_AUCTION"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="计划日预算" prop="dayBudget" :rules="{ required: true, message: '请选择计划日预算', trigger: 'blue' }">
            <el-input-number v-model="planForm.dayBudget" :min="50" :max="40000000" controls-position="right"></el-input-number>
            <span class="ml-10">元/天</span>
            <br />
            <p class="tip">每日预算有效范围为 50 ~ 40,000,000 元/天。</p>
          </el-form-item>

          <el-form-item label="计划名称" prop="cname" :rules="{ required: true, message: '请输入计划名称', maxlength: 60, trigger: 'blue' }">
            <!-- <el-input v-model="planForm.cname" maxlength="60" show-word-limit></el-input> -->
            <el-input v-model="planForm.cname" rows="4" type="textarea" style="width: 400px" placeholder="请输入计划名称" maxlength="60" show-word-limit />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button :disabled="!isSearchLock" type="primary" @click="getSelectPlanData">确 定</el-button>
        <!-- <el-button v-else type="primary">确 定 1</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getCrtSize, savePlan } from '@/network/api/advManagement/advManagementWatch' // 获取计划模板
import ContentBox from './contentBox.vue'

export default {
  components: {
    ContentBox
  },

  inject: ['close'],

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    accountBaiduAdv: {
      type: Array,
      default: () => []
    },
    promotedObjectType: {
      type: String,
      default: ''
    },
    formData: {
      type: Object,
      default: () => {}
    },
    taskId: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      // 广告位置list
      crtSizeList: [],
      planForm: {},
      planDiv: [],
      isSearchLock: true
    }
  },

  watch: {
    formData: {
      handler(value) {
        this.planForm = value

        this.planDiv = [
          { key: '投放位置', value: value.isFriendCircle ? '朋友圈信息流' : '公众号平台流量' },
          { key: '广告位置', value: value.crtSizeLabel || '' },
          { key: '购买方式', value: '竞价购买' },
          { key: '计划日预算', value: `${value.dayBudget}元/天` }
        ]
      },
      immediate: true
    },
    // 打开弹框调用
    visible(value) {
      if (value) {
        this.getCrtSizeFun(this.promotedObjectType)
      }
    }
  },

  methods: {
    /**
     * @desc 根据投放位置获取广告位置
     */
    getCrtSizeFun(type, flag) {
      getCrtSize(type, this.planForm.isFriendCircle).then(({ code, data }) => {
        if (code === 200) {
          this.crtSizeList = data
          if (this.planForm.adsAddress !== null && !flag) return
          this.planForm.adsAddress = `${data[0].other_id}+${data[0].id}+${data[0].value}+${data[0].label}`
          this.planForm.crtSizeId = data[0].id
          this.planForm.otherId = data[0].other_id
          this.planForm.crtSize = data[0].value
          this.planForm.crtSizeLabel = data[0].label
        }
      })
    },

    launchPlanAddressChange(value) {
      this.getCrtSizeFun(this.promotedObjectType, value + '')
    },

    adsAddressChange(value) {
      let [otherId, crtSizeId, crtSize, crtSizeLabel] = value.split('+')
      this.planForm.crtSizeId = +crtSizeId
      this.planForm.otherId = +otherId
      this.planForm.crtSize = +crtSize
      this.planForm.crtSizeLabel = crtSizeLabel
    },

    /**
     * @desc 获取选中的计划后提交
     */
    getSelectPlanData() {
      setTimeout(() => {
        if (this.isSearchLock) {
          this.isSearchLock = false
          let params = {
            taskId: this.taskId,
            planCampaignId: this.planForm.planCampaignId || null,
            cname: this.planForm.cname,
            otherId: this.planForm.otherId,
            crtSizeLabel: this.planForm.crtSizeLabel,
            crtSize: this.planForm.crtSize,
            dayBudget: this.planForm.dayBudget,
            crtSizeId: +this.planForm.crtSizeId,
            isFriendCircle: this.planForm.isFriendCircle
          }

          savePlan(params).then((res) => {
            if (res.code === 200) {
              this.close()
              this.$emit('byTaskIdGetAllFun')
              this.$message.success('计划保存成功!')
            }
            this.isSearchLock = true
          })
        }
      })
    },

    beforeClose() {
      this.$emit('byTaskIdGetAllFun')
      this.close()
    }
  }
}
</script>

<style lang="scss">
.kong {
  width: 100%;
  text-align: center;
  margin-top: 30px;
  font-size: 13px;
  color: rgb(180, 179, 179);
}
</style>
