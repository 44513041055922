var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "base-box",
        {
          attrs: {
            name: "floatbutton",
            desc: "页面中出现全景图，将暂时消失",
            title: !_vm.setting ? "基础设置" : "客服/按钮设置",
          },
        },
        [
          !_vm.setting
            ? _c("template", { slot: "body" }, [
                _c("div", [
                  _c("p", { staticClass: "w-100 font-13" }, [
                    _vm._v("转化按钮："),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "ml-20",
                      staticStyle: { display: "flex", "align-items": "center" },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticStyle: {
                            "font-size": "14px",
                            color: "#a7a5a5",
                          },
                        },
                        [_vm._v("添加商家微信")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "ml-20",
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              _vm.setting = true
                            },
                          },
                        },
                        [_vm._v("设置")]
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", [
                  _c("p", { staticClass: "w-100 font-13" }, [
                    _vm._v("卡片样式："),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "ml-20",
                      staticStyle: { display: "flex", "align-items": "center" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "250px" },
                          attrs: { placeholder: "请选择卡片样式" },
                          model: {
                            value: _vm.detail.cardType,
                            callback: function ($$v) {
                              _vm.$set(_vm.detail, "cardType", $$v)
                            },
                            expression: "detail.cardType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "图片+标题+描述+按钮", value: 0 },
                          }),
                          _c("el-option", {
                            attrs: { label: "标题+按钮", value: 1 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.detail.cardType === 0
                  ? _c("div", [
                      _c("p", { staticClass: "w-100 font-13" }, [
                        _vm._v("图片："),
                      ]),
                      _c(
                        "div",
                        { staticClass: "ml-20 image-updata" },
                        [
                          _c(
                            "el-upload",
                            {
                              staticClass: "avatar-uploader update",
                              attrs: {
                                action: _vm.UPLOAD_URL,
                                accept: ".png,.jpg,.jpeg",
                                "show-file-list": false,
                                "before-upload": _vm.beforeAvatarUpload,
                                "on-success": (res) =>
                                  _vm.handleAvatarSuccess(res),
                              },
                            },
                            [
                              _vm.detail.pureImageUrl
                                ? _c("img", {
                                    staticClass: "avatar",
                                    attrs: { src: _vm.detail.pureImageUrl },
                                  })
                                : _c("i", {
                                    staticClass:
                                      "el-icon-plus avatar-uploader-icon",
                                  }),
                            ]
                          ),
                          _c("p", { staticClass: "tip mt-20 ml-20" }, [
                            _vm._v(" 图片大小：96像素*96像素 "),
                            _c("br"),
                            _vm._v(" 图片格式：大小不超过300KB，不支持Gif "),
                          ]),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c("div", [
                  _c("p", { staticClass: "w-100 font-13" }, [_vm._v("标题：")]),
                  _c(
                    "div",
                    { staticClass: "ml-20", staticStyle: { display: "flex" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "250px" },
                        attrs: {
                          placeholder: "请输入悬浮组件标题",
                          maxlength: "10",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.detail.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "title", $$v)
                          },
                          expression: "detail.title",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm.detail.cardType === 0
                  ? _c("div", [
                      _c("p", { staticClass: "w-100 font-13" }, [
                        _vm._v("描述："),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "ml-20",
                          staticStyle: { display: "flex" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              placeholder: "请输入悬浮组件描述",
                              maxlength: "14",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.detail.desc,
                              callback: function ($$v) {
                                _vm.$set(_vm.detail, "desc", $$v)
                              },
                              expression: "detail.desc",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c("div", [
                  _c("p", { staticClass: "w-100 font-13" }, [
                    _vm._v("标题字色："),
                  ]),
                  _c(
                    "div",
                    { staticClass: "ml-20" },
                    [
                      _c(
                        "el-dropdown",
                        [
                          _c("div", {
                            staticClass: "ldy-bg-color",
                            style: `background:${_vm.detail.titleColor}`,
                          }),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              _c("twitter-color", {
                                staticStyle: { "margin-top": "10px" },
                                attrs: { colors: _vm.detail.titleColor },
                                on: {
                                  change: (value) =>
                                    (_vm.detail.titleColor = value),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.detail.cardType === 0
                  ? _c("div", [
                      _c("p", { staticClass: "w-100 font-13" }, [
                        _vm._v("描述字色："),
                      ]),
                      _c(
                        "div",
                        { staticClass: "ml-20" },
                        [
                          _c(
                            "el-dropdown",
                            [
                              _c("div", {
                                staticClass: "ldy-bg-color",
                                style: `background:${_vm.detail.descColor}`,
                              }),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                [
                                  _c("twitter-color", {
                                    staticStyle: { "margin-top": "10px" },
                                    attrs: { colors: _vm.detail.descColor },
                                    on: {
                                      change: (value) =>
                                        (_vm.detail.descColor = value),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _c("div", [
                  _c("p", { staticClass: "w-100 font-13" }, [
                    _vm._v("出现方式："),
                  ]),
                  _c(
                    "div",
                    { staticClass: "ml-20" },
                    [
                      _c("el-radio", { attrs: { value: "1", label: "1" } }, [
                        _vm._v("进入页面时出现 "),
                      ]),
                    ],
                    1
                  ),
                ]),
                _c("div", [
                  _c("p", { staticClass: "w-100 font-13" }, [
                    _vm._v("消失方式："),
                  ]),
                  _c(
                    "div",
                    { staticClass: "ml-20" },
                    [
                      _c("el-radio", { attrs: { value: "1", label: "1" } }, [
                        _vm._v("不消失 "),
                      ]),
                    ],
                    1
                  ),
                ]),
              ])
            : _c(
                "template",
                { slot: "body" },
                [
                  _c("div", [
                    _c("p", { staticClass: "w-100 font-13 font-13" }, [
                      _vm._v("客服类型："),
                    ]),
                    _c(
                      "div",
                      { staticClass: "ml-20" },
                      [
                        _c("el-radio", { attrs: { value: "1", label: "1" } }, [
                          _vm._v("企业微信客服 "),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c("div", [
                    _c("p", { staticClass: "w-100 font-13" }, [
                      _vm._v("添加类型："),
                    ]),
                    _c(
                      "div",
                      { staticClass: "ml-20" },
                      [
                        _c("el-radio", { attrs: { value: "1", label: "1" } }, [
                          _vm._v("客服 "),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c("div", [
                    _c("p", { staticClass: "w-100 font-13" }, [
                      _vm._v("客服分配："),
                    ]),
                    _c("div", { staticClass: "ml-20" }, [
                      _c("p", { staticClass: "tip" }, [
                        _vm._v("广告模板发布时添加"),
                      ]),
                    ]),
                  ]),
                  _c("el-divider"),
                  _c("div", [
                    _c("p", { staticClass: "w-100 font-13" }, [
                      _vm._v("按钮文案："),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "ml-20",
                        staticStyle: { display: "flex" },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "160px" },
                          attrs: { maxlength: 5, "show-word-limit": "" },
                          model: {
                            value: _vm.detail.componentItem.btnTitle,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.detail.componentItem,
                                "btnTitle",
                                $$v
                              )
                            },
                            expression: "detail.componentItem.btnTitle",
                          },
                        }),
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "ml-20",
                            attrs: { size: "small" },
                            model: {
                              value: _vm.detail.componentItem.btnFontType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.detail.componentItem,
                                  "btnFontType",
                                  $$v
                                )
                              },
                              expression: "detail.componentItem.btnFontType",
                            },
                          },
                          [
                            _c("el-radio-button", { attrs: { label: "0" } }, [
                              _vm._v("常规"),
                            ]),
                            _c("el-radio-button", { attrs: { label: "1" } }, [
                              _vm._v("加粗"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", [
                    _c("p", { staticClass: "w-100 font-13" }, [
                      _vm._v("按钮字体色："),
                    ]),
                    _c(
                      "div",
                      { staticClass: "ml-20" },
                      [
                        _c(
                          "el-dropdown",
                          [
                            _c("div", {
                              staticClass: "ldy-bg-color",
                              style: `background:${_vm.detail.componentItem.fontColor}`,
                            }),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c("twitter-color", {
                                  staticStyle: { "margin-top": "10px" },
                                  attrs: { colors: _vm.detail.btnFontColor },
                                  on: {
                                    change: (value) =>
                                      (_vm.detail.componentItem.fontColor =
                                        value),
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("div", [
                    _c("p", { staticClass: "w-100 font-13" }, [
                      _vm._v("按钮填充色："),
                    ]),
                    _c(
                      "div",
                      { staticClass: "ml-20" },
                      [
                        _c(
                          "el-dropdown",
                          [
                            _c("div", {
                              staticClass: "ldy-bg-color",
                              style: `background:${_vm.detail.componentItem.btnBgColorTheme}`,
                            }),
                            _c(
                              "el-dropdown-menu",
                              { attrs: { slot: "dropdown" }, slot: "dropdown" },
                              [
                                _c("twitter-color", {
                                  staticStyle: { "margin-top": "10px" },
                                  attrs: {
                                    colors:
                                      _vm.detail.componentItem.btnBgColorTheme,
                                  },
                                  on: {
                                    change: (value) =>
                                      (_vm.detail.componentItem.btnBgColorTheme =
                                        value),
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: { width: "100%" },
                      on: {
                        click: function ($event) {
                          _vm.setting = false
                        },
                      },
                    },
                    [_c("i", { staticClass: "el-icon-back" })]
                  ),
                ],
                1
              ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }