<template>
  <!--显示列弹框-->
  <el-dialog class="table-template" title="选择抖音号" :visible.sync="show" :append-to-body="true" width="1200px" top="20vh"
    @close="onCloseDialog">
    <div class="page-container unselected-all-table">
      <el-row :gutter="20">
        <el-col :span="5" class="page-name">
          <div v-for="(item, idx) in advertisers" :key="idx"
            :class="['name', item.advertiserId === activeAdvId ? 'is-active' : '']"
            @click="handleChangeAdv(item.advertiserId, idx)">{{ item.name }}</div>
        </el-col>
        <el-col :span="12" class="middle-content">
          <el-row>
            <el-col :span="24" style="text-align:right">
              <el-button type="text" icon="el-icon-refresh" @click="handleSyncList">同步</el-button>
            </el-col>
          </el-row>

          <el-table ref="multipleTable" :row-key="row => { return row.awemeId }" :data="tableData" v-loading="loading"
            height="390px" @select="handleCurrentChange" @select-all="handleAllChange">
            <el-table-column type="selection" label="选择" :selectable="checkSelectable" :reserve-selection="true">
            </el-table-column>
            <el-table-column label="名称" show-overflow-tooltip align="center">
              <span slot-scope="{ row }">{{ row.awemeName }}</span>
            </el-table-column>
            <el-table-column label="ID" show-overflow-tooltip align="center">
              <span slot-scope="{ row }">{{ row.awemeId }}</span>
            </el-table-column>
          </el-table>
          <!-- <div class="pagination-container">
            <c-pagination :page-size.sync="pageSize" :page-no.sync="pageNum" :pager-count="5" :total="total"
              tools="total, prev, pager, next" :get-data-fun="getPageData" />
          </div> -->
        </el-col>
        <el-col :span="7">
          <div class="comp-selected-box page-name">
            <div class="comp-title">已选抖音号</div>
            <div class="comp-selected-content">
              <div class="comp-selected-item" v-for="(item, index) in selectedData" :key="index">
                <template>
                  <span class="comp-name">{{ item.awemeName }}</span>
                  <span class="el-icon-close comp-del-icon" @click="handleDeleteSelected(index)"></span>
                </template>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">取 消</el-button>
      <el-button class="btn" type="primary" :loading="isLoading" :disabled="isLoading" @click="clickConfirm">确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getDouyinAccount, saveConfigDouyin } from '@/network/api/api-huge-batch'
import { PageCommonComponent } from '@/common/mixin'
export default {
  name: 'DouyinSelectDialog',
  componentves: {},
  filters: {},
  mixins: [PageCommonComponent],
  props: {
    baseId: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      show: false, // 是否显示弹框
      loading: false, // 表格加载状态
      isLoading: false, // 是否正在保存
      allTableData: [], // 全部数据
      tableData: [], // 当前账户所选列表
      selectedData: [], // 当前投放账户选中的数据
      advertisers: [], // 投放账户数据
      housekeeperId: '', // 管理账户id
      activeAdvId: '', // 当前选中账户id
      pageTypes: { 'TETRIS_EXTERNAL': 'pageOrangeWebsite', 'THIRD_EXTERNAL': 'pageThirdLandingPage', 'MINI_PROGRAME': 'pageOrangeWebsite' },
      pageNum: 1,
      pageSize: 5,
      total: 0
    }
  },
  watch: {},
  created() { },
  mounted() { },
  methods: {
    /* --------------------------- Actions --------------------------- */
    // 显示弹框
    showModal(data) {
      const { advertisers, housekeeperId, douyins } = data
      this.advertisers = advertisers || []
      this.housekeeperId = housekeeperId
      this.show = true
      this.activeAdvId = advertisers[0].advertiserId
      this.selectedData = [...douyins]
      this._getPageList()
    },

    // 关闭弹框
    closeModal() {
      this.show = false
    },
    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      // 重置表单
      this.activeAdvId = ''
      this.housekeeperId = ''
      this.tableData = []
      this.selectedData = []
      this.pageNum = 1
      this.pageSize = 5
      this.total = 0
      this.$refs.multipleTable.clearSelection()
    },

    /* --------------------------- 数据接口加载 -------------------------- */
    // 获取落地页列表
    _getPageList() {
      this.loading = true
      getDouyinAccount(this.housekeeperId, this.activeAdvId).then((res) => {
        if (res.code === 200) {
          // let { records, total } = res.data
          let list = res.data.map(v => {
            v.advertiserId = this.activeAdvId
            return v
          })
          // this.tableData = list
          // this.total = total
          this.tableData = list

          // 选中数据回显
          if (this.selectedData.length) {
            this.echo(this.tableData)
          }
        }
      }).finally(() => {
        this.loading = false
      })
    },

    // 表格数据回显
    echo(data) {
      let rows = []
      data.forEach(item => {
        this.selectedData.forEach(item2 => {
          if (item.awemeId === item2.awemeId) {
            rows.push(item)
          }
        })
      })
      this.toggleSelection(rows)
    },
    // 勾选表格选中
    toggleSelection(rows) {
      if (rows.length) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row, true)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    /* --------------------------- 数据接口加载 -------------------------- */
    /* --------------------------- 按钮点击 -------------------------- */
    // 复选框是否可选
    checkSelectable(row, index) {
      let flag = true
      let item = this.selectedData.filter(v => v.advertiserId === row.advertiserId)
      if (item && item.length) {
        flag = false
      } else {
        flag = true
      }
      // for (let i = 0; i < this.selectedData.length; i++) {
      //   if (row.awemeId === this.selectedData[i].awemeId) {
      //     flag = true
      //     break
      //   } else {
      //     flag = false
      //   }
      // }
      return flag
    },
    // 表格全选
    handleAllChange(selection) {
      if (selection.length) {
        let ids = this.selectedData.map(v => v.awemeId)
        selection.forEach(item => {
          if (!ids.includes(item.awemeId)) {
            this.selectedData.push(item)
          }
        })
      } else {
        this.tableData.forEach(item => {
          this.selectedData.forEach((item2, index) => {
            if (item.awemeId === item2.awemeId) {
              this.selectedData.splice(index, 1)
            }
          })
        })
      }
    },
    // table 选择 cell 时调用
    handleCurrentChange(selection, row) {
      let ids = this.selectedData.map(v => v.awemeId)
      if (!ids.includes(row.awemeId)) {
        this.selectedData.push(row)
      } else {
        let index = this.selectedData.findIndex(v => v.awemeId === row.awemeId)
        this.selectedData.splice(index, 1)
      }
    },
    // 删除已选组件
    handleDeleteSelected(index) {
      let selected = this.selectedData.splice(index, 1)
      let list = this.tableData
      if (selected.length) {
        list.forEach((obj, index) => {
          selected.forEach(item => {
            if (obj.awemeId === item.awemeId) {
              setTimeout(() => {
                this.$nextTick(() => this.$refs.multipleTable.toggleRowSelection(list[index], false))
              }, 10)
            }
          })
        })
      }
    },

    // 点击搜索
    onClickSearch() {
      if (this.pageNum === 1) {
        this._getPageList()
      } else {
        this.pageNum = 1
      }
    },
    // 切换顶级投放账户
    handleChangeAdv(id, index) {
      if (this.activeAdvId === id) {
        return
      }
      this.activeAdvId = id
      this.onClickSearch()
    },

    // 点击确定按钮
    clickConfirm() {
      if (!this.selectedData.length) {
        this.$message.warning('请选择转化追踪')
        return
      }
      let data = this.selectedData.map(v => {
        return {
          advertiserId: v.advertiserId,
          baseId: this.baseId,
          id: v.id || '',
          awemeId: v.awemeId,
          awemeName: v.awemeName
        }
      })
      this.isLoading = true
      saveConfigDouyin(data).then(res => {
        if (res.code === 200) {
          this.$emit('confirm', res.data)
          this.closeModal()
        }
      }).finally(() => {
        this.isLoading = false
      })
    },
    /* --------------------------- 按钮点击 -------------------------- */
    // 获取页码跳转数据
    getPageData() {
      this._getPageList()
    },
    // 同步
    handleSyncList() {
      this._getPageList()
      // let advIds = this.advertisers.map(v => v.advertiserId).join(',')
      // syncDouyinList(this.housekeeperId, advIds).then(res => {
      //   if (res.code === 200) {
      //     this._getPageList()
      //   }
      // })
    }
  }
}
</script>

<style lang="scss">
.unselected-all-table {
  .input-append-btn .el-input-group__append {
    background-color: #409eff;
    color: #fff;
  }

  .el-table th.el-table__cell>.cell .el-checkbox {
    display: none;
  }
}
</style>
<style scoped lang="scss">
@import '~@/assets/style/variables';
@import '~@/assets/style/mixin';

.el-row {
  margin-top: 10px;

  .template {
    padding: 0 !important;
    border: 1px #ccc solid;
    height: 400px;
    margin-right: 10px;

    .template-header {
      color: #333;
      font-size: 12px;
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid #ccc;

      span {
        margin: 0 10px;
      }
    }

    .tamplate-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 350px;
      box-sizing: border-box;

      .tamplate-content-title {
        height: 35px;
        border-bottom: 1px solid #ccc;
        color: #000;
        font-size: 16px;
        line-height: 35px;
        padding-left: 5px;
      }

      .tamplate-content-middle {
        height: 350px;
        overflow: auto;

        .tamplate-content-item {
          box-sizing: border-box;
          width: 100%;
          padding-left: 5px;
        }
      }
    }
  }

  .select {
    padding: 0 !important;
    border: 1px #ccc solid;
    height: 400px;

    .select-header {
      display: flex;
      justify-content: space-between;
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid #ccc;

      .selected {
        margin-left: 10px;
      }

      .clear {
        margin-right: 10px;
        color: #197afb;
        cursor: pointer;
      }
    }

    .select-content {
      padding: 0 20px;
      box-sizing: border-box;

      .select-content-item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
          font-size: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #666;
        }
      }
    }
  }
}

.table-template {
  .el-col {
    padding: 0 !important;
  }

  .copy-type {
    margin-bottom: 10px;
    width: 150px;
    box-sizing: border-box;
    display: flex;
    color: #909399;
    font-size: 16px;
    font-weight: 700;
    border-bottom: 1px solid #909399;

    .copy-type-item {
      margin-right: 15px;
      padding: 5px;
      cursor: pointer;
    }

    .isSelect {
      color: rgb(52, 92, 224);
      border-bottom: 2px solid rgb(52, 92, 224);
    }
  }

  .search-form-container {
    .el-form {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .flex-btn {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .left {
      margin-right: 10px;
    }
  }

  .page-container {
    margin-bottom: 50px;
    padding: 0 10px;

    .page-name {
      border: 1px solid #dcdfe6;
      height: 440px;
      overflow: auto;

      .name {
        padding: 10px;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        // border-bottom: 1px solid #ccc;
      }

      .is-active {
        color: #409eff;
        font-weight: 700;
        background: #ecf5ff;
      }
    }

    .middle-content {
      padding: 0 20px !important;
    }

    .comp-selected-box {
      padding: 10px;
      box-sizing: border-box;

      .comp-title {
        color: #1a1a1a;
        font-size: 16px;
        font-weight: 500;
      }

      .comp-selected-item {
        color: #606266;
        background: #ecf5ff;
        padding: 6px;
        border-radius: 4px;
        margin: 10px 0;
      }

      .comp-del-icon {
        float: right;
        position: relative;
        top: 4px;
        font-size: 14px;
        cursor: pointer;
      }
    }

    .btn-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;

      .operation-btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .table-info {
          padding: 10px 0;
          color: #333a3f;
          font-size: 16px;
          margin-left: 12px;

          .number {
            color: #2842c8;
            font-size: 24px;
            margin: 0 5px;
          }
        }

        .el-button--small {
          padding: 6px 13px;
        }

        .btn-content {
          @include flex-center;
          height: 18px;

          .icon-container {
            @include flex-center;
            width: 18px;
            height: 18px;
            border-radius: 9px;
            // background: #1667FF;
            margin-right: 6px;

            img {
              width: 12px;
              height: 12px;
            }
          }
        }
      }

      .function-btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .function-btn {
          @include flex-center;
          width: 26px;
          height: 20px;
          background: white;
          transition: 0.2s;
          margin-left: 10px;
          position: relative;
          cursor: pointer;

          .table-columns-container {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 10;
            width: 150px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 2px 5px 0px rgba(229, 228, 234, 1);
            border-radius: 4px;
            padding-left: 10px;
            overflow: hidden;
            display: none;

            .table-columns-item {
              height: 30px;
              line-height: 30px;
            }
          }

          &:hover {
            background: #f2f2f2;

            .table-columns-container {
              display: block;
            }
          }

          img {
            width: 18px;
            height: 18px;
          }
        }
      }
    }

    .table-select-tip {
      height: 38px;
      padding-left: 17px;
      display: flex;
      align-items: center;
      color: #909399;
      font-size: 14px;
      background: #dbe9ff;
      border: 1px solid #4788ff;
      border-radius: 4px;

      img {
        width: 16px;
        height: 16px;
      }
    }

    .table-link {
      color: #606266;
      transition: 0.2s;
      text-decoration: none;

      &:hover {
        color: $themeColor;
        text-decoration: underline;
      }
    }

    .poster-container {
      @include flex-center;
      width: 36px;
      height: 36px;
      overflow: hidden;

      .poster {
        width: 36px;
        min-height: 36px;
      }
    }

    .opt-container {
      display: flex;
      justify-content: space-around;

      .opt-text {
        font-size: 14px;
      }
    }

    .cell-btn {
      font-size: 0;

      img {
        width: 18px;
        height: 18px;
      }

      .cell-btn-hover {
        display: none;
      }

      &:hover {
        .cell-btn-icon {
          display: none;
        }

        .cell-btn-hover {
          display: block;
        }
      }
    }

    .pagination-container {
      height: 64px;
      box-sizing: border-box;
    }
  }
}

::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}
</style>
