<template>
  <!--显示列弹框-->
  <el-dialog class="edit-info-dialog" :visible.sync="show" title="广告版位选择" :modal-append-to-body="false" width="500px"
    @close="onCloseDialog">
    <el-form ref="ruleForm" @submit.native.prevent>
      <el-form-item>
        <el-checkbox-group v-model="checkedVideos" @change="handleCheckedCitiesChangeVideos">
          <el-checkbox :label="'i'">视频号</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item>
        <el-checkbox-group v-model="checkedFriends">
          <el-checkbox :label="'a'" :disabled="!!checkedVideos.length">朋友圈</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="checkAllWechat" :disabled="!!checkedVideos.length" :indeterminate="isIndeterminateWechat"
          @change="handleCheckAllChangeWechat">微信公众号与小程序</el-checkbox>
        <div style="margin: 15px 15px">
          <el-checkbox-group v-model="checkedWchats" @change="handleCheckedCitiesChangeWechat">
            <el-checkbox v-for="site in wechatSites" :disabled="!!checkedVideos.length" :key="site.saveValue"
              size="medium" :label="site.saveValue">{{
                  site.secondFloor
              }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="checkAllTencent" :disabled="!!checkedVideos.length"
          :indeterminate="isIndeterminateTencent" @change="handleCheckAllChangeTencent">腾讯平台与内容媒体</el-checkbox>
        <div style="margin: 15px 15px">
          <el-checkbox-group v-model="checkedTencents" @change="handleCheckedCitiesChangeTencent">
            <el-checkbox v-for="site in tencentSites" :disabled="!!checkedVideos.length" :key="site.saveValue"
              size="medium" :label="site.saveValue">{{
                  site.secondFloor
              }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </el-form-item>
      <el-form-item>
        <el-checkbox-group v-model="checkedYoulianghui">
          <el-checkbox :label="'h'" :disabled="!!checkedVideos.length">优量汇</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">取 消</el-button>
      <el-button class="btn" type="primary" @click="clickConfirm">确 定 </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { wechatOptions, tencentOptions } from '../util'

export default {
  name: 'TaskSiteSetDialog',
  data() {
    return {
      show: false, // 是否显示弹框
      loading: false,
      taskAccountId: 0,
      wechatOptions,
      tencentOptions,
      taskSiteSet: [],
      checkedFriends: [],
      checkedVideos: [],
      checkedYoulianghui: [],
      checkedWchats: [],
      checkedTencents: [],
      checkAllWechat: false,
      isIndeterminateWechat: false,
      checkAllTencent: false,
      isIndeterminateTencent: false,
      wechatSites: [],
      tencentSites: []
    }
  },
  methods: {
    /* --------------------------- Actions --------------------------- */
    // 显示弹框
    showModal(taskSiteSet, wechatSites, tencentSites) {
      this.taskSiteSet = taskSiteSet
      this.wechatSites = wechatSites
      this.tencentSites = tencentSites
      this.show = true
      if (taskSiteSet) {
        const data = taskSiteSet.split('')
        data.forEach((item) => {
          if (item === 'a') {
            this.checkedFriends = ['a']
          }
          if (item === 'i') {
            this.checkedVideos = ['i']
          }
          if (item === 'h') {
            this.checkedYoulianghui = ['h']
          }
          if (wechatOptions.includes(item)) {
            this.checkedWchats.push(item)
          }
          if (tencentOptions.includes(item)) {
            this.checkedTencents.push(item)
          }
        })
        this.checkAllWechat = this.checkedWchats.length === wechatOptions.length
        this.isIndeterminateWechat = this.checkedWchats.length > 0 && this.checkedWchats.length < wechatOptions.length
        this.checkAllTencent = this.checkedTencents.length === tencentOptions.length
        this.isIndeterminateTencent = this.checkedTencents.length > 0 && this.checkedTencents.length < tencentOptions.length
      }
    },

    handleCheckedCitiesChangeVideos(val) {
      this.checkedFriends = []
      this.checkedYoulianghui = []
      this.checkedWchats = []
      this.checkedTencents = []
      this.checkAllWechat = false
      this.isIndeterminateWechat = false
      this.checkAllTencent = false
      this.isIndeterminateTencent = false
    },

    handleCheckAllChangeWechat(val) {
      this.checkedWchats = val ? wechatOptions : []
      this.isIndeterminateWechat = false
    },

    handleCheckedCitiesChangeWechat(val) {
      let checkedCount = val.length
      this.checkAllWechat = checkedCount === this.wechatSites.length
      this.isIndeterminateWechat = checkedCount > 0 && checkedCount < this.wechatSites.length
    },

    handleCheckAllChangeTencent(val) {
      this.checkedTencents = val ? tencentOptions : []
      this.isIndeterminateTencent = false
    },

    handleCheckedCitiesChangeTencent(val) {
      let checkedCount = val.length
      this.checkAllTencent = checkedCount === this.tencentSites.length
      this.isIndeterminateTencent = checkedCount > 0 && checkedCount < this.tencentSites.length
    },

    // 关闭弹框
    closeModal() {
      this.show = false
    },

    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      this.checkedFriends = []
      this.checkedVideos = []
      this.checkedYoulianghui = []
      this.checkedWchats = []
      this.checkedTencents = []
      this.checkAllWechat = false
      this.isIndeterminateWechat = false
      this.checkAllTencent = false
      this.isIndeterminateTencent = false
    },

    // 点击确定按钮
    clickConfirm() {
      let data = []
      if (this.checkedVideos.length) {
        data = this.checkedVideos
      } else {
        data = this.checkedFriends.concat(this.checkedWchats, this.checkedTencents, this.checkedYoulianghui)
      }

      if (data && data.length) {
        let siteSet = ''
        data.map((item) => {
          siteSet += item
        })
        // console.log(siteSet, 'ssssss')
        this.$emit('confirm', siteSet, siteSet === this.taskSiteSet)
        this.closeModal()
      } else {
        return this.$message.info('请选择广告版位')
      }
    }
  }
}
</script>
