<!--
 * @Description: 腾讯广告->定向模板
 * @Date: 2021-12-01 09:15:29
 * @LastEditTime: 2022-02-16 11:54:40
-->

<template>
  <div>
    <!-- 列表查询 -->
    <el-form ref="search" :inline="true" :model="search" class="search-form">
      <el-form-item label="定向名称" prop="name">
        <el-input v-model="search.corporation_name" placeholder="请输入定向名称"></el-input>
      </el-form-item>
      <el-form-item label="关联项目" prop="projectId">
        <el-input v-model="search.corporation_name" placeholder="请输入账号名称"></el-input>
      </el-form-item>
      <el-form-item label="关联产品" prop="productId">
        <el-input v-model="search.corporation_name" placeholder="请输入账号名称"></el-input>
      </el-form-item>
      <el-form-item label="创建人" prop="createBy">
        <el-input v-model="search.createBy" placeholder="请输入账号名称"></el-input>
      </el-form-item>
      <el-form-item label="创建时间" prop="date">
        <el-date-picker v-model="search.date" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" round @click="onSubmit">查询</el-button>
        <el-button type="primary" round @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>

    <div style="text-align:right">
      <router-link :to="{ name: 'createTencentOrientation' }" tag="span">
        <el-button type="primary" icon="el-icon-plus" round>
          新建定向
        </el-button>
      </router-link>
    </div>
    <!-- 表格 -->
    <TablePane :data-source="dataSource" @getPage="getPage"> </TablePane>
  </div>
</template>

<script>
import TablePane from '@/components/tablePane.vue' // authorize adMarketingApiClientsSelect
// import { pageTargeting, saveTargeting, updateTargeting, deleteTargeting } from '@/network/api/passBack/index.js'
import { pageTargeting } from '@/network/api/assetManagement/assetManageTencent.js'
import moment from 'moment'

export default {
  components: {
    TablePane
  },
  data() {
    return {
      search: {
        name: null,
        projectId: -1,
        productId: -1,
        createBy: null,
        date: null
      },
      dataSource: {
        data: [],
        cols: [
          {
            label: '名称',
            prop: 'name'
          },
          {
            label: '描述',
            prop: 'description'
          },
          {
            label: '类型',
            render: function(record) {
              return record.content === 'PUBLIC' ? '公众号' : '投放广告'
            }
          },
          {
            label: '投放范围',
            render: function(record) {
              return record.scope === 'PUBLIC' ? '公众号' : '投放广告'
            }
          },
          {
            label: '项目',
            prop: 'projectId'
          },
          {
            label: '产品',
            prop: 'productId'
          },
          {
            label: '创建时间',
            render: function(record) {
              return moment(record.createTime).format('YYYY-MM-DD HH:mm:ss')
            }
          },
          {
            label: '关联计划数',
            prop: 'campaignNum'
          }
        ], // 表格的列数据
        isIndex: true, // 列表序号
        loading: false, // loading
        isOperation: true,
        pageData: {
          total: 0 // 总条数
        },
        handleSelectionChange() {},
        operation: {
          // 表格有操作列时设置
          label: '操作', // 列名
          width: 200, // 根据实际情况给宽度
          data: [
            {
              label: '编辑',
              type: 'text',
              handleRow: (index, record) => {
                this.advInfo_visible = true
              }
            }
          ]
        }
      }
    }
  },

  mounted() {
    this.getPage()
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    resetForm(formName) {
      this.$refs[formName].resetFields()
    },

    /**
     * @description: 获取列表
     * @param {*} params
     */
    getPage(params = { pageNum: 1, pageSize: 10 }) {
      this.dataSource.loading = true
      let search = {
        ...this.search
      }
      pageTargeting({ ...search, ...params }).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          // let newRecords = records.map((item) => {
          //   return {
          //     ...item,
          //     public_num: item.rule_range === 'PUBLIC' ? item.accounts.length || 0 : '无',
          //     plan_num: item.rule_range === 'AD' ? item.accounts.length || 0 : '无',
          //     logNum: item.logNum === 0 ? '无' : item.logNum
          //   }
          // })
          this.dataSource.data = records
          this.dataSource.pageData.total = total
          this.dataSource.loading = false
        }
      })
    }
  }
}
</script>
