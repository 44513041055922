import service from '@/network/service/service'

export function Page(data) {
  return service.post('/api/system/assetManagementBaiduTrack/page', data)
}

export function Add(data) {
  return service.post('/api/system/assetManagementBaiduTrack/save', data)
}

export function Update(data) {
  return service.post('/api/system/assetManagementBaiduTrack/update', data)
}

export function Delete(id) {
  return service.delete('/api/system/assetManagementBaiduTrack/' + id)
}

export function synchBaiduTrach(data) {
  return service.get('/api/system/assetManagementBaiduTrack/synchBaiduTrach?' + data)
}
export function shareBaiduTrach(data) {
  return service.get('/api/system/assetManagementBaiduTrack/shareBaiduTrach?' + data)
}
export function activationTrack(data) {
  return service.get('/api/system/assetManagementBaiduTrack/activationTrack?' + data)
}
// export function activationTrack1(data) {
//   return service.get('/api/system/assetManagementBaiduTrack/activationTrack1?' + data)
// }

export function activationTrack1(data) {
  return service.post('/api/system/assetManagementBaiduTrack/activationTrack1', data)
}
