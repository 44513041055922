<!--
 * @Description: 企微客服组
 * @Date: 2021-12-06 15:57:16
 * @LastEditTime: 2022-03-24 14:09:59
-->
<template>
  <div class="customer">
    <el-dialog title="企微客服组" :visible="visible" width="800px" :modal-append-to-body="false" :before-close="customerClose">
      <span>
        <el-table :data="customerData.list" style="width: 100%" border>
          <el-table-column label="公众号名称" prop="name" align="center" />

          <el-table-column label="绑定企微ID" type="corpid" align="center">
            <template slot-scope="{ row }">
              <el-select v-if="row.appids && row.appids.length" v-model="row.corpid" filterable size="small" placeholder="请选择绑定企微ID">
                <el-option v-for="item in row.appids" :key="item.id" :value="item.bindWorkwxAppid">{{ item.bindWorkwxAppid }}</el-option>
              </el-select>
              <el-input v-else v-model="row.corpid" style="width: 203px" placeholder="请输入绑定企微ID" size="small" />
            </template>
          </el-table-column>

          <el-table-column label="客服组ID" type="groupid" align="center">
            <template slot-scope="{ row }">
              <el-select v-if="row.appids && row.appids.length" v-model="row.groupid" filterable size="small" placeholder="请选择客服组ID">
                <el-option v-for="item in row.appids" :key="item.id" :value="item.kefuGroup">{{ item.kefuGroup }}</el-option>
              </el-select>
              <el-input v-else v-model="row.groupid" style="width: 203px" placeholder="请输入客服组ID" size="small" />
            </template>
          </el-table-column>

          <!-- <el-table-column label=""  align="center" width="60">
            <template slot-scope="scope">
              <i class="el-icon-delete" @click="deleteItem(scope.row.taskServiceId, scope.$index)"></i>
            </template>
          </el-table-column> -->
        </el-table>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="customerClose">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { saveService, deleteService, updateService, getAccountInterface } from '@/network/api/advManagement/advManagementWatch'
// updateService
export default {
  inject: ['customerClose'],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    accountAdv: {
      type: Array,
      default: () => []
    },
    formData: {
      type: Array,
      default: () => []
    },
    taskId: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      customerData: {},
      groupidList: [],
      corpidList: []
    }
  },

  watch: {
    formData: {
      handler(value) {
        this.customerData = value
      },
      immediate: true,
      deep: true
    },
    visible: {
      handler(value) {
        if (value) {
          this.getAccountInterfaceFun()
        }
      },
      immediate: true
    }
  },

  methods: {
    async getAccountInterfaceFun() {
      const { code, data } = await getAccountInterface({ page: 1, size: 100, accountId: this.formData.list.map((item) => item.ghid) + '' })
      if (code === 200) {
        data.data.records.map((item) => {
          this.customerData.list.forEach((element) => {
            if (item.accountId === element.ghid && !element.appids.some((s) => s.bindWorkwxAppid === element.corpid)) {
              element.appids.push(item)
              element.corpid = item.bindWorkwxAppid || null
              element.groupid = item.kefuGroup || null
            }
          })
        })
      }
    },

    async deleteItem(id, index) {
      if (id) {
        await deleteService(id)
      }
      this.customerData.list.splice(index, 1)
    },

    /**
     * @desc 获取选中的数据后提交
     */
    submit() {
      let createParams = []
      let updateParams = []
      if (this.customerData.list.some((s) => !s.corpid || !s.groupid)) return this.$message.error('请填写客服组信息')
      this.customerData.list.map((item) => {
        if (item.taskServiceId) {
          updateParams.push({
            taskId: this.taskId,
            taskServiceId: item.taskServiceId,
            name: item.name,
            ghid: item.ghid,
            corpid: item.corpid,
            groupid: item.groupid
          })
        } else {
          createParams.push({
            taskId: this.taskId,
            name: item.name,
            ghid: item.ghid,
            corpid: item.corpid,
            groupid: item.groupid
          })
        }
      })
      if (createParams.length) {
        saveService({ adMpManagementTaskPlanCustomerServiceENTS: createParams }).then((res) => {
          if (res.code === 200) {
            this.$emit('byTaskIdGetAllFun')
            this.customerClose()
          }
        })
      }
      if (updateParams.length) {
        updateService({ adMpManagementTaskPlanCustomerServiceENTS: updateParams }).then((res) => {
          if (res.code === 200) {
            this.$emit('byTaskIdGetAllFun')
            this.customerClose()
          }
        })
      }
      this.$message.success('企微客服组添加成功!')
    }
  }
}
</script>

<style lang="scss">
.handleTree-icon :hover {
  color: #409eff;
}

.avatar-uploader {
  .avatar-uploader-icon {
    font-size: 28px;
    color: #eae9f0;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border: 1px solid #f2f2f2;
    border-radius: 4px;
  }
  .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
}
.info {
  color: #ccc;
  p {
    margin: 0;
    padding: 0;
    font-size: 12px;
    line-height: 20px;
  }
}

.customer {
  .custom-tree-node {
    // flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    width: 100px !important;
    .label {
      width: 300px !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
</style>
