<template>
  <!--创意组件弹框-->
  <el-dialog class="table-template" title="创意组件" :visible.sync="show" :append-to-body="true" width="1200px" top="20vh"
    @close="onCloseDialog">
    <div class="page-container unselected-all-table">
      <el-row :gutter="20">
        <el-col :span="5" class="page-name">
          <div v-for="(item, idx) in advertisers" :key="idx"
            :class="['name', item.advertiserId === activeAdvId ? 'is-active' : '']"
            @click="handleChangeAdv(item.advertiserId, idx)">{{ item.name }}</div>
        </el-col>
        <el-col :span="12" class="middle-content">
          <el-row>
            <el-col :span="17">
              <el-input style="width: 250px" placeholder="请输入组件名称" v-model="componentName" class="input-append-btn">
                <el-button slot="append" type="primary" @click="onClickSearch">查询</el-button>
              </el-input>
            </el-col>
            <el-col :span="7" style="text-align:right">
              <el-button type="text" icon="el-icon-refresh" :loading="syncLoading" @click="handleSyncList">同步
              </el-button>
            </el-col>
          </el-row>

          <el-table ref="multipleTable" :row-key="row => { return row.componentId }" :data="tableData"
            v-loading="loading" @select="handleCurrentChange" @select-all="handleAllChange">
            <el-table-column type="selection" label="选择" :selectable="checkSelectable" :reserve-selection="true">
            </el-table-column>
            <el-table-column label="缩略图" align="center">
              <template slot-scope="{ row }">
                <div class="thumb-box"
                  style=" width: 60px;height: 60px;margin: auto;display: flex;align-items: center;">
                  <el-image :src="row.imageUrl" fit="contain"></el-image>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="名称" show-overflow-tooltip align="center">
              <span slot-scope="{ row }">{{ row.componentName }}</span>
            </el-table-column>
            <el-table-column label="ID" show-overflow-tooltip align="center">
              <span slot-scope="{ row }">{{ row.componentId }}</span>
            </el-table-column>
            <el-table-column label="状态" show-overflow-tooltip align="center">
              <span slot-scope="{ row }" :style="{ 'color': row.status == 'REJECT' ? '#F56C6C' : '#409EFF' }">{{
                  row.componentStatus
              }}</span>
            </el-table-column>
          </el-table>
          <div class="pagination-container">
            <c-pagination :page-size.sync="pageSize" :page-no.sync="pageNum" :pager-count="5" :total="total"
              tools="total, prev, pager, next" :get-data-fun="getPageData" />
          </div>
        </el-col>
        <el-col :span="7">
          <div class="comp-selected-box page-name">
            <div class="comp-title">已选组件</div>
            <div class="comp-selected-content">
              <div class="comp-selected-item" v-for="(item, index) in selectedData" :key="index">
                <template>
                  <span class="comp-name">{{ item.componentName }}</span>
                  <span class="el-icon-close comp-del-icon" @click="handleDeleteSelected(index)"></span>
                </template>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">取 消</el-button>
      <el-button class="btn" type="primary" :loading="isLoading" @click="clickConfirm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getCreativeComponentList, syncCreativeCompList } from '@/network/api/api-huge-batch'
import { PageCommonComponent } from '@/common/mixin'
export default {
  name: 'CreativeComponentDialog',
  components: {},
  filters: {},
  mixins: [PageCommonComponent],
  props: {},
  data() {
    return {
      show: false,
      loading: false,
      isLoading: false, // 是否正在保存
      syncLoading: false,
      tableData: [], // 当前投放账户列表
      selectedData: [], // 当前投放账户选中的数据
      component_types: [],
      componentStatus: [ // 创意组件状态
        { dictLabel: '审核通过', dictValue: 'PASS' },
        { dictLabel: '审核中', dictValue: 'UNDER' },
        { dictLabel: '未通过', dictValue: 'REJECT' }
      ],
      advertisers: [], // 投放账户数据
      housekeeperId: '', // 管理账户
      activeAdvId: '', // 当前选中账户id
      componentName: '', // 搜索创意组件名称
      pageNum: 1,
      pageSize: 5,
      total: 0
    }
  },
  computed: {},
  watch: {},
  created() { },
  mounted() { },
  methods: {
    /* --------------------------- Actions --------------------------- */
    showModal(data) {
      const { advertisers, housekeeperId, components } = data
      this.housekeeperId = housekeeperId
      this.advertisers = advertisers || []
      this.show = true
      this.activeAdvId = this.advertisers[0].advertiserId
      let comp = components.map(v => {
        return {
          componentId: v.comId,
          componentName: v.comName,
          advertiserId: v.advId
        }
      })
      this.selectedData = comp || []
      this._getCreativeComponentList()
    },

    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      // 重置表单
      this.activeAdvId = ''
      this.tableData = []
      this.selectedData = []
      this.component_types = []
      this.componentName = ''
      this.pageNum = 1
      this.pageSize = 10
      this.total = 0
      this.$refs.multipleTable.clearSelection()
    },
    // 关闭弹框
    closeModal() {
      this.show = false
    },

    /* --------------------------- 数据接口加载 -------------------------- */
    // 获取创意组件
    async _getCreativeComponentList() {
      await this.getDicts('component_types').then((response) => {
        this.component_types = response.data
      })
      let params = {
        componentName: this.componentName,
        advId: this.activeAdvId,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      this.loading = true
      await getCreativeComponentList(params).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.records.map((item) => {
            item.advertiserId = this.activeAdvId
            item.type = this.component_types.filter((f) => item.componentType === f.dictValue)[0].dictLabel
            item.componentStatus = this.componentStatus.filter((f) => item.status === f.dictValue)[0].dictLabel
            return item
          })
          this.total = res.data.total

          // 选中数据回显
          if (this.selectedData.length) {
            this.echo(this.tableData)
          }
          // for (let i = 0; i < this.tableData.length; i++) {
          //   if (this.selectedData[this.index].length > 0) {
          //     for (let j = 0; j < this.selectedData[this.index].length; j++) {
          //       if (this.tableData[i].componentId === this.selectedData[this.index][j].componentId) {
          //         this.$nextTick(() => this.$refs.multipleTable.toggleRowSelection(this.tableData[i], true))
          //       }
          //     }
          //   }
          // }
        }
      }).finally(() => {
        this.loading = false
      })
    },

    // 表格数据回显
    echo(data) {
      let rows = []
      data.forEach(item => {
        this.selectedData.forEach(item2 => {
          if (item.componentId === item2.componentId) {
            rows.push(item)
          }
        })
      })
      this.toggleSelection(rows)
    },
    // 勾选表格选中
    toggleSelection(rows) {
      if (rows.length) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row, true)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    /* --------------------------- 数据接口加载 -------------------------- */

    /* --------------------------- 按钮点击 -------------------------- */
    // 判断选中状态
    checkSelectable(row) {
      let flag = true
      let item = this.selectedData.filter(v => v.advertiserId === row.advertiserId)
      if (item && item.length) {
        flag = false
      } else {
        flag = true
      }
      // for (let i = 0; i < this.selectedData.length; i++) {
      //   if (row.componentId === this.selectedData[i].componentId) {
      //     flag = true
      //     break
      //   } else {
      //     flag = false
      //     break
      //   }
      // }
      return flag
    },
    // 表格全选
    handleAllChange(selection) {
      if (selection.length) {
        let ids = this.selectedData.map(v => v.componentId)
        selection.forEach(item => {
          if (!ids.includes(item.componentId)) {
            this.selectedData.push(item)
          }
        })
      } else {
        this.tableData.forEach(item => {
          this.selectedData.forEach((item2, index) => {
            if (item.componentId === item2.componentId) {
              this.selectedData.splice(index, 1)
            }
          })
        })
      }
    },
    // table 选择 cell 时调用
    handleCurrentChange(selection, row) {
      let ids = this.selectedData.map(v => v.componentId)
      if (!ids.includes(row.componentId)) {
        this.selectedData.push(row)
      } else {
        let index = this.selectedData.findIndex(v => v.componentId === row.componentId)
        this.selectedData.splice(index, 1)
      }
    },

    // 删除已选组件
    handleDeleteSelected(index) {
      let selected = this.selectedData.splice(index, 1)
      let list = this.tableData
      if (selected.length) {
        list.forEach((obj, index) => {
          selected.forEach(item => {
            if (obj.componentId === item.componentId) {
              setTimeout(() => {
                this.$nextTick(() => this.$refs.multipleTable.toggleRowSelection(list[index], false))
              }, 10)
            }
          })
        })
      }
    },

    // 点击搜索
    onClickSearch() {
      if (this.pageNum === 1) {
        this._getCreativeComponentList()
      } else {
        this.pageNum = 1
      }
    },

    // 切换顶级投放账户
    handleChangeAdv(id, index) {
      if (this.activeAdvId === id) {
        return
      }
      this.activeAdvId = id
      this.onClickSearch()
    },
    // 点击确定按钮
    clickConfirm() {
      if (!this.selectedData.length) {
        this.$message.warning('请选择组件')
        return
      }
      let componentIds = this.selectedData.map(v => {
        return {
          advId: v.advertiserId,
          comId: v.componentId,
          comName: v.componentName
        }
      })

      this.$emit('confirm', componentIds, componentIds.length)
      this.closeModal()
    },
    /* --------------------------- 按钮点击 -------------------------- */

    // 获取页码跳转数据
    getPageData() {
      this._getCreativeComponentList()
    },
    // 同步
    handleSyncList() {
      let advIds = this.advertisers.map(v => v.advertiserId).join(',')
      this.syncLoading = true
      syncCreativeCompList(this.housekeeperId, advIds).then(res => {
        if (res.code === 200) {
          this._getCreativeComponentList()
        }
      }).finally(() => {
        this.syncLoading = false
      })
    }
  }
}
</script>

<style lang="scss">
.unselected-all-table {
  .input-append-btn .el-input-group__append {
    background-color: #409eff;
    color: #fff;
  }

  .el-table th.el-table__cell>.cell .el-checkbox {
    display: none;
  }
}
</style>
<style scoped lang="scss">
@import '~@/assets/style/variables';
@import '~@/assets/style/mixin';

.el-row {
  margin-top: 10px;

  .template {
    padding: 0 !important;
    border: 1px #ccc solid;
    height: 400px;
    margin-right: 10px;

    .template-header {
      color: #333;
      font-size: 12px;
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid #ccc;

      span {
        margin: 0 10px;
      }
    }

    .tamplate-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 350px;
      box-sizing: border-box;

      .tamplate-content-title {
        height: 35px;
        border-bottom: 1px solid #ccc;
        color: #000;
        font-size: 16px;
        line-height: 35px;
        padding-left: 5px;
      }

      .tamplate-content-middle {
        height: 350px;
        overflow: auto;

        .tamplate-content-item {
          box-sizing: border-box;
          width: 100%;
          padding-left: 5px;
        }
      }
    }
  }

  .select {
    padding: 0 !important;
    border: 1px #ccc solid;
    height: 400px;

    .select-header {
      display: flex;
      justify-content: space-between;
      height: 50px;
      line-height: 50px;
      border-bottom: 1px solid #ccc;

      .selected {
        margin-left: 10px;
      }

      .clear {
        margin-right: 10px;
        color: #197afb;
        cursor: pointer;
      }
    }

    .select-content {
      padding: 0 20px;
      box-sizing: border-box;

      .select-content-item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
          font-size: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #666;
        }
      }
    }
  }
}

.table-template {
  .el-col {
    padding: 0 !important;
  }

  .copy-type {
    margin-bottom: 10px;
    width: 150px;
    box-sizing: border-box;
    display: flex;
    color: #909399;
    font-size: 16px;
    font-weight: 700;
    border-bottom: 1px solid #909399;

    .copy-type-item {
      margin-right: 15px;
      padding: 5px;
      cursor: pointer;
    }

    .isSelect {
      color: rgb(52, 92, 224);
      border-bottom: 2px solid rgb(52, 92, 224);
    }
  }

  .search-form-container {
    .el-form {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .flex-btn {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .left {
      margin-right: 10px;
    }
  }

  .page-container {
    margin-bottom: 50px;
    padding: 0 10px;

    .page-name {
      border: 1px solid #dcdfe6;
      height: 530px;
      overflow: auto;

      .name {
        padding: 10px;
        cursor: pointer;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        // border-bottom: 1px solid #ccc;
      }

      .is-active {
        color: #409eff;
        font-weight: 700;
        background: #ecf5ff;
      }
    }

    .middle-content {
      padding: 0 20px !important;
    }

    .comp-selected-box {
      padding: 10px;
      box-sizing: border-box;

      .comp-title {
        color: #1a1a1a;
        font-size: 16px;
        font-weight: 500;
      }

      .comp-selected-item {
        color: #606266;
        background: #ecf5ff;
        padding: 6px;
        border-radius: 4px;
        margin: 10px 0;
      }

      .comp-del-icon {
        float: right;
        position: relative;
        top: 4px;
        font-size: 14px;
        cursor: pointer;
      }
    }

    .btn-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;

      .operation-btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .table-info {
          padding: 10px 0;
          color: #333a3f;
          font-size: 16px;
          margin-left: 12px;

          .number {
            color: #2842c8;
            font-size: 24px;
            margin: 0 5px;
          }
        }

        .el-button--small {
          padding: 6px 13px;
        }

        .btn-content {
          @include flex-center;
          height: 18px;

          .icon-container {
            @include flex-center;
            width: 18px;
            height: 18px;
            border-radius: 9px;
            // background: #1667FF;
            margin-right: 6px;

            img {
              width: 12px;
              height: 12px;
            }
          }
        }
      }

      .function-btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .function-btn {
          @include flex-center;
          width: 26px;
          height: 20px;
          background: white;
          transition: 0.2s;
          margin-left: 10px;
          position: relative;
          cursor: pointer;

          .table-columns-container {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 10;
            width: 150px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 2px 5px 0px rgba(229, 228, 234, 1);
            border-radius: 4px;
            padding-left: 10px;
            overflow: hidden;
            display: none;

            .table-columns-item {
              height: 30px;
              line-height: 30px;
            }
          }

          &:hover {
            background: #f2f2f2;

            .table-columns-container {
              display: block;
            }
          }

          img {
            width: 18px;
            height: 18px;
          }
        }
      }
    }

    .table-select-tip {
      height: 38px;
      padding-left: 17px;
      display: flex;
      align-items: center;
      color: #909399;
      font-size: 14px;
      background: #dbe9ff;
      border: 1px solid #4788ff;
      border-radius: 4px;

      img {
        width: 16px;
        height: 16px;
      }
    }

    .table-link {
      color: #606266;
      transition: 0.2s;
      text-decoration: none;

      &:hover {
        color: $themeColor;
        text-decoration: underline;
      }
    }

    .poster-container {
      @include flex-center;
      width: 36px;
      height: 36px;
      overflow: hidden;

      .poster {
        width: 36px;
        min-height: 36px;
      }
    }

    .opt-container {
      display: flex;
      justify-content: space-around;

      .opt-text {
        font-size: 14px;
      }
    }

    .cell-btn {
      font-size: 0;

      img {
        width: 18px;
        height: 18px;
      }

      .cell-btn-hover {
        display: none;
      }

      &:hover {
        .cell-btn-icon {
          display: none;
        }

        .cell-btn-hover {
          display: block;
        }
      }
    }

    .pagination-container {
      height: 64px;
      box-sizing: border-box;
    }
  }
}

::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}
</style>
