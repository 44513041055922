var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c(
      "div",
      {
        style: {
          paddingLeft: "0px",
          paddingRight: "0px",
          paddingTop: `${_vm.detail.paddingTop}px`,
          paddingBottom: `${_vm.detail.paddingBottom}px`,
          boxSizing: "border-box",
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "button",
            style: {
              paddingLeft: "10px",
              paddingRight: "10px",
              width: _vm.detail.btnSize == "large" ? "100%" : "50%",
              height: _vm.detail.btnHeight + "px",
              background: _vm.detail.btnBgColorTheme,
              color: _vm.detail.btnFontColor,
              fontSize: _vm.detail.btnFontSize + "px",
              border: `1px solid ${_vm.detail.btnBorderColorTheme}`,
              fontWeight: _vm.detail.btnFontType,
              textAlign: _vm.detail.textAlignment,
            },
          },
          [_c("p", { domProps: { innerHTML: _vm._s(_vm.detail.btnTitle) } })]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }