var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.isEdit ? "编 辑" : "新 增",
        width: "45%",
        visible: _vm.visible,
        "before-close": _vm.close,
        "label-position": "right",
        "modal-append-to-body": false,
        "close-on-press-escape": false,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ent",
          attrs: { model: _vm.ent, rules: _vm.rules, "label-width": "110px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "标签分类名称", prop: "labelTypeName" } },
            [
              _c("el-input", {
                attrs: {
                  maxlength: "15",
                  placeholder: "最大输入长度15 超出截断",
                },
                model: {
                  value: _vm.ent.labelTypeName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "labelTypeName", $$v)
                  },
                  expression: "ent.labelTypeName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "关联项目", prop: "relationProjectId" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    multiple: "",
                    filterable: "",
                    placeholder: "请选择",
                  },
                  on: { change: _vm.selectChange },
                  model: {
                    value: _vm.ent.relationProjectId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ent, "relationProjectId", $$v)
                    },
                    expression: "ent.relationProjectId",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.bsProjectId,
                    attrs: { label: item.projectName, value: item.bsProjectId },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { attrs: { round: "" }, on: { click: _vm.close } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-popconfirm",
            {
              staticClass: "ml-10",
              attrs: {
                "confirm-button-text": "提交",
                "cancel-button-text": "取消",
                icon: "el-icon-info",
                "icon-color": "red",
                title: "确定提交？",
              },
              on: { confirm: _vm.submit },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { slot: "reference", type: "primary", round: "" },
                  slot: "reference",
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }