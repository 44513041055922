<!--
 * @Author: Zeng
 * @Date: 2024-03-13 11:36:58
 * @LastEditTime: 2024-03-29 16:08:51
-->
<template>
  <div>
    <el-form ref="form" class="custom-landingpage-form" :inline="true" :model="form" :rules="rules" label-width="100px">
      <el-row :gutter="24">
        <el-col :span="6">
          <el-form-item label="名称" prop="toolPageName">
            <el-input v-model="form.toolPageName" placeholder="请输入落地页名称" style="width: 100%;" maxlength="30"
              show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="类型" prop="toolPageType">
            <el-select v-model="form.toolPageType" placeholder="请选择类型" style="width: 100%" @change="handleChangePageType">
              <el-option label="跳转页" :value="1"></el-option>
              <el-option label="召回页" :value="2"></el-option>
              <el-option label="循环页" :value="3"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <template v-if="isRecallOrLoop">
          <el-col :span="6">
            <el-form-item label="顶部名称" prop="toolPageTopName">
              <el-input v-model="form.toolPageTopName" placeholder="请输入落地页顶部名称" style="width: 100%;" maxlength="10"
                show-word-limit>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="背景色" prop="colors">
              <el-dropdown style="width: 28px">
                <div class="ldy-bg-color" :style="`background:${form.colors}`"></div>
                <el-dropdown-menu slot="dropdown">
                  <twitter-color :colors="form.colors" style="margin-top: 10px"
                    @change="(value) => (form.colors = value)" />
                </el-dropdown-menu>
              </el-dropdown>
            </el-form-item>
          </el-col>
        </template>
      </el-row>
      <el-row v-if="isRecallOrLoop" :gutter="60" class="comp-box">
        <!-- 选择区 -->
        <el-col :span="7" style="min-width: 450px">
          <div class="grid-content bg-purple">
            <WidgetGallery :seleted-widgets-index="selectedWidgetsIndex" @setFinalWidgets="setFinalWidgets" />
          </div>
        </el-col>

        <!-- 工作区 -->
        <el-col style="width: 415px; margin-right: 60px;">
          <div ref="benchOptContainer" class="grid-content bg-purple workbench" :style="`background:${form.colors}`">
            <Workbench :final-widgets="finalWidgets" :seleted-widgets-index="selectedWidgetsIndex"
              @updateFinalWidgets="updateFinalWidgets" @setFinalWidgetsCount="setFinalWidgetsCount"
              @setSelectedIndex="setSelectedIndex" />
          </div>
        </el-col>

        <!-- 编辑区 -->
        <el-col :xl="10" :lg="8" style="min-width: 450px">
          <div class="grid-content bg-purple">
            <AdEditors ref="adEditors" :final-widgets="finalWidgets" :seleted-widgets-index="selectedWidgetsIndex"
              :platforms="platforms" />
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <!-- 【类型】为“召回页”时，【默认跳转】默认“开启”，不可修改 -->
        <el-col v-if="isRecallOrLoop" :span="6">
          <el-form-item label="默认跳转" prop="isSkip">
            <el-select v-model="form.isSkip" placeholder="请选择默认跳转" style="width: 100%" :disabled="true">
              <el-option label="开启" :value="true"></el-option>
              <el-option label="不开启" :value="false"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="平台" prop="platform"
            :rules="{ required: isRecallOrLoop ? false : true, message: '请选择平台', trigger: 'change' }">
            <el-select v-model="form.platform" placeholder="请选择平台" style="width: 100%" filterable>
              <el-option v-for="(item, index) in platforms" :key="index" :label="item.dictLabel"
                :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="关联内容" prop="relatedContent"
            :rules="{ required: isRecallOrLoop ? false : true, message: '请输入关联内容', trigger: 'blur' }">
            <el-input v-model="form.relatedContent" placeholder="请输入关联内容" style="width: 100%;" maxlength="30"
              show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <template v-if="!isRecallOrLoop || (isRecallOrLoop && !!form.isSkip)">
        <el-row :gutter="10">
          <el-col :span="6">
            <el-form-item label="链接类型" prop="linkType">
              <el-select v-model="form.linkType" placeholder="请选择链接类型" style="width: 100%">
                <el-option label="普通链接" :value="1"></el-option>
                <el-option label="获客助手链接" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="跳转链接" prop="skipLink">
              <el-input v-model="form.skipLink" type="textarea" :rows="3" resize="none" placeholder="请输入跳转链接"
                style="width: 100%;" maxlength="500" show-word-limit>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </template>

      <div class="form-footer">
        <el-button type="primary" :disabled="loading" @click="handleConfirm">保存</el-button>
        <el-button @click="handleCancel">取 消</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { updateCustomLandingPage, getCustomLandingPage } from '@/network/api/toolManagement/api-landing-page'
import { jsonFormat } from '@/utils/ruoyi'
import { pathValidator } from '@/common/utils/validate'
import Twitter from '@/components/twitterColor.vue'
import WidgetGallery from './components/widgetGallery.vue'
import Workbench from './components/workbench.vue'
import AdEditors from './components/adEditors.vue'
import { mapMutations } from 'vuex'

export default {
  components: {
    'twitter-color': Twitter,
    WidgetGallery,
    Workbench,
    AdEditors
  },
  data() {
    var urlValidate = (rule, value, callback) => {
      if (value && !pathValidator(value)) {
        callback(new Error('请检查URL是否正确'))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      isEdit: false, // 是否正在编辑落地页
      form: {
        toolPageName: '',
        toolPageType: 1,
        platform: '',
        relatedContent: '',
        linkType: 2,
        skipLink: '',
        toolPageTopName: '',
        colors: '#FFFFFF',
        isSkip: true
      },
      rules: {
        toolPageName: [{ required: true, message: '请输入落地页名称', trigger: 'blur' }],
        toolPageTopName: [{ required: true, message: '请输入落地页顶部名称', trigger: 'blur' }],
        toolPageType: [{ required: true, message: '请选择类型', trigger: 'change' }],
        platform: [{ required: true, message: '请选择平台', trigger: 'change' }],
        relatedContent: [{ required: true, message: '请输入关联内容', trigger: 'blur' }],
        linkType: [{ required: true, message: '请选择链接类型', trigger: 'change' }],
        isSkip: [{ required: true, message: '请选择默认跳转', trigger: 'change' }],
        skipLink: [{ required: true, message: '请输入跳转链接', trigger: 'blur' }, { validator: urlValidate, trigger: 'blur' }]
      },
      platforms: [],

      finalWidgets: [], // 选择的组件集合
      finalWidgetsCount: 0, // 选择的组件个数
      selectedWidgetsIndex: -1 // 当前选中的索引
    }
  },

  computed: {
    // 召回or循环页
    isRecallOrLoop() {
      return this.form.toolPageType === 2 || this.form.toolPageType === 3
    }
  },

  watch: {
    finalWidgets: {
      handler(value) {
        // console.log(value, this.$refs.benchOptContainer)
        setTimeout(() => {
          let containerHeight = this.$refs.benchOptContainer ? this.$refs.benchOptContainer.offsetHeight : 0
          this.SET_MARGIN_TOP(containerHeight)
        }, 500)
      },
      // immediate: true,
      deep: true
    }
  },

  created() {
    if (this.$route.params.id) {
      this.isEdit = this.$route.name.indexOf('edit') > -1
      this.getDetailById(this.$route.params.id)
    }
    this.getPlatforms()
  },

  mounted() { },
  methods: {
    ...mapMutations('landPage', ['SET_MARGIN_TOP']),
    async getDetailById(id) {
      try {
        let { data } = await getCustomLandingPage(id)
        let toolHtmlContent = data.toolHtmlContent ? jsonFormat(data.toolHtmlContent.replace(/&gt;/g, '>')) : []
        this.form = {
          ...this.form,
          ...data,
          toolPageType: +data.toolPageType,
          linkType: +data.linkType,
          isSkip: !!data.isSkip,
          colors: toolHtmlContent.length ? toolHtmlContent[0].backgroundColor : '#FFFFFF',
          toolHtmlContent: null
        }
        if (toolHtmlContent.length) {
          toolHtmlContent.shift()
          this.finalWidgets = toolHtmlContent
          this.finalWidgetsCount = toolHtmlContent.length - 1
          this.selectedWidgetsIndex = 0
        }

        if (this.isEdit) {
          this.form.toolPageId = data.toolPageId
        }
      } catch (error) {
        this.$message.error(error.msg)
      }
    },

    // 获取平台数据
    async getPlatforms() {
      try {
        let res = await this.getDicts('return_platform_type')
        this.platforms = res.data
      } catch (error) {
        console.log(error)
      }
    },

    /**
     * @description: 添加组件到工作区
     * @param {*} item 选择的组件类容
     */
    setFinalWidgets(item) {
      this.finalWidgets.push({
        ...item,
        id: this.finalWidgetsCount
      })
      this.selectedWidgetsIndex = this.finalWidgetsCount
      this.finalWidgetsCount++
    },

    /**
     * @description: 设置选择的组件集合数量
     * @param {*} count
     */
    setFinalWidgetsCount(count) {
      this.finalWidgetsCount = count
    },

    /**
     * @description: 设置当前选中的索引
     * @param {*} item 当前选中的索引
     */
    setSelectedIndex(index) {
      this.selectedWidgetsIndex = index
    },

    /**
    * @description: 修改FinalWidgets
    * @param {*} list
    */
    updateFinalWidgets(list) {
      this.finalWidgets = list
    },

    // 设置页面类型
    handleChangePageType(value) {
      switch (value) {
        case 2:
          this.form.isSkip = true
          break
        case 3:
          this.form.isSkip = false
          break
        default:
      }
    },

    // 点击确定
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return
        }

        let { toolPageId, toolPageName, toolPageType, toolPageTopName, colors, isSkip, platform, relatedContent, linkType, skipLink } = this.form

        let isValid = this.adEditorsValidate(this.finalWidgets || [])
        if ((toolPageType === 2 || toolPageType === 3) && !isValid) {
          return
        }

        let params = {
          toolPageName,
          toolPageType,
          isSkip,
          platform,
          relatedContent,
          linkType,
          skipLink
        }
        // 召回/循环 保存组件参数
        if (toolPageType === 2 || toolPageType === 3) {
          const toolHtmlContent = [
            {
              backgroundColor: colors,
              widgetTypeV2: 'body'
            },
            ...this.finalWidgets
          ]
          params = {
            ...params,
            toolPageTopName,
            toolHtmlContent: JSON.stringify(toolHtmlContent)
          }
        }
        // 编辑保存时带上id
        if (this.isEdit) {
          params.toolPageId = toolPageId
        }
        this.loading = true
        updateCustomLandingPage(params).then(({ code }) => {
          if (code === 200) {
            this.$message.success('落地页保存成功')
            this.$router.replace({
              name: 'customLandingPage'
            })
          }
        }).finally(() => {
          this.loading = false
        })
      })
    },

    // 验证落地页组件数据
    adEditorsValidate(data) {
      let flag = true
      try {
        if (!data.length) {
          flag = false
          return flag
        }
        data.map(v => {
          if (v.widgetTypeV2 === 'text' && !v.content.length) {
            throw new Error('请输入文本组件-文本内容')
          } else if (v.widgetTypeV2 === 'img') {
            if (!v.pureImageUrl) {
              throw new Error('请上传图片组件-图片')
            } else if (v.clickJump === 1 && !v.link) {
              throw new Error('请输入图片组件-跳转链接')
            }
          } else if (v.widgetTypeV2 === 'base') {
            if (!v.btnTitle) {
              throw new Error('请输入按钮组件-文案')
            } else if (!v.link) {
              throw new Error('请输入按钮组件-跳转链接')
            }
          }
        })
      } catch (error) {
        this.$message.error(error || '参数验证失败')
        flag = false
      }
      return flag
    },

    // 点击取消
    handleCancel() {
      this.$router.replace({
        name: 'customLandingPage'
      })
    }
  }
}
</script>

<style lang="scss">
.custom-landingpage-form {
  max-width: 80%;

  .form-footer {
    text-align: center;
    margin-top: 50px;
    padding-bottom: 150px;
  }

  .el-form-item {
    width: 100%;
  }

  .el-form-item .el-form-item__content {
    width: calc(100% - 100px);
  }

  .comp-box {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 20px;
    padding-left: 40px;
    box-sizing: border-box;
  }

  .bg-purple-dark {
    background: #f2f2f2;
  }

  .bg-purple {
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1), 0 0 6px rgba(0, 0, 0, 0.04);
  }

  .bg-purple-light {
    background: #e5e9f2;
  }

  .grid-content {
    border-radius: 4px;
    min-height: 36px;
    height: 100%;
  }

  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }

  .ldy-bg-color {
    width: 28px;
    height: 28px;
    border: 5px solid #bfc2c7;
    border-radius: 4px;
  }

  .workbench {
    min-height: 720px;
    width: 415px;
    background: #fcfcfc;
    // overflow: hidden auto;
    position: relative;
    box-sizing: border-box;
    border: solid 1px transparent;
  }
}
</style>
