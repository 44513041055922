/*
 * @Description:
 * @Date: 2021-10-13 16:03:30
 * @LastEditTime: 2022-04-15 14:06:03
 */
import service from '@/network/service/service'

export function Page(data) {
  return service.post('/api/kuaishou/accountManagementAccounts/page', data)
}

export function Add(appId) {
  return service.get(`/api/kuaishou/accountManagementAccounts/getOauthUrl/${appId}`)
}

// 获取主体
export function getSubject() {
  return service.get(`/api/kuaishou/accountManagementAccounts/getSubject`)
}
// 获取主体Byid
export function getSubjectById(id) {
  return service.get(`/api/kuaishou/accountManagementAccounts/getSubjectById/${id}`)
}

// 修改
export function accountsUpdate(data) {
  return service.post('/api/kuaishou/accountManagementAccounts/update', data)
}

//  批量操作
export function accountsBatch(data) {
  return service.post('/api/kuaishou/accountManagementAccounts/updateBatch', data)
}

//  账号添加
export function accountsSave(data) {
  return service.post('/api/kuaishou/accountManagementAccounts/save', data)
}

//  同步投放账号【账号列表】 ID
export function getAdvertiserNew(params) {
  return service.get(`/api/kuaishou/accountManagementAccounts/getShowAccount`, { params })
}

// 账户批量保存【项目 产品 人员】 I
export function Sync(data) {
  return service.post(`/api/kuaishou/accountManagementAccounts/saveBatch`, data)
}

// 付费激活
export function pActivation(planId) {
  return service.get(`api/kuaishou/accountManagementAccounts/pActivation/${planId}`)
}

// 付费激活2.2
export function pActivation2(data) {
  return service.post(`api/kuaishou/accountManagementAccounts/pActivation2`, data)
}
