import service from '@/network/service/service'

/**
 * 获取行业列表
 */
export const getIndustryList = (level) => service.get(`/api/system/advManagementHugeBase/industry?level=${level}`)

/**
 * 获取标签列表
 */
export const getTagList = (params) => service.post('/api/system/advManagementHugeBase/tags', params)

/**
 * 获取投放账户列表
 */
export const getPutAccountList = (projectId) => service.get(`/api/system/accountHugeHousekeeper/byProjectIdOrProductId/${projectId}`)

/**
 * 获取行动号召列表
 */
export const getActionTextList = (advertiserId) => service.get(`/api/system/advManagementHugeBase/action_text?advertiserId=${advertiserId}`)

/**
 * 选择图片素材上传oss
 */
export const selectUploadOssImage = (params) => service.post('/api/system/advManagementHugeBase/selectImage', params)

/**
 * 选择视频素材上传oss
 */
export const selectUploadOssVideo = (params) => service.post('/api/system/advManagementHugeBase/selectVideo', params)

/**
 * 获取第三方落地页
 */
export const getLandingPageList = (params) => service.post('/api/system/cwOceanenginePaging/page', params)

/**
 * 获取转化追踪列表
 */
export const getConvertList = (params) => service.post('/api/system/assetManagementHugeConvert/page', params)

/**
 * 获取转创意组件
 */
export const getCreativeComponentList = (params) => service.post('/api/system/assetManagementHugeCreativeComponent/page', params)

/**
 * 获取资产列表
 */
export const getAccountList = (params) => service.post('/api/system/assetManagementHugeAsset/page', params)

/**
 * 预览广告计划
 */
export const previewAdPlan = (params) => service.post('/api/system/advManagementHugeBase/createPlans', params)

/**
 * 提交审核
 */
export const submitAdPlan = (params) => service.post('/api/system/advManagementHugeBase/submit', params)

/**
 * 编辑关键词列表
 */
export const editKeywords = (params) => service.post('/api/system/advManagementHugeBase/updateKeyword', params)

/**
 * 获取列表
 */
export const Page = (params) => service.post('/api/system/advManagementHugeBase/page', params)

/**
 * 选择视频素材保存
 */
export const saveConfigVideo = (params) => service.post('/api/system/advManagementHugeBase/newSelectVideo', params)

/**
 * 选择图片素材保存
 */
export const saveConfigImage = (params) => service.post('/api/system/advManagementHugeBase/newSelectImage', params)

// 巨量版本v1
const JULIANG_VERSION = 'v1'
/**
 * 获取关键词列表
 */
export const uploadKeywords = (params) => service.post(`/api/system/advManagementHuge/${JULIANG_VERSION}/importKeywords`, params)

/**
 * 任务列表计划详情
 */
export const getListDetail = params => service.post(`/api/system/advManagementHuge/${JULIANG_VERSION}/listPlans`, params)

/**
 * 任务列表计划详情重新提交
 */
export const rePublishTask = params => service.get(`/api/system/advManagementHuge/${JULIANG_VERSION}/rePublishTask`, { params })

/**
 * 保存创建巨量批量任务基础配置
 */
export const saveConfigTask = params => service.post(`/api/system/advManagementHuge/${JULIANG_VERSION}/createTask`, params)

/**
 * 获取基本配置信息草稿
 */
export const getDraft = () => service.get(`/api/system/advManagementHuge/${JULIANG_VERSION}/getDraft`)

/**
 * 获取预存计划基本信息
 */
export const getPlanDraft = () => service.get(`/api/system/advManagementHuge/${JULIANG_VERSION}/getPresetConfigPlan`)

/**
 * 获取预存创意基本信息
 */
export const getCreativeDraft = () => service.get(`/api/system/advManagementHuge/${JULIANG_VERSION}/getPresetConfigCreative`)

/**
 * 获取监测链接历史信息
 */
export const getTraceUrlHistory = () => service.get(`/api/system/advManagementHuge/${JULIANG_VERSION}/getConfigTraceUrlsHistory`)

/**
 * 获取列表
 */
export const getById = (params) => service.get(`/api/system/advManagementHuge/${JULIANG_VERSION}/getById`, { params })

/**
 * 清空配置信息
 */
export const cleanConfigBase = params => service.get(`/api/system/advManagementHuge/${JULIANG_VERSION}/cleanConfig`, { params })

/**
 * 保存任务基础信息信息
 */
export const saveConfigBase = params => service.post(`/api/system/advManagementHuge/${JULIANG_VERSION}/saveOrUpdateConfigBase`, params)

/**
 * 保存广告组信息
 */
export const saveConfigGroup = params => service.post(`/api/system/advManagementHuge/${JULIANG_VERSION}/saveOrUpdateConfigGroup`, params)

/**
 * 保存广告计划基本信息
 */
export const saveConfigPlan = params => service.post(`/api/system/advManagementHuge/${JULIANG_VERSION}/saveOrUpdateConfigPlan`, params)

/**
 * 保存定向包
 */
export const saveConfigAudience = params => service.post(`/api/system/advManagementHuge/${JULIANG_VERSION}/saveOrUpdateConfigAudiencePackage`, params)

/**
 * 删除定向包
 */
export const deleteAudiencePackage = params => service.get(`/api/system/advManagementHuge/${JULIANG_VERSION}/deleteConfigAudiencePackage`, { params })

/**
 * 清空定向包
 */
export const cleanAudiencePackage = params => service.get(`/api/system/advManagementHuge/${JULIANG_VERSION}/cleanConfigAudiencePackage`, { params })

/**
 * 清空创意素材
 */
export const cleanCreativeMaterial = params => service.get(`/api/system/advManagementHuge/${JULIANG_VERSION}/cleanConfigMedias`, { params })

/**
 * 保存标题包
 */
export const saveConfigTitle = params => service.post(`/api/system/advManagementHuge/${JULIANG_VERSION}/saveOrUpdateConfigTitlePackages`, params)

/**
 * 删除标题包
 */
export const deleteTitlePackage = params => service.get(`/api/system/advManagementHuge/${JULIANG_VERSION}/deleteConfigTitlePackages`, { params })

/**
 * 清空标题包
 */
export const cleanTitlePackage = params => service.get(`/api/system/advManagementHuge/${JULIANG_VERSION}/cleanConfigTitlePackages`, { params })

/**
 * 删除落地页
 */
export const deletePages = params => service.get(`/api/system/advManagementHuge/${JULIANG_VERSION}/deleteConfigPages`, { params })

/**
 * 清空落地页
 */
export const cleanPages = params => service.get(`/api/system/advManagementHuge/${JULIANG_VERSION}/cleanConfigPages`, { params })

/**
 * 保存创意基本信息
 */
export const saveConfigCreative = params => service.post(`/api/system/advManagementHuge/${JULIANG_VERSION}/saveOrUpdateConfigCreative`, params)

/**
 * 保存创意素材组
 */
export const saveCreativeMaterial = params => service.post(`/api/system/advManagementHuge/${JULIANG_VERSION}/saveOrUpdateConfigMedia`, params)

/**
 * 保存落地页
 */
export const saveLandingPages = params => service.post(`/api/system/advManagementHuge/${JULIANG_VERSION}/saveOrUpdateConfigPages`, params)

/**
 * 保存转化追踪
 */
export const saveConfigConvert = params => service.post(`/api/system/advManagementHuge/${JULIANG_VERSION}/saveOrUpdateConfigConverts`, params)

/**
 * 保存资产选择
 */
export const saveConfigAssets = params => service.post(`/api/system/advManagementHuge/${JULIANG_VERSION}/saveOrUpdateConfigAsset`, params)

/**
 * 保存监测链接
 */
export const saveConfigTraceUrl = params => service.post(`/api/system/advManagementHuge/${JULIANG_VERSION}/saveOrUpdateConfigTraceUrls`, params)

/**
 * 保存抖音号
 */
export const saveConfigDouyin = params => service.post(`/api/system/advManagementHuge/${JULIANG_VERSION}/saveOrUpdateConfigDouyins`, params)

/**
 * 预览计划
 */
export const previewConfigPlans = params => service.post(`/api/system/advManagementHuge/${JULIANG_VERSION}/createPlans`, params)

/**
 * 暂存程序化任务
 */
export const savePlans = params => service.post(`/api/system/advManagementHuge/${JULIANG_VERSION}/savePlans`, params)

/**
 * 提交程序化任务
 */
export const submitPlans = params => service.post(`/api/system/advManagementHuge/${JULIANG_VERSION}/submit`, params)

/**
 * 删除程序化任务
 */
export const deletePlans = params => service.get(`/api/system/advManagementHuge/${JULIANG_VERSION}/removeTask`, { params })

/**
 * 查询抖音列表
 */
export const getDouyinAccount = (houseKeeperId, advId) => service.get(`/api/system/managementHugeIesAccount/search/${houseKeeperId}/${advId}`)

/**
 * 查询管家列表
 */
export const getHouseKeeper = params => service.get(`/api/system/accountHugeHousekeeper/query`, { params })

/**
 * 查询管家帐号下的投放账户列表
 */
export const getAdvertisers = params => service.get(`/api/system/accountHugeAdv/listAdv`, { params })

/**
 * 查询管家帐号下的投放账户列表V2
 */
export const getAdvertisersV2 = params => service.get(`/api/system/accountHugeAdv/listAdvAdvNew`, { params })

/**
 * 落地页列表
 */
export const getLandingList = (params, field) => service.post(`/api/system/advManagementHuge/${JULIANG_VERSION}/${field}`, params)

/**
 * 同步资产
 */
export const syncAssetsList = (accountId, advAccountId) => service.get(`/api/system/assetManagementHugeAsset/synchronization/${accountId}/${advAccountId}`)

/**
 * 同步转化追踪
 */
export const syncConvertList = (accountId, advAccountId) => service.get(`/api/system/assetManagementHugeConvert/synchronization/${accountId}/${advAccountId}`)

/**
 * 同步抖音号
 */
export const syncDouyinList = (accountId, advAccountId) => service.get(`/api/system/managementHugeIesAccount/search/${accountId}/${advAccountId}`)

/**
 * 同步落地页-橙子建站
 */
export const syncOrangePageList = (accountId, advAccountId) => service.get(`/api/system/assetManagementHugeCreateOrangeWebsite/syncOrangeWebSite/${accountId}/${advAccountId}`)

/**
 * 同步落地页-第三方落地页
 */
export const syncThirdPageList = (params) => service.get(`/api/system/assetManagementHugeThirdLandingPage/byAccountGetLists`, { params })

/**
 * 同步创意组件
 */
export const syncCreativeCompList = (accountId, advAccountId) => service.get(`/api/system/assetManagementHugeCreativeComponent/synchronization/${accountId}/${advAccountId}`)
