<template>
  <!--投放配置筛选弹框-->
  <el-dialog
    class="launch-configuration-dialog"
    title="投放配置"
    :visible.sync="show"
    :append-to-body="true"
    width="730px"
    top="20vh"
    @close="onCloseDialog"
  >
    <div class="page-container">
      <!-- 表格 -->
      <el-table
        v-loading="loading"
        :data="tableData"
        height="300px"
        stripe
        :border="true"
      >
        <el-table-column
          label="渠道名"
          align="center"
          prop="channelName"
        >
        </el-table-column>

        <el-table-column
          label="投手"
          align="center"
          prop="userName"
        >
        </el-table-column>

        <el-table-column
          label="广告平台"
          align="center"
          prop="toolProductPlatform"
        >
        </el-table-column>

        <el-table-column
          label="广告位"
          align="center"
          prop="toolProductAdSpace"
        >
        </el-table-column>

        <el-table-column
          label="开测时间"
          align="center"
          width="200px"
          prop="startTestTime"
        >
        </el-table-column>

        <el-table-column
          label="操作"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-button
              v-if="row.isDelete"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(row.toolProductLaunchSettingId)"
            ></el-button>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-container">
        <c-pagination
          :page-size.sync="pageSize"
          :page-no.sync="pageNum"
          :pager-count="5"
          :total="total"
          tools="total, prev, pager, next"
          :get-data-fun="getTableData"
        />
      </div>

      <!-- 表单 -->
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        :inline="true"
        label-width="80px"
        style="margin-top: 20px;"
      >
        <el-form-item
          label="宙斯配置"
          prop="toolProductDramaAccountId"
        >
          <el-select
            v-model="form.toolProductDramaAccountId"
            filterable
            remote
            reserve-keyword
            style="width: 206px;"
            placeholder="请输入关键词"
            :remote-method="_getZeusPulldown"
            :loading="searchLoading"
            @change="onClickChooseZs"
          >
            <el-option
              v-for="item in zeusList"
              :key="item.toolProductDramaAccountId"
              :label="item.channelName"
              :value="item.toolProductDramaAccountId"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="广告平台"
          prop="toolProductPlatform"
        >
          <el-input
            v-model="form.toolProductPlatform"
            style="width: 206px;"
            placeholder="请输入广告平台"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="广告位"
          prop="toolProductAdSpace"
        >
          <el-input
            v-model="form.toolProductAdSpace"
            style="width: 206px;"
            placeholder="请输入广告位"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="开测时间"
          prop="startTestTime"
        >
          <el-date-picker
            v-model="form.startTestTime"
            type="date"
            placeholder="选择日期时间"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            style="width: 206px;"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>
    <!--底部按钮-->
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        class="btn"
        @click="closeModal"
      >取 消</el-button>
      <el-button
        class="btn"
        type="primary"
        :disabled="saveLoading"
        @click="handleConfirm"
      >添加</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { PageCommonComponent } from '@/common/mixin'
import { launchConfigurationList, saveLaunchConfiguration, zeusPulldown, deleteLaunchConfiguration } from '@/network/api/toolManagement/api-product-test'

export default {
  name: 'LaunchConfigurationDialog',
  mixins: [PageCommonComponent],
  data() {
    return {
      show: false,
      loading: false,
      searchLoading: false,
      saveLoading: false,

      form: {
        channelName: '',
        toolProductDramaAccountId: '',
        toolProductPlatform: '',
        toolProductAdSpace: '',
        startTestTime: '',
        toolProductFilterItemId: '',
        toolProductId: ''
      },

      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 5,

      zeusList: [],

      rules: {
        toolProductDramaAccountId: [{ required: true, message: '请选择宙斯配置', trigger: 'change' }],
        toolProductPlatform: [{ required: true, message: '请输入广告平台', trigger: 'blur' }],
        toolProductAdSpace: [{ required: true, message: '请输入广告位', trigger: 'blur' }],
        startTestTime: [{ required: true, message: '请选择开测时间', trigger: 'change' }]

      }
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  methods: {
    /* --------------------------- Private --------------------------- */
    // 获取宙斯下拉
    _getZeusPulldown(query = '') {
      this.searchLoading = true
      let parmas = {
        channelName: query
      }
      zeusPulldown(parmas).then(res => {
        this.searchLoading = false
        this.zeusList = res.data.filter(item => {
          return item.channelName.toLowerCase()
            .indexOf(query.toLowerCase()) > -1
        })
      })
    },

    // 获取列表数据
    getTableData() {
      this.loading = true
      launchConfigurationList(this.form.toolProductId, this.pageNum, this.pageSize)
        .then(({ code, data }) => {
          if (code === 200) {
            this.tableData = data.records || []
            if (this.tableData.length) {
              this.tableData = this.tableData.map(item => {
                return {
                  toolProductLaunchSettingId: item.toolProductLaunchSetting.toolProductLaunchSettingId,
                  userName: item.toolProductLaunchPerson.userName,
                  channelName: item.toolProductLaunchSetting.channelName,
                  toolProductPlatform: item.toolProductLaunchSetting.toolProductPlatform,
                  toolProductAdSpace: item.toolProductLaunchSetting.toolProductAdSpace,
                  startTestTime: item.toolProductLaunchSetting.startTestTime,
                  isDelete: item.toolProductLaunchPerson.userId === this.userInfo.userid
                }
              })
            }
            this.total = data.total
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    /* --------------------------- Actions --------------------------- */
    showModal(id, toolProductFilterItemId) {
      this.show = true
      this.form.toolProductId = id
      this.form.toolProductFilterItemId = toolProductFilterItemId
      this._getZeusPulldown()
      this.getTableData()

      console.log(1111)
    },

    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      this.form = {
        channelName: '',
        toolProductDramaAccountId: '',
        toolProductPlatform: '',
        toolProductAdSpace: '',
        startTestTime: '',
        toolProductFilterItemId: '',
        toolProductId: ''
      }
      this.$refs['form'].clearValidate()
    },

    // 选择宙斯
    onClickChooseZs (e) {
      if (e) {
        let item = this.zeusList.find(item => item.toolProductDramaAccountId === this.form.toolProductDramaAccountId)
        this.form.toolProductPlatform = item.platformName
        this.form.toolProductAdSpace = item.adsPosiName
        this.form.startTestTime = item.picTime
        this.form.channelName = item.channelName
      }
    },

    // 关闭弹框
    closeModal() {
      this.show = false
    },

    // 删除配置
    handleDelete (id) {
      deleteLaunchConfiguration(id).then(({ code }) => {
        if (code === 200) {
          this.$message.success('删除成功')
          this.getTableData()
          this.$emit('confirm')
        }
      })
    },

    // 保存投放配置
    handleConfirm() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }
        let params = Object.assign({}, this.form)
        this.saveLoading = true
        saveLaunchConfiguration(params)
          .then(({ code }) => {
            if (code === 200) {
              this.$message.success('添加投放配置成功')
              this.getTableData()
              this.$emit('confirm')
            }
          })
          .finally(() => {
            this.saveLoading = false
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
