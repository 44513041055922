import service from '@/network/service/service'

export function Page(data) {
  return service.post('/api/kuaishou/kuaishouSubject/page', data)
}
// 修改
export function update(data) {
  return service.post('/api/kuaishou/kuaishouSubject/update', data)
}//  账号添加
export function save(data) {
  return service.post('/api/kuaishou/kuaishouSubject/save', data)
}

