/*
 * @Description: Do not edit
 * @Date: 2021-11-10 19:20:55
 * @LastEditTime: 2021-11-22 16:47:05
 */
import service from '@/network/service/service'

export function Page(data) {
  return service.post('/api/system/advManagementBaiduUnit/page', data)
}

export function Add(data) {
  return service.post('/api/system/advManagementBaiduUnit/save', data)
}

export function Update(data) {
  return service.post('/api/system/advManagementBaiduUnit/update', data)
}

export function Delete(id) {
  return service.delete('/api/system/advManagementBaiduUnit/' + id)
}
export function copyPlan(id) {
  return service.get('/api/system/advManagementBaiduUnit/copyPlan?' + id)
}
export function getBizArea(id) {
  return service.get('/api/system/advManagementBaiduUnit/getBizArea?id=' + id)
}
export function getCustom(name) {
  return service.get('/api/system/advManagementBaiduUnit/getCustom?name=' + name)
}
export function getTrackList(housekeeperId, baiduId) {
  return service.get('/api/system/advManagementBaiduUnit/getTrackList?housekeeperId=' + housekeeperId + '&baiduId=' + baiduId)
}

/**
 * @description 通过计划Id获取单元模板
 */
export function getUnitBuPlan(id) {
  return service.get('/api/system/advManagementBaiduUnit/GetUnitBuPlan/' + id)
}

/**
 * @description 通过计划subject获取单元模板
 */
export function GetUnitSubjectBuPlan(subject) {
  return service.get('/api/system/advManagementBaiduUnit/byPlanSubjectGetUnit/' + subject)
}

/**
 * @description 通过线上计划Id获取线上单元
 */
export function byApiPlanIdGetApiUnit(data) {
  return service.post('/api/system/advManagementBaiduPublish/byApiPlanIdGetApiUnit', data)
}

/**
 * @description 发布线上百度单元
 *
 */
export function batchUpUnit(data) {
  return service.post(`/api/system/advManagementBaiduPublish/batchUpUnit`, data)
}

/**
 * @description  删除线上百度计划
 *
 */
export function deleteBaiduUnit(housekeeperId, baiduId, adgroupFeedId) {
  return service.get(`/api/system/advManagementBaiduPublish/deleteBaiduApiUnit/${housekeeperId}/${baiduId}/${adgroupFeedId}`)
}

/**
 * @description  删除全部线上百度计划
 *
 */
export function deleteBaiduApiUnit(data) {
  return service.post(`/api/system/advManagementBaiduPublish/deleteBaiduApiUnit`, data)
}
