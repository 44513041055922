<template>
  <div>
    <Search ref="search" />
    <el-table v-loading="loading" stripe class="list-table" :data="data">
      <c-data-empty slot="empty" />
      <el-table-column prop="operator" label="操作人" width="300" align="left">
        <template slot-scope="{ row }">
          {{ `${row.operator}(${row.operatorId})` }}
        </template>
      </el-table-column>
      <el-table-column prop="ip" label="IP" width="300" align="left" />
      <el-table-column prop="uri" label="uri" width="300" align="left" />
      <el-table-column prop="parameter" show-overflow-tooltip label="请求参数" width="300" align="left" />
      <el-table-column prop="payload" show-overflow-tooltip label="请求参数" align="left" />
      <el-table-column prop="createTime" label="操作时间" width="300" align="left" />
      <el-table-column prop="partitionField" label="日期分片字段" width="300" align="left" />
    </el-table>
    <c-pagination :page-size.sync="pageInfo.pageSize" :page-no.sync="pageInfo.pageNum" :total="pageInfo.total" :get-data-fun="loadData" />
    <OptionDialog ref="dialog" />
  </div>
</template>

<script>
import { PageCommonComponent } from '@/common/mixin'
import { Page, Delete } from '@/network/api/adminLog'
import Search from './Search'
import OptionDialog from './OptionDialog'
import { AdminLogEnt } from './object'

export default {
  name: 'AdminLog',
  components: { Search, OptionDialog },
  mixins: [PageCommonComponent],
  provide: function() {
    return {
      loadData: this.loadData,
      showEdit: this.showEdit
    }
  },
  data() {
    return {
      loading: false,
      data: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData(isFirst = false) {
      const searchForm = this.$refs.search.getSearchForm()
      this.doLoadData(isFirst, searchForm)
    },
    doLoadData(firstPage = false, searchForm) {
      firstPage && (this.pageInfo.pageNum = 1)
      const req = { ...searchForm, ...this.pageInfo }
      this.loading = true
      Page(req).then((res) => {
        this.data = res.data.records
        this.pageInfo.total = res.data.total
        this.loading = false
      })
    },
    deleteRow(row) {
      this.$confirm('确定删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          Delete({ id: row.id }).then((res) => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.loadData()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showEdit(row) {
      this.$refs.dialog.show(true, { ...row })
    },
    showAdd() {
      this.$refs.dialog.show(false, new AdminLogEnt())
    }
  }
}
</script>

<style scoped></style>
