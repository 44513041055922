<template>
  <el-dialog
    :title="isEdit ? '编 辑': '新 增'"
    width="45%"
    :visible.sync="visible"
    :before-close="close"
    label-position="right"
    :modal-append-to-body="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <el-form ref="ent" :model="ent" :rules="rules" label-width="110px">
      <el-form-item label="品类名称" prop="typeName">
        <el-input v-model="ent.typeName" :disabled="isEdit" maxlength="30" placeholder="最大输入长度30 超出截断" />
      </el-form-item>
      <el-form-item label="是否启用" prop="isUseing">
        <el-select v-model="ent.isUseing" placeholder="请选择">
          <el-option v-for="item in isUseingList" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="ent.remark" type="textarea" maxlength="100" placeholder="最大输入长度100 超出截断" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button round @click="close">取消</el-button>
      <el-popconfirm class="ml-10" confirm-button-text="提交" cancel-button-text="取消" icon="el-icon-info" icon-color="red" title="确定提交？" @confirm="submit">
        <el-button slot="reference" type="primary" round>确定</el-button>
      </el-popconfirm>
    </span>
  </el-dialog>
</template>
<script>
import {
  Add,
  Update
} from '@/network/api/basicSetting/product/basicSettingProductType'
import { BasicSettingProductTypeEnt, formRules, isUseingList } from './object'

export default {
  inject: ['loadData'],
  data() {
    return {
      ent: new BasicSettingProductTypeEnt(),
      rules: formRules,
      isEdit: false,
      visible: false,
      cityList: [],
      isUseingList: isUseingList
    }
  },
  methods: {
    submit() {
      this.$refs.ent.validate((validate) => {
        if (validate) {
          this.isEdit ? this.updateRow() : this.addRow()
        }
      })
    },
    addRow() {
      const req = { ...this.ent }
      Add(req).then(res => {
        if (res.code === 200) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.loadData()
          this.close()
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    updateRow() {
      const req = { ...this.ent }
      Update(req).then(res => {
        if (res.code === 200) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.loadData()
          this.close()
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    close() {
      this.visible = false
      this.$nextTick(() => {
        this.$refs.ent.resetFields()
      })
    },
    show(isEdit = false, ent) {
      if (!isEdit) {
        ent.isUseing = 'ISUSEING'
      }
      this.ent = ent
      this.isEdit = isEdit
      this.visible = true
      this.$nextTick(() => {
        if (this.isEdit) {
          console.log('不能为空！')
        }
      })
    }
  }
}
</script>
