var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "base-box",
        { attrs: { name: "shelfnew" } },
        [
          _c(
            "template",
            { slot: "body" },
            [
              _c("div", [
                _c("p", { staticClass: "w-100" }, [_vm._v("类型：")]),
                _c(
                  "div",
                  { staticClass: "ml-20", staticStyle: { display: "flex" } },
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "104" },
                        model: {
                          value: _vm.detail.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "type", $$v)
                          },
                          expression: "detail.type",
                        },
                      },
                      [_vm._v("一行一个")]
                    ),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "103", disabled: "" },
                        model: {
                          value: _vm.detail.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "type", $$v)
                          },
                          expression: "detail.type",
                        },
                      },
                      [_vm._v("一行两个")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-divider"),
              _vm.detail.type === "104"
                ? _c("OnePerLine", { attrs: { detail: _vm.detail } })
                : _vm._e(),
              _vm.detail.type === "103"
                ? _c("TwoPerLine", { attrs: { detail: _vm.detail } })
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }