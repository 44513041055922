<template>
  <!--测试详情弹框-->
  <el-dialog
    class="test-details-dialog"
    title="测试详情"
    :visible.sync="show"
    :append-to-body="true"
    width="1200px"
    top="20vh"
    @close="onCloseDialog"
  >
    <div class="page-container">
      <div class="desc-box">
        <div class="box-item">
          <span>产品名称：</span>
          <span style="color: #989898;">{{ toolProductName }}</span>
        </div>
        <div class="box-item">
          <span>平台：</span>
          <span style="color: #989898;">{{ toolProductPlatform }}</span>
        </div>
        <div class="box-item">
          <span>类型：</span>
          <span style="color: #989898;"> {{ type | filterToolType }}</span>
        </div>
        <div class="box-item">
          <span>数量：</span>
          <span style="color: #989898;">{{ num }}</span>
        </div>
      </div>

      <el-table
        v-loading="loading"
        :stripe="true"
        :border="true"
        :data="tableData"
        height="300px"
      >
        <el-table-column
          type="index"
          label="序号"
          width="80"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="userName"
          label="投手"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="channelName"
          label="投放配置"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="toolProductPlatformLaunchSetting"
          label="广告平台"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="toolProductAdSpace"
          label="广告位"
          align="center"
        ></el-table-column>

        <el-table-column
          label="测试时段"
          align="center"
        >
          <template slot-scope="{ row }">
            <span>{{ row.testTimeInterval || '-' }}</span>
          </template>
        </el-table-column>

        <el-table-column
          label="测试状态"
          align="center"
        >
          <template slot-scope="{ row }">
            {{ row.testStatus | filterTestStatus }}
          </template>
        </el-table-column>

        <el-table-column
          label="结果"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-button
              type="text"
              @click="onClickEditResult(row.toolProductFilterItemId)"
            >
              <span v-if="row.toolProductFilterStatus === '-'">编辑</span>
              <span v-else>{{ row.toolProductFilterStatus }}</span>
            </el-button>
          </template>
        </el-table-column>

        <el-table-column
          prop="isInception"
          label="是否起量"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-tag v-if="row.isInception" type="success">已起量</el-tag>
            <el-tag v-else type="danger">未起量</el-tag>
          </template>
        </el-table-column>

        <el-table-column
          prop="inceptionTime"
          label="起量时间"
          align="center"
        >
          <template slot-scope="{ row }">
            {{ row.inceptionTime || '-' }}
          </template>
        </el-table-column>

        <el-table-column
          prop="consume"
          label="消耗"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="payPowderPrice"
          label="支付粉价"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="d1"
          label="D1"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="d2"
          label="D2"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="d3"
          label="D3"
          align="center"
        ></el-table-column>

        <el-table-column
          label="梯形图"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-button
              type="text"
              @click="handleGoDetail(row.toolProductFilterItemId)"
            >
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--底部按钮-->
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        class="btn"
        @click="closeModal"
      >取消</el-button>
    </div>

    <el-dialog
      :visible.sync="dialogFormVisible"
      :append-to-body="true"
      :modal-append-to-body="false"
      width="300px"
    >
      <el-form>
        <el-form-item>
          <el-select
            v-model="relust"
            placeholder="请选择结果"
          >
            <el-option
              label="成功"
              :value="1"
            ></el-option>
            <el-option
              label="失败"
              :value="0"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="onClickConfirm"
        >确 定</el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import { getProjectionData, getProjectionDataJump, testResult } from '@/network/api/toolManagement/api-product-test'

export default {
  name: 'TestDetailsDialog',
  filters: {
    filterToolProductFilterStatus (value) {
      const types = { '1': '全部', '2': '失败', '3': '成功' }
      return types[value] || ''
    },
    filterToolType (value) {
      const types = { '1': '全部', '2': '男频', '3': '女频' }
      return types[value] || ''
    },
    filterTestStatus (value) {
      const types = { '2': '未测试', '3': '待测试', '4': '测试中', '5': '已测试', '6': '自动结束' }
      return types[value] || ''
    }
  },
  data() {
    return {
      show: false,
      loading: false,
      saveLoading: false,

      relust: '',
      toolProductFilterItemId: '',
      dialogFormVisible: false,

      toolProductName: '',
      toolProductPlatform: '',
      type: '',
      num: '',

      toolProductId: '',
      tableData: []
    }
  },
  methods: {
    /* --------------------------- Actions --------------------------- */
    showModal(id, val) {
      this.show = true
      this.toolProductId = id
      this.toolProductName = val.toolProductName
      this.toolProductPlatform = val.toolProductPlatform
      this.type = val.type
      this.num = val.num

      this.getTableData()
    },

    // 监听 Dialog 弹框关闭
    onCloseDialog() {
    },

    // 关闭弹框1
    closeModal() {
      this.show = false
    },

    // 结果弹窗
    onClickEditResult (toolProductFilterItemId) {
      this.dialogFormVisible = true
      this.relust = ''
      this.toolProductFilterItemId = toolProductFilterItemId
    },

    // 确认修改
    onClickConfirm() {
      if (this.relust === '') {
        this.$message.error('请选择结果')
        return
      }
      this.saveLoading = true
      testResult(this.toolProductFilterItemId, this.relust).then(({ code }) => {
        if (code === 200) {
          this.$message.success('编辑成功')
          this.dialogFormVisible = false
          this.toolProductFilterItemId = ''
          this.getTableData()
        }
      }).finally(() => {
        this.saveLoading = false
      })
    },

    getTableData() {
      this.loading = true
      getProjectionData(this.toolProductId, 1, 1000)
        .then(({ code, data }) => {
          if (code === 200) {
            if (data.records.length) {
              this.tableData = data.records.map(item => {
                return {
                  userName: item.toolProductLaunchPerson ? item.toolProductLaunchPerson.userName : '-',

                  toolProductPlatformLaunchSetting: item.toolProductLaunchSetting ? item.toolProductLaunchSetting.toolProductPlatform : '-',
                  toolProductAdSpace: item.toolProductLaunchSetting ? item.toolProductLaunchSetting.toolProductAdSpace : '-',
                  channelName: item.toolProductLaunchSetting ? item.toolProductLaunchSetting.channelName : '',

                  testTimeInterval: item.toolProductFilterItem.testTimeInterval,
                  toolProductFilterStatus: item.toolProductFilterItem.toolProductFilterStatus,
                  testStatus: item.toolProductLibray.toolProductTestStatus,
                  consume: item.toolProductFilterItem.consume,
                  payPowderPrice: item.toolProductFilterItem.payPowderPrice,
                  isInception: item.toolProductFilterItem.isInception,
                  inceptionTime: item.toolProductFilterItem.inceptionTime,

                  d1: item.toolProductFilterItem.d1Show,
                  d2: item.toolProductFilterItem.d2Show,
                  d3: item.toolProductFilterItem.d3Show,
                  toolProductFilterItemId: item.toolProductFilterItem.toolProductFilterItemId
                }
              })
            } else {
              this.tableData = []
            }

            console.log(this.tableData)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    // 点击跳转
    handleGoDetail (toolProductFilterItemId) {
      getProjectionDataJump(toolProductFilterItemId).then(res => {
        if (res.code === 200) {
          let channel = res.data.channel
          let start_date = res.data.start_date
          let end_date = res.data.end_date
          window.open(`https://galaxy.mokamrp.com/#/theater-daily-roi?channel=${channel}&start_date=${start_date}&end_date=${end_date}`)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.desc-box {
  display: flex;
  justify-content: space-between;
  height: 47px;
  border-bottom: 1px dashed #cccc;
  margin-bottom: 10px;

  .box-item {
    span {
      font-size: 16px;
      color: #000;
    }
  }
}
</style>
