<!--
 * @Descripttion: 二次封装支持模糊音搜索el-select--全局组件
 * @Date: 2021-04-21 17:09:08
-->
<template>
  <el-select :value="value" v-bind="$attrs" :filter-method="handleFilter" filterable clearable v-on="$listeners" @focus="clearSelect" @clear="clearSelect">
    <el-option v-for="item in optionsList" :key="item[props.value]" v-bind="$attrs" :label="item[props.label]" :value="item[props.value]"> </el-option>
  </el-select>
</template>

<script>
import PinyinMatch from 'pinyin-match'
export default {
  name: 'SearchSelect',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    // 需要绑定的值 等于 v-model
    value: {
      type: [String, Number],
      default: ''
    },
    // 需要循环的数组 必传
    options: {
      type: Array,
      default() {
        return []
      },
      required: true
    },
    // el-option参数 必传
    props: {
      type: Object,
      default() {
        return {
          value: 'value',
          label: 'label'
        }
      },
      required: true
    }
  },
  data() {
    return {
      optionsList: [],
      copyOptionsList: []
    }
  },
  watch: {
    // 监听赋值并copy一份
    options: {
      handler(val) {
        this.optionsList = val
        this.copyOptionsList = JSON.parse(JSON.stringify(val))
      },
      immediate: true
    }
  },
  methods: {
    /**
     * @Description: 下拉框支持模糊搜索
     * @Author: JayShen
     * @param {*} val
     */
    handleFilter(val) {
      try {
        if (val) {
          this.optionsList = this.copyOptionsList
          this.optionsList = this.optionsList.filter((item) => PinyinMatch.match(item[this.props.label], val))
        } else {
          this.optionsList = this.copyOptionsList
        }
      } catch (error) {
        console.error('模糊音下拉框：', error)
      }
    },
    /**
     * @Description: clear、focus事件还原数组
     * @Author: JayShen
     * @param {*}
     */
    clearSelect() {
      this.optionsList = this.copyOptionsList
    }
  }
}
</script>
