/**
 * 页面公用的组件，定义在这个 mixin 中，组件中导入，减少导入代码的编写
 */

import Pagination from '@/components/Pagination'

export const PageCommonComponent = {
  components: {
    'c-pagination': Pagination
  },
  data() {
    return {
      isSearch: false,
      isLoading: false, // 是否正在加载数据
      totalCount: 0, // 数据总条数
      pageNum: 1, // 当前显示的页码
      pageSize: 10 // 每页显示条数
    }
  },
  methods: {
    // 时间格式化
    parseTime(time, cFormat) {
      if (arguments.length === 0) {
        return null
      }
      const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
      let date
      if (typeof time === 'object') {
        date = time
      } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
          time = parseInt(time)
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
          time = time * 1000
        }
        date = new Date(time)
      }
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
      }
      const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        if (key === 'a') {
          return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
        }
        if (result.length > 0 && value < 10) {
          value = '0' + value
        }
        return value || 0
      })
      return timeStr
    },

    // 搜索
    onClickSearch() {
      if (this.pageNo === 1) {
        this._loadData()
      } else {
        this.pageNo = 1
      }
    },

    // utc时间格式修改
    _getFormatTime(time) {
      var d = new Date(time)
      var times = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' ' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds()
      return times
    }
  }
}
