var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "edit-info-dialog",
      attrs: {
        visible: _vm.show,
        "modal-append-to-body": false,
        width: "650px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.onCloseDialog,
      },
    },
    [
      _c(
        "span",
        {
          staticClass: "dialog-title",
          attrs: { slot: "title" },
          slot: "title",
        },
        [_vm._v(_vm._s(_vm.title))]
      ),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.formData,
            "label-width": "150px",
            rules: _vm.rules,
          },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _vm.type === 1
            ? _c(
                "el-form-item",
                { attrs: { label: "计划名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入计划名称", clearable: "" },
                    model: {
                      value: _vm.formData.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.type === 2
            ? _c(
                "el-form-item",
                {
                  staticClass: "title-label",
                  attrs: { label: "日预算", prop: "dailyBudget" },
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 0 },
                      model: {
                        value: _vm.formData.isDailyBudget,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "isDailyBudget", $$v)
                        },
                        expression: "formData.isDailyBudget",
                      },
                    },
                    [_vm._v("不限")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.formData.isDailyBudget,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "isDailyBudget", $$v)
                        },
                        expression: "formData.isDailyBudget",
                      },
                    },
                    [_vm._v("指定日预算")]
                  ),
                  _vm.formData.isDailyBudget
                    ? _c("el-input", {
                        attrs: { placeholder: "请输入日预算", clearable: "" },
                        model: {
                          value: _vm.formData.dailyBudget,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "dailyBudget", $$v)
                          },
                          expression: "formData.dailyBudget",
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.type === 3
            ? _c(
                "el-form-item",
                {
                  staticClass: "title-label",
                  attrs: { label: "总预算", prop: "generalBudget" },
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 0 },
                      model: {
                        value: _vm.formData.isGeneralBudget,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "isGeneralBudget", $$v)
                        },
                        expression: "formData.isGeneralBudget",
                      },
                    },
                    [_vm._v("不限")]
                  ),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.formData.isGeneralBudget,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "isGeneralBudget", $$v)
                        },
                        expression: "formData.isGeneralBudget",
                      },
                    },
                    [_vm._v("指定总预算")]
                  ),
                  _vm.formData.isGeneralBudget
                    ? _c("el-input", {
                        attrs: { placeholder: "请输入总预算", clearable: "" },
                        model: {
                          value: _vm.formData.generalBudget,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "generalBudget", $$v)
                          },
                          expression: "formData.generalBudget",
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.type === 4
            ? _c(
                "el-form-item",
                {
                  staticClass: "title-label",
                  attrs: { label: "广告名称", prop: "advertisingName" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入广告名称", clearable: "" },
                    model: {
                      value: _vm.formData.advertisingName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "advertisingName", $$v)
                      },
                      expression: "formData.advertisingName",
                    },
                  }),
                  _c("div", { staticClass: "form-tips" }, [
                    _vm._v("为保证唯一，系统会在上述名称后自动拼接随机编码"),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.type === 5
            ? _c(
                "el-form-item",
                {
                  staticClass: "title-label",
                  attrs: { label: "出价", prop: "offer" },
                },
                [
                  _c("el-input-number", {
                    attrs: {
                      placeholder: "请输入出价",
                      min: 0.01,
                      max: 5000,
                      precision: 2,
                      clearable: "",
                    },
                    model: {
                      value: _vm.formData.offer,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "offer", $$v)
                      },
                      expression: "formData.offer",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "btn", on: { click: _vm.closeModal } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { type: "primary" },
              on: { click: _vm.clickConfirm },
            },
            [_vm._v("确 定 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }