var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "material-video-box" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "custom-class": "material-video-box",
            title: "视频选择",
            visible: _vm.visible,
            width: `1400px`,
            top: "100px",
            "before-close": _vm.close,
            "destroy-on-close": true,
            "append-to-body": true,
          },
        },
        [
          _c(
            "el-row",
            { staticClass: "selected-img", attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-col",
                    { staticClass: "tree-box", attrs: { span: 3 } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          placeholder: "输入关键字进行过滤",
                          size: "small",
                        },
                        model: {
                          value: _vm.filterText,
                          callback: function ($$v) {
                            _vm.filterText = $$v
                          },
                          expression: "filterText",
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "tree" },
                        [
                          _c("el-tree", {
                            ref: "tree",
                            attrs: {
                              data: _vm.adAlbum,
                              lazy: "",
                              load: _vm.loadNode,
                              props: _vm.defaultProps,
                              "filter-node-method": _vm.filterNode,
                            },
                            on: { "node-click": _vm.nodeClick },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 20 } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form",
                            {
                              ref: "videoQuery",
                              staticStyle: { marginLeft: "10px" },
                              attrs: { inline: true, model: _vm.videoQuery },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "上传人: " } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入上传人" },
                                    model: {
                                      value: _vm.videoQuery.nickname,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.videoQuery,
                                          "nickname",
                                          $$v
                                        )
                                      },
                                      expression: "videoQuery.nickname",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "宽: " } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入宽" },
                                    model: {
                                      value: _vm.videoQuery.width,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.videoQuery, "width", $$v)
                                      },
                                      expression: "videoQuery.width",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "高: " } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入高" },
                                    model: {
                                      value: _vm.videoQuery.height,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.videoQuery, "height", $$v)
                                      },
                                      expression: "videoQuery.height",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: { click: _vm.onSubmit },
                                    },
                                    [_vm._v("查询")]
                                  ),
                                  _c(
                                    "el-button",
                                    { on: { click: _vm.resetForm } },
                                    [_vm._v("重置")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c("el-col", { attrs: { span: 18 } }, [
                        _vm.videoList.length > 0
                          ? _c(
                              "ul",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.videoLoading,
                                    expression: "videoLoading",
                                  },
                                ],
                                staticClass: "select-video",
                                staticStyle: { margin: "0" },
                                attrs: { "element-loading-text": "视频获取中" },
                              },
                              [
                                _c(
                                  "el-row",
                                  _vm._l(_vm.videoList, function (item) {
                                    return _c(
                                      "el-col",
                                      { key: item.videoId, attrs: { span: 6 } },
                                      [
                                        _c(
                                          "li",
                                          {
                                            class: {
                                              active:
                                                _vm.selectedVideoList.some(
                                                  (s) =>
                                                    s.videoId === item.videoId
                                                ),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectVideo(item)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "video-div" },
                                              [
                                                _c("video", {
                                                  attrs: { src: item.url },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "attachments",
                                                class: {
                                                  "success-color":
                                                    _vm.successColor(item),
                                                },
                                              },
                                              [
                                                _c("p", [
                                                  _vm._v(
                                                    _vm._s(item.materialName)
                                                  ),
                                                ]),
                                                _c("div", [
                                                  _c("p", [
                                                    _vm._v(
                                                      _vm._s(item.width) +
                                                        "px * " +
                                                        _vm._s(item.height) +
                                                        "px"
                                                    ),
                                                  ]),
                                                  _c("p", [
                                                    _vm._v(
                                                      _vm._s(
                                                        (
                                                          item.size /
                                                          1024 /
                                                          1024
                                                        ).toFixed(2)
                                                      ) + "MB"
                                                    ),
                                                  ]),
                                                ]),
                                              ]
                                            ),
                                            _c("i", {
                                              staticClass: "el-icon-success",
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  }),
                                  1
                                ),
                                _c("el-pagination", {
                                  staticStyle: { "text-align": "right" },
                                  attrs: {
                                    background: "",
                                    layout: "prev, pager, next",
                                    total: _vm.videoListTotal,
                                    "current-page": _vm.videoQuery.pageNum,
                                    "page-size": 8,
                                  },
                                  on: {
                                    "update:currentPage": function ($event) {
                                      return _vm.$set(
                                        _vm.videoQuery,
                                        "pageNum",
                                        $event
                                      )
                                    },
                                    "update:current-page": function ($event) {
                                      return _vm.$set(
                                        _vm.videoQuery,
                                        "pageNum",
                                        $event
                                      )
                                    },
                                    "current-change": _vm.currentChange,
                                    "prev-click": _vm.currentChange,
                                    "next-click": _vm.currentChange,
                                  },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "ul",
                              {
                                staticClass: "select-img",
                                staticStyle: { margin: "0" },
                              },
                              [
                                _c("el-empty", {
                                  attrs: { description: "暂无数据" },
                                }),
                              ],
                              1
                            ),
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-row",
                            { staticClass: "selected-video" },
                            [
                              _vm.isBatch
                                ? _vm._l(
                                    new Array(_vm.videoCount),
                                    function (item, index) {
                                      return _c(
                                        "el-col",
                                        { key: index, attrs: { span: 12 } },
                                        [
                                          _c(
                                            "section",
                                            {
                                              staticClass:
                                                "selected-video-item",
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  [
                                                    !(
                                                      _vm.selectedVideoList[
                                                        index
                                                      ] &&
                                                      _vm.selectedVideoList[
                                                        index
                                                      ].videoUrl
                                                    )
                                                      ? _c("el-avatar", {
                                                          staticClass: "avatar",
                                                          attrs: {
                                                            shape: "square",
                                                            size: 100,
                                                            icon: "el-icon-video-camera-solid",
                                                          },
                                                        })
                                                      : _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "avatar-video",
                                                          },
                                                          [
                                                            _c("video", {
                                                              staticStyle: {
                                                                width: "100%",
                                                                height: "100%",
                                                              },
                                                              attrs: {
                                                                src:
                                                                  _vm
                                                                    .selectedVideoList[
                                                                    index
                                                                  ] &&
                                                                  _vm
                                                                    .selectedVideoList[
                                                                    index
                                                                  ].videoUrl,
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                  ],
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "avatar-mask",
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleImg(
                                                                index,
                                                                "updata"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("替换")]
                                                      ),
                                                      _c(
                                                        "p",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleImg(
                                                                index,
                                                                "delete"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("删除")]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                2
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  )
                                : _vm._l(
                                    _vm.selectedVideoList,
                                    function (item, index) {
                                      return _c(
                                        "el-col",
                                        { key: index, attrs: { span: 12 } },
                                        [
                                          _c(
                                            "section",
                                            {
                                              staticClass:
                                                "selected-video-item",
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "avatar-video",
                                                      },
                                                      [
                                                        _c("video", {
                                                          staticStyle: {
                                                            width: "100%",
                                                            height: "100%",
                                                          },
                                                          attrs: {
                                                            src:
                                                              item &&
                                                              item.videoUrl,
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "avatar-mask",
                                                    },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleImg(
                                                                index,
                                                                "updata"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("替换")]
                                                      ),
                                                      _c(
                                                        "p",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleImg(
                                                                index,
                                                                "delete"
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("删除")]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                2
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.select } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }