<!--
 * @Description: 推广创意
 * @Date: 2021-12-24 14:49:52
 * @LastEditTime: 2022-02-21 09:43:08
-->
<template>
  <el-form ref="searchForm" inline class="search-form search-form-4" :model="searchForm">
    <el-row :gutter="20">
      <el-col :span="6">
        <el-form-item prop="creativeFeedName" label="创意名称">
          <el-input v-model="searchForm.creativeFeedName" placeholder="请输入创意名称" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="createBy" label="创建者">
          <el-input v-model="searchForm.createBy" placeholder="请输入创建者" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="createTime" label="创建时间">
          <el-date-picker v-model="searchForm.selectDateValue" class="w-350" value-format="yyyy-MM-dd" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-button class="btn" type="primary" @click="search">
          查询
        </el-button>
        <el-button class="btn" @click="resetSearch">
          重置
        </el-button>
      </el-col>
    </el-row>
    <el-row> </el-row>
  </el-form>
</template>
<script>
import { searchForm } from './object'
export default {
  inject: ['loadData'],
  data() {
    return {
      searchForm: { ...searchForm }
    }
  },
  methods: {
    search() {
      this.searchForm.selectDate = this.searchForm.selectDateValue + ''

      this.loadData(true)
    },
    // 折中方案
    getSearchForm() {
      return { ...this.searchForm }
    },
    resetSearch() {
      this.$refs.searchForm.resetFields()
      this.searchForm.selectDateValue = null
      this.searchForm.selectDate = null
      this.loadData(true)
    }
  }
}
</script>
