var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "table-template",
      attrs: {
        title: "添加标题",
        visible: _vm.show,
        "append-to-body": true,
        width: "1200px",
        top: "20vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.onCloseDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "copy-type" },
        _vm._l(_vm.copyType, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              class: [
                "copy-type-item",
                item.id === _vm.copyIndex ? "isSelect" : "",
              ],
              on: {
                click: function ($event) {
                  return _vm.handleSelectType(item.id)
                },
              },
            },
            [_vm._v(_vm._s(item.value))]
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "search-form-container title-package-search" },
        [
          _c(
            "el-form",
            { staticClass: "search-form" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 15 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "项目" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: true },
                            model: {
                              value: _vm.searchForm.projectName,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "projectName", $$v)
                              },
                              expression: "searchForm.projectName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: true },
                            model: {
                              value: _vm.searchForm.productName,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "productName", $$v)
                              },
                              expression: "searchForm.productName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.copyIndex === 2
                    ? _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "创建人" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入创建人" },
                                model: {
                                  value: _vm.searchForm.createBy,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "createBy", $$v)
                                  },
                                  expression: "searchForm.createBy",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.copyIndex === 1
                    ? _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "关键字" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入关键字" },
                                model: {
                                  value: _vm.searchForm.text,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "text", $$v)
                                  },
                                  expression: "searchForm.text",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "70px" },
                          attrs: { type: "primary" },
                          on: { click: _vm.onClickSearch },
                        },
                        [_vm._v("搜索")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "70px" },
                          attrs: { type: "plain" },
                          on: { click: _vm.onClickReset },
                        },
                        [_vm._v("清空")]
                      ),
                      _vm.copyIndex === 2
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.onClickAddTxtGroup(0)
                                },
                              },
                            },
                            [_vm._v("新增标题包")]
                          )
                        : _vm._e(),
                      _vm.copyIndex === 1
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.onClickAddTxt(0)
                                },
                              },
                            },
                            [_vm._v("新增标题")]
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", icon: "el-icon-refresh" },
                          on: { click: _vm.handleRefresh },
                        },
                        [_vm._v("刷新")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.copyIndex === 2,
                  expression: "copyIndex === 2",
                },
              ],
              ref: "multipleTable",
              attrs: {
                data: _vm.txtGroupList,
                "v-loading": _vm.loading,
                "row-key": (row) => {
                  return row.textGroupId
                },
              },
              on: { "selection-change": _vm.onTableTextGroupSelectionChange },
            },
            [
              _c("c-data-empty", { attrs: { slot: "empty" }, slot: "empty" }),
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  selectable: _vm.checkSelectable,
                  "reserve-selection": true,
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "文案组名称",
                  "show-overflow-tooltip": "",
                  "min-width": "150px",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return _c("span", {}, [_vm._v(_vm._s(row.textGroupName))])
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "关联项目",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return _c("span", {}, [_vm._v(_vm._s(row.projectName))])
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "关联产品",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return _c("span", {}, [_vm._v(_vm._s(row.productName))])
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "关联计划数",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return _c("span", {}, [_vm._v(_vm._s(row.planNum || 0))])
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "标题数",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return _c("span", {}, [_vm._v(_vm._s(row.textNum))])
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "创建人",
                  "show-overflow-tooltip": "",
                  "min-width": "150px",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return _c("span", {}, [_vm._v(_vm._s(row.nickname))])
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "创建时间",
                  "show-overflow-tooltip": "",
                  "min-width": "180px",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return _c("span", {}, [_vm._v(_vm._s(row.createdAt))])
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.copyIndex === 1,
                  expression: "copyIndex === 1",
                },
              ],
              ref: "textTable",
              attrs: {
                data: _vm.txtList,
                "v-loading": _vm.loading,
                "row-key": (row) => {
                  return row.textId
                },
              },
              on: { "selection-change": _vm.onTableTextSelectionChange },
            },
            [
              _c("c-data-empty", { attrs: { slot: "empty" }, slot: "empty" }),
              _c("el-table-column", {
                attrs: { type: "selection", "reserve-selection": true },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "文案",
                  "show-overflow-tooltip": "",
                  "min-width": "150px",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return _c("span", {}, [_vm._v(_vm._s(row.text))])
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "关联项目",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return _c("span", {}, [_vm._v(_vm._s(row.projectName))])
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "关联产品",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return _c("span", {}, [_vm._v(_vm._s(row.productName))])
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "关联计划数",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return _c("span", {}, [_vm._v(_vm._s(row.planNum || 0))])
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "创建人",
                  "show-overflow-tooltip": "",
                  "min-width": "150px",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return _c("span", {}, [_vm._v(_vm._s(row.nickname))])
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "创建时间",
                  "show-overflow-tooltip": "",
                  "min-width": "250px",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return _c("span", {}, [_vm._v(_vm._s(row.createdAt))])
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination-container" },
            [
              _c("c-pagination", {
                attrs: {
                  "page-size": _vm.pageSize,
                  "page-no": _vm.pageNum,
                  total: _vm.total,
                  "get-data-fun": _vm.getPageData,
                },
                on: {
                  "update:pageSize": function ($event) {
                    _vm.pageSize = $event
                  },
                  "update:page-size": function ($event) {
                    _vm.pageSize = $event
                  },
                  "update:pageNo": function ($event) {
                    _vm.pageNum = $event
                  },
                  "update:page-no": function ($event) {
                    _vm.pageNum = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "btn", on: { click: _vm.closeModal } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: {
                type: "primary",
                loading: _vm.isLoading,
                disabled:
                  _vm.selectedData.length === 0 &&
                  _vm.selectedGroupData.length === 0,
              },
              on: {
                click: function ($event) {
                  return _vm.clickConfirm("form")
                },
              },
            },
            [_vm._v("确 定 ")]
          ),
        ],
        1
      ),
      _c("edit-copy-dialog", {
        ref: "editCopyDialog",
        on: { edit: _vm._getTxtList },
      }),
      _c("add-copy-group-dialog", {
        ref: "addCopyGroupDialog",
        on: { edit: _vm._getTxtGrouplList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }