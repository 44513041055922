/*
 * @Author: Zeng
 * @Date: 2024-03-29 08:59:35
 * @LastEditTime: 2024-03-29 09:04:32
 */
export default {
  namespaced: true,
  state: {
    marginTop: 100
  },
  mutations: {
    SET_MARGIN_TOP: (state, value) => {
      state.marginTop = value || 0
    }
  },

  actions: {}
}

