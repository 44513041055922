<!--
 * @Description: 关联账号
 * @Date: 2021-10-22 14:45:19
 * @LastEditTime: 2022-03-09 15:07:52
-->
<template>
  <div class="justify-center-column">
    <el-input v-model="query" placeholder="请输入公众号名称" style="width: 1000px" @input="remoteMethod" />
    <div class="transfer">
      <el-form-item label-width="0px">
        <el-transfer v-model="selectList" v-loading="publicListLoading" :right-default-checked="rightDefaultListKey" :data="publicList" :titles="['可选账号', '已关联账号']" @left-check-change="leftChange($event)" @right-check-change="rightChange($event)" @change="tChange"></el-transfer>
      </el-form-item>
    </div>
  </div>
</template>

<script>
import { getWechatAccountLike } from '@/network/api/assetManagement/assetManagementHugeLandingPageTemp'
import { debounce } from '@/utils/ruoyi'
import _ from 'lodash'
export default {
  props: {
    accounts: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      query: '',
      publicListLoading: false,
      publicList: [],
      selectList: [],
      rightDefaultListKey: [],
      selectListNew: [] // 暂存
    }
  },

  mounted() {
    this.setSelectList()
  },
  methods: {
    setSelectList() {
      let publicList = []
      let selectList = []
      this.accounts.map((item) => {
        selectList.push(`${item.id}+${item.name}`)
        publicList.push({
          key: `${item.id}+${item.name}`,
          label: item.name
        })
      })
      console.log(selectList)
      console.log(publicList)
      this.selectList = selectList
      this.publicList = publicList
    },

    remoteMethod: debounce(function(e) {
      this.getWechatAccountFun()
    }),

    getSelectList() {
      return this.selectList
    },

    getWechatAccountFun() {
      if (!this.query) return
      this.publicListLoading = true
      getWechatAccountLike(this.query).then(({ code, data }) => {
        if (code === 200) {
          this.publicListLoading = false
          data.forEach((item) => {
            item.key = `${item.ghid}+${item.name}`
            item.label = item.name
          })
          this.publicList = _.uniqBy([...data], 'key')
        }
      })
    },

    // 左侧选中/取消方法
    leftChange(e) {
      // console.log('lllllll', e)
      let _list = this.publicList
      if (e.length === 1) {
        _list.forEach(item => {
          if (item.key === e[0]) {
            item.disabled = false
          } else {
            item.disabled = true
          }
        })
      } else {
        _list.forEach(item => {
          item.disabled = false
        })
      }
    },

    // 右侧选中/取消方法
    rightChange(e) {
      const _list = this.publicList
      if (e.length === 1) {
        _list.forEach(item => {
          if (item.key === e[0]) {
            item.disabled = false
          } else {
            item.disabled = true
          }
        })
      } else {
        _list.forEach(item => {
          item.disabled = false
        })
      }
      // console.log('rrrrrr', e, this.selectList)
    },

    tChange(e, move) {
      const _data = this.publicList
      if (move === 'left') {
        _data.forEach(item => (item.disabled = false))
      } else {
        _data.forEach(item => {
          if (item.key === e[0]) {
            item.disabled = false
          } else {
            item.disabled = true
          }
        })
      }
      // console.log('ccccc', e, move)
    }
  }
}
</script>
<style lang="scss">
.transfer {
  .el-transfer-panel {
    width: 400px;
    height: 600px;
    .el-transfer-panel__body {
      height: 100%;
      .el-transfer-panel__list {
        height: 100%;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.transfer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
}
</style>
