<!--
 * @Description: 工作区 -> 轮播图组件
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2022-03-02 16:51:07
-->

<template>
  <section class="slider">
    <div v-if="detail.type === '101'" style="width: 100%">
      <div v-if="sliderList.length" style="width: 100%">
        <el-carousel :interval="2000">
          <el-carousel-item v-for="item in sliderList" :key="item.id" class="carousel-item">
            <img :src="item.componentItem.pureImageUrl || defaultImg" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div v-else style="text-align: center">
        <i v-for="(item, index) in detail.componentGroupList.componentGroup.length ? detail.componentGroupList.componentGroup : 3" :key="index" class="el-icon-picture-outline"></i>
        <p>轮播图组件</p>
      </div>
    </div>
    <div v-if="detail.type === '102'" style="width: 100%">
      <div v-if="sliderList2.length" style="width: 100%">
        <div :class="{ 'active-carousel': detail.type === '102' }" @click="settype('102')">
          <!-- <el-carousel :interval="2000" arrow="never"> -->
          <!-- <div v-for="(item, index) in detail.componentGroupList.componentItem" :key="item.id"> -->
          <el-carousel :interval="2000">
            <el-carousel-item v-for="item in sliderList2" :key="item.id" class="carousel-item">
              <img :src="item.pureImageUrl || defaultImg" alt="" />
            </el-carousel-item>
          </el-carousel>
          <!-- <el-carousel-item v-if="index === 0">
                <div class="carousel-item">
                  <div class="small" style="width: 15%"></div>
                  <div class="big" style="width: 82%"></div>
                </div>
              </el-carousel-item>
              <el-carousel-item v-if="index === detail.componentGroupList.componentItem.length - 1">
                <div class="carousel-item">
                  <div class="small" style="width: 82%"></div>
                  <div class="big" style="width: 15%"></div>
                </div>
              </el-carousel-item>
              <el-carousel-item v-else>
                <div class="carousel-item">
                  <div class="small" style="width: 12%"></div>
                  <div class="big" style="width: 69%"></div>
                  <div class="small" style="width: 12%"></div>
                </div>
              </el-carousel-item> -->
          <!-- </div> -->
          <!-- </el-carousel> -->
        </div>
      </div>
      <div v-else style="text-align: center">
        <i v-for="(item, index) in detail.length ? sliderList2 : 3" :key="index" class="el-icon-picture-outline"></i>
        <p>轮播图组件</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      sliderList: [],
      sliderList2: []
    }
  },

  watch: {
    detail: {
      handler(value) {
        let newValue = JSON.parse(JSON.stringify(value))
        if (value.type === '101') {
          newValue.componentGroupList.componentGroup.map((item) => {
            return {
              id: item.id,
              ...item.componentItem
            }
          })
          this.sliderList = newValue.componentGroupList.componentGroup
        } else {
          this.sliderList2 = newValue.componentGroupList.componentItem
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.slider {
  width: 100%;
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  i {
    font-size: 40px;
    color: rgb(162, 208, 238);
  }
  p {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 600;
    color: rgb(162, 208, 238);
  }
  .carousel-item {
    text-align: center;
    img {
      height: 100%;
      width: auto;
    }
  }
}
</style>
