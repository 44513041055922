/*
 * @Description: Do not edit
 * @Date: 2021-10-05 13:41:50
 * @LastEditTime: 2022-04-13 11:22:42
 */
import service from '@/network/service/service'

//  账号列表 post
export function ucAccountsPage(data) {
  return service.post('/api/uc/accountManagementAccounts/page', data)
}

//  账号添加
export function ucAccountsSave(data) {
  return service.post('/api/uc/accountManagementAccounts/save', data)
}

// 修改
export function ucAccountsUpdate(data) {
  return service.post('/api/uc/accountManagementAccounts/update', data)
}

//  批量操作
export function ucAccountsBatch(data) {
  return service.post('/api/uc/accountManagementAccounts/updateBatch', data)
}

//  删除
export function ucAccountsDelete(id) {
  return service.delete(`/api/uc/accountManagementAccounts/${id}`)
}
