import service from '@/network/service/service'

export function Page(data) {
  return service.post('/api/system/assetManagementBaiduWoodenFish/page', data)
}

export function Add(data) {
  return service.post('/api/system/assetManagementBaiduWoodenFish/save', data)
}

export function Update(data) {
  return service.post('/api/system/assetManagementBaiduWoodenFish/update', data)
}

export function Delete(id) {
  return service.delete('/api/system/assetManagementBaiduWoodenFish/' + id)
}

export function synchWoodenFish(data) {
  return service.get('/api/system/assetManagementBaiduWoodenFish/synchWoodenFish?' + data)
}

