var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "draggable",
        {
          attrs: { animation: 500 },
          on: { end: _vm.end },
          model: {
            value: _vm.finalWidgets,
            callback: function ($$v) {
              _vm.finalWidgets = $$v
            },
            expression: "finalWidgets",
          },
        },
        [
          _c(
            "transition-group",
            _vm._l(_vm.finalWidgets, function (ele, index) {
              return _c(
                "div",
                {
                  key: ele.widgetTypeV2,
                  class: {
                    active: _vm.seletedWidgetsIndex === index,
                    widget: true,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.setSelectedIndex(index)
                    },
                  },
                },
                [
                  _c(
                    "el-tooltip",
                    { attrs: { effect: "light", placement: "right-start" } },
                    [
                      _c("template", { slot: "content" }, [
                        _c("i", {
                          staticClass: "el-icon-delete-solid",
                          on: {
                            click: function ($event) {
                              return _vm.deleteItem(index)
                            },
                          },
                        }),
                      ]),
                      _c(
                        "div",
                        [
                          _c(ele.component, {
                            tag: "component",
                            attrs: { detail: ele },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }