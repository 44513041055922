
<template>
  <!--显示列弹框-->
  <el-dialog class="table-template" :title="'推荐标签'" :visible.sync="show" :append-to-body="true" width="1200px"
    top="20vh" @close="onCloseDialog">
    <div class="search-form-container">
      <el-form class="search-form">
        <el-row :gutter="15">
          <el-col :span="4">
            <el-input v-model="searchForm.tag" placeholder="请输入标签"></el-input>
          </el-col>
          <!-- 项目 -->
          <el-col :span="4">
            <el-select v-model="searchForm.projectId" filterable clearable placeholder="请选择项目" style="width: 100%;"
              @change="handleSelect">
              <el-option v-for="(item, index) in projectList" :key="index" :label="item.projectName"
                :value="item.bsProjectId"></el-option>
            </el-select>
          </el-col>
          <!-- 产品 -->
          <el-col :span="4">
            <el-select v-model="searchForm.productId" filterable :disabled="!searchForm.projectId" clearable
              placeholder="请选择产品" style="width: 100%;">
              <el-option v-for="(item, index) in productList" :key="index" :label="item.productName"
                :value="item.bsProductId"></el-option>
            </el-select>
          </el-col>
          <!--按钮-->
          <el-col :span="8">
            <!--搜索-->
            <el-button type="primary" style="width: 70px;" @click="onClickSearch">搜索</el-button>
            <!--清空-->
            <el-button type="plain" style="width: 70px;" @click="onClickReset">清空</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="page-container unselected-all-table">
      <!--表格-->
      <el-table ref="multipleTable" :data="tagList" v-loading="loading" :row-key="row => { return row.id }"
        @selection-change="onTableTextSelectionChange">
        <!--空数据提示-->
        <c-data-empty slot="empty"></c-data-empty>

        <el-table-column type="selection" :selectable="checkSelectable" :reserve-selection="true"></el-table-column>
        <el-table-column :label="'ID'" show-overflow-tooltip :min-width="'50px'" align="center">
          <span slot-scope="{row}">{{ row.id }}</span>
        </el-table-column>
        <el-table-column :label="'标签'" show-overflow-tooltip :min-width="'250px'" align="center">
          <span slot-scope="{row}">{{ row.tags }}</span>
        </el-table-column>
        <el-table-column :label="'历史消耗'" show-overflow-tooltip :min-width="'100px'" align="center">
          <span slot-scope="{row}">{{ row.historyConsume }}</span>
        </el-table-column>
        <el-table-column :label="'关联计划数'" show-overflow-tooltip :min-width="'100px'" align="center">
          <span slot-scope="{row}">{{ row.planCount }}</span>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <c-pagination :page-size.sync="pageSize" :page-no.sync="pageNum" :total="total" :get-data-fun="getPageData" />
      </div>
    </div>
    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">取 消</el-button>
      <el-button class="btn" type="primary" :loading="isLoading" :disabled="selectedData.length === 0"
        @click="clickConfirm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  getTagList
} from '@/network/api/api-huge-batch'
import { PageCommonComponent } from '@/common/mixin'
import { getMeRelationProject } from '@/network/api/basicSetting/project/basicSettingProject'
import { getProductByProject } from '@/network/api/basicSetting/product/basicSettingProduct'
export default {
  name: 'TagListDialog',
  components: {
  },
  filters: {},
  mixins: [PageCommonComponent],
  props: {},
  data() {
    return {
      show: false,
      loading: false,
      searchForm: {
        projectId: '',
        productId: '',
        tag: ''
      }, // 搜索条件
      tagList: [],
      selectedData: [], // 选中的标签数据
      projectList: [], // 项目列表
      productList: [], // 产品列表
      pageNum: 1,
      pageSize: 10,
      total: 0
    }
  },
  computed: {
  },
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {
    /* --------------------------- Actions --------------------------- */
    showModal() {
      this.show = true
      this._getTagList()
    },

    // 选择文案
    onTableTextSelectionChange(selection) {
      this.selectedData = selection
    },
    // 点击搜索
    onClickSearch() {
      if (this.pageNum === 1) {
        this._getTagList()
      } else {
        this.pageNum = 1
      }
    },
    // 点击清空
    onClickReset() {
      this.searchForm = {
        projectId: '',
        productId: '',
        tag: ''
      }
      this.pageNum = 1

      this.onClickSearch()
    },
    // 获取页码跳转数据
    getPageData() {
      this._getTagList()
    },

    // 点击确定按钮
    clickConfirm() {
      if (this.selectedData.length > 1) {
        this.$WarningMessage('只能选择一组标签')
        return
      }
      this.$emit('confirm', this.selectedData)
      this.closeModal()
    },
    // 选择关联项目之后
    handleSelect(e) {
      if (e !== '') {
        this.searchForm.productId = ''
        this._getProduct(e)
      }
    },
    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      // 重置表单
      this.searchForm = {
        projectId: '',
        productId: '',
        tag: ''
      } // 搜索条件
      this.tagList = []
      this.selectedData = [] // 选中的标签数据
      this.projectList = [] // 项目列表
      this.productList = [] // 产品列表
      this.$refs.multipleTable.clearSelection()
    },
    // 关闭弹框
    closeModal() {
      this.show = false
    },
    // 复选框是否可选
    checkSelectable(row, index) {
      let flag = true
      for (let i = 0; i < this.selectedData.length; i++) {
        if (row.id === this.selectedData[i].id) {
          flag = true
          break
        } else {
          flag = false
        }
      }
      return flag
    },
    /* --------------------------- Private --------------------------- */
    // 获取项目列表(个人可见)
    _getProject() {
      getMeRelationProject().then(res => {
        if (res.code === 200) {
          this.projectList = res.data
        }
      })
    },
    // 获取产品列表(个人可见)
    _getProduct(val) {
      getProductByProject(val).then(res => {
        if (res.code === 200) {
          this.productList = res.data
        }
      })
    },
    // 获取标签列表
    _getTagList() {
      this.isLoading = true
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      if (this.searchForm.tag) {
        params.text = this.searchForm.tag
      }
      if (this.searchForm.projectId) {
        params.projectId = this.searchForm.projectId
      }
      if (this.searchForm.productId) {
        params.productId = this.searchForm.productId
      }
      getTagList(params).then(res => {
        this.tagList = res.data.records
        this.total = res.data.total
        console.log(this.selectedData)
        // 选中数据回显
        if (this.selectedData.length) {
          this.echo(this.tagList)
        }
      }).catch(err => {
        this.$ErrorMessage(err.data || '获取标签列表失败')
      }).finally(() => {
        this.isLoading = false
      })
    },
    // 表格数据回显
    echo(data) {
      let rows = []
      data.forEach(item => {
        this.selectedData.forEach(item2 => {
          if (item.id === item2.id) {
            rows.push(item)
          }
        })
      })
      this.toggleSelection(rows)
    },
    // 勾选表格选中
    toggleSelection(rows) {
      if (rows.length) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row, true)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    }
  }
}
</script>

<style lang="scss">
.unselected-all-table {

  .el-table th.el-table__cell>.cell .el-checkbox {
    display: none;
  }
}
</style>

<style scoped lang="scss">
@import "~@/assets/style/variables";
@import "~@/assets/style/mixin";

.table-template {
  .copy-type {
    margin-bottom: 10px;
    width: 150px;
    box-sizing: border-box;
    display: flex;
    color: #909399;
    font-size: 16px;
    font-weight: 700;
    border-bottom: 1px solid #909399;

    .copy-type-item {
      margin-right: 15px;
      padding: 5px;
      cursor: pointer;
    }

    .isSelect {
      color: rgb(52, 92, 224);
      border-bottom: 2px solid rgb(52, 92, 224);
    }
  }

  .search-form-container {
    .el-form {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .flex-btn {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .left {
      margin-right: 10px;
    }
  }

  .page-container {
    margin-bottom: 50px;

    .btn-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;

      .operation-btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .table-info {
          padding: 10px 0;
          color: #333A3F;
          font-size: 16px;
          margin-left: 12px;

          .number {
            color: #2842C8;
            font-size: 24px;
            margin: 0 5px;
          }
        }

        .el-button--small {
          padding: 6px 13px;
        }

        .btn-content {
          @include flex-center;
          height: 18px;

          .icon-container {
            @include flex-center;
            width: 18px;
            height: 18px;
            border-radius: 9px;
            // background: #1667FF;
            margin-right: 6px;

            img {
              width: 12px;
              height: 12px;
            }
          }
        }
      }

      .function-btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .function-btn {
          @include flex-center;
          width: 26px;
          height: 20px;
          background: white;
          transition: 0.2s;
          margin-left: 10px;
          position: relative;
          cursor: pointer;

          .table-columns-container {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 10;
            width: 150px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 2px 5px 0px rgba(229, 228, 234, 1);
            border-radius: 4px;
            padding-left: 10px;
            overflow: hidden;
            display: none;

            .table-columns-item {
              height: 30px;
              line-height: 30px;
            }
          }

          &:hover {
            background: #F2F2F2;

            .table-columns-container {
              display: block;
            }
          }

          img {
            width: 18px;
            height: 18px;
          }
        }
      }
    }

    .table-select-tip {
      height: 38px;
      padding-left: 17px;
      display: flex;
      align-items: center;
      color: #909399;
      font-size: 14px;
      background: #DBE9FF;
      border: 1px solid #4788FF;
      border-radius: 4px;

      img {
        width: 16px;
        height: 16px;
      }
    }

    .table-link {
      color: #606266;
      transition: 0.2s;
      text-decoration: none;

      &:hover {
        color: $themeColor;
        text-decoration: underline;
      }
    }

    .poster-container {
      @include flex-center;
      width: 36px;
      height: 36px;
      overflow: hidden;

      .poster {
        width: 36px;
        min-height: 36px;
      }
    }

    .opt-container {
      display: flex;
      justify-content: space-around;

      .opt-text {
        font-size: 14px;
      }
    }

    .cell-btn {
      font-size: 0;

      img {
        width: 18px;
        height: 18px;
      }

      .cell-btn-hover {
        display: none;
      }

      &:hover {
        .cell-btn-icon {
          display: none;
        }

        .cell-btn-hover {
          display: block;
        }
      }
    }

    .pagination-container {
      height: 64px;
      padding-right: 50px;
      box-sizing: border-box;
    }
  }
}

::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}
</style>

