var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.isEdit ? "编 辑" : "新 增",
        width: "45%",
        visible: _vm.visible,
        "before-close": _vm.close,
        "label-position": "right",
        "modal-append-to-body": false,
        "close-on-press-escape": false,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ent",
          attrs: { model: _vm.ent, rules: _vm.rules, "label-width": "110px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "品类名称", prop: "typeName" } },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.isEdit,
                  maxlength: "30",
                  placeholder: "最大输入长度30 超出截断",
                },
                model: {
                  value: _vm.ent.typeName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "typeName", $$v)
                  },
                  expression: "ent.typeName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否启用", prop: "isUseing" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.ent.isUseing,
                    callback: function ($$v) {
                      _vm.$set(_vm.ent, "isUseing", $$v)
                    },
                    expression: "ent.isUseing",
                  },
                },
                _vm._l(_vm.isUseingList, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  maxlength: "100",
                  placeholder: "最大输入长度100 超出截断",
                },
                model: {
                  value: _vm.ent.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "remark", $$v)
                  },
                  expression: "ent.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { attrs: { round: "" }, on: { click: _vm.close } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-popconfirm",
            {
              staticClass: "ml-10",
              attrs: {
                "confirm-button-text": "提交",
                "cancel-button-text": "取消",
                icon: "el-icon-info",
                "icon-color": "red",
                title: "确定提交？",
              },
              on: { confirm: _vm.submit },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { slot: "reference", type: "primary", round: "" },
                  slot: "reference",
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }