<!--
 * @Description: 选择落地页
 * @Date: 2021-11-17 09:55:17
 * @LastEditTime: 2022-02-21 09:45:12
-->
<template>
  <el-dialog title="落地页选择" :visible="ldyVisible" width="1200px" label-position="right" :modal-append-to-body="false" :close-on-press-escape="false" :close-on-click-modal="false" @close="close">
    <!-- 落地页名称 -->
    <div>
      <el-row>
        <div class=" mb-20">
          <span class="spanTitlePlan"> 落地页选择</span>
          <span style="margin-left:10px"><el-checkbox v-model="isTrackChecked">使用转化追踪回传数据</el-checkbox></span>
        </div>
        <el-col :span="24" class="col">
          <ul v-if="houseAccountData.length > 0" class="account-list">
            <li v-for="item in houseAccountData" :key="item.accountName" :class="{ active: ldySelectId.baiduId === item.baiduId }" @click="setLdySelectId(item)">
              <p>{{ item.accountName }}</p>
              <i class="el-icon-arrow-right"></i>
            </li>
          </ul>
          <ul v-else>
            <li class="null">
              <p>暂无数据</p>
            </li>
          </ul>
          <div class="woodenFish">
            <div class="search">
              <p>落地页名称:</p>
              <el-input v-model="woodenFishFilterText" style="width: 300px" placeholder="输入落地页进行过滤"> </el-input>
            </div>
            <div class="search-tree">
              <el-tree ref="woodenFishRef" :data="woodenFishData" :filter-node-method="woodenFishFilterNode" show-checkbox node-key="id" :props="defaultProps" @check="woodenFishChange"> </el-tree>
            </div>
          </div>
        </el-col>

        <el-col :span="24">
          <div>
            <div>
              <el-table max-height="400px" :data="woodenFishSelectedData_ten" border style="width: 100% ">
                <el-table-column type="index" label="序号" width="70" align="center" :show-overflow-tooltip="true" />
                <el-table-column prop="accountName" label="投放账户" />
                <el-table-column prop="siteName" label="落地页名称" />
                <el-table-column prop="transType" label="目标转化" />
                <el-table-column label="操作" width="60">
                  <template slot-scope="{ row }">
                    <el-button type="text" size="small" @click="deleteWoodenFishData(row)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <el-pagination v-if="woodenFishSelectedData.length > 0" layout="prev, pager, next" :total="woodenFishSelectedData.length" :page-size="10" :current-page="woodenFishChangePage" @current-change="woodenFishPageChange" />
          </div>
        </el-col>
      </el-row>

      <!-- 转化追踪选择 -->
      <el-row v-if="isTrackChecked" style="margin-top: 30px">
        <div class=" mb-20">
          <span class="spanTitlePlan"> 转化追踪选择</span>
        </div>
        <el-col :span="24" class="col">
          <ul v-if="houseAccountData.length > 0" class="account-list">
            <li v-for="item in houseAccountData" :key="item.accountName" :class="{ active: trackSelectId.baiduId === item.baiduId }" @click="setTrackSelectId(item)">
              <p>{{ item.accountName }}</p>
              <i class="el-icon-arrow-right"></i>
            </li>
          </ul>
          <ul v-else>
            <li class="null">
              <p>暂无数据</p>
            </li>
          </ul>
          <div class="woodenFish">
            <div class="search">
              <p>转化追踪名称:</p>
              <el-input v-model="trackText" style="width: 300px" placeholder="输入落地页进行过滤"> </el-input>
            </div>
            <div class="search-tree">
              <el-tree ref="trackRef" :data="trackData" :filter-node-method="trackNode" show-checkbox node-key="id" :props="defaultProps" @check="trackChange"> </el-tree>
            </div>
          </div>
        </el-col>

        <el-col :span="24">
          <div>
            <div>
              <el-table max-height="400px" :data="trackSelectedData_ten" border style="width: 100% ">
                <el-table-column type="index" label="序号" width="70" align="center" :show-overflow-tooltip="true" />
                <el-table-column prop="accountName" width="300" label="投放账户" />
                <el-table-column prop="transName" label="转化追踪名称">
                  <template slot-scope="{ row }">
                    {{ row.transName }}
                  </template>
                </el-table-column>
                <el-table-column prop="transType" label="目标转化" />
                <el-table-column label="操作" width="60">
                  <template slot-scope="{ row }">
                    <el-button type="text" size="small" @click="deleteTrackData(row)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <el-pagination v-if="trackSelectedData.length > 0" layout="prev, pager, next" :total="trackSelectedData.length" :page-size="10" :current-page="trackChangePage" @current-change="trackPageChange" />
          </div>
        </el-col>
      </el-row>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="ldySubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getWoodenFishList, getTrackList } from '@/network/api/advManagement/advManagementBaiduPublish'
import { transType } from './transType'
export default {
  props: {
    ldyVisible: {
      type: Boolean,
      default: false
    },
    houseAccountData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      disabled: true,
      // 落地页选择start
      isTrackChecked: true,
      transFrom: 0,
      woodenFishData: [], // 基木鱼list
      woodenFishData_copy: [],
      ldySelectId: {}, // 选中的账号
      woodenFishFilterText: '', // 筛选落地页
      woodenFishSelectedData: [], // 右边选中的表格
      woodenFishSelectedData_ten: [], // 右边选中的表格
      woodenFishChangePage: 1, // 当前页
      // 落地页选择end
      // 转化追踪选择start
      trackSelectId: {}, // 选中的账号
      trackData: [], // 基木鱼list
      trackText: '',
      trackSelectedData: [], // 右边选中的表格
      trackSelectedData_ten: [], // 右边选中的表格
      trackChangePage: 1, // 当前页
      // 转化追踪选择startend

      hugeSelectList: [],
      hugeValue: '',
      accountValue: '',
      hugeList: [],
      hugeAllList: [],
      accountList: [],
      accountAllList: [],
      currentNodeList: [],
      // houseAccountData: [],
      houseAccountAllData: [],
      othercurrentPage: 1 // 当前页
    }
  },
  watch: {
    woodenFishFilterText(val) {
      this.$refs.woodenFishRef.filter(val)
    },
    trackText(val) {
      this.$refs.trackRef.filter(val)
    },
    isTrackChecked() {
      this.woodenFishData = this.getWoodenFishData(this.woodenFishData_copy)
      this.woodenFishSelectedData = [] // 右边选中的表格
      this.woodenFishSelectedData_ten = [] // 右边选中的表格
      this.trackSelectedData = [] // 右边选中的表格
      this.trackSelectedData_ten = [] // 右边选中的表格
      this.trackSelectId = {}
      this.trackSelectId = {}
      this.$refs.woodenFishRef.setCheckedKeys([])
      this.$refs.trackRef.setCheckedKeys([])
    }
  },
  methods: {
    // 落地页选择start
    setLdySelectId(item) {
      this.ldySelectId = item
      const { baiduId, hkId } = item
      this.getWoodenFish(baiduId, hkId)
    },

    // 获取基木鱼
    getWoodenFish(baiduId, housekeeperId) {
      getWoodenFishList({ baiduId, housekeeperId }).then(({ code, data }) => {
        if (code === 200) {
          this.woodenFishData_copy = data
          this.woodenFishData = this.getWoodenFishData(data)
        }
      })
    },

    getWoodenFishData(data) {
      return data.map((item) => {
        item.label = item.siteName
        item.id = item.woodenFishId
        item.children = this.isTrackChecked ? [] : this.formatTransType(item.transType, item.id)
        item.disabled = !this.isTrackChecked
        return item
      })
    },

    formatTransType(trans, pId) {
      if (!trans) return []
      const transTypeArr = []
      transType.forEach((item) => {
        if (trans.split(',').includes(item.id)) {
          transTypeArr.push({
            id: `${pId}-${item.id}`,
            label: item.label
          })
        }
      })
      return transTypeArr
    },

    // 选择落地页
    woodenFishChange(e, detaile) {
      let item = {}
      let _accountName = ''
      if (this.isTrackChecked) {
        _accountName = e.accountName
        item = {
          id: e.id,
          siteName: e.siteName,
          launchAccountName: this.houseAccountData.filter((f) => f.baiduId === this.ldySelectId.baiduId)[0].launchAccountName,
          accountName: e.accountName,
          url: e.onlineUrl,
          transType: ''
        }
      } else {
        const { siteName, accountName, onlineUrl } = detaile.halfCheckedNodes.length ? detaile.halfCheckedNodes[detaile.halfCheckedNodes.length - 1] : detaile.checkedNodes.filter((f) => f.id === +e.id.split('-')[0])[0]
        _accountName = accountName
        item = {
          id: e.id,
          siteName,
          launchAccountName: this.houseAccountData.filter((f) => f.baiduId === this.ldySelectId.baiduId)[0].launchAccountName,
          accountName: accountName, // this.houseAccountData.filter((f) => f.baiduId === this.ldySelectId.baiduId)[0].accountName,
          transType: transType.filter((f) => f.id === e.id.split('-')[1])[0].label,
          url: onlineUrl
        }
      }
      if (this.woodenFishSelectedData.some((s) => s.accountName === _accountName)) {
        // 已选择,取消选中
        let index = this.woodenFishSelectedData.findIndex((s) => s.accountName === _accountName)
        this.woodenFishSelectedData.splice(index, 1, item)
        // this.$message.error(`已选择过${siteName}下的转会目标`)
      } else {
        // 未选中
        this.woodenFishSelectedData.push(item)
      }
      this.$refs.woodenFishRef.setCheckedKeys(this.woodenFishSelectedData.map((item) => item.id))
      this.woodenFishSelectedData_ten = this.woodenFishSelectedData.slice(0, 10)
    },
    // 筛选落地页
    woodenFishFilterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },

    // 删除
    deleteWoodenFishData(val) {
      this.woodenFishSelectedData.forEach((x, index) => {
        if (x.id === val.id) {
          this.woodenFishSelectedData.splice(index, 1)
        }
      })
      this.woodenFishChangePage = 1
      this.woodenFishSelectedData_ten = this.woodenFishSelectedData.slice(0, 10)
      this.$refs.woodenFishRef.setCheckedKeys(this.woodenFishSelectedData.map((item) => item.id))
      this.$message({
        message: '删除成功',
        type: 'success'
      })
    },

    woodenFishPageChange(val) {
      var start = (val - 1) * 10
      var end = val * 10
      this.woodenFishChangePage = val
      this.woodenFishSelectedData_ten = this.woodenFishSelectedData.slice(start, end)
    },
    // 落地页选择end

    // 获取转化追踪start
    setTrackSelectId(item) {
      this.trackSelectId = item
      const { baiduId, hkId } = item
      this.getTrack(baiduId, hkId)
    },

    getTrack(baiduId, housekeeperId) {
      getTrackList({ baiduId, housekeeperId }).then(({ code, data }) => {
        if (code === 200) {
          this.trackData = data.map((item) => {
            item.label = item.transName
            item.id = item.appTransId
            item.children = this.formatTransType(item.transTypes, item.id)
            item.disabled = true
            return item
          })
        }
        console.log('this.trackData', this.trackData)
      })
    },

    trackChange(e, detaile) {
      const { transName, accountName, appTransId, transFrom } = detaile.halfCheckedNodes.length ? detaile.halfCheckedNodes[detaile.halfCheckedNodes.length - 1] : detaile.checkedNodes.filter((f) => f.id === +e.id.split('-')[0])[0]
      let item = {
        id: e.id,
        transName,
        appTransId,
        transFrom,
        launchAccountName: this.houseAccountData.filter((f) => f.baiduId === this.ldySelectId.baiduId)[0].launchAccountName,
        accountName: accountName, // this.houseAccountData.filter((f) => f.baiduId === this.ldySelectId.baiduId)[0].accountName,
        transType: transType.filter((f) => f.id === e.id.split('-')[1])[0].label
      }
      if (this.trackSelectedData.some((s) => s.accountName === accountName)) {
        // 已选择,取消选中
        let index = this.trackSelectedData.findIndex((s) => s.accountName === accountName)
        this.trackSelectedData.splice(index, 1, item)
        // this.$message.error(`已选择过${siteName}下的转会目标`)
      } else {
        // 未选中
        this.trackSelectedData.push(item)
      }
      this.$refs.trackRef.setCheckedKeys(this.trackSelectedData.map((item) => item.id))

      this.trackSelectedData_ten = this.trackSelectedData.slice(0, 10)
    },

    // 筛选落地页
    trackNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },

    // 删除
    deleteTrackData(val) {
      this.trackSelectedData.forEach((x, index) => {
        if (x.id === val.id) {
          this.trackSelectedData.splice(index, 1)
        }
      })
      this.trackChangePage = 1
      this.trackSelectedData_ten = this.trackSelectedData.slice(0, 10)
      this.$refs.trackRef.setCheckedKeys([])
      this.$message({
        message: '删除成功',
        type: 'success'
      })
    },

    trackPageChange(val) {
      var start = (val - 1) * 10
      var end = val * 10
      this.trackChangePage = val
      this.trackSelectedData_ten = this.trackSelectedData.slice(start, end)
    },

    ldySubmit() {
      if (this.isTrackChecked) {
        if (!(this.woodenFishSelectedData.length === this.houseAccountData.length && this.trackSelectedData.length === this.houseAccountData.length)) {
          return this.$message.error('落地页未选择完!')
        }
      } else {
        if (!(this.woodenFishSelectedData.length === this.houseAccountData.length)) {
          return this.$message.error('落地页未选择完!')
        }
      }
      let accountAndLandingPageAndTrackList = []
      this.houseAccountData.forEach((item, index) => {
        this.woodenFishSelectedData.forEach((w) => {
          if (item.accountName === w.accountName) {
            accountAndLandingPageAndTrackList.splice(index, 0, {
              accountName: item.accountName,
              housekeeperId: item.housekeeperId, // 管家id
              baiduId: item.baiduId, // 百度id
              onlineUrl: w.url, // 落地页url
              appTransId: null,
              transType: this.isTrackChecked ? '' : +w.id.split('-')[1], // 落地页转化目标
              transFrom: this.transFrom ? 0 : 2
            })
          }
        })
      })
      // console.log(data)
      let isTrackChecked = this.isTrackChecked
      accountAndLandingPageAndTrackList.forEach((item, index) => {
        this.trackSelectedData.forEach((t) => {
          if (item.accountName === t.accountName) {
            // delete accountAndLandingPageAndTrackList[index].accountName
            if (isTrackChecked) {
              accountAndLandingPageAndTrackList[index].appTransId = t.appTransId
              accountAndLandingPageAndTrackList[index].transType = +t.id.split('-')[1] // 落地页转化目标
              accountAndLandingPageAndTrackList[index].transFrom = +t.transFrom
            }
          }
        })
      })
      this.$emit('getLdyData', accountAndLandingPageAndTrackList)
    },

    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
.spanTitlePlan {
  font-size: 18px;
  font-weight: 700;
}

.elTreeLeft {
  overflow: auto;
  height: calc(300px);
  width: 300px;
}
.col {
  display: flex;
  border: 1px solid #ccc;
  height: 400px;
  margin-bottom: 40px;
  .woodenFish {
    width: 100%;
    padding: 10px 0 10px 10px;
    .search {
      display: flex;
      align-items: center;
      & > p {
        font-size: 14px;
        width: 100px;
      }
    }
    .search-tree {
      height: 332px;
      overflow-y: auto;
    }
  }
  & > ul {
    padding: 0 !important;
    margin: 0 !important;
    border-right: 1px solid #ccc;
    width: 180px;
    .null {
      display: flex;
      height: 100%;
      justify-content: center;
      p {
        font-size: 14px;
        color: #909399;
      }
    }
  }
  .account-list {
    font-size: 14px;
    height: 100%;
    width: 239px;
    // overflow-y: auto;
    li {
      line-height: 30px;
      color: #000;
      padding: 2px 6px;
      width: 239px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      &:hover {
        background: #409eff;
        color: #fff;
      }
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 !important;
        margin: 0 !important;
      }
    }
    .active {
      background: #409eff;
      color: #fff;
    }
  }
}
</style>
