<!--
 * @Description: 落地页
 * @Date: 2022-01-07 14:52:55
 * @LastEditTime: 2022-05-19 13:47:58
-->

<template>
  <div style="margin-top: 10px">
    <el-row :gutter="10">
      <el-col class="tree" :span="6">
        <div class="left-ul">
          <p>已选投放账户</p>
          <!-- 模板数据 -->
          <ul>
            <div v-if="accountData.length">
              <div>
                <li v-for="item in accountData" :key="item._id" :class="{ active: leftQuerySelect._id === item._id }" @click="setLeftQuerySelect(item)">{{ `${item._name}(${item.taaccountId})` }}</li>
              </div>
            </div>
            <div v-else style="text-align: center; margin-top: 40px">
              <span>未选择</span>
            </div>
          </ul>
        </div>
      </el-col>
      <el-col :span="12">
        <el-input v-model="name" placeholder="请输入落地页名称" class="input-with-select w-200" style="margin-right:10px"></el-input>
        <el-input v-model="ownerUid" placeholder="请输入落地页账户ID" class="input-with-select mb-10 w-200" style="margin-right:10px"></el-input>
        <el-button type="primary" @click="getPage">查询</el-button>
        <TablePane ref="TablePane" :data-source="dataSource" @getPage="getPage" />
      </el-col>
      <!-- <el-col :span="6">
        <p class="crowd-pack-selected-title">已选落地页</p>
        <ul class="crowd-pack-selected">
          <p class="tip">已发布</p>
          <li v-for="item in ldyData.list.filter((f) => f.taskPageId)" :key="item.id">
            <span>{{ item.pageName }}</span>
            <i class="el-icon-circle-close" @click="deleteSelectInterest(item)"></i>
          </li>
        </ul>
        <ul class="crowd-pack-selected">
          <p class="tip">未发布</p>
          <li v-for="item in ldyData.list.filter((f) => f.taskPageId === null)" :key="item.id">
            <span>{{ item.pageName }}</span>
            <i class="el-icon-circle-close" @click="deleteSelectInterest(item)"></i>
          </li>
        </ul>
      </el-col> -->
    </el-row>
  </div>
</template>

<script>
import TablePane from '@/components/tablePane.vue'
import { getLdy } from '@/network/api/advManagement/advManagementTencent' // 获取计划模板

export default {
  components: {
    TablePane
  },
  props: {
    formData: {
      type: Object,
      default: () => {}
    },
    taskId: {
      type: Number,
      default: 0
    },
    accountData: {
      type: Array,
      default: () => []
    },
    taskSiteSet: {
      type: Number,
      default: 0
    },
    creativityData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      name: null,
      ownerUid: null,
      ldyData: {},
      leftQuerySelect: {},
      dataSource: {
        data: [],
        cols: [
          {
            label: '落地页名称',
            prop: 'pageName'
          },
          {
            label: '上传时间',
            prop: 'createTime'
          }
        ], // 表格的列数据
        isIndex: false, // 列表序号
        loading: false, // loading
        isOperation: false,
        isSelection: true,
        pageData: {
          total: 0, // 总条数
          layout: 'total, prev, pager, next'
        },
        handleSelectionChange: () => {},
        selectable: (row) => {
          // 朋友圈 且使用原生落地页顶部素材
          if ([1, 12, 13, 14, 123, 124, 134, 1234].includes(this.taskSiteSet) && this.creativityData.overrideCanvasHeadOption === 'OPTION_CANVAS_OVERRIDE_CREATIVE') {
            let select = false
            const adcreativeTemplateId = this.creativityData.adcreativeTemplateId
            if (adcreativeTemplateId === 311) {
              const pageElementsSpec = row.pageElementsSpecList.filter((f) => f.elementType === 'IMAGE')[0]
              if (pageElementsSpec) {
                select = true
              }
            }
            if ([641, 642, 643].includes(adcreativeTemplateId)) {
              const pageElementsSpec = row.pageElementsSpecList.filter((f) => f.elementType === 'CAROUSEL')[0]
              if (pageElementsSpec) {
                select = true
              }
            }
            if ([720, 721].includes(adcreativeTemplateId)) {
              const pageElementsSpec = row.pageElementsSpecList.filter((f) => f.elementType === 'VIDEO')[0]
              if (pageElementsSpec) {
                select = true
              }
            }
            return select
          } else {
            return true
          }
        },
        selectAll: (selection) => {
          if (selection.length > 0) {
            let selectionList = []
            selectionList.push(...selection)
            let list = []
            selectionList.map((item) => {
              let onlineImageIdList = null
              let onlineVideoId = 0
              const adcreativeTemplateId = this.creativityData.adcreativeTemplateId
              if ([1, 12, 13, 14, 123, 124, 134, 1234].includes(this.taskSiteSet) && this.creativityData.overrideCanvasHeadOption === 'OPTION_CANVAS_OVERRIDE_CREATIVE') {
                if (adcreativeTemplateId === 311) {
                  const pageElementsSpec = item.pageElementsSpecList.filter((f) => f.elementType === 'IMAGE')[0]
                  if (pageElementsSpec) {
                    onlineImageIdList = JSON.stringify(pageElementsSpec.imageSpec.imageIdList)
                  }
                }
                if ([641, 642, 643].includes(adcreativeTemplateId)) {
                  const pageElementsSpec = item.pageElementsSpecList.filter((f) => f.elementType === 'CAROUSEL')[0]
                  if (pageElementsSpec) {
                    onlineImageIdList = JSON.stringify(pageElementsSpec.imageSpec.imageIdList)
                  }
                }
                if ([720, 721].includes(adcreativeTemplateId)) {
                  const pageElementsSpec = item.pageElementsSpecList.filter((f) => f.elementType === 'VIDEO')[0]
                  if (pageElementsSpec) {
                    onlineVideoId = pageElementsSpec.videoSpec.videoId
                  }
                }
              }
              if (list.length === 0 || !list.some((s) => s._id === item._id)) {
                list.push({
                  taskPageId: item.taskPageId || null,
                  taskId: item.taskId || null,
                  id: item.id,
                  pageName: item.pageName,
                  taskAccountId: item.taskAccountId,
                  onlineVideoId,
                  onlineImageIdList,
                  _id: `${item.taskAccountId}+${item.id}`
                })
              }
            })

            let arr = [...this.ldyData.list, ...list]

            let newArr = []
            arr.forEach((item) => {
              if (newArr.length === 0 || !newArr.some((s) => s._id === item._id)) {
                newArr.push(item)
              }
            })

            this.ldyData = {
              ...this.ldyData,
              list: newArr
            }
          } else {
            this.ldyData = {
              ...this.ldyData,
              list: this.ldyData.list.filter((f) => f.taskPageId !== null)
            }
          }
          this.$emit('setLdyData', this.ldyData)
        },
        select: (selection, row) => {
          if (row.taskPageId) {
            this.setSelectData()
            return this.$message.error('该条落地页已发布')
          }
          if (selection.length > 0) {
            let selectionList = []
            selectionList.push(...selection)
            let list = []
            selectionList.map((item) => {
              if (list.length === 0 || !list.some((s) => s._id === item._id)) {
                let onlineImageIdList = null
                let onlineVideoId = 0
                const adcreativeTemplateId = this.creativityData.adcreativeTemplateId
                if ([1, 12, 13, 14, 123, 124, 134, 1234].includes(this.taskSiteSet) && this.creativityData.overrideCanvasHeadOption === 'OPTION_CANVAS_OVERRIDE_CREATIVE') {
                  if (adcreativeTemplateId === 311) {
                    const pageElementsSpec = item.pageElementsSpecList.filter((f) => f.elementType === 'IMAGE')[0]
                    if (pageElementsSpec) {
                      onlineImageIdList = JSON.stringify(pageElementsSpec.imageSpec.imageIdList)
                    }
                  }
                  if ([641, 642, 643].includes(adcreativeTemplateId)) {
                    const pageElementsSpec = item.pageElementsSpecList.filter((f) => f.elementType === 'CAROUSEL')[0]
                    if (pageElementsSpec) {
                      onlineImageIdList = JSON.stringify(pageElementsSpec.imageSpec.imageIdList)
                    }
                  }
                  if ([720, 721].includes(adcreativeTemplateId)) {
                    const pageElementsSpec = item.pageElementsSpecList.filter((f) => f.elementType === 'VIDEO')[0]
                    if (pageElementsSpec) {
                      onlineVideoId = pageElementsSpec.videoSpec.videoId
                    }
                  }
                }
                list.push({
                  taskPageId: item.taskPageId || null,
                  taskId: item.taskId || null,
                  id: item.id,
                  pageName: item.pageName,
                  taskAccountId: item.taskAccountId,
                  onlineVideoId,
                  onlineImageIdList,
                  _id: `${item.taskAccountId}+${item.id}`
                })
              }
            })

            let arr = [...this.ldyData.list, ...list]

            let newArr = []
            arr.forEach((item) => {
              if (newArr.length === 0 || !newArr.some((s) => s._id === item._id)) {
                newArr.push(item)
              }
            })

            if (!selection.some((s) => s._id === row._id)) {
              newArr = newArr.filter((f) => f._id !== row._id)
            }

            this.ldyData = {
              ...this.ldyData,
              list: newArr
            }
          } else {
            this.ldyData = {
              ...this.ldyData,
              list: this.ldyData.list.filter((f) => f.taskPageId !== null)
            }
          }
          this.$emit('setLdyData', this.ldyData)
        }
      }
    }
  },

  watch: {
    formData: {
      handler(value) {
        if (value.list) {
          this.ldyData = {
            ...value,
            list: value.list.map((item) => {
              return {
                ...item,
                id: item.onlinePageId,
                pageName: item.onlinePageName,
                taskAccountId: item.taskAccountId,
                _id: `${item.taskAccountId}+${item.onlinePageId}`
              }
            })
          }
        } else {
          this.ldyData = value
        }
      },
      immediate: true,
      deep: true
    },
    accountData(val) {
      if (val && val.length) {
        this.leftQuerySelect = val[0]
      }
    }
  },

  methods: {
    setLeftQuerySelect(item) {
      this.leftQuerySelect = item
      this.getPage()
    },

    getPage(params = { pageNum: 1, pageSize: 10 }) {
      if (!this.leftQuerySelect || !this.leftQuerySelect.localAccountId) {
        this.leftQuerySelect = this.accountData[0]
      }
      this.dataSource.loading = true
      getLdy({ ...params, localAccountId: this.leftQuerySelect.localAccountId, ownerUid: this.ownerUid, pageName: this.name }).then(({ code, data }) => {
        if (code === 200) {
          const { list, pageInfo } = data
          list.forEach((element) => {
            element.id = element.pageId
            element.taskPageId = (this.ldyData.list.filter((f) => f.onlinePageId === element.id).length && this.ldyData.list.filter((f) => f.onlinePageId === element.id)[0].taskPageId) || null
            element.taskId = this.taskId
            element.taskAccountId = this.leftQuerySelect._id
            element._id = `${this.leftQuerySelect._id}+${element.pageId}`
          })
          this.dataSource.data = list
          this.dataSource.pageData.total = pageInfo.totalNumber
          this.dataSource.pageData.pageNum = params.pageNum
          this.dataSource.loading = false
          this.setSelectData()
        }
      })
    },

    setSelectData() {
      this.$refs['TablePane'].getRowData([])
      if (this.ldyData.list.length) {
        let rows = []
        this.dataSource.data.map((item) => {
          if (this.ldyData.list.some((s) => s._id === item._id)) {
            rows.push(item)
          }
        })
        this.$refs['TablePane'].getRowData(rows)
      }
    }
  }
}
</script>

<style lang="scss">
.city-box {
  .el-cascader__tags {
    max-height: 240px;
    overflow-y: auto;
  }
}
.el-cascader-panel {
  .el-cascader-menu__wrap {
    height: 320px;
  }
}
</style>

<style lang="scss" scoped>
p {
  margin: 0;
  padding: 0;
}

.background {
  background: #f7f8fa;
  padding: 10px;
  border-radius: 10px;
}

.crowd-pack-selected-title {
  border-left: 3px solid #409eff;
  padding-left: 5px;
  margin: 0 0 5px 0;
  font-weight: 600;
}
.crowd-pack-selected {
  display: flex;
  flex-direction: column;
  max-height: 486px;
  overflow-y: auto;
  li {
    width: 100%;
    display: flex;
    padding: 4px 0;
    align-items: center;
    font-size: 14px;
    span {
      width: 80%;
      text-align: right;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      flex: 1;
      margin-right: 10px;
    }
    i {
      width: 20px;
      cursor: pointer;
      font-weight: 600;
    }
  }
}

.crowd-pack-selected2 {
  display: flex;
  flex-direction: column;
  height: 296px;
  padding: 0 10px 0 0;
  overflow-y: auto;
  li {
    width: 100%;
    display: flex;
    padding: 4px 0;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    span {
      text-align: right;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-right: 10px;
      font-size: 13px;
    }
    i {
      cursor: pointer;
      color: #888888;
      font-size: 13px;
    }
  }
}

.tree {
  height: 100%;
  padding-left: 10px !important;
  border: 1px solid #ccc;
  border-radius: 8px;
  // padding: 20px;
  display: flex;

  .left-ul {
    // padding-top: 10px;
    width: 100%;
    // border-right: 1px solid #ccc;
    p {
      font-size: 16px;
      width: 100%;
      padding: 10px;
      border-bottom: 1px solid #ccc;
    }
    ul {
      overflow-y: auto;
      height: 452px;
      li {
        padding: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        &.active {
          background: #409eff;
          color: #fff;
        }
      }
    }
  }
  &.el-col {
    padding: 0 !important;
  }
}
</style>
