var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          staticStyle: { "margin-bottom": "10px" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "数据查询", name: "first" } }),
          _c("el-tab-pane", { attrs: { label: "文件下载", name: "second" } }),
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "search",
          staticClass: "search-form search-form-4",
          attrs: { inline: true, model: _vm.search },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _vm.activeName === "second"
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "生成日期", prop: "generateTime" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              placeholder: "选择日期",
                              "value-format": "yyyy-MM-dd",
                            },
                            model: {
                              value: _vm.search.generateTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.search, "generateTime", $$v)
                              },
                              expression: "search.generateTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.activeName === "second"
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "创建人", prop: "createBy" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请输入创建人" },
                            model: {
                              value: _vm.search.createBy,
                              callback: function ($$v) {
                                _vm.$set(_vm.search, "createBy", $$v)
                              },
                              expression: "search.createBy",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "数据类型", prop: "dataType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.search.dataType,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "dataType", $$v)
                            },
                            expression: "search.dataType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "企微留存", value: 1 },
                          }),
                          _c("el-option", {
                            attrs: { label: "企微流失", value: 2 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.activeName === "second"
                ? _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", round: "" },
                              on: { click: _vm.onSubmit },
                            },
                            [_vm._v("查 询")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", round: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.resetForm("ruleForm")
                                },
                              },
                            },
                            [_vm._v("重 置")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.activeName === "first"
        ? _c(
            "div",
            {
              staticClass: "projection-header",
              staticStyle: { "margin-bottom": "30px" },
            },
            [_c("span", [_vm._v("数据生成条件")])]
          )
        : _vm._e(),
      _vm.activeName === "first"
        ? _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "validate-on-rule-change": false,
                "label-position": "right",
                "label-suffix": "：",
                "label-width": "135px",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24, type: "flex" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "进粉时间", prop: "followersTime" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "daterange",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "value-format": "yyyy-MM-dd",
                              "picker-options": _vm.pickerOptions,
                            },
                            model: {
                              value: _vm.formData.followersTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "followersTime", $$v)
                              },
                              expression: "formData.followersTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "区分性别" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: {
                                width: "80px",
                                "margin-right": "5px",
                              },
                              attrs: { placeholder: "请选择是否区分" },
                              model: {
                                value: _vm.formData.isUseGender,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "isUseGender", $$v)
                                },
                                expression: "formData.isUseGender",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "是", value: 1 },
                              }),
                              _c("el-option", {
                                attrs: { label: "否", value: 2 },
                              }),
                            ],
                            1
                          ),
                          _vm.formData.isUseGender === 1
                            ? _c(
                                "el-select",
                                {
                                  attrs: {
                                    placeholder: "请选择性别",
                                    multiple: "",
                                    filterable: "",
                                    "collapse-tags": "",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.formData.gender,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "gender", $$v)
                                    },
                                    expression: "formData.gender",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "男", value: 1 },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "女", value: 2 },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "未知", value: 3 },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "导出数据", prop: "exportData" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择导出数据",
                                multiple: "",
                                filterable: "",
                                "collapse-tags": "",
                                clearable: "",
                              },
                              model: {
                                value: _vm.formData.exportData,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "exportData", $$v)
                                },
                                expression: "formData.exportData",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "IDFA", value: 1 },
                              }),
                              _c("el-option", {
                                attrs: { label: "IMEI", value: 2 },
                              }),
                              _c("el-option", {
                                attrs: { label: "MAC", value: 3 },
                              }),
                              _c("el-option", {
                                attrs: { label: "OAID", value: 4 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 24, type: "flex" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "title-label",
                          attrs: { label: "指定企微" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: {
                                width: "100px",
                                "margin-right": "5px",
                              },
                              attrs: { placeholder: "请选择是否指定企微" },
                              model: {
                                value: _vm.formData.selectWeCom,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "selectWeCom", $$v)
                                },
                                expression: "formData.selectWeCom",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "是", value: 1 },
                              }),
                              _c("el-option", {
                                attrs: { label: "否", value: 2 },
                              }),
                            ],
                            1
                          ),
                          _c("span", [_vm._v("注：不指定则全量数据生成")]),
                          _vm.formData.selectWeCom === 1
                            ? _c(
                                "div",
                                { staticStyle: { "margin-top": "10px" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      rows: 6,
                                      placeholder:
                                        "输入企微主体ID,支持多个，回传换行区格",
                                    },
                                    model: {
                                      value: _vm.formData.weComId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formData, "weComId", $$v)
                                      },
                                      expression: "formData.weComId",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.activeName === "first"
        ? _c(
            "div",
            { staticClass: "form-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.handleConfirm()
                    },
                  },
                },
                [_vm._v("生成数据文件")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        [
          _vm.activeName === "second"
            ? _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.list.loading,
                      expression: "list.loading",
                    },
                  ],
                  staticClass: "list-table",
                  class: { "no-data": !_vm.list.data || !_vm.list.data.length },
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.list.data, stripe: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      label: "序号",
                      width: "50",
                      align: "center",
                      fixed: "left",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "类型", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("filterToolType")(row.dataType)
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      415325422
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "数据条件", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onClickCheck(row)
                                    },
                                  },
                                },
                                [_vm._v("查看")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      629053524
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "状态", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "span",
                                {
                                  style: {
                                    color:
                                      row.status === "生成失败"
                                        ? "red"
                                        : row.status === "已完成"
                                        ? "lime"
                                        : "",
                                  },
                                },
                                [_vm._v(_vm._s(row.status))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1280348284
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createBy",
                      label: "创建人",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "生成时间",
                      align: "center",
                      width: "200",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "",
                      label: "操作",
                      align: "center",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              row.status === "已完成"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onClickDownload(row.url)
                                        },
                                      },
                                    },
                                    [_vm._v("下载")]
                                  )
                                : row.status === "生成失败"
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onClickRegenerationData(
                                            row.tiktokExportZipId
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("重新生成")]
                                  )
                                : _c("span", [_vm._v("-")]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1852276323
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.activeName === "second"
            ? _c(
                "div",
                { staticClass: "page" },
                [
                  _c("paginations", {
                    attrs: {
                      total: _vm.list.total,
                      page: _vm.list.pageNum,
                      "page-sizes": [5, 10, 15, 20],
                      limit: _vm.list.pageSize,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.list, "pageNum", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.list, "pageSize", $event)
                      },
                      pagination: _vm.getPage,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("data-details-dialog", { ref: "dataDetails" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }