<!--
 * @Description: 计划/ 广告 展示 tree
 * @Date: 2021-12-06 15:57:16
 * @LastEditTime: 2022-03-17 14:10:38
-->
<template>
  <div class="source">
    <!-- 添加计划 -->
    <SelectModal
      ref="modal"
      type="table"
      dialog-title="数据源"
      left-title="投放账户"
      :title-info="{
        title: '选择推广计划',
        leftLevel: '1'
      }"
      :visible="visible"
      :left-query-list="accountAdv.map((item) => ({ _name: item.name, _id: item.taskAccountId }))"
      :table-data-source="dataSource"
      :select-page-list="selectPageList"
      @onOk="getSelectSourceData"
    />
  </div>
</template>

<script>
import { getTrack, updateSource } from '@/network/api/advManagement/advManagementWatch'
import SelectModal from '@/components/selectModal/selectModal.vue'
import _ from 'lodash'

export default {
  inject: ['sourceClose'],
  provide: function() {
    return {
      getDataByLeftQuery: this.getSourceFun,
      queryFun1: this.sourceSync,
      queryText1: '同步',
      close: this.sourceClose,
      setSelectPageList: this.setSelectPageList,
      deleteSelectData: this.deleteSelectSourceData
    }
  },

  components: {
    SelectModal
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    accountAdv: {
      type: Array,
      default: () => []
    },
    taskId: {
      type: Number,
      default: 0
    },
    formData: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      selectPageList: [],
      // 当前选中的id
      taskAccountId: 0,
      // 表格数据
      dataSource: {
        data: [],
        cols: [
          {
            label: '数据源名称',
            prop: 'name'
          },
          {
            label: '数据源ID',
            prop: 'user_action_set_id'
          }
        ], // 表格的列数据
        isIndex: false, // 列表序号
        loading: true, // loading
        isOperation: false,
        isSelection: true,
        isBorder: true,
        selectAll: (selection) => {
          if (selection.length > 0) {
            let selectionList = []
            selectionList.push(...selection)
            let list = []
            selectionList.map((item) => {
              if (list.length === 0 || !list.some((s) => s.id === item.id)) {
                list.push(item)
              }
            })

            let arr = [...this.selectPageList, ...list]

            let newArr = []
            arr.forEach((item) => {
              if (newArr.length === 0 || !newArr.some((s) => s.id === item.id)) {
                newArr.push(item)
              }
            })
            let groupByAccountId = _.groupBy(newArr, 'taskAccountId')
            let pageList = []
            for (const key in groupByAccountId) {
              if (Object.hasOwnProperty.call(groupByAccountId, key)) {
                const element = groupByAccountId[key]
                pageList.push(element[element.length - 1])
              }
            }
            this.selectPageList = pageList
          } else {
            // 当点击全部取消
            this.selectPageList = this.selectPageList.filter((f) => f.taskAccountId !== this.taskAccountId)
          }
        },
        select: (selection, row) => {
          let selectionList = []
          selectionList.push(...selection)
          let list = []
          selectionList.map((item) => {
            if (list.length === 0 || !list.some((s) => s.id === item.id)) {
              list.push(item)
            }
          })

          let arr = [...this.selectPageList, ...list]

          let newArr = []
          arr.forEach((item) => {
            if (newArr.length === 0 || !newArr.some((s) => s.id === item.id)) {
              newArr.push(item)
            }
          })

          if (!selection.some((s) => s.id === row.id)) {
            newArr = newArr.filter((f) => f.id !== row.id)
          }

          let groupByAccountId = _.groupBy(newArr, 'taskAccountId')
          let pageList = []
          for (const key in groupByAccountId) {
            if (Object.hasOwnProperty.call(groupByAccountId, key)) {
              const element = groupByAccountId[key]
              pageList.push(element[element.length - 1])
            }
          }
          this.selectPageList = pageList
        },
        pageData: {
          total: 0, // 总条数
          pageSize: 10,
          layout: 'total, prev, pager, next'
        }
      }
    }
  },

  watch: {
    formData: {
      handler(value) {
        this.selectPageList = value.list
      },
      deep: true,
      immediate: true
    },
    visible: {
      handler(value) {
        if (value) {
          this.selectPageList = this.formData.list
        }
      }
    }
  },

  methods: {
    /**
     * @description: 同步数据源
     */
    async sourceSync(_id) {
      if (!_id) return this.$message.error('请先选择账户')
      this.dataSource.loading = true
      setTimeout(() => {
        this.dataSource.loading = false
      }, 200)
      this.$message.success('刷新成功!')
    },

    /**
     * @desc 获取当前选中账户下的数据源
     */
    async getSourceFun({ item, searchName }) {
      this.taskAccountId = item._id
      this.dataSource.loading = true
      const { code, data } = await getTrack({ taskAccountId: item._id, name: searchName })
      if (code === 200) {
        data.forEach((ele) => {
          ele.key = `${ele.user_action_set_id}+${item._id}`
          ele.id = `${ele.user_action_set_id}+${item._id}`
          ele.taskAccountId = item._id
          ele.accountName = item._name
          ele.show_name = `${ele.name}_(${item._name})`
        })
        this.dataSource.pageData.total = data.total
        this.dataSource.loading = false
        this.dataSource.data = data
      }
    },

    /**
     * @desc 删除数据后提交
     */
    deleteSelectSourceData(id) {
      let params = {
        saveTrackItems: []
      }
      this.selectPageList.map((item) => {
        if (item.id === id) {
          params.saveTrackItems.push({
            taskAccountId: item.taskAccountId,
            trackingSourceid: '',
            trackingSourceidName: ''
          })
        }
      })
      updateSource(params).then((res) => {
        if (res.code === 200) {
          this.$message.success(' 删除成功')
          this.$emit('byTaskIdGetAllFun')
        }
      })
    },

    /**
     * @desc 获取选中的数据后提交
     */
    getSelectSourceData() {
      if (!this.selectPageList.length) {
        return this.$message.error('请选择数据源')
      }
      let params = {
        saveTrackItems: []
      }
      this.selectPageList.map((item) => {
        params.saveTrackItems.push({
          taskAccountId: item.taskAccountId,
          trackingSourceid: JSON.stringify([item.user_action_set_id]),
          trackingSourceidName: item.name
        })
      })
      if (params.saveTrackItems.length && this.accountAdv.length === params.saveTrackItems.length) {
        updateSource(params).then((res) => {
          if (res.code === 200) {
            this.$emit('byTaskIdGetAllFun')
            this.$message.success('数据源添加成功')
            this.sourceClose()
          }
        })
      } else {
        return this.$message.error(`有${this.accountAdv.length - params.saveTrackItems.length}个账户未选择数据源!`)
      }
      this.selectPageList = []
    },

    setSelectPageList(data) {
      this.selectPageList = data
    }
  }
}
</script>

<style lang="scss">
.handleTree-icon :hover {
  color: #409eff;
}

.source {
  .custom-tree-node {
    // flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    width: 100px !important;
    .label {
      width: 300px !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
</style>
