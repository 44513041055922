<!--
 * @Description: 工作区 -> 图文复合组件
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2022-03-11 14:36:33
-->

<template>
  <section>
    <base-box name="shelfnew">
      <template slot="body">
        <div>
          <p class="w-100">类型：</p>
          <div class="ml-20" style="display: flex">
            <el-radio v-model="detail.type" label="104">一行一个</el-radio>
            <el-radio v-model="detail.type" label="103" disabled>一行两个</el-radio>
          </div>
        </div>
        <el-divider></el-divider>
        <OnePerLine v-if="detail.type === '104'" :detail="detail" />
        <TwoPerLine v-if="detail.type === '103'" :detail="detail" />
      </template>
    </base-box>
  </section>
</template>

<script>
import OnePerLine from './onePerLine.vue'
import TwoPerLine from './twoPerLine.vue'
import BaseBox from '../base.vue'
export default {
  components: {
    OnePerLine,
    TwoPerLine,
    BaseBox
  },
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.button {
  border-radius: 20px;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
}
</style>
