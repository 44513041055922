/*
 * @Description: Do not edit
 * @Date: 2021-10-18 11:53:23
 * @LastEditTime: 2024-03-13 14:39:53
 */
export let widgetGalleryGroup = [
  {
    title: '基础组件',
    widgets: [
      {
        name: '图片',
        type: 'img',
        icon: 'el-icon-picture-outline'
      },
      {
        name: '文本',
        type: 'text',
        icon: 'el-icon-tickets'
      }
    ]
  },
  {
    title: '转化按钮',
    widgets: [
      {
        name: '基础按钮',
        type: 'base',
        icon: 'el-icon-switch-button'
      }
    ]
  }
]
