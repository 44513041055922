<template>
  <el-dialog class="table-template" title="回传日志" :visible.sync="visible" :append-to-body="true"
             width="1150px" @close="visible = false"
  >
    <el-table :data="logList" class="list-table">
      <el-table-column property="id" label="序号" align="center" width="100"></el-table-column>
      <el-table-column property="return_time" label="回传时间" align="center" width="170"></el-table-column>
      <el-table-column property="action_type" label="转化目标" align="center" width="150">
        <template slot-scope="scope">
          <span v-if="scope.row.action_type === 'ADD_TO_CART'">添加购物车</span>
          <span v-else-if="scope.row.action_type === 'PURCHASE'">付费</span>
          <span v-else-if="scope.row.action_type === 'COMPLETE_ORDER'">下单</span>
        </template>
      </el-table-column>
      <el-table-column label="数据源信息" align="center" width="250">
        <template slot-scope="scope">
          <p>APPID: {{ scope.row.data_appid }}</p>
          <p>ID: {{ scope.row.data_id }}</p>
        </template>
      </el-table-column>
      <el-table-column label="小程序信息" align="center" width="250">
        <template slot-scope="scope">
          <p>APPID: {{ scope.row.mini_appid }}</p>
        </template>
      </el-table-column>
      <el-table-column property="return_status" label="状态" align="center" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.return_status === 1" style="color: #67C23A">成功</span>
          <span v-else style="color: #F56C6C">失败</span>
        </template>
      </el-table-column>
      <el-table-column property="return_user" label="操作人" align="center" width="150"></el-table-column>
    </el-table>
    <el-pagination background style="text-align:right; margin-top:10px" layout="prev, pager, next" :total="total" :current-page="pageNum" @current-change="getCallBackLog"> </el-pagination>
  </el-dialog>
</template>

<script>
import { callbackLog } from '@/network/api/passBack'

export default {
  name: 'TencentVNovelsCallbackDialog',
  data() {
    return {
      record: null,
      visible: false,
      logList: [],
      total: 0,
      pageNum: 1
    }
  },
  methods: {
    getCallBackLog(pageNum = 1) {
      this.logList = []
      this.total = 0
      let params = {
        pkUnique: this.record.id,
        pageNum,
        pageSize: 10
      }
      callbackLog(params).then((res) => {
        if (res.code === 200) {
          this.logList = res.data.data
          this.total = res.data.total
          this.pageNum = pageNum
        }
      })
    },
    showDialog(row) {
      this.record = row
      this.visible = true
      this.getCallBackLog()
    }
  }
}
</script>

<style scoped>

</style>
