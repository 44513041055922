<template>
  <div>
    <!-- 列表查询 -->
    <el-form
      ref="search"
      :inline="true"
      :model="search"
      class="search-form search-form-4"
    >
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item
            label="产品名称"
            prop="toolProductName"
          >
            <el-input
              v-model="search.toolProductName"
              placeholder="请输入产品名称"
              style="width: 100%"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item
            label="平台"
            prop="toolProductPlatform"
          >
            <el-select
              v-model="search.toolProductPlatform"
              style="width: 100%"
            >
              <el-option
                label="全部"
                :value="1"
              ></el-option>

              <el-option
                v-for="item in platformList"
                :key="item.dictValue"
                :label="item.dictLabel"
                :value="item.dictValue"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item
            label="类型"
            prop="type"
          >
            <el-select
              v-model="search.type"
              style="width: 100%"
            >
              <el-option
                label="全部"
                :value="1"
              ></el-option>

              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item
            label="发起时间"
            prop="createTime"
          >
            <el-date-picker
              v-model="search.createTime"
              style="width: 100%"
              type="datetimerange"
              prange-separator="至"
              start-placeholder="开始时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              end-placeholder="结束时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item
            label="筛选状态"
            prop="toolProductFilterStatus"
          >
            <el-select
              v-model="search.toolProductFilterStatus"
              style="width: 100%"
            >
              <el-option
                label="全部"
                :value="1"
              ></el-option>

              <el-option
                v-for="item in filterStatusList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item>
            <el-button
              type="primary"
              round
              @click="onSubmit"
            >查 询</el-button>
            <el-button
              type="primary"
              round
              @click="resetForm('ruleForm')"
            >重 置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div>
      <el-table
        v-loading="list.loading"
        :data="list.data"
        stripe
        class="list-table"
        :class="{ 'no-data': !list.data || !list.data.length }"
        style="width: 100%"
      >
        <el-table-column
          type="index"
          label="序号"
          width="50"
          align="center"
          fixed="left"
        ></el-table-column>

        <el-table-column
          prop="toolProductName"
          label="名称"
          align="center"
          fixed="left"
        ></el-table-column>

        <el-table-column
          prop="toolProductSource"
          label="来源"
          align="center"
        ></el-table-column>

        <el-table-column
          label="平台"
          align="center"
        >
          <template slot-scope="{ row }">
            {{ platformList.find(item => item.dictValue === row.toolProductPlatform).dictLabel }}
          </template>
        </el-table-column>

        <el-table-column
          label="类型"
          align="center"
        >
          <template slot-scope="{ row }">
            {{ row.type | filterToolType }}
          </template>
        </el-table-column>

        <el-table-column
          label="连载状态"
          align="center"
        >
          <template slot-scope="{ row }">
            {{ row.serializeStatus === '1' ? '连载' : '完结' }}
          </template>
        </el-table-column>

        <el-table-column
          prop="num"
          label="数量"
          align="center"
        ></el-table-column>

        <el-table-column
          label="主角名"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            {{ row.lead || '-' }}
          </template>
        </el-table-column>

        <el-table-column
          prop="createTime"
          label="收录时间"
          align="center"
          width="180"
        ></el-table-column>

        <el-table-column
          label="评价"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-button
              type="text"
              @click="handleProductEvaluation(row.toolProductId)"
            >{{
              row.toolProductCommentLists?.length || 0
            }}条</el-button>
          </template>
        </el-table-column>

        <el-table-column
          label="付费卡点"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-button
              type="text"
              @click="handlePayCardPoint(row.toolProductId)"
            >{{
              row.payCardPointListShow || '添加'
            }}</el-button>
          </template>
        </el-table-column>

        <el-table-column
          prop="filterPersonName"
          label="发起人"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="filterStartTime"
          label="发起时间"
          align="center"
          width="180"
        ></el-table-column>

        <el-table-column
          label="筛选状态"
          align="center"
        >
          <template slot-scope="{ row }">
            {{ row.toolProductFilterStatus | filterToolProductFilterStatus }}
          </template>
        </el-table-column>

        <el-table-column
          label="筛选情况"
          align="center"
        >
          <template slot-scope="{ row }">
            {{ row.filterInfo || '暂无' }}
          </template>
        </el-table-column>

        <el-table-column
          prop=""
          label="操作"
          fixed="right"
        >
          <template slot-scope="{ row }">
            <el-button
              v-if="row.toolProductFilterStatus === '3' && row.createUserId === userInfo.userid && row.filterInfo === '暂无'"
              type="text"
              @click="handleUnfiltering(row.toolProductId)"
            >
              <el-tooltip
                content="取消筛选"
                placement="top"
              >
                <i
                  class="el-icon-document-delete"
                  style="font-size: 16px"
                ></i>
              </el-tooltip>
            </el-button>

            <el-button
              v-if="row.toolProductFilterStatus === '3' && row.createUserId !== userInfo.userid && !row.filtered"
              type="text"
              @click="handleVote(row.toolProductId)"
            >
              <el-tooltip
                content="投票"
                placement="top"
              >
                <i
                  class="el-icon-thumb"
                  style="font-size: 16px"
                ></i>
              </el-tooltip>
            </el-button>

            <el-button
              v-if="row.toolProductFilterStatus === '3' && row.createUserId !== userInfo.userid && row.filtered"
              type="text"
              class="red"
              @click="handleCancel(row.toolProductId)"
            >
              <el-tooltip
                content="撤销"
                placement="top"
              >
                <i
                  class="el-icon-delete"
                  style="font-size: 16px"
                ></i>
              </el-tooltip>
            </el-button>

            <span v-if="row.toolProductFilterStatus !== '3'">-</span>
          </template>
        </el-table-column>
      </el-table>

      <div class="page">
        <paginations
          :total="list.total"
          :page.sync="list.pageNum"
          :page-sizes="[5, 10, 15, 20]"
          :limit.sync="list.pageSize"
          @pagination="getPage"
        />
      </div>
    </div>

    <!-- 产品评价 -->
    <product-evaluation-dialog
      ref="productEvaluation"
      @confirm="getPage"
    />

    <!-- 付费卡点 -->
    <pay-card-point-dialog
      ref="payCardPoint"
      @confirm="getPage"
    />

    <!-- 产品筛选投票 -->
    <product-screen-vote-dialog
      ref="productScreenVote"
      @confirm="getPage"
    />
  </div>
</template>

<script>
import ProductEvaluationDialog from './components/ProductEvaluationDialog'
import PayCardPointDialog from './components/PayCardPointDialog'
import ProductScreenVoteDialog from './components/ProductScreenVoteDialog'

import { unfilteringProductScreen, revokeProductScreen, productScreeningList } from '@/network/api/toolManagement/api-product-test'

import { mapGetters } from 'vuex'

export default {
  components: {
    'product-evaluation-dialog': ProductEvaluationDialog,
    'pay-card-point-dialog': PayCardPointDialog,
    'product-screen-vote-dialog': ProductScreenVoteDialog
  },
  filters: {
    filterToolType (value) {
      const types = { '1': '全部', '2': '男频', '3': '女频', '4': '男女通用' }
      return types[value] || ''
    },
    filterToolProductFilterStatus (value) {
      const types = { '3': '筛选中', '4': '未通过', '5': '已通过', '6': '已取消' }
      return types[value] || ''
    }
  },
  data () {
    return {
      search: {
        toolProductName: '',
        toolProductPlatform: 1,
        type: 1,
        createTime: [],
        toolProductFilterStatus: 3
      },

      list: {
        loading: true,
        data: [],
        pageSize: 10,
        pageNum: 1,
        total: 0
      },

      platformList: [],
      typeList: [{ id: 2, name: '男频' }, { id: 3, name: '女频' }, { id: 4, name: '男女通用' }],
      filterStatusList: [{ id: 3, name: '筛选中' }, { id: 4, name: '未通过' }, { id: 5, name: '已通过' }, { id: 6, name: '已取消' }]
    }
  },

  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },

  mounted () {
    this.getDicts('return_platform_type').then((response) => {
      this.platformList = response.data
      this.getPage()
    })
  },

  methods: {
    // 列表
    getPage () {
      this.list.loading = true
      let search = {
        ...this.search,
        pageSize: this.list.pageSize,
        pageNum: this.list.pageNum
      }
      productScreeningList(search).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          this.list.data = records
          this.list.total = total
          this.list.loading = false
        }
      }).finally(e => {
        this.list.loading = false
      })
    },

    // 搜索
    onSubmit () {
      if (this.list.pageNum === 1) {
        this.getPage()
      } else {
        this.list.pageNum = 1
      }
    },

    // 重置
    resetForm () {
      this.$refs['search'].resetFields()
      this.getPage()
    },

    // 更新数据
    handleRefresh () {
      this.getElementType()
    },

    // 点击产品评价
    handleProductEvaluation (id) {
      this.$refs.productEvaluation.showModal(id)
    },

    // 点击付费卡点
    handlePayCardPoint (id) {
      this.$refs.payCardPoint.showModal(id)
    },

    // 投票
    handleVote (id) {
      this.$refs.productScreenVote.showModal(id)
    },

    // 取消筛选
    handleUnfiltering (id) {
      this.$confirm('确定要取消该筛选吗?', '取消筛选', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { code } = await unfilteringProductScreen({ toolProductId: id })
        if (code === 200) {
          this.getPage()
          this.$message.success('取消筛选成功!')
        }
      }).catch(() => {
      })
    },

    // 撤销
    handleCancel (id) {
      this.$confirm('确定要撤销投票吗?', '撤销投票', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { code } = await revokeProductScreen({ toolProductId: id })
        if (code === 200) {
          this.getPage()
          this.$message.success('撤销投票成功!')
        }
      }).catch(() => {
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.element-img {
  width: 60px;
  height: 60px;
  text-align: center;
  margin: auto;
  padding-top: 12px;

  video {
    width: 100%;
    height: 100%;
  }
}

.element-pop-img {
  max-width: 260px;

  video {
    width: 100%;
    height: 100%;
  }
}

.red {
  color: #ff0000;
}
</style>
