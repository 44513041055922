<!--
 * @Description: 跳转小程序编辑区
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2022-05-25 09:27:41
-->

<template>
  <section class="section">
    <base-box name="applet">
      <template slot="body">
        <div class="setting">
          <p class="w-100">小程序链接：</p>
          <div>
            <el-button type="primary" @click="dialogVisible = true">添加跳转链接</el-button>
            <div class="url-list tip">
              <li v-for="(item, index) in detail.urlList" :key="index">
                <div class="display: block">
                  <span class="display: flex">
                    跳转链接：
                    <p style="width: 200px">
                      <el-tooltip :content="item.url">
                        <p class="url">{{ item.url }}</p>
                      </el-tooltip>
                    </p>
                  </span>
                  <span>访问上限：{{ item.uv }}</span>
                </div>
                <span class="ml-10" @click="deleteUrl(index)">
                  <i class="el-icon-error" />
                </span>
              </li>
            </div>
          </div>
        </div>

        <el-dialog title="提示" :visible.sync="dialogVisible" width="500px" :before-close="handleClose" :modal-append-to-body="false">
          <el-form :model="formData" :rules="rules" label-width="100px">
            <el-form-item label="跳转链接" prop="url">
              <el-input v-model="formData.url" placeholder="请输入跳转链接" style="width: 300px" />
            </el-form-item>
            <el-form-item label="访问上限(UV)" prop="uv">
              <el-input-number v-model="formData.uv" />
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submit">确 定</el-button>
          </span>
        </el-dialog>

        <div>
          <p class="w-100">按钮文案：</p>
          <div style="display: flex">
            <el-input :value="detail.btnTitle" style="width: 200px" maxlength="10" show-word-limit type="text" size="small" @input="btnTitle" />
            <el-radio-group v-model="detail.btnFontType" size="small" class="ml-20">
              <el-radio-button label="normal">常规</el-radio-button>
              <el-radio-button label="bold">加粗</el-radio-button>
            </el-radio-group>
          </div>
        </div>

        <div>
          <p class="w-100">按钮字体色：</p>
          <div>
            <el-dropdown>
              <div class="ldy-bg-color" :style="`background:${detail.btnFontColor}`"></div>
              <el-dropdown-menu slot="dropdown">
                <twitter-color :colors="detail.btnFontColor" style="margin-top: 10px" @change="(value) => (detail.btnFontColor = value)" />
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <div>
          <p class="w-100">按钮边框色：</p>
          <div>
            <el-dropdown>
              <div class="ldy-bg-color" :style="`background:${detail.btnBorderColorTheme}`"></div>
              <el-dropdown-menu slot="dropdown">
                <twitter-color :colors="detail.btnBorderColorTheme" style="margin-top: 10px" @change="(value) => (detail.btnBorderColorTheme = value)" />
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <div>
          <p class="w-100">按钮填充色：</p>
          <div>
            <el-dropdown>
              <div class="ldy-bg-color" :style="`background:${detail.btnBgColorTheme}`"></div>
              <el-dropdown-menu slot="dropdown">
                <twitter-color :colors="detail.btnBgColorTheme" style="margin-top: 10px" @change="(value) => (detail.btnBgColorTheme = value)" />
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <div>
          <p class="w-100">上边距：</p>
          <div>
            <el-slider v-model="detail.paddingTop" show-input> </el-slider>
          </div>
        </div>

        <div>
          <p class="w-100">下边距：</p>
          <div>
            <el-slider v-model="detail.paddingBottom" show-input> </el-slider>
          </div>
        </div>
      </template>
    </base-box>
  </section>
</template>

<script>
import BaseBox from './base.vue'
import Twitter from '@/components/twitterColor.vue'
export default {
  components: {
    'base-box': BaseBox,
    'twitter-color': Twitter
  },
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dialogVisible: false,
      formData: {
        url: '',
        uv: 0
      },
      rules: {
        url: [{ required: true, trigger: 'change' }]
      }
    }
  },
  methods: {
    btnTitle(value) {
      this.detail.btnTitle = this.nbsp2Space(value)
    },

    nbsp2Space(str) {
      var arrEntities = { lt: '<', gt: '>', nbsp: ' ', amp: '&', quot: '"' }
      return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, function(all, t) {
        return arrEntities[t]
      })
    },
    /**
     * @description: 打开资源库
     */
    openMaterialBox() {
      this.$emit('openMaterialBox')
    },

    /**
     * @description: 添加跳转链接
     */
    submit() {
      if (this.formData.url) {
        this.detail.urlList.push({ ...this.formData })
        this.dialogVisible = false
        this.formData = {
          url: '',
          uv: 0
        }
      } else {
        this.$message.error('跳转链接有误')
      }
    },

    deleteUrl(index) {
      this.detail.urlList.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.update {
  width: 130px !important;
  height: 130px;
  line-height: 130px;
  text-align: center;
  border: 1px solid #edecf2;
  background-color: #fbfafd;
  border-radius: 3px;
  cursor: pointer;
  font-size: 30px;
  color: #7d7d7d;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .avatar {
    width: 130px;
  }
}

.image-updata {
  display: flex !important;
  align-items: flex-start !important;
}

.ldy-bg-color {
  width: 28px;
  height: 28px;
  border: 5px solid #bfc2c7;
  border-radius: 4px;
}

.setting {
  display: flex;
  align-items: flex-start !important;
}

.section {
  .w-100 {
    font-size: 12px;
    margin-right: 10px;
  }
}
.url-list {
  li {
    display: flex;
    div {
      display: flex;
    }
    .url {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
</style>
