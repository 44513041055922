<template>
  <!--定向包弹框-->
  <el-dialog class="table-template" :title="'定向包'" :visible.sync="show" :append-to-body="true" width="800px" top="20vh"
    @close="onCloseDialog">
    <div class="search-form-container">
      <el-form class="search-form">
        <el-row :gutter="15">
          <el-col :span="6">
            <el-input v-model="queryParams.name" placeholder="请输入定向包名称" clearable style="width: 100%" />
          </el-col>
          <!--按钮-->
          <el-col :span="8">
            <!--搜索-->
            <el-button type="primary" style="width: 70px;" @click="onClickSearch">搜索</el-button>
            <!--清空-->
            <el-button type="plain" style="width: 70px;" @click="onClickReset">清空</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div style="display: flex;justify-content: space-between">
      <span style="margin-left: 20px;font-size: 12px;">已选：{{ selectedData.length }}/50</span>
      <div>
        <el-button round type="text" icon="el-icon-refresh" size="mini" @click="getTemplateList">刷新</el-button>
        <el-button round type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
      </div>
    </div>
    <div class="page-container">
      <el-table ref="multipleTable" v-loading="loading" :row-key="row => {return row.id}" :data="tempList"
        @select="handleCurrentChange" @select-all="handleAllChange">
        <el-table-column type="selection" :reserve-selection="true"></el-table-column>
        <el-table-column label="定向模板名称" align="center" prop="name" :show-overflow-tooltip="true" />
        <el-table-column label="关联计划数" align="center" prop="campaignNum" />
        <el-table-column label="操作" align="center" width="260" class-name="small-padding fixed-width">
          <template slot-scope="{ row }">
            <el-button round size="mini" type="text" @click="handleUpdate(row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <c-pagination :page-size.sync="queryParams.pageSize" :page-no.sync="queryParams.pageNum" :pager-count="5"
        :total="total" tools="total, prev, pager, next" :get-data-fun="getTemplateList" />
    </div>
    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">取 消</el-button>
      <el-button class="btn" type="primary" :loading="isLoading" :disabled="selectedData.length === 0"
        @click="clickConfirm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { templateList } from '@/network/api/massiveAd/massiveAd'
import { PageCommonComponent } from '@/common/mixin'

import { saveConfigAudience } from '@/network/api/api-huge-batch'
export default {
  name: 'OrientationPackageDialog',
  components: {},
  filters: {},
  mixins: [PageCommonComponent],
  props: {
    baseId: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      show: false,
      loading: false,
      isLoading: false,
      tempList: [], // 定向包列表
      selectedData: [], // 已选择定向包
      audienceTotal: 0, // 定向包选择上限
      queryParams: { // 查询参数
        pageNum: 1,
        pageSize: 10,
        name: null,
        projectId: null
      },
      total: 0
    }
  },
  computed: {},
  watch: {},
  created() {
    window.addEventListener('message', this.receiveMessage, false)
  },
  mounted() { },
  methods: {
    /* --------------------------- Actions --------------------------- */
    showModal(projectId, data, total) {
      this.queryParams.projectId = projectId
      let arr = data.map(v => {
        return {
          ...v,
          id: v.audiencePackageId
        }
      })
      this.selectedData = arr
      this.audienceTotal = total
      this.show = true
      this.getTemplateList()
    },
    // 点击清空
    onClickReset() {
      this.queryParams.name = null
      this.queryParams.pageNum = 1
      this.onClickSearch()
    },
    // 点击搜索
    onClickSearch() {
      if (this.pageNum === 1) {
        this.getTemplateList()
      } else {
        this.pageNum = 1
      }
    },
    // 点击确定按钮
    clickConfirm() {
      if (!this.selectedData.length) {
        return
      }
      if (this.selectedData.length > this.audienceTotal) {
        this.$message.warning('最多添加50个定向包')
        return
      }
      let packages = this.selectedData.map(v => {
        return {
          audiencePackageId: v.id,
          baseId: this.baseId,
          name: v.name
        }
      })
      this.isLoading = true
      saveConfigAudience(packages).then(res => {
        if (res.code === 200) {
          this.$emit('confirm', res.data)
          this.closeModal()
          this.isLoading = false
        }
      }).catch(e => {
        this.isLoading = false
      })
    },
    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      // 重置表单
      this.tempList = []
      this.selectedData = []
      this.queryParams = {
        pageNum: 1,
        pageSize: 10,
        name: null,
        projectId: null
      }
      this.$refs.multipleTable.clearSelection()
    },
    // 关闭弹框
    closeModal() {
      this.show = false
    },

    // 监听添加返回事件 刷新列表
    receiveMessage(event) {
      if (event.origin === window.location.origin && event.data === 'refresh') {
        this.getTemplateList()
      }
    },

    // 新增按钮操作
    handleAdd() {
      window.open(`${window.location.origin}/addDirectionTemp?page=juliang`)
    },
    // 编辑
    handleUpdate(row) {
      window.open(`${window.location.origin}/editDirectionTemp/${row.id}?page=juliang`)
    },

    // 表格全选
    handleAllChange(selection) {
      if (selection.length) {
        let ids = this.selectedData.map(v => v.id)
        selection.forEach(item => {
          if (!ids.includes(item.id)) {
            this.selectedData.push(item)
          }
        })
      } else {
        this.tempList.forEach(item => {
          this.selectedData.forEach((item2, index) => {
            if (item.id === item2.id) {
              this.selectedData.splice(index, 1)
            }
          })
        })
      }
    },
    // table 选择 cell 时调用
    handleCurrentChange(selection, row) {
      let ids = this.selectedData.map(v => v.id)
      if (!ids.includes(row.id)) {
        this.selectedData.push(row)
      } else {
        let index = this.selectedData.findIndex(v => v.id === row.id)
        this.selectedData.splice(index, 1)
      }
    },
    /* --------------------------- Private --------------------------- */
    // 查询模板列表
    getTemplateList() {
      this.loading = true
      templateList(this.queryParams).then((res) => {
        if (res.code === 200) {
          let { records, total } = res.data
          this.tempList = records
          this.total = total
          this.loading = false

          setTimeout(() => {
            if (this.selectedData.length) {
              this.echo(this.tempList)
            }
          }, 10)
        }
        this.loading = false
      })
        .catch((e) => {
          this.loading = false
        })
    },
    // 表格数据回显
    echo(data) {
      let rows = []
      data.forEach(item => {
        this.selectedData.forEach(item2 => {
          if (item.id === item2.id) {
            rows.push(item)
          }
        })
      })
      this.toggleSelection(rows)
    },
    // 勾选表格选中
    toggleSelection(rows) {
      if (rows.length) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row, true)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/assets/style/variables';
@import '~@/assets/style/mixin';

.table-template {
  .copy-type {
    margin-bottom: 10px;
    width: 150px;
    box-sizing: border-box;
    display: flex;
    color: #909399;
    font-size: 16px;
    font-weight: 700;
    border-bottom: 1px solid #909399;

    .copy-type-item {
      margin-right: 15px;
      padding: 5px;
      cursor: pointer;
    }

    .isSelect {
      color: rgb(52, 92, 224);
      border-bottom: 2px solid rgb(52, 92, 224);
    }
  }

  .search-form-container {
    .el-form {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .flex-btn {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .left {
      margin-right: 10px;
    }
  }

  .page-container {
    margin-bottom: 50px;

    .btn-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;

      .operation-btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .table-info {
          padding: 10px 0;
          color: #333a3f;
          font-size: 16px;
          margin-left: 12px;

          .number {
            color: #2842c8;
            font-size: 24px;
            margin: 0 5px;
          }
        }

        .el-button--small {
          padding: 6px 13px;
        }

        .btn-content {
          @include flex-center;
          height: 18px;

          .icon-container {
            @include flex-center;
            width: 18px;
            height: 18px;
            border-radius: 9px;
            // background: #1667FF;
            margin-right: 6px;

            img {
              width: 12px;
              height: 12px;
            }
          }
        }
      }

      .function-btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .function-btn {
          @include flex-center;
          width: 26px;
          height: 20px;
          background: white;
          transition: 0.2s;
          margin-left: 10px;
          position: relative;
          cursor: pointer;

          .table-columns-container {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 10;
            width: 150px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 2px 5px 0px rgba(229, 228, 234, 1);
            border-radius: 4px;
            padding-left: 10px;
            overflow: hidden;
            display: none;

            .table-columns-item {
              height: 30px;
              line-height: 30px;
            }
          }

          &:hover {
            background: #f2f2f2;

            .table-columns-container {
              display: block;
            }
          }

          img {
            width: 18px;
            height: 18px;
          }
        }
      }
    }

    .table-select-tip {
      height: 38px;
      padding-left: 17px;
      display: flex;
      align-items: center;
      color: #909399;
      font-size: 14px;
      background: #dbe9ff;
      border: 1px solid #4788ff;
      border-radius: 4px;

      img {
        width: 16px;
        height: 16px;
      }
    }

    .table-link {
      color: #606266;
      transition: 0.2s;
      text-decoration: none;

      &:hover {
        color: $themeColor;
        text-decoration: underline;
      }
    }

    .poster-container {
      @include flex-center;
      width: 36px;
      height: 36px;
      overflow: hidden;

      .poster {
        width: 36px;
        min-height: 36px;
      }
    }

    .opt-container {
      display: flex;
      justify-content: space-around;

      .opt-text {
        font-size: 14px;
      }
    }

    .cell-btn {
      font-size: 0;

      img {
        width: 18px;
        height: 18px;
      }

      .cell-btn-hover {
        display: none;
      }

      &:hover {
        .cell-btn-icon {
          display: none;
        }

        .cell-btn-hover {
          display: block;
        }
      }
    }

    .pagination-container {
      height: 64px;
      padding-right: 50px;
      box-sizing: border-box;
    }
  }
}

::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}
</style>
