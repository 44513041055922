<!--
 * @Description: 新建回传
 * @Date: 2021-10-21 09:12:51
 * @LastEditTime: 2024-09-29 15:11:20
-->
<template>
  <div class="rule">
    <el-form ref="submitParams" :model="ruleData" label-width="100px">
      <box name="规则名称">
        <el-form-item label="规则名称" prop="rule_name" :rules="[{ required: true, message: '请输入规则名称!' }]">
          <el-input v-model="ruleData.rule_name" placeholder="请输入规则名称" style="width:400px" />
        </el-form-item>
        <el-form-item v-if="ruleData.platform === 'gdt'" label="规则类型" prop="rule_type"
          :rules="[{ required: true, message: '请选择规则类型' }]">
          <el-checkbox-group v-model="ruleData.rule_type" @change="changeRuleType">
            <el-checkbox key="订单回传" :label="1">订单回传</el-checkbox>
            <el-checkbox key="企微加粉回传" :label="2">企微加粉回传</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </box>
      <el-collapse v-model="collapseVal">
        <el-collapse-item v-if="ruleData.rule_type.includes(1)" title="订单回传配置" :name="1">
          <box name="模式选择">
            <el-row>
              <el-col :span="10">
                <el-form-item label="回传类型" prop="type" :rules="[{ required: true }]">
                  <el-select v-model="ruleData.type" style="width:400px">
                    <el-option label="固定模式" value="FIXED" />
                    <el-option label="自定义模式" value="FREE" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="适用范围" prop="rule_range" :rules="[{ required: true }]">
                  <el-select v-model="ruleData.rule_range" style="width:400px" @change="ruleRangeChange">
                    <el-option label="公众号" value="PUBLIC" />
                    <el-option label="投放广告" value="AD" />
                    <el-option label="快应用" value="QUICKAPP" />
                    <el-option label="短剧" value="VNOVEL" />
                    <el-option v-if="ruleData.platform === 'gdt'" label="小游戏" value="GAME"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </box>

          <box name="回传条件设置">
            <Fixed v-if="ruleData.type === 'FIXED'" :rule-data="ruleData" />
            <Customize v-else :rule-data="ruleData" />
          </box>

          <box name="关联账号">
            <div v-if="ruleData.rule_range === 'PUBLIC'">
              <div v-if="isShowAccounts">
                <div v-if="ruleData.platform === 'gdt'">
                  <el-form-item label="回传类型" prop="return_action_type" :rules="[{ required: true }]">
                    <el-select v-model="ruleData.return_action_type" style="width:400px" @change="changeActionType">
                      <el-option label="数据源" :value="1" />
                    </el-select>
                  </el-form-item>
                  <div style="display: flex; flex-direction: row">
                    <el-form-item prop="wx_appid" style="width: 300px" label="小说"
                      :rules="{ required: true, message: '请选择小说', trigger: 'change' }">
                      <el-select v-model="ruleData.wx_appid">
                        <el-option label="阳光小说" value="P1001"></el-option>
                        <el-option label="番茄小说" value="P1016"></el-option>
                        <el-option label="阅文小说" value="P1003"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item
                      :rules="{ required: ruleData.platform === 'gdt' && ruleData.rule_range === 'PUBLIC', message: '请输入数据源ID', trigger: 'blur' }"
                      label="数据源ID" prop="data_id" style="width: 300px;">
                      <el-input v-model="ruleData.data_id" placeholder="输入数据源ID"></el-input>
                    </el-form-item>
                    <el-form-item :rules="{ required: true, message: '请输入APPID', trigger: 'blur' }" label="APPID"
                      prop="data_appid" style="width: 300px">
                      <el-input v-model="ruleData.data_appid" placeholder="创建数据源公众号/小程序APPID"
                        @input="inputChangeAppId"></el-input>
                    </el-form-item>
                  </div>
                  <div style="display: flex;justify-content: flex-start;margin-left: 90px;">
                    <AssociatedAccountV2 ref="account" :accounts="default_accounts" />
                  </div>
                </div>
                <AssociatedAccount v-else ref="account" :accounts="default_accounts" />
              </div>
            </div>

            <div v-if="ruleData.rule_range === 'AD'" style="padding: 20px; text-align:center">
              <el-form-item prop="plans" :rules="{ required: true, message: '请输入放广告计划Id', trigger: 'change' }">
                <el-input :value="ruleData.plans" type="textarea" style="width: 600px"
                  :autosize="{ minRows: 8, maxRows: 8 }" placeholder="输入投放广告计划，若多个计划请换行" @input="plansInput" />
              </el-form-item>
            </div>

            <div v-if="ruleData.rule_range === 'VNOVEL'" style="padding: 20px; text-align:left">
              <el-form-item label="回传类型" prop="return_action_type" :rules="[{ required: true }]">
                <el-select v-model="ruleData.return_action_type" style="width:400px" @change="changeActionType">
                  <template v-if="ruleData.platform === 'oceanengine'">
                    <el-option label="资产化回传" :value="3" />
                    <el-option label="获客助手回传" :value="4" />
                    <el-option label="IP+UA" :value="5" />
                  </template>
                  <template v-else>
                    <el-option label="数据源" :value="1" />
                    <el-option label="转化归因" :value="2" />
                  </template>
                </el-select>
              </el-form-item>
              <div style="display: flex; flex-direction: row">
                <el-form-item prop="vnovel_type" style="width: 300px" label="短剧"
                  :rules="{ required: true, message: '请选择短剧类型', trigger: 'change' }">
                  <el-select v-model="ruleData.vnovel_type" @change="handleSelectQuickAppType">
                    <!-- 腾讯独有《触摸短剧（V1011）》 -->
                    <el-option v-for="item in vnovelTypeList" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
                <!-- <el-form-item v-if="ruleData.vnovel_type !== 'V1007'" prop="callback_type" style="width: 300px" label="回传类型" :rules="{ required: true, message: '请选择回传类型', trigger: 'change' }">
                  <el-select v-model="ruleData.callback_type" @change="handleSelectQuickAppType">
                    <el-option label="有效获客" :value="1"></el-option>
                    <el-option label="付费" :value="2"></el-option>
                  </el-select>
                </el-form-item> -->
                <el-form-item
                  v-if="(ruleData.platform === 'gdt' || (ruleData.platform === 'oceanengine' && ruleData.return_action_type == 3)) && vnovelApplet.includes(ruleData.vnovel_type)"
                  prop="wx_appid" style="width: 300px" label="小程序"
                  :rules="{ required: true, message: '请选择小程序', trigger: 'change' }">
                  <el-select v-model="ruleData.wx_appid" @change="handleSelectApplet">
                    <el-option v-for="item in appletOptions" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
                <el-form-item v-if="ruleData.platform === 'gdt' && ruleData.return_action_type === 1"
                  :rules="{ required: ruleData.platform === 'gdt' && ruleData.rule_range === 'VNOVEL', message: '请输入数据源ID', trigger: 'blur' }"
                  label="数据源ID" prop="data_id" style="width: 300px">
                  <el-input v-model="ruleData.data_id" placeholder="输入数据源ID" @input="inputChangeId"></el-input>
                </el-form-item>
                <el-form-item v-if="ruleData.platform === 'gdt' && ruleData.return_action_type === 1"
                  :rules="{ required: ruleData.platform === 'gdt' && ruleData.rule_range === 'VNOVEL', message: '请请输入输入推广渠道ID', trigger: 'blur' }"
                  label="APPID" prop="data_appid" style="width: 300px">
                  <el-input v-model="ruleData.data_appid" placeholder="创建数据源公众号/小程序APPID"
                    @input="inputChangeAppId"></el-input>
                </el-form-item>
              </div>
              <el-form-item prop="plans" :rules="{ required: true, message: '请输入输入推广渠道ID', trigger: 'change' }">
                <el-input :value="ruleData.plans" type="textarea" style="width: 600px"
                  :autosize="{ minRows: 8, maxRows: 8 }" placeholder="输入推广渠道ID，若有多个请换行" @input="plansInput" />
              </el-form-item>
            </div>

            <div v-if="ruleData.rule_range === 'QUICKAPP'" style="padding: 20px; text-align:center">
              <el-form-item prop="quick_app_type" style="width: 300px" label="快应用"
                :rules="{ required: true, message: '请选择快应用类型', trigger: 'change' }">
                <el-select v-model="ruleData.quick_app_type" @change="handleSelectQuickAppType">
                  <el-option label="常看" :value="1"></el-option>
                  <el-option label="自有" :value="2"></el-option>
                  <el-option label="阅文陌香" :value="3"></el-option>
                  <el-option label="点众-松鼠阅读" :value="4"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="plansArr" label="推广ID" style="width: 100%"
                :rules="{ required: true, message: '请输入推广渠道Id', trigger: 'change' }">
                <el-select v-model="ruleData.plansArr" style="width: 100%" filterable multiple remote clearable
                  reserve-keyword placeholder="选择快应用，输入推广链接名称" :remote-method="remoteMethod">
                  <el-option v-for="item in promotionIdArr" :key="item.urlId" :label="item.urlName" :value="item.urlId">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>

            <div v-if="ruleData.rule_range === 'GAME'" style="padding: 20px; text-align:left">
              <div style="display: flex; flex-direction: row">
                <el-form-item style="width: 300px" label="游戏平台"
                  :rules="{ required: true, message: '请选择游戏平台', trigger: 'change' }">
                  <el-select v-model="ruleData.vnovel_type">
                    <el-option label="默认平台" value="G1001"></el-option>
                    <el-option label="一包多发" value="G1002"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item style="width: 300px" label="小游戏"
                  :rules="{ required: true, message: '请选择小游戏', trigger: 'change' }">
                  <el-select v-model="ruleData.wx_appid" filterable>
                    <el-option v-for="(item, index) in gameList" :key="index" :label="item.dictLabel"
                      :value="item.dictValue"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :rules="{ required: true, message: '请输入数据源ID', trigger: 'blur' }" label="数据源ID"
                  prop="data_id" style="width: 300px">
                  <el-input v-model="ruleData.data_id" placeholder="请输入数据源ID" @input="inputChangeId"></el-input>
                </el-form-item>
                <el-form-item :rules="{ required: true, message: '请输入APPID', trigger: 'blur' }" label="APPID"
                  prop="data_appid" style="width: 300px">
                  <el-input v-model="ruleData.data_appid" placeholder="创建数据源公众号/小程序APPID"
                    @input="inputChangeAppId"></el-input>
                </el-form-item>
              </div>

              <!-- 游戏平台为一包多发 -->
              <el-form-item v-if="ruleData.vnovel_type == 'G1002'" prop="plans"
                :rules="{ required: true, message: '请输入小游戏APPID', trigger: 'change' }">
                <el-input :value="ruleData.plans" type="textarea" style="width: 600px"
                  :autosize="{ minRows: 8, maxRows: 8 }" placeholder="请输入小游戏APPID，若有多个请换行" @input="plansInput" />
              </el-form-item>
              <el-form-item v-else prop="plans" :rules="{ required: true, message: '请输入推广渠道ID', trigger: 'change' }">
                <el-input :value="ruleData.plans" type="textarea" style="width: 600px"
                  :autosize="{ minRows: 8, maxRows: 8 }" placeholder="请输入推广渠道ID，若有多个请换行" @input="plansInput" />
              </el-form-item>
            </div>
          </box>
        </el-collapse-item>
        <el-collapse-item v-if="ruleData.rule_type.includes(2)" title="企微加粉回传配置" :name="2">
          <box name="回传条件设置">
            <div style="display: flex; " class="box-qw">
              <el-form-item label="回传方式" prop="return_type_qw" :rules="[{ required: true }]">
                <el-select v-model="ruleData.return_type_qw" style="width: 300px" @change="changeReturnTypeQW">
                  <el-option label="全部回传" :value="0" />
                  <el-option label="百分比回传" :value="1" />
                  <el-option label="循环比例回传" :value="2" />
                </el-select>
              </el-form-item>
              <!-- <el-form-item v-if="ruleData.return_type_qw === 1" style="margin-left: 5px" prop="return_rate_qw" :rules="[{ required: ruleData.return_rate_qw > 100, message: '0-100' }]"> -->
              <el-input-number v-if="ruleData.return_type_qw === 1" v-model="ruleData.return_rate_qw"
                style="width: 150px; margin-left: 10px" :min="0" :max="100" :precision="0"
                placeholder="0-100"></el-input-number>
              <!-- </el-form-item> -->
              <div v-if="ruleData.return_type_qw === 1" style="margin-top: 6px; padding: 0 5px">%</div>
              <el-form-item v-if="ruleData.return_type_qw === 2" style="margin-left: 10px; width: 100px"
                prop="customize_cycle_proportion_start_qw"
                :rules="[{ required: ruleData.return_type_qw === 2, message: '填入传几个用户!' }]"
                class="customize_cycle_proportion">
                <el-input v-if="ruleData.return_type_qw === 2" v-model="ruleData.customize_cycle_proportion_start_qw"
                  placeholder="填入传几个用户"></el-input>
              </el-form-item>
              <div v-if="ruleData.return_type_qw === 2" style="margin-top: 6px; padding: 0 10px">:</div>
              <el-form-item v-if="ruleData.return_type_qw === 2" prop="customize_cycle_proportion_end_qw"
                style="width: 100px" :rules="[{ required: ruleData.return_type_qw === 2, message: '填入卡几个用户!' }]"
                class="customize_cycle_proportion">
                <el-input v-if="ruleData.return_type_qw === 2" v-model="ruleData.customize_cycle_proportion_end_qw"
                  placeholder="填入卡几个用户"></el-input>
              </el-form-item>
            </div>
            <el-form-item v-if="ruleData.return_type_qw === 2" label="循环规则" prop="cycle_proportion">
              <el-radio-group v-model="ruleData.cycle_rule_qw">
                <el-radio :label="1">按用户循环</el-radio>
                <el-radio :label="2">每日重置</el-radio>
              </el-radio-group>
            </el-form-item>
          </box>
          <box name="关联内容设置">
            <div style="display: flex; flex-direction: row">
              <el-form-item :rules="{ required: true, message: '请输入数据源ID', trigger: 'blur' }" label="数据源ID"
                prop="data_id_qw" style="width: 400px">
                <el-input v-model="ruleData.data_id_qw" placeholder="输入数据源ID" @input="inputChangeIdQw"></el-input>
              </el-form-item>
              <el-form-item :rules="{ required: true, message: '创建数据源公众号/小程序APPID', trigger: 'blur' }" label="APPID"
                prop="data_appid_qw" style="width: 400px">
                <el-input v-model="ruleData.data_appid_qw" placeholder="创建数据源公众号/小程序APPID"
                  @input="inputChangeAppIdQw"></el-input>
              </el-form-item>
            </div>
            <el-form-item prop="wx_appid_qw" style="margin-left: 20px;" class="customize_cycle_proportion">
              <!-- <el-input v-model="ruleData.wx_appid_qw" placeholder="企微绑定公众号/小程序APPID"></el-input> -->
              <el-input v-model="ruleData.wx_appid_qw" type="textarea" style="width: 600px"
                :autosize="{ minRows: 8, maxRows: 8 }" placeholder="企微绑定公众号/小程序APPID、或广告ID，支持多选输入，回车换行" />
            </el-form-item>
          </box>
        </el-collapse-item>
      </el-collapse>
      <el-form-item label-width="0px">
        <el-button type="primary" class="mt-20 mb-20" @click="submitForm()">{{ this.$route.params.pk_unique ? '确认修改' :
          '立即创建' }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Box from './box'
import Fixed from './returnType/fixed.vue'
import Customize from './returnType/customize.vue'
import AssociatedAccount from './associatedAccount.vue'
import AssociatedAccountV2 from './associatedAccountV2.vue'
import { Save, byPkUniqueGet, Update } from '@/network/api/passBack/index.js'
import { getQuickAppPromotionId } from '@/network/api/assetManagement/assetManagementHugeLandingPageTemp'
import { debounce } from '@/utils/ruoyi'
import moment from 'moment'

const quickAppTypeMapping = {
  '1': 'K1001',
  '2': 'K1002',
  '3': 'K1004',
  '4': 'K1005'
}

const vnovelTypeArray = [
  { label: '九州 ', value: 'V1001' },
  { label: '野象', value: 'V1002' },
  { label: '柚子', value: 'V1003' },
  { label: '自有', value: 'V1004' },
  { label: '嘉书短剧', value: 'V1007' },
  { label: '触摸短剧', value: 'V1011' },
  { label: '容量短剧', value: 'V1009' },
  { label: '映客短剧', value: 'V1014' },
  { label: '掌中云短剧', value: 'V1015' },
  { label: '花生短剧', value: 'V1008' },
  { label: '阅文短剧', value: 'V1016' },
  { label: '博易短剧', value: 'V1010' },
  { label: '阳光短剧', value: 'V1005' },
  { label: '天桥短剧', value: 'V1019' },
  { label: '新片场短剧', value: 'V1018' },
  { label: '蜜糖短剧', value: 'V1020' },
  { label: '番茄短剧', value: 'V1021' },
  { label: '七悦短剧', value: 'V1006' },
  { label: '麦芽短剧', value: 'V1022' },
  { label: '掌阅短剧', value: 'V1023' },
  { label: '鹊娱短剧', value: 'V1025' },
  { label: '新迈步短剧', value: 'V1024' },
  { label: '六翼短剧', value: 'V1027' },
  { label: '长角鹿短剧', value: 'V1026' },
  { label: '常读短剧', value: 'V1030' },
  { label: '奇树有鱼', value: 'V1031' },
  { label: '友和短剧', value: 'V1033' },
  { label: '百川短剧', value: 'V1028' },
  { label: '古言短剧', value: 'V1034' },
  { label: '触摸短剧2', value: 'V1036' },
  { label: '欢瑞短剧', value: 'V1037' }
]

const vnovelApplet = ['V1001', 'V1002', 'V1004', 'V1007', 'V1011', 'V1009', 'V1014', 'V1015', 'V1008', 'V1016', 'V1010', 'V1005', 'V1019', 'V1018', 'V1020', 'V1021', 'V1006', 'V1022', 'V1025', 'V1024', 'V1027', 'V1026', 'V1030', 'V1031', 'V1023', 'V1033', 'V1028', 'V1034', 'V1036', 'V1037']

const vnovelAppletArray = {
  'V1001': [
    { label: '九夏短剧', value: 'wx8b18f60545f67a1a' }
  ],
  'V1002': [
    { label: '红豆热剧', value: 'wx0627f5f4146d12c4' },
    { label: '幽思剧院', value: 'wx889b91a34001fe86' },
    { label: '易明剧场', value: 'wx60fc2454e98b8c86' }
  ],
  'V1003': [],
  'V1004': [
    { label: '桃花热剧', value: 'wxdd4f1917ea565b2c' },
    { label: '花火小剧场', value: 'wx37c9da633147387b' },
    { label: '香兰看剧', value: 'wx26dade0ee8ef15a1' },
    { label: '南天竺看剧', value: 'wxdc5d31e8b9598efb' }
  ],
  'V1005': [
    { label: '宝藏剧场', value: 'wx0e92bdebd19fe009' },
    { label: '部落短剧', value: 'wxdebf45b3bb28c162' },
    { label: '火锅热剧', value: 'wxa7433e160b47c392' },
    { label: '暖阳看剧', value: 'wx71c9c42e448532c1' }
  ],
  'V1006': [
    { label: '星越剧场', value: 'wxc3d28fcf43b696a7' }
  ],
  'V1007': [
    { label: '雪兔剧场', value: 'wx8e9b4fa10aca1d61' },
    { label: '迎梦剧场', value: 'wx20055246d104c11f' },
    { label: '山洞剧场', value: 'wxd2140babab359ccb' }
  ],
  'V1008': [
    { label: '迎春短剧', value: 'wxde665acae4c3fd7a' },
    { label: '香菜短剧', value: 'wx3e638de4414ae4ea' },
    { label: '烧饼热剧', value: 'wxdea9af87b05ae654' }
  ],
  'V1009': [
    { label: '溜溜看吧', value: 'wx86b41f06e3789fee' },
    { label: '海洋短剧', value: 'wxcb6ad6b499e459b0' }
  ],
  'V1010': [
    { label: '香果追剧', value: 'wxb85b32a58988599b' },
    { label: '香果优新剧', value: 'wxbd98084815ad0061' },
    { label: '香果伴享剧', value: 'wx6ac3437976665dc7' }
  ],
  'V1011': [
    { label: '时光小短剧', value: 'wx97c577358582c256' },
    { label: '初晴小短剧', value: 'wxf01afe6743f1f29c' },
    { label: '樱花小短剧', value: 'wxf8c07797bee75687' },
    { label: '指尖潮剧', value: 'wx9dbc35d0dfeb74f7' }
  ],
  'V1014': [
    { label: '小鸟短剧', value: 'wx83903c27db9f331c' },
    { label: '星虎剧场', value: 'wx3934d453812def48' },
    { label: '奶猫剧场', value: 'wx0e3db2e96b858e0f' }
  ],
  'V1015': [
    { label: '云享剧集', value: 'wxfdd07eac23b84229' },
    { label: '菁菁好剧', value: 'wxc94c9920611f74c9' }
  ],
  'V1016': [
    { label: '山楂小剧场', value: 'wx081582431c93da1a' }
  ],
  'V1018': [
    { label: '传奇新剧场', value: 'wx66581d5f683f849b' },
    { label: '新传奇短剧', value: 'wx32e14433ba2edf7e' }
  ],
  'V1019': [
    { label: '红酸枣剧院', value: 'wx07027fe5c81fcda2' }
  ],
  'V1020': [
    { label: '太阿剧场', value: 'wxe7e714f2ec985e29' },
    { label: '猫饼剧场', value: 'wxb2299542ead9c02e' }
  ],
  'V1021': [
    { label: '柠萌好剧', value: 'wxadf368eb1f1351c6' }
  ],
  'V1022': [
    { label: '渝彪短剧', value: 'wxad6a64b9cb7a3f0f' }
  ],
  'V1023': [
    { label: '笑意短剧', value: 'wx97d05bcf97404898' }
  ],
  'V1024': [
    { label: '清芳剧场', value: 'wx74f66bdc2ad51d26' }
  ],
  'V1025': [
    { label: '天和剧院', value: 'wxeb817808c5bf6503' }
  ],
  'V1026': [
    { label: '非凡剧院', value: 'wxa0638db167f5cab0' }
  ],
  'V1027': [
    { label: '紫荆小剧场', value: 'wxbe45396427d5e61d' }
  ],
  'V1028': [
    { label: '晴荷剧场', value: 'wxf6118e002d21ced0' }
  ],
  'V1030': [
    { label: '月桦剧场', value: 'wx33d0d523e649bc63' },
    { label: '易柳剧场', value: 'wx2952ae8c8c04ea60' }
  ],
  'V1031': [
    { label: '冰雪热播', value: 'wxcf8ed37f95766237' }
  ],
  'V1033': [
    { label: '鲤鱼剧院', value: 'wx71f3895b2a70d873' }
  ],
  'V1034': [
    { label: '悠柔小剧场', value: 'wx44cec65e86e5ca04' }
  ],
  'V1036': [
    { label: '笙箫剧院', value: 'wxedae3f9ffede0491' },
    { label: '芳草剧院', value: 'wxdfd51084c1e3ec80' },
    { label: '寒露剧院', value: 'wx0ea6c6e4319a0e20' },
    { label: '枫叶小短剧', value: 'wx133a7267e20ae6d8' }
  ],
  'V1037': [
    { label: '凤麟短剧', value: 'wxa2530da3b3a921b7' }
  ]
}

export default {
  components: {
    Box,
    Customize,
    Fixed,
    AssociatedAccount,
    AssociatedAccountV2
  },
  data() {
    return {
      vnovelTypeList: vnovelTypeArray.filter(t => { return (this.$route.params.type === 'gdt' || this.$route.params.type === 'oceanengine') || (t.value !== 'V1011' && t.value !== 'V1019' && t.value !== 'V1018') }),
      vnovelApplet: vnovelApplet,
      gameList: [],
      ruleData: {
        rule_name: null /** 规则名称 */,
        rule_type: [1],
        type: 'FIXED' /** 回传类型 FIXED固定类型, FREE自定义模式 */,
        rule_range: 'PUBLIC' /** 适用范围  "PUBLIC" | "AD";*/,
        platform: this.$route.params.type,
        pk_unique: this.$route.params.pk_unique,
        // 固定模式
        return_condition: 1 /** 回传条件设置 => 上传行为 1 | 2 | 3 | 4 | 5;*/,
        /** 回传条件设置 => 部分订单回传开关 开1 关2*/
        is_few_return: true,
        /** 回传方式 => 1 百分比回传, 2 循环比例回传*/
        return_type: 1,
        /** 回传方式 => 百分比回传 0.1-0.9 */
        return_rate: 0.2,
        /** 循环比例 "1:1" | "2:1" | "3:1" | "4:1" | "5:1" |*/
        cycle_proportion: this.$route.params.type === 'baidu' ? [] : '1:1',
        customize_cycle_proportion_start: null,
        customize_cycle_proportion_end: null,
        is_first_return: 1,
        /** 是否金额缩减 */
        is_on_sale: false,
        /** 循环规则  1按订单循环, 2每日重置 */
        cycle_rule: 1,
        /** 启用时间 */
        date: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],

        return_action_type: 1,

        // 自定义模式
        /** 是否首充  1 | 2*/
        is_first_recharge: 1,
        /** 时间条件 单选框  1 | 2*/
        attention_type: 1,
        /** 时间条件 小时输入框 */
        attention_time: 0,
        /** 订单金额 选择框 'EQ' | 'GT' | 'EGT' | 'LT' | 'ELT' | 'NO'*/
        order_operator: 'GT',
        /** 订单金额 金额输入框 */
        order_amount: 0,
        /** 订单金额表格 */
        order_amount_rule: [
          {
            key: 1,
            operator: 'AND',
            amount_off: 0,
            amount_off_condition: 'EQ',
            amount_on: 0,
            amount_on_condition: 'EQ',
            order_return_rate: 1,
            order_effect_time: ['00:00:00', '23:59:59'],
            effective_begin_time: '00:00:00',
            effective_end_time: '23:59:59',
            order_return_pick: 1,
            order_return_ban: 1,
            verify: false
          }
        ],
        /** 订单金额验证 */
        order_amount_rule_flag: false,
        /** 关联账号 */
        accounts: [],
        /** 关联广计划 */
        plans: null,
        plansArr: [],
        vnovel_type: '',
        data_id: '',
        data_appid: '',
        wx_appid: '', // 短剧小程序appid

        // 企微回传配置
        return_type_qw: 0,
        return_rate_qw: 0,
        customize_cycle_proportion_qw: null,
        customize_cycle_proportion_start_qw: null,
        customize_cycle_proportion_end_qw: null,
        cycle_rule_qw: 1,
        data_id_qw: '',
        data_appid_qw: '',
        wx_appid_qw: ''
      },
      quick_app_type: null,
      // vnovel_type: null,
      callback_type: 1,
      isShowAccounts: false,
      default_accounts: [],
      promotionIdArr: [],
      appletOptions: [],
      collapseVal: [1]
      // data_id: '',
      // data_appid: '',
      // wx_appid: '' // 短剧小程序appid
    }
  },

  mounted() {
    this.getDictOfGame()
    this.getRulesByPk_unique()
  },

  methods: {

    handleSelectQuickAppType(val) {
      this.promotionIdArr = []
      this.ruleData.plansArr = []
      this.ruleData.wx_appid = ''
      this.appletOptions = vnovelAppletArray[val] || []
    },

    changeActionType(val) {
      this.vnovelTypeList = []
      if (val === 1) {
        this.vnovelTypeList = vnovelTypeArray.filter(t => { return (this.ruleData.platform === 'gdt' || this.$route.params.type === 'oceanengine') || t.value !== 'V1011' })
      } else {
        this.vnovelTypeList = vnovelTypeArray.filter(t => { return (this.ruleData.platform === 'gdt' || this.$route.params.type === 'oceanengine') || t.value !== 'V1011' })
        this.ruleData.vnovel_type = null
        this.ruleData.wx_appid = ''
        this.ruleData.data_id = ''
        this.ruleData.data_appid = ''
      }
    },

    changeRuleType(val) {
      this.collapseVal = val
      if (val.includes(1) && val.includes(2)) {
        if (this.ruleData.rule_range === 'VNOVEL') {
          if (this.ruleData.data_appid !== '') {
            this.ruleData.data_appid_qw = this.ruleData.data_appid
          }
          if (this.ruleData.data_appid_qw !== '') {
            this.ruleData.data_appid = this.ruleData.data_appid_qw
          }
        }
        if (this.ruleData.rule_range === 'VNOVEL' || this.ruleData.rule_range === 'PUBLIC') {
          if (this.ruleData.data_id !== '') {
            this.ruleData.data_id_qw = this.ruleData.data_id
          }
          if (this.ruleData.data_id_qw !== '') {
            this.ruleData.data_id = this.ruleData.data_id_qw
          }
        }
      }
    },

    // 企微回传配置 回传方式选择
    changeReturnTypeQW(val) {
      if (val === 0) {
        this.ruleData.return_rate_qw = 0
        this.ruleData.customize_cycle_proportion_start_qw = null
        this.ruleData.customize_cycle_proportion_end_qw = null
      } else if (val === 1) {
        this.ruleData.customize_cycle_proportion_start_qw = null
        this.ruleData.customize_cycle_proportion_end_qw = null
      } else {
        this.ruleData.return_rate_qw = 0
      }
    },

    inputChangeAppIdQw(val) {
      if (this.ruleData.rule_type.includes(1) && this.ruleData.rule_type.includes(2) && this.ruleData.rule_range === 'VNOVEL') {
        this.ruleData.data_appid = val
      }
    },

    inputChangeIdQw(val) {
      if (this.ruleData.rule_type.includes(1) && this.ruleData.rule_type.includes(2) && this.ruleData.rule_range === 'VNOVEL') {
        this.ruleData.data_id = val
      }
    },

    inputChangeAppId(val) {
      if (this.ruleData.rule_type.includes(1) && this.ruleData.rule_type.includes(2) && (this.ruleData.rule_range === 'VNOVEL' || this.ruleData.rule_range === 'PUBLIC')) {
        this.ruleData.data_appid_qw = val
      }
    },

    inputChangeId(val) {
      if (this.ruleData.rule_type.includes(1) && this.ruleData.rule_type.includes(2) && (this.ruleData.rule_range === 'VNOVEL' || this.ruleData.rule_range === 'PUBLIC')) {
        this.ruleData.data_id_qw = val
      }
    },

    handleSelectApplet() {
      this.promotionIdArr = []
      this.ruleData.plansArr = []
    },

    remoteMethod: debounce(function (name) {
      this.getPromotionId(name)
    }),

    getPromotionIdByUrlIds(urlIds) {
      if (this.ruleData.quick_app_type) {
        getQuickAppPromotionId({
          platform: quickAppTypeMapping[this.ruleData.quick_app_type], urlIds
        }).then(({ code, data }) => {
          if (code === 200) {
            this.promotionIdArr = data
          }
        })
      }
    },

    getPromotionId(name) {
      if (this.ruleData.quick_app_type) {
        getQuickAppPromotionId({
          platform: quickAppTypeMapping[this.ruleData.quick_app_type], name
        }).then(({ code, data }) => {
          if (code === 200) {
            let l = []
            if (data) {
              if (this.ruleData.plansArr.length) {
                l = this.promotionIdArr.filter(id => this.ruleData.plansArr.includes(id.urlId))
              }
              data.forEach(d => {
                if (l.filter(x => x.urlId === d.urlId).length === 0) {
                  l.push(d)
                }
              })
            } else {
              l = []
            }
            this.promotionIdArr = l
          }
        })
      }
    },

    ruleRangeChange(value) {
      if (value === 'AD') {
        this.ruleData = {
          ...this.ruleData,
          plans: this.ruleData.plans || null
        }
      } else if (value === 'GAME') {
        this.ruleData.vnovel_type = 'G1001'
      } else if (value === 'VNOVEL') {
        if (this.ruleData.platform === 'oceanengine') {
          this.ruleData = {
            ...this.ruleData,
            return_action_type: 3
          }
        }
      }
      if (this.ruleData.rule_type.includes(1) && this.ruleData.rule_type.includes(2)) {
        if (this.ruleData.rule_range === 'VNOVEL') {
          if (this.ruleData.data_appid !== '') {
            this.ruleData.data_appid_qw = this.ruleData.data_appid
          }
          if (this.ruleData.data_appid_qw !== '') {
            this.ruleData.data_appid = this.ruleData.data_appid_qw
          }
        }
        if (this.ruleData.rule_range === 'VNOVEL' || this.ruleData.rule_range === 'PUBLIC') {
          if (this.ruleData.data_id !== '') {
            this.ruleData.data_id_qw = this.ruleData.data_id
          }
          if (this.ruleData.data_id_qw !== '') {
            this.ruleData.data_id = this.ruleData.data_id_qw
          }
        }
      }
    },

    // 获取小游戏下拉
    getDictOfGame() {
      this.getDicts('miniGame_Details').then((response) => {
        this.gameList = response.data
      })
    },

    /**
     *@description 编辑时获取数据
     */
    getRulesByPk_unique() {
      if (!this.$route.params.pk_unique) return (this.isShowAccounts = true)
      byPkUniqueGet(this.$route.params.pk_unique).then(({ code, data }) => {
        if (code === 200) {
          data.is_few_return = data.is_few_return === 1
          data.is_on_sale = data.is_on_sale === 1
          data.date = [moment(data.start_time, 'HH:mm:ss'), moment(data.end_time, 'HH:mm:ss')]
          data.cycle_proportion = this.$route.params.type === 'baidu' && data.cycle_proportion ? data.cycle_proportion.split(',') : data.cycle_proportion
          if (this.$route.params.type !== 'baidu' && data.cycle_proportion === '0:0') {
            const customize_cycle_proportion_arr = data.customize_cycle_proportion.split(':')
            data.customize_cycle_proportion_start = customize_cycle_proportion_arr[0]
            data.customize_cycle_proportion_end = customize_cycle_proportion_arr[1]
          }
          if (data.rule_type !== null && data.rule_type.includes(2) && data.return_type_qw === 2) {
            const customize_cycle_proportion_arr = data.customize_cycle_proportion_qw.split(':')
            data.customize_cycle_proportion_start_qw = customize_cycle_proportion_arr[0]
            data.customize_cycle_proportion_end_qw = customize_cycle_proportion_arr[1]
          }

          let order_amount_rule = data.order_amount_rule.map(v => {
            v.order_effect_time = v.effective_begin_time && v.effective_end_time ? [v.effective_begin_time, v.effective_end_time] : []
            return v
          })
          this.ruleData = { ...data, order_amount_rule, order_operator: 'GT', plansArr: [] }
          this.ruleData.return_rate_qw = data.return_rate_qw * 100
          this.collapseVal = data.rule_type
          if (data.rule_type === null) {
            this.collapseVal = [1]
            this.ruleData.rule_type = [1]
          }
          this.default_accounts = data.accounts
          // 适用范围 为 投放广告
          if (data.rule_range === 'AD' || data.rule_range === 'VNOVEL' || data.rule_range === 'GAME') {
            this.ruleData.plans = data.accounts.map((item) => item.id).join('\n')
          } else if (data.rule_range === 'QUICKAPP') {
            this.ruleData.plansArr = data.accounts.map((item) => item.id)
            this.getPromotionIdByUrlIds(this.ruleData.plansArr.join(','))
          }
          if ((data.platform === 'gdt' || data.platform === 'oceanengine') && data.rule_range === 'VNOVEL') {
            this.handleSelectQuickAppType(data.vnovel_type)
            this.ruleData.wx_appid = data.wx_appid
          }
          this.isShowAccounts = true
          console.log('this.ruleData', data)
        }
      })
    },

    plansInput(value) {
      this.ruleData = {
        ...this.ruleData,
        plans: value
      }
    },

    /**
     *@description 提交
     */
    submitForm() {
      this.$refs['submitParams'].validate((valid) => {
        if (valid) {
          if (this.ruleData.order_amount_rule_flag) {
            this.$message.error('订单金额有误!')
            return false
          }
          let accountList = []
          if (this.ruleData.rule_type.includes(1)) {
            if (this.ruleData.rule_range === 'PUBLIC') {
              let accounts = this.$refs['account'].getSelectList()
              if (accounts.length) {
                accountList = accounts.map((item) => {
                  let [id, name] = item.split('+')
                  return { id, name }
                })
              }
            } else {
              if (this.ruleData.plans) {
                accountList = this.ruleData.plans.split('\n').map((item) => {
                  let [id] = item.split('+')
                  return { id, name: '' }
                })
              }

              if (this.ruleData.rule_range === 'QUICKAPP') {
                accountList = this.ruleData.plansArr.map((item) => {
                  let [id] = item.split('+')
                  return { id, name: '' }
                })
              }
            }
            if (this.$route.params.type !== 'baidu' && this.ruleData.cycle_proportion === '0:0') {
              this.ruleData.customize_cycle_proportion = [this.ruleData.customize_cycle_proportion_start, this.ruleData.customize_cycle_proportion_end].join(':')
            }
          }
          if (this.ruleData.rule_type.includes(2)) {
            this.ruleData.customize_cycle_proportion_qw = this.ruleData.return_type_qw === 2 ? [this.ruleData.customize_cycle_proportion_start_qw, this.ruleData.customize_cycle_proportion_end_qw].join(':') : null
            this.ruleData.return_rate_qw = this.ruleData.return_rate_qw / 100
          }

          if (this.ruleData.type === 'FIXED' && this.ruleData.return_condition <= 0) {
            this.$message.error('请设置回传条件')
            return false
          }
          let params = {
            ...this.ruleData,
            cycle_proportion: this.$route.params.type === 'baidu' ? this.ruleData.cycle_proportion + '' : this.ruleData.cycle_proportion,
            start_time: moment(this.ruleData.date[0]).format('HH:mm:ss'),
            end_time: moment(this.ruleData.date[1]).format('HH:mm:ss'),
            is_few_return: this.ruleData.is_few_return ? 1 : 2,
            accounts: accountList,
            is_on_sale: this.ruleData.is_on_sale ? 1 : 0
          }
          delete params.date
          if (this.$route.params.pk_unique) {
            Update({ ...params, pk_unique: this.$route.params.pk_unique }).then((res) => {
              if (res.code === 200) {
                this.$message.success('修改成功!')
                this.$router.push('/returnSettingRules')
              }
            })
          } else {
            Save(params).then((res) => {
              if (res.code === 200) {
                this.$message.success('创建成功!')
                this.$router.push('/returnSettingRules')
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.customize_cycle_proportion {
  ::v-deep .el-form-item__content {
    margin: 0 !important
  }
}
</style>
