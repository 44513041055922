var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "image" }, [
    _vm.detail.slider.every((item) => item !== "")
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-carousel",
              _vm._l(_vm.detail.slider, function (item) {
                return _c("el-carousel-item", { key: item }, [
                  _c("img", {
                    staticStyle: { width: "100%" },
                    attrs: { src: item, alt: "" },
                  }),
                ])
              }),
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          { staticStyle: { "text-align": "center" } },
          [
            _vm._l(_vm.detail.slider, function (item, index) {
              return _c("i", {
                key: index,
                staticClass: "el-icon-picture-outline",
              })
            }),
            _c("p", [_vm._v(_vm._s(_vm.detail.name) + "组件")]),
          ],
          2
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }