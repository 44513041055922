<template>
  <el-dialog title="图片选择" class="material-box" :visible.sync="show" :destroy-on-close="true" :width="`1500px`"
    top="100px" :append-to-body="true" @close="onCloseDialog">
    <!-- 内容开始 -->
    <el-row class="selected-img" :gutter="20">
      <!-- 左边树形筛选框 -->
      <el-col :span="4">
        <el-col :span="3" class="tree-box">
          <el-input v-model="filterText" placeholder="输入关键字进行过滤" size="small" style="width: 200px"> </el-input>
          <div class="tree">
            <el-tree ref="tree" :data="adAlbum" lazy :load="loadNode" :props="defaultProps"
              :filter-node-method="filterNode" @node-click="nodeClick"></el-tree>
          </div>
        </el-col>
      </el-col>

      <!-- 右边内容去 -->
      <el-col :span="15">
        <!-- 右上搜索框 -->
        <el-row>
          <el-col :span="22">
            <el-form ref="imageQuery" :inline="true" :model="imageQuery" style="marginLeft: 10px">
              <el-form-item label="上传人: ">
                <el-input v-model="imageQuery.nickname" placeholder="请输入上传人"></el-input>
              </el-form-item>
              <el-form-item label="宽: ">
                <el-input v-model="imageQuery.width" placeholder="请输入宽"></el-input>
              </el-form-item>
              <el-form-item label="高: ">
                <el-input v-model="imageQuery.height" placeholder="请输入高"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmit">查询</el-button>
                <el-button @click="resetForm">重置</el-button>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="2">
            <el-button type="primary" plain @click="handleUploadMaterial">上传</el-button>
          </el-col>
        </el-row>

        <!-- 右下内容选择区 -->
        <el-row :gutter="20">
          <ul v-if="imgList.length" v-loading="imgLoading" element-loading-text="图片获取中" style="margin: 0"
            class="select-img">
            <el-row>
              <el-col v-for="item in imgList" :key="item.imageId" :span="6">
                <li :class="{ active: selectedIds.includes(item.imageId) }" @click="handleSelectImg(item)">
                  <div class="avatar-box">
                    <img :src="item.url" class="avatar" />
                  </div>
                  <div class="attachments">
                    <p>{{ item.materialName }}</p>
                    <div>
                      <p>
                        <span>{{ item.width }}px</span> * <span>{{ item.height }}px</span>
                      </p>
                      <p>{{ parseInt(item.size / 1024) }}kb</p>
                    </div>
                  </div>
                  <i class="el-icon-success"></i>
                </li>
              </el-col>
            </el-row>
            <el-pagination background layout="prev, pager, next" :total="imagListTotal" style="text-align: right;"
              @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
            </el-pagination>
          </ul>
          <ul v-else style="margin: 0" class="select-img">
            <el-empty description="暂无数据"></el-empty>
          </ul>
        </el-row>
      </el-col>

      <!-- 已选素材 -->
      <el-col :span="5">
        <div class="comp-selected-box page-name">
          <div class="comp-title">已选素材</div>
          <div class="comp-selected-content">
            <div class="comp-selected-item" v-for="(item, index) in selectedData" :key="index">
              <img class="comp-video" :src="item.url" />
              <span class="el-icon-error comp-del-icon" @click="handleDeleteSelected(index)"></span>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 内容结束 -->
    <div slot="footer" class="dialog-footer">
      <el-button @click="closeModal">取 消</el-button>
      <el-button type="primary" :loading="isLoading" :disabled="selectedData.length === 0" @click="handleConfirm">确 定
      </el-button>
    </div>

    <add-material-dialog ref="addMaterialDialog" :type="'image'" @edit="_getUpdateMaterial"></add-material-dialog>
  </el-dialog>
</template>

<script>
import { saveConfigImage } from '@/network/api/api-huge-batch'
import { getMaterialList } from '@/network/api/advManagement/advManagementBaiduCreativeFend'
import { getAdAlbum, getAdMaterialGroup } from '@/network/api/account/materents'

import AddMaterialDialog from '@/components/materialManagement/material/AddMaterialDialog'
export default {
  components: {
    'add-material-dialog': AddMaterialDialog
  },
  props: {
    // 项目id
    projectList: {
      type: Array,
      default: () => []
    },
    // 投放账户
    advertisers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isLoading: false,
      show: false, // 是否显示弹框
      maxSelect: 0, // 最大选择数
      selectedData: [], // 新选中的图片
      imgList: [], // 图片列表
      adAlbum: [], // 专辑名称
      filterText: '', // 筛选专辑
      defaultProps: {
        children: 'children',
        label: 'label',
        isLeaf: 'leaf'
      },
      imgLoading: false, // 图片加载
      groupIndex: -1,
      imageIndex: -1,
      type: '',
      imageQuery: {
        // 图片搜索条件
        nickname: '',
        albumId: 0,
        materialGroupId: 0,
        width: null,
        height: null,
        pageNum: 1,
        type: 'image'
      }
    }
  },
  computed: {
    selectedIds() {
      let ids = this.selectedData.map(v => v.imageId)
      return ids
    }
  },

  watch: {
    // 筛选专辑
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },

  methods: {
    // 显示弹框
    showModal(groupIndex, imageIndex, type, maxSelect) {
      this.maxSelect = maxSelect
      this.groupIndex = groupIndex
      this.imageIndex = imageIndex
      this.type = type
      this.show = true
    },
    // 关闭弹框
    closeModal() {
      this.show = false
    },
    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      this.imageQuery = {
        // 图片搜索条件
        nickname: '',
        albumId: 0,
        materialGroupId: 0,
        width: null,
        height: null,
        pageNum: 1,
        type: 'image'
      }
      this.selectedData = [] // 选中的图片
      this.imgList = [] // 图片列表
      this.adAlbum = [] // 专辑名称
      this.filterText = '' // 筛选专辑
      this.groupIndex = -1
      this.imageIndex = -1
    },
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    /**
     * @description: 点击树形选择调用
     * @param {*} value
     * @return {*}
     */
    nodeClick(value) {
      const { albumId, materialGroupId } = value
      this.imageQuery.albumId = albumId
      this.imageQuery.materialGroupId = materialGroupId || 0
      this.imageQuery.pageNum = 1
      this.getMaterialListFun()
    },
    /**
     * @description: 获取tree组件数据
     * @param {*} node 上下文
     * @param {*} resolve 设置数据
     */
    loadNode(node, resolve) {
      let projectIds = this.projectList.map((item) => {
        return item.bsProjectId
      })
      // 获取专辑
      if (node.level === 0) {
        getAdAlbum({ type: 'image', projectIds: projectIds }).then(({ code, data }) => {
          if (code === 200) {
            data.forEach((f) => (f.label = f.albumName))
            return resolve(data)
          }
        })
      }
      // 获取素材组
      const { albumId } = node.data
      if (node.level === 1) {
        getAdMaterialGroup({ type: 'image', albumId }).then(({ code, data }) => {
          if (code === 200) {
            if (data !== null && data.length > 0) {
              data.forEach((f) => {
                f.label = f.materialGroupName
                f.leaf = true
              })
              return resolve(data)
            }
          } else {
            return resolve([])
          }
        })
      }
    },
    /**
     * @description: 获取图片列表
     * @param {*} current 当前页
     */
    getMaterialListFun() {
      this.imgLoading = true
      getMaterialList(this.imageQuery).then((res) => {
        if (res.code === 200) {
          this.imgList = res.data.list
          this.imagListTotal = res.data.total
          this.imgLoading = false
        }
      })
    },

    /**
     * @description: 切换分页
     * @param {*} current 当前页
     */
    currentChange(current) {
      this.imageQuery.pageNum = current
      this.getMaterialListFun()
    },

    /**
     * @description: 查询图片
     */
    onSubmit() {
      this.getMaterialListFun()
    },

    /**
     * @description: 重置
     */
    resetForm() {
      this.$refs['imageQuery'].resetFields()
      this.imageQuery.nickname = ''
      this.imageQuery.width = null
      this.imageQuery.height = null
      this.imageQuery.pageNum = null
      this.getMaterialListFun()
    },

    // 点击选中图片
    handleSelectImg(item) {
      if (this.selectedIds.includes(item.imageId)) {
        let index = this.selectedData.findIndex(v => v.imageId === item.imageId)
        this.selectedData.splice(index, 1)
      } else {
        if (this.type === 'video' && this.selectedData.length && this.selectedData.length >= this.maxSelect) {
          this.$message.warning(`根据创意组图片配置个数，最多可选择${this.maxSelect}个图片`)
          return
        }
        this.selectedData.push(item)
      }
    },
    // 点击上传按钮
    handleUploadMaterial() {
      if (this.imageQuery.albumId) {
        this.$refs.addMaterialDialog.showModalTree(this.imageQuery.albumId, this.imageQuery.materialGroupId || '')
      } else {
        this.$refs.addMaterialDialog.showModal()
      }
    },
    // 上传按钮回调
    _getUpdateMaterial() {
      this.getMaterialListFun()
    },
    /**
     * @description: 点击确定调用
     */
    handleConfirm() {
      let params = this.selectedData.map(v => {
        return {
          advertiserIds: this.advertisers,
          hash: v.hash,
          imageId: v.imageId,
          materialName: v.materialName,
          url: v.url,
          width: v.width,
          height: v.height
        }
      })
      this.isLoading = true
      saveConfigImage(params).then(res => {
        if (res.code === 200) {
          let row = {
            groupIndex: this.groupIndex,
            imageIndex: this.imageIndex,
            type: this.type
          }
          this.$emit('confirm', row, res.data)
          this.closeModal()
        }
      }).finally(() => {
        this.isLoading = false
      })
    },

    // 点击删除选中视频
    handleDeleteSelected(index) {
      this.selectedData.splice(index, 1)
    }
  }
}
</script>

<style scoped lang="scss">
.success-color {
  color: #409eff !important;
}

.el-dialog__body {
  z-index: 3000 !important;
}

.material-box {
  .selected-img {
    .selected-img-item {
      width: 100px;
      height: 100px;

      &>div {
        position: relative;

        .avatar-mask {
          width: 100px;
          height: 100px;
          background: rgba(0, 0, 0, 0.507);
          position: absolute;
          top: 0px;
          left: 10px;
          border-radius: 4px;
          display: none;

          p {
            margin-top: 20px;
            color: #fff;
            text-align: center;
            cursor: pointer;
          }
        }

        &:hover .avatar-mask {
          display: block;
        }
      }
    }

    .selected-img-item2 {
      width: 70px;
      height: 70px;

      &>div {
        position: relative;

        .avatar-mask {
          width: 70px;
          height: 70px;
          background: rgba(0, 0, 0, 0.507);
          position: absolute;
          top: 0px;
          left: 10px;
          border-radius: 4px;
          display: none;

          p {
            margin-top: 10px;
            color: #fff;
            text-align: center;
            cursor: pointer;
          }
        }

        &:hover .avatar-mask {
          display: block;
        }
      }
    }

    .avatar {
      margin: 10px;
    }
  }

  .tree-box {
    width: 100%;
    height: 500px;

    .tree {
      width: 100%;
      height: 460px;
      margin-top: 10px;
      overflow-y: auto;
    }
  }

  .select-img {
    height: 450px;
    padding: 0;

    li {
      border: 1px solid #ddd;
      margin: 10px;
      padding: 7px 3px 6px 3px;
      border-radius: 6px;
      cursor: pointer;
      position: relative;

      &>.avatar-box {
        height: 140px;
        overflow: hidden;
        display: flex;
        align-items: center;
      }

      img {
        width: 100%;
        margin: 0;
        background: none;
        margin: 0 !important;
      }

      .attachments {
        padding: 5px;

        >div {
          display: flex;
          justify-content: space-between;
          margin-top: 5px;
        }

        p {
          padding: 0;
          margin: 0;
        }
      }

      .el-icon-success {
        position: absolute;
        top: -10px;
        left: -10px;
        font-size: 20px;
        color: #409eff;
        display: none;
        background: #fff;
      }
    }

    .active {
      border-color: #409eff;

      .el-icon-success {
        display: block;
      }
    }
  }
}

.comp-selected-box {
  padding: 0 10px;
  box-sizing: border-box;
  height: 600px;
  overflow-y: auto;
  box-sizing: border-box;

  .comp-title {
    color: #1a1a1a;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .comp-selected-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
  }

  .comp-selected-item {
    position: relative;
    border: 1px solid #ccc;
    width: 100%;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
    }

    .comp-del-icon {
      position: absolute;
      top: -5px;
      right: -5px;
      font-size: 18px;
      cursor: pointer;
    }
  }
}
</style>
