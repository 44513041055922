import service from '@/network/service/service'

// 根据项目获取标签列表
export function getTagList(id) {
  return service.get(`api/system/basicSettingLabelType/${id}`)
}
// 获取当前用户可以看到的所有产品
export const getUserProduct = () => service.get('api/system/basicSettingProduct/getAllProduct')
// 获取当前用户可以看到的所有专辑
export const getUserAlbum = (params) => service.post('api/file/adAlbum/getAlbum', params)
// 上传素材
export const uploadMaterial = (params) => service.post('/api/file/upload', params)
/**
 * 批量上传文件
 * @param files 文件列表
 */
export const multipeImageMaterialUpload = (files) => {
  const formData = new FormData()
  files.forEach((file, i) => {
    return formData.append(`file${i}`, file)
  })
  return service.post('/api/file/upload', formData)
}
/* --------------------------- 素材专辑 --------------------------- */
/**
 * 获取素材专辑列表
 * @type video视频类型 image图片类型
 */
export const getAlbumList = (pageNum, pageSize, params) => service.post(`/api/file/adAlbum/list?pageNum=${pageNum}&pageSize=${pageSize}`, params)

/**
 *  AssumeRole - 获取扮演角色的临时身份凭证
 *
 */
export const getAssumeRole = () => service.get('/api/file/sts/getAssumeRole')
/**
 * 新增专辑
 * @type video视频类型 image图片类型
 */
export const addAlbum = (params) => service.post('/api/file/adAlbum/create', params)

/**
 * 获取单个专辑详情
 */
export const getAlbumDetail = (params) => service.get('/api/file/adAlbum/selectOne', { params })

/**
 * 编辑单个专辑
 */
export const editAlbum = (id, params) => service.put(`/api/file/adAlbum/update/${id}`, params)

/**
 * 删除单个专辑
 */
export const delAlbum = (id) => service.delete(`/api/file/adAlbum/delete/${id}`)

/**
 * 获取专辑列表(不分页)
 * @type video视频类型 image图片类型
 */
export const getAlbumAllList = (params) => service.post('/api/file/adAlbum/select', params)

/**
 * 根据名称查询专辑列表
 */
export const findAlbumList = (params) => service.post('/api/file/adAlbum/find', params)

/* --------------------------- 素材组 --------------------------- */
/**
 * 获取素材组列表
 * @type video视频类型 image图片类型
 */
export const getMarterialGroupList = (pageNum, pageSize, params) => service.post(`/api/file/adMaterialGroup/list?pageNum=${pageNum}&pageSize=${pageSize}`, params)

/**
 * 新增素材组列表
 * @type video视频类型 image图片类型
 */
export const addMarterialGroup = (params) => service.post('/api/file/adMaterialGroup/create', params)

/**
 * 获取单个素材组详情
 */
export const getMarterialGroupDetail = (params) => service.get('/api/file/adMaterialGroup/selectOne', { params })

/**
 * 编辑单个素材组
 */
export const editMarterialGroup = (id, params) => service.put(`/api/file/adMaterialGroup/update/${id}`, params)

/**
 * 删除单个素材组
 */
export const delMarterialGroup = (id) => service.delete(`/api/file/adMaterialGroup/delete/${id}`)

/**
 * 获取素材组列表(不分页)
 * @type video视频类型 image图片类型
 */
export const getMarterialGroupAllList = (params) => service.post('/api/file/adMaterialGroup/select', params)

/**
 * 根据名称查询素材组列表
 */
export const findMarterialGroupList = (params) => service.post('/api/file/adMaterialGroup/find', params)

/* --------------------------- 图片素材 --------------------------- */
/**
 * 获取图片素材列表
 */
export const getImageMarterialList = (pageNum, pageSize, params) => service.post(`/api/file/adMaterialImage/list?pageNum=${pageNum}&pageSize=${pageSize}`, params)

/**
 * 新增图片素材
 */
export const addImageMarterial = (params) => service.post('/api/file/adMaterialImage/create', params)

/**
 * 获取单个图片素材详情
 */
export const getImageMarterialDetail = (params) => service.get('/api/file/adMaterialImage/selectOne', { params })

/**
 * 获取图片左侧tree
 */
export const getImgStructure = (pageNum, pageSize, params) => service.post(`/api/file/adMaterialImage/getImgStructure?pageNum=${pageNum}&pageSize=${pageSize}`, params)
/**
 * 获取视频左侧tree
 */
export const getVideoStructure = (pageNum, pageSize, params) => service.post(`/api/file/adMaterialVideo/getVideoStructure?pageNum=${pageNum}&pageSize=${pageSize}`, params)

/**
 * 编辑单个图片素材
 */
export const editImageMarterial = (id, params) => service.put(`/api/file/adMaterialImage/update/${id}`, params)

/**
 *
 * 批量复制图片素材
 */
export const batchCopyImageMarterial = (params) => service.post('/api/file/adMaterialImage/batch/copy', params)

/**
 *
 * 批量移动图片素材
 */
export const batchMoveImageMarterial = (params) => service.post('/api/file/adMaterialImage/batch/move', params)

/**
 *
 * 批量删除图片素材
 */
export const batchDelImageMarterial = (params) => service.delete('/api/file/adMaterialImage/batch/delete', { data: params })

/**
 * 删除单个图片素材
 */
export const delImageMarterial = (id) => service.delete(`/api/file/adMaterialImage/delete/${id}`)

/* --------------------------- 视频素材 --------------------------- */
/**
 * 获取视频素材列表
 */
export const getVideoMarterialList = (pageNum, pageSize, params) => service.post(`/api/file/adMaterialVideo/list?pageNum=${pageNum}&pageSize=${pageSize}`, params)

/**
 * 新增视频素材
 */
export const addVideoMarterial = (params) => service.post('/api/file/adMaterialVideo/create', params)

/**
 * 获取单个视频素材详情
 */
export const getVideoMarterialDetail = (params) => service.get('/api/file/adMaterialVideo/selectOne', { params })

/**
 * 编辑单个视频素材
 */
export const editVideoMarterial = (id, params) => service.put(`/api/file/adMaterialVideo/update/${id}`, params)

/**
 * 删除单个视频素材
 */
export const delVideoMarterial = (id) => service.delete(`/api/file/adMaterialVideo/delete/${id}`)

/**
 *
 * 批量复制视频素材
 */
export const batchCopyVideoMarterial = (params) => service.post('/api/file/adMaterialVideo/batch/copy', params)

/**
 *
 * 批量移动视频素材
 */
export const batchMoveVideoMarterial = (params) => service.post('/api/file/adMaterialVideo/batch/move', params)

/**
 *
 * 批量删除视频素材
 */
export const batchDelVideoMarterial = (params) => service.delete('/api/file/adMaterialVideo/batch/delete', { data: params })

/* --------------------------- 文本素材 --------------------------- */
/**
 * 获取文本素材列表
 */
export const getTxtList = (pageNum, pageSize, params) => service.post(`/api/file/adMaterialText/list?pageNum=${pageNum}&pageSize=${pageSize}`, params)

/**
 * 新增文本素材
 */
export const addTxt = (params) => service.post('/api/file/adMaterialText/create', params)
export const addBatchTxt = (data) => service.post('/api/file/adMaterialText/batchCreate', data)

/**
 * 获取单个文本素材详情
 */
export const getTxtDetail = (params) => service.get('/api/file/adMaterialText/selectOne', { params })

/**
 * 编辑单个文本素材
 */
export const editTxt = (id, params) => service.put(`/api/file/adMaterialText/update/${id}`, params)

/**
 * 删除单个文本素材
 */
export const delTxt = (id) => service.delete(`/api/file/adMaterialText/delete/${id}`)

/**
 *
 * 批量删除文本素材
 */
export const batchDelTxt = (params) => service.delete('/api/file/adMaterialText/batch/delete', { data: params })

/**
 *
 * 批量移动文本素材
 */
export const batchMoveTxt = (params) => service.post('/api/file/adMaterialText/batchMove', params)

/* --------------------------- 文本组 --------------------------- */
/**
 * 获取文本组列表
 */
export const getTxtGrouplList = (pageNum, pageSize, params) => service.post(`/api/file/adMaterialTextGroup/list?pageNum=${pageNum}&pageSize=${pageSize}`, params)

/**
 * 新增文本组素材
 */
export const addTxtGroup = (params) => service.post('/api/file/adMaterialTextGroup/create', params)

/**
 * 获取单个文本组详情
 */
export const getTxtGroupDetail = (params) => service.get('/api/file/adMaterialTextGroup/selectOne', { params })

/**
 * 批量创建文本组素材
 */
export const batchAddTxtGroup = (params) => service.post('/api/file/adMaterialTextGroup/batch/create', params)

/**
 * 删除单个文本组素材
 */
export const delTxtGroup = (id) => service.delete(`/api/file/adMaterialTextGroup/delete/${id}`)

/**
 * 编辑单个文本组素材
 */
export const editTxtGroup = (id, params) => service.put(`/api/file/adMaterialTextGroup/update/${id}`, params)

/**
 *
 * 批量删除文本组素材
 */
export const batchDelTxtGroup = (params) => service.delete('/api/file/adMaterialTextGroup/batch/delete', { data: params })
