<!--
 * @Description: 推广计划模板选择
 * @Date: 2021-11-09 09:32:28
 * @LastEditTime: 2022-05-11 11:59:33
-->
<template>
  <div>
    <Base title="基础信息">
      <el-form :inline="true" :model="ruleData" class="demo-form-inline">
        <el-form-item label="项目">
          <el-select v-model="ruleData.projectId" style="width:300px" :disabled="dataSource.data.length" @change="(value) => ((selectProjectId = value), (ruleData.productId = '-1'))">
            <el-option v-for="(item, index) in projectList" :key="index" :label="item.projectName" :value="item.bsProjectId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品">
          <el-select v-model="ruleData.productId" style="width:300px" :disabled="dataSource.data.length" @change="(value) => (selectProductId = value)">
            <el-option label="不限" value="-1"></el-option>
            <el-option v-for="(item, index) in productListed" :key="index" :label="item.productName" :value="item.bsProductId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="housekeeperId" label="账户管家">
          <el-select
            v-model="ruleData.housekeeperId"
            :disabled="dataSource.data.length"
            style="width:300px"
            collapse-tags
            placeholder="管家账号"
            @change="
              (value) => {
                changeHouseKeeperFun(value), changeHouseKeeperFun2(value)
              }
            "
          >
            <el-option v-for="(item, index) in houseKeeper" :key="index" :label="item.launchAccountName" :value="item.housekeeperId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="advId" label="投放账户">
          <el-select v-model="ruleData.baiduId" style="width:300px" :disabled="!(ruleData.housekeeperId && ruleData.projectId) || dataSource.data.length" placeholder="投放账户" filterable multiple @change="(value) => changebaiduId(value)">
            <el-option v-for="(item, index) in accountBaiduAdv" :key="index" :label="item.accountName" :value="item.baiduId"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </Base>

    <Base title="推广计划模板">
      <div class="justify-sb">
        <div>
          <el-button type="primary" :disabled="!ruleData.baiduId.length" @click="addTemplate">添加计划</el-button>
          <span class="tip ml-10">选择计划后基础信息不可更改!</span>
        </div>
        <el-button :loading="pushLoading" type="primary" :disabled="dataSource.data.every((item) => item.releaseStatus === '已发布')" @click="publishAll">全部发布</el-button>
      </div>
      <div class="table-pane mt-20">
        <TablePane v-loading="tableLoading" :element-loading-text="`${campaignFeedName}, 正在发布中`" :data-source="dataSource" class="table" border @getPage="getPage"> </TablePane>
      </div>
      <div class="bottom-btn">
        <el-tooltip v-if="dataSource.data.length === 0 || dataSource.data.some((item) => item.releaseStatus === '未发布')" class="item" effect="dark" content="请发布全部计划模板！" placement="top">
          <div>
            <el-button type="primary" disabled>下一步</el-button>
          </div>
        </el-tooltip>
        <el-button v-else type="primary" :disabled="pushLoading || tableLoading" @click="nextStep">下一步</el-button>
        <el-button class="ml-20" :disabled="pushLoading || tableLoading" @click="cancel">取 消 </el-button>
      </div>
    </Base>
    <SeleteModal
      ref="modal"
      dialog-title="选择推广计划"
      left-title="投放账户"
      :tab-pane="[
        { title: '计划模板', name: 'jhmb', subTitle: [{ key: 'jhmb', value: '计划模板' }], sort: 1, level: '1', disabled: false },
        {
          title: '线上计划',
          name: 'xsmb',
          subTitle: [{ key: 'xsmb', value: '线上计划' }],
          sort: 2,
          level: '2',
          disabled: ruleData.baiduId.length ? false : true
        }
      ]"
      :visible="seleteModalVisible"
      :online-query="accountBaiduAdv.filter((item) => ruleData.baiduId.includes(item.baiduId))"
      :local-data="mePlan"
      :online-data="baiduMePlan"
      :data-source="dataSource.data"
      @onOk="getTemplateData"
    />
  </div>
</template>

<script>
import Base from './component/base.vue'
import baiduHouseKeeper from '@/mixin/account_baidu.js'
import TablePane from '@/components/tablePane.vue'
import SeleteModal from './component/seleteModal.vue'
import { byProjectIdGetPlan, getBaiduApiPlan, deleteBaiduApiPlan, batchUpPlan } from '@/network/api/advManagement/advManagementBaiduPlan' // 获取计划模板

const PUBLISH = '已发布'
const UN_PUBLISH = '未发布'

export default {
  provide: function() {
    return {
      getBaiduOnline: this.getBaiduApiPlanFun,
      close: () => {
        this.seleteModalVisible = false
        this.baiduMePlan = []
        this.mePlan = []
      }
    }
  },
  inject: ['setStep'],
  components: {
    Base,
    TablePane,
    SeleteModal
  },
  mixins: [baiduHouseKeeper],
  data() {
    return {
      stepsActive: 1, // 步骤条
      ruleData: {
        // 后期修改
        projectId: '',
        productId: '-1',
        housekeeperId: '', // 账号管家
        baiduId: [] // [] // 投放账户
      },
      seleteModalVisible: false,
      mePlan: [], // 计划模板list
      baiduMePlan: [], // 百度计划模板list
      templateData: [], // 选中的模板数据
      pushLoading: false,
      tableLoading: false,
      campaignFeedName: '',
      // 表格数据
      dataSource: {
        data: [],
        cols: [
          {
            label: '投放账户',
            prop: 'accountName'
          },
          {
            label: '计划名称',
            render: (record) => {
              return (
                /* eslint-disable */
                <el-tooltip placement="top">
                  <div slot="content">
                    <p>{record.type === '线上' ? '-' : record.lable}</p>
                  </div>
                  <p class="text-ellipsis">{record.type === '线上' ? '-' : record.lable}</p>
                </el-tooltip>
                /* eslint-disable */
              )
            }
          },
          {
            label: '线上计划Id',
            width: '180',
            render: (record) => (record.campaignFeedId ? record.campaignFeedId : '-')
          },
          {
            label: '线上计划名称',
            render: (record) => {
              return (
                /* eslint-disable */
                <el-tooltip placement="top">
                  <div slot="content">
                    <p>{record.campaignFeedName2 ? record.campaignFeedName2 : record.lable}</p>
                  </div>
                  <p class="text-ellipsis">{record.campaignFeedName2 ? record.campaignFeedName2 : record.lable}</p>
                </el-tooltip>
                /* eslint-disable */
              )
            }
          },
          {
            label: '创建人',
            width: '100',
            prop: 'createBy'
          },
          {
            label: '创建时间',
            width: '180',
            prop: 'createTime'
          },
          {
            label: '类型',
            width: 80,
            prop: 'type'
          },
          {
            label: '发布状态',
            width: 100,
            render: (record) => {
              if (record.releaseStatus === PUBLISH) {
                // eslint-disable-next-line
                return record.type === '模板' ? <el-tag type="success">已发布</el-tag> : '-'
              } else {
                // eslint-disable-next-line
                return <el-tag type="danger">未发布</el-tag>
              }
            }
          }
        ], // 表格的列数据
        isIndex: true, // 列表序号
        loading: false, // loading
        isOperation: true,
        isBorder: true,
        handleSelectionChange() {},
        pageData: {
          total: 0, // 总条数
          pageSize: 10,
          layout: 'total, prev, pager, next, jumper'
        },
        operation: {
          // 表格有操作列时设置
          label: '操作', // 列名
          width: '150', // 根据实际情况给宽度
          data: [
            {
              label: '发布', // 操作名称
              type: 'text', // 按钮类型
              showLabel: 'releaseStatus',
              showValue: ['未发布'],
              handleRow: async (index, record) => {
                this.tableLoading = true
                this.campaignFeedName = record.campaignFeedName
                if (record.releaseStatus === UN_PUBLISH) {
                  let params = {
                    housekeeperId: this.ruleData.housekeeperId,
                    items: [{ baiduId: record.baiduId, baiduAdvPlanId: record.baiduAdvPlanId }]
                  }
                  const { code, data } = await batchUpPlan(params)
                  if (code === 200) {
                    if (data.items[0].code === 200) {
                      this.$message.success('发布成功!!')
                      this.dataSource.data[index].releaseStatus = PUBLISH
                      this.dataSource.data[index].baiduId = +data.items[0].baiduId
                      this.dataSource.data[index].campaignFeedId = data.items[0].campaignFeedId
                      this.dataSource.data[index].campaignFeedName2 = data.items[0].campaignFeedName
                    } else {
                      this.$message.error('发布失败!')
                    }
                    this.tableLoading = false
                  }
                }
              } // 自定义事件
            },
            {
              label: '删除', // 操作名称
              type: 'text', // 按钮类型
              color: '#ff0000',
              handleRow: async (index, record) => {
                if (record.releaseStatus === PUBLISH && record.type === '模板') {
                  let params = {
                    deleteBaiduPlanItems: [
                      {
                        baiduId: record.baiduId,
                        campaignFeedId: record.campaignFeedId
                      }
                    ],
                    housekeeperId: record.housekeeperId
                  }
                  const { code } = await deleteBaiduApiPlan(params)
                  if (code === 200) {
                    this.dataSource.data.splice(index, 1)
                    this.$message.success('删除成功!')
                  }
                } else {
                  this.dataSource.data.splice(index, 1)
                  this.$message.success('删除成功!')
                }
                this.templateData = this.templateData.filter((f) => +f.campaignFeedId !== +record.campaignFeedId)
              }
            }
          ]
        }
      }
    }
  },

  methods: {
    /**
     * @description 获取选中的账户管家
     */
    changeHouseKeeperFun2(value) {
      this.ruleData.baiduId = []
      this.$emit('setHousekeeperInfo', this.houseKeeper.filter((item) => item.housekeeperId === value)[0])
    },

    /**
     * @description 获取选中的投放账户
     */
    changebaiduId(value) {
      let list = this.accountBaiduAdv.filter((item) => value.includes(item.baiduId))
      this.$emit('setBaiduIdList', list)
    },

    /**
     * @description 获取计划模板
     */
    async getMePlanFun() {
      const { code, data } = await byProjectIdGetPlan(this.ruleData.projectId, this.ruleData.productId)
      if (code === 200) {
        data.forEach((item) => {
          item.id = item.baiduAdvPlanId
          item.lable = item.campaignFeedName
          item.lable2 = item.projectName
        })
        // 判断计划模板是否被选中, 选中置灰
        if (this.dataSource.data.length) {
          data.forEach((item) => {
            this.dataSource.data.forEach((el) => {
              if (item.id === el.id) {
                item.disabled = true
              }
            })
          })
        }
        this.mePlan = data
      }
    },

    /**
     * 添加模板
     */
    addTemplate() {
      this.seleteModalVisible = true
      this.getMePlanFun()
    },

    /**
     * @description 获取线上计划
     * @param {item} 已选中的投放账户
     */
    async getBaiduApiPlanFun(item) {
      const { code, data } = await getBaiduApiPlan(item.hkId, item.baiduId, 1)
      if (code === 200) {
        data[0].plans.forEach((item) => {
          item.id = item.campaignFeedId
          item.lable = item.campaignFeedName
          item.accountName = data[0].accountBaiduAdvENT.accountName
          item.housekeeperId = data[0].accountBaiduAdvENT.hkId
          item.baiduId = data[0].accountBaiduAdvENT.baiduId
        })
        // 判线上计划板是否被选中, 选中置灰
        if (this.dataSource.data.length) {
          data[0].plans.forEach((item) => {
            this.dataSource.data.forEach((el) => {
              if (item.id === el.id) {
                item.disabled = true
              }
            })
          })
        }
        this.baiduMePlan = data[0].plans
      }
    },

    /**
     * @description 获取计划模板数据
     * @param {data}
     */
    getTemplateData(data) {
      let _templateData = [...this.templateData]
      let qc_data = [..._templateData, ...data.local]
      data.online.length &&
        data.online.map((item) => {
          if (qc_data.length === 0 || !_templateData.some((s) => +s.campaignFeedId === +item.campaignFeedId)) {
            qc_data.push(item)
          }
        })
      this.dataSource.data = [...qc_data].splice(0, 10)
      this.templateData = qc_data
      this.dataSource.pageData.total = qc_data.length
    },

    /**
     * @description 设置分页
     */
    getPage({ pageSize, pageNum }) {
      let data = [...this.templateData]
      this.dataSource.data = data.splice((pageNum - 1) * pageSize, pageSize * pageNum)
    },

    /**
     * @description 全部发布
     */
    publishAll() {
      this.pushLoading = true
      let items = []
      this.dataSource.data.map((item) => {
        if (item.releaseStatus === UN_PUBLISH) {
          items.push({ baiduId: item.baiduId, baiduAdvPlanId: item.baiduAdvPlanId })
        }
      })
      let params = {
        housekeeperId: this.ruleData.housekeeperId,
        items: items
      }
      batchUpPlan(params).then(({ code, data }) => {
        if (code === 200) {
          data.items.map((item) => {
            if (item.code === 200) {
              let index = this.dataSource.data.findIndex((f) => f.baiduAdvPlanId === +item.baiduAdvPlanId && f.baiduId === +item.baiduId)
              this.dataSource.data[index].releaseStatus = PUBLISH
              this.dataSource.data[index].campaignFeedId = item.campaignFeedId
              this.dataSource.data[index].campaignFeedName2 = item.campaignFeedName
            } else {
              this.$message.error(item.msg)
            }
          })
        }
        this.pushLoading = false
      })
    },

    /**
     * @description 下一步
     */
    nextStep() {
      // 1.将选中数据保持
      this.$emit('getPlanData', this.dataSource.data)
      // 2.跳到单元选择
      this.setStep('next')
    },

    /**
     * @description 取消
     */
    async cancel() {
      let deleteBaiduPlanItems = []
      this.dataSource.data.map((item) => {
        if (item.releaseStatus === PUBLISH && item.type === '模板') {
          deleteBaiduPlanItems.push({
            baiduId: item.baiduId,
            campaignFeedId: item.campaignFeedId
          })
        }
      })
      let params = {
        deleteBaiduPlanItems,
        housekeeperId: this.dataSource.data[0].housekeeperId
      }
      const { code } = await deleteBaiduApiPlan(params)
      if (code === 200) {
        this.dataSource.data = []
        this.templateData = []
        this.$message.success('操作成功!')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.demo-form-inline {
  display: flex;
  justify-content: space-between;
}

.table-pane {
  padding: 10px 40px 0 40px;
  text-align: right;
}

.bottom-btn {
  display: flex;
  justify-content: center;
}
</style>
