<template>
  <el-dialog
    class="data-details-dialog"
    title="数据生成条件"
    :visible.sync="show"
    :append-to-body="true"
    width="500px"
    top="20vh"
    @close="onCloseDialog"
  >
    <div class="page-container">
      <div class="desc-box">
        <div class="box-item">
          <span>数据条件：</span>
          <span style="color: #989898">{{ dataType | filterToolType }}</span>
        </div>
        <div class="box-item">
          <span>加粉时间：</span>
          <span style="color: #989898">{{ followersTime.split(',')[0] }}至{{ followersTime.split(',')[1] }}</span>
        </div>
        <div class="box-item">
          <span>区别性别：</span>
          <span style="color: #989898"> {{ isUseGender === 2 ? '否' : gender }}</span>
        </div>
        <div class="box-item">
          <span>导出数据：</span>
          <span style="color: #989898">{{ exportData }}</span>
        </div>
        <div class="box-item">
          <span>指定企微：</span>
          <span style="color: #989898">{{ +selectWeCom === 2 ? '否' : weComId }}</span>
        </div>
      </div>
    </div>
    <!--底部按钮-->
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        class="btn"
        @click="closeModal"
      >确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'DataDetailsDialog',
  filters: {
    filterToolType(value) {
      const types = { 1: '企微留存', 2: '企微流失' }
      return types[value] || ''
    }
  },
  data() {
    return {
      show: false,

      dataType: '',
      followersTime: '',
      isUseGender: '',
      gender: '',
      exportData: '',
      selectWeCom: '',
      weComId: '',

      genderList: [
        {
          id: 1,
          name: '男'
        },
        {
          id: 2,
          name: '女'
        },
        {
          id: 3,
          name: '未知'
        }
      ],

      exportDataList: [
        {
          id: 1,
          name: 'IDFA'
        },
        {
          id: 2,
          name: 'IMEI'
        },
        {
          id: 3,
          name: 'MAC'
        },
        {
          id: 4,
          name: 'OAID'
        }
      ]
    }
  },
  methods: {
    /* --------------------------- Actions --------------------------- */
    showModal(val) {
      this.show = true
      this.dataType = val.dataType
      this.followersTime = val.followersTime
      this.isUseGender = val.isUseGender
      this.gender = this.isUseGender === 1 ? val.gender.split(',').map(item => {
        return this.genderList.find(it => it.id === Number(item)).name
      }).join(',') : ''
      this.exportData = val.exportData.split(',').map(item => {
        return this.exportDataList.find(it => it.id === Number(item)).name
      }).join(',')
      this.selectWeCom = val.selectWeCom
      this.weComId = val.weComId
    },

    // 监听 Dialog 弹框关闭
    onCloseDialog() {

    },

    // 关闭弹框1
    closeModal() {
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
.box-item {
  margin-bottom: 15px;
}
</style>
