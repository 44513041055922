/*
 * @Description: 定向/人群路由
 * @Date: 2021-10-05 13:41:50
 * @LastEditTime: 2023-03-09 18:16:05
 */
import service from '@/network/service/service'
import { omit } from '../../../utils/ruoyi'

// ------------------人群--------------

// 获取人群列表
export function audiencePage(data) {
  return service.post('/api/tencent/adMpAudience/page', data)
}

// 新建人群
export function audienceSave(data) {
  return service.post('/api/tencent/adMpAudience/save', data)
}

// 修改人群
export function audienceUpdate(data) {
  return service.post('/api/tencent/adMpAudience/update', data)
}

// 删除人群 ids
export function audienceDelete(data) {
  return service.post('/api/tencent/adMpAudience/delete', data)
}

// 获取子级标签
export function getTags() {
  return service.get('/api/tencent/adMpAudience/getTags')
}
export function getLeafTags(data) {
  return service.post('/api/tencent/adMpAudience/getLeafTags', data)
}

// ------------------人群同步--------------
// 获取人群列表
export function audienceManagementPage(data) {
  return service.post('/api/tencent/adMpAudienceManagement/page', data)
}

// 同步
export function audienceManagementAsync(data) {
  return service.post('/api/tencent/adMpAudienceManagement/async', data)
}

// 人群包同步
export function adMpAudienceAsync(data) {
  return service.post('/api/tencent/adMpAudience/async', data)
}

// ------------------定向--------------

// 获取定向列表
export function targetingsPage(data) {
  return service.post('/api/tencent/adMpTargetings/page', data)
}

// 新建定向
export function targetingsSave(data) {
  return service.post('/api/tencent/adMpTargetings/save', data)
}

// 修改定向详情
export function targetingsTargetUpdate(data) {
  return service.post('/api/tencent/adMpTargetings/targetUpdate', data)
}

// 修改定向详情
export function targetingsUpdate(data) {
  return service.post('/api/tencent/adMpTargetings/update', data)
}

// 删除定向 ids
export function targetingsDelete(data) {
  return service.post('/api/tencent/adMpTargetings/delete', data)
}

// 获取一条定向 ids
export function getAdMpTargetingsOnly(id) {
  return service.get(`/api/tencent/adMpTargetings/get/${id}`)
}

// ------------------落地页--------------
// 获取落地页列表
export function ldyPage(data) {
  return service.post('/api/tencent/adMpPage/page', data)
}

// 新建落地页
export function ldySave(params, isWX) {
  let adCanvasInfo = {
    PageList: {
      Page: [
        {
          backgroundColor: params.backgroundColor,
          backgroundCover: null,
          componentItemList: {
            componentItem: params.canvasInfo
          }
        }
      ]
    },
    ...omit(params, ['backgroundColor', 'canvasInfo']), // adLocation,canvasName,shareDesc, shareTitle, shareThumb, topType
    pageID: '',
    supportInfo: {
      subType: isWX ? '15' : ['17'],
      platform: [],
      iosAppid: [],
      appid: [],
      qid: ''
    },
    btnType: 135168,
    videoFileSize: 0,
    version: 'v2',
    original_page_id: 0,
    platform: 'native',
    wxad_copyFlag: '2',
    hasRedPkt: '0',
    fileSize: 2089504,
    sizeType: '1',
    basicWidth: '750',
    basicRootFontSize: '1'
  }
  console.log(adCanvasInfo)
  return service.post('/api/tencent/adMpPage/save', {
    promotePage: JSON.stringify({ adCanvasInfo }),
    promoteTitle: adCanvasInfo.canvasName,
    topType: adCanvasInfo.topType,
    topMaterial: adCanvasInfo.shareThumbUrl,
    adLocation: adCanvasInfo.adLocation,
    projectId: adCanvasInfo.projectId,
    projectName: adCanvasInfo.projectName,
    productId: adCanvasInfo.productId,
    productName: adCanvasInfo.productName,
    is_enterprise_wx: 1,
    source: 1
  })
}

// 新建落地页
export function ldyUpdate(params, isWX) {
  let adCanvasInfo = {
    PageList: {
      Page: [
        {
          backgroundColor: params.backgroundColor,
          backgroundCover: null,
          componentItemList: {
            componentItem: params.canvasInfo
          }
        }
      ]
    },
    ...omit(params, ['backgroundColor', 'canvasInfo']), // adLocation,canvasName,shareDesc, shareTitle, shareThumb, topType
    pageID: '',
    supportInfo: {
      subType: isWX ? '15' : ['17'],
      platform: [],
      iosAppid: [],
      appid: [],
      qid: ''
    },
    btnType: 135168,
    videoFileSize: 0,
    version: 'v2',
    original_page_id: 0,
    platform: 'native',
    wxad_copyFlag: '2',
    hasRedPkt: '0',
    fileSize: 2089504,
    sizeType: '1',
    basicWidth: '750',
    basicRootFontSize: '1'
  }
  return service.post('/api/tencent/adMpPage/update', {
    id: adCanvasInfo.id,
    promotePage: JSON.stringify({ adCanvasInfo }),
    promoteTitle: adCanvasInfo.canvasName,
    topType: adCanvasInfo.topType,
    topMaterial: adCanvasInfo.shareThumbUrl,
    adLocation: adCanvasInfo.adLocation,
    projectId: adCanvasInfo.projectId,
    projectName: adCanvasInfo.projectName,
    productId: adCanvasInfo.productId,
    productName: adCanvasInfo.productName,
    is_enterprise_wx: 1,
    source: 1
  })
}

// 获取一条数据
export function getOnlyPage(id) {
  return service.get(`/api/tencent/adMpPage/get/${id}`)
}

// 删除和批量删除(伪删除)
export function ldyDelete(ids) {
  return service.post('/api/tencent/adMpPage/delete', { ids })
}

// 批量复制
export function ldyBatchCopy(ids) {
  return service.post('/api/tencent/adMpPage/batch/copy', { ids })
}

// 批量同步操作
export function ldyBatchAsync(data) {
  return service.post('/api/tencent/adMpPage/batch/async', data)
}
export function ldyBatchAsyncNew(data) {
  return service.post('/api/tencent/adMpPage/batch/asyncNew', data)
}
export function ldyBatchAsyncNewAdq(data) {
  return service.post('/api/tencent/adMpPage/batch/asyncNewAdq', data)
}
// 上传日志列表
export function ruleRelation(data) {
  return service.post('/api/tencent/adMpPageManagement/page', data)
}
// 重新同步
export function ldyResync(data) {
  return service.post('/api/tencent/adMpPageManagement/resync', data)
}
// 落地页上传日志筛选列表
export function ldyLogSelect() {
  return service.get('/api/tencent/adMpPageManagement/select')
}

