/*
 * @Description: 基础数据
 * @Date: 2021-10-18 11:53:23
 * @LastEditTime: 2023-03-16 15:26:33
 */

import { shelfnew_one } from './defaultWidgetData'
export let widgets = [
  {
    type: '顶部组件',
    title: '图片',
    name: 'topImg'
  },
  {
    type: '顶部组件',
    title: '轮播图',
    name: 'topSlider'
  },
  {
    type: '顶部组件',
    title: '视频',
    name: 'topVideo'
  },
  {
    type: '基础组件',
    title: '图片',
    name: 'img'
  },
  {
    type: '基础组件',
    title: '轮播图',
    name: 'slider'
  },
  {
    type: '基础组件',
    title: '文本',
    name: 'text'
  },
  {
    type: '基础组件',
    title: '视频',
    name: 'video'
  },
  {
    type: '转化按钮',
    title: '关注公众号',
    name: 'copyGh'
  },
  {
    type: '转化按钮',
    title: '添加商家微信',
    name: 'enterprise_wx_auto'
  },
  {
    type: '营销组件',
    title: '图文复合组件',
    name: 'shelfnew'
  },
  {
    type: '营销组件',
    title: '悬浮组件',
    name: 'floatbutton'
  }
]

export let widgetMapData = {
  topimg: {
    id: `widget_${new Date().getTime()}_0`,
    name: '顶部图片',
    type: '41',
    fileSize: 0,
    imageMd5: '',
    viewType: '0',
    initWidth: 800,
    adLocation: 'sns',
    imageWidth: 750,
    initHeight: 800,
    materialId: '',
    paddingTop: 0,
    widgetType: 'top',
    imageHeight: 750,
    paddingLeft: 0,
    paddingRight: 0,
    pureImageUrl: '',
    widgetTypeV2: 'topimg',
    component: 'm-topImg',
    paddingBottom: 0
  },

  topslider: {
    widgetType: 'top',
    widgetTypeV2: 'topslider',
    component: 'm-topSlider',
    type: '101',
    name: '轮播图',
    id: `widget_${new Date().getTime()}_0`,
    activeIndex: 0,
    pureImageUrl: '',
    pageCtrType: '1',
    isFullScreen: '0',
    componentCount: '3',
    componentGroupList: {
      componentGroup: [
        {
          id: `topslider_${new Date().getTime()}_0`,
          componentItem: {
            type: '41',
            fileSize: 0,
            imageMd5: '',
            initWidth: 800,
            imageWidth: 750,
            initHeight: 800,
            materialId: 0,
            paddingTop: 0,
            imageHeight: 750,
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0,
            pureImageUrl: ''
          }
        },
        {
          id: `topslider_${new Date().getTime()}_1`,
          componentItem: {
            type: '41',
            fileSize: 0,
            imageMd5: '',
            initWidth: 800,
            imageWidth: 750,
            initHeight: 800,
            materialId: 0,
            paddingTop: 0,
            imageHeight: 750,
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0,
            pureImageUrl: ''
          }
        },
        {
          id: `topslider_${new Date().getTime()}_2`,
          componentItem: {
            type: '41',
            fileSize: 0,
            imageMd5: '',
            initWidth: 800,
            imageWidth: 750,
            initHeight: 800,
            materialId: 0,
            paddingTop: 0,
            imageHeight: 750,
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0,
            pureImageUrl: ''
          }
        }
      ]
    }
  },

  // topvideo: {
  //   BitRate: '',
  //   adLocation: 'sns',
  //   displayHeight: '421',
  //   displayType: '0',
  //   displayWidth: '750',
  //   fileSize: '',
  //   forbiddenAutoPlay: '0',
  //   forbiddenControlView: '0',
  //   forbiddenPreload: '0',
  //   forceLandscapeWhenTurnToFullScreen: '0',
  //   id: `widget_${new Date().getTime()}_0`,
  //   initHeight: '720',
  //   initWidth: '1280',
  //   materialId: '',
  //   name: '顶部视频',
  //   outerUseTopMaterial: '0',
  //   paddingBottom: '0',
  //   paddingLeft: '0',
  //   paddingRight: '0',
  //   paddingTop: '0',
  //   preloadRatio: '60',
  //   styleType: 0,
  //   thumbMd5: '',
  //   thumbUrl: '',
  //   type: '142',
  //   videoDuration: '25',
  //   videoMd5: '',
  //   videoUrl: '',
  //   viewType: '0',
  //   widgetType: 'top',
  //   widgetTypeV2: 'topvideo',
  //   component: 'm-topVideo'
  // },

  topvideo: {
    widgetTypeV2: 'topvideo',
    widgetType: 'top',
    name: '顶部视频',
    type: '61',
    viewType: '0',
    styleType: 0,
    outerUseTopMaterial: '0',
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
    paddingLeft: 0,
    sightDisplayWidth: 750,
    sightDisplayHeight: 421,
    multipleSwitch: false, // 多视频开关
    videoList: [], // 多视频数据
    // displayHeight: 421,
    // displayWidth: 750,
    initWidth: 750,
    initHeight: 420,
    sightDisplayType: '0',
    fileSize: 0,
    materialId: 0,
    videoDuration: 16,
    sightThumbMd5: '',
    sightVideoMd5: '',
    BitRate: 0,
    originalMaterialId: 0,
    component: 'm-topVideo',

    id: `widget_${new Date().getTime()}_0`,
    styleHeightType: 1536,
    // 广告位， 默认朋友圈信息流，  公众号及其他暂时不显示
    adLocation: 'sns', // sns/gh
    // 选择朋友圈信息流,添加到以下数据里
    sightThumbUrl: '',
    sightVideoUrl: ''
  },

  img: {
    id: `widget_${new Date().getTime()}_0`,
    widgetTypeV2: 'img',
    widgetType: 'image',
    component: 'm-img',
    name: '图片',
    imageId: '',
    paddingTop: 0,
    paddingBottom: 0,
    pureImageUrl: '',
    height: '',
    width: '',
    type: '41',
    fileSize: 0,
    imageMd5: '',
    initWidth: 750,
    imageWidth: 750,
    initHeight: 422,
    materialId: 0,
    imageHeight: 422,
    paddingLeft: 0,
    paddingRight: 0
  },

  slider: {
    widgetType: 'top',
    widgetTypeV2: 'topslider',
    component: 'm-slider',
    type: '101',
    name: '轮播图',
    id: `widget_${new Date().getTime()}_0`,
    componentCount: '3',
    activeIndex: 0,
    pageCtrType: '1',
    isFullScreen: '0',
    componentGroupList: {
      componentGroup: [
        {
          id: `slider_${new Date().getTime()}_0`,
          componentItem: {
            type: '41',
            pureImageUrl: '',
            imageHeight: '',
            imageWidth: '',
            initWidth: '',
            initHeight: '',
            fileSize: 0,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            materialId: 0,
            imageMd5: ''
          }
        },
        {
          id: `slider_${new Date().getTime()}_1`,
          componentItem: {
            type: '41',
            pureImageUrl: '',
            imageHeight: '',
            imageWidth: '',
            initWidth: '',
            initHeight: '',
            fileSize: 0,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            materialId: 0,
            imageMd5: ''
          }
        },
        {
          id: `slider_${new Date().getTime()}_2`,
          componentItem: {
            type: '41',
            pureImageUrl: '',
            imageHeight: '',
            imageWidth: '',
            initWidth: '',
            initHeight: '',
            fileSize: 0,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            materialId: 0,
            imageMd5: ''
          }
        }
      ],
      // 102
      componentItem: [
        {
          id: `slider_${new Date().getTime()}_0`,
          type: '41',
          pureImageUrl: '',
          imageHeight: '',
          imageWidth: '',
          initWidth: '',
          initHeight: '',
          fileSize: 0,
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          materialId: 0,
          imageMd5: ''
        },
        {
          id: `slider_${new Date().getTime()}_1`,
          type: '41',
          pureImageUrl: '',
          imageHeight: '',
          imageWidth: '',
          initWidth: '',
          initHeight: '',
          fileSize: 0,
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          materialId: 0,
          imageMd5: ''
        },
        {
          id: `slider_${new Date().getTime()}_2`,
          type: '41',
          pureImageUrl: '',
          imageHeight: '',
          imageWidth: '',
          initWidth: '',
          initHeight: '',
          fileSize: 0,
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          materialId: 0,
          imageMd5: ''
        }
      ]
    }
  },

  video: {
    widgetTypeV2: 'video',
    widgetType: 'video',
    component: 'm-video',
    name: '视频',
    type: '61', // 长视频62
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
    paddingLeft: 0,
    id: `widget_${new Date().getTime()}_0`,
    initWidth: 750,
    initHeight: 420,
    sightDisplayWidth: 750,
    sightDisplayHeight: 420,
    sightThumbUrl: '',
    sightVideoUrl: '',
    sightDisplayType: '0',
    fileSize: 0,
    isFullScreen: 0,
    materialId: 0,
    videoDuration: 16,
    sightThumbMd5: '',
    sightVideoMd5: '',
    BitRate: 0,
    originalMaterialId: 0
  },

  text: {
    id: `widget_${new Date().getTime()}_0`,
    name: '文本',
    type: '1',
    content: '', // 类容
    expand: false,
    fontSize: 15,
    showType: '0', // '0'常规, '1'加粗
    fontColor: '#595959',
    paddingTop: 22,
    widgetTypeV2: 'text',
    widgetType: 'text',
    paddingLeft: 24,
    paddingRight: 24,
    component: 'm-text',
    paddingBottom: 22,
    textAlignment: '0' // 对齐方式
  },

  // 关注公众号
  copyGh: {
    id: `widget_${new Date().getTime()}_0`,
    widgetTypeV2: 'gh',
    widgetType: 'button',
    type: '21',
    name: '关注公众号',
    btnTitle: '关注公众号',
    subType: '17',
    fontSize: '30',
    fontColor: '#FFFFFF',
    component: 'm-copyGh',
    btnType: '0',
    borderSize: '0',
    btnHeight: '80',
    btnBgColorTheme: '#07C160',
    btnBorderColorTheme: '#FFFFFF',
    btnFontType: '0',
    btnStyle: '1',

    paddingTop: 28,
    paddingBottom: 28,
    paddingRight: 185,
    paddingLeft: 185,
    cornerRadius: 4,
    useIcon: '0'
  },

  // 跳转小程序
  applet: {
    widgetTypeV2: 'applet',
    component: 'm-applet',
    type: 'button',
    name: '跳转小程序',
    btnTitle: '关注', // 按钮文案
    btnFontType: '0', // 按钮外观 -> 按钮文案 '0'常规, '1'加粗
    backgroundColor: '#FFFFFF', // 组件底色
    btnFontColor: '#FFFFFF', // 按钮外观 -> 字体色
    btnBorderColorTheme: '#ffffff', // 按钮外观 -> 边框色
    btnBgColorTheme: '#07C160', // 按钮外观 -> 填充色
    paddingBottom: 0,
    paddingTop: 0,
    urlList: []
  },

  // 添加商家微信
  enterprise_wx_auto: {
    id: `widget_${new Date().getTime()}_0`,
    name: '添加商家微信',
    type: '21',
    qrUrl: '',
    corpid: '',
    btnType: '0',
    groupid: 197520,
    subType: '15',
    useIcon: '0',
    btnStyle: '1',
    btnTitle: '联系商家',
    fontSize: '30',
    btnHeight: '80',
    fontColor: '#FFFFFF',
    qrExtInfo: '',
    borderSize: '0',
    btnJumpUrl: '',
    mpJumpType: '1',
    paddingTop: 28,
    widgetType: 'button',
    btnFontType: '0',
    paddingLeft: 185,
    serviceType: '1',
    cornerRadius: 4,
    h5_config_id: '',
    paddingRight: 185,
    widgetTypeV2: 'enterprise_wx_auto',
    chatGroupName: '1028测试3',
    paddingBottom: 28,
    origBtnJumpUrl: '',
    btnBgColorTheme: '#07C160',
    needUpdateQrUrl: 0,
    wxapp_config_id: '',
    btnBorderColorTheme: '#FFFFFF',
    component: 'm-enterpriseWxAuto'
  },

  // 悬浮组件
  floatbutton: {
    widgetTypeV2: 'floatbutton',
    widgetType: 'float_button',
    type: '134',
    name: '悬浮组件',
    wxad_styleType: '3',
    onlyShowInTimelineAd: '0',
    backgroundImg: '',
    backgroundColor: '#F0F0F0',
    backgroundColorAlpha: '0.96',
    backgroundBlurEffect: '1',
    backgroundBlurEffectColor: '#F0F0F0',
    backgroundBlurEffectColorAlpha: '0.5',

    titleColor: '#171717',
    titleColorAlpha: '1',
    descColor: '#4c4c4c',
    descColorAlpha: '0.5',
    imageMd5: '',
    materialId: '',
    title: '标题',
    desc: '描述',
    cardType: 0,
    pureImageUrl: '',

    isFullClickable: '1',
    appearPaddingTop: '0',
    appearPaddingBottom: '0',
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
    paddingLeft: 0,
    component: 'm-floatButton',
    id: `widget_${new Date().getTime()}_0`,
    componentItem: {
      widgetTypeV2: 'enterprise_wx_auto',
      widgetType: 'button',
      type: '21',
      name: '添加商家微信',
      btnTitle: '联系商家',

      subType: '15',
      fontSize: '28',
      fontColor: '#FFFFFF',
      btnType: '0',
      btnBgColorTheme: '#07C160',
      btnBorderColorTheme: '#FFFFFF',
      btnFontType: '0',
      btnStyle: '1',
      cornerRadius: '4',
      useIcon: '0',
      mpJumpType: '1',
      origBtnJumpUrl: '',
      btnJumpUrl: '',
      corpid: '',
      groupid: 0,
      h5_config_id: '',
      wxapp_config_id: '',
      qrUrl: '',
      needUpdateQrUrl: 0,
      qrExtInfo: ' ',
      serviceType: '1',
      id: `widget_${new Date().getTime()}_0`,
      borderSize: '0',
      layoutWidth: '160',
      layoutHeight: '70',
      chatGroupName: ''
    }
  },

  // 图文复合组件
  shelfnew: {
    id: `widget_${new Date().getTime()}_0`,
    widgetType: 'shelfnew',
    widgetTypeV2: 'shelfnew',
    layoutWidth: '670',
    layoutHeight: '228',
    paddingLeft: '40',
    paddingRight: '40',
    paddingTop: 20,
    paddingBottom: 20,
    cornerRadius: 8,
    isChooseJump: 1,
    name: '图文复合组件1',
    type: '104',
    bgColor: '#ffffff',
    borderSize: 1,
    borderColor: '#e5e5e5',
    component: 'm-shelfNew',
    layoutItems: {
      componentItem: JSON.parse(JSON.stringify(shelfnew_one))
    }
  }
}
