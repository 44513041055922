var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.isEdit ? "编 辑" : "新 增",
        width: "45%",
        visible: _vm.visible,
        "before-close": _vm.close,
        "label-position": "right",
        "modal-append-to-body": false,
        "close-on-press-escape": false,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ent",
          attrs: { model: _vm.ent, rules: _vm.rules, "label-width": "110px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "publisBaiduId" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.publisBaiduId,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "publisBaiduId", $$v)
                  },
                  expression: "ent.publisBaiduId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "模板计划名称", prop: "templatePlanName" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.templatePlanName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "templatePlanName", $$v)
                  },
                  expression: "ent.templatePlanName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "投放平台", prop: "launchPlatform" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.launchPlatform,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "launchPlatform", $$v)
                  },
                  expression: "ent.launchPlatform",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "housekeeperId" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.housekeeperId,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "housekeeperId", $$v)
                  },
                  expression: "ent.housekeeperId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "账户名称", prop: "launchAccountName" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.launchAccountName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "launchAccountName", $$v)
                  },
                  expression: "ent.launchAccountName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "baiduId" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.baiduId,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "baiduId", $$v)
                  },
                  expression: "ent.baiduId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "账号名称", prop: "accountName" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.accountName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "accountName", $$v)
                  },
                  expression: "ent.accountName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "发布状态", prop: "publishStatus" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.publishStatus,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "publishStatus", $$v)
                  },
                  expression: "ent.publishStatus",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "投放状态", prop: "launcheStatus" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.launcheStatus,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "launcheStatus", $$v)
                  },
                  expression: "ent.launcheStatus",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "计划id", prop: "planId" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.planId,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "planId", $$v)
                  },
                  expression: "ent.planId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "单元id", prop: "unitId" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.unitId,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "unitId", $$v)
                  },
                  expression: "ent.unitId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "创意id", prop: "creativeId" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.creativeId,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "creativeId", $$v)
                  },
                  expression: "ent.creativeId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "逻辑删除", prop: "deleted" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.deleted,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "deleted", $$v)
                  },
                  expression: "ent.deleted",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "创建者", prop: "createBy" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.createBy,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "createBy", $$v)
                  },
                  expression: "ent.createBy",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "创建时间", prop: "createTime" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.createTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "createTime", $$v)
                  },
                  expression: "ent.createTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "更新者", prop: "updateBy" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.updateBy,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "updateBy", $$v)
                  },
                  expression: "ent.updateBy",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "更新时间", prop: "updateTime" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.updateTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "updateTime", $$v)
                  },
                  expression: "ent.updateTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "remark", $$v)
                  },
                  expression: "ent.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { attrs: { round: "" }, on: { click: _vm.close } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", round: "" },
              on: { click: _vm.submit },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }