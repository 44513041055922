var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "table-template",
      attrs: {
        title: "落地页链接",
        visible: _vm.show,
        "append-to-body": true,
        width: "600px",
        top: "20vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c("div", { staticClass: "page-container" }, [
        _c(
          "div",
          { staticClass: "link-box" },
          [
            _c("span", [_vm._v("落地页URL：")]),
            _c(
              "div",
              { staticClass: "url-text" },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: _vm.path,
                      placement: "top-start",
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.path))])]
                ),
              ],
              1
            ),
            _c(
              "el-button",
              {
                attrs: {
                  type: "text",
                  id: "copyBtn",
                  "data-clipboard-text": _vm.path,
                },
                on: { click: _vm.copyLink },
              },
              [_vm._v("复制")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "btn", on: { click: _vm.closeModal } },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }