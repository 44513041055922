var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "规则配置",
        visible: _vm.ruleDeployVisible,
        width: "650px",
        "modal-append-to-body": false,
        "before-close": _vm.handleClose,
      },
    },
    [
      _c(
        "span",
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: { model: _vm.ruleForm, "label-width": "140px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "使用已有计划：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.usePlanStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "usePlanStatus", $$v)
                        },
                        expression: "ruleForm.usePlanStatus",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("是"),
                      ]),
                      _c(
                        "el-radio",
                        { attrs: { label: false, disabled: "" } },
                        [_vm._v("否")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "复用已有广告：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.useAdStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "useAdStatus", $$v)
                        },
                        expression: "ruleForm.useAdStatus",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("是"),
                      ]),
                      _c(
                        "el-radio",
                        { attrs: { label: false, disabled: "" } },
                        [_vm._v("否")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "素材文案组合方式：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.taskCombine,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "taskCombine", $$v)
                        },
                        expression: "ruleForm.taskCombine",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("全量组合"),
                      ]),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("指定组合"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "广告默认状态" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ruleForm.configuredStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "configuredStatus", $$v)
                        },
                        expression: "ruleForm.configuredStatus",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "AD_STATUS_NORMAL" } }, [
                        _vm._v("开启"),
                      ]),
                      _c(
                        "el-radio",
                        { attrs: { label: "AD_STATUS_SUSPEND" } },
                        [_vm._v("暂停")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }