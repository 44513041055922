<!--表格空数据时显示-->
<template>
  <!--空数据提示-->
  <div class="empty-data">
    <div class="tip">{{ tip }}</div>
  </div>
</template>

<script>
export default {
  name: 'DataEmpty',
  props: {
    tip: {
      type: String,
      default: '暂无数据'
    }
  },
  data () {
    return {}
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/style/mixin";
.empty-data {
  @include flex-vertical-center;

  .icon {
    width: 180px;
    height: 204px;
  }

  .tip {
    text-align: center;
    font-size: 16px;
    font-family: "Microsoft YaHei";
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: 24px;
  }
}
</style>
