var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20, type: "flex", justify: "space-between" } },
        [
          _c(
            "el-col",
            [
              _c(
                "el-form",
                {
                  ref: "formInline",
                  attrs: {
                    inline: true,
                    model: _vm.formInline,
                    "label-width": "100px",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "落地页名称:", prop: "canvasName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        model: {
                          value: _vm.formInline.canvasName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "canvasName", $$v)
                          },
                          expression: "formInline.canvasName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分享标题:", prop: "shareTitle" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { maxlength: "14", "show-word-limit": "" },
                        model: {
                          value: _vm.formInline.shareTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "shareTitle", $$v)
                          },
                          expression: "formInline.shareTitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分享描述:", prop: "shareDesc" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { maxlength: "20", "show-word-limit": "" },
                        model: {
                          value: _vm.formInline.shareDesc,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "shareDesc", $$v)
                          },
                          expression: "formInline.shareDesc",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-dropdown",
                    { staticClass: "ml-20 mr-20" },
                    [
                      _c("div", {
                        staticClass: "ldy-bg-color",
                        style: `background:${_vm.formInline.colors}`,
                      }),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _c("twitter-color", {
                            staticStyle: { "margin-top": "10px" },
                            attrs: { colors: _vm.formInline.colors },
                            on: {
                              change: (value) =>
                                (_vm.formInline.colors = value),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.paramsId
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.add_visible = true
                                },
                              },
                            },
                            [_vm._v(" 修改项目产品")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 2 } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("提交并保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-divider", { staticStyle: { margin: "-12px" } }),
      _c(
        "el-row",
        { staticClass: "row-box", attrs: { gutter: 40 } },
        [
          _c("el-col", { attrs: { span: 8 } }, [
            _c(
              "div",
              { staticClass: "grid-content bg-purple" },
              [
                _c("WidgetGallery", {
                  attrs: {
                    "select-widgets-index": _vm.selectWidgetsIndex,
                    "final-widgets": _vm.finalWidgets,
                    "final-top-widgets": _vm.finalTopWidgets,
                    "top-widgets-disable": _vm.topWidgetsDisable,
                  },
                  on: {
                    setFinalWidgets: _vm.setFinalWidgets,
                    setFinalTopWidgets: _vm.setFinalTopWidgets,
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "el-col",
            {
              staticStyle: {
                width: "415px",
                "margin-right": "60px",
                position: "relative",
                height: "720px",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "grid-content bg-purple workbench",
                  style: `background:${_vm.formInline.colors}`,
                },
                [
                  _c("Workbench", {
                    attrs: {
                      "final-widgets": _vm.finalWidgets,
                      "final-top-widgets": _vm.finalTopWidgets,
                      "select-widgets-index": _vm.selectWidgetsIndex,
                    },
                    on: {
                      setFinalTopWidgets: function ($event) {
                        _vm.finalTopWidgets = {}
                      },
                      cancelTopWidgetsDisable: function ($event) {
                        _vm.topWidgetsDisable = false
                      },
                      setFinalWidgetsCount: _vm.setFinalWidgetsCount,
                      setSelectedIndex: _vm.setSelectedIndex,
                      updateFinalWidgets: _vm.updateFinalWidgets,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c("el-col", { attrs: { span: 8 } }, [
            _c(
              "div",
              { staticClass: "grid-content bg-purple" },
              [
                _c("AdEditors", {
                  attrs: {
                    "final-widgets": _vm.finalWidgets,
                    "final-top-widgets": _vm.finalTopWidgets,
                    "select-widgets-index": _vm.selectWidgetsIndex,
                  },
                  on: { setSelectedIndex: _vm.setSelectedIndex },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择项目产品",
            visible: _vm.add_visible,
            width: "620px",
            "modal-append-to-body": false,
            "show-close": false,
            "destroy-on-close": "",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formInline1",
              staticClass: "demo-ruleForm",
              attrs: { model: _vm.formInline1, "label-width": "100px" },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "项目:",
                    prop: "projectId",
                    rules: {
                      required: true,
                      message: "请选择关联项目",
                      trigger: "change",
                    },
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "400px" },
                      attrs: {
                        filterable: "",
                        clearable: "",
                        placeholder: "请选择关联项目",
                      },
                      on: { change: (value) => (_vm.selectProjectId = value) },
                      model: {
                        value: _vm.formInline1.projectId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline1, "projectId", $$v)
                        },
                        expression: "formInline1.projectId",
                      },
                    },
                    _vm._l(_vm.projectList, function (itm, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: {
                          label: itm.projectName,
                          value: itm.bsProjectId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "关联产品:" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "400px" },
                      attrs: { placeholder: "请选择产品" },
                      model: {
                        value: _vm.formInline1.productId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline1, "productId", $$v)
                        },
                        expression: "formInline1.productId",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "不限", value: 0 } }),
                      _vm._l(_vm.productListed, function (itm, idx) {
                        return _c("el-option", {
                          key: idx,
                          attrs: {
                            label: itm.productName,
                            value: itm.bsProductId,
                          },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: !_vm.formInline1.projectId,
                  },
                  on: {
                    click: function ($event) {
                      _vm.add_visible = false
                    },
                  },
                },
                [_vm._v("确定")]
              ),
              _vm.paramsId
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.add_visible = false
                        },
                      },
                    },
                    [_vm._v(" 关闭 ")]
                  )
                : _c(
                    "router-link",
                    { attrs: { to: "/wechatLdy", tag: "span" } },
                    [
                      _c("el-button", { staticClass: "ml-20" }, [
                        _vm._v(" 返回 "),
                      ]),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }