/*
 * @Description: Do not edit
 * @Date: 2021-10-13 16:03:30
 * @LastEditTime: 2021-10-29 10:00:34
 */
import service from '@/network/service/service'

export function Page(data) {
  return service.post('/api/system/assetManagementHugeConvert/page', data)
}

export function PageApp(data) {
  return service.post('/api/system/assetManagementHugeConvert/pageApp', data)
}

export function Add(data) {
  return service.post('/api/system/assetManagementHugeConvert/save', data)
}

export function Update(data) {
  return service.post('/api/system/assetManagementHugeConvert/update', data)
}

export function Delete(id) {
  return service.delete('/api/system/assetManagementHugeConvert/' + id)
}

export function ActivationApi(params) {
  return service.get('/api/system/assetManagementHugeConvert/activation', { params })
}

export function publish(params) {
  return service.get(`/api/system/assetManagementHugeConvert/publish/${params.accountId}/${params.GGZaccountId + ''}/${params.convertId}`)
}

export function Synchronization(params) {
  return service.get(`/api/system/assetManagementHugeConvert/synchronization/${params.accountId}/${params.GGZaccountId + ''}`)
}
