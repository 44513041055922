/*
 * @Description: Do not edit
 * @Date: 2021-10-05 13:41:50
 * @LastEditTime: 2024-05-29 18:12:43
 */
import service from '@/network/service/service'

export function Page(data) {
  return service.post('/api/system/baidu/account/accountPage', data)
}

export function Add(data) {
  return service.get('/api/system/baidu/account/getLpOauthUrl', data)
  // return service.post('/api/system/accountBaiduAdv/save', data)
}

export function Update(data) {
  return service.post('/api/system//baidu/account/updateAccount', data)
  // return service.post('/api/system/accountBaiduAdv/update', data)
}

export function Delete(id) {
  return service.delete('/api/system/accountBaiduAdv/' + id)
}

export function getAccountByHouseId(id) {
  return service.get('/api/system/accountBaiduAdv/getAccountByHouseId/' + id)
}
export function getAccountByHouge(id) {
  return service.get('/api/system/accountBaiduAdv/getAccountByHouge/' + id)
}

/**
 * @returns 获取百度管家账号
 */
export function getHouseKeeper(data) {
  // return service.get('/api/system/accountBaiduHousekeeper/getHouseKeeper')
  return service.post('/api/system/baidu/account/oauthSelect', data)
}

/**
 * @returns  通过管家id和项目id获取账户列表
 */
export function getAccountBaiduAdv({ selectHouseKeeperId, selectProjectId, selectProductId }) {
  return service.get(`/api/system/accountBaiduAdv/byHouseIdAndProjectidGet/${selectHouseKeeperId}/${selectProjectId}/${selectProductId}`)
}
