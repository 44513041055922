<template>
  <el-dialog
    :title="isEdit ? '编 辑': '新 增'"
    width="45%"
    :visible.sync="visible"
    :before-close="close"
    label-position="right"
    :modal-append-to-body="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <el-form ref="ent" :model="ent" :rules="rules" label-width="110px">

      <el-form-item label="来源名称" prop="source">
        <el-input v-model="ent.source" maxlength="30" placeholder="最大输入长度30 超出截断" />
      </el-form-item>
      <el-form-item label="是否启用" prop="isUseing">
        <el-select v-model="ent.isUseing" placeholder="请选择">
          <el-option v-for="item in isUseingList" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>

      <!-- <el-form-item label="关联项目名称" prop="relationProjectId">
        <el-select v-model="ent.relationProjectId" filterable placeholder="请选择" @change="selectChange">
          <el-option
            v-for="item in options"
            :key="item.bsProjectId"
            :label="item.projectName"
            :value="item.bsProjectId"
          />
        </el-select>
        <el-button class="ml-10" type="text" @click="projectAdd"><u>新增项目</u></el-button>
        <el-tooltip class="item" effect="dark" content="刷新关联项目选择内容" placement="top-start">
          <el-button class="ml-10" type="text" icon="el-icon-refresh" @click="refreshRest" />
        </el-tooltip>
      </el-form-item> -->
      <el-form-item label="返点比例(%)" prop="rebateRatio">
        <el-input v-model="ent.rebateRatio" type="number" max="100" placeholder="单位: %" />
      </el-form-item>

      <el-form-item label="备注" prop="remark" type="textarea">
        <el-input v-model="ent.remark" type="textarea" maxlength="100" min="0" placeholder="最大输入长度100 超出截断" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button round @click="close">取消</el-button>
      <el-popconfirm class="ml-10" confirm-button-text="提交" cancel-button-text="取消" icon="el-icon-info" icon-color="red" title="确定提交？" @confirm="submit">
        <el-button slot="reference" type="primary" round>确定</el-button>
      </el-popconfirm>
    </span>
  </el-dialog>
</template>
<script>
import {
  Add,
  Update
} from '@/network/api/basicSetting/product/basicSettingProductSource'
import { BasicSettingProductSourceEnt, formRules, isUseingList } from './object'
import {
  getProjectForSource
} from '@/network/api/basicSetting/project/basicSettingProject'

export default {
  inject: ['loadData', 'showAddProject'],
  data() {
    return {
      ent: new BasicSettingProductSourceEnt(),
      rules: formRules,
      isEdit: false,
      visible: false,
      cityList: [],
      isUseingList: isUseingList,
      options: []
    }
  },
  mounted() {
    this.getProject()
  },
  methods: {
    refreshRest() {
      this.getProject()
    },
    submit() {
      this.$refs.ent.validate((validate) => {
        if (validate) {
          this.isEdit ? this.updateRow() : this.addRow()
        }
      })
    },
    selectChange(val) {
      var arr2 = this.options.filter(w => w.bsProjectId === val)
      if (arr2.length > 0) {
        this.ent.relationProjectName = arr2[0].projectName
      }
    },
    getProject() {
      getProjectForSource().then(x => {
        if (x.code === 200) {
          this.options = x.data
        }
      })
    },
    projectAdd() {
      // this.close()
      this.showAddProject()
    },
    addRow() {
      const req = { ...this.ent }
      Add(req).then(res => {
        if (res.code === 200) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.loadData()
          this.close()
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    updateRow() {
      const req = { ...this.ent }
      Update(req).then(res => {
        if (res.code === 200) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.loadData()
          this.close()
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    close() {
      this.visible = false
      this.$nextTick(() => {
        this.$refs.ent.resetFields()
      })
    },
    show(isEdit = false, ent) {
      if (!isEdit) {
        ent.isUseing = 'ISUSEING'
      }
      this.ent = ent
      this.isEdit = isEdit

      this.visible = true
      this.$nextTick(() => {
        if (this.isEdit) {
          console.log('不能为空')
        }
      })
    }
  }
}
</script>
