/*
 * @Description: Do not edit
 * @Date: 2021-09-15 09:48:06
 * @LastEditTime: 2022-05-19 16:44:33
 */
import axios from 'axios'
import { MessageBox, Message } from 'element-ui'

const service = axios.create({
  // baseURL: 'http://test.ad-frontend.mokamrp.com/',
  timeout: 900000
})

// 发起请求前的处理
service.interceptors.request.use(
  (config) => {
    config.headers['Cache-Control'] = 'no-cache'
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
service.defaults.withCredentials = true

// 收到响应的处理
service.interceptors.response.use(
  (response) => {
    if (response.headers['content-disposition']) {
      return response
    }
    if (response.status === 200) {
      if (response.data.code === 200) {
        return response.data
      } else {
        Message({
          type: 'error',
          message: response.data.msg || response.data.message,
          showClose: true
        })
      }
    } else {
      return Promise.reject(response.data)
    }
  },
  (error) => {
    if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') > -1) {
      return Promise.reject(new Error('请求超时'))
    }
    const err = error.response.data || {}
    const status = error.response.status
    let errMsg = ''
    if (err.errors) {
      const errArr = []
      for (const k in err.errors) {
        errArr.push(err.errors[k].toString())
      }
      errMsg = errArr.toString()
    } else {
      errMsg = err.msg || err.message || '获取失败'
    }

    switch (status) {
      case 401:
        MessageBox.alert('认证失效，请重新登录', '', {
          confirmButtonText: '确定',
          type: 'warning',
          showClose: false,
          center: true,
          callback: () => {
            const _url = window.location.origin
            window.location.href = error.response.data.loginPageUrl + '?redirect_url=' + _url
          }
        })
        break
      default:
        if (!errMsg) {
          errMsg = '当前网络环境较差，请刷新重试'
        }
        return Promise.reject({ msg: errMsg })
    }
  }
)

export default service
