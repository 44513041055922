<template>
  <div class="region-content">
    <el-cascader
      ref="businessDistrictCascader"
      v-model="district.regionValue"
      style="width: 400px;"
      :props="{label: 'name', value: 'id', emitPath: false}"
      :options="district.region"
      filterable
      @change="handleSelectRegion"
    ></el-cascader>
    <div class="region-panel-box">
      <div class="region-panel">
        <div class="header">商圈</div>
        <div class="region-panel-content">
          <div v-if="district.businessData.length" class="panel-item">
            <el-checkbox
              v-model="districtCheckAll"
              :indeterminate="isIndeterminate"
              @change="handleCheckAllChange"
            >全选</el-checkbox>
          </div>
          <el-empty v-else :image-size="60" description="暂无数据"></el-empty>
          <div v-for="(item, index) in district.businessData" :key="index" class="panel-item">
            <el-checkbox v-model="item.isChecked" :checked="item.isChecked" @change="e => handleCheckedBusinessChange(e, item)">{{ item.name }}</el-checkbox>
          </div>
        </div>
      </div>
      <div class="region-panel selected">
        <div class="header">
          <span class="header-title">已选</span>
          <el-link v-if="district.selectedBusiness.length" type="primary" @click="handleClearSelect">清空</el-link>
        </div>
        <div class="region-panel-content">
          <div v-for="(item, index) in district.selectedBusiness" :key="index" class="panel-item panel-item-remove">
            {{ item.name }}
            <span class="icon-remove" @click="handleDeleteBusiness(item, index)"><el-icon class=" el-icon-close"></el-icon></span>
          </div>
          <el-empty v-if="!district.selectedBusiness.length" :image-size="60" description="暂无选择"></el-empty>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { regionSelect } from '@/network/api/massiveAd/massiveAd'

export default {
  name: 'BusinessDistrictComp',
  components: {},
  filters: {},
  props: {},
  data() {
    return {
      isIndeterminate: false, // 全选半角状态
      districtCheckAll: false, // 是否全选
      district: {
        region: [], // 省市数据
        list: {}, // 各省市下的商圈列表
        businessData: [], // 商圈数据
        regionValue: '', // 当前选中商圈区域
        selectedBusiness: [] // 选中的商圈
      }
    }
  },
  computed: {
    businessIds() {
      let ids = this.district.selectedBusiness.map(v => v.id)
      return ids || []
    }
  },
  watch: {
    'district.businessData': {
      handler(value) {
        if (!value.length) {
          this.districtCheckAll = false
          this.isIndeterminate = false
          return
        }
        let checkedIds = value.filter(v => v.isChecked === true)
        if (value.length === checkedIds.length) {
          this.districtCheckAll = true
          this.isIndeterminate = false
        } else {
          this.districtCheckAll = false
          if (checkedIds.length) {
            this.isIndeterminate = true
          } else {
            this.isIndeterminate = false
          }
        }
      },
      deep: true
    }
  },
  created() {
    this.getRegionSelect()
  },
  mounted() {
  },
  methods: {
    // 获取商圈数据
    getRegionSelect() {
      regionSelect().then(res => {
        if (res.code === 200) {
          let data = res.data

          let arr = []
          let obj = {}
          let list = {}
          data.forEach(item => {
            obj = {
              id: item.id,
              name: item.name,
              parentId: item.parentId,
              regionLevel: item.regionLevel,
              children: [] // 省市数据
            }
            this.getBusinessRegion(item, list, obj.children)
            arr.push(obj)
          })

          this.district.list = list
          this.district.region = arr
        }
      })
    },
    // 商圈地区下拉数据
    getBusinessRegion(item, list, children) {
      if (item.regionLevel === 'REGION_LEVEL_CITY') {
        // 市级对应商圈
        list[item.id] = item.children
        delete item.children
        children.push(item)
      } else {
        item.children && item.children.forEach(v => {
          this.getBusinessRegion(v, list, children)
        })
      }
    },

    // 按商圈-选择省市
    handleSelectRegion(value) {
      let list = this.district.list[value] || []
      let arr = list.map(v => {
        if (this.businessIds.includes(v.id)) {
          this.$set(v, 'isChecked', true)
        } else {
          this.$set(v, 'isChecked', false)
        }
        return v
      })
      this.district.businessData = arr
    },

    // 选择商圈
    handleCheckedBusinessChange(val, item, index) {
      if (val) {
        this.district.selectedBusiness.push(item)
      } else {
        let list = this.district.selectedBusiness
        list.forEach((v, i) => {
          if (v.id === item.id) {
            list.splice(i, 1)
            this.district.selectedBusiness = list
            return
          }
        })
      }
    },

    // 删除选中商圈
    handleDeleteBusiness(item, index) {
      let list = this.district.selectedBusiness
      let business = this.district.businessData.map(v => v.id)
      list.splice(index, 1)
      this.district.selectedBusiness = list
      if (business.includes(item.id)) {
        let arr = this.district.businessData.map(v => {
          if (v.id === item.id) {
            this.$set(v, 'isChecked', false)
          }
          return v
        })
        this.district.businessData = arr
      }
    },

    // 清空已选择
    handleClearSelect() {
      this.district.selectedBusiness = []
      let list = this.district.businessData
      let arr = list.map(v => {
        this.$set(v, 'isChecked', false)
        return v
      })
      this.district.businessData = arr
    },

    /**
     * 回显选中数据
     * params data Object
     * data.selected Array
     * */
    _setBusinessList(data) {
      this.district.selectedBusiness = data || []
    },

    // 获取选中数据
    _getBusinessList() {
      return this.district.selectedBusiness
    },

    // 点击商圈全选
    handleCheckAllChange(val) {
      if (val) { // 全选
        let arr = this.district.businessData.map((v, i) => {
          this.$set(v, 'isChecked', true)
          if (!this.businessIds.includes(v.id)) {
            this.district.selectedBusiness.push(v)
          }
          return v
        })
        this.district.businessData = arr
      } else { // 取消全选
        let arr = this.district.businessData.map((v, i) => {
          this.$set(v, 'isChecked', false)
          return v
        })
        this.removeCheckAll(this.district.selectedBusiness, this.district.businessData)
        this.district.businessData = arr
      }
    },
    removeCheckAll(arr1, arr2) {
      for (let i = 0; i < arr2.length; i++) {
        for (let j = 0; j < arr1.length; j++) {
          if (arr2[i].id === arr1[j].id) {
            let indexs = arr1.indexOf(arr1[j])
            arr1.splice(indexs, 1)
          }
        }
      }
      return arr1
    }
  }
}
</script>

<style scoped lang="scss">
    .region-content{
        margin-left: 90px;
        margin-bottom: 20px;
        .region-panel-box{
            display: flex;
          margin-bottom: 20px;
        }
        .region-panel{
            border: 1px solid #e0e0e0;
            box-sizing: border-box;
            width: 400px;
            margin-top: 10px;
            &.selected{
                width: 200px;
                margin-left: 16px;
            }
            .region-panel-content{
                height: 192px;
                overflow-y: auto;
            }
            .header{
                border-bottom: 1px solid #e0e0e0;
                box-sizing: border-box;
                background-color: #fafafa;
                padding: 8px 12px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              color: #333;
              font-size: 14px;
              font-weight: 500;
            }
            .panel-item{
                padding: 6px 12px;
                position: relative;
                cursor: pointer;
                display: block;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                line-height: 22px;
                font-size: 14px;
                color: #333;
                font-weight: 400;
                &:hover {
                    background-color: #f0f0f0;
                }
            }
          .panel-item-remove{
            margin: 8px 12px;
            padding: 2px 8px;
            background-color: #f0f0f0;
            border-radius: 4px;
            position: relative;
            .icon-remove{
              font-size: 14px;
              position: absolute;
              right: 8px;
              top: 2px;
              cursor: pointer;
              padding: 0 4px;
            }
          }
        }

    }
</style>
