var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "details-box" }, [
    _c(
      "div",
      { staticClass: "flex-between", staticStyle: { "margin-bottom": "20px" } },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary", disabled: !_vm.list.length },
            on: { click: _vm.handleSyncStatus },
          },
          [_vm._v("同步状态")]
        ),
        _c(
          "div",
          { staticClass: "flex-end" },
          [
            _vm._v("执行时间 "),
            _c("el-date-picker", {
              staticStyle: { width: "300px", "margin-left": "15px" },
              attrs: {
                type: "daterange",
                clearable: false,
                "prange-separator": "至",
                "start-placeholder": "开始时间",
                "value-format": "yyyy-MM-dd",
                "end-placeholder": "结束时间",
              },
              on: { change: _vm.handleChange },
              model: {
                value: _vm.execDate,
                callback: function ($$v) {
                  _vm.execDate = $$v
                },
                expression: "execDate",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
      },
      [
        Object.keys(_vm.list).length > 0
          ? _c(
              "el-collapse",
              {
                model: {
                  value: _vm.activeCollapse,
                  callback: function ($$v) {
                    _vm.activeCollapse = $$v
                  },
                  expression: "activeCollapse",
                },
              },
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "el-collapse-item",
                  { key: index, attrs: { name: "任务" + index } },
                  [
                    _c("template", { slot: "title" }, [
                      _c("div", { staticClass: "detail-collapse-header" }, [
                        _c("span", [_vm._v(_vm._s(item.date))]),
                        _c("span", [_vm._v(_vm._s(item.status))]),
                      ]),
                    ]),
                    _c(
                      "el-descriptions",
                      {
                        staticClass: "padding-cls",
                        attrs: { title: "", column: 4 },
                      },
                      [
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "任务名称" } },
                          [_vm._v(_vm._s(_vm.taskInfo.taskName))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "DMP账户" } },
                          [_vm._v(_vm._s(_vm.taskInfo.accountId))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "文件类型" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("filterDataType")(_vm.taskInfo.dataType)
                              )
                            ),
                          ]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "数据条件" } },
                          [_vm._v(_vm._s(_vm.taskInfo.dataConditionStr || "-"))]
                        ),
                        _c(
                          "el-descriptions-item",
                          { attrs: { label: "数据时间" } },
                          [_vm._v(_vm._s(_vm.taskInfo.lifeCycle))]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "detail-list-box" },
                      [
                        _c("div", { staticClass: "flex-end detail-total" }, [
                          _vm._v(" 共 "),
                          _c("span", [_vm._v(_vm._s(item.data.length))]),
                          _vm._v(" 个，其中计算中 "),
                          _c("span", [_vm._v(_vm._s(item.computeNum))]),
                          _vm._v(" 个，可用 "),
                          _c("span", [_vm._v(_vm._s(item.canUseNum))]),
                          _vm._v(" 个，其他 "),
                          _c("span", [_vm._v(" " + _vm._s(item.otherNum))]),
                          _vm._v(" 个 "),
                        ]),
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: item.loading,
                                expression: "item.loading",
                              },
                            ],
                            staticClass: "list-table",
                            attrs: { data: item.data, height: "200" },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { type: "index", label: "序号" },
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "audienceId", label: "ID" },
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "name", label: "名称" },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "peopleCovered",
                                label: "覆盖人数",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        _vm._v(
                                          _vm._s(row.peopleCovered || "-")
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "executeHms", label: "创建时间" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        _vm._v(
                                          _vm._s(
                                            row.executeTime +
                                              " " +
                                              row.executeHms
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "updateTime", label: "更新时间" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        _vm._v(_vm._s(row.updateTime || "-")),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "processStatus", label: "状态" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        row.processStatus === "ERROR"
                                          ? _c(
                                              "el-tooltip",
                                              {
                                                attrs: {
                                                  effect: "dark",
                                                  content: row.message,
                                                  placement: "top",
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    class:
                                                      "status-" +
                                                      row.processStatus,
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("filterStatus")(
                                                          row.processStatus
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "span",
                                              {
                                                class:
                                                  "status-" + row.processStatus,
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("filterStatus")(
                                                      row.processStatus
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                )
              }),
              1
            )
          : _c("el-empty", { attrs: { description: "暂无数据" } }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "detail-footer" },
      [
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.handleBack } },
          [_vm._v("返回")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }