<!--
 * @Description: 图片编辑区
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2023-03-16 16:24:05
-->

<template>
  <section>
    <base-box name="topVideo">
      <template slot="desc">
        投放朋友圈信息流，广告外层将自动拉取顶部视频。投放公众号及其他广告位，将不会拉取。
      </template>
      <template slot="body">
        <div>
          <p class="font-13">广告位：</p>
          <div class="ml-20">
            <el-radio-group v-model="detail.adLocation" @change="handleClearVideo">
              <el-radio label="sns">朋友圈信息流</el-radio>
              <el-radio label="gh">公众号及其他</el-radio></el-radio-group>
          </div>
        </div>

        <template v-if="detail.adLocation === 'sns'">
          <div>
            <p class="font-13">外层样式：</p>
            <div class="ml-20">
              <el-radio-group v-model="detail.viewType" @change="handleClearVideo">
                <el-radio label="0">常规广告</el-radio>
                <el-radio label="1">卡片广告</el-radio></el-radio-group>
            </div>
          </div>
          <template v-if="+detail.viewType === 0">
            <div>
              <p class="font-13">视频类型：</p>
              <div class="ml-20">
                <el-radio-group v-model="detail.styleType" @change="handleClearVideo">
                  <el-radio :label="0">横版视频</el-radio>
                  <el-radio :label="1">竖版视频</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div v-if="+detail.styleType === 1">
              <p class="font-13">视频尺寸：</p>
              <div class="ml-20">
                <el-select v-model="videoSize" placeholder="" @change="handleClearVideo">
                  <el-option label="720*1280像素" :value="0"></el-option>
                  <el-option label="750*1536像素" :value="1"></el-option>
                  <el-option label="750*1334像素" :value="2"></el-option>
                </el-select>
              </div>
            </div>
          </template>

          <div>
            <p class="font-13">多视频：</p>
            <div class="ml-20">
              <el-switch v-model="detail.multipleSwitch" @change="handleClearVideo">
              </el-switch>
            </div>
          </div>
        </template>

        <div class="image-updata">
          <p class="font-13">
            <span>选择视频：</span>
          </p>
          <div>
            <div class="video-list">
              <div v-for="(item, index) in detail.videoList" :key="index" class="update">
                <video v-if="item.sightVideoUrl" ref="video" :src="item.sightVideoUrl" class="avatar" />
              </div>
              <div class="update" @click="setMaterialBox">
                <i class="el-icon-plus avatar-uploader-icon" />
              </div>
            </div>
            <p class="tip mt-10">
              视频尺寸：{{ computedVideoSize() }} <br />
              视频大小：≤100MB <br />
              视频长度：6-90秒
            </p>
          </div>
        </div>
      </template>
    </base-box>
    <MaterialVideoBox :visible="materialBox_visible" :img-count="videoLimit" :video-listed="detail.videoList" name="ldy"
      :select-type="selectType" :attribute="{
        key: 'topImg',
        value: '顶部视频',
        size: 100 * 1024 * 1024,
        listType: 'image/jpg,image/jpeg,image/png',
        ...fileAttribute
      }" :is-batch="false" :is-page="true" @setMaterialBox="setMaterialBox" @handleVideoSuccess="selectVideo" />
  </section>
</template>

<script>
import BaseBox from './base.vue'
import MaterialVideoBox from '@/components/materialBox/videoV2.vue'
import { uploadMaterial } from '@/network/api/api-material'

export default {
  components: {
    'base-box': BaseBox,
    MaterialVideoBox
  },
  props: {
    detail: {
      type: [Object],
      default: () => { }
    }
  },

  data() {
    return {
      materialBox_visible: false,
      MATERIAL_LIMIT_NUM: 20, // 素材数量
      fileAttribute: {},
      selectType: '', // 视频单选/多选
      videoListed: [], // 已选中视频数据
      // 视频可选尺寸数组
      videoSizeList: {
        // adLocation = sns && viewType = 0 && styleType = 0
        'sns00': [
          { width: 1280, height: 720 },
          { width: 1280, height: 960 }
        ],
        // adLocation = sns && viewType = 0 && styleType = 1
        'sns01': [
          { width: 720, height: 1280 },
          { width: 750, height: 1536 },
          { width: 750, height: 1334 }
        ],
        // adLocation = sns && viewType = 1
        'sns1': [
          { width: 1280, height: 720 }
        ],
        // adLocation = gh
        'gh': [
          { width: 750, height: 1536 }
        ]
      },
      videoSize: 0 // 竖版视频-视频尺寸值
    }
  },

  computed: {
    videoLimit() {
      return this.detail.adLocation === 'sns' && this.detail.multipleSwitch ? this.MATERIAL_LIMIT_NUM : 1
    }
  },

  watch: {
    detail(value) {
      if (value) {
        if (value.adLocation === 'sns' && +value.viewType === 0 && +value.styleType === 0) {
          const obj = { '1280': 0, '1536': 1, '1334': 2 }
          this.videoSize = obj[value.initHeight]
        }
      }
    }
  },

  methods: {
    /**
     * @description: 打开/关闭资源库
     */
    setMaterialBox() {
      if (!this.materialBox_visible) {
        let info = this.generateCurrentVideoSize()
        let width = null
        let height = null
        if (info.key === 'gh') {
          this.selectType = ''
          width = info.list[0].width
          height = info.list[0].height
        } else {
          this.selectType = 'multiple'
          width = []
          height = []
          if (info.key === 'sns01') {
            let item = info.list[this.videoSize]
            width = [item.width]
            height = [item.height]
          } else {
            info.list.map(v => {
              width.push(v.width)
              height.push(v.height)
            })
          }
        }

        this.fileAttribute = {
          count: this.videoLimit,
          width: width,
          height: height
        }
      }

      this.materialBox_visible = !this.materialBox_visible
    },

    generateCurrentVideoSize() {
      let { adLocation, viewType, styleType } = this.detail
      let key = ''
      if (adLocation === 'sns') {
        if (+viewType === 1) {
          key = adLocation + viewType
        } else {
          key = adLocation + viewType + styleType
        }
      } else {
        key = adLocation
      }
      let sizeArr = this.videoSizeList[key]
      return {
        key,
        list: sizeArr
      }
    },

    /**
      * @description: 生成视频尺寸
      */
    computedVideoSize() {
      let info = this.generateCurrentVideoSize()
      if (info.key) {
        let list = []
        if (info.key === 'sns01') {
          let item = info.list[this.videoSize]
          list = [item.width + '*' + item.height + '像素']
        } else {
          list = info.list.map(v => {
            let str = v.width + '*' + v.height + '像素'
            return str
          })
        }
        return list.join('/')
      } else {
        return ''
      }
    },

    /**
     * @description: 选择图片后调用
     * @param {Array} img 图片信息
     */
    selectVideo(video) {
      let { detail } = this
      console.log(video)
      let list = video.map((v, i) => {
        return {
          materialId: v.videoId,
          sightVideoUrl: v.url,
          initWidth: v.initWidth || v.width,
          initHeight: v.initHeight || v.height,
          uploadVideoImg: v.uploadVideoImg || false,
          sightThumbUrl: v.sightThumbUrl || ''
        }
      })
      detail.videoList = JSON.parse(JSON.stringify(list))
      const { initWidth, initHeight, sightVideoUrl, sightThumbUrl } = detail.videoList[0]
      detail.sightVideoUrl = sightVideoUrl
      detail.width = initWidth
      detail.height = initHeight
      detail.sightThumbUrl = sightThumbUrl
      detail.uploadVideoImg = true

      setTimeout(() => {
        detail.videoList.map(async (v, i) => {
          if (!v.sightThumbUrl) {
            this.getVideoImg(v.initWidth, v.initHeight, v.sightVideoUrl, i).then(info => {
              if (info) {
                v.sightThumbUrl = info.url
                v.uploadVideoImg = true
                detail.sightThumbUrl = info.url
              }
            })
          } else {
            detail.sightThumbUrl = v.sightThumbUrl
          }
        })
      }, 2000)
    },

    // 生成视频封面
    getVideoImg(width, height, url, index = 0) {
      return new Promise(resolve => {
        let video = this.$refs.video[index]
        video.crossOrigin = 'Anonymous'
        video.setAttribute('src', url)

        setTimeout(() => {
          let canvas = document.createElement('canvas')
          canvas.width = width
          canvas.height = height
          canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height)
          var dataURL = canvas.toDataURL('image/png')
          let File = this.dataURLtoFile(dataURL, new Date().getTime())
          const form = new FormData()
          // 文件对象
          form.append('file', File)
          form.append('name', new Date().getTime())
          uploadMaterial(form).then((res) => {
            if (res.code === 200) {
              resolve(res.data)
            } else {
              resolve(null)
            }
          }).catch(() => {
            resolve(null)
          })
        }, 2000)
      })
    },

    dataURLtoFile(dataUrl, filename) {
      var arr = dataUrl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },

    handleClearVideo() {
      this.detail.videoList = []
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}

.video-list {
  display: flex;
  flex-wrap: wrap;
}

.update {
  width: 130px !important;
  height: 130px;
  line-height: 130px;
  text-align: center;
  border: 1px solid #edecf2;
  background-color: #fbfafd;
  border-radius: 3px;
  cursor: pointer;
  font-size: 30px;
  color: #7d7d7d;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px 10px;

  .avatar {
    width: 130px;
  }
}

.image-updata {
  display: flex !important;
  align-items: flex-start !important;
}

// .setting {
//   display: flex;
//   justify-content: flex-start;
//   margin: 10px 0;
//   align-items: center;
//   & > p {
//     font-size: 14px;
//     color: rgb(78, 78, 78);
//   }
//   & > div {
//     width: 80%;
//     margin-left: 30px;
//   }
// }
</style>
