import Vue from 'vue'
import Router from 'vue-router'

import Layout from '@/components/layout/Layout'

Vue.use(Router)

const viewRoutes = [
  {
    path: '/',
    component: Layout,
    redirect: 'home',
    children: [
      {
        path: '/login',
        redirect: '/home'
      },
      {
        path: '/home',
        name: 'home',
        meta: { title: '首页', parentPath: '/home' },
        component: () => import('@/views/home/Home')
      },
      {
        path: '/adminLog',
        name: 'adminLog',
        meta: { title: '操作日志' },
        component: () => import('@/views/adminLog')
      },
      {
        path: '/serverInfo',
        name: 'ServerInfo',
        meta: { title: '腾讯服务监控' },
        component: () => import('@/views/server/index')
      },
      {
        path: '/redis',
        name: 'redis',
        meta: { title: 'RedisManager' },
        component: () => import('@/views/redis')
      },
      {
        path: '/taskScheduling',
        name: 'taskScheduling',
        meta: { title: '任务调度' },
        component: () => import('@/views/job/index')
      },
      {
        path: '/job-log',
        name: 'job-log',
        meta: { title: '任务调度' },
        component: () => import('@/views/job/log')
      },

      {
        path: '/optLog',
        name: 'optLog',
        meta: { title: '操作日志' },
        component: () => import('@/views/operlog/index')
      },
      {
        path: '/dict',
        name: 'dict',
        meta: { title: '字典' },
        component: () => import('@/views/dict')
      },
      {
        path: '/dict-data/:dictId(\\d+)',
        name: 'dict-data',
        meta: { title: '字典' },
        component: () => import('@/views/dict/data')
      },
      {
        path: '/launchAccount/:housekeeperId(\\d+)',
        name: 'launchAccount',
        meta: { title: '投放账户' },
        component: () => import('@/views/account/accountBaiduAdv/AccountBaiduAdvMain')
      },

      // -------------------------------------------------账号管理---------------------------------------------------------
      // 账号管理 -> 百度账号  start
      {
        path: '/baiduHousekeeper',
        name: 'baiduHousekeeper',
        meta: { title: '百度账号管家', parentPath: '/numberManage' },
        component: () => import('@/views/account/accountBaiduHousekeeper/AccountBaiduHousekeeperMain')
      },
      {
        path: '/baiduLaunchAccount',
        name: 'baiduLaunchAccount',
        meta: { title: '百度投放账户', parentPath: '/numberManage' },
        component: () => import('@/views/account/accountBaiduAdv/AccountBaiduAdvMain')
      },
      // 账号管理 -> 百度账号 end

      // 账号管理 -> 百度账号  start
      {
        path: '/wxHousekeeper',
        name: 'wxHousekeeper',
        meta: { title: '微信服务商账户', parentPath: '/numberManage' },
        component: () => import('@/views/account/weChatAd/weChatHousekeeper')
      },
      {
        path: '/weChatLaunchAccount',
        name: 'weChatLaunchAccount',
        meta: { title: '微信投放账户', parentPath: '/numberManage' },
        component: () => import('@/views/account/weChatAd/weChatLaunchAccount')
      },
      {
        path: '/weChatLaunchAccount/:id',
        name: 'weChatLaunchAccountType',
        meta: { title: '微信投放账户', parentPath: '/numberManage' },
        component: () => import('@/views/account/weChatAd/weChatLaunchAccount')
      },
      // 账号管理 -> 百度账号 end

      // 账号管理 -> 巨量账号 start
      {
        path: '/hugeAdvAccount',
        name: 'hugeAdvAccount',
        meta: { title: '巨量广告主账户', parentPath: '/numberManage' },
        component: () => import('@/views/account/accountHugeAdv/AccountHugeAdvMain')
      },
      {
        path: '/hugeHousekeeper',
        name: 'hugeHousekeeper',
        meta: { title: '巨量管家账户', parentPath: '/numberManage' },
        component: () => import('@/views/account/accountHugeHousekeeper/AccountHugeHousekeeperMain')
      },
      // 账号管理 -> 巨量账号 end

      // 账号管理 -> 腾讯广告 start
      {
        path: '/tencentAdvAccount',
        name: 'tencentAdvAccount',
        meta: { title: '腾讯投放账户', parentPath: '/numberManage' },
        component: () => import('@/views/account/tencentAd/tencentAdvAccount')
      },
      {
        path: '/tencentHousekeeper',
        name: 'tencentHousekeeper',
        meta: { title: '服务商投放账户', parentPath: '/numberManage' },
        component: () => import('@/views/account/tencentAd/tencentHousekeeper')
      },
      // {
      //   path: '/weChatLaunchAccount',
      //   name: 'weChatLaunchAccount',
      //   meta: { title: '微信投放账号', parentPath: '/hugeAmountAdv' },
      //   component: () => import('@/views/account/weChatAd/weChatLaunchAccount')
      // },
      // 账号管理 -> 腾讯广告 end

      // 账号管理 -> 微信广告 start
      {
        path: '/hugeAdvAccount',
        name: 'hugeAdvAccount',
        meta: { title: '巨量广告主账户', parentPath: '/numberManage' },
        component: () => import('@/views/account/accountHugeAdv/AccountHugeAdvMain')
      },
      // 账号管理 -> 微信广告 end
      // 账号管理 -> uc广告 start
      {
        path: '/ucLaunchAccount',
        name: 'ucLaunchAccount',
        meta: { title: 'UC投放账户', parentPath: '/numberManage' },
        component: () => import('@/views/account/ucAd/launchAccount')
      },
      // 账号管理 -> uc广告 end

      // 账号管理 -> 快手 start
      {
        path: '/kuaishouLaunchAccount',
        name: 'kuaishouLaunchAccount',
        meta: { title: '快手投放账户', parentPath: '/numberManage' },
        component: () => import('@/views/account/kuaishouAd/AccountHugeHousekeeperMain')
      },
      {
        path: '/housekeeperKS',
        name: 'housekeeperKS',
        meta: { title: '快手管家账号', parentPath: '/housekeeperKS' },
        component: () => import('@/views/account/kuaishouHousekeeper/AccountHugeHousekeeperMain')
      },
      // 账号管理 -> 快手广告 end

      // -------------------------------------------------资产管理---------------------------------------------------------
      // 资产管理 -> 素材库 start
      {
        path: '/DocumentLibrary',
        name: 'documentLibrary',
        meta: { title: '文案库', parentPath: '/materialManagement' },
        component: () => import('@/views/assetManagement/materialManagement/DocumentLibrary')
      },
      {
        path: '/ImageMaterialManagement',
        name: 'imageMaterialManagement',
        meta: { title: '图片管理', parentPath: '/materialManagement' },
        component: () => import('@/views/assetManagement/materialManagement/ImageMaterialManagement')
      },
      {
        path: '/VideoMaterialManagement',
        name: 'videoMaterialManagement',
        meta: { title: '视频管理', parentPath: '/materialManagement' },
        component: () => import('@/views/assetManagement/materialManagement/VideoMaterialManagement')
      },
      // 资产管理 -> 素材库 end

      // 资产管理 -> 百度广告 start
      {
        path: '/baiduAudience',
        name: 'baiduAudience',
        meta: { title: '定向模板', parentPath: '/assetBaiduAdv' },
        component: () => import('@/views/assetManagement/baidu/assetManagementBaiduAudience/AssetManagementBaiduAudienceMain')
      },
      {
        path: '/woodenFish',
        name: 'woodenFish',
        meta: { title: '基木鱼落地页', parentPath: '/assetBaiduAdv' },
        component: () => import('@/views/assetManagement/baidu/assetManagementBaiduWoodenFish/AssetManagementBaiduWoodenFishMain')
      },

      {
        path: '/shareWoodenFish',
        name: 'shareWoodenFish',
        meta: { title: '分享日志', parentPath: '/assetBaiduAdv' },
        component: () => import('@/views/assetManagement/baidu/assetManagementBaiduWoodenFishLog/AssetManagementBaiduWoodenFishLogMain')
      },
      {
        path: '/trackBaidu',
        name: 'trackBaidu',
        meta: { title: '百度转化追踪', parentPath: '/assetBaiduAdv' },
        component: () => import('@/views/assetManagement/baidu/assetManagementBaiduTrack/AssetManagementBaiduTrackMain')
      },
      // 资产管理 -> 百度广告 end

      // 资产管理 -> 巨量广告 start
      {
        path: '/hugeAmountAd',
        name: 'hugeAmountAd',
        meta: { title: '定向模板', parentPath: '/assetHugeAdv' },
        component: () => import('@/views/hugeAmountAd/Index')
      },
      {
        path: '/addDirectionTemp',
        name: 'addDirectionTemp',
        meta: { title: '新建定向模板', parentPath: '/assetHugeAdv' },
        component: () => import('@/views/hugeAmountAd/directionTemp/addDirectionTemp')
      },
      {
        path: '/editDirectionTemp/:id',
        name: 'editDirectionTemp',
        meta: { title: '编辑定向模板', parentPath: '/assetHugeAdv' },
        component: () => import('@/views/hugeAmountAd/directionTemp/addDirectionTemp')
      },
      {
        path: '/copyDirectionTemp/:copyId',
        name: 'copyDirectionTemp',
        meta: { title: '复制定向模板', parentPath: '/assetHugeAdv' },
        component: () => import('@/views/hugeAmountAd/directionTemp/addDirectionTemp')
      },
      {
        path: '/hugeLandingPageTemp',
        name: 'hugeLandingPageTemp',
        meta: { title: '落地页模板', parentPath: '/assetHugeAdv' },
        component: () => import('@/views/assetManagement/huge/assetManagementHugeLandingPageTemp/AssetManagementHugeLandingPageTempMain')
      },
      {
        path: '/create-ldy',
        name: 'createLdy',
        meta: { title: '新建落地页模板', parentPath: '/assetHugeAdv' },
        component: () => import('@/views/assetManagement/huge_ldy')
      },
      {
        path: '/copy-create-ldy/:copyId',
        name: 'copyCreateLdy',
        meta: { title: '编辑落地页模板', parentPath: '/assetHugeAdv' },
        component: () => import('@/views/assetManagement/huge_ldy')
      },
      {
        path: '/edit-ldy/:id',
        name: 'editLdy',
        meta: { title: '编辑落地页模板', parentPath: '/assetHugeAdv' },
        component: () => import('@/views/assetManagement/huge_ldy')
      },
      {
        path: '/edit-ldy/:type/:id',
        name: 'editTamperLdy',
        meta: { title: '篡改配置', parentPath: '/assetHugeAdv' },
        component: () => import('@/views/assetManagement/huge_ldy')
      },
      {
        path: '/hugeCreateOrangeWebsite',
        name: 'hugeCreateOrangeWebsite',
        meta: { title: '橙子建站', parentPath: '/assetHugeAdv' },
        component: () => import('@/views/assetManagement/huge/assetManagementHugeCreateOrangeWebsite/AssetManagementHugeCreateOrangeWebsiteMain')
      },
      {
        path: '/hugeThirdLandingPage',
        name: 'hugeThirdLandingPage',
        meta: { title: '第三方落地页', parentPath: '/assetHugeAdv' },
        component: () => import('@/views/assetManagement/huge/assetManagementHugeThirdLandingPage/AssetManagementHugeThirdLandingPageMain')
      },
      {
        path: '/hugeConvertClue',
        name: 'hugeConvertClue',
        meta: { title: '跟踪线索', parentPath: '/assetHugeAdv' },
        component: () => import('@/views/assetManagement/huge/assetManagementHugeConvert/AssetManagementHugeConvertMain')
      },
      {
        path: '/hugeConvertApplication',
        name: 'hugeConvertApplication',
        meta: { title: '跟踪应用', parentPath: '/assetHugeAdv' },
        component: () => import('@/views/assetManagement/huge/assetManagementHugeConvert/AssetManagementHugeConvertMainApp')
      },

      // 巨量人群
      {
        path: '/hugeCrowdPackingTask',
        name: 'hugeCrowdPackingTask',
        meta: { title: '人群打包任务', parentPath: '/assetHugeAdv' },
        component: () => import('@/views/assetManagement/huge/crowdPackingTask/index')
      },
      {
        path: '/createHugeCrowdPackingTask',
        name: 'createHugeCrowdPackingTask',
        meta: { title: '新建人群自动打包任务', parentPath: '/assetHugeAdv' },
        component: () => import('@/views/assetManagement/huge/crowdPackingTask/create')
      },
      {
        path: '/editHugeCrowdPackingTask/:id',
        name: 'editHugeCrowdPackingTask',
        meta: { title: '编辑人群自动打包任务', parentPath: '/assetHugeAdv' },
        component: () => import('@/views/assetManagement/huge/crowdPackingTask/create')
      },
      {
        path: '/hugeCrowdPackingTaskDetail/:id',
        name: 'hugeCrowdPackingTaskDetail',
        meta: { title: '人群自动打包任务详情', parentPath: '/assetHugeAdv' },
        component: () => import('@/views/assetManagement/huge/crowdPackingTask/details')
      },
      // 巨量人群 end

      {
        path: '/creativityList',
        name: 'creativityList',
        meta: { title: '创意组件列表', parentPath: '/assetHugeAdv' },
        component: () => import('@/views/assetManagement/creativity')
      },
      {
        path: '/createCreativity',
        name: 'createCreativity',
        meta: { title: '创意组件列表', parentPath: '/assetHugeAdv' },
        component: () => import('@/views/assetManagement/creativity/createCreativity')
      },
      {
        path: '/editCreativity/:id',
        name: 'editCreativity',
        meta: { title: '创意组件列表', parentPath: '/assetHugeAdv' },
        component: () => import('@/views/assetManagement/creativity/createCreativity')
      },
      {
        path: '/eventList',
        name: 'eventList',
        meta: { title: '资产事件列表', parentPath: '/assetHugeAdv' },
        component: () => import('@/views/assetManagement/eventList')
      },
      {
        path: '/populationData',
        name: 'populationData',
        meta: { title: '人群数据列表', parentPath: '/assetHugeAdv' },
        component: () => import('@/views/assetManagement/populationData')
      },
      // 资产管理 -> 巨量广告 end

      // 资产管理 ->  华为 start
      {
        path: '/huaweiPageTemp',
        name: 'huaweiPageTemp',
        meta: { title: '落地页模板', parentPath: '/assetHuaweiAdv' },
        component: () => import('@/views/assetManagement/huawei_kuaishou_360/huaweiLdyList/pageTempMain')
      },
      {
        path: '/createHuaweiLdy',
        name: 'createHuaweiLdy',
        meta: { title: '新建落地页模板', parentPath: '/assetHuaweiAdv' },
        component: () => import('@/views/assetManagement/huawei_kuaishou_360/ldy')
      },
      {
        path: '/editHuaweiLdy/:id',
        name: 'editHuaweiLdy',
        meta: { title: '编辑落地页模板', parentPath: '/assetHuaweiAdv' },
        component: () => import('@/views/assetManagement/huawei_kuaishou_360/ldy')
      },
      {
        path: '/copyCreateHuaweiLdy/:copyId',
        name: 'copyCreateHuaweiLdy',
        meta: { title: '复制新建地页模板', parentPath: '/assetHuaweiAdv' },
        component: () => import('@/views/assetManagement/huawei_kuaishou_360/ldy')
      },
      {
        path: '/editHuaweiLdy/:type/:id',
        name: 'editHuaweiTamperLdy',
        meta: { title: '篡改配置', parentPath: '/assetHugeAdv' },
        component: () => import('@/views/assetManagement/huawei_kuaishou_360/ldy')
      },
      // 资产管理 ->  华为 end

      // 资产管理 ->  快手 start
      {
        path: '/kuaishouPageTemp',
        name: 'kuaishouPageTemp',
        meta: { title: '落地页模板', parentPath: '/assetHuaweiAdv' },
        component: () => import('@/views/assetManagement/huawei_kuaishou_360/kuaishouLdyList/pageTempMain')
      },
      {
        path: '/createKuaishouLdy',
        name: 'createKuaishouLdy',
        meta: { title: '新建落地页模板', parentPath: '/assetHuaweiAdv' },
        component: () => import('@/views/assetManagement/huawei_kuaishou_360/ldy')
      },
      {
        path: '/editKuaishouLdy/:id',
        name: 'editKuaishouLdy',
        meta: { title: '编辑落地页模板', parentPath: '/assetHuaweiAdv' },
        component: () => import('@/views/assetManagement/huawei_kuaishou_360/ldy')
      },
      {
        path: '/copyCreateKuaishouLdy/:copyId',
        name: 'copyCreateKuaishouLdy',
        meta: { title: '复制新建地页模板', parentPath: '/assetHuaweiAdv' },
        component: () => import('@/views/assetManagement/huawei_kuaishou_360/ldy')
      },
      {
        path: '/editKuaishouLdy/:type/:id',
        name: 'editKuaishouTamperLdy',
        meta: { title: '篡改配置', parentPath: '/assetHugeAdv' },
        component: () => import('@/views/assetManagement/huawei_kuaishou_360/ldy')
      },
      // 资产管理 ->  快手 end

      // 资产管理 ->  360 start
      {
        path: '/360PageTemp',
        name: '360PageTemp',
        meta: { title: '落地页模板', parentPath: '/assetHuaweiAdv' },
        component: () => import('@/views/assetManagement/huawei_kuaishou_360/360LdyList/pageTempMain')
      },
      {
        path: '/create360Ldy',
        name: 'create360Ldy',
        meta: { title: '新建落地页模板', parentPath: '/assetHuaweiAdv' },
        component: () => import('@/views/assetManagement/huawei_kuaishou_360/ldy')
      },
      {
        path: '/edit360Ldy/:id',
        name: 'edit360Ldy',
        meta: { title: '编辑落地页模板', parentPath: '/assetHuaweiAdv' },
        component: () => import('@/views/assetManagement/huawei_kuaishou_360/ldy')
      },
      {
        path: '/copyCreate360Ldy/:copyId',
        name: 'copyCreate360Ldy',
        meta: { title: '复制新建地页模板', parentPath: '/assetHuaweiAdv' },
        component: () => import('@/views/assetManagement/huawei_kuaishou_360/ldy')
      },
      {
        path: '/edit360Ldy/:type/:id',
        name: 'edit360TamperLdy',
        meta: { title: '篡改配置', parentPath: '/assetHugeAdv' },
        component: () => import('@/views/assetManagement/huawei_kuaishou_360/ldy')
      },
      // 资产管理 ->  360 end

      // 资产管理 -> 微信广告 start
      {
        path: '/wachatAudience',
        name: 'wachatAudience',
        meta: { title: '微信定向模板列表', parentPath: '/assetWechatAdv' },
        component: () => import('../views/assetManagement/weChat/orientation/index.vue')
      },
      {
        path: '/accountSyncVideo',
        name: 'accountSyncVideo',
        meta: { title: '素材上传任务列表', parentPath: '/accountSyncVideo' },
        component: () => import('../views/assetUpload/syncVideo/index.vue')
      },
      {
        path: '/addWachatAudienceTemp',
        name: 'addWachatAudienceTemp',
        meta: { title: '新建定向模板', parentPath: '/assetWechatAdv' },
        component: () => import('@/views/assetManagement/weChat/orientation/create')
      },
      {
        path: '/editWachatAudienceTemp/:id',
        name: 'editWachatAudienceTemp',
        meta: { title: '编辑定向模板', parentPath: '/assetWechatAdv' },
        component: () => import('@/views/assetManagement/weChat/orientation/create')
      },
      {
        path: '/wechatCrowdPack',
        name: 'wechatCrowdPack',
        meta: { title: '微信人群包列表', parentPath: '/assetWechatAdv' },
        component: () => import('@/views/assetManagement/weChat/crowdPack/index.vue')
      },
      {
        path: '/wechatLabelUpload',
        name: 'wechatLabelUpload',
        meta: { title: '标签生成人群包', parentPath: '/assetWechatAdv' },
        component: () => import('../views/assetManagement/weChat/crowdPack/labelUpload.vue')
      },
      {
        path: '/wechatLocalUpload',
        name: 'wechatLocalUpload',
        meta: { title: '本地上传人群包', parentPath: '/assetWechatAdv' },
        component: () => import('../views/assetManagement/weChat/crowdPack/localUpload.vue')
      },
      {
        path: '/wechatCreateLdy',
        name: 'wechatCreateLdy',
        meta: { title: '新建落地页模板', parentPath: '/assetWechatAdv' },
        component: () => import('../views/assetManagement/weChat/ldy/create.vue')
      },
      {
        path: '/wechatEditLdy/:id',
        name: 'wechatEditLdy',
        meta: { title: '编辑落地页模板', parentPath: '/assetWechatAdv' },
        component: () => import('../views/assetManagement/weChat/ldy/create.vue')
      },
      {
        path: '/wechatLdy',
        name: 'wechatLdy',
        meta: { title: '落地页模板', parentPath: '/assetWechatAdv' },
        component: () => import('../views/assetManagement/weChat/ldyList/index.vue')
      },
      {
        path: '/wechatLdyLog',
        name: 'wechatLdyLog',
        meta: { title: '落地页上传日志', parentPath: '/assetWechatAdv' },
        component: () => import('../views/assetManagement/weChat/ldyList/ldyLog/index.vue')
      },
      // {
      //   path: '/copyDirectionTemp/:id',
      //   name: 'copyDirectionTemp',
      //   meta: { title: '复制定向模板', parentPath: '/assetHugeAdv' },
      //   component: () => import('@/views/hugeAmountAd/directionTemp/addDirectionTemp')
      // },
      // {
      //   path: '/hugeLandingPageTemp',
      //   name: 'hugeLandingPageTemp',
      //   meta: { title: '落地页模板', parentPath: '/assetHugeAdv' },
      //   component: () => import('@/views/assetManagement/huge/assetManagementHugeLandingPageTemp/AssetManagementHugeLandingPageTempMain')
      // },
      // {
      //   path: '/create-ldy',
      //   name: 'createLdy',
      //   meta: { title: '新建落地页模板', parentPath: '/assetHugeAdv' },
      //   component: () => import('@/views/assetManagement/ldy')
      // },
      // {
      //   path: '/edit-ldy/:id',
      //   name: 'editLdy',
      //   meta: { title: '编辑落地页模板', parentPath: '/assetHugeAdv' },
      //   component: () => import('@/views/assetManagement/ldy')
      // },
      // 资产管理 -> 微信广告 end

      // 资产管理 -> 腾讯广告 start
      {
        path: '/tencentOrientation',
        name: 'tencentOrientation',
        meta: { title: '定向模板', parentPath: '/assetTencentAd' },
        component: () => import('@/views/assetManagement/tencent/orientation/index')
      },
      {
        path: '/createTencentOrientation',
        name: 'createTencentOrientation',
        meta: { title: '新建定向模板', parentPath: '/assetTencentAd' },
        component: () => import('@/views/assetManagement/tencent/orientation/create/index')
      },
      {
        path: '/synchronization',
        name: 'synchronization',
        meta: { title: '线上资产同步', parentPath: '/assetTencentAd' },
        component: () => import('@/views/assetManagement/tencent/synchronization')
      },
      {
        path: '/tencentCrowdPack',
        name: 'tencentCrowdPack',
        meta: { title: '人群管理', parentPath: '/assetTencentAd' },
        component: () => import('@/views/assetManagement/tencent/crowdManage/index')
      },
      {
        path: '/tencentCrowdPackingTask',
        name: 'tencentCrowdPackingTask',
        meta: { title: '人群打包任务', parentPath: '/assetTencentAd' },
        component: () => import('@/views/assetManagement/tencent/crowdPackingTask/index')
      },
      {
        path: '/createTencentCrowdPackingTask',
        name: 'createTencentCrowdPackingTask',
        meta: { title: '新建人群自动打包任务', parentPath: '/assetTencentAd' },
        component: () => import('@/views/assetManagement/tencent/crowdPackingTask/create')
      },
      {
        path: '/editTencentCrowdPackingTask/:id',
        name: 'editTencentCrowdPackingTask',
        meta: { title: '编辑人群自动打包任务', parentPath: '/assetTencentAd' },
        component: () => import('@/views/assetManagement/tencent/crowdPackingTask/create')
      },
      {
        path: '/tencentCrowdPackingTaskDetail/:id',
        name: 'tencentCrowdPackingTaskDetail',
        meta: { title: '人群自动打包任务详情', parentPath: '/assetTencentAd' },
        component: () => import('@/views/assetManagement/tencent/crowdPackingTask/details')
      },

      // 资产管理 -> 腾讯广告 end

      // -------------------------------------------------广告管理---------------------------------------------------------
      // 广告管理 -> 广告模板管理 start
      // {
      //   path: '/hugeAmountBatch',
      //   name: 'hugeAmountBatch',
      //   meta: { title: '程序化配置', parentPath: '/advTemplateManagement' },
      //   component: () => import('@/views/advManagement/hugeAmount/index')
      // },
      // {
      //   path: '/createHugeAmountBatch',
      //   name: 'createHugeAmountBatch',
      //   meta: { title: '新建程序化配置', parentPath: '/advTemplateManagement' },
      //   component: () => import('@/views/advManagement/hugeAmount/hugeAmountBatch/hugeAmountBatchMain')
      // },
      // {
      //   path: '/createHugeAmountBatch/:id',
      //   name: 'createHugeAmountBatch2',
      //   meta: { title: '新建程序化配置', parentPath: '/advTemplateManagement' },
      //   component: () => import('@/views/advManagement/hugeAmount/hugeAmountBatch/hugeAmountBatchMain')
      // },
      {
        path: '/baiduPlan',
        name: 'baiduPlan',
        meta: { title: '推广计划', parentPath: '/advTemplateManagement' },
        component: () => import('@/views/advManagement/baidu/advManagementBaiduPlan/AdvManagementBaiduPlanMain')
      },
      {
        path: '/baiduUnit',
        name: 'baiduUnit',
        meta: { title: '推广单元', parentPath: '/advTemplateManagement' },
        component: () => import('@/views/advManagement/baidu/advManagementBaiduUnit/AdvManagementBaiduUnitMain')
      },
      {
        path: '/baiduCreative',
        name: 'baiduCreative',
        meta: { title: '推广创意', parentPath: '/advTemplateManagement' },
        component: () => import('@/views/advManagement/baidu/advManagementBaiduCreativeFend/AdvManagementBaiduCreativeFendMain')
      },
      {
        path: '/createBaiduCreative',
        name: 'createBaiduCreative',
        meta: { title: '新建推广创意', parentPath: '/advTemplateManagement' },
        component: () => import('@/views/advManagement/baidu/advManagementBaiduCreativeFend/promoteCreativity/index')
      },
      {
        path: '/editBaiduCreative/:id/:type',
        name: 'editBaiduCreative',
        meta: { title: '编辑推广创意', parentPath: '/advTemplateManagement' },
        component: () => import('@/views/advManagement/baidu/advManagementBaiduCreativeFend/promoteCreativity/index')
      },
      {
        path: '/copyBaiduCreative/:id/:type',
        name: 'copyBaiduCreative',
        meta: { title: '复制推广创意', parentPath: '/advTemplateManagement' },
        component: () => import('@/views/advManagement/baidu/advManagementBaiduCreativeFend/promoteCreativity/index')
      },
      {
        path: '/baiduBatch',
        name: 'baiduBatch',
        meta: { title: '程序化批量', parentPath: '/advTemplateManagement' },
        component: () => import('@/views/advManagement/baidu/baiduBatch/baiduBatchMain')
      },
      // 广告管理 -> 广告模板管理 end

      // 广告管理 -> 腾讯广告 start
      {
        path: '/tencentAmountAdBatch',
        name: 'tencentAmountAdBatch',
        meta: { title: '腾讯广告程序化批量', parentPath: '/advTemplateManagement' },
        component: () => import('@/views/advManagement/tencent/index.vue')
      },
      {
        path: '/createTencentAmountAd',
        name: 'createTencentAmountAd',
        meta: { title: '配置程序化批量', parentPath: '/advTemplateManagement' },
        component: () => import('../views/advManagement/tencent/create/index.vue')
      },
      {
        path: '/createTencentAmountAd/:taskId',
        name: 'editTencentAmountAd',
        meta: { title: '配置程序化批量', parentPath: '/advTemplateManagement' },
        component: () => import('../views/advManagement/tencent/create/index.vue')
      },
      {
        path: '/synchronization',
        name: 'synchronization',
        meta: { title: '线上资产同步', parentPath: '/advTemplateManagement' },
        component: () => import('@/views/assetManagement/tencent/synchronization')
      },
      {
        path: '/advBatchCopy',
        name: 'advBatchCopy',
        meta: { title: '广告批量复制', parentPath: '/advTemplateManagement' },
        component: () => import('@/views/advManagement/tencent/advBatchCopy/index.vue')
      },

      // 广告管理 -> 腾讯广告 end

      // 广告管理 -> mp广告 start
      {
        path: '/weChatAmountAdBatch',
        name: 'weChatAmountAdBatch',
        meta: { title: '微信广告程序化批量', parentPath: '/advTemplateManagement' },
        component: () => import('@/views/advManagement/weChat/index.vue')
      },
      {
        path: '/createWeChatAmountAd',
        name: 'createWeChatAmountAd',
        meta: { title: '配置程序化批量', parentPath: '/advTemplateManagement' },
        component: () => import('../views/advManagement/weChat/create/index.vue')
      },
      {
        path: '/editWeChatAmountAd/:taskId',
        name: 'editWeChatAmountAd',
        meta: { title: '配置程序化批量', parentPath: '/advTemplateManagement' },
        component: () => import('../views/advManagement/weChat/create/index.vue')
      },
      // {
      //   path: '/synchronization',
      //   name: 'synchronization',
      //   meta: { title: '线上资产同步', parentPath: '/advTemplateManagement' },
      //   component: () => import('@/views/assetManagement/weChat/synchronization')
      // },

      // 广告管理 -> 腾讯广告 end

      // 广告管理 -> 巨量广告 start
      {
        path: '/hugeAmountAdBatch',
        name: 'hugeAmountAdBatch',
        meta: { title: '程序化批量', parentPath: '/advTemplateManagement' },
        component: () => import('@/views/advManagement/hugeAmountAd/index')
      },
      {
        path: '/createHugeAmountAdBatch',
        name: 'createHugeAmountAdBatch',
        meta: { title: '新建程序化配置', parentPath: '/advTemplateManagement' },
        component: () => import('@/views/advManagement/hugeAmountAd/create/index')
      },
      {
        path: '/editHugeAmountAdBatch/:id',
        name: 'editHugeAmountAdBatch',
        meta: { title: '新建程序化配置', parentPath: '/advTemplateManagement' },
        component: () => import('@/views/advManagement/hugeAmountAd/create/index')
      },
      // 广告管理 -> 巨量广告 end

      // 广告管理 -> 广告回传设置 Start
      {
        path: '/returnSettingRules',
        name: 'returnSettingRules',
        meta: { title: '回传规则配置', parentPath: '/adReturnSettings' },
        component: () => import('@/views/passback/created/setting.vue')
      },
      {
        path: '/createReturn/:type',
        name: 'createReturn',
        meta: { title: '新建回传配置', parentPath: '/adReturnSettings' },
        component: () => import('@/views/passback/created/index.vue')
      },
      {
        path: '/editReturn/:type/:pk_unique',
        name: 'editReturn',
        meta: { title: '编辑回传配置', parentPath: '/adReturnSettings' },
        component: () => import('@/views/passback/created/index.vue')
      },

      {
        path: '/activationRules',
        name: 'activationRules',
        meta: { title: '激活回传', parentPath: '/adReturnSettings' },
        component: () => import('@/views/passback/return/activation.vue')
      },
      {
        path: '/transformReturn',
        name: 'transformReturn',
        meta: { title: '转化回传', parentPath: '/adReturnSettings' },
        component: () => import('@/views/passback/return/transform.vue')
      },

      // 巨量回传
      // {
      //   path: '/hugeActivationRules',
      //   name: 'hugeActivationRules',
      //   meta: { title: '激活回传', parentPath: '/adReturnSettings' },
      //   component: () => import('@/views/passback/huge/activation.vue')
      // },
      // {
      //   path: '/hugeTransformReturn',
      //   name: 'hugeTransformReturn',
      //   meta: { title: '转化回传', parentPath: '/adReturnSettings' },
      //   component: () => import('@/views/passback/huge/transform.vue')
      // },

      // 百度回传
      // {
      //   path: '/baiduActivationRules',
      //   name: 'baiduActivationRules',
      //   meta: { title: '激活回传', parentPath: '/adReturnSettings' },
      //   component: () => import('@/views/passback/baidu/activation.vue')
      // },
      // {
      //   path: '/baiduTransformReturn',
      //   name: 'baiduTransformReturn',
      //   meta: { title: '转化回传', parentPath: '/adReturnSettings' },
      //   component: () => import('@/views/passback/baidu/transform.vue')
      // },

      // 快手回传
      // {
      //   path: '/kuaishouActivationRules',
      //   name: 'kuaishouActivationRules',
      //   meta: { title: '激活回传', parentPath: '/adReturnSettings' },
      //   component: () => import('@/views/passback/kuaishou/activation.vue')
      // },
      // {
      //   path: '/kuaishouTransformReturn',
      //   name: 'kuaishouTransformReturn',
      //   meta: { title: '转化回传', parentPath: '/adReturnSettings' },
      //   component: () => import('@/views/passback/kuaishou/transform.vue')
      // },

      // uc回传
      // {
      //   path: '/ucActivationRules',
      //   name: 'ucActivationRules',
      //   meta: { title: '激活回传', parentPath: '/adReturnSettings' },
      //   component: () => import('@/views/passback/uc/activation.vue')
      // },
      // {
      //   path: '/ucTransformReturn',
      //   name: 'ucTransformReturn',
      //   meta: { title: '转化回传', parentPath: '/adReturnSettings' },
      //   component: () => import('@/views/passback/uc/transform.vue')
      // },

      // {
      //   path: '/userMatchRate',
      //   name: 'userMatchRate',
      //   meta: { title: '转化回传', parentPath: '/adReturnSettings' },
      //   component: () => import('@/views/passback/matchRate.vue')
      // },
      // 广告管理 -> 广告回传设置 end

      // -------------------------------------------------基础管理---------------------------------------------------------
      // 基础管理 -> start
      {
        path: '/projectItemList',
        name: 'projectItemList',
        meta: { title: '项目列表', parentPath: '/projectManagement' },
        component: () => import('@/views/basicSetting/project/BasicSettingProjectMain')
      },
      {
        path: '/productItemList',
        name: 'productItemList',
        meta: { title: '产品列表', parentPath: '/productManagement' },
        component: () => import('@/views/basicSetting/product/item/BasicSettingProductMain')
      },
      {
        path: '/productType',
        name: 'productType',
        meta: { title: '产品品类', parentPath: '/productManagement' },
        component: () => import('@/views/basicSetting/product/type/BasicSettingProductTypeMain')
      },
      {
        path: '/productSource',
        name: 'productSource',
        meta: { title: '产品来源', parentPath: '/productManagement' },
        component: () => import('@/views/basicSetting/product/source/BasicSettingProductSourceMain')
      },
      {
        path: '/labelItemList',
        name: 'labelItemList',
        meta: { title: '标签列表', parentPath: '/labelManagement' },
        component: () => import('@/views/basicSetting/label/basicSettingLabelType/BasicSettingLabelTypeMain')
      },
      // 基础管理 -> end

      // -------------------------------------------------工具管理---------------------------------------------------------
      // 素材工具 -> start
      {
        path: '/toolVideoElement',
        name: 'toolVideoElement',
        meta: { title: '视频元素库', parentPath: '/toolManagement' },
        component: () => import('@/views/toolManagement/materialTool/videoElementLibrary/index')
      },
      {
        path: '/toolVideoTemplate',
        name: 'toolVideoTemplate',
        meta: { title: '视频模板库', parentPath: '/toolManagement' },
        component: () => import('@/views/toolManagement/materialTool/videoTemplateLibrary/index')
      },
      {
        path: '/toolVideoTask',
        name: 'toolVideoTask',
        meta: { title: '模板视频', parentPath: '/toolManagement' },
        component: () => import('@/views/toolManagement/materialTool/videoTask/index')
      },
      {
        path: '/creatVideoTask',
        name: 'creatVideoTask',
        meta: { title: '新增模板视频', parentPath: '/toolManagement' },
        component: () => import('@/views/toolManagement/materialTool/videoTask/create/index')
      },
      {
        path: '/editVideoTask/:id',
        name: 'editVideoTask',
        meta: { title: '编辑模板视频', parentPath: '/toolManagement' },
        component: () => import('@/views/toolManagement/materialTool/videoTask/create/index')
      },
      // 素材工具 -> end

      // 素材工具 -> 产品投测 start
      {
        path: '/productLibrary',
        name: 'productLibrary',
        meta: { title: '产品库', parentPath: '/toolManagement' },
        component: () => import('@/views/toolManagement/productTest/productLibrary/index')
      },
      {
        path: '/productScreen',
        name: 'productScreen',
        meta: { title: '产品筛选', parentPath: '/toolManagement' },
        component: () => import('@/views/toolManagement/productTest/productScreen/index')
      },
      {
        path: '/myProjection',
        name: 'myProjection',
        meta: { title: '我的投测', parentPath: '/toolManagement' },
        component: () => import('@/views/toolManagement/productTest/myProjection/index')
      },
      {
        path: '/projectionData',
        name: 'projectionData',
        meta: { title: '投测数据', parentPath: '/toolManagement' },
        component: () => import('@/views/toolManagement/productTest/projectionData/index')
      },
      {
        path: '/projectionStatics',
        name: 'projectionStatics',
        meta: { title: '投测统计', parentPath: '/toolManagement' },
        component: () => import('@/views/toolManagement/productTest/projectionStatics/index')
      },
      // 素材工具 -> 产品投测 end

      // 落地页工具 -> 中转落地页 start
      {
        path: '/transferLandingPage',
        name: 'transferLandingPage',
        meta: { title: '中转落地页', parentPath: '/toolManagement' },
        component: () => import('@/views/toolManagement/landingPageTool/transferLandingPage/index')
      },
      {
        path: '/customLandingPage',
        name: 'customLandingPage',
        meta: { title: '自定义落地页', parentPath: '/toolManagement' },
        component: () => import('@/views/toolManagement/landingPageTool/customLandingPage/index')
      },
      {
        path: '/createCustomPage',
        name: 'createCustomPage',
        meta: { title: '新增自定义落地页', parentPath: '/toolManagement' },
        component: () => import('@/views/toolManagement/landingPageTool/customLandingPage/create/index')
      },
      {
        path: '/editCustomPage/:id',
        name: 'editCustomPage',
        meta: { title: '编辑自定义落地页', parentPath: '/toolManagement' },
        component: () => import('@/views/toolManagement/landingPageTool/customLandingPage/create/index')
      },
      // 落地页工具 -> 中转落地页 end
      {
        path: '/publishLogTable',
        name: 'publishLogTable',
        meta: { title: '日志列表' },
        component: () => import('@/views/advManagement/publish/AdvManagementBaiduPublishMain')
      },
      {
        path: '/adminTools',
        name: 'adminTools',
        meta: { title: '工具' },
        component: () => import('@/views/tools')
      }
    ]
  },
  {
    path: '*',
    name: 'err404',
    component: () => import('@/components/Err404')
  }
]

const createRouter = () =>
  new Router({
    mode: 'history', // 去掉url中的#
    scrollBehavior: () => ({ y: 0 }),
    routes: viewRoutes
  })

const router = createRouter()

export default router
