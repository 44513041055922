<!--
 * @Description: 创意基础信息
 * @Date: 2021-12-06 15:57:16
 * @LastEditTime: 2021-12-13 14:20:12
-->
<template>
  <el-dialog title="规则配置" :visible="ruleDeployVisible" width="650px" :modal-append-to-body="false" :before-close="handleClose">
    <span>
      <el-form ref="ruleForm" :model="ruleForm" label-width="140px">
        <el-form-item label="使用已有计划：">
          <el-radio-group v-model="ruleForm.usePlanStatus">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false" disabled>否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="复用已有广告：">
          <el-radio-group v-model="ruleForm.useAdStatus">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false" disabled>否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="素材文案组合方式：">
          <el-radio-group v-model="ruleForm.taskCombine">
            <el-radio :label="1">全量组合</el-radio>
            <el-radio :label="2">指定组合</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="广告默认状态">
          <el-radio-group v-model="ruleForm.configuredStatus">
            <el-radio label="AD_STATUS_NORMAL">开启</el-radio>
            <el-radio label="AD_STATUS_SUSPEND">暂停</el-radio>
          </el-radio-group>
        </el-form-item>

        <!-- 非复用广告 -->
        <!-- <el-form-item v-if="!ruleForm.useAdStatus" label="广告分配规则：">
          <el-radio-group v-model="ruleForm.usePlanStatus">
            <el-radio label="按定向包分组">按定向包分组</el-radio>
            <el-radio label="按创意分组">按创意分组</el-radio>
            <el-radio label="按文案分组">按文案分组</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item v-if="!ruleForm.useAdStatus" label="推广计划名称：">
          <el-input v-model="ruleForm.adName" type="text" style="width: 400px" placeholder="请输入创意名称" maxlength="60" show-word-limit />
        </el-form-item>

        <el-form-item v-if="!ruleForm.useAdStatus" label="计划内广告数上限：">
          <el-input-number v-model="ruleForm.adName" type="text" style="width: 400px" placeholder="请输入计划内广告数上限" />
        </el-form-item>

        <el-form-item v-if="!ruleForm.useAdStatus" label="广告组内素材数上限：">
          <el-input-number v-model="ruleForm.adName" type="text" style="width: 400px" placeholder="请输入广告组内素材数上限" />
        </el-form-item>

        <el-form-item v-if="!ruleForm.useAdStatus" label="推广计划日预算：">
          <el-select v-model="ruleData.adName" placeholder="请选择">
            <el-option label="不限" value="-1"></el-option>
            <el-option label="指定日预算" value="-1"></el-option>
          </el-select>
          <el-input-number v-model="ruleForm.adName" />
        </el-form-item>

        <el-form-item v-if="!ruleForm.useAdStatus" label="推广计划总预算：">
          <el-select v-model="ruleData.adName" placeholder="请选择">
            <el-option label="不限" value="-1"></el-option>
            <el-option label="指定日预算" value="-1"></el-option>
          </el-select>
          <el-input-number v-model="ruleForm.adName" :min="50" :max="40000000" />
        </el-form-item>

        <el-form-item v-if="!ruleForm.useAdStatus" label="投放方式：">
          <el-select v-model="ruleData.adName" placeholder="请选择">
            <el-option label="不限" value="-1"></el-option>
            <el-option label="指定日预算" value="-1"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="!ruleForm.useAdStatus" label="计划默认状态：">
          <el-radio-group v-model="ruleForm.useAdStatus">
            <el-radio :label="true">开启</el-radio>
            <el-radio :label="false" :disabled="true">暂停</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="!ruleForm.useAdStatus" label="广告默认状态：">
          <el-radio-group v-model="ruleForm.useAdStatus">
            <el-radio :label="true">开启</el-radio>
            <el-radio :label="false" :disabled="true">暂停</el-radio>
          </el-radio-group>
        </el-form-item> -->
      </el-form>
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="onSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  inject: ['closeRuleDeploy'],
  props: {
    ruleDeployVisible: {
      type: Boolean,
      default: false
    },
    isRuleDeploy: {
      type: Boolean,
      default: false
    },
    taskId: {
      type: Number,
      default: 0
    },
    taskCombine: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      ruleForm: {
        usePlanStatus: true,
        useAdStatus: true,
        taskCombine: this.taskCombine,
        configuredStatus: 'AD_STATUS_SUSPEND'
      }
    }
  },

  computed: {
    ...mapGetters(['userInfo', 'isCollapse'])
  },

  methods: {
    setUserId(value) {
      if (value) {
        this.ruleForm.userId = this.userInfo.userid
      } else {
        this.ruleForm.userId = ''
      }
    },

    onSubmit() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          const planStatus = this.ruleForm.usePlanStatus ? '1' : '0'
          const adStatus = this.ruleForm.useAdStatus ? '1' : '0'
          const taskRule = planStatus + '-' + adStatus
          const taskCombine = this.ruleForm.taskCombine
          const configuredStatus = this.ruleForm.configuredStatus
          this.$emit('setRuleDeploy', true, taskRule, taskCombine, configuredStatus)
        }
      })
    },

    handleClose() {
      this.closeRuleDeploy()
      this.$refs['ruleForm'].resetFields()
    }
  }
}
</script>

<style lang="scss">
.handleTree-icon :hover {
  color: #409eff;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
