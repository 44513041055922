<template>
  <el-dialog :title="isEdit ? '编 辑' : '新 增'" width="60%" :visible.sync="visible" :before-close="close" label-position="right" :modal-append-to-body="false" :close-on-press-escape="false" :close-on-click-modal="false" :destroy-on-close="true" top="100px">
    <el-row class="bg-purple-dark ">
      <el-col :span="24">
        <div class="grid-content">
          <span>
            <font class="meH1">营销目标</font>
            <el-tooltip class="" effect="dark" placement="top-start">
              <div slot="content" style="width:400px">
                网站链接：适用于网站推广的广告主，提升页面的到访，进而在页面产生咨询、留下联系方式等销售线索应用推广：适用于应用推广诉求的广告主，提升应用的下载、安装、激活、付费等商品目录：适用于拥有众多商品且已对接百度商品中心的广告主，如零售平台、汽车平台、旅游平台、房产平台等电商店铺：适用于拥有成熟电商店铺的广告主，希望直达店铺、提升销量，目前已支持淘宝、天猫、京东、拼多多、1688等平台的店铺门店推广：适用于拥有线下门店的广告主，希望提升门店卡券分发、线索收集、线下到访等营销效果
              </div>
              <i class="el-icon-question ml-5 mr-5" />
            </el-tooltip>

            为您的推广计划设置营销目标，系统帮您找到达成该目标最佳的产品功能，帮您节省广告制作时间并制作更出色的广告。
          </span>
        </div>
      </el-col>
      <el-col :span="24">
        <div class="subject-type-block">
          <div class="subject-type-item-wrapper  subject-type-checked">
            <i class="bg-icon1" />
            <div class="subject-title">网站链接</div>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-row class="bg-purple-dark  mt-20 medisplay ">
      <el-col :span="24">
        <span class="meH1 ">计划设置</span>
        <!-- <el-button type="text" class="ml-10" @click="inportPlan">导入已有计划</el-button> -->
        <el-tooltip class="" effect="dark" placement="top-start">
          <div slot="content" style="width:400px">导入账户已有计划设置内容，如预算、时段、APP包信息等，保存后会新建一个全新的计划</div>
          <i class="el-icon-question  ml-5 mr-5" />
        </el-tooltip>
      </el-col>
    </el-row>

    <el-row class="bg-purple-dark mt-20  medisplay ">
      <el-col :span="4">
        <div class="meContent"><font style="color:red">*</font>关联项目</div></el-col>
      <el-col :span="8">
        <el-select v-model="ent.bsProjectId" class="w-300" filterable allow-create default-first-option placeholder="请选择" @change="bsProjectChange">
          <el-option v-for="item in projectList" :key="item.bsProjectId" :label="item.projectName" :value="item.bsProjectId" />
        </el-select>
      </el-col>
      <el-col :span="4"> <div class="meContent">关联产品</div></el-col>
      <el-col :span="8">
        <el-select v-model="ent.bsProductId" class="w-300" filterable allow-create default-first-option placeholder="请选择">
          <el-option v-for="item in productList" :key="item.bsProductId" :label="item.productName" :value="item.bsProductId" />
        </el-select>
      </el-col>
    </el-row>

    <el-row class="bg-purple-dark mt-10  medisplay ">
      <el-col :span="24">
        <el-row class="bg-purple-darks medisplay">
          <el-col :span="4">
            <div class="meContent">
              <font style="color:red">*</font>预算消耗方式
              <el-tooltip class="" effect="dark" placement="top-start">
                <div slot="content" style="width:400px">
                  标准：尽快将广告投放出去，预算可能会在短时间内消耗完;<br />
                  平衡投放：根据流量波动，让预算在整个投放日程中较为平稳的消耗;<br />
                  展现优先：尽可能获得更多展现，对比标准投放预算消耗更快;<br />
                </div>
                <i class="el-icon-question  ml-5 mr-5" />
              </el-tooltip></div></el-col>
          <el-col :span="20">
            <div>
              <el-radio-group v-model="ent.bgtctltype">
                <el-radio-button label="1">标准投放</el-radio-button>
                <el-radio-button label="0">均衡投放</el-radio-button>
                <el-radio-button label="2">展现优先</el-radio-button>
              </el-radio-group>
            </div>
          </el-col>
        </el-row>

        <el-row class="bg-purple-darks medisplay">
          <el-col :span="4">
            <div class="meContent"><font style="color:red">*</font>预算</div></el-col>
          <el-col :span="20">
            <div>
              <el-radio-group v-model="budgetType">
                <el-radio-button label="0">不限</el-radio-button>
                <el-radio-button label="-1">自定义</el-radio-button>
              </el-radio-group>
            </div>
          </el-col>
        </el-row>

        <el-row v-if="budgetType === '-1'" class="bg-purple-darks medisplay">
          <el-col :span="4"> <div class="meContent" /></el-col>
          <el-col :span="20">
            <div><el-input-number v-model="ent.budget" :precision="2" controls-position="right" :min="50" placeholder="保留两位 最低50元" />元/天</div>
          </el-col>
        </el-row>

        <el-row class="bg-purple-darks medisplay">
          <el-col :span="4">
            <div class="meContent"><font style="color:red">*</font>推广日期</div></el-col>
          <el-col :span="20">
            <div>
              <el-radio-group v-model="startAndEnd">
                <el-radio-button label="0">不限</el-radio-button>
                <el-radio-button label="-1">自定义</el-radio-button>
              </el-radio-group>
            </div>
          </el-col>
        </el-row>

        <el-row v-if="startAndEnd === '-1'" class="bg-purple-darks medisplay">
          <el-col :span="4"> <div class="meContent" /></el-col>
          <el-col :span="20">
            <div>
              <el-date-picker v-model="startAndEndTime" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" />
            </div>
          </el-col>
        </el-row>

        <el-row class="bg-purple-darks medisplay">
          <el-col :span="4">
            <div class="meContent"><font style="color:red">*</font>推广时段</div></el-col>
          <el-col :span="20">
            <div>
              <el-radio-group v-model="scheduleType" @change="scheduleChange">
                <el-radio-button label="0">不限</el-radio-button>
                <el-radio-button label="-1">自定义</el-radio-button>
              </el-radio-group>
            </div>
          </el-col>
        </el-row>

        <el-row v-if="scheduleType === '-1'" class="bg-purple-darks medisplay">
          <el-col :span="4"> <div class="meContent" /></el-col>
          <el-col :span="20">
            <!-- <simpleGrid :v-if="false" :schedule-type-data="scheduletypesC()" @getData="getDatas" /> -->
            <!-- 时间段组件 -->
            <div :v-if="false">
              <m-schedule ref="schedule" :value="scheduleData" :shortcuts="shortcuts" :days-title="'时段/日期'" :shortcuts-type="'dropdown'" @clear="handleClear" @change="handleChange" @click="handleClick">
                <template #hourTip="{day, rowIndex, colIndex}">
                  <div>{{ day.name }}-{{ rowIndex }}-{{ colIndex }}</div>
                </template>
                <template #confirm>
                  <div :class="commonCls">
                    <div :class="`${commonCls}-title`">
                      <h3>选中时间</h3>
                    </div>
                    <div :class="`${commonCls}-set-bid`">
                      <button type="text-strong" @click="handleConfirm">确定</button>
                      <button type="text-strong" @click="$refs.schedule.handleClose">取消</button>
                    </div>
                  </div>
                </template>
              </m-schedule>
            </div>
          </el-col>
        </el-row>

        <el-row class="bg-purple-dark medisplay">
          <el-col :span="4">
            <div class="meContent"><font style="color:red">*</font>计划名称</div></el-col>
          <el-col :span="20"> <el-input v-model="ent.campaignFeedName" type="text" placeholder="请输入内容" maxlength="100" show-word-limit /></el-col>
        </el-row>
      </el-col>
    </el-row>

    <span slot="footer" class="dialog-footer">
      <el-popconfirm title="确定提交？" @confirm="submit">
        <el-button slot="reference" type="primary" round>确定</el-button>
      </el-popconfirm>
      <el-button class="ml-10" round @click="close">取消</el-button>
    </span>
    <InportBaiduPlan ref="inportPlan"></InportBaiduPlan>
  </el-dialog>
</template>
<script>
import { Add, Update } from '@/network/api/advManagement/advManagementBaiduPlan'
import { getMeRelationProject } from '@/network/api/basicSetting/project/basicSettingProject'
import { getProductByProject } from '@/network/api/basicSetting/product/basicSettingProduct'
import { AdvManagementBaiduPlanEnt, formRules } from './object'
import { ScheduleType } from './object'
// import simpleGrid from './simpleGrid.vue'
import InportBaiduPlan from './InportBaiduPlan.vue'
import moment from 'moment'
import { mapGetters } from 'vuex'
export default {
  inject: ['loadData'],
  components: { InportBaiduPlan },
  data() {
    return {
      scheduletypes: [],
      budgetType: '0', // 预算
      startAndEnd: '0',
      scheduleType: '0',
      startAndEndTime: '',
      ent: new AdvManagementBaiduPlanEnt(),
      rules: formRules,
      isEdit: false,
      visible: false,
      cityList: [],
      projectList: [],
      scheduleData: null,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() + 1 * 24 * 60 * 60 * 1000 <= Date.now() - 8.64e6
        }
      },
      productList: [],
      shortcuts: [
        {
          label: '预设时段',
          value: 'shortcuts1',
          selected: {
            0: true,
            1: true,
            2: true,
            3: true,
            4: true,
            5: true,
            6: true
          }
        },
        {
          label: '预设时段2',
          value: 'shortcuts2',
          selected: {
            1: true,
            2: true,
            3: true,
            4: true,
            5: true
          }
        },
        {
          label: '预设时段3',
          value: 'shortcuts3',
          selected: {
            0: true,
            6: true
          }
        }
      ]
    }
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    commonCls() {
      return 'schedule-confirm-container'
    },
    ...mapGetters(['userInfo', 'isCollapse'])
  },
  watch: {
    timeSeries(val) {
      console.log(val)
    }
  },
  methods: {
    inportPlan() {
      this.$refs.inportPlan.show()
    },
    bsProjectChange(val) {
      this.ent.bsProductId = undefined
      this.getProduct(val)
      console.log(val)
    },
    getProduct(val) {
      getProductByProject(val).then((res) => {
        if (res.code === 200) {
          this.productList = res.data
        }
      })
    },
    getProject() {
      getMeRelationProject().then((res) => {
        if (res.code === 200) {
          this.projectList = res.data
        }
      })
    },
    scheduleChange() {
      if (this.scheduleType === '-1') {
        this.scheduletypes = []
        for (var i = 1; i < 8; i++) {
          var st = new ScheduleType()
          st.weekDay = i
          st.startHour = []
          st.endHour = []
          for (var z = 0; z < 24; z++) {
            st.endHour.push(z)
          }
          this.scheduletypes.push(st)
        }
      }
    },
    // getDatas(val) {
    //   this.scheduleData = val
    // },
    scheduletypesC() {
      if (this.scheduletypes.length === 0) {
        for (var i = 1; i < 8; i++) {
          var st = new ScheduleType()
          st.weekDay = i
          st.startHour = []
          st.endHour = []
          for (var z = 0; z < 24; z++) {
            st.endHour.push(z)
          }
          this.scheduletypes.push(st)
        }
      }
      return this.scheduletypes
    },

    submit() {
      this.ent.subject = 1
      this.ent.pause = 0
      if (this.ent.bsProjectId === undefined || this.ent.bsProjectId === null) {
        this.$message({ message: '请选择关联项目', type: 'error' })
        return
      }
      // if (this.ent.bsProductId === undefined || this.ent.bsProductId === null) {
      //   this.$message({ message: '请选择关联产品', type: 'error' })
      //   return
      // }
      if (this.budgetType === '0') {
        this.ent.budget = null
      } else {
        if (this.ent.budget == null || this.ent.budget === undefined || this.ent.budget <= 0) {
          this.$message({ message: '请输入预算', type: 'error' })
          return
        }
      }

      if (this.startAndEnd === '0') {
        this.ent.starttime = null
        this.ent.endtime = null
      } else {
        if (this.startAndEndTime.length !== 2) {
          this.$message({ message: '请选择推广日期', type: 'error' })
          return
        }
        this.ent.starttime = this.startAndEndTime[0]
        this.ent.endtime = this.startAndEndTime[1]
      }
      if (this.scheduleType === '0') {
        this.ent.schedule = null
      } else {
        if (Object.keys(this.scheduleData).length === 0) {
          this.$message({ message: '请选择推广时段', type: 'error' })
          return
        } else {
          this.ent.schedule = JSON.stringify(this.scheduleData)
        }
      }
      if (this.ent.campaignFeedName === undefined || this.ent.campaignFeedName === null) {
        this.$message({ message: '请输入计划名称', type: 'error' })
        return
      }
      this.isEdit ? this.updateRow() : this.addRow()
    },
    addRow() {
      const req = { ...this.ent }
      Add(req).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.loadData()
          this.close()
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    updateRow() {
      const req = { ...this.ent }
      Update(req).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          this.loadData()
          this.close()
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    close() {
      this.visible = false
      // this.$nextTick(() => {
      //   this.$refs.ent.resetFields()
      // })
    },
    getUID() {
      // 获取唯一值
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (Math.random() * 16) | 0
        var v = c === 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      })
    },
    show(isEdit = false, ent) {
      Object.assign(this.$data, this.$options.data())
      this.getProject()
      this.ent = ent
      if (!isEdit) {
        this.ent.bgtctltype = '1'
        this.scheduleData = this.ent.schedule
        // for (var i = 1; i < 8; i++) {
        //   var st = new ScheduleType()
        //   st.weekDay = i
        //   st.startHour = []
        //   st.endHour = []
        //   for (var z = 0; z < 24; z++) {
        //     st.endHour.push(z)
        //   }
        //   this.scheduletypes.push(st)
        // }
      }
      if (!isEdit) {
        this.ent.campaignFeedName = '计划_' + this.userInfo.username + '_' + moment().format('YYYY-MM-DD_hh:mm:ss') + '_名称'
      }
      this.isEdit = isEdit
      this.visible = true
      this.ent.bgtctltype = this.ent.bgtctltype + ''
      this.$nextTick(() => {
        if (this.isEdit) {
          this.getProduct(this.ent.bsProjectId)
          if (this.ent.budget === null) {
            this.budgetType = '0'
          } else {
            this.budgetType = '-1'
          }
          if (this.ent.starttime === null && this.ent.endtime === null) {
            this.startAndEnd = '0'
          } else {
            this.startAndEnd = '-1'
            this.startAndEndTime = []
            this.startAndEndTime.push(this.ent.starttime)
            this.startAndEndTime.push(this.ent.endtime)
          }
          if (this.ent.schedule === null) {
            this.scheduleType = '0'
          } else {
            this.scheduleType = '-1'
            // this.scheduletypes = JSON.parse(this.ent.schedule)
            // this.scheduleData = this.scheduletypes
            this.scheduleData = JSON.parse(this.ent.schedule)
          }

          console.log('不能为空')
        }
      })
    },
    // 时间段组件方法
    handleConfirm(needClose = true) {
      const { handleAdd, handleClose } = this.$refs.schedule
      handleAdd()
      if (needClose) {
        handleClose()
      }
    },
    handleChange(value) {
      this.scheduleData = value
    },
    handleClick(params) {
      console.log(params)
    },
    handleClear() {
      this.scheduleData = null
    }
  }
}
</script>

<style>
.el-dialog__body {
  background: #f2f4f8;
}

.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 1px;
  background: #fff;
}
.bg-purple-darks {
  border-radius: 4px;
  padding: 10px;
  padding-left: 12px;
  background: #fff;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.subject-type-block .subject-type-item-wrapper {
  margin-right: 20px;
  margin-bottom: 12px;
  margin-top: 12px;
  position: relative;
  width: 140px;
  height: 100px;
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  color: #282c33;
  background: #fff;
  border: 1px solid #e2e6f0;
  border-radius: 6px;
  box-sizing: border-box;
}
.subject-type-block .subject-type-checked {
  background: #f0f6ff;
  border: 1px solid #0052cc;
}

.subject-type-block .subject-type-item-wrapper i {
  display: inline-block;
  position: relative;
  top: -8px;
  width: 128px;
  height: 88px;
  transform: scale(0.5);
  background: url('../../../../assets/image/xuebi.png');
}

.subject-type-block .subject-type-item-wrapper .subject-title {
  position: absolute;
  top: 64px;
  width: 100%;
}
.subject-type-block .subject-type-item-wrapper i.bg-icon1 {
  background-position: -160px -8px;
}
.subject-type-block .subject-type-item-wrapper i.bg-icon3 {
  background-position: -165px -120px;
}
.subject-type-block .subject-type-item-wrapper i.bg-icon99 {
  background-position: -320px -344px;
}
.subject-type-block .subject-type-item-wrapper i.bg-icon7 {
  background-position: -169px -344px;
}
.subject-type-block .subject-type-item-wrapper i.bg-icon6 {
  background-position: -6px -236px;
}
.subject-type-block .subject-type-item-wrapper:hover {
  border: 1px solid #0052cc;
}

.medisplay {
  display: flex;
  align-items: center;
}
.meContent {
  display: flex;
  justify-content: center;
}
.meH1 {
  font-size: 20px;
  color: #282c33;
  margin-bottom: 14px;
}
</style>
