import { render, staticRenderFns } from "./brand.vue?vue&type=template&id=18390542&"
import script from "./brand.vue?vue&type=script&lang=js&"
export * from "./brand.vue?vue&type=script&lang=js&"
import style0 from "./brand.vue?vue&type=style&index=0&id=18390542&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/Data/ad_frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('18390542')) {
      api.createRecord('18390542', component.options)
    } else {
      api.reload('18390542', component.options)
    }
    module.hot.accept("./brand.vue?vue&type=template&id=18390542&", function () {
      api.rerender('18390542', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/advManagement/tencent/create/component/brand.vue"
export default component.exports