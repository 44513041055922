/*
 * @Description: Do not edit
 * @Date: 2021-10-18 11:53:23
 * @LastEditTime: 2021-10-25 13:46:55
 */
export let widgetGalleryGroup = [
  {
    title: '基础组件',
    widgets: [
      {
        name: '图片',
        type: 'img',
        icon: 'el-icon-picture-outline'
      },
      {
        name: '轮播图',
        type: 'slider',
        icon: 'el-icon-money'
      },
      {
        name: '文本',
        type: 'text',
        icon: 'el-icon-tickets'
      }
    ]
  },
  {
    title: '转化按钮',
    widgets: [
      {
        name: '关注公众号',
        type: 'copyGh',
        icon: 'el-icon-document-copy'
      },
      {
        name: '跳转小程序',
        type: 'applet',
        icon: 'el-icon-connection'
      },
      {
        name: '快应用',
        type: 'quickapp',
        icon: 'el-icon-position'
      }
    ]
  }
]
