<!--
 * @Description: 微信广告程序化
 * @Date: 2021-12-06 09:32:07
 * @LastEditTime: 2022-05-06 11:21:48
-->
<template>
  <div>
    <!-- 列表查询 -->
    <el-form ref="search" :inline="true" :model="search" class="search-form search-form-4">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="任务名称" prop="taskName">
            <el-input v-model="search.taskName" placeholder="请输入任务名称" style="width: 100%"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="创建人" prop="createBy">
            <el-input v-model="search.createBy" placeholder="请输入创建人" style="width: 100%"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="提交状态" prop="pushStatus">
            <el-select v-model="search.pushStatus" style="width: 100%">
              <el-option label="不限" value="1"></el-option>
              <el-option label="配置中" value="2"></el-option>
              <el-option label="待提交" value="3"></el-option>
              <el-option label="提交中" value="4"></el-option>
              <el-option label="提交完成" value="5"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="创建时间" prop="createTime">
            <el-date-picker v-model="search.createTime" style="width: 100%" type="daterange" prange-separator="至" start-placeholder="开始时间" value-format="yyyy-MM-dd" end-placeholder="结束时间"> </el-date-picker>
          </el-form-item>
        </el-col>
        <!-- style="text-align:right" -->
        <el-col :span="24">
          <el-form-item>
            <el-button type="primary" round @click="onSubmit">查 询</el-button>
            <el-button type="primary" round @click="resetForm('ruleForm')">重 置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- 表格 -->

    <div style="text-align:left " class="mb-15">
      <router-link :to="{ name: 'createWeChatAmountAd' }" tag="span">
        <el-button type="primary" icon="el-icon-plus" round> 新建任务 </el-button>
      </router-link>
    </div>
    <TablePane :data-source="dataSource" @getPage="getPage">
      <template slot="operation" slot-scope="{ row }">
        <!--  -->
        <!--  -->
        <el-button
          v-if="['2', '3', '5'].includes(row.webShowStatus) || row.webShowStatus !== '4'"
          :disabled="(userInfo.userid !== row.createById && NODE_ENV === 'production') || (+row.webShowStatus === 5 && NODE_ENV === 'production')"
          type="text"
          @click="
            () => {
              $router.push({ name: 'editWeChatAmountAd', params: { taskId: row.taskId } })
            }
          "
        >
          <el-tooltip content="继续" placement="top">
            <i class="el-icon-edit" style="font-size: 16px"></i>
          </el-tooltip>
        </el-button>

        <el-button :disabled="(userInfo.userid !== row.createById && NODE_ENV === 'production') || +row.webShowStatus === 5" type="text" class="red" @click="deleteTask(row.taskId)">
          <el-tooltip content="删除" placement="top">
            <i class="el-icon-delete" style="font-size: 16px"></i>
          </el-tooltip>
        </el-button>

        <el-button v-if="row.webShowStatus === '5'" type="text" @click="detailTask(row)">
          <el-tooltip content="详情" placement="top">
            <i class="el-icon-document" style="font-size: 16px"></i>
          </el-tooltip>
        </el-button>
      </template>
    </TablePane>

    <el-dialog
      title="任务详情"
      :visible.sync="descVisible"
      width="1000px"
      :modal-append-to-body="false"
      destroy-on-close
      @close="
        () => {
          showErrorFlag = false
        }
      "
    >
      <span>
        <el-descriptions>
          <el-descriptions-item label="任务名称">{{ dataSource_data_info.taskName }}</el-descriptions-item>
          <el-descriptions-item label="项目">{{ dataSource_data_info.projectName }}</el-descriptions-item>
          <el-descriptions-item label="产品">{{ dataSource_data_info.adMpManagementTaskLocalProductENTS ? dataSource_data_info.adMpManagementTaskLocalProductENTS.map((item) => item.productName) + '' : '不限' }}</el-descriptions-item>
          <el-descriptions-item label="提交广告数">{{ dataSource_data_info.sumNum }}</el-descriptions-item>
          <el-descriptions-item label="创建人">{{ dataSource_data_info.createBy }}</el-descriptions-item>
          <el-descriptions-item label="创建时间">{{ dataSource_data_info.createTime }}</el-descriptions-item>
        </el-descriptions>
        <el-checkbox v-model="showErrorFlag" style="margin-top: 30px" @change="showError"> 仅查看失败</el-checkbox>
        <TablePane :data-source="dataSource_desc" @getPage="getDescPage"> </TablePane>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Page, showDetails, publishTask, Delete } from '@/network/api/advManagement/advManagementWatch'
import { getDateStartEnd } from '@/utils/ruoyi'
// import moment from 'moment'
import { mapGetters } from 'vuex'
import TablePane from '@/components/tablePane.vue'
const statusList = {
  '1': '不限',
  '2': '配置中',
  '3': '待提交',
  '4': '提交中',
  '5': '提交完成'
}
let promotedObjectTypeList = {
  PRODUCTTYPE_WECHAT_SHOP: '推广商品',
  PRODUCTTYPE_LEAD_AD: '收集销售线索',
  PRODUCTTYPE_WECHAT: '推广公众号'
}
export default {
  components: {
    TablePane
  },
  data() {
    return {
      search: {
        taskName: null,
        createTime: '',
        createBy: null,
        pushStatus: null
      },
      pageSize: 10,
      descVisible: false,
      showErrorFlag: false,
      descData: [],
      dataSource_data: [],
      dataSource_data_info: {},
      dataSource: {
        data: [], // 表格数据
        cols: [
          {
            label: '任务名称',
            prop: 'taskName'
          },
          {
            label: '创建时间',
            prop: 'createTime'
          },
          {
            label: '创建人',
            prop: 'createBy'
          },
          {
            label: '项目',
            prop: 'projectName'
          },
          {
            label: '产品',
            render: (record) => <p>{record.adMpManagementTaskLocalProductENTS ? record.adMpManagementTaskLocalProductENTS.map((item) => item.productName) + '' : '不限'}</p>
          },
          {
            label: '投放账户',
            noTip: true,
            render: (record) => (
              /* eslint-disable */
              <el-tooltip class="item" effect="dark" placement="top">
                <template slot="content">
                  <p style="max-width: 200px; padding: 0; margin: 0">{record.accountENTList && record.accountENTList.map((item) => item.accountName) + ''}</p>
                </template>
                <p class="text-ellipsis">{record.accountENTList && record.accountENTList.map((item) => item.accountName) + ''}</p>
              </el-tooltip>
              /* eslint-disable */
            )
          },
          {
            label: '推广目标',
            render: (record) => <p>{promotedObjectTypeList[record.promotedObjectType]}</p>
          },
          {
            label: '提交广告数',
            render: (record) => <p>{record.sumNum || 0}</p>
          },
          {
            label: '状态',
            width: 160,
            render: (record) => {
              return (
                /*eslint-disable*/
                <div class="status">
                  <p style="font-size: 11px">{statusList[record.webShowStatus]}</p>
                  <div class="status-num">
                    <p style="font-size: 11px">
                      成功：<span style="color:green">{record.successNum}</span>
                    </p>
                    <p style="font-size: 11px">
                      失败：<span style="color:red">{record.failNum}</span>
                    </p>
                  </div>
                </div>
                /*eslint-disable*/
              )
            }
          }
        ], // 表格的列数据
        isIndex: true, // 列表序号
        loading: false, // loading
        isOperation: true,
        pageData: {
          total: 0 // 总条数
        },
        handleSelectionChange() {},
        operation: {
          // 表格有操作列时设置
          label: '操作', // 列名
          width: 120,
          data: [
            // {
            //   // label: '继续 ', // 操作名称
            //   icon: 'el-icon-edit',
            //   tooltip: '继续', // 按钮类型
            //   // disabled: true,
            //   showLabel: 'webShowStatus',
            //   showValue: ['2', '3', '4', '5'],
            //   handleRow: async (index, record) => {
            //     this.$router.push({ name: 'editWeChatAmountAd', params: { taskId: record.taskId } })
            //   } // 自定义事件
            // },
            // {
            //   label: '复制', // 操作名称
            //   showLabel: 'webShowStatus',
            //   showValue: ['5'],
            //   handleRow: async (index, record) => {}
            // },
            // {
            //   icon: 'el-icon-delete', // 操作名称
            //   tooltip: '删除', //'删除', // 按钮类型
            //   color: '#ff0000',
            //   // disabled: true,
            //   handleRow: async (index, record) => {
            //     const { code } = await Delete(record.taskId)
            //     if (code === 200) {
            //       this.getPage()
            //       this.$message.success('删除成功!')
            //     }
            //   }
            // },
            // {
            //   tooltip: '详情 ', // 操作名称
            //   icon: 'el-icon-document',
            //   showLabel: 'webShowStatus',
            //   showValue: ['5'],
            //   handleRow: async (index, record) => {
            //     this.descVisible = true
            //     this.dataSource_data_info = record
            //     this.getDescPage({ taskId: record.taskId })
            //   } // 自定义事件
            // }
          ]
        }
      },
      dataSource_desc: {
        data: [], // 表格数据
        cols: [
          {
            label: '账户Id',
            prop: 'accountId'
          },
          {
            label: '账户名称',
            prop: 'accountName'
          },
          {
            label: '计划名称',
            prop: 'cname'
          },
          {
            label: '广告组名称',
            prop: 'aname'
          },
          {
            label: '创意名称',
            prop: 'tname'
          },
          {
            label: '状态',
            // eslint-disable-next-line
            render: (record) => {
              return (
                <div>
                  {record.code === -2 ? (
                    <el-tag>提交中</el-tag>
                  ) : record.code === -1 ? (
                    <el-tag>待提交</el-tag>
                  ) : record.code === 200 ? (
                    <el-tag type="success">成功</el-tag>
                  ) : (
                    <el-tooltip class="item" effect="dark" placement="top">
                      <template slot="content">
                        <span style="max-width: 200px;">{record.message || '无'}</span>
                      </template>
                      <el-tag type="danger">失败</el-tag>
                    </el-tooltip>
                  )}
                </div>
              )
            }
          }
        ], // 表格的列数据
        isIndex: true, // 列表序号
        loading: true, // loading
        isOperation: true,
        pageData: {
          pageNum: 1,
          total: 0, // 总条数
          layout: 'total, prev, pager, next'
        },
        handleSelectionChange() {},
        operation: {
          // 表格有操作列时设置
          label: '操作', // 列名
          width: 100,
          data: [
            {
              label: '重新提交', // 操作名称
              type: 'text', // 按钮类型
              showLabel: 'code',
              showNotValue: [-1, 200],
              handleRow: async (index, record) => {
                this.dataSource_desc.loading = true
                const { code } = await publishTask(record.taskId)
                if (code === 200) {
                  this.$message.success('提交中,请稍后查看!')
                  this.dataSource_desc.loading = false
                  this.descVisible = false
                  this.getPage()
                }
              } // 自定义事件
            },
            {
              label: '-', // 操作名称
              type: 'text', // 按钮类型
              showLabel: 'code',
              showValue: [-1, 200]
            }
          ]
        }
      }
    }
  },

  mounted() {
    this.getPage()
  },

  computed: {
    ...mapGetters(['userInfo'])
  },

  methods: {
    /**
     * @description: 获取列表
     * @param {*} params
     */
    getPage(params = { pageNum: 1, pageSize: this.pageSize }) {
      this.dataSource.loading = true
      const [createTimeStart, createTimeEnd] = getDateStartEnd(this.search.createTime)
      let search = {
        ...this.search,
        createTimeEnd,
        createTimeStart
      }
      this.pageSize = params.pageSize
      delete search.createTime
      Page({ ...params, ...search }).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          this.dataSource.data = records
          this.dataSource_data = records
          this.dataSource.pageData.total = total
          this.dataSource.pageData.pageNum = params.pageNum
          this.dataSource.loading = false
        }
      })
    },

    async getDescPage({ taskId = this.dataSource_data_info.taskId, pageNum = 1, pageSize = 10, isSuccess = !this.showErrorFlag }) {
      this.dataSource_desc.loading = true
      const { code, data } = await showDetails({ taskId, pageNum, pageSize, isSuccess })
      if (code === 200) {
        this.dataSource_desc.data = data.adMpManagementTaskGenerateENT
        this.dataSource_desc.pageData.total = data.page.total
        this.dataSource_desc.pageData.pageNum = pageNum
        this.dataSource_desc.pageData
        this.dataSource_desc.loading = false
        // this.dataSource_data = data.records
      }
    },

    /**
     * @description:查看失败数据
     */
    showError(flag) {
      this.getDescPage({ pageNum: 1, pageSize: 10, isSuccess: !flag })
    },
    /**
     * @description: 重置
     */
    resetForm() {
      this.$refs['search'].resetFields()
      this.getPage()
    },

    /**
     * @description: 删除任务
     */
    async deleteTask(taskId) {
      const { code } = await Delete(taskId)
      if (code === 200) {
        this.getPage()
        this.$message.success('删除成功!')
      }
    },

    async detailTask(record) {
      this.descVisible = true
      this.dataSource_data_info = record
      this.getDescPage({ taskId: record.taskId })
    },

    /**
     * @description: 查询
     */
    onSubmit() {
      this.getPage()
    }
  }
}
</script>

<style>
.is-disabled {
  color: #c0c4cc !important;
}
</style>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.status {
  padding-right: 20px;
  p {
    padding: 0;
    margin: 0;
  }
  .status-num {
    display: flex;
    justify-content: space-between;
    p {
      font-size: 13px;
      color: rgb(153, 153, 153);
    }
  }
}
.red {
  color: #ff0000;
}
</style>
