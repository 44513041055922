<!--
 * @Description: Do not edit
 * @Date: 2021-09-15 09:48:06
 * @LastEditTime: 2023-07-20 17:29:45
-->
<template>
  <div class="home">
    <el-card shadow="hover">
      <div class="user">
        <el-avatar icon="el-icon-user-solid" :src="userInfo.avatar"></el-avatar>
        <span>欢迎,{{ userInfo.username }}.</span>
        <router-link to="/hugeAmountBatch"></router-link>
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  computed: {
    ...mapGetters(['userInfo'])
  }
}
</script>

<style scoped lang="scss">
.user {
  display: flex;
  align-items: center;

  span {
    margin-left: 20px;
  }
}
</style>
