<!--
 * @Description: 创意文案
 * @Date: 2021-12-06 15:57:16
 * @LastEditTime: 2022-05-19 10:22:26
-->
<template>
  <div>
    <!-- 朋友圈 -->
    <el-form-item v-if="crtSizeLabel === '朋友圈'" prop="circleOfFriendsText" label="文案:" :rules="rules.circleOfFriendsText">
      <SearchSelectAsync v-model="item.circleOfFriendsText" placeholder="请输入朋友圈文案" style="width: 510px" :max-length="40" />
    </el-form-item>

    <!-- 优雅横版大图 -->
    <el-form-item v-if="crtSizeLabel === '优雅横版大图'" prop="infoBarText" label="信息栏文案:" :rules="rules.infoBarText">
      <SearchSelectAsync v-model="item.infoBarText" placeholder="请输入信息栏文案" style="width: 510px" />
    </el-form-item>
    <el-form-item v-if="crtSizeLabel === '优雅横版大图'" prop="graphicTextTitle" label="图文标题:" :rules="rules.graphicTextTitle">
      <SearchSelectAsync v-model="item.graphicTextTitle" placeholder="请输入图文标题" style="width: 510px" />
    </el-form-item>
    <el-form-item v-if="crtSizeLabel === '优雅横版大图'" label="广告文案:" prop="advTextOne" :rules="rules.advTextOne">
      <SearchSelectAsync v-model="item.advTextOne" placeholder="请输入首行文案" style="width: 510px" :max-length="16" />
    </el-form-item>
    <el-form-item v-if="crtSizeLabel === '优雅横版大图'" label="" prop="advTextTwo" :rules="rules.advTextTwo">
      <SearchSelectAsync v-model="item.advTextTwo" placeholder="请输入次行文案" style="width: 510px" :max-length="16" />
    </el-form-item>
    <el-form-item v-if="crtSizeLabel === '优雅横版大图'" prop="advTextThree" label="" :rules="rules.advTextThree">
      <SearchSelectAsync v-model="item.advTextThree" placeholder="请输入第三行文案(选填)" style="width: 510px" :max-length="16" />
    </el-form-item>

    <!-- 沉浸式横版视频 -->
    <el-form-item v-if="crtSizeLabel === '沉浸式横版视频'" prop="adCardCopyText" label="广告卡片文案:" :rules="rules.adCardCopyText">
      <SearchSelectAsync v-model="item.adCardCopyText" placeholder="请输入广告卡片文案" style="width: 510px" />
    </el-form-item>
    <el-form-item v-if="crtSizeLabel === '沉浸式横版视频'" prop="infoBarText" label="信息栏文案:" :rules="rules.infoBarText">
      <SearchSelectAsync v-model="item.infoBarText" placeholder="请输入信息栏文案" style="width: 510px" />
    </el-form-item>
    <el-form-item v-if="crtSizeLabel === '沉浸式横版视频'" label="广告文案:" prop="advTextOne" :rules="rules.advTextOne">
      <SearchSelectAsync v-model="item.advTextOne" placeholder="请输入首行文案" style="width: 510px" :max-length="16" />
    </el-form-item>
    <el-form-item v-if="crtSizeLabel === '沉浸式横版视频'" label="" prop="advTextTwo" :rules="rules.advTextTwo">
      <SearchSelectAsync v-model="item.advTextTwo" placeholder="请输入次行文案" style="width: 510px" :max-length="16" />
    </el-form-item>
    <el-form-item v-if="crtSizeLabel === '沉浸式横版视频'" :rules="rules.advTextThree" label="" prop="advTextThree">
      <SearchSelectAsync v-model="item.advTextThree" placeholder="请输入第三行文案(选填)" style="width: 510px" :max-length="16" />
    </el-form-item>

    <el-form-item v-if="crtSizeLabel === '沉浸式横版视频' && creativityData.isEndPage" label="结束页文案:" prop="tailTextTitle" :rules="rules.tailTextTitle">
      <SearchSelectAsync v-model="item.tailTextTitle" placeholder="请输入结束页文案" style="width: 510px" />
    </el-form-item>

    <!-- 公众号文章底部图片 -->
    <el-form-item v-if="crtSizeLabel === '公众号文章底部图片'" label="信息栏文案:" prop="infoBarText" :rules="rules.infoBarText">
      <SearchSelectAsync v-model="item.infoBarText" placeholder="请输入信息栏文案" style="width: 510px" />
    </el-form-item>

    <!-- 公众号文章底部视频 -->
    <el-form-item v-if="crtSizeLabel === '公众号文章底部视频'" label="信息栏文案:" prop="infoBarText" :rules="rules.infoBarText">
      <SearchSelectAsync v-model="item.infoBarText" placeholder="请输入信息栏文案" style="width: 510px" />
    </el-form-item>
    <el-form-item v-if="crtSizeLabel === '公众号文章底部视频' && creativityData.isEndPage" label="结束页文案:" prop="tailTextTitle" :rules="rules.tailTextTitle">
      <SearchSelectAsync v-model="item.tailTextTitle" placeholder="请输入结束页文案" style="width: 510px" />
    </el-form-item>

    <!-- 公众号文章中部 -->
    <el-form-item v-if="crtSizeLabel === '公众号文章中部'" prop="text" label="信息栏文案:" :rules="rules.text">
      <SearchSelectAsync v-model="item.text" placeholder="请输入信息栏文案" style="width: 510px" />
    </el-form-item>
  </div>
</template>

<script>
import SearchSelectAsync from './searchSelectAsync.vue'
export default {
  components: {
    SearchSelectAsync
  },
  props: {
    /** 广告位置 */
    crtSizeLabel: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: () => {}
    },
    projectId: {
      type: String,
      default: ''
    },
    creativityData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      rules: {
        text: [
          { required: true, message: '请输入信息栏文案', trigger: 'change' },
          { max: 12, message: '长度最多为12个字符', trigger: 'change' }
        ],
        tailTextTitle: [
          { required: true, message: '请输入结束页文案', trigger: 'change' },
          { max: 12, message: '长度最多为12个字符', trigger: 'change' }
        ],
        infoBarText: [
          { required: true, message: '请输入信息栏文案', trigger: 'change' },
          { max: 12, message: '长度最多为12个字符', trigger: 'change' }
        ],
        advTextOne: [
          { required: true, message: '请输入首行文案', trigger: 'change' },
          { max: 16, message: '长度最多为16个字符', trigger: 'change' }
        ],
        advTextTwo: [
          { required: true, message: '请输入次行文案', trigger: 'change' },
          { max: 16, message: '长度最多为16个字符', trigger: 'change' }
        ],
        advTextThree: [{ required: false }],
        graphicTextTitle: [
          { required: true, message: '请输入图文文案', trigger: 'change' },
          { max: 12, message: '长度最多为12个字符', trigger: 'change' }
        ],
        circleOfFriendsText: [
          { required: true, message: '请输入朋友圈文案', trigger: 'change' },
          { max: 40, message: '长度最多为40个字符', trigger: 'change' }
        ],
        adCardCopyText: [
          { required: true, message: '请输入广告卡片文案', trigger: 'change' },
          { max: 12, message: '长度最多为12个字符', trigger: 'change' }
        ]
      }
    }
  }
}
</script>

<style lang="scss"></style>
