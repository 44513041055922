<!--
 * @Description: 基础框
 * @Date: 2021-11-09 09:19:23
 * @LastEditTime: 2022-02-10 10:29:07
-->

<template>
  <div class="base">
    <header>
      <p>
        {{ title }}<span class="tip ml-20" style="color: #ee4343">{{ desc }}</span>
      </p>
      <slot name="topRight"></slot>
    </header>
    <!-- <el-divider style="padding: 0; margin: 0" /> -->
    <section>
      <slot></slot>
    </section>
    <div class="footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
.base {
  .el-divider {
    margin: 10px 0;
    padding: 0;
  }
}
</style>

<style lang="scss" scoped>
p {
  margin: 0;
  padding: 0;
}

.base {
  // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
  // background-color: #f9f9f9;
  // border: 1px solid #e5e5e5;
  // border-radius: 7px;
  padding: 10px 0;
  margin-bottom: 30px;
  header {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  p {
    font-size: 20px;
    font-weight: 700;
    border-left: 3px solid #409eff;
    padding-left: 6px;
    height: 28px;
  }
  section {
    padding: 20px 20px 0 20px;
  }
  .footer {
    margin-top: 20px;
    padding: 0 10px;
  }
}
</style>
