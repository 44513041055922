var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "v-crop-box", on: { wheel: _vm.onMouseWheel } },
    [
      _c("img", {
        ref: "imageRef",
        class: { "v-crop-img": true, "v-crop-img--moving": _vm.didMouseDown },
        style: _vm.style,
        attrs: {
          crossOrigin: "anonymous",
          src: _vm.src,
          alt: "img",
          draggable: "false",
        },
        on: { load: (e) => _vm.onLoad(e) },
      }),
      _c("div", {
        class: {
          "v-crop-selection-active": _vm.didMouseDown,
          "v-crop-selection": true,
        },
        style: _vm.cropStyle,
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }