var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "clearfix" },
    [
      _c(
        "el-pagination",
        _vm._g(
          _vm._b(
            {
              staticClass: "pagination flr",
              attrs: {
                layout: _vm.tools,
                "current-page": _vm.no,
                "hide-on-single-page": _vm.hideOnSinglePage,
                "page-size": _vm.size,
                "page-sizes": _vm.sizes,
                total: _vm.total,
                "pager-count": _vm.pagerCount,
              },
              on: {
                "update:currentPage": function ($event) {
                  _vm.no = $event
                },
                "update:current-page": function ($event) {
                  _vm.no = $event
                },
                "update:pageSize": function ($event) {
                  _vm.size = $event
                },
                "update:page-size": function ($event) {
                  _vm.size = $event
                },
              },
            },
            "el-pagination",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }