var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    _vm._g(
      _vm._b(
        {
          attrs: {
            value: _vm.value,
            "filter-method": _vm.handleFilter,
            filterable: "",
            clearable: "",
          },
          on: { focus: _vm.clearSelect, clear: _vm.clearSelect },
        },
        "el-select",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    _vm._l(_vm.optionsList, function (item) {
      return _c(
        "el-option",
        _vm._b(
          {
            key: item[_vm.props.value],
            attrs: {
              label: item[_vm.props.label],
              value: item[_vm.props.value],
            },
          },
          "el-option",
          _vm.$attrs,
          false
        )
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }