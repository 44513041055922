<!--
 * @Description: Do not edit
 * @Date: 2021-11-10 19:20:55
 * @LastEditTime: 2022-04-18 14:55:22
-->
<template>
  <el-dialog title="同步投放账号" width="1000px" :visible.sync="visible" :before-close="close" :destroy-on-close="true" label-position="right" :modal-append-to-body="false" :close-on-press-escape="false" :close-on-click-modal="false">
    <span>
      <el-form ref="ent" :model="ent" :rules="rules" label-width="90px" class="demo-ruleForm justify-sb" :inline="true">
        <el-form-item label="开户主体: " prop="houseKeeper" :rules="{ required: true, message: '请选择开户主体', trigger: 'change' }">
          <el-select v-model="ent.houseKeeper" style="width: 400px">
            <el-option v-for="item in subject" :key="item.appId" :label="item.subjectName" :value="item.subjectId" />
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" :disabled="ent.houseKeeper === null" round @click="showAddSubmit">新增账户</el-button>
          <el-button type="primary" round :disabled="ent.houseKeeper === null" @click="getAdvertiserNewFun">刷新</el-button>
        </el-form-item>
      </el-form>
      <el-divider> 账号选择</el-divider>
      <el-row :gutter="10">
        <el-col class="justify-sb">
          <el-input v-model="ent.search" placeholder="请输入账号名称查询" style="width: 300px" size="small"><el-button slot="append" icon="el-icon-search" @click="getAdvertiserNewFun"></el-button></el-input>
          <p>已选 {{ dataSource_sync_right_all.length }} 个</p>
        </el-col>
        <el-col :span="12">
          <TablePane ref="multipleTable" class="mt-10" :data-source="dataSource_sync" @getPage="getAdvertiserNewFun" />
        </el-col>
        <el-col :span="12">
          <el-table v-loading="table_right_loading" :data="dataSource_sync_right_all" style="width: 100%" height="490" class="list-table mt-10">
            <el-table-column label="账号名称" align="center" show-overflow-tooltip>
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.onlinkUserName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="账号Id" align="center" show-overflow-tooltip>
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.onlinkAdvertiserId }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- <el-pagination style="text-align:right" :current-page.sync="currentPage1" :page-size="10" layout="total, prev, pager, next" :total="dataSource_sync_right_all.length" @current-change="handleCurrentChange"> </el-pagination> -->
        </el-col>
      </el-row>

      <!-- 账户配置 -->
      <el-divider>账户配置</el-divider>
      <el-form ref="accountConfig" :inline="true" :model="accountConfig" class="demo-ruleForm justify-sb">
        <el-form-item label="项目" prop="relationProjectId" :rules="{ required: true, message: '请选择项目', trigger: 'change' }">
          <el-select v-model="accountConfig.relationProjectId" placeholder="请选择项目" @change="bsProjectChange">
            <el-option v-for="item in projectList" :key="item.bsProjectId" :label="item.projectName" :value="item.bsProjectId" />
          </el-select>
        </el-form-item>

        <el-form-item label="产品" prop="relationProductId" :rules="{ required: true, message: '请选择产品', trigger: 'change' }">
          <el-select v-model="accountConfig.relationProductId" placeholder="请选择产品">
            <el-option label="不限" :value="0"></el-option>
            <el-option v-for="(itm, idx) in productList" :key="idx" :label="itm.productName" :value="itm.bsProductId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="人员" prop="launchUserId" :rules="{ required: true, message: '请选择人员', trigger: 'blue' }">
          <el-select v-model="accountConfig.launchUserId" value-key="key" filterable clearable placeholder="请选择投放人员">
            <el-option v-for="itm in userList" :key="itm.key" :label="itm.label" :value="itm.key"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </span>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" :disabled="!visible || dataSource_sync_right_all.length === 0" @click="submitForm('accountConfig')"> {{ rowAccountId ? '同步' : '添加' }}</el-button>
      <el-button @click="close">取消 </el-button>
    </span>
  </el-dialog>
</template>
<script>
var windowObjectReference = {
  closed: null
}
import { mapGetters } from 'vuex'
import TablePane from '@/components/tablePane.vue'
import { getAdvertiserNew, Sync, getSubject, Add } from '@/network/api/account/kuaishouAdvAccount'
import { getMeRelationProject } from '@/network/api/basicSetting/project/basicSettingProject'
import { getProductByProject } from '@/network/api/basicSetting/product/basicSettingProduct'
import { formRules } from './object'
import { getUser } from '@/network/api/user'
import AccountBaidu from '@/mixin/account_baidu'
export default {
  inject: ['loadData'],
  components: {
    TablePane
  },
  mixins: [AccountBaidu],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    rowAccountId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      // 管家账户
      houseKeeper: [],
      projectList: [],
      productList: [],
      userList: [],
      pageNum: 1,
      ent: {
        houseKeeper: null,
        search: null
      },
      subject: [],
      accountConfig: {
        relationProjectId: null,
        relationProductId: 0,
        launchUserId: null
      },
      rules: formRules,
      // 选中的列信息 start
      syncSelectRow: [],
      table_right_loading: false,
      dataSource_sync_right: [],
      dataSource_sync_right_all: [],
      dataSource_syncDataAll: [],
      // 选中的列 end
      dataSource_sync: {
        data: [],
        cols: [
          {
            label: '账号名称',
            with: 100,
            prop: 'onlinkUserName'
          },
          {
            label: '账户ID',
            prop: 'onlinkAdvertiserId'
          }
        ], // 表格的列数据
        isIndex: false, // 列表序号
        loading: false, // loading
        isOperation: false,
        isSelection: true,
        pageData: {
          total: 0, // 总条数
          layout: 'total, prev, pager, next'
        },
        selectAll: (selection) => {
          if (selection.length) {
            console.log(this.dataSource_sync_right_all)

            this.table_right_loading = true
            this.dataSource_sync_right_all.push(...selection)
            let list = []
            this.dataSource_sync_right_all.map((item) => {
              if (list.length === 0 || !list.some((s) => s.onlinkAdvertiserId === item.onlinkAdvertiserId)) {
                list.push(item)
              }
            })
            this.dataSource_sync_right_all = list
            this.table_right_loading = false
          } else {
            let ids = JSON.parse(JSON.stringify(this.dataSource_sync.data)).map((f) => f.onlinkAdvertiserId)
            this.dataSource_sync_right_all = this.dataSource_sync_right_all.filter((f) => !ids.includes(f.onlinkAdvertiserId))
          }
        },
        select: (selection, row) => {
          if (selection.length > 0) {
            this.table_right_loading = true

            if (selection.some((s) => s.onlinkAdvertiserId === row.onlinkAdvertiserId)) {
              this.dataSource_sync_right_all.push(...selection)
            } else {
              this.dataSource_sync_right_all = this.dataSource_sync_right_all.filter((f) => f.onlinkAdvertiserId !== row.onlinkAdvertiserId)
            }

            let list = []
            this.dataSource_sync_right_all.map((item) => {
              if (list.length === 0 || !list.some((s) => s.onlinkAdvertiserId === item.onlinkAdvertiserId)) {
                list.push(item)
              }
            })
            this.dataSource_sync_right_all = list
            this.table_right_loading = false
          }

          if (!selection.some((s) => s.onlinkAdvertiserId === row.onlinkAdvertiserId)) {
            this.dataSource_sync_right_all = this.dataSource_sync_right_all.filter((f) => f.onlinkAdvertiserId !== row.onlinkAdvertiserId)
            this.table_right_loading = false
          }
        }
      }
    }
  },

  computed: {
    ...mapGetters(['userInfo'])
  },

  watch: {
    visible: {
      handler(value) {
        if (value) {
          this.accountConfig = {
            ...this.accountConfig,
            launchUserId: this.userInfo.userid
          }
          this.getProject()
          this._getUserList()
        }
      }
    }
  },

  mounted() {
    getSubject().then((res) => {
      this.subject = res.data
    })
    let _this = this
    var loop = setInterval(function() {
      if (windowObjectReference.closed) {
        clearInterval(loop)
        _this.getAdvertiserNewFun()
      }
    }, 1000)
  },

  methods: {
    // 获取成员列表
    _getUserList() {
      getUser().then((res) => {
        if (res.code === 200) {
          this.userList = res.data
        }
      })
    },

    // 获取账户列表
    getAdvertiserNewFun({ pageNum = 1 }) {
      this.dataSource_sync.loading = true
      getAdvertiserNew({
        appId: this.ent.houseKeeper,
        name: this.ent.search,
        pageNum,
        pageSize: 10
      }).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          this.dataSource_sync.data = records
          this.dataSource_sync.pageData.total = total
          this.dataSource_sync.pageData.pageNum = pageNum
        }
        this.dataSource_sync.loading = false
      })
    },

    handleDelete(index, row) {
      this.dataSource_sync_right_all = this.dataSource_sync_right_all.filter((f) => f.onlinkAdvertiserId !== row.onlinkAdvertiserId)
      this.getPage({ pageNum: this.pageNum })
    },

    bsProjectChange(val) {
      this.ent.productId = undefined
      this.getProduct(val)
    },

    getProject() {
      getMeRelationProject().then((res) => {
        if (res.code === 200) {
          this.projectList = res.data
        }
      })
    },

    getProduct(val) {
      getProductByProject(val).then((res) => {
        if (res.code === 200) {
          this.productList = res.data
        }
      })
    },

    showAddSubmit() {
      Add(this.ent.houseKeeper).then(({ code, data }) => {
        if (code === 200) {
          windowObjectReference = window.open(data, 'authorize', 'width=900,height=700,left=100,top=0,menubar=no,toolbar=no')
          this.showAddVisible = false
        }
      })
    },

    submitForm() {
      const { launchUserId, relationProjectId, relationProductId } = this.accountConfig
      if (launchUserId && relationProjectId && +relationProductId >= 0) {
        this.$emit('setVisible', false)
        let req = {
          relationProjectId,
          relationProjectName: this.projectList.filter((item) => item.bsProjectId === relationProjectId)[0].projectName,

          relationProductId: relationProductId !== 0 ? relationProductId : 0,
          relationProductName: relationProductId !== 0 ? this.productList.filter((item) => item.bsProductId === relationProductId)[0].productName : '不限',
          launchUserId: launchUserId,
          launchUserName: this.userList.length ? this.userList.find((item) => item.key === launchUserId).label : null,

          accountLocalIds: this.dataSource_sync_right_all.map((item) => item.accountLocalId)
        }

        Sync(req).then(({ code }) => {
          if (code === 200) {
            this.$emit('getPage')
            this.$message.success(this.rowAccountId ? '同步管家账户成功!' : '添加账户成功!')
          }
          this.close()
          this.loadData()
        })
      } else {
        this.$message.error('请选择项目')
      }
    },

    close() {
      this.$refs['multipleTable'].getRowData([])
      this.dataSource_sync_right = []
      this.dataSource_syncDataAll = []
      this.dataSource_sync.data = []
      this.dataSource_sync.pageData.total = 0
      this.dataSource_sync_right_all = []
      this.ent.houseKeeper = null
      this.ent.search = null
      this.accountConfig = {
        relationProjectId: null,
        relationProductId: 0,
        launchUserId: null
      }
      this.$nextTick(() => {
        this.$emit('setVisible', false)
      })
    }
  }
}
</script>
