<!--
 * @Description: Do not edit
 * @Date: 2021-12-03 09:41:49
 * @LastEditTime: 2021-12-03 11:00:12
-->
<template>
  <div class="list-box">
    <div class="list-box-2">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.list-box {
  width: 400px;
  height: 300px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-left: 20px;
  padding-top: 1px;
  padding-bottom: 1px;
  margin-top: 10px;
  .list-box-2 {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    & > div {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
