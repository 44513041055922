<!--
 * @Description: Do not edit
 * @Date: 2021-10-26 16:18:32
 * @LastEditTime: 2022-04-25 09:52:24
-->
<template>
  <el-form ref="searchForm" class="search-form search-form-4" :model="searchForm">
    <el-row :gutter="20">
      <el-col :span="6">
        <el-form-item prop="pageName" label="名称">
          <el-input v-model="searchForm.pageName" placeholder="请输入推广页名称"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="createBy" label="创建人">
          <el-input v-model="searchForm.createBy" placeholder="请输入创建人"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-button class="btn" type="primary" @click="search">
          查询
        </el-button>
        <el-button class="btn" @click="resetSearch">
          重置
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { searchForm } from './object'
export default {
  inject: ['loadData'],
  data() {
    return {
      searchForm: { ...searchForm }
    }
  },
  methods: {
    search() {
      this.loadData(true)
    },
    // 折中方案
    getSearchForm() {
      return { ...this.searchForm }
    },
    resetSearch() {
      this.searchForm = searchForm
      this.$refs.searchForm.resetFields()
      this.search()
    }
  }
}
</script>
