<!--
 * @Author: Zeng
 * @Date: 2024-03-19 13:48:46
 * @LastEditTime: 2024-03-25 11:54:51
-->
<template>
  <el-dialog class="table-template" title="关联循环页" :visible.sync="show" :append-to-body="true" :destroy-on-close="true" width="800px" top="20vh"
    @close="onCloseDialog">
    <div class="page-container">
      <el-row :gutter="20" type="flex" justify="space-between" align="middle">
        <el-col :span="12">
          名称：{{ form.toolPageName }}
        </el-col>
        <el-col :span="12" style="text-align: right;">
          循环类型：<el-select v-model="form.cycleType" placeholder="请选择循环类型" style="width: 120px"
            @change="handleChangeCycleType">
            <el-option label="指定循环" :value="1"></el-option>
            <el-option label="随机循环" :value="2"></el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row :gutter="10" type="flex" justify="center">
        <el-col :span="10">
          <div class="transfer-box">
            <div class="transfer-header">
              <span class="title">未关联循环页</span>
            </div>
            <div class="transfer-search">
              <el-input placeholder="请输入落地页名称" v-model="search.name" size="mini" style="width: 100%;">
                <template slot="append">
                  <el-button type="primary" size="mini" @click="getTransfers">搜索</el-button>
                </template>
              </el-input>
            </div>
            <div class="transfer-table">
              <el-table ref="multipleTable" :data="transfers.data" class="list-table" size="mini"
                v-loading="transfers.loading" :row-key="row => { return row.toolPageId }" @select="handleSelectChange">
                <el-table-column type="selection" label="选择" width="55" :selectable="checkSelectable"
                  :reserve-selection="true"></el-table-column>
                <el-table-column prop="toolPageName" label="名称">
                  <template slot-scope="{row}">
                    <span>
                      <el-tooltip :content="row.toolPageName" placement="top">
                        <span>{{ row.toolPageName.length > 10 ? row.toolPageName.slice(0, 10) + '...' : row.toolPageName
                        }}</span>
                      </el-tooltip>
                    </span>
                  </template>
                </el-table-column>
              </el-table>
              <div class="page">
                <paginations layout="prev, pager, next" :total="transfers.total" :page.sync="transfers.pageNum"
                  :page-sizes="[10]" :limit.sync="transfers.pageSize" @pagination="getTransfers" />
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="transfer-btn-box">
            <div>
              <el-button type="info" round icon="el-icon-arrow-left" plain></el-button>
            </div>
            <div>
              <el-button type="info" round icon="el-icon-arrow-right" plain></el-button>
            </div>
          </div>
        </el-col>
        <el-col :span="10">
          <div class="transfer-box">
            <div class="transfer-header">
              <span class="title">已关联循环页</span>
              <span class="count">{{ checkedData.length }}/{{ transfers.maxSelect }}</span>
            </div>
            <div class="transfer-table">
              <el-table :data="checkedData" class="list-table" size="mini">
                <el-table-column prop="toolPageName" label="名称">
                  <template slot-scope="{row}">
                    <span>
                      <el-tooltip :content="row.toolPageName" placement="top">
                        <span>{{ row.toolPageName.length > 10 ? row.toolPageName.slice(0, 10) + '...' : row.toolPageName
                        }}</span>
                      </el-tooltip>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column prop="order" label="优先级">
                  <template slot="header" slot-scope="{row}">
                    <el-tooltip class="item" effect="dark" content="数字越小优先级越高" placement="top-start">
                      <span :key="row">优先级 <i class="el-icon-question" style="color: #E6A23C;"></i></span>
                    </el-tooltip>
                  </template>
                  <template slot-scope="{row, $index}">
                    <el-select v-model="row.order" placeholder="" style="width: 70px" size="mini"
                      :disabled="form.cycleType == 2">
                      <el-option v-for="item in 5" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                    <i @click="handleDeleteSelected($index)" class="el-icon-delete ml-10"
                      style="font-size: 14px;color: #F56C6C;cursor: pointer;"></i>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">取消</el-button>
      <el-button class="btn" type="primary" :disabled="loading" @click="handleConfirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getCustomLandPageList, relatedLandingPage } from '@/network/api/toolManagement/api-landing-page'

export default {
  data() {
    return {
      show: false,
      loading: false,
      search: {
        name: '',
        toolPageType: 3
      },
      transfers: {
        loading: false,
        data: [],
        total: 0,
        pageNum: 1,
        pageSize: 10,
        maxSelect: 5
      },
      checkedData: [],
      form: {
        toolPageId: '',
        toolPageName: '',
        cycleType: 2
      },
      rules: {
        name: [{ required: true, message: '请输入落地页名称', trigger: 'blur' }]
      }
    }
  },

  created() { },

  mounted() { },
  methods: {
    /* --------------------------- Actions --------------------------- */
    showModal(data) {
      if (Object.keys(data).length) {
        this.checkedData = data.lists
        this.form = {
          toolPageId: data.toolPageId,
          toolPageName: data.toolPageName,
          cycleType: this.checkIsCycle(data.lists)
        }
      }
      this.show = true
      this.getTransfers()
    },

    checkIsCycle(data) {
      let orderArr = data.map(v => {
        return v.order
      })
      const isEqual = orderArr.every(val => val === orderArr[0])
      return isEqual ? 2 : 1
    },

    // 获取循环页数据
    getTransfers() {
      this.transfers.loading = false
      let search = {
        ...this.search,
        createDate: '',
        createBy: '',
        toolPageName: this.search.name,
        pageSize: this.transfers.pageSize,
        pageNum: this.transfers.pageNum
      }
      getCustomLandPageList(search).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          let list = records.map(v => {
            v.createBy = v.createBy || '-'
            v.order = 1
            return v
          })
          this.transfers.data = list
          this.transfers.total = total

          // 选中数据回显
          if (this.checkedData.length) {
            this.echo(this.transfers.data)
          }
        }
      }).catch(e => {
        this.transfers.loading = false
      })
    },

    // 表格数据回显
    echo(data) {
      let rows = []
      data.forEach(item => {
        this.checkedData.forEach(item2 => {
          if (item.toolPageId === item2.toolPageId) {
            rows.push(item)
          }
        })
      })
      this.toggleSelection(rows)
    },

    // 勾选表格选中
    toggleSelection(rows) {
      if (rows.length) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row, true)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },

    // 复选框是否可选
    checkSelectable(row, index) {
      let flag = true
      if (this.checkedData.length >= 5) {
        flag = false
      } else {
        flag = true
      }
      return flag
    },

    // 选择未关联循环页
    handleSelectChange(selection, row) {
      let ids = this.checkedData.map(v => v.toolPageId)
      if (!ids.includes(row.toolPageId)) {
        // 指定循环
        if (this.form.cycleType === 1) {
          row.order = this.checkedData.length + 1
        }
        this.checkedData.push(row)
      } else {
        let index = this.checkedData.findIndex(v => v.toolPageId === row.toolPageId)
        this.checkedData.splice(index, 1)
      }
    },

    // 删除已选关联
    handleDeleteSelected(index) {
      let selected = this.checkedData.splice(index, 1)
      let list = this.transfers.data
      if (selected.length) {
        list.forEach((obj, index) => {
          selected.forEach(item => {
            if (obj.toolPageId === item.toolPageId) {
              setTimeout(() => {
                this.$nextTick(() => this.$refs.multipleTable.toggleRowSelection(list[index], false))
              }, 10)
            }
          })
        })
      }
    },

    // 修改循环类型
    handleChangeCycleType(value) {
      this.checkedData.map((v, index) => {
        // 随机循环都为1
        if (value === 2) {
          v.order = 1
        }
        return v
      })
    },

    // 点击确定按钮
    handleConfirm() {
      if (!this.checkedData.length) {
        this.$message.warning('请选择落地页')
        return
      }

      let data = this.checkedData.map(v => {
        return {
          toolPageId: v.toolPageId,
          order: v.order
        }
      })
      let params = {
        toolPageId: this.form.toolPageId,
        lists: data
      }
      this.loading = true
      relatedLandingPage(params).then(({ code }) => {
        if (code === 200) {
          this.$SuccessMessage('关联成功')
          this.closeModal()
        }
      }).finally(() => {
        this.loading = false
      })
    },
    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      // 重置
      this.search = {
        name: '',
        toolPageType: 3
      }
      this.transfers = {
        loading: false,
        data: [],
        total: 0,
        pageNum: 1,
        pageSize: 10,
        maxSelect: 5
      }
      this.checkedData = []
      this.form = {
        toolPageId: '',
        toolPageName: '',
        cycleType: 2
      }
      this.$refs.multipleTable.clearSelection()
      this.$emit('confirm')
    },
    // 关闭弹框
    closeModal() {
      this.show = false
    }
  }
}
</script>

<style lang="scss">
.transfer-box {
  .input-append-btn .el-input-group__append {
    background-color: #409eff;
    color: #fff;
  }

  .el-table th.el-table__cell>.cell .el-checkbox {
    display: none;
  }
}
</style>
<style scoped lang="scss">
.transfer-box {
  border: solid 1px #eee;
  padding: 0 20px;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 20px;
  min-height: 260px;
  height: 100%;

  .transfer-header {
    padding: 10px 0;
    margin-bottom: 10px;
    border-bottom: solid 1px #eee;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: #878787;

    span:first-child {
      font-size: 16px;
      color: #333;
    }
  }

  .transfer-table {
    padding-bottom: 20px;
    box-sizing: border-box;
  }
}

.transfer-btn-box {
  margin-top: 150px;
  text-align: center;

  div:first-child {
    margin-bottom: 10px;
  }
}
</style>
