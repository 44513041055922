/*
 * @Description: Do not edit
 * @Date: 2021-09-15 09:48:06
 * @LastEditTime: 2022-02-15 09:26:58
 */
import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/store-user'
import landPage from './modules/store-custom-landpage'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {
    userInfo: (state) => state.user.userInfo,
    menu: (state) => state.user.menu,
    sideMenu: (state) => state.user.sideMenu,
    breadcrumbMenu: (state) => state.user.breadcrumbMenu,
    isCollapse: (state) => state.user.isCollapse
  },
  mutations: {},
  actions: {},
  modules: {
    user: user,
    landPage: landPage
  }
})
