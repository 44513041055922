import service from '@/network/service/service'

export function Page(data) {
  return service.post('/api/system/basicSettingLabel/page', data)
}

export function Add(data) {
  return service.post('/api/system/basicSettingLabel/save', data)
}

export function Update(data) {
  return service.post('/api/system/basicSettingLabel/update', data)
}

export function DeleteLabel(id) {
  return service.delete('/api/system/basicSettingLabel/' + id)
}

