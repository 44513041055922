<template>
  <el-form ref="searchForm" class="search-form search-form-4" :model="searchForm">
    <el-row :gutter="20">
      <el-col :span="6">
        <el-form-item prop="productName" label="产品名称">
          <el-input v-model="searchForm.productName" placeholder="请输入产品名称" @keyup.enter.native="search" />
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-form-item prop="productTypeId" label="产品名称">
          <el-select v-model="searchForm.productTypeId" filterable default-first-option placeholder="请输入产品品类">
            <el-option v-for="item in typeList" :key="item.bsProductTypeId" :label="item.typeName" :value="item.bsProductTypeId" />
          </el-select>
          <!-- <el-input v-model="searchForm.productTypeId" placeholder="产品品类" /> -->
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="relationProjectId" label="关联项目">
          <el-select v-model="searchForm.relationProjectId" filterable default-first-option placeholder="请输入关联项目" @change="projectChange">
            <el-option v-for="item in projectList" :key="item.bsProjectId" :label="item.projectName" :value="item.bsProjectId" />
          </el-select>
          <!-- <el-input v-model="searchForm.relationProjectName" placeholder="关联项目名称" /> -->
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="productSourceId" label="产品来源">
          <el-select v-model="searchForm.productSourceId" filterable default-first-option placeholder="请输入产品来源">
            <el-option v-for="item in sourceList" :key="item.bsProductSourceId" :label="item.source" :value="item.bsProductSourceId" />
          </el-select>
          <!-- <el-input v-model="searchForm.productSourceId" placeholder="产品来源" /> -->
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-form-item prop="isUseing" label="是否启用">
          <el-select v-model="searchForm.isUseing" filterable default-first-option placeholder="请选择">
            <el-option v-for="item in isUseingList" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
          <!-- <el-input v-model="searchForm.isUseing" placeholder="是否启用" /> -->
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-button class="btn" type="primary" @click="search">
          查询
        </el-button>
        <el-button class="btn" @click="resetSearch">
          重置
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { searchForm, isUseingList } from './object'
import { getIsUseingData } from '@/network/api/basicSetting/product/basicSettingProductSource'
import { getAllIsUseing } from '@/network/api/basicSetting/product/basicSettingProductType'
import { getProjectForProduct } from '@/network/api/basicSetting/project/basicSettingProject'

export default {
  inject: ['loadData'],
  data() {
    return {
      searchForm: { ...searchForm },
      isUseingList: isUseingList,
      sourceListBackups: [],
      sourceList: [],
      projectList: [],
      objSoucer: {},
      typeList: []
    }
  },
  mounted() {
    this.getType()
    this.getSource()
    this.getProject()
  },

  methods: {
    getProject() {
      getProjectForProduct().then((res) => {
        if (res.code === 200) {
          const data = res.data
          if (undefined !== data.PROJECT) {
            this.projectList = data.PROJECT
          }
          if (undefined !== data.SOURCE) {
            this.objSoucer = data.SOURCE
            this.sourceList = data.SOURCE
          }
        }
      })
    },
    projectChange(val) {
      // this.sourceList = []
      // this.searchForm.productSourceId = null
      // if (this.objSoucer[val] !== undefined) {
      //   this.sourceList = this.objSoucer[val]
      // }
    },
    getType() {
      getAllIsUseing().then((res) => {
        if (res.code === 200) {
          this.typeList = res.data
        }
      })
    },
    getSource() {
      getIsUseingData().then((res) => {
        if (res.code === 200) {
          this.sourceList = res.data
          this.sourceListBackups = res.data
        }
      })
    },
    search() {
      this.loadData(true)
    },
    // 折中方案
    getSearchForm() {
      return { ...this.searchForm }
    },
    resetSearch() {
      this.getType()
      this.getSource()
      this.getProject()

      this.$refs.searchForm.resetFields()
    }
  }
}
</script>
