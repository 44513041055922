<!--
 * @Description: 推广计划模板选择
 * @Date: 2021-11-09 09:32:28
 * @LastEditTime: 2022-05-19 14:00:03
-->
<template>
  <div v-loading="loading">
    <Base title="基础信息">
      <el-form :inline="true" :model="ruleData" class="demo-form-inline ">
        <el-form-item label="任务名称:" prop="taskName" :rules="{ required: true, message: '请输入任务名称', trigger: 'change' }">
          <el-input v-model="ruleData.taskName" style="width:300px" @change="changeBaseInfo" />
        </el-form-item>

        <el-form-item label="项目名称:" prop="projectId" :rules="{ required: true, message: '请选择项目', trigger: 'change' }">
          <el-select v-model="ruleData.projectId" style="width:300px" @change="changeProjectId">
            <el-option v-for="(item, index) in projectList" :key="index" :label="item.projectName" :value="item.bsProjectId + ''"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="产品名称:" :rules="{ required: true, message: '请选择产品', trigger: 'change' }">
          <el-select v-model="ruleData.productIds" style="width:300px" @change="changeProductId">
            <el-option label="不限" value="-1"></el-option>
            <el-option v-for="(item, index) in productListed" :key="index" :label="item.productName" :value="item.bsProductId"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label=" 服 务 商: " prop="spid" :rules="{ required: true, message: '请选择服务商', trigger: 'change' }">
          <el-select v-model="ruleData.spid" placeholder="请选择服务商" style="width:300px" @change="changeBaseSpid">
            <!-- :label="`${itm.agencyName} (${itm.mobileId})`" -->
            <el-option v-for="(itm, idx) in adMpAmList" :key="idx" :label="`${itm.agencyName} (${itm.mobileId})`" :value="`${itm.spid}+${itm.id}`"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="投放账户:" prop="ghid" :rules="{ required: true, message: '请选择投放账户', trigger: 'blue' }">
          <!-- <div v-if="paramsTaskId" style="width:300px" class="text-ellipsis">
            <el-tooltip :content="baseInfo.account ? baseInfo.account.map((item) => item.accountName) + '' : ''" placement="top">
              <el-input disabled :value="baseInfo.account ? baseInfo.account.map((item) => item.accountName) + '' : ''" style="width:300px; color: #ccc" class="text-ellipsis"></el-input>
            </el-tooltip>
          </div> -->

          <div>
            <SearchSelectAsync
              ref="selectAsync"
              v-model="ruleData.ghid"
              name="ghids"
              :disabled="!ruleData.spid"
              multiple
              api="/api/tencent/adMpAmsAccounts/page"
              :params="['name']"
              :query="{ spid: ruleData.spid ? ruleData.spid.split('+')[0] : '',putInStatus:1 }"
              :props="{ label: 'name', value: 'ghid' }"
              style="width:300px"
              collapse-tags
              :options-data="optionsData"
              placeholder="请选择投放账户"
              @change="changeAccount"
            />
          </div>
        </el-form-item>

        <el-form-item prop="promotedObjectType" label="推广目标" :rules="{ required: true, message: '请选择推广目标', trigger: 'change' }">
          <el-select v-model="ruleData.promotedObjectType" placeholder="请选择推广目标" style="width:300px" @change="changeBaseInfo">
            <el-option v-for="item in promotionTargetList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </Base>
    <!-- 配置区 -->
    <Deploy
      ref="deployRef"
      :account-adv="accountAdv"
      :loading="loading"
      :task-id="taskId"
      :promoted-object-type="ruleData.promotedObjectType"
      :account-id="ruleData.accountId"
      :project-id="ruleData.projectId"
      :rule-data="ruleData"
      :spid="ruleData.spid"
      :product-listed="productListed"
      @setLoading="
        (value) => {
          loading = value
        }
      "
      @getBaseInfo="getBaseInfo"
      @getShowPreviewFun="getShowPreviewFun"
      @saveTaskFun="saveTaskFun"
    />
    <Base title="预览区">
      <!-- <TablePane ref="tableRef" :data-source="dataSource" class="table" border @getPage="getPage" /> -->
      <el-table v-loading="tableLoading" :data="dataSource_select" style="width: 100%" border class="table-weChat">
        <el-table-column label="投放账户" width="150">
          <template slot-scope="{ row }">
            <p>{{ row.account.accountName }}</p>
          </template>
        </el-table-column>
        <el-table-column label="推广计划数量" width="110">
          <template slot-scope="{ row }">
            <p>{{ row.count }}</p>
          </template>
        </el-table-column>
        <el-table-column label="广告数量" width="100">
          <template slot-scope="{ row }">
            <p>{{ row.count }}</p>
          </template>
        </el-table-column>
        <el-table-column label="计划">
          <el-table-column label="投放位置" show-overflow-tooltip>
            <template slot-scope="{ row }">
              {{ row.plan.isFriendCircle ? '朋友圈信息流' : '公众号平台流量' }}
            </template>
          </el-table-column>
          <el-table-column label="广告位置" show-overflow-tooltip>
            <template slot-scope="{ row }">
              {{ row.plan.crtSizeLabel }}
            </template>
          </el-table-column>
          <el-table-column label="计划日预算">
            <template slot-scope="{ row }">
              <p>{{ row.plan.dayBudget / 100 }}</p>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="广告">
          <el-table-column label="投放时间" width="200px">
            <template slot-scope="{ row }">
              <el-button type="text" @click="editLaunchData(row)">
                <p v-if="row.adData.endTime">
                  {{ `${moment(moment.unix(row.adData.beginTime)).format('YYYY-MM-DD')} ~ ${moment(moment.unix(row.adData.endTime)).format('YYYY-MM-DD')}` }}
                </p>
                <p v-else>
                  长期投放
                </p>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="投放时段">
            <template>
              <p>全天投放</p>
            </template>
          </el-table-column>
          <el-table-column label="优化目标">
            <template slot-scope="{ row }">
              <p>{{ objectiveList[+row.adData.bidObjective] || '' }}</p>
            </template>
          </el-table-column>
          <el-table-column label="广告日预算 (元)">
            <template slot-scope="{ row }">
              <p>{{ row.adData.dayBudget / 100 }}</p>
            </template>
          </el-table-column>
          <el-table-column label="出价 (元)">
            <template slot-scope="{ row }">
              <el-button type="text" @click="editBid(row)">{{ row.adData.bid / 100 }}</el-button>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <el-pagination style="text-align:right" class="mt-10" :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next" :total="dataSource.length" @current-change="handleCurrentChange"> </el-pagination>
    </Base>

    <div class="justify-center mb-20">
      <el-button type="primary" class=" mb-20" :disabled="dataSource.length === 0" @click="bulkSubmit">批量提交</el-button>

      <router-link to="/weChatAmountAdBatch" class="ml-20 mb-20">
        <el-button @click.once="cancelTask">取消任务</el-button>
      </router-link>
    </div>

    <el-dialog title="修改投放日期" :visible.sync="editLaunchDataVisible" width="600px" :modal-append-to-body="false" destroy-on-close>
      <span>
        <el-form ref="editForm" :model="editForm" label-width="120px">
          <el-form-item label="投放时间:" :rules="{ required: true, message: '请选择投放时间', trigger: 'blue' }">
            <el-radio v-model="editForm.launchData" label="1">选择开始和结束日期</el-radio>
            <el-radio v-model="editForm.launchData" label="2">长期投放</el-radio>
          </el-form-item>
          <el-form-item v-if="editForm.launchData === '1'" label="开始/结束日期:" :rules="{ required: true, message: '请选择开始/结束日期', trigger: 'blue' }">
            <el-date-picker v-model="editForm.date" value-format="yyyy-MM-dd" style="width:330px" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
          </el-form-item>
          <el-form-item v-if="editForm.launchData === '2'" label="开始日期:" :rules="{ required: true, message: '请选择开始日期', trigger: 'blue' }">
            <el-date-picker v-model="editForm.date2" value-format="yyyy-MM-dd" type="date" style="width:280px" placeholder="请选择开始日期"> </el-date-picker>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer ">
        <el-button @click="editLaunchDataVisible = false">取 消</el-button>
        <el-button type="primary" @click="getSelectPlanData">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="修改出价" :visible.sync="editBidVisible" width="370px" :modal-append-to-body="false" destroy-on-close>
      <span>
        <el-form ref="editForm2" :model="editForm2">
          <el-form-item label="出 价:">
            <el-input-number v-model="editForm2.bid" controls-position="right" :min="0" style="width: 210px"></el-input-number>
            <span class="ml-10">元</span>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editBidVisible = false">取 消</el-button>
        <el-button type="primary" @click="getSelectPlanData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Base from '../../../advManagement/baidu/baiduBatch/component/base.vue'
import accountUin_tencent from '@/mixin/accountUin_tencent.js'
import Deploy from './deploy.vue'
import { getAdMpAm } from '@/network/api/account/weChatAdvAccount.js'
import { getPromotionTarget, saveTask, updatePlan, deleteTaskId, publishTask, getGenerateList } from '@/network/api/advManagement/advManagementWatch.js'
import moment from 'moment'
import { mapGetters } from 'vuex'
// import _ from 'lodash'

export default {
  name: 'CreateWeChatAmountAd',
  components: {
    Base,
    Deploy
  },
  provide: function() {
    return {
      ruleData: () => this.ruleData,
      baseInfo: () => this.baseInfo
    }
  },
  mixins: [accountUin_tencent],

  data() {
    return {
      paramsTaskId: this.$route.params.taskId || 0,
      loading: !!this.$route.params.taskId,
      optionsData: [], // 编辑时 投放账户 默认值
      baseInfo: {},
      taskId: +this.$route.params.taskId || 0, // 任务Id
      show_ad_button_flag: false,
      adMpAmList: [], // 服务商list
      promotionTargetList: [], // 推广目标 list
      ruleData: {
        taskName: '',
        projectId: '',
        productIds: '-1',
        spid: '',
        ghid: [], // 投放账户
        promotedObjectType: '' // PRODUCTTYPE_WECHAT_SHOP [] // 推广目标
      },
      objectiveList: {
        2: '点击',
        6: '关注',
        7: '下单',
        10: '跳转按钮点击',
        14: '公众号内阅读',
        23: '关键页面访问',
        25: '公众号内注册',
        26: '公众号内发信息',
        32: '授信',
        34: '商品详情页浏览',
        36: '加企业微信客服'
      },
      accountAdv: [], // 选中的账号
      pushLoading: false,
      tableLoading: false,
      editLaunchDataVisible: false, // 修改时间
      editFormBase: {
        localAccountId: '',
        taskAccountId: '',
        generateId: ''
      },
      editForm: {
        launchData: '1',
        date: null,
        date2: null
      },
      editBidVisible: false, // 修改出价
      editForm2: {
        bid: null
      },
      // 表格数据
      dataSource: [],
      dataSource_select: [],
      currentPage: 1
    }
  },

  computed: {
    ...mapGetters(['userInfo'])
  },

  mounted() {
    this.publishAll()
    this._getAdMpAm()
    this._getPromotionTarget()
    this.ruleData.taskName = `${this.userInfo.username}-${moment().format('YYYYMMDDHHmmss')}`
  },

  methods: {
    // 获取服务商
    _getAdMpAm() {
      getAdMpAm().then((res) => {
        if (res.code === 200) {
          this.adMpAmList = res.data
        }
      })
    },

    // 获取推广计划
    _getPromotionTarget() {
      getPromotionTarget().then((res) => {
        if (res.code === 200) {
          this.promotionTargetList = res.data
        }
      })
    },

    // 获取基础信息
    getBaseInfo(base) {
      this.baseInfo = base
      base.account.forEach((item) => {
        item.ghid = `${item.localAccountId}+${item.accountId}+${item.accountName}`
        item.name = item.accountName
      })
      this.optionsData = base.account
      let account = base.account.map((item) => `${item.localAccountId}+${item.accountId}+${item.accountName}`)
      this.selectProjectId = base.management.projectId + ''
      this.ruleData = {
        taskName: base.management.taskName,
        projectId: base.management.projectId + '',
        productIds: base.localProductENT && base.localProductENT.length > 0 ? base.localProductENT[0].productId : '-1',
        spid: `${base.management.agencyName}+${base.management.localAmsId}`,
        ghid: account, // 投放账户
        promotedObjectType: base.management.promotedObjectType
      }
      this.accountAdv = base.account.map((item) => {
        return {
          taskAccountId: item.taskAccountId,
          name: item.accountName,
          ghid: item.accountId
        }
      })
    },

    /**
     * @description 生成taskId
     */
    saveTaskFun(value) {
      const { projectId, ghid, spid, promotedObjectType, taskName } = this.ruleData

      if (projectId === '' || !ghid.length || spid === '' || promotedObjectType === '' || taskName === '') {
        return this.$message.info('请先选择基础信息')
      }

      if (this.taskId !== 0) {
        this.$refs['deployRef'][value] = true
      } else {
        this.createTask(value)
      }
    },

    /**
     * @description 生成Task
     */
    createTask(value) {
      const { projectId, productIds, ghid, spid, promotedObjectType, taskName } = this.ruleData
      saveTask({
        adMpManagementTaskRequest: {
          taskName,
          promotedObjectType,
          productIsLimit: productIds !== '-1',
          projectId,
          projectName: this.projectList.filter((item) => item.bsProjectId === +projectId)[0].projectName,
          agencyName: spid.split('+')[0],
          localAmsId: spid.split('+')[1]
        },
        adMpManagementTaskLocalProductRequestList:
          /* eslint-disable */
          productIds !== '-1' ? [{ productId: productIds, productName: this.productListed.filter((item) => item.bsProductId === +productIds)[0].productName }] : null,
        /* eslint-disable */
        adMpManagementTaskAccountRequests: ghid.map((item) => {
          return {
            localAccountId: item.split('+')[0],
            accountId: item.split('+')[1],
            accountName: item.split('+')[2]
          }
        })
      }).then((res) => {
        if (res.code === 200) {
          this.taskId = +res.data.taskId
          this.$refs['deployRef'][value] = true
        }
      })
    },

    /**
     * @description 编辑获取数据
     */
    publishAll() {
      let { taskId } = this.$route.params
      if (taskId) {
        this.taskId = +taskId
        this.$refs['deployRef'].isRuleDeploy = true
        this.$refs['deployRef'].byTaskIdGetAllFun(taskId)
      }
    },

    /**
     * @description 批量提交
     */
    async bulkSubmit() {
      const { code } = await publishTask(this.taskId)
      if (code === 200) {
        this.$message.success('已提交!')
        this.startNewTask()
        this.$router.push('/weChatAmountAdBatch')
      }
    },

    /**
     * @description 点击修改投放时间
     */
    editLaunchData(row) {
      this.editLaunchDataVisible = true
      this.editFormBase = {
        localAccountId: row.account.localAccountId,
        taskAccountId: row.account.taskAccountId,
        generateId: row.generate.id
      }
      this.editForm = {
        launchData: row.adData.endTime ? '1' : '2',
        date: [moment(moment.unix(row.adData.beginTime)).format('YYYY-MM-DD'), moment(moment.unix(row.adData.endTime)).format('YYYY-MM-DD')],
        date2: moment(moment.unix(row.adData.beginTime)).format('YYYY-MM-DD')
      }
    },

    /**
     * @description 点击修改出价
     */
    editBid(row) {
      this.editBidVisible = true
      this.editFormBase = {
        localAccountId: row.account.localAccountId,
        taskAccountId: row.account.taskAccountId,
        generateId: row.generate.id
      }

      this.editForm2 = {
        launchData: row.adData.endTime ? '1' : '2',
        bid: row.adData.bid / 100
      }
    },

    /**
     * @desc 修改出价,投放时间
     */
    getSelectPlanData() {
      let params = {
        taskId: this.taskId,
        localAccountId: this.editFormBase.localAccountId,
        taskAccountId: this.editFormBase.taskAccountId,
        generateId: this.editFormBase.generateId
      }

      if (this.editBidVisible) {
        params.bid = +this.editForm2.bid * 100
      } else {
        params.bid = null
      }

      if (this.editLaunchDataVisible) {
        params.launchData = this.editForm.launchData
        params.beginTime = this.editForm.launchData === '1' ? moment(this.editForm.date[0]).unix() : moment(this.editForm.date2).unix()
        params.endTime = this.editForm.launchData === '2' ? null : moment(this.editForm.date[1]).unix()
      } else {
        params.launchData = null
        params.beginTime = null
        params.endTime = null
      }

      updatePlan(params).then((res) => {
        if (res.code === 200) {
          this.$emit('byTaskIdGetAllFun')
          this.$message.success('广告修改成功!')
          this.getGenerateListFun()
          this.editBidVisible = false
          this.editLaunchDataVisible = false
        }
      })
    },

    async getGenerateListFun() {
      const { code, data } = await getGenerateList(this.taskId) // { taskId: this.taskId, pageNum, pageSize }
      if (code === 200) {
        let dataList = []
        let adDataBase = data.adMpManagementGenerateRelationENTS
        data.adMpManagementTaskAccountENTS.map((item) => {
          item.adMpManagementTaskGenerateENT.map((item_task) => {
            let adData = JSON.parse(JSON.stringify(data.showDataitems.adMpManagementTaskPlanAdGroupENT))
            let filterData = []
            if (adDataBase.length) {
              filterData = adDataBase.filter((f) => f.generateId === item_task.id)
              if (filterData.length) {
                adData.bid = filterData[0].bid || adData.bid
                adData.beginTime = filterData[0].beginTime || adData.beginTime
                adData.endTime = filterData[0].launchData === 2 ? null : filterData[0].endTime
              }
            }
            dataList.push({
              generate: item_task,
              account: item,
              count: item.adMpManagementTaskGenerateENT.length,
              baseData: data.showDataitems.adMpManagementTaskPlanCampaignENT,
              plan: {
                ...data.showDataitems.adMpManagementTaskPlanCampaignENT,
                dayBudget: data.showDataitems.adMpManagementTaskPlanCampaignENT.dayBudget / 100
              },
              creativityData: data.showDataitems.adMpManagementTaskENT,
              adData: filterData.length
                ? JSON.parse(JSON.stringify(adData))
                : {
                    ...data.showDataitems.adMpManagementTaskPlanAdGroupENT,
                    dayBudget: data.showDataitems.adMpManagementTaskPlanAdGroupENT.dayBudget / 100
                  }
            })
          })
        })
        this.dataSource = dataList
        this.dataSource_select = dataList.slice(0, 10)
      }
    },

    handleCurrentChange(val) {
      this.tableLoading = true
      let start = (val - 1) * 10
      this.dataSource_select = this.dataSource.slice(start, val * 10)
      setTimeout(() => {
        this.tableLoading = false
      }, 200)
    },

    async getPage({ pageNum, pageSize }) {
      this.$refs['tableRef'].showPreviewFun({ pageNum, pageSize })
    },

    /**
     * @description 设置TaskId
     */
    setTaskId(value) {
      this.taskId = value
    },

    getShowPreviewFun(data) {
      this.tableLoading = true
      this.dataSource = []
      this.dataSource_select = []
      this.dataSource = data
      this.dataSource_select = data.slice(0, 10)
      setTimeout(() => {
        this.tableLoading = false
      }, 350)
    },
    /**
     * @description 开始新任务
     */
    startNewTask() {
      this.taskId = 0
      this.ruleData = {
        // 后期修改
        projectId: '',
        productIds: '-1',
        accountId: [], // 投放账户
        promotedObjectType: 'PROMOTED_OBJECT_TYPE_LEAD_AD' // [] // 推广目标
      }
      this.accountAdv = []
      this.dataSource = []
      this.$refs['deployRef'].startNewTask()
    },

    /**
     * @description 获取选中的投放账户
     */
    changeAccount(values) {
      let accountAdv = []
      values.map((item) => {
        const [id, ghid, name] = item.split('+')
        accountAdv.push({
          name,
          ghid
        })
      })
      this.accountAdv = accountAdv
      this.changeBaseInfo()
    },

    /**
     * @description 修改基础信息
     */
    changeBaseInfo() {
      if (this.taskId) {
        this.$refs['deployRef'].updateBaseInfoFun()
        this.dataSource = []
        this.dataSource_select = []
      }
    },

    changeProjectId(value) {
      
      this.selectProjectId = value
      this.ruleData.productIds = '-1'
      this.changeBaseInfo()
    },

    changeBaseSpid() {
      this.ruleData.ghid = ''
      this.optionsData = []
      this.$refs['selectAsync'].optionsList = []
      this.changeBaseInfo()
    },

    changeProductId(value) {
      this.selectProductId = value
      this.changeBaseInfo()
    },

    /**
     * @description 取消任务
     */
    async cancelTask() {
      if (this.taskId !== 0) {
        const { code } = await deleteTaskId(this.taskId)
        if (code === 200) {
          this.$message.success('任务已取消!')
          this.$router.push({ path: '/weChatAmountAdBatch' })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
p {
  padding: 0;
  margin: 0;
}
.table-pane {
  padding: 10px 40px 0 40px !important;
  text-align: right;
}

.bottom-btn {
  display: flex;
  justify-content: center;
}

.divider {
  border-right: 1px solid #dcdfe6;
  padding-right: 5px;
}
.title-count {
  border-bottom: 1px #ccc solid;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 0 3px 8px 1px;
  p {
    padding: 0;
    margin: 0;
  }
}
.footer-add {
  border-top: 1px #ccc solid;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding-top: 10px;
  cursor: pointer;
  color: #409eff;
}
</style>
