export const searchForm = {
  trackId: null,
  transFrom: null,
  transTypes: null,
  transName: null,
  housekeeperId: null,
  housekeeperName: null,
  baiduId: null,
  accountName: null,
  transStatus: null,
  deleted: null,
  createBy: null,
  createTime: null,
  updateBy: null,
  updateTime: null,
  remark: null
}

export class AssetManagementBaiduTrackEnt {
  trackId
  transFrom
  transTypes
  transName
  housekeeperId
  housekeeperName
  baiduId
  accountName
  transStatus
  deleted
  createBy
  createTime
  updateBy
  updateTime
  remark
}

export const formRules = {
  trackId: [{ required: true, message: '请填写', trigger: 'change' }],
  transFrom: [{ required: true, message: '请填写接入方式', trigger: 'change' }],
  transTypes: [{ required: true, message: '请填写转化类型	Integer[]', trigger: 'change' }],
  transName: [{ required: true, message: '请填写转化名称', trigger: 'change' }],
  housekeeperId: [{ required: true, message: '请填写本地id', trigger: 'change' }],
  housekeeperName: [{ required: true, message: '请填写账号管家', trigger: 'change' }],
  baiduId: [{ required: true, message: '请填写投放账户id', trigger: 'change' }],
  accountName: [{ required: true, message: '请填写账户名称', trigger: 'change' }],
  transStatus: [{ required: true, message: '请填写激活状态', trigger: 'change' }],
  deleted: [{ required: true, message: '请填写逻辑删除', trigger: 'change' }],
  createBy: [{ required: true, message: '请填写创建者', trigger: 'change' }],
  createTime: [{ required: true, message: '请填写创建时间', trigger: 'change' }],
  updateBy: [{ required: true, message: '请填写更新者', trigger: 'change' }],
  updateTime: [{ required: true, message: '请填写更新时间', trigger: 'change' }],
  remark: [{ required: true, message: '请填写备注', trigger: 'change' }]
}
