<!--
 * @Description: 巨量回传  回传设置
 * @Date: 2021-10-19 19:05:18
 * @LastEditTime: 2024-01-18 10:46:37
-->

<template>
  <div class="mb-20">
    <!-- 列表查询 -->
    <el-form ref="search" :inline="true" :model="search" class="search-form search-form-4">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="投放平台" prop="type">
            <el-select v-model="search.type" placeholder="请选择投放平台" style="width: 300px">
              <!-- <el-option label="全部" :value="null"></el-option> -->
              <el-option v-for="item in platform_4" :key="item.dictValue" :label="item.dictLabel" :value="+item.dictValue"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="来源" prop="platform">
            <el-select v-model="search.platform" placeholder="请选择来源">
              <el-option v-for="item in platformList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="名称/ID" prop="accounts">
            <el-input v-model="search.accounts" placeholder="请输入名称/ID,多个以空格区隔"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="回传状态" prop="status">
            <el-select v-model="search.status">
              <el-option label="全部" :value="null"></el-option>
              <el-option label="未推送" value="0"></el-option>
              <el-option label="推送完成" value="1"></el-option>
              <el-option label="推送失败" value="2"></el-option>
              <el-option label="手动推送" value="3"></el-option>
              <el-option label="需要手动推送" value="4"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="广告 I D " prop="adid">
            <el-input v-model="search.adid" placeholder="请输入广告ID"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="订单类型" prop="order_type">
            <el-select v-model="search.order_type">
              <el-option label="全部" :value="null"></el-option>
              <el-option label="未知" value="0"></el-option>
              <el-option label="首充" value="1"></el-option>
              <el-option label="复充" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="起始充值金额" prop="start_true_money">
            <el-input-number v-model="search.start_true_money" controls-position="right" :min="0" :max="search.end_true_money"></el-input-number>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="结束充值金额" prop="end_true_money">
            <el-input-number v-model="search.end_true_money" controls-position="right" :max="1000000" :min="search.start_true_money"></el-input-number>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="推广链接ID" prop="promotion_id">
            <el-input v-model="search.promotion_id" placeholder="请输入推广链接ID"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="注册时间" prop="user_createtime">
            <el-date-picker v-model="search.user_createtime" type="daterange" value-format="yyyy-MM-dd " range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="下单时间" prop="finish_time">
            <el-date-picker v-model="search.finish_time" type="daterange" value-format="yyyy-MM-dd " range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" round @click="onSubmit">查询</el-button>
            <el-button round @click="resetForm('search')">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row :gutter="20" style="margin-top: 10px; margin-bottom: 10px">
      <el-col :span="6" :offset="18">
        <div>
          <span style="color: #409EFF">{{ extra_data.total_user }}/{{ extra_data.true_user || 0 }}</span>
          <span>个付费用户，</span>
          <span style="color: #409EFF">{{ extra_data.total }}</span>笔订单，总金额
          <span style="color: #409EFF">{{ extra_data.total_money / 100 }}</span>元
          <!-- {{ `${extra_data.total_user}个付费用户，${extra_data.total}笔订单，总金额${extra_data.total_money / 100}元` }} -->
        </div>
      </el-col>
    </el-row>
    <!-- 表格 -->
    <TablePane :data-source="dataSource" @getPage="getPage"></TablePane>

    <el-dialog class="table-template" :title="'推送失败订单回传'" :visible.sync="returnDataVisible" :append-to-body="true" width="900px" @close="resetForm('returnData')">
      <span>
        <el-form ref="returnData" :inline="true" :model="returnData" style="width: 100%">
          <el-form-item label="广告ID: " prop="adid">
            <el-input v-model="returnData.adid" placeholder="请输入广告ID查询" style="width:260px; margin-right: 30px" />
          </el-form-item>

          <el-form-item label="数据时间: " prop="date">
            <el-date-picker v-model="returnData.date" value-format="yyyy-MM-dd" style="width:300px" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="() => {returnDataSearching = true;getPageReturn()}">查询</el-button>
          </el-form-item>
        </el-form>
      </span>

      <el-empty v-if="!returnDataSearching" class="return-empty-text" description="请通过广告ID查询回传数据"></el-empty>
      <TablePane v-else ref="TablePane" :data-source="dataSourceReturn" @getPage="getPageReturn"></TablePane>

      <!--底部按钮-->
      <div slot="footer" class="dialog-footer">
        <el-button class="btn" @click="resetForm('returnData')">取 消</el-button>
        <el-button class="btn" type="primary" @click="submitReturnData">回 传</el-button>
      </div>
    </el-dialog>

    <HugeCallbackDialog ref="hugeCallbackDialog" @submit="handleOk" />
    <VnovelCallbackDialog ref="vnovelCallbackDialog" @submit="handleOk" />
    <TencentVNovelsCallbackDialog ref="tencentVNovelsCallbackDialog" @submit="handleOk" />
    <CallBackLogDialogVue ref="callBackLogDialog" />
  </div>
</template>

<script>
import TablePane from '../../../components/tablePane.vue'
import { getDateStartEnd } from '@/utils/ruoyi'
import { transformPage, handHugeReturn, virtualCallback, virtualCallbackPage } from '@/network/api/passBack/index.js'
import moment from 'moment'
import Clipboard from 'clipboard'
import HugeCallbackDialog from '@/views/passback/return/HugeCallbackDialog'
import VnovelCallbackDialog from '@/views/passback/return/VnovelCallbackDialog'
import TencentVNovelsCallbackDialog from './TencentVNovelsCallbackDialog.vue'
import CallBackLogDialogVue from './callBackLogDialog.vue'

export default {
  components: {
    HugeCallbackDialog,
    VnovelCallbackDialog,
    TencentVNovelsCallbackDialog,
    TablePane,
    CallBackLogDialogVue
  },
  data() {
    return {
      vnovelPlatform: ['V1001', 'V1002', 'V1003', 'V1004'],
      platform_4: [],
      search: {
        platform: 'P1001',
        /** 公众号 */
        accounts: null,
        /** 回传状态 */
        status: null,
        /** 下单时间*/
        finish_time: [],
        /** 下单时间*/
        /** 注册时间*/
        user_createtime: [],
        /** 注册时间*/
        /** 充值金额*/
        start_true_money: 0,
        /** 充值金额*/
        end_true_money: 100,
        /** 广告ID*/
        adid: null,
        /** 订单类型*/
        order_type: null,
        type: 3,
        /** 推广链接id */
        promotion_id: null
      },
      returnDataVisible: false,
      /** 推送失败订单回传搜索状态 */
      returnDataSearching: false,
      callbackLogVisible: false,
      extra_data: null,
      virtualCallbackData: {
        platform: '',
        deliveryPlatform: ''
      },

      recordId: null,
      returnData: {
        adid: null,
        date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        type: 3
      },
      returnDataSelectList: [],
      dataSourceReturnData: [],
      platformList: [],
      copyParams: null,
      dataSourceReturn: {
        data: [], // 表格数据
        cols: [
          {
            label: '广告ID',
            width: 180,
            render: function (record) {
              return <span>{record.adid || '_'}</span>
            }
          },
          {
            label: 'URl',
            prop: 'complete_url'
          },
          {
            label: '创建时间',
            width: 205,
            render: function (record) {
              return <span>{moment.unix(+record.created_time).format('YYYY-MM-DD HH:mm:ss')}</span>
            }
          }
        ], // 表格的列数据
        isIndex: true, // 列表序号
        loading: false, // loading
        isOperation: false,
        isSelection: true,
        pageData: {
          pageNum: 1,
          total: 0 // 总条数
        },
        selectAll: (selection) => {
          if (selection.length > 0) {
            this.returnDataSelectList = [selection[0]]
          } else {
            this.returnDataSelectList = []
          }
          this.setSelectData()
        },

        select: (selection, row) => {
          if (selection.length > 0) {
            this.returnDataSelectList = [selection[selection.length - 1]]
          } else {
            this.returnDataSelectList = []
          }
          this.setSelectData()
        }
      },
      dataSource: {
        data: [], // 表格数据
        cols: [
          {
            label: '投放平台',
            fixed: 'left',
            width: 100,
            render: (record) => <span>{this.platform_4.find((f) => +f.dictValue === +record.type).dictLabel}</span>
          },
          {
            label: '来源',
            fixed: 'left',
            width: 100,
            render: (record) => <p>{this.platformList.find((f) => f.dictValue === record.platform).dictLabel}</p>
          },
          {
            label: '名称',
            width: 100,
            fixed: 'left',
            prop: 'public_name'
          },
          {
            label: 'ID',
            width: 150,
            prop: 'ghid'
          },
          {
            label: '广告ID',
            prop: 'adid',
            width: 150
          },
          {
            label: '订单ID',
            width: 200,
            prop: 'merchant_id',
            isCopy: true,
            handleRow: (index, record) => {
              var clipboard = new Clipboard('#copy' + record.merchant_id)
              clipboard.on('success', (e) => {
                this.$message.success('复制成功')
                // 释放内存
                clipboard.destroy()
              })
              clipboard.on('error', (e) => {
                // 不支持复制
                this.$message.error('不支持复制')
                // 释放内存
                clipboard.destroy()
              })
            },
            render: function (record) {
              /*eslint-disable*/
              return (
                  <el-tag id={'btnLink' + record.merchant_id} class="text-ellipsis" data-clipboard-text={record.merchant_id}>
                    {record.merchant_id}
                  </el-tag>
              )
            }
          },
          {
            label: '用户昵称',
            prop: 'nickname'
          },
          {
            label: '用户ID',
            prop: 'uid'
          },
          {
            label: '推广链ID',
            width: 100,
            prop: 'promotion_id'
          },
          {
            label: '注册时间',
            width: 100,
            render: (record) => {
              return (
                  /*eslint-disable*/
                  record.user_createtime ? (
                      <div style="font-size: 12px">
                        <div>{moment(moment.unix(record.user_createtime)).format('YYYY-MM-DD')}</div>
                        <div>{moment(moment.unix(record.user_createtime)).format('HH:mm:ss')}</div>
                      </div>
                  ) : (
                      '_'
                  )
              )
            }
          },
          {
            label: '下单时间',
            width: 100,
            render: (record) => {
              return (
                  /*eslint-disable*/
                  record.order_createtime ? (
                      <div style="font-size: 12px">
                        <div>{moment(moment.unix(record.order_createtime)).format('YYYY-MM-DD')}</div>
                        <div>{moment(moment.unix(record.order_createtime)).format('HH:mm:ss')}</div>
                      </div>
                  ) : (
                      '_'
                  )
              )
            }
          },
          {
            label: '充值金额',
            width: 100,
            // prop: 'money',
            render: (record) => (record.money ? (record.money / 100) : 0)
          },
          {
            label: '订单类型',
            width: 100,
            render: (record) => (record.order_type === 0 ? '未知' : record.order_type === 1 ? '首充' : '复充')
          },
          {
            label: '回传状态',
            width: 100,
            render: function (record) {
              /*eslint-disable*/
              return (
                  <div>
                    {record.status === 1 ? (
                      <el-tooltip placement="top">
                        <div slot="content" style="max-width: 200px">
                          {record.err_msg}
                        </div>
                        <div>
                          <el-tag type="success">推送完成</el-tag>
                        </div>
                      </el-tooltip>
                    ) : record.status === 0 ? (
                        <el-tag type="info">未推送</el-tag>
                    ) : (
                        <el-tooltip placement="top">
                          <div slot="content" style="max-width: 200px">
                            {record.err_msg}
                          </div>
                          <div>{record.status === 2 ? <el-tag type="danger">推送失败</el-tag> : record.status === 3 ?
                              <el-tag type="success">手动推送</el-tag> : <el-tag type="info">需手动推送</el-tag>}</div>
                        </el-tooltip>
                    )}
                  </div>
              )
            }
          },
          {
            label: '回传地址',
            prop: 'logidUrl'
          }
        ], // 表格的列数据
        isIndex: true, // 列表序号
        loading: false, // loading
        isOperation: true,
        pageData: {
          total: 0 // 总条数
        },

        operation: {
          // 表格有操作列时设置
          label: '操作', // 列名
          width: 100, // 根据实际情况给宽度
          fixed: 'right',
          data: [
            {
              label: '回传', // 操作名称
              type: 'text', // 按钮类型
              showLabel: 'status',
              showValue: this.search && this.search.type === 6 && this.search.platform.includes('V') ? [0, 1, 3, 4] : [4],
              handleRow: (index, record) => {
                if (record.type === 2) { //巨量
                    if (this.vnovelPlatform.includes(record.platform)) {
                      this.$refs.vnovelCallbackDialog.showDialog(record)
                      return;
                    }

                    this.$refs.hugeCallbackDialog.showDialog(record)
                    return;
                }
                // 腾讯
                if (record.type === 6 && (record.platform.includes('V') || record.platform.includes('G'))) {
                  this.$refs.tencentVNovelsCallbackDialog.showDialog(record)
                  return
                }

                let params = {
                  recordId: record.id,
                  platform: record.platform,
                  deliveryPlatform: record.type + ''
                }
                handHugeReturn(params).then((res) => {
                  if (res.code === 200) {
                    this.$message.success('回传成功!')
                    this.getPage(this.copyParams)
                  }
                })
              } // 自定义事件
            },
            {
              label: '假回传', // 操作名称
              type: 'text', // 按钮类型
              color: '#ff4040',
              showLabel: 'status',
              showValue: [2],
              handleRow: (index, record) => {
                this.virtualCallbackData = {
                  platform: record.platform,
                  deliveryPlatform: record.type + ''
                }
                this.returnData.platform = record.platform
                this.returnDataVisible = true
                this.recordId = record.id
                console.log('---------', 1)
                this.getPageReturn({platform: record.platform})
              } // 自定义事件
            },
            this.search && this.search.type === 6 && this.search.platform.includes('V') ? {
              label: '日志',
              type: 'text',
              handleRow: (index, record) => {
                if (record.type === 6 && record.platform.includes('V')) {
                  this.callbackLogVisible = true,
                  this.$refs.callBackLogDialog.showDialog(record)
                  return
                }
              }
            } : null,
            this.search && this.search.type === 6 && this.search.platform.includes('V') ? null : {
              label: '回传', // 操作名称
              type: 'text', // 按钮类型
              showLabel: 'status',
              showValue: [0, 1, 3],
              color: '#ccc',
              handleRow: (index, record) => {
              } // 自定义事件
            }
          ]
        }
      }
    }
  },

  mounted() {
    this.getDicts('return_platform_type').then((response) => {
      this.platformList = response.data
      this.search.platform = response.data[0].dictValue

      this.getPage()
    })
    this.getDicts('return_compaign_platform').then((response) => {
      this.platform_4 = response.data
    })
  },

  watch: {},
  methods: {
    setDataSourceOperation() {
      this.dataSource.operation = {
          // 表格有操作列时设置
          label: '操作', // 列名
          width: 100, // 根据实际情况给宽度
          fixed: 'right',
          data: [
            {
              label: '回传', // 操作名称
              type: 'text', // 按钮类型
              showLabel: 'status',
              showValue: this.search && this.search.type === 6 ? [0, 1, 3, 4] : [4],
              handleRow: (index, record) => {
                if (record.type === 2) { //巨量
                    if (this.vnovelPlatform.includes(record.platform)) {
                      this.$refs.vnovelCallbackDialog.showDialog(record)
                      return;
                    }

                    this.$refs.hugeCallbackDialog.showDialog(record)
                    return;
                }
                // 腾讯
                // && (record.platform.includes('V') || record.platform.includes('G'))
                if (record.type === 6) {
                  this.$refs.tencentVNovelsCallbackDialog.showDialog(record)
                  return
                }

                let params = {
                  recordId: record.id,
                  platform: record.platform,
                  deliveryPlatform: record.type + ''
                }
                handHugeReturn(params).then((res) => {
                  if (res.code === 200) {
                    this.$message.success('回传成功!')
                    this.getPage(this.copyParams)
                  }
                })
              } // 自定义事件
            },
            {
              label: '假回传', // 操作名称
              type: 'text', // 按钮类型
              color: '#ff4040',
              showLabel: 'status',
              showValue: [2],
              handleRow: (index, record) => {
                this.virtualCallbackData = {
                  platform: record.platform,
                  deliveryPlatform: record.type + ''
                }
                this.returnData.platform = record.platform
                this.returnDataVisible = true
                this.recordId = record.id
                console.log('---------', 2)
                // this.getPageReturn({platform: record.platform})
              } // 自定义事件
            },
            this.search && this.search.type === 6 && this.search.platform.includes('V') ? {
              label: '日志',
              type: 'text',
              handleRow: (index, record) => {
                if (record.type === 6 && record.platform.includes('V')) {
                  this.callbackLogVisible = true,
                  this.$refs.callBackLogDialog.showDialog(record)
                  return
                }
              }
            } : null,
            this.search && this.search.type === 6 ? null : {
              label: '回传', // 操作名称
              type: 'text', // 按钮类型
              showLabel: 'status',
              showValue: [0, 1, 3],
              color: '#ccc',
              handleRow: (index, record) => {
              } // 自定义事件
            }
          ]
        }
    },
    /**
     * @description: 获取列表
     * @param {*} params
     */
    getPage(params = {pageNum: 1, pageSize: 10}) {
      this.setDataSourceOperation()
      this.dataSource.loading = true
      const [start_finish_time, end_finish_time] = getDateStartEnd(this.search.finish_time)
      const [start_user_createtime, end_user_createtime] = getDateStartEnd(this.search.user_createtime)
      let accounts = this.search.accounts ? this.search.accounts.split(' ').filter((f) => !!f) : []
      let search = {
        ...this.search,
        accounts: accounts.length ? accounts.map((item) => item.replace(/\s+/g, '')).join(' ') : null,
        start_finish_time,
        end_finish_time,
        start_user_createtime,
        end_user_createtime
      }
      delete search.finish_time
      delete search.user_createtime
      this.copyParams = {...search, ...params}
      this.transformPageFun({...search, ...params})
    },

    transformPageFun(params) {
      transformPage(params).then(({code, data}) => {
        if (code === 200) {
          const {records, total, extra_data} = data
          this.extra_data = extra_data
          this.dataSource.data = records
          records.forEach((element) => {
            element.adid = element.adid || '_'
            element.logidUrl = element.logidUrl || '_'
            element.nickname = element.nickname || '_'
          })
          this.dataSource.pageData.total = total
          this.dataSource.pageData.pageNum = params.pageNum
          this.dataSource.loading = false
        }
      })
    },

    /**
     * @description: 获取假回传列表
     * @param {*} params
     */
    getPageReturn(params = {pageNum: 1, pageSize: 10}) {
      console.log('-------', 3)
      this.dataSourceReturn.loading = true
      this.dataSourceReturn.data = []
      const [start_user_createtime, end_user_createtime] = getDateStartEnd(JSON.parse(JSON.stringify(this.returnData.date)))
      let returnData = {
        ...params,
        platform: this.search.platform,
        type: this.search.type,
        start_user_createtime,
        end_user_createtime,
        adid: this.returnData.adid || ''
      }
      delete returnData.date

      virtualCallbackPage(returnData).then(({code, data}) => {
        if (code === 200) {
          const {records, total} = data

          this.dataSourceReturn.data = records
          this.dataSourceReturnData = records.map((item) => item.id)
          this.dataSourceReturn.pageData.pageNum = params.pageNum
          this.dataSourceReturn.pageData.total = total
          this.dataSourceReturn.loading = false
          this.setSelectData()
        }
      })
    },

    setSelectData() {
      this.$refs['TablePane'].getRowData([])
      if (this.returnDataSelectList.length) {
        let rows = []
        this.dataSourceReturn.data.map((item) => {
          if (this.returnDataSelectList.some((s) => s.id === item.id)) {
            rows.push(item)
          }
        })

        this.$refs['TablePane'].getRowData(rows)
      }
    },

    /**
     * @description: 假回传
     */
    submitReturnData() {
      let params = {
        ...this.virtualCallbackData,
        recordId: this.recordId,
        adRecordId: this.returnDataSelectList[0].id
      }
      virtualCallback(params).then(({code}) => {
        if (code === 200) {
          this.$message.success('回传成功!')
          this.resetForm('returnData')
          this.getPage()
        }
      })
    },

    /**
     * @description: 重置
     */
    resetForm(ref) {
      this.$refs[ref].resetFields()
      if (ref === 'returnData') {
        this.returnDataVisible = false
        this.returnDataSearching = false
        this.returnDataSelectList = []
        this.dataSourceReturnData = []
        this.returnData = {
          adid: null,
          date: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
          platform: '',
          type: 2
        }
      } else {
        this.$nextTick(() => {
          this.getPage()
        })
      }
    },

    /**
     * @description: 查询
     */
    onSubmit() {
      this.getPage()
    },
    handleOk() {
      this.getPage()
    }
  }
}
</script>

<style lang="scss">
 .el-empty.return-empty-text p{
  color: #F56C6C;
 }
</style>