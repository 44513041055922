<!--
 * @Description: Do not edit
 * @Date: 2021-10-05 13:41:50
 * @LastEditTime: 2022-02-17 11:25:29
-->
<template>
  <el-form ref="searchForm" class="search-form search-form-4" :model="searchForm">
    <el-row :gutter="20">
      <el-col :span="6">
        <el-form-item prop="housekeeperId" label="账户管家">
          <el-select v-model="searchForm.housekeeperId" filterable placeholder="请输入账户管家" @change="selectChange">
            <el-option v-for="item in houseKeeper()" :key="item.housekeeperId" :label="item.launchAccountName" :value="item.housekeeperId" />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-form-item prop="baiduId" label="投放账户">
          <el-select v-model="searchForm.baiduId" :disabled="!searchForm.housekeeperId" filterable placeholder="请输入投放账户">
            <el-option v-for="item in baiduAdv" :key="item.baiduId" :label="item.accountName" :value="item.baiduId" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="transName" label="转化名称">
          <el-input v-model="searchForm.transName" placeholder="请输入转化名称" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="transStatus" label="转化状态">
          <el-select v-model="searchForm.transStatus" filterable placeholder="请选择转化状态">
            <el-option v-for="item in transStatusList()" :key="item.dictValue" trans-from-list :label="item.dictLabel" :value="item.dictValue" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="transFrom" label="接入方式">
          <el-select v-model="searchForm.transFrom" filterable placeholder="请选择接入方式">
            <el-option v-for="item in transFromList()" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue" />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-button class="btn" type="primary" @click="search">
          查询
        </el-button>
        <el-button class="btn" @click="resetSearch">
          重置
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { searchForm } from './object'
import { getAccountByHouseId } from '@/network/api/account/accountBaiduAdv'
export default {
  inject: ['loadData', 'houseKeeper', 'transStatusList', 'transFromList'],
  data() {
    return {
      searchForm: { ...searchForm },
      baiduAdv: []
    }
  },
  methods: {
    selectChange(val) {
      this.baiduAdv = []
      this.searchForm.baiduId = ''

      getAccountByHouseId(val).then((res) => {
        if (res.code === 200) {
          this.baiduAdv = res.data
        }
      })
    },
    search() {
      this.loadData(true)
    },
    // 折中方案
    getSearchForm() {
      return { ...this.searchForm }
    },
    resetSearch() {
      this.searchForm.housekeeperId = undefined
      this.searchForm.baiduId = undefined
      this.searchForm.transName = undefined
      this.searchForm.transStatus = undefined
      this.searchForm.transFrom = undefined
      this.baiduAdv = []
      this.$refs.searchForm.resetFields()
    }
  }
}
</script>
