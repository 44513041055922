/*
 * @Description: Do not edit
 * @Date: 2021-10-05 13:41:50
 * @LastEditTime: 2021-12-22 09:18:07
 */
import service from '@/network/service/service'

//  服务商列表 post
export function adMpAmsPage(data) {
  return service.post('/api/tencent/adMpAms/page', data)
}

// 服务商下拉 get
export function getAdMpAm() {
  return service.get('/api/tencent/adMpAms/select')
}

//  账号列表 post
export function adMpAmsAccountsPage(data) {
  return service.post('/api/tencent/adMpAmsAccounts/page', data)
}

//  批量操作
export function batch(data) {
  return service.post('/api/tencent/adMpAmsAccounts/batch/update', data)
}

// 同步操作
export function adMpAmsAccountsAmsAsync(data) {
  return service.post(`/api/tencent/adMpAmsAccounts/async/account`, data)
}
