var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "企微客服组",
            visible: _vm.wecomModalVisible,
            width: "900px",
            "modal-append-to-body": false,
            "destroy-on-close": "",
          },
          on: {
            close: function ($event) {
              return _vm.resetForm()
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.dataFrom } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 10 } },
                [
                  _c("el-col", { attrs: { span: 8 } }, [_vm._v("账户ID")]),
                  _c("el-col", { attrs: { span: 8 } }, [_vm._v("绑定企微ID")]),
                  _c("el-col", { attrs: { span: 8 } }, [_vm._v("客服组ID")]),
                ],
                1
              ),
              _vm._l(_vm.dataFrom.wecomData, function (item) {
                return _c(
                  "el-row",
                  {
                    key: item.localAccountId,
                    staticStyle: { "margin-top": "10px" },
                    attrs: { gutter: 10 },
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c("el-form-item", [
                          _vm._v(" " + _vm._s(item.name) + " "),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              rules: {
                                required: true,
                                message: "请选择企微ID",
                                trigger: "change",
                              },
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  clearable: "",
                                  filterable: "",
                                  remote: "",
                                  "remote-method": (query) =>
                                    _vm.remoteMethod(
                                      query,
                                      item.localAccountId
                                    ),
                                  loading: _vm.loading,
                                },
                                model: {
                                  value: item.tACorpId,
                                  callback: function ($$v) {
                                    _vm.$set(item, "tACorpId", $$v)
                                  },
                                  expression: "item.tACorpId",
                                },
                              },
                              _vm._l(_vm.corpIdOption, function (it) {
                                return _c("el-option", {
                                  key: it.wxCorpid,
                                  attrs: {
                                    label: it.name + "(" + it.wxCorpid + ")",
                                    value: it.wxCorpid + "," + it.name,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              rules: {
                                required: true,
                                message: "请选择客服组ID",
                                trigger: "change",
                              },
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { clearable: "" },
                                model: {
                                  value: item.tAGroupId,
                                  callback: function ($$v) {
                                    _vm.$set(item, "tAGroupId", $$v)
                                  },
                                  expression: "item.tAGroupId",
                                },
                              },
                              _vm._l(
                                _vm.wxGroupIOptionMap[item.localAccountId],
                                function (it) {
                                  return _c("el-option", {
                                    key: it.id,
                                    attrs: {
                                      value: it.groupId,
                                      label:
                                        it.groupName + "(" + it.groupId + ")",
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveWecomData },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }