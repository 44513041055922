/*
 * @Description: Do not edit
 * @Date: 2021-09-15 09:48:06
 * @LastEditTime: 2024-05-31 14:32:46
 */
import service from '@/network/service/service'

export function Page(data) {
  return service.post('/api/system/baidu/account/oauthPage', data)
}

export function Add(data) {
  return service.get('/api/system/baidu/account/getOauthUrl')
}

export function Delete(id) {
  return service.delete('/api/system/baidu/account/deleteOauth/' + id)
}

export function getAccountCache(params) {
  return service.post('/api/system/baidu/account/getCacheAccount', params)
}

export function Sync(data) {
  return service.post('/api/system/baidu/account/synData', data)
}

// export function Add(data) {
//   return service.post('/api/system/accountBaiduHousekeeper/save', data)
// }

export function Update(data) {
  return service.post('/api/system/accountBaiduHousekeeper/update', data)
}

export function accountUnique(data) {
  return service.get('/api/system/accountBaiduHousekeeper/accountUnique/' + data)
}

export function synchAccount(data) {
  return service.get('/api/system/accountBaiduHousekeeper/synchAccount/' + data)
}
export function getHouseKeeper() {
  return service.get('/api/system/accountBaiduHousekeeper/getHouseKeeper')
}
