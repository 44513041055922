var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-20" },
    [
      _c(
        "el-form",
        {
          ref: "search",
          staticClass: "search-form search-form-4",
          attrs: { inline: true, model: _vm.search },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "投放平台", prop: "type" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "300px" },
                          attrs: { placeholder: "请选择投放平台" },
                          model: {
                            value: _vm.search.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "type", $$v)
                            },
                            expression: "search.type",
                          },
                        },
                        _vm._l(_vm.platform_4, function (item) {
                          return _c("el-option", {
                            key: item.dictValue,
                            attrs: {
                              label: item.dictLabel,
                              value: +item.dictValue,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "来源", prop: "platform" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择来源" },
                          model: {
                            value: _vm.search.platform,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "platform", $$v)
                            },
                            expression: "search.platform",
                          },
                        },
                        _vm._l(_vm.platformList, function (item) {
                          return _c("el-option", {
                            key: item.dictValue,
                            attrs: {
                              label: item.dictLabel,
                              value: item.dictValue,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称/ID", prop: "accounts" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入名称/ID,多个以空格区隔" },
                        model: {
                          value: _vm.search.accounts,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "accounts", $$v)
                          },
                          expression: "search.accounts",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "回传状态", prop: "status" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.search.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "status", $$v)
                            },
                            expression: "search.status",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: null },
                          }),
                          _c("el-option", {
                            attrs: { label: "未推送", value: "0" },
                          }),
                          _c("el-option", {
                            attrs: { label: "推送完成", value: "1" },
                          }),
                          _c("el-option", {
                            attrs: { label: "推送失败", value: "2" },
                          }),
                          _c("el-option", {
                            attrs: { label: "手动推送", value: "3" },
                          }),
                          _c("el-option", {
                            attrs: { label: "需要手动推送", value: "4" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "广告 I D ", prop: "adid" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入广告ID" },
                        model: {
                          value: _vm.search.adid,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "adid", $$v)
                          },
                          expression: "search.adid",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单类型", prop: "order_type" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.search.order_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "order_type", $$v)
                            },
                            expression: "search.order_type",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: null },
                          }),
                          _c("el-option", {
                            attrs: { label: "未知", value: "0" },
                          }),
                          _c("el-option", {
                            attrs: { label: "首充", value: "1" },
                          }),
                          _c("el-option", {
                            attrs: { label: "复充", value: "2" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "起始充值金额",
                        prop: "start_true_money",
                      },
                    },
                    [
                      _c("el-input-number", {
                        attrs: {
                          "controls-position": "right",
                          min: 0,
                          max: _vm.search.end_true_money,
                        },
                        model: {
                          value: _vm.search.start_true_money,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "start_true_money", $$v)
                          },
                          expression: "search.start_true_money",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "结束充值金额", prop: "end_true_money" },
                    },
                    [
                      _c("el-input-number", {
                        attrs: {
                          "controls-position": "right",
                          max: 1000000,
                          min: _vm.search.start_true_money,
                        },
                        model: {
                          value: _vm.search.end_true_money,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "end_true_money", $$v)
                          },
                          expression: "search.end_true_money",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "推广链接ID", prop: "promotion_id" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入推广链接ID" },
                        model: {
                          value: _vm.search.promotion_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "promotion_id", $$v)
                          },
                          expression: "search.promotion_id",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "注册时间", prop: "user_createtime" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "value-format": "yyyy-MM-dd ",
                          "range-separator": "-",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        model: {
                          value: _vm.search.user_createtime,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "user_createtime", $$v)
                          },
                          expression: "search.user_createtime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "下单时间", prop: "finish_time" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "value-format": "yyyy-MM-dd ",
                          "range-separator": "-",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        model: {
                          value: _vm.search.finish_time,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "finish_time", $$v)
                          },
                          expression: "search.finish_time",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", round: "" },
                          on: { click: _vm.onSubmit },
                        },
                        [_vm._v("查询")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { round: "" },
                          on: {
                            click: function ($event) {
                              return _vm.resetForm("search")
                            },
                          },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticStyle: { "margin-top": "10px", "margin-bottom": "10px" },
          attrs: { gutter: 20 },
        },
        [
          _c("el-col", { attrs: { span: 6, offset: 18 } }, [
            _c("div", [
              _c("span", { staticStyle: { color: "#409EFF" } }, [
                _vm._v(
                  _vm._s(_vm.extra_data.total_user) +
                    "/" +
                    _vm._s(_vm.extra_data.true_user || 0)
                ),
              ]),
              _c("span", [_vm._v("个付费用户，")]),
              _c("span", { staticStyle: { color: "#409EFF" } }, [
                _vm._v(_vm._s(_vm.extra_data.total)),
              ]),
              _vm._v("笔订单，总金额 "),
              _c("span", { staticStyle: { color: "#409EFF" } }, [
                _vm._v(_vm._s(_vm.extra_data.total_money / 100)),
              ]),
              _vm._v("元 "),
            ]),
          ]),
        ],
        1
      ),
      _c("TablePane", {
        attrs: { "data-source": _vm.dataSource },
        on: { getPage: _vm.getPage },
      }),
      _c(
        "el-dialog",
        {
          staticClass: "table-template",
          attrs: {
            title: "推送失败订单回传",
            visible: _vm.returnDataVisible,
            "append-to-body": true,
            width: "900px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.returnDataVisible = $event
            },
            close: function ($event) {
              return _vm.resetForm("returnData")
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-form",
                {
                  ref: "returnData",
                  staticStyle: { width: "100%" },
                  attrs: { inline: true, model: _vm.returnData },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "广告ID: ", prop: "adid" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "260px", "margin-right": "30px" },
                        attrs: { placeholder: "请输入广告ID查询" },
                        model: {
                          value: _vm.returnData.adid,
                          callback: function ($$v) {
                            _vm.$set(_vm.returnData, "adid", $$v)
                          },
                          expression: "returnData.adid",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "数据时间: ", prop: "date" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "300px" },
                        attrs: {
                          "value-format": "yyyy-MM-dd",
                          type: "daterange",
                          "range-separator": "~",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        model: {
                          value: _vm.returnData.date,
                          callback: function ($$v) {
                            _vm.$set(_vm.returnData, "date", $$v)
                          },
                          expression: "returnData.date",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: () => {
                              _vm.returnDataSearching = true
                              _vm.getPageReturn()
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.returnDataSearching
            ? _c("el-empty", {
                staticClass: "return-empty-text",
                attrs: { description: "请通过广告ID查询回传数据" },
              })
            : _c("TablePane", {
                ref: "TablePane",
                attrs: { "data-source": _vm.dataSourceReturn },
                on: { getPage: _vm.getPageReturn },
              }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "btn",
                  on: {
                    click: function ($event) {
                      return _vm.resetForm("returnData")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "btn",
                  attrs: { type: "primary" },
                  on: { click: _vm.submitReturnData },
                },
                [_vm._v("回 传")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("HugeCallbackDialog", {
        ref: "hugeCallbackDialog",
        on: { submit: _vm.handleOk },
      }),
      _c("VnovelCallbackDialog", {
        ref: "vnovelCallbackDialog",
        on: { submit: _vm.handleOk },
      }),
      _c("TencentVNovelsCallbackDialog", {
        ref: "tencentVNovelsCallbackDialog",
        on: { submit: _vm.handleOk },
      }),
      _c("CallBackLogDialogVue", { ref: "callBackLogDialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }