/*
 * @Author: Zeng
 * @Date: 2021-10-27 10:04:31
 * @LastEditTime: 2024-03-21 14:02:43
 */
import router from './index'
import store from '../store'

const whiteList = ['/']

router.beforeEach(async (to, from, next) => {
  if (whiteList.indexOf(to.path) !== -1) {
    next()
  } else {
    if (Object.keys(store.getters.userInfo).length > 0) {
      next()
    } else {
      try {
        await store.dispatch('user/GetUserInfo')
        await store.dispatch('user/GetUserMenu')
        next()
      } catch (error) {
        next()
      }
    }
  }
})
