<template>
  <!--发起产品筛选弹框-->
  <el-dialog
    class="product-screen-vote-dialog"
    title="发起产品筛选"
    :visible.sync="show"
    :append-to-body="true"
    width="620px"
    top="20vh"
    @close="onCloseDialog"
  >
    <div class="page-container">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        :inline="true"
      >
        <el-form-item
          label="身份"
          prop="place"
        >
          <el-select
            v-model="form.place"
            style="width: 180px"
            placeholder="请选择身份"
          >
            <el-option
              label="投手"
              value="投手"
            > </el-option>
            <el-option
              label="素材"
              value="素材"
            > </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="付费卡点"
          prop="typeName"
        >
          <el-select
            v-model="form.payCardPoint"
            filterable
            style="width: 180px"
            placeholder="请选择付费卡点"
          >
            <el-option
              v-for="item in options"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <el-input
        v-model="form.content"
        style="margin-top: 20px"
        type="textarea"
        placeholder="请输入评论"
        maxlength="100"
        show-word-limit
        :rows="4"
      >
      </el-input>
    </div>
    <!--底部按钮-->
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        class="btn"
        @click="closeModal"
      >取 消</el-button>
      <el-button
        class="btn"
        type="primary"
        :disabled="loading"
        @click="handleConfirm"
      >发起筛选</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  initiateScreening
} from '@/network/api/toolManagement/api-product-test'

export default {
  name: 'ProductScreenVoteDialog',
  props: {},
  data() {
    return {
      show: false,
      loading: false,

      form: {
        toolProductId: '',
        place: '投手',
        payCardPoint: 1,
        content: ''
      },

      rules: {
        place: [
          { required: true, message: '请选择身份', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    options() {
      return Array.from({ length: 30 }, (_, i) => 1 + i)
    }
  },
  methods: {
    /* --------------------------- Actions --------------------------- */
    showModal(id) {
      this.show = true
      this.form.toolProductId = id
    },

    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      // 重置表单
      this.tableData = []
      this.form = {
        toolProductId: '',
        place: '投手',
        payCardPoint: 1,
        content: ''
      }
      this.$refs['form'].clearValidate()
    },

    // 关闭弹框
    closeModal() {
      this.show = false
    },

    // 发起筛选
    handleConfirm () {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return
        }
        let params = JSON.parse(JSON.stringify(this.form))
        this.loading = true
        initiateScreening(params).then(({ code }) => {
          if (code === 200) {
            this.$message.success('发起筛选成功')
            this.$emit('confirm')
            this.closeModal()
          }
        }).finally(() => {
          this.loading = false
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
