var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.isEdit ? "编 辑" : "新 增",
        width: "60%",
        visible: _vm.visible,
        "before-close": _vm.close,
        "label-position": "right",
        "modal-append-to-body": false,
        "close-on-press-escape": false,
        "close-on-click-modal": false,
        "destroy-on-close": true,
        top: "100px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-row",
        { staticClass: "bg-purple-dark" },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c("div", { staticClass: "grid-content" }, [
              _c(
                "span",
                [
                  _c("font", { staticClass: "meH1" }, [_vm._v("营销目标")]),
                  _c(
                    "el-tooltip",
                    { attrs: { effect: "dark", placement: "top-start" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: { width: "400px" },
                          attrs: { slot: "content" },
                          slot: "content",
                        },
                        [
                          _vm._v(
                            " 网站链接：适用于网站推广的广告主，提升页面的到访，进而在页面产生咨询、留下联系方式等销售线索应用推广：适用于应用推广诉求的广告主，提升应用的下载、安装、激活、付费等商品目录：适用于拥有众多商品且已对接百度商品中心的广告主，如零售平台、汽车平台、旅游平台、房产平台等电商店铺：适用于拥有成熟电商店铺的广告主，希望直达店铺、提升销量，目前已支持淘宝、天猫、京东、拼多多、1688等平台的店铺门店推广：适用于拥有线下门店的广告主，希望提升门店卡券分发、线索收集、线下到访等营销效果 "
                          ),
                        ]
                      ),
                      _c("i", { staticClass: "el-icon-question ml-5 mr-5" }),
                    ]
                  ),
                  _vm._v(
                    " 为您的推广计划设置营销目标，系统帮您找到达成该目标最佳的产品功能，帮您节省广告制作时间并制作更出色的广告。 "
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("el-col", { attrs: { span: 24 } }, [
            _c("div", { staticClass: "subject-type-block" }, [
              _c(
                "div",
                {
                  staticClass: "subject-type-item-wrapper subject-type-checked",
                },
                [
                  _c("i", { staticClass: "bg-icon1" }),
                  _c("div", { staticClass: "subject-title" }, [
                    _vm._v("网站链接"),
                  ]),
                ]
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "bg-purple-dark mt-20 medisplay" },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c("span", { staticClass: "meH1" }, [_vm._v("计划设置")]),
              _c(
                "el-tooltip",
                { attrs: { effect: "dark", placement: "top-start" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { width: "400px" },
                      attrs: { slot: "content" },
                      slot: "content",
                    },
                    [
                      _vm._v(
                        "导入账户已有计划设置内容，如预算、时段、APP包信息等，保存后会新建一个全新的计划"
                      ),
                    ]
                  ),
                  _c("i", { staticClass: "el-icon-question ml-5 mr-5" }),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "bg-purple-dark mt-20 medisplay" },
        [
          _c("el-col", { attrs: { span: 4 } }, [
            _c(
              "div",
              { staticClass: "meContent" },
              [
                _c("font", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                _vm._v("关联项目"),
              ],
              1
            ),
          ]),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "w-300",
                  attrs: {
                    filterable: "",
                    "allow-create": "",
                    "default-first-option": "",
                    placeholder: "请选择",
                  },
                  on: { change: _vm.bsProjectChange },
                  model: {
                    value: _vm.ent.bsProjectId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ent, "bsProjectId", $$v)
                    },
                    expression: "ent.bsProjectId",
                  },
                },
                _vm._l(_vm.projectList, function (item) {
                  return _c("el-option", {
                    key: item.bsProjectId,
                    attrs: { label: item.projectName, value: item.bsProjectId },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c("el-col", { attrs: { span: 4 } }, [
            _c("div", { staticClass: "meContent" }, [_vm._v("关联产品")]),
          ]),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-select",
                {
                  staticClass: "w-300",
                  attrs: {
                    filterable: "",
                    "allow-create": "",
                    "default-first-option": "",
                    placeholder: "请选择",
                  },
                  model: {
                    value: _vm.ent.bsProductId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ent, "bsProductId", $$v)
                    },
                    expression: "ent.bsProductId",
                  },
                },
                _vm._l(_vm.productList, function (item) {
                  return _c("el-option", {
                    key: item.bsProductId,
                    attrs: { label: item.productName, value: item.bsProductId },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "bg-purple-dark mt-10 medisplay" },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-row",
                { staticClass: "bg-purple-darks medisplay" },
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c(
                      "div",
                      { staticClass: "meContent" },
                      [
                        _c("font", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v("预算消耗方式 "),
                        _c(
                          "el-tooltip",
                          { attrs: { effect: "dark", placement: "top-start" } },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: { width: "400px" },
                                attrs: { slot: "content" },
                                slot: "content",
                              },
                              [
                                _vm._v(
                                  " 标准：尽快将广告投放出去，预算可能会在短时间内消耗完;"
                                ),
                                _c("br"),
                                _vm._v(
                                  " 平衡投放：根据流量波动，让预算在整个投放日程中较为平稳的消耗;"
                                ),
                                _c("br"),
                                _vm._v(
                                  " 展现优先：尽可能获得更多展现，对比标准投放预算消耗更快;"
                                ),
                                _c("br"),
                              ]
                            ),
                            _c("i", {
                              staticClass: "el-icon-question ml-5 mr-5",
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 20 } }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.ent.bgtctltype,
                              callback: function ($$v) {
                                _vm.$set(_vm.ent, "bgtctltype", $$v)
                              },
                              expression: "ent.bgtctltype",
                            },
                          },
                          [
                            _c("el-radio-button", { attrs: { label: "1" } }, [
                              _vm._v("标准投放"),
                            ]),
                            _c("el-radio-button", { attrs: { label: "0" } }, [
                              _vm._v("均衡投放"),
                            ]),
                            _c("el-radio-button", { attrs: { label: "2" } }, [
                              _vm._v("展现优先"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "bg-purple-darks medisplay" },
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c(
                      "div",
                      { staticClass: "meContent" },
                      [
                        _c("font", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v("预算"),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 20 } }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.budgetType,
                              callback: function ($$v) {
                                _vm.budgetType = $$v
                              },
                              expression: "budgetType",
                            },
                          },
                          [
                            _c("el-radio-button", { attrs: { label: "0" } }, [
                              _vm._v("不限"),
                            ]),
                            _c("el-radio-button", { attrs: { label: "-1" } }, [
                              _vm._v("自定义"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm.budgetType === "-1"
                ? _c(
                    "el-row",
                    { staticClass: "bg-purple-darks medisplay" },
                    [
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("div", { staticClass: "meContent" }),
                      ]),
                      _c("el-col", { attrs: { span: 20 } }, [
                        _c(
                          "div",
                          [
                            _c("el-input-number", {
                              attrs: {
                                precision: 2,
                                "controls-position": "right",
                                min: 50,
                                placeholder: "保留两位 最低50元",
                              },
                              model: {
                                value: _vm.ent.budget,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ent, "budget", $$v)
                                },
                                expression: "ent.budget",
                              },
                            }),
                            _vm._v("元/天"),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-row",
                { staticClass: "bg-purple-darks medisplay" },
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c(
                      "div",
                      { staticClass: "meContent" },
                      [
                        _c("font", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v("推广日期"),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 20 } }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.startAndEnd,
                              callback: function ($$v) {
                                _vm.startAndEnd = $$v
                              },
                              expression: "startAndEnd",
                            },
                          },
                          [
                            _c("el-radio-button", { attrs: { label: "0" } }, [
                              _vm._v("不限"),
                            ]),
                            _c("el-radio-button", { attrs: { label: "-1" } }, [
                              _vm._v("自定义"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm.startAndEnd === "-1"
                ? _c(
                    "el-row",
                    { staticClass: "bg-purple-darks medisplay" },
                    [
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("div", { staticClass: "meContent" }),
                      ]),
                      _c("el-col", { attrs: { span: 20 } }, [
                        _c(
                          "div",
                          [
                            _c("el-date-picker", {
                              attrs: {
                                "value-format": "yyyy-MM-dd",
                                type: "daterange",
                                "range-separator": "至",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                "picker-options": _vm.pickerOptions,
                              },
                              model: {
                                value: _vm.startAndEndTime,
                                callback: function ($$v) {
                                  _vm.startAndEndTime = $$v
                                },
                                expression: "startAndEndTime",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-row",
                { staticClass: "bg-purple-darks medisplay" },
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c(
                      "div",
                      { staticClass: "meContent" },
                      [
                        _c("font", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v("推广时段"),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 20 } }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-radio-group",
                          {
                            on: { change: _vm.scheduleChange },
                            model: {
                              value: _vm.scheduleType,
                              callback: function ($$v) {
                                _vm.scheduleType = $$v
                              },
                              expression: "scheduleType",
                            },
                          },
                          [
                            _c("el-radio-button", { attrs: { label: "0" } }, [
                              _vm._v("不限"),
                            ]),
                            _c("el-radio-button", { attrs: { label: "-1" } }, [
                              _vm._v("自定义"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm.scheduleType === "-1"
                ? _c(
                    "el-row",
                    { staticClass: "bg-purple-darks medisplay" },
                    [
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("div", { staticClass: "meContent" }),
                      ]),
                      _c("el-col", { attrs: { span: 20 } }, [
                        _c(
                          "div",
                          { attrs: { "v-if": false } },
                          [
                            _c("m-schedule", {
                              ref: "schedule",
                              attrs: {
                                value: _vm.scheduleData,
                                shortcuts: _vm.shortcuts,
                                "days-title": "时段/日期",
                                "shortcuts-type": "dropdown",
                              },
                              on: {
                                clear: _vm.handleClear,
                                change: _vm.handleChange,
                                click: _vm.handleClick,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "hourTip",
                                    fn: function ({ day, rowIndex, colIndex }) {
                                      return [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(day.name) +
                                              "-" +
                                              _vm._s(rowIndex) +
                                              "-" +
                                              _vm._s(colIndex)
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                  {
                                    key: "confirm",
                                    fn: function () {
                                      return [
                                        _c("div", { class: _vm.commonCls }, [
                                          _c(
                                            "div",
                                            { class: `${_vm.commonCls}-title` },
                                            [_c("h3", [_vm._v("选中时间")])]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              class: `${_vm.commonCls}-set-bid`,
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  attrs: {
                                                    type: "text-strong",
                                                  },
                                                  on: {
                                                    click: _vm.handleConfirm,
                                                  },
                                                },
                                                [_vm._v("确定")]
                                              ),
                                              _c(
                                                "button",
                                                {
                                                  attrs: {
                                                    type: "text-strong",
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.$refs.schedule
                                                        .handleClose,
                                                  },
                                                },
                                                [_vm._v("取消")]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                234743152
                              ),
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-row",
                { staticClass: "bg-purple-dark medisplay" },
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c(
                      "div",
                      { staticClass: "meContent" },
                      [
                        _c("font", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ]),
                        _vm._v("计划名称"),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 20 } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "text",
                          placeholder: "请输入内容",
                          maxlength: "100",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.ent.campaignFeedName,
                          callback: function ($$v) {
                            _vm.$set(_vm.ent, "campaignFeedName", $$v)
                          },
                          expression: "ent.campaignFeedName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-popconfirm",
            { attrs: { title: "确定提交？" }, on: { confirm: _vm.submit } },
            [
              _c(
                "el-button",
                {
                  attrs: { slot: "reference", type: "primary", round: "" },
                  slot: "reference",
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "ml-10",
              attrs: { round: "" },
              on: { click: _vm.close },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
      _c("InportBaiduPlan", { ref: "inportPlan" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }