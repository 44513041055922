var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20, type: "flex", justify: "space-between" } },
        [
          _c(
            "el-col",
            [
              _c(
                "el-form",
                {
                  ref: "formInline",
                  attrs: {
                    inline: true,
                    model: _vm.formInline,
                    "label-width": "130px",
                    rules: _vm.rules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "落地页名称:", prop: "name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        model: {
                          value: _vm.formInline.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "name", $$v)
                          },
                          expression: "formInline.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "落地页顶部名称:", prop: "topName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        model: {
                          value: _vm.formInline.topName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formInline, "topName", $$v)
                          },
                          expression: "formInline.topName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "落地页背景:" } },
                    [
                      _c(
                        "el-dropdown",
                        { staticStyle: { width: "28px" } },
                        [
                          _c("div", {
                            staticClass: "ldy-bg-color",
                            style: `background:${_vm.formInline.colors}`,
                          }),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              _c("twitter-color", {
                                staticStyle: { "margin-top": "10px" },
                                attrs: { colors: _vm.formInline.colors },
                                on: {
                                  change: (value) =>
                                    (_vm.formInline.colors = value),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.id
                    ? _c(
                        "el-form-item",
                        [
                          _c(
                            "el-checkbox",
                            {
                              staticStyle: { "margin-left": "30px" },
                              attrs: { "true-label": 1, "false-label": 0 },
                              model: {
                                value: _vm.isCoverPage,
                                callback: function ($$v) {
                                  _vm.isCoverPage = $$v
                                },
                                expression: "isCoverPage",
                              },
                            },
                            [_vm._v(" 同步更新关联链接")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.type !== "tamper"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "是否开启篡改:" } },
                        [
                          _c("el-switch", {
                            attrs: { "active-value": 1, "inactive-value": 0 },
                            on: { change: _vm.switchChange },
                            model: {
                              value: _vm.isRedirect,
                              callback: function ($$v) {
                                _vm.isRedirect = $$v
                              },
                              expression: "isRedirect",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.type !== "tamper" && _vm.isRedirect
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "屏蔽地区:" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "340px" },
                              attrs: {
                                multiple: "",
                                filterable: "",
                                clearable: "",
                                placeholder: "请选择",
                              },
                              model: {
                                value: _vm.forbiddenArea,
                                callback: function ($$v) {
                                  _vm.forbiddenArea = $$v
                                },
                                expression: "forbiddenArea",
                              },
                            },
                            _vm._l(_vm.provinceList, function (item) {
                              return _c("el-option", {
                                key: item.dictValue,
                                attrs: {
                                  label: item.dictLabel,
                                  value: item.dictValue,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 2 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.setPublishVisible },
                },
                [_vm._v("提交并保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticStyle: { display: "flex", "flex-wrap": "nowrap" },
          attrs: { gutter: 60 },
        },
        [
          _c(
            "el-col",
            { staticStyle: { "min-width": "450px" }, attrs: { span: 7 } },
            [
              _c(
                "div",
                { staticClass: "grid-content bg-purple" },
                [
                  _c("WidgetGallery", {
                    attrs: { "seleted-widgets-index": _vm.seletedWidgetsIndex },
                    on: { setFinalWidgets: _vm.setFinalWidgets },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-col",
            {
              staticStyle: {
                width: "415px",
                "margin-right": "60px",
                height: "720px",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "grid-content bg-purple workbench",
                  style: `background:${_vm.formInline.colors}`,
                },
                [
                  _c("Workbench", {
                    attrs: {
                      "final-widgets": _vm.finalWidgets,
                      "seleted-widgets-index": _vm.seletedWidgetsIndex,
                    },
                    on: {
                      updateFinalWidgets: _vm.updateFinalWidgets,
                      setFinalWidgetsCount: _vm.setFinalWidgetsCount,
                      setSelectedIndex: _vm.setSelectedIndex,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-col",
            { staticStyle: { "min-width": "450px" }, attrs: { xl: 10, lg: 8 } },
            [
              _c(
                "div",
                { staticClass: "grid-content bg-purple" },
                [
                  _c("AdEditors", {
                    attrs: {
                      "final-widgets": _vm.finalWidgets,
                      "seleted-widgets-index": _vm.seletedWidgetsIndex,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "落地页发布",
                visible: _vm.publishVisible,
                width: "600px",
                "modal-append-to-body": false,
                "before-close": _vm.close,
                "show-close": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.publishVisible = $event
                },
              },
            },
            [
              _c(
                "span",
                [
                  _c(
                    "el-form",
                    {
                      ref: "submitParams",
                      attrs: {
                        model: _vm.submitParams,
                        rules: _vm.rules,
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "公 众 号", prop: "name" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "400px" },
                              attrs: {
                                filterable: "",
                                remote: "",
                                "reserve-keyword": "",
                                placeholder: "请输入公众号全称",
                                "remote-method": _vm.remoteMethod,
                                loading: _vm.publicListLoading,
                              },
                              on: { change: _vm.getGhid },
                              model: {
                                value: _vm.submitParams.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.submitParams, "name", $$v)
                                },
                                expression: "submitParams.name",
                              },
                            },
                            _vm._l(_vm.publicList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.name,
                                  value: `${item.ghid}+${item.name}+${item.wechat}+${item.headImg}`,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "主体域名", prop: "domain" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "400px" },
                              attrs: {
                                filterable: "",
                                remote: "",
                                "reserve-keyword": "",
                                placeholder: "请选择域名",
                              },
                              model: {
                                value: _vm.submitParams.domain,
                                callback: function ($$v) {
                                  _vm.$set(_vm.submitParams, "domain", $$v)
                                },
                                expression: "submitParams.domain",
                              },
                            },
                            _vm._l(_vm.domainPageList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.subject,
                                  value: `${item.scheme}://${item.domain}`,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-button", { on: { click: _vm.close } }, [
                    _vm._v("取 消"),
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: _vm.submitLoading },
                      on: { click: _vm.submitLoadingFun },
                    },
                    [_vm._v("确 认")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }