<!--
 * @Description: 创意组件列表
 * @Date: 2021-11-01 09:46:13
 * @LastEditTime: 2022-02-18 15:31:17
-->
<template>
  <section>
    <div>
      <el-form ref="ruleForm" :inline="true" class="search-form search-form-4" :model="ruleData">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item prop="housekeeperId" label="管家账号">
              <el-select v-model="ruleData.housekeeperId" placeholder="请输入管家账号" @change="handleSelectHousekeeper">
                <el-option v-for="(item, index) in accountHugeHousekeeperENTs" :key="index" :label="item.accountName" :value="item.accountId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item prop="advId" label="投放账户">
              <el-select v-model="ruleData.advId" :disabled="!ruleData.housekeeperId" placeholder="请选择投放账户" filterable multiple>
                <el-option v-for="(item, index) in accountHugeAdvENTLists[accountHugeHousekeeperENTsId]" :key="index" :label="item.accountName" :value="item.accountId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item prop="componentType" label="组件状态">
              <el-select v-model="ruleData.componentType" placeholder="请选择组件状态">
                <el-option v-for="(item, index) in component_types" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item prop="status" label="组件类型">
              <el-select v-model="ruleData.status" placeholder="请选择组件类型">
                <el-option v-for="(item, index) in componentStatus" :key="index" :label="item.dictLabel" :value="item.dictValue"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item prop="componentName" label="组件名称">
              <el-input v-model="ruleData.componentName" placeholder="请输入组件名称" />
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item>
              <el-button class="btn" type="primary" @click="getPage"> 查询 </el-button>
              <el-button class="btn" @click="resetForm"> 重置 </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div>
      <section class="creativity-detail">
        <div class="creativity-detail-btn">
          <el-button type="primary" style="margin-right: 10px" round @click="isSync = true">同步创意组件</el-button>
          <el-button type="primary" round><router-link to="/createCreativity" tag="span">新建创意组件</router-link></el-button>
        </div>
        <TablePane :data-source="dataSource" @getPage="getPage"> </TablePane>
      </section>
    </div>

    <!-- 同步事件管理 -->
    <el-dialog title="同步事件管理" :visible.sync="isSync" width="550px" :modal-append-to-body="false" @close="resetSyncForm">
      <span>
        <el-form ref="syncFormRef" :model="account" label-width="100px" :rules="rules">
          <el-form-item label="管家账号" prop="accountId">
            <el-select v-model="account.accountId" style="width:300px" @change="handleSelectHousekeeper1">
              <el-option v-for="(item, index) in accountHugeHousekeeperENTs" :key="index" :label="item.accountName" :value="item.accountId"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="投放账户" prop="GGZaccountId">
            <el-select v-model="account.GGZaccountId" filterable style="width:300px">
              <el-option v-for="(item, index) in accountHugeAdvENTLists[account.accountId]" :key="index" :label="item.accountName" :value="item.accountId"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isSync = false">取 消</el-button>
        <el-button type="primary" @click="sync">确认同步</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import getProjectAndProduct from '@/mixin/account.js'
import TablePane from '@/components/tablePane.vue'
import { Page, Synchronization } from '@/network/api/huge/assetManagementHugeCreativeComponent.js'
// import elFormSearch from '@/mixin/el-form-search.js'

export default {
  components: {
    TablePane
  },
  mixins: [getProjectAndProduct],
  data() {
    return {
      ruleData: {
        housekeeperId: '',
        advId: '',
        assetName: '',
        componentType: '',
        status: '',
        componentName: ''
      },
      component_types: [],
      componentStatus: [
        { dictLabel: '通过', dictValue: 'PASS' },
        { dictLabel: '审核中', dictValue: 'UNDER' },
        { dictLabel: '未通过', dictValue: 'REJECT' }
      ],
      isSync: false, // 批量激活visible
      account: {
        accountId: '',
        GGZaccountId: []
      }, // 激活选择的账户
      dataSource: {
        data: [], // 表格数据
        cols: [
          {
            label: '组件名称',
            prop: 'componentName'
          },
          {
            label: '组件ID',
            prop: 'componentId'
          },
          {
            label: '组件类型',
            render: (record) => {
              return this.component_types.filter((f) => record.componentType === f.dictValue)[0].dictLabel
            }
          },
          {
            label: '审核状态',
            render: (record) => {
              return this.componentStatus.filter((f) => record.status === f.dictValue)[0].dictLabel
            }
          },
          {
            label: '管家账号',
            prop: 'housekeeperAccountName'
          },
          {
            label: '投放账户',
            prop: 'advAccountName'
          }
        ], // 表格的列数据
        isIndex: true, // 列表序号
        loading: true, // loading
        isOperation: true,
        pageData: {
          total: 0 // 总条数
        },
        operation: {
          // 表格有操作列时设置
          label: '操作', // 列名
          width: '100', // 根据实际情况给宽度
          data: [
            // {
            //   tooltip: '预览', // 操作名称
            //   icon: 'el-icon-view',
            //   handleRow: () => {} // 自定义事件
            // },
            {
              tooltip: '编辑', // 操作名称
              icon: 'el-icon-edit',
              handleRow: (_, record) => {
                this.$router.push({
                  name: 'editCreativity',
                  params: {
                    id: record.creativeComponentId
                  }
                })
              } // 自定义事件
            }
          ]
        }
      }
    }
  },
  mounted() {
    this.getPage()
    this.getDicts('component_types').then((response) => {
      this.component_types = response.data
    })
  },
  methods: {
    /**
     * @description: 获取列表
     * @param {*} params
     */
    getPage(params = { pageNum: 1, pageSize: 10 }) {
      this.dataSource.loading = true
      Page({ ...params, ...this.ruleData }).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          this.dataSource.data = records
          this.dataSource.pageData.total = total
          this.dataSource.loading = false
        }
      })
    },
    /**
     * @description: 重置
     */
    resetForm() {
      this.$refs['ruleForm'].resetFields()
      this.getPage()
    },

    resetSyncForm() {
      this.$refs['syncFormRef'].resetFields()
      this.isSync = false
    },

    /**
     * @description: 同步事件管理
     */
    sync() {
      Synchronization(this.account).then(({ code }) => {
        if (code === 200) {
          this.account = {
            accountId: '',
            GGZaccountId: []
          }
          this.resetForm()
          this.resetSyncForm()
          return this.$message.success('同步成功!')
        }
      })
    },

    handleSelectHousekeeper(housekeeperId) {
      this.accountHugeHousekeeperENTsId = housekeeperId
      this.ruleData.advId = null
    },
    handleSelectHousekeeper1(housekeeperId) {
      this.accountHugeHousekeeperENTsId = housekeeperId
      this.account.GGZaccountId = null
    }
  }
}
</script>

<style lang="scss" scoped>
.creativity-detail {
  .creativity-detail-btn {
    display: flex;
    margin-bottom: 20px;
    justify-content: flex-start;
  }
}
</style>
