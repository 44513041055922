<template>
  <!--显示列弹框-->
  <el-dialog :visible.sync="show" :append-to-body="true" width="500px" top="10vh" @close="onCloseDialog">
    <!--自定义 title-->
    <span slot="title" class="dialog-title">素材信息</span>
    <div class="info">
      <div class="name">
        <span>素材名称</span>
        <el-input v-model="material.materialName" :disabled="true" show-word-limit maxlength="30" class="material-name" placeholder="请输入素材名称"></el-input><i slot="suffix" class="el-icon-edit el-input__icon"> </i>
      </div>
      <div class="info-image">
        <img v-if="type === 'image'" :src="material.url" alt="" />
        <video v-if="type === 'video'" :src="material.url" controls></video>
      </div>
      <!-- 基础信息 -->
      <div class="info-detail">
        <div class="title">
          <div class="long"></div>
          <div class="text">基础信息</div>
        </div>
        <div class="detail">
          <div class="item">
            <div>
              素材ID<span>{{ material.imageId || material.videoId }}</span>
            </div>
            <div>
              创建时间<span>{{ material.createdAt }}</span>
            </div>
          </div>
          <div class="item">
            <div>
              素材尺寸<span>{{ material.width }}*{{ material.height }}</span>
            </div>
            <div v-if="type === 'image'">
              素材大小<span>{{ Math.floor(material.size / 1024) }} KB</span>
            </div>
            <div v-if="type === 'video'">
              素材大小<span>{{ Math.floor(material.size / 1024 / 1024) }} MB</span>
            </div>
          </div>
          <div class="item">
            <div>
              专辑<span>{{ material.albumName || '无' }}</span>
            </div>
            <div>
              素材组<span>{{ material.materialGroupName || '无' }}</span>
            </div>
          </div>
          <div class="item">
            <div>
              来源<span>{{ material.nickname }}</span>
            </div>
          </div>
          <div class="input">
            <span>关键字</span>
            <el-input v-model="material.keyword" show-word-limit maxlength="30" class="textarea" placeholder="请输入关键字"></el-input><i slot="suffix" class="el-icon-edit el-input__icon"> </i>
          </div>
        </div>
      </div>
      <!-- 人员信息 -->
      <div class="user">
        <div class="title">
          <div class="long"></div>
          <div class="text">人员信息</div>
        </div>
        <div class="item">
          <span>创意人</span>
          <el-select v-model="creator" value-key="key" filterable clearable placeholder="请选投放人员">
            <el-option v-for="(itm, idx) in userList" :key="idx" :label="itm.label" :value="itm"></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">取 消</el-button>
      <el-button class="btn" @click="onClickMove">移 动</el-button>
      <el-button class="btn" type="primary" :loading="isLoading" :disabled="isLoading" @click="clickConfirm('form')">确 定</el-button>
    </div>
    <!-- 批量移动弹窗 -->
    <batch-move-material-dialog ref="batchMoveMaterialDialog" :type="type" @edit="handleDatail"></batch-move-material-dialog>
  </el-dialog>
</template>

<script>
import { getImageMarterialDetail, getVideoMarterialDetail, editImageMarterial, editVideoMarterial } from '@/network/api/api-material'
import { getUser } from '@/network/api/user'
import BatchMoveMaterialDialog from '@/components/materialManagement/material/BatchMoveMaterialDialog'
export default {
  name: 'MaterialDetailDialog',
  components: {
    'batch-move-material-dialog': BatchMoveMaterialDialog
  },
  filters: {},
  props: {},
  data() {
    return {
      show: false, // 是否显示弹框
      dialogTitle: '',
      type: '',
      material: {}, // 素材详情
      creator: '',
      id: '',
      userList: [],
      isLoading: false // 是否正在加载
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    /* --------------------------- Actions --------------------------- */
    // 显示弹框
    showModal(type, id) {
      this.show = true
      this.id = id
      this.type = type
      this.dialogTitle = '素材详情'
      this._getUserList()
      if (id && this.type === 'image') {
        this._getImageMarterialDetail(id)
      } else {
        this._getVideoMarterialDetail(id)
      }
    },

    // 关闭弹框
    closeModal() {
      this.show = false
    },
    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      // 清除数据
      this.creator = ''
      this.id = ''
      this.type = ''
      this.userList = []
      this.material = {}
    },

    // 点击确定按钮
    clickConfirm() {
      this.type === 'image' ? this._editImageMarterial() : this._editVideoMarterial()
    },
    // 点击移动
    onClickMove() {
      let ids = []
      ids.push(this.id)
      this.$refs.batchMoveMaterialDialog.showModal(ids)
    },
    /* --------------------------- Private --------------------------- */
    // 弹窗回调
    handleDatail() {
      if (this.type === 'image') {
        this._getImageMarterialDetail(this.id)
      } else {
        this._getVideoMarterialDetail(this.id)
      }
    },
    // 获取成员列表
    _getUserList() {
      getUser().then((res) => {
        if (res.code === 200) {
          this.userList = res.data
        }
      })
    },
    // 获取图片素材详情
    _getImageMarterialDetail(id) {
      this.isLoading = true
      getImageMarterialDetail({ id: id })
        .then((res) => {
          this.creator = res.data.creatorName
          this.material = res.data
        })
        .catch((err) => {
          this.$ErrorMessage(err.data || '获取图片素材详情失败')
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    // 获取视频素材详情
    _getVideoMarterialDetail(id) {
      this.isLoading = true
      getVideoMarterialDetail({ id: id })
        .then((res) => {
          this.creator = res.data.creatorName
          this.material = res.data
        })
        .catch((err) => {
          this.$ErrorMessage(err.data || '获取图片素材详情失败')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    // 编辑图片素材
    _editImageMarterial() {
      this.isLoading = true
      let params = {
        adminId: this.material.adminId,
        albumId: this.material.albumId,
        creatorId: this.creator.key,
        creatorName: this.creator.label,
        hash: this.material.hash,
        height: this.material.height,
        imageId: this.material.imageId,
        width: this.material.width,
        keyword: this.material.keyword,
        materialGroupId: this.material.materialGroupId,
        materialName: this.material.materialName,
        nickname: this.material.nickname,
        size: this.material.size,
        url: this.material.url
      }
      editImageMarterial(this.id, params)
        .then((res) => {
          // this.$SuccessMessage('编辑图片素材成功')
          this.$emit('edit')
          this.closeModal()
        })
        .catch(() => {
          this.$ErrorMessage('编辑图片素材失败')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    // 编辑视频素材
    _editVideoMarterial() {
      this.isLoading = true
      let params = {
        adminId: this.material.adminId,
        albumId: this.material.albumId,
        creatorId: this.creator.key,
        creatorName: this.creator.label,
        hash: this.material.hash,
        height: this.material.height,
        width: this.material.width,
        videoId: this.material.videoId,
        keyword: this.material.keyword,
        materialGroupId: this.material.materialGroupId,
        materialName: this.material.materialName,
        nickname: this.material.nickname,
        size: this.material.size,
        url: this.material.url
      }
      editVideoMarterial(this.id, params)
        .then((res) => {
          // this.$SuccessMessage('编辑视频素材成功')
          this.$emit('edit')
          this.closeModal()
        })
        .catch(() => {
          this.$ErrorMessage('编辑视频素材失败')
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.el-dialog__body {
  padding: 30px 10px !important;
}
::v-deep .material-name .el-input__inner {
  border: none;
  border-radius: 0 !important;
  border-bottom: 1px solid #ccc !important;
}
::v-deep .textarea .el-input__inner {
  border: none;
  border-radius: 0 !important;
  border-bottom: 1px solid #ccc !important;
}
</style>
<style scoped lang="scss">
.info {
  .name {
    display: flex;
    margin-top: -30px;
    // padding-left: 10px;
    margin-bottom: 10px;
    align-items: center;
    span {
      margin-right: 5px;
    }
    .material-name {
      width: 300px;
    }
  }
  .info-image {
    width: 100%;
    height: 250px;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 90%;
      height: 90%;
    }
    video {
      width: 90%;
      height: 90%;
    }
  }
  .info-detail {
    width: 100%;
    margin-top: 5px;
    .title {
      display: flex;
      .long {
        width: 3px;
        background-color: #ccc;
        margin-right: 5px;
      }
      .text {
        font-size: 16px;
        font-weight: 700;
      }
    }
    .detail {
      .item {
        font-size: 14px;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        div {
          width: 200px;
          margin-top: 3px;
        }
        span {
          margin-left: 10px;
        }
      }
      .input {
        display: flex;
        padding-left: 10px;
        align-items: center;
        span {
          margin-right: 5px;
        }
        .textarea {
          width: 300px;
        }
      }
    }
  }
  .user {
    .title {
      display: flex;
      margin-top: 5px;
      .long {
        width: 3px;
        background-color: #ccc;
        margin-right: 5px;
      }
      .text {
        font-size: 16px;
        font-weight: 700;
      }
    }
    .item {
      font-size: 14px;
      padding: 0 10px;
      span {
        margin: 0 10px;
      }
    }
  }
}
</style>
