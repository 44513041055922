/*
 * @Author: Zeng
 * @Date: 2023-03-14 14:02:48
 * @LastEditTime: 2023-10-23 15:43:27
 */

// 数据条件
export const dataConditions = {
  1: [
    // { id: 1, value: '累计注册用户' },
    { id: 2, value: '已支付用户' }
    // { id: 3, value: '未支付用户' }
  ],
  2: [
    // { id: 1, value: '累计加粉用户' },
    { id: 2, value: '留存用户' },
    { id: 3, value: '流失用户' }
  ]
}

// 关联类型
export const relationTypes = {
  1: [
    // { id: 1, value: '宙斯-稻香账号' },
    // { id: 2, value: '宙斯-晴天账号' },
    // { id: 3, value: '宙斯-花海账号' },
    { id: 4, value: '宙斯-短剧账号' }
  ],
  2: [
    // { id: 1, value: '宙斯-稻香账号' },
    // { id: 2, value: '宙斯-晴天账号' },
    // { id: 3, value: '宙斯-花海账号' },
    { id: 4, value: '宙斯-短剧账号' },
    { id: 5, value: '宙斯-盘古账号' }, // 条件：企微uinionid & 留存用户
    { id: 6, value: '宙斯-晴天账号(混合)' }, // 条件：企微uinionid & 留存用户
    { id: 7, value: '宙斯-晴天账号(直投)' } // 条件：企微uinionid & 留存用户
  ]
}

export function computeDuring(day) {
  const dateStart = new Date()
  const dateEnd = new Date()
  let timeRange = []
  if (day === 1) {
    dateEnd.setTime(dateEnd.getTime() - 3600 * 1000 * 24)
    timeRange = [dateEnd, dateEnd]
  } else {
    dateStart.setTime(dateStart.getTime() - 3600 * 1000 * 24 * (day - 1))
    dateEnd.setTime(dateEnd.getTime())
    timeRange = [dateStart, dateEnd]
  }
  return timeRange
}
