import service from '@/network/service/service'

export function Page(data) {
  return service.post('/admin/adminLog/page', data)
}

export function Add(data) {
  return service.post('/admin/adminLog/save', data)
}

export function Update(data) {
  return service.post('/admin/adminLog/update', data)
}

export function Delete(data) {
  return service.post('/admin/adminLog/delete', data)
}

