var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "tempalte",
    },
    [
      _vm.isShowStep
        ? [
            _c(
              "el-steps",
              { attrs: { active: _vm.active } },
              [
                _c("el-step", { attrs: { title: "步骤 1" } }),
                _c("el-step", { attrs: { title: "步骤 2" } }),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.active === 1
        ? [
            _c(
              "el-form",
              { staticClass: "active-one", attrs: { "label-width": "100px" } },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "投放版本" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "300px" },
                        model: {
                          value: _vm.ruleData.launchVersion,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleData, "launchVersion", $$v)
                          },
                          expression: "ruleData.launchVersion",
                        },
                      },
                      _vm._l(_vm.launchVersions, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.text, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "营销链路" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "300px" },
                        model: {
                          value: _vm.ruleData.marketingPurpose,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleData, "marketingPurpose", $$v)
                          },
                          expression: "ruleData.marketingPurpose",
                        },
                      },
                      _vm._l(_vm.marketingPurposes, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.text, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "推广目的" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "300px" },
                        model: {
                          value: _vm.ruleData.landingType,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleData, "landingType", $$v)
                          },
                          expression: "ruleData.landingType",
                        },
                      },
                      _vm._l(_vm.landingTypes, function (itm, idx) {
                        return _c("el-option", {
                          key: idx,
                          attrs: { label: itm.text, value: itm.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", plain: "" },
                    on: { click: _vm.handleNextStep },
                  },
                  [_vm._v("下一步")]
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm.active === 2
        ? [
            _c(
              "div",
              { staticClass: "config" },
              [
                _c(
                  "Base",
                  { attrs: { title: "基础信息" } },
                  [
                    _c(
                      "el-form",
                      {
                        attrs: {
                          inline: true,
                          model: _vm.ruleData,
                          rules: _vm.basicRule,
                          "label-width": "100px",
                        },
                      },
                      [
                        _c(
                          "el-row",
                          { attrs: { gutter: 24 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "任务名称",
                                      prop: "taskName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "300px" },
                                      attrs: {
                                        placeholder: "请输入任务名称",
                                        maxlength: "30",
                                        "show-word-limit": "",
                                      },
                                      on: { blur: _vm.handleInputName },
                                      model: {
                                        value: _vm.ruleData.taskName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.ruleData,
                                            "taskName",
                                            $$v
                                          )
                                        },
                                        expression: "ruleData.taskName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "项目", prop: "projectId" },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "300px" },
                                        attrs: {
                                          filterable: "",
                                          placeholder: "请选择项目",
                                        },
                                        on: { change: _vm.handleChangeProject },
                                        model: {
                                          value: _vm.ruleData.projectId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleData,
                                              "projectId",
                                              $$v
                                            )
                                          },
                                          expression: "ruleData.projectId",
                                        },
                                      },
                                      _vm._l(
                                        _vm.projectList,
                                        function (item, index) {
                                          return _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label: item.projectName,
                                              value: item.bsProjectId + "",
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: { label: "产品", prop: "productId" },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "300px" },
                                        attrs: {
                                          filterable: "",
                                          disabled: !_vm.ruleData.projectId,
                                          placeholder: "请选择产品",
                                        },
                                        on: { change: _vm.handleChangeProduct },
                                        model: {
                                          value: _vm.ruleData.productId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleData,
                                              "productId",
                                              $$v
                                            )
                                          },
                                          expression: "ruleData.productId",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: { label: "不限", value: "-1" },
                                        }),
                                        _vm._l(
                                          _vm.productList,
                                          function (item, index) {
                                            return _c("el-option", {
                                              key: index,
                                              attrs: {
                                                label: item.productName,
                                                value: item.bsProductId + "",
                                              },
                                            })
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "管理账号",
                                      prop: "housekeeperId",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "300px" },
                                        attrs: {
                                          placeholder: "请选择管理账号",
                                          filterable: "",
                                        },
                                        on: {
                                          change: _vm.handleChangeHouseKeeper,
                                        },
                                        model: {
                                          value: _vm.ruleData.housekeeperId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleData,
                                              "housekeeperId",
                                              $$v
                                            )
                                          },
                                          expression: "ruleData.housekeeperId",
                                        },
                                      },
                                      _vm._l(
                                        _vm.houseKeeperList,
                                        function (item, index) {
                                          return _c(
                                            "el-option",
                                            {
                                              key: index,
                                              attrs: {
                                                label: item.accountName,
                                                value: item.accountId,
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(item.accountName) +
                                                    "(" +
                                                    _vm._s(item.accountId) +
                                                    ")"
                                                ),
                                              ]),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "投放账户",
                                      prop: "advertisers",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "300px" },
                                        attrs: {
                                          placeholder: "请选择投放账户",
                                          "collapse-tags": "",
                                          "value-key": "advertiserId",
                                          disabled: !_vm.ruleData.housekeeperId,
                                          filterable: "",
                                          multiple: "",
                                        },
                                        on: { change: _vm.handleChangeAdv },
                                        model: {
                                          value: _vm.ruleData.advertisers,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleData,
                                              "advertisers",
                                              $$v
                                            )
                                          },
                                          expression: "ruleData.advertisers",
                                        },
                                      },
                                      _vm._l(
                                        _vm.advertiserList,
                                        function (item, index) {
                                          return _c(
                                            "el-option",
                                            {
                                              key: index,
                                              attrs: {
                                                label: item.name,
                                                value: item,
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(item.name) +
                                                    "(" +
                                                    _vm._s(item.advertiserId) +
                                                    ")"
                                                ),
                                              ]),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "推广目的",
                                      prop: "landingType",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "300px" },
                                        model: {
                                          value: _vm.ruleData.landingType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.ruleData,
                                              "landingType",
                                              $$v
                                            )
                                          },
                                          expression: "ruleData.landingType",
                                        },
                                      },
                                      _vm._l(
                                        _vm.landingTypes,
                                        function (itm, idx) {
                                          return _c("el-option", {
                                            key: idx,
                                            attrs: {
                                              label: itm.text,
                                              value: itm.id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("Base", { attrs: { title: "配置区" } }, [
                  _c(
                    "div",
                    { staticClass: "config-body" },
                    [
                      _c(
                        "div",
                        { staticClass: "config-body-content" },
                        [
                          _c(
                            "div",
                            { staticClass: "config-body-content-title-box" },
                            [
                              _c(
                                "el-row",
                                {
                                  staticClass: "config-body-content-title",
                                  attrs: { gutter: 28 },
                                },
                                [
                                  _c("el-col", { attrs: { span: 3 } }, [
                                    _c(
                                      "div",
                                      { staticClass: "group content-title" },
                                      [_vm._v("广告组")]
                                    ),
                                  ]),
                                  _c("el-col", { attrs: { span: 6 } }, [
                                    _c(
                                      "div",
                                      { staticClass: "plan content-title" },
                                      [_vm._v("广告计划")]
                                    ),
                                  ]),
                                  _c("el-col", { attrs: { span: 15 } }, [
                                    _c(
                                      "div",
                                      { staticClass: "creative content-title" },
                                      [_vm._v("广告创意")]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            {
                              staticClass: "config-body-content-middle",
                              attrs: { gutter: 28 },
                            },
                            [
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "item" },
                                  [
                                    _c("div", { staticClass: "item-header" }, [
                                      _c("div", { staticClass: "left" }, [
                                        _c("span", [_vm._v("广告组信息")]),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "item-body" }, [
                                      _c("div", [
                                        _vm._v("广告组预算: "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.advertiseGroup.budgetMode ==
                                                "BUDGET_MODE_INFINITE"
                                                ? "不限"
                                                : _vm.advertiseGroup.budget
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("div", [
                                        _vm._v("广告组默认状态: "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.advertiseGroup
                                                .groupOperation == "enable"
                                                ? "开启"
                                                : "暂停"
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("div", [
                                        _vm._v("组内计划上限: "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.advertiseGroup.groupPlanMax ||
                                                _vm.GROUP_PLAN_NUM
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                    _vm.isBasicHasValue
                                      ? _c(
                                          "el-button",
                                          {
                                            staticClass: "item-bottom",
                                            attrs: { icon: "el-icon-edit" },
                                            on: { click: _vm.handleClickRules },
                                          },
                                          [_vm._v("编辑 ")]
                                        )
                                      : _vm._e(),
                                    !_vm.isBasicHasValue
                                      ? _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "top",
                                              trigger: "hover",
                                              content: "请填写基础信息",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass:
                                                  "item-bottom disabled",
                                                attrs: {
                                                  slot: "reference",
                                                  type: "text",
                                                  icon: "el-icon-edit",
                                                },
                                                slot: "reference",
                                              },
                                              [_vm._v("编辑 ")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "item" },
                                  [
                                    _c("div", { staticClass: "item-header" }, [
                                      _c("div", { staticClass: "left" }, [
                                        _c("span", [_vm._v("计划基本信息")]),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "item-body" }, [
                                      _c("div", [
                                        _vm._v("广告位置: "),
                                        _vm.advertisePlan.id
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f(
                                                    "filterInventoryCatalog"
                                                  )(
                                                    _vm.advertisePlan
                                                      .inventoryCatalog
                                                  )
                                                )
                                              ),
                                            ])
                                          : _c("span", [_vm._v("无")]),
                                      ]),
                                      _c("div", [
                                        _vm._v("营销链路: "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.advertisePlan.id
                                                ? _vm.advertisePlan
                                                    .marketingPurpose ===
                                                  "CONVERSION"
                                                  ? "行动转化"
                                                  : "无"
                                                : "无"
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("div", [
                                        _vm._v("投放内容: "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.computedDeliverContent)
                                          ),
                                        ]),
                                      ]),
                                      _c("div", [
                                        _vm._v("转化目标: "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("filterConvertType")(
                                                _vm.advertisePlan.convertType
                                              )
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("div", [
                                        _vm._v("转化出价: "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.advertisePlan.cpaBid || 0
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                    _vm.advertiseGroup.id
                                      ? _c(
                                          "el-button",
                                          {
                                            staticClass: "item-bottom",
                                            attrs: { icon: "el-icon-edit" },
                                            on: { click: _vm.handleClickPlan },
                                          },
                                          [_vm._v(" 编辑 ")]
                                        )
                                      : _vm._e(),
                                    !_vm.advertiseGroup.id
                                      ? _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "top",
                                              trigger: "hover",
                                              content: "请填写广告组信息",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass:
                                                  "item-bottom disabled",
                                                attrs: {
                                                  slot: "reference",
                                                  type: "text",
                                                  icon: "el-icon-edit",
                                                },
                                                slot: "reference",
                                              },
                                              [_vm._v("编辑 ")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "item" },
                                  [
                                    _c("div", { staticClass: "item-header" }, [
                                      _c("div", { staticClass: "left" }, [
                                        _c("span", [_vm._v("定向包")]),
                                        _c(
                                          "span",
                                          { staticClass: "left-desc" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.audiencePackages.length
                                              ) +
                                                "/" +
                                                _vm._s(_vm.AUDIENCE_TOTAL)
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "right" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                icon: "el-icon-refresh",
                                                size: "mini",
                                              },
                                              on: {
                                                click: _vm.handleCleanAudience,
                                              },
                                            },
                                            [_vm._v("清空 ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "item-body" },
                                      [
                                        _c("div", [_vm._v("已选定向包:")]),
                                        _vm._l(
                                          _vm.audiencePackages,
                                          function (item, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass: "item-title-item",
                                              },
                                              [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    attrs: {
                                                      effect: "dark",
                                                      content: item.name,
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "title-text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.name)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-circle-close",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleDeletePackage(
                                                        item,
                                                        index
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                    _vm.advertiseGroup.id
                                      ? _c(
                                          "el-button",
                                          {
                                            staticClass: "item-bottom",
                                            attrs: { icon: "el-icon-plus" },
                                            on: {
                                              click: _vm.handleClickAudience,
                                            },
                                          },
                                          [_vm._v(" 添加 ")]
                                        )
                                      : _vm._e(),
                                    !_vm.advertiseGroup.id
                                      ? _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "top",
                                              trigger: "hover",
                                              content: "请填写广告组信息",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass:
                                                  "item-bottom disabled",
                                                attrs: {
                                                  slot: "reference",
                                                  type: "text",
                                                  icon: "el-icon-plus",
                                                },
                                                slot: "reference",
                                              },
                                              [_vm._v("添加 ")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "item" },
                                  [
                                    _c("div", { staticClass: "item-header" }, [
                                      _c("div", { staticClass: "left" }, [
                                        _c("span", [_vm._v("创建基本信息")]),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "item-body" }, [
                                      _c("div", [
                                        _vm._v("来源："),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.advertiseCreative.source ||
                                                "无"
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("div", [
                                        _vm._v("广告评论： "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.advertiseCreative.id
                                                ? _vm.advertiseCreative
                                                    .isCommentDisable === 1
                                                  ? "关闭"
                                                  : "启用"
                                                : "无"
                                            )
                                          ),
                                        ]),
                                      ]),
                                      _c("div", [
                                        _vm._v("创意分类： "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.advertiseCreative
                                                .thirdIndustryNames || "无"
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ]),
                                    _vm.advertisePlan.id
                                      ? _c(
                                          "el-button",
                                          {
                                            staticClass: "item-bottom",
                                            attrs: { icon: "el-icon-edit" },
                                            on: {
                                              click: _vm.handleClickCreative,
                                            },
                                          },
                                          [_vm._v(" 编辑 ")]
                                        )
                                      : _vm._e(),
                                    !_vm.advertisePlan.id
                                      ? _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "top",
                                              trigger: "hover",
                                              content: "请填写计划基本信息",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass:
                                                  "item-bottom disabled",
                                                attrs: {
                                                  slot: "reference",
                                                  type: "text",
                                                  icon: "el-icon-edit",
                                                },
                                                slot: "reference",
                                              },
                                              [_vm._v("编辑 ")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 6 } }, [
                                _c(
                                  "div",
                                  { staticClass: "item" },
                                  [
                                    _c("div", { staticClass: "item-header" }, [
                                      _c("div", { staticClass: "left" }, [
                                        _c("span", [_vm._v("创意素材")]),
                                        _c(
                                          "span",
                                          { staticClass: "left-desc" },
                                          [
                                            _vm._v(
                                              "已选创意组: " +
                                                _vm._s(
                                                  _vm.creativeMedia.groups
                                                    .length
                                                ) +
                                                "/" +
                                                _vm._s(_vm.MATERIAL_TOTAL)
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "right" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                icon: "el-icon-refresh",
                                                size: "mini",
                                              },
                                              on: {
                                                click: _vm.handleCleanMaterial,
                                              },
                                            },
                                            [_vm._v("清空 ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "item-material-body" },
                                      _vm._l(
                                        _vm.creativeMedia.groups,
                                        function (item, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "material-group",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "material-group-title",
                                                },
                                                [_vm._v(_vm._s(item.groupName))]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "material-group-list",
                                                },
                                                [
                                                  _vm._l(
                                                    item.videos,
                                                    function (itm, idx) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: `video-${idx}`,
                                                          staticClass:
                                                            "videoImage",
                                                        },
                                                        [
                                                          _c("el-image", {
                                                            attrs: {
                                                              src: itm.localImageUrl,
                                                              fit: "cover",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  _vm._l(
                                                    item.images,
                                                    function (itm, idx) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: `image-${idx}`,
                                                          staticClass: "image",
                                                        },
                                                        [
                                                          _c("el-image", {
                                                            attrs: {
                                                              src: itm.localImageUrl,
                                                              fit: "cover",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                    _vm.advertisePlan.id
                                      ? _c(
                                          "el-button",
                                          {
                                            staticClass: "item-bottom",
                                            attrs: { icon: "el-icon-plus" },
                                            on: {
                                              click:
                                                _vm.handleClickCreativeMaterial,
                                            },
                                          },
                                          [_vm._v(" 添加 ")]
                                        )
                                      : _vm._e(),
                                    !_vm.advertisePlan.id
                                      ? _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "top",
                                              trigger: "hover",
                                              content: "请填写计划基本信息",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass:
                                                  "item-bottom disabled",
                                                attrs: {
                                                  slot: "reference",
                                                  type: "text",
                                                  icon: "el-icon-plus",
                                                },
                                                slot: "reference",
                                              },
                                              [_vm._v("添加 ")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  { staticClass: "item" },
                                  [
                                    _c("div", { staticClass: "item-header" }, [
                                      _c("div", { staticClass: "left" }, [
                                        _c("span", [_vm._v("标题包")]),
                                        _c(
                                          "span",
                                          { staticClass: "left-desc" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.titlePackages.length) +
                                                "/" +
                                                _vm._s(_vm.TITLE_TOTAL)
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "right" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                icon: "el-icon-refresh",
                                                size: "mini",
                                              },
                                              on: {
                                                click: _vm.handleCleanTitle,
                                              },
                                            },
                                            [_vm._v("清空")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "item-body" },
                                      [
                                        _c("div", [_vm._v("已选标题包:")]),
                                        _vm._l(
                                          _vm.titlePackages,
                                          function (item, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass: "item-title-item",
                                              },
                                              [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    attrs: {
                                                      effect: "dark",
                                                      content:
                                                        item.titlePackageName,
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "title-text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.titlePackageName
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-circle-close",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleDeleteTitle(
                                                        item,
                                                        index
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                    _vm.advertisePlan.id
                                      ? _c(
                                          "el-button",
                                          {
                                            staticClass: "item-bottom",
                                            attrs: { icon: "el-icon-plus" },
                                            on: { click: _vm.handleClickTitle },
                                          },
                                          [_vm._v(" 添加 ")]
                                        )
                                      : _vm._e(),
                                    !_vm.advertisePlan.id
                                      ? _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "top",
                                              trigger: "hover",
                                              content: "请填写计划基本信息",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass:
                                                  "item-bottom disabled",
                                                attrs: {
                                                  slot: "reference",
                                                  type: "text",
                                                  icon: "el-icon-plus",
                                                },
                                                slot: "reference",
                                              },
                                              [_vm._v("添加 ")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                              _c("el-col", { attrs: { span: 3 } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "item",
                                    staticStyle: { border: "none" },
                                  },
                                  [
                                    _c("div", { staticClass: "item-header" }, [
                                      _c("div", { staticClass: "left" }, [
                                        _c("span", [_vm._v("落地页")]),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "right" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                icon: "el-icon-refresh",
                                                size: "mini",
                                              },
                                              on: {
                                                click: _vm.handleCleanPages,
                                              },
                                            },
                                            [_vm._v("清空")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "item-body" },
                                      [
                                        _c("div", [_vm._v("已选落地页:")]),
                                        _vm._l(
                                          _vm.landingPages,
                                          function (item, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass: "item-title-item",
                                              },
                                              [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    attrs: {
                                                      effect: "dark",
                                                      content: item.name,
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "title-text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.name)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-circle-close",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleDeletePages(
                                                        item,
                                                        index
                                                      )
                                                    },
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                    _vm.advertisePlan.id
                                      ? _c(
                                          "el-button",
                                          {
                                            staticClass: "item-bottom",
                                            attrs: { icon: "el-icon-plus" },
                                            on: { click: _vm.handleClickPages },
                                          },
                                          [_vm._v(" 添加 ")]
                                        )
                                      : _vm._e(),
                                    !_vm.advertisePlan.id
                                      ? _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "top",
                                              trigger: "hover",
                                              content: "请填写计划基本信息",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass:
                                                  "item-bottom disabled",
                                                attrs: {
                                                  slot: "reference",
                                                  type: "text",
                                                  icon: "el-icon-plus",
                                                },
                                                slot: "reference",
                                              },
                                              [_vm._v("添加 ")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        {
                          staticClass: "config-body-content-bottom",
                          attrs: { gutters: 20 },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 22 } },
                            [
                              _vm.advertisePlan.id &&
                              _vm.advertisePlan.trackType == 1
                                ? _c(
                                    "el-button",
                                    { on: { click: _vm.handleClickTracking } },
                                    [_vm._v("转化追踪 ")]
                                  )
                                : _vm._e(),
                              _vm.advertisePlan.id &&
                              _vm.advertisePlan.trackType == 2 &&
                              _vm.advertisePlan.contentType !==
                                "TETRIS_EXTERNAL"
                                ? _c(
                                    "el-button",
                                    { on: { click: _vm.handleClickAssets } },
                                    [_vm._v("资产选择 ")]
                                  )
                                : _vm._e(),
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    disabled: !_vm.ruleData.advertisers.length,
                                  },
                                  on: { click: _vm.handleClickTraceUrl },
                                },
                                [_vm._v("监测链接")]
                              ),
                              _vm.advertiseCreative.id &&
                              _vm.advertiseCreative.douyin == 2
                                ? _c(
                                    "el-button",
                                    { on: { click: _vm.handleClickDouyin } },
                                    [_vm._v("抖音号 ")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticStyle: { "text-align": "right" },
                              attrs: { span: 2 },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    disabled: !_vm.showPreviewBtn,
                                    loading: _vm.previewLoading,
                                  },
                                  on: { click: _vm.handleClickPreviewAd },
                                },
                                [_vm._v("预览广告 ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.previewData.length
                  ? _c(
                      "Base",
                      { attrs: { title: "预览区" } },
                      [
                        _c("preview-ad", {
                          attrs: {
                            info: _vm.previewData,
                            "base-id": _vm.baseId,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("c-rules-config-dialog", {
                  ref: "rulesConfigDialog",
                  attrs: { "base-id": _vm.baseId },
                  on: { confirm: _vm.getRuleConfig },
                }),
                _c("ad-info-dialog", {
                  ref: "adInfoDialog",
                  attrs: { "base-id": _vm.baseId },
                  on: { confirm: _vm.getAdInfo },
                }),
                _c("orientation-package-dialog", {
                  ref: "orientationPackageDialog",
                  attrs: { "base-id": _vm.baseId },
                  on: { confirm: _vm.getPackageData },
                }),
                _c("creative-info-config-dialog", {
                  ref: "creativeInfoConfigDialog",
                  attrs: {
                    "base-id": _vm.baseId,
                    "housekeeper-id": _vm.ruleData.housekeeperId,
                  },
                  on: { confirm: _vm.getCreative },
                }),
                _c("material-select-dialog", {
                  ref: "materialSelectDialog",
                  attrs: {
                    "base-id": _vm.baseId,
                    "project-list": _vm.projectList,
                    advertisers: _vm.advertisers,
                  },
                  on: { confirm: _vm.getMaterialList },
                }),
                _c("text-select-dialog", {
                  ref: "textSelectDialog",
                  attrs: { "base-id": _vm.baseId },
                  on: { confirm: _vm.getTxtList },
                }),
                _c("landing-pages-select-dialog", {
                  ref: "landingPageSelectDialog",
                  attrs: {
                    "base-id": _vm.baseId,
                    "housekeeper-id": _vm.ruleData.housekeeperId,
                  },
                  on: { confirm: _vm.getLandingPages },
                }),
                _c("conversion-tracking-dialog", {
                  ref: "conversionTrackingDialog",
                  attrs: {
                    "base-id": _vm.baseId,
                    "housekeeper-id": _vm.ruleData.housekeeperId,
                  },
                  on: { confirm: _vm.getTrackingData },
                }),
                _c("assets-select-dialog", {
                  ref: "assetsSelectDialog",
                  attrs: {
                    "base-id": _vm.baseId,
                    "housekeeper-id": _vm.ruleData.housekeeperId,
                  },
                  on: { confirm: _vm.getAssetsData },
                }),
                _c("douyin-select-dialog", {
                  ref: "douyinSelectDialog",
                  attrs: { "base-id": _vm.baseId },
                  on: { confirm: _vm.getDouyinData },
                }),
                _c("trace-url-dialog", {
                  ref: "traceUrlDialog",
                  attrs: { "base-id": _vm.baseId },
                  on: { confirm: _vm.getTraceUrlData },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }