export const searchForm = {

  selectDate: null,
  baiduAdvPlanId: null,
  campaignFeedId: null,
  campaignFeedName: null,
  subject: null,
  appinfo: null,
  budget: null,
  starttime: null,
  endtime: null,
  schedule: null,
  bgtctltype: null,
  pause: null,
  status: null,
  bstype: null,
  bsProjectId: null,
  projectName: null,
  bsProductId: null,
  productName: null,
  deleted: null,
  createBy: null,
  createTime: null,
  updateBy: null,
  updateTime: null,
  remark: null
}

export class AdvManagementBaiduPlanEnt {
  baiduAdvPlanId
  campaignFeedId
  campaignFeedName
  subject
  appinfo
  budget
  starttime
  endtime
  schedule
  bgtctltype
  pause
  status
  bstype
  bsProjectId
  projectName
  bsProductId
  productName
  deleted
  createBy
  createTime
  updateBy
  updateTime
  remark
}

export const formRules = {
  baiduAdvPlanId: [{ required: true, message: '请填写', trigger: 'change' }],

  campaignFeedName: [{ required: true, message: '请填写计划名称。长度限制最大100个字节，1个中文及中文符号按2个字节计算', trigger: 'change' }],
  subject: [{ required: true, message: '请填写推广对象。取值范围如下：1：网站链接2：应用下载（IOS）3：应用下载（Android）', trigger: 'change' }],
  appinfo: [{ required: true, message: '请填写推广app信息。subject=1时，该字段无效。对象定义参考下文推广app信息object', trigger: 'change' }],
  budget: [{ required: true, message: '请填写推广计划预算。默认为0,表示不限预算。正常取值范围为[50 - 9999999.99]', trigger: 'change' }],
  starttime: [{ required: true, message: '请填写推广开始日期。', trigger: 'change' }],
  endtime: [{ required: true, message: '请填写推广结束日期。', trigger: 'change' }],
  schedule: [{ required: true, message: '请填写暂停时段设置，对象定义参考下文暂停时段设置array(object)', trigger: 'change' }],
  bgtctltype: [{ required: true, message: '请填写预算分配控制方式。', trigger: 'change' }],
  pause: [{ required: true, message: '请填写是否暂停推广。默认为false。true：推广计划暂停 false：推广计划启用', trigger: 'change' }],
  status: [{
    required: true, message: '请填写推广计划状态', trigger: 'change'
  }],
  bstype: [{ required: true, message: '请填写1：普通计划 3：闪投计划 7：原生RTA 注：不支持修改', trigger: 'change' }],
  bsProjectId: [{ required: true, message: '请填写项目id', trigger: 'change' }],
  projectName: [{ required: true, message: '请填写项目名称', trigger: 'change' }],
  bsProductId: [{ required: true, message: '请填写产品id', trigger: 'change' }],
  productName: [{ required: true, message: '请填写产品name', trigger: 'change' }],
  deleted: [{ required: true, message: '请填写逻辑删除', trigger: 'change' }],
  createBy: [{ required: true, message: '请填写创建者', trigger: 'change' }],
  createTime: [{ required: true, message: '请填写创建时间', trigger: 'change' }],
  updateBy: [{ required: true, message: '请填写更新者', trigger: 'change' }],
  updateTime: [{ required: true, message: '请填写更新时间', trigger: 'change' }],
  remark: [{ required: true, message: '请填写备注', trigger: 'change' }]
}
export class ScheduleType {
  weekDay
  startHour
  endHour
}
