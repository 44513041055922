<template>
  <el-dialog
    :title="isEdit ? '编 辑': '新 增'"
    width="45%"
    :visible.sync="visible"
    :before-close="close"
    label-position="right"
    :modal-append-to-body="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <el-form ref="ent" :model="ent" :rules="rules" label-width="110px">
      <el-form-item label="" prop="orangeCreateId">
        <el-input v-model="ent.orangeCreateId"></el-input>
      </el-form-item>
      <el-form-item label="具体见返回示例业务数据" prop="bricks">
        <el-input v-model="ent.bricks"></el-input>
      </el-form-item>
      <el-form-item label="站点ID" prop="id">
        <el-input v-model="ent.siteId"></el-input>
      </el-form-item>
      <el-form-item label="站点名称" prop="name">
        <el-input v-model="ent.name"></el-input>
      </el-form-item>
      <el-form-item label="站点状态" prop="status">
        <el-input v-model="ent.status"></el-input>
      </el-form-item>
      <el-form-item label="建站类型" prop="siteType">
        <el-input v-model="ent.siteType"></el-input>
      </el-form-item>
      <el-form-item label="缩略图" prop="thumbnail">
        <el-input v-model="ent.thumbnail"></el-input>
      </el-form-item>
      <el-form-item label="请求日志id" prop="requestId">
        <el-input v-model="ent.requestId"></el-input>
      </el-form-item>
      <el-form-item label="关联管家账号名称" prop="relationHousekeeperName">
        <el-input v-model="ent.relationHousekeeperName"></el-input>
      </el-form-item>
      <el-form-item label="关联管家账号id" prop="relationHousekeeperId">
        <el-input v-model="ent.relationHousekeeperId"></el-input>
      </el-form-item>
      <el-form-item label="关联投放账户名称" prop="relationAdvAccountName">
        <el-input v-model="ent.relationAdvAccountName"></el-input>
      </el-form-item>
      <el-form-item label="关联投放账户id" prop="relationAdvId">
        <el-input v-model="ent.relationAdvId"></el-input>
      </el-form-item>
      <el-form-item label="逻辑删除" prop="deleted">
        <el-input v-model="ent.deleted"></el-input>
      </el-form-item>
      <el-form-item label="创建者" prop="createBy">
        <el-input v-model="ent.createBy"></el-input>
      </el-form-item>
      <el-form-item label="创建时间" prop="createTime">
        <el-input v-model="ent.createTime"></el-input>
      </el-form-item>
      <el-form-item label="更新者" prop="updateBy">
        <el-input v-model="ent.updateBy"></el-input>
      </el-form-item>
      <el-form-item label="更新时间" prop="updateTime">
        <el-input v-model="ent.updateTime"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="ent.remark"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button round @click="close">取消</el-button>
      <el-button type="primary" round @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {
  Add,
  Update
} from '@/network/api/assetManagement/assetManagementHugeCreateOrangeWebsite'
import { AssetManagementHugeCreateOrangeWebsiteEnt, formRules } from './object'

export default {
  inject: ['loadData'],
  data() {
    return {
      ent: new AssetManagementHugeCreateOrangeWebsiteEnt(),
      rules: formRules,
      isEdit: false,
      visible: false,
      cityList: []
    }
  },
  methods: {
    submit() {
      this.$refs.ent.validate((validate) => {
        if (validate) {
          this.isEdit ? this.updateRow() : this.addRow()
        }
      })
    },
    addRow() {
      let req = { ...this.ent }
      Add(req).then(res => {
        this.$message({
          message: '保存成功',
          type: 'success'
        })
        this.loadData()
        this.close()
      })
    },
    updateRow() {
      let req = { ...this.ent }
      Update(req).then(res => {
        this.$message({
          message: '修改成功',
          type: 'success'
        })
        this.loadData()
        this.close()
      })
    },
    close() {
      this.visible = false
      this.$nextTick(() => {
        this.$refs.ent.resetFields()
      })
    },
    show(isEdit = false, ent) {
      this.ent = ent
      this.isEdit = isEdit
      this.visible = true
      this.$nextTick(() => {
        if (this.isEdit) {
          console.log('不能为空')
        }
      })
    }
  }
}
</script>
