var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.isEdit ? "编 辑" : "新 增",
        width: "45%",
        visible: _vm.visible,
        "before-close": _vm.close,
        "label-position": "right",
        "modal-append-to-body": false,
        "close-on-press-escape": false,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ent",
          attrs: { model: _vm.ent, rules: _vm.rules, "label-width": "110px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "convertId" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.convertId,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "convertId", $$v)
                  },
                  expression: "ent.convertId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "广告主id", prop: "advertiserId" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.advertiserId,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "advertiserId", $$v)
                  },
                  expression: "ent.advertiserId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "转化id", prop: "id" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.id,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "id", $$v)
                  },
                  expression: "ent.id",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "转化名称\n", prop: "name" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "name", $$v)
                  },
                  expression: "ent.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "应用类型", prop: "appType" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.appType,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "appType", $$v)
                  },
                  expression: "ent.appType",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "包名", prop: "packageName" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.packageName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "packageName", $$v)
                  },
                  expression: "ent.packageName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "下载地址", prop: "downloadUrl" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.downloadUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "downloadUrl", $$v)
                  },
                  expression: "ent.downloadUrl",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "转化工具操作状态", prop: "optStatus" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.optStatus,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "optStatus", $$v)
                  },
                  expression: "ent.optStatus",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "转化来源", prop: "convertSourceType" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.convertSourceType,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "convertSourceType", $$v)
                  },
                  expression: "ent.convertSourceType",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "转化状态", prop: "status" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "status", $$v)
                  },
                  expression: "ent.status",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "转化类型", prop: "convertType" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.convertType,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "convertType", $$v)
                  },
                  expression: "ent.convertType",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "点击监测链接", prop: "actionTrackUrl" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.actionTrackUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "actionTrackUrl", $$v)
                  },
                  expression: "ent.actionTrackUrl",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "展示监测链接", prop: "displayTrackUrl" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.displayTrackUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "displayTrackUrl", $$v)
                  },
                  expression: "ent.displayTrackUrl",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "视频有效播放监测链接",
                prop: "videoPlayEffectiveTrackUrl",
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.videoPlayEffectiveTrackUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "videoPlayEffectiveTrackUrl", $$v)
                  },
                  expression: "ent.videoPlayEffectiveTrackUrl",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "视频播放完毕监测链接",
                prop: "videoPlayDoneTrackUrl",
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.videoPlayDoneTrackUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "videoPlayDoneTrackUrl", $$v)
                  },
                  expression: "ent.videoPlayDoneTrackUrl",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "视频播放监测链接", prop: "videoPlayTrackUrl" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.videoPlayTrackUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "videoPlayTrackUrl", $$v)
                  },
                  expression: "ent.videoPlayTrackUrl",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "激活回传地址",
                prop: "convertActivateCallbackUrl",
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.convertActivateCallbackUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "convertActivateCallbackUrl", $$v)
                  },
                  expression: "ent.convertActivateCallbackUrl",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "APP ID", prop: "appId" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.appId,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "appId", $$v)
                  },
                  expression: "ent.appId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "落地页链接", prop: "externalUrl" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.externalUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "externalUrl", $$v)
                  },
                  expression: "ent.externalUrl",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "监测参数", prop: "convertTrackParams" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.convertTrackParams,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "convertTrackParams", $$v)
                  },
                  expression: "ent.convertTrackParams",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "转化基础代码", prop: "convertBaseCode" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.convertBaseCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "convertBaseCode", $$v)
                  },
                  expression: "ent.convertBaseCode",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "转化代码（JS方式）", prop: "convertJsCode" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.convertJsCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "convertJsCode", $$v)
                  },
                  expression: "ent.convertJsCode",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: "转化代码（HTML方式）", prop: "convertHtmlCode" },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.convertHtmlCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "convertHtmlCode", $$v)
                  },
                  expression: "ent.convertHtmlCode",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "转化页面", prop: "convertXpathUrl" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.convertXpathUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "convertXpathUrl", $$v)
                  },
                  expression: "ent.convertXpathUrl",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "转化路径", prop: "convertXpathValue" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.convertXpathValue,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "convertXpathValue", $$v)
                  },
                  expression: "ent.convertXpathValue",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "直达链接", prop: "openUrl" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.openUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "openUrl", $$v)
                  },
                  expression: "ent.openUrl",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "创建时间", prop: "createTimeApi" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.createTimeApi,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "createTimeApi", $$v)
                  },
                  expression: "ent.createTimeApi",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "更新时间", prop: "modifyTimeApi" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.modifyTimeApi,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "modifyTimeApi", $$v)
                  },
                  expression: "ent.modifyTimeApi",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: "转化类型下匹配规则字段", prop: "ignoreParams" },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.ignoreParams,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "ignoreParams", $$v)
                  },
                  expression: "ent.ignoreParams",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "转化统计方式", prop: "convertDataType" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.convertDataType,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "convertDataType", $$v)
                  },
                  expression: "ent.convertDataType",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "逻辑删除", prop: "deleted" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.deleted,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "deleted", $$v)
                  },
                  expression: "ent.deleted",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "创建者", prop: "createBy" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.createBy,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "createBy", $$v)
                  },
                  expression: "ent.createBy",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "创建时间", prop: "createTime" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.createTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "createTime", $$v)
                  },
                  expression: "ent.createTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "更新者", prop: "updateBy" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.updateBy,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "updateBy", $$v)
                  },
                  expression: "ent.updateBy",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "更新时间", prop: "updateTime" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.updateTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "updateTime", $$v)
                  },
                  expression: "ent.updateTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "remark", $$v)
                  },
                  expression: "ent.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { attrs: { round: "" }, on: { click: _vm.close } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", round: "" },
              on: { click: _vm.submit },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }