<!--
 * @Description: 新建回传
 * @Date: 2021-10-21 09:12:51
 * @LastEditTime: 2021-10-21 10:41:32
-->
<template>
  <div class="rule">
    <div class="name">
      <p>{{ name }}</p>
    </div>
    <div class="body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.rule {
  margin-top: 20px;
  padding: 1px 20px 1px 0;
  background: #fafafa;
  .name {
    border-bottom: 1px #ebebeb solid;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 600;
    p {
      border-left: 2px solid #409eff;
      padding-left: 5px;
      margin: 0;
    }
  }
  .body {
    margin-top: 12px;
    padding-left: 10px;
  }
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}
</style>
