<!--
 * @Description: Do not edit
 * @Date: 2021-12-22 10:57:44
 * @LastEditTime: 2022-04-21 16:57:38
-->
<template>
  <div class="justify-start">
    <div class="area">
      <TreeSelect :value="selectData" placeholder="选择国家、省、市、区 " allow-clear style="width: 800px;" :tree-data="REGION_list" tree-checkable :show-checked-strategy="SHOW_ALL" :replace-fields="{ children: 'children', title: 'name', value: 'id', key: 'id' }" @change="change" />
      <!-- :max-tag-count="6" -->
      <!-- label-in-value -->
    </div>
    <!-- <div v-else class="area">
      <TreeSelect :value="selectData" placeholder="选择国家、省、市、区 " allow-clear style="width: 800px;" :tree-data="REGION_list" tree-checkable :show-checked-strategy="SHOW_ALL" :replace-fields="{ children: 'children', title: 'name', value: 'id', key: 'id' }" @change="change" />
    </div> -->
    <el-checkbox class="ml-20" @change="(e) => selectAll(e)">全选</el-checkbox>
  </div>
</template>

<script>
import { TreeSelect } from 'ant-design-vue'
const SHOW_ALL = TreeSelect.SHOW_ALL
const SHOW_PARENT = TreeSelect.SHOW_PARENT
import { getTargetingTags, getNewOne } from '@/network/api/assetManagement/assetManageTencent'

export default {
  components: {
    TreeSelect
  },
  props: {
    selectData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      REGION_list: [],
      SHOW_ALL,
      SHOW_PARENT,
      isSelectAll: false
    }
  },
  mounted() {
    getNewOne().then(({ code, data }) => {
      if (code === 200 && Object.hasOwnProperty.call(data, 'accountId')) {
        // 地域
        getTargetingTags({ accountId: data.accountId, type: 'REGION', tagSpec: null }).then((res) => {
          // 去除地区
          this.REGION_list = this.getValidCity(res.data.filter((f) => !['中国未知', '国外', '中国', '台湾省', '澳门特别行政区', '香港特别行政区'].includes(f.name)))
        })
      }
    })
  },
  methods: {
    getValidCity(list) {
      let data = list.map((item) => {
        item.disabled = !!(item.parentId !== '0' && item.cityLevel === 'CITY_LEVEL_NONE')
        if (item.children) {
          this.getValidCity(item.children)
        }
        return item
      })
      return data
    },
    change(value, label, extra) {
      this.$emit('changeArea', {
        area: value,
        areaContent: label
      })
    },
    // 全选
    selectAll(flag) {
      this.isSelectAll = flag
      if (flag) {
        this.$emit('changeArea', {
          area: this.REGION_list.map((item) => item.id),
          areaContent: this.REGION_list.map((item) => item.name)
        })
      } else {
        this.$emit('changeArea', {
          area: [],
          areaContent: []
        })
      }
    }
  }
}
</script>
<style lang="scss">
.area {
  .ant-select-selection {
    max-height: 300px;
    overflow-y: auto;
  }
}
.ant-select-dropdown {
  max-height: 400px !important;
}
</style>
