<!--
 * @Description: 百度投放账户
 * @Date: 2021-12-24 14:49:52
 * @LastEditTime: 2024-05-29 18:15:45
-->
<template>
  <el-form ref="searchForm" class="search-form search-form-4" :model="searchForm" :inline="true">
    <el-row :gutter="20">
      <el-col :span="6">
        <el-form-item prop="masterName" label="账户名称/ID">
          <el-input v-model="searchForm.masterName" placeholder="请输入账户名称" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="oauthId" label="账户管家">
          <el-select v-model="searchForm.oauthId" placeholder="请输入账户管家">
            <el-option v-for="item in houseKeeper()" :key="item.oauthId" :label="item.masterName" :value="item.oauthId" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="projectId" label="关联项目">
          <el-select v-model="searchForm.projectId" filterable placeholder="请输入关联项目" @change="changeProjec" @focus="focusProject">
            <el-option v-for="item in projects" :key="item.bsProjectId" :label="item.projectName" :value="item.bsProjectId" />
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-form-item prop="launchProductsIds" label="关联产品">
          <el-select v-model="searchForm.launchProductsIds" placeholder="请输入关联产品">
            <el-option label="不限" :value="0" />
            <el-option v-for="item in launchProducts" :key="item.bsProductId" :label="item.productName" :value="item.bsProductId" />
          </el-select>
          <!-- <el-input v-model="searchForm.launchProductsIds" placeholder="关联产品" /> -->
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="userName" label="投放人员">
          <el-input v-model="searchForm.userName" placeholder="请输入投放人员" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="putInStatus" label="投放状态">
          <el-select v-model="searchForm.putInStatus" placeholder="请输入投放状态">
            <el-option label="全部" value="null" />
            <el-option label="投放" value="1" />
            <el-option label="停投" value="0" />
          </el-select>
          <!-- <el-input v-model="searchForm.launchProductsIds" placeholder="关联产品" /> -->
        </el-form-item>
      </el-col>

      <el-col :span="6">

        <el-button class="btn" type="primary" @click="search">
          查询
        </el-button>
        <el-button class="btn" @click="resetSearch">
          重置
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { searchForm } from './object'
export default {
  inject: ['loadData', 'projectList', 'productList', 'houseKeeper'],
  data() {
    return {
      housekeepers: [],
      projects: [],
      productLists: {},
      launchProducts: [],
      searchForm: { ...searchForm }
    }
  },
  methods: {
    changeProjec(val) {
      this.launchProducts = this.productLists[val]
      this.searchForm.launchProductsIds = undefined
    },
    updateHousekeeperId(val) {
      this.focusProject()
      if (undefined !== val && val !== null) {
        this.searchForm.hkId = Number(val)
      } else {
        this.searchForm.hkId = undefined
      }
    },
    focusProject() {
      this.projects = this.projectList()
      this.productLists = this.productList()
    },
    search() {
      this.loadData(true)
    },
    // 折中方案
    getSearchForm() {
      return { ...this.searchForm }
    },
    resetSearch() {
      this.$refs.searchForm.resetFields()
      this.loadData(true)
      this.launchProducts = []
      console.log(1)
    }
  }
}
</script>
