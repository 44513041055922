<template>
  <div v-loading="loading" class="tempalte">
    <!-- 预览区 -->
    <div class="config-body" v-for="(item, index) in previews" :key="index">
      <div class="config-header">
        <span>{{ item.advertiserName }}（{{ item.advertiserId }}）</span>
        <div>
          <span>广告组数量：{{ item.groupSize }}</span>
          <span style="margin-left: 50px">广告计划数量：{{ item.planSize }}</span>
        </div>
      </div>
      <!-- 配置区内容 -->
      <div class="config-body-content">
        <!-- 配置区内容标题 -->
        <div class="config-body-content-title-box">
          <el-row :gutter="28" class="config-body-content-title">
            <el-col :span="3">
              <div class="group content-title">广告组</div>
            </el-col>
            <el-col :span="12">
              <div class="plan  content-title">广告计划</div>
            </el-col>
            <el-col :span="9">
              <div class="creative  content-title">广告创意</div>
            </el-col>
          </el-row>
        </div>

        <el-row :gutter="28" class="config-body-content-middle" v-for="(group, gindex) in item.groupList" :key="gindex">
          <!-- 广告组信息 -->
          <el-col :span="3">
            <div class="item">
              <div class="item-header">
                <div class="left">
                  <span>广告组信息</span>
                </div>
              </div>
              <div class="item-body">
                <div>名称: <span @click="handleClickEdit('groupName', index, gindex, 0, group)">{{ group.groupName }} <i
                      class="icon-edit el-icon-edit"></i></span></div>
                <div>推广目的: <span>{{ group.landingType === 'LINK' ? '销售线索收集' : '无' }}</span></div>
                <div>预算: <span>{{ group.budgetMode == 'BUDGET_MODE_INFINITE' ? '不限' :
                    group.budget
                }}</span></div>
              </div>
            </div>
          </el-col>
          <el-col :span="21">
            <el-table :data="group.planList" border size="small" class="preview-plan-table" style="width: 100%">
              <el-table-column prop="planName" label="广告计划名称" align="center">
                <template slot-scope="{row, $index}">
                  <span @click="handleClickEdit('planName', index, gindex, $index, row)">{{ row.planName }} <i
                      class="icon-edit el-icon-edit"></i></span>
                </template>
              </el-table-column>
              <el-table-column prop="date" label="转化目标" align="center">
                <template slot-scope="{row}">
                  <div class="table-item">营销链路：<span>{{ row.marketingPurpose === 'CONVERSION' ? '行动转化' : '无' }}</span>
                  </div>
                  <div class="table-item">转化追踪方式：<span>{{ row.convertSourceType === 1 ? '线索API' : '无' }}</span></div>
                  <div class="table-item">转化目标：<span>{{ row.convertType | filterConvertType }}</span></div>
                  <div class="table-item">转化追踪：<span>{{ row.convertName || row.assetName || '' }}</span></div>
                </template>
              </el-table-column>
              <el-table-column prop="date" label="预算与出价" align="center">
                <template slot-scope="{row}">
                  <div class="table-item">目标转化出价：<span>{{ row.cpaBid || 0 }}元</span></div>
                  <div class="table-item">预算：<span>{{ row.budget }}元</span></div>
                </template>
              </el-table-column>
              <el-table-column prop="audiencePackageName" label="用户定向包" align="center"></el-table-column>
              <el-table-column prop="inventoryCatalog" label="投放位置" align="center">
                <template slot-scope="{row}">
                  {{ row.inventoryCatalog | filterInventoryCatalog }}
                </template>
              </el-table-column>

              <el-table-column prop="date" label="创意分类标签" align="center">
                <template slot-scope="{row}">
                  <div class="table-item">创意分类：<span>{{ row.thirdIndustryNames || '无' }}</span></div>
                  <div class="table-item">创意标签：<span>{{ row.adKeywords || '无' }}</span></div>
                </template>
              </el-table-column>
              <el-table-column prop="" label="创意素材" align="center">
                <template slot-scope="{row}">
                  <div v-for="(m, mi) in JSON.parse(row.mediaUrls)" :key="mi">
                    <el-image :src="m" fit="cover" style="width:88px;height:50px"></el-image>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="titlePackageName" label="标题包" align="center"></el-table-column>
              <el-table-column prop="pageName" label="落地页" align="center"></el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="config-footer">
      <el-button type="primary" :disabled="!previews.length" @click="handleSubmitPlan">立即提交</el-button>
      <el-button type="primary" :disabled="!previews.length" @click="handleSavePlan" plain>任务暂存</el-button>
      <el-button :disabled="!previews.length" @click="handleCancelPlan">取 消</el-button>
    </div>

    <el-dialog class="table-template" :title="forms.title" :visible.sync="showEditDialog" :append-to-body="true"
      width="500px" top="20vh" @close="onCloseDialog">
      <div class="page-container">
        <el-input v-model="forms.name" placeholder="请输入名称" style="width: 300px;"></el-input>
      </div>
      <!--底部按钮-->
      <div class="dialog-footer">
        <el-button class="btn" @click="closeModal">取 消</el-button>
        <el-button class="btn" type="primary" :disabled="isLoading" @click="handleConfirmEdit">确
          定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { savePlans, submitPlans, deletePlans } from '@/network/api/api-huge-batch'
export default {
  name: 'PreviewAd',
  filters: {
    filterInventoryCatalog(value) {
      const types = { 'UNIVERSAL': '通投智选', 'MANUAL': '首选媒体' }
      return types[value] || '无'
    },
    filterConvertType(value) {
      const types = { 'AD_CONVERT_TYPE_PAY': '付费', 'AD_CONVERT_TYPE_CUSTOMER_EFFECTIVE': '有效获客' }
      return types[value] || '无'
    }
  },
  props: {
    info: {
      type: Array,
      default: () => []
    },
    baseId: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      loading: false, // !!this.$route.params.id,
      previews: [],
      showEditDialog: false,
      forms: {
        name: '', // 名称
        field: '', // 编辑的字段
        title: '', // 弹窗标题
        preivewIndex: 0,
        groupIndex: 0,
        planIndex: 0
      },
      isLoading: false
    }
  },
  computed: {},
  watch: {
    info: {
      handler(value) {
        this.previews = value
      },
      deep: true,
      immediate: true
    }
  },
  created() { },
  methods: {
    /* --------------------------- 数据接口加载 -------------------------- */
    /* --------------------------- 数据接口加载 -------------------------- */
    /* --------------------------- 按钮点击 -------------------------- */
    // 点击立即提交
    handleSubmitPlan() {
      this.loading = true
      submitPlans(this.previews).then(res => {
        if (res.code === 200) {
          this.$message.success('任务提交成功')
          this.$router.replace({
            name: 'hugeAmountAdBatch'
          })
        }
      }).finally(() => {
        this.loading = false
        this.$router.replace({
          name: 'hugeAmountAdBatch'
        })
      })
    },

    // 点击任务暂存
    handleSavePlan() {
      this.loading = true
      savePlans(this.previews).then(res => {
        if (res.code === 200) {
          this.$message.success('任务暂存成功')
          this.$router.replace({
            name: 'hugeAmountAdBatch'
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },

    // 点击取消
    handleCancelPlan() {
      this.$confirm('确定要取消该任务嘛?<div>取消后当前信息不会保存！</div>', '提示', {
        dangerouslyUseHTMLString: true,
        center: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { code } = await deletePlans({ baseId: this.baseId })
        if (code === 200) {
          this.$message.success('任务取消成功')
          this.$router.replace({
            name: 'hugeAmountAdBatch'
          })
        }
      }).catch(() => {
      })
    },

    // 编辑名称
    handleClickEdit(type, preivewIndex, gIndex, pIndex, item) {
      switch (type) {
        case 'groupName':
          this.forms = {
            name: item.groupName,
            field: type,
            title: '广告组名称编辑',
            preivewIndex: preivewIndex,
            groupIndex: gIndex,
            planIndex: pIndex
          }
          break
        case 'planName':
          this.forms = {
            name: item.planName,
            field: type,
            title: '广告计划名称编辑',
            preivewIndex: preivewIndex,
            groupIndex: gIndex,
            planIndex: pIndex
          }
          break
      }

      this.showEditDialog = true
    },

    // 点击确定修改
    handleConfirmEdit() {
      const { field, name, preivewIndex, groupIndex, planIndex } = this.forms
      if (!name) {
        this.$message.warning('请输入名称')
        return
      }
      let previewItem = this.previews[preivewIndex]
      let groupItem = previewItem['groupList'][groupIndex]
      switch (field) {
        case 'groupName': {
          groupItem[field] = name
          break
        }
        case 'planName': {
          let planItem = groupItem['planList'][planIndex]
          planItem[field] = name
          break
        }
      }
      this.closeModal()
    },

    // 编辑弹窗关闭回调
    onCloseDialog() { },
    // 点击关闭编辑弹窗
    closeModal() {
      this.showEditDialog = false
      this.forms = {
        name: '',
        field: '',
        title: '',
        preivewIndex: 0,
        groupIndex: 0,
        planIndex: 0
      }
    }
    /* --------------------------- 按钮点击 -------------------------- */
    /* --------------------------- 组件回调 -------------------------- */
    /* --------------------------- 组件回调 -------------------------- */
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  text-align: center;
  padding-bottom: 40px;
}

.tempalte {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  position: relative;

  .config {
    width: 100%;
    margin-top: 30px;

    .config-body {
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 20px;

      .config-header {
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        border: 1px solid #dcdfe6;
        background-color: #e9ecf4;
        padding: 12px;
        margin-bottom: -1px;
        font-size: 16px;
        color: #333;
        font-weight: 700;

        div {
          font-size: 14px;
          font-weight: 400;
          color: #878787;
        }
      }

      .config-body-header {
        background-color: #f9f9f9;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .config-body-content {
        width: 100%;
        box-sizing: border-box;
        border: 1px solid #dcdfe6;
        max-height: 400px;
        overflow-y: auto;

        .config-body-content-title-box {
          width: 100%;
          box-sizing: border-box;
          border-bottom: 1px solid #e6e9f1;
        }

        .config-body-content-title {
          display: flex;
          align-items: center;
          font-size: 14px;
          padding: 10px 0;
          width: 100%;
          box-sizing: border-box;

          .content-title {
            font-weight: 700;
            padding-left: 20px;
            width: 100%;
          }
        }

        .config-body-content-middle {
          width: 100%;
          margin: 0 !important;

          .el-col {
            padding: 0 !important;
            margin: 0 !important;
          }

          .item {
            position: relative;
            // border-right: 1px solid #dcdfe6;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;

            .item-header {
              padding-top: 10px;
              font-size: 14px;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .left {
                margin-top: 5px;
                margin-left: 10px;
                font-weight: 500;
              }

              .left-desc {
                font-size: 12px;
                color: #ccc;
                margin-left: 5px;
              }

              .right {
                position: relative;
                top: 2px;
                margin-right: 10px;
              }
            }

            .item-body {
              box-sizing: border-box;
              padding-top: 20px;
              padding-left: 10px;
              color: #666;
              font-size: 12px;
              width: 100%;
              flex: 1;
              margin-bottom: 45px;
              overflow-y: auto;

              div {
                margin-bottom: 10px;
              }

              span {
                color: #878787;
              }

              .item-title-item {
                width: 100%;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                justify-content: space-between;
                padding-right: 10px;
                height: 32px;
                margin-bottom: 4px;
                font-weight: 400;
                color: #666;
                background: #fafafa;
                border-radius: 4px;
                cursor: pointer;
              }

              .title-text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .item-material-body {
              box-sizing: border-box;
              padding-top: 20px;
              padding-left: 10px;
              color: #666;
              font-size: 14px;
              width: 100%;
              flex: 1;
              margin-bottom: 45px;
              overflow-y: auto;

              .material-group {
                .material-group-title {
                  font-size: 12px;
                  color: #666;
                  margin-bottom: 5px;
                }

                .material-group-list {
                  display: flex;
                  width: 100%;
                  margin-bottom: 10px;
                }

                .image {
                  position: relative;
                  display: block;
                  margin-right: 10px;

                  .el-image {
                    width: 88px;
                    height: 50px;
                  }

                  .el-icon-circle-close {
                    position: absolute;
                    top: 0;
                    right: 0;
                  }
                }

                .videoImage {
                  position: relative;
                  display: block;
                  margin-right: 10px;

                  .el-image {
                    width: 88px;
                    height: 50px;
                  }

                  .el-icon-circle-close {
                    position: absolute;
                    top: 0;
                    right: 0;
                  }
                }
              }
            }

            .item-bottom {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 13px 0;
              border-top: none;
              color: #409eff;
              font-size: 12px;
              cursor: pointer;
              border: none;
              border-radius: 0;
              border-top: solid 1px #dcdfe6;
              box-sizing: border-box;
              position: absolute;
              bottom: 0;

              &.disabled {
                opacity: 0.5;
              }
            }
          }
        }
      }

      .icon-edit {
        color: #409eff;
        font-size: 14px;
        cursor: pointer;
      }
    }

    .config-body-content-bottom {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }
  }

  .config-footer {
    padding: 50px 0 150px;
    text-align: center;
  }

  .preview-plan-table {
    margin-top: -1px;
    margin-right: -1px;
  }

  .table-item {
    text-align: left;
    color: #666;
    font-size: 12px;
    width: 100%;

    span {
      color: #878787;
    }
  }

  .btn {
    float: right;
    margin-bottom: 10px;
  }
}
</style>

