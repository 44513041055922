/*
 * @Description: 程序化批量Api
 * @Date: 2021-10-05 13:41:50
 * @LastEditTime: 2021-10-15 15:04:11
 */
import service from '@/network/service/service'

export function Page(data) {
  return service.post('/api/system/advManagementBaiduPublish/page', data)
}

export function Add(data) {
  return service.post('/api/system/advManagementBaiduPublish/save', data)
}

export function Update(data) {
  return service.post('/api/system/advManagementBaiduPublish/update', data)
}

export function Delete(id) {
  return service.delete('/api/system/advManagementBaiduPublish/' + id)
}

export function getWoodenFishList(params) {
  return service.get('api/system/assetManagementBaiduWoodenFish/getLists', { params })
}

export function getTrackList(params) {
  return service.get('api/system/assetManagementBaiduTrack/getLists', { params })
}
