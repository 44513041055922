<template>
  <div>
    <Search ref="search" />
    <el-row type="flex" justify="space-between" class="operate-box">
      <el-button type="primary" icon="el-icon-plus" round class="mt-20" @click="showAdd">新增推广单元</el-button>
    </el-row>

    <div class="table-container">
      <el-table v-loading="loading" stripe class="list-table" :data="data">
        <c-data-empty slot="empty" />
        <el-table-column type="index" label="序号" width="80" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="planName" label="计划名称" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="adgroupFeedName" label="单元名称" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="audience" label="优化目标" align="left" width="150" :show-overflow-tooltip="true" :formatter="formatteraudience1" />
        <el-table-column prop="audience" label="付费模式" align="left" width="150" :show-overflow-tooltip="true" :formatter="formatteraudience" />
        <!-- <el-table-column prop="ocpc" label="目标转化" align="left" :show-overflow-tooltip="true" :formatter="formatteraudience2" /> -->
        <el-table-column prop="ocpc" label="转化出价(元)" align="left" width="150" :show-overflow-tooltip="true" :formatter="formatteraudience3" />
        <el-table-column prop="createBy" label="创建者" align="left" width="150" />
        <el-table-column prop="createTime" label="创建时间" align="left" width="170" />
        <el-table-column label="操作" width="150">
          <template slot-scope="{ row }">
            <el-button type="text" size="small" @click="showEdit(row)">
              <el-tooltip content="编辑" placement="top">
                <i class="el-icon-edit" style="font-size: 16px"></i>
              </el-tooltip>
            </el-button>
            <el-button type="text" size="small" @click="deleteRow(row)">
              <el-tooltip content="删除" placement="top">
                <i class="el-icon-delete" style="font-size: 16px; color: red"></i>
              </el-tooltip>
            </el-button>
            <el-button type="text" size="small" @click="copyRow(row)">
              <el-tooltip content="复制" placement="top">
                <i class="el-icon-copy-document" style="font-size: 16px"></i>
              </el-tooltip>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <c-pagination :page-size.sync="pageInfo.pageSize" :page-no.sync="pageInfo.pageNum" :total="pageInfo.total" :get-data-fun="loadData" />
    <OptionDialog ref="dialog" />
  </div>
</template>

<script>
import { PageCommonComponent } from '@/common/mixin'
import { Page, Delete, copyPlan } from '@/network/api/advManagement/advManagementBaiduUnit'
import Search from './Search'
import OptionDialog from './OptionDialog'
import { transType } from './transType'
import { AdvManagementBaiduUnitEnt } from './object'
export default {
  name: 'AdvManagementBaiduUnit',
  components: { Search, OptionDialog },
  mixins: [PageCommonComponent],
  provide: function() {
    return {
      loadData: this.loadData,
      showEdit: this.showEdit
    }
  },
  data() {
    return {
      transTypes: transType,
      loading: false,
      data: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    copyRow(row) {
      this.$confirm('确定复制, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          copyPlan('id=' + row.baiduUnitId).then((res) => {
            if (res.code === 200) {
              this.$message({
                message: '复制成功',
                type: 'success'
              })
              this.loadData()
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消复制'
          })
        })
    },
    formatteraudience3(row, column, cellValue, index) {
      var json = JSON.parse(cellValue)
      return json.ocpcBid
    },
    formatteraudience2(row, column, cellValue, index) {
      var json = JSON.parse(cellValue)
      var ret = ''
      this.transTypes.forEach((item) => {
        if (item.id === json.transType) {
          ret = item.label
        }
      })
      return ret
    },
    formatteraudience1(row, column, cellValue, index) {
      return '转化'
    },
    formatteraudience(row, column, cellValue, index) {
      return 'Ocpm'
    },
    loadData(isFirst = false) {
      const searchForm = this.$refs.search.getSearchForm()
      var time = searchForm.selectDate
      if (time !== null && time !== undefined) {
        searchForm.selectDate = time.toString()
      }
      this.doLoadData(isFirst, searchForm)
    },
    doLoadData(firstPage = false, searchForm) {
      firstPage && (this.pageInfo.pageNum = 1)
      const req = { ...searchForm, ...this.pageInfo }
      this.loading = true
      Page(req).then((res) => {
        this.data = res.data.records
        this.pageInfo.total = res.data.total
        this.loading = false
      })
    },
    deleteRow(row) {
      this.$confirm('确定删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          Delete(row.baiduUnitId).then((res) => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.loadData()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showEdit(row) {
      this.$refs.dialog.show(true, { ...row })
    },
    showAdd() {
      this.$refs.dialog.show(false, new AdvManagementBaiduUnitEnt())
    }
  }
}
</script>

<style scoped></style>
