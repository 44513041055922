var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      style: {
        border: `1px solid ${_vm.detail.borderColor}`,
        background: _vm.detail.bgColor,
      },
    },
    [
      _c("img", {
        attrs: { src: _vm.imgInfo.pureImageUrl || _vm.defaultImg, alt: "" },
      }),
      _c("div", [
        _c("div", { staticClass: "title" }, [
          _c("h3", { style: { color: _vm.titleInfo.fontColor } }, [
            _vm._v(_vm._s(_vm.titleInfo.content)),
          ]),
          _c("p", { style: { color: _vm.descInfo.fontColor } }, [
            _vm._v(
              _vm._s(
                _vm.descInfo.descType === "text"
                  ? _vm.descInfo.content
                  : _vm.descInfo.content
              )
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "button" },
          [
            _c(
              "el-button",
              {
                style: {
                  background: _vm.buttonInfo.btnBgColorTheme,
                  color: _vm.buttonInfo.fontColor,
                  border: `1px solid ${_vm.buttonInfo.btnBorderColorTheme}`,
                  fontWeight:
                    _vm.buttonInfo.btnFontType === "0" ? "normal" : "bold",
                },
              },
              [_vm._v(" " + _vm._s(_vm.buttonInfo.btnTitle) + " ")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }