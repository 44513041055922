var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form-item",
        { attrs: { label: "创意样式：", prop: "crtSize" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.ruleFormData.crtSize,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleFormData, "crtSize", $$v)
                },
                expression: "ruleFormData.crtSize",
              },
            },
            [_c("el-radio", { attrs: { label: 998 } }, [_vm._v("图片式卡片")])],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "信息栏类型：", prop: "headDescType" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.ruleFormData.headDescType,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleFormData, "headDescType", $$v)
                },
                expression: "ruleFormData.headDescType",
              },
            },
            [_c("el-radio", { attrs: { label: 2 } }, [_vm._v("文案型")])],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "标签展示：", prop: "mpTagType" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.ruleFormData.mpTagType,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleFormData, "mpTagType", $$v)
                },
                expression: "ruleFormData.mpTagType",
              },
            },
            [_c("el-radio", { attrs: { label: 0 } }, [_vm._v("不启用")])],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "点击跳转：", prop: "pageType" } },
        [
          _c(
            "el-radio-group",
            {
              on: {
                change: (e) => {
                  this.$emit("pageTypeChange", e)
                },
              },
              model: {
                value: _vm.ruleFormData.pageType,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleFormData, "pageType", $$v)
                },
                expression: "ruleFormData.pageType",
              },
            },
            [
              _c("el-radio", { attrs: { label: 4 } }, [_vm._v("原生推广页")]),
              _c("el-radio", { attrs: { label: 6 } }, [_vm._v("公众号详情页")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.ruleFormData.pageType === 4
        ? _c(
            "el-form-item",
            { attrs: { label: "点击按钮跳转：", prop: "buttonPageType" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleFormData.buttonPageType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleFormData, "buttonPageType", $$v)
                    },
                    expression: "ruleFormData.buttonPageType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 4 } }, [
                    _vm._v("原生推广页"),
                  ]),
                  _c("el-radio", { attrs: { label: 40024 } }, [
                    _vm._v("一键关注"),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        { attrs: { label: "按钮文案：", prop: "buttonParam" } },
        [
          _vm.promotedObjectType === "PRODUCTTYPE_WECHAT"
            ? _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleFormData.buttonParam,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleFormData, "buttonParam", $$v)
                    },
                    expression: "ruleFormData.buttonParam",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "关注公众号" } }, [
                    _vm._v("关注公众号"),
                  ]),
                  _c("el-radio", { attrs: { label: "阅读小说" } }, [
                    _vm._v("阅读小说"),
                  ]),
                ],
                1
              )
            : _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleFormData.buttonParam,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleFormData, "buttonParam", $$v)
                    },
                    expression: "ruleFormData.buttonParam",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "查看详情" } }, [
                    _vm._v("查看详情"),
                  ]),
                  _c("el-radio", { attrs: { label: "立即咨询" } }, [
                    _vm._v("立即咨询"),
                  ]),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: {
            label: "创意名称：",
            prop: "tname",
            rules: { required: true, maxlength: 60, message: "请输入创意名称" },
          },
        },
        [
          _c("el-input", {
            staticStyle: { width: "400px" },
            attrs: {
              rows: "4",
              type: "textarea",
              placeholder: "请输入创意名称",
              maxlength: "60",
              "show-word-limit": "",
            },
            model: {
              value: _vm.ruleFormData.tname,
              callback: function ($$v) {
                _vm.$set(_vm.ruleFormData, "tname", $$v)
              },
              expression: "ruleFormData.tname",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }