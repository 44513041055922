
<template>
  <!--显示列弹框-->
  <el-dialog class="table-template" :title="'添加标题'" :visible.sync="show" :append-to-body="true" width="1200px"
    top="20vh" @close="onCloseDialog">
    <div class="copy-type">
      <div v-for="(item, index) in copyType" :key="index"
        :class="['copy-type-item', item.id === copyIndex ? 'isSelect' : '']" @click="handleSelectType(item.id)">{{
            item.value
        }}</div>
    </div>
    <div class="search-form-container title-package-search">
      <el-form class="search-form">
        <el-row :gutter="15">
          <el-col :span="4">
            <el-form-item label="项目">
              <el-input v-model="searchForm.projectName" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="产品">
              <el-input v-model="searchForm.productName" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="copyIndex === 2" :span="4">
            <el-form-item label="创建人">
              <el-input v-model="searchForm.createBy" placeholder="请输入创建人"></el-input>
            </el-form-item>
          </el-col>
          <el-col v-if="copyIndex === 1" :span="4">
            <el-form-item label="关键字">
              <el-input v-model="searchForm.text" placeholder="请输入关键字"></el-input>
            </el-form-item>
          </el-col>
          <!--按钮-->
          <el-col :span="8">
            <!--搜索-->
            <el-button type="primary" style="width: 70px;" @click="onClickSearch">搜索</el-button>
            <!--清空-->
            <el-button type="plain" style="width: 70px;" @click="onClickReset">清空</el-button>
            <el-button v-if="copyIndex === 2" type="primary" @click="onClickAddTxtGroup(0)">新增标题包</el-button>
            <el-button v-if="copyIndex === 1" type="primary" @click="onClickAddTxt(0)">新增标题</el-button>
            <el-button type="text" icon="el-icon-refresh" @click="handleRefresh">刷新</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="page-container">
      <!--表格-->
      <el-table v-show="copyIndex === 2" ref="multipleTable" :data="txtGroupList" :v-loading="loading"
        :row-key="row => { return row.textGroupId }" @selection-change="onTableTextGroupSelectionChange">
        <!--空数据提示-->
        <c-data-empty slot="empty"></c-data-empty>

        <el-table-column type="selection" :selectable="checkSelectable" :reserve-selection="true"></el-table-column>
        <el-table-column :label="'文案组名称'" show-overflow-tooltip :min-width="'150px'" align="center">
          <span slot-scope="{row}">{{ row.textGroupName }}</span>
        </el-table-column>
        <el-table-column :label="'关联项目'" show-overflow-tooltip align="center">
          <span slot-scope="{row}">{{ row.projectName }}</span>
        </el-table-column>
        <el-table-column :label="'关联产品'" show-overflow-tooltip align="center">
          <span slot-scope="{row}">{{ row.productName }}</span>
        </el-table-column>
        <el-table-column :label="'关联计划数'" show-overflow-tooltip align="center">
          <span slot-scope="{row}">{{ row.planNum || 0 }}</span>
        </el-table-column>
        <el-table-column :label="'标题数'" show-overflow-tooltip align="center">
          <span slot-scope="{row}">{{ row.textNum }}</span>
        </el-table-column>
        <el-table-column :label="'创建人'" show-overflow-tooltip :min-width="'150px'" align="center">
          <span slot-scope="{row}">{{ row.nickname }}</span>
        </el-table-column>
        <el-table-column :label="'创建时间'" show-overflow-tooltip :min-width="'180px'" align="center">
          <span slot-scope="{row}">{{ row.createdAt }}</span>
        </el-table-column>
      </el-table>

      <el-table v-show="copyIndex === 1"  ref="textTable" :data="txtList" :v-loading="loading"
        :row-key="row => { return row.textId }" @selection-change="onTableTextSelectionChange">
        <!--空数据提示-->
        <c-data-empty slot="empty"></c-data-empty>

        <el-table-column type="selection" :reserve-selection="true"></el-table-column>
        <el-table-column :label="'文案'" show-overflow-tooltip :min-width="'150px'" align="center">
          <span slot-scope="{row}">{{ row.text }}</span>
        </el-table-column>
        <el-table-column :label="'关联项目'" show-overflow-tooltip align="center">
          <span slot-scope="{row}">{{ row.projectName }}</span>
        </el-table-column>
        <el-table-column :label="'关联产品'" show-overflow-tooltip align="center">
          <span slot-scope="{row}">{{ row.productName }}</span>
        </el-table-column>
        <el-table-column :label="'关联计划数'" show-overflow-tooltip align="center">
          <span slot-scope="{row}">{{ row.planNum || 0 }}</span>
        </el-table-column>
        <el-table-column :label="'创建人'" show-overflow-tooltip :min-width="'150px'" align="center">
          <span slot-scope="{row}">{{ row.nickname }}</span>
        </el-table-column>
        <el-table-column :label="'创建时间'" show-overflow-tooltip :min-width="'250px'" align="center">
          <span slot-scope="{row}">{{ row.createdAt }}</span>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <c-pagination :page-size.sync="pageSize" :page-no.sync="pageNum" :total="total" :get-data-fun="getPageData" />
      </div>
    </div>
    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">取 消</el-button>
      <el-button class="btn" type="primary" :loading="isLoading"
        :disabled="selectedData.length === 0 && selectedGroupData.length === 0" @click="clickConfirm('form')">确 定
      </el-button>
    </div>

    <!-- 新增标题 -->
    <edit-copy-dialog ref="editCopyDialog" @edit="_getTxtList"></edit-copy-dialog>
    <!-- 新增标题包 -->
    <add-copy-group-dialog ref="addCopyGroupDialog" @edit="_getTxtGrouplList"></add-copy-group-dialog>
  </el-dialog>
</template>

<script>
import {
  getTxtList,
  getTxtGrouplList
} from '@/network/api/api-material'
import { saveConfigTitle } from '@/network/api/api-huge-batch'
import { PageCommonComponent } from '@/common/mixin'
import EditCopyDialog from '@/components/materialManagement/copyManagement/EditCopyDialog'
import AddCopyGroupDialog from '@/components/materialManagement/copyManagement/AddCopyGroupDialog'
export default {
  name: 'TextSelectDialog',
  components: {
    'edit-copy-dialog': EditCopyDialog,
    'add-copy-group-dialog': AddCopyGroupDialog
  },
  filters: {},
  mixins: [PageCommonComponent],
  props: {
    baseId: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      show: false,
      loading: false,
      copyType: [
        { id: 2, value: '文案组', name: 'textGroup' },
        { id: 1, value: '文案库', name: 'text' }
      ],
      copyIndex: 2, // 区分当前是文案还是文案组 1-文案 2-文案组
      searchForm: {
        projectId: '',
        projectName: '',
        productId: '',
        productName: '',
        text: '',
        createBy: '' // 创建人
      }, // 搜索条件
      titleData: [], // 已保存的标题包
      selectedData: [], // 选中的文案数据
      selectedGroupData: [], // 选中的文案组数据
      titleTotal: 0, // 标题包选择上限
      txtList: [], // 文案列表
      txtGroupList: [], // 文案组列表
      pageNum: 1,
      pageSize: 10,
      total: 0
    }
  },
  computed: {
  },
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {
    /* --------------------------- Actions --------------------------- */
    showModal(params, data, total) {
      this.searchForm = {
        ...this.searchForm,
        projectId: params.projectId,
        projectName: params.projectName,
        productId: params.productId,
        productName: params.productName
      }
      this.titleData = data
      this.titleTotal = total
      this.show = true
      if (this.copyIndex === 2) {
        this._getTxtGrouplList()
      } else {
        this._getTxtList()
      }
    },

    // 选择文案/文案组
    handleSelectType(id) {
      this.copyIndex = id
      this.onClickSearch()
    },

    // 复选框是否可选
    checkSelectable(row, index) {
      let flag = true
      for (let i = 0; i < this.titleData.length; i++) {
        if (row.textGroupId === this.titleData[i].titlePackageId) {
          flag = false
          break
        } else {
          flag = true
        }
      }
      return flag
    },

    // 选择文案
    onTableTextSelectionChange(selection) {
      this.selectedData = selection
    },
    // 选择文案组
    onTableTextGroupSelectionChange(selection) {
      this.selectedGroupData = selection
    },
    // 点击搜索
    onClickSearch() {
      if (this.pageNum === 1) {
        if (this.copyIndex === 1) {
          this._getTxtList()
        } else {
          this._getTxtGrouplList()
        }
      } else {
        this.pageNum = 1
      }
    },
    // 点击清空
    onClickReset() {
      this.searchForm.text = ''
      this.searchForm.createBy = ''
      this.pageNum = 1

      this.onClickSearch()
    },
    // 获取页码跳转数据
    getPageData() {
      if (this.copyIndex === 1) {
        this._getTxtList()
      } else {
        this._getTxtGrouplList()
      }
    },

    // 点击确定按钮
    clickConfirm() {
      let list = []
      if (this.selectedData.length) {
        let titles = []
        let textIds = []
        this.selectedData.map(v => {
          titles.push(v.text)
          textIds.push(v.textId)
        })
        list.push({
          baseId: this.baseId,
          titlePackageName: '临时文案组',
          titles: titles.join(','),
          textIds: textIds.join(',')
        })
      }
      if (this.selectedGroupData.length) {
        this.selectedGroupData.map(v => {
          list.push({
            baseId: this.baseId,
            titlePackageId: v.textGroupId,
            titlePackageName: v.textGroupName
          })
        })
      }
      if (!list.length) {
        this.$message.warning('请至少选择一个标题文案')
        return
      }
      if (list.length + this.titleData.length > this.titleTotal) {
        this.$message.warning('最多添加50个标题包')
        return
      }
      this.isLoading = true
      saveConfigTitle(list).then(res => {
        if (res.code === 200) {
          this.$emit('confirm', res.data)
          this.closeModal()
        }
      }).finally(() => {
        this.isLoading = false
      })
    },

    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      // 重置表单
      this.copyIndex = 2 // 区分当前是文案还是文案组 1-文案 2-文案组
      this.searchForm = {
        projectId: '',
        projectName: '',
        productId: '',
        productName: '',
        text: '',
        createBy: '' // 创建人
      } // 搜索条件
      this.selectedData = [] // 选中的文案数据
      this.selectedGroupData = [] // 选中的文案组数据
      this.txtList = [] // 文案列表
      this.txtGroupList = [] // 文案组列表
      this.pageNum = 1
      this.pageSize = 10
      this.total = 0
      this.$refs.textTable.clearSelection()
      this.$refs.multipleTable.clearSelection()
    },
    // 关闭弹框
    closeModal() {
      this.show = false
    },
    // 点击添加文本
    onClickAddTxt(id) {
      const { projectId, productId } = this.searchForm
      this.$refs.editCopyDialog.showModal(id, {
        projectId,
        productId
      })
    },
    // 点击添加文本组
    onClickAddTxtGroup(id) {
      const { projectId, productId } = this.searchForm
      this.$refs.addCopyGroupDialog.showModal({
        projectId,
        productId
      })
    },

    // 点击刷新按钮
    handleRefresh() {
      this.getPageData()
    },
    /* --------------------------- Private --------------------------- */
    // 获取文本列表
    _getTxtList() {
      this.isLoading = true
      let params = {
        projectId: this.searchForm.projectId
      }
      if (this.searchForm.text) {
        params.text = this.searchForm.text
      }
      getTxtList(this.pageNum, this.pageSize, params).then(async res => {
        this.txtList = res.data.list
        this.total = res.data.total
      }).catch(err => {
        this.$ErrorMessage(err.data || '获取文本列表失败')
      }).finally(() => {
        this.isLoading = false
      })
    },
    // 获取文本组列表
    _getTxtGrouplList() {
      this.isLoading = true
      let params = {
        projectId: this.searchForm.projectId
      }
      if (this.searchForm.textGroupName) {
        params.textGroupName = this.searchForm.textGroupName
      }
      getTxtGrouplList(this.pageNum, this.pageSize, params).then(res => {
        this.txtGroupList = res.data.list
        this.total = res.data.total
      }).catch(err => {
        this.$ErrorMessage(err.data || '获取文本组列表失败')
      }).finally(() => {
        this.isLoading = false
      })
    }
    // // 表格数据回显
    // echo(data) {
    //   let rows = []
    //   data.forEach(item => {
    //     this.selectedGroupData.forEach(item2 => {
    //       if (item.id === item2.id) {
    //         rows.push(item)
    //       }
    //     })
    //   })
    //   this.toggleSelection(rows)
    // },
    // // 勾选表格选中
    // toggleSelection(rows) {
    //   if (rows.length) {
    //     rows.forEach(row => {
    //       this.$refs.multipleTable.toggleRowSelection(row, true)
    //     })
    //   } else {
    //     this.$refs.multipleTable.clearSelection()
    //   }
    // }
  }
}
</script>

<style lang="scss">
.title-package-search {
  .el-input.is-disabled .el-input__inner {
    background-color: #ffffff;
    color: #606266;
  }
}
</style>
<style scoped lang="scss">
@import "~@/assets/style/variables";
@import "~@/assets/style/mixin";

.table-template {
  .copy-type {
    margin-bottom: 20px;
    width: 150px;
    box-sizing: border-box;
    display: flex;
    color: #909399;
    font-size: 16px;
    font-weight: 700;
    // border-bottom: 1px solid #909399;

    .copy-type-item {
      margin-right: 15px;
      padding: 5px;
      cursor: pointer;
    }

    .isSelect {
      color: #409EFF;
      border-bottom: 2px solid #409EFF;
    }
  }

  .search-form-container {
    .el-form {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .flex-btn {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .left {
      margin-right: 10px;
    }
  }

  .page-container {
    margin-bottom: 50px;

    .btn-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;

      .operation-btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .table-info {
          padding: 10px 0;
          color: #333A3F;
          font-size: 16px;
          margin-left: 12px;

          .number {
            color: #2842C8;
            font-size: 24px;
            margin: 0 5px;
          }
        }

        .el-button--small {
          padding: 6px 13px;
        }

        .btn-content {
          @include flex-center;
          height: 18px;

          .icon-container {
            @include flex-center;
            width: 18px;
            height: 18px;
            border-radius: 9px;
            // background: #1667FF;
            margin-right: 6px;

            img {
              width: 12px;
              height: 12px;
            }
          }
        }
      }

      .function-btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .function-btn {
          @include flex-center;
          width: 26px;
          height: 20px;
          background: white;
          transition: 0.2s;
          margin-left: 10px;
          position: relative;
          cursor: pointer;

          .table-columns-container {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 10;
            width: 150px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 2px 5px 0px rgba(229, 228, 234, 1);
            border-radius: 4px;
            padding-left: 10px;
            overflow: hidden;
            display: none;

            .table-columns-item {
              height: 30px;
              line-height: 30px;
            }
          }

          &:hover {
            background: #F2F2F2;

            .table-columns-container {
              display: block;
            }
          }

          img {
            width: 18px;
            height: 18px;
          }
        }
      }
    }

    .table-select-tip {
      height: 38px;
      padding-left: 17px;
      display: flex;
      align-items: center;
      color: #909399;
      font-size: 14px;
      background: #DBE9FF;
      border: 1px solid #4788FF;
      border-radius: 4px;

      img {
        width: 16px;
        height: 16px;
      }
    }

    .table-link {
      color: #606266;
      transition: 0.2s;
      text-decoration: none;

      &:hover {
        color: $themeColor;
        text-decoration: underline;
      }
    }

    .poster-container {
      @include flex-center;
      width: 36px;
      height: 36px;
      overflow: hidden;

      .poster {
        width: 36px;
        min-height: 36px;
      }
    }

    .opt-container {
      display: flex;
      justify-content: space-around;

      .opt-text {
        font-size: 14px;
      }
    }

    .cell-btn {
      font-size: 0;

      img {
        width: 18px;
        height: 18px;
      }

      .cell-btn-hover {
        display: none;
      }

      &:hover {
        .cell-btn-icon {
          display: none;
        }

        .cell-btn-hover {
          display: block;
        }
      }
    }

    .pagination-container {
      height: 64px;
      padding-right: 50px;
      box-sizing: border-box;
    }
  }
}

::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}
</style>

