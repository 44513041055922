import service from '@/network/service/service'

export function Page(data) {
  return service.post('/api/system/assetManagementHugeCreateOrangeWebsite/page', data)
}

export function Add(data) {
  return service.post('/api/system/assetManagementHugeCreateOrangeWebsite/save', data)
}

export function Update(data) {
  return service.post('/api/system/assetManagementHugeCreateOrangeWebsite/update', data)
}

export function Delete(id) {
  return service.delete('/api/system/assetManagementHugeCreateOrangeWebsite/' + id)
}

export function Sync({ accountId, GGZaccountId }) {
  return service.get(`/api/system/assetManagementHugeCreateOrangeWebsite/syncOrangeWebSite/${accountId}/${GGZaccountId}`)
}
