var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "justify-sb" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-plus", round: "" },
              on: { click: () => (_vm.sync_visible = true) },
            },
            [_vm._v("新建任务")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", round: "" },
              on: {
                click: function ($event) {
                  return _vm.getPage()
                },
              },
            },
            [_vm._v("刷新")]
          ),
        ],
        1
      ),
      _c("TablePane", {
        staticClass: "mt-20",
        attrs: { "data-source": _vm.dataSource },
        on: { getPage: _vm.getPage },
        scopedSlots: _vm._u([
          {
            key: "status",
            fn: function ({ scope }) {
              return [
                _c("p", [
                  _vm._v(
                    _vm._s(
                      `${
                        scope.row.status === "1"
                          ? "待复制"
                          : scope.row.status === "2"
                          ? "复制中"
                          : "复制完成"
                      }`
                    )
                  ),
                ]),
                scope.row.status === "3"
                  ? _c("div", [
                      _c("p", [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "green",
                              "margin-right": "10px",
                            },
                          },
                          [_vm._v(_vm._s(`成功：${scope.row.copyNumSuccess}`))]
                        ),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(`失败：${scope.row.copyNumFail}`)),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "广告复制配置",
            visible: _vm.sync_visible,
            width: "1020px",
            "modal-append-to-body": false,
            "destroy-on-close": "",
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            top: "100px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.sync_visible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm1",
              staticClass: "demo-ruleForm",
              attrs: { "label-width": "100px" },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "投放账户",
                    prop: "accountIds",
                    rules: {
                      required: true,
                      message: "请选择投放账户",
                      trigger: "blur",
                    },
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      ref: "accountSelect",
                      staticStyle: { width: "400px" },
                      attrs: {
                        "filter-method": _vm.handleFilter,
                        filterable: "",
                        clearable: "",
                        multiple: "",
                        placeholder: "请选择投放账户",
                        "multiple-limit": 5,
                      },
                      on: { change: _vm.changeAccount },
                      model: {
                        value: _vm.accountIds,
                        callback: function ($$v) {
                          _vm.accountIds = $$v
                        },
                        expression: "accountIds",
                      },
                    },
                    _vm._l(_vm.adMpAccountList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          value: item.id,
                          label:
                            item.corporationName + "(" + item.accountId + ")",
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.accountIdList && _vm.accountIdList.length
            ? _c(
                "div",
                _vm._l(_vm.accountIdList, function (item, index) {
                  return _c(
                    "el-row",
                    {
                      key: index,
                      staticClass: "bg-purple-dark mt-20 medisplay",
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "ruleForm",
                          refInFor: true,
                          staticClass: "demo-ruleForm",
                          attrs: {
                            model: _vm.ruleForm[item],
                            "label-width": "100px",
                          },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 23 } },
                            [
                              _c("el-card", { staticClass: "box-card" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "clearfix",
                                    attrs: { slot: "header" },
                                    slot: "header",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.setAccount(item))),
                                    ]),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: {
                                          float: "right",
                                          padding: "3px 0",
                                        },
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteCard(item)
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-row",
                                      { attrs: { gutter: 12 } },
                                      [
                                        _c(
                                          "el-col",
                                          { attrs: { span: 12 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "推广计划",
                                                  prop: "campaignId",
                                                  rules: {
                                                    required: true,
                                                    message: "请选择推广计划",
                                                    trigger: "blur",
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "search-select-tencent-campaigns",
                                                  {
                                                    staticStyle: {
                                                      width: "200px",
                                                    },
                                                    attrs: {
                                                      "account-id": item,
                                                      props: {
                                                        label: "campaignName",
                                                        value: "campaignId",
                                                      },
                                                      placeholder:
                                                        "请选择推广计划",
                                                    },
                                                    on: {
                                                      change: (val) =>
                                                        _vm.changeCampaigns(
                                                          val,
                                                          item
                                                        ),
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm[item]
                                                          .campaignId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm[item],
                                                          "campaignId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm[item].campaignId",
                                                    },
                                                  }
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 12 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "广告",
                                                  prop: "adGroup",
                                                  rules: {
                                                    required: true,
                                                    message: "请选择广告",
                                                    trigger: "blur",
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "search-select-tencent-adgroup-ids",
                                                  {
                                                    staticStyle: {
                                                      width: "200px",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        !_vm.ruleForm[item]
                                                          .campaignId,
                                                      "account-id": item,
                                                      "campaign-id":
                                                        _vm.ruleForm[item]
                                                          .campaignId,
                                                      props: {
                                                        label: "adName",
                                                        value: "adId",
                                                      },
                                                      placeholder: "请选择广告",
                                                    },
                                                    on: {
                                                      change: (val) =>
                                                        _vm.changeAds(
                                                          val,
                                                          item
                                                        ),
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.ruleForm[item]
                                                          .adGroup,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.ruleForm[item],
                                                          "adGroup",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ruleForm[item].adGroup",
                                                    },
                                                  }
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-col",
                                          { attrs: { span: 12 } },
                                          [
                                            _c(
                                              "el-form-item",
                                              {
                                                attrs: {
                                                  label: "复制广告数",
                                                  prop: "copyAdNum",
                                                  rules: {
                                                    required: true,
                                                    message: "请选择广告",
                                                    trigger: "blur",
                                                  },
                                                },
                                              },
                                              [
                                                _c("el-input-number", {
                                                  attrs: { min: 1, max: 20 },
                                                  model: {
                                                    value:
                                                      _vm.ruleForm[item]
                                                        .copyAdNum,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.ruleForm[item],
                                                        "copyAdNum",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ruleForm[item].copyAdNum",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm()
                    },
                  },
                },
                [_vm._v("立即复制")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.resetForm("ruleForm")
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "复制详情",
            visible: _vm.generate_task,
            "modal-append-to-body": false,
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.generate_task = $event
            },
            close: () => {
              _vm.generate.taskId = 0
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticClass: "list-table",
              attrs: { data: _vm.generate_task_list },
            },
            [
              _c("el-table-column", {
                attrs: {
                  property: "campaignId",
                  label: "计划ID",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  property: "copyAdId",
                  label: "广告ID",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  property: "adgroupId",
                  label: "广告组ID",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  property: "message",
                  label: "详细信息",
                  align: "center",
                },
              }),
            ],
            1
          ),
          _c("el-pagination", {
            staticStyle: { "text-align": "right", "margin-top": "10px" },
            attrs: {
              background: "",
              layout: "prev, pager, next",
              total: _vm.generate_task_total,
            },
            on: { "current-change": _vm.getRelationDetail },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }