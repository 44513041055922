var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    _vm._l(_vm.group, function (item) {
      return _c("div", { key: item.title, staticClass: "widget-box" }, [
        _c("p", { staticClass: "widget-title" }, [_vm._v(_vm._s(item.title))]),
        _c(
          "ul",
          { staticClass: "widget-ul" },
          _vm._l(item.widgets, function (widget) {
            return _c(
              "li",
              {
                key: widget.type,
                class: {
                  disable:
                    widget.type.indexOf("top") !== -1 && _vm.topWidgetsDisable,
                  selected: widget.type === _vm.finalTopWidgets.widgetTypeV2,
                },
                on: {
                  click: function ($event) {
                    return _vm.setFinalWidgets(widget)
                  },
                },
              },
              [
                _c("i", { class: widget.icon }),
                _c("p", [_vm._v(_vm._s(widget.name))]),
              ]
            )
          }),
          0
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }