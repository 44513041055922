<template>
  <div>
    <!-- 列表查询 -->
    <el-form ref="search" :inline="true" :model="search" class="search-form search-form-4">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="产品名称" prop="toolProductName">
            <el-input v-model="search.toolProductName" placeholder="请输入产品名称" style="width: 100%"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="平台" prop="toolProductPlatform">
            <el-select v-model="search.toolProductPlatform" style="width: 100%">
              <el-option label="全部" :value="1"></el-option>

              <el-option v-for="item in platformList" :key="item.dictValue" :label="item.dictLabel"
                :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="类型" prop="type">
            <el-select v-model="search.type" style="width: 100%">
              <el-option label="全部" :value="1"></el-option>

              <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="收录时间" prop="createTime">
            <el-date-picker v-model="search.createTime" style="width: 100%" type="datetimerange" prange-separator="至"
              start-placeholder="开始时间" value-format="yyyy-MM-dd HH:mm:ss" end-placeholder="结束时间">
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="测试状态" prop="toolProductTestStatus">
            <el-select v-model="search.toolProductTestStatus" style="width: 100%">
              <el-option label="全部" :value="1"></el-option>
              <el-option v-for="item in testStatusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="测试结果" prop="toolProductResult">
            <el-select v-model="search.toolProductResult" style="width: 100%">
              <el-option label="全部" :value="1"></el-option>

              <el-option v-for="item in toolProductResultList" :key="item.id" :label="item.name"
                :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" round @click="onSubmit">查 询</el-button>
            <el-button type="primary" round @click="resetForm('ruleForm')">重 置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div>
      <el-table v-loading="list.loading" :data="list.data" stripe class="list-table"
        :class="{ 'no-data': !list.data || !list.data.length }" style="width: 100%">
        <el-table-column type="index" label="序号" width="50" align="center" fixed="left"></el-table-column>

        <el-table-column prop="toolProductName" label="名称" align="center" fixed="left"></el-table-column>

        <el-table-column prop="toolProductSource" label="来源" align="center"></el-table-column>

        <el-table-column label="平台" align="center">
          <template slot-scope="{ row }">
            {{ platformList.find(item => item.dictValue === row.toolProductPlatform).dictLabel }}
          </template>
        </el-table-column>

        <el-table-column label="类型" align="center">
          <template slot-scope="{ row }">
            {{ row.type | filterToolType }}
          </template>
        </el-table-column>

        <el-table-column label="连载状态" align="center">
          <template slot-scope="{ row }">
            {{ row.serializeStatus === '1' ? '连载' : '完结' }}
          </template>
        </el-table-column>

        <el-table-column prop="num" label="数量" align="center"></el-table-column>

        <el-table-column prop="createTime" label="收录时间" width="180" align="center"></el-table-column>

        <el-table-column label="评价" align="center">
          <template slot-scope="{ row }">
            <el-button type="text" @click="handleProductEvaluation(row.toolProductId)">{{
              row.toolProductCommentLists?.length || 0
            }}条</el-button>
          </template>
        </el-table-column>

        <el-table-column label="收费卡点" align="center">
          <template slot-scope="{ row }">
            <el-button type="text" @click="handlePayCardPoint(row.toolProductId)">{{
              row.payCardPointListShow || '添加'
            }}</el-button>
          </template>
        </el-table-column>

        <el-table-column label="投放配置" align="center">
          <template slot-scope="{ row }">
            <el-button type="text" @click="handleLaunchConfiguration(row.toolProductId, row.toolProductFilterItemId)">{{
              row.channelName || '添加' }}</el-button>
          </template>
        </el-table-column>

        <el-table-column label="指定广告位" align="left" width="200px">
          <template slot-scope="{ row }">
            <el-tooltip v-if="row.toolProductLaunchPersonsTest.length" placement="top-start">
              <div slot="content">
                <div v-for="(asset, index) in row.toolProductLaunchPersonsTest" :key="index">{{ asset.userName || '-'
                }}：{{ asset.adSpace || '-' }}</div>
              </div>
              <div>
                <div v-for="(asset, index) in row.toolProductLaunchPersonsTest.slice(0, 3)" :key="index"
                  class="tool-user-label">{{ asset.userName || '-' }}：{{ asset.adSpace || '-' }}</div>
              </div>
            </el-tooltip>
            <span v-else>-</span>
          </template>
        </el-table-column>

        <el-table-column prop="toolProductAdPlatform" label="广告平台" align="center"></el-table-column>

        <el-table-column prop="toolProductAdSpace" label="广告位" align="center"></el-table-column>

        <el-table-column label="素材详情" align="center">
          <template slot-scope="{ row }">
            <el-button type="text" @click="handleMaterialDetails(row.toolProductId, row.toolProductFilterItemId)">{{
              row.toolPeoductAssetInfos
            }}</el-button>
          </template>
        </el-table-column>

        <el-table-column label="指定完成时间" align="left" width="200px">
          <template slot-scope="{ row }">
            <el-tooltip v-if="row.toolProductLaunchPersonsAsset.length" placement="top-start">
              <div slot="content">
                <div v-for="(asset, index) in row.toolProductLaunchPersonsAsset" :key="index">{{ asset.userName || '-'
                }}：{{ asset.appointTime || '-' }}</div>
              </div>
              <div>
                <div v-for="(asset, index) in row.toolProductLaunchPersonsAsset.slice(0, 3)" :key="index"
                  class="tool-user-label">{{ asset.userName || '-' }}：{{ asset.appointTime || '-' }}</div>
              </div>
            </el-tooltip>
            <span v-else>-</span>
          </template>
        </el-table-column>

        <el-table-column label="测试时段" align="center">
          <template slot-scope="{ row }">
            <span v-if="row.toolProductTestStatus === '2' || row.toolProductTestStatus === '3'">-</span>
            <span v-if="row.toolProductTestStatus === '4'">{{ row.createTimeLaunch }} ~ 暂无</span>
            <span v-if="row.toolProductTestStatus === '5'">{{ row.createTimeLaunch }} ~ {{ row.endTime }}</span>
          </template>
        </el-table-column>

        <el-table-column label="测试状态" align="center">
          <template slot-scope="{ row }">
            {{ row.toolProductTestStatus | filterTestStatus }}
          </template>
        </el-table-column>

        <el-table-column label="结果" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.toolProductFilterStatus }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="" label="操作" align="center" fixed="right">
          <template slot-scope="{ row }">
            <el-button
              v-if="row.toolProductTestStatus === '4' && row.toolProductFilterStatus !== '失败' && row.toolProductFilterStatus !== '成功'"
              type="text" @click="handleEndTest(row.toolProductFilterItemId)">
              <el-tooltip content="结束测试" placement="top">
                <i class="el-icon-finished" style="font-size: 16px"></i>
              </el-tooltip>
            </el-button>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>

      <div class="page">
        <paginations :total="list.total" :page.sync="list.pageNum" :page-sizes="[5, 10, 15, 20]"
          :limit.sync="list.pageSize" @pagination="getPage" />
      </div>
    </div>

    <!-- 产品评价 -->
    <product-evaluation-dialog ref="productEvaluation" @confirm="getPage" />

    <!-- 付费卡点 -->
    <pay-card-point-dialog ref="payCardPoint" @confirm="getPage" />

    <!-- 结束测试 -->
    <end-test-dialog ref="endTest" @confirm="getPage" />

    <!-- 投放配置 -->
    <launch-configuration-dialog ref="launchConfiguration" @confirm="getPage" />

    <!-- 素材详情 -->
    <material-details-dialog ref="materialDetails" @confirm="getPage" />
  </div>
</template>

<script>
import ProductEvaluationDialog from './components/ProductEvaluationDialog'
import PayCardPointDialog from './components/PayCardPointDialog'
import EndTestDialog from './components/EndTestDialog'
import LaunchConfigurationDialog from './components/LaunchConfigurationDialog'
import MaterialDetailsDialog from './components/MaterialDetailsDialog'

import { MyProjectionList } from '@/network/api/toolManagement/api-product-test'

export default {
  components: {
    'product-evaluation-dialog': ProductEvaluationDialog,
    'pay-card-point-dialog': PayCardPointDialog,
    'end-test-dialog': EndTestDialog,
    'launch-configuration-dialog': LaunchConfigurationDialog,
    'material-details-dialog': MaterialDetailsDialog
  },
  filters: {
    filterToolProductFilterStatus(value) {
      const types = { '1': '全部', '2': '失败', '3': '成功', '4': '男女通用' }
      return types[value] || ''
    },
    filterToolType(value) {
      const types = { '1': '全部', '2': '男频', '3': '女频' }
      return types[value] || ''
    },
    filterTestStatus(value) {
      const types = { '2': '未测试', '3': '待测试', '4': '测试中', '5': '已测试', '6': '自动结束' }
      return types[value] || ''
    }
  },
  data() {
    return {
      search: {
        toolProductName: '',
        toolProductPlatform: 1,
        type: 1,
        createTime: [],
        toolProductTestStatus: 1,
        toolProductResult: 1
      },

      list: {
        loading: true,
        data: [],
        pageSize: 10,
        pageNum: 1,
        total: 0
      },

      platformList: [],
      typeList: [{ id: 2, name: '男频' }, { id: 3, name: '女频' }, { id: 4, name: '男女通用' }],
      toolProductResultList: [{ id: 2, name: '失败' }, { id: 3, name: '成功' }],
      testStatusList: [{ id: 2, name: '未测试' }, { id: 3, name: '待测试' }, { id: 4, name: '测试中' }, { id: 5, name: '已测试' }, { id: 6, name: '自动结束' }]
    }
  },

  mounted() {
    this.getDicts('return_platform_type').then((response) => {
      this.platformList = response.data
      this.getPage()
    })
  },

  methods: {
    // 列表
    getPage() {
      this.list.loading = true
      let search = {
        ...this.search,
        pageSize: this.list.pageSize,
        pageNum: this.list.pageNum
      }
      MyProjectionList(search).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          if (records.length) {
            this.list.data = records.map(item => {
              console.log(item)
              return {
                // 产品数据
                toolProductId: item.toolProductLibray.toolProductId,
                toolProductName: item.toolProductLibray.toolProductName,
                toolProductSource: item.toolProductLibray.toolProductSource,
                toolProductPlatform: item.toolProductLibray.toolProductPlatform,
                type: item.toolProductLibray.type,
                serializeStatus: item.toolProductLibray.serializeStatus,
                num: item.toolProductLibray.num,
                createTime: item.toolProductLibray.createTime,
                payCardPointListShow: item.toolProductLibray.payCardPointListShow,
                toolProductCommentLists: item.toolProductLibray.toolProductCommentLists,
                toolProductTestStatus: item.toolProductLibray.toolProductTestStatus,

                // 个人测试状态和结果
                endTime: item.toolProductFilterItem.endTime,
                toolProductFilterStatus: item.toolProductFilterItem.toolProductFilterStatus,
                toolProductFilterItemId: item.toolProductFilterItem.toolProductFilterItemId,

                // 投放配置
                toolProductAdPlatform: item.toolProductLaunchSetting ? item.toolProductLaunchSetting.toolProductPlatform : '-',
                toolProductAdSpace: item.toolProductLaunchSetting ? item.toolProductLaunchSetting.toolProductAdSpace : '-',
                createTimeLaunch: item.toolProductLaunchSetting ? item.toolProductLaunchSetting.startTestTime : '',
                channelName: item.toolProductLaunchSetting ? item.toolProductLaunchSetting.channelName : '',

                // 投手/素材
                toolProductLaunchPersonsTest: item.toolProductLaunchPersonsTest || [],
                toolProductLaunchPersonsAsset: item.toolProductLaunchPersonsAsset || [],

                // 素材详情
                toolPeoductAssetInfos: this.changeData(item.toolPeoductAssetInfos),

                havaLaunchSetting: item.havaLaunchSetting,
                havaAssetInfo: item.havaAssetInfo
              }
            })
          } else {
            this.list.data = []
          }

          this.list.total = total
        }
      }).finally(e => {
        this.list.loading = false
      })
    },

    // 修改数据
    changeData(arr) {
      if (!arr) return '添加'
      if (arr.length && arr.length > 1) return arr[0].completionStatus + '...'
      else return arr[0].completionStatus
    },

    // 搜索
    onSubmit() {
      if (this.list.pageNum === 1) {
        this.getPage()
      } else {
        this.list.pageNum = 1
      }
    },

    // 重置
    resetForm() {
      this.$refs['search'].resetFields()
      this.getPage()
    },

    // 点击产品评价
    handleProductEvaluation(id) {
      this.$refs.productEvaluation.showModal(id)
    },

    // 点击付费卡点
    handlePayCardPoint(id) {
      this.$refs.payCardPoint.showModal(id)
    },

    // 投放配置
    handleLaunchConfiguration(id, toolProductFilterItemId) {
      this.$refs.launchConfiguration.showModal(id, toolProductFilterItemId)
    },

    // 查看素材详情
    handleMaterialDetails(id, toolProductFilterItemId) {
      this.$refs.materialDetails.showModal(id, toolProductFilterItemId)
    },

    // 结束测试
    handleEndTest(id) {
      this.$refs.endTest.showModal(id)
    }
  }
}
</script>

<style lang="scss" scoped>
.element-img {
  width: 60px;
  height: 60px;
  text-align: center;
  margin: auto;
  padding-top: 12px;

  video {
    width: 100%;
    height: 100%;
  }
}

.element-pop-img {
  max-width: 260px;

  video {
    width: 100%;
    height: 100%;
  }
}

.tool-user-label{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
}

.red {
  color: #ff0000;
}
</style>
