var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "base-box",
        { attrs: { name: "topVideo" } },
        [
          _c("template", { slot: "desc" }, [
            _vm._v(
              " 投放朋友圈信息流，广告外层将自动拉取顶部视频。投放公众号及其他广告位，将不会拉取。 "
            ),
          ]),
          _c(
            "template",
            { slot: "body" },
            [
              _c("div", [
                _c("p", { staticClass: "font-13" }, [_vm._v("广告位：")]),
                _c(
                  "div",
                  { staticClass: "ml-20" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        on: { change: _vm.handleClearVideo },
                        model: {
                          value: _vm.detail.adLocation,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "adLocation", $$v)
                          },
                          expression: "detail.adLocation",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "sns" } }, [
                          _vm._v("朋友圈信息流"),
                        ]),
                        _c("el-radio", { attrs: { label: "gh" } }, [
                          _vm._v("公众号及其他"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm.detail.adLocation === "sns"
                ? [
                    _c("div", [
                      _c("p", { staticClass: "font-13" }, [
                        _vm._v("外层样式："),
                      ]),
                      _c(
                        "div",
                        { staticClass: "ml-20" },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: { change: _vm.handleClearVideo },
                              model: {
                                value: _vm.detail.viewType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.detail, "viewType", $$v)
                                },
                                expression: "detail.viewType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "0" } }, [
                                _vm._v("常规广告"),
                              ]),
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("卡片广告"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    +_vm.detail.viewType === 0
                      ? [
                          _c("div", [
                            _c("p", { staticClass: "font-13" }, [
                              _vm._v("视频类型："),
                            ]),
                            _c(
                              "div",
                              { staticClass: "ml-20" },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    on: { change: _vm.handleClearVideo },
                                    model: {
                                      value: _vm.detail.styleType,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.detail, "styleType", $$v)
                                      },
                                      expression: "detail.styleType",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: 0 } }, [
                                      _vm._v("横版视频"),
                                    ]),
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("竖版视频"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          +_vm.detail.styleType === 1
                            ? _c("div", [
                                _c("p", { staticClass: "font-13" }, [
                                  _vm._v("视频尺寸："),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "ml-20" },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: { placeholder: "" },
                                        on: { change: _vm.handleClearVideo },
                                        model: {
                                          value: _vm.videoSize,
                                          callback: function ($$v) {
                                            _vm.videoSize = $$v
                                          },
                                          expression: "videoSize",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: {
                                            label: "720*1280像素",
                                            value: 0,
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: "750*1536像素",
                                            value: 1,
                                          },
                                        }),
                                        _c("el-option", {
                                          attrs: {
                                            label: "750*1334像素",
                                            value: 2,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _c("div", [
                      _c("p", { staticClass: "font-13" }, [_vm._v("多视频：")]),
                      _c(
                        "div",
                        { staticClass: "ml-20" },
                        [
                          _c("el-switch", {
                            on: { change: _vm.handleClearVideo },
                            model: {
                              value: _vm.detail.multipleSwitch,
                              callback: function ($$v) {
                                _vm.$set(_vm.detail, "multipleSwitch", $$v)
                              },
                              expression: "detail.multipleSwitch",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                : _vm._e(),
              _c("div", { staticClass: "image-updata" }, [
                _c("p", { staticClass: "font-13" }, [
                  _c("span", [_vm._v("选择视频：")]),
                ]),
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "video-list" },
                    [
                      _vm._l(_vm.detail.videoList, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "update" },
                          [
                            item.sightVideoUrl
                              ? _c("video", {
                                  ref: "video",
                                  refInFor: true,
                                  staticClass: "avatar",
                                  attrs: { src: item.sightVideoUrl },
                                })
                              : _vm._e(),
                          ]
                        )
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "update",
                          on: { click: _vm.setMaterialBox },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                        ]
                      ),
                    ],
                    2
                  ),
                  _c("p", { staticClass: "tip mt-10" }, [
                    _vm._v(
                      " 视频尺寸：" + _vm._s(_vm.computedVideoSize()) + " "
                    ),
                    _c("br"),
                    _vm._v(" 视频大小：≤100MB "),
                    _c("br"),
                    _vm._v(" 视频长度：6-90秒 "),
                  ]),
                ]),
              ]),
            ],
            2
          ),
        ],
        2
      ),
      _c("MaterialVideoBox", {
        attrs: {
          visible: _vm.materialBox_visible,
          "img-count": _vm.videoLimit,
          "video-listed": _vm.detail.videoList,
          name: "ldy",
          "select-type": _vm.selectType,
          attribute: {
            key: "topImg",
            value: "顶部视频",
            size: 100 * 1024 * 1024,
            listType: "image/jpg,image/jpeg,image/png",
            ..._vm.fileAttribute,
          },
          "is-batch": false,
          "is-page": true,
        },
        on: {
          setMaterialBox: _vm.setMaterialBox,
          handleVideoSuccess: _vm.selectVideo,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }