<!--
 * @Author: Zeng
 * @Date: 2023-09-04 09:03:38
 * @LastEditTime: 2023-09-06 09:48:40
-->
<template>
  <div>
    <!-- 列表查询 -->
    <el-form ref="search" :inline="true" :model="search" class="search-form search-form-4">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="名称" prop="toolPageRedirectName">
            <el-input v-model="search.toolPageRedirectName" placeholder="请输入名称" style="width: 100%"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="创建人" prop="userName">
            <el-input v-model="search.userName" placeholder="请输入创建人" style="width: 100%"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="创建时间" prop="createTime">
            <el-date-picker v-model="search.createTime" style="width: 100%" type="daterange" prange-separator="至"
              start-placeholder="开始时间" value-format="yyyy-MM-dd" end-placeholder="结束时间"> </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" round @click="onSubmit">查 询</el-button>
            <el-button type="primary" round @click="resetForm('ruleForm')">重 置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div style="text-align:left " class="mb-15">
      <el-button type="primary" icon="el-icon-plus" round @click="handleAddPage('')"> 新建 </el-button>
    </div>

    <div>
      <el-table v-loading="list.loading" :data="list.data" stripe class="list-table"
        :class="{ 'no-data': !list.data || !list.data.length }" style="width: 100%;">
        <el-table-column prop="toolPageRedirectShowId" label="页面ID"></el-table-column>
        <el-table-column prop="toolPageRedirectName" label="名称"></el-table-column>
        <el-table-column prop="userName" label="创建人"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column prop="" label="跳转URL" show-overflow-tooltip>
          <template slot-scope="{row}">
            <span>{{ row.redirectPage }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作">
          <template slot-scope="{row}">
            <el-button type="text" @click="handleAddPage(row)">
              <el-tooltip content="编辑" placement="top">
                <i class="el-icon-edit" style="font-size: 16px"></i>
              </el-tooltip>
            </el-button>
            <el-button :id="'btnLink' + row.toolPageRedirectShowId" type="text" :data-clipboard-text="url"
              @click="handleCopyPage(row.toolPageRedirectShowId)">
              <el-tooltip content="链接" placement="top">
                <i class="el-icon-link" style="font-size: 16px"></i>
              </el-tooltip>
            </el-button>
            <el-button type="text" class="red" @click="handleDeletePage(row.toolPageRedirectId)">
              <el-tooltip content="删除" placement="top">
                <i class="el-icon-delete" style="font-size: 16px;color:red"></i>
              </el-tooltip>
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="page">
        <paginations :total="list.total" :page.sync="list.pageNum" :page-sizes="[5, 10, 15, 20]"
          :limit.sync="list.pageSize" @pagination="getPage" />
      </div>
    </div>

    <landing-page-dialog ref="landingPageDialog" @confirm="getPage"></landing-page-dialog>
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import LandingPageDialog from './components/LandingPageDialog'
import { getLandingPage, deleteLandingPage } from '@/network/api/toolManagement/api-landing-page'
export default {
  components: {
    'landing-page-dialog': LandingPageDialog
  },
  data() {
    return {
      search: {
        toolPageRedirectName: '',
        createTime: this.getDateTime(),
        userName: ''
      },
      list: { // 列表数据
        loading: true,
        data: [],
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      url: ''
    }
  },

  created() {
    this.getPage()
  },

  mounted() { },
  methods: {
    /**
      * @description: 获取列表
      * @param {*} params
      */
    getPage() {
      this.list.loading = true
      let search = {
        ...this.search,
        createTime: this.formateDates(this.search.createTime),
        pageSize: this.list.pageSize,
        pageNum: this.list.pageNum
      }
      getLandingPage(search).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          records.forEach(v => {
            v.createBy = v.createBy || '-'
          })
          this.list.data = records
          this.list.total = total
          this.list.loading = false
        }
      }).catch(e => {
        this.list.loading = false
      })
    },
    /**
    * @description: 查询
    */
    onSubmit() {
      this.getPage()
    },

    /**
     * @description: 重置
     */
    resetForm() {
      this.$refs['search'].resetFields()
      this.getPage()
    },
    // 获取近7天时间段
    getDateTime() {
      const start = new Date(new Date().getTime() - 3600 * 1000 * 24 * 6).toISOString().split('T')[0]
      const end = new Date(new Date().getTime()).toISOString().split('T')[0]
      return [start, end]
    },

    formateDates(dates) {
      if (!dates || (dates && !dates.length)) {
        return []
      }
      const reg = new RegExp('-', 'g')
      const value1 = dates[0].replace(reg, '.')
      const value2 = dates[1].replace(reg, '.')
      return [value1, value2]
    },

    // 复制链接
    async handleCopyPage(id) {
      this.url = `${window.location.origin}/api/kuaishou/ToolPageRedirectController/showUrl?ID=${id}`
      this.$nextTick(() => {
        let clipboard = new Clipboard('#btnLink' + id)
        clipboard.on('success', (e) => {
          this.$message.success('中转页链接复制成功!')
          //  释放内存
          clipboard.destroy()
        })
        clipboard.on('error', (e) => {
          // 不支持复制
          this.$message.error('该浏览器不支持复制')
          // 释放内存
          clipboard.destroy()
        })
      })
    },

    // 点击添加/编辑
    handleAddPage(row) {
      this.$refs.landingPageDialog.showModal(row || {})
    },

    // 点击删除
    handleDeletePage(id) {
      this.$confirm('删除后，已生成链接将失去跳转能力！', '确定要删除该中转页吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { code } = await deleteLandingPage(id)
        if (code === 200) {
          this.getPage()
          this.$message.success('删除成功!')
        }
      }).catch(() => {
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
