<!--
 * @Description: 工作区 -> 轮播图组件
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2022-04-01 10:40:58
-->

<template>
  <section class="image">
    <div v-if="detail.slider.every((item) => item !== '')" style="width: 100%">
      <el-carousel>
        <el-carousel-item v-for="item in detail.slider" :key="item">
          <img :src="item" alt="" style="width:100%" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div v-else style="text-align: center">
      <i v-for="(item, index) in detail.slider" :key="index" class="el-icon-picture-outline"></i>
      <p>{{ detail.name }}组件</p>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.image {
  width: 100%;
  display: flex;
  min-height: 200px;
  justify-content: center;
  align-items: center;
  // background: #fff;
  display: flex;
  flex-direction: column;
  .el-carousel__item {
    display: flex;
    align-items: center;
  }
  i {
    font-size: 60px;
    color: #ccc;
  }
  p {
    margin-top: 10px;
    font-size: 24px;
    color: #ccc;
  }
}
</style>
