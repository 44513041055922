<!--
 * @Description: 创意基础信息
 * @Date: 2021-12-06 15:57:16
 * @LastEditTime: 2022-02-21 09:41:00
-->
<template>
  <div>
    <div class="creativity-material">
      <div v-if="materials.length">
        <div v-for="(item, index) in materials" :key="index" class="img-list">
          <p class="text-name">{{ item.name }}</p>
          <div class="text-box">
            <el-tooltip v-for="(title, i) in item.titles" :key="i" :content="title.descriptionContent" placement="top">
              <div class="text-item">{{ title.descriptionContent }}</div>
            </el-tooltip>
          </div>
          <div class="img-box">
            <div v-for="(itm, idx) in item.list" :key="idx" class="material-item-group">
              <div v-for="(v, i) in itm" :key="i">
                <div class="material-item" v-if="v.assetLocalUrl">
                  <video v-if="checkIsVideoType(v.assetLocalUrl)" :src="v.assetLocalUrl"></video>
                  <img v-else :src="v.assetLocalUrl" class="avatar" />
                </div>
                <!-- <div v-if="v.assetLocalId" class="material-item">
                    <video v-if="v.assetType === 'VIDEO' && v.assetLocalUrl" :src="v.assetLocalUrl"></video>
                    <img v-if="v.assetType === 'IMG' && v.assetLocalUrl" :src="v.assetLocalUrl" class="avatar" />
                  </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else style="text-align: center; padding-top: 20px; color: #909399">暂无数据</div>

      <el-dialog custom-class="dialog-item" title="素材组合编辑" :visible="materialVisible" width="880px" :append-to-body="true"
        destroy-on-close @close="handleClose">
        <span v-loading="loading">
          <div class="justify-start">
            <h3>素材组合</h3>
            <p class="ml-20">素材组数量：{{ creativityMaterialList.length }}组</p>
          </div>
          <!-- 广告版位非“朋友圈” 且 为视频时展示 -->
          <div v-if="(!isImage && taskSiteSet !== 1)" class="justify-start" style="margin: 20px 0;">
            <p class="mr-20">自动生成封面</p>
            <el-radio-group v-model="generateCover">
              <el-radio :label="0">不开启</el-radio>
              <el-radio :label="1">开启</el-radio>
            </el-radio-group>
          </div>
          <el-collapse v-model="collapse">
            <el-collapse-item v-for="(item, index) in creativityMaterialList" :key="index"
              :name="item.name || computedMaterialName(index)">
              <template slot="title">
                <div class="justify-sb group-title">
                  <p>{{ item.name || computedMaterialName(index) }}</p>
                  <div class="icon">
                    <i class="el-icon-document-copy" @click.stop="copyCreativeGroup(item, index)"></i>
                    <i v-if="creativityMaterialList.length > 1" class="el-icon-close"
                      @click.stop="deleteCreativeGroup(item, index)"></i>
                  </div>
                </div>
              </template>
              <!-- 文案 -->
              <div class="title-box">
                <div class="title-count">{{ item.titles.length }}/{{ TITLE_LIMIT_NUM }}</div>
                <el-form ref="titleForm" label-width="80px" label-position="left">
                  <el-form-item v-for="(title, idx) in item.titles" :key="idx" style="width: 100%;">
                    <template slot="label">
                      <div><span style="color: #F56C6C">*</span> 文案 {{ idx + 1 }}</div>
                    </template>
                    <el-input v-if="(taskSiteSet === 1 || taskSiteSet === 6)"
                      v-model="item.titles[idx].descriptionContent" type="textarea" style="width: 400px" :rows="4"
                      resize="none" placeholder="请输入文案, 支持换行输入, 最多4行"></el-input>
                    <el-input v-else v-model="item.titles[idx].descriptionContent" style="width: 400px" maxlength="30"
                      show-word-limit placeholder="请输入文案"></el-input>
                    <span class="title-opt">
                      <i v-if="item.titles.length < TITLE_LIMIT_NUM" class="el-icon-circle-plus-outline"
                        @click="handleAddTitle(index, idx)"></i>
                      <i v-if="idx > 0" class="el-icon-remove-outline" @click="handleDeleteTitle(index, idx)"></i>
                    </span>
                  </el-form-item>
                </el-form>
              </div>

              <div class="creative-group-body">
                <div class="creative-tabs">
                  <div v-if="isImage" class="tag active">图片({{ item.list.length * imageSize }}/{{ imageSize *
                    MATERIAL_LIMIT_NUM
                  }})
                  </div>
                  <div v-else class="tag active">视频({{ item.list.length }}/{{ MATERIAL_LIMIT_NUM }})</div>
                </div>

                <!-- 图片素材 -->
                <div v-if="isImage" class="creative-image-material">
                  <div v-for="(itm, idx) in item.list" :key="idx" class="creative-image-material-group">
                    <div v-for="(v, i) in itm" :key="i" class="creative-image-material-item">
                      <img v-if="v.assetLocalUrl" :src="v.assetLocalUrl" alt="" />
                      <div v-else class="avatar">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                      <div class="avatar-mask">
                        <p @click="handleUploadImage(index, idx, i, 'image')">上传图片</p>
                      </div>
                    </div>
                    <i class="icon-close el-icon-circle-close" @click.stop="handleDeleteMaterial(index, idx)"></i>
                  </div>
                  <template v-if="item.list.length < MATERIAL_LIMIT_NUM">
                    <div class="creative-image-material-item-add" @click="handleAddMaterial(index, true)">+</div>
                    <div v-if="uploadInfo.length" class="info">
                      <p>尺寸：{{ uploadInfo[0].assetLocalResizeWidth }}px * {{ uploadInfo[0].assetLocalResizeHeight
                      }}px(推荐)
                      </p>
                      <p>格式：JPG、JPEG、PNG格式</p>
                      <p>大小：{{ uploadInfo[0].assetLocalResizeFileSize }} KB 以内</p>
                    </div>
                  </template>

                </div>

                <!-- 视频素材 -->
                <div v-else class="creative-video-material">
                  <div v-for="(itm, idx) in item.list" :key="idx" class="creative-video-material-group"
                    :style="{ 'width': !isImage && taskSiteSet !== 1 ? '48%' : 'auto' }">
                    <!-- 视频 -->
                    <div class="creative-video-material-item">
                      <video v-if="itm[0].assetLocalUrl" :src="itm[0].assetLocalUrl" controls></video>
                      <div v-else class="avatar">
                        <i class="el-icon-video-camera"></i>
                      </div>
                      <div class="avatar-mask">
                        <p @click="handleUploadVideo(index, idx, 0)">上传视频</p>
                      </div>
                    </div>
                    <!-- 视频封面 -->
                    <div v-if="itm.length > 1 && (generateCover === 0 || itm[0].assetLocalUrl)"
                      class="creative-video-material-item">
                      <template v-if="itm[1].assetLocalUrl">
                        <video v-if="checkIsVideoType(itm[1].assetLocalUrl)" :src="itm[1].assetLocalUrl"
                          class="video-avatar"></video>
                        <el-image v-else :src="itm[1].assetLocalUrl" fit="contain"></el-image>
                      </template>
                      <div v-else class="avatar">
                        <i class="el-icon-plus avatar-uploader-icon"></i>
                      </div>
                      <div class="avatar-mask">
                        <p @click="handleUploadImage(index, idx, 1, 'video')">上传封面</p>
                      </div>
                    </div>
                    <!-- <div v-for="(v, i) in itm" :key="i" class="creative-video-material-item">
                          <template v-if="v.assetType === 'VIDEO'">
                            <video v-if="v.assetLocalUrl" :src="v.assetLocalUrl" controls></video>
                            <div v-else class="avatar">
                              <i class="el-icon-video-camera"></i>
                            </div>
                            <div class="avatar-mask">
                              <p @click="handleUploadVideo(index, idx, i)">上传视频</p>
                            </div>
                          </template>
                          <template v-if="v.assetType === 'IMG'">
                            <div v-if="generateCover === 0 || v.assetLocalUrl" class="creative-video-material-item-right">
                              <template v-if="v.assetLocalUrl">
                                <video v-if="checkIsVideoType(v.assetLocalUrl)" :src="v.assetLocalUrl"
                                  class="avatar"></video>
                                <el-image v-else :src="v.assetLocalUrl" fit="contain"></el-image>
                              </template>
                              <div v-else class="avatar">
                                <i class="el-icon-plus avatar-uploader-icon"></i>
                              </div>
                              <div class="avatar-mask">
                                <p @click="handleUploadImage(index, idx, i, 'video')">上传封面</p>
                              </div>
                            </div>
                          </template>
                        </div> -->
                    <i class="icon-close el-icon-circle-close" @click.stop="handleDeleteMaterial(index, idx)"></i>
                  </div>
                  <div v-if="item.list.length < MATERIAL_LIMIT_NUM"
                    :style="{ 'width': !isImage && taskSiteSet !== 1 ? '100%' : 'auto', 'display': 'flex' }">
                    <div class="creative-video-material-item-add" @click="handleAddMaterial(index, true)">+</div>
                    <div v-if="uploadInfo.length" class="info">
                      <p>视频尺寸：{{ uploadInfo[0].assetLocalResizeWidth }}px * {{ uploadInfo[0].assetLocalResizeHeight }}px
                      </p>
                      <p>格式：MP4、MOV、AVI格式</p>
                      <p>大小：100 MB 以内</p>
                      <p>播放时长: 5s - 30s</p>
                    </div>
                    <div v-if="uploadInfo.length > 1" class="info" style="margin-left: 20px">
                      <p>封面尺寸：{{ uploadInfo[1].assetLocalResizeWidth }}px * {{
                        uploadInfo[1].assetLocalResizeHeight
                      }}px(推荐)
                      </p>
                      <p>格式：JPG、JPEG、PNG格式</p>
                      <p>大小：{{ uploadInfo[1].assetLocalResizeFileSize }} KB 以内</p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div v-if="isImage" class="img-box">
                    <div v-for="(imgInfo, idx) in item.list" :key="idx" class="mr-10">
                      <div class="avatar-uploader" @click="setMaterialBox(index, 0)">
                        <img v-if="imgInfo.assetLocalUrl" :src="imgInfo.assetLocalUrl" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon" />
                      </div>
                    </div>
                    <div v-if="item.list.length < imageSize * MATERIAL_LIMIT_NUM" class="creative-image-material-item-add"
                      @click="handleAddImage(index)">+</div>
                    <div class="info">
                      <p>尺寸：{{ item.list[0].assetLocalResizeWidth }}px * {{ item.list[0].assetLocalResizeHeight }}px(推荐)</p>
                      <p>格式：JPG、JPEG、PNG格式</p>
                      <p>大小：{{ item.list[0].assetLocalResizeFileSize }} KB 以内</p>
                    </div>
                  </div> -->
              <!-- 视频 -->
              <!-- <div v-else class="justify-sb">
                    <div class="img-box">
                      <div class="mr-10">
                        <div class="avatar-uploader" @click="setMaterialVideoBox(index)">
                          <video v-if="item.list[0].assetLocalUrl" :src="item.list[0].assetLocalUrl" class="avatar" />
                          <i v-else class="el-icon-plus avatar-uploader-icon" />
                        </div>
                      </div>
                      <div class="info">
                        <p>最小尺寸：{{ item.list[0].assetLocalResizeWidth }}px * {{ item.list[0].assetLocalResizeHeight }}px</p>
                        <p>格式：MP4、MOV、AVI格式</p>
                        <p>大小：100 MB 以内</p>
                        <p>播放时长: 5s - 30s</p>
                      </div>
                    </div>
                    <div v-if="item.list.length > 1" class="img-box">
                      <p>封面:</p>
                      <div class="mr-10">
                        <div class="avatar-uploader" @click="setMaterialBox(index, 1)">
                          <img v-if="item.list[1].assetLocalUrl" :src="item.list[1].assetLocalUrl" class="avatar" />
                          <i v-else class="el-icon-plus avatar-uploader-icon" />
                        </div>
                      </div>
                      <div class="info">
                        <p>最小尺寸：{{ item.list[1].assetLocalResizeWidth }}px * {{ item.list[1].assetLocalResizeHeight }}px</p>
                        <p>格式：JPG、JPEG、PNG格式</p>
                        <p>大小：{{ item.list[1].assetLocalResizeFileSize }} KB 以内</p>
                      </div>
                    </div>
                  </div> -->
            </el-collapse-item>
          </el-collapse>
          <div style="text-align: center; margin-top: 10px">
            <el-button type="primary" @click="addCreativeGroup">添加素材组</el-button>
          </div>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" :loading="loading" @click="onSubmit">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <div v-if="materialVisible">
      <material-box :visible="materialBoxVisible" :img-count="imgCount" name="creativeGroup" :image-list="[]" :attribute="{
        key: 'creativeGroup',
        listType: 'image/jpg,image/jpeg,image/png',
        ...fileAttribute
      }" :project-id="[+projectId]" :is-batch="false" @setMaterialBox="materialBoxVisible = false"
        @submit="selectImg" />

      <material-video-box :visible="materialVideoVisible" :img-count="imgCount" name="videoUrl" :video-list="[]"
        :attribute="{
          key: 'videoHorizontal',
          listType: 'video/mp4',
          ...fileAttribute
        }" :project-id="[+projectId]" :is-batch="false" @setMaterialBox="materialVideoVisible = false"
        @handleVideoSuccess="selectVideo" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { saveAssetDesc, deleteAssetGroup, deleteAssetDesc, deleteAssetImgOrVideo } from '@/network/api/advManagement/advManagementTencent' // 获取计划模板
import MaterialBox from './img.vue'
import MaterialVideoBox from './video.vue'
export default {
  components: {
    MaterialBox,
    MaterialVideoBox
  },

  inject: ['closeMaterialCombina'],
  props: {
    materialVisible: {
      type: Boolean,
      default: false
    },
    creativityData: {
      type: Object,
      default: () => { }
    },
    materialCombinaData: {
      type: Array,
      default: () => []
    },
    taskId: {
      type: Number,
      default: 0
    },
    projectId: {
      type: [String, Number],
      default: ''
    },
    accountId: {
      type: Array,
      default: () => []
    },
    taskSiteSet: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      TITLE_LIMIT_NUM: 5, // 文案数量
      MATERIAL_LIMIT_NUM: 10, // 素材数量
      fileAttribute: {},
      fileInfo: {
        groupIndex: 0,
        rowIndex: 0,
        index: 0,
        type: 'image'
      },
      materialBoxVisible: false,
      materialVideoVisible: false,
      imgCount: 0,
      creativityMaterialList: [],
      materials: [], //
      currentItem: {}, // 当前添加素材类型字段
      currentCoverItem: {}, // 当前添加素材封面字段
      collapse: ['素材组01', '素材组02', '素材组03'],
      loading: false,
      generateCover: 0 // 自动生成封面
    }
  },

  computed: {
    ...mapGetters(['userInfo']),
    // 是否为图片类型
    isImage() {
      return [311, 641, 642, 643, 711, 712].includes(this.creativityData.adcreativeTemplateId)
    },
    // 图片类型组数量
    imageSize() {
      let num = 1
      switch (this.creativityData.adcreativeTemplateId) {
        case 641:
          num = 3
          break
        case 642:
          num = 4
          break
        case 643:
          num = 6
          break
        default:
          num = 1
      }
      return num
    },
    uploadInfo() {
      return this.getMaterialList(this.creativityData.adcreativeTemplateId)
    }
  },

  watch: {
    materialVisible(value) {
      if (value) {
        this.generateCover = this.creativityData.autoGenerateCoverPage ? 1 : 0
      }
    },
    materialCombinaData: {
      handler(value) {
        if (value.length) {
          this.materials = []
          this.creativityMaterialList = []
          let list = value.map((item) => {
            let titles = item.descLists.map(title => title.saveDescLists[0])
            let material = item.assetLists.map(v => {
              if (this.taskSiteSet !== 1 && !this.isImage && v.saveAssetLists.length === 1) {
                let cover = {
                  taskAssetId: null,
                  materialName: '',
                  assetGroupName: '',
                  assetGroupOrderBy: 1, // 组排序
                  assetType: 'IMG',
                  assetLocalId: null,
                  assetLocalUrl: v.saveAssetLists[0].assetLocalUrl,
                  assetLocalSignature: null,
                  assetLocalResizeFileSize: this.uploadInfo.assetLocalResizeFileSize,
                  assetLocalResizeWidth: this.uploadInfo.assetLocalResizeWidth,
                  assetLocalResizeHeight: this.uploadInfo.assetLocalResizeHeight
                }
                return [v.saveAssetLists[0], cover]
              } else {
                return v.saveAssetLists
              }
            })
            return {
              name: item.assetDescriptionGroupName,
              list: material,
              titles: titles
            }
          })
          this.creativityMaterialList = [...list]
          this.materials = [...list]
        } else {
          this.creativityMaterialList = [
            {
              name: this.computedMaterialName(),
              list: [],
              titles: [{
                taskDescriptionId: null,
                descriptionContent: '',
                desGroupOrderBy: 0,
                desGroupName: 0,
                textId: null
              }]
            }
          ]
          this.materials = []
        }
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    // 区分类别-新增素材组
    getMaterialList(type) {
      let taskId = this.taskId
      // let taskSiteSet = this.taskSiteSet
      let assetGroupName = ''
      let data = []
      if (type === 311) {
        data.push({
          taskId: taskId,
          taskAssetId: null,
          materialName: '',
          assetGroupName: assetGroupName,
          assetGroupOrderBy: 1, // 组排序
          assetType: 'IMG',
          assetLocalId: null,
          assetLocalUrl: null,
          assetLocalSignature: null,
          assetLocalResizeFileSize: 300,
          assetLocalResizeWidth: 800,
          assetLocalResizeHeight: 800
        })
      }
      if (type === 641 || type === 642 || type === 643) {
        let num = 3
        switch (type) {
          case 641:
            num = 3
            break
          case 642:
            num = 4
            break
          case 643:
            num = 6
            break
          default:
            num = 3
        }
        for (let i = 1; i <= num; i++) {
          data.push({
            taskId: taskId,
            taskAssetId: null,
            materialName: '',
            assetGroupName: assetGroupName,
            assetGroupOrderBy: 1, // 组排序
            assetType: 'IMG',
            assetLocalId: null,
            assetLocalUrl: null,
            assetLocalSignature: null,
            assetLocalResizeFileSize: 300,
            assetLocalResizeWidth: 800,
            assetLocalResizeHeight: 800
          })
        }
      }
      if (type === 711 || type === 712) {
        data.push({
          taskId: taskId,
          taskAssetId: null,
          materialName: '',
          assetGroupName: assetGroupName,
          assetGroupOrderBy: 1, // 组排序
          assetType: 'IMG',
          assetLocalId: null,
          assetLocalUrl: null,
          assetLocalSignature: null,
          assetLocalResizeFileSize: 400,
          assetLocalResizeWidth: type === 711 ? 1280 : 720,
          assetLocalResizeHeight: type === 711 ? 720 : 1280
        })
      }
      if (type === 721 || type === 720 || type === 599) {
        let item = {
          taskId,
          taskAssetId: null,
          materialName: '',
          assetGroupName: assetGroupName,
          assetGroupOrderBy: 1, // 组排序
          assetType: 'VIDEO',
          assetLocalId: null,
          assetLocalUrl: null,
          assetLocalSignature: null,
          assetLocalResizeFileSize: 100 * 1024,
          assetLocalResizeWidth: type === 599 ? 1280 : (type === 720 ? 1280 : 720),
          assetLocalResizeHeight: type === 599 ? 960 : (type === 720 ? 720 : 1280)
        }
        data.push(item)
        if (this.taskSiteSet !== 1) {
          data.push({
            taskId,
            taskAssetId: null,
            materialName: '',
            assetGroupName: assetGroupName,
            assetGroupOrderBy: 1, // 组排序
            assetType: 'IMG',
            assetLocalId: null,
            assetLocalUrl: null,
            assetLocalSignature: null,
            assetLocalResizeFileSize: 400,
            assetLocalResizeWidth: type === 599 ? 1280 : (type === 720 ? 1280 : 720),
            assetLocalResizeHeight: type === 599 ? 960 : (type === 720 ? 720 : 1280)
          })
        }
      }
      return data
    },

    // 添加文案
    handleAddTitle(index, i) {
      this.creativityMaterialList[index].titles.push({
        taskDescriptionId: null,
        descriptionContent: '',
        desGroupOrderBy: 0,
        desGroupName: 0,
        textId: null
      })
    },

    // 删除文案
    async handleDeleteTitle(index, i) {
      let item = this.creativityMaterialList[index]
      if (item.titles[i].taskDescriptionId) {
        this.loading = true
        this.$emit('clearTaskGenerate')
        await deleteAssetDesc({ taskId: this.taskId, desGroupName: item.titles[i].desGroupName })
        this.loading = false
      }

      this.creativityMaterialList[index].titles.splice(i, 1)
    },

    // 添加素材组
    addCreativeGroup() {
      if (!this.checkSelect()) {
        return this.$message.error('请先选择完素材后再添加!')
      }

      this.creativityMaterialList.push({
        list: [],
        titles: [{
          taskDescriptionId: null,
          descriptionContent: '',
          desGroupOrderBy: 0,
          desGroupName: 0,
          textId: null
        }]
      })
    },

    // 复制素材组
    copyCreativeGroup(item, index) {
      let flag = true
      if (!item.list.length) {
        flag = false
      } else {
        item.list[item.list.length - 1].forEach(v => {
          if (v.assetLocalId === null || v.assetLocalUrl === '') {
            flag = false
            return
          }
        })
      }
      if (!flag) {
        return this.$message.error('请先选择完素材后再复制!')
      }
      let list = []
      item.list.map(v => {
        let arr = v.map(value => {
          return {
            ...value,
            taskAssetId: null,
            assetGroupName: '',
            assetGroupOrderBy: 0
          }
        })
        list.push(arr)
      })

      let titles = item.titles.map(v => {
        return {
          ...v,
          taskDescriptionId: null,
          desGroupName: '',
          desGroupOrderBy: 0
        }
      })
      this.creativityMaterialList.push({
        list: list,
        titles: titles
      })
    },

    // 删除素材组
    async deleteCreativeGroup(item, groupIndex) {
      if (item.list.length && item.list[0][0].taskAssetId) {
        this.loading = true
        this.$emit('clearTaskGenerate')
        await deleteAssetGroup({ taskId: this.taskId, assetDescriptionGroupName: item.name })
        this.loading = false
      }
      this.creativityMaterialList.splice(groupIndex, 1)
      this.creativityMaterialList.forEach((item, index) => {
        const name = this.computedMaterialName(index)
        item.name = name
      })
    },

    // 添加素材
    handleAddMaterial(index, tag) {
      if (tag && !this.checkSelectFlag()) {
        return this.$message.error('请先选择完素材后再添加!')
      }
      let list = this.getMaterialList(this.creativityData.adcreativeTemplateId)
      this.currentItem = list[0]
      if (!this.isImage && this.taskSiteSet !== 1) {
        this.currentCoverItem = list[1]
      }
      this.creativityMaterialList[index].list.push(list)
    },

    // 删除素材
    async handleDeleteMaterial(groupIndex, rowIndex) {
      let list = this.creativityMaterialList[groupIndex].list
      if (list.length && list[rowIndex][0].taskAssetId) {
        this.loading = true
        this.$emit('clearTaskGenerate')
        await deleteAssetImgOrVideo({ taskId: this.taskId, assetGroupName: list[rowIndex][0].assetGroupName })
        this.loading = false
      }
      this.creativityMaterialList[groupIndex].list.splice(rowIndex, 1)
    },

    // 上传图片
    handleUploadImage(groupIndex, rowIndex, index, type) {
      let size = null
      if (type === 'image') {
        if (!Object.keys(this.currentItem).length) {
          let item = this.creativityMaterialList[groupIndex].list[0][0]
          this.currentItem = {
            ...item,
            taskAssetId: this.creativityMaterialList[groupIndex].list[rowIndex][index].assetLocalUrl ? item.taskAssetId : null,
            assetGroupName: '',
            assetGroupOrderBy: 1 // 组排序
          }
        }
        let listData = this.computedListData(groupIndex)
        let arr = listData.filter(v => v.assetLocalId)
        this.imgCount = this.MATERIAL_LIMIT_NUM * this.imageSize - arr.length
        size = this.currentItem.assetLocalResizeFileSize * 1024
      } else {
        // 封面
        let list = this.creativityMaterialList[groupIndex].list
        if (list.length && !list[0][0].assetLocalId) {
          return this.$message.error('请先选择视频！')
        }
        if (!Object.keys(this.currentCoverItem).length) {
          let item = this.creativityMaterialList[groupIndex].list[0][1]
          this.currentCoverItem = {
            ...item,
            taskAssetId: this.creativityMaterialList[groupIndex].list[rowIndex][index].assetLocalUrl ? item.taskAssetId : null,
            assetGroupName: '',
            assetGroupOrderBy: 1 // 组排序
          }
        }
        let listData = this.computedVideoListData(groupIndex)
        let arr = listData.slice(rowIndex)
        let covers = arr.filter(v => (v.cover && v.cover.assetLocalUrl))
        this.imgCount = list[rowIndex][0].assetLocalId ? arr.length - covers.length + 1 : arr.length - covers.length
        size = this.currentCoverItem.assetLocalResizeFileSize * 1024
      }
      this.fileAttribute = {
        value: this.getImgAttributeValue(),
        count: this.imgCount,
        size: size,
        width: [599, 720, 721].includes(this.creativityData.adcreativeTemplateId) ? null : this.currentItem.assetLocalResizeWidth,
        height: [599, 720, 721].includes(this.creativityData.adcreativeTemplateId) ? null : this.currentItem.assetLocalResizeHeight
      }

      this.fileInfo = {
        groupIndex: groupIndex,
        rowIndex: rowIndex,
        index: index,
        type: type
      }
      this.materialBoxVisible = true
    },

    // 上传视频
    handleUploadVideo(groupIndex, rowIndex, index) {
      let item = this.creativityMaterialList[groupIndex].list[rowIndex][index]
      let listData = this.computedVideoListData(groupIndex)
      let arr = listData.filter(v => v.assetLocalId)
      this.imgCount = item.assetLocalId ? this.MATERIAL_LIMIT_NUM - arr.length + 1 : this.MATERIAL_LIMIT_NUM - arr.length
      let value = ''
      switch (this.creativityData.adcreativeTemplateId) {
        case 720:
          value = '横版视频'
          break
        case 721:
          value = '竖版视频'
          break
        case 599:
          value = '横版视频4:3'
          break
      }

      this.fileAttribute = {
        value: value,
        count: this.imgCount,
        size: this.currentItem.assetLocalResizeFileSize * 1024,
        width: null,
        height: null
      }
      this.fileInfo = {
        groupIndex: groupIndex,
        rowIndex: rowIndex,
        index: index,
        type: 'video'
      }
      this.materialVideoVisible = true
    },

    // 选择图片回调
    selectImg(_data) {
      if (!_data || !_data.length) {
        return
      }

      let { groupIndex, rowIndex, index, type } = this.fileInfo
      // 整合数据字段
      let data = _data.map(v => {
        return {
          ...this.currentItem,
          materialName: v.materialName,
          assetLocalId: v.imageId,
          assetLocalUrl: v.image,
          assetLocalSignature: v.hash
        }
      })
      if (type === 'image') {
        if (this.creativityMaterialList[groupIndex].list[rowIndex][index].assetLocalId) {
          let item = data[0]
          this.creativityMaterialList[groupIndex].list[rowIndex].splice(index, 1, item)
          data.shift()
        }

        let arr = this.computedListData(groupIndex)
        let source = [...arr, ...data]
        let list = []
        for (let i = 0; i < source.length; i += this.imageSize) {
          let arr = source.slice(i, i + this.imageSize)
          if (arr.length < this.imageSize) {
            let arr1 = this.fillArr(this.imageSize - arr.length, 'IMG')
            arr = arr.concat(arr1)
            list.push(arr)
          } else {
            list.push(arr)
          }
        }
        this.creativityMaterialList[groupIndex].list = [...list]
      } else {
        // console.log('封面：', _data, this.creativityMaterialList[groupIndex].list[rowIndex])
        // this.imgCount = 1
        // this.creativityMaterialList[groupIndex].list[rowIndex][1] = { ...data[0] }

        // 整合数据字段
        let list = this.computedVideoListData(groupIndex)
        let coverItem = this.creativityMaterialList[groupIndex].list[rowIndex][index]
        if (coverItem.assetLocalId) {
          list = list.filter(v => v.assetLocalId !== coverItem.assetLocalId)
        }
        let videoIndex = 0
        let materialList = list.map((v, i) => {
          if (i >= rowIndex && data[videoIndex]) {
            v.cover = data[videoIndex]
            videoIndex++
          }
          let item = { ...v }
          delete item.cover
          return [item, {
            ...this.uploadInfo[1],
            assetType: 'IMG',
            materialName: v.cover.materialName,
            assetLocalId: v.cover.assetLocalId,
            assetLocalUrl: v.cover.assetLocalUrl,
            assetLocalSignature: v.cover.assetLocalSignature
          }]
        })
        this.creativityMaterialList[groupIndex].list = materialList
      }
    },

    // 选择视频回调
    selectVideo(_videos) {
      if (!_videos || !_videos.length) {
        return
      }
      let videos = _videos.map(v => {
        return {
          ...this.currentItem,
          materialName: v.materialName,
          assetLocalId: v.videoId,
          assetLocalUrl: v.videoUrl
        }
      })

      let { groupIndex, rowIndex, index } = this.fileInfo
      // 整合数据字段
      let data = this.creativityMaterialList[groupIndex].list.map(v => {
        if (!this.isImage && this.taskSiteSet !== 1) {
          return {
            ...v[0],
            cover: v[1] || null
          }
        } else {
          return v
        }
      })
      let item = this.creativityMaterialList[groupIndex].list[rowIndex][index]
      if (item.assetLocalId) {
        data = data.filter(v => v.assetLocalId !== item.assetLocalId)
      }
      let source = data.concat(videos).filter(v => v.assetLocalId)
      let materialList = source.map(v => {
        if (!this.isImage && this.taskSiteSet !== 1) {
          let item = { ...v }
          let cover = v.cover || this.uploadInfo[1] || {}
          delete item.cover
          return [item, {
            ...cover,
            assetType: 'IMG',
            assetLocalUrl: (!cover.assetLocalId && this.generateCover === 1) ? v.assetLocalUrl : cover.assetLocalUrl
          }]
        } else {
          return [v]
        }
      })
      this.creativityMaterialList[groupIndex].list = materialList
      // let coverNum = 0 // 总填充数据数量
      // let source = [...videos] // 填充数据源
      // for (let i = rowIndex; i < data.length; i++) {
      //   let arr = data.filter(v => v.assetLocalId)
      //   let cover = this.MATERIAL_LIMIT_NUM - arr.length
      //   coverNum += cover
      //   let res = source.slice(0, cover)
      //   source = source.slice(cover)
      //   let video = data.concat(res)
      //   video = video.filter(v => v.assetLocalId)
      //   let materialList = video.map(v => {
      //     let item = { ...v }
      //     let cover = v.cover || this.uploadInfo[1] || {}
      //     delete item.cover
      //     return [item, {
      //       ...cover,
      //       assetLocalUrl: (!cover.assetLocalId && this.generateCover === 1) ? v.assetLocalUrl : null
      //     }]
      //   })
      //   this.creativityMaterialList[groupIndex].list = materialList
      // }
    },
    // selectVideo(_data) {
    //   if (!_data || !_data.length) {
    //     return
    //   }

    //   let { groupIndex, rowIndex, index } = this.fileInfo
    //   // 整合数据字段
    //   let data = _data.map(v => {
    //     if (!this.isImage && this.taskSiteSet !== 1) {
    //       return [
    //         {
    //           ...this.currentItem,
    //           materialName: v.materialName,
    //           assetLocalId: v.videoId,
    //           assetLocalUrl: v.videoUrl
    //         }, {
    //           ...this.currentCoverItem,
    //           materialName: '',
    //           assetLocalId: null,
    //           assetLocalUrl: null
    //         }
    //       ]
    //     } else {
    //       return [{
    //         ...this.currentItem,
    //         materialName: v.materialName,
    //         assetLocalId: v.videoId,
    //         assetLocalUrl: v.videoUrl
    //       }]
    //     }
    //   })

    //   if (this.creativityMaterialList[groupIndex].list[rowIndex][index].assetLocalId) {
    //     let item = data[0]
    //     this.creativityMaterialList[groupIndex].list[rowIndex].splice(index, 1, item)
    //     data.shift()
    //   }

    //   let arr = this.computedVideoListData(groupIndex)
    //   let source = [...arr, ...data]
    //   let list = []
    //   for (let i = 0; i < source.length; i += this.imageSize) {
    //     let arr = source.slice(i, i + this.imageSize)
    //     if (arr.length < this.imageSize) {
    //       let arr1 = this.fillArr(this.imageSize - arr.length, 'VIDEO')
    //       arr = arr.concat(arr1)
    //       list.push(...arr)
    //     } else {
    //       if (this.generateCover === 1 && !arr[0][1].assetLocalUrl) {
    //         arr[0][1].assetLocalUrl = arr[0][0].assetLocalUrl
    //       }
    //       list.push(...arr)
    //     }
    //   }
    //   this.creativityMaterialList[groupIndex].list = [...list]
    // },

    // 填充数组
    fillArr(count, assetType) {
      let data = []
      for (let i = 0; i < count; i++) {
        data.push({
          assetType: assetType,
          assetLocalId: null,
          assetLocalUrl: null,
          assetLocalSignature: null,
          assetLocalResizeFileSize: null,
          assetLocalResizeWidth: null,
          assetLocalResizeHeight: null
        })
      }
      return data
    },

    computedVideoListData(groupIndex) {
      let data = this.creativityMaterialList[groupIndex].list.map(v => {
        return {
          ...v[0],
          cover: v[1] || null
        }
      })
      return data
    },

    computedListData(groupIndex) {
      let arr = this.creativityMaterialList[groupIndex]
      let data = []
      arr.list.map(v => {
        let list = v.filter(item => item.assetLocalId)
        data.push(...list)
      })
      return data
    },

    // 生成素材组名
    computedMaterialName(index = 0) {
      index = index + 1
      let order = index <= 9 ? '0' + index : index
      return '素材组' + order
    },

    // 判断是否可添加元素
    checkSelectFlag() {
      let listArray = []
      this.creativityMaterialList.map((item) => {
        listArray.push(...item.list)
      })
      let flag = true
      listArray.flat().forEach((item) => {
        if (!item.assetLocalUrl) {
          flag = false
          return
        }
      })
      return flag
    },

    checkIsVideoType(url) {
      let flag = false
      if (!url) {
        return flag
      }
      const type = ['mp4', 'mov', 'avi']
      const strArr = url && url.length ? url.split('.') : []
      let str = strArr[strArr.length - 1]
      if (strArr.length && type.includes(str)) {
        flag = true
      }
      return flag
    },

    checkSelect() {
      let listArray = []
      this.creativityMaterialList.map((item) => {
        listArray.push(...item.list)
      })
      let flag = true
      this.creativityMaterialList.forEach(item => {
        if (!item.list.length) {
          flag = false
          return
        }
        console.log(item.list)
        item.list[item.list.length - 1].forEach(v => {
          if (!v.assetLocalId && !v.assetLocalUrl) {
            flag = false
            return
          }
        })
        return
      })
      return flag
    },

    checkSelectTitles() {
      let save = true
      let titles = []
      this.creativityMaterialList.map(item => {
        titles.push(...item.titles)
      })
      titles.forEach((item) => {
        if (!item.descriptionContent) {
          save = false
          this.$message.error('请输入文案!')
          return
        } else if ((this.taskSiteSet === 1 || this.taskSiteSet === 6) && item?.descriptionContent.split('\n').length > 4) {
          save = false
          this.$message.error('文案换行最多4行！')
          return
        }
      })
      return save
    },

    // 视频封面
    getImgAttributeValue() {
      let value = '创意素材'
      if (this.taskSiteSet !== 1) {
        if (this.creativityData.adcreativeTemplateId === 720) {
          value = '横版视频'
        }
        if (this.creativityData.adcreativeTemplateId === 721) {
          value = '竖版视频'
        }
        if (this.creativityData.adcreativeTemplateId === 599) {
          value = '横版视频4:3'
        }
      }
      return value
    },

    /**
     * @description 创意素材添加
     */
    async onSubmit() {
      if (!this.checkSelectTitles()) {
        return
      }
      if (!this.checkSelectFlag()) {
        return this.$message.error('请先选择完素材后再保存!')
      }
      if (!this.checkSelect()) {
        return this.$message.error('请先选择完素材后再保存!')
      }

      let lists = []
      this.creativityMaterialList.map((item, _groupIndex) => {
        let groupName = this.computedMaterialName(_groupIndex)
        let groupIndex = _groupIndex + 1
        let titles = item.titles.map((title, i) => {
          let obj = {
            taskDescriptionId: title.taskDescriptionId,
            descriptionContent: title.descriptionContent,
            desGroupOrderBy: groupIndex,
            desGroupName: groupName,
            textId: title.textId
          }
          return { saveDescLists: [obj] }
        })
        let materials = item.list.map((v, i) => {
          let _arr = v.filter(w => !(w.assetType === 'IMG' && this.checkIsVideoType(w.assetLocalUrl)))
          let arr = _arr.map(child => {
            return {
              taskAssetId: child.taskAssetId,
              materialName: child.materialName,
              assetType: child.assetType,
              assetLocalId: child.assetLocalId,
              assetLocalUrl: child.assetLocalUrl,
              assetLocalSignature: child.assetLocalSignature,
              assetLocalResizeFileSize: child.assetLocalResizeFileSize,
              assetLocalResizeWidth: child.assetLocalResizeWidth,
              assetLocalResizeHeight: child.assetLocalResizeHeight,
              assetGroupOrderBy: groupIndex,
              assetGroupName: groupName
            }
          })
          return { saveAssetLists: arr }
        })

        lists.push({
          descLists: titles,
          assetLists: materials,
          assetDescriptionGroupName: groupName
        })
      })

      console.log(JSON.stringify(lists))

      this.$emit('clearTaskGenerate')
      saveAssetDesc({ taskId: this.taskId, autoGenerateCoverPage: this.generateCover === 1, lists: lists }).then((res) => {
        if (res.code === 200) {
          this.$message.success('素材保存成功!')
          this.handleClose()
        }
      })
    },

    handleClose() {
      this.creativityMaterialList = [
        {
          list: [],
          titles: [{
            taskDescriptionId: null,
            descriptionContent: '',
            desGroupOrderBy: 0,
            desGroupName: 0,
            textId: null
          }]
        }
      ]
      this.currentItem = {}
      this.currentCoverItem = {}
      this.imgCount = 0
      this.fileInfo = {
        groupIndex: 0,
        rowIndex: 0,
        index: 0,
        type: 'image'
      }
      this.fileAttribute = {}
      this.generateCover = 0
      this.$emit('byTaskIdGetAllFun')
      this.closeMaterialCombina()
    }
  }
}
</script>

<style lang="scss" scoped>
.creativity-material {
  overflow-y: auto;
  height: 300px;

  .text-name {
    font-weight: bold;
    margin-bottom: 6px;
    margin-top: 10px;
  }

  .img-box {
    width: 100%;
    display: flex;
    flex-flow: wrap row;

    .material-item-group {
      display: flex;
      border: solid 1px #d6d4d4;
      box-sizing: border-box;
      margin-right: 15px;
      margin-bottom: 15px;
      position: relative;
      justify-content: center;
      align-items: center;

      .material-item {
        width: 80px;
        margin: 5px;
      }

      img {
        width: 100%;
        height: 100%;
      }

      video {
        width: 100%;
      }
    }
  }

  .text-box {
    margin-bottom: 10px;

    .text-item {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-bottom: 5px;
    }
  }
}

.dialog-item {
  p {
    margin: 0;
    padding: 0;
  }

  .el-dialog__body {
    padding-top: 0px !important;
  }

  .group-title {
    width: 100%;

    .icon {
      i {
        margin-right: 10px;
      }
    }
  }

  .img-box {
    display: flex;
    flex-wrap: wrap;

    &>p {
      width: 50px;
      font-size: 16px;
      text-align: right;

      margin-right: 10px;
    }

    .img-box-title {
      border: solid 1px #d6d4d4;
      box-sizing: border-box;
    }

    .info {
      margin-top: 8px;
      margin-left: 10px;

      p {
        color: #a8a4a4;
        font-size: 12px;
        margin: 0;
        padding: 0;
      }
    }
  }

  .avatar-uploader {
    margin-bottom: 10px;

    .el-upload {
      border: 1px solid #aeadb2;
      background-color: #aeadb1;
      border-radius: 3px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }

    .el-upload:hover {
      border-color: $themeColor;

      .avatar-uploader-icon {
        color: $themeColor;
      }
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #d3d2d8;
      width: 100px;
      height: 100px;
      line-height: 100px;
      text-align: center;
      border: 1px solid #d6d4d4;
      border-radius: 0;
    }

    .avatar {
      width: 100px;
      height: 100px;
      display: block;
    }

    .el-upload__tip {
      color: #a8acb4;
      font-size: 12px;
      margin-top: -17px;
    }
  }

  .el-collapse-item__header {
    font-size: 16px;
    font-weight: bold;
  }

  .title-box {
    position: relative;

    .title-count {
      font-size: 14px;
      color: #999;
      text-align: right;
      position: absolute;
      right: 0;
    }

    .title-opt {
      font-size: 16px;
      color: #666;
      position: absolute;

      i {
        margin-left: 15px;
        cursor: pointer;
      }
    }
  }
}

.creative-group-body {
  position: relative;
  padding: 0 10px 10px;

  .creative-tabs {
    display: flex;
    font-size: 12px;
    height: 40px;
    line-height: 40px;
    margin-bottom: 15px;
    position: relative;
    border-bottom: 1px solid #dcdfe6;

    .tag {
      margin: 0 15px;
      cursor: pointer;
    }

    .tag-switch {
      position: absolute;
      right: 0;
    }

    .active {
      color: #409eff;
      border-bottom: solid 2px #409eff;
    }
  }

  .creative-image-material {
    width: 100%;
    display: flex;
    flex-flow: wrap row;

    .creative-image-material-group {
      display: flex;
      border: solid 1px #d6d4d4;
      box-sizing: border-box;
      margin-right: 15px;
      margin-bottom: 15px;
      position: relative;
      justify-content: center;
      align-items: center;

      .icon-close {
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 1000;
        color: #999;
        font-size: 20px;
        cursor: pointer;
      }
    }

    .creative-image-material-item {
      position: relative;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      width: 120px;
      height: 120px;
      border: 1px dashed #dcdfe6;
      margin: 5px;

      img {
        width: 100%;
        height: 100%;
      }

      video {
        width: 100%;
        height: 100%;
      }

      .avatar {
        width: 100%;
        height: 100%;
        font-size: 40px;
        color: #8c939d;
        background-color: #c0c4cc;
        text-align: center;
        padding-top: 30px;
      }

      .avatar-mask {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        display: none;
        padding: 0 20px;

        p {
          max-width: 150px;
          color: #fff;
          padding: 5px;
          margin: 45px auto 0;
          background-color: #409eff;
          font-size: 14px;
          border-radius: 4px;
          text-align: center;
          cursor: pointer;
        }
      }

      &:hover .avatar-mask {
        display: block;
      }
    }

    .creative-image-material-item-add {
      margin: 5px;
      display: flex;
      width: 120px;
      height: 120px;
      font-size: 40px;
      font-weight: 300;
      color: #8c939d;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: 1px dashed #dcdfe6;
      box-sizing: border-box;
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 10px;
    }
  }

  .creative-video-material {
    width: 100%;
    display: flex;
    flex-flow: wrap row;

    .creative-video-material-group {
      display: flex;
      border: solid 1px #d6d4d4;
      box-sizing: border-box;
      margin-right: 15px;
      margin-bottom: 15px;
      position: relative;
      justify-content: space-around;
      align-items: center;
      width: 48%;

      .icon-close {
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 1000;
        color: #999;
        font-size: 20px;
        cursor: pointer;
      }
    }

    .creative-video-material-item {
      position: relative;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      width: 120px;
      height: 120px;
      margin: 5px;

      .el-image {
        width: 100%;
        height: 100%;
        border: 1px dashed #dcdfe6;
      }

      video {
        width: 100%;
        height: 100%;
        border: 1px dashed #dcdfe6;
      }

      .video-avatar {
        width: 100%;
        height: 100%;
        font-size: 40px;
        color: #8c939d;
        background-color: #c0c4cc;
        text-align: center;
        border: 1px dashed #dcdfe6;
      }

      .avatar {
        width: 100%;
        height: 100%;
        font-size: 40px;
        color: #8c939d;
        background-color: #c0c4cc;
        text-align: center;
        padding-top: 30px;
        border: 1px dashed #dcdfe6;
      }

      .avatar-mask {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        display: none;
        padding: 0 20px;

        p {
          max-width: 150px;
          color: #fff;
          padding: 5px;
          margin: 45px auto 0;
          background-color: #409eff;
          font-size: 14px;
          border-radius: 4px;
          text-align: center;
          cursor: pointer;
        }
      }

      &:hover .avatar-mask {
        display: block;
      }
    }

    .creative-video-material-item-right {
      height: 100%;
    }

    .creative-video-material-item-add {
      margin: 5px;
      display: flex;
      width: 120px;
      height: 120px;
      font-size: 40px;
      font-weight: 300;
      color: #8c939d;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: 1px dashed #dcdfe6;
      box-sizing: border-box;
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 10px;
    }
  }
}
</style>
