<!--
 * @Description: Do not edit
 * @Date: 2021-11-04 09:04:50
 * @LastEditTime: 2022-02-21 09:39:40
-->
<template>
  <section>
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px" class="demo-dynamic">
      <el-form-item label="组件类型" prop="region">
        <el-select v-model="ruleForm.region" placeholder="请选择活动区域" class="w-400">
          <el-option label="区域一" value="shanghai"></el-option>
          <el-option label="区域二" value="beijing"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="组件名称" prop="name">
        <el-input v-model="ruleForm.name" class="w-400"></el-input>
      </el-form-item>

      <el-form-item label="卡片主图" prop="name">
        <div class="avatar-uploader">
          <div style="text-alian:left; display:flex">
            <i class="el-icon-plus el-upload--picture-card" style="font-size: 28px; color: #8c939d; margin-top:14px;  text-align: center" />
          </div>
        </div>
      </el-form-item>

      <el-form-item label="卡片标题" prop="name">
        <el-input v-model="ruleForm.name" class="w-400"></el-input>
      </el-form-item>

      <el-form-item label="推广卖点" prop="domains">
        <el-button type="primary" @click="addDomain">
          新增卖点
          <span :style="{ color: ruleForm.domains.length > 10 ? 'red' : '#fff' }" class="ml-10">
            {{ ruleForm.domains.length }}
          </span>
          / 10
        </el-button>
      </el-form-item>

      <el-form-item
        v-for="(domain, index) in ruleForm.domains"
        :key="domain.key"
        :label="'卖点' + (index + 1)"
        :prop="'domains.' + index + '.value'"
        :rules="{
          required: true,
          message: '域名不能为空',
          trigger: 'blur'
        }"
      >
        <el-input v-model="domain.value" class="w-400 mr-10"></el-input>
        <el-button icon="el-icon-delete" type="danger" @click.prevent="removeDomain(domain)"></el-button>
      </el-form-item>

      <el-form-item label="行动号召" prop="delivery">
        <el-switch v-model="ruleForm.delivery" class="w-400"></el-switch>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
    <material-box
      :visible="materialBox"
      :img-count="1"
      name="userPortrait"
      :image-list="[]"
      :attribute="{
        key: 'card',
        value: '卡片主图',
        count: 1,
        size: 1204,
        listType: 'image/jpg,image/jpeg,image/png',
        width: 108,
        height: 108
      }"
      @setMaterialBox="setMaterialBox"
      @submit="selectImg"
    />
    <!-- :project-id="projectId" -->
  </section>
</template>

<script>
import MaterialBox from '@/components/materialBox'
export default {
  components: {
    'material-box': MaterialBox
  },
  data() {
    return {
      paramsId: this.$route.params,
      ruleForm: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        domains: [
          {
            key: Date.now(),
            value: ''
          }
        ]
      },
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        region: [{ required: true, message: '请选择活动区域', trigger: 'change' }],
        date1: [{ type: 'date', required: true, message: '请选择日期', trigger: 'change' }],
        date2: [{ type: 'date', required: true, message: '请选择时间', trigger: 'change' }],
        type: [{ type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }],
        resource: [{ required: true, message: '请选择活动资源', trigger: 'change' }],
        desc: [{ required: true, message: '请填写活动形式', trigger: 'blur' }]
      }
    }
  },

  mounted() {
    this.getEditData()
  },

  methods: {
    async getEditData() {
      // const { code, data } =  await getData()
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    removeDomain(item) {
      var index = this.ruleForm.domains.indexOf(item)
      if (index !== -1) {
        this.ruleForm.domains.splice(index, 1)
      }
    },
    addDomain() {
      if (this.ruleForm.domains.length >= 10) {
        return this.$message.success('最多填写10个卖点')
      }
      this.ruleForm.domains.push({
        value: '',
        key: Date.now()
      })
    }
  }
}
</script>
