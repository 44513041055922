<template>
  <el-dialog :title="isEdit ? '编 辑' : '新 增'" width="45%" :visible.sync="visible" :before-close="close" label-position="right" :modal-append-to-body="false" :close-on-press-escape="false" :close-on-click-modal="false">
    <el-form ref="ent" :model="ent" :rules="rules" label-width="110px">
      <!-- <el-form-item label="" prop="logId">
        <el-input v-model="ent.logId" />
      </el-form-item> -->
      <el-form-item label="基木鱼id" prop="woodenFishId">
        <el-input v-model="ent.woodenFishId" />
      </el-form-item>
      <el-form-item label="分享账户id" prop="shareBaiduId">
        <el-input v-model="ent.shareBaiduId" />
      </el-form-item>
      <el-form-item label="分享投放账户" prop="shareAccountName">
        <el-input v-model="ent.shareAccountName" />
      </el-form-item>
      <el-form-item label="分享状态" prop="shareStatus">
        <el-input v-model="ent.shareStatus" />
      </el-form-item>
      <el-form-item label="落地页ID" prop="pageId">
        <el-input v-model="ent.pageId" />
      </el-form-item>
      <el-form-item label="落地页名称" prop="pageName">
        <el-input v-model="ent.pageName" />
      </el-form-item>
      <el-form-item label="逻辑删除" prop="deleted">
        <el-input v-model="ent.deleted" />
      </el-form-item>
      <el-form-item label="创建者" prop="createBy">
        <el-input v-model="ent.createBy" />
      </el-form-item>
      <el-form-item label="创建时间" prop="createTime">
        <el-input v-model="ent.createTime" />
      </el-form-item>
      <el-form-item label="更新者" prop="updateBy">
        <el-input v-model="ent.updateBy" />
      </el-form-item>
      <el-form-item label="更新时间" prop="updateTime">
        <el-input v-model="ent.updateTime" />
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="ent.remark" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button round @click="close">取消</el-button>
      <el-button type="primary" round @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { Add, Update } from '@/network/api/assetManagement/assetManagementBaiduWoodenFishLog'
import { AssetManagementBaiduWoodenFishLogEnt, formRules } from './object'

export default {
  inject: ['loadData'],
  data() {
    return {
      ent: new AssetManagementBaiduWoodenFishLogEnt(),
      rules: formRules,
      isEdit: false,
      visible: false,
      cityList: []
    }
  },
  methods: {
    submit() {
      this.$refs.ent.validate((validate) => {
        if (validate) {
          this.isEdit ? this.updateRow() : this.addRow()
        }
      })
    },
    addRow() {
      const req = { ...this.ent }
      Add(req).then((res) => {
        this.$message({
          message: '保存成功',
          type: 'success'
        })
        this.loadData()
        this.close()
      })
    },
    updateRow() {
      const req = { ...this.ent }
      Update(req).then((res) => {
        this.$message({
          message: '修改成功',
          type: 'success'
        })
        this.loadData()
        this.close()
      })
    },
    close() {
      this.visible = false
      this.$nextTick(() => {
        this.$refs.ent.resetFields()
      })
    },
    show(isEdit = false, ent) {
      this.ent = ent
      this.isEdit = isEdit
      this.visible = true
      this.$nextTick(() => {
        if (this.isEdit) {
          console.log('不能为空')
        }
      })
    }
  }
}
</script>
