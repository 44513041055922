/*
 * @Description: 数据
 * @Date: 2021-12-02 14:07:00
 * @LastEditTime: 2021-12-03 14:22:41
 */

export const age_min_list = [
  { key: 14, value: '14岁' },
  { key: 18, value: '18岁' },
  { key: 19, value: '19岁' },
  { key: 20, value: '20岁' },
  { key: 21, value: '21岁' },
  { key: 22, value: '22岁' },
  { key: 23, value: '23岁' },
  { key: 24, value: '24岁' },
  { key: 25, value: '25岁' },
  { key: 26, value: '26岁' },
  { key: 27, value: '27岁' },
  { key: 28, value: '28岁' },
  { key: 29, value: '29岁' },
  { key: 30, value: '30岁' },
  { key: 31, value: '31岁' },
  { key: 32, value: '32岁' },
  { key: 33, value: '33岁' },
  { key: 34, value: '34岁' },
  { key: 35, value: '35岁' },
  { key: 36, value: '36岁' },
  { key: 37, value: '37岁' },
  { key: 38, value: '38岁' },
  { key: 39, value: '39岁' },
  { key: 40, value: '40岁' },
  { key: 41, value: '41岁' },
  { key: 42, value: '42岁' },
  { key: 43, value: '43岁' },
  { key: 44, value: '44岁' },
  { key: 45, value: '45岁' },
  { key: 46, value: '46岁' },
  { key: 47, value: '47岁' },
  { key: 48, value: '48岁' },
  { key: 49, value: '49岁' },
  { key: 50, value: '50岁' },
  { key: 51, value: '51岁' },
  { key: 52, value: '52岁' },
  { key: 53, value: '53岁' },
  { key: 54, value: '54岁' },
  { key: 55, value: '55岁' },
  { key: 56, value: '56岁' },
  { key: 57, value: '57岁' },
  { key: 58, value: '58岁' },
  { key: 59, value: '59岁' },
  { key: 60, value: '60岁' },
  { key: 61, value: '61岁' },
  { key: 62, value: '62岁' },
  { key: 63, value: '63岁' },
  { key: 64, value: '64岁' },
  { key: 65, value: '65岁' },
  { key: 66, value: '66岁' },
  { key: 67, value: '66岁以上' }
]

export const age_max_list = [
  { key: 18, value: '18岁' },
  { key: 19, value: '19岁' },
  { key: 20, value: '20岁' },
  { key: 21, value: '21岁' },
  { key: 22, value: '22岁' },
  { key: 23, value: '23岁' },
  { key: 24, value: '24岁' },
  { key: 25, value: '25岁' },
  { key: 26, value: '26岁' },
  { key: 27, value: '27岁' },
  { key: 28, value: '28岁' },
  { key: 29, value: '29岁' },
  { key: 30, value: '30岁' },
  { key: 31, value: '31岁' },
  { key: 32, value: '32岁' },
  { key: 33, value: '33岁' },
  { key: 34, value: '34岁' },
  { key: 35, value: '35岁' },
  { key: 36, value: '36岁' },
  { key: 37, value: '37岁' },
  { key: 38, value: '38岁' },
  { key: 39, value: '39岁' },
  { key: 40, value: '40岁' },
  { key: 41, value: '41岁' },
  { key: 42, value: '42岁' },
  { key: 43, value: '43岁' },
  { key: 44, value: '44岁' },
  { key: 45, value: '45岁' },
  { key: 46, value: '46岁' },
  { key: 47, value: '47岁' },
  { key: 48, value: '48岁' },
  { key: 49, value: '49岁' },
  { key: 50, value: '50岁' },
  { key: 51, value: '51岁' },
  { key: 52, value: '52岁' },
  { key: 53, value: '53岁' },
  { key: 54, value: '54岁' },
  { key: 55, value: '55岁' },
  { key: 56, value: '56岁' },
  { key: 57, value: '57岁' },
  { key: 58, value: '58岁' },
  { key: 59, value: '59岁' },
  { key: 60, value: '60岁' },
  { key: 61, value: '61岁' },
  { key: 62, value: '62岁' },
  { key: 63, value: '63岁' },
  { key: 64, value: '64岁' },
  { key: 65, value: '65岁' },
  { key: 66, value: '66岁' },
  { key: 67, value: '66岁以上' }
]

export const residential_community_price_min = [
  { key: 1, value: '1元/m²' },
  { key: 1000, value: '1000元/m²' },
  { key: 2000, value: '2000元/m²' },
  { key: 3000, value: '3000元/m²' },
  { key: 4000, value: '4000元/m²' },
  { key: 5000, value: '5000元/m²' },
  { key: 6000, value: '6000元/m²' },
  { key: 7000, value: '7000元/m²' },
  { key: 8000, value: '8000元/m²' },
  { key: 9000, value: '9000元/m²' },
  { key: 10000, value: '10000元/m²' },
  { key: 20000, value: '20000元/m²' },
  { key: 30000, value: '30000元/m²' },
  { key: 40000, value: '40000元/m²' },
  { key: 50000, value: '50000元/m²' },
  { key: 60000, value: '60000元/m²' },
  { key: 70000, value: '70000元/m²' },
  { key: 80000, value: '80000元/m²' },
  { key: 90000, value: '90000元/m²' },
  { key: 100000, value: '100000元/m²' }
]

export const residential_community_price_max = [
  { key: 1000, value: '1000元/m²' },
  { key: 2000, value: '2000元/m²' },
  { key: 3000, value: '3000元/m²' },
  { key: 4000, value: '4000元/m²' },
  { key: 5000, value: '5000元/m²' },
  { key: 6000, value: '6000元/m²' },
  { key: 7000, value: '7000元/m²' },
  { key: 8000, value: '8000元/m²' },
  { key: 9000, value: '9000元/m²' },
  { key: 10000, value: '10000元/m²' },
  { key: 20000, value: '20000元/m²' },
  { key: 30000, value: '30000元/m²' },
  { key: 40000, value: '40000元/m²' },
  { key: 50000, value: '50000元/m²' },
  { key: 60000, value: '60000元/m²' },
  { key: 70000, value: '70000元/m²' },
  { key: 80000, value: '80000元/m²' },
  { key: 90000, value: '90000元/m²' },
  { key: 100000, value: '100000元/m²' },
  { key: 999999999, value: '100000元以上/m²' }
]

export const temperature_min = [
  { key: 223, value: '-50 ℃' },
  { key: 224, value: '-49 ℃' },
  { key: 225, value: '-48 ℃' },
  { key: 226, value: '-47 ℃' },
  { key: 227, value: '-46 ℃' },
  { key: 228, value: '-45 ℃' },
  { key: 229, value: '-44 ℃' },
  { key: 230, value: '-43 ℃' },
  { key: 231, value: '-42 ℃' },
  { key: 232, value: '-41 ℃' },
  { key: 233, value: '-40 ℃' },
  { key: 234, value: '-39 ℃' },
  { key: 235, value: '-38 ℃' },
  { key: 236, value: '-37 ℃' },
  { key: 237, value: '-36 ℃' },
  { key: 238, value: '-35 ℃' },
  { key: 239, value: '-34 ℃' },
  { key: 240, value: '-33 ℃' },
  { key: 241, value: '-32 ℃' },
  { key: 242, value: '-31 ℃' },
  { key: 243, value: '-30 ℃' },
  { key: 244, value: '-29 ℃' },
  { key: 245, value: '-28 ℃' },
  { key: 246, value: '-27 ℃' },
  { key: 247, value: '-26 ℃' },
  { key: 248, value: '-25 ℃' },
  { key: 249, value: '-24 ℃' },
  { key: 250, value: '-23 ℃' },
  { key: 251, value: '-22 ℃' },
  { key: 252, value: '-21 ℃' },
  { key: 253, value: '-20 ℃' },
  { key: 254, value: '-19 ℃' },
  { key: 255, value: '-18 ℃' },
  { key: 256, value: '-17 ℃' },
  { key: 257, value: '-16 ℃' },
  { key: 258, value: '-15 ℃' },
  { key: 259, value: '-14 ℃' },
  { key: 260, value: '-13 ℃' },
  { key: 261, value: '-12 ℃' },
  { key: 262, value: '-11 ℃' },
  { key: 263, value: '-10 ℃' },
  { key: 264, value: '-9 ℃' },
  { key: 265, value: '-8 ℃' },
  { key: 266, value: '-7 ℃' },
  { key: 267, value: '-6 ℃' },
  { key: 268, value: '-5 ℃' },
  { key: 269, value: '-4 ℃' },
  { key: 270, value: '-3 ℃' },
  { key: 271, value: '-2 ℃' },
  { key: 272, value: '-1 ℃' },
  { key: 273, value: '0 ℃' },
  { key: 274, value: '1 ℃' },
  { key: 275, value: '2 ℃' },
  { key: 276, value: '3 ℃' },
  { key: 277, value: '4 ℃' },
  { key: 278, value: '5 ℃' },
  { key: 279, value: '6 ℃' },
  { key: 280, value: '7 ℃' },
  { key: 281, value: '8 ℃' },
  { key: 282, value: '9 ℃' },
  { key: 283, value: '10 ℃' },
  { key: 284, value: '11 ℃' },
  { key: 285, value: '12 ℃' },
  { key: 286, value: '13 ℃' },
  { key: 287, value: '14 ℃' },
  { key: 288, value: '15 ℃' },
  { key: 289, value: '16 ℃' },
  { key: 290, value: '17 ℃' },
  { key: 291, value: '18 ℃' },
  { key: 292, value: '19 ℃' },
  { key: 293, value: '20 ℃' },
  { key: 294, value: '21 ℃' },
  { key: 295, value: '22 ℃' },
  { key: 296, value: '23 ℃' },
  { key: 297, value: '24 ℃' },
  { key: 298, value: '25 ℃' },
  { key: 299, value: '26 ℃' },
  { key: 300, value: '27 ℃' },
  { key: 301, value: '28 ℃' },
  { key: 302, value: '29 ℃' },
  { key: 303, value: '30 ℃' },
  { key: 304, value: '31 ℃' },
  { key: 305, value: '32 ℃' },
  { key: 306, value: '33 ℃' },
  { key: 307, value: '34 ℃' },
  { key: 308, value: '35 ℃' },
  { key: 309, value: '36 ℃' },
  { key: 310, value: '37 ℃' },
  { key: 311, value: '38 ℃' },
  { key: 312, value: '39 ℃' },
  { key: 313, value: '40 ℃' },
  { key: 314, value: '41 ℃' },
  { key: 315, value: '42 ℃' },
  { key: 316, value: '43 ℃' },
  { key: 317, value: '44 ℃' },
  { key: 318, value: '45 ℃' },
  { key: 319, value: '46 ℃' },
  { key: 320, value: '47 ℃' },
  { key: 321, value: '48 ℃' },
  { key: 322, value: '49 ℃' },
  { key: 323, value: '50 ℃' }
]
export const temperature_max = [
  { key: 223, value: '-50 ℃' },
  { key: 224, value: '-49 ℃' },
  { key: 225, value: '-48 ℃' },
  { key: 226, value: '-47 ℃' },
  { key: 227, value: '-46 ℃' },
  { key: 228, value: '-45 ℃' },
  { key: 229, value: '-44 ℃' },
  { key: 230, value: '-43 ℃' },
  { key: 231, value: '-42 ℃' },
  { key: 232, value: '-41 ℃' },
  { key: 233, value: '-40 ℃' },
  { key: 234, value: '-39 ℃' },
  { key: 235, value: '-38 ℃' },
  { key: 236, value: '-37 ℃' },
  { key: 237, value: '-36 ℃' },
  { key: 238, value: '-35 ℃' },
  { key: 239, value: '-34 ℃' },
  { key: 240, value: '-33 ℃' },
  { key: 241, value: '-32 ℃' },
  { key: 242, value: '-31 ℃' },
  { key: 243, value: '-30 ℃' },
  { key: 244, value: '-29 ℃' },
  { key: 245, value: '-28 ℃' },
  { key: 246, value: '-27 ℃' },
  { key: 247, value: '-26 ℃' },
  { key: 248, value: '-25 ℃' },
  { key: 249, value: '-24 ℃' },
  { key: 250, value: '-23 ℃' },
  { key: 251, value: '-22 ℃' },
  { key: 252, value: '-21 ℃' },
  { key: 253, value: '-20 ℃' },
  { key: 254, value: '-19 ℃' },
  { key: 255, value: '-18 ℃' },
  { key: 256, value: '-17 ℃' },
  { key: 257, value: '-16 ℃' },
  { key: 258, value: '-15 ℃' },
  { key: 259, value: '-14 ℃' },
  { key: 260, value: '-13 ℃' },
  { key: 261, value: '-12 ℃' },
  { key: 262, value: '-11 ℃' },
  { key: 263, value: '-10 ℃' },
  { key: 264, value: '-9 ℃' },
  { key: 265, value: '-8 ℃' },
  { key: 266, value: '-7 ℃' },
  { key: 267, value: '-6 ℃' },
  { key: 268, value: '-5 ℃' },
  { key: 269, value: '-4 ℃' },
  { key: 270, value: '-3 ℃' },
  { key: 271, value: '-2 ℃' },
  { key: 272, value: '-1 ℃' },
  { key: 273, value: '0 ℃' },
  { key: 274, value: '1 ℃' },
  { key: 275, value: '2 ℃' },
  { key: 276, value: '3 ℃' },
  { key: 277, value: '4 ℃' },
  { key: 278, value: '5 ℃' },
  { key: 279, value: '6 ℃' },
  { key: 280, value: '7 ℃' },
  { key: 281, value: '8 ℃' },
  { key: 282, value: '9 ℃' },
  { key: 283, value: '10 ℃' },
  { key: 284, value: '11 ℃' },
  { key: 285, value: '12 ℃' },
  { key: 286, value: '13 ℃' },
  { key: 287, value: '14 ℃' },
  { key: 288, value: '15 ℃' },
  { key: 289, value: '16 ℃' },
  { key: 290, value: '17 ℃' },
  { key: 291, value: '18 ℃' },
  { key: 292, value: '19 ℃' },
  { key: 293, value: '20 ℃' },
  { key: 294, value: '21 ℃' },
  { key: 295, value: '22 ℃' },
  { key: 296, value: '23 ℃' },
  { key: 297, value: '24 ℃' },
  { key: 298, value: '25 ℃' },
  { key: 299, value: '26 ℃' },
  { key: 300, value: '27 ℃' },
  { key: 301, value: '28 ℃' },
  { key: 302, value: '29 ℃' },
  { key: 303, value: '30 ℃' },
  { key: 304, value: '31 ℃' },
  { key: 305, value: '32 ℃' },
  { key: 306, value: '33 ℃' },
  { key: 307, value: '34 ℃' },
  { key: 308, value: '35 ℃' },
  { key: 309, value: '36 ℃' },
  { key: 310, value: '37 ℃' },
  { key: 311, value: '38 ℃' },
  { key: 312, value: '39 ℃' },
  { key: 313, value: '40 ℃' },
  { key: 314, value: '41 ℃' },
  { key: 315, value: '42 ℃' },
  { key: 316, value: '43 ℃' },
  { key: 317, value: '44 ℃' },
  { key: 318, value: '45 ℃' },
  { key: 319, value: '46 ℃' },
  { key: 320, value: '47 ℃' },
  { key: 321, value: '48 ℃' },
  { key: 322, value: '49 ℃' },
  { key: 323, value: '50 ℃' }
]
