<!--
 * @Description: 资产事件列表
 * @Date: 2021-11-01 09:46:13
 * @LastEditTime: 2022-02-21 09:39:33
-->
<template>
  <section>
    <div>
      <el-form
        ref="ruleForm"
        :inline="true"
        class="search-form search-form-4"
        :model="ruleData"
      >
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item
              prop="housekeeperId"
              label="管家账号"
            >
              <el-select
                v-model="ruleData.housekeeperId"
                style="width:300px"
                placeholder="请选择管家账号"
                @change="handleSelectHouseKeeper"
              >
                <el-option
                  v-for="(item, index) in accountHugeHousekeeperENTs"
                  :key="index"
                  :label="item.accountName"
                  :value="item.accountId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item
              prop="advId"
              label="投放账户"
            >
              <el-select
                v-model="ruleData.advId"
                :disabled="!ruleData.housekeeperId"
                placeholder="请选择投放账户"
                filterable
                multiple
              >
                <el-option
                  v-for="(item, index) in accountHugeAdvENTLists[accountHugeHousekeeperENTsId]"
                  :key="index"
                  :label="item.accountName"
                  :value="item.accountId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item
              prop="assetName"
              label="资产名称"
            >
              <el-input
                v-model="ruleData.assetName"
                placeholder="请输入资产名称"
              />
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item>
              <el-button
                class="btn"
                type="primary"
                @click="getPage"
              > 查询 </el-button>
              <el-button
                class="btn"
                @click="resetForm"
              > 重置 </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="assets">
      <section class="assets-list">
        <div class="assets-list-name">
          <p>资产列表<el-button
            style="margin-left: 20px;"
            type="text"
            icon="el-icon-refresh"
            @click="getPage"
          ></el-button></p>
        </div>
        <ul>
          <li
            v-for="item in assetsList"
            :key="item.assetId"
            :class="{ 'assets-list-item': true, active: assetId === item.assetId }"
            @click="selectAssetsId(item.assetId)"
          >
            <el-row
              type="flex"
              justify="space-between"
            >
              <div>
                <p>名称: {{ item.assetName }}</p>
              </div>
              <div>
                {{ assetTypeMapping[item.assetType] || '-' }}
              </div>
            </el-row>
            <p class="id">ID: {{ item.assetId }}</p>
          </li>
        </ul>
      </section>
      <section class="assets-detail">
        <div class="assets-detail-btn">
          <!-- <el-button :disabled="!(assetSelects.length > 0)">批量激活</el-button> -->
          <el-button
            type="primary"
            @click="syncFun"
          >同步事件</el-button>
        </div>
        <div
          v-if="assetId !== null"
          class="mt-10"
        >
          <TablePane
            :data-source="dataSource"
            @getPage="getEventPage"
          > </TablePane>
        </div>
        <el-empty
          v-else
          description="请先选择资源"
        ></el-empty>
      </section>
    </div>

    <!-- 同步事件管理 -->
    <el-dialog
      title="同步事件管理"
      :visible.sync="isSync"
      width="500px"
      :modal-append-to-body="false"
      @close="resetSyncForm"
    >
      <span>
        <el-form
          ref="syncFormRef"
          :model="account"
          label-width="100px"
          :rules="rules"
        >
          <el-form-item
            label="管家账号"
            prop="accountId"
          >
            <el-select
              v-model="account.accountId"
              style="width:300px"
              @change="handleSelectHouseKeeper"
            >
              <el-option
                v-for="(item, index) in accountHugeHousekeeperENTs"
                :key="index"
                :label="item.accountName"
                :value="item.accountId"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label="投放账户"
            prop="GGZaccountId"
          >
            <el-select
              v-model="account.GGZaccountId"
              filterable
              style="width:300px"
            >
              <el-option
                v-for="(item, index) in accountHugeAdvENTLists[accountHugeHousekeeperENTsId]"
                :key="index"
                :label="item.accountName"
                :value="item.accountId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </span>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="isSync = false">取 消</el-button>
        <el-button
          type="primary"
          @click="sync"
        >确认同步</el-button>
      </span>
    </el-dialog>

    <!-- 事件激活 -->
    <el-dialog
      title="事件激活"
      :visible.sync="isActivation"
      width="500px"
      :modal-append-to-body="false"
    >
      <div style="text-align: center">
        <div style="width: 100%;  display: flex; align-item: center">
          <el-tooltip
            :content="activationUrl"
            placement="top"
          >
            <h4
              class="text-ellipsis"
              style="width: 500px;margin-top: 16px"
            >落地页链接: {{ activationUrl }}</h4>
          </el-tooltip>
          <el-button
            type="text"
            class="ldy-link"
            :data-clipboard-text="activationUrl"
            @click="copyText"
          >复制</el-button>
        </div>
        <p class="tip mt-10">注：请复制以上“落地页链接”，并在巨量平台对应资产事件联调中使用</p>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="activation"
        >确认</el-button>
      </span>
    </el-dialog>

    <!-- 事件激活 -->
    <el-dialog
      title="同步事件管理"
      :visible.sync="synchronizationVisible"
      width="500px"
      :modal-append-to-body="false"
    >
      <div style="display: flex; justify-content: center; height: 200px;">
        <el-steps
          direction="vertical"
          :active="3"
          finish-status="success"
        >
          <el-step title="获取资产与事件"></el-step>
          <el-step title="同步资产与事件"></el-step>
          <el-step :title="`同步完成(新增${synchronizationSize}个)`"></el-step>
        </el-steps>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="synchronizationVisible = false"
        >确认</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import getProjectAndProduct from '@/mixin/account.js'
import TablePane from '@/components/tablePane.vue'
import { Page, Synchronization } from '@/network/api/huge/assetManagementHugeAsset.js'
import { EventPage } from '@/network/api/huge/assetManagementHugeCreatedEvent.js'
import Clipboard from 'clipboard'
// import elFormSearch from '@/mixin/el-form-search.js'

export default {
  components: {
    TablePane
  },
  mixins: [getProjectAndProduct],
  data() {
    return {
      ruleData: {
        housekeeperId: '',
        advId: '',
        assetName: ''
      },
      assetId: null,
      assetsList: [],
      assetSelects: [],
      isSync: false, // 批量激活visible
      account: {
        accountId: '',
        GGZaccountId: ''
      }, // 激活选择的账户
      assetTypeMapping: {
        'THIRD_EXTERNAL': '三方落地页',
        'MINI_PROGRAME': '小程序',
        'TETRIS_EXTERNAL': '橙子建站'
      },
      isActivation: false, // 激活
      synchronizationVisible: false,
      synchronizationSize: 0,
      activationUrl: '123',
      dataSource: {
        data: [], // 表格数据
        cols: [
          {
            label: '事件名称',
            prop: 'assetName'
          },
          {
            label: '优化目标',
            prop: 'eventCnName'
          },
          {
            label: '深度优化目标',
            render: function(record) {
              return '-'
            }
          },
          {
            label: '状态',
            render: function(record) {
              return record.debuggingStatus === 'Inactive' ? '未激活' : '已激活'
            }
          },
          {
            label: '管理账号',
            prop: 'housekeeperAccountName'
          },
          {
            label: '投放账户',
            prop: 'advAccountName'
          }
        ], // 表格的列数据
        isIndex: true, // 列表序号
        loading: true, // loading
        isOperation: true,
        isSelection: false,
        pageData: {
          total: 0 // 总条数
        },
        handleSelectionChange: (val) => {
          this.assetSelects = val
        }, // 点击行选中多选返回选中数组
        operation: {
          // 表格有操作列时设置
          label: '操作', // 列名
          width: '150', // 根据实际情况给宽度
          data: [
            {
              label: '激活', // 操作名称
              type: 'text', // 按钮类型
              // showLabel: 'debuggingStatus',
              // showValue: ['Inactive'],
              handleRow: (index, row) => {
                const { assetId, assetName, housekeeperAccountName, advAccountName, eventCnName, eventType } = row
                let url = `?assetId=${assetId}&assetName=${assetName}&housekeeperAccountName=${housekeeperAccountName}&advAccountName=${advAccountName}&eventCnName=${eventCnName}&eventType=${eventType}`
                this.activationUrl = 'https://oes.suohcm123.com/s/asset/index.html' + url
                this.isActivation = true
              } // 自定义事件
            }
            // {
            //   label: '-', // 操作名称
            //   type: 'text', // 按钮类型
            //   showLabel: 'debuggingStatus',
            //   showValue: ['Active'],
            //   handleRow: function() {
            //     this.isActivation = true
            //   } // 自定义事件
            // }
          ]
        }
      }
    }
  },
  mounted() {
    this.getPage()
    // this.getEventPage()
  },
  methods: {
    /**
     * @description: 复制落地页地址
     */
    copyText() {
      var clipboard = new Clipboard('.ldy-link')
      clipboard.on('success', (e) => {
        this.$message.success('复制成功!')
        //  释放内存
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message.error('该浏览器不支持复制')
        // 释放内存
        clipboard.destroy()
      })
    },

    syncFun() {
      this.isSync = true
      this.account.accountId = this.accountHugeHousekeeperENTs.filter((f) => f.housekeeperId === this.ruleData.housekeeperId)[0].accountId
      this.account.GGZaccountId = this.accountHugeAdvENTLists[this.accountHugeHousekeeperENTsId].filter((f) => this.ruleData.advId.includes(f.advId))[0].accountId
    },
    /**
     * @description: 获取资产列表
     */
    getPage() {
      let ruleData = { ...this.ruleData }
      ruleData.advId = ruleData.advId + ''
      Page(ruleData).then(({ code, data }) => {
        if (code === 200) {
          if (!data.records.length) {
            this.assetsList = []
            this.assetId = null
            this.dataSource.data = []
            this.dataSource.pageData.total = 0
          } else {
            this.assetsList = data.records
            this.assetId = data.records[0].assetId
            this.getEventPage()
          }
        }
      })
    },
    /**
     * @description: 获取列表
     * @param {*} params
     */
    getEventPage(params = { pageNum: 1, pageSize: 10 }) {
      this.dataSource.loading = true
      // ...this.ruleData,
      params.assetId = this.assetId

      EventPage({ ...params }).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          this.dataSource.data = records
          this.dataSource.pageData.total = total
          this.dataSource.loading = false
        }
      })
    },

    selectAssetsId(id) {
      this.assetId = id
      this.getEventPage()
    },
    /**
     * @description: 重置
     */
    resetForm() {
      this.$refs['ruleForm'].resetFields()
      Object.keys(this.ruleData).forEach((key) => {
        this.ruleData[key] = ''
      })
      this.getPage()
    },

    /**
     * @description: 同步事件管理
     */
    sync() {
      Synchronization(this.account).then(({ code, data }) => {
        if (code === 200) {
          this.account = {
            accountId: '',
            GGZaccountId: []
          }
          this.resetSyncForm()
          this.synchronizationVisible = true
          this.synchronizationSize = data.synchronizationSize
          this.$message.success('同步成功!')
          this.getPage()
        }
      })
    },
    /**
     * @description: 事件激活
     */
    activation() {
      this.isActivation = false
    },
    /**
     * @description: 重置同步框
     */
    resetSyncForm() {
      this.$refs['syncFormRef'].resetFields()
      this.isSync = false
    },

    handleSelectHouseKeeper(hoursekeeperId) {
      this.accountHugeHousekeeperENTsId = hoursekeeperId
      this.account.GGZaccountId = null
    }
  }
}
</script>

<style lang="scss" scoped>
p,
ul {
  padding: 0;
  margin: 0;
}

.assets {
  display: flex;
  width: 100%;
}
.assets-list {
  width: 250px;
  height: 720px;
  border-radius: 10px;
  background: #fafafa;
  box-shadow: 0 0 0 2px #f2f2f2;
  border: 1px solid #f2f2f2;
  text-align: left;
  .assets-list-name {
    font-weight: 500;
    font-size: 18px;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    p {
      border-left: 3px solid #40a0ff9f;
      padding-left: 10px;
    }
  }
  ul {
    height: 700px;
    overflow: hidden;
    overflow-y: auto;
  }
  .assets-list-item {
    padding: 5px 10px 5px 10px;
    color: #5e5e5e;
    cursor: pointer;
    p {
      padding: 3px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 500;
    }
    .id {
      font-size: 14px;
      color: #ccc;
    }
    &.active {
      background: #40a0ffd5;
      color: #fff;
      transition: all 0.5s;
    }
  }
}
.assets-detail {
  flex: 1;
  margin-left: 20px;
  height: 700px;
  border-radius: 10px;
  background: #fafafa;
  box-shadow: 0 0 0 2px #f2f2f2;
  border: 1px solid #f2f2f2;
  text-align: left;
  padding: 10px;
  .assets-detail-btn {
    text-align: right;
    padding: 10px;
    border-bottom: 1px solid rgb(221, 220, 220);
  }
}
</style>
