var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.isEdit ? "编 辑" : "新 增",
        width: "45%",
        visible: _vm.visible,
        "before-close": _vm.close,
        "label-position": "right",
        "modal-append-to-body": false,
        "close-on-press-escape": false,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ent",
          attrs: { model: _vm.ent, rules: _vm.rules, "label-width": "110px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "分类名称", prop: "lableTypeName" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.ent.lableTypeName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "lableTypeName", $$v)
                  },
                  expression: "ent.lableTypeName",
                },
              }),
            ],
            1
          ),
          !_vm.isEdit
            ? _c(
                "el-form-item",
                { attrs: { label: "", prop: "labelName" } },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 19 } }, [
                            _c("p", [
                              _vm._v(
                                "输入标签名称多个标签请换行；不统计重复签名；失去光标自动去重"
                              ),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 5 } }, [
                            _c(
                              "p",
                              {
                                staticStyle: {
                                  color: "#67C23A",
                                  "font-weight": "600",
                                },
                              },
                              [_vm._v("标签数：" + _vm._s(_vm.addNum))]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 10, maxRows: 24 },
                    },
                    on: { input: _vm.textChange, blur: _vm.blurInput },
                    model: {
                      value: _vm.ent.labelName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ent, "labelName", $$v)
                      },
                      expression: "ent.labelName",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.isEdit
            ? _c(
                "el-form-item",
                { attrs: { label: "标签名称", prop: "labelName" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ent.labelName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ent, "labelName", $$v)
                      },
                      expression: "ent.labelName",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { attrs: { round: "" }, on: { click: _vm.close } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-popconfirm",
            {
              staticClass: "ml-10",
              attrs: {
                "confirm-button-text": "提交",
                "cancel-button-text": "取消",
                icon: "el-icon-info",
                "icon-color": "red",
                title: "确定提交？",
              },
              on: { confirm: _vm.submit },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { slot: "reference", type: "primary", round: "" },
                  slot: "reference",
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }