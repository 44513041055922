<!--
 * @Description: 跳转小程序编辑区
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2024-03-29 09:35:23
-->

<template>
  <section class="section" ref="mySection">
    <base-box name="base">
      <template slot="body">
        <el-form ref="baseForm" class="img-form-box" :model="detail" size="mini" :inline="true" :rules="rules"
          label-width="80px">
          <el-form-item label="按钮文案" prop="btnTitle">
            <el-input v-model="detail.btnTitle" style="width: 100%" maxlength="10" show-word-limit type="text"
              size="small" />
          </el-form-item>

          <el-form-item label="按钮大小" prop="btnSize">
            <el-radio-group v-model="detail.btnSize" size="small">
              <el-radio-button label="large">大</el-radio-button>
              <el-radio-button label="small">小</el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="字符样式" prop="btnFontSize">
            <div style="display: flex">
              <div class="mr-10">
                <el-input-number v-model="detail.btnFontSize" size="small" :min="1" :max="100"></el-input-number>
              </div>
              <div class="mr-10">
                <el-dropdown>
                  <div class="ldy-bg-color" :style="`background:${detail.btnFontColor}`"></div>
                  <el-dropdown-menu slot="dropdown">
                    <twitter-color :colors="detail.btnFontColor" style="margin-top: 10px"
                      @change="(value) => (detail.btnFontColor = value)" />
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div class="">
                <el-radio-group v-model="detail.btnFontType" size="small">
                  <el-radio-button label="normal">常规</el-radio-button>
                  <el-radio-button label="bold">加粗</el-radio-button>
                </el-radio-group>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="对齐方式" prop="textAlignment">
            <el-radio-group v-model="detail.textAlignment" size="small">
              <el-radio-button label="left">左对齐</el-radio-button>
              <el-radio-button label="center">居中对齐</el-radio-button>
              <el-radio-button label="right">右对齐</el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="边框颜色" prop="btnBorderColorTheme">
            <el-dropdown>
              <div class="ldy-bg-color" :style="`background:${detail.btnBorderColorTheme}`"></div>
              <el-dropdown-menu slot="dropdown">
                <twitter-color :colors="detail.btnBorderColorTheme" style="margin-top: 10px"
                  @change="(value) => (detail.btnBorderColorTheme = value)" />
              </el-dropdown-menu>
            </el-dropdown>
          </el-form-item>

          <el-form-item label="填充颜色" prop="btnBgColorTheme">
            <el-dropdown>
              <div class="ldy-bg-color" :style="`background:${detail.btnBgColorTheme}`"></div>
              <el-dropdown-menu slot="dropdown">
                <twitter-color :colors="detail.btnBgColorTheme" style="margin-top: 10px"
                  @change="(value) => (detail.btnBgColorTheme = value)" />
              </el-dropdown-menu>
            </el-dropdown>
          </el-form-item>

          <el-form-item label="对齐方式" prop="textAlignment">
            <el-radio-group v-model="detail.textAlignment" size="small">
              <el-radio-button label="left">左对齐</el-radio-button>
              <el-radio-button label="center">居中对齐</el-radio-button>
              <el-radio-button label="right">右对齐</el-radio-button>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="顶部距离" prop="marginTop">
            <el-slider v-model="detail.marginTop" show-input :max="maxMarginTop"></el-slider>
          </el-form-item>

          <el-form-item label="上边距" prop="paddingTop">
            <el-slider v-model="detail.paddingTop" show-input :max="100"></el-slider>
          </el-form-item>

          <el-form-item label="下边距" prop="paddingBottom">
            <el-slider v-model="detail.paddingBottom" show-input :max="100"></el-slider>
          </el-form-item>

          <el-form-item label="按钮位置" prop="btnPosition">
            <el-select v-model="detail.btnPosition" size="mini" style="width: 100%">
              <el-option label="自定义" :value="1"></el-option>
              <el-option label="始终置顶" :value="2"></el-option>
              <el-option label="始终底部" :value="3"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="链接类型" prop="linkType">
            <el-select v-model="detail.linkType" size="mini" placeholder="请选择链接类型" style="width: 100%">
              <el-option label="普通链接" :value="1"></el-option>
              <el-option label="获客助手链接" :value="2"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="跳转链接" prop="link">
            <el-input v-model="detail.link" size="mini" type="textarea" :rows="3" resize="none" placeholder="请输入跳转链接"
              style="width: 100%;" maxlength="500" show-word-limit>
            </el-input>
          </el-form-item>

          <template v-if="detail.linkType == 1">
            <el-form-item label="平台" prop="platformId">
              <el-select v-model="detail.platformId" placeholder="请选择平台" size="mini" style="width: 100%" filterable>
                <el-option v-for="(item, index) in platforms" :key="index" :label="item.dictLabel"
                  :value="item.dictValue"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="关联内容" prop="relationContent">
              <el-input v-model="detail.relationContent" size="mini" placeholder="请输入投放平台相关ID" style="width: 100%;"
                maxlength="30" show-word-limit>
              </el-input>
            </el-form-item>
          </template>
        </el-form>
      </template>
    </base-box>
  </section>
</template>

<script>
import BaseBox from './box.vue'
import Twitter from '@/components/twitterColor.vue'
import { pathValidator } from '@/common/utils/validate'
import { mapState } from 'vuex'
export default {
  components: {
    'base-box': BaseBox,
    'twitter-color': Twitter
  },
  props: {
    detail: {
      type: Object,
      default: () => { }
    },
    platforms: {
      type: Array,
      default: () => []
    }
  },
  data() {
    var urlValidate = (rule, value, callback) => {
      if (value && !pathValidator(value)) {
        callback(new Error('请检查URL是否正确'))
      } else {
        callback()
      }
    }
    return {
      rules: {
        btnTitle: [{ required: true, message: '请输入按钮文案', trigger: 'blur' }],
        link: [{ required: true, message: '请输入跳转链接', trigger: 'blur' }, { validator: urlValidate, trigger: 'blur' }]
      }
    }
  },
  computed: {
    ...mapState({
      marginTop: state => state.landPage.marginTop
    }),
    maxMarginTop() {
      let { paddingTop, paddingBottom } = this.detail
      console.log(this.marginTop)
      return this.marginTop > 100 ? this.marginTop - paddingTop - paddingBottom - 46 : this.marginTop
    }
  },

  methods: {
    btnTitle(value) {
      this.detail.btnTitle = this.nbsp2Space(value)
    },

    nbsp2Space(str) {
      var arrEntities = { lt: '<', gt: '>', nbsp: ' ', amp: '&', quot: '"' }
      return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, function (all, t) {
        return arrEntities[t]
      })
    },

    validateForm() {
      let flag = true
      this.$refs.baseForm.validate(valid => {
        flag = valid
      })

      return flag
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}

.update {
  width: 130px !important;
  height: 130px;
  line-height: 130px;
  text-align: center;
  border: 1px solid #edecf2;
  background-color: #fbfafd;
  border-radius: 3px;
  cursor: pointer;
  font-size: 30px;
  color: #7d7d7d;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .avatar {
    width: 130px;
  }
}

.image-updata {
  display: flex !important;
  align-items: flex-start !important;
}

.ldy-bg-color {
  width: 28px;
  height: 28px;
  border: 5px solid #bfc2c7;
  border-radius: 4px;
}

.setting {
  display: flex;
  align-items: flex-start !important;
}

.section {
  height: 100%;

  .w-100 {
    font-size: 12px;
    margin-right: 10px;
  }
}

.url-list {
  li {
    display: flex;

    div {
      display: flex;
    }

    .url {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
</style>
