var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "material-details-dialog",
      attrs: {
        title: "素材详情",
        visible: _vm.show,
        "append-to-body": true,
        width: "730px",
        top: "20vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.onCloseDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                data: _vm.tableData,
                height: "300px",
                stripe: "",
                border: true,
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "素材人员", align: "center", prop: "userName" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "完成数量",
                  align: "center",
                  prop: "completionNum",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "完成情况",
                  align: "center",
                  prop: "completionStatus",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "时间", align: "center", prop: "createTime" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.isDelete
                          ? _c("el-button", {
                              attrs: { type: "text", icon: "el-icon-delete" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDelete(
                                    row.toolPeoductAssetInfoId
                                  )
                                },
                              },
                            })
                          : _c("span", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination-container" },
            [
              _c("c-pagination", {
                attrs: {
                  "page-size": _vm.pageSize,
                  "page-no": _vm.pageNum,
                  "pager-count": 5,
                  total: _vm.total,
                  tools: "total, prev, pager, next",
                  "get-data-fun": _vm.getTableData,
                },
                on: {
                  "update:pageSize": function ($event) {
                    _vm.pageSize = $event
                  },
                  "update:page-size": function ($event) {
                    _vm.pageSize = $event
                  },
                  "update:pageNo": function ($event) {
                    _vm.pageNum = $event
                  },
                  "update:page-no": function ($event) {
                    _vm.pageNum = $event
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { "margin-top": "20px" },
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                inline: false,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "完成数量", prop: "completionNum" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      min: 0,
                      max: 999,
                      "controls-position": "right",
                      placeholder: "请输入完成数量",
                    },
                    model: {
                      value: _vm.form.completionNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "completionNum", $$v)
                      },
                      expression: "form.completionNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "完成情况", prop: "completionStatus" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      placeholder: "请输入完成情况",
                      maxlength: "30",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.form.completionStatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "completionStatus", $$v)
                      },
                      expression: "form.completionStatus",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "推荐卡点", prop: "recommendCardPoints" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: {
                        filterable: "",
                        placeholder: "推荐收费卡点集数",
                      },
                      model: {
                        value: _vm.form.recommendCardPoints,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "recommendCardPoints", $$v)
                        },
                        expression: "form.recommendCardPoints",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "btn", on: { click: _vm.closeModal } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { type: "primary", disabled: _vm.saveLoading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("添加")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }