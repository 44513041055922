<!--
 * @Author: Zeng
 * @Date: 2023-06-20 11:59:57
 * @LastEditTime: 2023-06-26 09:49:56
-->
<template>
  <!--测试详情弹框-->
  <el-dialog class="product-details-dialog" title="产品起量详情" :visible.sync="show" :append-to-body="true" width="800px"
    top="20vh" @close="onCloseDialog">
    <div class="page-container">
      <el-table v-loading="loading" :stripe="true" :border="true" :data="tableData" height="300px">
        <el-table-column prop="channelName" label="配置名称" align="center"></el-table-column>

        <el-table-column prop="userName" label="投手" align="center"></el-table-column>

        <el-table-column prop="toolProductAdSpace" label="广告位" align="center"></el-table-column>

        <el-table-column label="首次起量日" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.inceptionTime || '-' }}</span>
          </template>
        </el-table-column>

        <el-table-column label="首次起量消耗" align="center">
          <template slot-scope="{ row }">
            {{ row.inceptionConsumer || '-' }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getProjectionData } from '@/network/api/toolManagement/api-product-test'
export default {
  name: 'AmountDetailsDialog',
  filters: {},
  data() {
    return {
      show: false,
      loading: false,
      toolProductId: '',
      tableData: []
    }
  },
  methods: {
    /* --------------------------- Actions --------------------------- */
    showModal(id) {
      this.show = true
      this.toolProductId = id
      this.getTableData()
    },

    // 监听 Dialog 弹框关闭
    onCloseDialog() {
    },

    // 关闭弹框1
    closeModal() {
      this.tableData = []
      this.loading = false
      this.show = false
    },

    getTableData() {
      this.loading = true
      getProjectionData(this.toolProductId, 1, 1000)
        .then(({ code, data }) => {
          if (code === 200) {
            if (data.records.length) {
              this.tableData = data.records.map(item => {
                return {
                  userName: item.toolProductLaunchPerson ? item.toolProductLaunchPerson.userName : '-',
                  toolProductAdSpace: item.toolProductLaunchSetting ? item.toolProductLaunchSetting.toolProductAdSpace : '-',
                  channelName: item.toolProductLaunchSetting ? item.toolProductLaunchSetting.channelName : '',
                  inceptionTime: item.toolProductFilterItem.inceptionTime,
                  inceptionConsumer: item.toolProductFilterItem.inceptionConsumer
                }
              })
            } else {
              this.tableData = []
            }

            console.log(this.tableData)
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
