var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "section" },
    [
      _c(
        "base-box",
        { attrs: { name: "slider" } },
        [
          _c("template", { slot: "body" }, [
            _c("div", [
              _c("p", { staticClass: "w-100" }, [_vm._v("图片数量：")]),
              _c(
                "div",
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "medium" },
                      on: { change: _vm.setImgCount },
                      model: {
                        value: _vm.detail.componentCount,
                        callback: function ($$v) {
                          _vm.$set(_vm.detail, "componentCount", $$v)
                        },
                        expression: "detail.componentCount",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "2" } }),
                      _c("el-radio-button", { attrs: { label: "3" } }),
                      _c("el-radio-button", { attrs: { label: "4" } }),
                      _c("el-radio-button", { attrs: { label: "5" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "image-updata" }, [
              _c("p", { staticClass: "w-100" }, [_vm._v("选择图片：")]),
              _c(
                "div",
                _vm._l(_vm.detail.slider, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "update",
                      on: { click: _vm.openMaterialBox },
                    },
                    [
                      item !== ""
                        ? _c("div", { staticClass: "image" }, [
                            _c("img", { attrs: { src: item } }),
                          ])
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                    ]
                  )
                }),
                0
              ),
            ]),
            _c(
              "p",
              {
                staticClass: "tip mt-10",
                staticStyle: { "margin-left": "70px" },
              },
              [
                _vm._v(" 暂不支持含有二维码的图片 "),
                _c("br"),
                _vm._v(" 图片格式：大小不超过300KB，不支持Gif "),
                _c("br"),
                _vm._v(" 图片尺寸：宽度750像素，高度不超过300-1125像素 "),
              ]
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }