var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-dynamic",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "组件类型", prop: "region" } },
            [
              _c(
                "el-select",
                {
                  staticClass: "w-400",
                  attrs: { placeholder: "请选择活动区域" },
                  model: {
                    value: _vm.ruleForm.region,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "region", $$v)
                    },
                    expression: "ruleForm.region",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: "区域一", value: "shanghai" },
                  }),
                  _c("el-option", {
                    attrs: { label: "区域二", value: "beijing" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "组件名称", prop: "name" } },
            [
              _c("el-input", {
                staticClass: "w-400",
                model: {
                  value: _vm.ruleForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "name", $$v)
                  },
                  expression: "ruleForm.name",
                },
              }),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "卡片主图", prop: "name" } }, [
            _c("div", { staticClass: "avatar-uploader" }, [
              _c(
                "div",
                { staticStyle: { "text-alian": "left", display: "flex" } },
                [
                  _c("i", {
                    staticClass: "el-icon-plus el-upload--picture-card",
                    staticStyle: {
                      "font-size": "28px",
                      color: "#8c939d",
                      "margin-top": "14px",
                      "text-align": "center",
                    },
                  }),
                ]
              ),
            ]),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "卡片标题", prop: "name" } },
            [
              _c("el-input", {
                staticClass: "w-400",
                model: {
                  value: _vm.ruleForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "name", $$v)
                  },
                  expression: "ruleForm.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "推广卖点", prop: "domains" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addDomain } },
                [
                  _vm._v(" 新增卖点 "),
                  _c(
                    "span",
                    {
                      staticClass: "ml-10",
                      style: {
                        color:
                          _vm.ruleForm.domains.length > 10 ? "red" : "#fff",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.ruleForm.domains.length) + " ")]
                  ),
                  _vm._v(" / 10 "),
                ]
              ),
            ],
            1
          ),
          _vm._l(_vm.ruleForm.domains, function (domain, index) {
            return _c(
              "el-form-item",
              {
                key: domain.key,
                attrs: {
                  label: "卖点" + (index + 1),
                  prop: "domains." + index + ".value",
                  rules: {
                    required: true,
                    message: "域名不能为空",
                    trigger: "blur",
                  },
                },
              },
              [
                _c("el-input", {
                  staticClass: "w-400 mr-10",
                  model: {
                    value: domain.value,
                    callback: function ($$v) {
                      _vm.$set(domain, "value", $$v)
                    },
                    expression: "domain.value",
                  },
                }),
                _c("el-button", {
                  attrs: { icon: "el-icon-delete", type: "danger" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.removeDomain(domain)
                    },
                  },
                }),
              ],
              1
            )
          }),
          _c(
            "el-form-item",
            { attrs: { label: "行动号召", prop: "delivery" } },
            [
              _c("el-switch", {
                staticClass: "w-400",
                model: {
                  value: _vm.ruleForm.delivery,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "delivery", $$v)
                  },
                  expression: "ruleForm.delivery",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v("立即创建")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.resetForm("ruleForm")
                    },
                  },
                },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("material-box", {
        attrs: {
          visible: _vm.materialBox,
          "img-count": 1,
          name: "userPortrait",
          "image-list": [],
          attribute: {
            key: "card",
            value: "卡片主图",
            count: 1,
            size: 1204,
            listType: "image/jpg,image/jpeg,image/png",
            width: 108,
            height: 108,
          },
        },
        on: { setMaterialBox: _vm.setMaterialBox, submit: _vm.selectImg },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }