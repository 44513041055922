var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "box",
    [
      _c("template", { slot: "title" }, [_vm._v("落地页信息")]),
      _c(
        "el-form-item",
        [
          _c(
            "template",
            { slot: "label" },
            [
              _vm._v(" 点击监测URL "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: "用于检测广告的点击效果。",
                    placement: "top",
                  },
                },
                [_c("i", { staticClass: "el-icon-question" })]
              ),
            ],
            1
          ),
          _c("el-input", {
            staticStyle: { width: "600px" },
            attrs: { maxlength: "1024" },
            model: {
              value: _vm.sizeForm.monitorUrl,
              callback: function ($$v) {
                _vm.$set(_vm.sizeForm, "monitorUrl", $$v)
              },
              expression: "sizeForm.monitorUrl",
            },
          }),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }