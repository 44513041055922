var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "base-box",
        { attrs: { name: "topSlider" } },
        [
          _c("template", { slot: "desc" }, [
            _vm._v(" 适用广告位：朋友圈信息流"),
            _c("br"),
            _vm._v(" 适用外层样式：常规广告"),
            _c("br"),
            _vm._v(
              " 创建广告时，仅选择了以上广告位与外层样式的广告可使用此推广页。广告外层将自动拉取顶部轮播图。 "
            ),
          ]),
          _c("template", { slot: "body" }, [
            _c("div", [
              _c("p", { staticClass: "font-13" }, [_vm._v("图片数量：")]),
              _c(
                "div",
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "medium" },
                      on: { change: _vm.setImgCount },
                      model: {
                        value: _vm.detail.componentCount,
                        callback: function ($$v) {
                          _vm.$set(_vm.detail, "componentCount", $$v)
                        },
                        expression: "detail.componentCount",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "3" } }),
                      _c("el-radio-button", { attrs: { label: "4" } }),
                      _c("el-radio-button", { attrs: { label: "6" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "image-updata" }, [
              _c("p", { staticClass: "font-13" }, [_vm._v("选择图片：")]),
              _c(
                "div",
                _vm._l(
                  _vm.detail.componentGroupList.componentGroup,
                  function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index.id,
                        staticClass: "update",
                        on: { click: _vm.setMaterialBox },
                      },
                      [
                        item.componentItem.pureImageUrl !== ""
                          ? _c("div", { staticClass: "image" }, [
                              _c("img", {
                                attrs: { src: item.componentItem.pureImageUrl },
                              }),
                            ])
                          : _c("i", {
                              staticClass: "el-icon-plus avatar-uploader-icon",
                            }),
                      ]
                    )
                  }
                ),
                0
              ),
            ]),
            _c(
              "p",
              {
                staticClass: "tip mt-10",
                staticStyle: { "margin-left": "70px" },
              },
              [
                _vm._v(" 暂不支持含有二维码的图片 "),
                _c("br"),
                _vm._v(" 图片格式：大小不超过300KB，不支持Gif "),
                _c("br"),
                _vm._v(" 图片尺寸：800*800像素、640*960像素、640*1136像素 "),
              ]
            ),
          ]),
        ],
        2
      ),
      _c("material-box", {
        attrs: {
          visible: _vm.materialBox_visible,
          "img-count": +_vm.detail.componentCount,
          "image-list": _vm.imageList,
          name: "ldy",
          "select-type": "multiple",
          attribute: {
            key: "topSlider",
            value: "顶部轮播图",
            count: +_vm.detail.componentCount,
            size: 300 * 1024,
            listType: "image/jpg,image/jpeg,image/png",
            width: [800, 640, 640],
            height: [800, 960, 1136],
          },
        },
        on: { setMaterialBox: _vm.setMaterialBox, submit: _vm.selectImg },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }