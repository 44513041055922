<template>
  <div>
    <el-form ref="search" :inline="true" :model="search" class="search-form search-form-4" @submit.native.prevent>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="账号名称" prop="onlinkUserName">
            <el-input v-model="search.onlinkUserName" placeholder="请输入账号名称" style="width: 100%"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="关联项目" prop="relationProjectId">
            <el-select v-model="search.relationProjectId" style="width: 100%" placeholder="请选择项目" @change="(value) => (selectProjectId = value)">
              <el-option v-for="item in projectList" :key="item.bsProjectId" :label="item.projectName" :value="item.bsProjectId" />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="关联产品" prop="relationProductId">
            <el-select v-model="search.relationProductId" style="width: 100%" placeholder="请选择产品" @change="(value) => (selectProductId = value)">
              <el-option label="不限" :value="0"></el-option>
              <el-option v-for="(itm, idx) in productListed" :key="idx" :label="itm.productName" :value="itm.bsProductId"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="投放人员" prop="launchUserName">
            <el-input v-model="search.launchUserName" placeholder="请输入投放人员" style="width: 100%;"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="投放状态" prop="putInStatus">
            <el-select v-model="search.putInStatus" style="width: 300px">
              <el-option label="全部" :value="null"></el-option>
              <el-option label="投放" :value="1"></el-option>
              <el-option label="停投" :value="0"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" round @click="loadData">查询</el-button>
            <el-button round @click="resetFormQuery('search')">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div class="justify-sb mb-15">
      <el-dropdown @command="handleCommand">
        <el-button type="primary"> 批量操作<i class="el-icon-arrow-down el-icon--right"></i> </el-button>
        <el-dropdown-menu slot="dropdown">
          <!-- :disabled=""  -->
          <el-dropdown-item command="0" :disabled="selectRow.length < 2 || !selectRow.every((item) => item.relationProjectId === selectRow[0].relationProjectId)">修改项目</el-dropdown-item>
          <el-dropdown-item command="1" :disabled="selectRow.length < 2 || !selectRow.every((item) => item.relationProjectId === selectRow[0].relationProjectId)">修改产品</el-dropdown-item>
          <el-dropdown-item command="2" :disabled="selectRow.length < 2 || !selectRow.every((item) => item.relationProjectId === selectRow[0].relationProjectId)">修改人员</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <div>
        <el-button class="ml-20" type="primary" round @click="visible = true">同步投放账号</el-button>
      </div>
    </div>
    <!-- <el-dialog title="选择账户主体" width="460px" :visible.sync="showAddVisible" :modal-append-to-body="false">
      <span>
        <label class="ml-20">账户主体: </label>
        <el-select v-model="appId" style="width:300px" placeholder="选择账户主体">
          <el-option v-for="item in subject" :key="item.appId" :label="item.subjectName" :value="item.appId" />
        </el-select>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showAddSubmit">确定</el-button>
      </span>
    </el-dialog> -->
    <div class="table-container">
      <TablePane :data-source="dataSource" @getPage="doLoadData"> </TablePane>
    </div>
    <!-- 编辑账户 -->
    <el-dialog title="编辑账户" :visible.sync="edit_visible" width="620px" :modal-append-to-body="false" destroy-on-close @open="showModal" @close="resetForm('ruleForm')">
      <el-form ref="ruleForm" :model="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="项目" prop="relationProjectId" :rules="{ required: true, message: '请选择项目', trigger: 'change' }">
          <el-select
            v-model="ruleForm.relationProjectId"
            style="width:400px"
            placeholder="请选择项目"
            @change="
              (value) => {
                selectProjectId = value
              }
            "
          >
            <el-option v-for="item in projectList" :key="item.bsProjectId" :label="item.projectName" :value="item.bsProjectId" />
          </el-select>
        </el-form-item>

        <el-form-item label="产品" prop="relationProductId">
          <el-select v-model="ruleForm.relationProductId" style="width:400px" placeholder="请选择产品" @change="(value) => (selectProductId = value)">
            <el-option label="不限" :value="0"></el-option>
            <el-option v-for="(itm, idx) in productListed" :key="idx" :label="itm.productName" :value="itm.bsProductId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="投放状态" prop="putInStatus" :rules="{ required: true, message: '请选择投放状态', trigger: 'change' }">
          <el-select v-model="ruleForm.putInStatus" style="width:400px" placeholder="请选择投放状态">
            <el-option label="投放" :value="1"></el-option>
            <el-option label="停投" :value="0"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="投放人员" prop="launch" :rules="{ required: true, message: '请选择投放人员', trigger: 'change' }">
          <el-select v-model="ruleForm.launch" value-key="key" filterable clearable placeholder="请选择投放人员" style="width: 400px;">
            <el-option v-for="(itm, idx) in userList" :key="idx" :label="itm.label" :value="`${itm.key}+${itm.label}`"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('ruleForm')">确定 </el-button>
        <el-button @click="resetForm('ruleForm')">取消</el-button>
      </span>
    </el-dialog>

    <!-- 批量修改 -->
    <el-dialog :title="commandType === '0' ? '关联项目批量修改' : commandType === '1' ? '关联产品批量修改' : '投放人员批量修改'" :visible.sync="editInfo_visible" width="620px" :modal-append-to-body="false" destroy-on-close @open="showModal">
      <el-form ref="ruleForm2" :model="ruleForm2" label-width="100px" class="demo-ruleForm">
        <div v-if="commandType === '0'">
          <el-form-item label="关联项目:" prop="relationProjectId" :rules="{ required: true, message: '请选择关联项目', trigger: 'change' }">
            <el-select v-model="ruleForm2.relationProjectId" style="width:400px" placeholder="请选择项目" @change="(value) => (selectProjectId = value)">
              <el-option v-for="item in projectList" :key="item.bsProjectId" :label="item.projectName" :value="item.bsProjectId" />
            </el-select>
          </el-form-item>
          <el-form-item label="产品:" prop="relationProductId">
            <el-select v-model="ruleForm2.relationProductId" style="width:400px" placeholder="请选择产品">
              <el-option label="不限" :value="0"></el-option>
              <el-option v-for="(itm, idx) in productListed" :key="idx" :label="itm.productName" :value="itm.bsProductId"></el-option>
            </el-select>
          </el-form-item>
        </div>

        <div v-else>
          <div v-if="commandType === '1'">
            <el-form-item label="项目:" prop="project_name">
              <span>{{ selectRow.length ? selectRow[0].relationProjectName : '' }}</span>
            </el-form-item>
            <el-form-item label="关联产品:">
              <el-select v-model="ruleForm2.relationProductId" style="width:400px" placeholder="请选择产品">
                <el-option label="不限" :value="0"></el-option>
                <el-option v-for="(itm, idx) in productListed" :key="idx" :label="itm.productName" :value="itm.bsProductId"></el-option>
              </el-select>
            </el-form-item>
          </div>

          <div v-if="commandType === '2'">
            <el-form-item label="投放人员:" prop="launch">
              <el-select v-model="ruleForm2.launch" value-key="key" filterable clearable placeholder="请选择投放人员" style="width: 100%;">
                <el-option v-for="(itm, idx) in userList" :key="idx" :label="itm.label" :value="`${itm.key}+${itm.label}`"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('ruleForm2')">修改</el-button>
        <!-- {{ productListed }} -->
        <el-button @click="resetForm('ruleForm2')">取消</el-button>
      </span>
    </el-dialog>

    <!-- 付费激活 -->
    <el-dialog title="付费激活" :visible.sync="plan_visible" width="620px" :modal-append-to-body="false" destroy-on-close @open="showModal" @close="resetForm('ruleForm')">
      <el-form ref="ruleForm3" :model="ruleForm3" label-width="100px" class="demo-ruleForm">
        <el-form-item label="账户类型:" prop="accountType" :rules="{ required: true, message: '请选择账户类型', trigger: 'change' }">
          <el-select v-model="ruleForm3.accountType" style="width:400px" placeholder="请选择账户类型">
            <el-option label="公众号加粉" :value="1"></el-option>
            <el-option label="快应用" :value="2"></el-option>
          </el-select>

        </el-form-item>
        <el-form-item label="计划ID:" prop="planId" :rules="{ required: true, message: '请输入计划ID', trigger: 'change' }">
          <el-input v-model="ruleForm3.planId" style="width:400px" placeholder="请输入计划ID" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('ruleForm3')">确定</el-button>

        <el-button @click="resetForm('ruleForm3')">取消</el-button>
      </span>
    </el-dialog>

    <OptionDialog :visible="visible" :row-account-id="rowAccountId" @setVisible="(value) => (visible = value)" />
  </div>
</template>

<script>
import { PageCommonComponent } from '@/common/mixin'
import TablePane from '@/components/tablePane.vue' // authorize adMarketingApiClientsSelect
import AccountBaidu from '@/mixin/account_baidu'
import { Page, accountsUpdate, accountsBatch, pActivation2 } from '@/network/api/account/kuaishouAdvAccount'

import OptionDialog from './OptionDialog'
import { getUser } from '@/network/api/user'

export default {
  name: 'AccountHugeHousekeeper',
  components: { OptionDialog, TablePane },
  mixins: [PageCommonComponent, AccountBaidu],
  provide: function() {
    return {
      loadData: this.loadData
    }
  },

  data() {
    return {
      selectRow: [], // 选中的列
      editInfo_visible: false,
      commandType: '0',
      // 编辑账户start
      edit_visible: false,
      userList: [], // 用户list
      ruleForm_id: 0,
      search: {
        onlinkUserName: null,
        relationProjectId: null,
        relationProductId: null,
        launchUserName: null
      },
      ruleForm: {
        relationProjectId: 0,
        relationProductId: null,
        putInStatus: null,
        launch: null
      },
      ruleForm2: {
        relationProjectId: null,
        relationProductId: 0,
        launch: null
      },
      // 编辑账户end
      plan_visible: false,
      ruleForm3: {
        planId: '',
        accountType: 1
      },
      appId: null,
      showAddVisible: false,
      loading: false,
      visible: false,
      rowAccountId: null,
      data: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      dataSource: {
        data: [],
        cols: [
          {
            label: '账户名称',
            prop: 'onlinkUserName'
          },
          {
            label: '账户主体',
            prop: 'onlinkCorporationName'
          },
          {
            label: '项目',
            prop: 'relationProjectName'
          },
          {
            label: '产品',
            prop: 'relationProductName'
          },
          {
            label: '投放状态',
            width: 100,
            render: (recode) => {
              return recode.putInStatus === 1 ? <el-tag type='success'>投放</el-tag> : <el-tag type='danger'>停投</el-tag>
            }
          },
          {
            label: '投放人员',
            prop: 'launchUserName'
          }
        ],
        isIndex: true, // 列表序号
        loading: false, // loading
        isOperation: true,
        isSelection: true,
        pageData: {
          total: 0 // 总条数
        },
        handleSelectionChange: (selection) => {
          this.selectRow = selection
        },
        operation: {
          // 表格有操作列时设置
          // 表格有操作列时设置
          label: '操作', // 列名
          width: 100, // 根据实际情况给宽度
          fixed: 'right',
          data: [
            {
              icon: 'el-icon-edit',
              tooltip: '编辑',
              handleRow: (index, row) => {
                this.ruleForm_id = row.accountLocalId
                this.selectProjectId = +row.relationProjectId
                // this.selectProductId = +row.relationProductId
                this.ruleForm = {
                  relationProjectId: row.relationProjectId,
                  relationProductId: row.relationProductId,
                  putInStatus: row.putInStatus,
                  launch: `${row.launchUserId}+${row.launchUserName}`
                }
                this.edit_visible = true
              }
            },
            {
              icon: 'el-icon-position',
              tooltip: '付费激活',
              handleRow: (index, row) => {
                this.plan_visible = true
                this.rowAccountId = row.accountLocalId
              }
            }
          ]
        }
      }
    }
  },
  mounted() {
    this.loadData()

    getUser().then((res) => {
      if (res.code === 200) {
        this.userList = res.data
      }
    })
  },

  methods: {
    loadData() {
      const searchForm = this.search
      this.doLoadData(searchForm)
    },
    doLoadData(searchForm) {
      const req = { pageNum: 1, pageSize: 10, ...searchForm }
      this.dataSource.loading = true
      Page(req).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          this.dataSource.data = records
          this.dataSource_data = records
          this.dataSource.pageData.total = total
          this.dataSource.pageData.pageNum = req.pageNum
          this.dataSource.loading = false
        }
      })
    },

    handleCommand(value) {
      this.selectProjectId = this.selectRow[0].relationProjectId
      this.ruleForm2.relationProductId = this.selectRow[0].relationProductId
      this.ruleForm2.launch = `${this.selectRow[0].launchUserId}+${this.selectRow[0].launchUserName}`
      this.commandType = value
      this.editInfo_visible = true
    },

    /**
     * @description: 提交数据
     * @param {*} formName ref
     */
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (formName === 'ruleForm') {
            let { relationProjectId, relationProductId, remark, accountName, accountPassword, apiKey, launch, putInStatus } = this.ruleForm
            let params = {
              accountName,
              accountPassword,
              apiKey,
              relationProjectId,
              relationProjectName: this.projectList.filter((item) => item.bsProjectId === relationProjectId)[0].projectName,
              relationProductId: relationProductId || 0,
              relationProductName: relationProductId !== 0 ? this.productListed.filter((item) => item.bsProductId === relationProductId)[0].productName : '不限',
              launchUserId: launch.split('+')[0],
              launchUserName: launch.split('+')[1],
              putInStatus,
              remark
            }
            if (this.edit_visible) {
              accountsUpdate({ accountLocalId: this.ruleForm_id, ...params }).then((res) => {
                if (res && res.code === 200) {
                  this.editInfo_visible = false
                  this.selectRow = []
                  this.$message.success('修改成功!')
                  this.resetFormQuery(formName)
                  this.edit_visible = false
                  this.id = 0
                  this.loadData()
                }
              })
            }
          }
        }
        if (formName === 'ruleForm2') {
          // 关联项目批量修改
          let { relationProjectId, relationProductId, launch } = this.ruleForm2
          if (this.commandType === '0') {
            let params = {
              accountLocalIds: this.selectRow.map((item) => item.accountLocalId),
              relationProjectId,
              relationProjectName: this.projectList.filter((item) => item.bsProjectId === relationProjectId)[0].projectName,
              relationProductId: relationProductId || 0,
              relationProductName: relationProductId !== 0 ? this.productListed.filter((item) => item.bsProductId === relationProductId)[0].productName : '不限'
            }
            accountsBatch(params).then((res) => {
              if (res && res.code === 200) {
                this.editInfo_visible = false
                this.selectRow = []
                this.$message.success('项目修改成功!')
                this.loadData()
              }
            })
          } else if (this.commandType === '1') {
            // 关联产品批量修改
            let params = {
              accountLocalIds: this.selectRow.map((item) => item.accountLocalId),
              // relationProjectId,
              // relationProjectName: this.projectList.filter((item) => item.bsProjectId === relationProjectId)[0].projectName,
              relationProductId: relationProductId || 0,
              relationProductName: relationProductId !== 0 ? this.productListed.filter((item) => item.bsProductId === relationProductId)[0].productName : '不限'
            }
            accountsBatch(params).then((res) => {
              if (res && res.code === 200) {
                this.editInfo_visible = false
                this.$message.success('产品修改成功!')
                this.loadData()
              }
            })
          } else {
            //  投放人员批量修改
            let params = {
              accountLocalIds: this.selectRow.map((item) => item.accountLocalId),
              launchUserId: launch.split('+')[0],
              launchUserName: launch.split('+')[1]
            }
            accountsBatch(params).then((res) => {
              if (res && res.code === 200) {
                this.editInfo_visible = false
                this.$message.success('投放人员修改成功!')
                this.selectRow = []
              }
            })
          }
          this.resetFormQuery(formName)
        }

        if (formName === 'ruleForm3') {
          pActivation2({ 'did': this.ruleForm3.planId, 'accountType': this.ruleForm3.accountType }).then((res) => {
            if (res && res.code === 200) {
              this.plan_visible = false
              this.$message.success('付费激活成功!')
              this.resetFormQuery(formName)
            }
          })
        }
      })
    },

    /**
     * @description: 重置数据
     * @param {*} formName ref
     */
    resetForm(formName) {
      this.edit_visible = false
      this.editInfo_visible = false
      this.plan_visible = false
      this.$nextTick(() => {
        this.$refs[formName].resetFields()
      })
    },

    resetFormQuery(formName) {
      this.productListed = []
      this.edit_visible = false
      this.editInfo_visible = false
      this.plan_visible = false
      this.selectProjectId = ''
      this.$nextTick(() => {
        this.$refs[formName].resetFields()
        this.loadData()
      })
    },

    // 同步账号
    getOauthFun() {
      this.visible = true
    }
  }
}
</script>

<style scoped></style>
