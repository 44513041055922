<!--
 * @Description: 朋友圈创意
 * @Date: 2022-01-06 10:42:13
 * @LastEditTime: 2022-04-09 15:09:08
-->
<template>
  <div>
    <el-form-item label="广告样式：" :rules="{ required: true }">
      <el-radio-group v-model="ruleFormData.adStyle">
        <el-radio label="routine">常规广告</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="素材类型：" prop="linkName" :rules="{ required: true }">
      <el-radio-group v-model="ruleFormData.assetType">
        <!-- <el-radio label="了解公众号">了解公众号</el-radio> -->
        <el-radio :label="1">常规单图</el-radio>
        <el-radio :label="3">常规三图</el-radio>
        <el-radio :label="4">常规四图</el-radio>
        <el-radio :label="6">常规六图</el-radio>
        <el-radio :label="71">竖版视频 720像素x1280像素</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="外层素材跳转：" label-width="120px" prop="pageType" :rules="{ required: true }">
      <el-radio-group v-model="ruleFormData.pageType">
        <el-radio :label="4">原生推广页</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="文字链文案：" prop="linkName" :rules="{ required: true }">
      <el-radio-group v-model="ruleFormData.linkName">
        <!-- <el-radio label="了解公众号">了解公众号</el-radio> -->
        <el-radio label="了解更多">了解更多</el-radio>
        <el-radio label="查看详情">查看详情</el-radio>
        <el-radio label="关注公众号">关注公众号</el-radio>
        <el-radio label="阅读小说">阅读小说</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="文字链跳转：" prop="linkPageType" :rules="{ required: true }">
      <el-radio-group v-model="ruleFormData.linkPageType">
        <el-radio :label="4">原生推广页</el-radio>
        <el-radio :label="6">公众号详情页</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item v-if="ruleFormData.assetType==1" label="创意复用：">
      <el-checkbox v-model="ruleForm.creativeReuse">订阅号</el-checkbox>
      <!-- <el-radio-group v-model="ruleForm.creativeReuse">
        <el-radio :label="1">订阅号</el-radio>
      </el-radio-group> -->
    </el-form-item>

    <el-form-item label="数据展示：" prop="snsDynamicShowSwitch">
      <el-switch v-model="ruleFormData.snsDynamicShowSwitch" @change="handelSnsDynamicShowSwitch" />
    </el-form-item>

    <el-form-item v-if="ruleFormData.snsDynamicShowSwitch" label="数据类型：" prop="snsDynamicShowType">
      <el-radio-group v-model="ruleForm.snsDynamicShowType">
        <el-radio :label="6">转化目标量</el-radio>
      </el-radio-group>
    </el-form-item>

    <!-- <el-form-item v-if="ruleForm.snsDynamicShowType === 6" label="转化行为：" prop="snsDynamicShowType">
      <el-radio-group v-model="ruleForm.snsDynamicShowText">
        <el-radio label="购买">购买</el-radio>
        <el-radio label="咨询">咨询</el-radio>
        <el-radio label="申请">申请</el-radio>
        <el-radio label="领取">领取</el-radio>
        <el-radio label="预约">预约</el-radio>
      </el-radio-group>
    </el-form-item> -->

    <el-form-item label="创意名称：" prop="tname" :rules="{ required: true, maxlength: 60, message: '请输入创意名称' }">
      <el-input v-model="ruleFormData.tname" rows="4" type="textarea" style="width: 400px" placeholder="请输入创意名称" maxlength="60" show-word-limit />
    </el-form-item>
  </div>
</template>

<script>
export default {
  props: {
    ruleFormData: {
      type: Object,
      default: () => {}
    },
    taskId: {
      type: Number,
      default: 0
    },
    promotedObjectType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      ruleForm: this.ruleFormData
    }
  },

  watch: {
    'ruleForm.snsDynamicShowType': {
      handler(value) {
        if (value === 6) {
          this.ruleForm.snsDynamicShowText = '购买'
        }
      }
    }
  },

  methods: {
    handelSnsDynamicShowSwitch() {
      this.ruleForm.snsDynamicShowType = null
    }
  }
}
</script>

<style lang="scss"></style>
