<!--
 * @Description: 轮播图编辑区
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2022-03-16 09:55:59
-->

<template>
  <section class="section">
    <base-box name="slider">
      <template slot="body">
        <div>
          <p class="w-100">图片数量：</p>
          <div>
            <el-radio-group v-model="detail.componentCount" size="medium" @change="setImgCount">
              <el-radio-button label="2"></el-radio-button>
              <el-radio-button label="3"></el-radio-button>
              <el-radio-button label="4"></el-radio-button>
              <el-radio-button label="5"></el-radio-button>
            </el-radio-group>
          </div>
        </div>

        <div class="image-updata">
          <p class="w-100">选择图片：</p>
          <div>
            <div v-for="(item, index) in detail.slider" :key="index" class="update" @click="openMaterialBox">
              <div v-if="item !== ''" class="image">
                <img :src="item" />
              </div>
              <i v-else class="el-icon-plus avatar-uploader-icon" />
            </div>
          </div>
        </div>
        <p class="tip mt-10" style="margin-left:70px">
          暂不支持含有二维码的图片 <br />
          图片格式：大小不超过300KB，不支持Gif <br />
          图片尺寸：宽度750像素，高度不超过300-1125像素
        </p>
      </template>
    </base-box>
  </section>
</template>

<script>
import BaseBox from './base.vue'
export default {
  components: {
    'base-box': BaseBox
  },
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    /**
     * @description: 设置轮播图数量
     */
    setImgCount(value) {
      let componentGroup = []
      for (let index = 0; index < +value; index++) {
        componentGroup.push('')
      }
      this.detail.slider = componentGroup
      // this.detail.componentCount = +value
      // this.detail.componentGroup = componentGroup
      // let len = this.detail.componentGroup.length
      // if (+value > len) {
      //   let componentGroup = []
      //   for (let index = 0; index < +value - len; index++) {
      //     componentGroup.push({
      //       id: index,
      //       imageId: 0,
      //       image: ''
      //     })
      //   }
      //   this.detail.componentGroup.push(...componentGroup)
      //   this.detail.componentCount = +value - len
      // } else {
      //   this.detail.componentCount.split(0, +value)
      // }
    },
    /**
     * @description: 打开资源库
     */
    openMaterialBox() {
      this.$emit('openMaterialBox')
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}

.section {
  .w-100 {
    font-size: 12px;
    margin-right: 10px;
  }
}
.update {
  width: 130px !important;
  height: 130px;
  line-height: 100px;
  text-align: center;
  border: 1px solid #edecf2;
  background-color: #fbfafd;
  border-radius: 3px;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }
  .avatar {
    width: 130px;
  }
}
.image-updata {
  display: flex !important;
  align-items: flex-start !important;
  & > div {
    display: flex;
    flex-wrap: wrap;
  }
}
// .setting {
//   display: flex;
//   justify-content: flex-start;
//   margin: 10px 0;
//   align-items: center;
//   & > p {
//     font-size: 14px;
//     color: rgb(78, 78, 78);
//   }
//   & > div {
//     width: 80%;
//     margin-left: 30px;
//   }
// }
</style>
