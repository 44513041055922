<template>
  <!--素材详情弹框-->
  <el-dialog
    class="material-details-dialog"
    title="素材详情"
    :visible.sync="show"
    :append-to-body="true"
    width="730px"
    top="20vh"
    @close="onCloseDialog"
  >
    <div class="page-container">
      <!-- 表格 -->
      <el-table
        v-loading="loading"
        :data="tableData"
        height="300px"
        stripe
        :border="true"
      >
        <el-table-column
          label="素材人员"
          align="center"
          prop="userName"
        >
        </el-table-column>
        <el-table-column
          label="完成数量"
          align="center"
          prop="completionNum"
        >
        </el-table-column>
        <el-table-column
          label="完成情况"
          align="center"
          prop="completionStatus"
        >
        </el-table-column>
        <el-table-column
          label="时间"
          align="center"
          prop="createTime"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-button
              v-if="row.isDelete"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(row.toolPeoductAssetInfoId)"
            ></el-button>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-container">
        <c-pagination
          :page-size.sync="pageSize"
          :page-no.sync="pageNum"
          :pager-count="5"
          :total="total"
          tools="total, prev, pager, next"
          :get-data-fun="getTableData"
        />
      </div>

      <!-- 表单 -->
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        :inline="false"
        label-width="80px"
        style="margin-top: 20px;"
      >
        <el-form-item
          label="完成数量"
          prop="completionNum"
        >
          <el-input-number
            v-model="form.completionNum"
            style="width: 300px;"
            :min="0"
            :max="999"
            controls-position="right"
            placeholder="请输入完成数量"
          ></el-input-number>
        </el-form-item>
        <el-form-item
          label="完成情况"
          prop="completionStatus"
        >
          <el-input
            v-model="form.completionStatus"
            style="width: 300px;"
            placeholder="请输入完成情况"
            maxlength="30"
            show-word-limit
          ></el-input>
        </el-form-item>

        <el-form-item
          label="推荐卡点"
          prop="recommendCardPoints"
        >
          <el-select
            v-model="form.recommendCardPoints"
            filterable
            style="width: 300px;"
            placeholder="推荐收费卡点集数"
          >
            <el-option
              v-for="item in options"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>

    <!--底部按钮-->
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        class="btn"
        @click="closeModal"
      >取 消</el-button>
      <el-button
        class="btn"
        type="primary"
        :disabled="saveLoading"
        @click="handleConfirm"
      >添加</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { PageCommonComponent } from '@/common/mixin'
import { materialDetailList, saveMaterialDetail, deleteMaterialDetail } from '@/network/api/toolManagement/api-product-test'

export default {
  name: 'MaterialDetailsDialog',
  mixins: [PageCommonComponent],
  props: {},
  data() {
    return {
      show: false,
      loading: false,
      saveLoading: false,

      form: {
        toolProductId: '',
        toolProductFilterItemId: '',
        completionNum: 0,
        completionStatus: '',
        recommendCardPoints: ''
      },

      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 5,

      rules: {
        completionNum: [{ required: true, message: '请输入完成数量', trigger: 'blur' }],
        completionStatus: [{ required: false, message: '请输入完成情况', trigger: 'blur' }],
        recommendCardPoints: [{ required: false, message: '请输入推荐收费卡点集数', trigger: 'blur' }]
      }
    }
  },
  computed: {
    options() {
      return Array.from({ length: 30 }, (_, i) => 1 + i)
    },
    ...mapGetters(['userInfo'])
  },
  methods: {
    /* --------------------------- Private --------------------------- */
    // 获取列表数据
    getTableData() {
      this.loading = true
      materialDetailList(this.form.toolProductId, this.pageNum, this.pageSize)
        .then(({ code, data }) => {
          if (code === 200) {
            this.tableData = data.records || []
            this.tableData = this.tableData.map(item => {
              return {
                toolPeoductAssetInfoId: item.toolPeoductAssetInfo.toolPeoductAssetInfoId,
                userName: item.toolProductLaunchPerson.userName,
                completionStatus: item.toolPeoductAssetInfo.completionStatus,
                completionNum: item.toolPeoductAssetInfo.completionNum,
                createTime: item.toolPeoductAssetInfo.createTime,
                isDelete: item.toolProductLaunchPerson.userId === this.userInfo.userid
              }
            })
            this.total = data.total
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    /* --------------------------- Actions --------------------------- */
    showModal(id, toolProductFilterItemId) {
      this.show = true
      this.form.toolProductId = id
      this.form.toolProductFilterItemId = toolProductFilterItemId
      this.getTableData()
    },

    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      this.tableData = []
      this.form = {
        toolProductId: '',
        toolProductFilterItemId: '',
        completionNum: 0,
        completionStatus: '',
        recommendCardPoints: ''
      }
      this.$refs['form'].clearValidate()
    },

    // 关闭弹框
    closeModal() {
      this.show = false
    },

    // 删除配置
    handleDelete (id) {
      deleteMaterialDetail(id).then(({ code }) => {
        if (code === 200) {
          this.$message.success('删除成功')
          this.getTableData()
          this.$emit('confirm')
        }
      })
    },

    // 添加
    handleConfirm() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }
        let params = Object.assign({}, this.form)
        this.saveLoading = true
        saveMaterialDetail(params)
          .then(({ code }) => {
            if (code === 200) {
              this.$message.success('添加成功')
              this.getTableData()
              this.$emit('confirm')
            }
          })
          .finally(() => {
            this.saveLoading = false
          })
      })
    }

  }
}
</script>

<style lang="scss" scoped></style>
