var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "base-box",
        { attrs: { name: "slider" } },
        [
          _c("template", { slot: "desc" }, [
            _vm._v(" 适用广告位：朋友圈信息流"),
            _c("br"),
            _vm._v(" 适用外层样式：常规广告"),
            _c("br"),
            _vm._v(
              " 创建广告时，仅选择了以上广告位与外层样式的广告可使用此推广页。广告外层将自动拉取顶部轮播图。 "
            ),
          ]),
          _c("template", { slot: "body" }, [
            _c("div", [
              _c("p", { staticClass: "font-13" }, [_vm._v("图片数量：")]),
              _c(
                "div",
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "medium" },
                      on: { change: _vm.setImgCount },
                      model: {
                        value: _vm.detail.componentCount,
                        callback: function ($$v) {
                          _vm.$set(_vm.detail, "componentCount", $$v)
                        },
                        expression: "detail.componentCount",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "2" } }),
                      _c("el-radio-button", { attrs: { label: "3" } }),
                      _c("el-radio-button", { attrs: { label: "4" } }),
                      _c("el-radio-button", { attrs: { label: "5" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              [
                _c("p", { staticClass: "font-13" }, [_vm._v("轮播样式：")]),
                _c(
                  "el-row",
                  { attrs: { gutter: 50 } },
                  [
                    _c(
                      "el-col",
                      { staticClass: "carousel", attrs: { span: 12 } },
                      [
                        _c(
                          "div",
                          {
                            class: {
                              "active-carousel": _vm.detail.type === "101",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.settype("101")
                              },
                            },
                          },
                          [
                            _c(
                              "el-carousel",
                              { attrs: { interval: 2000, arrow: "never" } },
                              _vm._l(3, function (item) {
                                return _c("el-carousel-item", { key: item }, [
                                  _c("div", { staticClass: "carousel-item" }, [
                                    _c("div", {
                                      staticClass: "small",
                                      staticStyle: { width: "100%" },
                                    }),
                                  ]),
                                ])
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "el-col",
                      { staticClass: "carousel", attrs: { span: 12 } },
                      [
                        _c(
                          "div",
                          {
                            class: {
                              "active-carousel": _vm.detail.type === "102",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.settype("102")
                              },
                            },
                          },
                          [
                            _c(
                              "el-carousel",
                              { attrs: { interval: 2000, arrow: "never" } },
                              [
                                _c("el-carousel-item", [
                                  _c("div", { staticClass: "carousel-item" }, [
                                    _c("div", {
                                      staticClass: "small",
                                      staticStyle: { width: "15%" },
                                    }),
                                    _c("div", {
                                      staticClass: "big",
                                      staticStyle: { width: "82%" },
                                    }),
                                  ]),
                                ]),
                                _c("el-carousel-item", [
                                  _c("div", { staticClass: "carousel-item" }, [
                                    _c("div", {
                                      staticClass: "small",
                                      staticStyle: { width: "12%" },
                                    }),
                                    _c("div", {
                                      staticClass: "big",
                                      staticStyle: { width: "69%" },
                                    }),
                                    _c("div", {
                                      staticClass: "small",
                                      staticStyle: { width: "12%" },
                                    }),
                                  ]),
                                ]),
                                _c("el-carousel-item", [
                                  _c("div", { staticClass: "carousel-item" }, [
                                    _c("div", {
                                      staticClass: "small",
                                      staticStyle: { width: "82%" },
                                    }),
                                    _c("div", {
                                      staticClass: "big",
                                      staticStyle: { width: "15%" },
                                    }),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "image-updata" }, [
              _c("p", { staticClass: "font-13" }, [_vm._v("选择图片：")]),
              _vm.detail.type === "101"
                ? _c(
                    "div",
                    _vm._l(
                      _vm.detail.componentGroupList.componentGroup,
                      function (item) {
                        return _c(
                          "div",
                          {
                            key: item.id,
                            staticClass: "update",
                            on: { click: _vm.setMaterialBox },
                          },
                          [
                            item.componentItem.pureImageUrl !== ""
                              ? _c("div", { staticClass: "image" }, [
                                  _c("img", {
                                    attrs: {
                                      src: item.componentItem.pureImageUrl,
                                    },
                                  }),
                                ])
                              : _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon",
                                }),
                          ]
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
              _vm.detail.type === "102"
                ? _c(
                    "div",
                    _vm._l(
                      _vm.detail.componentGroupList.componentItem,
                      function (item) {
                        return _c(
                          "div",
                          {
                            key: item.id,
                            staticClass: "update",
                            on: { click: _vm.setMaterialBox },
                          },
                          [
                            item.pureImageUrl !== ""
                              ? _c("div", { staticClass: "image" }, [
                                  _c("img", {
                                    attrs: { src: item.pureImageUrl },
                                  }),
                                ])
                              : _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon",
                                }),
                          ]
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
            ]),
            _c(
              "p",
              {
                staticClass: "tip mt-10",
                staticStyle: { "margin-left": "70px" },
              },
              [
                _vm._v(" 暂不支持含有二维码的图片 "),
                _c("br"),
                _vm._v(" 图片格式：大小不超过300KB，不支持Gif "),
                _c("br"),
                _vm._v(" 图片尺寸：宽度750像素，高度不超过300-1125像素 "),
              ]
            ),
          ]),
        ],
        2
      ),
      _c("material-box", {
        attrs: {
          visible: _vm.materialBox_visible,
          "img-count": +_vm.detail.componentCount,
          "image-list":
            _vm.detail.type === "101" ? _vm.imageList_101 : _vm.imageList_102,
          name: "ldy",
          attribute: {
            key: "slider",
            value: "轮播图",
            count: +_vm.detail.componentCount,
            size: 300 * 1024,
            listType: "image/jpg,image/jpeg,image/png",
            width: 750,
            height: [300, 1125],
          },
        },
        on: { setMaterialBox: _vm.setMaterialBox, submit: _vm.selectImg },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }