export default {
  inserted(el, binding, vnode) {
    const { value } = binding
    const permission = vnode.context.$store.state.user.permission
    if (value) {
      if (value instanceof Array) {
        const check = value.every(v => !permission.includes(v))
        if (check) {
          el.parentNode && el.parentNode.removeChild(el)
        }
      } else {
        if (!permission.includes(value)) {
          // console.log('not included-------', value)
          el.parentNode && el.parentNode.removeChild(el)
        }
      }
    } else {
      throw new Error(`need permission! Like v-has="'admin'"`)
    }
  }
}
