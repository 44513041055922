<template>
  <div class="video-template-box">
    <div class="template-left">
      <div class="template-search-form">
        <el-input v-model="templateName" placeholder="请输入模板名称" style="width: 200px;margin-right: 10px" clearable>
        </el-input>
        <el-button type="primary" @click="onSubmit">查询</el-button>
      </div>
      <div class="video-template-list">
        <div class="video-template-item" :class="{ 'active': activeTemplate === item.id }"
          v-for="(item, index) in templateList" :key="index" @click.stop="handleChangeTemplate(item)">
          <div class="name">{{ item.name }}</div>
          <div class="num">元素：{{ item.elementCount }}</div>
          <div class="template-id">模板ID：{{ item.videoTemplateId }}</div>
          <div class="template-opt">
            <span class="template-status" :class="{ 'offline': item.state === 0 }">{{ item.state | filterState }}</span>
            <el-button class="template-edit-btn" type="text" icon="el-icon-edit" @click.stop="handleAddTemplate(item)">
            </el-button>
          </div>
        </div>
      </div>
      <div class="video-template-add" @click="handleAddTemplate('')">新增</div>
    </div>
    <div class="template-right">
      <el-button type="primary" icon="el-icon-plus" round @click="handleAddRelation">添加</el-button>
      <div style="margin-top:10px">
        <el-table v-loading="loading" :data="list" stripe class="list-table"
          :class="{ 'no-data': !list || !list.length }" height="700px">
          <el-table-column type="index" label="序号" width="50"></el-table-column>
          <el-table-column prop="name" label="元素名称" align="center"></el-table-column>
          <el-table-column prop="baseType" label="基础类型" align="center">
            <template slot-scope="{row}">
              {{ row.baseType | filterBaseType }}
            </template>
          </el-table-column>
          <el-table-column prop="typeName" label="元素类型" align="center"></el-table-column>
          <el-table-column prop="replaceName" label="替换字段" align="center">
            <div slot-scope="{row}">
              <span v-if="!row.isEdit" class="edit-input-box">{{ row.replaceName }} <el-button type="text"
                  class="el-icon-edit" @click="handleEdit(row)"></el-button></span>
              <el-input v-else style="width: 100px" type="text" :autofocus="true" v-model="row.replaceName"
                @blur="handleEditSave(row)"></el-input>
            </div>
          </el-table-column>
          <el-table-column prop="url" label="预览" align="center">
            <template slot-scope="{row}">
              <el-popover v-if="row.baseType === 'IMG'" placement="right" trigger="hover">
                <div class="element-pop-img">
                  <el-image :src="row.url" fit="contain"></el-image>
                </div>
                <div slot="reference" class="element-img">
                  <el-image :src="row.url" fit="contain" lazy></el-image>
                </div>
              </el-popover>
              <el-popover v-if="row.baseType === 'VIDEO'" placement="right" trigger="hover">
                <div class="element-pop-img">
                  <video :src="row.url" controls></video>
                </div>
                <div slot="reference" class="element-img">
                  <video :src="row.url"></video>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="" label="操作">
            <template slot-scope="{row}">
              <el-button type="text" class="red" @click="handleDeleteElement(row.id)">
                <el-tooltip content="删除" placement="top">
                  <i class="el-icon-delete" style="font-size: 16px"></i>
                </el-tooltip>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <video-template ref="videoTemplateDialog" @confirm="getTemplateList"></video-template>

    <template-element-relation ref="templateRelationDialog" @refresh="refreshData"></template-element-relation>
  </div>
</template>

<script>
import { getTemplateElementList, getElementTemplateList, deleteTemplateElement, updateTemplateReplace } from '@/network/api/toolManagement/api-tool'

import VideoTemplateDialog from './components/VideoTemplateDialog'
import TemplateElementRelation from './components/TemplateElementRelation'
export default {
  components: {
    'video-template': VideoTemplateDialog,
    'template-element-relation': TemplateElementRelation
  },
  filters: {
    filterBaseType(value) {
      const types = { 'IMG': '图片', 'VIDEO': '视频' }
      return types[value] || ''
    },
    filterState(value) {
      const states = { 1: '上线', 0: '下线' }
      return states[value] || ''
    }
  },
  data() {
    return {
      templateName: '',
      templateList: [],
      activeTemplate: 0,
      list: [],
      loading: false
    }
  },

  mounted() {
    this.getTemplateList()
  },

  methods: {
    refreshData() {
      this.getTemplateList()
      this.getPage()
    },
    // 获取模板列表
    getTemplateList(tag) {
      getElementTemplateList({ name: this.templateName }).then(({ code, data }) => {
        if (code === 200) {
          this.templateList = data
          if (tag === 'search') {
            this.activeTemplate = data.length ? data[0].id : 0
          } else {
            this.activeTemplate = this.activeTemplate ? this.activeTemplate : (data.length ? data[0].id : 0)
          }
          this.getPage()
        }
      })
    },

    /**
         * @description: 获取列表
         * @param {*} params
         */
    getPage() {
      if (!this.activeTemplate) {
        return
      }
      let search = {
        templateId: this.activeTemplate
      }
      this.loading = true
      getTemplateElementList(search).then(({ code, data }) => {
        if (code === 200) {
          let arr = data.map(v => {
            return {
              ...v,
              copyReplaceName: v.replaceName
            }
          })
          this.list = arr || []
        }
      }).finally(e => {
        this.loading = false
      })
    },

    /**
 * @description: 查询
 */
    onSubmit() {
      this.getTemplateList('search')
    },

    // 点击新增模板
    handleAddTemplate(row) {
      this.$refs.videoTemplateDialog.showModal(row || {})
    },

    // 点击切换模板
    handleChangeTemplate(item) {
      if (this.activeTemplate === item.id) {
        return
      }
      this.activeTemplate = item.id
      this.getPage()
    },

    // 更新数据
    handleRefresh() {
      this.getElementType()
    },

    // 点击元素类型
    handleElementType() {
      this.$refs.elementTypeDialog.showModal()
    },

    // 点击添加元素
    handleAddElement(row) {
      this.$refs.elementLibraryDialog.showModal(row || {})
    },

    // 点击添加关联元素
    handleAddRelation() {
      this.$refs.templateRelationDialog.showModal(this.activeTemplate, this.list)
    },

    // 点击删除
    handleDeleteElement(id) {
      this.$confirm('确定要删除该选项吗?', '元素删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { code } = await deleteTemplateElement({ id: id })
        if (code === 200) {
          this.getPage()
          this.getTemplateList()
          this.$message.success('删除成功!')
        }
      }).catch(() => {
      })
    },

    // 点击编辑替换字段
    handleEdit(row) {
      if (row.isEdit) {
        this.$delete(row, 'isEdit')
      } else {
        this.$set(row, 'isEdit', true)
      }
    },

    // 编辑替换字段保存
    handleEditSave(row) {
      if (!row.replaceName) {
        this.$message.error('请输入替换字段')
        return
      }
      if (row.replaceName === row.copyReplaceName) {
        this.$delete(row, 'isEdit')
        return
      }
      updateTemplateReplace({ ...row }).then(({ code }) => {
        if (code === 200) {
          this.$delete(row, 'isEdit')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.element-img {
  width: 60px;
  height: 60px;
  text-align: center;
  margin: auto;
  padding-top: 12px;

  video {
    width: 100%;
    height: 100%
  }
}

.element-pop-img {
  max-width: 260px;

  video {
    width: 100%;
    height: 100%
  }
}

.video-template-box {
  display: flex;

  .template-left {
    border-right: solid 1px #dcdfe6;
    border-radius: 4px;
    box-sizing: border-box;
    width: 400px;
    position: relative;
    padding-right: 10px;

    .template-search-form {
      margin: 15px 15px 15px 0;
    }

    .video-template-add {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 10px;
      padding: 12px 0;
      background-color: $themeColor;
      text-align: center;
      font-size: 14px;
      color: #fff;
      cursor: pointer;
    }
  }

  .template-right {
    width: calc(100% - 400px);
    margin-left: 20px;
    padding: 10px;
    box-sizing: border-box;
    text-align: right;
  }

  .video-template-list {
    height: 700px;
    overflow-y: auto;
    padding-bottom: 50px;
    border: solid 1px #dcdfe6;
  }

  .video-template-item {
    position: relative;
    padding: 10px 15px;
    box-sizing: border-box;
    margin-bottom: -1px;
    cursor: pointer;

    &.active {
      background-color: #ecf5ff;
      border-color: #b3d8ff;
    }

    .name {
      font-size: 18px;
      color: #1a1a1a;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .num {
      font-size: 14px;
      margin: 8px 0;
    }

    .template-id {
      color: #999;
    }

    .template-opt {
      position: absolute;
      right: 20px;
      top: 0;
      padding-top: 30px;
    }

    .template-status {
      font-size: 14px;
      color: $themeColor;
      font-weight: 500;

      &.offline {
        color: #F56C6C;
      }
    }

    .template-edit-btn {
      font-size: 16px;
      color: #1a1a1a;
      padding-left: 20px;
      cursor: pointer;
    }
  }
}
</style>
