<template>
  <div v-if="['蒲栋良','陈飞', '刘威', '靳双动', '王程'].includes(userInfo.username)">
    <el-row>
      <el-button @click="handleEnd">关闭服务</el-button>
    </el-row>
    <el-row>
      <el-button @click="handleStart">开启服务</el-button>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { endServer, startServer } from '@/network/api/system'
import moment from 'moment'
import md5 from 'crypto-js/md5'
export default {
  name: 'AdminTools',
  computed: {
    ...mapGetters(['userInfo'])
  },
  methods: {
    handleEnd() {
      let d = moment().format('YYYY-MM-DD')
      endServer(md5(d)).then(res => {
        this.$message({
          message: '操作成功',
          type: 'success'
        })
      })
    },
    handleStart() {
      let d = moment().format('YYYY-MM-DD')
      startServer(md5(d)).then(res => {
        this.$message({
          message: '操作成功',
          type: 'success'
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
