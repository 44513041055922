/*
 * @Description: Do not edit
 * @Date: 2021-12-06 14:46:03
 * @LastEditTime: 2022-02-23 16:02:56
 */

import { getSelectAccountUin } from '@/network/api/assetManagement/assetManageTencent.js'
import { getBatchMeRelationProject } from '@/network/api/basicSetting/project/basicSettingProject'
import { debounce } from '@/utils/ruoyi'
export default {
  data() {
    return {
      AccountUin_Options: [], // 投放账户  请输入关键词查询

      projectList: [], // 项目
      selectProjectId: '', // '', // 项目Id
      productList: [], // 产品list
      productListed: [], //
      list: [],
      loading: false
    }
  },

  mounted() {
    this.getProjectAndProduct()
  },

  watch: {
    selectProjectId() {
      if (this.productList.length === 0) {
        this.getProjectAndProduct()
      }
      if (this.projectList.length && this.selectProjectId !== '') {
        this.productListed = this.productList[this.selectProjectId]
      } else {
        if (this.productList.length === 0) {
          getBatchMeRelationProject().then((res) => {
            if (res.code === 200) {
              const data = res.data
              if (data.products !== undefined) {
                this.productListed = data.products[this.selectProjectId]
              }
            }
          })
        } else {
          this.productListed = []
        }
      }
    }
  },

  methods: {
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        this.getSelectAccountUinFun(query)
      } else {
        this.AccountUin_Options = []
      }
    },

    // 获取项目,产品
    getProjectAndProduct() {
      getBatchMeRelationProject().then((res) => {
        if (res.code === 200) {
          const data = res.data
          if (data.projects !== undefined) {
            this.projectList = data.projects
          }
          if (data.products !== undefined) {
            this.productList = data.products
          }
        }
      })
    },

    getSelectAccountUinFun: debounce(function (query) {
      getSelectAccountUin({ accountId: query }).then((res) => {
        if (res.code === 200) {
          this.loading = false
          this.AccountUin_Options = res.data
        }
      })
    })
  }
}
