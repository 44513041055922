var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "customer" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "企微客服组",
            visible: _vm.visible,
            width: "800px",
            "modal-append-to-body": false,
            "before-close": _vm.customerClose,
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.customerData.list, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "公众号名称",
                      prop: "name",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "绑定企微ID",
                      type: "corpid",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.appids && row.appids.length
                              ? _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      filterable: "",
                                      size: "small",
                                      placeholder: "请选择绑定企微ID",
                                    },
                                    model: {
                                      value: row.corpid,
                                      callback: function ($$v) {
                                        _vm.$set(row, "corpid", $$v)
                                      },
                                      expression: "row.corpid",
                                    },
                                  },
                                  _vm._l(row.appids, function (item) {
                                    return _c(
                                      "el-option",
                                      {
                                        key: item.id,
                                        attrs: { value: item.bindWorkwxAppid },
                                      },
                                      [_vm._v(_vm._s(item.bindWorkwxAppid))]
                                    )
                                  }),
                                  1
                                )
                              : _c("el-input", {
                                  staticStyle: { width: "203px" },
                                  attrs: {
                                    placeholder: "请输入绑定企微ID",
                                    size: "small",
                                  },
                                  model: {
                                    value: row.corpid,
                                    callback: function ($$v) {
                                      _vm.$set(row, "corpid", $$v)
                                    },
                                    expression: "row.corpid",
                                  },
                                }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "客服组ID",
                      type: "groupid",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.appids && row.appids.length
                              ? _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      filterable: "",
                                      size: "small",
                                      placeholder: "请选择客服组ID",
                                    },
                                    model: {
                                      value: row.groupid,
                                      callback: function ($$v) {
                                        _vm.$set(row, "groupid", $$v)
                                      },
                                      expression: "row.groupid",
                                    },
                                  },
                                  _vm._l(row.appids, function (item) {
                                    return _c(
                                      "el-option",
                                      {
                                        key: item.id,
                                        attrs: { value: item.kefuGroup },
                                      },
                                      [_vm._v(_vm._s(item.kefuGroup))]
                                    )
                                  }),
                                  1
                                )
                              : _c("el-input", {
                                  staticStyle: { width: "203px" },
                                  attrs: {
                                    placeholder: "请输入客服组ID",
                                    size: "small",
                                  },
                                  model: {
                                    value: row.groupid,
                                    callback: function ($$v) {
                                      _vm.$set(row, "groupid", $$v)
                                    },
                                    expression: "row.groupid",
                                  },
                                }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.customerClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }