<!--
 * @Description:选择推广单元等框 Tree
 * @Date: 2021-11-09 11:49:47
 * @LastEditTime: 2022-05-19 16:43:44
-->
<template>
  <section class="section">
    <el-row type="flex" justify="space-between" :gutter="10" style="height: 100%">
      <el-col class="tree" :span="18">
        <div v-if="selected.level === '2'" class="left-ul">
          <p>已选{{ selected.sort === 1 ? `${leftTitle}模板` : `线上${leftTitle}` }}</p>
          <!-- 模板数据 -->
          <ul v-if="selected.sort === 1">
            <div v-if="_localQuery.length">
              <div v-if="dialogTitle === '选择推广计划'">
                <li v-for="item in _localQuery" :key="item._id" :class="{ active: localQuerySelect._id === item._id }" @click="setOnLocalQuerySelect(item)">{{ item._name }}</li>
              </div>
              <div v-else>
                <el-tree :render-content="renderContent" :data="_localQueryTree" default-expand-all :props="defaultProps" @node-click="setOnLocalQuerySelect"></el-tree>
              </div>
            </div>
            <div v-else style="text-align:center; margin-top: 40px">
              <span>未选择</span>
            </div>
          </ul>
          <!-- 线上数据 -->
          <ul v-if="selected.sort === 2">
            <div v-if="_onlineQuery.length" class="left-tree">
              <div v-if="dialogTitle === '选择推广计划'">
                <li v-for="item in _onlineQuery" :key="item._id" :class="{ active: onlineQuerySelect._id === item._id }" @click="setOnlineQuerySelect(item)">{{ item._name }}</li>
              </div>
              <div v-else>
                <el-tree :render-content="renderContent" :data="_onlineQuery" default-expand-all :props="defaultProps" @node-click="setOnlineQuerySelect"></el-tree>
              </div>
              <!-- //  -->
            </div>
            <div v-else style="text-align:center; margin-top: 40px">
              <span>未选择</span>
            </div>
          </ul>
        </div>

        <!-- tree 投标筛选部分 -->
        <div class="tree-select">
          <div class="tree-top">
            <p v-if="selected.subTitle.length === 1">{{ selected.subTitle[0].value }}</p>
            <!-- 二级nav -->
            <ul v-else>
              <li v-for="(item, index) in selected.subTitle" :key="item.key" :class="{ active: index === subTitleInedx, disable: onlineQuery.length === 0 }" @click="handleSubTitle(item, index)">{{ item.value }}</li>
            </ul>
            <el-input v-model="filterText" placeholder="输入关键字进行过滤" size="small" style="width: 300px"> </el-input>
          </div>
          <div v-loading="treeLoading" class="filter-tree-box">
            <el-tree ref="tree" :current-node-key="7" node-key="id" show-checkbox class="filter-tree" :data="selected.sort === 1 ? localData : onlineData" :props="{ label: 'lable' }" default-expand-all :filter-node-method="filterNode" @check-change="checkChange"> </el-tree>
          </div>
        </div>
      </el-col>

      <!-- 已选择部分 -->
      <el-col class="selected-tree" :span="6">
        <p>已选{{ selected.sort === 1 ? selected.title : subTitleActive.value }}</p>

        <!-- 选中模板数据 -->
        <ul v-if="selected.sort === 1">
          <li v-for="item in selectedDataLocal" :key="item.id" class="selected-tree-li">
            <p>{{ item.lable }}</p>
            <i class="el-icon-circle-close" @click="deleteItem(item.id)"></i>
          </li>
        </ul>
        <!-- 选中的线上数据 -->
        <ul v-if="selected.sort === 2 && subTitleInedx === 0">
          <li v-for="item in _selectedDataOnline" :key="item.id" class="selected-tree-li">
            <p>{{ item.lable }}</p>
            <i class="el-icon-circle-close" @click="deleteItem(item.id)"></i>
          </li>
        </ul>

        <!--选中 线上数据中 的 模板数据 -->
        <div v-if="selected.sort === 2 && subTitleInedx === 1">
          <el-menu default-active="1" class="el-menu-vertical-demo" :default-openeds="_selectedDataOnlineList.map((item) => item.campaignFeedName)">
            <Submenu v-for="(item, index) in _selectedDataOnlineList" :key="index" :item="item">
              <template slot-scope="user">
                <i class="el-icon-circle-close" style="font-size: 15px" @click="deleteItem2(user.id, user.campaignFeedName)"></i>
              </template>
            </Submenu>
          </el-menu>
        </div>
      </el-col>
    </el-row>
  </section>
</template>

<script>
// import { arraySetCount } from '@/utils/ruoyi'
import Submenu from './submenu.vue'

export default {
  inject: ['getBaiduOnLocal', 'getBaiduOnline', 'getBaiduOnlineTwo'],
  components: {
    Submenu
  },
  props: {
    dialogTitle: {
      type: String,
      default: ''
    },
    leftTitle: {
      type: String,
      default: ''
    },
    selected: {
      type: Object,
      default: () => {}
    },
    visible: {
      type: Boolean,
      default: false
    },
    // 模板数据
    localData: {
      type: Array,
      default: () => []
    },
    // 线上数据
    onlineData: {
      type: Array,
      default: () => []
    },
    // 线上左侧数据
    onlineQuery: {
      type: Array,
      default: () => []
    },
    // 模板左侧数据
    localQuery: {
      type: Array,
      default: () => []
    },
    dataSource: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      filterText: '',
      subTitleActive: this.selected.subTitle[0],
      subTitleInedx: 0,
      // 选中的数据
      selectedData: {
        local: [],
        online: []
      },
      // 本地左侧选中的数据
      localQuerySelect: {},
      selectedDataOnline_copy: {},
      selectedDataOnlineList: [],
      copyList: [],
      // 线上左侧选中的数据
      onlineQuerySelect: {},
      treeLoading: false,
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },

  computed: {
    // 去除重复数据
    selectedDataLocal() {
      let data = []
      this.selectedData.local.map((item) => {
        if (!data.some((s) => s.id === item.id) && !item.type2) {
          data.push(item)
        }
      })
      return data
    },

    // 去除重复数据
    _selectedDataOnline() {
      let data = []
      this.selectedData.online.map((item) => {
        if (!data.some((s) => s.id === item.id)) {
          data.push(item)
        }
      })
      return data
    },

    // 去重localQuery
    _localQuery() {
      let data = []
      this.localQuery.map((item) => {
        if (!data.some((s) => s._id === item._id)) {
          data.push(item)
        }
      })
      return data
    },
    // 左侧树形选择框
    _localQueryTree() {
      let newArr = []
      this.localQuery.map((item) => {
        if (!newArr.some((s) => s && s.label === item.accountName)) {
          newArr.push({
            ...item,
            label: item.accountName
            // children: []
          })
        }
      })
      if (this.dialogTitle === '选择推广单元') {
        this.localQuery.forEach((element) => {
          newArr.forEach((newElement) => {
            if (element.accountName === newElement.label) {
              newElement.children = newElement.children ? newElement.children : []
              newElement.children.push({
                ...element,
                label: element.lable
              })
            }
          })
        })
      }
      if (this.dialogTitle === '选择推广创意') {
        this.localQuery.forEach((element) => {
          newArr.forEach((newElement) => {
            if (element.accountName === newElement.label) {
              newElement.children = newElement.children ? newElement.children : []
              if (newElement.children.length === 0 || !newElement.children.some((s) => s.label === element.baiduUnitName)) {
                newElement.children.push({
                  ...element,
                  label: element.campaignFeedName
                })
              }
            }
          })
        })

        newArr.forEach((newElement) => {
          newElement.children.forEach((item) => {
            item.children = this.localQuery.filter((f) => f.baiduUnitName === item.baiduUnitName && f.campaignFeedId === item.campaignFeedId)
          })
        })
      }
      return newArr
    },
    // 左侧树形选择框
    _onlineQuery() {
      let newArr = []
      this.onlineQuery.map((item) => {
        if (!newArr.some((s) => s && s.label === item.accountName)) {
          newArr.push({
            ...item,
            label: item.accountName
          })
        }
      })
      if (this.dialogTitle === '选择推广计划') {
        return newArr
      }
      if (this.dialogTitle === '选择推广单元') {
        this.onlineQuery.forEach((element) => {
          newArr.forEach((newElement) => {
            if (element.accountName === newElement.label) {
              newElement.children = newElement.children ? newElement.children : []
              newElement.children.push({
                ...element,
                label: element.lable
              })
            }
          })
        })
      }
      if (this.dialogTitle === '选择推广创意') {
        this.onlineQuery.forEach((element) => {
          newArr.forEach((newElement) => {
            if (element.accountName === newElement.label) {
              newElement.children = newElement.children ? newElement.children : []
              if (newElement.children.length === 0 || !newElement.children.some((s) => s.label === element.baiduUnitName)) {
                newElement.children.push({
                  ...element,
                  label: element.campaignFeedName
                })
              }
            }
          })
        })

        newArr.forEach((newElement) => {
          newElement.children.forEach((item) => {
            item.children = this.onlineQuery.filter((f) => f.baiduUnitName === item.baiduUnitName)
          })
        })
      }
      return newArr
    },
    // 选中 线上数据中 的 模板数据
    _selectedDataOnlineList() {
      let newArr = []
      this.selectedDataOnlineList.map((item) => {
        if (!newArr.some((s) => s && s.campaignFeedName === item.campaignFeedName)) {
          newArr.push({
            ...item
          })
        }
      })

      this.selectedDataOnlineList.forEach((element) => {
        newArr.forEach((newElement) => {
          if (element.campaignFeedName === newElement.campaignFeedName) {
            newElement.children = newElement.children ? newElement.children : []
            newElement.children.push({
              ...element
            })
          }
        })
      })
      return newArr
    }
  },

  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
    /**
     * 当选 中线上数据 时,默认展示第一个
     */
    selected(val) {
      // 给选中的数据置灰
      if (val.level === '1') {
        this.setCheckedKeys(this.selectedData.local)
      }
      // 获取初始化数据
      if (val.sort === 2) {
        this.setOnlineQuerySelect(this.onlineQuery[0])
      } else {
        this.subTitleInedx = 0
      }
      // this.treeLoading = false
    },
    visible(value) {
      if (value === true) {
        if (this.localQuery.length) {
          this.setOnLocalQuerySelect(this.localQuery[0])
        }
      }
    }
  },

  mounted() {
    // 当模板数据需要条件筛选时执行
    if (this.localQuery.length) {
      // this.setOnLocalQuerySelect(this.localQuery[0])
    }
  },

  methods: {
    /**
     * @description 筛选tree
     */
    filterNode(value, data) {
      if (!value) return true
      return data.lable.indexOf(value) !== -1
    },

    renderContent(h, { node }) {
      return (
        // eslint-disable-next-line
        <span class="custom-tree-node text-ellipsis">
          <a>{node.label}</a>
        </span>
      )
    },

    /**
     * @description 线上单元下单元模板 设置勾选框
     */
    selectedDataOnline() {
      let selectedDataOnline_copy_arr = []
      if (this.dialogTitle === '选择推广单元' && this.subTitleInedx === 1) {
        for (const key in this.selectedDataOnline_copy) {
          if (Object.hasOwnProperty.call(this.selectedDataOnline_copy, key)) {
            const element = this.selectedDataOnline_copy[key]
            selectedDataOnline_copy_arr.push(element)
          }
        }
        this.selectedDataOnlineList = selectedDataOnline_copy_arr.flat()
        // 获取线上单元下模板单元数据
        let localData = this.selectedData.local.concat(selectedDataOnline_copy_arr.flat())
        let onlineData = this.selectedData.online

        this.selectedData = {
          local: localData,
          online: onlineData
        }
        this.$emit('getSelectedData', this.selectedData)
      }
    },

    /**
     * @description 二级nav选择时调用
     */
    async handleSubTitle(item, index = 0) {
      if (this.onlineQuery.length === 0) return
      this.subTitleActive = item
      this.subTitleInedx = index
      this.treeLoading = true
      if (index === 0) {
        await this.getBaiduOnline(this.onlineQuerySelect)
        this.setCheckedKeys(this.selectedData.online)
      } else {
        await this.getBaiduOnlineTwo(this.onlineQuerySelect)
        this.setCheckedKeys(this.selectedData.local)
      }
      this.treeLoading = false
    },

    /**
     * @description: (选中或取消选中)tree里数据, 分别添加到 (模板和线上) 已选里
     * @param {data} 选中/取消选中的数据
     * @param {flag} 选中/取消选
     */
    checkChange(data, flag) {
      // 线上单元 下单 元数据单独控制
      if (this.dialogTitle === '选择推广单元' && this.subTitleInedx === 1) {
        if (flag) {
          let _copy = JSON.parse(JSON.stringify(data))
          this.copyList.push(_copy)
          // 去重
          let _copyList = []
          this.copyList.forEach((item) => {
            if (_copyList.length === 0 || !_copyList.some((s) => s.adgroupFeedName === item.adgroupFeedName)) {
              _copyList.push({
                ...item,
                _name: data.lable,
                label: data.lable,
                accountName: this.onlineQuerySelect.accountName,
                baiduId: this.onlineQuerySelect.baiduId,
                campaignFeedId: this.onlineQuerySelect.campaignFeedId,
                type: '模板',
                type2: '线上单元模板',
                releaseStatus: '未发布',
                campaignFeedName: this.onlineQuerySelect.campaignFeedName
              })
            }
          })
          this.selectedDataOnline_copy[this.onlineQuerySelect._id] = _copyList
          this.selectedDataOnline()
        } else {
          let item = this.selectedDataOnline_copy[this.onlineQuerySelect._id]
          this.selectedDataOnline_copy[this.onlineQuerySelect._id] = item.filter((f) => f.id !== data.id)
          this.selectedDataOnline()
        }
      } else {
        const { id, lable, createBy = '-', createTime = '-', accountName = '-' } = data
        let localData = this.selectedData.local
        let onlineData = this.selectedData.online
        if (this.selected.sort === 1) {
          if (flag) {
            // _id ,  _name  用来展示
            // 推广计划模板,需要投放账户来对应
            if (this.dialogTitle === '选择推广计划') {
              this.onlineQuery.map((item) => {
                localData.push({
                  // 账户管家
                  housekeeperId: item.hkId,
                  //  投放账户名称/ID
                  baiduId: item.baiduId,
                  accountName: item.accountName,
                  // 计划名称/ID
                  baiduAdvPlanId: data.baiduAdvPlanId, // 计划模板Id, 用来发布
                  campaignFeedId: data.campaignFeedId || 0,
                  campaignFeedName: data.campaignFeedName,
                  subject: data.subject,
                  qc_id: data.baiduAdvPlanId,
                  _id: id,
                  _name: lable,
                  id,
                  lable,
                  createBy,
                  type: '模板',
                  releaseStatus: '未发布',
                  accountId: item.accountId,
                  createTime
                })
              })
            }
            if (this.dialogTitle === '选择推广单元') {
              localData.push({
                // 账户管家
                housekeeperId: this.localQuerySelect.housekeeperId,
                //  投放账户名称/ID
                baiduId: this.localQuerySelect.baiduId,
                accountName: this.localQuerySelect.accountName,
                // 计划名称/ID
                campaignFeedId: this.localQuerySelect.campaignFeedId,
                campaignFeedName: this.localQuerySelect.campaignFeedName,
                // 单元名称/ID
                baiduUnitId: data.baiduUnitId,
                baiduUnitName: data.adgroupFeedName,
                // 单元名称 线上
                adgroupFeedId: null, // 本地没有发布没有线上单元Id
                adgroupFeedName: data.adgroupFeedName,
                subject: this.localQuerySelect.subject,
                _id: id,
                _name: lable,
                id,
                lable,
                label: lable,
                createBy,
                type: '模板',
                releaseStatus: '未发布',
                pushStatus: '未发布',
                createTime
              })
            }

            if (this.dialogTitle === '选择推广创意') {
              if (this.localData.some((s) => s.disabled && s.disabled === true) || localData.some((s) => s.baiduUnitId === this.onlineQuerySelect.baiduUnitId)) {
                return this.$message.error('一个单元下只能选择一个模板')
              }
              localData.push({
                ...data,
                // 账户管家
                housekeeperId: this.localQuerySelect.housekeeperId,
                //  投放账户名称/ID
                baiduId: this.localQuerySelect.baiduId,
                accountName: this.localQuerySelect.accountName,
                // 计划名称/ID
                campaignFeedId: this.localQuerySelect.campaignFeedId,
                campaignFeedName: this.localQuerySelect.campaignFeedName,
                // 单元名称/ID
                baiduUnitId: this.localQuerySelect.baiduUnitId,
                baiduUnitName: this.localQuerySelect.baiduUnitName,
                // 单元名称 线上
                adgroupFeedName: this.localQuerySelect.adgroupFeedName,
                adgroupFeedId: this.localQuerySelect.adgroupFeedId,
                subject: this.localQuerySelect.subject,
                _id: id,
                _name: lable,
                label: lable,
                // 用来去重
                id: id,
                // 用来展示
                lable: data.creativeFeedName,
                type: '模板',
                releaseStatus: '未发布',
                createTime
              })
            }
          } else {
            // if (this.dialogTitle === '选择推广创意') {
            //   localData = localData.filter((item) => item.id !== data.baiduCreativeFendId)
            // } else {
            localData = localData.filter((item) => item.id !== id)
            // }
          }
        } else {
          // 线上数据
          if (flag) {
            if (this.dialogTitle === '选择推广计划') {
              onlineData.push({
                _id: data.id,
                // 账户管家
                housekeeperId: data.housekeeperId,
                // 投放账户
                baiduId: data.baiduId,
                accountName,
                // 计划名称
                campaignFeedId: data.campaignFeedId,
                campaignFeedName: data.campaignFeedName,
                subject: data.subject,
                qc_id: data.campaignFeedId,
                _name: lable,
                id,
                lable,
                type: '线上',
                createBy,
                createTime,
                releaseStatus: '已发布'
              })
            } else if (this.dialogTitle === '选择推广单元') {
              // console.log(this.onlineQuerySelect)
              onlineData.push({
                // 账户管家
                housekeeperId: data.housekeeperId || this.onlineQuerySelect.housekeeperId,
                // 投放账户名称/ID
                accountName: this.onlineQuerySelect.accountName,
                baiduId: this.onlineQuerySelect.baiduId,
                // 计划名称/ID
                campaignFeedId: this.onlineQuerySelect.campaignFeedId,
                campaignFeedName: this.onlineQuerySelect.campaignFeedName,
                // 单元名称/ID
                baiduUnitId: data.adgroupFeedId,
                baiduUnitName: data.adgroupFeedName,
                // 线上 单元名称/ID
                adgroupFeedId: data.adgroupFeedId,
                adgroupFeedName: data.adgroupFeedName,
                subject: this.onlineQuerySelect.subject,
                count: 1,
                _id: data.id,
                _name: lable,
                id,
                lable,
                label: lable,
                type: '线上',
                createBy,
                createTime,
                releaseStatus: '已发布'
              })
            } else if (this.dialogTitle === '选择推广创意') {
              if (this.onlineData.some((s) => s.disabled && s.disabled === true) || onlineData.some((s) => s.adgroupFeedId === this.onlineQuerySelect.adgroupFeedId)) {
                return this.$message.error('一个单元下只能选择一个模板')
              }
              // console.log(this.onlineQuerySelect)
              onlineData.push({
                ...data,
                count: 1,
                // 账户管家
                housekeeperId: this.onlineQuerySelect.housekeeperId,
                //  投放账户名称/ID
                baiduId: this.onlineQuerySelect.baiduId,
                accountName: this.onlineQuerySelect.accountName,
                // 计划名称/ID
                campaignFeedId: this.onlineQuerySelect.campaignFeedId,
                campaignFeedName: this.onlineQuerySelect.campaignFeedName,
                // 单元名称/ID
                baiduUnitId: this.onlineQuerySelect.id,
                baiduUnitName: this.onlineQuerySelect.lable,
                // 单元名称 线上
                adgroupFeedName: this.onlineQuerySelect.adgroupFeedName,
                adgroupFeedId: this.onlineQuerySelect.adgroupFeedId,
                subject: this.onlineQuerySelect.subject,
                // 创意名称
                creativeFeedId: data.creativeFeedId,
                creativeFeedName: data.creativeFeedName,
                // 用来去重
                id: data.id,
                // 用来展示
                lable: data.creativeFeedName,
                _id: data.id,
                _name: lable,
                type: '线上',
                createBy,
                createTime,
                releaseStatus: '未发布',
                pushStatus: '未发布'
              })
            }
          } else {
            onlineData = onlineData.filter((item) => item.id !== id)
          }
        }

        this.selectedData = {
          local: localData,
          online: onlineData
        }
        console.log(this.selectedData.local)
        this.$emit('getSelectedData', this.selectedData)
      }
    },

    /**
     * @description 获取本地数据
     */
    async setOnLocalQuerySelect(item) {
      if (this.selected.sort === 2) return
      if (this.dialogTitle === '选择推广单元') {
        if (this.localQuerySelect._id === item._id && this.localQuerySelect.baiduId === item.baiduId) return
      } else {
        if (this.localQuerySelect._id === item._id) return
      }
      this.treeLoading = true
      this.localQuerySelect = item
      await this.getBaiduOnLocal(item) // 获取模板数据, 该方法是调用父组件方法
      this.treeLoading = false
      this.setCheckedKeys(this.selectedData.local)
    },

    /**
     * @description 获取线上数据
     */
    async setOnlineQuerySelect(item) {
      if (this.selected.sort === 1 || item === undefined) return
      this.treeLoading = true
      this.onlineQuerySelect = item
      // 获取线上数据, 该方法是调用父组件方法
      this.subTitleInedx = 0
      if (this.subTitleInedx === 0) {
        //   tthis.subTitleActive = item
        // this.treeLoading = true
        await this.getBaiduOnline(item)
      } else {
        await this.getBaiduOnlineTwo(item)
      }
      this.treeLoading = false
      // 线上单元下单元模板
      if (this.dialogTitle === '选择推广单元' && this.subTitleInedx === 1) {
        this.copyList = this.selectedDataOnline_copy[item._id] || []
        this.setCheckedKeys(this.copyList)
      } else {
        this.setCheckedKeys(this.selectedData.online)
      }
    },

    /**
     * @description 删除选中的数据
     */
    deleteItem(id) {
      let localData = this.selectedData.local
      let onlineData = this.selectedData.online
      if (this.selected.sort === 1) {
        this.selectedData.local = localData.filter((f) => f.id !== id)
        this.setCheckedKeys(this.selectedData.local)
      } else {
        this.selectedData.online = onlineData.filter((f) => f.id !== id)
        this.setCheckedKeys(this.selectedData.online)
      }
    },

    /**
     * @description 删除选中的 线上数据中模板数据(x2时都删除)
     */
    deleteItem2(id, campaignFeedName) {
      for (const key in this.selectedDataOnline_copy) {
        if (Object.hasOwnProperty.call(this.selectedDataOnline_copy, key)) {
          const element = this.selectedDataOnline_copy[key]
          this.selectedDataOnline_copy[key] = element.filter((item) => item.id !== id || item.campaignFeedName !== campaignFeedName)
        }
      }
      // 设置 展示 和 勾选
      this.selectedDataOnline()
      this.setCheckedKeys(this.selectedDataOnline_copy[this.onlineQuerySelect.id])
    },

    /**
     * @description 设置选中的项,让复选框打钩
     */
    setCheckedKeys(data) {
      this.$refs.tree.setCheckedKeys(data.map((item) => item.id))
    },

    /**
     * @description 重置数据
     */
    resetData() {
      this.filterText = ''
      // 选中的数据
      this.selectedData = {
        local: [],
        online: []
      }
      // this.localData = []
      // 线上左侧选中的数据
      this.onlineQuerySelect = {}
      this.localQuerySelect = {}
      this.selectedDataOnline_copy = {}
      this.treeLoading = false
      // this.$refs.tree.setCheckedKeys([1000000000000])
    }
  }
}
</script>

<style lang="scss">
.left-tree {
  .el-tree-node > .el-tree-node__content {
    .el-tree-node__label {
      font-weight: 600;
    }
  }
  .el-tree-node__children {
    .el-tree-node__label {
      font-weight: 500 !important;
      margin-left: 10px;
    }
  }
}
.tree {
  .is-leaf {
    display: none;
  }
}
.selected-tree {
  .el-menu-vertical-demo {
    border-right: none !important;
    .el-submenu__title {
      height: 24px;
      line-height: 24px;
      font-size: 14px;
      background: antiquewhite;
    }
    .el-menu {
      li {
        height: 26px;
        line-height: 26px;
      }
    }
    .el-menu-item.is-active {
      color: #333;
    }
  }
}
</style>

<style lang="scss" scoped>
p {
  margin: 0;
  padding: 0;
}

.section {
  height: 500px;
}

.filter-tree-box {
  padding-left: 10px;
  height: 450px;
  overflow-x: auto;
}
.tree {
  height: 100%;
  padding-left: 10px !important;
  border: 1px solid #ccc;
  border-radius: 8px;
  // padding: 20px;
  display: flex;

  .left-ul {
    // padding-top: 10px;
    width: 300px;
    border-right: 1px solid #ccc;
    p {
      font-size: 16px;
      padding: 10px;
      border-bottom: 1px solid #ccc;
    }
    ul {
      overflow-y: auto;
      height: 452px;
      li {
        padding: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        &.active {
          background: #409eff;
          color: #fff;
        }
      }
    }
  }
  &.el-col {
    padding: 0 !important;
  }
}

.tree-select {
  flex: 1;
  .tree-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px;
    border-bottom: 1px solid #ccc;
    p {
      font-size: 16px;
    }
    ul {
      display: flex;
      li {
        margin: 0 10px 0 5px;
        cursor: pointer;
        &.active {
          color: #409eff;
        }
        &.disable {
          color: #ccc;
        }
      }
    }
  }

  // .filter-tree {
  //   padding-left: 10px;
  // }
}
.selected-tree {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-left: 15px;
  height: 500px;
  overflow-y: auto;
  .selected-tree-li {
    display: flex;
    align-items: center;
    padding: 4px;
    &:hover {
      background: #f2f2f2;
      // color: #f2f2f2;
    }
    p {
      // width: 80%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex: 1;
    }
    .count {
      margin: 0 4px;
      padding: 0 3px;
      border: 1px solid #ccc;
      font-size: 10px;
      border-radius: 4px;
    }
    i {
      cursor: pointer;
    }
  }
}
</style>
