var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-container",
    { staticClass: "app-layout" },
    [
      _c(
        "el-header",
        { staticClass: "header", staticStyle: { height: "48px" } },
        [
          _c("div", { staticClass: "system-logo" }, [
            _c(
              "div",
              { staticClass: "system-icon", on: { click: _vm.onClickMenu } },
              [
                _c("img", {
                  attrs: { src: require("@/assets/image/logo.svg"), alt: "" },
                }),
              ]
            ),
            _c("i", {
              staticClass: "iconfont icon-danxingshensuo",
              staticStyle: { color: "#000000", "font-size": "20px" },
              on: { click: _vm.onClickMenu },
            }),
          ]),
          _c("navbar"),
        ],
        1
      ),
      _c(
        "el-container",
        { staticClass: "container" },
        [
          _c(
            "el-aside",
            { staticClass: "aside", staticStyle: { width: "auto !important" } },
            [_vm.sideMenu.length !== 0 ? _c("Sidebar") : _vm._e()],
            1
          ),
          _c(
            "el-main",
            { staticClass: "content", staticStyle: { padding: "0px" } },
            [
              _c(
                "el-scrollbar",
                { staticClass: "scroll", attrs: { id: "mainScroll" } },
                [
                  _c("div", { staticClass: "page-tag" }, [_c("Breadcrumb")], 1),
                  _c(
                    "section",
                    { staticClass: "page-container" },
                    [
                      _c(
                        "transition",
                        { attrs: { name: "fade-transform", mode: "out-in" } },
                        [_c("router-view", { key: _vm.key })],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }