<!--
 * @Description: 工作区 -> 顶部轮播图组件
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2022-03-18 11:54:11
-->

<template>
  <section class="top-slider">
    <div v-if="sliderList.length !== 0 && !sliderList.every((item) => item.pureImageUrl === '')" style="width: 100%">
      <el-carousel height="260px">
        <el-carousel-item v-for="item in sliderList" :key="item.id">
          <img :src="item.pureImageUrl || defaultImg" alt="" style="width:100%" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div v-else style="text-align: center">
      <i v-for="(item, index) in sliderList.length ? sliderList : 3" :key="index" class="el-icon-picture-outline main-color"></i>
      <p class="main-color">顶部轮播图组件</p>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      sliderList: []
    }
  },

  watch: {
    detail: {
      handler(value) {
        this.sliderList = value.componentGroupList.componentGroup.map((item) => {
          return {
            id: item.id,
            ...item.componentItem
          }
        })
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.top-slider {
  width: 100%;
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(222, 237, 247);
  display: flex;
  flex-direction: column;
  i {
    font-size: 30px;
    color: #ccc;
  }
  p {
    margin-top: 10px;
    font-size: 20px;
    font-weight: 600;
    color: #ccc;
  }
}
</style>
