<template>
  <el-submenu v-if="item.children" :index="item.campaignFeedName">
    <template slot="title">
      <el-tooltip class="item" effect="dark" :content="item.campaignFeedName" placement="top">
        <div class="text-ellipsis">{{ item.campaignFeedName }}</div>
      </el-tooltip>
    </template>
    <el-menu-item v-for="(child, i) in item.children" :key="i" :index="child.id">
      <div class="menu-item-text">
        <p class="text-ellipsis">{{ child.lable }}</p>
        <slot :id="child.id" :campaignFeedName="item.campaignFeedName"> </slot>
      </div>
    </el-menu-item>
  </el-submenu>
</template>

<script>
export default {
  name: 'SidebarItem',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss">
p {
  margin: 0;
  padding: 0;
}
.menu-item-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-tooltip__popper.is-dark .icon {
  width: 30px;
  height: 30px;
  margin-right: 18px;
}

.menu.el-menu {
  .el-menu {
    background-color: #efeff4;
  }

  & > .el-menu-item,
  .el-submenu__title {
    height: 42px;
    line-height: 42px;
    padding-bottom: 10px;
  }

  .el-menu-item,
  .el-submenu__title {
    color: #555a6f;
    font-size: 14px;
  }

  .icon {
    margin-right: 11px;
    margin-left: 10px;
    font-size: 18px;
  }

  .icon-dot {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #409eff;
    margin-right: 11px;
  }

  .icon-menu-img {
    width: 16px;
    height: 16px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
    margin-right: 11px;
    margin-left: 10px;
    background-position-x: left;
  }

  .el-submenu .el-menu-item {
    padding-left: 40px !important;
    //margin-left: 20px !important;
  }

  .el-menu-item.is-active,
  .el-submenu.is-active {
    background-color: #fff;

    &:focus {
      background-color: #fff;
    }

    &:hover {
      background-color: #fff;
    }

    .icon-menu-img {
      background-position-x: right;
    }

    .el-submenu__title {
      color: #409eff;
    }
  }

  & > .el-menu-item:hover,
  .el-submenu__title:hover {
    background-color: inherit;
    color: #409eff;

    .icon {
      color: #409eff;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: transparent;
    }

    .icon-menu-img {
      background-position-x: right;
    }
  }

  .el-submenu.is-active .el-submenu__title {
    background-color: #efeff4;
  }

  .el-menu.el-menu--inline {
    .el-menu-item {
      &:focus {
        background-color: transparent;
      }

      &:hover {
        background-color: transparent;
        color: #409eff;
        transition: transform 0.1s linear;
      }

      &.is-active {
        color: #409eff;
        font-weight: bold;
        padding-left: 40px !important;
        background-color: #fff;
        margin-right: -2px;

        &:before {
          content: '';
          display: inline-block;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: #409eff;
          margin-right: 16px;
        }

        .icon-menu-img {
          background-position-x: right;
        }
      }
    }
  }
}
</style>
