<template>
  <!--付费卡点弹框-->
  <el-dialog
    class="pay-card-point-dialog"
    title="付费卡点"
    :visible.sync="show"
    :append-to-body="true"
    width="700px"
    top="20vh"
    @close="onCloseDialog"
  >
    <div class="page-container">
      <el-form
        ref="form"
        :inline="false"
        :model="form"
        :rules="rules"
      >
        <el-form-item
          label="付费卡点"
          prop="payCardPonits"
        >
          <el-select
            v-model="form.payCardPonits"
            multiple
            collapse-tags
            filterable
            style="width: 200px"
            placeholder="请选择付费卡点"
          >
            <el-option
              v-for="item in options"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>

          <el-button
            type="primary"
            style="margin-left: 10px"
            :loading="saveLoading"
            @click="handleAddType"
          >添加
          </el-button>
        </el-form-item>
      </el-form>

      <el-table
        v-loading="loading"
        :data="tableData"
        border
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="80px"
        ></el-table-column>

        <el-table-column
          prop="userName"
          label="人员"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="createTime"
          label="时间"
          align="center"
          width="200px"
        ></el-table-column>

        <el-table-column
          prop="payCardPonit"
          label="卡点"
          align="center"
        ></el-table-column>

        <el-table-column
          label="操作"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-button
              v-if="row.isDelete"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(row.toolProductPayCardPointId)"
            ></el-button>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-container">
        <c-pagination
          :page-size.sync="pageSize"
          :page-no.sync="pageNum"
          :total="total"
          :pager-count="5"
          tools="total, prev, pager, next"
          :get-data-fun="getTableData"
        />
      </div>
    </div>
    <!--底部按钮-->
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        class="btn"
        @click="closeModal"
      >关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { PageCommonComponent } from '@/common/mixin'
import {
  paidCardPointsList,
  deletePaidCardPoints,
  savePaidCardPoints
} from '@/network/api/toolManagement/api-product-test'
export default {
  name: 'PayCardPointDialog',
  mixins: [PageCommonComponent],
  props: {},
  data() {
    return {
      show: false,
      loading: false,
      saveLoading: false,

      form: {
        toolProductId: '',
        payCardPonits: [1]
      },

      rules: {
        payCardPonits: [
          { required: true, message: '请选择付费卡点', trigger: 'change' }
        ]
      },
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 5
    }
  },
  computed: {
    options() {
      return Array.from({ length: 30 }, (_, i) => 1 + i)
    },
    ...mapGetters(['userInfo'])
  },
  methods: {
    /* --------------------------- Actions --------------------------- */
    showModal(id) {
      this.show = true
      this.form.toolProductId = id
      this.getTableData()
    },

    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      this.tableData = []
      this.form = {
        toolProductId: '',
        payCardPonits: [1]
      }
      this.$refs['form'].clearValidate()
    },

    // 关闭弹框
    closeModal() {
      this.show = false
    },

    // 获取列表数据
    getTableData() {
      this.loading = true
      paidCardPointsList(this.form.toolProductId, this.pageNum, this.pageSize)
        .then(({ code, data }) => {
          if (code === 200) {
            this.tableData = data.records || []
            this.tableData = this.tableData.map((item) => {
              return {
                userName: item.toolProductLaunchPerson.userName,
                createTime: item.toolProductPayCardPoint.createTime,
                payCardPonit: item.toolProductPayCardPoint.payCardPonit,
                isDelete: item.toolProductLaunchPerson.userId === this.userInfo.userid,
                toolProductPayCardPointId: item.toolProductPayCardPoint.toolProductPayCardPointId
              }
            })
            console.log(this.tableData)
            this.total = data.total
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    // 点击添加
    handleAddType() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveLoading = true
          let parmas = Object.assign({}, this.form)
          savePaidCardPoints(parmas)
            .then(({ code }) => {
              if (code === 200) {
                this.getTableData()
                this.form.payCardPonits = []
                this.$emit('confirm')
                this.$message.success('添加成功')
              }
            })
            .finally(() => {
              this.saveLoading = false
            })
        }
      })
    },

    // 点击删除
    handleDelete(id) {
      deletePaidCardPoints(id).then(({ code }) => {
        if (code === 200) {
          this.getTableData()
          this.$emit('confirm')
          this.$message.success('删除卡点成功')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination-container {
  height: 64px;
  padding-right: 50px;
  box-sizing: border-box;
}
</style>
