<template>
  <!--分配投手弹框-->
  <el-dialog class="test-pitcher-dialog" title="分配投手" :visible.sync="show" :append-to-body="true" width="760px" top="20vh"
    @close="onCloseDialog">
    <div class="page-container">
      <el-form ref="form" :inline="true" label-width="90px" label-suffix="：" :model="form" :rules="rules">
        <el-form-item label="组别" prop="departmentId">
          <el-cascader ref="deptRef" v-model="form.departmentId" :options="deptList" :props="cascaderProp" filterable placeholder="请选择组别"
            @change="handleSelectDept"></el-cascader>
        </el-form-item>
        <el-form-item label="人员" prop="userId">
          <el-select v-model="form.userId" filterable placeholder="请选择名称" multiple collapse-tags>
            <el-option v-for="(itm, idx) in userList" :key="idx" :label="itm.userName" :value="itm.userId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="广告位" prop="adSpace">
          <el-select v-model="form.adSpace" placeholder="请选择广告位" multiple collapse-tags>
            <el-option v-for="(itm, idx) in adSites" :key="idx" :label="itm.label" :value="itm.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="margin-left: 10px" :loading="saveLoading" @click="handleAddType">添加
          </el-button>
        </el-form-item>
        <!-- <el-form-item label="名称" prop="userId">
          <el-select v-model="form.userId" filterable style="width: 300px" placeholder="请选择名称" multiple collapse-tags>
            <el-option v-for="(itm, idx) in userList" :key="idx" :label="itm.label" :value="itm.key"></el-option>
          </el-select>
          <el-button type="primary" style="margin-left: 10px" :loading="saveLoading" @click="handleAddType">添加
          </el-button>
        </el-form-item> -->
      </el-form>
      <el-divider></el-divider>
      <div class="tag-box">
        <div class="tag-title">分配投手</div>
        <el-table :data="tableData" size="small">
          <el-table-column label="组别" prop="deptName"></el-table-column>
          <el-table-column label="人员" prop="userName"></el-table-column>
          <el-table-column label="广告位" prop="adSpace" show-overflow-tooltip></el-table-column>
          <el-table-column label="分配时间" prop="allocationTime"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="{row}">
              <el-button type="text" size="small" @click="handleClose(row.toolProductPersonId)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- <el-tag v-for="tag in tableData" :key="tag.toolProductPersonId" :closable="tag.testDelete"
          style="margin-right: 10px;" @close="handleClose(tag.toolProductPersonId)">
          {{ tag.userName }}
        </el-tag> -->
      </div>
    </div>
    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {
  deletePitcherOrMaterial,
  savePitcherOrMaterial,
  getPitcherOrMaterial,
  getDeptList,
  getUserList
} from '@/network/api/toolManagement/api-product-test'

// import { getUser } from '@/network/api/user'

export default {
  name: 'TestPitcherDialog',
  props: {},
  data() {
    return {
      show: false,
      loading: false,
      saveLoading: false,
      cascaderProp: {
        emitPath: false,
        checkStrictly: true,
        value: 'departmentId',
        label: 'departmentName',
        children: 'children'
      },

      form: {
        departmentId: '', // 组别id
        departmentName: '', // 组别名称
        userId: [], // 类型名称
        toolProductId: '',
        type: 'TEST',
        adSpace: []
      },

      deptList: [],
      userList: [],
      adSites: [
        { key: 1, label: 'XS' },
        { key: 2, label: 'XQ' },
        { key: 3, label: '朋友圈' },
        { key: 4, label: '视频号' },
        { key: 5, label: '公众号' },
        { key: 6, label: '头条' }
      ],

      rules: {
        userId: [
          { required: true, message: '请选择人员', trigger: 'change' }
        ],
        departmentId: [
          { required: true, message: '请选择组别', trigger: 'change' }
        ]
      },

      tableData: []
    }
  },
  methods: {
    _getDeptList() {
      getDeptList({ departmentName: '' }).then(res => {
        let list = res.data.map(v => {
          return {
            departmentId: v.departmentId,
            departmentName: v.departmentName,
            children: v.children ? JSON.parse(v.children) : []
          }
        })
        this.deptList = list || []
      })
    },
    // 获取成员列表
    _getUserList() {
      getUserList({ departmentId: this.form.departmentId, userName: '' }).then((res) => {
        if (res.code === 200) {
          this.userList = res.data
        }
      })
    },

    // 获取当前测试投手
    _getPitcherOrMaterial() {
      getPitcherOrMaterial(this.form.toolProductId, 'TEST').then(res => {
        if (res.code === 200) {
          this.tableData = res.data
        }
      })
    },

    /* --------------------------- Actions --------------------------- */
    showModal(id) {
      this.show = true
      this.form.toolProductId = id
      this._getDeptList()
      this._getPitcherOrMaterial()
    },

    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      this.tableData = []
      this.form = {
        departmentId: '', // 组别id
        departmentName: '', // 组别名称
        userId: [], // 类型名称
        toolProductId: '',
        type: 'TEST',
        adSpace: []
      }
      this.$refs['form'].clearValidate()
    },

    // 关闭弹框
    closeModal() {
      this.$refs['form'].resetFields()
      this.show = false
    },

    // 选择部门
    handleSelectDept(value) {
      this.form.userId = []
      this._getUserList()
    },

    // 点击添加
    handleAddType() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let node = this.$refs.deptRef.getCheckedNodes()
          let params = {
            ...this.form,
            adSpace: this.form.adSpace.join(','),
            departmentName: node.length ? node[0].label : ''
          }
          this.saveLoading = true
          savePitcherOrMaterial(params)
            .then(({ code }) => {
              if (code === 200) {
                this._getPitcherOrMaterial()
                this.$emit('confirm')
                this.$message.success('添加成功')
              }
            })
            .finally(() => {
              this.saveLoading = false
            })
        }
      })
    },

    // 点击删除
    handleClose(toolProductPersonId) {
      deletePitcherOrMaterial(toolProductPersonId).then(({ code }) => {
        if (code === 200) {
          this._getPitcherOrMaterial()
          this.$message.success('删除成功')
          this.$emit('confirm')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tag-title {
  margin-bottom: 10px;
}
</style>
