var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "workbench" },
    [
      _c(
        "div",
        {
          on: {
            click: function ($event) {
              return _vm.setSelectedIndex(-1)
            },
          },
        },
        [
          _vm.finalTopWidgets.component
            ? _c(
                "el-tooltip",
                { attrs: { placement: "right-start" } },
                [
                  _c("template", { slot: "content" }, [
                    _c("i", {
                      staticClass: "el-icon-delete-solid",
                      on: {
                        click: function ($event) {
                          return _vm.deleteItem(-1)
                        },
                      },
                    }),
                  ]),
                  _c(
                    "div",
                    {
                      class: {
                        active: _vm.selectWidgetsIndex === -1,
                        widget: true,
                      },
                    },
                    [
                      _c(_vm.finalTopWidgets.component, {
                        tag: "component",
                        attrs: { detail: _vm.finalTopWidgets },
                      }),
                    ],
                    1
                  ),
                ],
                2
              )
            : _c("div", { staticClass: "top-component" }, [
                _c("i", { staticClass: "el-icon-s-help" }),
                _c("h3", [_vm._v("顶部组件")]),
                _c("p", [_vm._v("选择一个顶部组件到此处")]),
              ]),
        ],
        1
      ),
      _c(
        "draggable",
        {
          attrs: { animation: 500 },
          on: { end: _vm.end },
          model: {
            value: _vm.finalWidgets,
            callback: function ($$v) {
              _vm.finalWidgets = $$v
            },
            expression: "finalWidgets",
          },
        },
        [
          _c(
            "transition-group",
            _vm._l(_vm.finalWidgets, function (ele, index) {
              return _c(
                "div",
                {
                  key: ele.widgetTypeV2,
                  class: { widget: true },
                  on: {
                    click: function ($event) {
                      return _vm.setSelectedIndex(index)
                    },
                  },
                },
                [
                  ele.widgetTypeV2 === "floatbutton"
                    ? _c(
                        "div",
                        [
                          _c(
                            "el-tooltip",
                            { attrs: { placement: "right-start" } },
                            [
                              _c("template", { slot: "content" }, [
                                _c("i", {
                                  staticClass: "el-icon-delete-solid",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteItem(index)
                                    },
                                  },
                                }),
                              ]),
                              _c("m-floatButton", {
                                attrs: {
                                  detail: ele,
                                  index: index,
                                  "select-widgets-index":
                                    _vm.selectWidgetsIndex,
                                },
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          class: {
                            active: _vm.selectWidgetsIndex === index,
                            widget: true,
                          },
                        },
                        [
                          _c(
                            "el-tooltip",
                            { attrs: { placement: "right-start" } },
                            [
                              _c("template", { slot: "content" }, [
                                _c("i", {
                                  staticClass: "el-icon-delete-solid",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteItem(index)
                                    },
                                  },
                                }),
                              ]),
                              _c(
                                "div",
                                [
                                  _c(ele.component, {
                                    tag: "component",
                                    attrs: { detail: ele },
                                  }),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
      _vm.finalWidgets.some((s) => s.widgetTypeV2 === "floatbutton")
        ? _c("div", { staticStyle: { height: "62px" } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }