<!--
 * @Description: Do not edit
 * @Date: 2021-10-13 16:03:30
 * @LastEditTime: 2022-04-01 11:28:09
-->
<template>
  <el-form ref="searchForm" class="search-form search-form-4" :model="searchForm">
    <el-row :gutter="20">
      <el-col :span="6">
        <el-form-item prop="pageName" label="名称">
          <el-input v-model="searchForm.pageName" style="width: 100%" placeholder="请输入名称"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="displayId" label="ID">
          <el-input v-model="searchForm.id" style="width: 100%" placeholder="请输入ID"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="displayId" label="管家账号">
          <el-select v-model="searchForm.displayId" style="width: 100%" placeholder="请选择管家账号" @change="AdvENTListchange">
            <el-option v-for="(item, index) in accountHugeHousekeeperENTs" :key="index" :label="item.accountName" :value="item.accountId"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="campaignAccount" label="投放账户">
          <el-select v-model="searchForm.campaignAccount" :disabled="!searchForm.displayId" filterable style="width: 100%" placeholder="请选择投放账户">
            <el-option v-for="(item, index) in accountHugeAdvENTLists[accountHugeHousekeeperENTsId]" :key="index" :label="item.accountName" :value="item.accountId"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-button class="btn" type="primary" @click="search">
          查询
        </el-button>
        <el-button class="btn" @click="resetSearch">
          重置
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { searchForm } from './object'
export default {
  inject: ['loadData'],
  props: {
    accountHugeHousekeeperENTs: {
      type: Array,
      default: () => []
    },
    accountHugeAdvENTLists: {
      type: Array,
      default: () => []
    },
    accountHugeHousekeeperENTsId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      searchForm: searchForm
    }
  },
  methods: {
    search() {
      this.loadData(true)
    },
    // 折中方案
    getSearchForm() {
      return { ...this.searchForm }
    },
    resetSearch() {
      this.$refs.searchForm.resetFields()
      this.loadData(true)
    },
    AdvENTListchange(value) {
      this.accountHugeHousekeeperENTsId = value
      this.searchForm.campaignAccount = null
    }
  }
}
</script>
