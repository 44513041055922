<template>
  <div v-loading="loading" class="tempalte">
    <!-- 顶部步骤条 -->
    <template v-if="isShowStep">
      <el-steps :active="active">
        <el-step title="步骤 1"></el-step>
        <el-step title="步骤 2"></el-step>
      </el-steps>
    </template>
    <!-- 步骤1 -->
    <template v-if="active === 1">
      <el-form class="active-one" label-width="100px">
        <el-form-item label="投放版本">
          <el-select v-model="ruleData.launchVersion" style="width: 300px;">
            <el-option v-for="(item, index) in launchVersions" :key="index" :label="item.text" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="营销链路">
          <el-select v-model="ruleData.marketingPurpose" style="width: 300px;">
            <el-option v-for="(item, index) in marketingPurposes" :key="index" :label="item.text" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="推广目的">
          <el-select v-model="ruleData.landingType" style="width: 300px;">
            <el-option v-for="(itm, idx) in landingTypes" :key="idx" :label="itm.text" :value="itm.id"></el-option>
          </el-select>
        </el-form-item>
        <el-button type="primary" plain @click="handleNextStep">下一步</el-button>
      </el-form>
    </template>
    <!-- 步骤2 -->
    <template v-if="active === 2">
      <div class="config">
        <!-- 程序化配置基本信息 -->
        <Base title="基础信息">
        <el-form :inline="true" :model="ruleData" :rules="basicRule" label-width="100px">
          <el-row :gutter="24">
            <el-col :span="6">
              <el-form-item label="任务名称" prop="taskName">
                <el-input v-model="ruleData.taskName" placeholder="请输入任务名称" style="width: 300px;" maxlength="30"
                  @blur="handleInputName" show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="项目" prop="projectId">
                <el-select v-model="ruleData.projectId" filterable placeholder="请选择项目" style="width: 300px;"
                  @change="handleChangeProject">
                  <el-option v-for="(item, index) in projectList" :key="index" :label="item.projectName"
                    :value="item.bsProjectId + ''"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="产品" prop="productId">
                <el-select v-model="ruleData.productId" filterable :disabled="!ruleData.projectId" placeholder="请选择产品"
                  style="width: 300px;" @change="handleChangeProduct">
                  <el-option label="不限" value="-1"></el-option>
                  <el-option v-for="(item, index) in productList" :key="index" :label="item.productName"
                    :value="item.bsProductId + ''"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="管理账号" prop="housekeeperId">
                <el-select v-model="ruleData.housekeeperId" placeholder="请选择管理账号" style="width: 300px;" filterable
                  @change="handleChangeHouseKeeper">
                  <el-option v-for="(item, index) in houseKeeperList" :key="index" :label="item.accountName"
                    :value="item.accountId">
                    <span>{{ item.accountName }}({{ item.accountId }})</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="投放账户" prop="advertisers">
                <el-select v-model="ruleData.advertisers" placeholder="请选择投放账户" collapse-tags style="width: 300px;"
                  value-key="advertiserId" :disabled="!ruleData.housekeeperId" filterable multiple
                  @change="handleChangeAdv">
                  <el-option v-for="(item, index) in advertiserList" :key="index" :label="item.name" :value="item">
                    <span>{{ item.name }}({{ item.advertiserId }})</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="推广目的" prop="landingType">
                <el-select v-model="ruleData.landingType" style="width: 300px;">
                  <el-option v-for="(itm, idx) in landingTypes" :key="idx" :label="itm.text" :value="itm.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        </Base>

        <!-- 配置区 -->
        <Base title="配置区">
        <div class="config-body">
          <!-- 配置区内容 -->
          <div class="config-body-content">
            <!-- 配置区内容标题 -->
            <div class="config-body-content-title-box">
              <el-row :gutter="28" class="config-body-content-title">
                <el-col :span="3">
                  <div class="group content-title">广告组</div>
                </el-col>
                <el-col :span="6">
                  <div class="plan  content-title">广告计划</div>
                </el-col>
                <el-col :span="15">
                  <div class="creative  content-title">广告创意</div>
                </el-col>
              </el-row>
            </div>

            <el-row :gutter="28" class="config-body-content-middle">
              <!-- 广告组信息 -->
              <el-col :span="3">
                <div class="item">
                  <div class="item-header">
                    <div class="left">
                      <span>广告组信息</span>
                    </div>
                  </div>
                  <div class="item-body">
                    <div>广告组预算: <span>{{ advertiseGroup.budgetMode == 'BUDGET_MODE_INFINITE' ? '不限' :
                        advertiseGroup.budget
                    }}</span></div>
                    <div>广告组默认状态: <span>{{ advertiseGroup.groupOperation == 'enable' ? '开启' : '暂停' }}</span></div>
                    <div>组内计划上限: <span>{{ advertiseGroup.groupPlanMax || GROUP_PLAN_NUM }}</span></div>
                  </div>
                  <el-button v-if="isBasicHasValue" class="item-bottom" icon="el-icon-edit" @click="handleClickRules">编辑
                  </el-button>
                  <el-popover v-if="!isBasicHasValue" placement="top" trigger="hover" content="请填写基础信息">
                    <el-button slot="reference" type="text" icon="el-icon-edit" class="item-bottom disabled">编辑
                    </el-button>
                  </el-popover>
                </div>
              </el-col>

              <!-- 计划基本信息 -->
              <el-col :span="3">
                <div class="item">
                  <div class="item-header">
                    <div class="left">
                      <span>计划基本信息</span>
                    </div>
                  </div>
                  <div class="item-body">
                    <div>广告位置: <span v-if="advertisePlan.id">{{ advertisePlan.inventoryCatalog |
                        filterInventoryCatalog
                    }}</span><span v-else>无</span></div>
                    <div>营销链路: <span>{{ advertisePlan.id ? (advertisePlan.marketingPurpose === 'CONVERSION' ? '行动转化' :
                        '无') : '无'
                    }}</span></div>
                    <div>投放内容: <span>{{ computedDeliverContent }}</span>
                    </div>
                    <div>转化目标: <span>{{ advertisePlan.convertType | filterConvertType }}</span></div>
                    <div>转化出价: <span>{{ advertisePlan.cpaBid || 0 }}</span></div>
                  </div>
                  <el-button v-if="advertiseGroup.id" class="item-bottom" icon="el-icon-edit" @click="handleClickPlan">
                    编辑
                  </el-button>
                  <el-popover v-if="!advertiseGroup.id" placement="top" trigger="hover" content="请填写广告组信息">
                    <el-button slot="reference" type="text" icon="el-icon-edit" class="item-bottom disabled">编辑
                    </el-button>
                  </el-popover>
                </div>
              </el-col>
              <!-- 定向包 -->
              <el-col :span="3">
                <div class="item">
                  <div class="item-header">
                    <div class="left">
                      <span>定向包</span>
                      <span class="left-desc">{{ audiencePackages.length }}/{{ AUDIENCE_TOTAL }}</span>
                    </div>
                    <div class="right">
                      <el-button type="text" icon="el-icon-refresh" size="mini" @click="handleCleanAudience">清空
                      </el-button>
                    </div>
                  </div>
                  <div class="item-body">
                    <div>已选定向包:</div>
                    <div v-for="(item, index) in audiencePackages" :key="index" class="item-title-item">
                      <el-tooltip effect="dark" :content="item.name" placement="top">
                        <span class="title-text">{{ item.name }}</span>
                      </el-tooltip>
                      <i class="el-icon-circle-close" @click="handleDeletePackage(item, index)"></i>
                    </div>
                  </div>
                  <el-button v-if="advertiseGroup.id" class="item-bottom" icon="el-icon-plus"
                    @click="handleClickAudience">
                    添加
                  </el-button>
                  <el-popover v-if="!advertiseGroup.id" placement="top" trigger="hover" content="请填写广告组信息">
                    <el-button slot="reference" type="text" icon="el-icon-plus" class="item-bottom disabled">添加
                    </el-button>
                  </el-popover>
                </div>
              </el-col>
              <!-- 创建基本信息 -->
              <el-col :span="3">
                <div class="item">
                  <div class="item-header">
                    <div class="left">
                      <span>创建基本信息</span>
                    </div>
                  </div>
                  <div class="item-body">
                    <div>来源：<span>{{ advertiseCreative.source || '无' }}</span></div>
                    <div>广告评论： <span>{{ advertiseCreative.id ? (advertiseCreative.isCommentDisable === 1 ? '关闭' : '启用')
                        : '无'
                    }}</span></div>
                    <div>创意分类： <span>{{ advertiseCreative.thirdIndustryNames || '无' }}</span></div>
                  </div>
                  <el-button v-if="advertisePlan.id" class="item-bottom" icon="el-icon-edit"
                    @click="handleClickCreative">
                    编辑
                  </el-button>
                  <el-popover v-if="!advertisePlan.id" placement="top" trigger="hover" content="请填写计划基本信息">
                    <el-button slot="reference" type="text" icon="el-icon-edit" class="item-bottom disabled">编辑
                    </el-button>
                  </el-popover>
                </div>
              </el-col>
              <!-- 创意素材 -->
              <el-col :span="6">
                <div class="item">
                  <div class="item-header">
                    <div class="left">
                      <span>创意素材</span>
                      <span class="left-desc">已选创意组: {{ creativeMedia.groups.length }}/{{ MATERIAL_TOTAL }}</span>
                    </div>
                    <div class="right">
                      <el-button type="text" icon="el-icon-refresh" size="mini" @click="handleCleanMaterial">清空
                      </el-button>
                    </div>
                  </div>
                  <div class="item-material-body">
                    <div v-for="(item, index) in creativeMedia.groups" :key="index" class="material-group">
                      <div class="material-group-title">{{ item.groupName }}</div>
                      <div class="material-group-list">
                        <div v-for="(itm, idx) in item.videos" :key="`video-${idx}`" class="videoImage">
                          <el-image :src="itm.localImageUrl" fit="cover"></el-image>
                          <!-- <i class="el-icon-circle-close" @click="handleDeleteMaterial(index, idx, 'video')"></i> -->
                        </div>
                        <div v-for="(itm, idx) in item.images" :key="`image-${idx}`" class="image">
                          <el-image :src="itm.localImageUrl" fit="cover"></el-image>
                          <!-- <i class="el-icon-circle-close" @click="handleDeleteMaterial(index, idx, 'image')"></i> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <el-button v-if="advertisePlan.id" class="item-bottom" icon="el-icon-plus"
                    @click="handleClickCreativeMaterial">
                    添加
                  </el-button>
                  <el-popover v-if="!advertisePlan.id" placement="top" trigger="hover" content="请填写计划基本信息">
                    <el-button slot="reference" type="text" icon="el-icon-plus" class="item-bottom disabled">添加
                    </el-button>
                  </el-popover>
                </div>
              </el-col>
              <!-- 标题包 -->
              <el-col :span="3">
                <div class="item">
                  <div class="item-header">
                    <div class="left">
                      <span>标题包</span>
                      <span class="left-desc">{{ titlePackages.length }}/{{ TITLE_TOTAL }}</span>
                    </div>
                    <div class="right">
                      <el-button type="text" icon="el-icon-refresh" size="mini" @click="handleCleanTitle">清空</el-button>
                    </div>
                  </div>
                  <div class="item-body">
                    <div>已选标题包:</div>
                    <div v-for="(item, index) in titlePackages" :key="index" class="item-title-item">
                      <el-tooltip effect="dark" :content="item.titlePackageName"
                        placement="top">
                        <span class="title-text">{{ item.titlePackageName }}</span>
                      </el-tooltip>
                      <i class="el-icon-circle-close" @click="handleDeleteTitle(item, index)"></i>
                    </div>
                  </div>
                  <el-button v-if="advertisePlan.id" class="item-bottom" icon="el-icon-plus" @click="handleClickTitle">
                    添加
                  </el-button>
                  <el-popover v-if="!advertisePlan.id" placement="top" trigger="hover" content="请填写计划基本信息">
                    <el-button slot="reference" type="text" icon="el-icon-plus" class="item-bottom disabled">添加
                    </el-button>
                  </el-popover>
                </div>
              </el-col>
              <!-- 落地页 -->
              <el-col :span="3">
                <div style="border: none;" class="item">
                  <div class="item-header">
                    <div class="left">
                      <span>落地页</span>
                    </div>
                    <div class="right">
                      <el-button type="text" icon="el-icon-refresh" size="mini" @click="handleCleanPages">清空</el-button>
                    </div>
                  </div>
                  <div class="item-body">
                    <div>已选落地页:</div>
                    <div v-for="(item, index) in landingPages" :key="index" class="item-title-item">
                      <el-tooltip effect="dark" :content="item.name" placement="top">
                        <span class="title-text">{{ item.name }}</span>
                      </el-tooltip>
                      <i class="el-icon-circle-close" @click="handleDeletePages(item, index)"></i>
                    </div>
                  </div>
                  <el-button v-if="advertisePlan.id" class="item-bottom" icon="el-icon-plus" @click="handleClickPages">
                    添加
                  </el-button>
                  <el-popover v-if="!advertisePlan.id" placement="top" trigger="hover" content="请填写计划基本信息">
                    <el-button slot="reference" type="text" icon="el-icon-plus" class="item-bottom disabled">添加
                    </el-button>
                  </el-popover>
                </div>
              </el-col>
            </el-row>
          </div>
          <!-- 配置区底部 -->
          <el-row :gutters="20" class="config-body-content-bottom">
            <el-col :span="22">
              <el-button v-if="advertisePlan.id && advertisePlan.trackType == 1" @click="handleClickTracking">转化追踪
              </el-button>
              <el-button v-if="advertisePlan.id && advertisePlan.trackType == 2 && advertisePlan.contentType !== 'TETRIS_EXTERNAL'" @click="handleClickAssets">资产选择
              </el-button>
              <el-button :disabled="!ruleData.advertisers.length" @click="handleClickTraceUrl">监测链接</el-button>
              <el-button v-if="advertiseCreative.id && advertiseCreative.douyin == 2" @click="handleClickDouyin">抖音号
              </el-button>
            </el-col>
            <el-col :span="2" style="text-align: right;">
              <el-button type="primary" :disabled="!showPreviewBtn" :loading="previewLoading"
                @click="handleClickPreviewAd">预览广告
              </el-button>
            </el-col>
          </el-row>
        </div>
        </Base>

        <!-- 预览区 -->
        <Base v-if="previewData.length" title="预览区">
        <preview-ad :info="previewData" :base-id="baseId"></preview-ad>
        </Base>

        <!-- 广告组基础信息（原规则弹窗） -->
        <c-rules-config-dialog ref="rulesConfigDialog" :base-id="baseId" @confirm="getRuleConfig">
        </c-rules-config-dialog>

        <!-- 广告基本信息弹窗 -->
        <ad-info-dialog ref="adInfoDialog" :base-id="baseId" @confirm="getAdInfo"></ad-info-dialog>

        <!-- 定向包选择弹窗 -->
        <orientation-package-dialog ref="orientationPackageDialog" :base-id="baseId" @confirm="getPackageData">
        </orientation-package-dialog>

        <!-- 创意基本信息弹窗 -->
        <creative-info-config-dialog ref="creativeInfoConfigDialog" :base-id="baseId" :housekeeper-id="ruleData.housekeeperId" @confirm="getCreative">
        </creative-info-config-dialog>

        <!-- 创意素材弹窗 -->
        <material-select-dialog ref="materialSelectDialog" :base-id="baseId" :project-list="projectList"
          :advertisers="advertisers" @confirm="getMaterialList">
        </material-select-dialog>

        <!-- 标题包弹窗 -->
        <text-select-dialog ref="textSelectDialog" :base-id="baseId" @confirm="getTxtList"></text-select-dialog>

        <!-- 落地页选择弹窗 -->
        <landing-pages-select-dialog ref="landingPageSelectDialog" :base-id="baseId" :housekeeper-id="ruleData.housekeeperId" @confirm="getLandingPages">
        </landing-pages-select-dialog>

        <!-- 转化追踪 -->
        <conversion-tracking-dialog ref="conversionTrackingDialog" :base-id="baseId" :housekeeper-id="ruleData.housekeeperId" @confirm="getTrackingData">
        </conversion-tracking-dialog>

        <!-- 资产选择弹窗 -->
        <assets-select-dialog ref="assetsSelectDialog" :base-id="baseId" :housekeeper-id="ruleData.housekeeperId" @confirm="getAssetsData">
        </assets-select-dialog>

        <!-- 抖音号选择 -->
        <douyin-select-dialog ref="douyinSelectDialog" :base-id="baseId" @confirm="getDouyinData">
        </douyin-select-dialog>

        <!-- 监测链接 -->
        <trace-url-dialog ref="traceUrlDialog" :base-id="baseId" @confirm="getTraceUrlData"></trace-url-dialog>
      </div>
    </template>
  </div>
</template>

<script>
import { getMeRelationProject } from '@/network/api/basicSetting/project/basicSettingProject'
import {
  getProductByProject
} from '@/network/api/basicSetting/product/basicSettingProduct'
import {
  getById, getHouseKeeper, getAdvertisers,
  saveConfigBase, saveConfigTask, cleanAudiencePackage,
  deleteAudiencePackage, cleanCreativeMaterial, cleanTitlePackage,
  deleteTitlePackage, cleanConfigBase, deletePages, cleanPages,
  previewConfigPlans
} from '@/network/api/api-huge-batch'

import Base from '@/views/advManagement/baidu/baiduBatch/component/base.vue'
import RulesConfigDialog from './components/RulesConfigDialog'
import AdInfoDialog from './components/AdInfoDialog'
import OrientationPackageDialog from './components/OrientationPackageDialog'
import CreativeInfoConfigDialog from './components/CreativeInfoConfigDialog'
import MaterialSelectDialog from './components/MaterialSelectDialog'
import TextSelectDialog from './components/TextSelectDialog'
import LandingPageSelectDialog from './components/LandingPageSelectDialog'
import ConversionTrackingDialog from './components/ConversionTrackingDialog'
import AssetsSelectDialog from './components/AssetsSelectDialog'
import DouyinSelectDialog from './components/DouyinSelectDialog'
import TraceUrlDialog from './components/TraceUrlDialog'
import previewAd from './previewAd.vue'

export default {
  components: {
    Base,
    'c-rules-config-dialog': RulesConfigDialog,
    'ad-info-dialog': AdInfoDialog,
    'orientation-package-dialog': OrientationPackageDialog,
    'creative-info-config-dialog': CreativeInfoConfigDialog,
    'material-select-dialog': MaterialSelectDialog,
    'text-select-dialog': TextSelectDialog,
    'landing-pages-select-dialog': LandingPageSelectDialog,
    'conversion-tracking-dialog': ConversionTrackingDialog,
    'assets-select-dialog': AssetsSelectDialog,
    'douyin-select-dialog': DouyinSelectDialog,
    'trace-url-dialog': TraceUrlDialog,
    'preview-ad': previewAd
  },
  filters: {
    filterInventoryCatalog(value) {
      const types = { 'UNIVERSAL': '通投智选', 'MANUAL': '首选媒体' }
      return types[value] || '无'
    },
    filterConvertType(value) {
      const types = { 'AD_CONVERT_TYPE_PAY': '付费', 'AD_CONVERT_TYPE_CUSTOMER_EFFECTIVE': '有效获客' }
      return types[value] || '无'
    }
  },
  data() {
    return {
      /** 常量 */
      GROUP_PLAN_NUM: 500, // 组内计划上限
      AUDIENCE_TOTAL: 50, // 定向包总数
      MATERIAL_TOTAL: 50, // 创意素材总数
      TITLE_TOTAL: 50, // 标题包总数
      /** 常量 */
      loading: false, // !!this.$route.params.id,
      baseId: null, // 任务id
      ruleData: {
        launchVersion: 'v1', // 投放版本
        marketingPurpose: 'CONVERSION', // 营销链路
        landingType: 'LINK', // 推广目的
        taskName: '', // 任务名称
        projectId: '', // 项目id
        projectName: '', // 项目名称
        productId: '-1', // 产品id
        productName: '', // 产品名称
        housekeeperId: '', // 管理账号id
        advertisers: [] // 选中的投放账户
      },
      copyBaseInfo: {
        projectId: '', // 项目id
        productId: '-1', // 产品id
        housekeeperId: '', // 管理账号id
        advertisers: [] // 选中的投放账户
      },
      basicRule: { // 基础信息校验
        taskName: [{ required: true, message: '请输入任务名称', trigger: 'blur' }],
        projectId: [{ required: true, message: '请选择项目', trigger: 'change' }],
        productId: [{ required: true, message: '请选择产品', trigger: 'change' }],
        housekeeperId: [{ required: true, message: '请选择管理账号', trigger: 'change' }],
        advertisers: [{ required: true, message: '请选择投放账户', trigger: 'change' }],
        landingType: [{ required: true, message: '请选择产品', trigger: 'change' }]
      },
      advertiseGroup: {
        id: '',
        groupPlanMax: 500, // 广告组内计划数上限
        budget: 300, // 日预算
        budgetMode: 'BUDGET_MODE_INFINITE', // 广告组预算类型
        groupOperation: 'disable', // 广告组默认状态
        planOperation: 'disable', // 广告计划默认状态
        groupNameTemplate: '' // 广告组名称
      }, // 广告组信息
      advertisePlan: {
        marketingPurpose: 'CONVERSION', // 营销链路
        planNameTemplate: '', // 计划名称
        trackType: 2, // 数据对接方式
        pageType: 'TETRIS_EXTERNAL', // 落地页类型
        contentType: 'MINI_PROGRAME', // 推广内容
        convertSourceType: 1, // 转化追踪方式
        convertType: '', // 转化目标

        inventoryCatalog: 'MANUAL', // 广告位置
        feedDeliverySearch: 'HAS_OPEN', // 搜索快投
        searchKeyWord: [], // 搜索快投关键字
        // bidCoefficient: 'DISABLED', // 出价系数
        searchBidRatio: 1, // 出价系数值
        audienceExtend: 'OFF', // 定向拓展

        smartBidType: 1, // 投放场景
        flowControlMode: 1, // 竞价策略
        flowSpeedMode: 2, // 投放速度
        budgetMode: 'BUDGET_MODE_DAY', // 预算类型
        budget: '', // 预算金额
        scheduleType: 'SCHEDULE_FROM_NOW', // 投放时间
        scheduleRangeType: 1, // 投放时段
        scheduleRange: '', // 自定义投放时段
        pricing: 'PRICING_OCPM', // 付费方式
        cpaBid: '', // 转化出价
        autoInheritSwitch: 'ON', // 继承优质计划
        inheritType: 'INHERIT_FROM_ACCOUNT', // 继承账户类型
        inheritedAdvertiserId: '', // 继承账户ID
        inventoryTypes: '', // 首选媒体
        // 小程序相关内容
        appType: 1, // 类型
        appId: '', // appid
        appUrl: '', // 链接
        startPage: '', // 启动页面
        startParams: '', // 启动参数
        startTime: '', // 投放开始时间
        endTime: '', // 投放结束时间
        saveAsPreset: 0 // 存为预设
      }, // 广告计划基本信息
      audiencePackages: [], // 定向包
      advertiseCreative: {
        creativeType: 1, // 创意方式
        sourceType: 1, // 创意组件
        imageId: '', // 卡片主图id
        imageUrl: '', // 卡片主图url
        title: '', // 卡片标题
        productSellingPoints: '', // 推广卖点
        buttonText: '', // 行动号召
        enablePersonalAction: 1, // 智能优选
        componentName: '', // 组件名称
        componentIds: [], // 已有组件
        source: '', // 来源
        douyin: 1, // 原生广告
        isFeedAndFavSee: 0, // 主页作品列表隐藏广告视频
        isCommentDisable: 0, // 广告评论
        adDownloadStatus: 1, // 客户端视频下载
        thirdIndustryIds: [], // 创意分类id
        thirdIndustryNames: '', // 创意分类名称
        tagId: '', // 选择已有标签的id
        adKeywords: '', // 标签
        saveAsPreset: 0 // 存为预设
      }, // 创意基本信息
      creativeMedia: {
        imageSize: 0, // 图片个数
        videoSize: 1, // 视频个数
        groups: [], // 创意组
        generateCover: 1 // 自动生成封面
      }, // 创意素材
      titlePackages: [], // 标题包
      landingPages: [], // 落地页
      converts: [], // 转化追踪
      assets: [], // 资产选择
      douyins: [], // 抖音号
      traceUrls: [], // 监测链接
      /** source data */
      launchVersions: [{ id: 'v1', text: '巨量广告1.0' }], // 投放版本
      marketingPurposes: [{ id: 'CONVERSION', text: '行动转化' }], // 营销链路
      landingTypes: [{ id: 'LINK', text: '销售线索收集' }], // 推广目的
      projectList: [], // 项目
      productList: [], // 产品
      houseKeeperList: [], // 管理账号
      advertiserList: [], // 投放账户
      /** source data */
      previewLoading: false, // 预览加载
      previewData: [], // 预览信息

      isShowStep: false, // 是否显示步骤条
      active: 1, // 步骤条
      isBasicHasValue: false // 基础信息区是否全部有值
    }
  },
  computed: {
    advertisers() {
      let ids = this.ruleData.advertisers.map(v => v.advertiserId)
      return ids || []
    },
    /**
     * 基础信息字段
     */
    basicChange() {
      const { projectId, productId, housekeeperId, advertisers } = this.ruleData
      return { projectId, productId, housekeeperId, advertisers }
    },
    /**
     * 广告基本信息投放内容字段
     */
    computedDeliverContent() {
      if (this.advertisePlan.id && this.advertisePlan.trackType) {
        const types = { 'TETRIS_EXTERNAL': '橙子建站', 'THIRD_EXTERNAL': '第三方落地页', 'MINI_PROGRAME': '小程序' }
        if (this.advertisePlan.trackType === 1) {
          return types[this.advertisePlan.pageType] || '无'
        } else if (this.advertisePlan.trackType === 2) {
          return types[this.advertisePlan.contentType] || '无'
        } else {
          return '无'
        }
      } else {
        return '无'
      }
    },

    /**
     * 是否展示预览按钮
     */
    showPreviewBtn() {
      if (!this.ruleData.id) {
        return false
      }
      if (!this.advertiseGroup.id) {
        // this.$WarningMessage('请填写广告基本信息')
        return false
      }
      if (!this.advertisePlan.id) {
        // this.$WarningMessage('请填写广告计划基本信息')
        return false
      }
      if (!this.audiencePackages.length) {
        // this.$WarningMessage('请选择定向包')
        return false
      }
      if (!this.advertiseCreative.id) {
        // this.$WarningMessage('请选择创意基本信息')
        return false
      }
      if (!this.creativeMedia.id) {
        // this.$WarningMessage('请选择创意素材')
        return false
      }
      if (!this.titlePackages.length) {
        // this.$WarningMessage('请选择标题包')
        return false
      }
      if (!this.landingPages.length) {
        // this.$WarningMessage('请选择落地页')
        return false
      }

      // 转化追踪
      if (this.advertisePlan.trackType === 1 && !this.converts.length) {
        // this.$WarningMessage('请选择转化追踪')
        return false
      }

      // 资产选择
      if (this.advertisePlan.trackType === 2 && this.advertisePlan.contentType !== 'TETRIS_EXTERNAL' && !this.assets.length) {
        // this.$WarningMessage('请选择资产')
        return false
      }

      return true
    }
  },
  watch: {
    // 监听基础信息字段变化
    'basicChange': {
      handler(value) {
        const basicKey = ['projectId', 'productId', 'housekeeperId', 'advertisers']
        // 全部有值
        if (value[basicKey[0]] && value[basicKey[1]] && value[basicKey[2]] && value[basicKey[3]].length) {
          this.isBasicHasValue = true
        } else {
          this.isBasicHasValue = false
        }
      },
      deep: true
    }
  },
  beforeDestroy() {
    this.initData()
  },
  created() {
    this.getByIdFun()
  },
  methods: {
    // 编辑时获取
    async getByIdFun() {
      this._getProject()
      this._getHouseKeeper()

      let { id } = this.$route.params
      if (id) {
        this.isShowStep = false
        this.active = 2
        this.baseId = id
        const { code, data } = await getById({ id })
        if (code === 200) {
          const { base, group, plan, advertisers, audiencePackages, creative, titlePackages, creativeMedia, pages, converts, assets, douyins, traceUrls } = data.config
          let housekeeperAccountId = advertisers.length ? advertisers[0].housekeeperAccountId : ''
          let _advertisers = advertisers.map(v => {
            return {
              ...v,
              advertiserId: v.advertiserId + ''
            }
          })

          let projectId = base.projectId ? base.projectId + '' : ''
          let productId = base.productId ? base.productId + '' : ''

          this.ruleData = {
            id: base.id,
            launchVersion: base.version, // 投放版本
            marketingPurpose: base.marketingPurpose, // 营销链路
            landingType: base.landingType, // 推广目的
            taskName: base.taskName, // 任务名称
            projectId: projectId, // 项目id
            productId: '-1', // 产品id
            projectName: base.projectName, // 项目名称
            productName: base.productName || '', // 产品名称
            housekeeperId: housekeeperAccountId, // 管理账号id
            advertisers: [] // 选中的投放账户
          }
          this.copyBaseInfo = {
            projectId: projectId, // 项目id
            productId: '-1', // 产品id
            housekeeperId: housekeeperAccountId, // 管理账号id
            advertisers: [] // 选中的投放账户
          }
          this._getProduct(projectId, productId)
          this._getAdvertisers(housekeeperAccountId, _advertisers)

          if (group.id) {
            this.advertiseGroup = {
              id: group.id,
              groupPlanMax: group.groupPlanMax, // 广告组内计划数上限
              budget: group.budget, // 日预算
              budgetMode: group.budgetMode, // 广告组预算类型
              groupOperation: group.groupOperation, // 广告组默认状态
              planOperation: group.planOperation, // 广告计划默认状态
              groupNameTemplate: group.groupNameTemplate // 广告组名称
            }
          }

          if (plan.id) {
            this.advertisePlan = {
              id: plan.id,
              ...plan
            }
          }

          this.audiencePackages = audiencePackages || []
          if (creative.id) {
            this.advertiseCreative = { ...creative }
          }

          this.titlePackages = titlePackages || []
          if (creativeMedia) {
            this.creativeMedia = { ...creativeMedia }
          }
          this.landingPages = pages
          this.converts = converts
          this.assets = assets || []
          this.douyins = douyins || []
          this.traceUrls = traceUrls || []

          this.previewData = data.plans || []
        }
      } else {
        this.isShowStep = true
      }
    },

    /* --------------------------- 数据接口加载 -------------------------- */
    // 获取项目列表(个人可见)
    _getProject() {
      getMeRelationProject().then((res) => {
        if (res.code === 200) {
          this.projectList = res.data
        }
      })
    },
    // 获取产品列表(个人可见)
    _getProduct(val, productId = '') {
      if (!val) {
        return
      }
      getProductByProject(val).then(res => {
        if (res.code === 200) {
          this.productList = res.data
          this.ruleData.productId = productId || '-1'
          this.copyBaseInfo.productId = productId || '-1'
        }
      })
    },

    // 获取已授权的管理账号
    _getHouseKeeper() {
      getHouseKeeper({ name: '' }).then(res => {
        if (res.code === 200) {
          this.houseKeeperList = res.data
        }
      })
    },

    // 获取已授权的管理账号下的投放账户
    _getAdvertisers(id, arr = []) {
      if (!id) {
        return
      }
      getAdvertisers({ accountId: id }).then(res => {
        if (res.code === 200) {
          let data = res.data.map(v => {
            return {
              advertiserId: v.accountId,
              name: v.accountName
            }
          })
          this.advertiserList = data
          this.ruleData.advertisers = arr
          this.copyBaseInfo.advertisers = arr
        }
      })
    },
    /* --------------------------- 数据接口加载 -------------------------- */

    /* --------------------------- 按钮点击 -------------------------- */
    // 预览广告
    async handleClickPreviewAd() {
      if (!this.baseId) {
        return
      }
      let id = this.baseId
      this.previewLoading = true
      const { code, data } = await getById({ id })
      if (code === 200) {
        let params = {
          ...data.config
        }
        previewConfigPlans(params).then((res) => {
          if (res.code === 200) {
            this.previewData = res.data
          }
        }).finally(() => {
          this.previewLoading = false
        })
      } else {
        this.previewLoading = false
      }
    },

    // 取消预览
    handleCancelPreivew() {
      if (!this.previewData.length) {
        return
      }
      this.previewData = []
    },

    // 保存任务基础信息
    handleSaveConfigBase() {
      let { taskName, projectId, productId, projectName, productName, housekeeperId, advertisers, landingType } = this.ruleData

      let params = {
        id: this.baseId,
        baseId: this.baseId,
        taskName: taskName,
        projectId: projectId,
        projectName: projectName,
        productId: productId,
        productName: productName,
        housekeeperAccountId: housekeeperId,
        advertisers: advertisers,
        landingType: landingType
      }
      saveConfigBase(params).then(res => {
        if (res.code === 200) {
          console.log(res.data)
        }
      })
    },

    // 监听基础信息值是否变更
    handleWatchBasicInfo() {
      let flag = true
      if (this.advertiseGroup.id) {
        this.$confirm('基础信息变更，配置信息将清空<p>确定要变更吗？</p>', '基础信息变更提示', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          // 点击确定
          let { code } = await cleanConfigBase({ baseId: this.baseId })
          if (code === 200) {
            this.$message.success('清除配置信息成功')
            this.initData()
            flag = false
            return flag
          }
        }).catch(() => {
          flag = true
          return flag
        })
      } else {
        return flag
      }
    },

    // 修改任务名称
    handleInputName(value) {
      if (value && this.ruleData.id) {
        this.handleSaveConfigBase()
      }
    },

    // 点击下一步
    handleNextStep() {
      const { landingType, marketingPurpose, launchVersion } = this.ruleData
      let params = {
        landingType,
        marketingPurpose,
        version: launchVersion
      }
      saveConfigTask(params).then(res => {
        if (res && res.code === 200) {
          this.$router.replace({
            name: 'editHugeAmountAdBatch',
            params: { id: res.data.id }
          })
        }
      })
    },
    // 选择项目
    handleChangeProject(value) {
      if (this.handleWatchBasicInfo()) {
        let item = this.projectList.find(v => v.bsProjectId + '' === value)
        this.ruleData.projectName = item ? item.projectName : ''
        this.copyBaseInfo.projectId = value
        if (value !== '') {
          this.ruleData.productId = '-1'
          this._getProduct(value)
        }
      } else {
        this.ruleData.projectId = this.copyBaseInfo.projectId
      }
    },

    // 选择产品
    handleChangeProduct(value) {
      if (this.handleWatchBasicInfo()) {
        if (value === '-1') {
          this.ruleData.productName = '不限'
        } else {
          let item = this.productList.find(v => v.bsProductId + '' === value)
          this.ruleData.productName = item ? item.productName : ''
        }
        this.copyBaseInfo.productId = value
      } else {
        this.ruleData.productId = this.copyBaseInfo.productId
      }
    },

    // 选择管理账户
    handleChangeHouseKeeper(id) {
      if (this.handleWatchBasicInfo()) {
        this.copyBaseInfo.housekeeperId = id
        this.ruleData.advertisers = []
        this._getAdvertisers(id)
      } else {
        this.ruleData.housekeeperId = this.copyBaseInfo.housekeeperId
      }
    },

    // 选择投放账户
    handleChangeAdv(value) {
      if (this.handleWatchBasicInfo()) {
        this.copyBaseInfo.advertisers = value
      } else {
        this.ruleData.advertisers = this.copyBaseInfo.advertisers
      }
    },

    // --------------- 配置区 -------------------
    // 点击广告组编辑
    handleClickRules() {
      this.handleSaveConfigBase()
      this.$refs.rulesConfigDialog.showModal(this.advertiseGroup)
    },
    // 广告组信息确认回调
    getRuleConfig(row) {
      this.advertiseGroup = { ...row }
      this.handleCancelPreivew()
    },
    // 点击计划基本信息
    handleClickPlan() {
      this.$refs.adInfoDialog.showModal(this.advertisePlan)
    },
    // 广告计划基本信息回调
    async getAdInfo(row) {
      // 数据对接方式变化， 清空资产选择/转化追踪
      if (+row.trackType !== +this.advertisePlan.trackType) {
        if (row.trackType === 1) {
          this.converts = []
        } else {
          this.assets = []
        }
      }

      // 投放内容变化， 清空落地页
      const field = row.trackType === 1 ? 'pageType' : 'contentType'
      if (row[field] !== this.advertisePlan[field] && this.landingPages.length) {
        this.landingPages = []
      }
      this.advertisePlan = { ...row }
      this.handleCancelPreivew()
    },
    // 点击定向包
    handleClickAudience() {
      this.$refs.orientationPackageDialog.showModal(this.ruleData.projectId, this.audiencePackages, this.AUDIENCE_TOTAL)
    },
    // 定向包回调
    getPackageData(row) {
      this.audiencePackages = [...row]
      this.handleCancelPreivew()
    },
    // 删除定向包
    handleDeletePackage(item, index) {
      this.$confirm('确认删除定向包?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { code } = await deleteAudiencePackage({ id: item.id })
        if (code === 200) {
          this.audiencePackages.splice(index, 1)
          this.$message.success('删除成功')
          this.handleCancelPreivew()
        }
      }).catch(() => {
      })
    },

    // 清空定向包
    handleCleanAudience() {
      if (!this.baseId) {
        return
      }
      this.$confirm('确认清空定向包?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { code } = await cleanAudiencePackage({ baseId: this.baseId })
        if (code === 200) {
          this.audiencePackages = []
          this.handleCancelPreivew()
        }
      }).catch(() => {
      })
    },
    // 点击创意基本信息
    handleClickCreative() {
      this.$refs.creativeInfoConfigDialog.showModal(this.advertiseCreative, this.ruleData.advertisers)
    },
    // 创意基本信息回调
    getCreative(row) {
      // 原生广告是否启用变化， 清空抖音号数据
      if (row.douyin !== this.advertiseCreative.douyin) {
        this.douyins = []
      }
      this.advertiseCreative = { ...row }
      this.handleCancelPreivew()
    },
    // 点击创意素材组
    handleClickCreativeMaterial() {
      this.$refs.materialSelectDialog.showModal(this.creativeMedia, this.MATERIAL_TOTAL)
    },
    // 创意素材回调
    getMaterialList(row) {
      this.creativeMedia = { ...row }
      this.handleCancelPreivew()
    },
    // 清空创意素材组
    handleCleanMaterial() {
      if (!this.baseId) {
        return
      }
      this.$confirm('确认清空创意素材?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { code } = await cleanCreativeMaterial({ baseId: this.baseId })
        if (code === 200) {
          this.creativeMedia = {
            imageSize: 0, // 图片个数
            videoSize: 1, // 视频个数
            groups: [], // 创意组
            generateCover: 1 // 自动生成封面
          }
          this.handleCancelPreivew()
        }
      }).catch(() => {
      })
    },
    // 点击标题包
    handleClickTitle() {
      let { projectId, projectName, productId, productName } = this.ruleData
      let params = {
        projectId,
        projectName,
        productId,
        productName
      }
      this.$refs.textSelectDialog.showModal(params, this.titlePackages, this.TITLE_TOTAL)
    },
    // 标题包回调
    getTxtList(row) {
      row.forEach((item) => {
        if (!this.titlePackages.some((s) => s.id === item.id)) {
          this.titlePackages.push(item)
        }
      })
      this.handleCancelPreivew()
    },

    // 删除标题包
    handleDeleteTitle(item, index) {
      this.$confirm('确认删除标题包?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { code } = await deleteTitlePackage({ id: item.id })
        if (code === 200) {
          this.titlePackages.splice(index, 1)
          this.$message.success('删除成功')
          this.handleCancelPreivew()
        }
      }).catch(() => {
      })
    },

    // 清空标题包
    handleCleanTitle() {
      if (!this.baseId) {
        return
      }
      this.$confirm('确认清空标题包?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { code } = await cleanTitlePackage({ baseId: this.baseId })
        if (code === 200) {
          this.titlePackages = []
          this.handleCancelPreivew()
        }
      }).catch(() => {
      })
    },

    // 点击落地页
    handleClickPages() {
      const { pageType, trackType, contentType } = this.advertisePlan
      const { advertisers } = this.ruleData
      const type = trackType === 1 ? pageType : contentType
      let data = {
        pageType: type,
        advertisers: advertisers,
        landingPages: this.landingPages
      }
      this.$refs.landingPageSelectDialog.showModal(data)
    },
    // 落地页回调
    getLandingPages(row) {
      this.landingPages = [...row]
      this.handleCancelPreivew()
    },

    // 清空落地页
    handleCleanPages() {
      if (!this.baseId) {
        return
      }
      this.$confirm('确认清空落地页?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { code } = await cleanPages({ baseId: this.baseId })
        if (code === 200) {
          this.landingPages = []
          this.handleCancelPreivew()
        }
      }).catch(() => {
      })
    },

    // 删除落地页
    handleDeletePages(item, index) {
      this.$confirm('确认删除落地页?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { code } = await deletePages({ id: item.id })
        if (code === 200) {
          this.landingPages.splice(index, 1)
          this.$message.success('删除成功')
          this.handleCancelPreivew()
        }
      }).catch(() => {
      })
    },

    // 点击转化追踪
    handleClickTracking() {
      const { advertisers } = this.ruleData
      let data = {
        advertisers: advertisers,
        converts: this.converts
      }
      this.$refs.conversionTrackingDialog.showModal(data)
    },
    // 转化追踪弹窗回调
    getTrackingData(row) {
      this.converts = row
      this.handleCancelPreivew()
    },

    // 点击选择资产
    handleClickAssets() {
      const { pageType, trackType, contentType } = this.advertisePlan
      const { advertisers } = this.ruleData
      const type = trackType === 1 ? pageType : contentType
      let data = {
        pageType: type,
        advertisers: advertisers,
        assets: this.assets
      }
      this.$refs.assetsSelectDialog.showModal(data)
    },
    // 资产回调
    getAssetsData(row) {
      this.assets = row
      this.handleCancelPreivew()
    },

    // 点击抖音号
    handleClickDouyin() {
      const { advertisers, housekeeperId } = this.ruleData
      let data = {
        advertisers: advertisers,
        housekeeperId: housekeeperId,
        douyins: this.douyins
      }
      this.$refs.douyinSelectDialog.showModal(data)
    },
    // 抖音号回调
    getDouyinData(row) {
      this.douyin = row
      this.handleCancelPreivew()
    },

    // 点击检测链接
    handleClickTraceUrl() {
      const { advertisers } = this.ruleData
      this.$refs.traceUrlDialog.showModal(advertisers, this.traceUrls)
    },
    // 监测链接回调
    getTraceUrlData(row) {
      this.traceUrls = [...row]
      this.handleCancelPreivew()
    },
    // --------------- 配置区 -------------------
    /* --------------------------- 按钮点击 -------------------------- */
    // 重置所有数据
    initData() {
      // 广告组
      this.advertiseGroup = {
        id: '',
        groupPlanMax: 500, // 广告组内计划数上限
        budget: 300, // 日预算
        budgetMode: 'BUDGET_MODE_INFINITE', // 广告组预算类型
        groupOperation: 'disable', // 广告组默认状态
        planOperation: 'disable', // 广告计划默认状态
        groupNameTemplate: '' // 广告组名称
      }
      // 广告计划
      this.advertisePlan = {
        marketingPurpose: 'CONVERSION', // 营销链路
        planNameTemplate: '', // 计划名称
        trackType: 2, // 数据对接方式
        pageType: 'THIRD_EXTERNAL', // 落地页类型
        contentType: 'MINI_PROGRAME', // 推广内容
        convertSourceType: 1, // 转化追踪方式
        convertType: '', // 转化目标

        inventoryCatalog: 'MANUAL', // 广告位置
        feedDeliverySearch: 'HAS_OPEN', // 搜索快投
        searchKeyWord: [], // 搜索快投关键字
        // bidCoefficient: 'DISABLED', // 出价系数
        searchBidRatio: 1, // 出价系数值
        audienceExtend: 'OFF', // 定向拓展

        smartBidType: 1, // 投放场景
        flowControlMode: 1, // 竞价策略
        flowSpeedMode: 2, // 投放速度
        budgetMode: 'BUDGET_MODE_DAY', // 预算类型
        budget: '', // 预算金额
        scheduleType: 'SCHEDULE_FROM_NOW', // 投放时间
        scheduleRangeType: 1, // 投放时段
        scheduleRange: '', // 自定义投放时段
        pricing: 'PRICING_OCPM', // 付费方式
        cpaBid: '', // 转化出价
        autoInheritSwitch: 'ON', // 继承优质计划
        inheritType: 'INHERIT_FROM_ACCOUNT', // 继承账户类型
        inheritedAdvertiserId: '', // 继承账户ID
        inventoryTypes: '', // 首选媒体
        // 小程序相关内容
        appType: 1, // 类型
        appId: '', // appid
        appUrl: '', // 链接
        startPage: '', // 启动页面
        startParams: '', // 启动参数
        startTime: '', // 投放开始时间
        endTime: '', // 投放结束时间
        saveAsPreset: 0 // 存为预设
      } // 广告计划基本信息
      this.audiencePackages = [] // 定向包
      this.advertiseCreative = {
        creativeType: 1, // 创意方式
        sourceType: 1, // 创意组件
        imageId: '', // 卡片主图id
        imageUrl: '', // 卡片主图url
        title: '', // 卡片标题
        productSellingPoints: '', // 推广卖点
        buttonText: '', // 行动号召
        enablePersonalAction: 1, // 智能优选
        componentName: '', // 组件名称
        componentIds: [], // 已有组件
        source: '', // 来源
        douyin: 1, // 原生广告
        isFeedAndFavSee: 0, // 主页作品列表隐藏广告视频
        isCommentDisable: 0, // 广告评论
        adDownloadStatus: 1, // 客户端视频下载
        thirdIndustryIds: [], // 创意分类id
        thirdIndustryNames: '', // 创意分类名称
        tagId: '', // 选择已有标签的id
        adKeywords: '', // 标签
        saveAsPreset: 0 // 存为预设
      } // 创意基本信息
      this.creativeMedia = {
        imageSize: 0, // 图片个数
        videoSize: 1, // 视频个数
        groups: [], // 创意组
        generateCover: 1 // 自动生成封面
      } // 创意素材组
      this.titlePackages = [] // 标题包
      this.landingPages = [] // 落地页

      this.converts = [] // 转化追踪
      this.assets = [] // 资产选择
      this.douyins = [] // 抖音号
      this.traceUrls = [] // 监测链接
      this.previewData = [] // 预览数据
    }
  }
}
</script>

<style lang="scss" scoped>
.tempalte {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  position: relative;

  .active-one {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 250px;
    left: 50%;
    transform: translateX(-50%);
    width: 450px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 6%);
    // background-color: #f9f9f9;
    border: 1px solid #dcdfe6;
    border-radius: 7px;
    padding: 30px 20px 20px 20px;
    margin-bottom: 30px;
  }

  .config {
    width: 100%;
    margin-top: 30px;

    .config-body {
      width: 100%;
      box-sizing: border-box;

      .config-header {
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        border: 1px solid #dcdfe6;
        padding: 12px;
        margin-bottom: -1px;
        font-size: 14px;
        color: #000;

        div {
          color: #878787;
        }
      }

      .config-body-header {
        background-color: #f9f9f9;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .statistical-plan-number {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 400;
          color: #ccc;

          .number-item {
            margin-right: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .number {
              margin-left: 40%;
            }
          }
        }
      }

      .config-body-content {
        width: 100%;
        box-sizing: border-box;
        border: 1px solid #dcdfe6;

        .config-body-content-title-box {
          width: 100%;
          box-sizing: border-box;
          border-bottom: 1px solid #e6e9f1;
          background-color: #e9ecf4;
        }

        .config-body-content-title {
          display: flex;
          align-items: center;
          font-size: 16px;
          padding: 10px 0;
          width: 100%;
          box-sizing: border-box;

          .content-title {
            font-weight: 700;
            padding-left: 20px;
            width: 100%;
          }
        }

        .config-body-content-middle {
          width: 100%;
          margin: 0 !important;

          .el-col {
            padding: 0 !important;
            margin: 0 !important;
          }

          .item {
            height: 300px;
            position: relative;
            border-right: 1px solid #dcdfe6;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;

            .item-header {
              padding-top: 10px;
              font-size: 14px;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .left {
                margin-top: 5px;
                margin-left: 10px;
                font-weight: 500;
              }

              .left-desc {
                font-size: 12px;
                color: #ccc;
                margin-left: 5px;
              }

              .right {
                position: relative;
                top: 2px;
                margin-right: 10px;
              }
            }

            .item-body {
              box-sizing: border-box;
              padding-top: 20px;
              padding-left: 10px;
              color: #666;
              font-size: 14px;
              width: 100%;
              flex: 1;
              margin-bottom: 45px;
              overflow-y: auto;

              div {
                margin-bottom: 10px;
              }

              span {
                color: #878787;
              }

              .item-title-item {
                width: 100%;
                display: flex;
                align-items: center;
                box-sizing: border-box;
                justify-content: space-between;
                padding-right: 10px;
                height: 32px;
                margin-bottom: 4px;
                font-weight: 400;
                color: #666;
                background: #fafafa;
                border-radius: 4px;
                cursor: pointer;
              }

              .title-text {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .item-material-body {
              box-sizing: border-box;
              padding-top: 20px;
              padding-left: 10px;
              color: #666;
              font-size: 14px;
              width: 100%;
              flex: 1;
              margin-bottom: 45px;
              overflow-y: auto;

              .material-group {
                .material-group-title {
                  font-size: 12px;
                  color: #666;
                  margin-bottom: 5px;
                }

                .material-group-list {
                  display: flex;
                  flex-wrap: wrap;
                  width: 100%;
                  margin-bottom: 10px;
                }

                .image {
                  position: relative;
                  display: block;
                  margin-right: 10px;

                  .el-image {
                    width: 88px;
                    height: 50px;
                  }

                  .el-icon-circle-close {
                    position: absolute;
                    top: 0;
                    right: 0;
                  }
                }

                .videoImage {
                  position: relative;
                  display: block;
                  margin-right: 10px;

                  .el-image {
                    width: 88px;
                    height: 50px;
                  }

                  .el-icon-circle-close {
                    position: absolute;
                    top: 0;
                    right: 0;
                  }
                }
              }
            }

            .item-bottom {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 13px 0;
              border-top: none;
              color: #409eff;
              font-size: 12px;
              cursor: pointer;
              border: none;
              border-radius: 0;
              border-top: solid 1px #dcdfe6;
              box-sizing: border-box;
              position: absolute;
              bottom: 0;

              &.disabled {
                opacity: 0.5;
              }
            }
          }
        }
      }
    }

    .config-body-content-bottom {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
    }
  }

  .btn {
    float: right;
    margin-bottom: 10px;
  }

  .table {
    .table-td {
      font-size: 10px;
      color: #000;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
  }
}
</style>
