<template>
  <!--显示列弹框-->
  <el-dialog :visible.sync="show" :append-to-body="true" width="650px" top="20vh" @close="onCloseDialog">
    <!--自定义 title-->
    <span slot="title" class="dialog-title">{{ dialogTitle }}</span>
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="名称" prop="textGroupName">
        <el-input v-model="form.textGroupName" placeholder="请输入文案组名称"></el-input>
      </el-form-item>
      <el-form-item label="关联项目" prop="projectId">
        <el-select v-model="form.projectId" filterable clearable placeholder="请选择关联项目" style="width: 100%;"
          @change="handleSelect">
          <el-option v-for="(itm, idx) in projectList" :key="idx" :label="itm.projectName" :value="itm.bsProjectId">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关联产品" prop="productId">
        <el-select v-model="form.productId" filterable :disabled="!form.projectId" clearable placeholder="请选择关联产品"
          style="width: 100%;">
          <el-option label="不限" :value="0"></el-option>
          <el-option v-for="(itm, idx) in productList" :key="idx" :label="itm.productName" :value="itm.bsProductId">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="文案">
        <div class="content">
          <div v-for="(t, i) in txtList" :key="`tag-${i}`" class="tag-input">
            <el-select key="`txtList-${i}`" v-model="txtList[i]" :loading="isLoading" filterable clearable
              value-key="textId" style="width: 100%;" remote :remote-method="remoteMthod">
              <el-option v-for="(item, index) in textList" :key="index" :label="item.text" :value="item"></el-option>
              <div>
                <c-pagination :page-size.sync="pageSize" :page-no.sync="pageNum" :total="total"
                  :get-data-fun="getPageData" />
              </div>
            </el-select>
            <div v-if="txtList.length > 1" class="opt-icon-container" @click="onClickDelText(i)">
              <img class="opt-icon" src="@/assets/image/icon/icon-common-minus.png" alt="" />
              <img class="opt-icon-hl" src="@/assets/image/icon/icon-common-minus-hl.png" alt="" />
            </div>
          </div>
        </div>
        <div class="tag-add-container" @click="onClickAddText">
          <img class="add-icon-hl" src="@/assets/image/icon/icon-common-bg-plus-hl.png" alt="" />
          <span>添加文案</span>
        </div>
      </el-form-item>
    </el-form>

    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">取 消</el-button>
      <el-button class="btn" type="primary" :loading="isLoading" :disabled="isLoading" @click="clickConfirm('form')">确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { addTxtGroup, getTxtList } from '@/network/api/api-material'
import { PageCommonComponent } from '@/common/mixin'
import { getMeRelationProject } from '@/network/api/basicSetting/project/basicSettingProject'
import { getProductByProject } from '@/network/api/basicSetting/product/basicSettingProduct'
export default {
  name: 'AddCopyGroupDialog',
  components: {},
  filters: {},
  mixins: [PageCommonComponent],
  props: {},
  data() {
    return {
      show: false, // 是否显示弹框
      dialogTitle: '',
      projectList: [], // 项目列表
      productList: [], // 产品列表
      txtList: [{}], // 文本组列表
      textList: [],
      isLoading: false, // 是否正在加载
      id: '',
      form: {
        textGroupName: '',
        productId: '',
        projectId: ''
      }, // 表单数据
      pageNum: 1,
      total: 0,
      pageSize: 10,
      rules: {
        productId: [{ required: true, message: '请选择关联产品', trigger: 'blur' }],
        projectId: [{ required: true, message: '请选择关联项目', trigger: 'blur' }],
        textGroupName: [{ required: true, message: '请输入文案组名称', trigger: 'blur' }]
      } // 表单校验规则
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  watch: {},
  created() { },
  mounted() { },
  methods: {
    /* --------------------------- Actions --------------------------- */
    // 显示弹框
    showModal(params) {
      this.show = true
      this.dialogTitle = '新增文案组'
      this._getProject()
      this._getTxtList()
      if (params) {
        const { projectId, productId } = params
        this.form.projectId = +projectId || ''
        let _productId = productId <= 0 ? 0 : +productId || ''
        this._getProduct(this.form.projectId, _productId)
      }
    },

    // 关闭弹框
    closeModal() {
      this.show = false
    },
    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      // 重置表单
      this.$refs['form'].resetFields()

      // 清除数据
      this.form = {
        textGroupName: '',
        productId: '',
        projectId: ''
      }
      this.pageNum = 1
      this.total = 0
      this.pageSize = 10
      this.textList = []
      this.projectList = [] // 项目列表
      this.productList = [] // 产品列表
      this.txtList = [{}]
      this.id = ''
    },
    // 新增文本
    onClickAddText() {
      this.txtList.push({})
    },
    // 删除文本
    onClickDelText(i) {
      this.txtList.splice(i, 1)
    },
    // 点击确定按钮
    clickConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this._addTxtGroup()
        } else {
          this.$WarningMessage('请将表单填写完整')
        }
      })
    },
    // 选择关联项目之后
    handleSelect(e) {
      if (e !== '') {
        this.form.productId = ''
        this._getProduct(e)
      }
    },
    // 自定义搜索方法
    remoteMthod(query) {
      if (query !== '') {
        this.isLoading = true
        this.text = query
        setTimeout(() => {
          this.isLoading = false
          this._getTxtList()
        }, 200)
      }
    },
    getPageData() {
      this._getTxtList()
    },
    /* --------------------------- Private --------------------------- */
    // 获取项目列表(个人可见)
    _getProject() {
      getMeRelationProject().then((res) => {
        if (res.code === 200) {
          this.projectList = res.data
        }
      })
    },
    // 获取产品列表(个人可见)
    _getProduct(val, productId = 0) {
      getProductByProject(val).then((res) => {
        if (res.code === 200) {
          this.productList = res.data
          this.form.productId = productId
        }
      })
    },
    // 获取文本列表
    _getTxtList() {
      let params = {}
      if (this.text) {
        params.text = this.text
      }
      getTxtList(this.pageNum, this.pageSize, params).then((res) => {
        if (res.code === 200) {
          this.textList = res.data.list
          this.total = res.data.total
        }
      })
    },
    // 新增文案组
    _addTxtGroup() {
      if (this.txtList.some((s) => !s.text)) {
        return this.$message.error('请选择文案!')
      }
      this.isLoading = true
      let textIds = this.txtList.map((item) => {
        return item.textId
      })
      let params = {
        adMaterialTexts: this.txtList,
        productId: this.form.productId,
        projectId: this.form.projectId,
        adminId: this.userInfo.userid,
        nickname: this.userInfo.username,
        textGroupName: this.form.textGroupName,
        textIds: textIds.join(','),
        textNum: this.txtList.length
      }
      addTxtGroup(params)
        .then((res) => {
          this.$emit('edit')
          this.closeModal()
        })
        .catch((err) => {
          this.$ErrorMessage(err.data || '新增文案组失败')
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  .tag-input {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .opt-icon-container {
      width: 18px;
      height: 18px;
      position: relative;
      margin-left: 8px;
      cursor: pointer;

      .opt-icon-hl {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
      }

      .opt-icon {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 1;
      }

      &:hover {
        .opt-icon {
          opacity: 0;
        }

        .opt-icon-hl {
          opacity: 1;
        }
      }
    }
  }
}

.tag-add-container {
  display: flex;
  align-items: center;
  margin-top: 12px;
  color: #2842c8;
  cursor: pointer;

  .add-icon-hl {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
}

.dialog-footer {
  margin-right: 30px;
}
</style>
