<!--
 * @Author: Zeng
 * @Date: 2023-09-04 09:21:01
 * @LastEditTime: 2023-09-07 09:29:44
-->
<template>
  <!--中转落地页弹框-->
  <el-dialog class="element-library-box" :title="form.id ? '中转落地页编辑' : '中转落地页新增'" :visible.sync="show"
    :append-to-body="true" width="600px" top="20vh" @close="onCloseDialog">
    <div class="page-container">
      <el-form ref="form" :inline="false" label-width="100px" :model="form" :rules="rules">
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入名称" maxlength="20" show-word-limit style="width: 100%">
          </el-input>
        </el-form-item>
        <el-form-item label="跳转URL" prop="url">
          <el-input v-model="form.url" type="textarea" :rows="3" placeholder="请输入实际跳转URL" style="width: 100%"
            resize="none">
          </el-input>
          <div class="form-tips">URL中需带有http://或https://</div>
        </el-form-item>
      </el-form>
    </div>
    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">取消</el-button>
      <el-button class="btn" type="primary" :disabled="loading" @click="handleConfirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { updateLandingPage } from '@/network/api/toolManagement/api-landing-page'
import { pathValidator } from '@/common/utils/validate'
export default {
  data() {
    var urlValidate = (rule, value, callback) => {
      if (value && !pathValidator(value)) {
        callback(new Error('请检查URL是否正确'))
      } else {
        callback()
      }
    }
    return {
      show: false,
      loading: false,
      form: {
        name: '',
        url: ''
      },
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        url: [{ required: true, message: '请输入跳转URL', trigger: 'blur' }, {
          validator: urlValidate, trigger: 'blur'
        }]
      }
    }
  },

  created() { },

  mounted() { },
  methods: {
    /* --------------------------- Actions --------------------------- */
    showModal(data) {
      if (Object.keys(data).length) {
        this.form = {
          id: data.toolPageRedirectId,
          name: data.toolPageRedirectName,
          url: data.redirectPage
        }
      }
      this.show = true
    },
    // 点击确定按钮
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return
        }
        let { id, name, url } = this.form
        let params = {
          toolPageRedirectName: name,
          redirectPage: url
        }
        this.loading = true
        if (id) {
          params.toolPageRedirectId = id
        }

        updateLandingPage(params).then(({ code }) => {
          if (code === 200) {
            id ? this.$SuccessMessage('编辑成功') : this.$message.success('添加成功')
            this.$emit('confirm')
            this.closeModal()
          }
        }).finally(() => {
          this.loading = false
        })
      })
    },
    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      // 重置表单
      this.form = {
        name: '',
        url: ''
      }
      setTimeout(() => {
        this.$refs.form.clearValidate()
      }, 100)
    },
    // 关闭弹框
    closeModal() {
      this.show = false
    }
  }
}
</script>

<style scoped lang="scss">
.form-tips{
  color: #878787;
  font-size: 14px;
}
</style>
