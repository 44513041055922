<!--
 * @Descripttion: 二次封装支持模糊音搜索el-select--全局组件(投放账户)
 * @Date: 2021-04-21 17:09:08
-->
<template>
  <div class="box">
    <el-select :value="value" v-bind="$attrs" style="width: 460px" :loading="loading" :remote-method="remoteMethod" filterable clearable remote reserve-keyword v-on="$listeners">
      <el-option v-for="item in account_options" :key="item.id" :label="`${item.corporationName}(${item.accountId})`" :value="`${item.id}+${item.corporationName}`"></el-option>
    </el-select>
  </div>
</template>

<script>
import { debounce } from '@/utils/ruoyi'
// import _ from 'lodash'
// import { adMpAmsAccountsPage } from '@/network/api/account/weChatAdvAccount.js'
import { Page } from '@/network/api/account/tencentAdvAccount.js'
// import { getWechatAccountLike } from '@/network/api/assetManagement/assetManagementHugeLandingPageTemp'
export default {
  name: 'SearchSelectAsyncText',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    // 需要绑定的值 等于 v-model
    value: {
      type: [String, Number],
      default: ''
    },
    projectId: {
      type: String,
      default: ''
    },
    spid: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      default: 12
    }
  },
  data() {
    return {
      account_options: [],
      loading: false
    }
  },

  watch: {
    spid: {
      handler() {
        this.account_options = []
      }
    }
  },

  methods: {
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        this.getSelectAccountUinFun(query, this.projectId)
      } else {
        this.AccountUin_Options = []
      }
    },

    getSelectAccountUinFun: debounce(function(query) {
      // if (this.spid) {
      Page({ search: query, putInStatus: 1 }).then((res) => {
        if (res.code === 200) {
          this.loading = false
          this.account_options = res.data.records
        }
      })
      // } else {
      //   getWechatAccountLike(query).then((res) => {
      //     if (res.code === 200) {
      //       this.loading = false
      //       this.account_options = res.data
      //     }
      //   })
      // }
    })
  }
}
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  justify-items: start;
  align-items: center;
}
</style>
