/*
 * @Description: Do not edit
 * @Date: 2021-11-01 15:13:00
 * @LastEditTime: 2021-11-01 15:40:13
 */
import service from '@/network/service/service'

export function Page(data) {
  return service.post('/api/system/assetManagementHugeAsset/page', data)
}

export function Add(data) {
  return service.post('/api/system/assetManagementHugeAsset/save', data)
}

export function Update(data) {
  return service.post('/api/system/assetManagementHugeAsset/update', data)
}

export function Synchronization(data) {
  return service.get(`api/system/assetManagementHugeAsset/synchronization/${data.accountId}/${data.GGZaccountId + ''}`)
}

export function Delete(id) {
  return service.delete('/api/system/assetManagementHugeAsset/' + id)
}
