<template>
  <!--显示列弹框-->
  <el-dialog :visible.sync="show" title="广告组编辑" :append-to-body="true" width="800px" top="20vh">
    <el-form ref="form" :rules="rules" label-position="right" :model="form" label-width="150px">
      <el-form-item label="广告组内计划数上限" prop="groupPlanMax">
        <el-input-number v-model="form.groupPlanMax" label="广告组内计划数上限" :min="1" :max="500" :controls="false">
        </el-input-number>
        <span class="input-tips">可填写1～500</span>
      </el-form-item>
      <el-form-item label="广告组预算" prop="budgetMode">
        <el-radio-group v-model="form.budgetMode">
          <el-radio label="BUDGET_MODE_INFINITE">不限</el-radio>
          <el-radio label="BUDGET_MODE_DAY">指定预算</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="form.budgetMode === 'BUDGET_MODE_DAY'" label="日预算" prop="budget">
        <el-input-number v-model="form.budget" label="日预算" :min="300" :precision="2" :controls="false">
        </el-input-number>
        <span class="input-tips">日预算不少于300.00元</span>
      </el-form-item>
      <el-form-item label="广告组默认状态" prop="groupOperation">
        <el-radio-group v-model="form.groupOperation">
          <el-radio :label="'enable'">开启</el-radio>
          <el-radio :label="'disable'">暂停</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="广告计划默认状态" prop="planOperation">
        <el-radio-group v-model="form.planOperation">
          <el-radio :label="'enable'">开启</el-radio>
          <el-radio :label="'disable'">暂停</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="广告组名称" prop="groupNameTemplate">
        <el-input v-model="form.groupNameTemplate" type="text" placeholder="请输入广告组名称" maxlength="100" show-word-limit
          style="width: 300px" />
      </el-form-item>
      <div class="tip">通配符：
        <span @click="onClickSpecialChar('日期')">+日期</span>
        <span @click="onClickSpecialChar('产品名')">+产品名</span>
        <span @click="onClickSpecialChar('投手名称')">+投手名称</span>
        <span @click="onClickSpecialChar('标号')">+标号</span>
        <span @click="onClickSpecialChar('素材名')">+素材名</span>
      </div>
    </el-form>

    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">取 消</el-button>
      <el-button class="btn" type="primary" :loading="isLoading" :disabled="isLoading" @click="clickConfirm('form')">确 定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { saveConfigGroup } from '@/network/api/api-huge-batch'
export default {
  name: 'RulesConfigDialog',
  props: {
    baseId: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      show: false, // 是否显示弹框
      isLoading: false,
      form: {},
      rules: {
        groupNameTemplate: [{ required: true, message: '请输入广告组名称', trigger: 'blur' }],
        groupPlanMax: [
          { required: true, message: '请输入广告组内计划数上限', trigger: 'blur' },
          { type: 'number', min: 1, max: 500, message: '广告组内计划数范围是1～500', trigger: 'change' }
        ],
        budgetMode: [{ required: true, message: '请选择广告组预算类型', trigger: 'change' }],
        budget: [
          { required: true, message: '请输入日预算', trigger: 'blur' },
          { type: 'number', min: 300, message: '日预算最小为300.00', trigger: 'blur' }
        ],
        groupOperation: [{ required: true, message: '请选择广告组默认状态', trigger: 'change' }],
        planOperation: [{ required: true, message: '请选择广告计划默认状态', trigger: 'change' }]
      }
    }
  },
  watch: {},

  methods: {
    /* --------------------------- Actions --------------------------- */
    // 显示弹框
    showModal(rule) {
      this.show = true
      if (rule && rule.id) {
        this.form = {
          id: rule.id || '',
          groupPlanMax: +rule.groupPlanMax, // 广告组内计划数上限
          budget: +rule.budget || 0, // 日预算
          budgetMode: rule.budgetMode, // 广告组预算类型
          groupOperation: rule.groupOperation, // 广告组默认状态
          planOperation: rule.planOperation, // 广告计划默认状态
          groupNameTemplate: rule.groupNameTemplate // 广告组名称
        }
      } else {
        this.form = { ...rule }
      }
    },
    // 关闭弹框
    closeModal() {
      this.show = false
    },

    // 点击确定按钮
    clickConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = this.form
          let params = {
            id: this.form.id || '',
            baseId: this.baseId,
            campaignType: '',
            deliveryRelatedNum: '',
            budgetMode: form.budgetMode,
            budget: form.budgetMode === 'BUDGET_MODE_DAY' ? form.budget : 0,
            groupNameTemplate: form.groupNameTemplate,
            groupOperation: form.groupOperation,
            groupPlanMax: form.groupPlanMax,
            planOperation: form.planOperation
          }
          this.isLoading = true
          saveConfigGroup(params).then(res => {
            if (res.code === 200) {
              this.$emit('confirm', res.data)
              this.isLoading = false
              this.show = false
            } else {
              this.isLoading = false
            }
          }).catch(() => {
            this.isLoading = false
          })
        }
      })
    },

    // 增加广告组名通配符
    onClickSpecialChar(str) {
      this.form.groupNameTemplate += '<' + str + '>'
    }
    /* --------------------------- Private --------------------------- */
  }
}
</script>

<style scoped>
.tip {
  margin-left: 150px;
  font-size: 12px;
}

span {
  margin-left: 10px;
  color: #409eff;
  cursor: pointer;
}

.input-tips {
  font-size: 12px;
  color: #ccc;
}
</style>
