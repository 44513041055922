var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.seletedWidgetsIndex !== -1
    ? _c(
        "section",
        { staticClass: "ad-editors" },
        [
          _c(_vm.editors, {
            tag: "component",
            attrs: { detail: _vm.finalWidgets[_vm.seletedWidgetsIndex] },
            on: { openMaterialBox: _vm.setMaterialBox },
          }),
          _c("material-box", {
            attrs: {
              visible: _vm.materialBox,
              "img-count": _vm.imgCount,
              "image-list": _vm.imageList,
              name: "ldy",
              attribute: _vm.attribute,
            },
            on: { setMaterialBox: _vm.setMaterialBox, submit: _vm.selectImg },
          }),
        ],
        1
      )
    : _c("section", { staticClass: "ad-editors" }, [_vm._v(" 未选中组件 ")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }