var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "search",
          staticClass: "search-form search-form-4",
          attrs: { inline: true, model: _vm.search },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "任务名称", prop: "taskName" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请输入任务名称" },
                        model: {
                          value: _vm.search.taskName,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "taskName", $$v)
                          },
                          expression: "search.taskName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "创建人", prop: "createBy" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请输入创建人" },
                        model: {
                          value: _vm.search.createBy,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "createBy", $$v)
                          },
                          expression: "search.createBy",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "提交状态", prop: "status" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          model: {
                            value: _vm.search.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.search, "status", $$v)
                            },
                            expression: "search.status",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "不限", value: "" },
                          }),
                          _c("el-option", {
                            attrs: { label: "配置中", value: "2" },
                          }),
                          _c("el-option", {
                            attrs: { label: "待提交", value: "3" },
                          }),
                          _c("el-option", {
                            attrs: { label: "提交中", value: "4" },
                          }),
                          _c("el-option", {
                            attrs: { label: "提交完成", value: "5" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "创建时间", prop: "createTime" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "daterange",
                          "prange-separator": "至",
                          "start-placeholder": "开始时间",
                          "value-format": "yyyy-MM-dd",
                          "end-placeholder": "结束时间",
                        },
                        model: {
                          value: _vm.search.createTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.search, "createTime", $$v)
                          },
                          expression: "search.createTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", round: "" },
                          on: { click: _vm.onSubmit },
                        },
                        [_vm._v("查 询")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", round: "" },
                          on: {
                            click: function ($event) {
                              return _vm.resetForm("ruleForm")
                            },
                          },
                        },
                        [_vm._v("重 置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mb-15", staticStyle: { "text-align": "left" } },
        [
          _c(
            "router-link",
            { attrs: { to: { name: "createHugeAmountAdBatch" }, tag: "span" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary", icon: "el-icon-plus", round: "" } },
                [_vm._v(" 新建任务 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.list.loading,
                  expression: "list.loading",
                },
              ],
              staticClass: "list-table",
              class: { "no-data": !_vm.list.data || !_vm.list.data.length },
              staticStyle: { width: "100%" },
              attrs: { data: _vm.list.data, stripe: "" },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "序号", width: "50" },
              }),
              _c("el-table-column", {
                attrs: { prop: "id", label: "ID", width: "50" },
              }),
              _c("el-table-column", {
                attrs: { prop: "taskName", label: "任务名称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: { effect: "dark", placement: "top" },
                          },
                          [
                            _c("template", { slot: "content" }, [
                              _c("p", { staticClass: "task-name-Flow" }, [
                                _vm._v(_vm._s(row.taskName)),
                              ]),
                            ]),
                            _c("p", { staticClass: "task-name-Flow" }, [
                              _vm._v(_vm._s(row.taskName)),
                            ]),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "创建时间" },
              }),
              _c("el-table-column", {
                attrs: { prop: "createBy", label: "创建人" },
              }),
              _c("el-table-column", {
                attrs: { prop: "projectName", label: "项目" },
              }),
              _c("el-table-column", {
                attrs: { prop: "", label: "产品" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("p", [_vm._v(_vm._s(row.productName || "不限"))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "", label: "投放账户", width: "320" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: { effect: "dark", placement: "top" },
                          },
                          [
                            _c("template", { slot: "content" }, [
                              _c("p", { staticClass: "task-name-Flow" }, [
                                _vm._v(
                                  _vm._s(
                                    (row.advertisers.length &&
                                      row.advertisers.join(",")) ||
                                      "-"
                                  )
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "task-account-box" }, [
                              _c("p", { staticClass: "task-name-Flow" }, [
                                _vm._v(
                                  _vm._s(
                                    (row.advertisers.length &&
                                      row.advertisers.join(",")) ||
                                      "-"
                                  )
                                ),
                              ]),
                              row.advertisers && row.advertisers.length > 1
                                ? _c(
                                    "span",
                                    { staticClass: "task-account-total" },
                                    [
                                      _vm._v(
                                        "共" +
                                          _vm._s(
                                            row.advertisers &&
                                              row.advertisers.length
                                          ) +
                                          "个账户"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "", label: "推广目标" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("p", [
                          _vm._v(
                            _vm._s(_vm.promotedObjectTypeList[row.landingType])
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "", label: "提交广告数" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [_c("p", [_vm._v(_vm._s(row.planCount))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("div", { staticClass: "status" }, [
                          _c(
                            "p",
                            {
                              staticStyle: {
                                "font-size": "11px",
                                "font-weight": "400",
                              },
                              style: {
                                color:
                                  row.status == 5
                                    ? row.planFailCount == 0
                                      ? "green"
                                      : "red"
                                    : "",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.statusList[row.status]))]
                          ),
                          row.status == 5
                            ? _c(
                                "div",
                                { staticClass: "status-num" },
                                [
                                  _c(
                                    "p",
                                    { staticStyle: { "font-size": "11px" } },
                                    [
                                      _vm._v(" 成功："),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "green" } },
                                        [_vm._v(_vm._s(row.planSuccessCount))]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: row.failMsg,
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticStyle: { "font-size": "11px" },
                                        },
                                        [
                                          _vm._v(" 失败："),
                                          _c(
                                            "span",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v(_vm._s(row.planFailCount))]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.status == 2 || row.status == 3
                          ? [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleGoEdit(row)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: "继续",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-edit",
                                        staticStyle: { "font-size": "16px" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-button",
                                {
                                  staticClass: "red",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteTask(row.id)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: "删除",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-delete",
                                        staticStyle: { "font-size": "16px" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          : row.status == 5
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.detailTask(row)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      content: "详情",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-document",
                                      staticStyle: { "font-size": "16px" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c("span", [_vm._v("-")]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "page" },
            [
              _c("paginations", {
                attrs: {
                  total: _vm.list.total,
                  page: _vm.list.pageNum,
                  "page-sizes": [5, 10, 15, 20],
                  limit: _vm.list.pageSize,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.list, "pageNum", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.list, "pageSize", $event)
                  },
                  pagination: _vm.getPage,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "任务详情",
            visible: _vm.descVisible,
            width: "1200px",
            "modal-append-to-body": false,
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.descVisible = $event
            },
            close: () => {
              _vm.showErrorFlag = false
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-descriptions",
                { attrs: { column: 2 } },
                [
                  _c(
                    "el-descriptions-item",
                    { attrs: { span: 24, label: "任务名称" } },
                    [_vm._v(_vm._s(_vm.dataSource_data_info.taskName))]
                  ),
                  _c("el-descriptions-item", { attrs: { label: "项目" } }, [
                    _vm._v(_vm._s(_vm.dataSource_data_info.projectName)),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "产品" } }, [
                    _vm._v(
                      _vm._s(_vm.dataSource_data_info.productName || "不限")
                    ),
                  ]),
                  _c(
                    "el-descriptions-item",
                    { attrs: { label: "提交广告数" } },
                    [_vm._v(_vm._s(_vm.dataSource_data_info.planCount))]
                  ),
                  _c("el-descriptions-item", { attrs: { label: "创建人" } }, [
                    _vm._v(_vm._s(_vm.dataSource_data_info.createBy)),
                  ]),
                  _c("el-descriptions-item", { attrs: { label: "创建时间" } }, [
                    _vm._v(_vm._s(_vm.dataSource_data_info.createTime)),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticStyle: { "margin-top": "20px" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          on: { change: _vm.showError },
                          model: {
                            value: _vm.showErrorFlag,
                            callback: function ($$v) {
                              _vm.showErrorFlag = $$v
                            },
                            expression: "showErrorFlag",
                          },
                        },
                        [_vm._v(" 仅查看失败")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 12 },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "mini",
                            loading: _vm.republishLoading,
                          },
                          on: { click: _vm.handleRePublish },
                        },
                        [_vm._v("重新提交")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("TablePane", {
                attrs: { "data-source": _vm.dataSource_desc },
                on: { getPage: _vm.getDescPage },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }