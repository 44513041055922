<!--
 * @Description: 标签上传
 * @Date: 2021-12-20 15:03:27
 * @LastEditTime: 2021-12-31 11:44:09
-->
<template>
  <el-tree ref="tree" :data="data" show-checkbox node-key="value" default-expand-all :props="defaultProps" @check="checkChange"> </el-tree>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      defaultProps: {
        children: 'options',
        label: 'name'
      }
    }
  },

  methods: {
    checkChange(checkedNode) {
      this.$emit('getSelected', checkedNode)
    },

    setCheckedKeys(value) {
      if (value) {
        this.$refs.tree.setCheckedKeys(value)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
