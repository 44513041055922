<template>
  <div>
    <!-- 列表查询 -->
    <el-form ref="search" :inline="true" :model="search" class="search-form search-form-4" @submit.native.prevent>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="模版名称" prop="promoteTitle">
            <!-- <el-input v-model="search.promoteTitle" placeholder="请输入模版名称"></el-input> -->
            <SearchSelect v-model="search.promoteTitle" :options="promoteTitleList" :props="{
              label: 'label',
              value: 'label'
            }" placeholder="请选择模版名称" />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="上传人" prop="createBy">
            <!-- <el-input v-model="search.createBy" placeholder="请输入上传人"></el-input> -->
            <SearchSelect v-model="search.createBy" :options="creatNameList" :props="{
              label: 'label',
              value: 'label'
            }" placeholder="请选择上传人" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="投放平台" prop="launchPlatform">
            <el-select v-model="search.launchPlatform" placeholder="请选择投放平台" @change="changeLaunchPlatform">
              <el-option label="微信广告" value="MP"></el-option>
              <el-option label="腾讯广告" value="ADQ"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="上传账户" prop="ghid">
            <SearchSelect v-if="search.launchPlatform == 'MP'" v-model="search.ghid" :options="accountList" :props="{
              label: 'ghname',
              value: 'ghid'
            }" placeholder="请选择上传账户" />
            <SearchSelect v-else v-model="search.adqId" :options="accountList" :props="{
              label: 'adqId',
              value: 'adqId'
            }" placeholder="请选择上传账户" />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="上传时间" prop="createTime">
            <el-date-picker v-model="search.createTime" type="daterange" value-format="yyyy-MM-dd" range-separator="~"
              start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="上传状态" prop="localStatus">
            <el-select v-model="search.localStatus" placeholder="请选择上传状态">
              <el-option label="全部" value=""></el-option>
              <el-option label="上传成功" value="SUCCESS"></el-option>
              <el-option label="上传中" value="PENDING"></el-option>
              <el-option label="上传失败" value="FAIL"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" round @click="getPage">查询</el-button>
            <el-button round @click="resetFormQuery('search')">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <!-- 表格 -->
    <TablePane class="mt-20" :data-source="dataSource" @getPage="getPage">
      <template #ghname="{ scope }">
        <p>{{ search.launchPlatform == 'MP' ? scope.row.ghname + '(' + scope.row.ghid + ')' : scope.row.adqId }}</p>
      </template>
      <template #localStatus="{ scope }">
        <el-tag v-if="scope.row.localStatus == 'SUCCESS'" type="success">上传成功</el-tag>
        <el-tag v-else-if="scope.row.localStatus == 'PENDING'" type="info">上传中</el-tag>
        <el-tooltip v-else :content="scope.row.errMsg" placement="top">
          <el-tag type="danger">上传失败</el-tag>
        </el-tooltip>
      </template>
      <template slot="operation" slot-scope="{ row }">
        <el-button v-if="row.localStatus === 'FAIL'" type="text" @click="async(row)"> 重新上传 </el-button>
        <span v-else>—</span>
      </template>
    </TablePane>
  </div>
</template>

<script>
import TablePane from '@/components/tablePane.vue'
import { getDateStartEnd } from '@/utils/ruoyi'
import { ruleRelation, ldyResync, ldyLogSelect } from '@/network/api/assetManagement/assetManageWeChatAdv'

export default {
  components: {
    TablePane
  },
  data() {
    return {
      rtData: [],
      search: {
        promoteTitle: null,
        createTime: null,
        createBy: null,
        ghid: null,
        adqId: null,
        localStatus: '',
        launchPlatform: 'MP'
      },
      accountList: [], // 账号列表
      mpAccountList: [], // 微信账号列表
      tcAccountList: [], // 腾讯账户列表
      promoteTitleList: [], // 模版列表
      creatNameList: [], // 上传人
      isEnterpriseWx: 0, // 同步的行是否需要有企微组件
      selectRow_one: {},
      dataSource: {
        data: [],
        cols: [
          {
            label: '模板名称',
            prop: 'promoteTitle'
          },
          {
            label: '模版创建人',
            prop: 'promoteCreateBy'
          },
          {
            label: '上传人',
            prop: 'createBy'
          },
          {
            label: '上传时间',
            prop: 'createTime'
          },
          {
            label: '上传账户',
            prop: 'ghname',
            isTemplate: true
          },
          {
            label: '上传状态',
            prop: 'localStatus',
            isTemplate: true
          }
        ], // 表格的列数据
        isIndex: true, // 列表序号
        loading: true, // loading
        isOperation: true,
        // isSelection: true,
        pageData: {
          total: 0 // 总条数
        },
        // handleSelectionChange: (selection) => {
        //   this.selectRow = selection
        // },
        operation: {
          // 表格有操作列时设置
          label: '操作', // 列名
          width: 150, // 根据实际情况给宽度
          data: []
        }
      }
    }
  },
  mounted() {
    this.getPage()
    this.getSelect()
  },
  methods: {
    /** 重置 */
    resetFormQuery(formName) {
      this.$refs[formName].resetFields()
      setTimeout(() => {
        this.getPage()
      }, 300)
    },

    async getSelect() {
      const { data } = await ldyLogSelect()
      this.mpAccountList = data.accountList
      this.tcAccountList = data.tcAccountList
      this.promoteTitleList = data.promoteTitleList.map(m => ({ label: m }))
      this.creatNameList = data.creatNameList.map(m => ({ label: m }))
      this.accountList = []
      if (this.search.launchPlatform === 'MP') {
        this.accountList = data.accountList
      } else {
        this.accountList = data.tcAccountList
      }
    },

    changeLaunchPlatform(value) {
      this.accountList = []
      if (value === 'MP') {
        this.accountList = this.mpAccountList
      } else {
        this.accountList = this.tcAccountList
      }
      this.getPage()
    },

    /**
     * @description: 获取列表
     * @param {*} params
     */
    getPage(params = { pageNum: 1, pageSize: 10 }) {
      this.dataSource.loading = true
      let dateList = this.search.createTime ? getDateStartEnd(this.search.createTime) : null
      if (dateList && dateList.length) {
        if (!dateList[0] || !dateList[1]) {
          dateList = null
        }
      }
      let search = {
        ...this.search,
        dateList
      }
      delete search.createTime
      ruleRelation({ ...search, ...params }).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          this.dataSource.data = records
          this.dataSource.pageData.total = total
          this.dataSource.pageData.pageNum = params.pageNum
          this.dataSource.loading = false
        }
      })
    },

    /** 重新同步 */
    async async(record) {
      const asyncList = [{ id: record.id }]
      const params = { asyncList }
      const { code } = await ldyResync(params)
      if (code === 200) {
        this.$message.success('上传成功!')
      } else {
        this.$message.error('上传失败!')
      }
      this.getPage()
    }
  }
}
</script>
