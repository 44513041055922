<!--
 * @Description: 图片编辑区
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2024-03-21 16:18:50
-->

<template>
  <section class="section">
    <base-box name="img">
      <template slot="body">
        <el-form ref="imgForm" class="img-form-box" :model="detail" size="mini" :inline="true" :rules="rules"
          label-width="80px">
          <el-form-item label="上传图片" prop="pureImageUrl">
            <div class="image-updata">
              <div>
                <el-upload class="avatar-uploader" action="fakeaction" :show-file-list="false"
                  accept=".png,.jpg,.jpeg,.gif" :before-upload="beforeAvatarUpload" :http-request="handleUpload">
                  <template v-if="detail.pureImageUrl">
                    <img :src="detail.pureImageUrl" class="avatar" style="border:1px solid #ccc; border-radius: 4px " />
                    <span class="uploader-actions">
                      <span class="upload_action_delete" @click="handleRemoveImage">
                        <i class="el-icon-delete"></i>
                      </span>
                      <span class="upload_action_delete">
                        <i class="el-icon-edit"></i>
                      </span>
                    </span>
                  </template>
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
                <p class="tip mt-10">
                  图片大小：不超过500KB <br />
                  图片尺寸：宽度750像素，高度不超过1536像素 <br />
                  图片格式：png、jpg、jpeg、gif
                </p>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="上边距" prop="paddingTop">
            <el-slider v-model="detail.paddingTop" show-input :max="100"> </el-slider>
          </el-form-item>

          <el-form-item label="下边距" prop="paddingBottom">
            <el-slider v-model="detail.paddingBottom" show-input :max="100"> </el-slider>
          </el-form-item>

          <el-form-item label="点击跳转" prop="clickJump">
            <el-select v-model="detail.clickJump" size="mini" style="width: 100%">
              <el-option label="是" :value="1"></el-option>
              <el-option label="否" :value="2"></el-option>
            </el-select>
          </el-form-item>

          <!-- 【点击跳转】选择“否”时，【链接类型】【跳转链接】字段不显示 -->
          <template v-if="detail.clickJump == 1">
            <el-form-item label="链接类型" prop="linkType">
              <el-select v-model="detail.linkType" size="mini" placeholder="请选择链接类型" style="width: 100%">
                <el-option label="普通链接" :value="1"></el-option>
                <el-option label="获客助手链接" :value="2"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="跳转链接" prop="link">
              <el-input v-model="detail.link" size="mini" type="textarea" :rows="3" resize="none" placeholder="请输入跳转链接"
                style="width: 100%;" maxlength="500" show-word-limit>
              </el-input>
            </el-form-item>

            <template v-if="detail.linkType == 1">
              <el-form-item label="平台" prop="platformId">
                <el-select v-model="detail.platformId" placeholder="请选择平台" size="mini" style="width: 100%" filterable>
                  <el-option v-for="(item, index) in platforms" :key="index" :label="item.dictLabel"
                    :value="item.dictValue"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="关联内容" prop="relationContent">
                <el-input v-model="detail.relationContent" size="mini" placeholder="请输入投放平台相关ID" style="width: 100%;"
                  maxlength="30" show-word-limit>
                </el-input>
              </el-form-item>
            </template>
          </template>
        </el-form>
      </template>
    </base-box>
  </section>
</template>

<script>
import BaseBox from './box.vue'
import oss from '@/views/toolManagement/materialTool/videoTask/create/oss'
import SparkMD5 from 'spark-md5'
import { pathValidator } from '@/common/utils/validate'
export default {
  components: {
    'base-box': BaseBox
  },
  mixins: [oss],
  props: {
    detail: {
      type: Object,
      default: () => { }
    },
    platforms: {
      type: Array,
      default: () => []
    }
  },
  data() {
    var urlValidate = (rule, value, callback) => {
      if (value && !pathValidator(value)) {
        callback(new Error('请检查URL是否正确'))
      } else {
        callback()
      }
    }
    return {
      UPLOAD_URL: '/api/file/upload',
      rules: {
        pureImageUrl: [{ required: true, message: '请上传图片', trigger: 'change' }],
        link: [{ required: true, message: '请输入跳转链接', trigger: 'blur' }, { validator: urlValidate, trigger: 'blur' }]
      }
    }
  },
  methods: {
    // 判断图片大小
    beforeAvatarUpload: async function (file) {
      const isType = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'].includes(file.type)
      const isSize = file.size / 1024 <= 500

      if (!isType) {
        this.$message.error('图片格式支持png、jpg、jpeg、gif')
      }
      if (!isSize) {
        this.$message.error(`图片大小不超过500KB!`)
      }
      const reader = new FileReader()
      reader.readAsDataURL(file)
      // 让页面中的img标签的src指向读取的路径
      await new Promise((resolve, reject) => {
        if (!(isType && isSize)) {
          reject(false)
          return false
        }
        reader.onload = () => {
          const img = new Image()
          img.src = reader.result
          img.onload = () => {
            if (img.width !== 750 || img.height > 1536) {
              this.$message.error(`图片尺寸不符合宽度750px，高度不超过1536px`)
              reject(false)
              return false
            } else {
              resolve(true)
            }
          }
        }
      })
      // return isType && isSize
    },

    // 图片提交
    handleUpload(_file) {
      let that = this
      let file = _file.file
      const form = new FormData()
      // 文件对象
      form.append('file', file)
      this.getFileHash(file).then((val) => {
        this.ossUpload(file, {
          width: 0,
          size: file.size,
          hash: val.HASH,
          height: 0,
          duration: 0
        }, (val) => {
          console.log(val)
          that.detail.pureImageUrl = val.url
        }, (er) => {
          this.$message.error(er || '上传失败！')
        }, (p) => {
        })
      })
    },

    getFileHash(file) {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)
        reader.onload = ev => {
          let buffer = ev.target.result
          let spark = new SparkMD5.ArrayBuffer()
          let HASH
          let suffix
          spark.append(buffer)
          HASH = spark.end()
          suffix = file.name.substring(file.name.lastIndexOf('.') + 1)
          resolve({
            buffer,
            HASH,
            suffix,
            filename: `${HASH}.${suffix}`
          })
        }
      })
    },

    // 删除上传图片
    handleRemoveImage(e) {
      e && e.stopPropagation()
      this.detail.pureImageUrl = ''
    },

    validateForm() {
      let flag = true
      this.$refs.imgForm.validate(valid => {
        flag = valid
      })

      return flag
    }
  }
}
</script>

<style lang="scss">
.avatar-uploader .el-upload {
  width: 100% !important;
}

.img-form-box {
  width: 100%;

  .el-form-item--mini .el-form-item__label {
    font-size: 12px;
  }
}
</style>

<style lang="scss" scoped>
.avatar-uploader {
  width: 100% !important;
  height: 150px;
  line-height: 150px;
  text-align: center;
  border: 1px solid #edecf2;
  background-color: #fbfafd;
  border-radius: 3px;
  cursor: pointer;
  font-size: 30px;
  color: #7d7d7d;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .avatar {
    width: 150px;
  }

  .uploader-actions {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #000000, $alpha: 0.4);
    opacity: 0;

    .upload_action_delete {
      color: #fff;
      font-size: 20px;

      &:first-child {
        margin-right: 15px;
      }
    }
  }

  &:hover {
    .uploader-actions {
      opacity: 1;
    }
  }
}

.section {
  .w-100 {
    font-size: 12px;
    margin-right: 10px;
  }
}

.image-updata {
  display: flex !important;
  align-items: flex-start !important;

  &>div {
    width: 100% !important;
  }
}
</style>
