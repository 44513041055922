var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "module-box" }, [
    _c("div", { staticClass: "title" }, [
      _c("p", [
        _vm._v(" " + _vm._s(_vm.title) + " "),
        _c("span", { staticClass: "tip ml-10" }, [_vm._v(_vm._s(_vm.desc))]),
      ]),
    ]),
    _c("div", { staticClass: "module" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }