<template>
  <div>
    <el-tabs
      v-model="activeName"
      style="margin-bottom: 10px"
      @tab-click="handleClick"
    >
      <el-tab-pane
        label="数据查询"
        name="first"
      ></el-tab-pane>
      <el-tab-pane
        label="文件下载"
        name="second"
      ></el-tab-pane>
    </el-tabs>

    <!-- 列表查询 -->
    <el-form
      ref="search"
      :inline="true"
      :model="search"
      class="search-form search-form-4"
    >
      <el-row :gutter="20">
        <el-col
          v-if="activeName === 'second'"
          :span="6"
        >
          <el-form-item
            label="生成日期"
            prop="generateTime"
          >
            <el-date-picker
              v-model="search.generateTime"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col
          v-if="activeName === 'second'"
          :span="6"
        >
          <el-form-item
            label="创建人"
            prop="createBy"
          >
            <el-input
              v-model="search.createBy"
              placeholder="请输入创建人"
              style="width: 100%"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item
            label="数据类型"
            prop="dataType"
          >
            <el-select
              v-model="search.dataType"
              style="width: 100%"
              clearable
            >
              <el-option
                label="企微留存"
                :value="1"
              ></el-option>
              <el-option
                label="企微流失"
                :value="2"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col
          v-if="activeName === 'second'"
          :span="6"
        >
          <el-form-item>
            <el-button
              type="primary"
              round
              @click="onSubmit"
            >查 询</el-button>
            <el-button
              type="primary"
              round
              @click="resetForm('ruleForm')"
            >重 置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div
      v-if="activeName === 'first'"
      class="projection-header"
      style="margin-bottom: 30px"
    >
      <span>数据生成条件</span>
    </div>

    <el-form
      v-if="activeName === 'first'"
      ref="form"
      :model="formData"
      :rules="rules"
      :validate-on-rule-change="false"
      label-position="right"
      label-suffix="："
      label-width="135px"
    >
      <el-row
        :gutter="24"
        type="flex"
      >
        <el-col :span="8">
          <el-form-item
            label="进粉时间"
            prop="followersTime"
          >
            <el-date-picker
              v-model="formData.followersTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="区分性别">
            <el-select
              v-model="formData.isUseGender"
              style="width: 80px; margin-right: 5px"
              placeholder="请选择是否区分"
            >
              <el-option
                label="是"
                :value="1"
              > </el-option>
              <el-option
                label="否"
                :value="2"
              > </el-option>
            </el-select>

            <el-select
              v-if="formData.isUseGender === 1"
              v-model="formData.gender"
              placeholder="请选择性别"
              multiple
              filterable
              collapse-tags
              clearable
            >
              <el-option
                label="男"
                :value="1"
              > </el-option>
              <el-option
                label="女"
                :value="2"
              > </el-option>
              <el-option
                label="未知"
                :value="3"
              > </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item
            label="导出数据"
            prop="exportData"
          >
            <el-select
              v-model="formData.exportData"
              placeholder="请选择导出数据"
              multiple
              filterable
              collapse-tags
              clearable
            >
              <el-option
                label="IDFA"
                :value="1"
              > </el-option>
              <el-option
                label="IMEI"
                :value="2"
              > </el-option>
              <el-option
                label="MAC"
                :value="3"
              > </el-option>
              <el-option
                label="OAID"
                :value="4"
              > </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
        :gutter="24"
        type="flex"
      >
        <el-col :span="8">
          <el-form-item
            class="title-label"
            label="指定企微"
          >
            <el-select
              v-model="formData.selectWeCom"
              placeholder="请选择是否指定企微"
              style="width: 100px; margin-right: 5px"
            >
              <el-option
                label="是"
                :value="1"
              > </el-option>
              <el-option
                label="否"
                :value="2"
              > </el-option>
            </el-select>
            <span>注：不指定则全量数据生成</span>
            <div
              v-if="formData.selectWeCom === 1"
              style="margin-top: 10px"
            >
              <el-input
                v-model="formData.weComId"
                type="textarea"
                :rows="6"
                placeholder="输入企微主体ID,支持多个，回传换行区格"
              >
              </el-input>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div
      v-if="activeName === 'first'"
      class="form-footer"
    >
      <el-button
        type="primary"
        :disabled="loading"
        @click="handleConfirm()"
      >生成数据文件</el-button>
    </div>

    <div>
      <el-table
        v-if="activeName === 'second'"
        v-loading="list.loading"
        :data="list.data"
        stripe
        class="list-table"
        :class="{ 'no-data': !list.data || !list.data.length }"
        style="width: 100%"
      >
        <el-table-column
          type="index"
          label="序号"
          width="50"
          align="center"
          fixed="left"
        ></el-table-column>

        <el-table-column
          label="类型"
          align="center"
        >
          <template slot-scope="{ row }">
            {{ row.dataType | filterToolType }}
          </template>
        </el-table-column>

        <el-table-column
          label="数据条件"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-button
              type="text"
              @click="onClickCheck(row)"
            >查看</el-button>
          </template>
        </el-table-column>

        <el-table-column
          label="状态"
          align="center"
        >
          <template slot-scope="{ row }">
            <span :style="{color: row.status === '生成失败' ? 'red' : row.status === '已完成' ? 'lime' : ''}">{{ row.status }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="createBy"
          label="创建人"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="createTime"
          label="生成时间"
          align="center"
          width="200"
        ></el-table-column>

        <el-table-column
          prop=""
          label="操作"
          align="center"
          fixed="right"
        >
          <template slot-scope="{ row }">
            <el-button
              v-if="row.status === '已完成'"
              type="text"
              @click="onClickDownload(row.url)"
            >下载</el-button>
            <el-button
              v-else-if="row.status === '生成失败'"
              type="text"
              @click="onClickRegenerationData(row.tiktokExportZipId)"
            >重新生成</el-button>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>

      <div
        v-if="activeName === 'second'"
        class="page"
      >
        <paginations
          :total="list.total"
          :page.sync="list.pageNum"
          :page-sizes="[5, 10, 15, 20]"
          :limit.sync="list.pageSize"
          @pagination="getPage"
        />
      </div>
    </div>

    <!-- 数据详情 -->
    <data-details-dialog ref="dataDetails" />
  </div>
</template>

<script>
import {
  generateData,
  regenerationData,
  Page
} from '@/network/api/assetManagement/api-populationData'

import DataDetailsDialog from './components/DataDetailsDialog'

export default {
  components: {
    'data-details-dialog': DataDetailsDialog
  },
  filters: {
    filterToolType(value) {
      const types = { 1: '企微留存', 2: '企微流失' }
      return types[value] || ''
    }
  },
  data() {
    return {
      activeName: 'first',
      loading: false,

      search: {
        generateTime: this.getNowTime(new Date()),
        createBy: '',
        dataType: ''
      },

      formData: {
        followersTime: this.getDateTime(),
        isUseGender: 2,
        gender: [],
        exportData: [1, 2, 3, 4],
        selectWeCom: 2,
        weComId: ''
      },

      list: {
        loading: true,
        data: [],
        pageSize: 10,
        pageNum: 1,
        total: 0
      },

      pickerOptions: {
        disabledDate: time => {
          return time.getTime() + 1 * 24 * 3600 * 1000 > Date.now()
        }
      },

      rules: {
        followersTime: [
          { required: true, message: '请选择进粉时间', trigger: 'change' }
        ],
        exportData: [
          { required: true, message: '请选择导出数据', trigger: 'change' }
        ]
      }
    }
  },

  mounted() {
    this.getPage()
  },

  methods: {
    // 列表
    getPage() {
      this.list.loading = true
      let params = {
        ...this.search,
        pageSize: this.list.pageSize,
        pageNum: this.list.pageNum
      }
      Page(params)
        .then(({ code, data }) => {
          if (code === 200) {
            const { records, total } = data
            this.list.data = records || []
            this.list.total = total
          }
        })
        .finally((e) => {
          this.list.loading = false
        })
    },

    // 获取日期
    getNowTime(temp) {
      var now = new Date(temp)
      var year = now.getFullYear() // 得到年份
      var month = now.getMonth() + 1 // 得到月份
      var date = now.getDate() // 得到日期
      month = month.toString().padStart(2, '0')
      date = date.toString().padStart(2, '0')
      return `${year}-${month}-${date}`
    },

    // 获取近7天时间段
    getDateTime() {
      const start = new Date(new Date().getTime() - 3600 * 1000 * 24 * 7).toISOString().split('T')[0]
      const end = new Date(new Date().getTime() - 3600 * 1000 * 24 * 1).toISOString().split('T')[0]
      this.$emit('input', this.formateDates([start, end]))
      return [start, end]
    },

    // 格式化时间
    formateDates(dates) {
      const reg = new RegExp('-', 'g')
      const value1 = dates[0].replace(reg, '.')
      const value2 = dates[1].replace(reg, '.')
      return [value1, value2]
    },

    // 修改类型
    handleClick() {
      this.search.dataType = ''
    },

    // 搜索
    onSubmit() {
      if (this.list.pageNum === 1) {
        this.getPage()
      } else {
        this.list.pageNum = 1
      }
    },

    // 重置
    resetForm() {
      this.$refs['search'].resetFields()
      this.search = {
        generateTime: '',
        createBy: '',
        dataType: ''
      }
      this.getPage()
    },

    // 下载文件
    onClickDownload(url) {
      window.open(url)
    },

    // 查看数据
    onClickCheck(val) {
      this.$refs.dataDetails.showModal(val)
    },

    // 重新生成数据
    onClickRegenerationData(tiktokExportZipId) {
      regenerationData(tiktokExportZipId)
        .then(({ code }) => {
          if (code === 200) {
            this.$message.success('数据开始重新生成')
            this.getPage()
          }
        })
        .finally(() => {})
    },

    // 生成数据文件
    handleConfirm() {
      if (!this.search.dataType) {
        this.$message.error('请选择数据类型')
        return
      }

      if (this.formData.isUseGender === 1 && !this.formData.gender.length) {
        this.$message.error('请选择性别')
        return
      }

      if (this.formData.selectWeCom === 1 && !this.formData.weComId) {
        this.$message.error('请输入企微主体id')
        return
      }

      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }

        let parmas = Object.assign({}, this.formData)
        parmas.dataType = this.search.dataType
        parmas.followersTime = parmas.followersTime.join(',')
        parmas.gender = parmas.gender.join(',')
        parmas.exportData = parmas.exportData.join(',')

        this.loading = true
        generateData(parmas)
          .then(({ code }) => {
            if (code === 200) {
              this.$message.success('任务已创建，请至文件下载中查看！')
              this.getPage()
            }
          })
          .finally(() => {
            this.loading = false
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.projection-header {
  font-size: 20px;
  font-weight: 500;
  color: #1a1a1a;
  position: relative;
  margin: 10px 0;

  &::before {
    content: '';
    display: inline-block;
    width: 3px;
    height: 20px;
    background-color: $themeColor;
    position: absolute;
    top: 5px;
  }

  span {
    margin-left: 12px;
  }
}

.form-footer {
  text-align: center;
  margin-top: 200px;
}
</style>
