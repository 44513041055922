<!--
 * @Description: 巨量回传  回传设置
 * @Date: 2021-10-19 19:05:18
 * @LastEditTime: 2022-05-19 17:14:39
-->

<template>
  <div>
    <!-- 列表查询 -->
    <el-form ref="search" :inline="true" :model="search" class="search-form search-form-4">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="投放平台" prop="type">
            <el-select v-model="search.type" placeholder="请选择平台名称">
              <!-- <el-option label="全部" :value="null"></el-option> -->
              <el-option v-for="item in platform_4" :key="item.dictValue" :label="item.dictLabel" :value="+item.dictValue"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="来源" prop="platform">
            <el-select v-model="search.platform" placeholder="请选择来源">
              <el-option v-for="item in platformList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="名称/ID" prop="accounts">
            <el-input v-model="search.accounts" placeholder="请输入名称/ID,多个以空格区隔"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="匹配状态" prop="match_status">
            <el-select v-model="search.match_status">
              <el-option label="全部" value="1"></el-option>
              <el-option label="已匹配" value="2"></el-option>
              <el-option label="未匹配" value="3"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="回传状态" prop="status">
            <el-select v-model="search.status">
              <el-option label="全部" :value="null"></el-option>
              <el-option label="未回传" value="0"></el-option>
              <el-option label="回传成功" value="1"></el-option>
              <el-option label="回传失败" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="广告 I D " prop="adid">
            <el-input v-model="search.adid" placeholder="请输入广告ID"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="注册时间" prop="user_createtime">
            <el-date-picker v-model="search.user_createtime" type="daterange" value-format="yyyy-MM-dd" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" round @click="onSubmit">查询</el-button>
            <el-button round @click="resetForm">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- 表格 -->
    <TablePane :data-source="dataSource" @getPage="getPage">
      <template slot="operation" slot-scope="{ row }">
        <div v-if="row.virtual_status === 2">
          <el-tooltip :content="row.virtual_remark" placement="top">
            <span style="font-size: 12px">已回传</span>
          </el-tooltip>
        </div>

        <el-button v-if="row.virtual_status === 0" type="text" disabled> _ </el-button>
      </template>
    </TablePane>

    <el-dialog title="虚拟回传" :visible.sync="virtual" append-to-body width="600px">
      <span>
        <div class="justify-sb align-center">
          <p>投放平台：{{ virtualRow.type ? platform_4.find((f) => +f.dictValue === +virtualRow.type).dictLabel : '-' }}</p>
          <p>来源：{{ virtualRow.platform ? platformList.find((f) => f.dictValue === virtualRow.platform).dictLabel : '-' }}</p>
          <p>名称： {{ virtualRow.public_name }}</p>
        </div>
        <div class="justify-sb align-center">
          <p>用户ID：{{ virtualRow.uid }}</p>
          <p>广告ID：{{ virtualRow.adid }}</p>
          <p style="width: 100px"></p>
        </div>
        <div class="justify-center align-center mt-10">
          <span class="mr-10">回传金额:</span>
          <el-input-number v-model="virtualPrice" controls-position="right" :min="0" placeholder="请输入回传金额" style="width: 200px" />
        </div>
      </span>
      <!--底部按钮-->
      <div slot="footer" class="dialog-footer">
        <el-button class="btn" @click="virtual = false">取 消</el-button>
        <el-button class="btn" type="primary" @click="virtualSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TablePane from '../../../components/tablePane.vue'
import { activationPage, manualCallback } from '@/network/api/passBack/index.js'
import { getDateStartEnd } from '@/utils/ruoyi'
import moment from 'moment'
export default {
  components: {
    TablePane
  },
  data() {
    return {
      platformList: [], // 书城
      platform_4: [],
      virtual: false,
      virtualRow: {},
      virtualPrice: 0,
      search: {
        accounts: null,
        adid: null,
        type: 3,
        platform: 'P1001',
        match_status: '1',
        status: null,
        user_createtime: []
      },
      dataSource: {
        data: [], // 表格数据
        cols: [
          {
            label: '投放平台',
            fixed: 'left',
            width: 100,
            render: (record) => <span>{this.platform_4.find((f) => +f.dictValue === +record.type).dictLabel}</span>
          },
          {
            label: '来源',
            fixed: 'left',
            width: 100,
            render: (record) => <span>{this.platformList.find((f) => f.dictValue === record.platform).dictLabel}</span>
          },
          {
            label: '名称',
            width: 100,
            fixed: 'left',
            prop: 'public_name'
          },
          {
            label: 'ID',
            prop: 'ghid'
          },
          {
            label: '用户昵称',
            prop: 'nickname'
          },
          {
            label: '用户ID',
            prop: 'uid'
          },
          {
            label: '注册时间',
            width: 100,
            render: (record) => {
              return (
                /*eslint-disable*/
                record.user_createtime ? (
                  <div style="font-size: 12px">
                    <div>{moment(moment.unix(record.user_createtime)).format('YYYY-MM-DD')}</div>
                    <div>{moment(moment.unix(record.user_createtime)).format('HH:mm:ss')}</div>
                  </div>
                ) : (
                  '_'
                )
              )
            }
          },
          {
            label: '匹配状态',
            render: (record) => {
              return (
                // eslint-disable-next-line
                record.uid > 0 ? <el-tag type="success">已匹配</el-tag> : <el-tag type="info">未匹配</el-tag>
              )
            }
          },
          {
            label: '回传状态',

            render: function(record) {
              return (
                // eslint-disable-next-line
                +record.status === 0 ? <el-tag type="info">未回传</el-tag> : +record.status === 1 ? <el-tag type="success">回传完成</el-tag> : <el-tag type="danger">回传失败</el-tag>
              )
            }
          },
          {
            label: '广告ID',
            prop: 'adid'
          }
        ], // 表格的列数据
        isIndex: true, // 列表序号
        loading: false, // loading
        isOperation: true,
        pageData: {
          total: 0 // 总条数
        },
        handleSelectionChange() {},
        operation: {
          // 表格有操作列时设置
          label: '虚拟回传', // 列名
          width: 100, // 根据实际情况给宽度
          fixed: 'right',
          data: [
            {
              label: '立即回传', // 操作名称
              type: 'text', // 按钮类型
              showLabel: 'virtual_status',
              showValue: [1],
              handleRow: (index, record) => {
                this.virtual = true
                this.virtualRow = record
              } // 自定义事件
            }
          ]
        }
      }
    }
  },

  mounted() {
    this.getDicts('return_platform_type').then((response) => {
      this.platformList = response.data
      this.search.platform = response.data[0].dictValue
      this.getPage()
    })
    this.getDicts('return_compaign_platform').then((response) => {
      this.platform_4 = response.data
    })
  },

  methods: {
    // 虚拟回传确定
    async virtualSubmit() {
      let params = {
        recordId: this.virtualRow.id,
        money: this.virtualPrice,
        platform: this.virtualRow.platform
      }
      const { code, data } = await manualCallback(params)
      if (code === 200) {
        this.virtual = false
        this.virtualRow = {}
        this.virtualPrice = 0
        this.getPage()
        this.$message.success('虚拟回传完成!')
      }
    },

    /**
     * @description: 获取列表
     * @param {*} params
     */
    getPage(params = { pageNum: 1, pageSize: 10 }) {
      this.dataSource.loading = true
      const [start_user_createtime, end_user_createtime] = getDateStartEnd(this.search.user_createtime)
      let accounts = this.search.accounts ? this.search.accounts.split(' ').filter((f) => !!f) : []
      let search = {
        ...this.search,
        accounts: accounts.length ? accounts.map((item) => item.replace(/\s+/g, '')).join(' ') : null,
        start_user_createtime,
        end_user_createtime
      }
      delete search.user_createtime
      activationPage({ ...search, ...params }).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          this.dataSource.data = records
          records.forEach((element) => {
            element.nickname = element.nickname || '_'
            element.adid = element.adid || '_'
            element.uid = element.uid || '_'
          })
          this.dataSource.pageData.total = total
          this.dataSource.pageData.pageNum = params.pageNum
          this.dataSource.loading = false
        }
      })
    },
    /**
     * @description: 重置
     */
    resetForm() {
      this.$refs['search'].resetFields()
      this.$nextTick(() => {
        this.getPage()
      })
    },

    /**
     * @description: 查询
     */
    onSubmit() {
      this.getPage()
    }
  }
}
</script>
