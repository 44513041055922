var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "data-details-dialog",
      attrs: {
        title: "数据生成条件",
        visible: _vm.show,
        "append-to-body": true,
        width: "500px",
        top: "20vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.onCloseDialog,
      },
    },
    [
      _c("div", { staticClass: "page-container" }, [
        _c("div", { staticClass: "desc-box" }, [
          _c("div", { staticClass: "box-item" }, [
            _c("span", [_vm._v("数据条件：")]),
            _c("span", { staticStyle: { color: "#989898" } }, [
              _vm._v(_vm._s(_vm._f("filterToolType")(_vm.dataType))),
            ]),
          ]),
          _c("div", { staticClass: "box-item" }, [
            _c("span", [_vm._v("加粉时间：")]),
            _c("span", { staticStyle: { color: "#989898" } }, [
              _vm._v(
                _vm._s(_vm.followersTime.split(",")[0]) +
                  "至" +
                  _vm._s(_vm.followersTime.split(",")[1])
              ),
            ]),
          ]),
          _c("div", { staticClass: "box-item" }, [
            _c("span", [_vm._v("区别性别：")]),
            _c("span", { staticStyle: { color: "#989898" } }, [
              _vm._v(" " + _vm._s(_vm.isUseGender === 2 ? "否" : _vm.gender)),
            ]),
          ]),
          _c("div", { staticClass: "box-item" }, [
            _c("span", [_vm._v("导出数据：")]),
            _c("span", { staticStyle: { color: "#989898" } }, [
              _vm._v(_vm._s(_vm.exportData)),
            ]),
          ]),
          _c("div", { staticClass: "box-item" }, [
            _c("span", [_vm._v("指定企微：")]),
            _c("span", { staticStyle: { color: "#989898" } }, [
              _vm._v(_vm._s(+_vm.selectWeCom === 2 ? "否" : _vm.weComId)),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "btn", on: { click: _vm.closeModal } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }