/*
 * @Description: Do not edit
 * @Date: 2021-12-08 10:39:24
 * @LastEditTime: 2021-12-13 17:50:57
 */
// <!-- <el-radio :label="711">横版大图 16:9</el-radio>
//               <el-radio :label="712">竖版大图 9:16</el-radio>
//               <el-radio :label="718">横版三小图 3:2</el-radio>
//               <el-radio :label="721" class="mt-10">竖版视频 9:16</el-radio>
//               <el-radio :label="720" class="mt-10">横版视频 16:9</el-radio> -->
export function getMaterialList(type, taskId, taskSiteSet) {
  let data = []
  if (type === 311) {
    data.push({
      taskId: taskId,
      taskAssetId: null,
      materialName: '',
      assetGroupName: '创意组01',
      assetGroupOrderBy: 1, // 组排序
      assetType: 'IMG',
      assetLocalId: null,
      assetLocalUrl: null,
      assetLocalSignature: null,
      assetLocalResizeFileSize: 300,
      assetLocalResizeWidth: 800,
      assetLocalResizeHeight: 800
    })
  }
  if (type === 641 || type === 642 || type === 643) {
    let num = 3
    switch (type) {
      case 641:
        num = 3
        break
      case 642:
        num = 4
        break
      case 643:
        num = 6
        break
      default:
        num = 3
    }
    for (let i = 1; i <= num; i++) {
      data.push({
        taskId: taskId,
        taskAssetId: null,
        materialName: '',
        assetGroupName: '创意组01',
        assetGroupOrderBy: 1, // 组排序
        assetType: 'IMG',
        assetLocalId: null,
        assetLocalUrl: null,
        assetLocalSignature: null,
        assetLocalResizeFileSize: 300,
        assetLocalResizeWidth: 800,
        assetLocalResizeHeight: 800
      })
    }
  }
  if (type === 711 || type === 712) {
    data.push({
      taskId: taskId,
      taskAssetId: null,
      materialName: '',
      assetGroupName: '创意组01',
      assetGroupOrderBy: 1, // 组排序
      assetType: 'IMG',
      assetLocalId: null,
      assetLocalUrl: null,
      assetLocalSignature: null,
      assetLocalResizeFileSize: 400,
      assetLocalResizeWidth: type === 711 ? 1280 : 720,
      assetLocalResizeHeight: type === 711 ? 720 : 1280

      // creativeGroup: '创意组01',
      // autoGenerateCover: null,
      // taskId,
      // dataType: 'image',
      // localId: null,
      // localUrl: null,
      // type: null,
      // assetId: null,
      // maxSize: 140,
      // width: type === 711 ? 1280 : 1080,
      // height: type === 711 ? 720 : 1920
    })
  }
  if (type === 721 || type === 720 || type === 599) {
    data.push({
      taskId,
      taskAssetId: null,
      materialName: '',
      assetGroupName: '创意组01',
      assetGroupOrderBy: 1, // 组排序
      assetType: 'VIDEO',
      assetLocalId: null,
      assetLocalUrl: null,
      assetLocalSignature: null,
      assetLocalResizeFileSize: 100 * 1024,
      assetLocalResizeWidth: type === 599 ? 1280 : (type === 720 ? 1280 : 720),
      assetLocalResizeHeight: type === 599 ? 960 : (type === 720 ? 720 : 1280)
    })
    // [3, 4, 23, 24, 34, 234, 6].includes(taskSiteSet)
    if (taskSiteSet !== 1) {
      data.push({
        taskId: taskId,
        taskAssetId: null,
        materialName: '',
        assetGroupName: '创意组01',
        assetGroupOrderBy: 1, // 组排序
        assetType: 'IMG',
        assetLocalId: null,
        assetLocalUrl: null,
        assetLocalSignature: null,
        assetLocalResizeFileSize: 400,
        assetLocalResizeWidth: type === 599 ? 1280 : (type === 720 ? 1280 : 720),
        assetLocalResizeHeight: type === 599 ? 960 : (type === 720 ? 720 : 1280)
      })
    }
  }
  return data
}

export const creativeType = {
  311: '常规大图',
  641: '常规多图 三图',
  642: '常规多图 四图',
  643: '常规多图 六图',
  711: '横版大图',
  712: '竖版大图',
  720: '横版视频',
  721: '竖版视频',
  599: '横版视频'
}

export const adSiteSetOption = [
  { label: '朋友圈', value: 1 },
  { label: '微信公众号与小程序', value: 2 },
  { label: '腾讯平台与内容媒体', value: 3 },
  { label: '优量汇', value: 4 },
  { label: '腾讯平台与内容媒体+优量汇', value: 5 },
  { label: '视频号', value: 6 }
]

export const adcreativeTemplateTypImg = {
  1: [
    { label: 311, text: '常规大图 1:1' },
    { label: 641, text: '常规多图 1:1 三图' },
    { label: 642, text: '常规多图 1:1 四图' },
    { label: 643, text: '常规多图 1:1 六图', class: 'mt-10' },
    { label: 720, text: '横版视频 16:9', class: 'mt-10' },
    { label: 721, text: '竖版视频 9:16', class: 'mt-10' }
  ],
  2: [
    { label: 711, text: '横版大图 16:9' },
    { label: 720, text: '横版视频 16:9' },
    { label: 721, text: '竖版视频 9:16' }
  ],
  3: [
    { label: 711, text: '横版大图 16:9' },
    { label: 712, text: '竖版大图 9:16' },
    { label: 720, text: '横版视频 16:9' },
    { label: 721, text: '竖版视频 9:16', class: 'mt-10' }
  ],
  4: [
    { label: 711, text: '横版大图 16:9' },
    { label: 712, text: '竖版大图 9:16' },
    { label: 720, text: '横版视频 16:9' },
    { label: 721, text: '竖版视频 9:16', class: 'mt-10' }
  ],
  12: [
    { label: 720, text: '横版视频 16:9' },
    { label: 721, text: '竖版视频 9:16' }
  ],
  13: [
    { label: 720, text: '横版视频 16:9' },
    { label: 721, text: '竖版视频 9:16' }
  ],
  14: [
    { label: 311, text: '常规大图 1:1' },
    { label: 641, text: '常规多图 1:1 三图' },
    { label: 642, text: '常规多图 1:1 四图' },
    { label: 643, text: '常规多图 1:1 六图', class: 'mt-10' },
    { label: 720, text: '横版视频 16:9', class: 'mt-10' },
    { label: 721, text: '竖版视频 9:16', class: 'mt-10' }
  ],
  23: [
    { label: 711, text: '横版大图 16:9' },
    { label: 720, text: '横版视频 16:9' },
    { label: 721, text: '竖版视频 9:16' }
  ],
  24: [
    { label: 711, text: '横版大图 16:9' },
    { label: 720, text: '横版视频 16:9' },
    { label: 721, text: '竖版视频 9:16' }
  ],
  34: [
    { label: 711, text: '横版大图 16:9' },
    { label: 720, text: '横版视频 16:9' },
    { label: 721, text: '竖版视频 9:16' }
  ],
  123: [
    { label: 720, text: '横版视频 16:9' },
    { label: 721, text: '竖版视频 9:16' }
  ],
  124: [
    { label: 720, text: '横版视频 16:9' },
    { label: 721, text: '竖版视频 9:16' }
  ],
  134: [
    { label: 311, text: '常规大图 1:1' },
    { label: 720, text: '横版视频 16:9' },
    { label: 721, text: '竖版视频 9:16' }
  ],
  234: [
    { label: 711, text: '横版大图 16:9' },
    { label: 720, text: '横版视频 16:9' },
    { label: 721, text: '竖版视频 9:16' }
  ],
  1234: [
    { label: 720, text: '横版视频 16:9' },
    { label: 721, text: '竖版视频 9:16' }
  ],
  6: [
    { label: 599, text: '横版视频 4:3', class: 'mt-10' },
    { label: 720, text: '横版视频 16:9', class: 'mt-10' },
    { label: 721, text: '竖版视频 9:16', class: 'mt-10' }
  ]
}

export const adcreativeTemplateTypVideo = [
  { label: 720, text: '横版视频 16:9' },
  { label: 721, text: '竖版视频 9:16' },
  { label: 599, text: '横版视频 4:3' }
]

export const linkNameTypeMap = {
  VIEW_DETAILS: '查看详情',
  MORE_INFO: '了解更多',
  BUY_NOW: '立即购买',
  RESERVE_NOW: '立即预约',
  CONSULT_NOW: '立即咨询',
  APPLY_NOW: '立即申请',
  BOOK_NOW: '立即预定',
  GET_COUPONS: '领取优惠',
  GET_IT_NOW: '立即领取',
  GO_SHOPPING: '去逛逛',
  TRY_NOW: '立即体验',
  BUY_ASAP: '立即抢购',
  SETUP_NOW: '立即开通',
  SECKILL_NOW: '立即秒杀',
  WATCH_LIVE: '观看直播',
  ENTER_MINI_PROGRAM: '进入小程序'
}

// 微信公众号与小程序
export const wechatOptions = ['b', 'c']
// 腾讯平台与内容媒体
export const tencentOptions = ['d', 'e', 'f', 'g']
