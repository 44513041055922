<!--
 * @Description:选择推广单元等框 Tree
 * @Date: 2021-11-09 11:49:47
 * @LastEditTime: 2022-03-08 17:26:30
-->
<template>
  <section class="section">
    <el-row type="flex" justify="space-between" :gutter="10" style="height: 100%">
      <el-col class="tree" :span="18">
        <div class="left-ul">
          <p>已选{{ `${leftTitle}模板` }}</p>
          <!-- 模板数据 -->
          <ul>
            <div v-if="_leftQueryList.length">
              <!-- 左侧选择框是  tree 还是  <li></li> -->
              <div v-if="titleInfo.leftLevel === '2'">
                <el-tree :render-content="renderContent" :data="leftQueryList" default-expand-all :props="defaultProps" @node-click="setLeftQuerySelect"></el-tree>
              </div>
              <div v-else>
                <li v-for="item in _leftQueryList" :key="item._id" :class="{ active: leftQuerySelect._id === item._id }" @click="setLeftQuerySelect(item)">{{ item._name }}</li>
              </div>
            </div>
            <div v-else style="text-align:center; margin-top: 40px">
              <span>未选择</span>
            </div>
          </ul>
        </div>

        <!-- tree 投标筛选部分 -->
        <div class="tree-select">
          <div class="tree-top">
            <p>{{ `已有${dialogTitle}` }}</p>
            <el-input v-model="filterText" placeholder="输入关键字进行过滤" size="small" style="width: 300px"> </el-input>
          </div>
          <div v-loading="treeLoading" class="filter-tree-box">
            <el-tree ref="tree" :current-node-key="7" node-key="id" show-checkbox class="filter-tree" :data="data" :props="{ label: 'label' }" default-expand-all :filter-node-method="filterNode" @check-change="checkChange"> </el-tree>
            <el-pagination style="text-align: right;" layout="prev, pager, next" :total="total" @current-change="currentChange"> </el-pagination>
          </div>
        </div>
      </el-col>

      <!-- 已选择部分 -->
      <el-col class="selected-tree" :span="6">
        <p>已选{{ dialogTitle }}</p>

        <ul>
          <li v-for="item in _selectedData" :key="item.id" class="selected-tree-li">
            <p>{{ item.label }}</p>
            <i class="el-icon-circle-close" @click="deleteItem(item.id)"></i>
          </li>
        </ul>
      </el-col>
    </el-row>
  </section>
</template>

<script>
// import { arraySetCount } from '@/utils/ruoyi'
// import Submenu from './submenu.vue'

export default {
  inject: ['getDataByLeftQuery'],
  components: {
    // Submenu
  },
  props: {
    dialogTitle: {
      type: String,
      default: ''
    },
    total: {
      type: Number,
      default: 0
    },
    leftTitle: {
      type: String,
      default: ''
    },
    titleInfo: {
      type: Object,
      default: () => {}
    },
    visible: {
      type: Boolean,
      default: false
    },
    // 模板数据
    data: {
      type: Array,
      default: () => []
    },
    // 模板左侧数据
    leftQueryList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      filterText: '', // 过滤主体类容
      subTitleInedx: 0,
      // 选中的数据
      selectedData: [], // 选中的数据
      // 本地左侧选中的数据
      leftQuerySelect: {},
      selectedDataOnline_copy: {},
      selectedDataOnlineList: [],
      copyList: [],
      treeLoading: false,
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },

  computed: {
    // 去除重复数据
    _selectedData() {
      let data = []
      this.selectedData.map((item) => {
        if (!data.some((s) => s.id === item.id) && !item.type2) {
          data.push(item)
        }
      })
      return data
    },

    // 去重localQuery
    _leftQueryList() {
      let data = []
      this.leftQueryList.map((item) => {
        if (!data.some((s) => s._id === item._id)) {
          data.push(item)
        }
      })
      return data
    },
    // 左侧树形选择框
    _localQueryTree() {
      let newArr = []
      this.leftQueryList.map((item) => {
        if (!newArr.some((s) => s && s.label === item._name)) {
          newArr.push({
            ...item,
            label: item.accountName
            // children: []
          })
        }
      })

      if (this.titleInfo.leftLevel === '2') {
        this.leftQueryList.forEach((element) => {
          newArr.forEach((newElement) => {
            if (element.accountName === newElement.label) {
              newElement.children = newElement.children ? newElement.children : []
              newElement.children.push({
                ...element,
                label: element.label
              })
            }
          })
        })
      }
      if (this.dialogTitle === '3') {
        this.leftQueryList.forEach((element) => {
          newArr.forEach((newElement) => {
            if (element.accountName === newElement.label) {
              newElement.children = newElement.children ? newElement.children : []
              if (newElement.children.length === 0 || !newElement.children.some((s) => s.label === element.baiduUnitName)) {
                newElement.children.push({
                  ...element,
                  label: element.campaignFeedName
                })
              }
            }
          })
        })

        newArr.forEach((newElement) => {
          newElement.children.forEach((item) => {
            item.children = this.leftQueryList.filter((f) => f.baiduUnitName === item.baiduUnitName && f.campaignFeedId === item.campaignFeedId)
          })
        })
      }
      return newArr
    },
    // 选中 线上数据中 的 模板数据
    _selectedDataOnlineList() {
      let newArr = []
      this.selectedDataOnlineList.map((item) => {
        if (!newArr.some((s) => s && s.campaignFeedName === item.campaignFeedName)) {
          newArr.push({
            ...item
          })
        }
      })

      this.selectedDataOnlineList.forEach((element) => {
        newArr.forEach((newElement) => {
          if (element.campaignFeedName === newElement.campaignFeedName) {
            newElement.children = newElement.children ? newElement.children : []
            newElement.children.push({
              ...element
            })
          }
        })
      })
      return newArr
    }
  },

  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
    visible: {
      handler: function(value) {
        if (value === true) {
          console.log(this.leftQueryList)
          if (this.leftQueryList.length) {
            this.setLeftQuerySelect(this.leftQueryList[0])
          }
        }
      },
      immediate: true
    }
  },

  methods: {
    /**
     * @description 筛选tree
     */
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },

    renderContent(h, { node }) {
      return (
        // eslint-disable-next-line
        <span class="custom-tree-node text-ellipsis">
          <a>{node.label}</a>
        </span>
      )
    },

    /**
     * @description 线上单元下单元模板 设置勾选框
     */
    selectedDataOnline() {
      let selectedDataOnline_copy_arr = []
      if (this.dialogTitle === '选择推广单元' && this.subTitleInedx === 1) {
        for (const key in this.selectedDataOnline_copy) {
          if (Object.hasOwnProperty.call(this.selectedDataOnline_copy, key)) {
            const element = this.selectedDataOnline_copy[key]
            selectedDataOnline_copy_arr.push(element)
          }
        }
        this.selectedDataOnlineList = selectedDataOnline_copy_arr.flat()
        // 获取线上单元下模板单元数据
        let data = this.selectedData.local.concat(selectedDataOnline_copy_arr.flat())

        this.selectedData = {
          local: data
        }
        this.$emit('getSelectedData', this.selectedData)
      }
    },

    /**
     * @description: (选中或取消选中)tree里数据, 分别添加到 (模板和线上) 已选里
     * @param {data} 选中/取消选中的数据
     * @param {flag} 选中/取消选
     */
    checkChange(data, flag) {
      // 线上单元 下单 元数据单独控制
      const { id, label } = data
      let data1 = this.selectedData
      if (flag) {
        data1.push({
          ...data,
          _id: this.leftQuerySelect._id,
          _name: this.leftQuerySelect._name,
          id,
          label
        })
      } else {
        data1 = data1.filter((item) => item.id !== id)
      }

      this.selectedData = data1
      this.$emit('getSelectedData', this.selectedData)
    },

    /**
     * @description 获取本地数据
     */
    async setLeftQuerySelect(item) {
      this.treeLoading = true
      this.leftQuerySelect = item
      await this.getDataByLeftQuery(item, 1) // 获取模板数据, 该方法是调用父组件方法
      this.treeLoading = false
      this.setCheckedKeys(this.selectedData)
    },

    async currentChange(current) {
      this.treeLoading = true
      await this.getDataByLeftQuery(this.leftQuerySelect, current)
      this.treeLoading = false
      this.setCheckedKeys(this.selectedData)
    },

    /**
     * @description 删除选中的数据
     */
    deleteItem(id) {
      let data = this.selectedData
      this.selectedData = data.filter((f) => f.id !== id)
      this.setCheckedKeys(this.selectedData)
    },

    /**
     * @description 删除选中的 线上数据中模板数据(x2时都删除)
     */
    deleteItem2(id, campaignFeedName) {
      for (const key in this.selectedDataOnline_copy) {
        if (Object.hasOwnProperty.call(this.selectedDataOnline_copy, key)) {
          const element = this.selectedDataOnline_copy[key]
          this.selectedDataOnline_copy[key] = element.filter((item) => item.id !== id || item.campaignFeedName !== campaignFeedName)
        }
      }
      // 设置 展示 和 勾选
      this.selectedDataOnline()
      this.setCheckedKeys(this.selectedDataOnline_copy[this.onlineQuerySelect.id])
    },

    /**
     * @description 设置选中的项,让复选框打钩
     */
    setCheckedKeys(data) {
      this.$refs.tree.setCheckedKeys(data.map((item) => item.id))
    },

    /**
     * @description 重置数据
     */
    resetData() {
      this.filterText = ''
      // 选中的数据
      this.selectedData = {
        local: [],
        online: []
      }
      // this.data = []
      // 线上左侧选中的数据
      this.onlineQuerySelect = {}
      this.leftQuerySelect = {}
      this.selectedDataOnline_copy = {}
      this.treeLoading = false
      // this.$refs.tree.setCheckedKeys([1000000000000])
    }
  }
}
</script>

<style lang="scss">
.left-tree {
  .el-tree-node > .el-tree-node__content {
    .el-tree-node__label {
      font-weight: 600;
    }
  }
  .el-tree-node__children {
    .el-tree-node__label {
      font-weight: 500 !important;
      margin-left: 10px;
    }
  }
}
.tree {
  .is-leaf {
    display: none;
  }
}
.selected-tree {
  .el-menu-vertical-demo {
    border-right: none !important;
    .el-submenu__title {
      height: 24px;
      line-height: 24px;
      font-size: 14px;
      background: antiquewhite;
    }
    .el-menu {
      li {
        height: 26px;
        line-height: 26px;
      }
    }
    .el-menu-item.is-active {
      color: #333;
    }
  }
}
</style>

<style lang="scss" scoped>
p {
  margin: 0;
  padding: 0;
}

.section {
  height: 500px;
}

.filter-tree-box {
  padding-left: 10px;
  height: 450px;
  overflow-x: auto;
}
.tree {
  height: 100%;
  padding-left: 10px !important;
  border: 1px solid #ccc;
  border-radius: 8px;
  // padding: 20px;
  display: flex;

  .left-ul {
    // padding-top: 10px;
    width: 1;
    border-right: 1px solid #ccc;
    p {
      font-size: 16px;
      padding: 10px;
      border-bottom: 1px solid #ccc;
    }
    ul {
      overflow-y: auto;
      height: 452px;
      li {
        padding: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        &.active {
          background: #409eff;
          color: #fff;
        }
      }
    }
  }
  &.el-col {
    padding: 0 !important;
  }
}

.tree-select {
  flex: 1;
  .tree-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px;
    border-bottom: 1px solid #ccc;
    p {
      font-size: 16px;
    }
    ul {
      display: flex;
      li {
        margin: 0 10px 0 5px;
        cursor: pointer;
        &.active {
          color: #409eff;
        }
        &.disable {
          color: #ccc;
        }
      }
    }
  }

  // .filter-tree {
  //   padding-left: 10px;
  // }
}
.selected-tree {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-left: 15px;
  height: 500px;
  overflow-y: auto;
  .selected-tree-li {
    display: flex;
    align-items: center;
    padding: 4px;
    &:hover {
      background: #f2f2f2;
      // color: #f2f2f2;
    }
    p {
      // width: 80%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex: 1;
    }
    .count {
      margin: 0 4px;
      padding: 0 3px;
      border: 1px solid #ccc;
      font-size: 10px;
      border-radius: 4px;
    }
    i {
      cursor: pointer;
    }
  }
}
</style>
