<!--
 * @Description: 组件选择区
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2022-04-20 10:42:18
-->

<template>
  <section>
    <div v-for="item in group" :key="item.title" class="widget-box">
      <p class="widget-title mb-10">{{ item.title }}</p>
      <ul class="widget-ul">
        <li v-for="widget in item.widgets" :key="widget.type" @click="setFinalWidgets(widget)">
          <i :class="widget.icon"></i>
          <p>{{ widget.name }}</p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { widgetGalleryGroup } from './widgetGalleryData'
import { widgetMapData } from './indexData'
export default {
  data() {
    return {
      group: widgetGalleryGroup
    }
  },
  methods: {
    /**
     * @description: 添加组件到操作区
     * @param {*} item 其中一个组件的数据
     */
    setFinalWidgets(item) {
      const { type } = item
      const widget = JSON.parse(JSON.stringify(widgetMapData[type]))
      this.$emit('setFinalWidgets', widget)
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}

.widget-box {
  padding: 10px;
  .widget-title {
    font-size: 18px;
    border-left: 3px solid #409eff;
    padding-left: 10px;
  }
  .widget-ul {
    display: flex;
    justify-content: flex-start;
    padding: 0;
    li {
      width: 30%;
      height: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #f2f2f2;
      margin: 0 10px;
      border-radius: 6px;
      cursor: pointer;
      i {
        font-size: 26px;
      }
      p {
        margin-top: 10px;
      }
      &:hover {
        background: #c9c9c9;
        color: #fff;
      }
    }
  }
}
</style>
