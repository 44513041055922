export const searchForm = {
  woodenFishId: null,
  pageId: null,
  pageName: null,
  siteId: null,
  siteName: null,
  housekeeperId: null,
  housekeeperName: null,
  baiduId: null,
  accountName: null,
  transStatus: null,
  deleted: null,
  createBy: null,
  createTime: null,
  updateBy: null,
  updateTime: null,
  remark: null
}

export class AssetManagementBaiduWoodenFishEnt {
  woodenFishId
  pageId
  pageName
  siteId
  siteName
  housekeeperId
  housekeeperName
  baiduId
  accountName
  transStatus
  deleted
  createBy
  createTime
  updateBy
  updateTime
  remark
}

export const formRules = {
  woodenFishId: [{ required: true, message: '请填写', trigger: 'change' }],
  pageId: [{ required: true, message: '请填写落地页ID', trigger: 'change' }],
  pageName: [{ required: true, message: '请填写落地页名称', trigger: 'change' }],
  siteId: [{ required: true, message: '请填写站点ID', trigger: 'change' }],
  siteName: [{ required: true, message: '请填写站点名称', trigger: 'change' }],
  housekeeperId: [{ required: true, message: '请填写本地id', trigger: 'change' }],
  housekeeperName: [{ required: true, message: '请填写账号管家', trigger: 'change' }],
  baiduId: [{ required: true, message: '请填写投放账户id', trigger: 'change' }],
  accountName: [{ required: true, message: '请填写账户名称', trigger: 'change' }],
  transStatus: [{ required: true, message: '请填写激活状态', trigger: 'change' }],
  deleted: [{ required: true, message: '请填写逻辑删除', trigger: 'change' }],
  createBy: [{ required: true, message: '请填写创建者', trigger: 'change' }],
  createTime: [{ required: true, message: '请填写创建时间', trigger: 'change' }],
  updateBy: [{ required: true, message: '请填写更新者', trigger: 'change' }],
  updateTime: [{ required: true, message: '请填写更新时间', trigger: 'change' }],
  remark: [{ required: true, message: '请填写备注', trigger: 'change' }]
}
export const transType = [
  { 'label': ' 咨询按钮点击', 'id': '1' },
  { 'label': ' 电话按钮点击', 'id': '2' },
  { 'label': ' 表单提交成功', 'id': '3' },
  { 'label': ' 激活', 'id': '4' },
  { 'label': ' 表单按钮点击', 'id': '5' },
  { 'label': ' 下载（预约）按钮点击（小流量）', 'id': '6' },
  { 'label': ' 购买成功', 'id': '10' },
  { 'label': ' 订单提交成功', 'id': '14' },
  { 'label': ' 三句话咨询', 'id': '17' },
  { 'label': ' 留线索', 'id': '18' },
  { 'label': ' 一句话咨询', 'id': '19' },
  { 'label': ' 深度页面访问', 'id': '20' },
  { 'label': ' 注册（小流量）', 'id': '25' },
  { 'label': '  付费（小流量）', 'id': '26' },
  { 'label': ' 电话拨通', 'id': '30' },
  { 'label': ' 微信复制按钮点击（小流量）', 'id': '35' },
  { 'label': ' 申请（小流量）', 'id': '41' },
  { 'label': ' 授信（小流量）', 'id': '42' },
  { 'label': ' 商品下单成功', 'id': '45' },
  { 'label': ' 加入购物车', 'id': '46' },
  { 'label': ' 商品收藏', 'id': '47' },
  { 'label': ' 商品详情页到达', 'id': '48' },
  { 'label': ' 店铺调起', 'id': '57' },
  { 'label': ' 微信调起按钮点击', 'id': '67' },
  { 'label': ' 粉丝关注成功', 'id': '68' },
  { 'label': '  应用调起', 'id': '71' },
  { 'label': ' 微信加粉成功（小流量）', 'id': '79' },
  { 'label': ' 直播间成单（小流量）', 'id': '80' },
  { 'label': '  直播间观看（小流量）', 'id': '82' },
  { 'label': ' 直播间商品按钮点击（小流量）', 'id': '83' },
  { 'label': ' 直播间停留（小流量）', 'id': '84' },
  { 'label': ' 直播间评论（小流量）', 'id': '85' },
  { 'label': ' 直播间打赏（小流量）', 'id': '86' },
  { 'label': ' 直播间购物袋点击（小流量）', 'id': '87' }
]
