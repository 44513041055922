<!--
 * @Description: 计划/ 广告 展示 tree
 * @Date: 2021-12-06 15:57:16
 * @LastEditTime: 2022-03-22 17:00:43
-->
<template>
  <div class="source">
    <!-- 添加计划 -->
    <SelectModal
      ref="modal"
      type="table"
      dialog-title="人群包"
      left-title="投放账户"
      :title-info="{
        title: '选择推广计划',
        leftLevel: '1'
      }"
      :visible="visible"
      :left-query-list="accountAdv.map((item) => ({ _name: item.name, _id: item.taskAccountId }))"
      :table-data-source="{ dataSource, dataSource2 }"
      :select-page-list="[selectPageList, selectPageList2]"
      @onOk="getSelectSourceData"
    />
  </div>
</template>

<script>
import { getAudienceList, updateSaveAudience } from '@/network/api/advManagement/advManagementWatch'
import SelectModal from '@/components/selectModal/selectModal.vue'

export default {
  inject: ['crowdClose'],
  provide: function() {
    return {
      getDataByLeftQuery: this.getSourceFun,
      queryFun1: this.sourceSync,
      queryText1: '同步',
      close: this.crowdClose,
      setSelectPageList: this.setSelectPageList,
      deleteSelectData: this.deleteSelectSourceData
    }
  },

  components: {
    SelectModal
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    accountAdv: {
      type: Array,
      default: () => []
    },
    taskId: {
      type: Number,
      default: 0
    },
    formData: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      selectPageList: [],
      selectPageList2: [],
      crowdType: 0,
      // 当前选中的id
      taskAccountId: 0,
      // 表格数据
      dataSource: {
        data: [],
        cols: [
          {
            label: '人群包名称',
            prop: 'name'
          },
          {
            label: '人群包ID',
            prop: 'crowdId'
          },
          {
            label: '人群来源',
            prop: 'source',
            render: (recode) => {
              if (recode.source === 'ADVERTISER_OWN_DATA') {
                return <el-tooltip effect='dark' content='能在投放端竞价投放中使用' placement='top-start'> <el-tag type='success'>一方人群</el-tag> </el-tooltip>
              }
              if (recode.source === 'TENCENT_DATA') {
                return <el-tooltip effect='dark' content='不能在投放端竞价投放中使用' placement='top-start'> <el-tag type='danger'>二方人群</el-tag> </el-tooltip>
              }
              return <el-tooltip effect='dark' content='不能在投放端竞价投放中使用' placement='top-start'> <el-tag type='danger'>未知类型</el-tag> </el-tooltip>
            }
          },
          {
            label: '人群状态',
            prop: 'status',
            render: (recode) => {
              if (recode.status === 'SUCCESS') {
                return <el-tooltip effect='dark' content='成功可用' placement='top-start'> <el-tag type='success'>成功可用</el-tag> </el-tooltip>
              } else if (recode.status === 'ACTIVE') {
                return <el-tooltip effect='dark' content='成功可用' placement='top-start'> <el-tag type='success'>成功可用</el-tag> </el-tooltip>
              } else if (recode.status === 'PENDING') {
                return <el-tooltip effect='dark' content='待处理' placement='top-start'> <el-tag type='danger'>待处理</el-tag> </el-tooltip>
              } else if (recode.status === 'PROCESSING') {
                return <el-tooltip effect='dark' content='处理中' placement='top-start'> <el-tag type='danger'>处理中</el-tag> </el-tooltip>
              } else if (recode.status === 'ERROR') {
                return <el-tooltip effect='dark' content='错误' placement='top-start'> <el-tag type='danger'>错误</el-tag> </el-tooltip>
              } else if (recode.status === 'FROZEN') {
                return <el-tooltip effect='dark' content='冻结' placement='top-start'> <el-tag type='danger'>冻结</el-tag> </el-tooltip>
              } else if (recode.status === 'THAWING') {
                return <el-tooltip effect='dark' content='解冻中' placement='top-start'> <el-tag type='danger'>解冻中</el-tag> </el-tooltip>
              } else if (recode.status === 'LOCKING') {
                return <el-tooltip effect='dark' content='锁定' placement='top-start'> <el-tag type='danger'>锁定</el-tag> </el-tooltip>
              }
            }
          }
        ], // 表格的列数据
        isIndex: false, // 列表序号
        loading: true, // loading
        isOperation: false,
        isSelection: true,
        isBorder: true,
        selectable: (row) => {
          if (row.source === 'TENCENT_DATA' || row.source === 'UNKNOWN') {
            return false
          }
          if (row.status !== 'SUCCESS' && row.status !== 'ACTIVE') {
            return false
          }
          if (this.selectPageList2 && this.selectPageList2.length) {
            return !this.selectPageList2.some((s) => s.id === row.id)
          } else {
            return true
          }
        },
        selectAll: (selection) => {
          if (selection.length > 0) {
            let selectionList = []
            selectionList.push(...selection)
            let list = []
            selectionList.map((item) => {
              if (list.length === 0 || !list.some((s) => s.id === item.id)) {
                list.push(item)
              }
            })

            let arr = [...this.selectPageList, ...list]

            let newArr = []
            arr.forEach((item) => {
              if (newArr.length === 0 || !newArr.some((s) => s.id === item.id)) {
                newArr.push(item)
              }
            })
            this.selectPageList = newArr
          } else {
            // 当点击全部取消
            this.selectPageList = this.selectPageList.filter((f) => f.taskAccountId !== this.taskAccountId)
          }
        },
        select: (selection, row) => {
          let selectionList = []
          selectionList.push(...selection)
          let list = []
          selectionList.map((item) => {
            if (list.length === 0 || !list.some((s) => s.id === item.id)) {
              list.push(item)
            }
          })

          let arr = [...this.selectPageList, ...list]

          let newArr = []
          arr.forEach((item) => {
            if (newArr.length === 0 || !newArr.some((s) => s.id === item.id)) {
              newArr.push(item)
            }
          })

          if (!selection.some((s) => s.id === row.id)) {
            newArr = newArr.filter((f) => f.id !== row.id)
          }

          this.selectPageList = newArr
        },
        pageData: {
          total: 0, // 总条数
          pageSize: 10,
          layout: 'total, prev, pager, next'
        }
      },
      // 表格数据
      dataSource2: {
        data: [],
        cols: [
          {
            label: '人群包名称',
            prop: 'name'
          },
          {
            label: '人群包ID',
            prop: 'crowdId'
          },
          {
            label: '人群来源',
            prop: 'source',
            render: (recode) => {
              if (recode.source === 'ADVERTISER_OWN_DATA') {
                return <el-tooltip effect='dark' content='能在投放端竞价投放中使用' placement='top-start'> <el-tag type='success'>一方人群</el-tag> </el-tooltip>
              }
              if (recode.source === 'TENCENT_DATA') {
                return <el-tooltip effect='dark' content='不能在投放端竞价投放中使用' placement='top-start'> <el-tag type='danger'>二方人群</el-tag> </el-tooltip>
              }
              return <el-tooltip effect='dark' content='不能在投放端竞价投放中使用' placement='top-start'> <el-tag type='danger'>未知类型</el-tag> </el-tooltip>
            }
          },
          {
            label: '人群状态',
            prop: 'status',
            render: (recode) => {
              if (recode.status === 'SUCCESS') {
                return <el-tooltip effect='dark' content='成功可用' placement='top-start'> <el-tag type='success'>成功可用</el-tag> </el-tooltip>
              } else if (recode.status === 'ACTIVE') {
                return <el-tooltip effect='dark' content='成功可用' placement='top-start'> <el-tag type='success'>成功可用</el-tag> </el-tooltip>
              } else if (recode.status === 'PENDING') {
                return <el-tooltip effect='dark' content='待处理' placement='top-start'> <el-tag type='danger'>待处理</el-tag> </el-tooltip>
              } else if (recode.status === 'PROCESSING') {
                return <el-tooltip effect='dark' content='处理中' placement='top-start'> <el-tag type='danger'>处理中</el-tag> </el-tooltip>
              } else if (recode.status === 'ERROR') {
                return <el-tooltip effect='dark' content='错误' placement='top-start'> <el-tag type='danger'>错误</el-tag> </el-tooltip>
              } else if (recode.status === 'FROZEN') {
                return <el-tooltip effect='dark' content='冻结' placement='top-start'> <el-tag type='danger'>冻结</el-tag> </el-tooltip>
              } else if (recode.status === 'THAWING') {
                return <el-tooltip effect='dark' content='解冻中' placement='top-start'> <el-tag type='danger'>解冻中</el-tag> </el-tooltip>
              } else if (recode.status === 'LOCKING') {
                return <el-tooltip effect='dark' content='锁定' placement='top-start'> <el-tag type='danger'>锁定</el-tag> </el-tooltip>
              }
            }
          }
        ], // 表格的列数据
        isIndex: false, // 列表序号
        loading: true, // loading
        isOperation: false,
        isSelection: true,
        isBorder: true,
        selectable: (row) => {
          if (row.source === 'TENCENT_DATA' || row.source === 'UNKNOWN') {
            return false
          }
          if (row.status !== 'SUCCESS' && row.status !== 'ACTIVE') {
            return false
          }
          if (this.selectPageList && this.selectPageList.length) {
            return !this.selectPageList.some((s) => s.id === row.id)
          } else {
            return true
          }
        },
        selectAll: (selection) => {
          if (selection.length > 0) {
            let selectionList = []
            selectionList.push(...selection)
            let list = []
            selectionList.map((item) => {
              if (list.length === 0 || !list.some((s) => s.id === item.id)) {
                list.push(item)
              }
            })

            let arr = [...this.selectPageList2, ...list]

            let newArr = []
            arr.forEach((item) => {
              if (newArr.length === 0 || !newArr.some((s) => s.id === item.id)) {
                newArr.push(item)
              }
            })
            this.selectPageList2 = newArr
          } else {
            // 当点击全部取消
            this.selectPageList2 = this.selectPageList2.filter((f) => f.taskAccountId !== this.taskAccountId)
          }
        },
        select: (selection, row) => {
          let selectionList = []
          selectionList.push(...selection)
          let list = []
          selectionList.map((item) => {
            if (list.length === 0 || !list.some((s) => s.id === item.id)) {
              list.push(item)
            }
          })

          let arr = [...this.selectPageList2, ...list]

          let newArr = []
          arr.forEach((item) => {
            if (newArr.length === 0 || !newArr.some((s) => s.id === item.id)) {
              newArr.push(item)
            }
          })

          if (!selection.some((s) => s.id === row.id)) {
            newArr = newArr.filter((f) => f.id !== row.id)
          }

          this.selectPageList2 = newArr
        },
        pageData: {
          total: 0, // 总条数
          pageSize: 10,
          layout: 'total, prev, pager, next'
        }
      }
    }
  },

  watch: {
    formData: {
      handler(value) {
        this.selectPageList = value.list[0]
        this.selectPageList2 = value.list[1]
      },
      deep: true,
      immediate: true
    },
    visible: {
      handler(value) {
        if (value) {
          this.selectPageList = this.formData.list[0]
          this.selectPageList2 = this.formData.list[1]
        }
      }
    }
  },

  methods: {
    /**
     * @description: 同步人群包
     */
    async sourceSync(_id) {
      if (!_id) return this.$message.error('请先选择账户')
      this.dataSource.loading = true
      setTimeout(() => {
        this.dataSource.loading = false
      }, 200)
      this.$message.success('刷新成功!')
    },

    /**
     * @desc 获取当前选中账户下的人群包
     */
    async getSourceFun({ item, pageNum = 1, pageSize = 10, crowdType }) {
      this.crowdType = crowdType
      this.taskAccountId = item._id
      this.dataSource.loading = true
      const { code, data } = await getAudienceList({ taskAccountId: item._id, pageSize, page: pageNum })
      if (code === 200) {
        data.listing.items.forEach((ele) => {
          ele.key = `${ele.id}+${item._id}`
          ele.crowdId = ele.id
          ele.id = `${ele.id}+${item._id}`
          ele.taskAccountId = item._id
          ele.accountName = item._name
          ele.show_name = `${ele.name}_(${item._name})`
        })

        if (crowdType === 0) {
          this.dataSource.pageData.total = data.listing.total
          this.dataSource.loading = false
          this.dataSource.data = data.listing.items
        } else {
          this.dataSource2.pageData.total = data.listing.total
          this.dataSource2.loading = false
          this.dataSource2.data = data.listing.items
        }
      }
    },

    /**
     * @desc 删除数据后提交
     */
    deleteSelectSourceData(id) {
      this.$message.success(' 删除成功')
    },

    /**
     * @desc 获取选中的数据后提交
     */
    getSelectSourceData() {
      if (!this.selectPageList.length && !this.selectPageList2.length) {
        return this.$message.error('请选择人群包')
      }
      let params = {
        saveAudienceItem: []
      }
      this.accountAdv.map((item) => {
        let inDmpAudience = this.selectPageList.filter((f) => f.taskAccountId === item.taskAccountId).map((item) => item.crowdId)
        let notInDmpAudience = this.selectPageList2.filter((f) => f.taskAccountId === item.taskAccountId).map((item) => item.crowdId)
        if ([...inDmpAudience, ...notInDmpAudience].length) {
          params.saveAudienceItem.push({
            taskAccountId: item.taskAccountId,
            inDmpAudience: JSON.stringify(inDmpAudience),
            notInDmpAudience: JSON.stringify(notInDmpAudience),
            inDmpAudienceInfo: JSON.stringify(this.selectPageList.filter((f) => f.taskAccountId === item.taskAccountId)),
            notInDmpAudienceInfo: JSON.stringify(this.selectPageList2.filter((f) => f.taskAccountId === item.taskAccountId))
          })
        }
      })
      if (this.accountAdv.length === params.saveAudienceItem.length) {
        updateSaveAudience(params).then((res) => {
          if (res.code === 200) {
            this.$emit('byTaskIdGetAllFun')
            this.$message.success('人群包添加成功')
            this.crowdClose()
          }
        })
      } else {
        return this.$message.error(`有${this.accountAdv.length - params.saveAudienceItem.length}个账户未选择人群包!`)
      }
      this.selectPageList = []
    },

    setSelectPageList(data) {
      this.selectPageList = data[0]
      this.selectPageList2 = data[1]
    }
  }
}
</script>

<style lang="scss">
.handleTree-icon :hover {
  color: #409eff;
}

.source {
  .custom-tree-node {
    // flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    width: 100px !important;
    .label {
      width: 300px !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
</style>
