<template>
  <div class="app-container">
    <!-- <el-tabs v-model="activeTab" type="card">
      <el-tab-pane label="定向模板" name="template"></el-tab-pane>
    </el-tabs> -->
    <!--      定向模板-->
    <direction-temp />
  </div>
</template>

<script>
import directionTemp from './directionTemp/directionTemp'
export default {
  name: 'Index',
  components: {
    'direction-temp': directionTemp
  },
  filters: {},
  props: {},
  data() {
    return {
      activeTab: 'template'
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>

<style scoped lang="scss"></style>
