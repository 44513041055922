<!--
 * @Description: Do not edit
 * @Date: 2021-12-24 14:49:52
 * @LastEditTime: 2022-02-16 11:54:06
-->
<template>
  <el-form ref="searchForm" class="search-form search-form-4" :model="searchForm">
    <el-row :gutter="20">
      <el-col :span="6">
        <el-form-item prop="templatePlanName" label="模板计划名称">
          <el-input v-model="searchForm.templatePlanName" placeholder="请输入模板计划名称" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="launchPlatform" label="投放平台">
          <el-input v-model="searchForm.launchPlatform" placeholder="请输入投放平台" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="publishStatus" label="发布状态">
          <el-input v-model="searchForm.publishStatus" placeholder="请输入发布状态" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="createBy" label="创建者">
          <el-input v-model="searchForm.createBy" placeholder="请输入创建者" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="createTime" label="创建时间">
          <el-date-picker v-model="searchForm.createTime" type="date" value-format="yyyy-MM-dd" placeholder="选择创建日期" />
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-button class="btn" type="primary" @click="search">
          查询
        </el-button>
        <el-button class="btn" @click="resetSearch">
          重置
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { searchForm } from './object'
export default {
  inject: ['loadData'],
  data() {
    return {
      searchForm: { ...searchForm }
    }
  },
  methods: {
    search() {
      this.loadData(true)
    },
    // 折中方案
    getSearchForm() {
      return { ...this.searchForm }
    },
    resetSearch() {
      this.$refs.searchForm.resetFields()
    }
  }
}
</script>
