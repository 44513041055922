var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.isEdit ? "编 辑" : "新 增",
        width: "1040px",
        visible: _vm.visible,
        "before-close": _vm.close,
        "label-position": "right",
        "modal-append-to-body": false,
        "close-on-press-escape": false,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ent",
          attrs: { model: _vm.ent, rules: _vm.rules, "label-width": "110px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "项目名称", prop: "projectName" } },
            [
              _c("el-input", {
                attrs: {
                  disabled: _vm.isEdit,
                  maxlength: "30",
                  placeholder: "最大输入长度30 超出截断",
                },
                model: {
                  value: _vm.ent.projectName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "projectName", $$v)
                  },
                  expression: "ent.projectName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "可用状态", prop: "status" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ent.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.ent, "status", $$v)
                    },
                    expression: "ent.status",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "可用" } }),
                  _c("el-radio-button", { attrs: { label: "不可用" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "项目负责人", prop: "leaderUserId" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    "default-first-option": "",
                    placeholder: "请选择",
                  },
                  model: {
                    value: _vm.ent.leaderUserIdWeb,
                    callback: function ($$v) {
                      _vm.$set(_vm.ent, "leaderUserIdWeb", $$v)
                    },
                    expression: "ent.leaderUserIdWeb",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { label: item.label, value: item.key },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                attrs: {
                  maxlength: "100",
                  type: "textarea",
                  placeholder: "最大输入长度100 超出截断",
                },
                model: {
                  value: _vm.ent.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "remark", $$v)
                  },
                  expression: "ent.remark",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "可见状态", prop: "projectVisibleType" } },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.radioChange },
                  model: {
                    value: _vm.ent.projectVisibleType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ent, "projectVisibleType", $$v)
                    },
                    expression: "ent.projectVisibleType",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "全员可见" } }),
                  _c("el-radio-button", { attrs: { label: "指定人员" } }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.visibleTransfer
            ? _c("el-form-item", { attrs: { prop: "remark" } }, [
                _c(
                  "div",
                  { staticClass: "edit_dev" },
                  [
                    _c("el-transfer", {
                      attrs: {
                        titles: ["待选择", "已选择"],
                        filterable: "",
                        "filter-placeholder": "请输入",
                        data: _vm.options,
                      },
                      model: {
                        value: _vm.ent.visibleUserIdListWeb,
                        callback: function ($$v) {
                          _vm.$set(_vm.ent, "visibleUserIdListWeb", $$v)
                        },
                        expression: "ent.visibleUserIdListWeb",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { attrs: { round: "" }, on: { click: _vm.close } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { slot: "reference", type: "primary", round: "" },
              on: { click: _vm.submit },
              slot: "reference",
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }