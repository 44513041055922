<!--
 * @Description: 橙子建站
 * @Date: 2021-11-10 19:20:56
 * @LastEditTime: 2022-02-18 14:43:58
-->
<template>
  <div>
    <Search
      ref="search"
      :account-huge-housekeeper-e-n-ts="accountHugeHousekeeperENTs"
      :account-huge-adv-e-n-t-lists="accountHugeAdvENTLists"
      :account-huge-housekeeper-e-n-ts-id="accountHugeHousekeeperENTsId"
    />
    <el-row
      type="flex"
      justify="space-between"
      class="operate-box mt-20"
    >
      <el-button
        type="primary"
        icon="el-icon-orange"
        round
        @click="showSync"
      >同步账户
      </el-button>
      <el-dialog
        title="同步账户"
        :visible.sync="isSync"
        width="500px"
        :modal-append-to-body="false"
      >
        <span>
          <el-form
            :model="account"
            label-width="100px"
          >
            <el-form-item
              label="管家账号"
              prop="accountId"
            >
              <el-select
                v-model="account.accountId"
                style="width:300px"
                @change="AdvENTListchange"
              >
                <el-option
                  v-for="(item, index) in accountHugeHousekeeperENTs"
                  :key="index"
                  :label="item.accountName"
                  :value="item.accountId"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              label="广告主账号"
              prop="GGZaccountId"
            >
              <el-select
                v-model="account.GGZaccountId"
                filterable
                style="width:300px"
              >
                <el-option
                  v-for="(item, index) in accountHugeAdvENTLists[accountHugeHousekeeperENTsId]"
                  :key="index"
                  :label="item.accountName"
                  :value="item.accountId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </span>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button @click="isSync = false">取 消</el-button>
          <el-button
            type="primary"
            @click="dialogVisible"
          >确认同步</el-button>
        </span>
      </el-dialog>
    </el-row>
    <div class="table-container">
      <el-table
        v-loading="loading"
        stripe
        class="list-table"
        :data="data"
      >
        <c-data-empty slot="empty"></c-data-empty>

        <el-table-column
          type="index"
          label="序号"
          align="left"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="siteId"
          label="ID"
          align="left"
        />
        <el-table-column
          prop="name"
          label="名称"
          align="left"
        />
        <el-table-column
          prop="status"
          label="状态"
          align="left"
        >
          <template slot-scope="{row}">
            {{ statusMapping[row.status] }}
          </template>
        </el-table-column>
        <el-table-column
          prop="siteType"
          label="类型"
          align="left"
        >
          <template slot-scope="{row}">
            {{ siteTypeMapping[row.siteType] }}
          </template>
        </el-table-column>
        <el-table-column
          prop="thumbnail"
          label="缩略图"
          align="left"
        >
          <template slot-scope="{ row }">
            <i v-if="!row.thumbnail">-</i>
            <el-image
              v-else
              :src="row.thumbnail"
              style="height: 100px"
              :preview-src-list="[row.thumbnail]"
            />
          </template>
        </el-table-column>
        <el-table-column
          prop="relationHousekeeperName"
          label="管家账号"
          align="left"
        />
        <el-table-column
          prop="relationAdvAccountName"
          label="投放账户"
          align="left"
        />
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button
              type="text"
              size="small"
            >存为模板
            </el-button>
            <el-button
              type="text"
              size="small"
            >预览
            </el-button>
            <el-button
              type="text"
              size="small"
            >转增
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="deleteRow(row)"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <c-pagination
      :page-size.sync="pageInfo.pageSize"
      :page-no.sync="pageInfo.pageNum"
      :total="pageInfo.total"
      :get-data-fun="loadData"
    ></c-pagination>
    <OptionDialog ref="dialog" />
  </div>
</template>

<script>
import { PageCommonComponent } from '@/common/mixin'
import { Page, Delete, Sync } from '@/network/api/assetManagement/assetManagementHugeCreateOrangeWebsite'
import { getProjectAndProduct } from '@/network/api/assetManagement/assetManagementHugeThirdLandingPage'
import Search from './Search'
import OptionDialog from './OptionDialog'

export default {
  name: 'AssetManagementHugeCreateOrangeWebsite',
  components: { Search, OptionDialog },
  mixins: [PageCommonComponent],
  provide: function () {
    return {
      loadData: this.loadData,
      showEdit: this.showEdit
    }
  },
  data() {
    return {
      loading: false,
      data: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      projectAndProduct: [],
      account: {
        accountId: '',
        GGZaccountId: ''
      },
      accountHugeHousekeeperENTs: [],
      accountHugeHousekeeperENTsId: '',
      accountHugeAdvENTLists: {},
      isSync: false,
      statusMapping: {
        'enable': '已发布，已生效',
        'disable': '不可用',
        'deleted': '已删除',
        'edit': '未发布，未生效',
        'forbidden': '惩罚广告主，站点下线',
        'auditAccepted': '审核通过',
        'auditRejected': '审核拒绝',
        'auditBanned': '审核封禁',
        'auditDoing': '审核中'
      },
      siteTypeMapping: {
        'DEFAULT': '默认类型',
        'CREATIVEFORM': '附加创意表单',
        'CREATIVEFORMOLD': '附加创意表单',
        'SITE_REJECTED': '原生表单',
        'STORESITE': '门店站点',
        'FORM': '标准化落地页,普通表单',
        'SLIDE': 'h5翻页',
        'NOPUBLISHSITE': '不可发布站点（比如DPA）',
        'SITE_TRASH': '普通建站 使用应用下载模板',
        'PROGRAM': '程序化落地页(存在于 V2 版建站)',
        'SELF': '自助站点',
        'STREAMING': '流式站点',
        'POLL': '投票站点',
        'SUBCHAIN': '搜索子链',
        'MINIAPP': '小程序站点',
        'DPA': '动态商品站点',
        'NATIVE': '原生落地页',
        'AUTOGENNOVEL': '小说自动生成',
        'STOREORANGESITE': '门店橙子建站',
        'DEPOSITE': '托管落地页'

      }
    }
  },
  mounted() {
    this.loadData()
    this.getProjectAndProductFun()
  },
  methods: {
    loadData(isFirst = false) {
      const searchForm = this.$refs.search.getSearchForm()
      this.doLoadData(isFirst, searchForm)
    },
    doLoadData(firstPage = false, searchForm) {
      firstPage && (this.pageInfo.pageNum = 1)
      let req = { ...searchForm, ...this.pageInfo }
      this.loading = true
      Page(req).then((res) => {
        this.data = res.data.records
        this.pageInfo.total = res.data.total
        this.loading = false
      })
    },
    deleteRow(row) {
      this.$confirm('确定删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          Delete(row.id).then((res) => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.loadData()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showEdit(row) {
      this.$refs.dialog.show(true, { ...row })
    },
    showSync() {
      this.isSync = true
    },

    getProjectAndProductFun() {
      getProjectAndProduct().then(({ code, data }) => {
        if (code === 200) {
          // this.projectAndProduct = data
          this.accountHugeHousekeeperENTs = data.map((item) => item.accountHugeHousekeeperENT)
          let accountHugeAdvENTLists = {}
          data.map((item, index) => {
            let key = item.accountHugeHousekeeperENT.accountId
            if (index === 0) {
              this.accountHugeHousekeeperENTsId = key
            }
            accountHugeAdvENTLists[key] = item.accountHugeAdvENTList
          })
          this.accountHugeAdvENTLists = accountHugeAdvENTLists
        }
      })
    },
    dialogVisible() {
      Sync(this.account).then(({ code }) => {
        if (code === 200) {
          this.isSync = false
          this.account = {
            accountId: '',
            GGZaccountId: ''
          }
          this.$message.success('同步成功!')
        }
      })
    },
    AdvENTListchange(value) {
      this.accountHugeHousekeeperENTsId = value
      this.account.GGZaccountId = null
    }
  }
}
</script>

<style scoped></style>
