var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "creativity-material" },
      [
        _vm.creativityMaterialList.flag
          ? _c("div", [
              _vm.crtSizeLabel === "朋友圈"
                ? _c("div", { staticClass: "kong" }, [
                    _vm._v(" " + _vm._s("原生落地页顶部组件获取") + " "),
                  ])
                : _c(
                    "div",
                    _vm._l(_vm.creativityMaterialList.list, function (item) {
                      return _c(
                        "div",
                        {
                          key: item.groupName,
                          staticClass: "justify-sb align-center",
                        },
                        [
                          _c("div", { staticClass: "img-list" }, [
                            _c("p", { staticStyle: { "font-size": "12px" } }, [
                              _vm._v(_vm._s(item.groupName) + ":"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "img-box" },
                              _vm._l(
                                item.adMpManagementTaskImgVideoENTList,
                                function (image) {
                                  return _c(
                                    "div",
                                    { key: image.creativeGroup },
                                    [
                                      image.localAssetUrl &&
                                      image.localAssetUrl.indexOf(".mp4") >
                                        -1 &&
                                      image.localAssetUrl
                                        ? _c("video", {
                                            attrs: { src: image.localAssetUrl },
                                          })
                                        : _c("img", {
                                            staticClass: "avatar",
                                            attrs: { src: image.localAssetUrl },
                                          }),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                          _c("i", {
                            staticClass: "el-icon-circle-close close-icon",
                            on: {
                              click: function ($event) {
                                return _vm.deleteMaterialList(
                                  item.adMpManagementTaskImgVideoENTList[0]
                                    .imgVideoId
                                )
                              },
                            },
                          }),
                        ]
                      )
                    }),
                    0
                  ),
            ])
          : _c("div", { staticClass: "kong" }, [
              _vm._v(" " + _vm._s("请选择创意素材") + " "),
            ]),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "创意素材",
              visible: _vm.visible,
              width: "800px",
              "modal-append-to-body": false,
              "destroy-on-close": "",
              "before-close": _vm.handleClose,
            },
          },
          [
            _c(
              "span",
              [
                _c(
                  "div",
                  { staticClass: "justify-sb align-center pt-10 pb-10" },
                  [
                    _c("div", [
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v("创意内容"),
                      ]),
                      _c("span", { staticClass: "ml-20" }, [
                        _vm._v(
                          "创意组数量：" +
                            _vm._s(_vm.creativityMaterialList.list.length) +
                            "组"
                        ),
                      ]),
                    ]),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.isBatchFun },
                      },
                      [_vm._v("批量添加")]
                    ),
                  ],
                  1
                ),
                _vm.creativityMaterialList.list.length
                  ? _c(
                      "el-collapse",
                      {
                        model: {
                          value: _vm.collapse,
                          callback: function ($$v) {
                            _vm.collapse = $$v
                          },
                          expression: "collapse",
                        },
                      },
                      _vm._l(
                        _vm.creativityMaterialList.list,
                        function (item, index) {
                          return _c(
                            "el-collapse-item",
                            {
                              key: item.groupName,
                              attrs: { name: item.groupName },
                            },
                            [
                              _c("template", { slot: "title" }, [
                                _c(
                                  "div",
                                  { staticClass: "justify-sb group-title" },
                                  [
                                    _c("p", [_vm._v(_vm._s(item.groupName))]),
                                    _c("div", { staticClass: "icon" }, [
                                      _c("i", {
                                        staticClass: "el-icon-document-copy",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.copyCreativeGroup(index)
                                          },
                                        },
                                      }),
                                      _vm.creativityMaterialList.list.length > 1
                                        ? _c("i", {
                                            staticClass: "el-icon-close",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.deleteCreativeGroup(
                                                  index
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ]),
                                  ]
                                ),
                              ]),
                              [
                                "展示Banner图片",
                                "优雅横版大图",
                                "公众号文章底部图片",
                                "公众号文章中部",
                                "小程序banner广告",
                              ].includes(_vm.crtSizeLabel)
                                ? _c("div", { staticClass: "img-box" }, [
                                    _c("p", [_vm._v("图片:")]),
                                    _c("div", { staticClass: "mr-10" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "avatar-uploader",
                                          on: {
                                            click: function ($event) {
                                              return _vm.setMaterialBox(index)
                                            },
                                          },
                                        },
                                        [
                                          item
                                            .adMpManagementTaskImgVideoENTList[0]
                                            .localAssetUrl
                                            ? _c("img", {
                                                staticClass: "avatar",
                                                attrs: {
                                                  src: item
                                                    .adMpManagementTaskImgVideoENTList[0]
                                                    .localAssetUrl,
                                                },
                                              })
                                            : _c("i", {
                                                staticClass:
                                                  "el-icon-plus avatar-uploader-icon",
                                              }),
                                        ]
                                      ),
                                    ]),
                                    _c("div", { staticClass: "info" }, [
                                      _c("p", [
                                        _vm._v(
                                          "尺寸：" +
                                            _vm._s(
                                              _vm.creativityMaterialInfoData
                                                .width
                                            ) +
                                            "px * " +
                                            _vm._s(
                                              _vm.creativityMaterialInfoData
                                                .height
                                            ) +
                                            "px"
                                        ),
                                      ]),
                                      _c("p", [
                                        _vm._v("格式：JPG、JPEG、PNG格式"),
                                      ]),
                                      _c("p", [
                                        _vm._v(
                                          "大小：" +
                                            _vm._s(
                                              _vm.creativityMaterialInfoData
                                                .size / 1024
                                            ) +
                                            " kb 以内"
                                        ),
                                      ]),
                                    ]),
                                  ])
                                : _c("div", { staticClass: "justify-sb" }, [
                                    _c("div", { staticClass: "img-box" }, [
                                      _c("p", [_vm._v("视频:")]),
                                      _c("div", { staticClass: "mr-10" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "avatar-uploader",
                                            on: {
                                              click: function ($event) {
                                                return _vm.setMaterialVideoBox(
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            item
                                              .adMpManagementTaskImgVideoENTList[0]
                                              .localAssetUrl
                                              ? _c("video", {
                                                  staticClass: "avatar",
                                                  attrs: {
                                                    src: item
                                                      .adMpManagementTaskImgVideoENTList[0]
                                                      .localAssetUrl,
                                                  },
                                                })
                                              : _c("i", {
                                                  staticClass:
                                                    "el-icon-plus avatar-uploader-icon",
                                                }),
                                          ]
                                        ),
                                      ]),
                                      _c("div", { staticClass: "info" }, [
                                        _c("p", [
                                          _vm._v(
                                            "尺寸：" +
                                              _vm._s(
                                                _vm.creativityMaterialInfoData
                                                  .width
                                              ) +
                                              "px * " +
                                              _vm._s(
                                                _vm.creativityMaterialInfoData
                                                  .height
                                              ) +
                                              "px"
                                          ),
                                        ]),
                                        _c("p", [_vm._v("格式：mp4格式")]),
                                        _c("p", [_vm._v("大小：100MB 以内")]),
                                        _c("p", [_vm._v("播放时长: 6s - 30s")]),
                                      ]),
                                    ]),
                                  ]),
                            ],
                            2
                          )
                        }
                      ),
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "text-align": "center",
                      "margin-top": "10px",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: () => {
                            _vm.addCreativeGroup()
                          },
                        },
                      },
                      [_vm._v("添加创意组")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c("el-button", { on: { click: _vm.handleClose } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", disabled: !_vm.isSearchLock },
                    on: { click: _vm.onSubmit },
                  },
                  [_vm._v("确 定")]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _vm.visible
      ? _c(
          "div",
          [
            _c("material-box", {
              attrs: {
                visible: _vm.materialBoxVisible,
                "img-count": 1,
                name: "creativeGroup",
                "select-all": "",
                "image-list": [],
                "is-batch": _vm.isBatch,
                "select-type": "multiple",
                attribute: {
                  key: "creativeGroup",
                  value: "创意素材",
                  count: 1,
                  size: _vm.creativityMaterialInfoData.size,
                  listType: "image/jpg,image/jpeg,image/png",
                  width: _vm.creativityMaterialInfoData.width,
                  height: _vm.creativityMaterialInfoData.height,
                },
                "project-id": [+_vm.projectId],
              },
              on: {
                setMaterialBox: function ($event) {
                  _vm.materialBoxVisible = false
                },
                submit: _vm.selectImg,
              },
            }),
            _c("MaterialVideoBox", {
              attrs: {
                visible: _vm.materialVideoVisible,
                "img-count": 1,
                name: "videoUrl",
                "select-all": "",
                "is-batch": _vm.isBatch,
                "select-type": "multiple",
                "video-listed": [],
                attribute: {
                  key: "videoHorizontal",
                  value: "横版视频",
                  count: 1,
                  size: _vm.creativityMaterialInfoData.size,
                  listType: "video/mp4",
                  width: _vm.creativityMaterialInfoData.width,
                  height: _vm.creativityMaterialInfoData.height,
                },
                "project-id": [+_vm.projectId],
              },
              on: {
                setMaterialBox: function ($event) {
                  _vm.materialVideoVisible = false
                },
                handleVideoSuccess: _vm.selectVideo,
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }