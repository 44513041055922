/*
 * @Description: Do not edit
 * @Date: 2021-10-18 11:53:23
 * @LastEditTime: 2021-12-30 15:37:33
 */
export let widgetGalleryGroup = [
  {
    title: '顶部组件',
    widgets: [
      {
        name: '图片',
        type: 'topimg',
        icon: 'el-icon-picture-outline'
      },
      {
        name: '轮播图',
        type: 'topslider',
        icon: 'el-icon-money'
      },
      {
        name: '视频',
        type: 'topvideo',
        icon: 'el-icon-video-camera'
      }
    ]
  },
  {
    title: '基础组件',
    widgets: [
      {
        name: '图片',
        type: 'img',
        icon: 'el-icon-picture-outline'
      },
      {
        name: '轮播图',
        type: 'slider',
        icon: 'el-icon-money'
      },
      {
        name: '文本',
        type: 'text',
        icon: 'el-icon-tickets'
      },
      {
        name: '视频',
        type: 'video',
        icon: 'el-icon-video-camera'
      }
    ]
  },
  {
    title: '转化按钮',
    widgets: [
      {
        name: '关注公众号',
        type: 'copyGh',
        icon: 'el-icon-document-copy'
      },
      {
        name: '添加商家微信',
        type: 'enterprise_wx_auto',
        icon: 'el-icon-connection'
      }
    ]
  },
  {
    title: '营销组件',
    widgets: [
      {
        name: '图文复合组件',
        type: 'shelfnew',
        icon: 'el-icon-copy-document'
      },
      {
        name: '悬浮组件',
        type: 'floatbutton',
        icon: 'el-icon-coin'
      }
    ]
  }
]
