<template>
  <div class="details-box">
    <div class="flex-between" style="margin-bottom: 20px">
      <el-button type="primary" :disabled="!list.length" @click="handleSyncStatus">同步状态</el-button>
      <div class="flex-end">执行时间 <el-date-picker v-model="execDate" style="width: 300px;margin-left: 15px;"
          type="daterange" :clearable="false" prange-separator="至" start-placeholder="开始时间" value-format="yyyy-MM-dd"
          end-placeholder="结束时间" @change="handleChange">
        </el-date-picker>
      </div>
    </div>
    <div v-loading="loading">
      <el-collapse v-if="Object.keys(list).length > 0" v-model="activeCollapse">
        <el-collapse-item v-for="(item, index) in list" :name="'任务' + index" :key="index">
          <template slot="title">
            <div class="detail-collapse-header">
              <span>{{ item.date }}</span>
              <span>{{ item.status }}</span>
            </div>
          </template>
          <el-descriptions title="" :column="4" class="padding-cls">
            <el-descriptions-item label="任务名称">{{ taskInfo.taskName }}</el-descriptions-item>
            <el-descriptions-item label="DMP账户">{{ taskInfo.accountId }}</el-descriptions-item>
            <el-descriptions-item label="文件类型">{{ taskInfo.dataType | filterDataType }}</el-descriptions-item>
            <el-descriptions-item label="数据条件">{{ taskInfo.dataConditionStr || '-' }}</el-descriptions-item>
            <el-descriptions-item label="数据时间">{{ taskInfo.lifeCycle }}</el-descriptions-item>
          </el-descriptions>
          <div class="detail-list-box">
            <div class="flex-end detail-total">
              共 <span>{{ item.data.length }}</span> 个，其中计算中 <span>{{ item.computeNum }}</span> 个，可用 <span>{{
                item.canUseNum }}</span> 个，其他 <span> {{ item.otherNum }}</span> 个
            </div>
            <el-table v-loading="item.loading" :data="item.data" class="list-table" height="200">
              <el-table-column type="index" label="序号"></el-table-column>
              <el-table-column prop="audienceId" label="ID"></el-table-column>
              <el-table-column prop="name" label="名称"></el-table-column>
              <el-table-column prop="peopleCovered" label="覆盖人数">
                <template slot-scope="{ row }">{{ row.peopleCovered || '-' }}</template>
              </el-table-column>
              <el-table-column prop="executeHms" label="创建时间">
                <template slot-scope="{ row }">{{ row.executeTime + ' ' + row.executeHms }}</template>
              </el-table-column>
              <el-table-column prop="updateTime" label="更新时间">
                <template slot-scope="{ row }">{{ row.updateTime || '-' }}</template>
              </el-table-column>
              <el-table-column prop="processStatus" label="状态">
                <template slot-scope="{ row }">
                  <el-tooltip v-if="row.processStatus === 'ERROR'" effect="dark" :content="row.message" placement="top">
                    <span :class="'status-' + row.processStatus">{{
                      row.processStatus | filterStatus
                    }}</span>
                  </el-tooltip>
                  <span v-else :class="'status-' + row.processStatus">{{
                    row.processStatus | filterStatus
                  }}</span>
                </template>
              </el-table-column>
            </el-table>
            <!-- <div class="page">
            <paginations :total="item.total" :page.sync="item.pageNum" :page-sizes="[5, 10, 15, 20]"
              :limit.sync="item.pageSize" @pagination="getPage" />
          </div> -->
          </div>
        </el-collapse-item>
      </el-collapse>
      <el-empty v-else description="暂无数据"></el-empty>
    </div>

    <div class="detail-footer">
      <el-button type="primary" @click="handleBack">返回</el-button>
    </div>
  </div>
</template>

<script>
import { dataConditions, computeDuring } from './util'
import { getCrowdPackDetail } from '@/network/api/assetManagement/assetManageTencent'

export default {
  filters: {
    filterExecutionCycle(value) {
      return +value === 1 ? '单次' : '循环'
    },
    filterLoopCycle(value) {
      const obj = { 1: '每日', 2: '每周', 3: '每月' }
      return '-' + obj[+value] || ''
    },
    filterDataType(value) {
      const obj = { 1: '微信openid', 2: '企微unionid' }
      return obj[+value] || '-'
    },
    filterStatus(value) {
      const status = {
        'PENDING': '计算中',
        'PROCESSING': '计算中',
        'SUCCESS': '可用',
        'ERROR': '错误'
      }
      return status[value] || '-'
    }
  },
  data() {
    return {
      dataConditions,
      loading: false,
      taskId: null,
      execDate: [], // 执行时间
      activeCollapse: ['任务0'], // 展开面板
      list: [],
      taskInfo: null
    }
  },

  created() {
    let { id } = this.$route.params
    this.taskId = id || null
    this.getDateTime()
  },

  mounted() {
    this.getPage()
  },

  methods: {
    getDateTime() {
      let timeRange = computeDuring(7)
      const startDate = this.parseTime(timeRange[0], '{y}-{m}-{d}')
      const endDate = this.parseTime(timeRange[1], '{y}-{m}-{d}')
      this.execDate = [startDate, endDate]
    },
    // 切换时间
    handleChange(value) {
      this.getPage()
    },

    // 获取列表
    getPage() {
      let params = {
        adAdqAudienceTaskId: this.taskId,
        startAndEndT: this.execDate.join(',')
      }
      this.loading = true
      getCrowdPackDetail(params).then(({ code, data }) => {
        if (code === 200) {
          let { adqAudienceTask, showDataList } = data
          let list = []
          for (let key in showDataList) {
            let computeArr = showDataList[key].filter(v => v.processStatus === 'PENDING' || v.processStatus === 'PROCESSING')
            let canUseArr = showDataList[key].filter(v => v.processStatus === 'SUCCESS')
            let otherArr = showDataList[key].filter(v => v.processStatus === 'ERROR')
            list.push({
              loading: false,
              date: key,
              computeNum: computeArr.length,
              canUseNum: canUseArr.length,
              otherNum: otherArr.length,
              data: showDataList[key]
            })
          }
          this.list = list

          let obj = this.dataConditions[+adqAudienceTask.dataType].find(v => v.id === +adqAudienceTask.dataCondition)
          adqAudienceTask.dataConditionStr = obj.value || '-'
          adqAudienceTask.lifeCycle = JSON.parse(adqAudienceTask.lifeCycle).join('~')
          this.taskInfo = adqAudienceTask || null
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    loadMoreList(v, i) {
      console.log('more', v, v.pageNum + 1, v.disabled, i)
    },

    // 点击返回
    handleBack() {
      this.$router.replace({
        name: 'tencentCrowdPackingTask'
      })
    },

    // 同步状态
    handleSyncStatus() {
      this.getPage()
    }
  }
}
</script>

<style lang="scss">
.details-box {
  .el-collapse-item__header {
    background-color: #f5f7fa;
  }

  .el-collapse {
    border-left: solid 1px #EBEEF5;
    border-right: solid 1px #EBEEF5;
  }
}
</style>

<style scoped lang="scss">
.details-box {
  .flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center
  }

  .flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center
  }

  .padding-cls {
    padding: 10px 20px;
  }

  .detail-collapse-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin: 0 20px;

    span:last-child {
      color: #67C23A;
    }
  }

  .detail-list-box {
    border-top: solid 1px #EBEEF5;

    .list-table {
      width: 99%;
      margin: auto;
    }
  }

  .detail-total {
    font-size: 14px;
    color: #666;
    padding: 15px;
  }

  .detail-footer {
    text-align: center;
    margin: 100px 0;
  }

  .status-ERROR {
    color: #f00;
  }

  .status-SUCCESS {
    color: #67C23A;
  }
}
</style>
