var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _vm.detail.content === ""
      ? _c("div", { staticClass: "copyGh" }, [
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c("i", { staticClass: "el-icon-document-copy" }),
            _c("p", [_vm._v(_vm._s(_vm.detail.name) + "组件")]),
          ]),
        ])
      : _c(
          "div",
          {
            style: {
              paddingTop: `${_vm.detail.paddingTop}px`,
              paddingBottom: `${_vm.detail.paddingBottom}px`,
            },
          },
          [
            _vm.detail.type === "label"
              ? _c(
                  "div",
                  {
                    staticClass: "label",
                    style: {
                      background: _vm.detail.backgroundColor,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "avatar" },
                      [
                        _c(
                          "el-avatar",
                          {
                            attrs: { size: 54, src: "https://empty" },
                            on: { error: _vm.errorHandler },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://cw-oss.mokamrp.com/copywriting/outerside/images/IGmnH7n2zi28fDAHqDFFCo2stlGMe9pJIuEhFmIt.jpeg",
                              },
                            }),
                          ]
                        ),
                        _c("p", { style: { color: _vm.detail.ghFontColor } }, [
                          _vm._v("公众号名称"),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "button-btn1",
                        style: {
                          background: _vm.detail.btnBgColorTheme,
                          color: _vm.detail.btnFontColor,
                          border: `1px solid ${_vm.detail.btnBorderColorTheme}`,
                          fontWeight: _vm.detail.btnFontType,
                        },
                      },
                      [
                        _c("span", {
                          domProps: { innerHTML: _vm._s(_vm.detail.btnTitle) },
                        }),
                      ]
                    ),
                  ]
                )
              : _c("div", { staticClass: "button" }, [
                  _c(
                    "div",
                    {
                      staticClass: "button-btn",
                      style: {
                        background: _vm.detail.btnBgColorTheme,
                        color: _vm.detail.btnFontColor,
                        border: `1px solid ${_vm.detail.btnBorderColorTheme}`,
                      },
                    },
                    [
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.detail.btnTitle) },
                      }),
                    ]
                  ),
                ]),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }