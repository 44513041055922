<template>
  <el-container class="app-layout">
    <!--header-->
    <el-header class="header" style="height:48px">
      <div class="system-logo">
        <div class="system-icon" @click="onClickMenu">
          <img src="@/assets/image/logo.svg" alt="" />
        </div>
        <i class="iconfont icon-danxingshensuo" style="color: #000000; font-size: 20px " @click="onClickMenu" />
      </div>
      <navbar />
    </el-header>
    <!--aside-->
    <el-container class="container">
      <el-aside class="aside" style="width: auto !important;">
        <Sidebar v-if="sideMenu.length !== 0" />
      </el-aside>
      <!--主体-->
      <el-main class="content" style="padding: 0px;">
        <el-scrollbar id="mainScroll" class="scroll">
          <!--页面标签-->
          <div class="page-tag">
            <Breadcrumb />
            <!-- <span v-if="$route.meta.title">{{ $route.meta.title }}</span> -->
          </div>
          <section class="page-container">
            <transition name="fade-transform" mode="out-in">
              <router-view :key="key" />
            </transition>
          </section>
        </el-scrollbar>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import SideBar from './SideBar'
import NavBar from './NavBar'
import Breadcrumb from './Breadcrumb.vue'
import { mapGetters, mapMutations } from 'vuex'
// import { getMenuList as getMenuListApi } from '@/network/api/api-base'
export default {
  name: 'Layout',
  components: {
    Sidebar: SideBar,
    navbar: NavBar,
    Breadcrumb
  },

  computed: {
    key() {
      return this.$route.fullPath
    },

    ...mapGetters(['isCollapse', 'menu', 'sideMenu'])
  },

  methods: {
    ...mapMutations('user', ['TOGGLE_MENU']),
    // 点击菜单按钮

    onClickMenu() {
      this.TOGGLE_MENU(!this.isCollapse)
    }
  }
}
</script>

<style lang="scss">
.app-layout {
  .scroll {
    z-index: 4;
  }
}
</style>

<style scoped lang="scss">
.app-layout {
  width: 100%;
  height: 100vh;
  min-width: 1360px;

  .aside {
    position: relative;
    z-index: 4;
    background-color: #efeff4;

    // 去掉滚动条
    &::-webkit-scrollbar {
      width: 0;
      background-color: transparent;
    }
  }

  .container {
    overflow: hidden;
    z-index: 8;
    .content {
      position: relative;
      z-index: 4;
      background-color: #fff;
      padding: 34px 0 0 0;

      .scroll {
        box-sizing: border-box;

        .page-container {
          box-sizing: border-box;
          padding: 0 40px;
        }
      }
    }
  }
}

.page-tag {
  color: #333a3f;
  font-size: 24px;
  font-weight: bold;
  margin: 10px 40px 10px 40px;
  .page-tag-title {
    color: #333a3f;
    font-size: 24px;
    font-weight: bold;
  }
}

.header {
  background-color: #fff;
  display: flex;
  padding: 0 !important;
}

.system-logo {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  align-items: center;
  width: 169px;
  border-bottom: 1px solid #e6e6e6;
  // &::after {
  //   content: '';
  //   width: 2px;
  //   height: 30px;
  //   background: #e6e6e6;
  // }
  .system-icon {
    height: 40px;
    cursor: pointer;
    display: flex;
    /* justify-content: center; */
    align-items: center;

    img {
      width: auto;
      height: 30px;
    }
    .img-small {
      width: 50px;
      height: 20px;
      margin-left: 20px;
    }
  }

  .icon-menu {
    color: #555a6f;
    font-size: 20px;
    margin-left: 50px;
    cursor: pointer;
    margin-right: 20px;
  }

  // .system-info {
  //   text-align: left;

  //   p {
  //     font-size: 16px;
  //     color: #212937;
  //     font-weight: 400;
  //     margin: 0;
  //     padding: 0;
  //     white-space: nowrap;
  //   }

  //   span {
  //     font-size: 10px;
  //     color: #91949f;
  //     position: relative;
  //     // top: -5px;
  //   }
  // }
}
.isCollapse {
  transition: all 0.5s;
}

.isCollapse-1 {
  transition: all 1s;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
