var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c(
      "div",
      {
        style: {
          paddingLeft: "10px",
          paddingRight: "10px",
          paddingTop: `${_vm.detail.paddingTop}px`,
          paddingBottom: `${_vm.detail.paddingBottom}px`,
        },
      },
      [
        _vm.detail.type === "104"
          ? _c("OnePerLine", { attrs: { detail: _vm.detail } })
          : _vm._e(),
        _vm.detail.type === "103"
          ? _c("TwoPerLine", { attrs: { detail: _vm.detail } })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }