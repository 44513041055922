<!--
 * @Description: 图片编辑区
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2022-03-16 09:54:54
-->

<template>
  <section class="section">
    <base-box name="img">
      <template slot="body">
        <div class="image-updata">
          <p class="w-100">选择图片：</p>
          <div>
            <div class="update" @click="openMaterialBox">
              <img v-if="detail.pureImageUrl" :src="detail.pureImageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon" />
            </div>
            <p class="tip mt-10">
              暂不支持含有二维码的图片 <br />
              图片格式：大小不超过300KB， <br />
              图片尺寸：宽度750像素，高度不超过1536像素
            </p>
          </div>
        </div>

        <div>
          <p class="w-100">上边距：</p>
          <div class="ml-20">
            <el-slider v-model="detail.paddingTop" show-input> </el-slider>
          </div>
        </div>

        <div class="setting">
          <p class="w-100">下边距：</p>
          <div class="ml-20">
            <el-slider v-model="detail.paddingBottom" show-input> </el-slider>
          </div>
        </div>
      </template>
    </base-box>
  </section>
</template>

<script>
import BaseBox from './base.vue'
export default {
  components: {
    'base-box': BaseBox
  },
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    /**
     * @description: 打开资源库
     */
    openMaterialBox() {
      this.$emit('openMaterialBox')
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.update {
  width: 130px !important;
  height: 130px;
  line-height: 130px;
  text-align: center;
  border: 1px solid #edecf2;
  background-color: #fbfafd;
  border-radius: 3px;
  cursor: pointer;
  font-size: 30px;
  color: #7d7d7d;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .avatar {
    width: 130px;
  }
}

.section {
  .w-100 {
    font-size: 12px;
    margin-right: 10px;
  }
}

.image-updata {
  display: flex !important;
  align-items: flex-start !important;
}

// .setting {
//   display: flex;
//   justify-content: flex-start;
//   margin: 10px 0;
//   align-items: center;
//   & > p {
//     font-size: 14px;
//     color: rgb(78, 78, 78);
//   }
//   & > div {
//     width: 80%;
//     margin-left: 30px;
//   }
// }
</style>
