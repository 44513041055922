var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "allocate-materials-dialog",
      attrs: {
        title: "分配素材",
        visible: _vm.show,
        "append-to-body": true,
        width: "760px",
        top: "20vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.onCloseDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                inline: true,
                "label-width": "100px",
                "label-suffix": "：",
                model: _vm.form,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "组别", prop: "departmentId" } },
                [
                  _c("el-cascader", {
                    ref: "deptRef",
                    attrs: {
                      options: _vm.deptList,
                      props: _vm.cascaderProp,
                      filterable: "",
                      placeholder: "请选择组别",
                    },
                    on: { change: _vm.handleSelectDept },
                    model: {
                      value: _vm.form.departmentId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "departmentId", $$v)
                      },
                      expression: "form.departmentId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "人员", prop: "userId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: "请选择名称",
                        multiple: "",
                        "collapse-tags": "",
                      },
                      model: {
                        value: _vm.form.userId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "userId", $$v)
                        },
                        expression: "form.userId",
                      },
                    },
                    _vm._l(_vm.userList, function (itm, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { label: itm.userName, value: itm.userId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "指定完成时间",
                    prop: "appointTime",
                    "label-width": "130px",
                  },
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      placeholder: "请选择完成时间",
                      "value-format": "yyyy-MM-dd",
                      "picker-options": _vm.pickerOptions,
                    },
                    model: {
                      value: _vm.form.appointTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "appointTime", $$v)
                      },
                      expression: "form.appointTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { type: "primary", loading: _vm.saveLoading },
                      on: { click: _vm.handleAddType },
                    },
                    [_vm._v("添加 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-divider"),
          _c(
            "div",
            { staticClass: "tag-box" },
            [
              _c("div", { staticClass: "tag-title" }, [_vm._v("分配素材")]),
              _c(
                "el-table",
                { attrs: { data: _vm.tableData, size: "small" } },
                [
                  _c("el-table-column", {
                    attrs: { label: "组别", prop: "deptName" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "人员", prop: "userName" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "指定完成时间", prop: "appointTime" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleClose(
                                      row.toolProductPersonId
                                    )
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "btn", on: { click: _vm.closeModal } },
            [_vm._v("关 闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }