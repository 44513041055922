<!--
 * @Description: 落地页模板
 * @Date: 2021-10-18 10:49:17
 * @LastEditTime: 2022-04-01 11:31:13
-->

<template>
  <div>
    <!-- 列表查询 -->
    <el-form ref="search" :inline="true" :model="search" class="search-form search-form-4" @submit.native.prevent>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="定向名称" prop="promoteTitle">
            <el-input v-model="search.promoteTitle" placeholder="请输入定向名称"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="创建人" prop="createBy">
            <el-input v-model="search.createBy" placeholder="请输入创建人"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="项目" prop="projectId">
            <SearchSelect
              v-model="search.projectId"
              :options="projectList"
              :props="{
                label: 'projectName',
                value: 'bsProjectId'
              }"
              placeholder="请选择项目"
              @change="(value) => (selectProjectId = value)"
            />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="产品" prop="productId">
            <el-select v-model="search.productId" placeholder="请选择产品" @change="(value) => (selectProductId = value)">
              <el-option label="不限" :value="0"></el-option>
              <el-option v-for="(itm, idx) in productListed" :key="idx" :label="itm.productName" :value="itm.bsProductId"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="创建时间" prop="createTime">
            <el-date-picker v-model="search.createTime" type="daterange" value-format="yyyy-MM-dd" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" round @click="getPage">查询</el-button>
            <el-button round @click="resetFormQuery('search')">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div class="justify-sb">
      <el-dropdown @command="handleCommand">
        <el-button type="primary"> 批量操作<i class="el-icon-arrow-down el-icon--right"></i> </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="ldyBatchAsync" :disabled="selectRow.length < 2">批量同步</el-dropdown-item>
          <el-dropdown-item command="ldyBatchCopy" :disabled="selectRow.length < 2">批量复制</el-dropdown-item>
          <el-dropdown-item command="ldyDelete" :disabled="selectRow.length < 2">批量删除</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <router-link to="/wechatCreateLdy">
        <el-button type="primary" icon="el-icon-plus" round>新增模板</el-button>
      </router-link>
    </div>

    <!-- 表格 -->
    <TablePane class="mt-20" :data-source="dataSource" @getPage="getPage"> </TablePane>

    <!-- 人群包同步 -->
    <el-dialog title="落地页同步" :visible.sync="sync_visible" width="820px" :modal-append-to-body="false" destroy-on-close>
      <el-form ref="ruleForm" :model="ruleForm" label-width="100px" class="demo-ruleForm">

        <el-form-item label="投放平台:" prop="launchName" :rules="{ required: true, message: '请选择投放平台', trigger: 'change' }">
          <el-select
            v-model="ruleForm.launchName"
            style="width:400px"
            placeholder="请选择投放平台"
            @change="launchChange()"
          >
            <el-option label="微信广告" value="MP"></el-option>
            <el-option label="腾讯广告" value="ADQ"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-show="ruleForm.launchName==='MP'" label="服务商:" prop="spid" :rules="{ required: true, message: '请选择服务商', trigger: 'blue' }">
          <el-select
            v-model="ruleForm.spid"
            style="width:400px"
            placeholder="请选择服务商"
            @change="
              () => {
                ruleForm.ghids = null
              }
            "
          >
            <el-option v-for="(itm, idx) in adMpAmList" :key="idx" :label="`${itm.agencyName} (${itm.mobileId})`" :value="`${itm.spid}+${itm.mobileId}`"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-show="ruleForm.launchName==='MP'" label="投放账户:" prop="ghids" :rules="{ required: true, message: '请选择投放账户', trigger: 'blue' }">
          <SearchSelectAsyncAccount
            v-model="ruleForm.ghids"
            :disabled="!ruleForm.spid"
            :spid="ruleForm.spid && ruleForm.spid.split('+')[0]"
            multiple
            :props="{
              label: 'name',
              value: 'ghid'
            }"
            style="width:400px"
            placeholder="请选择投放账户"
          />
        </el-form-item>
        <editTable v-if="isEnterpriseWx !== 0 && ruleForm.launchName==='MP'" :edit-table-data="ruleForm.ghids" @retdata="editReturnData"></editTable>

        <!-- ADQ-->
        <el-form-item v-show="ruleForm.launchName==='ADQ'" label="投放账户:" prop="ghids" :rules="{ required: true, message: '请选择投放账户', trigger: 'blue' }">
          <searchSelectAsyncAccountAdq
            v-model="ruleForm.ghids"
            multiple
            :props="{
              label: 'corporationName',
              value: 'accountId'
            }"
            style="width:400px"
            placeholder="请选择投放账户"
          />
        </el-form-item>
        <editTable v-if="isEnterpriseWx !== 0 && ruleForm.launchName==='ADQ'" :edit-table-data="ruleForm.ghids" @retdata="editReturnData"></editTable>

        <!-- <el-form-item v-if="isEnterpriseWx !== 0" label="企微ID:" align="center">
          <el-select v-if="row.appids && row.appids.length" v-model="ruleForm.corpid" filterable size="small" placeholder="请选择绑定企微ID">
            <el-option v-for="item in row.appids" :key="item.id" :value="item.appid">{{ item.appid }}</el-option>
          </el-select>
          <el-input v-else v-model="ruleForm.corpid" style="width: 203px" placeholder="请输入绑定企微ID" size="small" />
        </el-form-item>

        <el-form-item v-if="isEnterpriseWx !== 0" label="客服组ID" type="groupid" align="center">
          <el-select v-if="row.appids && row.appids.length" v-model="ruleForm.groupid" filterable size="small" placeholder="请选择客服组ID">
            <el-option v-for="item in row.appids" :key="item.id" :value="item.kefuGroup">{{ item.kefuGroup }}</el-option>
          </el-select>
          <el-input v-else v-model="ruleForm.groupid" style="width: 203px" placeholder="请输入客服组ID" size="small" />
        </el-form-item> -->

      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
        <el-button @click="resetForm('ruleForm')">取消</el-button>
      </span>
    </el-dialog>

    <!-- 人群包批量同步 -->
    <el-dialog title="落地页批量同步" :visible.sync="sync_visible1" width="620px" :modal-append-to-body="false" destroy-on-close>
      <el-form ref="ruleForm1" :model="ruleForm1" label-width="100px" class="demo-ruleForm">
        <el-form-item label="服务商:" prop="spid" :rules="{ required: true, message: '请选择服务商', trigger: 'change' }">
          <el-select
            v-model="ruleForm1.spid"
            style="width:400px"
            placeholder="请选择服务商"
            @change="
              () => {
                ruleForm1.ghids = null
              }
            "
          >
            <el-option v-for="(itm, idx) in adMpAmList" :key="idx" :label="`${itm.agencyName} (${itm.mobileId})`" :value="`${itm.spid}+${itm.mobileId}`"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="投放账户:" prop="ghids" :rules="{ required: true, message: '请选择投放账户', trigger: 'blue' }">
          <SearchSelectAsyncAccount
            v-model="ruleForm1.ghids"
            :disabled="!ruleForm1.spid"
            :spid="ruleForm1.spid && ruleForm1.spid.split('+')[0]"
            multiple
            :props="{
              label: 'name',
              value: 'ghid'
            }"
            style="width:400px"
            placeholder="请选择投放账户"
          />
        </el-form-item>

        <!-- <el-form-item v-if="isEnterpriseWx !== 0" label="企微ID:" align="center">
          <el-select v-if="row.appids && row.appids.length" v-model="ruleForm.corpid" filterable size="small" placeholder="请选择绑定企微ID">
            <el-option v-for="item in row.appids" :key="item.id" :value="item.appid">{{ item.appid }}</el-option>
          </el-select>
          <el-input v-else v-model="ruleForm.corpid" style="width: 203px" placeholder="请输入绑定企微ID" size="small" />
        </el-form-item>

        <el-form-item v-if="isEnterpriseWx !== 0" label="客服组ID" type="groupid" align="center">
          <el-select v-if="row.appids && row.appids.length" v-model="ruleForm.groupid" filterable size="small" placeholder="请选择客服组ID">
            <el-option v-for="item in row.appids" :key="item.id" :value="item.kefuGroup">{{ item.kefuGroup }}</el-option>
          </el-select>
          <el-input v-else v-model="ruleForm.groupid" style="width: 203px" placeholder="请输入客服组ID" size="small" />
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm1('ruleForm1')">确定</el-button>
        <el-button @click="resetForm1('ruleForm1')">取消</el-button>
      </span>
    </el-dialog>
    <!-- 人群包批量同步 -->
    <el-dialog title="落地页批量同步" :visible.sync="sync_visible1" width="820px" :modal-append-to-body="false" destroy-on-close>
      <el-form ref="ruleForm1" :model="ruleForm1" label-width="100px" class="demo-ruleForm">
        <el-form-item label="服务商:" prop="spid" :rules="{ required: true, message: '请选择服务商', trigger: 'change' }">
          <el-select
            v-model="ruleForm1.spid"
            style="width:400px"
            placeholder="请选择服务商"
            @change="
              () => {
                ruleForm1.ghids = null
              }
            "
          >
            <el-option v-for="(itm, idx) in adMpAmList" :key="idx" :label="`${itm.agencyName} (${itm.mobileId})`" :value="`${itm.spid}+${itm.mobileId}`"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="投放账户:" prop="ghids" :rules="{ required: true, message: '请选择投放账户', trigger: 'blue' }">
          <SearchSelectAsyncAccount
            v-model="ruleForm1.ghids"
            :disabled="!ruleForm1.spid"
            :spid="ruleForm1.spid && ruleForm1.spid.split('+')[0]"
            multiple
            :props="{
              label: 'name',
              value: 'ghid'
            }"
            style="width:400px"
            placeholder="请选择投放账户"
          />
        </el-form-item>

        <!-- <el-form-item v-if="isEnterpriseWx !== 0" label="企微ID:" align="center">
          <el-select v-if="row.appids && row.appids.length" v-model="ruleForm.corpid" filterable size="small" placeholder="请选择绑定企微ID">
            <el-option v-for="item in row.appids" :key="item.id" :value="item.appid">{{ item.appid }}</el-option>
          </el-select>
          <el-input v-else v-model="ruleForm.corpid" style="width: 203px" placeholder="请输入绑定企微ID" size="small" />
        </el-form-item>

        <el-form-item v-if="isEnterpriseWx !== 0" label="客服组ID" type="groupid" align="center">
          <el-select v-if="row.appids && row.appids.length" v-model="ruleForm.groupid" filterable size="small" placeholder="请选择客服组ID">
            <el-option v-for="item in row.appids" :key="item.id" :value="item.kefuGroup">{{ item.kefuGroup }}</el-option>
          </el-select>
          <el-input v-else v-model="ruleForm.groupid" style="width: 203px" placeholder="请输入客服组ID" size="small" />
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm1('ruleForm1')">确定</el-button>
        <el-button @click="resetForm1('ruleForm1')">取消</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="关联账号"
      :visible.sync="associate_public"
      :modal-append-to-body="false"
      destroy-on-close
      @close="
        () => {
          ;(adMpPageManagemen.ghname = ''), (adMpPageManagemen.createBy = '')
        }
      "
    >
      <div>
        <el-input v-model="adMpPageManagemen.ghname" style="width: 250px; margin-bottom: 10px; margin-right: 20px" placeholder="请输入账户名称"> </el-input>
        <el-input v-model="adMpPageManagemen.createBy" style="width: 250px; margin-bottom: 10px; margin-right: 20px" placeholder="请输入同步人"> </el-input>
        <el-button slot="append" type="primary" icon="el-icon-search" @click="getRuleRelation(1)">查询</el-button>
      </div>
      <el-table :data="associate_public_List" class="list-table">
        <el-table-column property="ghname" label="账户名称" align="center"></el-table-column>
        <el-table-column property="ghid" label="账户ID" align="center"></el-table-column>
        <el-table-column property="createBy" label="同步人" align="center"></el-table-column>
        <el-table-column property="createTime" label="关联时间" align="center"></el-table-column>
      </el-table>
      <el-pagination style="text-align:right; margin-top:10px" background layout="prev, pager, next" :total="associate_public_total" @current-change="getRuleRelation"> </el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import TablePane from '@/components/tablePane.vue'
import editTable from './editTable.vue'
import { getDateStartEnd } from '@/utils/ruoyi'
import { ruleRelation } from '../../../../network/api/assetManagement/assetManageWeChatAdv'
import { getAdMpAm } from '@/network/api/account/weChatAdvAccount.js'
import accountUinTencent from '@/mixin/accountUin_tencent'
import { ldyPage, ldyDelete, ldyBatchCopy, ldyBatchAsync, ldyBatchAsyncNew, ldyBatchAsyncNewAdq } from '@/network/api/assetManagement/assetManageWeChatAdv.js'
import SearchSelectAsyncAccount from '@/components/searchSelectAsyncAccount.vue'
import searchSelectAsyncAccountAdq from '@/components/searchSelectAsyncAccountAdq.vue'

export default {
  components: {
    TablePane,
    SearchSelectAsyncAccount,
    searchSelectAsyncAccountAdq,
    editTable
  },
  mixins: [accountUinTencent],
  data() {
    return {
      rtData: [],
      search: {
        promoteTitle: null,
        createTime: null,
        createBy: null,
        projectId: null,
        productId: 0
      },
      adMpPageManagemen: {
        id: 0,
        createBy: '',
        ghname: ''
      },
      adMpAmList: [],
      associate_public: false,
      associate_public_List: [],
      associate_public_total: 0,
      batchInfo_visible: false, // 批量修改
      commandType: '0',
      selectRow: [], // 选中的列
      isEnterpriseWx: 0, // 同步的行是否需要有企微组件
      selectRow_one: {},
      ruleForm: {
        spid: null,
        ghids: null,
        groupid: null,
        launchName: 'MP',
        corpid: null
      },
      selectRow_one1: {},
      sync_visible: false,
      ruleForm1: {
        spid: null,
        ghids: null,
        groupid: null,
        corpid: null
      },
      sync_visible1: false,
      dataSource: {
        data: [],
        cols: [
          {
            label: '模板名称',
            prop: 'promoteTitle'
          },
          {
            label: '顶部素材预览',
            imageWidth: 40,
            imageHeight: 40,
            imageHoverWidth: 150,
            property: 'topMaterial'
          },
          {
            label: '创建人',
            prop: 'createBy'
          },
          {
            label: '关联项目',
            prop: 'projectName'
          },
          {
            label: '关联产品',
            prop: 'productName'
          },
          {
            label: '历史同步账户',
            prop: 'asyncNum',
            handleRow: (index, record) => {
              this.adMpPageManagemen.id = record.id
              this.associate_public = true
              this.getRuleRelation()
            }
          },
          {
            label: '创建时间',
            prop: 'createTime'
          }
        ], // 表格的列数据
        isIndex: true, // 列表序号
        loading: true, // loading
        isOperation: true,
        isSelection: true,
        pageData: {
          total: 0 // 总条数
        },
        handleSelectionChange: (selection) => {
          this.selectRow = selection
        },
        operation: {
          // 表格有操作列时设置
          label: '操作', // 列名
          width: 150, // 根据实际情况给宽度
          data: [
            {
              tooltip: '同步',
              icon: 'el-icon-upload2',
              handleRow: async (index, record) => {
                this.launchChange()
                this.sync_visible = true
                this.isEnterpriseWx = record.isEnterpriseWx
                this.selectRow_one = record
              }
            },
            {
              tooltip: '编辑',
              icon: 'el-icon-edit',
              handleRow: (index, recode) => {
                this.$router.push({
                  name: 'wechatEditLdy',
                  params: {
                    id: recode.id
                  }
                })
              }
            },
            {
              tooltip: '复制', // 操作名称
              icon: 'el-icon-copy-document',
              handleRow: async (index, record) => {
                const { code } = await ldyBatchCopy([record.id])
                if (code === 200) {
                  this.getPage()
                  this.$message.success('复制成功!')
                }
              }
            },
            {
              tooltip: '删除', // 操作名称
              icon: 'el-icon-delete',
              color: '#ff0000',
              handleRow: async (index, record) => {
                this.$confirm('确定要删除吗?', '删除提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'error'
                }).then(async () => {
                  const { code } = await ldyDelete([record.id])
                  if (code === 200) {
                    this.getPage()
                    this.$message.success('删除成功!')
                  }
                })
              }
            }
          ]
        }
      }
    }
  },

  mounted() {
    this.getPage()
    this._getAdMpAm()
  },
  methods: {
    // 投放平台参数修改事件
    launchChange() {
      const { ruleForm } = this
      this.rtData = []
      ruleForm.spid = null
      ruleForm.ghids = []
      ruleForm.groupid = null
      ruleForm.corpid = null
    },
    // 编辑table返回值
    editReturnData(dt) {
      this.rtData = dt
    },

    // 获取服务商
    _getAdMpAm() {
      getAdMpAm().then((res) => {
        if (res.code === 200) {
          this.adMpAmList = res.data
        }
      })
    },

    /**
     * @description 获取关联账号列表
     */
    getRuleRelation(pageNum = 1) {
      const { adMpPageManagemen } = this
      let params = {
        ghname: adMpPageManagemen.ghname,
        createBy: adMpPageManagemen.createBy,
        templateId: adMpPageManagemen.id,
        pageSize: 10,
        pageNum
      }
      ruleRelation({ ...params }).then(({ code, data }) => {
        if (code === 200) {
          this.associate_public_List = data.records
          this.associate_public_total = data.total
        }
      })
    },
    /**
     * @description: 重置数据
     * @param {*} formName ref
     */
    resetForm(formName) {
      this.batchInfo_visible = false
      this.sync_visible = false
      this.$refs[formName].resetFields()
    },
    /**
     * @description: 重置数据
     * @param {*} formName ref
     */
    resetForm1(formName) {
      this.batchInfo_visible = false
      this.sync_visible1 = false
      this.$refs[formName].resetFields()
    },
    resetFormQuery(formName) {
      this.batchInfo_visible = false
      this.sync_visible1 = false
      this.$refs[formName].resetFields()
      this.productListed = []
      setTimeout(() => {
        this.getPage()
      }, 300)
    },

    /**
     * @description: 批量操作
     * @param {*} value 修改的项
     */
    async handleCommand(value) {
      const ids = this.selectRow.map((item) => item.id)
      if (value === 'ldyDelete') {
        const { code } = await ldyDelete(ids)
        if (code === 200) {
          this.$message.success('删除成功!')
        }
        setTimeout(() => {
          this.getPage()
        }, 300)
      }
      if (value === 'ldyBatchCopy') {
        const { code } = await ldyBatchCopy(ids)
        if (code === 200) {
          this.$message.success('复制成功!')
        }
        setTimeout(() => {
          this.getPage()
        }, 300)
      }
      if (value === 'ldyBatchAsync') {
        this.sync_visible1 = true
        // this.isEnterpriseWx = record.isEnterpriseWx
        // this.selectRow_one1 = record

        // const { code } = await ldyBatchAsync(ids)
        // if (code === 200) {
        //   this.$message.success('同步成功!')
        // }
      }
    },

    /**
     * @description: 同步
     */
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        const { selectRow_one, selectRow, ruleForm } = this
        if (ruleForm.launchName === 'ADQ') {
          if (this.isEnterpriseWx !== 0 && (this.rtData === null || this.rtData.length === 0)) {
            this.$message.error('请确认企微ID或客服组ID!')
            return
          }
          if (this.rtData.length > 0) {
            var lst = this.rtData.filter((f) =>
              f.groupid === null || f.corpid === null || undefined === f.corpid || undefined === f.groupid
            )
            if (lst.length > 0) {
              this.$message.error('请确认企微ID或客服组ID!')
              return
            }
          }
          let params = {
            // ids: selectRow.length ? selectRow : [selectRow_one],
            ids: selectRow.length ? selectRow.map((item) => ({ id: item.id })) : [{ id: selectRow_one.id }],
            ghids: ruleForm.ghids.map((item) => {
              var ft = this.rtData.filter((element) =>
                item.split('+')[0] === element.ghid
              )
              return {
                groupid: ft.length > 0 ? ft[0].groupid : null,
                corpid: ft.length > 0 ? ft[0].corpid : null,
                ghid: item.split('+')[0],
                name: item.split('+')[1]
              }
            })
            // spid: ruleForm.spid.split('+')[0],
            // mobileId: ruleForm.spid.split('+')[1]
          }
          console.log(params)
          const { code } = await ldyBatchAsyncNewAdq(params)
          if (code === 200) {
            this.$message.success('同步成功!')
          }
          this.resetFormQuery(formName)
        }
        if (ruleForm.launchName === 'MP') {
          if (this.isEnterpriseWx !== 0 && (this.rtData === null || this.rtData.length === 0)) {
            this.$message.error('请确认企微ID或客服组ID!')
            return
          }
          if (this.rtData.length > 0) {
            // eslint-disable-next-line no-redeclare
            var lst = this.rtData.filter((f) =>
              f.groupid === null || f.corpid === null || undefined === f.corpid || undefined === f.groupid
            )
            if (lst.length > 0) {
              this.$message.error('请确认企微ID或客服组ID!')
              return
            }
          }
          let params = {
            // ids: selectRow.length ? selectRow : [selectRow_one],
            ids: selectRow.length ? selectRow.map((item) => ({ id: item.id })) : [{ id: selectRow_one.id }],
            ghids: ruleForm.ghids.map((item) => {
              var ft = this.rtData.filter((element) =>
                item.split('+')[0] === element.ghid
              )
              return {
                groupid: ft.length > 0 ? ft[0].groupid : null,
                corpid: ft.length > 0 ? ft[0].corpid : null,
                ghid: item.split('+')[0],
                name: item.split('+')[1]
              }
            }),
            spid: ruleForm.spid.split('+')[0],
            mobileId: ruleForm.spid.split('+')[1]
          }
          const { code } = await ldyBatchAsyncNew(params)
          if (code === 200) {
            this.$message.success('同步成功!')
          }
          this.resetFormQuery(formName)
        }
      })
    },
    /**
     * @description: 批量同步
     */
    submitForm1(formName) {
      this.$refs[formName].validate(async (valid) => {
        const { selectRow_one, selectRow, ruleForm1 } = this
        if (valid) {
          let params = {
            // ids: selectRow.length ? selectRow : [selectRow_one],
            ids: selectRow.length ? selectRow.map((item) => ({ id: item.id })) : [{ id: selectRow_one.id }],
            ghids: ruleForm1.ghids.map((item) => {
              return {
                ghid: item.split('+')[0],
                name: item.split('+')[1]
              }
            }),
            spid: ruleForm1.spid.split('+')[0],
            mobileId: ruleForm1.spid.split('+')[1]
          }
          const { code } = await ldyBatchAsync(params)
          if (code === 200) {
            this.$message.success('同步成功!')
          }
          this.resetFormQuery(formName)
        }
      })
    },
    /**
     * @description: 获取列表
     * @param {*} params
     */
    getPage(params = { pageNum: 1, pageSize: 10 }) {
      this.dataSource.loading = true
      const [startCreateTime, endCreateTime] = getDateStartEnd(this.search.createTime)
      let search = {
        ...this.search,
        startCreateTime,
        endCreateTime
      }
      delete search.createTime
      ldyPage({ ...search, ...params }).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          this.dataSource.data = records
          this.dataSource.pageData.total = total
          this.dataSource.pageData.pageNum = params.pageNum
          this.dataSource.loading = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.account-info {
  display: flex;
}
</style>
