/*
 * @Description: Do not edit
 * @Date: 2021-12-01 14:18:39
 * @LastEditTime: 2023-02-27 15:27:15
 */
import service from '@/network/service/service'

// 定向模板 start
export function pageTargeting(data) {
  return service.post('/api/tencent/adManagementGdtTargetings/page', data)
}

export function saveTargeting(data) {
  return service.post('/api/tencent/adManagementGdtTargetings/save', data)
}

export function updateTargeting(data) {
  return service.post('/api/tencent/adManagementGdtTargetings/update', data)
}

export function deleteTargeting(id) {
  return service.DELETE(`/api/tencent/adManagementGdtTargetings/${id}`)
}

// 获取定向标签
export function getNewOne(data) {
  return service.get('/api/tencent/adMarketingApiClients/getNewOne')
}

// 获取定向标签
export function getTargetingTags(data) {
  return service.post('/api/tencent/adManagementGdtTargetings/targeting_tags/get', data)
}

export function selectOne() {
  return service.get('/api/tencent/adMarketingApiClients/selectOne/?id=0')
}

// 获取投放账户
export function getSelectAccountUin(data) {
  return service.post('/api/tencent/adMarketingApiClients/select', data)
}

// 同步中心
export function Sync(data, type) {
  return service.post(`/api/tencent/${type}/async`, data)
}

export function SyncRefresh(key, type) {
  return service.get(`/api/tencent/${type}/async/refresh`, {
    params: { key }
  })
}

export function SyncRefreshPage(data) {
  return service.post(`/api/tencent/adManagementGdtAsyncResult/page`, data)
}

// 定向模板 end

// 人群包 start
// 人群自动打包任务列表
export function getCrowdPackList(params) {
  return service({
    method: 'get',
    url: '/api/tencent/AdAdqAudienceTaskController/page',
    params
  })
}

// 保存/更新人群打包任务
export function saveCrowdPackTask(data) {
  return service.post(`/api/tencent/AdAdqAudienceTaskController/saveOrUpdateTask`, data)
}

// 删除人群打包任务
export function deleteCrowdPackTask(adAdqAudienceTaskId) {
  return service.delete(`/api/tencent/AdAdqAudienceTaskController/deleteTask/${adAdqAudienceTaskId}`)
}

// 人群打包详情
export function getCrowdPackDetail(params) {
  return service({
    method: 'get',
    url: '/api/tencent/AdAdqAudienceTaskController/getTaskInfoList',
    params
  })
}

// 人群自动打包-任务数据范围列表
export function getDataRangeList(params) {
  return service({
    method: 'get',
    url: '/api/tencent/AdAdqAudienceTaskController/getTaskInfo',
    params
  })
}

// 人群打包编辑详情
export function getViewCrowdPackDetail(params) {
  return service({
    method: 'get',
    url: '/api/tencent/AdAdqAudienceTaskController/byIdgetInfo',
    params
  })
}
// 人群包 end
