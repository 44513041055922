var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "image",
      style: {
        paddingTop: `${_vm.detail.paddingTop}px`,
        paddingBottom: `${_vm.detail.paddingBottom}px`,
      },
    },
    [
      _c("el-image", { attrs: { src: _vm.detail.pureImageUrl } }, [
        _c(
          "div",
          {
            staticClass: "image-slot",
            staticStyle: { "text-align": "center" },
            attrs: { slot: "error" },
            slot: "error",
          },
          [
            _c("i", { staticClass: "el-icon-picture-outline" }),
            _c("p", [_vm._v(_vm._s(_vm.detail.name) + "组件")]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }