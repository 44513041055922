/*
 * @Description: Do not edit
 * @Date: 2021-09-15 09:48:06
 * @LastEditTime: 2022-03-11 09:36:08
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import MarketUI from 'market-ui-vue' // 引入组件库
import 'market-ui-vue/dist/Market.css'

import MUI from '@bytedance-ad/market-ui-vue2'
import './assets/style/fontIcon/iconfont.css'

MUI.config({ responseTransToUnderline: true }) // 对应自定义配置
Vue.use(MUI) // 全局引入

import GlobalPlugin from './common/plugins'
import { download } from '@/utils/request'
import '@/assets/style/index.scss'
import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, handleTree } from '@/utils/ruoyi'
import './router/permission'
// 自定义表格工具组件
import RightToolbar from '@/components/RightToolbar'
// 字典标签组件
// 字典标签组件
import DictTag from '@/components/DictTag'
import Pagination from '@/components/Pagination/index'
import SearchSelectAsync from '@/components/searchSelectAsync.vue'
import SearchSelect from '@/components/searchSelect.vue'
import { getDicts } from '@/network/api/system/dict/data'
import moment from 'moment'
import has from '@/common/directive/permission/'

import { hasPerm } from '@/common/utils/has-permission'
// 全局方法挂载
Vue.prototype.getDicts = getDicts
Vue.prototype.NODE_ENV = process.env.NODE_ENV
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.handleTree = handleTree
Vue.config.productionTip = false
Vue.prototype.download = download
Vue.prototype.defaultImg = 'https://wxa.wxs.qq.com/mpweb/delivery/legacy/moment-preview/pc/Holders/img-holder-large.png'
Vue.component('Paginations', Pagination)
Vue.component('SearchSelectAsync', SearchSelectAsync)
Vue.component('SearchSelect', SearchSelect)
Vue.component('RightToolbar', RightToolbar)
Vue.component('DictTag', DictTag)
Vue.directive('has', has)

Vue.prototype.hasPerm = hasPerm
Vue.prototype.moment = moment
// 2.6.11
Vue.use(Element, { size: 'medium' })
Vue.use(MarketUI)
// 全局方法挂载
Vue.use(GlobalPlugin)

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
