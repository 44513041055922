<template>
  <div class="app-container">
    <el-form ref="queryForm" class="search-form search-form-4" :model="queryParams" :inline="true" label-width="68px">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="定向名">
            <el-input v-model="queryParams.name" placeholder="请输入定向名称" clearable @keyup.enter.native="handleQuery" />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="关联项目">
            <el-select v-model="queryParams.projectId" placeholder="请选择关联项目" clearable @change="projectChange">
              <el-option v-for="item in projectOptions" :key="item.bsProjectId" :label="item.projectName" :value="item.bsProjectId" />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="关联产品">
            <el-select v-model="queryParams.productId" placeholder="请选择关联产品" clearable>
              <el-option v-for="item in productOptions" :key="item.bsProductId" :label="item.productName" :value="item.bsProductId" />
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="创建人" prop="createBy">
            <el-input v-model="queryParams.createBy" placeholder="请输入创建人" clearable @keyup.enter.native="handleQuery" />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="创建时间">
            <el-date-picker v-model="dateRange" value-format="yyyy-MM-dd" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="handleQuery">搜索</el-button>
            <el-button icon="el-icon-refresh" @click="resetQuery">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-row :gutter="10" class="mb8 justify-left">
      <el-button round type="primary" icon="el-icon-plus" @click="handleAdd">新增</el-button>
    </el-row>

    <el-table v-loading="loading" :data="tempList" class="list-table mt-20">
      <el-table-column label="名称" align="left" prop="name" :show-overflow-tooltip="true" />
      <el-table-column label="描述" align="left" prop="description" :show-overflow-tooltip="true" />
      <el-table-column label="类型" align="left" prop="type">
        <template slot-scope="{ row }">
          <span>{{ row.type | filterType }}</span>
        </template>
      </el-table-column>
      <el-table-column label="投放范围" align="left" prop="delivery_range">
        <template slot-scope="{ row }">
          <span v-if="row.content && row.content.delivery_range">{{ row.content.delivery_range | filterRange }}</span>
        </template>
      </el-table-column>
      <el-table-column label="项目" align="left" prop="projectName" />
      <el-table-column label="产品" align="left">
        <template slot-scope="{ row }">
          <span>{{ row.productName ? row.productName : '不限' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建人" align="left" prop="createBy" />
      <el-table-column label="创建时间" align="left" prop="createTime" width="180">
        <template slot-scope="{ row }">
          <span>{{ parseTime(row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="关联计划数" align="left" prop="campaignNum" />

      <el-table-column label="操作" align="left" class-name="small-padding fixed-width">
        <template slot-scope="{ row }">
          <el-button type="text" size="small" @click="handleCopy(row)">
            <el-tooltip content="复制" placement="top">
              <i class="el-icon-document-copy" style="font-size: 16px"></i>
            </el-tooltip>
          </el-button>
          <el-button :disabled="userInfo.userid !== row.createById" type="text" size="small" @click="handleUpdate(row)">
            <el-tooltip content="编辑" placement="top">
              <i class="el-icon-edit" style="font-size: 16px"></i>
            </el-tooltip>
          </el-button>
          <el-button type="text" :disabled="userInfo.userid !== row.createById" size="small" @click="handleDelete(row)">
            <el-tooltip content="删除" placement="top">
              <i class="el-icon-delete" style="font-size: 16px; color: red"></i>
            </el-tooltip>
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <paginations v-show="total > 0" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getTemplateList" />
  </div>
</template>

<script>
import { getMeRelationProject } from '@/network/api/basicSetting/project/basicSettingProject'
import { getProductByProject } from '@/network/api/basicSetting/product/basicSettingProduct'
import { templateList, delTemplate } from '@/network/api/massiveAd/massiveAd'
import { mapGetters } from 'vuex'
export default {
  name: 'DirectionTemp',
  components: {},
  filters: {
    filterType(val) {
      let obj = { EXTERNAL: '落地页' }
      return obj[val] || ''
    },
    filterRange(val) {
      let obj = { DEFAULT: '默认', UNIVERSAL: '通投智选', UNION: '穿山甲' }
      return obj[val] || ''
    }
  },
  props: {},
  data() {
    return {
      // 遮罩层
      loading: false,
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        name: null,
        createBy: null,
        projectId: null,
        productId: null,
        createTimeStart: null,
        createTimeEnd: null
      },
      // 日期范围
      dateRange: [],
      projectOptions: [], // 关联项目
      productOptions: [], // 关联产品
      // 总条数
      total: 0,
      // 定向模板表格数据
      tempList: []
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  watch: {},
  created() {
    this.getProject()
    this.getTemplateList()
  },
  mounted() {},
  methods: {
    // 查询模板列表
    getTemplateList() {
      if (this.dateRange.length === 2) {
        let start = this.dateRange[0]
        let end = this.dateRange[1]
        this.queryParams.createTimeStart = start + ' 00:00:00'
        this.queryParams.createTimeEnd = end + ' 23:59:59'
      }
      this.loading = true
      templateList(this.queryParams)
        .then((res) => {
          if (res.code === 200) {
            let records = res.data.records || []
            records.map((v) => {
              v.content = v.content ? JSON.parse(v.content) : {}
            })
            this.tempList = records
            this.total = res.data.total
            this.loading = false
          }
          this.loading = false
        })
        .catch((e) => {
          this.loading = false
        })
    },
    // 产品下拉
    getProduct(val) {
      getProductByProject(val).then((res) => {
        if (res.code === 200) {
          this.productOptions = res.data
        }
      })
    },
    // 项目下拉
    getProject() {
      getMeRelationProject().then((res) => {
        if (res.code === 200) {
          this.projectOptions = res.data
        }
      })
    },
    projectChange(val) {
      this.queryParams.productId = null
      this.getProduct(val)
    },
    // 搜索
    handleQuery() {
      this.queryParams.pageNum = 1
      this.getTemplateList()
    },
    // 重置
    resetQuery() {
      this.dateRange = []
      this.queryParams = {
        pageNum: 1,
        pageSize: 10,
        name: null,
        createBy: null,
        projectId: null,
        productId: null,
        createTimeStart: null,
        createTimeEnd: null
      }
      this.productOptions = []
      this.handleQuery()
    },
    // 新增按钮操作
    handleAdd() {
      this.$router.push({
        name: 'addDirectionTemp'
      })
    },
    // 复制
    handleCopy(row) {
      this.$router.push({
        name: 'copyDirectionTemp',
        params: {
          copyId: row.id
        }
      })
    },
    // 编辑
    handleUpdate(row) {
      this.$router.push({
        name: 'editDirectionTemp',
        params: {
          id: row.id
        }
      })
    },
    // 删除
    handleDelete(row) {
      this.$confirm('确定要删除该定向模板吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(function() {
          return delTemplate(row.id)
        })
        .then((res) => {
          if (res.code === 200) {
            this.$message.success('删除成功')
            this.getTemplateList()
          } else {
            this.$message.error(res.msg || '删除失败')
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss"></style>
