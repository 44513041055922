<template>
  <el-row>
    <el-col :span="12">
      <div style="border: 1px solid #d3d9e6">
        <el-alert
          style="height:40px"
          :title="selectName+'('+initNum+')'"
          :closable="false"
          type="info"
        />
        <el-input v-model="input5" style="height:35px" placeholder="请输入内容" class="input-with-select" @focus="focusClick" @blur="blurClick">
          <el-button slot="append" icon="el-icon-search" style="height:35px" />
        </el-input>
        <el-tree
          v-if="!tf"
          class="elTreeLeft"
          style=" margin-right: 2px;"
          :data="data1"
          default-expand-all
          @node-click="nodeClick"
        />
        <el-tree
          v-if="tf"
          ref="tree"
          class="elTreeLeft"
          style=" margin-right: 2px;"
          :data="data2"
          show-checkbox
          node-key="id"
          :default-expanded-keys="defShow"
          :default-checked-keys="selectedData"
          @check="checkTree"
        />
      </div>
    </el-col>
    <el-col :span="12">
      <div style="border: 1px solid #d3d9e6">
        <el-alert
          style="height:40px"
          :title="'已选中('+selectedData.length+')'"
          :closable="false"
          type="info"
        />
        <el-tree
          class="elTreeRight"
          :data="selectData"
          show-checkbox
          :default-expand-all="allTf"
          :default-checked-keys="selectedData"
          node-key="id"
          :expand-on-click-node="false"
          @check="endCheckTree"
        />
      </div>
    </el-col>
  </el-row>
</template>
<script>
// import { regionArray } from './regionArrays'

export default {
  components: { },
  props: {
    basicDate: {
      type: Array, // 类型
      default: null // 默认值
    },
    oneComp: {
      type: String, // 类型
      default: undefined // 默认值
    },
    selectName: {
      type: String, // 类型
      default: undefined // 默认值
    },
    showData: {
      type: Array,
      default: () => []
    },
    checkData: {
      type: Array,
      default: () => []
    }
  },
  inject: [],
  data() {
    return {
      value9: undefined,
      initNum: 0,
      selectNum: 0,
      selectedData: this.checkData !== undefined && this.checkData instanceof Array && this.checkData.length > 0 ? this.checkData : [],
      allTf: true,
      defShow: ['9999999'],
      tf: true,
      data1: [],
      data2: this.basicDate,
      input5: undefined,
      halfSelected: [],
      loading: undefined,
      selectData: this.showData !== undefined && this.showData instanceof Array && this.showData.length > 0 ? this.showData : []
    }
  },
  computed: {

  },
  watch: {
    showData: function(val) {
      if (val instanceof Array && val.length > 0) {
        this.selectData = val
      }
    },
    checkData: function(val) {
      if (val instanceof Array && val.length > 0) {
        this.selectedData = val
      }
    },
    input5: function(val) {
      this.data1 = []
      if (val !== undefined && val !== null && val.trim() !== '') {
        this.data1 = this.forData2Byname(this.basicDate, val)
      }
    },
    basicDate: function(val) {
      Object.assign(this.$data, this.$options.data())
      this.data2 = val
      this.initNum = this.forData3(this.data2)
    }
  },
  mounted() {
    this.initNum = this.forData3(this.data2)
  },
  methods: {
    endCheckTree(data1, data2, data3) {
      this.checkTree(data1, data2)
      this.$refs.tree.setCheckedKeys(this.selectedData)
      this.allTf = true
    },
    nodeClick(data1, data2, data3) {
      console.log(data1, data2, data3)
      if (!this.tf) {
        this.input5 = undefined
      }
      this.tf = !this.tf
      if (data1.id !== undefined) {
        var array = data1.id.split(',')
        if (array.length > 0) {
          var index = array.length - 1
          var halfCheckedKeys = []
          for (var i = 0; i < array.length; i++) {
            if (i !== array.length - 1) {
              halfCheckedKeys.push(array[i])
            }
          }
          this.halfSelected.forEach(t => {
            halfCheckedKeys.push(t)
          })
          var checkedKeys = []
          checkedKeys.push(array[index])
          this.selectedData.forEach(e => {
            checkedKeys.push(e)
          })
          this.checkTree({}, { 'halfCheckedKeys': halfCheckedKeys, 'checkedKeys': checkedKeys })
        }
      }
    },
    forData2Byname(dataArray, all) {
      var returnData = []
      dataArray.forEach(item => {
        if (item.label === this.oneComp || this.oneComp === null || this.oneComp === undefined) {
          var children = item.children
          var startid = item.id
          // 有子集合
          if (children !== undefined && children instanceof Array && children.length > 0) {
            // 遍历省
            children.forEach(s => {
              var schildren = s.children
              // 省包含
              var stf = s.label.indexOf(all) !== -1
              if (stf) {
                returnData.push({ 'label': s.label, 'id': startid + ',' + s.id, 'children': [] })
              }
              // 有子集合
              if (schildren !== undefined && schildren instanceof Array && schildren.length > 0) {
                // 遍历市
                schildren.forEach(sh => {
                  var qchildren = sh.children
                  // 市包含
                  var shtf = sh.label.indexOf(all) !== -1
                  if (shtf) {
                    returnData.push({ 'label': s.label + '>' + sh.label, 'id': startid + ',' + s.id + ',' + sh.id, 'children': [] })
                  }
                  if (qchildren !== undefined && qchildren instanceof Array && qchildren.length > 0) {
                    // 遍历区tt
                    qchildren.forEach(t => {
                      if (stf) {
                        returnData.push({ 'label': s.label + '>' + sh.label + '>' + t.label, 'id': startid + ',' + s.id + ',' + sh.id + ',' + t.id, 'children': [] })
                      } else {
                        if (shtf) {
                          returnData.push({ 'label': s.label + '>' + sh.label + '>' + t.label, 'id': startid + ',' + s.id + ',' + sh.id + ',' + t.id, 'children': [] })
                        } else {
                          if (t.label.indexOf(all) !== -1) {
                            returnData.push({ 'label': s.label + '>' + sh.label + '>' + t.label, 'id': startid + ',' + s.id + ',' + sh.id + ',' + t.id, 'children': [] })
                          }
                        }
                      }
                    })
                  }
                })
              }
            })
          }
        }
      })
      return returnData
    },
    forData2Byname1(dataArray, all) {
      var returnData = []
      dataArray.forEach(item => {
        var children = item.children
        // 有子集合
        if (children !== undefined && children instanceof Array && children.length > 0) {
          var obje1 = { 'label': item.label, 'id': item.id, 'children': [] }
          returnData.push(obje1)
        } else {
          if (item.label.indexOf(all) !== -1) {
            var obje = { 'label': item.label, 'id': item.id, 'children': [] }
            returnData.push(obje)
          }
        }
      })
      return returnData
    },
    blurClick() {
      var val = this.input5
      if (val === undefined || val === null || val.trim() === '') {
        this.input5 = undefined
        this.tf = true
      }
    },
    focusClick() {
      this.tf = false
    },
    forData3(dataArray) {
      var returnData = 0
      dataArray.forEach(item => {
        var children = item.children
        // eslint-disable-next-line no-empty
        if (children instanceof Array && children.length > 0) {
          var tr = this.forData3(children)
          returnData = returnData + tr
        } else {
          returnData++
        }
      })
      return returnData
    },
    checkTree(Currentdata, data) {
      this.data2 = this.basicDate
      this.selectData = []

      this.selectNum = 0
      // data halfCheckedKeys：半选中id  ;halfCheckedNodes: 半选中node checkedKeys 选中id  checkedNodes 选中节点
      var halfs = data.halfCheckedKeys
      // this.selected = []
      var check = data.checkedKeys
      var all = []
      if (halfs !== undefined && halfs instanceof Array) {
        this.halfSelected = halfs
        halfs.forEach(y => {
          all.push(y)
        })
      }
      if (check !== undefined && check instanceof Array) {
        this.selectedData = check

        // this.selected = check
        check.forEach(u => {
          all.push(u)
        })
      }
      if (all.length > 0) {
        if (this.data2 instanceof Array) {
          this.selectData = this.forData2(this.data2, all)
          if (this.selectNum > 20) {
            this.allTf = false
          }
        }
      }
      var returnData = { 'check': check, 'halfs': halfs, 'data': this.selectData }
      this.$emit('getData', returnData)
    },
    forData2(dataArray, all) {
      var returnData = []
      dataArray.forEach(item => {
        var id = item.id
        // 选中的包含
        if (all.indexOf(id) > -1) {
          var children = item.children
          // eslint-disable-next-line no-empty
          var obj = { 'label': item.label, 'id': item.id, 'children': [] }
          if (children instanceof Array && children.length > 0) {
            obj.children = this.forData2(children, all)
          } else {
            this.selectNum++
          }
          returnData.push(obj)
        }
      })
      return returnData
    },
    setCheckedKeys(array) {
      this.$refs.tree.setCheckedKeys(array)
    },
    resetChecked() {
      this.$refs.tree.setCheckedKeys([])
    }
  }
}
</script>
<style>
  .elTreeLeft {
        overflow: auto;
        height: calc( 200px);
        width: 400px;
    }
  .elTreeRight {
      overflow: auto;
      height: calc( 236px);
      width: 400px;
  }
  .el-alert {
    border-radius: 0px;
  }
  .el-input{
    border-radius: 0px;
  }
</style>
