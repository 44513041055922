<!--
 * @Description: 兴趣
 * @Date: 2021-12-22 10:57:44
 * @LastEditTime: 2022-04-21 17:02:52
-->
<template>
  <div>
    <TreeSelect :value="selectData.category" placeholder="选择行为名称 " allow-clear style="width: 800px;" :tree-data="BEHAVIOR_list" tree-checkable :show-checked-strategy="SHOW_PARENT" :replace-fields="{ children: 'children', title: 'name', value: 'id', key: 'id' }" @change="change" />
    <el-checkbox class="ml-20" @change="(e) => selectAll(e)">全选</el-checkbox>
  </div>
</template>

<script>
import { TreeSelect } from 'ant-design-vue'
const SHOW_PARENT = TreeSelect.SHOW_PARENT
import { getTargetingTags, getNewOne } from '@/network/api/assetManagement/assetManageTencent'

export default {
  components: {
    TreeSelect
  },
  props: {
    selectData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      // value: this.selectData,
      BEHAVIOR_list: [],
      SHOW_PARENT
    }
  },
  mounted() {
    getNewOne().then(({ code, data }) => {
      if (code === 200 && Object.hasOwnProperty.call(data, 'accountId')) {
        getTargetingTags({
          accountId: data.accountId,
          type: 'BEHAVIOR',
          tagSpec: {
            behavior_spec: {
              query_mode: 'TARGETING_TAG_QUERY_MODE_COMMON'
            }
          }
        }).then((res) => {
          this.BEHAVIOR_list = res.data
        })
      }
    })
  },
  methods: {
    change(value, label) {
      this.$emit('changeBehavior', {
        category: value,
        keyword_detail: label
      })
    },
    // 全选
    selectAll(flag) {
      if (flag) {
        this.$emit('changeBehavior', {
          category: this.BEHAVIOR_list.map((item) => item.id),
          keyword_detail: this.BEHAVIOR_list.map((item) => item.name)
        })
      } else {
        this.$emit('changeBehavior', {
          category: [],
          keyword_detail: []
        })
      }
    }
  }
}
</script>
