var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: `选择${_vm.dialogTitle}`,
            visible: _vm.visible,
            center: "",
            width: "70%",
            "modal-append-to-body": false,
            "destroy-on-close": "",
          },
          on: { close: _vm.onClose },
        },
        [
          _c(
            "span",
            [
              _vm.type === "table"
                ? _c("SelectModalTable", {
                    attrs: {
                      visible: _vm.visible,
                      "data-source": _vm.tableDataSource,
                      "select-page-list": _vm.selectPageList,
                      "title-info": _vm.titleInfo,
                      "left-title": _vm.leftTitle,
                      "dialog-title": _vm.dialogTitle,
                      data: _vm.data,
                      "left-query-list": _vm.leftQueryList,
                    },
                    on: { getSelectedData: _vm.getSelectedData },
                  })
                : _vm._e(),
              _vm.type === "tree"
                ? _c("SelectModalTree", {
                    ref: "modalTree",
                    attrs: {
                      visible: _vm.visible,
                      "query-fun": _vm.queryFun,
                      "title-info": _vm.titleInfo,
                      "left-title": _vm.leftTitle,
                      "dialog-title": _vm.dialogTitle,
                      data: _vm.data,
                      "left-query-list": _vm.leftQueryList,
                    },
                    on: { getSelectedData: _vm.getSelectedData },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onClose } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onOk } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }