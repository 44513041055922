var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "advertisement" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "品牌标识",
            visible: _vm.brandModalVisible,
            width: "1200px",
            "append-to-body": true,
            "destroy-on-close": "",
          },
          on: {
            close: function ($event) {
              return _vm.resetForm()
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c("el-col", { staticClass: "tree", attrs: { span: 8 } }, [
                _c("div", { staticClass: "left-ul" }, [
                  _c("p", [_vm._v("已选投放账户")]),
                  _c("ul", [
                    _vm.accountBaiduAdv.length
                      ? _c("div", [
                          _c(
                            "div",
                            _vm._l(_vm.accountBaiduAdv, function (item) {
                              return _c(
                                "li",
                                {
                                  key: item._id,
                                  class: {
                                    active:
                                      _vm.leftQuerySelect._id === item._id,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setLeftQuerySelect(item)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(`${item._name}(${item.taaccountId})`)
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ])
                      : _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "center",
                              "margin-top": "40px",
                            },
                          },
                          [_c("span", [_vm._v("未选择")])]
                        ),
                  ]),
                ]),
              ]),
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [
                  _c(
                    "el-input",
                    {
                      staticClass: "input-with-select mb-10 w-300",
                      attrs: { placeholder: "请输入关键词查询" },
                      model: {
                        value: _vm.serarch,
                        callback: function ($$v) {
                          _vm.serarch = $$v
                        },
                        expression: "serarch",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: { click: _vm.getBrandFun },
                        slot: "append",
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml-10",
                      attrs: { type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.addBrandInfo()
                        },
                      },
                    },
                    [_vm._v("上传品牌标识")]
                  ),
                  _c("TablePane", {
                    ref: "TablePane",
                    attrs: { "data-source": _vm.dataSource },
                    on: { getPage: _vm.getBrandFun },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.getSelectPlanData },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "品牌标识上传",
            visible: _vm.uploadBrandVisible,
            width: "500px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.uploadBrandVisible = $event
            },
          },
        },
        [
          _c(
            "span",
            [
              _c(
                "el-form",
                {
                  ref: "uploadForm",
                  attrs: { model: _vm.uploadForm, "label-width": "80px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "上传范围" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "only" },
                          model: {
                            value: _vm.uploadForm.range,
                            callback: function ($$v) {
                              _vm.$set(_vm.uploadForm, "range", $$v)
                            },
                            expression: "uploadForm.range",
                          },
                        },
                        [_vm._v("当前投放账户")]
                      ),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "all" },
                          model: {
                            value: _vm.uploadForm.range,
                            callback: function ($$v) {
                              _vm.$set(_vm.uploadForm, "range", $$v)
                            },
                            expression: "uploadForm.range",
                          },
                        },
                        [_vm._v("全部投放账户")]
                      ),
                    ],
                    1
                  ),
                  _c("el-form-item", { attrs: { label: "品牌形象" } }, [
                    _c("div", { staticClass: "justify-start" }, [
                      _c("div", { staticClass: "mr-10" }, [
                        _c(
                          "div",
                          {
                            staticClass: "avatar-uploader",
                            on: {
                              click: function ($event) {
                                _vm.materialBoxVisible = true
                              },
                            },
                          },
                          [
                            _vm.uploadForm.imageUrl
                              ? _c("img", {
                                  staticClass: "avatar",
                                  attrs: { src: _vm.uploadForm.imageUrl },
                                })
                              : _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon",
                                }),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "info" }, [
                        _c("p", [_vm._v("尺寸：512px * 512px")]),
                        _c("p", [_vm._v("格式：JPG、JPEG、PNG格式")]),
                        _c("p", [_vm._v("大小：50KB 以内")]),
                      ]),
                    ]),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "品牌名称" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "8", "show-word-limit": "" },
                        model: {
                          value: _vm.uploadForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.uploadForm, "name", $$v)
                          },
                          expression: "uploadForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.uploadBrandVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.brandAddFun } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("material-box", {
        attrs: {
          visible: _vm.materialBoxVisible,
          "img-count": 1,
          name: "creativeGroup",
          "image-list": [],
          attribute: {
            key: "creativeGroup",
            value: "上传头像",
            count: 1,
            size: 50 * 1024,
            listType: "image/jpg,image/jpeg,image/png",
            width: 512,
            height: 512,
          },
          "project-id": [+_vm.projectId],
        },
        on: {
          setMaterialBox: function ($event) {
            _vm.materialBoxVisible = false
          },
          submit: _vm.selectImg,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }