var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "section" },
    [
      _c(
        "el-row",
        {
          staticStyle: { height: "100%" },
          attrs: { type: "flex", justify: "space-between" },
        },
        [
          _c("el-col", { staticClass: "tree", attrs: { span: 6 } }, [
            _c("div", { staticClass: "left-ul" }, [
              _c("p", [_vm._v("已选" + _vm._s(`${_vm.leftTitle}`))]),
              _c("ul", [
                _vm._leftQueryList.length
                  ? _c("div", [
                      _vm.titleInfo.leftLevel === "2"
                        ? _c(
                            "div",
                            [
                              _c("el-tree", {
                                attrs: {
                                  "render-content": _vm.renderContent,
                                  data: _vm.leftQueryList,
                                  "default-expand-all": "",
                                  props: _vm.defaultProps,
                                },
                                on: { "node-click": _vm.setLeftQuerySelect },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.treeLoading,
                                  expression: "treeLoading",
                                },
                              ],
                            },
                            _vm._l(_vm._leftQueryList, function (item) {
                              return _c(
                                "li",
                                {
                                  key: item._id,
                                  class: {
                                    active:
                                      _vm.leftQuerySelect._id === item._id,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setLeftQuerySelect(item)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(item._name))]
                              )
                            }),
                            0
                          ),
                    ])
                  : _c(
                      "div",
                      {
                        staticStyle: {
                          "text-align": "center",
                          "margin-top": "40px",
                        },
                      },
                      [_c("span", [_vm._v("未选择")])]
                    ),
              ]),
            ]),
          ]),
          _c(
            "el-col",
            {
              staticClass: "selected-tree",
              attrs: { span: _vm.selectPageList ? 12 : 18 },
            },
            [
              _c(
                "div",
                { staticClass: "justify-sb" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "justify-start",
                      staticStyle: { width: "400px" },
                    },
                    [
                      ["商品"].includes(_vm.dialogTitle)
                        ? _c(
                            "el-select",
                            {
                              staticStyle: {
                                width: "150px",
                                "margin-right": "10px",
                              },
                              on: { change: _vm.selectChange },
                              model: {
                                value: _vm.catalogId,
                                callback: function ($$v) {
                                  _vm.catalogId = $$v
                                },
                                expression: "catalogId",
                              },
                            },
                            _vm._l(_vm.goodsList, function (item) {
                              return _c("el-option", {
                                key: item.catalog_id,
                                attrs: {
                                  label: item.name,
                                  value: item.catalog_id,
                                },
                              })
                            }),
                            1
                          )
                        : _vm._e(),
                      ["人群包"].includes(_vm.dialogTitle)
                        ? _c(
                            "el-radio-group",
                            {
                              on: { change: _vm.crowdSearchPage },
                              model: {
                                value: _vm.crowdType,
                                callback: function ($$v) {
                                  _vm.crowdType = $$v
                                },
                                expression: "crowdType",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: 0 } }, [
                                _vm._v("定向人群"),
                              ]),
                              _c("el-radio-button", { attrs: { label: 1 } }, [
                                _vm._v("排除人群"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      !["人群包"].includes(_vm.dialogTitle)
                        ? _c(
                            "el-input",
                            {
                              staticStyle: { flex: "1" },
                              attrs: { placeholder: "请输入关键词查询" },
                              model: {
                                value: _vm.selectTable,
                                callback: function ($$v) {
                                  _vm.selectTable = $$v
                                },
                                expression: "selectTable",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                },
                                on: { click: _vm.searchPage },
                                slot: "append",
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.queryText1
                    ? _c(
                        "el-button",
                        {
                          staticClass: "ml-20",
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.queryFun1(_vm.leftQuerySelect._id)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.queryText1))]
                      )
                    : _vm._e(),
                ],
                1
              ),
              ["人群包"].includes(_vm.dialogTitle)
                ? _c(
                    "div",
                    [
                      _vm.crowdType === 0
                        ? _c("TablePane", {
                            ref: "TablePane",
                            staticClass: "table mt-20",
                            attrs: { "data-source": _vm.dataSource.dataSource },
                            on: { getPage: _vm.getPage },
                          })
                        : _c("TablePane2", {
                            ref: "TablePane",
                            staticClass: "table mt-20",
                            attrs: {
                              "data-source": _vm.dataSource.dataSource2,
                            },
                            on: { getPage: _vm.getPage },
                          }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c("TablePane", {
                        ref: "TablePane",
                        staticClass: "table mt-20",
                        attrs: { "data-source": _vm.dataSource },
                        on: { getPage: _vm.getPage },
                      }),
                    ],
                    1
                  ),
            ]
          ),
          _vm.selectPageList
            ? _c(
                "el-col",
                { staticClass: "selected-tree", attrs: { span: 6 } },
                [
                  _c("p", [_vm._v("已选" + _vm._s(_vm.dialogTitle))]),
                  ["人群包"].includes(_vm.dialogTitle)
                    ? _c(
                        "ul",
                        [
                          _c("p", { staticClass: "mt-10 tip" }, [
                            _vm._v("定向人群:"),
                          ]),
                          _vm._l(_vm.selectPageList[0], function (item) {
                            return _c(
                              "li",
                              { key: item.id, staticClass: "selected-tree-li" },
                              [
                                _c("p", [
                                  _vm._v(_vm._s(item.show_name || item.name)),
                                ]),
                                _c("i", {
                                  staticClass: "el-icon-circle-close",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteCrowdItem(item.id, 0)
                                    },
                                  },
                                }),
                              ]
                            )
                          }),
                          _c("p", { staticClass: "mt-10 tip" }, [
                            _vm._v("排除人群:"),
                          ]),
                          _vm._l(_vm.selectPageList[1], function (item) {
                            return _c(
                              "li",
                              { key: item.id, staticClass: "selected-tree-li" },
                              [
                                _c("p", [
                                  _vm._v(_vm._s(item.show_name || item.name)),
                                ]),
                                _c("i", {
                                  staticClass: "el-icon-circle-close",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteCrowdItem(item.id, 1)
                                    },
                                  },
                                }),
                              ]
                            )
                          }),
                        ],
                        2
                      )
                    : _c(
                        "ul",
                        _vm._l(_vm.selectPageList, function (item) {
                          return _c(
                            "li",
                            { key: item.id, staticClass: "selected-tree-li" },
                            [
                              _c("p", [
                                _vm._v(_vm._s(item.show_name || item.name)),
                              ]),
                              _c("i", {
                                staticClass: "el-icon-circle-close",
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteItem(item.id)
                                  },
                                },
                              }),
                            ]
                          )
                        }),
                        0
                      ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }