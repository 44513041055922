var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "creativity" },
    [
      _c(
        "el-form",
        {
          ref: "sizeForm",
          attrs: {
            "label-position": "right",
            "label-width": "120px",
            rules: _vm.rules,
            model: _vm.sizeForm,
          },
        },
        [
          _c(
            "box",
            [
              _c("template", { slot: "title" }, [_vm._v("关联单元")]),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "关联单元",
                    "label-width": "100px",
                    prop: "baiduUnitId",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "400px" },
                      attrs: { placeholder: "请选择关联单元", filterable: "" },
                      on: { change: _vm.baiduUnitChange },
                      model: {
                        value: _vm.sizeForm.baiduUnitId,
                        callback: function ($$v) {
                          _vm.$set(_vm.sizeForm, "baiduUnitId", $$v)
                        },
                        expression: "sizeForm.baiduUnitId",
                      },
                    },
                    _vm._l(_vm.baiduUnitIds, function (item) {
                      return _c("el-option", {
                        key: item.baiduUnitId,
                        attrs: {
                          label: item.adgroupFeedName,
                          value: item.baiduUnitId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "box",
            [
              _c("template", { slot: "title" }, [_vm._v("生成方式")]),
              _c(
                "el-form-item",
                { attrs: { label: "创意方式" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.sizeForm.ideaType,
                        callback: function ($$v) {
                          _vm.$set(_vm.sizeForm, "ideaType", $$v)
                        },
                        expression: "sizeForm.ideaType",
                      },
                    },
                    [
                      _c(
                        "el-radio-button",
                        { attrs: { label: 1 } },
                        [
                          _vm._v(" 程序化 "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content:
                                  "支持批量上传标题和图片，系统将根据标题*图片*样式的最优组合，对不同流量进行不同展现。",
                                placement: "top",
                              },
                            },
                            [_c("i", { staticClass: "el-icon-question" })]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-radio-button",
                        { attrs: { label: 0, disabled: "" } },
                        [
                          _vm._v(" 自定义 "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "系统将根据您组合的创意进行优选投放。",
                                placement: "top",
                              },
                            },
                            [_c("i", { staticClass: "el-icon-question" })]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c("BrandInfo", {
            attrs: { "size-form": _vm.sizeForm, "project-id": _vm.projectId },
            on: { changeData: _vm.changeData },
          }),
          _c("LdyInfo", { attrs: { "size-form": _vm.sizeForm } }),
          _vm.sizeForm.ideaType === 1
            ? [
                _c("Programmatic", {
                  ref: "programmaticRef",
                  attrs: {
                    "size-form": _vm.sizeForm,
                    "project-id": _vm.projectId,
                  },
                  on: { changeData: _vm.changeData },
                }),
              ]
            : void 0,
          _c(
            "el-form-item",
            { attrs: { "label-width": "0px" } },
            [
              _c(
                "el-button",
                {
                  staticClass: "mb-20",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("sizeForm")
                    },
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.paramsId ? "确认创意" : "立即创建") + " "
                  ),
                ]
              ),
              _c(
                "router-link",
                { staticClass: "ml-20", attrs: { to: "/baiduCreative" } },
                [_c("el-button", [_vm._v(" 取消 ")])],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }