<!--
 * @Description: Do not edit
 * @Date: 2021-12-24 14:49:52
 * @LastEditTime: 2022-05-06 11:06:28
-->
<template>
  <el-form ref="searchForm" class="search-form search-form-4" :model="searchForm">
    <el-row :gutter="20">
      <el-col :span="6">
        <el-form-item prop="campaignFeedName" label="计划名称">
          <el-input v-model="searchForm.campaignFeedName" placeholder="请输入计划名称" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="createBy" label="创建者">
          <el-input v-model="searchForm.createBy" placeholder="请输入创建者" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="bsProjectId" label="项目名称">
          <el-select v-model="searchForm.bsProjectId" filterable allow-create default-first-option placeholder="请输入项目名称" @change="bsProjectChange">
            <el-option v-for="item in projectList" :key="item.bsProjectId" :label="item.projectName" :value="item.bsProjectId" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="bsProductId" label="产品名称">
          <el-select v-model="searchForm.bsProductId" filterable allow-create default-first-option placeholder="请输入产品名称">
            <el-option v-for="item in productList" :key="item.bsProductId" :label="item.productName" :value="item.bsProductId" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="createTime" label="创建时间">
          <el-date-picker v-model="searchForm.selectDate" type="daterange" format="yyyy-MM-dd" class="w-350" value-format="yyyy-MM-dd" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-button class="btn" type="primary" @click="search">
          查询
        </el-button>
        <el-button class="btn" @click="resetSearch">
          重置
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { searchForm } from './object'
import { getMeRelationProject } from '@/network/api/basicSetting/project/basicSettingProject'
import { getProductByProject } from '@/network/api/basicSetting/product/basicSettingProduct'
export default {
  inject: ['loadData'],
  data() {
    return {
      projectList: [],
      productList: [],
      searchForm: { ...searchForm }
    }
  },
  mounted() {
    this.getProject()
  },
  methods: {
    bsProjectChange(val) {
      this.searchForm.bsProductId = undefined
      this.getProduct(val)
    },
    getProduct(val) {
      getProductByProject(val).then((res) => {
        if (res.code === 200) {
          this.productList = res.data
        }
      })
    },
    getProject() {
      getMeRelationProject().then((res) => {
        if (res.code === 200) {
          this.projectList = res.data
        }
      })
    },
    search() {
      this.loadData(true)
    },
    // 折中方案
    getSearchForm() {
      return { ...this.searchForm }
    },
    resetSearch() {
      this.searchForm.selectDate = null
      this.$refs.searchForm.resetFields()
      this.productList = []
      this.loadData(true)
    }
  }
}
</script>
