<template>
  <el-dialog :title="isEdit ? '同步转化追踪' : '同步转化追踪'" width="576px" :visible.sync="visible" :before-close="close" label-position="right" :modal-append-to-body="false" :close-on-press-escape="false" :close-on-click-modal="false">
    <!-- <span style="color: darkgray;">{{ synch }}</span> -->

    <el-timeline style="margin-left: 168px;" class="mt-20">
      <el-timeline-item v-for="(activity, index) in activities" :key="index" :icon="activity.icon" :type="activity.type" :color="activity.color" :size="activity.size" :timestamp="activity.timestamp">
        {{ activity.content }}
      </el-timeline-item>
    </el-timeline>
    <span slot="footer" class="dialog-footer">
      <el-button round @click="close">取消</el-button>
      <el-button :disabled="butDisabled" type="primary" round @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import moment from 'moment'
import { synchBaiduTrach } from '@/network/api/assetManagement/assetManagementBaiduTrack'
import { AssetManagementBaiduTrackEnt, formRules } from './object'
export default {
  inject: ['loadData'],
  data() {
    return {
      butDisabled: true,
      ent: new AssetManagementBaiduTrackEnt(),
      rules: formRules,
      isEdit: false,
      visible: false,
      synch: '正在同步',
      unique: false,
      cityList: [],
      reverse: true,
      activities: [
        {
          content: '同步中',
          timestamp: '',
          size: 'large',
          type: 'primary',
          icon: 'el-icon-loading'
        },
        {
          content: '同步结果(新增n个)',
          timestamp: '',
          size: 'large'
        }
      ]
    }
  },
  methods: {
    UniqueChange(val) {
      this.butDisabled = true
      this.activities[1].timestamp = moment().format('YYYY-MM-DD hh:mm:ss')
      if (undefined === val) {
        this.$message({
          message: '网络异常',
          type: 'error'
        })
      }
      var data = ''
      if (val.synchRange === '2' && val.baiduId instanceof Array && val.baiduId.length > 1) {
        data = 'synchRange=3'
      } else {
        data = 'synchRange=' + val.synchRange
      }

      if (val.baiduId === undefined || val.baiduId === null) {
        data = data + '&baiduId=' + -1
      } else {
        data = data + '&baiduId=' + val.baiduId
      }
      if (val.housekeeperId === undefined || val.housekeeperId === null) {
        data = data + '&housekeeperId=' + -1
      } else {
        data = data + '&housekeeperId=' + val.housekeeperId
      }

      //
      // var a = 1
      // if (a === 1) {
      //   return
      // }

      //  { 'synchRange': this.synchRange, 'baiduId': this.baiduId, 'housekeeperId': this.housekeeperId }
      //  var data1 = 'synchRange=' + val.synchRange + '&baiduId=' + val.baiduId === undefined || val.baiduId === null ? -1 : val.baiduId + '&housekeeperId=' + val.housekeeperId === undefined || val.housekeeperId === null ? -1 : val.housekeeperId
      synchBaiduTrach(data)
        .then((res) => {
          if (res.code === 200) {
            if (undefined !== res.data) {
              this.activities[0].icon = 'el-icon-circle-check'
              this.activities[1].icon = 'el-icon-circle-check'
              this.activities[1].type = 'primary'
              this.activities[1].content = '同步结果(新增' + res.data + '个)'
              this.activities[1].timestamp = moment().format('YYYY-MM-DD hh:mm:ss')
              this.synch = '完成同步'
              this.loadData()
            } else {
              this.unique = false
            }
          } else {
            this.infoOpt()
          }
          this.butDisabled = false
        })
        .catch(() => {
          this.infoOpt()
        })
    },
    infoOpt() {
      this.activities[0].icon = 'el-icon-circle-close'
      this.activities[1].icon = 'el-icon-circle-close'
      this.activities[0].type = 'danger  '
      this.activities[1].type = 'danger  '
      this.activities[1].content = '同步失败'
      this.activities[1].timestamp = moment().format('YYYY-MM-DD hh:mm:ss')
      this.synch = '同步失败'
    },
    submit() {
      this.loadData()
      this.close()
    },

    close() {
      this.visible = false
    },
    show(isEdit = false, ent) {
      this.activities[0].timestamp = moment().format('YYYY-MM-DD hh:mm:ss')
      this.unique = false
      this.ent = ent
      this.isEdit = isEdit
      this.visible = true
      this.synch = '正在同步'
      this.activities[0].type = 'primary'
      this.activities[0].icon = 'el-icon-loading'
      this.activities[1].icon = ''
      this.activities[1].type = ''
      this.activities[1].content = '同步结果(新增n个)'
      this.activities[1].timestamp = ''

      this.UniqueChange(ent)
      this.$nextTick(() => {
        if (!this.isEdit) {
          this.activities[1].timestamp = ''
        }
      })
    }
  }
}
</script>
<style scoped></style>
