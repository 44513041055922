var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "base-box",
        { attrs: { name: "video" } },
        [
          _c("template", { slot: "body" }, [
            _c("div", [
              _c("p", { staticClass: "font-13" }, [_vm._v("视频类型：")]),
              _c(
                "div",
                { staticClass: "ml-20" },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "61" },
                      model: {
                        value: _vm.detail.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.detail, "type", $$v)
                        },
                        expression: "detail.type",
                      },
                    },
                    [_vm._v("短视频")]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "image-updata" }, [
              _c("p", { staticClass: "font-13" }, [_vm._v("选择视频：")]),
              _c("div", [
                _c(
                  "div",
                  { staticClass: "update", on: { click: _vm.setMaterialBox } },
                  [
                    _vm.detail.sightVideoUrl
                      ? _c("video", {
                          staticClass: "avatar",
                          attrs: { src: _vm.detail.sightVideoUrl },
                        })
                      : _c("i", {
                          staticClass: "el-icon-plus avatar-uploader-icon",
                        }),
                  ]
                ),
                _c("p", { staticClass: "tip mt-10" }, [
                  _vm._v(" 视频大小：≤100MB "),
                  _c("br"),
                  _vm._v(" 图片尺寸：750 * ≤1536"),
                  _c("br"),
                  _vm._v(" 视频长度：≤30秒 "),
                ]),
              ]),
            ]),
          ]),
        ],
        2
      ),
      _c("MaterialVideoBox", {
        attrs: {
          visible: _vm.materialBox_visible,
          "img-count": 1,
          "video-listed": _vm.videoList,
          name: "ldy",
          attribute: {
            key: "topImg",
            value: "顶部视频",
            count: 1,
            size: 100 * 1024 * 1024,
            listType: "image/jpg,image/jpeg,image/png",
            width: null,
            height: null,
          },
        },
        on: {
          setMaterialBox: _vm.setMaterialBox,
          handleVideoSuccess: _vm.selectVideo,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }