var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "source" },
    [
      _c("SelectModal", {
        ref: "modal",
        attrs: {
          type: "table",
          "dialog-title": "人群包",
          "left-title": "投放账户",
          "title-info": {
            title: "选择推广计划",
            leftLevel: "1",
          },
          visible: _vm.visible,
          "left-query-list": _vm.accountAdv.map((item) => ({
            _name: item.name,
            _id: item.taskAccountId,
          })),
          "table-data-source": {
            dataSource: _vm.dataSource,
            dataSource2: _vm.dataSource2,
          },
          "select-page-list": [_vm.selectPageList, _vm.selectPageList2],
        },
        on: { onOk: _vm.getSelectSourceData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }