var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _vm.detail.content === ""
      ? _c("div", { staticClass: "text" }, [
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c("i", { staticClass: "el-icon-tickets" }),
            _c("p", [_vm._v(_vm._s(_vm.detail.name) + "组件")]),
          ]),
        ])
      : _c(
          "div",
          {
            style: {
              paddingLeft: "10px",
              paddingRight: "10px",
              paddingTop: `${_vm.detail.paddingTop}px`,
              paddingBottom: `${_vm.detail.paddingBottom}px`,
              fontSize: `${_vm.detail.fontSize}px`,
              textAlign:
                _vm.detail.textAlignment === "0"
                  ? "left"
                  : _vm.detail.textAlignment === "1"
                  ? "center"
                  : "right",
              color: _vm.detail.fontColor,
              fontWeight: _vm.detail.showType === "0" ? "normal" : "bold",
            },
          },
          [
            _c(
              "pre",
              { staticStyle: { margin: "0", "white-space": "pre-line" } },
              [_vm._v("      " + _vm._s(_vm.detail.content) + "\n    ")]
            ),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }