<!--
 * @Description: 沉浸式视频
 * @Date: 2022-01-06 10:42:13
 * @LastEditTime: 2022-03-04 15:27:28
-->
<template>
  <div>
    <el-form-item label="信息栏类型：" prop="headDescType">
      <el-radio-group v-model="ruleFormData.headDescType">
        <el-radio :label="2">文案型</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item :label="promotedObjectType === 'PRODUCTTYPE_WECHAT' ? '点击跳转' : '外层素材跳转'" prop="pageType">
      <el-radio-group v-model="ruleFormData.pageType">
        <!-- /推广公众号纯在 -->
        <!-- <el-radio v-if="promotedObjectType === 'PRODUCTTYPE_WECHAT'" :label="6">公众号详情页</el-radio> -->
        <el-radio :label="4">原生推广页</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="按钮文案：" prop="buttonParam">
      <!-- /推广公众号 -->
      <el-radio-group v-if="promotedObjectType === 'PRODUCTTYPE_WECHAT'" v-model="ruleFormData.buttonParam">
        <el-radio label="关注公众号">关注公众号</el-radio>
        <el-radio label="查看详情">查看详情</el-radio>
        <el-radio label="了解更多">了解更多</el-radio>
      </el-radio-group>
      <el-radio-group v-else v-model="ruleFormData.buttonParam">
        <el-radio label="查看详情">查看详情</el-radio>
        <el-radio label="立即咨询">立即咨询</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="标签展示：" prop="mpTagType">
      <el-radio-group v-model="ruleFormData.mpTagType">
        <el-radio :label="0">不启用</el-radio>
      </el-radio-group>
    </el-form-item>

    <!-- -------_ -->
    <el-form-item label="结束页：" prop="isEndPage">
      <el-switch v-model="ruleFormData.isEndPage" @change="endPageFlagChange" />
    </el-form-item>
    <!--  -->
    <el-form-item v-if="ruleFormData.isEndPage" label="结束页类型：" prop="endPageType">
      <el-radio-group v-model="ruleFormData.endPageType">
        <el-radio :label="1">突出头像昵称</el-radio>
        <el-radio :label="2">突出文案</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="" prop="pageTopImgFlag">
      <el-checkbox v-model="ruleFormData.pageTopImgFlag" @change="pageTopImgFlagChange">支持在播放结束后展现广告结束页</el-checkbox>
    </el-form-item>

    <el-form-item v-if="ruleFormData.pageTopImgFlag" label="页面顶部图：" prop="pageTopImgs" :rules="rules.pageTopImgs">
      <div class="justify-start">
        <div v-for="(item, index) in ruleFormData.pageTopImgs" :key="index" class="avatar-uploader  mr-10" @click="materialBoxVisible = true">
          <div v-if="item.image || item.localAssetUrl" class="avatar-uploader" @click="materialBoxVisible = true">
            <img :src="item.image || item.localAssetUrl" style="width: 98px" />
          </div>
          <i v-else class="el-icon-plus avatar-uploader-icon" />
        </div>
      </div>
    </el-form-item>

    <el-form-item label="创意名称：" prop="tname" :rules="rules.tname">
      <el-input v-model="ruleFormData.tname" rows="4" type="textarea" style="width: 400px" placeholder="请输入创意名称" maxlength="60" show-word-limit />
    </el-form-item>

    <div v-if="materialBoxVisible">
      <material-box
        :visible="materialBoxVisible"
        :img-count="3"
        name="creativeGroup"
        :image-list="imageList"
        :attribute="{
          key: 'creativeGroup',
          value: '创意素材',
          count: 3,
          size: 300 * 1024,
          listType: 'image/jpg,image/jpeg,image/png',
          width: 750,
          height: 750
        }"
        :project-id="[+projectId]"
        @setMaterialBox="materialBoxVisible = false"
        @submit="selectImg"
      />
    </div>
  </div>
</template>

<script>
import MaterialBox from '@/components/materialBox'
export default {
  components: {
    MaterialBox
  },
  props: {
    ruleFormData: {
      type: Object,
      default: () => {}
    },
    promotedObjectType: {
      type: String,
      default: ''
    },
    projectId: {
      type: String,
      default: ''
    },
    taskId: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      materialBoxVisible: false,
      imageList: [],
      ruleForm: this.ruleFormData,
      rules: {
        tname: [{ required: true, message: '请输入创意名称', maxlength: 60 }],
        pageTopImgs: [{ validator: this.checkPageTopImgs, required: true, trigger: 'blur' }]
      }
    }
  },

  watch: {
    promotedObjectType: {
      handler(value) {
        if (value === 'PRODUCTTYPE_WECHAT') {
          this.ruleFormData.crtSize = 928
          this.ruleFormData.pageType = 6
          this.ruleFormData.buttonParam = '关注公众号'
        } else {
          this.ruleFormData.crtSize = 927
          this.ruleFormData.pageType = 4
          this.ruleFormData.buttonParam = '查看详情'
        }
      },
      immediate: true
    }
  },

  methods: {
    checkPageTopImgs(rule, value, callback) {
      if (this.ruleFormData.pageTopImgs.some((s) => s === '')) {
        return callback(new Error('请选择3张图片'))
      } else {
        callback()
      }
    },
    pageTopImgFlagChange(value) {
      if (value) {
        this.ruleFormData.pageTopImgs = ['', '', '']
      } else {
        this.ruleFormData.pageTopImgs = []
      }
    },

    endPageFlagChange(e) {
      this.ruleFormData.endPageType = e ? 1 : null
    },

    selectImg(imageList) {
      this.imageList = imageList
      this.ruleFormData.pageTopImgs = ['', '', '']
      imageList.map((item, index) => {
        this.ruleFormData.pageTopImgs[index] = item
      })
    }
  }
}
</script>

<style lang="scss"></style>
