<template>
  <div>
    <Search ref="search" />
    <el-row type="flex" justify="start" class="operate-box mt-20">
      <el-button type="primary" :disabled="shareBatchDisplay" round @click="dialogVisibleButton(undefined)">批量分享</el-button>
      <el-button type="primary" icon="el-icon-plus" round @click="showTbAdd">同步转化追踪</el-button>
    </el-row>
    <div class="table-container">
      <el-table v-loading="loading" stripe class="list-table" :data="data" @selection-change="handleSelectionChange">
        <c-data-empty slot="empty" />
        <el-table-column type="selection" />

        <el-table-column type="index" label="序号" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="appTransId" label="ID" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="transName" label="转化名称" align="left" :show-overflow-tooltip="true" />

        <el-table-column prop="transFrom" label="接入方式" align="left" :formatter="formatterTransFrom" :show-overflow-tooltip="true" />
        <!-- <el-table-column prop="transTypes" label="转化类型	Integer[]" width="100" align="left" /> -->
        x <el-table-column prop="transStatus" label="转化配置状态" align="left" :formatter="formatterTransStatus" :show-overflow-tooltip="true" />
        <el-table-column prop="housekeeperName" label="账号管家" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="accountName" label="账户名称" align="left" :show-overflow-tooltip="true" />
        <el-table-column label="操作" width="100">
          <template slot-scope="{ row }">
            <el-button type="text" size="small" @click="dialogVisibleButton(row)">分享</el-button>
            <!-- <el-button v-if="row.transStatus===2 || row.transStatus==='2'" type="text" size="small" @click="deleteRow(row)">激活</el-button> -->
            <el-button v-if="row.transStatus === 2 || row.transStatus === '2'" type="text" size="small" @click="deleteRow(row)">激活</el-button>
            <!-- <el-button v-if="row.transStatus === 2 || row.transStatus === '2'" type="text" size="small" @click="deleteRow1(row)">激活</el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <c-pagination :page-size.sync="pageInfo.pageSize" :page-no.sync="pageInfo.pageNum" :total="pageInfo.total" :get-data-fun="loadData" />
    <OptionDialog ref="dialog" />
    <tbOptionDialog ref="tbdialog" />
    <activetion ref="activeDialog" />
    <activetion1 ref="activeDialog1" />
    <el-dialog :title="titleContent" width="576px" :visible.sync="dialogVisible" label-position="right" :modal-append-to-body="false" :close-on-press-escape="false" :close-on-click-modal="false">
      <span v-if="!synchRangeShow" style="color: darkgray;">
        仅支持分享至主体
        <font style="color:#409EFF;size:14px">{{ subjectName }}</font>
        下的投放账户
      </span>

      <el-form class="mt-20">
        <el-form-item v-if="synchRangeShow" label="同步范围">
          <el-select v-model="synchRange" filterable placeholder="同步范围" style="width: 400px" @change="accountApponit">
            <el-option v-for="item in trackRange" :key="item.dictValue" trans-from-list :label="item.dictLabel" :value="item.dictValue" />
          </el-select>
        </el-form-item>

        <el-form-item label="账户管家">
          <el-select v-model="housekeeperId" :disabled="allStatus" style="width: 400px" filterable placeholder="请选择账户管家" @change="selectChange">
            <el-option v-for="item in shareHouseKeeper" :key="item.housekeeperId" :label="item.launchAccountName" :value="item.housekeeperId" />
          </el-select>
        </el-form-item>

        <el-form-item label="投放账户">
          <el-select :value="baiduId" :disabled="allStatus" filterable multiple style="width: 400px" placeholder="请选择投放账户" @change="setBaiduId">
            <el-option v-if="synchRangeShow" key="0" label="全部" value="0" />
            <el-option v-for="item in baiduAdv" :key="item.baiduId" :label="item.accountName" :value="item.baiduId" />
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="showAdd()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { PageCommonComponent } from '@/common/mixin'
import { Page } from '@/network/api/assetManagement/assetManagementBaiduTrack'
import Search from './Search'
import OptionDialog from './OptionDialog'
import tbOptionDialog from './tbOptionDialog'
import activetion from './activetion.vue'
import activetion1 from './activetion1.vue'
import { getAccountByHouseId } from '@/network/api/account/accountBaiduAdv'
import { getHouseKeeper } from '@/network/api/account/accountBaiduHousekeeper'
export default {
  name: 'AssetManagementBaiduTrack',
  components: { Search, OptionDialog, tbOptionDialog, activetion, activetion1 },
  mixins: [PageCommonComponent],
  provide: function() {
    return {
      loadData: this.loadData,
      houseKeeper: () => this.houseKeeper,
      transStatusList: () => this.transStatusList,
      transFromList: () => this.transFromList,
      showEdit: this.showEdit
    }
  },
  data() {
    return {
      allStatus: false,
      synchRangeShow: false,
      synchRange: '1',
      housekeeperId: undefined,
      baiduId: undefined,
      titleContent: '',
      loading: false,
      data: [],
      dialogVisible: false,
      houseKeeper: [],
      transStatusList: [],
      transFromList: [],
      multipleSelection: [],
      shareHouseKeeper: [],
      trackIds: '',
      baiduAdv: [],
      trackRange: [],
      shareBatchDisplay: true,
      subjectName: '',
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  mounted() {
    this.getHk()
    this.getDicts('baidu_track_trans_status').then((response) => {
      this.transStatusList = response.data
    })
    this.getDicts('asset_baidu_track_range').then((response) => {
      this.trackRange = response.data
    })
    this.getDicts('baidu_track_trans_from').then((response) => {
      this.transFromList = response.data
    })
    this.loadData()
  },
  methods: {
    setBaiduId(value) {
      if (value.includes('0')) {
        this.baiduId = this.baiduAdv.map((item) => item.baiduId)
      } else {
        this.baiduId = value
      }
    },

    formatterTransFrom(row, column, cellValue, index) {
      var rtstr = '-'
      this.transFromList.forEach((item) => {
        if (item.dictValue === cellValue + '') {
          rtstr = item.dictLabel
        }
      })
      return rtstr
    },
    formatterTransStatus(row, column, cellValue, index) {
      var rtstr = '-'
      this.transStatusList.forEach((item) => {
        if (item.dictValue === cellValue + '') {
          rtstr = item.dictLabel
        }
      })
      return rtstr
    },
    selectChange(val) {
      this.baiduAdv = []
      getAccountByHouseId(val).then((res) => {
        if (res.code === 200) {
          this.baiduAdv = res.data
        }
      })
    },
    accountApponit(val) {
      this.housekeeperId = undefined
      this.baiduId = undefined
      if (val === '1') {
        this.allStatus = true
      }
      if (val === '2') {
        this.allStatus = false
      }
    },
    handleSelectionChange(val) {
      this.shareHouseKeeper = []
      this.subjectName = ''
      this.trackIds = ''
      this.shareBatchDisplay = false
      if (val.length === 0) {
        this.shareBatchDisplay = true
        return
      }
      this.multipleSelection = val
      this.multipleSelection.forEach((item) => {
        var projectName = item.projectName
        var trackId = item.trackId

        if (this.trackIds === '') {
          this.trackIds = trackId
        } else {
          this.trackIds = this.trackIds + ',' + trackId
        }
        if (this.subjectName === '') {
          this.subjectName = projectName
        } else {
          if (this.subjectName !== projectName) {
            this.shareBatchDisplay = true
          }
        }
      })
      if (!this.shareBatchDisplay) {
        this.houseKeeper.forEach((item) => {
          if (item.launchAccountName === this.subjectName) {
            this.shareHouseKeeper.push(item)
          }
        })
      }
    },
    dialogVisibleButton(row) {
      this.allStatus = false
      this.baiduAdv = []
      this.housekeeperId = undefined
      this.baiduId = undefined
      this.synchRangeShow = false
      if (row === undefined) {
        this.titleContent = '批量分享'
        this.dialogVisible = true
      } else {
        this.titleContent = '分享'
        this.handleSelectionChange([row])
        this.dialogVisible = true
        this.shareBatchDisplay = true
      }
    },
    getHk() {
      getHouseKeeper().then((res) => {
        if (res.code === 200) {
          this.houseKeeper = res.data
        }
      })
    },
    loadData(isFirst = false) {
      const searchForm = this.$refs.search.getSearchForm()
      this.doLoadData(isFirst, searchForm)
    },
    doLoadData(firstPage = false, searchForm) {
      firstPage && (this.pageInfo.pageNum = 1)
      const req = { ...searchForm, ...this.pageInfo }
      this.loading = true
      Page(req).then((res) => {
        this.data = res.data.records
        this.pageInfo.total = res.data.total
        this.loading = false
      })
    },
    deleteRow(row) {
      if (row.transFrom === 1 || row.transFrom === '1') {
        this.$refs.activeDialog1.show(true, { ...row })
      } else {
        this.$refs.activeDialog.show(true, { ...row })
      }

      // this.$confirm('激活接口暂未开发', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      //   // this.$message({
      //   //   type: 'info',
      //   //   message: '已通知'
      //   // })
      // }).catch(() => {
      //   // this.$message({
      //   //   type: 'info',
      //   //   message: '已通知'
      //   // })
      // })
    },

    showEdit(row) {
      this.$refs.dialog.show(true, { ...row })
    },
    showAdd() {
      if (this.synchRangeShow) {
        if (this.synchRange !== '1') {
          if (this.housekeeperId === undefined || this.baiduId === undefined) {
            this.$message({
              message: '请选择条件',
              type: 'error'
            })
            return
          }
        }
        this.dialogVisible = false
        this.$refs.tbdialog.show(false, { synchRange: this.synchRange, baiduId: this.baiduId, housekeeperId: this.housekeeperId })
      } else {
        if (this.housekeeperId === undefined || this.baiduId === undefined || !(this.baiduId instanceof Array) || this.baiduId.length === 0) {
          this.$message({
            message: '请选择条件',
            type: 'error'
          })
          return
        }
        this.dialogVisible = false
        this.$refs.dialog.show(false, { trackIds: this.trackIds, baiduId: this.baiduId, housekeeperId: this.housekeeperId })
      }
    },
    showTbAdd() {
      this.synchRange = '1'
      this.allStatus = true
      this.housekeeperId = undefined
      this.baiduId = undefined
      this.titleContent = '同步转化追踪'
      this.synchRangeShow = true
      this.dialogVisible = true
      this.shareHouseKeeper = this.houseKeeper
    }
  }
}
</script>

<style scoped></style>
