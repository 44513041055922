var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "plan-modal",
      staticStyle: { height: "300px", "overflow-y": "auto" },
    },
    [
      _c("el-tree", {
        attrs: {
          data: _vm.treeData,
          "node-key": "id",
          "default-expand-all": "",
          "expand-on-click-node": false,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (row) {
              return _c(
                "span",
                { staticClass: "custom-tree-node" },
                [
                  _c(
                    "el-tooltip",
                    { attrs: { content: row.node.label, placement: "top" } },
                    [
                      _c("span", { staticClass: "label" }, [
                        _vm._v(_vm._s(row.node.label)),
                      ]),
                    ]
                  ),
                  row.node.level !== 1
                    ? _c("span", { staticClass: "handleTree-icon" }, [
                        _c("i", {
                          staticClass: "el-icon-circle-close",
                          on: { click: () => _vm.remove(row.node, row.data) },
                        }),
                      ])
                    : _vm._e(),
                ],
                1
              )
            },
          },
        ]),
      }),
      _c("SelectModal", {
        ref: "modal",
        attrs: {
          "dialog-title": "推广计划",
          "left-title": "投放账户",
          "title-info": {
            title: "选择推广计划",
            leftLevel: "1",
            rightLevel: "1",
          },
          visible: _vm.planModalVisible,
          "left-query-list": _vm.accountBaiduAdv,
          data: _vm.baiduMePlan,
          total: _vm.total,
        },
        on: { onOk: _vm.getSelectPlanData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }