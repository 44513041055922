<!--
 * @Description: 工作区 -> 图片组件
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2022-03-31 10:51:17
-->

<template>
  <section class="image-jl" :style="{ paddingTop: `${detail.paddingTop}px`, paddingBottom: `${detail.paddingBottom}px` }">
    <el-image :src="detail.pureImageUrl">
      <div slot="error" class="image-slot" style="text-align: center">
        <i class="el-icon-picture-outline"></i>
        <p>{{ detail.name }}组件</p>
      </div>
    </el-image>
  </section>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss">
p {
  padding: 0;
  margin: 0;
}
.image-jl {
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: #fff;
  display: flex;
  flex-direction: column;
  i {
    font-size: 60px;
    color: #ccc;
  }
  p {
    margin-top: 10px;
    font-size: 24px;
    color: #ccc;
  }
}
</style>
