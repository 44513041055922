var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "creativity-text" },
      [
        _vm.creativityTextData[0].descriptionContent !== null
          ? _c(
              "div",
              _vm._l(_vm.creativityTextData, function (item) {
                return _c(
                  "div",
                  { key: item.desGroupName, staticClass: "text-box" },
                  [
                    _c("p", { staticClass: "text-name" }, [
                      _vm._v(_vm._s(item.desGroupName)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "text-item" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              content: item.descriptionContent,
                              placement: "top",
                            },
                          },
                          [_c("p", [_vm._v(_vm._s(item.descriptionContent))])]
                        ),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            )
          : _c(
              "div",
              {
                staticStyle: {
                  "text-align": "center",
                  "padding-top": "20px",
                  color: "#909399",
                },
              },
              [_vm._v(" 暂无数据 ")]
            ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "创意文案",
              visible: _vm.textVisible,
              width: "800px",
              "modal-append-to-body": false,
              "destroy-on-close": "",
              "before-close": _vm.handleClose,
            },
          },
          [
            _c(
              "span",
              [
                _c("div", { staticClass: "justify-start" }, [
                  _c("h3", [_vm._v("文案内容")]),
                  _c("p", { staticClass: "ml-20" }, [
                    _vm._v(
                      "文案组数量：" +
                        _vm._s(
                          _vm.creativityTextList
                            ? _vm.creativityTextList.length
                            : 1
                        ) +
                        "组"
                    ),
                  ]),
                ]),
                _c(
                  "el-collapse",
                  {
                    model: {
                      value: _vm.collapse,
                      callback: function ($$v) {
                        _vm.collapse = $$v
                      },
                      expression: "collapse",
                    },
                  },
                  _vm._l(_vm.creativityTextList, function (item, index) {
                    return _c(
                      "el-collapse-item",
                      {
                        key: item.desGroupName,
                        attrs: { name: item.desGroupName },
                      },
                      [
                        _c("template", { slot: "title" }, [
                          _c("div", { staticClass: "justify-sb group-title" }, [
                            _c("p", [_vm._v(_vm._s(item.desGroupName))]),
                            _c("div", { staticClass: "icon" }, [
                              _c("i", {
                                staticClass: "el-icon-document-copy",
                                on: {
                                  click: function ($event) {
                                    return _vm.copyCreativeGroup(index)
                                  },
                                },
                              }),
                              (_vm.creativityTextList
                                ? _vm.creativityTextList.length
                                : 0) > 1
                                ? _c("i", {
                                    staticClass: "el-icon-close",
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteCreativeGroup(index)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticStyle: { "text-align": "center" } }, [
                          _c(
                            "div",
                            { staticClass: "ml-20" },
                            [
                              _c(
                                "el-form",
                                {
                                  attrs: {
                                    model: item,
                                    "label-width": "110px",
                                  },
                                },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                label: "广告文案:",
                                                prop: "descriptionContent",
                                                rules: _vm.rules.text,
                                              },
                                            },
                                            [
                                              _vm.taskSiteSet === 1 ||
                                              _vm.taskSiteSet === 6
                                                ? _c("el-input", {
                                                    staticStyle: {
                                                      width: "400px",
                                                    },
                                                    attrs: {
                                                      type: "textarea",
                                                      rows: 4,
                                                      resize: "none",
                                                      placeholder:
                                                        "请输入文案, 支持换行输入, 最多4行",
                                                    },
                                                    model: {
                                                      value:
                                                        item.descriptionContent,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "descriptionContent",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.descriptionContent",
                                                    },
                                                  })
                                                : _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "80%",
                                                      },
                                                      attrs: {
                                                        "allow-create": "",
                                                        "default-first-option":
                                                          "",
                                                        filterable: "",
                                                        remote: "",
                                                        "reserve-keyword": "",
                                                        placeholder:
                                                          "请输入文案",
                                                        "remote-method":
                                                          _vm.remoteMethod,
                                                        loading: _vm.loading,
                                                      },
                                                      on: {
                                                        change: (value) =>
                                                          _vm.textChange(
                                                            value,
                                                            index
                                                          ),
                                                      },
                                                      model: {
                                                        value:
                                                          item.descriptionContent,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "descriptionContent",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.descriptionContent",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.text_options,
                                                      function (option) {
                                                        return _c("el-option", {
                                                          key: option.textId,
                                                          attrs: {
                                                            label: option.text,
                                                            value: option.text,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "tip ml-10",
                                                  style: {
                                                    color:
                                                      (item.descriptionContent
                                                        ? item
                                                            .descriptionContent
                                                            .length
                                                        : 0) > 30
                                                        ? "#f80000"
                                                        : "#7d7d7d",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      `${
                                                        item.descriptionContent
                                                          ? item
                                                              .descriptionContent
                                                              .length
                                                          : 0
                                                      } / 30`
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      2
                    )
                  }),
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "text-align": "center",
                      "margin-top": "10px",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.addCreativeGroup },
                      },
                      [_vm._v("添加文案组")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c("el-button", { on: { click: _vm.handleClose } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                  [_vm._v("确 定")]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }