<!--
 * @Description: 腾讯广告批量复制
 * @Date: 2022-10-19 10:33:17
-->

<template>
  <div>
    <div class="justify-sb">
      <el-button type="primary" icon="el-icon-plus" round @click="() => (sync_visible = true)">新建任务</el-button>
      <el-button type="primary" round @click="getPage()">刷新</el-button>
    </div>

    <!-- 表格 -->
    <TablePane class="mt-20" :data-source="dataSource" @getPage="getPage">
      <template #status="{ scope }">
        <p>{{ `${scope.row.status === '1' ? '待复制' : scope.row.status === '2' ? '复制中' : '复制完成'}` }}</p>
        <div v-if="scope.row.status === '3'">
          <p>
            <span style="color: green; margin-right: 10px">{{ `成功：${scope.row.copyNumSuccess}` }}</span>
            <span style="color: red">{{ `失败：${scope.row.copyNumFail}` }}</span>
          </p>
        </div>
      </template>
    </TablePane>

    <!-- 广告复制配置 -->
    <el-dialog title="广告复制配置" :visible.sync="sync_visible" width="1020px" :modal-append-to-body="false" destroy-on-close :close-on-press-escape="false" :close-on-click-modal="false" top="100px">
      <el-form ref="ruleForm1" label-width="100px" class="demo-ruleForm">
        <el-form-item label="投放账户" prop="accountIds" :rules="{ required: true, message: '请选择投放账户', trigger: 'blur' }">
          <el-select ref="accountSelect" v-model="accountIds" :filter-method="handleFilter" filterable clearable multiple style="width: 400px" placeholder="请选择投放账户" :multiple-limit="5" @change="changeAccount">
            <el-option v-for="item in adMpAccountList" :key="item.id" :value="item.id" :label="item.corporationName + '(' + item.accountId + ')'"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!--投放账户复制 -->
      <div v-if="accountIdList && accountIdList.length">
        <el-row v-for="(item, index) in accountIdList" :key="index" class="bg-purple-dark mt-20 medisplay">
          <el-form ref="ruleForm" :model="ruleForm[item]" label-width="100px" class="demo-ruleForm">
            <el-col :span="23">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span>{{ setAccount(item) }}</span>
                  <el-button style="float: right; padding: 3px 0" type="text" @click="deleteCard(item)">删除</el-button>
                </div>
                <div>
                  <el-row :gutter="12">
                    <el-col :span="12">
                      <el-form-item label="推广计划" prop="campaignId" :rules="{ required: true, message: '请选择推广计划', trigger: 'blur' }">
                        <search-select-tencent-campaigns
                          v-model="ruleForm[item].campaignId"
                          :account-id="item"
                          :props="{
                            label: 'campaignName',
                            value: 'campaignId'
                          }"
                          style="width: 200px"
                          placeholder="请选择推广计划"
                          @change="(val) => changeCampaigns(val, item)"
                        ></search-select-tencent-campaigns>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="广告" prop="adGroup" :rules="{ required: true, message: '请选择广告', trigger: 'blur' }">
                        <search-select-tencent-adgroup-ids
                          v-model="ruleForm[item].adGroup"
                          :disabled="!ruleForm[item].campaignId"
                          :account-id="item"
                          :campaign-id="ruleForm[item].campaignId"
                          :props="{
                            label: 'adName',
                            value: 'adId'
                          }"
                          style="width: 200px"
                          placeholder="请选择广告"
                          @change="(val) => changeAds(val, item)"
                        ></search-select-tencent-adgroup-ids>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="复制广告数" prop="copyAdNum" :rules="{ required: true, message: '请选择广告', trigger: 'blur' }">
                        <el-input-number v-model="ruleForm[item].copyAdNum" :min="1" :max="20"></el-input-number>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </el-card>
            </el-col>
          </el-form>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm()">立即复制</el-button>
        <el-button @click="resetForm('ruleForm')">取消</el-button>
      </span>
    </el-dialog>

    <!-- 复制详情 -->
    <el-dialog
      title="复制详情"
      :visible.sync="generate_task"
      :modal-append-to-body="false"
      destroy-on-close
      @close="
        () => {
          ;(generate.taskId = 0)
        }
      "
    >
      <!-- <div>
        <el-input v-model="adMpPageManagemen.ghname" style="width: 250px; margin-bottom: 10px; margin-right: 20px" placeholder="请输入账户名称"> </el-input>
        <el-input v-model="adMpPageManagemen.createBy" style="width: 250px; margin-bottom: 10px; margin-right: 20px" placeholder="请输入同步人"> </el-input>
        <el-button slot="append" type="primary" icon="el-icon-search" @click="getRelationDetail(1)">查询</el-button>
      </div> -->
      <el-table :data="generate_task_list" class="list-table">
        <el-table-column property="campaignId" label="计划ID" align="center"></el-table-column>
        <el-table-column property="copyAdId" label="广告ID" align="center"></el-table-column>
        <el-table-column property="adgroupId" label="广告组ID" align="center"></el-table-column>
        <el-table-column property="message" label="详细信息" align="center"></el-table-column>
      </el-table>
      <el-pagination style="text-align: right; margin-top: 10px" background layout="prev, pager, next" :total="generate_task_total" @current-change="getRelationDetail"> </el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import TablePane from '@/components/tablePane.vue'
import { adMarketingApiClientsSelect, replicate, getCopyPage, getGenerateList } from '@/network/api/account/tencentAdvAccount.js'
// import SearchSelect from '../../../../components/searchSelect.vue'
import SearchSelectTencentCampaigns from '../../../../components/searchSelect/searchSelectTencentCampaigns.vue'
import SearchSelectTencentAdgroupIds from '../../../../components/searchSelect/searchSelectTencentAdGroupId.vue'
import { remove } from 'lodash'
import PinyinMatch from 'pinyin-match'

export default {
  components: {
    TablePane,
    // SearchSelect,
    SearchSelectTencentCampaigns,
    SearchSelectTencentAdgroupIds
  },
  data() {
    return {
      rtData: [],
      search: {
        promoteTitle: null,
        createTime: null,
        createBy: null,
        projectId: null,
        productId: 0
      },
      adMpAccountList: [],
      copyOptionsList: [],
      accountIds: [],
      ruleForm: {},
      ruleForm1: {
        // accountIds: null,
        campaignId: null,
        adgroupId: null,
        adId: null,
        copyAdNum: 0
      },
      generate: {
        taskId: 0
      },
      sync_visible: false,
      generate_task: false,
      generate_task_list: [],
      generate_task_total: 0,
      dataSource: {
        data: [],
        cols: [
          {
            label: '投放账户',
            prop: 'accountName',
            width: 550
          },
          {
            label: '创建时间',
            prop: 'createTime'
          },
          {
            label: '创建人',
            prop: 'createBy'
          },
          {
            label: '复制数量',
            prop: 'copyNum',
            handleRow: (index, record) => {
              this.generate.taskId = record.taskId
              this.generate_task = true
              this.getRelationDetail()
            }
          },
          {
            label: '状态',
            prop: 'status',
            isTemplate: true
          }
        ], // 表格的列数据
        isIndex: true, // 列表序号
        loading: true, // loading
        isOperation: false,
        isSelection: false,
        pageData: {
          total: 0 // 总条数
        }
      }
    }
  },

  computed: {
    accountIdList() {
      return this.accountIds
    }
  },

  mounted() {
    this.getPage()
    this.adMarketingAccountSelect()
  },

  methods: {
    // 获取投放账户
    adMarketingAccountSelect() {
      adMarketingApiClientsSelect({}).then((res) => {
        if (res.code === 200) {
          this.adMpAccountList = res.data
          this.copyOptionsList = JSON.parse(JSON.stringify(res.data))
        }
      })
    },

    /**
     * @Description: 下拉框支持模糊搜索
     * @Author: JayShen
     * @param {*} val
     */
    handleFilter(val) {
      try {
        if (val) {
          this.adMpAccountList = this.copyOptionsList
          this.adMpAccountList = this.adMpAccountList.filter((item) => PinyinMatch.match(item.corporationName, val) || PinyinMatch.match(item.accountId, val))
        } else {
          this.adMpAccountList = this.copyOptionsList
        }
      } catch (error) {
        console.error('模糊音下拉框：', error)
      }
    },

    setAccount(id) {
      const accoount = this.adMpAccountList.filter((f) => f.id === id)[0]
      return accoount?.corporationName + '(' + accoount?.accountId + ')'
    },

    changeAccount() {
      this.ruleForm = {}
      const form = JSON.parse(JSON.stringify(this.ruleForm))
      const accounts = this.accountIds
      if (accounts) {
        accounts.forEach((item) => {
          if (form[item]) {
            form[item].accountLocalId = this.ruleForm[item].accountLocalId
            form[item].campaignId = this.ruleForm[item].campaignId
            form[item].adGroup = this.ruleForm[item].adGroup
            form[item].adId = this.ruleForm[item].adId
            form[item].adgroupId = this.ruleForm[item].adgroupId
            form[item].copyAdNum = this.ruleForm[item].copyAdNum
          } else {
            form[item] = {}
            form[item].accountLocalId = item
            form[item].campaignId = ''
            form[item].adGroup = {}
            form[item].adId = null
            form[item].adgroupId = null
            form[item].copyAdNum = 0
          }
        })
      }
      this.ruleForm = JSON.parse(JSON.stringify(form))
      this.$forceUpdate()
    },

    changeCampaigns(value, accounId) {
      // console.log(value, accounId, this.ruleForm)
    },

    changeAds(value, accounId) {
      const form = JSON.parse(JSON.stringify(this.ruleForm))
      if (form[accounId]) {
        if (value) {
          form[accounId].adgroupId = value.adgroupId
          form[accounId].adId = value.adId
        } else {
          form[accounId].adgroupId = null
          form[accounId].adId = null
        }
      }
      this.ruleForm = JSON.parse(JSON.stringify(form))
    },

    deleteCard(accounId) {
      if (accounId) {
        if (this.accountIdList && this.accountIdList.length) {
          remove(this.accountIdList, (item) => {
            return item === accounId
          })
        }
      }
      this.accountIds = JSON.parse(JSON.stringify(this.accountIdList))
      this.changeAccount()
    },

    /**
     * @description 获取复制详情列表
     */
    getRelationDetail(pageNum = 1) {
      this.generate_task_list = []
      this.generate_task_total = 0
      const { generate } = this
      let params = {
        taskId: generate.taskId,
        pageSize: 10,
        pageNum
      }
      getGenerateList({ ...params }).then(({ code, data }) => {
        if (code === 200) {
          this.generate_task_list = data.records
          this.generate_task_total = data.total
        }
      })
    },

    /**
     * @description: 重置数据
     * @param {*} formName ref
     */
    resetForm() {
      this.sync_visible = false
      this.ruleForm = {}
      this.accountIds = []
      this.accountIdList.value = []
    },
    resetFormQuery(formName) {
      this.$refs[formName].resetFields()
      setTimeout(() => {
        this.getPage()
      }, 300)
    },

    /**
     * @description: 复制
     */
    async submitForm() {
      const { ruleForm } = this
      if (!ruleForm) {
        this.$message.error('请选择投放账户！')
        return
      }
      const list = Object.values(ruleForm)
      for (let item of list) {
        if (!item.campaignId) {
          this.$message.error('请选择推广计划！')
          return
        }
        if (!item.adId) {
          this.$message.error('请选择广告！')
          return
        }
      }
      const params = {
        replicateDataItem: list
      }
      const { code } = await replicate(params)
      if (code === 200) {
        this.$message.success('复制成功!')
        this.resetForm()
        setTimeout(() => {
          this.getPage()
        }, 300)
      } else {
        this.$message.error('复制失败!')
      }
    },

    /**
     * @description: 获取列表
     * @param {*} params
     */
    getPage(params = { pageNum: 1, pageSize: 10 }) {
      this.dataSource.loading = true
      getCopyPage({ ...params }).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          this.dataSource.data = records
          this.dataSource.pageData.total = total
          this.dataSource.pageData.pageNum = params.pageNum
          this.dataSource.loading = false
        }
      })
    }
  }
}
</script>

<style lang="scss">
.account-info {
  display: flex;
}
.el-dialog__body {
  background: #f2f4f8;
}
.bg-purple-dark {
  border-radius: 4px;
  //   padding: 12px;
  margin-bottom: 1px;
  //   background: #fff;
}
.medisplay {
  display: flex;
  align-items: center;
}
</style>
