<!--
 * @Description: 文本编辑区
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2022-03-16 09:54:25
-->

<template>
  <section class="section">
    <base-box name="text">
      <template slot="body">
        <div class="setting">
          <p class="w-100">文本内容：</p>
          <div>
            <el-input v-model="detail.content" type="textarea" :rows="15" placeholder="请输入内容"> </el-input>
          </div>
        </div>

        <div>
          <p class="w-100">字符样式：</p>
          <div style="display:flex">
            <div class="mr-10">
              <el-input-number v-model="detail.fontSize" size="small"></el-input-number>
            </div>
            <div class="mr-10">
              <el-dropdown>
                <div class="ldy-bg-color" :style="`background:${detail.fontColor}`"></div>
                <el-dropdown-menu slot="dropdown">
                  <twitter-color :colors="detail.fontColor" style="margin-top: 10px" @change="(value) => (detail.fontColor = value)" />
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="mr-10">
              <el-radio-group v-model="detail.showType" size="small">
                <el-radio-button label="normal">常规</el-radio-button>
                <el-radio-button label="bold">加粗</el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </div>

        <div>
          <p class="w-100">对齐方式：</p>
          <div>
            <el-radio-group v-model="detail.textAlignment" size="small" @change="setImgCount">
              <el-radio-button label="left">左对齐</el-radio-button>
              <el-radio-button label="center">居中对齐</el-radio-button>
              <el-radio-button label="right">右对齐</el-radio-button>
            </el-radio-group>
          </div>
        </div>

        <div>
          <p class="w-100">上边距：</p>
          <div>
            <el-slider v-model="detail.paddingTop" show-input> </el-slider>
          </div>
        </div>

        <div>
          <p class="w-100">下边距：</p>
          <div>
            <el-slider v-model="detail.paddingBottom" show-input> </el-slider>
          </div>
        </div>
      </template>
    </base-box>
  </section>
</template>

<script>
import BaseBox from './base.vue'
import Twitter from '@/components/twitterColor.vue'
export default {
  components: {
    'base-box': BaseBox,
    'twitter-color': Twitter
  },
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    /**
     * @description: 打开资源库
     */
    openMaterialBox() {
      this.$emit('openMaterialBox')
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.update {
  width: 130px !important;
  height: 130px;
  line-height: 130px;
  text-align: center;
  border: 1px solid #edecf2;
  background-color: #fbfafd;
  border-radius: 3px;
  cursor: pointer;
  font-size: 30px;
  color: #7d7d7d;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .avatar {
    width: 130px;
  }
}

.image-updata {
  display: flex !important;
  align-items: flex-start !important;
}

.ldy-bg-color {
  width: 28px;
  height: 28px;
  border: 5px solid #bfc2c7;
  border-radius: 4px;
}

.setting {
  display: flex;
  align-items: flex-start !important;
}

.section {
  .w-100 {
    font-size: 12px;
    margin-right: 10px;
  }
}
</style>
