<!--
 * @Description: 创意文案
 * @Date: 2021-12-06 15:57:16
 * @LastEditTime: 2022-05-19 16:43:23
-->
<template>
  <div>
    <div class="creativity-text">
      <div v-if="creativityTextList.flag">
        <div v-if="['展示Banner图片', '小程序banner广告'].includes(crtSizeLabel)" class="kong">
          {{ `${crtSizeLabel}无需选择文案` }}
        </div>
        <div v-for="(item, index) in creativityTextList.list" :key="item.groupName" class="list-box">
          <div class="justify-sb align-center">
            <p>{{ item.groupName }}</p>
            <i class="el-icon-circle-close close-icon" @click="deleteCreativeGroup(index)"></i>
          </div>
          <ul>
            <li v-for="(value, key) in item.adMpManagementTaskImgVideoENTList[0]" :key="key">
              <p v-if="!['taskTextId', 'assetGroupId'].includes(key)" class="key">{{ creativityTextType[key] }}：</p>
              <!-- <el-tooltip :content="value" placement="top"> -->
              <p v-if="!['taskTextId', 'assetGroupId'].includes(key)" class="value">{{ value }}</p>
              <!-- </el-tooltip> -->
            </li>
          </ul>
        </div>
      </div>
      <div v-else class="kong">
        请选择创意文案
      </div>

      <el-dialog title="创意文案" :visible="visible" width="800px" :modal-append-to-body="false" destroy-on-close :before-close="handleClose">
        <span>
          <div class="justify-sb align-center pt-10 pb-10">
            <div>
              <span style="font-weight: bold">创意内容</span>
              <span class="ml-20">创意组数量：{{ creativityTextList.list.length }}组</span>
            </div>
            <el-button type="primary" size="small" @click="isBatchFun">批量添加</el-button>
          </div>
          <el-collapse v-if="creativityTextList.list.length" v-model="collapse">
            <el-collapse-item v-for="(item, index) in creativityTextList.list" :key="item.groupName" :name="item.groupName">
              <template slot="title">
                <div class="justify-sb group-title">
                  <p>{{ item.groupName }}</p>
                  <div class="icon">
                    <i class="el-icon-document-copy" @click.stop="copyCreativeGroup(index)"></i>
                    <i v-if="creativityTextList.list.length > 1" class="el-icon-close" @click.stop="deleteCreativeGroup(index)"></i>
                  </div>
                </div>
              </template>
              <div style="text-align:center">
                <div class="justify-end">
                  <el-button type="text" @click="textVisible = true">新增文案</el-button>
                </div>
                <el-form :ref="`text-${index}`" :model="item.adMpManagementTaskImgVideoENTList[0]" label-width="110px">
                  <CreativityTextContent :crt-size-label="crtSizeLabel" :creativity-data="creativityData" :item="item.adMpManagementTaskImgVideoENTList[0]" :project-id="projectId" />
                </el-form>
              </div>
            </el-collapse-item>
          </el-collapse>
          <div style="text-align:center; margin-top: 10px">
            <el-button @click="addCreativeGroup">添加创意组</el-button>
          </div>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" :disabled="!isSearchLock" @click="onSubmit">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="新增文案" :visible.sync="textVisible" width="600px" :modal-append-to-body="false" destroy-on-close>
        <span>
          <el-form ref="ruleData" :inline="true" :model="ruleData" class="demo-form-inline mt-20">
            <el-form-item label="项目名称:" prop="projectId" :rules="{ required: true, message: '请选择项目', trigger: 'change' }">
              <el-select v-model="ruleData.projectId" style="width:400px" @change="(value) => ((selectProjectId = value), (ruleData.productIds = -1))">
                <el-option v-for="(item, index) in projectList" :key="index" :label="item.projectName" :value="item.bsProjectId + ''"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="产品名称:" :rules="{ required: true, message: '请选择产品', trigger: 'change' }">
              <el-select v-model="ruleData.productIds" style="width:400px" @change="(value) => (selectProductId = value)">
                <el-option label="不限" :value="-1"></el-option>
                <el-option v-for="(item, index) in productListed" :key="index" :label="item.productName" :value="item.bsProductId"></el-option>
              </el-select>
            </el-form-item>

            <br />
            <el-form-item label="文案内容" prop="textarea" :rules="{ required: true, message: '请选择产品', trigger: 'change' }">
              <el-input v-model="ruleData.textarea" type="textarea" style="width: 400px" :rows="6" placeholder="请输入文案，多个文案请换行！"> </el-input>
              <p class="tip">
                <span :style="{ color: (ruleData.textarea ? ruleData.textarea.split('\n').length : 0) > 20 ? 'red' : '#7d7d7d' }">{{ ruleData.textarea ? ruleData.textarea.split('\n').length : 1 }}</span>
                /20. 手动换行可添加多条文案
              </p>
            </el-form-item>
          </el-form>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="textVisible = false">取 消</el-button>
          <el-button type="primary" :disabled="!isSearchLock" @click="addTextSubmit">确 定</el-button>
        </span>
      </el-dialog>

      <CreativityBatchAddTextContent :is-batch="isBatch" :crt-size-label="crtSizeLabel" :creativity-data="creativityData" :project-id="projectId" @setIsBatch="setIsBatch" @onOk="batchSubmit" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { saveText, updateText, deleteText } from '@/network/api/advManagement/advManagementWatch'
import { addTxt } from '@/network/api/api-material'
import { creativityTextApi, creativityTextType, creativityDataListByCreateGroup } from '../util'
import CreativityTextContent from './creativityTextContent.vue'
import CreativityBatchAddTextContent from './creativityBatchAddTextContent'
import accountUin_tencent from '@/mixin/accountUin_tencent.js'
export default {
  inject: ['closeText'],
  components: {
    CreativityTextContent,
    CreativityBatchAddTextContent
  },
  mixins: [accountUin_tencent],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    /** 广告位置 */
    crtSizeLabel: {
      type: String,
      default: '朋友圈'
    },
    creativityData: {
      type: Object,
      default: () => {}
    },
    formData: {
      type: Object,
      default: () => {}
    },
    creativityTextDataTemplate: {
      type: Array,
      default: () => []
    },
    taskId: {
      type: Number,
      default: 0
    },
    projectId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isBatch: false, // 是否批量添加
      creativityTextType,
      ruleData: {
        projectId: null,
        productIds: -1,
        textarea: null
      },
      textVisible: false,
      isSearchLock: true,
      setIndex: 0,
      creativityTextList: {},
      creativityTextDiv: [],
      collapse: ['创意组01', '创意组02', '创意组03']
    }
  },

  computed: {
    ...mapGetters(['userInfo'])
  },

  watch: {
    formData: {
      handler(value) {
        this.creativityTextList = value
      },
      immediate: true
    }
  },

  methods: {
    // 批量添加
    isBatchFun() {
      this.isBatch = true
    },

    setIsBatch(value) {
      this.isBatch = value
    },

    /**
     * @description 打开图片素材选择框
     */
    setMaterialBox(index = 0) {
      this.setIndex = index
      this.materialBoxVisible = true
    },

    /**
     * @description 打开视频素材选择框
     */
    setMaterialVideoBox(index = 0) {
      this.setIndex = index
      this.materialVideoVisible = true
    },

    /**
     * @description 批量添加成功
     */
    batchSubmit(data) {
      data.forEach((element, index) => {
        this.add(element, index)
      })
    },

    /**
     * @description 添加创素材组
     */

    add(info, index) {
      let obj = {}
      if (info) {
        obj = info
      } else {
        for (const key in this.creativityTextDataTemplate[0]) {
          if (Object.hasOwnProperty.call(this.creativityTextDataTemplate[0], key)) {
            obj[key] = ''
          }
        }
      }

      let len = null
      let num = null
      console.log(this.creativityTextList.list)
      if (info) {
        this.creativityTextList.list = this.creativityTextList.list.filter((f) => {
          let values = Object.values(f.adMpManagementTaskImgVideoENTList[0])
          if (values.every((e) => e === '')) {
            return false
          } else {
            return true
          }
        })
      }
      len = this.creativityTextList.list.length
      num = len === 0 ? 1 : +this.creativityTextList.list[len - 1].groupName.substring(3) + 1

      this.creativityTextList.list.push({
        groupName: `创意组0${num}`,
        taskId: this.taskId,
        type: 'text',
        textType: creativityDataListByCreateGroup[this.crtSizeLabel],
        adMpManagementTaskImgVideoENTList: [obj]
      })
    },

    addCreativeGroup() {
      this.validateForm(this.add)
    },

    validateForm(callback) {
      let refName = `text-${this.creativityTextList.list.length - 1}`
      this.$refs[refName][0].validate((valid) => {
        if (valid) {
          callback()
        } else {
          this.$message.error('文案未填写完整!')
          return false
        }
      })
    },

    /**
     * @description 删除创素材组
     */
    async deleteCreativeGroup(index) {
      let item = this.creativityTextList.list[index]
      let taskTextId = item['adMpManagementTaskImgVideoENTList'][0].taskTextId
      if (taskTextId) {
        const { code } = await deleteText([taskTextId], creativityTextApi[this.crtSizeLabel])
        if (code === 200) {
          this.$emit('byTaskIdGetAllFun')
          this.$message.success('删除成功!')
        }
      } else {
        this.creativityTextList.list.splice(index, 1)
      }
    },

    /**
     * @description 复制创素材组
     */
    copyCreativeGroup(index) {
      let refName = `text-${this.creativityTextList.list.length - 1}`
      let len = this.creativityTextList.list.length
      let num = +this.creativityTextList.list[len - 1].groupName.substring(3)
      this.$refs[refName][0].validate((valid) => {
        if (valid) {
          let copyData = JSON.parse(JSON.stringify(this.creativityTextList.list[index]))
          copyData.groupName = `创意组0${num + 1}`
          copyData.assetGroupId = null
          this.creativityTextList.list.push(copyData)
        } else {
          this.$message.error('文案未填写完整!')
          return false
        }
      })
    },

    /**
     * @description 创意素材保存
     */

    submit() {
      if (this.isSearchLock) {
        this.isSearchLock = false
        let lists = this.creativityTextList.list

        let createParams = []
        let updateParams = []
        lists.map((item) => {
          if (item.assetGroupId) {
            updateParams.push(item.adMpManagementTaskImgVideoENTList[0])
          } else {
            createParams.push(item)
          }
        })
        if (createParams.length) {
          saveText({ lists: createParams }, creativityTextApi[this.crtSizeLabel]).then((res) => {
            if (res.code === 200) {
              this.isSearchLock = true
              this.handleClose()
            }
          })
        }
        if (updateParams.length) {
          updateText({ lists: updateParams }, creativityTextApi[this.crtSizeLabel]).then((res) => {
            if (res.code === 200) {
              this.isSearchLock = true
              this.handleClose()
            }
          })
        }
        this.$message.success('素材保存成功!')
      }
    },

    onSubmit() {
      this.validateForm(this.submit)
    },

    addTextSubmit() {
      this.$refs['ruleData'].validate((valid) => {
        if (valid) {
          const { projectId, productIds, textarea } = this.ruleData
          let params = {}
          let textareaArr = textarea.split('\n')
          if (textareaArr.length > 20) return this.$message.error('一次最多添加20条文案')
          params.productId = productIds
          params.projectId = projectId
          params.adminId = this.userInfo.userid
          params.nickname = this.userInfo.username
          textareaArr.map((item) => {
            addTxt({ ...params, text: item }).then((res) => {
              this.textVisible = false
              this.$refs['ruleData'].resetFields()
            })
          })
          this.$message.success('新增素材成功')
        }
      })
    },

    handleClose() {
      this.closeText()
      this.$emit('byTaskIdGetAllFun')
    }
  }
}
</script>

<style lang="scss">
p,
ul,
li {
  margin: 0;
  padding: 0;
}
.creativity-text {
  .close-icon {
    margin-bottom: 10px;
    cursor: pointer;
    &:hover {
      font-weight: 800;
      font-size: 15px;
    }
  }
  height: 300px;
  overflow-y: auto;
  .el-dialog__body {
    padding-top: 0px !important;
  }
  .group-title {
    width: 100%;
    .icon {
      i {
        margin-right: 10px;
      }
    }
  }

  .el-collapse-item__header {
    font-size: 16px;
    font-weight: bold;
  }

  .el-tabs .el-tabs__item.is-active {
    border-top: solid 0px #409eff;
  }

  .list-box {
    margin-bottom: 8px;
    border-bottom: 1px rgb(231, 229, 229) solid;

    ul {
      li {
        display: flex;
        justify-content: flex-start;
        p {
          font-size: 13px;
          line-height: 28px;
        }
        .key {
          white-space: nowrap;
        }
        .value {
          color: rgb(136, 136, 136);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  .list-box:last-child {
    border-bottom: 1px transparent solid !important;
  }
}
</style>
