var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "append-to-body": true,
        title: _vm.dialogTitle,
        "destroy-on-close": "",
        width: "680px",
        top: "20vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.onCloseDialog,
      },
    },
    [
      _c(
        "span",
        {
          staticClass: "dialog-materialName",
          attrs: { slot: "materialName" },
          slot: "materialName",
        },
        [_vm._v(_vm._s(_vm.dialogTitle))]
      ),
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "create-video-box",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "110px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "专辑", prop: "albumId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "请选择专辑",
                  },
                  on: { change: _vm.handleAlbum },
                  model: {
                    value: _vm.form.albumId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "albumId", $$v)
                    },
                    expression: "form.albumId",
                  },
                },
                _vm._l(_vm.albumList, function (itm, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { label: itm.albumName, value: itm.albumId },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "素材组", prop: "materialGroupId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    disabled: !_vm.form.albumId,
                    filterable: "",
                    clearable: "",
                    placeholder: "请选择素材组",
                  },
                  model: {
                    value: _vm.form.materialGroupId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "materialGroupId", $$v)
                    },
                    expression: "form.materialGroupId",
                  },
                },
                _vm._l(_vm.materialGroupList, function (itm, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: {
                      label: itm.materialGroupName,
                      value: itm.materialGroupId,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "关键词", prop: "keyword" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入图文标题" },
                model: {
                  value: _vm.form.keyword,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "keyword", $$v)
                  },
                  expression: "form.keyword",
                },
              }),
            ],
            1
          ),
          _vm.type === "image"
            ? _c(
                "el-form-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("div", [
                      _c("span", { staticStyle: { color: "#F56C6C" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(" 图片(" + _vm._s(_vm.images.length) + "/25)"),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "form-image-list" },
                    [
                      _vm._l(_vm.images, function (item, index) {
                        return _c("div", { key: index }, [
                          _c("div", { staticClass: "form-image-list-cell" }, [
                            _c("img", { attrs: { src: item.url, alt: "" } }),
                            _c(
                              "div",
                              {
                                staticClass: "form-image-list-cell-del",
                                on: {
                                  click: function ($event) {
                                    return _vm.onClickdelImage(index)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]),
                        ])
                      }),
                      _c(
                        "el-upload",
                        {
                          staticClass: "form-image-upload",
                          attrs: {
                            action: "fakeaction",
                            "list-type": "picture-card",
                            accept: ".png,.jpg,.jpeg,.gif",
                            multiple: true,
                            "show-file-list": false,
                            "http-request": _vm.uploadImage,
                          },
                        },
                        [_c("i", { staticClass: "el-icon-plus" })]
                      ),
                    ],
                    2
                  ),
                  _c("span", { staticStyle: { "font-size": "12px" } }, [
                    _vm._v("支持格式：png、jpg、jpeg、gif"),
                  ]),
                ],
                2
              )
            : _vm._e(),
          _vm.type === "video"
            ? _c(
                "el-form-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("div", [
                      _c("span", { staticStyle: { color: "#F56C6C" } }, [
                        _vm._v("*"),
                      ]),
                      _vm._v(" 视频(" + _vm._s(_vm.videos.length) + "/20)"),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "form-image-list" },
                    [
                      _vm._l(_vm.videos, function (item, index) {
                        return _c("div", { key: index }, [
                          _c(
                            "div",
                            { staticClass: "form-image-list-cell" },
                            [
                              _c("el-progress", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.status === "uploading",
                                    expression: "item.status === 'uploading'",
                                  },
                                ],
                                staticStyle: { margin: "45px 10px 0 10px" },
                                attrs: {
                                  "text-inside": true,
                                  "stroke-width": 14,
                                  percentage: item.progress,
                                  status: item.progress ? "success" : "warning",
                                },
                              }),
                              _c("video", { attrs: { src: item.url } }),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.status !== "uploading",
                                      expression: "item.status !== 'uploading'",
                                    },
                                  ],
                                  staticClass: "form-image-list-cell-del",
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      type: "primary",
                                      circle: "",
                                      icon: "el-icon-delete",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onClickdelVideo(index)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ])
                      }),
                      _vm.videos.length < 20
                        ? _c(
                            "el-upload",
                            {
                              staticClass: "form-image-upload",
                              attrs: {
                                id: "uploa-video",
                                action: "fakeaction",
                                accept: ".mp4,.ogg,.flv,.avi,.wmv,.rmvb,.mov",
                                "list-type": "picture-card",
                                multiple: true,
                                limit: _vm.videoLimit,
                                "show-file-list": false,
                                "before-upload": _vm.beforeUploadVideo,
                                "on-exceed": _vm.handleExceedVideo,
                              },
                            },
                            [_c("i", { staticClass: "el-icon-plus" })]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "btn", on: { click: _vm.closeModal } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: {
                type: "primary",
                loading: _vm.isLoading || _vm.fileUploading,
                disabled: _vm.isLoading,
              },
              on: {
                click: function ($event) {
                  return _vm.clickConfirm("form")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }