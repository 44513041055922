var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "table-template",
      attrs: {
        title: "回传日志",
        visible: _vm.visible,
        "append-to-body": true,
        width: "1150px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        close: function ($event) {
          _vm.visible = false
        },
      },
    },
    [
      _c(
        "el-table",
        { staticClass: "list-table", attrs: { data: _vm.logList } },
        [
          _c("el-table-column", {
            attrs: {
              property: "id",
              label: "序号",
              align: "center",
              width: "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              property: "return_time",
              label: "回传时间",
              align: "center",
              width: "170",
            },
          }),
          _c("el-table-column", {
            attrs: {
              property: "action_type",
              label: "转化目标",
              align: "center",
              width: "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.action_type === "ADD_TO_CART"
                      ? _c("span", [_vm._v("添加购物车")])
                      : scope.row.action_type === "PURCHASE"
                      ? _c("span", [_vm._v("付费")])
                      : scope.row.action_type === "COMPLETE_ORDER"
                      ? _c("span", [_vm._v("下单")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "数据源信息", align: "center", width: "250" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("p", [_vm._v("APPID: " + _vm._s(scope.row.data_appid))]),
                    _c("p", [_vm._v("ID: " + _vm._s(scope.row.data_id))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "小程序信息", align: "center", width: "250" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("p", [_vm._v("APPID: " + _vm._s(scope.row.mini_appid))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              property: "return_status",
              label: "状态",
              align: "center",
              width: "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.return_status === 1
                      ? _c("span", { staticStyle: { color: "#67C23A" } }, [
                          _vm._v("成功"),
                        ])
                      : _c("span", { staticStyle: { color: "#F56C6C" } }, [
                          _vm._v("失败"),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              property: "return_user",
              label: "操作人",
              align: "center",
              width: "150",
            },
          }),
        ],
        1
      ),
      _c("el-pagination", {
        staticStyle: { "text-align": "right", "margin-top": "10px" },
        attrs: {
          background: "",
          layout: "prev, pager, next",
          total: _vm.total,
          "current-page": _vm.pageNum,
        },
        on: { "current-change": _vm.getCallBackLog },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }