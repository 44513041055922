var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.crtSizeLabel === "朋友圈"
        ? _c(
            "el-form-item",
            {
              attrs: {
                prop: "circleOfFriendsText",
                label: "文案:",
                rules: _vm.rules.circleOfFriendsText,
              },
            },
            [
              _c("SearchSelectAsync", {
                staticStyle: { width: "510px" },
                attrs: { placeholder: "请输入朋友圈文案", "max-length": 40 },
                model: {
                  value: _vm.item.circleOfFriendsText,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "circleOfFriendsText", $$v)
                  },
                  expression: "item.circleOfFriendsText",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.crtSizeLabel === "优雅横版大图"
        ? _c(
            "el-form-item",
            {
              attrs: {
                prop: "infoBarText",
                label: "信息栏文案:",
                rules: _vm.rules.infoBarText,
              },
            },
            [
              _c("SearchSelectAsync", {
                staticStyle: { width: "510px" },
                attrs: { placeholder: "请输入信息栏文案" },
                model: {
                  value: _vm.item.infoBarText,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "infoBarText", $$v)
                  },
                  expression: "item.infoBarText",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.crtSizeLabel === "优雅横版大图"
        ? _c(
            "el-form-item",
            {
              attrs: {
                prop: "graphicTextTitle",
                label: "图文标题:",
                rules: _vm.rules.graphicTextTitle,
              },
            },
            [
              _c("SearchSelectAsync", {
                staticStyle: { width: "510px" },
                attrs: { placeholder: "请输入图文标题" },
                model: {
                  value: _vm.item.graphicTextTitle,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "graphicTextTitle", $$v)
                  },
                  expression: "item.graphicTextTitle",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.crtSizeLabel === "优雅横版大图"
        ? _c(
            "el-form-item",
            {
              attrs: {
                label: "广告文案:",
                prop: "advTextOne",
                rules: _vm.rules.advTextOne,
              },
            },
            [
              _c("SearchSelectAsync", {
                staticStyle: { width: "510px" },
                attrs: { placeholder: "请输入首行文案", "max-length": 16 },
                model: {
                  value: _vm.item.advTextOne,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "advTextOne", $$v)
                  },
                  expression: "item.advTextOne",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.crtSizeLabel === "优雅横版大图"
        ? _c(
            "el-form-item",
            {
              attrs: {
                label: "",
                prop: "advTextTwo",
                rules: _vm.rules.advTextTwo,
              },
            },
            [
              _c("SearchSelectAsync", {
                staticStyle: { width: "510px" },
                attrs: { placeholder: "请输入次行文案", "max-length": 16 },
                model: {
                  value: _vm.item.advTextTwo,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "advTextTwo", $$v)
                  },
                  expression: "item.advTextTwo",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.crtSizeLabel === "优雅横版大图"
        ? _c(
            "el-form-item",
            {
              attrs: {
                prop: "advTextThree",
                label: "",
                rules: _vm.rules.advTextThree,
              },
            },
            [
              _c("SearchSelectAsync", {
                staticStyle: { width: "510px" },
                attrs: {
                  placeholder: "请输入第三行文案(选填)",
                  "max-length": 16,
                },
                model: {
                  value: _vm.item.advTextThree,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "advTextThree", $$v)
                  },
                  expression: "item.advTextThree",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.crtSizeLabel === "沉浸式横版视频"
        ? _c(
            "el-form-item",
            {
              attrs: {
                prop: "adCardCopyText",
                label: "广告卡片文案:",
                rules: _vm.rules.adCardCopyText,
              },
            },
            [
              _c("SearchSelectAsync", {
                staticStyle: { width: "510px" },
                attrs: { placeholder: "请输入广告卡片文案" },
                model: {
                  value: _vm.item.adCardCopyText,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "adCardCopyText", $$v)
                  },
                  expression: "item.adCardCopyText",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.crtSizeLabel === "沉浸式横版视频"
        ? _c(
            "el-form-item",
            {
              attrs: {
                prop: "infoBarText",
                label: "信息栏文案:",
                rules: _vm.rules.infoBarText,
              },
            },
            [
              _c("SearchSelectAsync", {
                staticStyle: { width: "510px" },
                attrs: { placeholder: "请输入信息栏文案" },
                model: {
                  value: _vm.item.infoBarText,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "infoBarText", $$v)
                  },
                  expression: "item.infoBarText",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.crtSizeLabel === "沉浸式横版视频"
        ? _c(
            "el-form-item",
            {
              attrs: {
                label: "广告文案:",
                prop: "advTextOne",
                rules: _vm.rules.advTextOne,
              },
            },
            [
              _c("SearchSelectAsync", {
                staticStyle: { width: "510px" },
                attrs: { placeholder: "请输入首行文案", "max-length": 16 },
                model: {
                  value: _vm.item.advTextOne,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "advTextOne", $$v)
                  },
                  expression: "item.advTextOne",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.crtSizeLabel === "沉浸式横版视频"
        ? _c(
            "el-form-item",
            {
              attrs: {
                label: "",
                prop: "advTextTwo",
                rules: _vm.rules.advTextTwo,
              },
            },
            [
              _c("SearchSelectAsync", {
                staticStyle: { width: "510px" },
                attrs: { placeholder: "请输入次行文案", "max-length": 16 },
                model: {
                  value: _vm.item.advTextTwo,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "advTextTwo", $$v)
                  },
                  expression: "item.advTextTwo",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.crtSizeLabel === "沉浸式横版视频"
        ? _c(
            "el-form-item",
            {
              attrs: {
                rules: _vm.rules.advTextThree,
                label: "",
                prop: "advTextThree",
              },
            },
            [
              _c("SearchSelectAsync", {
                staticStyle: { width: "510px" },
                attrs: {
                  placeholder: "请输入第三行文案(选填)",
                  "max-length": 16,
                },
                model: {
                  value: _vm.item.advTextThree,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "advTextThree", $$v)
                  },
                  expression: "item.advTextThree",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.crtSizeLabel === "沉浸式横版视频" && _vm.creativityData.isEndPage
        ? _c(
            "el-form-item",
            {
              attrs: {
                label: "结束页文案:",
                prop: "tailTextTitle",
                rules: _vm.rules.tailTextTitle,
              },
            },
            [
              _c("SearchSelectAsync", {
                staticStyle: { width: "510px" },
                attrs: { placeholder: "请输入结束页文案" },
                model: {
                  value: _vm.item.tailTextTitle,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "tailTextTitle", $$v)
                  },
                  expression: "item.tailTextTitle",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.crtSizeLabel === "公众号文章底部图片"
        ? _c(
            "el-form-item",
            {
              attrs: {
                label: "信息栏文案:",
                prop: "infoBarText",
                rules: _vm.rules.infoBarText,
              },
            },
            [
              _c("SearchSelectAsync", {
                staticStyle: { width: "510px" },
                attrs: { placeholder: "请输入信息栏文案" },
                model: {
                  value: _vm.item.infoBarText,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "infoBarText", $$v)
                  },
                  expression: "item.infoBarText",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.crtSizeLabel === "公众号文章底部视频"
        ? _c(
            "el-form-item",
            {
              attrs: {
                label: "信息栏文案:",
                prop: "infoBarText",
                rules: _vm.rules.infoBarText,
              },
            },
            [
              _c("SearchSelectAsync", {
                staticStyle: { width: "510px" },
                attrs: { placeholder: "请输入信息栏文案" },
                model: {
                  value: _vm.item.infoBarText,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "infoBarText", $$v)
                  },
                  expression: "item.infoBarText",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.crtSizeLabel === "公众号文章底部视频" && _vm.creativityData.isEndPage
        ? _c(
            "el-form-item",
            {
              attrs: {
                label: "结束页文案:",
                prop: "tailTextTitle",
                rules: _vm.rules.tailTextTitle,
              },
            },
            [
              _c("SearchSelectAsync", {
                staticStyle: { width: "510px" },
                attrs: { placeholder: "请输入结束页文案" },
                model: {
                  value: _vm.item.tailTextTitle,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "tailTextTitle", $$v)
                  },
                  expression: "item.tailTextTitle",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.crtSizeLabel === "公众号文章中部"
        ? _c(
            "el-form-item",
            {
              attrs: {
                prop: "text",
                label: "信息栏文案:",
                rules: _vm.rules.text,
              },
            },
            [
              _c("SearchSelectAsync", {
                staticStyle: { width: "510px" },
                attrs: { placeholder: "请输入信息栏文案" },
                model: {
                  value: _vm.item.text,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "text", $$v)
                  },
                  expression: "item.text",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }