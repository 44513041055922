<!--
 * @Author: Zeng
 * @Date: 2023-07-11 15:50:04
 * @LastEditTime: 2023-07-28 15:48:34
-->
<template>
  <div class="projection-container">
    <div class="projection-header"><span>汇总数据</span></div>
    <div class="projection-content">
      <el-row :gutter="50">
        <el-col :span="6">
          <div class="projection-item-header">
            <span class="title">产品新增</span>
            <SearchDate v-model="product.dates" />
          </div>
          <el-table v-loading="product.loading" class="list-table" :data="product.list" stripe size="small" height="300">
            <el-empty slot="empty" description="暂无数据"></el-empty>
            <el-table-column label="日期" prop="showDate" align="center"></el-table-column>
            <el-table-column label="新增数" prop="addNum" align="center"></el-table-column>
            <el-table-column label="总数" prop="sumNum" align="center"></el-table-column>
          </el-table>
        </el-col>

        <el-col :span="18">
          <div class="projection-item-header">
            <span class="title">部门投测</span>
            <div class="item-search">
              <el-select v-model="dept.type" size="mini" style="width: 100px; margin-right: 10px;"
                @change="handleChangeType">
                <el-option label="汇总" :value="1"></el-option>
                <el-option label="部门细分" :value="2"></el-option>
              </el-select>
              <el-cascader v-if="dept.type === 2" ref="cascader" v-model="dept.deptId" :options="deptList"
                :props="cascaderProp" :show-all-levels="false" filterable placeholder="请选择部门" size="mini"
                style="width: 120px;margin-right: 10px;" @change="handleChangeDept"></el-cascader>
              <SearchDate v-model="dept.dates" />
            </div>
          </div>
          <el-table v-loading="dept.loading" class="list-table" :data="dept.list" stripe size="small">
            <el-empty slot="empty" description="暂无数据"></el-empty>
            <el-table-column label="日期" prop="showDate" align="center"></el-table-column>
            <el-table-column label="部门" prop="deptName" align="center"></el-table-column>
            <el-table-column label="新增投测" prop="newAddLaunchTestNum" align="center"></el-table-column>
            <el-table-column label="待测试" prop="waitTestNum" align="center"></el-table-column>
            <el-table-column label="测试中" prop="progressTest" align="center"></el-table-column>
            <el-table-column label="已完成" prop="completeNum" align="center"></el-table-column>
            <el-table-column label="新增起量" prop="newAddAmount" align="center"></el-table-column>
            <el-table-column label="累计起量" prop="sumAmount" align="center"></el-table-column>
            <el-table-column label="累计起量率" prop="sumAmountRate" align="center">
              <template slot-scope="{row}">{{ row.sumAmountRate }}%</template>
            </el-table-column>
          </el-table>
          <div class="page">
            <paginations :total="dept.total" :page.sync="dept.pageNum" :limit.sync="dept.pageSize"
              layout="prev, pager, next" @pagination="getDeptPage" small />
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import SearchDate from './SearchDate'
import { staticsProductAdd, staticsDeptLaunch } from '@/network/api/toolManagement/api-product-test'
export default {
  components: {
    SearchDate
  },
  props: {
    deptList: Array
  },
  data() {
    return {
      cascaderProp: {
        emitPath: false,
        checkStrictly: true,
        value: 'departmentId',
        label: 'departmentName',
        children: 'children'
      },
      product: {
        list: [],
        loading: false,
        total: 0,
        pageSize: 500,
        pageNum: 1,
        dates: []
      },
      dept: {
        list: [],
        loading: false,
        total: 0,
        pageSize: 5,
        pageNum: 1,
        type: 1,
        deptId: '',
        dates: []
      }
    }
  },

  watch: {
    'product.dates': {
      handler() {
        this.getProductPage()
      },
      deep: true
    },
    'dept.dates': {
      handler() {
        this.resetDeptPage()
      },
      deep: true
    }
  },

  mounted() {},
  methods: {
    // 选中部门
    handleChangeDept() {
      this.$refs.cascader.dropDownVisible = false
      this.resetDeptPage()
    },

    // 选中type
    handleChangeType(value) {
      if (value === 1) {
        this.dept.deptId = ''
        this.resetDeptPage()
      }
    },

    // 重置部门数据
    resetDeptPage() {
      this.dept.pageNum = 1
      this.getDeptPage()
    },

    getProductPage() {
      const { pageNum, pageSize, dates } = this.product
      this.product.loading = true
      staticsProductAdd(dates, pageNum, pageSize).then(({ code, data }) => {
        if (data.code === 200) {
          const { records, total } = data.data
          this.product.list = records || []
          this.product.total = total
        }
      }).finally(() => {
        this.product.loading = false
      })
    },

    getDeptPage() {
      const { pageNum, pageSize, dates, deptId } = this.dept
      let params = {
        pageNum,
        pageSize,
        deptId,
        dates
      }
      this.dept.loading = true
      staticsDeptLaunch(params).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          this.dept.list = records || []
          this.dept.total = total
        }
      }).finally(() => {
        this.dept.loading = false
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
