<template>
  <div>
    <!-- 列表查询 -->
    <el-form ref="search" :inline="true" :model="search" class="search-form search-form-4">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="任务名称" prop="name">
            <el-input v-model="search.name" placeholder="请输入任务名称" style="width: 100%"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="创建人" prop="createBy">
            <el-input v-model="search.createBy" placeholder="请输入创建人" style="width: 100%"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="创建时间" prop="createTime">
            <el-date-picker v-model="search.createTime" style="width: 100%" type="daterange" prange-separator="至"
              start-placeholder="开始时间" value-format="yyyy-MM-dd" end-placeholder="结束时间"> </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="任务状态" prop="status">
            <el-select v-model="search.status" style="width: 100%">
              <el-option label="全部" value=""></el-option>
              <el-option label="配置中" :value="2"></el-option>
              <el-option label="待提交" :value="3"></el-option>
              <el-option label="提交中" :value="4"></el-option>
              <el-option label="提交完成" :value="5"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item>
            <el-button type="primary" round @click="onSubmit">查 询</el-button>
            <el-button type="primary" round @click="resetForm('ruleForm')">重 置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div style="text-align:left " class="mb-15">
      <router-link :to="{ name: 'creatVideoTask' }" tag="span">
        <el-button type="primary" icon="el-icon-plus" round> 新建任务 </el-button>
      </router-link>
    </div>

    <div>
      <el-table v-loading="list.loading" :data="list.data" stripe class="list-table"
        :class="{ 'no-data': !list.data || !list.data.length }" style="width: 100%;">
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="id" label="ID" width="100"></el-table-column>
        <el-table-column prop="name" label="任务名称"></el-table-column>
        <el-table-column prop="createBy" label="创建人"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column prop="" label="数量">
          <template slot-scope="{row}">
            <span>{{ row.planCount }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="" width="180" label="状态">
          <template slot-scope="{row}">
            <div class="status">
              <p style="font-size: 11px; font-weight: 400"
                :style="{ 'color': [0, 5].includes(row.status) ? (row.planFailCount == 0 ? 'green' : 'red') : '' }">
                {{ statusList[row.status] }}</p>
              <div v-if=" [0, 5].includes(row.status) " class="status-num">
                <p style="font-size: 11px">
                  成功：<span style="color:green">{{ row.planSuccessCount }}</span>
                </p>
                <el-tooltip :content="row.failMsg" placement="top">
                  <p style="font-size: 11px">
                    失败：<span style="color:red">{{ row.planFailCount }}</span>
                  </p>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作">
          <template slot-scope="{row}">
            <template v-if="row.status == 2 || row.status == 3">
              <el-button type="text" @click="handleGoEdit(row)">
                <el-tooltip content="继续" placement="top">
                  <i class="el-icon-edit" style="font-size: 16px"></i>
                </el-tooltip>
              </el-button>
              <el-button type="text" class="red" @click="deleteTask(row.id)">
                <el-tooltip content="删除" placement="top">
                  <i class="el-icon-delete" style="font-size: 16px"></i>
                </el-tooltip>
              </el-button>
            </template>
            <el-button v-if="row.status == 4 || row.status == 5" type="text" @click="detailTask(row)">
              <el-tooltip content="详情" placement="top">
                <i class="el-icon-document" style="font-size: 16px"></i>
              </el-tooltip>
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="page">
        <paginations :total="list.total" :page.sync="list.pageNum" :page-sizes="[5, 10, 15, 20]"
          :limit.sync="list.pageSize" @pagination="getPage" />
      </div>
    </div>

    <!-- 视频任务详情弹窗 -->
    <el-dialog title="视频任务详情" :visible.sync="descVisible" width="800px" :modal-append-to-body="false" destroy-on-close
      @close="handleCloseDetail">
      <span>
        <el-descriptions :column="2">
          <el-descriptions-item label="任务名称">{{ videoDetails.name }}</el-descriptions-item>
          <el-descriptions-item label="创建人">{{ videoDetails.createBy }}</el-descriptions-item>
          <el-descriptions-item label="创建时间">{{ videoDetails.createTime }}</el-descriptions-item>
          <el-descriptions-item label="存储路径">{{ videoDetails.albumName }} - {{ videoDetails.materialGroupName }}
          </el-descriptions-item>
        </el-descriptions>
        <div style="margin-top:20px;text-align:right">
          <el-button type="primary" :loading="downloading" size="mini" @click="handleDownloadAll">全部下载</el-button>
          <el-button v-if="videoDetails.status < 5" type="primary" :loading="syncLoading" size="mini" @click="handleSyncConfigTask">同步状态</el-button>
          <el-button icon="el-icon-refresh" size="mini" circle @click="getDetailPage"></el-button>
        </div>
        <el-table v-loading="details.loading" :data="details.data" stripe class="list-table"
          :class="{ 'no-data': !details.data || !details.data.length }" style="width: 100%;">
          <el-table-column type="index" label="序号" width="50"></el-table-column>
          <el-table-column prop="" label="状态" align="center">
            <template slot-scope="{row}">
              <el-tooltip :content="row.failMsg" placement="top">
              <div :class="['status', 'status-' + row.status]">{{ detailStatus[row.status] }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="url" label="预览" align="center">
            <template slot-scope="{row}">
              <el-popover v-if="row.videoResultUrl" placement="right" trigger="hover">
                <div class="element-pop-img">
                  <video :src="row.videoResultUrl" controls></video>
                </div>
                <div slot="reference" class="element-img">
                  <video :src="row.videoResultUrl"></video>
                </div>
              </el-popover>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="操作" align="center">
            <template slot-scope="{row}">
              <el-button v-if="row.status != 3" type="text" class="red" @click="handleSyncTaskStatus(row)">同步状态</el-button>
              <el-button v-if="row.status == 4" type="text" class="red" @click="handleReSubmit(row)">重新执行</el-button>
              <el-button v-if="row.status == 3" type="text" class="red" @click="handleDownloadVideo(row)">下载至本地
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="page">
          <paginations :total="details.total" :page.sync="details.pageNum" :page-sizes="[5, 10, 15, 20]"
            :limit.sync="details.pageSize" @pagination="getDetailPage" />
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getVideoTaskList,
  deleteVideoTask,
  getVideoTaskDetail,
  reSubmitVideoTask,
  getDownloadUrls,
  syncConfigStatus,
  syncTaskStatus
} from '@/network/api/toolManagement/api-tool'
import { getDateStartEnd } from '@/utils/ruoyi'
import JSZip from 'jszip'
import FileSaver from 'file-saver'
import axios from 'axios'

export default {
  components: {
  },
  data() {
    return {
      search: {
        name: '',
        createTime: this.getDateTime(),
        createBy: '',
        status: ''
      },
      list: { // 列表数据
        loading: true,
        data: [],
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      statusList: { // 列表状态
        0: '提交失败',
        2: '配置中',
        3: '待执行',
        4: '执行中',
        5: '执行完成'
      },
      videoDetails: {}, // 详情数据
      details: { // 详情列表数据
        loading: true,
        data: [],
        pageSize: 5,
        pageNum: 1,
        total: 0
      },
      detailStatus: { // 详情列表状态
        1: '待提交',
        2: '已提交',
        3: '成功',
        4: '失败'
      },
      descVisible: false, // 是否展示详情弹窗
      downloading: false, // 下载状态
      syncLoading: false // 同步
    }
  },

  mounted() {
    this.getPage()
  },

  methods: {
    /**
      * @description: 获取列表
      * @param {*} params
      */
    getPage() {
      this.list.loading = true
      const [createTimeStart, createTimeEnd] = getDateStartEnd(this.search.createTime)
      let search = {
        ...this.search,
        createTimeEnd,
        createTimeStart,
        pageSize: this.list.pageSize,
        pageNum: this.list.pageNum
      }
      delete search.createTime
      getVideoTaskList(search).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          records.forEach(v => {
            v.createBy = v.createBy || '-'
          })
          this.list.data = records
          this.list.total = total
          this.list.loading = false
        }
      }).catch(e => {
        this.list.loading = false
      })
    },
    /**
     * @description: 查询
     */
    onSubmit() {
      this.getPage()
    },

    /**
     * @description: 重置
     */
    resetForm() {
      this.$refs['search'].resetFields()
      this.getPage()
    },

    // 点击继续
    handleGoEdit(row) {
      this.$router.push({
        name: 'editVideoTask',
        params: { id: row.id }
      })
    },

    /**
       * @description: 删除任务
       */
    deleteTask(id) {
      this.$confirm('确定要删除该任务吗?', '视频任务删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { code } = await deleteVideoTask({ id: id })
        if (code === 200) {
          this.getPage()
          this.$message.success('删除成功!')
        }
      }).catch(() => {
      })
    },

    // 获取近7天时间段
    getDateTime() {
      const start = new Date(new Date().getTime() - 3600 * 1000 * 24 * 6).toISOString().replace('T', ' ').split('.')[0]
      const end = new Date(new Date().getTime()).toISOString().replace('T', ' ').split('.')[0]
      return [start, end]
    },

    // -------- 详情 ---------
    detailTask(record) {
      this.descVisible = true
      this.videoDetails = record
      this.getDetailPage()
    },

    // 获取详情列表
    getDetailPage() {
      let params = {
        configId: this.videoDetails.id,
        pageSize: this.details.pageSize,
        pageNum: this.details.pageNum
      }
      this.details.loading = true
      getVideoTaskDetail(params).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          this.details.data = records
          this.details.total = total
          this.details.loading = false
        }
      }).finally(() => {
        this.details.loading = false
      })
    },

    // 关闭详情弹窗
    handleCloseDetail() {
      this.details = {
        loading: false,
        data: [],
        pageSize: 5,
        pageNum: 1,
        total: 0
      }
    },

    // 重新执行
    handleReSubmit(row) {
      this.details.loading = true
      reSubmitVideoTask({ taskId: row.id }).then(({ code }) => {
        if (code === 200) {
          this.$message.success('提交成功')
          this.getDetailPage()
          this.getPage()
        }
      }).finally(() => {
        this.details.loading = false
      })
    },

    // 下载至本地
    async handleDownloadVideo(row) {
      this.getFile(row.videoResultUrl).then(data => {
        const content = data
        const fileName = row.videoName
        const blob = new Blob([content])
        if ('download' in document.createElement('a')) {
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          URL.revokeObjectURL(elink.href)
          document.body.removeChild(elink)
        } else {
          navigator.msSaveBlob(blob, fileName)
        }
      }).catch(() => { })
    },

    // 点击全部下载
    handleDownloadAll() {
      this.downloading = true
      getDownloadUrls({ configId: this.videoDetails.id }).then(({ code, data }) => {
        if (code === 200) {
          data.map((v, i) => {
            this.downloadUrlZip(v, i + 1)
          })
        } else {
          this.downloading = false
        }
      }).catch(() => {
        this.downloading = false
      })
    },

    handleSyncConfigTask() {
      this.syncLoading = true
      syncConfigStatus({ configId: this.videoDetails.id }).then(() => {
        this.$message.success('操作成功')
        this.getDetailPage()
      }).finally(() => {
        this.syncLoading = false
      })
    },

    handleSyncTaskStatus(row) {
      syncTaskStatus({ configId: row.configId, taskId: row.id }).then(() => {
        this.$message.success('操作成功')
        this.getDetailPage()
      })
    },

    // 分组下载 打包
    downloadUrlZip(files, index) {
      const blogTitle = `${this.videoDetails.name}_${index}.zip`
      let zip = new JSZip()
      let promises = []
      let cache = {}

      files.forEach(item => {
        const promise = this.getFile(item).then(data => {
          const arrName = item.split('/')
          const fileName = arrName[arrName.length - 1]
          zip.file(fileName, data, { binary: true })
          cache[fileName] = data
        })
        promises.push(promise)
      })

      this.downloading = true
      Promise.all(promises).then(() => {
        zip.generateAsync({ type: 'blob' }).then(content => {
          FileSaver.saveAs(content, blogTitle)
        })
        if (files.length >= index) {
          this.downloading = false
        }
      }).catch(e => {
        this.$message.error('文件压缩失败')
        this.downloading = false
      })
    },

    // 获取下载文件内容
    getFile(url) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url,
          responseType: 'arraybuffer'
        }).then(data => {
          resolve(data.data)
        }).catch(error => {
          reject(error.toString())
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.element-img {
  width: 60px;
  height: 60px;
  text-align: center;
  margin: auto;
  padding-top: 12px;

  video {
    width: 100%;
    height: 100%
  }
}

.element-pop-img {
  max-width: 260px;

  video {
    width: 100%;
    height: 100%
  }
}

.status-3 {
  color: #67C23A;
}

.status-4 {
  color: #F56C6C;
}
.status {
  padding-right: 20px;
  p {
    padding: 0;
    margin: 0;
  }
  .status-num {
    display: flex;
    justify-content: space-between;
    p {
      font-size: 13px;
      color: rgb(153, 153, 153);
    }
  }
}
</style>
