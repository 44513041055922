export let searchForm = {
  id: null,
  pkUnique: null,
  pid: null,
  pageName: null,
  adminId: null,
  pageTemplate: null,
  pageType: null,
  status: null,
  errMsg: null,
  pageUrl: null,
  thumbnail: null,
  createdAt: null,
  updatedAt: null
}

export class CwOceanenginePageEnt {
  id
  pkUnique
  pid
  pageName
  adminId
  pageTemplate
  pageType
  status
  errMsg
  pageUrl
  thumbnail
  createdAt
  updatedAt
}

export let formRules = {
  id: [{ required: true, message: '请填写主键', trigger: 'change' }],
  pkUnique: [{ required: true, message: '请填写自定义主键', trigger: 'change' }],
  pid: [{ required: true, message: '请填写复制父类ID', trigger: 'change' }],
  pageName: [{ required: true, message: '请填写推广页名称', trigger: 'change' }],
  adminId: [{ required: true, message: '请填写创建人', trigger: 'change' }],
  pageTemplate: [{ required: true, message: '请填写推广页模板', trigger: 'change' }],
  pageType: [{ required: true, message: '请填写推广页类型', trigger: 'change' }],
  errMsg: [{ required: true, message: '请填写错误消息', trigger: 'change' }],
  pageUrl: [{ required: true, message: '请填写推广页URL', trigger: 'change' }],
  thumbnail: [{ required: true, message: '请填写缩略图', trigger: 'change' }],
  createdAt: [{ required: true, message: '请填写创建时间', trigger: 'change' }],
  updatedAt: [{ required: true, message: '请填写更新时间', trigger: 'change' }]
}
