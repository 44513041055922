<template>
  <!--模视频板弹框-->
  <el-dialog class="element-library-box" title="添加元素" :visible.sync="show" :append-to-body="true" width="1200px"
    top="20vh" @close="onCloseDialog">
    <div class="page-container unselected-all-table">
      <el-row :gutter="20">
        <el-col :span="17" class="middle-content">
          <el-form ref="search" :inline="true" :model="search" class="search-form search-form-4">
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item label="名称" prop="name">
                  <el-input v-model="search.name" placeholder="请输入名称" style="width: 100%"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="元素类型" prop="typeId">
                  <el-select v-model="search.typeId" style="width: 100%">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="item in elementTypes" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item>
                  <el-button type="primary" round @click="onSubmit">查 询</el-button>
                  <el-button type="primary" round @click="resetForm('ruleForm')">重 置</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-table ref="multipleTable" v-loading="loading" :data="tableData" :row-key="row => { return row.id }"
            :class="{ 'no-data': !tableData || !tableData.length }" @select="handleCurrentChange">
            <el-table-column type="selection" :reserve-selection="true"></el-table-column>
            <el-table-column type="index" label="序号" width="50"></el-table-column>
            <el-table-column prop="name" label="元素名称" align="center"></el-table-column>
            <el-table-column prop="typeName" label="元素类型" align="center"></el-table-column>
            <el-table-column prop="url" label="预览" align="center">
              <template slot-scope="{row}">
                <el-popover v-if="row.baseType === 'IMG'" placement="right" trigger="hover">
                  <div class="element-pop-img">
                    <el-image :src="row.url" fit="contain"></el-image>
                  </div>
                  <div slot="reference" class="element-img">
                    <el-image :src="row.url" fit="contain" lazy></el-image>
                  </div>
                </el-popover>
                <el-popover v-if="row.baseType === 'VIDEO'" placement="right" trigger="hover">
                  <div class="element-pop-img">
                    <video :src="row.url" controls></video>
                  </div>
                  <div slot="reference" class="element-img">
                    <video :src="row.url"></video>
                  </div>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-container">
            <c-pagination :page-size.sync="pageSize" :page-no.sync="pageNum" :pager-count="5" :total="total"
              tools="total, prev, pager, next" :get-data-fun="getPageData" />
          </div>
        </el-col>
        <el-col :span="7">
          <div class="comp-selected-box page-name">
            <div class="comp-title">已选元素</div>
            <div class="comp-selected-content">
              <div class="comp-selected-item" v-for="(item, index) in selectedData" :key="index">
                <template>
                  <span class="comp-name">{{ item.name }}</span>
                  <span class="el-icon-close comp-del-icon" @click="handleDeleteSelected(item, index)"></span>
                </template>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">关闭</el-button>
      <!-- <el-button class="btn" type="primary" :loading="isLoading" :disabled="isLoading" @click="handleConfirm">确定 -->
      <!-- </el-button> -->
    </div>
  </el-dialog>
</template>

<script>
import { getElementType, getElementList, relationTemplateElement, getTemplateElementList, deleteTemplateElement } from '@/network/api/toolManagement/api-tool'
import { PageCommonComponent } from '@/common/mixin'

export default {
  name: 'TemplateElementRelation',
  mixins: [PageCommonComponent],
  props: {},
  data() {
    return {
      templateId: null,
      show: false,
      loading: false, // 表格加载状态
      isLoading: false, // 是否正在保存
      elementTypes: [],
      search: {
        name: '',
        typeId: ''
      },
      selectedData: [],
      tableData: [],
      pageSize: 5,
      pageNum: 1,
      total: 0
    }
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {
    /* --------------------------- Actions --------------------------- */
    showModal(templateId, data) {
      this.templateId = templateId
      this.getElementTypes()
      this.selectedData = [...data]
      this.show = true
      this.getPage()
    },
    // 获取元素类型
    getElementTypes() {
      getElementType({ name: '' }).then(({ code, data }) => {
        if (code === 200) {
          this.elementTypes = data
        }
      })
    },

    // 获取元素列表
    getPage() {
      this.loading = true
      let search = {
        ...this.search,
        pageSize: this.pageSize,
        pageNum: this.pageNum
      }
      getElementList(search).then(({ code, data }) => {
        if (code === 200) {
          let { records, total } = data
          this.tableData = records
          this.total = total

          // 选中数据回显
          if (this.selectedData.length) {
            this.echo(this.tableData)
          }
        }
      }).finally(e => {
        this.loading = false
      })
    },

    // 获取已选元素列表
    getSelectedPage() {
      if (!this.templateId) {
        return
      }
      let search = {
        templateId: this.templateId
      }
      getTemplateElementList(search).then(({ code, data }) => {
        if (code === 200) {
          this.selectedData = data || []
        }
      })
    },

    // 表格数据回显
    echo(data) {
      let ids = this.selectedData.map(v => v.elementId)
      data.forEach(item => {
        if (!ids.includes(item.id)) {
          setTimeout(() => {
            this.$refs.multipleTable.toggleRowSelection(item, false)
          }, 10)
        } else {
          setTimeout(() => {
            this.$refs.multipleTable.toggleRowSelection(item, true)
          }, 10)
        }
      })
    },

    // 点击查询
    onSubmit() {
      if (this.pageNum === 1) {
        this.getPage()
      } else {
        this.pageNum = 1
      }
    },

    // 点击重置
    resetForm() {
      this.$refs['search'].resetFields()
      this.getPage()
    },

    // 获取页码跳转数据
    getPageData() {
      this.getPage()
    },

    // table 选择 cell 时调用
    handleCurrentChange(selection, row) {
      let ids = this.selectedData.map(v => v.elementId)
      if (ids.includes(row.id)) { // 删除
        let item = this.selectedData.find(v => v.elementId === row.id)
        if (this.isLoading) {
          return
        }
        this.isLoading = true
        deleteTemplateElement({ id: item.id }).then(({ code }) => {
          if (code === 200) {
            let index = this.selectedData.findIndex(v => v.elementId === item.id)
            this.selectedData.splice(index, 1)
          }
        }).finally(() => {
          this.isLoading = false
          this.$emit('refresh')
        })
      } else { // 添加
        if (this.isLoading) {
          return
        }
        let params = {
          elementId: row.id,
          templateId: this.templateId
        }
        this.isLoading = true
        relationTemplateElement(params).then(({ code, data }) => {
          if (code === 200) {
            this.getSelectedPage()
          }
        }).finally(() => {
          this.isLoading = false
          this.$emit('refresh')
        })
      }
    },

    // 点击删除按钮
    handleDeleteSelected(row, index) {
      if (this.isLoading) {
        return
      }
      this.isLoading = true
      deleteTemplateElement({ id: row.id }).then(({ code }) => {
        if (code === 200) {
          this.selectedData.splice(index, 1)
          setTimeout(() => {
            let rowIndex = this.tableData.findIndex(v => v.id === row.elementId)
            this.$nextTick(() => this.$refs.multipleTable.toggleRowSelection(this.tableData[rowIndex], false))
          }, 10)
        }
      }).finally(() => {
        this.isLoading = false
        this.$emit('refresh')
      })
    },

    // 监听 Dialog 弹框关闭
    onCloseDialog() {
      // 重置表单
      this.selectedData = []
      this.tableData = []
      this.total = 0
      this.pageSize = 5
      this.pageNum = 1
      this.$refs.multipleTable.clearSelection()
      this.$emit('refresh')
    },
    // 关闭弹框
    closeModal() {
      this.show = false
    }

    /* --------------------------- Private --------------------------- */
  }
}
</script>
<style lang="scss">
.unselected-all-table {
  .input-append-btn .el-input-group__append {
    background-color: #409eff;
    color: #fff;
  }

  .el-table th.el-table__cell>.cell .el-checkbox {
    display: none;
  }
}
</style>
<style scoped lang="scss">
@import '~@/assets/style/variables';
@import '~@/assets/style/mixin';

.element-img {
  width: 60px;
  height: 60px;
  text-align: center;
  margin: auto;
  padding-top: 12px;

  video {
    width: 100%;
    height: 100%
  }
}

.element-pop-img {
  max-width: 260px;

  video {
    width: 100%;
    height: 100%
  }
}

.page-container {
  margin-bottom: 50px;
  padding: 0 10px;

  .page-name {
    border: 1px solid #dcdfe6;
    height: 520px;
    overflow: auto;

    .name {
      padding: 10px;
      cursor: pointer;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      // border-bottom: 1px solid #ccc;
    }

    .is-active {
      color: #409eff;
      font-weight: 700;
      background: #ecf5ff;
    }
  }

  .middle-content {
    padding: 0 20px !important;
  }

  .comp-selected-box {
    padding: 10px;
    box-sizing: border-box;

    .comp-title {
      color: #1a1a1a;
      font-size: 16px;
      font-weight: 500;
    }

    .comp-selected-item {
      color: #606266;
      background: #ecf5ff;
      padding: 6px;
      border-radius: 4px;
      margin: 10px 0;
    }

    .comp-del-icon {
      float: right;
      position: relative;
      top: 4px;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;

    .operation-btn-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .table-info {
        padding: 10px 0;
        color: #333a3f;
        font-size: 16px;
        margin-left: 12px;

        .number {
          color: #2842c8;
          font-size: 24px;
          margin: 0 5px;
        }
      }

      .el-button--small {
        padding: 6px 13px;
      }

      .btn-content {
        @include flex-center;
        height: 18px;

        .icon-container {
          @include flex-center;
          width: 18px;
          height: 18px;
          border-radius: 9px;
          // background: #1667FF;
          margin-right: 6px;

          img {
            width: 12px;
            height: 12px;
          }
        }
      }
    }

    .function-btn-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .function-btn {
        @include flex-center;
        width: 26px;
        height: 20px;
        background: white;
        transition: 0.2s;
        margin-left: 10px;
        position: relative;
        cursor: pointer;

        .table-columns-container {
          position: absolute;
          top: 100%;
          left: 0;
          z-index: 10;
          width: 150px;
          background: rgba(255, 255, 255, 1);
          box-shadow: 0px 2px 5px 0px rgba(229, 228, 234, 1);
          border-radius: 4px;
          padding-left: 10px;
          overflow: hidden;
          display: none;

          .table-columns-item {
            height: 30px;
            line-height: 30px;
          }
        }

        &:hover {
          background: #f2f2f2;

          .table-columns-container {
            display: block;
          }
        }

        img {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  .table-select-tip {
    height: 38px;
    padding-left: 17px;
    display: flex;
    align-items: center;
    color: #909399;
    font-size: 14px;
    background: #dbe9ff;
    border: 1px solid #4788ff;
    border-radius: 4px;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .table-link {
    color: #606266;
    transition: 0.2s;
    text-decoration: none;

    &:hover {
      color: $themeColor;
      text-decoration: underline;
    }
  }

  .poster-container {
    @include flex-center;
    width: 36px;
    height: 36px;
    overflow: hidden;

    .poster {
      width: 36px;
      min-height: 36px;
    }
  }

  .opt-container {
    display: flex;
    justify-content: space-around;

    .opt-text {
      font-size: 14px;
    }
  }

  .cell-btn {
    font-size: 0;

    img {
      width: 18px;
      height: 18px;
    }

    .cell-btn-hover {
      display: none;
    }

    &:hover {
      .cell-btn-icon {
        display: none;
      }

      .cell-btn-hover {
        display: block;
      }
    }
  }

  .pagination-container {
    height: 64px;
    box-sizing: border-box;
  }
}
</style>
