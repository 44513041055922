<template>
  <img
    class="icon-img"
    :src="require(`@/assets/image/icon/${icon}.png`)"
    :style="{'width': width}"
  >
</template>

<script>
export default {
  name: 'Icon',
  props: {
    icon: {
      type: String,
      required: true
    },
    width: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  }
}
</script>

<style scoped lang="scss">
img.icon-img {
  position: relative;
  top: -1px;
}
</style>
