/*
 * @Description: oss
 */
let OSS = require('ali-oss')
import { getAssumeRole } from '@/network/api/api-material'

export default {
  data() {
    return {

      originName: null, // 文件原名
      fileName: null, // 文件名id
      fileUrl: null, // 文件url
      isShowS: false, // 是否展示进度条
      statusUp: -1, // 状态
      schedule: 0 // 进度
    }
  },
  watch: {
    statusUp(val) {
      console.log('statusUp->' + val)
    },

    schedule(value) {
      console.log('进度->' + value)
    }
  },
  methods: {
    getIsShow() {
      return this.isShowS
    },
    initData() {
      this.originName = null
      this.fileName = null
      this.isShowS = true
      this.statusUp = -1
      this.schedule = 0
      this.resultData = null
    },
    ossUpload(file, videoP, onSuccess, onFailure) {
      this.initData()
      try {
        getAssumeRole().then((res) => {
          if (res.code !== 200) {
            this.$message.error(`获取扮演角色的临时身份凭证 失败`)
            return
          }
          const client = new OSS({
            // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
            region: 'oss-cn-shanghai',
            // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
            accessKeyId: res.data.oss.accessKeyId,
            accessKeySecret: res.data.oss.accessKeySecret,
            // 从STS服务获取的安全令牌（SecurityToken）。
            stsToken: res.data.oss.securityToken,
            // 填写Bucket名称。
            bucket: 'copy-writing'
          })
          this.fileName = res.data.fileName
          this.originName = file.name
          console.log('fileName->' + this.fileName)
          client.multipartUpload(
            res.data.fileName + `${file.name}`, // 如果需要传入到指定的文件夹下，需要在文件名前加上文件夹名称，如： `xxxxx / ${ file.name } `
            file,
            {
              progress: (p, cpt, res) => { // 获取分片上传进度、断点和返回值。
                this.schedule = p * 100
              },
              parallel: 4, // 并发上传的分片数量
              partSize: 1024 * 1024 * 1, // 分片大小
              headers: { // 上传请求头设置
                'Content-Disposition': 'inline',
                'Content-Type': file.type
              },
              meta: { year: 2020, people: 'test' }, // 自定义元数据，通过HeadObject接口可以获取Object的元数据。
              mime: file.type // 上传文件类型
            }
          ).then(val => {
            console.log(val)
            let { res } = val
            let { status, data } = res
            console.log(res)
            this.statusUp = status
            if (status === 200) {
              var datas = this.Uint8ArrayToString(data)
              console.log(datas)
              var pattern = /<Location>(.*)<\/Location>/i
              var url = pattern.exec(datas)[1]
              console.log(url)
              this.isShowS = false
              videoP.url = url
              videoP.originName = this.originName
              onSuccess(videoP)
            }
          }).catch(function (err) {
            console.log('异常--->', err)
            onFailure(err)
          })
        })
      } catch (e) {
        console.log('异常--->', e)
        onFailure(e)
      }
    },
    Uint8ArrayToString(fileData) {
      var dataString = ''
      for (var i = 0; i < fileData.length; i++) {
        dataString += String.fromCharCode(fileData[i])
      }
      return dataString
    }

  }
}
