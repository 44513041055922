import { render, staticRenderFns } from "./DocumentLibrary.vue?vue&type=template&id=dd71e7de&scoped=true&"
import script from "./DocumentLibrary.vue?vue&type=script&lang=js&"
export * from "./DocumentLibrary.vue?vue&type=script&lang=js&"
import style0 from "./DocumentLibrary.vue?vue&type=style&index=0&id=dd71e7de&lang=scss&"
import style1 from "./DocumentLibrary.vue?vue&type=style&index=1&id=dd71e7de&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd71e7de",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/Data/ad_frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dd71e7de')) {
      api.createRecord('dd71e7de', component.options)
    } else {
      api.reload('dd71e7de', component.options)
    }
    module.hot.accept("./DocumentLibrary.vue?vue&type=template&id=dd71e7de&scoped=true&", function () {
      api.rerender('dd71e7de', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/assetManagement/materialManagement/DocumentLibrary.vue"
export default component.exports