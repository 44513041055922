<!--
 * @Description: 创意基础信息
 * @Date: 2021-12-06 15:57:16
 * @LastEditTime: 2022-02-21 09:41:00
-->
<template>
  <div>
    <div class="creativity-material">
      <!-- v-if="creativityMaterialList.length !== 0 && creativityMaterialList[0].list[0].assetLocalUrl" -->
      <div v-if="(materials.length && materials[0].list[0].assetLocalUrl)">
        <div v-for="item in materials" :key="item.name" class="img-list">
          <p>{{ item.name }}</p>
          <div class="img-box">
            <div v-for="image in item.list" :key="image.creativeGroup">
              <template v-if="image.assetLocalUrl">
                <video v-if="checkIsVideoType(image.assetLocalUrl)" :src="image.assetLocalUrl"></video>
                <img v-else :src="image.assetLocalUrl" class="avatar" />
              </template>
              <!-- <video v-if="image.assetType === 'VIDEO' && image.assetLocalUrl" :src="image.assetLocalUrl"></video>
                <img v-if="image.assetType === 'IMG' && image.assetLocalUrl"
                  :src="image.assetLocalUrl" class="avatar" /> -->
            </div>
          </div>
        </div>
      </div>
      <div v-else style="text-align: center; padding-top: 20px; color: #909399">暂无数据</div>

      <el-dialog custom-class="dialog-item" title="创意素材" :visible="materialVisible" width="800px" :append-to-body="true"
        destroy-on-close @close="handleClose">
        <div class="justify-start">
          <h3>创意内容</h3>
          <p class="ml-20">创意组数量：{{ creativityMaterialList.length }}组</p>
        </div>
        <!-- 广告版位非“朋友圈” 且 为视频时展示 -->
        <div v-if="(!isImage && taskSiteSet !== 1)" class="justify-start" style="margin: 20px 0;">
          <p class="mr-20">自动生成封面</p>
          <el-radio-group v-model="generateCover">
            <el-radio :label="0">不开启</el-radio>
            <el-radio :label="1">开启</el-radio>
          </el-radio-group>
        </div>
        <el-collapse v-model="collapse">
          <el-collapse-item v-for="(item, index) in creativityMaterialList" :key="item.name" :name="item.name">
            <template slot="title">
              <div class="justify-sb group-title">
                <p>{{ item.name }}</p>
                <div class="icon">
                  <i class="el-icon-document-copy" @click="copyCreativeGroup(index)"></i>
                  <i v-if="creativityMaterialList.length > 1" class="el-icon-close"
                    @click="deleteCreativeGroup(index)"></i>
                </div>
              </div>
            </template>
            <div v-if="isImage" class="img-box">
              <p>图片:</p>
              <div v-for="(imgInfo, idx) in item.list" :key="idx" class="mr-10">
                <div class="avatar-uploader" @click="setMaterialBox(index, 0)">
                  <img v-if="imgInfo.assetLocalUrl" :src="imgInfo.assetLocalUrl" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon" />
                </div>
              </div>
              <div class="info">
                <p>尺寸：{{ item.list[0].assetLocalResizeWidth }}px * {{ item.list[0].assetLocalResizeHeight }}px</p>
                <p>格式：JPG、JPEG、PNG格式</p>
                <p>大小：{{ item.list[0].assetLocalResizeFileSize }} KB 以内</p>
              </div>
            </div>
            <!-- 视频 -->
            <div v-else class="justify-sb">
              <div class="img-box">
                <p>视频:</p>
                <div class="mr-10">
                  <div class="avatar-uploader" @click="setMaterialVideoBox(index)">
                    <video v-if="item.list[0].assetLocalUrl" :src="item.list[0].assetLocalUrl" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon" />
                  </div>
                </div>
                <div class="info">
                  <p>最小尺寸：{{ item.list[0].assetLocalResizeWidth }}px * {{ item.list[0].assetLocalResizeHeight }}px</p>
                  <p>格式：MP4、MOV、AVI格式</p>
                  <p>大小：100 MB 以内</p>
                  <p>播放时长: 5s - 30s</p>
                </div>
              </div>
              <div v-if="(item.list.length > 1 && (generateCover === 0 || item.list[0].assetLocalUrl))" class="img-box">
                <p>封面:</p>
                <div class="mr-10">
                  <div class="avatar-uploader" @click="setMaterialBox(index, 1)">
                    <template v-if="item.list[1].assetLocalUrl">
                      <video v-if="checkIsVideoType(item.list[1].assetLocalUrl)" :src="item.list[1].assetLocalUrl"
                        class="avatar" />
                      <img v-else :src="item.list[1].assetLocalUrl" class="avatar" />
                    </template>
                    <i v-else class="el-icon-plus avatar-uploader-icon" />
                  </div>
                </div>
                <div class="info">
                  <p>最小尺寸：{{ item.list[1].assetLocalResizeWidth }}px * {{ item.list[1].assetLocalResizeHeight }}px</p>
                  <p>格式：JPG、JPEG、PNG格式</p>
                  <p>大小：{{ item.list[1].assetLocalResizeFileSize }} KB 以内</p>
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
        <div style="text-align: center; margin-top: 10px">
          <el-button type="primary" @click="addCreativeGroup">添加创意组</el-button>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="onSubmit">确 定</el-button>
        </span>
      </el-dialog>
      <!-- { image: sizeForm.userPortrait, imageId: sizeForm.userPortraitId } -->
    </div>
    <div v-if="materialVisible">
      <material-box :visible="materialBoxVisible" :img-count="imgCount" name="creativeGroup" :image-list="[]" :attribute="{
        key: 'creativeGroup',
        listType: 'image/jpg,image/jpeg,image/png',
        ...fileAttribute
      }" :project-id="[+projectId]" :is-batch="true" @setMaterialBox="materialBoxVisible = false"
        @submit="selectImg" />

      <MaterialVideoBox :visible="materialVideoVisible" :img-count="1" name="videoUrl" :video-list="[]" :attribute="{
        key: 'videoHorizontal',
        listType: 'video/mp4',
        ...fileAttribute
      }" :project-id="[+projectId]" :is-batch="true" @setMaterialBox="materialVideoVisible = false"
        @handleVideoSuccess="selectVideo" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { imgOrVideoUploadGdt, deleteAssetImgOrVideo } from '@/network/api/advManagement/advManagementTencent' // 获取计划模板
import MaterialBox from './img.vue'
import MaterialVideoBox from './video.vue'
export default {
  components: {
    MaterialBox,
    MaterialVideoBox
  },

  inject: ['closeMaterial'],
  props: {
    materialVisible: {
      type: Boolean,
      default: false
    },
    creativityData: {
      type: Object,
      default: () => { }
    },
    creativityMaterialData: {
      type: Array,
      default: () => []
    },
    creativityMaterialDataTemplate: {
      type: Array,
      default: () => []
    },
    taskId: {
      type: Number,
      default: 0
    },
    projectId: {
      type: [String, Number],
      default: ''
    },
    accountId: {
      type: Array,
      default: () => []
    },
    taskSiteSet: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      materialBoxVisible: false,
      materialVideoVisible: false,
      setIndex: 0,
      listIndex: 0,
      imgCount: 0,
      fileAttribute: {},
      creativityMaterialList: [],
      materials: [],
      collapse: ['创意组01', '创意组02', '创意组03'],
      generateCover: 0 // 自动生成封面
    }
  },

  computed: {
    ...mapGetters(['userInfo']),
    // 是否为图片类型
    isImage() {
      return [311, 641, 642, 643, 711, 712].includes(this.creativityData.adcreativeTemplateId)
    }
  },

  watch: {
    materialVisible(value) {
      if (value) {
        this.generateCover = this.creativityData.autoGenerateCoverPage ? 1 : 0
      }
    },
    creativityMaterialData: {
      handler(value) {
        this.creativityMaterialList = value
        // this.creativityMaterialList = value.map(v => {
        //   if (this.taskSiteSet !== 1 && !this.isImage && v.list.length === 1) {
        //     return {
        //       ...v,
        //       list: [v.list[0], {
        //         ...this.creativityMaterialDataTemplate[1],
        //         assetLocalUrl: v.list[0].assetLocalUrl,
        //         assetGroupName: v.list[0].assetGroupName,
        //         assetGroupOrderBy: v.list[0].assetGroupOrderBy
        //       }]
        //     }
        //   } else {
        //     return v
        //   }
        // })
        this.materials = value
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    setMaterialBox(index = 0, listIndex = 0) {
      this.setIndex = index
      this.listIndex = listIndex
      this.imgCount = this.creativityMaterialList[0].list.length
      if (listIndex === 1) {
        this.imgCount = 1
      }
      this.fileAttribute = {
        value: this.getImgAttributeValue(),
        count: this.imgCount,
        size: this.creativityMaterialList[0].list[listIndex].assetLocalResizeFileSize * 1024,
        width: [720, 721, 599].includes(this.creativityData.adcreativeTemplateId) ? null : this.creativityMaterialList[0].list[listIndex].assetLocalResizeWidth,
        height: [720, 721, 599].includes(this.creativityData.adcreativeTemplateId) ? null : this.creativityMaterialList[0].list[listIndex].assetLocalResizeHeight
      }
      this.materialBoxVisible = true
    },

    setMaterialVideoBox(index = 0) {
      this.setIndex = index
      this.fileAttribute = {
        value: this.creativityData.adcreativeTemplateId === 720 ? '横版视频' : (this.creativityData.adcreativeTemplateId === 599 ? '横版视频4:3' : '竖版视频'),
        count: 1,
        size: this.creativityMaterialList[0].list[0].assetLocalResizeFileSize * 1024,
        width: null,
        height: null
      }
      this.materialVideoVisible = true
    },

    // 视频封面
    getImgAttributeValue() {
      let value = '创意素材'
      if (this.taskSiteSet !== 1) {
        if (this.creativityData.adcreativeTemplateId === 720) {
          value = '横版视频'
        }
        if (this.creativityData.adcreativeTemplateId === 721) {
          value = '竖版视频'
        }
        if (this.creativityData.adcreativeTemplateId === 599) {
          value = '横版视频4:3'
        }
      }
      return value
    },

    /**
     * @description 添加创素材组
     */
    addCreativeGroup() {
      // if (this.creativityMaterialList[this.creativityMaterialList.length - 1].list[0].assetLocalId === null) {
      //   return this.$message.error('请先选择图片!')
      // }
      if (!this.checkSelect()) {
        return this.$message.error('请先选择完素材后再添加!')
      }
      let list = JSON.parse(JSON.stringify(this.creativityMaterialDataTemplate))
      list.forEach((ele) => {
        ele.assetGroupName = `创意组0${this.creativityMaterialList.length + 1}`
        ele.assetGroupOrderBy = this.creativityMaterialList.length + 1
      })
      this.creativityMaterialList.push({
        name: `创意组0${this.creativityMaterialList.length + 1}`,
        list
      })
    },

    addCreativeGroupV2() {
      let list = JSON.parse(JSON.stringify(this.creativityMaterialDataTemplate))
      list.forEach((ele) => {
        ele.assetGroupName = `创意组0${this.creativityMaterialList.length + 1}`
        ele.assetGroupOrderBy = this.creativityMaterialList.length + 1
      })
      this.creativityMaterialList.push({
        name: `创意组0${this.creativityMaterialList.length + 1}`,
        list
      })
    },

    /**
     * @description 删除创素材组
     */
    async deleteCreativeGroup(index) {
      let item = this.creativityMaterialList[index]
      if (item.list[0].taskAssetId) {
        this.$emit('clearTaskGenerate')
        await deleteAssetImgOrVideo({ taskId: this.taskId, assetGroupName: item.name })
      }
      this.creativityMaterialList.splice(index, 1)
      this.creativityMaterialList.forEach((item, index) => {
        const name = '创意组0' + (index + 1)
        item.name = name
        item.list.forEach((it) => {
          it.assetGroupName = name
          it.assetGroupOrderBy = index + 1
        })
      })
    },

    /**
     * @description 复制创素材组
     */
    copyCreativeGroup(index) {
      // if (this.creativityMaterialList[this.creativityMaterialList.length - 1].list[0].assetLocalId === null) {
      //   return this.$message.error('请先选择完图片后再复制!')
      // }
      if (!this.checkSelect()) {
        return this.$message.error('请先选择完素材后再复制!')
      }
      this.creativityMaterialList.push({
        name: `创意组0${this.creativityMaterialList.length + 1}`,
        list: JSON.parse(JSON.stringify(this.creativityMaterialList[index].list)).map((item) => {
          item.assetGroupName = `创意组0${this.creativityMaterialList.length + 1}`
          item.assetGroupOrderBy = this.creativityMaterialList.length + 1
          item.taskAssetId = null
          return item
        })
      })
    },

    /**
     * @description 选择图片后赋值图片信息
     */
    selectImg(value) {
      if (!value || !value.length) {
        return
      }
      console.log(value, 'sucai', this.setIndex, this.creativityMaterialList)
      if (this.imgCount < value.length) {
        const groupCount = Math.ceil(value.length / this.imgCount)
        let count = this.creativityMaterialList.length < value.length ? groupCount - this.creativityMaterialList.length : 0
        if (count) {
          for (let i = 0; i < count; i++) {
            this.addCreativeGroupV2()
          }
        }
        let begin = 0
        for (let i = 0; i < groupCount; i++) {
          const end = begin + this.imgCount
          const group = value.slice(begin, end)
          const material = this.creativityMaterialList[i]
          group.forEach((item, index) => {
            if ([599, 720, 721].includes(this.creativityData.adcreativeTemplateId)) {
              // 视频封面
              material.list[1].materialName = group[0].materialName
              material.list[1].assetLocalId = group[0].imageId
              material.list[1].assetLocalUrl = group[0].image
              material.list[1].assetLocalSignature = group[0].hash
              material.list[1].assetLocalResizeHeight = group[0].height
              material.list[1].assetLocalResizeWidth = group[0].width
            } else {
              material.list[index].materialName = item.materialName
              material.list[index].assetLocalId = item.imageId
              material.list[index].assetLocalUrl = item.image
              material.list[index].assetLocalSignature = item.hash
              material.list[index].assetLocalResizeHeight = group[0].height
              material.list[index].assetLocalResizeWidth = group[0].width
              console.log(index, this.creativityMaterialList[index].list)
            }
          })
          begin = end
        }

        // console.log(Math.round(value.length / this.imgCount), value, this.creativityMaterialList, 'ccccc')
      } else {
        if ([599, 720, 721].includes(this.creativityData.adcreativeTemplateId)) {
          // 视频封面
          this.creativityMaterialList[this.setIndex].list[1].materialName = value[0].materialName
          this.creativityMaterialList[this.setIndex].list[1].assetLocalId = value[0].imageId
          this.creativityMaterialList[this.setIndex].list[1].assetLocalUrl = value[0].image
          this.creativityMaterialList[this.setIndex].list[1].assetLocalSignature = value[0].hash
          this.creativityMaterialList[this.setIndex].list[1].assetLocalResizeHeight = value[0].height
          this.creativityMaterialList[this.setIndex].list[1].assetLocalResizeWidth = value[0].width
        } else {
          value.map((item, index) => {
            const material = this.creativityMaterialList[this.setIndex]
            material.list[index].materialName = item.materialName
            material.list[index].assetLocalId = item.imageId
            material.list[index].assetLocalUrl = item.image
            material.list[index].assetLocalSignature = item.hash
            material.list[index].assetLocalResizeHeight = value[0].height
            material.list[index].assetLocalResizeWidth = value[0].width
          })
        }
      }
    },

    checkSelect() {
      let listArray = this.creativityMaterialList.map((item) => item.list)
      let flag = true
      listArray.flat().forEach((item) => {
        if (item.assetLocalUrl === null) {
          flag = false
          return
        }
      })
      return flag
    },

    checkIsVideoType(url) {
      let flag = false
      if (!url) {
        return flag
      }
      const type = ['mp4', 'mov', 'avi']
      const strArr = url && url.length ? url.split('.') : []
      let str = strArr[strArr.length - 1]
      if (strArr.length && type.includes(str)) {
        flag = true
      }
      return flag
    },

    // 上传视频成功后,归类
    selectVideo(videos) {
      if (!videos || !videos.length) {
        return
      }
      if (videos.length > 1) {
        let count = this.creativityMaterialList.length < videos.length ? videos.length - this.creativityMaterialList.length : 0
        if (count) {
          for (let i = 0; i < count; i++) {
            this.addCreativeGroupV2()
          }
        }
        videos.forEach((item, index) => {
          this.creativityMaterialList[index].list[0].materialName = item.materialName
          this.creativityMaterialList[index].list[0].assetLocalId = item.videoId
          this.creativityMaterialList[index].list[0].assetLocalUrl = item.videoUrl
          if (this.generateCover === 1) {
            this.creativityMaterialList[index].list[1].assetLocalUrl = item.videoUrl
          }
        })
      } else {
        this.creativityMaterialList[this.setIndex].list[0].materialName = videos[0].materialName
        this.creativityMaterialList[this.setIndex].list[0].assetLocalId = videos[0].videoId
        this.creativityMaterialList[this.setIndex].list[0].assetLocalUrl = videos[0].videoUrl
        if (this.generateCover === 1) {
          this.creativityMaterialList[this.setIndex].list[1].assetLocalUrl = videos[0].videoUrl
        }
      }
      // console.log(this.creativityMaterialList, this.setIndex, 'video', videos)
    },

    /**
     * @description 创意素材添加
     */
    async onSubmit() {
      if (this.creativityMaterialList[this.creativityMaterialList.length - 1].list[0].assetLocalId === null) {
        return this.$message.error('请先选择最后一组图片!')
      }
      if (!this.checkSelect()) {
        return this.$message.error('请先选择完素材后再保存!')
      }
      let listArray = this.creativityMaterialList.map((item) => item.list)
      let list = listArray.flat().filter(v => !(v.assetType === 'IMG' && this.checkIsVideoType(v.assetLocalUrl)))
      this.$emit('clearTaskGenerate')
      imgOrVideoUploadGdt({ taskId: this.taskId, autoGenerateCoverPage: this.generateCover === 1, lists: list }).then((res) => {
        if (res.code === 200) {
          this.generateCover = 0
          this.$message.success('素材保存成功!')
          this.$emit('byTaskIdGetAllFun')
          this.closeMaterial()
        }
      })
    },

    handleClose() {
      this.generateCover = 0
      this.$emit('byTaskIdGetAllFun')
      this.closeMaterial()
    }
  }
}
</script>

<style lang="scss" scoped>
.creativity-material {
  overflow-y: auto;
  height: 300px;

  .img-list {

    // display: flex;
    .img-box {
      display: flex;
      justify-content: flex-start;

      img {
        width: 80px;
        margin-right: 5px;
      }

      video {
        width: 80px;
        margin-right: 5px;
      }
    }
  }

  .dialog-item {
    p {
      margin: 0;
      padding: 0;
    }

    .el-dialog__body {
      padding-top: 0px !important;
    }

    .group-title {
      width: 100%;

      .icon {
        i {
          margin-right: 10px;
        }
      }
    }

    .img-box {
      display: flex;

      &>p {
        width: 50px;
        font-size: 16px;
        text-align: right;

        margin-right: 10px;
      }

      .info {
        margin-top: 8px;
        margin-left: 10px;

        p {
          color: #a8a4a4;
          font-size: 12px;
          margin: 0;
          padding: 0;
        }
      }
    }

    .avatar-uploader {
      .el-upload {
        border: 1px solid #aeadb2;
        background-color: #aeadb1;
        border-radius: 3px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }

      .el-upload:hover {
        border-color: $themeColor;

        .avatar-uploader-icon {
          color: $themeColor;
        }
      }

      .avatar-uploader-icon {
        font-size: 28px;
        color: #d3d2d8;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
        border: 1px solid #d6d4d4;
        border-radius: 4px;
      }

      .avatar {
        width: 100px;
        height: 100px;
        display: block;
      }

      .el-upload__tip {
        color: #a8acb4;
        font-size: 12px;
        margin-top: -17px;
      }
    }

    .el-collapse-item__header {
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.el-tabs .el-tabs__item.is-active {
  border-top: solid 0px #409eff;
}

.dialog-item {
  p {
    margin: 0;
    padding: 0;
  }

  .el-dialog__body {
    padding-top: 0px !important;
  }

  .group-title {
    width: 100%;

    .icon {
      i {
        margin-right: 10px;
      }
    }
  }

  .img-box {
    display: flex;

    &>p {
      width: 50px;
      font-size: 16px;
      text-align: right;

      margin-right: 10px;
    }

    .info {
      margin-top: 8px;
      margin-left: 10px;

      p {
        color: #a8a4a4;
        font-size: 12px;
        margin: 0;
        padding: 0;
      }
    }
  }

  .avatar-uploader {
    .el-upload {
      border: 1px solid #aeadb2;
      background-color: #aeadb1;
      border-radius: 3px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }

    .el-upload:hover {
      border-color: $themeColor;

      .avatar-uploader-icon {
        color: $themeColor;
      }
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #d3d2d8;
      width: 100px;
      height: 100px;
      line-height: 100px;
      text-align: center;
      border: 1px solid #d6d4d4;
      border-radius: 4px;
    }

    .avatar {
      width: 100px;
      height: 100px;
      display: block;
    }

    .el-upload__tip {
      color: #a8acb4;
      font-size: 12px;
      margin-top: -17px;
    }
  }

  .el-collapse-item__header {
    font-size: 16px;
    font-weight: bold;
  }
}
</style>
