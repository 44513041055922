<!--
 * @Author: Zeng
 * @Date: 2024-03-19 11:39:51
 * @LastEditTime: 2024-03-20 16:14:32
-->
<template>
  <el-dialog class="table-template" title="落地页链接" :visible.sync="show" :append-to-body="true" width="600px" top="20vh">
    <div class="page-container">
      <div class="link-box">
        <span>落地页URL：</span>
        <div class="url-text">
          <el-tooltip class="item" effect="dark" :content="path" placement="top-start">
            <span>{{ path }}</span>
          </el-tooltip>
        </div>
        <el-button type="text" id="copyBtn" :data-clipboard-text="path" @click="copyLink">复制</el-button>
      </div>
    </div>
    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Clipboard from 'clipboard'
export default {
  data() {
    return {
      show: false,
      path: ''
    }
  },

  created() { },

  mounted() { },
  methods: {
    /* --------------------------- Actions --------------------------- */
    showModal(data) {
      this.path = data.path
      this.show = true
    },
    // 关闭弹框
    closeModal() {
      this.show = false
    },

    async copyLink() {
      let clipboard = new Clipboard('#copyBtn')
      clipboard.on('success', (e) => {
        this.$message.success('复制成功')
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message.error('不支持复制')
        // 释放内存
        clipboard.destroy()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.link-box {
  display: flex;
  align-items: center;
  text-align: center;
  flex-wrap: nowrap;
  white-space: nowrap;

  .url-text {
    color: #878788;
    margin-right: 10px;
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
