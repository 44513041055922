<!--
 * @Description: 推广计划模板选择
 * @Date: 2021-11-09 09:32:28
 * @LastEditTime: 2022-02-21 09:41:07
-->
<template>
  <div>
    <Base title="配置区" :desc="taskId ? '基础信息变更后，配置区清空重置' : ''">
    <template slot="topRight">
      <el-button type="primary" :disabled="taskId !== '' && isRuleDeploy" @click="addRule('add')">规则配置</el-button>
    </template>
    <div>
      <el-descriptions direction="vertical" :column="2" border>
        <el-descriptions-item label="广告" content-style="width: 33.33%">
          <el-row>
            <el-col :span="12" class="divider deploy-item">
              <div class="title-count">
                <p>已有计划</p>
                <p>已选 {{ planTotal }} 条</p>
              </div>
              <Plan :plan-modal-visible="planModalVisible" :account-baidu-adv="accountBaiduAdv" :plan-data="planData"
                :task-id="taskId" @byTaskIdGetAllFun="byTaskIdGetAllFun" @clearTaskGenerate="clearTaskGenerate" />
              <div class="footer-add">
                <el-button type="text" style="padding: 0 0" :disabled="isUpdateTask" @click="addPlan">添加</el-button>
              </div>
            </el-col>

            <el-col :span="12" style="padding-left: 8px" class="deploy-item">
              <div class="title-count">
                <p>复用广告</p>
                <p>已选 {{ adTotal }} 条</p>
              </div>
              <Advertisement :ad-modal-visible="adModalVisible" :ad-data="adData" :account-adv="accountBaiduAdv"
                :task-id="taskId" :product-name="productName" :task-site-set="taskSiteSet"
                :task-site-set-str="taskSiteSetStr" @byTaskIdGetAllFun="byTaskIdGetAllFun"
                @clearTaskGenerate="clearTaskGenerate" />
              <div class="footer-add">
                <el-button type="text" style="padding: 0 0" :disabled="isUpdateTask || planTotal === 0"
                  @click="addAd">添加</el-button>
              </div>
            </el-col>
          </el-row>
        </el-descriptions-item>

        <!-- ------------广告创意--------------- -->
        <el-descriptions-item label="广告创意" :span="16" content-style="width: 66.66%">
          <el-row>
            <el-col :span="6" class="divider deploy-item">
              <div class="title-count">
                <p>创意基础信息</p>
              </div>
              <CreativityBaseInfo :creativity-visible="creativityVisible" :creativity-data="creativityData"
                :task-id="taskId" :task-site-set="taskSiteSet" :task-site-set-str="taskSiteSetStr"
                :task-combine="taskCombine" @setCreativityBaseInfo="setCreativityBaseInfo"
                @byTaskIdGetAllFun="byTaskIdGetAllFun" @clearTaskGenerate="clearTaskGenerate" />
              <div class="footer-add">
                <el-button type="text" style="padding: 0 0" :disabled="isUpdateTask"
                  @click="addCreativity">编辑</el-button>
              </div>
            </el-col>
            <!-- 组合方式为指定 -->
            <template v-if="taskCombine === 2">
              <el-col :span="12" style="padding-left: 8px" class="divider deploy-item">
                <div class="title-count">
                  <p>素材组合</p>
                  <p>已选 {{ materialCombinaData.length }} 个</p>
                </div>
                <CreativityMaterialCombination ref="materialCombination" :material-visible="materialCombinaVisible"
                  :account-id="accountId" :project-id="projectId" :creativity-data="creativityData"
                  :material-combina-data="materialCombinaData" :task-id="taskId" :task-site-set="taskSiteSet"
                  @byTaskIdGetAllFun="byTaskIdGetAllFun" @clearTaskGenerate="clearTaskGenerate" />
                <div class="footer-add">
                  <el-button type="text" style="padding: 0 0"
                    :disabled="isUpdateTask || creativityData === null || creativityData.overrideCanvasHeadOption === 'OPTION_CANVAS_OVERRIDE_CREATIVE'"
                    @click="addMaterialCombination">添加</el-button>
                </div>
              </el-col>
            </template>
            <!-- 组合方式为全量 -->
            <template v-else>
              <el-col :span="6" style="padding-left: 8px" class="divider deploy-item">
                <div class="title-count">
                  <p>创意素材</p>
                  <p>已选 {{ creativityTotal }} 组</p>
                </div>
                <CreativityMaterial ref="material" :material-visible="materialVisible" :account-id="accountId"
                  :creativity-material-data-template="creativityMaterialDataTemplate"
                  :creativity-material-data="creativityMaterialData" :project-id="projectId"
                  :creativity-data="creativityData" :task-id="taskId" :task-site-set="taskSiteSet"
                  @byTaskIdGetAllFun="byTaskIdGetAllFun" @clearTaskGenerate="clearTaskGenerate" />
                <div class="footer-add">
                  <el-button type="text" style="padding: 0 0"
                    :disabled="isUpdateTask || creativityData === null || creativityData.overrideCanvasHeadOption === 'OPTION_CANVAS_OVERRIDE_CREATIVE'"
                    @click="addMaterial">添加</el-button>
                </div>
              </el-col>

              <el-col :span="6" style="padding-left: 8px" class="divider deploy-item">
                <div class="title-count">
                  <p>创意文案</p>
                  <p>已选 {{ creativityTextTotal }} 组</p>
                </div>
                <!-- //:account-id="accountId" -->
                <CreativityText :text-visible="textVisible" :creativity-text-data="creativityTextData"
                  :project-id="projectId" :task-id="taskId" :task-site-set="taskSiteSet"
                  @byTaskIdGetAllFun="byTaskIdGetAllFun" @clearTaskGenerate="clearTaskGenerate" />
                <div class="footer-add">
                  <el-button type="text" style="padding: 0 0" :disabled="isUpdateTask" @click="addText">添加</el-button>
                </div>
              </el-col>
            </template>
            <el-col :span="6" style="padding-left: 8px" class="deploy-item">
              <div class="title-count">
                <p>落地页</p>
                <p>已选 {{ ldyTotal }} 条</p>
              </div>
              <Ldy :visible="ldyModalVisible" :account-data="accountBaiduAdv" :form-data="ldyData" :task-id="taskId"
                :task-site-set="taskSiteSet" :creativity-data="creativityData" @byTaskIdGetAllFun="byTaskIdGetAllFun"
                @clearTaskGenerate="clearTaskGenerate" @closeLdy="closeLdy" />
              <!-- <LdyMp v-if="creativityData.pageType === 'PAGE_TYPE_CANVAS_WECHAT'" :visible="ldyModalVisible" :form-data="ldyData" :task-id="taskId" @byTaskIdGetAllFun="byTaskIdGetAllFun" @clearTaskGenerate="clearTaskGenerate" /> -->
              <!-- <LdyAdq v-else :visible="ldyModalVisible" :form-data="ldyData" :task-id="taskId" :account-data="accountBaiduAdv" @byTaskIdGetAllFun="byTaskIdGetAllFun" @clearTaskGenerate="clearTaskGenerate" /> -->
              <div class="footer-add">
                <el-button type="text" style="padding: 0 0" :disabled="isUpdateTask" @click="addLDY">添加</el-button>
              </div>
            </el-col>
          </el-row>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <template slot="footer">
      <div class="justify-sb">
        <div>
          <el-button @click="addBrand">品牌标识</el-button>
          <el-button v-if="taskSiteSetStr.includes('i')" @click="addVideoAccount">视频号</el-button>
          <el-button v-if="creativityData.pageType === 'PAGE_TYPE_CANVAS_WECHAT'" @click="addWecom">企微配置</el-button>
        </div>
        <el-button type="primary" :disabled="!show_ad_button_flag || isUpdateTask"
          @click="showPreviewFun()">预览广告</el-button>
      </div>
    </template>
    </Base>

    <Brand :brand-modal-visible="brandModalVisible" :project-id="projectId" :account-baidu-adv="accountBaiduAdv"
      :brand-data="brandData" :task-id="taskId" @byTaskIdGetAllFun="byTaskIdGetAllFun"
      @clearTaskGenerate="clearTaskGenerate" />
    <WeCom :wecom-modal-visible="wecomModalVisible" :account-data="accountBaiduAdv" :task-id="taskId"
      :wecom-data="wecomData" @byTaskIdGetAllFun="byTaskIdGetAllFun" @clearTaskGenerate="clearTaskGenerate" />

    <RuleDeploy :rule-deploy-visible="ruleDeployVisible" :is-rule-deploy="isRuleDeploy" :task-id="taskId"
      :task-combine="taskCombine" :configured-status="configuredStatus" @setRuleDeploy="setRuleDeploy" />

    <VideoAccountDialog :video-modal-visible="videoModalVisible" :project-id="projectId" :account-data="accountBaiduAdv"
      :video-account-data="videoAccountData" :task-id="taskId" @byTaskIdGetAllFun="byTaskIdGetAllFun" @clearTaskGenerate="clearTaskGenerate" />
  </div>
</template>

<script>
import Base from '../../../advManagement/baidu/baiduBatch/component/base.vue'
import RuleDeploy from './component/ruleDeploy.vue'
import Plan from './component/plan.vue'
import Advertisement from './component/advertisement.vue'
import Ldy from './component/ldy.vue'
import VideoAccountDialog from './component/videoAccountDialog'
// import LdyMp from './component/ldyMp.vue'
// import LdyAdq from './component/ldyAdq.vue'
import Brand from './component/brand.vue'
import WeCom from './component/wecom.vue'

import CreativityBaseInfo from './component/creativityBaseInfo.vue'
import CreativityMaterial from './component/creativityMaterial.vue'
import CreativityText from './component/creativityText.vue'
import CreativityMaterialCombination from './component/creativityMaterialCombination.vue'

import { byTaskIdGetAll, showPreviewAccount, generate, clearGenerate, getSaveAsPreset, deleteAssetImgOrVideo } from '@/network/api/advManagement/advManagementTencent' // 获取计划模板
import { getMaterialList, adcreativeTemplateTypImg } from './util'
import { jsonFormat } from '@/utils/ruoyi'
import moment from 'moment'

export default {
  provide: function () {
    return {
      getDataByLeftQuery: this.getPlanFun,
      close: () => {
        this.planModalVisible = false
        // this.planData = []
        this.adModalVisible = false
        this.brandModalVisible = false
        this.videoModalVisible = false
        this.wecomModalVisible = false
        // this.adData = []
      },
      closeCreativity: () => {
        this.creativityVisible = false
      },
      closeRuleDeploy: () => {
        this.ruleDeployVisible = false
      },
      closeMaterial: () => {
        this.materialVisible = false
      },
      closeMaterialCombina: () => {
        this.materialCombinaVisible = false
      },
      closeText: () => {
        this.textVisible = false
      },
      // closeLdy: () => {
      //   this.ldyModalVisible = false
      // },
      setSelectPageList: () => { }
    }
  },
  components: {
    Base,
    RuleDeploy, // 规则配置页面
    Plan, // 计划页面
    Advertisement, // 广告页面
    CreativityBaseInfo, // 创意基础信息页面
    CreativityMaterial, // 创意素材页面
    CreativityMaterialCombination, // 创意素材组合
    CreativityText,
    Ldy, // 落地页页面
    VideoAccountDialog, // 视频号
    // LdyMp,
    // LdyAdq,
    Brand, // 品牌 页面
    WeCom // 企微配置
  },
  props: {
    accountBaiduAdv: {
      type: Array,
      default: () => []
    },
    taskId: {
      type: Number,
      default: 0
    },
    projectId: {
      type: [String, Number],
      default: ''
    },
    accountId: {
      type: Array,
      default: () => []
    },
    isUpdateTask: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ruleDeployVisible: false, // 规则配置
      isRuleDeploy: false,
      taskRule: '', // 规则
      taskCombine: 1, // 创意素材组合方式
      configuredStatus: 'AD_STATUS_SUSPEND', // 广告状态
      productName: '', // 产品名称
      taskSiteSet: null, // 投放版位
      taskSiteSetStr: '',
      taskStatus: null, // 任务状态
      adcreativeTemplateTypImg,

      // 计划 start
      planModalVisible: false,
      planData: [],
      planTotal: 0,
      // 计划 end

      // 广告 start
      adModalVisible: false,
      adData: [],
      adTotal: 0,
      // 广告 end
      // 创意基础信息 start
      creativityVisible: false,
      creativityData: {
        userId: '',
        taskId: this.taskId,
        taskAdcId: null,
        adcreativeName: `创意名称:${moment().format('YYYYMMDDHHmmss')}`,
        adcreativeTemplateId: 311, // 创意形式
        adcreativeElements: null, // 创意元素
        pageType: 'PAGE_TYPE_CANVAS_WECHAT', // 落地页类型
        siteSet: null,
        promotedObjectType: null, // 推广目标类型
        pageSpec: null, // 落地页信息
        linkPageType: null, // 文字链跳转类型类型
        linkNameType: null, // 链接名称类型
        conversionDataType: null, // 数据展示的数据类型, 默认不使用：CONVERSION_DATA_DEFAULT， 转化数据量：CONVERSION_DATA_ADMETRIC
        conversionTargetType: null, // 数据展示转化行为
        creativeDisplayType: 'CREATIVE_DISPLAY_TYPE_INTELLIGENCE', // 创意展现
        autoDerivedCreativeEnabled: false, // 是否开启自动衍生视频创意
        overrideCanvasHeadOption: this.taskSiteSetStr && this.taskSiteSetStr.includes('a') ? 'OPTION_CREATIVE_OVERRIDE_CANVAS' : null // 使用顶部素材，使用：OPTION_CANVAS_OVERRIDE_CREATIVE， 不使用：OPTION_CREATIVE_OVERRIDE_CANVAS

        // buttonText: '查看详情',
        // bottomText: null,
        // adName: `创意名称:${new Date().getHours()}:${new Date().getMinutes()}`, // null,
        // userId: ''
      },
      creativityTotal: 0,
      show_ad_button_flag: false,
      adcreativesTemplate: null,
      // 创意基础信息 end

      // 创意素材 start
      materialVisible: false,
      creativityMaterialData: [
        {
          name: '创意组01',
          list: [
            {
              taskId: this.taskId,
              taskAssetId: null,
              assetGroupName: '创意组01',
              assetGroupOrderBy: 1, // 组排序
              assetType: 'IMG',
              assetLocalId: null,
              assetLocalUrl: null,
              assetLocalSignature: null,
              assetLocalResizeFileSize: 400,
              assetLocalResizeWidth: 1280,
              assetLocalResizeHeight: 720

              // creativeGroup: '创意组01',
              // autoGenerateCover: null,
              // taskId: this.taskId,
              // assetId: null,
              // dataType: 'image',
              // localId: null,
              // localUrl: null,
              // type: null,
              // maxSize: 500,
              // width: 1280,
              // height: 720
            }
          ]
        }
      ],
      // 创意素材 end

      // 素材组合 start
      materialCombinaVisible: false,
      materialCombinaData: [],
      // 素材组合 end

      // 创意文案 start
      textVisible: false,
      creativityTextData: [
        {
          taskId: this.taskId,
          textId: null,
          desGroupName: '文案组01',
          desGroupOrderBy: 1,
          descriptionContent: null,
          taskDescriptionId: null

          // text1: null,
          // text2: null,
          // wnGroup: '文案组01',
          // wnOrderBy: null
        }
      ],
      creativityTextTotal: 0,
      // 创意文案 end

      // 落地页 start
      ldyModalVisible: false,
      ldyData: {
        list: [],
        flag: false
      },
      ldyTotal: 0,
      // 计划 end
      // 品牌 start
      brandModalVisible: false,
      brandData: [],
      // 品牌 end
      // 视频号 start
      videoModalVisible: false,
      videoAccountData: [],
      // 视频号end
      baiduMePlan: [], // 百度计划模板list
      // 企微配置 start
      wecomModalVisible: false,
      wecomData: []
      // 企微配置 end
    }
  },

  computed: {
    creativityMaterialDataTemplate() {
      return getMaterialList(this.creativityData.adcreativeTemplateId, this.taskId, this.taskSiteSet)
    }
  },

  methods: {
    /**
     * @description 每次更新后获取最新数据
     * @param taskId 当前任务Id
     */
    byTaskIdGetAllFun(taskId = this.taskId) {
      byTaskIdGetAll(taskId).then((res) => {
        if (res.code === 200) {
          this.setData(res.data)
          if (this.show_ad_button_flag && this.taskStatus !== 'configuring') {
            this.showPreviewFun()
          }
        }
      })
    },

    conveTaskSiteSet(taskSiteSet) {
      const siteSets = taskSiteSet.split('')
      const wechatOptions = ['b', 'c']
      const tencentOptions = ['d', 'e', 'f', 'g']
      let firend = ''; let wechat = ''; let tencent = ''; let youlianghui = ''
      let video = ''
      siteSets.forEach(item => {
        if (item === 'a') {
          firend = '1'
        } else if (item === 'i') {
          video = '6'
        } else if (wechatOptions.includes(item)) {
          wechat = '2'
        } else if (tencentOptions.includes(item)) {
          tencent = '3'
        } else if (item === 'h') {
          youlianghui = '4'
        }
      })
      this.taskSiteSet = video ? +video : +(firend + wechat + tencent + youlianghui)
    },

    setData(data) {
      const { adAdqTaskAccountCAdInfs = [], adAdqTaskPages = null, adAdqTaskAssets = null, accountLists = [], adAdqTaskDescriptions = null, adAdqTaskAdcreatives = null, adAdqTask = null, assetAndDescLists = null } = data
      this.$emit('setAccount', { adAdqTask, accountLists })
      this.taskSiteSetStr = adAdqTask.taskSiteSet
      this.taskCombine = adAdqTask.taskCombine
      this.configuredStatus = adAdqTask.configuredStatus
      this.conveTaskSiteSet(adAdqTask.taskSiteSet)
      this.productName = adAdqTask.productName
      this.taskStatus = adAdqTask.taskStatus
      this.brandData = accountLists
      this.wecomData = accountLists
      this.videoAccountData = accountLists
      let isBrand = true
      accountLists.forEach((item) => {
        if (!item.tabrandImg || !item.tabrandName) {
          isBrand = false
          return
        }
      })
      if (adAdqTaskAccountCAdInfs?.length > 0 && adAdqTaskDescriptions !== null && accountLists.length > 0 && (adAdqTaskAdcreatives != null || assetAndDescLists != null) && adAdqTaskPages !== null && isBrand) {
        this.show_ad_button_flag = true
      } else {
        this.show_ad_button_flag = false
        this.$emit('getShowPreviewFun', null)
      }
      // 设置计划、广告
      if (adAdqTaskAccountCAdInfs && adAdqTaskAccountCAdInfs.length > 0) {
        this.planData = adAdqTaskAccountCAdInfs
        let total = 0
        adAdqTaskAccountCAdInfs.map((item) => {
          if (item.campaignId) {
            total += 1
          }
        })
        this.planTotal = total
        // 设置广告
        this.adData = adAdqTaskAccountCAdInfs
        let totalAd = 0
        adAdqTaskAccountCAdInfs.map((item) => {
          if (item.adgroupId) {
            totalAd += 1
          }
        })
        this.adTotal = totalAd
      } else {
        this.planData = []
        this.planTotal = 0
        this.adData = []
        this.adTotal = 0
      }

      // 设置创意
      if (adAdqTaskAdcreatives === null) {
        this.getSaveAsPresetFun(adAdqTask.taskSiteSet)
      } else {
        const { adcreativeTemplateId, taskAdcId, adcreativeElements } = adAdqTaskAdcreatives
        let elements = jsonFormat(adcreativeElements, 'object')
        this.creativityData = {
          ...adAdqTaskAdcreatives,
          userId: '',
          taskId: this.taskId,
          taskAdcId: +taskAdcId,
          adcreativeTemplateId: +adcreativeTemplateId, // 创意形式
          adcreativeElements: elements // 创意元素
        }
      }
      // 设置创意图片
      if (adAdqTaskAssets !== null) {
        this.creativityMaterialData = []
        // this.creativityMaterialData = adAdqTaskAssets
        const assetGroupNames = Object.keys(adAdqTaskAssets).sort()
        assetGroupNames.forEach((name) => {
          let groupList = adAdqTaskAssets[name]
          if (![311, 641, 642, 643, 711, 712].includes(this.creativityData.adcreativeTemplateId) && this.taskSiteSet !== 1 && adAdqTaskAssets[name].length === 1) {
            groupList = [...adAdqTaskAssets[name], {
              ...this.creativityMaterialDataTemplate[1],
              assetLocalUrl: adAdqTaskAssets[name][0].assetLocalUrl
            }]
          }
          this.creativityMaterialData.push({
            name,
            list: groupList
          })
        })
        this.creativityTotal = assetGroupNames.length
      } else {
        setTimeout(() => {
          this.creativityMaterialData = [
            {
              name: '创意组01',
              list: getMaterialList(this.creativityData.adcreativeTemplateId, this.taskId, this.taskSiteSet)
            }
          ]
          // this.creativityMaterialData = [
          //   {
          //     name: '创意组01',
          //     list: [
          //       {
          //         taskId: this.taskId,
          //         taskAssetId: null,
          //         assetGroupName: '创意组01',
          //         assetGroupOrderBy: 1, // 组排序
          //         assetType: 'IMG',
          //         assetLocalId: null,
          //         assetLocalUrl: null,
          //         assetLocalSignature: null,
          //         assetLocalResizeFileSize: 800,
          //         assetLocalResizeWidth: 1280,
          //         assetLocalResizeHeight: 720
          //       }
          //     ]
          //   }
          // ]
        }, 500)
      }

      // 指定素材组合
      if (assetAndDescLists !== null) {
        this.materialCombinaData = [...assetAndDescLists]
      }

      if (this.creativityData && this.creativityData.overrideCanvasHeadOption === 'OPTION_CANVAS_OVERRIDE_CREATIVE' && adAdqTaskAssets) {
        this.creativityMaterialData.forEach((item) => {
          this.deleteAsset(item.name)
        })
        this.creativityMaterialData = [
          {
            name: '创意组01',
            list: getMaterialList(this.creativityData.adcreativeTemplateId, this.taskId, this.taskSiteSet)
          }
        ]
        this.creativityTotal = 0
      }
      // 设置创意文案
      if (adAdqTaskDescriptions !== null) {
        let val = this.settingText(adAdqTaskDescriptions)
        this.creativityTextData = val
        this.creativityTextTotal = val.length
      } else {
        this.creativityTextData = [
          {
            taskId: this.taskId,
            textId: null,
            desGroupName: '文案组01',
            desGroupOrderBy: 1,
            descriptionContent: null,
            taskDescriptionId: null
          }
        ]
      }

      // 设置落地页
      if (adAdqTaskPages !== null) {
        let ldys = []
        Object.keys(adAdqTaskPages).forEach((key) => {
          ldys.push(...adAdqTaskPages[key])
        })
        this.ldyData.list = ldys
        this.ldyData.flag = true
        this.ldyTotal = this.ldyData.list.length || 0
      } else {
        this.ldyTotal = 0
        this.ldyData.list = []
        this.ldyData.flag = false
      }
    },

    /**
     * @description 重新配置
     */
    resetRule() {
      this.startNewTask()
    },

    // 删除素材
    async deleteAsset(assetGroupName) {
      await deleteAssetImgOrVideo({ taskId: this.taskId, assetGroupName })
    },

    // 获取预存创意
    getSaveAsPresetFun(taskSiteSet) {
      getSaveAsPreset(taskSiteSet).then(({ code, data }) => {
        if (code === 200 && data) {
          const { adcreativeTemplateId, taskAdcId, adcreativeElements } = data
          let elements = jsonFormat(adcreativeElements, 'object')
          this.creativityData = {
            ...data,
            userId: '',
            taskId: this.taskId,
            taskAdcId: +taskAdcId,
            adcreativeTemplateId: +adcreativeTemplateId, // 创意形式
            adcreativeElements: elements // 创意元素
          }
        } else {
          this.creativityData = {
            userId: '',
            taskId: this.taskId,
            taskAdcId: null,
            adcreativeName: `创意名称:${moment().format('YYYYMMDDHHmmss')}`,
            adcreativeTemplateId: this.adcreativeTemplateTypImg[this.taskSiteSet][0].label, // 创意形式
            adcreativeElements: null, // 创意元素
            pageType: 'PAGE_TYPE_CANVAS_WECHAT', // 落地页类型
            siteSet: null,
            promotedObjectType: null, // 推广目标类型
            pageSpec: null, // 落地页信息
            linkPageType: null, // 文字链跳转类型类型
            linkNameType: null, // 链接名称类型
            conversionDataType: null, // 数据展示的数据类型, 默认不使用：CONVERSION_DATA_DEFAULT， 转化数据量：CONVERSION_DATA_ADMETRIC
            conversionTargetType: null, // 数据展示转化行为
            creativeDisplayType: 'CREATIVE_DISPLAY_TYPE_INTELLIGENCE', // 创意展现
            autoDerivedCreativeEnabled: false, // 是否开启自动衍生视频创意
            overrideCanvasHeadOption: this.taskSiteSetStr && this.taskSiteSetStr.includes('a') ? 'OPTION_CREATIVE_OVERRIDE_CANVAS' : null // 使用顶部素材，使用：OPTION_CANVAS_OVERRIDE_CREATIVE， 不使用：OPTION_CREATIVE_OVERRIDE_CANVAS
          }
        }
      })
    },

    /**
     * @desc 获取预览广告信息
     */
    async showPreviewFun(params = { pageNum: 1, pageSize: 20 }) {
      if (this.taskStatus === 'configuring') {
        const res = await generate(this.taskId)
        if (res.code === 200) {
          const { code, data } = await showPreviewAccount({ taskId: this.taskId })
          if (code === 200) {
            if (data) {
              this.$emit('getShowPreviewFun', data)
            } else {
              this.$message.info('未生成数据!')
            }
          }
        }
      } else {
        const { code, data } = await showPreviewAccount({ taskId: this.taskId })
        if (code === 200) {
          if (data) {
            this.$emit('getShowPreviewFun', data)
          } else {
            this.$message.info('未生成数据!')
          }
        }
      }
    },

    // 清空任务预览
    clearTaskGenerate() {
      if (this.taskStatus !== 'configuring') {
        clearGenerate(this.taskId).then(({ code, data }) => {
          if (code === 200) {
            this.$emit('getShowPreviewFun', null)
          }
        })
      }
    },

    /**
     * @desc 设置文案格式
     */
    settingText(desMap) {
      let list = []
      Object.keys(desMap)
        .sort()
        .map((key) => {
          const desList = desMap[key]
          if (list.length === 0 || !list.some((s) => s.desGroupName === key)) {
            desList.forEach((item) => {
              list.push({
                ...item,
                textId: +item.textId
              })
            })
          } else {
            list.forEach((e) => {
              desList.forEach((item) => {
                if (e.desGroupName === item.desGroupName) {
                  e.descriptionContent = item.descriptionContent
                }
              })
            })
          }
        })
      return list
    },
    /**
     * @description 添加计划
     */
    addPlan() {
      this.$emit('saveTaskFun', 'planModalVisible')
    },
    /**
     * @description 添加广告
     */
    addAd() {
      this.$emit('saveTaskFun', 'adModalVisible')
    },

    /**
     * @description 创意基础信息
     */
    addCreativity() {
      this.$emit('saveTaskFun', 'creativityVisible')
    },
    /**
     * @description 创意素材
     */
    addMaterial() {
      this.$emit('saveTaskFun', 'materialVisible')
    },

    /**
     * @description 素材组合
     */
    addMaterialCombination() {
      this.$emit('saveTaskFun', 'materialCombinaVisible')
    },

    /**
     * @description 创意文案
     */
    addText() {
      this.$emit('saveTaskFun', 'textVisible')
    },
    /**
     * @description 落地页
     */
    addLDY() {
      this.$emit('saveTaskFun', 'ldyModalVisible')
    },
    closeLdy() {
      this.ldyModalVisible = false
    },

    /**
     * @description 品牌
     */
    addBrand() {
      this.$emit('saveTaskFun', 'brandModalVisible')
    },

    /**
     * @description 视频号
     */
    addVideoAccount() {
      this.$emit('saveTaskFun', 'videoModalVisible')
    },

    /** 企微配置 */
    addWecom() {
      this.$emit('saveTaskFun', 'wecomModalVisible')
    },

    /**
     * @description 创意基础信息
     */
    addRule(tag) {
      if (tag === 'add') {
        this.isRuleDeploy = false
      } else {
        // this.isRuleDeploy = true
        // this.taskRule = '1-1'
        // this.taskCombine = 1
        // this.ruleDeployVisible = false
      }

      this.$emit('saveTaskFun', 'ruleDeployVisible')
    },

    /**
     * @description 创意基础信息 配置成功后, 获取主任务信息
     */
    setCreativityBaseInfo() {
      this.materialCombinaData = []
      this.byTaskIdGetAllFun()
      // 改变创意基础信息后要清空创意素材=
      this.creativityMaterialData = [
        {
          name: '创意组01',
          list: getMaterialList(this.creativityData.adcreativeTemplateId, this.taskId, this.taskSiteSet)
        }
      ]
      this.creativityMaterialDataTemplate.value = getMaterialList(this.creativityData.adcreativeTemplateId, this.taskId, this.taskSiteSet)
    },

    /**
     * @description 规则配置成功后, 获取主任务信息
     */
    setRuleDeploy(value, rule, taskCombine, configuredStatus) {
      this.isRuleDeploy = value
      this.taskRule = rule
      this.taskCombine = taskCombine
      this.configuredStatus = configuredStatus
      this.ruleDeployVisible = false
      this.addRule()
      // this.byTaskIdGetAllFun()
    },
    /**
     * @description 开始新任务
     */
    startNewTask() {
      // this.isRuleDeploy = false
      this.show_ad_button_flag = false
      Object.assign(this.$data, this.$options.data())
      // 计划
      this.planData = []
      // 广告
      this.adData = []
      // 创意基础信息
      this.creativityData = {
        userId: '',
        taskId: this.taskId,
        taskAdcId: null,
        adcreativeName: `创意名称:${moment().format('YYYYMMDDHHmmss')}`,
        adcreativeTemplateId: 311, // 创意形式
        adcreativeElements: null, // 创意元素
        pageType: 'PAGE_TYPE_CANVAS_WECHAT', // 落地页类型
        siteSet: null,
        promotedObjectType: null, // 推广目标类型
        pageSpec: null, // 落地页信息
        linkPageType: null, // 文字链跳转类型类型
        linkNameType: null, // 链接名称类型
        conversionDataType: null, // 数据展示的数据类型, 默认不使用：CONVERSION_DATA_DEFAULT， 转化数据量：CONVERSION_DATA_ADMETRIC
        conversionTargetType: null, // 数据展示转化行为
        creativeDisplayType: 'CREATIVE_DISPLAY_TYPE_INTELLIGENCE', // 创意展现
        autoDerivedCreativeEnabled: false, // 是否开启自动衍生视频创意
        overrideCanvasHeadOption: this.taskSiteSetStr && this.taskSiteSetStr.includes('a') ? 'OPTION_CREATIVE_OVERRIDE_CANVAS' : null // 使用顶部素材，使用：OPTION_CANVAS_OVERRIDE_CREATIVE， 不使用：OPTION_CREATIVE_OVERRIDE_CANVAS
      }
      this.adcreativesTemplate = null
      // 创意素材
      this.creativityMaterialData = [
        {
          name: '创意组01',
          list: [
            {
              taskId: this.taskId,
              taskAssetId: null,
              assetGroupName: '创意组01',
              assetGroupOrderBy: 1, // 组排序
              assetType: 'IMG',
              assetLocalId: null,
              assetLocalUrl: null,
              assetLocalSignature: null,
              assetLocalResizeFileSize: 140,
              assetLocalResizeWidth: 1280,
              assetLocalResizeHeight: 720

              // creativeGroup: '创意组01',
              // autoGenerateCover: null,
              // taskId: this.taskId,
              // assetId: null,
              // dataType: 'image',
              // localId: null,
              // localUrl: null,
              // type: null,
              // maxSize: 140,
              // width: 1280,
              // height: 720
            }
          ]
        }
      ]
      // 创意文案
      this.creativityTextData = [
        {
          taskId: this.taskId,
          textId: null,
          desGroupName: '文案组01',
          desGroupOrderBy: 1,
          descriptionContent: null,
          taskDescriptionId: null

          // taskId: this.taskId,
          // textId: null,
          // text1: null,
          // text2: null,
          // wnGroup: '文案组01',
          // wnOrderBy: null
        }
      ]
      // 落地页
      this.ldyData = {
        list: [],
        flag: false
      }
      // 品牌
      this.brandData = []

      // 视频号
      this.videoAccountData = []
    }
  }
}
</script>

<style scoped lang="scss">
.divider {
  border-right: 1px solid #dcdfe6;
  padding-right: 5px;
}

.deploy-item {
  display: flex;
  flex-direction: column;
}

.title-count {
  border-bottom: 1px #ccc solid;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 0 3px 8px 1px;

  p {
    padding: 0;
    margin: 0;
  }
}

.footer-add {
  border-top: 1px #ccc solid;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding-top: 10px;
  cursor: pointer;
  color: #409eff;
}
</style>
