var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "append-to-body": true,
        width: "650px",
        top: "20vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.onCloseDialog,
      },
    },
    [
      _c(
        "span",
        {
          staticClass: "dialog-title",
          attrs: { slot: "title" },
          slot: "title",
        },
        [_vm._v(_vm._s(_vm.dialogTitle))]
      ),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "100px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "名称", prop: "textGroupName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入文案组名称" },
                model: {
                  value: _vm.form.textGroupName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "textGroupName", $$v)
                  },
                  expression: "form.textGroupName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "关联项目", prop: "projectId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "请选择关联项目",
                  },
                  on: { change: _vm.handleSelect },
                  model: {
                    value: _vm.form.projectId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "projectId", $$v)
                    },
                    expression: "form.projectId",
                  },
                },
                _vm._l(_vm.projectList, function (itm, idx) {
                  return _c("el-option", {
                    key: idx,
                    attrs: { label: itm.projectName, value: itm.bsProjectId },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "关联产品", prop: "productId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    filterable: "",
                    disabled: !_vm.form.projectId,
                    clearable: "",
                    placeholder: "请选择关联产品",
                  },
                  model: {
                    value: _vm.form.productId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "productId", $$v)
                    },
                    expression: "form.productId",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "不限", value: 0 } }),
                  _vm._l(_vm.productList, function (itm, idx) {
                    return _c("el-option", {
                      key: idx,
                      attrs: { label: itm.productName, value: itm.bsProductId },
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "文案" } }, [
            _c(
              "div",
              { staticClass: "content" },
              _vm._l(_vm.txtList, function (t, i) {
                return _c(
                  "div",
                  { key: `tag-${i}`, staticClass: "tag-input" },
                  [
                    _c(
                      "el-select",
                      {
                        key: "`txtList-${i}`",
                        staticStyle: { width: "100%" },
                        attrs: {
                          loading: _vm.isLoading,
                          filterable: "",
                          clearable: "",
                          "value-key": "textId",
                          remote: "",
                          "remote-method": _vm.remoteMthod,
                        },
                        model: {
                          value: _vm.txtList[i],
                          callback: function ($$v) {
                            _vm.$set(_vm.txtList, i, $$v)
                          },
                          expression: "txtList[i]",
                        },
                      },
                      [
                        _vm._l(_vm.textList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.text, value: item },
                          })
                        }),
                        _c(
                          "div",
                          [
                            _c("c-pagination", {
                              attrs: {
                                "page-size": _vm.pageSize,
                                "page-no": _vm.pageNum,
                                total: _vm.total,
                                "get-data-fun": _vm.getPageData,
                              },
                              on: {
                                "update:pageSize": function ($event) {
                                  _vm.pageSize = $event
                                },
                                "update:page-size": function ($event) {
                                  _vm.pageSize = $event
                                },
                                "update:pageNo": function ($event) {
                                  _vm.pageNum = $event
                                },
                                "update:page-no": function ($event) {
                                  _vm.pageNum = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                    _vm.txtList.length > 1
                      ? _c(
                          "div",
                          {
                            staticClass: "opt-icon-container",
                            on: {
                              click: function ($event) {
                                return _vm.onClickDelText(i)
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "opt-icon",
                              attrs: {
                                src: require("@/assets/image/icon/icon-common-minus.png"),
                                alt: "",
                              },
                            }),
                            _c("img", {
                              staticClass: "opt-icon-hl",
                              attrs: {
                                src: require("@/assets/image/icon/icon-common-minus-hl.png"),
                                alt: "",
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              0
            ),
            _c(
              "div",
              {
                staticClass: "tag-add-container",
                on: { click: _vm.onClickAddText },
              },
              [
                _c("img", {
                  staticClass: "add-icon-hl",
                  attrs: {
                    src: require("@/assets/image/icon/icon-common-bg-plus-hl.png"),
                    alt: "",
                  },
                }),
                _c("span", [_vm._v("添加文案")]),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "btn", on: { click: _vm.closeModal } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: {
                type: "primary",
                loading: _vm.isLoading,
                disabled: _vm.isLoading,
              },
              on: {
                click: function ($event) {
                  return _vm.clickConfirm("form")
                },
              },
            },
            [_vm._v("确 定 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }