var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      attrs: {
        title: "创意素材",
        size: "900px",
        "append-to-body": true,
        "custom-class": "dialog",
        visible: _vm.show,
        "label-position": "right",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.onCloseDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "drawer-content" },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "120px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "每个创意组配置" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "100px" },
                    attrs: { max: 10, controls: false },
                    on: {
                      change: function ($event) {
                        return _vm.handleSizeChange("videoSize")
                      },
                    },
                    model: {
                      value: _vm.videoSize,
                      callback: function ($$v) {
                        _vm.videoSize = $$v
                      },
                      expression: "videoSize",
                    },
                  }),
                  _vm._v(" 个视频 "),
                  _c("el-input-number", {
                    staticStyle: { width: "100px" },
                    attrs: { max: 12, controls: false },
                    on: {
                      change: function ($event) {
                        return _vm.handleSizeChange("imageSize")
                      },
                    },
                    model: {
                      value: _vm.imageSize,
                      callback: function ($$v) {
                        _vm.imageSize = $$v
                      },
                      expression: "imageSize",
                    },
                  }),
                  _vm._v(" 个图片 "),
                  _c("div", [
                    _c("span", { staticClass: "tip" }, [
                      _vm._v(
                        "提示：每组素材视频数量不得超过10，图片数量不得超过12"
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "自动生成封面" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      model: {
                        value: _vm.generateCover,
                        callback: function ($$v) {
                          _vm.generateCover = $$v
                        },
                        expression: "generateCover",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "关闭", value: 0 } }),
                      _c("el-option", { attrs: { label: "开启", value: 1 } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "group-info" }, [
            _c(
              "div",
              [
                _c("span", { staticClass: "group-label" }, [
                  _vm._v("创意制作"),
                ]),
                _c("span", { staticClass: "group-count" }, [
                  _vm._v(
                    "创意组数量：" + _vm._s(_vm.creativeMedias.length) + " 组"
                  ),
                ]),
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "10px" },
                    attrs: {
                      disabled: _vm.creativeMedias.length >= _vm.maxSelect,
                      type: "primary",
                      size: "mini",
                      plain: "",
                    },
                    on: { click: _vm.handleAddGroup },
                  },
                  [_vm._v("添加创意组 ")]
                ),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "danger",
                      disabled: !_vm.creativeMedias.length,
                      plain: "",
                      icon: "el-icon-delete",
                      size: "mini",
                    },
                    on: { click: _vm.handleEmpty },
                  },
                  [_vm._v("清空")]
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "creative" },
            _vm._l(_vm.creativeMedias, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "creative-content" },
                [
                  _c("div", { staticClass: "creative-group" }, [
                    _c("div", { staticClass: "creative-group-header" }, [
                      _c("span", { staticClass: "creative-group-name" }, [
                        _vm._v("创意组" + _vm._s(index + 1)),
                      ]),
                      _c("span", {
                        staticClass: "el-icon-delete",
                        staticStyle: { color: "red", cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.onClickDelGroup(index)
                          },
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "creative-group-body" }, [
                      _c("div", { staticClass: "creative-tabs" }, [
                        _c(
                          "div",
                          {
                            class: [
                              "tag",
                              item.activeMenu === 2 ? "active" : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.onClickTag(index, 2)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "视频(" +
                                _vm._s(item.videos.length) +
                                "/" +
                                _vm._s(_vm.videoSize) +
                                ")"
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            class: [
                              "tag",
                              item.activeMenu === 1 ? "active" : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.onClickTag(index, 1)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "图片(" +
                                _vm._s(item.images.length) +
                                "/" +
                                _vm._s(_vm.imageSize) +
                                ")"
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.activeMenu === 1,
                              expression: "item.activeMenu === 1",
                            },
                          ],
                          staticClass: "creative-image-material",
                        },
                        [
                          _vm._l(item.images, function (itm, idx) {
                            return _c(
                              "div",
                              {
                                key: idx,
                                staticClass: "creative-image-material-item",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-circle-close",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.onClickDelImage(index, idx)
                                    },
                                  },
                                }),
                                itm.imageUrl !== ""
                                  ? _c("img", {
                                      attrs: {
                                        src: itm.localImageUrl,
                                        alt: "",
                                      },
                                    })
                                  : _vm._e(),
                                itm.imageUrl === ""
                                  ? _c("div", { staticClass: "avatar" }, [
                                      _c("i", {
                                        staticClass: "el-icon-picture-outline",
                                      }),
                                    ])
                                  : _vm._e(),
                                _c("div", { staticClass: "avatar-mask" }, [
                                  _c(
                                    "p",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.onClickUploadImage(
                                            index,
                                            idx,
                                            "image"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("上传图片")]
                                  ),
                                ]),
                              ]
                            )
                          }),
                          item.images.length < _vm.imageSize
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "creative-image-material-item-add",
                                  on: {
                                    click: function ($event) {
                                      return _vm.onClickAddImage(index)
                                    },
                                  },
                                },
                                [_vm._v("+")]
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.activeMenu === 2,
                              expression: "item.activeMenu === 2",
                            },
                          ],
                          staticClass: "creative-video-material",
                        },
                        [
                          _vm._l(item.videos, function (itm, idx) {
                            return _c(
                              "div",
                              {
                                key: idx,
                                staticClass: "creative-video-material-item",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-circle-close",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.onClickDelVideo(index, idx)
                                    },
                                  },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "creative-video-material-item-left",
                                  },
                                  [
                                    itm.videoUrl !== ""
                                      ? _c("video", {
                                          attrs: {
                                            src: itm.localVideoUrl,
                                            controls: "",
                                          },
                                        })
                                      : _vm._e(),
                                    itm.videoUrl === ""
                                      ? _c("div", { staticClass: "avatar" }, [
                                          _c("i", {
                                            staticClass: "el-icon-video-camera",
                                          }),
                                        ])
                                      : _vm._e(),
                                    _c("div", { staticClass: "avatar-mask" }, [
                                      _c(
                                        "p",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.onClickUploadVideo(
                                                index,
                                                idx
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("上传视频")]
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm.generateCover == 0 || itm.videoImageUrl
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "creative-video-material-item-right",
                                      },
                                      [
                                        itm.videoImageUrl
                                          ? _c("el-image", {
                                              attrs: {
                                                src: itm.localImageUrl,
                                                fit: "contain",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.generateCover == 0
                                          ? [
                                              !itm.videoImageUrl
                                                ? _c(
                                                    "div",
                                                    { staticClass: "avatar" },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-picture-outline",
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                { staticClass: "avatar-mask" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.onClickUploadImage(
                                                            index,
                                                            idx,
                                                            "video"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("上传封面")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }),
                          item.videos.length < _vm.videoSize
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "creative-video-material-item-add",
                                  on: {
                                    click: function ($event) {
                                      return _vm.onClickAddVideo(index)
                                    },
                                  },
                                },
                                [_vm._v("+")]
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-button",
            { staticClass: "btn", on: { click: _vm.closeModal } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: {
                type: "primary",
                loading: _vm.isLoading,
                disabled: !_vm.creativeMedias.length,
              },
              on: { click: _vm.clickConfirm },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
      _c("image-select-dialog", {
        ref: "imageSelectDialog",
        attrs: {
          "project-list": _vm.projectList,
          advertisers: _vm.advertisers,
        },
        on: { confirm: _vm.handleSelectImage },
      }),
      _c("video-select-dialog", {
        ref: "videoSelectDialog",
        attrs: {
          "project-list": _vm.projectList,
          advertisers: _vm.advertisers,
        },
        on: { confirm: _vm.handleSelectVideo },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }