<!--
 * @Description: 落地页模板
 * @Date: 2021-10-18 10:49:17
 * @LastEditTime: 2024-03-18 18:29:44
-->

<template>
  <div>
    <el-row :gutter="20" type="flex" justify="space-between">
      <el-col>
        <el-form ref="formInline" :inline="true" :model="formInline" label-width="130px" :rules="rules">
          <el-form-item label="落地页名称:" prop="name">
            <el-input v-model="formInline.name" style="width:300px" />
          </el-form-item>
          <el-form-item label="落地页顶部名称:" prop="topName">
            <el-input v-model="formInline.topName" style="width:300px" />
          </el-form-item>
          <el-form-item label="落地页背景:">
            <el-dropdown style="width: 28px">
              <div class="ldy-bg-color" :style="`background:${formInline.colors}`"></div>
              <el-dropdown-menu slot="dropdown">
                <twitter-color :colors="formInline.colors" style="margin-top: 10px" @change="(value) => (formInline.colors = value)" />
              </el-dropdown-menu>
            </el-dropdown>
            <!-- palette -->
          </el-form-item>

          <el-form-item v-if="id">
            <el-checkbox v-model="isCoverPage" style=" margin-left: 30px" :true-label="1" :false-label="0"> 同步更新关联链接</el-checkbox>
          </el-form-item>
          <el-form-item v-if="type !== 'tamper'" label="是否开启篡改:">
            <el-switch v-model="isRedirect" :active-value="1" :inactive-value="0" @change="switchChange"> </el-switch>
          </el-form-item>

          <el-form-item v-if="type !== 'tamper' && isRedirect" label="屏蔽地区:">
            <el-select v-model="forbiddenArea" multiple style="width: 340px" filterable clearable placeholder="请选择">
              <el-option v-for="item in provinceList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue"> </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2"><el-button type="primary" @click="submit">提交并保存</el-button></el-col>
    </el-row>
    <el-row :gutter="60" style="display: flex; flex-wrap: nowrap;">
      <!-- 选择区 -->
      <el-col :span="7" style="min-width: 450px">
        <div class="grid-content bg-purple">
          <WidgetGallery :seleted-widgets-index="seletedWidgetsIndex" @setFinalWidgets="setFinalWidgets" />
        </div>
      </el-col>

      <!-- 工作区 -->
      <el-col style="width: 415px; margin-right: 60px; height: 720px">
        <div class="grid-content bg-purple workbench" :style="`background:${formInline.colors}`">
          <Workbench :final-widgets="finalWidgets" :seleted-widgets-index="seletedWidgetsIndex" @updateFinalWidgets="updateFinalWidgets" @setFinalWidgetsCount="setFinalWidgetsCount" @setSelectedIndex="setSelectedIndex" />
        </div>
      </el-col>

      <!-- 编辑区 -->
      <el-col :xl="10" :lg="8" style="min-width: 450px">
        <div class="grid-content bg-purple">
          <AdEditors :final-widgets="finalWidgets" :seleted-widgets-index="seletedWidgetsIndex" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import WidgetGallery from './widgetGallery.vue'
import Twitter from '@/components/twitterColor.vue'
import Workbench from './workbench.vue' // formalSave
import AdEditors from './adEditors.vue'
import { Add, Page, Update, formalSave, formalUpdate, getFormal } from '@/network/api/assetManagement/assetManagementHugeLandingPageTemp'
import { jsonFormat } from '@/utils/ruoyi'
import { widgetMapData } from './indexData'
export default {
  components: {
    'twitter-color': Twitter,
    WidgetGallery,
    Workbench,
    AdEditors
  },

  data() {
    return {
      NODE_ENV: process.env.NODE_ENV,
      rules: {
        name: [{ required: true, message: '落地页名称不能为空', trigger: 'blur' }],
        topName: [{ required: true, message: '落地页顶部名称为空', trigger: 'blur' }]
      },
      formInline: {
        name: '',
        pid: null,
        topName: '',
        colors: '#FFFFFF'
      },
      id: this.$route.params.id,
      type: this.$route.params.type || '',
      isCoverPage: 0,
      isRedirect: 0,
      forbiddenArea: [],
      provinceList: [],
      finalWidgets: [], // 选择的组件集合
      finalWidgetsCount: 0, // 选择的组件个数
      seletedWidgetsIndex: -1 // 当前选中的索引
    }
  },

  // watch: {
  //   isRedirect(value) {
  //     this.forbiddenArea = value ? ['110000', '310000', '510000', '810000'] : []
  //   }
  // },
  mounted() {
    this.editLoading()
    this.getDicts('province').then((response) => {
      this.provinceList = response.data
    })
  },
  methods: {
    switchChange(value) {
      this.forbiddenArea = value ? ['110000', '310000', '510000', '810000', '88', 'CN'] : []
    },
    /**
     * @description: 添加组件到工作区
     * @param {*} item 选择的组件类容
     */
    setFinalWidgets(item) {
      this.finalWidgets.push({
        ...item,
        id: this.finalWidgetsCount
      })
      console.log(this.finalWidgets)
      this.seletedWidgetsIndex = this.finalWidgetsCount
      this.finalWidgetsCount++
    },

    /**
     * @description: 设置选择的组件集合数量
     * @param {*} count
     */
    setFinalWidgetsCount(count) {
      this.finalWidgetsCount = count
    },
    /**
     * @description: 设置当前选中的索引
     * @param {*} item 当前选中的索引
     */
    setSelectedIndex(index) {
      this.seletedWidgetsIndex = index
    },

    /**
     * @description: 修改FinalWidgets
     * @param {*} list
     */
    updateFinalWidgets(list) {
      this.finalWidgets = list
    },

    /**
     * @description:编辑时获取数据
     */
    editLoading() {
      const { id, copyId } = this.$route.params
      if (!id && !copyId) return
      if (this.type === 'tamper') {
        getFormal(+this.id).then(({ code, data }) => {
          if (data === '') {
            this.getPage(id, copyId)
          } else {
            if (code === 200) {
              const { pageName, pageTemplate, forbiddenArea, isRedirect } = data
              const page_template = jsonFormat(pageTemplate.replace(/&gt;/g, '>'))
              if (copyId) {
                this.formInline.name = `${pageName}-复制`
                this.formInline.pid = copyId
              } else {
                this.formInline.pid = null
                this.formInline.name = pageName
                this.forbiddenArea = forbiddenArea ? forbiddenArea.split(',') : []
                this.isRedirect = isRedirect
              }
              this.formInline.topName = page_template[0].title_name
              this.formInline.colors = page_template[1].backgroundColor
              let new_page_template = page_template.filter((f) => !['hideCopyGh', 'platform'].includes(f.widgetTypeV2))
              this.finalWidgets = new_page_template.splice(2, page_template.length)

              this.seletedWidgetsIndex = 0
              this.finalWidgetsCount = page_template.length - 2
            }
          }
        })
      } else {
        this.getPage(id, copyId)
      }
    },

    getPage(id, copyId) {
      Page({ id: +(id || copyId) }).then(({ code, data }) => {
        if (code === 200) {
          const { pageName, pageTemplate, forbiddenArea, isRedirect } = data.records[0]
          const page_template = jsonFormat(pageTemplate)
          if (copyId) {
            this.formInline.name = `${pageName}-复制`
            this.formInline.pid = copyId
          } else {
            this.formInline.pid = null
            this.formInline.name = pageName
            this.forbiddenArea = forbiddenArea ? forbiddenArea.split(',') : []
            this.isRedirect = isRedirect
          }
          this.formInline.topName = page_template[0].title_name
          this.formInline.colors = page_template[1].backgroundColor
          let new_page_template = page_template.filter((f) => !['hideCopyGh', 'platform'].includes(f.widgetTypeV2))
          this.finalWidgets = new_page_template.splice(2, page_template.length)

          this.seletedWidgetsIndex = 0
          this.finalWidgetsCount = page_template.length - 2
        }
      })
    },

    /**
     * @description: 提交
     */
    submit() {
      const { name, pid, topName, colors } = this.formInline
      let page_template = [
        {
          title: name,
          title_name: topName,
          widgetTypeV2: 'title'
        },
        {
          backgroundColor: colors,
          widgetTypeV2: 'body'
        },
        ...this.finalWidgets,
        widgetMapData.platform
      ]

      if (!this.finalWidgets.some((s) => s.widgetTypeV2 === 'copyGh')) {
        page_template = [...page_template, widgetMapData.hideCopyGh]
      }

      let params = {
        pageName: name,
        pageTemplate: JSON.stringify(page_template),
        pageType: 'oceanengine'
      }

      if (this.isRedirect) {
        params.forbiddenArea = this.forbiddenArea.join(',')
        params.isRedirect = this.isRedirect
      }

      if (pid) {
        params.pid = pid
      }

      this.$refs['formInline'].validate((valid) => {
        if (valid) {
          // 篡改时调用
          if (this.type === 'tamper') {
            getFormal(+this.id).then((res) => {
              if (res.data === '') {
                formalSave({ ...params, parentContentId: +this.id }).then((res) => {
                  if (res.code === 200) {
                    this.$message.success('篡改成功!')
                    this.$router.push({ path: '/hugeLandingPageTemp' })
                  }
                })
              } else {
                formalUpdate({ ...params, isCoverPage: this.isCoverPage, id: res.data.id, parentContentId: +this.id }).then((res) => {
                  if (res.code === 200) {
                    this.$message.success('篡改编辑成功!')
                    this.$router.push({ path: '/hugeLandingPageTemp' })
                  }
                })
              }
            })
          } else {
            if (this.$route.params.id) {
              Update({ ...params, isCoverPage: this.isCoverPage, id: +this.$route.params.id }).then((res) => {
                if (res.code === 200) {
                  this.$message.success('编辑成功!')
                  this.$router.push({ path: '/hugeLandingPageTemp' })
                }
              })
            } else {
              Add({ ...params }).then((res) => {
                if (res.code === 200) {
                  this.$message.success('添加成功!')
                  this.$router.push({ path: '/hugeLandingPageTemp' })
                }
              })
            }
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #f2f2f2;
}
.bg-purple {
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.ldy-bg-color {
  width: 28px;
  height: 28px;
  border: 5px solid #bfc2c7;
  border-radius: 4px;
}

.workbench {
  height: 720px;
  width: 415px;
  background: #fcfcfc;
  overflow: hidden;
  overflow-y: auto;
}
</style>
