<!--
 * @Description: Do not edit
 * @Date: 2021-11-05 15:46:06
 * @LastEditTime: 2022-05-13 13:50:42
-->
<template>
  <div>
    <el-row>
      <el-col :span="24" class=" mb-20">
        <div>
          <el-steps :active="stepsActive" finish-status="success" simple>
            <el-step title="步骤1" description="推广计划模板选择" />
            <el-step title="步骤2" description="推广单元模板选择" />
            <el-step title="步骤3" description="推广创意模板选择" />
          </el-steps>
        </div>
      </el-col>
    </el-row>
    <div>
      <step-one v-show="stepsActive === 0" @getPlanData="getPlanData" @setBaiduIdList="setBaiduIdList" @setHousekeeperInfo="setHousekeeperInfo" />
      <step-two v-show="stepsActive === 1" :baidu-id-list="baiduIdList" :housekeeper-info="housekeeperInfo" @getUnitData="getUnitData" />
      <step-three v-show="stepsActive === 2" :housekeeper-info="housekeeperInfo" />
    </div>
  </div>
</template>

<script>
import StepOne from './step_one.vue'
import StepTwo from './step_two.vue'
import StepThree from './step_three.vue'
export default {
  provide: function() {
    return {
      setStep: (type) => {
        if (type === 'next') {
          this.stepsActive = this.stepsActive + 1
        } else if (type === 'prev') {
          this.stepsActive = this.stepsActive - 1
        } else {
          this.stepsActive = 0
        }
      },
      dataAll: this.dataAll
    }
  },
  components: {
    'step-one': StepOne,
    'step-two': StepTwo,
    'step-three': StepThree
  },
  data() {
    return {
      stepsActive: 0, // 步骤条
      dataAll: {
        plan: [], // 计划数据
        unit: [] // 单元数据
      },
      housekeeperId: 0,
      housekeeperInfo: {},
      baiduIdList: [] // 选择的投放账户
    }
  },

  methods: {
    // 获取计划数据
    getPlanData(data) {
      this.dataAll.plan = data
    },

    // 获取单元数据
    getUnitData(data) {
      this.dataAll.unit = data
    },

    // 获取选中的投放账户
    setBaiduIdList(list) {
      this.baiduIdList = list
    },

    // 获取选中的管家账户信息
    setHousekeeperInfo(info) {
      this.housekeeperInfo = info
    }
  }
}
</script>

<style scoped lang="scss"></style>
