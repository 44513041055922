<template>
  <el-dialog
    :title="isEdit ? '编 辑': '新 增'"
    width="45%"
    :visible.sync="visible"
    :before-close="close"
    label-position="right"
    :modal-append-to-body="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <el-form ref="ent" :model="ent" :rules="rules" label-width="110px">
      <el-form-item label="" prop="publisBaiduId">
        <el-input v-model="ent.publisBaiduId" />
      </el-form-item>
      <el-form-item label="模板计划名称" prop="templatePlanName">
        <el-input v-model="ent.templatePlanName" />
      </el-form-item>
      <el-form-item label="投放平台" prop="launchPlatform">
        <el-input v-model="ent.launchPlatform" />
      </el-form-item>
      <el-form-item label="" prop="housekeeperId">
        <el-input v-model="ent.housekeeperId" />
      </el-form-item>
      <el-form-item label="账户名称" prop="launchAccountName">
        <el-input v-model="ent.launchAccountName" />
      </el-form-item>
      <el-form-item label="" prop="baiduId">
        <el-input v-model="ent.baiduId" />
      </el-form-item>
      <el-form-item label="账号名称" prop="accountName">
        <el-input v-model="ent.accountName" />
      </el-form-item>
      <el-form-item label="发布状态" prop="publishStatus">
        <el-input v-model="ent.publishStatus" />
      </el-form-item>
      <el-form-item label="投放状态" prop="launcheStatus">
        <el-input v-model="ent.launcheStatus" />
      </el-form-item>
      <el-form-item label="计划id" prop="planId">
        <el-input v-model="ent.planId" />
      </el-form-item>
      <el-form-item label="单元id" prop="unitId">
        <el-input v-model="ent.unitId" />
      </el-form-item>
      <el-form-item label="创意id" prop="creativeId">
        <el-input v-model="ent.creativeId" />
      </el-form-item>
      <el-form-item label="逻辑删除" prop="deleted">
        <el-input v-model="ent.deleted" />
      </el-form-item>
      <el-form-item label="创建者" prop="createBy">
        <el-input v-model="ent.createBy" />
      </el-form-item>
      <el-form-item label="创建时间" prop="createTime">
        <el-input v-model="ent.createTime" />
      </el-form-item>
      <el-form-item label="更新者" prop="updateBy">
        <el-input v-model="ent.updateBy" />
      </el-form-item>
      <el-form-item label="更新时间" prop="updateTime">
        <el-input v-model="ent.updateTime" />
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="ent.remark" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button round @click="close">取消</el-button>
      <el-button type="primary" round @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {
  Add,
  Update
} from '@/network/api/advManagement/advManagementBaiduPublish'
import { AdvManagementBaiduPublishEnt, formRules } from './object'

export default {
  inject: ['loadData'],
  data() {
    return {
      ent: new AdvManagementBaiduPublishEnt(),
      rules: formRules,
      isEdit: false,
      visible: false,
      cityList: []
    }
  },
  methods: {
    submit() {
      this.$refs.ent.validate((validate) => {
        if (validate) {
          this.isEdit ? this.updateRow() : this.addRow()
        }
      })
    },
    addRow() {
      const req = { ...this.ent }
      Add(req).then(res => {
        this.$message({
          message: '保存成功',
          type: 'success'
        })
        this.loadData()
        this.close()
      })
    },
    updateRow() {
      const req = { ...this.ent }
      Update(req).then(res => {
        this.$message({
          message: '修改成功',
          type: 'success'
        })
        this.loadData()
        this.close()
      })
    },
    close() {
      this.visible = false
      this.$nextTick(() => {
        this.$refs.ent.resetFields()
      })
    },
    show(isEdit = false, ent) {
      this.ent = ent
      this.isEdit = isEdit
      this.visible = true
      this.$nextTick(() => {
        if (this.isEdit) {
          console.log('不能为空')
        }
      })
    }
  }
}
</script>
