<!--
 * @Description: Do not edit
 * @Date: 2021-10-19 19:07:04
 * @LastEditTime: 2022-05-20 09:35:45
-->

<template>
  <div>
    <!-- @row-click="getRowData" -->
    <el-table ref="multipleTable" v-loading="dataSource.loading" stripe class="list-table" style="width: 100%;"
      :class="{ 'no-data': !dataSource.data || !dataSource.data.length }" :data="dataSource.data"
      @select-all="dataSource.selectAll" @select="dataSource.select" @selectable="dataSource.selectable"
      @selection-change="dataSource.handleSelectionChange">
      <!-- 是否有多选 -->
      <el-table-column v-if="dataSource.isSelection" :selectable="dataSource.selectable" type="selection"
        :width="dataSource.selectionWidth || 50" align="left" />
      <!-- 是否需要序号 -->
      <el-table-column v-if="dataSource.isIndex" fixed="left" type="index" label="序号" width="60" align="left" />

      <template v-for="item in dataSource.cols">
        <!-- 表格的列展示 特殊情况处理 比如要输入框 显示图片 -->
        <el-table-column v-if="item.isTemplate" :key="item.prop" v-bind="item">
          <template slot-scope="scope">
            <!-- 比如要输入框 显示图片等等 自己定义 -->
            <slot :name="item.prop" :scope="scope" />
          </template>
        </el-table-column>
        <!-- 需要特殊颜色显示字体-->
        <el-table-column v-if="item.isSpecial" :key="item.prop" v-bind="item" align="left">
          <template slot-scope="scope">
            <span :class="item.isSpecialClass(scope.row[scope.column.property])">{{
            item.isSpecial(scope.row[scope.column.property]) }}</span>
          </template>
        </el-table-column>
        <!-- 需要带图标的某列，带回调事件-->
        <el-table-column v-if="item.isIcon" :key="item.prop" v-bind="item" align="left">
          <template slot-scope="scope">
            <span>
              <span>{{ item.filter(scope.row[scope.column.property]) }}</span>
              <i v-if="item.icon" :class="[item.icon(scope.row[scope.column.property]), 'icon-normal']"
                @click="item.handlerClick(scope.row)" />
            </span>
            <!-- 比如要输入框 显示图片等等 自己定义 -->
            <slot :name="item.prop" :scope="scope" />
          </template>
        </el-table-column>
        <!-- 图片带tooltip -->
        <el-table-column v-if="item.property" :key="item.prop" v-bind="item" align="left" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-popover v-if="scope.row[item.property].indexOf('.mp4') > -1" placement="top" trigger="hover">
              <video class="image-popover"
                :style="{ width: `${item.imageHoverWidth || 100}px`, height: item.imageHoverHeight ? `${item.imageHoverHeight}px` : 'auto' }"
                :src="scope.row[item.property]" alt="" />
              <div slot="reference">
                <video class="image-popover"
                  :style="{ width: `${item.imageWidth || 60}px`, height: item.imageHeight ? `${item.imageHeight}px` : 'auto' }"
                  :src="scope.row[item.property]" alt="" />
              </div>
            </el-popover>
            <el-popover v-else placement="top" trigger="hover">
              <img class="image-popover"
                :style="{ width: `${item.imageHoverWidth || 100}px`, height: item.imageHoverHeight ? `${item.imageHoverHeight}px` : 'auto' }"
                :src="scope.row[item.property]" alt="" />
              <div slot="reference">
                <img class="image-popover"
                  :style="{ width: `${item.imageWidth || 60}px`, height: item.imageHeight ? `${item.imageHeight}px` : 'auto' }"
                  :src="scope.row[item.property]" alt="" />
              </div>
            </el-popover>
          </template>
        </el-table-column>

        <!-- 大部分适用 -->
        <template v-if="item.handleRow">
          <el-table-column v-if="!item.isImagePopover && !item.isTemplate && !item.isSpecial && !item.isIcon"
            :key="item.prop" v-bind="item.render ? Object.assign({ formatter: item.render }, item) : item" align="left"
            show-overflow-tooltip>
            <template slot-scope="scope">
              <el-button v-if="scope.row[item.prop] == '无'" disabled :style="{ color: item.color }"
                :type="item.type ? item.type : 'text'" size="mini">
                {{ '_' }}
              </el-button>
              <el-button v-else :id="`copy${scope.row[item.prop]}`" :data-clipboard-text="scope.row[item.prop]"
                :style="{ color: item.color }" :type="item.type ? item.type : 'text'" size="mini"
                @click.native.prevent="item.handleRow(scope.$index, scope.row, item.label)">
                {{ scope.row[item.prop] }}
              </el-button>
            </template>
          </el-table-column>
        </template>

        <template v-if="!item.handleRow && !item.property && !item.isTemplate && !item.isSpecial && !item.isIcon">
          <el-table-column :key="item.prop"
            v-bind="item.render ? Object.assign({ formatter: item.render }, item) : item" align="left"
            :show-overflow-tooltip="!item.noTip" />
        </template>
      </template>
      <!-- 是否有操作列 -->
      <!-- 没有数据时候不固定列 -->

      <el-table-column v-if="dataSource.isOperation" :show-overflow-tooltip="dataSource.operation.overflowTooltip"
        v-bind="dataSource.data && dataSource.data.length ? { fixed: 'right' } : null" style="margin-right:20px"
        class-name="handle-td" label-class-name="tc" :width="dataSource.operation.width"
        :label="dataSource.operation.label" align="left" :fixed="dataSource.operation.fixed">
        <!-- UI统一一排放3个，4个以上出现更多 -->
        <template slot-scope="scope">
          <slot name="operation" :row="scope.row" />
          <!-- 三个一排的情况，去掉隐藏的按钮后的长度 -->
          <template v-if="dataSource.operation.data.length > 0">
            <div class="justify-start">
              <div v-for="(item, index) in dataSource.operation.data" :key="index" style="margin: 0">
                <div v-if="item">
                  <!-- 根据 showLabel字段 来判断是否展示 -->
                  <div v-if="item.showLabel">
                    <!--showNotValue[不显示的状态值]  showValue[显示的状态值] -->
                    <template
                      v-if="item.showNotValue ? !item.showNotValue.includes(scope.row[item.showLabel]) : item.showValue.includes(scope.row[item.showLabel])">
                      <!-- 按钮是否是图标 -->
                      <el-button v-if="item.icon" style="margin-right: 10px" type="text" :style="{ color: item.color }"
                        :disabled="item.disabled || false" :size="item.size || 'mini'"
                        @click.native.prevent="item.handleRow(scope.$index, scope.row, item.label)">
                        <el-tooltip :content="item.tooltip" placement="top">
                          <i :class="item.icon" style="font-size: 16px"></i>
                        </el-tooltip>
                      </el-button>
                      <el-button v-else style="margin-right: 10px" :style="{ color: item.color }" v-bind="item"
                        :disabled="item.disabled || false" :type="item.type || ''" :size="item.size || 'mini'"
                        @click.native.prevent="item.handleRow(scope.$index, scope.row, item.label)">
                        {{ item.label }}
                      </el-button>
                    </template>
                    <template v-else style="margin: 0px">
                      <i v-bind="item" :style="{ color: item.color }"
                        @click="item.handleRow(scope.$index, scope.row, item.label)" />
                    </template>
                  </div>
                  <div v-else>
                    <template v-if="item.showLabel !== 'icon'" style="margin: 0px">
                      <!-- 按钮是否是图标 -->
                      <el-button v-if="item.icon" style="margin-right: 10px" type="text" :style="{ color: item.color }"
                        :disabled="item.disabled || false" :size="item.size || 'mini'"
                        @click.native.prevent="item.handleRow(scope.$index, scope.row, item.label)">
                        <el-tooltip :content="item.tooltip" placement="top">
                          <p v-if="item.label">{{ item.label }}</p>
                          <i v-else :class="item.icon" style="font-size: 16px"></i>
                        </el-tooltip>
                      </el-button>
                      <el-button v-else style="margin-right: 10px" :style="{ color: item.color }" v-bind="item"
                        :disabled="item.disabled || false" :type="item.type || ''" :size="item.size || 'mini'"
                        @click.native.prevent="item.handleRow(scope.$index, scope.row, item.label)">
                        {{ item.label }}
                      </el-button>
                    </template>
                    <template v-else>
                      <i v-bind="item" @click="item.handleRow(scope.$index, scope.row, item.label)" />
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <div class="page">
      <el-pagination v-if="dataSource.pageData.total > 0" style="text-align:right"
        :layout="dataSource.pageData.layout || 'total, sizes, prev, pager, next, jumper'"
        :current-page="dataSource.pageData.pageNum || 1" :page-sizes="dataSource.pageData.pageSizes || [5, 10, 15, 20]"
        :page-size="dataSource.pageData.pageSize || 10" :total="dataSource.pageData.total"
        @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
  </div>
</template>

<script>
//  dataSource: {
//          tool:[
//            {
//              name: '新增用户', //按钮名称
//              key: 1,  // 唯一标识符
//              permission: 2010106, // 权限点
//              type: '',  // 使用element自带按钮类型
//              bgColor: '#67c23a', // 自定义背景色
//              handleClick: this.handleAdd //自定义事件
//            },
//          ]
//         data: [], // 表格数据
//         cols: [], // 表格的列数据
//         handleSelectionChange:(val)=>{} //点击行选中多选返回选中数组
//         isSelection: false, // 表格有多选时设置
//         isOperation: true, // 表格有操作列时设置
//         isIndex: true, // 列表序号
//         loading: true, // loading
//         pageData: {
//          total: 0, // 总条数
//          pageSize: 10, // 每页数量
//          pageNum: 1, // 页码
//          pageSize:[5,10,15,20]// 每页数量
//         }
//         operation: {
//           // 表格有操作列时设置
//           label: '操作', // 列名
//           width: '350', // 根据实际情况给宽度
//           data: [
//             {
//               label: '冻结', // 操作名称
//               permission:'' //权限点
//               type: 'info', //按钮类型
//               handleRow: function(){} // 自定义事件
//             },
//           ]
//         }
//       },
export default {
  // 接收父组件传递过来的值
  props: {
    //  表格数据和表格部分属性的对象
    // eslint-disable-next-line vue/require-default-prop
    dataSource: {
      type: Object
    },
    // eslint-disable-next-line vue/require-default-prop
    dataApi: {
      type: String
    }
  },
  data() {
    return {
      pageNum: this.dataSource.pageData.pageNum,
      pageSize: this.dataSource.pageData.pageSize
    }
  },
  watch: {
    'dataSource.cols': {
      // 监听表格列变化
      deep: true,
      handler() {
        // 解决表格列变动的抖动问题
        this.$nextTick(this.$refs.multipleTable.doLayout())
      }
    }
  },
  methods: {
    handleAdd(name) {
      console.log(name)
      this.$emit('toolMsg', name)
    },
    handleRow(index, row, lable) {
      console.log(index, row, lable)
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.$emit('getPage', { pageSize: val, pageNum: this.pageNum ? this.pageNum : 1 })
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.dataSource.pageData.pageNum = val
      this.$emit('getPage', { pageNum: val, pageSize: this.pageSize ? this.pageSize : 10 })
    },
    // 点击行即可选中
    getRowData(rows) {
      if (rows.length) {
        this.$nextTick(() => {
          rows.forEach((row) => {
            this.$refs.multipleTable.toggleRowSelection(row, true)
          })
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    clearSelection() {
      this.$refs.multipleTable.clearSelection()
    },
    toggleRowSelection(row) {
      this.$refs.multipleTable.toggleRowSelection(row, true)
    }
  }
}
</script>
<style lang="scss" scoped>
.page {
  margin-top: 20px;
}

.btn {
  display: flex;
  justify-content: center;
}

.btn div {
  margin-left: 5px;
}

.reference-img {
  width: 40px;
  height: 40px;
  background-size: 100% 100%;
  border-radius: 4px;
}

.image-popover {
  width: 200px;
  height: 200px;
  background-size: 100% 100%;
}

.icon {
  width: 25px;
  font-size: 20px;
  font-weight: bold;
}
</style>
