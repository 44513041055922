<!--
 * @Description: 工作区 -> 跳转小程序组件
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2022-04-01 09:10:45
-->

<template>
  <section>
    <div v-if="detail.content === ''" class="copyGh">
      <div style="text-align: center">
        <i class="el-icon-document-copy"></i>
        <p>{{ detail.name }}组件</p>
      </div>
    </div>
    <!-- paddingLeft: '10px',
        paddingRight: '10px', -->
    <div
      v-else
      :style="{
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingTop: `${detail.paddingTop}px`,
        paddingBottom: `${detail.paddingBottom}px`
      }"
    >
      <div
        class="button"
        :style="{
          background: detail.btnBgColorTheme,
          color: detail.btnFontColor,
          border: `1px solid ${detail.btnBorderColorTheme}`,
          fontWeight: detail.btnFontType
        }"
      >
        <p v-html="detail.btnTitle"></p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.button {
  border-radius: 20px;
  line-height: 42px;
  text-align: center;
  font-size: 20px;
  height: 42px;
}
.copyGh {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: #fff;
  display: flex;
  flex-direction: column;
  i {
    font-size: 60px;
    color: #ccc;
  }
  p {
    margin-top: 10px;
    font-size: 24px;
    color: #ccc;
  }
}
</style>
