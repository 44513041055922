var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.isEdit ? "编 辑" : "新 增",
        width: "45%",
        visible: _vm.visible,
        "before-close": _vm.close,
        "label-position": "right",
        "modal-append-to-body": false,
        "close-on-press-escape": false,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ent",
          attrs: { model: _vm.ent, rules: _vm.rules, "label-width": "110px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "基木鱼id", prop: "woodenFishId" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.woodenFishId,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "woodenFishId", $$v)
                  },
                  expression: "ent.woodenFishId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "分享账户id", prop: "shareBaiduId" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.shareBaiduId,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "shareBaiduId", $$v)
                  },
                  expression: "ent.shareBaiduId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "分享投放账户", prop: "shareAccountName" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.shareAccountName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "shareAccountName", $$v)
                  },
                  expression: "ent.shareAccountName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "分享状态", prop: "shareStatus" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.shareStatus,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "shareStatus", $$v)
                  },
                  expression: "ent.shareStatus",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "落地页ID", prop: "pageId" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.pageId,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "pageId", $$v)
                  },
                  expression: "ent.pageId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "落地页名称", prop: "pageName" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.pageName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "pageName", $$v)
                  },
                  expression: "ent.pageName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "逻辑删除", prop: "deleted" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.deleted,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "deleted", $$v)
                  },
                  expression: "ent.deleted",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "创建者", prop: "createBy" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.createBy,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "createBy", $$v)
                  },
                  expression: "ent.createBy",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "创建时间", prop: "createTime" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.createTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "createTime", $$v)
                  },
                  expression: "ent.createTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "更新者", prop: "updateBy" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.updateBy,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "updateBy", $$v)
                  },
                  expression: "ent.updateBy",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "更新时间", prop: "updateTime" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.updateTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "updateTime", $$v)
                  },
                  expression: "ent.updateTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ent.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.ent, "remark", $$v)
                  },
                  expression: "ent.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { attrs: { round: "" }, on: { click: _vm.close } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", round: "" },
              on: { click: _vm.submit },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }