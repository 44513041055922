<!--
 * @Description: 图片编辑区
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2022-05-26 11:46:14
-->

<template>
  <section>
    <base-box name="video">
      <template slot="body">
        <div>
          <p class="font-13">视频类型：</p>
          <div class="ml-20">
            <el-radio v-model="detail.type" label="61">短视频</el-radio>
          </div>
        </div>
        <div class="image-updata">
          <p class="font-13">选择视频：</p>
          <div>
            <div class="update" @click="setMaterialBox">
              <video v-if="detail.sightVideoUrl" :src="detail.sightVideoUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon" />
            </div>
            <p class="tip mt-10">
              视频大小：≤100MB <br />
              图片尺寸：750 * ≤1536<br />
              视频长度：≤30秒
            </p>
          </div>
        </div>
      </template>
    </base-box>
    <MaterialVideoBox
      :visible="materialBox_visible"
      :img-count="1"
      :video-listed="videoList"
      name="ldy"
      :attribute="{
        key: 'topImg',
        value: '顶部视频',
        count: 1,
        size: 100 * 1024 * 1024,
        listType: 'image/jpg,image/jpeg,image/png',
        width: null,
        height: null
      }"
      @setMaterialBox="setMaterialBox"
      @handleVideoSuccess="selectVideo"
    />
    <!-- width: 750,
        height: [0, 1536] -->
  </section>
</template>

<script>
import BaseBox from './base.vue'
import MaterialVideoBox from '@/components/materialBox/videoV2.vue'

export default {
  components: {
    'base-box': BaseBox,
    MaterialVideoBox
  },
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      materialBox_visible: false
    }
  },
  methods: {
    /**
     * @description: 打开/关闭资源库
     */
    setMaterialBox() {
      this.materialBox_visible = !this.materialBox_visible
    },

    /**
     * @description: 选择视频后调用
     * @param {Array} 视频信息
     */
    selectVideo(video) {
      this.videoList = video
      let { detail } = this
      const { url, videoUrl, width, height } = video[0]
      detail.sightVideoUrl = url || videoUrl
      detail.sightDisplayWidth = width
      detail.initWidth = width
      detail.sightDisplayHeight = height
      detail.initHeight = height
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.update {
  width: 130px !important;
  height: 130px;
  line-height: 130px;
  text-align: center;
  border: 1px solid #edecf2;
  background-color: #fbfafd;
  border-radius: 3px;
  cursor: pointer;
  font-size: 30px;
  color: #7d7d7d;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .avatar {
    width: 130px;
  }
}

.image-updata {
  display: flex !important;
  align-items: flex-start !important;
}

// .setting {
//   display: flex;
//   justify-content: flex-start;
//   margin: 10px 0;
//   align-items: center;
//   & > p {
//     font-size: 14px;
//     color: rgb(78, 78, 78);
//   }
//   & > div {
//     width: 80%;
//     margin-left: 30px;
//   }
// }
</style>
