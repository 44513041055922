var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Base",
        { attrs: { title: "基础信息" } },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form",
              attrs: {
                inline: true,
                model: _vm.ruleData,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { label: "任务名称" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请输入任务名称" },
                            on: { change: _vm.changeTaskName },
                            model: {
                              value: _vm.ruleData.taskName,
                              callback: function ($$v) {
                                _vm.$set(_vm.ruleData, "taskName", $$v)
                              },
                              expression: "ruleData.taskName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { label: "项目" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              on: { change: _vm.changeProject },
                              model: {
                                value: _vm.ruleData.projectId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleData, "projectId", $$v)
                                },
                                expression: "ruleData.projectId",
                              },
                            },
                            _vm._l(_vm.projectList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.projectName,
                                  value: item.bsProjectId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { label: "产品" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              on: { change: _vm.changeProduct },
                              model: {
                                value: _vm.ruleData.productId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleData, "productId", $$v)
                                },
                                expression: "ruleData.productId",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "不限", value: -1 },
                              }),
                              _vm._l(_vm.productListed, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.productName,
                                    value: item.bsProductId,
                                  },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            prop: "promotedObjectType",
                            label: "推广目标",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请输入推广目标" },
                              on: { change: _vm.changeTaskName },
                              model: {
                                value: _vm.ruleData.promotedObjectType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.ruleData,
                                    "promotedObjectType",
                                    $$v
                                  )
                                },
                                expression: "ruleData.promotedObjectType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: "收集销售线索",
                                  value: "PROMOTED_OBJECT_TYPE_LEAD_AD",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { label: "投放账户" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                multiple: "",
                                "collapse-tags": "",
                                filterable: "",
                                remote: "",
                                placeholder: "请输入名称/ID检索",
                                "remote-method": _vm.remoteMethod,
                                loading: _vm.loading,
                              },
                              on: { change: _vm.changeAccount },
                              model: {
                                value: _vm.ruleData.accountId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleData, "accountId", $$v)
                                },
                                expression: "ruleData.accountId",
                              },
                            },
                            _vm._l(
                              _vm.AccountUin_Options,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: `${item.corporationName}-${item.accountId}`,
                                    value: item.accountId,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { label: "广告版位" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              placeholder: "请选择广告版位",
                              disabled: true,
                            },
                            model: {
                              value: _vm.showSiteSet,
                              callback: function ($$v) {
                                _vm.showSiteSet = $$v
                              },
                              expression: "showSiteSet",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { staticStyle: { width: "100%" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.taskSiteSetShow },
                            },
                            [_vm._v("选择广告版位")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("Deploy", {
        ref: "deployRef",
        attrs: {
          "account-baidu-adv": _vm.accountBaiduAdv,
          "task-id": _vm.taskId,
          "account-id": _vm.ruleData.accountId,
          "project-id": _vm.ruleData.projectId,
          "is-update-task": _vm.isUpdateTask,
        },
        on: {
          setAccount: _vm.setAccount,
          getShowPreviewFun: _vm.getShowPreviewFun,
          saveTaskFun: _vm.saveTaskFun,
        },
      }),
      _c(
        "Base",
        {
          attrs: {
            title: "预览区",
            desc: _vm.taskId ? "配置信息变更后，预览区清空重置" : "",
          },
        },
        [
          _vm.previews.length
            ? _c(
                "div",
                { staticClass: "preview-scroll-content" },
                _vm._l(_vm.previews, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "preview-account-item" },
                    [
                      _c("div", { staticClass: "table-head" }, [
                        _c("div", { staticClass: "account" }, [
                          _vm._v(_vm._s(item.accountLists.taaccountId)),
                        ]),
                        _c(
                          "div",
                          [
                            _vm._v(" 广告数量："),
                            _c("el-button", { attrs: { type: "text" } }, [
                              _vm._v(_vm._s(item.adNum)),
                            ]),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: item.loading,
                              expression: "item.loading",
                            },
                            {
                              name: "el-table-infinite-scroll",
                              rawName: "v-el-table-infinite-scroll",
                              value: item.loadMore,
                              expression: "item.loadMore",
                            },
                          ],
                          staticClass: "table-weChat",
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: item.groupList,
                            "infinite-scroll-disabled": item.disabled,
                            "infinite-scroll-immediate": false,
                            "infinite-scroll-distance": "50px",
                            height: "400",
                            border: "",
                          },
                        },
                        [
                          _c(
                            "el-table-column",
                            {
                              attrs: {
                                label: "推广计划",
                                width: "300",
                                align: "center",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "推广计划信息",
                                  width: "250",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ row }) {
                                        return [
                                          _c("p", [
                                            _vm._v(_vm._s(row.campaignName)),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-table-column",
                            { attrs: { label: "广告", align: "center" } },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "广告名称",
                                  width: "250",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ row, $index }) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: { color: "#409eff" },
                                            },
                                            [_vm._v(_vm._s(row.stAdName))]
                                          ),
                                          _c("i", {
                                            staticClass:
                                              "el-icon-edit icon-edit",
                                            on: {
                                              click: function ($event) {
                                                return _vm.onClickEditInfo(
                                                  4,
                                                  row.taskGenerateId,
                                                  row.stAdName,
                                                  index,
                                                  $index
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "出价",
                                  width: "150",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ row, $index }) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: { color: "#409eff" },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  row.stAdBid
                                                    ? row.stAdBid / 100
                                                    : 0
                                                ) + "元"
                                              ),
                                            ]
                                          ),
                                          _c("i", {
                                            staticClass:
                                              "el-icon-edit icon-edit",
                                            on: {
                                              click: function ($event) {
                                                return _vm.onClickEditInfo(
                                                  5,
                                                  row.taskGenerateId,
                                                  row.stAdBid
                                                    ? row.stAdBid / 100
                                                    : 0,
                                                  index,
                                                  $index
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-table-column",
                            { attrs: { label: "广告创意", align: "center" } },
                            [
                              _c("el-table-column", {
                                attrs: { label: "创意素材", align: "center" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ row }) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              style: {
                                                display: row.videoUrls
                                                  ? "flex"
                                                  : "block",
                                                "justify-content": "center",
                                              },
                                            },
                                            [
                                              row.videoUrls &&
                                              row.videoUrls.length
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "preview-material-video",
                                                    },
                                                    _vm._l(
                                                      row.videoUrls,
                                                      function (video, vi) {
                                                        return _c(
                                                          "el-popover",
                                                          {
                                                            key: vi,
                                                            attrs: {
                                                              placement:
                                                                "right",
                                                              trigger: "hover",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "element-pop-img",
                                                              },
                                                              [
                                                                _c("video", {
                                                                  attrs: {
                                                                    src: video,
                                                                    controls:
                                                                      "",
                                                                    autoplay:
                                                                      "autoplay",
                                                                    loop: "-1",
                                                                    muted:
                                                                      "false",
                                                                  },
                                                                  domProps: {
                                                                    muted: true,
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "element-img",
                                                                attrs: {
                                                                  slot: "reference",
                                                                },
                                                                slot: "reference",
                                                              },
                                                              [
                                                                _c("video", {
                                                                  attrs: {
                                                                    src: video,
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.ruleData.taskSiteSet !==
                                                "a" &&
                                              row.videoUrls &&
                                              !row.imgUrls
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "preview-material-video",
                                                    },
                                                    [
                                                      _c(
                                                        "el-popover",
                                                        {
                                                          attrs: {
                                                            placement: "right",
                                                            trigger: "hover",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "element-pop-img",
                                                            },
                                                            [
                                                              _c("video", {
                                                                attrs: {
                                                                  src: row
                                                                    .videoUrls[0],
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "element-img",
                                                              attrs: {
                                                                slot: "reference",
                                                              },
                                                              slot: "reference",
                                                            },
                                                            [
                                                              _c("video", {
                                                                attrs: {
                                                                  src: row
                                                                    .videoUrls[0],
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : row.imgUrls &&
                                                  row.imgUrls.length
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "preview-material-image",
                                                    },
                                                    _vm._l(
                                                      row.imgUrls,
                                                      function (image, mi) {
                                                        return _c(
                                                          "el-popover",
                                                          {
                                                            key: mi,
                                                            attrs: {
                                                              placement:
                                                                "right",
                                                              trigger: "hover",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "element-pop-img",
                                                              },
                                                              [
                                                                _c("el-image", {
                                                                  attrs: {
                                                                    src: image,
                                                                    fit: "contain",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "element-img",
                                                                class:
                                                                  "element-img-group-" +
                                                                  row.imgUrls
                                                                    .length,
                                                                attrs: {
                                                                  slot: "reference",
                                                                },
                                                                slot: "reference",
                                                              },
                                                              [
                                                                _c("el-image", {
                                                                  attrs: {
                                                                    src: image,
                                                                    fit: "contain",
                                                                    lazy: "",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "创意文案",
                                  width: "250",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ row }) {
                                        return [
                                          _c("span", [
                                            _vm._v(_vm._s(row.text)),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "落地页",
                                  width: "300",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function ({ row }) {
                                        return [
                                          row.pageType === "ONLINE"
                                            ? _c(
                                                "div",
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      _vm._s(row.pageName)
                                                    ),
                                                  ]),
                                                  _c(
                                                    "el-popover",
                                                    {
                                                      attrs: {
                                                        placement: "top",
                                                        trigger: "click",
                                                      },
                                                      on: {
                                                        show: function (
                                                          $event
                                                        ) {
                                                          return _vm.handlePreviewPage(
                                                            row
                                                          )
                                                        },
                                                        hide: function (
                                                          $event
                                                        ) {
                                                          return _vm.hidePopover(
                                                            row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "qrcode-box",
                                                          attrs: {
                                                            id:
                                                              "set_page_qrcode" +
                                                              row.taskGenerateId,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "qrcode-loading",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "加载中..."
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "qrcode-tips",
                                                          staticStyle: {
                                                            "text-align":
                                                              "center",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "请使用微信扫码"
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          attrs: {
                                                            slot: "reference",
                                                          },
                                                          slot: "reference",
                                                        },
                                                        [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              attrs: {
                                                                type: "text",
                                                              },
                                                            },
                                                            [_vm._v("预览")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c("div", [
                                                _c("p", [
                                                  _vm._v(_vm._s(row.pageName)),
                                                ]),
                                              ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              {
                staticClass: "justify-sb",
                staticStyle: {
                  "justify-content": "center",
                  "margin-bottom": "100px",
                },
              },
              [
                _vm.previews.length
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.startTask },
                      },
                      [_vm._v("开始新任务")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-edit-info-dialog", {
        ref: "editInfo",
        on: { confirm: _vm.handleEditInfo },
      }),
      _c("task-site-set-dialog", {
        ref: "siteSet",
        on: { confirm: _vm.setSiteSet },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }