/*
 * @Description: Do not edit
 * @Date: 2021-09-15 09:48:06
 * @LastEditTime: 2022-04-29 13:55:11
 */
import service from '@/network/service/service'

export function Page(data) {
  return service.post('/api/system/advManagementBaiduCreativeFend/page', data)
}

export function Add(data) {
  return service.post('/api/system/advManagementBaiduCreativeFend/save', data)
}

export function Update(data) {
  return service.post('/api/system/advManagementBaiduCreativeFend/update', data)
}

export function Delete(id) {
  return service.delete('/api/system/advManagementBaiduCreativeFend/' + id)
}

export function getBaiduUnitId() {
  return service.get('/api/system/advManagementBaiduUnit/getVisibleUnit')
}

export function GetCreativeByUnit(baiduUnitId) {
  return service.get(`/api/system/advManagementBaiduCreativeFend/byUnitGetCreative?baiduUnitId=${baiduUnitId}`)
}

export function bySubjectGetCreative(subject) {
  return service.get(`/api/system/advManagementBaiduCreativeFend/bySubjectGetCreative?subject=${subject}`)
}
export function getMaterialList(data) {
  return service.post(`/api/file/adMaterialImage/list?pageSize=${data.pageSize || 8}&pageNum=${data.pageNum}`, data)
}

export function getMaterialList_video(data) {
  return service.post(`/api/file/adMaterialVideo/list?pageSize=8&pageNum=${data.pageNum}`, data)
}

// 创意上传素材zq\

export function getUploadList(data) {
  return service.post(`/api/system/advManagementBaiduPublish/getUploadList`, data)
}

// 创意上传素材\

export function byIdUploadBaiduImgOrVideo(data) {
  return service.post(`/api/file/provideSystemApi/byIdUploadBaiduImgOrVideo`, data)
}

/**
 * @description  删除创意计划
 *
 */

export function deleteBaiduCreativeFend(housekeeperId, baiduId, creativeFeedId) {
  return service.delete(`/api/advManagementBaiduPublish/deleteBaiduApiCreative/${housekeeperId}/${baiduId}/${creativeFeedId}`)
}

/**
 * @description  删除创意计划
 *
 */

export function deleteBaiduApiCreative(data) {
  return service.post(`/api/system/advManagementBaiduPublish/deleteBaiduApiCreative`, data)
}

/**
 * @description  创意发布
 *
 */

export function batchUpCreative(data) {
  return service.post('/api/system//advManagementBaiduPublish/batchUpCreative', data)
}
