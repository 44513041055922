/*
 * @Description: Do not edit
 * @Date: 2021-11-01 17:03:54
 * @LastEditTime: 2021-11-01 17:49:43
 */
import service from '@/network/service/service'

export function Page(data) {
  data.advId = data.advId + ''
  data.componentType = data.componentType + ''
  data.status = data.status + ''
  return service.post('/api/system/assetManagementHugeCreativeComponent/page', data)
}

export function Add(data) {
  return service.post('/api/system/assetManagementHugeCreativeComponent/save', data)
}

export function Update(data) {
  return service.post('/api/system/assetManagementHugeCreativeComponent/update', data)
}

export function Synchronization(params) {
  return service.get(`/api/system/assetManagementHugeCreativeComponent/synchronization/${params.accountId}/${params.GGZaccountId + ''}`)
}

export function Delete(id) {
  return service.delete('/api/system/assetManagementHugeCreativeComponent/' + id)
}
