/*
 * @Description: 账户管家 投放账户 百度
 * @Date: 2021-11-01 09:56:28
 * @LastEditTime: 2022-04-18 10:54:07
 */
import { getHouseKeeper, getAccountBaiduAdv } from '@/network/api/account/accountBaiduAdv'
import { getBatchMeRelationProject } from '@/network/api/basicSetting/project/basicSettingProject'

export default {
  data() {
    return {
      houseKeeper: [],
      accountBaiduAdv: [],
      projectList: [], // 项目
      productList: [], // 产品
      productListed: [], // 产品

      selectHouseKeeperId: '', // 管家
      selectProjectId: '', // '', // 项目Id
      selectProductId: -1, // 产品Id
      rules: {
        accountId: [{ required: true, message: '请选择管家账号', trigger: 'blur' }],
        GGZaccountId: [{ required: true, message: '请选择广告主账号', trigger: 'blur' }]
      } // 表单校验规则
    }
  },
  mounted() {
    this.getHouseKeeperFun()
    this.getProject()
  },
  watch: {
    selectProjectId() {
      if (this.projectList.length && this.selectProjectId !== '') {
        this.productListed = this.productList[this.selectProjectId]
        this.getAccountBaiduAdvFun()
      } else {
        this.productListed = []
      }
    }
  },
  methods: {
    /** 获取管家list */
    getHouseKeeperFun() {
      getHouseKeeper().then(({ code, data }) => {
        if (code === 200) {
          this.houseKeeper = data
        }
      })
    },

    getProject() {
      getBatchMeRelationProject().then((res) => {
        if (res.code === 200) {
          const data = res.data
          if (data.projects !== undefined) {
            this.projectList = data.projects
          }
          if (data.products !== undefined) {
            this.productList = data.products
          }
        }
      })
    },

    changeHouseKeeperFun(value) {
      this.selectHouseKeeperId = value
      this.getAccountBaiduAdvFun()
    },

    /** 获取投放账户 */
    getAccountBaiduAdvFun() {
      const { selectHouseKeeperId, selectProjectId, selectProductId } = this
      if (!(this.selectProjectId && this.selectHouseKeeperId)) return
      getAccountBaiduAdv({ selectHouseKeeperId, selectProjectId, selectProductId }).then(({ code, data }) => {
        if (code === 200) {
          data.forEach((item) => {
            item._id = item.baiduId
            item._name = item.accountName
          })
          this.accountBaiduAdv = data
        }
      })
    }
  }
}
