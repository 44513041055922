var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "test-details-dialog",
      attrs: {
        title: "测试详情",
        visible: _vm.show,
        "append-to-body": true,
        width: "1200px",
        top: "20vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.onCloseDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c("div", { staticClass: "desc-box" }, [
            _c("div", { staticClass: "box-item" }, [
              _c("span", [_vm._v("产品名称：")]),
              _c("span", { staticStyle: { color: "#989898" } }, [
                _vm._v(_vm._s(_vm.toolProductName)),
              ]),
            ]),
            _c("div", { staticClass: "box-item" }, [
              _c("span", [_vm._v("平台：")]),
              _c("span", { staticStyle: { color: "#989898" } }, [
                _vm._v(_vm._s(_vm.toolProductPlatform)),
              ]),
            ]),
            _c("div", { staticClass: "box-item" }, [
              _c("span", [_vm._v("类型：")]),
              _c("span", { staticStyle: { color: "#989898" } }, [
                _vm._v(" " + _vm._s(_vm._f("filterToolType")(_vm.type))),
              ]),
            ]),
            _c("div", { staticClass: "box-item" }, [
              _c("span", [_vm._v("数量：")]),
              _c("span", { staticStyle: { color: "#989898" } }, [
                _vm._v(_vm._s(_vm.num)),
              ]),
            ]),
          ]),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                stripe: true,
                border: true,
                data: _vm.tableData,
                height: "300px",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  width: "80",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "userName", label: "投手", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "channelName",
                  label: "投放配置",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "toolProductPlatformLaunchSetting",
                  label: "广告平台",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "toolProductAdSpace",
                  label: "广告位",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "测试时段", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.testTimeInterval || "-")),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "测试状态", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("filterTestStatus")(row.testStatus)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "结果", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.onClickEditResult(
                                  row.toolProductFilterItemId
                                )
                              },
                            },
                          },
                          [
                            row.toolProductFilterStatus === "-"
                              ? _c("span", [_vm._v("编辑")])
                              : _c("span", [
                                  _vm._v(_vm._s(row.toolProductFilterStatus)),
                                ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "isInception",
                  label: "是否起量",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.isInception
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("已起量"),
                            ])
                          : _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("未起量"),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "inceptionTime",
                  label: "起量时间",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(" " + _vm._s(row.inceptionTime || "-") + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "consume", label: "消耗", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "payPowderPrice",
                  label: "支付粉价",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "d1", label: "D1", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { prop: "d2", label: "D2", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { prop: "d3", label: "D3", align: "center" },
              }),
              _c("el-table-column", {
                attrs: { label: "梯形图", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleGoDetail(
                                  row.toolProductFilterItemId
                                )
                              },
                            },
                          },
                          [_vm._v(" 查看 ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "btn", on: { click: _vm.closeModal } },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogFormVisible,
            "append-to-body": true,
            "modal-append-to-body": false,
            width: "300px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择结果" },
                      model: {
                        value: _vm.relust,
                        callback: function ($$v) {
                          _vm.relust = $$v
                        },
                        expression: "relust",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "成功", value: 1 } }),
                      _c("el-option", { attrs: { label: "失败", value: 0 } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onClickConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }