import service from '@/network/service/service'
export function Page(data) {
  return service.post('/api/tencent/assetBatchUploadController/page', data)
}

export function uploadNew(data) {
  return service.post('/api/tencent/assetBatchUploadController/uploadNew', data)
}

export function refresData(id) {
  return service.get('/api/tencent/assetBatchUploadController/refresData/' + id)
}
