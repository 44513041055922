<template>
  <el-dialog title=" 编 辑 " width="700px" :visible.sync="visible" :before-close="close" label-position="right" :modal-append-to-body="false" :close-on-press-escape="false" :close-on-click-modal="false">
    <el-form ref="ent" :model="ent" :rules="rules" label-width="110px">
      <el-form-item label="账号ID" prop="accountId">
        <el-input v-model="ent.accountId" style="width:450px" disabled></el-input>
      </el-form-item>
      <el-form-item label="账户名称" prop="accountName">
        <el-input v-model="ent.accountName" style="width:450px" disabled></el-input>
      </el-form-item>
      <!-- <el-form-item label="登录账号" prop="loginAccount">
        <el-input v-model="ent.loginAccount" style="width:450px" disabled></el-input>
      </el-form-item> -->
      <el-form-item label="项目名称" prop="projectId">
        <el-select v-model="ent.projectId" filterable allow-create default-first-option style="width:450px" placeholder="请选择" @change="bsProjectChange">
          <el-option v-for="item in projectList" :key="item.bsProjectId" :label="item.projectName" :value="item.bsProjectId" />
        </el-select>
      </el-form-item>
      <el-form-item label="产品" prop="productId">
        <el-select v-model="ent.productId" filterable allow-create default-first-option placeholder="请选择" style="width:450px">
          <el-option label="不限" :value="0"></el-option>

          <el-option v-for="item in productList" :key="item.bsProductId" :label="item.productName" :value="item.bsProductId" />
        </el-select>
      </el-form-item>
      <el-form-item label="人员" prop="putBy" :rules="{ required: true, message: '请选择人员', trigger: 'blue' }">
        <el-select v-model="ent.putBy" value-key="key" filterable clearable placeholder="请选择投放人员" style="width:450px">
          <el-option v-for="item in userList" :key="item.key" :label="item.label" :value="item.key"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="最大转化投放" prop="smartBidNoBid">
        <el-switch v-model="ent.smartBidNoBid" :active-value="1" :inactive-value="0"/>
      </el-form-item>

      <el-form-item label="投放状态" prop="putInStatus">
        <el-select v-model="ent.putInStatus" filterable placeholder="投放状态" style="width:450px">
          <el-option label="投放" :value="1"></el-option>
          <el-option label="停投" :value="0"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button round @click="close">取消</el-button>
      <el-button type="primary" round @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { Update } from '@/network/api/huge/accountHugeAdv'
import { AccountHugeAdvEnt, formRules } from './object'
import { getMeRelationProject } from '@/network/api/basicSetting/project/basicSettingProject'
import { getProductByProject } from '@/network/api/basicSetting/product/basicSettingProduct'
import { getUser } from '@/network/api/user'

export default {
  inject: ['loadData'],
  data() {
    return {
      ent: new AccountHugeAdvEnt(),
      rules: formRules,
      projectList: [],
      userList: [],
      isEdit: false,
      visible: false,
      productList: [],
      cityList: []
    }
  },

  methods: {
    _getUserList() {
      getUser().then((res) => {
        if (res.code === 200) {
          this.userList = res.data
        }
      })
    },

    bsProjectChange(val) {
      this.ent.productId = 0
      this.ent.productName = '不限'
      this.getProduct(val)
    },

    getProject() {
      getMeRelationProject().then((res) => {
        if (res.code === 200) {
          this.projectList = res.data
        }
      })
    },

    getProduct(val) {
      getProductByProject(val).then((res) => {
        if (res.code === 200) {
          this.productList = res.data
        }
      })
    },

    submit() {
      this.$refs.ent.validate((validate) => {
        if (validate) {
          let req = { ...this.ent }
          req.putByName = this.userList.length ? this.userList.find((item) => item.key === req.putBy).label : null

          Update(req).then((res) => {
            this.$message({
              message: '修改成功',
              type: 'success'
            })
            this.loadData()
            this.close()
          })
        }
      })
    },

    close() {
      this.visible = false
      this.$nextTick(() => {
        this.$refs.ent.resetFields()
      })
    },

    show(isEdit = false, ent) {
      Object.assign(this.$data, this.$options.data())
      this.getProject()
      this._getUserList()
      this.ent = ent

      if (this.ent.projectId !== null && this.ent.projectId !== undefined) {
        this.getProduct(this.ent.projectId)
      }
      this.isEdit = isEdit
      this.visible = true
      this.$nextTick(() => {
        if (this.isEdit) {
          console.log('不能为空')
        }
      })
    }
  }
}
</script>
