<!--
 * @Author: Zeng
 * @Date: 2024-03-13 11:13:53
 * @LastEditTime: 2024-03-25 17:46:01
-->
<template>
  <div>
    <!-- 列表查询 -->
    <el-form ref="search" :inline="true" :model="search" class="search-form search-form-4">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="名称" prop="toolPageName">
            <el-input v-model="search.toolPageName" placeholder="请输入名称" style="width: 100%"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="创建人" prop="createBy">
            <el-input v-model="search.createBy" placeholder="请输入创建人" style="width: 100%"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="创建时间" prop="createDate">
            <el-date-picker v-model="search.createDate" style="width: 100%" type="daterange" prange-separator="至"
              start-placeholder="开始时间" value-format="yyyy-MM-dd" end-placeholder="结束时间"> </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="类型" prop="toolPageType">
            <el-select v-model="search.toolPageType" placeholder="请选择类型" style="width: 100%">
              <el-option v-for="item in pageTypes" :label="item.label" :value="item.key" :key="item.key"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between">
        <el-col :span="6">
          <router-link :to="{ name: 'createCustomPage' }" tag="span">
            <el-button type="primary" icon="el-icon-plus" round> 新建 </el-button>
          </router-link>
        </el-col>
        <el-col :span="6" style="text-align: right;">
          <el-form-item>
            <el-button type="primary" round @click="onSubmit">查 询</el-button>
            <el-button type="primary" round @click="resetForm('ruleForm')">重 置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div>
      <el-table v-loading="list.loading" :data="list.data" stripe class="list-table"
        :class="{ 'no-data': !list.data || !list.data.length }" style="width: 100%;">
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="toolPageName" label="名称">
          <template slot-scope="{row}">
            <span @click="handleEditPageName(row)">
              <el-tooltip :content="row.toolPageName" placement="top">
                <span>{{ row.toolPageName.length > 10 ? row.toolPageName.slice(0, 10) + '...' : row.toolPageName }}</span>
              </el-tooltip>
              <i class="ml-10 el-icon-edit" style="font-size: 16px;color: #409eff;cursor: pointer;"></i>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="类型">
          <template slot-scope="{row}">
            {{ row.toolPageType | filterToolPageType }}
          </template>
        </el-table-column>
        <el-table-column prop="createBy" label="创建人"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column prop="" label="操作">
          <template slot-scope="{row}">
            <el-button type="text" @click="handleEditPage(row.toolPageId)">
              <el-tooltip content="编辑" placement="top">
                <i class="el-icon-edit" style="font-size: 16px"></i>
              </el-tooltip>
            </el-button>
            <el-button type="text" @click="handlePreviewPage(row)">
              <el-tooltip content="预览" placement="top">
                <i class="el-icon-view" style="font-size: 16px "></i>
              </el-tooltip>
            </el-button>
            <el-button type="text" @click="handleViewUrl(row)">
              <el-tooltip content="链接" placement="top">
                <i class="el-icon-link" style="font-size: 16px "></i>
              </el-tooltip>
            </el-button>
            <el-button type="text" @click="handleCopyPage(row.toolPageId)">
              <el-tooltip content="复制" placement="top">
                <i class="el-icon-copy-document" style="font-size: 16px "></i>
              </el-tooltip>
            </el-button>
            <el-button v-if="row.toolPageType == 2" type="text" @click="handleRelationPage(row)">
              <el-tooltip content="关联循环页" placement="top">
                <i class="el-icon-set-up" style="font-size: 16px "></i>
              </el-tooltip>
            </el-button>
            <el-button type="text" class="red" @click="handleDeletePage(row.toolPageId)">
              <el-tooltip content="删除" placement="top">
                <i class="el-icon-delete" style="font-size: 16px;color:red"></i>
              </el-tooltip>
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="page">
        <paginations :total="list.total" :page.sync="list.pageNum" :page-sizes="[5, 10, 15, 20]"
          :limit.sync="list.pageSize" @pagination="getPage" />
      </div>
    </div>

    <!-- 编辑名称 -->
    <edit-name-dialog ref="editNameDialog" @confirm="getPage"></edit-name-dialog>

    <!-- 落地页预览 -->
    <preview-page-dialog ref="previewPageDialog"></preview-page-dialog>

    <!-- 落地页链接 -->
    <preview-link-dialog ref="previewLinkDialog"></preview-link-dialog>

    <!-- 关联循环页 -->
    <relation-page-dialog ref="relationPageDialog" @confirm="getPage"></relation-page-dialog>
  </div>
</template>

<script>
import { getCustomLandPageList, deleteCustomLandingPage, copyCustomLandingPage } from '@/network/api/toolManagement/api-landing-page'
import EditPageNameDialog from './components/EditPageNameDialog'
import PreviewPageDialog from './components/PreviewPageDialog'
import PreviewPageLinkDialog from './components/PreviewPageLinkDialog'
import RelationPageDialog from './components/RelationPageDialog.vue'
export default {
  components: {
    'edit-name-dialog': EditPageNameDialog,
    'preview-page-dialog': PreviewPageDialog,
    'preview-link-dialog': PreviewPageLinkDialog,
    'relation-page-dialog': RelationPageDialog
  },
  filters: {
    filterToolPageType(value) {
      const types = { 1: '跳转页', 2: '召回页', 3: '循环页' }
      return types[value] || ''
    }
  },
  data() {
    return {
      search: {
        toolPageName: '',
        createDate: this.getDateTime(),
        createBy: '',
        toolPageType: ''
      },
      list: { // 列表数据
        loading: true,
        data: [],
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      pageTypes: [
        { key: '', label: '全部' },
        { key: 1, label: '跳转页' },
        { key: 2, label: '召回页' },
        { key: 3, label: '循环页' }
      ]
    }
  },

  created() {
    this.getPage()
  },

  mounted() { },
  methods: {
    /**
      * @description: 获取列表
      * @param {*} params
      */
    getPage() {
      this.list.loading = false
      let search = {
        ...this.search,
        createDate: this.formateDates(this.search.createDate).join('-'),
        pageSize: this.list.pageSize,
        pageNum: this.list.pageNum
      }
      getCustomLandPageList(search).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          records.forEach(v => {
            v.createBy = v.createBy || '-'
          })
          this.list.data = records
          this.list.total = total
          this.list.loading = false
        }
      }).catch(e => {
        this.list.loading = false
      })
    },
    /**
   * @description: 查询
   */
    onSubmit() {
      this.getPage()
    },

    /**
     * @description: 重置
     */
    resetForm() {
      this.$refs['search'].resetFields()
      this.getPage()
    },
    // 获取近7天时间段
    getDateTime() {
      const start = new Date(new Date().getTime() - 3600 * 1000 * 24 * 29).toISOString().split('T')[0]
      const end = new Date(new Date().getTime()).toISOString().split('T')[0]
      return [start, end]
    },
    formateDates(dates) {
      if (!dates || (dates && !dates.length)) {
        return []
      }
      const reg = new RegExp('-', 'g')
      const value1 = dates[0].replace(reg, '')
      const value2 = dates[1].replace(reg, '')
      return [value1, value2]
    },

    // 点击编辑落地页名称
    handleEditPageName(row) {
      this.$refs.editNameDialog.showModal({
        id: row.toolPageId,
        name: row.toolPageName
      })
    },

    // 点击编辑
    handleEditPage(id) {
      this.$router.push({
        name: 'editCustomPage',
        params: { id }
      })
    },

    // 生成预览链接
    generateLink(id) {
      const IS_PROD = ['production'].includes(process.env.NODE_ENV)
      // const PAGE_URL = `http://172.16.24.95:3000/toolPage/${id}`
      const PAGE_URL = IS_PROD ? `https://tool-page-frontend.mokamrp.com/toolPage/${id}` : `https://test-tool-page-frontend.mokamrp.com/toolPage/${id}`
      const QUERY = {
        projectid: '__PROJECT_ID__',
        promotionid: '__PROMOTION_ID__',
        creativetype: '__CTYPE__',
        clickid: '__CLICKID__',
        ID: id
      }
      let path = '?'
      const keys = Object.keys(QUERY)
      const lastKey = keys[keys.length - 1]
      for (let key in QUERY) {
        path += `${key}=${QUERY[key]}`
        if (key !== lastKey) {
          path += '&'
        }
      }
      console.log('link:', PAGE_URL + path)
      return PAGE_URL + path
    },

    // 预览
    handlePreviewPage(row) {
      this.$refs.previewPageDialog.showModal({
        id: row.toolPageId,
        path: this.generateLink(row.toolPageId)
      })
    },

    // 查看链接
    handleViewUrl(row) {
      this.$refs.previewLinkDialog.showModal({
        id: row.toolPageId,
        path: this.generateLink(row.toolPageId)
      })
    },

    // 点击复制
    handleCopyPage(id) {
      copyCustomLandingPage(id).then(({ code, data }) => {
        if (code === 200) {
          this.$router.push({
            name: 'editCustomPage',
            params: { id: data.toolPageId }
          })
        }
      })
    },

    // 关联循环页
    handleRelationPage(row) {
      this.$refs.relationPageDialog.showModal({
        toolPageId: row.toolPageId,
        toolPageName: row.toolPageName,
        lists: row.childrens || []
      })
    },

    // 点击删除
    handleDeletePage(id) {
      this.$confirm('确定要删除该落地页吗?删除后，落地页将无法正常访问！', '落地页删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { code } = await deleteCustomLandingPage(id)
        if (code === 200) {
          this.getPage()
          this.$message.success('删除成功!')
        }
      }).catch(() => {
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
