export const searchForm = {
  id: null,
  operator: null,
  operatorId: null,
  ip: null,
  uri: null,
  parameter: null,
  payload: null,
  createTime: [],
  startTime: null,
  endTime: null
}

export class AdminLogEnt {
  id
  operator
  operatorId
  ip
  uri
  parameter
  payload
  partitionField
  createTime
}

export const formRules = {
}
