var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "justify-center-column" },
    [
      _c("el-input", {
        staticStyle: { width: "1000px" },
        attrs: { placeholder: "请输入公众号名称" },
        on: { input: _vm.remoteMethod },
        model: {
          value: _vm.query,
          callback: function ($$v) {
            _vm.query = $$v
          },
          expression: "query",
        },
      }),
      _c(
        "div",
        { staticClass: "transfer" },
        [
          _c(
            "el-form-item",
            { attrs: { "label-width": "0px" } },
            [
              _c("el-transfer", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.publicListLoading,
                    expression: "publicListLoading",
                  },
                ],
                attrs: {
                  "right-default-checked": _vm.rightDefaultListKey,
                  data: _vm.publicList,
                  titles: ["可选账号", "已关联账号"],
                },
                model: {
                  value: _vm.selectList,
                  callback: function ($$v) {
                    _vm.selectList = $$v
                  },
                  expression: "selectList",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }