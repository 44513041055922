/*
 * @Description: Do not edit
 * @Date: 2021-10-13 16:03:30
 * @LastEditTime: 2022-04-01 11:22:05
 */
import service from '@/network/service/service'

export function Page(data) {
  return service.post('/api/system/cwOceanenginePaging/page', data)
}

export function Add(data) {
  return service.post('/api/system/assetManagementHugeThirdLandingPage/save', data)
}

export function Update(data) {
  return service.post('/api/system/assetManagementHugeThirdLandingPage/update', data)
}

export function Delete(id) {
  return service.delete('/api/system/assetManagementHugeThirdLandingPage/' + id)
}

export function Sync({ accountId, GGZaccountId }) {
  return service.get(`/api/system/cwOceanenginePaging/syncThirdLandingPage/${accountId}/${GGZaccountId}`)
}

export function getProjectAndProduct() {
  return service.get('/api/system/accountHugeHousekeeper/byProjectAndProductPage')
}

export function reUpload(thirdLandingPageId) {
  return service.get(`/api/system/hugeAppApi/createThirdLandingPage/${thirdLandingPageId}`)
}
