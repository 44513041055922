<!--
 * @Author: Zeng
 * @Date: 2024-03-19 11:39:51
 * @LastEditTime: 2024-03-20 15:52:19
-->
<template>
  <el-dialog class="table-template" title="落地页预览" :visible.sync="show" :append-to-body="true" width="500px" top="20vh">
    <div class="page-container">
      <div class="qrcode-box" id="qrcodeBox">
        <span class="qrcode-loading">加载中...</span>
      </div>
      <div class="qrcode-tips" style="text-align:center">请扫码预览</div>
    </div>
    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import QRCode from 'qrcodejs2'
export default {
  data() {
    return {
      show: false
    }
  },

  created() { },

  mounted() { },
  methods: {
    /* --------------------------- Actions --------------------------- */
    showModal(data) {
      this.show = true
      setTimeout(() => {
        document.getElementById('qrcodeBox').innerHTML = ''
        new QRCode(document.getElementById('qrcodeBox'), {
          text: data.path,
          width: 150,
          height: 150,
          correctLevel: QRCode.CorrectLevel.Q
        })
      }, 200)
    },
    // 关闭弹框
    closeModal() {
      this.show = false
    }
  }
}
</script>

<style scoped lang="scss">
.qrcode-box {
  width: 150px;
  height: 150px;
  margin: auto;
  text-align: center;

  .qrcode-loading {
    color: #dcdfe6;
    font-size: 12px;
    margin-top: 40px;
  }
}

.qrcode-tips {
  text-align: center;
  margin-top: 10px;
}
</style>
