<!--
 * @Description: 轮播图编辑区
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2022-03-08 10:53:55
-->

<template>
  <section>
    <base-box name="topSlider">
      <template slot="desc">
        适用广告位：朋友圈信息流<br />
        适用外层样式：常规广告<br />
        创建广告时，仅选择了以上广告位与外层样式的广告可使用此推广页。广告外层将自动拉取顶部轮播图。
      </template>
      <template slot="body">
        <div>
          <p class="font-13">图片数量：</p>
          <div>
            <el-radio-group v-model="detail.componentCount" size="medium" @change="setImgCount">
              <el-radio-button label="3"></el-radio-button>
              <el-radio-button label="4"></el-radio-button>
              <el-radio-button label="6"></el-radio-button>
            </el-radio-group>
          </div>
        </div>

        <div class="image-updata">
          <p class="font-13">选择图片：</p>
          <div>
            <div v-for="(item, index) in detail.componentGroupList.componentGroup" :key="index.id" class="update" @click="setMaterialBox">
              <div v-if="item.componentItem.pureImageUrl !== ''" class="image">
                <img :src="item.componentItem.pureImageUrl" />
              </div>
              <i v-else class="el-icon-plus avatar-uploader-icon" />
            </div>
          </div>
        </div>
        <p class="tip mt-10" style="margin-left:70px">
          暂不支持含有二维码的图片 <br />
          图片格式：大小不超过300KB，不支持Gif <br />
          图片尺寸：800*800像素、640*960像素、640*1136像素
        </p>
      </template>
    </base-box>
    <material-box
      :visible="materialBox_visible"
      :img-count="+detail.componentCount"
      :image-list="imageList"
      name="ldy"
      select-type="multiple"
      :attribute="{
        key: 'topSlider',
        value: '顶部轮播图',
        count: +detail.componentCount,
        size: 300 * 1024,
        listType: 'image/jpg,image/jpeg,image/png',
        width: [800, 640, 640],
        height: [800, 960, 1136]
      }"
      @setMaterialBox="setMaterialBox"
      @submit="selectImg"
    />
  </section>
</template>

<script>
import BaseBox from './base.vue'
import MaterialBox from '@/components/materialBox'
import { topSlider_img_item } from '../defaultWidgetData'
export default {
  components: {
    'base-box': BaseBox,
    MaterialBox
  },
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      materialBox_visible: false,
      imageList: []
    }
  },
  methods: {
    /**
     * @description: 设置轮播图数量
     */
    setImgCount(value) {
      const { detail } = this
      let componentGroupList = { ...this.detail.componentGroupList }

      // 数量变多添加图结构
      let componentCount = componentGroupList.componentGroup.length
      if (+value > +componentCount) {
        for (let index = 0; index < +value - componentCount; index++) {
          componentGroupList.componentGroup.push({
            id: `slider_${new Date().getTime()}_${componentCount + index}`,
            componentItem: { ...topSlider_img_item }
          })
        }
        detail.componentGroupList = componentGroupList
      } else {
        // 数量变少减少图结构
        let { componentGroup } = componentGroupList
        detail.componentGroupList.componentGroup = componentGroup.slice(0, +value)
      }
    },

    /**
     * @description: 打开/关闭资源库
     */
    setMaterialBox() {
      this.materialBox_visible = !this.materialBox_visible
    },

    /**
     * @description: 选择图片后调用
     * @param {Array} img 图片信息
     */
    selectImg(imgs) {
      const { detail } = this
      this.imageList = imgs
      detail.pureImageUrl = imgs[0].image
      detail.id = 1
      let componentGroupList = { ...this.detail.componentGroupList }
      const { componentGroup } = { ...this.detail.componentGroupList }
      imgs.map((item, index) => {
        const { image, width, height } = item
        let componentItem = componentGroup[index].componentItem
        componentGroupList.componentGroup[index] = {
          ...componentGroup[index],
          componentItem: {
            ...componentItem,
            pureImageUrl: image,
            initHeight: width + '',
            initWidth: height + ''
          }
        }
      })
      detail.componentGroupList.componentGroup = componentGroup
      detail.id = `widget_${new Date().getTime()}_0`
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.update {
  width: 130px !important;
  height: 130px;
  line-height: 100px;
  text-align: center;
  border: 1px solid #edecf2;
  background-color: #fbfafd;
  border-radius: 3px;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }
  .avatar {
    width: 130px;
  }
}
.image-updata {
  display: flex !important;
  align-items: flex-start !important;
  & > div {
    display: flex;
    flex-wrap: wrap;
  }
}
// .setting {
//   display: flex;
//   justify-content: flex-start;
//   margin: 10px 0;
//   align-items: center;
//   & > p {
//     font-size: 14px;
//     color: rgb(78, 78, 78);
//   }
//   & > div {
//     width: 80%;
//     margin-left: 30px;
//   }
// }
</style>
