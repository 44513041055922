<!--
 * @Description: Do not edit
 * @Date: 2021-12-24 14:49:52
 * @LastEditTime: 2022-02-10 16:53:59
-->
<template>
  <el-form ref="searchForm" class="search-form search-form-4" :model="searchForm">
    <el-row :gutter="20">
      <el-col :span="6">
        <el-form-item prop="labelTypeName" label="标签分类名称">
          <el-input v-model="searchForm.labelTypeName" placeholder="标签分类名称" />
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="relationProjectId" label="项目">
          <el-select v-model="searchForm.relationProjectId" filterable placeholder="请选择项目">
            <el-option v-for="item in options" :key="item.bsProjectId" :label="item.projectName" :value="item.bsProjectId" />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-button class="btn" type="primary" @click="search">
          查询
        </el-button>
        <el-button class="btn" @click="resetSearch">
          重置
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { searchForm } from './object'
import { getProjectForSource } from '@/network/api/basicSetting/project/basicSettingProject'
export default {
  inject: ['loadData'],
  data() {
    return {
      options: [],
      searchForm: { ...searchForm }
    }
  },
  mounted() {
    this.getProject()
  },
  methods: {
    getProject() {
      getProjectForSource().then((x) => {
        if (x.code === 200) {
          this.options = x.data
        }
      })
    },
    search() {
      this.loadData(true)
    },
    // 折中方案
    getSearchForm() {
      return { ...this.searchForm }
    },
    resetSearch() {
      this.$refs.searchForm.resetFields()
    }
  }
}
</script>
