<template>
  <div>
    <Search ref="search" />
    <el-row type="flex" justify="space-between" class="operate-box">
      <el-button type="primary" icon="el-icon-plus" round @click="showAdd">新 增</el-button>
    </el-row>
    <div class="table-container">
      <el-table v-loading="loading" stripe class="list-table" :data="data">
        <c-data-empty slot="empty" />

        <el-table-column type="index" label="序号" width="80" align="left" />
        <el-table-column prop="masterUid" label="账户ID" align="left" />
        <el-table-column prop="masterName" label="账户名称" align="left" :show-overflow-tooltip="true" />
        <el-table-column label="操作" width="200">
          <template slot-scope="{ row }">
            <el-button type="text" size="small" @click="showTbAdd(row)">
              <el-tooltip content="同步投放账号" placement="top">
                <i class="el-icon-upload2" style="font-size: 16px"></i>
              </el-tooltip>
            </el-button>
            <!-- <el-button type="text" size="small" @click="deleteRow(row)">
              <el-tooltip content="删除" placement="top">
                <i class="el-icon-delete" style="font-size: 16px"></i>
              </el-tooltip>
            </el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <c-pagination :page-size.sync="pageInfo.pageSize" :page-no.sync="pageInfo.pageNum" :total="pageInfo.total"
      :get-data-fun="loadData" />
    <!-- <OptionDialog :visible.sync="visible" :row-account-id="rowAccountId"/> -->
    <OptionDialogSync :visible.sync="visible" :row-account-id="rowAccountId" />
    <!-- <tbOptionDialog ref="tbdialog" /> -->
  </div>
</template>

<script>
import { PageCommonComponent } from '@/common/mixin'
import { Page, Add, Delete } from '@/network/api/account/accountBaiduHousekeeper'
// import { getHouseKeeper } from '@/network/api/account/accountBaiduHousekeeper'
import Search from './Search'
// import OptionDialog from './OptionDialog'
import OptionDialogSync from './OptionDialogSync'
// import tbOptionDialog from './tbOptionDialog'
// import { AccountBaiduHousekeeperEnt } from './object'
var windowObjectReference = {
  closed: null
}
export default {
  name: 'AccountBaiduHousekeeper',
  components: { Search, OptionDialogSync },
  mixins: [PageCommonComponent],
  provide: function () {
    return {
      loadData: this.loadData
      // houseKeeperList: () => this.houseKeeper
    }
  },
  data() {
    return {
      loading: false,
      data: [],
      visible: false,
      rowAccountId: null,
      // houseKeeper: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  mounted() {
    this.loadData()
    // this.getHk()
    // let _this = this
    // var loop = setInterval(function () {
    //   if (windowObjectReference.closed) {
    //     clearInterval(loop)
    //     _this.loadData()
    //   }
    // }, 1000)

    // window.addEventListener('message', function (event) {
    //   console.log(event)
    //   if (event.origin === 'https://open.oceanengine.com' && event.data.code === 500) {
    //     this.$ErrorMessage('授权异常，请确认是否重复授权！')
    //   }
    // }, false)
  },

  methods: {
    // getHk() {
    //   getHouseKeeper().then((res) => {
    //     if (res.code === 200) {
    //       this.houseKeeper = res.data
    //     }
    //   })
    // },
    loadData(isFirst = false) {
      const searchForm = this.$refs.search.getSearchForm()
      this.doLoadData(isFirst, searchForm)
    },
    doLoadData(firstPage = false, searchForm) {
      firstPage && (this.pageInfo.pageNum = 1)
      const req = { ...searchForm, ...this.pageInfo }
      this.loading = true
      Page(req).then((res) => {
        this.data = res.data.records
        this.pageInfo.total = res.data.total
        this.loading = false
      })
    },
    deleteRow(row) {
      this.$confirm('确定要删除该账户管家吗？删除后账户管家关联的投放账户将一并删除！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          Delete(row.oauthId).then((res) => {
            if (res.code === 200) {
              this.$message({
                message: '删除成功',
                type: 'success'
              })
              this.loadData()
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              })
            }
          })
        })
        .catch(() => { })
    },
    showAdd() {
      Add().then(({ code, data }) => {
        if (code === 200) {
          this.doListenClose()
          windowObjectReference = window.open(data, 'authorize', 'width=900,height=700,left=100,top=0,menubar=no,toolbar=no')
        }
      })
    },
    showTbAdd(row) {
      this.visible = true
      this.rowAccountId = row.oauthId
    },

    doListenClose() {
      let _this = this
      var loop = setInterval(function () {
        if (windowObjectReference.closed) {
          clearInterval(loop)
          _this.loadData()
        }
      }, 1000)
    }
  }
}
</script>

<style scoped></style>
