<!--
 * @Description: 图片编辑区
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2022-02-25 09:49:28
-->
<!--
 * @Description: 图片编辑区
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2021-12-27 16:07:06
-->

<template>
  <section>
    <base-box name="img">
      <template slot="body">
        <div class="image-updata">
          <p class="font-13">选择图片：</p>
          <div>
            <div class="update" @click="setMaterialBox">
              <img v-if="detail.pureImageUrl" :src="detail.pureImageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon" />
            </div>
            <p class="tip mt-10">
              暂不支持含有二维码的图片 <br />
              图片格式：大小不超过300KB， <br />
              图片尺寸：宽度750像素，高度不超过1536像素
            </p>
          </div>
        </div>

        <div>
          <p class="font-13">上边距：</p>
          <div class="ml-20">
            <el-slider v-model="detail.paddingTop" show-input> </el-slider>
          </div>
        </div>

        <div class="setting">
          <p class="font-13">下边距：</p>
          <div class="ml-20">
            <el-slider v-model="detail.paddingBottom" show-input> </el-slider>
          </div>
        </div>
      </template>
    </base-box>
    <material-box
      :visible="materialBox_visible"
      :img-count="1"
      :image-list="imageList"
      name="ldy"
      :attribute="{
        key: 'img',
        value: '图片',
        count: 1,
        size: 300 * 1024,
        listType: 'image/jpg,image/jpeg,image/png',
        width: 750,
        height: [0, 1536]
      }"
      @setMaterialBox="setMaterialBox"
      @submit="selectImg"
    />
  </section>
</template>

<script>
import BaseBox from './base.vue'
import MaterialBox from '@/components/materialBox'

export default {
  components: {
    'base-box': BaseBox,
    MaterialBox
  },
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      materialBox_visible: false
    }
  },
  methods: {
    /**
     * @description: 打开/关闭资源库
     */
    setMaterialBox() {
      this.materialBox_visible = !this.materialBox_visible
    },

    /**
     * @description: 选择图片后调用
     * @param {Array} img 图片信息
     */
    selectImg(img) {
      this.imageList = img
      let { detail } = this
      const { height, width, image } = img[0]
      detail.imageHeight = height
      detail.imageWidth = width
      detail.pureImageUrl = image
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.update {
  width: 130px !important;
  height: 130px;
  line-height: 130px;
  text-align: center;
  border: 1px solid #edecf2;
  background-color: #fbfafd;
  border-radius: 3px;
  cursor: pointer;
  font-size: 30px;
  color: #7d7d7d;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .avatar {
    width: 130px;
  }
}

.image-updata {
  display: flex !important;
  align-items: flex-start !important;
}

// .setting {
//   display: flex;
//   justify-content: flex-start;
//   margin: 10px 0;
//   align-items: center;
//   & > p {
//     font-size: 14px;
//     color: rgb(78, 78, 78);
//   }
//   & > div {
//     width: 80%;
//     margin-left: 30px;
//   }
// }
</style>
