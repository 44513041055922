var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "table-template" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: {
            "tab-click": function ($event) {
              return _vm.onClickSelectType()
            },
          },
          model: {
            value: _vm.copyIndex,
            callback: function ($$v) {
              _vm.copyIndex = $$v
            },
            expression: "copyIndex",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "文案", name: "1" } }),
          _c("el-tab-pane", { attrs: { label: "文案组", name: "2" } }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "search-form-container" },
        [
          _c(
            "el-form",
            { staticClass: "search-form search-form-4" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _vm.copyIndex === "1"
                    ? _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入文案" },
                            model: {
                              value: _vm.searchForm.text,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "text", $$v)
                              },
                              expression: "searchForm.text",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.copyIndex === "2"
                    ? _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入文案组" },
                            model: {
                              value: _vm.searchForm.textGroupName,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "textGroupName", $$v)
                              },
                              expression: "searchForm.textGroupName",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            clearable: "",
                            placeholder: "请选择项目",
                          },
                          on: { change: _vm.handleSelect },
                          model: {
                            value: _vm.searchForm.projectId,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "projectId", $$v)
                            },
                            expression: "searchForm.projectId",
                          },
                        },
                        _vm._l(_vm.projectList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: item.projectName,
                              value: item.bsProjectId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            disabled: !_vm.searchForm.projectId,
                            clearable: "",
                            placeholder: "请选择产品",
                          },
                          model: {
                            value: _vm.searchForm.productId,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "productId", $$v)
                            },
                            expression: "searchForm.productId",
                          },
                        },
                        _vm._l(_vm.productList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: item.productName,
                              value: item.bsProductId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "70px" },
                          attrs: { type: "primary" },
                          on: { click: _vm.onClickSearch },
                        },
                        [_vm._v("搜索")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { width: "70px" },
                          attrs: { type: "plain" },
                          on: { click: _vm.onClickReset },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "flex-btn" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c(
              "el-dropdown",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.copyIndex === "1",
                    expression: "copyIndex === '1'",
                  },
                ],
                attrs: {
                  disabled: !_vm.selectedData.length,
                  "split-button": "",
                  type: "primary",
                },
              },
              [
                _vm._v(" 批量操作 "),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c(
                      "el-dropdown-item",
                      {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.onClickMoveProduct.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("批量关联产品")]
                    ),
                    _c(
                      "el-dropdown-item",
                      {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.onClickCreateTxtGroup.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [_vm._v("生成文案组")]
                    ),
                    _c(
                      "el-dropdown-item",
                      {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.onClickBatchDel.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("批量删除")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-dropdown",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.copyIndex === "2",
                    expression: "copyIndex === '2'",
                  },
                ],
                attrs: {
                  disabled: !_vm.selectedGroupData.length,
                  "split-button": "",
                  type: "primary",
                },
              },
              [
                _vm._v(" 批量操作 "),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c(
                      "el-dropdown-item",
                      {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.onClickBatchDel.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("批量删除")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.copyIndex === "1",
                    expression: "copyIndex === '1'",
                  },
                ],
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.onClickAddTxt(0)
                  },
                },
              },
              [_vm._v("新增文案")]
            ),
            _c(
              "el-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.copyIndex === "2",
                    expression: "copyIndex === '2'",
                  },
                ],
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.onClickAddTxtGroup(0)
                  },
                },
              },
              [_vm._v("新增文案组")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _vm.copyIndex === "1"
            ? [
                _c(
                  "el-table",
                  {
                    ref: "table",
                    attrs: { data: _vm.txtList, "v-loading": _vm.loading },
                    on: { "selection-change": _vm.onTableTextSelectionChange },
                  },
                  [
                    _c("c-data-empty", {
                      attrs: { slot: "empty" },
                      slot: "empty",
                    }),
                    _c("el-table-column", {
                      attrs: { fixed: "", type: "selection" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "ID",
                        "show-overflow-tooltip": "",
                        "min-width": "50px",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(row.textId)),
                              ])
                            },
                          },
                        ],
                        null,
                        false,
                        1174092984
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "文案",
                        "show-overflow-tooltip": "",
                        "min-width": "150px",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return _c("span", {}, [_vm._v(_vm._s(row.text))])
                            },
                          },
                        ],
                        null,
                        false,
                        1135254773
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "创建人",
                        "show-overflow-tooltip": "",
                        "min-width": "150px",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(row.nickname)),
                              ])
                            },
                          },
                        ],
                        null,
                        false,
                        826081888
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "项目",
                        "show-overflow-tooltip": "",
                        "min-width": "150px",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(row.projectName)),
                              ])
                            },
                          },
                        ],
                        null,
                        false,
                        2905948826
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "产品",
                        "show-overflow-tooltip": "",
                        "min-width": "150px",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(row.productName)),
                              ])
                            },
                          },
                        ],
                        null,
                        false,
                        1664228484
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "创建时间",
                        "show-overflow-tooltip": "",
                        "min-width": "250px",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(row.createdAt)),
                              ])
                            },
                          },
                        ],
                        null,
                        false,
                        25548189
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        "min-width": "80px",
                        align: "center",
                        fixed: "right",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("div", { staticClass: "opt-container" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "opt-text",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onClickAddTxt(row.textId)
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "opt-text",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onClickDel(row.textId)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2979173326
                      ),
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.copyIndex === "2"
            ? [
                _c(
                  "el-table",
                  {
                    ref: "table",
                    attrs: { data: _vm.txtGroupList, "v-loading": _vm.loading },
                    on: {
                      "selection-change": _vm.onTableTextGroupSelectionChange,
                    },
                  },
                  [
                    _c("c-data-empty", {
                      attrs: { slot: "empty" },
                      slot: "empty",
                    }),
                    _c("el-table-column", {
                      attrs: { fixed: "", type: "selection" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "ID",
                        "show-overflow-tooltip": "",
                        "min-width": "50px",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(row.textGroupId)),
                              ])
                            },
                          },
                        ],
                        null,
                        false,
                        1645941991
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "文案组名称",
                        "show-overflow-tooltip": "",
                        "min-width": "150px",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(row.textGroupName)),
                              ])
                            },
                          },
                        ],
                        null,
                        false,
                        3080390829
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "创建人",
                        "show-overflow-tooltip": "",
                        "min-width": "150px",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(row.nickname)),
                              ])
                            },
                          },
                        ],
                        null,
                        false,
                        826081888
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "项目",
                        "show-overflow-tooltip": "",
                        "min-width": "150px",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(row.projectName)),
                              ])
                            },
                          },
                        ],
                        null,
                        false,
                        2905948826
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "产品",
                        "show-overflow-tooltip": "",
                        "min-width": "150px",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(row.productName)),
                              ])
                            },
                          },
                        ],
                        null,
                        false,
                        1664228484
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "文案数量",
                        "show-overflow-tooltip": "",
                        "min-width": "150px",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(row.textNum)),
                              ])
                            },
                          },
                        ],
                        null,
                        false,
                        2491463843
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "创建时间",
                        "show-overflow-tooltip": "",
                        "min-width": "250px",
                        align: "center",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return _c("span", {}, [
                                _vm._v(_vm._s(row.createdAt)),
                              ])
                            },
                          },
                        ],
                        null,
                        false,
                        25548189
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        "min-width": "80px",
                        align: "center",
                        fixed: "right",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("div", { staticClass: "opt-container" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "opt-text",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onClickEditTxtGroup(
                                            row.textGroupId
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "opt-text",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onClickDel(row.textGroupId)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1096792620
                      ),
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "div",
            { staticClass: "pagination-container" },
            [
              _c("c-pagination", {
                attrs: {
                  "page-size": _vm.pageSize,
                  "page-no": _vm.pageNum,
                  total: _vm.total,
                  "get-data-fun": _vm.getPageData,
                },
                on: {
                  "update:pageSize": function ($event) {
                    _vm.pageSize = $event
                  },
                  "update:page-size": function ($event) {
                    _vm.pageSize = $event
                  },
                  "update:pageNo": function ($event) {
                    _vm.pageNum = $event
                  },
                  "update:page-no": function ($event) {
                    _vm.pageNum = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _c("edit-copy-dialog", {
        ref: "editCopyDialog",
        on: { edit: _vm._getTxtList },
      }),
      _c("add-copy-group-dialog", {
        ref: "addCopyGroupDialog",
        on: { edit: _vm._getTxtGrouplList },
      }),
      _c("edit-copy-group-dialog", {
        ref: "editCopyGroupDialog",
        on: { edit: _vm._getTxtGrouplList },
      }),
      _c("batch-contact-product", {
        ref: "batchContactProduct",
        on: { edit: _vm._getTxtList },
      }),
      _c("create-txt-group-dialog", { ref: "createTxtGroupDialog" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }