var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "section" },
    [
      _c(
        "base-box",
        { attrs: { name: "copyGh" } },
        [
          _c("template", { slot: "body" }, [
            _c("div", [
              _c("p", { staticClass: "w-100" }, [_vm._v("组件样式：")]),
              _c(
                "div",
                { staticStyle: { display: "flex", "align-items": "center" } },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "medium" },
                          model: {
                            value: _vm.detail.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.detail, "type", $$v)
                            },
                            expression: "detail.type",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "label" } }, [
                            _vm._v("标签"),
                          ]),
                          _c("el-radio", { attrs: { label: "button" } }, [
                            _vm._v("按钮"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c(
              "div",
              [
                _c("p", { staticClass: "w-100" }, [_vm._v("组件背景色：")]),
                _c(
                  "el-dropdown",
                  {
                    staticStyle: { width: "200px" },
                    attrs: { placement: "bottom-start" },
                  },
                  [
                    _c("div", {
                      staticClass: "ldy-bg-color",
                      style: `background:${_vm.detail.backgroundColor}`,
                    }),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c("twitter-color", {
                          staticStyle: { "margin-top": "10px" },
                          attrs: { colors: _vm.detail.backgroundColor },
                          on: {
                            change: (value) =>
                              (_vm.detail.backgroundColor = value),
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", [
              _c("p", { staticClass: "w-100" }, [_vm._v("公众号字体色：")]),
              _c(
                "div",
                [
                  _c(
                    "el-dropdown",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { placement: "bottom-start" },
                    },
                    [
                      _c("div", {
                        staticClass: "ldy-bg-color",
                        style: `background:${_vm.detail.ghFontColor}`,
                      }),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _c("twitter-color", {
                            staticStyle: { "margin-top": "10px" },
                            attrs: { colors: _vm.detail.ghFontColor },
                            on: {
                              change: (value) =>
                                (_vm.detail.ghFontColor = value),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", [
              _c("p", { staticClass: "w-100" }, [_vm._v("按钮文案：")]),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      value: _vm.detail.btnTitle,
                      maxlength: "10",
                      "show-word-limit": "",
                      type: "text",
                      size: "small",
                    },
                    on: { input: _vm.btnTitle },
                  }),
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "ml-20",
                      attrs: { size: "small" },
                      model: {
                        value: _vm.detail.btnFontType,
                        callback: function ($$v) {
                          _vm.$set(_vm.detail, "btnFontType", $$v)
                        },
                        expression: "detail.btnFontType",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "normal" } }, [
                        _vm._v("常规"),
                      ]),
                      _c("el-radio-button", { attrs: { label: "bold" } }, [
                        _vm._v("加粗"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", [
              _c("p", { staticClass: "w-100" }, [_vm._v("按钮字体色：")]),
              _c(
                "div",
                [
                  _c(
                    "el-dropdown",
                    [
                      _c("div", {
                        staticClass: "ldy-bg-color",
                        style: `background:${_vm.detail.btnFontColor}`,
                      }),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _c("twitter-color", {
                            staticStyle: { "margin-top": "10px" },
                            attrs: { colors: _vm.detail.btnFontColor },
                            on: {
                              change: (value) =>
                                (_vm.detail.btnFontColor = value),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", [
              _c("p", { staticClass: "w-100" }, [_vm._v("按钮边框色：")]),
              _c(
                "div",
                [
                  _c(
                    "el-dropdown",
                    [
                      _c("div", {
                        staticClass: "ldy-bg-color",
                        style: `background:${_vm.detail.btnBorderColorTheme}`,
                      }),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _c("twitter-color", {
                            staticStyle: { "margin-top": "10px" },
                            attrs: { colors: _vm.detail.btnBorderColorTheme },
                            on: {
                              change: (value) =>
                                (_vm.detail.btnBorderColorTheme = value),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", [
              _c("p", { staticClass: "w-100" }, [_vm._v("按钮填充色：")]),
              _c(
                "div",
                [
                  _c(
                    "el-dropdown",
                    [
                      _c("div", {
                        staticClass: "ldy-bg-color",
                        style: `background:${_vm.detail.btnBgColorTheme}`,
                      }),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _c("twitter-color", {
                            staticStyle: { "margin-top": "10px" },
                            attrs: { colors: _vm.detail.btnBgColorTheme },
                            on: {
                              change: (value) =>
                                (_vm.detail.btnBgColorTheme = value),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", [
              _c("p", { staticClass: "w-100" }, [_vm._v("上边距：")]),
              _c(
                "div",
                [
                  _c("el-slider", {
                    attrs: { "show-input": "" },
                    model: {
                      value: _vm.detail.paddingTop,
                      callback: function ($$v) {
                        _vm.$set(_vm.detail, "paddingTop", $$v)
                      },
                      expression: "detail.paddingTop",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", [
              _c("p", { staticClass: "w-100" }, [_vm._v("下边距：")]),
              _c(
                "div",
                [
                  _c("el-slider", {
                    attrs: { "show-input": "" },
                    model: {
                      value: _vm.detail.paddingBottom,
                      callback: function ($$v) {
                        _vm.$set(_vm.detail, "paddingBottom", $$v)
                      },
                      expression: "detail.paddingBottom",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }