<!--
 * @Description: 计划/ 广告 展示 tree
 * @Date: 2021-12-06 15:57:16
 * @LastEditTime: 2022-03-08 17:27:41
-->
<template>
  <div class="advertisement">
    <!-- 添加计划 -->
    <!-- <SelectModal
      ref="modal"
      type="table"
      dialog-title="品牌标识"
      left-title="投放账户"
      :title-info="{
        title: '选择推广计划',
        leftLevel: '1'
      }"
      :visible="brandModalVisible"
      :left-query-list="accountBaiduAdv"
      :data="brandPageList"
      :table-data-source="dataSource"
      @onOk="getSelectPlanData"
    /> -->

    <el-dialog title="品牌标识" :visible="brandModalVisible" width="1200px" :append-to-body="true" destroy-on-close @close="resetForm()">
      <el-row :gutter="10">
        <el-col class="tree" :span="8">
          <div class="left-ul">
            <p>已选投放账户</p>
            <!-- 模板数据 -->
            <ul>
              <div v-if="accountBaiduAdv.length">
                <div>
                  <li v-for="item in accountBaiduAdv" :key="item._id" :class="{ active: leftQuerySelect._id === item._id }" @click="setLeftQuerySelect(item)">{{ `${item._name}(${item.taaccountId})` }}</li>
                </div>
              </div>
              <div v-else style="text-align: center; margin-top: 40px">
                <span>未选择</span>
              </div>
            </ul>
          </div>
        </el-col>
        <el-col :span="16">
          <el-input v-model="serarch" placeholder="请输入关键词查询" class="input-with-select mb-10 w-300">
            <el-button slot="append" icon="el-icon-search" @click="getBrandFun"></el-button>
          </el-input>
          <!-- <el-button type="text" class="ml-10" @click="getBrandFun()">查询</el-button> -->
          <el-button type="text" class="ml-10" @click="addBrandInfo()">上传品牌标识</el-button>
          <TablePane ref="TablePane" :data-source="dataSource" @getPage="getBrandFun" />
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="getSelectPlanData">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="品牌标识上传" :visible.sync="uploadBrandVisible" width="500px" :append-to-body="true">
      <span>
        <el-form ref="uploadForm" :model="uploadForm" label-width="80px">
          <el-form-item label="上传范围">
            <el-radio v-model="uploadForm.range" label="only">当前投放账户</el-radio>
            <el-radio v-model="uploadForm.range" label="all">全部投放账户</el-radio>
          </el-form-item>
          <el-form-item label="品牌形象">
            <div class="justify-start">
              <div class="mr-10">
                <div class="avatar-uploader" @click="materialBoxVisible = true">
                  <img v-if="uploadForm.imageUrl" :src="uploadForm.imageUrl" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon" />
                </div>
              </div>
              <div class="info">
                <p>尺寸：512px * 512px</p>
                <p>格式：JPG、JPEG、PNG格式</p>
                <p>大小：50KB 以内</p>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="品牌名称">
            <el-input v-model="uploadForm.name" maxlength="8" show-word-limit></el-input>
          </el-form-item>
        </el-form>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="uploadBrandVisible = false">取 消</el-button>
        <el-button type="primary" @click="brandAddFun">确 定</el-button>
      </span>
    </el-dialog>

    <material-box
      :visible="materialBoxVisible"
      :img-count="1"
      name="creativeGroup"
      :image-list="[]"
      :attribute="{
        key: 'creativeGroup',
        value: '上传头像',
        count: 1,
        size: 50 * 1024,
        listType: 'image/jpg,image/jpeg,image/png',
        width: 512,
        height: 512
      }"
      :project-id="[+projectId]"
      @setMaterialBox="materialBoxVisible = false"
      @submit="selectImg"
    />
  </div>
</template>

<script>
import { getBrand, saveBrand, brandAdd } from '@/network/api/advManagement/advManagementTencent' // 获取计划模板
// import SelectModal from '../component/selectModal/selectModal.vue'
// import { Sync } from '@/network/api/assetManagement/assetManageTencent.js'
import MaterialBox from './img.vue'
import TablePane from '@/components/tablePane.vue'

export default {
  provide: function () {
    return {
      getDataByLeftQuery: this.getBrandFun,
      queryFun1: this.branSync,
      queryText1: '同步',
      queryFun2: this.addBrandInfo,
      queryText2: '上传品牌标识'
    }
  },

  inject: ['close'],

  components: {
    // SelectModal,
    TablePane,
    MaterialBox
  },

  props: {
    brandModalVisible: {
      type: Boolean,
      default: false
    },
    accountBaiduAdv: {
      type: Array,
      default: () => []
    },
    brandData: {
      type: Array,
      default: () => []
    },
    projectId: {
      type: [String, Number],
      default: ''
    },
    taskId: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      data: this.brandData,
      brandPageList: [],
      leftQuerySelect: this.accountBaiduAdv[0] || {},
      selectBrandPageList: [],
      uploadBrandVisible: false,
      materialBoxVisible: false,
      serarch: '',
      uploadForm: {
        range: 'only',
        name: '',
        imageId: '',
        imageUrl: '',
        accountId: ''
      },
      // 表格数据
      dataSource: {
        data: [],
        cols: [
          {
            label: '品牌标识',
            property: 'imageUrl'
          },
          {
            label: '品牌名称',
            prop: 'name'
          }
        ], // 表格的列数据
        isIndex: false, // 列表序号
        loading: false, // loading
        isOperation: false,
        isSelection: true,
        isBorder: true,
        handleSelectionChange: (rows) => {
          // this.selectBrandPageList = []
          let select = []
          if (rows.length > 1) {
            const pop = rows.pop()
            select = [pop]
            // this.selectBrandPageList.push(pop)
            this.$refs.TablePane.clearSelection()
            this.$refs.TablePane.toggleRowSelection(pop)
          } else {
            select = [...rows]
          }
          if (rows && rows.length) {
            const index = this.selectBrandPageList.findIndex((s) => s.accountId === select[0].accountId)
            if (index > -1) {
              this.selectBrandPageList.splice(index, 1, select[0])
            } else {
              this.selectBrandPageList.push(select[0])
            }
          }
          // console.log(rows, select, this.selectBrandPageList, 'hhhhh')
        },
        pageData: {
          total: 0, // 总条数
          pageSize: 10,
          layout: 'total, prev, pager, next'
        },
        select: () => {},
        selectable: () => {
          return true
        },
        selectAll: () => {}
      }
    }
  },

  watch: {
    accountBaiduAdv(val) {
      if (val && val.length) {
        // this.setLeftQuerySelect(val[0])
        this.leftQuerySelect = val[0]
      }
    },
    brandModalVisible(val) {
      if (val) {
        // this.setSelectData()
        this.setLeftQuerySelect(this.accountBaiduAdv[0])
      }
    },
    brandData(val) {
      if (val && val.length) {
        this.selectBrandPageList = []
        val.forEach(item => {
          if (item.tabrandImg && item.tabrandName) {
            this.selectBrandPageList.push({
              accountId: item.taaccountId,
              imageId: item.tabrandImg,
              name: item.tabrandName,
              key: item.tabrandImg + '+' + item.taskAccountId + '+' + item.name,
              taskAccountId: item.taskAccountId
            })
          }
        })
      }
    }
  },

  methods: {
    setLeftQuerySelect(item) {
      this.leftQuerySelect = item
      this.getBrandFun()
    },

    addBrandInfo() {
      this.uploadBrandVisible = true
    },

    /**
     * @description: 同步粤宝落地页
     */
    branSync(id) {
      if (!id) return this.$message.error('请先选择账户')
      // Sync({ accountIds: [id], promotedObjectType: 'PROMOTED_OBJECT_TYPE_LEAD_AD' }, 'adManagementGdtBrand').then((res) => {
      //   if (res.code === 200) {
      //     this.$message.success('刷新成功!')
      //     // this.sync_data = res.data
      //     // this.dialogVisible = true
      //   }
      // })
    },

    /**
     * @desc 获取当前选中账户下的品牌
     */
    getBrandFun(params = { pageNum: 1, pageSize: 10 }) {
      this.dataSource.loading = true
      getBrand({ localAccountId: this.leftQuerySelect.localAccountId, ...params }).then((res) => {
        if (res && res.code === 200) {
          const data = res.data
          this.dataSource.pageData.total = data.total
          data.list.forEach((ele) => {
            ele.key = `${ele.imageId}+${this.leftQuerySelect._id}+${ele.name}`
            ele.taskAccountId = this.leftQuerySelect._id
          })
          // this.dataSource.data = data.list
          this.brandPageList = data.list
          this.dataSource.data = data.list
          this.dataSource.pageData.total = data.pageInfo.totalNumber
          this.dataSource.pageData.pageNum = params.pageNum
          this.dataSource.loading = false
          this.setSelectData()
        } else {
          this.dataSource.loading = false
        }
      })
    },

    setSelectData() {
      this.$refs['TablePane'].getRowData([])
      if (this.selectBrandPageList.length) {
        let rows = []
        this.dataSource.data.map((item) => {
          if (this.selectBrandPageList.some((s) => `${s.imageId}+${s.taskAccountId}+${s.name}` === item.key)) {
            rows.push(item)
          }
        })
        this.$refs['TablePane'].getRowData(rows)
      }
    },

    /**
     * @desc 获取选中的数据后提交
     */
    getSelectPlanData() {
      // console.log(this.selectBrandPageList)
      if (!this.selectBrandPageList || !this.selectBrandPageList.length) {
        return this.$message.error('请选择品牌形象!')
      }
      let lists = []
      this.selectBrandPageList.map((item) => {
        if (lists.length === 0 || !lists.some((s) => item.key === `${s.tABrandImg}+${s.taskAccountId}+${s.tABrandName}`)) {
          lists.push({
            tABrandImg: item.imageId,
            tABrandName: item.name,
            taskAccountId: item.taskAccountId,
            tAIsUseBrand: true
          })
        }
      })
      this.$emit('clearTaskGenerate')
      saveBrand({ lists }).then((res) => {
        if (res.code === 200) {
          this.$emit('byTaskIdGetAllFun')
          this.$message.success('品牌保存成功!')
          this.close()
          this.selectBrandPageList = []
        }
      })
    },

    selectImg(value) {
      this.uploadForm.imageId = value[0]?.imageId
      this.uploadForm.imageUrl = value[0]?.image
    },

    // 上传品牌形象
    brandAddFun() {
      if (!this.uploadForm.imageUrl) {
        return this.$message.error('请上传品牌形象!')
      }
      if (!this.uploadForm.name) {
        return this.$message.error('请填写品牌名称!')
      }
      let localAccountId = this.leftQuerySelect.localAccountId.toString() + ','
      if (this.uploadForm.range === 'all') {
        localAccountId = ''
        this.accountBaiduAdv.forEach((item) => {
          localAccountId += item.localAccountId + ','
        })
      }
      const params = {
        url: this.uploadForm.imageUrl,
        brandName: this.uploadForm.name,
        localAccountId: localAccountId.substr(0, localAccountId.length - 1)
      }
      brandAdd(params).then(({ code }) => {
        if (code === 200) {
          this.$message.success('上传品牌标识成功!')
          this.getBrandFun()
          this.uploadBrandVisible = false
        }
      })
    },

    resetForm() {
      this.close()
    }
  }
}
</script>

<style lang="scss">
.handleTree-icon :hover {
  color: #409eff;
}

.avatar-uploader {
  .avatar-uploader-icon {
    font-size: 28px;
    color: #eae9f0;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border: 1px solid #f2f2f2;
    border-radius: 4px;
  }
  .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
}
.info {
  color: #ccc;
  p {
    margin: 0;
    padding: 0;
    font-size: 12px;
    line-height: 20px;
  }
}
.advertisement {
  .custom-tree-node {
    // flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    width: 100px !important;
    .label {
      width: 300px !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.tree {
  height: 100%;
  padding-left: 10px !important;
  border: 1px solid #ccc;
  border-radius: 8px;
  // padding: 20px;
  display: flex;

  .left-ul {
    // padding-top: 10px;
    width: 100%;
    // border-right: 1px solid #ccc;
    p {
      font-size: 16px;
      width: 100%;
      padding: 10px;
      border-bottom: 1px solid #ccc;
    }
    ul {
      overflow-y: auto;
      height: 452px;
      li {
        padding: 8px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        &.active {
          background: #409eff;
          color: #fff;
        }
      }
    }
  }
  &.el-col {
    padding: 0 !important;
  }
}
</style>
