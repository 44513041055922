export let searchForm = {
  audienceId: null,
  projectId: null,
  projectName: null,
  productId: null,
  productName: null,
  audienceName: null,
  deleted: null,
  createBy: null,
  createTime: null,
  updateBy: null,
  updateTime: null,
  audience: null,
  selectDate: null,
  remark: null
}

export class AssetManagementBaiduAudienceEnt {
  audienceId
  projectId
  projectName
  productId
  productName
  audienceName
  deleted
  createBy
  createTime
  updateBy
  updateTime
  audience
  remark
}

export let formRules = {
  audienceId: [{ required: true, message: '请填写定向设置id', trigger: 'change' }],
  projectId: [{ required: true, message: '请填写关联项目', trigger: 'change' }],
  projectName: [{ required: true, message: '请填写动态使用不做保存', trigger: 'change' }],
  productId: [{ required: true, message: '请填写关联产品', trigger: 'change' }],
  productName: [{ required: true, message: '请填写动态使用不做保存', trigger: 'change' }],
  audienceName: [{ required: true, message: '请填写定向名称', trigger: 'change' }],
  deleted: [{ required: true, message: '请填写逻辑删除', trigger: 'change' }],
  createBy: [{ required: true, message: '请填写创建者', trigger: 'change' }],
  createTime: [{ required: true, message: '请填写创建时间', trigger: 'change' }],
  updateBy: [{ required: true, message: '请填写更新者', trigger: 'change' }],
  updateTime: [{ required: true, message: '请填写更新时间', trigger: 'change' }],
  remark: [{ required: true, message: '请填写备注', trigger: 'change' }]
}
