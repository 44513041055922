<!--
 * @Description: 小程序banner广告
 * @Date: 2022-01-06 10:42:13
 * @LastEditTime: 2022-01-21 17:46:36
-->
<template>
  <div>
    <el-form-item label="创意样式：" prop="crtSize">
      <el-radio-group v-model="ruleFormData.crtSize">
        <el-radio :label="556">图片名片式banner</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="外层素材跳转" prop="pageType">
      <el-radio-group v-model="ruleFormData.pageType">
        <el-radio :label="4">原生推广页</el-radio>
        <el-radio :label="6">公众号详情页</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="点击按钮跳转：" prop="buttonPageType">
      <p class="tip reset-p" style="margin-top: 6px">弹窗提示用户关注</p>
    </el-form-item>

    <el-form-item label="创意名称：" prop="tname" :rules="{ required: true, message: '请输入创意名称', maxlength: 60 }">
      <el-input v-model="ruleFormData.tname" rows="4" type="textarea" style="width: 400px" placeholder="请输入创意名称" maxlength="60" show-word-limit />
    </el-form-item>
  </div>
</template>

<script>
export default {
  props: {
    ruleFormData: {
      type: Object,
      default: () => {}
    },
    promotedObjectType: {
      type: String,
      default: ''
    },
    taskId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      ruleForm: this.ruleFormData
    }
  },

  methods: {}
}
</script>

<style lang="scss"></style>
