<template>
  <div>
    <!-- 列表查询 -->
    <el-form
      ref="search"
      :inline="true"
      :model="search"
      class="search-form search-form-4"
    >
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item
            label="产品名称"
            prop="toolProductName"
          >
            <el-input
              v-model="search.toolProductName"
              placeholder="请输入产品名称"
              style="width: 100%"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item
            label="平台"
            prop="toolProductPlatform"
          >
            <el-select
              v-model="search.toolProductPlatform"
              style="width: 100%"
            >
              <el-option
                label="全部"
                :value="1"
              ></el-option>

              <el-option
                v-for="item in platformList"
                :key="item.dictValue"
                :label="item.dictLabel"
                :value="item.dictValue"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item
            label="类型"
            prop="type"
          >
            <el-select
              v-model="search.type"
              style="width: 100%"
            >
              <el-option
                label="全部"
                :value="1"
              ></el-option>
              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item
            label="收录时间"
            prop="createTime"
          >
            <el-date-picker
              v-model="search.createTime"
              style="width: 100%"
              type="daterange"
              prange-separator="至"
              start-placeholder="开始时间"
              value-format="yyyy-MM-dd"
              end-placeholder="结束时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item
            label="筛选状态"
            prop="toolProductFilterStatus"
          >
            <el-select
              v-model="search.toolProductFilterStatus"
              style="width: 100%"
            >
              <el-option
                label="全部"
                :value="1"
              ></el-option>

              <el-option
                v-for="item in filterStatusList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item
            label="测试状态"
            prop="toolProductTestStatus"
          >
            <el-select
              v-model="search.toolProductTestStatus"
              style="width: 100%"
            >
              <el-option
                label="全部"
                :value="1"
              ></el-option>

              <el-option
                v-for="item in testStatusList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item
            label="投手分配"
            prop="launchDivide"
          >
            <el-select
              v-model="search.launchDivide"
              style="width: 100%"
            >
              <el-option
                label="全部"
                :value="1"
              ></el-option>
              <el-option
                label="已分配"
                :value="2"
              ></el-option>
              <el-option
                label="未分配"
                :value="3"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item
            label="素材分配"
            prop="assetDivide"
          >
            <el-select
              v-model="search.assetDivide"
              style="width: 100%"
            >
              <el-option
                label="全部"
                :value="1"
              ></el-option>
              <el-option
                label="已分配"
                :value="2"
              ></el-option>
              <el-option
                label="未分配"
                :value="3"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item
            label="分配时间"
            prop="allocationTime"
          >
            <el-date-picker
              v-model="search.allocationTime"
              style="width: 100%"
              type="daterange"
              prange-separator="至"
              start-placeholder="开始时间"
              value-format="yyyy-MM-dd"
              end-placeholder="结束时间"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item>
            <el-button
              type="primary"
              round
              @click="onSubmit"
            >查 询</el-button>
            <el-button
              type="primary"
              round
              @click="resetForm('ruleForm')"
            >重 置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div
      style="display: flex;justify-content: space-between;"
      class="mb-15"
    >
      <el-button
        v-has="'addProduct'"
        type="primary"
        icon="el-icon-plus"
        round
        @click="handleAddProduct('')"
      >
        新建
      </el-button>

      <el-button
        v-has="'productScreen'"
        type="primary"
        round
        @click="handleFilterSetting"
      >
        筛选设置
      </el-button>
    </div>

    <div>
      <el-table
        v-loading="list.loading"
        :data="list.data"
        stripe
        class="list-table"
        :class="{ 'no-data': !list.data || !list.data.length }"
        style="width: 100%"
      >
        <el-table-column
          type="index"
          label="序号"
          width="50"
          align="center"
          fixed="left"
        ></el-table-column>

        <el-table-column
          prop="toolProductName"
          label="名称"
          align="left"
          fixed="left"
          show-overflow-tooltip
          width="200px"
        >
          <template slot-scope="{ row }">
            <el-button
              class="tool-product-name"
              type="text"
              icon="el-icon-document-copy"
              :data-clipboard-text="row.toolProductName"
              @click="handleClickCopy"
            >
              {{ row.toolProductName }}
            </el-button>
          </template>
        </el-table-column>

        <el-table-column
          prop="toolProductSource"
          label="来源"
          align="center"
          show-overflow-tooltip
        ></el-table-column>

        <el-table-column
          label="平台"
          align="center"
        >
          <template slot-scope="{ row }">
            {{ platformList.find(item => item.dictValue === row.toolProductPlatform)?.dictLabel }}
          </template>
        </el-table-column>

        <el-table-column
          label="类型"
          align="center"
        >
          <template slot-scope="{ row }">
            {{ row.type | filterToolType }}
          </template>
        </el-table-column>

        <el-table-column
          label="连载状态"
          align="center"
        >
          <template slot-scope="{ row }">
            {{ row.serializeStatus === '1' ? '连载' : '完结' }}
          </template>
        </el-table-column>

        <el-table-column
          prop="num"
          label="数量"
          align="center"
        ></el-table-column>

        <el-table-column
          label="主角名"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="{ row }">
            {{ row.lead || '-' }}
          </template>
        </el-table-column>

        <el-table-column
          prop="createTime"
          label="收录时间"
          align="center"
          width="180"
        ></el-table-column>

        <el-table-column
          label="评价"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-button
              type="text"
              @click="handleProductEvaluation(row.toolProductId)"
            >{{
              row.toolProductCommentLists?.length || 0
            }}条</el-button>
          </template>
        </el-table-column>

        <el-table-column
          label="付费卡点"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-button
              type="text"
              @click="handlePayCardPoint(row.toolProductId)"
            >{{
              row.payCardPointListShow || '添加'
            }}</el-button>
          </template>
        </el-table-column>

        <el-table-column
          label="筛选状态"
          align="center"
        >
          <template slot-scope="{ row }">
            {{ row.toolProductFilterStatus | filterToolProductFilterStatus }}
          </template>
        </el-table-column>

        <el-table-column
          label="测试状态"
          align="center"
        >
          <template slot-scope="{ row }">
            {{ row.toolProductTestStatus | filterToolProductTestStatus }}
          </template>
        </el-table-column>

        <el-table-column
          label="测试详情"
          align="center"
        >
          <template slot-scope="{ row }">
            <el-button
              v-if="row.toolProductFilterStatus === '5' && (row.toolProductTestStatus === '4' || row.toolProductTestStatus === '5')"
              type="text"
              @click="handleTestDetails(row.toolProductId, row)"
            >查看</el-button>

            <span v-else>-</span>
          </template>
        </el-table-column>

        <el-table-column
          label="测试投手"
          align="center"
          show-overflow-tooltip
          width="180"
        >
          <template slot-scope="{ row }">
            <span v-if="row.toolProductFilterStatus !== '5'">-</span>
            <el-tooltip
              v-else-if="row.toolProductLaunchPersonsTest && row.toolProductLaunchPersonsTest.length"
              v-has="'testPitcher'"
              placement="top-start"
            >
              <div slot="content">
                <div
                  v-for="(asset, index) in row.toolProductLaunchPersonsTest"
                  :key="index"
                >{{ asset.userName || '-'
                }}：{{ asset.adSpace || '-' }}</div>
              </div>
              <div>
                <div
                  v-for="(asset, index) in row.toolProductLaunchPersonsTest.slice(0, 3)"
                  :key="index"
                  class="tool-user-label"
                  @click="handleTestPitcher(row.toolProductId)"
                >{{ asset.userName || '-' }}：{{ asset.adSpace || '-' }}</div>
              </div>
            </el-tooltip>
            <el-button
              v-else
              v-has="'testPitcher'"
              class="tool-user-label"
              type="text"
              @click="handleTestPitcher(row.toolProductId)"
            >添加</el-button>
          </template>
        </el-table-column>

        <el-table-column
          prop="allocationTime"
          label="分配时间"
          align="center"
          width="180"
        >
          <template slot-scope="{ row }">
            {{ row.allocationTime || '-' }}
          </template>
        </el-table-column>

        <el-table-column
          label="素材人员"
          align="center"
          show-overflow-tooltip
          width="180"
        >
          <template slot-scope="{ row }">
            <span v-if="row.toolProductFilterStatus !== '5'">-</span>

            <el-button
              v-if="row.toolProductFilterStatus === '5'"
              v-has="'materialPersonnel'"
              type="text"
              @click="handleAllocateMaterials(row.toolProductId)"
            >{{ row.assetPersons }}</el-button>
          </template>
        </el-table-column>

        <el-table-column
          prop=""
          label="操作"
          fixed="right"
        >
          <template slot-scope="{ row }">
            <el-button
              type="text"
              @click="handleAddProduct(row.toolProductId)"
            >
              <el-tooltip
                content="编辑"
                placement="top"
              >
                <i
                  class="el-icon-edit"
                  style="font-size: 16px"
                ></i>
              </el-tooltip>
            </el-button>

            <el-button
              type="text"
              class="red"
              @click="handleDeleteElement(row.toolProductId)"
            >
              <el-tooltip
                content="删除"
                placement="top"
              >
                <i
                  class="el-icon-delete"
                  style="font-size: 16px"
                ></i>
              </el-tooltip>
            </el-button>

            <el-button
              v-if="row.toolProductFilterStatus === '2'"
              type="text"
              @click="handleInitiateScreen(row.toolProductId)"
            >
              <el-tooltip
                content="发起筛选"
                placement="top"
              >
                <i
                  class="el-icon-upload2"
                  style="font-size: 16px"
                ></i>
              </el-tooltip>
            </el-button>

          </template>
        </el-table-column>
      </el-table>

      <div class="page">
        <paginations
          :total="list.total"
          :page.sync="list.pageNum"
          :page-sizes="[5, 10, 15, 20]"
          :limit.sync="list.pageSize"
          @pagination="getPage"
        />
      </div>
    </div>

    <!-- 筛选设置 -->
    <filter-setting-dialog
      ref="filterSetting"
      @refresh="handleRefresh"
    ></filter-setting-dialog>

    <!-- 新增产品 -->
    <add-product-dialog
      ref="addProduct"
      @confirm="getPage"
    ></add-product-dialog>

    <!-- 产品评价 -->
    <product-evaluation-dialog
      ref="productEvaluation"
      @confirm="getPage"
    />

    <!-- 付费卡点 -->
    <pay-card-point-dialog
      ref="payCardPoint"
      @confirm="getPage"
    />

    <!-- 测试详情 -->
    <test-details-dialog
      ref="testDetails"
      @confirm="getPage"
    />

    <!-- 分配投手 -->
    <test-pitcher-dialog
      ref="testPitcher"
      @confirm="getPage"
    />

    <!-- 分配素材 -->
    <allocate-materials-dialog
      ref="allocateMaterials"
      @confirm="getPage"
    />

    <!-- 发起筛选 -->
    <product-screen-vote-dialog
      ref="productScreenVote"
      @confirm="getPage"
    />
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import AddProductDialog from './components/AddProductDialog'
import FilterSettingDialog from './components/FilterSettingDialog'
import ProductEvaluationDialog from './components/ProductEvaluationDialog'
import PayCardPointDialog from './components/PayCardPointDialog'
import TestDetailsDialog from './components/TestDetailsDialog'
import TestPitcherDialog from './components/TestPitcherDialog'
import AllocateMaterialsDialog from './components/AllocateMaterialsDialog'
import ProductScreenVoteDialog from './components/ProductScreenVoteDialog'

import { productLibraryList, deleteProduct } from '@/network/api/toolManagement/api-product-test'

export default {
  components: {
    'add-product-dialog': AddProductDialog,
    'filter-setting-dialog': FilterSettingDialog,
    'product-evaluation-dialog': ProductEvaluationDialog,
    'pay-card-point-dialog': PayCardPointDialog,
    'test-details-dialog': TestDetailsDialog,
    'test-pitcher-dialog': TestPitcherDialog,
    'allocate-materials-dialog': AllocateMaterialsDialog,
    'product-screen-vote-dialog': ProductScreenVoteDialog
  },
  filters: {
    filterToolType(value) {
      const types = { '1': '全部', '2': '男频', '3': '女频', '4': '男女通用' }
      return types[value] || ''
    },
    filterToolProductTestStatus(value) {
      const types = { '2': '未测试', '3': '待测试', '4': '测试中', '5': '已测试' }
      return types[value] || ''
    },
    filterToolProductFilterStatus(value) {
      const types = { '2': '未筛选', '3': '筛选中', '4': '未通过', '5': '已通过', '6': '已取消' }
      return types[value] || ''
    }
  },
  data() {
    return {
      search: {
        toolProductName: '',
        toolProductPlatform: 1,
        type: 1,
        createTime: [this.getNowTime(new Date() - 86400000 * 30), this.getNowTime(new Date())],
        allocationTime: [],
        toolProductTestStatus: 1,
        toolProductFilterStatus: 1,
        launchDivide: 1,
        assetDivide: 1
      },

      list: {
        loading: true,
        data: [],
        pageSize: 10,
        pageNum: 1,
        total: 0
      },

      platformList: [],
      typeList: [{ id: 2, name: '男频' }, { id: 3, name: '女频' }, { id: 4, name: '男女通用' }],
      filterStatusList: [{ id: 2, name: '未筛选' }, { id: 3, name: '筛选中' }, { id: 4, name: '未通过' }, { id: 5, name: '已通过' }, { id: 6, name: '已取消' }],
      testStatusList: [{ id: 2, name: '未测试' }, { id: 3, name: '待测试' }, { id: 4, name: '测试中' }, { id: 5, name: '已测试' }]
    }
  },

  mounted() {
    this.getDicts('return_platform_type').then((response) => {
      this.platformList = response.data
      this.getPage()
    })
  },

  methods: {
    // 列表
    getPage() {
      this.list.loading = true
      let search = {
        ...this.search,
        pageSize: this.list.pageSize,
        pageNum: this.list.pageNum
      }
      productLibraryList(search).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          this.list.data = records
          this.list.total = total
          this.list.loading = false
        }
      }).finally(e => {
        this.list.loading = false
      })
    },

    // 搜索
    onSubmit() {
      if (this.list.pageNum === 1) {
        this.getPage()
      } else {
        this.list.pageNum = 1
      }
    },

    // 重置
    resetForm() {
      this.$refs['search'].resetFields()
      this.getPage()
    },

    // 获取日期
    getNowTime(temp) {
      var now = new Date(temp)
      var year = now.getFullYear() 	// 得到年份
      var month = now.getMonth() + 1 // 得到月份
      var date = now.getDate() 		// 得到日期
      month = month.toString().padStart(2, '0')
      date = date.toString().padStart(2, '0')
      return `${year}-${month}-${date}`
    },

    // 更新数据
    handleRefresh() {
      this.getElementType()
    },

    // 点击筛选设置
    handleFilterSetting() {
      this.$refs.filterSetting.showModal()
    },

    // 点击复制名称
    handleClickCopy() {
      var clipboard = new Clipboard('.tool-product-name')
      clipboard.on('success', (e) => {
        this.$message.success('复制成功!')
        //  释放内存
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message.error('该浏览器不支持复制')
        // 释放内存
        clipboard.destroy()
      })
    },

    // 点击添加产品
    handleAddProduct(id) {
      this.$refs.addProduct.showModal(id)
    },

    // 点击发起筛选
    handleInitiateScreen(id) {
      this.$refs.productScreenVote.showModal(id)
    },

    // 点击产品评价
    handleProductEvaluation(id) {
      this.$refs.productEvaluation.showModal(id)
    },

    // 点击付费卡点
    handlePayCardPoint(id) {
      this.$refs.payCardPoint.showModal(id)
    },

    // 点击测试详情
    handleTestDetails(id, val) {
      this.$refs.testDetails.showModal(id, val)
    },

    // 点击测试投手
    handleTestPitcher(id) {
      this.$refs.testPitcher.showModal(id)
    },

    // 点击分配素材
    handleAllocateMaterials(id) {
      this.$refs.allocateMaterials.showModal(id)
    },

    // 点击删除
    handleDeleteElement(id) {
      this.$confirm('删除后所有关联投测数据都将一并删除！', '删除产品', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { code } = await deleteProduct(id)
        if (code === 200) {
          this.getPage()
          this.$message.success('删除成功!')
        }
      }).catch(() => {
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.element-img {
  width: 60px;
  height: 60px;
  text-align: center;
  margin: auto;
  padding-top: 12px;

  video {
    width: 100%;
    height: 100%;
  }
}

.element-pop-img {
  max-width: 260px;

  video {
    width: 100%;
    height: 100%;
  }
}

.tool-product-name {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tool-user-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  color: $themeColor;
  cursor: pointer;
}

.red {
  color: #ff0000;
}
</style>
