var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "creativity-material" },
      [
        _vm.materials.length
          ? _c(
              "div",
              _vm._l(_vm.materials, function (item, index) {
                return _c("div", { key: index, staticClass: "img-list" }, [
                  _c("p", { staticClass: "text-name" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                  _c(
                    "div",
                    { staticClass: "text-box" },
                    _vm._l(item.titles, function (title, i) {
                      return _c(
                        "el-tooltip",
                        {
                          key: i,
                          attrs: {
                            content: title.descriptionContent,
                            placement: "top",
                          },
                        },
                        [
                          _c("div", { staticClass: "text-item" }, [
                            _vm._v(_vm._s(title.descriptionContent)),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "img-box" },
                    _vm._l(item.list, function (itm, idx) {
                      return _c(
                        "div",
                        { key: idx, staticClass: "material-item-group" },
                        _vm._l(itm, function (v, i) {
                          return _c("div", { key: i }, [
                            v.assetLocalUrl
                              ? _c("div", { staticClass: "material-item" }, [
                                  _vm.checkIsVideoType(v.assetLocalUrl)
                                    ? _c("video", {
                                        attrs: { src: v.assetLocalUrl },
                                      })
                                    : _c("img", {
                                        staticClass: "avatar",
                                        attrs: { src: v.assetLocalUrl },
                                      }),
                                ])
                              : _vm._e(),
                          ])
                        }),
                        0
                      )
                    }),
                    0
                  ),
                ])
              }),
              0
            )
          : _c(
              "div",
              {
                staticStyle: {
                  "text-align": "center",
                  "padding-top": "20px",
                  color: "#909399",
                },
              },
              [_vm._v("暂无数据")]
            ),
        _c(
          "el-dialog",
          {
            attrs: {
              "custom-class": "dialog-item",
              title: "素材组合编辑",
              visible: _vm.materialVisible,
              width: "880px",
              "append-to-body": true,
              "destroy-on-close": "",
            },
            on: { close: _vm.handleClose },
          },
          [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
              },
              [
                _c("div", { staticClass: "justify-start" }, [
                  _c("h3", [_vm._v("素材组合")]),
                  _c("p", { staticClass: "ml-20" }, [
                    _vm._v(
                      "素材组数量：" +
                        _vm._s(_vm.creativityMaterialList.length) +
                        "组"
                    ),
                  ]),
                ]),
                !_vm.isImage && _vm.taskSiteSet !== 1
                  ? _c(
                      "div",
                      {
                        staticClass: "justify-start",
                        staticStyle: { margin: "20px 0" },
                      },
                      [
                        _c("p", { staticClass: "mr-20" }, [
                          _vm._v("自动生成封面"),
                        ]),
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.generateCover,
                              callback: function ($$v) {
                                _vm.generateCover = $$v
                              },
                              expression: "generateCover",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 0 } }, [
                              _vm._v("不开启"),
                            ]),
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("开启"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-collapse",
                  {
                    model: {
                      value: _vm.collapse,
                      callback: function ($$v) {
                        _vm.collapse = $$v
                      },
                      expression: "collapse",
                    },
                  },
                  _vm._l(_vm.creativityMaterialList, function (item, index) {
                    return _c(
                      "el-collapse-item",
                      {
                        key: index,
                        attrs: {
                          name: item.name || _vm.computedMaterialName(index),
                        },
                      },
                      [
                        _c("template", { slot: "title" }, [
                          _c("div", { staticClass: "justify-sb group-title" }, [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  item.name || _vm.computedMaterialName(index)
                                )
                              ),
                            ]),
                            _c("div", { staticClass: "icon" }, [
                              _c("i", {
                                staticClass: "el-icon-document-copy",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.copyCreativeGroup(item, index)
                                  },
                                },
                              }),
                              _vm.creativityMaterialList.length > 1
                                ? _c("i", {
                                    staticClass: "el-icon-close",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.deleteCreativeGroup(
                                          item,
                                          index
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "title-box" },
                          [
                            _c("div", { staticClass: "title-count" }, [
                              _vm._v(
                                _vm._s(item.titles.length) +
                                  "/" +
                                  _vm._s(_vm.TITLE_LIMIT_NUM)
                              ),
                            ]),
                            _c(
                              "el-form",
                              {
                                ref: "titleForm",
                                refInFor: true,
                                attrs: {
                                  "label-width": "80px",
                                  "label-position": "left",
                                },
                              },
                              _vm._l(item.titles, function (title, idx) {
                                return _c(
                                  "el-form-item",
                                  { key: idx, staticStyle: { width: "100%" } },
                                  [
                                    _c("template", { slot: "label" }, [
                                      _c("div", [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#F56C6C" } },
                                          [_vm._v("*")]
                                        ),
                                        _vm._v(" 文案 " + _vm._s(idx + 1)),
                                      ]),
                                    ]),
                                    _vm.taskSiteSet === 1 ||
                                    _vm.taskSiteSet === 6
                                      ? _c("el-input", {
                                          staticStyle: { width: "400px" },
                                          attrs: {
                                            type: "textarea",
                                            rows: 4,
                                            resize: "none",
                                            placeholder:
                                              "请输入文案, 支持换行输入, 最多4行",
                                          },
                                          model: {
                                            value:
                                              item.titles[idx]
                                                .descriptionContent,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item.titles[idx],
                                                "descriptionContent",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.titles[idx].descriptionContent",
                                          },
                                        })
                                      : _c("el-input", {
                                          staticStyle: { width: "400px" },
                                          attrs: {
                                            maxlength: "30",
                                            "show-word-limit": "",
                                            placeholder: "请输入文案",
                                          },
                                          model: {
                                            value:
                                              item.titles[idx]
                                                .descriptionContent,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item.titles[idx],
                                                "descriptionContent",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.titles[idx].descriptionContent",
                                          },
                                        }),
                                    _c("span", { staticClass: "title-opt" }, [
                                      item.titles.length < _vm.TITLE_LIMIT_NUM
                                        ? _c("i", {
                                            staticClass:
                                              "el-icon-circle-plus-outline",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleAddTitle(
                                                  index,
                                                  idx
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      idx > 0
                                        ? _c("i", {
                                            staticClass:
                                              "el-icon-remove-outline",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleDeleteTitle(
                                                  index,
                                                  idx
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ]),
                                  ],
                                  2
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "creative-group-body" }, [
                          _c("div", { staticClass: "creative-tabs" }, [
                            _vm.isImage
                              ? _c("div", { staticClass: "tag active" }, [
                                  _vm._v(
                                    "图片(" +
                                      _vm._s(item.list.length * _vm.imageSize) +
                                      "/" +
                                      _vm._s(
                                        _vm.imageSize * _vm.MATERIAL_LIMIT_NUM
                                      ) +
                                      ") "
                                  ),
                                ])
                              : _c("div", { staticClass: "tag active" }, [
                                  _vm._v(
                                    "视频(" +
                                      _vm._s(item.list.length) +
                                      "/" +
                                      _vm._s(_vm.MATERIAL_LIMIT_NUM) +
                                      ")"
                                  ),
                                ]),
                          ]),
                          _vm.isImage
                            ? _c(
                                "div",
                                { staticClass: "creative-image-material" },
                                [
                                  _vm._l(item.list, function (itm, idx) {
                                    return _c(
                                      "div",
                                      {
                                        key: idx,
                                        staticClass:
                                          "creative-image-material-group",
                                      },
                                      [
                                        _vm._l(itm, function (v, i) {
                                          return _c(
                                            "div",
                                            {
                                              key: i,
                                              staticClass:
                                                "creative-image-material-item",
                                            },
                                            [
                                              v.assetLocalUrl
                                                ? _c("img", {
                                                    attrs: {
                                                      src: v.assetLocalUrl,
                                                      alt: "",
                                                    },
                                                  })
                                                : _c(
                                                    "div",
                                                    { staticClass: "avatar" },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-picture-outline",
                                                      }),
                                                    ]
                                                  ),
                                              _c(
                                                "div",
                                                { staticClass: "avatar-mask" },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleUploadImage(
                                                            index,
                                                            idx,
                                                            i,
                                                            "image"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("上传图片")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }),
                                        _c("i", {
                                          staticClass:
                                            "icon-close el-icon-circle-close",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.handleDeleteMaterial(
                                                index,
                                                idx
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      2
                                    )
                                  }),
                                  item.list.length < _vm.MATERIAL_LIMIT_NUM
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "creative-image-material-item-add",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleAddMaterial(
                                                  index,
                                                  true
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("+")]
                                        ),
                                        _vm.uploadInfo.length
                                          ? _c("div", { staticClass: "info" }, [
                                              _c("p", [
                                                _vm._v(
                                                  "尺寸：" +
                                                    _vm._s(
                                                      _vm.uploadInfo[0]
                                                        .assetLocalResizeWidth
                                                    ) +
                                                    "px * " +
                                                    _vm._s(
                                                      _vm.uploadInfo[0]
                                                        .assetLocalResizeHeight
                                                    ) +
                                                    "px(推荐) "
                                                ),
                                              ]),
                                              _c("p", [
                                                _vm._v(
                                                  "格式：JPG、JPEG、PNG格式"
                                                ),
                                              ]),
                                              _c("p", [
                                                _vm._v(
                                                  "大小：" +
                                                    _vm._s(
                                                      _vm.uploadInfo[0]
                                                        .assetLocalResizeFileSize
                                                    ) +
                                                    " KB 以内"
                                                ),
                                              ]),
                                            ])
                                          : _vm._e(),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              )
                            : _c(
                                "div",
                                { staticClass: "creative-video-material" },
                                [
                                  _vm._l(item.list, function (itm, idx) {
                                    return _c(
                                      "div",
                                      {
                                        key: idx,
                                        staticClass:
                                          "creative-video-material-group",
                                        style: {
                                          width:
                                            !_vm.isImage &&
                                            _vm.taskSiteSet !== 1
                                              ? "48%"
                                              : "auto",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "creative-video-material-item",
                                          },
                                          [
                                            itm[0].assetLocalUrl
                                              ? _c("video", {
                                                  attrs: {
                                                    src: itm[0].assetLocalUrl,
                                                    controls: "",
                                                  },
                                                })
                                              : _c(
                                                  "div",
                                                  { staticClass: "avatar" },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-video-camera",
                                                    }),
                                                  ]
                                                ),
                                            _c(
                                              "div",
                                              { staticClass: "avatar-mask" },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleUploadVideo(
                                                          index,
                                                          idx,
                                                          0
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("上传视频")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        itm.length > 1 &&
                                        (_vm.generateCover === 0 ||
                                          itm[0].assetLocalUrl)
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "creative-video-material-item",
                                              },
                                              [
                                                itm[1].assetLocalUrl
                                                  ? [
                                                      _vm.checkIsVideoType(
                                                        itm[1].assetLocalUrl
                                                      )
                                                        ? _c("video", {
                                                            staticClass:
                                                              "video-avatar",
                                                            attrs: {
                                                              src: itm[1]
                                                                .assetLocalUrl,
                                                            },
                                                          })
                                                        : _c("el-image", {
                                                            attrs: {
                                                              src: itm[1]
                                                                .assetLocalUrl,
                                                              fit: "contain",
                                                            },
                                                          }),
                                                    ]
                                                  : _c(
                                                      "div",
                                                      { staticClass: "avatar" },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-plus avatar-uploader-icon",
                                                        }),
                                                      ]
                                                    ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "avatar-mask",
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleUploadImage(
                                                              index,
                                                              idx,
                                                              1,
                                                              "video"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("上传封面")]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                        _c("i", {
                                          staticClass:
                                            "icon-close el-icon-circle-close",
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.handleDeleteMaterial(
                                                index,
                                                idx
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    )
                                  }),
                                  item.list.length < _vm.MATERIAL_LIMIT_NUM
                                    ? _c(
                                        "div",
                                        {
                                          style: {
                                            width:
                                              !_vm.isImage &&
                                              _vm.taskSiteSet !== 1
                                                ? "100%"
                                                : "auto",
                                            display: "flex",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "creative-video-material-item-add",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleAddMaterial(
                                                    index,
                                                    true
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("+")]
                                          ),
                                          _vm.uploadInfo.length
                                            ? _c(
                                                "div",
                                                { staticClass: "info" },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      "视频尺寸：" +
                                                        _vm._s(
                                                          _vm.uploadInfo[0]
                                                            .assetLocalResizeWidth
                                                        ) +
                                                        "px * " +
                                                        _vm._s(
                                                          _vm.uploadInfo[0]
                                                            .assetLocalResizeHeight
                                                        ) +
                                                        "px "
                                                    ),
                                                  ]),
                                                  _c("p", [
                                                    _vm._v(
                                                      "格式：MP4、MOV、AVI格式"
                                                    ),
                                                  ]),
                                                  _c("p", [
                                                    _vm._v("大小：100 MB 以内"),
                                                  ]),
                                                  _c("p", [
                                                    _vm._v(
                                                      "播放时长: 5s - 30s"
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.uploadInfo.length > 1
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "info",
                                                  staticStyle: {
                                                    "margin-left": "20px",
                                                  },
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      "封面尺寸：" +
                                                        _vm._s(
                                                          _vm.uploadInfo[1]
                                                            .assetLocalResizeWidth
                                                        ) +
                                                        "px * " +
                                                        _vm._s(
                                                          _vm.uploadInfo[1]
                                                            .assetLocalResizeHeight
                                                        ) +
                                                        "px(推荐) "
                                                    ),
                                                  ]),
                                                  _c("p", [
                                                    _vm._v(
                                                      "格式：JPG、JPEG、PNG格式"
                                                    ),
                                                  ]),
                                                  _c("p", [
                                                    _vm._v(
                                                      "大小：" +
                                                        _vm._s(
                                                          _vm.uploadInfo[1]
                                                            .assetLocalResizeFileSize
                                                        ) +
                                                        " KB 以内"
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                        ]),
                      ],
                      2
                    )
                  }),
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "text-align": "center",
                      "margin-top": "10px",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.addCreativeGroup },
                      },
                      [_vm._v("添加素材组")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c("el-button", { on: { click: _vm.handleClose } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.loading },
                    on: { click: _vm.onSubmit },
                  },
                  [_vm._v("确 定")]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _vm.materialVisible
      ? _c(
          "div",
          [
            _c("material-box", {
              attrs: {
                visible: _vm.materialBoxVisible,
                "img-count": _vm.imgCount,
                name: "creativeGroup",
                "image-list": [],
                attribute: {
                  key: "creativeGroup",
                  listType: "image/jpg,image/jpeg,image/png",
                  ..._vm.fileAttribute,
                },
                "project-id": [+_vm.projectId],
                "is-batch": false,
              },
              on: {
                setMaterialBox: function ($event) {
                  _vm.materialBoxVisible = false
                },
                submit: _vm.selectImg,
              },
            }),
            _c("material-video-box", {
              attrs: {
                visible: _vm.materialVideoVisible,
                "img-count": _vm.imgCount,
                name: "videoUrl",
                "video-list": [],
                attribute: {
                  key: "videoHorizontal",
                  listType: "video/mp4",
                  ..._vm.fileAttribute,
                },
                "project-id": [+_vm.projectId],
                "is-batch": false,
              },
              on: {
                setMaterialBox: function ($event) {
                  _vm.materialVideoVisible = false
                },
                handleVideoSuccess: _vm.selectVideo,
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }