<!--
 * @Description: Do not edit
 * @Date: 2021-12-03 09:41:49
 * @LastEditTime: 2021-12-20 11:49:09
-->
<template>
  <div class="module-box">
    <div class="title">
      <p>
        {{ title }} <span class="tip ml-10">{{ desc }}</span>
      </p>
    </div>
    <div class="module">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
.module-box {
  width: auto;
  .title {
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 600;
    p {
      border-left: 3px solid #409eff;
      padding-left: 5px;
      margin: 0;
    }
  }
  .module {
    margin-top: 10px;
  }
}
</style>
