var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form-item",
        { attrs: { label: "创意样式：", prop: "crtSize" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.ruleFormData.crtSize,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleFormData, "crtSize", $$v)
                },
                expression: "ruleFormData.crtSize",
              },
            },
            [
              _c("el-radio", { attrs: { label: 556 } }, [
                _vm._v("图片名片式banner"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "外层素材跳转", prop: "pageType" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.ruleFormData.pageType,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleFormData, "pageType", $$v)
                },
                expression: "ruleFormData.pageType",
              },
            },
            [
              _c("el-radio", { attrs: { label: 4 } }, [_vm._v("原生推广页")]),
              _c("el-radio", { attrs: { label: 6 } }, [_vm._v("公众号详情页")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "点击按钮跳转：", prop: "buttonPageType" } },
        [
          _c(
            "p",
            {
              staticClass: "tip reset-p",
              staticStyle: { "margin-top": "6px" },
            },
            [_vm._v("弹窗提示用户关注")]
          ),
        ]
      ),
      _c(
        "el-form-item",
        {
          attrs: {
            label: "创意名称：",
            prop: "tname",
            rules: { required: true, message: "请输入创意名称", maxlength: 60 },
          },
        },
        [
          _c("el-input", {
            staticStyle: { width: "400px" },
            attrs: {
              rows: "4",
              type: "textarea",
              placeholder: "请输入创意名称",
              maxlength: "60",
              "show-word-limit": "",
            },
            model: {
              value: _vm.ruleFormData.tname,
              callback: function ($$v) {
                _vm.$set(_vm.ruleFormData, "tname", $$v)
              },
              expression: "ruleFormData.tname",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }