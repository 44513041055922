<template>
  <el-dialog
    :title="isEdit ? '编 辑': '新 增'"
    width="580px"
    :visible.sync="visible"
    :before-close="close"
    label-position="right"
    :modal-append-to-body="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <el-form ref="ent" :model="ent" :rules="rules" label-width="110px">

      <el-form-item v-if="type==='type'" label="产品品类" prop="productTypeId">
        <el-select
          v-model="ent.productTypeId"
          filterable
          default-first-option
          placeholder="请选择"
          @change="productType"
        >
          <el-option
            v-for="item in typeList"
            :key="item.bsProductTypeId"
            :label="item.typeName"
            :value="item.bsProductTypeId"
          />
        </el-select>
        <el-button class="ml-10" type="text" @click="typeAdd"><u>新增品类</u></el-button>
        <!-- <el-input v-model="ent.productTypeId" /> -->
      </el-form-item>

      <el-form-item v-if="type==='project'" label="关联项目" prop="relationProjectIdNew">
        <el-select
          v-model="ent.relationProjectIdNew"
          filterable
          multiple
          collapse-tags
          default-first-option
          placeholder="请选择"
          @change="projectChange"
        >
          <el-option
            v-for="item in projectList"
            :key="item.bsProjectId"
            :label="item.projectName"
            :value="item.bsProjectId"
          />
        </el-select>
        <el-button class="ml-10" type="text" @click="projectAdd"><u>新增项目</u></el-button>
      </el-form-item>

      <el-form-item v-if="type==='source' || type==='project'" label="产品来源" prop="productSourceId">
        <el-select
          v-model="ent.productSourceId"
          filterable
          default-first-option
          placeholder="请选择"
          @change="sourceChange"
        >
          <el-option
            v-for="item in sourceList"
            :key="item.bsProductSourceId"
            :label="item.source"
            :value="item.bsProductSourceId"
          />
        </el-select>
        <el-button class="ml-10" type="text" @click="sourceAdd"><u>新增来源</u></el-button>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button round @click="close">取消</el-button>
      <el-popconfirm class="ml-10" confirm-button-text="提交" cancel-button-text="取消" icon="el-icon-info" icon-color="red" title="确定提交？" @confirm="submit">
        <el-button slot="reference" type="primary" round>确定</el-button>
      </el-popconfirm>
    </span>
  </el-dialog>
</template>
<script>
import {
  Add,
  updateBatch
} from '@/network/api/basicSetting/product/basicSettingProduct'
import { BasicSettingProductEnt, formRules, isUseingList } from './object'
import { getProjectForProduct } from '@/network/api/basicSetting/project/basicSettingProject'
import { getAllIsUseing } from '@/network/api/basicSetting/product/basicSettingProductType'

export default {
  inject: ['loadData', 'showAddProject', 'showAddType', 'showAddSource'],
  data() {
    return {
      ent: new BasicSettingProductEnt(),
      rules: formRules,
      type: '',
      isEdit: false,
      visible: false,
      isUseingList: isUseingList,
      ids: [],
      cityList: [],
      typeList: [],
      projectList: [],
      sourceList: [],
      objSoucer: {}
    }
  },

  methods: {
    projectAdd() {
      this.close()
      this.showAddProject()
    },
    typeAdd() {
      this.close()
      this.showAddType()
    },
    sourceAdd() {
      this.close()
      this.showAddSource()
    },
    productType(val) {
      this.typeList.forEach(item => {
        if (item.bsProductTypeId === val) {
          this.ent.productTypeName = item.typeName
        }
      })
    },
    projectChange(val) {
      // this.sourceList = []
      // this.ent.productSourceId = undefined
      // this.ent.productSourceName = undefined
      // if (this.objSoucer[val] !== undefined) {
      //   this.sourceList = this.objSoucer[val]
      // }
      // this.projectList.forEach(item => {
      //   if (item.bsProjectId === val) {
      //     this.ent.relationProjectName = item.projectName
      //   }
      // })
    },
    sourceChange(val) {
      this.sourceList.forEach(item => {
        if (item.bsProductSourceId === val) {
          this.ent.productSourceName = item.source
        }
      })
    },

    getType() {
      getAllIsUseing().then(res => {
        if (res.code === 200) {
          this.typeList = res.data
          console.log(this.typeList)
        }
      })
    },
    getProject() {
      getProjectForProduct().then(res => {
        if (res.code === 200) {
          const data = res.data
          if (undefined !== data.PROJECT) {
            this.projectList = data.PROJECT
            console.log(this.projectList)
          }
          if (undefined !== data.SOURCE) {
            this.sourceList = data.SOURCE
            this.objSoucer = data.SOURCE
            // console.log(this.objSoucer)
          }
        }
      })
    },
    submit() {
      this.$refs.ent.validate((validate) => {
        if (validate) {
          this.updateRow()
        }
      })
    },
    addRow() {
      const req = { ...this.ent }
      Add(req).then(res => {
        this.$message({
          message: '保存成功',
          type: 'success'
        })
        this.loadData()
        this.close()
      })
    },
    updateRow() {
      const req = { ...this.ent }
      req.relationProjectIdNew = JSON.stringify(req.relationProjectIdNew)
      req.ids = this.ids.toString()
      req.type = this.type
      updateBatch(req).then(res => {
        this.$message({
          message: '修改成功',
          type: 'success'
        })
        this.loadData()
        this.close()
      })
    },
    close() {
      this.visible = false
      this.$nextTick(() => {
        this.$refs.ent.resetFields()
      })
    },
    shows(isEdit = false, ent) {
      if (!isEdit) {
        ent.isUseing = 'ISUSEING'
      }
      this.ent = ent
      this.isEdit = isEdit
      this.visible = true
      this.$nextTick(() => {
        if (this.isEdit) {
          console.log('不能为空')
        }
      })
    },

    show(type, ids, projectid) {
      this.visible = true
      if (undefined !== projectid) {
        this.projectChange(projectid)
      }
      if (type === 'type') {
        this.getType()
      } else {
        this.getProject()
      }
      this.ids = ids
      this.type = type
    }
  }
}
</script>
