var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { inline: true, model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "Base",
            { attrs: { title: "基础信息" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "任务名称", prop: "taskName" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "300px" },
                            attrs: {
                              placeholder: "请输入任务名称",
                              maxlength: "30",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.form.taskName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "taskName", $$v)
                              },
                              expression: "form.taskName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "执行周期", prop: "isLoop" } },
                        [
                          _c(
                            "el-select",
                            {
                              style: {
                                width: _vm.form.isLoop ? "120px" : "300px",
                              },
                              attrs: { placeholder: "请选择执行周期" },
                              on: { change: _vm.handleChangeCycleType },
                              model: {
                                value: _vm.form.isLoop,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "isLoop", $$v)
                                },
                                expression: "form.isLoop",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "单次", value: false },
                              }),
                              _c("el-option", {
                                attrs: { label: "循环", value: true },
                              }),
                            ],
                            1
                          ),
                          _vm.form.isLoop
                            ? _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    width: "165px",
                                    "margin-left": "15px",
                                  },
                                  attrs: { placeholder: "请选择循环周期" },
                                  on: { change: _vm.handleChangeLoop },
                                  model: {
                                    value: _vm.form.loopCycle,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "loopCycle", $$v)
                                    },
                                    expression: "form.loopCycle",
                                  },
                                },
                                _vm._l(_vm.loopCycles, function (item, index) {
                                  return _c(
                                    "el-option",
                                    _vm._b(
                                      { key: index },
                                      "el-option",
                                      item,
                                      false
                                    )
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "投放账户", prop: "accounts" } },
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: { "min-width": "800px" },
                              attrs: { stripe: "", data: _vm.form.accounts },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "housekeeperName",
                                  label: "管理账户",
                                  width: "300",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "accountName",
                                  label: "账户名称",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "accountId",
                                  label: "账户ID",
                                  width: "160",
                                },
                              }),
                              _c(
                                "el-table-column",
                                {
                                  attrs: { align: "right", width: "80" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                round: "",
                                                size: "mini",
                                                type: "danger",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeAdvAccount(
                                                    scope.$index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-remove-outline",
                                              }),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                },
                                [
                                  _c(
                                    "template",
                                    { slot: "header" },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            round: "",
                                            size: "mini",
                                            type: "primary",
                                          },
                                          on: {
                                            click: _vm.showAdvAccountDialog,
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-circle-plus-outline",
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Base",
            { attrs: { title: "数据信息" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "数据类型", prop: "dataCondition" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "300px" },
                              attrs: { placeholder: "请选择数据条件" },
                              model: {
                                value: _vm.form.dataCondition,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "dataCondition", $$v)
                                },
                                expression: "form.dataCondition",
                              },
                            },
                            _vm._l(
                              _vm.dataConditions.slice(1),
                              function (item, index) {
                                return _c(
                                  "el-option",
                                  _vm._b(
                                    { key: index },
                                    "el-option",
                                    item,
                                    false
                                  )
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 7 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "进粉时间", prop: "lifeCycle" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "picker-options": _vm.expireTimeOption,
                              type: "daterange",
                              "range-separator": "至",
                              "start-placeholder": "进粉开始时间",
                              "value-format": "yyyy-MM-dd",
                              "end-placeholder": "进粉结束时间",
                            },
                            model: {
                              value: _vm.form.lifeCycle,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "lifeCycle", $$v)
                              },
                              expression: "form.lifeCycle",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "性别拆分", prop: "whereGender" } },
                        [
                          _c(
                            "el-select",
                            {
                              style: {
                                width: _vm.form.whereGender ? "80px" : "300px",
                              },
                              attrs: { placeholder: "请选择性别拆分" },
                              model: {
                                value: _vm.form.whereGender,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "whereGender", $$v)
                                },
                                expression: "form.whereGender",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "拆分", value: true },
                              }),
                              _c("el-option", {
                                attrs: { label: "不拆分", value: false },
                              }),
                            ],
                            1
                          ),
                          _vm.form.whereGender
                            ? _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    width: "205px",
                                    "margin-left": "15px",
                                  },
                                  attrs: {
                                    multiple: "",
                                    placeholder: "请选择性别拆分",
                                  },
                                  model: {
                                    value: _vm.form.gender,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "gender", $$v)
                                    },
                                    expression: "form.gender",
                                  },
                                },
                                _vm._l(_vm.sexSelects, function (item, index) {
                                  return _c(
                                    "el-option",
                                    _vm._b(
                                      { key: index },
                                      "el-option",
                                      item,
                                      false
                                    )
                                  )
                                }),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "人群格式", prop: "fileFormat" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "300px" },
                              attrs: {
                                multiple: "",
                                placeholder: "请选择人群格式",
                              },
                              model: {
                                value: _vm.form.fileFormat,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "fileFormat", $$v)
                                },
                                expression: "form.fileFormat",
                              },
                            },
                            _vm._l(_vm.fileTypes, function (item, index) {
                              return _c(
                                "el-option",
                                _vm._b({ key: index }, "el-option", item, false)
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.form.dataCondition === 1 && _vm.form.isLoop
                    ? _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "留存用户修正",
                                prop: "keepUserCorrect",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "300px" },
                                  attrs: { placeholder: "请选择留存用户修正" },
                                  model: {
                                    value: _vm.form.keepUserCorrect,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "keepUserCorrect", $$v)
                                    },
                                    expression: "form.keepUserCorrect",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "是", value: true },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "否", value: false },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "指定企微", prop: "specifyWorkCorp" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: {
                                width: "100px",
                                "margin-right": "1em",
                              },
                              model: {
                                value: _vm.form.specifyWorkCorp,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "specifyWorkCorp", $$v)
                                },
                                expression: "form.specifyWorkCorp",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "是", value: true },
                              }),
                              _c("el-option", {
                                attrs: { label: "否", value: false },
                              }),
                            ],
                            1
                          ),
                          _c("span", { staticStyle: { color: "#888" } }, [
                            _vm._v("注：不指定则全量数据生成"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _vm.form.specifyWorkCorp
                    ? _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "指定企微主体ID",
                                prop: "corpIdList",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "400px" },
                                attrs: {
                                  type: "textarea",
                                  resize: "none",
                                  autosize: { minRows: 8, maxRows: 8 },
                                  placeholder: "请填写企微主体ID，换行区隔",
                                },
                                model: {
                                  value: _vm.form.corpIdList,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "corpIdList", $$v)
                                  },
                                  expression: "form.corpIdList",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "config-footer" },
            [
              _vm.buttonName
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: _vm.loading },
                      on: { click: _vm.handleConfirm },
                    },
                    [_vm._v(_vm._s(_vm.buttonName))]
                  )
                : _vm._e(),
              _c("el-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "投放账户添加",
            "modal-append-to-body": false,
            visible: _vm.showAdvertiserSearchDialog,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAdvertiserSearchDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.advForm,
                rules: _vm.advRules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "管理账号", prop: "keeper" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { filterable: "" },
                      on: { change: _vm.queryAdvertisers },
                      model: {
                        value: _vm.advForm.keeper,
                        callback: function ($$v) {
                          _vm.$set(_vm.advForm, "keeper", $$v)
                        },
                        expression: "advForm.keeper",
                      },
                    },
                    _vm._l(_vm.houseKeepers, function (i) {
                      return _c(
                        "el-option",
                        _vm._b({ key: i.value }, "el-option", i, false)
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "投放账户", prop: "advertisers" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { multiple: "", filterable: "" },
                      model: {
                        value: _vm.advForm.advertisers,
                        callback: function ($$v) {
                          _vm.$set(_vm.advForm, "advertisers", $$v)
                        },
                        expression: "advForm.advertisers",
                      },
                    },
                    _vm._l(_vm.advertisers, function (i) {
                      return _c(
                        "el-option",
                        _vm._b({ key: i.value }, "el-option", i, false)
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.addAdvAccount },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showAdvertiserSearchDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }