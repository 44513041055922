<!--
 * @Author: Zeng
 * @Date: 2023-02-21 17:49:56
 * @LastEditTime: 2024-02-29 11:25:35
-->
<template>
  <div>
    <el-form ref="form" :inline="true" :model="form" :rules="rules">
      <Base title="基础信息">
      <el-row :gutter="24">
        <el-col :span="6">
          <el-form-item label="任务名称" prop="taskName">
            <el-input v-model="form.taskName" placeholder="请输入任务名称" style="width: 300px;" maxlength="30" show-word-limit>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="DMP账户" prop="accountId">
            <el-select v-model="form.accountId" placeholder="请选择DMP账户" style="width: 300px;" filterable remote
              :remote-method="remoteMethod" :loading="loading" @change="changeAccount">
              <el-option v-for="(item, index) in AccountUin_Options" :key="index"
                :label="`${item.corporationName}-${item.accountId}`" :value="item.accountId"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="执行周期" prop="executionCycle">
            <el-select v-model="form.executionCycle" placeholder="请选择执行周期"
              :style="{ 'width': form.executionCycle === 2 ? '120px' : '300px' }" @change="handleChangeCycleType">
              <el-option label="单次" :value="1"></el-option>
              <el-option label="循环" :value="2"></el-option>
            </el-select>
            <!-- 【执行周期】选择“循环” -->
            <el-select v-if="form.executionCycle === 2" v-model="form.loopCycle" placeholder="请选择循环周期"
              style="width: 165px;margin-left: 15px;" @change="handleChangeLoop">
              <el-option v-for="(item, index) in loopCycles" :key="index" :label="item.value"
                :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      </Base>
      <Base title="数据信息">
      <el-row :gutter="24">
        <el-col :span="6">
          <el-form-item label="文件类型" prop="dataType">
            <el-select v-model="form.dataType" placeholder="请选择文件类型" style="width: 300px;" @change="handleChangeFileType">
              <el-option label="微信openid" :value="1"></el-option>
              <el-option label="企微unionid" :value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="数据条件" prop="dataCondition">
            <el-select v-model="form.dataCondition" placeholder="请选择数据条件" style="width: 300px;"
              @change="handleChangeDataCondition">
              <el-option v-for="(item, index) in dataConditions[form.dataType]" :key="index" :label="item.value"
                :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="数据时间" prop="lifeCycle">
            <el-date-picker v-model="form.lifeCycle" :picker-options="expireTimeOption" style="width: 300px"
              type="daterange" prange-separator="至" start-placeholder="首次执行数据开始时间" value-format="yyyy-MM-dd"
              end-placeholder="首次执行数据结束时间"> </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="6">
          <el-form-item label="数据关联" prop="dataRelationMode">
            <el-radio-group v-model="form.dataRelationMode">
              <el-radio :label="1">自动关联</el-radio>
              <el-radio v-if="form.dataType == 2" :label="2">人工指定</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="6">
          <el-form-item label="关联类型" prop="dataRelationType">
            <el-select v-model="form.dataRelationType" placeholder="请选择数据关联类型" style="width: 300px;">
              <el-option v-for="(item, index) in relationTypes[form.dataType]" :key="index" :label="item.value"
                :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="6">
                                                  <el-form-item label="分组" prop="group">
                                                    <el-select v-model="form.group" multiple placeholder="请选择数据关联类型" style="width: 300px;">
                                                                      <el-option label="全部" value=""></el-option>
                                                                      <el-option v-for="(item, index) in groups" :key="index" :label="item.value" :value="item.id"></el-option>
                                                                    </el-select>
                                                                  </el-form-item>
                                                                </el-col> -->
        <!-- 当【文件类型】选择“企微unionid”且【数据条件】选择“留存用户” 且【执行周期】选择“循环” -->
        <el-col v-if="form.dataType === 2 && form.dataCondition === 2 && form.executionCycle === 2" :span="6">
          <el-form-item label="留存用户修正" prop="keepUserCorrect">
            <el-select v-model="form.keepUserCorrect" placeholder="请选择留存用户修正" style="width: 300px;">
              <el-option label="是" :value="true"></el-option>
              <el-option label="否" :value="false"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <!-- 当【文件类型】选择“企微unionid”且【数据条件】选择“留存用户” 且【执行周期】选择“循环” -->
        <el-col :span="6" v-if="form.dataType === 2 && form.dataCondition === 2 && form.executionCycle === 2">
          <el-form-item label="性别拆分" prop="whereGender">
            <el-select v-model="form.whereGender" placeholder="请选择性别拆分"
              :style="{ width: form.whereGender == 1 ? '80px' : '300px' }">
              <el-option label="拆分" :value="1"></el-option>
              <el-option label="不拆分" :value="0"></el-option>
            </el-select>
            <!-- 【性别拆分】为”拆分“ -->
            <el-select v-if="form.whereGender == 1" v-model="form.gender" multiple placeholder="请选择性别拆分"
              style="width: 205px;margin-left: 15px;">
              <el-option label="男" value="1"></el-option>
              <el-option label="女" value="2"></el-option>
              <el-option label="未知" value="3"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!-- 当【文件类型】选择“微信openid”且【数据关联类型】选择“宙斯-短剧账号” -->
        <el-col :span="6" v-if="form.dataType === 1 && form.dataRelationType === 4">
          <el-form-item label="短剧小程序" prop="shortPlayApplet">
            <el-select v-model="form.shortPlayApplet" multiple placeholder="请选择短剧小程序" style="width: 300px;">
              <el-option v-for="(item, index) in applets" :key="index" :label="item.dictLabel + '-' + item.dictValue"
                :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="媒体平台" prop="platformId">
            <el-select v-model="form.platformId" placeholder="请选择媒体平台" style="width: 300px;">
              <el-option label="全部" value=" "></el-option>
              <el-option label="腾讯" value="腾讯"></el-option>
              <el-option label="巨量" value="巨量"></el-option>
              <el-option label="微信" value="微信"></el-option>
              <el-option label="快手" value="快手"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="10" v-if="form.dataType === 2 && form.dataRelationMode === 2">
          <el-form-item label="指定企微主体ID" prop="wxThemeId">
            <el-input v-model="form.wxThemeId" type="textarea" resize="none" style="width: 400px"
              :autosize="{ minRows: 8, maxRows: 8 }" placeholder="请填写企微主体ID，换行区隔" />
          </el-form-item>
        </el-col>
      </el-row>
      </Base>

      <div class="config-footer">
        <el-button type="primary" :disabled="loading" @click="handleCofirm">确定</el-button>
        <el-button @click="handleCancel">取 消</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import Base from '@/views/advManagement/baidu/baiduBatch/component/base.vue'
import { dataConditions, relationTypes, computeDuring } from './util'
import { saveCrowdPackTask, getSelectAccountUin, getViewCrowdPackDetail } from '@/network/api/assetManagement/assetManageTencent'
// import { getDicts } from '@/network/api/system/dict/data'
import { debounce } from '@/utils/ruoyi'
export default {
  components: {
    Base
  },
  data() {
    return {
      // 数据条件
      dataConditions,
      // 关联类型
      relationTypes,
      taskId: null,
      loading: false,
      form: {
        taskName: '', // 任务名称
        accountId: '', // DMP账户
        localAccountId: '', // 本地账户ID
        executionCycle: 1, // 执行周期
        loopCycle: 2, // 循环周期
        dataType: 1, // 文件类型
        dataCondition: '', // 数据条件
        lifeCycle: [], // 数据时间
        dataRelationMode: 1, // 数据关联
        dataRelationType: '', // 数据关联类型
        group: [], // 分组
        keepUserCorrect: true, // 留存用户修正
        shortPlayApplet: [], // 短剧小程序
        platformId: ' ', // 媒体平台
        whereGender: 0, // 性别拆分
        gender: [],
        wxThemeId: ''
      },
      rules: {
        taskName: [{ required: true, message: '请输入任务名称', trigger: 'blur' }],
        accountId: [{ required: true, message: '请选择DMP账户', trigger: 'change' }],
        executionCycle: [{ required: true, message: '请选择执行周期', trigger: 'change' }],
        dataType: [{ required: true, message: '请选择文件类型', trigger: 'change' }],
        dataCondition: [{ required: true, message: '请选择数据条件', trigger: 'change' }],
        lifeCycle: [{ required: true, message: '请选择数据时间', trigger: 'change' }],
        dataRelationMode: [{ required: true, message: '请选择数据关联', trigger: 'change' }],
        dataRelationType: [{ required: true, message: '请选择数据关联类型', trigger: 'change' }],
        group: [{ required: true, message: '请选择分组', trigger: 'change' }],
        keepUserCorrect: [{ required: true, message: '请选择留存用户修正', trigger: 'change' }],
        shortPlayApplet: [{ required: true, message: '请选择短剧小程序', trigger: 'change' }],
        platformId: [{ required: true, message: '请选择媒体平台', trigger: 'change' }],
        whereGender: [{ required: true, message: '请选择性别拆分', trigger: 'change' }],
        wxThemeId: [{ required: true, message: '请输入指定企微主体ID', trigger: 'blur' }]
      },
      expireTimeOption: {
        disabledDate(date) {
          return date.getTime() >= Date.now()
        }
      },
      // 循环周期
      loopCycles: [
        { id: 1, value: '每日' },
        { id: 2, value: '每7日' },
        { id: 3, value: '每30日' }
      ],
      // 分组
      groups: [],
      applets: [],
      remoteloading: false,
      AccountUin_Options: [] // 投放账户  请输入关键词查询
    }
  },

  created() {
    this.getDicts('short_play_applet').then(({ data }) => {
      this.applets = data
    })
    this.getById()
  },

  methods: {
    getById() {
      let { id } = this.$route.params
      if (!id) {
        this.handleCalculateDateRange()
        return
      }
      this.taskId = id || null
      getViewCrowdPackDetail({ adAdqAudienceTaskId: id }).then(({ code, data }) => {
        if (code === 200) {
          this.getSelectAccountUinFun(data.accountId)
          this.form = {
            taskName: data.taskName, // 任务名称
            accountId: data.accountId + '', // DMP账户
            localAccountId: data.localAccountId, // 本地账户ID
            executionCycle: +data.executionCycle, // 执行周期
            loopCycle: +data.loopCycle, // 循环周期
            dataType: +data.dataType, // 文件类型
            dataCondition: +data.dataCondition, // 数据条件
            lifeCycle: JSON.parse(data.lifeCycle), // 数据时间
            dataRelationMode: +data.dataRelationMode, // 数据关联
            dataRelationType: +data.dataRelationType, // 数据关联类型
            group: [], // 分组
            keepUserCorrect: data.keepUserCorrect, // 留存用户修正
            shortPlayApplet: JSON.parse(data.shortPlayApplet), // 短剧小程序
            platformId: data.platformId || ' ',
            whereGender: Number(data.whereGender) === 0 ? 0 : 1,
            gender: Number(data.whereGender) === 0 ? [] : data.whereGender.split(''),
            wxThemeId: data.wxThemeId || ''
          }
        }
      })
    },
    remoteMethod(query) {
      if (query !== '') {
        this.remoteloading = true
        this.getSelectAccountUinFun(query)
      } else {
        this.AccountUin_Options = []
      }
    },
    getSelectAccountUinFun: debounce(function (query) {
      getSelectAccountUin({ accountId: query }).then((res) => {
        if (res.code === 200) {
          this.remoteloading = false
          this.AccountUin_Options = res.data
        }
      })
    }),
    // 获取选中的投放账户
    changeAccount(value) {
      const account = this.AccountUin_Options.find((f) => f.accountId === value)
      this.form.localAccountId = account?.id
    },
    /**
     * @param {*} dateType
     * 0前一日 1当日 2前7日 3前30日
     */
    handleCalculateDateRange(dateType = 0) {
      let timeRange = []
      switch (dateType) {
        case 0:
          timeRange = computeDuring(1)
          break
        case 1:
          timeRange = [new Date(), new Date()]
          break
        case 2:
          timeRange = computeDuring(7)
          break
        case 3:
          timeRange = computeDuring(30)
          break
      }

      const startDate = this.parseTime(timeRange[0], '{y}-{m}-{d}')
      const endDate = this.parseTime(timeRange[1], '{y}-{m}-{d}')
      this.form.lifeCycle = [startDate, endDate]
    },

    // 切换执行周期类型
    handleChangeCycleType(type) {
      if (type === 1) {
        this.handleCalculateDateRange(0)
        this.form.loopCycle = 2
      } else {
        this.handleCalculateDateRange(this.form.loopCycle)
      }
    },

    // 切换循环周期
    handleChangeLoop(type) {
      this.handleCalculateDateRange(type)
    },

    // 切换文件类型
    handleChangeFileType() {
      this.form.dataCondition = ''
      this.form.dataRelationType = ''
      this.form.dataRelationMode = 1
    },

    handleChangeDataCondition(value) {
    },
    // 点击确定
    handleCofirm() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return
        }
        let whereGender = this.form.whereGender !== 0 ? this.form.gender.join('') : this.form.whereGender
        let params = {
          ...this.form,
          whereGender,
          lifeCycle: JSON.stringify(this.form.lifeCycle),
          shortPlayApplet: JSON.stringify(this.form.shortPlayApplet),
          platformId: this.form.platformId === ' ' ? '' : this.form.platformId
        }
        delete params.gender
        if (this.form.dataType === 1 || this.form.executionCycle === 1) {
          delete params.keepUserCorrect
          delete params.whereGender
        }
        if (this.form.dataCondition === 3) {
          // 流失用户
          delete params.keepUserCorrect
          delete params.whereGender
        }
        if (this.taskId) {
          params.adAdqAudienceTaskId = this.taskId
        }
        this.loading = true
        saveCrowdPackTask(params).then(({ code }) => {
          if (code === 200) {
            this.$message.success(this.taskId ? '更新成功！' : '添加成功！')
            this.$router.replace({
              name: 'tencentCrowdPackingTask'
            })
          }
          this.loading = false
        }).catch(e => {
          this.loading = false
        })
      })
    },

    // 点击取消
    handleCancel() {
      this.$router.replace({
        name: 'tencentCrowdPackingTask'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.config-footer {
  text-align: center;
}
</style>
