var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { inline: true, model: _vm.form, rules: _vm.rules },
        },
        [
          _c(
            "Base",
            { attrs: { title: "基础信息" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "任务名称", prop: "taskName" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "300px" },
                            attrs: {
                              placeholder: "请输入任务名称",
                              maxlength: "30",
                              "show-word-limit": "",
                            },
                            model: {
                              value: _vm.form.taskName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "taskName", $$v)
                              },
                              expression: "form.taskName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "DMP账户", prop: "accountId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "300px" },
                              attrs: {
                                placeholder: "请选择DMP账户",
                                filterable: "",
                                remote: "",
                                "remote-method": _vm.remoteMethod,
                                loading: _vm.loading,
                              },
                              on: { change: _vm.changeAccount },
                              model: {
                                value: _vm.form.accountId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "accountId", $$v)
                                },
                                expression: "form.accountId",
                              },
                            },
                            _vm._l(
                              _vm.AccountUin_Options,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: `${item.corporationName}-${item.accountId}`,
                                    value: item.accountId,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "执行周期", prop: "executionCycle" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              style: {
                                width:
                                  _vm.form.executionCycle === 2
                                    ? "120px"
                                    : "300px",
                              },
                              attrs: { placeholder: "请选择执行周期" },
                              on: { change: _vm.handleChangeCycleType },
                              model: {
                                value: _vm.form.executionCycle,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "executionCycle", $$v)
                                },
                                expression: "form.executionCycle",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "单次", value: 1 },
                              }),
                              _c("el-option", {
                                attrs: { label: "循环", value: 2 },
                              }),
                            ],
                            1
                          ),
                          _vm.form.executionCycle === 2
                            ? _c(
                                "el-select",
                                {
                                  staticStyle: {
                                    width: "165px",
                                    "margin-left": "15px",
                                  },
                                  attrs: { placeholder: "请选择循环周期" },
                                  on: { change: _vm.handleChangeLoop },
                                  model: {
                                    value: _vm.form.loopCycle,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "loopCycle", $$v)
                                    },
                                    expression: "form.loopCycle",
                                  },
                                },
                                _vm._l(_vm.loopCycles, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.value,
                                      value: item.id,
                                    },
                                  })
                                }),
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Base",
            { attrs: { title: "数据信息" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "文件类型", prop: "dataType" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "300px" },
                              attrs: { placeholder: "请选择文件类型" },
                              on: { change: _vm.handleChangeFileType },
                              model: {
                                value: _vm.form.dataType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "dataType", $$v)
                                },
                                expression: "form.dataType",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "微信openid", value: 1 },
                              }),
                              _c("el-option", {
                                attrs: { label: "企微unionid", value: 2 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "数据条件", prop: "dataCondition" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "300px" },
                              attrs: { placeholder: "请选择数据条件" },
                              on: { change: _vm.handleChangeDataCondition },
                              model: {
                                value: _vm.form.dataCondition,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "dataCondition", $$v)
                                },
                                expression: "form.dataCondition",
                              },
                            },
                            _vm._l(
                              _vm.dataConditions[_vm.form.dataType],
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.value, value: item.id },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "数据时间", prop: "lifeCycle" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "300px" },
                            attrs: {
                              "picker-options": _vm.expireTimeOption,
                              type: "daterange",
                              "prange-separator": "至",
                              "start-placeholder": "首次执行数据开始时间",
                              "value-format": "yyyy-MM-dd",
                              "end-placeholder": "首次执行数据结束时间",
                            },
                            model: {
                              value: _vm.form.lifeCycle,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "lifeCycle", $$v)
                              },
                              expression: "form.lifeCycle",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "数据关联",
                            prop: "dataRelationMode",
                          },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.form.dataRelationMode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "dataRelationMode", $$v)
                                },
                                expression: "form.dataRelationMode",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("自动关联"),
                              ]),
                              _vm.form.dataType == 2
                                ? _c("el-radio", { attrs: { label: 2 } }, [
                                    _vm._v("人工指定"),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "关联类型",
                            prop: "dataRelationType",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "300px" },
                              attrs: { placeholder: "请选择数据关联类型" },
                              model: {
                                value: _vm.form.dataRelationType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "dataRelationType", $$v)
                                },
                                expression: "form.dataRelationType",
                              },
                            },
                            _vm._l(
                              _vm.relationTypes[_vm.form.dataType],
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: { label: item.value, value: item.id },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.form.dataType === 2 &&
                  _vm.form.dataCondition === 2 &&
                  _vm.form.executionCycle === 2
                    ? _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "留存用户修正",
                                prop: "keepUserCorrect",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "300px" },
                                  attrs: { placeholder: "请选择留存用户修正" },
                                  model: {
                                    value: _vm.form.keepUserCorrect,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "keepUserCorrect", $$v)
                                    },
                                    expression: "form.keepUserCorrect",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "是", value: true },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "否", value: false },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _vm.form.dataType === 2 &&
                  _vm.form.dataCondition === 2 &&
                  _vm.form.executionCycle === 2
                    ? _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "性别拆分", prop: "whereGender" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  style: {
                                    width:
                                      _vm.form.whereGender == 1
                                        ? "80px"
                                        : "300px",
                                  },
                                  attrs: { placeholder: "请选择性别拆分" },
                                  model: {
                                    value: _vm.form.whereGender,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "whereGender", $$v)
                                    },
                                    expression: "form.whereGender",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "拆分", value: 1 },
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "不拆分", value: 0 },
                                  }),
                                ],
                                1
                              ),
                              _vm.form.whereGender == 1
                                ? _c(
                                    "el-select",
                                    {
                                      staticStyle: {
                                        width: "205px",
                                        "margin-left": "15px",
                                      },
                                      attrs: {
                                        multiple: "",
                                        placeholder: "请选择性别拆分",
                                      },
                                      model: {
                                        value: _vm.form.gender,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "gender", $$v)
                                        },
                                        expression: "form.gender",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "男", value: "1" },
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "女", value: "2" },
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "未知", value: "3" },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.dataType === 1 && _vm.form.dataRelationType === 4
                    ? _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "短剧小程序",
                                prop: "shortPlayApplet",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "300px" },
                                  attrs: {
                                    multiple: "",
                                    placeholder: "请选择短剧小程序",
                                  },
                                  model: {
                                    value: _vm.form.shortPlayApplet,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "shortPlayApplet", $$v)
                                    },
                                    expression: "form.shortPlayApplet",
                                  },
                                },
                                _vm._l(_vm.applets, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label:
                                        item.dictLabel + "-" + item.dictValue,
                                      value: item.dictValue,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "媒体平台", prop: "platformId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "300px" },
                              attrs: { placeholder: "请选择媒体平台" },
                              model: {
                                value: _vm.form.platformId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "platformId", $$v)
                                },
                                expression: "form.platformId",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "全部", value: " " },
                              }),
                              _c("el-option", {
                                attrs: { label: "腾讯", value: "腾讯" },
                              }),
                              _c("el-option", {
                                attrs: { label: "巨量", value: "巨量" },
                              }),
                              _c("el-option", {
                                attrs: { label: "微信", value: "微信" },
                              }),
                              _c("el-option", {
                                attrs: { label: "快手", value: "快手" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _vm.form.dataType === 2 && _vm.form.dataRelationMode === 2
                    ? _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "指定企微主体ID",
                                prop: "wxThemeId",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "400px" },
                                attrs: {
                                  type: "textarea",
                                  resize: "none",
                                  autosize: { minRows: 8, maxRows: 8 },
                                  placeholder: "请填写企微主体ID，换行区隔",
                                },
                                model: {
                                  value: _vm.form.wxThemeId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "wxThemeId", $$v)
                                  },
                                  expression: "form.wxThemeId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "config-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.loading },
                  on: { click: _vm.handleCofirm },
                },
                [_vm._v("确定")]
              ),
              _c("el-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }