<!--
第三方落地页
-->
<template>
  <div>
    <Search ref="search" :account-huge-housekeeper-e-n-ts="accountHugeHousekeeperENTs" :account-huge-adv-e-n-t-lists="accountHugeAdvENTLists" :account-huge-housekeeper-e-n-ts-id="accountHugeHousekeeperENTsId" />
    <el-row type="flex" justify="space-between" class="operate-box mt-20">
      <!-- <el-button type="primary" icon="el-icon-plus" round @click="showAdd">新 增</el-button> -->
      <el-button type="primary" icon="el-icon-orange" round @click="showSync">同步账户</el-button>
      <el-dialog title="同步账户" :visible.sync="isSync" width="500px" :modal-append-to-body="false">
        <span>
          <el-form :model="account" label-width="100px">
            <el-form-item label="管家账号" prop="accountId">
              <el-select v-model="account.accountId" style="width:300px" @change="AdvENTListchange">
                <el-option v-for="(item, index) in accountHugeHousekeeperENTs" :key="index" :label="item.accountName" :value="item.accountId"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="广告主账号" prop="GGZaccountId">
              <el-select v-model="account.GGZaccountId" filterable style="width:300px">
                <el-option v-for="(item, index) in accountHugeHousekeeperENTsId" :key="index" :label="item.accountName" :value="item.accountId"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="isSync = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible">同 步</el-button>
        </span>
      </el-dialog>
    </el-row>
    <div class="table-container">
      <el-table v-loading="loading" stripe class="list-table" :data="data">
        <c-data-empty slot="empty"></c-data-empty>
        <el-table-column type="index" label="序号" align="left" show-overflow-tooltip />
        <el-table-column prop="siteId" label="ID" align="left" show-overflow-tooltip />
        <el-table-column prop="pageName" label="名称" align="left" show-overflow-tooltip />
        <el-table-column prop="createBy" label="上传人" align="left" />
        <el-table-column prop="createdAt" label="上传时间" align="left" show-overflow-tooltip />
        <el-table-column prop="auditStatus" label="上传状态" align="left">
          <template slot-scope="{ row }"> {{ zd_status[row.auditStatus] }}</template>
        </el-table-column>
        <el-table-column prop="displayName" label="管家账号" align="left" show-overflow-tooltip />
        <el-table-column prop="campaignAccountName" label="投放账户" align="left" show-overflow-tooltip />
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button type="text" size="small" @click="setImageUrl(row)">预览 </el-button>
            <el-button type="text" class="tag-read" :data-clipboard-text="row.dataType === 1 ? `${row.domain}/${row.pageUrl}` : `${row.domain}${row.pageUrl}`" size="small" @click="copy">链接</el-button>
            <el-button v-if="row.auditStatus === '-1'" type="text" size="small" @click="reUploadFun(row)">重新上传</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="预览" :visible.sync="isImage" width="600px" :modal-append-to-body="false">
      <div class="is-image">
        <img style="width:350px" :src="imageUrl" />
      </div>
    </el-dialog>
    <c-pagination :page-size.sync="pageInfo.pageSize" :page-no.sync="pageInfo.pageNum" :total="pageInfo.total" :get-data-fun="loadData"> </c-pagination>
    <OptionDialog ref="dialog" />
  </div>
</template>

<script>
import { PageCommonComponent } from '@/common/mixin'
// Sync
import { Page, getProjectAndProduct, Sync, reUpload } from '@/network/api/assetManagement/assetManagementHugeThirdLandingPage'
import Search from './Search'
import Clipboard from 'clipboard'
// import { AssetManagementHugeThirdLandingPageEnt } from './object'
export default {
  name: 'AssetManagementHugeThirdLandingPage',
  components: { Search },
  mixins: [PageCommonComponent],
  provide: function() {
    return {
      loadData: this.loadData,
      showEdit: this.showEdi
    }
  },
  data() {
    return {
      loading: false,
      data: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      projectAndProduct: [],
      account: {
        accountId: '',
        GGZaccountId: ''
      },
      accountHugeHousekeeperENTs: [],
      accountHugeHousekeeperENTsId: [],
      accountHugeAdvENTLists: [],
      isSync: false,
      zd_status: [],
      isImage: false,
      imageUrl: ''
    }
  },
  mounted() {
    this.loadData()
    this.getProjectAndProductFun()
  },
  methods: {
    loadData(isFirst = false) {
      const searchForm = this.$refs.search.getSearchForm()
      this.doLoadData(isFirst, searchForm)
      this.getDicts('sys_zd_status').then((response) => {
        let zd_status = {}
        response.data.map((item) => {
          zd_status[item.dictValue] = item.dictLabel
        })
        this.zd_status = zd_status
      })
    },

    reUploadFun(row) {
      reUpload(row.thirdLandingPageId).then((res) => {
        if (res.code === 200) {
          this.$message.success('重新上传成功!')
        }
      })
    },

    copy() {
      var clipboard = new Clipboard('.tag-read')
      clipboard.on('success', (e) => {
        this.$message.success('复制成功!')
        //  释放内存
        clipboard.destroy()
      })
      clipboard.on('error', (e) => {
        // 不支持复制
        this.$message.error('该浏览器不支持复制')
        // 释放内存
        clipboard.destroy()
      })
    },

    setImageUrl(row) {
      this.imageUrl = row.dataType === 1 ? `${row.domain}/${row.pageUrl}` : `${row.domain}${row.pageUrl}`
      window.open(this.imageUrl, ' _top', 'width=770,height=900,menubar=no,toolbar=no, status=no,scrollbars=yes')
    },
    doLoadData(firstPage = false, searchForm) {
      firstPage && (this.pageInfo.pageNum = 1)
      let req = { ...searchForm, ...this.pageInfo }
      this.loading = true
      Page(req).then((res) => {
        this.data = res.data.records
        this.pageInfo.total = res.data.total
        this.loading = false
      })
    },
    // deleteRow(row) {
    //   this.$confirm('确定删除, 是否继续?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   })
    //     .then(() => {
    //       Delete(row.id).then((res) => {
    //         this.$message({
    //           message: '删除成功',
    //           type: 'success'
    //         })
    //         this.loadData()
    //       })
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: 'info',
    //         message: '已取消删除'
    //       })
    //     })
    // },
    showEdit(row) {
      this.$refs.dialog.show(true, { ...row })
    },
    showSync() {
      this.isSync = true
    },

    getProjectAndProductFun() {
      getProjectAndProduct().then(({ code, data }) => {
        if (code === 200) {
          this.projectAndProduct = data
          this.accountHugeHousekeeperENTs = data.map((item) => item.accountHugeHousekeeperENT)
          let accountHugeAdvENTLists = {}
          data.map((item, index) => {
            let key = item.accountHugeHousekeeperENT.accountId
            if (index === 0) {
              this.accountHugeHousekeeperENTsId = item.accountHugeAdvENTList
            }
            accountHugeAdvENTLists[key] = item.accountHugeAdvENTList
          })

          this.accountHugeAdvENTLists = accountHugeAdvENTLists
        }
      })
    },
    dialogVisible() {
      Sync(this.account).then(({ code }) => {
        if (code === 200) {
          this.isSync = false
          this.account = {
            accountId: '',
            GGZaccountId: ''
          }
          this.$message.success('同步成功!')
          this.loadData()
        }
      })
    },
    AdvENTListchange(value) {
      this.submitParams = {
        ...this.submitParams,
        GGZaccountId: null
      }
      this.accountHugeHousekeeperENTsId = this.accountHugeAdvENTLists[value]
    }
  }
}
</script>

<style scoped lang="scss">
.is-image {
  width: 100%;
  background: #f2f2f2;
  display: flex;
  justify-content: center;
}
</style>
