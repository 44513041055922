<!--  -->
<template>
  <el-dialog :title="'选择视频素材'" width="1200px" :visible.sync="visible" :before-close="close" label-position="right" :modal-append-to-body="false" :close-on-press-escape="false" :close-on-click-modal="false">
    <el-row>
      <el-col :span="24">
        <div>
          <el-form ref="searchForm" class="search-form search-form-4" :model="searchForm" :inline="true">
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item prop="albumId" label="专辑名称">
                  <el-select v-model="searchForm.albumId" placeholder="请选择" @change="alBumIdChange">
                    <el-option
                      v-for="item in albumList"
                      :key="item.albumId"
                      :label="item.albumName"
                      :value="item.albumId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="6">
                <el-form-item prop="materialGroupId" label="素材组">
                  <el-select v-model="searchForm.materialGroupId" placeholder="请选择">
                    <el-option
                      v-for="item in materialGroupList"
                      :key="item.materialGroupId"
                      :label="item.materialGroupName"
                      :value="item.materialGroupId"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="5">
                <el-form-item prop="materialName" label="素材名称">
                  <el-input v-model="searchForm.materialName" placeholder="请输入素材名称" />
                </el-form-item>
              </el-col> -->

              <el-col :span="9">
                <el-button class="btn" type="primary" @click="getPage()">
                  查询
                </el-button>
                <el-button class="btn" @click="resetSearch">
                  重置
                </el-button>
                <el-button class="btn" type="info" @click="checkedSele()">
                  查看选中
                </el-button>
                <el-button class="btn" type="success" @click="fixCheck">
                  确定
                </el-button>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-col>
    </el-row>

    <el-row class="material" :gutter="20">
      <el-col v-for="(item, index) in materialList" :key="index" :xl="4" :sm="6" :md="6" :xs="7">
        <div :class="['material-item', item.isSelected ? 'is-active' : '']" @click="onClickSelectCell(index)">
          <div class="material-item-image">
            <video :src="item.url" controls preload="meta" />
            <div class="size">{{ item.width }}*{{ item.height }}</div>
          </div>
          <div class="material-item-bottom">
            <div class="material-item-bottom-text">{{ item.materialName }}</div>

          </div>
        </div>
      </el-col>
    </el-row>

    <el-row v-show="showPage">
      <el-col :span="24">
        <el-pagination
          layout="prev, pager, next"
          :page-size="pageSize"
          :total="total"
          :current-page="pageNum"
          @current-change="changePage"
        >
        </el-pagination>
      </el-col>
    </el-row>

  </el-dialog>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import { getAlbumList, getMarterialGroupList, getVideoMarterialList } from '@/network/api/api-material'
export default {
  name: '',
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    return {
      showPage: true,
      materialGroupList: [], // 素材组列表
      albumList: [], // 专辑列表
      selectedData: [], // 选中的素材
      materialList: [], // 素材列表
      searchForm: {
        albumId: null,
        materialGroupId: null,
        materialName: null
      },
      pageNum: 1,
      pageSize: 12,
      total: 0,
      visible: false
    }
  },

  // 方法集合
  methods: {
    // 确定选中
    fixCheck() {
      this.visible = false
      this.$emit('fixCheck', this.selectedData)
      // this.close()
    },
    // 查看选中
    checkedSele() {
      this.showPage = false
      this.materialList = this.selectedData
    },
    // 分页事件
    changePage(pageNum) {
      this.pageNum = pageNum
      this.getPage()
    },
    // 重置
    resetSearch() {
      this.showPage = true
      this.$refs.searchForm.resetFields()
    },
    // 获取素材组列表
    alBumIdChange(val) {
      this.searchForm.materialGroupId = null
      getMarterialGroupList(this.pageNum, this.pageSize, { type: 'video', albumId: val })
        .then((res) => {
          this.materialGroupList = res.data.list
          this.total = res.data.total
        })
        .catch((err) => {
          this.$ErrorMessage(err.data || '获取素材组列表失败')
        })
    },
    // 获取专辑
    getAdAlbum() {
      getAlbumList(this.pageNum, this.pageSize, { type: 'video' })
        .then((res) => {
          this.albumList = res.data.list
        })
        .catch((err) => {
          this.$ErrorMessage(err.data || '获取专辑列表失败')
        })
    },
    // 获取列表
    getPage() {
      this.showPage = true
      getVideoMarterialList(this.pageNum, this.pageSize, { ...this.searchForm })
        .then((res) => {
          this.materialList = res.data.list.map((item) => {
            var seledList = this.selectedData.filter((f) => {
              return f.videoId === item.videoId
            })
            if (seledList.length > 0) {
              item.isSelected = true
            } else {
              item.isSelected = false
            }
            return item
          })
          this.total = res.data.total
          // this.selectedData = []
        })
        .catch((err) => {
          this.$ErrorMessage(err.data || '获取素材列表失败')
        })
    },
    // 点击选择或取消选择一条图片素材
    onClickSelectCell(index) {
      let item = this.materialList[index]
      item.isSelected = !item.isSelected
      if (item.isSelected) {
        // 添加到已选择的素材列表中
        this.selectedData.push(item)
      } else {
        // 找到对应索引，从已选择的素材列表中删除
        let index = this.selectedData.indexOf(item)
        this.selectedData.splice(index, 1)
      }
    },
    // 根据类型展示素材
    selectAsset(val) {
      Object.assign(this.$data, this.$options.data())
      this.visible = true
      this.getPage()
      this.getAdAlbum()
    },
    close() {
      this.visible = false
    }
  }
}
</script>
<style scoped lang="scss">

.material {
    color: #555a6f;
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .is-active {
      border: 3px solid #409eff !important;
    }
    .material-item {
      background-color: #fff;
      border: 3px solid transparent;
      box-shadow: 0 1px 5px 0 rgb(0 0 0 / 10%);
      margin-bottom: 20px;
      padding: 0px 6px 0px 6px;
      border-radius: 6px;
      &:hover {
        box-shadow: 2px 2px 5px 2px #ccc;
      }
      .material-item-image {
        position: relative;
        height: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        video {
          width: 100%;
          height: 95%;
          border-radius: 6px;
        }
        .size {
          font-size: 13px;
          background-color: #000;
          opacity: 0.5;
          color: #fff;
          padding: 2px 4px;
          position: absolute;
          bottom: 5px;
          right: 0;
        }
      }
      .material-item-bottom {
        display: flex;
        height: 40px;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
        .material-item-bottom-text {
          width: 150px;
          font-size: 13px;
          color: rgb(59, 59, 59);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .material-item-bottom-btn {
          .opt-icon-container {
            display: flex;
            .dian {
              width: 4px;
              height: 4px;
              background: rgb(59, 59, 59);
              border-radius: 50%;
              margin-right: 3px;
            }
          }
        }
      }
    }
  }
</style>

