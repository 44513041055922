<template>
  <el-drawer id="drawerid" size="70%" :visible.sync="visible" :show-close="false" :before-close="close" label-position="right" :destroy-on-close="true" :modal-append-to-body="false" :close-on-press-escape="false" :wrapper-closable="false">
    <div id="div" ref="orderBox" style="overflow:auto;margin-top:0px;overflow-x:hidden">
      <div id="titleUnit1" style="margin-bottom: 10px;" class="titleunitC">
        <span>新建定向模板</span>
      </div>
      <el-row :gutter="24">
        <el-col id="cotUnit" class="left-purple-dark">
          <div>
            <!--关联项目 -->
            <el-row class=" bg-purple-dark" style="margin-left: 30px;">
              <el-col :span="23">
                <div>
                  <el-row class="bg-purple-dark medisplay leftAndTop ">
                    <el-col :span="4">
                      <div class="meContent"><font style="color:red">*</font>关联项目</div>
                    </el-col>
                    <el-col :span="6">
                      <div>
                        <el-select v-model="ent.projectId" filterable allow-create default-first-option placeholder="请选择" @change="bsProjectChange">
                          <el-option v-for="item in rProjectList" :key="item.bsProjectId" :label="item.projectName" :value="item.bsProjectId" />
                        </el-select>
                      </div>
                    </el-col>

                    <el-col :span="4">
                      <div class="meContent"><font style="color:red">*</font>关联产品</div>
                    </el-col>
                    <el-col :span="6">
                      <div>
                        <el-select v-model="ent.productId" class="w-300" filterable allow-create default-first-option placeholder="请选择">
                          <el-option v-for="item in productList" :key="item.bsProductId" :label="item.productName" :value="item.bsProductId" />
                        </el-select>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </el-row>
            <!--定向设置 -->
            <el-row class=" bg-purple-dark" style="margin-left: 30px;">
              <div class="SettitleunitC">
                <span class="showFontSize20">定向设置</span>
              </div>
              <el-col :span="23">
                <div>
                  <!-- 地域 -->
                  <el-row class="medisplay leftAndTop ">
                    <el-col :span="4">
                      <div class="meContent"><font style="color:red">*</font>地域</div></el-col>
                    <el-col :span="20">
                      <div style="display: flex;align-items: center;">
                        <el-radio-group v-model="region" @change="regionChange">
                          <el-radio-button label="0">不限</el-radio-button>
                          <el-radio-button label="region">地域（省市区县）</el-radio-button>
                          <el-radio-button label="bizArea" :disabled="bizAreaDisplay">预置商圈</el-radio-button>
                          <el-radio-button label="place" :disabled="placeDisplay">场所</el-radio-button>
                        </el-radio-group>
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 地域 region-->
                  <el-row v-if="region === 'region'" class="medisplay leftAndTop ">
                    <el-col :span="4"> <div class="meContent" /></el-col>
                    <el-col :span="20">
                      <div style="display: flex;align-items: center;">
                        <treeTransfers :basic-date="basicDate" one-comp="中国" :show-data="regionEchoShowData" :check-data="regionEchoCheckData" select-name="地域（省市区县）" @getData="getRegionSelectData" />
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 地域 bizArea-->
                  <el-row v-if="region === 'bizArea'" class="medisplay leftAndTop ">
                    <el-col :span="4"> <div class="meContent" /></el-col>
                    <el-col :span="20">
                      <div style="display: flex;align-items: center;">
                        <bizArea :basic-date="bizAreaData" select-name="预置商圈" :echo-show-data="echoBizAreaShowData" :echo-check-data="echoBizAreaCheckData" @getData="getBizAreaData" />
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 地域 place-->
                  <el-row v-if="region === 'place'" class="medisplay leftAndTop ">
                    <el-col :span="4"> <div class="meContent" /></el-col>
                    <el-col :span="20">
                      <div style="display: flex;align-items: center;">
                        <treeTransfers :basic-date="basicPlaceDate" :show-data="placeEchoShowData" :check-data="placeEchoCheckData" select-name="场所" @getData="getPlaceSelectData" />
                      </div>
                    </el-col>
                  </el-row>

                  <!-- 年龄 -->
                  <el-row class=" medisplay leftAndTop ">
                    <el-col :span="4">
                      <div class="meContent"><font style="color:red">*</font>年龄</div></el-col>
                    <el-col :span="20">
                      <div style="display: flex;align-items: center;">
                        <el-radio-group v-model="age" @change="ageChange">
                          <el-radio-button label="age0">不限</el-radio-button>
                          <el-radio-button label="ageOther">指定年龄段</el-radio-button>
                          <el-radio-button label="customAge">自定义年龄</el-radio-button>
                        </el-radio-group>
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 年龄  ageOther-->
                  <el-row v-if="age === 'ageOther'" class="medisplay leftAndTop ">
                    <el-col :span="4"> <div class="meContent" /></el-col>
                    <el-col :span="20">
                      <div style="display: flex;align-items: center;">
                        <el-checkbox-group v-model="ageOtherGroup" @change="ageOtherGroupChange">
                          <el-checkbox v-for="age1 in ages" :key="age1" :label="age1">{{ age1 }}</el-checkbox>
                        </el-checkbox-group>
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 年龄  customAge-->
                  <el-row v-if="age === 'customAge'" class="medisplay leftAndTop ">
                    <el-col :span="4"> <div class="meContent" /></el-col>
                    <el-col :span="20">
                      <div style="display: flex;align-items: center;">
                        <el-select v-model="customAgeStart" placeholder="请选择" class="w-120 mr-10" @change="customAgeStartChange"> <el-option v-for="item in customAgeStartList" :key="item.value" :label="item.id" :value="item.value" /> </el-select>-
                        <el-select v-model="customAgeEnd" placeholder="请选择" class="w-120 ml-10" @change="customAgeStartChanges">
                          <el-option v-for="item in customAgeEndList" :key="item.value" :disabled="item.value === 56 ? false : customAgeStart + 9 > item.value" :label="item.id" :value="item.value" />
                        </el-select>
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 性别 -->
                  <el-row class=" medisplay leftAndTop ">
                    <el-col :span="4">
                      <div class="meContent"><font style="color:red">*</font>性别</div></el-col>
                    <el-col :span="20">
                      <div style="display: flex;align-items: center;">
                        <el-radio-group v-model="sex" @change="sexChange">
                          <el-radio-button label="0">不限</el-radio-button>
                          <el-radio-button label="2">男</el-radio-button>
                          <el-radio-button label="1">女</el-radio-button>
                        </el-radio-group>
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 人生阶段 -->
                  <el-row class=" medisplay leftAndTop ">
                    <el-col :span="4">
                      <div class="meContent"><font style="color:red">*</font>人生阶段</div></el-col>
                    <el-col :span="20">
                      <div style="display: flex;align-items: center;">
                        <el-checkbox-group v-model="lifeStage" @change="lifeStageChange">
                          <el-checkbox-button key="0" label="0">不限</el-checkbox-button>
                          <el-checkbox-button key="1" label="1">未婚</el-checkbox-button>
                          <el-checkbox-button key="2" label="2">已婚</el-checkbox-button>
                          <el-checkbox-button key="3" label="3">孕期</el-checkbox-button>
                          <el-checkbox-button key="4" label="4">家有儿女</el-checkbox-button>
                        </el-checkbox-group>
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 人生阶段  家有儿女-->
                  <el-row v-if="lifeStage.indexOf('4') !== -1" class="medisplay leftAndTop ">
                    <el-col :span="4"> <div class="meContent" /></el-col>
                    <el-col :span="20">
                      <div style="display: flex;align-items: center;">
                        <el-checkbox-group v-model="lifeStage4" @change="lifeStage4Change">
                          <el-checkbox v-for="item in lifeStageList" :key="item" :label="item">{{ item }}</el-checkbox>
                        </el-checkbox-group>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row class=" medisplay leftAndTop ">
                    <el-col :span="4">
                      <div class="meContent">
                        <font style="color:red">*</font>学历
                        <el-tooltip class="" effect="dark" placement="top-start">
                          <div slot="content" style="width:200px">
                            学历以取得毕业证为准，不包含在读情况
                          </div>
                          <i class="el-icon-question  ml-5 mr-5" />
                        </el-tooltip></div></el-col>
                    <el-col :span="20">
                      <div style="display: flex;align-items: center;">
                        <el-checkbox-group v-model="education" @change="educationChange">
                          <el-checkbox-button label="0">不限</el-checkbox-button>
                          <el-checkbox-button label="1">大学及以上</el-checkbox-button>
                          <el-checkbox-button label="3">大专</el-checkbox-button>
                          <el-checkbox-button label="2">高中及以下</el-checkbox-button>
                        </el-checkbox-group>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row class=" medisplay leftAndTop ">
                    <el-col :span="4">
                      <div class="meContent">
                        <font style="color:red">*</font>新兴趣（兴趣2.0）
                        <el-tooltip class="" effect="dark" placement="top-start">
                          <div slot="content" style="width:200px">
                            综合网民的浏览、搜索及应用内行为的表现综合判断人群兴趣类别，您可选择和自己业务相关的兴趣标签，定向目标人群 2020年升级了兴趣数据源及策略，为您找到更匹配人群，一定成本约束下，帮助您获取更多的转化量
                          </div>
                          <i class="el-icon-question  ml-5 mr-5" />
                        </el-tooltip></div></el-col>
                    <el-col :span="20">
                      <div style="display: flex;align-items: center;">
                        <el-radio-group v-model="newInterests" @change="newInterestsChange">
                          <el-radio-button label="0">不限</el-radio-button>
                          <el-radio-button label="1" :disabled="newInterestsDisplay">自定义</el-radio-button>
                        </el-radio-group>
                      </div>
                    </el-col>
                  </el-row>
                  <!-- 新兴趣 -->
                  <el-row v-if="newInterests === '1'" class="medisplay leftAndTop ">
                    <el-col :span="4"> <div class="meContent" /></el-col>
                    <el-col :span="20">
                      <div style="display: flex;align-items: center;">
                        <treeTransfers :basic-date="basicnewInterestsDate" :show-data="newInterestsEchoShowData" :check-data="newInterestsEchoCheckData" select-name="新兴趣" @getData="getnewInterestsSelectData" />
                      </div>
                    </el-col>
                  </el-row>

                  <el-row class=" medisplay leftAndTop ">
                    <el-col :span="4">
                      <div class="meContent"><font style="color:red">*</font>意图词</div></el-col>
                    <el-col :span="20">
                      <div style="display: flex;align-items: center;">
                        <el-radio-group v-model="keywords">
                          <el-radio-button label="0">不限</el-radio-button>
                        </el-radio-group>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row class=" medisplay leftAndTop ">
                    <el-col :span="4">
                      <div class="meContent">
                        <font style="color:red">*</font>人群包
                        <el-tooltip class="" effect="dark" placement="top-start">
                          <div slot="content" style="width:200px">
                            定向投放或者排除特定人群包，您可点击下方的管理自定义人群包进入观星盘创建或者管理自定义人群包。 为了您的投放效果，人群包暂不支持与以下定向同时选择：兴趣、意图词、商圈、场所
                          </div>
                          <i class="el-icon-question  ml-5 mr-5" />
                        </el-tooltip></div></el-col>
                    <el-col :span="20">
                      <div style="display: flex;align-items: center;">
                        <el-radio-group v-model="crowd" @change="crowdChange">
                          <el-radio-button label="0">不限</el-radio-button>
                          <!-- <el-radio-button label="crowd" :disabled="crowdDisplay" >定向人群</el-radio-button> -->
                          <!-- <el-radio-button label="crowd" disabled>定向人群</el-radio-button>
                          <el-radio-button label="excludeCrowd" disabled>排除人群</el-radio-button> -->
                        </el-radio-group>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row class=" medisplay leftAndTop ">
                    <el-col :span="4">
                      <div class="meContent">
                        <font style="color:red">*</font>排除已转化人群
                        <el-tooltip class="" effect="dark" placement="top-start">
                          <div slot="content" style="width:200px">
                            设置排除已转化定向，该定向仅支持oCPC、oCPM出价方式。默认排除近7天的已转化用户，若选择排除更多的时间窗，可前往观星盘设置后在平台的''人群包-排除人群''中排除。
                          </div>
                          <i class="el-icon-question  ml-5 mr-5" />
                        </el-tooltip></div></el-col>
                    <el-col :span="20">
                      <div style="display: flex;align-items: center;">
                        <el-radio-group v-model="excludeTrans" @change="excludeTransChange">
                          <el-radio-button label="0">不限</el-radio-button>
                          <el-radio-button label="1">同账户下</el-radio-button>
                          <el-radio-button label="2">同计划下</el-radio-button>
                          <el-radio-button label="3">同单元下</el-radio-button>
                        </el-radio-group>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row class=" medisplay leftAndTop ">
                    <el-col :span="4">
                      <div class="meContent"><font style="color:red">*</font>操作系统</div></el-col>
                    <el-col :span="20">
                      <div style="display: flex;align-items: center;">
                        <el-checkbox-group v-model="device" @change="deviceChange">
                          <el-checkbox-button label="0">不限</el-checkbox-button>
                          <el-checkbox-button label="1">IOS</el-checkbox-button>
                          <el-checkbox-button label="2">Android</el-checkbox-button>
                          <el-checkbox-button label="4">计算机</el-checkbox-button>
                        </el-checkbox-group>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row class=" medisplay leftAndTop ">
                    <el-col :span="4">
                      <div class="meContent">
                        <font style="color:red">*</font>APP行为
                        <el-tooltip class="" effect="dark" placement="top-start">
                          <div slot="content" style="width:200px">
                            根据手机APP使用行为寻找目标人群，注意：该功能仅支持Android操作系统。
                          </div>
                          <i class="el-icon-question  ml-5 mr-5" />
                        </el-tooltip></div></el-col>
                    <el-col :span="20">
                      <div style="display: flex;align-items: center;">
                        <el-radio-group v-model="app" @change="appChange">
                          <el-radio-button label="all" :disabled="deviceApp">不限</el-radio-button>
                          <el-radio-button label="category" :disabled="deviceApp">APP分类</el-radio-button>
                          <el-radio-button label="custom" :disabled="deviceApp">自定义APP</el-radio-button>
                        </el-radio-group>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row v-if="(!deviceApp && app === 'category') || (!deviceApp && app === 'custom')" class=" medisplay leftAndTop ">
                    <el-col :span="4">
                      <div class="meContent"><font style="color:red">*</font>定向逻辑</div></el-col>
                    <el-col :span="20">
                      <div style="display: flex;align-items: center;">
                        <el-radio-group v-model="behavior" @change="behaviorChange">
                          <el-radio-button label="2">定向已安装</el-radio-button>
                          <el-radio-button label="4">排除已安装</el-radio-button>
                        </el-radio-group>
                      </div>
                    </el-col>
                  </el-row>

                  <!-- 定向逻辑  可选APP分类-->
                  <el-row v-if="!deviceApp && app === 'category'" class="medisplay leftAndTop ">
                    <el-col :span="4"> <div class="meContent" /></el-col>
                    <el-col :span="20">
                      <div style="display: flex;align-items: center;">
                        <treeTransfers :basic-date="appTypeList" select-name="可选APP分类" :show-data="categoryEchoShowData" :check-data="categoryEchoCheckData" @getData="getappTypeSelectData" />
                      </div>
                    </el-col>
                  </el-row>

                  <!-- 定向逻辑  可选APP自定义-->
                  <el-row v-if="!deviceApp && app === 'custom'" class="medisplay leftAndTop ">
                    <el-col :span="4"> <div class="meContent" /></el-col>
                    <el-col :span="20">
                      <div style="display: flex;align-items: center;">
                        <treeTransferCustom :basic-date="[]" select-name="可选APP自定义" :show-datas="customEchoShowData" :check-data="customEchoCheckData" @getData="getcustomSelectData" />
                      </div>
                    </el-col>
                  </el-row>

                  <el-row class=" medisplay leftAndTop ">
                    <el-col :span="4">
                      <div class="meContent">
                        <font style="color:red">*</font>网络
                        <el-tooltip class="" effect="dark" placement="top-start">
                          <div slot="content" style="width:200px">
                            网络定向仅对移动端流量生效，计算机端流量无效
                          </div>
                          <i class="el-icon-question  ml-5 mr-5" />
                        </el-tooltip></div></el-col>
                    <el-col :span="20">
                      <div style="display: flex;align-items: center;">
                        <el-radio-group v-model="net" @change="netChange">
                          <el-radio-button label="0">不限</el-radio-button>
                          <el-radio-button label="1">wifi</el-radio-button>
                          <el-radio-button label="2">移动网络</el-radio-button>
                        </el-radio-group>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row v-if="device.indexOf('1') !== -1" class=" medisplay leftAndTop ">
                    <el-col :span="4">
                      <div class="meContent"><font style="color:red">*</font>iOS版本</div></el-col>
                    <el-col :span="20">
                      <div style="display: flex;align-items: center;">
                        <el-radio-group v-model="iosVersion" @change="iosVersionChange">
                          <el-radio-button label="0">不限</el-radio-button>
                          <el-radio-button label="1">自定义</el-radio-button>
                        </el-radio-group>
                      </div>
                    </el-col>
                  </el-row>
                  <!-- iOS版本  -->
                  <el-row v-if="iosVersion === '1' && device.indexOf('1') !== -1" class="medisplay leftAndTop ">
                    <el-col :span="4"> <div class="meContent" /></el-col>
                    <el-col :span="20">
                      <div style="display: flex;align-items: center;">
                        <el-checkbox-group v-model="iosVersionList" @change="iosVersionChangeGroup">
                          <el-checkbox v-for="item in iosList" :key="item" :label="item">{{ item }}</el-checkbox>
                        </el-checkbox-group>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row v-if="device.indexOf('2') !== -1" class=" medisplay leftAndTop ">
                    <el-col :span="4">
                      <div class="meContent"><font style="color:red">*</font>Android版本</div></el-col>
                    <el-col :span="20">
                      <div style="display: flex;align-items: center;">
                        <el-radio-group v-model="androidVersion" @change="androidVersionChange">
                          <el-radio-button label="0">不限</el-radio-button>
                          <el-radio-button label="1">自定义</el-radio-button>
                        </el-radio-group>
                      </div>
                    </el-col>
                  </el-row>

                  <!-- Android版本  -->
                  <el-row v-if="androidVersion === '1' && device.indexOf('2') !== -1" class="medisplay leftAndTop ">
                    <el-col :span="4"> <div class="meContent" /></el-col>
                    <el-col :span="20">
                      <div style="display: flex;align-items: center;">
                        <el-checkbox-group v-model="androidVersionList" @change="androidVersionChangeGroup">
                          <el-checkbox v-for="item in androidList" :key="item" :label="item">{{ item }}</el-checkbox>
                        </el-checkbox-group>
                      </div>
                    </el-col>
                  </el-row>

                  <el-row class=" medisplay leftAndTop mb-20 ">
                    <el-col :span="4">
                      <div class="meContent">
                        <font style="color:red">*</font>自动扩量
                        <el-tooltip class="" effect="dark" placement="top-start">
                          <div slot="content" style="width:200px">
                            启用自动扩量后，可在单元积累了一定的转化数据后，在当前已选定向之外探索更多的适配人群，达到更好投放效果。<br />
                            自动扩量的规模和效果，会根据您已选的流量和定向特征有所不同， 自动扩量不会突破年龄、性别和投放省市这些基础定向设定。
                          </div>
                          <i class="el-icon-question  ml-5 mr-5" />
                        </el-tooltip></div></el-col>
                    <el-col :span="20">
                      <div style="display: flex;align-items: center;"><el-switch v-model="autoExpansion" class="mr-10" @change="autoExpansionChange" /> <font class="descClass">启用自动扩量后，系统会在已选定向之外，自动将广告投放给更多合适的人群</font></div>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </el-row>
            <!--单元名称 -->
            <el-row class=" bg-purple-dark" style="margin-left: 30px;">
              <div style="height:40px" class="SettitleunitC">
                <span class="showFontSize20">定向名称</span>
              </div>
              <el-col :span="23">
                <div>
                  <el-row class=" medisplay leftAndTop mb-20">
                    <el-col :span="4">
                      <div class="meContent"><font style="color:red">*</font>定向名称</div></el-col>
                    <el-col :span="20">
                      <div style="display: flex;align-items: center;">
                        <el-input v-model="adgroupFeedName" maxlength="100" placeholder="请输入单元名称" />
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
              <el-col :span="23">
                <div class="height-70 ml-20 mb-20" style="padding-top:30px">
                  <el-popconfirm title="确定提交？" @confirm="submit">
                    <el-button slot="reference" type="primary" round>确定</el-button>
                  </el-popconfirm>
                  <el-button class="ml-10" round @click="close">取消</el-button>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <!--  隔离区-->
        <el-col :span="1">
          <div style="height:10px;" />
        </el-col>
      </el-row>
    </div>
  </el-drawer>
</template>
<script>
// getTrackList
import { Add, Update } from '@/network/api/assetManagement/assetManagementBaiduAudience'
import { formRules } from './object'
import { AdvManagementBaiduUnitEnt, CreateUnitAudience, launchType } from './create'

import { regionArray } from './regionArrays'
import { palceArray } from './palce'
import { newInterestsArray } from './newInterests'
import { appTypeArray } from './appType'
import treeTransfers from './treeTransfer.vue'
import treeTransferCustom from './treeTransferCustom'
import bizArea from './bizArea.vue'
import { bizAreaArray } from './bizAreaArrays'
import { transType } from './transType'
import { getMeRelationProject } from '@/network/api/basicSetting/project/basicSettingProject'
import { getProductByProject } from '@/network/api/basicSetting/product/basicSettingProduct'
import moment from 'moment'
import { mapGetters } from 'vuex'
export default {
  components: { treeTransfers, bizArea, treeTransferCustom },
  inject: ['loadData'],
  data() {
    return {
      customEchoShowData: [],
      customEchoCheckData: [],
      categoryEchoShowData: [],
      categoryEchoCheckData: [],
      adgroupFeedName: undefined,
      ocpcBid: 0.3,
      transTypeVl: undefined,
      transTypeList: transType,
      track: undefined,
      trackList: [],
      baiduAdv: [],
      baiduId: undefined,
      housekeeperId: undefined,
      // houseKeeper: [],
      lpUrl: undefined,
      nullll: '0',
      payMode: '1',
      bidtype: '0',
      newInterestsShow: '不限',
      newInterestsEchoShowData: [],
      newInterestsEchoCheckData: [],
      basicnewInterestsDate: newInterestsArray,
      basicnewInterestsList: undefined,
      getLifeStage: '不限',
      customAgeEnd: undefined,
      customAgeStart: undefined,
      customAgeStartList: [],
      customAgeEndList: [],
      getAgeData: '不限',
      ageOtherGroup: [],
      lifeStage4: [],
      ages: ['18岁以下', '18-24岁', '25-34岁', '35-44岁', '45-54岁', '54岁以上'],
      lifeStageList: ['0-3岁', '3-6岁', '小学', '中学'],
      basicDate: regionArray,
      regionEchoShowData: [],
      regionEchoCheckData: [],
      bizAreaData: bizAreaArray,
      basicPlaceDate: palceArray,
      placeEchoShowData: [],
      placeEchoCheckData: [],
      bizAreaDataC: undefined,
      echoBizAreaShowData: [],
      echoBizAreaCheckData: [],
      palceSelectData: undefined,
      regionSelectData: undefined,
      basicRegionDate: regionArray,
      ent: new AdvManagementBaiduUnitEnt(),
      audienceInit: new CreateUnitAudience(),
      rules: [formRules],
      autoExpansion: false,
      getIosVersionShow: undefined,
      iosVersion: '0',
      iosList: ['14.x', '13.x', '12.x', '11.x', '10.x', '9.x', '8.x'],
      iosVersionList: [],
      androidVersion: '0',
      androidList: ['11.x', '10.x', '9.x', '8.x', '7.x', '6.x', '5.x', '4.x'],
      androidVersionList: [],
      getAndroidVersionShow: undefined,
      net: '0',
      behavior: '2',
      device: ['0'], // 不限
      deviceShow: '不限',
      deviceApp: false,
      deviceTf: true,
      excludeTrans: '0', // 排除已转化人群
      age: 'age0',
      region: '0',
      regionShowData: '不限',
      ftypesShowC: launchType,
      bizAreaDisplay: false,
      placeDisplay: false,
      newInterestsDisplay: false,
      appshow: '不限',
      app: 'all',
      appTypeList: appTypeArray,
      appBehavior: undefined,
      appcustomBehavior: undefined,
      crowd: '0', // 定向人群
      crowdDisplay: false, // 定向互斥
      lifeStage: ['0'], // ['0'], // 人生阶段
      lifedeaf: true,
      keywords: '0',
      newInterests: '0', // 兴趣
      sex: '0',
      education: ['0'], // 学历
      educationTf: true,
      isEdit: false,
      visible: false,
      ftypesType: '0', // 投放范围类型
      ftypesData: [],
      ftypesValue: [],
      // 滚动条的高度
      projectList: [],
      rProjectList: [],
      productList: [],

      cityList: []
    }
  },

  mounted() {
    this.customAgeStartList = []
    this.customAgeEndList = []
    for (var i = 18; i <= 56; i++) {
      if (i === 56) {
        this.customAgeStartList.push({ id: i + '岁及以上', value: i })
        this.customAgeEndList.push({ id: i + '岁及以上', value: i })
      } else {
        if (i >= 27) {
          this.customAgeEndList.push({ id: i + '岁', value: i })
        }
        this.customAgeStartList.push({ id: i + '岁', value: i })
      }
    }
    window.addEventListener('scroll', this.handleScroll, true)
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters(['userInfo', 'isCollapse'])
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    bsProjectChange(val) {
      this.ent.productId = undefined
      this.getProduct(val)
    },
    getProduct(val) {
      getProductByProject(val).then((res) => {
        if (res.code === 200) {
          this.productList = res.data
        }
      })
    },
    getProject() {
      getMeRelationProject().then((res) => {
        if (res.code === 200) {
          this.rProjectList = res.data
        }
      })
    },

    /**
     * @description 自动扩量
     */
    getAutoExpansion() {
      if (this.autoExpansion) {
        return '开启'
      } else {
        return '关闭'
      }
    },
    autoExpansionChange() {
      this.audienceInit.getObjectData('autoExpansion').value = this.autoExpansion ? '1' : '0'
    },
    /**
     * @description *iOS版本 iosVersion
     */
    getIosVersion() {
      if (this.iosVersion === '0') {
        this.getIosVersionShow = '不限'
      } else {
        this.getIosVersionShow = '自定义' + this.iosVersionList
      }

      return this.iosVersion // this.audienceInit.getObjectData('iosVersion').value
    },
    iosVersionChangeGroup() {
      this.getIosVersion()
    },
    iosVersionChange() {
      this.getIosVersion()
      this.audienceInit.getObjectData('iosVersion').value = this.iosVersion
    },
    /**
     * @description *Android版本  androidVersion
     */
    getAndroidVersion() {
      if (this.androidVersion === '0') {
        this.getAndroidVersionShow = '不限'
      } else {
        this.getAndroidVersionShow = '自定义' + this.androidVersionList
      }
      //  return this.androidVersion // this.audienceInit.getObjectData('androidVersion').value
    },
    androidVersionChangeGroup() {
      this.getAndroidVersion()
    },
    androidVersionChange() {
      this.getAndroidVersion()
      this.audienceInit.getObjectData('androidVersion').value = this.androidVersion
    },
    /**
     * @description  *网络 net
     */
    netChange() {
      this.audienceInit.getObjectData('net').value = this.net
    },
    getNet() {
      if (this.net === '0') {
        return '不限'
      }
      if (this.net === '1') {
        return 'wifi'
      }
      if (this.net === '2') {
        return '移动网络'
      }
    },
    /**
     * @description APP自定义 behavior
     */
    getcustomSelectData(val) {
      this.appcustomBehavior = val
      this.getapp()
    },

    getappTypeSelectData(val) {
      this.appBehavior = val
      this.getapp()
    },
    behaviorChange() {
      this.getapp()
    },
    appChange() {
      this.getapp()
      this.appBehavior = undefined
      this.appcustomBehavior = undefined
      this.audienceInit.getObjectData('app').value = this.app
    },
    getapp() {
      if (this.app === 'all') {
        this.appshow = '不限'
      }
      if (this.app === 'category') {
        this.appshow = 'APP分类'
        if (this.behavior === '2') {
          this.appshow = 'APP分类-定向已安装'
          if (this.appBehavior !== undefined) {
            this.appshow = 'APP分类-定向已安装-选中' + this.appBehavior.check.length + '个'
          }
        } else if (this.behavior === '4') {
          this.appshow = 'APP分类-排除已安装'
          if (this.appBehavior !== undefined) {
            this.appshow = 'APP分类-排除已安装-选中' + this.appBehavior.check.length + '个'
          }
        }
      }
      if (this.app === 'custom') {
        this.appshow = '自定义APP'
        if (this.behavior === '2') {
          this.appshow = '自定义APP-定向已安装'
          if (this.appcustomBehavior !== undefined) {
            this.appshow = '自定义APP-定向已安装-选中' + this.appcustomBehavior.check.length + '个'
          }
        } else if (this.behavior === '4') {
          this.appshow = '自定义APP-排除已安装'
          if (this.appcustomBehavior !== undefined) {
            this.appshow = '自定义APP-排除已安装-选中' + this.appcustomBehavior.check.length + '个'
          }
        }
      }
    },
    /**
     * @description 操作系统 device
     */
    getdevice() {
      var content = ''
      if (this.device.indexOf('0') !== -1) {
        content = content + '不限'
      }
      if (this.device.indexOf('1') !== -1) {
        content = content + 'IOS,'
      }
      if (this.device.indexOf('2') !== -1) {
        content = content + 'Android,'
        this.appBehavior = undefined
      }
      if (this.device.indexOf('4') !== -1) {
        content = content + '计算机,'
      }
      this.deviceShow = content
      return content
    },
    deviceChange(val) {
      this.app = 'all'
      if (this.device.indexOf('0') !== -1 && this.deviceTf && this.device.length > 1) {
        this.deviceTf = false
        this.device.splice(this.device.indexOf('0'), 1)
      } else {
        if (this.device.indexOf('0') !== -1 && !this.deviceTf && this.device.length > 1) {
          this.device = ['0']
          this.deviceTf = true
        }
      }
      if (this.device.length === 0) {
        this.device = ['0']
      }
      this.deviceApp = true
      if (this.device.indexOf('0') !== -1) {
        this.deviceApp = false
      }
      if (this.device.indexOf('2') !== -1) {
        this.deviceApp = false
      }
      this.getdevice()
    },
    /**
     * @description 排除已转化人群 excludeTrans
     */
    excludeTransChange() {
      this.audienceInit.getObjectData('excludeTrans').value = this.excludeTrans
    },
    getexcludeTrans() {
      if (this.excludeTrans === '0' || this.excludeTrans === 0) {
        return '不限'
      }
      if (this.excludeTrans === '1' || this.excludeTrans === 1) {
        return '同账户下'
      }
      if (this.excludeTrans === '2' || this.excludeTrans === 2) {
        return '同计划下'
      }
      if (this.excludeTrans === '3' || this.excludeTrans === 3) {
        return '同单元下'
      }
    },

    /**
     * @description 人群包 crowd
     */
    getCrowd() {
      if (this.crowd === '0' || this.crowd === 0) {
        return '不限'
      }
      if (this.crowd === 'crowd') {
        return '定向人群'
      }
      if (this.crowd === 'excludeCrowd') {
        return '排除人群'
      }
    },
    crowdChange() {
      if (this.crowd === '0' || this.crowd === 0) {
        this.audienceInit.getObjectData('crowd').value = undefined
        this.audienceInit.getObjectData('excludeCrowd').value = undefined
        this.audienceInit.changeMutexDisNoplay('crowd')
      }
      if (this.crowd === 'crowd') {
        this.audienceInit.getObjectData('crowd').value = 'crowd'
        this.audienceInit.getObjectData('excludeCrowd').value = undefined
        this.audienceInit.changeDisplay('crowd', true)
      }
      if (this.crowd === 'excludeCrowd') {
        this.audienceInit.getObjectData('crowd').value = undefined
        this.audienceInit.getObjectData('excludeCrowd').value = 'excludeCrowd'
        this.audienceInit.changeMutexDisNoplay('crowd')
      }

      this.bizAreaDisplay = this.audienceInit.getObjectDataDisplay('bizArea')
      this.placeDisplay = this.audienceInit.getObjectDataDisplay('place')
      this.newInterestsDisplay = this.audienceInit.getObjectDataDisplay('newInterests')
    },

    /**
     * @description *新兴趣（兴趣2.0）  newInterests
     */
    getnewInterestsSelectData(val) {
      this.basicnewInterestsList = val
      this.newInterestsShow = this.getNewInterests()
    },
    getNewInterests() {
      if (this.newInterests === '0' || this.newInterests === 0) {
        return '不限'
      }
      if (this.newInterests === '1' || this.newInterests === 1) {
        if (this.basicnewInterestsList !== undefined && this.basicnewInterestsList.check !== undefined) {
          return '自定义-选中' + this.basicnewInterestsList.check.length + '个'
        } else {
          return '自定义-选中'
        }
      }
    },
    newInterestsChange() {
      this.basicnewInterestsList = []
      if (this.newInterests !== '0') {
        this.audienceInit.getObjectData('newInterests').value = this.newInterests
      } else {
        this.audienceInit.getObjectData('newInterests').value = undefined
      }
      if (this.newInterests === '1' || this.newInterests === 1) {
        this.audienceInit.changeDisplay('newInterests', true)
      } else {
        this.audienceInit.changeMutexDisNoplay('newInterests')
      }
      this.crowdDisplay = this.audienceInit.getObjectDataDisplay('crowd')
      this.newInterestsShow = this.getNewInterests()
    },

    /**
     * @description  *学历 education
     */
    getEducation() {
      var content = ''
      if (this.education.indexOf('0') !== -1) {
        content = content + '不限,'
      }
      if (this.education.indexOf('1') !== -1) {
        content = content + '大学及以上,'
      }
      if (this.education.indexOf('2') !== -1) {
        content = content + '高中及以下,'
      }
      if (this.education.indexOf('3') !== -1) {
        content = content + '大专,'
      }
      return content
    },
    educationChange() {
      if (this.education.length > 1) {
        if (this.education.indexOf('0') >= 0) {
          if (this.educationTf) {
            this.educationTf = false
            this.education.splice(this.education.indexOf('0'), 1)
          } else {
            this.education = ['0']
            this.educationTf = true
          }
        }
      }
      if (this.education.length === 0) {
        this.education = ['0']
      }

      this.audienceInit.getObjectData('education').value = this.education
    },

    /**
     * @description 人生阶段 lifeStage
     */
    lifeStage4Change() {
      this.getLifeStage = this.getLifeStages()
    },
    getLifeStages() {
      var content = ''
      if (this.lifeStage.indexOf('0') !== -1) {
        content = content + '不限,'
      }
      if (this.lifeStage.indexOf('1') !== -1) {
        content = content + '未婚,'
      }
      if (this.lifeStage.indexOf('2') !== -1) {
        content = content + '已婚,'
      }
      if (this.lifeStage.indexOf('3') !== -1) {
        content = content + '孕期,'
      }
      if (this.lifeStage.indexOf('4') !== -1) {
        content = content + '家有儿女,' + '-' + this.lifeStage4
      }
      return content
    },
    lifeStageChange() {
      if (this.lifeStage.indexOf('4') === -1) {
        this.lifeStage4 = []
      }
      if (this.lifeStage.length > 1) {
        if (this.lifeStage.indexOf('0') !== -1) {
          if (this.lifedeaf) {
            this.lifedeaf = false
            this.lifeStage.splice(this.lifeStage.indexOf('0'), 1)
          } else {
            this.lifeStage = ['0']
            this.lifedeaf = true
          }
        }
      }
      if (this.lifeStage.length === 0) {
        this.lifeStage = ['0']
      }
      this.audienceInit.getObjectData('lifeStage').value = this.lifeStage
      this.getLifeStage = this.getLifeStages()
    },

    /**
     * @description 性别 sex
     */
    getsex() {
      if (this.sex === '0' || this.sex === 0) {
        return '不限'
      }
      if (this.sex === '1' || this.sex === 1) {
        return '女'
      }
      if (this.sex === '2' || this.sex === 2) {
        return '男'
      }
    },
    sexChange() {
      this.audienceInit.getObjectData('sex').value = this.sex
      console.log(this.audienceInit.getObjectData('sex').value)
    },
    /**
     * @description 定向 年龄
     */
    ageOtherGroupChange() {
      this.getAgeData = this.ageOtherGroup.toString()
    },
    customAgeStartChange(val) {
      if (val + 9 > this.customAgeEnd) {
        if (val + 9 > 54) {
          this.customAgeEnd = 56
          this.getAgeData = this.customAgeStart + '-' + this.customAgeEnd + '岁及以上'
        } else {
          this.customAgeEnd = val + 9
          this.getAgeData = this.customAgeStart + '-' + this.customAgeEnd + '岁'
        }
      } else {
        this.customAgeStartChanges(this.customAgeEnd)
      }
    },
    customAgeStartChanges(val) {
      if (val === 56) {
        this.getAgeData = this.customAgeStart + '-' + this.customAgeEnd + '岁及以上'
      } else {
        this.getAgeData = this.customAgeStart + '-' + this.customAgeEnd + '岁'
      }
    },
    ageChange() {
      this.getAgeData = '请选择'
      this.customAgeEnd = undefined
      this.customAgeStart = undefined
      if (this.age === 'age0') {
        this.getAgeData = '不限'
      }
      if (this.age === 'customAge') {
        this.customAgeEnd = 27
        this.customAgeStart = 18
        this.getAgeData = this.customAgeStart + '-' + this.customAgeEnd + '岁'
      }
      this.ageOtherGroup = []
      // console.info(this.age)
    },

    /**
     * @description 定向 *地域 选择地域
     */
    getBizAreaData(val) {
      this.bizAreaDataC = val
      this.getregionData()
    },

    getPlaceSelectData(val) {
      this.palceSelectData = val
      this.getregionData()
    },
    getRegionSelectData(val) {
      this.regionSelectData = val
      this.getregionData()
    },
    regionChange() {
      this.regionShowData = undefined
      // this.basicDate = []
      this.regionSelectData = undefined
      this.palceSelectData = undefined
      this.bizAreaDataC = undefined
      if (this.region !== undefined) {
        this.audienceInit.regionMutex(this.region)
        this.crowdDisplay = this.audienceInit.getObjectDataDisplay('crowd')
      }
      if (this.region !== '0') {
        this.audienceInit.getObjectData(this.region).value = this.region
      } else {
        this.audienceInit.getObjectData('region').value = '9999999'
      }
      this.getregionData()
    },
    getregionData() {
      if (this.region !== '0') {
        if (this.region === 'region' && this.regionSelectData !== undefined && this.regionSelectData.check !== undefined) {
          this.regionShowData = '省市区（县）选中' + this.regionSelectData.check.length + '个'
          return
        }
        if (this.region === 'place' && this.palceSelectData !== undefined && this.palceSelectData.check !== undefined) {
          this.regionShowData = '场所 选中' + this.palceSelectData.check.length + '个'
          return
        }

        if (this.region === 'bizArea' && this.bizAreaDataC !== undefined && this.bizAreaDataC.check !== undefined) {
          this.regionShowData = '预置商圈 选中' + this.bizAreaDataC.check.length + '个'
          return
        }
        this.regionShowData = '请选择'
      } else {
        this.regionShowData = '不限'
      }
    },

    handleScroll(event) {
      var leftUnit = document.getElementById('leftUnit1')
      var titleUnit = document.getElementById('titleUnit1')
      if (undefined !== leftUnit && undefined !== titleUnit && titleUnit.getBoundingClientRect) {
        var top = titleUnit.getBoundingClientRect().top
        var end = 60 - top
        leftUnit.style.marginTop = end + 'px'
      }
    },

    submit() {
      this.arrangement()
    },

    /**
     * @description 回显
     */
    Echo() {
      // 定向对象
      if (this.ent.audience !== undefined) {
        var audience = JSON.parse(this.ent.audience)
        // region 回显
        if (audience.region !== undefined && (audience.region === '9999999' || audience.region === 9999999)) {
          this.region = '0'
        } else if (audience.region !== undefined) {
          this.region = 'region'
          var region = audience.region
          this.regionSelectData = region
          //  region 多级穿梭
          this.regionEchoShowData = region.data !== undefined ? region.data : []
          this.regionEchoCheckData = region.check !== undefined ? region.check : []
        }
        // 地域 bizArea 预置商圈 回显
        if (audience.bizArea !== undefined) {
          this.region = 'bizArea'
          var bizArea = audience.bizArea
          this.bizAreaDataC = bizArea
          //  bizArea 多级穿梭
          this.echoBizAreaShowData = bizArea.data !== undefined ? bizArea.data : []
          this.echoBizAreaCheckData = bizArea.check !== undefined ? bizArea.check : []
        }
        // 地域 place 预置商圈 回显
        if (audience.place !== undefined) {
          this.region = 'place'
          var place = audience.place
          this.palceSelectData = place
          //  bizArea 多级穿梭
          this.placeEchoShowData = place.data !== undefined ? place.data : []
          this.placeEchoCheckData = place.check !== undefined ? place.check : []
        }
        // age
        if (audience.age !== undefined && (audience.age === '0' || audience.age === 0)) {
          this.age = 'age0'
        } else if (audience.age !== undefined) {
          this.ageOtherGroup = audience.age.split(',')
          this.age = 'ageOther'
        }
        if (audience.customAge !== undefined) {
          this.age = 'customAge'
          var customAge = audience.customAge.split(',')
          if (customAge.length === 2) {
            this.customAgeStart = Number(customAge[0])
            if (Number(customAge[1]) > 55) {
              this.customAgeEnd = 56
            } else {
              this.customAgeEnd = Number(customAge[1])
            }
          }
          this.getAgeData = this.customAgeStart + '-' + this.customAgeEnd + '岁'
        }
        // sex
        if (audience.sex !== undefined) {
          this.sex = audience.sex + ''
        }
        //   lifeStage  人生阶段
        if (audience.lifeStage !== undefined) {
          var lifeStage = audience.lifeStage.split(',')
          this.lifeStage = []
          if (lifeStage.indexOf('0') !== -1 || lifeStage.indexOf(0) !== -1) {
            this.lifeStage.push('0')
          }
          if (lifeStage.indexOf('1') !== -1 || lifeStage.indexOf(1) !== -1) {
            this.lifeStage.push('1')
          }
          if (lifeStage.indexOf('2') !== -1 || lifeStage.indexOf(2) !== -1) {
            this.lifeStage.push('2')
          }
          if (lifeStage.indexOf('3') !== -1 || lifeStage.indexOf(3) !== -1) {
            this.lifeStage.push('3')
          }
          if (lifeStage.indexOf('401') !== -1 || lifeStage.indexOf(401) !== -1) {
            if (this.lifeStage.indexOf('4') === -1) {
              this.lifeStage.push('4')
            }
            this.lifeStage4.push('0-3岁')
          }
          if (lifeStage.indexOf('402') !== -1 || lifeStage.indexOf(402) !== -1) {
            if (this.lifeStage.indexOf('4') === -1) {
              this.lifeStage.push('4')
            }
            this.lifeStage4.push('3-6岁')
          }
          if (lifeStage.indexOf('403') !== -1 || lifeStage.indexOf(403) !== -1) {
            if (this.lifeStage.indexOf('4') === -1) {
              this.lifeStage.push('4')
            }
            this.lifeStage4.push('小学')
          }
          if (lifeStage.indexOf('404') !== -1 || lifeStage.indexOf(404) !== -1) {
            if (this.lifeStage.indexOf('4') === -1) {
              this.lifeStage.push('4')
            }
            this.lifeStage4.push('中学')
          }
        }
        //  学历
        if (audience.education !== undefined) {
          this.education = audience.education.split(',')
        }
        //  // newInterests 新兴趣（兴趣2.0）
        if (audience.newInterests === '0' || audience.newInterests === 0) {
          this.newInterests = '0'
        } else if (audience.newInterests !== undefined) {
          this.newInterests = '1'
          var newInterests = audience.newInterests
          this.basicnewInterestsList = newInterests
          this.newInterestsEchoShowData = newInterests.data !== undefined ? newInterests.data : []
          this.newInterestsEchoCheckData = newInterests.check !== undefined ? newInterests.check : []
        }
        //    //排除已转化人群 excludeTrans
        if (audience.excludeTrans !== undefined) {
          this.excludeTrans = audience.excludeTrans + ''
        }
        // 操作系统 device
        if (audience.device !== undefined) {
          this.device = audience.device.split(',')
          this.deviceChange('')
        }

        // APP行为
        if (audience.app !== undefined) {
          var app = audience.app
          if (app.type === 'all') {
            this.app = 'all'
            this.appChange()
          }
          if (app.type === 'category') {
            this.app = 'category'
            this.appChange()
            var list = app.list
            this.appBehavior = list
            //        customEchoShowData: [],
            // customEchoCheckData: [],
            // categoryEchoShowData: [],
            // categoryEchoCheckData: [],
            this.categoryEchoShowData = list.data !== undefined ? list.data : []
            this.categoryEchoCheckData = list.check !== undefined ? list.check : []
            this.behavior = app.behavior
          }
          if (app.type === 'custom') {
            this.app = 'custom'
            this.appChange()

            var list1 = app.list
            this.appcustomBehavior = list1
            this.customEchoShowData = list1.data !== undefined ? list1.data : []
            this.customEchoCheckData = list1.check !== undefined ? list1.check : []
            this.behavior = app.behavior
          }
        }
        // ios and

        if (audience.iosVersion !== undefined && audience.iosVersion === '0') {
          this.iosVersion = '0'
        } else if (audience.iosVersion !== undefined) {
          this.iosVersion = '1'
          this.iosVersionList = audience.iosVersion.split(',')
        }
        if (audience.androidVersion !== undefined && audience.androidVersion === '0') {
          this.androidVersion = '0'
        } else if (audience.androidVersion !== undefined) {
          this.androidVersion = '1'
          this.androidVersionList = audience.androidVersion.split(',')
        }
        // net
        this.net = audience.net
        this.autoExpansion = !!(audience.autoExpansion === 1 || audience.autoExpansion === '1')
      }
      this.adgroupFeedName = this.ent.audienceName
      // this.ageChange() // 显示age
      this.getLifeStage = this.getLifeStages() // 显示人生阶段
      this.newInterestsShow = this.getNewInterests() // 显示新兴趣
      this.getIosVersion()
      this.getAndroidVersion()
      this.getregionData()
    },

    /**
     * @description 整理结果
     */
    arrangement() {
      // 关联计划
      // if (this.ent.baiduAdvPlanId === undefined) {
      //   this.$message({
      //     message: '请选择关联计划',
      //     type: 'error'
      //   })
      //   return
      // }
      //  this.ent.campaignFeedId = this.baiduAdvPlanId
      // 投放范围
      // if (this.ftypesType === '0') {
      //   this.ent.ftypes = [].toString()
      // } else {
      //   if (this.ftypesData.length === 0) {
      //     this.$message({
      //       message: '请选择投放范围',
      //       type: 'error'
      //     })
      //     return
      //   }
      //   this.ent.ftypes = this.ftypesData.toString()
      // }
      // audience 定向对象

      var audience = {}
      // 地域 region 地域（省市区县）
      if (this.region === '0') {
        audience.region = '9999999'
      }
      if (this.region === 'region') {
        if (this.regionSelectData === undefined || this.regionSelectData.data === undefined || !(this.regionSelectData.data instanceof Array) || this.regionSelectData.data.length === 0) {
          this.$message({
            message: '请选择地域（省市区县）',
            type: 'error'
          })
          return
        }
        audience.region = this.regionSelectData
      }
      // 地域 bizArea 预置商圈
      if (this.region === 'bizArea') {
        if (this.bizAreaDataC === undefined || this.bizAreaDataC.data === undefined || !(this.bizAreaDataC.data instanceof Array) || this.bizAreaDataC.data.length === 0) {
          this.$message({
            message: '请选择预置商圈',
            type: 'error'
          })
          return
        }
        audience.bizArea = this.bizAreaDataC
      }
      //  地域 place 预置商圈
      if (this.region === 'place') {
        if (this.palceSelectData === undefined || this.palceSelectData.data === undefined || !(this.palceSelectData.data instanceof Array) || this.palceSelectData.data.length === 0) {
          this.$message({
            message: '请选择预置商圈',
            type: 'error'
          })
          return
        }
        audience.place = this.palceSelectData
      }
      //  age
      if (this.age === 'age0') {
        audience.age = '0'
      } else if (this.age === 'ageOther') {
        if (this.ageOtherGroup === undefined || this.ageOtherGroup.length === 0) {
          this.$message({
            message: '请选择指定年龄段',
            type: 'error'
          })
          return
        }
        audience.age = this.ageOtherGroup.toString()
      } else if (this.age === 'customAge') {
        if (this.customAgeEnd > 55) {
          audience.customAge = this.customAgeStart + ',99'
        } else {
          audience.customAge = this.customAgeStart + ',' + this.customAgeEnd
        }
      }
      // 性别
      if (this.sex !== undefined) {
        audience.sex = Number(this.sex)
      }
      // lifeStage 人生阶段
      if (this.lifeStage !== undefined) {
        var lifeStage = []
        //  lifeStageList: ['0-3岁', '3-6岁', '小学', '中学'],
        if (this.lifeStage.indexOf('0') !== -1) {
          lifeStage.push('0')
        }
        if (this.lifeStage.indexOf('1') !== -1) {
          lifeStage.push('1')
        }
        if (this.lifeStage.indexOf('2') !== -1) {
          lifeStage.push('2')
        }
        if (this.lifeStage.indexOf('3') !== -1) {
          lifeStage.push('3')
        }
        if (this.lifeStage.indexOf('4') !== -1) {
          if (this.lifeStage4.length === 4) {
            lifeStage.push(4)
          } else {
            if (this.lifeStage4.indexOf('0-3岁') !== -1) {
              lifeStage.push(401)
            }
            if (this.lifeStage4.indexOf('3-6岁') !== -1) {
              lifeStage.push(402)
            }
            if (this.lifeStage4.indexOf('小学') !== -1) {
              lifeStage.push(403)
            }
            if (this.lifeStage4.indexOf('中学') !== -1) {
              lifeStage.push(404)
            }
          }
        }
        audience.lifeStage = lifeStage.toString()
      }
      // 学历
      if (this.education !== undefined) {
        audience.education = this.education.toString()
      }
      // newInterests 新兴趣（兴趣2.0）
      if (this.newInterests === '0') {
        audience.newInterests = '0'
      }
      if (this.newInterests === '1') {
        if (this.basicnewInterestsList === undefined || this.basicnewInterestsList.data === undefined || !(this.basicnewInterestsList.data instanceof Array) || this.basicnewInterestsList.data.length === 0) {
          this.$message({
            message: '请选择自定义新兴趣',
            type: 'error'
          })
          return
        }
        audience.newInterests = this.basicnewInterestsList
      }
      // keywords 意图词
      audience.keywords = ''
      // crowd 人群包
      audience.crowd = ''
      // 排除已转化人群 excludeTrans
      audience.excludeTrans = this.excludeTrans
      // device
      audience.device = this.device.toString()
      // app 示例："{\"type\":\"category\",\"list\":[{\"id\":9107}],\"behavior\":[2]}"
      var app = {}
      if (this.app === 'all') {
        app.type = 'all'
      } else if (this.app === 'category') {
        if (this.appBehavior === undefined || this.appBehavior.data === undefined || !(this.appBehavior.data instanceof Array) || this.appBehavior.data.length === 0) {
          this.$message({
            message: '请选择 APP分类',
            type: 'error'
          })
          return
        }
        app.type = 'category'
        app.list = this.appBehavior
        app.behavior = this.behavior
      } else if (this.app === 'custom') {
        if (this.appcustomBehavior === undefined || this.appcustomBehavior.data === undefined || !(this.appcustomBehavior.data instanceof Array) || this.appcustomBehavior.data.length === 0) {
          this.$message({
            message: '请选择 自定义APP',
            type: 'error'
          })
          return
        }
        app.type = 'custom'
        app.list = this.appcustomBehavior
        app.behavior = this.behavior
      }
      audience.app = app

      // iosVersion iOS系统版本
      if (this.device.indexOf('1') !== -1) {
        if (this.iosVersion === '0') {
          audience.iosVersion = '0'
        } else if (this.iosVersion === '1') {
          audience.iosVersion = this.iosVersionList.toString()
        }
      }
      // androidVersion  Android系统版本	 androidVersionList androidVersion==='1'
      if (this.device.indexOf('2') !== -1) {
        if (this.androidVersion === '0') {
          audience.androidVersion = '0'
        } else if (this.androidVersion === '1') {
          audience.androidVersion = this.androidVersionList.toString()
        }
      }
      // net
      audience.net = this.net
      audience.autoExpansion = this.autoExpansion ? 1 : 0
      this.ent.audience = JSON.stringify(audience)
      // this.ent.bidtype = '3'
      // ocpc
      // var ocpc = {}
      // ocpc.transFrom = '2'
      // if (this.lpUrl === undefined) {
      //   this.$message({
      //     message: '请输入推广URL',
      //     type: 'error'
      //   })
      //   return
      // }
      // ocpc.lpUrl = this.lpUrl
      // if (this.track === undefined) {
      //   this.$message({
      //     message: '请选择转化名称',
      //     type: 'error'
      //   })
      //   return
      // }
      // ocpc.appTransId = this.track
      // ocpc.housekeeperId = this.housekeeperId
      // ocpc.baiduId = this.baiduId
      // if (this.transTypeVl === undefined) {
      //   this.$message({
      //     message: '请选择目标转化',
      //     type: 'error'
      //   })
      //   return
      // }
      // ocpc.transType = this.transTypeVl
      // if (this.ocpcBid === undefined) {
      //   this.$message({
      //     message: '请选择目标转化出价',
      //     type: 'error'
      //   })
      //   return
      // }
      // ocpc.ocpcBid = this.ocpcBid
      // this.ent.ocpc = JSON.stringify(ocpc)
      // // 单元名称
      // if (this.adgroupFeedName === undefined) {
      //   this.$message({
      //     message: '请输入推广单元名称',
      //     type: 'error'
      //   })
      //   return
      // }

      this.ent.audienceName = this.adgroupFeedName
      this.isEdit ? this.updateRow() : this.addRow()
    },
    /**
     * @description 添加
     */
    addRow() {
      const req = { ...this.ent }
      Add(req).then((res) => {
        this.$message({
          message: '保存成功',
          type: 'success'
        })
        this.loadData()
        this.close()
      })
    },
    /**
     * @description 修改
     */
    updateRow() {
      const req = { ...this.ent }
      Update(req).then((res) => {
        this.$message({
          message: '修改成功',
          type: 'success'
        })
        this.loadData()
        this.close()
      })
    },
    close() {
      window.removeEventListener('scroll', this.handleScroll)
      this.visible = false
    },
    getUID() {
      // 获取唯一值
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (Math.random() * 16) | 0
        var v = c === 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      })
    },
    show(isEdit = false, ent) {
      Object.assign(this.$data, this.$options.data())
      this.getProject()
      // Object.assign(this.$data, this.$options.data())
      if (!isEdit) {
        this.adgroupFeedName = '定向_' + this.userInfo.username + '_' + moment().format('YYYY-MM-DD_hh:mm:ss') + '_名称'
        // this.getHk()
        this.customAgeStartList = []
        this.customAgeEndList = []
        for (var i = 18; i <= 56; i++) {
          if (i === 56) {
            this.customAgeStartList.push({ id: i + '岁及以上', value: i })
            this.customAgeEndList.push({ id: i + '岁及以上', value: i })
          } else {
            if (i >= 27) {
              this.customAgeEndList.push({ id: i + '岁', value: i })
            }
            this.customAgeStartList.push({ id: i + '岁', value: i })
          }
        }
      }
      this.ent = ent
      this.isEdit = isEdit
      this.visible = true
      if (this.isEdit) {
        this.Echo()
        this.getProduct(this.ent.projectId)
      }
      this.$nextTick(() => {
        if (this.isEdit) {
          console.log('不能为空')
        }
      })
    }
  }
}
</script>
<style>
.leftAndTop {
  padding-left: 30px;
  padding-top: 20px;
}
.leftAndTops {
  padding-left: 30px;
  padding-top: 1px;
}
.medisplay {
  display: flex;
  align-items: center;
}
.el-drawer__header {
  display: none;
}
.el-drawer__body {
  background: #f2f4f8;
  overflow: 'hidden';
}
.left-purple-dark {
  background: #f2f4f8;
}
.bg-purple-dark {
  border-radius: 5px;
  margin-bottom: 30px;
  background: #fff;
}
.titleunitC {
  font-size: 20px;
  padding-top: 15px;
  margin-left: 30px;
}
.SettitleunitC {
  padding-top: 15px;
  margin-left: 30px;
}
.descClass {
  padding-left: 20px;
  font-size: 12px;
  color: #808389;
}
.showFontSize20 {
  font-size: 20px;
}
.showFontSize {
  font-size: 10px;
}
.showTitle {
  font-weight: 700;
}

.el-col {
  border-radius: 4px;
}

.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>
