<template>
  <div>
    <el-form
      ref="form"
      :inline="true"
      :model="form"
      :rules="rules"
    >
      <Base title="基础信息">
        <el-row :gutter="24">
          <el-col :span="6">
            <el-form-item
              label="任务名称"
              prop="taskName"
            >
              <el-input
                v-model="form.taskName"
                placeholder="请输入任务名称"
                style="width: 300px;"
                maxlength="30"
                show-word-limit
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="执行周期"
              prop="isLoop"
            >
              <el-select
                v-model="form.isLoop"
                placeholder="请选择执行周期"
                :style="{ 'width': form.isLoop ? '120px' : '300px' }"
                @change="handleChangeCycleType"
              >
                <el-option
                  label="单次"
                  :value="false"
                ></el-option>
                <el-option
                  label="循环"
                  :value="true"
                ></el-option>
              </el-select>
              <!-- 【执行周期】选择“循环” -->
              <el-select
                v-if="form.isLoop"
                v-model="form.loopCycle"
                placeholder="请选择循环周期"
                style="width: 165px;margin-left: 15px;"
                @change="handleChangeLoop"
              >
                <el-option
                  v-for="(item, index) in loopCycles"
                  :key="index"
                  v-bind="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="投放账户"
              prop="accounts"
            >
              <el-table
                stripe
                :data="form.accounts"
                style="min-width: 800px"
              >
                <el-table-column
                  prop="housekeeperName"
                  label="管理账户"
                  width="300"
                >
                </el-table-column>
                <el-table-column
                  prop="accountName"
                  label="账户名称"
                >
                </el-table-column>
                <el-table-column
                  prop="accountId"
                  label="账户ID"
                  width="160"
                >
                </el-table-column>
                <el-table-column
                  align="right"
                  width="80"
                >
                  <template
                    slot="header"
                  >
                    <el-button
                      round
                      size="mini"
                      type="primary"
                      @click="showAdvAccountDialog"
                    ><i class="el-icon-circle-plus-outline" /></el-button>
                  </template>
                  <template slot-scope="scope">
                    <el-button
                      round
                      size="mini"
                      type="danger"
                      @click="removeAdvAccount(scope.$index)"
                    ><i class="el-icon-remove-outline" /></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-col>
        </el-row>
      </Base>
      <Base title="数据信息">
        <el-row :gutter="24">
          <el-col :span="6">
            <el-form-item
              label="数据类型"
              prop="dataCondition"
            >
              <el-select
                v-model="form.dataCondition"
                placeholder="请选择数据条件"
                style="width: 300px;"
              >
                <el-option
                  v-for="(item, index) in dataConditions.slice(1)"
                  :key="index"
                  v-bind="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item
              label="进粉时间"
              prop="lifeCycle"
            >
              <el-date-picker
                v-model="form.lifeCycle"
                :picker-options="expireTimeOption"
                type="daterange"
                range-separator="至"
                start-placeholder="进粉开始时间"
                value-format="yyyy-MM-dd"
                end-placeholder="进粉结束时间"
              > </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col
            :span="6"
          >
            <el-form-item
              label="性别拆分"
              prop="whereGender"
            >
              <el-select
                v-model="form.whereGender"
                placeholder="请选择性别拆分"
                :style="{width: form.whereGender ? '80px' : '300px'}"
              >
                <el-option
                  label="拆分"
                  :value="true"
                ></el-option>
                <el-option
                  label="不拆分"
                  :value="false"
                ></el-option>
              </el-select>
              <!-- 【性别拆分】为”拆分“ -->
              <el-select
                v-if="form.whereGender"
                v-model="form.gender"
                multiple
                placeholder="请选择性别拆分"
                style="width: 205px;margin-left: 15px;"
              >
                <el-option
                  v-for="(item, index) in sexSelects"
                  :key="index"
                  v-bind="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="6">
            <el-form-item
              label="人群格式"
              prop="fileFormat"
            >
              <el-select
                v-model="form.fileFormat"
                multiple
                placeholder="请选择人群格式"
                style="width: 300px;"
              >
                <el-option
                  v-for="(item, index) in fileTypes"
                  :key="index"
                  v-bind="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col
            v-if="form.dataCondition === 1 && form.isLoop"
            :span="6"
          >
            <el-form-item
              label="留存用户修正"
              prop="keepUserCorrect"
            >
              <el-select
                v-model="form.keepUserCorrect"
                placeholder="请选择留存用户修正"
                style="width: 300px;"
              >
                <el-option
                  label="是"
                  :value="true"
                ></el-option>
                <el-option
                  label="否"
                  :value="false"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col
            :span="6"
          >
            <el-form-item
              label="指定企微"
              prop="specifyWorkCorp"
            >
              <el-select
                v-model="form.specifyWorkCorp"
                style="width: 100px; margin-right: 1em"
              >
                <el-option
                  label="是"
                  :value="true"
                ></el-option>
                <el-option
                  label="否"
                  :value="false"
                ></el-option>
              </el-select>
              <span style="color: #888">注：不指定则全量数据生成</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col
            v-if="form.specifyWorkCorp"
            :span="10"
          >
            <el-form-item
              label="指定企微主体ID"
              prop="corpIdList"
            >
              <el-input
                v-model="form.corpIdList"
                type="textarea"
                resize="none"
                style="width: 400px"
                :autosize="{ minRows: 8, maxRows: 8 }"
                placeholder="请填写企微主体ID，换行区隔"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </Base>

      <div class="config-footer">
        <el-button
          v-if="buttonName"
          type="primary"
          :disabled="loading"
          @click="handleConfirm"
        >{{ buttonName }}</el-button>
        <el-button @click="handleCancel">取 消</el-button>
      </div>
    </el-form>

    <el-dialog
      title="投放账户添加"
      :modal-append-to-body="false"
      :visible.sync="showAdvertiserSearchDialog"
      width="600px"
    >
      <el-form
        :model="advForm"
        :rules="advRules"
        label-width="80px"
      >
        <el-form-item
          label="管理账号"
          prop="keeper"
        >
          <el-select
            v-model="advForm.keeper"
            style="width: 100%"
            filterable
            @change="queryAdvertisers"
          >
            <el-option
              v-for="i in houseKeepers"
              :key="i.value"
              v-bind="i"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="投放账户"
          prop="advertisers"
        >
          <el-select
            v-model="advForm.advertisers"
            multiple
            filterable
            style="width: 100%"
          >
            <el-option
              v-for="i in advertisers"
              :key="i.value"
              v-bind="i"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="addAdvAccount"
        >确定</el-button>
        <el-button @click="showAdvertiserSearchDialog = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import Base from '@/views/advManagement/baidu/baiduBatch/component/base.vue'
import { dataConditions, fileTypes, loopCycles, fileTypeMap, sexSelects } from './util'
import { saveCrowdPackTask, getCrowdPackDetail } from '@/network/api/huge/assetCrowdPack'
import { getHouseKeeper, getAdvertisersV2 as getAdvertisers } from '@/network/api/api-huge-batch'
export default {
  name: 'HugeCrowdPackingTask',
  components: {
    Base
  },
  data() {
    return {
      loopCycles,
      // 数据条件
      dataConditions,
      // 文件类型
      fileTypes,
      sexSelects,
      taskId: null,
      loading: false,
      state: null,
      form: {
        taskName: '', // 任务名称
        fileFormat: ['s_idfa', 's_imei', 's_oaid'],
        isLoop: false, // 执行周期
        loopCycle: 1, // 循环周期
        dataCondition: '', // 数据条件
        accounts: [], // 账户列表 { housekeeperId: number; advId: number }
        lifeCycle: [], // 数据时间
        keepUserCorrect: true, // 留存用户修正
        whereGender: false, // 性别拆分
        gender: [],
        specifyWorkCorp: false, // 指定企微
        corpIdList: ''
      },
      advForm: {
        keeper: null,
        advertisers: []
      },
      expireTimeOption: {
        disabledDate(date) {
          return date.getTime() >= moment().startOf('day').valueOf()
        }
      },
      // 投放账户查询
      showAdvertiserSearchDialog: false,
      houseKeepers: [],
      advertisers: []
    }
  },

  computed: {
    buttonName () {
      // can not edit if state is not initial
      if (this.taskId && this.state > 1) return ''
      return this.taskId ? '编 辑' : '创 建'
    },
    rules() {
      return {
        taskName: [{ required: true, message: '请输入任务名称', trigger: 'blur' }],
        accounts: [{ required: true, message: '清选择账户列表', trigger: 'blur' }],
        specifyWorkCorp: [{ required: true, message: '请选择是否指定企微主体', trigger: 'change' }],
        isLoop: [{ required: true, message: '请选择执行周期', trigger: 'change' }],
        dataCondition: [{ required: true, message: '请选择数据条件', trigger: 'change' }],
        lifeCycle: [{ required: true, message: '请选择数据时间', trigger: 'change' }],
        fileFormat: [{ required: true, message: '请选择文件类型', trigger: 'change' }],
        keepUserCorrect: [{ required: true, message: '请选择留存用户修正', trigger: 'change' }],
        whereGender: [{ required: true, message: '请选择性别拆分', trigger: 'change' }],
        corpIdList: [{ required: this.form.specifyWorkCorp, message: '请输入指定企微主体ID', trigger: 'blur' }]
      }
    },
    advRules() {
      return {
        keeper: [{ required: true, message: '请选择管理账户', trigger: 'blur' }],
        advertisers: [{ required: true, message: '请选择投放账号', trigger: 'blur' }]
      }
    }
  },

  created() {
    this.getById()
    getHouseKeeper({ name: '' }).then(resp => {
      this.houseKeepers = resp?.data?.map(({ housekeeperId, accountName }) => ({ label: accountName, value: housekeeperId })) || []
    }).catch(console.error)
  },

  methods: {
    getById() {
      const { id: tiktokTaskId } = this.$route.params
      if (!tiktokTaskId) {
        this.handleChangeLoop(7)
        return
      }
      this.taskId = tiktokTaskId || null
      getCrowdPackDetail(tiktokTaskId).then(({ code, data }) => {
        if (code === 200 && data?.tiktokTasks) {
          const { taskName, taskExecuteCycle, account, taskDataWhere: dataCondition, dataWhere } = data.tiktokTasks
          const { d_add_employee_time: lifeCycle, is_use_gender: whereGender, s_sex, custom_audience_format, data_update: keepUserCorrect, is_use_we_com: specifyWorkCorp, we_com_id } = JSON.parse(dataWhere) || {}
          this.form = {
            taskName,
            fileFormat: custom_audience_format.map(({ value }) => value),
            isLoop: taskExecuteCycle > 0, // 执行周期
            loopCycle: taskExecuteCycle > 0 ? taskExecuteCycle : 1, // 循环周期
            dataCondition, // 数据条件
            accounts: account.map(({ accountHugeAdv: { accountId, accountName, advId }, accountHugeHousekeeper: { housekeeperId, accountName: housekeeperName }}) => ({ housekeeperId, housekeeperName, accountId, accountName, advId })), // 账户列表 { housekeeperId: number; advId: number }
            lifeCycle, // 数据时间
            keepUserCorrect, // 留存用户修正
            whereGender, // 性别拆分
            gender: s_sex.split(',').map(v => +v),
            specifyWorkCorp, // 指定企微
            corpIdList: we_com_id.join('\n')
          }
        }
      })
    },

    // 切换执行周期类型
    handleChangeCycleType(type) {
      this.handleChangeLoop(type === 1 ? 0 : this.form.loopCycle)
    },

    // 切换循环周期
    handleChangeLoop(type) {
      this.form.lifeCycle = [moment().subtract(1 + type, 'day').format('YYYY-MM-DD'), moment().subtract(1, 'day').format('YYYY-MM-DD')]
    },
    // 投放账号列表
    showAdvAccountDialog () {
      this.showAdvertiserSearchDialog = true
    },
    queryAdvertisers () {
      if (this.advForm.keeper) {
        getAdvertisers({ housekeeperId: this.advForm.keeper }).then(resp => {
          this.advertisers = resp?.data?.map(({ advId: value, accountId, accountName }) => ({ label: accountId + '-' + accountName, accountId, accountName, value })) || []
        }).catch(console.error)
      }
    },
    addAdvAccount () {
      this.showAdvertiserSearchDialog = false
      const { value: housekeeperId, label: housekeeperName } = this.houseKeepers.find(k => k.value === this.advForm.keeper)
      this.advForm.advertisers.forEach(advId => {
        if (this.form.accounts.find(a => a.advId === advId)) return // duplicate
        const { accountId, accountName } = this.advertisers.find(a => a.value === advId)
        this.form.accounts.push({
          housekeeperId,
          housekeeperName,
          advId,
          accountId,
          accountName
        })
      })
      this.advForm.advertisers.splice(0)
      this.advForm.keeper = null
    },
    removeAdvAccount (idx) {
      this.form.accounts.splice(idx, 1)
    },
    // 点击确定
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return
        }
        const { taskName, isLoop, loopCycle, accounts, dataCondition, lifeCycle, whereGender: is_use_gender, gender, fileFormat, keepUserCorrect: data_update, specifyWorkCorp: is_use_we_com, corpIdList } = this.form
        const params = {
          taskName,
          taskExecuteCycle: isLoop ? loopCycle : -1,
          accounts,
          dataWhere: {
            n_is_retained: dataCondition,
            d_add_employee_time: lifeCycle,
            is_use_gender,
            s_sex: is_use_gender ? gender.join(',') : '',
            custom_audience_format: fileFormat.map(value => ({ key: fileTypeMap[value], value })),
            data_update,
            is_use_we_com,
            we_com_id: corpIdList.split(/\r?\n/)
          }
        }
        if (this.taskId) {
          params.tiktokTaskId = this.taskId
        }
        this.loading = true
        saveCrowdPackTask(params).then(({ code }) => {
          if (code === 200) {
            this.$message.success(this.taskId ? '更新成功！' : '添加成功！')
            setTimeout(this.handleCancel, 1000)
          }
        }).finally(() => {
          this.loading = false
        })
      })
    },

    // 点击取消
    handleCancel() {
      this.$router.replace({
        name: 'hugeCrowdPackingTask'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.config-footer {
  text-align: center;
}</style>
