<!--
 * @Description: 落地页模板
 * @Date: 2021-10-18 10:49:17
 * @LastEditTime: 2023-03-16 16:15:29
-->

<template>
  <div>
    <el-row :gutter="20" type="flex" justify="space-between">
      <el-col>
        <el-form ref="formInline" :inline="true" :model="formInline" label-width="100px" :rules="rules">
          <el-form-item label="落地页名称:" prop="canvasName">
            <el-input v-model="formInline.canvasName" style="width:100%" />
          </el-form-item>
          <el-form-item label="分享标题:" prop="shareTitle">
            <el-input v-model="formInline.shareTitle" style="width:100%" maxlength="14" show-word-limit />
          </el-form-item>
          <el-form-item label="分享描述:" prop="shareDesc">
            <el-input v-model="formInline.shareDesc" style="width:100%" maxlength="20" show-word-limit />
          </el-form-item>
          <el-dropdown class="ml-20 mr-20">
            <div class="ldy-bg-color" :style="`background:${formInline.colors}`"></div>
            <el-dropdown-menu slot="dropdown">
              <twitter-color :colors="formInline.colors" style="margin-top: 10px"
                @change="(value) => (formInline.colors = value)" />
            </el-dropdown-menu>
          </el-dropdown>
          <el-form-item v-if="paramsId" label="">
            <el-button type="primary" @click="add_visible = true"> 修改项目产品</el-button>
            <!-- palette -->
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" @click="submit">提交并保存</el-button>
      </el-col>
    </el-row>
    <el-divider style="margin: -12px" />

    <el-row :gutter="40" class="row-box">
      <!-- 选择区 -->
      <el-col :span="8">
        <div class="grid-content bg-purple">
          <WidgetGallery :select-widgets-index="selectWidgetsIndex" :final-widgets="finalWidgets"
            :final-top-widgets="finalTopWidgets" :top-widgets-disable="topWidgetsDisable"
            @setFinalWidgets="setFinalWidgets" @setFinalTopWidgets="setFinalTopWidgets" />
        </div>
      </el-col>

      <!-- 工作区 -->
      <el-col style="width: 415px; margin-right: 60px;position: relative; height: 720px">
        <div class="grid-content bg-purple workbench" :style="`background:${formInline.colors}`">
          <Workbench :final-widgets="finalWidgets" :final-top-widgets="finalTopWidgets"
            :select-widgets-index="selectWidgetsIndex" @setFinalTopWidgets="finalTopWidgets = {}"
            @cancelTopWidgetsDisable="topWidgetsDisable = false" @setFinalWidgetsCount="setFinalWidgetsCount"
            @setSelectedIndex="setSelectedIndex" @updateFinalWidgets="updateFinalWidgets" />
        </div>
      </el-col>

      <!-- 编辑区 -->
      <el-col :span="8">
        <div class="grid-content bg-purple">
          <AdEditors :final-widgets="finalWidgets" :final-top-widgets="finalTopWidgets"
            :select-widgets-index="selectWidgetsIndex" @setSelectedIndex="setSelectedIndex" />
        </div>
      </el-col>
    </el-row>

    <el-dialog title="选择项目产品" :visible="add_visible" width="620px" :modal-append-to-body="false" :show-close="false"
      destroy-on-close>
      <el-form ref="formInline1" :model="formInline1" label-width="100px" class="demo-ruleForm">
        <el-form-item label="项目:" prop="projectId" :rules="{ required: true, message: '请选择关联项目', trigger: 'change' }">
          <el-select v-model="formInline1.projectId" filterable clearable placeholder="请选择关联项目" style="width: 400px;"
            @change="(value) => (selectProjectId = value)">
            <el-option v-for="(itm, idx) in projectList" :key="idx" :label="itm.projectName"
              :value="itm.bsProjectId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联产品:">
          <el-select v-model="formInline1.productId" style="width:400px" placeholder="请选择产品">
            <el-option label="不限" :value="0"></el-option>
            <el-option v-for="(itm, idx) in productListed" :key="idx" :label="itm.productName"
              :value="itm.bsProductId"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" :disabled="!formInline1.projectId" @click="add_visible = false">确定</el-button>

        <el-button v-if="paramsId" type="primary" @click="add_visible = false"> 关闭 </el-button>
        <router-link v-else to="/wechatLdy" tag="span"><el-button class="ml-20"> 返回 </el-button></router-link>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import WidgetGallery from './widgetGallery.vue'
import Twitter from '@/components/twitterColor.vue'
import Workbench from './workbench.vue'
import AdEditors from './adEditors.vue'
import { ldySave, getOnlyPage, ldyUpdate } from '@/network/api/assetManagement/assetManageWeChatAdv.js'
// import { jsonFormat } from '@/utils/ruoyi'
import moment from 'moment'
import accountUinTencent from '@/mixin/accountUin_tencent'

export default {
  components: {
    'twitter-color': Twitter,
    WidgetGallery,
    Workbench,
    AdEditors
  },
  mixins: [accountUinTencent],
  data() {
    return {
      rules: {
        canvasName: [{ required: true, message: '落地页名称不能为空', trigger: 'change' }],
        shareTitle: [{ required: true, message: '分享标题不能为空', trigger: 'change' }],
        shareDesc: [{ required: true, message: '分享描述不能为空', trigger: 'change' }]
      },
      formInline: {
        canvasName: '原生推广页' + moment(new Date(), 'yyyyMMddhhmmss'),
        shareTitle: '',
        shareDesc: '',
        colors: '#FFFFFF'
      },
      add_visible: false,
      paramsId: this.$route.params.id,
      formInline1: {
        projectId: null,
        productId: 0
      },
      finalTopWidgets: {}, // 选择的顶部组件
      finalWidgets: [], // 选择的组件集合
      finalWidgetsCount: 0, // 选择的组件个数
      selectWidgetsIndex: -2, // 当前选中的索引
      topWidgetsDisable: false // 顶部组件禁止点击
    }
  },
  watch: {
    // 增删时, 显示对应编辑区
    finalTopWidgets: {
      handler(value) {
        if (Object.keys(value).length === 0) {
          if (this.finalWidgets.length) {
            this.$emit('setSelectedIndex', 0)
            this.editors = this.finalWidgets[0].component
            this.detail = this.finalWidgets[0]
          } else {
            this.editors = ''
            this.detail = ''
          }
        } else {
          this.editors = value.component
          this.detail = value
        }
      },
      deep: true
    }
  },
  mounted() {
    this.editLoading()
    setTimeout(() => {
      this.add_visible = !this.$route.params.id
    }, 500)
  },
  methods: {
    /**
     * @description: 添加顶部组件到工作区
     * @param {*} item 选择的组件类容
     */
    setFinalTopWidgets(item) {
      this.selectWidgetsIndex = -1
      this.topWidgetsDisable = true
      this.finalTopWidgets = item
    },
    /**
     * @description: 添加组件到工作区
     * @param {*} item 选择的组件类容
     */
    setFinalWidgets(item) {
      let list = this.finalWidgets[this.finalWidgetsCount - 1]
      if (list && list.widgetTypeV2 === 'shelfnew') {
        if (list.layoutItems.componentItem[0].layoutItems.componentItem[0].pureImageUrl === '') {
          return this.$message.error('请选择配图!')
        }
      }
      this.finalWidgets.push({
        ...item,
        id: this.finalWidgetsCount
      })

      this.selectWidgetsIndex = this.finalWidgetsCount
      this.finalWidgetsCount++
    },

    /**
     * @description: 设置选择的组件集合数量
     * @param {*} count
     */
    setFinalWidgetsCount(count) {
      this.finalWidgetsCount = count
    },
    /**
     * @description: 设置当前选中的索引
     * @param {*} item 当前选中的索引
     */
    setSelectedIndex(index) {
      this.selectWidgetsIndex = index
    },

    /**
     * @description: 修改FinalWidgets
     * @param {*} list
     */
    updateFinalWidgets(list) {
      this.finalWidgets = list
    },

    /**
     * @description:编辑时获取数据
     */
    editLoading() {
      const { id } = this.$route.params
      if (!id) return
      getOnlyPage(+id).then(({ code, data }) => {
        if (code === 200) {
          let pageTemplate = JSON.parse(data.promotePage.replace(/&gt;/g, '>'))

          // 悬浮组件
          let floatButton = pageTemplate.adCanvasInfo.globalComponentItems ? pageTemplate.adCanvasInfo.globalComponentItems.componentItem[0] || '' : ''
          if (floatButton) {
            pageTemplate.adCanvasInfo.PageList.Page[0].componentItemList.componentItem.push(floatButton)
          }

          this.formInline.canvasName = pageTemplate.adCanvasInfo.canvasName
          this.formInline.shareTitle = pageTemplate.adCanvasInfo.shareTitle
          this.formInline.shareDesc = pageTemplate.adCanvasInfo.shareDesc
          this.formInline.colors = pageTemplate.adCanvasInfo.PageList.Page[0].backgroundColor
          this.formInline1.projectId = pageTemplate.adCanvasInfo.projectId
          this.formInline1.productId = pageTemplate.adCanvasInfo.productId

          // this.finalTopWidgets = pageTemplate.adCanvasInfo.PageList.Page[0].componentItemList.componentItem[0]
          let topWidgets = pageTemplate.adCanvasInfo.PageList.Page[0].componentItemList.componentItem[0]
          if (!topWidgets.videoList) {
            topWidgets.videoList = [{
              materialId: topWidgets.materialId,
              sightVideoUrl: topWidgets.sightVideoUrl,
              initWidth: topWidgets.initWidth,
              initHeight: topWidgets.initHeight,
              uploadVideoImg: topWidgets.uploadVideoImg,
              sightThumbUrl: topWidgets.sightThumbUrl
            }]
          }
          this.finalTopWidgets = topWidgets
          this.finalWidgets = pageTemplate.adCanvasInfo.PageList.Page[0].componentItemList.componentItem.slice(1)
          // 文本情况下,字号要 / 2
          // this.finalWidgets.forEach((item) => {
          //   if (item.widgetTypeV2 === 'text') {
          //     item.fontSize = item.fontSize / 2
          //   }
          // })

          this.selectWidgetsIndex = 0
          let pageComponent = pageTemplate.adCanvasInfo.globalComponentItems
          let componentItem = pageTemplate.adCanvasInfo.PageList.Page[0].componentItemList.componentItem
          if (pageComponent) {
            this.finalWidgetsCount = componentItem.length + 1
          } else {
            this.finalWidgetsCount = componentItem.length - 1
          }
          this.selectWidgetsIndex = -1
        }
      })
    },

    /**
     * @description: 提交
     */

    submit() {
      const { finalWidgets, finalTopWidgets } = this
      const { pureImageUrl, widgetTypeV2, adLocation = 'sns', videoList } = finalTopWidgets
      const { canvasName, shareDesc, shareTitle, colors } = this.formInline
      const { projectId, productId } = this.formInline1
      if (finalWidgets.length === 0 || widgetTypeV2 === undefined) {
        return this.$message.error('落地页类容不能为空!')
      }

      let widgets = []
      // 文本情况下,字号要 / 2
      finalWidgets.forEach((item) => {
        let row = { ...item }
        // if (row.widgetTypeV2 === 'text') {
        //   row.fontSize = row.fontSize * 2
        // }
        widgets.push({ ...row })
      })
      if (widgetTypeV2 === 'topvideo') {
        let uploadVideoImgs = videoList.filter(v => !v.uploadVideoImg)
        if (uploadVideoImgs.length > 0) {
          return this.$message.warning('正在生成视频顶部素材预览图...请稍后操作...')
        }
      }
      let params = {
        backgroundColor: colors,
        adLocation,
        canvasInfo: [finalTopWidgets, ...widgets.filter((f) => f.widgetTypeV2 !== 'floatbutton')],
        canvasName,
        shareDesc,
        shareTitle,
        shareThumbUrl: widgetTypeV2 === 'topvideo' ? videoList[0].sightThumbUrl : pureImageUrl,
        topType: finalTopWidgets.type,
        projectId,
        projectName: this.projectList.filter((item) => item.bsProjectId === projectId)[0].projectName,
        productId,
        productName: productId !== 0 ? this.productListed.filter((item) => item.bsProductId === productId)[0].productName : '不限'
      }

      // 悬浮组件提取到外面
      let floatButton = widgets.filter((f) => f.widgetTypeV2 === 'floatbutton')

      let isWX = widgets.some((s) => ['enterprise_wx_auto', 'shelfnew', 'floatbutton'].includes(s.widgetTypeV2))

      if (floatButton.length) {
        params.globalComponentItems = {
          componentItem: floatButton
        }
      } else {
        delete params.globalComponentItems
      }
      this.$refs['formInline'].validate((valid) => {
        if (valid) {
          if (this.$route.params.id) {
            ldyUpdate({ ...params, id: +this.$route.params.id }, isWX).then((res) => {
              if (res.code === 200) {
                this.$message.success('编辑成功!')
              }
            })
          } else {
            ldySave(params, isWX).then((res) => {
              if (res.code === 200) {
                this.$message.success('添加成功!')
              }
            })
          }
          this.$router.push({ path: '/wechatLdy' })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.row-box {
  display: flex;
  flex-wrap: nowrap;
}

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #f2f2f2;
}

.bg-purple {
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.ldy-bg-color {
  width: 34px;
  height: 34px;
  border: 5px solid #bfc2c7;
  border-radius: 4px;
}

.workbench {
  height: 720px;
  width: 415px;
  // background: #fcfcfc;
  overflow: hidden;
  overflow-y: auto;
}
</style>
