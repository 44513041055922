<!--
 * @Description: 推广单元
 * @Date: 2021-12-24 14:49:52
 * @LastEditTime: 2022-05-06 10:54:01
-->
<template>
  <el-form ref="searchForm" :inline="true" class="search-form search-form-4" :model="searchForm">
    <el-row :gutter="20">
      <el-col :span="6">
        <el-form-item prop="adgroupFeedName" label="推广单元名称">
          <el-input v-model="searchForm.adgroupFeedName" placeholder="请选择推广单元名称" />
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-form-item prop="createBy" label="创建者">
          <el-input v-model="searchForm.createBy" placeholder="请选择创建者" />
        </el-form-item>
      </el-col>

      <el-col :span="6">
        <el-form-item prop="baiduAdvPlanId" label="关联计划">
          <el-select v-model="searchForm.baiduAdvPlanId" filterable allow-create default-first-option placeholder="请选择关联计划">
            <el-option v-for="item in projectList" :key="item.baiduAdvPlanId" :label="item.campaignFeedName" :value="item.baiduAdvPlanId" />
          </el-select>
          <!--  <el-input v-model="searchForm.campaignFeedId" placeholder="推广计划" /> -->
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item prop="createTime" label="创建时间">
          <el-date-picker v-model="searchForm.selectDate" type="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-button class="btn" type="primary" @click="search">
          查询
        </el-button>
        <el-button class="btn" @click="resetSearch">
          重置
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import { searchForm } from './object'
import { getMePlan } from '@/network/api/advManagement/advManagementBaiduPlan'
export default {
  inject: ['loadData'],
  data() {
    return {
      projectList: [],
      searchForm: { ...searchForm }
    }
  },
  mounted() {
    this.getRelationProject()
  },
  methods: {
    // 获取关联的计划
    getRelationProject() {
      getMePlan().then((res) => {
        if (res.code === 200) {
          this.projectList = res.data
        }
      })
    },

    search() {
      this.loadData(true)
    },
    // 折中方案
    getSearchForm() {
      return { ...this.searchForm }
    },
    resetSearch() {
      this.searchForm.selectDate = null
      this.$refs.searchForm.resetFields()
      this.loadData(true)
    }
  }
}
</script>
