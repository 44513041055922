<!--
 * @Descripttion: 二次封装支持模糊音搜索el-select--全局组件(投放账户)
 * @Date: 2022-10-19 17:09:08
-->
<template>
  <div class="box">
    <el-select :value="value" v-bind="$attrs" style="width: 460px" :loading="loading" :remote-method="remoteMethod" filterable clearable remote reserve-keyword v-on="$listeners">
      <el-option v-for="item in campaign_options" :key="item[props.label]" :label="item[props.label]" :value="item[props.value]"> </el-option>
    </el-select>
  </div>
</template>

<script>
import { debounce } from '@/utils/ruoyi'
import { getCampaignsSelect } from '@/network/api/account/tencentAdvAccount.js'
export default {
  name: 'SearchSelectCampaignText',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    // 需要绑定的值 等于 v-model
    value: {
      type: [String, Number],
      default: ''
    },
    accountId: {
      type: Number,
      default: 0,
      required: true
    },
    maxLength: {
      type: Number,
      default: 12
    },
    props: {
      type: Object,
      default() {
        return {
          value: 'value',
          label: 'label'
        }
      },
      required: true
    }
  },
  data() {
    return {
      campaign_options: [],
      loading: false
    }
  },

  methods: {
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        this.getSelectCampaignsFun(query)
      } else {
        this.campaign_options = []
      }
    },

    getSelectCampaignsFun: debounce(function (query) {
      const params = {
        page: 1,
        pageSize: 500,
        localAccountId: this.accountId,
        campaignId: query
      }
      getCampaignsSelect(params).then((res) => {
        if (res.code === 200) {
          this.loading = false
          this.campaign_options = res.data?.list
        }
      })
    })
  }
}
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  justify-items: start;
  align-items: center;
}
</style>
