<template>
  <div class="table-template">
    <!-- <div class="copy-type">
      <div v-for="(item, index) in copyType" :key="index" :class="['copy-type-item', item.id === copyIndex ? 'isSelect' : '']" @click="onClickSelectType(item.id)">{{ item.value }}</div>
    </div> -->
    <el-tabs v-model="copyIndex" type="card" @tab-click="onClickSelectType()">
      <el-tab-pane label="文案" :name="'1'"></el-tab-pane>
      <el-tab-pane label="文案组" :name="'2'"></el-tab-pane>
    </el-tabs>
    <div class="search-form-container">
      <el-form class="search-form search-form-4">
        <el-row :gutter="20">
          <el-col v-if="copyIndex === '1'" :span="6">
            <el-input v-model="searchForm.text" placeholder="请输入文案"></el-input>
          </el-col>
          <el-col v-if="copyIndex === '2'" :span="6">
            <el-input v-model="searchForm.textGroupName" placeholder="请输入文案组"></el-input>
          </el-col>
          <!-- 项目 -->
          <el-col :span="6">
            <el-select v-model="searchForm.projectId" filterable clearable placeholder="请选择项目" style="width: 100%;" @change="handleSelect">
              <el-option v-for="(item, index) in projectList" :key="index" :label="item.projectName" :value="item.bsProjectId"></el-option>
            </el-select>
          </el-col>
          <!-- 产品 -->
          <el-col :span="6">
            <el-select v-model="searchForm.productId" filterable :disabled="!searchForm.projectId" clearable placeholder="请选择产品" style="width: 100%;">
              <el-option v-for="(item, index) in productList" :key="index" :label="item.productName" :value="item.bsProductId"></el-option>
            </el-select>
          </el-col>
          <!--按钮-->
          <el-col :span="6">
            <!--搜索-->
            <el-button type="primary" style="width: 70px;" @click="onClickSearch">搜索</el-button>
            <!--清空-->
            <el-button type="plain" style="width: 70px;" @click="onClickReset">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- 点击按钮 -->
    <div class="flex-btn">
      <div class="left">
        <el-dropdown v-show="copyIndex === '1'" :disabled="!selectedData.length" split-button type="primary">
          批量操作
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item @click.native="onClickMoveProject">批量关联项目</el-dropdown-item> -->
            <el-dropdown-item @click.native="onClickMoveProduct">批量关联产品</el-dropdown-item>
            <el-dropdown-item @click.native="onClickCreateTxtGroup">生成文案组</el-dropdown-item>
            <el-dropdown-item @click.native="onClickBatchDel">批量删除</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown v-show="copyIndex === '2'" :disabled="!selectedGroupData.length" split-button type="primary">
          批量操作
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="onClickBatchDel">批量删除</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="right">
        <el-button v-show="copyIndex === '1'" type="primary" @click="onClickAddTxt(0)">新增文案</el-button>
        <el-button v-show="copyIndex === '2'" type="primary" @click="onClickAddTxtGroup(0)">新增文案组</el-button>
      </div>
    </div>
    <div class="page-container">
      <!--表格-->
      <template v-if="copyIndex === '1'">
        <el-table ref="table" :data="txtList" :v-loading="loading" @selection-change="onTableTextSelectionChange">
          <!--空数据提示-->
          <c-data-empty slot="empty"></c-data-empty>

          <el-table-column fixed type="selection"></el-table-column>

          <el-table-column :label="'ID'" show-overflow-tooltip :min-width="'50px'" align="center">
            <span slot-scope="{ row }">{{ row.textId }}</span>
          </el-table-column>
          <el-table-column :label="'文案'" show-overflow-tooltip :min-width="'150px'" align="center">
            <span slot-scope="{ row }">{{ row.text }}</span>
          </el-table-column>
          <el-table-column :label="'创建人'" show-overflow-tooltip :min-width="'150px'" align="center">
            <span slot-scope="{ row }">{{ row.nickname }}</span>
          </el-table-column>
          <el-table-column :label="'项目'" show-overflow-tooltip :min-width="'150px'" align="center">
            <span slot-scope="{ row }">{{ row.projectName }}</span>
          </el-table-column>
          <el-table-column :label="'产品'" show-overflow-tooltip :min-width="'150px'" align="center">
            <span slot-scope="{ row }">{{ row.productName }}</span>
          </el-table-column>
          <el-table-column :label="'创建时间'" show-overflow-tooltip :min-width="'250px'" align="center">
            <span slot-scope="{ row }">{{ row.createdAt }}</span>
          </el-table-column>

          <!--操作-->
          <el-table-column label="操作" min-width="80px" align="center" fixed="right">
            <template slot-scope="{ row }">
              <div class="opt-container">
                <a class="opt-text" href="#" @click="onClickAddTxt(row.textId)">编辑</a>
                <a class="opt-text" href="#" @click="onClickDel(row.textId)">删除</a>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template v-if="copyIndex === '2'">
        <el-table ref="table" :data="txtGroupList" :v-loading="loading" @selection-change="onTableTextGroupSelectionChange">
          <!--空数据提示-->
          <c-data-empty slot="empty"></c-data-empty>

          <el-table-column fixed type="selection"></el-table-column>

          <el-table-column :label="'ID'" show-overflow-tooltip :min-width="'50px'" align="center">
            <span slot-scope="{ row }">{{ row.textGroupId }}</span>
          </el-table-column>
          <el-table-column :label="'文案组名称'" show-overflow-tooltip :min-width="'150px'" align="center">
            <span slot-scope="{ row }">{{ row.textGroupName }}</span>
          </el-table-column>
          <el-table-column :label="'创建人'" show-overflow-tooltip :min-width="'150px'" align="center">
            <span slot-scope="{ row }">{{ row.nickname }}</span>
          </el-table-column>
          <el-table-column :label="'项目'" show-overflow-tooltip :min-width="'150px'" align="center">
            <span slot-scope="{ row }">{{ row.projectName }}</span>
          </el-table-column>
          <el-table-column :label="'产品'" show-overflow-tooltip :min-width="'150px'" align="center">
            <span slot-scope="{ row }">{{ row.productName }}</span>
          </el-table-column>
          <el-table-column :label="'文案数量'" show-overflow-tooltip :min-width="'150px'" align="center">
            <span slot-scope="{ row }">{{ row.textNum }}</span>
          </el-table-column>
          <el-table-column :label="'创建时间'" show-overflow-tooltip :min-width="'250px'" align="center">
            <span slot-scope="{ row }">{{ row.createdAt }}</span>
          </el-table-column>

          <!--操作-->
          <el-table-column label="操作" min-width="80px" align="center" fixed="right">
            <template slot-scope="{ row }">
              <div class="opt-container">
                <a class="opt-text" href="#" @click="onClickEditTxtGroup(row.textGroupId)">编辑</a>
                <a class="opt-text" href="#" @click="onClickDel(row.textGroupId)">删除</a>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <div class="pagination-container">
        <c-pagination :page-size.sync="pageSize" :page-no.sync="pageNum" :total="total" :get-data-fun="getPageData" />
      </div>
    </div>
    <!-- 新增文案 -->
    <edit-copy-dialog ref="editCopyDialog" @edit="_getTxtList"></edit-copy-dialog>
    <!-- 新增文案组 -->
    <add-copy-group-dialog ref="addCopyGroupDialog" @edit="_getTxtGrouplList"></add-copy-group-dialog>
    <!-- 编辑文案组 -->
    <edit-copy-group-dialog ref="editCopyGroupDialog" @edit="_getTxtGrouplList"></edit-copy-group-dialog>
    <!-- 关联到产品 -->
    <batch-contact-product ref="batchContactProduct" @edit="_getTxtList"></batch-contact-product>
    <!-- 关联到项目 -->
    <!-- <batch-contact-project ref="batchContactProject" @edit="_getTxtList"></batch-contact-project> -->
    <!-- 生成素材组 -->
    <create-txt-group-dialog ref="createTxtGroupDialog"></create-txt-group-dialog>
  </div>
</template>

<script>
import { getTxtList, getTxtGrouplList, delTxtGroup, delTxt, batchDelTxtGroup, batchDelTxt, getUserProduct } from '@/network/api/api-material'
import { PageCommonComponent } from '@/common/mixin'
import EditCopyDialog from '@/components/materialManagement/copyManagement/EditCopyDialog'
import EditCopyGroupDialog from '@/components/materialManagement/copyManagement/EditCopyGroupDialog'
import AddCopyGroupDialog from '@/components/materialManagement/copyManagement/AddCopyGroupDialog'
import { getMeRelationProject } from '@/network/api/basicSetting/project/basicSettingProject'
import { getProductByProject } from '@/network/api/basicSetting/product/basicSettingProduct'
// import BatchContactProject from '@/components/materialManagement/copyManagement/BatchContactProject'
import BatchContactProduct from '@/components/materialManagement/copyManagement/BatchContactProduct'
import CreateTxtGroupDialog from '@/components/materialManagement/copyManagement/CreateTxtGroupDialog'
export default {
  name: 'DocumentLibrary',
  components: {
    'edit-copy-dialog': EditCopyDialog,
    'edit-copy-group-dialog': EditCopyGroupDialog,
    'add-copy-group-dialog': AddCopyGroupDialog,
    // 'batch-contact-project': BatchContactProject,
    'batch-contact-product': BatchContactProduct,
    'create-txt-group-dialog': CreateTxtGroupDialog
  },
  mixins: [PageCommonComponent],
  data() {
    return {
      loading: false,
      // copyType: [
      //   { id: 1, value: '文案' },
      //   { id: 2, value: '文案组' }
      // ],
      copyIndex: '1', // 区分当前是文案还是文案组 1-文案 2-文案组
      searchForm: {
        projectId: '',
        productId: '',
        text: '',
        textGroupName: ''
      }, // 搜索条件
      selectedData: [], // 选中的文案数据
      selectedGroupData: [], // 选中的文案组数据
      projectList: [], // 项目列表
      productList: [], // 产品列表
      allproductList: [], // 所有产品列表
      txtList: [], // 文案列表
      txtGroupList: [], // 文案组列表
      pageNum: 1,
      pageSize: 10,
      total: 0
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this._getTxtList()
    // this._getProject()
    // this._getUserProduct()
  },
  activated() {},
  methods: {
    // 点击搜索
    onClickSearch() {
      if (this.pageNum === 1) {
        if (this.copyIndex === '1') {
          this._getTxtList()
        } else {
          this._getTxtGrouplList()
        }
      } else {
        this.pageNum = 1
      }
    },
    // 点击清空
    onClickReset() {
      this.searchForm = {
        projectId: '',
        productId: '',
        text: '',
        textGroupName: ''
      }
      this.pageNum = 1

      this.onClickSearch()
    },
    // 获取页码跳转数据
    getPageData() {
      if (this.copyIndex === '1') {
        this._getTxtList()
      } else {
        this._getTxtGrouplList()
      }
    },
    // 批量删除
    onClickBatchDel() {
      if (this.copyIndex === '1') {
        let ids = this.selectedData.map((item) => {
          return item.textId
        })
        this._batchDelTxt(ids)
      } else {
        let ids = this.selectedGroupData.map((item) => {
          return item.textGroupId
        })
        this._batchDelTxtGroup(ids)
      }
    },
    // 批量关联项目
    // onClickMoveProject () {
    //   let ids = this.selectedData.map(item => {
    //     return item.textId
    //   })
    //   this.$refs.batchContactProject.showModal(ids)
    // },
    // 关联产品
    onClickMoveProduct() {
      let ids = this.selectedData.map((item) => {
        return item.textId
      })
      this.$refs.batchContactProduct.showModal(ids)
    },
    // 批量生成文案组
    onClickCreateTxtGroup() {
      this.$refs.createTxtGroupDialog.showModal(this.selectedData)
    },
    // 选择文案/文案组
    onClickSelectType() {
      this.onClickSearch()
    },
    // 删除文案
    onClickDel(id) {
      switch (this.copyIndex) {
        case '1':
          this._delTxt(id)
          break
        case '2':
          this._delTxtGroup(id)
          break
        default:
          break
      }
    },
    // 选择文案
    onTableTextSelectionChange(selection) {
      this.selectedData = selection
    },
    // 选择文案组
    onTableTextGroupSelectionChange(selection) {
      this.selectedGroupData = selection
    },

    // 点击添加文本
    onClickAddTxt(id) {
      this.$refs.editCopyDialog.showModal(id)
    },
    // 点击添加文本组
    onClickAddTxtGroup(id) {
      this.$refs.addCopyGroupDialog.showModal(id)
    },
    // 点击编辑文本组
    onClickEditTxtGroup(id) {
      this.$refs.editCopyGroupDialog.showModal(id)
    },
    // 选择关联项目之后
    handleSelect(e) {
      if (e !== '') {
        this.searchForm.productId = ''
        this._getProduct(e)
      }
    },
    /* --------------------------- Private -------------------------- */
    // 获取项目列表(个人可见)
    async _getProject() {
      await getMeRelationProject().then((res) => {
        if (res.code === 200) {
          this.projectList = res.data
        }
      })
    },
    // 获取产品列表(个人可见)
    _getProduct(val) {
      getProductByProject(val).then((res) => {
        if (res.code === 200) {
          this.productList = res.data
        }
      })
    },
    // 获取所有产品列表
    async _getUserProduct() {
      await getUserProduct().then((res) => {
        if (res.code === 200) {
          this.allproductList = res.data
        }
      })
    },
    // 获取文本列表
    async _getTxtList() {
      this.isLoading = true
      if (this.projectList.length === 0) {
        await this._getProject()
      }
      if (this.allproductList.length === 0) {
        await this._getUserProduct()
      }
      let params = {}
      if (this.searchForm.text) {
        params.text = this.searchForm.text
      }
      if (this.searchForm.projectId) {
        params.projectId = this.searchForm.projectId
      } else {
        let projectIds = this.projectList.map((item) => {
          return item.bsProjectId
        })
        params.projectIds = projectIds
      }
      if (this.searchForm.productId) {
        params.productId = this.searchForm.productId
      }
      getTxtList(this.pageNum, this.pageSize, params)
        .then(async (res) => {
          this.txtList = res.data.list.map((item) => {
            let projectName = this.projectList.find((v) => v.bsProjectId === item.projectId)
            item.projectName = projectName ? projectName.projectName : '无'
            let productName = this.allproductList.find((v) => v.bsProductId === item.productId)
            item.productName = productName ? productName.productName : '无'
            return item
          })
          this.selectedData = []
          this.total = res.data.total
        })
        .catch((err) => {
          this.$ErrorMessage(err.data || '获取文本列表失败')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    // 获取文本组列表
    async _getTxtGrouplList() {
      this.isLoading = true
      let params = {}
      if (this.projectList.length === 0) {
        await this._getProject()
      }
      if (this.allproductList.length === 0) {
        await this._getUserProduct()
      }
      if (this.searchForm.textGroupName) {
        params.textGroupName = this.searchForm.textGroupName
      }
      if (this.searchForm.projectId) {
        params.projectId = this.searchForm.projectId
      } else {
        let projectIds = this.projectList.map((item) => {
          return item.bsProjectId
        })
        params.projectIds = projectIds
      }
      if (this.searchForm.productId) {
        params.productId = this.searchForm.productId
      }
      getTxtGrouplList(this.pageNum, this.pageSize, params)
        .then((res) => {
          this.txtGroupList = res.data.list.map((item) => {
            let projectName = this.projectList.find((v) => v.bsProjectId === item.projectId)
            item.projectName = projectName ? projectName.projectName : '无'
            let productName = this.allproductList.find((v) => v.bsProductId === item.productId)
            item.productName = productName ? productName.productName : '无'
            return item
          })
          this.total = res.data.total
          this.selectedData = []
        })
        .catch((err) => {
          this.$ErrorMessage(err.data || '获取文本组列表失败')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    // 删除文本
    _delTxt(id) {
      this.$confirm('此操作将永久删除该文本，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delTxt(id)
          .then((res) => {
            // this.$SuccessMessage('删除文本成功')
            this._getTxtList()
          })
          .catch((err) => {
            this.$ErrorMessage(err.data || '编辑文本失败')
          })
      })
    },
    // 删除文本组
    _delTxtGroup(id) {
      this.$confirm('此操作将永久删除该文本组，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delTxtGroup(id)
          .then((res) => {
            // this.$SuccessMessage('删除文本组成功')
            this._getTxtGrouplList()
          })
          .catch((err) => {
            this.$ErrorMessage(err.data || '删除文本组成功失败')
          })
      })
    },
    // 批量删除文本
    _batchDelTxt(ids) {
      this.$confirm('此操作将永久删除该文本，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        batchDelTxt({ ids: ids })
          .then((res) => {
            // this.$SuccessMessage('删除文本成功')
            this._getTxtList()
          })
          .catch((err) => {
            this.$ErrorMessage(err.data || '编辑文本失败')
          })
      })
    },
    // 删除文本组
    _batchDelTxtGroup(ids) {
      this.$confirm('此操作将批量删除该文本组，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        batchDelTxtGroup({ ids: ids })
          .then((res) => {
            // this.$SuccessMessage('删除文本组成功')
            this._getTxtGrouplList()
          })
          .catch((err) => {
            this.$ErrorMessage(err.data || '删除文本组成功失败')
          })
      })
    }
  }
}
</script>

<style lang="scss">
.table-template {
  .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-table__row {
    height: 45px;
  }

  .el-table--small td {
    padding: 3px 0;
  }

  .el-button--plain {
    border: 1px solid #dcdfe6;
  }

  .el-button--text {
    padding: 0;
    margin-top: 7px;
  }
}
</style>

<style scoped lang="scss">
@import '~@/assets/style/variables';
@import '~@/assets/style/mixin';
.el-popover {
  padding: 0 !important;
  .popover-content {
    .popover-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 36px;
      cursor: pointer;

      &:hover {
        background: #f7f5ff;
        color: $themeColor;
      }
    }
  }
}
.table-template {
  .copy-type {
    margin-bottom: 10px;
    width: 150px;
    box-sizing: border-box;
    display: flex;
    color: #909399;
    font-size: 16px;
    font-weight: 700;
    border-bottom: 1px solid #909399;
    .copy-type-item {
      margin-right: 15px;
      padding: 5px;
      cursor: pointer;
    }
    .isSelect {
      color: rgb(52, 92, 224);
      border-bottom: 2px solid rgb(52, 92, 224);
    }
  }
  .flex-btn {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .left {
      margin-right: 10px;
    }
  }
  .search-form-collapse {
    height: 0;
    overflow: hidden;
    transition: 0.2s;
  }

  .page-container {
    margin-bottom: 50px;

    .btn-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;

      .operation-btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .table-info {
          padding: 10px 0;
          color: #333a3f;
          font-size: 16px;
          margin-left: 12px;
          .number {
            color: #2842c8;
            font-size: 24px;
            margin: 0 5px;
          }
        }

        .el-button--small {
          padding: 6px 13px;
        }

        .btn-content {
          @include flex-center;
          height: 18px;

          .icon-container {
            @include flex-center;
            width: 18px;
            height: 18px;
            border-radius: 9px;
            // background: #1667FF;
            margin-right: 6px;

            img {
              width: 12px;
              height: 12px;
            }
          }
        }
      }

      .function-btn-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .function-btn {
          @include flex-center;
          width: 26px;
          height: 20px;
          background: white;
          transition: 0.2s;
          margin-left: 10px;
          position: relative;
          cursor: pointer;

          .table-columns-container {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 10;
            width: 150px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 2px 5px 0px rgba(229, 228, 234, 1);
            border-radius: 4px;
            padding-left: 10px;
            overflow: hidden;
            display: none;

            .table-columns-item {
              height: 30px;
              line-height: 30px;
            }
          }

          &:hover {
            background: #f2f2f2;

            .table-columns-container {
              display: block;
            }
          }

          img {
            width: 18px;
            height: 18px;
          }
        }
      }
    }

    .table-select-tip {
      height: 38px;
      padding-left: 17px;
      display: flex;
      align-items: center;
      color: #909399;
      font-size: 14px;
      background: #dbe9ff;
      border: 1px solid #4788ff;
      border-radius: 4px;
      img {
        width: 16px;
        height: 16px;
      }
    }

    .table-link {
      color: #606266;
      transition: 0.2s;
      text-decoration: none;

      &:hover {
        color: $themeColor;
        text-decoration: underline;
      }
    }

    .poster-container {
      @include flex-center;
      width: 36px;
      height: 36px;
      overflow: hidden;

      .poster {
        width: 36px;
        min-height: 36px;
      }
    }

    .opt-container {
      display: flex;
      justify-content: space-around;
      .opt-text {
        font-size: 14px;
      }
    }

    .cell-btn {
      font-size: 0;

      img {
        width: 18px;
        height: 18px;
      }

      .cell-btn-hover {
        display: none;
      }

      &:hover {
        .cell-btn-icon {
          display: none;
        }

        .cell-btn-hover {
          display: block;
        }
      }
    }

    .pagination-container {
      height: 64px;
      padding-right: 50px;
      box-sizing: border-box;
    }
  }
}

::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
</style>
