<!--
 * @Description  组件编辑基础样式
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2021-10-18 15:22:29
-->

<template>
  <section>
    <p class="base-title">{{ nowWidget.type }}: {{ nowWidget.title }}</p>
    <div class="dotted"></div>
    <p style="margin: 20px 0; text-align:center">基础设置</p>
    <div class="slot-body">
      <slot name="body"></slot>
    </div>
  </section>
</template>

<script>
import { widgets } from '../indexData'
export default {
  props: {
    name: {
      type: String,
      default: 'image'
    }
  },
  data() {
    return {
      widgetsList: widgets
    }
  },
  computed: {
    nowWidget() {
      return this.widgetsList.filter((f) => f.name === this.name)[0]
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.base-title {
  font-size: 18px;
  border-left: 3px solid #409eff;
  padding-left: 10px;
}
.dotted {
  border-bottom: 1px #ccc dotted;
  margin-top: 10px;
  margin-bottom: 10px;
}

.slot-body {
  & > div {
    display: flex;
    justify-content: flex-start;
    margin: 20px 0;
    align-items: center;
    & > p {
      width: 70px;
      font-size: 14px;
      color: rgb(78, 78, 78);
      text-align: right;
    }
    & > div {
      width: 80%;
    }
  }
}
</style>
