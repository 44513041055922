<!--
 * @Description: 百度程序化创意专属(后期优化)
 * @Date: 2021-10-11 13:56:59
 * @LastEditTime: 2022-05-17 09:32:37
-->
<template>
  <div class="material-video-box">
    <el-dialog
      custom-class="material-video-box"
      title="视频选择"
      :visible="visible"
      :width="`1400px`"
      top="100px"
      :before-close="close"
      :destroy-on-close="true"
      :append-to-body="true"
    >
      <!-- 内容开始 -->
      <el-row
        class="selected-img"
        :gutter="20"
      >
        <!-- 左边树形筛选框 -->
        <el-col :span="4">
          <el-col
            :span="3"
            class="tree-box"
          >
            <el-input
              v-model="filterText"
              placeholder="输入关键字进行过滤"
              size="small"
              style="width: 200px"
            > </el-input>
            <div class="tree">
              <el-tree
                ref="tree"
                :data="adAlbum"
                lazy
                :load="loadNode"
                :props="defaultProps"
                :filter-node-method="filterNode"
                @node-click="nodeClick"
              ></el-tree>
            </div>
          </el-col>
        </el-col>

        <!-- 右边内容去 -->
        <el-col :span="20">
          <!-- 右上搜索框 -->
          <el-row>
            <el-col>
              <el-form
                ref="videoQuery"
                :inline="true"
                :model="videoQuery"
                style="marginLeft: 10px"
              >
                <el-form-item label="上传人: ">
                  <el-input
                    v-model="videoQuery.nickname"
                    placeholder="请输入上传人"
                  ></el-input>
                </el-form-item>
                <el-form-item label="宽: ">
                  <el-input
                    v-model="videoQuery.width"
                    placeholder="请输入宽"
                  ></el-input>
                </el-form-item>
                <el-form-item label="高: ">
                  <el-input
                    v-model="videoQuery.height"
                    placeholder="请输入高"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    @click="onSubmit"
                  >查询</el-button>
                  <el-button @click="resetForm">重置</el-button>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>

          <!-- 右下内容选择区 -->
          <el-row :gutter="20">
            <el-col :span="18">
              <ul
                v-if="videoList.length > 0"
                v-loading="videoLoading"
                element-loading-text="视频获取中"
                style="margin: 0"
                class="select-video"
              >
                <el-row>
                  <el-col
                    v-for="item in videoList"
                    :key="item.videoId"
                    :span="6"
                  >
                    <li
                      :class="{ active: selectedVideoList.some((s) => s.videoId === item.videoId) }"
                      @click="selectVideo(item)"
                    >
                      <div class="video-div">
                        <video :src="item.url"></video>
                      </div>
                      <div
                        class="attachments"
                        :class="{ 'success-color': successColor(item) }"
                      >
                        <p>{{ item.materialName }}</p>
                        <div>
                          <p>{{ item.width }}px * {{ item.height }}px</p>
                          <p>{{ (item.size / 1024 / 1024).toFixed(2) }}MB</p>
                        </div>
                      </div>
                      <i class="el-icon-success"></i>
                    </li>
                  </el-col>
                </el-row>
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="videoListTotal"
                  style="text-align: right;"
                  :current-page.sync="videoQuery.pageNum"
                  :page-size="8"
                  @current-change="currentChange"
                  @prev-click="currentChange"
                  @next-click="currentChange"
                > </el-pagination>
              </ul>
              <ul
                v-else
                style="margin: 0"
                class="select-img"
              >
                <el-empty description="暂无数据"></el-empty>
              </ul>
            </el-col>
            <el-col :span="6">
              <el-row class="selected-video">
                <template v-if="isBatch">
                  <el-col v-for="(item, index) in new Array(videoCount)" :key="index" :span="12">
                    <section class="selected-video-item">
                      <div>
                        <template>
                          <el-avatar v-if="!(selectedVideoList[index] && selectedVideoList[index].videoUrl)" shape="square" :size="100" class="avatar" icon="el-icon-video-camera-solid"></el-avatar>
                          <div v-else class="avatar-video">
                            <video style="width: 100%; height: 100%" :src="selectedVideoList[index] && selectedVideoList[index].videoUrl"></video>
                          </div>
                        </template>
                        <div class="avatar-mask">
                          <p @click="handleImg(index, 'updata')">替换</p>
                          <p @click="handleImg(index, 'delete')">删除</p>
                        </div>
                      </div>
                    </section>
                  </el-col>
                </template>
                <template v-else>
                  <el-col v-for="(item, index) in selectedVideoList" :key="index" :span="12">
                    <section class="selected-video-item">
                      <div>
                        <template>
                          <!-- <el-avatar v-if="!(selectedVideoList[index] && selectedVideoList[index].videoUrl)" shape="square" :size="100" class="avatar" icon="el-icon-video-camera-solid"></el-avatar> -->
                          <div class="avatar-video">
                            <video style="width: 100%; height: 100%" :src="item && item.videoUrl"></video>
                          </div>
                        </template>
                        <div class="avatar-mask">
                          <p @click="handleImg(index, 'updata')">替换</p>
                          <p @click="handleImg(index, 'delete')">删除</p>
                        </div>
                      </div>
                    </section>
                  </el-col>
                </template>
              </el-row>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <!-- 内容结束 -->
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="close">取 消</el-button>
        <el-button
          type="primary"
          @click="select"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getMaterialList_video } from '@/network/api/advManagement/advManagementBaiduCreativeFend'
import { getAdAlbum, getAdMaterialGroup } from '@/network/api/account/materents'
export default {
  props: {
    name: {
      type: String,
      default: 'video'
    },
    visible: {
      type: Boolean,
      default: false
    },
    // 最多上传数量
    imgCount: {
      type: Number,
      default: 6
    },
    // 已上传视频
    videoListed: {
      type: Array,
      default: () => []
    },
    // 图片属性用作验证图片是否符合

    // key: 'topImg', // 唯一key
    // value: '顶部视频',  //唯一name
    // count: 1, // 本次最多上传几个
    // size: 100 * 1024 * 1024,
    // listType: 'image/jpg,image/jpeg,image/png',
    // width: 640,
    // height: 480,
    // fixedHeight: [480, 360]  // 固定高度,当有超过一个固定高度时调用

    attribute: {
      type: Object,
      default: () => {}
    },
    // 项目id
    projectId: {
      type: Array,
      default: () => []
    },
    // 是否批量添加
    isBatch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedVideoList: [], // 选中的图片
      updateVideoIndex: -1, // 替换图片index
      UPLOAD_VIDEO_URL: process.env.VUE_APP_UPLOAD_VIDEO_URL,
      innerVisible: false,
      videoList: [], // 图片列表
      videoListTotal: 0, // 图片数量
      videoCount: 0,
      adAlbum: [], // 专辑名称
      filterText: '', // 筛选专辑
      defaultProps: {
        children: 'children',
        label: 'label',
        isLeaf: 'leaf'
      },
      videoLoading: false, // 图片加载
      videoQuery: {
        // 图片搜索条件
        nickname: '',
        albumId: 0,
        width: null,
        height: null,
        pageNum: 1,
        type: 'video'
      }
    }
  },
  watch: {
    visible: {
      handler() {
        this.selectedVideoList = JSON.parse(JSON.stringify(this.videoListed))
        if (!this.isBatch) {
          this.videoCount = this.imgCount
        } else {
          this.videoCount = 0
        }
      },
      immediate: true
    },
    // 筛选专辑
    filterText(val) {
      this.$refs.tree.filter(val)
    },

    attribute: {
      handler(val) {
        this.videoQuery.width = typeof val.width === 'number' ? val.width : ''
        this.videoQuery.height = typeof val.height === 'number' ? val.height : ''
      }
    }
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    /**
     * @description: 点击树形选择调用
     * @param {*} value
     * @return {*}
     */
    nodeClick(value) {
      const { albumId, materialGroupId, typeName } = value
      this.videoQuery.albumId = albumId
      this.videoQuery.materialGroupId = typeName === 'materialGroup' ? materialGroupId : null
      // this.imageQuery.materialGroupId = materialGroupId
      this.videoQuery.pageNum = 1
      this.getMaterialListFun()
    },
    /**
     * @description: 获取tree组件数据
     * @param {*} node 上下文
     * @param {*} resolve 设置数据
     */
    loadNode(node, resolve) {
      // 获取专辑
      if (node.level === 0) {
        getAdAlbum({ type: 'video', projectIds: this.projectId }).then(({ code, data }) => {
          if (code === 200) {
            data.forEach((f) => {
              f.label = f.albumName
              f.typeName = 'album'
            })
            return resolve(data)
          }
        })
      }
      // 获取素材组
      const { albumId } = node.data
      if (node.level === 1) {
        getAdMaterialGroup({ type: 'video', albumId }).then(({ code, data }) => {
          if (code === 200) {
            data &&
              data.forEach((f) => {
                f.label = f.materialGroupName
                f.leaf = true
                f.typeName = 'materialGroup'
              })
            return resolve(data)
          } else {
            return resolve([])
          }
        })
      }
    },
    /**
     * @description: 获取视频列表
     * @param {*} current 当前页
     */
    getMaterialListFun() {
      this.videoLoading = true
      getMaterialList_video(this.videoQuery).then((res) => {
        if (res.code === 200) {
          this.videoList = res.data.list
          this.videoListTotal = res.data.total
          this.videoLoading = false
        }
      })
    },

    /**
     * @description: 查询图片
     */
    onSubmit() {
      this.getMaterialListFun()
    },

    /**
     * @description: 重置
     */
    resetForm() {
      this.$refs['videoQuery'].resetFields()
      this.videoQuery.nickname = ''
      this.videoQuery.width = null
      this.videoQuery.height = null
      this.videoQuery.pageNum = 1
      this.getMaterialListFun()
    },

    /**
     * @description: 切换分页
     * @param {*} current 当前页
     */
    currentChange(current) {
      this.getMaterialListFun(current)
    },

    successColor(item) {
      const { size, width, height, value } = this.attribute
      if (size < item.size / 1024) {
        return false
      } else if (value === '横版视频4:3') {
        if (!(item.width / item.height === 4 / 3) || item.width < 1280 || item.height < 960) {
          return false
        } else {
          return true
        }
      } else if (value === '横版视频') {
        if (!(item.width / item.height === 16 / 9) || item.width < 1280 || item.height < 720) {
          return false
        } else {
          return true
        }
      } else if (value === '竖版视频') {
        if (!(item.width / item.height === 9 / 16) || item.width < 720 || item.height < 1280) {
          return false
        } else {
          return true
        }
      } else if (width !== item.width || height !== item.height) {
        return false
      } else {
        return true
      }
    },

    // 验证图片是否合规
    verification(info) {
      const { size, width, height, value } = this.attribute
      if (size < info.size / 1024) {
        return `视频不能大于${size}KB`
      } else if (['videoUrl_v'].includes(this.name) && (info.width !== width || info.height !== height)) {
        return `视频尺寸不符合`
      } else if (!(info.width / info.height === 4 / 3 || info.width / info.height === 16 / 9 || info.width / info.height === 9 / 16)) {
        return `视频尺寸比应该为16:9或 9:16或4:3`
      } else if (value === '横版视频4:3') {
        if (!(info.width / info.height === 4 / 3)) {
          return `横版视频尺寸比应该为4 : 3`
        } else if (info.width < 1280 || info.height < 960) {
          return `横版视频最小尺寸应该为 1280px * 960px`
        } else {
          return ''
        }
      } else if (value === '横版视频') {
        if (!(info.width / info.height === 16 / 9)) {
          return `横版视频尺寸比应该为16 : 9`
        } else if (info.width < 1280 || info.height < 720) {
          return `横版视频最小尺寸应该为 1280px * 720px`
        } else {
          return ''
        }
      } else if (value === '竖版视频') {
        if (!(info.width / info.height === 9 / 16)) {
          return `竖版视频尺寸比应该为9 : 16`
        } else if (info.width < 720 || info.height < 1280) {
          return `竖版视频最小尺寸应该为 720px * 1280px`
        } else {
          return ''
        }
      } else {
        return ''
      }
    },

    // 选择视频
    selectVideo(item) {
      const { selectedVideoList, updateVideoIndex, attribute } = this
      const massage = this.verification(item)
      if (massage) return this.$message.error(massage)
      // 替换视频
      if (updateVideoIndex !== -1) {
        selectedVideoList.splice(updateVideoIndex, 1, { videoUrl: item.url, videoId: +item.videoId, ...item })
      } else {
        // 添加视频
        const video = {
          videoUrl: item.url,
          videoId: +item.videoId,
          materialName: item.materialName,
          width: item.width,
          height: item.height,
          poster: []
        }
        if (!selectedVideoList.some((s) => s.videoId === video.videoId)) {
          if (this.isBatch) {
            selectedVideoList.push(video)
          } else {
            if (this.imgCount === 1) {
              this.selectedVideoList = [video]
              return
            }
            if (selectedVideoList.length >= attribute.count) {
              this.$message.warning(`视频最多选择${attribute.count}个`)
            } else {
              selectedVideoList.push(video)
            }
          }
        } else {
          selectedVideoList.splice(
            selectedVideoList.findIndex((s) => s.videoId === video.videoId),
            1
          )
        }
      }
      this.videoCount = selectedVideoList.length
      this.updateVideoIndex = -1
    },

    // 删除/替换视频
    handleImg(index, type) {
      if (!this.selectedVideoList.length) return this.$message.error('请先选择视频!')
      if (type === 'delete') {
        this.selectedVideoList.splice(index, 1)
        this.videoCount = this.selectedVideoList.length
      } else {
        this.updateVideoIndex = index
        this.$message.success('请选择要替换的视频!')
      }
    },
    // 关闭弹框
    close() {
      this.$emit('setMaterialBox')
    },

    select() {
      let videos = [...this.selectedVideoList]
      this.$emit('handleVideoSuccess', videos)
      this.close()
    }
  }
}
</script>

<style scoped lang="scss">
.material-video-box {
  .success-color {
    color: #409eff !important;
  }
  .selected-video {
    height: 439px;
    overflow-y: auto;
    .selected-video-item {
      width: 100px;
      height: 100px;
      .avatar-video {
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        margin-top: 10px;
        background: #c0c4cc;
        border-radius: 4px;
        video {
          width: 100%;
        }
      }
      & > div {
        position: relative;
        .avatar-mask {
          width: 100px;
          height: 100px;
          background: rgba(0, 0, 0, 0.507);
          position: absolute;
          top: 0px;
          left: 10px;
          border-radius: 4px;
          display: none;
          p {
            margin-top: 20px;
            color: #fff;
            text-align: center;
            cursor: pointer;
          }
        }
        &:hover .avatar-mask {
          display: block;
        }
      }
    }
    .avatar {
      margin: 10px;
    }
  }
  .tree-box {
    width: 100%;
    height: 500px;
    .tree {
      width: 100%;
      height: 460px;
      margin-top: 10px;
      overflow-y: auto;
    }
  }
  .select-video {
    li {
      border: 1px solid #ddd;
      margin: 10px;
      padding: 7px 1px 6px 1px;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      .video-div {
        background: #00000014;
      }
      video {
        width: 100%;
        height: 160px;
        margin: 0;
        display: flex;
        justify-content: center;
        background: none;
      }
      .attachments {
        padding: 5px;
        margin-top: 2px;
        height: 80px;
        > div {
          display: flex;
          justify-content: space-between;
          margin-top: 8px;
        }
        p {
          padding: 0;
          margin: 0;
        }
      }
      .el-icon-success {
        position: absolute;
        top: -10px;
        left: -10px;
        font-size: 20px;
        color: #409eff;
        display: none;
        background: #fff;
      }
    }
    .active {
      border-color: #409eff;
      .el-icon-success {
        display: block;
      }
    }
  }
}
</style>
