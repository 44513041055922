export const searchForm = {
  selectDate: null,
  baiduUnitId: null,
  adgroupFeedId: null,
  baiduAdvPlanId: null,
  campaignFeedId: null,
  adgroupFeedName: null,
  pause: null,
  status: null,
  audience: null,
  bid: null,
  producttypes: null,
  ftypes: null,
  bidtype: null,
  ocpc: null,
  atpfeedid: null,
  deleted: null,
  createBy: null,
  createTime: null,
  updateBy: null,
  updateTime: null,
  remark: null
}

export class AdvManagementBaiduUnitEnt {
  baiduAdvPlanId
  baiduUnitId
  adgroupFeedId
  campaignFeedId
  adgroupFeedName
  pause
  status
  audience
  bid
  producttypes
  ftypes
  bidtype
  ocpc
  atpfeedid
  deleted
  createBy
  createTime
  updateBy
  updateTime
  remark
}

export const formRules = {
  baiduUnitId: [{ required: true, message: '请填写', trigger: 'change' }],
  adgroupFeedId: [{ required: true, message: '请填写推广单元ID', trigger: 'change' }],
  campaignFeedId: [{ required: true, message: '请填写推广计划ID', trigger: 'change' }],
  adgroupFeedName: [{ required: true, message: '请填写推广单元名称 ', trigger: 'change' }],
  pause: [{
    required: true, message: '请填写暂停/启用推广单元 ', trigger: 'change'
  }],
  status: [{
    required: true, message: '请填写推广单元状态 ', trigger: 'change'
  }],
  audience: [{ required: true, message: '请填写定向设置	。', trigger: 'change' }],
  bid: [{
    required: true, message: '请填写出价为元，可精确到分。', trigger: 'change'
  }],
  producttypes: [{
    required: true, message: '请填写投放版位	', trigger: 'change'
  }],
  ftypes: [{
    required: true, message: '请填写投放范围  ', trigger: 'change'
  }],
  bidtype: [{
    required: true, message: '请填写默认值', trigger: 'change'
  }],
  ocpc: [{ required: true, message: '请填写oCPC信息	 本字段只有当bidtype=3有效。请参考oCPC设置对象', trigger: 'change' }],
  atpfeedid: [{ required: true, message: '请填写定向包ID 定向包的优先级高于audience字段设置的定向信息。已绑定定向包单元解除绑定需传0', trigger: 'change' }],
  deleted: [{ required: true, message: '请填写逻辑删除', trigger: 'change' }],
  createBy: [{ required: true, message: '请填写创建者', trigger: 'change' }],
  createTime: [{ required: true, message: '请填写创建时间', trigger: 'change' }],
  updateBy: [{ required: true, message: '请填写更新者', trigger: 'change' }],
  updateTime: [{ required: true, message: '请填写更新时间', trigger: 'change' }],
  remark: [{ required: true, message: '请填写备注', trigger: 'change' }]
}
