var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      attrs: {
        title: "广告基础信息",
        size: "900px",
        "append-to-body": true,
        "custom-class": "dialog",
        visible: _vm.show,
        "label-position": "right",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.onCloseDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "drawer-content" },
        [
          _c("div", { staticClass: "title" }, [_vm._v("营销场景")]),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "营销链路", prop: "marketingPurpose" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { disabled: true },
                      model: {
                        value: _vm.form.marketingPurpose,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "marketingPurpose", $$v)
                        },
                        expression: "form.marketingPurpose",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "行动转化", value: "CONVERSION" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "计划名称", prop: "planNameTemplate" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      placeholder: "请输入计划名称",
                      maxlength: "100",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.form.planNameTemplate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "planNameTemplate", $$v)
                      },
                      expression: "form.planNameTemplate",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "tip" }, [
                _vm._v("通配符 "),
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.onClickSpecialChar("日期")
                      },
                    },
                  },
                  [_vm._v("+日期")]
                ),
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.onClickSpecialChar("产品名")
                      },
                    },
                  },
                  [_vm._v("+产品名")]
                ),
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.onClickSpecialChar("投手名称")
                      },
                    },
                  },
                  [_vm._v("+投手名称")]
                ),
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.onClickSpecialChar("标号")
                      },
                    },
                  },
                  [_vm._v("+标号")]
                ),
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.onClickSpecialChar("素材名")
                      },
                    },
                  },
                  [_vm._v("+素材名")]
                ),
              ]),
              _c("div", { staticClass: "title" }, [_vm._v("投放内容")]),
              _c(
                "el-form-item",
                { attrs: { label: "数据对接方式", prop: "trackType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      attrs: { prop: "trackType" },
                      model: {
                        value: _vm.form.trackType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "trackType", $$v)
                        },
                        expression: "form.trackType",
                      },
                    },
                    _vm._l(_vm.trackTypes, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.text, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.form.trackType == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "落地页类型", prop: "pageType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "300px" },
                          model: {
                            value: _vm.form.pageType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "pageType", $$v)
                            },
                            expression: "form.pageType",
                          },
                        },
                        _vm._l(_vm.pageTypes, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.text, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.trackType == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "推广内容", prop: "contentType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "300px" },
                          model: {
                            value: _vm.form.contentType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "contentType", $$v)
                            },
                            expression: "form.contentType",
                          },
                        },
                        _vm._l(_vm.landTypes, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.text, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.form.trackType == 2 && _vm.form.contentType == "MINI_PROGRAME"
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "小程序类型", prop: "appType" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "300px" },
                            model: {
                              value: _vm.form.appType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "appType", $$v)
                              },
                              expression: "form.appType",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "字节小程序", value: 1 },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "小程序APPID", prop: "appId" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "300px" },
                          attrs: { placeholder: "请输入小程序APPID" },
                          model: {
                            value: _vm.form.appId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "appId", $$v)
                            },
                            expression: "form.appId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "小程序链接", prop: "appUrl" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "300px" },
                          attrs: { placeholder: "请输入小程序链接" },
                          model: {
                            value: _vm.form.appUrl,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "appUrl", $$v)
                            },
                            expression: "form.appUrl",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "启动页面", prop: "startPage" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "300px" },
                          attrs: { placeholder: "请输入启动页面" },
                          model: {
                            value: _vm.form.startPage,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "startPage", $$v)
                            },
                            expression: "form.startPage",
                          },
                        }),
                        _vm.showErrorTip.page
                          ? _c("span", { staticClass: "error-tip" }, [
                              _vm._v(_vm._s(_vm.showErrorTip.page)),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "启动参数", prop: "startParams" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "300px" },
                          attrs: { placeholder: "请输入启动参数" },
                          model: {
                            value: _vm.form.startParams,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "startParams", $$v)
                            },
                            expression: "form.startParams",
                          },
                        }),
                        _vm.showErrorTip.params
                          ? _c("span", { staticClass: "error-tip" }, [
                              _vm._v(_vm._s(_vm.showErrorTip.params)),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _c("el-form-item", { attrs: { label: "优化目标" } }, [
                _c(
                  "div",
                  { staticClass: "target" },
                  [
                    _vm.form.trackType == 1
                      ? _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-top": "40px" },
                            attrs: {
                              label: "转化追踪方式",
                              prop: "convertSourceType",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "300px" },
                                model: {
                                  value: _vm.form.convertSourceType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "convertSourceType", $$v)
                                  },
                                  expression: "form.convertSourceType",
                                },
                              },
                              _vm._l(
                                _vm.convertSourceTypes,
                                function (itm, idx) {
                                  return _c("el-option", {
                                    key: idx,
                                    attrs: { label: itm.text, value: itm.id },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-top": "40px" },
                        attrs: { label: "转化目标选择", prop: "convertType" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "300px" },
                            model: {
                              value: _vm.form.convertType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "convertType", $$v)
                              },
                              expression: "form.convertType",
                            },
                          },
                          _vm._l(_vm.convertTypes, function (itm, idx) {
                            return _c("el-option", {
                              key: idx,
                              attrs: { label: itm.text, value: itm.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "title" }, [_vm._v("投放范围")]),
              _c(
                "el-form-item",
                { attrs: { label: "广告位置", prop: "inventoryCatalog" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      model: {
                        value: _vm.form.inventoryCatalog,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "inventoryCatalog", $$v)
                        },
                        expression: "form.inventoryCatalog",
                      },
                    },
                    _vm._l(_vm.inventoryCatalogs, function (itm, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { label: itm.text, value: itm.id },
                      })
                    }),
                    1
                  ),
                  _vm.form.inventoryCatalog === "MANUAL"
                    ? _c("div", { staticClass: "media" }, [
                        _c("div", { staticClass: "media-title" }, [
                          _vm._v("首选媒体"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "media-content" },
                          [
                            _c(
                              "div",
                              { staticClass: "media-content-item" },
                              [
                                _c(
                                  "el-checkbox",
                                  {
                                    attrs: {
                                      indeterminate: _vm.isIndeterminate,
                                    },
                                    on: { change: _vm.handleCheckAllChange },
                                    model: {
                                      value: _vm.isSelectAll,
                                      callback: function ($$v) {
                                        _vm.isSelectAll = $$v
                                      },
                                      expression: "isSelectAll",
                                    },
                                  },
                                  [_vm._v("全选 ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-checkbox-group",
                              {
                                on: { change: _vm.handleCheckedChange },
                                model: {
                                  value: _vm.checkedMediaes,
                                  callback: function ($$v) {
                                    _vm.checkedMediaes = $$v
                                  },
                                  expression: "checkedMediaes",
                                },
                              },
                              _vm._l(_vm.mediaTypes, function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "media-content-item",
                                  },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        key: item.key,
                                        attrs: { label: item.key },
                                      },
                                      [_vm._v(_vm._s(item.value))]
                                    ),
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "搜索快投", prop: "feedDeliverySearch" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      model: {
                        value: _vm.form.feedDeliverySearch,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "feedDeliverySearch", $$v)
                        },
                        expression: "form.feedDeliverySearch",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "不启用", value: "DISABLED" },
                      }),
                      _c("el-option", {
                        attrs: { label: "启用", value: "HAS_OPEN" },
                      }),
                    ],
                    1
                  ),
                  _vm.form.feedDeliverySearch === "HAS_OPEN"
                    ? _c("span", { staticStyle: { "margin-left": "25px" } }, [
                        _c("span", [
                          _vm._v(
                            "已添加" +
                              _vm._s(_vm.form.searchKeyWord.length || 0) +
                              "个关键字"
                          ),
                        ]),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "margin-left": "10px",
                              "font-size": "14px",
                              color: "#409EFF",
                              cursor: "pointer",
                            },
                            on: { click: _vm.handleEditKeyword },
                          },
                          [_vm._v("编辑")]
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm.form.feedDeliverySearch === "HAS_OPEN"
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "出价系数", prop: "searchBidRatio" } },
                      [
                        _c("el-input-number", {
                          attrs: {
                            label: "请输入出价系数",
                            min: 1,
                            max: 2,
                            step: 0.1,
                            precision: 2,
                          },
                          model: {
                            value: _vm.form.searchBidRatio,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "searchBidRatio", $$v)
                            },
                            expression: "form.searchBidRatio",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "定向拓展", prop: "audienceExtend" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "300px" },
                            model: {
                              value: _vm.form.audienceExtend,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "audienceExtend", $$v)
                              },
                              expression: "form.audienceExtend",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "不启用", value: "OFF" },
                            }),
                            _c("el-option", {
                              attrs: { label: "启用", value: "ON" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _c("div", { staticClass: "title" }, [_vm._v("预算与出价")]),
              _c(
                "el-form-item",
                { attrs: { label: "投放场景", prop: "smartBidType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "300px" },
                      model: {
                        value: _vm.form.smartBidType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "smartBidType", $$v)
                        },
                        expression: "form.smartBidType",
                      },
                    },
                    _vm._l(_vm.smartBidTypes, function (itm, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: { label: itm.text, value: itm.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.form.smartBidType == 1
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "竞价策略", prop: "flowControlMode" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "300px" },
                            model: {
                              value: _vm.form.flowControlMode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "flowControlMode", $$v)
                              },
                              expression: "form.flowControlMode",
                            },
                          },
                          _vm._l(_vm.flowControlTypes, function (itm, idx) {
                            return _c("el-option", {
                              key: idx,
                              attrs: { label: itm.text, value: itm.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.form.flowControlMode == 1
                      ? _c(
                          "el-form-item",
                          {
                            attrs: { label: "投放速度", prop: "flowSpeedMode" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "300px" },
                                model: {
                                  value: _vm.form.flowSpeedMode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "flowSpeedMode", $$v)
                                  },
                                  expression: "form.flowSpeedMode",
                                },
                              },
                              _vm._l(
                                _vm.flowSpeedModeTypes,
                                function (itm, idx) {
                                  return _c("el-option", {
                                    key: idx,
                                    attrs: { label: itm.text, value: itm.id },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "预算", prop: "budgetMode" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "180px", "margin-right": "20px" },
                      model: {
                        value: _vm.form.budgetMode,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "budgetMode", $$v)
                        },
                        expression: "form.budgetMode",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "日预算", value: "BUDGET_MODE_DAY" },
                      }),
                      _c("el-option", {
                        attrs: { label: "总预算", value: "BUDGET_MODE_TOTAL" },
                      }),
                    ],
                    1
                  ),
                  _c("el-input-number", {
                    attrs: {
                      precision: 2,
                      min: 300,
                      step: 100,
                      placeholder: "请填写预算金额",
                    },
                    model: {
                      value: _vm.form.budget,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "budget", $$v)
                      },
                      expression: "form.budget",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "投放时间", prop: "scheduleType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "180px", "margin-right": "20px" },
                      model: {
                        value: _vm.form.scheduleType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "scheduleType", $$v)
                        },
                        expression: "form.scheduleType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          label: "从今天到长期投放",
                          value: "SCHEDULE_FROM_NOW",
                        },
                      }),
                      _c("el-option", {
                        attrs: {
                          label: "设置开始和结束日期",
                          value: "SCHEDULE_START_END",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.form.scheduleType === "SCHEDULE_START_END"
                    ? [
                        _c("el-date-picker", {
                          staticStyle: { width: "400px" },
                          attrs: {
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            type: "datetimerange",
                            "range-separator": "-",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                          },
                          on: { change: _vm.handleChangeDate },
                          model: {
                            value: _vm.date,
                            callback: function ($$v) {
                              _vm.date = $$v
                            },
                            expression: "date",
                          },
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "el-form-item",
                { attrs: { label: "投放时段", prop: "scheduleRangeType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "180px" },
                      model: {
                        value: _vm.form.scheduleRangeType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "scheduleRangeType", $$v)
                        },
                        expression: "form.scheduleRangeType",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "不限", value: 1 } }),
                      _c("el-option", {
                        attrs: { label: "指定时段", value: 2 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.form.scheduleRangeType === 2
                ? _c(
                    "div",
                    { staticClass: "time-range-box" },
                    [
                      _c("schedule", {
                        on: { change: _vm.getDate },
                        model: {
                          value: _vm.form.scheduleRange,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "scheduleRange", $$v)
                          },
                          expression: "form.scheduleRange",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "付费方式", prop: "pricing" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "180px" },
                      model: {
                        value: _vm.form.pricing,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "pricing", $$v)
                        },
                        expression: "form.pricing",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: {
                          label: "按展示付费(oCPM)",
                          value: "PRICING_OCPM",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.form.smartBidType !== 3
                ? _c(
                    "el-form-item",
                    { attrs: { label: "转化出价", prop: "cpaBid" } },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "180px" },
                        attrs: { min: 0.1, max: 10000 },
                        model: {
                          value: _vm.form.cpaBid,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "cpaBid", _vm._n($$v))
                          },
                          expression: "form.cpaBid",
                        },
                      }),
                      _vm._v(" 元 "),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "title" }, [_vm._v("优化设置")]),
              _c(
                "el-form-item",
                { attrs: { label: "继承优质计划", prop: "autoInheritSwitch" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "180px" },
                      model: {
                        value: _vm.form.autoInheritSwitch,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "autoInheritSwitch", $$v)
                        },
                        expression: "form.autoInheritSwitch",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "不启用", value: "OFF" },
                      }),
                      _c("el-option", {
                        attrs: { label: "启用", value: "ON" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.form.autoInheritSwitch === "ON"
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "继承账户类型", prop: "inheritType" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "180px" },
                            model: {
                              value: _vm.form.inheritType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "inheritType", $$v)
                              },
                              expression: "form.inheritType",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: {
                                label: "账户内计划",
                                value: "INHERIT_FROM_ACCOUNT",
                              },
                            }),
                            _c("el-option", {
                              attrs: {
                                label: "同组织下其他广告主",
                                value: "INHERIT_FROM_CUSTOMER",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.form.inheritType === "INHERIT_FROM_CUSTOMER"
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "账户ID",
                              prop: "inheritedAdvertiserId",
                            },
                          },
                          [
                            _c("el-input", {
                              staticStyle: { width: "300px" },
                              attrs: {
                                rows: 4,
                                type: "textarea",
                                resize: "none",
                                placeholder: "请输入账户ID，回车换行",
                              },
                              model: {
                                value: _vm.form.inheritedAdvertiserId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "inheritedAdvertiserId",
                                    $$v
                                  )
                                },
                                expression: "form.inheritedAdvertiserId",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c("div", { staticClass: "dialog-footer" }, [
        _c(
          "div",
          [
            _c(
              "el-checkbox",
              {
                model: {
                  value: _vm.form.saveAsPreset,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "saveAsPreset", $$v)
                  },
                  expression: "form.saveAsPreset",
                },
              },
              [_vm._v("存为预设")]
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-button",
              { staticClass: "btn", on: { click: _vm.closeModal } },
              [_vm._v("取 消")]
            ),
            _c(
              "el-button",
              {
                staticClass: "btn",
                attrs: {
                  type: "primary",
                  loading: _vm.isLoading,
                  disabled: _vm.isLoading,
                },
                on: {
                  click: function ($event) {
                    return _vm.handleConfirm("form")
                  },
                },
              },
              [_vm._v("确定 ")]
            ),
          ],
          1
        ),
      ]),
      _c("edit-keyword-dialog", {
        ref: "editKeywordDialog",
        on: { confirm: _vm.handleKeywordData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }