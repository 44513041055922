var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "element-library-box",
      attrs: {
        title: "添加元素",
        visible: _vm.show,
        "append-to-body": true,
        width: "1200px",
        top: "20vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.onCloseDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "page-container unselected-all-table" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { staticClass: "middle-content", attrs: { span: 17 } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "search",
                      staticClass: "search-form search-form-4",
                      attrs: { inline: true, model: _vm.search },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "名称", prop: "name" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "100%" },
                                    attrs: { placeholder: "请输入名称" },
                                    model: {
                                      value: _vm.search.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.search, "name", $$v)
                                      },
                                      expression: "search.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "元素类型", prop: "typeId" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      model: {
                                        value: _vm.search.typeId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.search, "typeId", $$v)
                                        },
                                        expression: "search.typeId",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "全部", value: "" },
                                      }),
                                      _vm._l(_vm.elementTypes, function (item) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            label: item.name,
                                            value: item.id,
                                          },
                                        })
                                      }),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", round: "" },
                                      on: { click: _vm.onSubmit },
                                    },
                                    [_vm._v("查 询")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", round: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.resetForm("ruleForm")
                                        },
                                      },
                                    },
                                    [_vm._v("重 置")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      ref: "multipleTable",
                      class: {
                        "no-data": !_vm.tableData || !_vm.tableData.length,
                      },
                      attrs: {
                        data: _vm.tableData,
                        "row-key": (row) => {
                          return row.id
                        },
                      },
                      on: { select: _vm.handleCurrentChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", "reserve-selection": true },
                      }),
                      _c("el-table-column", {
                        attrs: { type: "index", label: "序号", width: "50" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "name",
                          label: "元素名称",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "typeName",
                          label: "元素类型",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { prop: "url", label: "预览", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                row.baseType === "IMG"
                                  ? _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "right",
                                          trigger: "hover",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "element-pop-img" },
                                          [
                                            _c("el-image", {
                                              attrs: {
                                                src: row.url,
                                                fit: "contain",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "element-img",
                                            attrs: { slot: "reference" },
                                            slot: "reference",
                                          },
                                          [
                                            _c("el-image", {
                                              attrs: {
                                                src: row.url,
                                                fit: "contain",
                                                lazy: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                row.baseType === "VIDEO"
                                  ? _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "right",
                                          trigger: "hover",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "element-pop-img" },
                                          [
                                            _c("video", {
                                              attrs: {
                                                src: row.url,
                                                controls: "",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "element-img",
                                            attrs: { slot: "reference" },
                                            slot: "reference",
                                          },
                                          [
                                            _c("video", {
                                              attrs: { src: row.url },
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "pagination-container" },
                    [
                      _c("c-pagination", {
                        attrs: {
                          "page-size": _vm.pageSize,
                          "page-no": _vm.pageNum,
                          "pager-count": 5,
                          total: _vm.total,
                          tools: "total, prev, pager, next",
                          "get-data-fun": _vm.getPageData,
                        },
                        on: {
                          "update:pageSize": function ($event) {
                            _vm.pageSize = $event
                          },
                          "update:page-size": function ($event) {
                            _vm.pageSize = $event
                          },
                          "update:pageNo": function ($event) {
                            _vm.pageNum = $event
                          },
                          "update:page-no": function ($event) {
                            _vm.pageNum = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 7 } }, [
                _c("div", { staticClass: "comp-selected-box page-name" }, [
                  _c("div", { staticClass: "comp-title" }, [
                    _vm._v("已选元素"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "comp-selected-content" },
                    _vm._l(_vm.selectedData, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "comp-selected-item" },
                        [
                          [
                            _c("span", { staticClass: "comp-name" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                            _c("span", {
                              staticClass: "el-icon-close comp-del-icon",
                              on: {
                                click: function ($event) {
                                  return _vm.handleDeleteSelected(item, index)
                                },
                              },
                            }),
                          ],
                        ],
                        2
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "btn", on: { click: _vm.closeModal } },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }