<template>
  <div>
    <Search ref="search" />
    <!-- <el-row type="flex" justify="space-between" class="operate-box">
      <el-button type="primary" icon="el-icon-plus" round @click="showAdd">新 增</el-button>
    </el-row> -->
    <div class="table-container">
      <el-table v-loading="loading" stripe class="list-table" :data="data">
        <c-data-empty slot="empty" />
        <el-table-column type="index" label="序号" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="apiPlanName" width="200" label="模板计划名称" align="left" show-overflow-tooltip />
        <el-table-column prop="launchPlatform" label="投放平台" align="left" show-overflow-tooltip />
        <el-table-column prop="launchAccountName" label="账户管家" align="left" show-overflow-tooltip />
        <el-table-column prop="accountName" label="投放账户" align="left" show-overflow-tooltip />
        <el-table-column prop="publishStatus" label="发布状态" align="left" :formatter="formatterPublishStatus" show-overflow-tooltip />
        <el-table-column prop="launcheStatus" label="投放状态" align="left" :formatter="formatterLauncheStatus" show-overflow-tooltip />
        <el-table-column prop="apiPlanId" label="计划id" align="left" show-overflow-tooltip />
        <el-table-column prop="planLog" label="计划日志" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="unitLog" label="单元日志" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="creativeLog" label="创意日志" align="left" :show-overflow-tooltip="true" />
        <el-table-column prop="createBy" label="创建者" align="left" show-overflow-tooltip />
        <el-table-column prop="createTime" label="创建时间" align="left" show-overflow-tooltip />
      </el-table>
    </div>
    <c-pagination :page-size.sync="pageInfo.pageSize" :page-no.sync="pageInfo.pageNum" :total="pageInfo.total" :get-data-fun="loadData" />
    <OptionDialog ref="dialog" />
  </div>
</template>

<script>
import { PageCommonComponent } from '@/common/mixin'
import { Page, Delete } from '@/network/api/advManagement/advManagementBaiduPublish'
import Search from './Search'
import OptionDialog from './OptionDialog'
import { AdvManagementBaiduPublishEnt } from './object'
export default {
  name: 'AdvManagementBaiduPublish',
  components: { Search, OptionDialog },
  mixins: [PageCommonComponent],
  provide: function() {
    return {
      loadData: this.loadData,
      showEdit: this.showEdit
    }
  },
  data() {
    return {
      loading: false,
      data: [],
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    //     0 - 有效
    // 1 - 处于暂停时段
    // 2 - 暂停推广
    // 3 - 推广计划预算不足
    // 4 - 账户待激活
    // 11 - 账户预算不足
    // 20 - 账户余额为零
    // 23 - 被禁推
    // 24 - app已下线
    // 25 - 应用审核中
    formatterLauncheStatus(row, column, cellValue, index) {
      if (cellValue === '1') {
        return '启动'
      }
      if (cellValue === '0') {
        return '暂停（默认）'
      }
      return '-'
    },
    formatterPublishStatus(row, column, cellValue, index) {
      if (cellValue === '-1') {
        return '发布失败'
      }
      if (cellValue === '0') {
        return '发布成功'
      }
      return '-'
    },
    loadData(isFirst = false) {
      const searchForm = this.$refs.search.getSearchForm()
      this.doLoadData(isFirst, searchForm)
    },
    doLoadData(firstPage = false, searchForm) {
      firstPage && (this.pageInfo.pageNum = 1)
      const req = { ...searchForm, ...this.pageInfo }
      this.loading = true
      Page(req).then((res) => {
        this.data = res.data.records
        this.pageInfo.total = res.data.total
        this.loading = false
      })
    },
    deleteRow(row) {
      this.$confirm('确定删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          Delete(row.id).then((res) => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.loadData()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    showEdit(row) {
      this.$refs.dialog.show(true, { ...row })
    },
    showAdd() {
      this.$refs.dialog.show(false, new AdvManagementBaiduPublishEnt())
    }
  }
}
</script>

<style scoped></style>
