<!--
 * @Description: 工作区 -> 复制公众号组件
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2022-05-24 13:32:55
-->

<template>
  <section>
    <div v-if="detail.content === ''" class="copyGh">
      <div style="text-align: center">
        <i class="el-icon-document-copy"></i>
        <p>{{ detail.name }}组件</p>
      </div>
    </div>
    <!-- paddingLeft: '10px',
        paddingRight: '10px', -->
    <div
      v-else
      :style="{
        paddingTop: `${detail.paddingTop}px`,
        paddingBottom: `${detail.paddingBottom}px`
      }"
    >
      <div
        v-if="detail.type === 'label'"
        class="label"
        :style="{
          background: detail.backgroundColor
        }"
      >
        <div class="avatar">
          <el-avatar :size="54" src="https://empty" @error="errorHandler">
            <img src="https://cw-oss.mokamrp.com/copywriting/outerside/images/IGmnH7n2zi28fDAHqDFFCo2stlGMe9pJIuEhFmIt.jpeg" />
          </el-avatar>
          <p :style="{ color: detail.ghFontColor }">公众号名称</p>
        </div>
        <div
          class="button-btn1"
          :style="{
            background: detail.btnBgColorTheme,
            color: detail.btnFontColor,
            border: `1px solid ${detail.btnBorderColorTheme}`,
            fontWeight: detail.btnFontType
          }"
        >
          <span v-html="detail.btnTitle"></span>
        </div>
      </div>
      <div v-else class="button">
        <div
          class="button-btn"
          :style="{
            background: detail.btnBgColorTheme,
            color: detail.btnFontColor,
            border: `1px solid ${detail.btnBorderColorTheme}`
          }"
        >
          <span v-html="detail.btnTitle"></span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  .avatar {
    display: flex;
    align-items: center;
  }
  p {
    margin-left: 10px;
    font-size: 18px;
  }
  .button {
    padding: 5px 14px;
    border-radius: 5px;
    font-size: 20px;
  }
}
.button-btn1 {
  border-radius: 10px;
  line-height: 36px;
  text-align: center;
  font-size: 20px;
  height: 36px;
  padding: 0px 10px;
}
.button {
  padding-left: 10px;
  padding-right: 10px;
  .button-btn {
    border-radius: 20px;
    line-height: 42px;
    text-align: center;
    font-size: 20px;
    height: 42px;
  }
}
.copyGh {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  display: flex;
  flex-direction: column;
  i {
    font-size: 60px;
    color: #ccc;
  }
  p {
    margin-top: 10px;
    font-size: 24px;
    color: #ccc;
  }
}
</style>
