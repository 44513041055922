<!--
 * @Description: 工作区 -> 跳转小程序组件
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2023-03-14 13:59:06
-->

<template>
  <section :class="{ active: selectWidgetsIndex === index }">
    <template v-if="detail.cardType === 0">
      <div style="display: flex;align-items: center">
        <img :src="detail.pureImageUrl || defaultImg" alt=""/>
        <div class="title ml-10">
          <h3 :style="{ color: detail.titleColor }">{{ detail.title }}</h3>
          <p :style="{ color: detail.descColor }">{{ detail.desc }}</p>
        </div>
      </div>
      <div
        class="button"
        :style="{
          background: detail.componentItem.btnBgColorTheme,
          color: detail.componentItem.fontColor,
          border: `1px solid ${detail.componentItem.btnBorderColorTheme}`,
          fontWeight: detail.componentItem.btnFontType === '0' ? 'normal' : 'bold'
        }"
      >
        {{ detail.componentItem.btnTitle }}
      </div>
    </template>

    <template v-else>
      <div
        class="text"
        :style="{
          color: detail.titleColor
        }"
      >
        {{ detail.title }}
      </div>
      <div
        class="button"
        :style="{
          background: detail.componentItem.btnBgColorTheme,
          color: detail.componentItem.fontColor,
          border: `1px solid ${detail.componentItem.btnBorderColorTheme}`,
          fontWeight: detail.componentItem.btnFontType === '0' ? 'normal' : 'bold'
        }"
      >
        {{ detail.componentItem.btnTitle }}
      </div>
    </template>
  </section>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => {}
    },
    selectWidgetsIndex: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.active {
  border: 1px solid #409eff;
}
section {
  padding: 10px;
  width: 100%;
  border: 1px solid rgb(236, 236, 236);
  background: rgb(236, 236, 236);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0px;
  z-index: 100;
  img {
    width: 48px;
    height: 48px;
  }
  .text {
    font-weight: 700;
    font-size: 18px;
    color: rgb(23, 23, 23);
  }
  .button {
    width: auto;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    padding: 0 20px;
    font-size: 14px;
  }

  .title{
    h3{
      font-size: 16px;
    }
    p{
      font-size: 12px;
    }
  }
}
</style>
