<template>
  <div class="details-box">
    <div
      class="flex-between"
      style="margin-bottom: 20px"
    >
      <el-button
        type="primary"
        :disabled="!list.length"
        @click="handleSyncStatus"
      >同步状态</el-button>
      <div class="flex-end">执行时间 <el-date-picker
        v-model="execDate"
        style="width: 300px;margin-left: 15px;"
        type="daterange"
        :clearable="false"
        prange-separator="至"
        start-placeholder="开始时间"
        value-format="yyyy-MM-dd"
        end-placeholder="结束时间"
        @change="handleSyncStatus"
      >
      </el-date-picker>
      </div>
    </div>
    <div v-loading="loading">
      <el-collapse
        v-if="taskInfo && list.length"
        v-model="activeCollapse"
      >
        <el-collapse-item
          v-for="(item, index) in list"
          :key="index"
          :name="index"
        >
          <template slot="title">
            <div class="detail-collapse-header">
              {{ item.date }}
            </div>
          </template>
          <el-descriptions
            title=""
            :column="5"
            class="padding-cls"
          >
            <el-descriptions-item label="任务名称">{{ taskInfo.taskName }}</el-descriptions-item>
            <el-descriptions-item label="执行周期">{{ taskInfo.taskExecuteCycle | filterExecutionCycle }}</el-descriptions-item>
            <el-descriptions-item label="数据类型">{{ taskInfo.dataCondition | filterDataType }}</el-descriptions-item>
            <el-descriptions-item label="性别">{{ taskInfo.sex }}</el-descriptions-item>
            <el-descriptions-item label="留存修正">{{ taskInfo.keepUserCorrect ? '是' : '否' }}</el-descriptions-item>
          </el-descriptions>
          <div class="detail-list-box">
            <div class="flex-end detail-total">
              共 <span>{{ item.data.length }}</span> 个，其中计算中 <span>{{ item.computeNum }}</span> 个，可用 <span>{{
                item.canUseNum }}</span> 个，其他 <span> {{ item.otherNum }}</span> 个
            </div>
            <el-table
              v-loading="item.loading"
              :data="item.data"
              class="list-table"
              height="200"
            >
              <el-table-column
                type="index"
                label="序号"
              ></el-table-column>
              <el-table-column
                prop="account.accountHugeAdv.accountName"
                label="账户名称"
              ></el-table-column>
              <el-table-column
                prop="account.accountHugeAdv.accountId"
                label="账户ID"
              ></el-table-column>
              <el-table-column
                prop="dataSourceId"
                label="数据源ID"
              ></el-table-column>
              <el-table-column
                prop="customAudienceFormat"
                label="人群格式"
              >
                <template slot-scope="{ row }">{{ row.customAudienceFormat | filterAudienceFormat }}</template>
              </el-table-column>
              <el-table-column
                prop="customAudienceId"
                label="人群ID"
              >
                <template slot-scope="{ row }">{{ row.customAudienceId || '-' }}</template>
              </el-table-column>
              <el-table-column
                prop="coverNum"
                label="预计人数"
              >
                <template slot-scope="{ row }">{{ row.coverNum || '-' }}</template>
              </el-table-column>
              <el-table-column
                prop="executeAction"
                label="执行动作"
              >
                <template slot-scope="{ row }">{{ row.executeAction | filterAction }}</template>
              </el-table-column>
              <el-table-column
                prop="executeStatus"
                label="状态"
              >
                <template slot-scope="{ row }">
                  <el-tooltip
                    v-if="row.message"
                    effect="dark"
                    :content="row.message"
                    placement="top"
                  >
                    <span :class="'status-' + row.executeStatus">{{
                      row.executeStatus | filterStatus
                    }}</span>
                  </el-tooltip>
                  <span
                    v-else
                    :class="'status-' + row.executeStatus"
                  >{{
                    row.executeStatus | filterStatus
                  }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-collapse-item>
      </el-collapse>
      <el-empty
        v-else
        description="暂无数据"
      ></el-empty>
    </div>

    <div class="detail-footer">
      <el-button
        type="primary"
        @click="handleBack"
      >返回</el-button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import uniq from 'lodash/uniq'
import { dataConditionMap, fileTypeMap, sexTypeMap } from './util'
import { getCrowdPackDetail } from '@/network/api/huge/assetCrowdPack'

export default {
  name: 'HugeCrowdPackDetails',
  filters: {
    filterExecutionCycle(value) {
      if (value === -1) return '单次'
      return `循环-每${value > 1 ? value : ''}天`
    },
    filterDataType(value) {
      return dataConditionMap[value] || '-'
    },
    filterAudienceFormat(value) {
      return fileTypeMap[value] || '-'
    },
    filterAction(value) {
      const actions = {
        1: '添加',
        2: '覆盖'
      }
      return actions[value] || '-'
    },
    filterStatus(value) {
      const status = {
        1: '待计算',
        2: '计算中',
        3: '可用',
        4: '错误'
      }
      return status[value] || '-'
    }
  },
  data() {
    return {
      loading: false,
      taskId: null,
      execDate: [moment().subtract(1, 'week').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')], // 执行时间
      activeCollapse: [], // 展开面板
      list: [],
      taskInfo: null
    }
  },

  created() {
    let { id } = this.$route.params
    this.taskId = id || null
  },

  mounted() {
    this.handleSyncStatus()
  },

  methods: {

    // 获取列表
    handleSyncStatus() {
      this.loading = true
      getCrowdPackDetail(this.taskId, this.execDate).then(({ code, data }) => {
        if (code === 200) {
          if (!data?.tiktokTasks || !data?.tiktokPlans) return
          const { tiktokTasks, tiktokPlans } = data
          const { taskName, taskExecuteCycle, taskDataWhere: dataCondition, dataWhere } = tiktokTasks
          const { s_sex, data_update: keepUserCorrect } = JSON.parse(dataWhere)
          this.taskInfo = {
            taskName,
            taskExecuteCycle,
            dataCondition,
            sex: s_sex?.split(',').map(s => sexTypeMap[s]).join('、') || '-',
            keepUserCorrect
          }
          this.list = uniq(tiktokPlans.map(d => d.createData).sort((a, b) => b - a)).map(date => {
            const data = tiktokPlans.filter(d => d.createData === date)
            return {
              date,
              computeNum: data.filter(v => [1, 2].includes(v.executeStatus)).length,
              canUseNum: data.filter(v => v.executeStatus === 3).length,
              otherNum: data.filter(v => v.executeStatus === 4).length,
              data
            }
          })
          if (this.list?.length) {
            this.activeCollapse = [0]
          }
        }
      }).finally(() => {
        this.loading = false
      })
    },

    // 点击返回
    handleBack() {
      this.$router.replace({
        name: 'hugeCrowdPackingTask'
      })
    }

  }
}
</script>

<style lang="scss">
.details-box {
  .el-collapse-item__header {
    background-color: #f5f7fa;
  }

  .el-collapse {
    border-left: solid 1px #EBEEF5;
    border-right: solid 1px #EBEEF5;
  }
}
</style>

<style scoped lang="scss">
.details-box {
  .flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center
  }

  .flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center
  }

  .padding-cls {
    padding: 10px 20px;
  }

  .detail-collapse-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin: 0 20px;

    span:last-child {
      color: #67C23A;
    }
  }

  .detail-list-box {
    border-top: solid 1px #EBEEF5;

    .list-table {
      width: 99%;
      margin: auto;
    }
  }

  .detail-total {
    font-size: 14px;
    color: #666;
    padding: 15px;
  }

  .detail-footer {
    text-align: center;
    margin: 100px 0;
  }

  .status-4 {
    color: #f00;
  }

  .status-3 {
    color: #67C23A;
  }
}
</style>
