export const searchForm = {

  source: null,
  isUseing: null,
  relationProjectId: null,
  relationProjectName: null,
  rebateRatio: null,
  deleted: null,
  createBy: null,
  createTime: null,
  updateBy: null,
  updateTime: null,
  remark: null
}

export class BasicSettingProductSourceEnt {
  source
  isUseing
  relationProjectId
  relationProjectName
  rebateRatio
  deleted
  createBy
  createTime
  updateBy
  updateTime
  remark
}

export const formRules = {

  source: [{ required: true, message: '请填写来源名称', trigger: 'change' }],
  isUseing: [{ required: true, message: '请填写是否启用', trigger: 'change' }],
  relationProjectId: [{ required: true, message: '请填写关联项目名称', trigger: 'change' }],
  rebateRatio: [{ required: true, message: '请填写返点比例/反利率', trigger: 'change' }]

}

export const isUseingList = [{
  value: 'ISUSEING',
  label: '启用'
}, {
  value: 'ISNOTUSEING',
  label: '不启用'
}]
