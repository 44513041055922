var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("TreeSelect", {
        staticStyle: { width: "800px" },
        attrs: {
          value: _vm.selectData.category,
          placeholder: "选择行为名称 ",
          "allow-clear": "",
          "tree-data": _vm.BEHAVIOR_list,
          "tree-checkable": "",
          "show-checked-strategy": _vm.SHOW_PARENT,
          "replace-fields": {
            children: "children",
            title: "name",
            value: "id",
            key: "id",
          },
        },
        on: { change: _vm.change },
      }),
      _c(
        "el-checkbox",
        { staticClass: "ml-20", on: { change: (e) => _vm.selectAll(e) } },
        [_vm._v("全选")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }