<template>
  <div :class="{'hidden':hidden}" class="clearfix">
    <el-pagination
      class="pagination flr"
      :background="background"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :layout="layout"
      :page-sizes="pageSizes"
      :pager-count="pagerCount"
      :total="total"
      v-bind="$attrs"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import { scrollTo } from '@/utils/scroll-to'

export default {
  name: 'Pagination',
  props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 50]
      }
    },
    // 移动端页码按钮的数量端默认值5
    pagerCount: {
      type: Number,
      default: document.body.clientWidth < 992 ? 5 : 7
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next'
    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('pagination', { page: this.currentPage, limit: val })
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    },
    handleCurrentChange(val) {
      this.$emit('pagination', { page: val, limit: this.pageSize })
      if (this.autoScroll) {
        scrollTo(0, 800)
      }
    }
  }
}
</script>

<style scoped>
.pagination-container {
  background: #fff;
  padding: 32px 16px;
}
.pagination-container.hidden {
  display: none;
}
</style>

<style lang="scss">
@import "~@/assets/style/variables.scss";

$pageHeight: 24px;
.pagination {
  margin-top: 20px;
  /*margin: 30px 0px 30px 100px;*/

  &.el-pagination .btn-prev,
  &.el-pagination .btn-next {
    background-color: transparent;
    height: $pageHeight;
    line-height: $pageHeight;
  }

  &.el-pagination span:not([class*="suffix"]),
  &.el-pagination button {
    height: $pageHeight;
    line-height: $pageHeight;
  }

  .el-button.jump-btn {
    width: $pageHeight;
    height: $pageHeight;
    background-color: $themeColor;
    border-radius: 50%;
    text-align: center;
    line-height: $pageHeight;
    padding: 0;
    color: #fff;
    margin-left: 15px;

    &:hover {
      background-color: $themeColor;
    }
  }

  &.el-pagination__editor.el-input {
    width: 56px;
  }

  .el-pagination__editor.el-input .el-input__inner {
    height: 32px;
    border-radius: 16px;
    border: solid 1px #dcdfe6;
    background-color: #f8f9fb;
  }

  .el-pager li {
    min-width: $pageHeight;
    height: $pageHeight;
    margin-right: 13px;
    line-height: $pageHeight;
    font-size: 14px;
    color: #474747;
    font-weight: 500;
    transition: all 0.3s;
    border: solid 1px transparent;
    box-sizing: border-box;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      border: solid 1px $themeColor;
      color: $themeColor;
      box-sizing: border-box;
      background-color: #fbfafd;
    }

    &.active {
      border: solid 1px $themeColor;
      color: $themeColor;
      box-sizing: border-box;
      border-radius: 2px;
      background-color: #fbfafd;

      &:hover {
        border: solid 1px $themeColor;
        color: $themeColor;
      }
    }
  }

  .el-pagination__jump {
    margin-left: 10px;
  }

  &.el-pagination .el-pagination__sizes {
    margin: 0 5px;

    .el-select .el-input {
      width: 89px;

      .el-input__inner {
        height: 24px;
        border-radius: 2px;
        border: solid 1px #edecf2;
      }
    }
  }
}
</style>
