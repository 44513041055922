
<template>
  <!--显示列弹框-->
  <el-dialog
    :visible.sync="show"
    :append-to-body="true"
    width="600px"
    top="20vh"
    @close="onCloseDialog"
  >
    <!--自定义 title-->
    <span slot="title" class="dialog-title">{{ dialogTitle }}</span>
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="名称" prop="textGroupName">
        <el-input v-model="form.textGroupName" placeholder="请输入文案组名称"></el-input>
      </el-form-item>
      <el-form-item label="关联项目" prop="projectId">
        <el-select v-model="form.projectId" filterable clearable placeholder="请选择关联项目" style="width: 100%;" @change="handleSelect">
          <el-option v-for="(itm, idx) in projectList" :key="idx" :label="itm.projectName" :value="itm.bsProjectId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关联产品" prop="productId">
        <el-select v-model="form.productId" filterable :disabled="!form.projectId" clearable placeholder="请选择关联产品" style="width: 100%;">
          <el-option v-for="(itm, idx) in productList" :key="idx" :label="itm.productName" :value="itm.bsProductId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="文案">
        <div class="content">
          <div v-for="(t, i) in adMaterialTexts" :key="`tag-${i}`" class="tag-input">
            <el-input :key="`taginput-${i}`" v-model="adMaterialTexts[i].text" style="width: 100%;" placeholder="请输入文案"></el-input>
          </div>
        </div>
      </el-form-item>
    </el-form>

    <!--底部按钮-->
    <div slot="footer" class="dialog-footer">
      <el-button class="btn" @click="closeModal">取 消</el-button>
      <el-button class="btn" type="primary" :loading="isLoading" :disabled="isLoading" @click="clickConfirm('form')">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  getTxtGroupDetail,
  editTxtGroup
} from '@/network/api/api-material'
import { getMeRelationProject } from '@/network/api/basicSetting/project/basicSettingProject'
import { getProductByProject } from '@/network/api/basicSetting/product/basicSettingProduct'
export default {
  name: 'EditCopyGroupDialog',
  components: {
  },
  filters: {},
  props: {
  },
  data () {
    return {
      show: false, // 是否显示弹框
      dialogTitle: '',
      projectList: [], // 项目列表
      productList: [], // 产品列表
      adMaterialTexts: [], // 文本组列表
      isLoading: false, // 是否正在加载
      id: '',
      form: {
        textGroupName: '',
        productId: '',
        projectId: ''
      }, // 表单数据
      rules: {
        productId: [{ required: true, message: '请选择关联产品', trigger: 'blur' }],
        projectId: [{ required: true, message: '请选择关联项目', trigger: 'blur' }],
        textGroupName: [{ required: true, message: '请输入文案组名称', trigger: 'blur' }]
      } // 表单校验规则
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  watch: {},
  created () {
  },
  mounted () {
  },
  methods: {
    /* --------------------------- Actions --------------------------- */
    // 显示弹框
    showModal (id) {
      this.show = true
      this.dialogTitle = id ? '编辑文案组' : '编辑文案组'
      console.log(this.userInfo.userid)
      this._getProject()
      id && this._getTxtGroupDetail(id)
      this.id = id
    },

    // 关闭弹框
    closeModal () {
      this.show = false
    },
    // 监听 Dialog 弹框关闭
    onCloseDialog () {
      // 重置表单
      this.$refs['form'].resetFields()

      // 清除数据
      this.form = {
        textGroupName: '',
        productId: '',
        projectId: '',
        texts: ''
      }
      this.id = ''
    },
    // 删除文本
    onClickDelText (i) {
      this.adMaterialTexts.splice(i, 1)
    },
    // 点击确定按钮
    clickConfirm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this._editTxtGroup()
        } else {
          this.$WarningMessage('请将表单填写完整')
        }
      })
    },
    // 选择关联项目之后
    handleSelect (e) {
      if (e !== '') {
        this.form.productId = ''
        this._getProduct(e)
      }
    },
    /* --------------------------- Private --------------------------- */
    // 获取项目列表(个人可见)
    _getProject() {
      getMeRelationProject().then(res => {
        if (res.code === 200) {
          this.projectList = res.data
        }
      })
    },
    // 获取产品列表(个人可见)
    _getProduct(val) {
      getProductByProject(val).then(res => {
        if (res.code === 200) {
          this.productList = res.data
        }
      })
    },
    // 编辑文案组
    _editTxtGroup () {
      this.isLoading = true
      let textIds = this.adMaterialTexts.map(item => {
        return item.textId
      })
      let params = {
        adMaterialTexts: this.adMaterialTexts,
        productId: this.form.productId,
        projectId: this.form.projectId,
        adminId: this.userInfo.userid,
        nickname: this.userInfo.username,
        textGroupName: this.form.textGroupName,
        textIds: textIds.join(',')
      }
      editTxtGroup(this.id, params).then(res => {
        // this.$SuccessMessage('编辑文案组成功')
        this.$emit('edit')
        this.closeModal()
      }).catch(err => {
        this.$ErrorMessage(err.data || '编辑文案组失败')
      }).finally(() => {
        this.isLoading = false
      })
    },

    // 获取文案组详情
    _getTxtGroupDetail (id) {
      this.isLoading = true
      getTxtGroupDetail({ id: id }).then(res => {
        this._getProduct(res.data.projectId)
        this.adMaterialTexts = res.data.adMaterialTexts
        this.form.textGroupName = res.data.textGroupName
        this.form.productId = res.data.productId
        this.form.projectId = res.data.projectId
      }).catch(err => {
        this.$ErrorMessage(err.data || '获取文案组详情失败')
      }).finally(() => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .content {
      .tag-input {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        .opt-icon-container {
          width: 18px;
          height: 18px;
          position: relative;
          margin-left: 8px;
          cursor: pointer;
          .opt-icon-hl {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
          }
          .opt-icon {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 1;
          }
          &:hover {
            .opt-icon {
              opacity: 0;
            }
            .opt-icon-hl {
              opacity: 1;
            }
          }
        }
      }
    }
    .tag-add-container {
        display: flex;
        align-items: center;
        margin-top: 12px;
        color: #2842C8;
        cursor: pointer;
        .add-icon-hl {
          width: 16px;
          height: 16px;
          margin-right: 6px;
        }
      }
</style>

