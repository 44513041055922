<template>
  <div class="app-container">
    <el-form v-show="showSearch" ref="queryForm" class="search-form" :model="queryParams" :inline="true" label-width="68px">
      <el-form-item label="" prop="title">
        <el-input v-model="queryParams.title" placeholder="请输入系统模块" clearable style="width: 240px;" size="small" @keyup.enter.native="handleQuery" />
      </el-form-item>
      <el-form-item label="" prop="operName">
        <el-input v-model="queryParams.operName" placeholder="请输入操作人员" clearable style="width: 240px;" size="small" @keyup.enter.native="handleQuery" />
      </el-form-item>
      <el-form-item label="" prop="businessType">
        <el-select v-model="queryParams.businessType" placeholder="操作类型" clearable size="small" style="width: 240px">
          <el-option v-for="dict in typeOptions" :key="dict.dictValue" :label="dict.dictLabel" :value="dict.dictValue" />
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="status">
        <el-select v-model="queryParams.status" placeholder="操作状态" clearable size="small" style="width: 240px">
          <el-option v-for="dict in statusOptions" :key="dict.dictValue" :label="dict.dictLabel" :value="dict.dictValue" />
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-date-picker v-model="dateRange" size="small" style="width: 240px" value-format="yyyy-MM-dd" type="daterange" range-separator="-" start-placeholder="操作时间开始日期" end-placeholder="操作时间结束日期" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">查询</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8" style="    display: flex;">
      <el-button type="danger" plain icon="el-icon-delete" size="mini" round :disabled="multiple" @click="handleDelete">删除</el-button>

      <el-button type="danger" plain round icon="el-icon-delete" size="mini" @click="handleClean">清空</el-button>

      <el-button type="warning" plain round icon="el-icon-download" size="mini" @click="handleExport">导出</el-button>

      <right-toolbar class="ml-10" :show-search.sync="showSearch" @queryTable="getList" />
    </el-row>

    <el-table ref="tables" v-loading="loading" :data="list" :default-sort="defaultSort" @selection-change="handleSelectionChange" @sort-change="handleSortChange">
      <el-table-column type="selection" width="55" align="left" />
      <el-table-column label="日志编号" align="left" prop="operId" />
      <el-table-column label="系统模块" align="left" prop="title" />
      <el-table-column label="操作类型" align="left" prop="businessType" :formatter="typeFormat" />
      <el-table-column label="请求方式" align="left" prop="requestMethod" />
      <el-table-column label="操作人员" align="left" prop="operName" :show-overflow-tooltip="true" sortable="custom" :sort-orders="['descending', 'ascending']" width="100" />
      <el-table-column label="主机" align="left" prop="operIp" width="130" :show-overflow-tooltip="true" />
      <el-table-column label="操作状态" align="left" prop="status" :formatter="statusFormat" />
      <el-table-column label="操作日期" align="left" prop="operTime" sortable="custom" :sort-orders="['descending', 'ascending']" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.operTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="left" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button size="mini" type="text" icon="el-icon-view" @click="handleView(scope.row, scope.index)">详细</el-button>
        </template>
      </el-table-column>
    </el-table>

    <paginations v-show="total > 0" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList" />

    <!-- 操作日志详细 -->
    <el-dialog title="操作日志详细" :visible.sync="open" width="700px" append-to-body>
      <el-form ref="form" :model="form" label-width="100px" size="mini">
        <el-row>
          <el-col :span="12">
            <el-form-item label="操作模块：">{{ form.title }} / {{ typeFormat(form) }}</el-form-item>
            <el-form-item label="登录信息：">{{ form.operName }} / {{ form.operIp }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="请求地址：">{{ form.operUrl }}</el-form-item>
            <el-form-item label="请求方式：">{{ form.requestMethod }}</el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="操作方法：">{{ form.method }}</el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="请求参数：">{{ form.operParam }}</el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="返回参数：">{{ form.jsonResult }}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="操作状态：">
              <div v-if="form.status === 0">正常</div>
              <div v-else-if="form.status === 1">失败</div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="操作时间：">{{ parseTime(form.operTime) }}</el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item v-if="form.status === 1" label="异常信息：">{{ form.errorMsg }}</el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="open = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { list, delOperlog, cleanOperlog } from '@/network/api/system/operlog'

export default {
  name: 'Operlog',
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      list: [],
      // 是否显示弹出层
      open: false,
      // 类型数据字典
      typeOptions: [
        // { dictLabel: '新增', dictValue: '1' },
        // { dictLabel: '修改', dictValue: '2' },
        // { dictLabel: '删除', dictValue: '3' },
        // { dictLabel: '授权', dictValue: '4' },
        // { dictLabel: '导出', dictValue: '5' },
        // { dictLabel: '导入', dictValue: '6' },
        // { dictLabel: '强退', dictValue: '7' },
        // { dictLabel: '清空数据', dictValue: '9' }
      ],
      // 类型数据字典
      statusOptions: [
        // { dictLabel: '正常', dictValue: '0' },
        // { dictLabel: '失败', dictValue: '1' }
      ],
      // 日期范围
      dateRange: [],
      // 默认排序
      defaultSort: { prop: 'operTime', order: 'descending' },
      // 表单参数
      form: {},
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        title: undefined,
        operName: undefined,
        businessType: undefined,
        operTimeQuery: undefined,
        status: undefined
      }
    }
  },
  created() {
    this.getList()
    this.getDicts('sys_oper_type').then((response) => {
      this.typeOptions = response.data
    })
    this.getDicts('sys_common_status').then((response) => {
      this.statusOptions = response.data
    })
  },
  methods: {
    /** 查询登录日志 */
    getList() {
      if (this.dateRange.length === 2) {
        var zreo = this.dateRange[0]
        var one = this.dateRange[1]
        this.queryParams.operTimeQuery = zreo + ',' + one
      }
      this.loading = true
      list(this.queryParams).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    // 操作日志状态字典翻译
    statusFormat(row, column) {
      return this.selectDictLabel(this.statusOptions, row.status)
    },
    // 操作日志类型字典翻译
    typeFormat(row, column) {
      return this.selectDictLabel(this.typeOptions, row.businessType)
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams.operTimeQuery = undefined
      this.dateRange = []
      this.resetForm('queryForm')
      this.$refs.tables.sort(this.defaultSort.prop, this.defaultSort.order)
      this.handleQuery()
    },
    /** 多选框选中数据 */
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.operId)
      this.multiple = !selection.length
    },
    /** 排序触发事件 */
    handleSortChange(column, prop, order) {
      this.queryParams.orderByColumn = column.prop
      this.queryParams.isAsc = column.order
      this.getList()
    },
    /** 详细按钮操作 */
    handleView(row) {
      this.open = true
      this.form = row
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const operIds = row.operId || this.ids
      this.$confirm('是否确认删除日志编号为"' + operIds + '"的数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(function() {
          return delOperlog(operIds)
        })
        .then(() => {
          this.getList()
          this.msgSuccess('删除成功')
        })
        .catch(() => {})
    },
    /** 清空按钮操作 */
    handleClean() {
      this.$confirm('是否确认清空所有操作日志数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(function() {
          return cleanOperlog()
        })
        .then(() => {
          this.getList()
          this.msgSuccess('清空成功')
        })
        .catch(() => {})
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download(
        '/api/system/operlog/export',
        {
          ...this.queryParams
        },
        `operlog_${new Date().getTime()}.xlsx`
      )
    }
  }
}
</script>
