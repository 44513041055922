<template>
  <div class="navbar-box">
    <div class="navbar-left">
      <Dropdown />
    </div>
    <div class="navbar-right">
      <!--头像-->
      <div class="avatar-wrapper">
        <div class="image-box">
          <img v-if="userInfo.avatar" :src="userInfo.avatar" alt="" />
          <img v-else :src="require('@/assets/image/icon/icon-default-avatar.png')" alt="" />
        </div>
      </div>
      <!--用户名-->
      <el-dropdown>
        <div class="user-info">
          <span class="name">{{ userInfo.username }}</span>
          <i class="el-icon-arrow-down icon-right"></i>
        </div>
        <el-dropdown-menu>
          <el-dropdown-item :command="0">
            <s-confirm-box type="warning" text="确定要退出登录吗？" content="" @confirm="handleLogout">
              <span class="logout">退出登录</span>
            </s-confirm-box>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Dropdown from './dropdown.vue'
import { logout } from '@/network/api/api-base'
import ConfirmBox from '@/components/ConfirmBox'

export default {
  name: 'NavBar',
  components: {
    's-confirm-box': ConfirmBox,
    Dropdown
  },
  data() {
    return {
      searchContent: ''
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'isCollapse'])
  },
  methods: {
    // 搜索
    onClickSearch() {},

    // 退出登录
    handleLogout() {
      logout()
        .then((res) => {
          if (res.code === 200) {
            const _url = window.location.origin
            window.location.href = res.data + '?redirect_url=' + _url
          }
        })
        .catch((e) => {
          this.$ErrorMessage(e.msg || '')
        })
    },
    selectMenu(index) {
      this.$emit('selectMenu', index)
    }
  }
}
</script>

<style lang="scss">
.navbar-box {
  .iconfont {
    margin-right: 4px;
    font-size: 20px;
    color: #303133;
  }
}

.navbar-right {
  .el-badge__content {
    padding: 0 4.5px;
    height: 14px;
    line-height: 14px;
    background-color: #fb4444;
  }
}
</style>

<style scoped lang="scss">
@import '~@/assets/style/mixin.scss';

.navbar-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  flex: 1;

  .navbar-search {
    height: 100%;
    padding: 0 15px;
    @include flex-center;
    cursor: pointer;

    .icon {
      width: 20px;
      height: 20px;
      background-size: 20px !important;
      background: url(~@/assets/image/icon/icon-nav-search-normal.png) 0 0 no-repeat;

      &:hover {
        background: url(~@/assets/image/icon/icon-nav-search-hover.png) 0 0 no-repeat;
      }
    }
  }

  .navbar-right {
    // margin-right: 50px;
    display: flex;
    align-items: center;

    .avatar-wrapper {
      display: inline-block;
      margin-left: 50px;
      margin-right: 10px;

      .image-box {
        width: 30px;
        height: 30px;

        border-radius: 50%;
        overflow: hidden;

        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .icon-notification {
    color: #b7b9c5;
    font-size: 24px;
    position: relative;
    top: -10px;
  }

  .user-info {
    display: flex;
    align-items: center;
    cursor: pointer;

    .name {
      font-size: 16px;
      color: #212937;
      margin-right: 5px;
    }
  }
}
</style>
