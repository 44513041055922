var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "return-condition-custom" },
      [
        _c(
          "el-form-item",
          {
            attrs: {
              label: "是否首充 ",
              rules: [{ required: true }],
              prop: "is_first_recharge",
            },
          },
          [
            _c(
              "el-radio-group",
              {
                model: {
                  value: _vm.ruleData.is_first_recharge,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleData, "is_first_recharge", $$v)
                  },
                  expression: "ruleData.is_first_recharge",
                },
              },
              [
                _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                _c("el-radio", { attrs: { label: 2 } }, [_vm._v("否")]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "attention" },
          [
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "时间条件 ",
                  rules: [{ required: true }],
                  prop: "attention_type",
                },
              },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.ruleData.attention_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleData, "attention_type", $$v)
                      },
                      expression: "ruleData.attention_type",
                    },
                  },
                  [
                    _c("el-radio", { attrs: { label: 1 } }, [
                      _vm._v("关注当天"),
                    ]),
                    _c("el-radio", { attrs: { label: 2 } }, [_vm._v("关注后")]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("el-form-item", [
              _c(
                "div",
                { staticClass: "align-center" },
                [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.ruleData.attention_type === 1
                            ? "关注当天 "
                            : "关注后 "
                        ) +
                        " "
                    ),
                  ]),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100px", margin: "0 47px 0 10px" },
                      attrs: { prop: "attention_time" },
                    },
                    [
                      _c("el-inputNumber", {
                        attrs: {
                          min: 0,
                          max: _vm.ruleData.attention_type === 1 ? 23 : 999,
                          size: "small",
                        },
                        model: {
                          value: _vm.ruleData.attention_time,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleData, "attention_time", $$v)
                          },
                          expression: "ruleData.attention_time",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("span", [_vm._v("小时内的")]),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "attention" },
          [
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "回传方式 ",
                  rules: [{ required: true }],
                  prop: "attention_type",
                },
              },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择回传方式" },
                    model: {
                      value: _vm.ruleData.return_type,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleData, "return_type", $$v)
                      },
                      expression: "ruleData.return_type",
                    },
                  },
                  _vm._l(_vm.returnTypes0, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.label, value: item.value },
                    })
                  }),
                  1
                ),
                _vm.ruleData.return_type === 2
                  ? _c(
                      "el-select",
                      {
                        staticClass: "pl-10",
                        attrs: { placeholder: "请选择循环方式" },
                        model: {
                          value: _vm.ruleData.cycle_rule,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleData, "cycle_rule", $$v)
                          },
                          expression: "ruleData.cycle_rule",
                        },
                      },
                      _vm._l(_vm.returnTypes1, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
                _vm.ruleData.return_type === 2
                  ? _c(
                      "el-select",
                      {
                        staticClass: "pl-10",
                        attrs: { placeholder: "请选择循环卡控条件" },
                        model: {
                          value: _vm.ruleData.is_first_return,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleData, "is_first_return", $$v)
                          },
                          expression: "ruleData.is_first_return",
                        },
                      },
                      _vm._l(_vm.returnTypes2, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: "订单金额", rules: [{ required: true }] } },
          [
            _c("el-button", {
              staticClass: "mb-10",
              attrs: { icon: "el-icon-plus", type: "primary" },
              on: { click: _vm.addOrderAmountRule },
            }),
            _vm.ruleData.order_amount_rule.length
              ? _c(
                  "div",
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.ruleData.order_amount_rule,
                          border: "",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: "是否符合",
                            type: "key",
                            align: "center",
                            width: "100",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    row.verify
                                      ? _c(
                                          "p",
                                          { staticStyle: { color: "#409EFF" } },
                                          [_vm._v("验证通过")]
                                        )
                                      : _c(
                                          "p",
                                          { staticStyle: { color: "#ff0000" } },
                                          [_vm._v("条件有误")]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3331498096
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "下限条件",
                            type: "amount_off_condition",
                            align: "center",
                            width: "120",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          value: row.amount_off_condition,
                                          size: "small",
                                        },
                                        on: {
                                          change: (value) => {
                                            _vm.setOrderAmountRule(
                                              row.key,
                                              "amount_off_condition",
                                              value
                                            )
                                          },
                                        },
                                      },
                                      _vm._l(
                                        _vm.orderOperatorOption,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.value,
                                            attrs: {
                                              disabled: _vm
                                                .conditionDisabled2(row)
                                                .includes(item.value)
                                                ? true
                                                : false,
                                              label: item.title,
                                              value: item.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2395463353
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "下限金额",
                            type: "amount_off",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c("el-input-number", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        value: row.amount_off,
                                        min: 0,
                                        size: "small",
                                        "controls-position": "right",
                                      },
                                      on: {
                                        change: (value) => {
                                          _vm.setOrderAmountRule(
                                            row.key,
                                            "amount_off",
                                            value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3616580552
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "上限条件",
                            type: "amount_on_condition",
                            align: "center",
                            width: "120",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          value: row.amount_on_condition,
                                          size: "small",
                                        },
                                        on: {
                                          change: (value) => {
                                            _vm.setOrderAmountRule(
                                              row.key,
                                              "amount_on_condition",
                                              value
                                            )
                                          },
                                        },
                                      },
                                      _vm._l(
                                        _vm.orderOperatorOption,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.value,
                                            attrs: {
                                              disabled: _vm
                                                .conditionDisabled(row)
                                                .includes(item.value)
                                                ? true
                                                : false,
                                              label: item.title,
                                              value: item.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2728398396
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "上限金额",
                            type: "amount_on",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c("el-input-number", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        value: row.amount_on,
                                        min: 0,
                                        "controls-position": "right",
                                        size: "small",
                                      },
                                      on: {
                                        change: (value) => {
                                          _vm.setOrderAmountRule(
                                            row.key,
                                            "amount_on",
                                            value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3474231208
                          ),
                        }),
                        _vm.ruleData.return_type === 1
                          ? _c("el-table-column", {
                              attrs: {
                                label: "回传比例(%)",
                                type: "order_return_rate",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        _c("el-input-number", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            value: row.order_return_rate,
                                            "controls-position": "right",
                                            min: 0,
                                            max: 100,
                                          },
                                          on: {
                                            input: (value) => {
                                              _vm.setOrderAmountRule(
                                                row.key,
                                                "order_return_rate",
                                                value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3594812135
                              ),
                            })
                          : _vm._e(),
                        _vm.ruleData.return_type === 2
                          ? _c("el-table-column", {
                              attrs: {
                                label: "循环比例(%)",
                                type: "cycle_proportion01",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ row }) {
                                      return [
                                        _c("el-input-number", {
                                          staticStyle: { width: "90px" },
                                          attrs: {
                                            value: row.order_return_pick,
                                            "controls-position": "right",
                                            min: 0,
                                            max: 100,
                                          },
                                          on: {
                                            input: (value) => {
                                              _vm.setOrderAmountRule(
                                                row.key,
                                                "order_return_pick",
                                                value
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" : "),
                                        _c("el-input-number", {
                                          staticStyle: { width: "90px" },
                                          attrs: {
                                            value: row.order_return_ban,
                                            "controls-position": "right",
                                            min: 0,
                                            max: 100,
                                          },
                                          on: {
                                            input: (value) => {
                                              _vm.setOrderAmountRule(
                                                row.key,
                                                "order_return_ban",
                                                value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3010338188
                              ),
                            })
                          : _vm._e(),
                        _c("el-table-column", {
                          attrs: {
                            label: "生效时间",
                            type: "order_effect_time",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c("el-time-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        "is-range": "",
                                        size: "small",
                                        "value-format": "HH:mm:ss",
                                        "range-separator": "-",
                                        "start-placeholder": "开始时间",
                                        "end-placeholder": "结束时间",
                                      },
                                      on: {
                                        change: (value) => {
                                          _vm.setOrderAmountRule(
                                            row.key,
                                            "order_effect_time",
                                            value
                                          )
                                        },
                                      },
                                      model: {
                                        value: row.order_effect_time,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            row,
                                            "order_effect_time",
                                            $$v
                                          )
                                        },
                                        expression: "row.order_effect_time",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3058146048
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            type: "order_return_rate",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { color: "red" },
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteOrder(row.key)
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3690985970
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _c("el-form-item", { attrs: { label: "回传条件" } }, [
          _vm.ruleData.order_amount_rule.length
            ? _c(
                "div",
                _vm._l(_vm.ruleData.order_amount_rule, function (item) {
                  return _c(
                    "p",
                    {
                      key: item.key,
                      style: {
                        marginTop: "-2px",
                        color: _vm.ruleData.order_amount_rule_flag ? "red" : "",
                      },
                    },
                    [
                      _c("span", [_vm._v("回传用户在")]),
                      _c("span", { staticStyle: { color: "#409EFF" } }, [
                        _vm._v(
                          " 关注" +
                            _vm._s(
                              _vm.ruleData.attention_type === 1
                                ? "当天 "
                                : " 后 "
                            ) +
                            _vm._s(_vm.ruleData.attention_time) +
                            " 小时 "
                        ),
                      ]),
                      _c("span", [_vm._v("内,金额")]),
                      _c("span", { staticStyle: { color: "#409EFF" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.orderOperatorOption.filter(
                                (f) => f.value === item.amount_off_condition
                              )[0].title
                            ) +
                            " " +
                            _vm._s(item.amount_off) +
                            "元 "
                        ),
                      ]),
                      _c("span", [_vm._v("至")]),
                      _c("span", { staticStyle: { color: "#409EFF" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.orderOperatorOption.filter(
                                (f) => f.value === item.amount_on_condition
                              )[0].title
                            ) +
                            " " +
                            _vm._s(item.amount_on) +
                            "元 "
                        ),
                      ]),
                      _c("span", [_vm._v("回传比例为")]),
                      _c("span", { staticStyle: { color: "#409EFF" } }, [
                        _vm._v(" " + _vm._s(item.order_return_rate) + "% "),
                      ]),
                      _c("span", [_vm._v("循环比例为")]),
                      _c("span", { staticStyle: { color: "#409EFF" } }, [
                        _vm._v(" " + _vm._s(item.cycle_proportion) + "% "),
                      ]),
                      _c("span", [_vm._v("的订单")]),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
        _c(
          "el-form-item",
          { attrs: { prop: "date", label: "启用时间" } },
          [
            _c("el-time-picker", {
              attrs: {
                "is-range": "",
                "range-separator": "-",
                "start-placeholder": "开始时间",
                "end-placeholder": "结束时间",
              },
              model: {
                value: _vm.ruleData.date,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleData, "date", $$v)
                },
                expression: "ruleData.date",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { prop: "is_on_sale", label: "回传金额调整" } },
          [
            _c("el-switch", {
              on: { change: _vm.changeSale },
              model: {
                value: _vm.ruleData.is_on_sale,
                callback: function ($$v) {
                  _vm.$set(_vm.ruleData, "is_on_sale", $$v)
                },
                expression: "ruleData.is_on_sale",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { display: "flex" } },
          [
            _vm.ruleData.is_on_sale
              ? _c(
                  "el-form-item",
                  {
                    staticClass: "customize_cycle_proportion",
                    staticStyle: { "margin-left": "30px" },
                    attrs: {
                      prop: "discount",
                      rules: [
                        {
                          required:
                            _vm.ruleData.discount === "0" ||
                            _vm.ruleData.discount === null,
                          message: "请输入缩减比例!",
                        },
                      ],
                    },
                  },
                  [
                    _vm.ruleData.is_on_sale
                      ? _c("el-input-number", {
                          staticStyle: { width: "150px" },
                          attrs: {
                            min: 0,
                            precision: 2,
                            placeholder: "输入缩减比例",
                          },
                          model: {
                            value: _vm.ruleData.discount,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleData, "discount", $$v)
                            },
                            expression: "ruleData.discount",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.ruleData.is_on_sale
              ? _c(
                  "div",
                  { staticStyle: { "margin-top": "6px", padding: "0 10px" } },
                  [_vm._v("%")]
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }