<!--
 * @Author: Zeng
 * @Date: 2023-06-08 11:08:49
 * @LastEditTime: 2023-06-15 15:32:27
-->
<template>
  <div>
    <!-- 列表查询 -->
    <el-form ref="search" :inline="true" :model="search" class="search-form" @submit.native.prevent>
      <el-form-item label="账户名称" prop="corporationName">
        <el-input v-model="search.corporationName" placeholder="请输入账户名称" style="width: 300px"></el-input>
      </el-form-item>

      <el-form-item label="账户ID" prop="serviceAccountId">
        <el-input v-model="search.serviceAccountId" placeholder="请输入账户ID" style="width: 300px"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" round @click="getPage">查询</el-button>
        <el-button type="primary" round @click="resetFormQuery('search')">重置</el-button>
      </el-form-item>
    </el-form>

    <div class="justify-sb mb-15">
      <el-button type="primary" icon="el-icon-plus" round @click="handleAddHousekeeper('')"> 新增账户 </el-button>
    </div>

    <!-- 表格 -->
    <TablePane :data-source="dataSource" @getPage="getPage"> </TablePane>

    <!-- 新增/编辑账户 -->
    <add-housekeeper ref="addHousekeeper" @confirm="handleAuth"></add-housekeeper>

    <!-- 导入子客账户 -->
    <import-housekeeper ref="importHousekeeper" @confirm="getPage"></import-housekeeper>
  </div>
</template>

<script>
import TablePane from '@/components/tablePane.vue' // authorize adMarketingApiClientsSelect
import AddTencentHousekeeper from './components/AddTencentHousekeeper.vue'
import ImportTencentHousekeeper from './components/ImportTencentHousekeeper.vue'
import { getTencentHousekeeperList, refreshChildAccount, authTencentHousekeeper } from '@/network/api/account/tencentAdvAccount.js'

export default {
  components: {
    TablePane,
    'add-housekeeper': AddTencentHousekeeper,
    'import-housekeeper': ImportTencentHousekeeper
  },
  data() {
    return {
      search: {
        serviceAccountId: '',
        corporationName: ''
      },
      dataSource: {
        data: [],
        cols: [
          {
            label: '账户ID',
            prop: 'serviceAccountId'
          },
          {
            label: '账户名称',
            prop: 'corporationName',
            'show-overflow-tooltip': true
          },
          {
            label: '关联运营者微信号',
            prop: 'relationWx'
          },
          {
            label: '账户主体',
            prop: 'mdmName',
            'show-overflow-tooltip': true
          },
          {
            label: '绑定子客账户',
            prop: 'childrenAccountNum',
            render: (row) => {
              return (
                <div>
                  <span>{row.childrenAccountNum}</span>
                  <span class='el-icon-refresh icon-refresh' onClick={() => this.handleRefreshAccount(row.adMarketingApiServiceId)}></span>
                </div>
              )
            }
          },
          {
            label: '备注',
            prop: 'remark'
          }
        ], // 表格的列数据
        isIndex: true, // 列表序号
        loading: false, // loading
        isOperation: true,
        isSelection: false,
        pageData: {
          total: 0 // 总条数
        },
        operation: {
          // 表格有操作列时设置
          label: '操作', // 列名
          width: 100, // 根据实际情况给宽度
          fixed: 'right',
          data: [
            {
              icon: 'el-icon-paperclip',
              tooltip: '重新授权',
              handleRow: (index, row) => {
                this.handleAuthHousekeeper(row.adMarketingApiServiceId)
              }
            },
            {
              icon: 'el-icon-upload2',
              tooltip: '导入子客账户',
              handleRow: (index, row) => {
                this.$refs.importHousekeeper.showModal(row)
              }
            },
            {
              icon: 'el-icon-edit',
              tooltip: '编辑',
              handleRow: (index, row) => {
                this.handleAddHousekeeper(row)
              }
            }
          ]
        }
      }
    }
  },

  activated() {
    console.log('active')
  },

  created() { },

  mounted() {
    this.getPage()
  },
  methods: {
    /**
     * @description: 获取列表
     * @param {*} params
     */
    getPage(params = { pageNum: 1, pageSize: 10 }) {
      this.dataSource.loading = true
      let search = {
        ...this.search,
        serviceAccountId: this.search.serviceAccountId || 0,
        pageNum: params.pageNum || 1,
        pageSize: params.pageSize || 10
      }
      getTencentHousekeeperList(search).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          // records
          this.dataSource.data = records
          this.dataSource.pageData.total = total
          this.dataSource.loading = false
        }
      })
    },

    // 查询重置
    resetFormQuery(formName) {
      this.advInfo_visible = false
      this.editInfo_visible = false
      this.$refs[formName].resetFields()
      setTimeout(() => {
        this.getPage()
      }, 400)
    },

    // 点击刷新子客账户
    handleRefreshAccount(id) {
      this.dataSource.loading = true
      refreshChildAccount(id).then(res => {
        this.$message.success('子客账户更新完成！')
        this.dataSource.loading = false
        this.getPage()
      })
    },

    // 新增账户
    handleAddHousekeeper(row) {
      this.$refs.addHousekeeper.showModal(row)
    },

    // 授权账户
    handleAuth(url) {
      this.getPage()
      this.gdtClick(url)
    },

    // 重新授权
    handleAuthHousekeeper(id) {
      authTencentHousekeeper(id).then(res => {
        this.gdtClick(res.data)
      })
    },

    // 跳转授权链接
    async gdtClick(url) {
      if (!url) return
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
.icon-refresh {
  cursor: pointer;
  color: #333;
  margin-left: 10px;
}
</style>
