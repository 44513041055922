<!--
 * @Description: 复制公众号编辑区
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2022-02-25 09:50:21
-->

<template>
  <section>
    <base-box name="enterprise_wx_auto">
      <template slot="body">
        <el-divider></el-divider>
        <div>
          <p class="w-100 font-13">客服类型：</p>
          <div class="ml-20">
            <el-radio value="1" label="1">企业微信客服</el-radio>
          </div>
        </div>
        <div>
          <p class="w-100 font-13">添加类型：</p>
          <div class="ml-20" style="display: flex">
            <el-radio value="1" label="1">客服</el-radio>
          </div>
        </div>
        <div>
          <p class="w-100 font-13">客服分配：</p>
          <div class="ml-20">
            <p class="tip">广告模板发布时添加</p>
          </div>
        </div>
        <el-divider></el-divider>

        <div>
          <p class="w-100 font-13">按钮文案：</p>
          <div class="ml-20" style="display: flex">
            <el-input v-model="detail.btnTitle" style="width: 210px" maxlength="10" show-word-limit />
            <el-radio-group v-model="detail.btnFontType" size="small" class="ml-20">
              <el-radio-button label="0">常规</el-radio-button>
              <el-radio-button label="1">加粗</el-radio-button>
            </el-radio-group>
          </div>
        </div>

        <div>
          <p class="w-100 font-13">按钮字体色：</p>
          <div class="ml-20">
            <el-dropdown>
              <div class="ldy-bg-color" :style="`background:${detail.fontColor}`"></div>
              <el-dropdown-menu slot="dropdown">
                <twitter-color :colors="detail.fontColor" style="margin-top: 10px" @change="(value) => (detail.fontColor = value)" />
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <div>
          <p class="w-100 font-13">按钮边框色：</p>
          <div class="ml-20">
            <el-dropdown>
              <div class="ldy-bg-color" :style="`background:${detail.btnBorderColorTheme}`"></div>
              <el-dropdown-menu slot="dropdown">
                <twitter-color :colors="detail.btnBorderColorTheme" style="margin-top: 10px" @change="(value) => (detail.btnBorderColorTheme = value)" />
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <div>
          <p class="w-100 font-13">按钮填充色：</p>
          <div class="ml-20">
            <el-dropdown>
              <div class="ldy-bg-color" :style="`background:${detail.btnBgColorTheme}`"></div>
              <el-dropdown-menu slot="dropdown">
                <twitter-color :colors="detail.btnBgColorTheme" style="margin-top: 10px" @change="(value) => (detail.btnBgColorTheme = value)" />
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <div>
          <p class="w-100 font-13">上边距：</p>
          <div class="ml-20">
            <el-slider v-model="detail.paddingTop" show-input> </el-slider>
          </div>
        </div>

        <div>
          <p class="w-100 font-13">下边距：</p>
          <div class="ml-20">
            <el-slider v-model="detail.paddingBottom" show-input> </el-slider>
          </div>
        </div>
      </template>
    </base-box>
  </section>
</template>

<script>
import BaseBox from './base.vue'
import Twitter from '@/components/twitterColor.vue'
export default {
  components: {
    'base-box': BaseBox,
    'twitter-color': Twitter
  },
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    /**
     * @description: 打开资源库
     */
    openMaterialBox() {
      this.$emit('openMaterialBox')
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.update {
  width: 130px !important;
  height: 130px;
  line-height: 130px;
  text-align: center;
  border: 1px solid #edecf2;
  background-color: #fbfafd;
  border-radius: 3px;
  cursor: pointer;
  font-size: 30px;
  color: #7d7d7d;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .avatar {
    width: 130px;
  }
}

.image-updata {
  display: flex !important;
  align-items: flex-start !important;
}

.ldy-bg-color {
  width: 28px;
  height: 28px;
  border: 5px solid #bfc2c7;
  border-radius: 4px;
}

.setting {
  display: flex;
  align-items: flex-start !important;
}
</style>
