<!--
 * @Description: 工作区 -> 图文复合组件 一行一个
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2022-03-11 14:36:14
-->
<template>
  <div>
    <section>
      <template v-if="!setting">
        <div class="image-updata">
          <p>配图：</p>
          <div>
            <div class="update" @click="setMaterialBox">
              <img v-if="imgInfo.pureImageUrl" :src="imgInfo.pureImageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon" />
            </div>
            <p class="tip mt-10">
              图片格式：大小不超过300KB， <br />
              图片尺寸：宽度360像素，高度不超过360像素
            </p>
          </div>
        </div>

        <div>
          <p>标题：</p>
          <div class="ml-20">
            <el-input v-model="titleInfo.content" placeholder="请输入标题" maxlength="12" show-word-limit />
          </div>
        </div>

        <div>
          <p>描述：</p>
          <div class="ml-20">
            <el-radio-group v-model="rightInfo.descType" class="mt-20 mb-20" @change="changeDescType">
              <el-radio label="text">文字</el-radio>
              <el-radio label="price">价格</el-radio>
            </el-radio-group>

            <div>
              <el-input v-if="rightInfo.descType === 'text'" v-model="descInfo.content" placeholder="请输入文字描述" maxlength="15" show-word-limit />
              <el-input-number v-if="rightInfo.descType === 'price'" v-model="descInfo.content" :min="0" controls-position="right" placeholder="请输入价格" style="width: 250px" />
            </div>
          </div>
        </div>

        <div>
          <p class="w-100">字体色：</p>
          <div class="ml-20">
            <el-row>
              <el-col :span="12">
                <el-dropdown>
                  <div class="justify-start">
                    <span style="font-size:13px" class="mr-10">标题</span>
                    <div class="ldy-bg-color" :style="`background:${titleInfo.fontColor}`"></div>
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <twitter-color :colors="titleInfo.fontColor" style="margin-top: 10px" @change="(value) => (titleInfo.fontColor = value)" />
                  </el-dropdown-menu>
                </el-dropdown>
              </el-col>
              <el-col :span="12">
                <el-dropdown>
                  <div class="justify-start">
                    <span style="font-size:13px " class="mr-10">描述</span>
                    <div class="ldy-bg-color" :style="`background:${descInfo.fontColor}`"></div>
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <twitter-color :colors="buttonInfo.fontColor" style="margin-top: 10px" @change="(value) => (descInfo.fontColor = value)" />
                  </el-dropdown-menu>
                </el-dropdown>
              </el-col>
            </el-row>
          </div>
        </div>

        <div>
          <p class="w-100">其他颜色：</p>
          <div class="ml-20">
            <el-row>
              <el-col :span="12">
                <el-dropdown>
                  <div class="justify-start">
                    <span style="font-size:13px" class="mr-10">边框</span>
                    <div class="ldy-bg-color" :style="`background:${detail.borderColor}`"></div>
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <twitter-color :colors="detail.borderColor" style="margin-top: 10px" @change="(value) => (detail.borderColor = value)" />
                  </el-dropdown-menu>
                </el-dropdown>
              </el-col>
              <el-col :span="12">
                <el-dropdown>
                  <div class="justify-start">
                    <span style="font-size:13px" class="mr-10">背景</span>
                    <div class="ldy-bg-color" :style="`background:${detail.bgColor}`"></div>
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <twitter-color :colors="detail.bgColor" style="margin-top: 10px" @change="(value) => (detail.bgColor = value)" />
                  </el-dropdown-menu>
                </el-dropdown>
              </el-col>
            </el-row>
          </div>
        </div>

        <el-divider></el-divider>
        <div>
          <p>上边距：</p>
          <div class="ml-20">
            <el-slider v-model="detail.paddingTop" show-input> </el-slider>
          </div>
        </div>

        <div class="setting">
          <p>下边距：</p>
          <div class="ml-20">
            <el-slider v-model="detail.paddingBottom" show-input> </el-slider>
          </div>
        </div>

        <el-divider></el-divider>
        <div>
          <p>跳转方式：</p>
          <div class="ml-20">
            <el-radio-group v-model="dataInfo.jumpMode" class="mt-20 mb-20" @change="jumpModeType">
              <el-radio label="btn_jump">按钮跳转</el-radio>
              <el-radio label="total_jump">全局跳转</el-radio>
            </el-radio-group>
          </div>
        </div>

        <div class="setting">
          <p>按钮类型：</p>
          <div class="ml-20 justify-start">
            <el-select v-model="buttonInfo.widgetTypeV2" @change="buttonTypeChange">
              <!-- <el-option value="gh" label="关注公众号"></el-option> -->
              <el-option value="enterprise_wx_auto" label="添加商家微信"></el-option>
            </el-select>
            <el-button type="text" class="ml-20" @click="setting = true">设置</el-button>
          </div>
        </div>
      </template>
      <template v-else>
        <div>
          <p class="w-100">按钮文案：</p>
          <div class="ml-20" style="display: flex">
            <el-input v-model="buttonInfo.btnTitle" style="width: 160px" :maxlength="5" show-word-limit />
            <el-radio-group v-model="buttonInfo.btnFontType" size="small" class="ml-20">
              <el-radio-button label="0">常规</el-radio-button>
              <el-radio-button label="1">加粗</el-radio-button>
            </el-radio-group>
          </div>
        </div>

        <div>
          <p class="w-100">按钮字体色：</p>
          <div class="ml-20">
            <el-dropdown>
              <div class="ldy-bg-color" :style="`background:${buttonInfo.fontColor}`"></div>
              <el-dropdown-menu slot="dropdown">
                <twitter-color :colors="buttonInfo.fontColor" style="margin-top: 10px" @change="(value) => (buttonInfo.fontColor = value)" />
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <div>
          <p class="w-100">按钮边框色：</p>
          <div class="ml-20">
            <el-dropdown>
              <div class="ldy-bg-color" :style="`background:${buttonInfo.btnBorderColorTheme}`"></div>
              <el-dropdown-menu slot="dropdown">
                <twitter-color :colors="buttonInfo.btnBorderColorTheme" style="margin-top: 10px" @change="(value) => (buttonInfo.btnBorderColorTheme = value)" />
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <div>
          <p class="w-100">按钮填充色：</p>
          <div class="ml-20">
            <el-dropdown>
              <div class="ldy-bg-color" :style="`background:${buttonInfo.btnBgColorTheme}`"></div>
              <el-dropdown-menu slot="dropdown">
                <twitter-color :colors="buttonInfo.btnBgColorTheme" style="margin-top: 10px" @change="(value) => (buttonInfo.btnBgColorTheme = value)" />
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div style="width: 100%" @click="setting = false">
          <i class="el-icon-back"></i>
        </div>
      </template>

      <material-box
        :visible="materialBox_visible"
        :img-count="1"
        :image-list="imageList"
        name="ldy"
        :attribute="{
          key: 'img',
          value: '图片',
          count: 1,
          size: 300 * 1024,
          listType: 'image/jpg,image/jpeg,image/png',
          width: 360,
          height: 360
        }"
        @setMaterialBox="setMaterialBox"
        @submit="selectImg"
      />
    </section>
  </div>
</template>

<script>
import MaterialBox from '@/components/materialBox'
import Twitter from '@/components/twitterColor.vue'
import { widgetMapData } from '../../indexData'

export default {
  components: {
    MaterialBox,
    'twitter-color': Twitter
  },
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      setting: false,
      materialBox_visible: false,
      dataInfo: this.detail.layoutItems.componentItem[0],
      imgInfo: this.detail.layoutItems.componentItem[0].layoutItems.componentItem[0],
      rightInfo: this.detail.layoutItems.componentItem[0].layoutItems.componentItem[1],
      titleInfo: this.detail.layoutItems.componentItem[0].layoutItems.componentItem[1].layoutItems.componentItem[0],
      descInfo: this.detail.layoutItems.componentItem[0].layoutItems.componentItem[1].layoutItems.componentItem[1],
      buttonInfo: this.detail.layoutItems.componentItem[0].layoutItems.componentItem[1].layoutItems.componentItem[2]
    }
  },

  watch: {
    detail: {
      handler(value) {
        this.descInfo = this.detail.layoutItems.componentItem[0].layoutItems.componentItem[1].layoutItems.componentItem[1]
      },
      deep: true
    }
  },

  methods: {
    /**
     * @description: 打开/关闭资源库
     */
    setMaterialBox() {
      console.log(this.detail)
      this.materialBox_visible = !this.materialBox_visible
    },

    /**
     * @description: 描述 : 文字/价格
     */
    changeDescType(type) {
      const { detail } = this
      detail.id = ''
      if (type === 'text') {
        detail.layoutItems.componentItem[0].layoutItems.componentItem[1].layoutItems.componentItem[1] = {
          ...this.descInfo,
          content: '描述123',
          name: '描述'
        }
      } else {
        detail.layoutItems.componentItem[0].layoutItems.componentItem[1].layoutItems.componentItem[1] = {
          ...this.descInfo,
          content: 0,
          name: '价格'
        }
      }
      detail.id = `widget_${new Date().getTime()}_0`
    },

    /**
     * @description: 描述
     */
    changeDesc(value) {
      const { detail } = this
      detail.id = ''
      detail.layoutItems.componentItem[0].layoutItems.componentItem[1].layoutItems.componentItem[1] = {
        ...this.descInfo,
        content: value
      }

      detail.id = '1'
    },

    /**
     * @description: 跳转方式
     */
    jumpModeType(type) {
      const { detail } = this
      detail.id = ''

      let list = [...detail.layoutItems.componentItem]
      if (type === 'total_jump') {
        list[1] = {
          // ...this.buttonInfo,
          // paddingBottom: '0',
          // paddingLeft: '0',
          // paddingRight: '0',
          // paddingTop: '0',
          // btnHeight: '228',
          // layoutHeight: '228',
          // layoutWidth: '750',
          // fontColor: 'clear',
          // btnBgColorTheme: 'clear',
          // btnBorderColorTheme: 'clear',
          // btnTitle: ''
          borderSize: '0',
          btnBgColorTheme: 'clear',
          btnBorderColorTheme: 'clear',
          btnFontType: '0',
          btnHeight: '228',
          btnJumpUrl: '',
          btnStyle: '1',
          btnTitle: '',
          btnType: '0',
          chatGroupName: '1028测试3',
          cornerRadius: 4,
          corpid: '',
          fontColor: 'clear',
          fontSize: '26',
          groupid: 0,
          h5_config_id: '',
          id: 'widget_1636623372008_10',
          layoutHeight: '228',
          layoutWidth: '750',
          mpJumpType: '1',
          name: '商品添加商家微信',
          needUpdateQrUrl: 0,
          origBtnJumpUrl: '',
          paddingBottom: '0',
          paddingLeft: '0',
          paddingRight: '0',
          paddingTop: '0',
          qrExtInfo: '',
          qrUrl: '',
          serviceType: '1',
          subType: '15',
          type: '21',
          useIcon: '0',
          widgetType: 'button',
          widgetTypeV2: 'enterprise_wx_auto',
          wxapp_config_id: ''
        }
        detail.layoutItems.componentItem = list
      } else {
        detail.layoutItems.componentItem = list.slice(0, 1)
      }
      console.log(detail)
      detail.id = `widget_${new Date().getTime()}_0`
    },

    /**
     * @description: 按钮类型
     */

    buttonTypeChange(value) {
      const { detail } = this
      detail.id = ''
      if (value === 'gh') {
        detail.layoutItems.componentItem[0].layoutItems.componentItem[1].layoutItems.componentItem[2] = {
          ...this.buttonInfo,
          ...widgetMapData.copyGh,
          paddingBottom: '0',
          paddingLeft: '0',
          paddingRight: '0',
          paddingTop: '0',
          fontSize: '26'
        }
      } else {
        detail.layoutItems.componentItem[0].layoutItems.componentItem[1].layoutItems.componentItem[2] = {
          ...this.buttonInfo,
          ...widgetMapData.enterprise_wx_auto,
          paddingBottom: '0',
          paddingLeft: '0',
          paddingRight: '0',
          paddingTop: '0',
          fontSize: '26'
        }
      }
      detail.id = `widget_${new Date().getTime()}_0`
    },

    /**
     * @description: 选择图片后调用
     * @param {Array} img 图片信息
     */
    selectImg(img) {
      this.imageList = img
      let { imgInfo } = this
      const { height, width, image } = img[0]
      imgInfo.imageHeight = height
      imgInfo.imageWidth = width
      imgInfo.pureImageUrl = image
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
section {
  flex: 1;
  & > div {
    display: flex;
    justify-content: flex-start;
    margin: 20px 0;
    align-items: center;
    & > p {
      width: 70px;
      font-size: 13px;
      color: rgb(78, 78, 78);
      text-align: right;
    }
    & > div {
      width: 85%;
    }
  }
}
.update {
  width: 130px !important;
  height: 130px;
  line-height: 130px;
  text-align: center;
  border: 1px solid #edecf2;
  background-color: #fbfafd;
  border-radius: 3px;
  cursor: pointer;
  font-size: 30px;
  color: #7d7d7d;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .avatar {
    width: 130px;
  }
}

.image-updata {
  display: flex !important;
  align-items: flex-start !important;
}

.ldy-bg-color {
  width: 28px;
  height: 28px;
  border: 5px solid #bfc2c7;
  border-radius: 4px;
}

// .setting {
//   display: flex;
//   justify-content: flex-start;
//   margin: 10px 0;
//   align-items: center;
//   & > p {
//     font-size: 14px;
//     color: rgb(78, 78, 78);
//   }
//   & > div {
//     width: 80%;
//     margin-left: 30px;
//   }
// }
</style>
