export let searchForm = {
  convertId: null,
  id: null,
  name: null,
  optStatus: null,
  convertSourceType: null,
  status: null,
  convertType: null,
  actionTrackUrl: null,
  relationHousekeeperName: null,
  relationHousekeeperId: null,
  relationAdvAccountName: null,
  relationAdvId: null,
  deleted: null,
  createBy: null,
  createTime: null,
  updateBy: null,
  updateTime: null,
  remark: null
}

export class AssetManagementHugeConvertEnt {
  convertId
  id
  name
  optStatus
  convertSourceType
  status
  convertType
  actionTrackUrl
  relationHousekeeperName
  relationHousekeeperId
  relationAdvAccountName
  relationAdvId
  deleted
  createBy
  createTime
  updateBy
  updateTime
  remark
}

export let formRules = {
  convertId: [{ required: true, message: '请填写', trigger: 'change' }],

  id: [{ required: true, message: '请填写转化id', trigger: 'change' }],
  name: [{ required: true, message: '请填写转化名称', trigger: 'change' }],

  deleted: [{ required: true, message: '请填写逻辑删除', trigger: 'change' }],
  createBy: [{ required: true, message: '请填写创建者', trigger: 'change' }],
  createTime: [{ required: true, message: '请填写创建时间', trigger: 'change' }],
  updateBy: [{ required: true, message: '请填写更新者', trigger: 'change' }],
  updateTime: [{ required: true, message: '请填写更新时间', trigger: 'change' }],
  remark: [{ required: true, message: '请填写备注', trigger: 'change' }]
}

export let convertType = {
  AD_CONVERT_TYPE_EFFECTIVE_PLAY: '有效播放',
  AD_CONVERT_TYPE_CLICK_NUM: '点击量',
  AD_CONVERT_TYPE_SHOW_OFF_NUM: '展示量',
  AD_CONVERT_TYPE_PHONE: '电话拨打',
  AD_CONVERT_TYPE_FORM: '表单提交',
  AD_CONVERT_TYPE_MAP_SEARCH: '地图搜索',
  AD_CONVERT_TYPE_DOWNLOAD_FINISH: '下载完成',
  AD_CONVERT_TYPE_BUTTON: '按钮跳转',
  AD_CONVERT_TYPE_XPATH: 'xpath类型转换',
  AD_CONVERT_TYPE_VIEW: '关键页面浏览',
  AD_CONVERT_TYPE_ACTIVE: '激活',
  AD_CONVERT_TYPE_DOWNLOAD_START: '下载按钮download_start',
  AD_CONVERT_TYPE_QQ: 'qq咨询',
  AD_CONVERT_TYPE_LOTTERY: '抽奖',
  AD_CONVERT_TYPE_VOTE: '投票',
  AD_CONVERT_TYPE_ACTIVE_REGISTER: '激活且注册',
  AD_CONVERT_TYPE_PAY: '激活且付费',
  AD_CONVERT_TYPE_INSTALL_FINISH: '安装完成',
  AD_CONVERT_TYPE_PHONE_CONFIRM: '智能电话-确认拨打',
  AD_CONVERT_TYPE_PHONE_CONNECT: '智能电话-确认接通',
  AD_CONVERT_TYPE_PHONE_EFFECTIVE: '智能电话-有效接通',
  AD_CONVERT_TYPE_CONSULT_EFFECTIVE: '有效咨询',
  AD_CONVERT_TYPE_APP_ORDER: 'app内下单（电商）',
  AD_CONVERT_TYPE_APP_UV: 'app内访问',
  AD_CONVERT_TYPE_APP_CART: 'app内添加购物车（电商）',
  AD_CONVERT_TYPE_APP_PAY: 'app内付费',
  AD_CONVERT_TYPE_SALES_LEAD: '销售线索',
  AD_CONVERT_TYPE_GAME_ADDICTION: '关键行为（原深度转化）',
  AD_CONVERT_TYPE_CUSTOMER_EFFECTIVE: '有效获客',
  AD_CONVERT_TYPE_EFFECTIVE_COPY: '关键页面到达&有效内容复制',
  AD_CONVERT_TYPE_COUPON: '卡券领取',
  AD_CONVERT_TYPE_APP_DETAIL_UV: 'app内详情页到站uv',
  AD_CONVERT_TYPE_RSS: '账号关注',
  AD_CONVERT_TYPE_FORM_CONNECT: '表单提交-已接通',
  AD_CONVERT_TYPE_FORM_ANSWER: '有效沟通',
  AD_CONVERT_TYPE_DIALBACK: '提交回呼电话',
  AD_CONVERT_TYPE_DIALBACK_CONFIRM: '回呼电话-确认拨打',
  AD_CONVERT_TYPE_DIALBACK_CONNECT: '回呼电话-确认接通',
  AD_CONVERT_TYPE_FORM_DEEP: '分层表单',
  AD_CONVERT_TYPE_UPDATE_LEVEL: '激活且升级',
  AD_CONVERT_TYPE_CREATE_GAMEROLE: '激活且创建角色',
  AD_CONVERT_TYPE_NEXT_DAY_OPEN: '激活且次留',
  AD_CONVERT_TYPE_INVALID_CLUE: '无效线索',
  AD_CONVERT_TYPE_INTENTION_CLUE: '有意向客户',
  AD_CONVERT_TYPE_HIGH_VALUE_CLUE: '高价值客户',
  AD_CONVERT_TYPE_PAID_CLUE: '已成单',
  AD_CONVERT_TYPE_NATIVE_ACTION: '原生互动',
  AD_CONVERT_TYPE_LIKE_ACTION: '视频点赞',
  AD_CONVERT_TYPE_FOLLOW_ACTION: '账户关注',
  AD_CONVERT_TYPE_COMMENT_ACTION: '视频评论',
  AD_CONVERT_TYPE_LOCATION_ACTION: 'POI点击',
  AD_CONVERT_TYPE_SHOPPING_ACTION: '购物车点击',
  AD_CONVERT_TYPE_REDIRECT_TO_SHOP: '调起店铺',
  AD_CONVERT_TYPE_LINK_ACTION: 'link点击',
  AD_CONVERT_TYPE_DEEP_PURCHASE: '多次付费',
  AD_CONVERT_TYPE_SUCCESSORDER_ACTION: '小店转化',
  AD_CONVERT_TYPE_POI_COLLECT: 'poi地址点击',
  AD_CONVERT_TYPE_POI_ADDRESS_CLICK: 'poi收藏',
  AD_CONVERT_TYPE_RESERVATION: 'poi预定',
  AD_CONVERT_TYPE_MESSAGE_ACTION: '私信消息',
  AD_CONVERT_TYPE_SHARE_ACTION: '分享',
  AD_CONVERT_TYPE_CLICK_LANDING_PAGE: '访问推广详情页',
  AD_CONVERT_TYPE_CLICK_SHOPWINDOW: '访问主页商品橱窗',
  AD_CONVERT_TYPE_CLICK_DOWNLOAD: '访问主页下载应用',
  AD_CONVERT_TYPE_CLICK_CALL_DY: '点击主页内电话拨打',
  AD_CONVERT_TYPE_CLICK_WEBSITE: '访问主页官网',
  AD_CONVERT_PAGE_VIEW: '访问目标页面',
  AD_CONVERT_TYPE_MESSAGE: '短信',
  AD_CONVERT_TYPE_REDIRECT: '页面跳转',
  AD_CONVERT_TYPE_SHOPPING: '商品购买',
  AD_CONVERT_TYPE_CONSULT: '在线咨询',
  AD_CONVERT_TYPE_WECHAT: '微信',
  AD_CONVERT_TYPE_OTHER: '其他',
  AD_CONVERT_TYPE_MULTIPLE: '多转化事件',
  AD_CONVERT_TYPE_POI_MULTIPLE: 'POI门店多转化目标',
  AD_CONVERT_TYPE_INTERACTION: '互动',
  AD_CONVERT_TYPE_LOAN_COMPLETION: '互联网金融-完件',
  AD_CONVERT_TYPE_PRE_LOAN_CREDIT: '互联网金融-预授信',
  AD_CONVERT_TYPE_LOAN_CREDIT: '互联网金融-授信',
  AD_CONVERT_TYPE_IDCARD_INFORMATION: '身份证信息填写完成',
  AD_CONVERT_TYPE_BANKCARD_INFORMATION: '银行卡信息填写完成',
  AD_CONVERT_TYPE_PERSONAL_INFORMATION: '补充个人信息填写完成',
  AD_CONVERT_TYPE_CERTIFICATION_INFORMATION: '用户活体认证信息上传完成',
  AD_CONVERT_TYPE_LT_ROI: '广告变现ROI',
  AD_CONVERT_TYPE_LIVE_HOMEPAGE: '直播导流',
  AD_CONVERT_TYPE_REDIRECT_TO_STORE: '店铺导流',
  AD_CONVERT_TYPE_FEED_LIVE_HOMEPAGE: '火山feed进入直播页',
  AD_CONVERT_TYPE_AUTHORIZATION: '授权（电商）',
  AD_CONVERT_TYPE_COMMODITY_CLICK: '快上电商推广目的',
  AD_CONVERT_TYPE_CONSULT_CLUE: '留咨咨询',
  AD_CONVERT_TYPE_BOOST: '自然助推',
  AD_CONVERT_TYPE_STAY_TIME: '店铺停留',
  AD_CONVERT_TYPE_PURCHASE_OF_GOODS: '商品签收',
  AD_CONVERT_TYPE_PURCHASE_ROI: '付费ROI',
  AD_CONVERT_TYPE_LIVE_NATIVE_ACITON: '直播间原生互动',
  AD_CONVERT_TYPE_LIVE_FOLLOW_ACITON: '直播间关注',
  AD_CONVERT_TYPE_LIVE_COMMENT_ACTION: '直播间评论',
  AD_CONVERT_TYPE_LIVE_GIFT_ACTION: '直播间内打赏',
  AD_CONVERT_TYPE_LIVE_SLIDECART_CLICK_ACTION: '直播间查看购物车',
  AD_CONVERT_TYPE_LIVE_CLICK_PRODUCT_ACTION: '直播间查看商品',
  AD_CONVERT_TYPE_LIVE_ENTER_ACTION: '直播间观看',
  AD_CONVERT_TYPE_LIVE_SUCCESSORDER_ACTION: '直播间成单',
  AD_CONVERT_TYPE_NOTIFY_DOWNLOAD: '预约下载',
  AD_CONVERT_TYPE_PREMIUM_PAYMENT: '保险支付',
  AD_CONVERT_TYPE_MESSAGE_CLICK: '私信点击',
  AD_CONVERT_TYPE_UG_ROI: '内广roi',
  AD_CONVERT_TYPE_ENTER_HOMEPAGE: '进入个人主页',
  AD_CONVERT_TYPE_SHOPPING_CART: '商品购物车点击',
  AD_CONVERT_TYPE_WECHAT_REGISTER: '微信内注册',
  AD_CONVERT_TYPE_WECHAT_PAY: '微信内付费',
  AD_CONVERT_TYPE_MESSAGE_INTERACTION: '沟通互动',
  AD_CONVERT_TYPE_LIVE_STAY_TIME: '直播间停留',
  AD_CONVERT_TYPE_NEW_FOLLOW_ACTION: '粉丝增长',
  AD_CONVERT_TYPE_APPLET_CLICK: '小程序互动',
  AD_CONVERT_TYPE_MESSAGE_SERVICE: '私信服务',
  AD_CONVERT_TYPE_MESSAGE_CLUE: '私信留资',
  AD_CONVERT_TYPE_LIVE_FANS_ACTION: '直播间加入粉丝团',
  AD_CONVERT_TYPE_CLUE_CONFIRM: '回访_信息确认',
  AD_CONVERT_TYPE_CLUE_INTERFLOW: '回访_加为好友',
  AD_CONVERT_TYPE_CLUE_HIGH_INTENTION: '回访_高潜成交',
  AD_CONVERT_TYPE_SUBMIT_CERTIFICATION: '提交认证',
  AD_CONVERT_TYPE_FIRST_RENTAL_ORDER: '首次发单',
  AD_CONVERT_TYPE_LIVE_COMPONENT_CLICK: '组件点击',
  AD_CONVERT_TYPE_LIVE_BUSINESS_FITTING: '直播间组件点击',
  AD_CONVERT_TYPE_CLUE_PAY_SUCCEED: '支付_存在意向',
  AD_CONVERT_TYPE_OTO_STAY_TIME: '团单浏览',
  AD_CONVERT_TYPE_OTO_PAY: '团购支付',
  AD_CONVERT_TYPE_PREMIUM_ROI: '保费ROI',
  AD_CONVERT_TYPE_MESSAGE_ORDER_SUCCESS: '私信成单',
  AD_CONVERT_TYPE_MESSAGE_JOIN_GROUP: '私信用户入群',
  AD_CONVERT_TYPE_LIVE_JOIN_GROUP: '粉丝入群',
  AD_CONVERT_TYPE_LIVE_APPOINTMENT: '预约直播',
  AD_CONVERT_TYPE_FOLLOW_LIVE_ENTER: '粉丝访问直播',
  AD_CONVERT_TYPE_FOLLOW_CLICK_PRODUCT: '关注并加购',
  AD_CONVERT_TYPE_FOLLOW_VIDEO_PLAY_FINISH: '粉丝观看',
  AD_CONVERT_TYPE_GAMESTATION_DOWNLOAD_DOUPLUS: '游戏站下载 - DOU +',
  AD_CONVERT_TYPE_QC_FOLLOW_ACTION: '提升粉丝',
  AD_CONVERT_TYPE_QC_MUST_BUY: '内容种草',
  AD_CONVERT_TYPE_NOTIFY_FORM: '预约表单',
  AD_CONVERT_TYPE_IPU_QUALIFY: '激活首日广告展示达标',
  AD_CONVERT_TYPE_ANCHOR_CLICK: '直播支付ROI'
}
