var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "section",
      [
        !_vm.setting
          ? [
              _c("div", { staticClass: "image-updata" }, [
                _c("p", [_vm._v("配图：")]),
                _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "update",
                      on: { click: _vm.setMaterialBox },
                    },
                    [
                      _vm.imgInfo.pureImageUrl
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.imgInfo.pureImageUrl },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                    ]
                  ),
                  _vm._m(0),
                ]),
              ]),
              _c("div", [
                _c("p", [_vm._v("标题：")]),
                _c(
                  "div",
                  { staticClass: "ml-20" },
                  [
                    _c("el-input", {
                      attrs: {
                        placeholder: "请输入标题",
                        maxlength: "12",
                        "show-word-limit": "",
                      },
                      model: {
                        value: _vm.titleInfo.content,
                        callback: function ($$v) {
                          _vm.$set(_vm.titleInfo, "content", $$v)
                        },
                        expression: "titleInfo.content",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c("p", [_vm._v("描述：")]),
                _c(
                  "div",
                  { staticClass: "ml-20" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "mt-20 mb-20",
                        on: { change: _vm.changeDescType },
                        model: {
                          value: _vm.rightInfo.descType,
                          callback: function ($$v) {
                            _vm.$set(_vm.rightInfo, "descType", $$v)
                          },
                          expression: "rightInfo.descType",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "text" } }, [
                          _vm._v("文字"),
                        ]),
                        _c("el-radio", { attrs: { label: "price" } }, [
                          _vm._v("价格"),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _vm.rightInfo.descType === "text"
                          ? _c("el-input", {
                              attrs: {
                                placeholder: "请输入文字描述",
                                maxlength: "15",
                                "show-word-limit": "",
                              },
                              model: {
                                value: _vm.descInfo.content,
                                callback: function ($$v) {
                                  _vm.$set(_vm.descInfo, "content", $$v)
                                },
                                expression: "descInfo.content",
                              },
                            })
                          : _vm._e(),
                        _vm.rightInfo.descType === "price"
                          ? _c("el-input-number", {
                              staticStyle: { width: "250px" },
                              attrs: {
                                min: 0,
                                "controls-position": "right",
                                placeholder: "请输入价格",
                              },
                              model: {
                                value: _vm.descInfo.content,
                                callback: function ($$v) {
                                  _vm.$set(_vm.descInfo, "content", $$v)
                                },
                                expression: "descInfo.content",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c("p", { staticClass: "w-100" }, [_vm._v("字体色：")]),
                _c(
                  "div",
                  { staticClass: "ml-20" },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-dropdown",
                              [
                                _c("div", { staticClass: "justify-start" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "mr-10",
                                      staticStyle: { "font-size": "13px" },
                                    },
                                    [_vm._v("标题")]
                                  ),
                                  _c("div", {
                                    staticClass: "ldy-bg-color",
                                    style: `background:${_vm.titleInfo.fontColor}`,
                                  }),
                                ]),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  [
                                    _c("twitter-color", {
                                      staticStyle: { "margin-top": "10px" },
                                      attrs: {
                                        colors: _vm.titleInfo.fontColor,
                                      },
                                      on: {
                                        change: (value) =>
                                          (_vm.titleInfo.fontColor = value),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-dropdown",
                              [
                                _c("div", { staticClass: "justify-start" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "mr-10",
                                      staticStyle: { "font-size": "13px" },
                                    },
                                    [_vm._v("描述")]
                                  ),
                                  _c("div", {
                                    staticClass: "ldy-bg-color",
                                    style: `background:${_vm.descInfo.fontColor}`,
                                  }),
                                ]),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  [
                                    _c("twitter-color", {
                                      staticStyle: { "margin-top": "10px" },
                                      attrs: {
                                        colors: _vm.buttonInfo.fontColor,
                                      },
                                      on: {
                                        change: (value) =>
                                          (_vm.descInfo.fontColor = value),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c("p", { staticClass: "w-100" }, [_vm._v("其他颜色：")]),
                _c(
                  "div",
                  { staticClass: "ml-20" },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-dropdown",
                              [
                                _c("div", { staticClass: "justify-start" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "mr-10",
                                      staticStyle: { "font-size": "13px" },
                                    },
                                    [_vm._v("边框")]
                                  ),
                                  _c("div", {
                                    staticClass: "ldy-bg-color",
                                    style: `background:${_vm.detail.borderColor}`,
                                  }),
                                ]),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  [
                                    _c("twitter-color", {
                                      staticStyle: { "margin-top": "10px" },
                                      attrs: { colors: _vm.detail.borderColor },
                                      on: {
                                        change: (value) =>
                                          (_vm.detail.borderColor = value),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-dropdown",
                              [
                                _c("div", { staticClass: "justify-start" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "mr-10",
                                      staticStyle: { "font-size": "13px" },
                                    },
                                    [_vm._v("背景")]
                                  ),
                                  _c("div", {
                                    staticClass: "ldy-bg-color",
                                    style: `background:${_vm.detail.bgColor}`,
                                  }),
                                ]),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown",
                                  },
                                  [
                                    _c("twitter-color", {
                                      staticStyle: { "margin-top": "10px" },
                                      attrs: { colors: _vm.detail.bgColor },
                                      on: {
                                        change: (value) =>
                                          (_vm.detail.bgColor = value),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-divider"),
              _c("div", [
                _c("p", [_vm._v("上边距：")]),
                _c(
                  "div",
                  { staticClass: "ml-20" },
                  [
                    _c("el-slider", {
                      attrs: { "show-input": "" },
                      model: {
                        value: _vm.detail.paddingTop,
                        callback: function ($$v) {
                          _vm.$set(_vm.detail, "paddingTop", $$v)
                        },
                        expression: "detail.paddingTop",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "setting" }, [
                _c("p", [_vm._v("下边距：")]),
                _c(
                  "div",
                  { staticClass: "ml-20" },
                  [
                    _c("el-slider", {
                      attrs: { "show-input": "" },
                      model: {
                        value: _vm.detail.paddingBottom,
                        callback: function ($$v) {
                          _vm.$set(_vm.detail, "paddingBottom", $$v)
                        },
                        expression: "detail.paddingBottom",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("el-divider"),
              _c("div", [
                _c("p", [_vm._v("跳转方式：")]),
                _c(
                  "div",
                  { staticClass: "ml-20" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "mt-20 mb-20",
                        on: { change: _vm.jumpModeType },
                        model: {
                          value: _vm.dataInfo.jumpMode,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataInfo, "jumpMode", $$v)
                          },
                          expression: "dataInfo.jumpMode",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: "btn_jump" } }, [
                          _vm._v("按钮跳转"),
                        ]),
                        _c("el-radio", { attrs: { label: "total_jump" } }, [
                          _vm._v("全局跳转"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "setting" }, [
                _c("p", [_vm._v("按钮类型：")]),
                _c(
                  "div",
                  { staticClass: "ml-20 justify-start" },
                  [
                    _c(
                      "el-select",
                      {
                        on: { change: _vm.buttonTypeChange },
                        model: {
                          value: _vm.buttonInfo.widgetTypeV2,
                          callback: function ($$v) {
                            _vm.$set(_vm.buttonInfo, "widgetTypeV2", $$v)
                          },
                          expression: "buttonInfo.widgetTypeV2",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: {
                            value: "enterprise_wx_auto",
                            label: "添加商家微信",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "ml-20",
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            _vm.setting = true
                          },
                        },
                      },
                      [_vm._v("设置")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          : [
              _c("div", [
                _c("p", { staticClass: "w-100" }, [_vm._v("按钮文案：")]),
                _c(
                  "div",
                  { staticClass: "ml-20", staticStyle: { display: "flex" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "160px" },
                      attrs: { maxlength: 5, "show-word-limit": "" },
                      model: {
                        value: _vm.buttonInfo.btnTitle,
                        callback: function ($$v) {
                          _vm.$set(_vm.buttonInfo, "btnTitle", $$v)
                        },
                        expression: "buttonInfo.btnTitle",
                      },
                    }),
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "ml-20",
                        attrs: { size: "small" },
                        model: {
                          value: _vm.buttonInfo.btnFontType,
                          callback: function ($$v) {
                            _vm.$set(_vm.buttonInfo, "btnFontType", $$v)
                          },
                          expression: "buttonInfo.btnFontType",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: "0" } }, [
                          _vm._v("常规"),
                        ]),
                        _c("el-radio-button", { attrs: { label: "1" } }, [
                          _vm._v("加粗"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c("p", { staticClass: "w-100" }, [_vm._v("按钮字体色：")]),
                _c(
                  "div",
                  { staticClass: "ml-20" },
                  [
                    _c(
                      "el-dropdown",
                      [
                        _c("div", {
                          staticClass: "ldy-bg-color",
                          style: `background:${_vm.buttonInfo.fontColor}`,
                        }),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c("twitter-color", {
                              staticStyle: { "margin-top": "10px" },
                              attrs: { colors: _vm.buttonInfo.fontColor },
                              on: {
                                change: (value) =>
                                  (_vm.buttonInfo.fontColor = value),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c("p", { staticClass: "w-100" }, [_vm._v("按钮边框色：")]),
                _c(
                  "div",
                  { staticClass: "ml-20" },
                  [
                    _c(
                      "el-dropdown",
                      [
                        _c("div", {
                          staticClass: "ldy-bg-color",
                          style: `background:${_vm.buttonInfo.btnBorderColorTheme}`,
                        }),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c("twitter-color", {
                              staticStyle: { "margin-top": "10px" },
                              attrs: {
                                colors: _vm.buttonInfo.btnBorderColorTheme,
                              },
                              on: {
                                change: (value) =>
                                  (_vm.buttonInfo.btnBorderColorTheme = value),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c("p", { staticClass: "w-100" }, [_vm._v("按钮填充色：")]),
                _c(
                  "div",
                  { staticClass: "ml-20" },
                  [
                    _c(
                      "el-dropdown",
                      [
                        _c("div", {
                          staticClass: "ldy-bg-color",
                          style: `background:${_vm.buttonInfo.btnBgColorTheme}`,
                        }),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c("twitter-color", {
                              staticStyle: { "margin-top": "10px" },
                              attrs: { colors: _vm.buttonInfo.btnBgColorTheme },
                              on: {
                                change: (value) =>
                                  (_vm.buttonInfo.btnBgColorTheme = value),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  staticStyle: { width: "100%" },
                  on: {
                    click: function ($event) {
                      _vm.setting = false
                    },
                  },
                },
                [_c("i", { staticClass: "el-icon-back" })]
              ),
            ],
        _c("material-box", {
          attrs: {
            visible: _vm.materialBox_visible,
            "img-count": 1,
            "image-list": _vm.imageList,
            name: "ldy",
            attribute: {
              key: "img",
              value: "图片",
              count: 1,
              size: 300 * 1024,
              listType: "image/jpg,image/jpeg,image/png",
              width: 360,
              height: 360,
            },
          },
          on: { setMaterialBox: _vm.setMaterialBox, submit: _vm.selectImg },
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "tip mt-10" }, [
      _vm._v(" 图片格式：大小不超过300KB， "),
      _c("br"),
      _vm._v(" 图片尺寸：宽度360像素，高度不超过360像素 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }