<!--
 * @Description: 计划/ 广告 展示 tree
 * @Date: 2021-12-06 15:57:16
 * @LastEditTime: 2022-03-08 17:27:15
-->
<template>
  <div style="height: 300px;overflow-y: auto;" class="advertisement">
    <el-tree :data="treeData" node-key="id" default-expand-all :expand-on-click-node="false">
      <span slot-scope="row" class="custom-tree-node">
        <el-tooltip :content="row.node.label" placement="top">
          <span class="label">{{ row.node.label }}</span>
        </el-tooltip>
        <span v-if="row.node.level !== 1" class="handleTree-icon">
          <i class="el-icon-circle-close" @click="() => remove(row.node, row.data)"></i>
        </span>
      </span>
    </el-tree>
    <!-- 添加计划 -->
    <SelectModal
      ref="modal"
      dialog-title="推广广告"
      left-title="推广计划"
      :title-info="{
        title: '选择推广广告',
        leftLevel: '2',
        rightLevel: '1'
      }"
      :visible="adModalVisible"
      :left-query-list="planData"
      :data="myAdGroup"
      :adv="true"
      :total="total"
      :product-name="productName"
      :select-page-list="adData"
      @onOk="getSelectAdGroup"
    />
  </div>
</template>

<script>
import { getAdGroups, saveAdv, getAccountAndPlan, deleteAdv } from '@/network/api/advManagement/advManagementTencent' // 获取计划模板
import SelectModal from '../component/selectModal/selectModal.vue'

export default {
  provide: function() {
    return {
      getDataByLeftQuery: this.getAdGroupsFun,
      delete: this.delete
    }
  },

  inject: ['close'],

  components: {
    SelectModal
  },

  props: {
    adModalVisible: {
      type: Boolean,
      default: false
    },
    adData: {
      type: Array,
      default: () => []
    },
    accountAdv: {
      type: Array,
      default: () => []
    },
    taskId: {
      type: Number,
      default: 0
    },
    productName: {
      type: String,
      default: ''
    },
    taskSiteSetStr: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      data: this.adData,
      accountData: [],
      flag: false,
      myAdGroup: [],
      total: 0
    }
  },

  computed: {
    treeData() {
      const data = []
      this.accountData.forEach(item => {
        let children = []
        const adv = this.data.filter(f => f.taskAccountId === item._id)[0]
        if (adv && adv.adgroupId) {
          children = [{
            id: adv?.taskCampaignAdInfoId,
            label: adv?.adgroupName
          }]
        }
        data.push({
          id: item._id,
          label: item._name,
          children
        })
      })
      return data
    },
    planData() {
      const data = []
      this.accountData.forEach(item => {
        const adv = this.data.filter(f => f.taskAccountId === item._id)[0]
        data.push({
          id: item._id,
          label: item._name + '(' + item.taaccountId + ')',
          localAccountId: item.localAccountId,
          taskCampaignAdInfoId: 0,
          children: [{
            id: adv?.campaignId,
            label: adv?.campaignName,
            taskAccountId: item._id,
            localAccountId: item.localAccountId,
            taskCampaignAdInfoId: adv?.taskCampaignAdInfoId
          }]
        })
      })
      return data
    }
  },

  watch: {
    adData(value) {
      this.data = value
    },
    accountAdv(value) {
      this.accountData = []
      this.accountData = value
    },
    adModalVisible(value) {
      if (!value) return
      // this.getAccountAndPlanFun()
    }
  },

  methods: {
    remove(node, data) {
      const parent = node.parent
      const children = parent.data.children || parent.data
      const index = children.findIndex((d) => d.id === data.id)
      children.splice(index, 1)
      this.delete(data.id)
    },

    delete(id) {
      this.$emit('clearTaskGenerate')
      deleteAdv(id).then(({ code }) => {
        if (code === 200) {
          this.$emit('byTaskIdGetAllFun')
          this.$message.success('删除广告成功!')
          this.close()
        }
      })
    },

    async getAccountAndPlanFun() {
      const { code, data } = await getAccountAndPlan(this.taskId)
      if (code === 200) {
        this.planData = data
      }
    },

    /**
     * @desc 获取当前选中的计划下的广告
     */
    async getAdGroupsFun(item, pageNum = 1, text = null) {
      const params = {
        pageNum,
        pageSize: 10,
        localAccountId: item?.localAccountId,
        campaignId: item.children ? 0 : item.id,
        adgroupName: text || null,
        siteSet: this.taskSiteSetStr
      }
      const { code, data } = await getAdGroups(params)
      if (code === 200) {
        this.total = data.pageInfo.totalNumber
        data.list.forEach((ele) => {
          ele.id = `${item.id}+${ele.adgroupId}+${item.taskAccountId}`
          ele.label = ele.adgroupName
          ele.taskAccountId = item.taskAccountId || null
          ele.key = `${item.id}+${item.taskAccountId}`
        })
        // 判线上计划板是否被选中, 选中置灰
        if (this.data.length) {
          data.list.forEach((it) => {
            this.data.map((el) => {
              if (it.id === `${el.campaignId}+${el.adgroupId}+${el.taskAccountId}`) {
                it.taskCampaignAdInfoId = el.taskCampaignAdInfoId
                it.disabled = true
              }
            })
          })
        }
        this.myAdGroup = data.list
      }
    },

    /**
     * @desc 选中的广告后提交
     */
    getSelectAdGroup(value) {
      if (!value || !value.length) {
        this.$message.error('请选择广告!')
        return
      }
      let params = []
      value.map((item) => {
        params.push({
          adgroupId: item.adgroupId,
          adgroupName: item.adgroupName,
          localAdgroupName: item.localAdgroupName || null,
          taskAccountId: item.taskAccountId,
          tABidAmount: item.bidAmount,
          dateConfiguration: item.dateConfiguration,
          launchDate: item.launchDate,
          launchTime: item.launchTime
        })
      })
      this.$emit('clearTaskGenerate')
      saveAdv(params).then((res) => {
        if (res.code === 200) {
          this.$emit('byTaskIdGetAllFun')
          this.$message.success('广告保存成功!')
          this.close()
        }
      })
    }
  }
}
</script>

<style lang="scss">
.handleTree-icon :hover {
  color: #409eff;
}

.advertisement {
  .custom-tree-node {
    // flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    width: 100px !important;
    .label {
      width: 300px !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
</style>
