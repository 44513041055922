var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card", closable: "" },
          on: { "tab-remove": _vm.removeTab },
          model: {
            value: _vm.editableTabsValue,
            callback: function ($$v) {
              _vm.editableTabsValue = $$v
            },
            expression: "editableTabsValue",
          },
        },
        _vm._l(_vm.breadcrumbMenu, function (item) {
          return _c(
            "el-tab-pane",
            { key: item.url, attrs: { name: item.url } },
            [
              _c(
                "template",
                { slot: "label" },
                [
                  _c(
                    "a-dropdown",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "el-dropdown-link",
                          staticStyle: { display: "inline-block" },
                          attrs: { to: item.url, tag: "span" },
                        },
                        [_vm._v(" " + _vm._s(item.name) + " ")]
                      ),
                      _c(
                        "a-menu",
                        {
                          attrs: { slot: "overlay" },
                          on: { click: (e) => _vm.select(e, item.url) },
                          slot: "overlay",
                        },
                        [
                          _c("a-menu-item", { key: 2 }, [
                            _c("a", { attrs: { href: "javascript:;" } }, [
                              _vm._v("关闭当前"),
                            ]),
                          ]),
                          _c("a-menu-item", { key: 3 }, [
                            _c("a", { attrs: { href: "javascript:;" } }, [
                              _vm._v("关闭其他"),
                            ]),
                          ]),
                          _c("a-menu-item", { key: 4 }, [
                            _c("a", { attrs: { href: "javascript:;" } }, [
                              _vm._v("关闭所有"),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }