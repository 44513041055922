var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "section" },
    [
      _c(
        "base-box",
        { attrs: { name: "text" } },
        [
          _c("template", { slot: "body" }, [
            _c("div", { staticClass: "setting" }, [
              _c("p", { staticClass: "w-100" }, [_vm._v("文本内容：")]),
              _c(
                "div",
                [
                  _c("el-input", {
                    attrs: {
                      value: _vm.detail.content,
                      type: "textarea",
                      rows: 15,
                      placeholder: "请输入内容",
                    },
                    on: { input: _vm.inputContent },
                  }),
                ],
                1
              ),
            ]),
            _c("div", [
              _c("p", { staticClass: "w-100" }, [_vm._v("字符样式：")]),
              _c("div", { staticStyle: { display: "flex" } }, [
                _c(
                  "div",
                  { staticClass: "mr-10" },
                  [
                    _c("el-input-number", {
                      attrs: { size: "small" },
                      model: {
                        value: _vm.detail.fontSize,
                        callback: function ($$v) {
                          _vm.$set(_vm.detail, "fontSize", $$v)
                        },
                        expression: "detail.fontSize",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mr-10" },
                  [
                    _c(
                      "el-dropdown",
                      [
                        _c("div", {
                          staticClass: "ldy-bg-color",
                          style: `background:${_vm.detail.fontColor}`,
                        }),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c("twitter-color", {
                              staticStyle: { "margin-top": "10px" },
                              attrs: { colors: _vm.detail.fontColor },
                              on: {
                                change: (value) =>
                                  (_vm.detail.fontColor = value),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mr-10" },
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: { size: "small" },
                        model: {
                          value: _vm.detail.showType,
                          callback: function ($$v) {
                            _vm.$set(_vm.detail, "showType", $$v)
                          },
                          expression: "detail.showType",
                        },
                      },
                      [
                        _c("el-radio-button", { attrs: { label: "normal" } }, [
                          _vm._v("常规"),
                        ]),
                        _c("el-radio-button", { attrs: { label: "bold" } }, [
                          _vm._v("加粗"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", [
              _c("p", { staticClass: "w-100" }, [_vm._v("对齐方式：")]),
              _c(
                "div",
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "small" },
                      on: { change: _vm.setImgCount },
                      model: {
                        value: _vm.detail.textAlignment,
                        callback: function ($$v) {
                          _vm.$set(_vm.detail, "textAlignment", $$v)
                        },
                        expression: "detail.textAlignment",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "left" } }, [
                        _vm._v("左对齐"),
                      ]),
                      _c("el-radio-button", { attrs: { label: "center" } }, [
                        _vm._v("居中对齐"),
                      ]),
                      _c("el-radio-button", { attrs: { label: "right" } }, [
                        _vm._v("右对齐"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", [
              _c("p", { staticClass: "w-100" }, [_vm._v("上边距：")]),
              _c(
                "div",
                [
                  _c("el-slider", {
                    attrs: { "show-input": "" },
                    model: {
                      value: _vm.detail.paddingTop,
                      callback: function ($$v) {
                        _vm.$set(_vm.detail, "paddingTop", $$v)
                      },
                      expression: "detail.paddingTop",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", [
              _c("p", { staticClass: "w-100" }, [_vm._v("下边距：")]),
              _c(
                "div",
                [
                  _c("el-slider", {
                    attrs: { "show-input": "" },
                    model: {
                      value: _vm.detail.paddingBottom,
                      callback: function ($$v) {
                        _vm.$set(_vm.detail, "paddingBottom", $$v)
                      },
                      expression: "detail.paddingBottom",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }