<!--
 * @Description: 颜色选择
 * @Date: 2021-10-19 09:06:01
 * @LastEditTime: 2022-03-31 10:03:34
-->

<template>
  <twitter-picker v-model="colors" :default-colors="colorArr" style="margin-top: 10px" @input="change" />
</template>

<script>
import { Twitter } from 'vue-color'
export default {
  components: {
    'twitter-picker': Twitter
  },
  props: {
    colors: {
      type: String,
      default: '#ffffff'
    }
  },
  data() {
    return {
      colorArr: ['#ffffff', '#008000', '#FFFF00', '#000000', '#0000FF', '#FF0000', '#417505', '#FFF2E7', '#f62320', '#07C160']
    }
  },
  methods: {
    change(value) {
      this.$emit('change', value.hex)
    }
  }
}
</script>

<style lang="scss" scoped></style>
