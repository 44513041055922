var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.editors === ""
      ? _c("section", { staticClass: "ad-editors" }, [_vm._v(" 未选中组件 ")])
      : _c(
          "section",
          { staticClass: "ad-editors" },
          [
            _c(_vm.editors, {
              tag: "component",
              attrs: { detail: _vm.detail },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }