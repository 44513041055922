var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        { ref: "search", attrs: { model: _vm.search } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "投放账户",
                prop: "accountId",
                rules: {
                  required: true,
                  message: "请选择投放账户",
                  trigger: "blur",
                },
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "600px" },
                  attrs: {
                    multiple: "",
                    "multiple-limit": 10,
                    filterable: "",
                    remote: "",
                    "reserve-keyword": "",
                    placeholder: "请输入关键词",
                    "remote-method": _vm.remoteMethod,
                    loading: _vm.loading,
                  },
                  model: {
                    value: _vm.search.accountId,
                    callback: function ($$v) {
                      _vm.$set(_vm.search, "accountId", $$v)
                    },
                    expression: "search.accountId",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.accountId,
                    attrs: {
                      label: `${item.corporationName}-${item.accountId}`,
                      value: item.accountId,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "资产类型",
                prop: "checkedCities",
                rules: {
                  required: true,
                  message: "请选择资产类型",
                  trigger: "blur",
                },
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { min: 0 },
                  model: {
                    value: _vm.search.checkedCities,
                    callback: function ($$v) {
                      _vm.$set(_vm.search, "checkedCities", $$v)
                    },
                    expression: "search.checkedCities",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "adManagementGdtBrand" } }, [
                    _vm._v("品牌形象"),
                  ]),
                  _c("el-radio", { attrs: { label: "adManagementGdtPages" } }, [
                    _vm._v("阅宝落地页"),
                  ]),
                  _c(
                    "el-radio",
                    { attrs: { label: "adManagementGdtProductCatalogs" } },
                    [_vm._v("商品库")]
                  ),
                  _c(
                    "el-radio",
                    { attrs: { label: "adManagementGdtCustomAudiences" } },
                    [_vm._v("人群包")]
                  ),
                  _c(
                    "el-radio",
                    { attrs: { label: "adManagementGdtUserActionSets" } },
                    [_vm._v("数据源")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    round: "",
                    disabled: _vm.active === 1,
                  },
                  on: { click: _vm.submitForm },
                },
                [
                  _vm.active === 1
                    ? _c("i", { staticClass: "el-icon-loading" })
                    : _vm._e(),
                  _vm._v(" 立即同步 "),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.active === 2
        ? _c("TablePane", {
            staticClass: "table",
            attrs: { "data-source": _vm.dataSource, border: "" },
            on: { getPage: _vm.getPage },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }