export let searchForm = {
  orangeCreateId: null,
  bricks: null,
  siteId: null,
  name: null,
  functionType: null,
  status: null,
  siteType: null,
  thumbnail: null,
  requestId: null,
  relationHousekeeperName: null,
  relationHousekeeperId: null,
  relationAdvAccountName: null,
  relationAdvId: null,
  deleted: null,
  createBy: null,
  createTime: null,
  updateBy: null,
  updateTime: null,
  remark: null
}

export class AssetManagementHugeCreateOrangeWebsiteEnt {
  orangeCreateId
  bricks
  siteId
  functionType
  name
  status
  siteType
  thumbnail
  requestId
  relationHousekeeperName
  relationHousekeeperId
  relationAdvAccountName
  relationAdvId
  deleted
  createBy
  createTime
  updateBy
  updateTime
  remark
}

export let formRules = {
  orangeCreateId: [{ required: true, message: '请填写', trigger: 'change' }],
  bricks: [{ required: true, message: '请填写具体见返回示例业务数据', trigger: 'change' }],
  siteId: [{ required: true, message: '请填写站点ID', trigger: 'change' }],
  name: [{ required: true, message: '请填写站点名称', trigger: 'change' }],
  status: [{ required: true, message: '请填写站点状态', trigger: 'change' }],
  siteType: [{ required: true, message: '请填写建站类型', trigger: 'change' }],
  thumbnail: [{ required: true, message: '请填写缩略图', trigger: 'change' }],
  requestId: [{ required: true, message: '请填写请求日志id', trigger: 'change' }],
  relationHousekeeperName: [{ required: true, message: '请填写关联管家账号名称', trigger: 'change' }],
  relationHousekeeperId: [{ required: true, message: '请填写关联管家账号id', trigger: 'change' }],
  relationAdvAccountName: [{ required: true, message: '请填写关联投放账户名称', trigger: 'change' }],
  relationAdvId: [{ required: true, message: '请填写关联投放账户id', trigger: 'change' }],
  deleted: [{ required: true, message: '请填写逻辑删除', trigger: 'change' }],
  createBy: [{ required: true, message: '请填写创建者', trigger: 'change' }],
  createTime: [{ required: true, message: '请填写创建时间', trigger: 'change' }],
  updateBy: [{ required: true, message: '请填写更新者', trigger: 'change' }],
  updateTime: [{ required: true, message: '请填写更新时间', trigger: 'change' }],
  remark: [{ required: true, message: '请填写备注', trigger: 'change' }]
}
