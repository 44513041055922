var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "filter-setting-dialog",
      attrs: {
        title: "筛选设置",
        visible: _vm.show,
        "append-to-body": true,
        width: "600px",
        top: "20vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        close: _vm.onCloseDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                inline: false,
                "label-width": "100px",
                model: _vm.form,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "是否筛选", prop: "isFilter" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择是否筛选" },
                      model: {
                        value: _vm.form.isFilter,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "isFilter", $$v)
                        },
                        expression: "form.isFilter",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: true, label: "是" } }),
                      _c("el-option", { attrs: { value: false, label: "否" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.form.isFilter
                ? _c("el-form-item", { attrs: { label: "规则筛选" } }, [
                    _c(
                      "div",
                      { staticStyle: { "margin-bottom": "10px" } },
                      [
                        _vm._v(" 需要投手 "),
                        _c("el-input", {
                          staticStyle: { width: "50%", margin: "0 10px" },
                          attrs: { placeholder: "请输入数量" },
                          on: {
                            input: (e) =>
                              (_vm.form.num = _vm._integerZeroFn(e)),
                          },
                          model: {
                            value: _vm.form.launchPersonnel,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "launchPersonnel", $$v)
                            },
                            expression: "form.launchPersonnel",
                          },
                        }),
                        _vm._v(" 人投票通过 "),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { "margin-bottom": "10px" } },
                      [
                        _vm._v(" 需要素材 "),
                        _c("el-input", {
                          staticStyle: { width: "50%", margin: "0 10px" },
                          attrs: { placeholder: "请输入数量" },
                          on: {
                            input: (e) =>
                              (_vm.form.num = _vm._integerZeroFn(e)),
                          },
                          model: {
                            value: _vm.form.assetPersonnel,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "assetPersonnel", $$v)
                            },
                            expression: "form.assetPersonnel",
                          },
                        }),
                        _vm._v(" 人投票通过 "),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { "margin-bottom": "10px" } },
                      [
                        _vm._v(" 超过 "),
                        _c("el-input", {
                          staticStyle: {
                            width: "50%",
                            margin: "0 10px 0 37px",
                          },
                          attrs: { placeholder: "请输入数量" },
                          on: {
                            input: (e) =>
                              (_vm.form.num = _vm._integerZeroFn(e)),
                          },
                          model: {
                            value: _vm.form.overstepDay,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "overstepDay", $$v)
                            },
                            expression: "form.overstepDay",
                          },
                        }),
                        _vm._v(" 天自动结束 "),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticStyle: { "margin-bottom": "10px" } },
                      [
                        _vm._v(" 发起人 "),
                        _c(
                          "el-select",
                          {
                            staticStyle: {
                              width: "50%",
                              margin: "0 10px 0 23px",
                            },
                            attrs: { placeholder: "请选择是否筛选" },
                            model: {
                              value: _vm.form.isComputeInitiatePersonnel,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "isComputeInitiatePersonnel",
                                  $$v
                                )
                              },
                              expression: "form.isComputeInitiatePersonnel",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { value: true, label: "计入" },
                            }),
                            _c("el-option", {
                              attrs: { value: false, label: "不计入" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" 投票人数 "),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "btn", on: { click: _vm.closeModal } },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { type: "primary", disabled: _vm.loading },
              on: { click: _vm.handleConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }