/*
 * @Description: Do not edit
 * @Date: 2021-11-10 19:20:55
 * @LastEditTime: 2022-03-28 09:40:05
 */
export let searchForm = {
  housekeeperId: null,
  accountId: null,
  accountName: null,
  accountNumber: null,
  projectId: null,
  projectName: null,
  productId: null,
  productName: null,
  deleted: null,
  createBy: null,
  createTime: null,
  updateBy: null,
  updateTime: null,
  remark: null
}

// export class AccountHugeHousekeeperEnt {
//   housekeeperId
//   accountId
//   accountName
//   accountNumber
//   projectId
//   projectName
//   productId
//   productName
//   deleted
//   createBy
//   createTime
//   updateBy
//   updateTime
//   remark
// }

export let formRules = {
  housekeeperId: [{ required: true, message: '请填写', trigger: 'change' }],
  accountId: [{ required: true, message: '请填写账户ID', trigger: 'change' }],
  accountName: [{ required: true, message: '请填写账户名称', trigger: 'change' }],
  accountNumber: [{ required: true, message: '请填写登录账号', trigger: 'change' }],
  projectId: [{ required: true, message: '请填写项目id', trigger: 'change' }],
  projectName: [{ required: true, message: '请填写项目', trigger: 'change' }],
  productId: [{ required: true, message: '请填写产品id', trigger: 'change' }],
  productName: [{ required: true, message: '请填写产品', trigger: 'change' }],
  deleted: [{ required: true, message: '请填写逻辑删除', trigger: 'change' }],
  createBy: [{ required: true, message: '请填写创建者', trigger: 'change' }],
  createTime: [{ required: true, message: '请填写创建时间', trigger: 'change' }],
  updateBy: [{ required: true, message: '请填写更新者', trigger: 'change' }],
  updateTime: [{ required: true, message: '请填写更新时间', trigger: 'change' }],
  remark: [{ required: true, message: '请填写备注', trigger: 'change' }]
}
