import request from '@/network/service/service'

// 查询定向模板列表
export function templateList(query) {
  return request({
    url: '/api/system/adOceanengineAudiencePackage/page',
    method: 'post',
    data: query
  })
}

// 删除定向模板
export function delTemplate(id) {
  return request({
    url: `/api/system/adOceanengineAudiencePackage/${id}`,
    method: 'delete'
  })
}

// 添加定向模板
export function addTemplate(data) {
  return request({
    url: '/api/system/adOceanengineAudiencePackage/save',
    method: 'post',
    data: data
  })
}

// 定向模板详情
export function viewTemplate(data) {
  return request({
    url: `/api/system/adOceanengineAudiencePackage/info/${data}`,
    method: 'get'
  })
}

// 更新定向模板
export function updateTemplate(data) {
  return request({
    url: '/api/system/adOceanengineAudiencePackage/update',
    method: 'post',
    data: data
  })
}

// 复制定向模板
export function copyTemplate(data) {
  return request({
    url: '/api/system/adOceanengineAudiencePackage/copy',
    method: 'post',
    data: data
  })
}

// 获取商圈
export function regionSelect(data) {
  return request({
    url: '/api/system/adOceanengineAudiencePackage/getRegion',
    method: 'post'
  })
}
