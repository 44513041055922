<!--
 * @Description: 线上资产同步
 * @Date: 2021-12-06 09:32:07
 * @LastEditTime: 2022-02-17 16:37:01
-->

<template>
  <div>
    <!-- 列表查询 -->
    <el-form ref="search" :model="search">
      <el-form-item label="投放账户" prop="accountId" :rules="{ required: true, message: '请选择投放账户', trigger: 'blur' }">
        <el-select v-model="search.accountId" style="width: 600px" multiple :multiple-limit="10" filterable remote reserve-keyword placeholder="请输入关键词" :remote-method="remoteMethod" :loading="loading">
          <el-option v-for="item in options" :key="item.accountId" :label="`${item.corporationName}-${item.accountId}`" :value="item.accountId"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="资产类型" prop="checkedCities" :rules="{ required: true, message: '请选择资产类型', trigger: 'blur' }">
        <el-radio-group v-model="search.checkedCities" :min="0">
          <el-radio label="adManagementGdtBrand">品牌形象</el-radio>
          <el-radio label="adManagementGdtPages">阅宝落地页</el-radio>
          <el-radio label="adManagementGdtProductCatalogs">商品库</el-radio>
          <el-radio label="adManagementGdtCustomAudiences">人群包</el-radio>
          <el-radio label="adManagementGdtUserActionSets">数据源</el-radio>
        </el-radio-group>
      </el-form-item>
      <!-- <el-form-item label="资产类型" prop="checkedCities" :rules="{ required: true, message: '请选择资产类型', trigger: 'change' }">
        <el-checkbox-group v-model="search.checkedCities" :min="0">
          <el-checkbox label="adManagementGdtBrand">品牌形象</el-checkbox>
          <el-checkbox label="adManagementGdtPages">阅宝落地页</el-checkbox>
          <el-checkbox label="adManagementGdtProductCatalogs">商品库</el-checkbox>
          <el-checkbox label="adManagementGdtCustomAudiences">人群包</el-checkbox>
          <el-checkbox label="adManagementGdtUserActionSets">数据源</el-checkbox>
        </el-checkbox-group>
      </el-form-item> -->

      <el-form-item>
        <el-button type="primary" round :disabled="active === 1" @click="submitForm">
          <i v-if="active === 1" class="el-icon-loading"></i>
          立即同步
        </el-button>
      </el-form-item>
    </el-form>

    <!-- <el-dialog title="线上资产同步" :visible.sync="dialogVisible" width="30%" :before-close="handleClose" :modal-append-to-body="false">
      <span class="justify-center">
        <el-steps direction="vertical" :active="active" style="height:100px">

      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog> -->

    <TablePane v-if="active === 2" :data-source="dataSource" class="table" border @getPage="getPage"> </TablePane>
  </div>
</template>

<script>
import { getSelectAccountUin, Sync, SyncRefresh, SyncRefreshPage } from '@/network/api/assetManagement/assetManageTencent.js'
import TablePane from '@/components/tablePane.vue'
import { debounce } from '@/utils/ruoyi'
export default {
  components: {
    TablePane
  },
  data() {
    return {
      search: {
        accountId: null,
        checkedCities: 'adManagementGdtBrand'
      },
      options: [],
      list: [],
      loading: false,
      dialogVisible: false,
      dialogVisibleNum: 0,
      description: null,
      active: 0,
      typeValue: {
        adManagementGdtBrand: '品牌形象',
        adManagementGdtPages: '阅宝落地页',
        adManagementGdtProductCatalogs: '商品库',
        adManagementGdtCustomAudiences: '人群包',
        adManagementGdtUserActionSets: '数据源'
      },
      typeValue2: {
        brand: '品牌形象',
        pages: '阅宝落地页',
        productCatalogs: '商品库',
        customAudiences: '人群包',
        userActionSets: '数据源'
      },
      // 表格数据
      dataSource: {
        data: [],
        cols: [
          {
            label: '投放账户ID',
            render: (recode) => {
              return [...new Set(JSON.parse(recode.accountIds))] + ''
            }
          },
          {
            label: '同步人员',
            prop: 'createBy'
          },
          {
            label: '类型',
            render: (recode) => {
              return this.typeValue2[recode.type]
            }
          },
          {
            label: '本次同步数量',
            prop: 'asyncNum'
          }
        ], // 表格的列数据
        isIndex: true, // 列表序号
        loading: false, // loading
        isOperation: false,
        isBorder: true,
        handleSelectionChange() {},
        pageData: {
          total: 0, // 总条数
          pageSize: 10,
          layout: 'total, prev, pager, next, jumper'
        }
      }
    }
  },

  methods: {
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        this.getSelectAccountUinFun(query)
      } else {
        this.options = []
      }
    },

    getPage(params = { pageNum: 1, pageSize: 10, pkUnique: null }) {
      this.dataSource.loading = true
      this.dataSource.loading = true
      let search = {
        pkUnique: params.pkUnique
      }
      SyncRefreshPage({ ...search, ...params }).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          this.dataSource.data = records
          this.dataSource.pageData.total = total
          this.dataSource.loading = false
        }
      })
    },

    getSelectAccountUinFun: debounce(function(query) {
      getSelectAccountUin({ accountId: query }).then((res) => {
        if (res.code === 200) {
          this.loading = false
          this.options = res.data
        }
      })
    }),

    submitForm() {
      this.$refs['search'].validate((valid) => {
        if (valid) {
          Sync({ accountIds: this.search.accountId, promotedObjectType: 'PROMOTED_OBJECT_TYPE_LINK' }, this.search.checkedCities).then((res) => {
            if (res.code === 200) {
              this.sync_data = res.data
              this.active = 1
            }
          })
          // 获取同步结果
          let timer = null
          timer = setInterval(() => {
            if (this.sync_data) {
              SyncRefresh(this.sync_data, this.search.checkedCities).then((res) => {
                if (res.code === 200 && res.data.successNum !== null) {
                  this.dialogVisibleNum = res.data.successNum
                  this.description = res.data.message
                  this.active = 2
                  this.$message.success('同步成功!')
                  clearInterval(timer)
                  this.getPage({ pkUnique: this.sync_data, pageNum: 1, pageSize: 10 })
                }
              })
            }
          }, 3000)
        }
      })
    }
  }
}
</script>
