var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "table-template" },
    [
      _c(
        "el-container",
        [
          _c("el-aside", { attrs: { width: "20%" } }, [
            _c(
              "div",
              [
                _c(
                  "el-input",
                  {
                    staticClass: "input-with-select",
                    attrs: { placeholder: "请输入内容", clearable: "" },
                    model: {
                      value: _vm.input3,
                      callback: function ($$v) {
                        _vm.input3 = typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "input3",
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "90px" },
                        attrs: { slot: "prepend", placeholder: "请选择" },
                        on: { change: _vm.onClickSearchInput },
                        slot: "prepend",
                        model: {
                          value: _vm.imageTypeTree,
                          callback: function ($$v) {
                            _vm.imageTypeTree = $$v
                          },
                          expression: "imageTypeTree",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "专辑", value: "1" },
                        }),
                        _c("el-option", {
                          attrs: { label: "素材组", value: "2" },
                        }),
                      ],
                      1
                    ),
                    _c("el-button", {
                      attrs: { slot: "append", icon: "el-icon-search" },
                      on: { click: _vm.onClickSearchInput },
                      slot: "append",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: { "margin-top": "10px" },
                on: { click: _vm.onClickSearchAll },
              },
              [
                _c(
                  "el-row",
                  [
                    _c("el-col", { attrs: { span: 21 } }, [
                      _c("div", { staticClass: "grid-content bg-purple" }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-weight": "700",
                              "margin-top": "10px",
                            },
                          },
                          [_vm._v("全部专辑")]
                        ),
                      ]),
                    ]),
                    _c("el-col", { attrs: { span: 3 } }, [
                      _c(
                        "div",
                        { staticClass: "grid-content bg-purple-light" },
                        [
                          _c(
                            "el-popover",
                            {
                              staticStyle: { "padding-right": "20px" },
                              attrs: {
                                "popper-class": "popper",
                                placement: "top-start",
                                trigger: "hover",
                              },
                            },
                            [
                              _c("div", { staticClass: "popover-content" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "popover-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onClickAddAlbum(0, 0)
                                      },
                                    },
                                  },
                                  [_vm._v("新增专辑")]
                                ),
                              ]),
                              _c("el-button", {
                                staticStyle: { color: "#000000" },
                                attrs: {
                                  slot: "reference",
                                  type: "text",
                                  icon: "el-icon-more",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                },
                                slot: "reference",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { height: "700px" } },
              [
                _c("el-tree", {
                  staticStyle: { width: "95% height:600px" },
                  attrs: {
                    data: _vm.imgStructure,
                    props: _vm.defaultProps,
                    "expand-on-click-node": false,
                  },
                  on: { "node-click": _vm.albumCheckTree },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ data }) {
                        return _c(
                          "span",
                          { staticClass: "custom-tree-node" },
                          [
                            [
                              data.label.length <= 8
                                ? _c("span", [_vm._v(_vm._s(data.label))])
                                : _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: data.label,
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(data.label.slice(0, 8)) + "..."
                                        ),
                                      ]),
                                    ]
                                  ),
                            ],
                            data.level === 1
                              ? _c(
                                  "el-popover",
                                  {
                                    staticStyle: { "padding-right": "20px" },
                                    attrs: {
                                      "popper-class": "popper",
                                      placement: "top-start",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "popover-content" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "popover-btn",
                                            on: {
                                              click: function ($event) {
                                                return _vm.onClickAddAlbum(
                                                  data.albumId,
                                                  data.projectId
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("编辑专辑")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "popover-btn",
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteAlbum(
                                                  data.albumId
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("删除专辑")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "popover-btn",
                                            on: {
                                              click: function ($event) {
                                                return _vm.iconMore(data)
                                              },
                                            },
                                          },
                                          [_vm._v("新增素材组")]
                                        ),
                                      ]
                                    ),
                                    _c("el-button", {
                                      attrs: {
                                        slot: "reference",
                                        type: "text",
                                        icon: "el-icon-more",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                      slot: "reference",
                                    }),
                                  ],
                                  1
                                )
                              : _c(
                                  "el-popover",
                                  {
                                    staticStyle: { "padding-right": "5px" },
                                    attrs: {
                                      "popper-class": "popper",
                                      placement: "top-start",
                                      trigger: "hover",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "popover-content" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "popover-btn",
                                            on: {
                                              click: function ($event) {
                                                return _vm.onClickAddMaterialGroup(
                                                  data.materialGroupId
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("编辑素材组")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "popover-btn",
                                            on: {
                                              click: function ($event) {
                                                return _vm.onClickDelTree(
                                                  data.materialGroupId
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("删除素材组")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "popover-btn",
                                            on: {
                                              click: function ($event) {
                                                return _vm.iconMore(data)
                                              },
                                            },
                                          },
                                          [_vm._v("新增素材")]
                                        ),
                                      ]
                                    ),
                                    _c("el-button", {
                                      attrs: {
                                        slot: "reference",
                                        type: "text",
                                        icon: "el-icon-more",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                        },
                                      },
                                      slot: "reference",
                                    }),
                                  ],
                                  1
                                ),
                          ],
                          2
                        )
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
          _c(
            "el-main",
            { staticStyle: { "padding-top": "0px" } },
            [
              _c("div", { staticClass: "search-form-container" }, [
                _c(
                  "span",
                  {
                    staticStyle: { "font-size": "20px", "font-weight": "600" },
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.imageType === "1"
                          ? "专辑列表"
                          : _vm.imageType === "2"
                          ? "组列表"
                          : "素材列表"
                      )
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "flex-btn" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.imageType === "3",
                        expression: "imageType === '3'",
                      },
                    ],
                    staticClass: "left",
                  },
                  [
                    _c(
                      "el-dropdown",
                      {
                        attrs: {
                          disabled: !_vm.selectedData.length,
                          "split-button": "",
                          type: "primary",
                        },
                      },
                      [
                        _vm._v(" 批量操作 "),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.onClickBatchMove()
                                  },
                                },
                              },
                              [_vm._v("批量移动")]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.onClickBatchCopy()
                                  },
                                },
                              },
                              [_vm._v("批量复制")]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.onClickBatchDel()
                                  },
                                },
                              },
                              [_vm._v("批量删除")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-right": "10px" },
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.onClickAddMaterial(0)
                          },
                        },
                      },
                      [_vm._v("新增素材")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.imageType === "1",
                            expression: "imageType === '1'",
                          },
                        ],
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.onClickAddAlbum(0, 0)
                          },
                        },
                      },
                      [_vm._v("新增专辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.imageType === "2",
                            expression: "imageType === '2'",
                          },
                        ],
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.onClickAddMaterialGroupAlbumR()
                          },
                        },
                      },
                      [_vm._v("新增素材组")]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.imageType === "1",
                      expression: "imageType === '1'",
                    },
                  ],
                },
                [
                  _c(
                    "div",
                    { staticClass: "album" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        _vm._l(_vm.albumList, function (item, index) {
                          return _c(
                            "el-col",
                            {
                              key: index,
                              attrs: { xl: 4, sm: 6, md: 6, xs: 7 },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "album-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.onCickAlbum(item.albumId)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "album-item-image" },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            item.urls[0] ||
                                            "https://cw-oss.mokamrp.com/ad/2021/10/22/f73d496e-5744-4ea6-b7fa-ab78b8b6b126.jpeg",
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "album-item-bottom-box" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "album-item-middle" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "album-item-middle-left",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(item.materialGroupNum)
                                                ),
                                              ]),
                                              _c("span", [_vm._v("素材组")]),
                                            ]
                                          ),
                                          _c("div", {
                                            staticClass:
                                              "album-item-middle-center",
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "album-item-middle-right",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(item.imageMaterialNum)
                                                ),
                                              ]),
                                              _c("span", [_vm._v("素材")]),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "album-item-bottom" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "album-item-bottom-text",
                                            },
                                            [_vm._v(_vm._s(item.albumName))]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "album-item-bottom-btn",
                                            },
                                            [
                                              _c(
                                                "el-popover",
                                                {
                                                  attrs: {
                                                    "popper-class": "popper",
                                                    placement: "top",
                                                    width: "80",
                                                    trigger: "hover",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "popover-content",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "popover-btn",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onClickAddAlbum(
                                                                item.albumId,
                                                                item.projectId
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("编辑")]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "popover-btn",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.onClickDel(
                                                                item.albumId
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("删除")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "opt-icon-container",
                                                      attrs: {
                                                        slot: "reference",
                                                      },
                                                      slot: "reference",
                                                    },
                                                    [
                                                      _c("div", {
                                                        staticClass: "dian",
                                                      }),
                                                      _c("div", {
                                                        staticClass: "dian",
                                                      }),
                                                      _c("div", {
                                                        staticClass: "dian",
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.imageType === "2",
                      expression: "imageType === '2'",
                    },
                  ],
                },
                [
                  _c(
                    "div",
                    { staticClass: "material-group" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        _vm._l(_vm.materialGroupList, function (item, index) {
                          return _c(
                            "el-col",
                            {
                              key: index,
                              attrs: { xl: 4, sm: 6, md: 6, xs: 7 },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "material-group-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.onClickMaterialGroup(
                                        item.albumId,
                                        item.materialGroupId
                                      )
                                    },
                                  },
                                },
                                [
                                  item.urls.length === 1
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "material-group-item-image image-1",
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src:
                                                item.urls[0] ||
                                                "https://cw-oss.mokamrp.com/ad/2021/10/22/f73d496e-5744-4ea6-b7fa-ab78b8b6b126.jpeg",
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      )
                                    : item.urls.length > 1
                                    ? _c(
                                        "el-row",
                                        {
                                          staticClass:
                                            "material-group-item-image image-4",
                                        },
                                        [
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "col",
                                              staticStyle: {
                                                "margin-bottom": "5px",
                                              },
                                              attrs: { span: 12 },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src:
                                                    item.urls[0] ||
                                                    "https://cw-oss.mokamrp.com/ad/2021/10/22/f73d496e-5744-4ea6-b7fa-ab78b8b6b126.jpeg",
                                                  alt: "",
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "col",
                                              staticStyle: {
                                                "padding-left": "5px",
                                                "margin-bottom": "5px",
                                              },
                                              attrs: { span: 12 },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src:
                                                    item.urls[1] ||
                                                    "https://cw-oss.mokamrp.com/ad/2021/10/22/f73d496e-5744-4ea6-b7fa-ab78b8b6b126.jpeg",
                                                  alt: "",
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "col",
                                              attrs: { span: 12 },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src:
                                                    item.urls[2] ||
                                                    "https://cw-oss.mokamrp.com/ad/2021/10/22/f73d496e-5744-4ea6-b7fa-ab78b8b6b126.jpeg",
                                                  alt: "",
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "col",
                                              staticStyle: {
                                                "padding-left": "5px",
                                              },
                                              attrs: { span: 12 },
                                            },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src:
                                                    item.urls[3] ||
                                                    "https://cw-oss.mokamrp.com/ad/2021/10/22/f73d496e-5744-4ea6-b7fa-ab78b8b6b126.jpeg",
                                                  alt: "",
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "material-group-item-image image-1",
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: "https://cw-oss.mokamrp.com/ad/2021/10/22/f73d496e-5744-4ea6-b7fa-ab78b8b6b126.jpeg",
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "material-group-item-bottom",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "material-group-item-bottom-text",
                                        },
                                        [_vm._v(_vm._s(item.materialGroupName))]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "material-group-item-bottom-btn",
                                        },
                                        [
                                          _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                "popper-class": "popper",
                                                placement: "top",
                                                width: "80",
                                                trigger: "hover",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "popover-content",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "popover-btn",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.onClickAddMaterialGroup(
                                                            item.materialGroupId
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("编辑")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "popover-btn",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.onClickDel(
                                                            item.materialGroupId
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("删除")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "opt-icon-container",
                                                  attrs: { slot: "reference" },
                                                  slot: "reference",
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass: "dian",
                                                  }),
                                                  _c("div", {
                                                    staticClass: "dian",
                                                  }),
                                                  _c("div", {
                                                    staticClass: "dian",
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.imageType === "3",
                      expression: "imageType === '3'",
                    },
                  ],
                },
                [
                  _c(
                    "el-row",
                    { staticClass: "material", attrs: { gutter: 20 } },
                    _vm._l(_vm.materialList, function (item, index) {
                      return _c(
                        "el-col",
                        { key: index, attrs: { xl: 4, sm: 6, md: 6, xs: 7 } },
                        [
                          _c(
                            "div",
                            {
                              class: [
                                "material-item",
                                item.isSelected ? "is-active" : "",
                              ],
                              on: {
                                click: function ($event) {
                                  return _vm.onClickSelectCell(index)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "material-item-image" },
                                [
                                  _c("img", {
                                    attrs: { src: item.url, alt: "" },
                                  }),
                                  _c("div", { staticClass: "size" }, [
                                    _vm._v(
                                      _vm._s(item.width) +
                                        "*" +
                                        _vm._s(item.height)
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "material-item-bottom" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "material-item-bottom-text",
                                    },
                                    [_vm._v(_vm._s(item.materialName))]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "material-item-bottom-btn" },
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            "popper-class": "popper",
                                            placement: "top",
                                            width: "80",
                                            trigger: "hover",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "popover-content" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "popover-btn",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onClickMaterialInfo(
                                                        item.imageId
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("详情")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "popover-btn",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onClickCopy(
                                                        item.imageId
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("复制")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "popover-btn",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onClickMove(
                                                        item.imageId
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("移动")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "popover-btn",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onClickDel(
                                                        item.imageId
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "opt-icon-container",
                                              attrs: { slot: "reference" },
                                              slot: "reference",
                                            },
                                            [
                                              _c("div", {
                                                staticClass: "dian",
                                              }),
                                              _c("div", {
                                                staticClass: "dian",
                                              }),
                                              _c("div", {
                                                staticClass: "dian",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pagination-container" },
                [
                  _c("c-pagination", {
                    attrs: {
                      "page-size": _vm.pageSize,
                      "page-no": _vm.pageNum,
                      total: _vm.total,
                      "get-data-fun": _vm.getPageData,
                    },
                    on: {
                      "update:pageSize": function ($event) {
                        _vm.pageSize = $event
                      },
                      "update:page-size": function ($event) {
                        _vm.pageSize = $event
                      },
                      "update:pageNo": function ($event) {
                        _vm.pageNum = $event
                      },
                      "update:page-no": function ($event) {
                        _vm.pageNum = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _c("edit-album-dialog", {
                ref: "editAlbumDialog",
                attrs: { type: "image" },
                on: { edit: _vm._refreshListAndTree },
              }),
              _c("edit-material-group-dialog", {
                ref: "editMaterialGroupDialog",
                attrs: { type: "image" },
                on: { edit: _vm.refreshMarterialGroupList },
              }),
              _c("add-material-dialog", {
                ref: "addMaterialDialog",
                attrs: { type: "image" },
                on: { edit: _vm._getImageMarterialList },
              }),
              _c("material-detail-dialog", {
                ref: "materialDetailDialog",
                attrs: { type: "image" },
                on: { edit: _vm._getImageMarterialList },
              }),
              _c("batch-move-material-dialog", {
                ref: "batchMoveMaterialDialog",
                attrs: { type: "image" },
                on: { edit: _vm._getImageMarterialList },
              }),
              _c("batch-copy-material-dialog", {
                ref: "batchCopyMaterialDialog",
                attrs: { type: "image" },
                on: { edit: _vm._getImageMarterialList },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }