<template>
  <div>
    <!-- 列表查询 -->
    <el-form ref="search" :inline="true" :model="search" class="search-form search-form-4">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="元素名称" prop="name">
            <el-input v-model="search.name" placeholder="请输入元素名称" style="width: 100%"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="基础类型" prop="baseType">
            <el-select v-model="search.baseType" style="width: 100%">
              <el-option label="全部" value=""></el-option>
              <el-option v-for="item in baseTypes" :key="item.key" :label="item.value" :value="item.key"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="元素类型" prop="typeId">
            <el-select v-model="search.typeId" style="width: 100%">
              <el-option label="全部" value=""></el-option>
              <el-option v-for="item in elementTypes" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" round @click="onSubmit">查 询</el-button>
            <el-button type="primary" round @click="resetForm('ruleForm')">重 置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div style="text-align:left " class="mb-15">
      <el-button type="primary" icon="el-icon-plus" round @click="handleAddElement('')"> 新建元素 </el-button>
      <el-button type="primary" round @click="handleElementType"> 元素类型 </el-button>
    </div>

    <div>
      <el-table v-loading="list.loading" :data="list.data" stripe class="list-table"
        :class="{ 'no-data': !list.data || !list.data.length }" style="width: 100%;">
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="name" label="元素名称" align="center"></el-table-column>
        <el-table-column prop="baseType" label="基础类型" align="center">
          <template slot-scope="{row}">
            {{ row.baseType | filterBaseType }}
          </template>
        </el-table-column>
        <el-table-column prop="typeName" label="元素类型" align="center"></el-table-column>
        <el-table-column prop="url" label="预览" align="center">
          <template slot-scope="{row}">
            <el-popover v-if="row.baseType === 'IMG'" placement="right" trigger="hover">
              <div class="element-pop-img">
                <el-image :src="row.url" fit="contain"></el-image>
              </div>
              <div slot="reference" class="element-img">
                <el-image :src="row.url" fit="contain" lazy></el-image>
              </div>
            </el-popover>
            <el-popover v-if="row.baseType === 'VIDEO'" placement="right" trigger="hover">
              <div class="element-pop-img">
                <video :src="row.url" controls></video>
              </div>
              <div slot="reference" class="element-img">
                <video :src="row.url"></video>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="createBy" label="创建人" align="center"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
        <el-table-column prop="" label="操作">
          <template slot-scope="{row}">
            <el-button type="text" @click="handleAddElement(row)">
              <el-tooltip content="编辑" placement="top">
                <i class="el-icon-edit" style="font-size: 16px"></i>
              </el-tooltip>
            </el-button>
            <el-button type="text" class="red" @click="handleDeleteElement(row.id)">
              <el-tooltip content="删除" placement="top">
                <i class="el-icon-delete" style="font-size: 16px"></i>
              </el-tooltip>
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="page">
        <paginations :total="list.total" :page.sync="list.pageNum" :page-sizes="[5, 10, 15, 20]"
          :limit.sync="list.pageSize" @pagination="getPage" />
      </div>
    </div>

    <!-- 元素类型弹窗 -->
    <element-type ref="elementTypeDialog" @refresh="handleRefresh"></element-type>

    <!-- 元素库弹窗 -->
    <element-library ref="elementLibraryDialog" @confirm="getPage"></element-library>
  </div>
</template>

<script>
import ElementTypeDialogVue from './components/ElementTypeDialog'
import ElementLibraryDialog from './components/ElementLibraryDialog'
import { getElementList, deleteElement, getElementType } from '@/network/api/toolManagement/api-tool'
export default {
  components: {
    'element-type': ElementTypeDialogVue,
    'element-library': ElementLibraryDialog
  },
  filters: {
    filterBaseType(value) {
      const types = { 'IMG': '图片', 'VIDEO': '视频' }
      return types[value] || ''
    }
  },
  data() {
    return {
      search: {
        name: '',
        baseType: '',
        typeId: ''
      },
      list: {
        loading: true,
        data: [],
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      baseTypes: [{ key: 'IMG', value: '图片' }, { key: 'VIDEO', value: '视频' }],
      elementTypes: []
    }
  },

  mounted() {
    this.getElementType()
    this.getPage()
  },

  methods: {
    /**
         * @description: 获取列表
         * @param {*} params
         */
    getPage() {
      this.list.loading = true
      let search = {
        ...this.search,
        pageSize: this.list.pageSize,
        pageNum: this.list.pageNum
      }
      getElementList(search).then(({ code, data }) => {
        if (code === 200) {
          const { records, total } = data
          this.list.data = records
          this.list.total = total
          this.list.loading = false
        }
      }).finally(e => {
        this.list.loading = false
      })
    },

    // 获取元素类型
    getElementType() {
      getElementType({ name: '' }).then(({ code, data }) => {
        if (code === 200) {
          this.elementTypes = data
        }
      })
    },

    /**
 * @description: 查询
 */
    onSubmit() {
      if (this.list.pageNum === 1) {
        this.getPage()
      } else {
        this.list.pageNum = 1
      }
    },
    /**
     * @description: 重置
     */
    resetForm() {
      this.$refs['search'].resetFields()
      this.getPage()
    },

    // 更新数据
    handleRefresh() {
      this.getElementType()
    },

    // 点击元素类型
    handleElementType() {
      this.$refs.elementTypeDialog.showModal()
    },

    // 点击添加元素
    handleAddElement(row) {
      this.$refs.elementLibraryDialog.showModal(row || {})
    },

    // 点击删除
    handleDeleteElement(id) {
      this.$confirm('确定要删除该选项吗?', '元素删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { code } = await deleteElement({ id: id })
        if (code === 200) {
          this.getPage()
          this.$message.success('删除成功!')
        }
      }).catch(() => {
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.element-img {
  width: 60px;
  height: 60px;
  text-align: center;
  margin: auto;
  padding-top: 12px;

  video {
    width: 100%;
    height: 100%
  }
}

.element-pop-img {
  max-width: 260px;

  video {
    width: 100%;
    height: 100%
  }
}
</style>
