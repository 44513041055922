var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "box",
    [
      _c("template", { slot: "title" }, [_vm._v("程序化设置")]),
      _c(
        "el-form-item",
        { attrs: { label: "创意名称", prop: "creativeFeedName" } },
        [
          _c("el-input", {
            staticStyle: { width: "600px" },
            attrs: { maxlength: "1024" },
            model: {
              value: _vm.sizeForm.creativeFeedName,
              callback: function ($$v) {
                _vm.$set(_vm.sizeForm, "creativeFeedName", $$v)
              },
              expression: "sizeForm.creativeFeedName",
            },
          }),
        ],
        1
      ),
      _c("el-form-item", { attrs: { label: "创意标题", prop: "titles" } }, [
        _c("div", { staticClass: "label" }, [
          _c(
            "ul",
            _vm._l(_vm.titles, function (item, index) {
              return _c("li", { key: item }, [_vm._v(_vm._s(index + 1))])
            }),
            0
          ),
          _c(
            "div",
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "900px" },
                    attrs: { type: "textarea", autosize: { minRows: 6 } },
                    on: { change: _vm.changeTitles },
                    model: {
                      value: _vm.sizeForm.elements.titles,
                      callback: function ($$v) {
                        _vm.$set(_vm.sizeForm.elements, "titles", $$v)
                      },
                      expression: "sizeForm.elements.titles",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("p", { staticClass: "tip" }, [
          _c(
            "span",
            { style: { color: _vm.titles.length > 50 ? "red" : "##7d7d7d" } },
            [_vm._v(" " + _vm._s(_vm.titles.length) + " / 50 ")]
          ),
          _vm._v(" 点击回车填写下一条，每条标题最多不超过60字符 "),
        ]),
        _c(
          "ul",
          { staticClass: "custom-title ml-20 mt-10" },
          _vm._l(_vm.custom_title, function (item) {
            return _c(
              "li",
              {
                key: item.title,
                on: {
                  click: function ($event) {
                    return _vm.setSizeForm("titles", item.title)
                  },
                },
              },
              [_vm._v("+ " + _vm._s(item.title))]
            )
          }),
          0
        ),
      ]),
      _c("el-form-item", { attrs: { label: "创意物料", prop: "pic" } }, [
        _c(
          "div",
          [
            _vm._l(_vm.pic_label_type, function (item, index) {
              return _c(
                "el-radio",
                {
                  key: index,
                  attrs: { label: item.key, border: "" },
                  model: {
                    value: _vm.pic_label,
                    callback: function ($$v) {
                      _vm.pic_label = $$v
                    },
                    expression: "pic_label",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(item.value) +
                      " " +
                      _vm._s(
                        item.value === "固定三图"
                          ? Math.floor(
                              _vm.sizeForm.elements[item.key] &&
                                _vm.sizeForm.elements[item.key].length / 3
                            )
                          : _vm.sizeForm.elements[item.key] &&
                              _vm.sizeForm.elements[item.key].length
                      ) +
                      " / 50 "
                  ),
                ]
              )
            }),
            _vm._l(_vm.pic_label_type, function (item) {
              return _c("div", { key: item.key, staticClass: "pic-box" }, [
                item.key === "video" && _vm.pic_label === "video"
                  ? _c("div", { staticClass: "avatar-uploader mt-20 mb-20" }, [
                      _c("div", { staticClass: "justify-start" }, [
                        _c("div", { staticClass: "upload-box-2" }, [
                          _c("i", {
                            staticClass:
                              "upload-icon el-icon-plus el-upload--picture-card",
                            on: { click: _vm.setMaterialVideoBox },
                          }),
                          _c("div", { staticClass: "tip" }, [_vm._v("视 频")]),
                        ]),
                        _vm.sizeForm.elements.videoUrl &&
                        _vm.sizeForm.elements.videoUrl.length
                          ? _c(
                              "ul",
                              { staticClass: "material-showcase" },
                              _vm._l(
                                _vm.sizeForm.elements.videoUrl,
                                function (Item) {
                                  return _c("li", { key: Item }, [
                                    _c("video", {
                                      attrs: { src: Item.videoUrl },
                                    }),
                                    _c("div", { staticClass: "delete-img" }, [
                                      _c("i", {
                                        staticClass: "el-icon-delete",
                                        staticStyle: { "font-size": "28px" },
                                        on: {
                                          click: (e) =>
                                            _vm.deleteVideo(e, Item.videoUrl),
                                        },
                                      }),
                                    ]),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "pic-tip" }, [
                        _c("p", { staticClass: "tip" }, [
                          _vm._v(
                            "大小: " + _vm._s(_vm.flag ? "≤" : "d") + " 500MB"
                          ),
                        ]),
                        _c("p", { staticClass: "tip" }, [
                          _vm._v("横版尺寸: 横版16:9，分辨率≥1280*720"),
                        ]),
                        _c("p", { staticClass: "tip" }, [
                          _vm._v("竖版尺寸: 竖版9:16，分辨率≥720*1280"),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm.pic_label === "picture3Fixed"
                  ? _c("div", [
                      _vm.pic_label === item.key
                        ? _c(
                            "div",
                            {
                              staticClass: "avatar-uploader",
                              staticStyle: { display: "flex" },
                            },
                            [
                              _c("div", { staticClass: "upload-box-2" }, [
                                _c("i", {
                                  staticClass:
                                    "upload-icon el-icon-plus el-upload--picture-card",
                                  on: { click: _vm.setMaterialBox },
                                }),
                              ]),
                              _vm.sizeForm.elements[item.key] &&
                              _vm.sizeForm.elements[item.key].length
                                ? _c(
                                    "ul",
                                    { staticClass: "material-showcase" },
                                    _vm._l(
                                      _vm.sizeForm.elements[item.key],
                                      function (Item, index) {
                                        return _c(
                                          "li",
                                          {
                                            key: Item,
                                            class: {
                                              wite:
                                                (index + 1) % 3 === 0 &&
                                                index + 1 !==
                                                  _vm.sizeForm.elements[
                                                    item.key
                                                  ].length,
                                            },
                                          },
                                          [
                                            _c("img", {
                                              key: Item.imageid,
                                              staticClass: "avatar",
                                              attrs: { src: Item.image },
                                            }),
                                            _c(
                                              "div",
                                              { staticClass: "delete-img" },
                                              [
                                                _c("i", {
                                                  staticClass: "el-icon-delete",
                                                  on: {
                                                    click: (e) =>
                                                      _vm.deletePicImg(
                                                        e,
                                                        index
                                                      ),
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _c("div", [
                      _vm.pic_label === item.key
                        ? _c(
                            "div",
                            { staticClass: "img-upload-box" },
                            [
                              _c("div", { staticClass: "upload-box-2" }, [
                                _c("i", {
                                  staticClass:
                                    "upload-icon el-icon-plus el-upload--picture-card",
                                  on: { click: _vm.setMaterialBox },
                                }),
                                item.key === "video" &&
                                _vm.pic_label === "video"
                                  ? _c("div", { staticClass: "tip" }, [
                                      _vm._v(" 封面图 "),
                                    ])
                                  : _vm._e(),
                              ]),
                              item.key === "video" && _vm.pic_label === "video"
                                ? [
                                    _vm.sizeForm.elements.videoUrl &&
                                    _vm.sizeForm.elements.videoUrl.length
                                      ? _c(
                                          "ul",
                                          { staticClass: "material-showcase" },
                                          _vm._l(
                                            _vm.sizeForm.elements.videoUrl,
                                            function (Item, index) {
                                              return _c("li", { key: Item }, [
                                                Item.poster &&
                                                Item.poster.length
                                                  ? _c("div", [
                                                      _c("img", {
                                                        staticClass: "avatar",
                                                        attrs: {
                                                          src:
                                                            Item.poster[0]
                                                              .image ||
                                                            _vm.defaultImg,
                                                        },
                                                      }),
                                                    ])
                                                  : _vm._e(),
                                                _c(
                                                  "div",
                                                  { staticClass: "delete-img" },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-delete",
                                                      staticStyle: {
                                                        "font-size": "28px",
                                                      },
                                                      on: {
                                                        click: (e) =>
                                                          _vm.deletePosterImg(
                                                            e,
                                                            index
                                                          ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ])
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ]
                                : [
                                    _vm.sizeForm.elements[item.key] &&
                                    _vm.sizeForm.elements[item.key].length
                                      ? _c(
                                          "ul",
                                          { staticClass: "material-showcase" },
                                          _vm._l(
                                            _vm.sizeForm.elements[item.key],
                                            function (Item, index) {
                                              return _c("li", { key: index }, [
                                                _c("img", {
                                                  staticClass: "avatar",
                                                  attrs: { src: Item.image },
                                                }),
                                                _c(
                                                  "div",
                                                  { staticClass: "delete-img" },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "el-icon-delete",
                                                      staticStyle: {
                                                        "font-size": "28px",
                                                      },
                                                      on: {
                                                        click: (e) =>
                                                          _vm.deletePicImg(
                                                            e,
                                                            index
                                                          ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ])
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ],
                            ],
                            2
                          )
                        : _vm._e(),
                      item.key === "video" && _vm.pic_label === "video"
                        ? _c("div", { staticClass: "pic-tip" }, [
                            _c("p", { staticClass: "tip" }, [
                              _vm._v("尺寸: 1140 * 640"),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                _vm.pic_label === item.key
                  ? _c("div", { staticClass: "pic-tip" }, [
                      item.key !== "video"
                        ? _c("div", [
                            item.size && item.size < 10000
                              ? _c("p", { staticClass: "tip" }, [
                                  _vm._v(
                                    " 大小: " +
                                      _vm._s(
                                        item.key === "video"
                                          ? `≥${item.size / 1024}MB`
                                          : `≤ ${item.size}KB`
                                      ) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                            item.width && item.width < 10000
                              ? _c("p", { staticClass: "tip" }, [
                                  _vm._v(
                                    "尺寸:" +
                                      _vm._s(item.width) +
                                      " * " +
                                      _vm._s(item.height)
                                  ),
                                ])
                              : _vm._e(),
                            item.listType
                              ? _c("p", { staticClass: "tip" }, [
                                  _vm._v("格式: " + _vm._s(item.listType)),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ])
            }),
          ],
          2
        ),
      ]),
      _c(
        "el-form-item",
        { attrs: { label: "创意工具", prop: "progFlag" } },
        [
          _c(
            "template",
            { slot: "label" },
            [
              _vm._v(" 创意工具 "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: { effect: "dark", placement: "top" },
                },
                [
                  _c("template", { slot: "content" }, [
                    _vm._v(
                      " 创意加工工具可以将您本单元内提交的所有图片和标题作为元素， "
                    ),
                    _c("br"),
                    _vm._v(
                      " 由系统优选匹配模版进行二次加工，为您扩展创意候选集。 "
                    ),
                    _c("br"),
                    _vm._v(
                      " 扩展创意的投放数据将在组合叉乘结果中体现，您可以随时评估效果。 "
                    ),
                  ]),
                  _c("i", { staticClass: "el-icon-question" }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-checkbox",
            {
              attrs: {
                value: _vm.sizeForm.progFlag === 32 ? true : false,
                border: "",
              },
              on: { change: (e) => _vm.setSizeForm("progFlag", e) },
            },
            [_vm._v(" 图片生成视频 ")]
          ),
        ],
        2
      ),
      _c(
        "el-form-item",
        { attrs: { prop: "playnum" } },
        [
          _c(
            "template",
            { slot: "label" },
            [
              _vm._v(" 附加信息 "),
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: { effect: "dark", placement: "top" },
                },
                [
                  _c("template", { slot: "content" }, [
                    _vm._v(
                      " 选择附加信息后，在广告展示时系统会根据广告主选择的附加信息选出 "
                    ),
                    _c("br"),
                    _vm._v(
                      " 最优样式展示。其中评论数为下载类应用评论数； 阅读量、播放量分别 "
                    ),
                    _c("br"),
                    _vm._v(
                      " 针对图文和视频物料，根据广告实际点击数据等指标进行脱敏计算得出。 "
                    ),
                    _c("br"),
                    _vm._v(" 附加信息暂时只针对手百流量生效） "),
                  ]),
                  _c("i", { staticClass: "el-icon-question" }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("el-checkbox", {
                attrs: {
                  label: "阅读量",
                  value: _vm.sizeForm.readnum === 1,
                  border: "",
                },
                on: { change: (e) => _vm.setSizeForm("readnum", e) },
              }),
              _c("el-checkbox", {
                attrs: {
                  label: "播放量",
                  value: _vm.sizeForm.playnum === 1,
                  border: "",
                },
                on: { change: (e) => _vm.setSizeForm("playnum", e) },
              }),
            ],
            1
          ),
        ],
        2
      ),
      _c("material-box", {
        attrs: {
          visible: _vm.materialBox,
          "select-all": "",
          "img-count": _vm.pic_label_type.filter(
            (item) => item.key === _vm.pic_label
          )[0].count,
          name: _vm.pic_label,
          "image-list": JSON.parse(
            JSON.stringify(_vm.sizeForm.elements[_vm.pic_label])
          ),
          attribute: _vm.attributeImg,
          pic_label_type: _vm.pic_label_type,
          "project-id": _vm.projectId,
        },
        on: { setMaterialBox: _vm.setMaterialBox, submit: _vm.selectImg },
      }),
      _c("MaterialVideoBox", {
        attrs: {
          visible: _vm.materialVideo,
          "img-count": _vm.pic_label_type.filter(
            (item) => item.key === _vm.pic_label
          )[0].count,
          name: "videoUrl",
          "video-listed": _vm.sizeForm.elements["videoUrl"],
          attribute: _vm.attribute,
          "project-id": _vm.projectId,
        },
        on: {
          setMaterialBox: _vm.setMaterialVideoBox,
          clearvideo: _vm.clearvideo,
          handleVideoSuccess: _vm.handleVideoSuccess,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }