<!--
 * @Author: Zeng
 * @Date: 2023-07-11 17:07:51
 * @LastEditTime: 2023-07-20 10:10:56
-->
<template>
  <el-date-picker v-model="selectTime" :picker-options="pickerOptions" value-format="yyyy-MM-dd" type="daterange"
    :clearable="false" range-separator="~" start-placeholder="开始日期" style="width: 230px;" end-placeholder="结束日期"
    size="mini" @change="changeSelect">
  </el-date-picker>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      selectTime: this.getDateTime(),
      pickerOptions: {
        onPick: ({ minDate, maxDate }) => {
          // 把选择的第一个日期赋值给一个变量。
          this.choiceDate = minDate.getTime()
          // 如何你选择了两个日期了，就把那个变量置空
          if (maxDate) this.choiceDate = ''
        },
        disabledDate: time => {
          // 如何选择了一个日期
          if (this.choiceDate) {
            // 7天的时间戳
            const one = 6 * 24 * 3600 * 1000
            // 当前日期 - one = 7天之前
            const minTime = this.choiceDate - one
            // 当前日期 + one = 7天之后
            const maxTime = this.choiceDate + one
            return (
              time.getTime() < minTime ||
              time.getTime() > maxTime
              // 限制不能选择今天及以后
              // || time.getTime() + 1 * 24 * 3600 * 1000 > Date.now()
            )
          } else {
            // 如果没有选择日期，就要限制不能选择今天及以后
            // return time.getTime() + 1 * 24 * 3600 * 1000 > Date.now();
          }
        }
      }
    }
  },

  created() { },

  mounted() { },
  methods: {
    changeSelect(value) {
      if (!value) {
        this.choiceDate = null
      }
      this.selectTime = value
      this.$emit('input', this.formateDates(value))
    },

    // 获取近7天时间段
    getDateTime() {
      const start = new Date(new Date().getTime() - 3600 * 1000 * 24 * 6).toISOString().split('T')[0]
      const end = new Date(new Date().getTime()).toISOString().split('T')[0]
      this.$emit('input', this.formateDates([start, end]))
      return [start, end]
    },
    formateDates(dates) {
      const reg = new RegExp('-', 'g')
      const value1 = dates[0].replace(reg, '.')
      const value2 = dates[1].replace(reg, '.')
      return [value1, value2]
    }
  }
}
</script>

<style scoped lang="scss"></style>
