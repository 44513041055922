var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "creativity-material" },
      [
        _vm.materials.length && _vm.materials[0].list[0].assetLocalUrl
          ? _c(
              "div",
              _vm._l(_vm.materials, function (item) {
                return _c("div", { key: item.name, staticClass: "img-list" }, [
                  _c("p", [_vm._v(_vm._s(item.name))]),
                  _c(
                    "div",
                    { staticClass: "img-box" },
                    _vm._l(item.list, function (image) {
                      return _c(
                        "div",
                        { key: image.creativeGroup },
                        [
                          image.assetLocalUrl
                            ? [
                                _vm.checkIsVideoType(image.assetLocalUrl)
                                  ? _c("video", {
                                      attrs: { src: image.assetLocalUrl },
                                    })
                                  : _c("img", {
                                      staticClass: "avatar",
                                      attrs: { src: image.assetLocalUrl },
                                    }),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    }),
                    0
                  ),
                ])
              }),
              0
            )
          : _c(
              "div",
              {
                staticStyle: {
                  "text-align": "center",
                  "padding-top": "20px",
                  color: "#909399",
                },
              },
              [_vm._v("暂无数据")]
            ),
        _c(
          "el-dialog",
          {
            attrs: {
              "custom-class": "dialog-item",
              title: "创意素材",
              visible: _vm.materialVisible,
              width: "800px",
              "append-to-body": true,
              "destroy-on-close": "",
            },
            on: { close: _vm.handleClose },
          },
          [
            _c("div", { staticClass: "justify-start" }, [
              _c("h3", [_vm._v("创意内容")]),
              _c("p", { staticClass: "ml-20" }, [
                _vm._v(
                  "创意组数量：" +
                    _vm._s(_vm.creativityMaterialList.length) +
                    "组"
                ),
              ]),
            ]),
            !_vm.isImage && _vm.taskSiteSet !== 1
              ? _c(
                  "div",
                  {
                    staticClass: "justify-start",
                    staticStyle: { margin: "20px 0" },
                  },
                  [
                    _c("p", { staticClass: "mr-20" }, [_vm._v("自动生成封面")]),
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.generateCover,
                          callback: function ($$v) {
                            _vm.generateCover = $$v
                          },
                          expression: "generateCover",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v("不开启"),
                        ]),
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("开启"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-collapse",
              {
                model: {
                  value: _vm.collapse,
                  callback: function ($$v) {
                    _vm.collapse = $$v
                  },
                  expression: "collapse",
                },
              },
              _vm._l(_vm.creativityMaterialList, function (item, index) {
                return _c(
                  "el-collapse-item",
                  { key: item.name, attrs: { name: item.name } },
                  [
                    _c("template", { slot: "title" }, [
                      _c("div", { staticClass: "justify-sb group-title" }, [
                        _c("p", [_vm._v(_vm._s(item.name))]),
                        _c("div", { staticClass: "icon" }, [
                          _c("i", {
                            staticClass: "el-icon-document-copy",
                            on: {
                              click: function ($event) {
                                return _vm.copyCreativeGroup(index)
                              },
                            },
                          }),
                          _vm.creativityMaterialList.length > 1
                            ? _c("i", {
                                staticClass: "el-icon-close",
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteCreativeGroup(index)
                                  },
                                },
                              })
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                    _vm.isImage
                      ? _c(
                          "div",
                          { staticClass: "img-box" },
                          [
                            _c("p", [_vm._v("图片:")]),
                            _vm._l(item.list, function (imgInfo, idx) {
                              return _c(
                                "div",
                                { key: idx, staticClass: "mr-10" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "avatar-uploader",
                                      on: {
                                        click: function ($event) {
                                          return _vm.setMaterialBox(index, 0)
                                        },
                                      },
                                    },
                                    [
                                      imgInfo.assetLocalUrl
                                        ? _c("img", {
                                            staticClass: "avatar",
                                            attrs: {
                                              src: imgInfo.assetLocalUrl,
                                            },
                                          })
                                        : _c("i", {
                                            staticClass:
                                              "el-icon-plus avatar-uploader-icon",
                                          }),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            _c("div", { staticClass: "info" }, [
                              _c("p", [
                                _vm._v(
                                  "尺寸：" +
                                    _vm._s(item.list[0].assetLocalResizeWidth) +
                                    "px * " +
                                    _vm._s(
                                      item.list[0].assetLocalResizeHeight
                                    ) +
                                    "px"
                                ),
                              ]),
                              _c("p", [_vm._v("格式：JPG、JPEG、PNG格式")]),
                              _c("p", [
                                _vm._v(
                                  "大小：" +
                                    _vm._s(
                                      item.list[0].assetLocalResizeFileSize
                                    ) +
                                    " KB 以内"
                                ),
                              ]),
                            ]),
                          ],
                          2
                        )
                      : _c("div", { staticClass: "justify-sb" }, [
                          _c("div", { staticClass: "img-box" }, [
                            _c("p", [_vm._v("视频:")]),
                            _c("div", { staticClass: "mr-10" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "avatar-uploader",
                                  on: {
                                    click: function ($event) {
                                      return _vm.setMaterialVideoBox(index)
                                    },
                                  },
                                },
                                [
                                  item.list[0].assetLocalUrl
                                    ? _c("video", {
                                        staticClass: "avatar",
                                        attrs: {
                                          src: item.list[0].assetLocalUrl,
                                        },
                                      })
                                    : _c("i", {
                                        staticClass:
                                          "el-icon-plus avatar-uploader-icon",
                                      }),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "info" }, [
                              _c("p", [
                                _vm._v(
                                  "最小尺寸：" +
                                    _vm._s(item.list[0].assetLocalResizeWidth) +
                                    "px * " +
                                    _vm._s(
                                      item.list[0].assetLocalResizeHeight
                                    ) +
                                    "px"
                                ),
                              ]),
                              _c("p", [_vm._v("格式：MP4、MOV、AVI格式")]),
                              _c("p", [_vm._v("大小：100 MB 以内")]),
                              _c("p", [_vm._v("播放时长: 5s - 30s")]),
                            ]),
                          ]),
                          item.list.length > 1 &&
                          (_vm.generateCover === 0 ||
                            item.list[0].assetLocalUrl)
                            ? _c("div", { staticClass: "img-box" }, [
                                _c("p", [_vm._v("封面:")]),
                                _c("div", { staticClass: "mr-10" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "avatar-uploader",
                                      on: {
                                        click: function ($event) {
                                          return _vm.setMaterialBox(index, 1)
                                        },
                                      },
                                    },
                                    [
                                      item.list[1].assetLocalUrl
                                        ? [
                                            _vm.checkIsVideoType(
                                              item.list[1].assetLocalUrl
                                            )
                                              ? _c("video", {
                                                  staticClass: "avatar",
                                                  attrs: {
                                                    src: item.list[1]
                                                      .assetLocalUrl,
                                                  },
                                                })
                                              : _c("img", {
                                                  staticClass: "avatar",
                                                  attrs: {
                                                    src: item.list[1]
                                                      .assetLocalUrl,
                                                  },
                                                }),
                                          ]
                                        : _c("i", {
                                            staticClass:
                                              "el-icon-plus avatar-uploader-icon",
                                          }),
                                    ],
                                    2
                                  ),
                                ]),
                                _c("div", { staticClass: "info" }, [
                                  _c("p", [
                                    _vm._v(
                                      "最小尺寸：" +
                                        _vm._s(
                                          item.list[1].assetLocalResizeWidth
                                        ) +
                                        "px * " +
                                        _vm._s(
                                          item.list[1].assetLocalResizeHeight
                                        ) +
                                        "px"
                                    ),
                                  ]),
                                  _c("p", [_vm._v("格式：JPG、JPEG、PNG格式")]),
                                  _c("p", [
                                    _vm._v(
                                      "大小：" +
                                        _vm._s(
                                          item.list[1].assetLocalResizeFileSize
                                        ) +
                                        " KB 以内"
                                    ),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                  ],
                  2
                )
              }),
              1
            ),
            _c(
              "div",
              { staticStyle: { "text-align": "center", "margin-top": "10px" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.addCreativeGroup },
                  },
                  [_vm._v("添加创意组")]
                ),
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c("el-button", { on: { click: _vm.handleClose } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                  [_vm._v("确 定")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm.materialVisible
      ? _c(
          "div",
          [
            _c("material-box", {
              attrs: {
                visible: _vm.materialBoxVisible,
                "img-count": _vm.imgCount,
                name: "creativeGroup",
                "image-list": [],
                attribute: {
                  key: "creativeGroup",
                  listType: "image/jpg,image/jpeg,image/png",
                  ..._vm.fileAttribute,
                },
                "project-id": [+_vm.projectId],
                "is-batch": true,
              },
              on: {
                setMaterialBox: function ($event) {
                  _vm.materialBoxVisible = false
                },
                submit: _vm.selectImg,
              },
            }),
            _c("MaterialVideoBox", {
              attrs: {
                visible: _vm.materialVideoVisible,
                "img-count": 1,
                name: "videoUrl",
                "video-list": [],
                attribute: {
                  key: "videoHorizontal",
                  listType: "video/mp4",
                  ..._vm.fileAttribute,
                },
                "project-id": [+_vm.projectId],
                "is-batch": true,
              },
              on: {
                setMaterialBox: function ($event) {
                  _vm.materialVideoVisible = false
                },
                handleVideoSuccess: _vm.selectVideo,
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }