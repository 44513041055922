var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "table-template",
      attrs: {
        title: "落地页预览",
        visible: _vm.show,
        "append-to-body": true,
        width: "500px",
        top: "20vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c("div", { staticClass: "page-container" }, [
        _c("div", { staticClass: "qrcode-box", attrs: { id: "qrcodeBox" } }, [
          _c("span", { staticClass: "qrcode-loading" }, [_vm._v("加载中...")]),
        ]),
        _c(
          "div",
          {
            staticClass: "qrcode-tips",
            staticStyle: { "text-align": "center" },
          },
          [_vm._v("请扫码预览")]
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { staticClass: "btn", on: { click: _vm.closeModal } },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }