var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-scrollbar",
    { staticClass: "scroll" },
    [
      _c(
        "a-menu",
        {
          attrs: {
            mode: "inline",
            "inline-collapsed": _vm.isCollapse,
            theme: "light",
          },
          model: {
            value: _vm.openKeys,
            callback: function ($$v) {
              _vm.openKeys = $$v
            },
            expression: "openKeys",
          },
        },
        [
          _vm._l(_vm.sideMenu[0].children, function (item) {
            return [
              item.children.length === 0
                ? _c("a-menu-item", { key: item.url }, [
                    _c("i", {
                      class: `iconfont icon-${item.remarks || "el-dian"} mr-12`,
                    }),
                    _vm._v(" " + _vm._s(item.menuName) + " "),
                  ])
                : _vm._e(),
              _c(
                "a-sub-menu",
                { key: item.url },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _c("i", {
                      class: `iconfont icon-${item.remarks || "el-dian"} mr-12`,
                    }),
                    _c("span", [_vm._v(_vm._s(item.menuName) + " ")]),
                  ]),
                  _vm._l(item.children, function (itemTwo) {
                    return _c(
                      "a-menu-item",
                      {
                        key: itemTwo.url,
                        on: {
                          click: function ($event) {
                            return _vm.titleClick(itemTwo)
                          },
                        },
                      },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "align-center",
                            attrs: { tag: "div", to: itemTwo.url },
                          },
                          [
                            _c("i", {
                              class: `iconfont icon-dian`,
                              staticStyle: { "font-size": "22px" },
                            }),
                            _vm._v(" " + _vm._s(itemTwo.menuName) + " "),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }