<!--
 * @Description: 组件选择区
 * @Date: 2021-10-18 11:42:31
 * @LastEditTime: 2022-04-25 14:40:26
-->

<template>
  <section v-if="seletedWidgetsIndex !== -1" class="ad-editors">
    <component :is="editors" :detail.sync="finalWidgets[seletedWidgetsIndex]" @openMaterialBox="setMaterialBox" />
    <material-box :visible="materialBox" :img-count="imgCount" :image-list="imageList" name="ldy" :attribute="attribute" @setMaterialBox="setMaterialBox" @submit="selectImg" />
  </section>
  <section v-else class="ad-editors">
    未选中组件
  </section>
</template>

<script>
import MaterialBox from '@/components/materialBox'
import Img from './adEditors_component/img.vue'
import Slider from './adEditors_component/slider.vue'
import Text from './adEditors_component/text.vue'
import CopyGh from './adEditors_component/copyGh.vue'
import Applet from './adEditors_component/applet.vue'

export default {
  components: {
    'm-img': Img,
    'm-slider': Slider,
    'm-text': Text,
    'm-copyGh': CopyGh,
    'm-applet': Applet,
    'material-box': MaterialBox
  },
  props: {
    finalWidgets: {
      type: Array,
      default: () => []
    },
    seletedWidgetsIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      editors: '',
      materialBox: false,
      imageList: []
    }
  },

  computed: {
    /**
     * @description: 当前需要选择几张图片
     * @return {number}
     */
    imgCount() {
      if (this.finalWidgets[this.seletedWidgetsIndex].name === '图片') {
        return 1
      } else {
        return +this.finalWidgets[this.seletedWidgetsIndex].componentCount
      }
    },
    /**
     * @description: 可以上传的图片的格式
     * @return {object}
     */
    attribute() {
      if (this.finalWidgets[this.seletedWidgetsIndex].name === '图片') {
        return {
          count: 1,
          size: 300 * 1024,
          listType: 'image/jpg,image/jpeg,image/png',
          width: 750,
          height: [1, 1536]
        }
      } else {
        return {
          count: +this.finalWidgets[this.seletedWidgetsIndex].componentCount,
          size: 300 * 1024,
          listType: 'image/jpg,image/jpeg,image/png',
          width: 750,
          height: [300, 1125]
        }
      }
    }
  },
  watch: {
    seletedWidgetsIndex: {
      handler() {
        this.editors = this.finalWidgets[this.seletedWidgetsIndex].component
      }
    },
    materialBox: {
      handler(value) {
        if (value) {
          if (this.finalWidgets[this.seletedWidgetsIndex].name === '图片') {
            this.imageList = [
              {
                image: this.finalWidgets[this.seletedWidgetsIndex].image,
                iamgeId: this.finalWidgets[this.seletedWidgetsIndex].iamgeId
              }
            ]
          } else {
            this.imageList = this.finalWidgets[this.seletedWidgetsIndex].slider
              .filter((f) => !!f)
              .map((item) => {
                return {
                  image: item
                }
              })
          }
        }
      }
    }
  },
  methods: {
    /**
     * @description: 打开/关闭资源库
     */
    setMaterialBox() {
      this.materialBox = !this.materialBox
    },
    /**
     * @description: 选择图片后调用
     * @param {Array} img 图片信息
     */
    selectImg(img) {
      if (this.finalWidgets[this.seletedWidgetsIndex].name === '图片') {
        this.finalWidgets[this.seletedWidgetsIndex].pureImageUrl = img[0].image
        this.finalWidgets[this.seletedWidgetsIndex].imageId = img[0].imageId
      } else {
        // 轮播图
        this.finalWidgets[this.seletedWidgetsIndex].slider = img.map((item) => item.image)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  padding: 0;
  margin: 0;
}
.ad-editors {
  padding: 10px;
}
</style>
