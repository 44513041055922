<template>
  <el-dialog :title="'编 辑'" width="580px" :visible.sync="visible" :before-close="close" label-position="right" :modal-append-to-body="false" :close-on-press-escape="false" :close-on-click-modal="false">
    <el-form ref="ent" :model="ent" :rules="rules" label-width="110px">
      <el-form-item v-if="projectShow" label="关联项目" prop="projectId">
        <el-select v-if="peopleShow && productShow" v-model="ent.projectId" class="w-300" filterable placeholder="关联项目" @change="changeProjec" @focus="focusProject">
          <el-option v-for="item in projects" :key="item.bsProjectId" :label="item.projectName" :value="item.bsProjectId" />
        </el-select>
        <el-input v-else v-model="ent.projectName" disabled class="w-300" />
      </el-form-item>

      <el-form-item v-if="productShow" label="关联产品" prop="launchProductsIds">
        <el-select v-model="ent.launchProductsIds" filterable placeholder="关联产品" class="w-300" @change="changeProduct">
          <el-option label="不限" :value="0"></el-option>
          <el-option v-for="item in launchProducts" :key="item.bsProductId" :label="item.productName" :value="item.bsProductId" />
        </el-select>
      </el-form-item>
      <el-form-item v-if="peopleShow" label="投放人员" prop="userId">
        <el-select v-model="ent.userId" filterable default-first-option placeholder="请选择" class="w-300" @change="changePeople">
          <el-option v-for="(item,index) in options" :key="index" :label="item.label" :value="item.key" />
        </el-select>
      </el-form-item>
      <el-form-item v-if="peopleShow" label="投放状态" prop="putInStatus">
        <el-select v-model="ent.putInStatus" filterable placeholder="投放状态" class="w-300">
          <el-option label="投放" :value="1"></el-option>
          <el-option label="停投" :value="0"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button round @click="close">取消</el-button>
      <el-button type="primary" round @click="submit">确定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { Add, Update } from '@/network/api/account/accountBaiduAdv'
import { AccountBaiduAdvEnt, formRules } from './object'
import { getUser } from '@/network/api/user'
export default {
  inject: ['loadData', 'projectList', 'productList', 'houseKeeper'],
  data() {
    return {
      peopleShow: true,
      projectShow: true,
      productShow: true,
      ent: new AccountBaiduAdvEnt(),
      rules: formRules,
      isEdit: false,
      visible: false,
      options: [],
      projects: [],
      productLists: {},
      launchProducts: [],
      cityList: []
    }
  },
  methods: {
    changePeople(val) {
      this.options.forEach((x) => {
        if (x.key === val) {
          this.ent.userName = x.label
        }
      })
    },
    changeProduct(val) {
      this.launchProducts.forEach((x) => {
        if (x.bsProductId === val) {
          this.ent.launchProductsNames = x.productName
        }
      })
    },
    changeProjec(val) {
      this.ent.launchProductsIds = 0
      this.projects.forEach((x) => {
        if (x.bsProjectId === val) {
          this.ent.projectName = x.projectName
        }
      })

      this.options = []
      this.launchProducts = this.productLists[val]
      this.projects.forEach((x) => {
        if (x.bsProjectId === val) {
          // 全员
          if (x.projectVisibleType === 'ALL') {
            this.getUserList()
          } else {
            var visibleUserIdListName = x.visibleUserIdListName
            var visibleUserIdList = x.visibleUserIdList
            var listid = visibleUserIdList.split(',')
            var listname = visibleUserIdListName.split(',')
            if (listid.length === listname.length) {
              listname.forEach((w) => {
                this.options.push({ label: w, key: listid[this.options.length] })
              })
            }
            console.log(this.options)
          }
        }
      })
    },
    focusProject() {
      this.projects = this.projectList()
      this.productLists = this.productList()
    },
    getUserList() {
      getUser().then((res) => {
        if (res.code === 200) {
          this.options = res.data
        }
      })
    },
    submit() {
      this.$refs.ent.validate((validate) => {
        if (validate) {
          this.isEdit ? this.updateRow() : this.addRow()
        }
      })
    },
    addRow() {
      const req = {
        ...this.ent
      }
      Add(req).then((res) => {
        this.$message({
          message: '保存成功',
          type: 'success'
        })
        this.loadData()
        this.close()
      })
    },
    updateRow() {
      const req = {
        ...this.ent,
        launchProductsNames: this.ent.launchProductsIds ? this.launchProducts.find((f) => f.bsProductId === this.ent.launchProductsIds).productName : '不限'
      }
      Update(req).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          this.loadData()
          this.close()
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    close() {
      this.visible = false
      this.$nextTick(() => {
        this.$refs.ent.resetFields()
      })
    },
    show(isEdit = false, ent) {
      Object.assign(this.$data, this.$options.data())
      this.projectShow = true
      this.peopleShow = true
      this.productShow = true
      this.ent = new AccountBaiduAdvEnt()
      var launchProductsIds
      var userId
      if (ent.launchProductsIds !== undefined && ent.launchProductsIds !== null) {
        launchProductsIds = Number(ent.launchProductsIds)
      }

      if (ent.userId !== undefined) {
        userId = ent.userId
      }
      this.ent = ent
      this.focusProject()
      if (this.ent.projectId !== undefined && this.ent.projectId !== null) {
        this.ent.projectId = Number(this.ent.projectId)
        this.changeProjec(this.ent.projectId)
      }

      this.ent.launchProductsIds = launchProductsIds
      this.ent.userId = userId
      this.ent.oauthAdId = [ent.oauthAdId]
      this.isEdit = isEdit
      this.visible = true
      this.$nextTick(() => {
        if (this.isEdit) {
          console.log('不能为空')
        }
      })
    },
    showType(isEdit = false, ids, type, projectId) {
      Object.assign(this.$data, this.$options.data())
      this.ent = new AccountBaiduAdvEnt()
      this.focusProject()
      this.projectShow = true
      this.peopleShow = true
      this.productShow = true
      if (type === 'people') {
        this.productShow = false
      }
      if (type === 'product') {
        this.peopleShow = false
      }
      this.options = []
      if (projectId !== undefined && projectId !== null && projectId !== '') {
        this.ent.projectId = Number(projectId)
        this.changeProjec(this.ent.projectId)
      }
      this.ent.oauthAdId = ids
      // this.ent.ids = ids.toString()
      this.isEdit = isEdit
      this.visible = true
      this.$nextTick(() => {
        if (this.isEdit) {
          console.log('不能为空')
        }
      })
    }
  }
}
</script>
